import { createAsyncThunk } from '@reduxjs/toolkit';
import { mutationSubmitSignUpProvider } from '../usecases';
export const submitSignUp = createAsyncThunk('@signup/submitSignUp', async ({ di, input }, { rejectWithValue }) => {
    var _a, _b, _c;
    const mutationSubmitSignUp = mutationSubmitSignUpProvider(di.logProvider(), di.networkClientProvider());
    try {
        await mutationSubmitSignUp(input);
        return undefined;
    }
    catch (err) {
        const response = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data;
        if ((_b = response === null || response === void 0 ? void 0 : response.details) === null || _b === void 0 ? void 0 : _b.reason) {
            const rejectedPayload = {
                nextAction: ((_c = response === null || response === void 0 ? void 0 : response.details) === null || _c === void 0 ? void 0 : _c.action) || '',
                message: response.details.reason,
            };
            return rejectWithValue(rejectedPayload);
        }
        throw err;
    }
});
