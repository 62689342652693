import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QUERY_SENDOUT_URL, QuerySendoutParams, QuerySendoutOptions } from './types';

export const useQuerySendout = (params: QuerySendoutParams, options?: QuerySendoutOptions) =>
  useQuery(
    ['sendout', params],
    () =>
      api.campaignV2(QUERY_SENDOUT_URL, 'post', {
        path: { businessId: params.businessId },
        body: {
          body: params,
        },
      }),
    options,
  );
