import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_DEACTIVATE_REACT_FLOW_DOCUMENT_URL =
  '/internal/v1/businesses/{input.businessId}/deactivate_react_flow_document/flow_documents';

type MutationDeactivateReactFlowDocumentURL = typeof MUTATION_DEACTIVATE_REACT_FLOW_DOCUMENT_URL;

export type MutationDeactivateReactFlowDocumentResponse =
  Paths['flowV1'][MutationDeactivateReactFlowDocumentURL]['post']['responses']['200'];
export type MutationDeactivateReactFlowDocumentInput = {
  businessId: string;
} & Paths['flowV1'][MutationDeactivateReactFlowDocumentURL]['post']['parameters']['body']['input'];
export type MutationDeactivateReactFlowDocumentOptions = Omit<
  UseMutationOptions<
    MutationDeactivateReactFlowDocumentResponse,
    unknown,
    MutationDeactivateReactFlowDocumentInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
