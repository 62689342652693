const regexpSubdomain = /^[a-z0-9-]+$/;
export const validateShopifyDomain = (inputDomain) => {
    try {
        let input = inputDomain.trim();
        if (input === '')
            return '';
        if (regexpSubdomain.test(input))
            return `${input}.myshopify.com`;
        if (input.indexOf('://') < 0)
            input = `https://${input}`;
        const url = new URL(input);
        if (url.hostname !== url.host)
            return '';
        if (url.hostname.indexOf('.') < 0)
            return '';
        return url.hostname;
    }
    catch (e) {
        return '';
    }
};
