import { createContext } from 'use-context-selector';
import { Navigator } from '@connectlyai-tenets/navigation';

export type NavigationDeps = {
  navigatorProviderForAnalytics: () => Navigator;
  navigatorProviderForAudience: () => Navigator;
  navigatorProviderForSettings: () => Navigator;
  navigatorProviderForFlow: () => Navigator;
  navigatorProviderForInbox: () => Navigator;
  navigatorProviderForActivitybar: () => Navigator;
};

// TODO the following is a bit hacky
// consider the solution outlined in
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const NavigationContext = createContext<NavigationDeps>(undefined!);
