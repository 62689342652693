import { updateWAToken, useFeatureDispatch, WelcomeDIContext } from '@connectlyai-features/welcome';
import { useContext } from 'react';
import { BusinessServiceProvider } from '../useMutationUpdateWAToken';

export const useWhatsAppConnection = () => {
  const di = useContext(WelcomeDIContext);
  const dispatch = useFeatureDispatch();
  const setIsLoading = (_isLoading: boolean): void => {};
  const onConnectWhatsApp = (
    accessToken: string,
    userId: number,
    dataAccessExpirationTime: number,
    expiresIn: number,
    businessServiceProvider: BusinessServiceProvider,
    useBrazilCreditLine: boolean,
  ) => {
    if (!di) {
      return;
    }
    dispatch(
      updateWAToken({
        di,
        accessToken,
        userId,
        dataAccessExpirationTime,
        expiresIn,
        setIsLoading,
        businessServiceProvider,
        useBrazilCreditLine,
      }),
    );
  };
  return {
    onConnectWhatsApp,
  };
};
