import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_CREATE_OR_UPDATE_REACT_FLOW_DOCUMENT_URL,
  MutationCreateOrUpdateReactFlowDocumentInput,
  MutationCreateOrUpdateReactFlowDocumentResponse,
  MutationCreateOrUpdateReactFlowDocumentOptions,
} from './types';

export const useCreateOrUpdateFlowDocumentMutation = (options?: MutationCreateOrUpdateReactFlowDocumentOptions) => {
  return useMutation(
    [],
    (input: MutationCreateOrUpdateReactFlowDocumentInput) =>
      api.flowV1(MUTATION_CREATE_OR_UPDATE_REACT_FLOW_DOCUMENT_URL, 'post', {
        path: { 'input.documentInput.businessId': input.businessId },
        body: {
          input: {
            documentInput: {
              ...input,
            },
          },
        },
      }) as Promise<MutationCreateOrUpdateReactFlowDocumentResponse>,
    options,
  );
};
