import React from 'react';
import { Card, Label, SofiaNodeCanvasIcon, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TargetHandle } from '@components/TargetHandle';

export const SofiaNode = ({ id }: { id: string }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        alignItems: 'center',
        background: 'linear-gradient(114deg, #FF01E6 1.48%, #9A08C1 8.49%, #360F9C 27.19%, #360F9C 65.6%)',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        color: theme.palette.common.white,
        display: 'flex',
        gap: 1,
        ':hover': {
          background: '#5230AC',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        },
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <TargetHandle nodeId={id} nodeType="FLOW_OBJECT_TYPE_AI_ASSISTANT" actionType="on-execute" />
      <SofiaNodeCanvasIcon />
      <Label variant="h7">Sofia AI takes over conversation</Label>
    </Card>
  );
};
