import { useEffect, useMemo } from 'react';
import { useFeatureFlag } from '@hooks';
import { useAgentDescriptor } from 'src/scenes/Sofia/hooks';
import { useGenerateClientKey } from 'src/scenes/Sofia/hooks/useGenerateClientKey/useGenerateClientKey';
import { useQueryClientKeys } from 'src/scenes/Sofia/hooks/useQueryClientKeys/useQueryClientKeys';

export const useWebchatSofiaLink = ({ businessId }: { businessId: string }) => {
  const { data: clientKeysResponse, isFetching } = useQueryClientKeys({ businessId, enabled: !!businessId });
  const { data: agentDescriptorResponse } = useAgentDescriptor({ businessId });
  const { ffEnableSofiaWebchatTestButton } = useFeatureFlag(['ffEnableSofiaWebchatTestButton']);
  const { mutate, isLoading } = useGenerateClientKey();
  const clientKeys = clientKeysResponse?.response?.keys;
  const activeClientKeys = useMemo(
    () => (clientKeys || []).filter((key) => key.expiresAt == null || new Date(key.expiresAt) > new Date()),
    [clientKeys],
  );
  // Response is ordered chronologically; get most recent.
  const clientKey = activeClientKeys[activeClientKeys.length - 1] || null;

  const agentDescriptors = agentDescriptorResponse?.response?.agentDescriptors || [];
  const agentDescriptor = agentDescriptors[0] || null;

  /*
   * Create clientKey for business if none are present
   */
  useEffect(() => {
    if (
      ffEnableSofiaWebchatTestButton &&
      businessId &&
      agentDescriptor?.id &&
      !clientKey &&
      !isLoading &&
      !isFetching
    ) {
      mutate({ businessId, agentDescriptorId: agentDescriptor.id });
    }
  }, [ffEnableSofiaWebchatTestButton, clientKey, businessId, agentDescriptor, mutate, isLoading, isFetching]);

  return `${process.env.REACT_APP_WEBCHAT_URL}/?clientKey=${clientKey?.key || ''}`;
};
