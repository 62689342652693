import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { BroadcastCategoryEditRouteId } from '@connectlyai-sdks/inbox-navigation';
import { mapMessageTemplateToViewProps, mapSendoutCampaignToViewModel, mapSendoutToViewModel, } from '../common/presenter';
import { BroadcastDIContext } from '../../context';
import { ChannelType, isNotNull, isValidTemplate } from '../../entities';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { reset as audienceEditReset, selectCampaignId, selectEditAudience, selectName, selectSendoutIds, selectSize, selectBehaviors, selectCampaigns, selectSendouts, selectTemplateIds, } from '../../state/audienceEditSlice';
import { selectMessageTemplates } from '../../state/messageTemplateGroupSlice';
import { tryQueryAudience, tryQueryCampaigns, tryQuerySendouts, tryQueryMessageTemplateGroups, tryQueryAudienceCohort, } from '../../state/thunk';
export const BroadcastAudienceBuilderComponentId = 'cbbcfaa4-e8f5-431d-acf8-7a19518913fa';
const BroadcastAudienceBuilder = (_props) => {
    const di = useContext(BroadcastDIContext);
    const dispatch = useFeatureDispatch();
    const isEditRouteMatch = useRouteMatch(di ? di.navigatorProvider().generateGlobalRouteId(BroadcastCategoryEditRouteId) : '');
    const audienceId = isEditRouteMatch ? isEditRouteMatch.params.id : undefined;
    useEffect(() => {
        if (!di)
            return;
        dispatch(audienceEditReset());
        if (di.config.businessId) {
            dispatch(tryQueryCampaigns({
                di,
                channelType: ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
            }));
            dispatch(tryQuerySendouts({
                di,
                channelType: ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
            }));
            dispatch(tryQueryMessageTemplateGroups({ di }));
        }
    }, [di, dispatch]);
    useEffect(() => {
        if (!di)
            return;
        const fetchAudienceAndSize = async () => {
            if (audienceId) {
                const { query } = await dispatch(tryQueryAudience({ di, audienceId })).unwrap();
                if (query) {
                    dispatch(tryQueryAudienceCohort({ di, audienceId, query }));
                }
            }
        };
        if (audienceId) {
            fetchAudienceAndSize();
        }
    }, [di, dispatch, audienceId]);
    const editAudience = useFeatureSelector(selectEditAudience);
    const campaignId = useFeatureSelector(selectCampaignId);
    const campaigns = useFeatureSelector(selectCampaigns).map(mapSendoutCampaignToViewModel);
    const sendouts = useFeatureSelector(selectSendouts(campaignId)).map(mapSendoutToViewModel);
    const caption = editAudience ? 'Edit Audience' : 'Create Audience';
    const name = useFeatureSelector(selectName);
    const sendoutIds = useFeatureSelector(selectSendoutIds);
    const size = useFeatureSelector(selectSize);
    const templateIds = useFeatureSelector(selectTemplateIds(campaignId));
    const templates = useFeatureSelector(selectMessageTemplates)
        .filter((x) => templateIds.includes(x.dto.id) && isValidTemplate(x))
        .map(mapMessageTemplateToViewProps)
        .filter(isNotNull);
    const behaviors = useFeatureSelector(selectBehaviors);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastAudienceBuilderComponentId);
    if (!UIComponent) {
        return null;
    }
    return (_jsx(UIComponent, { campaignId: campaignId, campaigns: campaigns, sendouts: sendouts, caption: caption, name: name, sendoutIds: sendoutIds, size: size, templates: templates, behaviors: behaviors }));
};
export default BroadcastAudienceBuilder;
