import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    items: [],
    selectedItemKey: null,
};
export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        reset: () => initialState,
        setMenuItems: (state, action) => {
            state.items = action.payload;
            if (action.payload.length > 0) {
                state.selectedItemKey = action.payload[0].key;
            }
        },
        setSelectedMenuItem: (state, action) => {
            state.selectedItemKey = action.payload;
        },
    },
});
export const { reset, setMenuItems, setSelectedMenuItem } = menuSlice.actions;
export const selectMenuItems = (state) => state.menuSlice.items;
export const selectSelectedMenuItemKey = (state) => state.menuSlice.selectedItemKey;
export default menuSlice.reducer;
