import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const QUERY_LIST_CAMPAIGN_REPORTS_URL =
  '/internal/v1/businesses/{query.businessId}/query_list_campaign_reports/reports';
type QueryListCampaignReportsURL = typeof QUERY_LIST_CAMPAIGN_REPORTS_URL;

export type QueryListCampaignReportsResponse =
  Paths['reportV1'][QueryListCampaignReportsURL]['post']['responses']['200']['schema'];
export type QueryListCampaignReportsInput =
  Paths['reportV1'][QueryListCampaignReportsURL]['post']['parameters']['body']['query'];
export type QueryListCampaignReportsParams = { businessId: string } & QueryListCampaignReportsInput;

export type QueryListCampaignReportsOptions = Omit<
  UseQueryOptions<
    QueryListCampaignReportsResponse,
    ConnectlyError,
    QueryListCampaignReportsResponse,
    readonly ['campaignReports', QueryListCampaignReportsParams]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;
