import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { previewDocumentIdAtom, campaignDropdownInputIdAtom, campaignDropdownHoverIdAtom } from '@atoms/flow';
import { useQueryClient } from '@tanstack/react-query';
import { Autocomplete, TextField, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useDebounce, useFlowNavigator } from '../../../../hooks';

import { HIGHLIGHT_PREVIEW_DEBOUNCE_DELAY_MS, CAMPAIGN_LIST_REFETCH_ON_MOUNT_DELAY_MS } from './constants';
import { ListboxComponent } from './virtualization';
import { CampaignDropdownProps } from './types';

export const useCampaignDropdown = ({ campaigns }: CampaignDropdownProps) => {
  const [previewDocumentId, setPreviewDocumentId] = useAtom(previewDocumentIdAtom);
  const [inputId, setInputId] = useAtom(campaignDropdownInputIdAtom);
  const [hoverId, setHoverId] = useAtom(campaignDropdownHoverIdAtom);

  const queryClient = useQueryClient();

  // on mount refetch campaign list with delay
  useEffect(() => {
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['listCampaigns'] });
      queryClient.invalidateQueries({ queryKey: ['flowDocument'] });
    }, CAMPAIGN_LIST_REFETCH_ON_MOUNT_DELAY_MS);
  }, [queryClient]);

  const { flowDocumentIdRouteMatch, navigateToFlowDocument, navigateToFlowHome } = useFlowNavigator();

  useEffect(() => {
    if (!previewDocumentId) {
      let newPreviewId = '';
      if (flowDocumentIdRouteMatch) {
        newPreviewId = flowDocumentIdRouteMatch.params.flowDocumentId;
      } else if (campaigns.length > 0 && campaigns[0].flowDocumentId !== undefined) {
        // no url params found for preview document id
        // auto select first campaign in campaign choose menu
        newPreviewId = campaigns[0].flowDocumentId;
      }
      if (newPreviewId) {
        setPreviewDocumentId(newPreviewId);
        setInputId(newPreviewId);
        setHoverId(newPreviewId);
      }
    }
  }, [
    campaigns,
    flowDocumentIdRouteMatch,
    navigateToFlowDocument,
    previewDocumentId,
    setHoverId,
    setInputId,
    setPreviewDocumentId,
  ]);

  useDebounce(
    () => {
      if (previewDocumentId !== hoverId) {
        setPreviewDocumentId(hoverId);
      }
    },
    HIGHLIGHT_PREVIEW_DEBOUNCE_DELAY_MS,
    [hoverId, setPreviewDocumentId],
  );

  const ids: string[] = useMemo(
    () => campaigns.map((campaign) => campaign.flowDocumentId || '').filter((id) => !!id),
    [campaigns],
  );

  const labelOf = useCallback(
    (id: string) => campaigns.find((campaign) => campaign.flowDocumentId === id)?.name ?? '',
    [campaigns],
  );

  const handleHighlightChange = useCallback(
    (_: React.SyntheticEvent, id: string | null) => {
      if (!id) return;
      if (hoverId !== id) {
        setHoverId(id);
      }
    },
    [hoverId, setHoverId],
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent, id: string | null) => {
      if (!id) return;
      setInputId(id);
      if (hoverId !== id) {
        setHoverId(id);
      }

      if (flowDocumentIdRouteMatch && flowDocumentIdRouteMatch.params.flowDocumentId !== id) {
        navigateToFlowHome();
        setPreviewDocumentId(id);
      }
    },
    [hoverId, setHoverId, setInputId, flowDocumentIdRouteMatch, navigateToFlowHome, setPreviewDocumentId],
  );

  const handleBlur = useCallback(() => {
    setPreviewDocumentId(inputId);
    setHoverId(inputId);
  }, [setPreviewDocumentId, inputId, setHoverId]);

  return { handleBlur, handleChange, handleHighlightChange, ids, inputId, labelOf };
};

export const CampaignDropdown: FC<CampaignDropdownProps> = (props) => {
  const { handleBlur, handleChange, handleHighlightChange, ids, inputId, labelOf } = useCampaignDropdown(props);
  const { label, onClose, onOpen, usedInForm } = props;
  const theme = useTheme();

  return (
    <Autocomplete
      value={inputId}
      getOptionLabel={(id) => labelOf(id)}
      ListboxComponent={ListboxComponent}
      renderOption={(renderProps, option, _state) => (
        <span
          {...renderProps}
          key={option}
          style={{
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {labelOf(option)}
        </span>
      )}
      options={ids}
      onBlur={handleBlur}
      onChange={handleChange}
      onHighlightChange={handleHighlightChange}
      onClose={onClose}
      onOpen={onOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          {...(usedInForm ? { label } : {})}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: '10px',
          ...(usedInForm ? { height: theme.spacing(6) } : { p: '3.5px 6px' }),
        },
        flexGrow: 1,
      }}
    />
  );
};
