import { IncomingRoomEventData } from '@connectlyai-tenets/sdk';
import { canonicalize } from '@hooks/useFlowVariables/mappers';
import {
  CustomerRepliesNodeUIState,
  IncomingRoomEventDataMessageFormat,
  IncomingRoomEventDataV1Part,
  IncomingRoomEventDataMessageValidator,
  IncomingRoomEventDataErrorMessage,
} from './types';
import { DEFAULT_INCOMING_ROOM_EVENT_NODE_UI } from '../../components/CustomerRepliesNodeEditor/constants';

export const selectMessageFormatTypeFromData = (data: IncomingRoomEventData): IncomingRoomEventDataMessageFormat => {
  if (data?.v1?.message?.validators?.[0]?.any) {
    return 'any';
  }
  if (data?.v1?.message?.validators?.[0]?.text) {
    return 'text';
  }
  if (data?.v1?.message?.validators?.[0]?.media) {
    return 'media';
  }
  return 'text';
};

export const selectValidatorFromData = (data: IncomingRoomEventData): IncomingRoomEventDataMessageValidator => {
  if (data?.v1?.message?.validators?.[0]) {
    return data?.v1?.message?.validators?.[0];
  }
  return {};
};

export const selectErrorMessageFromData = (data: IncomingRoomEventData): IncomingRoomEventDataErrorMessage => {
  return data?.v1?.errorHandler?.v1?.errorMessage;
};

export const mapMessageFormatTypeToUIState = (
  formatType: IncomingRoomEventDataMessageFormat,
): CustomerRepliesNodeUIState['format'] => {
  switch (formatType) {
    case 'any':
      return 'All';
    case 'text':
      return 'Text only';
    case 'media':
      return 'Image only';
    default:
      return DEFAULT_INCOMING_ROOM_EVENT_NODE_UI.format;
  }
};

export const mapMessageFormatTypeToData = (
  formatType: CustomerRepliesNodeUIState['format'],
): IncomingRoomEventDataMessageFormat => {
  switch (formatType) {
    case 'All':
      return 'any';
    case 'Text only':
      return 'text';
    case 'Image only':
      return 'media';
    default:
      return 'text';
  }
};

export const mapUIStateToDataV1Message = (uiState: CustomerRepliesNodeUIState): IncomingRoomEventDataV1Part => {
  const formatType = mapMessageFormatTypeToData(uiState.format);
  const validator: IncomingRoomEventDataMessageValidator = { [formatType]: {} };

  if (validator.text) {
    if (uiState.maxCharacters) {
      validator.text.max = parseInt(uiState.maxCharacters, 10);
    }
    if (uiState.minCharacters) {
      validator.text.min = parseInt(uiState.minCharacters, 10);
    }
    if (uiState.regex) {
      validator.text.regexps = [{ pattern: uiState.regex }];
    }
  }

  return { message: { validators: [validator] } };
};

export const mapUIStateToDataV1ErrorHandler = (uiState: CustomerRepliesNodeUIState): IncomingRoomEventDataV1Part => {
  if (!uiState.enableErrorMessage || !uiState.errorMessage) return {};
  return { errorHandler: { v1: { errorMessage: uiState.errorMessage } } };
};

export const mapUIStateToMessageDataV1ParameterMapping = (
  uiState: CustomerRepliesNodeUIState,
): IncomingRoomEventDataV1Part => {
  if (!uiState?.saveAsVariable || !uiState.variable) return {};
  const variableWithoutCurly = uiState.variable.replace(/{{|}}/g, '');
  return {
    parameterMapping: {
      mappings: [{ rawParam: 'text', fullQualifier: canonicalize(variableWithoutCurly) }],
    },
  };
};

export const selectVariableFromData = (data: IncomingRoomEventData): CustomerRepliesNodeUIState['variable'] => {
  const fullQualifier = data?.v1?.parameterMapping?.mappings?.[0]?.fullQualifier || null;
  if (!fullQualifier) return null;
  return `{{${fullQualifier}}}`;
};

export const incomingRoomEventNodeDataToUIState = (nodeData: IncomingRoomEventData): CustomerRepliesNodeUIState => {
  const format = mapMessageFormatTypeToUIState(selectMessageFormatTypeFromData(nodeData));
  const variable = selectVariableFromData(nodeData);
  const saveAsVariable = !!variable;

  const validator = selectValidatorFromData(nodeData);
  const minCharacters = `${validator.text?.min || ''}`;
  const maxCharacters = `${validator.text?.max || ''}`;
  const regex = `${validator.text?.regexps?.[0]?.pattern || ''}`;
  const advancedTextSettings = minCharacters !== '' || maxCharacters !== '' || regex !== '';

  const errorMessage = selectErrorMessageFromData(nodeData) || '';
  const enableErrorMessage = errorMessage !== '';

  return {
    advancedTextSettings,
    enableErrorMessage,
    errorMessage,
    format,
    maxCharacters,
    minCharacters,
    regex,
    saveAsVariable,
    variable,
  };
};
