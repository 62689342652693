import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { FeatureFlagState, loadFeatureFlags } from '@connectlyai-tenets/feature-flag';
import { CONNECTLY_BUSINESS_ID } from '@components/Admin/constants';
import { BusinessList } from '@components/Admin/types';

export const adminModalOpenAtom = atom<boolean>(false);
export const adminBusinessIdAtom = atomWithStorage('adminBusinessId', '');
export const adminBusinessListAtom = atomWithStorage<BusinessList>('adminBusinessList', [
  { id: CONNECTLY_BUSINESS_ID, name: 'Connectly' },
]);

export const dismissedAlertsAtom = atom<string[]>([]);

export const featureFlagsAtom = atom<FeatureFlagState>(loadFeatureFlags());
