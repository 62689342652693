import { unwrapResult } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { DataOrigin, DataResponse, compareDataResponse } from '@connectlyai-tenets/data';
import { buildDIContainer } from './di';
import storeProvider from './state/store';
import { businessDtoUpdate } from './state/businessSlice';
import { fetchAgents, fetchBusiness, tryCreateQuickReplyTemplate, tryCreateTag, tryDeleteQuickReplyTemplate, tryDeleteTag, tryUpdateQuickReplyTemplate, tryUpdateTag, } from './state/thunk';
import { subscriptionDtoUpdateBusinessProvider } from './usecases/subscriptionDtoUpdateBusiness';
export default class RealBusinessDataStore {
    constructor(deps) {
        this.di = buildDIContainer(deps);
        this.businessStateSubject = new Subject();
        this.store = storeProvider();
        this.store.subscribe(() => {
            const newState = this.store.getState().businessReducer;
            this.businessStateSubject.next(newState);
        });
        const subscriptionDtoUpdateBusiness = subscriptionDtoUpdateBusinessProvider(this.di.logProvider(), this.di.dtoUpdateAccessorProvider());
        subscriptionDtoUpdateBusiness().subscribe((payload) => {
            this.store.dispatch(businessDtoUpdate(payload));
        });
    }
    async eventRequestRefreshQuickReplyTemplate(businessId) {
        return this.store
            .dispatch(fetchBusiness({
            di: this.di,
            businessId,
        }))
            .then(unwrapResult)
            .then();
    }
    async eventRequestRefreshTag(businessId) {
        return this.store
            .dispatch(fetchBusiness({
            di: this.di,
            businessId,
        }))
            .then(unwrapResult)
            .then();
    }
    async mutationCreateQuickReplyTemplate(input) {
        return this.store.dispatch(tryCreateQuickReplyTemplate({ di: this.di, input })).then(unwrapResult);
    }
    async mutationCreateTag(input) {
        return this.store
            .dispatch(tryCreateTag({ di: this.di, input }))
            .then(unwrapResult)
            .then((_result) => { });
    }
    async mutationDeleteQuickReplyTemplate(businessId, templateId) {
        return this.store.dispatch(tryDeleteQuickReplyTemplate({ di: this.di, businessId, templateId })).then(unwrapResult);
    }
    async mutationDeleteTag(businessId, tagId) {
        return this.store.dispatch(tryDeleteTag({ di: this.di, businessId, tagId })).then(unwrapResult);
    }
    async mutationUpdateQuickReplyTemplate(input) {
        return this.store.dispatch(tryUpdateQuickReplyTemplate({ di: this.di, input })).then(unwrapResult);
    }
    async mutationUpdateTag(input) {
        return this.store.dispatch(tryUpdateTag({ di: this.di, input })).then(unwrapResult);
    }
    subscribeBusiness(businessId) {
        this.store.dispatch(fetchBusiness({
            di: this.di,
            businessId,
        }));
        this.store.dispatch(fetchAgents({
            di: this.di,
            businessId,
        }));
        const currentState = this.store.getState().businessReducer.businesses[businessId];
        const startValue = currentState && currentState.status === 'succeeded' && currentState.business
            ? DataResponse.data(currentState.business)
            : DataResponse.loading();
        return this.businessStateSubject.pipe(map((state) => {
            const businessStatus = state.businesses[businessId];
            return businessStatus && businessStatus.status === 'succeeded' && businessStatus.business
                ? DataResponse.data(businessStatus.business, DataOrigin.SourceOfTruth)
                : DataResponse.loading();
        }), startWith(startValue), filter((val) => { var _a; return val.isDataReady() && ((_a = val.data) === null || _a === void 0 ? void 0 : _a.id) === businessId; }), distinctUntilChanged(compareDataResponse));
    }
}
