import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  FLOW_EVENT_REQUEST_VERIFY_URL,
  FlowEventRequestVerifyResponse,
  FlowEventRequestVerifyOptions,
  FlowEventRequestVerifyInput,
} from './types';

export const useEventRequestVerifyReactFlowDocument = (options?: FlowEventRequestVerifyOptions) => {
  return useMutation(
    (input: FlowEventRequestVerifyInput) =>
      api.flowV1(FLOW_EVENT_REQUEST_VERIFY_URL, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }) as Promise<FlowEventRequestVerifyResponse>,
    {
      ...options,
    },
  );
};
