import React, { FC, PropsWithChildren } from 'react';
import { Box, Label } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartSidebarSectionProps } from './types';

export const FlowChartSidebarSection: FC<PropsWithChildren<FlowChartSidebarSectionProps>> = ({
  sx = [],
  children,
  title,
}) => (
  <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Label
      component="div"
      sx={[
        {
          fontSize: '16px',
          lineHeight: '18.75px',
          fontWeight: 'fontWeightMedium',
        },
      ]}
    >
      {title}
    </Label>
    {children}
  </Box>
);
