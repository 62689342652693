import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, InputAdornment, TextField, TextFieldsIcon } from '@connectlyai-tenets/ui-styled-web';
import {
  selectIsFooterEnabled,
  selectFooter,
  selectFooterError,
  setFooter,
  setIsComponentEnabled,
} from '../../state/messageTemplates';
import { TemplateBuilderFooterProps } from './types';
import { applyHook } from '../../utils/applyHook';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { TemplateBuilderTitle } from '../TemplateBuilderTitle';
import { FOOTER_MAX_LENGTH } from './constants';
import { scrollToHTMLInputElement } from '../../utils';

const useTemplateBuilderFooter = (_props: Partial<TemplateBuilderFooterProps>): Partial<TemplateBuilderFooterProps> => {
  const dispatch = useDispatch();
  const footer = useSelector(selectFooter);
  const error = useSelector(selectFooterError);
  const isEnabled = useSelector(selectIsFooterEnabled);

  useEffect(() => {
    if (isEnabled) {
      scrollToHTMLInputElement('message-template-footer');
    }
  }, [isEnabled]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFooter(event.target.value));
    },
    [dispatch],
  );

  const handleEnabledChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
      dispatch(setIsComponentEnabled({ footer: !isEnabled }));
    },
    [dispatch, isEnabled],
  );

  return {
    footer,
    error,
    isEnabled,
    onChange: handleChange,
    onEnabledChange: handleEnabledChange,
  };
};

const TemplateBuilderFooterPresentation: FC<TemplateBuilderFooterProps> = ({
  footer,
  error,
  isEnabled,
  onChange,
  onEnabledChange,
}) => {
  const hint =
    'The footer is the small print of the message. This is very useful for when you have ' +
    'promotions and want to include the terms and conditions of the promo.';

  return (
    <TemplateBuilderItem error={Boolean(error)}>
      <TemplateBuilderTitle
        icon={<TextFieldsIcon color="action" />}
        hint={hint}
        optional={{ checked: isEnabled, onChange: onEnabledChange }}
      >
        Footer
      </TemplateBuilderTitle>
      <Collapse in={isEnabled} unmountOnExit>
        <TextField
          id="message-template-footer"
          autoComplete="off"
          placeholder="Enter text"
          error={Boolean(error)}
          fullWidth
          value={footer}
          onChange={onChange}
          variant="outlined"
          inputProps={{ maxLength: FOOTER_MAX_LENGTH }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{`${footer.length}/${FOOTER_MAX_LENGTH}`}</InputAdornment>,
          }}
          sx={{
            mt: 1.5,
            '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
            '& .MuiInputBase-input': { p: '11px 12px' },
          }}
        />
      </Collapse>
    </TemplateBuilderItem>
  );
};

export const TemplateBuilderFooter = applyHook(TemplateBuilderFooterPresentation, useTemplateBuilderFooter);
