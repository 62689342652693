import React, { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { useContextSelector } from 'use-context-selector';
import { AddVariable } from '@components/AddVariable';
import {
  Box,
  Checkbox,
  DeleteOutlineIcon,
  FormControlLabel,
  FormGroup,
  IconButton,
  InfoIcon,
  InputAdornment,
  InputLabel,
  Label,
  OpenInNewIcon,
  PhoneIcon,
  TextField,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import type { TemplatePrefilledParam } from '@connectlyai-tenets/sdk';
import { useFlowVariables } from '@hooks/useFlowVariables';
import { selectButtons, setButtons } from '../../../state/messageTemplates';
import { FlowVariableDialog, FlowVariableDialogRef } from '../../FlowVariableDialog';
import { CallToActionProps } from './types';
import { BUTTON_PHONE_MAX_LENGTH, BUTTON_TEXT_MAX_LENGTH, BUTTON_URL_MAX_LENGTH } from '../constants';
import { FlowContext } from '../../../contexts';
import { getButtonLabel, getButtonText } from '../../../utils';
import { SIZE_MEDIA_XXXS, SIZE_SPACING_ANNOTATION } from '../../../ui-theme';
import { CodeMirrorTextField } from '../../CodeMirrorTextField';

const useCallToAction = ({ button, error, index, onDelete, uiState }: CallToActionProps) => {
  const dispatch = useDispatch();
  const { mapToVariableHash, substituteVariables } = useFlowVariables();
  const buttons = useSelector(selectButtons, shallowEqual);
  const supportsLinkTracking = useContextSelector(FlowContext, (context) => context.supportsLinkTracking);
  const supportsVariables = useContextSelector(FlowContext, (context) => context.supportsVariables);
  const urlVarDialogRef = useRef<FlowVariableDialogRef>(null);
  const urlAddVarRef = useRef<HTMLButtonElement>(null);
  const urlActiveVarRef = useRef<HTMLElement | null>(null);
  const urlCodeMirrorRef = useRef<ReactCodeMirrorRef>({});

  const urlValue = uiState?.[index]?.url || '';
  const errorText = error?.[index]?.text;
  const errorUrl = error?.[index]?.url;
  const errorPhoneNumber = error?.[index]?.phoneNumber;

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          const { button: currentButton } = buttons[i];
          if (currentButton?.url) {
            return { ...buttons[i], button: { ...currentButton, url: { ...currentButton.url, text } } };
          }
          if (currentButton?.phoneNumber) {
            return {
              ...buttons[i],
              button: { ...currentButton, phoneNumber: { ...currentButton.phoneNumber, text } },
            };
          }

          return buttons[i];
        }

        return buttons[i];
      });

      const newError = error?.[index]?.text
        ? error.map((x, i) => {
            if (i === index) {
              return { ...error[i], text: '' };
            }
            return error[i];
          })
        : error;

      const newUiState = uiState;

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState }));
    },
    [buttons, dispatch, error, uiState, index],
  );

  const changeUrl = useCallback(
    (displayUrl: string) => {
      const pattern = /^(https?:\/\/)/;
      if (pattern.test(displayUrl)) {
        displayUrl = displayUrl.replace(/^(https?:\/\/)/, '');
      }
      let finalUrl = `https://${displayUrl}`;
      let mappings;
      const prefilledParams: TemplatePrefilledParam[] = [];
      if (supportsVariables) {
        const paramIdx = index + 1;
        const paramPrefix = `button_${paramIdx}_url_suffix.`;
        const newMappings = mapToVariableHash(finalUrl, paramPrefix, '{{}}');
        finalUrl = substituteVariables(finalUrl);
        mappings = {
          deletePrefix: paramPrefix,
          new: newMappings,
        };
        /*
          TODO(andreas): Normally prefilled params should happen here but because we need to 
          call an async function to finalize the params (in order to get the shorturl from the BE)
          we are skipping this process here and delegating to the FlowBuilderProduct service 
          to enhance the data of the node properly with the correct information. We still need to generate the
          mappings above consistently though.
          
          const url = new URL(finalUrl);
          Object.keys(variableMappings).forEach((val) => {
            const paramKey = val.replace(paramPrefix, '');
            const isPathParam = url.pathname.includes(encodeURIComponent(`{{${paramKey}}}`));
            prefilledParams.push({
              key: paramKey,
              input: true,
              rule: isPathParam ? 'PREFILL_RULE_SHORTLINK_URL_QUERY' : 'PREFILL_RULE_SHORTLINK_URL_QUERY_RAW',
            });
          });
        */
      }
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          const { button: currentButton } = buttons[i];
          const buttonUiState = uiState ? uiState[i] || { tracked: false } : { tracked: false };
          if (currentButton?.url) {
            const trackedUrl = buttonUiState.tracked && supportsLinkTracking ? finalUrl : undefined;
            return {
              ...buttons[i],
              button: { ...currentButton, url: { ...currentButton.url, url: finalUrl, trackedUrl, prefilledParams } },
            };
          }
        }

        return buttons[i];
      });

      const newError = error;

      const newUiState = uiState
        ? uiState.map((x, i) => {
            if (i === index) {
              return { ...uiState[i], url: displayUrl };
            }
            return uiState[i];
          })
        : uiState;

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState, mappings }));
    },
    [
      buttons,
      dispatch,
      error,
      uiState,
      supportsLinkTracking,
      supportsVariables,
      index,
      mapToVariableHash,
      substituteVariables,
    ],
  );

  const handleChangeUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const displayUrl = event.target.value;
      changeUrl(displayUrl);
    },
    [changeUrl],
  );

  const handleUrlEditorChange = useCallback(
    (value: string) => {
      changeUrl(value);
    },
    [changeUrl],
  );

  const handleChangeTracking = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          const { button: currentButton } = buttons[i];
          if (currentButton?.url) {
            const trackedUrl = isChecked ? currentButton.url.url : undefined;
            return {
              ...buttons[i],
              button: { ...currentButton, url: { ...currentButton.url, trackedUrl } },
            };
          }
        }

        return buttons[i];
      });

      const newError = error;

      const newUiState = uiState
        ? uiState.map((x, i) => {
            if (i === index) {
              return { ...uiState[i], tracked: isChecked };
            }
            return uiState[i];
          })
        : uiState;

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState }));
    },
    [dispatch, buttons, error, uiState, index],
  );

  const handleChangePhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const phoneNumber = event.target.value;
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          const { button: currentButton } = buttons[i];
          if (currentButton?.phoneNumber) {
            return {
              ...buttons[i],
              button: { ...currentButton, phoneNumber: { ...currentButton.phoneNumber, phoneNumber } },
            };
          }
        }

        return buttons[i];
      });

      const newError = error?.[index]?.phoneNumber
        ? error.map((x, i) => {
            if (i === index) {
              return { ...error[i], phoneNumber: '' };
            }
            return error[i];
          })
        : error;

      const newUiState = uiState;

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState }));
    },
    [buttons, dispatch, error, uiState, index],
  );

  const handleUrlOpenDialog = useCallback(() => {
    urlVarDialogRef.current?.handleOpenDialog();
  }, [urlVarDialogRef]);

  return {
    urlValue,
    urlActiveVarRef,
    urlAddVarRef,
    urlCodeMirrorRef,
    urlVarDialogRef,
    button,
    errorPhoneNumber,
    errorText,
    errorUrl,
    index,
    handleChangePhone,
    handleChangeText,
    handleChangeTracking,
    handleChangeUrl,
    handleUrlEditorChange,
    handleUrlOpenDialog,
    onDelete,
    supportsLinkTracking,
    supportsVariables,
    uiState,
  };
};

export const CallToAction: FC<CallToActionProps> = (props) => {
  const {
    urlValue,
    urlActiveVarRef,
    urlAddVarRef,
    urlCodeMirrorRef,
    urlVarDialogRef,
    button,
    errorPhoneNumber,
    errorText,
    errorUrl,
    index,
    handleChangePhone,
    handleChangeText,
    handleChangeTracking,
    handleChangeUrl,
    handleUrlEditorChange,
    handleUrlOpenDialog,
    onDelete,
    supportsLinkTracking,
    supportsVariables,
    uiState,
  } = useCallToAction(props);
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} id={`message-template-button${index}-content`}>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 0.5,
        }}
      >
        {button.button?.phoneNumber && <PhoneIcon color="action" sx={{ width: 24, height: 24 }} />}
        {button.button?.url && <OpenInNewIcon color="action" sx={{ width: 24, height: 24 }} />}
        <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium', flex: '1 1 auto' }}>
          {getButtonLabel(button, index)}
        </Label>
        <IconButton size="small" onClick={() => onDelete(index)} sx={{ m: '-5px 0' }}>
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <TextField
        id={`message-template-button${index}-text`}
        fullWidth
        placeholder="Enter text"
        error={Boolean(errorText)}
        helperText={errorText}
        variant="outlined"
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        value={getButtonText(button)}
        inputProps={{ maxLength: BUTTON_TEXT_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${
              getButtonText(button).length
            }/${BUTTON_TEXT_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        onChange={handleChangeText}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { borderRadius: '10px' },
          '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
        }}
      />
      {button.button?.url && (
        <>
          {supportsVariables && (
            <>
              <CodeMirrorTextField
                value={urlValue}
                codeMirrorUpdate={urlVarDialogRef?.current?.handleCodeMirrorUpdate}
                editable
                handleVariableClick={urlVarDialogRef?.current?.handleVariableClick}
                placeholder="Enter URL"
                setValue={handleUrlEditorChange}
                ref={urlCodeMirrorRef}
                containerStyle={{
                  marginTop: 1,
                  paddingLeft: 0.5,
                }}
              />
              <InputLabel error={Boolean(errorUrl)}>{errorUrl}</InputLabel>
              <AddVariable
                onClick={handleUrlOpenDialog}
                ref={urlAddVarRef}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              />
              <FlowVariableDialog
                anchorEl={urlActiveVarRef?.current || urlAddVarRef?.current}
                codeMirrorRef={urlCodeMirrorRef}
                value={urlValue}
                handleEditorChange={handleUrlEditorChange}
                ref={urlVarDialogRef}
              />
              {supportsLinkTracking && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: SIZE_SPACING_ANNOTATION }}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        mr: 0, // default is 16px but we want to control through gap above
                      }}
                      control={<Checkbox checked={uiState?.[index]?.tracked} onChange={handleChangeTracking} />}
                      label={<Label variant="body2">Smart link tracking</Label>}
                    />
                  </FormGroup>
                  <Tooltip title="" arrow>
                    <InfoIcon
                      id="tooltip-smart-link-tracking"
                      width={theme.spacing(SIZE_MEDIA_XXXS)}
                      height={theme.spacing(SIZE_MEDIA_XXXS)}
                      fill={theme.palette.common.black}
                      style={{ display: 'none' }}
                    />
                  </Tooltip>
                </Box>
              )}
            </>
          )}
          {!supportsVariables && (
            <>
              <TextField
                id={`message-template-button${index}-url`}
                fullWidth
                placeholder="your-site.com"
                error={Boolean(errorUrl)}
                helperText={errorUrl}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={urlValue}
                inputProps={{ maxLength: BUTTON_URL_MAX_LENGTH }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      https://
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">{`${urlValue.length}/${BUTTON_URL_MAX_LENGTH}`}</InputAdornment>
                  ),
                }}
                onChange={handleChangeUrl}
                sx={{
                  mt: 1.5,
                  '& .MuiInputBase-root': { borderRadius: '10px' },
                  '& .MuiInputBase-input': { px: 0, py: 1.5 },
                }}
              />
              {supportsLinkTracking && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: SIZE_SPACING_ANNOTATION }}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        mr: 0, // default is 16px but we want to control through gap above
                      }}
                      control={<Checkbox checked={uiState?.[index]?.tracked} onChange={handleChangeTracking} />}
                      label={<Label variant="body2">Smart link tracking</Label>}
                    />
                  </FormGroup>
                  <Tooltip title="" arrow>
                    <InfoIcon
                      id="tooltip-smart-link-tracking"
                      width={theme.spacing(SIZE_MEDIA_XXXS)}
                      height={theme.spacing(SIZE_MEDIA_XXXS)}
                      fill={theme.palette.common.black}
                      style={{ display: 'none' }}
                    />
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </>
      )}
      {button.button?.phoneNumber && (
        <TextField
          id={`message-template-button${index}-phone`}
          fullWidth
          placeholder="Enter phone number"
          error={Boolean(errorPhoneNumber)}
          helperText={errorPhoneNumber}
          variant="outlined"
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          value={button.button?.phoneNumber.phoneNumber || ''}
          inputProps={{ maxLength: BUTTON_PHONE_MAX_LENGTH }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {`${(button.button?.phoneNumber.phoneNumber || '').length}/${BUTTON_PHONE_MAX_LENGTH}`}
              </InputAdornment>
            ),
          }}
          onChange={handleChangePhone}
          sx={{
            mt: 1.5,
            '& .MuiInputBase-root': { borderRadius: '10px' },
            '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
          }}
        />
      )}
    </Box>
  );
};
