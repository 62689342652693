import { RefObject, useLayoutEffect, useEffect, useState, useCallback } from 'react';
import { WIDTH_OF_ONE_CHIP, WIDTH_OF_EXTRA_CHIP, MIN_NUM_CHIPS } from './constants';

// this function returns the number of "template message status chip"s that can be displayed in table cell
export const useResponsiveChips = (availableChipsCount: number, parentRef: RefObject<HTMLElement>) => {
  const [responsiveChipsCount, setChipsCount] = useState(MIN_NUM_CHIPS);

  const fitChipsToParent = useCallback(() => {
    if (parentRef.current) {
      const stringWidth = window.getComputedStyle(parentRef.current).width;
      const parentWidth = parseInt(stringWidth, 10);
      const requiredWidthForAll = availableChipsCount * WIDTH_OF_ONE_CHIP;

      if (parentWidth > requiredWidthForAll) {
        // since there is enough space return available chip count
        setChipsCount(availableChipsCount);
      } else {
        // there is not enough space, calculate how many chips can be displayed
        // first remove width of extra chip (+xx)
        const sizeLeftFromExtra = parentWidth - WIDTH_OF_EXTRA_CHIP;

        // then calculate how many chips can be displayed
        // by dividing the size left by the width of one chip
        const sizeInChips = Math.floor(sizeLeftFromExtra / WIDTH_OF_ONE_CHIP);

        // but display at least one chip
        const clampedCount = Math.max(sizeInChips, MIN_NUM_CHIPS);
        setChipsCount(clampedCount);
      }
    }
  }, [availableChipsCount, parentRef]);

  // just before rendering do the calculation
  useLayoutEffect(() => {
    fitChipsToParent();
  }, [fitChipsToParent]);

  // add event listener to change width of stack on window resize
  useEffect(() => {
    window.addEventListener('resize', fitChipsToParent);
    return () => window.removeEventListener('resize', fitChipsToParent);
  }, [fitChipsToParent]);

  return { responsiveChipsCount };
};
