import React, { FC } from 'react';
import { Stack, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { NAME_MAX_LENGTH } from './constants';
import { AudienceNameProps } from './types';

export const AudienceName: FC<AudienceNameProps> = ({ name, error, onChange }: AudienceNameProps) => {
  return (
    <Stack>
      <Label sx={{ mb: 0.5, fontWeight: 600 }}>Name</Label>
      <TextField
        autoFocus
        autoComplete="off"
        error={Boolean(error)}
        helperText={error}
        fullWidth
        placeholder="Enter audience name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={name}
        inputProps={{ maxLength: NAME_MAX_LENGTH }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{`${name.length}/${NAME_MAX_LENGTH}`}</InputAdornment>,
        }}
        onChange={onChange}
        sx={{
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Stack>
  );
};
