import { useEffect, useRef } from 'react';
import { useStore, Atom } from 'jotai';

export const useAtomEffect = <TAtom>(atom: Atom<TAtom>, callback: (value: TAtom, oldValue: TAtom) => void) => {
  const store = useStore();
  const oldValue = useRef<TAtom>(store.get(atom));

  useEffect(() => {
    const unsub = store.sub(atom, () => {
      callback(store.get(atom), oldValue.current);
      oldValue.current = store.get(atom);
    });

    return () => unsub();
  }, [atom, callback, store]);
};
