import React, { ComponentProps, PropsWithChildren, ReactElement, cloneElement, useMemo } from 'react';
import { NodeTypes } from 'react-flow-renderer';
import { MessageNodeData, TimeDelayData } from '@connectlyai-tenets/sdk';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { OptOutNode } from '../../components/OptOutNode';
import { CustomerRepliesNode } from '../../components/CustomerRepliesNode';
import { APICallNode } from '../../components/APICallNode';
import { TimeDelayNode } from '../../components/TimeDelayNode';
import { MessageNode } from '../../components/MessageNode';
import { SofiaNode } from '../../components/SofiaNode/SofiaNode';
import { HoverDecorator } from '../../../HoverDecorator';
import { DebuggerDecorator } from '../../../DebuggerDecorator';
import { MessageNode as MessageNodeOld } from '../../../MessageNode';
import { FlowChartSendCampaignNode } from '../../../FlowChartSendCampaignNode';

const CommonWrapper = <T extends { id?: string; data?: unknown }>({ children, ...props }: PropsWithChildren<T>) => {
  return (
    <DebuggerDecorator
      data={props?.data}
      sx={{
        fontSize: '6px',
        width: 280,
      }}
      shouldExpandNodeInitially={() => true}
    >
      <HoverDecorator nodeId={props?.id || ''}>{cloneElement(children as ReactElement, props)}</HoverDecorator>
    </DebuggerDecorator>
  );
};

export const useNodeTypes = (): NodeTypes => {
  const { ffEnableNewMessageNode } = useFeatureFlag(['ffEnableNewMessageNode']);
  return useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE: ffEnableNewMessageNode
        ? (props: ComponentProps<typeof MessageNode> & { data: MessageNodeData }) => (
            <CommonWrapper {...props}>
              <MessageNode {...props} />
            </CommonWrapper>
          )
        : (props: ComponentProps<typeof MessageNodeOld>) => (
            <CommonWrapper {...props}>
              <MessageNodeOld nodeType="FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE" {...props} />
            </CommonWrapper>
          ),
      FLOW_OBJECT_TYPE_AI_ASSISTANT: (props: ComponentProps<typeof SofiaNode> & { data: unknown }) => (
        <CommonWrapper {...props}>
          <SofiaNode {...props} />
        </CommonWrapper>
      ),
      FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT: (
        props: ComponentProps<typeof FlowChartSendCampaignNode> & { data: unknown },
      ) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <FlowChartSendCampaignNode {...props} />
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_OPT_OUT: (props: ComponentProps<typeof OptOutNode> & { data: unknown }) => (
        <CommonWrapper {...props}>
          <OptOutNode {...props} />
        </CommonWrapper>
      ),
      FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT: (props: ComponentProps<typeof CustomerRepliesNode> & { data: unknown }) => (
        <CommonWrapper {...props}>
          <CustomerRepliesNode {...props} />
        </CommonWrapper>
      ),
      FLOW_OBJECT_TYPE_CALL_API: (props: ComponentProps<typeof APICallNode> & { data: unknown }) => (
        <CommonWrapper {...props}>
          <APICallNode {...props} />
        </CommonWrapper>
      ),
      FLOW_OBJECT_TYPE_TIME_DELAY: (props: ComponentProps<typeof TimeDelayNode> & { data: TimeDelayData }) => (
        <CommonWrapper {...props}>
          <TimeDelayNode {...props} />
        </CommonWrapper>
      ),
    }),
    [ffEnableNewMessageNode],
  );
};
