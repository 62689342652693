import { ChannelIdentifier, channelTypeFromJSON, channelTypeToJSON, } from "../../common/channel/models";
import { mutationStatusFromJSON, mutationStatusToJSON, } from "../../common/api/models";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { CampaignRewardForContact as CampaignRewardForContact1 } from "../../widget/models";
import { MessageTemplate } from "../../messaging/template/models";
import { Attachment, ProgressStatus } from "../../event/connectly/models";
import { MessageTemplate as MessageTemplate2 } from "../../../events/facebook/graphapi/v12_0/whatsapp/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "models.crm.campaign";
export var CampaignType;
(function (CampaignType) {
    CampaignType[CampaignType["CAMPAIGN_TYPE_UNSPECIFIED"] = 0] = "CAMPAIGN_TYPE_UNSPECIFIED";
    CampaignType[CampaignType["CAMPAIGN_TYPE_REWARD_FOR_CONTACT"] = 1] = "CAMPAIGN_TYPE_REWARD_FOR_CONTACT";
    CampaignType[CampaignType["CAMPAIGN_TYPE_MARKETING"] = 2] = "CAMPAIGN_TYPE_MARKETING";
    CampaignType[CampaignType["CAMPAIGN_TYPE_CSAT"] = 3] = "CAMPAIGN_TYPE_CSAT";
    CampaignType[CampaignType["CAMPAIGN_TYPE_CUSTOM"] = 4] = "CAMPAIGN_TYPE_CUSTOM";
    CampaignType[CampaignType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CampaignType || (CampaignType = {}));
export function campaignTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "CAMPAIGN_TYPE_UNSPECIFIED":
            return CampaignType.CAMPAIGN_TYPE_UNSPECIFIED;
        case 1:
        case "CAMPAIGN_TYPE_REWARD_FOR_CONTACT":
            return CampaignType.CAMPAIGN_TYPE_REWARD_FOR_CONTACT;
        case 2:
        case "CAMPAIGN_TYPE_MARKETING":
            return CampaignType.CAMPAIGN_TYPE_MARKETING;
        case 3:
        case "CAMPAIGN_TYPE_CSAT":
            return CampaignType.CAMPAIGN_TYPE_CSAT;
        case 4:
        case "CAMPAIGN_TYPE_CUSTOM":
            return CampaignType.CAMPAIGN_TYPE_CUSTOM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignType.UNRECOGNIZED;
    }
}
export function campaignTypeToJSON(object) {
    switch (object) {
        case CampaignType.CAMPAIGN_TYPE_UNSPECIFIED:
            return "CAMPAIGN_TYPE_UNSPECIFIED";
        case CampaignType.CAMPAIGN_TYPE_REWARD_FOR_CONTACT:
            return "CAMPAIGN_TYPE_REWARD_FOR_CONTACT";
        case CampaignType.CAMPAIGN_TYPE_MARKETING:
            return "CAMPAIGN_TYPE_MARKETING";
        case CampaignType.CAMPAIGN_TYPE_CSAT:
            return "CAMPAIGN_TYPE_CSAT";
        case CampaignType.CAMPAIGN_TYPE_CUSTOM:
            return "CAMPAIGN_TYPE_CUSTOM";
        case CampaignType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var CampaignInputType;
(function (CampaignInputType) {
    CampaignInputType[CampaignInputType["CAMPAIGN_INPUT_TYPE_UNSPECIFIED"] = 0] = "CAMPAIGN_INPUT_TYPE_UNSPECIFIED";
    CampaignInputType[CampaignInputType["CAMPAIGN_INPUT_TYPE_SPREADSHEET"] = 1] = "CAMPAIGN_INPUT_TYPE_SPREADSHEET";
    CampaignInputType[CampaignInputType["CAMPAIGN_INPUT_TYPE_CSV"] = 2] = "CAMPAIGN_INPUT_TYPE_CSV";
    CampaignInputType[CampaignInputType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CampaignInputType || (CampaignInputType = {}));
export function campaignInputTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "CAMPAIGN_INPUT_TYPE_UNSPECIFIED":
            return CampaignInputType.CAMPAIGN_INPUT_TYPE_UNSPECIFIED;
        case 1:
        case "CAMPAIGN_INPUT_TYPE_SPREADSHEET":
            return CampaignInputType.CAMPAIGN_INPUT_TYPE_SPREADSHEET;
        case 2:
        case "CAMPAIGN_INPUT_TYPE_CSV":
            return CampaignInputType.CAMPAIGN_INPUT_TYPE_CSV;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignInputType.UNRECOGNIZED;
    }
}
export function campaignInputTypeToJSON(object) {
    switch (object) {
        case CampaignInputType.CAMPAIGN_INPUT_TYPE_UNSPECIFIED:
            return "CAMPAIGN_INPUT_TYPE_UNSPECIFIED";
        case CampaignInputType.CAMPAIGN_INPUT_TYPE_SPREADSHEET:
            return "CAMPAIGN_INPUT_TYPE_SPREADSHEET";
        case CampaignInputType.CAMPAIGN_INPUT_TYPE_CSV:
            return "CAMPAIGN_INPUT_TYPE_CSV";
        case CampaignInputType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var CampaignState;
(function (CampaignState) {
    CampaignState[CampaignState["CAMPAIGN_STATE_UNSPECIFIED"] = 0] = "CAMPAIGN_STATE_UNSPECIFIED";
    CampaignState[CampaignState["CAMPAIGN_STATE_INACTIVE"] = 2] = "CAMPAIGN_STATE_INACTIVE";
    CampaignState[CampaignState["CAMPAIGN_STATE_ACTIVE"] = 3] = "CAMPAIGN_STATE_ACTIVE";
    CampaignState[CampaignState["CAMPAIGN_STATE_DRAFT"] = 4] = "CAMPAIGN_STATE_DRAFT";
    CampaignState[CampaignState["CAMPAIGN_STATE_COMPLETED"] = 5] = "CAMPAIGN_STATE_COMPLETED";
    CampaignState[CampaignState["CAMPAIGN_STATE_ABORTED"] = 6] = "CAMPAIGN_STATE_ABORTED";
    CampaignState[CampaignState["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CampaignState || (CampaignState = {}));
export function campaignStateFromJSON(object) {
    switch (object) {
        case 0:
        case "CAMPAIGN_STATE_UNSPECIFIED":
            return CampaignState.CAMPAIGN_STATE_UNSPECIFIED;
        case 2:
        case "CAMPAIGN_STATE_INACTIVE":
            return CampaignState.CAMPAIGN_STATE_INACTIVE;
        case 3:
        case "CAMPAIGN_STATE_ACTIVE":
            return CampaignState.CAMPAIGN_STATE_ACTIVE;
        case 4:
        case "CAMPAIGN_STATE_DRAFT":
            return CampaignState.CAMPAIGN_STATE_DRAFT;
        case 5:
        case "CAMPAIGN_STATE_COMPLETED":
            return CampaignState.CAMPAIGN_STATE_COMPLETED;
        case 6:
        case "CAMPAIGN_STATE_ABORTED":
            return CampaignState.CAMPAIGN_STATE_ABORTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignState.UNRECOGNIZED;
    }
}
export function campaignStateToJSON(object) {
    switch (object) {
        case CampaignState.CAMPAIGN_STATE_UNSPECIFIED:
            return "CAMPAIGN_STATE_UNSPECIFIED";
        case CampaignState.CAMPAIGN_STATE_INACTIVE:
            return "CAMPAIGN_STATE_INACTIVE";
        case CampaignState.CAMPAIGN_STATE_ACTIVE:
            return "CAMPAIGN_STATE_ACTIVE";
        case CampaignState.CAMPAIGN_STATE_DRAFT:
            return "CAMPAIGN_STATE_DRAFT";
        case CampaignState.CAMPAIGN_STATE_COMPLETED:
            return "CAMPAIGN_STATE_COMPLETED";
        case CampaignState.CAMPAIGN_STATE_ABORTED:
            return "CAMPAIGN_STATE_ABORTED";
        case CampaignState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCampaignConfig() {
    return {
        configOneof: undefined,
        inputCohortUrl: "",
        resultCohortUrl: "",
        inputType: 0,
        numRecipients: 0,
    };
}
export const CampaignConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "rewardForContact") {
            CampaignRewardForContact.encode(message.configOneof.rewardForContact, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.$case) === "marketing") {
            CampaignMarketing.encode(message.configOneof.marketing, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.$case) === "broadcast") {
            CampaignBroadcast.encode(message.configOneof.broadcast, writer.uint32(26).fork()).ldelim();
        }
        if (message.inputCohortUrl !== "") {
            writer.uint32(778).string(message.inputCohortUrl);
        }
        if (message.resultCohortUrl !== "") {
            writer.uint32(786).string(message.resultCohortUrl);
        }
        if (message.inputType !== 0) {
            writer.uint32(792).int32(message.inputType);
        }
        if (message.numRecipients !== 0) {
            writer.uint32(800).int32(message.numRecipients);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "rewardForContact",
                        rewardForContact: CampaignRewardForContact.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.configOneof = {
                        $case: "marketing",
                        marketing: CampaignMarketing.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.configOneof = {
                        $case: "broadcast",
                        broadcast: CampaignBroadcast.decode(reader, reader.uint32()),
                    };
                    break;
                case 97:
                    message.inputCohortUrl = reader.string();
                    break;
                case 98:
                    message.resultCohortUrl = reader.string();
                    break;
                case 99:
                    message.inputType = reader.int32();
                    break;
                case 100:
                    message.numRecipients = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.rewardForContact)
                ? {
                    $case: "rewardForContact",
                    rewardForContact: CampaignRewardForContact.fromJSON(object.rewardForContact),
                }
                : isSet(object.marketing)
                    ? {
                        $case: "marketing",
                        marketing: CampaignMarketing.fromJSON(object.marketing),
                    }
                    : isSet(object.broadcast)
                        ? {
                            $case: "broadcast",
                            broadcast: CampaignBroadcast.fromJSON(object.broadcast),
                        }
                        : undefined,
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
            resultCohortUrl: isSet(object.resultCohortUrl)
                ? String(object.resultCohortUrl)
                : "",
            inputType: isSet(object.inputType)
                ? campaignInputTypeFromJSON(object.inputType)
                : 0,
            numRecipients: isSet(object.numRecipients)
                ? Number(object.numRecipients)
                : 0,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "rewardForContact" &&
            (obj.rewardForContact = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.rewardForContact)
                ? CampaignRewardForContact.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.rewardForContact)
                : undefined);
        ((_d = message.configOneof) === null || _d === void 0 ? void 0 : _d.$case) === "marketing" &&
            (obj.marketing = ((_e = message.configOneof) === null || _e === void 0 ? void 0 : _e.marketing)
                ? CampaignMarketing.toJSON((_f = message.configOneof) === null || _f === void 0 ? void 0 : _f.marketing)
                : undefined);
        ((_g = message.configOneof) === null || _g === void 0 ? void 0 : _g.$case) === "broadcast" &&
            (obj.broadcast = ((_h = message.configOneof) === null || _h === void 0 ? void 0 : _h.broadcast)
                ? CampaignBroadcast.toJSON((_j = message.configOneof) === null || _j === void 0 ? void 0 : _j.broadcast)
                : undefined);
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        message.resultCohortUrl !== undefined &&
            (obj.resultCohortUrl = message.resultCohortUrl);
        message.inputType !== undefined &&
            (obj.inputType = campaignInputTypeToJSON(message.inputType));
        message.numRecipients !== undefined &&
            (obj.numRecipients = Math.round(message.numRecipients));
        return obj;
    },
};
function createBaseCampaignOutcome() {
    return { outcomeOneof: undefined };
}
export const CampaignOutcome = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1") {
            ReportV1.encode(message.outcomeOneof.reportV1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignOutcome();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.outcomeOneof = {
                        $case: "reportV1",
                        reportV1: ReportV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            outcomeOneof: isSet(object.reportV1)
                ? { $case: "reportV1", reportV1: ReportV1.fromJSON(object.reportV1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1" &&
            (obj.reportV1 = ((_b = message.outcomeOneof) === null || _b === void 0 ? void 0 : _b.reportV1)
                ? ReportV1.toJSON((_c = message.outcomeOneof) === null || _c === void 0 ? void 0 : _c.reportV1)
                : undefined);
        return obj;
    },
};
function createBaseCampaignRewardForContact() {
    return { widgetConfig: undefined, discountCode: "", messageTemplate: "" };
}
export const CampaignRewardForContact = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.widgetConfig !== undefined) {
            CampaignRewardForContact1.encode(message.widgetConfig, writer.uint32(10).fork()).ldelim();
        }
        if (message.discountCode !== "") {
            writer.uint32(18).string(message.discountCode);
        }
        if (message.messageTemplate !== "") {
            writer.uint32(26).string(message.messageTemplate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignRewardForContact();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.widgetConfig = CampaignRewardForContact1.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.discountCode = reader.string();
                    break;
                case 3:
                    message.messageTemplate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            widgetConfig: isSet(object.widgetConfig)
                ? CampaignRewardForContact1.fromJSON(object.widgetConfig)
                : undefined,
            discountCode: isSet(object.discountCode)
                ? String(object.discountCode)
                : "",
            messageTemplate: isSet(object.messageTemplate)
                ? String(object.messageTemplate)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.widgetConfig !== undefined &&
            (obj.widgetConfig = message.widgetConfig
                ? CampaignRewardForContact1.toJSON(message.widgetConfig)
                : undefined);
        message.discountCode !== undefined &&
            (obj.discountCode = message.discountCode);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate);
        return obj;
    },
};
function createBaseWABroadcastTemplateV1() {
    return {
        waMessageTemplateId: "",
        gsheetsSpreadsheetId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
        messageTemplate: undefined,
    };
}
export const WABroadcastTemplateV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(18).string(message.gsheetsSpreadsheetId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(26).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(34).string(message.waMessageTemplateLanguage);
        }
        if (message.messageTemplate !== undefined) {
            MessageTemplate.encode(message.messageTemplate, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWABroadcastTemplateV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 2:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 4:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                case 5:
                    message.messageTemplate = MessageTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
            messageTemplate: isSet(object.messageTemplate)
                ? MessageTemplate.fromJSON(object.messageTemplate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate
                ? MessageTemplate.toJSON(message.messageTemplate)
                : undefined);
        return obj;
    },
};
function createBaseSTBBroadcastTemplateV1() {
    return { gsheetsSpreadsheetId: "", messageTemplates: [] };
}
export const STBBroadcastTemplateV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(10).string(message.gsheetsSpreadsheetId);
        }
        for (const v of message.messageTemplates) {
            STBBroadcastTemplateV1_MessageTemplateIdentifier.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastTemplateV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 2:
                    message.messageTemplates.push(STBBroadcastTemplateV1_MessageTemplateIdentifier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            messageTemplates: Array.isArray(object === null || object === void 0 ? void 0 : object.messageTemplates)
                ? object.messageTemplates.map((e) => STBBroadcastTemplateV1_MessageTemplateIdentifier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        if (message.messageTemplates) {
            obj.messageTemplates = message.messageTemplates.map((e) => e
                ? STBBroadcastTemplateV1_MessageTemplateIdentifier.toJSON(e)
                : undefined);
        }
        else {
            obj.messageTemplates = [];
        }
        return obj;
    },
};
function createBaseSTBBroadcastTemplateV1_MessageTemplateIdentifier() {
    return {
        waMessageTemplateId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
        stbName: "",
        messageTemplate: undefined,
    };
}
export const STBBroadcastTemplateV1_MessageTemplateIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(18).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(26).string(message.waMessageTemplateLanguage);
        }
        if (message.stbName !== "") {
            writer.uint32(34).string(message.stbName);
        }
        if (message.messageTemplate !== undefined) {
            MessageTemplate.encode(message.messageTemplate, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastTemplateV1_MessageTemplateIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 2:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                case 4:
                    message.stbName = reader.string();
                    break;
                case 5:
                    message.messageTemplate = MessageTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
            stbName: isSet(object.stbName) ? String(object.stbName) : "",
            messageTemplate: isSet(object.messageTemplate)
                ? MessageTemplate.fromJSON(object.messageTemplate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        message.stbName !== undefined && (obj.stbName = message.stbName);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate
                ? MessageTemplate.toJSON(message.messageTemplate)
                : undefined);
        return obj;
    },
};
function createBaseCampaignBroadcast() {
    return {
        cohortCsv: "",
        messageTemplate: "",
        attachments: [],
        audienceChannel: undefined,
        templateOneof: undefined,
        tagId: undefined,
        audienceSegmentId: "",
    };
}
export const CampaignBroadcast = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.cohortCsv !== "") {
            writer.uint32(10).string(message.cohortCsv);
        }
        if (message.messageTemplate !== "") {
            writer.uint32(18).string(message.messageTemplate);
        }
        for (const v of message.attachments) {
            Attachment.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.audienceChannel !== undefined) {
            ChannelIdentifier.encode(message.audienceChannel, writer.uint32(34).fork()).ldelim();
        }
        if (((_a = message.templateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcastTemplateV1") {
            WABroadcastTemplateV1.encode(message.templateOneof.waBroadcastTemplateV1, writer.uint32(42).fork()).ldelim();
        }
        if (((_b = message.templateOneof) === null || _b === void 0 ? void 0 : _b.$case) === "stbBroadcastTemplateV1") {
            STBBroadcastTemplateV1.encode(message.templateOneof.stbBroadcastTemplateV1, writer.uint32(50).fork()).ldelim();
        }
        if (message.tagId !== undefined) {
            StringValue.encode({ value: message.tagId }, writer.uint32(58).fork()).ldelim();
        }
        if (message.audienceSegmentId !== "") {
            writer.uint32(66).string(message.audienceSegmentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignBroadcast();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cohortCsv = reader.string();
                    break;
                case 2:
                    message.messageTemplate = reader.string();
                    break;
                case 3:
                    message.attachments.push(Attachment.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.audienceChannel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.templateOneof = {
                        $case: "waBroadcastTemplateV1",
                        waBroadcastTemplateV1: WABroadcastTemplateV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.templateOneof = {
                        $case: "stbBroadcastTemplateV1",
                        stbBroadcastTemplateV1: STBBroadcastTemplateV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.tagId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.audienceSegmentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cohortCsv: isSet(object.cohortCsv) ? String(object.cohortCsv) : "",
            messageTemplate: isSet(object.messageTemplate)
                ? String(object.messageTemplate)
                : "",
            attachments: Array.isArray(object === null || object === void 0 ? void 0 : object.attachments)
                ? object.attachments.map((e) => Attachment.fromJSON(e))
                : [],
            audienceChannel: isSet(object.audienceChannel)
                ? ChannelIdentifier.fromJSON(object.audienceChannel)
                : undefined,
            templateOneof: isSet(object.waBroadcastTemplateV1)
                ? {
                    $case: "waBroadcastTemplateV1",
                    waBroadcastTemplateV1: WABroadcastTemplateV1.fromJSON(object.waBroadcastTemplateV1),
                }
                : isSet(object.stbBroadcastTemplateV1)
                    ? {
                        $case: "stbBroadcastTemplateV1",
                        stbBroadcastTemplateV1: STBBroadcastTemplateV1.fromJSON(object.stbBroadcastTemplateV1),
                    }
                    : undefined,
            tagId: isSet(object.tagId) ? String(object.tagId) : undefined,
            audienceSegmentId: isSet(object.audienceSegmentId)
                ? String(object.audienceSegmentId)
                : "",
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        message.cohortCsv !== undefined && (obj.cohortCsv = message.cohortCsv);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate);
        if (message.attachments) {
            obj.attachments = message.attachments.map((e) => e ? Attachment.toJSON(e) : undefined);
        }
        else {
            obj.attachments = [];
        }
        message.audienceChannel !== undefined &&
            (obj.audienceChannel = message.audienceChannel
                ? ChannelIdentifier.toJSON(message.audienceChannel)
                : undefined);
        ((_a = message.templateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcastTemplateV1" &&
            (obj.waBroadcastTemplateV1 = ((_b = message.templateOneof) === null || _b === void 0 ? void 0 : _b.waBroadcastTemplateV1)
                ? WABroadcastTemplateV1.toJSON((_c = message.templateOneof) === null || _c === void 0 ? void 0 : _c.waBroadcastTemplateV1)
                : undefined);
        ((_d = message.templateOneof) === null || _d === void 0 ? void 0 : _d.$case) === "stbBroadcastTemplateV1" &&
            (obj.stbBroadcastTemplateV1 = ((_e = message.templateOneof) === null || _e === void 0 ? void 0 : _e.stbBroadcastTemplateV1)
                ? STBBroadcastTemplateV1.toJSON((_f = message.templateOneof) === null || _f === void 0 ? void 0 : _f.stbBroadcastTemplateV1)
                : undefined);
        message.tagId !== undefined && (obj.tagId = message.tagId);
        message.audienceSegmentId !== undefined &&
            (obj.audienceSegmentId = message.audienceSegmentId);
        return obj;
    },
};
function createBaseCampaignMarketing() {
    return { discountCode: "", filters: [], cohortCsv: "", smsConfig: undefined };
}
export const CampaignMarketing = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.discountCode !== "") {
            writer.uint32(10).string(message.discountCode);
        }
        for (const v of message.filters) {
            CampaignMarketingFilter.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.cohortCsv !== "") {
            writer.uint32(26).string(message.cohortCsv);
        }
        if (message.smsConfig !== undefined) {
            SMSMarketingConfig.encode(message.smsConfig, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignMarketing();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.discountCode = reader.string();
                    break;
                case 2:
                    message.filters.push(CampaignMarketingFilter.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.cohortCsv = reader.string();
                    break;
                case 10:
                    message.smsConfig = SMSMarketingConfig.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            discountCode: isSet(object.discountCode)
                ? String(object.discountCode)
                : "",
            filters: Array.isArray(object === null || object === void 0 ? void 0 : object.filters)
                ? object.filters.map((e) => CampaignMarketingFilter.fromJSON(e))
                : [],
            cohortCsv: isSet(object.cohortCsv) ? String(object.cohortCsv) : "",
            smsConfig: isSet(object.smsConfig)
                ? SMSMarketingConfig.fromJSON(object.smsConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.discountCode !== undefined &&
            (obj.discountCode = message.discountCode);
        if (message.filters) {
            obj.filters = message.filters.map((e) => e ? CampaignMarketingFilter.toJSON(e) : undefined);
        }
        else {
            obj.filters = [];
        }
        message.cohortCsv !== undefined && (obj.cohortCsv = message.cohortCsv);
        message.smsConfig !== undefined &&
            (obj.smsConfig = message.smsConfig
                ? SMSMarketingConfig.toJSON(message.smsConfig)
                : undefined);
        return obj;
    },
};
function createBaseSMSMarketingConfig() {
    return { messageTemplate: "" };
}
export const SMSMarketingConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messageTemplate !== "") {
            writer.uint32(10).string(message.messageTemplate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSMSMarketingConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageTemplate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messageTemplate: isSet(object.messageTemplate)
                ? String(object.messageTemplate)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate);
        return obj;
    },
};
function createBaseCampaignMarketingFilter() {
    return { filterOneof: undefined };
}
export const CampaignMarketingFilter = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.filterOneof) === null || _a === void 0 ? void 0 : _a.$case) === "tagFilter") {
            CampaignMarketingFilterByTag.encode(message.filterOneof.tagFilter, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignMarketingFilter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.filterOneof = {
                        $case: "tagFilter",
                        tagFilter: CampaignMarketingFilterByTag.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            filterOneof: isSet(object.tagFilter)
                ? {
                    $case: "tagFilter",
                    tagFilter: CampaignMarketingFilterByTag.fromJSON(object.tagFilter),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.filterOneof) === null || _a === void 0 ? void 0 : _a.$case) === "tagFilter" &&
            (obj.tagFilter = ((_b = message.filterOneof) === null || _b === void 0 ? void 0 : _b.tagFilter)
                ? CampaignMarketingFilterByTag.toJSON((_c = message.filterOneof) === null || _c === void 0 ? void 0 : _c.tagFilter)
                : undefined);
        return obj;
    },
};
function createBaseCampaignMarketingFilterByTag() {
    return { tags: [] };
}
export const CampaignMarketingFilterByTag = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tags) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignMarketingFilterByTag();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tags.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tags: Array.isArray(object === null || object === void 0 ? void 0 : object.tags)
                ? object.tags.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tags) {
            obj.tags = message.tags.map((e) => e);
        }
        else {
            obj.tags = [];
        }
        return obj;
    },
};
function createBaseReportV1() {
    return { url: "" };
}
export const ReportV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReportV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        return obj;
    },
};
function createBaseSMSBroadcastV1() {
    return {};
}
export const SMSBroadcastV1 = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSMSBroadcastV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseWAMessageTemplateCollection() {
    return { templates: [] };
}
export const WAMessageTemplateCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.templates) {
            MessageTemplate2.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWAMessageTemplateCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templates.push(MessageTemplate2.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templates: Array.isArray(object === null || object === void 0 ? void 0 : object.templates)
                ? object.templates.map((e) => MessageTemplate2.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.templates) {
            obj.templates = message.templates.map((e) => e ? MessageTemplate2.toJSON(e) : undefined);
        }
        else {
            obj.templates = [];
        }
        return obj;
    },
};
function createBaseWABroadcastV1() {
    return { waMessageTemplateId: "" };
}
export const WABroadcastV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWABroadcastV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        return obj;
    },
};
function createBaseSTBBroadcastV1() {
    return { messageTemplates: [] };
}
export const STBBroadcastV1 = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.messageTemplates) {
            STBBroadcastV1_MessageTemplateIdentifier.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageTemplates.push(STBBroadcastV1_MessageTemplateIdentifier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messageTemplates: Array.isArray(object === null || object === void 0 ? void 0 : object.messageTemplates)
                ? object.messageTemplates.map((e) => STBBroadcastV1_MessageTemplateIdentifier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.messageTemplates) {
            obj.messageTemplates = message.messageTemplates.map((e) => e ? STBBroadcastV1_MessageTemplateIdentifier.toJSON(e) : undefined);
        }
        else {
            obj.messageTemplates = [];
        }
        return obj;
    },
};
function createBaseSTBBroadcastV1_MessageTemplateIdentifier() {
    return { waMessageTemplateId: "", stbName: "" };
}
export const STBBroadcastV1_MessageTemplateIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.stbName !== "") {
            writer.uint32(34).string(message.stbName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastV1_MessageTemplateIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 4:
                    message.stbName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            stbName: isSet(object.stbName) ? String(object.stbName) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.stbName !== undefined && (obj.stbName = message.stbName);
        return obj;
    },
};
function createBaseCampaignConfigInput() {
    return { configOneof: undefined, inputType: 0 };
}
export const CampaignConfigInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcast") {
            WABroadcastV1.encode(message.configOneof.waBroadcast, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.$case) === "stbBroadcastV1") {
            STBBroadcastV1.encode(message.configOneof.stbBroadcastV1, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.$case) === "smsBroadcastV1") {
            SMSBroadcastV1.encode(message.configOneof.smsBroadcastV1, writer.uint32(26).fork()).ldelim();
        }
        if (message.inputType !== 0) {
            writer.uint32(792).int32(message.inputType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignConfigInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "waBroadcast",
                        waBroadcast: WABroadcastV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.configOneof = {
                        $case: "stbBroadcastV1",
                        stbBroadcastV1: STBBroadcastV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.configOneof = {
                        $case: "smsBroadcastV1",
                        smsBroadcastV1: SMSBroadcastV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 99:
                    message.inputType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.waBroadcast)
                ? {
                    $case: "waBroadcast",
                    waBroadcast: WABroadcastV1.fromJSON(object.waBroadcast),
                }
                : isSet(object.stbBroadcastV1)
                    ? {
                        $case: "stbBroadcastV1",
                        stbBroadcastV1: STBBroadcastV1.fromJSON(object.stbBroadcastV1),
                    }
                    : isSet(object.smsBroadcastV1)
                        ? {
                            $case: "smsBroadcastV1",
                            smsBroadcastV1: SMSBroadcastV1.fromJSON(object.smsBroadcastV1),
                        }
                        : undefined,
            inputType: isSet(object.inputType)
                ? campaignInputTypeFromJSON(object.inputType)
                : 0,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcast" &&
            (obj.waBroadcast = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.waBroadcast)
                ? WABroadcastV1.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.waBroadcast)
                : undefined);
        ((_d = message.configOneof) === null || _d === void 0 ? void 0 : _d.$case) === "stbBroadcastV1" &&
            (obj.stbBroadcastV1 = ((_e = message.configOneof) === null || _e === void 0 ? void 0 : _e.stbBroadcastV1)
                ? STBBroadcastV1.toJSON((_f = message.configOneof) === null || _f === void 0 ? void 0 : _f.stbBroadcastV1)
                : undefined);
        ((_g = message.configOneof) === null || _g === void 0 ? void 0 : _g.$case) === "smsBroadcastV1" &&
            (obj.smsBroadcastV1 = ((_h = message.configOneof) === null || _h === void 0 ? void 0 : _h.smsBroadcastV1)
                ? SMSBroadcastV1.toJSON((_j = message.configOneof) === null || _j === void 0 ? void 0 : _j.smsBroadcastV1)
                : undefined);
        message.inputType !== undefined &&
            (obj.inputType = campaignInputTypeToJSON(message.inputType));
        return obj;
    },
};
function createBaseInitializeCampaignInput() {
    return {
        id: "",
        businessId: "",
        name: "",
        configName: "",
        type: 0,
        audienceChannelType: 0,
        config: undefined,
        audienceSegmentId: "",
    };
}
export const InitializeCampaignInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.configName !== "") {
            writer.uint32(58).string(message.configName);
        }
        if (message.type !== 0) {
            writer.uint32(32).int32(message.type);
        }
        if (message.audienceChannelType !== 0) {
            writer.uint32(40).int32(message.audienceChannelType);
        }
        if (message.config !== undefined) {
            CampaignConfigInput.encode(message.config, writer.uint32(50).fork()).ldelim();
        }
        if (message.audienceSegmentId !== "") {
            writer.uint32(66).string(message.audienceSegmentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInitializeCampaignInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 7:
                    message.configName = reader.string();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.audienceChannelType = reader.int32();
                    break;
                case 6:
                    message.config = CampaignConfigInput.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.audienceSegmentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            name: isSet(object.name) ? String(object.name) : "",
            configName: isSet(object.configName) ? String(object.configName) : "",
            type: isSet(object.type) ? campaignTypeFromJSON(object.type) : 0,
            audienceChannelType: isSet(object.audienceChannelType)
                ? channelTypeFromJSON(object.audienceChannelType)
                : 0,
            config: isSet(object.config)
                ? CampaignConfigInput.fromJSON(object.config)
                : undefined,
            audienceSegmentId: isSet(object.audienceSegmentId)
                ? String(object.audienceSegmentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.name !== undefined && (obj.name = message.name);
        message.configName !== undefined && (obj.configName = message.configName);
        message.type !== undefined && (obj.type = campaignTypeToJSON(message.type));
        message.audienceChannelType !== undefined &&
            (obj.audienceChannelType = channelTypeToJSON(message.audienceChannelType));
        message.config !== undefined &&
            (obj.config = message.config
                ? CampaignConfigInput.toJSON(message.config)
                : undefined);
        message.audienceSegmentId !== undefined &&
            (obj.audienceSegmentId = message.audienceSegmentId);
        return obj;
    },
};
function createBaseVerifyCampaignResult() {
    return {
        status: 0,
        message: "",
        entity: undefined,
        progress: undefined,
        startedAt: undefined,
        completedAt: undefined,
    };
}
export const VerifyCampaignResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.entity !== undefined) {
            CampaignDto.encode(message.entity, writer.uint32(26).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(34).fork()).ldelim();
        }
        if (message.startedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyCampaignResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.entity = CampaignDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            entity: isSet(object.entity)
                ? CampaignDto.fromJSON(object.entity)
                : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            startedAt: isSet(object.startedAt) ? String(object.startedAt) : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? CampaignDto.toJSON(message.entity)
                : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        message.startedAt !== undefined && (obj.startedAt = message.startedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        return obj;
    },
};
function createBaseSubmitCampaignResult() {
    return {
        status: 0,
        message: "",
        entity: undefined,
        progress: undefined,
        startedAt: undefined,
        completedAt: undefined,
    };
}
export const SubmitCampaignResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.entity !== undefined) {
            CampaignDto.encode(message.entity, writer.uint32(26).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(34).fork()).ldelim();
        }
        if (message.startedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitCampaignResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.entity = CampaignDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            entity: isSet(object.entity)
                ? CampaignDto.fromJSON(object.entity)
                : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            startedAt: isSet(object.startedAt) ? String(object.startedAt) : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? CampaignDto.toJSON(message.entity)
                : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        message.startedAt !== undefined && (obj.startedAt = message.startedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        return obj;
    },
};
function createBaseCampaignDtos() {
    return { campaigns: [] };
}
export const CampaignDtos = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.campaigns) {
            CampaignDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignDtos();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaigns.push(CampaignDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaigns: Array.isArray(object === null || object === void 0 ? void 0 : object.campaigns)
                ? object.campaigns.map((e) => CampaignDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.campaigns) {
            obj.campaigns = message.campaigns.map((e) => e ? CampaignDto.toJSON(e) : undefined);
        }
        else {
            obj.campaigns = [];
        }
        return obj;
    },
};
function createBaseCampaignDto() {
    return {
        id: "",
        businessId: "",
        name: "",
        configName: "",
        groupId: "",
        config: undefined,
        type: 0,
        state: 0,
        startDate: undefined,
        endDate: undefined,
        outcome: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        verifiedAt: undefined,
        submittedAt: undefined,
        scheduledAt: undefined,
        dispatchedAt: undefined,
        completedAt: undefined,
        cancelledAt: undefined,
    };
}
export const CampaignDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.configName !== "") {
            writer.uint32(154).string(message.configName);
        }
        if (message.groupId !== "") {
            writer.uint32(82).string(message.groupId);
        }
        if (message.config !== undefined) {
            CampaignConfig.encode(message.config, writer.uint32(34).fork()).ldelim();
        }
        if (message.type !== 0) {
            writer.uint32(40).int32(message.type);
        }
        if (message.state !== 0) {
            writer.uint32(48).int32(message.state);
        }
        if (message.startDate !== undefined) {
            Timestamp.encode(toTimestamp(message.startDate), writer.uint32(58).fork()).ldelim();
        }
        if (message.endDate !== undefined) {
            Timestamp.encode(toTimestamp(message.endDate), writer.uint32(66).fork()).ldelim();
        }
        if (message.outcome !== undefined) {
            CampaignOutcome.encode(message.outcome, writer.uint32(74).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(90).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(98).fork()).ldelim();
        }
        if (message.verifiedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.verifiedAt), writer.uint32(106).fork()).ldelim();
        }
        if (message.submittedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.submittedAt), writer.uint32(114).fork()).ldelim();
        }
        if (message.scheduledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.scheduledAt), writer.uint32(122).fork()).ldelim();
        }
        if (message.dispatchedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.dispatchedAt), writer.uint32(130).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(138).fork()).ldelim();
        }
        if (message.cancelledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.cancelledAt), writer.uint32(146).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 19:
                    message.configName = reader.string();
                    break;
                case 10:
                    message.groupId = reader.string();
                    break;
                case 4:
                    message.config = CampaignConfig.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.state = reader.int32();
                    break;
                case 7:
                    message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.outcome = CampaignOutcome.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.verifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.submittedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 15:
                    message.scheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.dispatchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 18:
                    message.cancelledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            name: isSet(object.name) ? String(object.name) : "",
            configName: isSet(object.configName) ? String(object.configName) : "",
            groupId: isSet(object.groupId) ? String(object.groupId) : "",
            config: isSet(object.config)
                ? CampaignConfig.fromJSON(object.config)
                : undefined,
            type: isSet(object.type) ? campaignTypeFromJSON(object.type) : 0,
            state: isSet(object.state) ? campaignStateFromJSON(object.state) : 0,
            startDate: isSet(object.startDate) ? String(object.startDate) : undefined,
            endDate: isSet(object.endDate) ? String(object.endDate) : undefined,
            outcome: isSet(object.outcome)
                ? CampaignOutcome.fromJSON(object.outcome)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            verifiedAt: isSet(object.verifiedAt)
                ? String(object.verifiedAt)
                : undefined,
            submittedAt: isSet(object.submittedAt)
                ? String(object.submittedAt)
                : undefined,
            scheduledAt: isSet(object.scheduledAt)
                ? String(object.scheduledAt)
                : undefined,
            dispatchedAt: isSet(object.dispatchedAt)
                ? String(object.dispatchedAt)
                : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
            cancelledAt: isSet(object.cancelledAt)
                ? String(object.cancelledAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.name !== undefined && (obj.name = message.name);
        message.configName !== undefined && (obj.configName = message.configName);
        message.groupId !== undefined && (obj.groupId = message.groupId);
        message.config !== undefined &&
            (obj.config = message.config
                ? CampaignConfig.toJSON(message.config)
                : undefined);
        message.type !== undefined && (obj.type = campaignTypeToJSON(message.type));
        message.state !== undefined &&
            (obj.state = campaignStateToJSON(message.state));
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        message.outcome !== undefined &&
            (obj.outcome = message.outcome
                ? CampaignOutcome.toJSON(message.outcome)
                : undefined);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.verifiedAt !== undefined && (obj.verifiedAt = message.verifiedAt);
        message.submittedAt !== undefined &&
            (obj.submittedAt = message.submittedAt);
        message.scheduledAt !== undefined &&
            (obj.scheduledAt = message.scheduledAt);
        message.dispatchedAt !== undefined &&
            (obj.dispatchedAt = message.dispatchedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        message.cancelledAt !== undefined &&
            (obj.cancelledAt = message.cancelledAt);
        return obj;
    },
};
function createBaseVerifyCampaign() {
    return { inputCohortUrl: "", revision: "" };
}
export const VerifyCampaign = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.inputCohortUrl !== "") {
            writer.uint32(10).string(message.inputCohortUrl);
        }
        if (message.revision !== "") {
            writer.uint32(810).string(message.revision);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputCohortUrl = reader.string();
                    break;
                case 101:
                    message.revision = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
            revision: isSet(object.revision) ? String(object.revision) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        message.revision !== undefined && (obj.revision = message.revision);
        return obj;
    },
};
function createBaseSubmitCampaign() {
    return { submitOneof: undefined, revision: "" };
}
export const SubmitCampaign = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.submitOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now") {
            SendCampaignNow.encode(message.submitOneof.now, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.submitOneof) === null || _b === void 0 ? void 0 : _b.$case) === "schedule") {
            ScheduleCampaign.encode(message.submitOneof.schedule, writer.uint32(18).fork()).ldelim();
        }
        if (message.revision !== "") {
            writer.uint32(810).string(message.revision);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.submitOneof = {
                        $case: "now",
                        now: SendCampaignNow.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.submitOneof = {
                        $case: "schedule",
                        schedule: ScheduleCampaign.decode(reader, reader.uint32()),
                    };
                    break;
                case 101:
                    message.revision = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            submitOneof: isSet(object.now)
                ? { $case: "now", now: SendCampaignNow.fromJSON(object.now) }
                : isSet(object.schedule)
                    ? {
                        $case: "schedule",
                        schedule: ScheduleCampaign.fromJSON(object.schedule),
                    }
                    : undefined,
            revision: isSet(object.revision) ? String(object.revision) : "",
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.submitOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now" &&
            (obj.now = ((_b = message.submitOneof) === null || _b === void 0 ? void 0 : _b.now)
                ? SendCampaignNow.toJSON((_c = message.submitOneof) === null || _c === void 0 ? void 0 : _c.now)
                : undefined);
        ((_d = message.submitOneof) === null || _d === void 0 ? void 0 : _d.$case) === "schedule" &&
            (obj.schedule = ((_e = message.submitOneof) === null || _e === void 0 ? void 0 : _e.schedule)
                ? ScheduleCampaign.toJSON((_f = message.submitOneof) === null || _f === void 0 ? void 0 : _f.schedule)
                : undefined);
        message.revision !== undefined && (obj.revision = message.revision);
        return obj;
    },
};
function createBaseSendCampaignNow() {
    return {};
}
export const SendCampaignNow = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendCampaignNow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseUnscheduleCampaign() {
    return {};
}
export const UnscheduleCampaign = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUnscheduleCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseScheduleCampaign() {
    return { scheduledAt: undefined };
}
export const ScheduleCampaign = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.scheduledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.scheduledAt), writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScheduleCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.scheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            scheduledAt: isSet(object.scheduledAt)
                ? String(object.scheduledAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.scheduledAt !== undefined &&
            (obj.scheduledAt = message.scheduledAt);
        return obj;
    },
};
function createBaseScheduleCampaignDto() {
    return { scheduleOneof: undefined };
}
export const ScheduleCampaignDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "schedule") {
            ScheduleCampaign.encode(message.scheduleOneof.schedule, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.$case) === "unschedule") {
            UnscheduleCampaign.encode(message.scheduleOneof.unschedule, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScheduleCampaignDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.scheduleOneof = {
                        $case: "schedule",
                        schedule: ScheduleCampaign.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.scheduleOneof = {
                        $case: "unschedule",
                        unschedule: UnscheduleCampaign.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            scheduleOneof: isSet(object.schedule)
                ? {
                    $case: "schedule",
                    schedule: ScheduleCampaign.fromJSON(object.schedule),
                }
                : isSet(object.unschedule)
                    ? {
                        $case: "unschedule",
                        unschedule: UnscheduleCampaign.fromJSON(object.unschedule),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "schedule" &&
            (obj.schedule = ((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.schedule)
                ? ScheduleCampaign.toJSON((_c = message.scheduleOneof) === null || _c === void 0 ? void 0 : _c.schedule)
                : undefined);
        ((_d = message.scheduleOneof) === null || _d === void 0 ? void 0 : _d.$case) === "unschedule" &&
            (obj.unschedule = ((_e = message.scheduleOneof) === null || _e === void 0 ? void 0 : _e.unschedule)
                ? UnscheduleCampaign.toJSON((_f = message.scheduleOneof) === null || _f === void 0 ? void 0 : _f.unschedule)
                : undefined);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
