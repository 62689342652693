/* eslint-disable react/no-unused-prop-types */
import React, { FC, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Label,
  TextField,
  useTheme,
  makeStyles,
  DeleteOutlineIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { NotificationContext } from '../../contexts';
import { isEmail } from '../../presentation/preview/utils';
import { selectBusinessId, useMeData, useEventRequestGenerateCampaignReport } from '../../hooks';
import { SIZE_CONTENT_M, SIZE_MEDIA_S, SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { selectedAtom } from '../../atoms/analytics';

type AnalyticsRequestGenerateCampaignReportDialogProps = {
  open: boolean;
  onClose: () => void;
  reportTimeData: { startDate: string; endDate: string; timeZone: string };
};

type EmailField = {
  id: string;
  text: string;
  error: null | string;
};

const useAnalyticsRequestGenerateCampaignReportDialog = ({
  open,
  onClose,
  reportTimeData,
}: AnalyticsRequestGenerateCampaignReportDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const [error, setError] = useState(false);
  const selected = useAtomValue(selectedAtom);
  const [emailFields, setEmailFields] = useState<EmailField[]>([{ id: '1', text: '', error: null }]);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);

  const isInList = (group: EmailField[], item: string) => {
    return !!group.find((em: EmailField) => em.text.toLowerCase() === item.toLowerCase());
  };

  const { mutate, isLoading } = useEventRequestGenerateCampaignReport({
    onSuccess: () => {
      setError(false);
      queryClient.invalidateQueries({ queryKey: ['campaignReports'] });
      onClose();

      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('sendoutReportSuccess'),
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('sendoutReportError'),
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  const handleRequestGenerateCampaignReport = useCallback(() => {
    const emailList = emailFields.reduce((acc: string[], cur) => {
      if (isEmail(cur.text)) {
        acc.push(cur.text);
      }

      return acc;
    }, []);

    if (!emailList.length || error) {
      return null;
    }

    return mutate({
      clientRequestId: uuidv4(),
      businessId: businessId || '',
      emails: emailList,
      sendoutIds: [...new Set(selected.map((val) => val.sendoutId))],
      campaignIds: [...new Set(selected.map((val) => val.campaignId))],
      timeRangeStart: reportTimeData.startDate || '',
      timeRangeEnd: reportTimeData.endDate || '',
      timezone: reportTimeData.timeZone || '',
    });
  }, [
    mutate,
    businessId,
    error,
    emailFields,
    selected,
    reportTimeData.startDate,
    reportTimeData.endDate,
    reportTimeData.timeZone,
  ]);

  const handleAddEmailField = () => {
    if (emailFields.length === 5) {
      return null;
    }

    const randomId = Math.floor(Math.random() * 1000);

    const newEmail = {
      id: String(randomId),
      text: '',
      error: null,
    };

    return setEmailFields([...emailFields, newEmail]);
  };

  const handleChangeEmail = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      const newEmailValue = event.target.value;

      const allFields = [...emailFields];
      const updateFields = emailFields.map((field) => {
        if (field.id === id) {
          if (isInList(allFields, newEmailValue)) {
            setError(true);
            return { ...field, text: newEmailValue, error: t('duplicateEmail') };
          }

          setError(false);
          return { ...field, text: newEmailValue, error: null };
        }

        return field;
      });

      setEmailFields(updateFields);
    },
    [setError, emailFields, t],
  );

  const handleDelete = useCallback(
    (id: string) => {
      const updateFields = emailFields.filter((field) => field.id !== id);

      setError(false);
      setEmailFields(updateFields);
    },
    [emailFields],
  );

  const handleOnBlur = useCallback(() => {
    const updateFields = emailFields.map((field) => {
      if (!isEmail(field.text)) {
        setError(true);
        return { ...field, error: t('invalidEmail') };
      }

      return field;
    });

    setEmailFields(updateFields);
  }, [emailFields, t]);

  return {
    emailFields,
    error,
    open,
    isLoading,
    handleChangeEmail,
    handleOnBlur,
    handleAddEmailField,
    handleClose: onClose,
    handleDelete,
    handleRequestGenerateCampaignReport,
  };
};

const useStyles = makeStyles(() => ({
  divider: {
    borderColor: '#E8EAF0',
    marginTop: '16px',
    marginBottom: '16px',
  },
  input: {
    '& > .MuiInputBase-root': {
      height: '45px',
    },
  },
}));

export const AnalyticsRequestGenerateCampaignReportDialog: FC<AnalyticsRequestGenerateCampaignReportDialogProps> = (
  props,
) => {
  const {
    error,
    emailFields,
    open,
    isLoading,
    handleChangeEmail,
    handleOnBlur,
    handleClose,
    handleAddEmailField,
    handleDelete,
    handleRequestGenerateCampaignReport,
  } = useAnalyticsRequestGenerateCampaignReportDialog(props);

  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const theme = useTheme();

  const noEmails = emailFields.length === 1 && !emailFields[0].text;
  const styles = useStyles();

  return (
    <Dialog open={open} maxWidth="xl" onClose={handleClose}>
      <Box sx={{ p: 4, maxWidth: theme.spacing(SIZE_CONTENT_M), minWidth: '440px' }}>
        <DialogTitle sx={{ px: 0 }}>
          <Label variant="h5" sx={{ fontWeight: '700!important' }}>
            Where do you want to send this report?
          </Label>
          <Label sx={{ fontSize: '14px!important', color: 'rgba(0, 0, 0, 0.65)', mt: 1 }}>
            You can send this report to up to five people
          </Label>
        </DialogTitle>

        <Divider classes={{ root: styles.divider }} />

        <DialogContent
          sx={{
            px: 0,
            display: 'flex',
            maxHeight: theme.spacing(SIZE_CONTENT_M),
            py: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: '90px',
            }}
          >
            <Label
              sx={{
                fontSize: '14px!important',
                fontWeight: '700!important',
                color: 'rgba(0, 0, 0, 0.65)',
                mb: 1,
                mt: 1,
              }}
            >
              Email address
            </Label>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {emailFields.map((field) => (
                <Box
                  key={field.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <TextField
                    error={Boolean(field.error)}
                    helperText={field.error}
                    required
                    value={field.text}
                    onChange={(e) => handleChangeEmail(e, field.id)}
                    onBlur={handleOnBlur}
                    autoComplete="off"
                    sx={{ width: '100%', height: 'inherit' }}
                    classes={{ root: styles.input }}
                  />
                  {emailFields.length > 1 && (
                    <DeleteOutlineIcon style={{ cursor: 'pointer' }} onClick={() => handleDelete(field.id)} />
                  )}
                </Box>
              ))}
            </Box>

            <Button
              variant="text"
              onClick={handleAddEmailField}
              disabled={emailFields.length === 5}
              sx={{
                mt: 1,
                fontSize: '12px',
              }}
            >
              Add new
            </Button>
          </Box>
        </DialogContent>

        <Divider classes={{ root: styles.divider }} />

        <DialogActions sx={{ px: 0, paddingBottom: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              height: theme.spacing(SIZE_MEDIA_S),
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
          >
            Cancel
          </Button>

          <Button
            sx={{
              minWidth: '140px',
              height: theme.spacing(SIZE_MEDIA_S),
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
            variant="contained"
            disabled={isLoading || noEmails || error}
            onClick={handleRequestGenerateCampaignReport}
          >
            {isLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="inherit" />}
            {!isLoading && t('sendReport')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
