export const getLocalTimezone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZone;
};

export const getOneYearBackFromToday = () => {
  const today = new Date();
  return new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
};
