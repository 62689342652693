import React, { VFC, useCallback, useContext } from 'react';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { Box, Button, ContentCopyIcon, Label, SvgIcon, WhatsAppIcon } from '@connectlyai-tenets/ui-styled-web';
import { PhoneNumberProps } from './types';
import { NotificationContext } from '../../contexts';
import { formatPhoneNumber } from '../../utils';

export const PhoneNumber: VFC<PhoneNumberProps> = ({ number, onCopy, sx = [] }) => {
  const { notificationProvider } = useContext(NotificationContext);
  const formattedNumber = formatPhoneNumber(number, true);

  const handleCopy = useCallback(() => {
    if (!number) {
      return;
    }

    if (onCopy) {
      onCopy();
      return;
    }

    navigator.clipboard.writeText(number);

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Phone copied to clipboard',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  }, [notificationProvider, number, onCopy]);

  return (
    <Box sx={[{ display: 'flex', flexDirection: 'row' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          boxSizing: 'border-box',
          height: 36.5,
          width: '100%',
          borderRadius: '5px',
          color: 'var(--activitybarColorMain)',
          backgroundColor: 'secondary.light',

          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <SvgIcon
          sx={{
            ml: 1.5,
            width: 16,
            height: 16,
          }}
        >
          <WhatsAppIcon />
        </SvgIcon>
        <Label
          noWrap
          sx={{
            flex: '1 1 auto',
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          {formattedNumber}
        </Label>
        <Button
          title="Copy"
          variant="outlined"
          color="inherit"
          disableRipple
          onClick={handleCopy}
          sx={{
            minWidth: 'unset',
            h: '100%',
            p: '7px',
            borderSize: 0,
            borderColor: 'transparent',
          }}
        >
          <ContentCopyIcon fontSize="small" sx={{ color: 'primary.main' }} />
        </Button>
      </Box>
    </Box>
  );
};
