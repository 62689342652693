import React from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { useAtomValue } from 'jotai';
import { QuerySendoutAnalyticsResponse, generatePlaceholderData } from '@hooks/useQuerySendoutAnalytics';
import { dateRangeLast7DaysAtom } from '@atoms/home';
import { selectBusinessId, useMeData, useQuerySendoutAnalytics } from '@hooks';
import { RecentSendouts } from './RecentSendouts';
import { BestPerforming } from './BestPerforming';

const placeholderData: QuerySendoutAnalyticsResponse = generatePlaceholderData(10);

const useAnalyticsTableGroupData = () => {
  const dateRange = useAtomValue(dateRangeLast7DaysAtom);
  const startDate = dateRange.startDate.toISOString();
  const endDate = dateRange.endDate.toISOString();

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: sendoutAnalytics, isLoading } = useQuerySendoutAnalytics(
    { businessId: businessId || '', timeRangeStart: startDate, timeRangeEnd: endDate },
    { enabled: !!businessId, placeholderData },
  );

  const rows = React.useMemo(
    () => sendoutAnalytics?.entity?.items?.filter((s) => s.sendoutName) || [],
    [sendoutAnalytics],
  );

  const filteredData = React.useMemo(
    () =>
      rows.map((val) => {
        const displayErrorCount = (val.sentCount || 0) - (val.deliveredCount || 0);
        if ((val.errors?.total || 0) < displayErrorCount) {
          const unknownStatusCount = displayErrorCount - (val.errors?.total || 0);
          // Show difference as In Progress/Not enough info
          const newErrors = {
            items: [
              {
                error: {
                  key: '$custom_$unknownStatus',
                  code: '$custom',
                  subcode: '$unknownStatus',
                  userMessage: 'In progress/Not enough info',
                },
                count: unknownStatusCount,
              },
              ...(val.errors?.items || []),
            ],
            total: displayErrorCount,
          };
          return {
            ...val,
            errors: newErrors,
          };
        }
        return val;
      }),
    [rows],
  );

  return {
    filteredData,
    isLoading,
  };
};

export const TablesGroup = () => {
  const { filteredData, isLoading } = useAnalyticsTableGroupData();

  return (
    <Box>
      <Box
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'minmax(340px, 1fr)' }}
        gap={2}
      >
        <RecentSendouts filteredData={filteredData} isLoading={isLoading} />
        <BestPerforming filteredData={filteredData} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
