import { DEFAULT_SOFIA_DATA, DEFAULT_STORE_DESCRIPTOR } from './constants';
import { SofiaStoreDescriptionView } from './types';
import { StoreDescriptor } from './hooks/useSofiaStoreDescriptor';
import { StoreDescriptorV2 } from './hooks/useSofiaStoreDescriptorV2';
import { getLanguage } from '../../utils';
import { sortAge } from './utils';

export const selectStoreDescriptorView = (storeDescriptor: StoreDescriptor): SofiaStoreDescriptionView => {
  storeDescriptor = storeDescriptor || DEFAULT_STORE_DESCRIPTOR;
  const viewData = { ...DEFAULT_SOFIA_DATA };
  viewData.name = storeDescriptor.name || '';
  viewData.url = storeDescriptor.url || '';
  viewData.language = getLanguage(storeDescriptor.language || '');
  if (typeof storeDescriptor.about === 'string') {
    try {
      const json = JSON.parse(storeDescriptor.about);
      viewData.about = json?.about_store || '';
    } catch {
      viewData.about = storeDescriptor.about;
    }
  }
  if (typeof storeDescriptor.customerArchetype === 'string') {
    try {
      const json = JSON.parse(storeDescriptor.customerArchetype);
      const { age, gender, description } = json;
      viewData.customersAge = Array.isArray(age) ? age.sort(sortAge) : [age];
      viewData.customersGender = Array.isArray(gender) ? gender[0] : gender;
      viewData.customersDescription = description;
    } catch {
      console.log('no customers data');
    }
  }
  if (storeDescriptor.contactMethods) {
    const { email, phone } = storeDescriptor.contactMethods;
    if (email) {
      viewData.email = String(email || '');
    }
    if (phone) {
      viewData.phone = String(phone || '');
    }
  }
  if (storeDescriptor.socialMediaHandles) {
    const { facebook, instagram } = storeDescriptor.socialMediaHandles;
    if (facebook) {
      viewData.facebook = String(facebook || '');
    }
    if (instagram) {
      viewData.instagram = String(instagram || '');
    }
  }

  return viewData;
};

export const selectStoreDescriptorViewV2 = (storeDescriptor: StoreDescriptorV2): SofiaStoreDescriptionView => {
  storeDescriptor = storeDescriptor || DEFAULT_STORE_DESCRIPTOR;
  const viewData = { ...DEFAULT_SOFIA_DATA };
  viewData.id = storeDescriptor.id || '';
  viewData.name = storeDescriptor.name || '';
  viewData.url = storeDescriptor.url || '';
  viewData.language = getLanguage(storeDescriptor.defaultLanguage || '');
  if (typeof storeDescriptor.about === 'string') {
    try {
      const json = JSON.parse(storeDescriptor.about);
      viewData.about = json?.about_store || '';
    } catch {
      viewData.about = storeDescriptor.about;
    }
  }

  return viewData;
};
