import { TimeDelayData } from '@connectlyai-tenets/sdk';
import { cloneDeep } from 'lodash';
import { TimeDelayNodeUIState, TimeDelayDurationType } from '../../components/TimeDelayNodeEditor/types';
import { DEFAULT_TIME_DELAY_NODE_UI } from '../../components/TimeDelayNodeEditor/constants';

export const mapTimeDelayUIStateToDataV1Input = (
  uiState: TimeDelayNodeUIState,
): NonNullable<TimeDelayData['v1']>['input'] => {
  const input: NonNullable<TimeDelayData['v1']>['input'] = { delayInSeconds: 10 * 60 * 60 };

  switch (uiState.delayType) {
    case TimeDelayDurationType.HOURS:
      input.delayInSeconds = uiState.delayNumber * 60 * 60;
      break;
    case TimeDelayDurationType.MINUTES:
      input.delayInSeconds = uiState.delayNumber * 60;
      break;
    default:
  }

  return input;
};

export const timeDelayNodeDataToUIState = (nodeData: TimeDelayData): TimeDelayNodeUIState => {
  const state = cloneDeep(DEFAULT_TIME_DELAY_NODE_UI);

  const delayInSeconds = nodeData.v1?.input?.delayInSeconds || 0;

  if (delayInSeconds === 0) {
    return state;
  }
  if (delayInSeconds % (1 * 60 * 60) === 0) {
    state.delayNumber = delayInSeconds / (1 * 60 * 60);
    state.delayNumberInput = `${state.delayNumber}`;
    state.delayType = TimeDelayDurationType.HOURS;
  } else if (delayInSeconds % (1 * 60) === 0) {
    state.delayNumber = delayInSeconds / (1 * 60);
    state.delayNumberInput = `${state.delayNumber}`;
    state.delayType = TimeDelayDurationType.MINUTES;
  }

  return state;
};
