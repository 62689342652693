import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { RecommenderOnboardParameters, RecommenderOnboardResponse } from './types';

export const useMutationRecommenderOnboard = (
  options?: Omit<
    UseMutationOptions<RecommenderOnboardResponse, ConnectlyError, RecommenderOnboardParameters, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ['recommenderOnboard'],
    ({ businessId, input }: RecommenderOnboardParameters) =>
      api.recommenderV1('/internal/v1/businesses/{input.businessId}/recommender/onboard', 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { ...input } },
      }) as Promise<RecommenderOnboardResponse>,
    options,
  );
