import React, { useState, useRef, useCallback, useMemo } from 'react';
import {
  Button,
  ArrowDropDownIcon,
  ClickAwayListener,
  Grow,
  Paper,
  Label,
  Popper,
  MenuItem,
  MenuList,
  Box,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { DropdownToggleProps } from './types';
import { MessageStatus } from '../../utils';

const useDropdownToggle = ({ isActive, onChangeActive }: DropdownToggleProps) => {
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(
    (_: Event) => {
      setOpen(false);
    },
    [setOpen],
  );

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setOpen(false);
      onChangeActive(index === 0);
    },
    [setOpen, onChangeActive],
  );

  const selectedIndex = useMemo(() => (isActive ? 0 : 1), [isActive]);

  return {
    anchorRef,
    handleClose,
    handleMenuItemClick,
    handleOpen,
    isOpen,
    selectedIndex,
  };
};

export const DropdownToggle = ({ isActive, messageStatus, onChangeActive, disabled = false }: DropdownToggleProps) => {
  const theme = useTheme();
  const { anchorRef, handleClose, handleMenuItemClick, handleOpen, isOpen, selectedIndex } = useDropdownToggle({
    isActive,
    onChangeActive,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'components.dropdownOptions' });
  const options = [t('active'), t('inactive')];

  const getColor = () => {
    if (messageStatus === MessageStatus.PENDING) {
      return theme.palette.yellow;
    }
    if (messageStatus === MessageStatus.PARTIALLY_APPROVED) {
      return theme.palette.warning.light;
    }
    if (messageStatus === MessageStatus.REJECTED) {
      return theme.palette.error.main;
    }
    return selectedIndex === 0 ? theme.palette.success.main : theme.palette.text.secondary;
  };

  const getLabel = () => {
    if (messageStatus === MessageStatus.PENDING) {
      return t('pending');
    }
    if (messageStatus === MessageStatus.PARTIALLY_APPROVED) {
      return t('active');
    }
    if (messageStatus === MessageStatus.REJECTED) {
      return t('rejected');
    }
    return options[selectedIndex];
  };
  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          border: '1px solid #E9E9E9',
          borderRadius: '10px',
          pr: 0.5,
          height: theme.spacing(5.5),
        }}
      >
        <Label
          variant="body1"
          sx={{
            textTransform: 'none',
            color: getColor(),
          }}
        >
          {getLabel()}
        </Label>
        <ArrowDropDownIcon sx={{ color: theme.palette.text.secondary }} />
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
