import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Tab, Tabs, PillsItem, Pills } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useMeData, useFeatureFlag } from '@hooks';
import { useSetAtom } from 'jotai';
import { audiencePageEnhancementsAtom } from '@atoms/audience';
import { upperFirst } from 'src/utils/titleCase';
import { AudienceFilter } from '../../types';
import { AudiencesTable } from '../../components/AudiencesTable';
import { CreateAudienceModal } from '../../components/CreateAudienceModal';
import { SIZE_SPACING_INTER_SECTION } from '../../../../ui-theme';

const useAudiences = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [modalOpen, setModalOpen] = useState(false);
  const [audiencesFilter, setAudiencesFilter] = useState<AudienceFilter>('ALL');

  const { ffEnableSmartAudiences, ffEnableAnalyticsRedesign } = useFeatureFlag([
    'ffEnableSmartAudiences',
    'ffEnableAnalyticsRedesign',
  ]);
  const { t } = useTranslation('translation', { keyPrefix: 'audience.audiences' });
  const { filterAllLabel, filterSmartLabel, filterImportedLabel } = useMemo(() => {
    return {
      filterAllLabel: t('filters.all'),
      filterSmartLabel: t('filters.smart'),
      filterImportedLabel: t('filters.imported'),
    };
  }, [t]);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const changeFilter = useCallback(
    (filter: AudienceFilter) => {
      setAudiencesFilter(filter);
    },
    [setAudiencesFilter],
  );
  const handleChangeFilter = (event: React.SyntheticEvent, newValue: AudienceFilter) => {
    setAudiencesFilter(newValue);
  };
  const { primaryButtonText } = useMemo(() => {
    return {
      primaryButtonText: t('createAudienceText'),
    };
  }, [t]);
  return {
    primaryButtonText,
    ffEnableSmartAudiences,
    ffEnableAnalyticsRedesign,
    audiencesFilter,
    setAudiencesFilter,
    businessId,
    changeFilter,
    handleChangeFilter,
    closeModal,
    filterAllLabel,
    filterImportedLabel,
    filterSmartLabel,
    modalOpen,
    openModal,
  };
};

export const Audiences = () => {
  const {
    primaryButtonText,
    ffEnableSmartAudiences,
    ffEnableAnalyticsRedesign,
    audiencesFilter,
    setAudiencesFilter,
    businessId,
    changeFilter,
    handleChangeFilter,
    closeModal,
    filterAllLabel,
    filterImportedLabel,
    filterSmartLabel,
    modalOpen,
    openModal,
  } = useAudiences();
  const updatePageEnhancements = useSetAtom(audiencePageEnhancementsAtom);

  useEffect(() => {
    updatePageEnhancements({
      primaryButton: {
        children: primaryButtonText,
        onClick: openModal,
        track: [
          'click new_audience_button',
          {
            businessId,
            from: 'list',
          },
        ],
      },
    });
  }, [businessId, openModal, primaryButtonText, updatePageEnhancements]);
  useEffect(() => {
    return () => {
      updatePageEnhancements({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateAudienceModal
        businessId={businessId || ''}
        isOpen={modalOpen}
        closeModal={closeModal}
        audienceTypes={ffEnableSmartAudiences ? ['spreadsheet', 'query'] : ['spreadsheet']}
      />
      <Stack gap={1}>
        {ffEnableSmartAudiences && !ffEnableAnalyticsRedesign && (
          <Tabs value={audiencesFilter} onChange={handleChangeFilter}>
            <Tab value="ALL" label={filterAllLabel} />
            <Tab value="SMART" label={filterSmartLabel} />
            <Tab value="IMPORTED" label={filterImportedLabel} />
          </Tabs>
        )}

        {ffEnableAnalyticsRedesign && (
          <Pills>
            {(['ALL', 'SMART', 'IMPORTED'] as AudienceFilter[]).map((filter: AudienceFilter) => (
              <PillsItem
                key={filter}
                item={upperFirst(filter)}
                onClick={() => setAudiencesFilter(filter)}
                isActive={audiencesFilter === filter}
              />
            ))}
          </Pills>
        )}

        <Stack gap={SIZE_SPACING_INTER_SECTION}>
          <AudiencesTable filter={audiencesFilter} />
        </Stack>
      </Stack>
    </>
  );
};
