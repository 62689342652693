import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_DELETE_QUICK_REPLY_ITEM_URL = '/v1/businesses/{businessId}/quick_reply_templates/{id}';

type MutationDeleteQuickReplyItemUrl = typeof MUTATION_DELETE_QUICK_REPLY_ITEM_URL;

export type MutationDeleteQuickReplyItemResponse =
  Paths['profileV1'][MutationDeleteQuickReplyItemUrl]['delete']['responses']['200']['schema'];
export type MutationDeleteQuickReplyItemInput =
  Paths['profileV1'][MutationDeleteQuickReplyItemUrl]['delete']['parameters']['path'];
export type MutationDeleteQuickReplyItemOptions = Omit<
  UseMutationOptions<MutationDeleteQuickReplyItemResponse, ConnectlyError, MutationDeleteQuickReplyItemInput, unknown>,
  'mutationKey' | 'mutationFn'
>;
