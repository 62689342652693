/* eslint-disable @typescript-eslint/no-explicit-any */
import { convert } from 'html-to-text';

// quick script just for demo purposes
// to fetch webpage data

export const webpageToProduct = async (
  formData: any,
  networkClientProvider: any,
  generateAIMessage: any,
  notificationServiceProvider: any,
  resolve: any,
  quitEarly: any,
) => {
  const extendedFormData = { ...formData, Webpagedata: '', Webpagetext: '', Webpageimage: '', Webpageimagealt: '' };
  // if form key inside formdata is webpage then get the webpage and convert it to text with await promise
  const formPromises = Object.entries(extendedFormData).map(([key, value]) =>
    key?.toLowerCase() !== 'webpagedata'
      ? Promise.resolve(value)
      : networkClientProvider()
          .post(
            `${process.env.REACT_APP_INBOX_API_GATEWAY}/proxy/zyte/v1/extract`,
            {
              url: extendedFormData.Webpage,
              httpResponseBody: true,
            },
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then((res: any) => {
            return atob(res.data.httpResponseBody);
          })
          .catch((_: any) => {
            quitEarly();
            return '';
          }),
  );
  await Promise.all(formPromises).then((values) => {
    Object.entries(extendedFormData).forEach(([key, _], index) => {
      extendedFormData[key] = values[index];
    });
  });

  // if form key inside formdata is webpage then get the webpage and convert it to text with await promise
  const formPromises2 = Object.entries(extendedFormData).map(([key, value]) =>
    key?.toLowerCase() !== 'webpagetext'
      ? Promise.resolve(value)
      : convert(extendedFormData.Webpagedata)
          .replace(/\[.+\]/g, '')
          .replace(/\n+/g, '\n')
          .replace(/^\/\//g, '/')
          .substring(0, 4097),
  );
  await Promise.all(formPromises2).then((values) => {
    Object.entries(extendedFormData).forEach(([key, _], index) => {
      extendedFormData[key] = values[index];
    });
  });

  const checkImage = (path: string) =>
    new Promise((res) => {
      const img = new Image();
      img.onload = () => res(path);
      img.onerror = () => res(null);

      img.src = path;
    });
  const possibleImages = extendedFormData.Webpagedata.match(/[^"'\s]*\.(jpeg|jpg|png)([^"'\s]*)*/g)
    .map((relativeURL: string) =>
      // if relative url starts with // then add https to it
      relativeURL.startsWith('//') ? `https:${relativeURL}` : new URL(relativeURL, extendedFormData.Webpage).href,
    )
    .map(
      // if url has // in it other than http:// or https:// then remove it
      (url: string) => url.replace(/^(https?:)?\/\//, 'https://'),
    );
  const allValidImages = await Promise.all(possibleImages?.filter((image: string) => checkImage(image)));

  console.log(possibleImages, allValidImages);
  if (allValidImages.length === 0) {
    quitEarly();
  }

  const imageSystemMessage =
    "You need to find which image url has the best shot to be described image, you cannot say anything else but image url. Only provide the absolute url to image. Dont include html code. Even if you think there is no image that satisfies the criteria, just provide an image url. If missing add https to the url. Don't explain the url.";
  const firstImageMessage =
    `Which one of has best shot to be the product image in source code, in product webpage or first product image in a webpage that has a list of products ${
      extendedFormData.Webpage
    }? From image tags here ${allValidImages?.join(' ')}`.substring(0, 4097);
  let firstImageResponse = '';

  // if form key inside formdata is webpage then get the webpage and convert it to text with await promise
  const formPromises3 = Object.entries(extendedFormData).map(([key, value]) =>
    key?.toLowerCase() !== 'webpageimage'
      ? Promise.resolve(value)
      : generateAIMessage([
          {
            role: 'system',
            content: imageSystemMessage,
          },
          { role: 'user', content: firstImageMessage },
        ]).then((answer: any) => {
          firstImageResponse = answer;
          return answer
            .replace(/\n+/g, '')
            .replace(/^\/\//g, '/')
            .match(/[^"'\s]*\.(jpeg|jpg|png)([^"'\s]*)*/g);
        }),
  );
  await Promise.all(formPromises3).then((values) => {
    Object.entries(extendedFormData).forEach(([key, _], index) => {
      extendedFormData[key] = values[index];
    });
  });

  const isFirstImageOK = await checkImage(extendedFormData.Webpageimage);
  if (!isFirstImageOK) {
    [extendedFormData.Webpageimage] = allValidImages;
  }

  // if form key inside formdata is webpage then get the webpage and convert it to text with await promise
  const formPromises4 = Object.entries(extendedFormData).map(([key, value]) =>
    key?.toLowerCase() !== 'webpageimagealt'
      ? Promise.resolve(value)
      : generateAIMessage([
          {
            role: 'system',
            content: imageSystemMessage,
          },
          { role: 'user', content: firstImageMessage },
          { role: 'assistant', content: firstImageResponse },
          {
            role: 'user',
            content:
              'Which one has the second best shot to be the product image, that has a variation like color alternative or a different pov of product?',
          },
        ]).then((answer: any) => {
          return answer
            .replace(/\n+/g, '')
            .replace(/^\/\//g, '')
            .match(/[^"'\s]*\.[jpeg|jpg|png]([^"'\s]*)*/g);
        }),
  );
  await Promise.all(formPromises4).then((values) => {
    Object.entries(extendedFormData).forEach(([key, _], index) => {
      extendedFormData[key] = values[index];
    });
  });

  const isSecondImageOK = await checkImage(extendedFormData.Webpageimagealt);
  if (!isSecondImageOK) {
    extendedFormData.Webpageimagealt = extendedFormData.Webpageimage;
  }
  resolve(extendedFormData);
};
