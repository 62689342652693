import { Card } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';

export const getNewButtons = (cards: Array<Card>) => {
  if (cards.length === 0) {
    return [{ button: { quickReply: { text: '' } } }];
  }
  const [firstCard] = cards;
  return firstCard.buttons?.map(({ button }) => {
    if (button?.phoneNumber) {
      return { button: { phoneNumber: { text: '', phoneNumber: '' } } };
    }
    if (button?.url) {
      return {
        button: { url: { trackedUrl: 'www.example.com', text: '', url: '', example: [], prefilledParams: [] } },
      };
    }
    return { button: { quickReply: { text: '' } } };
  });
};
