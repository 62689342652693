import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { MIN_DATE } from '@scenes/Inbox/constants';
import { blueGrey, Box, ConnectlySection, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { inboxAnalyticsTimeRangesAtom as timeRangesAtom } from '@atoms/inbox';
import { useQueryAgentMetrics } from '@hooks/useQueryAgentMetrics';
import { msToTimeFormat, computeChange } from '@scenes/Inbox/utils';
import { KeyPerformanceIndicator, KeyPerformanceIndicatorV2 } from '@components';
import { selectBusinessId, useFeatureFlag, useMeData } from '@hooks';
import { KPIDisplay } from './types';

const useKeyPerformanceIndicators = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const timeRanges = useAtomValue(timeRangesAtom);
  const { data: currentPeriodData, isLoading: currentDataIsLoading } = useQueryAgentMetrics(
    { 'query.businessId': businessId || '' },
    {
      timeRangeStart: timeRanges[0].timeRangeStart,
      timeRangeEnd: timeRanges[0].timeRangeEnd,
    },
    { enabled: !!businessId },
  );
  const { data: previousPeriodData, isLoading: previousDataIsLoading } = useQueryAgentMetrics(
    { 'query.businessId': businessId || '' },
    {
      timeRangeStart: timeRanges[1].timeRangeStart,
      timeRangeEnd: timeRanges[1].timeRangeEnd,
    },
    { enabled: !!businessId },
  );
  const displayData: KPIDisplay = useMemo(() => {
    const { entity: current } = currentPeriodData || {};
    const { entity: previous } = previousPeriodData || {};
    const {
      activeUsers: currentActiveUsers,
      avgResolutionTime: currentAvgResolutionTime,
      avgWaitTime: currentAvgWaitTime,
    } = current || {};
    const {
      activeUsers: previousActiveUsers,
      avgResolutionTime: previousAvgResolutionTime,
      avgWaitTime: previousAvgWaitTime,
    } = previous || {};
    const currentActive: number = Number(currentActiveUsers) || 0;
    const currentResolution: number = Number(currentAvgResolutionTime) || 0;
    const currentWait: number = Number(currentAvgWaitTime) || 0;
    const returnData: KPIDisplay = {
      activeUsers: {
        current: String(currentActive),
      },
      avgResolutionTime: {
        current: msToTimeFormat(currentResolution),
      },
      avgWaitTime: {
        current: msToTimeFormat(currentWait),
      },
    };
    const outOfRange = new Date(timeRanges[1].timeRangeStart) < MIN_DATE;
    if (!outOfRange) {
      const previousActive: number = Number(previousActiveUsers) || 0;
      const previousResolution: number = Number(previousAvgResolutionTime) || 0;
      const previousWait: number = Number(previousAvgWaitTime) || 0;
      returnData.activeUsers.change = computeChange(currentActive, previousActive);
      returnData.avgResolutionTime.change = computeChange(currentResolution, previousResolution);
      returnData.avgWaitTime.change = computeChange(currentWait, previousWait);
    }
    return returnData;
  }, [currentPeriodData, previousPeriodData]);
  return {
    displayData,
    isLoading: currentDataIsLoading || previousDataIsLoading,
  };
};

export const KeyPerformanceIndicators = () => {
  const theme = useTheme();
  const { displayData, isLoading } = useKeyPerformanceIndicators();
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.inbox' });
  const { t: tGeneric } = useTranslation('translation', { keyPrefix: 'generic' });
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);

  if (ffEnableAnalyticsRedesign) {
    return (
      <ConnectlySection title={tGeneric('overview', 'Overview')}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            borderTop: `1px solid ${blueGrey[200]}`,
          }}
        >
          <KeyPerformanceIndicatorV2
            title={t('avgResolutionTime.title')}
            data={displayData.avgResolutionTime}
            isLoading={isLoading}
            tooltip={t('avgResolutionTime.tooltip')}
            sx={{
              p: 2,
              pl: 0,
              borderRight: `1px solid ${blueGrey[200]}`,
              borderBottom: `1px solid ${blueGrey[200]}`,
            }}
          />
          <KeyPerformanceIndicatorV2
            title={t('avgWaitTime.title')}
            data={displayData.avgWaitTime}
            isLoading={isLoading}
            tooltip={t('avgWaitTime.tooltip')}
            sx={{
              p: 2,
              borderRight: `1px solid ${blueGrey[200]}`,
              borderBottom: `1px solid ${blueGrey[200]}`,
            }}
          />
          <KeyPerformanceIndicatorV2
            title={t('activeUsers.title')}
            data={displayData.activeUsers}
            isLoading={isLoading}
            tooltip={t('activeUsers.tooltip')}
            sx={{
              p: 2,
              borderBottom: `1px solid ${blueGrey[200]}`,
            }}
          />
        </Box>
      </ConnectlySection>
    );
  }

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(14),
        maxHeight: theme.spacing(14),
        justifyContent: 'flex-end',
        gap: 2,
        flexWrap: 'wrap',
        position: 'relative',
      }}
    >
      <KeyPerformanceIndicator
        title={t('avgResolutionTime.title')}
        data={displayData.avgResolutionTime}
        isLoading={isLoading}
        tooltip={t('avgResolutionTime.tooltip')}
        sx={{ background: theme.palette.common.white }}
      />
      <KeyPerformanceIndicator
        title={t('avgWaitTime.title')}
        data={displayData.avgWaitTime}
        isLoading={isLoading}
        tooltip={t('avgWaitTime.tooltip')}
        sx={{ background: theme.palette.common.white }}
      />
      <KeyPerformanceIndicator
        title={t('activeUsers.title')}
        data={displayData.activeUsers}
        isLoading={isLoading}
        tooltip={t('activeUsers.tooltip')}
        sx={{ background: theme.palette.common.white }}
      />
    </Box>
  );
};
