import React, { FC, useRef } from 'react';
import { SourceHandle, SourceHandleRef } from '@components/SourceHandle';
import { Button, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { HandleType } from '../../sdks/flow/createHandleId';
import { TemplateListRow } from './types';

export const FlowChartMessageNodeListRow: FC<{
  index: number;
  row: TemplateListRow;
  handleType: HandleType;
  isLast?: boolean;
}> = ({ index, row, handleType, isLast }) => {
  const theme = useTheme();
  const sourceHandle = useRef<SourceHandleRef | null>(null);
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        textTransform: 'none',
        display: 'flex',
        gap: 0.5,
        borderRadius: isLast ? `0 0 ${theme.spacing(1)} ${theme.spacing(1)}` : 0,
        borderBottom: isLast ? undefined : `1px solid ${theme.palette.divider}`,
      }}
      disabled={sourceHandle.current?.isDisabled}
      onClick={sourceHandle.current?.handleClick}
    >
      <Label variant="body1">{row.text || `Row ${index + 1}`}</Label>
      <SourceHandle ref={sourceHandle} {...handleType} handleLineLength={42} />
    </Button>
  );
};
