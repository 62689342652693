import { UseQueryOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const QUERY_CAMPAIGN_UTM_CONFIGURATION_URL =
  '/internal/v1/businesses/{query.businessId}/query_utm_configuration/campaigns';
type QueryCampaignUTMConfigurationURL = typeof QUERY_CAMPAIGN_UTM_CONFIGURATION_URL;

export type QueryCampaignUTMConfigurationResponse =
  Paths['campaignproductV1'][QueryCampaignUTMConfigurationURL]['post']['responses']['200']['schema'];
export type QueryCampaignUTMConfigurationQuery =
  Paths['campaignproductV1'][QueryCampaignUTMConfigurationURL]['post']['parameters']['body']['query'];
export type QueryCampaignUTMConfigurationInput = QueryCampaignUTMConfigurationQuery & { businessId: string };
export type QueryCampaignUTMConfigurationOptions = Omit<
  UseQueryOptions<
    QueryCampaignUTMConfigurationResponse,
    ConnectlyError,
    QueryCampaignUTMConfigurationResponse,
    readonly ['config.campaigns.utm', { businessId?: string }]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;

export type QueryCampaignUTMConfigurationDto = NonNullable<QueryCampaignUTMConfigurationResponse['entity']>;
