import { CampaignsResponse } from './types';
import { Agent } from '../useAgentsData/types';
import { generateRandomText } from '../../utils/generateRandomText';

export const selectCampaigns = (data: CampaignsResponse) => data.entity?.campaigns;

export const createCampaignsWithAuthorsSelector =
  (authors: Record<string, Agent> | undefined) => (data: CampaignsResponse) => {
    const campaigns = selectCampaigns(data);
    return campaigns?.map((campaign) => ({
      ...campaign,
      authorSkeleton: authors === undefined,
      authorName: authors?.[campaign?.authorId || -Infinity]?.displayName || '',
    }));
  };

export const generateUseCampaignsPlaceholderData = (count = 5): CampaignsResponse => {
  return {
    entity: {
      campaigns: Array.from({ length: count }, () => ({
        id: `SKELETON-${generateRandomText(5)}`,
        businessId: generateRandomText(10),
        name: generateRandomText(10),
        description: generateRandomText(20),
        outcome: {
          reportV1: {
            url: generateRandomText(20),
          },
        },
        status: {
          sendoutCount: 2,
          sentCount: 3,
        },
        authorId: '00000000-0000-0000-0000-000000000000',
        authorName: generateRandomText(12),
        authorSkeleton: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      })),
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectNormalizedCampaigns = (data: CampaignsResponse) => {
  return (
    data?.entity?.campaigns?.reduce((acc: any, item: any) => {
      acc[item.id] = item;
      return acc;
    }, {}) || {}
  );
};
