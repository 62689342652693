import { RoomDtos } from '@connectlyai-idl/models/dist/models/room/models';
const ROOMS_PAGE_LENGTH = 50;
function getParams(filter, offset, limit) {
    const params = [];
    if (filter) {
        params.push(filter);
    }
    if (offset) {
        const { type, eventUlid, time } = offset;
        if (type) {
            params.push(`filter.offset.type=${type}`);
        }
        if (eventUlid) {
            params.push(`filter.offset.eventUlid=${eventUlid}`);
        }
        if (time) {
            params.push(`filter.offset.time=${time}`);
        }
    }
    if (limit) {
        params.push(`filter.limit=${limit}`);
    }
    return params.length > 0 ? `?${params.join('&')}` : '';
}
export const queryRoomsProvider = (log, networkClient, onDurationRoomsQuery) => async (businessId, filter, offset) => {
    const params = getParams(filter, offset, ROOMS_PAGE_LENGTH);
    const url = `/v1/businesses/${businessId}/rooms${params}`;
    log.debug('queryRooms@start', () => ({
        url,
    }));
    try {
        const start = new Date().getTime();
        const resp = await networkClient.get(url);
        log.debug('queryRooms@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        const elapsed = new Date().getTime() - start;
        log.debug('queryRoomPresentation duration', () => ({
            elapsed,
        }));
        onDurationRoomsQuery === null || onDurationRoomsQuery === void 0 ? void 0 : onDurationRoomsQuery(elapsed / 1000);
        return {
            started: !offset,
            completed: !resp.data.prev,
            prev: resp.data.prev,
            next: resp.data.next,
            result: RoomDtos.fromJSON(resp.data.entity),
        };
    }
    catch (err) {
        log.error('queryRooms@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
