import React from 'react';
import { Box, CircularProgress, Label } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';

export const LoadingBestPerformingCampaigns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home.analytics' });

  return (
    <Box
      sx={{
        mt: 4,
        mx: 'auto',
        width: '70%',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <CircularProgress size={20} sx={{ mt: 4 }} />
      <Label sx={{ mt: 2 }}>
        {t('bestPerformingCampaignsLoading', 'Hang on tight while we calculate your best performing campaigns...')}
      </Label>
    </Box>
  );
};
