import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AssetContentParameters } from './types';

export const useAssetContent = <TData = unknown>({
  url,
}: Omit<
  UseQueryOptions<unknown, unknown, TData, readonly [target: 'asset-content', uri: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  AssetContentParameters) => {
  return useQuery(['asset-content', url], async () => {
    if (!url) return null;
    try {
      // Fetch the file from the url
      const response = await fetch(url);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Read the response as text
      const text = await response.text();
      return text;
    } catch (error) {
      console.error('Failed to fetch file content:', error);
    }
    return null;
  });
};
