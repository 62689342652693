import React, { useMemo } from 'react';
import { KeyPerformanceIndicator } from '@components';
import { useTranslation } from 'react-i18next';
import { SendoutAnalytics } from '@hooks/useQuerySendoutAnalytics/types';
import { useAtomValue } from 'jotai';
import { QuerySendoutAnalyticsResponse, generatePlaceholderData } from '@hooks/useQuerySendoutAnalytics';
import { dateRangeLast7DaysAtom } from '@atoms/home';
import { selectBusinessId, useAnalytics, useMeData, useQuerySendoutAnalytics } from '@hooks';
import { useNavigator as useAnalyticsNavigator } from '@scenes/Analytics/hooks';
import { Box, Button, ConnectlyCard, Stack } from '@connectlyai-tenets/ui-styled-web';
import { AggregateSendoutsDataProps, AnalyticsSendoutsAggregateDataProps } from '@scenes/Analytics/types';

const useAggregateData = (filteredRows: SendoutAnalytics[]) => {
  const totalSent = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.sentCount ?? 0), 0),
    [filteredRows],
  );
  const totalDelivered = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.deliveredCount ?? 0), 0),
    [filteredRows],
  );
  const totalOpened = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.readCount ?? 0), 0),
    [filteredRows],
  );

  const openRate = useMemo(() => ((totalOpened || 0) / (totalDelivered || 0)) * 100, [totalDelivered, totalOpened]);
  const engagements = useMemo(
    () =>
      filteredRows.reduce((total, sendout) => total + (sendout?.sendoutEngagement?.uniqueSessionEngagement ?? 0), 0),
    [filteredRows],
  );
  const engagementRate = useMemo(() => ((engagements || 0) / (totalOpened || 0)) * 100, [totalOpened, engagements]);

  return {
    totalSent,
    totalDelivered,
    openRate,
    opened: {
      total: totalOpened,
      rate: openRate,
    },
    engagement: {
      total: engagements,
      rate: engagementRate,
    },
  };
};

export const CampaignsOverviewMetrics = ({ filteredRows, prevFilteredData }: AnalyticsSendoutsAggregateDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });

  const { totalSent, totalDelivered, openRate, opened, engagement } = useAggregateData(filteredRows);

  const {
    totalSent: prevTotalSent,
    openRate: prevOpenRate,
    engagement: prevEngagement,
  } = useAggregateData(prevFilteredData);

  const metrics = useMemo<AggregateSendoutsDataProps[]>(
    () => [
      {
        title: 'Total Sent',
        value: totalSent,
        hint: t('sentHint'),
        changeValue: prevTotalSent ? (totalSent / prevTotalSent - 1) * 100 : 0,
      },
      {
        title: 'Opened',
        value: opened.total,
        rate: (totalDelivered || 0) === 0 ? undefined : opened.rate,
        hint: t('openedHint'),
        changeValue: prevOpenRate ? openRate - prevOpenRate : 0,
      },
      {
        title: 'Engagement',
        value: engagement.total,
        rate: (totalDelivered || 0) === 0 ? undefined : engagement.rate,
        hint: t('engagementHint'),
        changeValue: engagement.rate ? engagement.rate - prevEngagement.rate : 0,
      },
    ],
    [
      totalSent,
      t,
      prevTotalSent,
      opened.total,
      opened.rate,
      totalDelivered,
      prevOpenRate,
      openRate,
      engagement.total,
      engagement.rate,
      prevEngagement.rate,
    ],
  );
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {metrics.map((metric) => {
        const { title, value, rate, hint, changeValue, lowerIsBetter } = metric;
        return (
          <KeyPerformanceIndicator
            key={title}
            title={title}
            data={{ current: String(value), rate, change: changeValue }}
            isLoading={false}
            tooltip={hint}
            lowerIsBetter={lowerIsBetter}
          />
        );
      })}
    </Box>
  );
};

const placeholderData: QuerySendoutAnalyticsResponse = generatePlaceholderData(10);

const useAnalyticsSendoutsOverview = () => {
  const { routes: analyticsRoutes } = useAnalyticsNavigator();
  const { sendAnalytics } = useAnalytics();

  const dateRange = useAtomValue(dateRangeLast7DaysAtom);
  const startDate = dateRange.startDate.toISOString();
  const endDate = dateRange.endDate.toISOString();

  const prevStartDate = useMemo(() => {
    const currStart = new Date(startDate).getTime();
    const currEnd = new Date(endDate).getTime();
    const diff = currEnd - currStart;
    return new Date(currStart - diff).toISOString();
  }, [startDate, endDate]);

  const prevEndDate = useMemo(() => {
    const currStart = new Date(startDate).getTime();
    const currEnd = new Date(endDate).getTime();
    const diff = currEnd - currStart;
    return new Date(currEnd - diff).toISOString();
  }, [startDate, endDate]);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: sendoutAnalytics, isLoading } = useQuerySendoutAnalytics(
    { businessId: businessId || '', timeRangeStart: startDate, timeRangeEnd: endDate },
    { enabled: !!businessId, placeholderData },
  );
  const { data: prevSendoutAnalytics, isLoading: prevIsLoading } = useQuerySendoutAnalytics(
    { businessId: businessId || '', timeRangeStart: prevStartDate, timeRangeEnd: prevEndDate },
    { enabled: !!businessId, placeholderData },
  );

  const rows = React.useMemo(() => sendoutAnalytics?.entity?.items || [], [sendoutAnalytics]);
  const prevRows = React.useMemo(() => prevSendoutAnalytics?.entity?.items || [], [prevSendoutAnalytics]);

  const filteredData = React.useMemo(
    () =>
      rows.map((val) => {
        const displayErrorCount = (val.sentCount || 0) - (val.deliveredCount || 0);
        if ((val.errors?.total || 0) < displayErrorCount) {
          const unknownStatusCount = displayErrorCount - (val.errors?.total || 0);
          // Show difference as In Progress/Not enough info
          const newErrors = {
            items: [
              {
                error: {
                  key: '$custom_$unknownStatus',
                  code: '$custom',
                  subcode: '$unknownStatus',
                  userMessage: 'In progress/Not enough info',
                },
                count: unknownStatusCount,
              },
              ...(val.errors?.items || []),
            ],
            total: displayErrorCount,
          };
          return {
            ...val,
            errors: newErrors,
          };
        }
        return val;
      }),
    [rows],
  );

  const prevFilteredData = React.useMemo(
    () =>
      prevRows.map((val) => {
        const displayErrorCount = (val.sentCount || 0) - (val.deliveredCount || 0);
        if ((val.errors?.total || 0) < displayErrorCount) {
          const unknownStatusCount = displayErrorCount - (val.errors?.total || 0);
          // Show difference as In Progress/Not enough info
          const newErrors = {
            items: [
              {
                error: {
                  key: '$custom_$unknownStatus',
                  code: '$custom',
                  subcode: '$unknownStatus',
                  userMessage: 'In progress/Not enough info',
                },
                count: unknownStatusCount,
              },
              ...(val.errors?.items || []),
            ],
            total: displayErrorCount,
          };
          return {
            ...val,
            errors: newErrors,
          };
        }
        return val;
      }),
    [prevRows],
  );

  const handleShowAllCampaigns = () => {
    sendAnalytics('homepage_analytics.campaigns_overview.show_all_clicked');

    return analyticsRoutes.campaigns.navigate();
  };

  return {
    filteredData,
    prevFilteredData,
    isLoading: isLoading || prevIsLoading,
    handleShowAllCampaigns,
  };
};

export const CampaignsOverviewV2 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });
  const { filteredData, prevFilteredData, isLoading, handleShowAllCampaigns } = useAnalyticsSendoutsOverview();

  return (
    <ConnectlyCard title="Campaigns overview">
      {!isLoading && <CampaignsOverviewMetrics filteredRows={filteredData} prevFilteredData={prevFilteredData} />}

      <Stack direction="row" justifyContent="center">
        <Button sx={{ mt: 2 }} onClick={handleShowAllCampaigns} variant="contained" color="secondary">
          {t('showAll', 'Show all')}
        </Button>
      </Stack>
    </ConnectlyCard>
  );
};
