import { Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const AUDIENCE_COHORTS_QUERY_URL = '/internal/v1/businesses/{input.businessId}/list/audience_cohorts';
type QueryUrl = typeof AUDIENCE_COHORTS_QUERY_URL;

export type AudienceCohortsParameters = {
  businessId: string;
} & Paths['audienceV1'][QueryUrl]['post']['parameters']['body']['input'];

export type AudienceCohortsResponse = Paths['audienceV1'][QueryUrl]['post']['responses']['200']['schema'];

export type AudienceCohortsOptions<TData = AudienceCohortsResponse> = Omit<
  UseQueryOptions<AudienceCohortsResponse, unknown, TData, readonly [target: 'audienceCohort', segmentId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
>;

export type AudienceCohort = NonNullable<NonNullable<AudienceCohortsResponse['entity']>['entries']>[0];
