import { ViewPlugin, EditorView, Decoration, DecorationSet, MatchDecorator, ViewUpdate } from '@codemirror/view';
import { VariableWidget } from './VariableWidget';
import { VariableViewPluginProps } from './types';

export const VariableViewPlugin = ({ handleVariableClick, lineHeight, variableTypeOf }: VariableViewPluginProps) => {
  const placeholderMatcher = new MatchDecorator({
    regexp: /{{([^{}]*)}}/gi,

    decorate: (add, from, to, match, _) => {
      const variable = match[1];
      const variableWidget = new VariableWidget({
        from,
        to,
        variable,
        onClick: handleVariableClick,
        lineHeight,
        variableType: variableTypeOf(variable),
      });
      add(from, to, Decoration.replace({ widget: variableWidget }));
    },
  });

  return ViewPlugin.fromClass(
    class {
      placeholders: DecorationSet;

      constructor(view: EditorView) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }

      update(update: ViewUpdate) {
        this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders);
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none;
        }),
    },
  );
};
