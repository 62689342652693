import React, { FC, useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  InfoOutlinedIcon,
  Stack,
  Switch,
  Collapse,
  DesktopDatePicker,
  HourglassEmptyRoundedIcon,
  FormControlLabel,
  TextField,
  Label,
  Radio,
  TimePicker,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { campaignDateAtom, documentIdAtom, sendoutIdAtom, frequencyCapAtom } from '@atoms/flow';
import { useAtom, useAtomValue } from 'jotai';
import {
  useMeData,
  selectBusinessId,
  useCampaignDate,
  useFeatureFlag,
  useAnalytics,
  useMutationSendoutExecutionOptions,
  useResourceLimitView,
  SpreadsheetFrquencyCapOption,
} from '../../../../hooks';
import { useCampaignV3 } from '../../hooks/useCampaignV3';
import { NotificationContext } from '../../../../contexts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customTextField = (props: any) => <TextField sx={{ '& fieldset': { borderRadius: '10px' } }} {...props} />;
const useChooseTime = () => {
  const documentId = useAtomValue(documentIdAtom);
  const sendoutId = useAtomValue(sendoutIdAtom);
  const [campaignDate, setCampaignDate] = useAtom(campaignDateAtom);
  const campaignDateMemo = useMemo(() => (campaignDate ? new Date(campaignDate) : null), [campaignDate]);
  const { dateRestrictions } = useCampaignDate();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { enabled: frequencyCapEnabledGlobally } = useResourceLimitView({ businessId });
  const [frequencyCapOption, setFrequencyCapOption] = useAtom(frequencyCapAtom);
  const capOn = useMemo(() => {
    return frequencyCapOption === 'FREQUENCY_CAP_OPTION_ENABLED';
  }, [frequencyCapOption]);
  const { notificationProvider } = useContext(NotificationContext);
  const { mutate, isLoading: changeIsLoading } = useMutationSendoutExecutionOptions({
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to update setting. Please try again',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: (data, settings) => {
      setFrequencyCapOption(settings.frequencyCapOption);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Setting updated.',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });
  const { handleScheduleToNow, handleScheduleToISODate } = useCampaignV3();

  const handleDateChange = useCallback(
    (date: Date | null) => {
      setCampaignDate(date);
      if (!date) return;
      handleScheduleToISODate(date?.toISOString());
    },
    [setCampaignDate, handleScheduleToISODate],
  );

  const handleClickSendNow = useCallback(() => {
    setCampaignDate(null);
    handleScheduleToNow();
  }, [setCampaignDate, handleScheduleToNow]);

  const handleClickSchedule = useCallback(() => {
    const to5minLater = new Date();
    to5minLater.setMinutes(to5minLater.getMinutes() + 5);
    setCampaignDate(to5minLater);
    handleScheduleToISODate(to5minLater.toISOString());
  }, [setCampaignDate, handleScheduleToISODate]);
  const { t } = useTranslation('translation', { keyPrefix: 'flow.chooseTime' });
  const { title, sendNow, schedule, frequencyCap } = useMemo(
    () => ({
      title: t('title'),
      sendNow: {
        title: t('sendNow.title'),
        subtitle: t('sendNow.subtitle'),
      },
      schedule: {
        title: t('schedule.title'),
        subtitle: t('schedule.subtitle'),
      },
      frequencyCap: {
        title: t('frequencyCap.title'),
        onInfo: t('frequencyCap.onInfo'),
        offInfo: t('frequencyCap.offInfo'),
      },
    }),
    [t],
  );

  const { ffEnableCampaignSettings } = useFeatureFlag(['ffEnableCampaignSettings']);
  const { sendAnalytics } = useAnalytics();
  const handleCapChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!businessId || !sendoutId) {
        return;
      }
      const isOn = event.target.checked;
      const newOption: SpreadsheetFrquencyCapOption = isOn
        ? 'FREQUENCY_CAP_OPTION_ENABLED'
        : 'FREQUENCY_CAP_OPTION_DISABLED';
      const trackEvent = isOn ? '(flow) enable frequency cap' : '(flow) disable frequency cap';
      sendAnalytics(trackEvent, { businessId, documentId, sendoutId });
      mutate({ businessId, sendoutId, frequencyCapOption: newOption });
    },
    [businessId, documentId, mutate, sendAnalytics, sendoutId],
  );

  const theme = useTheme();

  return {
    campaignDate,
    campaignDateMemo,
    capOn,
    changeIsLoading,
    dateRestrictions,
    ffEnableCampaignSettings,
    frequencyCap,
    frequencyCapEnabledGlobally,
    handleCapChange,
    handleClickSchedule,
    handleClickSendNow,
    handleDateChange,
    schedule,
    sendNow,
    theme,
    title,
  };
};
export const ChooseTime: FC = () => {
  const {
    campaignDate,
    campaignDateMemo,
    capOn,
    changeIsLoading,
    dateRestrictions,
    ffEnableCampaignSettings,
    frequencyCap,
    frequencyCapEnabledGlobally,
    handleCapChange,
    handleClickSchedule,
    handleClickSendNow,
    handleDateChange,
    schedule,
    sendNow,
    theme,
    title,
  } = useChooseTime();
  return (
    <>
      <Label variant="h6" gutterBottom>
        {title}
      </Label>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {ffEnableCampaignSettings && frequencyCapEnabledGlobally && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '10px',
            }}
          >
            <FormControlLabel
              checked={!campaignDate}
              onClick={handleClickSendNow}
              control={<Switch checked={capOn} onChange={handleCapChange} disabled={changeIsLoading} />}
              label={
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <HourglassEmptyRoundedIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />
                  <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px', ml: 0.25 }}>
                    {frequencyCap.title}
                  </Label>
                </Stack>
              }
              labelPlacement="start"
              sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
            />
            <Stack
              direction="row"
              sx={{
                p: 2,
                background: capOn ? theme.palette.secondary.light : '#F8F1E6',
                borderRadius: '10px',
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 1, color: capOn ? theme.palette.primary.main : '#E7A242' }} />
              <Label variant="body1" sx={{ color: '#1C3B5E' }}>
                {capOn ? frequencyCap.onInfo : frequencyCap.offInfo}
              </Label>
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            checked={!campaignDate}
            onClick={handleClickSendNow}
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px' }}>
                {sendNow.title}
              </Label>
            }
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1.25 }}>
            {sendNow.subtitle}
          </Label>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            checked={!!campaignDate}
            onClick={handleClickSchedule}
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px' }}>
                {schedule.title}
              </Label>
            }
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1.25 }}>
            {schedule.subtitle}
          </Label>
          <Collapse in={!!campaignDate}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
              }}
            >
              <DesktopDatePicker
                label="Date"
                disablePast
                minDate={dateRestrictions.minDate}
                maxDate={dateRestrictions.maxDate}
                openTo="day"
                value={campaignDateMemo}
                views={['day']}
                onChange={handleDateChange}
                slots={{
                  textField: customTextField,
                }}
              />
              <TimePicker
                label="Time"
                minTime={dateRestrictions.minTime}
                maxTime={dateRestrictions.maxTime}
                value={campaignDateMemo}
                onChange={handleDateChange}
                orientation="portrait"
                slots={{
                  textField: customTextField,
                }}
              />
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};
