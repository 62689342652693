import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { flatMap, uniqBy } from 'lodash';
import { useAtomValue } from 'jotai';
import { edgesAtom, nodesAtom, compileResultAtom } from '@atoms/flow';
import {
  TemplateComponent,
  selectMessageTemplateIndex,
  useMessageTemplateGroupsData,
} from '@hooks/useMessageTemplateGroupsData';
import { useMeData, selectBusinessId } from '@hooks/useMeData';
import { HandleActionType, createHandleId } from '../../../../sdks/flow/createHandleId';
import { selectInvalidTemplates } from '../../../../state/messageTemplates';
import { Warning, CompileWarning } from './types';
import { NEEDS_AT_LEAST_ONE_MESSAGE_WARNING } from './constants';
import {
  mapNodesWithUnconfiguredTemplatesToWarning,
  mapTemplateNotFoundToWarning,
  mapUnconnectedButtonsToWarning,
  mapStrayNodesToWarning,
  mapNodesWithPendingTemplatesToWarning,
  mapNodesWithTemplateErrorsToWarning,
  mapCompileResultErrorsToWarning,
} from './mappers';
import { shouldShowError } from '../../mappers';
import { useTemplateStatus } from '../useTemplateStatus';

export const useFlowChecks = () => {
  const invalidTemplates = useSelector(selectInvalidTemplates);
  const compileResult = useAtomValue(compileResultAtom);
  const nodes = useAtomValue(nodesAtom);
  const edges = useAtomValue(edgesAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: templateIndex = {}, isFetching: isTemplateFetching } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateIndex,
    keepPreviousData: true,
  });

  const { pendingTemplates } = useTemplateStatus();

  const compileResultErrors = useMemo(
    () =>
      compileResult?.items?.reduce<CompileWarning[]>((acc, item) => {
        if (!shouldShowError(item)) return acc;
        const node = nodes.find((n) => n.id === item.id);
        if (!node) return acc;
        return [...acc, { node, error: item }];
      }, []) || [],
    [compileResult, nodes],
  );

  const templateNotFound = useMemo(
    () =>
      nodes.filter((node) => {
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN') return false;
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT') return false;
        if (!node?.data?.v1?.templateId) return false;
        if (templateIndex[node?.data?.v1?.templateId] || templateIndex[node?.data?.v1?.waMessageTemplateId])
          return false;
        if (pendingTemplates.find((nodeId) => node?.id === nodeId)) return false;
        return true;
      }),
    [nodes, templateIndex, pendingTemplates],
  );

  const strayNodes = useMemo(
    () =>
      nodes.filter((node) => {
        if (
          node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' ||
          node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT'
        )
          return false;
        return !edges.some((edge) => edge.target === node.id);
      }),
    [nodes, edges],
  );

  const quickReplyButtons = useMemo(
    () =>
      flatMap(
        nodes.map((node) =>
          node?.data?.v1?.messageTemplateInput?.templateComponents
            ? node.data.v1.messageTemplateInput.templateComponents
                .filter((component: TemplateComponent) => !!component.button)
                .map((button: TemplateComponent, buttonIndex: number) =>
                  button?.button?.quickReply
                    ? {
                        buttonIndex,
                        label: button?.button?.quickReply?.text,
                        node,
                      }
                    : null,
                )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((result: any) => !!result) || []
            : [],
        ),
      ),
    [nodes],
  );
  const listMessageRows = useMemo(
    () =>
      flatMap(
        flatMap(
          nodes.map((node) =>
            node?.data?.v1?.messageTemplateInput?.templateComponents
              ? node.data.v1.messageTemplateInput.templateComponents
                  .filter(
                    (component: TemplateComponent) =>
                      !!component.list &&
                      !!component.list?.sections &&
                      component.list?.sections.length > 0 &&
                      !!component.list?.sections[0].rows &&
                      component.list?.sections[0].rows.length > 0,
                  )
                  .map((component: TemplateComponent) =>
                    component.list?.sections?.[0].rows?.map((row, buttonIndex) => ({
                      buttonIndex,
                      label: row.text,
                      node,
                    })),
                  ) || []
              : [],
          ),
        ),
      ),
    [nodes],
  );

  const unconnectedButtons = useMemo(
    () =>
      [...quickReplyButtons, ...listMessageRows].filter(({ node, buttonIndex }) => {
        const handleType = {
          nodeId: node.id,
          nodeType: node.type,
          actionType: 'button-click' as HandleActionType,
          buttonIndex,
        };
        const handleId = createHandleId(handleType);
        return !edges.find((edge) => edge.sourceHandle === handleId);
      }),
    [quickReplyButtons, edges, listMessageRows],
  );

  const nodesWithTemplateErrors = useMemo(
    () =>
      flatMap(
        nodes
          .map((node) => ({
            node,
            invalidTemplate: invalidTemplates.find(([key]) => node?.id === key)?.[1],
          }))
          .filter(({ invalidTemplate }) => !!invalidTemplate)
          .map(({ node, invalidTemplate }) =>
            invalidTemplate?.errors
              ? Object.entries(invalidTemplate?.errors)
                  .filter(([_, error]) => !!error)
                  .map(([part, error]) => ({ part, error, node }))
              : [],
          ),
      ),
    [nodes, invalidTemplates],
  );

  const nodesWithUnconfiguredTemplates = useMemo(
    () =>
      nodes.filter(
        (node) =>
          node?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE' && !node?.data?.v1?.messageTemplateInput,
      ),
    [nodes],
  );

  const nodesWithPendingTemplates = useMemo(
    () => nodes.filter((node) => pendingTemplates.find((nodeId) => node?.id === nodeId)),
    [nodes, pendingTemplates],
  );

  const needsAtLeastOneMessage: Warning[] = useMemo(
    () =>
      nodes.filter(
        (node) =>
          node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' && node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT',
      ).length === 0
        ? [NEEDS_AT_LEAST_ONE_MESSAGE_WARNING]
        : [],
    [nodes],
  );

  const warnings: Warning[] = useMemo(
    () =>
      [
        ...uniqBy(
          compileResultErrors.map((x) => mapCompileResultErrorsToWarning(x)),
          (t) => t.key,
        ),
        ...nodesWithTemplateErrors.map((x) => mapNodesWithTemplateErrorsToWarning(x)),
        ...unconnectedButtons.map((x) => mapUnconnectedButtonsToWarning(x)),
        ...strayNodes.map((x) => mapStrayNodesToWarning(x)),
        ...templateNotFound.map((x) => mapTemplateNotFoundToWarning(x)),
        ...nodesWithUnconfiguredTemplates.map((x) => mapNodesWithUnconfiguredTemplatesToWarning(x)),
        ...nodesWithPendingTemplates.map((x) => mapNodesWithPendingTemplatesToWarning(x)),
        ...needsAtLeastOneMessage,
      ].sort((a, b) => a.nodeName?.localeCompare(b.nodeName)),
    [
      compileResultErrors,
      unconnectedButtons,
      strayNodes,
      templateNotFound,
      needsAtLeastOneMessage,
      nodesWithTemplateErrors,
      nodesWithUnconfiguredTemplates,
      nodesWithPendingTemplates,
    ],
  );

  return { warnings, isIdle: isTemplateFetching && templateNotFound.length > 0 };
};
