import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';
import { usePrevious } from '..';
import { AppState } from '../../state/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const indent = (json: any) => JSON.stringify(json, null, '  ');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSendSliceChangesToDatadog = (sliceSelector: (state: AppState) => any) => {
  const slice = useSelector(sliceSelector);
  const previousSlice = usePrevious(slice);
  useEffect(() => {
    if (!previousSlice) return;
    const changes = diff(previousSlice, slice);

    // return if nothing has changed
    if (!changes || Object.keys(changes).length === 0) return;

    const title = `Slice changes`.substring(0, 100);
    datadogRum.addAction(title, { changes: indent(changes) });
    datadogRum.addAction('Current slice state', { templatesState: indent(slice) });
  }, [previousSlice, slice]);
};
