import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { WelcomeDIContext } from '@connectlyai-features/welcome';
import { WhatsAppOnboardingProps } from './types';
import { applyHook, track } from '../../utils';
import {
  WhatsAppOnboardingStep,
  goToStep,
  selectBusinessProfilePicture,
  selectStep,
  setBusinessProfilePicture,
} from '../../features/welcome';
import { BusinessName, BusinessProfilePicture, Connect, PhoneNumberOld, PhoneNumberSelector } from './Steps';
import { ApprovalDialog } from '../ApprovalDialog';
import { selectBusinessId, useEffectOnce, useMeData, useUpdateWhatsAppProfilePicture } from '../../hooks';
import { useClaimNumber } from '../../features/welcome/hooks/useClaimNumber';

const useWhatsAppOnboarding = ({
  onNextStep,
  whatsApp,
}: Partial<WhatsAppOnboardingProps>): Partial<WhatsAppOnboardingProps> => {
  const picture = useSelector(selectBusinessProfilePicture);
  const { updatePicture } = useUpdateWhatsAppProfilePicture();

  const dispatch = useDispatch();
  const step = useSelector(selectStep) as WhatsAppOnboardingStep;

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  useEffect(() => {
    if (whatsApp?.isWhatsAppActive) {
      setConfirmationOpen(true);
      if (picture) {
        updatePicture(picture);
        dispatch(setBusinessProfilePicture(''));
      }
    }
  }, [whatsApp?.isWhatsAppActive, step, updatePicture, picture, dispatch]);

  const handleClose = useCallback(() => {
    setConfirmationOpen(false);

    dispatch(goToStep('connectShopify'));
    if (onNextStep) {
      onNextStep();
    }
  }, [dispatch, onNextStep]);

  return {
    confirmationOpen,
    onClose: handleClose,
    step,
    whatsApp,
  };
};

export const WhatsAppOnboardingPresentation = ({
  confirmationOpen,
  onClose,
  step,
  whatsApp,
}: WhatsAppOnboardingProps) => {
  const di = useContext(WelcomeDIContext);
  if (di === undefined) {
    return null;
  }
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [isClaiming, setIsClaiming] = useState(false);
  const { claimNumber } = useClaimNumber({ setClaimNumberIsLoading: setIsClaiming });
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding' });

  useEffectOnce(() => {
    track('(onboarding) load phone number selector', { businessId });
  });
  let content = null;
  switch (step) {
    case 'phoneNumberSelector': {
      content = <PhoneNumberSelector />;
      break;
    }
    case 'phoneNumberOld': {
      content = <PhoneNumberOld />;
      break;
    }
    case 'businessName': {
      content = <BusinessName claimNumber={claimNumber} claimedPhoneNumber={whatsApp?.claimedNumber} />;
      break;
    }
    case 'businessProfilePicture': {
      content = <BusinessProfilePicture />;
      break;
    }
    case 'connectWhatsApp': {
      content = (
        <Connect
          claimedPhoneNumber={whatsApp?.claimedNumber}
          verificationCode={whatsApp?.verificationCode}
          isClaiming={isClaiming}
          onClaimNumber={claimNumber}
        />
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box sx={{ px: 3, pt: 4.5, pb: 3 }}>
      <ApprovalDialog open={confirmationOpen} title={t('approvalTitle')} onClose={onClose} />
      {content}
    </Box>
  );
};

export const WhatsAppOnboarding = applyHook(WhatsAppOnboardingPresentation, useWhatsAppOnboarding);
