import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.twilio.webhook.v20100401";
function createBaseTwilioMessagingWebhookEvent() {
    return {
        MessagingServiceSid: "",
        MessageSid: "",
        AccountSid: "",
        NumMedia: "",
        Body: "",
        From: "",
        FromCountry: "",
        FromZip: "",
        FromState: "",
        FromCity: "",
        To: "",
        ToCountry: "",
        ToZip: "",
        ToState: "",
        ToCity: "",
        OptOutType: "",
        MessageStatus: "",
        ProfileName: "",
        WaId: "",
        ButtonText: "",
        MediaContentType0: "",
        MediaUrl0: "",
        MediaContentType1: "",
        MediaUrl1: "",
        MediaContentType2: "",
        MediaUrl2: "",
        MediaContentType3: "",
        MediaUrl3: "",
        MediaContentType4: "",
        MediaUrl4: "",
        MediaContentType5: "",
        MediaUrl5: "",
        MediaContentType6: "",
        MediaUrl6: "",
        MediaContentType7: "",
        MediaUrl7: "",
        MediaContentType8: "",
        MediaUrl8: "",
        MediaContentType9: "",
        MediaUrl9: "",
    };
}
export const TwilioMessagingWebhookEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.MessagingServiceSid !== "") {
            writer.uint32(10).string(message.MessagingServiceSid);
        }
        if (message.MessageSid !== "") {
            writer.uint32(18).string(message.MessageSid);
        }
        if (message.AccountSid !== "") {
            writer.uint32(26).string(message.AccountSid);
        }
        if (message.NumMedia !== "") {
            writer.uint32(34).string(message.NumMedia);
        }
        if (message.Body !== "") {
            writer.uint32(42).string(message.Body);
        }
        if (message.From !== "") {
            writer.uint32(50).string(message.From);
        }
        if (message.FromCountry !== "") {
            writer.uint32(58).string(message.FromCountry);
        }
        if (message.FromZip !== "") {
            writer.uint32(66).string(message.FromZip);
        }
        if (message.FromState !== "") {
            writer.uint32(74).string(message.FromState);
        }
        if (message.FromCity !== "") {
            writer.uint32(82).string(message.FromCity);
        }
        if (message.To !== "") {
            writer.uint32(90).string(message.To);
        }
        if (message.ToCountry !== "") {
            writer.uint32(98).string(message.ToCountry);
        }
        if (message.ToZip !== "") {
            writer.uint32(106).string(message.ToZip);
        }
        if (message.ToState !== "") {
            writer.uint32(114).string(message.ToState);
        }
        if (message.ToCity !== "") {
            writer.uint32(122).string(message.ToCity);
        }
        if (message.OptOutType !== "") {
            writer.uint32(130).string(message.OptOutType);
        }
        if (message.MessageStatus !== "") {
            writer.uint32(138).string(message.MessageStatus);
        }
        if (message.ProfileName !== "") {
            writer.uint32(146).string(message.ProfileName);
        }
        if (message.WaId !== "") {
            writer.uint32(154).string(message.WaId);
        }
        if (message.ButtonText !== "") {
            writer.uint32(322).string(message.ButtonText);
        }
        if (message.MediaContentType0 !== "") {
            writer.uint32(162).string(message.MediaContentType0);
        }
        if (message.MediaUrl0 !== "") {
            writer.uint32(170).string(message.MediaUrl0);
        }
        if (message.MediaContentType1 !== "") {
            writer.uint32(178).string(message.MediaContentType1);
        }
        if (message.MediaUrl1 !== "") {
            writer.uint32(186).string(message.MediaUrl1);
        }
        if (message.MediaContentType2 !== "") {
            writer.uint32(194).string(message.MediaContentType2);
        }
        if (message.MediaUrl2 !== "") {
            writer.uint32(202).string(message.MediaUrl2);
        }
        if (message.MediaContentType3 !== "") {
            writer.uint32(210).string(message.MediaContentType3);
        }
        if (message.MediaUrl3 !== "") {
            writer.uint32(218).string(message.MediaUrl3);
        }
        if (message.MediaContentType4 !== "") {
            writer.uint32(226).string(message.MediaContentType4);
        }
        if (message.MediaUrl4 !== "") {
            writer.uint32(234).string(message.MediaUrl4);
        }
        if (message.MediaContentType5 !== "") {
            writer.uint32(242).string(message.MediaContentType5);
        }
        if (message.MediaUrl5 !== "") {
            writer.uint32(250).string(message.MediaUrl5);
        }
        if (message.MediaContentType6 !== "") {
            writer.uint32(258).string(message.MediaContentType6);
        }
        if (message.MediaUrl6 !== "") {
            writer.uint32(266).string(message.MediaUrl6);
        }
        if (message.MediaContentType7 !== "") {
            writer.uint32(274).string(message.MediaContentType7);
        }
        if (message.MediaUrl7 !== "") {
            writer.uint32(282).string(message.MediaUrl7);
        }
        if (message.MediaContentType8 !== "") {
            writer.uint32(290).string(message.MediaContentType8);
        }
        if (message.MediaUrl8 !== "") {
            writer.uint32(298).string(message.MediaUrl8);
        }
        if (message.MediaContentType9 !== "") {
            writer.uint32(306).string(message.MediaContentType9);
        }
        if (message.MediaUrl9 !== "") {
            writer.uint32(314).string(message.MediaUrl9);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTwilioMessagingWebhookEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.MessagingServiceSid = reader.string();
                    break;
                case 2:
                    message.MessageSid = reader.string();
                    break;
                case 3:
                    message.AccountSid = reader.string();
                    break;
                case 4:
                    message.NumMedia = reader.string();
                    break;
                case 5:
                    message.Body = reader.string();
                    break;
                case 6:
                    message.From = reader.string();
                    break;
                case 7:
                    message.FromCountry = reader.string();
                    break;
                case 8:
                    message.FromZip = reader.string();
                    break;
                case 9:
                    message.FromState = reader.string();
                    break;
                case 10:
                    message.FromCity = reader.string();
                    break;
                case 11:
                    message.To = reader.string();
                    break;
                case 12:
                    message.ToCountry = reader.string();
                    break;
                case 13:
                    message.ToZip = reader.string();
                    break;
                case 14:
                    message.ToState = reader.string();
                    break;
                case 15:
                    message.ToCity = reader.string();
                    break;
                case 16:
                    message.OptOutType = reader.string();
                    break;
                case 17:
                    message.MessageStatus = reader.string();
                    break;
                case 18:
                    message.ProfileName = reader.string();
                    break;
                case 19:
                    message.WaId = reader.string();
                    break;
                case 40:
                    message.ButtonText = reader.string();
                    break;
                case 20:
                    message.MediaContentType0 = reader.string();
                    break;
                case 21:
                    message.MediaUrl0 = reader.string();
                    break;
                case 22:
                    message.MediaContentType1 = reader.string();
                    break;
                case 23:
                    message.MediaUrl1 = reader.string();
                    break;
                case 24:
                    message.MediaContentType2 = reader.string();
                    break;
                case 25:
                    message.MediaUrl2 = reader.string();
                    break;
                case 26:
                    message.MediaContentType3 = reader.string();
                    break;
                case 27:
                    message.MediaUrl3 = reader.string();
                    break;
                case 28:
                    message.MediaContentType4 = reader.string();
                    break;
                case 29:
                    message.MediaUrl4 = reader.string();
                    break;
                case 30:
                    message.MediaContentType5 = reader.string();
                    break;
                case 31:
                    message.MediaUrl5 = reader.string();
                    break;
                case 32:
                    message.MediaContentType6 = reader.string();
                    break;
                case 33:
                    message.MediaUrl6 = reader.string();
                    break;
                case 34:
                    message.MediaContentType7 = reader.string();
                    break;
                case 35:
                    message.MediaUrl7 = reader.string();
                    break;
                case 36:
                    message.MediaContentType8 = reader.string();
                    break;
                case 37:
                    message.MediaUrl8 = reader.string();
                    break;
                case 38:
                    message.MediaContentType9 = reader.string();
                    break;
                case 39:
                    message.MediaUrl9 = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            MessagingServiceSid: isSet(object.MessagingServiceSid)
                ? String(object.MessagingServiceSid)
                : "",
            MessageSid: isSet(object.MessageSid) ? String(object.MessageSid) : "",
            AccountSid: isSet(object.AccountSid) ? String(object.AccountSid) : "",
            NumMedia: isSet(object.NumMedia) ? String(object.NumMedia) : "",
            Body: isSet(object.Body) ? String(object.Body) : "",
            From: isSet(object.From) ? String(object.From) : "",
            FromCountry: isSet(object.FromCountry) ? String(object.FromCountry) : "",
            FromZip: isSet(object.FromZip) ? String(object.FromZip) : "",
            FromState: isSet(object.FromState) ? String(object.FromState) : "",
            FromCity: isSet(object.FromCity) ? String(object.FromCity) : "",
            To: isSet(object.To) ? String(object.To) : "",
            ToCountry: isSet(object.ToCountry) ? String(object.ToCountry) : "",
            ToZip: isSet(object.ToZip) ? String(object.ToZip) : "",
            ToState: isSet(object.ToState) ? String(object.ToState) : "",
            ToCity: isSet(object.ToCity) ? String(object.ToCity) : "",
            OptOutType: isSet(object.OptOutType) ? String(object.OptOutType) : "",
            MessageStatus: isSet(object.MessageStatus)
                ? String(object.MessageStatus)
                : "",
            ProfileName: isSet(object.ProfileName) ? String(object.ProfileName) : "",
            WaId: isSet(object.WaId) ? String(object.WaId) : "",
            ButtonText: isSet(object.ButtonText) ? String(object.ButtonText) : "",
            MediaContentType0: isSet(object.MediaContentType0)
                ? String(object.MediaContentType0)
                : "",
            MediaUrl0: isSet(object.MediaUrl0) ? String(object.MediaUrl0) : "",
            MediaContentType1: isSet(object.MediaContentType1)
                ? String(object.MediaContentType1)
                : "",
            MediaUrl1: isSet(object.MediaUrl1) ? String(object.MediaUrl1) : "",
            MediaContentType2: isSet(object.MediaContentType2)
                ? String(object.MediaContentType2)
                : "",
            MediaUrl2: isSet(object.MediaUrl2) ? String(object.MediaUrl2) : "",
            MediaContentType3: isSet(object.MediaContentType3)
                ? String(object.MediaContentType3)
                : "",
            MediaUrl3: isSet(object.MediaUrl3) ? String(object.MediaUrl3) : "",
            MediaContentType4: isSet(object.MediaContentType4)
                ? String(object.MediaContentType4)
                : "",
            MediaUrl4: isSet(object.MediaUrl4) ? String(object.MediaUrl4) : "",
            MediaContentType5: isSet(object.MediaContentType5)
                ? String(object.MediaContentType5)
                : "",
            MediaUrl5: isSet(object.MediaUrl5) ? String(object.MediaUrl5) : "",
            MediaContentType6: isSet(object.MediaContentType6)
                ? String(object.MediaContentType6)
                : "",
            MediaUrl6: isSet(object.MediaUrl6) ? String(object.MediaUrl6) : "",
            MediaContentType7: isSet(object.MediaContentType7)
                ? String(object.MediaContentType7)
                : "",
            MediaUrl7: isSet(object.MediaUrl7) ? String(object.MediaUrl7) : "",
            MediaContentType8: isSet(object.MediaContentType8)
                ? String(object.MediaContentType8)
                : "",
            MediaUrl8: isSet(object.MediaUrl8) ? String(object.MediaUrl8) : "",
            MediaContentType9: isSet(object.MediaContentType9)
                ? String(object.MediaContentType9)
                : "",
            MediaUrl9: isSet(object.MediaUrl9) ? String(object.MediaUrl9) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.MessagingServiceSid !== undefined &&
            (obj.MessagingServiceSid = message.MessagingServiceSid);
        message.MessageSid !== undefined && (obj.MessageSid = message.MessageSid);
        message.AccountSid !== undefined && (obj.AccountSid = message.AccountSid);
        message.NumMedia !== undefined && (obj.NumMedia = message.NumMedia);
        message.Body !== undefined && (obj.Body = message.Body);
        message.From !== undefined && (obj.From = message.From);
        message.FromCountry !== undefined &&
            (obj.FromCountry = message.FromCountry);
        message.FromZip !== undefined && (obj.FromZip = message.FromZip);
        message.FromState !== undefined && (obj.FromState = message.FromState);
        message.FromCity !== undefined && (obj.FromCity = message.FromCity);
        message.To !== undefined && (obj.To = message.To);
        message.ToCountry !== undefined && (obj.ToCountry = message.ToCountry);
        message.ToZip !== undefined && (obj.ToZip = message.ToZip);
        message.ToState !== undefined && (obj.ToState = message.ToState);
        message.ToCity !== undefined && (obj.ToCity = message.ToCity);
        message.OptOutType !== undefined && (obj.OptOutType = message.OptOutType);
        message.MessageStatus !== undefined &&
            (obj.MessageStatus = message.MessageStatus);
        message.ProfileName !== undefined &&
            (obj.ProfileName = message.ProfileName);
        message.WaId !== undefined && (obj.WaId = message.WaId);
        message.ButtonText !== undefined && (obj.ButtonText = message.ButtonText);
        message.MediaContentType0 !== undefined &&
            (obj.MediaContentType0 = message.MediaContentType0);
        message.MediaUrl0 !== undefined && (obj.MediaUrl0 = message.MediaUrl0);
        message.MediaContentType1 !== undefined &&
            (obj.MediaContentType1 = message.MediaContentType1);
        message.MediaUrl1 !== undefined && (obj.MediaUrl1 = message.MediaUrl1);
        message.MediaContentType2 !== undefined &&
            (obj.MediaContentType2 = message.MediaContentType2);
        message.MediaUrl2 !== undefined && (obj.MediaUrl2 = message.MediaUrl2);
        message.MediaContentType3 !== undefined &&
            (obj.MediaContentType3 = message.MediaContentType3);
        message.MediaUrl3 !== undefined && (obj.MediaUrl3 = message.MediaUrl3);
        message.MediaContentType4 !== undefined &&
            (obj.MediaContentType4 = message.MediaContentType4);
        message.MediaUrl4 !== undefined && (obj.MediaUrl4 = message.MediaUrl4);
        message.MediaContentType5 !== undefined &&
            (obj.MediaContentType5 = message.MediaContentType5);
        message.MediaUrl5 !== undefined && (obj.MediaUrl5 = message.MediaUrl5);
        message.MediaContentType6 !== undefined &&
            (obj.MediaContentType6 = message.MediaContentType6);
        message.MediaUrl6 !== undefined && (obj.MediaUrl6 = message.MediaUrl6);
        message.MediaContentType7 !== undefined &&
            (obj.MediaContentType7 = message.MediaContentType7);
        message.MediaUrl7 !== undefined && (obj.MediaUrl7 = message.MediaUrl7);
        message.MediaContentType8 !== undefined &&
            (obj.MediaContentType8 = message.MediaContentType8);
        message.MediaUrl8 !== undefined && (obj.MediaUrl8 = message.MediaUrl8);
        message.MediaContentType9 !== undefined &&
            (obj.MediaContentType9 = message.MediaContentType9);
        message.MediaUrl9 !== undefined && (obj.MediaUrl9 = message.MediaUrl9);
        return obj;
    },
};
function createBaseTwilioWhatsappProfile() {
    return { profileName: "", waId: "" };
}
export const TwilioWhatsappProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.profileName !== "") {
            writer.uint32(10).string(message.profileName);
        }
        if (message.waId !== "") {
            writer.uint32(18).string(message.waId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTwilioWhatsappProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.profileName = reader.string();
                    break;
                case 2:
                    message.waId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            profileName: isSet(object.profileName) ? String(object.profileName) : "",
            waId: isSet(object.waId) ? String(object.waId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.profileName !== undefined &&
            (obj.profileName = message.profileName);
        message.waId !== undefined && (obj.waId = message.waId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
