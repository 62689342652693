import React, { useMemo } from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { useMeData, useSettingsData, selectBusinessId } from '@hooks';
import { SMBWrapperProps } from './types';
import { selectSMBWrapperView } from './selectors';
import { DEFAULT_SMB_WRAPPER_VIEW } from './constants';
import { ShopifyAbandonedCheckoutOnboarding } from '../ShopifyAbandonedCheckoutOnboarding';
import { SMB } from '../SMB';

const useSMBWrapper = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: view } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectSMBWrapperView,
  });
  const {
    recommendationIntegration,
    shopifyIntegration,
    shopifyIsActive,
    whatsAppIsActive,
    whatsAppClaimedNumber,
    storeName,
  } = view || DEFAULT_SMB_WRAPPER_VIEW;

  const isOnboarded = useMemo(() => {
    return whatsAppIsActive && shopifyIsActive;
  }, [whatsAppIsActive, shopifyIsActive]);

  return {
    recommendationIntegration,
    shopifyIntegration,
    shopifyIsActive,
    isOnboarded,
    whatsAppIsActive,
    whatsAppClaimedNumber,
    storeName,
  };
};

export const SMBWrapper = ({ verificationCode }: SMBWrapperProps) => {
  const {
    recommendationIntegration,
    shopifyIsActive,
    whatsAppIsActive,
    isOnboarded,
    whatsAppClaimedNumber,
    storeName,
  } = useSMBWrapper();

  return isOnboarded ? (
    <SMB recommendationIntegration={recommendationIntegration} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <ShopifyAbandonedCheckoutOnboarding
        shopify={{
          isShopifyActive: shopifyIsActive,
          storeName,
        }}
        whatsApp={{
          claimedNumber: whatsAppClaimedNumber,
          isWhatsAppActive: whatsAppIsActive,
          verificationCode,
        }}
      />
    </Box>
  );
};
