import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "google.protobuf";
export var NullValue;
(function (NullValue) {
    NullValue[NullValue["NULL_VALUE"] = 0] = "NULL_VALUE";
    NullValue[NullValue["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(NullValue || (NullValue = {}));
export function nullValueFromJSON(object) {
    switch (object) {
        case 0:
        case "NULL_VALUE":
            return NullValue.NULL_VALUE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return NullValue.UNRECOGNIZED;
    }
}
export function nullValueToJSON(object) {
    switch (object) {
        case NullValue.NULL_VALUE:
            return "NULL_VALUE";
        case NullValue.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseStruct() {
    return { fields: {} };
}
export const Struct = {
    encode(message, writer = _m0.Writer.create()) {
        Object.entries(message.fields).forEach(([key, value]) => {
            if (value !== undefined) {
                Struct_FieldsEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
            }
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStruct();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = Struct_FieldsEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.fields[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            fields: isObject(object.fields)
                ? Object.entries(object.fields).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        obj.fields = {};
        if (message.fields) {
            Object.entries(message.fields).forEach(([k, v]) => {
                obj.fields[k] = v;
            });
        }
        return obj;
    },
    wrap(object) {
        const struct = createBaseStruct();
        if (object !== undefined) {
            Object.keys(object).forEach((key) => {
                struct.fields[key] = object[key];
            });
        }
        return struct;
    },
    unwrap(message) {
        const object = {};
        Object.keys(message.fields).forEach((key) => {
            object[key] = message.fields[key];
        });
        return object;
    },
};
function createBaseStruct_FieldsEntry() {
    return { key: "", value: undefined };
}
export const Struct_FieldsEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStruct_FieldsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object === null || object === void 0 ? void 0 : object.value) ? object.value : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseValue() {
    return { kind: undefined };
}
export const Value = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = message.kind) === null || _a === void 0 ? void 0 : _a.$case) === "nullValue") {
            writer.uint32(8).int32(message.kind.nullValue);
        }
        if (((_b = message.kind) === null || _b === void 0 ? void 0 : _b.$case) === "numberValue") {
            writer.uint32(17).double(message.kind.numberValue);
        }
        if (((_c = message.kind) === null || _c === void 0 ? void 0 : _c.$case) === "stringValue") {
            writer.uint32(26).string(message.kind.stringValue);
        }
        if (((_d = message.kind) === null || _d === void 0 ? void 0 : _d.$case) === "boolValue") {
            writer.uint32(32).bool(message.kind.boolValue);
        }
        if (((_e = message.kind) === null || _e === void 0 ? void 0 : _e.$case) === "structValue") {
            Struct.encode(Struct.wrap(message.kind.structValue), writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.kind) === null || _f === void 0 ? void 0 : _f.$case) === "listValue") {
            ListValue.encode(ListValue.wrap(message.kind.listValue), writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.kind = {
                        $case: "nullValue",
                        nullValue: reader.int32(),
                    };
                    break;
                case 2:
                    message.kind = { $case: "numberValue", numberValue: reader.double() };
                    break;
                case 3:
                    message.kind = { $case: "stringValue", stringValue: reader.string() };
                    break;
                case 4:
                    message.kind = { $case: "boolValue", boolValue: reader.bool() };
                    break;
                case 5:
                    message.kind = {
                        $case: "structValue",
                        structValue: Struct.unwrap(Struct.decode(reader, reader.uint32())),
                    };
                    break;
                case 6:
                    message.kind = {
                        $case: "listValue",
                        listValue: ListValue.unwrap(ListValue.decode(reader, reader.uint32())),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            kind: isSet(object.nullValue)
                ? { $case: "nullValue", nullValue: nullValueFromJSON(object.nullValue) }
                : isSet(object.numberValue)
                    ? { $case: "numberValue", numberValue: Number(object.numberValue) }
                    : isSet(object.stringValue)
                        ? { $case: "stringValue", stringValue: String(object.stringValue) }
                        : isSet(object.boolValue)
                            ? { $case: "boolValue", boolValue: Boolean(object.boolValue) }
                            : isSet(object.structValue)
                                ? { $case: "structValue", structValue: object.structValue }
                                : isSet(object.listValue)
                                    ? { $case: "listValue", listValue: [...object.listValue] }
                                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const obj = {};
        ((_a = message.kind) === null || _a === void 0 ? void 0 : _a.$case) === "nullValue" &&
            (obj.nullValue =
                ((_b = message.kind) === null || _b === void 0 ? void 0 : _b.nullValue) !== undefined
                    ? nullValueToJSON((_c = message.kind) === null || _c === void 0 ? void 0 : _c.nullValue)
                    : undefined);
        ((_d = message.kind) === null || _d === void 0 ? void 0 : _d.$case) === "numberValue" &&
            (obj.numberValue = (_e = message.kind) === null || _e === void 0 ? void 0 : _e.numberValue);
        ((_f = message.kind) === null || _f === void 0 ? void 0 : _f.$case) === "stringValue" &&
            (obj.stringValue = (_g = message.kind) === null || _g === void 0 ? void 0 : _g.stringValue);
        ((_h = message.kind) === null || _h === void 0 ? void 0 : _h.$case) === "boolValue" &&
            (obj.boolValue = (_j = message.kind) === null || _j === void 0 ? void 0 : _j.boolValue);
        ((_k = message.kind) === null || _k === void 0 ? void 0 : _k.$case) === "structValue" &&
            (obj.structValue = (_l = message.kind) === null || _l === void 0 ? void 0 : _l.structValue);
        ((_m = message.kind) === null || _m === void 0 ? void 0 : _m.$case) === "listValue" &&
            (obj.listValue = (_o = message.kind) === null || _o === void 0 ? void 0 : _o.listValue);
        return obj;
    },
    wrap(value) {
        const result = createBaseValue();
        if (value === null) {
            result.kind = { $case: "nullValue", nullValue: NullValue.NULL_VALUE };
        }
        else if (typeof value === "boolean") {
            result.kind = { $case: "boolValue", boolValue: value };
        }
        else if (typeof value === "number") {
            result.kind = { $case: "numberValue", numberValue: value };
        }
        else if (typeof value === "string") {
            result.kind = { $case: "stringValue", stringValue: value };
        }
        else if (Array.isArray(value)) {
            result.kind = { $case: "listValue", listValue: value };
        }
        else if (typeof value === "object") {
            result.kind = { $case: "structValue", structValue: value };
        }
        else if (typeof value !== "undefined") {
            throw new Error("Unsupported any value type: " + typeof value);
        }
        return result;
    },
    unwrap(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (((_a = message.kind) === null || _a === void 0 ? void 0 : _a.$case) === "nullValue") {
            return null;
        }
        else if (((_b = message.kind) === null || _b === void 0 ? void 0 : _b.$case) === "numberValue") {
            return (_c = message.kind) === null || _c === void 0 ? void 0 : _c.numberValue;
        }
        else if (((_d = message.kind) === null || _d === void 0 ? void 0 : _d.$case) === "stringValue") {
            return (_e = message.kind) === null || _e === void 0 ? void 0 : _e.stringValue;
        }
        else if (((_f = message.kind) === null || _f === void 0 ? void 0 : _f.$case) === "boolValue") {
            return (_g = message.kind) === null || _g === void 0 ? void 0 : _g.boolValue;
        }
        else if (((_h = message.kind) === null || _h === void 0 ? void 0 : _h.$case) === "structValue") {
            return (_j = message.kind) === null || _j === void 0 ? void 0 : _j.structValue;
        }
        else if (((_k = message.kind) === null || _k === void 0 ? void 0 : _k.$case) === "listValue") {
            return (_l = message.kind) === null || _l === void 0 ? void 0 : _l.listValue;
        }
        else {
            return undefined;
        }
    },
};
function createBaseListValue() {
    return { values: [] };
}
export const ListValue = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.values) {
            Value.encode(Value.wrap(v), writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.values.push(Value.unwrap(Value.decode(reader, reader.uint32())));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            values: Array.isArray(object === null || object === void 0 ? void 0 : object.values) ? [...object.values] : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.values) {
            obj.values = message.values.map((e) => e);
        }
        else {
            obj.values = [];
        }
        return obj;
    },
    wrap(value) {
        const result = createBaseListValue();
        result.values = value !== null && value !== void 0 ? value : [];
        return result;
    },
    unwrap(message) {
        return message.values;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
