import validator from 'validator';

const INVALID_BODY_LINE_BREAK_REGEXP = /(\n){3,}/g;
const VARIABLE_REGEXP = /{{[0-9]+}}/g;

export function findNextVariable(text: string): number {
  const found = text.match(VARIABLE_REGEXP);
  if (!found) {
    return 1;
  }

  let uniqueVars = found.map((x: string) => Number(x.replace('{{', '').replace('}}', ''))).sort((a, b) => a - b);
  uniqueVars = [...new Set(uniqueVars)];

  for (let i = 0; i < uniqueVars.length; i += 1) {
    // variables start from 1
    if (i === 0 && uniqueVars[0] !== 1) {
      return 1;
    }
    // monotonic increase by 1
    if (i > 0 && uniqueVars[i] !== uniqueVars[i - 1] + 1) {
      return uniqueVars[i - 1] + 1;
    }
  }

  return uniqueVars[uniqueVars.length - 1] + 1;
}

export function isValidURL(url: string): boolean {
  return validator.isURL(url);
}

export function isEmail(email: string): boolean {
  return validator.isEmail(email);
}

export function isNumber(value: string): boolean {
  return value.match(/^[0-9]+$/) !== null;
}

export function normalizeBody(body: string): string {
  return body.trim().replace('\r\n', '\n').replace(INVALID_BODY_LINE_BREAK_REGEXP, '\n\n');
}

export function normalizeButton(text: string): string {
  return text.trim().replace('\r\n', '').replace('\n', '');
}

export function normalizeUrl(url: string): string {
  const trimmedUrl = url.trim().replace('\r\n', '').replace('\n', '');
  return !trimmedUrl.startsWith('https://') && !trimmedUrl.startsWith('http://') && trimmedUrl.indexOf('.') !== -1
    ? `https://${trimmedUrl}`
    : trimmedUrl;
}

export function normalizeHeader(text: string): string {
  const normalizedHeader = text.trim().replace('\r\n', '').replace('\n', '');
  return normalizedHeader;
}

export function normalizeFooter(text: string): string {
  return text.trim().replace('\r\n', '').replace('\n', '');
}

export function normalizeListButton(text: string): string {
  return text.trim().replace('\r\n', '').replace('\n', '');
}

export function normalizeListRowText(text: string): string {
  return text.trim().replace('\r\n', '').replace('\n', '');
}

export function normalizeName(name: string): string {
  name = name.trim().replace('\r\n', '').replace('\n', '').toLowerCase();

  let outputName = '';
  for (let i = 0; i < name.length; i += 1) {
    const x = name[i];
    if (x >= 'a' && x <= 'z') {
      outputName += x;
    } else if (x >= '0' && x <= '9') {
      outputName += x;
    } else if (x === ' ') {
      outputName += '_';
    } else {
      outputName += `_u${x.charCodeAt(0).toString(16).padStart(4, '0')}_`;
    }
  }

  return outputName;
}

/* Converts given string with unicode format '_u[0000-ffff]{4}_' to renderable character
    Example input: Test_u005f_
    Output: Test_
*/
export function denormalizeName(name: string): string {
  return name.replace(/_u([0-9a-fA-F]{4}_)/g, (_, hexValue) => String.fromCharCode(parseInt(hexValue, 16)));
}

// sanitize html to only allow b, i, s, code html tags and no attributes
// to be used in displaying styled messages
export function sanitize(html: string): string {
  const validTags = ['b', 'i', 's', 'code'];

  return html.replace(/<(\/?)([a-zA-Z0-9]+)([^>]*?)>/g, (match, close, tag, _) => {
    if (validTags.includes(tag)) {
      return `<${close}${tag}>`;
    }
    return '';
  });
}

// format messages according to whatsapp *bold* is bold, _italic_ is italic, and ~strike~ is strike ```code``` is monocode
// this function tries to match exact behavior of whatsapp IOS, currently it does not allow intersecting tags with monocode
export function formattingPreviewOf(message: string): string {
  return message
    .split('```') // split by monocode notation to avoid intersecting tags with monocode
    .map((unit, i) => {
      return i % 2 === 1
        ? unit // inside monocode, do not format to match whatsapp IOS
        : unit // outside monocode, format bold, italic and strikethrough
            .replace(/\*(.*?)\*/g, '<b>$1</b>') // replace *...* with <b>...</b>
            .replace(/_(.*?)_/g, '<i>$1</i>') // replace _..._ with <i>...</i>
            .replace(/~(.*?)~/g, '<s>$1</s>'); // replace ~...~ with <s>...</s>
    })
    .join('```') // join back together using monocode notation
    .replace(/```(.*?)```/g, '<code>$1</code>'); // finally replace ```...``` with <code>...</code>
}

/**
 * Converts a letter to a Regional Indicator Symbol.
 */
function getRegionalIndicatorSymbol(letter: string) {
  return String.fromCodePoint(0x1f1e6 - 65 + letter.toUpperCase().charCodeAt(0));
}

/**
 * Converts an ISO-2 format country name to unicode flag
 * @param {string} country :ISO-2 format country name (e.g 'US', 'CA')
 */

export function getCountryFlag(country: string) {
  return getRegionalIndicatorSymbol(country[0]) + getRegionalIndicatorSymbol(country[1]);
}
