import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useSettingsData } from '@hooks/useSettingsData';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { ACIntegrationType } from '@hooks/useInstallIntegrationMutation';
import { getDiscountAmountString } from '@components/CartAbandonmentDiscount/utils';
import { ReminderParameterMetadata, ShopifyAbandonedCartDiscount } from '@hooks/useSettingsData/types';
import { selectMessageTemplateByIds, useMessageTemplateGroupsData } from '@hooks/useMessageTemplateGroupsData';
import { useUninstallIntegrationMutation } from '@hooks/useUninstallIntegrationMutation';
import { NotificationContext } from '../../contexts';
import { MessageStatus, getACTemplateStatus, track } from '../../utils';

export const useAbandonedCart = (integrationType: ACIntegrationType) => {
  const queryClient = useQueryClient();
  const isVtexIntegration = integrationType === 'INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT';
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [open, setOpen] = useState(false);
  const [deactivatePopupOpen, setDeactivatePopup] = useState(false);
  const { notificationProvider } = useContext(NotificationContext);
  const { data: profileSettings, isLoading } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
  });
  const integration = profileSettings?.entity?.integrations?.entries?.find((x) => x.type === integrationType);

  const remindersMetadata = integration?.installMetadata?.customShopifyAbandonedCheckoutMetadata?.v2?.reminders;
  const firstReminderMetadata: ReminderParameterMetadata | undefined = remindersMetadata?.length
    ? remindersMetadata[0]
    : undefined;
  const secondReminderMetadata: ReminderParameterMetadata | undefined =
    remindersMetadata && remindersMetadata?.length >= 2 ? remindersMetadata[1] : undefined;
  const v1ReminderMetadata = integration?.installMetadata?.customShopifyAbandonedCheckoutMetadata?.reminderTemplateWaId;
  const firstReminderTemplateWaId = v1ReminderMetadata || firstReminderMetadata?.reminderTemplateWaId;
  const secondReminderTemplateWaId = secondReminderMetadata?.reminderTemplateWaId;
  const reminderTemplateIds: string[] = [firstReminderTemplateWaId, secondReminderTemplateWaId].filter(
    String,
  ) as string[];
  const { data: selectedTemplates } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateByIds(reminderTemplateIds),
    keepPreviousData: true,
  });
  const parameters = integration?.parameters;
  const integrationParameters = isVtexIntegration
    ? parameters?.customVtexAbandonedCheckoutParameters?.reminderParametersList?.[0]
    : (parameters?.customShopifyAbandonedCheckoutParameters?.alwaysOnReminderParameters ??
      parameters?.customShopifyAbandonedCheckoutParameters?.reminderParametersList?.[0]);
  const language = integrationParameters?.reminderTemplateLanguage;
  const discount = !isVtexIntegration
    ? (parameters?.customShopifyAbandonedCheckoutParameters?.alwaysOnReminderParameters?.discount ??
      parameters?.customShopifyAbandonedCheckoutParameters?.reminderParametersList?.[0]?.discount)
    : undefined;
  const isPersonalMessage = integrationParameters?.personalizedMessageEnabled;
  const reminder = isVtexIntegration
    ? parameters?.customVtexAbandonedCheckoutParameters?.reminderParametersList?.[1]
    : parameters?.customShopifyAbandonedCheckoutParameters?.reminderParametersList?.[1];
  const isDefaultMessage = Boolean(!integrationParameters?.personalizedMessageEnabled);
  const discountAmount = getDiscountAmountString(discount as ShopifyAbandonedCartDiscount);
  const firstMessage = selectedTemplates?.length ? selectedTemplates[0] : undefined;
  const secondMessage = selectedTemplates && selectedTemplates?.length >= 2 ? selectedTemplates[1] : undefined;
  const status: MessageStatus = getACTemplateStatus(firstMessage, secondMessage);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChangeDeactivatePopup = useCallback(
    (active: boolean) => {
      if (!active) {
        track('(AC) Deactivate', { businessId });
      }
      setDeactivatePopup(!active);
    },
    [businessId, setDeactivatePopup],
  );

  const { mutate: mutateUninstallIntegration, isLoading: isUninstallingIntegration } = useUninstallIntegrationMutation({
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully deactivated abandoned cart',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
      handleChangeDeactivatePopup(true);
      queryClient.invalidateQueries(['settings']);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error deactivating abandoned cart',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
      handleChangeDeactivatePopup(true);
    },
  });

  const handleDeactivate = useCallback(() => {
    if (integration?.id) {
      mutateUninstallIntegration({
        businessId: businessId ?? '',
        integrationId: integration?.id,
      });
    }
  }, [businessId, integration?.id, mutateUninstallIntegration]);

  return {
    businessId,
    language,
    discount,
    reminder,
    isDefaultMessage,
    discountAmount,
    status,
    firstMessage,
    secondMessage,
    isPersonalMessage,
    integrationId: integration?.id,
    isActivated: Boolean(integration),
    integrationParams: integrationParameters,
    open,
    deactivatePopupOpen,
    isDeactivating: isUninstallingIntegration,
    isLoading,
    onDeactivate: handleDeactivate,
    onOpen: handleOpen,
    onClose: handleClose,
    onChangeDeactivatePopup: handleChangeDeactivatePopup,
  };
};
