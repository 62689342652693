import { Order, CellValue } from './types';

export const sortString =
  (order: Order) =>
  (a: CellValue, b: CellValue): number => {
    if (b === undefined) return -1;
    return order === 'asc' ? String(b).localeCompare(String(a)) : String(a).localeCompare(String(b));
  };

export const sortNumber =
  (order: Order) =>
  (a: CellValue, b: CellValue): number => {
    if (b === undefined) return -1;
    return order === 'asc' ? (Number(a) || 0) - (Number(b) || 0) : (Number(b) || 0) - (Number(a) || 0);
  };

export const sortPhoneNumber =
  (order: Order) =>
  (a: CellValue, b: CellValue): number => {
    const cleanPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9]/g, '');
    a = cleanPhoneNumber(String(a));
    b = cleanPhoneNumber(String(b));
    // sort by first digit, then second, etc.
    for (let i = 0; i < a.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (a[i] === b[i]) continue;
      return order === 'asc' ? Number(a[i]) - Number(b[i]) : Number(b[i]) - Number(a[i]);
    }
    return 0;
  };

export const sortDate =
  (order: Order) =>
  (a: CellValue, b: CellValue): number => {
    // convert to date number for comparison
    a = new Date(a || '').getTime();
    b = new Date(b || '').getTime();
    return sortNumber(order)(a, b);
  };
