import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { Box, Button, CircularProgress, LinearProgress, Tooltip } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useFeatureFlag, useMeData, useUpdateWAToken } from '@hooks';
import {
  isPreviousStepDisabledAtom,
  isNextStepDisabledAtom,
  smbOnboardingStepAtom,
  smbOnboardingProgressAtom,
  shopifyOnboardingConnectUrlAtom,
  phoneNumberAtom,
  useNewPhoneNumberAtom,
  businessNameAtom,
  smbOnboardingNextStepAtom,
  smbOnboardingPreviousStepAtom,
} from '@atoms/home';
import { SMBOnboardingFooterButton, SMBOnboardingFooterProps } from './types';
import { useConnectWhatsApp, getAppId, track } from '../../../../utils';
import { NotificationContext } from '../../../../contexts';

const useSMBOnboardingFooter = ({ whatsApp }: SMBOnboardingFooterProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.smbOnboardingFooter' });
  const { notificationProvider } = useContext(NotificationContext);

  const isNextStepDisabled = useAtomValue(isNextStepDisabledAtom);
  const isPreviousStepDisabled = useAtomValue(isPreviousStepDisabledAtom);
  const [step, setStep] = useAtom(smbOnboardingStepAtom);
  const nextStep = useAtomValue(smbOnboardingNextStepAtom);
  const previousStep = useAtomValue(smbOnboardingPreviousStepAtom);
  const progress = useAtomValue(smbOnboardingProgressAtom);
  const phoneNumber = useAtomValue(phoneNumberAtom);
  const useNewPhoneNumber = useAtomValue(useNewPhoneNumberAtom);
  const businessName = useAtomValue(businessNameAtom);
  const connectUrl = useAtomValue(shopifyOnboardingConnectUrlAtom);
  const isBillingStep = useMemo(() => {
    return step === 'payment';
  }, [step]);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { update } = useUpdateWAToken();
  const { ffBusinessServiceProviderVTEX, ffBrazilCreditLine } = useFeatureFlag([
    'ffBusinessServiceProviderVTEX',
    'ffBrazilCreditLine',
  ]);

  const handleCompleteWAConnect = (
    accessToken: string,
    userId: number,
    dataAccessExpirationTime: number,
    expiresIn: number,
  ) => {
    update({
      businessId: businessId || '',
      accessToken,
      userId,
      dataAccessExpirationTime,
      expiresIn,
      businessServiceProvider: ffBusinessServiceProviderVTEX
        ? 'BUSINESS_SERVICE_PROVIDER_VTEX'
        : 'BUSINESS_SERVICE_PROVIDER_CONNECTLY',
      useBrazilCreditLine: ffBrazilCreditLine,
    });
  };

  const [startConnect, isConnecting] = useConnectWhatsApp(
    {
      businessId: businessId as string,
      phoneNumber: useNewPhoneNumber ? whatsApp?.claimedNumber : phoneNumber,
      name: businessName,
      appId: getAppId(ffBusinessServiceProviderVTEX),
    },
    handleCompleteWAConnect,
    () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Cancelled connecting WhatsApp',
          icon: '',
          severity: NotificationSeverity.INFO,
        },
      });
    },
  );

  const handleNextStep = useCallback(() => {
    switch (step) {
      case 'phoneNumberSelector': {
        if (useNewPhoneNumber) {
          track('(onboarding) business name', { businessId });
        } else {
          track('(onboarding) phone number old', { businessId });
        }
        break;
      }
      case 'phoneNumberOld': {
        track('(onboarding) business name', { businessId });
        break;
      }
      case 'businessName': {
        track('(onboarding) business profile picture', { businessId });
        break;
      }
      case 'businessProfilePicture': {
        track('(onboarding) connect whatsapp', { businessId });
        break;
      }
      case 'connectWhatsApp': {
        track('(onboarding) connect shopify', { businessId });
        startConnect();
        break;
      }
      case 'connectShopify': {
        track('(onboarding) authorize payment', { businessId });
        break;
      }
      case 'payment': {
        track('(onboarding) complete', { businessId });
        break;
      }
      default: {
        break;
      }
    }
    switch (step) {
      case 'connectWhatsApp': {
        startConnect();
        break;
      }
      case 'payment': {
        break;
      }
      default: {
        setStep(nextStep);
      }
    }
  }, [setStep, startConnect, useNewPhoneNumber, businessId, step, nextStep]);

  const handlePreviousStep = useCallback(() => {
    setStep(previousStep);
  }, [setStep, previousStep]);

  const getLink = () => {
    if (step === 'connectShopify') {
      return connectUrl;
    }
    return '';
  };

  const getNextButtonLabel = () => {
    if (isBillingStep) {
      return t('authorizePayment');
    }
    return step === 'connectWhatsApp' || step === 'connectShopify' ? t('connect') : t('next');
  };

  const previousButton: SMBOnboardingFooterButton | undefined =
    step === 'phoneNumberSelector' || isBillingStep
      ? undefined
      : {
          disabled: isPreviousStepDisabled,
          href: '',
          onClick: handlePreviousStep,
          title: t('back'),
        };

  const nextButton: SMBOnboardingFooterButton = {
    disabled: isNextStepDisabled,
    href: getLink(),
    onClick: handleNextStep,
    title: getNextButtonLabel(),
  };

  return {
    isConnecting,
    previousButton,
    nextButton,
    progress,
    step,
  };
};

export const SMBOnboardingFooter = ({ whatsApp }: SMBOnboardingFooterProps) => {
  const { isConnecting, previousButton, nextButton, progress, step } = useSMBOnboardingFooter({ whatsApp });
  const {
    disabled: isPreviousStepDisabled,
    href: prevStepHref,
    title: previousStepTitle,
    onClick: onPreviousStep,
  } = previousButton || {};
  const {
    disabled: isNextStepDisabled,
    href: nextStepHref,
    title: nextStepTitle,
    onClick: onNextStep,
  } = nextButton || {};
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyOnboarding' });
  const isConnectingWhatsapp = isConnecting && step === 'connectWhatsApp';
  const NextButton = (
    <Button
      variant="contained"
      disabled={isNextStepDisabled || isConnectingWhatsapp}
      href={nextStepHref || ''}
      target="_blank"
      onClick={onNextStep}
    >
      {isConnectingWhatsapp ? <CircularProgress size={16} /> : nextStepTitle}
    </Button>
  );
  return (
    <Box
      sx={{
        height: 68,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ mx: 3, display: 'flex', gap: 1, alignSelf: 'flex-end' }}>
        {previousButton && (
          <Button disabled={isPreviousStepDisabled} href={prevStepHref} onClick={onPreviousStep}>
            {previousStepTitle}
          </Button>
        )}
        {nextButton &&
          (isConnectingWhatsapp ? (
            <Tooltip arrow placement="top" title={t('loadingMessage')}>
              <Box>{NextButton}</Box>
            </Tooltip>
          ) : (
            NextButton
          ))}
      </Box>
      <LinearProgress variant="determinate" value={progress} sx={{ height: 10 }} />
    </Box>
  );
};
