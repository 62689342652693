import React, { FC, useState, ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Label,
  Button,
  TabPanel,
  OutlinedInput,
  InputAdornment,
  SearchIcon,
  Skeleton,
  useTheme,
  Tabs,
  Tab,
  ConnectlyCard,
  Pills,
  PillsItem,
} from '@connectlyai-tenets/ui-styled-web';
import { upperFirst } from 'src/utils/titleCase';
import { PageLayout } from '@components/PageLayout';
import { useHistory } from 'react-router-dom';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { applyHook } from '../../utils/applyHook';
import { track } from '../../utils/analytics/analytics';
import {
  useBusinessChannelMetaData,
  useSettingsData,
  selectBusinessId,
  selectWAChannelId,
  selectScoreAndLimit,
  useMeData,
  useDebounce,
  useScheduleCampaignMutation,
  useDeleteCampaignMutation,
  useFeatureFlag,
  useAnalytics,
} from '../../hooks';
import { WithAnalytics } from '../../components';
import { CampaignsTable } from '../CampaignsTable';
import { SendoutsTable } from '../SendoutsTable';
import { UnscheduleCampaignDialog } from '../UnscheduleCampaignDialog';
import { DeleteSendoutDialog } from '../DeleteSendoutDialog';
import { WhatsAppBroadcastProps, WhatsAppBroadcastTabIndex } from './types';
import { LABEL_DICTIONARY, QUALITY_SCORE_DICTIONARY } from './constants';
import ScheduleCampaignDialogWebView, { DialogActionResult } from '../../presentation/ScheduleCampaignDialogWebView';

const useWhatsAppBroadcast = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.pages.tabs.campaign' });
  const [scheduleId, setScheduleId] = useState('');
  const [unScheduleId, setUnScheduleId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: businessChannelId } = useSettingsData({
    businessId: businessId as string,
    select: selectWAChannelId,
    enabled: !!businessId,
  });
  const { data: metaData } = useBusinessChannelMetaData({
    businessId: businessId as string,
    businessChannelId: businessChannelId as string,
    enabled: !!businessId && !!businessChannelId,
    select: selectScoreAndLimit,
  });
  const { mutate } = useScheduleCampaignMutation();
  const { mutate: deleteCampaign } = useDeleteCampaignMutation();
  const { sendAnalytics } = useAnalytics();

  const [tabIndex, setTabIndex] = useState<WhatsAppBroadcastTabIndex>('CAMPAIGN');

  useDebounce(
    () => {
      setDebouncedSearch(search as string);
    },
    250,
    [search],
  );

  const handleChangeTab = (index: WhatsAppBroadcastTabIndex) => {
    setTabIndex(index);
  };
  const handleChangeTabNew = (event: React.SyntheticEvent, newValue: WhatsAppBroadcastTabIndex) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSchedule = (id: string) => {
    setScheduleId(id);
  };

  const handleUnschedule = (id: string) => {
    setUnScheduleId(id);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
  };

  const handleUnscheduleDialogConfirm = () => {
    setUnScheduleId('');
    if (businessId) {
      track('schedule campaign', { businessId, campaignId: unScheduleId });
      mutate({ businessId, sendoutId: unScheduleId, unschedule: {} });
    }
  };

  const handleUnscheduleDialogClose = () => {
    setUnScheduleId('');
  };

  const handleDeleteDialogConfirm = () => {
    if (businessId) {
      switch (tabIndex) {
        case 'CAMPAIGN': {
          deleteCampaign({ businessId, campaignId: deleteId });
          break;
        }
        case 'DRAFT': {
          // CAUTION: sendouts are managed by the flow document don't delete directly
          // deleteSendout({ businessId, sendoutId: deleteId });
          break;
        }
        case 'SENDOUT': {
          // CAUTION: sendouts are managed by the flow document don't delete directly
          // deleteSendout({ businessId, sendoutId: deleteId });
          break;
        }
        default: {
          assertUnreachable(tabIndex);
        }
      }
    }
    setDeleteId('');
  };

  const handleDeleteDialogClose = () => {
    setDeleteId('');
  };

  const handleScheduleDialogClose = (scheduledAt: Date | null, result: DialogActionResult) => {
    setScheduleId('');
    if (!scheduledAt || result === DialogActionResult.DISMISS || !businessId) {
      return;
    }
    track('schedule campaign', { businessId, campaignId: scheduleId });
    mutate({ businessId, sendoutId: scheduleId, schedule: { scheduledAt: scheduledAt.toISOString() } });
  };

  const history = useHistory();
  const handleCreate = useCallback(() => {
    sendAnalytics('campaigns.top_actions.create_campaign_clicked');

    history.push('/flow');
  }, [history, sendAnalytics]);

  const handleViewAnalytics = useCallback(() => {
    history.push('/analytics');
  }, [history]);

  const title = useMemo(() => {
    return t('tabDisplay');
  }, [t]);

  return {
    title,
    scheduleId,
    unScheduleId,
    deleteId,
    onChangeTab: handleChangeTab,
    onChangeTabNew: handleChangeTabNew,
    tabIndex,
    setTabIndex,
    businessId,
    score: metaData?.score,
    limit: metaData?.limit,
    search,
    debouncedSearch,
    onCreate: handleCreate,
    onViewAnalytics: handleViewAnalytics,
    onSearchChange: handleSearchChange,
    onSchedule: handleSchedule,
    onUnschedule: handleUnschedule,
    onDelete: handleDelete,
    onUnscheduleDialogConfirm: handleUnscheduleDialogConfirm,
    onUnscheduleDialogClose: handleUnscheduleDialogClose,
    onScheduleDialogClose: handleScheduleDialogClose,
    onDeleteDialogConfirm: handleDeleteDialogConfirm,
    onDeleteDialogClose: handleDeleteDialogClose,
  };
};

const WhatsAppBroadcastRepresentation: FC<WhatsAppBroadcastProps> = ({
  title,
  tabIndex,
  onChangeTab,
  onChangeTabNew,
  score,
  limit,
  search,
  scheduleId,
  unScheduleId,
  deleteId,
  businessId,
  debouncedSearch,
  onCreate,
  onViewAnalytics,
  onSearchChange,
  onSchedule,
  onUnschedule,
  onDelete,
  onUnscheduleDialogConfirm,
  onUnscheduleDialogClose,
  onScheduleDialogClose,
  onDeleteDialogConfirm,
  onDeleteDialogClose,
}) => {
  const qualityScore = QUALITY_SCORE_DICTIONARY[score || 'WHATS_APP_QUALITY_SCORE_UNSPECIFIED'];
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.campaign' });
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);

  return (
    <PageLayout
      title={title}
      primaryButton={{
        dataTestId: 'broadcast/wacampaign/createnew',
        onClick: onCreate,
        children: t('createNew', 'Create new campaign'),
      }}
      secondaryButton={{
        dataTestId: 'broadcast/wacampaign/createnew',
        children: t('viewAnalytics', 'View analytics'),
        onClick: onViewAnalytics,
        track: ['campaigns.top_actions.view_analytics_clicked'],
        variant: 'outlined',
        disabled: !businessId,
      }}
    >
      <DeleteSendoutDialog
        tabIndex={tabIndex}
        open={Boolean(deleteId)}
        onClose={onDeleteDialogClose}
        onConfirm={onDeleteDialogConfirm}
      />
      <ScheduleCampaignDialogWebView open={Boolean(scheduleId)} onClose={onScheduleDialogClose} />
      <UnscheduleCampaignDialog
        open={Boolean(unScheduleId)}
        onConfirm={onUnscheduleDialogConfirm}
        onClose={onUnscheduleDialogClose}
      />
      <Label variant="h6" sx={{ marginBottom: 1 }}>
        Account Overview
      </Label>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
        <ConnectlyCard title="Quality score" sx={{ flexGrow: 1 }}>
          {score !== undefined ? (
            <Label variant="body1" sx={{ color: qualityScore.color }}>
              {qualityScore.label}
            </Label>
          ) : (
            <Skeleton width={100} height={32} />
          )}
        </ConnectlyCard>
        <ConnectlyCard title="Messaging limit per day" sx={{ flexGrow: 1 }}>
          {score !== undefined ? (
            <Label variant="body1">{LABEL_DICTIONARY[limit || 'WHATS_APP_MESSAGE_LIMIT_TIER_UNSPECIFIED']}</Label>
          ) : (
            <Skeleton width={100} height={32} />
          )}
        </ConnectlyCard>
      </Box>
      <Label variant="h6" sx={{ marginBottom: 1 }}>
        My Campaigns
      </Label>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', maxHeight: theme.spacing(5) }}>
        {!ffEnableAnalyticsRedesign && (
          <Tabs value={tabIndex} onChange={onChangeTabNew}>
            <Tab value="CAMPAIGN" label="Campaigns" />
            <Tab value="SENDOUT" label="Sendouts" />
            <Tab value="DRAFT" label="Drafts" />
          </Tabs>
        )}

        {ffEnableAnalyticsRedesign && (
          <Pills>
            {(['CAMPAIGN', 'SENDOUT', 'DRAFT'] as WhatsAppBroadcastTabIndex[]).map(
              (filter: WhatsAppBroadcastTabIndex) => {
                return (
                  <PillsItem
                    key={filter}
                    item={upperFirst(filter)}
                    onClick={() => onChangeTab(filter)}
                    isActive={tabIndex === filter}
                  />
                );
              },
            )}
          </Pills>
        )}
        <OutlinedInput
          placeholder="Search"
          size="small"
          skin="search"
          sx={{ width: 320 }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          value={search}
          onInput={onSearchChange}
        />
      </Box>
      <TabPanel value={tabIndex} index="CAMPAIGN">
        <Box>
          <Label>Here you can see all the campaigns that you sent. Campaign can have multiple sendouts.</Label>
          <CampaignsTable search={debouncedSearch} onDelete={onDelete} />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index="SENDOUT">
        <Label>
          Sendouts indicate how many times a campaign was sent. Multiple sendouts can be part of a single campaign.
        </Label>
        <SendoutsTable
          states={['SENDOUT_STATE_ACTIVE', 'SENDOUT_STATE_COMPLETED']}
          search={debouncedSearch}
          onSchedule={onSchedule}
          onUnschedule={onUnschedule}
          onDelete={onDelete}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index="DRAFT">
        <SendoutsTable
          states={['SENDOUT_STATE_DRAFT']}
          search={debouncedSearch}
          onSchedule={onSchedule}
          onUnschedule={onUnschedule}
          onDelete={onDelete}
        />
      </TabPanel>
    </PageLayout>
  );
};

export const WhatsAppBroadcast = applyHook(WhatsAppBroadcastRepresentation, useWhatsAppBroadcast);
