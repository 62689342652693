import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { FlowDocumentParametersBody, FlowDocumentParametersPath, FlowDocumentOptions } from './types';

export const useFlowDocumentData = ({
  businessId,
  flowDocumentId,
  ...options
}: FlowDocumentOptions & FlowDocumentParametersPath & FlowDocumentParametersBody) =>
  useQuery(
    ['flowDocument', { flowDocumentId, businessId }],
    () =>
      api.flowV1('/internal/v1/businesses/{query.businessId}/query_react_flow_document/flow_documents', 'post', {
        path: { 'query.businessId': businessId },
        body: { query: { flowDocumentId } },
      }),
    options,
  );
