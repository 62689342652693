import { unwrapResult } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, startWith, map } from 'rxjs/operators';
import { compareDataResponse, DataOrigin, DataResponse } from '@connectlyai-tenets/data';
import { buildDIContainer } from './di';
import storeProvider from './state/store';
import { fetchCustomer } from './state/thunk';
function maybeEmitState(state) {
    if (state.status === 'succeeded') {
        const val = state.customers;
        return DataResponse.data(val, DataOrigin.SourceOfTruth);
    }
    return null;
}
export default class RealCustomerDataStore {
    constructor(deps) {
        this.di = buildDIContainer(deps);
        this.allCustomers = new Subject();
        this.store = storeProvider();
        this.store.subscribe(() => {
            const newState = this.store.getState().customerSlice;
            const maybeEmission = maybeEmitState(newState);
            if (maybeEmission) {
                this.allCustomers.next(maybeEmission);
            }
        });
    }
    async eventRequestRefreshCustomer(customerId) {
        return this.store
            .dispatch(fetchCustomer({
            di: this.di,
            customerId,
        }))
            .then(unwrapResult)
            .then();
    }
    subscribeCustomer(customerId) {
        this.store.dispatch(fetchCustomer({
            di: this.di,
            customerId,
        }));
        const currentState = this.store.getState().customerSlice;
        const maybeEmission = maybeEmitState(currentState);
        const startValue = maybeEmission || DataResponse.loading();
        return this.allCustomers.pipe(startWith(startValue), map((customers) => {
            const cs = customers.getOrNull();
            if (cs) {
                return cs[customerId].customer;
            }
            return undefined;
        }), filter((val) => val !== undefined), map((val) => DataResponse.data(val)), distinctUntilChanged(compareDataResponse));
    }
}
