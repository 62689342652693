export const InboxRouteId = '/inbox';
export const ChatRoomRouteId = '/room/:roomId';
export const AnalyticsRouteId = '/analytics';
export const OnboardingRouteId = '/welcome';
export function generateInboxRoute() {
    return {
        id: InboxRouteId,
        params: {},
    };
}
export function generateInboxAnalyticsRoute() {
    return {
        id: `${InboxRouteId}${AnalyticsRouteId}`,
        params: {},
    };
}
export function generateChatroomRoute(roomId) {
    return {
        id: ChatRoomRouteId,
        params: {
            roomId,
        },
    };
}
export function generateOnboardingRoute() {
    return {
        id: OnboardingRouteId,
        params: {},
    };
}
