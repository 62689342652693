import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import {
  QUERY_LIST_CAMPAIGN_REPORTS_URL,
  QueryListCampaignReportsParams,
  QueryListCampaignReportsOptions,
} from './types';

export const useQueryListCampaignReports = (
  params: QueryListCampaignReportsParams,
  options?: QueryListCampaignReportsOptions,
) =>
  useQuery(
    [
      'campaignReports',
      {
        businessId: params.businessId || '',
      },
    ],
    () =>
      api.reportV1(QUERY_LIST_CAMPAIGN_REPORTS_URL, 'post', {
        path: { 'query.businessId': params.businessId },
        body: { query: { ...params } },
      }),
    options,
  );
