import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import {
  Box,
  Button,
  Card,
  CheckCircleIcon,
  CheckIcon,
  CircularProgress,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorIcon,
  IconButton,
  Label,
  List,
  ListItem,
  ListItemIcon,
  ReportIcon,
  Switch,
  useTheme,
  WhatsAppIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { FlowDiagnostic } from '../../../sdks/flow/types';
import { selectDocumentId, selectRevisionId } from '../../../features/flow-autoreply/state';
import { FlowDIContext } from '../../../features/flow/contexts/FlowDIContext';
import {
  selectBusinessId,
  useMeData,
  useQueryFlowDocumentVersions,
  useRequestCompileReactFlowDocument,
  useRequestDeployReactFlowDocument,
  useFlowWaMessageTemplateResource,
  useMutationDeactivateReactFlowDocument,
  useMessageTemplateGroupsData,
  NodeObjectUpdate,
  useCreateNode,
} from '../../../hooks';
import {
  FlowChartSidebarBuildFlow,
  FlowChartSidebarSection,
  FlowChartSidebarActions,
  FlowChartSidebarType,
} from '../../../components';
import {
  approveConnectlyTemplates,
  selectUnsentMessageTemplatesIndex,
  replaceUnsentMessageTemplate,
  setMessageTemplateStatus,
  selectHasPendingTemplates,
  setMessageTemplateFromNode,
} from '../../../state/messageTemplates';
import {
  SIZE_CONTENT_L,
  SIZE_MEDIA_M,
  SIZE_MEDIA_XS,
  SIZE_SPACING_GUTTER_H,
  SIZE_SPACING_GUTTER_V,
  SIZE_SPACING_INTER_COMPONENT,
  SIZE_SPACING_INTER_SECTION,
} from '../../../ui-theme';
import { selectLanguage, selectNodes } from '../../../state/flow';
import { NotificationContext } from '../../../contexts';
import { useAutoReplyFlowChangeAppliers } from '../../../features/flow-autoreply';

const CUSTOM_CLIENT_DIAGNOSTIC_AGGREGATE_TEMPLATES = uuidv4();

type CheckFlowState = 'error' | 'success' | 'loading' | 'default' | 'pending';
type CompileErrorIndex = {
  [key: string]: FlowDiagnostic;
};

const useFlowChartAutoReplyV1Sidebar = () => {
  const di = useContext(FlowDIContext);
  const queryClient = useQueryClient();

  const [isCheckCompileErrorsDialogOpen, setIsCheckCompileErrorsDialogOpen] = useState(false);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const documentId = useSelector(selectDocumentId);
  const documentRevisionId = useSelector(selectRevisionId);
  const {
    data: requestCompileData,
    mutate: requestCompileMutate,
    isLoading: requestCompileIsLoading,
    isError: requestCompileIsError,
    isSuccess: requestCompileIsSuccess,
  } = useRequestCompileReactFlowDocument();

  const { data: documentVersions } = useQueryFlowDocumentVersions(
    { businessId: businessId || '', flowDocumentId: documentId || '' },
    { enabled: !!businessId && !!documentId },
  );

  const { isLoading: isTemplatesLoading } = useMessageTemplateGroupsData({
    businessId: businessId || '',
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });

  const hasPendingTemplates = useSelector(selectHasPendingTemplates);

  const isFlowActive = useMemo(() => {
    return (
      (documentVersions?.entity?.entries || []).find(
        (version) => version.deployIntent === 'FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY',
      ) !== undefined
    );
  }, [documentVersions]);

  const { mutate: mutateDeactivateReactFlowDocument, isLoading: isDeactivateLoading } =
    useMutationDeactivateReactFlowDocument();

  const handleDeactivateFlow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked && businessId && documentId) {
        mutateDeactivateReactFlowDocument({ businessId, flowDocumentId: documentId });
      }
    },
    [mutateDeactivateReactFlowDocument, businessId, documentId],
  );

  const isSameRevision = useMemo(
    () => documentRevisionId === requestCompileData?.revisionId,
    [documentRevisionId, requestCompileData],
  );

  const handleCompileFlow = useCallback(() => {
    if (businessId && documentId && documentRevisionId) {
      const clientRequestId = uuidv4();
      requestCompileMutate({
        businessId,
        flowDocumentId: documentId,
        clientRequestId,
        flowDocumentRevisionId: documentRevisionId,
        options: {
          version: 'v2',
        },
      });
    }
  }, [requestCompileMutate, businessId, documentId, documentRevisionId]);

  const requestDeploySuccess = useCallback(() => {
    const notificationService = di.notificationServiceProvider();
    notificationService.notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Activated successfully!',
        icon: '',
        severity: NotificationSeverity.SUCCESS,
      },
    });
    queryClient.invalidateQueries({
      queryKey: ['flow_document_versions', { flowDocumentId: documentId || '' }],
    });
  }, [di, queryClient, documentId]);
  const requestDeployError = useCallback(() => {
    const notificationService = di.notificationServiceProvider();
    notificationService.notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Unable to activate. Please try again.',
        icon: '',
        severity: NotificationSeverity.ERROR,
      },
    });
  }, [di]);

  const { mutate: requestDeployMutate, isLoading: requestDeployIsLoading } = useRequestDeployReactFlowDocument({
    onSuccess: requestDeploySuccess,
    onError: requestDeployError,
  });

  const handleDeployFlow = useCallback(() => {
    if (businessId && documentId && documentRevisionId) {
      const clientRequestId = uuidv4();
      requestDeployMutate({
        businessId,
        flowDocumentId: documentId,
        clientRequestId,
        flowDocumentRevisionId: documentRevisionId,
      });
    }
  }, [requestDeployMutate, businessId, documentId, documentRevisionId]);

  const handleCheckCompileErrors = useCallback(() => {
    setIsCheckCompileErrorsDialogOpen(true);
  }, []);

  const handleCheckCompileErrorsDialogClose = useCallback(() => {
    setIsCheckCompileErrorsDialogOpen(false);
  }, []);

  const diagnostics = useMemo(() => {
    return requestCompileData?.items || [];
  }, [requestCompileData]);

  const diagnosticsNote = useMemo(() => {
    return diagnostics.filter(({ kind }) => kind === 'DIAGNOSTIC_KIND_NOTE');
  }, [diagnostics]);

  // generate stable keys for current compilation errors
  const requestCompileErrors = useMemo(() => {
    const errors = diagnostics.filter(({ kind }) => kind === 'DIAGNOSTIC_KIND_ERROR');
    const areAllMissingTemplates =
      errors.length > 0 && errors.every((item) => item.code === 'DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID');
    if (areAllMissingTemplates) {
      const key = uuidv4() || '';
      const details = errors.reduce<{ [key: string]: string }>((previousValue, currentValue) => {
        const nodeId = currentValue.id || '';
        previousValue[nodeId] = nodeId;
        return previousValue;
      }, {});
      const value: FlowDiagnostic = {
        id: CUSTOM_CLIENT_DIAGNOSTIC_AGGREGATE_TEMPLATES,
        kind: 'DIAGNOSTIC_KIND_ERROR',
        userMessage: {
          title: 'Message Verification Required',
          message:
            // eslint-disable-next-line max-len
            'Your messages will be submitted to WhatsApp server for automatic approvals. The review process takes only a few seconds.',
        },
        details,
      };
      return {
        [key]: value,
      };
    }
    return errors.reduce<{ [key: string]: FlowDiagnostic }>((previousValue, currentValue) => {
      const key = uuidv4();
      previousValue[key] = currentValue;
      return previousValue;
    }, {});
  }, [diagnostics]);

  useEffect(() => {
    if (Object.keys(requestCompileErrors).length > 0) {
      setIsCheckCompileErrorsDialogOpen(true);
    }
  }, [requestCompileErrors]);

  const dispatch = useDispatch();

  useEffect(() => {
    const nodeIdsOfSessionMessages = diagnosticsNote
      .filter(
        ({ code, message }) =>
          (code && code === 'DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED') ||
          message === 'connectly template is approved',
      )
      .map((diag) => diag.id || '');
    if (nodeIdsOfSessionMessages.length > 0) {
      dispatch(approveConnectlyTemplates({ nodeIds: nodeIdsOfSessionMessages }));
    }
  }, [diagnosticsNote, dispatch]);

  return {
    isFlowActive,
    isCheckCompileErrorsDialogOpen,
    isDeactivateLoading,
    isSameRevision,
    isTemplatesLoading,
    requestCompileErrors,
    requestCompileIsLoading,
    requestCompileIsError,
    requestCompileIsSuccess,
    requestDeployIsLoading,
    handleDeployFlow,
    handleDeactivateFlow,
    handleCompileFlow,
    handleCheckCompileErrors,
    handleCheckCompileErrorsDialogClose,
    hasPendingTemplates,
  };
};

const useCheckCompileErrorsDialog = ({ requestCompileErrors }: CheckCompileErrorsDialogProps) => {
  const [isHandled, setIsHandled] = useState(false);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { mutateCreateWaTemplate, mutateCreateWaTemplateIsLoading } = useFlowWaMessageTemplateResource();
  const templatesIndex = useSelector(selectUnsentMessageTemplatesIndex, shallowEqual);
  const language = useSelector(selectLanguage);
  const nodes = useSelector(selectNodes);
  const dispatch = useDispatch();
  const { onNodeObjectChange } = useAutoReplyFlowChangeAppliers();

  const errors = useMemo(() => {
    return Object.keys(requestCompileErrors)
      .filter((key) => {
        const diagnostic = requestCompileErrors[key];
        return diagnostic.kind === 'DIAGNOSTIC_KIND_ERROR';
      })
      .map((key) => requestCompileErrors[key]);
  }, [requestCompileErrors]);

  const { notificationProvider } = useContext(NotificationContext);
  const { remoteTemplateNames, templateName } = useCreateNode();

  const onlyMissingTemplateError = useMemo(() => {
    if (errors.length === 1 && errors[0].id === CUSTOM_CLIENT_DIAGNOSTIC_AGGREGATE_TEMPLATES) {
      const compileError = errors[0];
      return {
        title: 'Submit',
        onClick: () => {
          // TODO this doesn't represent the best way to run this.
          // especially with handling the mutateCreateWaTemplateIsLoading and the setIsHandled
          // since it doesn't take into account all the mutation calls but probably only the last
          Object.keys(compileError.details || {}).forEach((nodeId) => {
            const template = templatesIndex[nodeId];
            if (template && businessId) {
              // find duplicates names and assign a new name to them
              const templateNames: string[] = [...(remoteTemplateNames || [])];
              let { name } = template;
              if (name && !templateNames.includes(name)) {
                templateNames.push(name);
              } else {
                if (template.status !== undefined) return;

                const node = nodes.find((n) => n.id === nodeId);
                if (!node) return;

                const newName = templateName();
                templateNames.push(newName);

                const updatedNodeForName = produce(node, (draft) => {
                  draft.data.v1.name = newName;
                  if (draft.data.v1.messageTemplateInput) draft.data.v1.messageTemplateInput.name = newName;
                  return draft;
                });

                const update: NodeObjectUpdate = {
                  id: updatedNodeForName.id,
                  item: updatedNodeForName,
                };
                onNodeObjectChange([update]);
                dispatch(setMessageTemplateFromNode({ node: updatedNodeForName }));

                name = newName;
              }

              mutateCreateWaTemplate(
                {
                  nodeId,
                  businessId,
                  template: { ...template, language: { code: language } },
                  templateGroup: { id: name, name, category: 'MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING' },
                },
                {
                  onSuccess: (data) => {
                    setIsHandled(true);

                    if (!data || !data.template || !data.templateGroup || !data.templateGroup.name) return;
                    if (data?.template?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
                      dispatch(
                        setMessageTemplateStatus({
                          nodeId,
                          status: 'MESSAGE_TEMPLATE_STATUS_REJECTED',
                          rejectionReason: {
                            reason: data?.template?.rejectionReason?.reason as string,
                          },
                        }),
                      );
                      return;
                    }
                    dispatch(
                      replaceUnsentMessageTemplate({
                        nodeId,
                        template: { ...data.template, ...{ name: data.templateGroup.name, errors: undefined } },
                      }),
                    );
                    const templateId = data.template?.id;
                    if (templateId) {
                      const actualNode = nodes.find((n) => n.id === nodeId);
                      if (!actualNode) {
                        return;
                      }
                      const updatedNode = produce(actualNode, (draft) => {
                        switch (actualNode.type) {
                          case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
                            draft.data.v1.waMessageTemplateId = data.template?.id;
                            break;
                          }
                          case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
                            draft.data.v1.templateId = data.template?.id;
                            break;
                          }
                          default: {
                            break;
                          }
                        }
                        return draft;
                      });

                      const update: NodeObjectUpdate = {
                        id: updatedNode.id,
                        item: updatedNode,
                      };
                      onNodeObjectChange([update]);
                    }
                  },
                  onError: () => {
                    notificationProvider().notify({
                      surface: NotificationSurface.SNACKBAR,
                      notification: {
                        message: 'Template creation failed. Please recreate the node and try again.',
                        icon: '',
                        severity: NotificationSeverity.ERROR,
                      },
                    });
                  },
                },
              );
            }
          });
        },
      };
    }
    return null;
  }, [
    errors,
    businessId,
    templatesIndex,
    mutateCreateWaTemplate,
    setIsHandled,
    notificationProvider,
    language,
    dispatch,
    nodes,
    onNodeObjectChange,
  ]);

  return {
    hasErrors: errors.length > 0,
    onlyMissingTemplateError,
    isHandled,
    isActionLoading: mutateCreateWaTemplateIsLoading,
  };
};

type CheckCompileErrorsDialogProps = PropsWithChildren<{
  onCancel: () => void;
  open: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  requestCompileErrors: CompileErrorIndex;
}>;

const CheckCompileErrorsDialog: FC<CheckCompileErrorsDialogProps> = (props) => {
  const { hasErrors, onlyMissingTemplateError, isHandled, isActionLoading } = useCheckCompileErrorsDialog(props);
  const { children, open, onCancel } = props;
  const theme = useTheme();
  return (
    <Dialog open={open} maxWidth="xl" onClose={onCancel}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Flow Check
        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: theme.spacing(SIZE_CONTENT_L),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          {children}
        </Box>
      </DialogContent>
      {hasErrors && (
        <DialogActions
          sx={{
            px: SIZE_SPACING_GUTTER_H,
            py: SIZE_SPACING_GUTTER_V,
            justifyContent: 'center',
          }}
        >
          {!onlyMissingTemplateError && (
            <Button variant="contained" onClick={onCancel}>
              Edit Flow
            </Button>
          )}
          {onlyMissingTemplateError && (
            <>
              <Button
                variant="contained"
                onClick={onlyMissingTemplateError.onClick}
                disabled={isActionLoading || isHandled}
              >
                {isActionLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="inherit" />}
                {!isActionLoading && !isHandled && onlyMissingTemplateError.title}
                {!isActionLoading && isHandled && (
                  <CheckIcon
                    sx={{
                      width: theme.spacing(SIZE_MEDIA_XS),
                      height: theme.spacing(SIZE_MEDIA_XS),
                    }}
                    color="inherit"
                  />
                )}
              </Button>
              {!isHandled && (
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

type CheckFlowButtonProps = {
  disabled: boolean;
  state: CheckFlowState;
  onClick: () => void;
};

const CheckFlowButton: FC<CheckFlowButtonProps> = ({ disabled, state, onClick }) => {
  const theme = useTheme();
  let alignItems = 'center';
  let background;
  let variant: 'contained' | 'text' = 'contained';
  let startIcon;
  let textTransform: 'none' | undefined;
  let color;
  switch (state) {
    case 'error':
      alignItems = 'flex-start';
      variant = 'text';
      startIcon = <ErrorIcon color="error" />;
      textTransform = 'none';
      color = theme.palette.text.primary;
      break;
    case 'success':
      alignItems = 'flex-start';
      variant = 'text';
      startIcon = <CheckCircleIcon color="success" />;
      textTransform = 'none';
      break;
    case 'pending':
      alignItems = 'flex-start';
      variant = 'text';
      startIcon = <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="warning" />;
      textTransform = 'none';
      color = theme.palette.text.primary;
      break;
    case 'loading':
      break;
    default:
      break;
  }
  return (
    <Button
      sx={{ width: '100%', alignItems, background, textTransform, color }}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
    >
      {state === 'loading' && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} />}
      {state === 'default' && 'Check Flow'}
      {state === 'success' && 'Flow Check completed'}
      {state === 'error' && 'Flow Check errors'}
      {state === 'pending' && 'Waiting for template approval'}
    </Button>
  );
};

const useCheckCompileErrorEntry = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [isHandled, setIsHandled] = useState(false);

  const { mutateCreateWaTemplate, mutateCreateWaTemplateIsLoading } = useFlowWaMessageTemplateResource();
  const templatesIndex = useSelector(selectUnsentMessageTemplatesIndex, shallowEqual);
  const language = useSelector(selectLanguage);

  const { notificationProvider } = useContext(NotificationContext);

  const isCompileErrorActionable = useCallback(
    (compileError: FlowDiagnostic) => {
      if (compileError.code === 'DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID') {
        const nodeId = compileError.id || '';
        const template = templatesIndex[nodeId];
        if (template && businessId) {
          const { name } = template;
          return {
            title: 'Submit',
            onClick: () => {
              mutateCreateWaTemplate(
                {
                  nodeId,
                  businessId,
                  template: { ...template, language: { code: language } },
                  templateGroup: { id: name, name, category: 'MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING' },
                },
                {
                  onSuccess: () => {
                    setIsHandled(true);
                  },
                  onError: () => {
                    notificationProvider().notify({
                      surface: NotificationSurface.SNACKBAR,
                      notification: {
                        message: 'Template creation failed. Please recreate the node and try again.',
                        icon: '',
                        severity: NotificationSeverity.ERROR,
                      },
                    });
                  },
                },
              );
            },
          };
        }
      }
      return null;
    },
    [businessId, templatesIndex, mutateCreateWaTemplate, setIsHandled, notificationProvider, language],
  );

  return {
    isHandled,
    isCompileErrorActionable,
    isActionLoading: mutateCreateWaTemplateIsLoading,
  };
};

type CheckCompileErrorEntryProps = {
  diagnostic: FlowDiagnostic;
};

const CheckCompileErrorEntry: FC<CheckCompileErrorEntryProps> = ({ diagnostic }) => {
  const { isHandled, isCompileErrorActionable, isActionLoading } = useCheckCompileErrorEntry();
  const theme = useTheme();
  const isActionable = isCompileErrorActionable(diagnostic);
  let iconBackgroundColor;
  let iconColor;
  let Icon: JSX.Element = <ReportIcon />;
  switch (diagnostic.kind) {
    case 'DIAGNOSTIC_KIND_ERROR':
      iconBackgroundColor = theme.palette.error.main;
      iconColor = theme.palette.error.contrastText;
      break;
    case 'DIAGNOSTIC_KIND_WARN':
      iconBackgroundColor = theme.palette.warning.main;
      iconColor = theme.palette.warning.contrastText;
      break;
    case 'DIAGNOSTIC_KIND_NOTE':
      iconBackgroundColor = theme.palette.primary.main;
      iconColor = theme.palette.primary.contrastText;
      break;
    default:
      iconBackgroundColor = theme.palette.primary.main;
      iconColor = theme.palette.primary.contrastText;
      break;
  }
  if (diagnostic.id === CUSTOM_CLIENT_DIAGNOSTIC_AGGREGATE_TEMPLATES) {
    iconBackgroundColor = undefined;
    iconColor = undefined;
    Icon = <WhatsAppIcon />;
  }
  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: SIZE_SPACING_INTER_COMPONENT,
        background: theme.palette.secondary.light,
        borderRadius: '10px',
      }}
    >
      <ListItemIcon>
        <IconButton
          sx={{
            background: iconBackgroundColor,
            color: iconColor,
            width: theme.spacing(SIZE_MEDIA_M),
            height: theme.spacing(SIZE_MEDIA_M),
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          {Icon}
        </IconButton>
      </ListItemIcon>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Label variant="body1">{diagnostic.userMessage?.title}</Label>
        <Label variant="caption" color="textSecondary">
          {diagnostic.userMessage?.message}
        </Label>
      </Box>
      {isActionable && !isHandled && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Button variant="contained" onClick={isActionable.onClick}>
            {isActionLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="inherit" />}
            {!isActionLoading && isActionable.title}
          </Button>
        </Box>
      )}
      {isHandled && (
        <CheckIcon
          sx={{
            width: theme.spacing(SIZE_MEDIA_XS),
            height: theme.spacing(SIZE_MEDIA_XS),
          }}
        />
      )}
    </ListItem>
  );
};

export const FlowChartAutoReplyV1Sidebar: FC = () => {
  const {
    isCheckCompileErrorsDialogOpen,
    isFlowActive,
    isDeactivateLoading,
    isSameRevision,
    isTemplatesLoading,
    requestCompileErrors,
    requestCompileIsLoading,
    requestCompileIsError,
    requestCompileIsSuccess,
    requestDeployIsLoading,
    handleCompileFlow,
    handleDeactivateFlow,
    handleDeployFlow,
    handleCheckCompileErrors,
    handleCheckCompileErrorsDialogClose,
    hasPendingTemplates,
  } = useFlowChartAutoReplyV1Sidebar();

  const theme = useTheme();
  const hasCompilationError = !requestCompileIsLoading && isSameRevision && requestCompileIsError;
  let checkFlowState: CheckFlowState = 'default';
  if (requestCompileIsLoading || isTemplatesLoading) {
    checkFlowState = 'loading';
  } else if (hasPendingTemplates) {
    checkFlowState = 'pending';
  } else if (!isSameRevision) {
    checkFlowState = 'default';
  } else if (requestCompileIsSuccess) {
    checkFlowState = 'success';
  } else if (hasCompilationError) {
    checkFlowState = 'error';
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        padding: 3,
        gap: SIZE_SPACING_INTER_SECTION,
        background: theme.palette.background.paper,
      }}
    >
      <CheckCompileErrorsDialog
        open={isCheckCompileErrorsDialogOpen}
        onCancel={handleCheckCompileErrorsDialogClose}
        requestCompileErrors={requestCompileErrors}
      >
        <List sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }} disablePadding>
          {Object.keys(requestCompileErrors).map((key) => {
            const diagnostic = requestCompileErrors[key];
            return <CheckCompileErrorEntry key={key} diagnostic={diagnostic} />;
          })}
        </List>
      </CheckCompileErrorsDialog>

      <FlowChartSidebarSection sx={{ flex: '0 0 auto', px: 1 }} title="">
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: SIZE_SPACING_GUTTER_H,
            py: SIZE_SPACING_GUTTER_V,
          }}
          variant="outlined"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }}>
            <Label>Status</Label>
            <Label
              variant="h5"
              sx={{ color: isFlowActive ? theme.palette.success.main : theme.palette.text.secondary }}
            >
              {isFlowActive ? 'Active' : 'Inactive'}
            </Label>
          </Box>
          <Switch
            checkedIcon={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: isDeactivateLoading ? theme.palette.background.paper : theme.palette.primary.main,
                  boxShadow: theme.shadows[1],
                }}
              >
                {isFlowActive && isDeactivateLoading && <CircularProgress size={14} color="primary" thickness={6} />}
              </Box>
            }
            disabled={!isFlowActive}
            checked={isFlowActive}
            onChange={handleDeactivateFlow}
          />
        </Card>
      </FlowChartSidebarSection>

      <FlowChartSidebarSection sx={{ flex: '1 1 auto', overflowY: 'auto', px: 1 }} title="">
        <FlowChartSidebarBuildFlow title="Build Flow" type={FlowChartSidebarType.AutoReply} />
      </FlowChartSidebarSection>

      <FlowChartSidebarActions>
        <CheckFlowButton
          disabled={
            requestCompileIsLoading ||
            (isSameRevision && requestCompileIsSuccess) ||
            isTemplatesLoading ||
            hasPendingTemplates
          }
          state={checkFlowState}
          onClick={hasCompilationError ? handleCheckCompileErrors : handleCompileFlow}
        />
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          onClick={handleDeployFlow}
          disabled={
            !requestCompileIsSuccess ||
            !isSameRevision ||
            requestDeployIsLoading ||
            isTemplatesLoading ||
            hasPendingTemplates
          }
        >
          {requestDeployIsLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="inherit" />}
          {!requestDeployIsLoading && 'Activate'}
        </Button>
      </FlowChartSidebarActions>
    </Box>
  );
};
