import { WhatsAppBroadcastProps } from './types';

export const LABEL_DICTIONARY: Record<NonNullable<WhatsAppBroadcastProps['limit']>, string> = {
  WHATS_APP_MESSAGE_LIMIT_TIER_TIER250: '250',
  WHATS_APP_MESSAGE_LIMIT_TIER_TIER100K: '100,000',
  WHATS_APP_MESSAGE_LIMIT_TIER_TIER10K: '10,000',
  WHATS_APP_MESSAGE_LIMIT_TIER_TIER1K: '1,000',
  WHATS_APP_MESSAGE_LIMIT_TIER_UNSPECIFIED: 'Unknown',
  WHATS_APP_MESSAGE_LIMIT_TIER_TIER_UNLIMITED: 'Unknown',
};

export const QUALITY_SCORE_DICTIONARY: Record<
  NonNullable<WhatsAppBroadcastProps['score']>,
  { color: string; label: string }
> = {
  WHATS_APP_QUALITY_SCORE_HIGH: {
    color: 'success.main',
    label: 'High',
  },
  WHATS_APP_QUALITY_SCORE_MEDIUM: {
    color: 'warning.main',
    label: 'Medium',
  },
  WHATS_APP_QUALITY_SCORE_LOW: {
    color: 'error.main',
    label: 'Low',
  },
  WHATS_APP_QUALITY_SCORE_UNSPECIFIED: {
    color: 'inherith',
    label: 'Unknown',
  },
};
