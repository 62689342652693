import { AgentsResponse, Agent } from './types';

export const selectAgents = (data: AgentsResponse) => data.entity?.agents;

export const selectAgentsMap = (data: AgentsResponse) =>
  selectAgents(data)?.reduce<Record<NonNullable<Agent['id']>, Agent>>(
    (map, agent) => ({
      ...map,
      [agent.id || 0]: agent,
    }),
    {},
  );
