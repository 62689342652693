import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AudienceState } from './types';

const initialState: AudienceState = {
  listBuilderParams: undefined,
  segmentBuilderParams: undefined,
};

export const slice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    closeListBuilder: (state) => {
      state.listBuilderParams = undefined;
    },
    openListBuilder: (state) => {
      state.listBuilderParams = {
        name: '',
        openApproval: false,
        url: '',
      };
    },
    setListBuilderName: (state, action: PayloadAction<string>) => {
      if (!state.listBuilderParams) {
        return;
      }

      state.listBuilderParams.name = action.payload;
    },
    setListBuilderOpenApproval: (state, action: PayloadAction<boolean>) => {
      if (!state.listBuilderParams) {
        return;
      }

      state.listBuilderParams.openApproval = action.payload;
    },
    setListBuilderUrl: (state, action: PayloadAction<string>) => {
      if (!state.listBuilderParams) {
        return;
      }

      state.listBuilderParams.url = action.payload;
    },
  },
});

export const { closeListBuilder, openListBuilder, setListBuilderName, setListBuilderOpenApproval, setListBuilderUrl } =
  slice.actions;

export default slice.reducer;
