import React, { FC, useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  DialogContent,
  Label,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@connectlyai-tenets/ui-styled-web';
import { useFlowDocumentData } from '../../hooks/useFlowDocumentData';
import { SIZE_CONTENT_L, SIZE_CONTENT_S } from '../../ui-theme';
import {
  selectBusinessId,
  selectMessageTemplateById,
  useMeData,
  useMessageTemplateGroupsData,
  useSettingsData,
  useTemplateParams,
} from '../../hooks';
import { apiKeyAtom, targetPhoneAtom } from './atoms';
import { CampaignAPIDialogProps } from './types';

export const useCampaignAPIDialogV2 = ({ flowDocumentId }: Partial<CampaignAPIDialogProps>) => {
  const [targetPhone, setTargetPhone] = useAtom(targetPhoneAtom);
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: flowDocument } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: flowDocumentId || '',
    enabled: !!businessId && !!flowDocumentId,
  });

  const { data: businessChannel } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
  });
  const whatsappPhoneNumbers = businessChannel?.entity?.channels
    ?.map((channel) => channel?.remoteProfile?.wabaPhoneNumber?.phoneNumber)
    .filter((x): x is string => Boolean(x));

  const fetchedNodes = useMemo(
    () => flowDocument?.entity?.nodes?.map((node) => (node.options ? JSON.parse(node.options) : {})),
    [flowDocument],
  );
  const fetchedEdges = useMemo(
    () => flowDocument?.entity?.edges?.map((edge) => (edge.options ? JSON.parse(edge.options) : {})),
    [flowDocument],
  );

  const campaignNode = useMemo(
    () => fetchedNodes?.find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN'),
    [fetchedNodes],
  );
  const campaignLanguage = useMemo(() => campaignNode?.data?.v1?.campaignConfig?.language, [campaignNode]);

  const firstTemplateNode = useMemo(() => {
    const id = fetchedEdges?.find((x) => x.source === campaignNode?.id)?.target;
    return fetchedNodes?.find((x) => x.id === id);
  }, [campaignNode, fetchedNodes, fetchedEdges]);

  const firstTemplateId = useMemo(
    () => firstTemplateNode?.data?.v1?.templateId || firstTemplateNode?.data?.v1?.waMessageTemplateId,
    [firstTemplateNode],
  );

  const { data: firstTemplate } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(firstTemplateId),
  });

  const firstTemplateName = useMemo(() => firstTemplate?.template?.externalTemplate?.whatsapp?.name, [firstTemplate]);

  const { paramsJSON, paramsKeys, paramsHash, setParameter } = useTemplateParams(firstTemplate);

  const requestBody = useMemo(
    () => ({
      campaignName: flowDocument?.entity?.name || 'CHANGE_TO_CAMPAIGN_NAME',
      language: campaignLanguage || 'CHANGE_TO_LANGUAGE',
      number: targetPhone || 'CHANGE_TO_TARGET_PHONE_NUMBER',
      sender: whatsappPhoneNumbers?.[0] || 'CHANGE_TO_SOURCE_PHONE_NUMBER',
      templateName: firstTemplateName || 'CHANGE_TO_FIRST_TEMPLATE_NAME',
      parameters: paramsJSON,
    }),
    [campaignLanguage, firstTemplateName, flowDocument, paramsJSON, targetPhone, whatsappPhoneNumbers],
  );

  const curlPath = useMemo(
    () => `${process.env.REACT_APP_INBOX_API_GATEWAY}/v1/businesses/${businessId}/send/whatsapp_templated_messages`,
    [businessId],
  );

  const curlSnippet = useMemo(() => {
    if (!businessId) return '';
    return `curl  --location '${curlPath}' \\\n`
      .concat(`  --header 'Content-Type: application/json' \\\n`)
      .concat(`  --header 'x-api-key: ${apiKey || 'CHANGE_TO_API_KEY'}' \\\n`)
      .concat(`  --data '${JSON.stringify(requestBody, null, '\t\t')}'`);
  }, [apiKey, businessId, curlPath, requestBody]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(curlSnippet);
  }, [curlSnippet]);

  const paramsForm = useMemo(
    () =>
      paramsKeys.map((paramKey) => (
        <>
          <Label variant="body2">Value for {paramKey}</Label>
          <TextField
            key={paramKey}
            value={paramsHash[paramKey]}
            onChange={(e) => setParameter(paramKey, e.target.value)}
            sx={{ background: (theme) => theme.palette.common.white }}
            placeholder={paramKey}
          />
        </>
      )),
    [paramsKeys, paramsHash, setParameter],
  );

  const isNotSupported = useMemo(
    () => !!flowDocument && (flowDocument?.entity?.type !== 'FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1' || !firstTemplateName),
    [flowDocument, firstTemplateName],
  );

  return {
    curlSnippet,
    handleCopy,
    targetPhone,
    setTargetPhone,
    apiKey,
    setApiKey,
    paramsForm,
    firstTemplate,
    isNotSupported,
  };
};
export const CampaignAPIDialogV2: FC<CampaignAPIDialogProps> = ({ flowDocumentId }) => {
  const {
    apiKey,
    curlSnippet,
    firstTemplate,
    handleCopy,
    isNotSupported,
    paramsForm,
    setApiKey,
    setTargetPhone,
    targetPhone,
  } = useCampaignAPIDialogV2({
    flowDocumentId,
  });

  return (
    <>
      {isNotSupported && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_S),
          }}
        >
          <Alert severity="warning" sx={{ width: 'fit-content', mx: 'auto', mt: 2 }}>
            <Label variant="subtitle1">Sorry, this campaign is not supported in API Builder.</Label>
          </Alert>
        </DialogContent>
      )}

      {!isNotSupported && !firstTemplate && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_L),
          }}
        >
          <CircularProgress />
        </DialogContent>
      )}
      {!isNotSupported && !!firstTemplate && (
        <DialogContent
          sx={{ width: (theme) => theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.secondary.light,
              borderRadius: (theme) => theme.spacing(1),
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              justifyContent: 'flex-start',
              padding: (theme) => theme.spacing(2),
              width: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flex: '0 1 auto',
                justifyContent: 'space-between',
                width: 1,
              }}
            >
              <Label variant="subtitle2">Easy Builder</Label>
            </Box>
            <Label variant="body1">Phone number to send</Label>
            <TextField
              value={targetPhone}
              onChange={(e) => setTargetPhone(e.target.value)}
              sx={{ background: (theme) => theme.palette.common.white, mb: 2 }}
              placeholder='include "+" and country code, e.g. "+1234567890"'
            />
            <Label variant="body1">API key to use</Label>
            <TextField
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              sx={{ background: (theme) => theme.palette.common.white, mb: 2 }}
              placeholder="go to Settings > Business Keys to create a new key"
            />
            {!!paramsForm.length && <Label variant="body1">Template Parameters</Label>}
            {paramsForm}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              background: (theme) => theme.palette.secondary.light,
              borderRadius: (theme) => theme.spacing(1),
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              justifyContent: 'flex-start',
              padding: (theme) => theme.spacing(2),
              width: 1,
            }}
          >
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flex: '0 1 auto',
                justifyContent: 'space-between',
                width: 1,
              }}
            >
              <Label variant="subtitle2">Curl Snippet</Label>
              <Button
                variant="text"
                color="primary"
                disableRipple
                sx={{
                  display: 'flex',
                  padding: 0,
                }}
                onClick={handleCopy}
              >
                <Label variant="body2">COPY TEXT</Label>
              </Button>
            </Box>
            <Box
              sx={{
                background: (theme) => theme.palette.common.white,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: (theme) => theme.spacing(1),
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                flex: '1 0 auto',
                mt: 1,
                p: 1.5,
                width: 1,
              }}
            >
              <Label
                sx={{
                  borderRadius: '10px',
                  wordBreak: 'break-word',
                  cursor: 'text',
                  display: 'flex',
                  whiteSpace: 'pre-wrap',
                }}
                variant="body2"
              >
                {curlSnippet}
              </Label>
            </Box>
          </Box>
        </DialogContent>
      )}
    </>
  );
};
