import React from 'react';
import { Button, Slide, SvgIcon, WhatsAppIcon, Box, Label, ContentCopyIcon } from '@connectlyai-tenets/ui-styled-web';
import { SIZE_MEDIA_XXS } from '../../../../../../ui-theme';
import { PhoneNumberProps, ClaimPhoneNumberProps } from './types';
import { formatPhoneNumber } from '../../../../../../utils';

const PhoneNumber = ({ number, onCopy }: PhoneNumberProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          boxSizing: 'border-box',
          height: 36.5,
          width: '100%',
          borderRadius: '5px',
          color: 'var(--activitybarColorMain)',
          backgroundColor: 'secondary.light',

          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <SvgIcon
          sx={{
            ml: 1.5,
            width: (theme) => theme.spacing(SIZE_MEDIA_XXS),
            height: (theme) => theme.spacing(SIZE_MEDIA_XXS),
          }}
        >
          <WhatsAppIcon />
        </SvgIcon>
        <Label
          noWrap
          sx={{
            flex: '1 1 auto',
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          {formatPhoneNumber(number, true) || number}
        </Label>
        <Button
          title="Copy"
          variant="outlined"
          color="inherit"
          disableRipple
          onClick={onCopy}
          sx={{
            minWidth: 'unset',
            h: '100%',
            p: '7px',
            borderSize: 0,
            borderColor: 'transparent',
          }}
        >
          <ContentCopyIcon fontSize="small" sx={{ color: 'primary.main' }} />
        </Button>
      </Box>
    </Box>
  );
};

export const ClaimPhoneNumber = ({ number, onClaim, onCopy, isClaiming }: ClaimPhoneNumberProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: 226,
        height: 36.5,
        overflow: 'hidden',
      }}
    >
      <PhoneNumber number={number || ''} onCopy={onCopy} />
      <Slide in={!number} appear={false} direction="up" timeout={1000}>
        <Button
          variant="contained"
          sx={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
          onClick={onClaim}
          disabled={isClaiming}
        >
          Claim number
        </Button>
      </Slide>
    </Box>
  );
};
