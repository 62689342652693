import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const QUERY_SUBSCRIPTION_URL =
  '/internal/v1/businesses/{businessId}/fetch_shopify_subscription_confirmation_url';
type QuerySubscriptionURL = typeof QUERY_SUBSCRIPTION_URL;

export type QuerySubscriptionResponse = Paths['billingV1'][QuerySubscriptionURL]['get']['responses']['200']['schema'];

export type QuerySubscriptionOptions = Omit<
  UseQueryOptions<QuerySubscriptionResponse, ConnectlyError, QuerySubscriptionResponse, readonly ['subscriptionURL']>,
  'initialData' | 'queryFn' | 'queryKey'
>;
