import React from 'react';
import { AnalyticsSendoutsOverviewControls } from '../../AnalyticsSendoutsOverviewControls';
import { AnalyticsSendoutsOverview } from '../../AnalyticsSendoutsOverview';

// eslint-disable-next-line react/display-name
export const Campaigns = () => {
  return (
    <>
      <AnalyticsSendoutsOverviewControls />
      <AnalyticsSendoutsOverview />
    </>
  );
};
