import { useAuth0 } from '@auth0/auth0-react';
export const useAuth = () => {
    const { logout, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    return {
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        getAccessTokenSilently,
        logout,
    };
};
