import { UseQueryOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const LIST_CAMPAIGN_PRESETS_URL =
  '/internal/v1/businesses/{query.businessId}/query_list_campaign_presets/flow_documents';
export type ListCampaignsPresetsURL = typeof LIST_CAMPAIGN_PRESETS_URL;

export type ListCampaignsPresetsResponse =
  Paths['flowV1'][ListCampaignsPresetsURL]['post']['responses']['200']['schema'];
export type ListCampaignsPresetsParametersBody =
  Paths['flowV1'][ListCampaignsPresetsURL]['post']['parameters']['body']['query'];
export type ListCampaignsPresetsParametersPath = { businessId: string };

export type ListCampaignsPresetsOptions = Omit<
  UseQueryOptions<
    ListCampaignsPresetsResponse,
    unknown,
    ListCampaignsPresetsResponse,
    readonly [target: 'listCampaignsPresets', businessId: string]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;
