import { Box, Label, ListItem, ListItemButton, ListItemText, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React, { useCallback, useState } from 'react';
import { SMBSettingsBilling } from '../../components/SMBSettingsBilling';
import { SMBSettingsGeneral } from '../../components/SMBSettingsGeneral';
import { MENU_ITEMS, SETTINGS_CATEGORY_BILLING } from './constants';

export const SettingsSMB = () => {
  const theme = useTheme();
  const [selectedMenuItem, setSelectedMenuItem] = useState(MENU_ITEMS[0].key);
  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setSelectedMenuItem(event.currentTarget.dataset.key ?? MENU_ITEMS[0].key);
    },
    [setSelectedMenuItem],
  );

  const getContent = () => {
    switch (selectedMenuItem) {
      case SETTINGS_CATEGORY_BILLING:
        return <SMBSettingsBilling />;
      default:
        return <SMBSettingsGeneral />;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Box sx={{ width: theme.spacing(30), p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              position: 'sticky',
              display: 'flex',
              flexWrap: 'wrap',
              flexShirnk: 0,
              width: '100%',
            }}
          >
            <ListItem>
              <ListItemText primary={<Label variant="h5">Settings</Label>} />
            </ListItem>
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {MENU_ITEMS.map((val) => {
              const isSelected = val.key === selectedMenuItem;
              return (
                <ListItemButton
                  key={val.key}
                  sx={{
                    color: 'inherit',
                    borderRight: isSelected ? `${theme.spacing(0.5)} solid ${theme.palette.primary.main}` : 'none',
                  }}
                  data-key={val.key}
                  data-testid="settings/menuitem"
                  selected={isSelected}
                  onClick={handleMenuItemClick}
                >
                  <ListItemText primary={<Label variant="subtitle2">{val.title}</Label>} />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>{getContent()}</Box>
    </Box>
  );
};
