import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  ExpandMoreIcon,
  styled,
} from '@connectlyai-tenets/ui-styled-web';

// source: https://mui.com/material-ui/react-accordion/#customization

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Accordion = styled((props: any) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  }),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AccordionSummary = styled((props: any) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: theme.palette.grey[200],
  overflowY: 'auto',
  whiteSpace: 'pre-wrap',
  maxHeight: 400,
  maxWidth: 800,
  boxSizing: 'border-box',
}));
