import { createAPI } from '@connectlyai-tenets/sdk';

export const accessTokenService = {
  // A temporary solution until we get rid of the old SDK
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tempSolution: async (data = {}) => '',
};

const getAccessToken = () => accessTokenService.tempSolution();

export const api = createAPI({ getAccessToken });
