import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "exp.eta.events";
function createBaseRecommenderCampaignCommand() {
    return {
        productId: "",
        shopifyBusinessChannelId: undefined,
        shopifyCustomerChannelId: undefined,
        waCustomerChannelExternalId: undefined,
        sendoutId: undefined,
        waBusinessChannelExternalId: undefined,
    };
}
export const RecommenderCampaignCommand = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.productId !== "") {
            writer.uint32(10).string(message.productId);
        }
        if (message.shopifyBusinessChannelId !== undefined) {
            StringValue.encode({ value: message.shopifyBusinessChannelId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.shopifyCustomerChannelId !== undefined) {
            StringValue.encode({ value: message.shopifyCustomerChannelId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.waCustomerChannelExternalId !== undefined) {
            StringValue.encode({ value: message.waCustomerChannelExternalId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.sendoutId !== undefined) {
            StringValue.encode({ value: message.sendoutId }, writer.uint32(42).fork()).ldelim();
        }
        if (message.waBusinessChannelExternalId !== undefined) {
            StringValue.encode({ value: message.waBusinessChannelExternalId }, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecommenderCampaignCommand();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.productId = reader.string();
                    break;
                case 2:
                    message.shopifyBusinessChannelId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.shopifyCustomerChannelId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.waCustomerChannelExternalId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.sendoutId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.waBusinessChannelExternalId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            productId: isSet(object.productId) ? String(object.productId) : "",
            shopifyBusinessChannelId: isSet(object.shopifyBusinessChannelId)
                ? String(object.shopifyBusinessChannelId)
                : undefined,
            shopifyCustomerChannelId: isSet(object.shopifyCustomerChannelId)
                ? String(object.shopifyCustomerChannelId)
                : undefined,
            waCustomerChannelExternalId: isSet(object.waCustomerChannelExternalId)
                ? String(object.waCustomerChannelExternalId)
                : undefined,
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : undefined,
            waBusinessChannelExternalId: isSet(object.waBusinessChannelExternalId)
                ? String(object.waBusinessChannelExternalId)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.productId !== undefined && (obj.productId = message.productId);
        message.shopifyBusinessChannelId !== undefined &&
            (obj.shopifyBusinessChannelId = message.shopifyBusinessChannelId);
        message.shopifyCustomerChannelId !== undefined &&
            (obj.shopifyCustomerChannelId = message.shopifyCustomerChannelId);
        message.waCustomerChannelExternalId !== undefined &&
            (obj.waCustomerChannelExternalId = message.waCustomerChannelExternalId);
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.waBusinessChannelExternalId !== undefined &&
            (obj.waBusinessChannelExternalId = message.waBusinessChannelExternalId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
