import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_REMOVE_SOFIA_DOCUMENTS =
  '/internal/v1/businesses/{input.businessId}/sofia/ocean_beach_document_association/delete';

type MutationRemoveSofiaDocuments = typeof MUTATION_REMOVE_SOFIA_DOCUMENTS;

export type MutationRemoveSofiaDocumentsResponse =
  Paths['sofiaV1'][MutationRemoveSofiaDocuments]['post']['responses']['200']['schema'];

export type MutationRemoveSofiaDocumentsQuery =
  Paths['sofiaV1'][MutationRemoveSofiaDocuments]['post']['parameters']['body']['input'];

export type MutationRemoveSofiaDocumentsParams = { businessId: string } & MutationRemoveSofiaDocumentsQuery;

export type MutationRemoveSofiaDocumentsOptions = Omit<
  UseMutationOptions<MutationRemoveSofiaDocumentsResponse, ConnectlyError, MutationRemoveSofiaDocumentsParams, unknown>,
  'mutationKey' | 'mutationFn'
>;
