import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from 'use-file-picker';
import produce from 'immer';
import { Box, Button, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import {
  selectBusinessId,
  selectWhatsappBusinessChannel,
  selectWhatsappProfilePicture,
  useBusinessChannelsData,
  useMediaUploadMutation,
  useMeData,
  usePrevious,
  useBusinessChannelMutation,
} from '@hooks';
import { NotificationContext } from '../../../../../../contexts';
import { IMAGE_MAX_FILE_SIZE_MB } from '../../../../../../utils/messageTemplates';
import { SIZE_MEDIA_M } from '../../../../../../ui-theme';

const useBusinessProfilePicture = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessProfilePicture' });
  const { data: businessId = '' } = useMeData({ select: selectBusinessId });
  const { data: businessProfilePicture } = useBusinessChannelsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectWhatsappProfilePicture,
  });
  const { notificationProvider } = useContext(NotificationContext);
  const queryClient = useQueryClient();
  const { mutate: mutateBusinessChannel, isLoading: isMutatingBusinessChannel } = useBusinessChannelMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['businessChannels']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `New business profile picture has been saved.`,
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `Something went wrong while updating new business profile picture. Please try again.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const [openFileSelector, { filesContent, plainFiles, loading: isFileSelectorLoading, errors, clear }] = useFilePicker(
    {
      accept: ['.png', '.jpg', '.jpeg'],
      multiple: false,
      maxFileSize: IMAGE_MAX_FILE_SIZE_MB,
    },
  );
  const previousIsFileSelectorLoading = usePrevious(isFileSelectorLoading);
  const { isLoading: isUploading, mutate: uploadFile } = useMediaUploadMutation();
  const { isFetching: isFetchingProfilePicture, isLoading: isLoadingProfilePicture } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappProfilePicture,
  });
  const { data: whatsappBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappBusinessChannel,
  });

  const isLoading = useMemo(() => {
    return (
      isFetchingProfilePicture ||
      isLoadingProfilePicture ||
      isUploading ||
      isMutatingBusinessChannel ||
      isFileSelectorLoading
    );
  }, [
    isFetchingProfilePicture,
    isLoadingProfilePicture,
    isUploading,
    isMutatingBusinessChannel,
    isFileSelectorLoading,
  ]);

  useEffect(() => {
    if (isFileSelectorLoading || !previousIsFileSelectorLoading) return;

    if (errors.length > 0) {
      const { fileSizeToolarge: fileSizeTooLarge } = errors[0];
      if (fileSizeTooLarge) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: t('errorFileSize', { size: IMAGE_MAX_FILE_SIZE_MB }),
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      } else {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: t('errorFile'),
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      }
      return;
    }

    const imageRegExp = /\.(jpg|jpeg|png)$/i;
    if (!imageRegExp.test(plainFiles[0].name)) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('errorFileType'),
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }

    if (!plainFiles.length || !filesContent.length) return;
    if (!businessId) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('errorBeforeUploadFile'),
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }

    uploadFile(
      {
        businessId,
        file: plainFiles[0],
      },
      {
        onSuccess: (data) => {
          if (data) {
            if (!whatsappBusinessChannel?.id || !whatsappBusinessChannel?.remoteProfile?.wabaPhoneNumber) return;
            clear();
            const newWhatsappBusinessChannel = produce(whatsappBusinessChannel, (draft) => {
              if (draft?.remoteProfile?.wabaPhoneNumber)
                draft.remoteProfile.wabaPhoneNumber.profilePictureUrl = data.uri;
            });
            mutateBusinessChannel({
              businessId,
              channel: {
                byTypeAndExternalId: {
                  externalId: whatsappBusinessChannel?.externalId,
                  channelType: whatsappBusinessChannel?.channelType,
                },
              },
              ...newWhatsappBusinessChannel,
            });
          }
        },
        onError: () => {
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: t('errorUploadFile'),
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          });
        },
      },
    );
  }, [
    businessId,
    clear,
    errors,
    filesContent.length,
    isFileSelectorLoading,
    notificationProvider,
    previousIsFileSelectorLoading,
    plainFiles,
    uploadFile,
    whatsappBusinessChannel,
    mutateBusinessChannel,
    t,
    filesContent,
  ]);

  const handleUpload = useCallback(() => {
    openFileSelector();
  }, [openFileSelector]);

  return {
    businessProfilePicture,
    isUploading: isUploading || isMutatingBusinessChannel,
    isLoading,
    onUpload: handleUpload,
  };
};

export const BusinessProfilePicture = () => {
  const { businessProfilePicture, isUploading, onUpload, isLoading } = useBusinessProfilePicture();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessProfilePicture' });
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'flex-start' }}>
      <Box>
        <Label>
          {t('title')}
          <span
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            {` (${t('optional')})`}
          </span>
        </Label>
        <Label color="textSecondary" variant="body1">
          {t('subtitle')}
        </Label>
      </Box>
      {businessProfilePicture && (
        <Box
          component="img"
          src={businessProfilePicture}
          sx={{
            width: theme.spacing(SIZE_MEDIA_M),
            height: 'auto',
          }}
        />
      )}
      <Button disabled={isLoading} variant={businessProfilePicture ? 'outlined' : 'contained'} onClick={onUpload}>
        {isUploading ? t('uploading') : businessProfilePicture ? t('reupload') : t('upload')}
      </Button>
    </Box>
  );
};
