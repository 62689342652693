export const mutationChangeRoomStatusProvider = (log, networkClient) => async (input) => {
    const url = `/v1/businesses/${input.businessId}/change_status/rooms/${input.roomId}`;
    log.debug('mutationChangeRoomStatus@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.put(url, {
            businessId: input.businessId,
            roomId: input.roomId,
            userId: input.userId,
            status: input.status,
        });
        log.debug('mutationChangeRoomStatus@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationChangeRoomStatus@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
