import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { mapChannelTypeToIconKind } from '@connectlyai-sdks/data-rooms';
import { BLANK_DASH } from '../../entities';
import { CustomerDIContext } from '../../context';
import { selectCustomerDescription } from '../../state/customerSlice';
import { useFeatureSelector } from '../../state/hooks';
export const QuickProfileComponentId = '2f9b8e79-819d-427b-91d2-6467b1552333';
function mapToChannelIcon(entity) {
    const channelType = entity === null || entity === void 0 ? void 0 : entity.channelType;
    if (channelType === undefined) {
        return undefined;
    }
    return mapChannelTypeToIconKind(channelType);
}
const QuickProfile = (_props) => {
    const di = useContext(CustomerDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(QuickProfileComponentId);
    const entity = useFeatureSelector(selectCustomerDescription);
    const customerViewModel = {
        subject: entity.subject || entity.phone || BLANK_DASH,
        avatarUrl: entity.avatarUrl,
        channelIconKind: mapToChannelIcon(entity.lastChannel),
        lastMessage: undefined,
    };
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { customerViewModel: customerViewModel }) });
};
export default QuickProfile;
