import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.common";
export var RoomStatus;
(function (RoomStatus) {
    RoomStatus[RoomStatus["ROOM_STATUS_UNSPECIFIED"] = 0] = "ROOM_STATUS_UNSPECIFIED";
    RoomStatus[RoomStatus["ROOM_STATUS_OPEN"] = 1] = "ROOM_STATUS_OPEN";
    RoomStatus[RoomStatus["ROOM_STATUS_IN_PROGRESS"] = 2] = "ROOM_STATUS_IN_PROGRESS";
    RoomStatus[RoomStatus["ROOM_STATUS_CLOSED"] = 3] = "ROOM_STATUS_CLOSED";
    RoomStatus[RoomStatus["ROOM_STATUS_CUSTOM"] = 4] = "ROOM_STATUS_CUSTOM";
    RoomStatus[RoomStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(RoomStatus || (RoomStatus = {}));
export function roomStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "ROOM_STATUS_UNSPECIFIED":
            return RoomStatus.ROOM_STATUS_UNSPECIFIED;
        case 1:
        case "ROOM_STATUS_OPEN":
            return RoomStatus.ROOM_STATUS_OPEN;
        case 2:
        case "ROOM_STATUS_IN_PROGRESS":
            return RoomStatus.ROOM_STATUS_IN_PROGRESS;
        case 3:
        case "ROOM_STATUS_CLOSED":
            return RoomStatus.ROOM_STATUS_CLOSED;
        case 4:
        case "ROOM_STATUS_CUSTOM":
            return RoomStatus.ROOM_STATUS_CUSTOM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return RoomStatus.UNRECOGNIZED;
    }
}
export function roomStatusToJSON(object) {
    switch (object) {
        case RoomStatus.ROOM_STATUS_UNSPECIFIED:
            return "ROOM_STATUS_UNSPECIFIED";
        case RoomStatus.ROOM_STATUS_OPEN:
            return "ROOM_STATUS_OPEN";
        case RoomStatus.ROOM_STATUS_IN_PROGRESS:
            return "ROOM_STATUS_IN_PROGRESS";
        case RoomStatus.ROOM_STATUS_CLOSED:
            return "ROOM_STATUS_CLOSED";
        case RoomStatus.ROOM_STATUS_CUSTOM:
            return "ROOM_STATUS_CUSTOM";
        case RoomStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_UNSPECIFIED"] = 0] = "DELIVERY_STATUS_UNSPECIFIED";
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_DELIVERED"] = 1] = "DELIVERY_STATUS_DELIVERED";
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_READ"] = 2] = "DELIVERY_STATUS_READ";
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_FAILURE"] = 3] = "DELIVERY_STATUS_FAILURE";
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_FAILED_TO_DELIVER"] = 4] = "DELIVERY_STATUS_FAILED_TO_DELIVER";
    DeliveryStatus[DeliveryStatus["DELIVERY_STATUS_SENT"] = 5] = "DELIVERY_STATUS_SENT";
    DeliveryStatus[DeliveryStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(DeliveryStatus || (DeliveryStatus = {}));
export function deliveryStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "DELIVERY_STATUS_UNSPECIFIED":
            return DeliveryStatus.DELIVERY_STATUS_UNSPECIFIED;
        case 1:
        case "DELIVERY_STATUS_DELIVERED":
            return DeliveryStatus.DELIVERY_STATUS_DELIVERED;
        case 2:
        case "DELIVERY_STATUS_READ":
            return DeliveryStatus.DELIVERY_STATUS_READ;
        case 3:
        case "DELIVERY_STATUS_FAILURE":
            return DeliveryStatus.DELIVERY_STATUS_FAILURE;
        case 4:
        case "DELIVERY_STATUS_FAILED_TO_DELIVER":
            return DeliveryStatus.DELIVERY_STATUS_FAILED_TO_DELIVER;
        case 5:
        case "DELIVERY_STATUS_SENT":
            return DeliveryStatus.DELIVERY_STATUS_SENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DeliveryStatus.UNRECOGNIZED;
    }
}
export function deliveryStatusToJSON(object) {
    switch (object) {
        case DeliveryStatus.DELIVERY_STATUS_UNSPECIFIED:
            return "DELIVERY_STATUS_UNSPECIFIED";
        case DeliveryStatus.DELIVERY_STATUS_DELIVERED:
            return "DELIVERY_STATUS_DELIVERED";
        case DeliveryStatus.DELIVERY_STATUS_READ:
            return "DELIVERY_STATUS_READ";
        case DeliveryStatus.DELIVERY_STATUS_FAILURE:
            return "DELIVERY_STATUS_FAILURE";
        case DeliveryStatus.DELIVERY_STATUS_FAILED_TO_DELIVER:
            return "DELIVERY_STATUS_FAILED_TO_DELIVER";
        case DeliveryStatus.DELIVERY_STATUS_SENT:
            return "DELIVERY_STATUS_SENT";
        case DeliveryStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WebhookTopic;
(function (WebhookTopic) {
    WebhookTopic[WebhookTopic["WEBHOOK_TOPIC_TOPIC_UNSPECIFIED"] = 0] = "WEBHOOK_TOPIC_TOPIC_UNSPECIFIED";
    WebhookTopic[WebhookTopic["WEBHOOK_TOPIC_TOPIC_MESSAGES"] = 1] = "WEBHOOK_TOPIC_TOPIC_MESSAGES";
    WebhookTopic[WebhookTopic["WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS"] = 2] = "WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS";
    WebhookTopic[WebhookTopic["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WebhookTopic || (WebhookTopic = {}));
export function webhookTopicFromJSON(object) {
    switch (object) {
        case 0:
        case "WEBHOOK_TOPIC_TOPIC_UNSPECIFIED":
            return WebhookTopic.WEBHOOK_TOPIC_TOPIC_UNSPECIFIED;
        case 1:
        case "WEBHOOK_TOPIC_TOPIC_MESSAGES":
            return WebhookTopic.WEBHOOK_TOPIC_TOPIC_MESSAGES;
        case 2:
        case "WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS":
            return WebhookTopic.WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WebhookTopic.UNRECOGNIZED;
    }
}
export function webhookTopicToJSON(object) {
    switch (object) {
        case WebhookTopic.WEBHOOK_TOPIC_TOPIC_UNSPECIFIED:
            return "WEBHOOK_TOPIC_TOPIC_UNSPECIFIED";
        case WebhookTopic.WEBHOOK_TOPIC_TOPIC_MESSAGES:
            return "WEBHOOK_TOPIC_TOPIC_MESSAGES";
        case WebhookTopic.WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS:
            return "WEBHOOK_TOPIC_TOPIC_DELIVERY_STATUS";
        case WebhookTopic.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseMetadata() {
    return { external: {} };
}
export const Metadata = {
    encode(message, writer = _m0.Writer.create()) {
        Object.entries(message.external).forEach(([key, value]) => {
            Metadata_ExternalEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = Metadata_ExternalEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.external[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            external: isObject(object.external)
                ? Object.entries(object.external).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        obj.external = {};
        if (message.external) {
            Object.entries(message.external).forEach(([k, v]) => {
                obj.external[k] = v;
            });
        }
        return obj;
    },
};
function createBaseMetadata_ExternalEntry() {
    return { key: "", value: "" };
}
export const Metadata_ExternalEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadata_ExternalEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
