import { createSlice } from '@reduxjs/toolkit';
import { resetStore, setNotesError, setNotesInput } from './action';
import { updateCustomerNotes } from './thunk';
const initialState = {
    isActive: false,
    isError: false,
    errorMessage: null,
    textInput: null,
};
export const updateNotesProfileSlice = createSlice({
    name: 'updateNotesProfile',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setNotesInput, (state, action) => {
            state.isActive = action.payload.isActive;
            state.isError = false;
            state.errorMessage = null;
            state.textInput = action.payload.textInput;
        });
        builder.addCase(setNotesError, (state, action) => {
            state.isError = action.payload.isError;
            state.errorMessage = action.payload.isError ? action.payload.errorMsg || '' : null;
        });
        builder.addCase(updateCustomerNotes.pending, (state, _action) => {
            state.isActive = true;
            state.isError = false;
            state.errorMessage = null;
        });
        builder.addCase(updateCustomerNotes.fulfilled, (state, action) => {
            state.isActive = false;
            state.isError = false;
            state.errorMessage = null;
            state.textInput = action.payload.textInput;
        });
        builder.addCase(updateCustomerNotes.rejected, (state, _action) => {
            state.isActive = true;
            state.isError = true;
            state.errorMessage = 'Error: Please try again!';
        });
    },
});
export const { reset } = updateNotesProfileSlice.actions;
export const selectUpdateNotesProfileState = (state) => state.updateNotesProfileSlice;
export default updateNotesProfileSlice.reducer;
