export const SettingsRouteId = '/settings';
export const SettingsCategoryRouteId = '/settings/:categoryId';
export const SETTINGS_CATEGORY_GENERAL = 'general';
export const SETTINGS_CATEGORY_QUICK_REPLY = 'quick-reply';
export const SETTINGS_CATEGORY_TAG = 'tag';
export const SETTINGS_CATEGORY_USERS = 'users';
export const SETTINGS_CATEGORY_FEATURES = 'features';
export const SETTINGS_CATEGORY_CAMPAIGNS = 'campaigns';
export const SETTINGS_VTEX = 'vtex';
export function generateSettingsRoute() {
    return {
        id: SettingsRouteId,
        params: {},
    };
}
export function generateSettingsCategoryRoute(args) {
    return {
        id: SettingsCategoryRouteId,
        params: {
            categoryId: args.categoryId,
        },
    };
}
