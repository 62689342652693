import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
const initialState = {
    items: [],
};
export const quickReplySlice = createSlice({
    name: 'quickReply',
    initialState,
    reducers: {
        reset: () => initialState,
        setQuickReplyTemplates: (state, action) => {
            state.items = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
    },
});
export const { setQuickReplyTemplates } = quickReplySlice.actions;
export const selectQuickReplyTemplates = (state) => (state.quickReplySlice.items);
export default quickReplySlice.reducer;
