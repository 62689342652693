import React, { useMemo, useEffect, useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  EmptyProfilePictureIcon,
  WhitePencilIcon,
  ProfilePencilBackgroundIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useFilePicker } from 'use-file-picker';
import produce from 'immer';
import { useQueryClient } from '@tanstack/react-query';
import {
  useMediaUploadMutation,
  selectBusinessId,
  useMeData,
  useBusinessChannelsData,
  selectWhatsappProfilePicture,
  selectWhatsappBusinessChannel,
  useBusinessChannelMutation,
  usePrevious,
  useDebounce,
} from '../../hooks';
import { IMAGE_MAX_FILE_SIZE_MB } from '../../utils/messageTemplates';
import { NotificationContext } from '../../contexts';
import { WhatsappBusinessProfilePictureProps } from './types';

export const WhatsappBusinessProfilePicture = ({
  supportPreview = false,
  savePreviewedFile = false,
  readOnly = false,
  setSavePreviewedFile = () => {},
  handleChangePictureSuccess,
  handleChangePictureError,
}: WhatsappBusinessProfilePictureProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [proposedImage, setProposedImage] = useState<any>(null);
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  useEffect(() => {
    if (imageSrc) {
      setImageSrc(null);
    }
    if (proposedImage) {
      setProposedImage(null);
    }
  }, [businessId]);
  const [openFileSelector, { filesContent, plainFiles, loading: isFileSelectorLoading, errors, clear }] = useFilePicker(
    {
      accept: ['.png', '.jpg', '.jpeg'],
      multiple: false,
      maxFileSize: IMAGE_MAX_FILE_SIZE_MB,
    },
  );
  const previousIsFileSelectorLoading = usePrevious(isFileSelectorLoading);
  const { isLoading: isUploading, mutate: uploadFile } = useMediaUploadMutation();
  const {
    data: whatsappBusinessProfilePicture,
    isFetching: isFetchingProfilePicture,
    isLoading: isLoadingProfilePicture,
  } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappProfilePicture,
  });
  const { data: whatsappBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappBusinessChannel,
  });
  const queryClient = useQueryClient();
  const { mutate: mutateBusinessChannel, isLoading: isMutatingProfilePicture } = useBusinessChannelMutation({
    onSuccess: () => {
      if (handleChangePictureSuccess) handleChangePictureSuccess();
      queryClient.invalidateQueries(['businessChannels']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `New business profile picture has been saved.`,
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
    onError: () => {
      if (handleChangePictureError) handleChangePictureError();
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `Something went wrong while updating new business profile picture. Please try again.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const isLoading = useMemo(() => {
    return (
      isFetchingProfilePicture ||
      isLoadingProfilePicture ||
      isUploading ||
      isMutatingProfilePicture ||
      isFileSelectorLoading
    );
  }, [isFetchingProfilePicture, isLoadingProfilePicture, isUploading, isMutatingProfilePicture, isFileSelectorLoading]);

  const [isLoadingDebounced, setIsLoadingDebounced] = React.useState(false);
  useDebounce(
    () => {
      setIsLoadingDebounced(isLoading);
    },
    250,
    [isLoading],
  );
  const uploadProfilePicture = (id: string) => {
    uploadFile(
      {
        businessId: id,
        file: plainFiles[0],
      },
      {
        onSuccess: (data) => {
          if (data) {
            if (!whatsappBusinessChannel?.id || !whatsappBusinessChannel?.remoteProfile?.wabaPhoneNumber) return;
            clear();
            const newWhatsappBusinessChannel = produce(whatsappBusinessChannel, (draft) => {
              if (draft?.remoteProfile?.wabaPhoneNumber)
                draft.remoteProfile.wabaPhoneNumber.profilePictureUrl = data.uri;
            });
            mutateBusinessChannel({
              businessId: id,
              channel: {
                byTypeAndExternalId: {
                  externalId: whatsappBusinessChannel?.externalId,
                  channelType: whatsappBusinessChannel?.channelType,
                },
              },
              ...newWhatsappBusinessChannel,
            });
          }
        },
        onError: () => {
          setImageSrc(null);
          if (handleChangePictureError) handleChangePictureError();
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: `Something went wrong while uploading new business profile picture. Please try again.`,
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          });
        },
      },
    );
  };

  useEffect(() => {
    if (isFileSelectorLoading || !previousIsFileSelectorLoading) return;

    if (errors.length > 0) {
      const { fileSizeToolarge: fileSizeTooLarge } = errors[0];
      if (fileSizeTooLarge) {
        if (handleChangePictureError) handleChangePictureError();
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message:
              `File size exceeds limit (${IMAGE_MAX_FILE_SIZE_MB}MB)` +
              ` and cannot be uploaded. Please select another one.`,
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      } else {
        if (handleChangePictureError) handleChangePictureError();
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: `Something went wrong with the file you are trying to upload. Please try another file.`,
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      }
      return;
    }

    const imageRegExp = /\.(jpg|jpeg|png)$/i;
    if (!imageRegExp.test(plainFiles[0].name)) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `This file type cannot be uploaded. Please select .jpg, .jpeg or .png file.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }

    if (!plainFiles.length || !filesContent.length) return;
    if (!businessId) {
      if (handleChangePictureError) handleChangePictureError();
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `Something went wrong before uploading new business profile picture. Please try again.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }
    if (supportPreview) {
      const file = plainFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
        setProposedImage(file);
        if (handleChangePictureSuccess) handleChangePictureSuccess();
      };
      reader.readAsDataURL(file);
    } else {
      uploadProfilePicture(businessId);
    }
  }, [
    businessId,
    clear,
    errors,
    filesContent.length,
    isFileSelectorLoading,
    mutateBusinessChannel,
    notificationProvider,
    previousIsFileSelectorLoading,
    plainFiles,
    uploadFile,
    whatsappBusinessChannel,
  ]);
  useEffect(() => {
    if (businessId && savePreviewedFile && proposedImage) {
      setProposedImage(null);
      setSavePreviewedFile(false);
      uploadProfilePicture(businessId);
    }
  }, [businessId, savePreviewedFile, proposedImage]);

  return (
    <Box
      sx={{
        width: (theme) => theme.spacing(8),
        height: (theme) => theme.spacing(8),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: readOnly ? 'default' : 'pointer',
        '&:hover': {
          '.pencil-icon': {
            display: 'flex',
          },
          '.dark-layer': {
            display: 'flex',
          },
        },
      }}
      onClick={() => !readOnly && openFileSelector()}
    >
      {isLoadingDebounced && <CircularProgress size={32} />}
      {!isLoadingDebounced && !(imageSrc || whatsappBusinessProfilePicture) && <EmptyProfilePictureIcon />}
      {!isLoadingDebounced && (imageSrc || whatsappBusinessProfilePicture) && (
        <Box
          key={imageSrc || whatsappBusinessProfilePicture}
          component="img"
          src={imageSrc || whatsappBusinessProfilePicture}
          alt="image"
          sx={{ width: '100%', height: '100%', borderRadius: '50%' }}
        />
      )}
      {!readOnly && (
        <>
          <Box
            className="dark-layer"
            sx={{
              width: (theme) => theme.spacing(8),
              height: (theme) => theme.spacing(8),
              alignItems: 'center',
              display: 'none',
              justifyContent: 'center',
              position: 'absolute',
            }}
          >
            <ProfilePencilBackgroundIcon />
          </Box>
          <Box
            className="pencil-icon"
            sx={{
              position: 'absolute',
              display: 'none',
            }}
          >
            <WhitePencilIcon />
          </Box>
        </>
      )}
    </Box>
  );
};
