import Long from "long";
import * as _m0 from "protobufjs/minimal";
import { BoolValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "models.common.api";
export var MutationStatus;
(function (MutationStatus) {
    MutationStatus[MutationStatus["MUTATION_STATUS_UNSPECIFIED"] = 0] = "MUTATION_STATUS_UNSPECIFIED";
    MutationStatus[MutationStatus["MUTATION_STATUS_SUCCESS"] = 1] = "MUTATION_STATUS_SUCCESS";
    MutationStatus[MutationStatus["MUTATION_STATUS_FAILURE"] = 2] = "MUTATION_STATUS_FAILURE";
    MutationStatus[MutationStatus["MUTATION_STATUS_CUSTOM"] = 3] = "MUTATION_STATUS_CUSTOM";
    MutationStatus[MutationStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MutationStatus || (MutationStatus = {}));
export function mutationStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "MUTATION_STATUS_UNSPECIFIED":
            return MutationStatus.MUTATION_STATUS_UNSPECIFIED;
        case 1:
        case "MUTATION_STATUS_SUCCESS":
            return MutationStatus.MUTATION_STATUS_SUCCESS;
        case 2:
        case "MUTATION_STATUS_FAILURE":
            return MutationStatus.MUTATION_STATUS_FAILURE;
        case 3:
        case "MUTATION_STATUS_CUSTOM":
            return MutationStatus.MUTATION_STATUS_CUSTOM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MutationStatus.UNRECOGNIZED;
    }
}
export function mutationStatusToJSON(object) {
    switch (object) {
        case MutationStatus.MUTATION_STATUS_UNSPECIFIED:
            return "MUTATION_STATUS_UNSPECIFIED";
        case MutationStatus.MUTATION_STATUS_SUCCESS:
            return "MUTATION_STATUS_SUCCESS";
        case MutationStatus.MUTATION_STATUS_FAILURE:
            return "MUTATION_STATUS_FAILURE";
        case MutationStatus.MUTATION_STATUS_CUSTOM:
            return "MUTATION_STATUS_CUSTOM";
        case MutationStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var AvailabilityStatus;
(function (AvailabilityStatus) {
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_UNSPECIFIED"] = 0] = "AVAILABILITY_STATUS_UNSPECIFIED";
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_OK"] = 1] = "AVAILABILITY_STATUS_OK";
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_INVALID"] = 2] = "AVAILABILITY_STATUS_INVALID";
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_UNAVAILABLE"] = 3] = "AVAILABILITY_STATUS_UNAVAILABLE";
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM"] = 4] = "AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM";
    AvailabilityStatus[AvailabilityStatus["AVAILABILITY_STATUS_CUSTOM"] = 5] = "AVAILABILITY_STATUS_CUSTOM";
    AvailabilityStatus[AvailabilityStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(AvailabilityStatus || (AvailabilityStatus = {}));
export function availabilityStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "AVAILABILITY_STATUS_UNSPECIFIED":
            return AvailabilityStatus.AVAILABILITY_STATUS_UNSPECIFIED;
        case 1:
        case "AVAILABILITY_STATUS_OK":
            return AvailabilityStatus.AVAILABILITY_STATUS_OK;
        case 2:
        case "AVAILABILITY_STATUS_INVALID":
            return AvailabilityStatus.AVAILABILITY_STATUS_INVALID;
        case 3:
        case "AVAILABILITY_STATUS_UNAVAILABLE":
            return AvailabilityStatus.AVAILABILITY_STATUS_UNAVAILABLE;
        case 4:
        case "AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM":
            return AvailabilityStatus.AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM;
        case 5:
        case "AVAILABILITY_STATUS_CUSTOM":
            return AvailabilityStatus.AVAILABILITY_STATUS_CUSTOM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AvailabilityStatus.UNRECOGNIZED;
    }
}
export function availabilityStatusToJSON(object) {
    switch (object) {
        case AvailabilityStatus.AVAILABILITY_STATUS_UNSPECIFIED:
            return "AVAILABILITY_STATUS_UNSPECIFIED";
        case AvailabilityStatus.AVAILABILITY_STATUS_OK:
            return "AVAILABILITY_STATUS_OK";
        case AvailabilityStatus.AVAILABILITY_STATUS_INVALID:
            return "AVAILABILITY_STATUS_INVALID";
        case AvailabilityStatus.AVAILABILITY_STATUS_UNAVAILABLE:
            return "AVAILABILITY_STATUS_UNAVAILABLE";
        case AvailabilityStatus.AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM:
            return "AVAILABILITY_STATUS_UNAVAILABLE_CUSTOM";
        case AvailabilityStatus.AVAILABILITY_STATUS_CUSTOM:
            return "AVAILABILITY_STATUS_CUSTOM";
        case AvailabilityStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseXState() {
    return { created: undefined };
}
export const XState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.created !== undefined) {
            BoolValue.encode({ value: message.created }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseXState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.created = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            created: isSet(object.created) ? Boolean(object.created) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.created !== undefined && (obj.created = message.created);
        return obj;
    },
};
function createBaseSimplePaging() {
    return { page: 0, perPage: 0, total: 0 };
}
export const SimplePaging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.page !== 0) {
            writer.uint32(8).int32(message.page);
        }
        if (message.perPage !== 0) {
            writer.uint32(16).int32(message.perPage);
        }
        if (message.total !== 0) {
            writer.uint32(24).int64(message.total);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSimplePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.perPage = reader.int32();
                    break;
                case 3:
                    message.total = longToNumber(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            page: isSet(object.page) ? Number(object.page) : 0,
            perPage: isSet(object.perPage) ? Number(object.perPage) : 0,
            total: isSet(object.total) ? Number(object.total) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.page !== undefined && (obj.page = Math.round(message.page));
        message.perPage !== undefined &&
            (obj.perPage = Math.round(message.perPage));
        message.total !== undefined && (obj.total = Math.round(message.total));
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
