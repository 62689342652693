import { AttachmentType as AttachmentTypeDto, } from '@connectlyai-sdks/data-rooms';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { MediaParameter_Type, mediaParameter_TypeToJSON, } from '@connectlyai-idl/models/dist/models/messaging/template/models';
import { AttachmentType, MessageClass } from '../entities';
export function getTemplatedMessage(message, di) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (!message) {
        return {
            type: 'text',
            text: '',
            attachments: [],
        };
    }
    const header = (_a = message.preview) === null || _a === void 0 ? void 0 : _a.header;
    const body = (_b = message.preview) === null || _b === void 0 ? void 0 : _b.body;
    const buttons = (_c = message.preview) === null || _c === void 0 ? void 0 : _c.buttons.map((button) => {
        var _a, _b, _c;
        if (((_a = button.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'url') {
            return {
                type: 'button',
                text: button.valueOneof.url.text,
                url: button.valueOneof.url.link,
            };
        }
        if (((_b = button.valueOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'quickReply') {
            return {
                type: 'button',
                text: button.valueOneof.quickReply.text,
                quickReply: button.valueOneof.quickReply.payload,
            };
        }
        if (((_c = button.valueOneof) === null || _c === void 0 ? void 0 : _c.$case) === 'phoneNumber') {
            return {
                type: 'button',
                text: button.valueOneof.phoneNumber.text,
                phoneNumber: button.valueOneof.phoneNumber.phoneNumber,
            };
        }
        return {
            type: 'button',
            text: '',
        };
    });
    if (((_d = header === null || header === void 0 ? void 0 : header.media) === null || _d === void 0 ? void 0 : _d.type) && ((_e = header === null || header === void 0 ? void 0 : header.media) === null || _e === void 0 ? void 0 : _e.type) !== MediaParameter_Type.TYPE_IMAGE) {
        di.logProvider().warn('unsupported media link', () => {
            var _a;
            return ({
                mediaType: mediaParameter_TypeToJSON(((_a = header === null || header === void 0 ? void 0 : header.media) === null || _a === void 0 ? void 0 : _a.type) || MediaParameter_Type.UNRECOGNIZED),
            });
        });
    }
    return {
        type: 'structured',
        header: header && {
            text: ((_f = header.text) === null || _f === void 0 ? void 0 : _f.text) || '',
            mediaUrl: ((_g = header.media) === null || _g === void 0 ? void 0 : _g.type) === MediaParameter_Type.TYPE_IMAGE ? (_h = header.media) === null || _h === void 0 ? void 0 : _h.link : '',
        },
        body: body && {
            text: ((_j = body.text) === null || _j === void 0 ? void 0 : _j.text) || '',
        },
        buttons: buttons !== null && buttons !== void 0 ? buttons : [],
    };
}
const mapToAttachmentType = (type) => {
    switch (type) {
        case AttachmentTypeDto.ATTACHMENT_TYPE_UNSPECIFIED:
        case AttachmentTypeDto.ATTACHMENT_TYPE_FILE:
        case AttachmentTypeDto.UNRECOGNIZED:
            return AttachmentType.FILE;
        case AttachmentTypeDto.ATTACHMENT_TYPE_IMAGE:
            return AttachmentType.IMAGE;
        case AttachmentTypeDto.ATTACHMENT_TYPE_VIDEO:
            return AttachmentType.VIDEO;
        case AttachmentTypeDto.ATTACHMENT_TYPE_AUDIO:
            return AttachmentType.AUDIO;
        default:
            return assertUnreachable(type);
    }
};
const mapToAttachment = (attachment) => {
    var _a, _b, _c;
    return ({
        type: mapToAttachmentType(attachment.type),
        payload: {
            id: ((_a = attachment.attachmentOneof) === null || _a === void 0 ? void 0 : _a.asset.id) || '',
            url: ((_b = attachment.attachmentOneof) === null || _b === void 0 ? void 0 : _b.asset.url) || '',
            fileName: ((_c = attachment.attachmentOneof) === null || _c === void 0 ? void 0 : _c.asset.filename) || '',
        },
    });
};
export const mapToMessage = (room, event, di) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const authoredByCustomer = event.authorId in room.customerChannelsById;
    const customerAvatar = '';
    const businessAvatar = '';
    let messageContent;
    switch ((_b = (_a = event.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'message': {
            const { text } = event.connectlyEvent.eventOneof.message;
            const attachments = event.connectlyEvent.eventOneof.message.attachments.map(mapToAttachment);
            const { location } = event.connectlyEvent.eventOneof.message;
            messageContent = {
                type: 'text',
                text,
                attachments,
                location,
            };
            break;
        }
        case 'templatedMessage': {
            messageContent = getTemplatedMessage((_c = event.connectlyEvent) === null || _c === void 0 ? void 0 : _c.eventOneof.templatedMessage, di);
            break;
        }
        case 'buttonResponse': {
            const { text } = event.connectlyEvent.eventOneof.buttonResponse;
            messageContent = {
                type: 'text',
                text,
                attachments: [],
            };
            break;
        }
        case 'rewardForContact': {
            const text = 'Reward for contact';
            messageContent = {
                type: 'text',
                text,
                attachments: [],
            };
            break;
        }
        case 'roomAssignment': {
            const text = `Room ${event.connectlyEvent.eventOneof.roomAssignment.roomId} ` +
                `assigned to user ${event.connectlyEvent.eventOneof.roomAssignment.assignedUserId}`;
            messageContent = {
                type: 'text',
                text,
                attachments: [],
            };
            break;
        }
        case 'roomStatusChange': {
            const text = `Room ${event.connectlyEvent.eventOneof.roomStatusChange.roomId} ` +
                `changed status from ${event.connectlyEvent.eventOneof.roomStatusChange.from} ` +
                `to ${event.connectlyEvent.eventOneof.roomStatusChange.to}`;
            messageContent = {
                type: 'text',
                text,
                attachments: [],
            };
            break;
        }
        default:
            messageContent = {
                type: 'text',
                text: 'This message is currently unsupported. We are working hard to make it visible.',
                attachments: [],
            };
    }
    const customerName = `${(_e = (_d = room.customer) === null || _d === void 0 ? void 0 : _d.profile) === null || _e === void 0 ? void 0 : _e.firstName} ${(_g = (_f = room.customer) === null || _f === void 0 ? void 0 : _f.profile) === null || _g === void 0 ? void 0 : _g.lastName}`.trim();
    return {
        id: event.id,
        content: messageContent,
        class: authoredByCustomer ? MessageClass.THEIRS : MessageClass.MINE,
        sender: {
            id: event.authorId,
            avatarUrl: authoredByCustomer ? ((_j = (_h = room.customer) === null || _h === void 0 ? void 0 : _h.profile) === null || _j === void 0 ? void 0 : _j.profilePic) || customerAvatar : businessAvatar,
            name: authoredByCustomer ? customerName : '',
        },
        recipient: {
            id: authoredByCustomer ? event.customerChannelId : event.businessUserId,
            avatarUrl: authoredByCustomer ? businessAvatar : ((_l = (_k = room.customer) === null || _k === void 0 ? void 0 : _k.profile) === null || _l === void 0 ? void 0 : _l.profilePic) || customerAvatar,
            name: '',
        },
        sentAtInISO: event.createdAt,
        deliveredAtInISO: event.deliveredAt,
        readAtInISO: event.readAt,
        error: (_m = event.platformError) === null || _m === void 0 ? void 0 : _m.source,
    };
};
