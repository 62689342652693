import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  ANALYTICS_SUBPAGE_INBOX,
  ANALYTICS_SUBPAGE_SOFIA,
  ANALYTICS_SUBPAGE_CAMPAIGNS,
  ANALYTICS_SUBPAGE_ACCOUNT_OVERVIEW,
  generateAnalyticsCategoryRoute,
  generateAnalyticsHomeRoute,
  generateSendoutReportRoute,
  SendoutReportRouteId,
} from '@connectlyai-sdks/inbox-navigation';
import { NavigationContext } from '../../../../contexts';

export const useNavigator = () => {
  const homeRoute = generateAnalyticsHomeRoute();
  const campaignsRoute = generateAnalyticsCategoryRoute({ categoryId: ANALYTICS_SUBPAGE_CAMPAIGNS });
  const sofiaRoute = generateAnalyticsCategoryRoute({ categoryId: ANALYTICS_SUBPAGE_SOFIA });
  const inboxRoute = generateAnalyticsCategoryRoute({ categoryId: ANALYTICS_SUBPAGE_INBOX });
  const accountOverviewRoute = generateAnalyticsCategoryRoute({ categoryId: ANALYTICS_SUBPAGE_ACCOUNT_OVERVIEW });

  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForAnalytics);

  const openSendoutReport = (sendoutId: string) => navigatorProvider().navigate(generateSendoutReportRoute(sendoutId));

  const routes = useMemo(() => {
    return {
      home: {
        pathname: navigatorProvider().generateGlobalPath(homeRoute),
        navigate: () => navigatorProvider().navigate(campaignsRoute),
      },
      campaigns: {
        pathname: navigatorProvider().generateGlobalPath(campaignsRoute),
        navigate: () => navigatorProvider().navigate(campaignsRoute),
      },
      sofia: {
        pathname: navigatorProvider().generateGlobalPath(sofiaRoute),
        navigate: () => navigatorProvider().navigate(sofiaRoute),
      },
      inbox: {
        pathname: navigatorProvider().generateGlobalPath(inboxRoute),
        navigate: () => navigatorProvider().navigate(inboxRoute),
      },
      accountOverview: {
        pathname: navigatorProvider().generateGlobalPath(accountOverviewRoute),
        navigate: () => navigatorProvider().navigate(accountOverviewRoute),
      },
      sendoutReport: {
        pathname: SendoutReportRouteId,
        navigate: (sendoutId: string) => navigatorProvider().navigate(generateSendoutReportRoute(sendoutId)),
      },
    };
  }, [navigatorProvider, homeRoute, campaignsRoute, sofiaRoute, inboxRoute, accountOverviewRoute]);
  return { routes, openSendoutReport };
};
