import { CampaignState, CampaignInputType, } from '@connectlyai-idl/models/dist/models/crm/campaign/models';
import { MessageTemplate as MessageTemplateDto, MessageTemplateStatus, } from '@connectlyai-idl/models/dist/models/messaging/template/models';
export { MessageTemplateStatus, messageTemplateStatusToJSON, } from '@connectlyai-idl/models/dist/models/messaging/template/models';
export { MediaParameter_Type } from '@connectlyai-idl/models/dist/models/messaging/template/models';
export { MessageTemplateDto };
export { DtoUpdate } from '@connectlyai-sdks/data-update';
export { AssetDto } from '@connectlyai-idl/models/dist/models/asset/models';
export { MessageTemplateComponent_Button as MessageTemplateButton, MessageTemplateComponent_Button_Type, MessageTemplateComponent_Format, MessageTemplate_Status, } from '@connectlyai-idl/models/dist/events/facebook/graphapi/v12_0/whatsapp/models';
export { CampaignDtos } from '@connectlyai-idl/models/dist/models/crm/campaign/models';
export { CampaignDto, CampaignState, CampaignType, InitializeCampaignInput, VerifyCampaignResult, SubmitCampaignResult, campaignStateToJSON, campaignTypeToJSON, STBBroadcastV1, STBBroadcastV1_MessageTemplateIdentifier, WABroadcastV1, } from '@connectlyai-idl/models/dist/models/crm/campaign/models';
export { ChannelType, channelTypeToJSON } from '@connectlyai-idl/models/dist/models/common/channel/models';
export { MutationStatus } from '@connectlyai-idl/models/dist/models/common/api/models';
export { BusinessChannelMetadata } from '@connectlyai-idl/models/dist/models/profile/store/models';
export { AudienceSegmentDtoCollection, AudienceSegmentDto, QueryAudienceCohortInput, QueryAudienceSegmentsInput, AudienceSegmentInput, } from '@connectlyai-idl/models/dist/models/audience/dto/dto';
export { AudienceSegment, AudienceSegmentQuery, AudienceSegmentDynamicQuery, AudienceSegmentDynamicQueryClause, BehaviorSendoutButtonClick, BehaviorSendoutButtonClickClause, SendoutContext, } from '@connectlyai-idl/models/dist/models/audience/models';
export { CampaignDto as CampaignDtoNew, CampaignOrder, campaignOrderToJSON, SendoutDto, SendoutDtoCollection, } from '@connectlyai-idl/models/dist/models/campaign/dto';
export { SendoutState, sendoutStateToJSON } from '@connectlyai-idl/models/dist/models/campaign/models';
export function audienceChannelType(entity) {
    var _a, _b, _c, _d, _e;
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast':
            return (_e = (_d = (_c = entity.config) === null || _c === void 0 ? void 0 : _c.configOneof) === null || _d === void 0 ? void 0 : _d.broadcast.audienceChannel) === null || _e === void 0 ? void 0 : _e.channelType;
        default:
            return undefined;
    }
}
export function configInput(entity) {
    var _a, _b, _c, _d, _e, _f, _g;
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast':
            switch ((_e = (_d = (_c = entity.config) === null || _c === void 0 ? void 0 : _c.configOneof) === null || _d === void 0 ? void 0 : _d.broadcast.templateOneof) === null || _e === void 0 ? void 0 : _e.$case) {
                case 'waBroadcastTemplateV1': {
                    const { waBroadcastTemplateV1 } = (_g = (_f = entity.config) === null || _f === void 0 ? void 0 : _f.configOneof) === null || _g === void 0 ? void 0 : _g.broadcast.templateOneof;
                    return {
                        configOneof: {
                            $case: 'waBroadcast',
                            waBroadcast: {
                                waMessageTemplateId: waBroadcastTemplateV1.waMessageTemplateId,
                            },
                        },
                        inputType: CampaignInputType.CAMPAIGN_INPUT_TYPE_SPREADSHEET,
                    };
                }
                default:
                    return undefined;
            }
        default:
            return undefined;
    }
}
export function isVerified(entity) {
    var _a, _b, _c;
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast': {
            const { broadcast } = (_c = entity.config) === null || _c === void 0 ? void 0 : _c.configOneof;
            return broadcast.cohortCsv !== '';
        }
        default:
            return false;
    }
}
export function templateIds(entity) {
    var _a, _b, _c, _d, _e, _f;
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast': {
            const { broadcast } = (_c = entity.config) === null || _c === void 0 ? void 0 : _c.configOneof;
            switch ((_d = broadcast.templateOneof) === null || _d === void 0 ? void 0 : _d.$case) {
                case 'waBroadcastTemplateV1':
                    return [(_e = broadcast.templateOneof) === null || _e === void 0 ? void 0 : _e.waBroadcastTemplateV1.waMessageTemplateId];
                case 'stbBroadcastTemplateV1':
                    return (_f = broadcast.templateOneof) === null || _f === void 0 ? void 0 : _f.stbBroadcastTemplateV1.messageTemplates.map((x) => x.waMessageTemplateId);
                default:
                    return undefined;
            }
        }
        default:
            return undefined;
    }
}
export function getAudienceSegmentId(entity) {
    var _a, _b, _c, _d;
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast': {
            return (_d = (_c = entity.config) === null || _c === void 0 ? void 0 : _c.configOneof) === null || _d === void 0 ? void 0 : _d.broadcast.audienceSegmentId;
        }
        default:
            return '';
    }
}
function getVariableSheetUrl(dto) {
    var _a, _b, _c, _d, _e;
    switch ((_b = (_a = dto.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast': {
            const { broadcast } = dto.config.configOneof;
            switch ((_c = broadcast.templateOneof) === null || _c === void 0 ? void 0 : _c.$case) {
                case 'waBroadcastTemplateV1': {
                    const { gsheetsSpreadsheetId } = (_d = broadcast.templateOneof) === null || _d === void 0 ? void 0 : _d.waBroadcastTemplateV1;
                    return `https://docs.google.com/spreadsheets/d/${gsheetsSpreadsheetId}/edit#gid=0`;
                }
                case 'stbBroadcastTemplateV1': {
                    const { gsheetsSpreadsheetId } = (_e = broadcast.templateOneof) === null || _e === void 0 ? void 0 : _e.stbBroadcastTemplateV1;
                    return `https://docs.google.com/spreadsheets/d/${gsheetsSpreadsheetId}/edit#gid=0`;
                }
                default:
                    return undefined;
            }
        }
        default:
            return undefined;
    }
}
export function getReportUrl(outcome) {
    var _a;
    if (!outcome) {
        return undefined;
    }
    switch ((_a = outcome.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) {
        case 'reportV1':
            return outcome.outcomeOneof.reportV1.url;
        default:
            return undefined;
    }
}
export function verifyCampaign(entity) {
    var _a, _b, _c;
    const error = new Error('Unsupported campaign.');
    if (entity.state !== CampaignState.CAMPAIGN_STATE_DRAFT) {
        throw error;
    }
    switch ((_b = (_a = entity.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'broadcast': {
            const { broadcast } = entity.config.configOneof;
            switch ((_c = broadcast.templateOneof) === null || _c === void 0 ? void 0 : _c.$case) {
                case 'waBroadcastTemplateV1':
                    return entity;
                case 'stbBroadcastTemplateV1':
                    return entity;
                default:
                    throw error;
            }
        }
        default:
            throw error;
    }
}
export function mapCampaignDto(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        config: dto.config,
        configName: dto.configName,
        createdAt: dto.createdAt,
        completedAt: dto.completedAt,
        dispatchedAt: dto.dispatchedAt,
        name: dto.name,
        reportUrl: getReportUrl(dto.outcome),
        scheduledAt: dto.scheduledAt,
        state: dto.state,
        type: dto.type,
        variableSheetUrl: getVariableSheetUrl(dto),
        verifiedAt: dto.verifiedAt,
    };
}
export function mapSendoutDto(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        campaignId: dto.campaignId,
        createdAt: dto.createdAt,
        dispatchedAt: dto.dispatchedAt,
        completedAt: dto.completedAt,
    };
}
export function mapSendoutCampaignDto(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        name: dto.name,
    };
}
export function isValidTemplate(template) {
    return template && template.dto && template.dto.status === MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_APPROVED;
}
export const isNotNull = (val) => !!val;
