import { useQuery } from '@tanstack/react-query';
import { ListCampaignsOptions, ListCampaignsParametersPath, ListCampaignsParametersBody } from './types';
import { api } from '../../api';

export const fetchCampaigns = ({
  businessId,
  flowDocumentId,
  paging,
}: ListCampaignsParametersPath & ListCampaignsParametersBody) => {
  return api.campaignV2('/internal/v2/businesses/{businessId}/list/campaigns', 'post', {
    path: { businessId },
    body: {
      entity: {
        orderBy: 'CAMPAIGN_ORDER_CREATED_AT_DESC',
        hasFlowDocumentId: true,
        flowDocumentId,
        paging,
      },
    },
  });
};

export const useListCampaignsWithFlowData = ({
  businessId,
  flowDocumentId,
  paging,
  ...options
}: ListCampaignsOptions & ListCampaignsParametersPath & ListCampaignsParametersBody) =>
  useQuery(
    [
      'campaigns',
      { businessId },
      {
        orderBy: 'CAMPAIGN_ORDER_CREATED_AT_DESC',
        flowDocumentId,
        hasFlowDocumentId: true,
        paging,
      },
    ],
    () => fetchCampaigns({ businessId, flowDocumentId, paging }),
    options,
  );
