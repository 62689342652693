import React, { PropsWithChildren, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { claimingPhoneNumberAtom } from '@atoms/home';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Fade,
  FormControlLabel,
  InputAdornment,
  Label,
  List,
  ListItem,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useFeatureFlag, useMeData, useUpdateWAToken } from '@hooks';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { ContentCardHeader, ClaimPhoneNumber } from './components';
import { CaptionLabel } from '../../../../presentation/common/typography';
import { getAppId, useConnectWhatsApp } from '../../../../utils';
import { ApprovalDialog } from '../../../../components/ApprovalDialog';
import { ConnectWhatsAppProps } from './types';
import { NotificationContext } from '../../../../contexts';
import { useMutationProvisionPhoneNumber } from '../../hooks';

const ContentCard: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      boxSizing: 'border-box',
      py: 2,
    }}
  >
    {children}
  </Box>
);

const ContentCardContent: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      ml: 3.5,
    }}
  >
    {children}
  </Box>
);

type ContentCardActionsProps = {
  sx?: SxProps;
};

const ContentCardActions: FC<PropsWithChildren<ContentCardActionsProps>> = ({ children, sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      py: 1,
      ...sx,
    }}
  >
    {children}
  </Box>
);

ContentCardActions.defaultProps = {
  sx: undefined,
};

type InstructionListProps = {
  sx?: SxProps;
};

const InstructionList: FC<PropsWithChildren<InstructionListProps>> = ({ sx = {}, children }) => (
  <List
    sx={{
      p: 0,
      ml: 3,
      listStyle: 'disc',
      color: 'text.secondary',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      ...sx,
    }}
  >
    {children}
  </List>
);

InstructionList.defaultProps = {
  sx: undefined,
};

const InstructionListItem: FC<PropsWithChildren> = ({ children }) => (
  <ListItem
    sx={{
      display: 'list-item',
      p: 0,
    }}
  >
    {children}
  </ListItem>
);

export const ConnectWhatsApp = ({
  claimedNumber,
  isActive,
  onNextStep,
  title,
  verificationCode,
}: ConnectWhatsAppProps) => {
  const { data: businessId = '' } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);
  const [tabValue, setTabValue] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isClaiming, setIsClaiming] = useAtom(claimingPhoneNumberAtom);
  const [hintOpen, setHintOpen] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutate: mutationProvisionPhoneNumber } = useMutationProvisionPhoneNumber({
    onError: () => {
      setIsClaiming(false);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error provisioning phone number.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: () => {
      setIsClaiming(false);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully provisioned phone number',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
      queryClient.invalidateQueries(['settings', businessId]);
    },
  });
  const claimNumber = useCallback(() => {
    if (businessId) {
      setIsClaiming(true);
      mutationProvisionPhoneNumber({
        businessId,
        criteria: {
          isoCountry: 'US',
          areaCode: undefined,
        },
      });
    }
  }, [mutationProvisionPhoneNumber, setIsClaiming, businessId]);

  const { update } = useUpdateWAToken();
  const { ffBusinessServiceProviderVTEX, ffBrazilCreditLine } = useFeatureFlag([
    'ffBusinessServiceProviderVTEX',
    'ffBrazilCreditLine',
  ]);

  const handleCompleteWAConnect = (
    accessToken: string,
    userId: number,
    dataAccessExpirationTime: number,
    expiresIn: number,
  ) => {
    setIsConnecting(false);
    update({
      businessId: businessId || '',
      accessToken,
      userId,
      dataAccessExpirationTime,
      expiresIn,
      businessServiceProvider: ffBusinessServiceProviderVTEX
        ? 'BUSINESS_SERVICE_PROVIDER_VTEX'
        : 'BUSINESS_SERVICE_PROVIDER_CONNECTLY',
      useBrazilCreditLine: ffBrazilCreditLine,
    });
  };

  useEffect(() => {
    if (isActive) {
      setConfirmationOpen(true);
    }
  }, [isActive]);

  const handleCopyVerificationCode = useCallback(() => {
    if (!verificationCode) {
      return;
    }

    navigator.clipboard.writeText(verificationCode);

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Code copied to clipboard',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  }, [notificationProvider, verificationCode]);

  useEffect(() => {
    if (!verificationCode) {
      return;
    }

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: `You verification code is ${verificationCode}`,
        icon: '',
        severity: NotificationSeverity.INFO,
        action: {
          text: 'Copy',
          callback: handleCopyVerificationCode,
        },
      },
    });
  }, [verificationCode, notificationProvider, handleCopyVerificationCode]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(parseInt(newValue, 10));
  };

  const handleClose = useCallback(() => {
    setConfirmationOpen(false);
    if (onNextStep) {
      onNextStep();
    }
  }, [onNextStep]);

  const handleCancelWAConnect = () => {
    setIsConnecting(false);
    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Cancelled connecting WhatsApp',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  };

  const [startConnect] = useConnectWhatsApp(
    {
      businessId,
      phoneNumber: tabValue === 0 ? claimedNumber : undefined,
      appId: getAppId(ffBusinessServiceProviderVTEX),
    },
    handleCompleteWAConnect,
    handleCancelWAConnect,
  );

  const handleWAConnect = () => {
    setIsConnecting(true);
    startConnect();
  };

  const handleCopyNumber = () => {
    if (!claimedNumber) {
      return;
    }

    navigator.clipboard.writeText(claimedNumber);

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Phone copied to clipboard',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  };

  return (
    <>
      <ApprovalDialog open={confirmationOpen} title="WhatsApp has been connected!" onClose={handleClose} />

      <Box>
        <Label variant="h4" sx={{ whiteSpace: 'pre-wrap' }}>
          {title || 'Connect WhatsApp'}
        </Label>
        <Box>
          <ContentCard key="1">
            <ContentCardHeader step={1} title="Choose your phone number option" />
            <ContentCardContent>
              <RadioGroup
                defaultValue="0"
                value={tabValue}
                onChange={handleTabChange}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel value="0" control={<Radio />} label="Get a free number" />
                <FormControlLabel value="1" control={<Radio />} label="Use my own number" />
              </RadioGroup>
            </ContentCardContent>
          </ContentCard>

          <Collapse in={tabValue === 0} appear={false} timeout={claimedNumber ? 'auto' : 0}>
            <Box>
              <ContentCard key="2">
                <ContentCardHeader
                  step={2}
                  title="Claim your free number"
                  subtitle="Get a free number through Connectly."
                />
                <ContentCardContent>
                  <ClaimPhoneNumber
                    number={claimedNumber}
                    onClaim={claimNumber}
                    onCopy={handleCopyNumber}
                    isClaiming={isClaiming}
                  />
                </ContentCardContent>
              </ContentCard>
            </Box>
          </Collapse>

          <Box hidden={tabValue !== 0}>
            {Boolean(claimedNumber) && (
              <ContentCard key="3">
                <ContentCardHeader
                  step={3}
                  title="Connect to WhatsApp Business"
                  subtitle="You will need admin access to Facebook Business Manager."
                />
                <ContentCardContent>
                  <ContentCardActions>
                    <Button variant="contained" disabled={isConnecting || isClaiming} onClick={handleWAConnect}>
                      {isConnecting ? 'Connecting...' : 'Connect Now'}
                    </Button>
                    <Collapse in={hintOpen}>
                      <Fade in={hintOpen}>
                        <Alert severity="info" onClose={() => setHintOpen(false)} sx={{ mt: 2, maxWidth: 620 }}>
                          <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column', alignItems: 'flex-start' }}>
                            {`
                              You will be asked to put in your WhatsApp number
                              and use it to receive an verification code during the sign up process.
                            `}
                          </Box>
                        </Alert>
                      </Fade>
                    </Collapse>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                      <Label
                        variant="body1"
                        sx={{
                          fontWeight: 'fontWeightMedium',
                        }}
                      >
                        Verification code
                      </Label>
                      <TextField
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant="text"
                                aria-label="copy verification code to clipboard"
                                disabled={!verificationCode}
                                onClick={handleCopyVerificationCode}
                              >
                                Copy
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="000000"
                        value={verificationCode}
                        sx={{
                          '& .MuiInputBase-root': {
                            borderRadius: '10px',
                            fontSize: '48px',
                          },
                          '& .MuiInputBase-input': {
                            height: '56px',
                            px: '16px',
                            py: '14px',
                          },
                        }}
                      />
                    </Box>
                  </ContentCardActions>
                </ContentCardContent>
              </ContentCard>
            )}
          </Box>

          <Box hidden={tabValue !== 1}>
            <ContentCard key="2">
              <ContentCardHeader step={2} title="Connect to WhatsApp Business" />
              <ContentCardContent>
                <InstructionList sx={{ mb: '10px' }}>
                  <InstructionListItem>
                    <CaptionLabel>You will need admin access to Facebook Business Manager.</CaptionLabel>
                  </InstructionListItem>
                  <InstructionListItem>
                    <CaptionLabel>
                      The phone number can not be currently used with WhatsApp or WhatsApp for business.
                    </CaptionLabel>
                  </InstructionListItem>
                  <InstructionListItem>
                    <CaptionLabel>It cannot be your personal phone number.</CaptionLabel>
                  </InstructionListItem>
                </InstructionList>
                <ContentCardActions>
                  <Button variant="contained" disabled={isConnecting} onClick={handleWAConnect}>
                    {isConnecting ? 'Connecting...' : 'Connect Now'}
                  </Button>
                </ContentCardActions>
              </ContentCardContent>
            </ContentCard>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ConnectWhatsApp.defaultProps = {
  onNextStep: () => {},
};

export default ConnectWhatsApp;
