import { QueryAlertsResponse } from '@hooks/useQueryAlertsData/types';
import { AlertColor } from '@connectlyai-tenets/ui-styled-web';

export const mapSeverityToAlertColor = (
  severity: NonNullable<
    NonNullable<NonNullable<NonNullable<NonNullable<QueryAlertsResponse['entity']>['entries']>[0]>['alert']>['v1']
  >['severity'],
) => {
  let color: AlertColor;

  switch (severity) {
    case 'ALERT_SEVERITY_HIGH':
      color = 'error';
      break;
    case 'ALERT_SEVERITY_MEDIUM':
      color = 'warning';
      break;
    case 'ALERT_SEVERITY_LOW':
      color = 'info';
      break;
    default:
      color = 'info';
      break;
  }

  return color;
};
