import React, { ComponentProps, useMemo } from 'react';
import { NodeTypes } from 'react-flow-renderer';
import { TimeDelayData } from '@connectlyai-tenets/sdk';
import { OptOutNode } from '../../components/FlowChartCampaignV3/components/OptOutNode';
import { CustomerRepliesNode } from '../../components/FlowChartCampaignV3/components/CustomerRepliesNode';
import { APICallNode } from '../../components/FlowChartCampaignV3/components/APICallNode';
import { TimeDelayNode } from '../../components/FlowChartCampaignV3/components/TimeDelayNode';
import { FlowChartAiAssistantNode } from '../../components/FlowChartAiAssistantNode/FlowChartAiAssistantNode';
import { HoverDecorator } from '../../components/HoverDecorator';
import { DebuggerDecorator } from '../../components/DebuggerDecorator';
import { MessageNode } from '../../components/MessageNode';
import { FlowChartSendCampaignNode } from '../../components/FlowChartSendCampaignNode';

export const useFlowNodeTypes = (): NodeTypes => {
  return useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SEND_WA_MESSAGE: (props: ComponentProps<typeof MessageNode>) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <MessageNode nodeType="FLOW_OBJECT_TYPE_SEND_WA_MESSAGE" {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE: (props: ComponentProps<typeof MessageNode>) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <MessageNode nodeType="FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE" {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_AI_ASSISTANT: (props: ComponentProps<typeof FlowChartAiAssistantNode> & { data: unknown }) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <FlowChartAiAssistantNode {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN: (
        props: ComponentProps<typeof FlowChartSendCampaignNode> & { data: unknown },
      ) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <FlowChartSendCampaignNode {...props} />
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT: (
        props: ComponentProps<typeof FlowChartSendCampaignNode> & { data: unknown },
      ) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <FlowChartSendCampaignNode {...props} />
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_OPT_OUT: (props: ComponentProps<typeof OptOutNode> & { data: unknown }) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <OptOutNode {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT: (props: ComponentProps<typeof CustomerRepliesNode> & { data: unknown }) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <CustomerRepliesNode {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_CALL_API: (props: ComponentProps<typeof APICallNode> & { data: unknown }) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <APICallNode {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
      FLOW_OBJECT_TYPE_TIME_DELAY: (props: ComponentProps<typeof TimeDelayNode> & { data: TimeDelayData }) => (
        <DebuggerDecorator
          data={props?.data}
          sx={{
            fontSize: '6px',
            width: 280,
          }}
          shouldExpandNodeInitially={() => true}
        >
          <HoverDecorator nodeId={props?.id || ''}>
            <TimeDelayNode {...props} />
          </HoverDecorator>
        </DebuggerDecorator>
      ),
    }),
    [],
  );
};
