// unreduxified, has only messageTemplates redux

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  useTheme,
  Autocomplete,
  Box,
  Label,
  PencilIcon,
  CircularProgress,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom, useAtomValue } from 'jotai';
import {
  campaignLanguageAtom,
  campaignNameAtom,
  campaignNameColorAtom,
  campaignNameHintAtom,
  campaignStepAtom,
  customSendoutNodeAtom,
  isCampaignNameDialogOpenAtom,
  isCampaignNameDirtyAtom,
  prevCampaignNameAtom,
  savedCampaignNameAtom,
} from '@atoms/flow';
import { useAnalytics, useFeatureFlag } from '@hooks';
import { CAMPAIGN_NAME_MAX_LENGTH, CAMPAIGN_NAME_VALID_CHARS_REGEXP } from './constants';
import { Languages as LanguageSource, getLanguage } from '../../../../utils';
import { SIZE_CONTENT_L } from '../../../../ui-theme';
import { CampaignNameDialogProps } from './types';
import { useGenerateCampaignName } from '../../hooks/useGenerateCampaignName/useGenerateCampaignName';

const useCampaignName = ({
  isNameUpdateError,
  isNameUpdateSuccess,
  isNameUpdateLoading,
  resetNameUpdate,
  updateCampaignName,
}: CampaignNameDialogProps) => {
  const [campaignLanguage, setCampaignLanguage] = useAtom(campaignLanguageAtom);
  const [campaignName, setCampaignName] = useAtom(campaignNameAtom);
  const [campaignNameColor, setCampaignNameColor] = useAtom(campaignNameColorAtom);
  const [campaignNameHint, setCampaignNameHint] = useAtom(campaignNameHintAtom);
  const [campaignStep, setCampaignStep] = useAtom(campaignStepAtom);
  const [isCampaignNameDialogOpen, setIsCampaignNameDialogOpen] = useAtom(isCampaignNameDialogOpenAtom);
  const [isCampaignNameDirty, setIsCampaignNameDirty] = useAtom(isCampaignNameDirtyAtom);
  const [prevCampaignName, setPrevCampaignName] = useAtom(prevCampaignNameAtom);
  const customSendoutNode = useAtomValue(customSendoutNodeAtom);
  const savedCampaignName = useAtomValue(savedCampaignNameAtom);

  const trim = (s: string) => s.replace(/  +/g, ' ').trim();
  const trimmedCampaignName = useMemo(() => trim(campaignName), [campaignName]);

  const hasInvalidChars = useMemo(() => !CAMPAIGN_NAME_VALID_CHARS_REGEXP.test(campaignName), [campaignName]);

  const { isCampaignNameTaken } = useGenerateCampaignName();
  const isCampaignNameExist = useMemo(
    () => isCampaignNameTaken(trimmedCampaignName),
    [isCampaignNameTaken, trimmedCampaignName],
  );

  useEffect(() => {
    if (campaignName === '' || campaignName === savedCampaignName) {
      setCampaignNameColor(undefined);
      setCampaignNameHint('');
    } else if (hasInvalidChars) {
      setCampaignNameColor('error');
      setCampaignNameHint('Please only use letters, numbers, spaces, dots and underscores in campaign names.');
    } else if (isNameUpdateError) {
      setCampaignNameColor('error');
      setCampaignNameHint('Please try another campaign name.');
    } else if (isCampaignNameExist) {
      setCampaignNameColor('error');
      setCampaignNameHint('This name is already taken. Please choose another one.');
    } else {
      setCampaignNameColor('success');
      setCampaignNameHint('Campaign name is available.');
    }
  }, [
    campaignName,
    hasInvalidChars,
    isCampaignNameExist,
    isNameUpdateError,
    isNameUpdateSuccess,
    savedCampaignName,
    setCampaignNameColor,
    setCampaignNameHint,
  ]);

  const isConfirmDisabled = useMemo(
    () =>
      campaignName === '' ||
      isCampaignNameExist ||
      isNameUpdateError ||
      hasInvalidChars ||
      isNameUpdateLoading ||
      trimmedCampaignName === savedCampaignName,
    [
      campaignName,
      isCampaignNameExist,
      hasInvalidChars,
      isNameUpdateError,
      isNameUpdateLoading,
      trimmedCampaignName,
      savedCampaignName,
    ],
  );

  const campaignNameDialogEnabled = useMemo(
    () => !isCampaignNameDialogOpen && campaignStep === 'build', // Add later && !isAISidebarOpen,
    [campaignStep, isCampaignNameDialogOpen],
  );

  // dialog opened via edit button (pencil icon)
  const openCampaignNameDialog = useCallback(() => {
    resetNameUpdate();
    setPrevCampaignName(campaignName);
    setCampaignName(campaignName);

    // Flicker happens with useEffect when isNameUpdateSuccess should close the dialog but only if it's already open
    // Without following delay the campaign dialog was being closed right away on a new campaign
    setTimeout(() => {
      setIsCampaignNameDialogOpen(true);
    }, 100);
  }, [campaignName, resetNameUpdate, setIsCampaignNameDialogOpen, setCampaignName, setPrevCampaignName]);

  const { sendAnalytics } = useAnalytics();
  const handleConfirm = useCallback(() => {
    setCampaignName(trimmedCampaignName);
    updateCampaignName();
    sendAnalytics('clicks confirm_for_campaign_name v3');
  }, [sendAnalytics, trimmedCampaignName, updateCampaignName, setCampaignName]);

  useEffect(() => {
    if (isNameUpdateSuccess && isCampaignNameDialogOpen) {
      setIsCampaignNameDialogOpen(false);
      resetNameUpdate();
    }
  }, [isCampaignNameDialogOpen, isNameUpdateSuccess, resetNameUpdate, setIsCampaignNameDialogOpen]);

  useEffect(() => {
    if (isNameUpdateError && !isCampaignNameDialogOpen) {
      setPrevCampaignName(campaignName);
      setIsCampaignNameDialogOpen(true);
    }
  }, [campaignName, isCampaignNameDialogOpen, isNameUpdateError, setIsCampaignNameDialogOpen, setPrevCampaignName]);

  useEffect(() => {
    if (isNameUpdateError && isCampaignNameDialogOpen && isCampaignNameDirty) {
      resetNameUpdate();
    }
  }, [isCampaignNameDialogOpen, isNameUpdateError, isCampaignNameDirty, resetNameUpdate]);

  const handleCancel = useCallback(() => {
    setIsCampaignNameDialogOpen(false);
    if (prevCampaignName === '') {
      setCampaignStep('preface');
    } else {
      resetNameUpdate();
      setCampaignName(prevCampaignName);
    }
  }, [prevCampaignName, setIsCampaignNameDialogOpen, resetNameUpdate, setCampaignName, setCampaignStep]);

  const handleLanguageChange = useCallback(
    (_event: React.BaseSyntheticEvent, value: string | null) => {
      if (!value) return;
      setCampaignLanguage(value);
    },
    [setCampaignLanguage],
  );
  const handleCampaignNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsCampaignNameDirty(event.target.value !== prevCampaignName);
      setCampaignName(event.target.value);
    },
    [prevCampaignName, setCampaignName, setIsCampaignNameDirty],
  );
  const isCampaignLanguageDisabled = useMemo(() => !customSendoutNode, [customSendoutNode]);

  return {
    campaignLanguage,
    campaignName,
    campaignNameColor,
    campaignNameDialogEnabled,
    campaignNameHint,
    handleCampaignNameChange,
    handleCancel,
    handleConfirm,
    handleLanguageChange,
    isCampaignNameDialogOpen,
    isCampaignLanguageDisabled,
    isConfirmDisabled,
    isNameUpdateLoading,
    openCampaignNameDialog,
  };
};

export const CampaignName: FC<CampaignNameDialogProps> = (props) => {
  const theme = useTheme();

  const {
    campaignLanguage,
    campaignName,
    campaignNameColor,
    campaignNameDialogEnabled,
    campaignNameHint,
    handleCampaignNameChange,
    handleCancel,
    handleConfirm,
    handleLanguageChange,
    isCampaignNameDialogOpen,
    isCampaignLanguageDisabled,
    isConfirmDisabled,
    isNameUpdateLoading,
    openCampaignNameDialog,
  } = useCampaignName(props);

  const tag = `${campaignName.length}/${CAMPAIGN_NAME_MAX_LENGTH}`;
  return (
    <Box
      sx={{
        ':hover': { path: { fill: theme.palette.primary.main } },
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
      }}
      onClick={campaignNameDialogEnabled ? openCampaignNameDialog : () => {}}
    >
      <Label variant="h6">
        {campaignName || 'Create a Campaign'} • {getLanguage(campaignLanguage) || 'English'}
      </Label>
      {campaignNameDialogEnabled && <PencilIcon />}
      <Dialog open={isCampaignNameDialogOpen} maxWidth="xl">
        <DialogTitle>Campaign name</DialogTitle>
        <DialogContent
          sx={{
            minHeight: '92px',
            width: theme.spacing(SIZE_CONTENT_L),
          }}
        >
          <TextField
            autoFocus
            fullWidth
            placeholder="Campaign name"
            value={campaignName}
            onChange={handleCampaignNameChange}
            error={campaignNameColor === 'error'}
            color={campaignNameColor}
            helperText={campaignNameHint || ' '}
            inputProps={{
              maxLength: CAMPAIGN_NAME_MAX_LENGTH,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{tag}</InputAdornment>,
            }}
            sx={{
              mt: 2.5,
              mb: 1,
              '& .MuiInputBase-input': { p: '11px 12px' },
              '& .MuiInputBase-root': {
                pr: '12px',
                borderRadius: '10px',
                '& fieldset': {
                  borderColor: campaignNameColor === 'success' ? 'green' : '',
                },
              },
              '& .MuiFormHelperText-root': { color: campaignNameColor === 'success' ? 'green' : '' },
            }}
          />
          <Autocomplete
            id="campaign-name-language"
            disabled={isCampaignLanguageDisabled}
            fullWidth
            value={campaignLanguage}
            getOptionLabel={(x) => getLanguage(x)}
            options={LanguageSource}
            onChange={handleLanguageChange}
            renderInput={(params) => <TextField {...params} placeholder="Select language" variant="outlined" />}
            sx={{ '& .MuiInputBase-root': { p: '3.5px 6px', borderRadius: '10px' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancel} disabled={isNameUpdateLoading}>
            Cancel
          </Button>
          <Button disabled={isConfirmDisabled} onClick={handleConfirm}>
            {isNameUpdateLoading && <CircularProgress size={18} />}
            {!isNameUpdateLoading && 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
