import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useCallback, useState } from 'react';
import { Box, Stack, Chip } from '@mui/material';
import { ContentCopy as ContentCopyIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Label } from '../Label';
import { WithSkeleton } from '../WithSkeleton';
import { blueGrey, green, red } from '../../theme';
import { FormattedTooltip } from '../Tooltip';
const KPIChange = ({ change, lowerIsBetter, isLoading, }) => {
    const improved = lowerIsBetter ? change < 0 : change > 0;
    const roundedChange = useMemo(() => {
        if (!change) {
            return '0.0';
        }
        return Math.round(change * 100) / 100;
    }, [change]);
    const label = `${change > 0 ? '+' : ''}${roundedChange}%`;
    if (isLoading) {
        return null;
    }
    return (_jsx(Chip, { label: label, sx: {
            height: 'unset',
            borderRadius: 1,
            px: 0.5,
            py: 0.5,
            fontSize: 12,
            background: change === 0 || Number.isNaN(change) ? blueGrey[100] : improved ? green[100] : red[100],
            color: change === 0 || Number.isNaN(change) ? blueGrey[900] : improved ? green[900] : red[900],
            '& > span': {
                p: 0,
            },
        } }));
};
export const KeyPerformanceIndicator = ({ title, data, lowerIsBetter, isLoading, tooltip, sx, changeValue, showCopyIcon = false, handleCopy, copyText, copiedText, }) => {
    const [isCopyIconVisible, setIsCopyIconVisible] = useState(false);
    const [hasBeenCopied, setHasBeenCopied] = useState(false);
    const dataCopy = `${title}, ${data.current}, ${changeValue}%`;
    const onCopy = useCallback(() => {
        if (handleCopy) {
            handleCopy(dataCopy);
        }
        setHasBeenCopied(true);
        setTimeout(() => {
            setHasBeenCopied(false);
        }, 2000);
    }, [dataCopy, handleCopy]);
    return (_jsxs(Box, { onMouseEnter: () => setIsCopyIconVisible(true), onMouseLeave: () => setIsCopyIconVisible(false), sx: [
            {
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                userSelect: 'none',
                gap: 0,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: [_jsx(WithSkeleton, { wrap: isLoading, children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Label, { variant: "h6", sx: { color: blueGrey[900], mr: 1, fontSize: '14px!important' }, children: title }), tooltip && (_jsx(FormattedTooltip, { arrow: true, placement: "top", title: tooltip, children: _jsx(InfoOutlinedIcon, { sx: { color: blueGrey[500], fontSize: '18px!important' } }) })), data.change !== undefined && (_jsx(WithSkeleton, { wrap: isLoading, children: _jsx(Box, { sx: { ml: 1 }, children: _jsx(KPIChange, { isLoading: isLoading, change: data.change, lowerIsBetter: lowerIsBetter }) }) }))] }), isCopyIconVisible && showCopyIcon && (_jsx(FormattedTooltip, { arrow: true, placement: "top", title: hasBeenCopied ? copiedText : copyText, children: _jsx(ContentCopyIcon, { onClick: onCopy, fontSize: "inherit", sx: { cursor: 'pointer', color: blueGrey[500] } }) }))] }) }), _jsxs(Stack, { direction: "row", gap: 1, alignItems: "baseline", sx: { mt: 1 }, children: [_jsx(WithSkeleton, { wrap: isLoading, children: _jsx(Label, { variant: "body2", sx: { fontSize: '16px!important', color: blueGrey[900] }, children: data.current }) }), !!data.rate && (_jsx(WithSkeleton, { wrap: isLoading, children: _jsxs(Label, { variant: "body2", sx: { color: blueGrey[700] }, children: [data.rate.toFixed(2), "%"] }) }))] })] }));
};
