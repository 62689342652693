import React, { FC, PropsWithChildren } from 'react';
import { Box, InputAdornment, Label, Switch, SxProps, TextField, Theme } from '@connectlyai-tenets/ui-styled-web';
import { NAME_MAX_LENGTH } from './constants';
import { AudienceNameProps } from './types';

const Optional: FC<PropsWithChildren> = ({ children }) => (
  <Label
    component="span"
    sx={{
      ml: 1,
      py: 0.5,
      px: 1,
      backgroundColor: 'grey.100',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.4px',
      fontWeight: 'fontWeightBold',
    }}
  >
    {children}
  </Label>
);

const BoxTitleOptional: FC<PropsWithChildren> = () => (
  <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} />
);

type OptionalProps = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

type BoxTitleProps = {
  optional?: OptionalProps;
  sx?: SxProps<Theme>;
};

const BoxTitle: FC<PropsWithChildren<BoxTitleProps>> = ({ children, optional, sx = [] }) => (
  <Label
    sx={[
      {
        display: 'inline-flex',
        alignItems: 'center',
        userSelect: 'none',
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 'fontWeightMedium',
        letterSpacing: '0.15px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
    {optional && (
      <BoxTitleOptional>
        <Optional>OPTIONAL</Optional>
        <Switch checked={optional.checked} onChange={optional.onChange} sx={{ mr: -1.5 }} />
      </BoxTitleOptional>
    )}
  </Label>
);

BoxTitle.defaultProps = {
  optional: undefined,
  sx: undefined,
};

export const BoxTitleSmall: FC<PropsWithChildren<BoxTitleProps>> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BoxTitle {...props} sx={{ fontSize: '16px', lineHeight: '18.75px' }} />
);

BoxTitleSmall.defaultProps = {
  optional: undefined,
  sx: undefined,
};

export const AudienceName: FC<AudienceNameProps> = ({ name, error, onChange }: AudienceNameProps) => {
  return (
    <Box>
      <BoxTitleSmall>Audience Name</BoxTitleSmall>
      <TextField
        autoFocus
        autoComplete="off"
        error={Boolean(error)}
        helperText={error}
        fullWidth
        placeholder="Enter audience name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={name}
        inputProps={{ maxLength: NAME_MAX_LENGTH }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{`${name.length}/${NAME_MAX_LENGTH}`}</InputAdornment>,
        }}
        onChange={onChange}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Box>
  );
};
