import React, { useMemo, useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { sofiaWebsiteScanAtom, sofiaStoreDescriptorAtom } from '@atoms/sofia';
import {
  Box,
  CalendarTodayIcon,
  Card,
  FormControl,
  Label,
  Link,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { SIZE_INPUT_XXL } from '../../../../ui-theme';

export const InputWebsite = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const url = useMemo(() => {
    return descriptor.url;
  }, [descriptor]);
  const [website, setWebsite] = useAtom(sofiaWebsiteScanAtom);
  const theme = useTheme();
  const { link, title, subtitle } = useMemo(() => {
    return {
      title: t('onboardingWebsiteScanTitle'),
      subtitle: t('onboardingWebsiteScanSubtitle'),
      link: t('onboardingWebsiteScanLink'),
    };
  }, [t]);
  const inputWebsite = useCallback(() => {
    setWebsite(url);
  }, [setWebsite, url]);
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWebsite(e.target.value);
    },
    [setWebsite],
  );
  return (
    <Card sx={{ boxShadow: 'none', px: 2, py: 5.95 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <CalendarTodayIcon />
        <Label variant="h6">{title}</Label>
        <Label variant="body1" sx={{ color: theme.palette.text.secondary }}>
          {subtitle}
        </Label>
        {website !== undefined && (
          <FormControl variant="outlined" margin="normal" sx={{ minWidth: theme.spacing(SIZE_INPUT_XXL), my: 0 }}>
            <TextField
              label="Website to Scan"
              name="website"
              variant="outlined"
              margin="normal"
              value={website}
              onChange={handleChange}
              sx={{ background: theme.palette.common.white }}
            />
          </FormControl>
        )}
        {website === undefined && (
          <Link
            underline="hover"
            variant="body2"
            sx={{ textTransform: 'uppercase', fontWeight: 600, cursor: 'pointer' }}
            onClick={inputWebsite}
          >
            {link}
          </Link>
        )}
      </Box>
    </Card>
  );
};
