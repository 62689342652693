import { UseQueryOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const QUERY_ALERTS_URL = '/v1/businesses/{businessId}/query_alerts';
type QueryAlertsURL = typeof QUERY_ALERTS_URL;

export type QueryAlertsResponse = Paths['profileV1'][QueryAlertsURL]['get']['responses']['200']['schema'];
export type QueryAlertsParametersPath = Paths['profileV1'][QueryAlertsURL]['get']['parameters']['path'];

export type QueryAlertsOptions = Omit<
  UseQueryOptions<
    QueryAlertsResponse,
    unknown,
    QueryAlertsResponse,
    readonly [target: 'queryAlerts', businessId: string]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;
