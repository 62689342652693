import React, { forwardRef, useContext } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { CAMPAIGN_LIST_ITEM_SIZE } from './constants';

// source: https://github.com/mui/material-ui/blob/v5.15.11/docs/data/material/components/autocomplete/Virtualize.tsx

export const renderRow = ({ data, index, style }: ListChildComponentProps) => {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return <Box style={inlineStyle}>{dataSet}</Box>;
};

export const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
export const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

export const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData = children as [];
    const itemCount = itemData.length;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * CAMPAIGN_LIST_ITEM_SIZE;
      }
      return itemCount * CAMPAIGN_LIST_ITEM_SIZE;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            overscanCount={50}
            itemSize={() => CAMPAIGN_LIST_ITEM_SIZE}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);
