import React, { useMemo } from 'react';
import {
  Box,
  Label,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  DotIcon,
  MenuItem,
  IconButton,
  DeleteOutlineIcon,
  Stack,
} from '@connectlyai-tenets/ui-styled-web';
import { UseTagProps, TagProps } from './types';
import { SIZE_MEDIA_XXS, SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { colors } from './colors';

const useTag = ({ id, colorHex, editState }: UseTagProps) => {
  const isEditName = useMemo(() => {
    if (!editState) {
      return false;
    }
    return id === editState?.itemId && editState.propertyKey === 'name';
  }, [id, editState]);

  const itemColor = useMemo(() => {
    return colors.find((val) => val.colorHex === colorHex);
  }, [colorHex]);

  return {
    isEditName,
    itemColor,
  };
};

export const Tag = ({
  colorsMap,
  item,
  editState,
  handleColorChange,
  handleDeleteDialogOpen,
  handleEditTagFocus,
  handleEditTagBlur,
  handleEditTagChange,
}: TagProps) => {
  const { isEditName, itemColor } = useTag({ id: item.id, colorHex: item.colorHex, editState });
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={2} width="100%">
      <Box
        sx={{
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'center',
          width: 'min-content',
          gap: SIZE_SPACING_INTER_COMPONENT,
        }}
      >
        <TextField
          sx={{
            flex: '1 0 auto',
            maxWidth: '50%',
          }}
          data-key={item.id}
          data-propertykey="name"
          label="Name"
          value={isEditName ? editState?.input : item.name}
          InputProps={{
            onFocus: handleEditTagFocus,
            onBlur: handleEditTagBlur,
          }}
          variant="outlined"
          onChange={handleEditTagChange}
          disabled={!item.mutable}
        />
        <FormControl variant="outlined" sx={{ background: 'transparent', flex: '1 1 auto' }}>
          <InputLabel id="tags-color">Color</InputLabel>
          <Select
            sx={{
              flex: '1 0 auto',
              '&:focus': {
                background: 'transparent',
              },
            }}
            label="Color"
            variant="outlined"
            displayEmpty
            value={itemColor?.id || ''}
            inputProps={{
              name: 'tags-color',
              id: 'tags-color',
            }}
            renderValue={(colorVal: string) => {
              const color = colorsMap[colorVal];
              if (!color) {
                return '';
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DotIcon
                    sx={{
                      width: theme.spacing(SIZE_MEDIA_XXS),
                      height: theme.spacing(SIZE_MEDIA_XXS),
                    }}
                    htmlColor={color.colorHex}
                  />
                  <Label
                    style={{
                      lineHeight: 'inherit',
                    }}
                    variant="body1"
                  >
                    {color.name}
                  </Label>
                </Box>
              );
            }}
            onChange={(e) => handleColorChange(e, item.id)}
            disabled={!item.mutable}
          >
            {colors.map((colorVal) => (
              <MenuItem
                sx={{
                  '&.Mui-selected': {
                    background: theme.palette.primary.light,
                  },
                  '&.Mui-selected:hover': {
                    background: theme.palette.primary.light,
                  },
                }}
                key={colorVal.id}
                value={colorVal.id}
              >
                <ListItemIcon>
                  <DotIcon
                    sx={{
                      width: theme.spacing(SIZE_MEDIA_XXS),
                      height: theme.spacing(SIZE_MEDIA_XXS),
                    }}
                    htmlColor={colorVal.colorHex}
                  />
                </ListItemIcon>
                <ListItemText primary={colorVal.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
          mx: SIZE_SPACING_INTER_COMPONENT,
        }}
      >
        <IconButton data-itemid={item.id} onClick={() => handleDeleteDialogOpen(item.id)} disabled={!item.mutable}>
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
