import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyPerformanceIndicator, KeyPerformanceIndicatorProps } from '@connectlyai-tenets/ui-styled-web';

export const KeyPerformanceIndicatorV2 = ({
  title,
  data,
  lowerIsBetter,
  isLoading,
  tooltip,
  sx,
  showCopyIcon = false,
}: Omit<KeyPerformanceIndicatorProps, 'handleCopy' | 'copyText' | 'copiedText' | 'changeValue'>) => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });

  const roundedChange = useMemo(() => {
    return Math.round(data?.change || 0 * 100) / 100;
  }, [data.change]);

  const handleCopy = useCallback((value: string) => {
    navigator.clipboard.writeText(value);
  }, []);

  return (
    <KeyPerformanceIndicator
      title={title}
      data={data}
      lowerIsBetter={lowerIsBetter}
      isLoading={isLoading}
      tooltip={tooltip}
      sx={sx}
      showCopyIcon={showCopyIcon}
      changeValue={roundedChange}
      handleCopy={handleCopy}
      copyText={t('copy')}
      copiedText={t('copied')}
    />
  );
};
