import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ChangeFlowDocumentResponse } from '../useChangeFlowDocumentMutation';
import { UseFlowSendChangeParameters } from './types';
import { FlowChange, useChangesQueue } from '../useChangesQueue';
import { setRevisionId } from '../../state/flow';

export const useFlowSendChange = ({
  businessId,
  changeFlowDocumentMutate,
  flowDocumentId,
  updateEdgeId,
  onSuccessNodeChange,
  onSuccessNodeObjectUpdate,
}: UseFlowSendChangeParameters) => {
  const dispatch = useDispatch();

  const { removeEdgeChange, removeNodeChange, removeNodeObjectUpdateChange } = useChangesQueue();
  const sendChange = useCallback(
    (sendableChange: FlowChange) => {
      if (!businessId || !flowDocumentId) {
        return;
      }
      if (sendableChange.node) {
        // send node changes to server
        changeFlowDocumentMutate(
          {
            flowDocumentId,
            businessId,
            clientRevisionId: '1',
            nodeChange: { options: JSON.stringify(sendableChange.node) },
          },
          {
            onSuccess: (receivedData: ChangeFlowDocumentResponse) => {
              dispatch(setRevisionId(receivedData.entity?.revisionId || ''));
              if (!sendableChange.node) return;
              onSuccessNodeChange(sendableChange.node, receivedData);
            },
          },
        );

        // remove node change from changesQueue
        removeNodeChange(sendableChange.node);
      } else if (sendableChange.edge) {
        // send node changes to server
        changeFlowDocumentMutate(
          {
            flowDocumentId,
            businessId,
            clientRevisionId: '1',
            edgeChange: { options: JSON.stringify(sendableChange.edge) },
          },
          {
            onSuccess: (receivedData: ChangeFlowDocumentResponse) => {
              dispatch(setRevisionId(receivedData.entity?.revisionId || ''));
              if (sendableChange.edge?.type === 'add' && receivedData.entity?.edgeChange?.options) {
                const parsed = JSON.parse(receivedData.entity?.edgeChange?.options);
                const newId = parsed?.item?.id;
                if (newId) {
                  updateEdgeId?.(sendableChange.edge?.item.id, newId);
                }
              }
            },
          },
        );

        // remove edge change from changesQueue
        removeEdgeChange(sendableChange.edge);
      } else if (sendableChange.nodeObjectUpdate) {
        // send node changes to server
        changeFlowDocumentMutate(
          {
            flowDocumentId,
            businessId: businessId as string,
            clientRevisionId: '1',
            nodeObjectUpdate: {
              node: {
                id: sendableChange.nodeObjectUpdate.id,
                options: JSON.stringify(sendableChange.nodeObjectUpdate.item),
              },
            },
          },
          {
            onSuccess: (receivedData: ChangeFlowDocumentResponse) => {
              dispatch(setRevisionId(receivedData.entity?.revisionId || ''));
              if (!sendableChange.nodeObjectUpdate) return;
              onSuccessNodeObjectUpdate(sendableChange.nodeObjectUpdate, receivedData);
            },
          },
        );

        // remove nodeObjectUpdate change from changesQueue
        removeNodeObjectUpdateChange(sendableChange.nodeObjectUpdate);
      }
    },
    [
      businessId,
      changeFlowDocumentMutate,
      dispatch,
      flowDocumentId,
      onSuccessNodeChange,
      onSuccessNodeObjectUpdate,
      removeEdgeChange,
      removeNodeChange,
      removeNodeObjectUpdateChange,
      updateEdgeId,
    ],
  );
  return { sendChange };
};
