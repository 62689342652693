import { PreNodeData } from './aiTemplates';

// This file should be deleted once V3 is fully released without feature flag

export const aiTemplatesOld = {
  webpageToProduct: [
    {
      id: '',
      position: { x: 600, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Generate a marketing message parsing information from webpage data at the end' +
            'Promote the product' +
            'Include the product name and brand in the text' +
            'Use under 150 characters.' +
            'Use very few fitting emojis.' +
            'Use a line break after every sentence.' +
            'Ask if the recipient wants to learn more.' +
            'Add a greeting at the beginning of the message followed by the text {{1}}.' +
            "Which will be subbed by recipient's name." +
            '%webpagetext%',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No',
                  },
                },
              },
              {
                header: {
                  media: {
                    type: 'TYPE_IMAGE',
                    example: ['%webpageimage%'],
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      position: { x: 1125, y: 400 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
          prompt:
            'Generate a marketing message parsing information from webpage data at the end, this is a reply message.' +
            'Include the product name in the text' +
            'Give more details.' +
            'Tell them to click the link but do not provide the link.' +
            'Use few fitting emojis.' +
            'Do not greet the recipient.' +
            '%webpagetext%',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              { button: { url: { text: 'Show me', url: '%webpage%' } } },
              {
                header: {
                  media: {
                    type: 'TYPE_IMAGE',
                    example: ['%webpageimagealt%'],
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1125, y: 950 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt:
            'Send a message to the recipient of a marketing campaign ' +
            'saying that you will reach out again for the next sale.' +
            "Don't use the exact same sentence" +
            'Use under 100 characters.',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
  ],
  promotionWithQuiz: [
    {
      id: '',
      position: { x: 600, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Generate a whatsapp marketing message for the company %Brand%.' +
            'Add a greeting at the beginning of the message followed by the text {{1}}.' +
            "Which will be subbed by recipient's name." +
            'Describe that if they answer three quiz questions correctly, they will get a discount code' +
            'Describe code can be used purchases from brand %Brand% for discount percentage %discount percentage%.' +
            'Provide first quiz question about topic %quiz topic% and it should be a yes or no question' +
            'Answer of the question must be yes, but dont tell' +
            "Pick a timeless question that you are certain of it's answer" +
            'Use under 150 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1150, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Provide second quiz question about topic %quiz topic% and it should be a yes or no question' +
            'Answer of the question must be yes, but dont tell' +
            "Pick a timeless question that you are certain of it's answer" +
            'Start by congratulating user for answering correctly, on previous question' +
            'Use under 70 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1700, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Provide third quiz question about topic %quiz topic% and it should be a yes or no question' +
            'Answer of the question must be yes, but dont tell' +
            "Pick a timeless question that you are certain of it's answer" +
            'Start by congratulating user for answering correctly two questions in row' +
            'Use under 70 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1700, y: -100 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
          prompt:
            'Start by congratulating user for answering correctly all three questions' +
            'Provide user discount code %discount code% for discount percentage %discount percentage%.' +
            'Describe that discount code can be used purchases from brand %Brand% following the link.' +
            'Tell them to click the link but do not provide the link.' +
            'Use under 150 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              { button: { url: { text: 'Click here to use code', url: '%url%' } } },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1700, y: 1000 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt:
            'Tell the recipient that answer was incorrect' +
            ' tell them for this time they can try another answer from previous message' +
            'Use under 75 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
  ] as PreNodeData[],
  productPromotion: [
    {
      id: '',
      position: { x: 500, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Generate a marketing message for the company %brand% in the vertical %industry%.' +
            'Promote the following product %product list%' +
            'Use under 150 characters.' +
            'Use very few fitting emojis.' +
            'Use a line break after every sentence.' +
            'Ask if the recipient wants to learn more.' +
            'The message is in %language%.' +
            'Add a greeting at the beginning of the message followed by the text {{1}}.' +
            "Which will be subbed by recipient's name.",

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Tell me more',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No thanks',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      position: { x: 925, y: 400 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
          prompt:
            'Generate a marketing message for brand %brand% in the vertical %industry%, this is a reply message.' +
            'Add details about the brand.' +
            'The promotion details are %Promotion Details% for the products %product list%' +
            'Tell them to click the link but do not provide the link.' +
            'Use under 250 characters.' +
            'Use few fitting emojis.' +
            'Do not greet the recipient.' +
            'The message is in %language%.',

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              { button: { url: { text: 'Show me', url: '%url%' } } },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 925, y: 950 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt:
            'Send a message to the recipient of a marketing campaign ' +
            'saying that you will reach out again for the next sale.' +
            "Don't use the exact same sentence" +
            'Use under 100 characters.' +
            'The language is %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
  ] as PreNodeData[],
  salesEvent: [
    {
      id: '',
      position: { x: 500, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Generate a marketing message for the company %brand% in the vertical %industry%.' +
            'Promote the sales event %sales event%' +
            'Use under 150 characters.' +
            'Use very few fitting emojis.' +
            'Refrain any discount percentage.' +
            'Ask if the recipient wants to learn more.' +
            'The message is in %language%.' +
            'Add a greeting at the beginning of the message followed by the text {{1}}.' +
            "Which will be subbed by recipient's name.",

          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Tell me more',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No thanks',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      position: { x: 925, y: 400 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
          prompt:
            'Generate a marketing message for brand %brand% in the vertical %industry%, this is a reply message.' +
            'The promotion details are %Promotion Details%' +
            'Tell them to click the link but do not provide the link.' +
            'Use under 150 characters.' +
            'Use few fitting emojis.' +
            'Do not greet the recipient.' +
            'The message is in %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              { button: { url: { text: 'Show me', url: '%url%' } } },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 925, y: 1050 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt:
            'Generate a message and tell the customer sorry to bother you ' +
            'and that they can always check the sales event later if they are interested.' +
            'Use under 80 characters and add maximum two fitting emojis.' +
            'End the message with the company name %brand%.' +
            'The language is %language%',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 1325, y: 700 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
          prompt:
            'Generate a marketing message for brand %brand% in the vertical %industry%, this is a reply message.' +
            'Add details about the brand.' +
            'The promotion details are %Promotion Details%' +
            'Tell them to click the link but do not provide the link.' +
            'Use between 250-300 characters.' +
            'Use few fitting emojis.' +
            'Do not greet the recipient.' +
            'The message is in %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              { button: { url: { text: 'Show me', url: '%url%' } } },
            ],
          },
        },
      },
    },
  ] as PreNodeData[],
  welcomeSeries: [
    {
      id: '',
      position: { x: 500, y: 500 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
          prompt:
            'Generate a whatsapp marketing opt-in message for the company %Brand%.' +
            'Describe how this is the official company channel and will be used for exclusive deals and promotions.' +
            'Add a greeting at the beginning of the message followed by the text {{1}}.' +
            "Which will be subbed by recipient's name." +
            'Use under 100 characters.' +
            'Use very few fitting emojis.' +
            'The message is in %language%.',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'Yes',
                  },
                },
              },
              {
                button: {
                  quickReply: {
                    text: 'No',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      position: { x: 925, y: 400 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt:
            'Create a message thanking the user for subscribing' +
            ' and tell them they will receive their first discount soon.' +
            "Don't use the exact same sentence" +
            'Use 50 characters.' +
            'Use one fitting emoji.' +
            'The language is %language%',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
    {
      id: '',
      position: { x: 925, y: 750 },
      data: {
        v1: {
          waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
          prompt: 'Say thank you for letting us know. The language is %language%',
          messageTemplateInput: {
            templateComponents: [
              {
                body: {
                  text: {
                    text: 'Generating...',
                  },
                },
              },
            ],
          },
        },
      },
    },
  ] as PreNodeData[],
};
