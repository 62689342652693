import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import produce from 'immer';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { useQueryClient } from '@tanstack/react-query';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';
import { selectUnsentMessageTemplatesIndex, setMessageTemplateStatusUpdate } from '../../state/messageTemplates';
import { NodeObjectUpdate } from '../useChangesQueue';
import { selectNodes } from '../../state/flow';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';

export const useCreateMessageTemplateResultData = () => {
  const { dtoUpdateAccessorProvider: provider } = useContext(FlowDIContext) as FlowDIContextProps;
  const dispatch = useDispatch();
  const nodes = useSelector(selectNodes);
  const templatesIndex = useSelector(selectUnsentMessageTemplatesIndex);
  const queryClient = useQueryClient();

  const { onNodeObjectChange } = useCampaignFlowChangeAppliers();

  useEffect(() => {
    const subscription = provider()
      .subscriptionDtoUpdate()
      .pipe(
        filter((x) => x.resultOneof?.$case === 'messageTemplateStatusUpdate'),
        map((x) => {
          if (x.resultOneof?.$case !== 'messageTemplateStatusUpdate') {
            return unreachable();
          }
          return x?.resultOneof?.messageTemplateStatusUpdate;
        }),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        dispatch(setMessageTemplateStatusUpdate(x));
        queryClient.invalidateQueries(['messageTemplateGroups']);

        const nodeId = Object.entries(templatesIndex).find(([_, template]) => template?.id === x.templateId)?.[0];
        if (!nodeId) {
          return;
        }

        const actualNode = nodes.find((n) => n.id === nodeId);
        if (!actualNode) {
          return;
        }

        const updatedNode = produce(actualNode, (draft) => {
          switch (actualNode.type) {
            case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
              draft.data.v1.waMessageTemplateId = x.templateId;
              break;
            }
            case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
              draft.data.v1.templateId = x.templateId;
              break;
            }
            default: {
              break;
            }
          }
        });

        const update: NodeObjectUpdate = {
          id: updatedNode.id,
          item: updatedNode,
        };

        onNodeObjectChange([update]);
      });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [dispatch, nodes, onNodeObjectChange, provider, templatesIndex, queryClient]);
};
