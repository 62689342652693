import { InboxConfigV1 } from "../models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.inbox.dto";
function createBaseInboxConfigDto() {
    return { configV1: undefined };
}
export const InboxConfigDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.configV1 !== undefined) {
            InboxConfigV1.encode(message.configV1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInboxConfigDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configV1 = InboxConfigV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configV1: isSet(object.configV1)
                ? InboxConfigV1.fromJSON(object.configV1)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.configV1 !== undefined &&
            (obj.configV1 = message.configV1
                ? InboxConfigV1.toJSON(message.configV1)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
