import React from 'react';
import { AddCircleOutlineRoundedIcon, Button, Label } from '@connectlyai-tenets/ui-styled-web';
import { AddVariableProps } from './types';

export const AddVariable = React.forwardRef<HTMLButtonElement, AddVariableProps>((props, ref) => (
  <Button sx={{ py: 0.5, px: 1, textTransform: 'none' }} color="primary" variant="text" ref={ref} {...props}>
    <AddCircleOutlineRoundedIcon sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }} />
    <Label sx={{ ml: 0.5, textTransform: 'none' }}>{props.text || 'Variable'}</Label>
  </Button>
));

AddVariable.displayName = 'AddVariable';
