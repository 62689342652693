import { useCallback, useContext } from 'react';
import { MutateOptions } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useContextSelector } from 'use-context-selector';
import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { BusinessChannel, MutationUpdateWATokenResponse, MutationUpdateWATokenParameters } from './types';
import { NetworkContext } from '../../contexts/network';
import { LoggerContext } from '../../contexts';
import { dataAtom, errorAtom, loadingAtom } from './atoms';

// Uses a direct call even though it partially implement the tanstack api ergonomics.
// https://tanstack.com/query/v4/docs/react/reference/useMutation
export const useMutationUpdateWAToken = () => {
  const networkClientProvider = useContextSelector(NetworkContext, (context) => context.networkClientProvider);
  const networkClient = networkClientProvider();
  const { logger } = useContext(LoggerContext);
  const [data, setData] = useAtom(dataAtom);
  const [error, setError] = useAtom(errorAtom);
  const [isLoading, setIsLoading] = useAtom(loadingAtom);

  const mutate = useCallback(
    (
      params: MutationUpdateWATokenParameters,
      options?: MutateOptions<MutationUpdateWATokenResponse, ConnectlyError, MutationUpdateWATokenParameters>,
    ) => {
      logger.debug('updateWAToken@start', () => ({}));
      const { businessId, accessToken, userId, dataAccessExpirationTime, expiresIn, businessServiceProvider } = params;

      const requestConfig = {
        timeout: 60000, // set a timeout
      };
      setIsLoading(true);
      // eslint-disable-next-line max-len
      let url = `/v1/webhooks/whatsapp/signup?business_id=${businessId}&business_service_provider=${businessServiceProvider}&access_token=${accessToken}&user_id=${userId}&data_access_expiration_time=${dataAccessExpirationTime}&expires_in=${expiresIn}`;
      if (params.useBrazilCreditLine) {
        url += `&override_credit_line=${process.env.REACT_APP_INBOX_WA_BRAZIL_CREDIT_LINE}`;
      }
      networkClient
        .get(url, requestConfig)
        .then((response) => {
          logger.debug('updateWAToken@success', () => ({
            status: response.statusText,
            data: response.data,
          }));
          const respData = {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            channels: response.data.channels.map((x: any) => BusinessChannel.fromJSON(x)),
          };
          setData(respData);
          setError(undefined);
          if (options?.onSuccess) {
            options.onSuccess(respData, params, {});
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          logger.error('updateWAToken@failure', () => ({
            error: err,
          }));
          const cnctError = err?.response?.data as ConnectlyError;
          setData(undefined);
          setError(cnctError);
          if (options?.onError) {
            options.onError(cnctError, params, {});
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [logger, networkClient, setData, setError, setIsLoading],
  );
  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
