import React, { FC, useMemo } from 'react';
import { Box, Label, PlayCircleOutlineIcon, alpha } from '@connectlyai-tenets/ui-styled-web';
import { CardHeader } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';
import { FlowChartMessageNodeButton } from '@components/FlowChartMessageNodeButton';
import { TemplateComponent } from '@hooks/useMessageTemplateGroupsData/types';
import { CodeMirrorTextField } from '@components/CodeMirrorTextField';

export const CarouselItem: FC<{
  header?: CardHeader;
  message?: string;
  buttons: TemplateComponent[];
  id: string;
  cardIndex: number;
  onClick: (index: number) => void;
  active: boolean;
}> = ({ message, buttons, id, header, onClick, cardIndex, active }) => {
  const previewStyles = useMemo(() => {
    if (header?.media?.type === 'TYPE_IMAGE' && Array.isArray(header?.media?.example)) {
      return {
        backgroundImage: `url(${header.media.example[0]})`,
        backgroundSize: 'cover',
      };
    }
    if (header?.media?.type === 'TYPE_VIDEO' && Array.isArray(header?.media.example)) {
      return {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: 'grey.300',
        backgroundColor: 'common.white',
      };
    }
    return {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'grey.300',
      backgroundColor: 'grey.100',
    };
  }, [header]);

  const handleClick = () => {
    onClick?.(cardIndex);
  };

  return (
    <Box
      sx={{
        filter: 'drop-shadow(0px 1px 0.5px rgba(0, 0, 0, 0.20))',
        display: 'flex',
        flexGrow: 1,
        width: 204,
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.common.white,
          borderRadius: '6px',
          p: 1.5,
          boxSizing: 'border-box',
          display: 'flex',
          gap: 1.5,
          flexDirection: 'column',
          borderColor: (theme) => (active ? theme.palette.primary.main : 'transparent'),
          borderWidth: 2,
          borderStyle: 'solid',
        }}
      >
        <Box
          sx={{
            borderRadius: 1,
            position: 'relative',
            textAlign: 'center',
            width: 176,
            height: 120,
            ...previewStyles,
            '&:hover > div': {
              opacity: 1,
            },
          }}
        >
          {header?.media?.type === 'TYPE_VIDEO' && (
            <PlayCircleOutlineIcon
              sx={{
                width: 40,
                height: 40,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            />
          )}
          {header?.variable && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  background: (theme) => theme.palette.common.black,
                  color: (theme) => theme.palette.common.white,
                  display: 'flex',
                  paddingX: 1,
                  paddingY: 0.5,
                  borderRadius: 2,
                }}
              >
                {header?.variable}
              </Box>
            </Box>
          )}
        </Box>
        <CodeMirrorTextField editable={false} value={message || 'Add a message'} multiline displayFormatting />
        {buttons?.map((button, i) => (
          <FlowChartMessageNodeButton
            button={button}
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-button-${i}`}
            handleType={{
              nodeId: id,
              nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE',
              actionType: 'button-click',
              buttonIndex: i,
              cardIndex,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
