import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.metrics.dto";
export var UnitFamily;
(function (UnitFamily) {
    UnitFamily[UnitFamily["UNIT_FAMILY_UNSPECIFIED"] = 0] = "UNIT_FAMILY_UNSPECIFIED";
    UnitFamily[UnitFamily["UNIT_FAMILY_GENERAL"] = 1] = "UNIT_FAMILY_GENERAL";
    UnitFamily[UnitFamily["UNIT_FAMILY_CURRENCY"] = 2] = "UNIT_FAMILY_CURRENCY";
    UnitFamily[UnitFamily["UNIT_FAMILY_PERCENT"] = 3] = "UNIT_FAMILY_PERCENT";
    UnitFamily[UnitFamily["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(UnitFamily || (UnitFamily = {}));
export function unitFamilyFromJSON(object) {
    switch (object) {
        case 0:
        case "UNIT_FAMILY_UNSPECIFIED":
            return UnitFamily.UNIT_FAMILY_UNSPECIFIED;
        case 1:
        case "UNIT_FAMILY_GENERAL":
            return UnitFamily.UNIT_FAMILY_GENERAL;
        case 2:
        case "UNIT_FAMILY_CURRENCY":
            return UnitFamily.UNIT_FAMILY_CURRENCY;
        case 3:
        case "UNIT_FAMILY_PERCENT":
            return UnitFamily.UNIT_FAMILY_PERCENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return UnitFamily.UNRECOGNIZED;
    }
}
export function unitFamilyToJSON(object) {
    switch (object) {
        case UnitFamily.UNIT_FAMILY_UNSPECIFIED:
            return "UNIT_FAMILY_UNSPECIFIED";
        case UnitFamily.UNIT_FAMILY_GENERAL:
            return "UNIT_FAMILY_GENERAL";
        case UnitFamily.UNIT_FAMILY_CURRENCY:
            return "UNIT_FAMILY_CURRENCY";
        case UnitFamily.UNIT_FAMILY_PERCENT:
            return "UNIT_FAMILY_PERCENT";
        case UnitFamily.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseTimeSeriesAttributes() {
    return { interval: undefined, values: undefined, unit: undefined };
}
export const TimeSeriesAttributes = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.interval !== undefined) {
            TimeSeriesInterval.encode(message.interval, writer.uint32(10).fork()).ldelim();
        }
        if (message.values !== undefined) {
            TimeSeriesValues.encode(message.values, writer.uint32(18).fork()).ldelim();
        }
        if (message.unit !== undefined) {
            Unit.encode(message.unit, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTimeSeriesAttributes();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.interval = TimeSeriesInterval.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.values = TimeSeriesValues.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.unit = Unit.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            interval: isSet(object.interval)
                ? TimeSeriesInterval.fromJSON(object.interval)
                : undefined,
            values: isSet(object.values)
                ? TimeSeriesValues.fromJSON(object.values)
                : undefined,
            unit: isSet(object.unit) ? Unit.fromJSON(object.unit) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.interval !== undefined &&
            (obj.interval = message.interval
                ? TimeSeriesInterval.toJSON(message.interval)
                : undefined);
        message.values !== undefined &&
            (obj.values = message.values
                ? TimeSeriesValues.toJSON(message.values)
                : undefined);
        message.unit !== undefined &&
            (obj.unit = message.unit ? Unit.toJSON(message.unit) : undefined);
        return obj;
    },
};
function createBaseTimeSeriesInterval() {
    return { startTime: 0, milliseconds: 0 };
}
export const TimeSeriesInterval = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.startTime !== 0) {
            writer.uint32(8).int32(message.startTime);
        }
        if (message.milliseconds !== 0) {
            writer.uint32(16).int32(message.milliseconds);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTimeSeriesInterval();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.startTime = reader.int32();
                    break;
                case 2:
                    message.milliseconds = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            startTime: isSet(object.startTime) ? Number(object.startTime) : 0,
            milliseconds: isSet(object.milliseconds)
                ? Number(object.milliseconds)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.startTime !== undefined &&
            (obj.startTime = Math.round(message.startTime));
        message.milliseconds !== undefined &&
            (obj.milliseconds = Math.round(message.milliseconds));
        return obj;
    },
};
function createBaseTimeSeriesValues() {
    return { times: [], values: [] };
}
export const TimeSeriesValues = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.times) {
            writer.int32(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.values) {
            writer.double(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTimeSeriesValues();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.times.push(reader.int32());
                        }
                    }
                    else {
                        message.times.push(reader.int32());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.values.push(reader.double());
                        }
                    }
                    else {
                        message.values.push(reader.double());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            times: Array.isArray(object === null || object === void 0 ? void 0 : object.times)
                ? object.times.map((e) => Number(e))
                : [],
            values: Array.isArray(object === null || object === void 0 ? void 0 : object.values)
                ? object.values.map((e) => Number(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.times) {
            obj.times = message.times.map((e) => Math.round(e));
        }
        else {
            obj.times = [];
        }
        if (message.values) {
            obj.values = message.values.map((e) => e);
        }
        else {
            obj.values = [];
        }
        return obj;
    },
};
function createBaseUnit() {
    return { family: 0, name: "", shortName: "", plural: "", symbol: "" };
}
export const Unit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.family !== 0) {
            writer.uint32(8).int32(message.family);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.shortName !== "") {
            writer.uint32(26).string(message.shortName);
        }
        if (message.plural !== "") {
            writer.uint32(34).string(message.plural);
        }
        if (message.symbol !== "") {
            writer.uint32(42).string(message.symbol);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUnit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.family = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.shortName = reader.string();
                    break;
                case 4:
                    message.plural = reader.string();
                    break;
                case 5:
                    message.symbol = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            family: isSet(object.family) ? unitFamilyFromJSON(object.family) : 0,
            name: isSet(object.name) ? String(object.name) : "",
            shortName: isSet(object.shortName) ? String(object.shortName) : "",
            plural: isSet(object.plural) ? String(object.plural) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.family !== undefined &&
            (obj.family = unitFamilyToJSON(message.family));
        message.name !== undefined && (obj.name = message.name);
        message.shortName !== undefined && (obj.shortName = message.shortName);
        message.plural !== undefined && (obj.plural = message.plural);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        return obj;
    },
};
function createBaseScalarAttributes() {
    return { unit: undefined, value: 0 };
}
export const ScalarAttributes = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.unit !== undefined) {
            Unit.encode(message.unit, writer.uint32(10).fork()).ldelim();
        }
        if (message.value !== 0) {
            writer.uint32(17).double(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScalarAttributes();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.unit = Unit.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.value = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            unit: isSet(object.unit) ? Unit.fromJSON(object.unit) : undefined,
            value: isSet(object.value) ? Number(object.value) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.unit !== undefined &&
            (obj.unit = message.unit ? Unit.toJSON(message.unit) : undefined);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
