import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { RoomStatus } from '@connectlyai-idl/models/dist/models/common/models';
import { resetStore, setRoom } from './action';
const initialState = {
    loadingState: LoadingState.UNINITIALIZED,
    room: {
        dto: undefined,
        id: '',
        assignedAgent: undefined,
        description: {
            id: '',
            customerId: '',
            subject: '',
            avatarUrl: '',
            lastChannel: undefined,
        },
        defaultBusinessChannel: undefined,
        defaultCustomerChannel: undefined,
        thread: {
            messages: [],
        },
        status: RoomStatus.ROOM_STATUS_UNSPECIFIED,
        tags: [],
        isMediaViewerOpen: false,
        unreadCount: 0,
    },
};
export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        startRoomMonitor: (state, _action) => {
            state.loadingState = LoadingState.FULL_LOADING;
        },
        reset: () => initialState,
        toggleMediaViewer: (state) => {
            state.room.isMediaViewerOpen = !state.room.isMediaViewerOpen;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setRoom, (state, action) => {
            state.room = action.payload;
            state.loadingState = LoadingState.DATA;
        });
    },
});
export const { reset, startRoomMonitor, toggleMediaViewer } = roomSlice.actions;
export const selectRoomDto = (state) => state.roomSlice.room.dto;
export const selectMessages = (state) => state.roomSlice.room.thread.messages;
export const selectAssignedAgent = (state) => (state.roomSlice.room.assignedAgent);
export const selectRoomStatus = (state) => (state.roomSlice.room.status);
export const selectDescription = (state) => state.roomSlice.room.description;
export const selectLoadingState = (state) => state.roomSlice.loadingState;
export const selectRoomId = (state) => state.roomSlice.room.id;
export const selectRoomTags = (state) => state.roomSlice.room.tags;
export default roomSlice.reducer;
