import { type UseMutationOptions } from '@tanstack/react-query';
import { type ConnectlyError, type Paths } from '@connectlyai-tenets/sdk';

interface BusinessIdParam {
  businessId: string;
}

export const GENERATE_CLIENT_KEY = '/internal/v1/businesses/{input.businessId}/ai/client_key/generate/v1';
type GenerateClientKey = typeof GENERATE_CLIENT_KEY;
type GenerateClientKeyResponse = Paths['aiV1'][GenerateClientKey]['post']['responses']['200']['schema'];
type GenerateClientKeyInput = Paths['aiV1'][GenerateClientKey]['post']['parameters']['body']['input'];

export type GenerateClientKeyParams = BusinessIdParam & GenerateClientKeyInput;
export type GenerateClientKeyOptions = UseMutationOptions<
  GenerateClientKeyResponse,
  ConnectlyError,
  GenerateClientKeyParams,
  unknown
>;
