import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { ChangeFlowDocumentOptions, ChangeFlowDocumentMutation, ChangeFlowDocumentResponse } from './types';

export const useChangeFlowDocumentMutation = (options?: ChangeFlowDocumentOptions) => {
  return useMutation(
    (variables: ChangeFlowDocumentMutation) =>
      api.flowV1('/internal/v1/businesses/{input.businessId}/change_react_flow_document/flow_documents', 'post', {
        path: { 'input.businessId': variables.businessId },
        body: {
          input: {
            ...variables,
          },
        },
      }) as Promise<ChangeFlowDocumentResponse>,
    {
      ...options,
    },
  );
};
