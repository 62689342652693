import { RefObject, useCallback, useEffect, useState } from 'react';
import { DEF_SIDEBAR_WIDTH_PERC, MAX_SIDEBAR_WIDTH_PERC, MIN_SIDEBAR_WIDTH_PERC } from './constants';

export const useSidebarResizer = (sidebarRef: RefObject<HTMLDivElement>) => {
  const [resizeToggle, setResizeToggle] = useState(false);
  const [customWidth, setCustomWidth] = useState(window.innerWidth * DEF_SIDEBAR_WIDTH_PERC);

  // called when user clicks on divider
  const resizeStart = useCallback(() => {
    setResizeToggle(true);
    document.body.classList.add('resizing-cursor');
  }, []);

  const resizeStop = useCallback(() => {
    setResizeToggle(false);
    document.body.classList.remove('resizing-cursor');
  }, []);

  const resizeStep = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (resizeToggle && sidebarRef.current) {
        const windowWidth = window.innerWidth;

        // this is the target size but it will be clamped by boundaries
        const targetSize = mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left;
        const clampedSize = Math.max(
          Math.min(targetSize, windowWidth * MAX_SIDEBAR_WIDTH_PERC),
          windowWidth * MIN_SIDEBAR_WIDTH_PERC,
        );
        setCustomWidth(clampedSize);
      }
    },
    [resizeToggle, sidebarRef],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resizeStep);
    window.addEventListener('mouseup', resizeStop);
    return () => {
      window.removeEventListener('mousemove', resizeStep);
      window.removeEventListener('mouseup', resizeStop);
    };
  }, [resizeToggle, resizeStep, resizeStop]);

  // set to default width when users resizes whole screen
  const resetToDefaultWidth = useCallback(() => {
    const windowWidth = window.innerWidth;
    setCustomWidth(windowWidth * DEF_SIDEBAR_WIDTH_PERC);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resetToDefaultWidth);
    return () => {
      window.removeEventListener('resize', resetToDefaultWidth);
    };
  }, [resetToDefaultWidth]);

  return { resizeStart, customWidth };
};
