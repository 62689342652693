// unreduxified, has only messageTemplates redux

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { flatten } from 'lodash';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  NodeChange,
  EdgeChange,
  EdgeAddChange,
  Node,
  applyEdgeChanges,
  applyNodeChanges,
  NodeRemoveChange,
  NodeSelectionChange,
  EdgeRemoveChange,
} from 'react-flow-renderer';
import { createHandleId, HandleType } from '@sdks/flow/createHandleId';
import { edgesAtom, nodesAtom, selectedNodeAtom } from '@atoms/flow';
import { useGetAtom } from '@hooks/useGetAtom';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useFlowVariables } from '@hooks/useFlowVariables';
import { TemplateComponent } from '@hooks/useMessageTemplateGroupsData';
import { NodeObjectUpdate, useChangesQueue } from '../useChangesQueue';
import { FlowChangeAppliers, FlowChangeAppliersParams } from './types';
import { cancel } from '../../../../state/actions';

// filter out node's unnecessary properties before adding them to changeQueue for backend
const filterOutNodeProperties = (node: Node): Node => {
  return produce(node, (draft) => {
    delete draft.data?.v1?.isGenerating;
    delete draft.dragging;
    delete draft.height;
    delete draft.positionAbsolute;
    delete draft.selected;
    delete draft.width;
  });
};

export const useNodeObjectChange = () => {
  const { addNodeObjectUpdatesToQueue } = useChangesQueue({});

  const setNodes = useSetAtom(nodesAtom);

  const onNodeObjectChange = useCallback(
    (updates: NodeObjectUpdate[]) => {
      // filter out unnecessary properties of nodes before adding them to changeQueue for backend
      const changesToSend = produce(updates, (draft) => {
        draft.forEach((update) => {
          update.item = filterOutNodeProperties(update.item);
        });
        return draft;
      });

      addNodeObjectUpdatesToQueue(changesToSend);

      setNodes((nodes) =>
        produce(nodes, (draft) =>
          draft.map((node) => {
            const update = updates.find((c) => c.id === node.id);
            if (update) {
              return { ...node, ...update.item };
            }
            return node;
          }),
        ),
      );
    },
    [addNodeObjectUpdatesToQueue, setNodes],
  );

  return {
    onNodeObjectChange,
  };
};

export const useFlowChangeAppliers = ({
  onNodesChangeRequested,
  selectIsDocumentEditable,
}: FlowChangeAppliersParams): FlowChangeAppliers => {
  const isDocumentEditable = useSelector(selectIsDocumentEditable);

  const [nodes, setNodes] = useAtom(nodesAtom);
  const [edges, setEdges] = useAtom(edgesAtom);
  const getNodes = useGetAtom(nodesAtom);
  const getEdges = useGetAtom(edgesAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);

  const dispatch = useDispatch();

  const { addEdgeChangesToQueue, addNodeChangesToQueue } = useChangesQueue({});
  const { onNodeObjectChange } = useNodeObjectChange();

  const edgeBySourceHandleId = useCallback(
    (handleId: string) => {
      return edges.find((edge) => edge.sourceHandle === handleId);
    },
    [edges],
  );

  const isHandleConnected = useCallback(
    (handleType: HandleType) => {
      const handleId = createHandleId(handleType);
      return edgeBySourceHandleId(handleId) !== undefined;
    },
    [edgeBySourceHandleId],
  );

  const shouldEdgesBeOverriden = useCallback(
    (change: EdgeAddChange) => {
      const edgesCurrent = getEdges();
      const nodesCurrent = getNodes();
      const nodesById = nodesCurrent.reduce<Record<string, Node<unknown>>>((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});
      const edgesStartingAtHandle = edgesCurrent.filter((edge) => edge.sourceHandle === change.item.sourceHandle);
      const changeTargetNode = nodesById[change.item.target];
      return edgesStartingAtHandle.filter((edge) => {
        // edge.sourceNode is undefined so fetch it manually
        const sourceNode = nodesById[edge.source];
        if (!sourceNode) {
          return true;
        }
        const targetNode = nodesById[edge.target];
        if (!targetNode) {
          return true;
        }
        const afterExecuteHandleId = createHandleId({ nodeId: sourceNode?.id || '', actionType: 'after-execute' });
        if (
          sourceNode?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE' &&
          edge.sourceHandle === afterExecuteHandleId &&
          changeTargetNode.type !== targetNode.type
        ) {
          return false;
        }
        return true;
      });
    },
    [getNodes, getEdges],
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      // Overwrite edges
      // when one source handle is reconnected, remove old edge
      const edgesToBeOverwritten = flatten(
        changes.map((change) => {
          if (change.type !== 'add') {
            return [];
          }
          if (!change.item.sourceHandle) {
            return [];
          }
          const edgesToBeDeleted = shouldEdgesBeOverriden(change);
          return edgesToBeDeleted.map((edge) => ({ id: edge.id, type: 'remove' }) as EdgeRemoveChange);
        }),
      );
      const changesToBeApplied = [...changes, ...edgesToBeOverwritten];

      addEdgeChangesToQueue(changesToBeApplied);
      setEdges((oldEdges) => applyEdgeChanges(changesToBeApplied, oldEdges));
    },
    [addEdgeChangesToQueue, setEdges, shouldEdgesBeOverriden],
  );

  const { cleanupVariables } = useFlowVariables();
  const { ffEnableCleanupVariables } = useFeatureFlag(['ffEnableCleanupVariables']);
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      // if node is going to be deleted delete edges connected to it
      const nodesToRemove = changes.filter((change) => change.type === 'remove') as NodeRemoveChange[];
      const idsToRemove = nodesToRemove.map((change) => change.id);
      const edgesCurrent = getEdges();
      const edgesToRemove = edgesCurrent.filter(
        (edge) => idsToRemove.includes(edge.source) || idsToRemove.includes(edge.target),
      );
      onEdgesChange(edgesToRemove.map((edge) => ({ type: 'remove', id: edge.id })));

      changes = onNodesChangeRequested(changes);

      // use react flow's internal function to apply changes to nodes
      setNodes((oldNodes) => applyNodeChanges(changes, oldNodes));

      // cleanup variables when nodes are removed
      if (ffEnableCleanupVariables && idsToRemove.length) {
        cleanupVariables();
      }

      // filter out unnecessary properties of nodes before adding them to changeQueue for backend
      const changesToSend = produce(changes, (draft) => {
        draft.forEach((change) => {
          if (change.type === 'add') {
            change.item = filterOutNodeProperties(change.item);
          }
        });
        return draft;
      });
      // add any changes to changesQueue, changes queue will filter out according to changes types
      addNodeChangesToQueue(changesToSend);
    },
    [
      getEdges,
      onEdgesChange,
      onNodesChangeRequested,
      setNodes,
      ffEnableCleanupVariables,
      addNodeChangesToQueue,
      cleanupVariables,
    ],
  );

  const selectNode = useCallback(
    (id: string) => {
      const selectionChanges: NodeSelectionChange[] = nodes.map((node) => ({
        id: node.id,
        type: 'select',
        selected: node.id === id,
      }));
      onNodesChange(selectionChanges);
    },
    [nodes, onNodesChange],
  );

  const resetSelection = useCallback(() => {
    const deselectChange: NodeSelectionChange[] = nodes.map((node) => ({
      id: node.id,
      type: 'select',
      selected: false,
    }));
    onNodesChange(deselectChange);
  }, [nodes, onNodesChange]);

  const setNodeAsGenerating = useCallback(
    (nodeId: string, isGenerating: boolean, aiMessage: string) => {
      if (nodeId === selectedNode?.id) {
        dispatch(cancel());
      }

      const node = nodes.find((n) => n.id === nodeId);
      if (!node || !node?.data?.v1?.messageTemplateInput?.templateComponents) return;

      onNodeObjectChange([
        {
          id: nodeId,
          item: produce(node, (draft: Node) => {
            draft.data.v1.isGenerating = isGenerating;
            draft.data.v1.templateId = '';
            draft.data.v1.messageTemplateInput.templateComponents = [
              ...(draft.data.v1.messageTemplateInput.templateComponents.filter((x: TemplateComponent) => !x.body) ||
                []),
              { body: { text: { text: aiMessage } } },
            ];
          }),
        },
      ]);
    },
    [dispatch, nodes, onNodeObjectChange, selectedNode?.id],
  );

  // to prevent changing document when document is not editable
  // use protected functions of onEdgesChange and onNodesChange
  const onEdgesChangeProtected = useMemo(
    () => (isDocumentEditable ? onEdgesChange : () => {}),
    [isDocumentEditable, onEdgesChange],
  );
  const onNodesChangeProtected = useMemo(
    () => (isDocumentEditable ? onNodesChange : () => {}),
    [isDocumentEditable, onNodesChange],
  );

  const createNodeAtHandle = useCallback(() => {}, []);

  return {
    onNodeObjectChange,
    onNodesChange,
    onNodesChangeProtected,
    onEdgesChange,
    onEdgesChangeProtected,
    selectNode,
    resetSelection,
    createNodeAtHandle,
    isHandleConnected,
    setNodeAsGenerating,
  };
};
