import { useContext, useState } from 'react';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';

import {
  BusinessChannelMutation,
  BusinessChannelResponse,
  selectBusinessId,
  useBusinessChannelMutation,
  useMeData,
  useSettingsData,
} from '@hooks';

import { useTranslation } from 'react-i18next';
import { padStart } from 'lodash';
import { track } from 'src/utils';

import { INSTRUCTIONS_URL, VIDEO_URL } from './constants';
import { vtexChannelSelector } from './selectors';
import { NotificationContext } from '../../../../contexts';

function hideToken(token: string, numberOfChars = 6): string {
  const lastChars = token.substring(token.length - numberOfChars);
  return padStart('', Math.min(token.length - numberOfChars, 50), '*') + lastChars;
}

export function useVtexTokenForm() {
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [accessTokenDisplay, setAccessTokenDisplay] = useState('');
  const [apiKeyDisplay, setApiKeyDisplay] = useState('');
  const [accessTokenError, setAccessTokenError] = useState('');
  const [apiKeyError, setApiKeyError] = useState('');
  const [apiKeyDisabled, setApiKeyDisabled] = useState(false);
  const [accessTokenDisabled, setAccessTokenDisabled] = useState(false);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.vtexSettings' });

  const handleSetData = (channel: BusinessChannelResponse['entity']) => {
    const storedApiKey = channel?.remoteProfile?.vtexProfile?.apiKey;
    const storedAccessToken = channel?.accessToken;

    if (storedApiKey) {
      setApiKeyDisplay(hideToken(storedApiKey));
      setApiKey(storedApiKey);
      setApiKeyDisabled(true);
    }
    if (storedAccessToken) {
      setAccessTokenDisplay(hideToken(storedAccessToken));
      setAccessToken(storedAccessToken);
      setAccessTokenDisabled(true);
    }
  };

  const { data: vtexChannel, isFetching: loadingSettingsData } = useSettingsData({
    businessId: businessId || '',
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: vtexChannelSelector,
    onSuccess(data) {
      handleSetData(data);
    },
  });

  const { mutateAsync: updateBusinessChannel } = useBusinessChannelMutation({
    onSuccess(data) {
      handleSetData(data.entity);

      track('settings.vtex_token.update', {
        businessId,
        accessToken: hideToken(accessToken, 10),
        apiKey: hideToken(apiKey, 10),
      });
    },
  });

  const handleSaveToken = (newAccessToken: string, newApiKey: string) => {
    if (!businessId) throw new Error('Business Id not found');
    let errors = false;

    setAccessTokenError('');
    setApiKeyError('');

    if (accessToken || apiKey) {
      if (!accessToken) {
        errors = true;
        setAccessTokenError('Access Token is mandatory');
      }
      if (!apiKey) {
        errors = true;
        setApiKeyError('API Key is mandatory');
      }
    }

    if (errors) throw new Error('Some fields are not filled');

    const payload: BusinessChannelMutation = {
      businessId,
      accessToken: newAccessToken,
      remoteProfile: { vtexProfile: { apiKey: newApiKey } },
      channel: { byTypeAndExternalId: { channelType: 'CHANNEL_TYPE_VTEX' } },
      query: { overrideRemoteProfile: true },
    };

    if (vtexChannel?.externalId && vtexChannel?.channelType)
      payload.channel = {
        byTypeAndExternalId: { channelType: vtexChannel.channelType, externalId: vtexChannel.externalId },
      };

    return updateBusinessChannel(payload);
  };

  const handleClearApiKey = () => {
    setApiKey('');
    setApiKeyDisplay('');
    setApiKeyDisabled(false);
  };
  const handleClearAccessToken = () => {
    setAccessToken('');
    setAccessTokenDisplay('');
    setAccessTokenDisabled(false);
  };

  const handleSetAccessToken = (newAccessToken: string) => {
    setAccessToken(newAccessToken);
    setAccessTokenDisplay(newAccessToken);
  };
  const handleSetApiKey = (newApiKey: string) => {
    setApiKey(newApiKey);
    setApiKeyDisplay(newApiKey);
  };

  const handleSubnmit = async () => {
    try {
      setLoading(true);
      await handleSaveToken(accessToken, apiKey);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          severity: NotificationSeverity.SUCCESS,
          message: t('tokenCard.successMessage'),
          icon: '',
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          severity: NotificationSeverity.ERROR,
          message: err.message || t('tokenCard.errorMessage'),
          icon: '',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const isLoading = loading || loadingSettingsData;
  return {
    INSTRUCTIONS_URL,
    VIDEO_URL,

    handleSubnmit,
    isLoading,
    saveDisabled: loading,
    title: t('tokenCard.title'),

    accessToken: accessTokenDisplay,
    setAccessToken: handleSetAccessToken,
    apiKey: apiKeyDisplay,
    setApiKey: handleSetApiKey,

    accessTokenError,
    apiKeyError,

    apiKeyDisabled: apiKeyDisabled || isLoading,
    accessTokenDisabled: accessTokenDisabled || isLoading,
    handleClearApiKey,
    handleClearAccessToken,
  };
}
