import React, { FC, useState } from 'react';
import { Alert, Box } from '@connectlyai-tenets/ui-styled-web';
import { useFeatureFlag } from '@hooks';
import { Trans } from 'react-i18next';

export const SetupSofiaAlert: FC = () => {
  const { ffEnableSofiaAIByDefault } = useFeatureFlag(['ffEnableSofiaAIByDefault']);
  const [show, setShow] = useState(ffEnableSofiaAIByDefault);

  const handleClose = () => {
    setShow(false);
  };

  if (!show) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        left: 8,
        right: 8,
        zIndex: 5,
      }}
    >
      <Alert severity="warning" color="warning" sx={{ alignItems: 'center' }} onClose={handleClose}>
        <Trans i18nKey="setupSofiaUi.alert.message">
          Sofia AI is not set up yet. Set it up now to boost your sales and never miss a message with AI-powered product
          recommendations tailored for each user.{' '}
          <Box component="span" sx={{ textDecoration: 'underline', fontWeight: 700 }}>
            Set up Sofia
          </Box>
        </Trans>
      </Alert>
    </Box>
  );
};
