import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Label,
  Link,
  makeStyles,
  styled,
  SettingsIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  CloseIcon,
  Slider,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationService, NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useMutationInstallWidget } from '../hooks/useMutationInstallWidget';
import { NotificationContext } from '../contexts';
import { getShopifyConnectUrl } from '../utils';
import { selectBusinessId, selectShopifyChannel, useMeData, useSettingsData } from '../hooks';
import { SIZE_CONTENT_L } from '../ui-theme';

const LEARN_MORE_LINK_WIDGET = 'https://www.connectly.ai/helpcenter/add-connectly-chat-widget-to-your-website';

const useStyles = makeStyles((theme) => ({
  learnMoreButton: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
}));

const WidgetContainer = styled.div`
  position: relative;
  gap: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 10px;
  padding: ${(props) => props.theme.spacing(3)};
  box-sizing: border-box;
  background: ${(props) => props.theme.palette.common.white};
`;

const WidgetHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const WidgetContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3)};
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.theme.palette.secondary.light};
  border-radius: ${(props) => props.theme.spacing(1)};
`;

const WidgetInstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  border-radius: ${(props) => props.theme.spacing(1.25)};
  box-sizing: border-box;
`;

const WidgetInstructionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const WidgetScriptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: ${(props) => props.theme.spacing(1.5)};
  padding: ${(props) => props.theme.spacing(2)};
  border: 1px solid ${(props) => props.theme.palette.divider};
  background: ${(props) => props.theme.palette.common.white};
`;

const WidgetScript = styled.p`
  padding: 0;
  margin: 0;
  height: 76px;
  color: var(--activitybarColorDark);
  word-break: break-word;
  font-family:
    Consolas,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New;
  cursor: text;
  overflow-y: auto;
`;

const WidgetIconsContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.spacing(6)};
  bottom: ${(props) => props.theme.spacing(6)};
  user-select: none;
`;

type WidgetContainerWebViewProps = {
  script: string;
  notificationService: NotificationService;
};

const WidgetContainerWebView: FC<WidgetContainerWebViewProps> = ({
  script,
  notificationService,
}: WidgetContainerWebViewProps) => {
  const theme = useTheme();
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const classes = useStyles();
  const { data: shopifyChannel } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectShopifyChannel,
  });
  const [positionRight, setPositionRight] = useState(1);
  const positionRegex = useMemo(() => /positionRight:.*px/, []);
  const modifiedScript = useMemo(
    () => script.replace(positionRegex, `positionRight:"${(positionRight - 1) * 40 + 8}px`),
    [script, positionRight, positionRegex],
  );
  const positionMarks = useMemo(() => Array.from(Array(10).keys()).map((v) => ({ value: v, label: v })), []);
  const [showWidgetSettings, setShowWidgetSettings] = useState(false);
  const { mutate: mutateInstallWidget } = useMutationInstallWidget({
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully installed application',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to install widget (please check permissions).',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const handleInstallWidget = useCallback(() => {
    mutateInstallWidget({
      businessId: businessId ?? '',
      businessChannelId: shopifyChannel?.id,
      script: modifiedScript,
    });
  }, [mutateInstallWidget, businessId, shopifyChannel?.id, modifiedScript]);

  const handleChangePosition = useCallback(
    (event: Event, newPositionRight: number | number[]) => {
      setPositionRight(newPositionRight as number);
    },
    [setPositionRight],
  );

  const handleCopyScript = () => {
    navigator.clipboard.writeText(modifiedScript);

    notificationService.notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Copied to clipboard',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  };

  const PERMISSION_URL = getShopifyConnectUrl(
    businessId ?? '',
    shopifyChannel?.remoteProfile?.shopifyProfile?.shop?.myshopifyDomain ?? '',
  );

  return (
    <WidgetContainer id="widget">
      <Dialog open={showWidgetSettings} onClose={() => setShowWidgetSettings(false)} maxWidth="xl">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Label sx={{ fontWeight: 500, fontSize: '20px' }}>Widget Settings</Label>
          <IconButton size="small" onClick={() => setShowWidgetSettings(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Label variant="subtitle1">Position Right</Label>
          <Slider
            aria-label="Position Right"
            value={positionRight}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={10}
            onChange={handleChangePosition}
            marks={positionMarks}
            getAriaValueText={(value: number) => `${value}`}
          />
        </DialogContent>
      </Dialog>
      <WidgetHeaderContainer>
        <Label variant="h6">Web Widget</Label>
        <Label variant="body1">
          Copy the global site tag into the &lt;footer&gt; section of your HTML. See&nbsp;
          <Link href={LEARN_MORE_LINK_WIDGET} target="_blank" underline="hover">
            here
          </Link>
          &nbsp;for detailed implementation directions.
        </Label>
      </WidgetHeaderContainer>
      <WidgetContentContainer>
        <WidgetInstructionContainer>
          <WidgetInstructionHeaderContainer>
            <Label variant="subtitle2">Site Tag</Label>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="text"
                color="primary"
                disableRipple
                className={classes.learnMoreButton}
                onClick={handleCopyScript}
              >
                COPY TEXT
              </Button>
              <IconButton onClick={() => setShowWidgetSettings(true)}>
                <SettingsIcon />
              </IconButton>
            </Box>
          </WidgetInstructionHeaderContainer>
          <WidgetScriptContainer>
            <WidgetScript>
              <Label variant="body1">{modifiedScript}</Label>
            </WidgetScript>
          </WidgetScriptContainer>
        </WidgetInstructionContainer>
        <WidgetIconsContainer className="connectlyai-widget-parent" />
      </WidgetContentContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Button variant="contained" onClick={handleInstallWidget}>
          Install Widget
        </Button>
        <Button href={PERMISSION_URL?.href ?? ''} target="_blank">
          Update Permissions
        </Button>
      </Box>
    </WidgetContainer>
  );
};

export default WidgetContainerWebView;
