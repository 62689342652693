import { IncomingRoomEventData, APICallData, TimeDelayData, MessageNodeData } from '@connectlyai-tenets/sdk';
import { NodeData } from '../useNodePersistence/types';
import { NodeUIState } from './types';
import { NodeType } from '../../../../sdks/flow/createNode';
import { incomingRoomEventNodeDataToUIState } from '../useNodePersistence/incomingRoomEventMappers';
import { apiCallNodeDataToUIState } from '../useNodePersistence/apiCallNodeMappers';
import { timeDelayNodeDataToUIState } from '../useNodePersistence/timeDelayNodeMappers';
import { DEFAULT_TIME_DELAY_NODE_UI } from '../../components/TimeDelayNodeEditor/constants';
import { DEFAULT_API_CALL_NODE_UI } from '../../components/APICallNodeEditor/constants';
import { DEFAULT_INCOMING_ROOM_EVENT_NODE_UI } from '../../components/CustomerRepliesNodeEditor/constants';
import { messageNodeDataToUIState } from '../useNodePersistence/messageNodeMappers';
import { DEFAULT_MESSAGE_NODE_UI } from '../../components/MessageNodeEditor/constants';

export const mapNodeDataToUIState = <NodeUIStateType extends NodeUIState>(
  data: NodeData,
  type: NodeType,
): NodeUIStateType => {
  switch (type) {
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE':
      return messageNodeDataToUIState(data as MessageNodeData) as NodeUIStateType;
    case 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT':
      return incomingRoomEventNodeDataToUIState(data as IncomingRoomEventData) as NodeUIStateType;
    case 'FLOW_OBJECT_TYPE_CALL_API':
      return apiCallNodeDataToUIState(data as APICallData) as NodeUIStateType;
    case 'FLOW_OBJECT_TYPE_TIME_DELAY':
      return timeDelayNodeDataToUIState(data as TimeDelayData) as NodeUIStateType;
    default:
      return {} as NodeUIStateType;
  }
};

export const mapNodeTypeToDefaultUIState = (type: NodeType): NodeUIState => {
  switch (type) {
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE':
      return DEFAULT_MESSAGE_NODE_UI;
    case 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT':
      return DEFAULT_INCOMING_ROOM_EVENT_NODE_UI;
    case 'FLOW_OBJECT_TYPE_CALL_API':
      return DEFAULT_API_CALL_NODE_UI;
    case 'FLOW_OBJECT_TYPE_TIME_DELAY':
      return DEFAULT_TIME_DELAY_NODE_UI;
    default:
      return {} as NodeUIState;
  }
};
