import {
  Avatar,
  Box,
  CompanyIcon,
  Dialog,
  Divider,
  Grid,
  Label,
  Radio,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePricingPlansData } from '../../hooks';
import { applyHook } from '../../utils';
import { BillingCountryConversationPrice } from '../BillingCountryConversationPrice';
import { PaymentOnboardingProps } from './types';

const usePaymentOnboarding = (): Partial<PaymentOnboardingProps> => {
  const { data: pricingPlanData } = usePricingPlansData();
  const pricingPlans = pricingPlanData?.entity;
  const prices = pricingPlanData?.prices;

  return {
    pricingPlans,
    prices,
  };
};

const PaymentOnboardingContent = ({ pricingPlans, prices, subscriptionStatus }: PaymentOnboardingProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.paymentOnboarding' });
  const theme = useTheme();

  const [showCountryConversationPrice, setShowCountryConversationPrice] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(pricingPlans[0].id);

  const onClose = useCallback(() => {
    setShowCountryConversationPrice(false);
  }, [setShowCountryConversationPrice]);

  if (showCountryConversationPrice) {
    return (
      <Dialog open={showCountryConversationPrice} onClose={onClose} maxWidth="xl">
        <BillingCountryConversationPrice onClose={onClose} prices={prices} />
      </Dialog>
    );
  }
  const isNewUser = subscriptionStatus === 'SUBSCRIPTION_STATUS_UNPAID';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, pt: 4.5, pb: 3, gap: 2 }}>
      <Label variant="h5" sx={{ fontWeight: 500 }}>
        {isNewUser ? t('title') : t('existingUserTitle')}
      </Label>
      <Grid container spacing={2} columns={pricingPlans?.length > 1 ? 2 : 1}>
        {pricingPlans?.map((pricingPlan) => (
          <Grid key={pricingPlan?.name} item xs onClick={() => setSelectedPlan(pricingPlan.id)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                backgroundColor: theme.palette.grey[100],
                borderRadius: '10px',
                height: '100%',
                p: 2,
                boxSizing: 'border-box',
                cursor: pricingPlans?.length > 1 ? 'pointer' : 'unset',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar sx={{ backgroundColor: '#825DE1', width: 36, height: 36 }}>
                      <CompanyIcon fill="white" width={24} height={24} />
                    </Avatar>
                    <Label variant="h6">{`${pricingPlan?.name}`}</Label>
                  </Box>
                  {pricingPlans?.length > 1 && <Radio checked={selectedPlan === pricingPlan.id} sx={{ m: '-9px' }} />}
                </Box>
                <Label variant="h6" sx={{ whiteSpace: 'pre-line' }}>
                  {isNewUser
                    ? t('trialText', {
                        price: pricingPlan?.price ?? '',
                        interval: pricingPlan?.recurringInterval ?? 'month',
                      })
                    : t('existingUserTrialText', {
                        price: pricingPlan?.price ?? '',
                        interval: pricingPlan?.recurringInterval ?? 'month',
                      })}
                </Label>
              </Box>
              <Divider />
              <Box sx={{ fontSize: '14px', lineHeight: '150%', color: theme.palette.text.secondary }}>
                {pricingPlan?.products?.map((product) => (
                  <Label key={product} sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>{`· ${product}`}</Label>
                ))}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const PaymentOnboardingPresentation = (props: PaymentOnboardingProps) => {
  if (!props.pricingPlans) {
    return null;
  }
  // TODO: Remove this when we there are more pricing plans. This prevents the test plans from showing.
  return <PaymentOnboardingContent {...props} pricingPlans={[props.pricingPlans[0]]} />;
};

export const PaymentOnboarding = applyHook(PaymentOnboardingPresentation, usePaymentOnboarding);
