import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { CampaignsQuery, CampaignsResponse, CampaignsUrlParameters } from './types';

export const useCampaignsData = <TData = CampaignsResponse>({
  channelTypes,
  businessId,
  search,
  orderBy,
  types,
  ...options
}: Omit<
  UseQueryOptions<CampaignsResponse, unknown, TData, readonly ['campaigns', CampaignsUrlParameters, CampaignsQuery]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<CampaignsQuery> &
  CampaignsUrlParameters) =>
  useQuery(
    ['campaigns', { businessId }, { search, channelTypes, orderBy, types }],
    () =>
      api.campaignV2('/internal/v2/businesses/{businessId}/list/campaigns', 'post', {
        path: { businessId },
        body: { entity: { channelTypes, orderBy, search, types } },
      }),
    options,
  );
