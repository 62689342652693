import React, { useCallback, useState, useMemo } from 'react';
import { Box, Button } from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { KeyPerformanceIndicator } from '@components/KeyPerformanceIndicator';
import { AbandonedCartGenerateReportDialog } from '@components/AbandonedCartGenerateReportDialog';
import { useTranslation } from 'react-i18next';
import { AbandonedCartAnalyticsProps, UseAbandonedCartAnalyticsProps, MetricsDict, MetricProps } from './types';
import { PLACEHOLDER_DATA, METRIC_INFO } from './constants';
import { useIntegrationMetricsData, selectIntegrationMetrics } from '../../hooks/useIntegrationMetricsData';
import { DateRange, DateRangePicker } from '../DateRangePicker';
import { dateRangeAtom } from './atoms';
import { mapMetricName, formatScalar } from './utils';

const useAbandonedCartAnalytics = ({ businessId, integrationId }: UseAbandonedCartAnalyticsProps) => {
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const [openExportData, setOpenExportData] = useState(false);
  const { data, isLoading } = useIntegrationMetricsData({
    businessId: businessId || '',
    integrationId: integrationId || '',
    startDate: `${dateRange.startDate.toISOString().slice(0, 10)}T00:00:00.000Z`,
    endDate: `${dateRange.endDate.toISOString().slice(0, 10)}T00:00:00.000Z`,
    enabled: !!businessId,
    select: selectIntegrationMetrics,
    keepPreviousData: true,
  });
  const metricsRaw = useMemo(() => {
    return data || selectIntegrationMetrics(PLACEHOLDER_DATA);
  }, [data]);
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const metrics: MetricProps[] = useMemo(() => {
    const analyticsMetrics: MetricsDict = {};
    // delivered total used to calculate rates
    let deliveredValue = 0;
    const deliveredMetric = metricsRaw?.find((metric) => metric.metricName === 'delivered_total');
    if (deliveredMetric) {
      deliveredValue = deliveredMetric.current?.scalar?.value || 0;
    }
    metricsRaw?.forEach((metric) => {
      const metricName = metric.metricName || '';
      const lookupName = mapMetricName(metricName);
      if (!lookupName) return;
      const metricInfo = METRIC_INFO[lookupName];
      // for metric names that start with the same string, we want to differentiate them
      const duplicateIdentifier = metricName.slice(lookupName.length).replace(/_/g, ' ');
      const metricData: MetricProps = {
        title: `${t(`metrics.${lookupName}.title`)}${duplicateIdentifier}`,
        hint: t(`metrics.${lookupName}.hint`),
      };
      analyticsMetrics[metricName] = metricData;
      const { current, change } = metric;
      if (current) {
        const { scalar } = current;
        if (scalar) {
          metricData.value = formatScalar(scalar);
          if (scalar.value && metricInfo.rateDenominator === 'delivered_total') {
            if (deliveredValue === 0) {
              metricData.rate = undefined;
            } else {
              metricData.rate = (scalar.value / deliveredValue) * 100;
            }
          }
        }
      }
      if (change) {
        const { scalar } = change;
        if (typeof scalar?.value === 'number') {
          metricData.change = scalar?.value;
        }
      }
    });
    return Object.values(analyticsMetrics);
  }, [metricsRaw, t]);

  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDateRange(newDateRange);
    },
    [setDateRange],
  );

  const handleClose = useCallback(() => setOpenExportData(false), []);
  const buttonText = useMemo(() => t('sendReport'), [t]);
  return {
    metrics,
    isLoading,
    dateRange,
    buttonText,
    openExportData,
    handleDateRangeChange,
    handleClose,
    setOpenExportData,
  };
};
export const AbandonedCartAnalytics = ({
  businessId,
  integrationId,
  businessChannelId,
}: AbandonedCartAnalyticsProps) => {
  const {
    metrics,
    isLoading,
    dateRange,
    buttonText,
    openExportData,
    handleDateRangeChange,
    handleClose,
    setOpenExportData,
  } = useAbandonedCartAnalytics({
    businessId,
    integrationId,
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <AbandonedCartGenerateReportDialog
        businessChannelId={businessChannelId}
        startDate={dateRange.startDate.toISOString()}
        endDate={dateRange.endDate.toISOString()}
        open={openExportData}
        onClose={handleClose}
      />
      <Box
        sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
      >
        <DateRangePicker dateRange={dateRange} setDateRange={handleDateRangeChange} />
        <Button variant="text" onClick={() => setOpenExportData(true)}>
          {buttonText}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
        {metrics.map((metric) => {
          const { title, value, rate, hint, change, lowerIsBetter } = metric;
          return (
            <KeyPerformanceIndicator
              key={title}
              title={title}
              data={{ current: String(value), rate, change }}
              isLoading={isLoading}
              tooltip={hint}
              lowerIsBetter={lowerIsBetter}
            />
          );
        })}
      </Box>
    </Box>
  );
};
