import React, { useCallback, useRef, useContext } from 'react';
import { useAtom } from 'jotai';
import { SaveActionCard, Stack } from '@connectlyai-tenets/ui-styled-web';
import { validatePassword } from '@scenes/Settings/components/UserManagement/utils';
import { PasswordInput } from '@components/PasswordInput';
import { useUpdateUserMutation } from '@scenes/Settings/hooks/useUpdateUserMutation';
import { selectBusinessId, selectUserId, useFeatureFlag, useMeData } from '@hooks';
import {
  changePasswordNewPasswordErrorAtom as newPasswordAtom,
  changePasswordConfirmPasswordErrorAtom as confirmPasswordAtom,
} from '@atoms/settings';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { NotificationContext } from '../../../../contexts';

const useChangePassword = () => {
  const { notificationProvider } = useContext(NotificationContext);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [newPasswordError, setNewPasswordError] = useAtom(newPasswordAtom);
  const [confirmPasswordError, setConfirmPasswordError] = useAtom(confirmPasswordAtom);
  const { data: userId } = useMeData({
    select: selectUserId,
  });
  const { data: businessId } = useMeData({
    select: selectBusinessId,
  });
  const { mutate: updateUserMutate, isLoading: isUserUpdating } = useUpdateUserMutation({
    onSuccess: () => {
      setNewPasswordError('');
      setConfirmPasswordError('');
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Password updated',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error updating password',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });
  const handleSubmit = useCallback(() => {
    const newPassword = newPasswordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (!userId || !businessId) {
      setConfirmPasswordError('Something went wrong');
      return;
    }
    if (!newPassword) {
      setConfirmPasswordError('');
      setNewPasswordError('Current password is required');
      return;
    }
    if (newPassword !== confirmPassword) {
      setNewPasswordError('');
      setConfirmPasswordError('Passwords do not match');
      return;
    }
    const newPasswordValidation = validatePassword(newPassword);
    if (!newPasswordValidation.isValid) {
      setConfirmPasswordError('');
      setNewPasswordError(newPasswordValidation.message);
      return;
    }
    updateUserMutate({
      businessId,
      userId,
      password: newPassword,
    });
  }, [businessId, userId, updateUserMutate, setConfirmPasswordError, setNewPasswordError]);
  const error = false;
  return {
    confirmPasswordRef,
    confirmPasswordError,
    newPasswordRef,
    newPasswordError,
    handleSubmit,
    error,
    saveDisabled: Boolean(error) || !userId || isUserUpdating,
  };
};

export const ChangePassword = () => {
  const { confirmPasswordRef, confirmPasswordError, newPasswordRef, newPasswordError, handleSubmit, saveDisabled } =
    useChangePassword();
  return (
    <SaveActionCard onSubmit={handleSubmit} saveDisabled={saveDisabled} title="Change Password">
      <Stack>
        <PasswordInput
          inputRef={newPasswordRef}
          id="new-password-input"
          label="New Password"
          name="new password"
          variant="outlined"
          margin="normal"
          sx={{ width: 'fit-content', mt: 0 }}
          defaultValue=""
          error={Boolean(newPasswordError)}
          helperText={newPasswordError}
        />
        <PasswordInput
          inputRef={confirmPasswordRef}
          id="confirm-password-input"
          label="Confirm Password"
          variant="outlined"
          sx={{ width: 'fit-content' }}
          name="confirm password"
          margin="normal"
          defaultValue=""
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError}
        />
      </Stack>
    </SaveActionCard>
  );
};
