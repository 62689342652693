import { Paths } from '@connectlyai-tenets/sdk';

export const VERIFY_SPREADSHEET_URL =
  '/internal/v3/businesses/{input.businessId}/request_verify_cohort/sendouts/{input.sendoutId}';
type VerifySpreadsheetURL = typeof VERIFY_SPREADSHEET_URL;

export type VerifySpreadsheetQuery = Paths['campaignV3'][VerifySpreadsheetURL]['post']['parameters']['body']['input'];

export type VerifySpreadsheetUrlParameters = {
  businessId: string;
  sendoutId: string;
};

export type VerifySpreadsheetVariables = VerifySpreadsheetQuery & VerifySpreadsheetUrlParameters;

export type VerifySpreadsheetResponse = Paths['campaignV3'][VerifySpreadsheetURL]['post']['responses']['200']['schema'];
