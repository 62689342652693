import { generatePath } from 'react-router';
export default class DelegatingNavigator {
    constructor(delegate, routerId = '') {
        this.delegate = delegate;
        this.routerId = routerId;
    }
    generateGlobalRouteId(to) {
        const globalToId = to.startsWith(this.routerId) ? to : `${this.routerId}${to}`;
        return globalToId;
    }
    generateGlobalPath(to) {
        const localPath = generatePath(to.id, to.params);
        const globalPath = localPath.startsWith(this.routerId) ? localPath : `${this.routerId}${localPath}`;
        return globalPath;
    }
    navigate(to) {
        const finalRoute = {
            id: to.id,
            params: {
                ...to.params,
                fromRouter: this.routerId,
            },
        };
        this.delegate.navigate(finalRoute);
    }
}
