import {
  FormControl,
  FormControlLabel,
  Label,
  Radio,
  RadioGroup,
  Stack,
  WidgetBorderRainbowIcon,
  WidgetBorderGradientIcon,
  WidgetBorderBlackIcon,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import React from 'react';
import { usePartialAgentDescriptor } from './usePartialAgentDescriptor';

export const ColorCustomization = () => {
  const {
    value: borderColor,
    setValue: setBorderColor,
    onSave,
  } = usePartialAgentDescriptor<'gradient' | 'black' | 'rainbow'>({
    keyPath: 'borderColor',
    defaultValue: 'gradient',
  });

  return (
    <SaveActionCard
      onSubmit={onSave}
      title="Color customization"
      subtitle="Personalize the WebSearch colors to match your brand, creating a vibrant and engaging experience for your customers!"
    >
      <FormControl>
        <RadioGroup
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e) => setBorderColor(e.target.value as 'gradient' | 'black' | 'rainbow')}
          value={borderColor}
        >
          <FormControlLabel
            value="rainbow"
            control={<Radio />}
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <WidgetBorderRainbowIcon />
                <Label>Rainbow</Label>
              </Stack>
            }
          />
          <FormControlLabel
            value="gradient"
            control={<Radio />}
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <WidgetBorderGradientIcon />
                <Label>Gradient</Label>
              </Stack>
            }
          />
          <FormControlLabel
            value="black"
            control={<Radio />}
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <WidgetBorderBlackIcon />

                <Label>Black</Label>
              </Stack>
            }
          />
        </RadioGroup>
      </FormControl>
    </SaveActionCard>
  );
};
