import React, { useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { overviewDatesAtom, conversationFilterAtom, conversationFilterCategoriesAtom } from '@atoms/analytics';
import { uniq } from 'lodash';
import i18next from 'i18next';
import { Label, Box, ChatIcon, Link } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useConversationOverviewData, useFeatureFlag, useMeData } from '@hooks';
import { IconWithLoading } from '@components';
import {
  sortNumber,
  sortString,
  EnhancedTable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
} from '@components/EnhancedTable';
import { ConversationOverviewProps } from './types';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { WHATSAPP_PRICING_LINK } from '../../constants';
import { Country, ConversationsCount } from './TableCells';

const LinkText = ({ href, children }: { href: string; children?: string }) => {
  return (
    <Link href={href} target="_blank">
      {children}
    </Link>
  );
};

const useDataTable = () => {
  const [startDate, endDate] = useAtomValue(overviewDatesAtom);
  const activeConversationFilter = useAtomValue(conversationFilterAtom);
  const setConversationFilterCategories = useSetAtom(conversationFilterCategoriesAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: conversationOverview, isLoading: isConversationOverviewLoading } = useConversationOverviewData({
    businessId: businessId as string,
    enabled: !!businessId,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    startTime: startDate.toISOString(),
    endTime: endDate.toISOString(),
  });

  const conversationFilterOptions: string[] = useMemo(
    () =>
      uniq([
        'ALL',
        ...(conversationOverview?.entity?.map((x) => {
          if (!x.conversationCategory) {
            return '';
          }

          return (x.conversationCategory as string).replace(/_/g, '');
        }) || []),
      ]),

    [conversationOverview],
  );

  useEffect(() => {
    setConversationFilterCategories(conversationFilterOptions);
  }, [conversationFilterOptions, setConversationFilterCategories]);

  const filteredConversations = useMemo(() => {
    if (!conversationOverview?.entity) return [];
    if (activeConversationFilter === 'ALL') {
      return (
        conversationOverview.entity
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .reduce((acc: any, cur: ConversationOverviewProps) => {
            const existing = acc.find((item: ConversationOverviewProps) => item.country === cur.country);
            if (existing?.conversations) {
              existing.conversations += cur.conversations;
            } else {
              acc.push({ ...cur });
            }
            return acc;
          }, [])
          .sort(
            (a: ConversationOverviewProps, b: ConversationOverviewProps) =>
              (b.conversations || 0) - (a.conversations || 0),
          )
      );
    }
    return conversationOverview.entity.filter((x) => x.conversationCategory === activeConversationFilter);
  }, [conversationOverview, activeConversationFilter]);

  const totalFilteredConversations = useMemo(
    () =>
      [...filteredConversations]?.reduce((acc, cur) => {
        return acc + (cur.conversations ? cur.conversations : 0);
      }, 0),
    [filteredConversations],
  );

  const rows: ETableRowProps[] = useMemo(() => {
    const regionNames = new Intl.DisplayNames([i18next.language], { type: 'region' });
    if (!filteredConversations) return [];
    return filteredConversations.map((item: { country: string; conversations: number }) => {
      return {
        id: 'conversations',
        data: [
          {
            value: item.country,
            display: <Country country={item.country || ''} regions={regionNames} />,
            align: 'left',
          },
          {
            value: item.conversations,
            display: <ConversationsCount value={item.conversations?.toLocaleString() || ''} />,
            align: 'right',
          },
          {
            value: '',
            display: '',
          },
        ],
      };
    });
  }, [filteredConversations]);

  const { t } = useTranslation('translation', { keyPrefix: 'analytics.accountOverview' });
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'country',
        label: t('columns.country', 'Country'),
        width: '30%',
        sort: sortString,
        align: 'left',
      },
      {
        id: 'conversations',
        label: t('columns.conversations', 'Conversations'),
        width: '10%',
        sort: sortNumber,
        align: 'right',
      },
      {
        id: '',
        label: '',
        width: '60%',
      },
    ];
  }, [t]);
  const title = useMemo(() => {
    return `${totalFilteredConversations || 0} ${
      totalFilteredConversations === 1 ? t('conversation', 'conversation') : t('conversations', 'conversations')
    }`;
  }, [totalFilteredConversations, t]);

  const description = () => (
    <Trans
      i18nKey="description"
      defaults="Conversations are message threads between you and your customers. Conversations can 
      last up to 24-hours and must be initiated by you (business-initiated) or your customers (user-initiated). 
      Conversation costs vary based on recipients locations"
      t={t}
      components={[<LinkText key="description" href={WHATSAPP_PRICING_LINK} />]}
    />
  );

  return {
    loading: isConversationOverviewLoading,
    title,
    description,
    rows,
    tableHead,
  };
};

export const DataTable = () => {
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);
  const { title, description, tableHead, rows, loading } = useDataTable();

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        minHeight: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!ffEnableAnalyticsRedesign && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconWithLoading loading={loading} size={4}>
              <ChatIcon sx={{ width: (theme) => theme.spacing(4), height: (theme) => theme.spacing(4) }} />
            </IconWithLoading>
            <Label variant="h6" sx={{ ml: SIZE_SPACING_INTER_COMPONENT }}>
              {title}
            </Label>
          </Box>

          <Label variant="body1" sx={{ color: (theme) => theme.palette.text.secondary, maxWidth: '750px', mt: 1 }}>
            {description()}
          </Label>
        </>
      )}
      <Box sx={{ mt: 2 }}>
        <EnhancedTable
          rows={rows}
          headers={tableHead}
          sortDefault={{ by: 'createdAt', order: 'desc' }}
          enablePagination={false}
        />
      </Box>
    </Box>
  );
};
