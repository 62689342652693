import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  GroupIcon,
  Label,
  Link,
  Radio,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { setAudience, selectAudience } from '../../state/flow';
import {
  useAudienceSegmentsData,
  useMeData,
  selectBusinessId,
  selectPhoneListSegments,
  AudienceSegment,
} from '../../hooks';
import { FlowChartSidebarChooseAudienceProps } from './types';
import { applyHook } from '../../utils';
import { AudienceListBuilder } from '../AudienceListBuilder';
import { openListBuilder } from '../../features/audiences';
import { WithAnalytics } from '../WithAnalytics';

const useFlowChartSidebarChooseAudience = (): Partial<FlowChartSidebarChooseAudienceProps> => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: audienceSegments = [], isLoading } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectPhoneListSegments },
  );

  const dispatch = useDispatch();
  const { segmentId: audienceSegmentId, isEnabled: useAudience } = useSelector(selectAudience);
  const handleUseAudienceChange = useCallback(
    (value: boolean) => {
      dispatch(setAudience({ segmentId: audienceSegmentId, isEnabled: value }));
    },
    [audienceSegmentId, dispatch],
  );

  const handleAudienceSegmentChange = useCallback(
    (value: AudienceSegment | null) => {
      dispatch(setAudience({ segmentId: value?.segment?.id || '', isEnabled: true }));
    },
    [dispatch],
  );

  const handleCreate = useCallback(() => {
    dispatch(openListBuilder());
  }, [dispatch]);

  const handleCreateSuccess = useCallback(
    (value: AudienceSegment) => {
      handleAudienceSegmentChange(value);
    },
    [handleAudienceSegmentChange],
  );

  return {
    audienceSegment: audienceSegments.find((x) => x.segment?.id === audienceSegmentId),
    audienceSegments,
    isLoading,
    onAudienceSegmentChange: handleAudienceSegmentChange,
    onCreate: handleCreate,
    onCreateSuccess: handleCreateSuccess,
    onUseAudienceChange: handleUseAudienceChange,
    useAudience,
  };
};

const FlowChartSidebarChooseAudiencePresentation: FC<FlowChartSidebarChooseAudienceProps> = ({
  audienceSegment,
  audienceSegments,
  businessId,
  isLoading,
  onAudienceSegmentChange,
  onCreate,
  onCreateSuccess,
  onUseAudienceChange,
  useAudience,
}) => {
  return (
    <>
      <AudienceListBuilder onSuccess={onCreateSuccess} />
      <Label variant="h6" gutterBottom>
        Choose Audience
      </Label>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2">
                Use a saved audience
              </Label>
            }
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
            checked={useAudience}
            onChange={() => onUseAudienceChange(true)}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Autocomplete
              id="audience-segment-selector"
              fullWidth
              loading={isLoading}
              value={audienceSegment || null}
              getOptionLabel={(option) => option.segment?.name || ''}
              renderOption={(props, option, _state) => (
                <li {...props} key={option.segment?.id}>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Label noWrap>{option.segment?.name || ''}</Label>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        color: 'text.secondary',
                      }}
                    >
                      <GroupIcon sx={{ width: '18px', height: '18px' }} />
                      <Label>{option.stats?.size || ''}</Label>
                    </Box>
                  </Box>
                </li>
              )}
              options={audienceSegments}
              onChange={(_, value) => onAudienceSegmentChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // error={Boolean(error)}
                  // helperText={error}
                  placeholder="Select Audience"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              sx={{ mt: 1.5, '& .MuiInputBase-root': { p: '3.5px 6px', borderRadius: '10px' } }}
            />
            <WithAnalytics
              type="click new_audience_button"
              data={{
                businessId,
                from: 'flow',
              }}
            >
              <Link
                underline="none"
                onClick={onCreate}
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Create New Audience
              </Link>
            </WithAnalytics>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px' }}>
                Create a new sheet
              </Label>
            }
            checked={!useAudience}
            onChange={() => onUseAudienceChange(false)}
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1 }}>
            Fill out a Google sheet with the recipient details.
          </Label>
        </Box>
      </Box>
    </>
  );
};

export const FlowChartSidebarChooseAudience = applyHook(
  FlowChartSidebarChooseAudiencePresentation,
  useFlowChartSidebarChooseAudience,
);
