import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_CREATE_SCRAPING_JOB = '/internal/v1/businesses/{input.businessId}/ai/new_scraping_job';

type MutationCreateScrapingJob = typeof MUTATION_CREATE_SCRAPING_JOB;

export type MutationCreateScrapingJobResponse =
  Paths['aiV1'][MutationCreateScrapingJob]['post']['responses']['200']['schema'];

export type MutationCreateScrapingJobQuery = NonNullable<
  Paths['aiV1'][MutationCreateScrapingJob]['post']['parameters']['body']['input']
>['manualScrapingEmailAlertRequest'];

export type MutationCreateScrapingJobParams = { businessId: string } & MutationCreateScrapingJobQuery;

export type MutationCreateScrapingJobOptions = Omit<
  UseMutationOptions<MutationCreateScrapingJobResponse, ConnectlyError, MutationCreateScrapingJobParams, unknown>,
  'mutationKey' | 'mutationFn'
>;
