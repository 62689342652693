import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { MetricName } from './types';
import { IntegrationMetricDataScalar } from '../../hooks/useIntegrationMetricsData';
import { formatPercent, formatNumber, formatCurrency } from '../../utils';

export const mapMetricName = (name: string): MetricName | undefined => {
  if (name.startsWith('conversion_value_total')) {
    return 'conversion_value_total';
  }
  if (name.startsWith('click_through_conversion_value_total')) {
    return 'click_through_conversion_value_total';
  }

  switch (name) {
    case 'delivered_total': {
      return 'delivered_total';
    }
    case 'read_total': {
      return 'read_total';
    }
    case 'conversion_total': {
      return 'conversion_total';
    }
    case 'total_clicks': {
      return 'total_clicks';
    }
    case 'unique_clicks': {
      return 'unique_clicks';
    }
    case 'click_through_conversion_total': {
      return 'click_through_conversion_total';
    }
    case 'click_through_conversion_value_total': {
      return 'click_through_conversion_value_total';
    }
    default: {
      return undefined;
    }
  }
};

export function formatScalar(scalar: IntegrationMetricDataScalar | undefined): string {
  const { unit, value } = scalar || {};
  if (value === undefined) {
    return '';
  }
  if (!unit) {
    return formatNumber(value);
  }

  const { family } = unit;
  if (!family) {
    return formatNumber(value);
  }

  switch (family) {
    case 'UNIT_FAMILY_CURRENCY': {
      return formatCurrency(value, unit.shortName || '');
    }
    case 'UNIT_FAMILY_GENERAL': {
      return formatNumber(value);
    }
    case 'UNIT_FAMILY_PERCENT': {
      return formatPercent(Math.floor(value));
    }
    case 'UNIT_FAMILY_UNSPECIFIED': {
      return formatNumber(value);
    }
    default: {
      assertUnreachable(family);
      return '';
    }
  }
}
