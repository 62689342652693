import { Paths } from '@connectlyai-tenets/sdk';

export const SOFIA_STORE_DESCRIPTOR_V2_URL =
  '/internal/v1/businesses/{input.businessId}/ai/store_descriptor_v2/query/v1';

type SofiaUrl = typeof SOFIA_STORE_DESCRIPTOR_V2_URL;

export type SofiaV2UrlParameters = { businessId: string };
export type SofiaV2Response = Paths['aiV1'][SofiaUrl]['get']['responses']['200']['schema'];
export type StoreDescriptorV2 = NonNullable<
  NonNullable<NonNullable<SofiaV2Response>['response']>['storeDescriptors']
>[0];
