import React, { useCallback } from 'react';
import { useAtom } from 'jotai';
import { MIN_DATE, MAX_DATE, DATA_LAG_DAYS } from '@scenes/Sofia/constants';
import { dateRangeAtom } from '@atoms/sofia';
import { Box, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useDateRange } from '../../../../hooks/useDateRange';
import { DateRange, DateRangePicker } from '../../../../components/DateRangePicker';

const useAnalyticsControls = () => {
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDateRange(newDateRange);
    },
    [setDateRange],
  );

  const dateRangeList = useDateRange(DATA_LAG_DAYS).defaultDateRangeList.filter((_: unknown, i: number) => {
    if ([2, 3, 4].includes(i)) {
      return true;
    }
    return false;
  });
  return {
    dateRange,
    handleDateRangeChange,
    dateRangeList,
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
  };
};

export const AnalyticsControls = () => {
  const { minDate, maxDate, dateRangeList, dateRange, handleDateRangeChange } = useAnalyticsControls();

  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: '1 1 auto',
        maxHeight: theme.spacing(5.5),
        position: 'relative',
      }}
    >
      <DateRangePicker
        dateRange={dateRange}
        setDateRange={handleDateRangeChange}
        minDate={minDate}
        maxDate={maxDate}
        dateRangeList={dateRangeList}
      />
    </Box>
  );
};
