import { FlowObjectPropertyValue, ReactFlowNode, ReactFlowEdge, FlowDocumentVersion, Dependency, ReactFlowNodeChange, ReactFlowEdgeChange, ReactFlowNodeObjectUpdate, flowDocumentTypeFromJSON, flowDocumentCategoryFromJSON, flowDocumentTypeToJSON, flowDocumentCategoryToJSON, flowObjectTypeFromJSON, flowObjectTypeToJSON, } from "../models";
import { mutationStatusFromJSON, mutationStatusToJSON, } from "../../common/api/models";
import { Diagnostic, verificationStatusFromJSON, verificationTypeFromJSON, modeFromJSON, verificationStatusToJSON, verificationTypeToJSON, modeToJSON, } from "../compiler/models";
import { ProgressStatus } from "../../event/connectly/models";
import { Error } from "../../error/error";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.flow.dto";
function createBaseFlowDocumentOptions() {
    return {};
}
export const FlowDocumentOptions = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentOptions();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseFlowDocumentInput() {
    return {
        id: "",
        businessId: "",
        type: 0,
        category: 0,
        name: "",
        options: undefined,
    };
}
export const FlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.type !== 0) {
            writer.uint32(24).int32(message.type);
        }
        if (message.category !== 0) {
            writer.uint32(32).int32(message.category);
        }
        if (message.name !== "") {
            writer.uint32(42).string(message.name);
        }
        if (message.options !== undefined) {
            FlowDocumentOptions.encode(message.options, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.category = reader.int32();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.options = FlowDocumentOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            type: isSet(object.type) ? flowDocumentTypeFromJSON(object.type) : 0,
            category: isSet(object.category)
                ? flowDocumentCategoryFromJSON(object.category)
                : 0,
            name: isSet(object.name) ? String(object.name) : "",
            options: isSet(object.options)
                ? FlowDocumentOptions.fromJSON(object.options)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.type !== undefined &&
            (obj.type = flowDocumentTypeToJSON(message.type));
        message.category !== undefined &&
            (obj.category = flowDocumentCategoryToJSON(message.category));
        message.name !== undefined && (obj.name = message.name);
        message.options !== undefined &&
            (obj.options = message.options
                ? FlowDocumentOptions.toJSON(message.options)
                : undefined);
        return obj;
    },
};
function createBaseFlowObjectInput() {
    return {
        id: "",
        businessId: "",
        flowDocumentId: "",
        type: 0,
        properties: [],
    };
}
export const FlowObjectInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(26).string(message.flowDocumentId);
        }
        if (message.type !== 0) {
            writer.uint32(32).int32(message.type);
        }
        for (const v of message.properties) {
            FlowObjectPropertyInput.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObjectInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.flowDocumentId = reader.string();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.properties.push(FlowObjectPropertyInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            type: isSet(object.type) ? flowObjectTypeFromJSON(object.type) : 0,
            properties: Array.isArray(object === null || object === void 0 ? void 0 : object.properties)
                ? object.properties.map((e) => FlowObjectPropertyInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.type !== undefined &&
            (obj.type = flowObjectTypeToJSON(message.type));
        if (message.properties) {
            obj.properties = message.properties.map((e) => e ? FlowObjectPropertyInput.toJSON(e) : undefined);
        }
        else {
            obj.properties = [];
        }
        return obj;
    },
};
function createBaseFlowObjectPropertyInput() {
    return {
        revisionId: "",
        businessId: "",
        flowObjectId: "",
        key: "",
        value: undefined,
    };
}
export const FlowObjectPropertyInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.revisionId !== "") {
            writer.uint32(10).string(message.revisionId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.flowObjectId !== "") {
            writer.uint32(26).string(message.flowObjectId);
        }
        if (message.key !== "") {
            writer.uint32(34).string(message.key);
        }
        if (message.value !== undefined) {
            FlowObjectPropertyValue.encode(message.value, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObjectPropertyInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.revisionId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.flowObjectId = reader.string();
                    break;
                case 4:
                    message.key = reader.string();
                    break;
                case 5:
                    message.value = FlowObjectPropertyValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowObjectId: isSet(object.flowObjectId)
                ? String(object.flowObjectId)
                : "",
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? FlowObjectPropertyValue.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowObjectId !== undefined &&
            (obj.flowObjectId = message.flowObjectId);
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? FlowObjectPropertyValue.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseFlowObjectPropertyCollectionInput() {
    return { entries: [] };
}
export const FlowObjectPropertyCollectionInput = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            FlowObjectPropertyInput.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObjectPropertyCollectionInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(FlowObjectPropertyInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => FlowObjectPropertyInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? FlowObjectPropertyInput.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseQueryReactFlowDocumentInput() {
    return { businessId: "", flowDocumentId: "" };
}
export const QueryReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseQueryFlowDocumentVersionsInput() {
    return { businessId: "", flowDocumentId: "" };
}
export const QueryFlowDocumentVersionsInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryFlowDocumentVersionsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseQueryFlowDocumentVersionInput() {
    return { businessId: "", flowDocumentId: "", flowDocumentVersionId: "" };
}
export const QueryFlowDocumentVersionInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(26).string(message.flowDocumentVersionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryFlowDocumentVersionInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.flowDocumentVersionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        return obj;
    },
};
function createBaseQueryReactFlowDocumentVersionsInput() {
    return { businessId: "", flowDocumentId: "" };
}
export const QueryReactFlowDocumentVersionsInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryReactFlowDocumentVersionsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseReactFlowDocumentInput() {
    return { documentInput: undefined };
}
export const ReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.documentInput !== undefined) {
            FlowDocumentInput.encode(message.documentInput, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.documentInput = FlowDocumentInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            documentInput: isSet(object.documentInput)
                ? FlowDocumentInput.fromJSON(object.documentInput)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.documentInput !== undefined &&
            (obj.documentInput = message.documentInput
                ? FlowDocumentInput.toJSON(message.documentInput)
                : undefined);
        return obj;
    },
};
function createBaseReactFlowDocumentDto() {
    return {
        id: "",
        businessId: "",
        type: 0,
        category: 0,
        name: "",
        nodes: [],
        edges: [],
        lastRevisionId: "",
    };
}
export const ReactFlowDocumentDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.type !== 0) {
            writer.uint32(24).int32(message.type);
        }
        if (message.category !== 0) {
            writer.uint32(32).int32(message.category);
        }
        if (message.name !== "") {
            writer.uint32(42).string(message.name);
        }
        for (const v of message.nodes) {
            ReactFlowNode.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.edges) {
            ReactFlowEdge.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.lastRevisionId !== "") {
            writer.uint32(66).string(message.lastRevisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowDocumentDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.category = reader.int32();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.nodes.push(ReactFlowNode.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.edges.push(ReactFlowEdge.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.lastRevisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            type: isSet(object.type) ? flowDocumentTypeFromJSON(object.type) : 0,
            category: isSet(object.category)
                ? flowDocumentCategoryFromJSON(object.category)
                : 0,
            name: isSet(object.name) ? String(object.name) : "",
            nodes: Array.isArray(object === null || object === void 0 ? void 0 : object.nodes)
                ? object.nodes.map((e) => ReactFlowNode.fromJSON(e))
                : [],
            edges: Array.isArray(object === null || object === void 0 ? void 0 : object.edges)
                ? object.edges.map((e) => ReactFlowEdge.fromJSON(e))
                : [],
            lastRevisionId: isSet(object.lastRevisionId)
                ? String(object.lastRevisionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.type !== undefined &&
            (obj.type = flowDocumentTypeToJSON(message.type));
        message.category !== undefined &&
            (obj.category = flowDocumentCategoryToJSON(message.category));
        message.name !== undefined && (obj.name = message.name);
        if (message.nodes) {
            obj.nodes = message.nodes.map((e) => e ? ReactFlowNode.toJSON(e) : undefined);
        }
        else {
            obj.nodes = [];
        }
        if (message.edges) {
            obj.edges = message.edges.map((e) => e ? ReactFlowEdge.toJSON(e) : undefined);
        }
        else {
            obj.edges = [];
        }
        message.lastRevisionId !== undefined &&
            (obj.lastRevisionId = message.lastRevisionId);
        return obj;
    },
};
function createBaseFlowDocumentVersionDtoCollection() {
    return { entries: [] };
}
export const FlowDocumentVersionDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            FlowDocumentVersion.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentVersionDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(FlowDocumentVersion.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => FlowDocumentVersion.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? FlowDocumentVersion.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseReactFlowDocumentVersionDtoCollection() {
    return { entries: [] };
}
export const ReactFlowDocumentVersionDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            FlowDocumentVersion.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowDocumentVersionDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(FlowDocumentVersion.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => FlowDocumentVersion.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? FlowDocumentVersion.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseChangeReactFlowDocumentInput() {
    return {
        businessId: "",
        flowDocumentId: "",
        clientRevisionId: "",
        changeOneof: undefined,
    };
}
export const ChangeReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRevisionId !== "") {
            writer.uint32(26).string(message.clientRevisionId);
        }
        if (((_a = message.changeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "nodeChange") {
            ReactFlowNodeChange.encode(message.changeOneof.nodeChange, writer.uint32(82).fork()).ldelim();
        }
        if (((_b = message.changeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "edgeChange") {
            ReactFlowEdgeChange.encode(message.changeOneof.edgeChange, writer.uint32(90).fork()).ldelim();
        }
        if (((_c = message.changeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "nodeObjectUpdate") {
            ReactFlowNodeObjectUpdate.encode(message.changeOneof.nodeObjectUpdate, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChangeReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRevisionId = reader.string();
                    break;
                case 10:
                    message.changeOneof = {
                        $case: "nodeChange",
                        nodeChange: ReactFlowNodeChange.decode(reader, reader.uint32()),
                    };
                    break;
                case 11:
                    message.changeOneof = {
                        $case: "edgeChange",
                        edgeChange: ReactFlowEdgeChange.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.changeOneof = {
                        $case: "nodeObjectUpdate",
                        nodeObjectUpdate: ReactFlowNodeObjectUpdate.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRevisionId: isSet(object.clientRevisionId)
                ? String(object.clientRevisionId)
                : "",
            changeOneof: isSet(object.nodeChange)
                ? {
                    $case: "nodeChange",
                    nodeChange: ReactFlowNodeChange.fromJSON(object.nodeChange),
                }
                : isSet(object.edgeChange)
                    ? {
                        $case: "edgeChange",
                        edgeChange: ReactFlowEdgeChange.fromJSON(object.edgeChange),
                    }
                    : isSet(object.nodeObjectUpdate)
                        ? {
                            $case: "nodeObjectUpdate",
                            nodeObjectUpdate: ReactFlowNodeObjectUpdate.fromJSON(object.nodeObjectUpdate),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRevisionId !== undefined &&
            (obj.clientRevisionId = message.clientRevisionId);
        ((_a = message.changeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "nodeChange" &&
            (obj.nodeChange = ((_b = message.changeOneof) === null || _b === void 0 ? void 0 : _b.nodeChange)
                ? ReactFlowNodeChange.toJSON((_c = message.changeOneof) === null || _c === void 0 ? void 0 : _c.nodeChange)
                : undefined);
        ((_d = message.changeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "edgeChange" &&
            (obj.edgeChange = ((_e = message.changeOneof) === null || _e === void 0 ? void 0 : _e.edgeChange)
                ? ReactFlowEdgeChange.toJSON((_f = message.changeOneof) === null || _f === void 0 ? void 0 : _f.edgeChange)
                : undefined);
        ((_g = message.changeOneof) === null || _g === void 0 ? void 0 : _g.$case) === "nodeObjectUpdate" &&
            (obj.nodeObjectUpdate = ((_h = message.changeOneof) === null || _h === void 0 ? void 0 : _h.nodeObjectUpdate)
                ? ReactFlowNodeObjectUpdate.toJSON((_j = message.changeOneof) === null || _j === void 0 ? void 0 : _j.nodeObjectUpdate)
                : undefined);
        return obj;
    },
};
function createBaseReactFlowDocumentChangeDto() {
    return {
        businessId: "",
        flowDocumentId: "",
        clientRevisionId: "",
        revisionId: "",
        changeOneof: undefined,
    };
}
export const ReactFlowDocumentChangeDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRevisionId !== "") {
            writer.uint32(26).string(message.clientRevisionId);
        }
        if (message.revisionId !== "") {
            writer.uint32(34).string(message.revisionId);
        }
        if (((_a = message.changeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "nodeChange") {
            ReactFlowNodeChange.encode(message.changeOneof.nodeChange, writer.uint32(82).fork()).ldelim();
        }
        if (((_b = message.changeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "edgeChange") {
            ReactFlowEdgeChange.encode(message.changeOneof.edgeChange, writer.uint32(90).fork()).ldelim();
        }
        if (((_c = message.changeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "nodeObjectUpdate") {
            ReactFlowNodeObjectUpdate.encode(message.changeOneof.nodeObjectUpdate, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowDocumentChangeDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRevisionId = reader.string();
                    break;
                case 4:
                    message.revisionId = reader.string();
                    break;
                case 10:
                    message.changeOneof = {
                        $case: "nodeChange",
                        nodeChange: ReactFlowNodeChange.decode(reader, reader.uint32()),
                    };
                    break;
                case 11:
                    message.changeOneof = {
                        $case: "edgeChange",
                        edgeChange: ReactFlowEdgeChange.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.changeOneof = {
                        $case: "nodeObjectUpdate",
                        nodeObjectUpdate: ReactFlowNodeObjectUpdate.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRevisionId: isSet(object.clientRevisionId)
                ? String(object.clientRevisionId)
                : "",
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            changeOneof: isSet(object.nodeChange)
                ? {
                    $case: "nodeChange",
                    nodeChange: ReactFlowNodeChange.fromJSON(object.nodeChange),
                }
                : isSet(object.edgeChange)
                    ? {
                        $case: "edgeChange",
                        edgeChange: ReactFlowEdgeChange.fromJSON(object.edgeChange),
                    }
                    : isSet(object.nodeObjectUpdate)
                        ? {
                            $case: "nodeObjectUpdate",
                            nodeObjectUpdate: ReactFlowNodeObjectUpdate.fromJSON(object.nodeObjectUpdate),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRevisionId !== undefined &&
            (obj.clientRevisionId = message.clientRevisionId);
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        ((_a = message.changeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "nodeChange" &&
            (obj.nodeChange = ((_b = message.changeOneof) === null || _b === void 0 ? void 0 : _b.nodeChange)
                ? ReactFlowNodeChange.toJSON((_c = message.changeOneof) === null || _c === void 0 ? void 0 : _c.nodeChange)
                : undefined);
        ((_d = message.changeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "edgeChange" &&
            (obj.edgeChange = ((_e = message.changeOneof) === null || _e === void 0 ? void 0 : _e.edgeChange)
                ? ReactFlowEdgeChange.toJSON((_f = message.changeOneof) === null || _f === void 0 ? void 0 : _f.edgeChange)
                : undefined);
        ((_g = message.changeOneof) === null || _g === void 0 ? void 0 : _g.$case) === "nodeObjectUpdate" &&
            (obj.nodeObjectUpdate = ((_h = message.changeOneof) === null || _h === void 0 ? void 0 : _h.nodeObjectUpdate)
                ? ReactFlowNodeObjectUpdate.toJSON((_j = message.changeOneof) === null || _j === void 0 ? void 0 : _j.nodeObjectUpdate)
                : undefined);
        return obj;
    },
};
function createBaseVerifyReactFlowDocumentInput() {
    return { businessId: "", flowDocumentId: "", clientRequestId: "" };
}
export const VerifyReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRequestId !== "") {
            writer.uint32(26).string(message.clientRequestId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        return obj;
    },
};
function createBaseCompileReactFlowDocumentInput() {
    return {
        businessId: "",
        flowDocumentId: "",
        clientRequestId: "",
        flowDocumentRevisionId: "",
    };
}
export const CompileReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRequestId !== "") {
            writer.uint32(26).string(message.clientRequestId);
        }
        if (message.flowDocumentRevisionId !== "") {
            writer.uint32(34).string(message.flowDocumentRevisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCompileReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRequestId = reader.string();
                    break;
                case 4:
                    message.flowDocumentRevisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            flowDocumentRevisionId: isSet(object.flowDocumentRevisionId)
                ? String(object.flowDocumentRevisionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.flowDocumentRevisionId !== undefined &&
            (obj.flowDocumentRevisionId = message.flowDocumentRevisionId);
        return obj;
    },
};
function createBaseLinkReactFlowDocumentInput() {
    return {
        businessId: "",
        flowDocumentId: "",
        clientRequestId: "",
        flowDocumentVersionId: "",
        flowDocumentRevisionId: "",
    };
}
export const LinkReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRequestId !== "") {
            writer.uint32(26).string(message.clientRequestId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(34).string(message.flowDocumentVersionId);
        }
        if (message.flowDocumentRevisionId !== "") {
            writer.uint32(42).string(message.flowDocumentRevisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLinkReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRequestId = reader.string();
                    break;
                case 4:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 5:
                    message.flowDocumentRevisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            flowDocumentRevisionId: isSet(object.flowDocumentRevisionId)
                ? String(object.flowDocumentRevisionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.flowDocumentRevisionId !== undefined &&
            (obj.flowDocumentRevisionId = message.flowDocumentRevisionId);
        return obj;
    },
};
function createBaseDeployReactFlowDocumentInput() {
    return {
        businessId: "",
        flowDocumentId: "",
        clientRequestId: "",
        flowDocumentVersionId: "",
        flowDocumentRevisionId: "",
    };
}
export const DeployReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRequestId !== "") {
            writer.uint32(26).string(message.clientRequestId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(34).string(message.flowDocumentVersionId);
        }
        if (message.flowDocumentRevisionId !== "") {
            writer.uint32(42).string(message.flowDocumentRevisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeployReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRequestId = reader.string();
                    break;
                case 4:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 5:
                    message.flowDocumentRevisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            flowDocumentRevisionId: isSet(object.flowDocumentRevisionId)
                ? String(object.flowDocumentRevisionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.flowDocumentRevisionId !== undefined &&
            (obj.flowDocumentRevisionId = message.flowDocumentRevisionId);
        return obj;
    },
};
function createBaseSubmitReactFlowDocumentInput() {
    return { businessId: "", flowDocumentId: "", clientRequestId: "" };
}
export const SubmitReactFlowDocumentInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.clientRequestId !== "") {
            writer.uint32(26).string(message.clientRequestId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitReactFlowDocumentInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        return obj;
    },
};
function createBaseSubmitReactFlowDocumentResult() {
    return {
        clientRequestId: "",
        flowDocumentId: "",
        status: 0,
        message: "",
        progress: undefined,
        error: undefined,
    };
}
export const SubmitReactFlowDocumentResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientRequestId !== "") {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        if (message.status !== 0) {
            writer.uint32(24).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(34).string(message.message);
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(42).fork()).ldelim();
        }
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitReactFlowDocumentResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                case 3:
                    message.status = reader.int32();
                    break;
                case 4:
                    message.message = reader.string();
                    break;
                case 5:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        return obj;
    },
};
function createBaseVerifyReactFlowDocumentResult() {
    return {
        clientRequestId: "",
        revisionId: "",
        flowDocumentId: "",
        status: 0,
        type: 0,
        mode: 0,
        progress: undefined,
        items: [],
        dependencies: [],
    };
}
export const VerifyReactFlowDocumentResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientRequestId !== "") {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.revisionId !== "") {
            writer.uint32(18).string(message.revisionId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(26).string(message.flowDocumentId);
        }
        if (message.status !== 0) {
            writer.uint32(32).int32(message.status);
        }
        if (message.type !== 0) {
            writer.uint32(40).int32(message.type);
        }
        if (message.mode !== 0) {
            writer.uint32(48).int32(message.mode);
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.items) {
            Diagnostic.encode(v, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.dependencies) {
            Dependency.encode(v, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyReactFlowDocumentResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.revisionId = reader.string();
                    break;
                case 3:
                    message.flowDocumentId = reader.string();
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.mode = reader.int32();
                    break;
                case 7:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.items.push(Diagnostic.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.dependencies.push(Dependency.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            status: isSet(object.status)
                ? verificationStatusFromJSON(object.status)
                : 0,
            type: isSet(object.type) ? verificationTypeFromJSON(object.type) : 0,
            mode: isSet(object.mode) ? modeFromJSON(object.mode) : 0,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => Diagnostic.fromJSON(e))
                : [],
            dependencies: Array.isArray(object === null || object === void 0 ? void 0 : object.dependencies)
                ? object.dependencies.map((e) => Dependency.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.status !== undefined &&
            (obj.status = verificationStatusToJSON(message.status));
        message.type !== undefined &&
            (obj.type = verificationTypeToJSON(message.type));
        message.mode !== undefined && (obj.mode = modeToJSON(message.mode));
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        if (message.items) {
            obj.items = message.items.map((e) => e ? Diagnostic.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        if (message.dependencies) {
            obj.dependencies = message.dependencies.map((e) => e ? Dependency.toJSON(e) : undefined);
        }
        else {
            obj.dependencies = [];
        }
        return obj;
    },
};
function createBaseCompileReactFlowDocumentResult() {
    return {
        clientRequestId: "",
        revisionId: "",
        flowDocumentId: "",
        flowDocumentVersionId: "",
        status: 0,
        message: "",
        error: undefined,
        progress: undefined,
        items: [],
        dependencies: [],
    };
}
export const CompileReactFlowDocumentResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientRequestId !== "") {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.revisionId !== "") {
            writer.uint32(18).string(message.revisionId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(26).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(34).string(message.flowDocumentVersionId);
        }
        if (message.status !== 0) {
            writer.uint32(40).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(50).string(message.message);
        }
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(58).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.items) {
            Diagnostic.encode(v, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.dependencies) {
            Dependency.encode(v, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCompileReactFlowDocumentResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.revisionId = reader.string();
                    break;
                case 3:
                    message.flowDocumentId = reader.string();
                    break;
                case 4:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.message = reader.string();
                    break;
                case 7:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.items.push(Diagnostic.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.dependencies.push(Dependency.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => Diagnostic.fromJSON(e))
                : [],
            dependencies: Array.isArray(object === null || object === void 0 ? void 0 : object.dependencies)
                ? object.dependencies.map((e) => Dependency.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        if (message.items) {
            obj.items = message.items.map((e) => e ? Diagnostic.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        if (message.dependencies) {
            obj.dependencies = message.dependencies.map((e) => e ? Dependency.toJSON(e) : undefined);
        }
        else {
            obj.dependencies = [];
        }
        return obj;
    },
};
function createBaseDeployReactFlowDocumentResult() {
    return {
        clientRequestId: "",
        revisionId: "",
        flowDocumentId: "",
        flowDocumentVersionId: "",
        status: 0,
        message: "",
        error: undefined,
        progress: undefined,
        items: [],
        dependencies: [],
    };
}
export const DeployReactFlowDocumentResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientRequestId !== "") {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.revisionId !== "") {
            writer.uint32(18).string(message.revisionId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(26).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(34).string(message.flowDocumentVersionId);
        }
        if (message.status !== 0) {
            writer.uint32(40).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(50).string(message.message);
        }
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(58).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.items) {
            Diagnostic.encode(v, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.dependencies) {
            Dependency.encode(v, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeployReactFlowDocumentResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.revisionId = reader.string();
                    break;
                case 3:
                    message.flowDocumentId = reader.string();
                    break;
                case 4:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.message = reader.string();
                    break;
                case 7:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.items.push(Diagnostic.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.dependencies.push(Dependency.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientRequestId: isSet(object.clientRequestId)
                ? String(object.clientRequestId)
                : "",
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => Diagnostic.fromJSON(e))
                : [],
            dependencies: Array.isArray(object === null || object === void 0 ? void 0 : object.dependencies)
                ? object.dependencies.map((e) => Dependency.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientRequestId !== undefined &&
            (obj.clientRequestId = message.clientRequestId);
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        if (message.items) {
            obj.items = message.items.map((e) => e ? Diagnostic.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        if (message.dependencies) {
            obj.dependencies = message.dependencies.map((e) => e ? Dependency.toJSON(e) : undefined);
        }
        else {
            obj.dependencies = [];
        }
        return obj;
    },
};
function createBaseDeactivateReactFlowDocumentVersionInput() {
    return { businessId: "", flowDocumentId: "" };
}
export const DeactivateReactFlowDocumentVersionInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeactivateReactFlowDocumentVersionInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseResendCampaignInput() {
    return { businessId: "", flowDocumentId: "" };
}
export const ResendCampaignInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(18).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResendCampaignInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
