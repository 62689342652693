import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    zE: (arg1: string, arg2: string) => void | undefined;
  }
}

// zendesk widget is instantiated in public/index.html, this is a quick workaround to hide it on /inbox and /signup
export const useZendeskWidget = () => {
  const location = useLocation();
  useEffect(() => {
    if (typeof window.zE === 'undefined') return;

    window.zE(
      'messenger',
      location?.pathname.startsWith('/inbox') || location?.pathname.startsWith('/signup') ? 'hide' : 'show',
    );
  }, [location?.pathname]);
};
