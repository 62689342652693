import { createSlice } from '@reduxjs/toolkit';
import { ComposerSendBehavior, USER_EMPTY } from '../entities';
import { resetStore, setUser } from './action';
import { updateComposerSendBehavior } from './thunk';
const initialState = {
    user: USER_EMPTY,
};
export const userSlice = createSlice({
    name: '@chatroom/user',
    initialState,
    reducers: {
        startUserMonitor: (_state, _action) => { },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setUser, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(updateComposerSendBehavior.fulfilled, (_state, _action) => { });
    },
});
export const { reset, startUserMonitor } = userSlice.actions;
export const selectSendBehavior = (state) => {
    var _a;
    return (((_a = state.userSlice.user.settings) === null || _a === void 0 ? void 0 : _a.clickToSend)
        || ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED);
};
export const selectUser = (state) => state.userSlice.user.id;
export default userSlice.reducer;
