import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router';
const AuthProvider = ({ domain, clientId, redirectUri, audience, children, }) => {
    const history = useHistory();
    const location = useLocation();
    const onRedirectCallback = (appState) => {
        history.push((appState === null || appState === void 0 ? void 0 : appState.returnTo) || location.pathname);
    };
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, redirectUri: redirectUri, onRedirectCallback: onRedirectCallback, audience: audience, children: children }));
};
export default AuthProvider;
