import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { BusinessChannelMetaResponse, BusinessChannelMetaUrlParameters } from './types';

export const useBusinessChannelMetaData = <TData = BusinessChannelMetaResponse>({
  businessId,
  businessChannelId,
  ...options
}: Omit<
  UseQueryOptions<BusinessChannelMetaResponse, unknown, TData, readonly ['business-channel-meta']>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Omit<BusinessChannelMetaUrlParameters, 'channel.id'> & { businessChannelId: string }) =>
  useQuery(
    ['business-channel-meta'],
    () =>
      api.profileV1('/v1/businesses/{businessId}/fetch_metadata/channels/{channel.id}', 'get', {
        path: { 'channel.id': businessChannelId, businessId },
      }),
    options,
  );
