import React, { useMemo } from 'react';
import { Sentiment } from '@scenes/Sofia/types';
import {
  ConnectlyCard,
  EmojiEmotionsIcon,
  Label,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { selectSentimentAnalysis } from '../../selectors';
import { SENTIMENT_EMOJIS } from '../../constants';
import { useAnalysisDataInRanges } from '../../hooks';

type SentimentCardProps = {
  sentiment: Sentiment;
  count: number;
  percent: number;
  change: number | undefined;
};
const SentimentCard = ({ sentiment, count, percent, change }: SentimentCardProps) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const display = useMemo(() => {
    let changeNode: React.ReactNode = null;
    let changeColor = theme.palette.grey[500];
    if (change) {
      if (['very positive', 'positive'].includes(sentiment)) {
        changeColor = change > 0 ? theme.palette.success.main : theme.palette.error.main;
      }
      if (sentiment === 'negative') {
        changeColor = change > 0 ? theme.palette.error.main : theme.palette.success.main;
      }
      const iconStyles = {
        width: theme.spacing(isLg ? 2.5 : 2),
        height: theme.spacing(isLg ? 2.5 : 2),
        color: changeColor,
        mr: 0.5,
      };
      changeNode = change > 0 ? <TrendingUpIcon sx={iconStyles} /> : <TrendingDownIcon sx={iconStyles} />;
      const changeText = `${change > 0 ? '+' : ''}${Math.round(change)}% ${t('changePeriod', 'from last period')}`;
      changeNode = (
        <Stack direction="row" alignItems="center">
          {changeNode}
          <Label variant="body1" color="text.secondary">
            {changeText}
          </Label>
        </Stack>
      );
    }
    return {
      title: `${Math.round(percent)}% ${t(`sentimentAnalysis.sentiment.${sentiment}.title`, sentiment)}`,
      change: changeNode,
      count: `${count} ${t('sentimentAnalysis.sessions', 'Sessions')}`,
    };
  }, [theme, change, percent, t, sentiment, count, isLg]);

  return (
    <Stack
      gap={SIZE_SPACING_INTER_COMPONENT}
      alignItems="center"
      py={SIZE_SPACING_INTER_COMPONENT}
      sx={{ flex: isMd ? '0 0 25%' : '0 0 50%', boxSizing: 'border-box' }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          border: `1px solid ${theme.palette.grey[400]}`,
          width: theme.spacing(6.5),
          height: theme.spacing(6.5),
          borderRadius: '50%',
          fontSize: theme.typography.h3.fontSize,
          lineHeight: theme.typography.h3.lineHeight,
          opacity: count === 0 && change === 0 ? 0.5 : 1,
        }}
      >
        {SENTIMENT_EMOJIS[sentiment]}
      </Stack>
      <Label variant="body1" sx={{ fontWeight: 'bold !important' }}>
        {display.title}
      </Label>
      <Label variant="body1" color="text.secondary">
        {display.count}
      </Label>
      {display.change}
    </Stack>
  );
};

const useSentimentAnalysis = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics.sentimentAnalysis' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('title', 'Sentiment Analysis'),
      subtitle: t('subtitle', 'View the sentiment score of your customer interactions.'),
    };
  }, [t]);
  const theme = useTheme();
  const analyticsData = useAnalysisDataInRanges({});
  const data = useMemo(() => {
    return selectSentimentAnalysis(analyticsData);
  }, [analyticsData]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return {
    theme,
    title,
    subtitle,
    data,
    isMd,
  };
};

export const SentimentAnalysis = () => {
  const { theme, title, subtitle, data, isMd } = useSentimentAnalysis();
  return (
    <ConnectlyCard
      sx={{ flexDirection: 'column', userSelect: 'none', flex: '0 0 auto' }}
      icon={<EmojiEmotionsIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />}
      title={title}
      subtitle={subtitle}
      isNew
      contentSx={{ flexWrap: isMd ? 'nowrap' : 'wrap', flexDirection: 'row' }}
    >
      <SentimentCard
        sentiment="very positive"
        count={data['very positive'].count}
        percent={data['very positive'].percent}
        change={data['very positive'].change}
      />
      <SentimentCard
        sentiment="positive"
        count={data.positive.count}
        percent={data.positive.percent}
        change={data.positive.change}
      />
      <SentimentCard
        sentiment="mixed"
        count={data.mixed.count}
        percent={data.mixed.percent}
        change={data.mixed.change}
      />
      <SentimentCard
        sentiment="negative"
        count={data.negative.count}
        percent={data.negative.percent}
        change={data.negative.change}
      />
    </ConnectlyCard>
  );
};
