import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { filter, map } from 'rxjs/operators';
import { DataResponse } from '@connectlyai-tenets/data';
import { DataContext } from '../../contexts';
import { Business } from './types';

export const useBusinessData = ({ businessId }: { businessId: string | undefined }) => {
  const [business, setBusiness] = useState<Business | null>(null);
  const businessDataStoreProvider = useContextSelector(DataContext, (context) => context.businessDataStoreProvider);

  useEffect(() => {
    if (!businessId) {
      return () => {};
    }
    const businessDataStore = businessDataStoreProvider();
    const subscription = businessDataStore
      .subscribeBusiness(businessId)
      .pipe(
        filter((payload: DataResponse<Business>) => payload.isDataReady()),
        map((payload: DataResponse<Business>) => payload.requireData()),
      )
      .subscribe((payload: Business) => {
        setBusiness(payload);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [businessDataStoreProvider, businessId]);

  return {
    business,
  };
};
