import React, { FC, useCallback, useEffect } from 'react';
import { Box, Label, useTheme, CircularProgress, LoadingGif } from '@connectlyai-tenets/ui-styled-web';
import { useAtom, useSetAtom } from 'jotai';
import {
  documentTypeAtom,
  documentIdAtom,
  getStartedCategoryAtom,
  campaignStepAtom,
  starterAtom,
  isAISidebarOpenAtom,
  campaignTemplateAtom,
  campaignNameAtom,
} from '@atoms/flow';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { track } from 'src/utils';
import {
  FlowChartGetStartedCampaignCard,
  FlowChartGetStartedCustomCampaignCard,
  FlowChartGetStartedSidebar,
  FlowChartHeader,
  FlowChartSidebarPlaceholder,
} from '../../..';
import { CampaignTemplateType } from '../../../../hooks/useAICampaign/types';
import { useFlowNavigator } from '../../../../hooks';
import { ChooseCampaign } from '../ChooseCampaign';
import { useGenerateCampaignName } from '../../hooks/useGenerateCampaignName';

const useGetStarted = () => {
  const [category, setCategory] = useAtom(getStartedCategoryAtom);
  const setDocumentType = useSetAtom(documentTypeAtom);
  const setDocumentId = useSetAtom(documentIdAtom);
  const setCampaignStep = useSetAtom(campaignStepAtom);
  const setCampaignName = useSetAtom(campaignNameAtom);
  const setStarter = useSetAtom(starterAtom);
  const setIsAISidebarOpen = useSetAtom(isAISidebarOpenAtom);
  const setCampaignTemplate = useSetAtom(campaignTemplateAtom);

  useEffect(() => {
    setIsAISidebarOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { flowDocumentIdRouteMatch, navigateToFlowHome } = useFlowNavigator();
  useEffect(() => {
    if (flowDocumentIdRouteMatch?.params?.flowDocumentId) {
      setCategory('resend-campaign');
    }
  }, [flowDocumentIdRouteMatch, setCategory]);

  const { generateCampaignName, isLoadingCampaigns } = useGenerateCampaignName();

  const handleChooseCampaignTemplate = useCallback(
    (campaignTemplate: CampaignTemplateType) => {
      const name = generateCampaignName('AI Campaign');
      track('clicks choose_campaign_template', { templateName: campaignTemplate });
      setDocumentType('FLOW_DOCUMENT_TYPE_SENDOUT_V3');
      setCampaignName(name);
      setCampaignStep('build');
      setDocumentId('');
      setStarter('template');
      setIsAISidebarOpen(true);
      setCampaignTemplate(campaignTemplate);
    },
    [
      generateCampaignName,
      setCampaignName,
      setCampaignStep,
      setCampaignTemplate,
      setDocumentId,
      setDocumentType,
      setIsAISidebarOpen,
      setStarter,
    ],
  );

  const { ffEnableCampaignPresets } = useFeatureFlag(['ffEnableCampaignPresets']);

  const handleChooseEmptyCampaign = useCallback(() => {
    if (ffEnableCampaignPresets) {
      setCategory('campaign-presets');
      return;
    }
    const name = generateCampaignName(`Campaign`);
    track('clicks choose_campaign_template', { templateName: 'empty' });
    setCampaignName(name);
    setDocumentType('FLOW_DOCUMENT_TYPE_SENDOUT_V3');
    setCampaignStep('build');
    setDocumentId('');
    setStarter('empty');
  }, [
    ffEnableCampaignPresets,
    generateCampaignName,
    setCampaignName,
    setCampaignStep,
    setDocumentType,
    setDocumentId,
    setCategory,
    setStarter,
  ]);

  const handleChooseNewCampaign = useCallback(() => {
    if (flowDocumentIdRouteMatch) navigateToFlowHome();
    setCategory('new-campaign');
  }, [flowDocumentIdRouteMatch, navigateToFlowHome, setCategory]);
  const handleChooseResendCampaign = useCallback(() => {
    setCategory('resend-campaign');
  }, [setCategory]);
  const handleChooseAutomation = useCallback(() => {
    setCategory('automation');
  }, [setCategory]);

  return {
    category,
    handleChooseCampaignTemplate,
    handleChooseEmptyCampaign,
    handleChooseNewCampaign,
    handleChooseResendCampaign,
    handleChooseAutomation,
    isLoadingCampaigns,
  };
};

export const GetStarted: FC = () => {
  const {
    category,
    handleChooseCampaignTemplate,
    handleChooseEmptyCampaign,
    handleChooseNewCampaign,
    handleChooseResendCampaign,
    handleChooseAutomation,
    isLoadingCampaigns,
  } = useGetStarted();
  const theme = useTheme();
  const { ffEnableWebpageToProduct } = useFeatureFlag(['ffEnableWebpageToProduct']);

  return (
    <>
      <FlowChartHeader>
        <Label variant="h6">Get Started</Label>
      </FlowChartHeader>
      <Box
        sx={{
          background: theme.palette.background.default,
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <FlowChartSidebarPlaceholder>
          <FlowChartGetStartedSidebar
            selectedCategory={category}
            onCampaignSelected={handleChooseNewCampaign}
            onResendCampaignSelected={handleChooseResendCampaign}
            onAutomationSelected={handleChooseAutomation}
          />
        </FlowChartSidebarPlaceholder>
        {category === 'new-campaign' && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              maxHeight: `calc(100vh - ${theme.spacing(22)})`,
              overflowY: 'scroll',
              p: 3,
              width: '100%',
            }}
          >
            {isLoadingCampaigns && <LoadingGif sx={{ alignSelf: 'center' }} />}
            {!isLoadingCampaigns && (
              <Box
                sx={{
                  alignContent: 'flex-start',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 3,
                  maxWidth: theme.spacing(120),
                  width: '100%',
                }}
              >
                <FlowChartGetStartedCustomCampaignCard handleChooseEmptyCampaign={handleChooseEmptyCampaign} />
                {ffEnableWebpageToProduct && (
                  <FlowChartGetStartedCampaignCard
                    // eslint-disable-next-line max-len
                    exampleText="👋 Hi Joe, get ready to look stylish with Sunny's Glasses! Our glasses are perfect for any occasion..."
                    image="/assets/campaign-templates/product-promotion.png"
                    subtitle="Create a campaign only using your product webpage"
                    tags={['Marketing']}
                    title="Webpage to Product"
                    titleAlt="Página web al producto"
                    handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('webpageToProduct')}
                  />
                )}
                <FlowChartGetStartedCampaignCard
                  // eslint-disable-next-line max-len
                  exampleText="👋 Hi Joe, get ready to look stylish with Sunny's Glasses! Our glasses are perfect for any occasion..."
                  image="/assets/campaign-templates/product-promotion.png"
                  subtitle="Create a two-way marketing campaign to promote your products or services."
                  tags={['Marketing']}
                  title="Product Promotion"
                  titleAlt="Promoción  |  Promoção"
                  handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('productPromotion')}
                />
                <FlowChartGetStartedCampaignCard
                  // eslint-disable-next-line max-len
                  exampleText="🥳 Hi Mark, check out Sunny's Glasses for our Summer Sales! Get up to 20% off on sunglasses..."
                  image="/assets/campaign-templates/sales-event.png"
                  subtitle="Encourage your customers to participate in an upcoming sales event."
                  tags={['Marketing']}
                  title="Sales event"
                  titleAlt="Evento de ventas  |  Evento de vendas"
                  handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('salesEvent')}
                />
                <FlowChartGetStartedCampaignCard
                  // eslint-disable-next-line max-len
                  exampleText="Hello Sarah! 👋 This is the official channel for Sunny's Glasses. We'll be sending exclusive deals..."
                  image="/assets/campaign-templates/welcome-series.png"
                  // eslint-disable-next-line max-len
                  subtitle="Welcome your new customers and ask them if they want to opt in for future marketing messages."
                  tags={['General']}
                  title="Welcome Series"
                  titleAlt="Serie de Bienvenida  |  Série de boas-vindas"
                  handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('welcomeSeries')}
                />
                <FlowChartGetStartedCampaignCard
                  // eslint-disable-next-line max-len
                  exampleText="🧠 Test your knowledge and get a 15% discount code for Sunny's Glasses! Answer 3 quiz questions..."
                  image="/assets/campaign-templates/promotion-with-quiz.png"
                  subtitle="Reward your customers with a discount code if they can pass your quiz."
                  tags={['Marketing']}
                  title="Promotion With Quiz"
                  titleAlt="Cuestionario de promoción  |  Questionário de promoções"
                  handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('promotionWithQuiz')}
                />
              </Box>
            )}
          </Box>
        )}
        {category === 'resend-campaign' && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <ChooseCampaign />
          </Box>
        )}
        {category === 'automation' && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              maxHeight: `calc(100vh - ${theme.spacing(22)})`,
              overflowY: 'scroll',
              px: 8,
              py: 4.5,
              width: '100%',
            }}
          />
        )}
      </Box>
    </>
  );
};
