import { filter, map, tap } from 'rxjs/operators';
import { unreachable } from '@connectlyai-tenets/static-analysis';
export const subscriptionDtoUpdateBusinessProvider = (log, accessor) => () => (accessor.subscriptionDtoUpdate().pipe(filter((dtoUpdate) => {
    var _a;
    return (((_a = dtoUpdate.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'businessDto');
}), map((dtoUpdate) => {
    var _a;
    if (((_a = dtoUpdate.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) !== 'businessDto') {
        return unreachable();
    }
    return dtoUpdate.resultOneof.businessDto;
}), tap((businessDto) => {
    log.debug('subscriptionDtoUpdateBusiness@data', () => ({
        businessDto,
    }));
})));
