import { Paths } from '@connectlyai-tenets/sdk';
import { definitions } from '@connectlyai-tenets/sdk/src/schemes/signupV1.generated';

export const SIGNUP_URL = '/v1/signup/start';

type SignupURL = typeof SIGNUP_URL;

export type SignupParams = Paths['signupV1'][SignupURL]['post']['parameters']['body']['input'];

export type SignupResponse = Paths['signupV1'][SignupURL]['post']['responses']['200']['schema'];

export type InboxExperience = definitions['inboxInboxExperience'];
