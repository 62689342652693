import { AppSidebarItem } from './types';

// do first parts of pathnames match
export const isPathShallowMatch = (path1: string, path2: string) => {
  if (!path1.startsWith('/') || !path2.startsWith('/')) {
    return false;
  }
  if (path1.split('/')[1].toLocaleLowerCase() === path2.split('/')[1].toLocaleLowerCase()) {
    return true;
  }
  return false;
};

export const isMenuItemVisible = (item: AppSidebarItem) => !item.hidden;
