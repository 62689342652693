import { unwrapResult } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DataOrigin, DataResponse } from '@connectlyai-tenets/data';
import { LoadingState } from '@connectlyai-sdks/loading';
import { buildDIContainer } from './di';
import storeProvider from './state/store';
import { setUser } from './state/userSlice';
import { fetchUser, updateInboxSettings, updateProfile } from './state/thunk';
function maybeEmitState(state) {
    if (state.status === 'succeeded') {
        const val = state.user;
        return DataResponse.data(val, DataOrigin.SourceOfTruth);
    }
    return null;
}
export default class RealUserDataStore {
    constructor(deps) {
        this.di = buildDIContainer(deps);
        this.loggedInUserSubject = new Subject();
        this.userStateSubject = new Subject();
        this.store = storeProvider();
        this.store.subscribe(() => {
            const newState = this.store.getState().loggedInUser;
            const maybeEmission = maybeEmitState(newState);
            if (maybeEmission) {
                this.loggedInUserSubject.next(maybeEmission);
            }
            if (newState.loading !== LoadingState.UNINITIALIZED) {
                this.userStateSubject.next(newState);
            }
        });
    }
    setUser(user) {
        this.store.dispatch(setUser(user));
    }
    latestLoggedInUser() {
        return this.store.getState().loggedInUser.user;
    }
    userState() {
        return this.userStateSubject;
    }
    loggedInUser() {
        this.store.dispatch(fetchUser(this.di));
        const currentState = this.store.getState().loggedInUser;
        const maybeEmission = maybeEmitState(currentState);
        const startValue = maybeEmission || DataResponse.loading();
        return this.loggedInUserSubject.pipe(startWith(startValue));
    }
    mutationUpdateComposerSendBehavior(input) {
        return this.store
            .dispatch(updateInboxSettings({
            di: this.di,
            input: {
                inboxSettingsInputOneof: {
                    $case: 'v1',
                    v1: {
                        composerSendBehavior: input.sendBehavior,
                    },
                },
            },
        }))
            .then(unwrapResult)
            .then((_payload) => {
        });
    }
    mutationUpdateUserProfile(input) {
        return this.store
            .dispatch(updateProfile({
            di: this.di,
            input,
        }))
            .then(unwrapResult);
    }
}
