/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useMemo, useState } from 'react';
import {
  Label,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableSortLabel,
  useTheme,
  Box,
} from '@connectlyai-tenets/ui-styled-web';
import { applyHook } from '../../utils/applyHook';
import {
  selectBusinessId,
  generateUseSendoutsPlaceholderData,
  useMeData,
  selectSendouts,
  useSendoutsData,
  SendoutsQuery,
} from '../../hooks';
import { DIRECTION_DICTIONARY } from './constants';
import SkeletonRow from './SkeletonRow';
import CompletedRow from './CompletedRow';
import ScheduledRow from './ScheduledRow';
import ReadyToSendRow from './ReadyToSendRow';
import FailedRow from './FailedRow';
import DefaultRow from './DefaultRow';
import { RowProps, SendoutsTableProps } from './types';

const placeholderData = generateUseSendoutsPlaceholderData(10);

const useSendoutsTable = ({ states, search }: Partial<SendoutsTableProps>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = useState<SendoutsQuery['orderBy']>('CAMPAIGN_ORDER_UNSPECIFIED');
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: sendouts } = useSendoutsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    states,
    select: selectSendouts,
    placeholderData,
    search: { name: search },
    orderBy,
    keepPreviousData: true,
  });

  const rows = React.useMemo(() => sendouts || [], [sendouts]);

  const visibleRows = React.useMemo(() => {
    const paginated = rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;
    return paginated;
  }, [rows, page, rowsPerPage]);

  const rowsCount = rows.length;

  // Reset the paging and selection if a new filter is applied
  React.useEffect(() => {
    setPage(0);
  }, [search, orderBy, states]);

  const createSortHandler = (asc: SendoutsQuery['orderBy'], desc: SendoutsQuery['orderBy']) => () => {
    const isAsc = orderBy === asc;
    setOrderBy(isAsc ? desc : asc);
  };

  const isOnlyDrafts = useMemo(() => states?.length === 1 && states[0] === 'SENDOUT_STATE_DRAFT', [states]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    businessId,
    orderBy,
    isOnlyDrafts,
    rowsPerPage,
    page,
    rowsCount,
    visibleRows,
    createSortHandler,
    handleChangePage,
    handleRowsPerPageChange,
  };
};

const rowsDictionary: Array<{ condition: (props: RowProps) => boolean; component: FC<RowProps> }> = [
  { condition: ({ id }) => !!id?.includes('SKELETON'), component: SkeletonRow },
  { condition: ({ scheduledAt, completedAt }) => !!scheduledAt && !completedAt, component: ScheduledRow },
  {
    condition: ({ scheduledAt, completedAt, state }) => !scheduledAt && !completedAt && state !== 'SENDOUT_STATE_DRAFT',
    component: ReadyToSendRow,
  },
  { condition: ({ state }) => state === 'SENDOUT_STATE_COMPLETED', component: CompletedRow },
  { condition: () => true, component: DefaultRow },
  // temporary disabled, waiting for BE
  { condition: () => false, component: FailedRow },
];

export const SendoutsTableRepresentation: FC<SendoutsTableProps> = ({
  page,
  rowsCount,
  rowsPerPage,
  visibleRows,
  createSortHandler,
  orderBy,
  businessId,
  onSchedule,
  onUnschedule,
  onDelete,
  isOnlyDrafts,
  handleChangePage,
  handleRowsPerPageChange,
}) => {
  const theme = useTheme();
  if (!visibleRows?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          userSelect: 'none',
        }}
      >
        <Box
          sx={{
            fontSize: 96,
          }}
        >
          🕵️‍♂️
        </Box>
        <Label variant="body1">No sendouts</Label>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        overflowY: 'auto',
        minWidth: theme.breakpoints.values.md,
        height: '100%',
      }}
    >
      <Table stickyHeader sx={{ tableLayout: 'fixed', whiteSpace: 'nowrap', borderSpacing: theme.spacing(0, 1.5) }}>
        <TableHead>
          <TableRow>
            <TableCell width={350}>
              <TableSortLabel
                onClick={createSortHandler('CAMPAIGN_ORDER_NAME', 'CAMPAIGN_ORDER_NAME_DESC')}
                direction={DIRECTION_DICTIONARY[orderBy || 'CAMPAIGN_ORDER_NAME']}
              >
                <Label variant="body2">Sendouts</Label>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={createSortHandler('CAMPAIGN_ORDER_CREATED_AT', 'CAMPAIGN_ORDER_CREATED_AT_DESC')}
                direction={DIRECTION_DICTIONARY[orderBy || 'CAMPAIGN_ORDER_NAME']}
              >
                <Label variant="body2">Created Date</Label>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <Label variant="body2">Status</Label>
            </TableCell>
            {!isOnlyDrafts && (
              <TableCell>
                <Label variant="body2">Recipients</Label>
              </TableCell>
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows?.map((props) => {
            const Component = rowsDictionary.find(({ condition }) =>
              condition({ ...props, businessId, onSchedule, onUnschedule }),
            )?.component;
            if (Component) {
              return (
                <Component
                  key={props.id}
                  {...props}
                  onSchedule={onSchedule}
                  onUnschedule={onUnschedule}
                  onDelete={onDelete}
                  businessId={businessId}
                />
              );
            }
            return null;
          })}
        </TableBody>
        <TableFooter>
          <TablePagination
            sx={{
              '& .MuiToolbar-root': {
                px: 'calc(24px + 64px)', // the dimensions of a typical bottom-left overview item. e.g. zendesk
              },
            }}
            rowsPerPageOptions={[10, 25, 50]}
            colSpan={4} // SHOULD be the same as number of TableCell above
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            component="td"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableFooter>
      </Table>
    </Box>
  );
};

export const SendoutsTable = applyHook(SendoutsTableRepresentation, useSendoutsTable);
