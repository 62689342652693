import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DesktopDatePicker,
  TimePicker,
  TextField,
  styled,
  makeStyles,
  Label,
} from '@connectlyai-tenets/ui-styled-web';

const MIN_SCHEDULED_DELAY_MINUTES = 5;

const StaticTimePickerWrapper = styled.div`
  position: relative;
`;

const StaticDateTimePicker = styled.div`
  display: flex;
`;

const useStyles = makeStyles((_theme) => ({
  scheduleCampaignContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export enum DialogActionResult {
  DISMISS,
  PRIMARY,
}

function isSameDay(date1: Date | null | undefined, date2: Date | null | undefined): boolean {
  if (date1 === null || date1 === undefined) {
    return false;
  }
  if (date2 === null || date2 === undefined) {
    return false;
  }

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function useScheduleCampaignDialog(
  open: boolean,
): [
  minDate: Date | undefined,
  maxDate: Date | undefined,
  minTime: Date | undefined,
  maxTime: Date | undefined,
  scheduledAt: Date | null,
  onChange: (newValue: Date | null) => void,
] {
  const [minDate, maxDate] = useMemo(() => {
    if (!open) {
      return [undefined, undefined];
    }

    const min = new Date();
    min.setMinutes(min.getMinutes() + MIN_SCHEDULED_DELAY_MINUTES);
    min.setSeconds(0);

    const max = new Date();
    max.setMonth(max.getMonth() + 1);
    max.setMinutes(max.getMinutes() + MIN_SCHEDULED_DELAY_MINUTES);
    max.setSeconds(0);

    return [min, max];
  }, [open]);

  const [scheduledAt, setScheduledAt] = useState<Date | null>(minDate || null);
  useEffect(() => {
    if (open) {
      setScheduledAt(minDate || null);
    }
  }, [minDate, open]);

  const [minTime, maxTime] = useMemo(() => {
    if (!open) {
      return [undefined, undefined];
    }

    const min = isSameDay(scheduledAt, minDate) ? minDate : undefined;
    const max = isSameDay(scheduledAt, maxDate) ? maxDate : undefined;

    return [min, max];
  }, [open, scheduledAt, minDate, maxDate]);

  const onChange = useCallback(
    (newValue: Date | null) => {
      if (newValue !== null) {
        newValue = minDate !== undefined && newValue < minDate ? minDate : newValue;
        newValue = maxDate !== undefined && newValue > maxDate ? maxDate : newValue;
      }
      setScheduledAt(newValue);
    },
    [minDate, maxDate],
  );

  return [minDate, maxDate, minTime, maxTime, scheduledAt, onChange];
}

type ScheduleCampaignDialogProps = {
  open: boolean;
  onClose: (scheduledAt: Date | null, result: DialogActionResult) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customTextField = (props: any) => <TextField sx={{ '& fieldset': { borderRadius: '10px' } }} {...props} />;

const ScheduleCampaignDialog: FC<ScheduleCampaignDialogProps> = ({ open, onClose }: ScheduleCampaignDialogProps) => {
  const styles = useStyles();

  const [minDate, maxDate, minTime, maxTime, scheduledAt, onChange] = useScheduleCampaignDialog(open);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(scheduledAt, DialogActionResult.DISMISS)}
      aria-labelledby="schedule-campaign-dialog-title"
      aria-describedby="schedule-campaign-dialog-description"
      PaperProps={{
        style: { maxWidth: 'unset' },
      }}
    >
      <DialogTitle id="schedule-campaign-dialog-title">Schedule Campaign</DialogTitle>
      <DialogContent className={styles.scheduleCampaignContent}>
        <Label variant="subtitle1" color="text.secondary">
          Date & Time
        </Label>
        <Label variant="body1">{scheduledAt?.toLocaleString()}</Label>
        <StaticDateTimePicker>
          <DesktopDatePicker
            disablePast
            minDate={minDate}
            maxDate={maxDate}
            openTo="day"
            value={scheduledAt}
            views={['day']}
            onChange={onChange}
            slots={{
              textField: customTextField,
            }}
          />
          <StaticTimePickerWrapper>
            <TimePicker
              minTime={minTime}
              maxTime={maxTime}
              value={scheduledAt}
              onChange={onChange}
              orientation="portrait"
              slots={{
                textField: customTextField,
              }}
            />
          </StaticTimePickerWrapper>
        </StaticDateTimePicker>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onClose(scheduledAt, DialogActionResult.DISMISS)} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onClose(scheduledAt, DialogActionResult.PRIMARY)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleCampaignDialog;
