import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Label,
  Menu,
  MenuItem,
  PhoneEnabledIcon,
  ReplyIcon,
  useMediaQuery,
  useTheme,
  LinkIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { useFeatureFlag } from '@hooks';
import { useTranslation } from 'react-i18next';
import { AddButtonProps } from './types';
import { ButtonGroup } from '../../../state/messageTemplates';
import {
  MAX_ALLOWED_BUTTONS,
  MAX_ALLOWED_QUICK_REPLY_BUTTONS,
  MAX_ALLOWED_URL_BUTTONS,
  MAX_ALLOWED_PHONE_BUTTONS,
} from './constants';

export const AddButton: FC<AddButtonProps> = ({ buttons, group, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'addButton' });
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const enableNewTemplateButtons = getFlagByName('ffEnableNewTemplateButtons') || ffEnableETA;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAddQuickReply = useCallback(() => {
    setAnchorEl(null);
    onClick('QUICK_REPLY');
  }, [onClick]);

  const handleAddPhoneNumber = useCallback(() => {
    setAnchorEl(null);
    onClick('PHONE_NUMBER');
  }, [onClick]);

  const handleAddLink = useCallback(() => {
    setAnchorEl(null);
    onClick('URL');
  }, [onClick]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!enableNewTemplateButtons && group === ButtonGroup.QuickReply) {
        handleAddQuickReply();
        return;
      }

      setAnchorEl(event.currentTarget);
    },
    [enableNewTemplateButtons, group, handleAddQuickReply],
  );

  const displayQuickReply = enableNewTemplateButtons || group === ButtonGroup.QuickReply;
  const displayCallToAction = enableNewTemplateButtons || group === ButtonGroup.CallToAction;

  const disableAll = useMemo(() => buttons.length >= MAX_ALLOWED_BUTTONS, [buttons]);

  const disableQuickReply = useMemo(
    () => disableAll || buttons.filter((x) => x.button?.quickReply).length >= MAX_ALLOWED_QUICK_REPLY_BUTTONS,
    [buttons, disableAll],
  );
  const disableURL = useMemo(
    () => disableAll || buttons.filter((x) => x.button?.url).length >= MAX_ALLOWED_URL_BUTTONS,
    [buttons, disableAll],
  );
  const disablePhone = useMemo(
    () => disableAll || buttons.filter((x) => x.button?.phoneNumber).length >= MAX_ALLOWED_PHONE_BUTTONS,
    [buttons, disableAll],
  );

  return (
    <Box sx={{ mt: isLargeScreen ? 2 : 1 }}>
      <Button
        id="add-template-button"
        color="secondary"
        variant="outlined"
        onClick={handleClick}
        sx={{ py: 0.5, px: 1, alignSelf: 'flex-start' }}
      >
        {t('title')}
      </Button>
      <Menu
        id="add-template-button-menu"
        MenuListProps={{
          'aria-labelledby': 'add-template-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiMenu-paper': { minWidth: '264px' } }}
      >
        {displayQuickReply && (
          <MenuItem disabled={disableQuickReply} onClick={handleAddQuickReply}>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <ReplyIcon color="action" />
              <Label variant="body1">{t('optionQuickReply')}</Label>
            </Box>
          </MenuItem>
        )}
        {displayQuickReply && displayCallToAction && <Divider />}
        {displayCallToAction && (
          <MenuItem disabled={disableURL} onClick={handleAddLink}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <LinkIcon color="action" />
                <Label variant="body1">{t('optionUrl')}</Label>
              </Box>
              <Label variant="body2" color="text.secondary">
                {t('max', { value: 2 })}
              </Label>
            </Box>
          </MenuItem>
        )}
        {displayCallToAction && (
          <MenuItem disabled={disablePhone} onClick={handleAddPhoneNumber}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <PhoneEnabledIcon color="action" />
                <Label variant="body1">{t('optionPhone')}</Label>
              </Box>
              <Label variant="body2" color="text.secondary">
                {t('max', { value: 1 })}
              </Label>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
