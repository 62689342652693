import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SignupEmailResendResponse, SignupEmailResendParams, SIGNUP_EMAIL_RESEND_URL } from './types';

export const useSignupEmailResend = (
  options?: Omit<
    UseMutationOptions<SignupEmailResendResponse, ConnectlyError, SignupEmailResendParams, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ ...body }: SignupEmailResendParams) =>
      api.signupV1(SIGNUP_EMAIL_RESEND_URL, 'post', {
        body: { body },
      }) as Promise<SignupEmailResendResponse>,
    options,
  );
