const APP_TITLE = 'Connectly Inbox';
const DEFAULT_ICON = 'favicon.ico';
const DEFAULT_DARK_ICON = 'favicon.dark.ico';
const UNREAD_ICON = 'favicon_unread.ico';
const UNREAD_DARK_ICON = 'favicon_unread.dark.ico';
const ANIMATION_DURATION_MS = 1000;

function getIconUrl(icon: string): string {
  return `${process.env.PUBLIC_URL}/${icon}`;
}

function toDefaultState(isDark: boolean): void {
  const { document } = window;
  if (!document) return;

  document.title = APP_TITLE;

  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (!favicon) return;

  favicon.href = getIconUrl(isDark ? DEFAULT_DARK_ICON : DEFAULT_ICON);
}

function toUnreadState(count: number, isDark: boolean): void {
  const { document } = window;
  if (!document) return;

  if (count > 0) {
    document.title = `(${count}) ${APP_TITLE}`;

    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (!favicon) return;

    favicon.href = getIconUrl(isDark ? UNREAD_DARK_ICON : UNREAD_ICON);
  }
}

function updateIcon(isDark: boolean): void {
  const { document } = window;
  if (!document) return;

  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (!favicon) return;

  const { href } = favicon;

  if (isDark && href.endsWith(getIconUrl(DEFAULT_ICON))) {
    favicon.href = getIconUrl(DEFAULT_DARK_ICON);
  } else if (isDark && href.endsWith(getIconUrl(UNREAD_ICON))) {
    favicon.href = getIconUrl(UNREAD_DARK_ICON);
  } else if (!isDark && href.endsWith(getIconUrl(DEFAULT_DARK_ICON))) {
    favicon.href = getIconUrl(DEFAULT_ICON);
  } else if (!isDark && href.endsWith(getIconUrl(UNREAD_DARK_ICON))) {
    favicon.href = getIconUrl(UNREAD_ICON);
  }
}

export interface Notifications {
  update(): void;
  clear(): void;
}

export default class TabNotifications implements Notifications {
  private count = 0;

  private timer: NodeJS.Timeout | 0 = 0;

  private isDark = false;

  constructor() {
    window.onfocus = () => {
      this.clear();
    };

    this.isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    updateIcon(this.isDark);
    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        this.isDark = e.matches;
        updateIcon(this.isDark);
      });
    }
  }

  update(): void {
    const { document } = window;
    if (document.hasFocus()) {
      return;
    }

    const { timer } = this;

    this.count += 1;

    if (document.title !== APP_TITLE) {
      toUnreadState(this.count, this.isDark);
    }

    if (timer) {
      return;
    }
    toUnreadState(this.count, this.isDark);
    this.timer = setInterval(() => {
      if (!document) {
        return;
      }

      if (document.title === APP_TITLE) {
        toUnreadState(this.count, this.isDark);
      } else {
        toDefaultState(this.isDark);
      }
    }, ANIMATION_DURATION_MS);
  }

  clear(): void {
    clearInterval(this.timer);
    this.timer = 0;

    this.count = 0;
    toDefaultState(this.isDark);
  }
}
