import { CustomerRouteId } from '@connectlyai-sdks/inbox-navigation';
import { Logger } from '@connectlyai-tenets/logging';
import {
  CloseRouteId,
  Navigator,
  Route,
  generatePath as pathImpl,
  RouteId,
  BackRouteId,
} from '@connectlyai-tenets/navigation';

export type NavigatorConfig = {
  id: Set<string>;
  instance: Navigator;
  setStateOpen: (state: boolean) => void;
};

function calculateMaybeRouterId(routeId: string): string {
  const checkRouteId = routeId.startsWith('/') ? routeId.slice(1) : routeId;
  const routeParts = checkRouteId.split('/');
  const result = `/${routeParts[0] || ''}`;
  return result;
}

export default class RealNavigator implements Navigator {
  private readonly logProvider: () => Logger;

  private readonly mainPaneNavigator: NavigatorConfig;

  private readonly sidePaneNavigator: NavigatorConfig;

  constructor(logProvider: () => Logger, mainPaneNavigator: NavigatorConfig, sidePaneNavigator: NavigatorConfig) {
    this.logProvider = logProvider;
    this.mainPaneNavigator = mainPaneNavigator;
    this.sidePaneNavigator = sidePaneNavigator;
  }

  private closeSidePane(fromRouter = '/main') {
    this.sidePaneNavigator.setStateOpen(false);
    this.sidePaneNavigator.instance.navigate({
      id: '/',
      params: {
        fromRouter,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  generateGlobalRouteId(to: RouteId): RouteId {
    return to;
  }

  // eslint-disable-next-line class-methods-use-this
  generateGlobalPath(to: Route): string {
    return pathImpl(to.id, to.params);
  }

  navigate(to: Route): void {
    const log = this.logProvider();
    log.debug('navigate@start', () => ({
      to,
    }));
    if (to.params.isExternal === 'true') {
      window.open(to.id, '_blank');
      return;
    }
    const fromRouterId = to.params.fromRouter.toString();
    const localToId = to.id;
    const maybeRouterId = calculateMaybeRouterId(localToId);
    const globalToId =
      localToId.startsWith(fromRouterId) ||
      this.sidePaneNavigator.id.has(fromRouterId) ||
      this.mainPaneNavigator.id.has(maybeRouterId) ||
      this.sidePaneNavigator.id.has(maybeRouterId)
        ? localToId
        : `${to.params.fromRouter}${to.id}`;
    const globalRoute = {
      ...to,
      id: globalToId,
    };
    if (localToId === CloseRouteId && this.sidePaneNavigator.id.has(fromRouterId)) {
      this.closeSidePane(fromRouterId);
      return;
    }
    if (localToId === CustomerRouteId) {
      this.sidePaneNavigator.setStateOpen(true);
      this.sidePaneNavigator.instance.navigate(to);
      return;
    }
    this.closeSidePane();
    this.mainPaneNavigator.setStateOpen(false);
    if (localToId === BackRouteId) {
      this.mainPaneNavigator.instance.navigate(to);
      return;
    }
    this.mainPaneNavigator.instance.navigate(globalRoute);
  }
}
