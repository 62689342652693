import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QueryAgentsBodyParams, QueryAgentsPathParams, QueryAgentsData } from './types';

export const fetchQueryAgents = async (
  pathParams: QueryAgentsPathParams,
  bodyParams: QueryAgentsBodyParams,
): Promise<QueryAgentsData> => {
  const endpoint = '/internal/v1/businesses/{query.businessId}/query_agents';
  if (!pathParams['query.businessId']) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  return api.analyticsV1(endpoint, 'post', {
    path: pathParams,
    body: { query: bodyParams },
  }) as Promise<QueryAgentsData>;
};

export const useQueryAgents = (
  pathParams: QueryAgentsPathParams,
  bodyParams: QueryAgentsBodyParams,
  options?: UseQueryOptions<QueryAgentsData, Error>,
) => {
  return useQuery<QueryAgentsData, Error>(
    [
      'agentsMetrics',
      pathParams['query.businessId'],
      bodyParams.timeRangeStart,
      bodyParams.timeRangeEnd,
      bodyParams.orderBy,
    ],
    () => fetchQueryAgents(pathParams, bodyParams),
    options,
  );
};
