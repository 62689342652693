import React, { useMemo, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { selectedSmartAudienceAtom, cohortsGeneratingAtom } from '@atoms/audience';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  useMeData,
  selectBusinessId,
  selectMostEngagedSegmentOptions,
  useAudienceSegmentsData,
  selectLatestCohort,
  useAudienceSegmentCohorts,
  selectSegmentDisplayName,
  useStandardNotifications,
  useFeatureFlag,
} from '@hooks';
import {
  Button,
  Card,
  Divider,
  FavoriteIcon,
  Label,
  NewLabel,
  Stack,
  useTheme,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { SelectAudience } from '@components';
import { SIZE_SPACING_INTER_COMPONENT, SIZE_SPACING_INTER_SECTION } from '../../../../ui-theme';
import { CohortSize } from './CohortSize';
import { useMutationDeleteSegment } from '../../hooks';
import { selectQueryText } from './selectors';

const useSmartAudiences = () => {
  const { ffEnableDeleteSmartAudience } = useFeatureFlag(['ffEnableDeleteSmartAudience']);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: options, isLoading: isLoadingOptions } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectMostEngagedSegmentOptions, enabled: !!businessId },
  );
  const [selectedSegment, setSelectedSegment] = useAtom(selectedSmartAudienceAtom);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const selectedQuery = useMemo(() => {
    const segment = options?.find((item) => item?.id === selectedSegment);
    if (!segment) {
      return '';
    }
    const { queryView } = segment;
    if (!queryView) {
      return '';
    }
    return selectQueryText({ queryView });
  }, [options, selectedSegment]);
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useStandardNotifications({
    successMessage: 'Segment Deleted',
    errorMessage: 'Failed to delete segment',
  });
  const { mutate: mutateDeleteSegment, isLoading: deleteIsLoading } = useMutationDeleteSegment({
    onError: () => {
      notifyError();
    },
    onSuccess: () => {
      notifySuccess();
      queryClient.invalidateQueries(['audienceSegments']);
      setSelectedSegment('');
    },
  });
  const setCohortsGenerating = useSetAtom(cohortsGeneratingAtom);
  const { data: cohort, isLoading } = useAudienceSegmentCohorts(
    { businessId: businessId || '', segmentId: selectedSegment },
    {
      select: selectLatestCohort,
      enabled: Boolean(businessId && selectedSegment),
      refetchInterval: (fetchedData, _) => {
        if (fetchedData?.id && fetchedData?.segmentId) {
          setCohortsGenerating((prev: string[]) => prev.filter((id: string) => id !== fetchedData.segmentId));
          return false;
        }
        return 5000;
      },
    },
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { header, title, description, createAudienceText, sendCampaignText } = useMemo(() => {
    return {
      header: t('audience.manage.smartAudience.header'),
      title: t('audience.manage.smartAudience.audiences.engaged.title'),
      description: t('audience.manage.smartAudience.audiences.engaged.description'),
      createAudienceText: t('audience.manage.smartAudience.createAudience'),
      sendCampaignText: t('audience.manage.smartAudience.sendCampaign'),
    };
  }, [t]);
  const deleteAudience = useCallback(() => {
    if (selectedSegment && businessId) {
      mutateDeleteSegment({ businessId, segmentId: selectedSegment });
    }
  }, [businessId, mutateDeleteSegment, selectedSegment]);
  const theme = useTheme();
  return {
    businessId,
    closeModal,
    cohort,
    createAudienceText,
    deleteAudience,
    deleteIsLoading,
    description,
    ffEnableDeleteSmartAudience,
    header,
    isLoading,
    isLoadingOptions,
    modalOpen,
    openModal,
    options: options || [],
    selectedQuery,
    selectedSegment,
    sendCampaignText,
    theme,
    title,
  };
};
export const SmartAudiences = () => {
  const {
    businessId,
    cohort,
    createAudienceText,
    deleteAudience,
    deleteIsLoading,
    description,
    ffEnableDeleteSmartAudience,
    header,
    isLoading,
    isLoadingOptions,
    openModal,
    options,
    selectedQuery,
    selectedSegment,
    sendCampaignText,
    theme,
    title,
  } = useSmartAudiences();
  return options.length || isLoadingOptions ? (
    <Stack gap={SIZE_SPACING_INTER_SECTION}>
      <Stack direction="row" alignItems="center">
        <WithSkeleton wrap={isLoadingOptions}>
          <Label variant="h6">{header}</Label>
        </WithSkeleton>
        <WithSkeleton wrap={isLoadingOptions}>
          <NewLabel sx={{ backgroundColor: '#D0E7FF', ml: SIZE_SPACING_INTER_COMPONENT }} />
        </WithSkeleton>
      </Stack>
      <Card variant="main">
        <Stack width="100%" gap={2}>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <WithSkeleton wrap={isLoadingOptions}>
                <FavoriteIcon />
              </WithSkeleton>
              <WithSkeleton wrap={isLoadingOptions}>
                <Label variant="h6" sx={{ ml: SIZE_SPACING_INTER_COMPONENT }}>
                  {title}
                </Label>
              </WithSkeleton>
            </Stack>
          </Stack>
          <WithSkeleton wrap={isLoadingOptions}>
            <Label variant="body1" color="text.secondary">
              {description}
            </Label>
          </WithSkeleton>
          <Stack gap={SIZE_SPACING_INTER_COMPONENT}>
            <Divider sx={{ borderColor: theme.palette.grey[200] }} />
            <WithSkeleton wrap={isLoadingOptions}>
              <SelectAudience
                id="audience-page-select-audience"
                businessId={businessId || ''}
                inputPlaceholder="Smart Audience"
                getOptionLabel={selectSegmentDisplayName}
                audienceType="dynamicV2"
              />
            </WithSkeleton>
            <Stack direction="row" justifyContent="space-between">
              <WithSkeleton wrap={isLoadingOptions}>
                <Label variant="body1" color="text.secondary">
                  {selectedQuery}
                </Label>
              </WithSkeleton>
              {selectedSegment && (
                <WithSkeleton wrap={isLoadingOptions}>
                  <CohortSize
                    businessId={businessId}
                    segmentId={selectedSegment}
                    cohort={cohort}
                    isLoading={isLoading}
                  />
                </WithSkeleton>
              )}
            </Stack>
            <Divider sx={{ borderColor: theme.palette.grey[200] }} />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <WithSkeleton wrap={isLoadingOptions}>
              <Button variant="outlined" color="secondary" sx={{ width: 'fit-content' }} onClick={openModal}>
                {createAudienceText}
              </Button>
            </WithSkeleton>
            <WithSkeleton wrap={isLoadingOptions}>
              <Button variant="contained" color="primary" sx={{ width: 'fit-content' }}>
                {sendCampaignText}
              </Button>
            </WithSkeleton>
          </Stack>
          {ffEnableDeleteSmartAudience && selectedSegment && (
            <Button
              variant="contained"
              color="error"
              sx={{ width: 'fit-content' }}
              onClick={deleteAudience}
              disabled={deleteIsLoading || !selectedSegment}
            >
              Delete Audience
            </Button>
          )}
        </Stack>
      </Card>
    </Stack>
  ) : null;
};
