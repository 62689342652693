import React from 'react';
import {
  Box,
  Button,
  ConnectlyCard,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Label,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useAbandonedCart } from '@hooks/useAbandonedCart';
import { AbandonedCartActivation } from '@components/AbandonedCartActivation';
import { Carousel } from '@components/Carousel';
import { selectShopifyBusinessChannel, useBusinessChannelsData } from '@hooks';

import { track } from 'src/utils';
import { AbandonedCartMessagePreview } from './AbandonedCartMessagePreview';
import { AbandonedCartReminderPreview } from './AbandonedCartReminderPreview';
import { AbandonedCartBody } from './AbandonedCartBody';
import { SHOPIFY_INTEGRATION_TYPE } from './constants';

export const AbandonedCart = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart' });

  const {
    businessId,
    language,
    integrationId,
    discount,
    reminder,
    isDefaultMessage,
    status,
    firstMessage,
    secondMessage,
    discountAmount,
    deactivatePopupOpen,
    isActivated,
    isPersonalMessage,
    integrationParams,
    open,
    isDeactivating,
    onDeactivate,
    onOpen,
    onClose,
    onChangeDeactivatePopup,
  } = useAbandonedCart(SHOPIFY_INTEGRATION_TYPE);

  const { data: shopifyBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectShopifyBusinessChannel,
  });

  // Get template status
  const ActivationBody = (
    <>
      <Label variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
        {t('description')}
      </Label>
      <Button
        variant="contained"
        onClick={() => {
          track('(AC) Activate', { businessId });
          onOpen();
        }}
        disabled={isDeactivating}
      >
        {t('activateNow')}
      </Button>
      <Box
        sx={{
          display: 'flex',
          background: theme.palette.midnightIndigo,
          borderRadius: '10px',
          p: 2,
          gap: 3,
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{ width: '64px', height: '64px' }}
          src={`${process.env.PUBLIC_URL}/assets/welcome/ai_chat.png`}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Label sx={{ color: theme.palette.common.white, fontWeight: 500, fontSize: '22px' }}>
            {t('recoveredAmount')}
          </Label>
          <Label sx={{ color: theme.palette.common.white, fontWeight: 400, fontSize: '14px' }}>
            {t('recoveredHint')}
          </Label>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Dialog open={deactivatePopupOpen} onClose={() => onChangeDeactivatePopup(true)}>
        <DialogTitle>{t('deactivateAbandonedCart.deactivate')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-deactivate-description">{t('deactivateAbandonedCart.body')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onChangeDeactivatePopup(true)}>{t('deactivateAbandonedCart.cancel')}</Button>
          <Button onClick={onDeactivate}>{t('deactivateAbandonedCart.ok')}</Button>
        </DialogActions>
      </Dialog>
      <AbandonedCartActivation
        open={open}
        integrationParameters={integrationParams}
        reminder={reminder}
        firstMessageTemplate={firstMessage}
        secondMessageTemplate={secondMessage}
        integrationType={SHOPIFY_INTEGRATION_TYPE}
        onClose={onClose}
      />
      <ConnectlyCard
        media={{
          children: (
            <Box
              component="img"
              sx={{ width: '80px', height: '65px' }}
              src={`${process.env.PUBLIC_URL}/assets/welcome/cart_abandonment_workflow.png`}
            />
          ),
        }}
        title={t('title')}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          {language ? (
            <AbandonedCartBody
              language={language}
              integrationId={integrationId}
              discount={discount}
              status={status}
              discountAmount={discountAmount}
              isActivated={isActivated}
              isDeactivating={isDeactivating}
              businessChannelId={shopifyBusinessChannel?.id ?? ''}
              onOpen={onOpen}
              onChangeDeactivatePopup={onChangeDeactivatePopup}
            />
          ) : (
            ActivationBody
          )}
          {reminder !== undefined ? (
            <Carousel>
              <AbandonedCartMessagePreview
                discount={discount}
                language={language}
                isDefaultMessage={isDefaultMessage}
                isPersonalMessage={isPersonalMessage}
              />
              <AbandonedCartReminderPreview language={language} />
            </Carousel>
          ) : (
            <AbandonedCartMessagePreview
              discount={discount}
              language={language}
              isDefaultMessage={isDefaultMessage}
              isPersonalMessage={isPersonalMessage}
            />
          )}
        </Box>
      </ConnectlyCard>
    </>
  );
};
