import React from 'react';
import { Box, ConnectlyCard, ConnectlySection, Link } from '@connectlyai-tenets/ui-styled-web';
import { DataTable } from '@scenes/AccountOverview/components';
import { AnalyticsControls } from '@scenes/AccountOverview';
import { useFeatureFlag } from '@hooks';
import { useTranslation, Trans } from 'react-i18next';
import { WHATSAPP_PRICING_LINK } from '@scenes/AccountOverview/constants';

const HeaderActions = () => {
  return (
    <Box
      sx={{
        pt: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 3,
      }}
    >
      <AnalyticsControls />
    </Box>
  );
};

const LinkText = ({ href, children }: { href: string; children?: string }) => {
  return (
    <Link href={href} target="_blank">
      {children}
    </Link>
  );
};

// Used with redesigned navigation system
export const AccountOverview = () => {
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);
  const Section = ffEnableAnalyticsRedesign ? ConnectlySection : ConnectlyCard;

  const { t } = useTranslation('translation', { keyPrefix: 'analytics.accountOverview' });
  const description = () => (
    <Trans
      i18nKey="description"
      defaults="Conversations are message threads between you and your customers. Conversations can 
      last up to 24-hours and must be initiated by you (business-initiated) or your customers (user-initiated). 
      Conversation costs vary based on recipients locations"
      t={t}
      components={[<LinkText key="description" href={WHATSAPP_PRICING_LINK} />]}
    />
  );

  return (
    <>
      <HeaderActions />
      <Section
        title={ffEnableAnalyticsRedesign ? 'Conversations' : ''}
        subtitle={ffEnableAnalyticsRedesign ? description() : ''}
      >
        <DataTable />
      </Section>
    </>
  );
};
