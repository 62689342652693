import React from 'react';
import { Avatar, Box, Label } from '@connectlyai-tenets/ui-styled-web';
import { CaptionLabel } from '../../../../../../presentation/common/typography';

type ContentCardHeaderProps = {
  step: number;
  title: string;
  subtitle?: string;
};

export const ContentCardHeader = ({ step, title, subtitle }: ContentCardHeaderProps) => (
  <Box>
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}>
      <Avatar
        sx={{
          width: (theme) => theme.spacing(2.5),
          height: (theme) => theme.spacing(2.5),
          background: (theme) => theme.palette.common.black,
          fontSize: 'caption.fontSize',
        }}
      >
        {step}
      </Avatar>
      <Label sx={{ fontSize: '18px', lineHeight: '160%', letterSpacing: '0.15px', fontWeight: 'fontWeightMedium' }}>
        {title}
      </Label>
    </Box>
    {subtitle && (
      <CaptionLabel
        sx={{
          pl: '30px',
        }}
      >
        {subtitle}
      </CaptionLabel>
    )}
  </Box>
);

ContentCardHeader.defaultProps = {
  subtitle: '',
};
