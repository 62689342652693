import React, { FC } from 'react';
import { Box, FormControlLabel, Label, Radio, RadioGroup } from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderButtonGroupItemProps, TemplateBuilderButtonGroupSelectorProps } from './types';
import { ButtonGroup } from '../../state/messageTemplates/types';

const TemplateBuilderButtonGroupItem: FC<TemplateBuilderButtonGroupItemProps> = ({
  content,
  title,
  value,
}: TemplateBuilderButtonGroupItemProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      padding: 2,
      border: (theme) => `1px solid ${theme.palette.divider}`,
      borderRadius: '10px',
      height: '24px',
    }}
  >
    <FormControlLabel
      value={value}
      control={<Radio sx={{ m: '-9px' }} />}
      label={
        <Label variant="body1" sx={{ lineHeight: '19px' }}>
          {title}
        </Label>
      }
      labelPlacement="start"
      sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
    />
    {content && (
      <Label variant="body2" color="textSecondary">
        {content}
      </Label>
    )}
  </Box>
);

export const TemplateBuilderButtonGroupSelector: FC<TemplateBuilderButtonGroupSelectorProps> = ({
  group,
  onChange,
  sx = [],
}: TemplateBuilderButtonGroupSelectorProps) => (
  <RadioGroup
    value={group}
    onChange={(e) => {
      onChange(Number.parseInt(e.target.value, 10) as ButtonGroup);
    }}
    sx={[{ gap: 1.5 }, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    {[ButtonGroup.QuickReply, ButtonGroup.CallToAction].map((x) => {
      switch (x) {
        case ButtonGroup.QuickReply: {
          return <TemplateBuilderButtonGroupItem key={x} value={x} title="Quick Reply Button" />;
        }
        case ButtonGroup.CallToAction: {
          return <TemplateBuilderButtonGroupItem key={x} value={x} title="Link Button" />;
        }
        default: {
          return <TemplateBuilderButtonGroupItem key={x} value={x} title="Not Implemented Button" />;
        }
      }
    })}
  </RadioGroup>
);

TemplateBuilderButtonGroupSelector.defaultProps = {
  sx: undefined,
};
