import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NavigationRoute, PageLayout } from '@components/PageLayout';
import { useTranslation } from 'react-i18next';
import { settingsPageEnhancementsAtom } from '@atoms/settings';
import { useAtomValue } from 'jotai';
import { useFeatureFlag } from '@hooks';
import { General } from './General';
import { QuickReply } from './QuickReply';
import { Campaigns } from './Campaigns';
import { TagsManager } from './TagsManager';
import { UserManagement } from './UserManagement';
import { BetaFeatures } from './BetaFeatures';
import { useNavigator } from '../hooks';
import { VtexTokenForm } from './VTEX';

const useSettings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.pages' });
  const { routes: settingsRoutes } = useNavigator();
  const { ffEnableVtexSettings } = useFeatureFlag(['ffEnableVtexSettings']);
  const routes: NavigationRoute[] = useMemo(() => {
    const routesList = [
      {
        ...settingsRoutes.home,
        display: t('tabs.general.tabDisplay', 'General'),
      },
      {
        ...settingsRoutes.quickReply,
        display: t('tabs.quickReply.tabDisplay', 'Quick Replies'),
      },
      {
        ...settingsRoutes.campaigns,
        display: t('tabs.campaigns.tabDisplay', 'Campaigns'),
      },
      {
        ...settingsRoutes.tags,
        display: t('tabs.tags.tabDisplay', 'Tags'),
      },
      {
        ...settingsRoutes.users,
        display: t('tabs.users.tabDisplay', 'Users'),
      },
      {
        ...settingsRoutes.features,
        display: t('tabs.features.tabDisplay', 'Beta Features'),
      },
    ];
    if (ffEnableVtexSettings)
      routesList.push({
        ...settingsRoutes.vtex,
        display: t('tabs.vtex.tabDisplay', 'VTEX'),
      });
    return routesList;
  }, [
    settingsRoutes.campaigns,
    settingsRoutes.features,
    settingsRoutes.home,
    settingsRoutes.quickReply,
    settingsRoutes.tags,
    settingsRoutes.users,
    settingsRoutes.vtex,
    ffEnableVtexSettings,
    t,
  ]);
  const pageEnhancements = useAtomValue(settingsPageEnhancementsAtom);
  const { title } = useMemo(() => {
    return {
      title: t('title'),
    };
  }, [t]);
  return {
    title,
    pageEnhancements,
    routes,
  };
};

const Content = () => {
  const { routes } = useNavigator();
  return (
    <Switch>
      <Route path={routes.home.pathname} exact>
        <General />
      </Route>
      <Route path={routes.general.pathname}>
        <General />
      </Route>
      <Route path={routes.quickReply.pathname}>
        <QuickReply />
      </Route>
      <Route path={routes.campaigns.pathname}>
        <Campaigns />
      </Route>
      <Route path={routes.tags.pathname}>
        <TagsManager />
      </Route>
      <Route path={routes.users.pathname}>
        <UserManagement />
      </Route>
      <Route path={routes.features.pathname}>
        <BetaFeatures />
      </Route>
      <Route path={routes.vtex.pathname}>
        <VtexTokenForm />
      </Route>
    </Switch>
  );
};

export const Settings = () => {
  const { title, routes, pageEnhancements } = useSettings();
  return (
    <PageLayout title={title} primaryButton={pageEnhancements.primaryButton} routes={routes}>
      <Content />
    </PageLayout>
  );
};
