import { startOfDay, startOfMonth, endOfMonth, subDays, subMonths, endOfDay } from 'date-fns';
import { UTCDate } from '@date-fns/utc';

export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const DATE_RANGES = {
  all: {
    startDate: new UTCDate(startOfDay(new UTCDate(2020, 0, 1))),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  today: {
    startDate: new UTCDate(startOfDay(new UTCDate())),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  last7Days: {
    startDate: new UTCDate(startOfDay(subDays(new UTCDate(), 6))),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  last1Month: {
    startDate: new UTCDate(startOfDay(subMonths(new UTCDate(), 1))),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  last3Months: {
    startDate: new UTCDate(startOfDay(subMonths(new UTCDate(), 3))),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  last6Months: {
    startDate: new UTCDate(startOfDay(subMonths(new UTCDate(), 6))),
    endDate: new UTCDate(endOfDay(new UTCDate())),
  },
  calMonth: {
    startDate: new UTCDate(startOfDay(startOfMonth(new UTCDate()))),
    endDate: new UTCDate(endOfMonth(new UTCDate())),
  },
  prevCalMonth: {
    startDate: new UTCDate(startOfDay(startOfMonth(subMonths(new UTCDate(), 1)))),
    endDate: new UTCDate(endOfDay(endOfMonth(subMonths(new UTCDate(), 1)))),
  },
};
