import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingState } from '@connectlyai-sdks/loading';
import { LoadingBackdrop } from '@connectlyai-tenets/ui-styled-web';
import RedirectToLogin from './RedirectToLogin';
const ProtectedRoute = ({ loadingState, children, unauthorizedView, audience, ...props }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const location = useLocation();
    const isUserLoading = loadingState === LoadingState.FULL_LOADING;
    const path = `${location.pathname}${location.search}`;
    return (_jsxs(Route, { ...props, children: [_jsx(LoadingBackdrop, { open: isLoading || isUserLoading }), !isLoading && !isAuthenticated && _jsx(RedirectToLogin, { audience: audience, redirectPath: path }), !isLoading && isAuthenticated && loadingState === LoadingState.FULL_ERROR && unauthorizedView, !isLoading && isAuthenticated && loadingState === LoadingState.DATA && children] }));
};
export default ProtectedRoute;
