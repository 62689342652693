/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useCallback, useState } from 'react';
import {
  Label,
  Stack,
  Button,
  Tooltip,
  useTheme,
  EmojiEventsIcon,
  ConnectlySection,
  ConnectlyCard,
} from '@connectlyai-tenets/ui-styled-web';
import { EnhancedTableRowProps as ETableRowProps, TableHeadCell as THeadCell } from '@components/EnhancedTable';
import { CampaignAPIDialog } from '@components';
import { useNavigator } from '@scenes/Analytics/hooks';
import { useTranslation } from 'react-i18next';
import { generateUrlRoute, useHistory } from '@connectlyai-tenets/navigation';
import {
  selectBusinessId,
  selectNormalizedCampaigns,
  useAnalytics,
  useCampaignsData,
  useFeatureFlag,
  useMeData,
} from '@hooks';
import { SimpleTable } from '../SimpleTable';
import { UseBestPerformingCampaignsTableProps, BestPerformingProps } from './types';
import { LoadingBestPerformingCampaigns } from './LoadingBestPerformingCampaigns';
import { RowActions } from './RowActions';

const useBestPerfomingCampaignsTable = ({ data, isAnalyticsPage }: UseBestPerformingCampaignsTableProps) => {
  const { routes: analyticsRoutes } = useNavigator();
  const { sendAnalytics } = useAnalytics();

  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });
  const { t: tHome } = useTranslation('translation', { keyPrefix: 'home.analytics' });

  const [apiDocumentId, setApiDocumentId] = useState<string | undefined>(undefined);
  const handleOpenAPI = useCallback(
    (flowDocumentId: string) => {
      setApiDocumentId(flowDocumentId);
    },
    [setApiDocumentId],
  );
  const closeAPIDialog = useCallback(() => {
    setApiDocumentId(undefined);
  }, [setApiDocumentId]);

  const title = tHome('bestPerformingCampaigns', 'Best performing campaigns');

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: campaigns, isLoading } = useCampaignsData({
    enabled: !!businessId,
    businessId: businessId as string,
    orderBy: 'CAMPAIGN_ORDER_CREATED_AT_DESC',
    select: selectNormalizedCampaigns,
  });

  const history = useHistory();
  const handleViewResultsButtonClick = useCallback(
    (url: string) => {
      if (url) {
        const urlRoute = generateUrlRoute(url || '');
        history.push(urlRoute.id);
      }
    },
    [history],
  );

  const theme = useTheme();
  const tableHead: THeadCell[] = useMemo(() => {
    const headArray: THeadCell[] = [
      {
        id: 'campaignName',
        label: `${t('table.columns.campaignName')}/ID`,
        width: '28%',
        align: 'left',
      },
      {
        id: 'sentCount',
        label: t('table.columns.sent'),
        width: '9%',
        align: 'right',
      },
      {
        id: 'readCount',
        label: t('table.columns.opened'),
        width: '9%',
        align: 'right',
      },
      {
        id: 'buttonClicks',
        label: t('table.columns.engaged'),
        width: '9%',
        align: 'right',
      },
    ];

    if (isAnalyticsPage) {
      headArray.push({
        id: 'actions',
        label: '',
        width: '15%',
        align: 'right',
      });
    }

    return headArray;
  }, [t, isAnalyticsPage]);

  const rows: ETableRowProps[] = useMemo(() => {
    if (!data) return [];
    if (Object.keys(campaigns || {}).length === 0) return [];

    return data
      .reduce((acc: any, item: any) => {
        const existing = acc.find((_item: any) => _item.campaignId === item.campaignId);

        if (existing) {
          const { uniqueButtonClicks } = item.buttonClicks;
          const { uniqueButtonClicks: prevUniqueButtonClicks } = existing.buttonClicks;

          existing.readCount += item.readCount;
          existing.buttonClicks = {
            ...existing.buttonClicks,
            uniqueButtonClicks: prevUniqueButtonClicks + uniqueButtonClicks,
          };
        } else {
          acc.push({ ...item });
        }

        return acc;
      }, [])
      .sort((a: any, b: any) => {
        return b.buttonClicks.uniqueButtonClicks - a.buttonClicks.uniqueButtonClicks;
      })
      .map((item: any) => {
        const finalData = [
          {
            value: item.campaignName,
            display: (
              <Tooltip
                title={tHome('viewResults', 'View Results')}
                arrow
                placement="top"
                disableHoverListener={!campaigns?.[item.campaignId]?.outcome?.reportV1?.url || isAnalyticsPage}
              >
                <Stack
                  direction="column"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '200px', cursor: 'pointer' }}
                >
                  <Label variant="body1" noWrap>
                    {item.campaignName}
                  </Label>
                  <Label noWrap variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {item.campaignId}
                  </Label>
                </Stack>
              </Tooltip>
            ),
          },
          {
            value: item.sentCount,
            display: item.sentCount,
            align: 'right',
          },
          {
            value: item.readCount,
            display: item.readCount,
            align: 'right',
          },
          {
            value: item.sendoutEngagement?.uniqueSessionEngagement,
            display: item.sendoutEngagement?.uniqueSessionEngagement,
            align: 'right',
          },
        ];

        if (isAnalyticsPage) {
          finalData.push({
            value: '',
            display: (
              <RowActions
                isAnalyticsPage={isAnalyticsPage}
                flowDocumentId={campaigns[item.campaignId]?.flowDocumentId}
                handleOpenAPI={handleOpenAPI}
                handleViewResultsButtonClick={() =>
                  handleViewResultsButtonClick(campaigns[item.campaignId]?.outcome?.reportV1?.url)
                }
              />
            ),
            align: 'right',
          });
        }

        const onClick =
          campaigns[item.campaignId]?.outcome?.reportV1?.url && !isAnalyticsPage
            ? () => handleViewResultsButtonClick(campaigns[item.campaignId]?.outcome?.reportV1?.url)
            : null;

        return {
          id: item.campaignId || '',
          campaignId: item.campaignId || '',
          onClick,
          handleOpenAPI,
          hoverActions: isAnalyticsPage ? null : (
            <RowActions flowDocumentId={campaigns[item.campaignId]?.flowDocumentId} handleOpenAPI={handleOpenAPI} />
          ),
          data: finalData,
        };
      });
  }, [
    data,
    isAnalyticsPage,
    handleViewResultsButtonClick,
    handleOpenAPI,
    campaigns,
    theme.palette.text.secondary,
    tHome,
  ]);

  const handleShowAllBestPerforming = () => {
    sendAnalytics('homepage_analytics.best_performing_campaigns.show_all_clicked');

    return analyticsRoutes.campaigns.navigate();
  };

  return {
    rows,
    tableHead,
    title,
    theme,
    isLoading,
    closeAPIDialog,
    apiDocumentId,
    handleShowAllBestPerforming,
  };
};

export const BestPerforming = ({ filteredData, isLoading, isAnalyticsPage = false }: BestPerformingProps) => {
  const { t: tHome } = useTranslation('translation', { keyPrefix: 'generic' });
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);

  const {
    rows,
    title,
    theme,
    tableHead,
    isLoading: isLoadingCampaings,
    closeAPIDialog,
    apiDocumentId,
    handleShowAllBestPerforming,
  } = useBestPerfomingCampaignsTable({
    data: filteredData,
    isLoading,
    isAnalyticsPage,
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const data = rows.slice(0, isExpanded && isAnalyticsPage ? 10 : 3);

  const handleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  const Section = isAnalyticsPage && ffEnableAnalyticsRedesign ? ConnectlySection : ConnectlyCard;

  return (
    <>
      <CampaignAPIDialog flowDocumentId={apiDocumentId} onClose={closeAPIDialog} />

      <Section title={title} icon={<EmojiEventsIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}>
        {isLoadingCampaings ? (
          <LoadingBestPerformingCampaigns />
        ) : (
          <>
            <SimpleTable rows={data} headers={tableHead} isLoading={isLoadingCampaings} />

            {rows.length > 3 && (
              <Stack direction="row" justifyContent="center">
                {isAnalyticsPage ? (
                  <Button sx={{ mt: 2 }} onClick={handleShowMore}>
                    {isExpanded ? tHome('showLess', 'Show less') : tHome('showAll', 'Show all')}
                  </Button>
                ) : (
                  <Button sx={{ mt: 2 }} onClick={handleShowAllBestPerforming} variant="contained" color="secondary">
                    {tHome('showAll', 'Show all')}
                  </Button>
                )}
              </Stack>
            )}
          </>
        )}
      </Section>
    </>
  );
};
