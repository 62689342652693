import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.twilio.v20100401.error";
function createBaseError() {
    return { status: 0, message: "", code: 0, moreInfo: "" };
}
export const Error = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.code !== 0) {
            writer.uint32(24).int32(message.code);
        }
        if (message.moreInfo !== "") {
            writer.uint32(34).string(message.moreInfo);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.code = reader.int32();
                    break;
                case 4:
                    message.moreInfo = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? Number(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            moreInfo: isSet(object.moreInfo) ? String(object.moreInfo) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined && (obj.status = Math.round(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.moreInfo !== undefined && (obj.moreInfo = message.moreInfo);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
