import { CustomZendeskHandoverMetadata, CustomShopifyAbandonedCheckoutMetadata, CustomShopifyMultiProductMarketingMetadata, AutoReplyV1Metadata, ShopifyRecommenderMetadata, BCAMerchantCatalogMetadata, SalesBotMetadata, CustomVtexAbandonedCheckoutMetadata, integrationTypeFromJSON, integrationTypeToJSON, } from "../models";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { CustomZendeskHandoverParameters, CustomShopifyAbandonedCheckoutParameters, CustomShopifyMultiProductMarketingParameters, AutoReplyV1Parameters, ShopifyRecommenderParameters, BCAMerchantCatalogParameters, SalesBotParameters, CustomVtexAbandonedCheckoutParameters, } from "../parameters/models";
import { TimeSeriesAttributes, ScalarAttributes, } from "../../metrics/dto/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.integration.dto";
function createBaseInstallIntegrationInput() {
    return {
        integrationType: 0,
        businessId: "",
        integrationParameters: undefined,
        forceReinstall: false,
    };
}
export const InstallIntegrationInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.integrationType !== 0) {
            writer.uint32(8).int32(message.integrationType);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.integrationParameters !== undefined) {
            IntegrationParametersInput.encode(message.integrationParameters, writer.uint32(26).fork()).ldelim();
        }
        if (message.forceReinstall === true) {
            writer.uint32(32).bool(message.forceReinstall);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInstallIntegrationInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.integrationType = reader.int32();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.integrationParameters = IntegrationParametersInput.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.forceReinstall = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            integrationType: isSet(object.integrationType)
                ? integrationTypeFromJSON(object.integrationType)
                : 0,
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            integrationParameters: isSet(object.integrationParameters)
                ? IntegrationParametersInput.fromJSON(object.integrationParameters)
                : undefined,
            forceReinstall: isSet(object.forceReinstall)
                ? Boolean(object.forceReinstall)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.integrationType !== undefined &&
            (obj.integrationType = integrationTypeToJSON(message.integrationType));
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.integrationParameters !== undefined &&
            (obj.integrationParameters = message.integrationParameters
                ? IntegrationParametersInput.toJSON(message.integrationParameters)
                : undefined);
        message.forceReinstall !== undefined &&
            (obj.forceReinstall = message.forceReinstall);
        return obj;
    },
};
function createBaseMigrateIntegrationInput() {
    return { businessId: "", integrationType: 0, forceReinstall: false };
}
export const MigrateIntegrationInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.integrationType !== 0) {
            writer.uint32(16).int32(message.integrationType);
        }
        if (message.forceReinstall === true) {
            writer.uint32(24).bool(message.forceReinstall);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMigrateIntegrationInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.integrationType = reader.int32();
                    break;
                case 3:
                    message.forceReinstall = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            integrationType: isSet(object.integrationType)
                ? integrationTypeFromJSON(object.integrationType)
                : 0,
            forceReinstall: isSet(object.forceReinstall)
                ? Boolean(object.forceReinstall)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.integrationType !== undefined &&
            (obj.integrationType = integrationTypeToJSON(message.integrationType));
        message.forceReinstall !== undefined &&
            (obj.forceReinstall = message.forceReinstall);
        return obj;
    },
};
function createBaseUninstallIntegrationInput() {
    return { businessId: "", integrationType: 0 };
}
export const UninstallIntegrationInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.integrationType !== 0) {
            writer.uint32(16).int32(message.integrationType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUninstallIntegrationInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.integrationType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            integrationType: isSet(object.integrationType)
                ? integrationTypeFromJSON(object.integrationType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.integrationType !== undefined &&
            (obj.integrationType = integrationTypeToJSON(message.integrationType));
        return obj;
    },
};
function createBaseInstallIntegrationsInput() {
    return { input: [] };
}
export const InstallIntegrationsInput = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.input) {
            InstallIntegrationInput.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInstallIntegrationsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.input.push(InstallIntegrationInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            input: Array.isArray(object === null || object === void 0 ? void 0 : object.input)
                ? object.input.map((e) => InstallIntegrationInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.input) {
            obj.input = message.input.map((e) => e ? InstallIntegrationInput.toJSON(e) : undefined);
        }
        else {
            obj.input = [];
        }
        return obj;
    },
};
function createBaseMigrateIntegrationsInput() {
    return { input: [] };
}
export const MigrateIntegrationsInput = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.input) {
            MigrateIntegrationInput.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMigrateIntegrationsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.input.push(MigrateIntegrationInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            input: Array.isArray(object === null || object === void 0 ? void 0 : object.input)
                ? object.input.map((e) => MigrateIntegrationInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.input) {
            obj.input = message.input.map((e) => e ? MigrateIntegrationInput.toJSON(e) : undefined);
        }
        else {
            obj.input = [];
        }
        return obj;
    },
};
function createBaseUninstallIntegrationsInput() {
    return { input: [] };
}
export const UninstallIntegrationsInput = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.input) {
            UninstallIntegrationInput.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUninstallIntegrationsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.input.push(UninstallIntegrationInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            input: Array.isArray(object === null || object === void 0 ? void 0 : object.input)
                ? object.input.map((e) => UninstallIntegrationInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.input) {
            obj.input = message.input.map((e) => e ? UninstallIntegrationInput.toJSON(e) : undefined);
        }
        else {
            obj.input = [];
        }
        return obj;
    },
};
function createBaseIntegrationParametersInput() {
    return { paramsOneof: undefined };
}
export const IntegrationParametersInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverParameters") {
            CustomZendeskHandoverParameters.encode(message.paramsOneof.customZendeskHandoverParameters, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.paramsOneof) === null || _b === void 0 ? void 0 : _b.$case) === "customShopifyAbandonedCheckoutParameters") {
            CustomShopifyAbandonedCheckoutParameters.encode(message.paramsOneof.customShopifyAbandonedCheckoutParameters, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.paramsOneof) === null || _c === void 0 ? void 0 : _c.$case) ===
            "customShopifyMultiProductMarketingParameters") {
            CustomShopifyMultiProductMarketingParameters.encode(message.paramsOneof.customShopifyMultiProductMarketingParameters, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.paramsOneof) === null || _d === void 0 ? void 0 : _d.$case) === "autoReplyV1Parameters") {
            AutoReplyV1Parameters.encode(message.paramsOneof.autoReplyV1Parameters, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.paramsOneof) === null || _e === void 0 ? void 0 : _e.$case) === "shopifyRecommenderParameters") {
            ShopifyRecommenderParameters.encode(message.paramsOneof.shopifyRecommenderParameters, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.paramsOneof) === null || _f === void 0 ? void 0 : _f.$case) === "bcaMerchantCatalogParameters") {
            BCAMerchantCatalogParameters.encode(message.paramsOneof.bcaMerchantCatalogParameters, writer.uint32(50).fork()).ldelim();
        }
        if (((_g = message.paramsOneof) === null || _g === void 0 ? void 0 : _g.$case) === "salesBotCommandParameters") {
            SalesBotParameters.encode(message.paramsOneof.salesBotCommandParameters, writer.uint32(66).fork()).ldelim();
        }
        if (((_h = message.paramsOneof) === null || _h === void 0 ? void 0 : _h.$case) === "customVtexAbandonedCheckoutParameters") {
            CustomVtexAbandonedCheckoutParameters.encode(message.paramsOneof.customVtexAbandonedCheckoutParameters, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationParametersInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paramsOneof = {
                        $case: "customZendeskHandoverParameters",
                        customZendeskHandoverParameters: CustomZendeskHandoverParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.paramsOneof = {
                        $case: "customShopifyAbandonedCheckoutParameters",
                        customShopifyAbandonedCheckoutParameters: CustomShopifyAbandonedCheckoutParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.paramsOneof = {
                        $case: "customShopifyMultiProductMarketingParameters",
                        customShopifyMultiProductMarketingParameters: CustomShopifyMultiProductMarketingParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.paramsOneof = {
                        $case: "autoReplyV1Parameters",
                        autoReplyV1Parameters: AutoReplyV1Parameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.paramsOneof = {
                        $case: "shopifyRecommenderParameters",
                        shopifyRecommenderParameters: ShopifyRecommenderParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.paramsOneof = {
                        $case: "bcaMerchantCatalogParameters",
                        bcaMerchantCatalogParameters: BCAMerchantCatalogParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 8:
                    message.paramsOneof = {
                        $case: "salesBotCommandParameters",
                        salesBotCommandParameters: SalesBotParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.paramsOneof = {
                        $case: "customVtexAbandonedCheckoutParameters",
                        customVtexAbandonedCheckoutParameters: CustomVtexAbandonedCheckoutParameters.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paramsOneof: isSet(object.customZendeskHandoverParameters)
                ? {
                    $case: "customZendeskHandoverParameters",
                    customZendeskHandoverParameters: CustomZendeskHandoverParameters.fromJSON(object.customZendeskHandoverParameters),
                }
                : isSet(object.customShopifyAbandonedCheckoutParameters)
                    ? {
                        $case: "customShopifyAbandonedCheckoutParameters",
                        customShopifyAbandonedCheckoutParameters: CustomShopifyAbandonedCheckoutParameters.fromJSON(object.customShopifyAbandonedCheckoutParameters),
                    }
                    : isSet(object.customShopifyMultiProductMarketingParameters)
                        ? {
                            $case: "customShopifyMultiProductMarketingParameters",
                            customShopifyMultiProductMarketingParameters: CustomShopifyMultiProductMarketingParameters.fromJSON(object.customShopifyMultiProductMarketingParameters),
                        }
                        : isSet(object.autoReplyV1Parameters)
                            ? {
                                $case: "autoReplyV1Parameters",
                                autoReplyV1Parameters: AutoReplyV1Parameters.fromJSON(object.autoReplyV1Parameters),
                            }
                            : isSet(object.shopifyRecommenderParameters)
                                ? {
                                    $case: "shopifyRecommenderParameters",
                                    shopifyRecommenderParameters: ShopifyRecommenderParameters.fromJSON(object.shopifyRecommenderParameters),
                                }
                                : isSet(object.bcaMerchantCatalogParameters)
                                    ? {
                                        $case: "bcaMerchantCatalogParameters",
                                        bcaMerchantCatalogParameters: BCAMerchantCatalogParameters.fromJSON(object.bcaMerchantCatalogParameters),
                                    }
                                    : isSet(object.salesBotCommandParameters)
                                        ? {
                                            $case: "salesBotCommandParameters",
                                            salesBotCommandParameters: SalesBotParameters.fromJSON(object.salesBotCommandParameters),
                                        }
                                        : isSet(object.customVtexAbandonedCheckoutParameters)
                                            ? {
                                                $case: "customVtexAbandonedCheckoutParameters",
                                                customVtexAbandonedCheckoutParameters: CustomVtexAbandonedCheckoutParameters.fromJSON(object.customVtexAbandonedCheckoutParameters),
                                            }
                                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const obj = {};
        ((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverParameters" &&
            (obj.customZendeskHandoverParameters = ((_b = message.paramsOneof) === null || _b === void 0 ? void 0 : _b.customZendeskHandoverParameters)
                ? CustomZendeskHandoverParameters.toJSON((_c = message.paramsOneof) === null || _c === void 0 ? void 0 : _c.customZendeskHandoverParameters)
                : undefined);
        ((_d = message.paramsOneof) === null || _d === void 0 ? void 0 : _d.$case) === "customShopifyAbandonedCheckoutParameters" &&
            (obj.customShopifyAbandonedCheckoutParameters = ((_e = message.paramsOneof) === null || _e === void 0 ? void 0 : _e.customShopifyAbandonedCheckoutParameters)
                ? CustomShopifyAbandonedCheckoutParameters.toJSON((_f = message.paramsOneof) === null || _f === void 0 ? void 0 : _f.customShopifyAbandonedCheckoutParameters)
                : undefined);
        ((_g = message.paramsOneof) === null || _g === void 0 ? void 0 : _g.$case) ===
            "customShopifyMultiProductMarketingParameters" &&
            (obj.customShopifyMultiProductMarketingParameters = ((_h = message.paramsOneof) === null || _h === void 0 ? void 0 : _h.customShopifyMultiProductMarketingParameters)
                ? CustomShopifyMultiProductMarketingParameters.toJSON((_j = message.paramsOneof) === null || _j === void 0 ? void 0 : _j.customShopifyMultiProductMarketingParameters)
                : undefined);
        ((_k = message.paramsOneof) === null || _k === void 0 ? void 0 : _k.$case) === "autoReplyV1Parameters" &&
            (obj.autoReplyV1Parameters = ((_l = message.paramsOneof) === null || _l === void 0 ? void 0 : _l.autoReplyV1Parameters)
                ? AutoReplyV1Parameters.toJSON((_m = message.paramsOneof) === null || _m === void 0 ? void 0 : _m.autoReplyV1Parameters)
                : undefined);
        ((_o = message.paramsOneof) === null || _o === void 0 ? void 0 : _o.$case) === "shopifyRecommenderParameters" &&
            (obj.shopifyRecommenderParameters = ((_p = message.paramsOneof) === null || _p === void 0 ? void 0 : _p.shopifyRecommenderParameters)
                ? ShopifyRecommenderParameters.toJSON((_q = message.paramsOneof) === null || _q === void 0 ? void 0 : _q.shopifyRecommenderParameters)
                : undefined);
        ((_r = message.paramsOneof) === null || _r === void 0 ? void 0 : _r.$case) === "bcaMerchantCatalogParameters" &&
            (obj.bcaMerchantCatalogParameters = ((_s = message.paramsOneof) === null || _s === void 0 ? void 0 : _s.bcaMerchantCatalogParameters)
                ? BCAMerchantCatalogParameters.toJSON((_t = message.paramsOneof) === null || _t === void 0 ? void 0 : _t.bcaMerchantCatalogParameters)
                : undefined);
        ((_u = message.paramsOneof) === null || _u === void 0 ? void 0 : _u.$case) === "salesBotCommandParameters" &&
            (obj.salesBotCommandParameters = ((_v = message.paramsOneof) === null || _v === void 0 ? void 0 : _v.salesBotCommandParameters)
                ? SalesBotParameters.toJSON((_w = message.paramsOneof) === null || _w === void 0 ? void 0 : _w.salesBotCommandParameters)
                : undefined);
        ((_x = message.paramsOneof) === null || _x === void 0 ? void 0 : _x.$case) === "customVtexAbandonedCheckoutParameters" &&
            (obj.customVtexAbandonedCheckoutParameters = ((_y = message.paramsOneof) === null || _y === void 0 ? void 0 : _y.customVtexAbandonedCheckoutParameters)
                ? CustomVtexAbandonedCheckoutParameters.toJSON((_z = message.paramsOneof) === null || _z === void 0 ? void 0 : _z.customVtexAbandonedCheckoutParameters)
                : undefined);
        return obj;
    },
};
function createBaseIntegrationDto() {
    return {
        id: "",
        businessId: "",
        type: 0,
        parameters: undefined,
        installMetadata: undefined,
    };
}
export const IntegrationDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.type !== 0) {
            writer.uint32(24).int32(message.type);
        }
        if (message.parameters !== undefined) {
            IntegrationParametersDto.encode(message.parameters, writer.uint32(34).fork()).ldelim();
        }
        if (message.installMetadata !== undefined) {
            IntegrationInstallMetadataDto.encode(message.installMetadata, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.parameters = IntegrationParametersDto.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.installMetadata = IntegrationInstallMetadataDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            type: isSet(object.type) ? integrationTypeFromJSON(object.type) : 0,
            parameters: isSet(object.parameters)
                ? IntegrationParametersDto.fromJSON(object.parameters)
                : undefined,
            installMetadata: isSet(object.installMetadata)
                ? IntegrationInstallMetadataDto.fromJSON(object.installMetadata)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.type !== undefined &&
            (obj.type = integrationTypeToJSON(message.type));
        message.parameters !== undefined &&
            (obj.parameters = message.parameters
                ? IntegrationParametersDto.toJSON(message.parameters)
                : undefined);
        message.installMetadata !== undefined &&
            (obj.installMetadata = message.installMetadata
                ? IntegrationInstallMetadataDto.toJSON(message.installMetadata)
                : undefined);
        return obj;
    },
};
function createBaseIntegrationDtoCollection() {
    return { entries: [] };
}
export const IntegrationDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            IntegrationDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(IntegrationDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => IntegrationDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? IntegrationDto.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseIntegrationParametersDto() {
    return { paramsOneof: undefined };
}
export const IntegrationParametersDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverParameters") {
            CustomZendeskHandoverParameters.encode(message.paramsOneof.customZendeskHandoverParameters, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.paramsOneof) === null || _b === void 0 ? void 0 : _b.$case) === "customShopifyAbandonedCheckoutParameters") {
            CustomShopifyAbandonedCheckoutParameters.encode(message.paramsOneof.customShopifyAbandonedCheckoutParameters, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.paramsOneof) === null || _c === void 0 ? void 0 : _c.$case) ===
            "customShopifyMultiProductMarketingParameters") {
            CustomShopifyMultiProductMarketingParameters.encode(message.paramsOneof.customShopifyMultiProductMarketingParameters, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.paramsOneof) === null || _d === void 0 ? void 0 : _d.$case) === "autoReplyV1Parameters") {
            AutoReplyV1Parameters.encode(message.paramsOneof.autoReplyV1Parameters, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.paramsOneof) === null || _e === void 0 ? void 0 : _e.$case) === "shopifyRecommenderParameters") {
            ShopifyRecommenderParameters.encode(message.paramsOneof.shopifyRecommenderParameters, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.paramsOneof) === null || _f === void 0 ? void 0 : _f.$case) === "bcaMerchantCatalogParameters") {
            BCAMerchantCatalogParameters.encode(message.paramsOneof.bcaMerchantCatalogParameters, writer.uint32(58).fork()).ldelim();
        }
        if (((_g = message.paramsOneof) === null || _g === void 0 ? void 0 : _g.$case) === "salesBotCommandParameters") {
            SalesBotParameters.encode(message.paramsOneof.salesBotCommandParameters, writer.uint32(66).fork()).ldelim();
        }
        if (((_h = message.paramsOneof) === null || _h === void 0 ? void 0 : _h.$case) === "customVtexAbandonedCheckoutParameters") {
            CustomVtexAbandonedCheckoutParameters.encode(message.paramsOneof.customVtexAbandonedCheckoutParameters, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationParametersDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paramsOneof = {
                        $case: "customZendeskHandoverParameters",
                        customZendeskHandoverParameters: CustomZendeskHandoverParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.paramsOneof = {
                        $case: "customShopifyAbandonedCheckoutParameters",
                        customShopifyAbandonedCheckoutParameters: CustomShopifyAbandonedCheckoutParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.paramsOneof = {
                        $case: "customShopifyMultiProductMarketingParameters",
                        customShopifyMultiProductMarketingParameters: CustomShopifyMultiProductMarketingParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.paramsOneof = {
                        $case: "autoReplyV1Parameters",
                        autoReplyV1Parameters: AutoReplyV1Parameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.paramsOneof = {
                        $case: "shopifyRecommenderParameters",
                        shopifyRecommenderParameters: ShopifyRecommenderParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.paramsOneof = {
                        $case: "bcaMerchantCatalogParameters",
                        bcaMerchantCatalogParameters: BCAMerchantCatalogParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 8:
                    message.paramsOneof = {
                        $case: "salesBotCommandParameters",
                        salesBotCommandParameters: SalesBotParameters.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.paramsOneof = {
                        $case: "customVtexAbandonedCheckoutParameters",
                        customVtexAbandonedCheckoutParameters: CustomVtexAbandonedCheckoutParameters.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paramsOneof: isSet(object.customZendeskHandoverParameters)
                ? {
                    $case: "customZendeskHandoverParameters",
                    customZendeskHandoverParameters: CustomZendeskHandoverParameters.fromJSON(object.customZendeskHandoverParameters),
                }
                : isSet(object.customShopifyAbandonedCheckoutParameters)
                    ? {
                        $case: "customShopifyAbandonedCheckoutParameters",
                        customShopifyAbandonedCheckoutParameters: CustomShopifyAbandonedCheckoutParameters.fromJSON(object.customShopifyAbandonedCheckoutParameters),
                    }
                    : isSet(object.customShopifyMultiProductMarketingParameters)
                        ? {
                            $case: "customShopifyMultiProductMarketingParameters",
                            customShopifyMultiProductMarketingParameters: CustomShopifyMultiProductMarketingParameters.fromJSON(object.customShopifyMultiProductMarketingParameters),
                        }
                        : isSet(object.autoReplyV1Parameters)
                            ? {
                                $case: "autoReplyV1Parameters",
                                autoReplyV1Parameters: AutoReplyV1Parameters.fromJSON(object.autoReplyV1Parameters),
                            }
                            : isSet(object.shopifyRecommenderParameters)
                                ? {
                                    $case: "shopifyRecommenderParameters",
                                    shopifyRecommenderParameters: ShopifyRecommenderParameters.fromJSON(object.shopifyRecommenderParameters),
                                }
                                : isSet(object.bcaMerchantCatalogParameters)
                                    ? {
                                        $case: "bcaMerchantCatalogParameters",
                                        bcaMerchantCatalogParameters: BCAMerchantCatalogParameters.fromJSON(object.bcaMerchantCatalogParameters),
                                    }
                                    : isSet(object.salesBotCommandParameters)
                                        ? {
                                            $case: "salesBotCommandParameters",
                                            salesBotCommandParameters: SalesBotParameters.fromJSON(object.salesBotCommandParameters),
                                        }
                                        : isSet(object.customVtexAbandonedCheckoutParameters)
                                            ? {
                                                $case: "customVtexAbandonedCheckoutParameters",
                                                customVtexAbandonedCheckoutParameters: CustomVtexAbandonedCheckoutParameters.fromJSON(object.customVtexAbandonedCheckoutParameters),
                                            }
                                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const obj = {};
        ((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverParameters" &&
            (obj.customZendeskHandoverParameters = ((_b = message.paramsOneof) === null || _b === void 0 ? void 0 : _b.customZendeskHandoverParameters)
                ? CustomZendeskHandoverParameters.toJSON((_c = message.paramsOneof) === null || _c === void 0 ? void 0 : _c.customZendeskHandoverParameters)
                : undefined);
        ((_d = message.paramsOneof) === null || _d === void 0 ? void 0 : _d.$case) === "customShopifyAbandonedCheckoutParameters" &&
            (obj.customShopifyAbandonedCheckoutParameters = ((_e = message.paramsOneof) === null || _e === void 0 ? void 0 : _e.customShopifyAbandonedCheckoutParameters)
                ? CustomShopifyAbandonedCheckoutParameters.toJSON((_f = message.paramsOneof) === null || _f === void 0 ? void 0 : _f.customShopifyAbandonedCheckoutParameters)
                : undefined);
        ((_g = message.paramsOneof) === null || _g === void 0 ? void 0 : _g.$case) ===
            "customShopifyMultiProductMarketingParameters" &&
            (obj.customShopifyMultiProductMarketingParameters = ((_h = message.paramsOneof) === null || _h === void 0 ? void 0 : _h.customShopifyMultiProductMarketingParameters)
                ? CustomShopifyMultiProductMarketingParameters.toJSON((_j = message.paramsOneof) === null || _j === void 0 ? void 0 : _j.customShopifyMultiProductMarketingParameters)
                : undefined);
        ((_k = message.paramsOneof) === null || _k === void 0 ? void 0 : _k.$case) === "autoReplyV1Parameters" &&
            (obj.autoReplyV1Parameters = ((_l = message.paramsOneof) === null || _l === void 0 ? void 0 : _l.autoReplyV1Parameters)
                ? AutoReplyV1Parameters.toJSON((_m = message.paramsOneof) === null || _m === void 0 ? void 0 : _m.autoReplyV1Parameters)
                : undefined);
        ((_o = message.paramsOneof) === null || _o === void 0 ? void 0 : _o.$case) === "shopifyRecommenderParameters" &&
            (obj.shopifyRecommenderParameters = ((_p = message.paramsOneof) === null || _p === void 0 ? void 0 : _p.shopifyRecommenderParameters)
                ? ShopifyRecommenderParameters.toJSON((_q = message.paramsOneof) === null || _q === void 0 ? void 0 : _q.shopifyRecommenderParameters)
                : undefined);
        ((_r = message.paramsOneof) === null || _r === void 0 ? void 0 : _r.$case) === "bcaMerchantCatalogParameters" &&
            (obj.bcaMerchantCatalogParameters = ((_s = message.paramsOneof) === null || _s === void 0 ? void 0 : _s.bcaMerchantCatalogParameters)
                ? BCAMerchantCatalogParameters.toJSON((_t = message.paramsOneof) === null || _t === void 0 ? void 0 : _t.bcaMerchantCatalogParameters)
                : undefined);
        ((_u = message.paramsOneof) === null || _u === void 0 ? void 0 : _u.$case) === "salesBotCommandParameters" &&
            (obj.salesBotCommandParameters = ((_v = message.paramsOneof) === null || _v === void 0 ? void 0 : _v.salesBotCommandParameters)
                ? SalesBotParameters.toJSON((_w = message.paramsOneof) === null || _w === void 0 ? void 0 : _w.salesBotCommandParameters)
                : undefined);
        ((_x = message.paramsOneof) === null || _x === void 0 ? void 0 : _x.$case) === "customVtexAbandonedCheckoutParameters" &&
            (obj.customVtexAbandonedCheckoutParameters = ((_y = message.paramsOneof) === null || _y === void 0 ? void 0 : _y.customVtexAbandonedCheckoutParameters)
                ? CustomVtexAbandonedCheckoutParameters.toJSON((_z = message.paramsOneof) === null || _z === void 0 ? void 0 : _z.customVtexAbandonedCheckoutParameters)
                : undefined);
        return obj;
    },
};
function createBaseIntegrationInstallMetadataDto() {
    return { metadataOneof: undefined };
}
export const IntegrationInstallMetadataDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = message.metadataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverMetadata") {
            CustomZendeskHandoverMetadata.encode(message.metadataOneof.customZendeskHandoverMetadata, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.metadataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "customShopifyAbandonedCheckoutMetadata") {
            CustomShopifyAbandonedCheckoutMetadata.encode(message.metadataOneof.customShopifyAbandonedCheckoutMetadata, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.metadataOneof) === null || _c === void 0 ? void 0 : _c.$case) ===
            "customShopifyMultiProductMarketingMetadata") {
            CustomShopifyMultiProductMarketingMetadata.encode(message.metadataOneof.customShopifyMultiProductMarketingMetadata, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.metadataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "autoReplyV1Metadata") {
            AutoReplyV1Metadata.encode(message.metadataOneof.autoReplyV1Metadata, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.metadataOneof) === null || _e === void 0 ? void 0 : _e.$case) === "shopifyRecommenderMetadata") {
            ShopifyRecommenderMetadata.encode(message.metadataOneof.shopifyRecommenderMetadata, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.metadataOneof) === null || _f === void 0 ? void 0 : _f.$case) === "bcaMerchantCatalogMetadata") {
            BCAMerchantCatalogMetadata.encode(message.metadataOneof.bcaMerchantCatalogMetadata, writer.uint32(50).fork()).ldelim();
        }
        if (((_g = message.metadataOneof) === null || _g === void 0 ? void 0 : _g.$case) === "salesBotCommandMetadata") {
            SalesBotMetadata.encode(message.metadataOneof.salesBotCommandMetadata, writer.uint32(66).fork()).ldelim();
        }
        if (((_h = message.metadataOneof) === null || _h === void 0 ? void 0 : _h.$case) === "customVtexAbandonedCheckoutMetadata") {
            CustomVtexAbandonedCheckoutMetadata.encode(message.metadataOneof.customVtexAbandonedCheckoutMetadata, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationInstallMetadataDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metadataOneof = {
                        $case: "customZendeskHandoverMetadata",
                        customZendeskHandoverMetadata: CustomZendeskHandoverMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.metadataOneof = {
                        $case: "customShopifyAbandonedCheckoutMetadata",
                        customShopifyAbandonedCheckoutMetadata: CustomShopifyAbandonedCheckoutMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.metadataOneof = {
                        $case: "customShopifyMultiProductMarketingMetadata",
                        customShopifyMultiProductMarketingMetadata: CustomShopifyMultiProductMarketingMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.metadataOneof = {
                        $case: "autoReplyV1Metadata",
                        autoReplyV1Metadata: AutoReplyV1Metadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.metadataOneof = {
                        $case: "shopifyRecommenderMetadata",
                        shopifyRecommenderMetadata: ShopifyRecommenderMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.metadataOneof = {
                        $case: "bcaMerchantCatalogMetadata",
                        bcaMerchantCatalogMetadata: BCAMerchantCatalogMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 8:
                    message.metadataOneof = {
                        $case: "salesBotCommandMetadata",
                        salesBotCommandMetadata: SalesBotMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.metadataOneof = {
                        $case: "customVtexAbandonedCheckoutMetadata",
                        customVtexAbandonedCheckoutMetadata: CustomVtexAbandonedCheckoutMetadata.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            metadataOneof: isSet(object.customZendeskHandoverMetadata)
                ? {
                    $case: "customZendeskHandoverMetadata",
                    customZendeskHandoverMetadata: CustomZendeskHandoverMetadata.fromJSON(object.customZendeskHandoverMetadata),
                }
                : isSet(object.customShopifyAbandonedCheckoutMetadata)
                    ? {
                        $case: "customShopifyAbandonedCheckoutMetadata",
                        customShopifyAbandonedCheckoutMetadata: CustomShopifyAbandonedCheckoutMetadata.fromJSON(object.customShopifyAbandonedCheckoutMetadata),
                    }
                    : isSet(object.customShopifyMultiProductMarketingMetadata)
                        ? {
                            $case: "customShopifyMultiProductMarketingMetadata",
                            customShopifyMultiProductMarketingMetadata: CustomShopifyMultiProductMarketingMetadata.fromJSON(object.customShopifyMultiProductMarketingMetadata),
                        }
                        : isSet(object.autoReplyV1Metadata)
                            ? {
                                $case: "autoReplyV1Metadata",
                                autoReplyV1Metadata: AutoReplyV1Metadata.fromJSON(object.autoReplyV1Metadata),
                            }
                            : isSet(object.shopifyRecommenderMetadata)
                                ? {
                                    $case: "shopifyRecommenderMetadata",
                                    shopifyRecommenderMetadata: ShopifyRecommenderMetadata.fromJSON(object.shopifyRecommenderMetadata),
                                }
                                : isSet(object.bcaMerchantCatalogMetadata)
                                    ? {
                                        $case: "bcaMerchantCatalogMetadata",
                                        bcaMerchantCatalogMetadata: BCAMerchantCatalogMetadata.fromJSON(object.bcaMerchantCatalogMetadata),
                                    }
                                    : isSet(object.salesBotCommandMetadata)
                                        ? {
                                            $case: "salesBotCommandMetadata",
                                            salesBotCommandMetadata: SalesBotMetadata.fromJSON(object.salesBotCommandMetadata),
                                        }
                                        : isSet(object.customVtexAbandonedCheckoutMetadata)
                                            ? {
                                                $case: "customVtexAbandonedCheckoutMetadata",
                                                customVtexAbandonedCheckoutMetadata: CustomVtexAbandonedCheckoutMetadata.fromJSON(object.customVtexAbandonedCheckoutMetadata),
                                            }
                                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const obj = {};
        ((_a = message.metadataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customZendeskHandoverMetadata" &&
            (obj.customZendeskHandoverMetadata = ((_b = message.metadataOneof) === null || _b === void 0 ? void 0 : _b.customZendeskHandoverMetadata)
                ? CustomZendeskHandoverMetadata.toJSON((_c = message.metadataOneof) === null || _c === void 0 ? void 0 : _c.customZendeskHandoverMetadata)
                : undefined);
        ((_d = message.metadataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "customShopifyAbandonedCheckoutMetadata" &&
            (obj.customShopifyAbandonedCheckoutMetadata = ((_e = message.metadataOneof) === null || _e === void 0 ? void 0 : _e.customShopifyAbandonedCheckoutMetadata)
                ? CustomShopifyAbandonedCheckoutMetadata.toJSON((_f = message.metadataOneof) === null || _f === void 0 ? void 0 : _f.customShopifyAbandonedCheckoutMetadata)
                : undefined);
        ((_g = message.metadataOneof) === null || _g === void 0 ? void 0 : _g.$case) ===
            "customShopifyMultiProductMarketingMetadata" &&
            (obj.customShopifyMultiProductMarketingMetadata = ((_h = message.metadataOneof) === null || _h === void 0 ? void 0 : _h.customShopifyMultiProductMarketingMetadata)
                ? CustomShopifyMultiProductMarketingMetadata.toJSON((_j = message.metadataOneof) === null || _j === void 0 ? void 0 : _j.customShopifyMultiProductMarketingMetadata)
                : undefined);
        ((_k = message.metadataOneof) === null || _k === void 0 ? void 0 : _k.$case) === "autoReplyV1Metadata" &&
            (obj.autoReplyV1Metadata = ((_l = message.metadataOneof) === null || _l === void 0 ? void 0 : _l.autoReplyV1Metadata)
                ? AutoReplyV1Metadata.toJSON((_m = message.metadataOneof) === null || _m === void 0 ? void 0 : _m.autoReplyV1Metadata)
                : undefined);
        ((_o = message.metadataOneof) === null || _o === void 0 ? void 0 : _o.$case) === "shopifyRecommenderMetadata" &&
            (obj.shopifyRecommenderMetadata = ((_p = message.metadataOneof) === null || _p === void 0 ? void 0 : _p.shopifyRecommenderMetadata)
                ? ShopifyRecommenderMetadata.toJSON((_q = message.metadataOneof) === null || _q === void 0 ? void 0 : _q.shopifyRecommenderMetadata)
                : undefined);
        ((_r = message.metadataOneof) === null || _r === void 0 ? void 0 : _r.$case) === "bcaMerchantCatalogMetadata" &&
            (obj.bcaMerchantCatalogMetadata = ((_s = message.metadataOneof) === null || _s === void 0 ? void 0 : _s.bcaMerchantCatalogMetadata)
                ? BCAMerchantCatalogMetadata.toJSON((_t = message.metadataOneof) === null || _t === void 0 ? void 0 : _t.bcaMerchantCatalogMetadata)
                : undefined);
        ((_u = message.metadataOneof) === null || _u === void 0 ? void 0 : _u.$case) === "salesBotCommandMetadata" &&
            (obj.salesBotCommandMetadata = ((_v = message.metadataOneof) === null || _v === void 0 ? void 0 : _v.salesBotCommandMetadata)
                ? SalesBotMetadata.toJSON((_w = message.metadataOneof) === null || _w === void 0 ? void 0 : _w.salesBotCommandMetadata)
                : undefined);
        ((_x = message.metadataOneof) === null || _x === void 0 ? void 0 : _x.$case) === "customVtexAbandonedCheckoutMetadata" &&
            (obj.customVtexAbandonedCheckoutMetadata = ((_y = message.metadataOneof) === null || _y === void 0 ? void 0 : _y.customVtexAbandonedCheckoutMetadata)
                ? CustomVtexAbandonedCheckoutMetadata.toJSON((_z = message.metadataOneof) === null || _z === void 0 ? void 0 : _z.customVtexAbandonedCheckoutMetadata)
                : undefined);
        return obj;
    },
};
function createBaseQueryMetricsInput() {
    return {
        businessId: "",
        integrationId: "",
        startDate: undefined,
        endDate: undefined,
    };
}
export const QueryMetricsInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.integrationId !== "") {
            writer.uint32(18).string(message.integrationId);
        }
        if (message.startDate !== undefined) {
            Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined) {
            Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryMetricsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.integrationId = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            integrationId: isSet(object.integrationId)
                ? String(object.integrationId)
                : "",
            startDate: isSet(object.startDate) ? String(object.startDate) : undefined,
            endDate: isSet(object.endDate) ? String(object.endDate) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.integrationId !== undefined &&
            (obj.integrationId = message.integrationId);
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        return obj;
    },
};
function createBaseMetricCollection() {
    return { metrics: [] };
}
export const MetricCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.metrics) {
            Metric.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetricCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metrics.push(Metric.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            metrics: Array.isArray(object === null || object === void 0 ? void 0 : object.metrics)
                ? object.metrics.map((e) => Metric.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.metrics) {
            obj.metrics = message.metrics.map((e) => e ? Metric.toJSON(e) : undefined);
        }
        else {
            obj.metrics = [];
        }
        return obj;
    },
};
function createBaseMetric() {
    return {
        metricName: "",
        current: undefined,
        previous: undefined,
        change: undefined,
    };
}
export const Metric = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.metricName !== "") {
            writer.uint32(10).string(message.metricName);
        }
        if (message.current !== undefined) {
            MetricData.encode(message.current, writer.uint32(18).fork()).ldelim();
        }
        if (message.previous !== undefined) {
            MetricData.encode(message.previous, writer.uint32(26).fork()).ldelim();
        }
        if (message.change !== undefined) {
            MetricData.encode(message.change, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetric();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metricName = reader.string();
                    break;
                case 2:
                    message.current = MetricData.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.previous = MetricData.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.change = MetricData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            metricName: isSet(object.metricName) ? String(object.metricName) : "",
            current: isSet(object.current)
                ? MetricData.fromJSON(object.current)
                : undefined,
            previous: isSet(object.previous)
                ? MetricData.fromJSON(object.previous)
                : undefined,
            change: isSet(object.change)
                ? MetricData.fromJSON(object.change)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.metricName !== undefined && (obj.metricName = message.metricName);
        message.current !== undefined &&
            (obj.current = message.current
                ? MetricData.toJSON(message.current)
                : undefined);
        message.previous !== undefined &&
            (obj.previous = message.previous
                ? MetricData.toJSON(message.previous)
                : undefined);
        message.change !== undefined &&
            (obj.change = message.change
                ? MetricData.toJSON(message.change)
                : undefined);
        return obj;
    },
};
function createBaseMetricData() {
    return { metricOneof: undefined };
}
export const MetricData = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.metricOneof) === null || _a === void 0 ? void 0 : _a.$case) === "timeseries") {
            TimeSeriesAttributes.encode(message.metricOneof.timeseries, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.metricOneof) === null || _b === void 0 ? void 0 : _b.$case) === "scalar") {
            ScalarAttributes.encode(message.metricOneof.scalar, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetricData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metricOneof = {
                        $case: "timeseries",
                        timeseries: TimeSeriesAttributes.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.metricOneof = {
                        $case: "scalar",
                        scalar: ScalarAttributes.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            metricOneof: isSet(object.timeseries)
                ? {
                    $case: "timeseries",
                    timeseries: TimeSeriesAttributes.fromJSON(object.timeseries),
                }
                : isSet(object.scalar)
                    ? { $case: "scalar", scalar: ScalarAttributes.fromJSON(object.scalar) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.metricOneof) === null || _a === void 0 ? void 0 : _a.$case) === "timeseries" &&
            (obj.timeseries = ((_b = message.metricOneof) === null || _b === void 0 ? void 0 : _b.timeseries)
                ? TimeSeriesAttributes.toJSON((_c = message.metricOneof) === null || _c === void 0 ? void 0 : _c.timeseries)
                : undefined);
        ((_d = message.metricOneof) === null || _d === void 0 ? void 0 : _d.$case) === "scalar" &&
            (obj.scalar = ((_e = message.metricOneof) === null || _e === void 0 ? void 0 : _e.scalar)
                ? ScalarAttributes.toJSON((_f = message.metricOneof) === null || _f === void 0 ? void 0 : _f.scalar)
                : undefined);
        return obj;
    },
};
function createBaseQueryIntegrationStateInput() {
    return { businessId: "", integrationId: "" };
}
export const QueryIntegrationStateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.integrationId !== "") {
            writer.uint32(18).string(message.integrationId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryIntegrationStateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.integrationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            integrationId: isSet(object.integrationId)
                ? String(object.integrationId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.integrationId !== undefined &&
            (obj.integrationId = message.integrationId);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
