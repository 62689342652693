import React, { useMemo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Label,
  Box,
  useTheme,
  CircularProgress,
} from '@connectlyai-tenets/ui-styled-web';
import { DeleteEntriesModalProps, useDeleteEntriesModalProps } from './types';

export const useDeleteEntriesModal = ({ setOpen }: useDeleteEntriesModalProps) => {
  const copy = useMemo(() => {
    return {
      cancelText: 'Cancel',
      confirmText: 'Delete',
    };
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const theme = useTheme();
  return {
    onClose,
    copy,
    theme,
  };
};
export const DeleteEntriesModal = ({ open, setOpen, title, subtitle, onConfirm, loading }: DeleteEntriesModalProps) => {
  const { copy, onClose, theme } = useDeleteEntriesModal({ setOpen });
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Label variant="h6" fontWeight={500} sx={{ px: 3, pt: 3 }}>
        {title}
      </Label>
      <Label variant="body1" sx={{ px: 3, pt: 1, color: theme.palette.text.secondary }}>
        {subtitle}
      </Label>
      <DialogContent sx={{ pt: 1, width: theme.spacing(51), maxWidth: '100vw' }}>
        <Box sx={{ textAlign: 'right', pt: 2 }}>
          <Button variant="text" color="primary" onClick={onClose} disabled={loading}>
            {copy.cancelText}
          </Button>
          <Button variant="text" color="primary" onClick={onConfirm} disabled={loading}>
            {loading && <CircularProgress size={15} sx={{ color: theme.palette.text.secondary }} />}
            {!loading && copy.confirmText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
