import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { ChannelType } from '@connectlyai-idl/models/dist/models/common/channel/models';
import { mapChannelTypeToIconKind } from '@connectlyai-sdks/data-rooms';
import { SidebarDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectSearchState } from '../../state/searchSlice';
import { regexpPhoneNumberSpace } from '../../usecases/querySearchCustomers';
export const SearchResultComponentId = '2d0b5bd6-1931-4fb0-89c5-f91585db0b10';
const MAX_RESULT_ITEMS = 30;
function mapHighlightExec(text, textLower, searchInput) {
    const parts = [];
    let [remainIndex, remain] = [0, textLower];
    for (let i = 0; i < searchInput.length; i += 1) {
        const searchPart = searchInput[i];
        const index = remain.indexOf(searchPart);
        if (index < 0) {
            return [text];
        }
        parts.push(text.slice(remainIndex, remainIndex + index), text.slice(remainIndex + index, remainIndex + index + searchPart.length));
        remainIndex += index + searchPart.length;
        remain = textLower.slice(remainIndex);
    }
    parts.push(text.slice(remainIndex));
    return parts;
}
function mapHighlight(text, searchInput) {
    searchInput = searchInput.trim().toLowerCase();
    if (searchInput === '') {
        return [text];
    }
    const textLower = text.toLowerCase();
    const out = mapHighlightExec(text, textLower, searchInput.split(/\s+/g));
    if (out.length === 1) {
        const searchPhone = searchInput.replace(regexpPhoneNumberSpace, '');
        return mapHighlightExec(text, textLower, [searchPhone]);
    }
    return out;
}
export function mapSearchResultItemsToViewModel(items, searchInput) {
    if (items === undefined) {
        return [];
    }
    searchInput = searchInput.toLowerCase();
    return items.slice(0, MAX_RESULT_ITEMS).map((item) => {
        var _a, _b, _c, _d, _e, _f;
        const externalId = ((_a = item.channel) === null || _a === void 0 ? void 0 : _a.externalId) || '';
        const fullName = `${((_b = item.profile) === null || _b === void 0 ? void 0 : _b.firstName) || ''} ${((_c = item.profile) === null || _c === void 0 ? void 0 : _c.lastName) || ''}`;
        let [title, subtitle] = [fullName.trim(), externalId];
        if (title === '') {
            [title, subtitle] = [subtitle, ''];
        }
        const channelType = ((_d = item.channel) === null || _d === void 0 ? void 0 : _d.channelType) || ChannelType.CHANNEL_TYPE_UNSPECIFIED;
        const out = {
            id: ((_e = item.channel) === null || _e === void 0 ? void 0 : _e.id) || '',
            avatarUrl: ((_f = item.profile) === null || _f === void 0 ? void 0 : _f.profilePic) || '',
            avatarBadge: mapChannelTypeToIconKind(channelType),
            channelType,
            title,
            titleHighlight: mapHighlight(title, searchInput),
            subtitle,
            subtitleHighlight: mapHighlight(subtitle, searchInput),
            roomId: item.roomId,
        };
        if (out.subtitleHighlight.length === 1) {
            out.subtitle = '';
            out.subtitleHighlight[0] = '';
        }
        return out;
    });
}
const SearchResult = (_) => {
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const searchState = useFeatureSelector(selectSearchState);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(SearchResultComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { searchState: searchState, resultItems: mapSearchResultItemsToViewModel(searchState.resultItems, searchState.searchText) })) }));
};
export default SearchResult;
