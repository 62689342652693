import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State, ReactFlowDocumentDto } from './types';

const initialState: State = {
  documentId: null,
  documentDto: null,
};

export const slice = createSlice({
  name: 'flow-autoreply',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setDocumentId: (state, action: PayloadAction<string>) => {
      state.documentId = action.payload;
    },
    setDocumentDto: (state, action: PayloadAction<ReactFlowDocumentDto>) => {
      state.documentDto = action.payload;
    },
  },
});

export default slice.reducer;

export const { setDocumentDto, setDocumentId } = slice.actions;
