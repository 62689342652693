import React, { FC } from 'react';
import { Label, TableRow, TableCell, Tooltip, IconButton, CalendarTodayIcon } from '@connectlyai-tenets/ui-styled-web';
import { RowProps } from './types';

const ReadyToSendRow: FC<RowProps> = ({ id, readableName, createdAt, scheduledAt, onSchedule }) => (
  <TableRow>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
      }}
    >
      <Label variant="subtitle2">{readableName}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1" sx={{ color: 'success.light' }}>
        Ready to send
      </Label>
      <Label color="textSecondary" variant="body2">
        {scheduledAt && new Date(scheduledAt).toLocaleString()}
      </Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    />
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
        textAlign: 'right',
      }}
    >
      <Tooltip title="Continue" arrow placement="top">
        <IconButton onClick={() => onSchedule?.(id as string)}>
          <CalendarTodayIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  </TableRow>
);

export default ReadyToSendRow;
