export var MessageStatus;
(function (MessageStatus) {
    MessageStatus[MessageStatus["SENT"] = 0] = "SENT";
    MessageStatus[MessageStatus["RECEIVED"] = 1] = "RECEIVED";
})(MessageStatus || (MessageStatus = {}));
export var RoomViewState;
(function (RoomViewState) {
    RoomViewState[RoomViewState["NORMAL"] = 0] = "NORMAL";
    RoomViewState[RoomViewState["HIGHLIGHTED"] = 1] = "HIGHLIGHTED";
    RoomViewState[RoomViewState["SELECTED"] = 2] = "SELECTED";
})(RoomViewState || (RoomViewState = {}));
