import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EdgeAddChange,
  NodeChange,
  Node,
  NodeAddChange,
  NodeRemoveChange,
  NodeSelectionChange,
  EdgeChange,
} from 'react-flow-renderer';
import { useAtom } from 'jotai';
import produce from 'immer';
import { isFlowChecksOpenAtom } from '@atoms/flow';
import { flatMap } from 'lodash';
import { FlowChangeAppliers } from './types';
import {
  save,
  selectTemplateId,
  CREATE_NEW_TEMPLATE_ID,
  selectName,
  selectBody,
  selectButtons,
  selectFooter,
  selectHeaderText,
  selectHeaderMedia,
  normalize,
  selectStatus,
  setName,
} from '../../../../state/messageTemplates';
import { cancel, editNode } from '../../../../state/actions';
import {
  selectSelectedNode,
  selectNodes,
  selectIsAIGenerating,
  selectIsAISidebarOpen,
  selectIsDocumentEditable,
  setIsAISidebarOpen,
  selectEdges,
} from '../../../../state/flow';
import appStore from '../../../../state/store';
import {
  selectHeaderType,
  selectTemplateMappings,
  selectTemplateComponents,
} from '../../../../state/messageTemplates/selectors';
import { HeaderType } from '../../../../state/messageTemplates/types';
import { useFlowChangeAppliers, useNodeObjectChange } from '../../../../hooks/useFlowChangeAppliers';
import { createHandleId, HandleType } from '../../../../sdks/flow/createHandleId';
import { useCreateNode } from '../../../../hooks/useCreateNode';
import { createEdge } from '../../../../sdks/flow/createEdge';

export const useCampaignFlowChangeAppliers = (): FlowChangeAppliers => {
  const dispatch = useDispatch();

  const selectedNode = useSelector(selectSelectedNode);
  const isAISidebarOpen = useSelector(selectIsAISidebarOpen);
  const isAIGenerating = useSelector(selectIsAIGenerating);

  const [isFlowChecksOpen, setIsFlowChecksOpen] = useAtom(isFlowChecksOpenAtom);

  const { onNodeObjectChange } = useNodeObjectChange();
  const { templateName } = useCreateNode();

  // use getState rather than selector to get nodes and edges immediately
  const edgesCurrent = useCallback(() => selectEdges(appStore.getState()), []);
  const nodesCurrent = useCallback(() => selectNodes(appStore.getState()), []);

  // display or close template editor sidebar according to changes
  const showEditorFor = useCallback(
    (changes: NodeChange[], currentNodes: Node[]) => {
      // if a new node is added edit the node
      const addedNode = changes.find(
        (change) => change.type === 'add' && (change.item.selected === undefined || change.item.selected),
      ) as NodeAddChange;
      if (addedNode) {
        dispatch(editNode({ node: addedNode.item }));

        // if flow checks open close flow checks
        if (isFlowChecksOpen) {
          setIsFlowChecksOpen(false);
        }

        return;
      }

      // open template edit sidebar if node is selected and close sidebar if current node id is deselected
      const nodeSelectionChanges = changes.filter((change) => change.type === 'select') as NodeSelectionChange[];
      const selectChange = nodeSelectionChanges.find((change) => change.selected);
      if (selectChange) {
        const nodeToSelect = currentNodes.find((node) => node.id === selectChange.id) as Node;
        if (!nodeToSelect) return;
        dispatch(editNode({ node: nodeToSelect, showErrors: true }));

        // if editor is showing for a rejected template rename it
        const status = selectStatus(appStore.getState());
        if (status === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
          const newName = templateName();
          dispatch(setName(newName));
        }

        // after AI stopped generating move to node by selecting the node
        if (isAISidebarOpen && !isAIGenerating) {
          dispatch(setIsAISidebarOpen(false));
        }

        // if flow checks open close flow checks
        if (isFlowChecksOpen) {
          setIsFlowChecksOpen(false);
        }

        return;
      }

      // rest of the code should only run if a node is already selected
      if (!selectedNode) return;

      const hasDeselect = nodeSelectionChanges.some((change) => !change.selected && change.id === selectedNode?.id);
      if (hasDeselect) {
        dispatch(cancel());
        return;
      }

      // close template edit sidebar if currently selected node is deleted
      const nodeDeleteChanges = changes.filter((change) => change.type === 'remove') as NodeRemoveChange[];
      const isDeletingSelectedNode = nodeDeleteChanges.find((change) => change.id === selectedNode?.id);
      if (isDeletingSelectedNode) {
        dispatch(cancel());
      }
    },
    [dispatch, selectedNode, isAISidebarOpen, isAIGenerating, isFlowChecksOpen, setIsFlowChecksOpen, templateName],
  );

  // call this function to save if selected node is going to be changed
  // it returns true if template has no errors, false if it has errors
  const saveTemplate = useCallback(() => {
    if (!selectedNode) {
      return true;
    }

    const { type } = selectedNode;
    const isTemplatedMessage =
      type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE' || type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE';
    if (!isTemplatedMessage) {
      return true;
    }

    dispatch(normalize());

    const appState = appStore.getState();
    const name = selectName(appState);
    const body = selectBody(appState);
    const buttons = selectButtons(appState);
    const footer = selectFooter(appState);
    const textHeader = selectHeaderText(appState);
    const mediaHeader = selectHeaderMedia(appState);
    const headerType = selectHeaderType(appState);
    const selectedTemplateId = selectTemplateId(appState);
    const templateComponents = selectTemplateComponents(appState);
    const templateMappings = selectTemplateMappings(appState);

    const templateExist = selectedTemplateId !== CREATE_NEW_TEMPLATE_ID && selectedTemplateId !== null;
    const updatedNode = produce(selectedNode, (draft) => {
      switch (type) {
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
          if (templateExist) {
            draft.data.v1.templateId = selectedTemplateId;
          } else {
            draft.data.v1.templateId = CREATE_NEW_TEMPLATE_ID;
          }

          if (draft.data.v1.name) draft.data.v1.name = name;

          if (templateComponents) {
            draft.data.v1.messageTemplateInput = {
              templateComponents,
              name,
            };
          }
          const templateMappingsProcessed: { [key: string]: string } = {};
          Object.keys(templateMappings).forEach((key) => {
            const variable = templateMappings[key];
            templateMappingsProcessed[key] = variable.replace(/{{\s*([\w.]+)\s*}}/, '$1');
          });

          break;
        }
        case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
          if (templateExist) {
            draft.data.v1.waMessageTemplateId = selectedTemplateId;
          } else {
            draft.data.v1.waMessageTemplateId = CREATE_NEW_TEMPLATE_ID;
          }

          if (name) draft.data.v1.name = name;
          if (body) draft.data.v1.body = body;
          if (footer) draft.data.v1.footer = footer;
          if (buttons.length > 0) draft.data.v1.buttons = buttons;
          if (mediaHeader.length > 0) draft.data.v1.mediaHeader = mediaHeader;
          if (textHeader) draft.data.v1.textHeader = textHeader;
          if (headerType !== HeaderType.Text || textHeader) draft.data.v1.headerType = headerType;
          break;
        }
        default: {
          break;
        }
      }
      return draft;
    });

    onNodeObjectChange([
      {
        id: updatedNode.id,
        item: updatedNode,
      },
    ]);

    dispatch(save());

    // use getState rather than selector to immediately get errors
    return !appStore.getState().messageTemplates.templateBuilderParams?.errors;
  }, [dispatch, onNodeObjectChange, selectedNode]);

  // prepare changes to select added node, and deselect all others
  const selectNewNode = useCallback((changes: NodeChange[], currentNodes: Node[]) => {
    return [
      // select added node
      ...changes.map((change) =>
        change.type === 'add' ? { ...change, item: { ...change.item, selected: true } } : change,
      ),
      // deselect all other nodes if a new node is added
      ...currentNodes.map(
        (node) =>
          ({
            id: node.id,
            type: 'select',
            selected: false,
          }) as NodeSelectionChange,
      ),
    ];
  }, []);

  // returns true if a node is selected and going to be changed with current changes
  // returns false when there is no node selected
  const isReplacingSelectedNode = useCallback(
    (changes: NodeChange[]) => {
      if (!selectedNode) return false;

      const addNewNode = changes.some((change) => change.type === 'add');
      if (addNewNode) return true;

      const nodeSelectionChanges = changes.filter((change) => change.type === 'select') as NodeSelectionChange[];

      const newSelection = nodeSelectionChanges.some((change) => change.selected && change.id !== selectedNode?.id);
      if (newSelection) return true;

      const deselectCurrent = nodeSelectionChanges.some((change) => !change.selected && change.id === selectedNode?.id);
      if (deselectCurrent) return true;

      return false;
    },
    [selectedNode],
  );

  const onNodesChangeRequested = useCallback(
    (changes: NodeChange[]) => {
      let hasTemplateError = false;
      if (isReplacingSelectedNode(changes)) {
        hasTemplateError = !saveTemplate();
      }
      if (hasTemplateError) {
        changes = [...changes.filter((change) => change.type !== 'select')];
      } else {
        if (changes.find((change) => change.type === 'add' && change.item.selected === undefined)) {
          // if a new node is added select that node
          changes = selectNewNode(changes, nodesCurrent());
        }

        // display or close template editor sidebar according to changes
        showEditorFor(changes, nodesCurrent());
      }
      return changes;
    },
    [isReplacingSelectedNode, showEditorFor, nodesCurrent, saveTemplate, selectNewNode],
  );

  const flowChangeAppliers = useFlowChangeAppliers({ onNodesChangeRequested, selectIsDocumentEditable });
  const { isHandleConnected, onNodesChange, onEdgesChange } = flowChangeAppliers;
  const { createNode } = useCreateNode();

  const createNodeAtHandle = useCallback(
    (handleType: HandleType) => {
      const { nodeId: fromNodeId, buttonIndex, nodeType: currentNodeType } = handleType;
      const nodeFrom = nodesCurrent().find((node) => node.id === fromNodeId);
      if (!nodeFrom) return;
      if (isHandleConnected(handleType)) return;

      let newNodeType = currentNodeType;
      switch (currentNodeType) {
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
          break;
        }
        case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE';
          break;
        }
        default: {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
          break;
        }
      }

      const newNode = createNode(newNodeType, {
        position: {
          x: nodeFrom.position.x + 400 + ((buttonIndex ?? 0) % 2) * 100,
          y: nodeFrom.position.y + (buttonIndex ?? 0) * 200 - 100,
        },
      }) as Node;

      onNodesChange([
        {
          type: 'add',
          item: newNode,
        },
      ]);

      const handleId = createHandleId(handleType);
      const edgeAdd: EdgeAddChange = {
        type: 'add',
        item: createEdge({
          source: fromNodeId,
          sourceHandle: handleId,
          target: newNode.id,
          targetHandle: `${newNode.id}:on-execute`,
        }),
      };
      onEdgesChange([edgeAdd]);
    },
    [createNode, isHandleConnected, nodesCurrent, onEdgesChange, onNodesChange],
  );

  const cleanAfterButtonIsDeleted = useCallback(
    (buttonIndex: number) => {
      if (!selectedNode || !selectedNode?.type) return;

      onEdgesChange(
        flatMap(
          edgesCurrent().map((edge) => {
            if (!edge.sourceHandle?.startsWith(`${selectedNode?.id}:button-click:`)) return [];
            const foundIndexString = edge.sourceHandle.replace(`${selectedNode?.id}:button-click:`, '');
            const foundIndex = parseInt(foundIndexString, 10);
            if (foundIndex < buttonIndex) return [];

            const deleteEdge: EdgeChange[] = [
              {
                id: edge.id,
                type: 'remove',
              },
            ];

            if (foundIndex === buttonIndex) return deleteEdge;

            return [
              ...deleteEdge,
              {
                type: 'add',
                item: createEdge({
                  ...edge,
                  sourceHandle: `${selectedNode?.id}:button-click:${foundIndex - 1}`,
                }),
              },
            ];
          }),
        ),
      );
    },
    [edgesCurrent, onEdgesChange, selectedNode],
  );

  return {
    ...flowChangeAppliers,
    createNodeAtHandle,
    cleanAfterButtonIsDeleted,
  };
};
