import React, { FC } from 'react';
import { generateUrlRoute } from '@connectlyai-tenets/navigation';
import { Label, TableRow, TableCell, Tooltip, IconButton, AssessmentIcon } from '@connectlyai-tenets/ui-styled-web';
import { track } from 'src/utils';
import { RowProps } from './types';
import { useCampaignsDIContainer } from '../../features/campaigns';

const CompletedRow: FC<RowProps> = ({ readableName, createdAt, scheduledAt, outcome, id, businessId }) => {
  const di = useCampaignsDIContainer();
  if (!di) {
    return null;
  }
  const handleViewResultsButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { campaignId, url } = e.currentTarget.dataset;
    track('clicks view_campaign_button', { businessId, campaignId });
    if (e.currentTarget.dataset.url) {
      try {
        const navigator = di.navigatorProvider();
        const urlRoute = generateUrlRoute(url || '');
        navigator.navigate(urlRoute);
      } catch (err) {
        const log = di.logProvider();
        log.error('Invalid link', () => ({ url }));
      }
    }
  };
  return (
    <TableRow>
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
          borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
        }}
      >
        <Label variant="subtitle2">{readableName}</Label>
      </TableCell>
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
        }}
      >
        <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
      </TableCell>
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
        }}
      >
        <Label variant="body1">Completed</Label>
        <Label color="textSecondary" variant="body2">
          {scheduledAt && new Date(scheduledAt).toLocaleString()}
        </Label>
      </TableCell>
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
        }}
      >
        {outcome?.executionDataV1?.numDispatched && (
          <Label variant="body1">{outcome?.executionDataV1?.numDispatched}</Label>
        )}
      </TableCell>
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
          borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
          textAlign: 'right',
        }}
      >
        <Tooltip title="View Results" arrow placement="top">
          <IconButton
            onClick={handleViewResultsButtonClick}
            data-url={outcome?.reportV1?.url || ''}
            data-campaignId={id}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default CompletedRow;
