import React, { VFC } from 'react';
import { Box, Label } from '@connectlyai-tenets/ui-styled-web';
import { useSelector } from 'react-redux';
import { selectBusinessName, selectBusinessProfilePicture, selectStep } from '../../features/welcome';
import { SMBOnboardingHeaderProps } from './types';
import { applyHook } from '../../utils';

const useSMBOnboardingHeader = (_props: Partial<SMBOnboardingHeaderProps>): Partial<SMBOnboardingHeaderProps> => {
  const step = useSelector(selectStep);
  const businessProfilePicture = useSelector(selectBusinessProfilePicture);
  const businessName = useSelector(selectBusinessName);

  return {
    businessName,
    businessProfilePicture,
    step,
  };
};

export const SMBOnboardingHeaderPresentation: VFC<SMBOnboardingHeaderProps> = ({
  businessName,
  businessProfilePicture,
  isBilling,
  step,
}) => {
  let image = null;
  if (isBilling) {
    image = (
      <Box
        alt=""
        component="img"
        height={200}
        draggable={false}
        src={`${process.env.PUBLIC_URL}/assets/welcome/onboarding_payment.png`}
      />
    );
  } else {
    switch (step) {
      case 'phoneNumberSelector':
      case 'phoneNumberOld':
      case 'businessName':
      case 'businessProfilePicture': {
        image = (
          <Box sx={{ width: 511, height: 161, position: 'relative', alignSelf: 'flex-end' }}>
            <Box
              alt=""
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/welcome/onboarding_whatsapp_phone_preview.png`}
              width={511}
              height={161}
              draggable={false}
            />
            <Box
              width={46}
              height={46}
              src={businessProfilePicture}
              component="img"
              sx={{
                position: 'absolute',
                left: 100,
                top: 81,
                borderRadius: '50%',
                overflow: 'hidden',
                opacity: businessProfilePicture ? 1 : 0,
                userSelect: 'none',
              }}
              draggable={false}
            />
            <Box
              sx={{
                position: 'absolute',
                left: 154,
                top: 82,
                maxWidth: 200,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Label
                noWrap
                sx={{
                  fontSize: 22,
                  lineHeight: '26px',
                  fontWeight: 600,
                  userSelect: 'none',
                }}
              >
                {businessName || 'Business Name'}
              </Label>
              <Box
                alt=""
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/welcome/whatsapp_verified_badge.png`}
                width={22}
                height={22}
                draggable={false}
              />
            </Box>
          </Box>
        );
        break;
      }
      case 'connectWhatsApp': {
        image = (
          <Box
            alt=""
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/welcome/onboarding_whatsapp_connect.png`}
            width={332}
            height={174}
            draggable={false}
          />
        );
        break;
      }
      case 'connectShopify': {
        image = (
          <Box
            alt=""
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/welcome/onboarding_shopify_connect.png`}
            width={219}
            height={157}
            draggable={false}
          />
        );
        break;
      }
      default: {
        break;
      }
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        width: '100%',
        background: '#F6F8FF',
        userSelect: 'none',
      }}
    >
      {image}
    </Box>
  );
};

export const SMBOnboardingHeader = applyHook(SMBOnboardingHeaderPresentation, useSMBOnboardingHeader);
