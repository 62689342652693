const sourceKey = 'source';
export default class ApiError extends Error {
    constructor(code, message, isUnrecognizedError, details) {
        super(message);
        this.code = code;
        this.messageDetails = message;
        this.details = details;
        this.isUnrecognizedError = isUnrecognizedError;
        Object.setPrototypeOf(this, ApiError.prototype);
    }
    getMessageDetails() {
        if (this.isUnrecognizedError && this.details && sourceKey in this.details) {
            return this.details[sourceKey];
        }
        return this.messageDetails;
    }
    getCode() {
        return this.code;
    }
}
