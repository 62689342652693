import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@hooks';
import { CampaignsFrequencyCap } from '../../components/CampaignsFrequencyCap';
import { CampaignsUTM } from '../../components/CampaignsUTM';

const useCampaigns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.campaigns' });
  const { ffEnableCampaignsUTMSettings } = useFeatureFlag(['ffEnableCampaignsUTMSettings']);
  const { title } = useMemo(() => {
    return {
      title: t('title'),
    };
  }, [t]);
  return {
    title,
    ffEnableCampaignsUTMSettings,
  };
};

export const Campaigns = () => {
  const { ffEnableCampaignsUTMSettings } = useCampaigns();
  return (
    <>
      <CampaignsFrequencyCap />
      {ffEnableCampaignsUTMSettings && <CampaignsUTM />}
    </>
  );
};
