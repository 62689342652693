import React from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';

export const BorderAnimation = () => {
  return (
    <>
      <Box className="rephrase" />
      <Box
        sx={{
          borderRadius: '10px',
          position: 'absolute',
          top: '2px',
          left: '2px',
          width: `calc(100% - 4px)`,
          height: `calc(100% - 4px)`,
          backgroundColor: (theme) => theme.palette.background.paper,
          boxSizing: 'border-box',
        }}
      />
    </>
  );
};
