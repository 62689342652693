import { DATE_PERIOD_LIST, DatePeriodKey, DEFAULT_DATE_PERIOD } from '@hooks';
import { DATA_LAG_DAYS } from '@scenes/Sofia/constants';
import { atom } from 'jotai';
import { DATE_RANGES } from '@hooks/useDateRange/constants';
import {
  OnboardingStep,
  ShopifyOnboardingOptions,
  ONBOARDING_STEP_LIST,
  ShopifyAbandonedCheckoutOnboardingStep,
} from '../../features/welcome';
import { getPreviousOnboardingStep, getNextOnboardingStep, getIsNextStepDisabled } from './mappers';
import { DateRange } from '../../components/DateRangePicker';
import { TimeRange, buildQueryRanges } from '../../utils/analytics';

export const dateRangeAtom = atom<DateRange>({
  ...DATE_RANGES.last1Month,
  key: 'selection',
});

export const dateRangeLast7DaysAtom = atom<DateRange>({
  ...DATE_RANGES.last7Days,
  key: 'selection',
});

const dateInUTC = () => new Date(`${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`);

export const claimingPhoneNumberAtom = atom<boolean>(false);
export const waConnectOTPCodeAtom = atom<string>('');
export const overviewPeriodAtom = atom<DatePeriodKey>(DEFAULT_DATE_PERIOD);
export const useNewPhoneNumberAtom = atom<boolean>(true);
export const overviewDatesAtom = atom(DATE_PERIOD_LIST[DEFAULT_DATE_PERIOD].getDates(dateInUTC()));

export const sofiaAnalyticsSummaryTimeRangesAtom = atom<[TimeRange, TimeRange]>((get) => {
  const dateRange = get(overviewDatesAtom);
  const period = get(overviewPeriodAtom);
  const timeRange: DateRange = { key: period, startDate: dateRange[0], endDate: dateRange[1] };
  timeRange.startDate = new Date(timeRange.startDate.getTime() - DATA_LAG_DAYS * 24 * 60 * 60 * 1000);
  timeRange.endDate = new Date(timeRange.endDate.getTime() - DATA_LAG_DAYS * 24 * 60 * 60 * 1000);
  const defaultRange = { timeRangeStart: '', timeRangeEnd: '' };
  const queryRanges = buildQueryRanges(timeRange, 2);
  return [queryRanges[0] || defaultRange, queryRanges[1] || defaultRange];
});

export const homeSofiaAnalysisTimeRangesAtom = atom<[TimeRange, TimeRange]>((get) => {
  const dateRange = get(dateRangeLast7DaysAtom);
  const defaultRange = { timeRangeStart: '', timeRangeEnd: '' };
  const queryRanges = buildQueryRanges(dateRange, 2, true);
  return [queryRanges[0] || defaultRange, queryRanges[1] || defaultRange];
});

export const shopifyAbandonedCheckoutOnboardingStepAtom = atom<ShopifyAbandonedCheckoutOnboardingStep>(
  ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep,
);
export const smbOnboardingStepAtom = atom<OnboardingStep>('phoneNumberSelector');
export const smbOnboardingNextStepAtom = atom<OnboardingStep>((get) => {
  const useNewPhoneNumber = get(useNewPhoneNumberAtom);
  const step = get(smbOnboardingStepAtom);
  return getNextOnboardingStep(step, useNewPhoneNumber);
});
export const smbOnboardingPreviousStepAtom = atom<OnboardingStep>((get) => {
  const useNewPhoneNumber = get(useNewPhoneNumberAtom);
  const step = get(smbOnboardingStepAtom);
  return getPreviousOnboardingStep(step, useNewPhoneNumber);
});

export const smbOnboardingProgressAtom = atom<number>((get) => {
  const step = get(smbOnboardingStepAtom);
  const index =
    ONBOARDING_STEP_LIST.findIndex((item) => item === step || (Array.isArray(item) && item.includes(step))) || 0;
  return ((index + 1) / ONBOARDING_STEP_LIST.length) * 100;
});
export const shopifyOnboardingOptionsAtom = atom<ShopifyOnboardingOptions | undefined>(undefined);
export const shopifyOnboardingConnectUrlAtom = atom<string>((get) => {
  const options = get(shopifyOnboardingOptionsAtom);
  if (!options) {
    return '';
  }
  return options.connectUrl;
});

export const shopifyOnboardingStoreUrlAtom = atom<string>((get) => {
  const options = get(shopifyOnboardingOptionsAtom);
  if (!options) {
    return '';
  }
  return options.storeUrl;
});
export const businessNameAtom = atom<string>('');
export const phoneNumberAtom = atom<string>('');
export const confirmationAtom = atom<boolean>(false);
export const isNextStepDisabledAtom = atom<boolean>((get) => {
  const step = get(smbOnboardingStepAtom);
  const businessName = get(businessNameAtom);
  const phoneNumber = get(phoneNumberAtom);
  const connectUrl = get(shopifyOnboardingConnectUrlAtom);
  const confirmation = get(confirmationAtom);
  return getIsNextStepDisabled(step, businessName, phoneNumber, connectUrl, confirmation);
});
export const isPreviousStepDisabledAtom = atom<boolean>((get) => {
  const step = get(smbOnboardingStepAtom);
  return step === 'phoneNumberSelector';
});
