import React, { useMemo } from 'react';
import { Box, ConnectlyCard, LightningIcon, CampaignStartIconV2 } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useMeData, selectRole } from '@hooks';
import { useHomeNavigator } from '@scenes/Home/hooks/useHomeNavigator';
import { LinkCardV2 } from './LinkCards';
import { SofiaCTAv2 } from './SofiaCTA';
import { WhatsAppOBAStatusV2 } from './WhatsAppOBAStatus';

export type LinkCardProps = {
  title: string;
  subtitle: string;
  navigate: () => void;
  icon?: ({ width, height }: { width: string | number; height: string | number }) => React.ReactNode;
};

// todo john - skeleton state for loading

export const ActionCards = () => {
  const { data: role } = useMeData({ select: selectRole });
  const isAgent = useMemo(() => role === 'ROLE_AGENT', [role]);

  const { handleOpenSofia, handleOpenCampaigns, handleOpenAutomations } = useHomeNavigator();

  const { t } = useTranslation('translation', { keyPrefix: 'welcome.home' });
  const linkCards: LinkCardProps[] = useMemo(() => {
    return [
      {
        title: t('campaign.title'),
        subtitle: t('campaign.callToAction'),
        // eslint-disable-next-line react/no-unstable-nested-components
        icon: () => <CampaignStartIconV2 width={20} height={20} />,
        navigate: handleOpenCampaigns,
      },
      {
        title: t('automations.title'),
        subtitle: t('automations.callToAction'),
        // eslint-disable-next-line react/no-unstable-nested-components
        icon: () => <LightningIcon width={20} height={20} />,
        navigate: handleOpenAutomations,
      },
    ];
  }, [t, handleOpenAutomations, handleOpenCampaigns]);

  return (
    <ConnectlyCard title="Get the most out of Connectly">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            gap: 2,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {linkCards.map((linkCard: LinkCardProps) => {
            return <LinkCardV2 key={linkCard.title} {...linkCard} />;
          })}
        </Box>

        <Box
          sx={{
            gap: 2,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <SofiaCTAv2 navigate={handleOpenSofia} />
          {!isAgent && <WhatsAppOBAStatusV2 />}
        </Box>
      </Box>
    </ConnectlyCard>
  );
};
