import { useCallback } from 'react';
import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { AnalyticsType, track } from 'src/utils/analytics';

export const useAnalytics = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const sendAnalytics = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (type: AnalyticsType, data?: Record<string, unknown>) => {
      track(type, {
        businessId,
        ...data,
      });
    },
    [businessId],
  );

  return { sendAnalytics };
};
