import { useQuery } from '@tanstack/react-query';
import {
  LIST_CAMPAIGN_PRESETS_URL,
  ListCampaignsPresetsOptions,
  ListCampaignsPresetsParametersPath,
  ListCampaignsPresetsParametersBody,
} from './types';
import { api } from '../../../../api';

export const useListCampaignsPresetsData = ({
  businessId,
  ...options
}: ListCampaignsPresetsOptions & ListCampaignsPresetsParametersPath & ListCampaignsPresetsParametersBody) =>
  useQuery(
    ['listCampaignsPresets', businessId],
    () =>
      api.flowV1(LIST_CAMPAIGN_PRESETS_URL, 'post', {
        path: {
          'query.businessId': businessId,
        },
      }),
    options,
  );
