import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const UPDATE_RESOURCE_LIMIT_URL = '/v1/businesses/{input.businessId}/update_by_resource/ratelimit';
type UpdateResourceLimit = typeof UPDATE_RESOURCE_LIMIT_URL;

export type UpdateResourceLimitBodyInput =
  Paths['profileV1'][UpdateResourceLimit]['post']['parameters']['body']['input'];

export type UpdateResourceLimitParameters = UpdateResourceLimitBodyInput & {
  businessId: string;
};

export type UpdateResourceLimitResponse = Paths['profileV1'][UpdateResourceLimit]['post']['responses']['200']['schema'];

export type UpdateResourceLimitOptions = Omit<
  UseMutationOptions<UpdateResourceLimitResponse, ConnectlyError, UpdateResourceLimitParameters, unknown>,
  'mutationKey' | 'mutupdateFn'
>;
