import { useCallback, useEffect, useMemo } from 'react';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { Node, Edge, NodeChange, EdgeChange } from 'react-flow-renderer';
import { v4 as uuidv4 } from 'uuid';
import { ulid } from 'ulid';
import { resetFlowChart } from '../../state/actions/flowChart';
import { immutableReplace } from '../../utils/immutableReplace';
import {
  selectPreviewDocumentId,
  setCampaignName,
  setDocumentId,
  setEdges,
  setNodes,
  setSavedCampaignName,
  setRevisionId,
  setIsCampaignNameDialogOpen,
  setLanguage,
  setAudience,
} from '../../state/flow';
import {
  replaceUnsentMessageTemplate,
  UnsentMessageTemplate,
  DEFAULT_UNSENT_MESSAGE_TEMPLATE,
  CREATE_NEW_TEMPLATE_ID,
  recalculateAllErrors,
} from '../../state/messageTemplates';
import { MessageNodeProps } from '../../components/MessageNode/types';
import { getMediaHeaderType } from '../../utils';
import { useChangesQueue } from '../useChangesQueue';
import { useFlowDocumentData } from '../useFlowDocumentData';
import { selectBusinessId, useMeData } from '../useMeData';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { useMessageTemplateGroupsData } from '../useMessageTemplateGroupsData';
import { useListCampaignsWithFlowData } from '../useListCampaignsWithFlowData';

// TODO move to an independent sdk or hook
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUnsentMessageTemplate = (node: any, dispatch: Dispatch<AnyAction>) => {
  if (!node.data) return;

  const { id: nodeId, type: nodeType } = node;

  let fixedNode = node;

  // fix for templates that were accidentally created with footer: { example: string[]; text: string }
  // instead of just footer: string
  // only happens for nodes that are of type "FLOW_OBJECT_TYPE_SEND_WA_MESSAGE"

  if (node.data.v1 && node.data.v1.footer?.example) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fixedNode = produce(node, (draft: any) => {
      draft.data.v1.footer = node.data.v1.footer.text;
    });
  }

  const v1 = (fixedNode as MessageNodeProps).data?.v1;
  if (!v1) return;

  const templateId = v1.waMessageTemplateId || v1.templateId;
  if (templateId && templateId !== CREATE_NEW_TEMPLATE_ID) return;

  const messageTemplate: UnsentMessageTemplate = DEFAULT_UNSENT_MESSAGE_TEMPLATE();

  switch (nodeType) {
    case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
      const { name, body, footer, buttons, textHeader, mediaHeader, headerType } = v1;
      if (!name && !body && !footer && !buttons && !textHeader && !mediaHeader) return;
      if (name) {
        messageTemplate.name = name;
      }
      let { templateComponents } = messageTemplate;

      if (!templateComponents) return;

      if (body) {
        templateComponents = [...templateComponents.filter((x) => !x.body), { body: { text: { text: body } } }];
      }
      if (footer) {
        templateComponents = [...templateComponents.filter((x) => !x.footer), { footer: { text: { text: footer } } }];
      }
      if (textHeader) {
        templateComponents = [
          ...templateComponents.filter((x) => !x.header),
          { header: { text: { text: textHeader } } },
        ];
      }
      if (buttons) {
        templateComponents = [...templateComponents.filter((x) => !x.button), ...buttons];
      }
      if (mediaHeader && headerType) {
        templateComponents = [
          ...templateComponents.filter((x) => !x.header),
          {
            header: {
              media: {
                type: getMediaHeaderType(headerType),
                example: mediaHeader,
              },
            },
          },
        ];
      }
      dispatch(
        replaceUnsentMessageTemplate({
          nodeId,
          template: { ...messageTemplate, templateComponents },
        }),
      );
      break;
    }
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
      const { name, messageTemplateInput } = v1;
      if (!messageTemplateInput) return;

      if (name) {
        messageTemplate.name = name;
      }

      const { templateComponents } = messageTemplateInput;
      if (!templateComponents) return;

      dispatch(
        replaceUnsentMessageTemplate({
          nodeId,
          template: { ...messageTemplate, templateComponents },
        }),
      );
      break;
    }
    default: {
      break;
    }
  }
};

export const useCampaignFromPreview = (loadPreviewIntoState = false) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading: isLoadingTemplates } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
  });

  const previewDocumentId = useSelector(selectPreviewDocumentId);

  const { data: campaignsWithFlowList } = useListCampaignsWithFlowData({
    businessId: businessId as string,
    enabled: !!businessId,
    flowDocumentId: previewDocumentId,
  });

  const dispatch = useDispatch();

  const { markIdsOnline } = useChangesQueue();
  const { onNodesChange, onEdgesChange } = useCampaignFlowChangeAppliers();

  const {
    data: previewDocument,
    isLoading: isLoadingPreviewDocument,
    isSuccess,
  } = useFlowDocumentData({
    businessId: businessId as string,
    flowDocumentId: previewDocumentId,
    enabled: !!businessId && !!previewDocumentId,
  });

  const loadFlowFromPreview = useCallback(() => {
    if (previewDocument?.entity?.nodes && previewDocument?.entity?.edges) {
      const fetchedNodes = previewDocument?.entity?.nodes.map((node) => (node.options ? JSON.parse(node.options) : {}));
      const fetchedEdges = previewDocument?.entity?.edges.map((edge) => (edge.options ? JSON.parse(edge.options) : {}));

      dispatch(setNodes(fetchedNodes));
      dispatch(setEdges(fetchedEdges));
      dispatch(setRevisionId(previewDocument.entity?.lastRevisionId || ''));

      markIdsOnline(fetchedNodes.map((node) => node.id));
      markIdsOnline(fetchedEdges.map((edge) => edge.id));

      dispatch(resetFlowChart());

      fetchedNodes.forEach((node) => {
        // load unsent templates into template slice
        updateUnsentMessageTemplate(node, dispatch);

        // load campaign language into flow slice
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' && node.data?.v1?.campaignConfig?.language) {
          dispatch(setLanguage(node.data?.v1?.campaignConfig?.language));
        }

        // load audience into flow slice
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' && node.data?.v1?.sendoutConfig?.audienceSegmentId) {
          dispatch(
            setAudience({
              segmentId: node.data?.v1?.sendoutConfig?.audienceSegmentId,
              isEnabled: Boolean(node.data?.v1?.sendoutConfig?.audienceSegmentId),
            }),
          );
        }
      });
      dispatch(recalculateAllErrors(fetchedNodes));
    }
  }, [dispatch, markIdsOnline, previewDocument]);

  useEffect(() => {
    if (isSuccess && loadPreviewIntoState) {
      loadFlowFromPreview();
    }
  }, [loadFlowFromPreview, loadPreviewIntoState, isSuccess]);

  // start from scratch and send nodes to backend by their id
  const duplicateCampaignFromPreview = useCallback(() => {
    dispatch(setNodes([]));
    dispatch(setEdges([]));

    let nodesToDup = previewDocument?.entity?.nodes?.map((node) => (node.options ? JSON.parse(node.options) : {}));
    let edgesToDup = previewDocument?.entity?.edges?.map((edge) => (edge.options ? JSON.parse(edge.options) : {}));

    nodesToDup = produce(nodesToDup, (draft) => {
      const campaignToDup: Node = draft?.filter(
        (node: Node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN',
      )[0];
      if (campaignToDup.data.v1) delete campaignToDup.data.v1;
      return draft;
    });

    // need to regenerate all ids for nodes and edges
    nodesToDup?.forEach((node) => {
      const newId = ulid();
      nodesToDup = immutableReplace(nodesToDup, node.id, newId);
      edgesToDup = immutableReplace(edgesToDup, node.id, newId);
    });
    edgesToDup?.forEach((edge) => {
      const newId = ulid();
      nodesToDup = immutableReplace(nodesToDup, edge.id, newId);
      edgesToDup = immutableReplace(edgesToDup, edge.id, newId);
    });

    const nodeChanges: NodeChange[] =
      nodesToDup?.map((node) => ({
        type: 'add',
        id: node.id as string,
        item: { ...node, selected: false } as Node,
      })) ?? [];

    const edgeChanges: EdgeChange[] =
      edgesToDup?.map((edge) => ({
        type: 'add',
        id: edge.id as string,
        item: edge as Edge,
      })) ?? [];

    onNodesChange(nodeChanges);
    onEdgesChange(edgeChanges);

    dispatch(resetFlowChart());
    nodesToDup?.forEach((node) => {
      // load unsent templates into template slice
      updateUnsentMessageTemplate(node, dispatch);
    });

    dispatch(recalculateAllErrors(nodesToDup || []));

    dispatch(setDocumentId(uuidv4()));
    dispatch(setCampaignName(`${previewDocument?.entity?.name} copy`));
  }, [dispatch, onEdgesChange, onNodesChange, previewDocument]);

  const startCampaignFromPreview = useCallback(() => {
    dispatch(setSavedCampaignName(previewDocument?.entity?.name ?? ''));
    dispatch(setCampaignName(previewDocument?.entity?.name ?? ''));
    dispatch(setDocumentId(previewDocumentId));
    dispatch(setIsCampaignNameDialogOpen(false));
  }, [dispatch, previewDocument, previewDocumentId]);

  const isCampaignSentBefore: boolean = useMemo(() => {
    const previewCampaign = campaignsWithFlowList?.entity?.campaigns?.find(
      (campaign) => campaign.flowDocumentId === previewDocumentId,
    );
    if (!previewCampaign) return false;
    if (previewCampaign.status?.sentCount && previewCampaign.status?.sentCount > 0) return true;
    if (previewCampaign.status?.activeCount && previewCampaign.status?.activeCount > 0) return true;
    return false;
  }, [campaignsWithFlowList, previewDocumentId]);

  return {
    campaignsWithFlowList,
    duplicateCampaignFromPreview,
    loadFlowFromPreview,
    isCampaignSentBefore,
    isLoadingPreviewDocument: isLoadingPreviewDocument || isLoadingTemplates,
    startCampaignFromPreview,
    previewDocument,
  };
};
