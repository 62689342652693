import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAtom } from 'jotai';
import { featureFlagsAtom } from '@atoms/app';
import { CONNECTLY_BUSINESS_ID } from '@components/Admin/constants';
import type { FeatureFlagKey } from '@connectlyai-tenets/feature-flag';
import { setFlagByName, defaultFeatureFlags } from '@connectlyai-tenets/feature-flag';
import { Label, Switch, Box, Button, StackCard } from '@connectlyai-tenets/ui-styled-web';
import { useAnalytics, selectBusinessId, useMeData } from '@hooks';
import { showAllFeatureFlagsAtom } from '@atoms/settings';
import { startCase } from 'lodash';

export const BetaFeatures = () => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useAtom(featureFlagsAtom);
  const [showAllFeatureFlags, setShowAllFeatureFlags] = useAtom(showAllFeatureFlagsAtom);
  const dirtyFlags = useRef(false);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { sendAnalytics } = useAnalytics();

  const toggleFlag = useCallback(
    (flag: FeatureFlagKey) => {
      const newState = !isFeatureEnabled[flag];
      sendAnalytics(newState ? `beta_features_enable_${flag}` : `beta_features_disable_${flag}`);
      setIsFeatureEnabled((oldState) => ({ ...oldState, [flag]: newState }));
      setFlagByName(flag, newState);
      dirtyFlags.current = true;
    },
    [setIsFeatureEnabled, isFeatureEnabled, sendAnalytics],
  );

  const betaFeatures = useMemo(
    () => [
      {
        name: 'Sofia AI Onboarding',
        isChecked: () => isFeatureEnabled.ffEnableSofiaOnboarding,
        onChange: () => toggleFlag('ffEnableSofiaOnboarding'),
        default: defaultFeatureFlags.ffEnableSofiaOnboarding,
        flag: 'ffEnableSofiaOnboarding',
      },
      {
        name: 'Sofia AI Node',
        isChecked: () => isFeatureEnabled.ffEnableAINode,
        onChange: () => toggleFlag('ffEnableAINode'),
        default: defaultFeatureFlags.ffEnableAINode,
        flag: 'ffEnableAINode',
      },
      ...(showAllFeatureFlags
        ? (Object.keys(isFeatureEnabled) as FeatureFlagKey[])
            .filter((flag) => {
              return !flag.startsWith('ffDevOnly');
            })
            .map((flag) => ({
              name: startCase(flag.replace('ff', '').replace('Enable', '')),
              isChecked: () => isFeatureEnabled[flag],
              onChange: () => toggleFlag(flag),
              default: defaultFeatureFlags[flag],
              flag,
            }))
        : []),
    ],
    [isFeatureEnabled, toggleFlag, showAllFeatureFlags],
  );

  const showAllCounter = useRef(0);
  const handleShowAllFeatureFlags = useCallback(
    (doNow?: boolean) => {
      if (businessId !== CONNECTLY_BUSINESS_ID) return;
      if (doNow) {
        setShowAllFeatureFlags(!showAllFeatureFlags);
        return;
      }
      showAllCounter.current += 1;
      if (showAllCounter.current < 5) return;
      setShowAllFeatureFlags(!showAllFeatureFlags);
      showAllCounter.current = 0;
    },
    [businessId, setShowAllFeatureFlags, showAllFeatureFlags],
  );

  // when component disappears, refresh the page to apply the changes
  useEffect(() => {
    return () => {
      if (dirtyFlags.current) window.location.reload();
    };
  }, []);
  const items = useMemo(() => {
    return betaFeatures.map((feature) => {
      return {
        id: feature.name,
        node: (
          <Box
            key={feature.name}
            sx={{
              alignItems: 'flex-start',
              borderRadius: '10px',
              boxSizing: 'border-box',
              cursor: 'pointer',
              display: 'flex',
              flex: '1 0 auto',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              '& p': {
                cursor: 'pointer',
                userSelect: 'none',
              },
            }}
            onClick={feature.onChange}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Label variant="body1">{feature.name}</Label>
                {showAllFeatureFlags && (
                  <Label variant="body1" color={(theme) => theme.palette.grey[500]}>
                    ({feature.flag})
                  </Label>
                )}
              </Box>
              <Label variant="body2" color={(theme) => theme.palette.grey[500]}>
                Default: {feature.default ? 'Enabled' : 'Disabled'}
              </Label>
            </Box>
            <Switch checked={feature.isChecked()} onChange={feature.onChange} />
          </Box>
        ),
      };
    });
  }, [betaFeatures, showAllFeatureFlags]);
  return (
    <>
      {businessId === CONNECTLY_BUSINESS_ID && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowAllFeatureFlags(true)}
          sx={{ width: 'fit-content' }}
        >
          {showAllFeatureFlags ? 'Hide' : 'Show All'}
        </Button>
      )}
      <StackCard items={items} />
    </>
  );
};
