import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Label,
  LinearProgress,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import produce from 'immer';
import { applyHook } from '../../utils';
import { AudienceListBuilderProps, AudienceListState } from './types';
import {
  closeListBuilder,
  selectListBuilderIsOpen,
  selectListBuilderIsOpenApproval,
  selectListBuilderName,
  selectListBuilderUrl,
  setListBuilderName,
  setListBuilderOpenApproval,
  setListBuilderUrl,
} from '../../features/audiences';
import { AudienceName } from '../AudienceName';
import { AudienceFileSelector } from '../AudienceFileSelector';
import {
  AudienceSegmentsResponse,
  UploadResponse,
  selectBusinessId,
  useCreateAudienceSegmentMutation,
  useFileUploadMutation,
  useMeData,
} from '../../hooks';
import { NotificationContext } from '../../contexts';
import { ApprovalDialog } from '../ApprovalDialog';

const useAudienceListBuilder = ({
  onSuccess,
}: Partial<AudienceListBuilderProps>): Partial<AudienceListBuilderProps> => {
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);

  const dispatch = useDispatch();

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [controller, setController] = useState<AbortController | null>(null);
  const { isLoading: isUploading, mutate: uploadFile } = useFileUploadMutation();
  const { isLoading: isSaving, mutate: saveAudienceList } = useCreateAudienceSegmentMutation({
    onMutate: () => {},
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `Something went wrong while uploading audience. Please try again.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: (value) => {
      const hasData = Boolean(queryClient.getQueryData<AudienceSegmentsResponse>(['audienceSegments']));
      if (!hasData) {
        return;
      }

      if (value) {
        queryClient.setQueryData<AudienceSegmentsResponse>(['audienceSegments'], (data) =>
          produce(data, (draft: AudienceSegmentsResponse) => {
            if (value.entity) {
              draft?.entity?.segments?.unshift(value.entity);
            }

            return draft;
          }),
        );

        if (onSuccess && value.entity) {
          onSuccess(value.entity);
        }
      }
      dispatch(setListBuilderOpenApproval(true));
    },
  });

  const name = useSelector(selectListBuilderName);
  const url = useSelector(selectListBuilderUrl);
  const open = useSelector(selectListBuilderIsOpen);
  const openApproval = useSelector(selectListBuilderIsOpenApproval);

  const handleClose = useCallback(() => {
    if (controller) {
      controller.abort();
      setController(null);
    }

    dispatch(closeListBuilder());
  }, [controller, dispatch]);

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setListBuilderName(e.currentTarget.value));
    },
    [dispatch],
  );

  const handleUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setListBuilderUrl(e.currentTarget.value));
    },
    [dispatch],
  );

  const handleSelectFile = useCallback(
    (file: File) => {
      if (!file) {
        return;
      }
      if (!businessId) {
        return;
      }

      if (controller) {
        controller.abort();
        setController(null);
      }

      const newController = new AbortController();
      setController(newController);

      uploadFile(
        {
          businessId,
          controller: newController,
          file,
        },
        {
          onSettled: () => {
            setController(null);
          },
          onSuccess: (data: UploadResponse) => {
            if (!data) {
              return;
            }
            if (!businessId) {
              return;
            }
            if (!name) {
              return;
            }
            dispatch(setListBuilderUrl(data.uri));

            saveAudienceList({
              businessId,
              segmentId: uuidv4(),
              input: {
                name,
                query: { phoneList: {} },
                dataInput: {
                  phoneListImport: {
                    importUrl: data.uri,
                  },
                },
              },
            });
          },
          onError: (error) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((error as any).name === 'AbortError') {
              return;
            }

            notificationProvider().notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: `Something went wrong while uploading file. Please try again.`,
                icon: '',
                severity: NotificationSeverity.ERROR,
              },
            });
          },
        },
      );
    },
    [businessId, controller, dispatch, name, notificationProvider, saveAudienceList, uploadFile],
  );

  let state;
  if (isUploading) {
    state = AudienceListState.UPLOAD;
  } else if (isSaving) {
    state = AudienceListState.SAVE;
  } else if (openApproval) {
    state = AudienceListState.COMPLETE;
  } else if (open) {
    state = AudienceListState.CREATE;
  }

  return {
    name,
    onClose: handleClose,
    onNameChange: handleNameChange,
    onSelectFile: handleSelectFile,
    onUrlChange: handleUrlChange,
    state,
    url,
  };
};

const AudienceListBuilderPresentation = ({
  name,
  nameError,
  onClose,
  onNameChange,
  onSelectFile,
  state,
}: AudienceListBuilderProps) => {
  return (
    <>
      <ApprovalDialog
        title="Audience list has been saved!"
        onClose={onClose}
        open={state === AudienceListState.COMPLETE}
      />
      <Dialog open={state === AudienceListState.UPLOAD || state === AudienceListState.SAVE}>
        <DialogTitle>Uploading CSV</DialogTitle>
        <DialogContent sx={{ px: 3, py: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LinearProgress sx={{ width: 552 }} />
          <DialogContentText>In Progress</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={state !== AudienceListState.UPLOAD} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state === AudienceListState.CREATE}
        onClose={onClose}
        data-testid="broadcast/audiencebuilder"
        aria-labelledby="broadcast-audiencebuilder-title"
        aria-describedby="broadcast-audiencebuilder-description"
        disableRestoreFocus
      >
        <DialogTitle
          id="broadcast-audiencebuilder-title"
          sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'space-between' }}>New Audience List</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'auto' }}>
          <Label variant="body2" color="textSecondary" id="broadcast-audiencebuilder-description">
            Your audience list doesn’t change unless you make an update.
          </Label>
          <AudienceName name={name} error={nameError} onChange={onNameChange} />
          <AudienceFileSelector onSuccess={onSelectFile} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const AudienceListBuilder = applyHook(AudienceListBuilderPresentation, useAudienceListBuilder);
