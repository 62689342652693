import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { resetStore, setCustomer } from './action';
const initialState = {
    loadingState: LoadingState.UNINITIALIZED,
    description: {
        customerId: '',
        subject: '',
        firstName: '',
        lastName: '',
        avatarUrl: '',
        phone: '',
        email: '',
        lastMessageAt: '',
        lastChannel: undefined,
    },
    shopifyProfile: {
        name: '',
        email: '',
        shopifyId: '',
        totalSpent: 0,
        totalOrders: 0,
        lastOrder: '',
    },
    notesProfile: {
        text: '',
    },
};
export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        startCustomerMonitor: (state, _action) => {
            state.loadingState = LoadingState.FULL_LOADING;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setCustomer, (state, action) => {
            state.loadingState = LoadingState.DATA;
            state.description = action.payload.customer;
            state.shopifyProfile = action.payload.shopifyProfile;
            state.notesProfile = action.payload.notesProfile;
        });
    },
});
export const { reset, startCustomerMonitor } = customerSlice.actions;
export const selectCustomerDescription = (state) => state.customerSlice.description;
export const selectShopifyDescription = (state) => state.customerSlice.shopifyProfile;
export const selectNotesProfile = (state) => state.customerSlice.notesProfile;
export const selectLoadingState = (state) => state.customerSlice.loadingState;
export default customerSlice.reducer;
