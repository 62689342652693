// unreduxified, has only messageTemplates redux

import { useCallback, useEffect } from 'react';
import { NodeChange, EdgeChange } from 'react-flow-renderer';
import { v4 as uuidv4 } from 'uuid';
import { useGetAtom } from '@hooks/useGetAtom';
import {
  changesQueueAtom,
  edgesAtom,
  documentIdAtom,
  movingNodeIdsAtom,
  nodesAtom,
  nodesUIStateAtom,
  onlineIdsAtom,
  revisionIdAtom,
  isSendingChangeAtom,
  isFlowPopulatedAtom,
} from '@atoms/flow';
import { produce } from 'immer';
import { useDispatch } from 'react-redux';
import { selectBusinessId, useMeData, ChangeFlowDocumentResponse, useChangeFlowDocumentMutation } from '@hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { immutableReplace } from '../../../../utils/immutableReplace';
import {
  ChangesQueueParams,
  EdgeChangeExt,
  FlowChange,
  NodeChangeExt,
  NodeObjectUpdateExt,
  OnSuccessArgs,
} from './types';
import {
  mapChangeFlowDocumentResponseToNewId,
  mapFlowChangeToAddOperation,
  mapFlowChangeToRequest,
  mapFlowChangeToRequiredIds,
  mapFlowChangeToType,
} from './mappers';
import { updateNodeId as updateNode } from '../../../../state/actions';

export const useChangesQueue = ({ isEnabled = false }: ChangesQueueParams) => {
  const [changesQueue, setChangesQueue] = useAtom(changesQueueAtom);
  const [movingNodeIds, setMovingNodeIds] = useAtom(movingNodeIdsAtom);
  const [onlineIds, setOnlineIds] = useAtom(onlineIdsAtom);
  const [isFlowPopulated, setIsFlowPopulated] = useAtom(isFlowPopulatedAtom);
  const documentId = useAtomValue(documentIdAtom);
  const setEdges = useSetAtom(edgesAtom);
  const setNodes = useSetAtom(nodesAtom);
  const setNodesUIState = useSetAtom(nodesUIStateAtom);
  const setRevisionId = useSetAtom(revisionIdAtom);
  const setIsSendingChange = useSetAtom(isSendingChangeAtom);
  const getIsSendingChange = useGetAtom(isSendingChangeAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const preventSendingChanges = useCallback(
    () => !businessId || !documentId || !isEnabled || getIsSendingChange(),
    [businessId, documentId, isEnabled, getIsSendingChange],
  );

  const { mutate: changeFlowDocumentMutate } = useChangeFlowDocumentMutation();
  const sendChange = useCallback(
    (flowChange: FlowChange) => {
      if (preventSendingChanges() || !businessId) return;
      setIsSendingChange(true);
      changeFlowDocumentMutate(
        {
          flowDocumentId: documentId,
          businessId,
          clientRevisionId: '1',
          ...flowChange?.helpers?.request,
        },
        {
          onSuccess: (response: ChangeFlowDocumentResponse) =>
            flowChange.helpers.onSuccess?.({ change: flowChange, response }),
          onError: () => setIsSendingChange(false),
        },
      );
    },
    [businessId, changeFlowDocumentMutate, documentId, preventSendingChanges, setIsSendingChange],
  );

  const isIdOnline = useCallback((id: string) => onlineIds.includes(id), [onlineIds]);

  const isSendable = useCallback(
    (change: FlowChange) => {
      if (!change?.helpers?.request) return false;
      // check if all required ids are online
      // for ex; deleting an edge requires the edge id to be online
      // although adding an edge requires source node and target node ids to be online
      if (mapFlowChangeToRequiredIds(change).some((id) => !isIdOnline(id))) return false;

      // do not send a node while it is being dragged
      if (change.node && change.node.type === 'position' && movingNodeIds.includes(change.node?.id)) return false;

      return true;
    },
    [isIdOnline, movingNodeIds],
  );

  useEffect(() => {
    if (preventSendingChanges()) return;
    const change = changesQueue.find((c) => isSendable(c));
    if (!change) {
      if (!isFlowPopulated) setIsFlowPopulated(true);
      return;
    }
    sendChange(change);
  }, [changesQueue, isSendable, sendChange, preventSendingChanges, isFlowPopulated, setIsFlowPopulated]);

  const removeChange = useCallback(
    (queueId: string) => {
      setChangesQueue((oldChangesQueue) => oldChangesQueue.filter((change) => change?.helpers?.queueId !== queueId));
    },
    [setChangesQueue],
  );

  // mark edge or node id as online to be able to send further changes to backend
  // do not need to call this option when updateIdInChangesQueue is called
  const markIdsOnline = useCallback(
    (ids: string[]) => {
      const newIds = ids.filter((id) => !onlineIds.includes(id));
      if (newIds.length === 0) return;
      setOnlineIds((oldOnlineIds) => [...oldOnlineIds, ...newIds]);
    },
    [onlineIds, setOnlineIds],
  );

  // used when first node is created id is replaced with backend generated id
  const updateIdInChangesQueue = useCallback(
    (oldId: string, newId: string) => {
      markIdsOnline([newId]);
      setChangesQueue((oldChangesQueue) =>
        oldChangesQueue.map((change) =>
          produce(change, (draft) => {
            if (draft.edge) draft.edge = immutableReplace(draft.edge, oldId, newId);
            if (draft.node) draft.node = immutableReplace(draft.node, oldId, newId);
            if (draft.nodeObjectUpdate) draft.nodeObjectUpdate = immutableReplace(draft.nodeObjectUpdate, oldId, newId);
            draft.helpers.request = immutableReplace(draft.helpers.request, oldId, newId);
          }),
        ),
      );
    },
    [setChangesQueue, markIdsOnline],
  );

  const dispatch = useDispatch(); // remove after template redux is removed

  const updateNodeId = useCallback(
    (oldNodeId: string, newId: string) => {
      // step 1: update node id in nodes
      setNodes((oldNodes) => [
        ...oldNodes.map((node) => (node.id === oldNodeId ? { ...node, id: newId, data: { ...node.data } } : node)),
      ]);
      // step 2: update node id in edges, source and target and also sourceHandle and targetHandle
      setEdges((oldEdges) => [
        ...oldEdges.map((edge) =>
          // eslint-disable-next-line no-nested-ternary
          edge.source === oldNodeId
            ? { ...edge, source: newId, sourceHandle: edge.sourceHandle?.replace(oldNodeId, newId) }
            : edge.target === oldNodeId
              ? { ...edge, target: newId, targetHandle: edge.targetHandle?.replace(oldNodeId, newId) }
              : edge,
        ),
      ]);

      // step 3: update node id in changesQueue, changes should replace new id so they can be applied in backend
      updateIdInChangesQueue(oldNodeId, newId);

      // step 4: update node id in template builder
      dispatch(updateNode({ nodeId: oldNodeId, newNodeId: newId as string })); // remove after template redux is removed

      // step 4: update node id in ui nodes state
      setNodesUIState((oldNodesUIState) => {
        const uiStates = { ...oldNodesUIState };
        const oldUIState = oldNodesUIState[oldNodeId];
        if (oldUIState) {
          uiStates[newId] = oldUIState;
          delete uiStates[oldNodeId];
        }
        return uiStates;
      });
    },
    [dispatch, updateIdInChangesQueue, setNodes, setEdges, setNodesUIState],
  );

  const updateEdgeId = useCallback(
    (oldEdgeId: string, newId: string) => {
      setEdges((oldEdges) => [
        ...oldEdges.map((edge) => (edge.id === oldEdgeId ? { ...edge, id: newId, data: { ...edge.data } } : edge)),
      ]);
      // update edge id in changesQueue, changes should replace new id so they can be applied in backend
      updateIdInChangesQueue(oldEdgeId, newId);
    },
    [updateIdInChangesQueue, setEdges],
  );

  const updateObjectId = useCallback(
    (change: FlowChange, response: ChangeFlowDocumentResponse) => {
      const addOperation = mapFlowChangeToAddOperation(change);
      if (!addOperation) return;

      const newId = mapChangeFlowDocumentResponseToNewId(response);
      if (!newId) return;

      switch (addOperation.type) {
        case 'node':
          updateNodeId(addOperation.id, newId);
          break;
        case 'edge':
          updateEdgeId(addOperation.id, newId);
          break;
        default:
          break;
      }
    },
    [updateEdgeId, updateNodeId],
  );

  const prepareForQueue = useCallback(
    (flowChange: Partial<FlowChange>): FlowChange => {
      const type = mapFlowChangeToType(flowChange);
      if (!type || !flowChange[type]) return { ...flowChange, helpers: {} };

      const queueId = uuidv4();

      const prepared = {
        ...flowChange,
        helpers: {
          queueId,
          onSuccess: (data: OnSuccessArgs) => {
            removeChange(queueId);
            setRevisionId(data.response.entity?.revisionId || '');
            updateObjectId(prepared, data.response);
            flowChange?.helpers?.onSuccess?.(data);
            setIsSendingChange(false);
          },
          request: mapFlowChangeToRequest(type, flowChange),
          type,
        },
      };

      return prepared;
    },
    [removeChange, setRevisionId, updateObjectId, setIsSendingChange],
  );

  const addNodeChangesToQueue = useCallback(
    (nodeChanges: NodeChangeExt[]) => {
      // only send position changes if change.dragging = false
      nodeChanges.forEach((change) => {
        if (change.type === 'position') {
          if (change.dragging) {
            // position is being dragged add node id to movingNodeIds
            setMovingNodeIds([...movingNodeIds, change.id]);
          } else {
            // position is final remove node id from movingNodeIds
            setMovingNodeIds([...movingNodeIds.filter((id) => id !== change.id)]);
          }
        }
      });

      const shouldChangeBeSentToBackend = (nodeChange: NodeChange) => {
        if (nodeChange.type === 'position') {
          return nodeChange.position !== undefined;
        }
        return !['dimensions', 'select', 'reset'].includes(nodeChange.type);
      };

      // position changes should not preserved a new change with same id exist
      const nodeIdsOfNewPosChanges = nodeChanges.map((nodeChange) =>
        nodeChange.type === 'position' && nodeChange.position !== undefined ? nodeChange.id : undefined,
      );
      const isPositionOverwritten = (change: FlowChange) => {
        return change.node?.type === 'position' && nodeIdsOfNewPosChanges.includes(change.node?.id);
      };

      setChangesQueue((oldChangesQueue) => [
        // filter old changes remove position changes that are overwritten by new changes
        ...oldChangesQueue.filter((change) => !isPositionOverwritten(change)),

        // filter new changes on whether backend is interested in them
        ...nodeChanges
          .filter((nodeChange) => shouldChangeBeSentToBackend(nodeChange))
          .map((nodeChange) => prepareForQueue({ node: nodeChange })),
      ]);
    },
    [setChangesQueue, movingNodeIds, prepareForQueue, setMovingNodeIds],
  );

  const addEdgeChangesToQueue = useCallback(
    (edgeChanges: EdgeChangeExt[]) => {
      const shouldChangeBeSentToBackend = (edgeChange: EdgeChange) => {
        return !['select', 'reset'].includes(edgeChange.type);
      };

      setChangesQueue((oldChangesQueue) => [
        ...oldChangesQueue,

        // filter new changes on whether backend is interested in them
        ...edgeChanges
          .filter((edgeChange) => shouldChangeBeSentToBackend(edgeChange))
          .map((edgeChange) => prepareForQueue({ edge: edgeChange })),
      ]);
    },
    [setChangesQueue, prepareForQueue],
  );

  const addNodeObjectUpdatesToQueue = useCallback(
    (updates: NodeObjectUpdateExt[]) => {
      setChangesQueue((oldChangesQueue) => [
        ...oldChangesQueue,

        // filter new changes on whether backend is interested in them
        ...updates.map((nodeObjectUpdate) => prepareForQueue({ nodeObjectUpdate })),
      ]);
    },
    [setChangesQueue, prepareForQueue],
  );

  return {
    addEdgeChangesToQueue,
    addNodeChangesToQueue,
    addNodeObjectUpdatesToQueue,
    markIdsOnline,
    updateIdInChangesQueue,
  };
};
