import { MutableRefObject, useEffect, useRef } from 'react';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback: MutableRefObject<(() => void) | undefined> = useRef();

  const tick = () => savedCallback.current && savedCallback.current();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay) {
      // set to null to pause the interval
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
};
