import memoize from 'lodash/memoize';
import { mutationCreateQuickReplyTemplateProvider, mutationCreateTagProvider, mutationDeleteQuickReplyTemplateProvider, mutationDeleteTagProvider, mutationUpdateQuickReplyTemplateProvider, mutationUpdateTagProvider, queryAgentsProvider, queryBusinessProvider, } from '../usecases';
export const buildDIContainer = (deps) => ({
    dtoUpdateAccessorProvider: deps.dtoUpdateAccessorProvider,
    logProvider: deps.logProvider,
    mutationCreateQuickReplyTemplateProvider: memoize(() => mutationCreateQuickReplyTemplateProvider(deps.logProvider(), deps.networkClientProvider())),
    mutationCreateTagProvider: memoize(() => mutationCreateTagProvider(deps.logProvider(), deps.networkClientProvider())),
    mutationDeleteQuickReplyTemplateProvider: memoize(() => mutationDeleteQuickReplyTemplateProvider(deps.logProvider(), deps.networkClientProvider())),
    mutationDeleteTagProvider: memoize(() => mutationDeleteTagProvider(deps.logProvider(), deps.networkClientProvider())),
    mutationUpdateQuickReplyTemplateProvider: memoize(() => mutationUpdateQuickReplyTemplateProvider(deps.logProvider(), deps.networkClientProvider())),
    mutationUpdateTagProvider: memoize(() => mutationUpdateTagProvider(deps.logProvider(), deps.networkClientProvider())),
    queryAgentsProvider: memoize(() => queryAgentsProvider(deps.logProvider(), deps.networkClientProvider())),
    queryBusinessProvider: memoize(() => queryBusinessProvider(deps.logProvider(), deps.networkClientProvider())),
});
