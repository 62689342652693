import { amber, cyan, green, indigo, lightGreen, lime, pink, teal, yellow } from '@connectlyai-tenets/ui-styled-web';

export type ColorViewModel = {
  id: string;
  colorHex: string;
  name: string;
};

export const colors: ColorViewModel[] = [
  {
    id: 'pink',
    colorHex: pink[300],
    name: 'Pink',
  },
  {
    id: 'indigo',
    colorHex: indigo[300],
    name: 'Indigo',
  },
  {
    id: 'cyan',
    colorHex: cyan[300],
    name: 'Cyan',
  },
  {
    id: 'teal',
    colorHex: teal[300],
    name: 'Teal',
  },
  {
    id: 'green',
    colorHex: green[300],
    name: 'Green',
  },
  {
    id: 'lightgreen',
    colorHex: lightGreen[300],
    name: 'Light Green',
  },
  {
    id: 'lime',
    colorHex: lime[300],
    name: 'Lime',
  },
  {
    id: 'yellow',
    colorHex: yellow[300],
    name: 'Yellow',
  },
  {
    id: 'amber',
    colorHex: amber[300],
    name: 'Amber',
  },
];
