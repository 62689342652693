import { Paths } from '@connectlyai-tenets/sdk';

export const AGENT_DESCRIPTOR_URL = '/internal/v1/businesses/{input.businessId}/ai/agent_descriptor_v1/query/v1';

type AgentDescriptorUrl = typeof AGENT_DESCRIPTOR_URL;

// export type AgentUrlParameters = Paths['aiV1'][AgentDescriptorUrl]['get']['parameters']['path'];
export type AgentDescriptorUrlParameters = { businessId: string };
export type AgentDescriptorResponse = Paths['aiV1'][AgentDescriptorUrl]['get']['responses']['200']['schema'];
export type AgentDescriptor = NonNullable<NonNullable<AgentDescriptorResponse['response']>['agentDescriptors']>[0];
