import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { useEventRequestDeployReactFlowDocument } from '../useEventRequestDeployReactFlowDocument';
import { DtoUpdate, DeployReactFlowDocumentResult, RequestDeployReactFlowDocumentParams } from './types';
import { FlowDIContext } from '../../features/flow/contexts/FlowDIContext';

export const useRequestDeployReactFlowDocument = (props?: RequestDeployReactFlowDocumentParams) => {
  const di = useContext(FlowDIContext);
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  }, [props]);
  const [clientRequestId, setClientRequestId] = React.useState<string | undefined>(undefined);
  const [result, setResult] = React.useState<DeployReactFlowDocumentResult | null>(null);

  const { mutate } = useEventRequestDeployReactFlowDocument({
    onError: () => {
      di?.notificationServiceProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Unable to deploy. Please try again.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onMutate: (input) => {
      setResult(null);
      setClientRequestId(input.clientRequestId);
    },
  });

  useEffect(() => {
    let sub: Subscription;
    if (di && clientRequestId) {
      sub = di
        .dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => {
            return dtoUpdate?.deployReactFlowDocumentResult !== undefined;
          }),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate?.deployReactFlowDocumentResult === undefined) {
              return unreachable();
            }
            return dtoUpdate.deployReactFlowDocumentResult;
          }),
        )
        .subscribe((deployReactFlowDocumentResult) => {
          if (clientRequestId === deployReactFlowDocumentResult.clientRequestId) {
            setResult(deployReactFlowDocumentResult);
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [di, clientRequestId]);

  const isSuccess = useMemo(() => result?.status === 'MUTATION_STATUS_SUCCESS', [result]);
  const isLoading = useMemo(() => clientRequestId !== undefined && result === null, [clientRequestId, result]);
  const isError = useMemo(() => result?.status === 'MUTATION_STATUS_FAILURE', [result]);

  useEffect(() => {
    if (isSuccess && latestProps.current?.onSuccess) {
      latestProps.current.onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && latestProps.current?.onError) {
      latestProps.current.onError();
    }
  }, [isError]);

  return { mutate, isLoading, isSuccess, isError, data: result };
};
