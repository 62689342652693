import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { tryMutationUpdateAudience, tryQueryAudience, tryQueryAudienceCohort, tryQuerySendouts } from './thunk';
import { getAudienceParams } from './utils';
export function mapStatusToLoadingState(status) {
    switch (status) {
        case 'success':
            return LoadingState.DATA;
        case 'failure':
            return LoadingState.FULL_ERROR;
        case 'loading':
            return LoadingState.FULL_LOADING;
        case 'empty':
            return LoadingState.UNINITIALIZED;
        default:
            return assertUnreachable(status);
    }
}
const initialState = {
    query: undefined,
    campaignId: '',
    sendoutIds: [],
    name: '',
    size: 0,
    behaviors: [],
    editAudience: undefined,
    campaigns: {
        byId: {},
        ids: [],
    },
    sendouts: {
        byId: {},
        byCampaignId: {},
    },
    queryAudienceRequestId: '',
    queryAudienceStatus: 'empty',
    querySendoutsRequestId: '',
    querySendoutsStatus: 'empty',
    mutationUpdateAudienceRequestId: '',
    mutationUpdateAudienceStatus: 'empty',
};
export const slice = createSlice({
    name: 'audience-edit',
    initialState,
    reducers: {
        reset: () => initialState,
        setMutationAudienceName: (state, action) => {
            state.name = action.payload;
        },
        setMutationAudienceCampaignId: (state, action) => {
            state.campaignId = action.payload;
            state.sendoutIds = state.sendouts.byCampaignId[action.payload] || [];
            state.behaviors = [];
        },
        setMutationAudienceSendoutIds: (state, action) => {
            state.sendoutIds = action.payload;
        },
        setMutationAudienceBehaviors: (state, action) => {
            state.behaviors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(tryQueryAudience.pending, (state, action) => {
            state.queryAudienceStatus = 'loading';
            state.queryAudienceRequestId = action.meta.requestId;
        });
        builder.addCase(tryQueryAudience.fulfilled, (state, action) => {
            if (state.queryAudienceRequestId !== action.meta.requestId) {
                return;
            }
            state.queryAudienceStatus = 'success';
            state.editAudience = action.payload;
            const { campaignId, sendoutIds, name, size, behaviors } = getAudienceParams(action.payload, state.sendouts.byId);
            state.behaviors = behaviors;
            state.campaignId = campaignId;
            state.name = name;
            state.sendoutIds = sendoutIds;
            state.size = size;
        });
        builder.addCase(tryQueryAudience.rejected, (state, action) => {
            if (state.queryAudienceRequestId !== action.meta.requestId) {
                return;
            }
            state.queryAudienceStatus = 'failure';
        });
        builder.addCase(tryQuerySendouts.pending, (state, action) => {
            state.querySendoutsStatus = 'loading';
            state.querySendoutsRequestId = action.meta.requestId;
        });
        builder.addCase(tryQuerySendouts.fulfilled, (state, action) => {
            if (state.querySendoutsRequestId !== action.meta.requestId) {
                return;
            }
            state.querySendoutsStatus = 'success';
            state.campaigns = {
                byId: {},
                ids: [],
            };
            state.sendouts = {
                byId: {},
                byCampaignId: {},
            };
            action.payload.sendouts.forEach((x) => {
                state.sendouts.byId[x.id] = x;
                state.sendouts.byCampaignId[x.campaignId] = [...(state.sendouts.byCampaignId[x.campaignId] || []), x.id];
                if (x.campaign) {
                    if (!state.campaigns.byId[x.campaignId]) {
                        state.campaigns.ids = [...state.campaigns.ids, x.campaignId];
                    }
                    state.campaigns.byId[x.campaignId] = x.campaign;
                }
            });
            if (state.editAudience) {
                const { campaignId, sendoutIds, name, size, behaviors } = getAudienceParams(state.editAudience, state.sendouts.byId);
                state.behaviors = behaviors;
                state.campaignId = campaignId;
                state.name = name;
                state.sendoutIds = sendoutIds;
                state.size = size;
            }
        });
        builder.addCase(tryQuerySendouts.rejected, (state, action) => {
            if (state.querySendoutsRequestId !== action.meta.requestId) {
                return;
            }
            state.querySendoutsStatus = 'failure';
        });
        builder.addCase(tryMutationUpdateAudience.pending, (state, action) => {
            state.mutationUpdateAudienceRequestId = action.meta.requestId;
            state.mutationUpdateAudienceStatus = 'loading';
        });
        builder.addCase(tryMutationUpdateAudience.fulfilled, (state, action) => {
            if (state.mutationUpdateAudienceRequestId === action.meta.requestId) {
                state.mutationUpdateAudienceStatus = 'success';
            }
        });
        builder.addCase(tryMutationUpdateAudience.rejected, (state, action) => {
            if (state.mutationUpdateAudienceRequestId === action.meta.requestId) {
                state.mutationUpdateAudienceStatus = 'failure';
            }
        });
        builder.addCase(tryQueryAudienceCohort.fulfilled, (state, action) => {
            state.size = action.payload;
            if (state.editAudience) {
                state.editAudience.size = action.payload;
            }
        });
    },
});
export const { reset, setMutationAudienceCampaignId, setMutationAudienceSendoutIds, setMutationAudienceName, setMutationAudienceBehaviors, } = slice.actions;
export const selectCampaigns = (state) => state.audienceEditSlice.campaigns.ids.map((x) => state.audienceEditSlice.campaigns.byId[x]);
export const selectSendouts = (campaignId) => (state) => campaignId
    ? (state.audienceEditSlice.sendouts.byCampaignId[campaignId] || []).map((x) => state.audienceEditSlice.sendouts.byId[x])
    : [];
export const selectEditAudience = (state) => state.audienceEditSlice.editAudience;
export const selectBehaviors = (state) => state.audienceEditSlice.behaviors;
export const selectCampaignId = (state) => state.audienceEditSlice.campaignId;
export const selectName = (state) => state.audienceEditSlice.name;
export const selectSendoutIds = (state) => state.audienceEditSlice.sendoutIds;
export const selectSize = (state) => state.audienceEditSlice.size;
export const selectLoadingState = (state) => mapStatusToLoadingState(state.audienceEditSlice.mutationUpdateAudienceStatus);
export const selectTemplateIds = (campaignId) => (state) => {
    if (!campaignId) {
        return [];
    }
    const sendouts = state.audienceEditSlice.sendouts.byCampaignId[campaignId];
    if (!sendouts || sendouts.length === 0) {
        return [];
    }
    const sendout = state.audienceEditSlice.sendouts.byId[sendouts[0]];
    if (!sendout) {
        return [];
    }
    const { config } = sendout;
    if (!config) {
        return [];
    }
    const { configOneof } = config;
    if (!configOneof) {
        return [];
    }
    switch (configOneof.$case) {
        case 'broadcast': {
            const { broadcast } = configOneof;
            if (!broadcast) {
                return [];
            }
            const { templateOneof } = broadcast;
            if (!templateOneof) {
                return [];
            }
            switch (templateOneof.$case) {
                case 'waBroadcastTemplateV1': {
                    return templateOneof.waBroadcastTemplateV1.waMessageTemplateId
                        ? [templateOneof.waBroadcastTemplateV1.waMessageTemplateId]
                        : [];
                }
                case 'stbBroadcastTemplateV1': {
                    return templateOneof.stbBroadcastTemplateV1.messageTemplates.length > 0
                        ? [templateOneof.stbBroadcastTemplateV1.messageTemplates[0].waMessageTemplateId]
                        : [];
                }
                default: {
                    return [];
                }
            }
        }
        default: {
            return [];
        }
    }
};
export default slice.reducer;
