import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ContentCopyIcon,
  InfoIcon,
  Label,
  Stack,
  useTheme,
  Card,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { FormattedTooltip } from '@components/Tooltips';
import { KeyPerformanceIndicatorProps } from './types';
import { SIZE_CONTENT_XS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

const KPIChange = ({
  change,
  lowerIsBetter,
  isLoading,
}: {
  change: number;
  lowerIsBetter?: boolean;
  isLoading: boolean;
}) => {
  const theme = useTheme();
  const improved = lowerIsBetter ? change < 0 : change > 0;
  const roundedChange = useMemo(() => {
    return Math.round(change * 100) / 100;
  }, [change]);
  return (
    <Label
      variant="body1"
      sx={{
        color:
          isLoading || change === 0 || Number.isNaN(change)
            ? theme.palette.grey[600]
            : theme.palette[improved ? 'success' : 'error'].main,
      }}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading || change === 0 ? '' : change > 0 ? `⭡ ${roundedChange}%` : `⭣ ${roundedChange}%`}
    </Label>
  );
};

export const KeyPerformanceIndicator = ({
  title,
  data,
  lowerIsBetter,
  isLoading,
  tooltip,
  sx,
  isAnalyticsPage = false,
}: KeyPerformanceIndicatorProps) => {
  const theme = useTheme();
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [hasBeenCopied, setHasBeenCopied] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });

  const roundedChange = useMemo(() => {
    return Math.round(data?.change || 0 * 100) / 100;
  }, [data.change]);

  const dataCopy = `${title}, ${data.current}, ${roundedChange}%`;
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(dataCopy);
    setHasBeenCopied(true);

    setTimeout(() => {
      setHasBeenCopied(false);
    }, 2000);
  }, [dataCopy]);

  return (
    <Card
      variant="main"
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
      sx={[
        {
          flex: `1 1 ${theme.spacing(SIZE_CONTENT_XS)}`,
          minWidth: theme.spacing(SIZE_CONTENT_XS),
          boxSizing: 'border-box',
          height: theme.spacing(14),
          display: 'flex',
          justifyContent: data.change === undefined ? 'flex-start' : 'space-between',
          flexDirection: 'column',
          userSelect: 'none',
          gap: 0,
          p: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <WithSkeleton wrap={isLoading}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Label variant="body2" sx={{ color: theme.palette.grey[800], mr: 1 }}>
              {title}
            </Label>
            {tooltip && (
              <FormattedTooltip arrow placement="top" title={tooltip}>
                <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
              </FormattedTooltip>
            )}
          </Box>
          {isAnalyticsPage && showCopyIcon && (
            <FormattedTooltip arrow placement="top" title={hasBeenCopied ? t('copied') : t('copy')}>
              <ContentCopyIcon
                onClick={handleCopy}
                fontSize="inherit"
                sx={{ cursor: 'pointer', color: theme.palette.secondary.dark }}
              />
            </FormattedTooltip>
          )}
        </Box>
      </WithSkeleton>
      <Stack direction="row" gap={SIZE_SPACING_INTER_COMPONENT} alignItems="flex-end">
        <WithSkeleton wrap={isLoading}>
          <Label variant="h4" sx={{ mt: data.change === undefined ? 1.25 : 0, fontWeight: '600!important' }}>
            {data.current}
          </Label>
        </WithSkeleton>
        {!!data.rate && (
          <WithSkeleton wrap={isLoading}>
            <Label variant="body1" sx={{ color: theme.palette.secondary.dark }}>
              {data.rate.toFixed(2)}%
            </Label>
          </WithSkeleton>
        )}
      </Stack>
      {data.change !== undefined && (
        <WithSkeleton wrap={isLoading}>
          <KPIChange isLoading={isLoading} change={data.change} lowerIsBetter={lowerIsBetter} />
        </WithSkeleton>
      )}
    </Card>
  );
};
