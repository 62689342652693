export function formatCurrency(num: number, currency: string): string {
  if (!currency) return `${num}`;
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    notation: 'compact',
    compactDisplay: 'short', // 98000 => 98K
    maximumFractionDigits: 1, // 2500.93 => 2,500.9
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (options as any).trailingZeroDisplay = 'stripIfInteger'; // 150.0 => 150

  const formatter = new Intl.NumberFormat(undefined, options);

  return formatter.format(num);
}
