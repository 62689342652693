import { ParameterDescriptor, FlowVar } from "../descriptor/core";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "exp.eta.events";
export var FlowTrigger_DispatchMode;
(function (FlowTrigger_DispatchMode) {
    FlowTrigger_DispatchMode[FlowTrigger_DispatchMode["DISPATCH_MODE_UNSPECIFIED"] = 0] = "DISPATCH_MODE_UNSPECIFIED";
    FlowTrigger_DispatchMode[FlowTrigger_DispatchMode["DISPATCH_MODE_SYNCHRONOUS"] = 1] = "DISPATCH_MODE_SYNCHRONOUS";
    FlowTrigger_DispatchMode[FlowTrigger_DispatchMode["DISPATCH_MODE_ASYNCHRONOUS_PERSIST"] = 2] = "DISPATCH_MODE_ASYNCHRONOUS_PERSIST";
    FlowTrigger_DispatchMode[FlowTrigger_DispatchMode["DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT"] = 3] = "DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT";
    FlowTrigger_DispatchMode[FlowTrigger_DispatchMode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowTrigger_DispatchMode || (FlowTrigger_DispatchMode = {}));
export function flowTrigger_DispatchModeFromJSON(object) {
    switch (object) {
        case 0:
        case "DISPATCH_MODE_UNSPECIFIED":
            return FlowTrigger_DispatchMode.DISPATCH_MODE_UNSPECIFIED;
        case 1:
        case "DISPATCH_MODE_SYNCHRONOUS":
            return FlowTrigger_DispatchMode.DISPATCH_MODE_SYNCHRONOUS;
        case 2:
        case "DISPATCH_MODE_ASYNCHRONOUS_PERSIST":
            return FlowTrigger_DispatchMode.DISPATCH_MODE_ASYNCHRONOUS_PERSIST;
        case 3:
        case "DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT":
            return FlowTrigger_DispatchMode.DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowTrigger_DispatchMode.UNRECOGNIZED;
    }
}
export function flowTrigger_DispatchModeToJSON(object) {
    switch (object) {
        case FlowTrigger_DispatchMode.DISPATCH_MODE_UNSPECIFIED:
            return "DISPATCH_MODE_UNSPECIFIED";
        case FlowTrigger_DispatchMode.DISPATCH_MODE_SYNCHRONOUS:
            return "DISPATCH_MODE_SYNCHRONOUS";
        case FlowTrigger_DispatchMode.DISPATCH_MODE_ASYNCHRONOUS_PERSIST:
            return "DISPATCH_MODE_ASYNCHRONOUS_PERSIST";
        case FlowTrigger_DispatchMode.DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT:
            return "DISPATCH_MODE_ASYNCHRONOUS_TRANSIENT";
        case FlowTrigger_DispatchMode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseFlowTrigger() {
    return {
        flowId: "",
        documentTarget: undefined,
        mode: 0,
        businessChannelId: undefined,
        customerChannelId: undefined,
        parameters: [],
        flowVars: [],
    };
}
export const FlowTrigger = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowId !== "") {
            writer.uint32(10).string(message.flowId);
        }
        if (message.documentTarget !== undefined) {
            FlowDocumentTarget.encode(message.documentTarget, writer.uint32(58).fork()).ldelim();
        }
        if (message.mode !== 0) {
            writer.uint32(24).int32(message.mode);
        }
        if (message.businessChannelId !== undefined) {
            StringValue.encode({ value: message.businessChannelId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.customerChannelId !== undefined) {
            StringValue.encode({ value: message.customerChannelId }, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.parameters) {
            ParameterDescriptor.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.flowVars) {
            FlowVar.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowTrigger();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowId = reader.string();
                    break;
                case 7:
                    message.documentTarget = FlowDocumentTarget.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.mode = reader.int32();
                    break;
                case 4:
                    message.businessChannelId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.customerChannelId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.parameters.push(ParameterDescriptor.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.flowVars.push(FlowVar.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowId: isSet(object.flowId) ? String(object.flowId) : "",
            documentTarget: isSet(object.documentTarget)
                ? FlowDocumentTarget.fromJSON(object.documentTarget)
                : undefined,
            mode: isSet(object.mode)
                ? flowTrigger_DispatchModeFromJSON(object.mode)
                : 0,
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : undefined,
            customerChannelId: isSet(object.customerChannelId)
                ? String(object.customerChannelId)
                : undefined,
            parameters: Array.isArray(object === null || object === void 0 ? void 0 : object.parameters)
                ? object.parameters.map((e) => ParameterDescriptor.fromJSON(e))
                : [],
            flowVars: Array.isArray(object === null || object === void 0 ? void 0 : object.flowVars)
                ? object.flowVars.map((e) => FlowVar.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowId !== undefined && (obj.flowId = message.flowId);
        message.documentTarget !== undefined &&
            (obj.documentTarget = message.documentTarget
                ? FlowDocumentTarget.toJSON(message.documentTarget)
                : undefined);
        message.mode !== undefined &&
            (obj.mode = flowTrigger_DispatchModeToJSON(message.mode));
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.customerChannelId !== undefined &&
            (obj.customerChannelId = message.customerChannelId);
        if (message.parameters) {
            obj.parameters = message.parameters.map((e) => e ? ParameterDescriptor.toJSON(e) : undefined);
        }
        else {
            obj.parameters = [];
        }
        if (message.flowVars) {
            obj.flowVars = message.flowVars.map((e) => e ? FlowVar.toJSON(e) : undefined);
        }
        else {
            obj.flowVars = [];
        }
        return obj;
    },
};
function createBaseFlowDocumentTarget() {
    return { id: "", versionId: undefined };
}
export const FlowDocumentTarget = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.versionId !== undefined) {
            StringValue.encode({ value: message.versionId }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentTarget();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.versionId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            versionId: isSet(object.versionId) ? String(object.versionId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.versionId !== undefined && (obj.versionId = message.versionId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
