export const green = {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
    A100: '#E3F9E5',
    A200: '#95E8B7',
    A400: '#51CA7F',
    A700: '#399A5A',
};
