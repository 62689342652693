import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLayout, NavigationRoute } from '@components/PageLayout';
import { useMeData, selectBusinessId, useFeatureFlag } from '@hooks';
import { useAtomValue } from 'jotai';
import { audiencePageEnhancementsAtom } from '@atoms/audience';
import { Templates } from './Templates';
import { Audiences } from './Audiences';
import { OptOut } from './OptOut';
import { useNavigator } from '../hooks';

export const useAudience = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { t } = useTranslation('translation', { keyPrefix: 'audience.pages' });
  const { ffEnableSmartAudiences } = useFeatureFlag(['ffEnableSmartAudiences']);
  const { routes: audienceRoutes } = useNavigator();
  const routes: NavigationRoute[] = useMemo(() => {
    if (ffEnableSmartAudiences) {
      return [
        {
          ...audienceRoutes.manage,
          display: t('tabs.manage.tabDisplay', 'My Audiences'),
        },
        {
          ...audienceRoutes.templates,
          display: t('tabs.templates.tabDisplay', 'Templates'),
        },
        {
          ...audienceRoutes.optOut,
          display: t('tabs.optOut.tabDisplay', 'Opt Out'),
          track: ['(audience) open opt-out management', { businessId }],
        },
      ];
    }
    return [
      {
        ...audienceRoutes.manage,
        display: t('tabs.manage.tabDisplay', 'My Audiences'),
      },
      {
        ...audienceRoutes.optOut,
        display: t('tabs.optOut.tabDisplay', 'Opt Out'),
        track: ['(audience) open opt-out management', { businessId }],
      },
    ];
  }, [audienceRoutes.manage, audienceRoutes.optOut, audienceRoutes.templates, businessId, ffEnableSmartAudiences, t]);
  const pageEnhancements = useAtomValue(audiencePageEnhancementsAtom);
  const { title } = useMemo(() => {
    return {
      title: t('title', 'Audiences'),
    };
  }, [t]);
  return {
    title,
    pageEnhancements,
    routes,
  };
};

const Content = () => {
  const { routes } = useNavigator();
  return (
    <Switch>
      <Route path={routes.home.pathname} exact>
        <Audiences />
      </Route>
      <Route path={routes.manage.pathname}>
        <Audiences />
      </Route>
      <Route path={routes.templates.pathname}>
        <Templates />
      </Route>
      <Route path={routes.optOut.pathname}>
        <OptOut />
      </Route>
    </Switch>
  );
};

export const Audience = () => {
  const { title, routes, pageEnhancements } = useAudience();
  return (
    <PageLayout title={title} primaryButton={pageEnhancements.primaryButton} testId="audience" routes={routes}>
      <Content />
    </PageLayout>
  );
};
