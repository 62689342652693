import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { BroadcastDIContext } from '../../context';
import { BROADCAST_DID_INIT } from '../../analytics';
import BroadcastAudienceBuilder from '../broadcast-audiencebuilder/BroadcastAudienceBuilder';
import BroadcastAudiences from '../broadcast-audiences/BroadcastAudiences';
import BroadcastMessageTemplates from '../broadcast-messagetemplates/BroadcastMessageTemplates';
import BroadcastMessageTemplatesNew from '../broadcast-messagetemplatesnew/BroadcastMessageTemplatesNew';
import BroadcastSidebar from '../broadcast-sidebar/BroadcastSidebar';
export const BroadcastComponentId = '89a3dd62-387c-4f79-a2cc-09a6831e96a8';
const Broadcast = ({ WhatsAppBroadcast }) => {
    const di = useContext(BroadcastDIContext);
    useEffect(() => {
        if (di !== undefined) {
            di.logProvider().debug(BROADCAST_DID_INIT, () => ({}));
        }
    }, [di]);
    if (di === undefined) {
        return null;
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastComponentId);
    if (!UIComponent) {
        return null;
    }
    return (_jsx(UIComponent, { AudiencesNew: BroadcastAudienceBuilder, Audiences: BroadcastAudiences, MessageTemplates: BroadcastMessageTemplates, MessageTemplatesNew: BroadcastMessageTemplatesNew, Sidebar: BroadcastSidebar, WACampaign: WhatsAppBroadcast }));
};
export default Broadcast;
