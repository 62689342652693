import React, { useState, FC, ReactNode } from 'react';
import { Box, TableRow, TableCell, useTheme, WithSkeleton } from '@connectlyai-tenets/ui-styled-web';
import { EnhancedTableRowProps } from '@components/EnhancedTable/types';

export type SimpleTableRowProps = Pick<EnhancedTableRowProps, 'id' | 'data'> & {
  onClick?: () => void;
  isLoading?: boolean;
  hoverActions?: ReactNode;
};

export const SimpleTableRow: FC<SimpleTableRowProps> = ({
  id,
  data,
  onClick,
  isLoading,
  hoverActions,
}: SimpleTableRowProps) => {
  const theme = useTheme();
  const [showActions, setShowActions] = useState(false);

  return (
    <TableRow
      sx={{
        position: 'relative',
        border: 'none',
        '&:hover': {
          background: theme.palette.grey.A100,
        },
        cursor: onClick ? 'pointer' : 'default',
      }}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      onClick={onClick}
    >
      {data.map((item, i) => {
        let justifyContent = 'flex-start';
        switch (item.align) {
          case 'left':
            justifyContent = 'flex-start';
            break;
          case 'center':
            justifyContent = 'space-around';
            break;
          case 'right':
            justifyContent = 'flex-end';
            break;
          default:
            justifyContent = i === data.length - 1 ? 'flex-end' : 'flex-start';
            break;
        }
        return (
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell-${id}-${i}`}
            sx={{
              position: 'relative',
              borderTop: `1px solid ${theme.palette.grey[200]}`,
              height: theme.spacing(4.5),
              maxHeight: theme.spacing(4.5),
              py: 1.5,
              px: 1,
            }}
          >
            <WithSkeleton wrap={!!isLoading}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent,
                }}
              >
                {item.display || item.value}
              </Box>
            </WithSkeleton>
            {showActions && i === 3 && hoverActions && <Box>{hoverActions}</Box>}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
