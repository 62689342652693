import { Link } from '@connectlyai-tenets/ui-styled-web';
import React, { FC, PropsWithChildren, useRef, useCallback } from 'react';
import { FlowVariableDialog, FlowVariableDialogProps, FlowVariableDialogRef } from '@components/FlowVariableDialog';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';

export const VariableButton: FC<
  PropsWithChildren & { onChange: FlowVariableDialogProps['handleEditorChange']; value: string }
> = ({ children, onChange, value }) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const flowDialogCodeMirrorRef = useRef<ReactCodeMirrorRef>({});
  const varDialogRef = useRef<FlowVariableDialogRef>(null);

  const handleClick = useCallback(() => {
    varDialogRef.current?.handleOpenDialog();
  }, [varDialogRef]);

  return (
    <>
      <Link ref={ref} onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Link>
      <FlowVariableDialog
        anchorEl={ref.current}
        codeMirrorRef={flowDialogCodeMirrorRef}
        value={value}
        handleEditorChange={onChange}
        ref={varDialogRef}
        isSessionVariable
        clearOnChange
      />
    </>
  );
};
