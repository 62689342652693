import { useEffect, useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
// eslint-disable-next-line max-len
import { MessageTemplateComponent_Button_Type } from '@connectlyai-idl/models/dist/events/facebook/graphapi/v12_0/whatsapp/models';
import { enUS, es, ptBR } from 'date-fns/locale';
import i18n from '../i18n';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { track } from './analytics';

// TODO get colors from theme
const backgroundColors = [
  '#F8FAFC',
  '#FEF2F2',
  '#FAF5FF',
  '#FFF7ED',
  '#FFFBEB',
  '#F0FDF4',
  '#CCFBF1',
  '#EFF6FF',
  '#FDF4FF',
];

const borderColors = [
  '#E2E8F0', // blueGrey 200
  '#FECACA', // red 200
  '#E9D5FF', // purple 200
  '#FED7AA', // orange 200
  '#FDE68A', // amber 200
  '#BBF7D0', // green 200
  '#99F6E4', // teal 200
  '#BFDBFE', // blue 200
  '#F5D0FE', // fuscia 200
];

const colors = [
  '#334155', // blueGrey 700
  '#B91C1C', // red 700
  '#7E22CE', // purple 700
  '#C2410C', // orange 700
  '#B45309', // amber 700
  '#15803D', // green 700
  '#0F766E', // teal 700
  '#1D4ED8', // blue 700
  '#A21CAF', // fuscia 700
];

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
function getHashCode(str: string): number {
  let hash = 0;
  let i;
  let chr;

  if (!str) return hash;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
}

export function getBackgroundColor(id: string): string {
  return backgroundColors[Math.abs(getHashCode(id)) % 9];
}
export function getBorderColor(id: string): string {
  return borderColors[Math.abs(getHashCode(id)) % 9];
}
export function getColor(id: string): string {
  return colors[Math.abs(getHashCode(id)) % 9];
}

function getFirstLetter(str: string | undefined): string | undefined {
  if (!str) {
    return '';
  }

  for (let i = 0; i < str.length; i += 1) {
    const char = str.charAt(i);
    if (char.toUpperCase() !== char.toLowerCase()) {
      return char;
    }
    if (char >= '0' && char <= '9') {
      return char;
    }
    if (char.length > 1) {
      return char;
    }
  }

  return [...str].length > 0 ? [...str][0] : '';
}

/**
 * Function that returns the 2 letters that can be used with avatar fallbacks.
 * @param displayName the display name
 * @returns 0-2 letters to display in a fallback avatar.
 */
export function getLetters(displayName: string | undefined): string | undefined {
  if (!displayName || displayName.length === 0) {
    return undefined;
  }

  const split = displayName.trim().split(' ');
  if (split.length === 1) {
    return getFirstLetter(split[0]);
  }
  if (split.length > 1) {
    return (getFirstLetter(split[0]) || '') + getFirstLetter(split[split.length - 1]);
  }

  return undefined;
}

export function isSafari(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return /^((?!chrome|android).)*safari/i.test(ua);
}

export function isAndroid(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export function isIOS(): boolean {
  const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

  if (navigator.platform && iDevices.indexOf(navigator.platform) > -1) {
    return true;
  }

  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isWindowsPhone(): boolean {
  if (navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  }

  if (navigator.userAgent.match(/iemobile/i)) {
    return true;
  }

  if (navigator.userAgent.match(/WPDesktop/i)) {
    return true;
  }

  return false;
}

export function isMobile(): boolean {
  return isAndroid() || isIOS() || isWindowsPhone();
}

export function isMacOS(): boolean {
  return navigator.platform.toLowerCase().indexOf('mac') >= 0;
}

export function isAppleDevice(): boolean {
  const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacIntel'];

  if (navigator.platform) {
    return iDevices.indexOf(navigator.platform) > -1;
  }

  return /iPad|iPhone|iPod|Mac\sOS\sX/.test(navigator.userAgent);
}

export type ButtonType = {
  id: number;
  label: string;
};

export const ButtonTypes: ButtonType[] = [
  {
    id: MessageTemplateComponent_Button_Type.QUICK_REPLY,
    label: 'Quick Reply',
  },
  {
    id: MessageTemplateComponent_Button_Type.URL,
    label: 'Web Site',
  },
  {
    id: MessageTemplateComponent_Button_Type.PHONE_NUMBER,
    label: 'Phone Number',
  },
];

export type Category = {
  id: string;
  label: string;
};

// eslint-disable-next-line max-len
/// https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/#interactive-message-template-request
export const Categories: Category[] = [
  {
    id: 'TRANSACTIONAL',
    label: 'Account Update',
  },
  {
    id: 'OTP',
    label: 'Payment Update',
  },
  {
    id: 'MARKETING',
    label: 'Personal Finance Update',
  },
];

export function getCategory(category: string): string {
  switch (category) {
    case 'ACCOUNT_UPDATE': {
      return 'Account Update';
    }
    case 'PAYMENT_UPDATE': {
      return 'Payment Update';
    }
    case 'PERSONAL_FINANCE_UPDATE': {
      return 'Personal Finance Update';
    }
    case 'SHIPPING_UPDATE': {
      return 'Shipping Update';
    }
    case 'RESERVATION_UPDATE': {
      return 'Reservation Update';
    }
    case 'ISSUE_RESOLUTION': {
      return 'Issue Resolution';
    }
    case 'APPOINTMENT_UPDATE': {
      return 'Appointment Update';
    }
    case 'TRANSPORTATION_UPDATE': {
      return 'Transportation Update';
    }
    case 'TICKET_UPDATE': {
      return 'Ticket Update';
    }
    case 'ALERT_UPDATE': {
      return 'Alert Update';
    }
    case 'AUTO_REPLY': {
      return 'Auto Reply';
    }
    default: {
      return category;
    }
  }
}

/// https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/#supported-languages
export const Languages: string[] = [
  'en',
  'es',
  'pt_BR',
  'sq',
  'ar',
  'az',
  'bn',
  'bg',
  'zh_CN',
  'hr',
  'cs',
  'da',
  'nl',
  'et',
  'fil',
  'fi',
  'fr',
  'ka',
  'de',
  'el',
  'gu',
  'he',
  'hi',
  'hu',
  'id',
  'it',
  'ja',
  'kk',
  'ko',
  'ms',
  'ml',
  'mr',
  'nb',
  'fa',
  'pl',
  'pa',
  'ro',
  'ru',
  'sr',
  'sk',
  'sl',
  'sv',
  'ta',
  'te',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
];

export function getCartAbandonmentLanguage(language: string): string {
  switch (language) {
    case 'en': {
      return i18n.t('common.languages.english');
    }
    case 'es': {
      return i18n.t('common.languages.spanish');
    }
    case 'pt_BR': {
      return i18n.t('common.languages.portuguese');
    }
    default: {
      return language;
    }
  }
}

export function getLanguageCode(language: string): string {
  switch (language) {
    case 'Afrikaans': {
      return 'af';
    }
    case 'Albanian': {
      return 'sq';
    }
    case 'Arabic': {
      return 'ar';
    }
    case 'Azerbaijani': {
      return 'az';
    }
    case 'Bengali': {
      return 'bn';
    }
    case 'Bulgarian': {
      return 'bg';
    }
    case 'Catalan': {
      return 'ca';
    }
    case 'Chinese': {
      return 'zh_CN';
    }
    case 'Chinese (HKG)': {
      return 'zh_HK';
    }
    case 'Chinese (TAI)': {
      return 'zh_TW';
    }
    case 'Croatian': {
      return 'hr';
    }
    case 'Czech': {
      return 'cs';
    }
    case 'Danish': {
      return 'da';
    }
    case 'Dutch': {
      return 'nl';
    }
    case 'English': {
      return 'en';
    }
    case 'English (UK)': {
      return 'en_GB';
    }
    case 'English (US)': {
      return 'en_US';
    }
    case 'Estonian': {
      return 'et';
    }
    case 'Filipino': {
      return 'fil';
    }
    case 'Finnish': {
      return 'fi';
    }
    case 'French': {
      return 'fr';
    }
    case 'Georgian': {
      return 'ka';
    }
    case 'German': {
      return 'de';
    }
    case 'Greek': {
      return 'el';
    }
    case 'Gujarati': {
      return 'gu';
    }
    case 'Hausa': {
      return 'ha';
    }
    case 'Hebrew': {
      return 'he';
    }
    case 'Hindi': {
      return 'hi';
    }
    case 'Hungarian': {
      return 'hu';
    }
    case 'Indonesian': {
      return 'id';
    }
    case 'Irish': {
      return 'ga';
    }
    case 'Italian': {
      return 'it';
    }
    case 'Japanese': {
      return 'ja';
    }
    case 'Kannada': {
      return 'kn';
    }
    case 'Kazakh': {
      return 'kk';
    }
    case 'Kinyarwanda': {
      return 'rw_RW';
    }
    case 'Korean': {
      return 'ko';
    }
    case 'Kyrgyz (Kyrgyzstan)': {
      return 'ky_KG';
    }
    case 'Lao': {
      return 'lo';
    }
    case 'Latvian': {
      return 'lv';
    }
    case 'Lithuanian': {
      return 'lt';
    }
    case 'Macedonian': {
      return 'mk';
    }
    case 'Malay': {
      return 'ms';
    }
    case 'Malayalam': {
      return 'ml';
    }
    case 'Marathi': {
      return 'mr';
    }
    case 'Norwegian': {
      return 'nb';
    }
    case 'Persian': {
      return 'fa';
    }
    case 'Polish': {
      return 'pl';
    }
    case 'Portuguese':
    case 'pt': {
      return 'pt_BR';
    }
    case 'Portuguese (POR)': {
      return 'pt_PT';
    }
    case 'Punjabi': {
      return 'pa';
    }
    case 'Romanian': {
      return 'ro';
    }
    case 'Russian': {
      return 'ru';
    }
    case 'Serbian': {
      return 'sr';
    }
    case 'Slovak': {
      return 'sk';
    }
    case 'Slovenian': {
      return 'sl';
    }
    case 'Spanish': {
      return 'es';
    }
    case 'Spanish (ARG)': {
      return 'es_AR';
    }
    case 'Spanish (SPA)': {
      return 'es_ES';
    }
    case 'Spanish (MEX)': {
      return 'es_MX';
    }
    case 'Swahili': {
      return 'sw';
    }
    case 'Swedish': {
      return 'sv';
    }
    case 'Tamil': {
      return 'ta';
    }
    case 'Telugu': {
      return 'te';
    }
    case 'Thai': {
      return 'th';
    }
    case 'Turkish': {
      return 'tr';
    }
    case 'Ukrainian': {
      return 'uk';
    }
    case 'Urdu': {
      return 'ur';
    }
    case 'Uzbek': {
      return 'uz';
    }
    case 'Vietnamese': {
      return 'vi';
    }
    case 'Zulu': {
      return 'zu';
    }
    default: {
      return language;
    }
  }
}

export function getLanguage(language: string): string {
  switch (language) {
    case 'af': {
      return 'Afrikaans';
    }
    case 'sq': {
      return 'Albanian';
    }
    case 'ar': {
      return 'Arabic';
    }
    case 'az': {
      return 'Azerbaijani';
    }
    case 'bn': {
      return 'Bengali';
    }
    case 'bg': {
      return 'Bulgarian';
    }
    case 'ca': {
      return 'Catalan';
    }
    case 'zh_CN': {
      return 'Chinese';
    }
    case 'zh_HK': {
      return 'Chinese (HKG)';
    }
    case 'zh_TW': {
      return 'Chinese (TAI)';
    }
    case 'hr': {
      return 'Croatian';
    }
    case 'cs': {
      return 'Czech';
    }
    case 'da': {
      return 'Danish';
    }
    case 'nl': {
      return 'Dutch';
    }
    case 'en': {
      return 'English';
    }
    case 'en_GB': {
      return 'English (UK)';
    }
    case 'en_US': {
      return 'English (US)';
    }
    case 'et': {
      return 'Estonian';
    }
    case 'fil': {
      return 'Filipino';
    }
    case 'fi': {
      return 'Finnish';
    }
    case 'fr': {
      return 'French';
    }
    case 'ka': {
      return 'Georgian';
    }
    case 'de': {
      return 'German';
    }
    case 'el': {
      return 'Greek';
    }
    case 'gu': {
      return 'Gujarati';
    }
    case 'ha': {
      return 'Hausa';
    }
    case 'he': {
      return 'Hebrew';
    }
    case 'hi': {
      return 'Hindi';
    }
    case 'hu': {
      return 'Hungarian';
    }
    case 'id': {
      return 'Indonesian';
    }
    case 'ga': {
      return 'Irish';
    }
    case 'it': {
      return 'Italian';
    }
    case 'ja': {
      return 'Japanese';
    }
    case 'kn': {
      return 'Kannada';
    }
    case 'kk': {
      return 'Kazakh';
    }
    case 'rw_RW': {
      return 'Kinyarwanda';
    }
    case 'ko': {
      return 'Korean';
    }
    case 'ky_KG': {
      return 'Kyrgyz (Kyrgyzstan)';
    }
    case 'lo': {
      return 'Lao';
    }
    case 'lv': {
      return 'Latvian';
    }
    case 'lt': {
      return 'Lithuanian';
    }
    case 'mk': {
      return 'Macedonian';
    }
    case 'ms': {
      return 'Malay';
    }
    case 'ml': {
      return 'Malayalam';
    }
    case 'mr': {
      return 'Marathi';
    }
    case 'nb': {
      return 'Norwegian';
    }
    case 'fa': {
      return 'Persian';
    }
    case 'pl': {
      return 'Polish';
    }
    case 'pt_BR':
    case 'pt-BR': {
      return 'Portuguese';
    }
    case 'pt_PT':
    case 'pt-PT': {
      return 'Portuguese (POR)';
    }
    case 'pa': {
      return 'Punjabi';
    }
    case 'ro': {
      return 'Romanian';
    }
    case 'ru': {
      return 'Russian';
    }
    case 'sr': {
      return 'Serbian';
    }
    case 'sk': {
      return 'Slovak';
    }
    case 'sl': {
      return 'Slovenian';
    }
    case 'es': {
      return 'Spanish';
    }
    case 'es_AR':
    case 'es-AR': {
      return 'Spanish (ARG)';
    }
    case 'es_ES':
    case 'es-ES': {
      return 'Spanish (SPA)';
    }
    case 'es_MX':
    case 'es-MX': {
      return 'Spanish (MEX)';
    }
    case 'sw': {
      return 'Swahili';
    }
    case 'sv': {
      return 'Swedish';
    }
    case 'ta': {
      return 'Tamil';
    }
    case 'te': {
      return 'Telugu';
    }
    case 'th': {
      return 'Thai';
    }
    case 'tr': {
      return 'Turkish';
    }
    case 'uk': {
      return 'Ukrainian';
    }
    case 'ur': {
      return 'Urdu';
    }
    case 'uz': {
      return 'Uzbek';
    }
    case 'vi': {
      return 'Vietnamese';
    }
    case 'zu': {
      return 'Zulu';
    }
    default: {
      return language;
    }
  }
}

export type ConnectWhatsAppParameters = {
  businessId: string;
  name?: string;
  phoneNumber?: string;
  appId: string;
};

export function getAppId(useVtex: boolean): string {
  if (useVtex) {
    return process.env.REACT_APP_INBOX_VTEX_WA_LOGIN_APP_ID || '';
  }
  return process.env.REACT_APP_INBOX_WA_LOGIN_APP_ID || '';
}

export const WHATSAPP_INTEGRATION_SCOPE = ['whatsapp_business_management', 'whatsapp_business_messaging'];

export function useConnectWhatsApp(
  { businessId, name, phoneNumber, appId }: ConnectWhatsAppParameters,
  onComplete: (accessToken: string, userId: number, dataAccessExpirationTime: number, expiresIn: number) => void,
  onCancel: () => void,
): [() => void, boolean] {
  const { ffEnableWhatsAppV19 } = useFeatureFlag(['ffEnableWhatsAppV19']);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w: any = window;
  const [isConnecting, setIsConnecting] = useState(false);
  useEffect(() => {
    w.fbAsyncInit = () => {
      w.FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: ffEnableWhatsAppV19 ? 'v19.0' : 'v14.0',
      });
    };
    const loadSDK = (d: Document, s: string, id: string) => {
      if (d.getElementById(id)) {
        return;
      }
      const fjs = d.getElementsByTagName(s)[0] as HTMLScriptElement;
      if (!fjs) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    };
    loadSDK(document, 'script', 'facebook-jssdk');
    if (w.fbq) {
      w.fbq('trackCustom', 'WhatsAppOnboardingStart', {
        appId,
        feature: 'whatsapp_embedded_signup',
      });
    }
  }, [w]);

  const startConnect = () => {
    // 32-bits attempt id
    const attemptId = Math.floor(Math.random() * (2 ** 32 - 1));
    setIsConnecting(true);
    track('WA login started', {
      attemptId,
      businessId,
    });

    // https://developers.facebook.com/docs/whatsapp/embedded-signup/pre-filled-data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setup: any = {};
    try {
      const parsedNumber = parsePhoneNumber(phoneNumber || '');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const business: any = {};
      if (parsedNumber) {
        business.phone = {
          code: parsedNumber.countryCallingCode,
          number: parsedNumber.nationalNumber,
        };
      }
      if (name) {
        business.name = name;
      }

      setup.business = business;
      // eslint-disable-next-line no-empty
    } catch {}

    w.FB.login(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        if (response.authResponse) {
          const {
            accessToken,
            userID: userId,
            data_access_expiration_time: dataAccessExpirationTime,
            expiresIn,
          } = response.authResponse;
          track('WA login completed', {
            attemptId,
            businessId,
            response,
          });
          onComplete(accessToken, userId, dataAccessExpirationTime, expiresIn);
        } else {
          track('WA login canceled', {
            attemptId,
            businessId,
            response,
          });
          setIsConnecting(false);
          onCancel();
        }
      },
      {
        scope: WHATSAPP_INTEGRATION_SCOPE.join(','),
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup,
        },
      },
    );
  };

  return [startConnect, isConnecting];
}

export function stringToBoolean(str: string | null): boolean {
  if (str === null) {
    return false;
  }

  switch (str.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
      return false;
    default:
      return Boolean(str);
  }
}

export function downloadSampleCSV(fileName: string): void {
  const rows = [['14151231234'], ['14151231235']];

  const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
}

export function getLocale(language: string): Locale {
  switch (language) {
    case 'es':
      return es;
    case 'pt':
      return ptBR;
    default:
      return enUS;
  }
}
