import React, { FC } from 'react';
import {
  Box,
  LowPriorityIcon,
  Button,
  Label,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { useFeatureFlag } from '@hooks';
import { ButtonGroupProps } from './types';

export const ButtonGroup: FC<ButtonGroupProps> = ({ getItemComponent, isReorderEnabled, items, label, onReorder }) => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const enableNewTemplateButtons = getFlagByName('ffEnableNewTemplateButtons') || ffEnableETA;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  if (items.length === 0) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: isLargeScreen ? 2 : 1 }}>
      {enableNewTemplateButtons && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip arrow placement="top" title="Swap button group order">
            <Button
              disabled={!isReorderEnabled || !enableNewTemplateButtons}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onReorder}
              sx={{ m: 0, p: 0, minWidth: 32, minHeight: 32 }}
            >
              <LowPriorityIcon />
            </Button>
          </Tooltip>
          {label && <Label variant="subtitle2">{label}</Label>}
        </Box>
      )}
      {items.map(getItemComponent)}
    </Box>
  );
};
