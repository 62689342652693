import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import {
  MUTATION_UPSERT_TAG_URL,
  MutationUpsertTagInput,
  MutationUpsertTagOptions,
  MutationUpsertTagResponse,
} from './types';

export const useMutationUpsertTag = (options?: MutationUpsertTagOptions) => {
  return useMutation(
    [],
    (input: MutationUpsertTagInput) =>
      api.profileV1(MUTATION_UPSERT_TAG_URL, 'put', {
        path: { 'input.businessId': input.businessId, 'input.tagId': input.id },
        body: {
          input: {
            config: {
              v1: {
                ...input,
              },
            },
          },
        },
      }) as Promise<MutationUpsertTagResponse>,
    options,
  );
};
