import { BusinessChannelsResponse } from './types';

export const selectWhatsappProfilePicture = (businessChannels: BusinessChannelsResponse) =>
  businessChannels.entity?.entries?.find((channel) => channel.channelType === 'CHANNEL_TYPE_WHATSAPP_CLOUD')
    ?.remoteProfile?.wabaPhoneNumber?.profilePictureUrl;
export const selectWhatsappBusinessChannel = (businessChannels: BusinessChannelsResponse) =>
  businessChannels.entity?.entries?.find((channel) => channel.channelType === 'CHANNEL_TYPE_WHATSAPP_CLOUD');

export const selectVtexBusinessChannel = (businessChannels: BusinessChannelsResponse) =>
  businessChannels.entity?.entries?.find((channel) => channel.channelType === 'CHANNEL_TYPE_VTEX');

export const selectVtexBusinessChannelId = (businessChannels: BusinessChannelsResponse): string | undefined =>
  businessChannels.entity?.entries?.find((channel) => channel.channelType === 'CHANNEL_TYPE_VTEX')?.id;

export const selectShopifyBusinessChannel = (businessChannels: BusinessChannelsResponse) =>
  businessChannels.entity?.entries?.find((channel) => channel.channelType === 'CHANNEL_TYPE_SHOPIFY');
