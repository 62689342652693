import React, { FC, PropsWithChildren, MouseEventHandler } from 'react';
import { Box, useMediaQuery, useTheme, SxProps, Theme, ClickAwayListener } from '@connectlyai-tenets/ui-styled-web';

export const TemplateBuilderItem: FC<
  PropsWithChildren<{
    error?: boolean;
    sx?: SxProps<Theme>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onClickOutside?: () => void;
  }>
> = ({ children, error, sx, onClickOutside = () => {}, onClick }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <Box
        onClick={onClick}
        sx={[
          {
            borderRadius: '10px',
            px: isLargeScreen ? 3 : 2,
            p: isLargeScreen ? 2 : 1,
            border: `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Box>
    </ClickAwayListener>
  );
};
