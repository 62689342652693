import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { ScheduleSendoutVariables, ScheduleSendoutResponse, SCHEDULE_SENDOUT_URL } from './types';

export const useScheduleSendoutMutation = (
  options?: Omit<
    UseMutationOptions<ScheduleSendoutResponse, ConnectlyError, ScheduleSendoutVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, sendoutId, ...rest }: ScheduleSendoutVariables) =>
      api.campaignV3(SCHEDULE_SENDOUT_URL, 'post', {
        path: { 'input.businessId': businessId, 'input.sendoutId': sendoutId },
        body: { input: rest },
      }) as Promise<ScheduleSendoutResponse>,
    options,
  );
