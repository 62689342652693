import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_ADD_OPT_OUT_URL = '/v1/businesses/{businessId}/opt_out_list';

type MutationAddOptOutUrl = typeof MUTATION_ADD_OPT_OUT_URL;

export type MutationAddOptOutResponse = Paths['profileV1'][MutationAddOptOutUrl]['post']['responses']['200']['schema'];
export type MutationAddOptOutPathParameters = Paths['profileV1'][MutationAddOptOutUrl]['post']['parameters']['path'];
export type MutationAddOptOutBodyParameters =
  Paths['profileV1'][MutationAddOptOutUrl]['post']['parameters']['body']['input'];
export type MutationAddOptOutParameters = MutationAddOptOutPathParameters & MutationAddOptOutBodyParameters;
export type MutationAddOptOutOptions = Omit<
  UseMutationOptions<MutationAddOptOutResponse, ConnectlyError, MutationAddOptOutParameters, unknown>,
  'mutationKey' | 'mutationFn'
>;
