import React, { useCallback, useEffect, useMemo, useState, Suspense, useRef } from 'react';
import { Provider } from 'react-redux';
import { Socket } from 'socket.io-client';
import { Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ChatRoomConfig, ChatRoomProps } from '@connectlyai-features/chatroom';
import { CustomerConfig, CustomerProps } from '@connectlyai-features/customer';
import { UnauthorizedView } from '@connectlyai-features/welcome';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  InboxRouteId,
  AudienceRouteId,
  ChatRoomRouteArgs,
  ChatRoomRouteId,
  CustomerRouteArgs,
  CustomerRouteId,
  generateHomeRoute,
} from '@connectlyai-sdks/inbox-navigation';
import { RealBusinessDataStore } from '@connectlyai-sdks/data-business';
import { ContextDataStore, RealContextDataStore, Filter, Context } from '@connectlyai-sdks/data-context';
import { RealCustomerDataStore } from '@connectlyai-sdks/data-customer';
import { RealRoomsDataStore } from '@connectlyai-sdks/data-rooms';
import { RealDtoUpdateAccessor, DtoUpdate, RoomDto, DtoUpdateAccessor } from '@connectlyai-sdks/data-update';
import { RealUserDataStore, User, UserState } from '@connectlyai-sdks/data-user';
import { RealNotificationService } from '@connectlyai-sdks/notification';
import { LoadingState } from '@connectlyai-sdks/loading';
import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { Logger } from '@connectlyai-tenets/logging';
import { adminBusinessIdAtom } from '@atoms/app';
import { Observable } from 'rxjs';
import {
  createMemoryHistory,
  DelegatingNavigator,
  HistoryNavigator,
  IllegalHostingError,
  useHistory,
} from '@connectlyai-tenets/navigation';
import { DataResponse } from '@connectlyai-tenets/data';
import { UIRegistry } from '@connectlyai-tenets/ui-registry';
import {
  AdapterDateFns,
  Box,
  ChevronLeftIcon,
  IconButton,
  LoadingGif,
  LocalizationProvider,
  makeStyles,
  MenuIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { RedirectToLogin, ProtectedRoute, useAuthedNetworkClient, useAuth } from '@connectlyai-sdks/auth';
import { NetworkClient } from '@connectlyai-tenets/network-request';
import { OnboardingModals } from '@scenes/Sofia/components/Onboarding';
import { INBOX_DID_INIT } from './analytics';
import { EXPERIMENT_SUPPORT_ENABLED } from './experiments';
import { buildCampaignsDIContainer, CampaignsDIContext } from './features/campaigns';
import Notifications from './features/notifications';
import Support from './features/support';
import './App.css';
import RealNavigator from './RealNavigator';
import {
  COLOR_CONNECTLY_GREY,
  COLOR_CONNECTLY_GREY_DARK,
  COLOR_CONNECTLY_GREY_LIGHT,
  SIZE_MEDIA_M,
  SIZE_SPACING_GUTTER_H,
} from './ui-theme';
import store from './state/store';
import { FlowDIContext, FlowDIContextProps } from './features/flow/contexts/FlowDIContext';
import { useEffectOnce, useLogger, useZendeskWidget } from './hooks';
import { DataContext, LoggerContext, NotificationContext } from './contexts';
import { NetworkContext } from './contexts/network';
import { Settings } from './scenes/Settings';
import { SettingsSMB } from './scenes/SettingsSMB';
import { Analytics as AnalyticsScene } from './scenes/Analytics';
import { ShopifySignUp } from './scenes/ShopifySignup';
import { CustomerProfile } from './scenes/CustomerProfile';
import { NavigationContext } from './contexts/navigation';
import { Audience as AudienceScene } from './scenes/Audience';
import { Automation, Home, Sofia, SofiaNew } from './scenes';
import { AdminModal, CONNECTLY_BUSINESS_ID } from './components/Admin';
import {
  ANALYTICS_ROUTER_ID,
  BROADCAST_ROUTER_ID,
  connectionIssuesSet,
  FLOW_ROUTER_ID,
  SETTINGS_ROUTER_ID,
  SIDEPANE_ROUTER_ID,
  SMB_SETTINGS,
} from './constants';
import { ReactComponent as NoThreadsIcon } from './assets/nothreads.svg';
import { AppLayout } from './components/AppLayout';
import { setUserId, setUserProperties, track } from './utils';
import {
  AppContainer,
  Blank,
  BlankBody,
  Content,
  EmptyPanel,
  InboxContainer,
  InboxContent,
  InboxPaneContainer,
  MainPane,
  SidebarContainer,
} from './styled';

const isNewHomeEnabled = getFlagByName('ffEnableNewHome');
const ffEnableSofiaSubpages = getFlagByName('ffEnableSofiaSubpages');

// to be loaded lazy
const SignUp = React.lazy(() => import(/* webpackChunkName: "signup" */ '@connectlyai-features/signup'));
const Broadcast = React.lazy(() => import(/* webpackChunkName: "broadcast" */ '@connectlyai-features/broadcast'));
const Welcome = React.lazy(() => import(/* webpackChunkName: "welcome" */ '@connectlyai-features/welcome'));

// load chatroom features at the same time by using same chunk name
const ChatRoom = React.lazy(() => import(/* webpackChunkName: "chatroom" */ '@connectlyai-features/chatroom'));
const Sidebar = React.lazy(() => import(/* webpackChunkName: "chatroom" */ '@connectlyai-features/sidebar'));
const Customer = React.lazy(() => import(/* webpackChunkName: "chatroom" */ '@connectlyai-features/customer'));

const WhatsAppBroadcast = React.lazy(() =>
  import(
    /* webpackChunkName: "broadcast" */
    './scenes'
  ).then((module) => ({
    default: module.WhatsAppBroadcast,
  })),
);
const FlowChart = React.lazy(() =>
  import(
    /* webpackChunkName: "FlowChart" */
    './scenes'
  ).then((module) => ({ default: module.FlowChart })),
);

export type AppConfig = {
  audience: string;
  sidePaneNavigationInitialEntries?: string[];
};

export type AppProps = {
  config: AppConfig;
  log: Logger;
  networkClient: NetworkClient;
  widgetClient: NetworkClient;
  realtimeClient: (accessToken: string) => Socket;
  uiRegistry: UIRegistry;
};

const EmptyThread = () => {
  return (
    <EmptyPanel>
      <Blank>
        <NoThreadsIcon />
        <BlankBody>No Thread Selected</BlankBody>
      </Blank>
    </EmptyPanel>
  );
};

const ChatRoomWithParams = <
  PassedProps extends Omit<ChatRoomProps, keyof { config: ChatRoomConfig }> & {
    businessId: string;
    businessUserId: string;
  },
>(
  props: PassedProps,
) => {
  const params = useParams<ChatRoomRouteArgs>();
  if (params === undefined) {
    throw new Error();
  }
  const { roomId } = params;
  const { businessId, businessUserId } = props;

  const config = useMemo(() => ({ businessId, businessUserId, roomId }), [businessId, businessUserId, roomId]);

  const allProps: ChatRoomProps = {
    ...props,
    config,
  };
  return (
    <Suspense>
      <ChatRoom
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allProps}
      />
    </Suspense>
  );
};

const CustomerWithParams = <
  PassedProps extends Omit<CustomerProps, keyof { config: CustomerConfig }> & {
    businessId: string;
  },
>(
  props: PassedProps,
) => {
  const params = useParams<CustomerRouteArgs>();
  if (params === undefined) {
    throw new Error();
  }
  const { customerId } = params;
  const { businessId } = props;

  const config = useMemo(() => ({ customerId, businessId }), [customerId, businessId]);

  const allProps: CustomerProps = {
    ...props,
    config,
  };
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingGif />
        </Box>
      }
    >
      <Customer
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...allProps}
      />
    </Suspense>
  );
};

const useStyles = makeStyles((theme) => ({
  app: {
    '--activitybarColorMain': COLOR_CONNECTLY_GREY,
    '--activitybarColorLight': COLOR_CONNECTLY_GREY_LIGHT,
    '--activitybarColorDark': COLOR_CONNECTLY_GREY_DARK,
    // todo remove - only used by fullPageLayout / BroadcastMessageTemplatesNewWebView
    '--headerHeight': theme.spacing(9),
    '--menuWidth': theme.spacing(30),
    '--navbarHeight': theme.spacing(7),
    '--navbarGutter': theme.spacing(SIZE_SPACING_GUTTER_H),
    '--navbarColorMain': COLOR_CONNECTLY_GREY_LIGHT,
    '--windowBackgroundColor': theme.palette.background.default,
    '--paperBackgroundColor': theme.palette.background.paper,
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
    },
  },
});

function App({ config, log, networkClient, widgetClient, realtimeClient, uiRegistry }: AppProps): JSX.Element {
  useEffect(() => {
    log.debug(INBOX_DID_INIT, () => ({}));
  }, []);

  const sessionId = useMemo(() => datadogRum.getInternalContext()?.session_id, []);
  const { authedClient } = useAuthedNetworkClient({ networkClient, audience: config.audience, sessionId });
  const { getAccessTokenSilently } = useAuth();
  const logProvider = useCallback(() => log, []);
  const networkClientProvider = useCallback(() => authedClient, []);
  const widgetClientProvider = useCallback(() => widgetClient, []);
  const authTokenProvider = useCallback(() => getAccessTokenSilently, []);
  const realtimeClientProvider = useCallback(() => realtimeClient, []);
  const uiRegistryProvider = useCallback(() => uiRegistry, []);
  const [loadingState, setLoadingState] = useState<LoadingState>(0);
  const [unauthorizedEmail, setUnauthorizedEmail] = useState<string | undefined>(undefined);
  const [unauthorizedMethod, setUnauthorizedMethod] = useState<'sso' | undefined>(undefined);
  // todo remove
  const [activitybarOpen, setActivitybarOpen] = useState(false);
  const [historyStackStartIndex, setHistoryStackStartIndex] = useState(0);
  const [, setSidePaneOpen] = useState<boolean>(config.sidePaneNavigationInitialEntries !== undefined);

  const [realUser, setRealUser] = useState<User | null>(null);
  const [adminBusinessId, setAdminBusinessId] = useAtom(adminBusinessIdAtom);
  const user = useMemo(
    () =>
      adminBusinessId && realUser?.business.id === CONNECTLY_BUSINESS_ID
        ? {
            ...realUser,
            business: {
              id: adminBusinessId,
              name: '',
            },
          }
        : realUser,
    [adminBusinessId, realUser],
  );
  const isAdminUser = useMemo(() => realUser?.business?.id === CONNECTLY_BUSINESS_ID, [realUser?.business?.id]);

  // ----
  const notificationService = new RealNotificationService();
  const notificationServiceProvider = useCallback(() => notificationService, []);
  // # Data layer initialization
  const dtoUpdateAccessorRef = useRef<DtoUpdateAccessor | null>(null);
  const dtoUpdateAccessor = useMemo(() => {
    if (!user?.business.id || !user?.accessToken || !user?.id || !logProvider() || !realtimeClientProvider()) {
      const emptyRealDtoUpdateAccessor: DtoUpdateAccessor = {
        subscriptionDtoUpdate: () => new Observable<DtoUpdate>(),
        subscriptionSerializedDto: () => new Observable<string>(),
        subscriptionRoomDto: () => new Observable<RoomDto>(),
      };
      return emptyRealDtoUpdateAccessor;
    }
    if (!dtoUpdateAccessorRef.current) {
      const businessId = user?.business.id || '';
      const accessToken = user?.accessToken || '';
      const clientId = user?.id || '';
      dtoUpdateAccessorRef.current = new RealDtoUpdateAccessor({
        onDisconnect: (reason: Socket.DisconnectReason) => {
          if (!connectionIssuesSet.has(reason)) {
            return;
          }
          track('network_unavailable', {
            businessId,
          });
        },
        config: {
          accessToken,
          businessId,
          clientId,
          isAdminUser,
        },
        logProvider,
        realtimeClientProvider,
      });
    }
    return dtoUpdateAccessorRef.current;
  }, [isAdminUser, user?.id, user?.business.id, user?.accessToken, logProvider, realtimeClientProvider]);

  const dtoUpdateAccessorProvider = useCallback(() => dtoUpdateAccessor, [dtoUpdateAccessor]);
  const businessDataStore = useMemo(
    () =>
      new RealBusinessDataStore({
        dtoUpdateAccessorProvider,
        logProvider,
        networkClientProvider,
      }),
    [dtoUpdateAccessorProvider],
  );

  const businessDataStoreProvider = useCallback(() => businessDataStore, [businessDataStore]);

  const userDataStore = useMemo(
    () =>
      new RealUserDataStore({
        logProvider,
        networkClientProvider,
        authTokenProvider,
      }),
    [],
  );
  const userDataStoreProvider = useCallback(() => userDataStore, []);

  useEffect(() => {
    const sub = userDataStore.userState().subscribe((value: UserState | null) => {
      if ((value?.loading || value?.loading === 0) && value.loading !== loadingState) {
        setLoadingState(value.loading);
      }
      if (value?.unauthorized?.email && value.unauthorized.email !== unauthorizedEmail) {
        setUnauthorizedEmail(value.unauthorized.email);
      }
      if (value?.unauthorized?.method && value.unauthorized.method !== unauthorizedMethod) {
        setUnauthorizedMethod(value.unauthorized.method);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [userDataStore]);

  useEffect(() => {
    const sub = userDataStore.loggedInUser().subscribe((userResponse: DataResponse<User | null>) => {
      userResponse.fold(
        (value: User | null) => {
          track('logins in', {
            id: value?.id,
            firstName: value?.firstName,
            lastName: value?.lastName,
            displayName: value?.displayName,
            business: value?.business,
          });
          if (value) {
            setUserId(value.id);
            setUserProperties({
              id: value?.id,
              firstName: value?.firstName,
              lastName: value?.lastName,
              displayName: value?.displayName,
              business: value?.business,
            });
            // if user was previously admin, then logs in to a non-admin account, clear adminBusinessId
            if (value?.business?.id && adminBusinessId) {
              if (value.business.id !== CONNECTLY_BUSINESS_ID) {
                setAdminBusinessId('');
              }
            }
          }
          setRealUser(value);
        },
        () => {},
        (_err: Error) => {},
      );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [adminBusinessId, setAdminBusinessId, userDataStore]);

  // Use a button or any event handler to trigger the inspection
  const contextDataStoreRef = useRef<ContextDataStore | null>(null);
  const contextDataStore = useMemo(() => {
    if (!user?.business.id || !businessDataStoreProvider() || !logProvider()) {
      const emptyContextDataStore: ContextDataStore = {
        mutationSetFilter: (_: string) => Promise.resolve(),
        mutationUnsetFilter: (_: string) => Promise.resolve(),
        subscriptionContext: () => new Observable<Context>(),
        subscriptionFilters: () => new Observable<Filter[]>(),
      };
      return emptyContextDataStore;
    }
    if (!contextDataStoreRef.current) {
      const businessId = user?.business.id || '';
      contextDataStoreRef.current = new RealContextDataStore({
        config: {
          businessId,
        },
        logProvider,
        businessDataStoreProvider,
      });
    }
    return contextDataStoreRef.current;
  }, [user?.business.id, logProvider, businessDataStoreProvider]);
  const contextDataStoreProvider = useCallback(() => contextDataStore, [contextDataStore]);
  const roomsDataStore = useMemo(() => {
    const businessId = user?.business.id || '';
    const accessToken = user?.accessToken;
    return new RealRoomsDataStore({
      onDurationRoomsQuery: (durationInSeconds: number) => {
        track('duration_rooms_query', {
          businessId,
          duration_in_seconds: durationInSeconds,
        });
      },
      config: {
        accessToken,
        businessId,
      },
      dtoUpdateAccessorProvider,
      logProvider,
      notificationServiceProvider,
      networkClientProvider,
    });
  }, [
    user?.business.id,
    user?.accessToken,
    dtoUpdateAccessorProvider,
    logProvider,
    notificationServiceProvider,
    networkClientProvider,
  ]);
  const roomsDataStoreProvider = useCallback(() => roomsDataStore, [roomsDataStore]);
  const customerDataStore = useMemo(() => {
    const businessId = user?.business.id || '';
    const accessToken = user?.accessToken;
    return new RealCustomerDataStore({
      config: {
        accessToken,
        businessId,
      },
      logProvider,
      networkClientProvider,
    });
  }, [user?.business.id, user?.accessToken, logProvider, networkClientProvider]);
  const customerDataStoreProvider = useCallback(() => customerDataStore, [user?.business.id, user?.accessToken]);

  const sidebarConfig = useMemo(
    () => ({ businessId: user?.business.id || '', userId: user?.id || '' }),
    [user?.business.id, user?.id],
  );
  const welcomeConfig = useMemo(
    () => ({
      businessId: user?.business.id || '',
      businessUserId: user?.id || '',
    }),
    [user?.business.id, user?.id],
  );
  const broadcastConfig = useMemo(() => ({ businessId: user?.business.id || '' }), [user?.business.id]);

  // #region navigation
  const mainPaneHistory = useHistory();
  useEffect(() => {
    setHistoryStackStartIndex(mainPaneHistory.length);
  }, []);
  const sidePaneHistory = useMemo(
    () =>
      createMemoryHistory({
        initialEntries: config.sidePaneNavigationInitialEntries,
      }),
    [],
  );
  if (mainPaneHistory === undefined) {
    throw IllegalHostingError;
  }
  const mainPaneNavigator = useMemo(() => new HistoryNavigator(mainPaneHistory), [mainPaneHistory]);
  const sidePaneNavigator = useMemo(() => new HistoryNavigator(sidePaneHistory, SIDEPANE_ROUTER_ID), [sidePaneHistory]);
  const globalNavigator = useMemo(
    () =>
      new RealNavigator(
        logProvider,
        {
          id: new Set([BROADCAST_ROUTER_ID, InboxRouteId, SETTINGS_ROUTER_ID, ANALYTICS_ROUTER_ID, FLOW_ROUTER_ID]),
          instance: mainPaneNavigator,
          // toodo remove
          setStateOpen: setActivitybarOpen,
        },
        {
          id: new Set([SIDEPANE_ROUTER_ID]),
          instance: sidePaneNavigator,
          setStateOpen: setSidePaneOpen,
        },
      ),
    [mainPaneNavigator, sidePaneNavigator, setSidePaneOpen],
  );
  const navigatorForActivitybar = useMemo(() => new DelegatingNavigator(globalNavigator, '/'), [globalNavigator]);
  const navigatorForAnalytics = useMemo(
    () => new DelegatingNavigator(globalNavigator, ANALYTICS_ROUTER_ID),
    [globalNavigator],
  );
  const navigatorForBroadcast = useMemo(
    () => new DelegatingNavigator(globalNavigator, BROADCAST_ROUTER_ID),
    [globalNavigator],
  );
  const navigatorForInbox = useMemo(() => new DelegatingNavigator(globalNavigator, InboxRouteId), [globalNavigator]);
  const navigatorForAudience = useMemo(
    () => new DelegatingNavigator(globalNavigator, AudienceRouteId),
    [globalNavigator],
  );
  const navigatorForFlow = useMemo(() => new DelegatingNavigator(globalNavigator, FLOW_ROUTER_ID), [globalNavigator]);
  const navigatorForSettings = useMemo(
    () => new DelegatingNavigator(globalNavigator, SETTINGS_ROUTER_ID),
    [globalNavigator],
  );
  const navigatorForSidePane = useMemo(
    () => new DelegatingNavigator(globalNavigator, SIDEPANE_ROUTER_ID),
    [globalNavigator],
  );

  const navigatorProviderForActivitybar = useCallback(() => navigatorForActivitybar, [globalNavigator]);
  const navigatorProviderForAnalytics = useCallback(() => navigatorForAnalytics, [globalNavigator]);
  const navigatorProviderForBroadcast = useCallback(() => navigatorForBroadcast, [globalNavigator]);
  const navigatorProviderForInbox = useCallback(() => navigatorForInbox, [globalNavigator]);
  const navigatorProviderForAudience = useCallback(() => navigatorForAudience, [globalNavigator]);
  const navigatorProviderForFlow = useCallback(() => navigatorForFlow, [globalNavigator]);
  const navigatorProviderForSettings = useCallback(() => navigatorForSettings, [globalNavigator]);
  const navigatorProviderForSidePane = useCallback(() => navigatorForSidePane, [globalNavigator]);

  const handleTopbarNavigationMenuClick = () => {
    setActivitybarOpen(true);
  };
  const handleTopbarNavigationBackClick = () => {
    if (mainPaneHistory.length > historyStackStartIndex) {
      mainPaneHistory.goBack();
    } else {
      mainPaneHistory.replace(generateHomeRoute().id);
    }
  };
  // #endregion

  const styles = useStyles();
  const theme = useTheme();
  const isMediaXs = useMediaQuery(theme.breakpoints.down('sm'));
  const sidebarExtensions = useMemo(() => {
    const MenuTopBarNavigation = () => (
      <IconButton
        style={{
          width: theme.spacing(SIZE_MEDIA_M),
          height: theme.spacing(SIZE_MEDIA_M),
        }}
        onClick={handleTopbarNavigationMenuClick}
      >
        <MenuIcon />
      </IconButton>
    );
    return isMediaXs
      ? {
          TopBarNavigation: MenuTopBarNavigation,
        }
      : undefined;
  }, [theme, isMediaXs, handleTopbarNavigationMenuClick]);
  const chatroomExtensions = useMemo(() => {
    const BackTopBarNavigation = () => (
      <IconButton
        style={{
          width: theme.spacing(SIZE_MEDIA_M),
          height: theme.spacing(SIZE_MEDIA_M),
        }}
        onClick={handleTopbarNavigationBackClick}
      >
        <ChevronLeftIcon />
      </IconButton>
    );
    return isMediaXs
      ? {
          TopBarNavigation: BackTopBarNavigation,
        }
      : undefined;
  }, [theme, isMediaXs, handleTopbarNavigationBackClick]);

  const activitybarConfig = useMemo(
    () => ({
      businessId: user?.business.id || '',
      isMediaXs,
    }),
    [isMediaXs, user?.business.id],
  );

  const unauthorizedView = useMemo(
    () => (
      <UnauthorizedView
        uiRegistryProvider={uiRegistryProvider}
        method={unauthorizedMethod || undefined}
        email={unauthorizedEmail}
      />
    ),
    [unauthorizedEmail, unauthorizedMethod],
  );

  useEffect(() => {
    document.body.classList.add(styles.app);
    return () => {
      document.body.classList.remove(styles.app);
    };
  }, []);

  const query = useQuery();
  const campaignsDIContext = useMemo(
    () =>
      buildCampaignsDIContainer({
        config: {},
        logProvider,
        navigatorProvider: navigatorProviderForBroadcast,
      }),
    [logProvider, navigatorProviderForBroadcast],
  );

  const flowDIContext: FlowDIContextProps = useMemo(
    () => ({
      dtoUpdateAccessorProvider,
      navigatorProvider: navigatorProviderForFlow,
      networkClientProvider,
      notificationServiceProvider,
    }),
    [dtoUpdateAccessorProvider, navigatorProviderForFlow, networkClientProvider, notificationServiceProvider],
  );

  const notificationDeps = useMemo(
    () => ({
      notificationProvider: notificationServiceProvider,
    }),
    [notificationServiceProvider],
  );

  const navigationDeps = useMemo(
    () => ({
      navigatorProviderForAnalytics,
      navigatorProviderForAudience,
      navigatorProviderForSettings,
      navigatorProviderForFlow,
      navigatorProviderForActivitybar,
      navigatorProviderForInbox,
    }),
    [
      navigatorProviderForAnalytics,
      navigatorProviderForAudience,
      navigatorProviderForSettings,
      navigatorProviderForFlow,
      navigatorProviderForActivitybar,
      navigatorProviderForInbox,
    ],
  );

  const { sugar } = useLogger();
  const { loggerDeps } = useMemo(
    () => sugar({ business_id: user?.business.id, logger: 'App' }),
    [user?.business.id, sugar],
  );
  const networkDeps = useMemo(
    () => ({
      networkClientProvider,
    }),
    [networkClientProvider],
  );

  const dataDeps = useMemo(
    () => ({
      businessDataStoreProvider,
    }),
    [businessDataStoreProvider],
  );

  useEffect(() => {
    if (!user?.displayName || !user?.business.id) return;
    datadogRum.setUser({ name: user?.displayName, id: user?.id, email: user?.username });
    datadogRum.setGlobalContextProperty('business_id', user?.business.id);
  }, [user]);

  useEffectOnce(() => {
    datadogRum.setGlobalContextProperty('screen_height', window.screen.height);
    datadogRum.setGlobalContextProperty('screen_width', window.screen.width);
    datadogRum.startSessionReplayRecording();
    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  });

  useZendeskWidget();

  return (
    <DataContext.Provider value={dataDeps}>
      <LoggerContext.Provider value={loggerDeps}>
        <NetworkContext.Provider value={networkDeps}>
          <NotificationContext.Provider value={notificationDeps}>
            <NavigationContext.Provider value={navigationDeps}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                  <Provider store={store}>
                    <AppContainer className="App">
                      {isAdminUser && <AdminModal />}
                      <Notifications
                        logProvider={logProvider}
                        notificationServiceProvider={notificationServiceProvider}
                      />
                      {EXPERIMENT_SUPPORT_ENABLED && (
                        <Support logProvider={logProvider} widgetClientProvider={widgetClientProvider} />
                      )}
                      <Content>
                        <Switch>
                          <Route exact path="/">
                            <RedirectToLogin audience={config.audience} redirectPath={generateHomeRoute().id} />
                          </Route>
                          <Route exact path="/signup">
                            {query.get('token') ? (
                              <Suspense>
                                <SignUp
                                  logProvider={logProvider}
                                  uiRegistryProvider={uiRegistryProvider}
                                  networkClientProvider={() => networkClient}
                                  token={query.get('token') || ''}
                                />
                              </Suspense>
                            ) : (
                              <ShopifySignUp audience={config.audience} redirectPath={generateHomeRoute().id} />
                            )}
                          </Route>
                          <ProtectedRoute
                            path="/"
                            audience={config.audience}
                            loadingState={loadingState || LoadingState.UNINITIALIZED}
                            unauthorizedView={unauthorizedView}
                          >
                            <AppLayout isAdminUser={isAdminUser}>
                              <OnboardingModals />
                              <Switch>
                                <Route path={InboxRouteId}>
                                  <InboxContainer>
                                    <InboxContent>
                                      <Route path={InboxRouteId} exact={isMediaXs}>
                                        <SidebarContainer>
                                          <Suspense>
                                            <Sidebar
                                              businessDataStoreProvider={businessDataStoreProvider}
                                              config={sidebarConfig}
                                              contextDataStoreProvider={contextDataStoreProvider}
                                              extensions={sidebarExtensions}
                                              log={logProvider}
                                              navigator={navigatorProviderForInbox}
                                              networkClientProvider={networkClientProvider}
                                              roomsDataStore={roomsDataStoreProvider}
                                              uiRegistry={uiRegistryProvider}
                                            />
                                          </Suspense>
                                        </SidebarContainer>
                                      </Route>
                                      <InboxPaneContainer>
                                        <MainPane isFirstPane={false}>
                                          <Switch>
                                            <Route path={InboxRouteId + ChatRoomRouteId}>
                                              <ChatRoomWithParams
                                                businessDataStoreProvider={businessDataStoreProvider}
                                                businessId={user?.business.id || ''}
                                                businessUserId={user?.id || ''}
                                                extensions={chatroomExtensions}
                                                logProvider={logProvider}
                                                navigator={navigatorProviderForInbox}
                                                networkClientProvider={networkClientProvider}
                                                notificationServiceProvider={notificationServiceProvider}
                                                roomsDataStoreProvider={roomsDataStoreProvider}
                                                userDataStoreProvider={userDataStoreProvider}
                                                uiRegistryProvider={uiRegistryProvider}
                                              />
                                            </Route>
                                            {!isMediaXs && (
                                              <Route exact path={InboxRouteId}>
                                                <EmptyThread />
                                              </Route>
                                            )}
                                          </Switch>
                                        </MainPane>
                                        <Router history={sidePaneHistory}>
                                          <Switch>
                                            <Route path={SIDEPANE_ROUTER_ID + CustomerRouteId}>
                                              <CustomerProfile>
                                                <CustomerWithParams
                                                  businessId={user?.business.id || ''}
                                                  customerDataStoreProvider={customerDataStoreProvider}
                                                  logProvider={logProvider}
                                                  navigatorProvider={navigatorProviderForSidePane}
                                                  networkClientProvider={networkClientProvider}
                                                  uiRegistryProvider={uiRegistryProvider}
                                                />
                                              </CustomerProfile>
                                            </Route>
                                          </Switch>
                                        </Router>
                                      </InboxPaneContainer>
                                    </InboxContent>
                                  </InboxContainer>
                                </Route>
                                {!isMediaXs && (
                                  <Route path={SETTINGS_ROUTER_ID}>
                                    <MainPane isFirstPane>
                                      <Settings />
                                    </MainPane>
                                  </Route>
                                )}
                                {!isMediaXs && (
                                  <Route path={BROADCAST_ROUTER_ID}>
                                    <MainPane isFirstPane>
                                      <CampaignsDIContext.Provider value={campaignsDIContext}>
                                        <Suspense>
                                          <Broadcast
                                            config={broadcastConfig}
                                            businessDataStoreProvider={businessDataStoreProvider}
                                            dtoUpdateAccessorProvider={dtoUpdateAccessorProvider}
                                            logProvider={logProvider}
                                            navigatorProvider={navigatorProviderForBroadcast}
                                            notificationServiceProvider={notificationServiceProvider}
                                            networkClientProvider={networkClientProvider}
                                            uiRegistryProvider={uiRegistryProvider}
                                            WhatsAppBroadcast={WhatsAppBroadcast}
                                          />
                                        </Suspense>
                                      </CampaignsDIContext.Provider>
                                    </MainPane>
                                  </Route>
                                )}
                                {!isMediaXs && (
                                  <Route path={ANALYTICS_ROUTER_ID}>
                                    <MainPane isFirstPane>
                                      <FlowDIContext.Provider value={flowDIContext}>
                                        <AnalyticsScene />
                                      </FlowDIContext.Provider>
                                    </MainPane>
                                  </Route>
                                )}
                                <Route path={SMB_SETTINGS}>
                                  <MainPane isFirstPane>
                                    <SettingsSMB />
                                  </MainPane>
                                </Route>
                                <Route path="/flow">
                                  <MainPane isFirstPane>
                                    <FlowDIContext.Provider value={flowDIContext}>
                                      <Suspense>
                                        <FlowChart />
                                      </Suspense>
                                    </FlowDIContext.Provider>
                                  </MainPane>
                                </Route>
                                <Route path={['/automations', '/automation']}>
                                  <MainPane isFirstPane>
                                    <Automation />
                                  </MainPane>
                                </Route>
                                <Route path="/sofia">
                                  {ffEnableSofiaSubpages && (
                                    <MainPane isFirstPane>
                                      <SofiaNew />
                                    </MainPane>
                                  )}
                                  {!ffEnableSofiaSubpages && (
                                    <MainPane isFirstPane>
                                      <Sofia />
                                    </MainPane>
                                  )}
                                </Route>

                                <Route path="/audience">
                                  <MainPane isFirstPane>
                                    <AudienceScene />
                                  </MainPane>
                                </Route>
                                <Route path={generateHomeRoute().id}>
                                  <MainPane isFirstPane>
                                    <Suspense>
                                      {isNewHomeEnabled && (
                                        <Home dtoUpdateAccessorProvider={dtoUpdateAccessorProvider} />
                                      )}
                                      {!isNewHomeEnabled && (
                                        <Welcome
                                          businessDataStoreProvider={businessDataStoreProvider}
                                          config={welcomeConfig}
                                          dtoUpdateAccessorProvider={dtoUpdateAccessorProvider}
                                          navigatorProvider={navigatorProviderForInbox}
                                          notificationServiceProvider={notificationServiceProvider}
                                          networkClientProvider={networkClientProvider}
                                          widgetClientProvider={widgetClientProvider}
                                          logProvider={logProvider}
                                          uiRegistryProvider={uiRegistryProvider}
                                          userDataStoreProvider={userDataStoreProvider}
                                        />
                                      )}
                                    </Suspense>
                                  </MainPane>
                                </Route>
                              </Switch>
                            </AppLayout>
                          </ProtectedRoute>
                        </Switch>
                      </Content>
                    </AppContainer>
                  </Provider>
                </QueryClientProvider>
              </LocalizationProvider>
            </NavigationContext.Provider>
          </NotificationContext.Provider>
        </NetworkContext.Provider>
      </LoggerContext.Provider>
    </DataContext.Provider>
  );
}

export default App;
