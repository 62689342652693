import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, LinearProgress, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { selectBusinessId, useMeData, useQuerySubscriptionURL } from '@hooks';
import { track } from 'src/utils';
import { ErrorDialog } from '../../../../components/ErrorDialog';
import { PaymentOnboarding } from '../../../../components/PaymentOnboarding';
import { SMBPaymentProps } from './types';

export const SMBPayment = ({ subscriptionStatus }: SMBPaymentProps) => {
  const [showPaymentError, setShowPaymentError] = useState(subscriptionStatus === 'SUBSCRIPTION_STATUS_CANCELLED');

  const { t } = useTranslation('translation', { keyPrefix: 'welcome.smbOnboardingFooter' });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: subscriptionURL, isLoading: isLoadingSubscriptionUrl } = useQuerySubscriptionURL(businessId as string);

  const onNextStep = () => {
    track('(onboarding) complete', { businessId });
  };

  const onPaymentErrorClose = useCallback(() => {
    setShowPaymentError(false);
  }, [setShowPaymentError]);

  const theme = useTheme();
  return (
    <>
      <ErrorDialog
        open={showPaymentError}
        onClose={onPaymentErrorClose}
        title={t('paymentOnboarding.paymentDeclinedTitle')}
        content={t('paymentOnboarding.paymentDeclinedContent')}
      />
      <Box
        sx={{
          m: 1,
          alignSelf: 'center',
          borderRadius: theme.spacing(1.5),
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Box sx={{ width: 600, minHeight: 580, display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                width: '100%',
                background: '#F6F8FF',
                userSelect: 'none',
              }}
            >
              <Box
                alt=""
                component="img"
                height={200}
                draggable={false}
                src={`${process.env.PUBLIC_URL}/assets/welcome/onboarding_payment.png`}
              />
            </Box>
            <Box sx={{ flex: '1 1 auto' }}>
              <PaymentOnboarding subscriptionStatus={subscriptionStatus} />
            </Box>
            <Box
              sx={{
                height: 68,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ mx: 3, display: 'flex', gap: 1, alignSelf: 'flex-end' }}>
                <Button
                  variant="contained"
                  disabled={isLoadingSubscriptionUrl}
                  href={subscriptionURL?.confirmationUrl ?? ''}
                  target="_blank"
                  onClick={onNextStep}
                >
                  {isLoadingSubscriptionUrl ? <CircularProgress size={16} /> : t('authorizePayment')}
                </Button>
              </Box>
              <LinearProgress variant="determinate" value={100} sx={{ height: 10 }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
