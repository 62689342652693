import { MessageNodeData } from '@connectlyai-tenets/sdk';
import { cloneDeep } from 'lodash';
import { CardHeader, MessageNodeUIState } from '../../components/MessageNodeEditor/types';
import { DEFAULT_MESSAGE_NODE_UI } from '../../components/MessageNodeEditor/constants';

const getHeader = (
  cardIndex: number,
  mappings: {
    rawParam?: string;
    fullQualifier?: string;
  }[],
  header?: CardHeader,
) => {
  const hasVariable = mappings.find((mapping) => mapping.rawParam === `card.${cardIndex}.header.image`);
  if (hasVariable) {
    return {
      variable: `{{${hasVariable.fullQualifier}}}`,
    };
  }
  return header;
};

export const messageNodeDataToUIState = (uiState: MessageNodeData): MessageNodeUIState => {
  const result = cloneDeep(DEFAULT_MESSAGE_NODE_UI);
  const mappings = uiState.v1?.parameterMapping?.mappings || [];
  if (uiState.v1?.messageTemplateInput?.templateComponents) {
    const templateComponents = uiState.v1?.messageTemplateInput?.templateComponents;
    const dictionary = templateComponents.reduce(
      (acc, component) => ({
        ...acc,
        ...component,
      }),
      {},
    );
    result.body = dictionary.body?.text?.text || '';
    if (!dictionary.carousel) {
      delete result.carousel;
      return result;
    }
    result!.carousel!.active = 0;
    if (dictionary.carousel?.cards?.length) {
      result!.carousel!.cards = dictionary.carousel.cards.map((card, index) => {
        const cardDictionary = card.components?.reduce((acc: Record<string, unknown>, component) => {
          if (component.button) {
            const newComp = cloneDeep(component);
            if (newComp?.button?.url && newComp.button.url.trackedUrl) {
              newComp.button.url.url = newComp.button.url.trackedUrl;
            }
            return {
              ...acc,
              buttons: acc.buttons ? [...(acc.buttons as Array<unknown>), newComp] : [newComp],
            };
          }
          if (component.body) {
            return {
              ...acc,
              body: component.body?.text?.text || '',
            };
          }
          return {
            ...acc,
            ...component,
          };
        }, {}) as NonNullable<MessageNodeUIState['carousel']>['cards'][number];
        return {
          body: cardDictionary?.body || '',
          buttons: cardDictionary?.buttons || [],
          header: getHeader(index, mappings, cardDictionary?.header),
        };
      });
    }
  }
  return result;
};
