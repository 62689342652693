export var ConnectlyIconKind;
(function (ConnectlyIconKind) {
    ConnectlyIconKind[ConnectlyIconKind["UNKNOWN"] = 0] = "UNKNOWN";
    ConnectlyIconKind[ConnectlyIconKind["CONNECTLY"] = 1] = "CONNECTLY";
    ConnectlyIconKind[ConnectlyIconKind["MESSENGER"] = 2] = "MESSENGER";
    ConnectlyIconKind[ConnectlyIconKind["SMS"] = 3] = "SMS";
    ConnectlyIconKind[ConnectlyIconKind["INSTAGRAM"] = 4] = "INSTAGRAM";
    ConnectlyIconKind[ConnectlyIconKind["WHATSAPP"] = 5] = "WHATSAPP";
    ConnectlyIconKind[ConnectlyIconKind["FACEBOOK"] = 6] = "FACEBOOK";
    ConnectlyIconKind[ConnectlyIconKind["CONNECTLY_FESTIVE"] = 7] = "CONNECTLY_FESTIVE";
    ConnectlyIconKind[ConnectlyIconKind["CONNECTLY_CELEBRATION"] = 8] = "CONNECTLY_CELEBRATION";
    ConnectlyIconKind[ConnectlyIconKind["INBOX"] = 9] = "INBOX";
    ConnectlyIconKind[ConnectlyIconKind["SETTINGS"] = 10] = "SETTINGS";
    ConnectlyIconKind[ConnectlyIconKind["BROADCAST"] = 11] = "BROADCAST";
    ConnectlyIconKind[ConnectlyIconKind["WIDGET"] = 12] = "WIDGET";
    ConnectlyIconKind[ConnectlyIconKind["HOME"] = 13] = "HOME";
    ConnectlyIconKind[ConnectlyIconKind["QUESTION"] = 14] = "QUESTION";
    ConnectlyIconKind[ConnectlyIconKind["ANALYTICS"] = 15] = "ANALYTICS";
    ConnectlyIconKind[ConnectlyIconKind["AI_MESSAGE"] = 16] = "AI_MESSAGE";
    ConnectlyIconKind[ConnectlyIconKind["AC_MESSAGE"] = 17] = "AC_MESSAGE";
    ConnectlyIconKind[ConnectlyIconKind["AUTOMATION_NAV_ICON"] = 18] = "AUTOMATION_NAV_ICON";
})(ConnectlyIconKind || (ConnectlyIconKind = {}));
