import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';
import { selectDocumentId } from '../../state/flow';

export const useReactFlowDocumentChangeData = (onReactFlowDocumentChange: () => void) => {
  const documentId = useSelector(selectDocumentId);

  const { dtoUpdateAccessorProvider } = useContext(FlowDIContext) as FlowDIContextProps;

  useEffect(() => {
    let sub: Subscription;
    if (documentId) {
      sub = dtoUpdateAccessorProvider()
        .subscriptionDtoUpdate()
        .pipe(
          filter(
            (dtoUpdate) =>
              dtoUpdate.objectId === documentId &&
              dtoUpdate.resultOneof?.$case === 'reactFlowDocumentChange' &&
              dtoUpdate.resultOneof?.reactFlowDocumentChange !== undefined,
          ),
          map((dtoUpdate) => {
            if (dtoUpdate.resultOneof?.$case !== 'reactFlowDocumentChange') {
              return unreachable();
            }
            return dtoUpdate?.resultOneof?.reactFlowDocumentChange;
          }),
          filter((reactFlowDocumentChange) => !!reactFlowDocumentChange),
        )
        .subscribe(() => {
          onReactFlowDocumentChange();
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [documentId, onReactFlowDocumentChange, dtoUpdateAccessorProvider]);
};
