/* eslint-disable react/no-array-index-key */
import React, { useMemo, ChangeEventHandler, useState, useCallback, useTransition } from 'react';
import { CardCounter } from '@components/FlowChartCampaignV3/components/CardCounter';
import { CarouselFooterNavigation } from '@components/FlowChartCampaignV3/components/CarouselFooterNavigation';
import { CarouseUploadBox } from '@components/FlowChartCampaignV3/components/CarouselUploadBox';
import { AddButton } from '@components/TemplateBuilderButtons/AddButton';
import { TemplateBuilderItem } from '@components/TemplateBuilderItem';
import { TemplateBuilderTitle } from '@components/TemplateBuilderTitle';
import { cloneDeep, get, set } from 'lodash';
import {
  ViewCarouselOutlined,
  Divider,
  Box,
  IconButton,
  DeleteOutlineIcon,
  useTheme,
  ArrowForwardIosIcon,
  ArrowBackIosIcon,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useNodeUIState } from '@components/FlowChartCampaignV3/hooks/useNodeUIState';
import { CardHeader, MessageNodeUIState } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';
import { ButtonType } from '@components/TemplateBuilderButtons';
import { v4 as uuidv4 } from 'uuid';
import {
  useFlowVariables,
  parseVariables,
  FlowVariableExtended,
  mapToVariableHashV2,
  populateVariablesV2,
} from '@hooks/useFlowVariables';
import { TemplateBuilderBodyCard } from '@components/TemplateBuilderBodyCard';
import { mappingsAtom, selectedNodeAtom } from '@atoms/flow';
import { useAtom, useAtomValue } from 'jotai';
import createButton from './createButton';
import QuickReply from './QuickReply';
import PhoneNumber from './PhoneNumber';
import LinkToUrl from './LinkToUrl';
import { CAROUSEL_CARDS_LIMIT } from './constants';
import { getNewButtons } from './getNewButtons';

export const TemplateBuilderCarousel = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'carouselBuilder' });
  const { nodeUIState, setNodeUIStateKey } = useNodeUIState<MessageNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
  });

  const [, startTransition] = useTransition();

  const cards = useMemo(() => nodeUIState?.carousel?.cards || [], [nodeUIState]);
  const active = useMemo(() => nodeUIState.carousel?.active || 0, [nodeUIState]);
  const activeCard = useMemo(() => cards[active], [cards, active]);
  const [deleteButtonIndex, setDeleteButtonIndex] = useState<number>();
  const { canonicalize, addVariables, variablesSet } = useFlowVariables();
  const [, setMappings] = useAtom(mappingsAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);

  const handleInput = useCallback(
    (value: string) => {
      startTransition(() => {
        const newCards = cloneDeep(cards);
        newCards[active].body = value;
        setNodeUIStateKey('carousel', {
          ...nodeUIState.carousel,
          active,
          cards: newCards,
        });
        setMappings((prev) => ({
          ...prev,
          ...mapToVariableHashV2(value, `card.${active}.body.`, '{{}}'),
        }));
      });
    },
    [active, cards, nodeUIState.carousel, setMappings, setNodeUIStateKey],
  );

  const handleFooterNavigationClick = (index: number) => {
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: index,
      cards,
    });
  };

  const handleAddButtonClick = (type: ButtonType) => {
    const newCards = cloneDeep(cards).map((card) => ({
      ...card,
      buttons: [...(card.buttons || []), createButton(type)],
    }));
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active,
      cards: newCards,
    });
  };

  const handleAddCardClick = () => {
    const clonedCards = cloneDeep(cards);
    const newCards = [...clonedCards, { body: '', buttons: getNewButtons(clonedCards) }];
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: newCards.length - 1,
      cards: newCards,
    });
  };

  const handleDeleteCard = () => {
    const newCards = cloneDeep(cards);
    newCards.splice(active, 1);
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: active === newCards.length ? active - 1 : active,
      cards: newCards,
    });
    setMappings((prev) => {
      return Object.keys(prev).reduce((acc, key) => {
        if (key.startsWith(`card.${active}.`)) {
          return acc;
        }
        return {
          ...acc,
          [key]: prev[key],
        };
      }, {});
    });
  };

  const handleChangeUploadBox = (comp?: CardHeader) => {
    if (comp?.variable) {
      setMappings((prev) => ({
        ...prev,
        [`card.${active}.header.image`]: String(comp?.variable).replace(/{{|}}/g, ''),
      }));
      const newVariables = parseVariables(comp?.variable).reduce((acc: Array<FlowVariableExtended>, variable) => {
        if (!variablesSet.has(variable)) {
          acc.push({
            id: uuidv4(),
            type: 'FLOW_TYPE_STRING',
            namespace: '',
            namespaceCanonical: '',
            simpleName: variable,
            simpleNameCanonical: canonicalize(variable),
            validators: [],
          });
        }
        return acc;
      }, []);
      addVariables(newVariables);
    }
    const newCards = cloneDeep(cards);
    newCards[active].header = comp;
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active,
      cards: newCards,
    });
  };

  const handlePrevious = () => {
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: active - 1,
      cards,
    });
  };

  const handleNext = () => {
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: active + 1,
      cards,
    });
  };

  const createQuickReplyChangeHandler =
    (index: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      setNodeUIStateKey('carousel', {
        ...nodeUIState.carousel,
        active,
        cards: set(
          cloneDeep(cards),
          `${active}.buttons.${index}.button.quickReply.text`,
          (event.target as HTMLInputElement).value,
        ),
      });
    };

  const createPhoneTextChangeHandler =
    (index: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      setNodeUIStateKey('carousel', {
        ...nodeUIState.carousel,
        active,
        cards: set(
          cloneDeep(cards),
          `${active}.buttons.${index}.button.phoneNumber.text`,
          (event.target as HTMLInputElement).value,
        ),
      });
    };

  const createPhoneChangeHandler =
    (index: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      setNodeUIStateKey('carousel', {
        ...nodeUIState.carousel,
        active,
        cards: set(
          cloneDeep(cards),
          `${active}.buttons.${index}.button.phoneNumber.phoneNumber`,
          (event.target as HTMLInputElement).value,
        ),
      });
    };

  const createDeleteButtonHandler = (index: number) => () => {
    setDeleteButtonIndex(index);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteButtonIndex(undefined);
  };

  const handleConfirmDelete = () => {
    const newCards = cloneDeep(cards).map((card) => ({
      ...card,
      buttons: card.buttons!.filter((_, i) => i !== deleteButtonIndex),
    }));
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active,
      cards: newCards,
    });
    setDeleteButtonIndex(undefined);
  };

  const createUrlTextChangeHandler =
    (index: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      setNodeUIStateKey('carousel', {
        ...nodeUIState.carousel,
        active,
        cards: set(
          cloneDeep(cards),
          `${active}.buttons.${index}.button.url.text`,
          (event.target as HTMLInputElement).value,
        ),
      });
    };

  const createUrlChangeHandler = (index: number) => (value: string) => {
    startTransition(() => {
      setNodeUIStateKey('carousel', {
        ...nodeUIState.carousel,
        active,
        cards: set(cloneDeep(cards), `${active}.buttons.${index}.button.url.url`, value),
      });

      setMappings((prev) => ({
        ...prev,
        ...mapToVariableHashV2(value, `card.${active}.button.${index}.urlSuffix.`, '{{}}'),
      }));
    });
  };

  const createTrackedChangeHandler =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const clonedCards = cloneDeep(cards);
      if (checked) {
        setNodeUIStateKey('carousel', {
          ...nodeUIState.carousel,
          active,
          cards: set(
            clonedCards,
            `${active}.buttons.${index}.button.url.trackedUrl`,
            get(clonedCards, `${active}.buttons.${index}.button.url.url`, 'www.example.com'),
          ),
        });
      } else {
        setNodeUIStateKey('carousel', {
          ...nodeUIState.carousel,
          active,
          cards: set(clonedCards, `${active}.buttons.${index}.button.url.trackedUrl`, ''),
        });
      }
    };

  const handleReorder = (from: number, to: number) => {
    const newCards = cloneDeep(cards);
    const [removed] = newCards.splice(from, 1);
    newCards.splice(to, 0, removed);
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active,
      cards: newCards,
    });
  };

  return (
    <TemplateBuilderItem sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
      <TemplateBuilderTitle icon={<ViewCarouselOutlined color="action" />}>
        {t('carouselCardsTitle')}
      </TemplateBuilderTitle>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CardCounter current={active + 1} limit={cards.length} />
        {cards.length > 2 && (
          <IconButton onClick={handleDeleteCard}>
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <CarouseUploadBox onChange={handleChangeUploadBox} value={activeCard.header} />
        {active !== 0 && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              left: -20,
              transform: 'translateY(-50%)',
              fontSize: 12,
              height: 40,
              width: 40,
              background: theme.palette.common.white,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: alpha(theme.palette.common.black, 0.2),
              boxShadow: `0px 4px 26px 0px ${alpha(theme.palette.common.black, 0.16)}`,
              '&:hover': {
                background: alpha(theme.palette.common.white, 0.5),
              },
            }}
            onClick={handlePrevious}
          >
            <ArrowBackIosIcon sx={{ height: 12, marginLeft: 0.5 }} />
          </IconButton>
        )}
        {active !== cards.length - 1 && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              right: -20,
              transform: 'translateY(-50%)',
              fontSize: 12,
              height: 40,
              width: 40,
              background: theme.palette.common.white,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: alpha(theme.palette.common.black, 0.2),
              boxShadow: `0px 4px 26px 0px ${alpha(theme.palette.common.black, 0.16)}`,
              '&:hover': {
                background: alpha(theme.palette.common.white, 0.5),
              },
            }}
            onClick={handleNext}
          >
            <ArrowForwardIosIcon sx={{ height: 12 }} />
          </IconButton>
        )}
      </Box>
      <TemplateBuilderBodyCard minimized value={activeCard.body} onInput={handleInput} active={active} />
      <Dialog
        open={deleteButtonIndex !== undefined}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 3, fontWeight: 700, fontSize: 26 }}>
          {t('dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('dialogContent')}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 3, paddingBottom: 3 }}>
          <Button variant="outlined" color="secondary" onClick={handleCloseDeleteDialog}>
            {t('dialogCancel')}
          </Button>
          <Button variant="outlined" color="error" onClick={handleConfirmDelete} autoFocus>
            {t('dialogDelete')}
          </Button>
        </DialogActions>
      </Dialog>
      {activeCard.buttons?.map((button, index) => {
        if (button.button?.quickReply) {
          return (
            <QuickReply
              key={`button-${index}`}
              value={String(button.button.quickReply.text)}
              index={index}
              onChange={createQuickReplyChangeHandler(index)}
              onDelete={createDeleteButtonHandler(index)}
            />
          );
        }
        if (button.button?.phoneNumber) {
          return (
            <PhoneNumber
              key={`button-${index}`}
              value={String(button.button.phoneNumber.text)}
              valuePhone={String(button.button.phoneNumber.phoneNumber)}
              index={index}
              onChange={createPhoneTextChangeHandler(index)}
              onChangePhone={createPhoneChangeHandler(index)}
              onDelete={createDeleteButtonHandler(index)}
            />
          );
        }

        if (button.button?.url) {
          return (
            <LinkToUrl
              key={`button-${index}`}
              value={String(button.button.url.text)}
              valueUrl={populateVariablesV2(
                String(button.button.url.url),
                `card.${active}.button.${index}.urlSuffix`,
                selectedNode?.data?.v1?.parameterMapping?.mappings,
              )}
              tracked={Boolean(button.button.url.trackedUrl)}
              index={index}
              onChange={createUrlTextChangeHandler(index)}
              onChangeUrl={createUrlChangeHandler(index)}
              onDelete={createDeleteButtonHandler(index)}
              onChangeTracked={createTrackedChangeHandler(index)}
            />
          );
        }
        return null;
      })}
      <AddButton group={0} buttons={activeCard.buttons || []} onClick={handleAddButtonClick} />
      <Divider />
      <CarouselFooterNavigation
        cards={cards}
        active={active}
        onClick={handleFooterNavigationClick}
        onAdd={handleAddCardClick}
        onReorder={handleReorder}
        outOfLimit={cards.length >= CAROUSEL_CARDS_LIMIT}
      />
    </TemplateBuilderItem>
  );
};
