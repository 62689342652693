import { filter, map, tap } from 'rxjs/operators';
import { unreachable } from '@connectlyai-tenets/static-analysis';
export const subscriptionDtoUpdateRoomProvider = (log, accessor) => () => (accessor.subscriptionDtoUpdate().pipe(filter((dtoUpdate) => {
    var _a;
    return (((_a = dtoUpdate.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'roomDto');
}), map((dtoUpdate) => {
    var _a;
    if (((_a = dtoUpdate.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) !== 'roomDto') {
        return unreachable();
    }
    return dtoUpdate.resultOneof.roomDto;
}), tap((verifyCampaignResult) => {
    log.debug('subscriptionDtoUpdateRoom@data', () => ({
        verifyCampaignResult,
    }));
})));
