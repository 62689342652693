type FormatOptions = {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  hour12?: boolean;
};

export const formatDate = (date: string, options?: FormatOptions) => {
  const dateObject = new Date(date);
  if (Number.isNaN(dateObject.getTime())) {
    return '';
  }
  return new Intl.DateTimeFormat(navigator.language || 'en-GB', {
    year: options ? options.year : '2-digit',
    month: options ? options.month : '2-digit',
    day: options ? options.day : '2-digit',
    hour: options ? options.hour : '2-digit',
    minute: options ? options.minute : '2-digit',
    hour12: options ? options.hour12 : false,
  }).format(dateObject);
};
