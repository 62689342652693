import React, { useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  FacebookIcon,
  InstagramIcon,
  Label,
  SMSIcon,
  Tooltip,
  useTheme,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { FbLoginType } from '@connectlyai-idl/models/dist/events/facebook/webhook/v6_0/models';
import { editChannelsAtom } from '@atoms/settings';
import {
  selectBusinessId,
  selectFBMessengerChannel,
  selectInstagramChannel,
  selectSMSChannel,
  useFeatureFlag,
  useMeData,
  useSettingsData,
} from '../../../../hooks';
import { SIZE_INPUT_M, SIZE_INPUT_XXL, SIZE_MEDIA_XXS } from '../../../../ui-theme';
import { MessagingChannelsProps } from './types';
import { formatPhoneNumber } from '../../../../utils';

const useMessagingChannels = () => {
  const [editChannels, setEditChannels] = useAtom(editChannelsAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: smsChannel } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSMSChannel,
  });
  const { data: fbChannel } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectFBMessengerChannel,
  });
  const { data: igChannel } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectInstagramChannel,
  });

  const handleEditFBChannel = useCallback(() => {
    setEditChannels({
      ...editChannels,
      editFBChannelName: true,
    });
  }, [editChannels, setEditChannels]);

  const handleEditIGChannel = useCallback(() => {
    setEditChannels({
      ...editChannels,
      editIgChannelName: true,
    });
  }, [editChannels, setEditChannels]);

  const phoneNumber = useMemo(() => {
    if (smsChannel?.externalId) {
      return formatPhoneNumber(smsChannel.externalId, false);
    }
    return '';
  }, [smsChannel]);

  const fbMessengerPageName = useMemo(() => fbChannel?.remoteProfile?.fbPageProfile?.name ?? 'Connected', [fbChannel]);

  const igPageHandler = useMemo(() => igChannel?.remoteProfile?.igUserProfile?.username ?? '', [igChannel]);

  const facebookLoginParams = useMemo(() => {
    return new URLSearchParams({
      client_id: process.env.REACT_APP_INBOX_FB_CLIENT_ID || '', // fb client id
      redirect_uri: process.env.REACT_APP_INBOX_FB_REDIRECT_URI || '', // webhook backend
      scope: 'pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement,catalog_management',
      response_type: 'code',
      auth_type: 'rerequest',
      state: JSON.stringify({
        id: businessId,
        business_id: businessId,
        type: FbLoginType.FB_LOGIN_TYPE_FACEBOOK,
      }),
    });
  }, [businessId]);

  const facebookLoginUrl = useMemo(() => new URL('https://www.facebook.com/v10.0/dialog/oauth'), []);
  facebookLoginUrl.search = facebookLoginParams.toString();
  const instagramLoginUrl = useMemo(() => {
    const url = new URL(facebookLoginUrl.toString());
    url.searchParams.set(
      'scope',
      'pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement,catalog_management,' +
        'instagram_basic,instagram_manage_messages,business_management,instagram_manage_comments',
    );
    url.searchParams.set(
      'state',
      JSON.stringify({
        id: businessId,
        business_id: businessId,
        type: FbLoginType.FB_LOGIN_TYPE_INSTAGRAM,
      }),
    );
    return url;
  }, [businessId, facebookLoginUrl]);

  return {
    editChannels,
    phoneNumber,
    fbMessengerPageName,
    igPageHandler,
    instagramLoginUrl,
    facebookLoginUrl,
    handleEditFBChannel,
    handleEditIGChannel,
  };
};
export const MessagingChannels = ({ readOnly }: MessagingChannelsProps) => {
  const theme = useTheme();

  const {
    editChannels,
    phoneNumber,
    fbMessengerPageName,
    igPageHandler,
    instagramLoginUrl,
    facebookLoginUrl,
    handleEditFBChannel,
    handleEditIGChannel,
  } = useMessagingChannels();

  return (
    <SaveActionCard
      title="Messaging channels"
      subtitle="Manage the channels you wish to talk to your customers"
      sx={{ gap: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Label variant="caption" color="textSecondary">
          Phone Number
        </Label>
        <Tooltip title="Your assigned SMS number">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: theme.palette.common.white,
              border: `1px solid ${theme.palette.divider}`,
              pl: 2,
              gap: 1,
              width: theme.spacing(SIZE_INPUT_XXL),
              height: theme.spacing(SIZE_INPUT_M),
              borderRadius: 5,
            }}
          >
            <SMSIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
            <Label variant="body1" sx={{ fontWeight: 600 }}>
              {phoneNumber}
            </Label>
          </Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Label variant="caption" color="textSecondary">
          Facebook Messenger
        </Label>
        {fbMessengerPageName === '' || editChannels.editFBChannelName ? (
          <Tooltip title="Log in with Facebook">
            <Button
              variant="outlined"
              color="secondary"
              href={facebookLoginUrl.href}
              sx={{
                display: 'flex',
                gap: 1,
                width: theme.spacing(SIZE_INPUT_XXL),
                height: theme.spacing(SIZE_INPUT_M),
                color: theme.palette.primary.main,
                fontWeight: 800,
              }}
            >
              <FacebookIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
              <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
                Connect Facebook
              </Label>
            </Button>
          </Tooltip>
        ) : (
          // eslint-disable-next-line max-len
          <Tooltip title="Your connected Facebook Account Page. If the name doesn't show correctly, you can try reconnecting to refresh it.">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.divider}`,
                pl: 2,
                width: theme.spacing(SIZE_INPUT_XXL),
                height: theme.spacing(SIZE_INPUT_M),
                borderRadius: 5,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FacebookIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
                <Label variant="body1" sx={{ fontWeight: 600 }}>
                  {fbMessengerPageName}
                </Label>
              </Box>
              {!readOnly && (
                <Button
                  disableRipple
                  variant="text"
                  color="primary"
                  onClick={handleEditFBChannel}
                  sx={{
                    margin: 0,
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Label variant="caption" color="textSecondary">
          Instagram
        </Label>
        {igPageHandler === '' || editChannels.editIgChannelName ? (
          <Tooltip title="Log in with Instagram">
            <Button
              variant="outlined"
              color="secondary"
              href={instagramLoginUrl.href}
              sx={{
                display: 'flex',
                gap: 1,
                width: theme.spacing(SIZE_INPUT_XXL),
                height: theme.spacing(SIZE_INPUT_M),
                color: theme.palette.primary.main,
                fontWeight: 800,
              }}
            >
              <InstagramIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
              <Label variant="caption">Connect Instagram</Label>
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Your connected Instagram Account Page">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.divider}`,
                pl: 2,
                width: theme.spacing(SIZE_INPUT_XXL),
                height: theme.spacing(SIZE_INPUT_M),
                borderRadius: 5,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <InstagramIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
                <Label variant="body1" sx={{ fontWeight: 600 }}>
                  {igPageHandler}
                </Label>
              </Box>
              {!readOnly && (
                <Button
                  disableRipple
                  variant="text"
                  color="primary"
                  onClick={handleEditIGChannel}
                  sx={{
                    margin: 0,
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
    </SaveActionCard>
  );
};
