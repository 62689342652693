import { t } from 'i18next';

export const getGreeting = () => {
  const today = new Date();
  const currHr = today.getHours();

  if (currHr < 12) {
    return t('welcome.home.goodMorning');
  }
  if (currHr < 17) {
    return t('welcome.home.goodAfternoon');
  }
  if (currHr < 21) {
    return t('welcome.home.goodEvening');
  }
  return t('welcome.home.goodNight');
};
