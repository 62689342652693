import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { adminBusinessIdAtom } from '@atoms/app';
import { useAtomValue } from 'jotai';
import { api } from '../../api';
import { MeResponse } from './types';

export const useMeData = <TData = MeResponse>(
  options: Omit<
    UseQueryOptions<MeResponse, unknown, TData, readonly [target: 'me', businessId: string]>,
    'initialData' | 'queryFn' | 'queryKey'
  > = {},
) => {
  const adminBusinessId = useAtomValue(adminBusinessIdAtom);
  const callback = useCallback(
    () =>
      api.profileV1('/v1/users/me', 'get').then((data) => {
        if (data?.entity && adminBusinessId) {
          data.entity.businesses = [
            {
              id: adminBusinessId,
              name: 'temp',
            },
          ];
        }
        return Promise.resolve(data);
      }),
    [adminBusinessId],
  );
  return useQuery(['me', adminBusinessId], callback, options);
};
