import React, { FC, PropsWithChildren } from 'react';
import { Paper, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SIZE_CONTENT_L, SIZE_SPACING_GUTTER_H, SIZE_CONTENT_M } from '../../ui-theme';

export const CustomerProfile: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={2}
      sx={{
        position: 'absolute',
        borderRadius: '10px',
        top: theme.spacing(12),
        right: theme.spacing(5),
        zIndex: 10000,
        width: theme.spacing(SIZE_CONTENT_L),
        height: `calc(100% - ${theme.spacing(12 + 2 * SIZE_SPACING_GUTTER_H)})`,
        [theme.breakpoints.down('md')]: {
          top: theme.spacing(9),
          right: theme.spacing(3),
          height: `calc(100% - ${theme.spacing(9 + SIZE_SPACING_GUTTER_H)})`,
          width: theme.spacing(SIZE_CONTENT_M),
        },
        backgroundColor: theme.palette.skyBlue,
        padding: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        scrollbarGutter: 'stable',
        '&:hover': {
          overflow: 'auto',
        },
      }}
    >
      {children}
    </Paper>
  );
};
