import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { CUSTOMER_DID_INIT } from '../../analytics';
import { CustomerDIContext } from '../../context';
import { setCustomer } from '../../state/action';
import { selectLoadingState, startCustomerMonitor } from '../../state/customerSlice';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import BasicProfile from '../basicprofile/BasicProfile';
import QuickProfile from '../quickprofile/QuickProfile';
import NotesProfile from '../notesprofile/NotesProfile';
import ShopifyProfile from '../shopifyprofile/ShopifyProfile';
export const CustomerComponentId = '0efbbb77-01fe-4e2f-8df1-387ea9de2e3c';
const Customer = ({ logProvider }) => {
    const di = useContext(CustomerDIContext);
    if (di === undefined) {
        logProvider().error('CustomerDIContext is undefined', () => ({
            component: CustomerComponentId,
        }));
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        logProvider().debug(CUSTOMER_DID_INIT, () => ({}));
    }, []);
    useEffect(() => {
        dispatch(startCustomerMonitor());
        const sub = di
            .subscriptionCustomerProvider()(di.config.customerId)
            .subscribe((payload) => {
            dispatch(setCustomer(payload));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.customerId, di.subscriptionCustomerProvider, dispatch, di]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(CustomerComponentId);
    const loadingState = useFeatureSelector(selectLoadingState);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { QuickProfile: QuickProfile, BasicProfile: BasicProfile, ShopifyProfile: ShopifyProfile, NotesProfile: NotesProfile, loadingState: loadingState })) }));
};
export default Customer;
