import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { tryMutationDeleteAudience, tryMutationUpdateAudience, tryQueryAudienceCohort, tryQueryAudiences, } from './thunk';
import { resetStore } from './action';
const initialState = {
    status: 'empty',
    items: {
        ids: [],
        byId: {},
    },
};
function mapStatusToLoadingState(status) {
    switch (status) {
        case 'success':
            return LoadingState.DATA;
        case 'failure':
            return LoadingState.FULL_ERROR;
        case 'loading':
            return LoadingState.FULL_LOADING;
        case 'empty':
            return LoadingState.UNINITIALIZED;
        default:
            return assertUnreachable(status);
    }
}
export const slice = createSlice({
    name: 'audiences',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(tryQueryAudiences.pending, (state, _action) => {
            state.status = 'loading';
        });
        builder.addCase(tryQueryAudiences.fulfilled, (state, action) => {
            state.status = 'success';
            state.items.ids = [];
            state.items.byId = {};
            action.payload.forEach((val) => {
                state.items.ids.push(val.id);
                state.items.byId[val.id] = val;
            });
        });
        builder.addCase(tryQueryAudiences.rejected, (state, _action) => {
            state.status = 'failure';
        });
        builder.addCase(tryMutationDeleteAudience.fulfilled, (state, action) => {
            const { audienceId } = action.meta.arg;
            state.items.ids = state.items.ids.filter((id) => audienceId !== id);
            delete state.items.byId[audienceId];
        });
        builder.addCase(tryMutationUpdateAudience.fulfilled, (state, action) => {
            state.items.byId[action.payload.audience.id] = action.payload.audience;
            state.items.ids = [
                action.payload.audience.id,
                ...state.items.ids.filter((x) => x !== action.payload.audience.id),
            ];
        });
        builder.addCase(tryQueryAudienceCohort.fulfilled, (state, action) => {
            const { audienceId } = action.meta.arg;
            if (audienceId) {
                const audience = state.items.byId[audienceId];
                if (audience) {
                    audience.size = action.payload;
                }
            }
        });
    },
});
export const { reset } = slice.actions;
export const selectAudiences = (state) => state.audiencesSlice.items.ids.map((x) => state.audiencesSlice.items.byId[x]);
export const selectLoadingState = (state) => mapStatusToLoadingState(state.audiencesSlice.status);
export default slice.reducer;
