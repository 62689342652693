import React, { useCallback, useMemo, useRef, useContext, useEffect } from 'react';
import { Box, Button, Label, AddIcon, StackCard } from '@connectlyai-tenets/ui-styled-web';
import remove from 'lodash/remove';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useTranslation } from 'react-i18next';
import {
  selectBusinessId,
  selectQuickReplyTemplates,
  useMeData,
  useSettingsData,
  useMutationCreateQuickReplyTemplate,
} from '@hooks';
import {
  deleteConfirmDialogAtom,
  quickReplyEditStateAtom,
  quickReplyHoverKeyAtom,
  quickReplyStateAtom,
} from '@atoms/settings';
import { useMutationDeleteQuickReplyItem } from '@hooks/useMutationDeleteQuickReplyItem';
import { useQueryClient } from '@tanstack/react-query';
import { useMutationUpdateQuickReplyTemplate } from '@hooks/useMutationUpdateQuickReplyTemplate';
import { QuickReplyDeleteConfirmDialog } from './QuickReplyDeleteConfirmDialog';
import { QuickReplyTemplate } from './QuickReplyTemplate';
import { NotificationContext } from '../../../../contexts';
import { DialogActionResult, QuickReplyState, QuickReplyTemplateView } from './types';

const useQuickReply = () => {
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const inputRef = useRef<HTMLElement | undefined>();
  const [hoverKey, setHoverKey] = useAtom(quickReplyHoverKeyAtom);
  const [editState, setEditState] = useAtom(quickReplyEditStateAtom);
  const [quickReplyState, setQuickReplyState] = useAtom(quickReplyStateAtom);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useAtom(deleteConfirmDialogAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const {
    data: quickReplyTemplates,
    isLoading,
    refetch: refetchSettingsData,
  } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectQuickReplyTemplates,
  });
  const { mutate: mutateUpdateQuickReplyTemplate } = useMutationUpdateQuickReplyTemplate({
    onSuccess() {
      queryClient.invalidateQueries(['settings']);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error updating quick reply template',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const { mutate: mutateDeleteQuickReplyItem, isLoading: isDeletingQuickReplyItem } = useMutationDeleteQuickReplyItem({
    onSuccess() {
      queryClient.invalidateQueries(['settings']);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error deleting quick reply template',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const onDelete = (id: string) => {
    setQuickReplyState((prev: QuickReplyState) => {
      remove(prev.items.ids, (val) => val === id);
      delete prev.items.byId[id];
      return {
        items: {
          ids: [...prev.items.ids],
          byId: {
            ...prev.items.byId,
          },
        },
      };
    });
  };
  const { mutate: mutateCreateQuickReplyTemplate } = useMutationCreateQuickReplyTemplate({
    // optimisticAdd
    onMutate(input) {
      const { id, header, bodyTemplate } = input;
      if (!id) {
        return;
      }
      setQuickReplyState((prev: QuickReplyState) => {
        prev.items.ids.unshift(id);
        prev.items.byId[id] = {
          item: {
            id,
            title: header || '',
            body: bodyTemplate || '',
          },
          state: 'idle',
        };
        return {
          items: {
            ids: [...prev.items.ids],
            byId: {
              ...prev.items.byId,
            },
          },
        };
      });
      setEditState({
        itemId: id,
        propertyKey: 'title',
        input: '',
      });
    },
    onError(err, input) {
      const { id: itemId } = input;
      if (!itemId) {
        return;
      }
      onDelete(itemId);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error adding quick reply template',
          severity: NotificationSeverity.ERROR,
        },
      });
      refetchSettingsData();
    },
  });

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setHoverKey(e.currentTarget.dataset.hoverkey || '');
    },
    [setHoverKey],
  );

  const handleMouseLeave = useCallback(
    (_e: React.MouseEvent<HTMLDivElement>) => {
      setHoverKey('');
    },
    [setHoverKey],
  );

  const handleAddButtonClick = () => {
    const tempId = uuidv4();
    const input = {
      businessId: businessId || '',
      id: tempId,
      header: '',
      bodyTemplate: '',
    };
    mutateCreateQuickReplyTemplate(input);
  };

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteConfirmDialog({
      isOpen: true,
      itemId: e.currentTarget.dataset.itemid || '',
    });
  };
  const onDeleteConfirmDialogClose = (result: DialogActionResult): void => {
    setDeleteConfirmDialog({
      isOpen: false,
      itemId: undefined,
    });
    switch (result) {
      case DialogActionResult.DISMISS:
      case DialogActionResult.SECONDARY:
        break;
      case DialogActionResult.PRIMARY:
        mutateDeleteQuickReplyItem({
          businessId: businessId || '',
          id: deleteConfirmDialog.itemId || '',
        });
        onDelete(deleteConfirmDialog.itemId || '');
        break;
      default:
        break;
    }
  };
  const onClickEmoji = (e: React.MouseEvent<HTMLButtonElement>) => {
    const inputRootEl = e.currentTarget.closest<HTMLElement>('.MuiInputBase-root');
    const inputEl = inputRootEl?.querySelector<HTMLElement>('textarea,input');
    inputRef.current = inputEl || undefined;
    if (inputRootEl) {
      const itemId = inputRootEl.dataset.key || '';
      const propertyKey = inputRootEl.dataset.propertykey || '';
      if (propertyKey === 'title' || propertyKey === 'body') {
        const itemState = quickReplyState.items.byId[itemId];
        setEditState({
          itemId,
          propertyKey,
          input: itemState.item?.[propertyKey] || '',
        });
      }
    }
  };

  const onCloseEmoji = () => {
    setEditState(undefined);
    setHoverKey('');
  };

  const onFocusElement = (inputEl: HTMLElement | null) => {
    const inputRootEl = inputEl?.closest<HTMLElement>('.MuiTextField-root');
    if (inputRootEl) {
      const itemId = inputRootEl.dataset.key || '';
      const propertyKey = inputRootEl.dataset.propertykey || '';
      if (propertyKey === 'title' || propertyKey === 'body') {
        const itemState = quickReplyState.items.byId[itemId];
        setEditState({
          itemId,
          propertyKey,
          input: itemState.item?.[propertyKey] || '',
        });
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectEmoji = ({ native }: any) => {
    const { current } = inputRef;
    if (!current) {
      return;
    }

    if (document.activeElement !== current) {
      current.focus();
      onFocusElement(current);
    }

    document.execCommand('insertText', false, native);
  };

  const handleEditPropertyChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputRootEl = e.currentTarget.closest<HTMLElement>('.MuiTextField-root');
    if (inputRootEl) {
      const itemId = inputRootEl.dataset.key || '';
      const propertyKey = inputRootEl.dataset.propertykey || '';
      if (propertyKey === 'title' || propertyKey === 'body') {
        setEditState({
          itemId,
          propertyKey,
          input: e.currentTarget.value || '',
        });
      }
    }
  };

  const handleEditPropertyFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onFocusElement(e.currentTarget);
  };

  const handleEditPropertyBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputRootEl = e.currentTarget.closest<HTMLElement>('.MuiTextField-root');
    if (inputRootEl) {
      const itemId = inputRootEl.dataset.key || '';
      const propertyKey = editState?.propertyKey;
      const input = editState?.input;
      if (propertyKey && input) {
        const currItem = quickReplyState.items.byId[itemId].item;
        mutateUpdateQuickReplyTemplate({
          businessId: businessId || '',
          'input.entity.id': itemId,
          header: propertyKey === 'title' ? input : currItem.title,
          bodyTemplate: propertyKey === 'body' ? input : currItem.body,
        });
        setQuickReplyState((prev: QuickReplyState) => {
          const itemById = prev.items.byId[itemId];
          itemById.state = 'processing';
          const { item } = itemById;
          item[propertyKey] = input;
          return {
            items: {
              ids: [...prev.items.ids],
              byId: {
                ...prev.items.byId,
              },
            },
          };
        });
      }
      setEditState(undefined);
    }
  };

  // Data Connector
  useEffect(() => {
    const ids = quickReplyTemplates?.map((val) => val.id || '') || [];
    const byId =
      quickReplyTemplates?.reduce<QuickReplyState['items']['byId']>((acc, val) => {
        acc[val.id || ''] = {
          item: {
            id: val.id || '',
            title: val.header || '',
            body: val.bodyTemplate || '',
          },
          state: 'idle',
        };
        return acc;
      }, {}) || {};
    setQuickReplyState({
      items: {
        ids,
        byId,
      },
    });
  }, [quickReplyTemplates, setQuickReplyState]);

  const templates: QuickReplyTemplateView[] = useMemo(() => {
    return quickReplyState.items.ids.map((id: string) => {
      const itemState = quickReplyState.items.byId[id];
      const template = itemState.item;
      return {
        id: template.id || '',
        title: template.title || '',
        body: template.body || '',
        isLoading: itemState.state === 'processing',
      };
    });
  }, [quickReplyState]);

  const { t } = useTranslation('translation', { keyPrefix: 'settings.quickReply' });
  const totalMessagesDisplay = useMemo(() => {
    if (isLoading) {
      return t('loading');
    }
    if (templates.length === 1) {
      return `1 ${t('savedMessage')}`;
    }
    return `${templates.length} ${t('savedMessages')}`;
  }, [templates.length, isLoading, t]);

  return {
    editState,
    isDeletingQuickReplyItem,
    deleteConfirmDialog,
    handleAddButtonClick,
    handleDeleteButtonClick,
    handleEditPropertyBlur,
    handleEditPropertyChange,
    handleEditPropertyFocus,
    handleMouseEnter,
    handleMouseLeave,
    hoverKey,
    totalMessagesDisplay,
    onClickEmoji,
    onCloseEmoji,
    onSelectEmoji,
    onDeleteConfirmDialogClose,
    templates,
  };
};

export const QuickReply = () => {
  const {
    editState,
    isDeletingQuickReplyItem,
    deleteConfirmDialog,
    handleAddButtonClick,
    handleDeleteButtonClick,
    handleEditPropertyBlur,
    handleEditPropertyChange,
    handleEditPropertyFocus,
    handleMouseEnter,
    handleMouseLeave,
    hoverKey,
    totalMessagesDisplay,
    onClickEmoji,
    onCloseEmoji,
    onSelectEmoji,
    onDeleteConfirmDialogClose,
    templates,
  } = useQuickReply();
  return (
    <>
      <QuickReplyDeleteConfirmDialog
        deleteConfirmDialog={deleteConfirmDialog}
        onDeleteConfirmDialogClose={onDeleteConfirmDialogClose}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Label variant="h6" noWrap>
          {totalMessagesDisplay}
        </Label>
        <Button color="primary" startIcon={<AddIcon />} variant="contained" onClick={handleAddButtonClick}>
          Add
        </Button>
      </Box>
      <StackCard
        items={templates.map((val) => ({
          id: val.id,
          node: (
            <QuickReplyTemplate
              key={val.id}
              id={val.id}
              title={val.title}
              body={val.body}
              isLoading={val.isLoading}
              hoverKey={hoverKey}
              editState={editState}
              isDeletingQuickReplyItem={isDeletingQuickReplyItem}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleDeleteButtonClick={handleDeleteButtonClick}
              handleEditPropertyFocus={handleEditPropertyFocus}
              handleEditPropertyBlur={handleEditPropertyBlur}
              handleEditPropertyChange={handleEditPropertyChange}
              onClickEmoji={onClickEmoji}
              onCloseEmoji={onCloseEmoji}
              onSelectEmoji={onSelectEmoji}
            />
          ),
        }))}
      />
    </>
  );
};
