import { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { useEventRequestVerifyReactFlowDocument } from '../useEventRequestVerifyReactFlowDocument';
import { DtoUpdate, RequestVerifyReactFlowDocumentParams } from './types';
import { FlowDIContext } from '../../features/flow/contexts/FlowDIContext';
import {
  selectDocumentVerificationId,
  selectDocumentVerificationResult,
  setDocumentVerificationId,
  setDocumentVerificationResult,
} from '../../state/flow';

export const useRequestVerifyReactFlowDocument = (props?: RequestVerifyReactFlowDocumentParams) => {
  const di = useContext(FlowDIContext);
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  }, [props]);
  const clientRequestId = useSelector(selectDocumentVerificationId);
  const result = useSelector(selectDocumentVerificationResult);
  const dispatch = useDispatch();

  const { mutate } = useEventRequestVerifyReactFlowDocument({
    onError: () => {
      di?.notificationServiceProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Unable to verify. Please try again.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onMutate: (input) => {
      dispatch(setDocumentVerificationResult(undefined));
      dispatch(setDocumentVerificationId(input.clientRequestId));
    },
  });

  useEffect(() => {
    let sub: Subscription;
    if (di && clientRequestId) {
      sub = di
        .dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => {
            return dtoUpdate?.verifyReactFlowDocumentResult !== undefined;
          }),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate?.verifyReactFlowDocumentResult === undefined) {
              return unreachable();
            }
            return dtoUpdate.verifyReactFlowDocumentResult;
          }),
        )
        .subscribe((verifyReactFlowDocumentResult) => {
          if (clientRequestId === verifyReactFlowDocumentResult.clientRequestId) {
            dispatch(setDocumentVerificationResult(verifyReactFlowDocumentResult));
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [di, clientRequestId, dispatch]);

  const isSuccess = useMemo(() => result?.status === 'VERIFICATION_STATUS_OK', [result]);
  const isLoading = useMemo(() => clientRequestId !== undefined && result === undefined, [clientRequestId, result]);
  const isError = useMemo(() => result?.status === 'VERIFICATION_STATUS_ERROR', [result]);

  useEffect(() => {
    if (isSuccess && latestProps.current?.onSuccess) {
      latestProps.current.onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && latestProps.current?.onError) {
      latestProps.current.onError();
    }
  }, [isError]);

  return { mutate, isLoading, isSuccess, isError, data: result };
};
