import { useMutation } from '@tanstack/react-query';
import { api } from 'src/api';

import { GENERATE_CLIENT_KEY, GenerateClientKeyOptions, GenerateClientKeyParams } from './types';

export const useGenerateClientKey = (options?: GenerateClientKeyOptions) => {
  return useMutation(
    [],
    (input: GenerateClientKeyParams) =>
      api.aiV1(GENERATE_CLIENT_KEY, 'post', {
        path: { 'input.businessId': input.businessId },
      }),
    options,
  );
};
