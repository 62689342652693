export const CampaignSendoutRouteId = '/analytics/campaign/:sendoutName';
export const AnalyticsCategoryRouteId = '/analytics/:categoryId';
export const AnalyticsHomeRouteId = '/analytics';
export const AnalyticsAccountOverviewRouteId = '/analytics/account-overview';
export const SendoutReportRoute = '/analytics/sendout';
export const SendoutReportRouteId = `${SendoutReportRoute}/:sendoutId`;
export const ANALYTICS_SUBPAGE_CAMPAIGNS = 'campaign';
export const ANALYTICS_SUBPAGE_SOFIA = 'sofia';
export const ANALYTICS_SUBPAGE_INBOX = 'inbox';
export const ANALYTICS_SUBPAGE_ACCOUNT_OVERVIEW = 'account-overview';
export function generateAnalyticsHomeRoute() {
    return {
        id: AnalyticsHomeRouteId,
        params: {},
    };
}
export function generateAnalyticsCategoryRoute(args) {
    return {
        id: AnalyticsCategoryRouteId,
        params: {
            categoryId: args.categoryId,
        },
    };
}
export function generateCampaignSendoutRoute(sendoutName) {
    return {
        id: CampaignSendoutRouteId,
        params: {
            sendoutName,
        },
    };
}
export function generateAnalyticsAccountOverviewRoute() {
    return {
        id: AnalyticsAccountOverviewRouteId,
        params: {},
    };
}
export function generateSendoutReportRoute(sendoutId) {
    return {
        id: SendoutReportRouteId,
        params: {
            sendoutId,
        },
    };
}
