import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { resetStore } from './action';
import { tryQueryWAMessageTemplates, tryMutationCreateMessageTemplate, tryMutationDeleteMessageTemplate, uploadAttachment, } from './thunk';
const initialState = {
    status: 'empty',
    items: {
        ids: [],
        byId: {},
    },
    attachment: {
        state: 'idle',
        attachmentState: undefined,
    },
};
function mapStatusToLoadingState(status) {
    switch (status) {
        case 'success':
            return LoadingState.DATA;
        case 'failure':
            return LoadingState.FULL_ERROR;
        case 'loading':
            return LoadingState.FULL_LOADING;
        case 'empty':
            return LoadingState.UNINITIALIZED;
        default:
            return assertUnreachable(status);
    }
}
function mutationUpdateAttachmentReadyState(state) {
    var _a;
    state.attachment.state = ((_a = state.attachment.attachmentState) === null || _a === void 0 ? void 0 : _a.state) === 'completed' ? 'idle' : 'processing';
}
export const messageTemplatesSlice = createSlice({
    name: 'message-templates',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(tryQueryWAMessageTemplates.pending, (state, _action) => {
            state.status = 'loading';
        });
        builder.addCase(tryQueryWAMessageTemplates.fulfilled, (state, action) => {
            state.status = 'success';
            state.items.ids = [];
            state.items.byId = {};
            action.payload.forEach((val) => {
                state.items.ids.push(val.id);
                state.items.byId[val.id] = val;
            });
        });
        builder.addCase(tryMutationDeleteMessageTemplate.fulfilled, (state, action) => {
            const { templateGroupId } = action.meta.arg;
            const templateIds = {};
            Object.keys(state.items.byId).forEach((x) => {
                if (state.items.byId[x].name === templateGroupId) {
                    templateIds[x] = x;
                }
            });
            state.items.ids = state.items.ids.filter((templateId) => templateIds[templateId] !== templateId);
            Object.keys(templateIds).forEach((templateId) => {
                delete state.items.byId[templateId];
            });
        });
        builder.addCase(tryMutationCreateMessageTemplate.fulfilled, (state, action) => {
            const { templateId } = action.payload;
            const { template } = action.meta.arg;
            state.items.byId[templateId] = template;
            state.items.ids = [...state.items.ids.filter((x) => x !== templateId), templateId];
        });
        builder.addCase(tryQueryWAMessageTemplates.rejected, (state, _action) => {
            state.status = 'failure';
        });
        builder.addCase(uploadAttachment.pending, (state, action) => {
            const { localId, localFileUrl, name } = action.meta.arg;
            const { attachmentState } = state.attachment;
            if (attachmentState) {
                attachmentState.state = 'uploading';
            }
            else {
                state.attachment.attachmentState = {
                    name,
                    localId,
                    localUrl: localFileUrl,
                    state: 'uploading',
                };
            }
            mutationUpdateAttachmentReadyState(state);
        });
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            const { name } = action.meta.arg;
            const { attachmentState } = state.attachment;
            if (attachmentState) {
                attachmentState.state = 'completed';
                attachmentState.remoteId = action.payload.id;
                attachmentState.remoteUrl = action.payload.uri;
                attachmentState.name = name;
                attachmentState.localUrl = '';
                mutationUpdateAttachmentReadyState(state);
            }
        });
        builder.addCase(uploadAttachment.rejected, (state, _action) => {
            const { attachmentState } = state.attachment;
            if (attachmentState) {
                attachmentState.state = 'error';
                mutationUpdateAttachmentReadyState(state);
            }
        });
    },
});
export const { reset } = messageTemplatesSlice.actions;
export const selectMessageTemplates = (state) => (state.messageTemplatesSlice.items.ids.map((val) => state.messageTemplatesSlice.items.byId[val]));
export const selectLoadingState = (state) => (mapStatusToLoadingState(state.messageTemplatesSlice.status));
export const selectMessageTemplateAttachment = (state) => (state.messageTemplatesSlice.attachment.attachmentState);
export default messageTemplatesSlice.reducer;
