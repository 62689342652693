import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetFlowContent, resetFlowToPreface } from './actions';
import { Edge, FlowDocumentState, Node, FlowCategory } from './types';
import { FlowChange } from '../../hooks/useChangesQueue/types';

const initialState: FlowDocumentState = {
  category: 'new-campaign',
  changesQueue: [],
  edges: [],
  hasUnsavedChanges: false,
  language: 'en',
  movingNodeIds: [],
  nodes: [],
  onlineIds: [],
  revisionId: null,
};

// This state contains the "campaign flow state"
export const flowDocumentSlice = createSlice({
  name: 'flow-document',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<FlowCategory>) => {
      state.category = action.payload;
    },
    setChangesQueue: (state, action: PayloadAction<FlowChange[]>) => {
      state.changesQueue = action.payload;
    },
    setEdges: (state, action: PayloadAction<Edge[]>) => {
      state.edges = action.payload;
    },
    setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setMovingNodeIds: (state, action: PayloadAction<string[]>) => {
      state.movingNodeIds = action.payload;
    },
    setNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload;
    },
    setOnlineIds: (state, action: PayloadAction<string[]>) => {
      state.onlineIds = action.payload;
    },
    setRevisionId: (state, action: PayloadAction<string>) => {
      if (action.payload === '' || action.payload <= (state.revisionId || '')) {
        return;
      }
      state.revisionId = action.payload;
    },
    updateNodeWithId: (state, { payload: { id, newNode } }: PayloadAction<{ id: string; newNode: Node }>) => {
      state.nodes = state.nodes.map((node) => (node.id === id ? newNode : node));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFlowContent, (state, _action) => {
      state.changesQueue = [];
      state.edges = [];
      state.nodes = [];
      state.movingNodeIds = [];
      state.onlineIds = [];
      state.revisionId = null;
    });
    builder.addCase(resetFlowToPreface, (state, _action) => {
      state.changesQueue = [];
      state.edges = [];
      state.nodes = [];
      state.hasUnsavedChanges = false;
      state.movingNodeIds = [];
      state.onlineIds = [];
      state.revisionId = null;
    });
  },
});

export const {
  setCategory,
  setChangesQueue,
  setEdges,
  setHasUnsavedChanges,
  setLanguage,
  setMovingNodeIds,
  setNodes,
  setOnlineIds,
  setRevisionId,
  updateNodeWithId,
} = flowDocumentSlice.actions;

export default flowDocumentSlice.reducer;
