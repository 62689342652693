import React from 'react';
import { HeartBrokenOutlinedIcon, PaymentsOutlinedIcon, ThumbUpIcon, Theme } from '@connectlyai-tenets/ui-styled-web';
import { QueryTemplateType } from './types';

export const TEMPLATES: QueryTemplateType[] = ['engaged', 'winback', 'highValue'];
export const TEMPLATE_INFO: Record<
  QueryTemplateType,
  { icon: React.ReactNode; backgroundColor: string; highlightColor: string; comingSoon?: boolean; vtex?: boolean }
> = {
  engaged: {
    icon: (
      <ThumbUpIcon
        sx={{
          width: (theme: Theme) => theme.spacing(2),
          height: (theme: Theme) => theme.spacing(2),
          color: '#007DFF',
        }}
      />
    ),
    backgroundColor: 'rgba(0, 125, 255, 0.1)',
    highlightColor: '#F6F8FF',
  },
  winback: {
    icon: (
      <HeartBrokenOutlinedIcon
        sx={{ width: (theme: Theme) => theme.spacing(2), height: (theme: Theme) => theme.spacing(2) }}
      />
    ),
    backgroundColor: 'rgba(0, 255, 71, 0.2)',
    highlightColor: '#FFFFFF',
    comingSoon: true,
    vtex: true,
  },
  highValue: {
    icon: (
      <PaymentsOutlinedIcon
        sx={{ width: (theme: Theme) => theme.spacing(2), height: (theme: Theme) => theme.spacing(2) }}
      />
    ),
    backgroundColor: 'rgba(255, 46, 0, 0.1)',
    highlightColor: '#FFFFFF',
    comingSoon: true,
    vtex: true,
  },
};
