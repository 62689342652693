import { ChangeFlowDocumentResponse } from '@hooks/useChangeFlowDocumentMutation';
import { FlowChange, FlowChangeType } from './types';

export const mapFlowChangeToRequest = (type: FlowChangeType, change: Partial<FlowChange>) => {
  switch (type) {
    case 'node':
      return { nodeChange: { options: JSON.stringify(change?.node) } };
    case 'edge':
      return { edgeChange: { options: JSON.stringify(change?.edge) } };
    case 'nodeObjectUpdate':
      return {
        nodeObjectUpdate: {
          node: {
            id: change?.nodeObjectUpdate?.id,
            options: JSON.stringify(change?.nodeObjectUpdate?.item),
          },
        },
      };
    default:
      return undefined;
  }
};

export const mapFlowChangeToType = (flowChange: Partial<FlowChange>): FlowChangeType | undefined => {
  if (flowChange?.node) {
    return 'node';
  }
  if (flowChange?.edge) {
    return 'edge';
  }
  if (flowChange?.nodeObjectUpdate) {
    return 'nodeObjectUpdate';
  }
  return undefined;
};

export const mapFlowChangeToRequiredIds = (change: FlowChange) => {
  if (change.node) {
    if (change.node.type === 'position' || change.node.type === 'remove') {
      return [change.node?.id];
    }
  }
  if (change.edge) {
    if (change.edge.type === 'add') {
      return [change.edge.item.source, change.edge.item.target];
    }
    if (change.edge.type === 'remove') {
      return [change.edge.id];
    }
  }
  if (change.nodeObjectUpdate) {
    return [change.nodeObjectUpdate?.id];
  }
  return [];
};

export const mapChangeFlowDocumentResponseToNewId = (response: ChangeFlowDocumentResponse) => {
  if (response.entity?.nodeChange?.options) {
    const parsed = JSON.parse(response.entity?.nodeChange?.options);
    return parsed?.item?.id;
  }
  if (response.entity?.edgeChange?.options) {
    const parsed = JSON.parse(response.entity?.edgeChange?.options);
    return parsed?.item?.id;
  }
  return undefined;
};

export const mapFlowChangeToAddOperation = (change: FlowChange) => {
  if (change.node && change.node?.type === 'add' && change.node.item.id) {
    return { type: 'node', id: change.node.item.id };
  }
  if (change.edge && change.edge?.type === 'add' && change.edge.item.id) {
    return { type: 'edge', id: change.edge.item.id };
  }
  return undefined;
};
