import React, { FC, useCallback } from 'react';
import { Box, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useDispatch, useSelector } from 'react-redux';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { v4 as uuidv4 } from 'uuid';
import { useSetAtom } from 'jotai';
import { documentTypeAtom } from '@atoms/flow';
import { track } from 'src/utils';
import {
  FlowChartGetStartedCampaignCard,
  FlowChartGetStartedChooseCampaign,
  FlowChartGetStartedCustomCampaignCard,
  FlowChartGetStartedSidebar,
  FlowChartHeader,
  FlowChartSidebarPlaceholder,
} from '../../components';
import {
  goToNextFlowState,
  resetFlowToPreface,
  setCampaignTemplate,
  setCategory,
  setDocumentId,
  setIsAISidebarOpen,
  setStarter,
  selectCategory,
} from '../../state/flow';
import { CampaignTemplateType } from '../../hooks/useAICampaign/types';
import { resetFlowChart } from '../../state/actions';

const useFlowChartGetStarted = () => {
  const setDocumentType = useSetAtom(documentTypeAtom);

  const category = useSelector(selectCategory);
  const dispatch = useDispatch();

  const handleChooseCampaignTemplate = useCallback(
    (campaignTemplate: CampaignTemplateType) => {
      track('clicks choose_campaign_template', { templateName: campaignTemplate });

      setDocumentType('FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1');
      dispatch(resetFlowChart());
      dispatch(resetFlowToPreface());
      dispatch(setStarter('template'));
      dispatch(setDocumentId(uuidv4()));
      dispatch(setIsAISidebarOpen(true));
      dispatch(setCampaignTemplate(campaignTemplate));
      dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
    },
    [dispatch, setDocumentType],
  );

  const handleChooseEmptyCampaign = useCallback(() => {
    track('clicks choose_campaign_template', { templateName: 'empty' });

    setDocumentType('FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1');
    dispatch(resetFlowChart());
    dispatch(resetFlowToPreface());
    dispatch(setStarter('empty'));
    dispatch(setDocumentId(uuidv4()));
    dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
  }, [dispatch, setDocumentType]);

  const handleChooseNewCampaign = useCallback(() => {
    dispatch(setCategory('new-campaign'));
  }, [dispatch]);
  const handleChooseResendCampaign = useCallback(() => {
    dispatch(setCategory('resend-campaign'));
  }, [dispatch]);
  const handleChooseAutomation = useCallback(() => {
    dispatch(setCategory('automation'));
  }, [dispatch]);

  return {
    category,
    handleChooseCampaignTemplate,
    handleChooseEmptyCampaign,
    handleChooseNewCampaign,
    handleChooseResendCampaign,
    handleChooseAutomation,
  };
};

export const FlowChartGetStarted: FC = () => {
  const {
    category,
    handleChooseCampaignTemplate,
    handleChooseEmptyCampaign,
    handleChooseNewCampaign,
    handleChooseResendCampaign,
    handleChooseAutomation,
  } = useFlowChartGetStarted();
  const theme = useTheme();
  const ffEnableWebpageToProduct = getFlagByName('ffEnableWebpageToProduct');
  return (
    <>
      <FlowChartHeader>
        <Label variant="subtitle2">Get Started</Label>
      </FlowChartHeader>
      <Box
        sx={{
          background: theme.palette.skyBlue,
          display: 'flex',
          height: '100%',
        }}
      >
        <FlowChartSidebarPlaceholder>
          <FlowChartGetStartedSidebar
            selectedCategory={category}
            onCampaignSelected={handleChooseNewCampaign}
            onResendCampaignSelected={handleChooseResendCampaign}
            onAutomationSelected={handleChooseAutomation}
          />
        </FlowChartSidebarPlaceholder>
        {category === 'new-campaign' && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              maxHeight: `calc(100vh - ${theme.spacing(22)})`,
              overflowY: 'scroll',
              px: 8,
              py: 4.5,
              width: '100%',
            }}
          >
            <Box
              sx={{
                alignContent: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 3,
                maxWidth: theme.spacing(125),
                width: '100%',
              }}
            >
              <FlowChartGetStartedCustomCampaignCard handleChooseEmptyCampaign={handleChooseEmptyCampaign} />
              {ffEnableWebpageToProduct && (
                <FlowChartGetStartedCampaignCard
                  // eslint-disable-next-line max-len
                  exampleText="👋 Hi Joe, get ready to look stylish with Sunny's Glasses! Our glasses are perfect for any occasion..."
                  image="/assets/campaign-templates/product-promotion.png"
                  subtitle="Create a campaign only using your product webpage"
                  tags={['Marketing']}
                  title="Webpage to Product"
                  titleAlt="Página web al producto"
                  handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('webpageToProduct')}
                />
              )}
              <FlowChartGetStartedCampaignCard
                // eslint-disable-next-line max-len
                exampleText="👋 Hi Joe, get ready to look stylish with Sunny's Glasses! Our glasses are perfect for any occasion..."
                image="/assets/campaign-templates/product-promotion.png"
                subtitle="Create a two-way marketing campaign to promote your products or services."
                tags={['Marketing']}
                title="Product Promotion"
                titleAlt="Promoción  |  Promoção"
                handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('productPromotion')}
              />
              <FlowChartGetStartedCampaignCard
                // eslint-disable-next-line max-len
                exampleText="🥳 Hi Mark, check out Sunny's Glasses for our Summer Sales! Get up to 20% off on sunglasses..."
                image="/assets/campaign-templates/sales-event.png"
                subtitle="Encourage your customers to participate in an upcoming sales event."
                tags={['Marketing']}
                title="Sales event"
                titleAlt="Evento de ventas  |  Evento de vendas"
                handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('salesEvent')}
              />
              <FlowChartGetStartedCampaignCard
                // eslint-disable-next-line max-len
                exampleText="Hello Sarah! 👋 This is the official channel for Sunny's Glasses. We'll be sending exclusive deals..."
                image="/assets/campaign-templates/welcome-series.png"
                subtitle="Welcome your new customers and ask them if they want to opt in for future marketing messages."
                tags={['General']}
                title="Welcome Series"
                titleAlt="Serie de Bienvenida  |  Série de boas-vindas"
                handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('welcomeSeries')}
              />
              <FlowChartGetStartedCampaignCard
                // eslint-disable-next-line max-len
                exampleText="🧠 Test your knowledge and get a 15% discount code for Sunny's Glasses! Answer 3 quiz questions..."
                image="/assets/campaign-templates/promotion-with-quiz.png"
                subtitle="Reward your customers with a discount code if they can pass your quiz."
                tags={['Marketing']}
                title="Promotion With Quiz"
                titleAlt="Cuestionario de promoción  |  Questionário de promoções"
                handleChooseCampaignTemplate={() => handleChooseCampaignTemplate('promotionWithQuiz')}
              />
            </Box>
          </Box>
        )}
        {category === 'resend-campaign' && (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <FlowChartGetStartedChooseCampaign />
          </Box>
        )}
      </Box>
    </>
  );
};
