import React from 'react';
import { ComponentId, Registry } from '@connectlyai-tenets/ui-registry';
import {
  BroadcastComponentId,
  BroadcastAudienceBuilderComponentId,
  BroadcastAudiencesComponentId,
  BroadcastMessageTemplatesComponentId,
  BroadcastMessageTemplatesNewComponentId,
  BroadcastSidebarComponentId,
} from '@connectlyai-features/broadcast';
import {
  ActionBarComponentId as ChatRoomActionBarComponentId,
  ChatMessageComponentId,
  ChatRoomComponentId,
  ChatThreadComponentId,
  ComposerComponentId,
  ComposerTemplatePopoverComponentId,
} from '@connectlyai-features/chatroom';
import {
  BasicProfileComponentId,
  CustomerComponentId,
  NotesProfileComponentId,
  QuickProfileComponentId,
  ShopifyProfileComponentId,
} from '@connectlyai-features/customer';
import { NavbarComponentId } from '@connectlyai-features/navbar';
import {
  ActionbarComponentId as SidebarActionBarComponentId,
  OnboardingRoomCardComponentId,
  RoomCardComponentId,
  SearchComponentId,
  SearchResultComponentId,
  SidebarComponentId,
} from '@connectlyai-features/sidebar';
import { UnauthorizedComponentId, WelcomeComponentId } from '@connectlyai-features/welcome';
import { SignUpComponentId } from '@connectlyai-features/signup';

// broadcast
const BroadcastAudiencesWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastAudiencesWebView'
    ),
);
const BroadcastAudienceBuilderWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastAudienceBuilderWebView'
    ),
);
const BroadcastMessageTemplatesNewWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastMessageTemplatesNewWebView'
    ),
);
const BroadcastMessageTemplatesWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastMessageTemplatesWebView'
    ),
);
const BroadcastSidebarWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastSidebarWebView'
    ),
);
const BroadcastWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "broadcast" */
      '../presentation/BroadcastWebView'
    ),
);

// chatroom
const ChatMessageWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ChatMessageWebView'
    ),
);
const ChatRoomActionBarWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ChatRoomActionBarWebView'
    ),
);
const ChatRoomWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ChatRoomWebView'
    ),
);
const ChatThreadWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ChatThreadWebView'
    ),
);
const ComposerTemplatePopoverWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ComposerTemplatePopoverWebView'
    ),
);
const ComposerWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/ComposerWebView'
    ),
);
const CustomerBasicProfileWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/CustomerBasicProfileWebView'
    ),
);
const CustomerQuickProfileWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/CustomerQuickProfileWebView'
    ),
);
const CustomerNotesProfileWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/CustomerNotesProfileWebView'
    ),
);
const CustomerShopifyProfileWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/CustomerShopifyProfileWebView'
    ),
);
const CustomerWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/CustomerWebView'
    ),
);
const NavbarWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/NavbarWebView'
    ),
);
const SearchResultWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SearchResultWebView'
    ),
);
const SearchWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SearchWebView'
    ),
);
const SidebarActionBarWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SidebarActionBarWebView'
    ),
);
const SidebarConnectlyRoomCardWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SidebarConnectlyRoomCardWebView'
    ),
);
const SidebarRoomCardWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SidebarRoomCardWebView'
    ),
);
const SidebarWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "chatroom" */
      '../presentation/SidebarWebView'
    ),
);

// signup
const SignUpWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "signup" */
      '../presentation/SignUpWebView'
    ),
);

// welcome
const UnauthorizedWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "welcome" */
      '../presentation/UnauthorizedWebView'
    ),
);
const WelcomeWebView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "welcome" */
      '../components/Home/Home'
    ),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const registryMap = new Map<ComponentId, React.FC<any>>();
registryMap.set(BroadcastComponentId, BroadcastWebView);
registryMap.set(BroadcastAudienceBuilderComponentId, BroadcastAudienceBuilderWebView);
registryMap.set(BroadcastAudiencesComponentId, BroadcastAudiencesWebView);
registryMap.set(BroadcastMessageTemplatesComponentId, BroadcastMessageTemplatesWebView);
registryMap.set(BroadcastMessageTemplatesNewComponentId, BroadcastMessageTemplatesNewWebView);
registryMap.set(BroadcastSidebarComponentId, BroadcastSidebarWebView);
registryMap.set(ChatMessageComponentId, ChatMessageWebView);
registryMap.set(ChatRoomActionBarComponentId, ChatRoomActionBarWebView);
registryMap.set(ChatRoomComponentId, ChatRoomWebView);
registryMap.set(ChatThreadComponentId, ChatThreadWebView);
registryMap.set(ComposerComponentId, ComposerWebView);
registryMap.set(ComposerTemplatePopoverComponentId, ComposerTemplatePopoverWebView);
registryMap.set(CustomerComponentId, CustomerWebView);
registryMap.set(BasicProfileComponentId, CustomerBasicProfileWebView);
registryMap.set(QuickProfileComponentId, CustomerQuickProfileWebView);
registryMap.set(NavbarComponentId, NavbarWebView);
registryMap.set(NotesProfileComponentId, CustomerNotesProfileWebView);
registryMap.set(WelcomeComponentId, WelcomeWebView);
registryMap.set(UnauthorizedComponentId, UnauthorizedWebView);
registryMap.set(ShopifyProfileComponentId, CustomerShopifyProfileWebView);
registryMap.set(SearchComponentId, SearchWebView);
registryMap.set(SearchResultComponentId, SearchResultWebView);
registryMap.set(SidebarActionBarComponentId, SidebarActionBarWebView);
registryMap.set(SidebarComponentId, SidebarWebView);
registryMap.set(RoomCardComponentId, SidebarRoomCardWebView);
registryMap.set(OnboardingRoomCardComponentId, SidebarConnectlyRoomCardWebView);
registryMap.set(SignUpComponentId, SignUpWebView);

const registry = new Registry(registryMap);

export default registry;
