import React, { useContext, useEffect, useMemo } from 'react';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { useEventRequestCompileReactFlowDocument } from '../useEventRequestCompileReactFlowDocument';
import { DtoUpdate, CompileReactFlowDocumentResult } from './types';
import { FlowDIContext } from '../../features/flow/contexts/FlowDIContext';
import { COMPILE_TIMEOUT_MS } from './constants';

export const useRequestCompileReactFlowDocument = () => {
  const di = useContext(FlowDIContext);
  const [clientRequestId, setClientRequestId] = React.useState<string | undefined>(undefined);
  const [result, setResult] = React.useState<CompileReactFlowDocumentResult | null>(null);
  const [isTimedout, setIsTimedout] = React.useState<boolean>(false);
  const timer = React.useRef<NodeJS.Timeout | null>(null);

  const { mutate } = useEventRequestCompileReactFlowDocument({
    onError: () => {
      di?.notificationServiceProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Unable to compile. Please try again.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onMutate: (input) => {
      setResult(null);
      setClientRequestId(input.clientRequestId);
      setIsTimedout(false);
      timer.current = setTimeout(() => {
        setIsTimedout(true);
        setClientRequestId(undefined);
        di?.notificationServiceProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'Compilation timed out. Please try again.',
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      }, COMPILE_TIMEOUT_MS);
    },
    retry: 0,
  });

  useEffect(() => {
    let sub: Subscription;
    if (di && clientRequestId) {
      sub = di
        .dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => {
            return dtoUpdate?.compileReactFlowDocumentResult !== undefined;
          }),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate?.compileReactFlowDocumentResult === undefined) {
              return unreachable();
            }
            return dtoUpdate.compileReactFlowDocumentResult;
          }),
        )
        .subscribe((compileReactFlowDocumentResult) => {
          if (clientRequestId === compileReactFlowDocumentResult.clientRequestId) {
            setResult(compileReactFlowDocumentResult);
            if (timer.current) clearTimeout(timer.current);
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [di, clientRequestId]);

  const isSuccess = useMemo(() => result?.status === 'MUTATION_STATUS_SUCCESS', [result]);
  const isLoading = useMemo(() => clientRequestId !== undefined && result === null, [clientRequestId, result]);
  const isError = useMemo(() => result?.status === 'MUTATION_STATUS_FAILURE', [result]);

  return { mutate, isLoading, isSuccess, isError, data: result, isTimedout };
};
