import { unwrapResult } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { isIncomingMessage } from '@connectlyai-idl/models/dist/utils';
import { DataOrigin, DataResponse, compareDataResponse } from '@connectlyai-tenets/data';
import memoize from 'lodash/memoize';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import storeProvider from './state/store';
import { buildDIContainer } from './di';
import { newEventArrival, roomDtoUpdate, setAgentRoom, setFilter, setRoomStatus, } from './state/roomsSlice';
import { fetchRooms, fetchRoom } from './state/fetchThunk';
function maybeEmitState(state) {
    if (state.status === 'succeeded' || state.status === 'newevent') {
        const { rooms, activeRoomsById } = state;
        return DataResponse.data({ rooms, activeRoomsById }, DataOrigin.SourceOfTruth);
    }
    return null;
}
export default class RealRoomsDataStore {
    constructor(deps) {
        this.di = buildDIContainer(deps);
        this.allRooms = new Subject();
        this.allMessages = new Subject();
        this.uniqueMessages = this.allMessages.pipe(distinctUntilChanged());
        this.store = storeProvider();
        this.store.subscribe(() => {
            const newState = this.store.getState();
            const maybeEmission = maybeEmitState(newState.rooms);
            if (maybeEmission) {
                this.allRooms.next(maybeEmission);
            }
            this.allMessages.next(newState.rooms.messages);
        });
        this.subscribeRealtimeRoomUpdates = memoize(() => {
            const { di } = this;
            di.subscriptionDtoUpdateRoomProvider()().subscribe((payload) => {
                this.store.dispatch(roomDtoUpdate(payload));
            });
            di.subscriptionRoomDtoProvider()().subscribe((payload) => {
                var _a, _b;
                if (isIncomingMessage(payload)) {
                    di.notificationServiceProvider().notify({
                        surface: NotificationSurface.TAB,
                        notification: {
                            message: 'MESSAGE_INCOMING',
                            severity: NotificationSeverity.INFO,
                            icon: '',
                        },
                    });
                }
                this.store.dispatch(newEventArrival(payload));
                const newArrival = payload;
                if (newArrival.events.length === 1) {
                    let roomId;
                    switch ((_b = (_a = newArrival.events[0].connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) {
                        case 'message':
                            roomId = newArrival.roomId;
                            this.store.dispatch(fetchRoom({
                                di,
                                roomId,
                            }));
                            break;
                        case 'roomAssignment':
                            roomId = newArrival.events[0].connectlyEvent.eventOneof.roomAssignment.roomId;
                            this.store.dispatch(fetchRoom({
                                di,
                                roomId,
                            }));
                            break;
                        case 'roomStatusChange':
                            roomId = newArrival.events[0].connectlyEvent.eventOneof.roomStatusChange.roomId;
                            this.store.dispatch(fetchRoom({
                                di,
                                roomId,
                            }));
                            break;
                        default:
                            break;
                    }
                }
            });
        });
    }
    eventRefreshRooms(str) {
        this.store.dispatch(setFilter(str));
        this.store.dispatch(fetchRooms({
            di: this.di,
        }));
        return Promise.resolve();
    }
    eventRequestNextRoomsPage() {
        const { rooms } = this.store.getState();
        if (rooms.pageStatus === 'loading' || rooms.pageStatus === 'completed') {
            return Promise.resolve();
        }
        this.store.dispatch(fetchRooms({
            di: this.di,
        }));
        return Promise.resolve();
    }
    async eventRefreshRoom(roomId) {
        return this.store
            .dispatch(fetchRoom({
            di: this.di,
            roomId,
        }))
            .then(unwrapResult)
            .then();
    }
    mutationAssignRoomAgent(input) {
        return this.di
            .mutationAssignRoomAgentProvider()(input)
            .then(() => {
            this.store.dispatch(setAgentRoom(input));
        });
    }
    mutationCreateEvent(input) {
        const { businessId, roomId } = input;
        return this.di.mutationCreateEvent()(businessId, roomId, input);
    }
    composeTemplatedMessage(input) {
        const { channelSelector } = input;
        if (!(channelSelector === null || channelSelector === void 0 ? void 0 : channelSelector.businessId)) {
            return Promise.reject();
        }
        return this.di.composeTemplatedMessage()(channelSelector === null || channelSelector === void 0 ? void 0 : channelSelector.businessId, input);
    }
    mutationUpdateMessageReadWatermark(input) {
        return this.di.mutationUpdateMessageReadWatermark()(input);
    }
    subscribeMessage(eventId) {
        const currentState = this.store.getState();
        const currentEvent = currentState.rooms.messages.byId[eventId];
        const currentData = currentEvent ? DataResponse.data(currentEvent) : DataResponse.loading();
        return this.uniqueMessages.pipe(map((messages) => {
            const event = messages.byId[eventId];
            const data = event ? DataResponse.data(event) : DataResponse.loading();
            return data;
        }), startWith(currentData), distinctUntilChanged(compareDataResponse));
    }
    subscribeRoom(roomId) {
        this.subscribeRealtimeRoomUpdates();
        if (this.di.config.businessId !== '') {
            this.store.dispatch(fetchRoom({ di: this.di, roomId }));
        }
        const currentState = this.store.getState().rooms;
        const maybeEmission = maybeEmitState(currentState);
        const startValue = maybeEmission || DataResponse.loading();
        return this.allRooms.pipe(startWith(startValue), map((rooms) => { var _a; return (_a = rooms.getOrNull()) === null || _a === void 0 ? void 0 : _a.rooms.roomsById[roomId]; }), filter((val) => val !== undefined), map((val) => DataResponse.data(val)), distinctUntilChanged(compareDataResponse));
    }
    subscribeRooms() {
        this.subscribeRealtimeRoomUpdates();
        if (this.di.config.businessId !== '') {
            this.store.dispatch(fetchRooms({
                di: this.di,
            }));
        }
        const currentState = this.store.getState().rooms;
        const maybeEmission = maybeEmitState(currentState);
        const startValue = maybeEmission || DataResponse.loading();
        return this.allRooms.pipe(startWith(startValue));
    }
    async mutationChangeRoomStatus(input) {
        return this.di
            .mutationChangeRoomStatusProvider()(input)
            .then(() => {
            this.store.dispatch(setRoomStatus(input));
        });
    }
}
