import { channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.widget";
function createBaseContactChannel() {
    return { channel: undefined };
}
export const ContactChannel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channel !== undefined) {
            ContactChannel_Identifier.encode(message.channel, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContactChannel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channel = ContactChannel_Identifier.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channel: isSet(object.channel)
                ? ContactChannel_Identifier.fromJSON(object.channel)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channel !== undefined &&
            (obj.channel = message.channel
                ? ContactChannel_Identifier.toJSON(message.channel)
                : undefined);
        return obj;
    },
};
function createBaseContactChannel_Identifier() {
    return { externalId: "", channelType: 0, platformIdentifier: "" };
}
export const ContactChannel_Identifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.externalId !== "") {
            writer.uint32(10).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        if (message.platformIdentifier !== "") {
            writer.uint32(26).string(message.platformIdentifier);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContactChannel_Identifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.externalId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                case 3:
                    message.platformIdentifier = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            platformIdentifier: isSet(object.platformIdentifier)
                ? String(object.platformIdentifier)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.platformIdentifier !== undefined &&
            (obj.platformIdentifier = message.platformIdentifier);
        return obj;
    },
};
function createBaseWidgetDto() {
    return {
        id: "",
        name: "",
        contactChannels: [],
        configs: undefined,
        campaigns: undefined,
    };
}
export const WidgetDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        for (const v of message.contactChannels) {
            ContactChannel.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.configs !== undefined) {
            ConfigsDto.encode(message.configs, writer.uint32(34).fork()).ldelim();
        }
        if (message.campaigns !== undefined) {
            CampaignsDto.encode(message.campaigns, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWidgetDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.contactChannels.push(ContactChannel.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.configs = ConfigsDto.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.campaigns = CampaignsDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            contactChannels: Array.isArray(object === null || object === void 0 ? void 0 : object.contactChannels)
                ? object.contactChannels.map((e) => ContactChannel.fromJSON(e))
                : [],
            configs: isSet(object.configs)
                ? ConfigsDto.fromJSON(object.configs)
                : undefined,
            campaigns: isSet(object.campaigns)
                ? CampaignsDto.fromJSON(object.campaigns)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        if (message.contactChannels) {
            obj.contactChannels = message.contactChannels.map((e) => e ? ContactChannel.toJSON(e) : undefined);
        }
        else {
            obj.contactChannels = [];
        }
        message.configs !== undefined &&
            (obj.configs = message.configs
                ? ConfigsDto.toJSON(message.configs)
                : undefined);
        message.campaigns !== undefined &&
            (obj.campaigns = message.campaigns
                ? CampaignsDto.toJSON(message.campaigns)
                : undefined);
        return obj;
    },
};
function createBaseConfigsDto() {
    return {
        defaultMobile: undefined,
        defaultDesktop: undefined,
        homeMobile: undefined,
        homeDesktop: undefined,
        productMobile: undefined,
        productDesktop: undefined,
    };
}
export const ConfigsDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.defaultMobile !== undefined) {
            ConfigDto.encode(message.defaultMobile, writer.uint32(10).fork()).ldelim();
        }
        if (message.defaultDesktop !== undefined) {
            ConfigDto.encode(message.defaultDesktop, writer.uint32(18).fork()).ldelim();
        }
        if (message.homeMobile !== undefined) {
            ConfigDto.encode(message.homeMobile, writer.uint32(26).fork()).ldelim();
        }
        if (message.homeDesktop !== undefined) {
            ConfigDto.encode(message.homeDesktop, writer.uint32(34).fork()).ldelim();
        }
        if (message.productMobile !== undefined) {
            ConfigDto.encode(message.productMobile, writer.uint32(42).fork()).ldelim();
        }
        if (message.productDesktop !== undefined) {
            ConfigDto.encode(message.productDesktop, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigsDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.defaultMobile = ConfigDto.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.defaultDesktop = ConfigDto.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.homeMobile = ConfigDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.homeDesktop = ConfigDto.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.productMobile = ConfigDto.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.productDesktop = ConfigDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            defaultMobile: isSet(object.defaultMobile)
                ? ConfigDto.fromJSON(object.defaultMobile)
                : undefined,
            defaultDesktop: isSet(object.defaultDesktop)
                ? ConfigDto.fromJSON(object.defaultDesktop)
                : undefined,
            homeMobile: isSet(object.homeMobile)
                ? ConfigDto.fromJSON(object.homeMobile)
                : undefined,
            homeDesktop: isSet(object.homeDesktop)
                ? ConfigDto.fromJSON(object.homeDesktop)
                : undefined,
            productMobile: isSet(object.productMobile)
                ? ConfigDto.fromJSON(object.productMobile)
                : undefined,
            productDesktop: isSet(object.productDesktop)
                ? ConfigDto.fromJSON(object.productDesktop)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.defaultMobile !== undefined &&
            (obj.defaultMobile = message.defaultMobile
                ? ConfigDto.toJSON(message.defaultMobile)
                : undefined);
        message.defaultDesktop !== undefined &&
            (obj.defaultDesktop = message.defaultDesktop
                ? ConfigDto.toJSON(message.defaultDesktop)
                : undefined);
        message.homeMobile !== undefined &&
            (obj.homeMobile = message.homeMobile
                ? ConfigDto.toJSON(message.homeMobile)
                : undefined);
        message.homeDesktop !== undefined &&
            (obj.homeDesktop = message.homeDesktop
                ? ConfigDto.toJSON(message.homeDesktop)
                : undefined);
        message.productMobile !== undefined &&
            (obj.productMobile = message.productMobile
                ? ConfigDto.toJSON(message.productMobile)
                : undefined);
        message.productDesktop !== undefined &&
            (obj.productDesktop = message.productDesktop
                ? ConfigDto.toJSON(message.productDesktop)
                : undefined);
        return obj;
    },
};
function createBaseConfigDto() {
    return {
        expanded: undefined,
        positionOneof: undefined,
        initialMessageBody: undefined,
    };
}
export const ConfigDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.expanded !== undefined) {
            ExpandedConfig.encode(message.expanded, writer.uint32(10).fork()).ldelim();
        }
        if (((_a = message.positionOneof) === null || _a === void 0 ? void 0 : _a.$case) === "fixed") {
            ConfigDto_PositionFixed.encode(message.positionOneof.fixed, writer.uint32(18).fork()).ldelim();
        }
        if (((_b = message.positionOneof) === null || _b === void 0 ? void 0 : _b.$case) === "relative") {
            ConfigDto_PositionRelative.encode(message.positionOneof.relative, writer.uint32(26).fork()).ldelim();
        }
        if (message.initialMessageBody !== undefined) {
            StringValue.encode({ value: message.initialMessageBody }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.expanded = ExpandedConfig.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.positionOneof = {
                        $case: "fixed",
                        fixed: ConfigDto_PositionFixed.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.positionOneof = {
                        $case: "relative",
                        relative: ConfigDto_PositionRelative.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.initialMessageBody = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            expanded: isSet(object.expanded)
                ? ExpandedConfig.fromJSON(object.expanded)
                : undefined,
            positionOneof: isSet(object.fixed)
                ? {
                    $case: "fixed",
                    fixed: ConfigDto_PositionFixed.fromJSON(object.fixed),
                }
                : isSet(object.relative)
                    ? {
                        $case: "relative",
                        relative: ConfigDto_PositionRelative.fromJSON(object.relative),
                    }
                    : undefined,
            initialMessageBody: isSet(object.initialMessageBody)
                ? String(object.initialMessageBody)
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        message.expanded !== undefined &&
            (obj.expanded = message.expanded
                ? ExpandedConfig.toJSON(message.expanded)
                : undefined);
        ((_a = message.positionOneof) === null || _a === void 0 ? void 0 : _a.$case) === "fixed" &&
            (obj.fixed = ((_b = message.positionOneof) === null || _b === void 0 ? void 0 : _b.fixed)
                ? ConfigDto_PositionFixed.toJSON((_c = message.positionOneof) === null || _c === void 0 ? void 0 : _c.fixed)
                : undefined);
        ((_d = message.positionOneof) === null || _d === void 0 ? void 0 : _d.$case) === "relative" &&
            (obj.relative = ((_e = message.positionOneof) === null || _e === void 0 ? void 0 : _e.relative)
                ? ConfigDto_PositionRelative.toJSON((_f = message.positionOneof) === null || _f === void 0 ? void 0 : _f.relative)
                : undefined);
        message.initialMessageBody !== undefined &&
            (obj.initialMessageBody = message.initialMessageBody);
        return obj;
    },
};
function createBaseConfigDto_PositionFixed() {
    return { bottom: 0, right: 0 };
}
export const ConfigDto_PositionFixed = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bottom !== 0) {
            writer.uint32(8).int32(message.bottom);
        }
        if (message.right !== 0) {
            writer.uint32(16).int32(message.right);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigDto_PositionFixed();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bottom = reader.int32();
                    break;
                case 2:
                    message.right = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bottom: isSet(object.bottom) ? Number(object.bottom) : 0,
            right: isSet(object.right) ? Number(object.right) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.bottom !== undefined && (obj.bottom = Math.round(message.bottom));
        message.right !== undefined && (obj.right = Math.round(message.right));
        return obj;
    },
};
function createBaseConfigDto_PositionRelative() {
    return { parentElementClass: "" };
}
export const ConfigDto_PositionRelative = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.parentElementClass !== "") {
            writer.uint32(10).string(message.parentElementClass);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigDto_PositionRelative();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.parentElementClass = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            parentElementClass: isSet(object.parentElementClass)
                ? String(object.parentElementClass)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.parentElementClass !== undefined &&
            (obj.parentElementClass = message.parentElementClass);
        return obj;
    },
};
function createBaseExpandedConfig() {
    return { ctaIcon: "", ctaText: "" };
}
export const ExpandedConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ctaIcon !== "") {
            writer.uint32(10).string(message.ctaIcon);
        }
        if (message.ctaText !== "") {
            writer.uint32(18).string(message.ctaText);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpandedConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ctaIcon = reader.string();
                    break;
                case 2:
                    message.ctaText = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ctaIcon: isSet(object.ctaIcon) ? String(object.ctaIcon) : "",
            ctaText: isSet(object.ctaText) ? String(object.ctaText) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ctaIcon !== undefined && (obj.ctaIcon = message.ctaIcon);
        message.ctaText !== undefined && (obj.ctaText = message.ctaText);
        return obj;
    },
};
function createBaseCampaignsDto() {
    return { configs: [] };
}
export const CampaignsDto = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.configs) {
            CampaignDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignsDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configs.push(CampaignDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configs: Array.isArray(object === null || object === void 0 ? void 0 : object.configs)
                ? object.configs.map((e) => CampaignDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.configs) {
            obj.configs = message.configs.map((e) => e ? CampaignDto.toJSON(e) : undefined);
        }
        else {
            obj.configs = [];
        }
        return obj;
    },
};
function createBaseCampaignDto() {
    return { id: "", configOneof: undefined, name: "" };
}
export const CampaignDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "rewardForContact") {
            CampaignRewardForContact.encode(message.configOneof.rewardForContact, writer.uint32(74).fork()).ldelim();
        }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.id = reader.string();
                    break;
                case 9:
                    message.configOneof = {
                        $case: "rewardForContact",
                        rewardForContact: CampaignRewardForContact.decode(reader, reader.uint32()),
                    };
                    break;
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            configOneof: isSet(object.rewardForContact)
                ? {
                    $case: "rewardForContact",
                    rewardForContact: CampaignRewardForContact.fromJSON(object.rewardForContact),
                }
                : undefined,
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "rewardForContact" &&
            (obj.rewardForContact = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.rewardForContact)
                ? CampaignRewardForContact.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.rewardForContact)
                : undefined);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseCampaignRewardForContact() {
    return {
        expanded: undefined,
        bannerUrl: "",
        heading: "",
        subHeading: "",
        disclaimer: "",
        successHeading: "",
        successBody: "",
        defaultCountry: "",
    };
}
export const CampaignRewardForContact = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.expanded !== undefined) {
            ExpandedConfig.encode(message.expanded, writer.uint32(10).fork()).ldelim();
        }
        if (message.bannerUrl !== "") {
            writer.uint32(18).string(message.bannerUrl);
        }
        if (message.heading !== "") {
            writer.uint32(26).string(message.heading);
        }
        if (message.subHeading !== "") {
            writer.uint32(34).string(message.subHeading);
        }
        if (message.disclaimer !== "") {
            writer.uint32(42).string(message.disclaimer);
        }
        if (message.successHeading !== "") {
            writer.uint32(50).string(message.successHeading);
        }
        if (message.successBody !== "") {
            writer.uint32(58).string(message.successBody);
        }
        if (message.defaultCountry !== "") {
            writer.uint32(66).string(message.defaultCountry);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignRewardForContact();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.expanded = ExpandedConfig.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.bannerUrl = reader.string();
                    break;
                case 3:
                    message.heading = reader.string();
                    break;
                case 4:
                    message.subHeading = reader.string();
                    break;
                case 5:
                    message.disclaimer = reader.string();
                    break;
                case 6:
                    message.successHeading = reader.string();
                    break;
                case 7:
                    message.successBody = reader.string();
                    break;
                case 8:
                    message.defaultCountry = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            expanded: isSet(object.expanded)
                ? ExpandedConfig.fromJSON(object.expanded)
                : undefined,
            bannerUrl: isSet(object.bannerUrl) ? String(object.bannerUrl) : "",
            heading: isSet(object.heading) ? String(object.heading) : "",
            subHeading: isSet(object.subHeading) ? String(object.subHeading) : "",
            disclaimer: isSet(object.disclaimer) ? String(object.disclaimer) : "",
            successHeading: isSet(object.successHeading)
                ? String(object.successHeading)
                : "",
            successBody: isSet(object.successBody) ? String(object.successBody) : "",
            defaultCountry: isSet(object.defaultCountry)
                ? String(object.defaultCountry)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.expanded !== undefined &&
            (obj.expanded = message.expanded
                ? ExpandedConfig.toJSON(message.expanded)
                : undefined);
        message.bannerUrl !== undefined && (obj.bannerUrl = message.bannerUrl);
        message.heading !== undefined && (obj.heading = message.heading);
        message.subHeading !== undefined && (obj.subHeading = message.subHeading);
        message.disclaimer !== undefined && (obj.disclaimer = message.disclaimer);
        message.successHeading !== undefined &&
            (obj.successHeading = message.successHeading);
        message.successBody !== undefined &&
            (obj.successBody = message.successBody);
        message.defaultCountry !== undefined &&
            (obj.defaultCountry = message.defaultCountry);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
