import React, { useCallback, useMemo } from 'react';
import { AuthenticationButton } from '@connectlyai-sdks/auth';
import {
  Stack,
  MoreHorizIcon,
  Box,
  Label,
  SidebarConnectlyIcon,
  useTheme,
  useMediaQuery,
  Popover,
  IconButton,
} from '@connectlyai-tenets/ui-styled-web';
import { useMeData, selectRole } from '@hooks';
import { Admin, CONNECTLY_BUSINESS_ID } from '@components/Admin';
import { adminBusinessIdAtom } from '@atoms/app';
import { useAtomValue } from 'jotai';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { APP_SIDEBAR_ITEMS } from './constants';
import { AppSidebarItemList, AppSidebarProps } from './types';
import { Item } from './Item';
import packageJson from '../../../package.json';
import { isMenuItemVisible, isPathShallowMatch } from './utils';

const useAppSidebar = () => {
  const adminBusinessId = useAtomValue(adminBusinessIdAtom);
  const history = useHistory();
  const { data: role } = useMeData({
    select: selectRole,
  });
  const isAgent = useMemo(() => !role || role === 'ROLE_AGENT', [role]);
  const items = useMemo(() => {
    return APP_SIDEBAR_ITEMS.filter((item) => (!item.restricted || !isAgent) && isMenuItemVisible(item));
  }, [isAgent]);
  const { pathname } = useLocation();
  const activeRoute = useMemo(() => {
    const adjustedPathname = pathname.startsWith('/flow') ? '/campaigns' : pathname;
    return APP_SIDEBAR_ITEMS.find((route) => isPathShallowMatch(adjustedPathname, route.to));
  }, [pathname]);
  const narrow = useMemo(() => {
    if (activeRoute?.narrow) {
      return true;
    }
    if (pathname === '/flow') {
      return true;
    }
    return false;
  }, [activeRoute?.narrow, pathname]);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isOpen = useMemo(() => {
    if (!narrow && !isMdScreen) {
      return true;
    }
    return false;
  }, [isMdScreen, narrow]);
  const activityBarWidth = useMemo(() => {
    let isNarrow = false;
    if (activeRoute?.narrow) {
      isNarrow = true;
    } else if (pathname === '/flow') {
      isNarrow = true;
    } else if (isMdScreen) {
      isNarrow = true;
    }
    return isNarrow ? theme.spacing(8) : theme.spacing(28.75);
  }, [activeRoute?.narrow, isMdScreen, pathname, theme]);

  const renderItem = useCallback(
    (item: AppSidebarItemList[0]) => {
      return (
        <Item
          key={item.to}
          title={item.title}
          icon={item.icon}
          announcement={item.announcement}
          open={isOpen}
          active={activeRoute?.to === item.to}
          onClick={() => {
            history.push(item.to);
          }}
        />
      );
    },
    [isOpen, activeRoute?.to, history],
  );

  return {
    activityBarWidth,
    adminBusinessId,
    items,
    open: isOpen,
    renderItem,
    theme,
  };
};

// eslint-disable-next-line react/display-name
export const AppSidebar = ({ isAdminUser }: AppSidebarProps) => {
  const { activityBarWidth, adminBusinessId, items, open, renderItem, theme } = useAppSidebar();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'useritem-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const audience = process.env.REACT_APP_INBOX_AUTH0_AUDIENCE || '';
  return (
    <Box
      sx={{
        borderRight: '1px solid #E4E4E7',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        height: '100%',
        whiteSpace: 'nowrap',
        flex: `0 0 ${activityBarWidth}`,
        width: activityBarWidth,
        transition: 'flex .2s ease-in-out; width .2s ease-in-out;',
        overflow: 'hidden',
        textOverflow: 'clip',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          alignItems: 'center',
          borderBottom: '1px solid #E4E4E7',
          boxSizing: 'border-box',
          gap: 1.5,
          height: theme.spacing(8),
          p: 2,
        }}
      >
        {!open && <SidebarConnectlyIcon />}
        {open && (
          <>
            <Stack direction="row" alignItems="center">
              <SidebarConnectlyIcon />
            </Stack>
            <IconButton sx={{ '&:hover': { backgroundColor: '#F1F5F9', color: '#64748B' } }} onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
            <Popover
              id={id}
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <AuthenticationButton audience={audience} />
            </Popover>
          </>
        )}
      </Stack>
      <Box
        sx={{
          alignItems: open ? 'flex-start' : 'center',
          display: 'flex',
          gap: 1,
          px: 1,
          py: 2,
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        {items.filter((item) => item.placement === 'middle').map((item) => renderItem(item))}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          borderTop: '1px solid #E4E4E7',
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
          flexDirection: 'column',
          py: 2,
          px: 1,
        }}
      >
        {open && <Label variant="overline">{packageJson.version}</Label>}
        {isAdminUser && <Admin godMode={!!(adminBusinessId && adminBusinessId !== CONNECTLY_BUSINESS_ID)} />}

        {items.filter((item) => item.placement === 'bottom').map((item) => renderItem(item))}
      </Box>
    </Box>
  );
};
