import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useAtom } from 'jotai';
import { sofiaOnboardingKnowledgeChoiceAtom } from '@atoms/sofia';
import {
  Box,
  Button,
  Card,
  ConnectlyCard,
  DesktopIcon,
  DocumentBlueIcon,
  FreeTextIcon,
  HighlightIcon,
  Label,
  SofiaIcon,
  StoreIcon,
  TextFieldsIcon,
  TranslateIcon,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { OnboardingContainerProps, OnboardingKnowledgeCardProps, OnboardingProps, UseOnboardingProps } from './types';
import { InputLanguage, InputStoreName, InputStoreUrl, InputStoreDescription } from '../StoreDescriptorInputs';
import { SetFreeText, InputFreeText } from '../InputFreeText';
import { InputDocuments } from '../InputDocuments';
import { InputWebsite } from '../InputWebsite';
import { SIZE_MEDIA_XS } from '../../../../ui-theme';

const OnboardingContainer: FC<PropsWithChildren<OnboardingContainerProps>> = ({ title, icon, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.skyBlue,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          mb: 1.5,
        }}
      >
        {icon}
        <Label variant="body1" sx={{ ml: 1 }}>
          {title}
        </Label>
      </Box>
      {children}
    </Box>
  );
};

const OnboardingKnowledgeCard = ({ title, icon, activation, selected, onSelect }: OnboardingKnowledgeCardProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.18)',
        background: theme.palette.common.white,
        cursor: 'pointer',
        minWidth: 0,
        flex: '1 1 0',
        outline: selected ? `2px solid ${theme.palette.primary.main}` : undefined,
        '&:hover': {
          outline: `${selected ? '2' : '1'}px solid ${theme.palette.primary.main}`,
        },
      }}
      onClick={onSelect}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          mb: 1,
        }}
      >
        {icon}
        <Box
          sx={{
            width: theme.spacing(2),
            height: theme.spacing(2),
            border: `2px solid ${selected ? theme.palette.primary.main : theme.palette.text.secondary}`,
            borderRadius: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {selected && (
            <Box
              sx={{
                width: theme.spacing(1.3),
                height: theme.spacing(1.3),
                background: theme.palette.primary.main,
                borderRadius: theme.spacing(1.3),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          )}
        </Box>
      </Box>
      <Label variant="body1" fontWeight="500">
        {title}
      </Label>
      <Label
        variant="body2"
        sx={{
          my: 1,
          borderRadius: 1,
          background: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: '12px',
          width: 'fit-content',
          px: 1,
          py: 0.5,
        }}
      >
        {activation}
      </Label>
    </Box>
  );
};

const useOnboardingKnowledgeBase = () => {
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const [selected, setSelected] = useAtom(sofiaOnboardingKnowledgeChoiceAtom);
  const theme = useTheme();
  const info = useMemo(() => {
    if (selected === 'website') {
      return t('sofia.onboardingWebsiteScanInfo');
    }
    return t('sofia.onboardingKnowledgeBaseInfo');
  }, [selected, t]);
  const cards = useMemo(() => {
    const size = {
      width: theme.spacing(6),
      height: theme.spacing(6),
    };
    return [
      {
        icon: <FreeTextIcon {...size} />,
        title: t('sofia.onboardingKnowledgeBaseTextTitle'),
        activation: t('generic.activateToday'),
        selected: selected === 'text',
        onSelect: () => setSelected('text'),
      },
      {
        icon: <DocumentBlueIcon {...size} />,
        title: t('sofia.onboardingKnowledgeBaseDocumentTitle'),
        activation: t('generic.activateToday'),
        selected: selected === 'document',
        onSelect: () => setSelected('document'),
      },
      {
        icon: <DesktopIcon {...size} />,
        title: t('sofia.onboardingKnowledgeBaseWebsiteTitle'),
        activation: t('generic.activateIn3Days'),
        selected: selected === 'website',
        onSelect: () => setSelected('website'),
      },
    ];
  }, [theme, t, selected, setSelected]);
  return {
    info,
    cards,
    theme,
    selected,
  };
};

const OnboardingKnowledgeBase = ({ freeText, setFreeText }: { freeText: string; setFreeText: SetFreeText }) => {
  const { info, cards, theme, selected } = useOnboardingKnowledgeBase();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        {cards.map((card, index) => {
          return (
            <OnboardingKnowledgeCard
              // eslint-disable-next-line react/no-array-index-key
              key={`onboarding-knowledge-card-${index}`}
              title={card.title}
              icon={card.icon}
              activation={card.activation}
              selected={card.selected}
              onSelect={card.onSelect}
            />
          );
        })}
      </Box>
      <Card sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HighlightIcon
            sx={{
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              color: theme.palette.common.white,
              backgroundColor: theme.palette.common.black,
              borderRadius: theme.spacing(2),
              p: 0.5,
            }}
          />
          <Label sx={{ ml: 1 }}>{info}</Label>
        </Box>
      </Card>
      {selected === 'document' && <InputDocuments isOnboarding />}
      {selected === 'text' && <InputFreeText freeText={freeText} setFreeText={setFreeText} />}
      {selected === 'website' && <InputWebsite />}
    </Box>
  );
};

const useOnboarding = ({ freeText, setFreeText }: UseOnboardingProps) => {
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const title = useMemo(() => {
    return t('sofia.onboardingTitle');
  }, [t]);
  const theme = useTheme();
  const section = useMemo(() => {
    const size = {
      width: theme.spacing(SIZE_MEDIA_XS),
      height: theme.spacing(SIZE_MEDIA_XS),
    };
    const list = [
      {
        title: t('sofia.onboardingLanguageTitle'),
        icon: <TranslateIcon {...size} />,
        body: <InputLanguage autoFocus />,
      },
      {
        title: t('sofia.onboardingStoreTitle'),
        icon: <StoreIcon {...size} />,
        body: (
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <InputStoreName />
            <InputStoreUrl />
          </Box>
        ),
      },
      {
        title: t('sofia.onboardingStoreDescriptionTitle'),
        icon: <TextFieldsIcon {...size} />,
        body: (
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <InputStoreDescription requireValue />
          </Box>
        ),
      },
      {
        title: t('sofia.onboardingInfoTitle'),
        icon: (
          <Box sx={{ '& path': { fill: theme.palette.common.black } }}>
            <SofiaIcon {...size} />
          </Box>
        ),
        body: <OnboardingKnowledgeBase freeText={freeText} setFreeText={setFreeText} />,
      },
    ];
    return list;
  }, [freeText, setFreeText, t, theme]);

  const finishText = useMemo(() => {
    return t('generic.finish');
  }, [t]);
  return {
    title,
    section,
    finishText,
  };
};

export const Onboarding = ({ finishDisabled, finishOnboarding, freeText, setFreeText }: OnboardingProps) => {
  const { title, section, finishText } = useOnboarding({ freeText, setFreeText });
  return (
    <ConnectlyCard title={title}>
      {section.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <OnboardingContainer key={`onboarding-${index}`} icon={item.icon} title={item.title}>
            {item.body}
          </OnboardingContainer>
        );
      })}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button variant="contained" color="primary" disabled={finishDisabled} onClick={finishOnboarding}>
          {finishText}
        </Button>
      </Box>
    </ConnectlyCard>
  );
};
