import { FC, useEffect, useState } from 'react';
import { HomeRouteId } from '@connectlyai-sdks/inbox-navigation';
import { useLocation } from 'react-router';
import { SupportProps } from '../../api';
import packageJson from '../../../../../package.json';

const SUPPORT_WIDGET_ID = '__connectlyai-support';
const SUPPORT_BUSINESS_ID = '6318bf87-c3a3-4b3a-9251-d984d99a3b85';

const supportPaths: {
  [key: string]: boolean;
} = {
  [HomeRouteId]: true,
};

/**
 * Support main component.
 */
const Support: FC<SupportProps> = ({ logProvider, widgetClientProvider }: SupportProps) => {
  const [supportWidget, setSupportWidget] = useState('');
  const [supportEnabled, setSupportEnabled] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const log = logProvider();
    log.debug('location@start', () => ({
      location,
    }));

    const isRouteMatch = supportPaths[location.pathname] || false;
    if (isRouteMatch) {
      setSupportEnabled(true);
    } else {
      setSupportEnabled(false);
    }
  }, [location, logProvider]);

  useEffect(() => {
    const log = logProvider();
    // declare the data fetching function
    const fetchData = async () => {
      log.debug('queryWidgetScriptProvider@start', () => ({}));
      try {
        const widgetClient = widgetClientProvider();
        const resp = await widgetClient.get('/');
        log.debug('queryWidgetScriptProvider@success', () => ({
          status: resp.statusText,
          data: resp.data,
        }));

        const tagLoaderUrl = new URL(process.env.REACT_APP_INBOX_WIDGET_TAGLOADER_SRC || '');
        tagLoaderUrl.searchParams.append('v', packageJson.version);
        const hydradedScript = resp.data
          .replace('{BUSINESS_ID}', SUPPORT_BUSINESS_ID)
          .replace('{TAG_LOADER_SRC}', tagLoaderUrl.href)
          .replace('{MODULES_WIDGET_POSITION}', 'fixed')
          .replace('{POSITION_RIGHT}', '8px')
          .replace('{POSITION_BOTTOM}', '8px');

        setSupportWidget(hydradedScript);
      } catch (err) {
        log.error('queryWidgetScriptProvider@failure', () => ({
          error: err,
        }));
      }
    };

    // call the function
    fetchData().catch((err) => {
      log.error('queryWidgetScriptProvider@failure', () => ({
        error: err,
      }));
    });
  }, [logProvider, widgetClientProvider]);

  useEffect(() => {
    if (supportWidget === '') {
      return;
    }
    const scriptTagOnPage = document.getElementById(SUPPORT_WIDGET_ID);

    function showSupportWidget() {
      // eslint-disable-next-line no-console
      console.log(`Support Enabled: ${supportEnabled}`);
      window.postMessage({ type: 3, show: supportEnabled }, '*');
    }
    if (!scriptTagOnPage) {
      const widgetScriptTag = document.createElement('script');
      widgetScriptTag.id = SUPPORT_WIDGET_ID;
      widgetScriptTag.type = 'text/javascript';
      // Initialize
      widgetScriptTag.text = [
        supportWidget,
        `window.__connectlyai.tags = {
          connectlyai_widget_container: {
            isVisible: ${supportEnabled},
          },
        }`,
      ].join('\n');
      document.body.appendChild(widgetScriptTag);
    } else {
      setTimeout(() => {
        showSupportWidget();
      }, 0);
    }
  }, [supportWidget, supportEnabled]);

  return null;
};

export default Support;
