export const COLOR_CONNECTLY_GREY = '#20232A';
export const COLOR_CONNECTLY_GREY_DARK = '#404246';
export const COLOR_CONNECTLY_GREY_LIGHT = '#F5F5F5';
export const COLOR_CONNECTLY_DIVIDER = '#D6D6D6';
export const COLOR_CONNECTLY_UNREAD = '#4CAF50';

// sizes of input content in spacing
export const SIZE_CONTENT_XL = 128; // 1024px in normal
export const SIZE_CONTENT_L = 75; // 600px in normal
export const SIZE_CONTENT_M = 51; // 408px in normal
export const SIZE_CONTENT_S = 40; // 320px in normal
export const SIZE_CONTENT_XS = 22.5; // 180px in normal
export const SIZE_CONTENT_XXS = 19.5; // 156px in normal (e.g. tall headers)
export const SIZE_CONTENT_XXXS = 10; // 80px in normal (e.g. headers)

// sizes of media content in spacing units
export const SIZE_MEDIA_XXXXL = 54; // 432px in normal
export const SIZE_MEDIA_XXXL = 40; // 320px in normal
export const SIZE_MEDIA_XXL = 36; // 288px in normal
export const SIZE_MEDIA_XL = 16; // 128px in normal
export const SIZE_MEDIA_L = 8; // 64px in normal
export const SIZE_MEDIA_M = 5.5; // 44px in normal
export const SIZE_MEDIA_S = 4; // 32px in normal
export const SIZE_MEDIA_XS = 3; // 24px in normal
export const SIZE_MEDIA_XXS = 2; // 16px in normal
export const SIZE_MEDIA_XXXS = 1.5; // 12px in normal e.g. info icons

// sizes of input content in spacing
export const SIZE_INPUT_XXL = 37; // 296px in normal
export const SIZE_INPUT_XL = 22.5; // 180px in normal
export const SIZE_INPUT_L = 14; // 112px in normal
export const SIZE_INPUT_M = 5; // 40px in normal

// size of whitespace in spacing
export const SIZE_SPACING_CARD = 4; // Spacing between content sections
export const SIZE_SPACING_INTER_SECTION = 3; // Spacing between content sections
export const SIZE_SPACING_INTER_COMPONENT = 1; // Spacing between content components
export const SIZE_SPACING_ANNOTATION = 0.5; // Spacing between components annotations
export const SIZE_SPACING_GUTTER_V = 4; // Spacing between edge and content in vertical/column direction
export const SIZE_SPACING_GUTTER_H = 3; // Spacing between edge and content in horizontal/row direction

// sizes of specific components in spacing
export const ACTIVITY_BAR_WIDTH_CLOSED = 8;
export const ACTIVITY_BAR_WIDTH_OPEN = 30;
export const ACTION_BAR_HEIGHT = 13.5;
