export const getTriggerInstruction = (currentTriggerStep: number) => {
  switch (currentTriggerStep) {
    case 1: {
      return `
      1. In the VTEX Admin, access Store Settings > Storefront > Master Data.
      2. In Master Data, click Applications > Create Applications > Data structure.
      3. Click on the Trigger tab.
      4. Click on the Add New button.
      5. Enter the name of the trigger.
      6. In Data Entity, select the Client (Customer) value.
      7. In Status, check Enabled.`;
    }
    case 2: {
      return `8. On the "Rules" tab, follow the steps below:
      In Trigger Rule, select O valor de um atributo
     for alterado (An attribute value is changed).
     In Field, select Última sessão (Last session).
     Click in Add filter (4x) to enable the fields.
     Select and fill in as indicated and illustrated below.
       • Checkout - Diferente - Finalizado - and
         (Checkout - Different from - Completed - and)
       • Checkout - Não está nulo - and (Checkout - is not null - and)
       • Carrinho - Não está nulo - and (Cart - is not null - and)
       • Último Carrinho - Não está nulo (Last cart - is not null)`;
    }
    case 3: {
      return `
      9. Go to the "Schedule" tab.
      10. Select "Run ASAP"`;
    }
    case 4: {
      return `
      11. Go to the tab "If positive".
      12. Select the action "Send an HTTP request"`;
    }
    case 5: {
      return '13. On the URL field, paste:';
    }
    case 6: {
      return '14. On method, change to "POST"';
    }
    case 7: {
      return '15. On Content as JSON, paste the code below:';
    }
    case 8: {
      return '16. Hit Save';
    }
    default: {
      return '';
    }
  }
};

export const getStepLabel = (currentStep: number) => {
  switch (currentStep) {
    case 0: {
      return 'To get started, set up a trigger in your VTEX Account.';
    }
    case 1: {
      return 'Create a custom VTEX API key and token';
    }
    case 2: {
      return 'Enter your VTEX store URL and your account name';
    }
    default: {
      return '';
    }
  }
};
export const getStepHelperText = (currentStep: number) => {
  switch (currentStep) {
    case 0: {
      return 'After configuring the trigger on the VTEX Admin Panel, proceed by clicking the Next button below.';
    }
    case 1: {
      return 'Add your custom API key and token below';
    }
    case 2: {
      return 'This is located on the top left of your VTEX Admin Panel';
    }
    default: {
      return '';
    }
  }
};
