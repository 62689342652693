import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  Box,
  CodeIcon,
  Dialog,
  FormatBoldIcon,
  FormatItalicIcon,
  FormatStrikethroughIcon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
  Label,
  DialogTitle,
  Button,
  DialogActions,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { MESSAGE_MAX_LENGTH } from './constants';
import { isAppleDevice } from '../../utils';
import EmojiPickerButton from '../../presentation/EmojiPicker';
import { MessageEditorDialogProps } from './types';

const useMessageEditorDialog = ({ message, open, onCancel, onSave }: MessageEditorDialogProps) => {
  const [unsavedMessage, setUnsavedMessage] = useState(message);

  // when dialog is opened set the message
  useEffect(() => {
    if (open) {
      setUnsavedMessage(message);
    }
  }, [message, open]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectEmoji = useCallback(({ native }: any) => {
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    if (document.activeElement !== element) {
      element.focus();
    }

    document.execCommand('insertText', false, native);
  }, []);

  const handleFormatting = useCallback((format: string) => {
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    element.focus();

    if (element.selectionStart === null || element.selectionEnd === null) {
      return;
    }

    const selectedText = element.value.substring(element.selectionStart, element.selectionEnd);

    const { selectionStart: prevSelectionStart, selectionEnd: prevSelectionEnd } = element;
    document.execCommand('insertText', false, `${format}${selectedText}${format}`);
    if (!selectedText) {
      // move cursor between marks
      element.selectionStart -= format.length;
      element.selectionEnd -= format.length;
    } else {
      /// keep text selected
      element.selectionStart = prevSelectionStart + format.length;
      element.selectionEnd = prevSelectionEnd + format.length;
    }
  }, []);

  const handleBold = useCallback(() => {
    handleFormatting('*');
  }, [handleFormatting]);

  const handleItalic = useCallback(() => {
    handleFormatting('_');
  }, [handleFormatting]);

  const handleStrikethrough = useCallback(() => {
    handleFormatting('~');
  }, [handleFormatting]);

  const handleMonospace = useCallback(() => {
    handleFormatting('```');
  }, [handleFormatting]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let handled = false;
      switch (e.code) {
        case 'KeyI': {
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleItalic();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleItalic();
            handled = true;
          }
          break;
        }
        case 'KeyB': {
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleBold();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleBold();
            handled = true;
          }
          break;
        }
        default: {
          break;
        }
      }

      if (handled) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [handleBold, handleItalic],
  );

  const handleSave = useCallback(() => onSave(unsavedMessage), [onSave, unsavedMessage]);

  return {
    message: unsavedMessage,
    onBold: handleBold,
    onCancel,
    onItalic: handleItalic,
    onKeyDown: handleKeyDown,
    onMessageChange: setUnsavedMessage,
    onMonospace: handleMonospace,
    onSave: handleSave,
    onSelectEmoji: handleSelectEmoji,
    onStrikethrough: handleStrikethrough,
    open,
  };
};

export const MessageEditorDialog: FC<MessageEditorDialogProps> = (props) => {
  const {
    message,
    onBold,
    onCancel,
    onItalic,
    onKeyDown,
    onMessageChange,
    onMonospace,
    onSave,
    onSelectEmoji,
    onStrikethrough,
    open,
  } = useMessageEditorDialog(props);
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.autoReplySection.editAutoReplyMessageDialog' });
  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle id="alert-dialog-title">
        <Label variant="h6">{t('title')}</Label>
      </DialogTitle>
      <Box
        sx={{
          px: 3,
          mb: 1,
          width: theme.spacing(75),
          boxSizing: 'border-box',
        }}
      >
        <TextField
          id="message-template-body"
          autoComplete="off"
          autoFocus
          placeholder={t('hint')}
          fullWidth
          multiline
          minRows={8}
          maxRows={16}
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          variant="outlined"
          inputProps={{
            maxLength: MESSAGE_MAX_LENGTH * 2,
          }}
          InputProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              padding: 1.5,
              borderRadius: '10px',
            },
            endAdornment: (
              <InputAdornment
                disableTypography
                position="end"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  mb: 2,
                  mr: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  <EmojiPickerButton
                    anchorOrigin={{
                      vertical: -parseInt(theme.spacing(1), 10),
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disabled={false}
                    tooltip="Insert emoji"
                    onSelect={onSelectEmoji}
                  />
                  <Tooltip title={`Bold (${isAppleDevice() ? '⌘' : 'Ctrl'} + b)`}>
                    <IconButton sx={{ p: 0.5 }} onClick={onBold}>
                      <FormatBoldIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={`Italic (${isAppleDevice() ? '⌘' : 'Ctrl'} + i)`}>
                    <IconButton sx={{ p: 0.5 }} onClick={onItalic}>
                      <FormatItalicIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Strikethrough">
                    <IconButton sx={{ p: 0.5 }} onClick={onStrikethrough}>
                      <FormatStrikethroughIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Mono">
                    <IconButton sx={{ p: 0.5 }} onClick={onMonospace}>
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    color: message.length > MESSAGE_MAX_LENGTH ? 'error.main' : 'text.secondary',
                    userSelect: 'none',
                  }}
                >
                  {`${message.length}/${MESSAGE_MAX_LENGTH}`}
                </Box>
              </InputAdornment>
            ),
          }}
          onKeyDown={onKeyDown}
        />
      </Box>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button onClick={onSave}>{t('save')}</Button>
      </DialogActions>
    </Dialog>
  );
};
