import React from 'react';
import { Label, Box } from '@connectlyai-tenets/ui-styled-web';
import { getCountryFlag } from '../../../../presentation/preview/utils';

export const Country = ({ country, regions }: { country: string; regions: Intl.DisplayNames }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    <Label variant="h5">{getCountryFlag(country)}</Label>
    <Label sx={{ fontSize: '16px', lineHeight: '100%', fontWeight: 500 }}>{regions.of(country)}</Label>
  </Box>
);

export const ConversationsCount = ({ value }: { value: string }) => (
  <Box>
    <Label variant="subtitle2">{value}</Label>
  </Box>
);
