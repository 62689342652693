import { AppState } from '../../../state/store';
import { ONBOARDING_STEP_LIST } from './constants';

export const selectBusinessName = (state: AppState) => state.welcome.businessName;
export const selectBusinessProfilePicture = (state: AppState) => state.welcome.businessProfilePicture;
export const selectConfirmation = (state: AppState) => state.welcome.confirmation;
export const selectConnectUrl = (state: AppState) => state.welcome.shopifyOptions?.connectUrl || '';
export const selectPhoneNumber = (state: AppState) => state.welcome.phoneNumber;
export const selectProgress = (state: AppState) => {
  const { step } = state.welcome;

  let index = 0;
  for (let i = 0; i < ONBOARDING_STEP_LIST.length; i += 1) {
    if (Array.isArray(ONBOARDING_STEP_LIST[i]) && ONBOARDING_STEP_LIST[i].includes(step)) {
      index = i;
      break;
    }
    if (ONBOARDING_STEP_LIST[i] === step) {
      index = i;
      break;
    }
  }

  return ((index + 1) / ONBOARDING_STEP_LIST.length) * 100;
};
export const selectStep = (state: AppState) => state.welcome.step;
export const selectStoreUrl = (state: AppState) => state.welcome.shopifyOptions?.storeUrl || '';
export const selectIsNextStepDisabled = (state: AppState) => {
  switch (state.welcome.step) {
    case 'businessName': {
      return state.welcome.businessName.trim().length === 0;
    }
    case 'phoneNumberOld': {
      return state.welcome.phoneNumber.trim().length === 0;
    }
    case 'connectShopify': {
      return !state.welcome.shopifyOptions?.connectUrl;
    }
    case 'connectWhatsApp': {
      return !state.welcome.confirmation;
    }
    default: {
      return false;
    }
  }
};
export const selectIsPreviousStepDisabled = (state: AppState) => state.welcome.step === 'phoneNumberSelector';
export const selectUseNewPhoneNumber = (state: AppState) => state.welcome.useNewPhoneNumber;
