import React, { FC, useRef } from 'react';
import { Button, PhoneIcon, OpenInNewIcon, ReplyIcon, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SourceHandle, SourceHandleRef } from '@components/SourceHandle';
import { HandleType } from '../../sdks/flow/createHandleId';
import { getButtonText } from '../../utils';
import { TemplateComponent } from '../../hooks/useMessageTemplateGroupsData/types';

export const FlowChartMessageNodeButton: FC<{
  button: TemplateComponent;
  handleType: HandleType;
  isLast?: boolean;
}> = ({ button, handleType, isLast }) => {
  const sourceHandle = useRef<SourceHandleRef | null>(null);
  const isQuickReply = button.button?.quickReply;
  const isCarousel = handleType.nodeType === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE';
  const theme = useTheme();

  return (
    <Button
      variant={isCarousel ? 'outlined' : 'contained'}
      color="secondary"
      sx={{
        textTransform: 'none',
        display: 'flex',
        gap: 0.5,
        borderRadius: isCarousel ? undefined : `0 0 ${isLast ? theme.spacing(1) : 0} ${isLast ? theme.spacing(1) : 0}`,
        borderBottom: isCarousel || isLast ? undefined : `1px solid ${theme.palette.divider}`,
      }}
      disabled={!isQuickReply || !!sourceHandle.current?.isDisabled}
      onClick={sourceHandle.current?.handleClick}
    >
      {button.button?.phoneNumber && <PhoneIcon sx={{ width: theme.spacing(2.25), height: theme.spacing(2.25) }} />}
      {button.button?.url && <OpenInNewIcon sx={{ width: theme.spacing(2.25), height: theme.spacing(2.25) }} />}
      {button.button?.quickReply && <ReplyIcon sx={{ width: theme.spacing(2.25), height: theme.spacing(2.25) }} />}
      <Label variant="body1">{getButtonText(button) || 'Button Text'}</Label>
      {button.button?.quickReply && <SourceHandle ref={sourceHandle} {...handleType} handleLineLength={35} />}
    </Button>
  );
};
