import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { SofiaV2UrlParameters, SofiaV2Response, SOFIA_STORE_DESCRIPTOR_V2_URL } from './types';

export const useSofiaStoreDescriptorV2 = <TData = SofiaV2Response>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<SofiaV2Response, unknown, TData, readonly [target: 'sofiaV2', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  SofiaV2UrlParameters) => {
  return useQuery(
    ['sofiaV2', businessId],
    () =>
      api.aiV1(SOFIA_STORE_DESCRIPTOR_V2_URL, 'get', {
        path: { 'input.businessId': businessId },
        // query: { noCache: true },
      }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
