import { ZendeskZisCommentAddedEvent, ZendeskZisStatusChangedEvent, } from "../../events/zendesk/v2/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.ticket";
function createBaseComment() {
    return {
        ticketUrl: "",
        authorName: "",
        authoredByStaff: false,
        body: "",
        isPublic: false,
        rawOneof: undefined,
    };
}
export const Comment = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.ticketUrl !== "") {
            writer.uint32(10).string(message.ticketUrl);
        }
        if (message.authorName !== "") {
            writer.uint32(18).string(message.authorName);
        }
        if (message.authoredByStaff === true) {
            writer.uint32(24).bool(message.authoredByStaff);
        }
        if (message.body !== "") {
            writer.uint32(34).string(message.body);
        }
        if (message.isPublic === true) {
            writer.uint32(40).bool(message.isPublic);
        }
        if (((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "zendesk") {
            ZendeskZisCommentAddedEvent.encode(message.rawOneof.zendesk, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticketUrl = reader.string();
                    break;
                case 2:
                    message.authorName = reader.string();
                    break;
                case 3:
                    message.authoredByStaff = reader.bool();
                    break;
                case 4:
                    message.body = reader.string();
                    break;
                case 5:
                    message.isPublic = reader.bool();
                    break;
                case 11:
                    message.rawOneof = {
                        $case: "zendesk",
                        zendesk: ZendeskZisCommentAddedEvent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticketUrl: isSet(object.ticketUrl) ? String(object.ticketUrl) : "",
            authorName: isSet(object.authorName) ? String(object.authorName) : "",
            authoredByStaff: isSet(object.authoredByStaff)
                ? Boolean(object.authoredByStaff)
                : false,
            body: isSet(object.body) ? String(object.body) : "",
            isPublic: isSet(object.isPublic) ? Boolean(object.isPublic) : false,
            rawOneof: isSet(object.zendesk)
                ? {
                    $case: "zendesk",
                    zendesk: ZendeskZisCommentAddedEvent.fromJSON(object.zendesk),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.ticketUrl !== undefined && (obj.ticketUrl = message.ticketUrl);
        message.authorName !== undefined && (obj.authorName = message.authorName);
        message.authoredByStaff !== undefined &&
            (obj.authoredByStaff = message.authoredByStaff);
        message.body !== undefined && (obj.body = message.body);
        message.isPublic !== undefined && (obj.isPublic = message.isPublic);
        ((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "zendesk" &&
            (obj.zendesk = ((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.zendesk)
                ? ZendeskZisCommentAddedEvent.toJSON((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.zendesk)
                : undefined);
        return obj;
    },
};
function createBaseStatus() {
    return {
        ticketUrl: "",
        previousStatus: "",
        currentStatus: "",
        rawOneof: undefined,
    };
}
export const Status = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.ticketUrl !== "") {
            writer.uint32(10).string(message.ticketUrl);
        }
        if (message.previousStatus !== "") {
            writer.uint32(18).string(message.previousStatus);
        }
        if (message.currentStatus !== "") {
            writer.uint32(26).string(message.currentStatus);
        }
        if (((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "zendesk") {
            ZendeskZisStatusChangedEvent.encode(message.rawOneof.zendesk, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStatus();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticketUrl = reader.string();
                    break;
                case 2:
                    message.previousStatus = reader.string();
                    break;
                case 3:
                    message.currentStatus = reader.string();
                    break;
                case 11:
                    message.rawOneof = {
                        $case: "zendesk",
                        zendesk: ZendeskZisStatusChangedEvent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticketUrl: isSet(object.ticketUrl) ? String(object.ticketUrl) : "",
            previousStatus: isSet(object.previousStatus)
                ? String(object.previousStatus)
                : "",
            currentStatus: isSet(object.currentStatus)
                ? String(object.currentStatus)
                : "",
            rawOneof: isSet(object.zendesk)
                ? {
                    $case: "zendesk",
                    zendesk: ZendeskZisStatusChangedEvent.fromJSON(object.zendesk),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.ticketUrl !== undefined && (obj.ticketUrl = message.ticketUrl);
        message.previousStatus !== undefined &&
            (obj.previousStatus = message.previousStatus);
        message.currentStatus !== undefined &&
            (obj.currentStatus = message.currentStatus);
        ((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "zendesk" &&
            (obj.zendesk = ((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.zendesk)
                ? ZendeskZisStatusChangedEvent.toJSON((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.zendesk)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
