import { styled, LinearProgress, linearProgressClasses } from '@connectlyai-tenets/ui-styled-web';

export const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
    backgroundColor: '#007DFF',
  },
}));
