import {
  ArrowBackIcon,
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Label,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAbandonedCartActivation } from '@hooks/useAbandonedCart';
import { SIZE_CONTENT_L, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { AbandonedCartEdit } from '../AbandonedCartEdit';
import { AbandonedCartEditDeliveryWindow } from '../AbandonedCartEditDeliveryWindow';
import { AbandonedCartEditDiscount } from '../AbandonedCartEditDiscount';
import { AbandonedCartEditLanguage } from '../AbandonedCartEditLanguage';
import { AbandonedCartEditMessageType } from '../AbandonedCartEditMessageType';
import { ApprovalDialog } from '../ApprovalDialog';
import { WhatsAppMessageTemplatePreview } from '../WhatsAppMessageTemplatePreview';
import { ActivationScreen } from './constants';

import { AbandonedCartActivationProps } from './types';

export const AbandonedCartActivation = ({
  firstMessageTemplate,
  secondMessageTemplate,
  integrationParameters,
  integrationType,
  open,
  reminder,
  onClose,
}: AbandonedCartActivationProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart.editIntegration' });
  const {
    screen,
    language,
    discount,
    reminderDiscount,
    isPersonalMessage,
    enableReminder,
    enableHeaderImage,
    enableSafeWindow,
    isLoading,
    onBack,
    onSetScreen,
    onLanguageChange,
    onSetReminder,
    onDiscountSave,
    onReminderDiscountSave,
    onSetMessageType,
    onSetSafeWindow,
    onSubmit,
    onSetHeaderImage,
  } = useAbandonedCartActivation(integrationType, integrationParameters, reminder);

  const messageTitle = useMemo(
    () => (isPersonalMessage ? t('aiExample') : t('standardExample')),
    [isPersonalMessage, t],
  );

  const getTitle = useMemo(() => {
    switch (screen) {
      case ActivationScreen.HomeScreen:
        return t('titleHome');
      case ActivationScreen.EditLanguage:
        return t('language');
      case ActivationScreen.EditDiscount:
        return t('discount');
      case ActivationScreen.EditReminderDiscount:
        return t('discount');
      case ActivationScreen.EditMessageType:
        return t('messageType');
      case ActivationScreen.EditSafetyWindow:
        return t('safetyWindow.title');
      case ActivationScreen.MessagePreview:
        return t('titlePreview');
      case ActivationScreen.ReminderPreview:
        return t('titlePreview');
      default:
        return t('titleHome');
    }
  }, [screen, t]);
  const getScreen = () => {
    const reminderMessagePreview = (
      <WhatsAppMessageTemplatePreview
        bodyText={
          language === 'es' ? (
            <>
              ¿Sigues pensando en esos artículos? ¡No lo pienses más, es hora de hacerlos tuyos! 💥🔥{'\n\n'}
              <Box
                component="span"
                m="{1}"
                sx={{ backgroundColor: theme.palette.grey.A100, p: 0.5, borderRadius: 0.5 }}
              >
                Nombre Comercial
              </Box>
            </>
          ) : (
            <>
              {`Still thinking about those items? Think no more, it's time to make them yours!`} 💥🔥{'\n\n'}
              <Box
                component="span"
                m="{1}"
                sx={{ backgroundColor: theme.palette.grey.A100, p: 0.5, borderRadius: 0.5 }}
              >
                Business Name
              </Box>
            </>
          )
        }
      />
    );

    const EditAC = (
      <AbandonedCartEdit
        language={language}
        discount={discount}
        enableReminder={enableReminder}
        enableSafeWindow={enableSafeWindow}
        enableHeaderImage={enableHeaderImage}
        isPersonalMessage={isPersonalMessage}
        isLoading={isLoading}
        reminder={enableReminder}
        reminderDiscount={reminderDiscount}
        firstMessageTemplate={firstMessageTemplate}
        secondMessageTemplate={secondMessageTemplate}
        onEdit={onSetScreen}
        onActivate={onSubmit}
        onEditReminder={onSetReminder}
        onSetHeaderImage={onSetHeaderImage}
        integrationType={integrationType}
      />
    );
    switch (screen) {
      case ActivationScreen.HomeScreen:
        return EditAC;
      case ActivationScreen.EditLanguage:
        return <AbandonedCartEditLanguage language={language} setLanguage={onLanguageChange} />;
      case ActivationScreen.EditDiscount:
        return (
          <Box sx={{ width: theme.spacing(SIZE_CONTENT_L) }}>
            <AbandonedCartEditDiscount discount={discount} onSave={onDiscountSave} onClose={() => {}} />
          </Box>
        );
      case ActivationScreen.EditReminderDiscount:
        return (
          <Box sx={{ width: theme.spacing(SIZE_CONTENT_L) }}>
            <AbandonedCartEditDiscount discount={reminderDiscount} onSave={onReminderDiscountSave} onClose={() => {}} />
          </Box>
        );
      case ActivationScreen.EditMessageType:
        return (
          <AbandonedCartEditMessageType
            isPersonalMessage={isPersonalMessage}
            setMessageType={onSetMessageType}
            language={language}
            discount={discount}
          />
        );
      case ActivationScreen.EditSafetyWindow:
        return (
          <AbandonedCartEditDeliveryWindow enableSafeWindow={enableSafeWindow} onSetSafeWindow={onSetSafeWindow} />
        );
      case ActivationScreen.MessagePreview:
        return (
          <DialogContent
            sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.grey.A100,
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                gap: SIZE_SPACING_INTER_COMPONENT,
              }}
            >
              <Label variant="subtitle1" sx={{ lineHeight: '20px' }}>
                {messageTitle}
              </Label>
              <Label variant="caption" sx={{ color: theme.palette.grey.A700 }}>
                {t('messageVaries')}
              </Label>
              <WhatsAppMessageTemplatePreview
                discount={discount}
                language={language}
                isPersonalMessage={isPersonalMessage}
                img={`${process.env.PUBLIC_URL}/assets/template_sample_message_image.png`}
              />
            </Box>
          </DialogContent>
        );
      case ActivationScreen.ReminderPreview:
        return (
          <DialogContent
            sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.grey.A100,
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                gap: SIZE_SPACING_INTER_COMPONENT,
              }}
            >
              <Label variant="subtitle1" sx={{ lineHeight: '20px' }}>
                {messageTitle}
              </Label>
              <Label variant="caption" sx={{ color: theme.palette.grey.A700 }}>
                {t('messageVaries')}
              </Label>
              {reminderMessagePreview}
            </Box>
          </DialogContent>
        );
      default:
        return EditAC;
    }
  };

  if (screen === ActivationScreen.Conclusion) {
    return (
      <ApprovalDialog
        open={open}
        onClose={() => {
          onSetScreen(ActivationScreen.HomeScreen);
          onClose();
        }}
        title={t('success')}
        content={t('successContent')}
      />
    );
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {screen === ActivationScreen.HomeScreen ? (
          <Box />
        ) : (
          <IconButton size="small" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Label variant="h5">{getTitle}</Label>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {getScreen()}
    </Dialog>
  );
};
