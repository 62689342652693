// unreduxified, has only messageTemplates redux

import { useCallback, useMemo } from 'react';
import { escapeRegExp } from 'lodash';
import { useAtomValue } from 'jotai';
import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { useMessageTemplateGroupsData, selectAllMessageTemplateNames } from '@hooks/useMessageTemplateGroupsData';
import { campaignNameAtom, documentTypeAtom, nodesAtom, revisionIdAtom } from '@atoms/flow';
import { normalizeName } from '../../../../presentation/preview/utils';
import { createNode as createNodeSDK, NodeType } from '../../../../sdks/flow/createNode';
import { hasNodeName } from '../../../../utils';

export const useCreateNode = () => {
  const campaignName = useAtomValue(campaignNameAtom);
  const documentType = useAtomValue(documentTypeAtom);
  const nodes = useAtomValue(nodesAtom);
  const documentRevisionId = useAtomValue(revisionIdAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: remoteTemplateNames, isSuccess: isRemoteReady } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectAllMessageTemplateNames,
    keepPreviousData: true,
  });

  const templateName = useCallback(() => {
    const normalizedName =
      documentType === 'FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1'
        ? `auto_reply_${normalizeName(documentRevisionId?.slice((documentRevisionId?.length || 0) - 6) || '')}`
        : normalizeName(campaignName);

    const localTemplateNames = nodes.filter((node) => node?.data?.v1?.name).map((node) => node?.data?.v1?.name);

    const regex = new RegExp(`^${escapeRegExp(normalizedName)}_\\d+$`);
    const allNames = [...localTemplateNames, ...(remoteTemplateNames || [])];
    const matchingNames = allNames.filter((name) => regex.test(name));
    if (matchingNames.length === 0) return `${normalizedName}_1`;
    const matchingNumbers = matchingNames
      .map((name) => parseInt(name.replace(`${normalizedName}_`, ''), 10))
      .sort((a, b) => a - b);

    const nextNumber = matchingNumbers[matchingNumbers.length - 1] + 1;
    return `${normalizedName}_${nextNumber}`;
  }, [campaignName, documentRevisionId, nodes, remoteTemplateNames, documentType]);

  const isReady = useMemo(() => campaignName !== '' && isRemoteReady, [campaignName, isRemoteReady]);

  const createNode = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (nodeType: NodeType, customization: any = {}) => {
      const rawNode = createNodeSDK(nodeType);
      if (!rawNode) return undefined;
      return {
        ...rawNode,
        ...customization,
        data: {
          ...rawNode?.data,
          ...customization?.data,
          v1: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(rawNode?.data?.v1 as any),
            ...customization?.data?.v1,
            ...(hasNodeName(nodeType) ? { name: templateName() } : {}),
          },
        },
      };
    },
    [templateName],
  );
  return { createNode, isReady, templateName, remoteTemplateNames };
};
