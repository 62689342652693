import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Slide,
  useTheme,
  Label,
  Button,
  ErrorIcon,
  CloseIcon,
  IconButton,
  useMediaQuery,
  Tooltip,
  ChevronRightIcon,
  CircularProgress,
} from '@connectlyai-tenets/ui-styled-web';
import { useContextSelector } from 'use-context-selector';
import { useAtom } from 'jotai';
import { Node } from 'react-flow-renderer';
import { isFlowChecksOpenAtom } from '@atoms/flow';
import { useFlowChecks } from '../../hooks/useFlowChecks';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';

const useFlowChartChecksSidebar = () => {
  const centerToNode = useContextSelector(FlowCampaignContext, (context) => context?.centerToNode);
  const [isFlowChecksOpen, setIsFlowChecksOpen] = useAtom(isFlowChecksOpenAtom);
  const { warnings, isIdle: isFlowChecksIdle } = useFlowChecks();
  const handleDismiss = useCallback(() => {
    setIsFlowChecksOpen(false);
  }, [setIsFlowChecksOpen]);

  useEffect(() => {
    if (isFlowChecksOpen && warnings.length === 0) {
      setIsFlowChecksOpen(false);
    }
  }, [isFlowChecksOpen, isFlowChecksIdle, warnings, setIsFlowChecksOpen]);

  return {
    isFlowChecksOpen,
    setIsFlowChecksOpen,
    isFlowChecksIdle,
    handleDismiss,
    warnings,
    centerToNode,
  };
};

export const FlowChartChecksSidebar = () => {
  const { isFlowChecksOpen, isFlowChecksIdle, handleDismiss, warnings, centerToNode } = useFlowChartChecksSidebar();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Slide
      in={isFlowChecksOpen}
      direction="right"
      timeout={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: theme.palette.background.paper,
          willChange: 'transform',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            gap: 1,
            flex: '0 0 auto',
            px: isLargeScreen ? 3 : 2,
            mt: isLargeScreen ? 3.5 : 2,
          }}
        >
          <Label variant="h6">Flow Check</Label>
          <IconButton aria-label="close" onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Label variant="body1" sx={{ mx: isLargeScreen ? 3 : 2, my: isLargeScreen ? 3 : 2 }}>
          Several issues have been detected. Please resolve them before proceeding.
        </Label>
        {isFlowChecksIdle && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isFlowChecksIdle && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: isLargeScreen ? 3 : 2,
              flex: '1 1 auto',
              gap: 1.5,
              overflowY: 'scroll',
            }}
          >
            {warnings.map((warning) => (
              <Box
                key={warning.key}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  gap: 1,
                  borderRadius: '10px',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  p: isLargeScreen ? 2 : 1,
                  cursor: 'pointer',
                  '&:hover': {
                    background: theme.palette.grey[100],
                  },
                }}
                onClick={() => centerToNode(warning.node as Node)}
              >
                <Tooltip title={warning.nodeName || 'Node has no name'}>
                  <ErrorIcon sx={{ color: theme.palette.warning.main }} />
                </Tooltip>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, flexGrow: 1 }}>
                  <Label variant="body1">{warning.warning}</Label>
                  <Label variant="body1" color="text.secondary">
                    {warning.details}
                  </Label>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ChevronRightIcon />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Button variant="outlined" color="secondary" onClick={handleDismiss} sx={{ m: isLargeScreen ? 3 : 2 }}>
          Dismiss
        </Button>
      </Box>
    </Slide>
  );
};
