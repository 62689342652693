import { Paths } from '@connectlyai-tenets/sdk';

export const SCHEDULE_SENDOUT_URL = '/internal/v3/businesses/{input.businessId}/schedule/sendouts/{input.sendoutId}';
type ScheduleSendoutURL = typeof SCHEDULE_SENDOUT_URL;

export type ScheduleSendoutQuery = Paths['campaignV3'][ScheduleSendoutURL]['post']['parameters']['body']['input'];

export type ScheduleSendoutUrlParameters = {
  businessId: string;
  sendoutId: string;
};

export type ScheduleSendoutVariables = ScheduleSendoutQuery & ScheduleSendoutUrlParameters;

export type ScheduleSendoutResponse = Paths['campaignV3'][ScheduleSendoutURL]['post']['responses']['200']['schema'];
