import { Paths } from '@connectlyai-tenets/sdk';

export const INITIALIZE_SPREADSHEET_URL =
  '/internal/v3/businesses/{input.businessId}/initialize_spreadsheet/sendouts/{input.sendoutId}';
type InitializeSpreadsheetURL = typeof INITIALIZE_SPREADSHEET_URL;

export type InitializeSpreadsheetQuery =
  Paths['campaignV3'][InitializeSpreadsheetURL]['post']['parameters']['body']['input'];

export type InitializeSpreadsheetUrlParameters = {
  businessId: string;
  sendoutId: string;
};

export type InitializeSpreadsheetVariables = InitializeSpreadsheetQuery & InitializeSpreadsheetUrlParameters;

export type InitializeSpreadsheetResponse =
  Paths['campaignV3'][InitializeSpreadsheetURL]['post']['responses']['200']['schema'];

export type SpreadsheetFrquencyCapOption = NonNullable<
  NonNullable<
    NonNullable<NonNullable<NonNullable<InitializeSpreadsheetResponse['entity']>['configV3']>['v3']>['execution']
  >['v1']
>['frequencyCapOption'];

export type SpreadsheetUrl = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<NonNullable<NonNullable<InitializeSpreadsheetResponse['entity']>['configV3']>['v3']>['cohort']
    >['v1']
  >['spreadsheetV1']
>['spreadsheetUrl'];
