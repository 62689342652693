export const labels = (t: (_: string) => string) => ({
  title: t('flow.apiNodeTitle'),
  url: {
    label: t('generic.url'),
  },
  method: {
    label: t('generic.method'),
  },
  parameters: {
    title: t('generic.parameters'),
    hint: 'Parameters hint',
    add: t('flow.addParameter'),
    type: {
      label: t('generic.type'),
    },
    key: {
      label: t('flow.parameterKey'),
    },
    value: {
      label: t('flow.parameterValue'),
    },
  },
  query: {
    add: t('flow.addParameter'),
    title: t('generic.query'),
    hint: 'Headers hint',
  },
  body: {
    add: t('flow.addParameter'),
    title: t('generic.body'),
    hint: t('flow.apiNodeBodyHint'),
  },
  headers: {
    add: t('flow.addParameter'),
    title: t('generic.headers'),
    hint: 'Headers hint',
  },
  save: t('generic.save'),
});
