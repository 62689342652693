import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NodeChange,
  EdgeChange,
  Node,
  applyEdgeChanges,
  applyNodeChanges,
  NodeRemoveChange,
  NodeSelectionChange,
  EdgeRemoveChange,
} from 'react-flow-renderer';
import produce from 'immer';
import { TemplateComponent } from '@hooks/useMessageTemplateGroupsData';
import { NodeObjectUpdate, useChangesQueue } from '../useChangesQueue';
import { FlowChangeAppliers, FlowChangeAppliersParams } from './types';
import { cancel, updateNodeId as updateNode } from '../../state/actions';
import { createHandleId, HandleType } from '../../sdks/flow/createHandleId';
import { selectNodes, setEdges, setNodes, selectSelectedNode } from '../../state/flow';
import appStore from '../../state/store';

// filter out node's unnecessary properties before adding them to changeQueue for backend
const filterOutNodeProperties = (node: Node): Node => {
  return produce(node, (draft) => {
    delete draft.data?.v1?.isGenerating;
    delete draft.dragging;
    delete draft.height;
    delete draft.positionAbsolute;
    delete draft.selected;
    delete draft.width;
  });
};

export const useNodeObjectChange = () => {
  const dispatch = useDispatch();

  const { addNodeObjectUpdatesToQueue } = useChangesQueue();
  // use getState rather than selector to get nodes and edges immediately
  const nodesCurrent = useCallback(() => selectNodes(appStore.getState()), []);

  const onNodeObjectChange = useCallback(
    (updates: NodeObjectUpdate[]) => {
      // filter out unnecessary properties of nodes before adding them to changeQueue for backend
      const changesToSend = produce(updates, (draft) => {
        draft.forEach((update) => {
          update.item = filterOutNodeProperties(update.item);
        });
        return draft;
      });

      addNodeObjectUpdatesToQueue(changesToSend);

      dispatch(
        setNodes(
          produce(nodesCurrent(), (draft) =>
            draft.map((node) => {
              const update = updates.find((c) => c.id === node.id);
              if (update) {
                return { ...node, ...update.item };
              }
              return node;
            }),
          ),
        ),
      );
    },
    [addNodeObjectUpdatesToQueue, dispatch, nodesCurrent],
  );

  return {
    onNodeObjectChange,
  };
};

export const useFlowChangeAppliers = ({
  onNodesChangeRequested,
  selectIsDocumentEditable,
}: FlowChangeAppliersParams): FlowChangeAppliers => {
  const isDocumentEditable = useSelector(selectIsDocumentEditable);

  // use getState rather than selector to get nodes and edges immediately
  const nodes = useCallback(() => appStore.getState().flowDocument.nodes, []);
  const edges = useCallback(() => appStore.getState().flowDocument.edges, []);

  const selectedNode = useSelector(selectSelectedNode);
  const dispatch = useDispatch();

  const { addEdgeChangesToQueue, addNodeChangesToQueue, updateIdInChangesQueue } = useChangesQueue();
  const { onNodeObjectChange } = useNodeObjectChange();

  // updating node id requires 3 steps:
  const updateNodeId = useCallback(
    (oldNodeId: string, newId: string) => {
      // step 1: update node id in nodes
      dispatch(
        setNodes([
          ...nodes().map((node) => (node.id === oldNodeId ? { ...node, id: newId, data: { ...node.data } } : node)),
        ]),
      );

      // step 2: update node id in edges, source and target and also sourceHandle and targetHandle
      dispatch(
        setEdges([
          ...edges().map((edge) =>
            // eslint-disable-next-line no-nested-ternary
            edge.source === oldNodeId
              ? { ...edge, source: newId, sourceHandle: edge.sourceHandle?.replace(oldNodeId, newId) }
              : edge.target === oldNodeId
                ? { ...edge, target: newId, targetHandle: edge.targetHandle?.replace(oldNodeId, newId) }
                : edge,
          ),
        ]),
      );

      // step 3: update node id in changesQueue, changes should replace new id so they can be applied in backend
      updateIdInChangesQueue(oldNodeId, newId);

      // step 4: update node id in template builder
      dispatch(updateNode({ nodeId: oldNodeId, newNodeId: newId as string }));
    },
    [dispatch, edges, nodes, updateIdInChangesQueue],
  );

  const updateEdgeId = useCallback(
    (oldEdgeId: string, newId: string) => {
      dispatch(
        setEdges([
          ...edges().map((edge) => (edge.id === oldEdgeId ? { ...edge, id: newId, data: { ...edge.data } } : edge)),
        ]),
      );

      // update edge id in changesQueue, changes should replace new id so they can be applied in backend
      updateIdInChangesQueue(oldEdgeId, newId);
    },
    [dispatch, edges, updateIdInChangesQueue],
  );

  const edgeBySourceHandleId = useCallback(
    (handleId: string) => {
      return edges().find((edge) => edge.sourceHandle === handleId);
    },
    [edges],
  );

  const isHandleConnected = useCallback(
    (handleType: HandleType) => {
      const handleId = createHandleId(handleType);
      return edgeBySourceHandleId(handleId) !== undefined;
    },
    [edgeBySourceHandleId],
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      // Overwrite edges
      // when one source handle is reconnected, remove old edge
      const edgesToBeOverwritten = changes
        .map((change) => {
          if (change.type !== 'add') return null;
          if (!change.item.sourceHandle) return null;
          const edgeToBeOverwritten = edgeBySourceHandleId(change.item.sourceHandle);
          if (!edgeToBeOverwritten) return null;
          return { id: edgeToBeOverwritten.id, type: 'remove' } as EdgeRemoveChange;
        })
        .filter((change) => !!change) as EdgeRemoveChange[];
      changes = [...changes, ...edgesToBeOverwritten];

      addEdgeChangesToQueue(changes);
      dispatch(setEdges(applyEdgeChanges(changes, edges())));
    },
    [addEdgeChangesToQueue, dispatch, edges, edgeBySourceHandleId],
  );

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      // if node is going to be deleted delete edges connected to it
      const nodesToRemove = changes.filter((change) => change.type === 'remove') as NodeRemoveChange[];
      const idsToRemove = nodesToRemove.map((change) => change.id);
      const edgesToRemove = edges().filter(
        (edge) => idsToRemove.includes(edge.source) || idsToRemove.includes(edge.target),
      );
      onEdgesChange(edgesToRemove.map((edge) => ({ type: 'remove', id: edge.id })));

      changes = onNodesChangeRequested(changes);

      // use react flow's internal function to apply changes to nodes
      dispatch(setNodes(applyNodeChanges(changes, nodes())));

      // filter out unnecessary properties of nodes before adding them to changeQueue for backend
      const changesToSend = produce(changes, (draft) => {
        draft.forEach((change) => {
          if (change.type === 'add') {
            change.item = filterOutNodeProperties(change.item);
          }
        });
        return draft;
      });
      // add any changes to changesQueue, changes queue will filter out according to changes types
      addNodeChangesToQueue(changesToSend);
    },
    [addNodeChangesToQueue, dispatch, edges, nodes, onEdgesChange, onNodesChangeRequested],
  );

  const selectNode = useCallback(
    (id: string) => {
      const selectionChanges: NodeSelectionChange[] = nodes().map((node) => ({
        id: node.id,
        type: 'select',
        selected: node.id === id,
      }));
      onNodesChange(selectionChanges);
    },
    [nodes, onNodesChange],
  );

  const resetSelection = useCallback(() => {
    const deselectChange: NodeSelectionChange[] = nodes().map((node) => ({
      id: node.id,
      type: 'select',
      selected: false,
    }));
    onNodesChange(deselectChange);
  }, [nodes, onNodesChange]);

  const setNodeAsGenerating = useCallback(
    (nodeId: string, isGenerating: boolean, aiMessage: string) => {
      if (nodeId === selectedNode?.id) {
        dispatch(cancel());
      }

      const node = nodes().find((n) => n.id === nodeId);
      if (!node || !node?.data?.v1?.messageTemplateInput?.templateComponents) return;

      onNodeObjectChange([
        {
          id: nodeId,
          item: produce(node, (draft: Node) => {
            draft.data.v1.isGenerating = isGenerating;
            draft.data.v1.templateId = '';
            draft.data.v1.messageTemplateInput.templateComponents = [
              ...(draft.data.v1.messageTemplateInput.templateComponents.filter((x: TemplateComponent) => !x.body) ||
                []),
              { body: { text: { text: aiMessage } } },
            ];
          }),
        },
      ]);
    },
    [dispatch, nodes, onNodeObjectChange, selectedNode?.id],
  );

  // to prevent changing document when document is not editable
  // use protected functions of onEdgesChange and onNodesChange
  const onEdgesChangeProtected = useMemo(
    () => (isDocumentEditable ? onEdgesChange : () => {}),
    [isDocumentEditable, onEdgesChange],
  );
  const onNodesChangeProtected = useMemo(
    () => (isDocumentEditable ? onNodesChange : () => {}),
    [isDocumentEditable, onNodesChange],
  );

  const createNodeAtHandle = useCallback(() => {}, []);
  const cleanAfterButtonIsDeleted = useCallback(() => {}, []);

  return {
    updateNodeId,
    updateEdgeId,
    onNodeObjectChange,
    onNodesChange,
    onNodesChangeProtected,
    onEdgesChange,
    onEdgesChangeProtected,
    selectNode,
    resetSelection,
    createNodeAtHandle,
    isHandleConnected,
    setNodeAsGenerating,
    cleanAfterButtonIsDeleted,
  };
};
