import React, { useState, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  DeleteOutlineIcon,
  Modal,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';
import { adminModalOpenAtom, adminBusinessIdAtom, adminBusinessListAtom } from '@atoms/app';
import { useAtomEffect } from '@hooks';
import { api } from '../../api';
import { SettingsResponse } from '../../hooks/useSettingsData/types';
import { CONNECTLY_BUSINESS_ID } from './constants';

export const AdminModal = () => {
  const queryClient = useQueryClient();
  const [adminModalOpen, setAdminModalOpen] = useAtom(adminModalOpenAtom);
  const [adminBusinessId, setAdminBusinessId] = useAtom(adminBusinessIdAtom);
  const [businesses, setBusinesses] = useAtom(adminBusinessListAtom);
  const [isLoading, setIsLoading] = useState(false);
  const dirtyBusiness = useRef(false);

  const addBusinesses = useCallback(
    async (businessIds: string[]) => {
      setIsLoading(true);
      let newBusinesses = await Promise.all(
        businessIds.map((businessId) =>
          queryClient.fetchQuery(['business', businessId], () => {
            return api
              .profileV1('/v1/businesses/{businessId}', 'get', { path: { businessId } })
              .then((data: SettingsResponse) => {
                return {
                  id: data?.entity?.id || '',
                  name: data?.entity?.name || '',
                };
              })
              .catch(() => {
                return {
                  id: '',
                  name: '',
                };
              });
          }),
        ),
      );
      newBusinesses = newBusinesses.filter((business) => !!business.id);
      if (!newBusinesses || !newBusinesses.length) {
        setIsLoading(false);
        return;
      }
      const newAdmin = newBusinesses[0].id;
      const updatedBusinesses = [
        ...businesses.filter((business) => !businessIds.includes(business.id)),
        ...newBusinesses,
      ];
      setBusinesses(updatedBusinesses);
      setAdminBusinessId(newAdmin);
      dirtyBusiness.current = true;
      setIsLoading(false);
    },
    [businesses, queryClient, setAdminBusinessId, setBusinesses],
  );

  const onClose = () => {
    setAdminModalOpen(false);
  };

  const deleteId = useCallback(
    (id: string) => {
      let updatedAdminId = adminBusinessId;
      if (adminBusinessId === id) {
        updatedAdminId = CONNECTLY_BUSINESS_ID;
        setAdminBusinessId(updatedAdminId);
        dirtyBusiness.current = true;
      }
      const updatedBusinesses = [...businesses.filter((business) => business.id !== id)];
      setBusinesses(updatedBusinesses);
    },
    [adminBusinessId, businesses, setAdminBusinessId, setBusinesses],
  );

  const currentValue = useMemo(() => adminBusinessId || CONNECTLY_BUSINESS_ID, [adminBusinessId]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setAdminBusinessId(value);
      dirtyBusiness.current = true;
    },
    [setAdminBusinessId],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const values = e.currentTarget.value
          .split(',')
          .map((v) => v.trim())
          .filter((v) => v);
        if (values) {
          e.currentTarget.value = '';
          addBusinesses(values);
        }
      }
    },
    [addBusinesses],
  );

  // when component disappears, refresh the page to apply the changes
  useAtomEffect(adminModalOpenAtom, (open) => {
    if (!open && dirtyBusiness.current) window.location.reload();
  });

  return (
    <Modal
      dialog={{ open: adminModalOpen, onClose, maxWidth: 'xl' }}
      title="Admin Functions"
      closeButton={{ onClick: onClose }}
    >
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">Select Business Account</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={currentValue}
          onChange={handleChange}
        >
          {businesses.map((business) => (
            <Box
              key={business.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel value={business.id} control={<Radio />} label={business.name} />
              {business.id !== CONNECTLY_BUSINESS_ID && (
                <IconButton size="small" onClick={() => deleteId(business.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
      <Input
        placeholder="Add Business Id(s)"
        disabled={isLoading}
        sx={{ display: 'block' }}
        onKeyDown={handleKeyDown}
      />
    </Modal>
  );
};
