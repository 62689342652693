import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.common.channel";
export var ChannelType;
(function (ChannelType) {
    ChannelType[ChannelType["CHANNEL_TYPE_UNSPECIFIED"] = 0] = "CHANNEL_TYPE_UNSPECIFIED";
    ChannelType[ChannelType["CHANNEL_TYPE_FB_MESSENGER"] = 1] = "CHANNEL_TYPE_FB_MESSENGER";
    ChannelType[ChannelType["CHANNEL_TYPE_SMS"] = 2] = "CHANNEL_TYPE_SMS";
    ChannelType[ChannelType["CHANNEL_TYPE_INSTAGRAM"] = 3] = "CHANNEL_TYPE_INSTAGRAM";
    ChannelType[ChannelType["CHANNEL_TYPE_CONNECTLY"] = 4] = "CHANNEL_TYPE_CONNECTLY";
    ChannelType[ChannelType["CHANNEL_TYPE_WHATSAPP"] = 5] = "CHANNEL_TYPE_WHATSAPP";
    ChannelType[ChannelType["CHANNEL_TYPE_EMAIL"] = 6] = "CHANNEL_TYPE_EMAIL";
    ChannelType[ChannelType["CHANNEL_TYPE_SHOPIFY"] = 7] = "CHANNEL_TYPE_SHOPIFY";
    ChannelType[ChannelType["CHANNEL_TYPE_WHATSAPP_CLOUD"] = 8] = "CHANNEL_TYPE_WHATSAPP_CLOUD";
    ChannelType[ChannelType["CHANNEL_TYPE_ZENDESK"] = 9] = "CHANNEL_TYPE_ZENDESK";
    ChannelType[ChannelType["CHANNEL_TYPE_NUBANK"] = 10] = "CHANNEL_TYPE_NUBANK";
    ChannelType[ChannelType["CHANNEL_TYPE_ALTOPAYMENT"] = 11] = "CHANNEL_TYPE_ALTOPAYMENT";
    ChannelType[ChannelType["CHANNEL_TYPE_CUSTOM_ECOMMERCE"] = 12] = "CHANNEL_TYPE_CUSTOM_ECOMMERCE";
    ChannelType[ChannelType["CHANNEL_TYPE_ALTOPAYMENT_STAGING"] = 13] = "CHANNEL_TYPE_ALTOPAYMENT_STAGING";
    ChannelType[ChannelType["CHANNEL_TYPE_VTEX"] = 14] = "CHANNEL_TYPE_VTEX";
    ChannelType[ChannelType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ChannelType || (ChannelType = {}));
export function channelTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "CHANNEL_TYPE_UNSPECIFIED":
            return ChannelType.CHANNEL_TYPE_UNSPECIFIED;
        case 1:
        case "CHANNEL_TYPE_FB_MESSENGER":
            return ChannelType.CHANNEL_TYPE_FB_MESSENGER;
        case 2:
        case "CHANNEL_TYPE_SMS":
            return ChannelType.CHANNEL_TYPE_SMS;
        case 3:
        case "CHANNEL_TYPE_INSTAGRAM":
            return ChannelType.CHANNEL_TYPE_INSTAGRAM;
        case 4:
        case "CHANNEL_TYPE_CONNECTLY":
            return ChannelType.CHANNEL_TYPE_CONNECTLY;
        case 5:
        case "CHANNEL_TYPE_WHATSAPP":
            return ChannelType.CHANNEL_TYPE_WHATSAPP;
        case 6:
        case "CHANNEL_TYPE_EMAIL":
            return ChannelType.CHANNEL_TYPE_EMAIL;
        case 7:
        case "CHANNEL_TYPE_SHOPIFY":
            return ChannelType.CHANNEL_TYPE_SHOPIFY;
        case 8:
        case "CHANNEL_TYPE_WHATSAPP_CLOUD":
            return ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD;
        case 9:
        case "CHANNEL_TYPE_ZENDESK":
            return ChannelType.CHANNEL_TYPE_ZENDESK;
        case 10:
        case "CHANNEL_TYPE_NUBANK":
            return ChannelType.CHANNEL_TYPE_NUBANK;
        case 11:
        case "CHANNEL_TYPE_ALTOPAYMENT":
            return ChannelType.CHANNEL_TYPE_ALTOPAYMENT;
        case 12:
        case "CHANNEL_TYPE_CUSTOM_ECOMMERCE":
            return ChannelType.CHANNEL_TYPE_CUSTOM_ECOMMERCE;
        case 13:
        case "CHANNEL_TYPE_ALTOPAYMENT_STAGING":
            return ChannelType.CHANNEL_TYPE_ALTOPAYMENT_STAGING;
        case 14:
        case "CHANNEL_TYPE_VTEX":
            return ChannelType.CHANNEL_TYPE_VTEX;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ChannelType.UNRECOGNIZED;
    }
}
export function channelTypeToJSON(object) {
    switch (object) {
        case ChannelType.CHANNEL_TYPE_UNSPECIFIED:
            return "CHANNEL_TYPE_UNSPECIFIED";
        case ChannelType.CHANNEL_TYPE_FB_MESSENGER:
            return "CHANNEL_TYPE_FB_MESSENGER";
        case ChannelType.CHANNEL_TYPE_SMS:
            return "CHANNEL_TYPE_SMS";
        case ChannelType.CHANNEL_TYPE_INSTAGRAM:
            return "CHANNEL_TYPE_INSTAGRAM";
        case ChannelType.CHANNEL_TYPE_CONNECTLY:
            return "CHANNEL_TYPE_CONNECTLY";
        case ChannelType.CHANNEL_TYPE_WHATSAPP:
            return "CHANNEL_TYPE_WHATSAPP";
        case ChannelType.CHANNEL_TYPE_EMAIL:
            return "CHANNEL_TYPE_EMAIL";
        case ChannelType.CHANNEL_TYPE_SHOPIFY:
            return "CHANNEL_TYPE_SHOPIFY";
        case ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD:
            return "CHANNEL_TYPE_WHATSAPP_CLOUD";
        case ChannelType.CHANNEL_TYPE_ZENDESK:
            return "CHANNEL_TYPE_ZENDESK";
        case ChannelType.CHANNEL_TYPE_NUBANK:
            return "CHANNEL_TYPE_NUBANK";
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT:
            return "CHANNEL_TYPE_ALTOPAYMENT";
        case ChannelType.CHANNEL_TYPE_CUSTOM_ECOMMERCE:
            return "CHANNEL_TYPE_CUSTOM_ECOMMERCE";
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT_STAGING:
            return "CHANNEL_TYPE_ALTOPAYMENT_STAGING";
        case ChannelType.CHANNEL_TYPE_VTEX:
            return "CHANNEL_TYPE_VTEX";
        case ChannelType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseChannelIdentifier() {
    return { id: "", externalId: "", channelType: 0 };
}
export const ChannelIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.externalId !== "") {
            writer.uint32(18).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(24).int32(message.channelType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChannelIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.externalId = reader.string();
                    break;
                case 3:
                    message.channelType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        return obj;
    },
};
function createBaseChannelSelector() {
    return { predicateOneof: undefined };
}
export const ChannelSelector = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byId") {
            ChannelSelector_ByID.encode(message.predicateOneof.byId, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.$case) === "byTypeAndExternalId") {
            ChannelSelector_ByTypeAndExternalID.encode(message.predicateOneof.byTypeAndExternalId, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChannelSelector();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.predicateOneof = {
                        $case: "byId",
                        byId: ChannelSelector_ByID.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.predicateOneof = {
                        $case: "byTypeAndExternalId",
                        byTypeAndExternalId: ChannelSelector_ByTypeAndExternalID.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            predicateOneof: isSet(object.byId)
                ? { $case: "byId", byId: ChannelSelector_ByID.fromJSON(object.byId) }
                : isSet(object.byTypeAndExternalId)
                    ? {
                        $case: "byTypeAndExternalId",
                        byTypeAndExternalId: ChannelSelector_ByTypeAndExternalID.fromJSON(object.byTypeAndExternalId),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byId" &&
            (obj.byId = ((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.byId)
                ? ChannelSelector_ByID.toJSON((_c = message.predicateOneof) === null || _c === void 0 ? void 0 : _c.byId)
                : undefined);
        ((_d = message.predicateOneof) === null || _d === void 0 ? void 0 : _d.$case) === "byTypeAndExternalId" &&
            (obj.byTypeAndExternalId = ((_e = message.predicateOneof) === null || _e === void 0 ? void 0 : _e.byTypeAndExternalId)
                ? ChannelSelector_ByTypeAndExternalID.toJSON((_f = message.predicateOneof) === null || _f === void 0 ? void 0 : _f.byTypeAndExternalId)
                : undefined);
        return obj;
    },
};
function createBaseChannelSelector_ByID() {
    return { id: "" };
}
export const ChannelSelector_ByID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChannelSelector_ByID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseChannelSelector_ByTypeAndExternalID() {
    return { externalId: "", channelType: 0 };
}
export const ChannelSelector_ByTypeAndExternalID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.externalId !== "") {
            writer.uint32(10).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChannelSelector_ByTypeAndExternalID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.externalId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
