import { CellValue, TableHeadCell, Order, sortString } from '@components/EnhancedTable';
import { RoleDict } from './types';

export const ROLE_DICT: RoleDict = {
  ROLE_OWNER: {
    id: 'ROLE_OWNER',
    display: 'Owner',
    description: 'Full access (including API key and adding new users)',
  },
  ROLE_MARKETING: {
    id: 'ROLE_MARKETING',
    display: 'Marketing',
    description: 'Full access (excluding API key and adding new users)',
  },
  ROLE_AGENT: {
    id: 'ROLE_AGENT',
    display: 'Agent',
    description: 'Only inbox access',
  },
  ROLE_UNSPECIFIED: {
    id: 'ROLE_UNSPECIFIED',
    display: 'Agent',
    description: 'Only inbox access',
  },
};

const roleOrderList = Object.keys(ROLE_DICT);

const sortRole =
  (order: Order) =>
  (a: CellValue, b: CellValue): number => {
    if (typeof a === 'string' && typeof b === 'string') {
      const aIndex = roleOrderList.indexOf(a);
      const bIndex = roleOrderList.indexOf(b);
      return order === 'asc' ? aIndex - bIndex : bIndex - aIndex;
    }
    return 0;
  };

export const TABLE_HEAD: TableHeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    width: '100%',
    sort: sortString,
  },
  {
    id: 'role',
    label: 'Role',
    width: '4rem',
    sort: sortRole,
  },
];
