import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import {
  QUERY_REACT_FLOW_DOCUMENT_VERSIONS_URL,
  QueryReactFlowDocumentVersionsOptions,
  QueryReactFlowDocumentVersionsInput,
} from './types';

export const useQueryFlowDocumentVersions = (
  input: QueryReactFlowDocumentVersionsInput,
  options?: QueryReactFlowDocumentVersionsOptions,
) =>
  useQuery(
    ['flow_document_versions', { flowDocumentId: input.flowDocumentId || '' }],
    () =>
      api.flowV1(QUERY_REACT_FLOW_DOCUMENT_VERSIONS_URL, 'post', {
        path: { 'query.businessId': input.businessId },
        body: { query: { ...input } },
      }),
    options,
  );
