import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';
import {
  Divider,
  useTheme,
  Box,
  FormControlLabel,
  Label,
  Link,
  Radio,
  Stack,
  useMediaQuery,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { selectQueryText, CohortSize, CreateAudienceModal, AudienceType } from '@scenes/Audience';
import { audienceAtom } from '@atoms/flow';
import { selectedPhoneListAudienceAtom, selectedSmartAudienceAtom, cohortsGeneratingAtom } from '@atoms/audience';
import {
  useAudienceSegmentsData,
  useMeData,
  selectBusinessId,
  useAudienceSegmentCohorts,
  selectPhoneListSegments,
  selectLatestCohort,
  AudienceSegment,
  useFeatureFlag,
  useAnalytics,
  selectMostEngagedSegmentOptions,
  selectMostEngagedSegments,
  selectSegmentDisplayName,
  selectEngagedSegmentQueryView,
  useAtomEffect,
  useQuerySendoutV3,
  selectAudienceId,
} from '../../../../hooks';
import { AudienceOptionType, AudienceOptions } from './types';
import { WithAnalytics } from '../../../WithAnalytics';
import { useCampaignV3 } from '../../hooks/useCampaignV3';
import { useCampaignId } from '../../hooks/useCampaignId';
import { AudienceSelectorSection } from './AudienceSelectorSection';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';

const useChooseAudience = () => {
  const theme = useTheme();
  const [audienceTypes, setAudienceTypes] = useState<AudienceType[]>([]);
  const isMediaMd = useMediaQuery(theme.breakpoints.down('lg'));
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { handleSetAudience, handleEmptyAudience, handleUpdateCohort } = useCampaignV3();
  const { campaignId, isLoading: isLoadingCampaignId } = useCampaignId();
  const { data: storedAudienceId } = useQuerySendoutV3({
    businessId: businessId || '',
    input: {
      byCampaignConfigurationId: {
        campaignConfigurationId: campaignId,
      },
    },
    enabled: !!businessId && !!campaignId,
    select: selectAudienceId,
  });
  const { data: phoneListSegments = [] } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectPhoneListSegments, enabled: !!businessId },
  );

  // todo replace this
  const { data: mostEngagedSegments, isLoading: isLoadingSmartSegments } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectMostEngagedSegmentOptions, enabled: !!businessId },
  );

  const { data: engagedSegments } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectMostEngagedSegments, enabled: !!businessId },
  );
  const [selectedPhoneListAudienceSegmentId, setSelectedPhoneListAudienceSegmentId] =
    useAtom(selectedPhoneListAudienceAtom);
  const [selectedSmartAudienceSegmentId, setSelectedSmartAudienceSegmentId] = useAtom(selectedSmartAudienceAtom);
  const setCohortsGenerating = useSetAtom(cohortsGeneratingAtom);
  const { data: mostEngagedCohort, isLoading: isLoadingCohort } = useAudienceSegmentCohorts(
    { businessId: businessId || '', segmentId: selectedSmartAudienceSegmentId },
    {
      select: selectLatestCohort,
      enabled: Boolean(businessId && selectedSmartAudienceSegmentId),
      refetchInterval: (fetchedData, _) => {
        if (fetchedData?.id && fetchedData?.segmentId) {
          setCohortsGenerating((prev: string[]) => prev.filter((id: string) => id !== fetchedData.segmentId));
          return false;
        }
        return 5000;
      },
    },
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const selectedQuery = useMemo(() => {
    const segment = engagedSegments?.find((item) => item?.segment?.id === selectedSmartAudienceSegmentId);
    if (!segment) {
      return '';
    }
    const queryView = selectEngagedSegmentQueryView(segment);
    if (!queryView) {
      return '';
    }
    return selectQueryText({ queryView });
  }, [engagedSegments, selectedSmartAudienceSegmentId]);
  const [audience, setAudience] = useAtom(audienceAtom);
  const { type: audienceType, [AudienceOptionType.AUDIENCE]: audienceInfo } = audience;
  const { segmentId: audienceSegmentId } = audienceInfo;
  const { sendAnalytics } = useAnalytics();
  const handleUseAudienceChange = useCallback(
    (option: AudienceOptionType, checked: boolean, selectionType?: 'phoneList' | 'dynamicV2', segmentId?: string) => {
      if (!checked) {
        return;
      }
      segmentId = segmentId || '';
      let type: AudienceOptionType = AudienceOptionType.AUDIENCE;
      if (!selectionType) {
        type = option;
        segmentId = segmentId || audienceSegmentId;
      } else if (selectionType === 'phoneList') {
        if (segmentId) {
          setSelectedPhoneListAudienceSegmentId(segmentId);
        } else {
          segmentId = selectedPhoneListAudienceSegmentId;
        }
      } else if (selectionType === 'dynamicV2') {
        if (segmentId) {
          setSelectedSmartAudienceSegmentId(segmentId);
        } else {
          segmentId = selectedSmartAudienceSegmentId;
        }
        sendAnalytics('(flow) select smart audience', { businessId, segmentId });
      }
      setAudience({
        type,
        [AudienceOptionType.AUDIENCE]: { segmentId },
      });
      handleUpdateCohort(AudienceOptions[type].inputType);
      if (type === AudienceOptionType.AUDIENCE) {
        if (segmentId) {
          handleSetAudience(segmentId);
        }
      } else {
        handleEmptyAudience();
      }
    },
    [
      audienceSegmentId,
      businessId,
      handleEmptyAudience,
      handleSetAudience,
      handleUpdateCohort,
      selectedPhoneListAudienceSegmentId,
      selectedSmartAudienceSegmentId,
      sendAnalytics,
      setAudience,
      setSelectedPhoneListAudienceSegmentId,
      setSelectedSmartAudienceSegmentId,
    ],
  );
  // When this component is mounted, once the current audience settings are loaded, initialize the UI state
  useEffect(() => {
    if (storedAudienceId && !isLoadingCampaignId) {
      if (phoneListSegments.find((item) => item?.segment?.id === storedAudienceId)) {
        handleUseAudienceChange(AudienceOptionType.AUDIENCE, true, 'phoneList', storedAudienceId);
      } else if (
        engagedSegments?.find((item) => item?.segment?.id === storedAudienceId) ||
        mostEngagedSegments?.find((item) => item?.id === storedAudienceId)
      ) {
        handleUseAudienceChange(AudienceOptionType.AUDIENCE, true, 'dynamicV2', storedAudienceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedAudienceId, isLoadingCampaignId]);
  const queryClient = useQueryClient();
  // When this component is unmounted, invalidate the query to ensure the latest audience settings are fetched
  // the next time this component is mounted.
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([
        'sendoutV3',
        { businessId, input: { byCampaignConfigurationId: { campaignConfigurationId: campaignId } } },
      ]);
      // for some reason, the query key is not being refatched when the component remounts.
      // Calling refetchQueries here fixes that
      queryClient.refetchQueries([
        'sendoutV3',
        { businessId, input: { byCampaignConfigurationId: { campaignConfigurationId: campaignId } } },
      ]);
    };
  }, []);

  const handleSelectSegment = useCallback(
    (segmentId: string) => {
      setAudience({
        type: AudienceOptionType.AUDIENCE,
        [AudienceOptionType.AUDIENCE]: { segmentId },
      });
      handleUpdateCohort(AudienceOptions[AudienceOptionType.AUDIENCE].inputType);
      handleSetAudience(segmentId);
    },
    [setAudience, handleSetAudience, handleUpdateCohort],
  );

  const { phoneListChecked, smartChecked } = useMemo(() => {
    const isAudienceType = audienceType === AudienceOptionType.AUDIENCE;
    return {
      phoneListChecked: isAudienceType && !!phoneListSegments.find((item) => item?.segment?.id === audienceSegmentId),
      smartChecked: isAudienceType && !!engagedSegments?.find((item) => item?.segment?.id === audienceSegmentId),
    };
  }, [audienceSegmentId, audienceType, engagedSegments, phoneListSegments]);
  useAtomEffect(selectedPhoneListAudienceAtom, (id) => {
    if (id && phoneListChecked) {
      handleSelectSegment(id);
    }
  });
  useAtomEffect(selectedSmartAudienceAtom, (id) => {
    if (id && smartChecked) {
      handleSelectSegment(id);
    }
  });

  const handleCreateSuccess = useCallback(
    (value: AudienceSegment) => {
      const segmentId = value.segment?.id;
      if (segmentId) {
        handleSelectSegment(segmentId);
      }
    },
    [handleSelectSegment],
  );
  const getSmartAudienceOptionLabel = useCallback(
    (option: AudienceSegment) => {
      const segmentName = option.segment?.name || '';
      return t(`flow.chooseAudience.smartAudience.segmentNames.${segmentName}`) || segmentName;
    },
    [t],
  );
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(
    (type: AudienceType) => {
      setAudienceTypes([type]);
      setModalOpen(true);
    },
    [setModalOpen],
  );
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const createAudienceText = useMemo(() => {
    return t('audience.manage.smartAudience.createAudience');
  }, [t]);

  return {
    audienceType,
    audienceTypes,
    businessId,
    closeModal,
    createAudienceText,
    engagedSegments,
    getSmartAudienceOptionLabel,
    handleUseAudienceChange,
    isLoadingCampaignId,
    isLoadingCohort,
    isLoadingSmartSegments,
    isMediaMd,
    modalOpen,
    mostEngagedCohort,
    mostEngagedSegments,
    onCreateSuccess: handleCreateSuccess,
    openModal,
    phoneListChecked,
    phoneListSegments,
    selectedQuery,
    selectedSmartAudienceSegmentId,
    smartChecked,
    theme,
  };
};

export const ChooseAudience = () => {
  const {
    audienceType,
    audienceTypes,
    businessId,
    closeModal,
    createAudienceText,
    handleUseAudienceChange,
    isLoadingCampaignId,
    isLoadingCohort,
    isLoadingSmartSegments,
    modalOpen,
    mostEngagedCohort,
    mostEngagedSegments,
    openModal,
    phoneListChecked,
    selectedQuery,
    selectedSmartAudienceSegmentId,
    smartChecked,
    theme,
  } = useChooseAudience();
  const { ffEnableSmartAudiences } = useFeatureFlag(['ffEnableSmartAudiences']);
  return (
    <>
      <CreateAudienceModal
        businessId={businessId || ''}
        isOpen={modalOpen}
        closeModal={closeModal}
        audienceTypes={audienceTypes}
      />
      <Label variant="h6" gutterBottom>
        Choose Audience
      </Label>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="body1" sx={{ fontWeight: 'bold' }}>
                Create a new sheet
              </Label>
            }
            checked={audienceType === AudienceOptionType.SPREADSHEET}
            onChange={(_, checked) => handleUseAudienceChange(AudienceOptionType.SPREADSHEET, checked)}
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
            disabled={isLoadingCampaignId}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1 }}>
            Fill out a Google sheet with the recipient details.
          </Label>
        </Box>
        {ffEnableSmartAudiences && (mostEngagedSegments?.length || isLoadingSmartSegments) && (
          <AudienceSelectorSection
            title="Most engaged customers"
            id="audience-smart-segment-selector"
            inputPlaceholder="Smart Audience"
            businessId={businessId || ''}
            checked={smartChecked}
            onCheckedChange={(_, checked) => handleUseAudienceChange(AudienceOptionType.AUDIENCE, checked, 'dynamicV2')}
            getOptionLabel={selectSegmentDisplayName}
            audienceType="dynamicV2"
            isLoading={isLoadingSmartSegments || isLoadingCampaignId}
          >
            <WithSkeleton wrap={isLoadingSmartSegments || isLoadingCampaignId}>
              <Label variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                {selectedQuery}
              </Label>
            </WithSkeleton>
            <Stack divider={<Divider />} gap={SIZE_SPACING_INTER_COMPONENT}>
              <CohortSize
                businessId={businessId}
                segmentId={selectedSmartAudienceSegmentId}
                cohort={mostEngagedCohort}
                isLoading={isLoadingCohort}
              />
              <WithAnalytics
                type="click new_smart_audience_button"
                data={{
                  businessId,
                  from: 'flow',
                }}
              >
                <WithSkeleton wrap={isLoadingSmartSegments || isLoadingCampaignId}>
                  <Link
                    underline="none"
                    onClick={() => openModal('query')}
                    variant="body2"
                    sx={{
                      cursor: 'pointer',
                      alignSelf: 'flex-start',
                      mt: 1,
                    }}
                  >
                    {createAudienceText}
                  </Link>
                </WithSkeleton>
              </WithAnalytics>
            </Stack>
          </AudienceSelectorSection>
        )}
        <AudienceSelectorSection
          title="Use a saved audience"
          id="audience-saved-segment-selector"
          inputPlaceholder="Select Saved Audience"
          businessId={businessId || ''}
          checked={phoneListChecked}
          onCheckedChange={(_, checked) => handleUseAudienceChange(AudienceOptionType.AUDIENCE, checked, 'phoneList')}
          audienceType="phoneList"
          isLoading={isLoadingCampaignId}
        >
          <WithAnalytics
            type="click new_audience_button"
            data={{
              businessId,
              from: 'flow',
            }}
          >
            <WithSkeleton wrap={isLoadingCampaignId}>
              <Link
                underline="none"
                onClick={() => openModal('spreadsheet')}
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                  mt: 1,
                }}
              >
                Create New Audience
              </Link>
            </WithSkeleton>
          </WithAnalytics>
        </AudienceSelectorSection>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="body1" sx={{ fontWeight: 'bold' }}>
                Use the Connectly API
              </Label>
            }
            checked={audienceType === AudienceOptionType.API}
            onChange={(_, checked) => handleUseAudienceChange(AudienceOptionType.API, checked)}
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
            disabled={isLoadingCampaignId}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1 }}>
            Send campaign requests through our api.
          </Label>
        </Box>
      </Box>
    </>
  );
};
