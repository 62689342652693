import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

if (!i18n.isInitialized) {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(Backend)
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      supportedLngs: ['en', 'es', 'pt'],
      fallbackLng: 'en',
      nonExplicitSupportedLngs: true,
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // eslint-disable-next-line max-len
      returnNull: false, // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).i18n = i18n;

export default i18n;
