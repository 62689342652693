import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@connectlyai-idl/models/dist/models/error/code';
import { setInboxSettings, setUserProfile } from './action';
export const fetchUser = createAsyncThunk('user/me', async (di, { rejectWithValue }) => {
    var _a, _b;
    const queryMe = di.queryMeProvider();
    const getAuthTokenFunc = di.authTokenProvider();
    try {
        const userPresentation = await queryMe();
        const accessToken = await getAuthTokenFunc();
        return {
            dto: userPresentation,
            accessToken,
        };
    }
    catch (err) {
        const response = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data;
        if ((response === null || response === void 0 ? void 0 : response.code) === ErrorCode.ERROR_CODE_UNAUTHORIZED_USER && ((_b = response === null || response === void 0 ? void 0 : response.details) === null || _b === void 0 ? void 0 : _b.email)) {
            const rejectedValue = {
                method: 'sso',
                email: response.details.email,
            };
            return rejectWithValue(rejectedValue);
        }
        throw err;
    }
});
export const updateInboxSettings = createAsyncThunk('user/updateInboxSettings', async (arg, { dispatch }) => {
    var _a;
    const mutationUpdateInboxSettings = arg.di.mutationUpdateInboxSettingsProvider();
    const inputSettingsV1 = (_a = arg.input.inboxSettingsInputOneof) === null || _a === void 0 ? void 0 : _a.v1;
    if (inputSettingsV1) {
        dispatch(setInboxSettings({
            inboxSettingsOneof: {
                $case: 'v1',
                v1: inputSettingsV1,
            },
        }));
    }
    await mutationUpdateInboxSettings(arg.input);
});
export const updateProfile = createAsyncThunk('user/updateProfile', async (arg, { dispatch }) => {
    const mutationUpdateUserProfile = arg.di.mutationUpdateProfileProvider();
    if (arg.input.displayName) {
        await mutationUpdateUserProfile(arg.input);
        dispatch(setUserProfile(arg.input));
    }
});
