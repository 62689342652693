import { useCallback, useEffect, useMemo, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { Node } from 'react-flow-renderer';
import { Subject } from 'rxjs';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import { uniq, startCase, lowerCase, flatten, camelCase, upperFirst, toLower } from 'lodash';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useCenterToNode } from '@components/FlowChartCampaignV3/hooks/useCenterToNode';
import { useAtom, useAtomValue } from 'jotai';
import {
  aiConversationAtom,
  aiStateAtom,
  campaignLanguageAtom,
  campaignTemplateAtom,
  customSendoutNodeAtom,
  edgesAtom,
  isAISidebarOpenAtom,
  nodesAtom,
} from '@atoms/flow';
import { useGetAtom } from '@hooks/useGetAtom';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import {
  selectNodes,
  selectAiState,
  selectIsAISidebarOpen,
  selectCampaignSendNode,
  selectCampaignTemplate,
  setAiState as setAiStateRedux,
  setIsAISidebarOpen as setIsAISidebarOpenRedux,
  selectAiConversation,
  setAiConversation as setAiConversationRedux,
  selectLanguage,
  selectEdges,
} from '../../state/flow';
import { setMessageTemplateFromNode } from '../../state/messageTemplates';
import { NodeType } from '../../sdks/flow/createNode';
import { createEdge } from '../../sdks/flow/createEdge';
import { createHandleId } from '../../sdks/flow/createHandleId';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';
import { accessTokenService } from '../../api';
import { aiTemplateEdges, aiTemplates, formPlaceholders, PreNodeData } from './aiTemplates';
import { aiTemplatesOld } from './aiTemplatesOld';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import appStore from '../../state/store';
import { AIResponse, AIState, NodeAnimationState, AIMessage } from './types';
import { buttonLocalization } from './localization';
import { webpageToProduct } from './webpageToProduct';
import { normalizeUrl } from '../../presentation/preview/utils';
import { getLanguage } from '../../utils';
import { TemplateComponent } from '../useMessageTemplateGroupsData';
import { updateUnsentMessageTemplate } from '../useCampaignFromPreview';

// these imports should change after deprecating non v3 flow builder for campaigns
// eslint-disable-next-line max-len
import { useCampaignFlowChangeAppliers as useCampaignFlowChangeAppliersOld } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { useCreateNode as useCreateNodeOld } from '../useCreateNode';
// eslint-disable-next-line max-len
import { useCampaignFlowChangeAppliers as useCampaignFlowChangeAppliersV3 } from '../../components/FlowChartCampaignV3/hooks/useCampaignFlowChangeAppliers';
import { useCreateNode as useCreateNodeV3 } from '../../components/FlowChartCampaignV3/hooks/useCreateNode';

import { useFlowVariables, FlowVariableExtended } from '../useFlowVariables';

export const typingObservable = new Subject<string>();
const aiResponseObservable = new Subject<AIResponse>();
const defaultAnimationState = [
  {
    index: 0,
    nodeId: '',
    nodeExist: false,
    startedTyping: false,
    finishedTyping: false,
  },
  {
    index: 1,
    nodeId: '',
    nodeExist: false,
    startedTyping: false,
    finishedTyping: false,
  },
  {
    index: 2,
    nodeId: '',
    nodeExist: false,
    startedTyping: false,
    finishedTyping: false,
  },
];
// Temporary hook to get, set state for ai campaign builder
// Should be deleted after V3 is fully released without feature flag
export const useStateForAICampaign = () => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);

  const [aiConversation, setAiConversation] = useAtom(aiConversationAtom);
  const [aiState, setAiState] = useAtom(aiStateAtom);
  const [isAISidebarOpen, setIsAISidebarOpen] = useAtom(isAISidebarOpenAtom);
  const campaignLanguage = useAtomValue(campaignLanguageAtom);
  const campaignTemplate = useAtomValue(campaignTemplateAtom);
  const customSendoutNode = useAtomValue(customSendoutNodeAtom);
  const getEdges = useGetAtom(edgesAtom);
  const getNodes = useGetAtom(nodesAtom);

  const dispatch = useDispatch();

  const aiConversationRedux = useSelector(selectAiConversation);
  const aiStateRedux = useSelector(selectAiState);
  const campaignSendNodeRedux = useSelector(selectCampaignSendNode);
  const campaignTemplateRedux = useSelector(selectCampaignTemplate);
  const edgesCurrentRedux = useCallback(() => selectEdges(appStore.getState()), []);
  const isAISidebarOpenRedux = useSelector(selectIsAISidebarOpen);
  const languageCodeRedux = useSelector(selectLanguage);
  const nodesCurrentRedux = useCallback(() => selectNodes(appStore.getState()), []);
  const setAiConversationReduxDispatch = useCallback(
    (c: AIMessage[]) => dispatch(setAiConversationRedux(c)),
    [dispatch],
  );
  const setAiStateReduxDispatch = useCallback((s: AIState) => dispatch(setAiStateRedux(s)), [dispatch]);
  const setIsAISidebarOpenReduxDispatch = useCallback(
    (open: boolean) => dispatch(setIsAISidebarOpenRedux(open)),
    [dispatch],
  );

  return {
    aiConversation: ffEnableETA ? aiConversation : aiConversationRedux,
    aiState: ffEnableETA ? aiState : aiStateRedux,
    campaignSendNode: ffEnableETA ? customSendoutNode : campaignSendNodeRedux,
    campaignTemplate: ffEnableETA ? campaignTemplate : campaignTemplateRedux,
    edgesCurrent: ffEnableETA ? getEdges : edgesCurrentRedux,
    isAISidebarOpen: ffEnableETA ? isAISidebarOpen : isAISidebarOpenRedux,
    languageCode: ffEnableETA ? campaignLanguage : languageCodeRedux,
    nodesCurrent: ffEnableETA ? getNodes : nodesCurrentRedux,
    setAiConversation: ffEnableETA ? setAiConversation : setAiConversationReduxDispatch,
    setAiState: ffEnableETA ? setAiState : setAiStateReduxDispatch,
    setIsAISidebarOpen: ffEnableETA ? setIsAISidebarOpen : setIsAISidebarOpenReduxDispatch,
  };
};

export const useAICampaign = () => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const { networkClientProvider, notificationServiceProvider } = useContext(FlowDIContext) as FlowDIContextProps;
  const useCampaignFlowChangeAppliers = ffEnableETA
    ? useCampaignFlowChangeAppliersV3
    : useCampaignFlowChangeAppliersOld;

  const useCreateNode = ffEnableETA ? useCreateNodeV3 : useCreateNodeOld;
  const { createNode } = useCreateNode();

  const { onNodesChange, onNodeObjectChange, onEdgesChange, resetSelection } = useCampaignFlowChangeAppliers();

  const centerToNodeOld = useContextSelector(FlowCampaignContext, (context) => context?.centerToNode);
  const { centerToNode: centerToNodeV3 } = useCenterToNode();
  const centerToNode = ffEnableETA ? centerToNodeV3 : centerToNodeOld;

  const customSendoutNode = useAtomValue(customSendoutNodeAtom);
  const { setFlowVariables } = useFlowVariables();

  const {
    aiState,
    campaignSendNode,
    campaignTemplate,
    edgesCurrent,
    isAISidebarOpen,
    languageCode,
    nodesCurrent,
    setAiConversation,
    setAiState,
    setIsAISidebarOpen,
  } = useStateForAICampaign();

  const language = useMemo(() => getLanguage(languageCode), [languageCode]);

  const reactFlowInstance = useContextSelector(FlowCampaignContext, (context) => context?.reactFlowInstance);
  const { mutateAsync: generateAIMessage } = useMutation({
    mutationFn: async (conversation: AIMessage[]) =>
      networkClientProvider()
        .post(
          `${process.env.REACT_APP_INBOX_API_GATEWAY}/proxy/open_ai/v1/chat/completions`,
          {
            model: 'gpt-3.5-turbo',
            messages: conversation,
            temperature: 0.3,
            max_tokens: 200,
          },
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await accessTokenService.tempSolution()}`,
            },
            timeout: 10000,
          },
        )
        .then((response) => response.data?.choices[0]?.message?.content)
        // remove hashtags
        .then((text) => text.replace(/\s#\w+/g, ''))
        // add a line break after each sentence (emojis on line end denoted by \W
        // and variables like {{1}} should stay on previous line)
        .then((text) => text.replace(/((\.|!|\?)+( *\d*\W)*\s*)/gu, '$1\n\n'))
        // make line breaks double
        .then((text) => text.replace(/\n+/g, '\n\n'))
        // remove line breaks from start and end of the message text
        .then((text) => text.replace(/^([\r\n]+)+|([\r\n]+)+$/g, ''))
        // remove dots from start of text
        .then((text) => text.replace(/^\.*([\r\n])*/g, '')),
    retry: 10,
  });
  // is used when regenerating ONLY ONE MESSAGE in a created campaign
  const generateAIMessageFor = useCallback(
    (prompt: string) => generateAIMessage([{ role: 'user', content: prompt }]),
    [generateAIMessage],
  );

  const nodeAnimations = useRef<NodeAnimationState[]>(defaultAnimationState);
  const aiResponses = useRef<AIResponse[]>([]);
  const preNodeData = useRef<PreNodeData[]>([]);

  const hideCampaignForAI = useMemo(() => isAISidebarOpen && aiState === 'planning', [isAISidebarOpen, aiState]);

  const dispatch = useDispatch();

  const animateExistingNode = useCallback(
    (animationIndex: number, nodeId: string, aiResponse: AIResponse) => {
      const node = nodesCurrent().find((n) => n.id === nodeId);
      if (!node) return;
      const nodeWithMessage = produce(node, (draft) => {
        const { messageTemplateInput } = draft.data.v1;
        if (messageTemplateInput.templateComponents)
          messageTemplateInput.templateComponents = messageTemplateInput?.templateComponents?.map(
            (component: TemplateComponent) =>
              component.body ? { body: { text: { text: aiResponse.response } } } : component,
          );
        draft.data.v1.prompt = aiResponse.prompt;
        draft.data.v1.isGenerating = false;
        return draft;
      });
      onNodeObjectChange([
        {
          id: nodeWithMessage.id,
          item: nodeWithMessage,
        },
      ]);
      dispatch(setMessageTemplateFromNode({ node: nodeWithMessage }));

      // load unsent template into template slice
      updateUnsentMessageTemplate(nodeWithMessage, dispatch);

      nodeAnimations.current = produce(nodeAnimations.current, (draft) => {
        const nodeAnimation = draft.find((nAnimation) => nAnimation.index === animationIndex);
        if (!nodeAnimation) return draft;
        nodeAnimation.startedTyping = true;
        return draft;
      });
    },
    [dispatch, nodesCurrent, onNodeObjectChange],
  );

  const finishedAnimation = useCallback(() => {
    setAiState('completed');
    setTimeout(() => {
      reactFlowInstance?.fitView({ duration: 1000 });
    }, 100);

    // following part is to add variables to sendout node, only for flow campaigns v3
    if (!customSendoutNode) return;

    const enterVariables: FlowVariableExtended[] = [
      {
        id: uuidv4(),
        type: 'FLOW_TYPE_STRING',
        namespace: '',
        namespaceCanonical: '',
        simpleName: 'name',
        simpleNameCanonical: 'name',
        validators: [],
        initializer: undefined,
      },
    ];
    setFlowVariables(enterVariables);

    const newCustomSendoutNode = produce(customSendoutNode, (draft) => {
      if (!draft.data.v3) draft.data.v3 = {};
      draft.data.v3.enterVariables = enterVariables;
      return draft;
    });
    onNodeObjectChange([
      {
        id: newCustomSendoutNode.id,
        item: newCustomSendoutNode,
      },
    ]);
  }, [setAiState, reactFlowInstance, customSendoutNode, onNodeObjectChange, setFlowVariables]);

  const createNodeMapSessionType = useCallback(
    (node: PreNodeData) => {
      let sessionType: NodeType;
      switch (node.data.v1.type) {
        case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE':
          sessionType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE';
          break;
        case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE':
          sessionType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE';
          break;
        default:
          sessionType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
          break;
      }
      return createNode(sessionType, node) as Node;
    },
    [createNode],
  );

  const createGeneratingNode = useCallback(
    (animationIndex: number) => {
      const preNode = preNodeData.current[animationIndex];
      const node = createNodeMapSessionType(preNode);
      onNodesChange([{ type: 'add', item: node }]);
      nodeAnimations.current = produce(nodeAnimations.current, (draft) => {
        const animation = draft.find((nodeAnim) => nodeAnim.index === animationIndex);
        if (!animation) return draft;
        animation.nodeExist = true;
        animation.nodeId = preNode.id;
        return draft;
      });

      setTimeout(() => {
        centerToNode(node);
      }, 600);
    },
    [createNodeMapSessionType, onNodesChange, preNodeData, centerToNode],
  );

  const createEdgeForAnimation = useCallback(
    (animationIndex: number) => {
      // change this to sendout node
      if (!campaignSendNode) return;
      const nodeAnim = nodeAnimations.current.find((nAnim) => nAnim.index === animationIndex);
      if (!nodeAnim) return;
      if (animationIndex === 0) {
        const id = nodeAnim.nodeId;
        onEdgesChange([
          {
            type: 'add',
            item: createEdge({
              source: campaignSendNode?.id,
              sourceHandle: `${campaignSendNode?.id}:send`,
              target: id,
              targetHandle: `${id}:on-execute`,
            }),
          },
        ]);
        return;
      }
      const { edgeFrom } = nodeAnim;
      if (!edgeFrom) return;
      edgeFrom.forEach((edge) => {
        const sourceId = nodeAnimations.current.find((nAnim) => nAnim.index === edge[1])?.nodeId;
        const sourceNode = nodesCurrent().find((node) => node.id === sourceId);
        const sourceNodeType = sourceNode?.type as NodeType;
        if (!sourceId || !sourceNodeType) return;
        onEdgesChange([
          {
            type: 'add',
            item: createEdge({
              source: sourceId,
              sourceHandle: createHandleId({
                nodeId: sourceId,
                nodeType: sourceNodeType,
                actionType: 'button-click',
                buttonIndex: edge[2],
              }),
              target: nodeAnim.nodeId,
              targetHandle: `${nodeAnim.nodeId}:on-execute`,
            }),
          },
        ]);
      });
    },
    [campaignSendNode, nodesCurrent, onEdgesChange],
  );

  const createTypingNode = useCallback(
    (animationIndex: number, aiResponse: AIResponse) => {
      const preNode = preNodeData.current[animationIndex];
      let node = createNodeMapSessionType(preNode);

      node = produce(node, (draft) => {
        const { messageTemplateInput } = draft.data.v1;
        if (messageTemplateInput.templateComponents)
          messageTemplateInput.templateComponents = messageTemplateInput?.templateComponents?.map(
            (component: TemplateComponent) =>
              component.body ? { body: { text: { text: aiResponse.response } } } : component,
          );
        draft.data.v1.prompt = aiResponse.prompt;
        draft.data.v1.isGenerating = false;
        return draft;
      });
      dispatch(setMessageTemplateFromNode({ node }));

      // load unsent template into template slice
      updateUnsentMessageTemplate(node, dispatch);

      onNodesChange([{ type: 'add', item: node }]);
      nodeAnimations.current = produce(nodeAnimations.current, (draft) => {
        const animation = draft.find((nodeAnim) => nodeAnim.index === animationIndex);
        if (!animation) return draft;
        animation.nodeExist = true;
        animation.nodeId = preNode.id;
        animation.startedTyping = true;
        return draft;
      });

      setTimeout(() => {
        centerToNode(node);
      }, 600);
    },
    [createNodeMapSessionType, dispatch, onNodesChange, preNodeData, centerToNode],
  );

  const clearFlowChart = useCallback(() => {
    onNodesChange(
      nodesCurrent()
        .filter((node) => node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT')
        .filter((node) => node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN')
        .map((node) => ({
          id: node.id,
          type: 'remove',
        })),
    );
    onEdgesChange(
      edgesCurrent().map((edge) => ({
        id: edge.id,
        type: 'remove',
      })),
    );
    resetSelection();
  }, [edgesCurrent, nodesCurrent, onEdgesChange, onNodesChange, resetSelection]);

  const formInputs = useMemo(() => {
    if (!campaignTemplate) return [];
    const template = ffEnableETA ? aiTemplates[campaignTemplate] : aiTemplatesOld[campaignTemplate];
    const prompts = template.map((prenode) => prenode.data.v1.prompt);
    const buttons = template.map((prenode) =>
      prenode.data.v1?.messageTemplateInput?.templateComponents?.filter(
        (component: TemplateComponent) => component.button,
      ),
    );
    const buttonText = flatten(
      flatten(buttons).map((component) => [
        component.button?.quickReply?.text,
        component.button?.url?.text,
        component.button?.url?.url,
      ]),
    );
    const mediaHeaders = flatten(
      template.map(
        (prenode) =>
          prenode.data?.v1?.messageTemplateInput?.templateComponents?.find(
            (component: TemplateComponent) => component.header,
          )?.header?.text?.text,
      ),
    );

    // find all inputs in text that has % sign between variables like %brand%
    const inputs = uniq([...prompts, ...buttonText, ...mediaHeaders].join().match(/%(.*?)%/g));
    const inputsStartCased = inputs.map((s) => startCase(lowerCase(s.replace(/%/g, ''))));
    return inputsStartCased;
  }, [campaignTemplate]);

  const ffEnableWebpageToProduct = getFlagByName('ffEnableWebpageToProduct');

  const generateAIFromTemplate = useCallback(
    async (formData: Record<string, string>) => {
      if (!campaignTemplate) return;
      const template = ffEnableETA ? aiTemplates[campaignTemplate] : aiTemplatesOld[campaignTemplate];
      if (!template) return;
      const edges = aiTemplateEdges[campaignTemplate];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let extendedFormData: any = { ...formData, language };

      const count = template.length;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const buttonLocal = buttonLocalization[languageCode] as any;

      if (ffEnableWebpageToProduct && campaignTemplate === 'webpageToProduct') {
        clearFlowChart();
        // START -- to add a generating message node while loading webpage
        preNodeData.current = template.map((t) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          produce(t, (draft: any) => {
            draft.id = uuidv4();
            if (!draft.data.v1) draft.data.v1 = {};
            draft.data.v1.isGenerating = true;
          }),
        );
        nodeAnimations.current = [
          {
            index: 0,
            nodeId: '123',
            nodeExist: false,
            startedTyping: false,
            finishedTyping: false,
          },
        ];
        setAiState('generating');
        createGeneratingNode(0);
        createEdgeForAnimation(0);
        // END -- to add a generating message node while loading webpage
        let stopGoingFurther = false;
        extendedFormData = await new Promise((resolve, reject) => {
          webpageToProduct(
            extendedFormData,
            networkClientProvider,
            generateAIMessage,
            notificationServiceProvider,
            resolve,
            reject,
          );
        }).catch(() => {
          notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: 'Something went wrong, fetching product webpage. Please try another url.',
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          });
          setAiState('planning');
          stopGoingFurther = true;
        });
        if (stopGoingFurther) return;
      }

      preNodeData.current = template.map((t) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        produce(t, (draft: any) => {
          draft.id = uuidv4();
          if (!draft.data.v1) draft.data.v1 = {};
          draft.data.v1.isGenerating = true;
          draft.selected = false;

          const buttons = draft.data.v1.messageTemplateInput?.templateComponents?.filter(
            (component: TemplateComponent) => component.button,
          );

          const header = draft.data.v1.messageTemplateInput?.templateComponents?.find(
            (component: TemplateComponent) => component.header,
          );
          // translate node buttons to target language
          if (buttons)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            buttons.forEach((component: any) => {
              if (component.button?.quickReply?.text)
                component.button.quickReply.text =
                  upperFirst(toLower(buttonLocal[component.button?.quickReply?.text])) ||
                  component.button?.quickReply?.text;
              if (component.button?.url?.text)
                component.button.url.text =
                  upperFirst(toLower(buttonLocal[component.button.url.text])) || component.button.url.text;
            });

          // replace form data input in prompt ex %brand% should be replaced with user value
          Object.entries(extendedFormData).forEach(([key, value]) => {
            const keyRegex = new RegExp(`%${key}%`, 'gi');
            draft.data.v1.prompt = draft.data.v1.prompt.replace(keyRegex, value);
            if (buttons)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              buttons.forEach((component: any) => {
                if (component.button?.quickReply?.text)
                  component.button.quickReply.text = component.button?.quickReply?.text.replace(keyRegex, value);
                if (component.button?.url?.text)
                  component.button.url.text = component.button?.url?.text.replace(keyRegex, value);
                if (component.button?.url?.url) {
                  component.button.url.url = normalizeUrl(component.button?.url?.url.replace(keyRegex, value));
                  component.button.url.trackedUrl = component.button.url.url;
                }
              });
            if (header?.header?.media?.example.includes(keyRegex))
              header.header.media.example = header?.header?.media?.example.replace(keyRegex, value);
          });

          return draft;
        }),
      );

      const animationState = {
        index: 0,
        nodeId: '',
        nodeExist: false,
        startedTyping: false,
        finishedTyping: false,
      };

      nodeAnimations.current = [...Array(count).keys()].map((index) => ({
        ...animationState,
        index,
        ...(edges.length > index - 1 && index - 1 >= 0
          ? { edgeFrom: edges.filter((edge: number[]) => edge[0] === index) }
          : {}),
      }));
      aiResponses.current = [];
      clearFlowChart();

      setAiState('generating');
      setAiConversation([]);
      createGeneratingNode(0);
      createEdgeForAnimation(0);

      const aiCalls = preNodeData.current.map((prenode, index) => () => {
        const conversation = [
          ...selectAiConversation(appStore.getState()),
          { role: 'user', content: prenode.data.v1.prompt } as AIMessage,
        ];

        setAiConversation(conversation);
        return generateAIMessage(conversation).then((aiMessage) => {
          setAiConversation([...conversation, { role: 'assistant', content: aiMessage }]);
          aiResponseObservable.next({
            index,
            response: aiMessage,
            prompt: prenode.data.v1.prompt,
          });
        });
      });

      // call all prompts sequentially
      aiCalls.reduce((acc, aiCall) => acc.then(() => aiCall()), Promise.resolve());
    },
    [
      campaignTemplate,
      clearFlowChart,
      createEdgeForAnimation,
      createGeneratingNode,
      ffEnableWebpageToProduct,
      generateAIMessage,
      language,
      languageCode,
      networkClientProvider,
      notificationServiceProvider,
      setAiConversation,
      setAiState,
    ],
  );

  useEffect(() => {
    const aiResponseSubscription = aiResponseObservable.subscribe((aiResponse) => {
      aiResponses.current = [...aiResponses.current, aiResponse];
      const nodeAnimation = nodeAnimations.current.find((nAnimation) => nAnimation.index === aiResponse.index);
      if (nodeAnimation?.nodeExist) {
        animateExistingNode(nodeAnimation.index, nodeAnimation.nodeId, aiResponse);
      }
    });
    const typingSubscription = typingObservable.subscribe((nodeIdFinishedTyping) => {
      if (nodeAnimations.current.find((nAnimation) => nAnimation.nodeId === nodeIdFinishedTyping)?.finishedTyping)
        return;

      nodeAnimations.current = produce(nodeAnimations.current, (draft) => {
        const nodeAnimation = draft.find((nAnimation) => nAnimation.nodeId === nodeIdFinishedTyping);
        if (!nodeAnimation) return draft;
        nodeAnimation.finishedTyping = true;
        return draft;
      });

      const nodeAnimation = nodeAnimations.current.find((nAnimation) => nAnimation.nodeId === nodeIdFinishedTyping);
      if (!nodeAnimation) return;
      if (nodeAnimation.index === preNodeData.current.length - 1) {
        finishedAnimation();
        return;
      }
      const nextAIResponse = aiResponses.current.find((aiResponse) => aiResponse.index === nodeAnimation.index + 1);
      if (!nextAIResponse) {
        createGeneratingNode(nodeAnimation.index + 1);
      } else {
        createTypingNode(nodeAnimation.index + 1, nextAIResponse);
      }
      createEdgeForAnimation(nodeAnimation.index + 1);
    });

    return () => {
      if (aiResponseSubscription) {
        aiResponseSubscription.unsubscribe();
      }
      if (typingSubscription) {
        typingSubscription.unsubscribe();
      }
    };
  }, [
    aiResponses,
    animateExistingNode,
    createEdgeForAnimation,
    createGeneratingNode,
    createTypingNode,
    finishedAnimation,
    nodeAnimations,
  ]);

  const formPlaceholderOf = useCallback((input: string) => {
    return formPlaceholders[camelCase(input)] ? `Eg. ${formPlaceholders[camelCase(input)]}` : '';
  }, []);

  return {
    aiState,
    campaignTemplate,
    formInputs,
    formPlaceholderOf,
    generateAIFromTemplate,
    generateAIMessageFor,
    hideCampaignForAI,
    isAIGenerating: useMemo(() => aiState === 'generating', [aiState]),
    isAISidebarOpen,
    setIsAISidebarOpen,
  };
};
