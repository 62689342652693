import React from 'react';
import { AudienceSegment } from '../../../../hooks/useAudienceSegmentsData';

export enum AudienceOptionType {
  AUDIENCE = 'AUDIENCE',
  SPREADSHEET = 'SPREADSHEET',
  API = 'API',
}

export const AudienceOptions = {
  [AudienceOptionType.AUDIENCE]: {
    inputType: 'SENDOUT_INPUT_TYPE_SPREADSHEET' as const,
  },
  [AudienceOptionType.SPREADSHEET]: {
    inputType: 'SENDOUT_INPUT_TYPE_SPREADSHEET' as const,
  },
  [AudienceOptionType.API]: {
    inputType: 'SENDOUT_INPUT_TYPE_API' as const,
  },
};

export type AudienceState = {
  type: AudienceOptionType;
  [AudienceOptionType.AUDIENCE]: {
    segmentId: string;
  };
};

export type AudienceSectionProps = {
  title: string;
  id: string;
  businessId: string;
  checked: boolean;
  inputPlaceholder?: string;
  onCheckedChange?: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
  getOptionLabel?: (option: AudienceSegment) => string;
  isLoading?: boolean;
  audienceType: 'dynamicV2' | 'phoneList';
};
