import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QUERY_SENDOUT_URL_V3, QuerySendoutParams, QuerySendoutResponse } from './types';

export const useQuerySendoutV3 = <TData = QuerySendoutResponse>({
  businessId,
  input,
  ...options
}: QuerySendoutParams &
  Omit<
    UseQueryOptions<QuerySendoutResponse, unknown, TData, readonly ['sendoutV3', QuerySendoutParams]>,
    'initialData' | 'queryFn' | 'queryKey'
  > &
  QuerySendoutParams) =>
  useQuery(
    ['sendoutV3', { businessId, input }],
    () =>
      api.campaignV3(QUERY_SENDOUT_URL_V3, 'post', {
        path: { 'input.businessId': businessId },
        body: {
          input,
        },
      }),
    options,
  );
