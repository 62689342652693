import React, { ChangeEvent, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  ErrorIcon,
  FormControlLabel,
  IconButton,
  Label,
  Paper,
  RefreshIcon,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom, useAtomValue } from 'jotai';
import { confirmationAtom, phoneNumberAtom, useNewPhoneNumberAtom } from '@atoms/home';
import { ConnectProps } from './types';
import { SIZE_MEDIA_XXS } from '../../../../../../ui-theme';
import { PhoneNumber } from '../../../../../../components/PhoneNumber';

const useConnect = ({ claimedPhoneNumber }: Partial<ConnectProps>) => {
  const [confirmation, setConfirmation] = useAtom(confirmationAtom);
  const phoneNumber = useAtomValue(phoneNumberAtom);
  const useNewPhoneNumber = useAtomValue(useNewPhoneNumberAtom);

  const handleConfirmationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmation(e.target.checked);
    },
    [setConfirmation],
  );

  const number = useMemo(() => {
    return useNewPhoneNumber ? claimedPhoneNumber || '' : phoneNumber;
  }, [claimedPhoneNumber, phoneNumber, useNewPhoneNumber]);
  return {
    number,
    confirmation,
    onChange: handleConfirmationChange,
  };
};

export const Connect = ({ claimedPhoneNumber, onClaimNumber, verificationCode, isClaiming }: ConnectProps) => {
  const { confirmation, onChange, number } = useConnect({
    claimedPhoneNumber,
    verificationCode,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.connect' });
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Label>{t('title')}</Label>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ width: 240 }}>
          <PhoneNumber number={isClaiming ? 'Loading...' : number} />
        </Box>
        {!isClaiming && !number && (
          <IconButton
            style={{
              width: theme.spacing(SIZE_MEDIA_XXS),
              height: theme.spacing(SIZE_MEDIA_XXS),
            }}
            onClick={onClaimNumber}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px', p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <ErrorIcon />
            <Label>{t('verificationCodeHint')}</Label>
            <FormControlLabel
              control={<Checkbox checked={confirmation} onChange={onChange} />}
              label={t('verificationCodeCheckBox')}
            />
          </Box>
          <Box sx={{ borderRadius: '10px', backgroundColor: 'secondary.light', width: '170px', flexShrink: 0, p: 2 }}>
            <Label>Verification Code</Label>
            <TextField
              disabled
              placeholder="000000"
              value={verificationCode}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                  borderColor: 'transparent',
                  fontSize: '48px',
                },
                '& .MuiInputBase-input': {
                  px: 0,
                  py: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
