import { DataExport, DataExportList } from '../DataTable/types';
import { CsvData, CsvRow } from './types';

export const createCsvData = ({
  header,
  data,
  mapToDisplay,
}: {
  header: CsvRow;
  data: DataExportList;
  mapToDisplay: (item: DataExport) => CsvRow;
}): CsvData => {
  const result: CsvData = [header];
  data.forEach((item) => {
    result.push(mapToDisplay(item));
  });
  return result;
};
export const downloadCsv = (csvData: CsvData, filename: string) => {
  const buildCsvString = (data: CsvData): string => {
    return data.map((row) => row.join(',')).join('\n');
  };
  const csvString = buildCsvString(csvData);
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
