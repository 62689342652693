import React, { useMemo, useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { sofiaEditSettingAtom, sofiaStoreDescriptorAtom } from '@atoms/sofia';
import { Modal, Stack, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { ModalBodyData, InputDataModalProps } from './types';
import { InputDocuments } from '../InputDocuments';
import { InputFreeText } from '../InputFreeText';
import {
  InputFacebook,
  InputInstagram,
  InputCustomersAge,
  InputCustomersDescription,
  InputCustomersGender,
  InputEmail,
  InputLanguage,
  InputPhone,
  InputStoreDescription,
  InputStoreName,
  InputStoreUrl,
  InputWhatsAppLink,
} from '../StoreDescriptorInputs';
import { WelcomeMessageTextInput } from '../WelcomeMessageTextInput';
import { PromptAdditionTextInput } from '../PromptAdditionTextInput';
import { SIZE_CONTENT_L, SIZE_SPACING_INTER_COMPONENT, SIZE_SPACING_INTER_SECTION } from '../../../../ui-theme';

const InputBasicInformation = () => {
  return (
    <Stack gap={SIZE_SPACING_INTER_COMPONENT}>
      <InputLanguage disabled />
      <InputStoreName autoFocus />
      <InputStoreUrl />
    </Stack>
  );
};

const InputCustomersProfile = () => {
  return (
    <Stack gap={SIZE_SPACING_INTER_COMPONENT}>
      <InputCustomersGender autoFocus />
      <InputCustomersAge />
      <InputCustomersDescription />
    </Stack>
  );
};

const InputCustomersSupport = () => {
  return (
    <Stack gap={SIZE_SPACING_INTER_COMPONENT}>
      <InputEmail autoFocus />
      <InputPhone />
      <InputWhatsAppLink />
    </Stack>
  );
};

const InputSocialMedia = () => {
  return (
    <Stack gap={SIZE_SPACING_INTER_COMPONENT}>
      <InputFacebook autoFocus />
      <InputInstagram />
    </Stack>
  );
};

const useInputDataModal = ({
  saveFreeText,
  saveStoreDescriptor,
  revertChanges,
  saveWelcomeMessage,
  saveCustomPrompt,
}: InputDataModalProps) => {
  const [setting, setSetting] = useAtom(sofiaEditSettingAtom);
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const onClose = useCallback(() => {
    setSetting(null);
  }, [setSetting]);
  const onCancel = useCallback(() => {
    // revert settings atoms back to stored values
    revertChanges();
    onClose();
  }, [onClose, revertChanges]);
  const { t } = useTranslation('translation', { keyPrefix: '' });
  // useEffect(() => {
  //   if (setting) {
  //     // focus first input
  //   }
  // }, [setting]);
  const { saveLabel, cancelLabel } = useMemo(() => {
    return {
      saveLabel: t('generic.save'),
      cancelLabel: t('generic.cancel'),
    };
  }, [t]);
  const saveDisabled = useMemo(() => {
    switch (setting) {
      case 'basicInformation':
        if (descriptor.name === '' || descriptor.url === '') {
          return true;
        }
        break;
      case 'storeDescription':
        if (descriptor.about === '') {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  }, [descriptor.about, descriptor.name, descriptor.url, setting]);
  const { title, body, handleSave }: ModalBodyData = useMemo(() => {
    const returnValue: ModalBodyData = {
      title: '',
      body: null,
      handleSave: () => {
        saveStoreDescriptor();
        onClose();
      },
    };
    switch (setting) {
      case 'basicInformation':
        returnValue.body = <InputBasicInformation />;
        returnValue.title = t('sofia.basicInformationDataSectionTitle');
        break;
      case 'storeDescription':
        returnValue.body = <InputStoreDescription autoFocus requireValue />;
        returnValue.title = t('sofia.storeDescriptionDataSectionTitle');
        break;
      case 'welcomeMessage':
        returnValue.body = <WelcomeMessageTextInput />;
        returnValue.title = t('sofia.welcomeMessageDataSectionTitle');
        returnValue.handleSave = async () => {
          await saveWelcomeMessage();
          onClose();
        };
        break;
      case 'promptAddition':
        returnValue.body = <PromptAdditionTextInput />;
        returnValue.title = t('sofia.promptAdditionDataSectionTitle');
        returnValue.handleSave = async () => {
          await saveCustomPrompt();
          onClose();
        };
        break;
      case 'customerProfile':
        returnValue.body = <InputCustomersProfile />;
        returnValue.title = t('sofia.customerProfileDataSectionTitle');
        break;
      case 'customerSupport':
        returnValue.body = <InputCustomersSupport />;
        returnValue.title = t('sofia.customerSupportDataSectionTitle');
        break;
      case 'socialMedia':
        returnValue.body = <InputSocialMedia />;
        returnValue.title = t('sofia.socialMediaDataSectionTitle');
        break;
      case 'documents':
        returnValue.body = <InputDocuments isOnboarding={false} />;
        returnValue.title = t('sofia.documentsDataSectionTitle');
        break;
      case 'freeText':
        returnValue.body = <InputFreeText />;
        returnValue.title = t('sofia.freeTextDataSectionTitle');
        returnValue.handleSave = async () => {
          await saveFreeText();
          onClose();
        };
        break;
      default:
        break;
    }
    return returnValue;
  }, [onClose, saveCustomPrompt, saveFreeText, saveStoreDescriptor, saveWelcomeMessage, setting, t]);

  const theme = useTheme();
  return {
    theme,
    setting,
    title,
    onClose,
    cancelLabel,
    body,
    saveLabel,
    onCancel,
    saveDisabled,
    handleSave,
  };
};
export const InputDataModal = ({
  freeTextAssetId,
  revertChanges,
  saveStoreDescriptor,
  saveFreeText,
  saveWelcomeMessage,
  saveCustomPrompt,
}: InputDataModalProps) => {
  // eslint-disable-next-line max-len
  const { theme, setting, title, onClose, cancelLabel, body, saveLabel, onCancel, saveDisabled, handleSave } =
    useInputDataModal({
      saveFreeText,
      saveStoreDescriptor,
      saveWelcomeMessage,
      saveCustomPrompt,
      revertChanges,
      freeTextAssetId,
    });
  return (
    <Modal
      dialog={{ open: !!setting, onClose, maxWidth: 'xl', disableRestoreFocus: true }}
      title={title}
      closeButton={{ onClick: onClose }}
      actions={{
        tertiaryButton: { onClick: onCancel, children: cancelLabel },
        primaryButton: { onClick: handleSave, children: saveLabel, disabled: saveDisabled },
      }}
    >
      <Stack sx={{ minWidth: theme.spacing(110) }}>{body}</Stack>
    </Modal>
  );
};
