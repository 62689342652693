import React, { useCallback, useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { ShopifyOnboardingProps } from './types';
import { applyHook, getShopifyConnectUrl } from '../../utils';
import { goToStep, selectStoreUrl, setShopifyOptions } from '../../features/welcome';
import { selectBusinessId, useMeData } from '../../hooks';
import { ApprovalDialog } from '../ApprovalDialog';

const useShopifyOnboarding = ({
  onNextStep,
  shopify,
}: Partial<ShopifyOnboardingProps>): Partial<ShopifyOnboardingProps> => {
  const dispatch = useDispatch();

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    if (shopify?.isShopifyActive) {
      dispatch(
        setShopifyOptions({
          storeUrl: shopify?.storeName,
          connectUrl: getShopifyConnectUrl(businessId || '', shopify?.storeName)?.href || '',
        }),
      );
      setConfirmationOpen(true);
    }
  }, [businessId, dispatch, shopify?.isShopifyActive, shopify?.storeName]);

  const handleClose = useCallback(() => {
    setConfirmationOpen(false);
    dispatch(goToStep('payment'));
    if (onNextStep) {
      onNextStep();
    }
  }, [dispatch, onNextStep]);

  const storeUrl = useSelector(selectStoreUrl);
  const subdomainParam = localStorage.getItem('shopify_shop_subdomain');
  useEffect(() => {
    if (subdomainParam) {
      dispatch(
        setShopifyOptions({
          storeUrl: subdomainParam,
          connectUrl: getShopifyConnectUrl(businessId || '', subdomainParam)?.href || '',
        }),
      );
    }
  }, [dispatch, subdomainParam, businessId]);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(
        setShopifyOptions({ storeUrl: value, connectUrl: getShopifyConnectUrl(businessId || '', value)?.href || '' }),
      );
    },
    [businessId, dispatch],
  );

  return {
    confirmationOpen,
    onChange: handleChange,
    onClose: handleClose,
    storeUrl,
  };
};

export const ShopifyOnboardingPresentation: VFC<ShopifyOnboardingProps> = ({
  confirmationOpen,
  onChange,
  onClose,
  storeUrl,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyOnboarding' });
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <Box sx={{ px: 3, pt: 4.5, pb: 3 }}>
      <ApprovalDialog open={confirmationOpen} title={t('approvalTitle')} onClose={onClose} />
      <Label>{t('title')}</Label>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          autoFocus
          autoComplete="off"
          placeholder={t('enterStoreUrl') || ''}
          fullWidth
          value={storeUrl}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
          }}
          sx={{
            mt: 1.5,
            '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
            '& .MuiInputBase-input': { p: '11px 12px' },
          }}
        />
      </Box>
    </Box>
  );
};

export const ShopifyOnboarding = applyHook(ShopifyOnboardingPresentation, useShopifyOnboarding);
