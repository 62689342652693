export const SofiaRouteId = '/sofia';
export const WebsearchRouteId = '/sofia/websearch';
export function generateSofiaRoute() {
    return {
        id: SofiaRouteId,
        params: {},
    };
}
export function generateSofiaWebsearchRoute() {
    return {
        id: WebsearchRouteId,
        params: {},
    };
}
