import React from 'react';
import { Box, Label, useMediaQuery, useTheme, ExpandMoreIcon, Collapse, Grid } from '@connectlyai-tenets/ui-styled-web';
import { sendoutIdAtom, documentIdAtom, campaignNameAtom, compileResultAtom } from '@atoms/flow';
import { useAtomEffect } from '@hooks';
import { useAtomValue } from 'jotai';
import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';
import { JotaiDevToolsPanel } from '../../../JotaiDevToolsPanel';

export const CampaignDevTools = () => {
  const theme = useTheme();
  const campaignName = useAtomValue(campaignNameAtom);
  const compileResult = useAtomValue(compileResultAtom);
  const documentId = useAtomValue(documentIdAtom);
  const sendoutId = useAtomValue(sendoutIdAtom);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  useAtomEffect(compileResultAtom, () => setExpanded('compilePanel'));

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [hidden, setHidden] = React.useState(true);
  return (
    <Box
      sx={{
        top: theme.spacing(isLargeScreen ? 10 : 8),
        right: 0,
        position: 'absolute',
        height: `calc(100vh - ${theme.spacing(isLargeScreen ? 10 : 8)})`,
        maxHeight: `calc(100vh - ${theme.spacing(isLargeScreen ? 10 : 8)})`,
        boxSizing: 'border-box',
        alignItems: 'center',
        gap: 0,
      }}
    >
      <Box
        onClick={() => setHidden(!hidden)}
        sx={{
          p: 0,
          width: '32px',
          backgroundColor: 'white',
          height: '100px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRight: 'none',
          boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '10px 0 0 10px',
          top: '50%',
          transform: 'translateY(-50%)',
          position: 'absolute',
          left: '-32px',
          zIndex: 1,
        }}
      >
        <ExpandMoreIcon
          sx={{
            transform: hidden ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
      </Box>
      <Collapse
        in={!hidden}
        orientation="horizontal"
        sx={{
          borderRadius: '0 0 0 10px',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.1)',
          height: '100%',
        }}
      >
        <Box
          sx={{
            padding: 2,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: 800,
            maxWidth: 800,
            overflowY: 'auto',
            height: '100%',
          }}
        >
          <Box sx={{ backgroundColor: '#f4f4f4', p: 2, border: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid container item>
                <Grid item xs={3}>
                  <Label variant="body1">Campaign Name:</Label>
                </Grid>
                <Grid item>
                  <Label variant="subtitle2">{campaignName}</Label>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={3}>
                  <Label variant="body1">Flow Document Id:</Label>
                </Grid>
                <Grid item>
                  <Label variant="subtitle2">{documentId}</Label>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={3}>
                  <Label variant="body1">Sendout Id:</Label>
                </Grid>
                <Grid item>
                  <Label variant="subtitle2">{sendoutId}</Label>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Accordion expanded={expanded === 'compilePanel'} onChange={handleChange('compilePanel')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Label sx={{ width: '33%', flexShrink: 0 }}>Compilation</Label>
              <Label sx={{ color: 'text.secondary' }}>
                {Object.keys(compileResult).length > 0 ? '✅ Data received' : 'No data'}
              </Label>
            </AccordionSummary>
            <AccordionDetails>
              <Label
                variant="body2"
                sx={{
                  fontFamily: 'monospace',
                }}
              >
                {JSON.stringify(compileResult, null, 2)}
              </Label>
            </AccordionDetails>
          </Accordion>
          <JotaiDevToolsPanel />
        </Box>
      </Collapse>
    </Box>
  );
};
