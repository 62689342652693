import { NodeChange, EdgeChange } from 'react-flow-renderer';
import { NodeObjectUpdate } from '../useChangesQueue';
import { HandleType } from '../../sdks/flow/createHandleId';
import { selectIsDocumentEditable } from '../../state/flow';
import { useFlowChangeAppliers } from '../useFlowChangeAppliers';

export const useReadOnlyFlowChangeAppliers = () => {
  const flowChangeAppliers = useFlowChangeAppliers({
    onNodesChangeRequested: (changes: NodeChange[]) => changes,
    selectIsDocumentEditable,
  });

  return {
    updateNodeId: (_: string, _2: string) => {},
    updateEdgeId: (_: string, _2: string) => {},
    onNodeObjectChange: (_: NodeObjectUpdate[]) => {},
    onNodesChange: (_: NodeChange[]) => {},
    onNodesChangeProtected: (_: NodeChange[]) => {},
    onEdgesChange: (_: EdgeChange[]) => {},
    onEdgesChangeProtected: (_: EdgeChange[]) => {},
    selectNode: (_: string) => {},
    resetSelection: () => {},
    isHandleConnected: flowChangeAppliers.isHandleConnected,
    createNodeAtHandle: (_: HandleType) => {},
    setNodeAsGenerating: (_: string, _2: boolean, _3: string) => {},
    cleanAfterButtonIsDeleted: (_: number) => {},
  };
};
