import React, { VFC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, CircularProgress, Label, Stack, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { spreadsheetURLAtom } from '@atoms/flow';
import { useAtomValue } from 'jotai';
import { SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import {
  setIsSpreadsheetOpen,
  selectIsSpreadsheetOpen,
  selectSubmitError,
  selectAudience,
} from '../../../../state/flow';
import { FlowChartSidebarRecipientDetailsHint } from '../../../FlowChartSidebarRecipientDetailsHint';
import { useCampaignV3 } from '../../hooks/useCampaignV3';

export const SPREADSHEET_CHILD_WINDOW = 'spreadsheet-window';

export const RecipientDetails: VFC = () => {
  const submitError = useSelector(selectSubmitError);
  let isSpreadsheetOpen = useSelector(selectIsSpreadsheetOpen);
  const audience = useSelector(selectAudience);
  const dispatch = useDispatch();
  const handleSpreadsheetOpen = () => {
    dispatch(setIsSpreadsheetOpen(true));
  };

  isSpreadsheetOpen = audience.isEnabled && audience.segmentId ? true : isSpreadsheetOpen;

  const { handleVerifySpreadsheet, verifySpreadsheetResult } = useCampaignV3();

  const verifyHint = useMemo(() => {
    if (verifySpreadsheetResult?.status === 'MUTATION_STATUS_SUCCESS') return 'Spreadsheet verified successfully';
    if (verifySpreadsheetResult?.status === 'MUTATION_STATUS_FAILURE') return 'Spreadsheet verification has failed';
    return '';
  }, [verifySpreadsheetResult]);

  const theme = useTheme();

  const verifyHintColor = useMemo(() => {
    if (verifySpreadsheetResult?.status === 'MUTATION_STATUS_SUCCESS') return theme.palette.success.main;
    if (verifySpreadsheetResult?.status === 'MUTATION_STATUS_FAILURE') return theme.palette.error.main;
    return theme.palette.text.primary;
  }, [verifySpreadsheetResult, theme]);

  const verifyProgress = useMemo(() => {
    if (
      verifySpreadsheetResult?.status === 'MUTATION_STATUS_CUSTOM' &&
      verifySpreadsheetResult.progress &&
      verifySpreadsheetResult.progress.completed &&
      verifySpreadsheetResult.progress.total
    )
      return {
        completed: verifySpreadsheetResult.progress.completed as number,
        total: verifySpreadsheetResult.progress.total as number,
      };
    return null;
  }, [verifySpreadsheetResult]);

  const spreadsheetURL = useAtomValue(spreadsheetURLAtom);
  const isFailure = submitError !== null;

  return (
    <>
      <Label variant="h6" gutterBottom>
        Finalize Recipient Details
      </Label>
      {isFailure && (
        <Label variant="subtitle2" color="error">
          Please go back to modify campaign nodes and correct errors
        </Label>
      )}
      {!isFailure && !spreadsheetURL && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 10,
            justifyContent: 'center',
            width: 1,
            gap: 4,
          }}
        >
          <CircularProgress />
          <Label>Please wait while generating spreadsheet</Label>
        </Box>
      )}
      {spreadsheetURL && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              gap: 2,
            }}
          >
            <Stack direction="row" alignItems="center">
              <Avatar
                sx={{
                  width: theme.spacing(SIZE_MEDIA_XS),
                  height: theme.spacing(SIZE_MEDIA_XS),
                  marginRight: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
                  fontSize: theme.typography.caption.fontSize,
                }}
              >
                1
              </Avatar>
              <Label variant="subtitle2">Edit recipients and variables</Label>
            </Stack>
            <Button
              variant={isSpreadsheetOpen ? 'outlined' : 'contained'}
              // eslint-disable-next-line no-nested-ternary
              color={isSpreadsheetOpen ? 'secondary' : 'primary'}
              target={SPREADSHEET_CHILD_WINDOW}
              href={spreadsheetURL}
              onClick={handleSpreadsheetOpen}
              sx={{ alignSelf: 'flex-start' }}
            >
              {isSpreadsheetOpen ? 'Edit recipient list' : 'Add recipient list'}
            </Button>
            <FlowChartSidebarRecipientDetailsHint
              content={`Come back to this screen and continue 
                the process after you’ve completed the recipient list.`}
            />
          </Box>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              gap: 2,
            }}
          >
            <Stack direction="row" alignItems="center">
              <Avatar
                sx={{
                  width: theme.spacing(SIZE_MEDIA_XS),
                  height: theme.spacing(SIZE_MEDIA_XS),
                  marginRight: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
                  fontSize: theme.typography.caption.fontSize,
                }}
              >
                2
              </Avatar>
              <Label variant="subtitle2">Verify information and correct errors</Label>
            </Stack>
            <Box>
              <Button
                variant="contained"
                onClick={handleVerifySpreadsheet}
                disabled={!isSpreadsheetOpen || (!!verifyProgress && verifyProgress.completed < verifyProgress.total)}
              >
                {!!verifyProgress && verifyProgress.completed < verifyProgress.total
                  ? `Verifying... ${verifyProgress.completed}%`
                  : 'Verify'}
              </Button>
              {verifyHint && (
                <Label
                  component="div"
                  variant="body1"
                  sx={{
                    mt: 2,
                    color: verifyHintColor,
                  }}
                >
                  {verifyHint}
                </Label>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
