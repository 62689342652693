import { createSlice } from '@reduxjs/toolkit';
import { ChannelType, ComposerSendBehavior, InboxExperience } from '../entities';
import { resetStore, setUser } from './action';
import { deactivateIntegration, installIntegration, updateSettings, updateWAToken } from './thunk';
function updateChannels(state, channels) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    let smsChannel;
    let waChannel;
    const waCloudChannels = [];
    let shopifyChannel;
    let fbMessengerChannel;
    let instagramChannel;
    channels.forEach((channel) => {
        if (channel.channelType === ChannelType.CHANNEL_TYPE_SMS) {
            smsChannel = channel;
        }
        if (channel.channelType === ChannelType.CHANNEL_TYPE_WHATSAPP) {
            waChannel = channel;
        }
        if (channel.channelType === ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD) {
            waCloudChannels.push(channel);
        }
        if (channel.channelType === ChannelType.CHANNEL_TYPE_FB_MESSENGER) {
            fbMessengerChannel = channel;
        }
        if (channel.channelType === ChannelType.CHANNEL_TYPE_INSTAGRAM) {
            instagramChannel = channel;
        }
        if (channel.channelType === ChannelType.CHANNEL_TYPE_SHOPIFY) {
            shopifyChannel = channel;
        }
    });
    if (smsChannel !== undefined) {
        state.settings.channel.phoneNumber = smsChannel.externalId;
        state.settings.channel.smsChannel = smsChannel;
    }
    if (waChannel !== undefined) {
        state.settings.channel.whatsAppNumber = waChannel.externalId;
    }
    if (shopifyChannel !== undefined) {
        state.settings.channel.shopifyAccessToken = shopifyChannel.accessToken;
    }
    state.settings.channel.hasShopifyChannel = Boolean(shopifyChannel);
    waCloudChannels.forEach((channel) => {
        const { remoteProfile } = channel;
        if (remoteProfile) {
            const { profileOneof } = remoteProfile;
            if (profileOneof && profileOneof.$case === 'wabaPhoneNumber' && profileOneof.wabaPhoneNumber) {
                const s = state.settings.channel.whatsAppCloudNumbers;
                const n = profileOneof.wabaPhoneNumber.phoneNumber;
                if (!s.includes(n)) {
                    s.push(n);
                }
            }
        }
    });
    if (fbMessengerChannel !== undefined && ((_b = (_a = fbMessengerChannel === null || fbMessengerChannel === void 0 ? void 0 : fbMessengerChannel.remoteProfile) === null || _a === void 0 ? void 0 : _a.profileOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'fbPageProfile') {
        state.settings.channel.fbMessengerPageName =
            ((_e = (_d = (_c = fbMessengerChannel === null || fbMessengerChannel === void 0 ? void 0 : fbMessengerChannel.remoteProfile) === null || _c === void 0 ? void 0 : _c.profileOneof) === null || _d === void 0 ? void 0 : _d.fbPageProfile) === null || _e === void 0 ? void 0 : _e.name) || '';
    }
    if (instagramChannel !== undefined && ((_g = (_f = instagramChannel === null || instagramChannel === void 0 ? void 0 : instagramChannel.remoteProfile) === null || _f === void 0 ? void 0 : _f.profileOneof) === null || _g === void 0 ? void 0 : _g.$case) === 'igUserProfile') {
        state.settings.channel.instagramPageHandler =
            ((_k = (_j = (_h = instagramChannel === null || instagramChannel === void 0 ? void 0 : instagramChannel.remoteProfile) === null || _h === void 0 ? void 0 : _h.profileOneof) === null || _j === void 0 ? void 0 : _j.igUserProfile) === null || _k === void 0 ? void 0 : _k.username) || '';
    }
    if (shopifyChannel !== undefined && ((_m = (_l = shopifyChannel === null || shopifyChannel === void 0 ? void 0 : shopifyChannel.remoteProfile) === null || _l === void 0 ? void 0 : _l.profileOneof) === null || _m === void 0 ? void 0 : _m.$case) === 'shopifyProfile') {
        state.settings.channel.shopifyShopName =
            ((_r = (_q = (_p = (_o = shopifyChannel === null || shopifyChannel === void 0 ? void 0 : shopifyChannel.remoteProfile) === null || _o === void 0 ? void 0 : _o.profileOneof) === null || _p === void 0 ? void 0 : _p.shopifyProfile) === null || _q === void 0 ? void 0 : _q.shop) === null || _r === void 0 ? void 0 : _r.name) || '';
    }
}
const initialState = {
    settings: {
        agentProfile: {
            id: '',
            displayName: '',
        },
        businessProfile: {
            name: '',
            shopifyFocus: false,
            integrations: [],
        },
        channel: {
            phoneNumber: '',
            whatsAppNumber: '',
            whatsAppCloudNumbers: [],
            shopifyAccessToken: '',
            hasShopifyChannel: false,
            fbMessengerPageName: '',
            instagramPageHandler: '',
            shopifyShopName: '',
            smsChannel: undefined,
        },
        isAgentProfileReady: false,
        isBusinessProfileReady: false,
        inboxConfig: undefined,
        inboxSettings: {
            composerSendBehavior: ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED,
        },
        widget: {
            script: '',
        },
        waConnect: {
            otpCode: '',
        },
    },
};
export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        reset: () => initialState,
        setBusinessChannel: (state, action) => {
            const { payload: channel } = action;
            updateChannels(state, [channel]);
        },
        setBusiness: (state, action) => {
            var _a, _b;
            const { payload: business } = action;
            const { name, inboxConfig, integrations } = business;
            state.settings.isBusinessProfileReady = true;
            state.settings.inboxConfig = inboxConfig;
            state.settings.businessProfile.name = name;
            state.settings.businessProfile.shopifyFocus =
                ((_a = inboxConfig === null || inboxConfig === void 0 ? void 0 : inboxConfig.configV1) === null || _a === void 0 ? void 0 : _a.experience) === InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS ||
                    ((_b = inboxConfig === null || inboxConfig === void 0 ? void 0 : inboxConfig.configV1) === null || _b === void 0 ? void 0 : _b.experience) === InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS;
            state.settings.businessProfile.integrations = integrations || [];
            updateChannels(state, business.channels);
        },
        setOTPCode: (state, action) => {
            state.settings.waConnect.otpCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(deactivateIntegration.fulfilled, (state, action) => {
            const { integrationId } = action.meta.arg;
            const { businessProfile } = state.settings;
            businessProfile.integrations = businessProfile.integrations.filter((x) => x.id !== integrationId);
        });
        builder.addCase(installIntegration.fulfilled, (state, action) => {
            const { type, parameters } = action.meta.arg;
            const { integrationId = '' } = action.payload;
            const { businessProfile } = state.settings;
            businessProfile.integrations = [
                {
                    id: integrationId,
                    businessId: '',
                    type,
                    parameters,
                    installMetadata: undefined,
                },
                ...businessProfile.integrations.filter((x) => x.type !== type),
            ];
        });
        builder.addCase(setUser, (state, action) => {
            state.settings.isAgentProfileReady = true;
            state.settings.agentProfile.id = action.payload.id;
            state.settings.agentProfile.displayName = action.payload.displayName;
        });
        builder.addCase(updateSettings.pending, (_state, _action) => {
        });
        builder.addCase(updateSettings.fulfilled, (state, action) => {
            var _a;
            const { payload: business } = action;
            const { name, inboxConfig, integrations } = business;
            state.settings.businessProfile.name = name;
            state.settings.businessProfile.shopifyFocus =
                ((_a = inboxConfig === null || inboxConfig === void 0 ? void 0 : inboxConfig.configV1) === null || _a === void 0 ? void 0 : _a.experience) === InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS;
            state.settings.businessProfile.integrations = (integrations === null || integrations === void 0 ? void 0 : integrations.entries) || [];
        });
        builder.addCase(updateSettings.rejected, (_state, _action) => {
        });
        builder.addCase(updateWAToken.fulfilled, (state, action) => {
            const waCloudChannels = [];
            action.payload.forEach((channel) => {
                if (channel.channelType === ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD) {
                    waCloudChannels.push(channel);
                }
            });
            waCloudChannels.forEach((channel) => {
                const { remoteProfile } = channel;
                if (remoteProfile) {
                    const { profileOneof } = remoteProfile;
                    if (profileOneof && profileOneof.$case === 'wabaPhoneNumber' && profileOneof.wabaPhoneNumber) {
                        const s = state.settings.channel.whatsAppCloudNumbers;
                        const n = profileOneof.wabaPhoneNumber.phoneNumber;
                        if (!s.includes(n)) {
                            s.push(n);
                        }
                    }
                }
            });
        });
    },
});
export const { reset, setBusiness, setBusinessChannel, setOTPCode } = settingsSlice.actions;
export const selectSettings = (state) => state.settingsSlice.settings;
export default settingsSlice.reducer;
