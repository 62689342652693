import { createSlice } from '@reduxjs/toolkit';
import { resetStore, setCustomerInput } from './action';
import { updateCustomerProfile } from './thunk';
export const initialState = {
    isActive: false,
    isError: false,
    errorMessage: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
};
export const updateCustomerProfileSlice = createSlice({
    name: 'updateCustomerProfileSlice',
    initialState,
    reducers: {
        setCustomerProfileUpdateActive(state, action) {
            if (state.isActive && !action.payload) {
                state.isError = false;
                state.errorMessage = null;
                state.firstName = null;
                state.lastName = null;
                state.phone = null;
                state.email = null;
            }
            if (!state.isActive && action.payload) {
                state.isError = false;
                state.errorMessage = null;
                state.firstName = '';
                state.lastName = '';
                state.phone = '';
                state.email = '';
            }
            state.isActive = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setCustomerInput, (state, action) => {
            state.isActive = action.payload.isActive;
            state.isError = false;
            state.errorMessage = null;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
        });
        builder.addCase(updateCustomerProfile.pending, (state, _action) => {
            state.isActive = true;
            state.isError = false;
            state.errorMessage = null;
        });
        builder.addCase(updateCustomerProfile.fulfilled, (state, _action) => {
            state.isActive = false;
            state.isError = false;
            state.errorMessage = null;
            state.firstName = null;
            state.lastName = null;
            state.email = null;
            state.phone = null;
        });
        builder.addCase(updateCustomerProfile.rejected, (state, _action) => {
            state.isActive = true;
            state.isError = true;
            state.errorMessage = 'Error: Please try again!';
        });
    },
});
export const { reset, setCustomerProfileUpdateActive } = updateCustomerProfileSlice.actions;
export const selectUpdateCustomerProfileState = (state) => state.updateCustomerProfileSlice;
export default updateCustomerProfileSlice.reducer;
