import { provisionNumber, useFeatureDispatch, WelcomeDIContext } from '@connectlyai-features/welcome';
import { useContext } from 'react';
import { ConnectShopify } from './types';

export const useClaimNumber = ({ setClaimNumberIsLoading }: ConnectShopify) => {
  const di = useContext(WelcomeDIContext);
  const dispatch = useFeatureDispatch();
  const claimNumber = () => {
    if (!di) {
      return;
    }
    dispatch(
      provisionNumber({
        di,
        setIsLoading: setClaimNumberIsLoading,
      }),
    );
  };

  return { claimNumber };
};
