import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetFlowToPreface } from './actions';
import { AIState, ConnectlyError, FlowState, State, AIMessage, Starter, AudienceState } from './types';
import { clampDate, minMaxDate } from '../../hooks/useCampaignDate/utils';
import { CampaignTemplateType } from '../../hooks/useAICampaign/types';
import { VerifyReactFlowDocumentResult } from '../../hooks/useRequestVerifyReactFlowDocument/types';

const initialState: State = {
  aiConversation: [],
  aiState: 'planning',
  audience: { segmentId: '', isEnabled: false },
  campaignDate: '',
  campaignName: '',
  campaignTemplate: null,
  documentId: '',
  documentVerificationId: undefined,
  documentVerificationResult: undefined,
  flowState: 'preface',
  fetchCampaignSendNode: false,
  isAISidebarOpen: false,
  isCampaignNameDialogOpen: true,
  isEdgeAttached: false,
  isSpreadsheetOpen: false,
  previewDocumentId: '',
  savedCampaignName: '',
  starter: 'empty',
  submitError: null,
};

// This state contains the "campaign flow state"
export const campaignFlowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    goToNextFlowState: (state, action: PayloadAction<{ isNeedingTemplateApproval: boolean }>) => {
      switch (state.flowState) {
        case 'buildCampaign': {
          state.flowState = 'verifyCampaign';
          break;
        }
        case 'verifyCampaign': {
          if (action.payload.isNeedingTemplateApproval) {
            state.flowState = 'approveTemplates';
          } else {
            state.flowState = 'chooseAudience';
          }
          break;
        }
        case 'approveTemplates': {
          state.flowState = 'chooseAudience';
          break;
        }
        case 'chooseAudience': {
          state.flowState = 'recipientDetails';
          break;
        }
        case 'recipientDetails': {
          state.flowState = 'chooseTime';
          break;
        }
        case 'chooseTime': {
          state.flowState = 'conclusion';
          break;
        }
        default: {
          state.flowState = 'buildCampaign';
          break;
        }
      }
    },
    goToPreviousFlowState: (state) => {
      switch (state.flowState) {
        case 'recipientDetails': {
          state.flowState = 'chooseAudience';
          break;
        }
        case 'chooseTime': {
          state.flowState = 'recipientDetails';
          break;
        }
        case 'conclusion': {
          state.flowState = 'chooseTime';
          break;
        }
        default: {
          state.flowState = 'buildCampaign';
          break;
        }
      }
    },
    setAiConversation: (state, action: PayloadAction<AIMessage[]>) => {
      state.aiConversation = action.payload;
    },
    setAiState: (state, action: PayloadAction<AIState>) => {
      state.aiState = action.payload;
    },
    setAudience: (state, action: PayloadAction<AudienceState>) => {
      state.audience = action.payload;
    },
    setCampaignDate: (state, action: PayloadAction<string>) => {
      if (!action.payload) {
        state.campaignDate = '';
        return;
      }
      const date = new Date(action.payload);
      const [minDateScope, maxDateScope] = minMaxDate(new Date());
      state.campaignDate = clampDate(date, minDateScope, maxDateScope)?.toISOString() || '';
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setCampaignTemplate: (state, action: PayloadAction<CampaignTemplateType>) => {
      state.campaignTemplate = action.payload;
    },
    setDocumentId: (state, action: PayloadAction<string>) => {
      state.documentId = action.payload;
    },
    setDocumentVerificationId: (state, action: PayloadAction<string | undefined>) => {
      state.documentVerificationId = action.payload;
    },
    setDocumentVerificationResult: (state, action: PayloadAction<VerifyReactFlowDocumentResult | undefined>) => {
      state.documentVerificationResult = action.payload;
    },
    setFetchCampaignSendNode: (state, action: PayloadAction<boolean>) => {
      state.fetchCampaignSendNode = action.payload;
    },
    setFlowState: (state, action: PayloadAction<FlowState>) => {
      state.flowState = action.payload;
    },
    setIsCampaignNameDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isCampaignNameDialogOpen = action.payload;
    },
    setIsEdgeAttached: (state, action: PayloadAction<boolean>) => {
      state.isEdgeAttached = action.payload;
    },
    setIsAISidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isAISidebarOpen = action.payload;
    },
    setIsSpreadsheetOpen: (state, action: PayloadAction<boolean>) => {
      state.isSpreadsheetOpen = action.payload;
    },
    setPreviewDocumentId: (state, action: PayloadAction<string>) => {
      state.previewDocumentId = action.payload;
    },
    setSubmitError: (state, action: PayloadAction<ConnectlyError | null>) => {
      state.submitError = action.payload;
    },
    setSavedCampaignName: (state, action: PayloadAction<string>) => {
      state.savedCampaignName = action.payload;
    },
    setStarter: (state, action: PayloadAction<Starter>) => {
      state.starter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFlowToPreface, (state, _action) => {
      state.audience = { segmentId: '', isEnabled: false };
      state.aiConversation = [];
      state.aiState = 'planning';
      state.campaignDate = '';
      state.campaignName = '';
      state.campaignTemplate = null;
      state.documentId = '';
      state.documentVerificationId = undefined;
      state.documentVerificationResult = undefined;
      state.flowState = 'preface';
      state.isCampaignNameDialogOpen = true;
      state.isAISidebarOpen = false;
      state.isEdgeAttached = false;
      state.isSpreadsheetOpen = false;
      state.submitError = null;
      state.savedCampaignName = '';
      state.starter = 'empty';
    });
  },
});

export const {
  goToNextFlowState,
  goToPreviousFlowState,
  setAiConversation,
  setAiState,
  setAudience,
  setCampaignDate,
  setCampaignName,
  setCampaignTemplate,
  setDocumentId,
  setDocumentVerificationId,
  setDocumentVerificationResult,
  setFetchCampaignSendNode,
  setFlowState,
  setIsCampaignNameDialogOpen,
  setIsEdgeAttached,
  setIsAISidebarOpen,
  setIsSpreadsheetOpen,
  setPreviewDocumentId,
  setSubmitError,
  setSavedCampaignName,
  setStarter,
} = campaignFlowSlice.actions;

export default campaignFlowSlice.reducer;
