import React from 'react';
import { FormControl, TextField, Label } from '@connectlyai-tenets/ui-styled-web';
import { TextInputProps } from './types';

export const TextInput = ({
  label,
  name,
  value,
  info,
  autoFocus,
  multiline,
  rows,
  noLabel,
  title,
  placeholder,
  handleChange,
  error,
}: TextInputProps) => {
  return (
    <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
      {title && <Label variant="h7" sx={{ mb: -1 }}>{`${title}:`}</Label>}
      <TextField
        error={Boolean(error)}
        helperText={error}
        autoFocus={autoFocus}
        label={noLabel ? undefined : label}
        name={name}
        variant="outlined"
        margin="normal"
        value={value}
        fullWidth
        multiline={multiline}
        placeholder={placeholder}
        rows={rows}
        onChange={handleChange}
        sx={{ background: (theme) => theme.palette.common.white }}
      />
      {info && (
        <Label variant="body1" sx={{ color: 'text.secondary' }}>
          {info}
        </Label>
      )}
    </FormControl>
  );
};
