import { channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import { Subscription, PaymentMethod, billingPlatformFromJSON, billingPlatformToJSON, } from "../billing/models";
import { Timestamp } from "../../google/protobuf/timestamp";
import { ConfigsDto } from "../widget/models";
import { TagDtoCollection } from "../tags/models";
import { InboxConfigDto } from "../inbox/dto/dto";
import { IntegrationDtoCollection } from "../integration/dto/models";
import { Profile, Page, } from "../../events/facebook/graphapi/v10_0/profile/models";
import { ShopifyCustomer, ShopifyRemoteProfile, } from "../../events/shopify/v2023_01/models";
import { TwilioWhatsappProfile } from "../../events/twilio/webhook/v20100401/models";
import { IgUser } from "../../events/facebook/graphapi/v3_2/profile/models";
import { WhatsAppWebhookContacts } from "../../events/facebook/webhook/v12_0/whatsapp/models";
import { Customer as Customer1 } from "../../events/vtex/v1/models";
import { Account, PhoneNumberProfile, } from "../../events/twilio/v20100401/models";
import { WABAPhoneNumber } from "../../events/facebook/graphapi/v12_0/profile/models";
import { ZendeskProfile } from "../../events/zendesk/auth/models";
import { NuPayProfile } from "../../events/nubank/nupay/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.profile";
export var BusinessKeyType;
(function (BusinessKeyType) {
    BusinessKeyType[BusinessKeyType["BUSINESS_KEY_TYPE_UNSPECIFIED"] = 0] = "BUSINESS_KEY_TYPE_UNSPECIFIED";
    BusinessKeyType[BusinessKeyType["BUSINESS_KEY_TYPE_API_KEY"] = 1] = "BUSINESS_KEY_TYPE_API_KEY";
    BusinessKeyType[BusinessKeyType["BUSINESS_KEY_TYPE_WEBHOOK_SECRET"] = 2] = "BUSINESS_KEY_TYPE_WEBHOOK_SECRET";
    BusinessKeyType[BusinessKeyType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(BusinessKeyType || (BusinessKeyType = {}));
export function businessKeyTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "BUSINESS_KEY_TYPE_UNSPECIFIED":
            return BusinessKeyType.BUSINESS_KEY_TYPE_UNSPECIFIED;
        case 1:
        case "BUSINESS_KEY_TYPE_API_KEY":
            return BusinessKeyType.BUSINESS_KEY_TYPE_API_KEY;
        case 2:
        case "BUSINESS_KEY_TYPE_WEBHOOK_SECRET":
            return BusinessKeyType.BUSINESS_KEY_TYPE_WEBHOOK_SECRET;
        case -1:
        case "UNRECOGNIZED":
        default:
            return BusinessKeyType.UNRECOGNIZED;
    }
}
export function businessKeyTypeToJSON(object) {
    switch (object) {
        case BusinessKeyType.BUSINESS_KEY_TYPE_UNSPECIFIED:
            return "BUSINESS_KEY_TYPE_UNSPECIFIED";
        case BusinessKeyType.BUSINESS_KEY_TYPE_API_KEY:
            return "BUSINESS_KEY_TYPE_API_KEY";
        case BusinessKeyType.BUSINESS_KEY_TYPE_WEBHOOK_SECRET:
            return "BUSINESS_KEY_TYPE_WEBHOOK_SECRET";
        case BusinessKeyType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var EligibilityType;
(function (EligibilityType) {
    EligibilityType[EligibilityType["ELIGIBILITY_TYPE_UNSPECIFIED"] = 0] = "ELIGIBILITY_TYPE_UNSPECIFIED";
    EligibilityType[EligibilityType["ELIGIBILITY_TYPE_BIDIRECTIONAL"] = 1] = "ELIGIBILITY_TYPE_BIDIRECTIONAL";
    EligibilityType[EligibilityType["ELIGIBILITY_TYPE_INBOUND_ONLY"] = 2] = "ELIGIBILITY_TYPE_INBOUND_ONLY";
    EligibilityType[EligibilityType["ELIGIBILITY_TYPE_OUTBOUND_ONLY"] = 3] = "ELIGIBILITY_TYPE_OUTBOUND_ONLY";
    EligibilityType[EligibilityType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(EligibilityType || (EligibilityType = {}));
export function eligibilityTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "ELIGIBILITY_TYPE_UNSPECIFIED":
            return EligibilityType.ELIGIBILITY_TYPE_UNSPECIFIED;
        case 1:
        case "ELIGIBILITY_TYPE_BIDIRECTIONAL":
            return EligibilityType.ELIGIBILITY_TYPE_BIDIRECTIONAL;
        case 2:
        case "ELIGIBILITY_TYPE_INBOUND_ONLY":
            return EligibilityType.ELIGIBILITY_TYPE_INBOUND_ONLY;
        case 3:
        case "ELIGIBILITY_TYPE_OUTBOUND_ONLY":
            return EligibilityType.ELIGIBILITY_TYPE_OUTBOUND_ONLY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return EligibilityType.UNRECOGNIZED;
    }
}
export function eligibilityTypeToJSON(object) {
    switch (object) {
        case EligibilityType.ELIGIBILITY_TYPE_UNSPECIFIED:
            return "ELIGIBILITY_TYPE_UNSPECIFIED";
        case EligibilityType.ELIGIBILITY_TYPE_BIDIRECTIONAL:
            return "ELIGIBILITY_TYPE_BIDIRECTIONAL";
        case EligibilityType.ELIGIBILITY_TYPE_INBOUND_ONLY:
            return "ELIGIBILITY_TYPE_INBOUND_ONLY";
        case EligibilityType.ELIGIBILITY_TYPE_OUTBOUND_ONLY:
            return "ELIGIBILITY_TYPE_OUTBOUND_ONLY";
        case EligibilityType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Role;
(function (Role) {
    Role[Role["ROLE_UNSPECIFIED"] = 0] = "ROLE_UNSPECIFIED";
    Role[Role["ROLE_OWNER"] = 1] = "ROLE_OWNER";
    Role[Role["ROLE_AGENT"] = 2] = "ROLE_AGENT";
    Role[Role["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Role || (Role = {}));
export function roleFromJSON(object) {
    switch (object) {
        case 0:
        case "ROLE_UNSPECIFIED":
            return Role.ROLE_UNSPECIFIED;
        case 1:
        case "ROLE_OWNER":
            return Role.ROLE_OWNER;
        case 2:
        case "ROLE_AGENT":
            return Role.ROLE_AGENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Role.UNRECOGNIZED;
    }
}
export function roleToJSON(object) {
    switch (object) {
        case Role.ROLE_UNSPECIFIED:
            return "ROLE_UNSPECIFIED";
        case Role.ROLE_OWNER:
            return "ROLE_OWNER";
        case Role.ROLE_AGENT:
            return "ROLE_AGENT";
        case Role.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var PaymentMethodStatus;
(function (PaymentMethodStatus) {
    PaymentMethodStatus[PaymentMethodStatus["PAYMENT_METHOD_STATUS_UNSPECIFIED"] = 0] = "PAYMENT_METHOD_STATUS_UNSPECIFIED";
    PaymentMethodStatus[PaymentMethodStatus["PAYMENT_METHOD_STATUS_UNVERIFIED"] = 1] = "PAYMENT_METHOD_STATUS_UNVERIFIED";
    PaymentMethodStatus[PaymentMethodStatus["PAYMENT_METHOD_STATUS_VERIFIED"] = 2] = "PAYMENT_METHOD_STATUS_VERIFIED";
    PaymentMethodStatus[PaymentMethodStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PaymentMethodStatus || (PaymentMethodStatus = {}));
export function paymentMethodStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "PAYMENT_METHOD_STATUS_UNSPECIFIED":
            return PaymentMethodStatus.PAYMENT_METHOD_STATUS_UNSPECIFIED;
        case 1:
        case "PAYMENT_METHOD_STATUS_UNVERIFIED":
            return PaymentMethodStatus.PAYMENT_METHOD_STATUS_UNVERIFIED;
        case 2:
        case "PAYMENT_METHOD_STATUS_VERIFIED":
            return PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFIED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PaymentMethodStatus.UNRECOGNIZED;
    }
}
export function paymentMethodStatusToJSON(object) {
    switch (object) {
        case PaymentMethodStatus.PAYMENT_METHOD_STATUS_UNSPECIFIED:
            return "PAYMENT_METHOD_STATUS_UNSPECIFIED";
        case PaymentMethodStatus.PAYMENT_METHOD_STATUS_UNVERIFIED:
            return "PAYMENT_METHOD_STATUS_UNVERIFIED";
        case PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFIED:
            return "PAYMENT_METHOD_STATUS_VERIFIED";
        case PaymentMethodStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseBusiness() {
    return {
        id: "",
        name: "",
        profilePicId: "",
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
        channels: [],
        widgetConfigs: undefined,
        handle: "",
        quickReplyTemplates: undefined,
        systemRobotUserId: "",
        tags: undefined,
        properties: undefined,
        inboxConfig: undefined,
        integrations: undefined,
        organization: undefined,
    };
}
export const Business = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.profilePicId !== "") {
            writer.uint32(26).string(message.profilePicId);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.channels) {
            BusinessChannel.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.widgetConfigs !== undefined) {
            ConfigsDto.encode(message.widgetConfigs, writer.uint32(66).fork()).ldelim();
        }
        if (message.handle !== "") {
            writer.uint32(74).string(message.handle);
        }
        if (message.quickReplyTemplates !== undefined) {
            QuickReplyTemplateCollection.encode(message.quickReplyTemplates, writer.uint32(82).fork()).ldelim();
        }
        if (message.systemRobotUserId !== "") {
            writer.uint32(90).string(message.systemRobotUserId);
        }
        if (message.tags !== undefined) {
            TagDtoCollection.encode(message.tags, writer.uint32(98).fork()).ldelim();
        }
        if (message.properties !== undefined) {
            BusinessProperties.encode(message.properties, writer.uint32(106).fork()).ldelim();
        }
        if (message.inboxConfig !== undefined) {
            InboxConfigDto.encode(message.inboxConfig, writer.uint32(114).fork()).ldelim();
        }
        if (message.integrations !== undefined) {
            IntegrationDtoCollection.encode(message.integrations, writer.uint32(122).fork()).ldelim();
        }
        if (message.organization !== undefined) {
            Organization.encode(message.organization, writer.uint32(130).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusiness();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.profilePicId = reader.string();
                    break;
                case 4:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.channels.push(BusinessChannel.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.widgetConfigs = ConfigsDto.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.handle = reader.string();
                    break;
                case 10:
                    message.quickReplyTemplates = QuickReplyTemplateCollection.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.systemRobotUserId = reader.string();
                    break;
                case 12:
                    message.tags = TagDtoCollection.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.properties = BusinessProperties.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.inboxConfig = InboxConfigDto.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.integrations = IntegrationDtoCollection.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.organization = Organization.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            profilePicId: isSet(object.profilePicId)
                ? String(object.profilePicId)
                : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
            channels: Array.isArray(object === null || object === void 0 ? void 0 : object.channels)
                ? object.channels.map((e) => BusinessChannel.fromJSON(e))
                : [],
            widgetConfigs: isSet(object.widgetConfigs)
                ? ConfigsDto.fromJSON(object.widgetConfigs)
                : undefined,
            handle: isSet(object.handle) ? String(object.handle) : "",
            quickReplyTemplates: isSet(object.quickReplyTemplates)
                ? QuickReplyTemplateCollection.fromJSON(object.quickReplyTemplates)
                : undefined,
            systemRobotUserId: isSet(object.systemRobotUserId)
                ? String(object.systemRobotUserId)
                : "",
            tags: isSet(object.tags)
                ? TagDtoCollection.fromJSON(object.tags)
                : undefined,
            properties: isSet(object.properties)
                ? BusinessProperties.fromJSON(object.properties)
                : undefined,
            inboxConfig: isSet(object.inboxConfig)
                ? InboxConfigDto.fromJSON(object.inboxConfig)
                : undefined,
            integrations: isSet(object.integrations)
                ? IntegrationDtoCollection.fromJSON(object.integrations)
                : undefined,
            organization: isSet(object.organization)
                ? Organization.fromJSON(object.organization)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.profilePicId !== undefined &&
            (obj.profilePicId = message.profilePicId);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        if (message.channels) {
            obj.channels = message.channels.map((e) => e ? BusinessChannel.toJSON(e) : undefined);
        }
        else {
            obj.channels = [];
        }
        message.widgetConfigs !== undefined &&
            (obj.widgetConfigs = message.widgetConfigs
                ? ConfigsDto.toJSON(message.widgetConfigs)
                : undefined);
        message.handle !== undefined && (obj.handle = message.handle);
        message.quickReplyTemplates !== undefined &&
            (obj.quickReplyTemplates = message.quickReplyTemplates
                ? QuickReplyTemplateCollection.toJSON(message.quickReplyTemplates)
                : undefined);
        message.systemRobotUserId !== undefined &&
            (obj.systemRobotUserId = message.systemRobotUserId);
        message.tags !== undefined &&
            (obj.tags = message.tags
                ? TagDtoCollection.toJSON(message.tags)
                : undefined);
        message.properties !== undefined &&
            (obj.properties = message.properties
                ? BusinessProperties.toJSON(message.properties)
                : undefined);
        message.inboxConfig !== undefined &&
            (obj.inboxConfig = message.inboxConfig
                ? InboxConfigDto.toJSON(message.inboxConfig)
                : undefined);
        message.integrations !== undefined &&
            (obj.integrations = message.integrations
                ? IntegrationDtoCollection.toJSON(message.integrations)
                : undefined);
        message.organization !== undefined &&
            (obj.organization = message.organization
                ? Organization.toJSON(message.organization)
                : undefined);
        return obj;
    },
};
function createBaseBusinessProperties() {
    return { waForBusinessConfigured: false };
}
export const BusinessProperties = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waForBusinessConfigured === true) {
            writer.uint32(8).bool(message.waForBusinessConfigured);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessProperties();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waForBusinessConfigured = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waForBusinessConfigured: isSet(object.waForBusinessConfigured)
                ? Boolean(object.waForBusinessConfigured)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.waForBusinessConfigured !== undefined &&
            (obj.waForBusinessConfigured = message.waForBusinessConfigured);
        return obj;
    },
};
function createBaseContactInfo() {
    return { email: "", contactTypeOneof: undefined };
}
export const ContactInfo = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.email !== "") {
            writer.uint32(10).string(message.email);
        }
        if (((_a = message.contactTypeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "phone") {
            writer.uint32(90).string(message.contactTypeOneof.phone);
        }
        if (((_b = message.contactTypeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "facebookPsid") {
            writer.uint32(98).string(message.contactTypeOneof.facebookPsid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContactInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 11:
                    message.contactTypeOneof = { $case: "phone", phone: reader.string() };
                    break;
                case 12:
                    message.contactTypeOneof = {
                        $case: "facebookPsid",
                        facebookPsid: reader.string(),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            email: isSet(object.email) ? String(object.email) : "",
            contactTypeOneof: isSet(object.phone)
                ? { $case: "phone", phone: String(object.phone) }
                : isSet(object.facebookPsid)
                    ? { $case: "facebookPsid", facebookPsid: String(object.facebookPsid) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d;
        const obj = {};
        message.email !== undefined && (obj.email = message.email);
        ((_a = message.contactTypeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "phone" &&
            (obj.phone = (_b = message.contactTypeOneof) === null || _b === void 0 ? void 0 : _b.phone);
        ((_c = message.contactTypeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "facebookPsid" &&
            (obj.facebookPsid = (_d = message.contactTypeOneof) === null || _d === void 0 ? void 0 : _d.facebookPsid);
        return obj;
    },
};
function createBaseMember() {
    return { memberOneof: undefined };
}
export const Member = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.memberOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customer") {
            Customer.encode(message.memberOneof.customer, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.memberOneof) === null || _b === void 0 ? void 0 : _b.$case) === "businessUser") {
            BusinessUser.encode(message.memberOneof.businessUser, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMember();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.memberOneof = {
                        $case: "customer",
                        customer: Customer.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.memberOneof = {
                        $case: "businessUser",
                        businessUser: BusinessUser.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            memberOneof: isSet(object.customer)
                ? { $case: "customer", customer: Customer.fromJSON(object.customer) }
                : isSet(object.businessUser)
                    ? {
                        $case: "businessUser",
                        businessUser: BusinessUser.fromJSON(object.businessUser),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.memberOneof) === null || _a === void 0 ? void 0 : _a.$case) === "customer" &&
            (obj.customer = ((_b = message.memberOneof) === null || _b === void 0 ? void 0 : _b.customer)
                ? Customer.toJSON((_c = message.memberOneof) === null || _c === void 0 ? void 0 : _c.customer)
                : undefined);
        ((_d = message.memberOneof) === null || _d === void 0 ? void 0 : _d.$case) === "businessUser" &&
            (obj.businessUser = ((_e = message.memberOneof) === null || _e === void 0 ? void 0 : _e.businessUser)
                ? BusinessUser.toJSON((_f = message.memberOneof) === null || _f === void 0 ? void 0 : _f.businessUser)
                : undefined);
        return obj;
    },
};
function createBaseCustomerProfile() {
    return {
        firstName: "",
        lastName: "",
        gender: "",
        profilePic: "",
        locale: "",
        timezone: 0,
    };
}
export const CustomerProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstName !== "") {
            writer.uint32(10).string(message.firstName);
        }
        if (message.lastName !== "") {
            writer.uint32(18).string(message.lastName);
        }
        if (message.gender !== "") {
            writer.uint32(26).string(message.gender);
        }
        if (message.profilePic !== "") {
            writer.uint32(34).string(message.profilePic);
        }
        if (message.locale !== "") {
            writer.uint32(42).string(message.locale);
        }
        if (message.timezone !== 0) {
            writer.uint32(48).int32(message.timezone);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.gender = reader.string();
                    break;
                case 4:
                    message.profilePic = reader.string();
                    break;
                case 5:
                    message.locale = reader.string();
                    break;
                case 6:
                    message.timezone = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstName: isSet(object.firstName) ? String(object.firstName) : "",
            lastName: isSet(object.lastName) ? String(object.lastName) : "",
            gender: isSet(object.gender) ? String(object.gender) : "",
            profilePic: isSet(object.profilePic) ? String(object.profilePic) : "",
            locale: isSet(object.locale) ? String(object.locale) : "",
            timezone: isSet(object.timezone) ? Number(object.timezone) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.gender !== undefined && (obj.gender = message.gender);
        message.profilePic !== undefined && (obj.profilePic = message.profilePic);
        message.locale !== undefined && (obj.locale = message.locale);
        message.timezone !== undefined &&
            (obj.timezone = Math.round(message.timezone));
        return obj;
    },
};
function createBaseCustomer() {
    return {
        id: "",
        channels: [],
        profile: undefined,
        notes: undefined,
        tags: {},
        softDeleted: false,
    };
}
export const Customer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        for (const v of message.channels) {
            CustomerChannel.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.profile !== undefined) {
            CustomerProfile.encode(message.profile, writer.uint32(34).fork()).ldelim();
        }
        if (message.notes !== undefined) {
            CustomerNote.encode(message.notes, writer.uint32(42).fork()).ldelim();
        }
        Object.entries(message.tags).forEach(([key, value]) => {
            Customer_TagsEntry.encode({ key: key, value }, writer.uint32(90).fork()).ldelim();
        });
        if (message.softDeleted === true) {
            writer.uint32(48).bool(message.softDeleted);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 3:
                    message.channels.push(CustomerChannel.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.profile = CustomerProfile.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.notes = CustomerNote.decode(reader, reader.uint32());
                    break;
                case 11:
                    const entry11 = Customer_TagsEntry.decode(reader, reader.uint32());
                    if (entry11.value !== undefined) {
                        message.tags[entry11.key] = entry11.value;
                    }
                    break;
                case 6:
                    message.softDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            channels: Array.isArray(object === null || object === void 0 ? void 0 : object.channels)
                ? object.channels.map((e) => CustomerChannel.fromJSON(e))
                : [],
            profile: isSet(object.profile)
                ? CustomerProfile.fromJSON(object.profile)
                : undefined,
            notes: isSet(object.notes)
                ? CustomerNote.fromJSON(object.notes)
                : undefined,
            tags: isObject(object.tags)
                ? Object.entries(object.tags).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
            softDeleted: isSet(object.softDeleted)
                ? Boolean(object.softDeleted)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.channels) {
            obj.channels = message.channels.map((e) => e ? CustomerChannel.toJSON(e) : undefined);
        }
        else {
            obj.channels = [];
        }
        message.profile !== undefined &&
            (obj.profile = message.profile
                ? CustomerProfile.toJSON(message.profile)
                : undefined);
        message.notes !== undefined &&
            (obj.notes = message.notes
                ? CustomerNote.toJSON(message.notes)
                : undefined);
        obj.tags = {};
        if (message.tags) {
            Object.entries(message.tags).forEach(([k, v]) => {
                obj.tags[k] = v;
            });
        }
        message.softDeleted !== undefined &&
            (obj.softDeleted = message.softDeleted);
        return obj;
    },
};
function createBaseCustomer_TagsEntry() {
    return { key: "", value: "" };
}
export const Customer_TagsEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomer_TagsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseCustomerNote() {
    return { text: "" };
}
export const CustomerNote = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerNote();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseMutationUpdateCustomerProfileInput() {
    return { customerProfile: undefined, customerChannelProfile: undefined };
}
export const MutationUpdateCustomerProfileInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.customerProfile !== undefined) {
            UpdateCustomerProfileInput.encode(message.customerProfile, writer.uint32(10).fork()).ldelim();
        }
        if (message.customerChannelProfile !== undefined) {
            UpdateCustomerChannelProfileInput.encode(message.customerChannelProfile, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationUpdateCustomerProfileInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customerProfile = UpdateCustomerProfileInput.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.customerChannelProfile =
                        UpdateCustomerChannelProfileInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customerProfile: isSet(object.customerProfile)
                ? UpdateCustomerProfileInput.fromJSON(object.customerProfile)
                : undefined,
            customerChannelProfile: isSet(object.customerChannelProfile)
                ? UpdateCustomerChannelProfileInput.fromJSON(object.customerChannelProfile)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.customerProfile !== undefined &&
            (obj.customerProfile = message.customerProfile
                ? UpdateCustomerProfileInput.toJSON(message.customerProfile)
                : undefined);
        message.customerChannelProfile !== undefined &&
            (obj.customerChannelProfile = message.customerChannelProfile
                ? UpdateCustomerChannelProfileInput.toJSON(message.customerChannelProfile)
                : undefined);
        return obj;
    },
};
function createBaseUpdateCustomerProfileInput() {
    return {
        id: "",
        profile: undefined,
        note: undefined,
        phoneNumber: undefined,
        email: undefined,
    };
}
export const UpdateCustomerProfileInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.profile !== undefined) {
            CustomerProfile.encode(message.profile, writer.uint32(18).fork()).ldelim();
        }
        if (message.note !== undefined) {
            CustomerNote.encode(message.note, writer.uint32(26).fork()).ldelim();
        }
        if (message.phoneNumber !== undefined) {
            StringValue.encode({ value: message.phoneNumber }, writer.uint32(34).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateCustomerProfileInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.profile = CustomerProfile.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.note = CustomerNote.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.phoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            profile: isSet(object.profile)
                ? CustomerProfile.fromJSON(object.profile)
                : undefined,
            note: isSet(object.note) ? CustomerNote.fromJSON(object.note) : undefined,
            phoneNumber: isSet(object.phoneNumber)
                ? String(object.phoneNumber)
                : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.profile !== undefined &&
            (obj.profile = message.profile
                ? CustomerProfile.toJSON(message.profile)
                : undefined);
        message.note !== undefined &&
            (obj.note = message.note ? CustomerNote.toJSON(message.note) : undefined);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.email !== undefined && (obj.email = message.email);
        return obj;
    },
};
function createBaseUpdateCustomerChannelProfileInput() {
    return { id: "", externalProfile: undefined };
}
export const UpdateCustomerChannelProfileInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.externalProfile !== undefined) {
            ExternalProfile.encode(message.externalProfile, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateCustomerChannelProfileInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.externalProfile = ExternalProfile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            externalProfile: isSet(object.externalProfile)
                ? ExternalProfile.fromJSON(object.externalProfile)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.externalProfile !== undefined &&
            (obj.externalProfile = message.externalProfile
                ? ExternalProfile.toJSON(message.externalProfile)
                : undefined);
        return obj;
    },
};
function createBaseBusinessUser() {
    return { id: "", contactInfo: [], channels: [], tags: {} };
}
export const BusinessUser = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        for (const v of message.contactInfo) {
            ContactInfo.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.channels) {
            BusinessChannel.encode(v, writer.uint32(26).fork()).ldelim();
        }
        Object.entries(message.tags).forEach(([key, value]) => {
            BusinessUser_TagsEntry.encode({ key: key, value }, writer.uint32(90).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.contactInfo.push(ContactInfo.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.channels.push(BusinessChannel.decode(reader, reader.uint32()));
                    break;
                case 11:
                    const entry11 = BusinessUser_TagsEntry.decode(reader, reader.uint32());
                    if (entry11.value !== undefined) {
                        message.tags[entry11.key] = entry11.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            contactInfo: Array.isArray(object === null || object === void 0 ? void 0 : object.contactInfo)
                ? object.contactInfo.map((e) => ContactInfo.fromJSON(e))
                : [],
            channels: Array.isArray(object === null || object === void 0 ? void 0 : object.channels)
                ? object.channels.map((e) => BusinessChannel.fromJSON(e))
                : [],
            tags: isObject(object.tags)
                ? Object.entries(object.tags).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.contactInfo) {
            obj.contactInfo = message.contactInfo.map((e) => e ? ContactInfo.toJSON(e) : undefined);
        }
        else {
            obj.contactInfo = [];
        }
        if (message.channels) {
            obj.channels = message.channels.map((e) => e ? BusinessChannel.toJSON(e) : undefined);
        }
        else {
            obj.channels = [];
        }
        obj.tags = {};
        if (message.tags) {
            Object.entries(message.tags).forEach(([k, v]) => {
                obj.tags[k] = v;
            });
        }
        return obj;
    },
};
function createBaseBusinessUser_TagsEntry() {
    return { key: "", value: "" };
}
export const BusinessUser_TagsEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessUser_TagsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseExternalProfile() {
    return { profileOneof: undefined, fetchedAt: undefined };
}
export const ExternalProfile = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = message.profileOneof) === null || _a === void 0 ? void 0 : _a.$case) === "fbUserProfile") {
            Profile.encode(message.profileOneof.fbUserProfile, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.profileOneof) === null || _b === void 0 ? void 0 : _b.$case) === "shopifyCustomerProfile") {
            ShopifyCustomer.encode(message.profileOneof.shopifyCustomerProfile, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.profileOneof) === null || _c === void 0 ? void 0 : _c.$case) === "twilioWhatsappProfile") {
            TwilioWhatsappProfile.encode(message.profileOneof.twilioWhatsappProfile, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.profileOneof) === null || _d === void 0 ? void 0 : _d.$case) === "igUserProfile") {
            IgUser.encode(message.profileOneof.igUserProfile, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.profileOneof) === null || _e === void 0 ? void 0 : _e.$case) === "whatsappProfile") {
            WhatsAppWebhookContacts.encode(message.profileOneof.whatsappProfile, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.profileOneof) === null || _f === void 0 ? void 0 : _f.$case) === "vtexCustomerProfile") {
            Customer1.encode(message.profileOneof.vtexCustomerProfile, writer.uint32(50).fork()).ldelim();
        }
        if (message.fetchedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.fetchedAt), writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExternalProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.profileOneof = {
                        $case: "fbUserProfile",
                        fbUserProfile: Profile.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.profileOneof = {
                        $case: "shopifyCustomerProfile",
                        shopifyCustomerProfile: ShopifyCustomer.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.profileOneof = {
                        $case: "twilioWhatsappProfile",
                        twilioWhatsappProfile: TwilioWhatsappProfile.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.profileOneof = {
                        $case: "igUserProfile",
                        igUserProfile: IgUser.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.profileOneof = {
                        $case: "whatsappProfile",
                        whatsappProfile: WhatsAppWebhookContacts.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.profileOneof = {
                        $case: "vtexCustomerProfile",
                        vtexCustomerProfile: Customer1.decode(reader, reader.uint32()),
                    };
                    break;
                case 10:
                    message.fetchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            profileOneof: isSet(object.fbUserProfile)
                ? {
                    $case: "fbUserProfile",
                    fbUserProfile: Profile.fromJSON(object.fbUserProfile),
                }
                : isSet(object.shopifyCustomerProfile)
                    ? {
                        $case: "shopifyCustomerProfile",
                        shopifyCustomerProfile: ShopifyCustomer.fromJSON(object.shopifyCustomerProfile),
                    }
                    : isSet(object.twilioWhatsappProfile)
                        ? {
                            $case: "twilioWhatsappProfile",
                            twilioWhatsappProfile: TwilioWhatsappProfile.fromJSON(object.twilioWhatsappProfile),
                        }
                        : isSet(object.igUserProfile)
                            ? {
                                $case: "igUserProfile",
                                igUserProfile: IgUser.fromJSON(object.igUserProfile),
                            }
                            : isSet(object.whatsappProfile)
                                ? {
                                    $case: "whatsappProfile",
                                    whatsappProfile: WhatsAppWebhookContacts.fromJSON(object.whatsappProfile),
                                }
                                : isSet(object.vtexCustomerProfile)
                                    ? {
                                        $case: "vtexCustomerProfile",
                                        vtexCustomerProfile: Customer1.fromJSON(object.vtexCustomerProfile),
                                    }
                                    : undefined,
            fetchedAt: isSet(object.fetchedAt) ? String(object.fetchedAt) : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        const obj = {};
        ((_a = message.profileOneof) === null || _a === void 0 ? void 0 : _a.$case) === "fbUserProfile" &&
            (obj.fbUserProfile = ((_b = message.profileOneof) === null || _b === void 0 ? void 0 : _b.fbUserProfile)
                ? Profile.toJSON((_c = message.profileOneof) === null || _c === void 0 ? void 0 : _c.fbUserProfile)
                : undefined);
        ((_d = message.profileOneof) === null || _d === void 0 ? void 0 : _d.$case) === "shopifyCustomerProfile" &&
            (obj.shopifyCustomerProfile = ((_e = message.profileOneof) === null || _e === void 0 ? void 0 : _e.shopifyCustomerProfile)
                ? ShopifyCustomer.toJSON((_f = message.profileOneof) === null || _f === void 0 ? void 0 : _f.shopifyCustomerProfile)
                : undefined);
        ((_g = message.profileOneof) === null || _g === void 0 ? void 0 : _g.$case) === "twilioWhatsappProfile" &&
            (obj.twilioWhatsappProfile = ((_h = message.profileOneof) === null || _h === void 0 ? void 0 : _h.twilioWhatsappProfile)
                ? TwilioWhatsappProfile.toJSON((_j = message.profileOneof) === null || _j === void 0 ? void 0 : _j.twilioWhatsappProfile)
                : undefined);
        ((_k = message.profileOneof) === null || _k === void 0 ? void 0 : _k.$case) === "igUserProfile" &&
            (obj.igUserProfile = ((_l = message.profileOneof) === null || _l === void 0 ? void 0 : _l.igUserProfile)
                ? IgUser.toJSON((_m = message.profileOneof) === null || _m === void 0 ? void 0 : _m.igUserProfile)
                : undefined);
        ((_o = message.profileOneof) === null || _o === void 0 ? void 0 : _o.$case) === "whatsappProfile" &&
            (obj.whatsappProfile = ((_p = message.profileOneof) === null || _p === void 0 ? void 0 : _p.whatsappProfile)
                ? WhatsAppWebhookContacts.toJSON((_q = message.profileOneof) === null || _q === void 0 ? void 0 : _q.whatsappProfile)
                : undefined);
        ((_r = message.profileOneof) === null || _r === void 0 ? void 0 : _r.$case) === "vtexCustomerProfile" &&
            (obj.vtexCustomerProfile = ((_s = message.profileOneof) === null || _s === void 0 ? void 0 : _s.vtexCustomerProfile)
                ? Customer1.toJSON((_t = message.profileOneof) === null || _t === void 0 ? void 0 : _t.vtexCustomerProfile)
                : undefined);
        message.fetchedAt !== undefined && (obj.fetchedAt = message.fetchedAt);
        return obj;
    },
};
function createBaseBusinessChannelRemoteProfile() {
    return { profileOneof: undefined };
}
export const BusinessChannelRemoteProfile = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = message.profileOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopifyProfile") {
            ShopifyRemoteProfile.encode(message.profileOneof.shopifyProfile, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.profileOneof) === null || _b === void 0 ? void 0 : _b.$case) === "twilioAccount") {
            Account.encode(message.profileOneof.twilioAccount, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.profileOneof) === null || _c === void 0 ? void 0 : _c.$case) === "igUserProfile") {
            IgUser.encode(message.profileOneof.igUserProfile, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.profileOneof) === null || _d === void 0 ? void 0 : _d.$case) === "fbPageProfile") {
            Page.encode(message.profileOneof.fbPageProfile, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.profileOneof) === null || _e === void 0 ? void 0 : _e.$case) === "wabaPhoneNumber") {
            WABAPhoneNumber.encode(message.profileOneof.wabaPhoneNumber, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.profileOneof) === null || _f === void 0 ? void 0 : _f.$case) === "twilioSmsProfile") {
            PhoneNumberProfile.encode(message.profileOneof.twilioSmsProfile, writer.uint32(50).fork()).ldelim();
        }
        if (((_g = message.profileOneof) === null || _g === void 0 ? void 0 : _g.$case) === "zendeskProfile") {
            ZendeskProfile.encode(message.profileOneof.zendeskProfile, writer.uint32(58).fork()).ldelim();
        }
        if (((_h = message.profileOneof) === null || _h === void 0 ? void 0 : _h.$case) === "nupayProfile") {
            NuPayProfile.encode(message.profileOneof.nupayProfile, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelRemoteProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.profileOneof = {
                        $case: "shopifyProfile",
                        shopifyProfile: ShopifyRemoteProfile.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.profileOneof = {
                        $case: "twilioAccount",
                        twilioAccount: Account.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.profileOneof = {
                        $case: "igUserProfile",
                        igUserProfile: IgUser.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.profileOneof = {
                        $case: "fbPageProfile",
                        fbPageProfile: Page.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.profileOneof = {
                        $case: "wabaPhoneNumber",
                        wabaPhoneNumber: WABAPhoneNumber.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.profileOneof = {
                        $case: "twilioSmsProfile",
                        twilioSmsProfile: PhoneNumberProfile.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.profileOneof = {
                        $case: "zendeskProfile",
                        zendeskProfile: ZendeskProfile.decode(reader, reader.uint32()),
                    };
                    break;
                case 8:
                    message.profileOneof = {
                        $case: "nupayProfile",
                        nupayProfile: NuPayProfile.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            profileOneof: isSet(object.shopifyProfile)
                ? {
                    $case: "shopifyProfile",
                    shopifyProfile: ShopifyRemoteProfile.fromJSON(object.shopifyProfile),
                }
                : isSet(object.twilioAccount)
                    ? {
                        $case: "twilioAccount",
                        twilioAccount: Account.fromJSON(object.twilioAccount),
                    }
                    : isSet(object.igUserProfile)
                        ? {
                            $case: "igUserProfile",
                            igUserProfile: IgUser.fromJSON(object.igUserProfile),
                        }
                        : isSet(object.fbPageProfile)
                            ? {
                                $case: "fbPageProfile",
                                fbPageProfile: Page.fromJSON(object.fbPageProfile),
                            }
                            : isSet(object.wabaPhoneNumber)
                                ? {
                                    $case: "wabaPhoneNumber",
                                    wabaPhoneNumber: WABAPhoneNumber.fromJSON(object.wabaPhoneNumber),
                                }
                                : isSet(object.twilioSmsProfile)
                                    ? {
                                        $case: "twilioSmsProfile",
                                        twilioSmsProfile: PhoneNumberProfile.fromJSON(object.twilioSmsProfile),
                                    }
                                    : isSet(object.zendeskProfile)
                                        ? {
                                            $case: "zendeskProfile",
                                            zendeskProfile: ZendeskProfile.fromJSON(object.zendeskProfile),
                                        }
                                        : isSet(object.nupayProfile)
                                            ? {
                                                $case: "nupayProfile",
                                                nupayProfile: NuPayProfile.fromJSON(object.nupayProfile),
                                            }
                                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        const obj = {};
        ((_a = message.profileOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopifyProfile" &&
            (obj.shopifyProfile = ((_b = message.profileOneof) === null || _b === void 0 ? void 0 : _b.shopifyProfile)
                ? ShopifyRemoteProfile.toJSON((_c = message.profileOneof) === null || _c === void 0 ? void 0 : _c.shopifyProfile)
                : undefined);
        ((_d = message.profileOneof) === null || _d === void 0 ? void 0 : _d.$case) === "twilioAccount" &&
            (obj.twilioAccount = ((_e = message.profileOneof) === null || _e === void 0 ? void 0 : _e.twilioAccount)
                ? Account.toJSON((_f = message.profileOneof) === null || _f === void 0 ? void 0 : _f.twilioAccount)
                : undefined);
        ((_g = message.profileOneof) === null || _g === void 0 ? void 0 : _g.$case) === "igUserProfile" &&
            (obj.igUserProfile = ((_h = message.profileOneof) === null || _h === void 0 ? void 0 : _h.igUserProfile)
                ? IgUser.toJSON((_j = message.profileOneof) === null || _j === void 0 ? void 0 : _j.igUserProfile)
                : undefined);
        ((_k = message.profileOneof) === null || _k === void 0 ? void 0 : _k.$case) === "fbPageProfile" &&
            (obj.fbPageProfile = ((_l = message.profileOneof) === null || _l === void 0 ? void 0 : _l.fbPageProfile)
                ? Page.toJSON((_m = message.profileOneof) === null || _m === void 0 ? void 0 : _m.fbPageProfile)
                : undefined);
        ((_o = message.profileOneof) === null || _o === void 0 ? void 0 : _o.$case) === "wabaPhoneNumber" &&
            (obj.wabaPhoneNumber = ((_p = message.profileOneof) === null || _p === void 0 ? void 0 : _p.wabaPhoneNumber)
                ? WABAPhoneNumber.toJSON((_q = message.profileOneof) === null || _q === void 0 ? void 0 : _q.wabaPhoneNumber)
                : undefined);
        ((_r = message.profileOneof) === null || _r === void 0 ? void 0 : _r.$case) === "twilioSmsProfile" &&
            (obj.twilioSmsProfile = ((_s = message.profileOneof) === null || _s === void 0 ? void 0 : _s.twilioSmsProfile)
                ? PhoneNumberProfile.toJSON((_t = message.profileOneof) === null || _t === void 0 ? void 0 : _t.twilioSmsProfile)
                : undefined);
        ((_u = message.profileOneof) === null || _u === void 0 ? void 0 : _u.$case) === "zendeskProfile" &&
            (obj.zendeskProfile = ((_v = message.profileOneof) === null || _v === void 0 ? void 0 : _v.zendeskProfile)
                ? ZendeskProfile.toJSON((_w = message.profileOneof) === null || _w === void 0 ? void 0 : _w.zendeskProfile)
                : undefined);
        ((_x = message.profileOneof) === null || _x === void 0 ? void 0 : _x.$case) === "nupayProfile" &&
            (obj.nupayProfile = ((_y = message.profileOneof) === null || _y === void 0 ? void 0 : _y.nupayProfile)
                ? NuPayProfile.toJSON((_z = message.profileOneof) === null || _z === void 0 ? void 0 : _z.nupayProfile)
                : undefined);
        return obj;
    },
};
function createBaseBusinessChannel() {
    return {
        id: "",
        externalId: "",
        channelType: 0,
        accessToken: "",
        remoteProfile: undefined,
        multiplexConfig: undefined,
    };
}
export const BusinessChannel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.externalId !== "") {
            writer.uint32(18).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(24).int32(message.channelType);
        }
        if (message.accessToken !== "") {
            writer.uint32(34).string(message.accessToken);
        }
        if (message.remoteProfile !== undefined) {
            BusinessChannelRemoteProfile.encode(message.remoteProfile, writer.uint32(42).fork()).ldelim();
        }
        if (message.multiplexConfig !== undefined) {
            EventMultiplexConfig.encode(message.multiplexConfig, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.externalId = reader.string();
                    break;
                case 3:
                    message.channelType = reader.int32();
                    break;
                case 4:
                    message.accessToken = reader.string();
                    break;
                case 5:
                    message.remoteProfile = BusinessChannelRemoteProfile.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.multiplexConfig = EventMultiplexConfig.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            accessToken: isSet(object.accessToken) ? String(object.accessToken) : "",
            remoteProfile: isSet(object.remoteProfile)
                ? BusinessChannelRemoteProfile.fromJSON(object.remoteProfile)
                : undefined,
            multiplexConfig: isSet(object.multiplexConfig)
                ? EventMultiplexConfig.fromJSON(object.multiplexConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.accessToken !== undefined &&
            (obj.accessToken = message.accessToken);
        message.remoteProfile !== undefined &&
            (obj.remoteProfile = message.remoteProfile
                ? BusinessChannelRemoteProfile.toJSON(message.remoteProfile)
                : undefined);
        message.multiplexConfig !== undefined &&
            (obj.multiplexConfig = message.multiplexConfig
                ? EventMultiplexConfig.toJSON(message.multiplexConfig)
                : undefined);
        return obj;
    },
};
function createBaseEventMultiplexConfig() {
    return { configs: [] };
}
export const EventMultiplexConfig = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.configs) {
            EventMultiplexConfig_CustomerChannelMultiplexConfig.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventMultiplexConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configs.push(EventMultiplexConfig_CustomerChannelMultiplexConfig.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configs: Array.isArray(object === null || object === void 0 ? void 0 : object.configs)
                ? object.configs.map((e) => EventMultiplexConfig_CustomerChannelMultiplexConfig.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.configs) {
            obj.configs = message.configs.map((e) => e
                ? EventMultiplexConfig_CustomerChannelMultiplexConfig.toJSON(e)
                : undefined);
        }
        else {
            obj.configs = [];
        }
        return obj;
    },
};
function createBaseEventMultiplexConfig_CustomerChannelMultiplexConfig() {
    return { externalId: "", addresses: [] };
}
export const EventMultiplexConfig_CustomerChannelMultiplexConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.externalId !== "") {
            writer.uint32(10).string(message.externalId);
        }
        for (const v of message.addresses) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventMultiplexConfig_CustomerChannelMultiplexConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.externalId = reader.string();
                    break;
                case 2:
                    message.addresses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            addresses: Array.isArray(object === null || object === void 0 ? void 0 : object.addresses)
                ? object.addresses.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.externalId !== undefined && (obj.externalId = message.externalId);
        if (message.addresses) {
            obj.addresses = message.addresses.map((e) => e);
        }
        else {
            obj.addresses = [];
        }
        return obj;
    },
};
function createBaseBusinessKey() {
    return { businessId: "", type: 0, key: undefined, extra: undefined };
}
export const BusinessKey = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.key !== undefined) {
            StringValue.encode({ value: message.key }, writer.uint32(26).fork()).ldelim();
        }
        if (message.extra !== undefined) {
            BusinessKeyExtraConfigStore.encode(message.extra, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.key = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.extra = BusinessKeyExtraConfigStore.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            type: isSet(object.type) ? businessKeyTypeFromJSON(object.type) : 0,
            key: isSet(object.key) ? String(object.key) : undefined,
            extra: isSet(object.extra)
                ? BusinessKeyExtraConfigStore.fromJSON(object.extra)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.type !== undefined &&
            (obj.type = businessKeyTypeToJSON(message.type));
        message.key !== undefined && (obj.key = message.key);
        message.extra !== undefined &&
            (obj.extra = message.extra
                ? BusinessKeyExtraConfigStore.toJSON(message.extra)
                : undefined);
        return obj;
    },
};
function createBaseBusinessKeyExtraConfigStore() {
    return { typeOneof: undefined };
}
export const BusinessKeyExtraConfigStore = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "apiKey") {
            ApiKeyExtraConfig.encode(message.typeOneof.apiKey, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "webhookSecret") {
            WebhookSecretExtraConfig.encode(message.typeOneof.webhookSecret, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessKeyExtraConfigStore();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeOneof = {
                        $case: "apiKey",
                        apiKey: ApiKeyExtraConfig.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.typeOneof = {
                        $case: "webhookSecret",
                        webhookSecret: WebhookSecretExtraConfig.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeOneof: isSet(object.apiKey)
                ? { $case: "apiKey", apiKey: ApiKeyExtraConfig.fromJSON(object.apiKey) }
                : isSet(object.webhookSecret)
                    ? {
                        $case: "webhookSecret",
                        webhookSecret: WebhookSecretExtraConfig.fromJSON(object.webhookSecret),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "apiKey" &&
            (obj.apiKey = ((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.apiKey)
                ? ApiKeyExtraConfig.toJSON((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.apiKey)
                : undefined);
        ((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "webhookSecret" &&
            (obj.webhookSecret = ((_e = message.typeOneof) === null || _e === void 0 ? void 0 : _e.webhookSecret)
                ? WebhookSecretExtraConfig.toJSON((_f = message.typeOneof) === null || _f === void 0 ? void 0 : _f.webhookSecret)
                : undefined);
        return obj;
    },
};
function createBaseApiKeyExtraConfig() {
    return { userId: "", roles: [] };
}
export const ApiKeyExtraConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.userId !== "") {
            writer.uint32(10).string(message.userId);
        }
        writer.uint32(18).fork();
        for (const v of message.roles) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseApiKeyExtraConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roles.push(reader.int32());
                        }
                    }
                    else {
                        message.roles.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            userId: isSet(object.userId) ? String(object.userId) : "",
            roles: Array.isArray(object === null || object === void 0 ? void 0 : object.roles)
                ? object.roles.map((e) => roleFromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.userId !== undefined && (obj.userId = message.userId);
        if (message.roles) {
            obj.roles = message.roles.map((e) => roleToJSON(e));
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
};
function createBaseWebhookSecretExtraConfig() {
    return {};
}
export const WebhookSecretExtraConfig = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWebhookSecretExtraConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseCustomerChannel() {
    return {
        id: "",
        externalId: "",
        channelType: 0,
        externalProfile: undefined,
        tags: [],
        eligibility: undefined,
        businessChannelId: "",
    };
}
export const CustomerChannel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.externalId !== "") {
            writer.uint32(18).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(24).int32(message.channelType);
        }
        if (message.externalProfile !== undefined) {
            ExternalProfile.encode(message.externalProfile, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.tags) {
            writer.uint32(42).string(v);
        }
        if (message.eligibility !== undefined) {
            Eligibility.encode(message.eligibility, writer.uint32(50).fork()).ldelim();
        }
        if (message.businessChannelId !== "") {
            writer.uint32(58).string(message.businessChannelId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerChannel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.externalId = reader.string();
                    break;
                case 3:
                    message.channelType = reader.int32();
                    break;
                case 4:
                    message.externalProfile = ExternalProfile.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.tags.push(reader.string());
                    break;
                case 6:
                    message.eligibility = Eligibility.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.businessChannelId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            externalProfile: isSet(object.externalProfile)
                ? ExternalProfile.fromJSON(object.externalProfile)
                : undefined,
            tags: Array.isArray(object === null || object === void 0 ? void 0 : object.tags)
                ? object.tags.map((e) => String(e))
                : [],
            eligibility: isSet(object.eligibility)
                ? Eligibility.fromJSON(object.eligibility)
                : undefined,
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.externalProfile !== undefined &&
            (obj.externalProfile = message.externalProfile
                ? ExternalProfile.toJSON(message.externalProfile)
                : undefined);
        if (message.tags) {
            obj.tags = message.tags.map((e) => e);
        }
        else {
            obj.tags = [];
        }
        message.eligibility !== undefined &&
            (obj.eligibility = message.eligibility
                ? Eligibility.toJSON(message.eligibility)
                : undefined);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        return obj;
    },
};
function createBaseQueryCustomerChannelsInput() {
    return { businessId: "", channelTypes: [] };
}
export const QueryCustomerChannelsInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        writer.uint32(18).fork();
        for (const v of message.channelTypes) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryCustomerChannelsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.channelTypes.push(reader.int32());
                        }
                    }
                    else {
                        message.channelTypes.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            channelTypes: Array.isArray(object === null || object === void 0 ? void 0 : object.channelTypes)
                ? object.channelTypes.map((e) => channelTypeFromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        if (message.channelTypes) {
            obj.channelTypes = message.channelTypes.map((e) => channelTypeToJSON(e));
        }
        else {
            obj.channelTypes = [];
        }
        return obj;
    },
};
function createBaseCustomerChannelCollection() {
    return { channels: [] };
}
export const CustomerChannelCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.channels) {
            CustomerChannel.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerChannelCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channels.push(CustomerChannel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channels: Array.isArray(object === null || object === void 0 ? void 0 : object.channels)
                ? object.channels.map((e) => CustomerChannel.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.channels) {
            obj.channels = message.channels.map((e) => e ? CustomerChannel.toJSON(e) : undefined);
        }
        else {
            obj.channels = [];
        }
        return obj;
    },
};
function createBaseEligibility() {
    return {
        type: 0,
        sourceEventId: "",
        startTime: undefined,
        endTime: undefined,
    };
}
export const Eligibility = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.sourceEventId !== "") {
            writer.uint32(18).string(message.sourceEventId);
        }
        if (message.startTime !== undefined) {
            Timestamp.encode(toTimestamp(message.startTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.endTime !== undefined) {
            Timestamp.encode(toTimestamp(message.endTime), writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEligibility();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.sourceEventId = reader.string();
                    break;
                case 3:
                    message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? eligibilityTypeFromJSON(object.type) : 0,
            sourceEventId: isSet(object.sourceEventId)
                ? String(object.sourceEventId)
                : "",
            startTime: isSet(object.startTime) ? String(object.startTime) : undefined,
            endTime: isSet(object.endTime) ? String(object.endTime) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = eligibilityTypeToJSON(message.type));
        message.sourceEventId !== undefined &&
            (obj.sourceEventId = message.sourceEventId);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
};
function createBaseUserScope() {
    return { userId: "", businessId: "", roles: [] };
}
export const UserScope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.userId !== "") {
            writer.uint32(10).string(message.userId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        writer.uint32(26).fork();
        for (const v of message.roles) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserScope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roles.push(reader.int32());
                        }
                    }
                    else {
                        message.roles.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            userId: isSet(object.userId) ? String(object.userId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            roles: Array.isArray(object === null || object === void 0 ? void 0 : object.roles)
                ? object.roles.map((e) => roleFromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.userId !== undefined && (obj.userId = message.userId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        if (message.roles) {
            obj.roles = message.roles.map((e) => roleToJSON(e));
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
};
function createBaseQuickReplyTemplateCollection() {
    return { templates: [] };
}
export const QuickReplyTemplateCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.templates) {
            QuickReplyTemplate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuickReplyTemplateCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templates.push(QuickReplyTemplate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templates: Array.isArray(object === null || object === void 0 ? void 0 : object.templates)
                ? object.templates.map((e) => QuickReplyTemplate.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.templates) {
            obj.templates = message.templates.map((e) => e ? QuickReplyTemplate.toJSON(e) : undefined);
        }
        else {
            obj.templates = [];
        }
        return obj;
    },
};
function createBaseMutationCreateQuickReplyTemplateInput() {
    return { entity: undefined };
}
export const MutationCreateQuickReplyTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.entity !== undefined) {
            QuickReplyTemplate.encode(message.entity, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationCreateQuickReplyTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entity = QuickReplyTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entity: isSet(object.entity)
                ? QuickReplyTemplate.fromJSON(object.entity)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? QuickReplyTemplate.toJSON(message.entity)
                : undefined);
        return obj;
    },
};
function createBaseMutationUpdateQuickReplyTemplateInput() {
    return { entity: undefined };
}
export const MutationUpdateQuickReplyTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.entity !== undefined) {
            QuickReplyTemplate.encode(message.entity, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationUpdateQuickReplyTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entity = QuickReplyTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entity: isSet(object.entity)
                ? QuickReplyTemplate.fromJSON(object.entity)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? QuickReplyTemplate.toJSON(message.entity)
                : undefined);
        return obj;
    },
};
function createBaseQuickReplyTemplate() {
    return {
        id: "",
        businessId: "",
        authorId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        header: "",
        bodyTemplate: "",
        variables: [],
    };
}
export const QuickReplyTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.authorId !== undefined) {
            StringValue.encode({ value: message.authorId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.header !== "") {
            writer.uint32(90).string(message.header);
        }
        if (message.bodyTemplate !== "") {
            writer.uint32(98).string(message.bodyTemplate);
        }
        for (const v of message.variables) {
            QuickReplyTemplateVariable.encode(v, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuickReplyTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.authorId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.header = reader.string();
                    break;
                case 12:
                    message.bodyTemplate = reader.string();
                    break;
                case 13:
                    message.variables.push(QuickReplyTemplateVariable.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            authorId: isSet(object.authorId) ? String(object.authorId) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            header: isSet(object.header) ? String(object.header) : "",
            bodyTemplate: isSet(object.bodyTemplate)
                ? String(object.bodyTemplate)
                : "",
            variables: Array.isArray(object === null || object === void 0 ? void 0 : object.variables)
                ? object.variables.map((e) => QuickReplyTemplateVariable.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.authorId !== undefined && (obj.authorId = message.authorId);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.header !== undefined && (obj.header = message.header);
        message.bodyTemplate !== undefined &&
            (obj.bodyTemplate = message.bodyTemplate);
        if (message.variables) {
            obj.variables = message.variables.map((e) => e ? QuickReplyTemplateVariable.toJSON(e) : undefined);
        }
        else {
            obj.variables = [];
        }
        return obj;
    },
};
function createBaseQuickReplyTemplateVariable() {
    return { key: "" };
}
export const QuickReplyTemplateVariable = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuickReplyTemplateVariable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        return obj;
    },
};
function createBaseBusinessChannelSelector() {
    return { businessId: "", predicateOneof: undefined };
}
export const BusinessChannelSelector = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byId") {
            BusinessChannelSelector_ByID.encode(message.predicateOneof.byId, writer.uint32(18).fork()).ldelim();
        }
        if (((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.$case) === "byTypeAndExternalId") {
            BusinessChannelSelector_ByTypeAndExternalID.encode(message.predicateOneof.byTypeAndExternalId, writer.uint32(26).fork()).ldelim();
        }
        if (((_c = message.predicateOneof) === null || _c === void 0 ? void 0 : _c.$case) === "byTypeOnly") {
            BusinessChannelSelector_ByType.encode(message.predicateOneof.byTypeOnly, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelSelector();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.predicateOneof = {
                        $case: "byId",
                        byId: BusinessChannelSelector_ByID.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.predicateOneof = {
                        $case: "byTypeAndExternalId",
                        byTypeAndExternalId: BusinessChannelSelector_ByTypeAndExternalID.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.predicateOneof = {
                        $case: "byTypeOnly",
                        byTypeOnly: BusinessChannelSelector_ByType.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            predicateOneof: isSet(object.byId)
                ? {
                    $case: "byId",
                    byId: BusinessChannelSelector_ByID.fromJSON(object.byId),
                }
                : isSet(object.byTypeAndExternalId)
                    ? {
                        $case: "byTypeAndExternalId",
                        byTypeAndExternalId: BusinessChannelSelector_ByTypeAndExternalID.fromJSON(object.byTypeAndExternalId),
                    }
                    : isSet(object.byTypeOnly)
                        ? {
                            $case: "byTypeOnly",
                            byTypeOnly: BusinessChannelSelector_ByType.fromJSON(object.byTypeOnly),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        ((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byId" &&
            (obj.byId = ((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.byId)
                ? BusinessChannelSelector_ByID.toJSON((_c = message.predicateOneof) === null || _c === void 0 ? void 0 : _c.byId)
                : undefined);
        ((_d = message.predicateOneof) === null || _d === void 0 ? void 0 : _d.$case) === "byTypeAndExternalId" &&
            (obj.byTypeAndExternalId = ((_e = message.predicateOneof) === null || _e === void 0 ? void 0 : _e.byTypeAndExternalId)
                ? BusinessChannelSelector_ByTypeAndExternalID.toJSON((_f = message.predicateOneof) === null || _f === void 0 ? void 0 : _f.byTypeAndExternalId)
                : undefined);
        ((_g = message.predicateOneof) === null || _g === void 0 ? void 0 : _g.$case) === "byTypeOnly" &&
            (obj.byTypeOnly = ((_h = message.predicateOneof) === null || _h === void 0 ? void 0 : _h.byTypeOnly)
                ? BusinessChannelSelector_ByType.toJSON((_j = message.predicateOneof) === null || _j === void 0 ? void 0 : _j.byTypeOnly)
                : undefined);
        return obj;
    },
};
function createBaseBusinessChannelSelector_ByID() {
    return { id: "" };
}
export const BusinessChannelSelector_ByID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelSelector_ByID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseBusinessChannelSelector_ByTypeAndExternalID() {
    return { externalId: "", channelType: 0 };
}
export const BusinessChannelSelector_ByTypeAndExternalID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.externalId !== "") {
            writer.uint32(10).string(message.externalId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelSelector_ByTypeAndExternalID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.externalId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        return obj;
    },
};
function createBaseBusinessChannelSelector_ByType() {
    return { channelType: 0 };
}
export const BusinessChannelSelector_ByType = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channelType !== 0) {
            writer.uint32(24).int32(message.channelType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelSelector_ByType();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 3:
                    message.channelType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        return obj;
    },
};
function createBaseBusinessChannelFilter() {
    return { predicateOneof: undefined };
}
export const BusinessChannelFilter = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byType") {
            BusinessChannelFilter_ByType.encode(message.predicateOneof.byType, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelFilter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.predicateOneof = {
                        $case: "byType",
                        byType: BusinessChannelFilter_ByType.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            predicateOneof: isSet(object.byType)
                ? {
                    $case: "byType",
                    byType: BusinessChannelFilter_ByType.fromJSON(object.byType),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byType" &&
            (obj.byType = ((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.byType)
                ? BusinessChannelFilter_ByType.toJSON((_c = message.predicateOneof) === null || _c === void 0 ? void 0 : _c.byType)
                : undefined);
        return obj;
    },
};
function createBaseBusinessChannelFilter_ByType() {
    return { type: 0 };
}
export const BusinessChannelFilter_ByType = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessChannelFilter_ByType();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? channelTypeFromJSON(object.type) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = channelTypeToJSON(message.type));
        return obj;
    },
};
function createBaseMutationDeleteBusinessChannelInput() {
    return { businessId: "", businessChannelId: "" };
}
export const MutationDeleteBusinessChannelInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationDeleteBusinessChannelInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        return obj;
    },
};
function createBaseOrganization() {
    return {
        id: "",
        name: "",
        salesPersonId: "",
        billingPlatform: 0,
        platformCustomerId: "",
        products: [],
        conversations: 0,
        pricingPlanId: "",
        subscription: undefined,
        paymentMethod: undefined,
        email: "",
        paymentMethodStatus: 0,
        trialExpiredAt: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
    };
}
export const Organization = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.salesPersonId !== "") {
            writer.uint32(26).string(message.salesPersonId);
        }
        if (message.billingPlatform !== 0) {
            writer.uint32(128).int32(message.billingPlatform);
        }
        if (message.platformCustomerId !== "") {
            writer.uint32(34).string(message.platformCustomerId);
        }
        for (const v of message.products) {
            writer.uint32(42).string(v);
        }
        if (message.conversations !== 0) {
            writer.uint32(48).uint32(message.conversations);
        }
        if (message.pricingPlanId !== "") {
            writer.uint32(58).string(message.pricingPlanId);
        }
        if (message.subscription !== undefined) {
            Subscription.encode(message.subscription, writer.uint32(106).fork()).ldelim();
        }
        if (message.paymentMethod !== undefined) {
            PaymentMethod.encode(message.paymentMethod, writer.uint32(114).fork()).ldelim();
        }
        if (message.email !== "") {
            writer.uint32(122).string(message.email);
        }
        if (message.paymentMethodStatus !== 0) {
            writer.uint32(64).int32(message.paymentMethodStatus);
        }
        if (message.trialExpiredAt !== undefined) {
            Timestamp.encode(toTimestamp(message.trialExpiredAt), writer.uint32(74).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(82).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(90).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrganization();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.salesPersonId = reader.string();
                    break;
                case 16:
                    message.billingPlatform = reader.int32();
                    break;
                case 4:
                    message.platformCustomerId = reader.string();
                    break;
                case 5:
                    message.products.push(reader.string());
                    break;
                case 6:
                    message.conversations = reader.uint32();
                    break;
                case 7:
                    message.pricingPlanId = reader.string();
                    break;
                case 13:
                    message.subscription = Subscription.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.paymentMethod = PaymentMethod.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.email = reader.string();
                    break;
                case 8:
                    message.paymentMethodStatus = reader.int32();
                    break;
                case 9:
                    message.trialExpiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            salesPersonId: isSet(object.salesPersonId)
                ? String(object.salesPersonId)
                : "",
            billingPlatform: isSet(object.billingPlatform)
                ? billingPlatformFromJSON(object.billingPlatform)
                : 0,
            platformCustomerId: isSet(object.platformCustomerId)
                ? String(object.platformCustomerId)
                : "",
            products: Array.isArray(object === null || object === void 0 ? void 0 : object.products)
                ? object.products.map((e) => String(e))
                : [],
            conversations: isSet(object.conversations)
                ? Number(object.conversations)
                : 0,
            pricingPlanId: isSet(object.pricingPlanId)
                ? String(object.pricingPlanId)
                : "",
            subscription: isSet(object.subscription)
                ? Subscription.fromJSON(object.subscription)
                : undefined,
            paymentMethod: isSet(object.paymentMethod)
                ? PaymentMethod.fromJSON(object.paymentMethod)
                : undefined,
            email: isSet(object.email) ? String(object.email) : "",
            paymentMethodStatus: isSet(object.paymentMethodStatus)
                ? paymentMethodStatusFromJSON(object.paymentMethodStatus)
                : 0,
            trialExpiredAt: isSet(object.trialExpiredAt)
                ? String(object.trialExpiredAt)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.salesPersonId !== undefined &&
            (obj.salesPersonId = message.salesPersonId);
        message.billingPlatform !== undefined &&
            (obj.billingPlatform = billingPlatformToJSON(message.billingPlatform));
        message.platformCustomerId !== undefined &&
            (obj.platformCustomerId = message.platformCustomerId);
        if (message.products) {
            obj.products = message.products.map((e) => e);
        }
        else {
            obj.products = [];
        }
        message.conversations !== undefined &&
            (obj.conversations = Math.round(message.conversations));
        message.pricingPlanId !== undefined &&
            (obj.pricingPlanId = message.pricingPlanId);
        message.subscription !== undefined &&
            (obj.subscription = message.subscription
                ? Subscription.toJSON(message.subscription)
                : undefined);
        message.paymentMethod !== undefined &&
            (obj.paymentMethod = message.paymentMethod
                ? PaymentMethod.toJSON(message.paymentMethod)
                : undefined);
        message.email !== undefined && (obj.email = message.email);
        message.paymentMethodStatus !== undefined &&
            (obj.paymentMethodStatus = paymentMethodStatusToJSON(message.paymentMethodStatus));
        message.trialExpiredAt !== undefined &&
            (obj.trialExpiredAt = message.trialExpiredAt);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBaseSavedAudienceList() {
    return { id: "" };
}
export const SavedAudienceList = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSavedAudienceList();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseSavedAudienceListData() {
    return { items: [] };
}
export const SavedAudienceListData = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.items) {
            SavedAudienceListItem.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSavedAudienceListData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.items.push(SavedAudienceListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => SavedAudienceListItem.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.items) {
            obj.items = message.items.map((e) => e ? SavedAudienceListItem.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        return obj;
    },
};
function createBaseSavedAudienceListItem() {
    return { id: "" };
}
export const SavedAudienceListItem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSavedAudienceListItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
