import React, { MouseEventHandler, FC, PropsWithChildren } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Button,
  EditIcon,
  FileCopyIcon,
  DeleteIcon,
  Tooltip,
  BoltIcon,
  CampaignMUIIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { FlowContext } from '../../contexts';
import { useTemplateCategory } from '../FlowChartCampaignV3/hooks/useTemplateCategory';

type HoverDecoratorButtonProps = {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

export type HoverDecoratorProps = {
  nodeId: string;
  deleteDisabled?: boolean;
  duplicateDisabled?: boolean;
  editDisabled?: boolean;
};

const HoverDecoratorButton: FC<PropsWithChildren<HoverDecoratorButtonProps>> = ({
  title,
  children,
  onClick,
  disabled,
}) => (
  <Tooltip
    title={title}
    placement="top"
    // https://github.com/mui/material-ui/issues/28679
    componentsProps={{
      tooltip: { sx: { mb: '6px !important' } },
    }}
  >
    <Button
      onClick={onClick}
      size="small"
      variant="contained"
      color="secondary"
      disabled={disabled}
      sx={{ width: (theme) => theme.spacing(6), minWidth: (theme) => theme.spacing(6), px: '0 !important' }}
    >
      {children}
    </Button>
  </Tooltip>
);

HoverDecoratorButton.defaultProps = {
  disabled: false,
};

export const HoverDecorator: FC<PropsWithChildren<HoverDecoratorProps>> = ({
  children,
  duplicateDisabled,
  deleteDisabled,
  editDisabled,
  nodeId,
}) => {
  const isDocumentEditable = useContextSelector(FlowContext, (context) => context.isDocumentEditable);
  const { createDeleteHandler, createDuplicateHandler, createEditHandler } = useContextSelector(
    FlowContext,
    (context) => context.flowHandlers,
  );

  const {
    canChangeTemplateCategory,
    handleChangeTemplateCategory,
    showTemplateCategoryButton,
    templateCategoryText,
    templateCategoryIcon,
  } = useTemplateCategory(nodeId);

  if (!isDocumentEditable) return <Box>{children}</Box>;

  const handleDelete = createDeleteHandler(nodeId);
  const handleDuplicate = createDuplicateHandler(nodeId);
  const handleEdit = createEditHandler(nodeId);

  return (
    <Box
      sx={{
        '& > div:first-child': { display: 'none' },
        '&:hover > div:first-child': { display: 'flex' },
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          gap: 1.5,
          paddingBottom: 1.5,
          justifyContent: 'flex-end',
          width: '100%',
          top: -44,
        }}
      >
        {!editDisabled && (
          <HoverDecoratorButton title="Edit" onClick={handleEdit}>
            <EditIcon />
          </HoverDecoratorButton>
        )}
        {showTemplateCategoryButton && (
          <HoverDecoratorButton
            title={templateCategoryText}
            onClick={handleChangeTemplateCategory}
            disabled={!canChangeTemplateCategory}
          >
            {templateCategoryIcon}
          </HoverDecoratorButton>
        )}
        {!duplicateDisabled && (
          <HoverDecoratorButton title="Duplicate" onClick={handleDuplicate}>
            <FileCopyIcon />
          </HoverDecoratorButton>
        )}
        {!deleteDisabled && (
          <HoverDecoratorButton title="Delete" onClick={handleDelete}>
            <DeleteIcon />
          </HoverDecoratorButton>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

HoverDecorator.defaultProps = {
  deleteDisabled: false,
  duplicateDisabled: false,
  editDisabled: false,
};
