import React, { ComponentProps, FC, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import ReactFlow, { Background, Controls, ReactFlowInstance, ReactFlowProps, MarkerType } from 'react-flow-renderer';
import { useSelector, useDispatch } from 'react-redux';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { Box, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartAiAssistantNode } from '../../../components/FlowChartAiAssistantNode';
import { useAutoReplyFlowChangeAppliers } from '../../../features/flow-autoreply/hooks/useAutoReplyFlowChangeAppliers';
import { useFlowFromPreview } from '../../../features/flow-autoreply/hooks/useFlowFromPreview';
import {
  selectEdges,
  selectNodes,
  setDocumentId,
  selectIsDocumentEditable,
} from '../../../features/flow-autoreply/state';
import appStore from '../../../state/store';
import { FlowChartHeader } from '../../../components/FlowChartHeader';
import {
  HoverDecorator,
  FlowChartNodeSidebar,
  FlowChartSidebarPlaceholder,
  FlowChartCustomAutoReplyNode,
  MessageNode,
} from '../../../components';
import { FlowContext } from '../../../contexts';
import { FlowChartCustomEdge } from '../../../components/FlowChartCustomEdge/FlowChartCustomEdge';
import { FlowChartAutoReplyV1Sidebar } from './FlowChartAutoReplyV1Sidebar';
import { FlowChartIncomingRoomEvent } from '../../../components/FlowChartIncomingRoomEvent';
import {
  selectBusinessId,
  useChangeFlowDocumentMutation,
  useCreateMessageTemplateResultData,
  useChangesQueue,
  useFlowHandlers,
  useFlowSendChange,
  useMeData,
} from '../../../hooks';
import { NodeChange, NodeObjectUpdate } from '../../../hooks/useChangesQueue';
import { ChangeFlowDocumentResponse } from '../../../hooks/useChangeFlowDocumentMutation';
import { FlowHandlers } from '../../../hooks/useFlowHandlers';
import { resetFlowContent } from '../../../state/flow';

const isAutoReplyBodyVariablesEnabled = getFlagByName('ffEnableAutoReplyBodyVariables');

type FlowChartAutoReplyV1Props = {
  documentId: string;
};

export const FlowChartSendWaMessage = (props: ComponentProps<typeof MessageNode>) => (
  <HoverDecorator nodeId={props?.id || ''}>
    <MessageNode {...props} />
  </HoverDecorator>
);

export const FlowChartIncomingRoomEventDecorated = (props: ComponentProps<typeof FlowChartIncomingRoomEvent>) => (
  <FlowChartIncomingRoomEvent {...props} />
);

export const FlowChartSendConnectlyTemplateMessageDecorated = (
  props: ComponentProps<typeof FlowChartIncomingRoomEvent>,
) => (
  <HoverDecorator nodeId={props?.id || ''}>
    <MessageNode nodeType="FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE" {...props} />
  </HoverDecorator>
);

export const FlowChartAIAssistantDecorated = (props: ComponentProps<typeof FlowChartAiAssistantNode>) => (
  <HoverDecorator nodeId={props?.id || ''} editDisabled duplicateDisabled>
    <FlowChartAiAssistantNode {...props} />
  </HoverDecorator>
);

export const FlowChartAutoReplyV1: FC<FlowChartAutoReplyV1Props> = ({ documentId }) => {
  const flowRef = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

  const dispatch = useDispatch();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  // use getState rather than selector to get nodes and edges immediately
  const nodesCurrent = useCallback(() => selectNodes(appStore.getState()), []);

  const { sendableChange } = useChangesQueue();
  const flowChangeAppliers = useAutoReplyFlowChangeAppliers();
  const { onEdgesChangeProtected, onNodesChangeProtected, updateNodeId, updateEdgeId } = flowChangeAppliers;
  const flowHandlers: FlowHandlers = useFlowHandlers({ flowChangeAppliers: useAutoReplyFlowChangeAppliers });
  const isNodePotentialTarget = useCallback(
    (connectionNodeId: string | undefined | null, nodeId: string) => {
      if (!connectionNodeId || !nodeId || connectionNodeId === nodeId) {
        return false;
      }
      const currentNodes = nodesCurrent();
      const startNode = currentNodes.find((node) => node.id === connectionNodeId);
      const endNode = currentNodes.find((node) => node.id === nodeId);
      switch (startNode?.type) {
        case 'FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY':
          return endNode?.type === 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT';
        case 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT':
          if (endNode?.type === 'FLOW_OBJECT_TYPE_AI_ASSISTANT') return true;
          if (endNode?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE') return true;
          if (endNode?.type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE') return true;
          return false;
        case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE':
          if (endNode?.type === 'FLOW_OBJECT_TYPE_AI_ASSISTANT') return true;
          return endNode?.type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE';
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE':
          if (endNode?.type === 'FLOW_OBJECT_TYPE_AI_ASSISTANT') return true;
          return endNode?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE';
        case 'FLOW_OBJECT_TYPE_AI_ASSISTANT':
          return false;
        default:
          return false;
      }
    },
    [nodesCurrent],
  );

  const isDocumentEditable = useSelector(selectIsDocumentEditable);

  const flowDeps = useMemo(
    () => ({
      flowChangeAppliers,
      flowHandlers,
      isDocumentEditable,
      isNodePotentialTarget,
      supportsLinkTracking: false,
      supportsVariables: isAutoReplyBodyVariablesEnabled,
    }),
    [flowChangeAppliers, flowHandlers, isDocumentEditable, isNodePotentialTarget],
  );

  const {
    isLoading: isLoadingPreviewDocument,
    isSuccess: isSuccessLoadingPreviewDocument,
    loadFlowFromPreview,
  } = useFlowFromPreview();

  useEffect(() => {
    dispatch(setDocumentId(documentId));
  }, [dispatch, documentId]);

  useEffect(() => {
    if (isLoadingPreviewDocument) {
      dispatch(resetFlowContent());
    } else if (isSuccessLoadingPreviewDocument) {
      loadFlowFromPreview();
    }
  }, [dispatch, isLoadingPreviewDocument, isSuccessLoadingPreviewDocument, loadFlowFromPreview]);

  const edges = useSelector(selectEdges);
  const nodes = useSelector(selectNodes);
  const theme = useTheme();

  // #section state management

  const { mutate: changeFlowDocumentMutate, isLoading: isLoadingChangeFlow } = useChangeFlowDocumentMutation();
  const useFlowSendChangeOnSuccessNodeChange = useCallback(
    (nodeChange: NodeChange, receivedData: ChangeFlowDocumentResponse) => {
      if (nodeChange.type === 'add' && receivedData.entity?.nodeChange?.options) {
        const parsed = JSON.parse(receivedData.entity?.nodeChange?.options);
        const newId = parsed?.item?.id;
        if (newId) {
          updateNodeId?.(nodeChange.item.id, newId);
        }
      }
    },
    [updateNodeId],
  );
  const useFlowSendChangeOnSuccessNodeObjectUpdate = useCallback(
    (_nodeObjectUpdate: NodeObjectUpdate, _receivedData: ChangeFlowDocumentResponse) => {},
    [],
  );
  const { sendChange } = useFlowSendChange({
    businessId,
    changeFlowDocumentMutate,
    flowDocumentId: documentId,
    updateEdgeId,
    onSuccessNodeChange: useFlowSendChangeOnSuccessNodeChange,
    onSuccessNodeObjectUpdate: useFlowSendChangeOnSuccessNodeObjectUpdate,
  });

  // if campaign document is saved and no change is loading send one node change to server
  useEffect(() => {
    if (documentId && !isLoadingChangeFlow) {
      const change = sendableChange();
      if (change) {
        sendChange(change);
      }
    }
  }, [documentId, sendableChange, isLoadingChangeFlow, sendChange]);

  useCreateMessageTemplateResultData();

  // #endsection

  const defaultEdgeOptions = useMemo(
    () => ({
      markerEnd: { type: MarkerType.ArrowClosed, color: theme.palette.connectionLine },
      style: { stroke: theme.palette.connectionLine, strokeWidth: 2, fill: 'none' },
      type: 'FLOW_OBJECT_TYPE_SIMPLE_EDGE',
    }),
    [theme.palette.connectionLine],
  );

  const edgeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SIMPLE_EDGE: FlowChartCustomEdge,
    }),
    [],
  );
  const nodeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SEND_WA_MESSAGE: FlowChartSendWaMessage,
      FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY: FlowChartCustomAutoReplyNode,
      FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT: FlowChartIncomingRoomEventDecorated,
      FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE: FlowChartSendConnectlyTemplateMessageDecorated,
      FLOW_OBJECT_TYPE_AI_ASSISTANT: FlowChartAIAssistantDecorated,
    }),
    [],
  );

  const reactFlowProps: ReactFlowProps = {
    defaultEdgeOptions,
    deleteKeyCode: null,
    edges,
    edgeTypes,
    minZoom: 0.1,
    maxZoom: 2.5,
    selectionKeyCode: null,
    multiSelectionKeyCode: null,
    nodes,
    nodeTypes,
    onConnect: flowHandlers.handleConnect,
    onDrop: (e) => flowHandlers.handleDrop(e, reactFlowInstance, flowRef.current),
    onDragOver: flowHandlers.handleDragOver,
    onEdgesChange: onEdgesChangeProtected,
    onEdgeUpdate: flowHandlers.handleEdgeUpdate,
    onEdgeUpdateEnd: flowHandlers.handleEdgeUpdateEnd,
    onEdgeUpdateStart: flowHandlers.handleEdgeUpdateStart,
    onInit: setReactFlowInstance,
    onNodesChange: onNodesChangeProtected,
    proOptions: { account: 'paid-custom', hideAttribution: true },
    selectNodesOnDrag: false,
    snapToGrid: true,
  };

  return (
    <FlowContext.Provider value={flowDeps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <FlowChartHeader>Auto Reply Doc {documentId}</FlowChartHeader>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            background: theme.palette.flowGradient,
          }}
        >
          <FlowChartSidebarPlaceholder>
            <FlowChartAutoReplyV1Sidebar />
            <FlowChartNodeSidebar />
          </FlowChartSidebarPlaceholder>
          <Box ref={flowRef} sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <ReactFlow {...reactFlowProps}>
              <Background />
              <Controls showInteractive={false} />
            </ReactFlow>
          </Box>
        </Box>
      </Box>
    </FlowContext.Provider>
  );
};
