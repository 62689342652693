import React, { FC, ChangeEventHandler } from 'react';
import {
  Box,
  DeleteOutlineIcon,
  IconButton,
  InputAdornment,
  Label,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { BUTTON_TEXT_MAX_LENGTH } from '@components/TemplateBuilderButtons';

const QuickReply: FC<{
  onDelete?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disableDelete?: boolean;
  index: number;
  errorText?: string;
  value: string;
}> = ({ onDelete, onChange, index, errorText, value, disableDelete }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} id={`message-template-button${index}-content`}>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 0.5,
        }}
      >
        <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium', flex: '1 1 auto' }}>
          Quick Reply
        </Label>
        {!disableDelete && (
          <IconButton size="small" onClick={onDelete} sx={{ m: '-5px 0' }}>
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Box>
      <TextField
        id={`message-template-button${index}-text`}
        fullWidth
        placeholder="Enter text"
        error={Boolean(errorText)}
        helperText={errorText}
        variant="outlined"
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        value={value}
        inputProps={{ maxLength: BUTTON_TEXT_MAX_LENGTH }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{`${value.length}/${BUTTON_TEXT_MAX_LENGTH}`}</InputAdornment>,
        }}
        onChange={onChange}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { borderRadius: '10px' },
          '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
        }}
      />
    </Box>
  );
};

export default QuickReply;
