import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Provider, createStore, useStore } from 'jotai';
import type { WritableAtom } from 'jotai/vanilla';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyWritableAtom = WritableAtom<unknown, any[], any>;

export const FusedStateProvider: FC<PropsWithChildren<{ atoms: AnyWritableAtom[] }>> = ({ atoms, children }) => {
  const generalStore = useStore();
  const limitedStore = createStore();

  useEffect(() => {
    const unsubs: (() => void)[] = [];
    atoms.forEach((atom) => {
      // set initial values of atoms in limitedStore
      limitedStore.set(atom, generalStore.get(atom));

      // if generalStore value of atom changes, sync limitedStore value of atom
      unsubs.push(
        generalStore.sub(atom, () => {
          if (limitedStore.get(atom) !== generalStore.get(atom)) {
            limitedStore.set(atom, generalStore.get(atom));
          }
        }),
      );

      // if limitedStore value of atom changes, sync generalStore value of atom
      unsubs.push(
        limitedStore.sub(atom, () => {
          if (limitedStore.get(atom) !== generalStore.get(atom)) {
            generalStore.set(atom, limitedStore.get(atom));
          }
        }),
      );
    });
    return () => {
      unsubs.forEach((unsub) => unsub());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider store={limitedStore}>{children}</Provider>;
};
