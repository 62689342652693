import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';
import { definitions } from '@connectlyai-tenets/sdk/src/schemes/campaignV3.generated';

export type CampaignSendoutInputType = definitions['campaignSendoutInputType'];

export const UPDATE_COHORT_URL = '/internal/v3/businesses/{input.businessId}/update_cohort/sendouts/{input.sendoutId}';
type UpdateCohortURL = typeof UPDATE_COHORT_URL;

export type UpdateCohortInput = Paths['campaignV3'][UpdateCohortURL]['post']['parameters']['body']['input'];

export type UpdateCohortVariables = UpdateCohortInput & {
  businessId: string;
  sendoutId: string;
};

export type UpdateCohortResponse = Paths['campaignV3'][UpdateCohortURL]['post']['responses']['200']['schema'];

export type UpdateCohortOptions = Omit<
  UseMutationOptions<UpdateCohortResponse, ConnectlyError, UpdateCohortVariables, unknown>,
  'mutationKey' | 'mutationFn'
>;
