import { AgentsResponse } from '@hooks/useAgentsData/types';
import { Role, UserList } from '@scenes/Settings/components/UserManagement';

export const computeChange = (c: number, l: number) => (c && l ? Math.floor((c - l) / l) * 100 : 0);

export const msToTimeFormat = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
};

export const selectAgents = (data: AgentsResponse): UserList => {
  if (!data) return [];
  const agents = data.entity?.agents ?? [];
  return agents.map((agent) => {
    const roles = agent?.scope?.roles || [];
    let role: Role = 'ROLE_AGENT';
    if (roles.includes('ROLE_OWNER')) {
      role = 'ROLE_OWNER';
    } else if (roles.includes('ROLE_MARKETING')) {
      role = 'ROLE_MARKETING';
    }
    return {
      id: agent.id || '',
      name: agent.displayName || '',
      email: agent.username || '',
      initials: `${agent.firstName?.charAt(0).toUpperCase() || ''}${agent.lastName?.charAt(0).toUpperCase() || ''}`,
      role,
    };
  });
};
