import React, { useMemo } from 'react';
import { Box, useTheme, blueGrey, ConnectlySection, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { KeyPerformanceIndicator, KeyPerformanceIndicatorV2 } from '@components';
import { SendoutAnalytics } from '@hooks/useQuerySendoutAnalytics/types';
import { useFeatureFlag } from '@hooks';
import { AggregateSendoutsDataProps, AnalyticsSendoutsAggregateDataProps } from './types';

const useAggregateData = (filteredRows: SendoutAnalytics[]) => {
  const totalSent = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.sentCount ?? 0), 0),
    [filteredRows],
  );
  const totalDelivered = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.deliveredCount ?? 0), 0),
    [filteredRows],
  );
  const deliveredRate = useMemo(() => ((totalDelivered || 0) / (totalSent || 0)) * 100, [totalSent, totalDelivered]);
  const totalOpened = useMemo(
    () =>
      filteredRows.reduce(
        // account for case where readCount is greater than deliveredCount
        (total, sendout) => total + Math.min(sendout?.deliveredCount ?? 0, sendout?.readCount ?? 0),
        0,
      ),
    [filteredRows],
  );
  const openRate = useMemo(() => ((totalOpened || 0) / (totalDelivered || 0)) * 100, [totalDelivered, totalOpened]);
  const uniqueSessionEngagment = useMemo(
    () =>
      filteredRows.reduce(
        (total, sendout) =>
          total + Math.min(sendout?.sendoutEngagement?.uniqueSessionEngagement ?? 0, sendout?.deliveredCount ?? 0),
        0,
      ),
    [filteredRows],
  );
  const uniqueSessionEngagementRate = useMemo(
    () => ((uniqueSessionEngagment || 0) / (totalOpened || 0)) * 100,
    [totalOpened, uniqueSessionEngagment],
  );
  const uniqueLinkClicks = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout.linkClicks?.uniqueLinkClicks ?? 0), 0),
    [filteredRows],
  );
  const uniqueLinkClicksRate = useMemo(
    () => ((uniqueLinkClicks || 0) / (totalOpened || 0)) * 100,
    [totalOpened, uniqueLinkClicks],
  );
  const errors = useMemo(
    () => filteredRows.reduce((total, sendout) => total + (sendout?.errors?.total ?? 0), 0),
    [filteredRows],
  );

  const uniqueCampaigns = useMemo(
    () => new Set(filteredRows.map((sendout) => sendout.campaignId)).size,
    [filteredRows],
  );

  return {
    totalSent,
    totalDelivered,
    errors,
    delivered: {
      total: totalDelivered,
      rate: deliveredRate,
    },
    opened: {
      total: totalOpened,
      rate: openRate,
    },
    engagement: {
      total: uniqueSessionEngagment,
      rate: uniqueSessionEngagementRate,
    },
    uniqueLinkClicks: {
      total: uniqueLinkClicks,
      rate: uniqueLinkClicksRate,
    },
    uniqueCampaigns,
  };
};
export const AnalyticsSendoutsAggregateData = ({
  filteredRows,
  prevFilteredData,
}: AnalyticsSendoutsAggregateDataProps) => {
  const theme = useTheme();
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });
  const { t: tGeneric } = useTranslation('translation', { keyPrefix: 'generic' });

  const { totalSent, totalDelivered, errors, delivered, opened, engagement, uniqueLinkClicks, uniqueCampaigns } =
    useAggregateData(filteredRows);

  const {
    totalSent: prevTotalSent,
    totalDelivered: prevTotalDelivered,
    errors: prevErrors,
    opened: prevOpened,
    engagement: prevEngagement,
    uniqueLinkClicks: prevUniqueLinkClicks,
    uniqueCampaigns: prevUniqueCampaigns,
  } = useAggregateData(prevFilteredData);

  const metrics = useMemo<AggregateSendoutsDataProps[]>(() => {
    let metricsArray = [
      {
        title: 'Delivered',
        value: delivered.total,
        rate: delivered.rate,
        hint: t('deliveredHint'),
        changeValue: prevTotalDelivered ? (totalDelivered / prevTotalDelivered - 1) * 100 : 0,
      },
      {
        title: 'Opened',
        value: opened.total,
        rate: (totalDelivered || 0) === 0 ? undefined : opened.rate,
        hint: t('openedHint'),
        changeValue: prevOpened.total ? (opened.total / prevOpened.total - 1) * 100 : 0,
      },
      {
        title: 'Engaged',
        value: engagement.total,
        rate: (totalDelivered || 0) === 0 ? undefined : engagement.rate,
        hint: t('engagementHint'),
        changeValue: prevEngagement.total ? (engagement.total / prevEngagement.total - 1) * 100 : 0,
      },
      {
        title: 'Unique Link Clicks',
        value: uniqueLinkClicks.total,
        rate: (totalDelivered || 0) === 0 ? undefined : uniqueLinkClicks.rate,
        hint: t('uniqueLinkClicksHint'),
        changeValue: prevUniqueLinkClicks.total ? (uniqueLinkClicks.total / prevUniqueLinkClicks.total - 1) * 100 : 0,
      },
      {
        title: 'Errors',
        value: errors,
        hint: t('errorsHint'),
        changeValue: prevErrors ? (errors / prevErrors - 1) * 100 : 0,
      },
    ];

    if (ffEnableAnalyticsRedesign) {
      metricsArray = [
        {
          title: 'Unique campaigns',
          value: uniqueCampaigns,
          hint: '',
          changeValue: prevUniqueCampaigns ? (uniqueCampaigns / prevUniqueCampaigns - 1) * 100 : 0,
        },
        {
          title: 'Sendouts sent',
          value: totalSent,
          hint: t('sentHint'),
          changeValue: prevTotalSent ? (totalSent / prevTotalSent - 1) * 100 : 0,
        },
        ...metricsArray,
      ];
    } else {
      metricsArray = [
        {
          title: 'Total Sent',
          value: totalSent,
          hint: t('sentHint'),
          changeValue: prevTotalSent ? (totalSent / prevTotalSent - 1) * 100 : 0,
        },
        ...metricsArray,
      ];
    }

    return metricsArray;
  }, [
    totalSent,
    t,
    prevTotalSent,
    delivered.total,
    delivered.rate,
    prevTotalDelivered,
    totalDelivered,
    opened.total,
    opened.rate,
    prevOpened.total,
    engagement.total,
    engagement.rate,
    prevEngagement.total,
    uniqueLinkClicks.total,
    uniqueLinkClicks.rate,
    prevUniqueLinkClicks.total,
    errors,
    prevErrors,
    ffEnableAnalyticsRedesign,
    uniqueCampaigns,
    prevUniqueCampaigns,
  ]);

  const keyPerformanceIndicatorContainerStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    borderTop: '1px solid #f0f0f0',
  };

  const Section = ffEnableAnalyticsRedesign ? ConnectlySection : ConnectlyCard;

  return (
    <Section
      title={ffEnableAnalyticsRedesign ? tGeneric('overview', 'Overview') : `${uniqueCampaigns} Campaigns`}
      subtitle={ffEnableAnalyticsRedesign ? '' : `${totalSent} sendouts`}
    >
      <Box
        sx={
          ffEnableAnalyticsRedesign
            ? keyPerformanceIndicatorContainerStyles
            : { display: 'flex', flexWrap: 'wrap', gap: 2 }
        }
      >
        {metrics.map((metric, i) => {
          const { title, value, rate, hint, changeValue, lowerIsBetter } = metric;

          if (ffEnableAnalyticsRedesign) {
            return (
              <KeyPerformanceIndicatorV2
                key={title}
                title={title}
                data={{ current: String(value), rate, change: changeValue }}
                isLoading={false}
                tooltip={hint}
                lowerIsBetter={lowerIsBetter}
                showCopyIcon
                sx={{
                  p: 2,
                  pl: i === 0 || i === 3 || i === 6 ? 0 : 2,
                  borderRight: i === 2 || i === 5 || i === 7 ? ' ' : `1px solid ${blueGrey[200]}`,
                  borderBottom: `1px solid ${blueGrey[200]}`,
                }}
              />
            );
          }

          return (
            <KeyPerformanceIndicator
              key={title}
              title={title}
              data={{ current: String(value), rate, change: changeValue }}
              isLoading={false}
              tooltip={hint}
              lowerIsBetter={lowerIsBetter}
              isAnalyticsPage
              sx={{ minWidth: theme.spacing(30) }}
            />
          );
        })}
      </Box>
    </Section>
  );
};
