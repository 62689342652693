import * as _m0 from "protobufjs/minimal";
import { StringValue, Int32Value, DoubleValue, BoolValue, } from "../../../google/protobuf/wrappers";
import { Struct } from "../../../google/protobuf/struct";
export const protobufPackage = "events.vtex.v1";
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["ORDER_STATUS_UNSPECIFIED"] = 0] = "ORDER_STATUS_UNSPECIFIED";
    OrderStatus[OrderStatus["ORDER_STATUS_ORDER_CREATED"] = 1] = "ORDER_STATUS_ORDER_CREATED";
    OrderStatus[OrderStatus["ORDER_STATUS_ORDER_ACCEPTED"] = 2] = "ORDER_STATUS_ORDER_ACCEPTED";
    OrderStatus[OrderStatus["ORDER_STATUS_CANCELING"] = 3] = "ORDER_STATUS_CANCELING";
    OrderStatus[OrderStatus["ORDER_STATUS_ON_ORDER_COMPLETED"] = 4] = "ORDER_STATUS_ON_ORDER_COMPLETED";
    OrderStatus[OrderStatus["ORDER_STATUS_ON_ORDER_COMPLETED_FFM"] = 5] = "ORDER_STATUS_ON_ORDER_COMPLETED_FFM";
    OrderStatus[OrderStatus["ORDER_STATUS_PAYMENT_APPROVED"] = 6] = "ORDER_STATUS_PAYMENT_APPROVED";
    OrderStatus[OrderStatus["ORDER_STATUS_PAYMENT_PENDING"] = 7] = "ORDER_STATUS_PAYMENT_PENDING";
    OrderStatus[OrderStatus["ORDER_STATUS_CANCELLATION_REQUESTED"] = 8] = "ORDER_STATUS_CANCELLATION_REQUESTED";
    OrderStatus[OrderStatus["ORDER_STATUS_CANCELED"] = 9] = "ORDER_STATUS_CANCELED";
    OrderStatus[OrderStatus["ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT"] = 10] = "ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT";
    OrderStatus[OrderStatus["ORDER_STATUS_WINDOW_TO_CHANGE_SELLER"] = 11] = "ORDER_STATUS_WINDOW_TO_CHANGE_SELLER";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION"] = 12] = "ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_FOR_FULFILLMENT"] = 13] = "ORDER_STATUS_WAITING_FOR_FULFILLMENT";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_FFM_AUTHORIZATION"] = 14] = "ORDER_STATUS_WAITING_FFM_AUTHORIZATION";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION"] = 15] = "ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION";
    OrderStatus[OrderStatus["ORDER_STATUS_AUTHORIZE_FULFILLMENT"] = 16] = "ORDER_STATUS_AUTHORIZE_FULFILLMENT";
    OrderStatus[OrderStatus["ORDER_STATUS_CANCELLATION_WINDOW"] = 17] = "ORDER_STATUS_CANCELLATION_WINDOW";
    OrderStatus[OrderStatus["ORDER_STATUS_READY_FOR_INVOICING"] = 18] = "ORDER_STATUS_READY_FOR_INVOICING";
    OrderStatus[OrderStatus["ORDER_STATUS_VERIFYING_INVOICE"] = 19] = "ORDER_STATUS_VERIFYING_INVOICE";
    OrderStatus[OrderStatus["ORDER_STATUS_INVOICED"] = 20] = "ORDER_STATUS_INVOICED";
    OrderStatus[OrderStatus["ORDER_STATUS_READY_FOR_HANDLING"] = 21] = "ORDER_STATUS_READY_FOR_HANDLING";
    OrderStatus[OrderStatus["ORDER_STATUS_START_HANDLING"] = 22] = "ORDER_STATUS_START_HANDLING";
    OrderStatus[OrderStatus["ORDER_STATUS_CANCELLATION_REQUESTED_SELLER"] = 23] = "ORDER_STATUS_CANCELLATION_REQUESTED_SELLER";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION"] = 24] = "ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION";
    OrderStatus[OrderStatus["ORDER_STATUS_WAITING_SELLER_HANDLING"] = 25] = "ORDER_STATUS_WAITING_SELLER_HANDLING";
    OrderStatus[OrderStatus["ORDER_STATUS_HANDLING_SHIPPING"] = 26] = "ORDER_STATUS_HANDLING_SHIPPING";
    OrderStatus[OrderStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OrderStatus || (OrderStatus = {}));
export function orderStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "ORDER_STATUS_UNSPECIFIED":
            return OrderStatus.ORDER_STATUS_UNSPECIFIED;
        case 1:
        case "ORDER_STATUS_ORDER_CREATED":
            return OrderStatus.ORDER_STATUS_ORDER_CREATED;
        case 2:
        case "ORDER_STATUS_ORDER_ACCEPTED":
            return OrderStatus.ORDER_STATUS_ORDER_ACCEPTED;
        case 3:
        case "ORDER_STATUS_CANCELING":
            return OrderStatus.ORDER_STATUS_CANCELING;
        case 4:
        case "ORDER_STATUS_ON_ORDER_COMPLETED":
            return OrderStatus.ORDER_STATUS_ON_ORDER_COMPLETED;
        case 5:
        case "ORDER_STATUS_ON_ORDER_COMPLETED_FFM":
            return OrderStatus.ORDER_STATUS_ON_ORDER_COMPLETED_FFM;
        case 6:
        case "ORDER_STATUS_PAYMENT_APPROVED":
            return OrderStatus.ORDER_STATUS_PAYMENT_APPROVED;
        case 7:
        case "ORDER_STATUS_PAYMENT_PENDING":
            return OrderStatus.ORDER_STATUS_PAYMENT_PENDING;
        case 8:
        case "ORDER_STATUS_CANCELLATION_REQUESTED":
            return OrderStatus.ORDER_STATUS_CANCELLATION_REQUESTED;
        case 9:
        case "ORDER_STATUS_CANCELED":
            return OrderStatus.ORDER_STATUS_CANCELED;
        case 10:
        case "ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT":
            return OrderStatus.ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT;
        case 11:
        case "ORDER_STATUS_WINDOW_TO_CHANGE_SELLER":
            return OrderStatus.ORDER_STATUS_WINDOW_TO_CHANGE_SELLER;
        case 12:
        case "ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION":
            return OrderStatus.ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION;
        case 13:
        case "ORDER_STATUS_WAITING_FOR_FULFILLMENT":
            return OrderStatus.ORDER_STATUS_WAITING_FOR_FULFILLMENT;
        case 14:
        case "ORDER_STATUS_WAITING_FFM_AUTHORIZATION":
            return OrderStatus.ORDER_STATUS_WAITING_FFM_AUTHORIZATION;
        case 15:
        case "ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION":
            return OrderStatus.ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION;
        case 16:
        case "ORDER_STATUS_AUTHORIZE_FULFILLMENT":
            return OrderStatus.ORDER_STATUS_AUTHORIZE_FULFILLMENT;
        case 17:
        case "ORDER_STATUS_CANCELLATION_WINDOW":
            return OrderStatus.ORDER_STATUS_CANCELLATION_WINDOW;
        case 18:
        case "ORDER_STATUS_READY_FOR_INVOICING":
            return OrderStatus.ORDER_STATUS_READY_FOR_INVOICING;
        case 19:
        case "ORDER_STATUS_VERIFYING_INVOICE":
            return OrderStatus.ORDER_STATUS_VERIFYING_INVOICE;
        case 20:
        case "ORDER_STATUS_INVOICED":
            return OrderStatus.ORDER_STATUS_INVOICED;
        case 21:
        case "ORDER_STATUS_READY_FOR_HANDLING":
            return OrderStatus.ORDER_STATUS_READY_FOR_HANDLING;
        case 22:
        case "ORDER_STATUS_START_HANDLING":
            return OrderStatus.ORDER_STATUS_START_HANDLING;
        case 23:
        case "ORDER_STATUS_CANCELLATION_REQUESTED_SELLER":
            return OrderStatus.ORDER_STATUS_CANCELLATION_REQUESTED_SELLER;
        case 24:
        case "ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION":
            return OrderStatus.ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION;
        case 25:
        case "ORDER_STATUS_WAITING_SELLER_HANDLING":
            return OrderStatus.ORDER_STATUS_WAITING_SELLER_HANDLING;
        case 26:
        case "ORDER_STATUS_HANDLING_SHIPPING":
            return OrderStatus.ORDER_STATUS_HANDLING_SHIPPING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OrderStatus.UNRECOGNIZED;
    }
}
export function orderStatusToJSON(object) {
    switch (object) {
        case OrderStatus.ORDER_STATUS_UNSPECIFIED:
            return "ORDER_STATUS_UNSPECIFIED";
        case OrderStatus.ORDER_STATUS_ORDER_CREATED:
            return "ORDER_STATUS_ORDER_CREATED";
        case OrderStatus.ORDER_STATUS_ORDER_ACCEPTED:
            return "ORDER_STATUS_ORDER_ACCEPTED";
        case OrderStatus.ORDER_STATUS_CANCELING:
            return "ORDER_STATUS_CANCELING";
        case OrderStatus.ORDER_STATUS_ON_ORDER_COMPLETED:
            return "ORDER_STATUS_ON_ORDER_COMPLETED";
        case OrderStatus.ORDER_STATUS_ON_ORDER_COMPLETED_FFM:
            return "ORDER_STATUS_ON_ORDER_COMPLETED_FFM";
        case OrderStatus.ORDER_STATUS_PAYMENT_APPROVED:
            return "ORDER_STATUS_PAYMENT_APPROVED";
        case OrderStatus.ORDER_STATUS_PAYMENT_PENDING:
            return "ORDER_STATUS_PAYMENT_PENDING";
        case OrderStatus.ORDER_STATUS_CANCELLATION_REQUESTED:
            return "ORDER_STATUS_CANCELLATION_REQUESTED";
        case OrderStatus.ORDER_STATUS_CANCELED:
            return "ORDER_STATUS_CANCELED";
        case OrderStatus.ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT:
            return "ORDER_STATUS_WINDOW_TO_CHANGE_PAYMENT";
        case OrderStatus.ORDER_STATUS_WINDOW_TO_CHANGE_SELLER:
            return "ORDER_STATUS_WINDOW_TO_CHANGE_SELLER";
        case OrderStatus.ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION:
            return "ORDER_STATUS_WAITING_FOR_SELLER_CONFIRMATION";
        case OrderStatus.ORDER_STATUS_WAITING_FOR_FULFILLMENT:
            return "ORDER_STATUS_WAITING_FOR_FULFILLMENT";
        case OrderStatus.ORDER_STATUS_WAITING_FFM_AUTHORIZATION:
            return "ORDER_STATUS_WAITING_FFM_AUTHORIZATION";
        case OrderStatus.ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION:
            return "ORDER_STATUS_WAITING_FOR_MANUAL_AUTHORIZATION";
        case OrderStatus.ORDER_STATUS_AUTHORIZE_FULFILLMENT:
            return "ORDER_STATUS_AUTHORIZE_FULFILLMENT";
        case OrderStatus.ORDER_STATUS_CANCELLATION_WINDOW:
            return "ORDER_STATUS_CANCELLATION_WINDOW";
        case OrderStatus.ORDER_STATUS_READY_FOR_INVOICING:
            return "ORDER_STATUS_READY_FOR_INVOICING";
        case OrderStatus.ORDER_STATUS_VERIFYING_INVOICE:
            return "ORDER_STATUS_VERIFYING_INVOICE";
        case OrderStatus.ORDER_STATUS_INVOICED:
            return "ORDER_STATUS_INVOICED";
        case OrderStatus.ORDER_STATUS_READY_FOR_HANDLING:
            return "ORDER_STATUS_READY_FOR_HANDLING";
        case OrderStatus.ORDER_STATUS_START_HANDLING:
            return "ORDER_STATUS_START_HANDLING";
        case OrderStatus.ORDER_STATUS_CANCELLATION_REQUESTED_SELLER:
            return "ORDER_STATUS_CANCELLATION_REQUESTED_SELLER";
        case OrderStatus.ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION:
            return "ORDER_STATUS_WAITING_FOR_MKT_AUTHORIZATION";
        case OrderStatus.ORDER_STATUS_WAITING_SELLER_HANDLING:
            return "ORDER_STATUS_WAITING_SELLER_HANDLING";
        case OrderStatus.ORDER_STATUS_HANDLING_SHIPPING:
            return "ORDER_STATUS_HANDLING_SHIPPING";
        case OrderStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCustomer() {
    return {
        corporateName: undefined,
        rclastsessiondate: undefined,
        updatedIn: undefined,
        email: undefined,
        birthDateMonth: undefined,
        document: undefined,
        createdIn: undefined,
        firstName: undefined,
        accountId: undefined,
        phone: undefined,
        userId: undefined,
        stateRegistration: undefined,
        lastInteractionIn: undefined,
        customerClass: undefined,
        gender: undefined,
        rclastcartvalue: undefined,
        rclastcart: undefined,
        localeDefault: undefined,
        lastName: undefined,
        accountName: undefined,
        birthDate: undefined,
        rclastsession: undefined,
        dataEntityId: undefined,
        documentType: undefined,
        businessPhone: undefined,
        carttag: undefined,
        checkouttag: undefined,
        homePhone: undefined,
    };
}
export const Customer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.corporateName !== undefined) {
            StringValue.encode({ value: message.corporateName }, writer.uint32(10).fork()).ldelim();
        }
        if (message.rclastsessiondate !== undefined) {
            StringValue.encode({ value: message.rclastsessiondate }, writer.uint32(18).fork()).ldelim();
        }
        if (message.updatedIn !== undefined) {
            StringValue.encode({ value: message.updatedIn }, writer.uint32(26).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(34).fork()).ldelim();
        }
        if (message.birthDateMonth !== undefined) {
            Int32Value.encode({ value: message.birthDateMonth }, writer.uint32(42).fork()).ldelim();
        }
        if (message.document !== undefined) {
            StringValue.encode({ value: message.document }, writer.uint32(50).fork()).ldelim();
        }
        if (message.createdIn !== undefined) {
            StringValue.encode({ value: message.createdIn }, writer.uint32(58).fork()).ldelim();
        }
        if (message.firstName !== undefined) {
            StringValue.encode({ value: message.firstName }, writer.uint32(66).fork()).ldelim();
        }
        if (message.accountId !== undefined) {
            StringValue.encode({ value: message.accountId }, writer.uint32(74).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(82).fork()).ldelim();
        }
        if (message.userId !== undefined) {
            StringValue.encode({ value: message.userId }, writer.uint32(90).fork()).ldelim();
        }
        if (message.stateRegistration !== undefined) {
            StringValue.encode({ value: message.stateRegistration }, writer.uint32(98).fork()).ldelim();
        }
        if (message.lastInteractionIn !== undefined) {
            StringValue.encode({ value: message.lastInteractionIn }, writer.uint32(106).fork()).ldelim();
        }
        if (message.customerClass !== undefined) {
            StringValue.encode({ value: message.customerClass }, writer.uint32(114).fork()).ldelim();
        }
        if (message.gender !== undefined) {
            StringValue.encode({ value: message.gender }, writer.uint32(122).fork()).ldelim();
        }
        if (message.rclastcartvalue !== undefined) {
            DoubleValue.encode({ value: message.rclastcartvalue }, writer.uint32(130).fork()).ldelim();
        }
        if (message.rclastcart !== undefined) {
            StringValue.encode({ value: message.rclastcart }, writer.uint32(138).fork()).ldelim();
        }
        if (message.localeDefault !== undefined) {
            StringValue.encode({ value: message.localeDefault }, writer.uint32(146).fork()).ldelim();
        }
        if (message.lastName !== undefined) {
            StringValue.encode({ value: message.lastName }, writer.uint32(154).fork()).ldelim();
        }
        if (message.accountName !== undefined) {
            StringValue.encode({ value: message.accountName }, writer.uint32(162).fork()).ldelim();
        }
        if (message.birthDate !== undefined) {
            StringValue.encode({ value: message.birthDate }, writer.uint32(170).fork()).ldelim();
        }
        if (message.rclastsession !== undefined) {
            StringValue.encode({ value: message.rclastsession }, writer.uint32(178).fork()).ldelim();
        }
        if (message.dataEntityId !== undefined) {
            StringValue.encode({ value: message.dataEntityId }, writer.uint32(186).fork()).ldelim();
        }
        if (message.documentType !== undefined) {
            StringValue.encode({ value: message.documentType }, writer.uint32(194).fork()).ldelim();
        }
        if (message.businessPhone !== undefined) {
            StringValue.encode({ value: message.businessPhone }, writer.uint32(202).fork()).ldelim();
        }
        if (message.carttag !== undefined) {
            Struct.encode(Struct.wrap(message.carttag), writer.uint32(210).fork()).ldelim();
        }
        if (message.checkouttag !== undefined) {
            Struct.encode(Struct.wrap(message.checkouttag), writer.uint32(218).fork()).ldelim();
        }
        if (message.homePhone !== undefined) {
            StringValue.encode({ value: message.homePhone }, writer.uint32(226).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.corporateName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.rclastsessiondate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.updatedIn = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.birthDateMonth = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.document = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.createdIn = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.firstName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.accountId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.userId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.stateRegistration = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.lastInteractionIn = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.customerClass = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.gender = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.rclastcartvalue = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 17:
                    message.rclastcart = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 18:
                    message.localeDefault = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 19:
                    message.lastName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 20:
                    message.accountName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 21:
                    message.birthDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 22:
                    message.rclastsession = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 23:
                    message.dataEntityId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 24:
                    message.documentType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 25:
                    message.businessPhone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 26:
                    message.carttag = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 27:
                    message.checkouttag = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 28:
                    message.homePhone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            corporateName: isSet(object.corporateName)
                ? String(object.corporateName)
                : undefined,
            rclastsessiondate: isSet(object.rclastsessiondate)
                ? String(object.rclastsessiondate)
                : undefined,
            updatedIn: isSet(object.updatedIn) ? String(object.updatedIn) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            birthDateMonth: isSet(object.birthDateMonth)
                ? Number(object.birthDateMonth)
                : undefined,
            document: isSet(object.document) ? String(object.document) : undefined,
            createdIn: isSet(object.createdIn) ? String(object.createdIn) : undefined,
            firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
            accountId: isSet(object.accountId) ? String(object.accountId) : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
            userId: isSet(object.userId) ? String(object.userId) : undefined,
            stateRegistration: isSet(object.stateRegistration)
                ? String(object.stateRegistration)
                : undefined,
            lastInteractionIn: isSet(object.lastInteractionIn)
                ? String(object.lastInteractionIn)
                : undefined,
            customerClass: isSet(object.customerClass)
                ? String(object.customerClass)
                : undefined,
            gender: isSet(object.gender) ? String(object.gender) : undefined,
            rclastcartvalue: isSet(object.rclastcartvalue)
                ? Number(object.rclastcartvalue)
                : undefined,
            rclastcart: isSet(object.rclastcart)
                ? String(object.rclastcart)
                : undefined,
            localeDefault: isSet(object.localeDefault)
                ? String(object.localeDefault)
                : undefined,
            lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
            accountName: isSet(object.accountName)
                ? String(object.accountName)
                : undefined,
            birthDate: isSet(object.birthDate) ? String(object.birthDate) : undefined,
            rclastsession: isSet(object.rclastsession)
                ? String(object.rclastsession)
                : undefined,
            dataEntityId: isSet(object.dataEntityId)
                ? String(object.dataEntityId)
                : undefined,
            documentType: isSet(object.documentType)
                ? String(object.documentType)
                : undefined,
            businessPhone: isSet(object.businessPhone)
                ? String(object.businessPhone)
                : undefined,
            carttag: isObject(object.carttag) ? object.carttag : undefined,
            checkouttag: isObject(object.checkouttag)
                ? object.checkouttag
                : undefined,
            homePhone: isSet(object.homePhone) ? String(object.homePhone) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.corporateName !== undefined &&
            (obj.corporateName = message.corporateName);
        message.rclastsessiondate !== undefined &&
            (obj.rclastsessiondate = message.rclastsessiondate);
        message.updatedIn !== undefined && (obj.updatedIn = message.updatedIn);
        message.email !== undefined && (obj.email = message.email);
        message.birthDateMonth !== undefined &&
            (obj.birthDateMonth = message.birthDateMonth);
        message.document !== undefined && (obj.document = message.document);
        message.createdIn !== undefined && (obj.createdIn = message.createdIn);
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.accountId !== undefined && (obj.accountId = message.accountId);
        message.phone !== undefined && (obj.phone = message.phone);
        message.userId !== undefined && (obj.userId = message.userId);
        message.stateRegistration !== undefined &&
            (obj.stateRegistration = message.stateRegistration);
        message.lastInteractionIn !== undefined &&
            (obj.lastInteractionIn = message.lastInteractionIn);
        message.customerClass !== undefined &&
            (obj.customerClass = message.customerClass);
        message.gender !== undefined && (obj.gender = message.gender);
        message.rclastcartvalue !== undefined &&
            (obj.rclastcartvalue = message.rclastcartvalue);
        message.rclastcart !== undefined && (obj.rclastcart = message.rclastcart);
        message.localeDefault !== undefined &&
            (obj.localeDefault = message.localeDefault);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.accountName !== undefined &&
            (obj.accountName = message.accountName);
        message.birthDate !== undefined && (obj.birthDate = message.birthDate);
        message.rclastsession !== undefined &&
            (obj.rclastsession = message.rclastsession);
        message.dataEntityId !== undefined &&
            (obj.dataEntityId = message.dataEntityId);
        message.documentType !== undefined &&
            (obj.documentType = message.documentType);
        message.businessPhone !== undefined &&
            (obj.businessPhone = message.businessPhone);
        message.carttag !== undefined && (obj.carttag = message.carttag);
        message.checkouttag !== undefined &&
            (obj.checkouttag = message.checkouttag);
        message.homePhone !== undefined && (obj.homePhone = message.homePhone);
        return obj;
    },
};
function createBaseItem() {
    return {
        id: undefined,
        quantity: undefined,
        uniqueId: undefined,
        productId: undefined,
        productRefId: undefined,
        refId: undefined,
        ean: undefined,
        name: undefined,
        skuName: undefined,
        modalType: undefined,
        parentItemIndex: undefined,
        parentAssemblyBinding: undefined,
        priceValidUntil: undefined,
        tax: undefined,
        price: undefined,
        listPrice: undefined,
        manualPrice: undefined,
        manualPriceAppliedBy: undefined,
        sellingPrice: undefined,
        rewardValue: undefined,
        additionalInfo: undefined,
        dimension: undefined,
        brandName: undefined,
        brandId: undefined,
        offeringInfo: undefined,
        offeringType: undefined,
        offeringTypeId: undefined,
        preSaleDate: undefined,
        productCategoryIds: undefined,
        productCategories: undefined,
        seller: undefined,
        sellerChain: [],
        imageUrl: undefined,
        detailUrl: undefined,
        bundleItems: [],
        priceTags: [],
        availability: undefined,
        measurementUnit: undefined,
        manufacturerCode: undefined,
        priceDefinition: undefined,
        sellingPrices: [],
    };
}
export const Item = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.quantity !== undefined) {
            Int32Value.encode({ value: message.quantity }, writer.uint32(18).fork()).ldelim();
        }
        if (message.uniqueId !== undefined) {
            StringValue.encode({ value: message.uniqueId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.productId !== undefined) {
            StringValue.encode({ value: message.productId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.productRefId !== undefined) {
            StringValue.encode({ value: message.productRefId }, writer.uint32(42).fork()).ldelim();
        }
        if (message.refId !== undefined) {
            StringValue.encode({ value: message.refId }, writer.uint32(50).fork()).ldelim();
        }
        if (message.ean !== undefined) {
            StringValue.encode({ value: message.ean }, writer.uint32(58).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(66).fork()).ldelim();
        }
        if (message.skuName !== undefined) {
            StringValue.encode({ value: message.skuName }, writer.uint32(74).fork()).ldelim();
        }
        if (message.modalType !== undefined) {
            StringValue.encode({ value: message.modalType }, writer.uint32(82).fork()).ldelim();
        }
        if (message.parentItemIndex !== undefined) {
            Int32Value.encode({ value: message.parentItemIndex }, writer.uint32(90).fork()).ldelim();
        }
        if (message.parentAssemblyBinding !== undefined) {
            StringValue.encode({ value: message.parentAssemblyBinding }, writer.uint32(98).fork()).ldelim();
        }
        if (message.priceValidUntil !== undefined) {
            StringValue.encode({ value: message.priceValidUntil }, writer.uint32(106).fork()).ldelim();
        }
        if (message.tax !== undefined) {
            Int32Value.encode({ value: message.tax }, writer.uint32(114).fork()).ldelim();
        }
        if (message.price !== undefined) {
            Int32Value.encode({ value: message.price }, writer.uint32(122).fork()).ldelim();
        }
        if (message.listPrice !== undefined) {
            Int32Value.encode({ value: message.listPrice }, writer.uint32(130).fork()).ldelim();
        }
        if (message.manualPrice !== undefined) {
            Int32Value.encode({ value: message.manualPrice }, writer.uint32(138).fork()).ldelim();
        }
        if (message.manualPriceAppliedBy !== undefined) {
            StringValue.encode({ value: message.manualPriceAppliedBy }, writer.uint32(146).fork()).ldelim();
        }
        if (message.sellingPrice !== undefined) {
            Int32Value.encode({ value: message.sellingPrice }, writer.uint32(154).fork()).ldelim();
        }
        if (message.rewardValue !== undefined) {
            Int32Value.encode({ value: message.rewardValue }, writer.uint32(162).fork()).ldelim();
        }
        if (message.additionalInfo !== undefined) {
            AdditionalInfo.encode(message.additionalInfo, writer.uint32(170).fork()).ldelim();
        }
        if (message.dimension !== undefined) {
            Dimension.encode(message.dimension, writer.uint32(178).fork()).ldelim();
        }
        if (message.brandName !== undefined) {
            StringValue.encode({ value: message.brandName }, writer.uint32(186).fork()).ldelim();
        }
        if (message.brandId !== undefined) {
            StringValue.encode({ value: message.brandId }, writer.uint32(194).fork()).ldelim();
        }
        if (message.offeringInfo !== undefined) {
            StringValue.encode({ value: message.offeringInfo }, writer.uint32(202).fork()).ldelim();
        }
        if (message.offeringType !== undefined) {
            StringValue.encode({ value: message.offeringType }, writer.uint32(210).fork()).ldelim();
        }
        if (message.offeringTypeId !== undefined) {
            StringValue.encode({ value: message.offeringTypeId }, writer.uint32(218).fork()).ldelim();
        }
        if (message.preSaleDate !== undefined) {
            StringValue.encode({ value: message.preSaleDate }, writer.uint32(226).fork()).ldelim();
        }
        if (message.productCategoryIds !== undefined) {
            StringValue.encode({ value: message.productCategoryIds }, writer.uint32(234).fork()).ldelim();
        }
        if (message.productCategories !== undefined) {
            Struct.encode(Struct.wrap(message.productCategories), writer.uint32(242).fork()).ldelim();
        }
        if (message.seller !== undefined) {
            StringValue.encode({ value: message.seller }, writer.uint32(250).fork()).ldelim();
        }
        for (const v of message.sellerChain) {
            StringValue.encode({ value: v }, writer.uint32(258).fork()).ldelim();
        }
        if (message.imageUrl !== undefined) {
            StringValue.encode({ value: message.imageUrl }, writer.uint32(266).fork()).ldelim();
        }
        if (message.detailUrl !== undefined) {
            StringValue.encode({ value: message.detailUrl }, writer.uint32(274).fork()).ldelim();
        }
        for (const v of message.bundleItems) {
            BundleItem.encode(v, writer.uint32(282).fork()).ldelim();
        }
        for (const v of message.priceTags) {
            PriceTag.encode(v, writer.uint32(290).fork()).ldelim();
        }
        if (message.availability !== undefined) {
            StringValue.encode({ value: message.availability }, writer.uint32(298).fork()).ldelim();
        }
        if (message.measurementUnit !== undefined) {
            StringValue.encode({ value: message.measurementUnit }, writer.uint32(306).fork()).ldelim();
        }
        if (message.manufacturerCode !== undefined) {
            StringValue.encode({ value: message.manufacturerCode }, writer.uint32(314).fork()).ldelim();
        }
        if (message.priceDefinition !== undefined) {
            PriceDefinition.encode(message.priceDefinition, writer.uint32(322).fork()).ldelim();
        }
        for (const v of message.sellingPrices) {
            SellingPrice.encode(v, writer.uint32(330).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.quantity = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.uniqueId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.productId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.productRefId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.refId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.ean = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.skuName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.modalType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.parentItemIndex = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.parentAssemblyBinding = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.priceValidUntil = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.tax = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.price = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.listPrice = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 17:
                    message.manualPrice = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 18:
                    message.manualPriceAppliedBy = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 19:
                    message.sellingPrice = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 20:
                    message.rewardValue = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 21:
                    message.additionalInfo = AdditionalInfo.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.dimension = Dimension.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.brandName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 24:
                    message.brandId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 25:
                    message.offeringInfo = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 26:
                    message.offeringType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 27:
                    message.offeringTypeId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 28:
                    message.preSaleDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 29:
                    message.productCategoryIds = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 30:
                    message.productCategories = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 31:
                    message.seller = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 32:
                    message.sellerChain.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 33:
                    message.imageUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 34:
                    message.detailUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 35:
                    message.bundleItems.push(BundleItem.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.priceTags.push(PriceTag.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.availability = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 38:
                    message.measurementUnit = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 39:
                    message.manufacturerCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 40:
                    message.priceDefinition = PriceDefinition.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.sellingPrices.push(SellingPrice.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            quantity: isSet(object.quantity) ? Number(object.quantity) : undefined,
            uniqueId: isSet(object.uniqueId) ? String(object.uniqueId) : undefined,
            productId: isSet(object.productId) ? String(object.productId) : undefined,
            productRefId: isSet(object.productRefId)
                ? String(object.productRefId)
                : undefined,
            refId: isSet(object.refId) ? String(object.refId) : undefined,
            ean: isSet(object.ean) ? String(object.ean) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            skuName: isSet(object.skuName) ? String(object.skuName) : undefined,
            modalType: isSet(object.modalType) ? String(object.modalType) : undefined,
            parentItemIndex: isSet(object.parentItemIndex)
                ? Number(object.parentItemIndex)
                : undefined,
            parentAssemblyBinding: isSet(object.parentAssemblyBinding)
                ? String(object.parentAssemblyBinding)
                : undefined,
            priceValidUntil: isSet(object.priceValidUntil)
                ? String(object.priceValidUntil)
                : undefined,
            tax: isSet(object.tax) ? Number(object.tax) : undefined,
            price: isSet(object.price) ? Number(object.price) : undefined,
            listPrice: isSet(object.listPrice) ? Number(object.listPrice) : undefined,
            manualPrice: isSet(object.manualPrice)
                ? Number(object.manualPrice)
                : undefined,
            manualPriceAppliedBy: isSet(object.manualPriceAppliedBy)
                ? String(object.manualPriceAppliedBy)
                : undefined,
            sellingPrice: isSet(object.sellingPrice)
                ? Number(object.sellingPrice)
                : undefined,
            rewardValue: isSet(object.rewardValue)
                ? Number(object.rewardValue)
                : undefined,
            additionalInfo: isSet(object.additionalInfo)
                ? AdditionalInfo.fromJSON(object.additionalInfo)
                : undefined,
            dimension: isSet(object.dimension)
                ? Dimension.fromJSON(object.dimension)
                : undefined,
            brandName: isSet(object.brandName) ? String(object.brandName) : undefined,
            brandId: isSet(object.brandId) ? String(object.brandId) : undefined,
            offeringInfo: isSet(object.offeringInfo)
                ? String(object.offeringInfo)
                : undefined,
            offeringType: isSet(object.offeringType)
                ? String(object.offeringType)
                : undefined,
            offeringTypeId: isSet(object.offeringTypeId)
                ? String(object.offeringTypeId)
                : undefined,
            preSaleDate: isSet(object.preSaleDate)
                ? String(object.preSaleDate)
                : undefined,
            productCategoryIds: isSet(object.productCategoryIds)
                ? String(object.productCategoryIds)
                : undefined,
            productCategories: isObject(object.productCategories)
                ? object.productCategories
                : undefined,
            seller: isSet(object.seller) ? String(object.seller) : undefined,
            sellerChain: Array.isArray(object === null || object === void 0 ? void 0 : object.sellerChain)
                ? object.sellerChain.map((e) => String(e))
                : [],
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : undefined,
            detailUrl: isSet(object.detailUrl) ? String(object.detailUrl) : undefined,
            bundleItems: Array.isArray(object === null || object === void 0 ? void 0 : object.bundleItems)
                ? object.bundleItems.map((e) => BundleItem.fromJSON(e))
                : [],
            priceTags: Array.isArray(object === null || object === void 0 ? void 0 : object.priceTags)
                ? object.priceTags.map((e) => PriceTag.fromJSON(e))
                : [],
            availability: isSet(object.availability)
                ? String(object.availability)
                : undefined,
            measurementUnit: isSet(object.measurementUnit)
                ? String(object.measurementUnit)
                : undefined,
            manufacturerCode: isSet(object.manufacturerCode)
                ? String(object.manufacturerCode)
                : undefined,
            priceDefinition: isSet(object.priceDefinition)
                ? PriceDefinition.fromJSON(object.priceDefinition)
                : undefined,
            sellingPrices: Array.isArray(object === null || object === void 0 ? void 0 : object.sellingPrices)
                ? object.sellingPrices.map((e) => SellingPrice.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.uniqueId !== undefined && (obj.uniqueId = message.uniqueId);
        message.productId !== undefined && (obj.productId = message.productId);
        message.productRefId !== undefined &&
            (obj.productRefId = message.productRefId);
        message.refId !== undefined && (obj.refId = message.refId);
        message.ean !== undefined && (obj.ean = message.ean);
        message.name !== undefined && (obj.name = message.name);
        message.skuName !== undefined && (obj.skuName = message.skuName);
        message.modalType !== undefined && (obj.modalType = message.modalType);
        message.parentItemIndex !== undefined &&
            (obj.parentItemIndex = message.parentItemIndex);
        message.parentAssemblyBinding !== undefined &&
            (obj.parentAssemblyBinding = message.parentAssemblyBinding);
        message.priceValidUntil !== undefined &&
            (obj.priceValidUntil = message.priceValidUntil);
        message.tax !== undefined && (obj.tax = message.tax);
        message.price !== undefined && (obj.price = message.price);
        message.listPrice !== undefined && (obj.listPrice = message.listPrice);
        message.manualPrice !== undefined &&
            (obj.manualPrice = message.manualPrice);
        message.manualPriceAppliedBy !== undefined &&
            (obj.manualPriceAppliedBy = message.manualPriceAppliedBy);
        message.sellingPrice !== undefined &&
            (obj.sellingPrice = message.sellingPrice);
        message.rewardValue !== undefined &&
            (obj.rewardValue = message.rewardValue);
        message.additionalInfo !== undefined &&
            (obj.additionalInfo = message.additionalInfo
                ? AdditionalInfo.toJSON(message.additionalInfo)
                : undefined);
        message.dimension !== undefined &&
            (obj.dimension = message.dimension
                ? Dimension.toJSON(message.dimension)
                : undefined);
        message.brandName !== undefined && (obj.brandName = message.brandName);
        message.brandId !== undefined && (obj.brandId = message.brandId);
        message.offeringInfo !== undefined &&
            (obj.offeringInfo = message.offeringInfo);
        message.offeringType !== undefined &&
            (obj.offeringType = message.offeringType);
        message.offeringTypeId !== undefined &&
            (obj.offeringTypeId = message.offeringTypeId);
        message.preSaleDate !== undefined &&
            (obj.preSaleDate = message.preSaleDate);
        message.productCategoryIds !== undefined &&
            (obj.productCategoryIds = message.productCategoryIds);
        message.productCategories !== undefined &&
            (obj.productCategories = message.productCategories);
        message.seller !== undefined && (obj.seller = message.seller);
        if (message.sellerChain) {
            obj.sellerChain = message.sellerChain.map((e) => e);
        }
        else {
            obj.sellerChain = [];
        }
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        message.detailUrl !== undefined && (obj.detailUrl = message.detailUrl);
        if (message.bundleItems) {
            obj.bundleItems = message.bundleItems.map((e) => e ? BundleItem.toJSON(e) : undefined);
        }
        else {
            obj.bundleItems = [];
        }
        if (message.priceTags) {
            obj.priceTags = message.priceTags.map((e) => e ? PriceTag.toJSON(e) : undefined);
        }
        else {
            obj.priceTags = [];
        }
        message.availability !== undefined &&
            (obj.availability = message.availability);
        message.measurementUnit !== undefined &&
            (obj.measurementUnit = message.measurementUnit);
        message.manufacturerCode !== undefined &&
            (obj.manufacturerCode = message.manufacturerCode);
        message.priceDefinition !== undefined &&
            (obj.priceDefinition = message.priceDefinition
                ? PriceDefinition.toJSON(message.priceDefinition)
                : undefined);
        if (message.sellingPrices) {
            obj.sellingPrices = message.sellingPrices.map((e) => e ? SellingPrice.toJSON(e) : undefined);
        }
        else {
            obj.sellingPrices = [];
        }
        return obj;
    },
};
function createBaseAdditionalInfo() {
    return {
        dimension: undefined,
        brandName: undefined,
        brandId: undefined,
        offeringInfo: undefined,
        offeringType: undefined,
        offeringTypeId: undefined,
    };
}
export const AdditionalInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.dimension !== undefined) {
            Dimension.encode(message.dimension, writer.uint32(10).fork()).ldelim();
        }
        if (message.brandName !== undefined) {
            StringValue.encode({ value: message.brandName }, writer.uint32(18).fork()).ldelim();
        }
        if (message.brandId !== undefined) {
            StringValue.encode({ value: message.brandId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.offeringInfo !== undefined) {
            StringValue.encode({ value: message.offeringInfo }, writer.uint32(34).fork()).ldelim();
        }
        if (message.offeringType !== undefined) {
            StringValue.encode({ value: message.offeringType }, writer.uint32(42).fork()).ldelim();
        }
        if (message.offeringTypeId !== undefined) {
            StringValue.encode({ value: message.offeringTypeId }, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAdditionalInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.dimension = Dimension.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.brandName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.brandId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.offeringInfo = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.offeringType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.offeringTypeId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            dimension: isSet(object.dimension)
                ? Dimension.fromJSON(object.dimension)
                : undefined,
            brandName: isSet(object.brandName) ? String(object.brandName) : undefined,
            brandId: isSet(object.brandId) ? String(object.brandId) : undefined,
            offeringInfo: isSet(object.offeringInfo)
                ? String(object.offeringInfo)
                : undefined,
            offeringType: isSet(object.offeringType)
                ? String(object.offeringType)
                : undefined,
            offeringTypeId: isSet(object.offeringTypeId)
                ? String(object.offeringTypeId)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.dimension !== undefined &&
            (obj.dimension = message.dimension
                ? Dimension.toJSON(message.dimension)
                : undefined);
        message.brandName !== undefined && (obj.brandName = message.brandName);
        message.brandId !== undefined && (obj.brandId = message.brandId);
        message.offeringInfo !== undefined &&
            (obj.offeringInfo = message.offeringInfo);
        message.offeringType !== undefined &&
            (obj.offeringType = message.offeringType);
        message.offeringTypeId !== undefined &&
            (obj.offeringTypeId = message.offeringTypeId);
        return obj;
    },
};
function createBaseDimension() {
    return {
        cubicweight: undefined,
        height: undefined,
        length: undefined,
        weight: undefined,
        width: undefined,
    };
}
export const Dimension = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cubicweight !== undefined) {
            DoubleValue.encode({ value: message.cubicweight }, writer.uint32(10).fork()).ldelim();
        }
        if (message.height !== undefined) {
            DoubleValue.encode({ value: message.height }, writer.uint32(18).fork()).ldelim();
        }
        if (message.length !== undefined) {
            DoubleValue.encode({ value: message.length }, writer.uint32(26).fork()).ldelim();
        }
        if (message.weight !== undefined) {
            DoubleValue.encode({ value: message.weight }, writer.uint32(34).fork()).ldelim();
        }
        if (message.width !== undefined) {
            DoubleValue.encode({ value: message.width }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDimension();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cubicweight = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.height = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.length = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.weight = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.width = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cubicweight: isSet(object.cubicweight)
                ? Number(object.cubicweight)
                : undefined,
            height: isSet(object.height) ? Number(object.height) : undefined,
            length: isSet(object.length) ? Number(object.length) : undefined,
            weight: isSet(object.weight) ? Number(object.weight) : undefined,
            width: isSet(object.width) ? Number(object.width) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.cubicweight !== undefined &&
            (obj.cubicweight = message.cubicweight);
        message.height !== undefined && (obj.height = message.height);
        message.length !== undefined && (obj.length = message.length);
        message.weight !== undefined && (obj.weight = message.weight);
        message.width !== undefined && (obj.width = message.width);
        return obj;
    },
};
function createBaseBundleItem() {
    return {
        type: undefined,
        id: undefined,
        name: undefined,
        price: undefined,
        attachments: [],
    };
}
export const BundleItem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== undefined) {
            StringValue.encode({ value: message.type }, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== undefined) {
            Int32Value.encode({ value: message.id }, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(26).fork()).ldelim();
        }
        if (message.price !== undefined) {
            Int32Value.encode({ value: message.price }, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.attachments) {
            StringValue.encode({ value: v }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBundleItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.id = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.price = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.attachments.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : undefined,
            id: isSet(object.id) ? Number(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            price: isSet(object.price) ? Number(object.price) : undefined,
            attachments: Array.isArray(object === null || object === void 0 ? void 0 : object.attachments)
                ? object.attachments.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.price !== undefined && (obj.price = message.price);
        if (message.attachments) {
            obj.attachments = message.attachments.map((e) => e);
        }
        else {
            obj.attachments = [];
        }
        return obj;
    },
};
function createBasePriceTag() {
    return {
        identifier: undefined,
        isPercentual: undefined,
        name: undefined,
        rawValue: undefined,
        value: undefined,
    };
}
export const PriceTag = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.identifier !== undefined) {
            StringValue.encode({ value: message.identifier }, writer.uint32(10).fork()).ldelim();
        }
        if (message.isPercentual !== undefined) {
            BoolValue.encode({ value: message.isPercentual }, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(26).fork()).ldelim();
        }
        if (message.rawValue !== undefined) {
            DoubleValue.encode({ value: message.rawValue }, writer.uint32(34).fork()).ldelim();
        }
        if (message.value !== undefined) {
            Int32Value.encode({ value: message.value }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceTag();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.identifier = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.isPercentual = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.rawValue = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.value = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            identifier: isSet(object.identifier)
                ? String(object.identifier)
                : undefined,
            isPercentual: isSet(object.isPercentual)
                ? Boolean(object.isPercentual)
                : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            rawValue: isSet(object.rawValue) ? Number(object.rawValue) : undefined,
            value: isSet(object.value) ? Number(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.identifier !== undefined && (obj.identifier = message.identifier);
        message.isPercentual !== undefined &&
            (obj.isPercentual = message.isPercentual);
        message.name !== undefined && (obj.name = message.name);
        message.rawValue !== undefined && (obj.rawValue = message.rawValue);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBasePriceDefinition() {
    return { calculatedSellingPrice: undefined, total: undefined };
}
export const PriceDefinition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.calculatedSellingPrice !== undefined) {
            Int32Value.encode({ value: message.calculatedSellingPrice }, writer.uint32(10).fork()).ldelim();
        }
        if (message.total !== undefined) {
            Int32Value.encode({ value: message.total }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceDefinition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.calculatedSellingPrice = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.total = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            calculatedSellingPrice: isSet(object.calculatedSellingPrice)
                ? Number(object.calculatedSellingPrice)
                : undefined,
            total: isSet(object.total) ? Number(object.total) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.calculatedSellingPrice !== undefined &&
            (obj.calculatedSellingPrice = message.calculatedSellingPrice);
        message.total !== undefined && (obj.total = message.total);
        return obj;
    },
};
function createBaseSellingPrice() {
    return { value: undefined, quantity: undefined };
}
export const SellingPrice = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.value !== undefined) {
            Int32Value.encode({ value: message.value }, writer.uint32(10).fork()).ldelim();
        }
        if (message.quantity !== undefined) {
            Int32Value.encode({ value: message.quantity }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSellingPrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.quantity = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            value: isSet(object.value) ? Number(object.value) : undefined,
            quantity: isSet(object.quantity) ? Number(object.quantity) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.value !== undefined && (obj.value = message.value);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        return obj;
    },
};
function createBaseOrderForm() {
    return {
        items: [],
        clientProfileData: undefined,
        orderFormId: undefined,
        orderId: undefined,
        status: 0,
        isCompleted: undefined,
        value: undefined,
        totals: [],
        shippingData: undefined,
        changesAttachment: undefined,
        storePreferencesData: undefined,
        creationDate: undefined,
        marketingData: undefined,
    };
}
export const OrderForm = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.items) {
            Item.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.clientProfileData !== undefined) {
            Customer.encode(message.clientProfileData, writer.uint32(18).fork()).ldelim();
        }
        if (message.orderFormId !== undefined) {
            StringValue.encode({ value: message.orderFormId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.orderId !== undefined) {
            StringValue.encode({ value: message.orderId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(40).int32(message.status);
        }
        if (message.isCompleted !== undefined) {
            BoolValue.encode({ value: message.isCompleted }, writer.uint32(50).fork()).ldelim();
        }
        if (message.value !== undefined) {
            Int32Value.encode({ value: message.value }, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.totals) {
            OrderTotal.encode(v, writer.uint32(66).fork()).ldelim();
        }
        if (message.shippingData !== undefined) {
            ShippingData.encode(message.shippingData, writer.uint32(74).fork()).ldelim();
        }
        if (message.changesAttachment !== undefined) {
            ChangesAttachment.encode(message.changesAttachment, writer.uint32(82).fork()).ldelim();
        }
        if (message.storePreferencesData !== undefined) {
            StorePreferencesData.encode(message.storePreferencesData, writer.uint32(90).fork()).ldelim();
        }
        if (message.creationDate !== undefined) {
            StringValue.encode({ value: message.creationDate }, writer.uint32(98).fork()).ldelim();
        }
        if (message.marketingData !== undefined) {
            MarketingData.encode(message.marketingData, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderForm();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.items.push(Item.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.clientProfileData = Customer.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.orderFormId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.orderId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.isCompleted = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.value = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.totals.push(OrderTotal.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.shippingData = ShippingData.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.changesAttachment = ChangesAttachment.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.storePreferencesData = StorePreferencesData.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.creationDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.marketingData = MarketingData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => Item.fromJSON(e))
                : [],
            clientProfileData: isSet(object.clientProfileData)
                ? Customer.fromJSON(object.clientProfileData)
                : undefined,
            orderFormId: isSet(object.orderFormId)
                ? String(object.orderFormId)
                : undefined,
            orderId: isSet(object.orderId) ? String(object.orderId) : undefined,
            status: isSet(object.status) ? orderStatusFromJSON(object.status) : 0,
            isCompleted: isSet(object.isCompleted)
                ? Boolean(object.isCompleted)
                : undefined,
            value: isSet(object.value) ? Number(object.value) : undefined,
            totals: Array.isArray(object === null || object === void 0 ? void 0 : object.totals)
                ? object.totals.map((e) => OrderTotal.fromJSON(e))
                : [],
            shippingData: isSet(object.shippingData)
                ? ShippingData.fromJSON(object.shippingData)
                : undefined,
            changesAttachment: isSet(object.changesAttachment)
                ? ChangesAttachment.fromJSON(object.changesAttachment)
                : undefined,
            storePreferencesData: isSet(object.storePreferencesData)
                ? StorePreferencesData.fromJSON(object.storePreferencesData)
                : undefined,
            creationDate: isSet(object.creationDate)
                ? String(object.creationDate)
                : undefined,
            marketingData: isSet(object.marketingData)
                ? MarketingData.fromJSON(object.marketingData)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.items) {
            obj.items = message.items.map((e) => (e ? Item.toJSON(e) : undefined));
        }
        else {
            obj.items = [];
        }
        message.clientProfileData !== undefined &&
            (obj.clientProfileData = message.clientProfileData
                ? Customer.toJSON(message.clientProfileData)
                : undefined);
        message.orderFormId !== undefined &&
            (obj.orderFormId = message.orderFormId);
        message.orderId !== undefined && (obj.orderId = message.orderId);
        message.status !== undefined &&
            (obj.status = orderStatusToJSON(message.status));
        message.isCompleted !== undefined &&
            (obj.isCompleted = message.isCompleted);
        message.value !== undefined && (obj.value = message.value);
        if (message.totals) {
            obj.totals = message.totals.map((e) => e ? OrderTotal.toJSON(e) : undefined);
        }
        else {
            obj.totals = [];
        }
        message.shippingData !== undefined &&
            (obj.shippingData = message.shippingData
                ? ShippingData.toJSON(message.shippingData)
                : undefined);
        message.changesAttachment !== undefined &&
            (obj.changesAttachment = message.changesAttachment
                ? ChangesAttachment.toJSON(message.changesAttachment)
                : undefined);
        message.storePreferencesData !== undefined &&
            (obj.storePreferencesData = message.storePreferencesData
                ? StorePreferencesData.toJSON(message.storePreferencesData)
                : undefined);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate);
        message.marketingData !== undefined &&
            (obj.marketingData = message.marketingData
                ? MarketingData.toJSON(message.marketingData)
                : undefined);
        return obj;
    },
};
function createBaseOrderTotal() {
    return { id: undefined, name: undefined, value: undefined };
}
export const OrderTotal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(18).fork()).ldelim();
        }
        if (message.value !== undefined) {
            Int32Value.encode({ value: message.value }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderTotal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.value = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            value: isSet(object.value) ? Number(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseShippingData() {
    return { id: undefined, address: undefined, selectedAddresses: [] };
}
export const ShippingData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address !== undefined) {
            Address.encode(message.address, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.selectedAddresses) {
            SelectedAddress.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShippingData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address = Address.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.selectedAddresses.push(SelectedAddress.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            address: isSet(object.address)
                ? Address.fromJSON(object.address)
                : undefined,
            selectedAddresses: Array.isArray(object === null || object === void 0 ? void 0 : object.selectedAddresses)
                ? object.selectedAddresses.map((e) => SelectedAddress.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.address !== undefined &&
            (obj.address = message.address
                ? Address.toJSON(message.address)
                : undefined);
        if (message.selectedAddresses) {
            obj.selectedAddresses = message.selectedAddresses.map((e) => e ? SelectedAddress.toJSON(e) : undefined);
        }
        else {
            obj.selectedAddresses = [];
        }
        return obj;
    },
};
function createBaseAddress() {
    return {
        addressType: undefined,
        receiverName: undefined,
        addressId: undefined,
        versionId: undefined,
        entityId: undefined,
        postalCode: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        street: undefined,
        number: undefined,
        neighborhood: undefined,
        complement: undefined,
        reference: undefined,
    };
}
export const Address = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.addressType !== undefined) {
            StringValue.encode({ value: message.addressType }, writer.uint32(10).fork()).ldelim();
        }
        if (message.receiverName !== undefined) {
            StringValue.encode({ value: message.receiverName }, writer.uint32(18).fork()).ldelim();
        }
        if (message.addressId !== undefined) {
            StringValue.encode({ value: message.addressId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.versionId !== undefined) {
            StringValue.encode({ value: message.versionId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.entityId !== undefined) {
            StringValue.encode({ value: message.entityId }, writer.uint32(42).fork()).ldelim();
        }
        if (message.postalCode !== undefined) {
            StringValue.encode({ value: message.postalCode }, writer.uint32(50).fork()).ldelim();
        }
        if (message.city !== undefined) {
            StringValue.encode({ value: message.city }, writer.uint32(58).fork()).ldelim();
        }
        if (message.state !== undefined) {
            StringValue.encode({ value: message.state }, writer.uint32(66).fork()).ldelim();
        }
        if (message.country !== undefined) {
            StringValue.encode({ value: message.country }, writer.uint32(74).fork()).ldelim();
        }
        if (message.street !== undefined) {
            StringValue.encode({ value: message.street }, writer.uint32(82).fork()).ldelim();
        }
        if (message.number !== undefined) {
            StringValue.encode({ value: message.number }, writer.uint32(90).fork()).ldelim();
        }
        if (message.neighborhood !== undefined) {
            StringValue.encode({ value: message.neighborhood }, writer.uint32(98).fork()).ldelim();
        }
        if (message.complement !== undefined) {
            StringValue.encode({ value: message.complement }, writer.uint32(106).fork()).ldelim();
        }
        if (message.reference !== undefined) {
            StringValue.encode({ value: message.reference }, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddress();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addressType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.receiverName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.addressId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.versionId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.entityId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.postalCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.city = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.state = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.country = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.street = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.number = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.neighborhood = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.complement = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.reference = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            addressType: isSet(object.addressType)
                ? String(object.addressType)
                : undefined,
            receiverName: isSet(object.receiverName)
                ? String(object.receiverName)
                : undefined,
            addressId: isSet(object.addressId) ? String(object.addressId) : undefined,
            versionId: isSet(object.versionId) ? String(object.versionId) : undefined,
            entityId: isSet(object.entityId) ? String(object.entityId) : undefined,
            postalCode: isSet(object.postalCode)
                ? String(object.postalCode)
                : undefined,
            city: isSet(object.city) ? String(object.city) : undefined,
            state: isSet(object.state) ? String(object.state) : undefined,
            country: isSet(object.country) ? String(object.country) : undefined,
            street: isSet(object.street) ? String(object.street) : undefined,
            number: isSet(object.number) ? String(object.number) : undefined,
            neighborhood: isSet(object.neighborhood)
                ? String(object.neighborhood)
                : undefined,
            complement: isSet(object.complement)
                ? String(object.complement)
                : undefined,
            reference: isSet(object.reference) ? String(object.reference) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.addressType !== undefined &&
            (obj.addressType = message.addressType);
        message.receiverName !== undefined &&
            (obj.receiverName = message.receiverName);
        message.addressId !== undefined && (obj.addressId = message.addressId);
        message.versionId !== undefined && (obj.versionId = message.versionId);
        message.entityId !== undefined && (obj.entityId = message.entityId);
        message.postalCode !== undefined && (obj.postalCode = message.postalCode);
        message.city !== undefined && (obj.city = message.city);
        message.state !== undefined && (obj.state = message.state);
        message.country !== undefined && (obj.country = message.country);
        message.street !== undefined && (obj.street = message.street);
        message.number !== undefined && (obj.number = message.number);
        message.neighborhood !== undefined &&
            (obj.neighborhood = message.neighborhood);
        message.complement !== undefined && (obj.complement = message.complement);
        message.reference !== undefined && (obj.reference = message.reference);
        return obj;
    },
};
function createBaseSelectedAddress() {
    return {
        addressId: undefined,
        versionId: undefined,
        entityId: undefined,
        addressType: undefined,
        receiverName: undefined,
        street: undefined,
        number: undefined,
        complement: undefined,
        neighborhood: undefined,
        postalCode: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        reference: undefined,
    };
}
export const SelectedAddress = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.addressId !== undefined) {
            StringValue.encode({ value: message.addressId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.versionId !== undefined) {
            StringValue.encode({ value: message.versionId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.entityId !== undefined) {
            StringValue.encode({ value: message.entityId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.addressType !== undefined) {
            StringValue.encode({ value: message.addressType }, writer.uint32(34).fork()).ldelim();
        }
        if (message.receiverName !== undefined) {
            StringValue.encode({ value: message.receiverName }, writer.uint32(42).fork()).ldelim();
        }
        if (message.street !== undefined) {
            StringValue.encode({ value: message.street }, writer.uint32(50).fork()).ldelim();
        }
        if (message.number !== undefined) {
            StringValue.encode({ value: message.number }, writer.uint32(58).fork()).ldelim();
        }
        if (message.complement !== undefined) {
            StringValue.encode({ value: message.complement }, writer.uint32(66).fork()).ldelim();
        }
        if (message.neighborhood !== undefined) {
            StringValue.encode({ value: message.neighborhood }, writer.uint32(74).fork()).ldelim();
        }
        if (message.postalCode !== undefined) {
            StringValue.encode({ value: message.postalCode }, writer.uint32(82).fork()).ldelim();
        }
        if (message.city !== undefined) {
            StringValue.encode({ value: message.city }, writer.uint32(90).fork()).ldelim();
        }
        if (message.state !== undefined) {
            StringValue.encode({ value: message.state }, writer.uint32(98).fork()).ldelim();
        }
        if (message.country !== undefined) {
            StringValue.encode({ value: message.country }, writer.uint32(106).fork()).ldelim();
        }
        if (message.reference !== undefined) {
            StringValue.encode({ value: message.reference }, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSelectedAddress();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addressId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.versionId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.entityId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.addressType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.receiverName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.street = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.number = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.complement = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.neighborhood = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.postalCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.city = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.state = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.country = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.reference = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            addressId: isSet(object.addressId) ? String(object.addressId) : undefined,
            versionId: isSet(object.versionId) ? String(object.versionId) : undefined,
            entityId: isSet(object.entityId) ? String(object.entityId) : undefined,
            addressType: isSet(object.addressType)
                ? String(object.addressType)
                : undefined,
            receiverName: isSet(object.receiverName)
                ? String(object.receiverName)
                : undefined,
            street: isSet(object.street) ? String(object.street) : undefined,
            number: isSet(object.number) ? String(object.number) : undefined,
            complement: isSet(object.complement)
                ? String(object.complement)
                : undefined,
            neighborhood: isSet(object.neighborhood)
                ? String(object.neighborhood)
                : undefined,
            postalCode: isSet(object.postalCode)
                ? String(object.postalCode)
                : undefined,
            city: isSet(object.city) ? String(object.city) : undefined,
            state: isSet(object.state) ? String(object.state) : undefined,
            country: isSet(object.country) ? String(object.country) : undefined,
            reference: isSet(object.reference) ? String(object.reference) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.addressId !== undefined && (obj.addressId = message.addressId);
        message.versionId !== undefined && (obj.versionId = message.versionId);
        message.entityId !== undefined && (obj.entityId = message.entityId);
        message.addressType !== undefined &&
            (obj.addressType = message.addressType);
        message.receiverName !== undefined &&
            (obj.receiverName = message.receiverName);
        message.street !== undefined && (obj.street = message.street);
        message.number !== undefined && (obj.number = message.number);
        message.complement !== undefined && (obj.complement = message.complement);
        message.neighborhood !== undefined &&
            (obj.neighborhood = message.neighborhood);
        message.postalCode !== undefined && (obj.postalCode = message.postalCode);
        message.city !== undefined && (obj.city = message.city);
        message.state !== undefined && (obj.state = message.state);
        message.country !== undefined && (obj.country = message.country);
        message.reference !== undefined && (obj.reference = message.reference);
        return obj;
    },
};
function createBaseChangesAttachment() {
    return { id: undefined, changesData: [] };
}
export const ChangesAttachment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.changesData) {
            ChangesData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChangesAttachment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.changesData.push(ChangesData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            changesData: Array.isArray(object === null || object === void 0 ? void 0 : object.changesData)
                ? object.changesData.map((e) => ChangesData.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.changesData) {
            obj.changesData = message.changesData.map((e) => e ? ChangesData.toJSON(e) : undefined);
        }
        else {
            obj.changesData = [];
        }
        return obj;
    },
};
function createBaseChangesData() {
    return {
        reason: undefined,
        discountValue: undefined,
        incrementValue: undefined,
        itemsAdded: [],
        itemsRemoved: [],
        receipt: undefined,
    };
}
export const ChangesData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reason !== undefined) {
            StringValue.encode({ value: message.reason }, writer.uint32(10).fork()).ldelim();
        }
        if (message.discountValue !== undefined) {
            Int32Value.encode({ value: message.discountValue }, writer.uint32(18).fork()).ldelim();
        }
        if (message.incrementValue !== undefined) {
            Int32Value.encode({ value: message.incrementValue }, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.itemsAdded) {
            StringValue.encode({ value: v }, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.itemsRemoved) {
            ItemsRemoved.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.receipt !== undefined) {
            Receipt.encode(message.receipt, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChangesData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.discountValue = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.incrementValue = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.itemsAdded.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 5:
                    message.itemsRemoved.push(ItemsRemoved.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.receipt = Receipt.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reason: isSet(object.reason) ? String(object.reason) : undefined,
            discountValue: isSet(object.discountValue)
                ? Number(object.discountValue)
                : undefined,
            incrementValue: isSet(object.incrementValue)
                ? Number(object.incrementValue)
                : undefined,
            itemsAdded: Array.isArray(object === null || object === void 0 ? void 0 : object.itemsAdded)
                ? object.itemsAdded.map((e) => String(e))
                : [],
            itemsRemoved: Array.isArray(object === null || object === void 0 ? void 0 : object.itemsRemoved)
                ? object.itemsRemoved.map((e) => ItemsRemoved.fromJSON(e))
                : [],
            receipt: isSet(object.receipt)
                ? Receipt.fromJSON(object.receipt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reason !== undefined && (obj.reason = message.reason);
        message.discountValue !== undefined &&
            (obj.discountValue = message.discountValue);
        message.incrementValue !== undefined &&
            (obj.incrementValue = message.incrementValue);
        if (message.itemsAdded) {
            obj.itemsAdded = message.itemsAdded.map((e) => e);
        }
        else {
            obj.itemsAdded = [];
        }
        if (message.itemsRemoved) {
            obj.itemsRemoved = message.itemsRemoved.map((e) => e ? ItemsRemoved.toJSON(e) : undefined);
        }
        else {
            obj.itemsRemoved = [];
        }
        message.receipt !== undefined &&
            (obj.receipt = message.receipt
                ? Receipt.toJSON(message.receipt)
                : undefined);
        return obj;
    },
};
function createBaseItemsRemoved() {
    return {
        id: undefined,
        name: undefined,
        quantity: undefined,
        price: undefined,
    };
}
export const ItemsRemoved = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(18).fork()).ldelim();
        }
        if (message.quantity !== undefined) {
            Int32Value.encode({ value: message.quantity }, writer.uint32(26).fork()).ldelim();
        }
        if (message.price !== undefined) {
            Int32Value.encode({ value: message.price }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseItemsRemoved();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.quantity = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.price = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            quantity: isSet(object.quantity) ? Number(object.quantity) : undefined,
            price: isSet(object.price) ? Number(object.price) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
};
function createBaseReceipt() {
    return { date: undefined, orderId: undefined, receipt: undefined };
}
export const Receipt = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.date !== undefined) {
            StringValue.encode({ value: message.date }, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderId !== undefined) {
            StringValue.encode({ value: message.orderId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.receipt !== undefined) {
            StringValue.encode({ value: message.receipt }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReceipt();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.date = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.orderId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.receipt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            date: isSet(object.date) ? String(object.date) : undefined,
            orderId: isSet(object.orderId) ? String(object.orderId) : undefined,
            receipt: isSet(object.receipt) ? String(object.receipt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.date !== undefined && (obj.date = message.date);
        message.orderId !== undefined && (obj.orderId = message.orderId);
        message.receipt !== undefined && (obj.receipt = message.receipt);
        return obj;
    },
};
function createBaseStorePreferencesData() {
    return { countryCode: undefined, currencyCode: undefined };
}
export const StorePreferencesData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.countryCode !== undefined) {
            StringValue.encode({ value: message.countryCode }, writer.uint32(10).fork()).ldelim();
        }
        if (message.currencyCode !== undefined) {
            StringValue.encode({ value: message.currencyCode }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStorePreferencesData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.countryCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.currencyCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            countryCode: isSet(object.countryCode)
                ? String(object.countryCode)
                : undefined,
            currencyCode: isSet(object.currencyCode)
                ? String(object.currencyCode)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.countryCode !== undefined &&
            (obj.countryCode = message.countryCode);
        message.currencyCode !== undefined &&
            (obj.currencyCode = message.currencyCode);
        return obj;
    },
};
function createBaseOrderHook() {
    return {
        Domain: undefined,
        OrderId: undefined,
        State: undefined,
        LastState: undefined,
        LastChange: undefined,
        CurrentChange: undefined,
        Origin: undefined,
        hookConfig: undefined,
    };
}
export const OrderHook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.Domain !== undefined) {
            StringValue.encode({ value: message.Domain }, writer.uint32(10).fork()).ldelim();
        }
        if (message.OrderId !== undefined) {
            StringValue.encode({ value: message.OrderId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.State !== undefined) {
            StringValue.encode({ value: message.State }, writer.uint32(26).fork()).ldelim();
        }
        if (message.LastState !== undefined) {
            StringValue.encode({ value: message.LastState }, writer.uint32(34).fork()).ldelim();
        }
        if (message.LastChange !== undefined) {
            StringValue.encode({ value: message.LastChange }, writer.uint32(42).fork()).ldelim();
        }
        if (message.CurrentChange !== undefined) {
            StringValue.encode({ value: message.CurrentChange }, writer.uint32(50).fork()).ldelim();
        }
        if (message.Origin !== undefined) {
            Origin.encode(message.Origin, writer.uint32(58).fork()).ldelim();
        }
        if (message.hookConfig !== undefined) {
            StringValue.encode({ value: message.hookConfig }, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderHook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.Domain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.OrderId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.State = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.LastState = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.LastChange = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.CurrentChange = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.Origin = Origin.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.hookConfig = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            Domain: isSet(object.Domain) ? String(object.Domain) : undefined,
            OrderId: isSet(object.OrderId) ? String(object.OrderId) : undefined,
            State: isSet(object.State) ? String(object.State) : undefined,
            LastState: isSet(object.LastState) ? String(object.LastState) : undefined,
            LastChange: isSet(object.LastChange)
                ? String(object.LastChange)
                : undefined,
            CurrentChange: isSet(object.CurrentChange)
                ? String(object.CurrentChange)
                : undefined,
            Origin: isSet(object.Origin) ? Origin.fromJSON(object.Origin) : undefined,
            hookConfig: isSet(object.hookConfig)
                ? String(object.hookConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.Domain !== undefined && (obj.Domain = message.Domain);
        message.OrderId !== undefined && (obj.OrderId = message.OrderId);
        message.State !== undefined && (obj.State = message.State);
        message.LastState !== undefined && (obj.LastState = message.LastState);
        message.LastChange !== undefined && (obj.LastChange = message.LastChange);
        message.CurrentChange !== undefined &&
            (obj.CurrentChange = message.CurrentChange);
        message.Origin !== undefined &&
            (obj.Origin = message.Origin ? Origin.toJSON(message.Origin) : undefined);
        message.hookConfig !== undefined && (obj.hookConfig = message.hookConfig);
        return obj;
    },
};
function createBaseOrigin() {
    return { Account: undefined, Key: undefined };
}
export const Origin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.Account !== undefined) {
            StringValue.encode({ value: message.Account }, writer.uint32(10).fork()).ldelim();
        }
        if (message.Key !== undefined) {
            StringValue.encode({ value: message.Key }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrigin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.Account = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.Key = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            Account: isSet(object.Account) ? String(object.Account) : undefined,
            Key: isSet(object.Key) ? String(object.Key) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.Account !== undefined && (obj.Account = message.Account);
        message.Key !== undefined && (obj.Key = message.Key);
        return obj;
    },
};
function createBaseOrder() {
    return {
        orderId: undefined,
        orderFormId: undefined,
        clientProfileData: undefined,
        value: undefined,
        totals: [],
        status: undefined,
        storePreferencesData: undefined,
        creationDate: undefined,
        marketingData: undefined,
    };
}
export const Order = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderId !== undefined) {
            StringValue.encode({ value: message.orderId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderFormId !== undefined) {
            StringValue.encode({ value: message.orderFormId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.clientProfileData !== undefined) {
            Customer.encode(message.clientProfileData, writer.uint32(26).fork()).ldelim();
        }
        if (message.value !== undefined) {
            Int32Value.encode({ value: message.value }, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.totals) {
            OrderTotal.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.status !== undefined) {
            StringValue.encode({ value: message.status }, writer.uint32(50).fork()).ldelim();
        }
        if (message.storePreferencesData !== undefined) {
            StorePreferencesData.encode(message.storePreferencesData, writer.uint32(58).fork()).ldelim();
        }
        if (message.creationDate !== undefined) {
            StringValue.encode({ value: message.creationDate }, writer.uint32(66).fork()).ldelim();
        }
        if (message.marketingData !== undefined) {
            MarketingData.encode(message.marketingData, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.orderFormId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.clientProfileData = Customer.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.value = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.totals.push(OrderTotal.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.status = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.storePreferencesData = StorePreferencesData.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.creationDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.marketingData = MarketingData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderId: isSet(object.orderId) ? String(object.orderId) : undefined,
            orderFormId: isSet(object.orderFormId)
                ? String(object.orderFormId)
                : undefined,
            clientProfileData: isSet(object.clientProfileData)
                ? Customer.fromJSON(object.clientProfileData)
                : undefined,
            value: isSet(object.value) ? Number(object.value) : undefined,
            totals: Array.isArray(object === null || object === void 0 ? void 0 : object.totals)
                ? object.totals.map((e) => OrderTotal.fromJSON(e))
                : [],
            status: isSet(object.status) ? String(object.status) : undefined,
            storePreferencesData: isSet(object.storePreferencesData)
                ? StorePreferencesData.fromJSON(object.storePreferencesData)
                : undefined,
            creationDate: isSet(object.creationDate)
                ? String(object.creationDate)
                : undefined,
            marketingData: isSet(object.marketingData)
                ? MarketingData.fromJSON(object.marketingData)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderId !== undefined && (obj.orderId = message.orderId);
        message.orderFormId !== undefined &&
            (obj.orderFormId = message.orderFormId);
        message.clientProfileData !== undefined &&
            (obj.clientProfileData = message.clientProfileData
                ? Customer.toJSON(message.clientProfileData)
                : undefined);
        message.value !== undefined && (obj.value = message.value);
        if (message.totals) {
            obj.totals = message.totals.map((e) => e ? OrderTotal.toJSON(e) : undefined);
        }
        else {
            obj.totals = [];
        }
        message.status !== undefined && (obj.status = message.status);
        message.storePreferencesData !== undefined &&
            (obj.storePreferencesData = message.storePreferencesData
                ? StorePreferencesData.toJSON(message.storePreferencesData)
                : undefined);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate);
        message.marketingData !== undefined &&
            (obj.marketingData = message.marketingData
                ? MarketingData.toJSON(message.marketingData)
                : undefined);
        return obj;
    },
};
function createBaseMarketingData() {
    return {
        utmSource: undefined,
        utmPartner: undefined,
        utmMedium: undefined,
        utmCampaign: undefined,
        coupon: undefined,
    };
}
export const MarketingData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.utmSource !== undefined) {
            StringValue.encode({ value: message.utmSource }, writer.uint32(10).fork()).ldelim();
        }
        if (message.utmPartner !== undefined) {
            StringValue.encode({ value: message.utmPartner }, writer.uint32(18).fork()).ldelim();
        }
        if (message.utmMedium !== undefined) {
            StringValue.encode({ value: message.utmMedium }, writer.uint32(26).fork()).ldelim();
        }
        if (message.utmCampaign !== undefined) {
            StringValue.encode({ value: message.utmCampaign }, writer.uint32(34).fork()).ldelim();
        }
        if (message.coupon !== undefined) {
            StringValue.encode({ value: message.coupon }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketingData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.utmSource = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.utmPartner = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.utmMedium = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.utmCampaign = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.coupon = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            utmSource: isSet(object.utmSource) ? String(object.utmSource) : undefined,
            utmPartner: isSet(object.utmPartner)
                ? String(object.utmPartner)
                : undefined,
            utmMedium: isSet(object.utmMedium) ? String(object.utmMedium) : undefined,
            utmCampaign: isSet(object.utmCampaign)
                ? String(object.utmCampaign)
                : undefined,
            coupon: isSet(object.coupon) ? String(object.coupon) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.utmSource !== undefined && (obj.utmSource = message.utmSource);
        message.utmPartner !== undefined && (obj.utmPartner = message.utmPartner);
        message.utmMedium !== undefined && (obj.utmMedium = message.utmMedium);
        message.utmCampaign !== undefined &&
            (obj.utmCampaign = message.utmCampaign);
        message.coupon !== undefined && (obj.coupon = message.coupon);
        return obj;
    },
};
function createBaseCustomers() {
    return { customers: [] };
}
export const Customers = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.customers) {
            Customer.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomers();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customers.push(Customer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customers: Array.isArray(object === null || object === void 0 ? void 0 : object.customers)
                ? object.customers.map((e) => Customer.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.customers) {
            obj.customers = message.customers.map((e) => e ? Customer.toJSON(e) : undefined);
        }
        else {
            obj.customers = [];
        }
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
