import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import {
  CONVERSATION_OVERVIEW_URL,
  ConversationOverviewQuery,
  ConversationOverviewResponse,
  ConversationOverviewUrlParameters,
} from './types';

export const useConversationOverviewData = <TData = ConversationOverviewResponse>({
  businessId = '',
  startTime,
  endTime,
  ...options
}: Omit<
  UseQueryOptions<
    ConversationOverviewResponse,
    unknown,
    TData,
    readonly ['conversationOverview', ConversationOverviewUrlParameters, ConversationOverviewQuery]
  >,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<ConversationOverviewQuery> &
  ConversationOverviewUrlParameters) => {
  return useQuery(
    ['conversationOverview', { businessId }, { startTime, endTime }],
    () =>
      api.billingV1(CONVERSATION_OVERVIEW_URL, 'post', {
        path: { businessId },
        body: { body: { startTime, endTime } },
      }),
    options,
  );
};
