import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { AgentDescriptorUrlParameters, AgentDescriptorResponse, AGENT_DESCRIPTOR_URL } from './types';

export const useAgentDescriptor = <TData = AgentDescriptorResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<AgentDescriptorResponse, unknown, TData, readonly [target: 'sofia-agent', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  AgentDescriptorUrlParameters) => {
  return useQuery(
    ['sofia-agent', businessId],
    () =>
      api.aiV1(AGENT_DESCRIPTOR_URL, 'get', {
        path: { 'input.businessId': businessId },
      }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
