export enum ActivationScreen {
  HomeScreen,
  EditLanguage,
  EditDiscount,
  EditReminderDiscount,
  EditMessageType,
  MessagePreview,
  ReminderPreview,
  EditSafetyWindow,
  Conclusion,
}
