import { UseMutationOptions, UseMutateFunction, UseMutationResult } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_CREATE_OR_UPDATE_REACT_FLOW_DOCUMENT_URL =
  '/internal/v1/businesses/{input.documentInput.businessId}/create_or_update_react_flow_document/flow_documents';

type MutationCreateOrUpdateReactFlowDocumentURL = typeof MUTATION_CREATE_OR_UPDATE_REACT_FLOW_DOCUMENT_URL;

export type MutationCreateOrUpdateReactFlowDocumentResponse =
  Paths['flowV1'][MutationCreateOrUpdateReactFlowDocumentURL]['post']['responses']['200']['schema'];
type MutationCreateOrUpdateReactFlowDocumentBody = NonNullable<
  Paths['flowV1'][MutationCreateOrUpdateReactFlowDocumentURL]['post']['parameters']['body']['input']['documentInput']
>;
export type MutationCreateOrUpdateReactFlowDocumentInput = { businessId: string } & Required<
  Pick<MutationCreateOrUpdateReactFlowDocumentBody, 'id'>
> &
  MutationCreateOrUpdateReactFlowDocumentBody;
export type MutationCreateOrUpdateReactFlowDocumentOptions = Omit<
  UseMutationOptions<
    MutationCreateOrUpdateReactFlowDocumentResponse,
    ConnectlyError,
    MutationCreateOrUpdateReactFlowDocumentInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
export type CreateOrUpdateFlowDocumentMutationResult = UseMutationResult<
  MutationCreateOrUpdateReactFlowDocumentResponse,
  ConnectlyError,
  MutationCreateOrUpdateReactFlowDocumentInput,
  unknown
>;
