import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeChange, EdgeChange } from 'react-flow-renderer';
import { immutableReplace } from '../../utils/immutableReplace';
import { ChangesQueue, FlowChange, NodeObjectUpdate } from './types';
import {
  selectChangesQueue,
  selectMovingNodeIds,
  selectOnlineIds,
  setChangesQueue,
  setHasUnsavedChanges as setHasUnsavedChangesAction,
  setMovingNodeIds,
  setOnlineIds,
} from '../../state/flow';
import appStore from '../../state/store';

export const useChangesQueue = (): ChangesQueue => {
  // use getState rather than selector to get the current state immediately
  const movingNodeIds = useSelector(selectMovingNodeIds);
  const changesQueueCurrent = useCallback(() => selectChangesQueue(appStore.getState()), []);
  const movingNodeIdsCurrent = useCallback(() => selectMovingNodeIds(appStore.getState()), []);
  const onlineIdsCurrent = useCallback(() => selectOnlineIds(appStore.getState()), []);

  const dispatch = useDispatch();
  const setHasUnsavedChanges = useCallback((b: boolean) => dispatch(setHasUnsavedChangesAction(b)), [dispatch]);

  const reset = useCallback(() => {
    setMovingNodeIds([]);
    setHasUnsavedChanges(false);
  }, [setHasUnsavedChanges]);

  const isIdOnline = useCallback((id: string) => onlineIdsCurrent().includes(id), [onlineIdsCurrent]);

  const isSendable = useCallback(
    (change: FlowChange) => {
      if (change.node) {
        if (change.node.type === 'position') {
          return isIdOnline(change.node?.id) && !movingNodeIds.includes(change.node?.id);
        }
        if (change.node.type === 'remove') {
          return isIdOnline(change.node?.id);
        }
      }
      if (change.edge) {
        if (change.edge.type === 'add') {
          return isIdOnline(change.edge.item.source) && isIdOnline(change.edge.item.target);
        }
        if (change.edge.type === 'remove') {
          return isIdOnline(change.edge.id);
        }
      }
      if (change.nodeObjectUpdate) {
        return isIdOnline(change.nodeObjectUpdate?.id);
      }
      return true;
    },
    [isIdOnline, movingNodeIds],
  );

  const sendableChange = () => changesQueueCurrent().find((change) => isSendable(change));

  const addNodeChangesToQueue = useCallback(
    (nodeChanges: NodeChange[]) => {
      // only send position changes if change.dragging = false
      nodeChanges.forEach((change) => {
        if (change.type === 'position') {
          if (change.dragging) {
            // position is being dragged add node id to movingNodeIds
            setMovingNodeIds([...movingNodeIdsCurrent(), change.id]);
          } else {
            // position is final remove node id from movingNodeIds
            setMovingNodeIds([...movingNodeIdsCurrent().filter((id) => id !== change.id)]);
          }
        }
      });

      const shouldChangeBeSentToBackend = (nodeChange: NodeChange) => {
        if (nodeChange.type === 'position') {
          return nodeChange.position !== undefined;
        }
        return !['dimensions', 'select', 'reset'].includes(nodeChange.type);
      };

      // position changes should not preserved a new change with same id exist
      const nodeIdsOfNewPosChanges = nodeChanges.map((nodeChange) =>
        nodeChange.type === 'position' && nodeChange.position !== undefined ? nodeChange.id : undefined,
      );
      const isPositionOverwritten = (change: FlowChange) => {
        return change.node?.type === 'position' && nodeIdsOfNewPosChanges.includes(change.node?.id);
      };

      const wrapNodeChanges = (change: NodeChange): FlowChange => {
        return { node: change };
      };

      dispatch(
        setChangesQueue([
          // filter old changes remove position changes that are overwritten by new changes
          ...changesQueueCurrent().filter((change) => !isPositionOverwritten(change)),

          // filter new changes on whether backend is interested in them
          ...nodeChanges
            .filter((nodeChange) => shouldChangeBeSentToBackend(nodeChange))
            .map((nodeChange) => wrapNodeChanges(nodeChange)),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [dispatch, changesQueueCurrent, movingNodeIdsCurrent, setHasUnsavedChanges],
  );

  const addEdgeChangesToQueue = useCallback(
    (edgeChanges: EdgeChange[]) => {
      const shouldChangeBeSentToBackend = (edgeChange: EdgeChange) => {
        return !['select', 'reset'].includes(edgeChange.type);
      };

      // wrap changes since NodeRemoveChange and EdgeRemoveChange are structurally equal
      // and cannot be distinguished while sending to backend
      const wrapEdgeChanges = (change: EdgeChange): FlowChange => {
        return { edge: change };
      };

      dispatch(
        setChangesQueue([
          ...changesQueueCurrent(),

          // filter new changes on whether backend is interested in them
          ...edgeChanges
            .filter((edgeChange) => shouldChangeBeSentToBackend(edgeChange))
            .map((edgeChange) => wrapEdgeChanges(edgeChange)),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [changesQueueCurrent, dispatch, setHasUnsavedChanges],
  );

  const addNodeObjectUpdatesToQueue = useCallback(
    (updates: NodeObjectUpdate[]) => {
      const wrapNodeObjectUpdates = (update: NodeObjectUpdate): FlowChange => {
        return { nodeObjectUpdate: update };
      };

      dispatch(
        setChangesQueue([
          ...changesQueueCurrent(),

          // filter new changes on whether backend is interested in them
          ...updates.map((update) => wrapNodeObjectUpdates(update)),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [changesQueueCurrent, dispatch, setHasUnsavedChanges],
  );

  const removeNodeChange = useCallback(
    (nodeChange: NodeChange) => {
      dispatch(
        setChangesQueue([
          ...changesQueueCurrent().filter((change) => {
            if (change.edge || change.node?.type !== nodeChange.type) return true;
            return (
              (change.node?.type === 'add' &&
                nodeChange.type === 'add' &&
                change.node?.item.id !== nodeChange.item.id) ||
              (change.node?.type === 'position' &&
                nodeChange.type === 'position' &&
                change.node?.id !== nodeChange.id) ||
              (change.node?.type === 'remove' && nodeChange.type === 'remove' && change.node?.id !== nodeChange.id)
            );
          }),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [changesQueueCurrent, dispatch, setHasUnsavedChanges],
  );

  const removeEdgeChange = useCallback(
    (edgeChange: EdgeChange) => {
      dispatch(
        setChangesQueue([
          ...changesQueueCurrent().filter((change) => {
            if (change.node || change.edge?.type !== edgeChange.type) return true;
            return (
              (change.edge?.type === 'add' &&
                edgeChange.type === 'add' &&
                change.edge?.item.id !== edgeChange.item.id) ||
              (change.edge?.type === 'remove' && edgeChange.type === 'remove' && change.edge?.id !== edgeChange.id)
            );
          }),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [changesQueueCurrent, dispatch, setHasUnsavedChanges],
  );

  const removeNodeObjectUpdateChange = useCallback(
    (nodeObjectUpdate: NodeObjectUpdate) => {
      dispatch(
        setChangesQueue([
          ...changesQueueCurrent().filter((change) => {
            if (change.node || change.edge) return true;
            return change.nodeObjectUpdate?.id !== nodeObjectUpdate.id;
          }),
        ]),
      );

      setHasUnsavedChanges(changesQueueCurrent().length > 0);
    },
    [dispatch, changesQueueCurrent, setHasUnsavedChanges],
  );

  // mark edge or node id as online to be able to send further changes to backend
  // do not need to call this option when updateIdInChangesQueue is called
  const markIdsOnline = useCallback(
    (ids: string[]) => {
      dispatch(setOnlineIds([...onlineIdsCurrent(), ...ids]));
    },
    [dispatch, onlineIdsCurrent],
  );

  // used when first node is created id is replaced with backend generated id
  const updateIdInChangesQueue = useCallback(
    (oldNodeId: string, newNodeId: string) => {
      markIdsOnline([newNodeId]);
      dispatch(setChangesQueue(immutableReplace(changesQueueCurrent(), oldNodeId, newNodeId)));
    },
    [dispatch, changesQueueCurrent, markIdsOnline],
  );

  return {
    addEdgeChangesToQueue,
    addNodeChangesToQueue,
    addNodeObjectUpdatesToQueue,
    markIdsOnline,
    removeNodeChange,
    removeEdgeChange,
    removeNodeObjectUpdateChange,
    reset,
    sendableChange,
    updateIdInChangesQueue,
  };
};
