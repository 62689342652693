import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { ChatRoomDIContext } from '../../context';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectSendBehavior } from '../../state/userSlice';
import { selectAttachments, selectEligibilityDuration, selectIsActive, selectNotification, selectSendIsActive, selectTemplateButtonIsActive, selectTextMessageInput, } from '../../state/composerSlice';
import { tryRefreshEligibility } from '../../state/thunk';
import ComposerTemplatePopover from '../templatepopover/TemplatePopover';
export const ComposerComponentId = '985db1f9-1b61-49aa-9d4b-8f55e32e6561';
const COMPOSER_TIMER_REFRESH_RATE_IN_MS = 5000;
const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
function mapAttachmentStatusToViewState(state) {
    if (state.state === 'uploading') {
        return 'processing';
    }
    if (state.state === 'completed') {
        return 'success';
    }
    if (state.state === 'error') {
        return 'failure';
    }
    return assertUnreachable(state.state);
}
function mapAttachmentStateToViewModel(state) {
    return {
        localId: state.localId,
        localFileUrl: state.localUrl,
        state: mapAttachmentStatusToViewState(state),
        type: state.type,
        fileName: state.fileName,
    };
}
const Composer = (_props) => {
    const di = useContext(ChatRoomDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(tryRefreshEligibility({}));
        }, COMPOSER_TIMER_REFRESH_RATE_IN_MS);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const textMessageInput = useFeatureSelector(selectTextMessageInput);
    const sendBehavior = useFeatureSelector(selectSendBehavior);
    const sendIsActive = useFeatureSelector(selectSendIsActive);
    const templateButtonIsActive = useFeatureSelector(selectTemplateButtonIsActive);
    const isActive = useFeatureSelector(selectIsActive);
    const notification = useFeatureSelector(selectNotification);
    const attachments = useFeatureSelector(selectAttachments).map(mapAttachmentStateToViewModel);
    const eligibilityDuration = useFeatureSelector(selectEligibilityDuration);
    let inputAdornment;
    if (eligibilityDuration && eligibilityDuration >= 0 && textMessageInput === '' && attachments.length === 0) {
        const eligibilityDurationInDate = new Date(eligibilityDuration);
        let text = '';
        let style = 'success';
        if (eligibilityDuration > ONE_DAY_IN_MILLIS) {
            text = '24+ h';
        }
        else if (eligibilityDurationInDate.getUTCHours() >= 4) {
            text = eligibilityDurationInDate.toISOString().substr(11, 5);
        }
        else if (eligibilityDurationInDate.getUTCHours() > 0 || eligibilityDurationInDate.getUTCMinutes() >= 30) {
            style = 'warn';
            text = eligibilityDurationInDate.toISOString().substr(11, 5);
        }
        else {
            style = 'critical';
            text = eligibilityDurationInDate.toISOString().substr(11, 5);
        }
        inputAdornment = {
            style,
            text,
        };
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ComposerComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { ComposerTemplatePopover: ComposerTemplatePopover, attachments: attachments, textMessage: textMessageInput, isActive: isActive, inputAdornment: inputAdornment, notification: notification, sendBehavior: sendBehavior, sendIsActive: sendIsActive, templateButtonIsActive: templateButtonIsActive })) }));
};
export default Composer;
