import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@connectlyai-tenets/ui-styled-web';
import React from 'react';
import { DialogActionResult, QuickReplyTemplateDialogProps } from './types';

export const QuickReplyDeleteConfirmDialog = ({
  deleteConfirmDialog,
  onDeleteConfirmDialogClose,
}: QuickReplyTemplateDialogProps) => {
  return (
    <Dialog
      open={deleteConfirmDialog.isOpen}
      onClose={() => onDeleteConfirmDialogClose(DialogActionResult.DISMISS)}
      data-testid="settings/quickreply/deleteconfirmdialog"
      aria-labelledby="deleteconfirmdialog-title"
      aria-describedby="deleteconfirmdialog-description"
    >
      <DialogTitle id="deleteconfirmdialog-title">Remove Message?</DialogTitle>
      <DialogContent>
        <DialogContentText id="deleteconfirmdialog-description">
          Are you sure you want to remove this saved message?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onDeleteConfirmDialogClose(DialogActionResult.SECONDARY)}
          color="inherit"
          data-testid="settings/quickreply/secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onDeleteConfirmDialogClose(DialogActionResult.PRIMARY)}
          color="primary"
          data-testid="settings/quickreply/primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
