import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router';
const RedirectToLogin = ({ audience, redirectPath }) => {
    const [loginInit, setLoginInit] = React.useState(false);
    const { isLoading, isAuthenticated, loginWithRedirect, handleRedirectCallback } = useAuth0();
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        const isAuthCallBack = location.search.includes('code=') && location.search.includes('state=');
        if (!isLoading && !isAuthenticated && !isAuthCallBack && !loginInit) {
            (async function login() {
                await loginWithRedirect({
                    audience,
                    appState: { returnTo: redirectPath },
                });
                setLoginInit(true);
            })();
        }
        else if (isAuthenticated) {
            history.replace(redirectPath);
        }
    }, [
        isLoading,
        isAuthenticated,
        location,
        loginInit,
        loginWithRedirect,
        handleRedirectCallback,
        history,
        redirectPath,
        audience,
    ]);
    return null;
};
export default RedirectToLogin;
