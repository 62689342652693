import React, { useMemo } from 'react';
import { useResourceRateLimit, selectResourceLimitView } from '../useResourceRateLimit';

export const useResourceLimitView = ({
  businessId,
}: {
  businessId?: string;
}): { enabled: boolean; duration?: number; isLoading?: boolean } => {
  const {
    data: viewData,
    isLoading,
    isError: capDisabled,
  } = useResourceRateLimit(
    { businessId: businessId || '' },
    { enabled: !!businessId, select: selectResourceLimitView },
  );
  const { enabled, duration } = useMemo(() => {
    if (capDisabled) {
      return { enabled: false, duration: undefined };
    }
    return viewData || { enabled: false, duration: undefined };
  }, [capDisabled, viewData]);

  return {
    enabled,
    isLoading,
    duration,
  };
};
