import React, { useMemo, useCallback, useState, useLayoutEffect, useEffect } from 'react';
import ReactFlow, { MarkerType, Background, Controls, ReactFlowInstance } from 'react-flow-renderer';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Label,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useSetAtom } from 'jotai';
import { documentTypeAtom } from '@atoms/flow';
import { useAnalytics } from '@hooks/useAnalytics';
import { Link } from 'react-router-dom';
import { FlowChartCustomEdge } from '../FlowChartCustomEdge';
import { selectEdges, goToNextFlowState, selectNodes, setStarter, selectPreviewDocumentId } from '../../state/flow';
import { useFlowNodeTypes } from '../../hooks/useFlowNodeTypes';
import {
  useCampaignFromPreview,
  useDebouncedCallback,
  useReadOnlyFlowChangeAppliers,
  useReadOnlyFlowHandlers,
} from '../../hooks';
import { FlowContext, FlowDeps } from '../../contexts';
import { CampaignAutoComplete } from './CampaignAutoComplete';

export const useFlowChartGetStartedChooseCampaign = () => {
  const theme = useTheme();
  const setDocumentType = useSetAtom(documentTypeAtom);
  const edges = useSelector(selectEdges);
  const nodes = useSelector(selectNodes);

  const dispatch = useDispatch();

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const previewDocumentId = useSelector(selectPreviewDocumentId);

  const handlePreviewChange = useDebouncedCallback(() => {
    reactFlowInstance?.fitView({ duration: 600 });
  }, 200);

  useEffect(() => {
    handlePreviewChange();
  }, [handlePreviewChange, previewDocumentId]);

  const {
    campaignsWithFlowList,
    duplicateCampaignFromPreview,
    isCampaignSentBefore,
    isLoadingPreviewDocument,
    previewDocument,
    startCampaignFromPreview,
  } = useCampaignFromPreview(true);

  const flowChangeAppliers = useReadOnlyFlowChangeAppliers();
  const flowHandlers = useReadOnlyFlowHandlers();

  const flowDeps: FlowDeps = useMemo(
    () => ({
      flowChangeAppliers,
      flowHandlers,
      isDocumentEditable: false,
      isNodePotentialTarget: () => false,
      supportsLinkTracking: false,
      supportsVariables: false,
    }),
    [flowChangeAppliers, flowHandlers],
  );

  const { sendAnalytics } = useAnalytics();

  const handleDuplicate = useCallback(() => {
    sendAnalytics('clicks flow_duplicate_existing_campaign');
    setDocumentType('FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1');
    duplicateCampaignFromPreview();
    dispatch(setStarter('duplicate'));
    dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
  }, [dispatch, duplicateCampaignFromPreview, sendAnalytics, setDocumentType]);

  const handleEdit = useCallback(() => {
    sendAnalytics('clicks flow_edit_existing_campaign');
    setDocumentType('FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1');
    startCampaignFromPreview();
    dispatch(setStarter('edit'));
    dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
  }, [dispatch, sendAnalytics, startCampaignFromPreview, setDocumentType]);

  const handleResend = useCallback(() => {
    sendAnalytics('clicks flow_resend_existing_campaign');
    setDocumentType('FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1');
    startCampaignFromPreview();
    dispatch(setStarter('resend'));
    dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
  }, [dispatch, sendAnalytics, startCampaignFromPreview, setDocumentType]);

  const defaultEdgeOptions = useMemo(
    () => ({
      markerEnd: { type: MarkerType.ArrowClosed, color: theme.palette.connectionLine },
      style: { stroke: theme.palette.connectionLine, strokeWidth: 2, fill: 'none' },
      type: 'FLOW_OBJECT_TYPE_SIMPLE_EDGE',
    }),
    [theme.palette.connectionLine],
  );

  const edgeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SIMPLE_EDGE: FlowChartCustomEdge,
    }),
    [],
  );
  const nodeTypes = useFlowNodeTypes();

  const reactFlowProps = {
    defaultEdgeOptions,
    deleteKeyCode: null,
    edges,
    edgeTypes,
    minZoom: 0.1,
    maxZoom: 2.5,
    selectionKeyCode: null,
    multiSelectionKeyCode: null,
    nodes,
    nodeTypes,
    proOptions: { account: 'paid-custom', hideAttribution: true },
    selectNodesOnDrag: false,
    snapToGrid: true,
    onInit: setReactFlowInstance,
  };

  const [isOpen, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const [isEditable, setIsEditable] = useState(false);

  // If the campaign is sent before, we should not allow the user to edit it.
  // for ui to not jump, change isEditable only when the modal is closed.
  useLayoutEffect(() => {
    if (!isOpen) setIsEditable(!isCampaignSentBefore);
  }, [isOpen, isCampaignSentBefore]);

  const isV3 = useMemo(() => previewDocument?.entity?.type === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3', [previewDocument]);

  const isInteractionDisabled = isOpen || isLoadingPreviewDocument || isV3;

  return {
    campaignsWithFlowList,
    flowDeps,
    handleClose,
    handleDuplicate,
    handleEdit,
    handleOpen,
    handleResend,
    isEditable,
    isInteractionDisabled,
    isLoadingPreviewDocument,
    isV3,
    reactFlowProps,
  };
};

export const FlowChartGetStartedChooseCampaign = () => {
  const theme = useTheme();
  const {
    campaignsWithFlowList,
    flowDeps,
    handleClose,
    handleDuplicate,
    handleEdit,
    handleOpen,
    handleResend,
    isEditable,
    isInteractionDisabled,
    isLoadingPreviewDocument,
    isV3,
    reactFlowProps,
  } = useFlowChartGetStartedChooseCampaign();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <FlowContext.Provider value={flowDeps}>
      <Box sx={{ width: '100%', height: '100%', position: 'relative', background: theme.palette.flowGradient }}>
        <Box sx={{ width: '100%', position: 'absolute', zIndex: 10 }}>
          <Box
            sx={{
              alignItems: 'center',
              background: theme.palette.background.paper,
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              display: 'flex',
              gap: 2,
              maxWidth: theme.spacing(120), // 960px
              mt: 4,
              mx: isLargeScreen ? 'auto' : 5,
              p: 2,
            }}
          >
            <CampaignAutoComplete
              campaignsWithFlowList={campaignsWithFlowList}
              onOpen={handleOpen}
              onClose={handleClose}
            />
            {!isEditable && (
              <>
                <Button variant="contained" onClick={handleResend} disabled={isInteractionDisabled}>
                  Resend
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleDuplicate} disabled={isInteractionDisabled}>
                  Duplicate & Edit
                </Button>
              </>
            )}
            {isEditable && (
              <Button variant="outlined" color="secondary" onClick={handleEdit} disabled={isInteractionDisabled}>
                Edit
              </Button>
            )}
          </Box>
          {isV3 && (
            <Alert
              severity="warning"
              sx={{
                width: 'fit-content',
                mx: isLargeScreen ? 'auto' : 5,
                mt: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Label variant="subtitle1">
                This campaign uses named variables, to open it please enable the feature flag from{' '}
                <Button variant="text" component={Link} to="/settings/features">
                  settings page
                </Button>
              </Label>
            </Alert>
          )}
        </Box>
        <ReactFlow {...reactFlowProps}>
          <Background />
          <Controls showInteractive={false} />
        </ReactFlow>
        {isLoadingPreviewDocument && (
          <CircularProgress sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />
        )}
      </Box>
    </FlowContext.Provider>
  );
};
