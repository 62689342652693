/* eslint-disable no-restricted-syntax */
import { ViewPlugin, EditorView, Decoration, DecorationSet, ViewUpdate } from '@codemirror/view';
import { RangeSetBuilder } from '@codemirror/state';
import { VariableType } from '@hooks/useFlowVariables/types';
import { FormatWidget } from './FormatWidget';
import { buildDecorationRangesFromString, DecorationRange } from './formatter';

const buildDecorationRangesFromView = (view: EditorView): DecorationRange[] => {
  const allText = view.state.doc.toString();
  return buildDecorationRangesFromString(allText);
};

function buildDecorations(
  ranges: DecorationRange[],
  lineHeight: number,
  variableTypeOf: (variable: string) => VariableType,
): DecorationSet {
  const builder = new RangeSetBuilder<Decoration>();
  ranges.forEach((range) => {
    const { to, from, text, decorationStyle } = range;
    const widget = Decoration.widget({
      widget: new FormatWidget({
        text,
        format: decorationStyle,
        lineHeight,
        variableType: decorationStyle.includes('vari') ? variableTypeOf(text) : 'STANDARD',
      }),
    });
    builder.add(from, to, widget);
  });

  return builder.finish();
}

export const FormatViewPlugin = ({
  lineHeight,
  variableTypeOf,
}: {
  lineHeight: number;
  variableTypeOf: (variable: string) => VariableType;
}) => {
  return ViewPlugin.fromClass(
    class {
      ranges: DecorationRange[];

      lineHeight: number;

      constructor(view: EditorView) {
        const ranges = buildDecorationRangesFromView(view);
        this.ranges = ranges;
        this.lineHeight = lineHeight;
      }

      update(update: ViewUpdate) {
        const ranges = buildDecorationRangesFromView(update.view);
        this.ranges = ranges;
      }

      get decorations() {
        return buildDecorations(this.ranges, this.lineHeight, variableTypeOf);
      }
    },
    {
      decorations: (v) => v.decorations,
    },
  );
};
