import React, { useCallback } from 'react';
import { Box, Dialog, DialogContent, Label, Button, Modal } from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { exitFlowModalOpenAtom } from '@atoms/flow';
import { useFeatureFlag } from '@hooks';
import { ExitFlowModalProps } from './types';

const useExitFlowModal = () => {
  const [isOpen, setIsOpen] = useAtom(exitFlowModalOpenAtom);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return {
    isOpen,
    onClose,
  };
};

export const ExitFlowModal = ({ goBackToPreface }: ExitFlowModalProps) => {
  const { isOpen, onClose } = useExitFlowModal();
  return (
    <Modal
      dialog={{ open: isOpen, onClose, maxWidth: 'xl' }}
      title="Exit Flow Builder"
      contentText="Are you sure you want to exit? Any unsaved progress will be lost"
      actions={{
        tertiaryButton: {
          children: 'Cancel',
          onClick: onClose,
        },
        destructiveButton: {
          children: 'Confirm',
          onClick: goBackToPreface,
        },
      }}
    />
  );
};
