import {
  BaseDecorationStyle,
  DecorationStyleCombination,
  BasicDecorationRange,
  CombinedDecorationRange,
  Pattern,
  SkipDict,
  DecorationRange,
} from './types';
import { ALLOWED_STYLES, SPECIAL_PATTERNS, BASIC_PATTERNS, ALL_PATTERNS } from './constants';

const buildDecorationStyleCombination = (styles: BaseDecorationStyle[]): DecorationStyleCombination => {
  // This should never happen. styles should have length >= 1
  if (!styles.length) return 'bold';
  styles.sort();
  const style: string = styles.join('-');
  const match = ALLOWED_STYLES.find((allowed) => allowed === style);
  if (match) {
    return match;
  }
  return 'bold';
};

// Find ranges for all included patterns in str, sorted by start position
// ignore ranges are skipped over.
// In practice, str is one line, and ignoreRanges are vari or mono ranges.
const buildRangesForPatterns = (
  str: string,
  patternList: Pattern[],
  ignoreRanges?: BasicDecorationRange[],
): BasicDecorationRange[] => {
  const matches: BasicDecorationRange[] = [];

  for (let patternIndex = 0; patternIndex < patternList.length; patternIndex += 1) {
    const { name, open, close } = patternList[patternIndex];

    const matchSubstring = (index: number, toMatch: string) => {
      if (toMatch.length > 1) {
        return str.substring(index, index + toMatch.length) === toMatch;
      }
      return str[index] === toMatch;
    };
    let start = -1;
    let end;
    let i = 0;
    const skipDict = (ignoreRanges || []).reduce((dict: SkipDict, range: BasicDecorationRange) => {
      dict[range.from] = range.to;
      return dict;
    }, {} as SkipDict);
    while (i < str.length) {
      const skip = skipDict[i];
      // skip over atomic ranges (vari, mono)
      if (skip) {
        i = skip;
        // eslint-disable-next-line no-continue
        continue;
      }
      if (matchSubstring(i, open) && start === -1) {
        // Found the start of a pattern
        start = i;
        i += open.length;
      } else if (matchSubstring(i, close) && start !== -1) {
        // Found the end of a pattern
        end = i;

        // Add the match to the array
        matches.push({
          from: start,
          to: end + close.length,
          text: str.substring(start + open.length, end),
          decorationStyle: name,
        });

        // Reset start for the next match
        start = -1;
        i += close.length;
      } else {
        i += 1;
      }
    }
  }

  matches.sort((a, b) => a.from - b.from);
  return matches;
};

export const buildAllBasicRanges = (str: string): BasicDecorationRange[] => {
  let specialRanges: BasicDecorationRange[] = [];
  // special ranges are atomic and have a priority. Formats overlapping with a special range are ignored
  for (let patternIndex = 0; patternIndex < SPECIAL_PATTERNS.length; patternIndex += 1) {
    const pattern = SPECIAL_PATTERNS[patternIndex];
    specialRanges = [...specialRanges, ...buildRangesForPatterns(str, [pattern], specialRanges)];
    specialRanges.sort((a, b) => a.from - b.from);
  }
  const basicRanges = buildRangesForPatterns(str, BASIC_PATTERNS, specialRanges);
  const allRanges = [...specialRanges, ...basicRanges];
  allRanges.sort((a, b) => a.from - b.from);
  return allRanges;
};

const calcStylesDiff = (styles1: BaseDecorationStyle[], styles2: BaseDecorationStyle[]): BaseDecorationStyle[] => {
  // return items in styles1 that are not in styles1
  return styles1.filter((style) => !styles2.includes(style));
};

const extractTotalOverlap = (
  leftRange: CombinedDecorationRange,
  rightRange: CombinedDecorationRange,
): {
  newLeftRange: CombinedDecorationRange;
  newMiddleRange: CombinedDecorationRange;
  newRightRange: CombinedDecorationRange;
} => {
  const leftLength = rightRange.from - leftRange.from;
  const lastLeftDelimLength = ALL_PATTERNS[leftRange.decorationStyle[leftRange.decorationStyle.length - 1]].open.length;
  const newLeftRange: CombinedDecorationRange = {
    from: leftRange.from,
    to: rightRange.from,
    text: leftRange.text.substring(0, leftLength - lastLeftDelimLength),
    decorationStyle: leftRange.decorationStyle,
  };
  const newMiddleRange: CombinedDecorationRange = {
    from: rightRange.from,
    to: rightRange.to,
    text: rightRange.text,
    decorationStyle: [...leftRange.decorationStyle, ...rightRange.decorationStyle],
  };
  const newRightRange: CombinedDecorationRange = {
    from: rightRange.to,
    to: leftRange.to,
    text: leftRange.text.substring(leftRange.text.length - (leftRange.to - rightRange.to) + lastLeftDelimLength),
    decorationStyle: leftRange.decorationStyle,
  };
  return { newLeftRange, newMiddleRange, newRightRange };
};

const extractPartialOverlap = (
  leftRange: CombinedDecorationRange,
  rightRange: CombinedDecorationRange,
): {
  newLeftRange: CombinedDecorationRange;
  newMiddleRange: CombinedDecorationRange;
  newRightRange: CombinedDecorationRange;
} => {
  const stylesDiffLeft = calcStylesDiff(leftRange.decorationStyle, rightRange.decorationStyle);
  const stylesDiffRight = calcStylesDiff(rightRange.decorationStyle, leftRange.decorationStyle);

  const leftOffset = stylesDiffLeft.length ? ALL_PATTERNS[stylesDiffLeft[0]].open.length : 1;
  const rightOffset = stylesDiffRight.length ? ALL_PATTERNS[stylesDiffRight[0]].close.length : 1;

  const overlapSize = leftRange.to - rightRange.from;

  const newLeftRange: CombinedDecorationRange = {
    from: leftRange.from,
    to: rightRange.from,
    text: leftRange.text.substring(0, leftRange.text.length - overlapSize + leftOffset),
    decorationStyle: leftRange.decorationStyle,
  };

  const rightSize = rightRange.to - leftRange.to;
  const newMiddleRange: CombinedDecorationRange = {
    from: rightRange.from,
    to: leftRange.to,
    text: rightRange.text.substring(0, rightRange.text.length - rightSize),
    decorationStyle: [...leftRange.decorationStyle, ...rightRange.decorationStyle],
  };

  const newRightRange: CombinedDecorationRange = {
    from: leftRange.to,
    to: rightRange.to,
    text: rightRange.text.substring(rightRange.text.length - rightSize + rightOffset),
    decorationStyle: rightRange.decorationStyle,
  };
  return { newLeftRange, newMiddleRange, newRightRange };
};

// Processs BasicDecorationRanges by calculating overlaps.
// Input ranges are sorted by start position
// We look only at the leftmost two ranges.
// If they overlap, these two ranges will be replaced by three ranges that reduce overlaps.
// If they do not overlap, the left range is moved to the completed list.
// After every iteration, ranges are sorted again by start.
// Finished when original list is empty.
export const combineBasicRanges = (ranges: BasicDecorationRange[]): DecorationRange[] => {
  const combinedRanges: CombinedDecorationRange[] = ranges.map((range: BasicDecorationRange) => {
    return {
      ...range,
      decorationStyle: [range.decorationStyle],
    };
  });
  const finalRanges: DecorationRange[] = [];

  while (combinedRanges.length) {
    const leftRange = combinedRanges[0];
    if (combinedRanges.length === 1) {
      finalRanges.push({
        ...combinedRanges[0],
        decorationStyle: buildDecorationStyleCombination(combinedRanges[0].decorationStyle),
      });
      combinedRanges.shift();
    } else {
      const rightRange = combinedRanges[1];
      if (rightRange.from < leftRange.to) {
        // total or partial overlap
        if (rightRange.to < leftRange.to) {
          // total overlap
          const { newLeftRange, newMiddleRange, newRightRange } = extractTotalOverlap(leftRange, rightRange);
          // replace left and right with these new ranges
          combinedRanges.shift(); // remove leftRange
          combinedRanges.shift(); // remove rightRange
          combinedRanges.unshift(newLeftRange, newMiddleRange, newRightRange);
        } else {
          // partial overlap
          const { newLeftRange, newMiddleRange, newRightRange } = extractPartialOverlap(leftRange, rightRange);
          // replace left and right with these new ranges
          combinedRanges.shift(); // remove leftRange
          combinedRanges.shift(); // remove rightRange
          combinedRanges.unshift(newLeftRange, newMiddleRange, newRightRange);
        }
      } else {
        // If no overlap, move left range to completed list
        const firstRange = combinedRanges.shift();
        if (firstRange) {
          finalRanges.push({
            ...firstRange,
            decorationStyle: buildDecorationStyleCombination(firstRange.decorationStyle),
          });
        }
      }
    }
    combinedRanges.sort((a, b) => a.from - b.from);
  }

  // remove any empty ranges
  return finalRanges.filter((range) => range.from !== range.to);
};

export const buildDecorationRangesFromString = (allText: string): DecorationRange[] => {
  const textSplit = allText.split('\n');
  const combinedRanges: DecorationRange[] = [];
  let offset = 0;
  for (let lineIndex = 0; lineIndex < textSplit.length; lineIndex += 1) {
    const line = textSplit[lineIndex];
    const lineRanges: BasicDecorationRange[] = buildAllBasicRanges(line);
    if (offset) {
      for (let r = 0; r < lineRanges.length; r += 1) {
        lineRanges[r].from += offset;
        lineRanges[r].to += offset;
      }
    }
    combinedRanges.push(...combineBasicRanges(lineRanges));
    offset += line.length + 1; // new line counts as one char in CodeMirror
  }
  return combinedRanges;
};
