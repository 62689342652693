import React from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { SMBOnboardingContent } from './SMBOnboardingContent';
import { SMBOnboardingFooter } from './SMBOnboardingFooter';
import { SMBOnboardingHeader } from './SMBOnboardingHeader';
import { SMBOnboardingProps } from './types';

export const SMBOnboarding = ({ onNextStep, shopify, whatsApp }: SMBOnboardingProps) => {
  return (
    <Box sx={{ width: 600, minHeight: 580, display: 'flex', flexDirection: 'column' }}>
      <SMBOnboardingHeader isBilling={whatsApp.isWhatsAppActive && shopify.isShopifyActive} />
      <SMBOnboardingContent onNextStep={onNextStep} shopify={shopify} whatsApp={whatsApp} />
      <SMBOnboardingFooter whatsApp={whatsApp} />
    </Box>
  );
};
