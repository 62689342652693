import { Language, Component, TextParameter, MediaParameter, MessageTemplateInput, } from "../../../../models/messaging/template/models";
import { BusinessChannelSelector } from "../../../../models/profile/models";
import { LinkCreateCustomerContextBodyV1 } from "../../../../models/link/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.connectly.templated_message.v1";
function createBaseTemplatedMessage() {
    return {
        templateId: "",
        language: undefined,
        components: [],
        preview: undefined,
    };
}
export const TemplatedMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.templateId !== "") {
            writer.uint32(10).string(message.templateId);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.components) {
            Component.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.preview !== undefined) {
            TemplatedMessagePreview.encode(message.preview, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateId = reader.string();
                    break;
                case 2:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.components.push(Component.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.preview = TemplatedMessagePreview.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateId: isSet(object.templateId) ? String(object.templateId) : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => Component.fromJSON(e))
                : [],
            preview: isSet(object.preview)
                ? TemplatedMessagePreview.fromJSON(object.preview)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.templateId !== undefined && (obj.templateId = message.templateId);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        if (message.components) {
            obj.components = message.components.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        message.preview !== undefined &&
            (obj.preview = message.preview
                ? TemplatedMessagePreview.toJSON(message.preview)
                : undefined);
        return obj;
    },
};
function createBaseTemplatedMessagePreview() {
    return { header: undefined, body: undefined, footer: undefined, buttons: [] };
}
export const TemplatedMessagePreview = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== undefined) {
            TemplatedMessagePreview_Header.encode(message.header, writer.uint32(10).fork()).ldelim();
        }
        if (message.body !== undefined) {
            TemplatedMessagePreview_Body.encode(message.body, writer.uint32(18).fork()).ldelim();
        }
        if (message.footer !== undefined) {
            TemplatedMessagePreview_Footer.encode(message.footer, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.buttons) {
            TemplatedMessagePreview_Button.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = TemplatedMessagePreview_Header.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.body = TemplatedMessagePreview_Body.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.footer = TemplatedMessagePreview_Footer.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.buttons.push(TemplatedMessagePreview_Button.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header)
                ? TemplatedMessagePreview_Header.fromJSON(object.header)
                : undefined,
            body: isSet(object.body)
                ? TemplatedMessagePreview_Body.fromJSON(object.body)
                : undefined,
            footer: isSet(object.footer)
                ? TemplatedMessagePreview_Footer.fromJSON(object.footer)
                : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => TemplatedMessagePreview_Button.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined &&
            (obj.header = message.header
                ? TemplatedMessagePreview_Header.toJSON(message.header)
                : undefined);
        message.body !== undefined &&
            (obj.body = message.body
                ? TemplatedMessagePreview_Body.toJSON(message.body)
                : undefined);
        message.footer !== undefined &&
            (obj.footer = message.footer
                ? TemplatedMessagePreview_Footer.toJSON(message.footer)
                : undefined);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? TemplatedMessagePreview_Button.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Header() {
    return { text: undefined, media: undefined };
}
export const TemplatedMessagePreview_Header = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            TextParameter.encode(message.text, writer.uint32(10).fork()).ldelim();
        }
        if (message.media !== undefined) {
            MediaParameter.encode(message.media, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Header();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = TextParameter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.media = MediaParameter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text)
                ? TextParameter.fromJSON(object.text)
                : undefined,
            media: isSet(object.media)
                ? MediaParameter.fromJSON(object.media)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined &&
            (obj.text = message.text
                ? TextParameter.toJSON(message.text)
                : undefined);
        message.media !== undefined &&
            (obj.media = message.media
                ? MediaParameter.toJSON(message.media)
                : undefined);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Body() {
    return { text: undefined };
}
export const TemplatedMessagePreview_Body = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            TextParameter.encode(message.text, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Body();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = TextParameter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text)
                ? TextParameter.fromJSON(object.text)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined &&
            (obj.text = message.text
                ? TextParameter.toJSON(message.text)
                : undefined);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Footer() {
    return { text: undefined };
}
export const TemplatedMessagePreview_Footer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            TextParameter.encode(message.text, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Footer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = TextParameter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text)
                ? TextParameter.fromJSON(object.text)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined &&
            (obj.text = message.text
                ? TextParameter.toJSON(message.text)
                : undefined);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Button() {
    return { valueOneof: undefined };
}
export const TemplatedMessagePreview_Button = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "url") {
            TemplatedMessagePreview_Button_URL.encode(message.valueOneof.url, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.$case) === "quickReply") {
            TemplatedMessagePreview_Button_QuickReply.encode(message.valueOneof.quickReply, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.valueOneof) === null || _c === void 0 ? void 0 : _c.$case) === "phoneNumber") {
            TemplatedMessagePreview_Button_PhoneNumber.encode(message.valueOneof.phoneNumber, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Button();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valueOneof = {
                        $case: "url",
                        url: TemplatedMessagePreview_Button_URL.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.valueOneof = {
                        $case: "quickReply",
                        quickReply: TemplatedMessagePreview_Button_QuickReply.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.valueOneof = {
                        $case: "phoneNumber",
                        phoneNumber: TemplatedMessagePreview_Button_PhoneNumber.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            valueOneof: isSet(object.url)
                ? {
                    $case: "url",
                    url: TemplatedMessagePreview_Button_URL.fromJSON(object.url),
                }
                : isSet(object.quickReply)
                    ? {
                        $case: "quickReply",
                        quickReply: TemplatedMessagePreview_Button_QuickReply.fromJSON(object.quickReply),
                    }
                    : isSet(object.phoneNumber)
                        ? {
                            $case: "phoneNumber",
                            phoneNumber: TemplatedMessagePreview_Button_PhoneNumber.fromJSON(object.phoneNumber),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "url" &&
            (obj.url = ((_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.url)
                ? TemplatedMessagePreview_Button_URL.toJSON((_c = message.valueOneof) === null || _c === void 0 ? void 0 : _c.url)
                : undefined);
        ((_d = message.valueOneof) === null || _d === void 0 ? void 0 : _d.$case) === "quickReply" &&
            (obj.quickReply = ((_e = message.valueOneof) === null || _e === void 0 ? void 0 : _e.quickReply)
                ? TemplatedMessagePreview_Button_QuickReply.toJSON((_f = message.valueOneof) === null || _f === void 0 ? void 0 : _f.quickReply)
                : undefined);
        ((_g = message.valueOneof) === null || _g === void 0 ? void 0 : _g.$case) === "phoneNumber" &&
            (obj.phoneNumber = ((_h = message.valueOneof) === null || _h === void 0 ? void 0 : _h.phoneNumber)
                ? TemplatedMessagePreview_Button_PhoneNumber.toJSON((_j = message.valueOneof) === null || _j === void 0 ? void 0 : _j.phoneNumber)
                : undefined);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Button_URL() {
    return { text: "", link: "" };
}
export const TemplatedMessagePreview_Button_URL = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.link !== "") {
            writer.uint32(18).string(message.link);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Button_URL();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.link = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            link: isSet(object.link) ? String(object.link) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.link !== undefined && (obj.link = message.link);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Button_PhoneNumber() {
    return { text: "", phoneNumber: "" };
}
export const TemplatedMessagePreview_Button_PhoneNumber = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(18).string(message.phoneNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Button_PhoneNumber();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.phoneNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        return obj;
    },
};
function createBaseTemplatedMessagePreview_Button_QuickReply() {
    return { text: "", payload: "" };
}
export const TemplatedMessagePreview_Button_QuickReply = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.payload !== "") {
            writer.uint32(18).string(message.payload);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplatedMessagePreview_Button_QuickReply();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.payload = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            payload: isSet(object.payload) ? String(object.payload) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
function createBaseComposeTemplatedMessageInput() {
    return {
        channelSelector: undefined,
        templateId: "",
        language: undefined,
        components: [],
        modifiers: [],
        messageTemplateInput: undefined,
    };
}
export const ComposeTemplatedMessageInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channelSelector !== undefined) {
            BusinessChannelSelector.encode(message.channelSelector, writer.uint32(10).fork()).ldelim();
        }
        if (message.templateId !== "") {
            writer.uint32(18).string(message.templateId);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.components) {
            Component.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.modifiers) {
            ComposeTemplatedMessageInputModifier.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.messageTemplateInput !== undefined) {
            MessageTemplateInput.encode(message.messageTemplateInput, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComposeTemplatedMessageInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channelSelector = BusinessChannelSelector.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.templateId = reader.string();
                    break;
                case 3:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.components.push(Component.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.modifiers.push(ComposeTemplatedMessageInputModifier.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.messageTemplateInput = MessageTemplateInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelSelector: isSet(object.channelSelector)
                ? BusinessChannelSelector.fromJSON(object.channelSelector)
                : undefined,
            templateId: isSet(object.templateId) ? String(object.templateId) : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => Component.fromJSON(e))
                : [],
            modifiers: Array.isArray(object === null || object === void 0 ? void 0 : object.modifiers)
                ? object.modifiers.map((e) => ComposeTemplatedMessageInputModifier.fromJSON(e))
                : [],
            messageTemplateInput: isSet(object.messageTemplateInput)
                ? MessageTemplateInput.fromJSON(object.messageTemplateInput)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channelSelector !== undefined &&
            (obj.channelSelector = message.channelSelector
                ? BusinessChannelSelector.toJSON(message.channelSelector)
                : undefined);
        message.templateId !== undefined && (obj.templateId = message.templateId);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        if (message.components) {
            obj.components = message.components.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        if (message.modifiers) {
            obj.modifiers = message.modifiers.map((e) => e ? ComposeTemplatedMessageInputModifier.toJSON(e) : undefined);
        }
        else {
            obj.modifiers = [];
        }
        message.messageTemplateInput !== undefined &&
            (obj.messageTemplateInput = message.messageTemplateInput
                ? MessageTemplateInput.toJSON(message.messageTemplateInput)
                : undefined);
        return obj;
    },
};
function createBaseComposeTemplatedMessageInputModifier() {
    return { modifierOneof: undefined };
}
export const ComposeTemplatedMessageInputModifier = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.modifierOneof) === null || _a === void 0 ? void 0 : _a.$case) === "createLinkContext") {
            ComposeTemplatedMessageInputModifier_LinkContextV1.encode(message.modifierOneof.createLinkContext, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComposeTemplatedMessageInputModifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.modifierOneof = {
                        $case: "createLinkContext",
                        createLinkContext: ComposeTemplatedMessageInputModifier_LinkContextV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            modifierOneof: isSet(object.createLinkContext)
                ? {
                    $case: "createLinkContext",
                    createLinkContext: ComposeTemplatedMessageInputModifier_LinkContextV1.fromJSON(object.createLinkContext),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.modifierOneof) === null || _a === void 0 ? void 0 : _a.$case) === "createLinkContext" &&
            (obj.createLinkContext = ((_b = message.modifierOneof) === null || _b === void 0 ? void 0 : _b.createLinkContext)
                ? ComposeTemplatedMessageInputModifier_LinkContextV1.toJSON((_c = message.modifierOneof) === null || _c === void 0 ? void 0 : _c.createLinkContext)
                : undefined);
        return obj;
    },
};
function createBaseComposeTemplatedMessageInputModifier_LinkContextV1() {
    return { context: undefined };
}
export const ComposeTemplatedMessageInputModifier_LinkContextV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.context !== undefined) {
            LinkCreateCustomerContextBodyV1.encode(message.context, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComposeTemplatedMessageInputModifier_LinkContextV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.context = LinkCreateCustomerContextBodyV1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            context: isSet(object.context)
                ? LinkCreateCustomerContextBodyV1.fromJSON(object.context)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.context !== undefined &&
            (obj.context = message.context
                ? LinkCreateCustomerContextBodyV1.toJSON(message.context)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
