import { Paths } from '@connectlyai-tenets/sdk';

export type UnsubscribeParameters = {
  businessId: string;
};

export const UNSUBSCRIBE_URL = '/v1/businesses/{businessId}/organization/unsubscribe';

type UnsubscribeUrl = typeof UNSUBSCRIBE_URL;

export type UnsubscribeInput = Paths['profileV1'][UnsubscribeUrl]['post']['parameters']['body']['body'];

export type UnsubscribeVariables = UnsubscribeInput & UnsubscribeParameters;

export type UnsubscribeResponse = Paths['profileV1'][UnsubscribeUrl]['post']['responses']['200']['schema'];
