import React, { FC, useMemo, useState } from 'react';
import {
  ArrowBackIcon,
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ForumIcon,
  IconButton,
  InputAdornment,
  Label,
  MenuItem,
  Select,
} from '@connectlyai-tenets/ui-styled-web';
import i18next from 'i18next';
import { groupBy, uniq } from 'lodash';
import { selectBusinessId, useConversationOverviewData, useDatePeriod, useMeData } from '@hooks';
import { SIZE_CONTENT_L } from '../../../../ui-theme';
import { getCountryFlag } from '../../../../presentation/preview/utils';
import { ConversationsModalProps } from './types';
import { constantToTitleCase } from '../../../../utils/titleCase';

const useConversationsModal = ({ open, onClose, startDate, endDate }: ConversationsModalProps) => {
  const [conversationFilter, setConversationFilter] = useState('ALL');

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: conversationOverview, isLoading: isConversationOverviewLoading } = useConversationOverviewData({
    businessId: businessId as string,
    enabled: !!businessId,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    startTime: startDate.toISOString(),
    endTime: endDate.toISOString(),
  });

  const conversationFilterOptions: string[] = useMemo(
    () =>
      uniq([
        'ALL',
        ...(conversationOverview?.entity
          ?.filter((x) => !!x.conversationCategory)
          .map((x) => (x.conversationCategory as string).replace(/_/g, ''))
          .sort() || []),
      ]),
    [conversationOverview],
  );

  const regionNames = new Intl.DisplayNames([i18next.language], { type: 'region' });
  const filteredConversations = useMemo(() => {
    if (!conversationOverview?.entity) return [];
    if (conversationFilter === 'ALL') {
      const countryGrouped = groupBy(conversationOverview.entity, 'country');
      return Object.keys(countryGrouped)
        .map((country) => ({
          country,
          conversations: countryGrouped[country]
            .map((x) => (x.conversations ? x.conversations : 0))
            .reduce((acc, x) => acc + x, 0),
        }))
        .sort((a, b) => b.conversations - a.conversations);
    }
    return conversationOverview.entity.filter((x) => x.conversationCategory === conversationFilter);
  }, [conversationOverview, conversationFilter]);

  const totalFilteredConversations = useMemo(
    () => filteredConversations.map((x) => (x.conversations ? x.conversations : 0)).reduce((acc, x) => acc + x, 0),
    [filteredConversations],
  );

  const { datePeriodToString } = useDatePeriod();
  return {
    conversationFilter,
    conversationFilterOptions,
    datePeriodToString,
    endDate,
    filteredConversations,
    isConversationOverviewLoading,
    onClose,
    open,
    regionNames,
    setConversationFilter,
    startDate,
    totalFilteredConversations,
  };
};

export const ConversationsModal: FC<ConversationsModalProps> = ({ open, onClose, startDate, endDate }) => {
  const {
    conversationFilter,
    conversationFilterOptions,
    filteredConversations,
    isConversationOverviewLoading,
    regionNames,
    totalFilteredConversations,
    setConversationFilter,
  } = useConversationsModal({
    open,
    onClose,
    startDate,
    endDate,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton size="small" onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Label variant="h5" fontWeight={500}>
          Conversations
        </Label>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ width: (theme) => theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Label variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary, fontWeight: 400 }}>
          Conversations are message threads between you and your customers. Conversations can last up to 24-hours and
          must be initiated by you (business-initiated) or your customers (user-initiated). Conversation costs vary
          based on recipients locations.
        </Label>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {conversationFilterOptions.length > 1 && (
            <Select
              value={conversationFilter}
              renderValue={constantToTitleCase}
              onChange={(event) => setConversationFilter(event.target.value)}
              startAdornment={
                <InputAdornment position="start" sx={{ pointerEvents: 'none' }}>
                  <ForumIcon fontSize="small" />
                </InputAdornment>
              }
              sx={{
                width: (theme) => theme.spacing(85),
                flexGrow: 1,
                borderRadius: '10px',
                '& .MuiSelect-select': { py: '11px', pl: '12px' },
              }}
            >
              {conversationFilterOptions.map((x) => (
                <MenuItem key={x} value={x}>
                  {constantToTitleCase(x)}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        {totalFilteredConversations && totalFilteredConversations > 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: (theme) => theme.palette.grey.A100,
              borderRadius: '10px',
              gap: 2,
              p: 2,
              maxHeight: (theme) => theme.spacing(60),
              overflow: 'hidden',
              scrollbarGutter: 'stable',
              '&:hover': {
                overflow: 'auto',
              },
            }}
          >
            <Label variant="subtitle1">
              Total:&nbsp;{!isConversationOverviewLoading && totalFilteredConversations}
            </Label>
            <Divider />
            <Label variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
              Breakdown by recipient countries:
            </Label>
            {filteredConversations.map(
              ({ country, conversations }) =>
                country && (
                  <Box
                    key={country}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Label variant="h5">{getCountryFlag(country)}</Label>
                      <Label sx={{ fontSize: '16px', lineHeight: '100%', fontWeight: 500 }}>
                        {regionNames.of(country)}
                      </Label>
                    </Box>
                    <Box>
                      <Label variant="subtitle2">{conversations?.toLocaleString()}</Label>
                    </Box>
                  </Box>
                ),
            )}
          </Box>
        ) : (
          <Label variant="body2" sx={{ my: 2, textAlign: 'center' }}>
            No conversations found, please try changing the date range or conversation direction.
          </Label>
        )}
      </DialogContent>
    </Dialog>
  );
};
