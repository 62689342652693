import React from 'react';
import { Button, Label, Grid, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderItem } from '@components/TemplateBuilderItem';
import { TemplateBuilderTitle } from '@components/TemplateBuilderTitle';
import { TextOrVariable } from '@components/TextOrVariable';
import { ParametersQuickProps } from './types';

export const ParametersQuick = ({ optional, title, parameters, active, handleEdit }: ParametersQuickProps) => {
  const theme = useTheme();
  return (
    <TemplateBuilderItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <TemplateBuilderTitle optional={optional}>{title}</TemplateBuilderTitle>
      {active && (
        <>
          <Grid container spacing={2} alignItems="stretch">
            {!!parameters.length &&
              parameters.map((param) => {
                return (
                  <>
                    <Grid item xs={3}>
                      <Label variant="body1" sx={{ color: theme.palette.text.secondary, wordBreak: 'break-word' }}>
                        {param.key.value}
                      </Label>
                    </Grid>
                    <Grid item xs={9}>
                      <TextOrVariable key={param.value.value} value={param.value.value} />
                    </Grid>
                  </>
                );
              })}
          </Grid>

          <Button variant="contained" onClick={handleEdit}>
            Edit
          </Button>
        </>
      )}
    </TemplateBuilderItem>
  );
};
