import { BusinessChannelMetaResponse } from './types';

export const selectScoreAndLimit = (data: BusinessChannelMetaResponse) => ({
  score: data.entity?.whatsappMetadata?.qualityWrapper?.qualityScore,
  limit: data.entity?.whatsappMetadata?.messagesLimitTier,
});

export const selectAccountStatus = (data: BusinessChannelMetaResponse) => ({
  phoneNumberStatus: data.entity?.whatsappMetadata?.phoneNumberStatus?.v12,
  reviewStatus: data.entity?.whatsappMetadata?.accountReviewStatus?.v12,
  verificationStatus: data.entity?.whatsappMetadata?.businessAccountVerificationStatus?.v12,
  isOfficialBusinessAccount: data.entity?.whatsappMetadata?.isOfficialBusinessAccount,
});
