/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Label, TypographyProps, styledMui } from '@connectlyai-tenets/ui-styled-web';

const LabelComponent = (props?: TypographyProps & { component?: string }) => {
  return <Label {...props} variant="caption" />;
};

export const CaptionLabel = styledMui(LabelComponent)<TypographyProps>`
  color: ${(props) => props.theme.palette.text.secondary};
`;
