// wrapper for Material-UI Tooltip component that wraps title in a <pre> tag to preserve newlines
import React, { PropsWithChildren } from 'react';
import { Tooltip, TooltipProps } from '@connectlyai-tenets/ui-styled-web';

export const FormattedTooltip: React.FC<PropsWithChildren<TooltipProps>> = ({ title, children, ...rest }) => {
  return (
    <Tooltip
      title={
        title && (
          <pre style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', whiteSpace: 'pre-wrap', margin: 0 }}>
            {title}
          </pre>
        )
      }
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
