import React, { FC, useCallback } from 'react';
import {
  Box,
  CampaignIcon,
  CampaignBlackIcon,
  Label,
  Radio,
  useTheme,
  useMediaQuery,
  ConnectlyCard,
  Stack,
} from '@connectlyai-tenets/ui-styled-web';
import { FlowChartGetStartedSidebarProps } from './types';
import { SIZE_MEDIA_M } from '../../ui-theme';

export const FlowChartGetStartedSidebar: FC<FlowChartGetStartedSidebarProps> = ({
  onResendCampaignSelected,
  onCampaignSelected,
  selectedCategory,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleCampaignSelected = useCallback(() => {
    if (onCampaignSelected) {
      onCampaignSelected();
    }
  }, [onCampaignSelected]);
  const handleResendCampaignSelected = useCallback(() => {
    if (onResendCampaignSelected) {
      onResendCampaignSelected();
    }
  }, [onResendCampaignSelected]);

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 2,
        px: isLargeScreen ? 3 : 2,
        py: isLargeScreen ? 4.5 : 2,
        width: '100%',
      }}
    >
      <ConnectlyCard
        title="New Campaign"
        media={{
          children: (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <CampaignIcon
                style={{
                  flexShrink: 0,
                  height: theme.spacing(SIZE_MEDIA_M),
                  width: theme.spacing(SIZE_MEDIA_M),
                }}
              />
              <Radio sx={{ p: 0 }} checked={selectedCategory === 'new-campaign'} />
            </Stack>
          ),
        }}
        onClick={handleCampaignSelected}
        sx={{
          // eslint-disable-next-line max-len
          border: `1px solid ${selectedCategory === 'new-campaign' ? theme.palette.primary.main : undefined}`,
        }}
      >
        {isLargeScreen && (
          <Label color="textSecondary" variant="body2">
            Send a single or multi-message campaign to your audience.
          </Label>
        )}
      </ConnectlyCard>
      <ConnectlyCard
        title="Resend or edit a Campaign"
        media={{
          children: (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <CampaignBlackIcon
                style={{
                  flexShrink: 0,
                  height: theme.spacing(SIZE_MEDIA_M),
                  width: theme.spacing(SIZE_MEDIA_M),
                }}
              />
              <Radio sx={{ p: 0 }} checked={selectedCategory === 'resend-campaign'} />
            </Stack>
          ),
        }}
        sx={{
          // eslint-disable-next-line max-len
          border: `1px solid ${selectedCategory === 'resend-campaign' ? theme.palette.primary.main : undefined}`,
        }}
        onClick={handleResendCampaignSelected}
      >
        {isLargeScreen && (
          <Label color="textSecondary" variant="body2">
            Choose a past campaign, make edits and send to your audience again.
          </Label>
        )}
      </ConnectlyCard>
    </Box>
  );
};
