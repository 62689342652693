import React from 'react';
import { Stack, Label, Tooltip, InfoIcon, Box, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { DeliveryStatsSectionProps } from './types';
import { LinearProgressBar } from '../LinearProgressBar';

export const DeliveryStatsSection = ({ title, hint = '', percentValue = -1, value }: DeliveryStatsSectionProps) => {
  const theme = useTheme();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
          <Label variant="h7" sx={{ fontWeight: '400' }}>
            {title}
          </Label>
          {hint.length > 0 && (
            <Tooltip arrow placement="top" title={hint}>
              <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
          {percentValue > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '100px',
                background: theme.palette.grey.A100,
                padding: '2px 8px',
              }}
            >
              <Label
                variant="h7"
                sx={{
                  fontSize: '12px',
                }}
              >
                {Math.round(percentValue)}%
              </Label>
            </Box>
          )}
          <Label sx={{ fontWeight: '500' }}>{value}</Label>
        </Stack>
      </Stack>
      <Stack mt="8px">
        <LinearProgressBar variant="determinate" value={percentValue < 0 ? 100 : percentValue} />
      </Stack>
    </Stack>
  );
};
