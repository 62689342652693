import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { BusinessKeyQuery, BusinessKeyResponse } from './types';

export const useBusinessKeyData = <TData = BusinessKeyResponse>({
  entityBusinessId,
  type,
  enabled,
}: { entityBusinessId: string } & BusinessKeyQuery &
  Omit<
    UseQueryOptions<BusinessKeyResponse, unknown, TData, readonly ['businessKey']>,
    'initialData' | 'queryFn' | 'queryKey'
  >) =>
  useQuery({
    queryKey: ['businessKey', { type }],
    queryFn: () =>
      api.profileV1('/v1/businesses/{entity.businessId}/business_keys', 'post', {
        path: { 'entity.businessId': entityBusinessId },
        body: { entity: { type } },
      }),
    enabled,
  });
