import { MessageTemplateStatus, MediaParameter_Type, } from '@connectlyai-idl/models/dist/models/messaging/template/models';
import { WhatsAppMessageLimitTier, WhatsAppQualityScore, } from '@connectlyai-idl/models/dist/models/profile/store/models';
import { CampaignState, MessageTemplateComponent_Format, } from '../../entities';
export function mapAudienceToViewModel(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        name: dto.name,
        size: dto.size,
        created: dto.createdAt !== undefined ? new Date(dto.createdAt) : undefined,
        updated: dto.updatedAt !== undefined ? new Date(dto.updatedAt) : undefined,
        query: dto.query,
    };
}
export function mapSendoutToViewModel(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        campaignId: dto.campaignId,
        created: dto.createdAt !== undefined ? new Date(dto.createdAt) : undefined,
        dispated: dto.dispatchedAt !== undefined ? new Date(dto.dispatchedAt) : undefined,
        completed: dto.completedAt !== undefined ? new Date(dto.completedAt) : undefined,
    };
}
export function mapSendoutCampaignToViewModel(dto) {
    return {
        id: dto.id,
        businessId: dto.businessId,
        name: dto.name,
    };
}
export var TemplateStatus;
(function (TemplateStatus) {
    TemplateStatus["Approved"] = "Approved";
    TemplateStatus["Pending"] = "Pending";
    TemplateStatus["Rejected"] = "Rejected";
    TemplateStatus["Paused"] = "Paused";
    TemplateStatus["Disabled"] = "Disabled";
    TemplateStatus["Undefined"] = "";
})(TemplateStatus || (TemplateStatus = {}));
export function mapCampaignToViewModel(entity) {
    var _a, _b;
    let templates = [];
    const { config } = entity;
    if (config) {
        switch ((_a = config.configOneof) === null || _a === void 0 ? void 0 : _a.$case) {
            case 'broadcast': {
                const { broadcast } = config.configOneof;
                if (broadcast) {
                    switch ((_b = broadcast.templateOneof) === null || _b === void 0 ? void 0 : _b.$case) {
                        case 'stbBroadcastTemplateV1': {
                            templates = broadcast.templateOneof.stbBroadcastTemplateV1.messageTemplates.map((x) => x.waMessageTemplateId);
                            break;
                        }
                        case 'waBroadcastTemplateV1': {
                            templates = [broadcast.templateOneof.waBroadcastTemplateV1.waMessageTemplateId, '', '', ''];
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    return {
        created: entity.createdAt !== undefined ? new Date(entity.createdAt) : undefined,
        completed: entity.completedAt !== undefined ? new Date(entity.completedAt) : undefined,
        dispatched: entity.dispatchedAt !== undefined ? new Date(entity.dispatchedAt) : undefined,
        id: entity.id,
        isDeleteEnabled: false,
        isEditEnabled: entity.state === CampaignState.CAMPAIGN_STATE_DRAFT,
        isOutcomeEnabled: entity.state === CampaignState.CAMPAIGN_STATE_COMPLETED && Boolean(entity.reportUrl),
        isScheduleEnabled: !entity.scheduledAt && !entity.completedAt && entity.state !== CampaignState.CAMPAIGN_STATE_DRAFT,
        isUnscheduleEnabled: Boolean(entity.scheduledAt) && !entity.completedAt,
        scheduled: entity.scheduledAt !== undefined ? new Date(entity.scheduledAt) : undefined,
        templates,
        title: entity.configName,
        verified: entity.verifiedAt !== undefined ? new Date(entity.verifiedAt) : undefined,
    };
}
export function mapWhatsAppQualitySignalsModel(entity, businessChannelID) {
    var _a, _b, _c, _d, _e;
    const metadata = entity[businessChannelID];
    if (!metadata) {
        return {
            qualityScore: WhatsAppQualityScore.WHATS_APP_QUALITY_SCORE_UNSPECIFIED,
            messagingLimit: WhatsAppMessageLimitTier.WHATS_APP_MESSAGE_LIMIT_TIER_UNSPECIFIED,
            messagesAvailable: undefined,
        };
    }
    if (!((_a = metadata.metadataOneof) === null || _a === void 0 ? void 0 : _a.whatsappMetadata)) {
        return {
            qualityScore: WhatsAppQualityScore.WHATS_APP_QUALITY_SCORE_UNSPECIFIED,
            messagingLimit: WhatsAppMessageLimitTier.WHATS_APP_MESSAGE_LIMIT_TIER_UNSPECIFIED,
            messagesAvailable: undefined,
        };
    }
    const qualityScore = ((_d = (_c = (_b = metadata === null || metadata === void 0 ? void 0 : metadata.metadataOneof) === null || _b === void 0 ? void 0 : _b.whatsappMetadata) === null || _c === void 0 ? void 0 : _c.qualityWrapper) === null || _d === void 0 ? void 0 : _d.qualityScore) ||
        WhatsAppQualityScore.WHATS_APP_QUALITY_SCORE_UNSPECIFIED;
    return {
        qualityScore,
        messagingLimit: (_e = metadata.metadataOneof) === null || _e === void 0 ? void 0 : _e.whatsappMetadata.messagesLimitTier,
        messagesAvailable: 0,
    };
}
export function bodyComponentContent(components) {
    var _a, _b;
    const body = components.find((component) => { var _a; return ((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'body'; });
    if (!body) {
        return '';
    }
    if (((_a = body.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'body') {
        return ((_b = body.componentOneof.body.text) === null || _b === void 0 ? void 0 : _b.text) ? body.componentOneof.body.text.text : '';
    }
    return '';
}
export function footerComponentContent(components) {
    var _a, _b;
    const footer = components.find((component) => { var _a; return ((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'footer'; });
    if (!footer) {
        return '';
    }
    if (((_a = footer.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'footer') {
        return ((_b = footer.componentOneof.footer.text) === null || _b === void 0 ? void 0 : _b.text) ? footer.componentOneof.footer.text.text : '';
    }
    return '';
}
export function buttonComponentContent(externalTemplate) {
    var _a;
    let buttons = [];
    if (externalTemplate) {
        (_a = externalTemplate.channelOneof) === null || _a === void 0 ? void 0 : _a.whatsapp.components.forEach((component) => {
            buttons = buttons.concat(component.buttons);
        });
    }
    return buttons;
}
export function extractRejectedReason(externalTemplate) {
    var _a, _b;
    if (externalTemplate && ((_a = externalTemplate.channelOneof) === null || _a === void 0 ? void 0 : _a.whatsapp.rejectedReason) !== 'NONE') {
        return (_b = externalTemplate.channelOneof) === null || _b === void 0 ? void 0 : _b.whatsapp.rejectedReason;
    }
    return undefined;
}
function mapHeaderType(type) {
    switch (type) {
        case MediaParameter_Type.TYPE_IMAGE:
            return MessageTemplateComponent_Format.IMAGE;
        case MediaParameter_Type.TYPE_AUDIO:
            return MessageTemplateComponent_Format.VIDEO;
        case MediaParameter_Type.TYPE_VIDEO:
            return MessageTemplateComponent_Format.VIDEO;
        case MediaParameter_Type.TYPE_DOCUMENT:
            return MessageTemplateComponent_Format.DOCUMENT;
        default:
            return MessageTemplateComponent_Format.FORMAT_UNSPECIFIED;
    }
}
export const isTemplateHeaderTypeMedia = (type) => {
    return (type === MessageTemplateComponent_Format.IMAGE ||
        type === MessageTemplateComponent_Format.VIDEO ||
        type === MessageTemplateComponent_Format.DOCUMENT);
};
function extractHeaderComponentContent(components) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const header = components.find((component) => { var _a; return ((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'header'; });
    if (!header) {
        return {
            headerType: MessageTemplateComponent_Format.FORMAT_UNSPECIFIED,
            headerText: '',
            headerUrl: '',
        };
    }
    if (((_a = header.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'header') {
        if (((_b = header.componentOneof.header.formatOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'text') {
            return {
                headerType: MessageTemplateComponent_Format.TEXT,
                headerText: header.componentOneof.header.formatOneof.text.text,
                headerUrl: '',
            };
        }
        if (((_c = header.componentOneof.header.formatOneof) === null || _c === void 0 ? void 0 : _c.$case) === 'media') {
            const url = ((_h = (_g = (_f = (_e = (_d = header === null || header === void 0 ? void 0 : header.componentOneof) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.formatOneof) === null || _f === void 0 ? void 0 : _f.media) === null || _g === void 0 ? void 0 : _g.example) === null || _h === void 0 ? void 0 : _h[0]) || '';
            return {
                headerType: mapHeaderType(header.componentOneof.header.formatOneof.media.type),
                headerText: '',
                headerUrl: url,
            };
        }
    }
    return {
        headerType: MessageTemplateComponent_Format.FORMAT_UNSPECIFIED,
        headerText: '',
        headerUrl: '',
    };
}
function templateLanguage(language) {
    if (!language) {
        return '';
    }
    return language.code;
}
export function templateCreatedAtDate(date) {
    return date !== undefined ? new Date(date) : undefined;
}
function mapMessageTemplateStatus(status) {
    switch (status) {
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_APPROVED:
            return TemplateStatus.Approved;
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PENDING:
            return TemplateStatus.Pending;
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_REJECTED:
            return TemplateStatus.Rejected;
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PAUSED:
            return TemplateStatus.Paused;
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_DISABLED:
            return TemplateStatus.Disabled;
        default:
            return TemplateStatus.Undefined;
    }
}
function mapStatusDescription(status, rejectedReason) {
    switch (status) {
        case 'Approved':
            return 'Approved';
        case 'Pending':
            return 'The message is being reviewed by WhatsApp. This review process can take up to 48 hours.';
        case 'Rejected': {
            const description = 'The message was rejected by WhatsApp';
            switch (rejectedReason) {
                case 'TAG_CONTENT_MISMATCH':
                    return `${description} because the language and/or template category selected don’t match the template content.`;
                case 'INVALID_FORMAT':
                    return `${description} because placeholders or other elements that were formatted incorrectly. Spelling, punctuation, grammar errors can also get rejected.`;
                case 'ABUSIVE_CONTENT':
                    return `${description} because message contains abusive content, or content not in the chosen language. Spam-like messages will also be rejected for this reason.`;
                default:
                    return `${description}.`;
            }
        }
        case 'Paused':
            return 'This message was paused by WhatsApp due to low quality score.';
        case 'Disabled':
            return 'This message was disabled by WhatsApp automatically due to customer feedback.';
        default:
            return '';
    }
}
function mapMessageTemplateToViewModel(template) {
    const { dto, group } = template;
    const extractedHeader = extractHeaderComponentContent(dto.templateComponents);
    const extractedBody = bodyComponentContent(dto.templateComponents);
    const extractedFooter = footerComponentContent(dto.templateComponents);
    const extractedButtons = buttonComponentContent(dto.externalTemplate);
    const extractedRejectionReason = extractRejectedReason(dto.externalTemplate);
    const mappedStatus = mapMessageTemplateStatus(dto.status);
    return {
        id: dto.id,
        name: group.name,
        language: templateLanguage(dto.language),
        created: templateCreatedAtDate(dto.createdAt),
        content: bodyComponentContent(dto.templateComponents),
        status: mappedStatus,
        statusDescription: mapStatusDescription(mappedStatus, extractedRejectionReason),
        structure: {
            header: extractedHeader,
            body: extractedBody,
            footer: extractedFooter,
            buttons: extractedButtons,
            attachment: {
                localId: '',
                localFileUrl: '',
                remoteUrl: extractedHeader.headerUrl,
                name: '',
                state: 'success',
            },
        },
    };
}
export function mapMessageTemplateGroupsToViewModel(templateGroups) {
    const viewModel = {};
    templateGroups.forEach((group) => {
        const templates = group.templates.map((dto) => mapMessageTemplateToViewModel({ dto, group }));
        viewModel[group.id] = {
            id: group.id,
            name: group.name,
            category: group.category,
            created: templateCreatedAtDate(group.createdAt),
            updated: templateCreatedAtDate(group.updatedAt),
            templates,
        };
    });
    return viewModel;
}
function mapMessageTemplateHeaderToViewModel(components) {
    var _a, _b, _c;
    const header = components.find((component) => { var _a; return ((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'header'; });
    if (!header) {
        return undefined;
    }
    let text = '';
    let media = MediaParameter_Type.TYPE_UNSPECIFIED;
    let example = [];
    if (((_a = header.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'header') {
        if (((_b = header.componentOneof.header.formatOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'text') {
            text = header.componentOneof.header.formatOneof.text.text;
            example = header.componentOneof.header.formatOneof.text.example;
        }
        if (((_c = header.componentOneof.header.formatOneof) === null || _c === void 0 ? void 0 : _c.$case) === 'media') {
            media = header.componentOneof.header.formatOneof.media.type;
            example = header.componentOneof.header.formatOneof.media.example;
        }
    }
    return {
        format: media,
        text,
        example,
    };
}
function mapMessageTemplateButtonToViewModel(components) {
    const buttons = components.filter((component) => { var _a; return ((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'button'; });
    const props = [];
    buttons.forEach((button, ix) => {
        var _a, _b;
        if (((_a = button.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'button') {
            const id = ix.toString();
            let text = '';
            let type = '';
            switch ((_b = button.componentOneof.button.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) {
                case 'quickReply':
                    text = button.componentOneof.button.typeOneof.quickReply.text;
                    type = 'quickReply';
                    break;
                case 'url':
                    text = button.componentOneof.button.typeOneof.url.text;
                    type = 'url';
                    break;
                case 'phoneNumber':
                    text = button.componentOneof.button.typeOneof.phoneNumber.text;
                    type = 'phoneNumber';
                    break;
                default:
                    break;
            }
            props.push({
                id,
                text,
                type,
            });
        }
    });
    return props;
}
export function mapMessageTemplateToViewProps(entity) {
    if (!entity) {
        return null;
    }
    const { dto, group } = entity;
    if (!dto) {
        return null;
    }
    if (!group) {
        return null;
    }
    return {
        id: dto.id,
        name: group.name,
        language: templateLanguage(dto.language),
        header: mapMessageTemplateHeaderToViewModel(dto.templateComponents),
        body: bodyComponentContent(dto.templateComponents),
        footer: footerComponentContent(dto.templateComponents),
        buttons: mapMessageTemplateButtonToViewModel(dto.templateComponents),
    };
}
