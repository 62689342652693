import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Navigator } from '@connectlyai-tenets/navigation';
import { generateChatroomRoute } from '@connectlyai-sdks/inbox-navigation';
import { useTranslation } from 'react-i18next';
import { Label, Stack, ChatIcon, useTheme, IconButton, ConnectlyMessageIcon } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useMeData } from '@hooks';
import {
  sortPhoneNumber,
  sortString,
  sortDate,
  sortNumber,
  EnhancedTable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
} from '@components/EnhancedTable';
import { useTableDataInRanges } from '../../hooks/useTableDataInRanges/useTableDataInRanges';
import { selectAnalyticsTableData } from '../../selectors';
import { formatDate, formatPhoneNumber, track } from '../../../../utils';
import { NavigationContext } from '../../../../contexts';
import { SENTIMENT_EMOJIS, SENTIMENT_LABELS } from '../../constants';
import { Sentiment } from '../../types';
import { ExportData } from '../ExportData';

const ViewDetailsButton = ({
  roomId,
  businessId,
  navigatorProvider,
}: {
  roomId: string;
  businessId: string | undefined;
  navigatorProvider: () => Navigator;
}) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="close"
      onClick={() => {
        track('(sofia) open chatroom from analytics', { roomId, businessId });
        navigatorProvider().navigate(generateChatroomRoute(roomId));
      }}
    >
      <ConnectlyMessageIcon color={theme.palette.grey[400]} />
    </IconButton>
  );
};

const SentimentDisplay = ({ sentiment }: { sentiment: string }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics.sentimentAnalysis.sentiment' });
  const theme = useTheme();
  const label = useMemo(() => {
    let emoji = '';
    switch (sentiment) {
      case 'very positive':
      case 'positive':
      case 'negative':
      case 'mixed':
        emoji = SENTIMENT_EMOJIS[sentiment];
        break;
      default:
        break;
    }
    const emojiLabel = emoji ? (
      <Label mr={0.5} sx={{ fontSize: `${theme.spacing(2)} !important` }}>
        {emoji}
      </Label>
    ) : null;
    const textLabel = emoji ? (
      <Label variant="body1">{t(`${sentiment}.title`, SENTIMENT_LABELS[sentiment as Sentiment])}</Label>
    ) : (
      sentiment
    );
    return (
      <Stack direction="row" alignItems="center">
        {emojiLabel}
        {textLabel}
      </Stack>
    );
  }, [sentiment, t, theme]);
  return label;
};

const useDataTable = () => {
  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForInbox);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { currentData } = useTableDataInRanges({});
  const data = useMemo(() => {
    return selectAnalyticsTableData(currentData);
  }, [currentData]);
  const rows: ETableRowProps[] = useMemo(() => {
    if (!data) return [];
    return data.map((item) => {
      return {
        id: `${item.customerExternalId}-${item.createdAt}` || '',
        data: [
          {
            value: item.roomId,
            display: item.roomId ? (
              <ViewDetailsButton roomId={item.roomId} businessId={businessId} navigatorProvider={navigatorProvider} />
            ) : null,
          },
          {
            value: item.customerExternalId,
            display: formatPhoneNumber(item.customerExternalId, true),
          },
          {
            value: item.createdAt,
            display: formatDate(item.createdAt || ''),
          },
          {
            value: item.numTurns,
            display: item.numTurns,
            align: 'right',
          },
          {
            value: item.countMessages,
            display: item.countMessages,
            align: 'right',
          },
          {
            value: item.countAssistantMessages,
            display: item.countAssistantMessages,
            align: 'right',
          },
          {
            value: item.sentiment,
            display: <SentimentDisplay sentiment={item.sentiment} />,
            align: 'left',
          },
        ],
      };
    });
  }, [businessId, data, navigatorProvider]);

  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: '',
        label: '',
        width: '5%',
      },
      {
        id: 'customerExternalId',
        label: t('columns.customerExternalId'),
        width: '22%',
        sort: sortPhoneNumber,
      },
      {
        id: 'createdAt',
        label: t('columns.createdAt'),
        width: '21%',
        sort: sortDate,
      },
      {
        id: 'numTurns',
        label: t('columns.numTurns'),
        width: '12%',
        sort: sortNumber,
      },
      {
        id: 'countMessages',
        label: t('columns.countMessages'),
        tooltip: t('columns.countMessagesTooltip'),
        width: '12%',
        sort: sortNumber,
      },
      {
        id: 'countAssistantMessages',
        label: t('columns.countAssistantMessages'),
        tooltip: t('columns.countAssistantMessagesTooltip'),
        width: '12%',
        sort: sortNumber,
      },
      {
        id: 'sentiment',
        label: t('columns.sentiment'),
        width: '16%',
        sort: sortString,
        tooltip: t('columns.sentimentTooltip'),
      },
    ];
  }, [t]);
  const title = useMemo(() => {
    if (!data) return 'loading';
    return `${data.length} ${data.length === 1 ? t('conversation') : t('conversations')}`;
  }, [data, t]);
  const theme = useTheme();

  return {
    loading: !data,
    title,
    rows,
    tableHead,
    theme,
    navigatorProvider,
  };
};

export const DataTable = () => {
  const { title, tableHead, rows, loading, theme, navigatorProvider } = useDataTable();
  return (
    <EnhancedTable
      title={title}
      isLoading={loading}
      icon={<ChatIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />}
      rows={rows}
      headers={tableHead}
      sortDefault={{ by: 'createdAt', order: 'desc' }}
      actions={<ExportData />}
    />
  );
};
