import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import {
  Alert,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import {
  FlowChartSidebarBuildFlow,
  FlowChartSidebarChooseAudience,
  FlowChartSidebarRecipientDetails,
  FlowChartSidebarChooseTime,
  FlowChartSidebarType,
} from '..';
import {
  selectCampaignSendNode,
  selectFlowState,
  selectHasUnsavedChanges,
  selectEdges,
  selectNodes,
  selectSendoutId,
  selectStarter,
  FlowState,
} from '../../state/flow';
import { useCampaignDate, useRequestVerifyReactFlowDocument } from '../../hooks';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import { DebuggerDecorator } from '../DebuggerDecorator';
import appStore from '../../state/store';
import { useFlowChecks } from '../../hooks/useFlowChecks';

export type FlowStep = {
  step: number;
  label: string;
  status: FlowState | FlowState[];
};

export const FlowChartSidebar: FC = () => {
  const campaignSendNode = useSelector(selectCampaignSendNode);
  const edges = useSelector(selectEdges);
  const flowState = useSelector(selectFlowState);
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);
  const nodes = useSelector(selectNodes);
  const sendoutId = useSelector(selectSendoutId);
  const starter = useSelector(selectStarter);

  const { isLoading: isVerifyingCampaign } = useRequestVerifyReactFlowDocument();
  const { isIdle: isFlowChecksIdle } = useFlowChecks();

  const steps: FlowStep[] = [
    { step: 0, label: 'Build Campaign', status: ['buildCampaign', 'verifyCampaign', 'approveTemplates'] },
    { step: 1, label: 'Choose Audience', status: 'chooseAudience' },
    { step: 2, label: 'Finalize Recipient Details', status: 'recipientDetails' },
    { step: 3, label: 'Choose Time', status: ['chooseTime', 'conclusion'] },
  ];
  const goToNextState = useContextSelector(FlowCampaignContext, (context) => context?.goToNextState);
  const goToPreviousState = useContextSelector(FlowCampaignContext, (context) => context?.goToPreviousState);

  const { isCampaignDateValid } = useCampaignDate();

  const { isSuccess: isVerifySuccess } = useContextSelector(
    FlowCampaignContext,
    (context) => context.verifySendoutResultData,
  );

  const currentStepIndex = steps.findIndex((s) => s.status === flowState || s.status.includes(flowState));

  const [isFlowChartValid, nextButtonHint] = useMemo(() => {
    if (nodes.length <= 1) return [false, 'Need at least one message'];
    if (edges.length === 0) return [false, 'Missing connection from start node'];
    if (hasUnsavedChanges) return [false, 'Please wait while saving changes.'];
    if (isFlowChecksIdle) return [false, 'Loading templates, please wait.'];
    return [true, ''];
  }, [edges.length, hasUnsavedChanges, nodes.length, isFlowChecksIdle]);

  const nextButtonLabel = useMemo(() => {
    switch (flowState) {
      case 'verifyCampaign': {
        return isVerifyingCampaign ? 'Verifying...' : 'Next';
      }
      case 'chooseTime':
      case 'conclusion': {
        return 'Submit Campaign';
      }
      default: {
        return 'Next';
      }
    }
  }, [flowState, isVerifyingCampaign]);

  const isBackButtonDisabled = useMemo(
    () =>
      ['buildCampaign', 'verifyCampaign', 'approveTemplates'].includes(flowState) ||
      (starter === 'resend' && flowState === 'chooseAudience'),
    [flowState, starter],
  );

  const isNextButtonDisabled = useMemo(() => {
    if (hasUnsavedChanges) {
      return true;
    }
    switch (currentStepIndex) {
      case 0: {
        return !isFlowChartValid || isVerifyingCampaign;
      }
      case 1: {
        return !sendoutId || !campaignSendNode?.data?.v1?.campaignConfig?.name;
      }
      case 2: {
        return !isVerifySuccess;
      }
      case 3: {
        return !isCampaignDateValid;
      }
      default: {
        return false;
      }
    }
  }, [
    campaignSendNode?.data?.v1?.campaignConfig?.name,
    currentStepIndex,
    hasUnsavedChanges,
    isCampaignDateValid,
    isFlowChartValid,
    isVerifySuccess,
    isVerifyingCampaign,
    sendoutId,
  ]);

  let sidebarContent = null;
  switch (currentStepIndex) {
    case 1: {
      sidebarContent = <FlowChartSidebarChooseAudience />;
      break;
    }
    case 2: {
      sidebarContent = <FlowChartSidebarRecipientDetails />;
      break;
    }
    case 3: {
      sidebarContent = <FlowChartSidebarChooseTime />;
      break;
    }
    default: {
      sidebarContent = <FlowChartSidebarBuildFlow title="Build Campaign" type={FlowChartSidebarType.Campaign} />;
      break;
    }
  }

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        p: isLargeScreen ? 3 : 2,
        boxSizing: 'border-box',
        width: '100%',
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 1.5,
        overflow: 'hidden',
      }}
    >
      <Stepper
        sx={{ width: theme.spacing(25), marginBottom: isLargeScreen ? 1 : 0, marginLeft: -1 }}
        activeStep={steps[currentStepIndex]?.step}
      >
        {steps.map((step) => (
          <Step key={step.step}>
            <Tooltip placement="top" title={step.label}>
              <StepLabel
                sx={{
                  boxSizing: 'border-box',
                  '& .MuiStepLabel-iconContainer': {
                    padding: 0,
                  },
                }}
              />
            </Tooltip>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'scroll',
          mx: isLargeScreen ? -3 : -2,
        }}
      >
        <Box
          sx={{
            mx: isLargeScreen ? 3 : 2,
            my: isLargeScreen ? 3 : 1,
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: isLargeScreen ? 3 : 2,
          }}
        >
          <DebuggerDecorator
            data={{ ...appStore.getState().flow, ...appStore.getState().flowDocument }}
            shouldExpandNodeInitially={(keyPath: string[]) => keyPath.includes('changesQueue')}
          >
            {sidebarContent}
          </DebuggerDecorator>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 auto', mt: 1.5, gap: 1.5 }}>
        {nextButtonHint && <Alert severity="warning">{nextButtonHint}</Alert>}
        <Button variant="contained" onClick={goToNextState} disabled={isNextButtonDisabled}>
          {nextButtonLabel}
        </Button>
        <Button variant="outlined" color="secondary" onClick={goToPreviousState} disabled={isBackButtonDisabled}>
          Back
        </Button>
      </Box>
    </Box>
  );
};
