export const BroadcastRouteId = '/campaigns';
export const BroadcastCategoryRouteId = '/campaigns/:categoryId';
export const BroadcastCategoryNewRouteId = '/campaigns/:categoryId/new';
export const BroadcastCategoryEditRouteId = '/campaigns/:categoryId/edit/:id';
export const BroadcastCategoryViewRouteId = '/campaigns/:categoryId/view/:id';
export const BROADCAST_CATEGORY_WA_CAMPAIGN = 'whatsapp-broadcast';
export const BROADCAST_CATEGORY_SMS_CAMPAIGN = 'sms-campaign';
export const BROADCAST_CATEGORY_MESSAGE_TEMPLATES = 'message-templates';
export const BROADCAST_CATEGORY_AUDIENCES = 'audiences';
export function generateEditRoute(categoryId, id) {
    return {
        id: BroadcastCategoryEditRouteId,
        params: {
            categoryId,
            id,
        },
    };
}
export function generateViewRoute(categoryId, id) {
    return {
        id: BroadcastCategoryViewRouteId,
        params: {
            categoryId,
            id,
        },
    };
}
export function generateNewRoute(categoryId) {
    return {
        id: BroadcastCategoryNewRouteId,
        params: {
            categoryId,
        },
    };
}
export function generateBroadcastRoute(categoryId = '') {
    if (categoryId) {
        return {
            id: BroadcastCategoryRouteId,
            params: {
                categoryId,
            },
        };
    }
    return {
        id: BroadcastRouteId,
        params: {
            categoryId,
        },
    };
}
