import React from 'react';
import { IconButton, Box, Label, useTheme, CloseIcon, InsertDriveFileIcon } from '@connectlyai-tenets/ui-styled-web';
import { DataPillProps } from './types';

export const DataPill = ({ display, handleRemove, isDocument, disabled }: DataPillProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.grey[200],
        my: handleRemove ? 0.5 : 0,
        pl: 1,
        pr: handleRemove ? 0.5 : 1,
        borderRadius: 1,
        mr: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: theme.spacing(3.25),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {isDocument && <InsertDriveFileIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />}
        <Label
          variant="body2"
          sx={{
            ml: isDocument ? 0.25 : 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {display}
        </Label>
        {handleRemove && (
          <IconButton
            disabled={disabled}
            onClick={handleRemove}
            sx={{ color: theme.palette.common.black, p: 0.5, ml: 0.25 }}
          >
            <CloseIcon sx={{ width: theme.spacing(1.5), height: theme.spacing(1.5) }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
