import React, { useMemo, useCallback, useContext, useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useTranslation } from 'react-i18next';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useEffectOnce, useMeData } from '@hooks';
import { claimingPhoneNumberAtom, smbOnboardingStepAtom } from '@atoms/home';
import { track } from 'src/utils';
import { WhatsAppOnboardingProps, UseWhatsAppOnboardingProps } from './types';
import { NotificationContext } from '../../../../contexts';
import { BusinessName, BusinessProfilePicture, Connect, PhoneNumberOld, PhoneNumberSelector } from './Steps';
import { ApprovalDialog } from '../../../../components/ApprovalDialog';
import { useMutationProvisionPhoneNumber } from '../../hooks';

const useWhatsAppOnboarding = ({ onNextStep, whatsApp }: UseWhatsAppOnboardingProps) => {
  const [isClaiming, setIsClaiming] = useAtom(claimingPhoneNumberAtom);
  const step = useAtomValue(smbOnboardingStepAtom);
  const { data: businessId = '' } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  useEffect(() => {
    if (whatsApp?.isWhatsAppActive) {
      setConfirmationOpen(true);
    }
  }, [whatsApp?.isWhatsAppActive, setConfirmationOpen]);

  const handleClose = useCallback(() => {
    setConfirmationOpen(false);
    onNextStep();
  }, [onNextStep]);

  const { mutate: mutationProvisionPhoneNumber } = useMutationProvisionPhoneNumber({
    onError: () => {
      setIsClaiming(false);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error provisioning phone number.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: () => {
      setIsClaiming(false);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully provisioned phone number',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });
  const claimNumber = useCallback(() => {
    if (businessId) {
      setIsClaiming(true);
      mutationProvisionPhoneNumber({
        businessId,
        criteria: {
          isoCountry: 'US',
          areaCode: undefined,
        },
      });
    }
  }, [mutationProvisionPhoneNumber, setIsClaiming, businessId]);

  const claimedPhoneNumber = useMemo(() => whatsApp?.claimedNumber || '', [whatsApp?.claimedNumber]);
  const verificationCode = useMemo(() => whatsApp?.verificationCode || '', [whatsApp?.verificationCode]);
  const content: React.ReactNode | null = useMemo(() => {
    let result: React.ReactNode = null;
    switch (step) {
      case 'phoneNumberSelector': {
        result = <PhoneNumberSelector />;
        break;
      }
      case 'phoneNumberOld': {
        result = <PhoneNumberOld />;
        break;
      }
      case 'businessName': {
        result = <BusinessName claimNumber={claimNumber} claimedPhoneNumber={whatsApp?.claimedNumber} />;
        break;
      }
      case 'businessProfilePicture': {
        result = <BusinessProfilePicture />;
        break;
      }
      case 'connectWhatsApp': {
        result = (
          <Connect
            claimedPhoneNumber={whatsApp?.claimedNumber || ''}
            verificationCode={whatsApp?.verificationCode || ''}
            isClaiming={isClaiming}
            onClaimNumber={claimNumber}
          />
        );
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }, [claimNumber, isClaiming, step, whatsApp?.claimedNumber, whatsApp?.verificationCode]);

  return {
    claimedPhoneNumber,
    verificationCode,
    confirmationOpen,
    onClose: handleClose,
    content,
  };
};

export const WhatsAppOnboarding = ({ whatsApp, onNextStep }: WhatsAppOnboardingProps) => {
  const { confirmationOpen, onClose, content } = useWhatsAppOnboarding({ onNextStep, whatsApp });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding' });

  useEffectOnce(() => {
    track('(onboarding) load phone number selector', { businessId });
  });
  return (
    <Box sx={{ px: 3, pt: 4.5, pb: 3 }}>
      <ApprovalDialog open={confirmationOpen} title={t('approvalTitle')} onClose={onClose} />
      {content}
    </Box>
  );
};
