import { type UseQueryOptions } from '@tanstack/react-query';
import { type Paths } from '@connectlyai-tenets/sdk';

export const QUERY_CLIENT_KEYS = '/internal/v1/businesses/{input.businessId}/query/client_keys';

type QueryClientKeys = typeof QUERY_CLIENT_KEYS;

interface BusinessIdParam {
  businessId: string;
}

type QueryClientKeysResponse = Paths['aiV1'][QueryClientKeys]['get']['responses']['200']['schema'];
export type QueryClientKeysUrlParameters = Paths['aiV1'][QueryClientKeys]['get']['parameters']['path'];
export type QueryClientKeysOptions = BusinessIdParam &
  UseQueryOptions<QueryClientKeysResponse, unknown, QueryClientKeysResponse, readonly [string]>;
