import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { BusinessChannelOptions, BusinessChannelMutation, BusinessChannelResponse } from './types';

export const useBusinessChannelMutation = (options?: BusinessChannelOptions) => {
  return useMutation(
    ({ businessId, query, ...variables }: BusinessChannelMutation) =>
      api.profileV1('/v1/businesses/{entity.businessId}/channels', 'post', {
        path: { 'entity.businessId': businessId },
        query,
        body: {
          entity: {
            ...variables,
          },
        },
      }) as Promise<BusinessChannelResponse>,
    {
      ...options,
    },
  );
};
