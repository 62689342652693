import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { getCartAbandonmentLanguage } from '../../utils';
import { CART_ABANDONMENT_MESSAGE_LANGUAGES } from './constants';
import { AbandonedCartEditLanguageProps } from './types';

export const AbandonedCartEditLanguage = ({ language, setLanguage }: AbandonedCartEditLanguageProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart.editIntegration' });
  const theme = useTheme();

  const [newLanguage, setNewLanguage] = useState(language);
  return (
    <>
      <DialogContent
        sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2, py: 0 }}
      >
        <Box sx={{ display: 'flex', py: 2 }}>
          <TextField
            select
            value={newLanguage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLanguage(e.target.value)}
            label={t('language')}
            variant="outlined"
            sx={{ width: '100%' }}
          >
            {CART_ABANDONMENT_MESSAGE_LANGUAGES.map((acLanguage) => (
              <MenuItem key={acLanguage} value={acLanguage}>
                {getCartAbandonmentLanguage(acLanguage)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
        <Button
          variant="contained"
          onClick={() => {
            setLanguage(newLanguage);
          }}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};
