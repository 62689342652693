import React, { useCallback } from 'react';
import {
  Button,
  Box,
  ConnectlyDeleteIcon,
  Label,
  LargeDeviceIcon,
  SmallDeviceIcon,
  Stack,
  TextField,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import { usePartialAgentDescriptor } from './usePartialAgentDescriptor';

export const WidgetPromptAnimation = () => {
  const {
    value: widgetPrompts,
    setValue: setWidgetPrompts,
    onSave: saveWidgetPrompts,
  } = usePartialAgentDescriptor<{ desktop: string[]; mobile: string[] }>({
    keyPath: 'widgetPrompts',
    defaultValue: {
      desktop: ['What can we help you find?', 'Find the perfect gift', 'Ask our AI shopping expert'],
      mobile: ['Search with AI', 'Shop gifts', 'AI Expert'],
    },
  });

  const addWidgetPrompt = useCallback(() => {
    setWidgetPrompts({ ...widgetPrompts, desktop: [...widgetPrompts.desktop, ''] });
  }, [widgetPrompts, setWidgetPrompts]);

  const removeWidgetPrompt = useCallback(
    (index: number) => {
      setWidgetPrompts({ ...widgetPrompts, desktop: widgetPrompts.desktop.filter((_, i) => i !== index) });
    },
    [widgetPrompts, setWidgetPrompts],
  );

  const updateWidgetPrompt = useCallback(
    (index: number, value: string) => {
      setWidgetPrompts({
        ...widgetPrompts,
        desktop: widgetPrompts.desktop.map((widgetPrompt, i) => (i === index ? value : widgetPrompt)),
      });
    },
    [widgetPrompts, setWidgetPrompts],
  );

  const addMobileWidgetPrompt = useCallback(() => {
    setWidgetPrompts({ ...widgetPrompts, mobile: [...widgetPrompts.mobile, ''] });
  }, [widgetPrompts, setWidgetPrompts]);

  const removeMobileWidgetPrompt = useCallback(
    (index: number) => {
      setWidgetPrompts({ ...widgetPrompts, mobile: widgetPrompts.mobile.filter((_, i) => i !== index) });
    },
    [widgetPrompts, setWidgetPrompts],
  );

  const updateMobileWidgetPrompt = useCallback(
    (index: number, value: string) => {
      setWidgetPrompts({
        ...widgetPrompts,
        mobile: widgetPrompts.mobile.map((widgetPrompt, i) => (i === index ? value : widgetPrompt)),
      });
    },
    [widgetPrompts, setWidgetPrompts],
  );
  return (
    <SaveActionCard
      onSubmit={saveWidgetPrompts}
      title="Prompt text animation"
      subtitle="This text will be shown in rotation while the search bar is collapsed. Write examples of products that you want your customers to be searching for. Ex. Summer shoes, Minimal white sneakers, etc."
    >
      <Stack direction="row" spacing={2} mt={2}>
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={1}>
            <Label>
              <SmallDeviceIcon /> Smaller devices (most of your customers will see them)
            </Label>
            <Label>These prompts will appear at smaller devices where the space is limited, like mobile phones. </Label>
          </Stack>
          <Stack direction="column" spacing={3}>
            {widgetPrompts.mobile.map((mobileWidgetPrompt, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stack direction="row" spacing={2} key={index} alignItems="center">
                <TextField
                  value={mobileWidgetPrompt}
                  onChange={(e) => updateMobileWidgetPrompt(index, e.target.value)}
                  fullWidth
                  placeholder="Enter mobile widget prompt"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    mt: 1.5,
                    '& .MuiInputBase-root': { borderRadius: '10px' },
                    '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
                  }}
                />
                {widgetPrompts.mobile.length > 2 && (
                  <Box onClick={() => removeMobileWidgetPrompt(index)}>
                    <ConnectlyDeleteIcon />
                  </Box>
                )}
              </Stack>
            ))}
            <Stack direction="row" spacing={3} mt={1}>
              <Button variant="outlined" color="secondary" sx={{ mt: 1.5 }} onClick={addMobileWidgetPrompt}>
                Add new mobile prompt text
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={1}>
            <Label>
              <LargeDeviceIcon /> Larger devices
            </Label>
            <Label>
              These prompts will appear at larger devices where there is more space, like tablets and laptops.
            </Label>
          </Stack>
          <Stack direction="column" spacing={3}>
            {widgetPrompts.desktop.map((widgetPrompt, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stack direction="row" spacing={2} key={index} alignItems="center">
                <TextField
                  value={widgetPrompt}
                  onChange={(e) => updateWidgetPrompt(index, e.target.value)}
                  fullWidth
                  placeholder="Enter widget prompt"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    mt: 1.5,
                    '& .MuiInputBase-root': { borderRadius: '10px' },
                    '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
                  }}
                />
                {widgetPrompts.desktop.length > 2 && (
                  <Box onClick={() => removeWidgetPrompt(index)}>
                    <ConnectlyDeleteIcon />
                  </Box>
                )}
              </Stack>
            ))}
            <Stack direction="row" spacing={3} mt={1}>
              <Button variant="outlined" color="secondary" sx={{ mt: 1.5 }} onClick={addWidgetPrompt}>
                Add new prompt text
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </SaveActionCard>
  );
};
