import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { Box, Button, Label } from '@connectlyai-tenets/ui-styled-web';
import React, { useContext, useState } from 'react';
import { NotificationContext } from '../../contexts';
import { useInterval } from '../../hooks/useInterval';
import { useSignupEmailResend } from '../../hooks/useSignupEmailResend';
import { applyHook } from '../../utils';
import { DEFAULT_EMAIL_TIMER } from './constants';
import { SignupEmailActivationProps } from './types';

const useSignupEmailActivation = (): Partial<SignupEmailActivationProps> => {
  const { notificationProvider } = useContext(NotificationContext);
  const [countdown, setCountdown] = useState<number>(DEFAULT_EMAIL_TIMER);

  useInterval(
    () => {
      setCountdown(countdown - 1);
    },
    countdown ? 1000 : null,
  );

  const { mutate: onSignupEmailResend } = useSignupEmailResend({
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'E-mail has been sent',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to send email',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  const handleSendEmail = (signupToken: string) => {
    if (countdown === 0) {
      setCountdown(DEFAULT_EMAIL_TIMER);
      onSignupEmailResend({
        signupToken,
      });
    }
  };
  return {
    countdown,
    onResend: handleSendEmail,
  };
};
const SignupEmailActivationPresentation = ({ countdown, signupToken, onResend }: SignupEmailActivationProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        py: 3,
      }}
    >
      <Label variant="h3">🎉</Label>
      <Label variant="body1" sx={{ fontWeight: 500, lineHeight: '22px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
        {`We just sent you a login link.\nPlease check your email!`}
      </Label>
      <Button fullWidth variant="contained" onClick={() => onResend(signupToken)} disabled={countdown !== 0}>
        {countdown === 0 ? 'Resend Email' : `Resend Email (${countdown})`}
      </Button>
    </Box>
  );
};

export const SignupEmailActivation = applyHook(SignupEmailActivationPresentation, useSignupEmailActivation);
