import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomer } from './thunk';
const initialCustomerStatus = {
    status: 'loading',
    timestamp: 0,
    customer: undefined,
};
const initialState = {
    status: 'loading',
    customers: {},
};
export const customerSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomer.pending, (state, action) => {
            state.status = 'loading';
            const { customerId } = action.meta.arg;
            if (!state.customers[customerId]) {
                state.customers[customerId] = initialCustomerStatus;
            }
        });
        builder.addCase(fetchCustomer.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const { customerId } = action.meta.arg;
            const customerState = state.customers[customerId];
            customerState.status = 'succeeded';
            customerState.timestamp += 1;
            customerState.customer = action.payload;
        });
        builder.addCase(fetchCustomer.rejected, (state, action) => {
            state.status = 'failed';
            const { customerId } = action.meta.arg;
            const customerState = state.customers[customerId];
            customerState.status = 'failed';
        });
    },
});
export const { reset } = customerSlice.actions;
