import { ACMessageIcon, AIMessageIcon, Box, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React, { useMemo } from 'react';
import { WhatsAppMessageTemplatePreview } from '@components/WhatsAppMessageTemplatePreview';
import { useTranslation } from 'react-i18next';
import { SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { AbandonedCartMessagePreviewProps } from './types';

export const AbandonedCartMessagePreview = ({
  isDefaultMessage,
  isPersonalMessage,
  discount,
  language,
}: AbandonedCartMessagePreviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart' });

  const messageTitle = useMemo(
    () => (isPersonalMessage ? t('editIntegration.aiExample') : t('editIntegration.standardExample')),
    [isPersonalMessage, t],
  );

  return (
    <Box
      sx={{
        background: isDefaultMessage ? theme.palette.common.white : theme.palette.midnightIndigo,
        borderRadius: '10px',
        px: 1.5,
        paddingBottom: 1.5,
        display: 'flex',
        flexDirection: 'column',
        gap: SIZE_SPACING_INTER_COMPONENT,
        boxShadow: isDefaultMessage ? '0px 2px 6px 0px #0000002E' : 'none',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', p: 1.5 }}>
        {isDefaultMessage ? (
          <ACMessageIcon fill={theme.palette.common.black} width={32} height={32} />
        ) : (
          <AIMessageIcon fill={theme.palette.common.white} width={32} height={32} />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Label variant="subtitle1" sx={{ color: theme.palette.common.white }}>
            {messageTitle}
          </Label>
          {!isDefaultMessage && (
            <Label variant="caption" sx={{ color: theme.palette.common.white }}>
              {t('editIntegration.messageVaries')}
            </Label>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.secondary.light,
          borderRadius: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
          px: 3,
          height: '100%',
        }}
      >
        <WhatsAppMessageTemplatePreview
          discount={discount}
          language={language}
          isPersonalMessage={isPersonalMessage}
          img={`${process.env.PUBLIC_URL}/assets/template_sample_message_image.png`}
        />
      </Box>
    </Box>
  );
};
