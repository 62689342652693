import React, { FC, PropsWithChildren } from 'react';
import { useKeyPress } from 'react-flow-renderer';
import { Card } from '@connectlyai-tenets/ui-styled-web';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { JSONTree } from 'react-json-tree';
import { JSON_TREE_THEME } from './constants';

const isDebuggerEnabled = getFlagByName('ffEnableDebugFlow');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DebuggerDecorator: FC<PropsWithChildren<any>> = ({ children, ...props }) => {
  const debuggerPressed = useKeyPress(['Meta', 'Control']);

  return (
    <>
      {isDebuggerEnabled && debuggerPressed && (
        <Card
          sx={{
            ...props?.sx,
            whiteSpace: 'pre-wrap',
            p: 1,
          }}
        >
          <JSONTree data={props?.data} {...props} theme={JSON_TREE_THEME} hideRoot />
        </Card>
      )}
      {!(isDebuggerEnabled && debuggerPressed) && children}
    </>
  );
};
