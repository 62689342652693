/* eslint-disable react/no-unused-prop-types */
import React, { FC, useEffect } from 'react';
import {
  Chip,
  CircularProgress,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Label,
  List,
  ListItem,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { selectBusinessId, useMeData, useQueryListCampaignReports } from '../../hooks';
import {
  SIZE_CONTENT_L,
  SIZE_CONTENT_M,
  SIZE_CONTENT_XS,
  SIZE_MEDIA_XS,
  SIZE_SPACING_INTER_COMPONENT,
} from '../../ui-theme';

type AnalyticsRecentCampaignReportsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const useAnalyticsRecentCampaignReportsDialog = ({ open, onClose }: AnalyticsRecentCampaignReportsDialogProps) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const {
    data: campaignReportsData,
    isLoading,
    refetch,
  } = useQueryListCampaignReports(
    { businessId: businessId || '' },
    {
      enabled: !!businessId,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [refetch, open]);

  const campaignReports = React.useMemo(() => campaignReportsData?.entity?.items || [], [campaignReportsData]);

  return {
    open,
    campaignReports,
    isLoading,
    handleClose: onClose,
  };
};

const findStatusColor = (status: string) => {
  if (status === 'Succeeded') {
    return {
      text: 'Succeeded',
      styles: { backgroundColor: '#dffee0', color: '#4cae50' },
    };
  }

  return {
    text: 'Failed',
    styles: { backgroundColor: '#ffebeb', color: '#cf5656' },
  };
};

export const AnalyticsRecentCampaignReportsDialog: FC<AnalyticsRecentCampaignReportsDialogProps> = (props) => {
  const { open, campaignReports, isLoading, handleClose } = useAnalyticsRecentCampaignReportsDialog(props);
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const theme = useTheme();

  return (
    <Dialog open={open} maxWidth="xl" onClose={handleClose}>
      <DialogTitle>
        {t('pastReports', 'Past reports')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
            top: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: theme.spacing(SIZE_CONTENT_XS),
          maxHeight: theme.spacing(SIZE_CONTENT_M),
          width: theme.spacing(SIZE_CONTENT_L),
        }}
      >
        {isLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} />}
        {!isLoading && (
          <List>
            {campaignReports.map((val, i) => (
              <ListItem key={val.id} sx={{ justifyContent: 'space-between', px: 0, gap: 2 }}>
                <Label variant="body1" noWrap>
                  {val.name}
                </Label>
                <Chip
                  label={findStatusColor(val.userStatus || '').text}
                  sx={{ minWidth: '90px', ...findStatusColor(val.userStatus || '').styles }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};
