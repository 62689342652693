import { useCallback, useMemo } from 'react';
import { selectBusinessId, useMeData } from '@hooks';
import { useListCampaignsData } from '../useListCampaignsData';

export const useGenerateCampaignName = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: campaigns, isLoading: isLoadingCampaigns } = useListCampaignsData({
    businessId: businessId || '',
    entity: { filterDeleted: { option: 'DELETED_OPTION_INCLUDE' } },
    enabled: !!businessId,
  });

  const existingCampaignNames = useMemo(
    () =>
      campaigns?.entity?.campaigns?.reduce<string[]>(
        (acc, campaign) => (campaign.name ? [...acc, campaign.name] : acc),
        [],
      ) || [],
    [campaigns],
  );

  const generateCampaignName = useCallback(
    (startsWith: string = 'Campaign') => {
      let rootString = startsWith;
      const matchCopyRegex = startsWith.match(/(.+?)\bcopy\b(.*)/i);
      if (matchCopyRegex) rootString = `${matchCopyRegex[1]}copy`;

      const rootStringRegex = new RegExp(`^${rootString} (\\d+)$`, 'i');
      const lastCount = existingCampaignNames.reduce((acc, name) => {
        const match = name.match(rootStringRegex);
        if (!match) return acc;
        const number = BigInt(match[1]);
        if (Number.isNaN(number) || number < BigInt(acc)) return acc;
        return match[1];
      }, '0');

      return `${rootString} ${(BigInt(lastCount) + BigInt(1)).toString()}`;
    },
    [existingCampaignNames],
  );

  const isCampaignNameTaken = useCallback(
    (campaignName: string) =>
      existingCampaignNames.some((name) => name.localeCompare(campaignName, undefined, { sensitivity: 'base' }) === 0),
    [existingCampaignNames],
  );

  return {
    existingCampaignNames,
    generateCampaignName,
    isCampaignNameTaken,
    isLoadingCampaigns,
  };
};
