import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Slide, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartSidebarTemplateBuilder } from '../FlowChartSidebarTemplateBuilder';
import { selectEditedNodeId } from '../../state/messageTemplates';
import type { NodeType } from '../../sdks/flow/createNode';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { selectNodes } from '../../state/flow';
import { DebuggerDecorator } from '../DebuggerDecorator';
import appStore from '../../state/store';

export const FlowChartNodeSidebar: FC = () => {
  const nodeId = useSelector(selectEditedNodeId);
  const nodes = useSelector(selectNodes);
  const node = nodes.find((n) => n.id === nodeId);

  const theme = useTheme();

  const { onNodeObjectChange, resetSelection } = useCampaignFlowChangeAppliers();

  let sidebar = null;
  switch (node?.type as NodeType) {
    case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
      sidebar = <FlowChartSidebarTemplateBuilder onNodeObjectChange={onNodeObjectChange} />;
      break;
    }
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
      sidebar = <FlowChartSidebarTemplateBuilder onNodeObjectChange={onNodeObjectChange} />;
      break;
    }
    default: {
      sidebar = (
        <Box sx={{ m: 3 }}>
          <Alert title="Error" severity="error" onClose={resetSelection}>
            You can&apos;t edit this node
          </Alert>
        </Box>
      );
      break;
    }
  }

  return (
    <Slide
      in={Boolean(nodeId)}
      direction="right"
      timeout={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: theme.palette.background.paper,
          willChange: 'transform',
        }}
      >
        <DebuggerDecorator data={appStore.getState().messageTemplates} shouldExpandNodeInitially={() => true}>
          {sidebar}
        </DebuggerDecorator>
      </Box>
    </Slide>
  );
};
