import React, { DragEventHandler, MouseEventHandler, useMemo } from 'react';
import { Box, Label, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useAtomValue } from 'jotai';
import { reactFlowInstanceAtom } from '@atoms/flow';
import { useSofiaEnabled } from '@scenes/Sofia/hooks';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@hooks';
import { FeatureFlagState } from '@connectlyai-tenets/feature-flag';
import { FlowChartSidebarNodeHint } from '../../../FlowChartSidebarNodeHint';
import { NodeToolbarItem } from './NodeToolbarItem';
import { useFlowHandlers } from '../../hooks/useFlowHandlers';
import { NodeType } from '../../../../sdks/flow/createNode';
import { useCampaignFlowChangeAppliers } from '../../hooks/useCampaignFlowChangeAppliers';
import { NODE_TOOLBAR_NODES } from './constants';

type NodeItem = (typeof NODE_TOOLBAR_NODES)[number];

export const NodeToolbar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'nodeToolbar' });
  const { handleNodeCreate } = useFlowHandlers({ flowChangeAppliers: useCampaignFlowChangeAppliers });
  const reactFlowInstance = useAtomValue(reactFlowInstanceAtom);
  const ffEnableAINode = useSofiaEnabled();
  const allFeatureFlags = useFeatureFlag('*');
  const features = useMemo(
    () => ({ ...allFeatureFlags, ffEnableAINode }) as FeatureFlagState & { ffEnableAINode: boolean },
    [allFeatureFlags, ffEnableAINode],
  );

  const createHandleDragStart =
    (type: string): DragEventHandler<HTMLDivElement> =>
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
    };

  const createHandleNodeClick =
    (type: string): MouseEventHandler<HTMLDivElement> =>
    (_event) => {
      handleNodeCreate(type as NodeType, reactFlowInstance || undefined, null);
    };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const groups = useMemo(
    () =>
      NODE_TOOLBAR_NODES.reduce((acc, node) => {
        if ('featureFlag' in node && features[node.featureFlag] === false) {
          return acc;
        }
        const group: Array<NodeItem> = acc.get(node.group) || [];
        acc.set(node.group, [...group, node]);
        return acc;
      }, new Map<NodeItem['group'], Array<NodeItem>>()),
    [features],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: isLargeScreen ? 3 : 2,
      }}
    >
      <Label variant="h6">Build Campaign</Label>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <>
          <FlowChartSidebarNodeHint />
          {Array.from(groups.entries()).map(([group, nodes]) => (
            <>
              <Label variant="h7" color={theme.palette.grey[500]}>
                {t(group)}
              </Label>
              {nodes.map((node) => (
                <NodeToolbarItem
                  {...node}
                  hintLearnMore={'hintLearnMore' in node ? node.hintLearnMore : ''}
                  hint={t(node.hint)}
                  title={t(node.title)}
                  key={node.nodeType}
                  onDragStart={createHandleDragStart(node.nodeType)}
                  onClick={createHandleNodeClick(node.nodeType)}
                />
              ))}
            </>
          ))}
        </>
      </Box>
    </Box>
  );
};
