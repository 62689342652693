import React from 'react';
import { useTheme } from '@connectlyai-tenets/ui-styled-web';

export const HandleLine = ({ width }: { width: number }) => {
  const theme = useTheme();
  return (
    <svg
      width={`${width}`}
      height="3"
      viewBox={`0 0 ${width} 3`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="crispEdges"
    >
      <path d={`M0 1 H${width}`} stroke={theme.palette.connectionLine} strokeWidth="2" strokeLinecap="butt" />
    </svg>
  );
};
