import { InitializeSpreadsheetResponse, SpreadsheetUrl, SpreadsheetFrquencyCapOption } from './types';

export const selectSpreadsheetFrequencyCapOption = (
  data: InitializeSpreadsheetResponse,
): SpreadsheetFrquencyCapOption => {
  const frequencyCapOption = data?.entity?.configV3?.v3?.execution?.v1?.frequencyCapOption;
  return frequencyCapOption;
};

export const selectSpreadsheetURL = (data: InitializeSpreadsheetResponse): SpreadsheetUrl => {
  const url = data?.entity?.configV3?.v3?.cohort?.v1?.spreadsheetV1?.spreadsheetUrl;
  return url;
};
