import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { generateSofiaRoute, generateSofiaWebsearchRoute } from '@connectlyai-sdks/inbox-navigation';
import { NavigationContext } from '../../../../contexts';

export const useNavigator = () => {
  const homeRoute = generateSofiaRoute();
  const websearchRoute = generateSofiaWebsearchRoute();

  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForActivitybar);
  const routes = useMemo(() => {
    return {
      home: {
        pathname: navigatorProvider().generateGlobalPath(homeRoute),
        navigate: () => navigatorProvider().navigate(homeRoute),
      },
      websearch: {
        pathname: navigatorProvider().generateGlobalPath(websearchRoute),
        navigate: () => navigatorProvider().navigate(websearchRoute),
      },
    };
  }, [homeRoute, navigatorProvider, websearchRoute]);
  return { routes };
};
