export enum CSATOption {
  LOVING = 'LOVING',
  OK = 'OK',
  MEH = 'MEH',
  NOTENJOYING = 'NOTENJOYING',
}

export const CSATOptions = {
  [CSATOption.LOVING]: {
    emoji: '😍' as const,
    tooltip: 'lov-tooltip' as const,
    dialogPrefix: 'csat.dialog.pos' as const,
  },
  [CSATOption.OK]: {
    emoji: '😐' as const,
    tooltip: 'ok-tooltip' as const,
    dialogPrefix: 'csat.dialog.pos' as const,
  },
  [CSATOption.MEH]: {
    emoji: '😒' as const,
    tooltip: 'meh-tooltip' as const,
    dialogPrefix: 'csat.dialog.neg' as const,
  },
  [CSATOption.NOTENJOYING]: {
    emoji: '😢' as const,
    tooltip: 'not-tooltip' as const,
    dialogPrefix: 'csat.dialog.neg' as const,
  },
};

export type CSATState = {
  closeUTCTimestamp?: string;
  submitUTCTimestamp?: string;
};
