import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
import { tryFetchBusinessChannelMetadata } from './thunk';
const initialState = {
    businessChannels: [],
    businessChannelMetadata: {},
    status: 'empty',
};
export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        reset: () => initialState,
        setBusinessChannels: (state, action) => {
            state.businessChannels = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(tryFetchBusinessChannelMetadata.pending, (state, _action) => {
            state.status = 'loading';
        });
        builder.addCase(tryFetchBusinessChannelMetadata.fulfilled, (state, action) => {
            state.status = 'success';
            const { businessChannelId } = action.meta.arg;
            state.businessChannels.forEach((ch) => {
                if (ch.id === businessChannelId) {
                    state.businessChannelMetadata[businessChannelId] = action.payload;
                }
            });
        });
        builder.addCase(tryFetchBusinessChannelMetadata.rejected, (state, _action) => {
            state.status = 'failure';
        });
    },
});
export const { setBusinessChannels, reset } = businessSlice.actions;
export const selectBusinessChannels = (state) => state.businessSlice.businessChannels;
export const selectBusinessChannelMetadataMap = (state) => state.businessSlice.businessChannelMetadata;
export default businessSlice.reducer;
