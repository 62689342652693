export const mutationUpdateMessageReadWatermarkProvider = (log, networkClient) => async (input) => {
    const url = `/v1/businesses/${input.businessId}/rooms/${input.roomId}/events/${input.eventId}`;
    log.debug('mutationUpdateMessageReadWatermark@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.put(url, {
            userId: input.userId,
        });
        log.debug('mutationUpdateMessageReadWatermark@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.error('mutationUpdateMessageReadWatermark@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
