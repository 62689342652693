import { useCallback, useContext } from 'react';
import { useAtom } from 'jotai';
import { whatsAppCloudNumbersAtom } from '@atoms/settings';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { ChannelType } from '@connectlyai-idl/models/dist/models/common/channel/models';
import { useMutationUpdateWAToken, MutationUpdateWATokenParameters } from '../useMutationUpdateWAToken';
import { NotificationContext } from '../../contexts';

export const useUpdateWAToken = () => {
  const { notificationProvider } = useContext(NotificationContext);
  const { isLoading, mutate } = useMutationUpdateWAToken();
  const [whatsAppCloudNumbers, setWhatsAppCloudNumbers] = useAtom(whatsAppCloudNumbersAtom);

  const update = useCallback(
    (params: MutationUpdateWATokenParameters) => {
      mutate(params, {
        onSuccess(data) {
          const s = [...whatsAppCloudNumbers];
          data.channels.forEach((channel) => {
            if (channel.channelType !== ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD) {
              return;
            }
            const { remoteProfile } = channel;
            if (!remoteProfile) {
              return;
            }
            const { profileOneof } = remoteProfile;
            if (profileOneof && profileOneof.$case === 'wabaPhoneNumber' && profileOneof.wabaPhoneNumber) {
              const n = profileOneof.wabaPhoneNumber.phoneNumber;
              if (!s.includes(n)) {
                s.push(n);
              }
            }
          });
          setWhatsAppCloudNumbers(s);
        },
        onError: () => {
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: 'Error connecting WhatsApp',
              severity: NotificationSeverity.ERROR,
              icon: '',
            },
          });
        },
      });
    },
    [mutate, notificationProvider, setWhatsAppCloudNumbers, whatsAppCloudNumbers],
  );

  return {
    isLoading,
    update,
  };
};
