import { RateLimitQueryResponse } from './types';
import { DAY_IN_SECONDS } from '../useMutationResourceLimit/constants';
import { ResourceLimitDisplay } from '../../scenes/Settings/pages/Campaigns/types';

export const selectResourceLimit = (data: RateLimitQueryResponse): number | undefined => {
  return data?.entity?.limit;
};

export const selectResourceDuration = (data: RateLimitQueryResponse): number | undefined => {
  return data?.entity?.duration;
};

export const selectResourceLimitDurationInDays = (data: RateLimitQueryResponse): number | undefined => {
  const duration = selectResourceDuration(data);
  if (duration) {
    return Math.floor(duration / DAY_IN_SECONDS);
  }
  return undefined;
};

export const selectResourceLimitView = (data: RateLimitQueryResponse): ResourceLimitDisplay => {
  const durationInDays = selectResourceLimitDurationInDays(data);
  const limit = selectResourceLimit(data);
  return {
    enabled: Boolean(durationInDays && limit === 1),
    duration: durationInDays,
  };
};
