import { useState, useCallback, useMemo } from 'react';
import { UseSelectFunctionalityProps, SelectFunctionality } from './types';

export const useSelectFunctionality = ({
  selectSupport,
  selectableRowIds,
}: UseSelectFunctionalityProps): SelectFunctionality => {
  const [selected, setSelected] = useState<string[]>([]);
  const [selecting, setSelecting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSelectRow = useCallback(
    (id: string) => {
      if (!selectSupport) {
        return;
      }
      if (selected.includes(id)) {
        const newSelected = selected.filter((x) => x !== id);
        setSelected(newSelected);
        if (selectSupport.setSelected) {
          selectSupport.setSelected(newSelected);
        }
      } else {
        const newSelected = [...selected, id];
        setSelected(newSelected);
        if (selectSupport.setSelected) {
          selectSupport.setSelected(newSelected);
        }
      }
    },
    [selectSupport, selected],
  );
  const onSelectAll = useCallback(
    (selectAll: boolean) => {
      if (!selectSupport) {
        return;
      }
      if (selectAll) {
        setSelected(selectableRowIds);
        if (selectSupport.setSelected) {
          selectSupport.setSelected(selectableRowIds);
        }
      } else {
        setSelected([]);
        if (selectSupport.setSelected) {
          selectSupport.setSelected([]);
        }
      }
    },
    [selectableRowIds, selectSupport],
  );
  const resetSelected = useCallback(() => {
    if (!selectSupport) {
      return;
    }
    setSelected([]);
    if (selectSupport.setSelected) {
      selectSupport.setSelected([]);
    }
    setSelecting(false);
  }, [selectSupport]);
  const confirmAction = useCallback(async () => {
    if (!selectSupport || !selectSupport.handleAction) {
      return;
    }
    setLoading(true);
    await selectSupport.handleAction(selected);
    setShowDeleteModal(false);
    resetSelected();
    setLoading(false);
  }, [selectSupport, resetSelected, selected]);
  const modal = useMemo(() => {
    if (!selectSupport || !selectSupport.confirmModal) {
      return null;
    }
    const { title, subtitle } = selectSupport.confirmModal;
    return {
      open: showDeleteModal,
      setOpen: (open: boolean) => setShowDeleteModal(open),
      title: typeof title === 'string' ? title : title(selected.length),
      subtitle: typeof subtitle === 'string' ? subtitle : subtitle(selected.length),
      onConfirm: confirmAction,
    };
  }, [confirmAction, selectSupport, selected.length, showDeleteModal]);
  const cancelEdit = useCallback(() => {
    if (!selectSupport) {
      return;
    }
    setSelected([]);
    if (selectSupport.setSelected) {
      selectSupport.setSelected([]);
    }
    setSelecting(false);
  }, [selectSupport]);
  const actionButtonText = useMemo(() => {
    if (!selectSupport || !selectSupport.controls) {
      return '';
    }
    return selectSupport.controls.actionText(selected.length, selecting);
  }, [selecting, selectSupport, selected.length]);
  const isSelecting = useMemo(() => {
    if (!selectSupport) {
      return selecting;
    }
    if (selectSupport.overrideControls) {
      return selectSupport.overrideControls.selecting;
    }
    return selecting;
  }, [selecting, selectSupport]);
  if (!selectSupport) {
    return null;
  }
  return {
    onSelectRow,
    onSelectAll,
    modal,
    cancelEdit,
    actionButtonText,
    selected,
    selecting: isSelecting,
    setSelecting,
    showControls: !!selectSupport.overrideControls,
    loading,
  };
};
