import React, { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Label, Box, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import {
  sortString,
  sortNumber,
  UserCell,
  EnhancedTable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
} from '@components/EnhancedTable';
import { msToTimeFormat, selectAgents } from '@scenes/Inbox/utils';
import { inboxAnalyticsTimeRangesAtom as timeRangesAtom } from '@atoms/inbox';
import { useQueryAgents, UserMetrics } from '@hooks/useQueryAgents';
import { selectBusinessId, useMeData, useAgentsData } from '../../../../hooks';

const useAgentsPerformance = () => {
  const timeRange = useAtomValue(timeRangesAtom)[0];
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: users } = useAgentsData({ businessId: businessId || '', select: selectAgents });
  const { data: metrics } = useQueryAgents(
    { 'query.businessId': businessId || '' },
    {
      timeRangeStart: timeRange.timeRangeStart,
      timeRangeEnd: timeRange.timeRangeEnd,
    },
    { enabled: !!businessId },
  );

  const rows: ETableRowProps[] = useMemo(() => {
    if (!users) return [];
    const usersMetrics: UserMetrics[] = metrics?.entity?.items || [];
    return users.map((user) => {
      const match = usersMetrics.find((userMetric: UserMetrics) => userMetric.username === user.email);
      const name = user.name || '';
      const email = user.email || '';
      const initials = user.initials || '';
      const avgResolutionTime = match ? Number(match?.avgResolutionTime) : undefined;
      const conversations = match ? Number(match?.conversations) : undefined;
      return {
        id: name,
        data: [
          {
            value: name,
            display: <UserCell name={name} initials={initials} email={email} />,
          },
          {
            value: avgResolutionTime,
            display: avgResolutionTime === undefined ? '—' : msToTimeFormat(avgResolutionTime),
          },
          {
            value: conversations,
            display: conversations === undefined ? '—' : String(conversations),
          },
        ],
      };
    });
  }, [metrics?.entity?.items, users]);

  const { t } = useTranslation('translation', { keyPrefix: 'analytics.inbox' });
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        label: t('name'),
        width: '50%',
        sort: sortString,
      },
      {
        id: 'avgResolutionTime',
        label: t('avgResolutionTime.columnLabel'),
        width: '25%',
        sort: sortNumber,
      },
      {
        id: 'conversations',
        label: t('conversations.columnLabel'),
        width: '25%',
        sort: sortNumber,
      },
    ];
  }, [t]);

  return {
    rows,
    tableHead,
  };
};

export const AgentsPerformance = () => {
  const { tableHead, rows } = useAgentsPerformance();
  return (
    <ConnectlyCard>
      <EnhancedTable
        title="Agent Performance"
        rows={rows}
        headers={tableHead}
        sortDefault={{ by: 'conversations', order: 'desc' }}
      />
    </ConnectlyCard>
  );
};
