import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { SofiaAnalyticsParameters, SofiaAnalyticsResponse, SOFIA_ANALYTICS_URL } from './types';

export const useTableAnalyticsData = <TData = SofiaAnalyticsResponse>({
  businessId,
  query,
  ...options
}: Omit<
  UseQueryOptions<
    SofiaAnalyticsResponse,
    unknown,
    TData,
    readonly [target: 'sofia-analytics', businessId: string, timeRangeStart: string, timeRangeEnd: string]
  >,
  'initialData' | 'queryFn' | 'queryKey'
> &
  SofiaAnalyticsParameters) => {
  return useQuery(
    ['sofia-analytics', businessId, query.timeRangeStart || '', query.timeRangeEnd || ''],
    () =>
      api.analyticsV1(SOFIA_ANALYTICS_URL, 'post', {
        path: { 'query.businessId': businessId },
        body: { query },
      }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
