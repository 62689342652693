import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Collapse,
  DeleteOutlineIcon,
  IconButton,
  InputAdornment,
  Label,
  ListIcon,
  TextField,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { ListTemplateComponent } from '../../hooks/useMessageTemplateGroupsData/types';
import {
  selectListButton,
  selectListButtonError,
  selectListError,
  selectListSimpleRows,
  selectList,
  setList,
} from '../../state/messageTemplates';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { TemplateBuilderTitle } from '../TemplateBuilderTitle';
import { LIST_BUTTON_MAX_LENGTH, ROW_TEXT_MAX_LENGTH } from './constants';
import { FOCUS_DELAY_MS, scrollToBottomAndSelectHTMLInputElement } from '../../utils';

const useTemplateBuilderList = () => {
  const dispatch = useDispatch();
  const listButton = useSelector(selectListButton, shallowEqual);
  const list = useSelector(selectList, shallowEqual);
  const listButtonError = useSelector(selectListButtonError);
  const listError = useSelector(selectListError);
  const simpleRows = useSelector(selectListSimpleRows, shallowEqual);

  const handleListButtonChange = (value: string) => {
    const newList: ListTemplateComponent = { sections: list?.sections, button: value };
    dispatch(setList({ list: newList }));
  };

  const handleAddButton = useCallback(() => {
    let newList = list;
    const newRow = { text: '', id: uuidv4(), description: '' };
    if (!newList?.sections || newList?.sections?.length === 0 || !newList.sections[0].rows) {
      newList = {
        ...list,
        sections: [
          {
            title: '',
            rows: [newRow],
          },
        ],
      };
    } else {
      newList = {
        ...list,
        sections: newList?.sections.map((section, id) =>
          id === 0
            ? {
                ...section,
                rows: [...(section?.rows || []), newRow],
              }
            : section,
        ),
      };
    }

    dispatch(setList({ list: newList }));

    const rowIndexToScroll = (newList.sections?.[0]?.rows?.length || 0) - 1;
    if (rowIndexToScroll < 0) return;

    setTimeout(() => {
      scrollToBottomAndSelectHTMLInputElement(
        `message-template-content`,
        `message-template-list-row-${rowIndexToScroll}-text`,
      );
    }, FOCUS_DELAY_MS);
  }, [list, dispatch]);

  const handleChangeRow = useCallback(
    (value: string, id: string | undefined) => {
      if (!id) return;
      const newList = {
        ...list,
        sections: list?.sections?.map((section) => {
          return {
            ...section,
            rows: section.rows?.map((row) => {
              if (row.id === id) {
                return { ...row, text: value };
              }
              return row;
            }),
          };
        }),
      };

      dispatch(setList({ list: newList }));
    },
    [list, dispatch],
  );

  const handleDeleteButton = useCallback(
    (index: number) => {
      const newList = {
        ...list,
        sections: list?.sections?.map((section) => {
          return {
            ...section,
            rows: section.rows?.filter((row, rowIndex) => rowIndex !== index),
          };
        }),
      };

      dispatch(setList({ list: newList }));
    },
    [list, dispatch],
  );

  return {
    simpleRows,
    listButton,
    listButtonError,
    listError,
    handleAddButton,
    handleDeleteButton,
    handleListButtonChange,
    handleChangeRow,
  };
};

export const TemplateBuilderList: FC = () => {
  const {
    simpleRows,
    listButton,
    listButtonError,
    listError,
    handleAddButton,
    handleDeleteButton,
    handleListButtonChange,
    handleChangeRow,
  } = useTemplateBuilderList();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <TemplateBuilderItem>
      <TemplateBuilderTitle icon={<ListIcon color="action" />}>List</TemplateBuilderTitle>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: isLargeScreen ? 2 : 1, mt: isLargeScreen ? 2 : 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium' }}>
            List button
          </Label>
          <TextField
            fullWidth
            placeholder="Enter text"
            error={Boolean(listButtonError)}
            helperText={listButtonError}
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            value={listButton}
            inputProps={{ maxLength: LIST_BUTTON_MAX_LENGTH }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{`${listButton.length}/${LIST_BUTTON_MAX_LENGTH}`}</InputAdornment>
              ),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleListButtonChange(e.target.value)}
            sx={{
              '& .MuiInputBase-root': { borderRadius: '10px' },
              '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
            }}
          />
        </Box>
        {simpleRows?.map((row, index) => (
          <Box key={row.id} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: isLargeScreen ? 2 : 1,
              }}
            >
              <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium' }}>
                Row {index + 1}
              </Label>
              {simpleRows.length > 1 && (
                <IconButton size="small" onClick={() => handleDeleteButton(index)} sx={{ m: '-5px 0' }}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </Box>
            <TextField
              id={`message-template-list-row-${index}-text`}
              fullWidth
              placeholder="Enter text"
              error={Boolean(listError?.[index])}
              helperText={listError?.[index]}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              value={row.text}
              inputProps={{ maxLength: ROW_TEXT_MAX_LENGTH }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{`${row.text?.length}/${ROW_TEXT_MAX_LENGTH}`}</InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRow(e.target.value, row.id)}
              sx={{
                '& .MuiInputBase-root': { borderRadius: '10px' },
                '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
              }}
              key={row.id}
            />
          </Box>
        ))}

        <Collapse in={simpleRows === undefined || simpleRows?.length < 10}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleAddButton}
            sx={{ my: isLargeScreen ? 2 : 1, py: 0.5, px: 1, alignSelf: 'flex-start' }}
          >
            Add Row
          </Button>
        </Collapse>
      </Box>
    </TemplateBuilderItem>
  );
};
