function* getDynamicQueryBehaviors(query) {
    if (query) {
        const { clause, or, and } = query;
        if (clause) {
            yield clause;
        }
        if (or) {
            for (let i = 0; i < or.length; i += 1) {
                yield* getDynamicQueryBehaviors(or[i]);
            }
        }
        if (and) {
            for (let i = 0; i < or.length; i += 1) {
                yield* getDynamicQueryBehaviors(and[i]);
            }
        }
    }
}
function getQueryBehaviors(query) {
    if (!query)
        return [];
    const { queryOneof } = query;
    if (queryOneof) {
        switch (queryOneof.$case) {
            case 'dynamic': {
                return [...getDynamicQueryBehaviors(queryOneof.dynamic)];
            }
            default: {
                return [];
            }
        }
    }
    return [];
}
function getBehaviorsContext(behaviors) {
    for (let i = 0; i < behaviors.length; i += 1) {
        const behavior = behaviors[i];
        const { clauseOneof } = behavior;
        if (clauseOneof) {
            switch (clauseOneof.$case) {
                case 'sendoutButtonClick': {
                    const { sendoutButtonClick } = clauseOneof;
                    if (sendoutButtonClick && sendoutButtonClick.context) {
                        return sendoutButtonClick.context;
                    }
                    break;
                }
                case 'sendoutParticipant': {
                    const { sendoutParticipant } = clauseOneof;
                    if (sendoutParticipant && sendoutParticipant.sendout) {
                        return sendoutParticipant.sendout;
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }
    return undefined;
}
export function getAudienceParams(audience, sendouts) {
    const { name, size, query } = audience;
    let behaviors = getQueryBehaviors(query);
    const context = getBehaviorsContext(behaviors);
    behaviors = behaviors.filter((x) => { var _a; return ((_a = x.clauseOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'sendoutButtonClick'; });
    let campaignId = (context === null || context === void 0 ? void 0 : context.campaigns[0]) || '';
    if (campaignId === '') {
        const sendout = sendouts[(context === null || context === void 0 ? void 0 : context.sendouts[0]) || ''];
        if (sendout) {
            campaignId = sendout.campaignId;
        }
    }
    const sendoutIds = (context === null || context === void 0 ? void 0 : context.sendouts) || [];
    return {
        campaignId,
        sendoutIds,
        name,
        size,
        behaviors,
    };
}
function buildButtonClickDynamicQuery(campaignId, sendoutIds, behavior) {
    let payload = '';
    let text = '';
    const { clauseOneof } = behavior;
    if (clauseOneof && clauseOneof.$case === 'sendoutButtonClick') {
        text = clauseOneof.sendoutButtonClick.text;
        payload = clauseOneof.sendoutButtonClick.payload;
    }
    return {
        and: [],
        or: [],
        clause: {
            clauseOneof: {
                $case: 'sendoutButtonClick',
                sendoutButtonClick: {
                    payload,
                    text,
                    context: {
                        campaigns: [campaignId],
                        sendouts: sendoutIds || [],
                    },
                },
            },
        },
    };
}
export function buildAudienceQuery(campaignId, sendoutIds, behaviors) {
    const buttonClickBehaviors = behaviors.filter((x) => { var _a; return ((_a = x.clauseOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'sendoutButtonClick'; });
    if (buttonClickBehaviors.length > 1) {
        return {
            queryOneof: {
                $case: 'dynamic',
                dynamic: {
                    and: [],
                    or: buttonClickBehaviors.map((x) => buildButtonClickDynamicQuery(campaignId, sendoutIds, x)),
                    clause: undefined,
                },
            },
        };
    }
    if (buttonClickBehaviors.length === 1) {
        return {
            queryOneof: {
                $case: 'dynamic',
                dynamic: buildButtonClickDynamicQuery(campaignId, sendoutIds, buttonClickBehaviors[0]),
            },
        };
    }
    return {
        queryOneof: {
            $case: 'dynamic',
            dynamic: {
                and: [],
                or: [],
                clause: {
                    clauseOneof: {
                        $case: 'sendoutParticipant',
                        sendoutParticipant: {
                            sendout: {
                                campaigns: [],
                                sendouts: sendoutIds,
                            },
                        },
                    },
                },
            },
        },
    };
}
