import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { BroadcastDIContext } from '../../context';
import { useFeatureSelector, useFeatureDispatch } from '../../state/hooks';
import { selectBusinessChannels, setBusinessChannels } from '../../state/businessSlice';
import { ChannelType } from '../../entities';
import { selectMessageTemplateAttachment } from '../../state/messageTemplatesSlice';
function mapAttachmentStatusToViewState(state) {
    if (state.state === 'uploading') {
        return 'processing';
    }
    if (state.state === 'completed') {
        return 'success';
    }
    if (state.state === 'error') {
        return 'failure';
    }
    return assertUnreachable(state.state);
}
function mapAttachmentStateToViewModel(state) {
    return {
        localId: state.localId,
        localFileUrl: state.localUrl,
        remoteUrl: state.remoteUrl,
        state: mapAttachmentStatusToViewState(state),
        name: state.name,
    };
}
export const BroadcastMessageTemplatesNewComponentId = '7f92e022-8b12-11ec-a8a3-0242ac120002';
const BroadcastMessageTemplatesNew = (_props) => {
    const di = useContext(BroadcastDIContext);
    const dispatch = useFeatureDispatch();
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    useEffect(() => {
        if (di.config.businessId === '') {
            return undefined;
        }
        const sub = di
            .subscriptionBusinessProvider()(di.config.businessId)
            .subscribe((payload) => {
            dispatch(setBusinessChannels(payload.channels));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.businessId, di.subscriptionBusinessProvider]);
    const channels = useFeatureSelector(selectBusinessChannels);
    const waChannel = channels.find((x) => x.channelType === ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD);
    const attachmentState = useFeatureSelector(selectMessageTemplateAttachment);
    let attachment;
    if (attachmentState) {
        attachment = mapAttachmentStateToViewModel(attachmentState);
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastMessageTemplatesNewComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { attachment: attachment, channel: waChannel }) });
};
export default BroadcastMessageTemplatesNew;
