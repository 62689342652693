import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "exp.eta.descriptor";
export var OperatorType;
(function (OperatorType) {
    OperatorType[OperatorType["OPERATOR_TYPE_UNSPECIFIED"] = 0] = "OPERATOR_TYPE_UNSPECIFIED";
    OperatorType[OperatorType["OPERATOR_TYPE_EQ"] = 1] = "OPERATOR_TYPE_EQ";
    OperatorType[OperatorType["OPERATOR_TYPE_NEQ"] = 2] = "OPERATOR_TYPE_NEQ";
    OperatorType[OperatorType["OPERATOR_TYPE_GT"] = 3] = "OPERATOR_TYPE_GT";
    OperatorType[OperatorType["OPERATOR_TYPE_GTE"] = 4] = "OPERATOR_TYPE_GTE";
    OperatorType[OperatorType["OPERATOR_TYPE_LT"] = 5] = "OPERATOR_TYPE_LT";
    OperatorType[OperatorType["OPERATOR_TYPE_LTE"] = 6] = "OPERATOR_TYPE_LTE";
    OperatorType[OperatorType["OPERATOR_TYPE_CONTAINS"] = 7] = "OPERATOR_TYPE_CONTAINS";
    OperatorType[OperatorType["OPERATOR_TYPE_REGEX_MATCH"] = 8] = "OPERATOR_TYPE_REGEX_MATCH";
    OperatorType[OperatorType["OPERATOR_TYPE_AND"] = 9] = "OPERATOR_TYPE_AND";
    OperatorType[OperatorType["OPERATOR_TYPE_OR"] = 10] = "OPERATOR_TYPE_OR";
    OperatorType[OperatorType["OPERATOR_TYPE_IS_PHONE_NUMBER"] = 11] = "OPERATOR_TYPE_IS_PHONE_NUMBER";
    OperatorType[OperatorType["OPERATOR_TYPE_IS_EMAIL"] = 12] = "OPERATOR_TYPE_IS_EMAIL";
    OperatorType[OperatorType["OPERATOR_TYPE_IS_NUMBERIC"] = 13] = "OPERATOR_TYPE_IS_NUMBERIC";
    OperatorType[OperatorType["OPERATOR_TYPE_CAST"] = 14] = "OPERATOR_TYPE_CAST";
    OperatorType[OperatorType["OPERATOR_TYPE_NOT"] = 15] = "OPERATOR_TYPE_NOT";
    OperatorType[OperatorType["OPERATOR_TYPE_STARTS_WITH"] = 16] = "OPERATOR_TYPE_STARTS_WITH";
    OperatorType[OperatorType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OperatorType || (OperatorType = {}));
export function operatorTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "OPERATOR_TYPE_UNSPECIFIED":
            return OperatorType.OPERATOR_TYPE_UNSPECIFIED;
        case 1:
        case "OPERATOR_TYPE_EQ":
            return OperatorType.OPERATOR_TYPE_EQ;
        case 2:
        case "OPERATOR_TYPE_NEQ":
            return OperatorType.OPERATOR_TYPE_NEQ;
        case 3:
        case "OPERATOR_TYPE_GT":
            return OperatorType.OPERATOR_TYPE_GT;
        case 4:
        case "OPERATOR_TYPE_GTE":
            return OperatorType.OPERATOR_TYPE_GTE;
        case 5:
        case "OPERATOR_TYPE_LT":
            return OperatorType.OPERATOR_TYPE_LT;
        case 6:
        case "OPERATOR_TYPE_LTE":
            return OperatorType.OPERATOR_TYPE_LTE;
        case 7:
        case "OPERATOR_TYPE_CONTAINS":
            return OperatorType.OPERATOR_TYPE_CONTAINS;
        case 8:
        case "OPERATOR_TYPE_REGEX_MATCH":
            return OperatorType.OPERATOR_TYPE_REGEX_MATCH;
        case 9:
        case "OPERATOR_TYPE_AND":
            return OperatorType.OPERATOR_TYPE_AND;
        case 10:
        case "OPERATOR_TYPE_OR":
            return OperatorType.OPERATOR_TYPE_OR;
        case 11:
        case "OPERATOR_TYPE_IS_PHONE_NUMBER":
            return OperatorType.OPERATOR_TYPE_IS_PHONE_NUMBER;
        case 12:
        case "OPERATOR_TYPE_IS_EMAIL":
            return OperatorType.OPERATOR_TYPE_IS_EMAIL;
        case 13:
        case "OPERATOR_TYPE_IS_NUMBERIC":
            return OperatorType.OPERATOR_TYPE_IS_NUMBERIC;
        case 14:
        case "OPERATOR_TYPE_CAST":
            return OperatorType.OPERATOR_TYPE_CAST;
        case 15:
        case "OPERATOR_TYPE_NOT":
            return OperatorType.OPERATOR_TYPE_NOT;
        case 16:
        case "OPERATOR_TYPE_STARTS_WITH":
            return OperatorType.OPERATOR_TYPE_STARTS_WITH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OperatorType.UNRECOGNIZED;
    }
}
export function operatorTypeToJSON(object) {
    switch (object) {
        case OperatorType.OPERATOR_TYPE_UNSPECIFIED:
            return "OPERATOR_TYPE_UNSPECIFIED";
        case OperatorType.OPERATOR_TYPE_EQ:
            return "OPERATOR_TYPE_EQ";
        case OperatorType.OPERATOR_TYPE_NEQ:
            return "OPERATOR_TYPE_NEQ";
        case OperatorType.OPERATOR_TYPE_GT:
            return "OPERATOR_TYPE_GT";
        case OperatorType.OPERATOR_TYPE_GTE:
            return "OPERATOR_TYPE_GTE";
        case OperatorType.OPERATOR_TYPE_LT:
            return "OPERATOR_TYPE_LT";
        case OperatorType.OPERATOR_TYPE_LTE:
            return "OPERATOR_TYPE_LTE";
        case OperatorType.OPERATOR_TYPE_CONTAINS:
            return "OPERATOR_TYPE_CONTAINS";
        case OperatorType.OPERATOR_TYPE_REGEX_MATCH:
            return "OPERATOR_TYPE_REGEX_MATCH";
        case OperatorType.OPERATOR_TYPE_AND:
            return "OPERATOR_TYPE_AND";
        case OperatorType.OPERATOR_TYPE_OR:
            return "OPERATOR_TYPE_OR";
        case OperatorType.OPERATOR_TYPE_IS_PHONE_NUMBER:
            return "OPERATOR_TYPE_IS_PHONE_NUMBER";
        case OperatorType.OPERATOR_TYPE_IS_EMAIL:
            return "OPERATOR_TYPE_IS_EMAIL";
        case OperatorType.OPERATOR_TYPE_IS_NUMBERIC:
            return "OPERATOR_TYPE_IS_NUMBERIC";
        case OperatorType.OPERATOR_TYPE_CAST:
            return "OPERATOR_TYPE_CAST";
        case OperatorType.OPERATOR_TYPE_NOT:
            return "OPERATOR_TYPE_NOT";
        case OperatorType.OPERATOR_TYPE_STARTS_WITH:
            return "OPERATOR_TYPE_STARTS_WITH";
        case OperatorType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var PropertyType;
(function (PropertyType) {
    PropertyType[PropertyType["PROPERTY_TYPE_UNSPECIFIED"] = 0] = "PROPERTY_TYPE_UNSPECIFIED";
    PropertyType[PropertyType["PROPERTY_TYPE_NUMBER"] = 1] = "PROPERTY_TYPE_NUMBER";
    PropertyType[PropertyType["PROPERTY_TYPE_ULID"] = 2] = "PROPERTY_TYPE_ULID";
    PropertyType[PropertyType["PROPERTY_TYPE_BOOL"] = 3] = "PROPERTY_TYPE_BOOL";
    PropertyType[PropertyType["PROPERTY_TYPE_STRING"] = 4] = "PROPERTY_TYPE_STRING";
    PropertyType[PropertyType["PROPERTY_TYPE_JSON"] = 5] = "PROPERTY_TYPE_JSON";
    PropertyType[PropertyType["PROPERTY_TYPE_UUID"] = 6] = "PROPERTY_TYPE_UUID";
    PropertyType[PropertyType["PROPERTY_TYPE_NULL"] = 7] = "PROPERTY_TYPE_NULL";
    PropertyType[PropertyType["PROPERTY_TYPE_TIMESTAMP"] = 8] = "PROPERTY_TYPE_TIMESTAMP";
    PropertyType[PropertyType["PROPERTY_TYPE_DURATION"] = 9] = "PROPERTY_TYPE_DURATION";
    PropertyType[PropertyType["PROPERTY_TYPE_RRULE"] = 10] = "PROPERTY_TYPE_RRULE";
    PropertyType[PropertyType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PropertyType || (PropertyType = {}));
export function propertyTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "PROPERTY_TYPE_UNSPECIFIED":
            return PropertyType.PROPERTY_TYPE_UNSPECIFIED;
        case 1:
        case "PROPERTY_TYPE_NUMBER":
            return PropertyType.PROPERTY_TYPE_NUMBER;
        case 2:
        case "PROPERTY_TYPE_ULID":
            return PropertyType.PROPERTY_TYPE_ULID;
        case 3:
        case "PROPERTY_TYPE_BOOL":
            return PropertyType.PROPERTY_TYPE_BOOL;
        case 4:
        case "PROPERTY_TYPE_STRING":
            return PropertyType.PROPERTY_TYPE_STRING;
        case 5:
        case "PROPERTY_TYPE_JSON":
            return PropertyType.PROPERTY_TYPE_JSON;
        case 6:
        case "PROPERTY_TYPE_UUID":
            return PropertyType.PROPERTY_TYPE_UUID;
        case 7:
        case "PROPERTY_TYPE_NULL":
            return PropertyType.PROPERTY_TYPE_NULL;
        case 8:
        case "PROPERTY_TYPE_TIMESTAMP":
            return PropertyType.PROPERTY_TYPE_TIMESTAMP;
        case 9:
        case "PROPERTY_TYPE_DURATION":
            return PropertyType.PROPERTY_TYPE_DURATION;
        case 10:
        case "PROPERTY_TYPE_RRULE":
            return PropertyType.PROPERTY_TYPE_RRULE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PropertyType.UNRECOGNIZED;
    }
}
export function propertyTypeToJSON(object) {
    switch (object) {
        case PropertyType.PROPERTY_TYPE_UNSPECIFIED:
            return "PROPERTY_TYPE_UNSPECIFIED";
        case PropertyType.PROPERTY_TYPE_NUMBER:
            return "PROPERTY_TYPE_NUMBER";
        case PropertyType.PROPERTY_TYPE_ULID:
            return "PROPERTY_TYPE_ULID";
        case PropertyType.PROPERTY_TYPE_BOOL:
            return "PROPERTY_TYPE_BOOL";
        case PropertyType.PROPERTY_TYPE_STRING:
            return "PROPERTY_TYPE_STRING";
        case PropertyType.PROPERTY_TYPE_JSON:
            return "PROPERTY_TYPE_JSON";
        case PropertyType.PROPERTY_TYPE_UUID:
            return "PROPERTY_TYPE_UUID";
        case PropertyType.PROPERTY_TYPE_NULL:
            return "PROPERTY_TYPE_NULL";
        case PropertyType.PROPERTY_TYPE_TIMESTAMP:
            return "PROPERTY_TYPE_TIMESTAMP";
        case PropertyType.PROPERTY_TYPE_DURATION:
            return "PROPERTY_TYPE_DURATION";
        case PropertyType.PROPERTY_TYPE_RRULE:
            return "PROPERTY_TYPE_RRULE";
        case PropertyType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseExpression() {
    return { expOneof: undefined };
}
export const Expression = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.expOneof) === null || _a === void 0 ? void 0 : _a.$case) === "literal") {
            Literal.encode(message.expOneof.literal, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.expOneof) === null || _b === void 0 ? void 0 : _b.$case) === "valueExp") {
            ValueExpression.encode(message.expOneof.valueExp, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpression();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.expOneof = {
                        $case: "literal",
                        literal: Literal.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.expOneof = {
                        $case: "valueExp",
                        valueExp: ValueExpression.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            expOneof: isSet(object.literal)
                ? { $case: "literal", literal: Literal.fromJSON(object.literal) }
                : isSet(object.valueExp)
                    ? {
                        $case: "valueExp",
                        valueExp: ValueExpression.fromJSON(object.valueExp),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.expOneof) === null || _a === void 0 ? void 0 : _a.$case) === "literal" &&
            (obj.literal = ((_b = message.expOneof) === null || _b === void 0 ? void 0 : _b.literal)
                ? Literal.toJSON((_c = message.expOneof) === null || _c === void 0 ? void 0 : _c.literal)
                : undefined);
        ((_d = message.expOneof) === null || _d === void 0 ? void 0 : _d.$case) === "valueExp" &&
            (obj.valueExp = ((_e = message.expOneof) === null || _e === void 0 ? void 0 : _e.valueExp)
                ? ValueExpression.toJSON((_f = message.expOneof) === null || _f === void 0 ? void 0 : _f.valueExp)
                : undefined);
        return obj;
    },
};
function createBaseValueExpression() {
    return { operator: 0, operands: [] };
}
export const ValueExpression = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.operator !== 0) {
            writer.uint32(8).int32(message.operator);
        }
        for (const v of message.operands) {
            Expression.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValueExpression();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.operator = reader.int32();
                    break;
                case 2:
                    message.operands.push(Expression.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            operator: isSet(object.operator)
                ? operatorTypeFromJSON(object.operator)
                : 0,
            operands: Array.isArray(object === null || object === void 0 ? void 0 : object.operands)
                ? object.operands.map((e) => Expression.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.operator !== undefined &&
            (obj.operator = operatorTypeToJSON(message.operator));
        if (message.operands) {
            obj.operands = message.operands.map((e) => e ? Expression.toJSON(e) : undefined);
        }
        else {
            obj.operands = [];
        }
        return obj;
    },
};
function createBaseLiteral() {
    return { litOneof: undefined };
}
export const Literal = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.litOneof) === null || _a === void 0 ? void 0 : _a.$case) === "variable") {
            VariableLiteral.encode(message.litOneof.variable, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.litOneof) === null || _b === void 0 ? void 0 : _b.$case) === "const") {
            ConstLiteral.encode(message.litOneof.const, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLiteral();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.litOneof = {
                        $case: "variable",
                        variable: VariableLiteral.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.litOneof = {
                        $case: "const",
                        const: ConstLiteral.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            litOneof: isSet(object.variable)
                ? {
                    $case: "variable",
                    variable: VariableLiteral.fromJSON(object.variable),
                }
                : isSet(object.const)
                    ? { $case: "const", const: ConstLiteral.fromJSON(object.const) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.litOneof) === null || _a === void 0 ? void 0 : _a.$case) === "variable" &&
            (obj.variable = ((_b = message.litOneof) === null || _b === void 0 ? void 0 : _b.variable)
                ? VariableLiteral.toJSON((_c = message.litOneof) === null || _c === void 0 ? void 0 : _c.variable)
                : undefined);
        ((_d = message.litOneof) === null || _d === void 0 ? void 0 : _d.$case) === "const" &&
            (obj.const = ((_e = message.litOneof) === null || _e === void 0 ? void 0 : _e.const)
                ? ConstLiteral.toJSON((_f = message.litOneof) === null || _f === void 0 ? void 0 : _f.const)
                : undefined);
        return obj;
    },
};
function createBaseVariableLiteral() {
    return { variable: undefined };
}
export const VariableLiteral = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.variable !== undefined) {
            Variable.encode(message.variable, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariableLiteral();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.variable = Variable.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            variable: isSet(object.variable)
                ? Variable.fromJSON(object.variable)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.variable !== undefined &&
            (obj.variable = message.variable
                ? Variable.toJSON(message.variable)
                : undefined);
        return obj;
    },
};
function createBaseConstLiteral() {
    return { type: 0, value: "" };
}
export const ConstLiteral = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConstLiteral();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? propertyTypeFromJSON(object.type) : 0,
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = propertyTypeToJSON(message.type));
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBasePropertyDescriptor() {
    return { name: "", type: 0, schema: [] };
}
export const PropertyDescriptor = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        for (const v of message.schema) {
            PropertyDescriptor.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePropertyDescriptor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.schema.push(PropertyDescriptor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            type: isSet(object.type) ? propertyTypeFromJSON(object.type) : 0,
            schema: Array.isArray(object === null || object === void 0 ? void 0 : object.schema)
                ? object.schema.map((e) => PropertyDescriptor.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = propertyTypeToJSON(message.type));
        if (message.schema) {
            obj.schema = message.schema.map((e) => e ? PropertyDescriptor.toJSON(e) : undefined);
        }
        else {
            obj.schema = [];
        }
        return obj;
    },
};
function createBasePropertyValue() {
    return { property: undefined, value: "" };
}
export const PropertyValue = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.property !== undefined) {
            PropertyDescriptor.encode(message.property, writer.uint32(10).fork()).ldelim();
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePropertyValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.property = PropertyDescriptor.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            property: isSet(object.property)
                ? PropertyDescriptor.fromJSON(object.property)
                : undefined,
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.property !== undefined &&
            (obj.property = message.property
                ? PropertyDescriptor.toJSON(message.property)
                : undefined);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseParameterDescriptor() {
    return {
        property: undefined,
        optional: false,
        value: undefined,
        context: [],
    };
}
export const ParameterDescriptor = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.property !== undefined) {
            PropertyDescriptor.encode(message.property, writer.uint32(10).fork()).ldelim();
        }
        if (message.optional === true) {
            writer.uint32(16).bool(message.optional);
        }
        if (message.value !== undefined) {
            ParameterValue.encode(message.value, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.context) {
            ParameterValue.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParameterDescriptor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.property = PropertyDescriptor.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.optional = reader.bool();
                    break;
                case 3:
                    message.value = ParameterValue.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.context.push(ParameterValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            property: isSet(object.property)
                ? PropertyDescriptor.fromJSON(object.property)
                : undefined,
            optional: isSet(object.optional) ? Boolean(object.optional) : false,
            value: isSet(object.value)
                ? ParameterValue.fromJSON(object.value)
                : undefined,
            context: Array.isArray(object === null || object === void 0 ? void 0 : object.context)
                ? object.context.map((e) => ParameterValue.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.property !== undefined &&
            (obj.property = message.property
                ? PropertyDescriptor.toJSON(message.property)
                : undefined);
        message.optional !== undefined && (obj.optional = message.optional);
        message.value !== undefined &&
            (obj.value = message.value
                ? ParameterValue.toJSON(message.value)
                : undefined);
        if (message.context) {
            obj.context = message.context.map((e) => e ? ParameterValue.toJSON(e) : undefined);
        }
        else {
            obj.context = [];
        }
        return obj;
    },
};
function createBaseParameterValue() {
    return { valueOneof: undefined, fallBackValues: [] };
}
export const ParameterValue = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "const") {
            PropertyValue.encode(message.valueOneof.const, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.$case) === "variable") {
            Variable.encode(message.valueOneof.variable, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.fallBackValues) {
            ParameterValue.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParameterValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valueOneof = {
                        $case: "const",
                        const: PropertyValue.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.valueOneof = {
                        $case: "variable",
                        variable: Variable.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.fallBackValues.push(ParameterValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            valueOneof: isSet(object.const)
                ? { $case: "const", const: PropertyValue.fromJSON(object.const) }
                : isSet(object.variable)
                    ? { $case: "variable", variable: Variable.fromJSON(object.variable) }
                    : undefined,
            fallBackValues: Array.isArray(object === null || object === void 0 ? void 0 : object.fallBackValues)
                ? object.fallBackValues.map((e) => ParameterValue.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "const" &&
            (obj.const = ((_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.const)
                ? PropertyValue.toJSON((_c = message.valueOneof) === null || _c === void 0 ? void 0 : _c.const)
                : undefined);
        ((_d = message.valueOneof) === null || _d === void 0 ? void 0 : _d.$case) === "variable" &&
            (obj.variable = ((_e = message.valueOneof) === null || _e === void 0 ? void 0 : _e.variable)
                ? Variable.toJSON((_f = message.valueOneof) === null || _f === void 0 ? void 0 : _f.variable)
                : undefined);
        if (message.fallBackValues) {
            obj.fallBackValues = message.fallBackValues.map((e) => e ? ParameterValue.toJSON(e) : undefined);
        }
        else {
            obj.fallBackValues = [];
        }
        return obj;
    },
};
function createBaseVariable() {
    return { variableOneof: undefined };
}
export const Variable = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        if (((_a = message.variableOneof) === null || _a === void 0 ? void 0 : _a.$case) === "eventDatum") {
            FlowDatum.encode(message.variableOneof.eventDatum, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.variableOneof) === null || _b === void 0 ? void 0 : _b.$case) === "actionDatum") {
            FlowDatum.encode(message.variableOneof.actionDatum, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.variableOneof) === null || _c === void 0 ? void 0 : _c.$case) === "sessionDatum") {
            PropertyDescriptor.encode(message.variableOneof.sessionDatum, writer.uint32(794).fork()).ldelim();
        }
        if (((_d = message.variableOneof) === null || _d === void 0 ? void 0 : _d.$case) === "var") {
            VariableAccessor.encode(message.variableOneof.var, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.variableOneof = {
                        $case: "eventDatum",
                        eventDatum: FlowDatum.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.variableOneof = {
                        $case: "actionDatum",
                        actionDatum: FlowDatum.decode(reader, reader.uint32()),
                    };
                    break;
                case 99:
                    message.variableOneof = {
                        $case: "sessionDatum",
                        sessionDatum: PropertyDescriptor.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.variableOneof = {
                        $case: "var",
                        var: VariableAccessor.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            variableOneof: isSet(object.eventDatum)
                ? {
                    $case: "eventDatum",
                    eventDatum: FlowDatum.fromJSON(object.eventDatum),
                }
                : isSet(object.actionDatum)
                    ? {
                        $case: "actionDatum",
                        actionDatum: FlowDatum.fromJSON(object.actionDatum),
                    }
                    : isSet(object.sessionDatum)
                        ? {
                            $case: "sessionDatum",
                            sessionDatum: PropertyDescriptor.fromJSON(object.sessionDatum),
                        }
                        : isSet(object.var)
                            ? { $case: "var", var: VariableAccessor.fromJSON(object.var) }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        ((_a = message.variableOneof) === null || _a === void 0 ? void 0 : _a.$case) === "eventDatum" &&
            (obj.eventDatum = ((_b = message.variableOneof) === null || _b === void 0 ? void 0 : _b.eventDatum)
                ? FlowDatum.toJSON((_c = message.variableOneof) === null || _c === void 0 ? void 0 : _c.eventDatum)
                : undefined);
        ((_d = message.variableOneof) === null || _d === void 0 ? void 0 : _d.$case) === "actionDatum" &&
            (obj.actionDatum = ((_e = message.variableOneof) === null || _e === void 0 ? void 0 : _e.actionDatum)
                ? FlowDatum.toJSON((_f = message.variableOneof) === null || _f === void 0 ? void 0 : _f.actionDatum)
                : undefined);
        ((_g = message.variableOneof) === null || _g === void 0 ? void 0 : _g.$case) === "sessionDatum" &&
            (obj.sessionDatum = ((_h = message.variableOneof) === null || _h === void 0 ? void 0 : _h.sessionDatum)
                ? PropertyDescriptor.toJSON((_j = message.variableOneof) === null || _j === void 0 ? void 0 : _j.sessionDatum)
                : undefined);
        ((_k = message.variableOneof) === null || _k === void 0 ? void 0 : _k.$case) === "var" &&
            (obj.var = ((_l = message.variableOneof) === null || _l === void 0 ? void 0 : _l.var)
                ? VariableAccessor.toJSON((_m = message.variableOneof) === null || _m === void 0 ? void 0 : _m.var)
                : undefined);
        return obj;
    },
};
function createBaseFlowDatum() {
    return { flowId: "", property: undefined };
}
export const FlowDatum = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowId !== "") {
            writer.uint32(10).string(message.flowId);
        }
        if (message.property !== undefined) {
            PropertyDescriptor.encode(message.property, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDatum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowId = reader.string();
                    break;
                case 2:
                    message.property = PropertyDescriptor.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowId: isSet(object.flowId) ? String(object.flowId) : "",
            property: isSet(object.property)
                ? PropertyDescriptor.fromJSON(object.property)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowId !== undefined && (obj.flowId = message.flowId);
        message.property !== undefined &&
            (obj.property = message.property
                ? PropertyDescriptor.toJSON(message.property)
                : undefined);
        return obj;
    },
};
function createBaseVariableDescriptor() {
    return { id: "", namespace: "", name: "", type: 0 };
}
export const VariableDescriptor = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.namespace !== "") {
            writer.uint32(18).string(message.namespace);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.type !== 0) {
            writer.uint32(32).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariableDescriptor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.namespace = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            namespace: isSet(object.namespace) ? String(object.namespace) : "",
            name: isSet(object.name) ? String(object.name) : "",
            type: isSet(object.type) ? propertyTypeFromJSON(object.type) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.namespace !== undefined && (obj.namespace = message.namespace);
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = propertyTypeToJSON(message.type));
        return obj;
    },
};
function createBaseVariableAccessor() {
    return { fullyQualifiedName: "", type: 0 };
}
export const VariableAccessor = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.fullyQualifiedName !== "") {
            writer.uint32(10).string(message.fullyQualifiedName);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariableAccessor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fullyQualifiedName = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            fullyQualifiedName: isSet(object.fullyQualifiedName)
                ? String(object.fullyQualifiedName)
                : "",
            type: isSet(object.type) ? propertyTypeFromJSON(object.type) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.fullyQualifiedName !== undefined &&
            (obj.fullyQualifiedName = message.fullyQualifiedName);
        message.type !== undefined && (obj.type = propertyTypeToJSON(message.type));
        return obj;
    },
};
function createBaseFlowVar() {
    return { desc: undefined, value: undefined };
}
export const FlowVar = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.desc !== undefined) {
            VariableDescriptor.encode(message.desc, writer.uint32(10).fork()).ldelim();
        }
        if (message.value !== undefined) {
            StringValue.encode({ value: message.value }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowVar();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.desc = VariableDescriptor.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.value = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            desc: isSet(object.desc)
                ? VariableDescriptor.fromJSON(object.desc)
                : undefined,
            value: isSet(object.value) ? String(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.desc !== undefined &&
            (obj.desc = message.desc
                ? VariableDescriptor.toJSON(message.desc)
                : undefined);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseMetadata() {
    return { md: {} };
}
export const Metadata = {
    encode(message, writer = _m0.Writer.create()) {
        Object.entries(message.md).forEach(([key, value]) => {
            Metadata_MdEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = Metadata_MdEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.md[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            md: isObject(object.md)
                ? Object.entries(object.md).reduce((acc, [key, value]) => {
                    acc[key] = ParameterValue.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        obj.md = {};
        if (message.md) {
            Object.entries(message.md).forEach(([k, v]) => {
                obj.md[k] = ParameterValue.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseMetadata_MdEntry() {
    return { key: "", value: undefined };
}
export const Metadata_MdEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            ParameterValue.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadata_MdEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = ParameterValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? ParameterValue.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? ParameterValue.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
