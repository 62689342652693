import React, { useCallback } from 'react';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import {
  overviewDatesAtom,
  dateRangeLast7DaysAtom,
  conversationFilterCategoriesAtom,
  conversationFilterAtom,
} from '@atoms/analytics';
import { upperFirst } from 'src/utils/titleCase';
import { Box, Tab, Tabs, useTheme, Pills, PillsItem } from '@connectlyai-tenets/ui-styled-web';
import { useFeatureFlag } from '@hooks';
import { DateRange, DateRangePicker } from '../../../../components/DateRangePicker';

const useAnalyticsControls = () => {
  const [dateRange, setDateRange] = useAtom(dateRangeLast7DaysAtom);
  const setDates = useSetAtom(overviewDatesAtom);
  const setConversationFilter = useSetAtom(conversationFilterAtom);
  const activeConversationFilter = useAtomValue(conversationFilterAtom);
  const conversationFilterCategories = useAtomValue(conversationFilterCategoriesAtom);
  const handleChangeFilter = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setConversationFilter(newValue);
    },
    [setConversationFilter],
  );

  const getDateInUTC = (d: Date) => new Date(`${d.toISOString().slice(0, 10)}T00:00:00.000Z`);

  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDates([getDateInUTC(newDateRange.startDate), getDateInUTC(newDateRange.endDate)]);
      setDateRange(newDateRange);
    },
    [setDateRange, setDates],
  );

  return {
    dateRange,
    handleDateRangeChange,
    conversationFilterCategories,
    setConversationFilter,
    handleChangeFilter,
    activeConversationFilter,
  };
};

export const AnalyticsControls = () => {
  const {
    dateRange,
    handleChangeFilter,
    handleDateRangeChange,
    conversationFilterCategories,
    setConversationFilter,
    activeConversationFilter,
  } = useAnalyticsControls();

  const theme = useTheme();
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto',
        maxHeight: theme.spacing(5.5),
        position: 'relative',
        gap: 3,
      }}
    >
      <DateRangePicker dateRange={dateRange} setDateRange={handleDateRangeChange} />

      {!ffEnableAnalyticsRedesign && (
        <Tabs value={activeConversationFilter} onChange={handleChangeFilter}>
          {conversationFilterCategories.map((filter: string) => (
            <Tab key={filter} label={upperFirst(filter)} value={filter} />
          ))}
        </Tabs>
      )}

      {ffEnableAnalyticsRedesign && (
        <Pills>
          {conversationFilterCategories.map((filter: string) => (
            <PillsItem
              key={filter}
              item={upperFirst(filter)}
              onClick={() => setConversationFilter(filter)}
              isActive={activeConversationFilter === filter}
            />
          ))}
        </Pills>
      )}
    </Box>
  );
};
