import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Box,
  Button,
  CartIcon,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Label,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { apiKeyAtom, apiTokenAtom, vtexAccountNameAtom, vtexActivationStepAtom } from '@atoms/abandonedcart';
import {
  selectBusinessId,
  selectVtexBusinessChannel,
  useBusinessChannelMutation,
  useBusinessChannelsData,
  useMeData,
} from '@hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ApprovalDialog } from '@components/ApprovalDialog';
import { VtexTriggerSetupProps } from '../VtexAbandonedCart/types';
import { VTEX_CONNECTLY_WEBHOOK_URL, VTEX_CONTENT_JSON, VTEX_TRIGGER_SETUP_VIDEO_URL } from './constants';
import { NotificationContext } from '../../contexts';
import { SIZE_MEDIA_XL, SIZE_CONTENT_XS } from '../../ui-theme';
import { getStepHelperText, getStepLabel, getTriggerInstruction } from './mapper';
import { isAccoutNameValid } from './utils';

export const VtexTriggerSetup = ({ open, onClose, onOpenActivation }: VtexTriggerSetupProps) => {
  const theme = useTheme();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);
  const [currentTriggerStep, setCurrentTriggerStep] = useState(1);
  const [currentStep, setCurrentStep] = useAtom(vtexActivationStepAtom);
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);
  const [apiToken, setApiToken] = useAtom(apiTokenAtom);
  const [vtexAccountName, setVtexAccountName] = useAtom(vtexAccountNameAtom);
  const queryClient = useQueryClient();

  const accountNameError = useMemo(() => {
    if (currentStep === 2) {
      return !isAccoutNameValid(vtexAccountName);
    }
    return false;
  }, [currentStep, vtexAccountName]);

  const { data: vtexBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectVtexBusinessChannel,
  });

  const { mutate: mutateBusinessChannel, isLoading: isMutatingChannel } = useBusinessChannelMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['businessChannels']);
      setCurrentStep(3);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: `Something went wrong. Please try again.`,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const onNext = () => {
    switch (currentStep) {
      case 0: {
        if (vtexBusinessChannel) {
          setCurrentStep(3);
        } else {
          setCurrentStep(1);
        }
        break;
      }
      case 1: {
        setCurrentStep(2);
        break;
      }
      case 2: {
        mutateBusinessChannel({
          businessId: businessId as string,
          channel: {
            byTypeAndExternalId: {
              externalId: vtexAccountName,
              channelType: 'CHANNEL_TYPE_VTEX',
            },
          },
          accessToken: apiToken,
          remoteProfile: {
            vtexProfile: {
              apiKey,
              storeDomain: `${vtexAccountName}.myvtex.com`,
            },
          },
        });
        break;
      }
      case 3: {
        setCurrentStep(0);
        onOpenActivation();
        onClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  const onBack = () => {
    switch (currentStep) {
      case 1: {
        setCurrentStep(0);
        break;
      }
      case 2: {
        if (vtexBusinessChannel) {
          setCurrentStep(0);
        } else {
          setCurrentStep(1);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const validInput = useMemo(() => {
    if (currentStep === 1) {
      return Boolean(apiKey) && Boolean(apiToken);
    }
    if (currentStep === 2) {
      return Boolean(vtexAccountName);
    }
    return true;
  }, [currentStep, apiKey, apiToken, vtexAccountName]);

  useEffect(() => {
    if (vtexBusinessChannel?.externalId) {
      setVtexAccountName(vtexBusinessChannel?.externalId);
    }
  }, [vtexBusinessChannel?.externalId, setVtexAccountName]);

  const triggerStepText = useMemo(() => {
    return getTriggerInstruction(currentTriggerStep);
  }, [currentTriggerStep]);

  const copyText = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);

      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Copied to clipboard',
          icon: '',
          severity: NotificationSeverity.INFO,
        },
      });
    },
    [notificationProvider],
  );

  const triggerHelper = useMemo(() => {
    switch (currentTriggerStep) {
      case 5: {
        return (
          <Box>
            <Button variant="text" color="primary" disableRipple onClick={() => copyText(VTEX_CONNECTLY_WEBHOOK_URL)}>
              <Label variant="body2">COPY TEXT</Label>
            </Button>
            <Label variant="body1">{VTEX_CONNECTLY_WEBHOOK_URL}</Label>
          </Box>
        );
      }
      case 7: {
        return (
          <Box>
            <Button variant="text" color="primary" disableRipple onClick={() => copyText(VTEX_CONTENT_JSON)}>
              <Label variant="body2">COPY TEXT</Label>
            </Button>
            <Box
              sx={{
                height: theme.spacing(SIZE_CONTENT_XS),
                overflow: 'hidden',
                scrollbarGutter: 'stable',
                '&:hover': {
                  overflow: 'auto',
                },
              }}
            >
              <Label variant="body1">{VTEX_CONTENT_JSON}</Label>
            </Box>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  }, [currentTriggerStep, copyText]);

  const stepBody = useMemo(() => {
    switch (currentStep) {
      case 0: {
        return (
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.light,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              p: 2,
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="small"
                disabled={currentTriggerStep === 1}
                onClick={() => setCurrentTriggerStep(currentTriggerStep - 1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Label variant="body1">{`Step ${currentTriggerStep}/8`}</Label>
              <IconButton
                size="small"
                disabled={currentTriggerStep === 8}
                onClick={() => setCurrentTriggerStep(currentTriggerStep + 1)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box>
              <Label variant="body1">
                <pre style={{ fontFamily: 'inherit' }}>{triggerStepText}</pre>
              </Label>
              {triggerHelper}
            </Box>
          </Box>
        );
      }
      case 1: {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <TextField placeholder="Enter API Key" onChange={(e) => setApiKey(e.target.value)} value={apiKey} />
            <TextField placeholder="Enter API Token" onChange={(e) => setApiToken(e.target.value)} value={apiToken} />
          </Box>
        );
      }
      case 2: {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <TextField
              placeholder="Enter VTEX Account Name"
              helperText={accountNameError ? 'Please enter a valid account name' : ' '}
              error={accountNameError}
              onChange={(e) => setVtexAccountName(e.target.value)}
              value={vtexAccountName}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  }, [
    currentStep,
    currentTriggerStep,
    triggerHelper,
    triggerStepText,
    apiKey,
    apiToken,
    vtexAccountName,
    setApiKey,
    setApiToken,
    setVtexAccountName,
  ]);

  const stepLabel = useMemo(() => getStepLabel(currentStep), [currentStep]);
  const helperText = useMemo(() => getStepHelperText(currentStep), [currentStep]);
  if (currentStep === 3) {
    return (
      <ApprovalDialog
        content=""
        onClose={onNext}
        open={currentStep === 3}
        title="Congratulations! The VTEX integration is finalized."
      />
    );
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 200,
            width: '100%',
            background: '#F6F8FF',
            userSelect: 'none',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{ position: 'absolute', right: '16px', top: '16px' }}>
            <IconButton onClick={onClose} sx={{ mx: 1 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CartIcon width={theme.spacing(SIZE_MEDIA_XL)} />
        </Box>
      </Box>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-start', p: 1 }}>
          <Label variant="h4">{stepLabel}</Label>
          {currentStep === 0 && (
            <Box>
              <Button variant="outlined" color="secondary" href={VTEX_TRIGGER_SETUP_VIDEO_URL} target="_blank">
                Watch Video
              </Button>
            </Box>
          )}
          <Label color="text-secondary" variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {helperText}
          </Label>
          {stepBody}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={currentStep === 0} onClick={onBack}>
          Back
        </Button>
        <Button disabled={isMutatingChannel || !validInput || accountNameError} onClick={onNext}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};
