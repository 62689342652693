import { createAsyncThunk } from '@reduxjs/toolkit';
export const fetchRooms = createAsyncThunk('rooms/fetchRooms', async (arg, { getState }) => {
    const { di } = arg;
    const state = getState();
    const queryRooms = di.queryRoomsProvider();
    const { businessId } = di.config;
    if (businessId) {
        const rooms = await queryRooms(di.config.businessId, state.rooms.filter, state.rooms.offset);
        return rooms;
    }
    throw new Error('invalid business id');
});
export const fetchRoom = createAsyncThunk('rooms/fetchRoom', async (arg) => {
    const { di, roomId } = arg;
    const queryRoom = di.queryRoomProvider();
    const room = await queryRoom(di.config.businessId, roomId);
    return room;
});
