import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiError } from '@connectlyai-tenets/error';
import { Error } from '@connectlyai-idl/models/dist/models/error/error';
import { ErrorCode } from '@connectlyai-idl/models/dist/models/error/code';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { toApiError } from './Error';
const ffEnableHeaderSessionId = getFlagByName('ffEnableHeaderSessionId');
const requiresReAuthentication = (resp) => {
    if (resp &&
        resp.status === 401 &&
        typeof resp.data === 'string' &&
        resp.data.startsWith('missing scopes claim on jwt token')) {
        return true;
    }
    return false;
};
const WithGetTokenSilentlyQuery = (networkMethod, getTokenSilently, logout, audience, sessionId) => async (...requestArgs) => {
    const [url, config] = requestArgs;
    const token = await getTokenSilently({
        audience,
    });
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    if (ffEnableHeaderSessionId && sessionId)
        options.headers['Cnt-Ss-Id'] = sessionId;
    return networkMethod(url, { ...config, ...options }).catch(async (error) => {
        if (requiresReAuthentication(error.response)) {
            await logout({
                returnTo: typeof window !== 'undefined' ? window.location.origin : '/',
            });
        }
        throw error;
    });
};
const WithGetTokenSilentlyMutation = (networkMethod, getTokenSilently, audience, sessionId) => async (...requestArgs) => {
    const [url, data, config] = requestArgs;
    const token = await getTokenSilently({
        audience,
    });
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    if (ffEnableHeaderSessionId && sessionId)
        options.headers['Cnt-Ss-Id'] = sessionId;
    return networkMethod(url, data, { ...config, ...options }).catch((error) => {
        if (error.response && error.response.data) {
            const parsedError = Error.fromJSON(error.response.data);
            throw toApiError(parsedError);
        }
        throw new ApiError(ErrorCode.ERROR_CODE_UNSPECIFIED, error.message, true, error.details);
    });
};
const WithGetTokenSilentlyDeletion = (networkMethod, getTokenSilently, audience, sessionId) => async (...requestArgs) => {
    const [url, config] = requestArgs;
    const token = await getTokenSilently({
        audience,
    });
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    if (ffEnableHeaderSessionId && sessionId)
        options.headers['Cnt-Ss-Id'] = sessionId;
    return networkMethod(url, { ...config, ...options });
};
const useAuthedNetworkClient = ({ networkClient, audience, sessionId, }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const authedClient = React.useMemo(() => ({
        get: WithGetTokenSilentlyQuery(networkClient.get, getAccessTokenSilently, logout, audience, sessionId),
        delete: WithGetTokenSilentlyDeletion(networkClient.delete, getAccessTokenSilently, audience, sessionId),
        post: WithGetTokenSilentlyMutation(networkClient.post, getAccessTokenSilently, audience, sessionId),
        put: WithGetTokenSilentlyMutation(networkClient.put, getAccessTokenSilently, audience, sessionId),
        patch: WithGetTokenSilentlyMutation(networkClient.patch, getAccessTokenSilently, audience, sessionId),
    }), [networkClient, getAccessTokenSilently, logout, audience, sessionId]);
    return { authedClient };
};
export default useAuthedNetworkClient;
