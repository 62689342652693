import React, { FC, useCallback, useMemo, useRef, useState, MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { AddVariable } from '@components/AddVariable';
import { HideableAlert } from '@components/HideableAlert';
import {
  Box,
  Button,
  CodeIcon,
  FormatBoldIcon,
  FormatItalicIcon,
  FormatStrikethroughIcon,
  IconButton,
  InputAdornment,
  Label,
  SidebarSofiaIcon,
  TextField,
  TextFieldsIcon,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { v4 as uuidv4 } from 'uuid';
import { useAtomValue } from 'jotai';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { SelectionRange, EditorSelection } from '@codemirror/state';
import { selectedNodeAtom } from '@atoms/flow';
import { useAnalytics, useFeatureFlag, useRephraseWithAI } from '@hooks';
import { useTranslation } from 'react-i18next';
import { selectBody, selectBodyError, setBody } from '../../state/messageTemplates';
import { BODY_MAX_LENGTH } from './constants';
import { isAppleDevice } from '../../utils';
import EmojiPickerButton from '../../presentation/EmojiPicker';
import { findNextVariable } from '../../presentation/preview/utils';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { TemplateBuilderTitle } from '../TemplateBuilderTitle';
import { CodeMirrorTextField } from '../CodeMirrorTextField';
import { FlowVariableDialog, FlowVariableDialogRef } from '../FlowVariableDialog';
import { parseVariables, useFlowVariables } from '../../hooks/useFlowVariables';
import { FlowVariableExtended } from '../../hooks/useFlowVariables/types';
import './style.css';
import { BorderAnimation } from './BorderAnimation';

const isAutoReplyBodyVariablesEnabled = getFlagByName('ffEnableAutoReplyBodyVariables');

const useTemplateBuilderBody = ({ minimized }: { minimized?: boolean }) => {
  const [focused, setFocused] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { sendAnalytics } = useAnalytics();
  const shouldBeMinimized = minimized && !focused;
  const { populateVariables, canonicalize, variablesSet, addVariables } = useFlowVariables();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'templateBuilder' });

  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const { mutateAsync: generatePromptResult } = useRephraseWithAI();
  const shouldUseCodeMirror = isAutoReplyBodyVariablesEnabled || ffEnableETA;

  const body = useSelector(selectBody);
  const error = useSelector(selectBodyError);

  const flowVariableDialogRef = useRef<FlowVariableDialogRef>(null);
  const addVariableRef = useRef<HTMLButtonElement>(null);
  const activeVariableRef = useRef<HTMLElement | null>(null);
  const codeMirrorRef = useRef<ReactCodeMirrorRef | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickOutside = useCallback(() => {
    setFocused(false);
  }, []);

  const handleBodyChange = useCallback(
    (newBody: string) => {
      dispatch(setBody(newBody));
    },
    [dispatch],
  );

  const handleBodyInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      handleBodyChange(event.target.value);
    },
    [handleBodyChange],
  );

  const rephrasingActionText = isLoading
    ? t('rephraseWithAILoading', 'Rephrazing...')
    : t('rephraseWithAI', 'Rephrase with AI');
  const handleGenerateResult = useCallback(async () => {
    sendAnalytics('flow.template_builder.rephrase_with_ai_clicked');
    setLoading(true);

    const result = (await generatePromptResult(body)) || body;

    setLoading(false);
    handleBodyChange(result);
  }, [body, generatePromptResult, handleBodyChange, sendAnalytics]);

  const handleBodyEditorChange = useCallback(
    (value: string) => {
      const newVariables = parseVariables(value).reduce((acc: Array<FlowVariableExtended>, variable) => {
        if (!variablesSet.has(variable)) {
          acc.push({
            id: uuidv4(),
            type: 'FLOW_TYPE_STRING',
            namespace: '',
            namespaceCanonical: '',
            simpleName: variable,
            simpleNameCanonical: canonicalize(variable),
            validators: [],
          });
        }
        return acc;
      }, []);
      addVariables(newVariables);
      handleBodyChange(value);
    },
    [handleBodyChange, variablesSet, canonicalize, addVariables],
  );

  const selectedNode = useAtomValue(selectedNodeAtom);
  const populatedBody = useMemo(
    () =>
      selectedNode?.data?.v1?.parameterMapping?.mappings
        ? populateVariables(body, 'body_', selectedNode?.data?.v1?.parameterMapping?.mappings)
        : body,
    [body, populateVariables, selectedNode?.data?.v1?.parameterMapping?.mappings],
  );

  const handleAddVariable = useCallback(() => {
    if (isAutoReplyBodyVariablesEnabled || ffEnableETA) {
      flowVariableDialogRef.current?.handleOpenDialog();
      return;
    }

    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    element.focus();

    const unselectedTextFrom = element.selectionStart === null ? undefined : element.selectionStart;
    const unselectedTextTo = element.selectionEnd === null ? element.value.length : element.selectionEnd;
    const unselectedText = element.value.substring(0, unselectedTextFrom) + element.value.substring(unselectedTextTo);

    const nextVariable = findNextVariable(unselectedText);

    document.execCommand('insertText', false, `{{${nextVariable}}}`);
  }, []);

  const handleSelectEmojiV3 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cmRef: React.MutableRefObject<ReactCodeMirrorRef | null>, { native }: any) => {
      if (!cmRef || !cmRef.current) return;
      const { view } = cmRef.current;
      if (!view) return;
      const transaction = view.state.changeByRange((range: SelectionRange) => {
        const insert = native;
        const changes = {
          from: range.from,
          to: range.to,
          insert,
        };

        // If no text is selected, insert the emoji and set the cursor position after it
        if (range.from === range.to) {
          return {
            changes,
            range: EditorSelection.cursor(range.from + insert.length),
          };
        }
        // Calculate the new selection range
        const newSelectionFrom = range.from;
        const newSelectionTo = range.from + insert.length;

        return {
          changes,
          range: EditorSelection.range(newSelectionFrom, newSelectionTo),
        };
      });
      view.dispatch(transaction);
      view.focus();
    },
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectEmoji = useCallback(({ native }: any) => {
    if (shouldUseCodeMirror) {
      handleSelectEmojiV3(codeMirrorRef, { native });
      return;
    }
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    if (document.activeElement !== element) {
      element.focus();
    }

    document.execCommand('insertText', false, native);
  }, []);

  const handleFormatting = useCallback((format: string) => {
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    element.focus();

    if (element.selectionStart === null || element.selectionEnd === null) {
      return;
    }

    const selectedText = element.value.substring(element.selectionStart, element.selectionEnd);

    const { selectionStart: prevSelectionStart, selectionEnd: prevSelectionEnd } = element;
    document.execCommand('insertText', false, `${format}${selectedText}${format}`);
    if (!selectedText) {
      // move cursor between marks
      element.selectionStart -= format.length;
      element.selectionEnd -= format.length;
    } else {
      /// keep text selected
      element.selectionStart = prevSelectionStart + format.length;
      element.selectionEnd = prevSelectionEnd + format.length;
    }
  }, []);

  const handleFormattingV3 = useCallback((cmRef: React.MutableRefObject<ReactCodeMirrorRef | null>, format: string) => {
    if (!cmRef || !cmRef.current) return;
    const { view } = cmRef.current;
    if (!view) return;
    const transaction = view.state.changeByRange((range: SelectionRange) => {
      // If no text is selected, insert the formatting characters and set the cursor position between them
      if (range.from === range.to) {
        const changes = {
          from: range.from,
          to: range.to,
          insert: `${format}${format}`,
        };

        // Set the cursor position between the formatting characters
        const cursorPosition = range.from + format.length;

        return {
          changes,
          range: EditorSelection.cursor(cursorPosition),
        };
      }
      const selectedText = view.state.sliceDoc(range.from, range.to);
      let insert = '';
      if (
        selectedText &&
        selectedText.length >= 2 * format.length &&
        selectedText.substring(0, format.length) === format &&
        selectedText.substring(selectedText.length - format.length) === format
      ) {
        // remove format
        insert = `${selectedText.substring(format.length, selectedText.length - format.length)}`;
      } else {
        // add format
        insert = `${format}${selectedText}${format}`;
      }
      const changes = {
        from: range.from,
        to: range.to,
        insert,
      };
      // Calculate the new selection range
      const newSelectionFrom = range.from;
      const newSelectionTo = range.from + insert.length;

      return {
        changes,
        range: EditorSelection.range(newSelectionFrom, newSelectionTo),
      };
    });
    view.dispatch(transaction);
    view.focus();
  }, []);

  const handleBold = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '*');
      return;
    }
    handleFormatting('*');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleItalic = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '_');
      return;
    }
    handleFormatting('_');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleStrikethrough = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '~');
      return;
    }
    handleFormatting('~');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleMonospace = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '```');
      return;
    }
    handleFormatting('```');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let handled = false;
      switch (e.code) {
        case 'KeyI': {
          if (shouldUseCodeMirror) {
            // TODO italic shortcut for CodeMirror is jumping to beginning of editor
            break;
          }
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleItalic();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleItalic();
            handled = true;
          }
          break;
        }
        case 'KeyB': {
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleBold();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleBold();
            handled = true;
          }
          break;
        }
        default: {
          break;
        }
      }

      if (handled) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [shouldUseCodeMirror, handleBold, handleItalic],
  );

  return {
    shouldUseCodeMirror,
    activeVariableRef,
    addVariableRef,
    body: populatedBody,
    codeMirrorRef,
    error,
    handleBodyEditorChange,
    handleBodyInputChange,
    onAddVariable: handleAddVariable,
    onBold: handleBold,
    onItalic: handleItalic,
    onKeyDown: handleKeyDown,
    onMonospace: handleMonospace,
    onSelectEmoji: handleSelectEmoji,
    onStrikethrough: handleStrikethrough,
    onClick: handleClick,
    onClickOutside: handleClickOutside,
    flowVariableDialogRef,
    focused,
    shouldBeMinimized,
    handleGenerateResult,
    rephrasingActionText,
    isRephrazing: isLoading,
  };
};

export const TemplateBuilderBody: FC<{ minimized?: boolean }> = ({ minimized }) => {
  const {
    shouldUseCodeMirror,
    activeVariableRef,
    addVariableRef,
    body,
    codeMirrorRef,
    error,
    handleBodyEditorChange,
    handleBodyInputChange,
    onAddVariable,
    onBold,
    onItalic,
    onKeyDown,
    onMonospace,
    onSelectEmoji,
    onStrikethrough,
    flowVariableDialogRef,
    onClick,
    onClickOutside,
    shouldBeMinimized,
    handleGenerateResult,
    rephrasingActionText,
    isRephrazing,
  } = useTemplateBuilderBody({ minimized });
  const theme = useTheme();

  const hint =
    'The body of the message is where the main content will go, ' +
    'here you can insert emojis and variables to personalize your ' +
    'messages. Use short, concise and interesting messages.';

  return (
    <TemplateBuilderItem error={Boolean(error)} onClick={onClick} onClickOutside={onClickOutside}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          minHeight: '30px',
        }}
      >
        <TemplateBuilderTitle icon={<TextFieldsIcon color="action" />} hint={hint}>
          Message
        </TemplateBuilderTitle>

        {!!body && (
          <Button
            onClick={handleGenerateResult}
            startIcon={<SidebarSofiaIcon width={20} height={20} />}
            variant="text"
            sx={{
              fontSize: '12px!important',
              background: theme.palette.grey[100],
              borderRadius: 50,
              px: 1.5,
              py: 0.5,
              textTransform: 'none',
              pointerEvents: isRephrazing ? 'none' : 'auto',
              '&:hover': {
                background: theme.palette.grey[200],
              },
            }}
          >
            {rephrasingActionText}
          </Button>
        )}
      </Box>
      {shouldUseCodeMirror && (
        <Box sx={{ position: 'relative' }}>
          {isRephrazing && <BorderAnimation />}

          <CodeMirrorTextField
            isLoading={isRephrazing}
            value={body}
            error={Boolean(error)}
            codeMirrorUpdate={flowVariableDialogRef?.current?.handleCodeMirrorUpdate}
            editable
            multiline
            handleVariableClick={flowVariableDialogRef?.current?.handleVariableClick}
            setValue={handleBodyEditorChange}
            ref={codeMirrorRef}
            onKeyDown={onKeyDown}
            minimized={shouldBeMinimized}
            endAdornment={
              shouldBeMinimized ? null : (
                <InputAdornment
                  sx={{
                    color: body.length > BODY_MAX_LENGTH ? 'error.main' : 'text.secondary',
                    userSelect: 'none',
                    height: '1.5rem',
                    position: 'relative',
                  }}
                  disableTypography
                  disablePointerEvents
                  position="end"
                >
                  <Label variant="body2" sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                    {`${body.length}/${BODY_MAX_LENGTH}`}
                  </Label>
                </InputAdornment>
              )
            }
            placeholder="Enter text"
          />
          <FlowVariableDialog
            anchorEl={activeVariableRef?.current || addVariableRef?.current}
            codeMirrorRef={codeMirrorRef}
            value={body}
            handleEditorChange={handleBodyEditorChange}
            ref={flowVariableDialogRef}
          />
        </Box>
      )}
      {!shouldUseCodeMirror && (
        <TextField
          id="message-template-body"
          autoComplete="off"
          autoFocus
          placeholder="Enter text"
          error={Boolean(error)}
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          value={body}
          onChange={handleBodyInputChange}
          variant="outlined"
          inputProps={{
            maxLength: BODY_MAX_LENGTH * 2,
          }}
          InputProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              padding: 1.5,
              borderRadius: '10px',
            },
            endAdornment: (
              <InputAdornment
                sx={{
                  height: 'auto',
                  alignSelf: 'flex-end',
                  mt: 1.5,
                  color: body.length > BODY_MAX_LENGTH ? 'error.main' : 'text.secondary',
                  userSelect: 'none',
                }}
                disableTypography
                disablePointerEvents
                position="end"
              >
                <Label variant="body2">{`${body.length}/${BODY_MAX_LENGTH}`}</Label>
              </InputAdornment>
            ),
          }}
          onKeyDown={onKeyDown}
          sx={{ mt: 2 }}
        />
      )}
      {error && (
        <Label variant="body2" sx={{ color: 'error.main', mt: 1, ml: 1 }}>
          {error}
        </Label>
      )}
      {!shouldBeMinimized && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <AddVariable onClick={onAddVariable} ref={addVariableRef} />
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <Tooltip title={`Bold (${isAppleDevice() ? '⌘' : 'Ctrl'} + b)`}>
              <IconButton sx={{ p: 0.5 }} onClick={onBold}>
                <FormatBoldIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Italic${shouldUseCodeMirror ? '' : `(${isAppleDevice() ? '⌘' : 'Ctrl'} + i)`}`}>
              <IconButton sx={{ p: 0.5 }} onClick={onItalic}>
                <FormatItalicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Strikethrough">
              <IconButton sx={{ p: 0.5 }} onClick={onStrikethrough}>
                <FormatStrikethroughIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mono">
              <IconButton sx={{ p: 0.5 }} onClick={onMonospace}>
                <CodeIcon />
              </IconButton>
            </Tooltip>
            <EmojiPickerButton
              anchorOrigin={{
                vertical: -parseInt(theme.spacing(1), 10),
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              disabled={false}
              tooltip="Insert emoji"
              onSelect={onSelectEmoji}
            />
          </Box>
        </Box>
      )}
      {!shouldUseCodeMirror && (
        <HideableAlert title="What are variables?" storageKey="tooltip-template-body-variables" sx={{ mt: 3 }}>
          Variables are placeholders for dynamic content that help personalize your campaign, for example: customer
          names or coupon codes.
        </HideableAlert>
      )}
    </TemplateBuilderItem>
  );
};
