import { useCallback, useMemo, useState, useEffect } from 'react';
import { flatMap } from 'lodash';
import { SelectedMessageTemplate } from '../useMessageTemplateGroupsData/types';
import { Params } from './types';
import { PARAM_REGEX } from './constants';

// Prepares template parameters to be used with direct API calls from customers
export const useTemplateParams = (template: SelectedMessageTemplate | undefined) => {
  const [paramsHash, setParamsHash] = useState<Params>({});

  // reset values for params when template changes
  useEffect(() => {
    setParamsHash({});
  }, [template]);

  // params are denoted by {{parameter_name}} in the template body
  // denoted by { "name": "body_parameter_name", value": "example_value" } in the request body
  const templateBodyParams = useMemo(
    () =>
      template?.template?.templateComponents
        ?.find((x) => x.body)
        ?.body?.text?.text?.match(new RegExp(PARAM_REGEX, 'g'))
        ?.map((x) => `body_${x.replace(/{{|}}/g, '')}`) || [],
    [template],
  );

  const templateHeaderParams = useMemo(() => {
    const headerComp = template?.template?.templateComponents?.find((x) => x.header);
    if (!headerComp) return [];
    if (headerComp.header?.text?.text && PARAM_REGEX.test(headerComp.header?.text?.text)) {
      return ['header_text'];
    }
    if (headerComp.header?.media?.type === 'TYPE_IMAGE') {
      return ['header_image'];
    }
    if (headerComp.header?.media?.type === 'TYPE_DOCUMENT') {
      return ['header_document', 'header_document_filename'];
    }
    return [];
  }, [template]);

  // as a post processing step
  // header_document_filename parameter is merged into header_document parameter
  // they are initialized as two separate parameters
  // for convenience with api form builder
  //
  // from this -> {
  //                  "name": "header_document",
  //                  "value": "document url",
  //              },
  //              {
  //                  "name": "header_document_filename",
  //                  "value": "document label to display on whatsapp",
  //              }
  //
  // to this ->   {
  //                 "name": "header_document",
  //                 "value": "document url",
  //                 "filename": "document label to display on whatsapp"
  //              }
  //
  const mergeHeaderParams = useCallback(
    (params: { name: string; value: string }[]) =>
      params
        .map((param) => {
          if (param.name === 'header_document') {
            return {
              name: param.name,
              value: param.value,
              filename: params.find((x) => x.name === 'header_document_filename')?.value,
            };
          }
          return param;
        })
        .filter((param) => param.name !== 'header_document_filename'),
    [],
  );

  const templateButtonParams = useMemo(
    () =>
      template?.template?.templateComponents
        ?.filter((x) => x.button)
        ?.map((button, index) =>
          PARAM_REGEX.test(button?.button?.url?.url || '') ? `button_${index + 1}_url_suffix` : '',
        )
        ?.filter((x) => !!x) || [],
    [template],
  );

  // these are the parameters that are prefilled in the template
  // currently used for smart links feature of buttons
  // if tracked url also has a variable ignore prefilled params in the same button
  const prefilledParams = useMemo(
    () =>
      flatMap(
        template?.template?.templateComponents
          ?.filter((x) => x.button)
          ?.filter(
            (button) =>
              PARAM_REGEX.test(button?.button?.url?.url || '') &&
              !PARAM_REGEX.test(button?.button?.url?.trackedUrl || ''),
          )
          .map((button, index) => ({ buttonIndex: index, prefilled: button?.button?.url?.prefilledParams }))
          .map(
            ({ buttonIndex, prefilled }) =>
              prefilled
                ?.filter((x) => !!x?.value)
                .map((params) => ({ name: `button_${buttonIndex + 1}_url_suffix`, value: params?.value as string })) ||
              [],
          ),
      )?.filter((x) => !!x),
    [template],
  );

  const paramsKeys = useMemo(
    () =>
      [...templateBodyParams, ...templateHeaderParams, ...templateButtonParams]
        .filter((paramKey) => !prefilledParams.map((x) => x?.name).includes(paramKey))
        .sort(),
    [prefilledParams, templateBodyParams, templateHeaderParams, templateButtonParams],
  );

  const paramsJSON = useMemo(() => {
    const objects = [
      ...paramsKeys
        .filter((param) => !Object.keys(paramsHash).includes(param))
        .map((param) => ({
          name: param,
          value: 'CHANGE_TO_VALUE',
        })),
      ...Object.entries(paramsHash).map(([param, value]) => ({
        name: param,
        value: value || 'CHANGE_TO_VALUE',
      })),
      ...prefilledParams,
    ];
    return mergeHeaderParams(objects).sort((a, b) => a.name.localeCompare(b.name));
  }, [paramsKeys, mergeHeaderParams, paramsHash, prefilledParams]);

  const setParameter = useCallback(
    (name: string, value: string) => {
      setParamsHash((prev) => ({ ...prev, [name]: value }));
    },
    [setParamsHash],
  );

  return { paramsJSON, paramsKeys, paramsHash, setParameter };
};
