import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { MessageTemplateGroupsQuery, MessageTemplateGroupsResponse, MessageTemplateGroupsUrlParameters } from './types';

export const useMessageTemplateGroupsData = <TData = MessageTemplateGroupsResponse>({
  channelType = 'CHANNEL_TYPE_WHATSAPP_CLOUD',
  businessId = '',
  language,
  filterStatus,
  includeSystemTemplates,
  names,
  filterHasVariables,
  filterByTemplateIdOrExternalTemplateId,
  ...options
}: Omit<
  UseQueryOptions<
    MessageTemplateGroupsResponse,
    unknown,
    TData,
    readonly ['messageTemplateGroups', MessageTemplateGroupsUrlParameters, MessageTemplateGroupsQuery]
  >,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<MessageTemplateGroupsQuery> &
  MessageTemplateGroupsUrlParameters) => {
  const params: MessageTemplateGroupsQuery & MessageTemplateGroupsUrlParameters = {
    channelType,
    businessId,
    language,
    filterStatus,
    includeSystemTemplates,
    names,
    filterHasVariables,
  };

  if (filterByTemplateIdOrExternalTemplateId?.templateId) {
    params.filterByTemplateIdOrExternalTemplateId = { ...filterByTemplateIdOrExternalTemplateId };
  }

  return useQuery(
    [
      'messageTemplateGroups',
      { businessId },
      {
        channelType,
        language,
        filterStatus,
        includeSystemTemplates,
        names,
        filterHasVariables,
        filterByTemplateIdOrExternalTemplateId,
      },
    ],
    () =>
      api.profileV1('/v1/businesses/{businessId}/list/message_template_groups', 'post', {
        path: { businessId },
        body: { input: { ...params } },
      }),
    options,
  );
};
