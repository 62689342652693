import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './dataReducer';
export const staticStore = configureStore({
    reducer: dataReducer,
    devTools: {
        name: 'connectly-data-room',
    },
});
const storeProvider = () => staticStore;
export default storeProvider;
