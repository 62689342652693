import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Box,
  Collapse,
  Crop75Icon,
  LinkIcon,
  SelectChangeEvent,
  useTheme,
  useMediaQuery,
  Divider,
  Alert,
} from '@connectlyai-tenets/ui-styled-web';
import { useContextSelector } from 'use-context-selector';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { TemplateComponent, usePrevious, useFeatureFlag } from '@hooks';
import {
  selectButtons,
  selectButtonsError,
  selectButtonsGroup,
  selectButtonsUiState,
  selectCanChangeButtonsGroup,
  selectButtonMappings,
  selectIsButtonsCollectionEnabled,
  setButtons,
  setButtonsGroup,
  setIsComponentEnabled,
} from '../../state/messageTemplates';
import { ButtonType, TemplateBuilderButtonsProps } from './types';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { TemplateBuilderTitle } from '../TemplateBuilderTitle';
import { TemplateBuilderButtonGroupSelector } from '../TemplateBuilderButtonGroupSelector';
import { ButtonError, ButtonGroup, ButtonState } from '../../state/messageTemplates/types';
import {
  FOCUS_DELAY_MS,
  scrollToBottom,
  scrollToBottomAndSelectHTMLInputElement,
  scrollToHTMLInputElement,
} from '../../utils';
import { ButtonGroup as ButtonGroupComponent } from './ButtonGroup';
import { AddButton } from './AddButton';
import { QuickReply } from './QuickReply';
import { CallToAction } from './CallToAction';
import { FlowContext } from '../../contexts';

const linkTrackingDefault = true;

function getButtonType(component: TemplateComponent): ButtonType {
  const { button } = component;
  if (!button) {
    return 'UNKNOWN';
  }

  const { quickReply, url, phoneNumber } = button;
  if (phoneNumber) {
    return 'PHONE_NUMBER';
  }
  if (url) {
    return 'URL';
  }
  if (quickReply) {
    return 'QUICK_REPLY';
  }

  return 'UNKNOWN';
}

// Inserts button after the last button of the same type
function insertButton(buttons: TemplateComponent[], button: TemplateComponent): number {
  if (buttons.length === 0) {
    buttons.push(button);
    return buttons.indexOf(button);
  }

  if (button.button?.phoneNumber || button.button?.url) {
    let lastIndex = -1;
    buttons.forEach((x, i) => {
      lastIndex = x.button?.phoneNumber || x.button?.url ? i : lastIndex;
    });

    if (lastIndex === -1) {
      buttons.push(button);
    } else {
      buttons.splice(lastIndex + 1, 0, button);
    }
  }

  if (button.button?.quickReply) {
    let lastIndex = -1;
    buttons.forEach((x, i) => {
      lastIndex = x.button?.quickReply ? i : lastIndex;
    });
    if (lastIndex === -1) {
      buttons.push(button);
    } else {
      buttons.splice(lastIndex + 1, 0, button);
    }
  }

  return buttons.indexOf(button);
}

const useTemplateBuilderButtons = (_props: Partial<TemplateBuilderButtonsProps>) => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const enableNewTemplateButtons = getFlagByName('ffEnableNewTemplateButtons') || ffEnableETA;

  const dispatch = useDispatch();
  const buttons = useSelector(selectButtons, shallowEqual);
  const group = useSelector(selectButtonsGroup);
  const canChangeGroup = useSelector(selectCanChangeButtonsGroup);
  const error = useSelector(selectButtonsError, shallowEqual);
  const uiState = useSelector(selectButtonsUiState, shallowEqual);
  const buttonMappings = useSelector(selectButtonMappings);
  const isEnabled = useSelector(selectIsButtonsCollectionEnabled);
  const prevIsEnabled = usePrevious(isEnabled);

  const cleanAfterButtonIsDeleted = useContextSelector(
    FlowContext,
    (context) => context.flowChangeAppliers.cleanAfterButtonIsDeleted,
  );

  useEffect(() => {
    if (isEnabled && prevIsEnabled === false) {
      scrollToBottom('message-template-content');
    }
  }, [isEnabled, prevIsEnabled]);

  const handleAddButton = useCallback(
    (type: ButtonType) => {
      let button: TemplateComponent =
        type === 'QUICK_REPLY'
          ? { button: { quickReply: { text: '' } } }
          : { button: { url: { text: '', url: '', example: [] } } };
      if (type === 'PHONE_NUMBER') {
        button = { button: { phoneNumber: { text: '', phoneNumber: '' } } };
      }

      const newButtons = [...buttons];
      const index = insertButton(newButtons, button);

      const newError = error ? [...error] : undefined;
      newError?.splice(index, 0, {});

      const newUiState = [...uiState];
      newUiState.splice(index, 0, { tracked: linkTrackingDefault, url: '' });

      const newMappings: { [key: string]: string } = {};
      buttonMappings.forEach((val) => {
        const buttonParamRegex = /button_([0-9]+)(.*)/;
        const match = val.key.match(buttonParamRegex);
        if (!match) {
          return;
        }
        const buttonParamIdx = parseInt(match[1], 10);
        if (buttonParamIdx - 1 < index) {
          newMappings[val.key] = val.value;
        } else if (buttonParamIdx - 1 > index) {
          const newKey = `button_${buttonParamIdx + 1}${match[2]}`;
          newMappings[newKey] = val.value;
        }
        // ignore buttonIdx === index
      });
      const mappings = {
        deletePrefix: 'button_',
        new: newMappings,
      };

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState, mappings }));

      setTimeout(() => {
        scrollToHTMLInputElement(`message-template-button${index}-text`);
      }, FOCUS_DELAY_MS);
    },
    [buttons, dispatch, error, uiState, buttonMappings],
  );

  const handleDeleteButton = useCallback(
    (index: number) => {
      const newButtons = [...buttons];
      newButtons.splice(index, 1);

      const newError = error ? [...error] : undefined;
      newError?.splice(index, 1);

      const newUiState = [...uiState];
      newUiState?.splice(index, 1);

      const newMappings: { [key: string]: string } = {};
      buttonMappings.forEach((val) => {
        const buttonParamRegex = /button_([0-9]+)(.*)/;
        const match = val.key.match(buttonParamRegex);
        if (!match) {
          return;
        }
        const buttonParamIdx = parseInt(match[1], 10);
        if (buttonParamIdx - 1 < index) {
          newMappings[val.key] = val.value;
        } else if (buttonParamIdx - 1 > index) {
          const newKey = `button_${buttonParamIdx - 1}${match[2]}`;
          newMappings[newKey] = val.value;
        }
        // ignore buttonIdx === index
      });
      const mappings = {
        deletePrefix: 'button_',
        new: newMappings,
      };

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState, mappings }));

      cleanAfterButtonIsDeleted(index);
    },
    [buttons, cleanAfterButtonIsDeleted, dispatch, error, uiState, buttonMappings],
  );

  const handleChangeCallToActionType = useCallback(
    (_event: SelectChangeEvent, index: number) => {
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          return getButtonType(buttons[i]) === 'PHONE_NUMBER'
            ? { button: { url: { text: '', url: '', example: [] } } }
            : { button: { phoneNumber: { text: '', phoneNumber: '' } } };
        }

        return buttons[i];
      });

      const newUiState = uiState.map((x, i) => {
        if (i === index) {
          return { ...uiState[i], tracked: linkTrackingDefault, url: '' };
        }
        return uiState[i];
      });

      const mappings = {
        deletePrefix: 'button_',
        new: {},
      };

      dispatch(setButtons({ buttons: newButtons, error: undefined, uiState: newUiState, mappings }));
    },
    [buttons, dispatch, uiState],
  );

  const handleChangeQuickReplyText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const text = event.target.value;
      const newButtons = buttons.map((x, i) => {
        if (i === index) {
          const { button: currentButton } = buttons[i];
          if (currentButton?.quickReply) {
            return {
              ...buttons[i],
              button: { ...currentButton, quickReply: { ...currentButton.quickReply, text } },
            };
          }

          return buttons[i];
        }

        return buttons[i];
      });

      const newError = error?.[index]?.text
        ? error.map((x, i) => {
            if (i === index) {
              return { ...error[i], text: '' };
            }
            return error[i];
          })
        : error;

      const newUiState = uiState;

      dispatch(setButtons({ buttons: newButtons, error: newError, uiState: newUiState }));
    },
    [buttons, dispatch, error, uiState],
  );

  const handleChangeGroup = useCallback(
    (g: ButtonGroup) => {
      dispatch(setButtonsGroup(g));

      const newButtons = [
        g === ButtonGroup.CallToAction
          ? { button: { url: { text: '', url: '', example: [] } } }
          : { button: { quickReply: { text: '' } } },
      ];

      const newUiState = [{ tracked: linkTrackingDefault, url: '' }];

      const mappings = {
        deletePrefix: 'button_',
        new: {},
      };

      dispatch(setButtons({ buttons: newButtons, error: undefined, uiState: newUiState, mappings }));

      scrollToBottomAndSelectHTMLInputElement('message-template-content', `message-template-button0-text`);
    },
    [dispatch],
  );

  const handleEnabledChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
      dispatch(setIsComponentEnabled({ buttons: !isEnabled }));
    },
    [dispatch, isEnabled],
  );

  const handleReorder = useCallback(() => {
    const link: TemplateComponent[] = [];
    const linkUiState: Partial<ButtonState>[] = [];
    const linkError: Partial<ButtonError>[] = [];
    const quickReply: TemplateComponent[] = [];
    const quickReplyUiState: Partial<ButtonState>[] = [];
    const quickReplyError: Partial<ButtonError>[] = [];

    const firstQuickReply = Boolean(buttons[0]?.button?.quickReply);

    buttons.forEach((x, i) => {
      if (x.button?.quickReply) {
        quickReply.push(x);
        quickReplyUiState.push(uiState[i]);
        if (error) {
          quickReplyError.push(error[i]);
        }
      } else if (x.button?.phoneNumber || x.button?.url) {
        link.push(x);
        linkUiState.push(uiState[i]);
        if (error) {
          linkError.push(error[i]);
        }
      }
    });

    const newButtons = !firstQuickReply ? [...quickReply, ...link] : [...link, ...quickReply];

    let newError: Partial<ButtonError>[] | undefined = !firstQuickReply
      ? [...quickReplyError, ...linkError]
      : [...linkError, ...quickReplyError];
    if (!error) {
      newError = undefined;
    }

    const newUiState = !firstQuickReply
      ? [...quickReplyUiState, ...linkUiState]
      : [...linkUiState, ...quickReplyUiState];

    dispatch(
      setButtons({
        buttons: newButtons,
        error: newError,
        uiState: newUiState,
      }),
    );
  }, [buttons, dispatch, error, uiState]);

  return {
    enableNewTemplateButtons,
    buttons,
    canChangeGroup,
    group,
    error,
    uiState,
    isEnabled,
    onAddButton: handleAddButton,
    onChangeGroup: handleChangeGroup,
    onDeleteButton: handleDeleteButton,
    onEnabledChange: handleEnabledChange,
    onChangeCallToActionType: handleChangeCallToActionType,
    onReorder: handleReorder,
    handleChangeQuickReplyText,
  };
};

export const TemplateBuilderButtons: FC<TemplateBuilderButtonsProps> = (props) => {
  const {
    enableNewTemplateButtons,
    buttons,
    canChangeGroup,
    error,
    group,
    isEnabled,
    onAddButton,
    onChangeGroup,
    onDeleteButton,
    onEnabledChange,
    onReorder,
    uiState,
    handleChangeQuickReplyText,
  } = useTemplateBuilderButtons(props);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const isQuickReplyFirst = Boolean(buttons[0]?.button?.quickReply);
  const quickReplyList = buttons.filter((x) => x.button?.quickReply);
  const getQuickReplyComponent = useCallback(
    (x: TemplateComponent) => {
      const index = buttons.indexOf(x);
      // eslint-disable-next-line react/no-array-index-key
      return (
        <Box key={`qr_${index}`}>
          <QuickReply
            button={x}
            error={error}
            index={index}
            onChangeText={handleChangeQuickReplyText}
            onDelete={onDeleteButton}
          />
        </Box>
      );
    },
    [buttons, error, onDeleteButton, handleChangeQuickReplyText],
  );

  const callToActionList = buttons.filter((x) => x.button?.phoneNumber || x.button?.url);
  const getCallToActionComponent = useCallback(
    (x: TemplateComponent) => {
      const index = buttons.indexOf(x);
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`cta_${index}`}>
          <CallToAction button={x} error={error} index={index} onDelete={onDeleteButton} uiState={uiState} />
        </Box>
      );
    },
    [buttons, error, onDeleteButton, uiState],
  );

  const hint =
    enableNewTemplateButtons || group === ButtonGroup.QuickReply
      ? 'You can create up to 10 buttons. If you add more than 3 buttons, they will appear in a list.'
      : 'You can create up to 3 buttons.';

  const hasBothKindOfButtons = useMemo(
    () => quickReplyList.length > 0 && callToActionList.length > 0,
    [quickReplyList, callToActionList],
  );

  const isReorderEnabled = false;
  // change isReorderEnabled to below after bug, edges are not updated when buttons are reordered, is fixed
  // useMemo(() =>  hasBothKindOfButtons, [hasBothKindOfButtons]);

  return (
    <TemplateBuilderItem error={Boolean(error)}>
      <TemplateBuilderTitle
        icon={group === ButtonGroup.QuickReply ? <Crop75Icon color="action" /> : <LinkIcon color="action" />}
        optional={canChangeGroup ? { checked: isEnabled, onChange: onEnabledChange } : undefined}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {canChangeGroup ? 'Buttons' : group === ButtonGroup.QuickReply ? 'Buttons' : 'Links'}
      </TemplateBuilderTitle>
      <Collapse in={isEnabled} unmountOnExit>
        <Alert severity="info">{hint}</Alert>
        {hasBothKindOfButtons && <Divider sx={{ mt: 2 }} />}
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: isLargeScreen ? 3 : 2 }}>
          {canChangeGroup && (
            <TemplateBuilderButtonGroupSelector
              group={group}
              onChange={onChangeGroup}
              sx={{ mt: isLargeScreen ? 2 : 1 }}
            />
          )}
          {isQuickReplyFirst ? (
            <>
              <ButtonGroupComponent
                getItemComponent={getQuickReplyComponent}
                isReorderEnabled={isReorderEnabled}
                items={quickReplyList}
                label="Group 1: quick reply buttons"
                onReorder={onReorder}
              />
              {hasBothKindOfButtons && <Divider />}
              <ButtonGroupComponent
                getItemComponent={getCallToActionComponent}
                isReorderEnabled={isReorderEnabled}
                items={callToActionList}
                label="Group 2: link buttons"
                onReorder={onReorder}
              />
            </>
          ) : (
            <>
              <ButtonGroupComponent
                getItemComponent={getCallToActionComponent}
                isReorderEnabled={isReorderEnabled}
                items={callToActionList}
                label="Group 1: link buttons"
                onReorder={onReorder}
              />
              {hasBothKindOfButtons && <Divider />}
              <ButtonGroupComponent
                getItemComponent={getQuickReplyComponent}
                isReorderEnabled={isReorderEnabled}
                items={quickReplyList}
                label="Group 2: quick reply buttons"
                onReorder={onReorder}
              />
            </>
          )}
        </Box>
        {hasBothKindOfButtons && <Divider sx={{ mt: 2, mb: isLargeScreen ? 0 : 1 }} />}
        <AddButton group={group} buttons={buttons} onClick={onAddButton} />
      </Collapse>
    </TemplateBuilderItem>
  );
};
