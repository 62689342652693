import React, { useCallback } from 'react';
import { selectBusinessId, useQueryAlertsData, useMeData } from '@hooks';
import { Alert } from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { dismissedAlertsAtom } from '@atoms/app';
import { mapSeverityToAlertColor } from './mappers';

export const Alerts = () => {
  const [dismissedAlerts, setDismissedAlerts] = useAtom(dismissedAlertsAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: alerts } = useQueryAlertsData(
    {
      businessId: businessId || '',
    },
    {
      enabled: !!businessId,
    },
  );

  const handleCloseAlert = useCallback(
    (alertId: string) => {
      setDismissedAlerts([...dismissedAlerts, alertId]);
    },
    [dismissedAlerts, setDismissedAlerts],
  );
  const isAlertDismissed = useCallback((alertId: string) => dismissedAlerts.includes(alertId), [dismissedAlerts]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {alerts?.entity?.entries?.map((alert) => {
        const alertV1 = alert?.alert?.v1;
        if (!alertV1 || !alert.id || isAlertDismissed(alert.id)) return null;
        const alertColor = mapSeverityToAlertColor(alertV1.severity);

        return (
          <Alert
            title={alertV1.title || ''}
            severity={alertColor}
            variant="outlined"
            color={alertColor}
            onClose={() => handleCloseAlert(alert.id || '')}
            key={alert.id}
            sx={{ borderRadius: 0 }}
          >
            {alertV1?.message || ''}
          </Alert>
        );
      })}
    </>
  );
};
