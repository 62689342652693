import React, { useMemo, useCallback } from 'react';
import {
  Button,
  ConnectlyCard,
  Modal,
  Divider,
  EmojiEventsIcon,
  Label,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@hooks';
import { snakeCase } from 'lodash';
import { useAnalysisDataInRanges } from '../../hooks';
import { FrequencyAnalysisProps, UseFrequencyAnalysisProps } from './types';
import { TopicsOrWordsAnalysis } from '../../types';

const useFrequencyAnalysis = ({ name, selectData }: UseFrequencyAnalysisProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const { sendAnalytics } = useAnalytics();
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const { title, subtitle, showAllLabel } = useMemo(() => {
    return {
      title: t(`${name}.title`, name),
      subtitle: t(`${name}.subtitle`, ''),
      showAllLabel: t('showAllLabel', 'Show All'),
    };
  }, [name, t]);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const analyticsData = useAnalysisDataInRanges({});
  const rawData: TopicsOrWordsAnalysis = useMemo(() => {
    return selectData(analyticsData);
  }, [analyticsData, selectData]);
  const data = useMemo(() => {
    return rawData.list.map((entry) => {
      // add entries for countLabel, changeLabel and changeIcon
      const { change, count } = entry;
      let changeIcon: React.ReactNode = null;
      let changeLabel = '';
      const countLabel = t(`${name}.countLabel_${count === 1 ? 'one' : 'other'}`, {
        count,
        total: rawData.total,
        defaultValue: `${count} in ${rawData.total} sessions`,
      });
      const iconStyles = {
        width: theme.spacing(isLg ? 2.5 : 2),
        height: theme.spacing(isLg ? 2.5 : 2),
        color: theme.palette.grey[500],
        mr: 0.5,
      };
      if (change) {
        iconStyles.color = change > 0 ? theme.palette.success.main : theme.palette.error.main;
        changeIcon =
          // eslint-disable-next-line no-nested-ternary
          change === 0 ? '-' : change > 0 ? <TrendingUpIcon sx={iconStyles} /> : <TrendingDownIcon sx={iconStyles} />;
        const sign = change > 0 ? '+' : '';
        const changeRounded = Math.round(change);
        changeLabel = t(`${name}.changeLabel`, {
          change: changeRounded,
          sign,
          defaultValue: `${sign}${changeRounded}`,
        });
      } else if (change === 0) {
        changeLabel = t('noChange', 'no change');
      }
      return {
        ...entry,
        changeIcon,
        changeLabel,
        countLabel,
      };
    });
  }, [isLg, name, rawData.list, rawData.total, t, theme]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const handleShowModal = useCallback(() => {
    sendAnalytics(`analytics.sofia.${snakeCase(name)}.show_all_clicked`);
    setShowModal(true);
  }, [sendAnalytics, name]);
  const handleHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return {
    data,
    handleHideModal,
    handleShowModal,
    isMd,
    showAllLabel,
    showModal,
    subtitle,
    theme,
    title,
  };
};

type Data = {
  changeIcon: React.ReactNode;
  changeLabel: string;
  countLabel: string;
  name: string;
  count: number;
  change: number | undefined;
}[];

const Content = ({ data, isFullWidth }: { data: Data; isFullWidth: boolean }) => {
  const theme = useTheme();
  if (!isFullWidth) {
    data = data.slice(0, 3);
  }
  return (
    <Stack width="100%" divider={<Divider color={theme.palette.grey[300]} />}>
      {data.map(({ name: itemName, changeLabel, changeIcon, countLabel }, index) => {
        return (
          <Stack
            key={itemName}
            direction={isFullWidth ? 'row' : 'column'}
            alignItems={isFullWidth ? 'center' : 'flex-start'}
            py={1.5}
          >
            <Stack sx={{ flex: '1 1 auto' }}>
              <Label
                variant="body1"
                ml={0.5}
                sx={{ fontWeight: 'bold !important' }}
              >{`${index + 1}. ${itemName}`}</Label>
              <Label variant="body1" color="text.secondary">
                {countLabel}
              </Label>
            </Stack>
            <Stack direction="row" gap={0.5} sx={{ flex: isFullWidth ? '0 0 28%' : '1 1 auto' }}>
              {changeIcon}
              <Label variant="body1" color="text.secondary">
                {changeLabel}
              </Label>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export const FrequencyAnalysis = ({ isFullWidth, selectData, name }: FrequencyAnalysisProps) => {
  const { theme, title, subtitle, data, showModal, handleShowModal, handleHideModal, showAllLabel } =
    useFrequencyAnalysis({
      isFullWidth,
      selectData,
      name,
    });
  return (
    <>
      <Modal
        dialog={{ open: showModal, onClose: handleHideModal, maxWidth: 'xl' }}
        title={
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            <EmojiEventsIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />
            <Label variant="h6" sx={{ fontWeight: 'bold !important' }}>
              {title}
            </Label>
          </Stack>
        }
      >
        <Content data={data} isFullWidth />
      </Modal>
      <ConnectlyCard
        sx={{ flexDirection: 'column', userSelect: 'none', flex: '1 1 auto' }}
        icon={<EmojiEventsIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />}
        title={title}
        subtitle={subtitle}
        isNew
      >
        <Content data={data} isFullWidth={false} />
        {data.length > 3 && <Button onClick={handleShowModal}>{showAllLabel}</Button>}
      </ConnectlyCard>
    </>
  );
};
