import React from 'react';
import { Box, Button, ConnectlyCard, ErrorIcon, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import {
  selectAccountStatus,
  selectBusinessId,
  selectWAChannelId,
  useBusinessChannelMetaData,
  useMeData,
  useSettingsData,
} from '../../hooks';
import { META_SUPPORT_CONTACT } from './constants';

export const WhatsAppAccountStatus = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.accountStatus' });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: businessChannelId } = useSettingsData({
    businessId: businessId as string,
    select: selectWAChannelId,
    enabled: !!businessId,
  });
  const { data: accountStatusMetaData } = useBusinessChannelMetaData({
    businessId: businessId as string,
    businessChannelId: businessChannelId as string,
    enabled: !!businessId && !!businessChannelId,
    select: selectAccountStatus,
  });

  let statusMessage = null;
  switch (accountStatusMetaData?.phoneNumberStatus) {
    case 'WHATS_APP_PHONE_NUMBER_STATUS_DELETED':
      statusMessage = t('deleted');
      break;
    case 'WHATS_APP_PHONE_NUMBER_STATUS_BANNED':
      statusMessage = t('banned');
      break;
    case 'WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED':
      statusMessage = t('restricted');
      break;
    case 'WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED':
      statusMessage = t('rateLimited');
      break;
    case 'WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED':
      statusMessage = t('disconnected');
      break;
    default:
      break;
  }
  if (!statusMessage) {
    return null;
  }
  return (
    <ConnectlyCard
      icon={
        <ErrorIcon sx={{ color: theme.palette.error.light, width: theme.spacing(5.4), height: theme.spacing(5.4) }} />
      }
      title={t('notVerifiedTitle')}
    >
      <Label variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {statusMessage}
      </Label>
      <Box>
        <Button href={META_SUPPORT_CONTACT} target="_blank" variant="contained" color="primary">
          {t('contact')}
        </Button>
      </Box>
    </ConnectlyCard>
  );
};
