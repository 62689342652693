import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "models.experiment.dto";
function createBaseUserExperimentDtoCollection() {
    return { entries: [] };
}
export const UserExperimentDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            UserExperimentDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserExperimentDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(UserExperimentDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => UserExperimentDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? UserExperimentDto.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseUserExperimentDto() {
    return {
        id: "",
        qualifier: "",
        on: false,
        impressions: 0,
        cohort: undefined,
    };
}
export const UserExperimentDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.qualifier !== "") {
            writer.uint32(18).string(message.qualifier);
        }
        if (message.on === true) {
            writer.uint32(24).bool(message.on);
        }
        if (message.impressions !== 0) {
            writer.uint32(32).uint32(message.impressions);
        }
        if (message.cohort !== undefined) {
            StringValue.encode({ value: message.cohort }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserExperimentDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.qualifier = reader.string();
                    break;
                case 3:
                    message.on = reader.bool();
                    break;
                case 4:
                    message.impressions = reader.uint32();
                    break;
                case 5:
                    message.cohort = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            qualifier: isSet(object.qualifier) ? String(object.qualifier) : "",
            on: isSet(object.on) ? Boolean(object.on) : false,
            impressions: isSet(object.impressions) ? Number(object.impressions) : 0,
            cohort: isSet(object.cohort) ? String(object.cohort) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.qualifier !== undefined && (obj.qualifier = message.qualifier);
        message.on !== undefined && (obj.on = message.on);
        message.impressions !== undefined &&
            (obj.impressions = Math.round(message.impressions));
        message.cohort !== undefined && (obj.cohort = message.cohort);
        return obj;
    },
};
function createBaseIncrementMyExperimentImpressionRequestDto() {
    return { businessId: "", experimentId: "" };
}
export const IncrementMyExperimentImpressionRequestDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.experimentId !== "") {
            writer.uint32(18).string(message.experimentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIncrementMyExperimentImpressionRequestDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.experimentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            experimentId: isSet(object.experimentId)
                ? String(object.experimentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.experimentId !== undefined &&
            (obj.experimentId = message.experimentId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
