import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, CardMedia, CardHeader, CardActionArea, CardActions } from '@mui/material';
import { NewLabel } from '../NewLabel/NewLabel';
import { blueGrey } from '../../theme/colors';
import { Actions } from '../Actions';
import { WithSkeleton } from '../WithSkeleton';
export const ConnectlyCard = ({ actions, isLoading, children, contentSx = [], headerSx = [], action, media, icon, isNew, onClick, selected, subtitle, sx = [], title, p = 4, }) => {
    const hasHeader = icon || title || action;
    const actionsLeftPadded = icon && !children && actions && (actions.position === 'flex-start' || actions.position === 'start');
    const Content = (_jsxs(_Fragment, { children: [media && (_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsx(CardMedia, { ...media, sx: [
                        { px: p || undefined, pt: p || undefined, mb: 1 },
                        ...(Array.isArray(media.sx) ? media.sx : [media.sx]),
                    ] }) })), hasHeader && (_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsx(CardHeader, { avatar: icon, title: title, subheader: subtitle, action: action || (isNew ? _jsx(NewLabel, {}) : undefined), sx: [
                        { px: p, pt: media ? 0 : p, pb: children || actions ? 0 : p, mb: children ? 1 : 0 },
                        ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
                    ] }) })), children && (_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsx(CardContent, { sx: [
                        {
                            gap: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            px: p,
                            pt: media || hasHeader ? 0 : p,
                            pb: actions ? 0 : p,
                            '&:last-child': {
                                px: p,
                                pt: media || hasHeader ? 0 : p,
                                pb: actions ? 0 : p,
                            },
                        },
                        ...(Array.isArray(contentSx) ? contentSx : [contentSx]),
                    ], children: children }) }))] }));
    const variant = onClick ? 'action' : 'main';
    return (_jsxs(Card, { variant: variant, sx: [
            {
                userSelect: 'none',
                border: selected ? `1px solid ${blueGrey[500]}` : undefined,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: [onClick ? (_jsx(CardActionArea, { onClick: onClick, sx: { height: '100%', border: `1px solid ${blueGrey[200]}` }, children: Content })) : (Content), actions && Object.keys(actions).length > 0 && (_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsx(CardActions, { sx: { px: p, pl: icon && actionsLeftPadded ? (p || 0) + 4 : p, pb: p, pt: 2 }, children: _jsx(Actions, { ...actions }) }) }))] }));
};
