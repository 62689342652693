import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SignupResponse, SignupParams, SIGNUP_URL } from './types';

export const useSignup = (
  options?: Omit<
    UseMutationOptions<SignupResponse, ConnectlyError, SignupParams, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ ...input }: SignupParams) =>
      api.signupV1(SIGNUP_URL, 'post', {
        body: { input },
      }) as Promise<SignupResponse>,
    options,
  );
