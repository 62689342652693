import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DateRangePicker as UnstyledDateRangePicker,
  RangeKeyDict,
  useTheme,
  Box,
  CalendarTodayIcon,
  ArrowDropDownIcon,
  Label,
  Popover,
  useMediaQuery,
  Dialog,
} from '@connectlyai-tenets/ui-styled-web';
import i18next from 'i18next';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangeProps } from './types';
import { getLocale } from '../../utils/common';
import { getDatePickerLabelValue } from '../../utils/analytics';
import { useDateRange } from '../../hooks/useDateRange';

// Component to import styling react-date-range styling
export const DateRangePicker = ({
  dateRange,
  setDateRange,
  dateRangeList,
  minDate,
  maxDate,
  omitRangeAll = false,
}: DateRangeProps) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { defaultDateRangeList } = useDateRange(0, omitRangeAll);
  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseDialog = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleChangeDate = useCallback(
    ({ selection }: RangeKeyDict) => {
      if (selection.startDate && selection.endDate) {
        setDateRange({
          key: 'selection',
          startDate: startOfDay(selection.startDate),
          endDate: endOfDay(selection.endDate),
        });
      }
    },
    [setDateRange],
  );

  const DatePicker = useMemo(
    () => (
      <UnstyledDateRangePicker
        months={2}
        locale={getLocale(i18next.language)}
        weekdayDisplayFormat="EEEEE" // Format reference https://date-fns.org/docs/format
        direction="horizontal"
        moveRangeOnFirstSelection={false}
        ranges={[dateRange]}
        onChange={handleChangeDate}
        inputRanges={[]}
        minDate={minDate || new Date(2020, 0, 1)}
        maxDate={maxDate || endOfDay(new Date())}
        staticRanges={dateRangeList ?? defaultDateRangeList}
        rangeColors={[theme.palette.primary.main]}
        color={theme.palette.primary.main}
      />
    ),
    [minDate, maxDate, dateRange, dateRangeList, defaultDateRangeList, handleChangeDate, theme.palette.primary.main],
  );

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popoverId = open ? 'simple-popper' : undefined;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.spacing(0.5),
          px: 1,
          py: 1,
          gap: 1,
        }}
        onClick={handleOpenDialog}
      >
        <CalendarTodayIcon fontSize="small" />
        <Label variant="body1">{getDatePickerLabelValue(dateRange.startDate, dateRange.endDate)}</Label>
        <ArrowDropDownIcon fontSize="small" />
      </Box>
      {isLargeScreen ? (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseDialog}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {DatePicker}
        </Popover>
      ) : (
        <Dialog open={open} onClose={handleCloseDialog}>
          {DatePicker}
        </Dialog>
      )}
    </Box>
  );
};
