import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import {
  MUTATION_REMOVE_SOFIA_DOCUMENTS,
  MutationRemoveSofiaDocumentsParams,
  MutationRemoveSofiaDocumentsOptions,
} from './types';

export const useRemoveSofiaDocumentAssociation = (options?: MutationRemoveSofiaDocumentsOptions) => {
  return useMutation(
    [],
    (input: MutationRemoveSofiaDocumentsParams) =>
      api.sofiaV1(MUTATION_REMOVE_SOFIA_DOCUMENTS, 'post', {
        path: { 'input.businessId': input.businessId },
        body: { input: { assetId: input.assetId } },
      }),
    options,
  );
};
