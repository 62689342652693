import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
const initialState = {
    agents: [],
    tags: {
        ids: [],
        byId: {},
    },
};
function mutationSetTags(state, tags) {
    state.tags.ids = [];
    state.tags.byId = {};
    tags.forEach((tag) => {
        state.tags.ids.push(tag.id);
        state.tags.byId[tag.id] = tag;
    });
}
export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        reset: () => initialState,
        setAgents: (state, action) => {
            state.agents = action.payload;
        },
        setTags: (state, action) => {
            mutationSetTags(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
    },
});
export const { reset, setAgents, setTags } = businessSlice.actions;
export const selectAgents = (state) => state.businessSlice.agents;
export const selectTags = (state) => state.businessSlice.tags.byId;
export default businessSlice.reducer;
