import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Label,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useQueryClient } from '@tanstack/react-query';
import { PasswordInput } from '@components/PasswordInput';
import { NotificationContext } from '../../../../contexts';
import { useAddUserMutation } from '../../hooks/useAddUserMutation';
import { AddUserModalProps, NewUserData, Role, UseAddUserModalParam, UseAddUserModal } from './types';
import { validatePassword } from './utils';

const useAddUserModal = ({ businessId, closeModal }: UseAddUserModalParam): UseAddUserModal => {
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const [formData, setFormData] = useState<NewUserData>({
    email: '',
    password: '',
    role: 'ROLE_AGENT',
  });
  const [passwordError, setPasswordError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate: mutateAddUser } = useAddUserMutation({
    onError: (data) => {
      const error = data?.message || "Something wen't worong";
      setErrorMessage(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['agents']);
      setErrorMessage('');
      setPasswordError('');
      setSuccess(true);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'User Created',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });

  const onCancel = useCallback(() => {
    setFormData({
      email: '',
      password: '',
      role: 'ROLE_AGENT',
    });
    setErrorMessage('');
    setPasswordError('');
    closeModal();
  }, [closeModal, setFormData, setErrorMessage, setPasswordError]);

  const onClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (success && !errorMessage && !passwordError) {
      setSuccess(false);
      onCancel();
    }
  }, [success, errorMessage, passwordError, onCancel, setFormData]);

  const submitDisabled = useMemo(() => !(formData.email && formData.password), [formData.email, formData.password]);

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData],
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<Role>) => {
      const name = e.target.name || '';
      if (name) {
        setFormData({
          ...formData,
          [e.target.name || '']: e.target.value,
        });
      }
    },
    [formData],
  );

  const handleSubmit = useCallback(() => {
    const { email, password, role } = formData;
    const { isValid, message } = validatePassword(password);
    if (!isValid) {
      setPasswordError(message);
      return;
    }

    if (passwordError) {
      setPasswordError('');
    }
    const newUser = {
      businessId,
      email,
      password,
      displayName: email,
      username: email,
      roles: [role],
    };

    mutateAddUser(newUser);
  }, [mutateAddUser, businessId, formData, setPasswordError, passwordError]);

  return {
    handleSubmit,
    errorMessage,
    formData,
    handleTextChange,
    handleSelectChange,
    onClose,
    onCancel,
    passwordError,
    submitDisabled,
  };
};

export const AddUserModal = ({ businessId, isOpen, closeModal }: AddUserModalProps) => {
  const {
    handleTextChange,
    handleSelectChange,
    submitDisabled,
    formData,
    handleSubmit,
    errorMessage,
    passwordError,
    onClose,
    onCancel,
  }: UseAddUserModal = useAddUserModal({ businessId, closeModal });

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl">
      <Label variant="h6" fontWeight={500} sx={{ px: 3, pt: 3 }}>
        Add User
      </Label>
      <DialogContent sx={{ pt: 1, width: (theme) => theme.spacing(51), maxWidth: '100vw' }}>
        <TextField
          id="email-input"
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleTextChange}
        />
        <PasswordInput
          id="password-input"
          label="Password"
          name="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.password}
          onChange={handleTextChange}
        />
        {!passwordError && (
          <Label variant="body2" sx={{ color: (theme) => theme.palette.text.secondary, mb: 1 }}>
            Temporary Password. User can change it from Settings → General.
          </Label>
        )}
        {passwordError && (
          <Label variant="body2" color="error" sx={{ mb: 1 }}>
            {passwordError}
          </Label>
        )}
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel id="role-label">Role</InputLabel>
          <Select labelId="role-label" label="Role" name="role" value={formData.role} onChange={handleSelectChange}>
            <MenuItem value="ROLE_AGENT">Agent</MenuItem>
            <MenuItem value="ROLE_MARKETING">Marketing</MenuItem>
            <MenuItem value="ROLE_OWNER">Owner</MenuItem>
          </Select>
        </FormControl>
        {errorMessage && (
          <Label color="error" sx={{ mb: (theme) => theme.spacing(1) }}>
            {errorMessage}
          </Label>
        )}
        <Box sx={{ textAlign: 'right', pt: 2 }}>
          <Button variant="text" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="text" color="primary" disabled={submitDisabled} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
