import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import {
  MUTATION_DELETE_TAG_URL,
  MutationDeleteTagParameters,
  MutationDeleteTagOptions,
  MutationDeleteTagResponse,
} from './types';

export const useMutationDeleteTag = (options?: MutationDeleteTagOptions) => {
  return useMutation(
    [],
    (input: MutationDeleteTagParameters) =>
      api.profileV1(MUTATION_DELETE_TAG_URL, 'delete', {
        path: input,
      }) as Promise<MutationDeleteTagResponse>,
    options,
  );
};
