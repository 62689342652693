import React from 'react';
import {
  Alert,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Label,
  Link,
  useTheme,
  ConnectlyCard,
} from '@connectlyai-tenets/ui-styled-web';
import { useWhatsappAPIKey } from '@scenes/Settings/hooks/useWhatsappAPIKey';
import { businessKeyDialogOpenForAtom, isKeysVisibleAtom } from '@atoms/settings';
import { useAtomValue } from 'jotai';

export const WhatsappAPIKey = () => {
  const isKeysVisible = useAtomValue(isKeysVisibleAtom);
  const businessKeyDialogOpenFor = useAtomValue(businessKeyDialogOpenForAtom);
  const theme = useTheme();
  const {
    handleCopyKey,
    handleRevealKeysButton,
    handleDialogAgree,
    handleSetDialogOpenFor,
    handleDialogCancel,
    keys,
    isOwner,
  } = useWhatsappAPIKey();
  let boxGenerateApiKey;
  if (isOwner) {
    boxGenerateApiKey = (
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          gap: theme.spacing(2),
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{ px: 3, display: isKeysVisible ? 'none' : 'block' }}
          onClick={handleRevealKeysButton}
        >
          Generate API Key
        </Button>
      </Box>
    );
  } else {
    boxGenerateApiKey = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          alignItems: 'flex-start',
          boxSizing: 'border-box',
          borderRadius: '10px',
          background: theme.palette.common.white,
          gap: theme.spacing(2),
        }}
      >
        <Label variant="body2" color="error">
          You are not allowed to generate keys. Please contact your admin or Connectly support.
        </Label>
      </Box>
    );
  }
  return (
    <ConnectlyCard title="API Key and Webhook Secret">
      <Label variant="body1">
        In order to send WhatsApp messages with our developer API, you will need an API key and business ID. You can
        also subscribe to our Webhook API for delivery status information and error messages. See{' '}
        <Link sx={{ textDecoration: 'none' }} href="https://docs.connectly.ai/quick-start">
          here
        </Link>{' '}
        for detailed information.
      </Label>

      {isKeysVisible && (
        <>
          <Alert severity="warning">
            Your generated key will be shown only once. It is important that you keep track of your key and do not lose
            it, as you will not be able to retrieve it once it is generated. Please keep it secure and do not share it
            with others.
          </Alert>
          <Box
            sx={{
              display: 'flex',
              width: 1,
              gap: theme.spacing(2),
            }}
          >
            {keys.map((key) => (
              <Box
                key={key.type}
                sx={{
                  alignItems: 'center',
                  background: theme.palette.secondary.light,
                  borderRadius: theme.spacing(1),
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  justifyContent: 'flex-start',
                  padding: theme.spacing(2),
                  width: 1,
                }}
              >
                <Box
                  sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flex: '0 1 auto',
                    justifyContent: 'space-between',
                    width: 1,
                  }}
                >
                  <Label variant="body2">{key.type} </Label>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableRipple
                    sx={{
                      display: key.key ? 'flex' : 'none',
                      padding: 0,
                    }}
                    onClick={() => handleCopyKey(key)}
                  >
                    <Label variant="body2">COPY TEXT</Label>
                  </Button>
                </Box>
                <Box
                  sx={{
                    alignItems: key.key ? 'start' : 'center',
                    justifyContent: key.key ? 'start' : 'center',
                    background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.spacing(1),
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                    mt: 1,
                    p: 1.5,
                    width: 1,
                  }}
                >
                  <Label
                    sx={{
                      borderRadius: '10px',
                      wordBreak: 'break-word',
                      cursor: 'text',
                      display: key.key ? 'flex' : 'none',
                    }}
                    variant="body2"
                  >
                    {key.key}
                  </Label>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ px: 3, display: key.key ? 'none' : 'block', background: 'white' }}
                    onClick={() => handleSetDialogOpenFor(key)}
                  >
                    Create {key.type}
                  </Button>
                </Box>

                <Dialog
                  open={businessKeyDialogOpenFor === key}
                  onClose={() => handleDialogCancel()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Create {key.type}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Alert severity="warning">
                        Are you sure you want to create a new {key.type}? Your existing {key.type} (if you have one)
                        will be overwritten and become invalid immediately.
                      </Alert>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleDialogCancel()}>Cancel</Button>
                    <Button onClick={() => handleDialogAgree(key)} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ))}
          </Box>
        </>
      )}

      {boxGenerateApiKey}
    </ConnectlyCard>
  );
};
