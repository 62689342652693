import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { BroadcastDIContext } from '../../context';
import { selectMessageTemplateGroups } from '../../state/messageTemplateGroupSlice';
import { tryQueryMessageTemplateGroups } from '../../state/thunk';
import { mapMessageTemplateGroupsToViewModel } from '../common/presenter';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectLoadingState } from '../../state/messageTemplatesSlice';
import { setBusinessChannels } from '../../state/businessSlice';
export const BroadcastMessageTemplatesComponentId = 'b12c6bb6-8a1e-11ec-a8a3-0242ac120002';
const BroadcastMessageTemplates = (_props) => {
    const di = useContext(BroadcastDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        if (di.config.businessId) {
            dispatch(tryQueryMessageTemplateGroups({ di }));
        }
    }, [di.config.businessId]);
    useEffect(() => {
        if (di.config.businessId === '') {
            return undefined;
        }
        const sub = di
            .subscriptionBusinessProvider()(di.config.businessId)
            .subscribe((payload) => {
            dispatch(setBusinessChannels(payload.channels));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.businessId, di.subscriptionBusinessProvider]);
    const loadingState = useFeatureSelector(selectLoadingState);
    const templateGroups = mapMessageTemplateGroupsToViewModel(useFeatureSelector(selectMessageTemplateGroups));
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastMessageTemplatesComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { loadingState: loadingState, templateGroups: templateGroups }) });
};
export default BroadcastMessageTemplates;
