import React, { FC, useCallback, useEffect, useMemo, useRef, DragEvent } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import ReactFlow, { Background, Controls, MarkerType, useKeyPress } from 'react-flow-renderer';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, CloseIcon, IconButton, useTheme, CircularProgress } from '@connectlyai-tenets/ui-styled-web';
import {
  campaignLanguageAtom,
  campaignStepAtom,
  compileResultAtom,
  customSendoutNodeAtom,
  edgesAtom,
  exitFlowModalOpenAtom,
  isAIGeneratingAtom,
  isDocumentEditableAtom,
  isFlowPopulatedAtom,
  nodesAtom,
  reactFlowInstanceAtom,
  savedCampaignNameAtom,
  isFlowChecksOpenAtom,
  starterAtom,
  audienceAtom,
  documentIdAtom,
} from '@atoms/flow';
import * as flowAtoms from '@atoms/flow';
import { CompileReactFlowDocumentResult } from '@hooks/useRequestCompileReactFlowDocument/types';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import {
  FlowChartSidebarAICampaign,
  FlowChartSidebarPlaceholder,
  FlowChartConclusionDialog,
  CampaignAPIDialog,
} from '@components';
import { FlowContext } from '../../contexts';
import {
  FlowHandlers,
  selectBusinessId,
  useCreateMessageTemplateResultData,
  useFlowNavigator,
  useMeData,
  useAtomEffect,
} from '../../hooks';
import { useFlowHandlers } from './hooks/useFlowHandlers';
import { useCampaignFlowChangeAppliers } from './hooks/useCampaignFlowChangeAppliers';
import { FlowChartCustomEdge } from '../FlowChartCustomEdge/FlowChartCustomEdge';
import { FlowChartHeader } from '../FlowChartHeader/FlowChartHeader';
import { CampaignDevTools } from './components/CampaignDevTools/CampaignDevTools';
import { TemplateApprovalDialog } from './components/TemplateApprovalDialog';
import { useChangesQueue } from './hooks/useChangesQueue';
import { CampaignName } from './components/CampaignName';
import { FlowChecks } from './components/FlowChecks';
import { NodeEditor } from './components/NodeEditor';
import { Sidebar } from './components/Sidebar';
import { AudienceOptionType } from './components/ChooseAudience';
import { useCampaignStep } from './hooks/useCampaignStep';
import { useCampaignV3 } from './hooks/useCampaignV3';
import { approveConnectlyTemplates } from '../../state/messageTemplates/actions';
import { shouldShowError, isDiagnosticApprovedTemplate } from './mappers';
import { useSendAtomChangesToDatadog } from '../../hooks/useSendAtomChangesToDatadog';
import { useSendSliceChangesToDatadog } from '../../hooks/useSendSliceChangesToDatadog';
import { selectTemplatesSlice } from '../../state/messageTemplates';
import { ExitFlowModal } from './components/ExitFlowModal';
import { useNodeTypes } from './hooks/useNodeTypes/useNodeTypes';
import { useCampaignFlowEdges } from './hooks/useCampaignFlowEdges';
import { useCreateCampaign } from './hooks/useCreateCampaign';
import { SetupSofiaAlert } from './components/SetupSofiaAlert';

const isDebuggerEnabled = getFlagByName('ffEnableDebugFlow');

export const FlowChartCampaignV3: FC = () => {
  const [reactFlowInstance, setReactFlowInstance] = useAtom(reactFlowInstanceAtom);
  const setExitFlowModalOpen = useSetAtom(exitFlowModalOpenAtom);
  const setIsFlowChecksOpen = useSetAtom(isFlowChecksOpenAtom);
  const campaignStep = useAtomValue(campaignStepAtom);
  const customSendoutNode = useAtomValue(customSendoutNodeAtom);
  const edges = useAtomValue(edgesAtom);
  const isAIGenerating = useAtomValue(isAIGeneratingAtom);
  const isFlowPopulated = useAtomValue(isFlowPopulatedAtom);
  const isDocumentEditable = useAtomValue(isDocumentEditableAtom);
  const nodes = useAtomValue(nodesAtom);
  const savedCampaignName = useAtomValue(savedCampaignNameAtom);
  const starter = useAtomValue(starterAtom);
  const audience = useAtomValue(audienceAtom);
  const documentId = useAtomValue(documentIdAtom);

  const showAPIDialogInConclusion = audience.type === AudienceOptionType.API && campaignStep === 'conclusion';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useSendAtomChangesToDatadog(flowAtoms as Record<string, any>);
  useSendSliceChangesToDatadog(selectTemplatesSlice);
  const onCloseFlow = useCallback(() => setExitFlowModalOpen(true), [setExitFlowModalOpen]);

  const { isNodePotentialTarget } = useCampaignFlowEdges();
  const { flowDocumentIdRouteMatch, navigateToCampaignsList } = useFlowNavigator();

  const closeAPIDialog = useCallback(() => {
    navigateToCampaignsList();
  }, [navigateToCampaignsList]);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { handleCompile, isRequestCompileLoading, isRequestCompileTimedout, revisionId } = useCampaignV3();
  useEffect(() => {
    if (campaignStep === 'compile' && revisionId && !isRequestCompileLoading) {
      handleCompile();
    }
    // Prevent infinite number of requests by not including isRequestCompileLoading in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStep, handleCompile, isRequestCompileTimedout, revisionId]);

  const { goBackToPreface, goToNextCampaignStep, goToPreviousCampaignStep } = useCampaignStep();
  useEffect(() => {
    if (campaignStep === 'compile' && isRequestCompileTimedout) {
      goToPreviousCampaignStep();
    }
  }, [campaignStep, goToPreviousCampaignStep, isRequestCompileTimedout]);

  const { createOrUpdateFlowHookReturns, updateCampaignName } = useCreateCampaign();

  const campaignNameProps = useMemo(
    () => ({
      isNameUpdateError: createOrUpdateFlowHookReturns.isError,
      isNameUpdateLoading: createOrUpdateFlowHookReturns.isLoading,
      isNameUpdateSuccess: createOrUpdateFlowHookReturns.isSuccess,
      resetNameUpdate: createOrUpdateFlowHookReturns.reset,
      updateCampaignName,
    }),
    [createOrUpdateFlowHookReturns, updateCampaignName],
  );

  const flowChangeAppliers = useCampaignFlowChangeAppliers();
  const { onEdgesChangeProtected, onNodesChangeProtected, onNodeObjectChange } = flowChangeAppliers;
  const flowHandlers: FlowHandlers = useFlowHandlers({ flowChangeAppliers: useCampaignFlowChangeAppliers });

  const onLanguageChange = useCallback(
    (language: string) => {
      if (!customSendoutNode) return;
      if (customSendoutNode.data?.v3?.language === language) return;
      const updatedNode = produce(customSendoutNode, (draft) => {
        draft.data.v3.language = language;
        return draft;
      });
      onNodeObjectChange([{ id: updatedNode.id, item: updatedNode }]);
    },
    [customSendoutNode, onNodeObjectChange],
  );
  useAtomEffect(campaignLanguageAtom, onLanguageChange);

  const flowDeps = useMemo(
    () => ({
      flowChangeAppliers,
      flowHandlers,
      isDocumentEditable,
      isNodePotentialTarget,
      supportsLinkTracking: true,
      supportsVariables: true,
    }),
    [flowChangeAppliers, flowHandlers, isNodePotentialTarget, isDocumentEditable],
  );

  useCreateMessageTemplateResultData();

  useChangesQueue({
    isEnabled: !!savedCampaignName && !isAIGenerating,
  });

  const dispatch = useDispatch();
  const onCompileResultChange = useCallback(
    (compileResult: CompileReactFlowDocumentResult) => {
      if (campaignStep !== 'compile' || Object.keys(compileResult).length === 0) return;
      const { items = [] } = compileResult;

      if (compileResult?.status === 'MUTATION_STATUS_FAILURE') {
        if (items.some((item) => shouldShowError(item))) {
          setIsFlowChecksOpen(true);
          goToPreviousCampaignStep();
          return;
        }
      }

      const approvedNodeIds = items.filter(isDiagnosticApprovedTemplate).map((x) => x.id as string);

      dispatch(approveConnectlyTemplates({ nodeIds: approvedNodeIds }));

      goToNextCampaignStep();
    },
    [campaignStep, dispatch, goToNextCampaignStep, goToPreviousCampaignStep, setIsFlowChecksOpen],
  );
  useAtomEffect(compileResultAtom, onCompileResultChange);

  const edgeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SIMPLE_EDGE: FlowChartCustomEdge,
    }),
    [],
  );

  const flowRef = useRef<HTMLDivElement>(null);

  // Handle dropping of a node from sidebar into the flow document
  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      if (!flowRef.current || !reactFlowInstance) return;
      flowHandlers.handleDrop(e, reactFlowInstance, flowRef.current);
    },
    [flowHandlers, reactFlowInstance],
  );

  // When delete key is pressed delete all selected nodes
  const deletePressed = useKeyPress(['Delete', 'Backspace']);
  useEffect(() => {
    if (deletePressed) {
      const selectedElements = nodes.filter((node) => node.selected);
      flowHandlers.handleNodesDelete(selectedElements);
    }
  }, [deletePressed, flowHandlers, flowHandlers.handleNodesDelete, nodes]);

  const theme = useTheme();

  const nodeTypes = useNodeTypes();
  const defaultEdgeOptions = useMemo(
    () => ({
      markerEnd: { type: MarkerType.ArrowClosed, color: theme.palette.connectionLine },
      style: { stroke: theme.palette.connectionLine, strokeWidth: 2, fill: 'none' },
      type: 'FLOW_OBJECT_TYPE_SIMPLE_EDGE',
    }),
    [theme.palette.connectionLine],
  );

  const reactFlowProps = {
    defaultEdgeOptions,
    deleteKeyCode: null,
    edges,
    edgeTypes,
    minZoom: 0.1,
    maxZoom: 2.5,
    selectionKeyCode: null,
    multiSelectionKeyCode: null,
    nodes,
    nodeTypes,
    onConnect: flowHandlers.handleConnect,
    onDragOver: flowHandlers.handleDragOver,
    onEdgesChange: onEdgesChangeProtected,
    onEdgeUpdate: flowHandlers.handleEdgeUpdate,
    onEdgeUpdateEnd: flowHandlers.handleEdgeUpdateEnd,
    onEdgeUpdateStart: flowHandlers.handleEdgeUpdateStart,
    onInit: setReactFlowInstance,
    onNodesChange: onNodesChangeProtected,
    proOptions: { account: 'paid-custom', hideAttribution: true },
    selectNodesOnDrag: false,
    snapToGrid: true,
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <ExitFlowModal goBackToPreface={goBackToPreface} />
      <FlowContext.Provider value={flowDeps}>
        <FlowChartHeader>
          <CampaignName {...campaignNameProps} />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton
              aria-label="close"
              component={Link}
              to={flowDocumentIdRouteMatch ? '/campaigns/' : `/flow/`}
              onClick={onCloseFlow}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {isDebuggerEnabled && <CampaignDevTools />}
        </FlowChartHeader>
        {(!businessId || !isFlowPopulated) && (
          <CircularProgress sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />
        )}
        {businessId && isFlowPopulated && (
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              background: theme.palette.flowGradient,
              overflow: 'hidden',
            }}
          >
            <TemplateApprovalDialog selectNode={flowChangeAppliers.selectNode} allowCancel={starter !== 'resend'} />
            {showAPIDialogInConclusion && (
              <CampaignAPIDialog
                open={campaignStep === 'conclusion'}
                flowDocumentId={documentId}
                onClose={closeAPIDialog}
              />
            )}

            {audience.type !== AudienceOptionType.API && (
              <FlowChartConclusionDialog
                campaignDate=""
                open={campaignStep === 'conclusion'}
                onStartOver={navigateToCampaignsList}
                // TODO: change it back to submitProgress={submitProgress} when backend is fixed
                submitProgress={{ total: 100, completed: 100 }}
                // TODO: change it back to isSubmitSuccess={isSubmitSuccess} when backend is fixed
                isSubmitSuccess={campaignStep === 'conclusion'}
              />
            )}

            {showAPIDialogInConclusion ? null : (
              <FlowChartSidebarPlaceholder>
                <Sidebar />
                <FlowChartSidebarAICampaign />
                <NodeEditor />
                <FlowChecks />
              </FlowChartSidebarPlaceholder>
            )}

            <Box ref={flowRef} sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <SetupSofiaAlert />
              <ReactFlow {...reactFlowProps} onDrop={handleDrop}>
                <Background />
                <Controls showInteractive={false} />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: theme.palette.grey[500],
                    fontWeight: 700,
                    fontSize: '10px',
                  }}
                >
                  v3
                </Box>
              </ReactFlow>
            </Box>
          </Box>
        )}
      </FlowContext.Provider>
    </Box>
  );
};
