import React, { useCallback } from 'react';
import {
  Alert,
  Box,
  Button,
  ConnectlyDeleteIcon,
  Stack,
  TextField,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import { usePartialAgentDescriptor } from './usePartialAgentDescriptor';

export const IncludedURLs = () => {
  const {
    value: urls,
    setValue: setUrls,
    onSave,
  } = usePartialAgentDescriptor<string[]>({
    keyPath: 'webchatClientProperties.includedPaths',
    defaultValue: [''],
  });

  const addUrl = useCallback(() => {
    setUrls((list) => [...list, '']);
  }, [setUrls]);

  const removeUrl = useCallback(
    (index: number) => {
      setUrls((list) => list.filter((_, i) => i !== index));
    },
    [setUrls],
  );

  const updateUrl = useCallback(
    (index: number, value: string) => {
      setUrls((list) => list.map((url, i) => (i === index ? value : url)));
    },
    [setUrls],
  );

  return (
    <SaveActionCard
      onSubmit={onSave}
      title="Included URLs"
      subtitle="Add the URLs that you want Sofia to be sharing with your customers."
    >
      <Alert severity="info" sx={{ mt: 1.5 }}>
        Add the URLs that you want widget to be displayed with your customers. Use exact url or regex format for example
        /test to include only /test page or \/product\/.* to include all pages under /product/ path.
      </Alert>
      {urls.map((url, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack direction="row" spacing={2} key={index} alignItems="center">
          <TextField
            value={url}
            onChange={(e) => updateUrl(index, e.target.value)}
            fullWidth
            placeholder="Enter url to allow"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={{
              mt: 1.5,
              '& .MuiInputBase-root': { borderRadius: '10px' },
              '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
            }}
          />
          {urls.length > 1 && (
            <Box onClick={() => removeUrl(index)}>
              <ConnectlyDeleteIcon />
            </Box>
          )}
        </Stack>
      ))}
      <Stack direction="row" spacing={3} mt={1}>
        <Button variant="outlined" color="secondary" sx={{ mt: 1.5 }} onClick={addUrl}>
          Add new URL
        </Button>
      </Stack>
    </SaveActionCard>
  );
};
