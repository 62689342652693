import React, { useMemo, useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { showUploadModalAtom } from '@atoms/audience';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Label,
  useTheme,
  IconButton,
  CloseIcon,
  Alert,
} from '@connectlyai-tenets/ui-styled-web';
import { useMeData, selectBusinessId, useAnalytics } from '@hooks';
import { useTranslation } from 'react-i18next';
import { DropZone } from '@components';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { SIZE_CONTENT_L, SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import {
  UPLOAD_CHANNEL_TYPE,
  UPLOAD_SOURCE_TYPE,
  ACCEPTED_FILE_TYPES,
  SAMPLE_PHONE_NUMBERS,
  OptOutChannelType,
  OptOutSourceType,
} from './constants';
import { downloadCsv } from '../DownloadOptOut/utils';
import { useMutationAddOptOut } from '../../hooks/useMutationAddOptOut';
import { NotificationContext } from '../../../../contexts';

const useUploadOptOutModal = () => {
  const [uploadErrorText, setUploadErrorText] = useState<string>('');
  const [isOpen, setOpen] = useAtom(showUploadModalAtom);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const { t } = useTranslation('translation', { keyPrefix: 'audience.optOut' });
  const copy = useMemo(() => {
    return {
      title: t('uploadModalTitle'),
      info: t('uploadModalInfo'),
      sampleText: t('uploadModalSampleText'),
      failedText: t('uploadFailed'),
    };
  }, [t]);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const onUploadError = useCallback(
    ({ message }: { message: string }) => {
      setUploadErrorText(message);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message,
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    [notificationProvider],
  );
  const { sendAnalytics } = useAnalytics();
  const { mutate: mutateAddOptOut, isSuccess } = useMutationAddOptOut({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['opt-out-data', businessId] });
      sendAnalytics('(audience) upload opt-out list', { businessId });
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully updated opt-out list',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      onUploadError({ message: copy.failedText });
    },
  });
  const handleUploadFile = useCallback(
    async (file: File) => {
      if (!businessId) {
        onUploadError({ message: copy.failedText });
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8'); // Read the file as text
        reader.onload = (evt) => {
          if (!evt?.target?.result) {
            onUploadError({ message: copy.failedText });
            return;
          }
          const content = String(evt.target.result);
          // Process the CSV content
          // Grab the first column of each row and filter out non-numbers
          const optOutList = content
            .split('\n')
            .map((row) => row.split(',')[0].replace(/\D/g, ''))
            // eslint-disable-next-line no-restricted-globals
            .filter((item) => item && !isNaN(Number(item)))
            .map((item) => ({
              externalId: item,
              channelType: UPLOAD_CHANNEL_TYPE as OptOutChannelType,
              sourceType: UPLOAD_SOURCE_TYPE as OptOutSourceType,
            }));
          setUploadErrorText('');
          mutateAddOptOut({ businessId, optOutList });
        };
        reader.onerror = () => {
          onUploadError({ message: copy.failedText });
        };
      }
    },
    [businessId, copy.failedText, mutateAddOptOut, onUploadError],
  );
  const handleDownload = useCallback(() => {
    downloadCsv(SAMPLE_PHONE_NUMBERS, 'sample.csv');
  }, []);
  const theme = useTheme();

  return {
    isOpen,
    onClose,
    copy,
    handleUploadFile,
    handleDownload,
    theme,
    isUploadSuccess: isSuccess,
    uploadErrorText,
  };
};

export const UploadOptOutModal = () => {
  const { isOpen, onClose, copy, handleUploadFile, handleDownload, theme, isUploadSuccess, uploadErrorText } =
    useUploadOptOutModal();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <IconButton
        size="small"
        onClick={onClose}
        sx={{
          width: theme.spacing(4),
          height: theme.spacing(4),
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(2),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Label variant="h6" sx={{ mb: SIZE_SPACING_INTER_COMPONENT }}>
          {copy.title}
        </Label>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: theme.spacing(SIZE_CONTENT_L) }}>
        <Alert severity="info" sx={{ mb: SIZE_SPACING_INTER_COMPONENT }}>
          {copy.info}
          <Button variant="contained" color="secondary" onClick={handleDownload}>
            {copy.sampleText}
          </Button>
        </Alert>
        <DropZone
          autoFocus
          handleUploadFile={handleUploadFile}
          sx={{ height: theme.spacing(20) }}
          isSuccess={isUploadSuccess}
          errorHelperText={uploadErrorText}
          acceptedFileTypes={ACCEPTED_FILE_TYPES}
          acceptedFileTypesText={`Accepted file types: ${ACCEPTED_FILE_TYPES.join(', ')}`}
        />
      </DialogContent>
    </Dialog>
  );
};

export const UploadOptOutButton = () => {
  const setOpen = useSetAtom(showUploadModalAtom);
  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const { t } = useTranslation('translation', { keyPrefix: 'audience.optOut' });
  const text = useMemo(() => t('uploadButtonText'), [t]);
  return (
    <Button variant="outlined" color="secondary" onClick={onClick}>
      {text}
    </Button>
  );
};
