import React, { FC } from 'react';
import { Label, EmptyCanvasIcon, useTheme, useMediaQuery, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartGetStartedCustomCampaignCardProps } from './types';

export const FlowChartGetStartedCustomCampaignCard: FC<FlowChartGetStartedCustomCampaignCardProps> = ({
  handleChooseEmptyCampaign,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ConnectlyCard
      title="Custom Campaign"
      subtitle="Campaña personalizada  |  Campanha personalizada"
      media={{ children: <EmptyCanvasIcon /> }}
      onClick={handleChooseEmptyCampaign}
      sx={{
        flex: '0 0 auto',
        flexBasis: `calc(50% - ${theme.spacing(3)})`,
      }}
    >
      {isLargeScreen && (
        <Label color="textSecondary" variant="body2">
          You will start with an empty canvas. We recommend to start with an example from our library.
        </Label>
      )}
    </ConnectlyCard>
  );
};
