import {
  SofiaStoreDescriptionView,
  StoreDescriptorDefinition,
  StoreDescriptorDefinitionV2,
  Age,
  Gender,
  Sentiment,
  SentimentLabel,
} from './types';

export const SOFIA_SUPPORTED_AGES: Age[] = ['all', 'under12', '12-18', '19-34', '35-54', 'over55'];
export const SOFIA_AGE_DISPLAY: Record<Age, string> = {
  all: 'All Ages',
  under12: 'Under 12',
  '12-18': '12-18',
  '19-34': '19-34',
  '35-54': '35-54',
  over55: 'Over 55',
};

export const SOFIA_SUPPORTED_GENDERS: Gender[] = ['all', 'female', 'male', 'mostly_female', 'mostly_male'];
export const SOFIA_GENDER_DISPLAY: Record<Gender, string> = {
  all: 'All Genders',
  female: 'Female',
  male: 'Male',
  mostly_female: 'Mostly female',
  mostly_male: 'Mostly male',
};

export const DEFAULT_SOFIA_DATA: SofiaStoreDescriptionView = {
  id: '',
  language: '',
  name: '',
  url: '',
  email: '',
  phone: '',
  whatsAppLink: '',
  about: '',
  customersGender: 'all',
  customersAge: ['all'],
  customersDescription: '',
  facebook: '',
  instagram: '',
};

export const DEFAULT_STORE_DESCRIPTOR: StoreDescriptorDefinition = {
  name: '',
  url: '',
  about: '',
  customerArchetype: '',
  socialMediaHandles: undefined,
  contactMethods: undefined,
  language: '',
  sofiaExperience: 'SOFIA_EXPERIENCE_LITE',
  numRecommendations: undefined,
  additionalData: undefined,
};

export const DEFAULT_STORE_DESCRIPTOR_V2: StoreDescriptorDefinitionV2 = {
  name: '',
  url: '',
  about: '',
  defaultLanguage: '',
  additionalData: undefined,
};

export const SOFIA_STATUS_DICT = {
  INDEXER_STATUS_UNSPECIFIED: 'generic.unspecified',
  INDEXER_STATUS_SUCCESS: 'sofia.statusReadyToUse',
  INDEXER_STATUS_IN_PROGRESS: 'sofia.statusUpdating',
  INDEXER_STATUS_ERROR: 'generic.error',
};

export const SOFIA_DOCS_LINK = 'https://docs.connectly.ai/how-to-use-sofia-ai';

export const FREE_TEXT_DOCUMENT_FILENAME = 'sofia_free_text.txt';

export const DEFAULT_WELCOME_MESSAGE = 'Hello, welcome to our store!';
export const DEFAULT_PROMPT_ADDITION = 'Respond in a friendly tone.';

// export const DATA_LAG_DAYS = 1;

// Date when this feature is launched
export const MIN_DATE = new Date(2023, 8, 1);

// data for the preceding day is made available at 1:00 AM UTC
// users will query for data based on day ranges in their local time zone.
// assume that the data is available by 2:00 AM UTC

// data lag in date range depends on if local time is after 02:00 UTC of the same day
export const DATA_LAG_DAYS = new Date().getUTCHours() >= 2 ? 1 : 2;
export const MAX_DATE = new Date(new Date().setUTCDate(new Date().getUTCDate() - DATA_LAG_DAYS));

export const SENTIMENT_EMOJIS: Record<Sentiment, string> = {
  'very positive': '😍',
  positive: '😊',
  negative: '😞',
  mixed: '🥴',
};

export const SENTIMENT_LABELS: Record<Sentiment, SentimentLabel> = {
  'very positive': 'Very Positive',
  positive: 'Positive',
  negative: 'Negative',
  mixed: 'Mixed',
};
