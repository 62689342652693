import React, { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { Box, Skeleton } from '@connectlyai-tenets/ui-styled-web';
import { waConnectOTPCodeAtom } from '@atoms/home';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { DtoUpdateAccessor } from '@connectlyai-sdks/data-update';
import {
  selectSMSChannelId,
  useMeData,
  useSettingsData,
  selectDisplayName,
  selectBusinessId,
  useLogger,
  selectIsShopifyFocus,
  useFeatureFlag,
} from '@hooks';
import { CSATSection } from '@features/csat/components';
import { subscriptionOTPProvider } from '@connectlyai-features/welcome';
import { PageLayout } from '@components/PageLayout';
import { SetupWhatsApp } from '../SetupWhatsApp';
import { SMBWrapper } from '../SMBWrapper';
import { getGreeting } from './utils';
import { HomepageV2 } from '../HomepageV2';
import { selectWhatsAppInfo } from '../../selectors';

const enableForceSMB = getFlagByName('ffEnableForceSMB');
const useHome = ({ dtoUpdateAccessorProvider }: { dtoUpdateAccessorProvider: () => DtoUpdateAccessor }) => {
  const { logger } = useLogger();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: smsChannelId } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectSMSChannelId,
  });
  const { data: isShopifyFocus } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectIsShopifyFocus,
  });
  const { data: whatsAppInfo, isLoading: whatsAppIsLoading } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsAppInfo,
  });
  const { whatsAppIsActive, whatsAppClaimedNumber } = whatsAppInfo || {};

  const [otpCode, setOTPCode] = useAtom(waConnectOTPCodeAtom);
  useEffect(() => {
    if (smsChannelId) {
      const subscriptionOTP = subscriptionOTPProvider(logger, dtoUpdateAccessorProvider());
      const sub = subscriptionOTP(smsChannelId).subscribe((otpcode: string) => {
        setOTPCode(otpcode);
      });

      return () => {
        sub.unsubscribe();
      };
    }
    return () => {};
  }, [businessId, smsChannelId, dtoUpdateAccessorProvider, logger, setOTPCode]);

  const isShopifyAbandonedCheckoutCustomer: boolean = useMemo(() => {
    if (enableForceSMB) {
      return true;
    }
    return Boolean(isShopifyFocus);
  }, [isShopifyFocus]);
  const { data: displayName } = useMeData({ select: selectDisplayName });
  const greeting = useMemo(() => {
    const g = getGreeting();
    return `${g}${displayName ? `, ${displayName}` : ''}`;
  }, [displayName]);

  return {
    whatsAppIsLoading,
    whatsAppIsActive: Boolean(whatsAppIsActive),
    whatsAppClaimedNumber,
    isShopifyAbandonedCheckoutCustomer,
    whatsAppConnectVerificationCode: otpCode,
    greeting,
  };
};

const Loading = () => {
  return (
    <>
      <Box sx={{ width: '100%', height: '100%', transform: 'translateY(-50px)' }}>
        <Skeleton width="100%" height={260} />
      </Box>
      <Box sx={{ width: '100%', height: '100%', transform: 'translateY(-175px)' }}>
        <Skeleton width="100%" height={350} />
      </Box>
      <Box sx={{ width: '100%', height: '100%', transform: 'translateY(-300px)', display: 'flex', gap: 2 }}>
        <Skeleton width="50%" height={260} />
        <Skeleton width="50%" height={260} />
      </Box>
      <Box sx={{ width: '100%', height: '100%', transform: 'translateY(-400px)' }}>
        <Skeleton width="100%" height={260} />
      </Box>
      <Box sx={{ width: '100%', height: '100%', transform: 'translateY(-500px)' }}>
        <Skeleton width="100%" height={260} />
      </Box>
    </>
  );
};

export const Home = ({ dtoUpdateAccessorProvider }: { dtoUpdateAccessorProvider: () => DtoUpdateAccessor }) => {
  const {
    greeting,
    isShopifyAbandonedCheckoutCustomer,
    whatsAppConnectVerificationCode,
    whatsAppIsActive,
    whatsAppClaimedNumber,
    whatsAppIsLoading,
  } = useHome({
    dtoUpdateAccessorProvider,
  });
  const { ffEnableCSAT } = useFeatureFlag(['ffEnableCSAT']);

  const getHomePageContent = () => {
    if (isShopifyAbandonedCheckoutCustomer) {
      return <SMBWrapper verificationCode={whatsAppConnectVerificationCode} />;
    }
    if (whatsAppIsLoading) {
      return <Loading />;
    }
    if (!whatsAppIsActive) {
      return (
        <SetupWhatsApp
          whatsAppClaimedNumber={whatsAppClaimedNumber}
          whatsAppIsActive={whatsAppIsActive}
          whatsAppConnectVerificationCode={whatsAppConnectVerificationCode}
        />
      );
    }

    return (
      <>
        {ffEnableCSAT && <CSATSection />}
        <HomepageV2 />
      </>
    );
  };
  return <PageLayout title={greeting}>{getHomePageContent()}</PageLayout>;
};
