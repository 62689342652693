import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { REMOVE_USER_URL, RemoveUserPath, RemoveUserResponse } from './types';

export const useRemoveUserMutation = (
  options?: Omit<
    UseMutationOptions<RemoveUserResponse, ConnectlyError, RemoveUserPath, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    (input: RemoveUserPath) =>
      api.profileV1(REMOVE_USER_URL, 'delete', {
        path: { businessId: input.businessId, userId: input.userId },
      }),
    {
      ...options,
    },
  );
