export const amber = {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
    A100: '#FFECB3',
    A200: '#FFE082',
    A400: '#FFD54F',
    A700: '#FFCA28',
};
