import * as React from 'react';
import { styled, Tooltip, TooltipProps, tooltipClasses } from '@connectlyai-tenets/ui-styled-web';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
}));
