import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFilePicker } from 'use-file-picker';
import { Alert, Box, Button } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { applyHook, downloadSampleCSV } from '../../utils';
import { AudienceFileSelectorProps } from './types';
import { AUDIENCE_LIST_ACCEPT_FILE_EXT, AUDIENCE_LIST_MAX_FILE_SIZE_MB } from './constants';
import { usePrevious } from '../../hooks';
import { NotificationContext } from '../../contexts';
import { BoxTitleSmall } from '../AudienceName';
import { selectListBuilderIsSelectEnabled } from '../../features/audiences';

const useAudienceFileSelector = ({
  onSuccess,
}: Partial<AudienceFileSelectorProps>): Partial<AudienceFileSelectorProps> => {
  const { notificationProvider } = useContext(NotificationContext);

  const isEnabled = useSelector(selectListBuilderIsSelectEnabled);

  const [openFileSelector, { filesContent, plainFiles, loading: isFileSelectorLoading, errors, clear }] = useFilePicker(
    {
      accept: AUDIENCE_LIST_ACCEPT_FILE_EXT,
      multiple: false,
      maxFileSize: AUDIENCE_LIST_MAX_FILE_SIZE_MB,
    },
  );
  const previousIsFileSelectorLoading = usePrevious(isFileSelectorLoading);

  useEffect(() => {
    if (isFileSelectorLoading || !previousIsFileSelectorLoading) return;

    if (errors.length > 0) {
      const { fileSizeToolarge: fileSizeTooLarge } = errors[0];
      if (fileSizeTooLarge) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message:
              `File size exceeds limit (${AUDIENCE_LIST_MAX_FILE_SIZE_MB}MB)` +
              ` and cannot be uploaded. Please select another one.`,
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      } else {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: `Something went wrong with the file you are trying to upload. Please try another file.`,
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      }
      return;
    }

    if (!plainFiles.length || !filesContent.length) return;

    if (onSuccess) {
      onSuccess(plainFiles[0]);
    }
  }, [
    clear,
    errors,
    filesContent.length,
    isFileSelectorLoading,
    notificationProvider,
    previousIsFileSelectorLoading,
    plainFiles,
    onSuccess,
  ]);

  const handleSelect = useCallback(() => {
    openFileSelector();
  }, [openFileSelector]);

  const handleDownloadExample = useCallback(() => {
    downloadSampleCSV('example_audience.csv');
  }, []);

  return {
    isEnabled,
    onDownloadExample: handleDownloadExample,
    onSelect: handleSelect,
  };
};

const AudienceFileSelectorPresentation = ({ isEnabled, onDownloadExample, onSelect }: AudienceFileSelectorProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column' }}>
      <BoxTitleSmall>Import audience from CSV</BoxTitleSmall>
      <Alert severity="info" color="info">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
          Make sure your CSV file has a single column for phone numbers in the format: (country code) + number (e.g.,
          14151231234).
          <Button onClick={onDownloadExample}>Download Example</Button>
        </Box>
      </Alert>
      <Button disabled={!isEnabled} variant="contained" onClick={onSelect} sx={{ alignSelf: 'center' }}>
        Upload CSV
      </Button>
    </Box>
  );
};

export const AudienceFileSelector = applyHook(AudienceFileSelectorPresentation, useAudienceFileSelector);
