import { configureStore } from '@reduxjs/toolkit';
import audienceListBuilderReducer from '../features/audiences/state/slice';
import messageTemplatesReducer from './messageTemplates/slice';
import flowReducer from './flow/campaignFlowSlice';
import flowDocumentReducer from './flow/flowDocumentSlice';
import flowAutoReplyReducer from '../features/flow-autoreply/state/slice';
import welcomeReducer from '../features/welcome/state/slice';

const appStore = configureStore({
  reducer: {
    audienceListBuilder: audienceListBuilderReducer,
    flow: flowReducer,
    flowAutoReply: flowAutoReplyReducer,
    flowDocument: flowDocumentReducer,
    messageTemplates: messageTemplatesReducer,
    welcome: welcomeReducer,
  },
  devTools: {
    name: 'connectly-inbox',
  },
});

export type AppState = ReturnType<typeof appStore.getState>;
export default appStore;
