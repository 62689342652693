import React from 'react';
import { Box, Table, TableBody } from '@connectlyai-tenets/ui-styled-web';
import { EnhancedTableProps } from '@components/EnhancedTable/types';
import { EmptySimpleTableView } from './EmptyState';
import { SimpleTableHead } from './SimpleTableHead';
import { SimpleTableRow, SimpleTableRowProps } from './SimpleTableRow';

type SimpleTableProps = Pick<EnhancedTableProps, 'rows' | 'headers' | 'isLoading'>;

export const SimpleTable = ({ rows, headers, isLoading = false }: SimpleTableProps) => {
  return (
    <Box
      sx={{
        minHeight: 0,
        flex: '1 1 100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        scrollbarGutter: 'stable',
        '&:hover': {
          overflow: 'auto',
        },
      }}
    >
      <Table
        stickyHeader
        sx={{
          flex: '0 0 auto',
          height: 'auto',
        }}
      >
        <SimpleTableHead cells={headers} />
        <TableBody
          sx={{
            position: 'relative',
            overflow: 'hidden',
            scrollbarGutter: 'stable',
            '&:hover': {
              overflow: 'auto',
            },
          }}
        >
          {rows.map((tableRow: SimpleTableRowProps) => (
            <SimpleTableRow
              key={tableRow.id}
              id={tableRow.id}
              data={tableRow.data}
              onClick={tableRow.onClick}
              isLoading={isLoading}
              hoverActions={tableRow.hoverActions}
            />
          ))}

          {!rows.length && <EmptySimpleTableView />}
        </TableBody>
      </Table>
    </Box>
  );
};
