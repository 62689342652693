export const mutationDeleteTagProvider = (log, networkClient) => async (businessId, tagId) => {
    const url = `/v1/businesses/${businessId}/tags/${tagId}`;
    log.debug('mutationDeleteTag@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.delete(url, {});
        log.debug('mutationDeleteTag@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationDeleteTag@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
