// unreduxified, has only messageTemplates redux
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CheckIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Label,
  LinearProgress,
  MessageVerificationIcon,
  ReportIcon,
  DotIcon,
  ModalProps,
  Stack,
  Modal,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { modifyTemplateComponents } from '@components/FlowChartCampaignV3/utils';
import produce from 'immer';
import { useAtom, useAtomValue } from 'jotai';
import { templateApprovalDialogOpenAtom, campaignLanguageAtom, nodesAtom, compileResultAtom } from '@atoms/flow';
import { selectBusinessId, useMeData, NodeObjectUpdate, useFeatureFlag } from '@hooks';
import { useFlowVariables } from '@hooks/useFlowVariables';
import { SendConnectlyTemplateMessageData, TemplateCategory } from '@connectlyai-tenets/sdk';
import type { Node } from 'react-flow-renderer';
import { TEMPLATE_CATEGORIES, DEFAULT_TEMPLATE_CATEGORY } from '../../hooks/useTemplateCategory/constants';
import { TemplateApprovalDialogProps, AutoFix } from './types';
import {
  selectFirstInvalidTemplate,
  selectHasPendingTemplates,
  selectHasRejectedTemplates,
  selectHasUnsentTemplates,
  selectRejectedTemplates,
  selectUnsentMessageTemplatesIndex,
  UnsentMessageTemplate,
  setMessageTemplateFromNode,
} from '../../../../state/messageTemplates';
import { getRejectedReasonText, getTemplateStatusColor, track } from '../../../../utils';
import { useCampaignFlowChangeAppliers } from '../../hooks/useCampaignFlowChangeAppliers';
import appStore from '../../../../state/store';
import { selectFirstRejectedTemplate } from '../../../../state/messageTemplates/selectors';
import { denormalizeName } from '../../../../presentation/preview/utils';
import { useWaTemplateMutations } from '../../hooks/useWaTemplateMutations';
import { useCreateNode } from '../../hooks/useCreateNode';
import { useCampaignStep } from '../../hooks/useCampaignStep';
import { useCenterToNode } from '../../hooks/useCenterToNode';
import type { CompileWarning } from '../../hooks/useFlowChecks/types';

type RejectedMessageTemplateProps = {
  template: UnsentMessageTemplate;
};

const RejectedMessageTemplate: FC<RejectedMessageTemplateProps> = ({ template }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Label variant="body1" fontWeight="fontWeightMedium">
        {denormalizeName(template.name)}
      </Label>
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <DotIcon
          sx={{
            width: '12px',
            height: '12px',
            color: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED'),
            mr: 0.25,
          }}
        />
        <Label sx={{ fontSize: 12, lineHeight: '16px', letterSpacing: 0.15 }}>Rejected by WhatsApp</Label>
      </Box>
      <Label color="textSecondary" sx={{ fontSize: 12, lineHeight: '16px', letterSpacing: 0.15 }}>
        {`Reason: ${getRejectedReasonText(template.rejectionReason?.reason)}`}
      </Label>
    </Box>
  );
};

const useTemplateApprovalDialog = ({ allowCancel, selectNode }: TemplateApprovalDialogProps) => {
  const [templateApprovalDialogOpen, setTemplateApprovalDialogOpen] = useAtom(templateApprovalDialogOpenAtom);
  const campaignLanguage = useAtomValue(campaignLanguageAtom);
  const nodes = useAtomValue(nodesAtom);
  const nodesDictionary = nodes.reduce(
    (acc, node) => {
      acc[node.id] = node;
      return acc;
    },
    {} as Record<string, Node>,
  );

  const templatesIndex = useCallback(() => selectUnsentMessageTemplatesIndex(appStore.getState()), []);
  const hasPendingTemplates = useSelector(selectHasPendingTemplates);
  const hasRejectedTemplates = useSelector(selectHasRejectedTemplates);
  const hasUnsentTemplates = useSelector(selectHasUnsentTemplates);
  const rejectedTemplates = useSelector(selectRejectedTemplates);
  const firstInvalidTemplate = useSelector(selectFirstInvalidTemplate);
  const firstRejectedTemplate = useSelector(selectFirstRejectedTemplate);
  const { ffEnableTemplateAutofix } = useFeatureFlag(['ffEnableTemplateAutofix']);

  const dispatch = useDispatch();

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { mutateCreateWaTemplate, mutateUpdateWaTemplate } = useWaTemplateMutations();

  const { substituteVariables } = useFlowVariables();

  const { templateName, remoteTemplateNames } = useCreateNode();
  const { onNodeObjectChange } = useCampaignFlowChangeAppliers();

  const { centerToNode } = useCenterToNode();

  const { campaignStep, goToNextCampaignStep, goToPreviousCampaignStep } = useCampaignStep();
  const compileResult = useAtomValue(compileResultAtom);
  const compileResultFixTemplates = useMemo(
    () =>
      compileResult?.items?.reduce<CompileWarning[]>((acc, item) => {
        if (
          item.code !== 'DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID' &&
          item.code !== 'DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_INCONSISTENT'
        ) {
          return acc;
        }
        const node = nodes.find((n) => n.id === item.id);
        if (!node) {
          return acc;
        }
        return [...acc, { node, error: item }];
      }, []) || [],
    [compileResult, nodes],
  );

  const onNextState = useCallback(() => {
    setTemplateApprovalDialogOpen(false);
    if (campaignStep === 'approval') goToNextCampaignStep();
  }, [campaignStep, setTemplateApprovalDialogOpen, goToNextCampaignStep]);

  const onPreviousState = useCallback(() => {
    if (!allowCancel) return;
    setTemplateApprovalDialogOpen(false);
    goToPreviousCampaignStep();
  }, [allowCancel, setTemplateApprovalDialogOpen, goToPreviousCampaignStep]);

  const handleSendTemplates = useCallback(() => {
    if (!businessId) {
      return;
    }

    // Safety check: find potentially duplicates names and assign a new name to them
    const templateNames: string[] = [...(remoteTemplateNames || [])];
    Object.entries(templatesIndex()).forEach(([nodeId, unsentMessageTemplate]) => {
      const name = unsentMessageTemplate?.name;
      if (name && !templateNames.includes(name)) {
        templateNames.push(name);
        return;
      }
      if (unsentMessageTemplate?.status !== undefined) return;

      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return;

      const newName = templateName();
      templateNames.push(newName);

      const updatedNode = produce(node, (draft) => {
        draft.data.v1.name = newName;
        if (draft.data.v1.messageTemplateInput) draft.data.v1.messageTemplateInput.name = newName;
        return draft;
      });

      const update: NodeObjectUpdate = {
        id: updatedNode.id,
        item: updatedNode,
      };
      onNodeObjectChange([update]);
      dispatch(setMessageTemplateFromNode({ node: updatedNode }));
    });

    const templatesSubmittedFromAutofix: string[] = [];
    if (ffEnableTemplateAutofix) {
      const localUnsentTemplates = templatesIndex();
      compileResultFixTemplates.forEach((compileWarning) => {
        const { node, error } = compileWarning;
        const unsentTemplate = localUnsentTemplates[node.id];
        const autofix = error?.details?.autofix;

        if (
          autofix &&
          unsentTemplate &&
          !templatesSubmittedFromAutofix.includes(node.id) &&
          node.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE'
        ) {
          const typedNode = node as Node<SendConnectlyTemplateMessageData>;
          const fix = JSON.parse(autofix) as AutoFix;
          const { id: unsentTemplateId, name } = unsentTemplate;
          // CAUTION template category not managed properly with idl
          const templateCategory: TemplateCategory = TEMPLATE_CATEGORIES.includes(node?.data?.v1?.templateCategory)
            ? node?.data?.v1?.templateCategory
            : DEFAULT_TEMPLATE_CATEGORY;
          if (fix.fixMissingTemplate) {
            const { nodeTemplateInput } = fix.fixMissingTemplate;
            templatesSubmittedFromAutofix.push(node.id);
            const updatedNode = produce(typedNode, (draft) => {
              if (draft.data.v1) {
                draft.data.v1.messageTemplateInput = nodeTemplateInput;
              }
              return draft;
            });
            const update: NodeObjectUpdate = {
              id: updatedNode.id,
              item: updatedNode,
            };
            onNodeObjectChange([update]);
            dispatch(setMessageTemplateFromNode({ node: updatedNode }));
            mutateCreateWaTemplate({
              nodeId: node.id,
              businessId,
              template: {
                ...unsentTemplate,
                language: { code: campaignLanguage },
                templateComponents: nodeTemplateInput?.templateComponents,
              },
              templateGroup: { id: name, name, category: templateCategory },
            });
          }
          if (fix.fixInconsistentTemplate) {
            const { nodeTemplateInput } = fix.fixInconsistentTemplate;
            templatesSubmittedFromAutofix.push(node.id);
            const updatedNode = produce(typedNode, (draft) => {
              if (draft.data.v1) {
                draft.data.v1.messageTemplateInput = nodeTemplateInput;
              }
              return draft;
            });
            const update: NodeObjectUpdate = {
              id: updatedNode.id,
              item: updatedNode,
            };
            onNodeObjectChange([update]);
            dispatch(setMessageTemplateFromNode({ node: updatedNode }));
            mutateUpdateWaTemplate({
              nodeId: updatedNode.id,
              businessId,
              byExternalTemplateId: {
                templateId: unsentTemplateId,
              },
              templateComponents: nodeTemplateInput?.templateComponents,
            });
          }
        }
      });
    }

    // CAUTION This should not be needed after ffEnableTemplateAutofix is activated
    Object.entries(templatesIndex())
      .filter(
        (x): x is [string, UnsentMessageTemplate] =>
          !!x[1] && (x[1].status === undefined || x[1].status === 'MESSAGE_TEMPLATE_STATUS_PENDING'),
      )
      .forEach(([nodeId, x]) => {
        const node = nodes.find((n) => n.id === nodeId);

        const templateCategory: TemplateCategory = TEMPLATE_CATEGORIES.includes(node?.data?.v1?.templateCategory)
          ? node?.data?.v1?.templateCategory
          : DEFAULT_TEMPLATE_CATEGORY;

        // modify template components to map variables from {{name}} to {{1}} etc.
        const { templateComponents } = x;
        if (!templateComponents) return;
        const stateTemplateComponents = nodesDictionary[nodeId]?.data?.v1?.messageTemplateInput?.templateComponents;
        const modifiedTemplateComponents = modifyTemplateComponents(stateTemplateComponents, substituteVariables);
        const { id, name } = x;
        if (id) {
          track('debug.nonautofix.update');
          mutateUpdateWaTemplate({
            nodeId,
            businessId,
            byExternalTemplateId: {
              templateId: id,
            },
            templateComponents: modifiedTemplateComponents,
          });
        } else if (!templatesSubmittedFromAutofix.includes(nodeId)) {
          track('debug.nonautofix.create');
          mutateCreateWaTemplate({
            nodeId,
            businessId,
            template: {
              ...x,
              language: { code: campaignLanguage },
              templateComponents: modifiedTemplateComponents,
            },
            templateGroup: { id: name, name, category: templateCategory },
          });
        }
      });
  }, [
    compileResultFixTemplates,
    ffEnableTemplateAutofix,
    businessId,
    campaignLanguage,
    dispatch,
    mutateCreateWaTemplate,
    mutateUpdateWaTemplate,
    nodes,
    onNodeObjectChange,
    remoteTemplateNames,
    substituteVariables,
    templateName,
    templatesIndex,
    nodesDictionary,
  ]);

  const handleEditInvalidTemplate = useCallback(() => {
    onPreviousState();
    if (!firstInvalidTemplate) {
      return;
    }

    const [nodeId, template] = firstInvalidTemplate;
    if (!nodeId || !template) {
      return;
    }

    const actualNode = nodes.find((n) => n.id === nodeId);
    if (!actualNode) {
      return;
    }
    selectNode(nodeId);
    centerToNode(actualNode, true);
  }, [centerToNode, firstInvalidTemplate, nodes, onPreviousState, selectNode]);

  const handleEditRejectedTemplate = useCallback(() => {
    onPreviousState();
    if (!firstRejectedTemplate) {
      return;
    }

    const [nodeId, template] = firstRejectedTemplate;
    if (!nodeId || !template) {
      return;
    }

    const actualNode = nodes.find((n) => n.id === nodeId);
    if (!actualNode) {
      return;
    }
    selectNode(nodeId);
    centerToNode(actualNode, true);
  }, [centerToNode, firstRejectedTemplate, nodes, onPreviousState, selectNode]);
  return {
    hasInvalidTemplates: !!firstInvalidTemplate,
    hasPendingTemplates,
    hasRejectedTemplates,
    hasUnsentTemplates,
    onApprove: handleSendTemplates,
    onNextState,
    onPreviousState,
    onEditInvalidTemplate: handleEditInvalidTemplate,
    onEditRejectedTemplate: handleEditRejectedTemplate,
    open: templateApprovalDialogOpen,
    rejectedTemplates,
    selectNode,
    templates: [],
  };
};

export const TemplateApprovalDialog: FC<TemplateApprovalDialogProps> = ({ allowCancel, selectNode }) => {
  const {
    hasInvalidTemplates,
    hasPendingTemplates,
    hasRejectedTemplates,
    hasUnsentTemplates,
    onNextState,
    onPreviousState,
    onApprove,
    onEditInvalidTemplate,
    onEditRejectedTemplate,
    open,
    rejectedTemplates,
  } = useTemplateApprovalDialog({ allowCancel, selectNode });
  const theme = useTheme();

  let modalProps: ModalProps = { dialog: { open } };
  let modalChildren: React.ReactNode = null;
  if (hasInvalidTemplates) {
    modalProps = {
      dialog: { open },
      title: 'Message Verification',
      contentText: 'Please correct template errors before approval.',
      actions: {
        primaryButton: {
          onClick: onEditInvalidTemplate,
          children: 'Ok',
        },
      },
    };
  } else if (hasPendingTemplates) {
    modalProps = {
      dialog: { open },
      title: 'Message Verification',
      contentText: 'In Progress',
    };
    modalChildren = <LinearProgress sx={{ mt: 1, width: 552 }} />;
  } else if (hasUnsentTemplates) {
    modalProps = {
      dialog: { open },
      title: 'Message Verification',
      icon: <MessageVerificationIcon width="72px" height="48px" />,
      contentText:
        // eslint-disable-next-line max-len
        "Your messages will be submitted to WhatsApp server for automatic approvals. The review process takes only a few seconds. Your campaign will not be created until it's submitted to WhatsApp.",
      actions: {
        primaryButton: {
          onClick: onApprove,
          children: 'Confirm',
        },
        tertiaryButton: {
          onClick: onPreviousState,
          children: 'Cancel',
        },
      },
    };
  } else if (hasRejectedTemplates) {
    modalProps = {
      dialog: { open },
      title: 'Message Verification',
      contentText:
        rejectedTemplates.length === 1
          ? '1 message need your attention.'
          : `${rejectedTemplates.length} messages need your attention.`,
      icon: (
        <Box
          sx={{
            width: theme.spacing(4),
            height: theme.spacing(4),
            backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED'),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            '& > svg': {
              width: theme.spacing(2.5),
              fill: theme.palette.common.white,
            },
          }}
        >
          <ReportIcon />
        </Box>
      ),
      actions: {
        primaryButton: {
          onClick: onEditRejectedTemplate,
          children: 'Edit Campaign',
        },
      },
    };
    modalChildren = (
      <Stack
        alignItems="center"
        gap={1}
        sx={{
          width: 552,
          boxSizing: 'border-box',
          py: 4.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'stretch',
            gap: 2,
          }}
        >
          <Box
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                maxHeight: 300,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: '10px',
                backgroundColor: theme.palette.grey[100],
                px: 3,
                py: 2,
                boxSizing: 'border-box',
                overflowY: 'auto',
              }}
            >
              {rejectedTemplates.map((x) => (
                <RejectedMessageTemplate key={x.name} template={x} />
              ))}
            </Box>
          </Box>
        </Box>
      </Stack>
    );
  } else {
    modalProps = {
      dialog: { open },
      title: 'Message Verification',
      contentText: 'All messages are approved!',
      icon: (
        <Box
          sx={{
            width: theme.spacing(4),
            height: theme.spacing(4),
            backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_APPROVED'),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            '& > svg': {
              width: theme.spacing(2.5),
              fill: theme.palette.common.white,
            },
          }}
        >
          <CheckIcon />
        </Box>
      ),
      actions: {
        primaryButton: {
          onClick: onNextState,
          children: 'Continue',
        },
      },
    };
  }

  let dialogContent = null;
  if (hasInvalidTemplates) {
    dialogContent = (
      <>
        <DialogContent>
          <DialogContentText>Please correct template errors before approval.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditInvalidTemplate}>Ok</Button>
        </DialogActions>
      </>
    );
  } else if (hasPendingTemplates) {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <DialogContent sx={{ px: 3, py: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LinearProgress sx={{ width: 552 }} />
          <DialogContentText>In Progress</DialogContentText>
        </DialogContent>
        <DialogActions />
      </>
    );
  } else if (hasUnsentTemplates) {
    dialogContent = (
      <Box sx={{ px: 3, py: 5.5, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <MessageVerificationIcon />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Label variant="h5">Message Verification</Label>
          <DialogContentText align="center">
            Your messages will be submitted to WhatsApp server for automatic approvals. The review process takes only a
            seconds. Your campaign will not be created until it’s submitted to WhatsApp.
          </DialogContentText>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={onApprove}>
            Confirm
          </Button>
          <Button variant="text" onClick={onPreviousState}>
            Cancel
          </Button>
        </Box>
      </Box>
    );
  } else if (hasRejectedTemplates) {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <Box sx={{ p: 3, pt: 1 }}>
          <Box
            sx={{
              width: 552,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              py: 4.5,
            }}
          >
            <Box
              sx={{
                width: 44,
                height: 44,
                backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED'),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                '& > svg': {
                  width: 24,
                  fill: theme.palette.common.white,
                },
              }}
            >
              <ReportIcon />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 2,
              }}
            >
              <Label variant="h5">
                {rejectedTemplates.length === 1
                  ? '1 message need your attention.'
                  : `${rejectedTemplates.length} messages need your attention.`}
              </Label>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    maxHeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: '10px',
                    backgroundColor: theme.palette.grey[100],
                    px: 3,
                    py: 2,
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                  }}
                >
                  {rejectedTemplates.map((x) => (
                    <RejectedMessageTemplate key={x.name} template={x} />
                  ))}
                </Box>
              </Box>
              <Button variant="contained" onClick={onEditRejectedTemplate}>
                Edit Campaign
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <Box sx={{ p: 3, pt: 1 }}>
          <Box
            sx={{
              py: 4.5,
              px: 3,
              width: 552,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 44,
                height: 44,
                backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_APPROVED'),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                '& > svg': {
                  width: 24,
                  fill: theme.palette.common.white,
                },
              }}
            >
              <CheckIcon />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Label variant="h5">All messages are approved!</Label>
              <Button variant="contained" onClick={onNextState}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  return <Modal {...modalProps}>{modalChildren}</Modal>;
};
