import { PrefilledParam } from "../../messaging/template/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.flow.lang";
export var FlowType;
(function (FlowType) {
    FlowType[FlowType["FLOW_TYPE_UNSPECIFIED"] = 0] = "FLOW_TYPE_UNSPECIFIED";
    FlowType[FlowType["FLOW_TYPE_STRING"] = 1] = "FLOW_TYPE_STRING";
    FlowType[FlowType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowType || (FlowType = {}));
export function flowTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "FLOW_TYPE_UNSPECIFIED":
            return FlowType.FLOW_TYPE_UNSPECIFIED;
        case 1:
        case "FLOW_TYPE_STRING":
            return FlowType.FLOW_TYPE_STRING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowType.UNRECOGNIZED;
    }
}
export function flowTypeToJSON(object) {
    switch (object) {
        case FlowType.FLOW_TYPE_UNSPECIFIED:
            return "FLOW_TYPE_UNSPECIFIED";
        case FlowType.FLOW_TYPE_STRING:
            return "FLOW_TYPE_STRING";
        case FlowType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var ValidatorType;
(function (ValidatorType) {
    ValidatorType[ValidatorType["VALIDATOR_TYPE_UNSPECIFIED"] = 0] = "VALIDATOR_TYPE_UNSPECIFIED";
    ValidatorType[ValidatorType["VALIDATOR_TYPE_URL"] = 1] = "VALIDATOR_TYPE_URL";
    ValidatorType[ValidatorType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ValidatorType || (ValidatorType = {}));
export function validatorTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "VALIDATOR_TYPE_UNSPECIFIED":
            return ValidatorType.VALIDATOR_TYPE_UNSPECIFIED;
        case 1:
        case "VALIDATOR_TYPE_URL":
            return ValidatorType.VALIDATOR_TYPE_URL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ValidatorType.UNRECOGNIZED;
    }
}
export function validatorTypeToJSON(object) {
    switch (object) {
        case ValidatorType.VALIDATOR_TYPE_UNSPECIFIED:
            return "VALIDATOR_TYPE_UNSPECIFIED";
        case ValidatorType.VALIDATOR_TYPE_URL:
            return "VALIDATOR_TYPE_URL";
        case ValidatorType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseValidator() {
    return { type: 0, paramsOneof: undefined };
}
export const Validator = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "urlParams") {
            Validator_URLParams.encode(message.paramsOneof.urlParams, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.paramsOneof = {
                        $case: "urlParams",
                        urlParams: Validator_URLParams.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? validatorTypeFromJSON(object.type) : 0,
            paramsOneof: isSet(object.urlParams)
                ? {
                    $case: "urlParams",
                    urlParams: Validator_URLParams.fromJSON(object.urlParams),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.type !== undefined &&
            (obj.type = validatorTypeToJSON(message.type));
        ((_a = message.paramsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "urlParams" &&
            (obj.urlParams = ((_b = message.paramsOneof) === null || _b === void 0 ? void 0 : _b.urlParams)
                ? Validator_URLParams.toJSON((_c = message.paramsOneof) === null || _c === void 0 ? void 0 : _c.urlParams)
                : undefined);
        return obj;
    },
};
function createBaseValidator_URLParams() {
    return {};
}
export const Validator_URLParams = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidator_URLParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseVariableDeclaration() {
    return {
        id: "",
        type: 0,
        namespace: "",
        namespaceCanonical: "",
        simpleName: "",
        simpleNameCanonical: "",
        validators: [],
        initializer: undefined,
    };
}
export const VariableDeclaration = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.namespace !== "") {
            writer.uint32(26).string(message.namespace);
        }
        if (message.namespaceCanonical !== "") {
            writer.uint32(34).string(message.namespaceCanonical);
        }
        if (message.simpleName !== "") {
            writer.uint32(42).string(message.simpleName);
        }
        if (message.simpleNameCanonical !== "") {
            writer.uint32(50).string(message.simpleNameCanonical);
        }
        for (const v of message.validators) {
            Validator.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.initializer !== undefined) {
            VariableInitializer.encode(message.initializer, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariableDeclaration();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.namespace = reader.string();
                    break;
                case 4:
                    message.namespaceCanonical = reader.string();
                    break;
                case 5:
                    message.simpleName = reader.string();
                    break;
                case 6:
                    message.simpleNameCanonical = reader.string();
                    break;
                case 7:
                    message.validators.push(Validator.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.initializer = VariableInitializer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            type: isSet(object.type) ? flowTypeFromJSON(object.type) : 0,
            namespace: isSet(object.namespace) ? String(object.namespace) : "",
            namespaceCanonical: isSet(object.namespaceCanonical)
                ? String(object.namespaceCanonical)
                : "",
            simpleName: isSet(object.simpleName) ? String(object.simpleName) : "",
            simpleNameCanonical: isSet(object.simpleNameCanonical)
                ? String(object.simpleNameCanonical)
                : "",
            validators: Array.isArray(object === null || object === void 0 ? void 0 : object.validators)
                ? object.validators.map((e) => Validator.fromJSON(e))
                : [],
            initializer: isSet(object.initializer)
                ? VariableInitializer.fromJSON(object.initializer)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.type !== undefined && (obj.type = flowTypeToJSON(message.type));
        message.namespace !== undefined && (obj.namespace = message.namespace);
        message.namespaceCanonical !== undefined &&
            (obj.namespaceCanonical = message.namespaceCanonical);
        message.simpleName !== undefined && (obj.simpleName = message.simpleName);
        message.simpleNameCanonical !== undefined &&
            (obj.simpleNameCanonical = message.simpleNameCanonical);
        if (message.validators) {
            obj.validators = message.validators.map((e) => e ? Validator.toJSON(e) : undefined);
        }
        else {
            obj.validators = [];
        }
        message.initializer !== undefined &&
            (obj.initializer = message.initializer
                ? VariableInitializer.toJSON(message.initializer)
                : undefined);
        return obj;
    },
};
function createBaseVariableInitializer() {
    return { prefillOneof: undefined };
}
export const VariableInitializer = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.prefillOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            PrefilledParam.encode(message.prefillOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVariableInitializer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.prefillOneof = {
                        $case: "v1",
                        v1: PrefilledParam.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            prefillOneof: isSet(object.v1)
                ? { $case: "v1", v1: PrefilledParam.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.prefillOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.prefillOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? PrefilledParam.toJSON((_c = message.prefillOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
