import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useContextSelector } from 'use-context-selector';
import { useAtomValue, useAtom } from 'jotai';
import { Box, Stack } from '@connectlyai-tenets/ui-styled-web';
import { getLocalTimezone } from 'src/utils';
import { QuerySendoutAnalyticsResponse, generatePlaceholderData } from '@hooks/useQuerySendoutAnalytics';
import { BestPerforming } from '@scenes/Home/components/HomepageV2/BestPerforming/BestPerforming';
import { CampaignSendoutRouteArgs, CampaignSendoutRouteId } from '@connectlyai-sdks/inbox-navigation';
import { NavigationContext } from '../../contexts';
import { selectBusinessId, useMeData, useQuerySendoutAnalytics } from '../../hooks';
import { AnalyticsRecentCampaignReportsDialog } from './AnalyticsRecentCampaignReportsDialog';
import { AnalyticsRequestGenerateCampaignReportDialog } from './AnalyticsRequestGenerateCampaignReportDialog';
import {
  recentCampaignReportsDialogOpenAtom,
  generateCampaignReportDialogOpenAtom,
  dateRangeAtom,
  campaignFilterV2Atom,
  sendoutsExportDataAtom,
} from '../../atoms/analytics';
import { AnalyticsSendoutsAggregateData } from './AnalyticsSendoutsAggregateData';
import { AnalyticsSendoutsTable } from './AnalyticsSendoutsTable';

const placeholderData: QuerySendoutAnalyticsResponse = generatePlaceholderData(10);

const useAnalyticsSendoutsOverview = () => {
  const [isFilterInitialized, setIsFilterInitialized] = React.useState(false);
  const dateRange = useAtomValue(dateRangeAtom);
  const sendoutsExportData = useAtomValue(sendoutsExportDataAtom);
  const startDate = dateRange.startDate.toISOString();
  const endDate = dateRange.endDate.toISOString();
  const [campaignFilter, setCampaignFilter] = useAtom(campaignFilterV2Atom);
  const [recentCampaignReportsDialogOpen, setRecentCampaignReportsDialogOpen] = useAtom(
    recentCampaignReportsDialogOpenAtom,
  );
  const [generateCampaignReportDialogOpen, setGenerateCampaignReportDialogOpen] = useAtom(
    generateCampaignReportDialogOpenAtom,
  );
  const prevStartDate = useMemo(() => {
    const currStart = new Date(startDate).getTime();
    const currEnd = new Date(endDate).getTime();
    const diff = currEnd - currStart;
    return new Date(currStart - diff).toISOString();
  }, [startDate, endDate]);

  const prevEndDate = useMemo(() => {
    const currStart = new Date(startDate).getTime();
    const currEnd = new Date(endDate).getTime();
    const diff = currEnd - currStart;
    return new Date(currEnd - diff).toISOString();
  }, [startDate, endDate]);

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: sendoutAnalytics, isLoading } = useQuerySendoutAnalytics(
    {
      businessId: businessId || '',
      timeRangeStart: startDate,
      timeRangeEnd: endDate,
    },
    { enabled: !!businessId, placeholderData },
  );
  const { data: prevSendoutAnalytics, isLoading: prevIsLoading } = useQuerySendoutAnalytics(
    { businessId: businessId || '', timeRangeStart: prevStartDate, timeRangeEnd: prevEndDate },
    { enabled: !!businessId, placeholderData },
  );

  const timeZone = getLocalTimezone();

  const reportTimeData = React.useMemo(
    () => ({
      startDate,
      endDate,
      timeZone,
    }),
    [startDate, endDate, timeZone],
  );

  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForAnalytics);
  const isCampaignSendoutRouteMatch = useRouteMatch<CampaignSendoutRouteArgs>(
    navigatorProvider().generateGlobalRouteId(CampaignSendoutRouteId),
  );

  const rows = React.useMemo(() => sendoutAnalytics?.entity?.items || [], [sendoutAnalytics]);
  const prevRows = React.useMemo(() => prevSendoutAnalytics?.entity?.items || [], [prevSendoutAnalytics]);

  React.useEffect(() => {
    if (!isFilterInitialized && isCampaignSendoutRouteMatch) {
      const { sendoutName } = isCampaignSendoutRouteMatch.params;
      const sendout = rows.find((val) => val.sendoutName === sendoutName.replace(/\+/g, ' '));

      if (sendout) {
        setIsFilterInitialized(true);
        setCampaignFilter((prevFilter) => [...prevFilter, { id: sendout.campaignId, label: sendout.campaignName }]);
      }
    }
  }, [isFilterInitialized, isCampaignSendoutRouteMatch, rows, setCampaignFilter]);

  const filteredRows = React.useMemo(
    () =>
      rows.filter((sendout) => {
        if (sendout.sendoutId?.includes('SKELETON')) return true;
        if (campaignFilter.length > 0 && !campaignFilter.some((filter) => filter.id === sendout.campaignId)) {
          return false;
        }
        return true;
      }),
    [rows, campaignFilter],
  );

  const filteredData = React.useMemo(
    () =>
      filteredRows.map((val) => {
        const displayErrorCount = (val.sentCount || 0) - (val.deliveredCount || 0);
        if ((val.errors?.total || 0) < displayErrorCount) {
          const unknownStatusCount = displayErrorCount - (val.errors?.total || 0);
          // Show difference as In Progress/Not enough info
          const newErrors = {
            items: [
              {
                error: {
                  key: '$custom_$unknownStatus',
                  code: '$custom',
                  subcode: '$unknownStatus',
                  userMessage: 'In progress/Not enough info',
                },
                count: unknownStatusCount,
              },
              ...(val.errors?.items || []),
            ],
            total: displayErrorCount,
          };
          return {
            ...val,
            errors: newErrors,
          };
        }
        return val;
      }),
    [filteredRows],
  );

  const prevFilteredRows = React.useMemo(
    () =>
      prevRows.filter((sendout) => {
        if (campaignFilter.length > 0 && !campaignFilter.some((filter) => filter.id === sendout.campaignId)) {
          return false;
        }

        return true;
      }),
    [prevRows, campaignFilter],
  );

  const prevFilteredData = React.useMemo(
    () =>
      prevFilteredRows.map((val) => {
        const displayErrorCount = (val.sentCount || 0) - (val.deliveredCount || 0);
        if ((val.errors?.total || 0) < displayErrorCount) {
          const unknownStatusCount = displayErrorCount - (val.errors?.total || 0);
          // Show difference as In Progress/Not enough info
          const newErrors = {
            items: [
              {
                error: {
                  key: '$custom_$unknownStatus',
                  code: '$custom',
                  subcode: '$unknownStatus',
                  userMessage: 'In progress/Not enough info',
                },
                count: unknownStatusCount,
              },
              ...(val.errors?.items || []),
            ],
            total: displayErrorCount,
          };
          return {
            ...val,
            errors: newErrors,
          };
        }
        return val;
      }),
    [prevFilteredRows],
  );

  const handleRecentCampaignReportsDialogClose = () => {
    setRecentCampaignReportsDialogOpen(false);
  };

  const handleGenerateCampaignReportDialogClose = () => {
    setGenerateCampaignReportDialogOpen(false);
  };

  return {
    generateCampaignReportDialogOpen,
    filteredData,
    recentCampaignReportsDialogOpen,
    sendoutsExportData,
    prevFilteredData,
    handleGenerateCampaignReportDialogClose,
    handleRecentCampaignReportsDialogClose,
    isLoading: isLoading || prevIsLoading,
    reportTimeData,
  };
};

export const AnalyticsSendoutsOverview = () => {
  const {
    generateCampaignReportDialogOpen,
    filteredData,
    recentCampaignReportsDialogOpen,
    sendoutsExportData,
    prevFilteredData,
    handleGenerateCampaignReportDialogClose,
    handleRecentCampaignReportsDialogClose,
    isLoading,
    reportTimeData,
  } = useAnalyticsSendoutsOverview();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <AnalyticsRecentCampaignReportsDialog
        open={recentCampaignReportsDialogOpen}
        onClose={handleRecentCampaignReportsDialogClose}
      />
      {generateCampaignReportDialogOpen && (
        <AnalyticsRequestGenerateCampaignReportDialog
          open={generateCampaignReportDialogOpen}
          onClose={handleGenerateCampaignReportDialogClose}
          reportTimeData={reportTimeData}
        />
      )}
      <Box sx={{ height: '100%' }}>
        <Stack gap={6}>
          {!sendoutsExportData && (
            <AnalyticsSendoutsAggregateData filteredRows={filteredData} prevFilteredData={prevFilteredData} />
          )}

          {!sendoutsExportData && <BestPerforming filteredData={filteredData} isLoading={isLoading} isAnalyticsPage />}

          <AnalyticsSendoutsTable filteredRows={filteredData} isLoading={isLoading} />
        </Stack>
      </Box>
    </Box>
  );
};
