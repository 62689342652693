import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import {
  AUDIENCE_COHORTS_QUERY_URL,
  AudienceCohortsOptions,
  AudienceCohortsParameters,
  AudienceCohortsResponse,
} from './types';

export const useAudienceSegmentCohorts = <TData = AudienceCohortsResponse>(
  { businessId, segmentId }: AudienceCohortsParameters,
  options: AudienceCohortsOptions<TData> = {},
) =>
  useQuery(
    ['audienceCohort', segmentId || ''],
    () =>
      api.audienceV1(AUDIENCE_COHORTS_QUERY_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { segmentId } },
      }),
    options,
  );
