import { atom } from 'jotai';
import { DATE_PERIOD_LIST, DEFAULT_DATE_PERIOD } from '@hooks';
import { PageLayoutEnhancements } from '@components/PageLayout';
import { CampaignOption } from '@components/CampaignAutocomplete';
import { SelectedSendout } from '../scenes/Analytics/types';
import { DateRange } from '../components/DateRangePicker/types';
import { DATE_RANGES } from '../hooks/useDateRange/constants';

export const dateRangeAtom = atom<DateRange>({
  ...DATE_RANGES.last1Month,
  key: 'selection',
});

export const dateRangeLast7DaysAtom = atom<DateRange>({
  ...DATE_RANGES.last7Days,
  key: 'selection',
});

const dateInUTC = () => new Date(`${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`);

export const overviewDatesAtom = atom(DATE_PERIOD_LIST[DEFAULT_DATE_PERIOD].getDates(dateInUTC()));
export const conversationFilterAtom = atom<string>('ALL');
export const conversationFilterCategoriesAtom = atom<string[]>([]);

export const campaignFilterV2Atom = atom<CampaignOption[]>([]);
export const selectedAtom = atom<readonly SelectedSendout[]>([]);
export const recentCampaignReportsDialogOpenAtom = atom<boolean>(false);
export const generateCampaignReportDialogOpenAtom = atom<boolean>(false);
export const sendoutsExportDataAtom = atom<boolean>(false);
export const analyticsPageEnhancementsAtom = atom<PageLayoutEnhancements>({});
