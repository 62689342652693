const NOTIFICATION_AUDIO_DELAY_MS = 5000;
const NOTIFICATION_AUDIO_FILENAME = './notification.mp3';

function getAudioUrl(audio: string): string {
  return `${process.env.PUBLIC_URL}/${audio}`;
}

export interface Notifications {
  update(): void;
}

export default class AudioNotifications implements Notifications {
  private nextSoundAt = new Date();

  private sound: HTMLAudioElement | undefined;

  constructor() {
    this.sound = undefined;
    window.onload = () => {
      this.unlockDeviceAudio();
    };
  }

  update = (): void => {
    if (document.hasFocus()) {
      return;
    }

    const { sound } = this;
    if (!sound) {
      return;
    }

    const now = new Date();
    if (now > this.nextSoundAt) {
      try {
        sound.currentTime = 0;
        sound.play();
      } finally {
        const nextSoundAt = new Date();
        nextSoundAt.setMilliseconds(nextSoundAt.getMilliseconds() + NOTIFICATION_AUDIO_DELAY_MS);
        this.nextSoundAt = nextSoundAt;
      }
    }
  };

  unlockAudio = (): void => {
    try {
      const sound = new Audio(getAudioUrl(NOTIFICATION_AUDIO_FILENAME));
      sound.setAttribute('mozaudiochannel', 'notification');
      sound.autoplay = true;
      sound.pause();

      this.sound = sound;
    } finally {
      document.body.removeEventListener('click', this.unlockAudio);
      document.body.removeEventListener('touchstart', this.unlockAudio);
    }
  };

  // first sound should be invoked on user interaction
  unlockDeviceAudio = (): void => {
    document.body.addEventListener('click', this.unlockAudio);
    document.body.addEventListener('touchstart', this.unlockAudio);
  };
}
