import React, { useCallback, useContext } from 'react';
import { Logger } from '@connectlyai-tenets/logging';
import { LoggerContext, LoggerDeps } from '../../contexts';

export const useLogger = () => {
  const { logger } = useContext(LoggerContext);

  const sugar: (additionalContext: Record<string, unknown>) => {
    logger: Logger;
    loggerDeps: LoggerDeps;
  } = useCallback(
    (additionalContext) => {
      const newLogger = logger.sugar(additionalContext);

      const loggerDeps: LoggerDeps = {
        logger: newLogger,
      };

      return { logger: newLogger, loggerDeps };
    },
    [logger],
  );
  return { logger, sugar };
};
