import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import { DeleteSegmentResponse, DeleteSegmentOptions, DeleteSegmentPath, DELETE_SEGMENT_URL } from './types';

export const useMutationDeleteSegment = (options?: DeleteSegmentOptions) =>
  useMutation(
    ({ businessId, segmentId }: DeleteSegmentPath) =>
      api.audienceV1(DELETE_SEGMENT_URL, 'delete', {
        path: { businessId, segmentId },
      }) as Promise<DeleteSegmentResponse>,
    options,
  );
