import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const EVENT_REQUEST_GENERATE_CAMPAIGN_REPORT_URL =
  '/internal/v1/businesses/{input.businessId}/event_request_generate_campaign_report/reports';

type EventRequestGenerateCampaignReportURL = typeof EVENT_REQUEST_GENERATE_CAMPAIGN_REPORT_URL;

export type EventRequestGenerateCampaignReportResponse =
  Paths['reportV1'][EventRequestGenerateCampaignReportURL]['post']['responses']['202'];
export type EventRequestGenerateCampaignReportInput = {
  businessId: string;
} & Paths['reportV1'][EventRequestGenerateCampaignReportURL]['post']['parameters']['body']['input'];
export type EventRequestGenerateCampaignReportOptions = Omit<
  UseMutationOptions<
    EventRequestGenerateCampaignReportResponse,
    ConnectlyError,
    EventRequestGenerateCampaignReportInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
