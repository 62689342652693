import { ComposeTemplatedMessageInput, TemplatedMessage, } from '@connectlyai-idl/models/dist/events/connectly/templated_message/v1/models';
export const composeTemplatedMessageProvider = (log, networkClient) => async (businessId, input) => {
    const url = `/internal/v1/businesses/${businessId}/compose/templated_message`;
    log.debug('composeTemplatedMessage@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.post(url, ComposeTemplatedMessageInput.toJSON(input));
        log.debug('composeTemplatedMessage@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return TemplatedMessage.fromJSON(resp.data.message);
    }
    catch (err) {
        log.error('composeTemplatedMessage@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
