import React, { FC } from 'react';
import { Label, TableRow, TableCell, WithSkeleton } from '@connectlyai-tenets/ui-styled-web';
import { RowProps } from './types';

const SkeletonRow: FC<RowProps> = ({ campaign, createdAt, scheduledAt }) => (
  <TableRow>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
      }}
    >
      <WithSkeleton wrap>
        <Label
          variant="subtitle2"
          sx={{
            '& > a': (theme) => ({ color: theme.palette.primary.main, textDecoration: 'none' }),
          }}
        >
          {campaign?.name}
        </Label>
      </WithSkeleton>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <WithSkeleton wrap>
        <Label variant="body1">{createdAt}</Label>
      </WithSkeleton>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <WithSkeleton wrap>
        <Label variant="body1" sx={{ fontWeight: 500 }}>
          Connectly
        </Label>
      </WithSkeleton>
      <WithSkeleton wrap>
        <Label variant="body2">{scheduledAt}</Label>
      </WithSkeleton>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
      }}
    >
      <WithSkeleton wrap />
    </TableCell>
  </TableRow>
);

export default SkeletonRow;
