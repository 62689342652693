import { styled, styledMui } from '@connectlyai-tenets/ui-styled-web';
import { SIZE_SPACING_INTER_COMPONENT } from './ui-theme';

export const AppContainer = styledMui('div')`
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const InboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const InboxContent = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1 1 auto;
  min-width: 0;
  ${(props) => props.theme.breakpoints.down('md')} {
    border-radius: 0;
    gap: 0;
    padding: 0;
  }
  background: ${(props) => props.theme.palette.background.default};
`;

export const SidebarContainer = styled.div`
  position: relative;
  z-index: 100;

  flex-direction: column;
  overflow-y: hidden;
  width: max-content;
  min-width: max-content;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);

  background: var(--windowBackgroundColor);

  ${(props) => props.theme.breakpoints.down('sm')} {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
  }
`;

export const InboxPaneContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
`;

export const MainPane = styled.div<{ isFirstPane: boolean }>`
  position: relative;
  z-index: 100;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  background: var(--windowBackgroundColor);
`;

export const EmptyPanel = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--paperBackgroundColor);
`;

export const Blank = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: var(--toolbarHeight);
  margin-bottom: var(--toolbarHeight);

  padding: 20px;
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  line-height: 1.6em;
  text-align: center;
  gap: ${(props) => props.theme.spacing(SIZE_SPACING_INTER_COMPONENT)};
`;

export const BlankBody = styled.span`
  color: ${(props) => props.theme.palette.text.secondary};
`;
