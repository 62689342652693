import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/system';
export const LoadingGif = ({ sx, size = '64px' }) => {
    return (_jsx(Box, { sx: [
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: size,
                height: size,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: _jsx("img", { src: "/boo.gif", alt: "Loading...", width: size, height: size }) }));
};
