import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  FLOW_EVENT_REQUEST_DEPLOY_URL,
  FlowEventRequestDeployResponse,
  FlowEventRequestDeployOptions,
  FlowEventRequestDeployInput,
} from './types';

export const useEventRequestDeployReactFlowDocument = (options?: FlowEventRequestDeployOptions) => {
  return useMutation(
    (input: FlowEventRequestDeployInput) =>
      api.flowV1(FLOW_EVENT_REQUEST_DEPLOY_URL, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }) as Promise<FlowEventRequestDeployResponse>,
    {
      ...options,
    },
  );
};
