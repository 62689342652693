import { InstallIntegrationInput } from '../entities';
export const mutationInstallIntegrationProvider = (log, networkClient) => async (input) => {
    log.debug('installIntegrationPresentation@start', () => ({}));
    try {
        const { businessId } = input;
        const url = `/internal/v1/businesses/${businessId}/install/integrations`;
        const resp = await networkClient.post(url, InstallIntegrationInput.toJSON(input));
        log.debug('installIntegrationPresentation@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return { integrationId: resp.data.integrationId };
    }
    catch (err) {
        log.error('installIntegrationPresentation@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
