import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { Box, Button, Grid, Label, TextField, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts';
import { useEffectOnce } from '../../hooks';
import { LoginProps, useOnLogin } from '../../hooks/useOnLogin';
import { useSignup } from '../../hooks/useSignup';
import { InboxExperience } from '../../hooks/useSignup/types';
import { isEmail } from '../../presentation/preview/utils';
import { SIZE_CONTENT_M } from '../../ui-theme';
import { applyHook, track } from '../../utils';
import { SignupEmailActivation } from '../SignupEmailActivation';
import { INITIAL_SIGNUP_STATE } from './constants';
import { BusinessInformation, ShopifySignupProps, SignupRequest } from './types';

const validateInput = (value: string, msg: string): string => (value.trim() === '' ? msg : '');

const useShopifySignup = ({
  audience = '',
  redirectPath = '',
}: Partial<LoginProps>): Partial<ShopifySignupProps> & Partial<LoginProps> => {
  const { search } = window.location;
  const { t } = useTranslation('translation', { keyPrefix: 'signup.validation' });
  const searchParams = new URLSearchParams(search);
  const { notificationProvider } = useContext(NotificationContext);
  const [formState, setFormState] = useState<BusinessInformation>(INITIAL_SIGNUP_STATE);
  const [formError, setFormError] = useState<BusinessInformation>(INITIAL_SIGNUP_STATE);
  const [token, setToken] = useState<string | undefined>(undefined);
  const { onLogin } = useOnLogin({ audience, redirectPath });

  useEffectOnce(() => {
    track('(onboarding) load signup');
  });

  useEffect(() => {
    const storeUrl = searchParams.get('install_shopify_shop_subdomain');
    if (storeUrl) {
      localStorage.setItem('shopify_shop_subdomain', storeUrl);
    }
  }, [searchParams]);

  const { mutate: mutateSignUp, isLoading: isSignupLoading } = useSignup({
    onSuccess: (res) => {
      setToken(res.signupToken);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('signupFail'),
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  const handleInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState({
        ...formState,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    },
    [setFormState, formState],
  );

  const handleSubmit = () => {
    const experience = searchParams.get('inbox_experience');
    const formValidation: BusinessInformation = {
      firstName: validateInput(formState.firstName, t('firstNameInput')),
      lastName: validateInput(formState.lastName, t('lastNameInput')),
      businessName: validateInput(formState.businessName, t('businessInput')),
      email: validateInput(formState.email, t('emailInput')),
    };
    if (Object.values(formValidation).filter((v) => v !== '').length) {
      setFormError(formValidation);
      return;
    }

    if (!isEmail(formState.email)) {
      setFormError({
        ...formState,
        email: t('email'),
      });
    }

    const signupReq: SignupRequest = {
      displayName: `${formState.firstName.trim()} ${formState.lastName.trim()}`,
      businessName: formState.businessName,
      email: formState.email,
      experience: (experience ?? 'INBOX_EXPERIENCE_UNSPECIFIED') as unknown as InboxExperience,
    };
    mutateSignUp(signupReq);
  };

  return {
    formState,
    formError,
    isLoading: isSignupLoading,
    token,
    onInputChange: handleInputChange,
    onSubmit: handleSubmit,
    onLogin,
  };
};

const ShopifySignUpPresentation = ({
  formState,
  formError,
  isLoading,
  token,
  onInputChange,
  onSubmit,
  onLogin,
}: ShopifySignupProps & Partial<LoginProps>) => {
  const theme = useTheme();

  const { t } = useTranslation('translation', { keyPrefix: 'signup' });

  const Form = (
    <>
      <Box
        component="img"
        sx={{
          width: '92px',
          height: '92px',
        }}
        src={`${process.env.PUBLIC_URL}/assets/logo_black.svg`}
      />
      <Label variant="h6">{t('createAccount')}</Label>
      <TextField
        fullWidth
        label={t('firstName')}
        name="firstName"
        value={formState.firstName}
        error={formError.firstName !== ''}
        helperText={formError.firstName}
        variant="standard"
        onChange={onInputChange}
        inputProps={{ 'data-testid': 'input-firstname' }}
        data-testid="firstname"
      />
      <TextField
        fullWidth
        label={t('lastName')}
        name="lastName"
        value={formState.lastName}
        error={formError.lastName !== ''}
        helperText={formError.lastName}
        variant="standard"
        onChange={onInputChange}
        inputProps={{ 'data-testid': 'input-lastname' }}
        data-testid="lastname"
      />
      <TextField
        fullWidth
        label={t('businessName')}
        name="businessName"
        value={formState.businessName}
        error={formError.businessName !== ''}
        helperText={formError.businessName}
        variant="standard"
        onChange={onInputChange}
        inputProps={{ 'data-testid': 'input-businessname' }}
        data-testid="businessname"
      />
      <TextField
        fullWidth
        label={t('businessEmail')}
        name="email"
        value={formState.email}
        error={formError.email !== ''}
        helperText={formError.email}
        variant="standard"
        onChange={onInputChange}
        inputProps={{ 'data-testid': 'input-email' }}
        data-testid="email"
      />
      <Grid container spacing={2} columns={2}>
        <Grid item xs>
          <Button fullWidth variant="contained" onClick={() => onSubmit()} disabled={isLoading}>
            {t('signup')}
          </Button>
        </Grid>
        <Grid item xs>
          <Button fullWidth variant="outlined" color="secondary" disabled={isLoading} onClick={() => onLogin()}>
            {t('login')}
          </Button>
        </Grid>
      </Grid>
      <Label variant="body2" sx={{ color: theme.palette.grey.A700 }}>
        {t('caption')}
      </Label>
    </>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        background: theme.palette.skyBlue,
      }}
    >
      <Box
        component="img"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 1 / 4,
        }}
        src={`${process.env.PUBLIC_URL}/assets/shopify_signup_bg.png`}
      />
      <Box
        sx={{
          px: 3,
          py: 2,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: theme.spacing(SIZE_CONTENT_M),
          background: theme.palette.common.white,
        }}
      >
        {token ? <SignupEmailActivation signupToken={token} /> : Form}
      </Box>
    </Box>
  );
};

export const ShopifySignUp = applyHook(ShopifySignUpPresentation, useShopifySignup);
