import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { SidebarDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectSearchIsActive } from '../../state/searchSlice';
export const ActionbarComponentId = '235fbd44-96d9-4ae9-bd88-3d02ef2762b6';
const ActionBar = ({ Search }) => {
    var _a;
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const searchIsActive = useFeatureSelector(selectSearchIsActive);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(ActionbarComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { Search: Search, TopBarNavigation: (_a = di.extensions) === null || _a === void 0 ? void 0 : _a.TopBarNavigation, searchIsActive: searchIsActive })) }));
};
export default ActionBar;
