import React, { ChangeEvent, useState, useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { selectedPhoneListAudienceAtom } from '@atoms/audience';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { useQueryClient } from '@tanstack/react-query';
import {
  useTheme,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Label,
} from '@connectlyai-tenets/ui-styled-web';
import {
  AudienceSegmentsResponse,
  useCreateAudienceSegmentMutation,
  useFileUploadMutation,
  useStandardNotifications,
} from '@hooks';
import { DropZone } from '@components';
import { SIZE_SPACING_INTER_COMPONENT, SIZE_SPACING_INTER_SECTION } from '../../../../../ui-theme';
import { AudienceName, NAME_MAX_LENGTH } from '../AudienceName';
import { ACCEPTED_FILE_TYPES } from '../constants';
import { CreateSpreadsheetAudienceProps } from './types';

export const CreateSpreadsheetAudience = ({
  cancelText,
  createText,
  businessId,
  closeModal,
  title,
  width,
  isMediaMd,
}: CreateSpreadsheetAudienceProps) => {
  const setSelectedPhoneListAudienceId = useSetAtom(selectedPhoneListAudienceAtom);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [fileError, setFileError] = useState('');
  const [helperText, setHelperText] = useState('');
  const [spreadsheetFileUri, setSpreadsheetFileUri] = useState<string | null>(null);
  const { isLoading: isUploading, mutate: uploadFile, isSuccess } = useFileUploadMutation();
  const queryClient = useQueryClient();
  const { notifySuccess: notifySaveSuccess, notifyError: notifySaveError } = useStandardNotifications({
    successMessage: 'Audience Created',
    errorMessage: 'Something went wrong while uploading audience. Please try again.',
  });
  const { isLoading: isSaving, mutate: saveAudienceList } = useCreateAudienceSegmentMutation({
    onMutate: () => {},
    onError: () => {
      notifySaveError();
    },
    onSuccess: (value) => {
      const hasData = Boolean(queryClient.getQueryData<AudienceSegmentsResponse>(['audienceSegments']));
      if (!hasData) {
        return;
      }

      if (value) {
        queryClient.setQueryData<AudienceSegmentsResponse>(['audienceSegments'], (data) =>
          produce(data, (draft: AudienceSegmentsResponse) => {
            if (value.entity) {
              draft?.entity?.segments?.unshift(value.entity);
            }

            return draft;
          }),
        );
        if (value.entity?.segment?.id) {
          setSelectedPhoneListAudienceId(value.entity?.segment?.id);
        }
      }
      setName('');
      setHelperText('');
      setSpreadsheetFileUri(null);
      closeModal();
      notifySaveSuccess();
    },
  });
  const { notifyError: notifyUploadError } = useStandardNotifications({
    errorMessage: 'Something went wrong while uploading file. Please try again.',
  });
  const handleUploadFile = useCallback(
    async (file: File) => {
      if (!file || !businessId) {
        return;
      }
      const { name: fileName, type } = file;
      if (!ACCEPTED_FILE_TYPES.includes(type)) {
        setFileError('Invalid file type. Please upload a .csv file');
        return;
      }
      if (!name) {
        // initialize name
        setName(fileName.split('.')[0].slice(0, NAME_MAX_LENGTH));
      }
      uploadFile(
        {
          businessId,
          file,
        },
        {
          onSuccess: (data) => {
            const { uri } = data;
            setSpreadsheetFileUri(uri);
            setFileError('');
          },
          onError: () => {
            notifyUploadError();
          },
        },
      );
    },
    [businessId, name, notifyUploadError, uploadFile],
  );
  const onNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.currentTarget.value);
    },
    [setName],
  );
  const handleSubmit = useCallback(() => {
    if (!spreadsheetFileUri || !name) {
      if (!spreadsheetFileUri) {
        setFileError('Please upload a file');
      }
      if (!name) {
        setNameError('Please enter a name');
      }
      return;
    }
    saveAudienceList({
      businessId,
      segmentId: uuidv4(),
      input: {
        name,
        query: { phoneList: {} },
        dataInput: {
          phoneListImport: {
            importUrl: spreadsheetFileUri,
          },
        },
      },
    });
  }, [businessId, name, saveAudienceList, spreadsheetFileUri]);
  const theme = useTheme();

  return (
    <Stack sx={{ flex: isMediaMd ? '0 0 auto' : `1 1 ${theme.spacing(width)} ` }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Label variant="h4">{title}</Label>
        </Stack>
      </DialogTitle>
      <Divider
        sx={{
          mx: SIZE_SPACING_INTER_SECTION,
          my: SIZE_SPACING_INTER_COMPONENT,
          borderColor: theme.palette.grey[200],
        }}
      />
      <DialogContent sx={{ pt: 1 }}>
        <Stack gap={SIZE_SPACING_INTER_SECTION}>
          <AudienceName name={name} error={nameError} onChange={onNameChange} />
          <DropZone
            handleUploadFile={handleUploadFile}
            sx={{ height: theme.spacing(24.4) }}
            errorHelperText={fileError}
            isSuccess={isSuccess}
            acceptedFileTypesText={`Accepted file types: ${ACCEPTED_FILE_TYPES.join(', ')}`}
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
          pt: isMediaMd || helperText ? 4.5 : 7.25,
        }}
      >
        <Button variant="outlined" color="secondary" onClick={closeModal}>
          {cancelText}
        </Button>
        <Button variant="contained" color="primary" disabled={isSaving || isUploading} onClick={handleSubmit}>
          {createText}
        </Button>
      </DialogActions>
    </Stack>
  );
};
