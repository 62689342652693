// This component is for development purposes.
// Adapted from source https://github.com/jotaijs/jotai-devtools/issues/58

import React from 'react';
import { css, Global } from '@emotion/react';
import { DevTools, DevToolsProps } from 'jotai-devtools';

// Context: https://github.com/jotaijs/jotai-devtools/issues/58
// To be removed if JotaiDevToolsPanel is exported upstream

const jotaiStyle = css`
  /* hide trigger button */
  .jotai-devtools-trigger-button {
    display: none !important;
  }

  /* position the panel relative to its container */
  .jotai-devtools-shell {
    margin-top: 16px !important;
    position: relative !important;
    flex-grow: 1;
    z-index: auto !important;
  }

  /* hide panel header to save space and to make 'minimize' unreachable */
  .jotai-devtools-shell > div:nth-of-type(2) {
    display: none !important;
  }

  /* text become blurry border radius is applied */
  .jotai-devtools-i3l3in {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`;

export const JotaiDevToolsPanel = (props: DevToolsProps) => (
  <>
    <Global styles={jotaiStyle} />
    <DevTools isInitialOpen {...props} />
  </>
);
