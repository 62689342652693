import { useContext, useEffect, useMemo, useState } from 'react';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { AxiosInstanceBuilder } from '@connectlyai-tenets/network-request';
import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { WidgetScriptType } from './types';
import { NotificationContext } from '../../contexts';
import { WIDGET_CLIENT_TIMEOUT_MS } from './constants';
import packageJson from '../../../package.json';

export const useWidget = (): WidgetScriptType => {
  const [script, setScript] = useState('');
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);

  const widgetClient = useMemo(
    () =>
      new AxiosInstanceBuilder()
        .withBaseURL(process.env.REACT_APP_INBOX_WIDGET_BASELOADER_URI)
        .withTimeout(WIDGET_CLIENT_TIMEOUT_MS)
        .build(),
    [],
  );
  useEffect(() => {
    if (!businessId) return;
    // declare the data fetching function
    const fetchData = async () => {
      try {
        const resp = await widgetClient.get('/');

        const tagLoaderUrl = new URL(process.env.REACT_APP_INBOX_WIDGET_TAGLOADER_SRC || '');
        tagLoaderUrl.searchParams.append('v', packageJson.version);
        const hydradedScript = resp.data
          .replace('{BUSINESS_ID}', businessId)
          .replace('{TAG_LOADER_SRC}', tagLoaderUrl.href)
          .replace('{MODULES_WIDGET_POSITION}', 'fixed')
          .replace('{POSITION_RIGHT}', '8px')
          .replace('{POSITION_BOTTOM}', '8px');

        setScript(hydradedScript);
      } catch (err) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'Failed to fetch widget content',
            severity: NotificationSeverity.ERROR,
            icon: '',
          },
        });
      }
    };
    fetchData();
  }, [notificationProvider, widgetClient, businessId]);
  const scriptTag = `<script>${script}</script>`;

  return {
    script: scriptTag,
  };
};
