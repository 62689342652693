import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { nodesAtom } from '@atoms/flow';
import { selectMessageTemplateIndex, useMessageTemplateGroupsData } from '@hooks/useMessageTemplateGroupsData';
import { useMeData, selectBusinessId } from '@hooks/useMeData';
import { EMPTY_TEMPLATE_STATUS } from './constants';
import { TemplateStatuses } from './types';

export const useTemplateStatus: () => TemplateStatuses = () => {
  const nodes = useAtomValue(nodesAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: templateIndex = {}, isLoading: isTemplatesLoading } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateIndex,
    keepPreviousData: true,
  });

  const { pendingTemplates, notExistingTemplates, rejectedTemplates } = useMemo(
    () =>
      isTemplatesLoading
        ? cloneDeep(EMPTY_TEMPLATE_STATUS)
        : nodes.reduce<TemplateStatuses>((acc, node) => {
            if (node?.type !== 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE') return acc;
            if (!node?.data?.v1?.templateId) return acc;
            const remoteTemplate = templateIndex[node?.data?.v1?.templateId];
            if (!remoteTemplate) return { ...acc, notExistingTemplates: [...acc.notExistingTemplates, node.id] };
            if (remoteTemplate?.template?.status === 'MESSAGE_TEMPLATE_STATUS_PENDING')
              return { ...acc, pendingTemplates: [...acc.pendingTemplates, node.id] };
            if (remoteTemplate?.template?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED')
              return { ...acc, rejectedTemplates: [...acc.rejectedTemplates, node.id] };
            return acc;
          }, cloneDeep(EMPTY_TEMPLATE_STATUS)),
    [nodes, templateIndex, isTemplatesLoading],
  );
  return { pendingTemplates, notExistingTemplates, rejectedTemplates };
};
