import React from 'react';
import { SofiaStarsIcon, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { SOFIA_DOCS_LINK } from '@scenes/Sofia/constants';

const useSofiaCTA = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.home.sofia' });

  return {
    title: t('title'),
    subtitle: t('subtitle'),
    actionText: t('actionText'),
    linkHref: SOFIA_DOCS_LINK,
  };
};

export const SofiaCTAv2 = ({ navigate }: { navigate: () => void }) => {
  const { title, subtitle, actionText } = useSofiaCTA();
  return (
    <ConnectlyCard
      title={title}
      subtitle={subtitle}
      icon={<SofiaStarsIcon fill="#942AFF" />}
      actions={{ primaryButton: { onClick: navigate, children: actionText } }}
    />
  );
};
