import { MessageTemplate } from '../../hooks/useMessageTemplateGroupsData';

export type UnsentMessageTemplate = MessageTemplate & {
  name: string;
  errors: Partial<TemplateBuilderErrors> | undefined;
};

export enum HeaderType {
  Text,
  Image,
  Video,
  Document,
}

export type MediaType = 'VARIABLE' | 'MEDIA';
export type MediaTypeDisplay = 'Use a variable' | 'Upload a file';
export type MediaTypeDict = Record<MediaType, MediaTypeDisplay>;

export enum ButtonGroup {
  QuickReply,
  CallToAction,
}

export type TemplateComponentEnabled = {
  header: boolean;
  footer: boolean;
  buttons: boolean;
  list: boolean;
};

export type ButtonError = {
  text: string;
  url: string;
  phoneNumber: string;
};

export type ButtonState = {
  url: string;
  tracked: boolean;
};

export type TemplateBuilderUiState = {
  buttons: Partial<ButtonState>[] | undefined;
  // Temporary variable mappings that will be converted to actual mappings.
  // Should use the form paramName: "{{variableFqn}}"
  mappings: {
    [key: string]: string;
  };
};

export type TemplateBuilderErrors = {
  templateId: string;
  header: string;
  body: string;
  footer: string;
  buttons: Partial<ButtonError>[];
  listButton: string;
  list: Partial<string>[];
};

export type TemplateBuilderParams = {
  nodeId: string;
  messageTemplate: UnsentMessageTemplate;
  templateId: string | null;
  previewTemplateId: string | null;
  buttonsGroup: ButtonGroup;
  canChangeButtonsGroup: boolean;
  headerType: HeaderType;
  isComponentEnabled: TemplateComponentEnabled;
  errors: Partial<TemplateBuilderErrors> | undefined;
  uiState: TemplateBuilderUiState;
};

export type TemplateApprovalDialogParams = {
  open: boolean;
};
