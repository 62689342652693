import { Timestamp } from "../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
import { Struct } from "../../google/protobuf/struct";
export const protobufPackage = "models.link";
function createBaseLink() {
    return {
        id: "",
        slug: "",
        businessId: "",
        displayName: "",
        redirectUrl: "",
        shortUrl: "",
        createdAt: undefined,
        expiresAt: undefined,
        deletedAt: undefined,
    };
}
export const Link = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.slug !== "") {
            writer.uint32(18).string(message.slug);
        }
        if (message.businessId !== "") {
            writer.uint32(26).string(message.businessId);
        }
        if (message.displayName !== "") {
            writer.uint32(34).string(message.displayName);
        }
        if (message.redirectUrl !== "") {
            writer.uint32(42).string(message.redirectUrl);
        }
        if (message.shortUrl !== "") {
            writer.uint32(50).string(message.shortUrl);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(170).fork()).ldelim();
        }
        if (message.expiresAt !== undefined) {
            Timestamp.encode(toTimestamp(message.expiresAt), writer.uint32(178).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(186).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLink();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.businessId = reader.string();
                    break;
                case 4:
                    message.displayName = reader.string();
                    break;
                case 5:
                    message.redirectUrl = reader.string();
                    break;
                case 6:
                    message.shortUrl = reader.string();
                    break;
                case 21:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.expiresAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 23:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            slug: isSet(object.slug) ? String(object.slug) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
            redirectUrl: isSet(object.redirectUrl) ? String(object.redirectUrl) : "",
            shortUrl: isSet(object.shortUrl) ? String(object.shortUrl) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            expiresAt: isSet(object.expiresAt) ? String(object.expiresAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.slug !== undefined && (obj.slug = message.slug);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.displayName !== undefined &&
            (obj.displayName = message.displayName);
        message.redirectUrl !== undefined &&
            (obj.redirectUrl = message.redirectUrl);
        message.shortUrl !== undefined && (obj.shortUrl = message.shortUrl);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.expiresAt !== undefined && (obj.expiresAt = message.expiresAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBaseCustomerContext() {
    return {
        customerChannelId: "",
        sendoutId: "",
        campaignId: "",
        flowDocumentId: "",
        flowDocumentVersionId: "",
        flowDocumentEtaDescriptorId: "",
        flowDocumentName: "",
        additionalData: undefined,
    };
}
export const CustomerContext = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.customerChannelId !== "") {
            writer.uint32(10).string(message.customerChannelId);
        }
        if (message.sendoutId !== "") {
            writer.uint32(18).string(message.sendoutId);
        }
        if (message.campaignId !== "") {
            writer.uint32(26).string(message.campaignId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(34).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(42).string(message.flowDocumentVersionId);
        }
        if (message.flowDocumentEtaDescriptorId !== "") {
            writer.uint32(50).string(message.flowDocumentEtaDescriptorId);
        }
        if (message.flowDocumentName !== "") {
            writer.uint32(58).string(message.flowDocumentName);
        }
        if (message.additionalData !== undefined) {
            Struct.encode(Struct.wrap(message.additionalData), writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerContext();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customerChannelId = reader.string();
                    break;
                case 2:
                    message.sendoutId = reader.string();
                    break;
                case 3:
                    message.campaignId = reader.string();
                    break;
                case 4:
                    message.flowDocumentId = reader.string();
                    break;
                case 5:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 6:
                    message.flowDocumentEtaDescriptorId = reader.string();
                    break;
                case 7:
                    message.flowDocumentName = reader.string();
                    break;
                case 21:
                    message.additionalData = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customerChannelId: isSet(object.customerChannelId)
                ? String(object.customerChannelId)
                : "",
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            flowDocumentEtaDescriptorId: isSet(object.flowDocumentEtaDescriptorId)
                ? String(object.flowDocumentEtaDescriptorId)
                : "",
            flowDocumentName: isSet(object.flowDocumentName)
                ? String(object.flowDocumentName)
                : "",
            additionalData: isObject(object.additionalData)
                ? object.additionalData
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.customerChannelId !== undefined &&
            (obj.customerChannelId = message.customerChannelId);
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.flowDocumentEtaDescriptorId !== undefined &&
            (obj.flowDocumentEtaDescriptorId = message.flowDocumentEtaDescriptorId);
        message.flowDocumentName !== undefined &&
            (obj.flowDocumentName = message.flowDocumentName);
        message.additionalData !== undefined &&
            (obj.additionalData = message.additionalData);
        return obj;
    },
};
function createBaseCustomerContextDescriptor() {
    return { id: "", slug: "", linkId: "", businessId: "", context: undefined };
}
export const CustomerContextDescriptor = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.slug !== "") {
            writer.uint32(18).string(message.slug);
        }
        if (message.linkId !== "") {
            writer.uint32(26).string(message.linkId);
        }
        if (message.businessId !== "") {
            writer.uint32(34).string(message.businessId);
        }
        if (message.context !== undefined) {
            CustomerContext.encode(message.context, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerContextDescriptor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.linkId = reader.string();
                    break;
                case 4:
                    message.businessId = reader.string();
                    break;
                case 21:
                    message.context = CustomerContext.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            slug: isSet(object.slug) ? String(object.slug) : "",
            linkId: isSet(object.linkId) ? String(object.linkId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            context: isSet(object.context)
                ? CustomerContext.fromJSON(object.context)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.slug !== undefined && (obj.slug = message.slug);
        message.linkId !== undefined && (obj.linkId = message.linkId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.context !== undefined &&
            (obj.context = message.context
                ? CustomerContext.toJSON(message.context)
                : undefined);
        return obj;
    },
};
function createBaseClickMetadata() {
    return {
        ipAddr: "",
        userAgent: "",
        allHeaders: undefined,
        allQueryParams: undefined,
    };
}
export const ClickMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ipAddr !== "") {
            writer.uint32(10).string(message.ipAddr);
        }
        if (message.userAgent !== "") {
            writer.uint32(18).string(message.userAgent);
        }
        if (message.allHeaders !== undefined) {
            Struct.encode(Struct.wrap(message.allHeaders), writer.uint32(170).fork()).ldelim();
        }
        if (message.allQueryParams !== undefined) {
            Struct.encode(Struct.wrap(message.allQueryParams), writer.uint32(178).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseClickMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ipAddr = reader.string();
                    break;
                case 2:
                    message.userAgent = reader.string();
                    break;
                case 21:
                    message.allHeaders = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.allQueryParams = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ipAddr: isSet(object.ipAddr) ? String(object.ipAddr) : "",
            userAgent: isSet(object.userAgent) ? String(object.userAgent) : "",
            allHeaders: isObject(object.allHeaders) ? object.allHeaders : undefined,
            allQueryParams: isObject(object.allQueryParams)
                ? object.allQueryParams
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ipAddr !== undefined && (obj.ipAddr = message.ipAddr);
        message.userAgent !== undefined && (obj.userAgent = message.userAgent);
        message.allHeaders !== undefined && (obj.allHeaders = message.allHeaders);
        message.allQueryParams !== undefined &&
            (obj.allQueryParams = message.allQueryParams);
        return obj;
    },
};
function createBaseLinkCreateLinkBodyV1() {
    return { redirectUrl: "", displayName: "", expiresAt: undefined };
}
export const LinkCreateLinkBodyV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.redirectUrl !== "") {
            writer.uint32(10).string(message.redirectUrl);
        }
        if (message.displayName !== "") {
            writer.uint32(18).string(message.displayName);
        }
        if (message.expiresAt !== undefined) {
            Timestamp.encode(toTimestamp(message.expiresAt), writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLinkCreateLinkBodyV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.redirectUrl = reader.string();
                    break;
                case 2:
                    message.displayName = reader.string();
                    break;
                case 21:
                    message.expiresAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            redirectUrl: isSet(object.redirectUrl) ? String(object.redirectUrl) : "",
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
            expiresAt: isSet(object.expiresAt) ? String(object.expiresAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.redirectUrl !== undefined &&
            (obj.redirectUrl = message.redirectUrl);
        message.displayName !== undefined &&
            (obj.displayName = message.displayName);
        message.expiresAt !== undefined && (obj.expiresAt = message.expiresAt);
        return obj;
    },
};
function createBaseLinkCreateCustomerContextBodyV1() {
    return {
        customerChannelId: "",
        sendoutId: "",
        campaignId: "",
        flowDocumentId: "",
        flowDocumentVersionId: "",
        flowDocumentEtaDescriptorId: "",
        flowDocumentName: "",
        additionalData: undefined,
    };
}
export const LinkCreateCustomerContextBodyV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.customerChannelId !== "") {
            writer.uint32(10).string(message.customerChannelId);
        }
        if (message.sendoutId !== "") {
            writer.uint32(18).string(message.sendoutId);
        }
        if (message.campaignId !== "") {
            writer.uint32(26).string(message.campaignId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(34).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(42).string(message.flowDocumentVersionId);
        }
        if (message.flowDocumentEtaDescriptorId !== "") {
            writer.uint32(50).string(message.flowDocumentEtaDescriptorId);
        }
        if (message.flowDocumentName !== "") {
            writer.uint32(58).string(message.flowDocumentName);
        }
        if (message.additionalData !== undefined) {
            Struct.encode(Struct.wrap(message.additionalData), writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLinkCreateCustomerContextBodyV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customerChannelId = reader.string();
                    break;
                case 2:
                    message.sendoutId = reader.string();
                    break;
                case 3:
                    message.campaignId = reader.string();
                    break;
                case 4:
                    message.flowDocumentId = reader.string();
                    break;
                case 5:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 6:
                    message.flowDocumentEtaDescriptorId = reader.string();
                    break;
                case 7:
                    message.flowDocumentName = reader.string();
                    break;
                case 21:
                    message.additionalData = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customerChannelId: isSet(object.customerChannelId)
                ? String(object.customerChannelId)
                : "",
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            flowDocumentEtaDescriptorId: isSet(object.flowDocumentEtaDescriptorId)
                ? String(object.flowDocumentEtaDescriptorId)
                : "",
            flowDocumentName: isSet(object.flowDocumentName)
                ? String(object.flowDocumentName)
                : "",
            additionalData: isObject(object.additionalData)
                ? object.additionalData
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.customerChannelId !== undefined &&
            (obj.customerChannelId = message.customerChannelId);
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        message.flowDocumentEtaDescriptorId !== undefined &&
            (obj.flowDocumentEtaDescriptorId = message.flowDocumentEtaDescriptorId);
        message.flowDocumentName !== undefined &&
            (obj.flowDocumentName = message.flowDocumentName);
        message.additionalData !== undefined &&
            (obj.additionalData = message.additionalData);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
