export const ANALYTICS_ROUTER_ID = '/analytics';
export const BROADCAST_ROUTER_ID = '/campaigns';
export const INBOX_ROUTER_ID = '/inbox';
export const FLOW_ROUTER_ID = '/flow';
export const SETTINGS_ROUTER_ID = '/settings';
export const SIDEPANE_ROUTER_ID = '/sidepane';
export const SIGNUP_ROUTER_ID = '/signup';
export const SMB_SETTINGS = '/smb_settings';

export const connectionIssuesSet = new Set(['ping timeout', 'transport close', 'transport error']);

export const SOFIA_AI_FILTER_TAG_ID = '462d1fae-b76c-11ee-87cc-0242ac120003';
