import { Paths } from '@connectlyai-tenets/sdk';

export const CHANGE_ROLE_URL = '/v1/businesses/{businessId}/managed_users/{userId}';
export type ChangeRoleUrl = typeof CHANGE_ROLE_URL;

export type ChangeUserRoleQuery = Paths['profileV1'][ChangeRoleUrl]['patch']['parameters']['body']['input'];

export type ChangeUserRolePath = Paths['profileV1'][ChangeRoleUrl]['patch']['parameters']['path'];

export type ChangeUserRoleResponse = Paths['profileV1'][ChangeRoleUrl]['patch']['responses']['200']['schema'];
