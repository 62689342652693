import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@hooks';
import {
  Box,
  Button,
  Label,
  useTheme,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
  NewLabel,
  blueGrey,
  purple,
} from '@connectlyai-tenets/ui-styled-web';
import { LightTooltip } from '@components/Tooltips';
import { AppSidebarItemProps } from './types';

export const Item = ({ icon, onClick, open, title, active, announcement }: AppSidebarItemProps) => {
  const theme = useTheme();
  const sx = {
    textTransform: 'none',
    borderRadius: 1.5,
    py: 1,
    px: 1.75,
    textDecoration: 'none',
    justifyContent: 'flex-start',
    minHeight: theme.spacing(5),
    minWidth: '0 !important',
    gap: 2,
    backgroundColor: active ? blueGrey[100] : theme.palette.common.white,
    width: '100%',
    boxSizing: 'border-box',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover p': {
      color: blueGrey[900],
    },
    '&:hover path': {
      stroke: blueGrey[900],
    },
    '& path': {
      stroke: active ? purple[700] : blueGrey[500],
    },
  };
  const { t } = useTranslation('translation', { keyPrefix: 'sidebar.announcements' });
  const localStorageKey = `${announcement}-hidden`;
  const { on: announcementOn, name: announcementName } = announcement || {};
  const [announcementHidden, setAnnouncementHidden] = useState(
    !announcementOn || localStorage.getItem(localStorageKey) === 'true',
  );
  useEffect(() => {
    const hidden = localStorage.getItem(localStorageKey) === 'true';
    if (hidden !== announcementHidden) {
      setAnnouncementHidden(hidden);
    }
  }, [announcementHidden, localStorageKey, open]);
  const handleHide = useCallback(() => {
    setAnnouncementHidden(true);
    localStorage.setItem(localStorageKey, 'true');
  }, [localStorageKey]);
  const isMediaLg = useMediaQuery(theme.breakpoints.up('lg'));
  const announcementTooltip = useMemo(() => {
    const {
      title: announcementTitle,
      subtitle: announcementSubtitle,
      dismiss,
    } = {
      title: announcement ? t(`${announcementName}.title`, 'Title') : '',
      subtitle: announcement ? t(`${announcementName}.subtitle`, 'Subtitle') : '',
      dismiss: announcement ? t(`${announcementName}.dismiss`, 'Dismiss') : '',
    };
    if (announcement) {
      return (
        <Card
          sx={{
            width: theme.spacing(isMediaLg ? 53 : 48),
            boxShadow: '0px 4px 9.1px 0px #00000040',
            p: 1.5,
          }}
        >
          <CardContent sx={{ p: 1 }}>
            <NewLabel sx={{ width: 'fit-content' }} />
            <Label variant="h6" sx={{ fontWeight: 'bold !important', pt: 0.5, pb: 1 }}>
              {announcementTitle}
            </Label>
            <Label variant="body1" color="text.secondary">
              {announcementSubtitle}
            </Label>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleHide} size="small">
              {dismiss}
            </Button>
          </CardActions>
        </Card>
      );
    }
    return null;
  }, [announcement, announcementName, handleHide, isMediaLg, t, theme]);
  return (
    <>
      {open && (
        <LightTooltip
          title={announcementTooltip}
          placement="right"
          arrow
          open={Boolean(announcementTooltip && !announcementHidden)}
        >
          <Button variant="contained" color="secondary" sx={sx} onClick={onClick} fullWidth>
            <Box
              component={icon}
              width={theme.spacing(2.5)}
              height={theme.spacing(2.5)}
              sx={{ flex: `0 0 ${theme.spacing(2.5)}` }}
            />
            <Label
              noWrap
              variant="body1"
              color={active ? theme.palette.primary.main : blueGrey[500]}
              sx={{ minWidth: 0, textOverflow: 'clip' }}
            >
              {title}
            </Label>
          </Button>
        </LightTooltip>
      )}
      {!open && (
        <Tooltip title={title} placement="right">
          <Button variant="contained" color="secondary" sx={sx} onClick={onClick} fullWidth>
            <Box component={icon} width={theme.spacing(2.5)} height={theme.spacing(2.5)} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
