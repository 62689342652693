import Long from "long";
import { VariableDeclaration } from "./lang/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.flow";
export var FlowDocumentType;
(function (FlowDocumentType) {
    FlowDocumentType[FlowDocumentType["FLOW_DOCUMENT_TYPE_UNSPECIFIED"] = 0] = "FLOW_DOCUMENT_TYPE_UNSPECIFIED";
    FlowDocumentType[FlowDocumentType["FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1"] = 1] = "FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1";
    FlowDocumentType[FlowDocumentType["FLOW_DOCUMENT_TYPE_ETA_V1"] = 2] = "FLOW_DOCUMENT_TYPE_ETA_V1";
    FlowDocumentType[FlowDocumentType["FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1"] = 3] = "FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1";
    FlowDocumentType[FlowDocumentType["FLOW_DOCUMENT_TYPE_SENDOUT_V3"] = 4] = "FLOW_DOCUMENT_TYPE_SENDOUT_V3";
    FlowDocumentType[FlowDocumentType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowDocumentType || (FlowDocumentType = {}));
export function flowDocumentTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "FLOW_DOCUMENT_TYPE_UNSPECIFIED":
            return FlowDocumentType.FLOW_DOCUMENT_TYPE_UNSPECIFIED;
        case 1:
        case "FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1":
            return FlowDocumentType.FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1;
        case 2:
        case "FLOW_DOCUMENT_TYPE_ETA_V1":
            return FlowDocumentType.FLOW_DOCUMENT_TYPE_ETA_V1;
        case 3:
        case "FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1":
            return FlowDocumentType.FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1;
        case 4:
        case "FLOW_DOCUMENT_TYPE_SENDOUT_V3":
            return FlowDocumentType.FLOW_DOCUMENT_TYPE_SENDOUT_V3;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowDocumentType.UNRECOGNIZED;
    }
}
export function flowDocumentTypeToJSON(object) {
    switch (object) {
        case FlowDocumentType.FLOW_DOCUMENT_TYPE_UNSPECIFIED:
            return "FLOW_DOCUMENT_TYPE_UNSPECIFIED";
        case FlowDocumentType.FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1:
            return "FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1";
        case FlowDocumentType.FLOW_DOCUMENT_TYPE_ETA_V1:
            return "FLOW_DOCUMENT_TYPE_ETA_V1";
        case FlowDocumentType.FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1:
            return "FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1";
        case FlowDocumentType.FLOW_DOCUMENT_TYPE_SENDOUT_V3:
            return "FLOW_DOCUMENT_TYPE_SENDOUT_V3";
        case FlowDocumentType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var FlowDocumentCategory;
(function (FlowDocumentCategory) {
    FlowDocumentCategory[FlowDocumentCategory["FLOW_DOCUMENT_CATEGORY_UNSPECIFIED"] = 0] = "FLOW_DOCUMENT_CATEGORY_UNSPECIFIED";
    FlowDocumentCategory[FlowDocumentCategory["FLOW_DOCUMENT_CATEGORY_CAMPAIGN"] = 1] = "FLOW_DOCUMENT_CATEGORY_CAMPAIGN";
    FlowDocumentCategory[FlowDocumentCategory["FLOW_DOCUMENT_CATEGORY_AUTOMATION"] = 2] = "FLOW_DOCUMENT_CATEGORY_AUTOMATION";
    FlowDocumentCategory[FlowDocumentCategory["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowDocumentCategory || (FlowDocumentCategory = {}));
export function flowDocumentCategoryFromJSON(object) {
    switch (object) {
        case 0:
        case "FLOW_DOCUMENT_CATEGORY_UNSPECIFIED":
            return FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_UNSPECIFIED;
        case 1:
        case "FLOW_DOCUMENT_CATEGORY_CAMPAIGN":
            return FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_CAMPAIGN;
        case 2:
        case "FLOW_DOCUMENT_CATEGORY_AUTOMATION":
            return FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_AUTOMATION;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowDocumentCategory.UNRECOGNIZED;
    }
}
export function flowDocumentCategoryToJSON(object) {
    switch (object) {
        case FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_UNSPECIFIED:
            return "FLOW_DOCUMENT_CATEGORY_UNSPECIFIED";
        case FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_CAMPAIGN:
            return "FLOW_DOCUMENT_CATEGORY_CAMPAIGN";
        case FlowDocumentCategory.FLOW_DOCUMENT_CATEGORY_AUTOMATION:
            return "FLOW_DOCUMENT_CATEGORY_AUTOMATION";
        case FlowDocumentCategory.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var FlowObjectType;
(function (FlowObjectType) {
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_UNSPECIFIED"] = 0] = "FLOW_OBJECT_TYPE_UNSPECIFIED";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_SIMPLE_EDGE"] = 1] = "FLOW_OBJECT_TYPE_SIMPLE_EDGE";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_SEND_WA_MESSAGE"] = 2] = "FLOW_OBJECT_TYPE_SEND_WA_MESSAGE";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN"] = 3] = "FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY"] = 4] = "FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT"] = 5] = "FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE"] = 6] = "FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT"] = 7] = "FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT";
    FlowObjectType[FlowObjectType["FLOW_OBJECT_TYPE_AI_ASSISTANT"] = 8] = "FLOW_OBJECT_TYPE_AI_ASSISTANT";
    FlowObjectType[FlowObjectType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowObjectType || (FlowObjectType = {}));
export function flowObjectTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "FLOW_OBJECT_TYPE_UNSPECIFIED":
            return FlowObjectType.FLOW_OBJECT_TYPE_UNSPECIFIED;
        case 1:
        case "FLOW_OBJECT_TYPE_SIMPLE_EDGE":
            return FlowObjectType.FLOW_OBJECT_TYPE_SIMPLE_EDGE;
        case 2:
        case "FLOW_OBJECT_TYPE_SEND_WA_MESSAGE":
            return FlowObjectType.FLOW_OBJECT_TYPE_SEND_WA_MESSAGE;
        case 3:
        case "FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN":
            return FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN;
        case 4:
        case "FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY":
            return FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY;
        case 5:
        case "FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT":
            return FlowObjectType.FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT;
        case 6:
        case "FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE":
            return FlowObjectType.FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE;
        case 7:
        case "FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT":
            return FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT;
        case 8:
        case "FLOW_OBJECT_TYPE_AI_ASSISTANT":
            return FlowObjectType.FLOW_OBJECT_TYPE_AI_ASSISTANT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowObjectType.UNRECOGNIZED;
    }
}
export function flowObjectTypeToJSON(object) {
    switch (object) {
        case FlowObjectType.FLOW_OBJECT_TYPE_UNSPECIFIED:
            return "FLOW_OBJECT_TYPE_UNSPECIFIED";
        case FlowObjectType.FLOW_OBJECT_TYPE_SIMPLE_EDGE:
            return "FLOW_OBJECT_TYPE_SIMPLE_EDGE";
        case FlowObjectType.FLOW_OBJECT_TYPE_SEND_WA_MESSAGE:
            return "FLOW_OBJECT_TYPE_SEND_WA_MESSAGE";
        case FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN:
            return "FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN";
        case FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY:
            return "FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY";
        case FlowObjectType.FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT:
            return "FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT";
        case FlowObjectType.FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE:
            return "FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE";
        case FlowObjectType.FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT:
            return "FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT";
        case FlowObjectType.FLOW_OBJECT_TYPE_AI_ASSISTANT:
            return "FLOW_OBJECT_TYPE_AI_ASSISTANT";
        case FlowObjectType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var FlowDocumentDeployIntent;
(function (FlowDocumentDeployIntent) {
    FlowDocumentDeployIntent[FlowDocumentDeployIntent["FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED"] = 0] = "FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED";
    FlowDocumentDeployIntent[FlowDocumentDeployIntent["FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT"] = 1] = "FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT";
    FlowDocumentDeployIntent[FlowDocumentDeployIntent["FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY"] = 2] = "FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY";
    FlowDocumentDeployIntent[FlowDocumentDeployIntent["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FlowDocumentDeployIntent || (FlowDocumentDeployIntent = {}));
export function flowDocumentDeployIntentFromJSON(object) {
    switch (object) {
        case 0:
        case "FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED":
            return FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED;
        case 1:
        case "FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT":
            return FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT;
        case 2:
        case "FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY":
            return FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FlowDocumentDeployIntent.UNRECOGNIZED;
    }
}
export function flowDocumentDeployIntentToJSON(object) {
    switch (object) {
        case FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED:
            return "FLOW_DOCUMENT_DEPLOY_INTENT_UNSPECIFIED";
        case FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT:
            return "FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT";
        case FlowDocumentDeployIntent.FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY:
            return "FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY";
        case FlowDocumentDeployIntent.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseFlowObjectPropertyValue() {
    return { valueOneof: undefined };
}
export const FlowObjectPropertyValue = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "f64") {
            writer.uint32(9).double(message.valueOneof.f64);
        }
        if (((_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.$case) === "f32") {
            writer.uint32(21).float(message.valueOneof.f32);
        }
        if (((_c = message.valueOneof) === null || _c === void 0 ? void 0 : _c.$case) === "i32") {
            writer.uint32(24).int32(message.valueOneof.i32);
        }
        if (((_d = message.valueOneof) === null || _d === void 0 ? void 0 : _d.$case) === "i64") {
            writer.uint32(32).int64(message.valueOneof.i64);
        }
        if (((_e = message.valueOneof) === null || _e === void 0 ? void 0 : _e.$case) === "u32") {
            writer.uint32(40).uint32(message.valueOneof.u32);
        }
        if (((_f = message.valueOneof) === null || _f === void 0 ? void 0 : _f.$case) === "u64") {
            writer.uint32(48).uint64(message.valueOneof.u64);
        }
        if (((_g = message.valueOneof) === null || _g === void 0 ? void 0 : _g.$case) === "si32") {
            writer.uint32(56).sint32(message.valueOneof.si32);
        }
        if (((_h = message.valueOneof) === null || _h === void 0 ? void 0 : _h.$case) === "si64") {
            writer.uint32(64).sint64(message.valueOneof.si64);
        }
        if (((_j = message.valueOneof) === null || _j === void 0 ? void 0 : _j.$case) === "fi32") {
            writer.uint32(77).fixed32(message.valueOneof.fi32);
        }
        if (((_k = message.valueOneof) === null || _k === void 0 ? void 0 : _k.$case) === "fi64") {
            writer.uint32(81).fixed64(message.valueOneof.fi64);
        }
        if (((_l = message.valueOneof) === null || _l === void 0 ? void 0 : _l.$case) === "sf32") {
            writer.uint32(93).sfixed32(message.valueOneof.sf32);
        }
        if (((_m = message.valueOneof) === null || _m === void 0 ? void 0 : _m.$case) === "sf64") {
            writer.uint32(97).sfixed64(message.valueOneof.sf64);
        }
        if (((_o = message.valueOneof) === null || _o === void 0 ? void 0 : _o.$case) === "bool") {
            writer.uint32(104).bool(message.valueOneof.bool);
        }
        if (((_p = message.valueOneof) === null || _p === void 0 ? void 0 : _p.$case) === "str") {
            writer.uint32(114).string(message.valueOneof.str);
        }
        if (((_q = message.valueOneof) === null || _q === void 0 ? void 0 : _q.$case) === "bytes") {
            writer.uint32(122).bytes(message.valueOneof.bytes);
        }
        if (((_r = message.valueOneof) === null || _r === void 0 ? void 0 : _r.$case) === "tomb") {
            writer.uint32(128).bool(message.valueOneof.tomb);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObjectPropertyValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valueOneof = { $case: "f64", f64: reader.double() };
                    break;
                case 2:
                    message.valueOneof = { $case: "f32", f32: reader.float() };
                    break;
                case 3:
                    message.valueOneof = { $case: "i32", i32: reader.int32() };
                    break;
                case 4:
                    message.valueOneof = {
                        $case: "i64",
                        i64: longToNumber(reader.int64()),
                    };
                    break;
                case 5:
                    message.valueOneof = { $case: "u32", u32: reader.uint32() };
                    break;
                case 6:
                    message.valueOneof = {
                        $case: "u64",
                        u64: longToNumber(reader.uint64()),
                    };
                    break;
                case 7:
                    message.valueOneof = { $case: "si32", si32: reader.sint32() };
                    break;
                case 8:
                    message.valueOneof = {
                        $case: "si64",
                        si64: longToNumber(reader.sint64()),
                    };
                    break;
                case 9:
                    message.valueOneof = { $case: "fi32", fi32: reader.fixed32() };
                    break;
                case 10:
                    message.valueOneof = {
                        $case: "fi64",
                        fi64: longToNumber(reader.fixed64()),
                    };
                    break;
                case 11:
                    message.valueOneof = { $case: "sf32", sf32: reader.sfixed32() };
                    break;
                case 12:
                    message.valueOneof = {
                        $case: "sf64",
                        sf64: longToNumber(reader.sfixed64()),
                    };
                    break;
                case 13:
                    message.valueOneof = { $case: "bool", bool: reader.bool() };
                    break;
                case 14:
                    message.valueOneof = { $case: "str", str: reader.string() };
                    break;
                case 15:
                    message.valueOneof = { $case: "bytes", bytes: reader.bytes() };
                    break;
                case 16:
                    message.valueOneof = { $case: "tomb", tomb: reader.bool() };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            valueOneof: isSet(object.f64)
                ? { $case: "f64", f64: Number(object.f64) }
                : isSet(object.f32)
                    ? { $case: "f32", f32: Number(object.f32) }
                    : isSet(object.i32)
                        ? { $case: "i32", i32: Number(object.i32) }
                        : isSet(object.i64)
                            ? { $case: "i64", i64: Number(object.i64) }
                            : isSet(object.u32)
                                ? { $case: "u32", u32: Number(object.u32) }
                                : isSet(object.u64)
                                    ? { $case: "u64", u64: Number(object.u64) }
                                    : isSet(object.si32)
                                        ? { $case: "si32", si32: Number(object.si32) }
                                        : isSet(object.si64)
                                            ? { $case: "si64", si64: Number(object.si64) }
                                            : isSet(object.fi32)
                                                ? { $case: "fi32", fi32: Number(object.fi32) }
                                                : isSet(object.fi64)
                                                    ? { $case: "fi64", fi64: Number(object.fi64) }
                                                    : isSet(object.sf32)
                                                        ? { $case: "sf32", sf32: Number(object.sf32) }
                                                        : isSet(object.sf64)
                                                            ? { $case: "sf64", sf64: Number(object.sf64) }
                                                            : isSet(object.bool)
                                                                ? { $case: "bool", bool: Boolean(object.bool) }
                                                                : isSet(object.str)
                                                                    ? { $case: "str", str: String(object.str) }
                                                                    : isSet(object.bytes)
                                                                        ? { $case: "bytes", bytes: bytesFromBase64(object.bytes) }
                                                                        : isSet(object.tomb)
                                                                            ? { $case: "tomb", tomb: Boolean(object.tomb) }
                                                                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
        const obj = {};
        ((_a = message.valueOneof) === null || _a === void 0 ? void 0 : _a.$case) === "f64" && (obj.f64 = (_b = message.valueOneof) === null || _b === void 0 ? void 0 : _b.f64);
        ((_c = message.valueOneof) === null || _c === void 0 ? void 0 : _c.$case) === "f32" && (obj.f32 = (_d = message.valueOneof) === null || _d === void 0 ? void 0 : _d.f32);
        ((_e = message.valueOneof) === null || _e === void 0 ? void 0 : _e.$case) === "i32" &&
            (obj.i32 = Math.round((_f = message.valueOneof) === null || _f === void 0 ? void 0 : _f.i32));
        ((_g = message.valueOneof) === null || _g === void 0 ? void 0 : _g.$case) === "i64" &&
            (obj.i64 = Math.round((_h = message.valueOneof) === null || _h === void 0 ? void 0 : _h.i64));
        ((_j = message.valueOneof) === null || _j === void 0 ? void 0 : _j.$case) === "u32" &&
            (obj.u32 = Math.round((_k = message.valueOneof) === null || _k === void 0 ? void 0 : _k.u32));
        ((_l = message.valueOneof) === null || _l === void 0 ? void 0 : _l.$case) === "u64" &&
            (obj.u64 = Math.round((_m = message.valueOneof) === null || _m === void 0 ? void 0 : _m.u64));
        ((_o = message.valueOneof) === null || _o === void 0 ? void 0 : _o.$case) === "si32" &&
            (obj.si32 = Math.round((_p = message.valueOneof) === null || _p === void 0 ? void 0 : _p.si32));
        ((_q = message.valueOneof) === null || _q === void 0 ? void 0 : _q.$case) === "si64" &&
            (obj.si64 = Math.round((_r = message.valueOneof) === null || _r === void 0 ? void 0 : _r.si64));
        ((_s = message.valueOneof) === null || _s === void 0 ? void 0 : _s.$case) === "fi32" &&
            (obj.fi32 = Math.round((_t = message.valueOneof) === null || _t === void 0 ? void 0 : _t.fi32));
        ((_u = message.valueOneof) === null || _u === void 0 ? void 0 : _u.$case) === "fi64" &&
            (obj.fi64 = Math.round((_v = message.valueOneof) === null || _v === void 0 ? void 0 : _v.fi64));
        ((_w = message.valueOneof) === null || _w === void 0 ? void 0 : _w.$case) === "sf32" &&
            (obj.sf32 = Math.round((_x = message.valueOneof) === null || _x === void 0 ? void 0 : _x.sf32));
        ((_y = message.valueOneof) === null || _y === void 0 ? void 0 : _y.$case) === "sf64" &&
            (obj.sf64 = Math.round((_z = message.valueOneof) === null || _z === void 0 ? void 0 : _z.sf64));
        ((_0 = message.valueOneof) === null || _0 === void 0 ? void 0 : _0.$case) === "bool" &&
            (obj.bool = (_1 = message.valueOneof) === null || _1 === void 0 ? void 0 : _1.bool);
        ((_2 = message.valueOneof) === null || _2 === void 0 ? void 0 : _2.$case) === "str" && (obj.str = (_3 = message.valueOneof) === null || _3 === void 0 ? void 0 : _3.str);
        ((_4 = message.valueOneof) === null || _4 === void 0 ? void 0 : _4.$case) === "bytes" &&
            (obj.bytes =
                ((_5 = message.valueOneof) === null || _5 === void 0 ? void 0 : _5.bytes) !== undefined
                    ? base64FromBytes((_6 = message.valueOneof) === null || _6 === void 0 ? void 0 : _6.bytes)
                    : undefined);
        ((_7 = message.valueOneof) === null || _7 === void 0 ? void 0 : _7.$case) === "tomb" &&
            (obj.tomb = (_8 = message.valueOneof) === null || _8 === void 0 ? void 0 : _8.tomb);
        return obj;
    },
};
function createBaseFlowObjectProperty() {
    return { revisionId: "", flowObjectId: "", key: "", value: undefined };
}
export const FlowObjectProperty = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.revisionId !== "") {
            writer.uint32(10).string(message.revisionId);
        }
        if (message.flowObjectId !== "") {
            writer.uint32(18).string(message.flowObjectId);
        }
        if (message.key !== "") {
            writer.uint32(26).string(message.key);
        }
        if (message.value !== undefined) {
            FlowObjectPropertyValue.encode(message.value, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObjectProperty();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.revisionId = reader.string();
                    break;
                case 2:
                    message.flowObjectId = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.value = FlowObjectPropertyValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
            flowObjectId: isSet(object.flowObjectId)
                ? String(object.flowObjectId)
                : "",
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? FlowObjectPropertyValue.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        message.flowObjectId !== undefined &&
            (obj.flowObjectId = message.flowObjectId);
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? FlowObjectPropertyValue.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseFlowObject() {
    return { id: "", type: 0 };
}
export const FlowObject = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowObject();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            type: isSet(object.type) ? flowObjectTypeFromJSON(object.type) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.type !== undefined &&
            (obj.type = flowObjectTypeToJSON(message.type));
        return obj;
    },
};
function createBaseFlowDocument() {
    return {
        id: "",
        businessId: "",
        type: 0,
        category: 0,
        name: "",
        objects: [],
        properties: [],
        latestVersion: undefined,
        revisionId: "",
    };
}
export const FlowDocument = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.type !== 0) {
            writer.uint32(24).int32(message.type);
        }
        if (message.category !== 0) {
            writer.uint32(32).int32(message.category);
        }
        if (message.name !== "") {
            writer.uint32(42).string(message.name);
        }
        for (const v of message.objects) {
            FlowObject.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.properties) {
            FlowObjectProperty.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.latestVersion !== undefined) {
            FlowDocumentVersion.encode(message.latestVersion, writer.uint32(66).fork()).ldelim();
        }
        if (message.revisionId !== "") {
            writer.uint32(74).string(message.revisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocument();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.category = reader.int32();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.objects.push(FlowObject.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.properties.push(FlowObjectProperty.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.latestVersion = FlowDocumentVersion.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.revisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            type: isSet(object.type) ? flowDocumentTypeFromJSON(object.type) : 0,
            category: isSet(object.category)
                ? flowDocumentCategoryFromJSON(object.category)
                : 0,
            name: isSet(object.name) ? String(object.name) : "",
            objects: Array.isArray(object === null || object === void 0 ? void 0 : object.objects)
                ? object.objects.map((e) => FlowObject.fromJSON(e))
                : [],
            properties: Array.isArray(object === null || object === void 0 ? void 0 : object.properties)
                ? object.properties.map((e) => FlowObjectProperty.fromJSON(e))
                : [],
            latestVersion: isSet(object.latestVersion)
                ? FlowDocumentVersion.fromJSON(object.latestVersion)
                : undefined,
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.type !== undefined &&
            (obj.type = flowDocumentTypeToJSON(message.type));
        message.category !== undefined &&
            (obj.category = flowDocumentCategoryToJSON(message.category));
        message.name !== undefined && (obj.name = message.name);
        if (message.objects) {
            obj.objects = message.objects.map((e) => e ? FlowObject.toJSON(e) : undefined);
        }
        else {
            obj.objects = [];
        }
        if (message.properties) {
            obj.properties = message.properties.map((e) => e ? FlowObjectProperty.toJSON(e) : undefined);
        }
        else {
            obj.properties = [];
        }
        message.latestVersion !== undefined &&
            (obj.latestVersion = message.latestVersion
                ? FlowDocumentVersion.toJSON(message.latestVersion)
                : undefined);
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        return obj;
    },
};
function createBaseFlowDocumentVersion() {
    return {
        id: "",
        businessId: "",
        versionId: "",
        flowDocumentId: "",
        name: "",
        deployIntent: 0,
        compileOutput: undefined,
    };
}
export const FlowDocumentVersion = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.versionId !== "") {
            writer.uint32(26).string(message.versionId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(34).string(message.flowDocumentId);
        }
        if (message.name !== "") {
            writer.uint32(42).string(message.name);
        }
        if (message.deployIntent !== 0) {
            writer.uint32(48).int32(message.deployIntent);
        }
        if (message.compileOutput !== undefined) {
            FlowDocumentCompileOutput.encode(message.compileOutput, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentVersion();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.versionId = reader.string();
                    break;
                case 4:
                    message.flowDocumentId = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.deployIntent = reader.int32();
                    break;
                case 7:
                    message.compileOutput = FlowDocumentCompileOutput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            versionId: isSet(object.versionId) ? String(object.versionId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            name: isSet(object.name) ? String(object.name) : "",
            deployIntent: isSet(object.deployIntent)
                ? flowDocumentDeployIntentFromJSON(object.deployIntent)
                : 0,
            compileOutput: isSet(object.compileOutput)
                ? FlowDocumentCompileOutput.fromJSON(object.compileOutput)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.versionId !== undefined && (obj.versionId = message.versionId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.name !== undefined && (obj.name = message.name);
        message.deployIntent !== undefined &&
            (obj.deployIntent = flowDocumentDeployIntentToJSON(message.deployIntent));
        message.compileOutput !== undefined &&
            (obj.compileOutput = message.compileOutput
                ? FlowDocumentCompileOutput.toJSON(message.compileOutput)
                : undefined);
        return obj;
    },
};
function createBaseFlowDocumentCompileOutputAutoReplyV1() {
    return { etaFlowId: "", etaFlowRootId: "", dependencies: undefined };
}
export const FlowDocumentCompileOutputAutoReplyV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.etaFlowId !== "") {
            writer.uint32(10).string(message.etaFlowId);
        }
        if (message.etaFlowRootId !== "") {
            writer.uint32(18).string(message.etaFlowRootId);
        }
        if (message.dependencies !== undefined) {
            DependencyCollection.encode(message.dependencies, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentCompileOutputAutoReplyV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.etaFlowId = reader.string();
                    break;
                case 2:
                    message.etaFlowRootId = reader.string();
                    break;
                case 3:
                    message.dependencies = DependencyCollection.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            etaFlowId: isSet(object.etaFlowId) ? String(object.etaFlowId) : "",
            etaFlowRootId: isSet(object.etaFlowRootId)
                ? String(object.etaFlowRootId)
                : "",
            dependencies: isSet(object.dependencies)
                ? DependencyCollection.fromJSON(object.dependencies)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.etaFlowId !== undefined && (obj.etaFlowId = message.etaFlowId);
        message.etaFlowRootId !== undefined &&
            (obj.etaFlowRootId = message.etaFlowRootId);
        message.dependencies !== undefined &&
            (obj.dependencies = message.dependencies
                ? DependencyCollection.toJSON(message.dependencies)
                : undefined);
        return obj;
    },
};
function createBaseFlowDocumentCompileOutputSendoutV3() {
    return { etaFlowId: "", etaFlowRootId: "", dependencies: undefined };
}
export const FlowDocumentCompileOutputSendoutV3 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.etaFlowId !== "") {
            writer.uint32(10).string(message.etaFlowId);
        }
        if (message.etaFlowRootId !== "") {
            writer.uint32(18).string(message.etaFlowRootId);
        }
        if (message.dependencies !== undefined) {
            DependencyCollection.encode(message.dependencies, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentCompileOutputSendoutV3();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.etaFlowId = reader.string();
                    break;
                case 2:
                    message.etaFlowRootId = reader.string();
                    break;
                case 3:
                    message.dependencies = DependencyCollection.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            etaFlowId: isSet(object.etaFlowId) ? String(object.etaFlowId) : "",
            etaFlowRootId: isSet(object.etaFlowRootId)
                ? String(object.etaFlowRootId)
                : "",
            dependencies: isSet(object.dependencies)
                ? DependencyCollection.fromJSON(object.dependencies)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.etaFlowId !== undefined && (obj.etaFlowId = message.etaFlowId);
        message.etaFlowRootId !== undefined &&
            (obj.etaFlowRootId = message.etaFlowRootId);
        message.dependencies !== undefined &&
            (obj.dependencies = message.dependencies
                ? DependencyCollection.toJSON(message.dependencies)
                : undefined);
        return obj;
    },
};
function createBaseFlowDocumentCompileOutput() {
    return { typeOneof: undefined };
}
export const FlowDocumentCompileOutput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "autoReplyV1") {
            FlowDocumentCompileOutputAutoReplyV1.encode(message.typeOneof.autoReplyV1, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "sendoutV3") {
            FlowDocumentCompileOutputSendoutV3.encode(message.typeOneof.sendoutV3, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentCompileOutput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeOneof = {
                        $case: "autoReplyV1",
                        autoReplyV1: FlowDocumentCompileOutputAutoReplyV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.typeOneof = {
                        $case: "sendoutV3",
                        sendoutV3: FlowDocumentCompileOutputSendoutV3.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeOneof: isSet(object.autoReplyV1)
                ? {
                    $case: "autoReplyV1",
                    autoReplyV1: FlowDocumentCompileOutputAutoReplyV1.fromJSON(object.autoReplyV1),
                }
                : isSet(object.sendoutV3)
                    ? {
                        $case: "sendoutV3",
                        sendoutV3: FlowDocumentCompileOutputSendoutV3.fromJSON(object.sendoutV3),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "autoReplyV1" &&
            (obj.autoReplyV1 = ((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.autoReplyV1)
                ? FlowDocumentCompileOutputAutoReplyV1.toJSON((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.autoReplyV1)
                : undefined);
        ((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "sendoutV3" &&
            (obj.sendoutV3 = ((_e = message.typeOneof) === null || _e === void 0 ? void 0 : _e.sendoutV3)
                ? FlowDocumentCompileOutputSendoutV3.toJSON((_f = message.typeOneof) === null || _f === void 0 ? void 0 : _f.sendoutV3)
                : undefined);
        return obj;
    },
};
function createBaseReactFlowNode() {
    return { id: "", options: "" };
}
export const ReactFlowNode = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.options !== "") {
            writer.uint32(18).string(message.options);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowNode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.options = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            options: isSet(object.options) ? String(object.options) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.options !== undefined && (obj.options = message.options);
        return obj;
    },
};
function createBaseReactFlowEdge() {
    return { id: "", options: "" };
}
export const ReactFlowEdge = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.options !== "") {
            writer.uint32(18).string(message.options);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowEdge();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.options = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            options: isSet(object.options) ? String(object.options) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.options !== undefined && (obj.options = message.options);
        return obj;
    },
};
function createBaseReactFlowNodeChange() {
    return { options: "" };
}
export const ReactFlowNodeChange = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.options !== "") {
            writer.uint32(10).string(message.options);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowNodeChange();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.options = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            options: isSet(object.options) ? String(object.options) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.options !== undefined && (obj.options = message.options);
        return obj;
    },
};
function createBaseReactFlowEdgeChange() {
    return { options: "" };
}
export const ReactFlowEdgeChange = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.options !== "") {
            writer.uint32(10).string(message.options);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowEdgeChange();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.options = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            options: isSet(object.options) ? String(object.options) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.options !== undefined && (obj.options = message.options);
        return obj;
    },
};
function createBaseReactFlowNodeObjectUpdate() {
    return { node: undefined };
}
export const ReactFlowNodeObjectUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.node !== undefined) {
            ReactFlowNode.encode(message.node, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReactFlowNodeObjectUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.node = ReactFlowNode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            node: isSet(object.node)
                ? ReactFlowNode.fromJSON(object.node)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.node !== undefined &&
            (obj.node = message.node
                ? ReactFlowNode.toJSON(message.node)
                : undefined);
        return obj;
    },
};
function createBaseDependencyCollection() {
    return { dependencies: [] };
}
export const DependencyCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.dependencies) {
            Dependency.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependencyCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.dependencies.push(Dependency.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            dependencies: Array.isArray(object === null || object === void 0 ? void 0 : object.dependencies)
                ? object.dependencies.map((e) => Dependency.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.dependencies) {
            obj.dependencies = message.dependencies.map((e) => e ? Dependency.toJSON(e) : undefined);
        }
        else {
            obj.dependencies = [];
        }
        return obj;
    },
};
function createBaseDependency() {
    return { nodeIds: [], typeOneof: undefined };
}
export const Dependency = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        for (const v of message.nodeIds) {
            writer.uint32(10).string(v);
        }
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waMessageTemplate") {
            DependencyWhatsappMessageTemplate.encode(message.typeOneof.waMessageTemplate, writer.uint32(82).fork()).ldelim();
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "shortlink") {
            DependencyShortlink.encode(message.typeOneof.shortlink, writer.uint32(90).fork()).ldelim();
        }
        if (((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "variable") {
            DependencyVariable.encode(message.typeOneof.variable, writer.uint32(98).fork()).ldelim();
        }
        if (((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "sendout") {
            DependencySendout.encode(message.typeOneof.sendout, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependency();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeIds.push(reader.string());
                    break;
                case 10:
                    message.typeOneof = {
                        $case: "waMessageTemplate",
                        waMessageTemplate: DependencyWhatsappMessageTemplate.decode(reader, reader.uint32()),
                    };
                    break;
                case 11:
                    message.typeOneof = {
                        $case: "shortlink",
                        shortlink: DependencyShortlink.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.typeOneof = {
                        $case: "variable",
                        variable: DependencyVariable.decode(reader, reader.uint32()),
                    };
                    break;
                case 13:
                    message.typeOneof = {
                        $case: "sendout",
                        sendout: DependencySendout.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeIds: Array.isArray(object === null || object === void 0 ? void 0 : object.nodeIds)
                ? object.nodeIds.map((e) => String(e))
                : [],
            typeOneof: isSet(object.waMessageTemplate)
                ? {
                    $case: "waMessageTemplate",
                    waMessageTemplate: DependencyWhatsappMessageTemplate.fromJSON(object.waMessageTemplate),
                }
                : isSet(object.shortlink)
                    ? {
                        $case: "shortlink",
                        shortlink: DependencyShortlink.fromJSON(object.shortlink),
                    }
                    : isSet(object.variable)
                        ? {
                            $case: "variable",
                            variable: DependencyVariable.fromJSON(object.variable),
                        }
                        : isSet(object.sendout)
                            ? {
                                $case: "sendout",
                                sendout: DependencySendout.fromJSON(object.sendout),
                            }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        if (message.nodeIds) {
            obj.nodeIds = message.nodeIds.map((e) => e);
        }
        else {
            obj.nodeIds = [];
        }
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waMessageTemplate" &&
            (obj.waMessageTemplate = ((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.waMessageTemplate)
                ? DependencyWhatsappMessageTemplate.toJSON((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.waMessageTemplate)
                : undefined);
        ((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "shortlink" &&
            (obj.shortlink = ((_e = message.typeOneof) === null || _e === void 0 ? void 0 : _e.shortlink)
                ? DependencyShortlink.toJSON((_f = message.typeOneof) === null || _f === void 0 ? void 0 : _f.shortlink)
                : undefined);
        ((_g = message.typeOneof) === null || _g === void 0 ? void 0 : _g.$case) === "variable" &&
            (obj.variable = ((_h = message.typeOneof) === null || _h === void 0 ? void 0 : _h.variable)
                ? DependencyVariable.toJSON((_j = message.typeOneof) === null || _j === void 0 ? void 0 : _j.variable)
                : undefined);
        ((_k = message.typeOneof) === null || _k === void 0 ? void 0 : _k.$case) === "sendout" &&
            (obj.sendout = ((_l = message.typeOneof) === null || _l === void 0 ? void 0 : _l.sendout)
                ? DependencySendout.toJSON((_m = message.typeOneof) === null || _m === void 0 ? void 0 : _m.sendout)
                : undefined);
        return obj;
    },
};
function createBaseDependencyWhatsappMessageTemplate() {
    return { templateId: "" };
}
export const DependencyWhatsappMessageTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.templateId !== "") {
            writer.uint32(10).string(message.templateId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependencyWhatsappMessageTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateId: isSet(object.templateId) ? String(object.templateId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.templateId !== undefined && (obj.templateId = message.templateId);
        return obj;
    },
};
function createBaseDependencyShortlink() {
    return { shortlink: "" };
}
export const DependencyShortlink = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shortlink !== "") {
            writer.uint32(10).string(message.shortlink);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependencyShortlink();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shortlink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shortlink: isSet(object.shortlink) ? String(object.shortlink) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.shortlink !== undefined && (obj.shortlink = message.shortlink);
        return obj;
    },
};
function createBaseDependencyVariable() {
    return { variable: undefined };
}
export const DependencyVariable = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.variable !== undefined) {
            VariableDeclaration.encode(message.variable, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependencyVariable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.variable = VariableDeclaration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            variable: isSet(object.variable)
                ? VariableDeclaration.fromJSON(object.variable)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.variable !== undefined &&
            (obj.variable = message.variable
                ? VariableDeclaration.toJSON(message.variable)
                : undefined);
        return obj;
    },
};
function createBaseDependencySendout() {
    return { sendoutId: "", name: "" };
}
export const DependencySendout = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sendoutId !== "") {
            writer.uint32(10).string(message.sendoutId);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDependencySendout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendoutId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
const atob = globalThis.atob ||
    ((b64) => globalThis.Buffer.from(b64, "base64").toString("binary"));
function bytesFromBase64(b64) {
    const bin = atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
        arr[i] = bin.charCodeAt(i);
    }
    return arr;
}
const btoa = globalThis.btoa ||
    ((bin) => globalThis.Buffer.from(bin, "binary").toString("base64"));
function base64FromBytes(arr) {
    const bin = [];
    arr.forEach((byte) => {
        bin.push(String.fromCharCode(byte));
    });
    return btoa(bin.join(""));
}
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
