import React, { useCallback } from 'react';
import { Label, Box, Button, FormControl, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SingleValue, MultiValue, SelectorProps } from './types';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';

export const Selector = <T extends SingleValue | MultiValue>({
  value,
  onChange,
  options,
  label,
  autoFocus,
}: SelectorProps<T>) => {
  const theme = useTheme();
  const multiSelect = Array.isArray(value);
  const onClick = useCallback(
    (target: string) => {
      if (multiSelect) {
        const newSelectedValues = value.includes(target) ? value.filter((v) => v !== target) : [...value, target];
        onChange(newSelectedValues as T);
      } else {
        onChange(target as T);
      }
    },
    [multiSelect, onChange, value],
  );

  return (
    <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 1.5 }}>
      {label && <Label variant="h7">{`${label}:`}</Label>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {options.map((option, i) => {
          const active = multiSelect ? value.includes(option.value) : value === option.value;
          return (
            <Button
              key={option.value}
              autoFocus={autoFocus && i === 0}
              sx={{
                textTransform: 'none',
                background: active ? theme.palette.common.black : theme.palette.common.white,
                color: active ? theme.palette.common.white : theme.palette.common.black,
                py: SIZE_SPACING_INTER_COMPONENT,
                px: 2,
                borderRadius: 4,
                m: 1,
                ml: i === 0 ? 0 : 1,
                mr: i === options.length - 1 ? 0 : 1,
                border: `1px solid ${theme.palette.grey[300]}`,
                cursor: multiSelect || !value.includes(option.value) ? 'pointer' : 'default',
                transition: 'background 0.2s',
                '&:hover': {
                  background: active ? theme.palette.common.black : theme.palette.grey[100],
                },
                '&:focus': {
                  outline: `2px solid ${theme.palette.primary.main}`,
                },
              }}
              onClick={() => onClick(option.value)}
            >
              <Label variant="body1">{option.label || option.value}</Label>
            </Button>
          );
        })}
      </Box>
    </FormControl>
  );
};
