import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  BusinessOrganizationDataResponse,
  BusinessOrganizationDataQuery,
  BusinessOrganizationDataParameters,
} from './types';
import { api } from '../../api';

export const useBusinessOrganizationData = <TData = BusinessOrganizationDataResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<BusinessOrganizationDataResponse, unknown, TData, readonly ['businessOrganization']>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<BusinessOrganizationDataQuery> &
  BusinessOrganizationDataParameters) =>
  useQuery(
    ['businessOrganization'],
    () =>
      api.profileV1('/v1/businesses/{businessId}/fetch_organization', 'post', {
        path: { businessId },
        body: { body: {} },
      }),
    options,
  );
