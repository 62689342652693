import { createSlice } from '@reduxjs/toolkit';
import { FilterByStatusOptions, FilterByChannelOptionsAll, FilterByRoomStatusOptionsAll, } from '../entities';
import { resetStore, setFilterByChannel, setFilterByMarketingStatus, setFilterByRoomStatus, setFilterByStatus, setFilterByTag, setPartialFilterQuery, } from './action';
const initialState = {
    contextFilters: [],
    partialFilterQuery: '',
    selectedFilterByChannel: FilterByChannelOptionsAll,
    selectedFilterByMarketingStatus: false,
    selectedFilterByStatus: FilterByStatusOptions.ALL.key,
    selectedFilterByRoomStatus: FilterByRoomStatusOptionsAll,
    selectedFilterByTag: [],
};
export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setContextFilters: (state, action) => {
            state.contextFilters = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setFilterByChannel, (state, action) => {
            state.selectedFilterByChannel = action.payload;
        });
        builder.addCase(setFilterByMarketingStatus, (state, action) => {
            state.selectedFilterByMarketingStatus = action.payload;
        });
        builder.addCase(setFilterByRoomStatus, (state, action) => {
            state.selectedFilterByRoomStatus = action.payload;
        });
        builder.addCase(setFilterByStatus, (state, action) => {
            if (action.payload in FilterByStatusOptions) {
                state.selectedFilterByStatus = action.payload;
            }
        });
        builder.addCase(setFilterByTag, (state, action) => {
            state.selectedFilterByTag = action.payload;
        });
        builder.addCase(setPartialFilterQuery, (state, action) => {
            state.partialFilterQuery = action.payload;
        });
    },
});
export const { reset, setContextFilters } = filterSlice.actions;
export const selectContextFilters = (state) => state.filter.contextFilters;
export const selectPartialFilterQuery = (state) => state.filter.partialFilterQuery;
export const selectSelectedFilterByChannel = (state) => state.filter.selectedFilterByChannel;
export const selectSelectedFilterByStatus = (state) => state.filter.selectedFilterByStatus;
export const selectSelectedFilterByRoomStatus = (state) => state.filter.selectedFilterByRoomStatus;
export const selectSelectedFilterByMarketingStatus = (state) => state.filter.selectedFilterByMarketingStatus;
export const selectSelectedFilterByTag = (state) => state.filter.selectedFilterByTag.concat(state.filter.contextFilters.map((filter) => filter.id));
export default filterSlice.reducer;
