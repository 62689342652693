import { AgentDescriptorResponse, AgentDescriptor } from './types';

export const selectStoreDescriptorV2Id = (data: AgentDescriptorResponse): string | null => {
  const agentDescriptors = data?.response?.agentDescriptors || [];
  let id = null;
  for (let i = 0; i < agentDescriptors.length; i += 1) {
    const descriptor = agentDescriptors[i];
    const resources = descriptor.resources || [];
    if (resources.length === 1 && resources[0].knowledgeBase) {
      id = descriptor.storeDescriptorId || null;
      break;
    }
  }
  return id;
};

export const selectAgentDescriptorByTag = (data: AgentDescriptorResponse, tag: string): AgentDescriptor | undefined => {
  const agentDescriptors = data?.response?.agentDescriptors;
  if (!agentDescriptors) {
    return undefined;
  }
  const deployedDescriptor = agentDescriptors?.find((desc) => {
    if (Array.isArray(desc?.additionalData?.tags) && desc?.additionalData?.tags.includes(tag)) {
      return true;
    }
    return false;
  });
  return deployedDescriptor;
};

export const selectDeployedAgentDescriptor = (data: AgentDescriptorResponse): AgentDescriptor | undefined => {
  return selectAgentDescriptorByTag(data, 'deployed');
};

export const selectDeployedAgentDescriptorId = (data: AgentDescriptorResponse): string | undefined => {
  return selectDeployedAgentDescriptor(data)?.id;
};

export const selectStagedAgentDescriptor = (data: AgentDescriptorResponse): AgentDescriptor | undefined => {
  return selectAgentDescriptorByTag(data, 'staged');
};

export const selectStagedAgentDescriptorId = (data: AgentDescriptorResponse): string | undefined => {
  return selectStagedAgentDescriptor(data)?.id;
};

type AgentDescriptorsByTag = {
  deployed: AgentDescriptor | undefined;
  staged: AgentDescriptor | undefined;
};
export const selectAgentDescriptors = (data: AgentDescriptorResponse): AgentDescriptorsByTag => {
  return {
    deployed: selectDeployedAgentDescriptor(data),
    staged: selectStagedAgentDescriptor(data),
  };
};

export const selectDeployedAgentPromptAddition = (data: AgentDescriptorResponse): string | undefined => {
  const agent = selectDeployedAgentDescriptor(data);
  const promptResource = agent?.resources?.find((resource) => !!resource.promptAddition);
  return promptResource?.promptAddition?.promptAddition || '';
};
