export function assertUnreachable(value, noThrow) {
    if (value === undefined) {
        throw new Error('Unreachable code');
    }
    if (noThrow) {
        return value;
    }
    throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}
export function unreachable() {
    throw new Error('Unreachable code');
}
