import { Timestamp } from "../../google/protobuf/timestamp";
import { Notification } from "../../events/nubank/nupay/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.payment";
function createBasePaymentNotification() {
    return {
        referenceId: "",
        timestamp: undefined,
        paymentMethodType: "",
        rawOneof: undefined,
    };
}
export const PaymentNotification = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.referenceId !== "") {
            writer.uint32(10).string(message.referenceId);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(26).fork()).ldelim();
        }
        if (message.paymentMethodType !== "") {
            writer.uint32(34).string(message.paymentMethodType);
        }
        if (((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "notification") {
            Notification.encode(message.rawOneof.notification, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaymentNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.referenceId = reader.string();
                    break;
                case 3:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.paymentMethodType = reader.string();
                    break;
                case 11:
                    message.rawOneof = {
                        $case: "notification",
                        notification: Notification.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            referenceId: isSet(object.referenceId) ? String(object.referenceId) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            paymentMethodType: isSet(object.paymentMethodType)
                ? String(object.paymentMethodType)
                : "",
            rawOneof: isSet(object.notification)
                ? {
                    $case: "notification",
                    notification: Notification.fromJSON(object.notification),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.referenceId !== undefined &&
            (obj.referenceId = message.referenceId);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.paymentMethodType !== undefined &&
            (obj.paymentMethodType = message.paymentMethodType);
        ((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "notification" &&
            (obj.notification = ((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.notification)
                ? Notification.toJSON((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.notification)
                : undefined);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
