export const mutationDeactivateIntegrationProvider = (log, networkClient) => async (input) => {
    log.debug('deactivateIntegrationPresentation@start', () => ({}));
    try {
        const { businessId, integrationId } = input;
        const url = `/internal/v1/businesses/${businessId}/uninstall/integrations/${integrationId}`;
        const resp = await networkClient.post(url, input);
        log.debug('deactivateIntegrationPresentation@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.error('deactivateIntegrationPresentation@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
