import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, styled } from '@connectlyai-tenets/ui-styled-web';
const LoaderContainer = styled.div `
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`;
var LoadingShape;
(function (LoadingShape) {
    LoadingShape[LoadingShape["CIRCULAR"] = 0] = "CIRCULAR";
})(LoadingShape || (LoadingShape = {}));
export const Loader = ({ type = LoadingShape.CIRCULAR }) => (_jsx(LoaderContainer, { children: type === LoadingShape.CIRCULAR && _jsx(CircularProgress, {}) }));
Loader.defaultProps = {
    type: LoadingShape.CIRCULAR,
};
export const WithLoading = ({ type = LoadingShape.CIRCULAR, children, }) => (_jsxs(_Fragment, { children: [_jsx(Loader, { type: type }), children] }));
