import React, { FC, PropsWithChildren } from 'react';
import { Box, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';

export const FlowChartSidebarPlaceholder: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        boxShadow: '1px 0px 0px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flex: '0 0 auto',
        flexDirection: 'column',
        width: isLargeScreen ? 408 : 300,
        position: 'relative',
        overflow: 'hidden',
        zIndex: 10,
      }}
    >
      {children}
    </Box>
  );
};
