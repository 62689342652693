import { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useStore, Atom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const indent = (json: any) => JSON.stringify(json, null, '  ');

export const useSendAtomChangesToDatadog = <TAtom>(atoms: Record<string, Atom<TAtom>>) => {
  const store = useStore();
  const oldValues = useRef<Record<string, TAtom>>({});

  useEffect(() => {
    const subs = Object.entries(atoms).map(([key, atom]) => {
      oldValues.current[key] = store.get(atom);
      return store.sub(atom, () => {
        const newValue = store.get(atom);
        const newValueString = indent(newValue);
        const oldValue = oldValues.current[key] || '';
        const oldValueString = indent(oldValue);
        const title = `${key} changed, new value: ${newValueString}, old value: ${oldValueString}`.substring(0, 100);
        datadogRum.addAction(title, { new: newValueString, old: oldValueString });
        oldValues.current[key] = newValue;
        datadogRum.addAction('Current state', {
          state: indent(oldValues.current),
        });
      });
    });

    return () => {
      subs.forEach((sub: () => void) => sub());
    };
  }, [atoms, store]);
};
