import { Paths } from '@connectlyai-tenets/sdk';

export type ProvisionPhoneNumberParams = {
  businessId: string;
};

export const PROVISION_PHONE_NUMBER_URL = '/internal/v1/businesses/{businessId}/provision/phone_numbers';

type ProvisionPhoneNumberUrl = typeof PROVISION_PHONE_NUMBER_URL;

export type ProvisionPhoneNumberInput =
  Paths['profileV1'][ProvisionPhoneNumberUrl]['post']['parameters']['body']['input'];

export type ProvisionPhoneNumberVariables = ProvisionPhoneNumberParams & ProvisionPhoneNumberInput;

export type ProvisionPhoneNumberResponse =
  Paths['profileV1'][ProvisionPhoneNumberUrl]['post']['responses']['200']['schema'];
