import React, { useCallback, useContext, useState, useMemo } from 'react';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  CompanyIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Label,
  Stack,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts';
import { selectBusinessId, selectShopifyChannel, useMeData, useSettingsData } from '../../hooks';
import { useBusinessOrganizationData } from '../../hooks/useBusinessOrganizationData';
import { useMutationUnsubscribe } from '../../hooks/useMutationUnsubscribe';
import { useQuerySubscriptionURL } from '../../hooks/useQuerySubscriptionURL';
import { applyHook } from '../../utils';
import { UPDATE_BILLING_URL } from './constants';
import { SMBSettingsBillingProps } from './types';

const useBillingSettings = (): Partial<SMBSettingsBillingProps> => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  return {
    businessId,
  };
};

const SubscribeButton = ({ businessId, hadSubscription }: SMBSettingsBillingProps) => {
  const { data: subscriptionURL, isLoading } = useQuerySubscriptionURL(businessId as string);
  const { t } = useTranslation('translation', { keyPrefix: 'settings.billing' });
  const buttonText = useMemo(() => {
    if (hadSubscription) {
      return t('subscribeAgain');
    }
    return t('subscribe');
  }, [t, hadSubscription]);
  return (
    <Stack>
      <Button disabled={isLoading || !subscriptionURL} href={subscriptionURL?.confirmationUrl ?? ''} target="_blank">
        {isLoading ? <CircularProgress size={16} /> : buttonText}
      </Button>
      {!isLoading && !subscriptionURL && (
        <Label variant="body1" color="error">
          {t('subscriptionUrlError')}
        </Label>
      )}
    </Stack>
  );
};

const SMBSettingsBillingContent = ({ businessId }: SMBSettingsBillingProps) => {
  const { notificationProvider } = useContext(NotificationContext);
  const { t } = useTranslation('translation', { keyPrefix: 'settings.billing' });
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { data: shopifyChannel } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectShopifyChannel,
  });
  const shopDomain = shopifyChannel?.remoteProfile?.shopifyProfile?.shop?.myshopifyDomain;
  const adminUrl = `https://${shopDomain}/admin/settings/apps`;

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const { data: org } = useBusinessOrganizationData({
    businessId,
  });
  const { mutate: mutateUnsubscribe } = useMutationUnsubscribe({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['businessOrganization'] });
      setShowCancelDialog(false);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to unsubscribe',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const subscriptionStatus = org?.entity?.subscription?.status;
  const pricingPlan = org?.entity?.subscription?.pricingPlan;
  const expirationTime = org?.entity?.subscription?.currentPeriodEnd;

  const currentDate = new Date();
  const expirationDate = expirationTime ? new Date(expirationTime) : null;

  const getStatus = () => {
    switch (subscriptionStatus) {
      case 'SUBSCRIPTION_STATUS_TRIALING':
        return { label: t('trialing'), color: theme.palette.success.light };
      case 'SUBSCRIPTION_STATUS_ACTIVE':
        return { label: t('active'), color: theme.palette.success.light };
      case undefined:
        return { label: t('noSubscription'), color: theme.palette.warning.light };
      default:
        if (!expirationDate) {
          return { label: t('expired'), color: theme.palette.warning.light };
        }
        if (expirationDate > currentDate) {
          return {
            label: t('planEnding', { date: expirationDate.toLocaleDateString() }),
            color: theme.palette.success.light,
          };
        }
        return {
          label: t('planEnded', { date: expirationDate.toLocaleDateString() }),
          color: theme.palette.warning.light,
        };
    }
  };
  const getFooter = () => {
    switch (subscriptionStatus) {
      case 'SUBSCRIPTION_STATUS_TRIALING':
      case 'SUBSCRIPTION_STATUS_ACTIVE':
        return (
          <>
            <Button onClick={() => setShowCancelDialog(true)}>{t('cancelSubscription')}</Button>
            <Button href={UPDATE_BILLING_URL} target="_blank">
              {t('updatePayment')}
            </Button>
          </>
        );
      default:
        return <SubscribeButton businessId={businessId} hadSubscription={subscriptionStatus !== undefined} />;
    }
  };

  const handleCancelSubscription = useCallback(() => {
    mutateUnsubscribe({
      businessId,
    });
  }, [mutateUnsubscribe, businessId]);

  const status = getStatus();
  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        margin: '0px auto',
        p: 4,
        background: theme.palette.grey.A100,
        height: '100%',
        gap: 2,
      }}
    >
      <Dialog open={showCancelDialog} onClose={() => setShowCancelDialog(false)}>
        <DialogTitle>
          <Label variant="body1">{t('cancelSubscription')}</Label>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('confirmationMessage')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {shopDomain ? (
            <Button href={adminUrl} target="_blank">
              {t('cancelSubscription')}
            </Button>
          ) : (
            <Button onClick={() => handleCancelSubscription()}>{t('cancelSubscription')}</Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              setShowCancelDialog(false);
            }}
          >
            {t('keepPlan')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Label variant="subtitle1">{t('billing')}</Label>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          boxSizing: 'border-box',
          borderRadius: '10px',
          padding: 3,
          gap: 1,
          background: theme.palette.common.white,
        }}
      >
        <Label variant="subtitle1">{t('mySubscription')}</Label>
        <Box
          sx={{
            margin: '16px 0px',
            flex: 1,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: '10px',
            boxSizing: 'border-box',
            p: 1,
            alignItems: 'flex-start',
            gap: 1,
            width: '100%',
          }}
        >
          <Box sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Avatar sx={{ backgroundColor: '#825DE1', p: 0.5 }}>
                <CompanyIcon fill="white" width={36} height={36} />
              </Avatar>
              {pricingPlan && (
                <Label variant="subtitle1">
                  {`${pricingPlan.name}\n
$${pricingPlan.price} ${pricingPlan.currency?.toUpperCase()} / ${pricingPlan.recurringInterval}`}
                </Label>
              )}
              <Label sx={{ color: status.color }}>{status.label}</Label>
            </Box>
            <Divider sx={{ margin: '16px 0px' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {pricingPlan?.products?.map((product) => (
                <Label key={product} variant="body1" sx={{ color: theme.palette.secondary.dark }}>
                  {`· ${product}`}
                </Label>
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>{getFooter()}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const SMBSettingsBillingPresentation = (props: SMBSettingsBillingProps) => {
  if (!props.businessId) {
    return null;
  }
  return <SMBSettingsBillingContent {...props} />;
};
export const SMBSettingsBilling = applyHook(SMBSettingsBillingPresentation, useBillingSettings);
