import { get } from 'lodash';
import { ParameterValueMapping } from '@connectlyai-tenets/sdk';
import type { AppState } from '../store';
import { MediaHeaderTemplateComponentType, TemplateComponent } from '../../hooks/useMessageTemplateGroupsData/types';
import { CREATE_NEW_TEMPLATE_ID, EMPTY_TEMPLATE_ID } from './constants';
import { HeaderType, UnsentMessageTemplate } from './types';
import { mapMediaHeaderTypeToTemplateParamName, mapMediaHeaderTypeStringToTemplateParamName } from '../../utils';

export const selectCanChangeButtonsGroup = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.canChangeButtonsGroup;
export const selectEditedNodeId = (state: AppState) => state.messageTemplates.templateBuilderParams?.nodeId;
export const selectUnsentMessageTemplateByNodeId = (nodeId: string) => (state: AppState) =>
  state.messageTemplates.unsentTemplates.entities[nodeId];
export const selectUnsentMessageTemplatesIndex = (state: AppState) => state.messageTemplates.unsentTemplates.entities;
export const selectBody = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.body)?.body?.text
    ?.text || '';
export const selectBodyError = (state: AppState) => state.messageTemplates.templateBuilderParams?.errors?.body || '';
export const selectName = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.name || '';
export const selectHeaderText = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.header)?.header?.text
    ?.text || '';
export const selectHeaderMedia = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.header)?.header
    ?.media?.example || [];
export const selectHeaderType = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.headerType || HeaderType.Text;
export const selectHeaderMediaMapping = (state: AppState) => {
  const headerMediaParamName = mapMediaHeaderTypeToTemplateParamName(selectHeaderType(state));
  return get(state.messageTemplates.templateBuilderParams?.uiState?.mappings, headerMediaParamName) || '';
};
export const selectHeaderMediaMappingByNodeId = (state: AppState, nodeId: string): string => {
  const editedId = state.messageTemplates.templateBuilderParams?.nodeId;
  if (editedId === nodeId) {
    return selectHeaderMediaMapping(state);
  }
  const templateData = state.flowDocument.nodes.find((node) => node?.id === nodeId)?.data?.v1;
  if (templateData) {
    const mediaType = templateData?.messageTemplateInput?.templateComponents.find((c: TemplateComponent) => c.header)
      ?.header?.media?.type;
    const parameterMapping = templateData?.parameterMapping as ParameterValueMapping | undefined;
    if (mediaType && parameterMapping && parameterMapping.mappings) {
      const param = parameterMapping.mappings.find(
        (p) => p.rawParam === mapMediaHeaderTypeStringToTemplateParamName(mediaType),
      );
      if (param && param.fullQualifier) {
        return `{{${param.fullQualifier}}}`;
      }
    }
  }
  return '';
};
export const selectHeaderMediaTypeByNodeId = (
  state: AppState,
  nodeId: string,
): MediaHeaderTemplateComponentType | '' => {
  const { templateBuilderParams } = state.messageTemplates;
  const editedId = templateBuilderParams?.nodeId;
  if (nodeId === editedId) {
    const mediaType = templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.header)?.header?.media
      ?.type;
    return mediaType || '';
  }
  const templateData = state.flowDocument.nodes.find((node) => node?.id === nodeId)?.data?.v1;
  if (templateData) {
    const mediaType = templateData?.messageTemplateInput?.templateComponents.find((c: TemplateComponent) => c.header)
      ?.header?.media?.type;
    return mediaType || '';
  }
  return '';
};
export const selectButtonMappings = (state: AppState) => {
  const mappings = state.messageTemplates.templateBuilderParams?.uiState?.mappings || {};
  return Object.keys(mappings)
    .filter((val) => val.startsWith('button_'))
    .map((key) => ({
      key,
      value: mappings[key],
    }));
};

export const selectHeaderError = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.errors?.header || '';
export const selectFooter = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.footer)?.footer?.text
    ?.text || '';
export const selectFooterError = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.errors?.footer || '';
export const selectButtons = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.filter(
    (x): x is TemplateComponent => !!x.button,
  ) || [];
export const selectButtonsError = (state: AppState) => state.messageTemplates.templateBuilderParams?.errors?.buttons;
export const selectButtonsUiState = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.uiState?.buttons || [];
export const selectButtonsGroup = (state: AppState) => state.messageTemplates.templateBuilderParams?.buttonsGroup;
export const selectIsHeaderEnabled = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.isComponentEnabled.header || false;
export const selectIsFooterEnabled = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.isComponentEnabled.footer || false;
export const selectIsButtonsCollectionEnabled = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.isComponentEnabled.buttons || false;
export const selectList = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.list)?.list || {
    button: '',
    sections: [],
  };
export const selectListSimpleRows = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.list)?.list
    ?.sections?.[0]?.rows;
export const selectListButton = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate?.templateComponents?.find((x) => x.list)?.list
    ?.button || '';
export const selectListButtonError = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.errors?.listButton || '';
export const selectListError = (state: AppState) => state.messageTemplates.templateBuilderParams?.errors?.list || [];
export const selectErrors = (state: AppState) => state.messageTemplates.templateBuilderParams?.errors;
export const selectTemplateComponents = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.messageTemplate.templateComponents || [];
export const selectTemplateMappings = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.uiState.mappings || {};
export const selectTemplateId = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.templateId ?? EMPTY_TEMPLATE_ID;
export const selectPreviewTemplateId = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.previewTemplateId ?? EMPTY_TEMPLATE_ID;
export const selectTemplateIdError = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.errors?.templateId || '';

export const selectTemplateApprovalDialogOpen = (state: AppState) =>
  state.messageTemplates.templateApprovalDialogParams?.open || false;
export const selectHasUnsentTemplates = (state: AppState) =>
  !!Object.values(state.messageTemplates.unsentTemplates.entities).find((x) => x?.status === undefined);
export const selectHasPendingTemplates = (state: AppState) =>
  !!Object.values(state.messageTemplates.unsentTemplates.entities).find(
    (x) => x?.status === 'MESSAGE_TEMPLATE_STATUS_PENDING',
  );
export const selectHasRejectedTemplates = (state: AppState) =>
  !!Object.values(state.messageTemplates.unsentTemplates.entities).find(
    (x) => x?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED',
  );
export const selectRejectedTemplates = (state: AppState) =>
  Object.values(state.messageTemplates.unsentTemplates.entities).filter(
    (x): x is UnsentMessageTemplate => x?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED',
  );
export const selectFirstInvalidTemplate = (state: AppState) =>
  Object.entries(state.messageTemplates.unsentTemplates.entities).find(([_, x]) => !!x?.errors);
export const selectInvalidTemplates = (state: AppState) =>
  Object.entries(state.messageTemplates.unsentTemplates.entities).filter(([_, x]) => !!x?.errors);
export const selectPendingTemplates = (state: AppState) =>
  Object.entries(state.messageTemplates.unsentTemplates.entities).filter(
    ([_, x]) => x?.status === 'MESSAGE_TEMPLATE_STATUS_PENDING',
  );
export const selectFirstRejectedTemplate = (state: AppState) =>
  Object.entries(state.messageTemplates.unsentTemplates.entities).find(
    ([_, x]) => x?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED',
  );
export const selectIsEditable = (state: AppState) =>
  state.messageTemplates.templateBuilderParams?.templateId === CREATE_NEW_TEMPLATE_ID ||
  state.messageTemplates.unsentTemplates.entities[state.messageTemplates.templateBuilderParams?.nodeId || 0]?.status ===
    'MESSAGE_TEMPLATE_STATUS_REJECTED';
export const selectStatus = (state: AppState) =>
  state.messageTemplates.unsentTemplates.entities[state.messageTemplates.templateBuilderParams?.nodeId || 0]?.status ||
  'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
export const selectTemplatesSlice = (state: AppState) => state.messageTemplates;
