import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SettingsUrlParameters, SettingsResponse } from './types';

export const useSettingsData = <TData = SettingsResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<SettingsResponse, unknown, TData, readonly [target: 'settings', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  SettingsUrlParameters) => {
  return useQuery(
    ['settings', businessId],
    () => api.profileV1('/v1/businesses/{businessId}', 'get', { path: { businessId } }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
