import React, { useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  selectBusinessId,
  useAnalytics,
  useMeData,
  useQueryCampaignUTMConfiguration,
  useMutationUpsertCampaignUTMConfiguration,
  useStandardNotifications,
} from '@hooks';
import {
  Box,
  ConnectlyCard,
  Divider,
  InfoOutlinedIcon,
  Label,
  NewLabel,
  Stack,
  Switch,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { DEFAULT_SETTINGS } from './constants';

const useCampaignsUTM = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.campaigns.utm' });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: campaignUTMConfig, isLoading: configIsLoading } = useQueryCampaignUTMConfiguration({
    businessId: businessId || '',
    query: {
      businessId,
    },
    enabled: !!businessId,
  });
  const enabled = useMemo(
    () => campaignUTMConfig?.entity?.status === 'PRODUCT_CONFIG_STATUS_ENABLED',
    [campaignUTMConfig],
  );
  const queryClient = useQueryClient();
  const { notifySuccess: notifyChangeConfigSuccess, notifyError: notifyChangeConfigError } = useStandardNotifications({
    successMessage: 'UTM settings updated.',
    errorMessage: 'Failed to update setting. Please try again',
  });
  const { mutate: mutateConfig, isLoading: changeIsLoading } = useMutationUpsertCampaignUTMConfiguration({
    onError: () => {
      notifyChangeConfigError();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['config.campaigns.utm', { businessId }], { entity: data.entity });
      notifyChangeConfigSuccess();
    },
  });

  const { cardTitle, cardDescription, sectionHeader, sectionInfo } = useMemo(() => {
    return {
      cardDescription: t('description'),
      cardTitle: t('title'),
      enableTooltip: t('enableTooltip'),
      sectionHeader: t('editSection'),
      sectionInfo: t('editSectionInfo'),
    };
  }, [t]);

  const { sendAnalytics } = useAnalytics();
  const changeEnabled = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newEnabled = event.target.checked;
      const trackEvent = newEnabled ? 'settings_campaigns.utm.enable' : 'settings_campaigns.utm.disable';
      sendAnalytics(trackEvent);
      mutateConfig({
        businessId: businessId || '',
        status: newEnabled ? 'PRODUCT_CONFIG_STATUS_ENABLED' : 'PRODUCT_CONFIG_STATUS_DISABLED',
        config: DEFAULT_SETTINGS,
      });
    },
    [businessId, mutateConfig, sendAnalytics],
  );
  const inputDisabled = useMemo(() => {
    return configIsLoading || changeIsLoading;
  }, [configIsLoading, changeIsLoading]);

  const switchTooltip = useMemo(() => {
    let tooltip = '';
    if (inputDisabled) {
      tooltip = t('loading');
    } else if (enabled) {
      tooltip = t('disableTooltip');
    } else {
      tooltip = t('enableTooltip');
    }
    return tooltip;
  }, [enabled, inputDisabled, t]);

  return {
    cardDescription,
    cardTitle,
    changeEnabled,
    enabled,
    inputDisabled,
    sectionHeader,
    sectionInfo,
    switchTooltip,
  };
};

export const CampaignsUTM = () => {
  const {
    cardDescription,
    cardTitle,
    changeEnabled,
    enabled,
    inputDisabled,
    sectionHeader,
    sectionInfo,
    switchTooltip,
  } = useCampaignsUTM();
  const theme = useTheme();
  return (
    <ConnectlyCard>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Label variant="h5">{cardTitle}</Label>
            <NewLabel sx={{ ml: 1 }} />
          </Stack>
          <Tooltip title={switchTooltip} placement="top" arrow>
            <Box>
              <Switch checked={enabled} onChange={changeEnabled} disabled={inputDisabled} />
            </Box>
          </Tooltip>
        </Stack>
        <Label variant="body1">{cardDescription}</Label>
        <Divider sx={{ my: 1 }} />
        <Label variant="h7" sx={{ fontWeight: '700 !important' }}>
          {sectionHeader}
        </Label>
        <Stack direction="row" sx={{ p: 2, background: theme.palette.grey[200], borderRadius: '10px' }}>
          <InfoOutlinedIcon sx={{ mr: 1 }} />
          <Label variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
            {sectionInfo}
          </Label>
        </Stack>
      </Stack>
    </ConnectlyCard>
  );
};
