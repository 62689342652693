import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_DELETE_QUICK_REPLY_ITEM_URL,
  MutationDeleteQuickReplyItemInput,
  MutationDeleteQuickReplyItemOptions,
} from './types';

export const useMutationDeleteQuickReplyItem = (options?: MutationDeleteQuickReplyItemOptions) => {
  return useMutation(
    [],
    (input: MutationDeleteQuickReplyItemInput) =>
      api.profileV1(MUTATION_DELETE_QUICK_REPLY_ITEM_URL, 'delete', {
        path: { businessId: input.businessId, id: input.id },
      }),
    options,
  );
};
