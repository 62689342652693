import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { DeleteAudienceSegmentVariables, DeleteAudienceSegmentResponse } from './types';

export const useDeleteAudienceSegmentMutation = (
  options?: Omit<
    UseMutationOptions<DeleteAudienceSegmentResponse, ConnectlyError, DeleteAudienceSegmentVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, segmentId }: DeleteAudienceSegmentVariables) =>
      api.audienceV1('/internal/v1/businesses/{businessId}/audience_segments/{segmentId}', 'delete', {
        path: { businessId, segmentId },
      }) as Promise<DeleteAudienceSegmentResponse>,
    options,
  );
