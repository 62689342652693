import React, { FC, useState, PropsWithChildren } from 'react';
import { Alert, Collapse } from '@connectlyai-tenets/ui-styled-web';
import { HideableAlertProps } from './types';

export const HideableAlert: FC<PropsWithChildren<HideableAlertProps>> = ({
  variant = 'standard',
  color = 'info',
  severity = 'info',
  storageKey,
  children,
  ...rest
}) => {
  const localStorageKey = `${storageKey}-hidden`;
  const [hidden, setHidden] = useState(localStorage.getItem(localStorageKey) === 'true');
  const handleHide = () => {
    setHidden(true);
    localStorage.setItem(localStorageKey, 'true');
  };

  return (
    <Collapse in={!hidden} unmountOnExit>
      <Alert variant={variant} color={color} severity={severity} {...rest} onClose={handleHide}>
        {children && children}
      </Alert>
    </Collapse>
  );
};
