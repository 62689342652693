import { Timestamp } from "../../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
import { Int64Value, StringValue, BoolValue, } from "../../../google/protobuf/wrappers";
export const protobufPackage = "events.zendesk.v2";
function createBaseUserThumbnails() {
    return {
        id: undefined,
        name: undefined,
        contentUrl: undefined,
        contentType: undefined,
        size: undefined,
    };
}
export const UserThumbnails = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(18).fork()).ldelim();
        }
        if (message.contentUrl !== undefined) {
            StringValue.encode({ value: message.contentUrl }, writer.uint32(26).fork()).ldelim();
        }
        if (message.contentType !== undefined) {
            StringValue.encode({ value: message.contentType }, writer.uint32(34).fork()).ldelim();
        }
        if (message.size !== undefined) {
            Int64Value.encode({ value: message.size }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserThumbnails();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.contentUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.contentType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.size = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            contentUrl: isSet(object.contentUrl)
                ? String(object.contentUrl)
                : undefined,
            contentType: isSet(object.contentType)
                ? String(object.contentType)
                : undefined,
            size: isSet(object.size) ? Number(object.size) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.contentUrl !== undefined && (obj.contentUrl = message.contentUrl);
        message.contentType !== undefined &&
            (obj.contentType = message.contentType);
        message.size !== undefined && (obj.size = message.size);
        return obj;
    },
};
function createBaseUserPhoto() {
    return {
        id: undefined,
        name: undefined,
        contentUrl: undefined,
        contentType: undefined,
        size: undefined,
        thumbnails: [],
    };
}
export const UserPhoto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(18).fork()).ldelim();
        }
        if (message.contentUrl !== undefined) {
            StringValue.encode({ value: message.contentUrl }, writer.uint32(26).fork()).ldelim();
        }
        if (message.contentType !== undefined) {
            StringValue.encode({ value: message.contentType }, writer.uint32(34).fork()).ldelim();
        }
        if (message.size !== undefined) {
            Int64Value.encode({ value: message.size }, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.thumbnails) {
            UserThumbnails.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserPhoto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.contentUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.contentType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.size = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.thumbnails.push(UserThumbnails.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            contentUrl: isSet(object.contentUrl)
                ? String(object.contentUrl)
                : undefined,
            contentType: isSet(object.contentType)
                ? String(object.contentType)
                : undefined,
            size: isSet(object.size) ? Number(object.size) : undefined,
            thumbnails: Array.isArray(object === null || object === void 0 ? void 0 : object.thumbnails)
                ? object.thumbnails.map((e) => UserThumbnails.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.contentUrl !== undefined && (obj.contentUrl = message.contentUrl);
        message.contentType !== undefined &&
            (obj.contentType = message.contentType);
        message.size !== undefined && (obj.size = message.size);
        if (message.thumbnails) {
            obj.thumbnails = message.thumbnails.map((e) => e ? UserThumbnails.toJSON(e) : undefined);
        }
        else {
            obj.thumbnails = [];
        }
        return obj;
    },
};
function createBaseUser() {
    return {
        id: undefined,
        url: undefined,
        name: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        timeZone: undefined,
        email: undefined,
        phone: undefined,
        locale: undefined,
        localeId: undefined,
        organizationId: undefined,
        role: undefined,
        verified: undefined,
        photo: undefined,
    };
}
export const User = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.url !== undefined) {
            StringValue.encode({ value: message.url }, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.timeZone !== undefined) {
            StringValue.encode({ value: message.timeZone }, writer.uint32(50).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(58).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(66).fork()).ldelim();
        }
        if (message.locale !== undefined) {
            StringValue.encode({ value: message.locale }, writer.uint32(74).fork()).ldelim();
        }
        if (message.localeId !== undefined) {
            Int64Value.encode({ value: message.localeId }, writer.uint32(82).fork()).ldelim();
        }
        if (message.organizationId !== undefined) {
            Int64Value.encode({ value: message.organizationId }, writer.uint32(90).fork()).ldelim();
        }
        if (message.role !== undefined) {
            StringValue.encode({ value: message.role }, writer.uint32(98).fork()).ldelim();
        }
        if (message.verified !== undefined) {
            BoolValue.encode({ value: message.verified }, writer.uint32(106).fork()).ldelim();
        }
        if (message.photo !== undefined) {
            UserPhoto.encode(message.photo, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.url = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.timeZone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.locale = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.localeId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.organizationId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.role = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.verified = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.photo = UserPhoto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            url: isSet(object.url) ? String(object.url) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            timeZone: isSet(object.timeZone) ? String(object.timeZone) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
            locale: isSet(object.locale) ? String(object.locale) : undefined,
            localeId: isSet(object.localeId) ? Number(object.localeId) : undefined,
            organizationId: isSet(object.organizationId)
                ? Number(object.organizationId)
                : undefined,
            role: isSet(object.role) ? String(object.role) : undefined,
            verified: isSet(object.verified) ? Boolean(object.verified) : undefined,
            photo: isSet(object.photo) ? UserPhoto.fromJSON(object.photo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.url !== undefined && (obj.url = message.url);
        message.name !== undefined && (obj.name = message.name);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.timeZone !== undefined && (obj.timeZone = message.timeZone);
        message.email !== undefined && (obj.email = message.email);
        message.phone !== undefined && (obj.phone = message.phone);
        message.locale !== undefined && (obj.locale = message.locale);
        message.localeId !== undefined && (obj.localeId = message.localeId);
        message.organizationId !== undefined &&
            (obj.organizationId = message.organizationId);
        message.role !== undefined && (obj.role = message.role);
        message.verified !== undefined && (obj.verified = message.verified);
        message.photo !== undefined &&
            (obj.photo = message.photo ? UserPhoto.toJSON(message.photo) : undefined);
        return obj;
    },
};
function createBaseRequest() {
    return {
        description: undefined,
        id: undefined,
        status: undefined,
        subject: undefined,
    };
}
export const Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.description !== undefined) {
            StringValue.encode({ value: message.description }, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(18).fork()).ldelim();
        }
        if (message.status !== undefined) {
            StringValue.encode({ value: message.status }, writer.uint32(26).fork()).ldelim();
        }
        if (message.subject !== undefined) {
            StringValue.encode({ value: message.subject }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.description = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.status = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.subject = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            description: isSet(object.description)
                ? String(object.description)
                : undefined,
            id: isSet(object.id) ? Number(object.id) : undefined,
            status: isSet(object.status) ? String(object.status) : undefined,
            subject: isSet(object.subject) ? String(object.subject) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.description !== undefined &&
            (obj.description = message.description);
        message.id !== undefined && (obj.id = message.id);
        message.status !== undefined && (obj.status = message.status);
        message.subject !== undefined && (obj.subject = message.subject);
        return obj;
    },
};
function createBaseCreateUpdateRequestResponse() {
    return { request: undefined };
}
export const CreateUpdateRequestResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.request !== undefined) {
            Request.encode(message.request, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateUpdateRequestResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.request = Request.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            request: isSet(object.request)
                ? Request.fromJSON(object.request)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.request !== undefined &&
            (obj.request = message.request
                ? Request.toJSON(message.request)
                : undefined);
        return obj;
    },
};
function createBaseRequester() {
    return { name: undefined };
}
export const Requester = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRequester();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseComment() {
    return { body: undefined };
}
export const Comment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            body: isSet(object.body) ? String(object.body) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function createBaseCreateUpdateRequestParams() {
    return { requester: undefined, subject: undefined, comment: undefined };
}
export const CreateUpdateRequestParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.requester !== undefined) {
            Requester.encode(message.requester, writer.uint32(10).fork()).ldelim();
        }
        if (message.subject !== undefined) {
            StringValue.encode({ value: message.subject }, writer.uint32(18).fork()).ldelim();
        }
        if (message.comment !== undefined) {
            Comment.encode(message.comment, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateUpdateRequestParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.requester = Requester.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.subject = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.comment = Comment.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            requester: isSet(object.requester)
                ? Requester.fromJSON(object.requester)
                : undefined,
            subject: isSet(object.subject) ? String(object.subject) : undefined,
            comment: isSet(object.comment)
                ? Comment.fromJSON(object.comment)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.requester !== undefined &&
            (obj.requester = message.requester
                ? Requester.toJSON(message.requester)
                : undefined);
        message.subject !== undefined && (obj.subject = message.subject);
        message.comment !== undefined &&
            (obj.comment = message.comment
                ? Comment.toJSON(message.comment)
                : undefined);
        return obj;
    },
};
function createBaseCreateRequestRequest() {
    return { request: undefined };
}
export const CreateRequestRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.request !== undefined) {
            CreateUpdateRequestParams.encode(message.request, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateRequestRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.request = CreateUpdateRequestParams.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            request: isSet(object.request)
                ? CreateUpdateRequestParams.fromJSON(object.request)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.request !== undefined &&
            (obj.request = message.request
                ? CreateUpdateRequestParams.toJSON(message.request)
                : undefined);
        return obj;
    },
};
function createBaseZendeskZisComment() {
    return {
        commentIsPublic: undefined,
        commentAuthorIsStaff: undefined,
        commentAuthorName: undefined,
        commentBody: undefined,
        commentId: undefined,
    };
}
export const ZendeskZisComment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.commentIsPublic !== undefined) {
            BoolValue.encode({ value: message.commentIsPublic }, writer.uint32(42).fork()).ldelim();
        }
        if (message.commentAuthorIsStaff !== undefined) {
            BoolValue.encode({ value: message.commentAuthorIsStaff }, writer.uint32(34).fork()).ldelim();
        }
        if (message.commentAuthorName !== undefined) {
            StringValue.encode({ value: message.commentAuthorName }, writer.uint32(26).fork()).ldelim();
        }
        if (message.commentBody !== undefined) {
            StringValue.encode({ value: message.commentBody }, writer.uint32(18).fork()).ldelim();
        }
        if (message.commentId !== undefined) {
            Int64Value.encode({ value: message.commentId }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisComment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 5:
                    message.commentIsPublic = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.commentAuthorIsStaff = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.commentAuthorName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.commentBody = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 1:
                    message.commentId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            commentIsPublic: isSet(object.commentIsPublic)
                ? Boolean(object.commentIsPublic)
                : undefined,
            commentAuthorIsStaff: isSet(object.commentAuthorIsStaff)
                ? Boolean(object.commentAuthorIsStaff)
                : undefined,
            commentAuthorName: isSet(object.commentAuthorName)
                ? String(object.commentAuthorName)
                : undefined,
            commentBody: isSet(object.commentBody)
                ? String(object.commentBody)
                : undefined,
            commentId: isSet(object.commentId) ? Number(object.commentId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.commentIsPublic !== undefined &&
            (obj.commentIsPublic = message.commentIsPublic);
        message.commentAuthorIsStaff !== undefined &&
            (obj.commentAuthorIsStaff = message.commentAuthorIsStaff);
        message.commentAuthorName !== undefined &&
            (obj.commentAuthorName = message.commentAuthorName);
        message.commentBody !== undefined &&
            (obj.commentBody = message.commentBody);
        message.commentId !== undefined && (obj.commentId = message.commentId);
        return obj;
    },
};
function createBaseZendeskZisIdentity() {
    return { subdomain: undefined, accountId: undefined };
}
export const ZendeskZisIdentity = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subdomain !== undefined) {
            StringValue.encode({ value: message.subdomain }, writer.uint32(18).fork()).ldelim();
        }
        if (message.accountId !== undefined) {
            Int64Value.encode({ value: message.accountId }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisIdentity();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.subdomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 1:
                    message.accountId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subdomain: isSet(object.subdomain) ? String(object.subdomain) : undefined,
            accountId: isSet(object.accountId) ? Number(object.accountId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subdomain !== undefined && (obj.subdomain = message.subdomain);
        message.accountId !== undefined && (obj.accountId = message.accountId);
        return obj;
    },
};
function createBaseZendeskZisTicket() {
    return {
        ticketId: undefined,
        ticketAssigneeId: undefined,
        ticketCommentAuthorId: undefined,
        ticketCreatedAt: undefined,
        ticketRequesterId: undefined,
        ticketStatus: undefined,
        ticketSubmitterId: undefined,
        ticketType: undefined,
        ticketUpdatedAt: undefined,
        ticketTags: [],
        ticketFormId: undefined,
        ticketBrandId: undefined,
        ticketGroupId: undefined,
        ticketOrganizationId: undefined,
        ticketExternalId: undefined,
    };
}
export const ZendeskZisTicket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticketId !== undefined) {
            Int64Value.encode({ value: message.ticketId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.ticketAssigneeId !== undefined) {
            Int64Value.encode({ value: message.ticketAssigneeId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.ticketCommentAuthorId !== undefined) {
            Int64Value.encode({ value: message.ticketCommentAuthorId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.ticketCreatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.ticketCreatedAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.ticketRequesterId !== undefined) {
            Int64Value.encode({ value: message.ticketRequesterId }, writer.uint32(42).fork()).ldelim();
        }
        if (message.ticketStatus !== undefined) {
            StringValue.encode({ value: message.ticketStatus }, writer.uint32(50).fork()).ldelim();
        }
        if (message.ticketSubmitterId !== undefined) {
            Int64Value.encode({ value: message.ticketSubmitterId }, writer.uint32(58).fork()).ldelim();
        }
        if (message.ticketType !== undefined) {
            StringValue.encode({ value: message.ticketType }, writer.uint32(66).fork()).ldelim();
        }
        if (message.ticketUpdatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.ticketUpdatedAt), writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.ticketTags) {
            writer.uint32(82).string(v);
        }
        if (message.ticketFormId !== undefined) {
            Int64Value.encode({ value: message.ticketFormId }, writer.uint32(90).fork()).ldelim();
        }
        if (message.ticketBrandId !== undefined) {
            Int64Value.encode({ value: message.ticketBrandId }, writer.uint32(98).fork()).ldelim();
        }
        if (message.ticketGroupId !== undefined) {
            Int64Value.encode({ value: message.ticketGroupId }, writer.uint32(106).fork()).ldelim();
        }
        if (message.ticketOrganizationId !== undefined) {
            Int64Value.encode({ value: message.ticketOrganizationId }, writer.uint32(114).fork()).ldelim();
        }
        if (message.ticketExternalId !== undefined) {
            Int64Value.encode({ value: message.ticketExternalId }, writer.uint32(122).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisTicket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticketId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.ticketAssigneeId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.ticketCommentAuthorId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.ticketCreatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.ticketRequesterId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.ticketStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.ticketSubmitterId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.ticketType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.ticketUpdatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.ticketTags.push(reader.string());
                    break;
                case 11:
                    message.ticketFormId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.ticketBrandId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.ticketGroupId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.ticketOrganizationId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.ticketExternalId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticketId: isSet(object.ticketId) ? Number(object.ticketId) : undefined,
            ticketAssigneeId: isSet(object.ticketAssigneeId)
                ? Number(object.ticketAssigneeId)
                : undefined,
            ticketCommentAuthorId: isSet(object.ticketCommentAuthorId)
                ? Number(object.ticketCommentAuthorId)
                : undefined,
            ticketCreatedAt: isSet(object.ticketCreatedAt)
                ? String(object.ticketCreatedAt)
                : undefined,
            ticketRequesterId: isSet(object.ticketRequesterId)
                ? Number(object.ticketRequesterId)
                : undefined,
            ticketStatus: isSet(object.ticketStatus)
                ? String(object.ticketStatus)
                : undefined,
            ticketSubmitterId: isSet(object.ticketSubmitterId)
                ? Number(object.ticketSubmitterId)
                : undefined,
            ticketType: isSet(object.ticketType)
                ? String(object.ticketType)
                : undefined,
            ticketUpdatedAt: isSet(object.ticketUpdatedAt)
                ? String(object.ticketUpdatedAt)
                : undefined,
            ticketTags: Array.isArray(object === null || object === void 0 ? void 0 : object.ticketTags)
                ? object.ticketTags.map((e) => String(e))
                : [],
            ticketFormId: isSet(object.ticketFormId)
                ? Number(object.ticketFormId)
                : undefined,
            ticketBrandId: isSet(object.ticketBrandId)
                ? Number(object.ticketBrandId)
                : undefined,
            ticketGroupId: isSet(object.ticketGroupId)
                ? Number(object.ticketGroupId)
                : undefined,
            ticketOrganizationId: isSet(object.ticketOrganizationId)
                ? Number(object.ticketOrganizationId)
                : undefined,
            ticketExternalId: isSet(object.ticketExternalId)
                ? Number(object.ticketExternalId)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticketId !== undefined && (obj.ticketId = message.ticketId);
        message.ticketAssigneeId !== undefined &&
            (obj.ticketAssigneeId = message.ticketAssigneeId);
        message.ticketCommentAuthorId !== undefined &&
            (obj.ticketCommentAuthorId = message.ticketCommentAuthorId);
        message.ticketCreatedAt !== undefined &&
            (obj.ticketCreatedAt = message.ticketCreatedAt);
        message.ticketRequesterId !== undefined &&
            (obj.ticketRequesterId = message.ticketRequesterId);
        message.ticketStatus !== undefined &&
            (obj.ticketStatus = message.ticketStatus);
        message.ticketSubmitterId !== undefined &&
            (obj.ticketSubmitterId = message.ticketSubmitterId);
        message.ticketType !== undefined && (obj.ticketType = message.ticketType);
        message.ticketUpdatedAt !== undefined &&
            (obj.ticketUpdatedAt = message.ticketUpdatedAt);
        if (message.ticketTags) {
            obj.ticketTags = message.ticketTags.map((e) => e);
        }
        else {
            obj.ticketTags = [];
        }
        message.ticketFormId !== undefined &&
            (obj.ticketFormId = message.ticketFormId);
        message.ticketBrandId !== undefined &&
            (obj.ticketBrandId = message.ticketBrandId);
        message.ticketGroupId !== undefined &&
            (obj.ticketGroupId = message.ticketGroupId);
        message.ticketOrganizationId !== undefined &&
            (obj.ticketOrganizationId = message.ticketOrganizationId);
        message.ticketExternalId !== undefined &&
            (obj.ticketExternalId = message.ticketExternalId);
        return obj;
    },
};
function createBaseZendeskZisStatusTransition() {
    return { previousStatus: undefined, currentStatus: undefined };
}
export const ZendeskZisStatusTransition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.previousStatus !== undefined) {
            StringValue.encode({ value: message.previousStatus }, writer.uint32(10).fork()).ldelim();
        }
        if (message.currentStatus !== undefined) {
            StringValue.encode({ value: message.currentStatus }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisStatusTransition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.previousStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.currentStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            previousStatus: isSet(object.previousStatus)
                ? String(object.previousStatus)
                : undefined,
            currentStatus: isSet(object.currentStatus)
                ? String(object.currentStatus)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.previousStatus !== undefined &&
            (obj.previousStatus = message.previousStatus);
        message.currentStatus !== undefined &&
            (obj.currentStatus = message.currentStatus);
        return obj;
    },
};
function createBaseZendeskZisCommentAddedEvent() {
    return { ticket: undefined, ticketComment: undefined };
}
export const ZendeskZisCommentAddedEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticket !== undefined) {
            ZendeskZisTicket.encode(message.ticket, writer.uint32(10).fork()).ldelim();
        }
        if (message.ticketComment !== undefined) {
            ZendeskZisComment.encode(message.ticketComment, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisCommentAddedEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticket = ZendeskZisTicket.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.ticketComment = ZendeskZisComment.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticket: isSet(object.ticket)
                ? ZendeskZisTicket.fromJSON(object.ticket)
                : undefined,
            ticketComment: isSet(object.ticketComment)
                ? ZendeskZisComment.fromJSON(object.ticketComment)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticket !== undefined &&
            (obj.ticket = message.ticket
                ? ZendeskZisTicket.toJSON(message.ticket)
                : undefined);
        message.ticketComment !== undefined &&
            (obj.ticketComment = message.ticketComment
                ? ZendeskZisComment.toJSON(message.ticketComment)
                : undefined);
        return obj;
    },
};
function createBaseZendeskZisStatusChangedEvent() {
    return { ticket: undefined, statusTransition: undefined };
}
export const ZendeskZisStatusChangedEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticket !== undefined) {
            ZendeskZisTicket.encode(message.ticket, writer.uint32(10).fork()).ldelim();
        }
        if (message.statusTransition !== undefined) {
            ZendeskZisStatusTransition.encode(message.statusTransition, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisStatusChangedEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticket = ZendeskZisTicket.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.statusTransition = ZendeskZisStatusTransition.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticket: isSet(object.ticket)
                ? ZendeskZisTicket.fromJSON(object.ticket)
                : undefined,
            statusTransition: isSet(object.statusTransition)
                ? ZendeskZisStatusTransition.fromJSON(object.statusTransition)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticket !== undefined &&
            (obj.ticket = message.ticket
                ? ZendeskZisTicket.toJSON(message.ticket)
                : undefined);
        message.statusTransition !== undefined &&
            (obj.statusTransition = message.statusTransition
                ? ZendeskZisStatusTransition.toJSON(message.statusTransition)
                : undefined);
        return obj;
    },
};
function createBaseZendeskZisWebhookEvent() {
    return {
        identity: undefined,
        commentAdded: undefined,
        statusChanged: undefined,
    };
}
export const ZendeskZisWebhookEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.identity !== undefined) {
            ZendeskZisIdentity.encode(message.identity, writer.uint32(10).fork()).ldelim();
        }
        if (message.commentAdded !== undefined) {
            ZendeskZisCommentAddedEvent.encode(message.commentAdded, writer.uint32(18).fork()).ldelim();
        }
        if (message.statusChanged !== undefined) {
            ZendeskZisStatusChangedEvent.encode(message.statusChanged, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisWebhookEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.identity = ZendeskZisIdentity.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.commentAdded = ZendeskZisCommentAddedEvent.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.statusChanged = ZendeskZisStatusChangedEvent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            identity: isSet(object.identity)
                ? ZendeskZisIdentity.fromJSON(object.identity)
                : undefined,
            commentAdded: isSet(object.commentAdded)
                ? ZendeskZisCommentAddedEvent.fromJSON(object.commentAdded)
                : undefined,
            statusChanged: isSet(object.statusChanged)
                ? ZendeskZisStatusChangedEvent.fromJSON(object.statusChanged)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.identity !== undefined &&
            (obj.identity = message.identity
                ? ZendeskZisIdentity.toJSON(message.identity)
                : undefined);
        message.commentAdded !== undefined &&
            (obj.commentAdded = message.commentAdded
                ? ZendeskZisCommentAddedEvent.toJSON(message.commentAdded)
                : undefined);
        message.statusChanged !== undefined &&
            (obj.statusChanged = message.statusChanged
                ? ZendeskZisStatusChangedEvent.toJSON(message.statusChanged)
                : undefined);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
