import { ButtonType } from '@components/TemplateBuilderButtons';

const createButton = (type: ButtonType) => {
  if (type === 'QUICK_REPLY') {
    return { button: { quickReply: { text: '' } } };
  }
  if (type === 'PHONE_NUMBER') {
    return { button: { phoneNumber: { text: '', phoneNumber: '' } } };
  }

  return { button: { url: { trackedUrl: 'www.example.com', text: '', url: '', example: [], prefilledParams: [] } } };
};

export default createButton;
