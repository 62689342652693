import React, { useMemo, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Label, AddIcon, SelectChangeEvent, StackCard } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { selectBusinessId, selectVisibleTags, useMeData, useSettingsData } from '@hooks';
import { tagsStateAtom, tagEditStateAtom } from '@atoms/settings';
import { track } from 'src/utils';
import { useMutationDeleteTag, useMutationUpsertTag } from '../../hooks';
import { TagView, TagsState, DialogActionResult } from './types';
import { NotificationContext } from '../../../../contexts';
import { ColorViewModel, colors } from './colors';
import { Tag } from './Tag';
import { TagsCreateDialog, TagsCreateDialogResult } from './TagsCreateDialog';
import { TagsDeleteDialog } from './TagsDeleteDialog';
import { createDialogOpenAtom, isDeleteDialogOpenAtom, deleteDialogItemAtom } from './atoms';

const useTagsManager = () => {
  const { notificationProvider } = useContext(NotificationContext);

  const [tagsState, setTagsState] = useAtom(tagsStateAtom);
  const [editState, setEditState] = useAtom(tagEditStateAtom);
  const [tagCreateDialogWebViewOpen, setTagCreateDialogWebViewOpen] = useAtom(createDialogOpenAtom);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useAtom(isDeleteDialogOpenAtom);
  const [deleteDialogItem, setDeleteDialogItem] = useAtom(deleteDialogItemAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { mutate: mutateUpsertTag } = useMutationUpsertTag();
  const { mutate: mutateDeleteTag } = useMutationDeleteTag();

  const { data: tagsData, refetch: refetchSettingsData } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectVisibleTags,
  });
  // Data Connector
  useEffect(() => {
    const ids = tagsData?.map((val) => val.id || '') || [];
    const byId =
      tagsData?.reduce<TagsState['items']['byId']>((acc, val) => {
        const { id, isMutable } = val;
        const info = val?.config?.v1;
        if (id && info) {
          const { name, color } = info;
          acc[id] = {
            item: {
              id,
              name: name || '',
              colorHex: color || '',
              mutable: isMutable !== false,
            },
            state: 'idle',
          };
        }
        return acc;
      }, {}) || {};
    setTagsState({
      items: {
        ids,
        byId,
      },
    });
  }, [tagsData, setTagsState]);

  const tags: TagView[] = useMemo(() => {
    return tagsState.items.ids
      .map((id: string) => {
        const tagState = tagsState.items.byId[id];
        const tag = tagState.item;
        return {
          id: tag.id || '',
          name: tag.name || '',
          colorHex: tag.colorHex || '',
          isLoading: tagState.state === 'processing',
          mutable: tag.mutable,
        };
      })
      .sort((a, b) => {
        if (a.mutable && !b.mutable) {
          return -1;
        }
        if (!a.mutable && b.mutable) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
  }, [tagsState.items.byId, tagsState.items.ids]);

  const totalTagsDisplay = useMemo(() => {
    return `${tags.length} Tag${tags.length === 1 ? '' : 's'}`;
  }, [tags.length]);

  const colorsMap = useMemo(
    () =>
      colors.reduce<{ [id: string]: ColorViewModel }>((map, val) => {
        map[val.id] = val;
        return map;
      }, {}),
    [],
  );

  const handleCreateNew = useCallback(() => {
    setTagCreateDialogWebViewOpen(true);
  }, [setTagCreateDialogWebViewOpen]);

  const handleTagCreateDialogClose = useCallback(() => {
    setTagCreateDialogWebViewOpen(false);
  }, [setTagCreateDialogWebViewOpen]);

  const handleTagCreateDialogSubmit = useCallback(
    (result: TagsCreateDialogResult) => {
      const tagId = uuidv4();
      track('creates tag:settings', {
        businessId,
        tagId,
      });
      mutateUpsertTag(
        {
          businessId: businessId || '',
          id: tagId,
          name: result.name,
          color: result.color,
        },
        {
          onSuccess: () => {
            const notificationService = notificationProvider();
            notificationService.notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'A new tag was added',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
              },
            });
            refetchSettingsData();
          },
          onError: () => {
            const notificationService = notificationProvider();
            notificationService.notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Failed to create tag',
                severity: NotificationSeverity.ERROR,
                icon: '',
              },
            });
          },
        },
      );
      setTagCreateDialogWebViewOpen(false);
    },
    [notificationProvider, businessId, mutateUpsertTag, setTagCreateDialogWebViewOpen, refetchSettingsData],
  );

  const handleDeleteDialogClose = useCallback(
    (result: DialogActionResult) => {
      setIsDeleteDialogOpen(false);
      if (result !== DialogActionResult.PRIMARY) return;
      if (!deleteDialogItem) return;

      mutateDeleteTag(
        {
          businessId: businessId || '',
          tagId: deleteDialogItem,
        },
        {
          onSuccess: () => {
            const notificationService = notificationProvider();
            notificationService.notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Tag was deleted',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
              },
            });
            refetchSettingsData();
          },
          onError: () => {
            const notificationService = notificationProvider();
            notificationService.notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Failed to delete tag',
                severity: NotificationSeverity.ERROR,
                icon: '',
              },
            });
          },
        },
      );
    },
    [setIsDeleteDialogOpen, mutateDeleteTag, deleteDialogItem, businessId, refetchSettingsData, notificationProvider],
  );

  const handleEditTagChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (editState) {
        setEditState({
          ...editState,
          input: e.currentTarget.value,
        });
      }
    },
    [editState, setEditState],
  );

  const handleEditTagFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputRootEl = e.currentTarget.closest<HTMLElement>('.MuiTextField-root');
      if (inputRootEl) {
        const itemId = inputRootEl.dataset.key || '';
        const propertyKey = inputRootEl.dataset.propertykey || '';
        const tag = tagsState.items.byId[itemId].item;
        if (propertyKey === 'name') {
          setEditState({
            itemId,
            propertyKey,
            input: tag.name,
          });
        }
      }
    },
    [tagsState.items.byId, setEditState],
  );

  const handleEditTagBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputRootEl = e.currentTarget.closest<HTMLElement>('.MuiTextField-root');
      if (inputRootEl && editState) {
        const itemId = inputRootEl.dataset.key || '';
        const { item } = tagsState.items.byId[itemId];
        const newTag = {
          id: itemId,
          name: editState.input,
          color: item.colorHex,
        };
        mutateUpsertTag(
          {
            businessId: businessId || '',
            ...newTag,
          },
          {
            onError: () => {
              const notificationService = notificationProvider();
              notificationService.notify({
                surface: NotificationSurface.SNACKBAR,
                notification: {
                  message: 'Failed to update tag',
                  severity: NotificationSeverity.ERROR,
                  icon: '',
                },
              });
            },
          },
        );
        setTagsState((prev: TagsState) => {
          const itemById = prev.items.byId[itemId];
          itemById.state = 'processing';
          itemById.item.colorHex = newTag.color;
          itemById.item.name = newTag.name;
          return {
            items: {
              ids: [...prev.items.ids],
              byId: {
                ...prev.items.byId,
              },
            },
          };
        });
        setEditState(undefined);
      }
    },
    [businessId, editState, tagsState.items.byId, setTagsState, notificationProvider, mutateUpsertTag, setEditState],
  );

  const handleColorChange = useCallback(
    (e: SelectChangeEvent, itemId: string) => {
      const colorId = e.target.value as string;
      const color = colorsMap[colorId]?.colorHex || colorsMap.green.colorHex;
      const { item } = tagsState.items.byId[itemId];
      const newTag = {
        id: itemId,
        name: item.name,
        color,
      };
      mutateUpsertTag(
        {
          businessId: businessId || '',
          ...newTag,
        },
        {
          onError: () => {
            const notificationService = notificationProvider();
            notificationService.notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Failed to update tag',
                severity: NotificationSeverity.ERROR,
                icon: '',
              },
            });
          },
        },
      );
      setTagsState((prev: TagsState) => {
        const itemById = prev.items.byId[itemId];
        itemById.state = 'processing';
        itemById.item.colorHex = newTag.color;
        itemById.item.name = newTag.name;
        return {
          items: {
            ids: [...prev.items.ids],
            byId: {
              ...prev.items.byId,
            },
          },
        };
      });
    },
    [businessId, colorsMap, tagsState.items.byId, notificationProvider, setTagsState, mutateUpsertTag],
  );

  const handleDeleteDialogOpen = useCallback(
    (item: TagView) => {
      setDeleteDialogItem(item.id);
      setIsDeleteDialogOpen(true);
    },
    [setIsDeleteDialogOpen, setDeleteDialogItem],
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { addText } = useMemo(
    () => ({
      addText: t('generic.add'),
    }),
    [t],
  );

  return {
    addText,
    colorsMap,
    editState,
    handleCreateNew,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleTagCreateDialogClose,
    handleTagCreateDialogSubmit,
    handleEditTagChange,
    handleEditTagFocus,
    handleEditTagBlur,
    handleColorChange,
    isDeleteDialogOpen,
    tagCreateDialogWebViewOpen,
    tags,
    totalTagsDisplay,
  };
};

export const TagsManager = () => {
  const {
    addText,
    colorsMap,
    editState,
    handleCreateNew,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleTagCreateDialogClose,
    handleTagCreateDialogSubmit,
    handleColorChange,
    handleEditTagChange,
    handleEditTagFocus,
    handleEditTagBlur,
    isDeleteDialogOpen,
    tagCreateDialogWebViewOpen,
    tags,
    totalTagsDisplay,
  } = useTagsManager();
  return (
    <>
      <TagsCreateDialog
        colors={colors}
        open={tagCreateDialogWebViewOpen}
        onClose={handleTagCreateDialogClose}
        onSubmit={handleTagCreateDialogSubmit}
      />
      <TagsDeleteDialog open={isDeleteDialogOpen} handleDeleteDialogClose={handleDeleteDialogClose} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Label variant="h6" noWrap>
          {totalTagsDisplay}
        </Label>
        <Button color="primary" startIcon={<AddIcon />} variant="contained" onClick={handleCreateNew}>
          {addText}
        </Button>
      </Box>
      <StackCard
        items={tags.map((item) => ({
          id: item.id,
          node: (
            <Tag
              key={item.id}
              item={item}
              editState={editState}
              colorsMap={colorsMap}
              handleColorChange={handleColorChange}
              handleDeleteDialogOpen={() => handleDeleteDialogOpen(item)}
              handleEditTagBlur={handleEditTagBlur}
              handleEditTagFocus={handleEditTagFocus}
              handleEditTagChange={handleEditTagChange}
            />
          ),
        }))}
      />
    </>
  );
};
