import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ListCampaignsParams, ListCampaignsResponse } from './types';
import { api } from '../../../../api';

export const useListCampaignsData = <TData = ListCampaignsResponse>({
  businessId,
  entity,
  ...options
}: ListCampaignsParams &
  Omit<
    UseQueryOptions<ListCampaignsResponse, unknown, TData, readonly ['listCampaigns', ListCampaignsParams]>,
    'initialData' | 'queryFn' | 'queryKey'
  > &
  ListCampaignsParams) =>
  useQuery(
    ['listCampaigns', { businessId, entity }],
    () =>
      api.campaignV2('/internal/v2/businesses/{businessId}/list/campaigns', 'post', {
        path: { businessId },
        body: {
          entity,
        },
      }),
    options,
  );
