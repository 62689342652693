import React, { FC } from 'react';
import {
  ArrowBackIcon,
  Box,
  CloseIcon,
  DialogTitle,
  DialogContent,
  IconButton,
  Label,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { BillingCountryConversationPriceProps } from './types';
import { getCountryFlag } from '../../presentation/preview/utils';

export const BillingCountryConversationPrice: FC<BillingCountryConversationPriceProps> = ({
  onBack,
  onClose,
  prices,
}: BillingCountryConversationPriceProps) => {
  const regionNames = new Intl.DisplayNames([i18next.language], { type: 'region' });
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.billing' });

  return (
    <>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {onBack ? (
          <IconButton size="small" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <Box />
        )}
        <Label sx={{ fontWeight: 500, fontSize: '24px' }}>{t('countryConversation.conversationCostDetails')}</Label>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Label sx={{ color: theme.palette.text.secondary, fontSize: '14px', lineHeight: '143%' }}>
          {t('trialPeriod.hint')}
        </Label>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: theme.palette.grey.A100,
            borderRadius: '10px',
            gap: 2,
            p: 2,
            maxHeight: 480,
            overflow: 'hidden',
            scrollbarGutter: 'stable',
            '&:hover': {
              overflow: 'auto',
            },
          }}
        >
          {prices?.map(
            ({ countryCode, price }) =>
              countryCode && (
                <Box
                  key={countryCode}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Label sx={{ fontSize: '24px', lineHeight: '150%' }}>{getCountryFlag(countryCode)}</Label>
                    <Label sx={{ fontSize: '16px', lineHeight: '100%', fontWeight: 500 }}>
                      {regionNames.of(countryCode)}
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ fontSize: '14px', lineHeight: '150%' }}>
                      {t('countryConversation.perConversation', { price: `$${price}` })}
                    </Label>
                  </Box>
                </Box>
              ),
          )}
        </Box>
      </DialogContent>
    </>
  );
};
