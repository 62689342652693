import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { ParameterValueMapping } from '@connectlyai-tenets/sdk';
import {
  MediaHeaderTemplateComponentType,
  MessageTemplate,
  MessageTemplateGroup,
  MessageTemplateStatus,
  SelectedMessageTemplate,
  TemplateComponent,
} from '../hooks/useMessageTemplateGroupsData/types';
import {
  ButtonError,
  HeaderType,
  TemplateBuilderErrors,
  TemplateComponentEnabled,
  UnsentMessageTemplate,
} from '../state/messageTemplates/types';
import {
  isValidURL,
  normalizeBody,
  normalizeFooter,
  normalizeHeader,
  normalizeListButton,
  normalizeListRowText,
} from '../presentation/preview/utils';
import { HEADER_TEXT_MAX_LENGTH } from '../components/TemplateBuilderHeader/constants';
import { FOOTER_MAX_LENGTH } from '../components/TemplateBuilderFooter/constants';
import { BUTTON_TEXT_MAX_LENGTH, BUTTON_VARIABLES_MAX_COUNT } from '../components/TemplateBuilderButtons/constants';
import { CREATE_NEW_TEMPLATE_ID, EMPTY_TEMPLATE_ID } from '../state/messageTemplates/constants';
import { getLanguage } from './common';
import { NodeType, TemplateType } from '../sdks/flow/createNode';
import { LIST_BUTTON_MAX_LENGTH, ROW_TEXT_MAX_LENGTH } from '../components/TemplateBuilderList/constants';
import i18n from '../i18n';

// https://stackoverflow.com/questions/64389323/why-do-unicode-emoji-property-escapes-match-numbers
const EMOJI_REGEXP = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
const FORMAT_CHARACTERS_REGEXP = /[_*~(```)]/;
const VARIABLE_NONESTRICT_REGEXP = /{{\w+}}/g;

export const AUDIO_MAX_FILE_SIZE_MB = 16;
export const DOCUMENT_MAX_FILE_SIZE_MB = 100;
export const IMAGE_MAX_FILE_SIZE_MB = 5;
export const STICKER_ANIMATED_MAX_FILE_SIZE_MB = 0.5;
export const STICKER_MAX_FILE_SIZE_MB = 0.1;
export const VIDEO_MAX_FILE_SIZE_MB = 16;

export enum MessageStatus {
  APPROVED,
  DISABLED,
  PARTIALLY_APPROVED,
  PAUSED,
  PENDING,
  REJECTED,
  UNSPECIFIED,
}

// MUST map to https://github.com/connectlyai/connectly-backend/blob/0146d161cd4202be4a505ea1adb756166f55b8e4/go/sdks/operability/template/connectly/template_components.go#L261
export function mapMediaHeaderTypeToTemplateParamName(type: HeaderType | undefined): string {
  switch (type) {
    case HeaderType.Image: {
      return 'header_image';
    }
    case HeaderType.Video: {
      return 'header_video';
    }
    case HeaderType.Document: {
      return 'header_document';
    }
    default: {
      return 'header_unknown';
    }
  }
}

export function mapMediaHeaderTypeStringToTemplateParamName(type: MediaHeaderTemplateComponentType): string {
  switch (type) {
    case 'TYPE_IMAGE': {
      return 'header_image';
    }
    case 'TYPE_VIDEO': {
      return 'header_video';
    }
    case 'TYPE_DOCUMENT': {
      return 'header_document';
    }
    default: {
      return 'header_unknown';
    }
  }
}

function getTemplateIdError(templateId: string | null): string {
  if (templateId === EMPTY_TEMPLATE_ID) {
    return 'Please select a template or create a new one';
  }

  return '';
}

function getBodyError(components: TemplateComponent[] | undefined): string {
  if (!components || !components.length) {
    return 'Please provide a body';
  }

  const bodyComponent = components.find((x) => x.body);
  if (!normalizeBody(bodyComponent?.body?.text?.text || '')) {
    return 'Please provide a body';
  }

  return '';
}

function getHeaderError(
  components: TemplateComponent[] | undefined,
  isComponentEnabled: TemplateComponentEnabled,
  parameterMapping: ParameterValueMapping | undefined,
): string {
  if (!isComponentEnabled.header) {
    return '';
  }

  const headerComponent = components?.find((x) => x.header);
  if (!headerComponent || !headerComponent.header) {
    return 'Please provide a header text';
  }

  const { text, media } = headerComponent.header;
  if (text) {
    const normalizedHeader = normalizeHeader(text.text || '');
    if (normalizedHeader === '') {
      return 'Please provide a header text';
    }

    if (normalizedHeader.length > HEADER_TEXT_MAX_LENGTH) {
      return `Header must not exceed ${HEADER_TEXT_MAX_LENGTH} characters`;
    }

    if (FORMAT_CHARACTERS_REGEXP.test(normalizedHeader)) {
      return "Header can't have any formatting characters (_, *, ~ or ```)";
    }

    if (EMOJI_REGEXP.test(normalizedHeader)) {
      return "Header can't have any emojis";
    }
  }
  if (media) {
    const { type, example } = media;
    if (!type) {
      return 'Something went wrong. Try selecting a different header type.';
    }
    if (parameterMapping?.mappings) {
      const paramName = mapMediaHeaderTypeStringToTemplateParamName(type);
      const variable = parameterMapping.mappings?.find((x) => x.rawParam === paramName)?.fullQualifier;
      if (variable) {
        return '';
      }
    }
    if (!example || !example.length) {
      if (type === 'TYPE_IMAGE') {
        return 'Please upload an image';
      }
      if (type === 'TYPE_VIDEO') {
        return 'Please upload a video';
      }
      if (type === 'TYPE_DOCUMENT') {
        return 'Please upload a document';
      }
      return 'Please upload media';
    }
  }

  return '';
}

function getFooterError(
  components: TemplateComponent[] | undefined,
  isComponentEnabled: TemplateComponentEnabled,
): string {
  if (!isComponentEnabled.footer) {
    return '';
  }

  const footerComponent = components?.find((x) => x.footer);
  if (!footerComponent || !footerComponent.footer) {
    return 'Please provide a footer text';
  }

  const { text } = footerComponent.footer;
  if (text) {
    const normalizedFooter = normalizeFooter(text.text || '');
    if (normalizedFooter === '') {
      return 'Please provide a footer text';
    }

    if (normalizedFooter.length > FOOTER_MAX_LENGTH) {
      return `Footer must not exceed ${FOOTER_MAX_LENGTH} characters`;
    }
  }

  return '';
}

function getButtonError(component: TemplateComponent): Partial<ButtonError> {
  const { button } = component;
  if (!button) {
    return {};
  }

  const { quickReply, url, phoneNumber } = button;

  const text = quickReply?.text || url?.text || phoneNumber?.text || '';
  if (text.trim() === '') {
    return { text: 'Please provide a button text' };
  }

  if (text.length > BUTTON_TEXT_MAX_LENGTH) {
    return { text: `Text must not exceed ${BUTTON_TEXT_MAX_LENGTH} characters` };
  }

  if (EMOJI_REGEXP.test(text)) {
    return { text: 'Emojis in buttons are not supported. Please remove them' };
  }

  if (phoneNumber) {
    const { phoneNumber: number = '' } = phoneNumber;
    if (!isPossiblePhoneNumber(number)) {
      return { phoneNumber: 'Please provide a valid phone number' };
    }
  }

  if (url) {
    let { url: link = '' } = url;
    const { trackedUrl } = url;
    const isTracked = Boolean(trackedUrl);
    link = link.toLowerCase();
    link =
      !link.startsWith('https://') && !link.startsWith('http://') && link.indexOf('.') !== -1
        ? `https://${link}`
        : link;

    if (!isValidURL(link)) {
      return { url: 'Please provide a valid link' };
    }

    try {
      const test = new URL(link);

      if (test.hostname === 'wa.me') {
        return { url: "Direct links to WhatsApp aren't allowed for buttons" };
      }

      if (test.hostname === 'bit.ly') {
        return { url: "Bit.ly links aren't allowed for buttons" };
      }
    } catch (_e) {
      return { url: 'Please provide a valid link' };
    }

    if (!isTracked) {
      const variables = link.match(VARIABLE_NONESTRICT_REGEXP);
      if (variables && variables.length > 0) {
        if (variables.length > BUTTON_VARIABLES_MAX_COUNT) {
          return { url: 'Only one variable {{1}} can be added to the end of a URL' };
        }
        if (!link.endsWith('{{1}}')) {
          return { url: 'Only one variable {{1}} can be added to the end of a URL' };
        }
      }
    }
  }

  return {};
}

function getButtonsError(
  components: TemplateComponent[] | undefined,
  isComponentEnabled: TemplateComponentEnabled,
): Partial<ButtonError>[] | undefined {
  if (!isComponentEnabled.buttons) {
    return undefined;
  }

  const buttonComponents = components?.filter((x) => x.button);
  if (!buttonComponents || !buttonComponents.length) {
    return undefined;
  }

  const buttonErrors = buttonComponents?.map(getButtonError) || [];

  // display only first error
  let hasFirstError = false;
  buttonErrors.forEach((button) => {
    if (hasFirstError) {
      delete button.text;
      delete button.url;
      delete button.phoneNumber;
    } else if (button.text || button.url || button.phoneNumber) {
      hasFirstError = true;
    }
  });

  return buttonErrors.find((x) => x.text || x.phoneNumber || x.url) ? buttonErrors : undefined;
}

function getListButtonError(
  components: TemplateComponent[] | undefined,
  isComponentEnabled: TemplateComponentEnabled,
): string {
  if (!isComponentEnabled.list) {
    return '';
  }

  const listComponent = components?.find((x) => x.list);
  if (!listComponent || !listComponent.list) {
    return 'Please provide a list button text';
  }

  const text = listComponent.list.button;
  if (!text) {
    return 'Please provide a list button text';
  }

  const normalizedListButton = normalizeListButton(text);
  if (normalizedListButton === '') {
    return 'Please provide a list button text';
  }

  if (normalizedListButton.length > LIST_BUTTON_MAX_LENGTH) {
    return `List button must not exceed ${LIST_BUTTON_MAX_LENGTH} characters`;
  }

  return '';
}

function getListError(
  components: TemplateComponent[] | undefined,
  isComponentEnabled: TemplateComponentEnabled,
): string[] | undefined {
  if (!isComponentEnabled.list) {
    return undefined;
  }

  const listComponent = components?.find((x) => x.list);
  if (!listComponent || !listComponent.list) {
    return undefined;
  }

  const rows = listComponent.list.sections?.[0].rows;
  if (!rows || !rows.length) {
    return undefined;
  }

  const errors = rows.map((row, index) => {
    const { text } = row;
    if (!text) {
      return `Please provide a text for list row ${index + 1}`;
    }

    const normalizedText = normalizeListRowText(text);
    if (!normalizedText) {
      return `Please provide a text for list row ${index + 1}`;
    }

    if (normalizedText.length > ROW_TEXT_MAX_LENGTH) {
      return `List row text must not exceed ${ROW_TEXT_MAX_LENGTH} characters`;
    }
    return '';
  });

  if (errors.filter((x) => x !== '').length) {
    return errors;
  }
  return undefined;
}

export function getTemplateBuilderErrors(
  templateId: string | null,
  template: UnsentMessageTemplate,
  isComponentEnabled: TemplateComponentEnabled,
  parameterMapping?: ParameterValueMapping,
): Partial<TemplateBuilderErrors> | undefined {
  if (!template || templateId !== CREATE_NEW_TEMPLATE_ID) {
    return undefined;
  }

  const allErrors: Partial<TemplateBuilderErrors> = {};

  const templateIdError = getTemplateIdError(templateId);
  if (templateIdError) {
    allErrors.templateId = templateIdError;
  }

  const { templateComponents } = template;

  const headerError = getHeaderError(templateComponents, isComponentEnabled, parameterMapping);
  if (headerError) {
    allErrors.header = headerError;
  }

  const bodyError = getBodyError(templateComponents);
  if (bodyError) {
    allErrors.body = bodyError;
  }

  const footerError = getFooterError(templateComponents, isComponentEnabled);
  if (footerError) {
    allErrors.footer = footerError;
  }

  const buttonsError = getButtonsError(templateComponents, isComponentEnabled);
  if (buttonsError) {
    allErrors.buttons = buttonsError;
  }

  const listError = getListError(templateComponents, isComponentEnabled);
  if (listError) {
    allErrors.list = listError;
  }

  const listButtonError = getListButtonError(templateComponents, isComponentEnabled);
  if (listButtonError) {
    allErrors.listButton = listButtonError;
  }

  if (Object.values(allErrors).length > 0) {
    return allErrors;
  }

  return undefined;
}

export function getButtonText(component: TemplateComponent): string {
  const defaultText = '';

  const { button } = component;
  if (!button) {
    return defaultText;
  }

  const { quickReply, url, phoneNumber } = button;
  if (phoneNumber) {
    return phoneNumber.text || defaultText;
  }
  if (url) {
    return url.text || defaultText;
  }
  if (quickReply) {
    return quickReply.text || defaultText;
  }

  return defaultText;
}

export function getButtonLabel(component: TemplateComponent, index: number): string {
  const { button } = component;
  if (!button) {
    return 'Button';
  }

  const { quickReply, url, phoneNumber } = button;
  if (url) {
    return 'Link to website';
  }
  if (phoneNumber) {
    return 'Link to phone number';
  }
  if (quickReply) {
    return 'Quick Reply';
  }

  return `Button ${index + 1}`;
}

export function getHeaderLabel(type: HeaderType): string {
  switch (type) {
    case HeaderType.Text: {
      return 'Text';
    }
    case HeaderType.Image: {
      return 'Image';
    }
    case HeaderType.Video: {
      return 'Video';
    }
    case HeaderType.Document: {
      return 'Document';
    }
    default: {
      return '';
    }
  }
}

export function getTemplateLabel(
  templateId: string,
  templateIndex: Record<string, { template: MessageTemplate; group: MessageTemplateGroup }>,
): string {
  const templateGroup = templateIndex[templateId];

  return templateGroup
    ? `${templateGroup.group?.name} (${getLanguage(templateGroup.template.language?.code || '')})`
    : `${templateId}`;
}

export function getTemplateStatusColor(status: MessageTemplateStatus | MessageStatus): string {
  switch (status) {
    case 'MESSAGE_TEMPLATE_STATUS_APPROVED':
    case MessageStatus.APPROVED: {
      return '#4CAF50';
    }
    case 'MESSAGE_TEMPLATE_STATUS_PENDING':
    case MessageStatus.PARTIALLY_APPROVED: {
      return '#FF9800';
    }
    case MessageStatus.PENDING: {
      return '#FDD835';
    }
    case 'MESSAGE_TEMPLATE_STATUS_REJECTED':
    case MessageStatus.REJECTED: {
      return '#FF5722';
    }
    default: {
      return '#7986CB';
    }
  }
}

export function getMessageNodeLabel(
  incomplete: boolean,
  templateType: TemplateType | undefined,
  status: MessageTemplateStatus,
): string {
  if (incomplete) {
    return 'Message Incomplete';
  }

  let messageLabel;
  switch (templateType) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      messageLabel = 'Link Message';
      break;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      messageLabel = 'Button Message';
      break;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      messageLabel = 'Simple Message';
      break;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      messageLabel = 'List Message';
      break;
    }
    default: {
      messageLabel = 'Message';
    }
  }

  switch (status) {
    case 'MESSAGE_TEMPLATE_STATUS_APPROVED': {
      return `Approved ${messageLabel}`;
    }
    case 'MESSAGE_TEMPLATE_STATUS_PENDING': {
      return `Pending ${messageLabel}`;
    }
    case 'MESSAGE_TEMPLATE_STATUS_REJECTED': {
      return `Rejected ${messageLabel}`;
    }
    case 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED': {
      return messageLabel;
    }
    default: {
      return messageLabel;
    }
  }
}

export function isMediaHeaderType(type: HeaderType): boolean {
  switch (type) {
    case HeaderType.Image:
    case HeaderType.Video:
    case HeaderType.Document: {
      return true;
    }
    default: {
      return false;
    }
  }
}

export function getAcceptedFileExtensionRegex(type: HeaderType): RegExp | null {
  switch (type) {
    case HeaderType.Image: {
      return /\.(jpg|jpeg|png)$/i;
    }
    case HeaderType.Video: {
      return /\.(mp4|3gp)$/i;
    }
    case HeaderType.Document: {
      return /\.(txt|pdf|doc|ppt|xls|docx|pptx|xlsx)$/i;
    }
    default: {
      return null;
    }
  }
}

// https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types
export function getAcceptedFileFormats(type: HeaderType): string | string[] {
  switch (type) {
    case HeaderType.Text: {
      return '';
    }
    case HeaderType.Image: {
      return ['image/png', 'image/jpeg'];
    }
    case HeaderType.Video: {
      return ['video/mp4', 'video/3gp'];
    }
    case HeaderType.Document: {
      return [
        'text/plain',
        'application/pdf',
        'application/vnd.ms-powerpoint',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
    }
    default: {
      return '';
    }
  }
}

// https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types
export function getMaxFileSize(type: HeaderType): number | undefined {
  switch (type) {
    case HeaderType.Text: {
      return 0;
    }
    case HeaderType.Image: {
      return IMAGE_MAX_FILE_SIZE_MB;
    }
    case HeaderType.Video: {
      return VIDEO_MAX_FILE_SIZE_MB;
    }
    case HeaderType.Document: {
      return DOCUMENT_MAX_FILE_SIZE_MB;
    }
    default: {
      return 0;
    }
  }
}

export function getMediaHeaderType(type: HeaderType): MediaHeaderTemplateComponentType {
  switch (type) {
    case HeaderType.Text: {
      return 'TYPE_UNSPECIFIED';
    }
    case HeaderType.Image: {
      return 'TYPE_IMAGE';
    }
    case HeaderType.Video: {
      return 'TYPE_VIDEO';
    }
    case HeaderType.Document: {
      return 'TYPE_DOCUMENT';
    }
    default: {
      return 'TYPE_UNSPECIFIED';
    }
  }
}

export function getRejectedReasonText(rejectedReason: string | undefined): string {
  if (!rejectedReason) {
    return '';
  }

  const description = 'The message was rejected by WhatsApp';
  switch (rejectedReason) {
    case 'NONE': {
      return '';
    }
    case 'TAG_CONTENT_MISMATCH': {
      return `${description} because the language and/or template category selected don’t match the template content.`;
    }
    case 'INVALID_FORMAT': {
      return `${description} because placeholders or other elements that were formatted incorrectly. Spelling, punctuation, grammar errors can also get rejected.`;
    }
    case 'ABUSIVE_CONTENT': {
      return `${description} because message contains abusive content, or content not in the chosen language. Spam-like messages will also be rejected for this reason.`;
    }
    default: {
      return `${rejectedReason}`;
    }
  }
}

export function hasNodeName(type: NodeType): boolean {
  switch (type) {
    case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_WA_QUICK_REPLY_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_WA_CTA_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE':
    case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
}

export const getACTemplateStatus = (
  firstTemplate: SelectedMessageTemplate | undefined,
  secondTemplate: SelectedMessageTemplate | undefined,
) => {
  const firstMessageStatus = firstTemplate?.template?.status;
  let secondMessageStatus = secondTemplate?.template?.status;

  if (!firstMessageStatus) {
    return MessageStatus.UNSPECIFIED;
  }
  // In the event the second message is turned off we will default both to same status
  if (!secondMessageStatus) {
    secondMessageStatus = firstMessageStatus;
  }
  if (
    firstMessageStatus === 'MESSAGE_TEMPLATE_STATUS_PENDING' ||
    secondMessageStatus === 'MESSAGE_TEMPLATE_STATUS_PENDING'
  ) {
    return MessageStatus.PENDING;
  }

  if (
    firstMessageStatus === 'MESSAGE_TEMPLATE_STATUS_REJECTED' &&
    secondMessageStatus === 'MESSAGE_TEMPLATE_STATUS_REJECTED'
  ) {
    return MessageStatus.REJECTED;
  }

  if (
    firstMessageStatus === 'MESSAGE_TEMPLATE_STATUS_APPROVED' &&
    secondMessageStatus === 'MESSAGE_TEMPLATE_STATUS_APPROVED'
  ) {
    return MessageStatus.APPROVED;
  }

  return MessageStatus.PARTIALLY_APPROVED;
};

export const getTemplateStatusShortLabel = (messageStatus: MessageTemplateStatus | undefined) => {
  if (!messageStatus) {
    return '';
  }
  if (messageStatus === 'MESSAGE_TEMPLATE_STATUS_PENDING') {
    return i18n.t('messageTemplate.pending');
  }
  if (messageStatus === 'MESSAGE_TEMPLATE_STATUS_APPROVED') {
    return i18n.t('messageTemplate.approved');
  }
  if (messageStatus === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
    return i18n.t('messageTemplate.rejected');
  }
  if (messageStatus === 'MESSAGE_TEMPLATE_STATUS_PAUSED') {
    return i18n.t('messageTemplate.paused');
  }
  if (messageStatus === 'MESSAGE_TEMPLATE_STATUS_DISABLED') {
    return i18n.t('messageTemplate.disabled');
  }
  return '';
};
