import { ActivationScreen } from '@components/AbandonedCartActivation/constants';
import { ShopifyAbandonedCartDiscount, ShopifyDiscountType } from '@hooks/useSettingsData/types';
import { atom } from 'jotai';

export const screenAtom = atom<ActivationScreen>(ActivationScreen.HomeScreen);

// Shopify integration atoms
export const languageAtom = atom<string>('en');
export const discountAtom = atom<ShopifyAbandonedCartDiscount>({
  discountType: 'DISCOUNT_TYPE_FIXED_AMOUNT' as ShopifyDiscountType,
  discountCode: '',
  discountValue: 0,
} as ShopifyAbandonedCartDiscount);
export const reminderDiscountAtom = atom<ShopifyAbandonedCartDiscount>({
  discountType: 'DISCOUNT_TYPE_FIXED_AMOUNT' as ShopifyDiscountType,
  discountCode: '',
  discountValue: 0,
} as ShopifyAbandonedCartDiscount);
export const isPersonalMessageAtom = atom<boolean>(true);
export const enableReminderAtom = atom<boolean>(true);
export const enableSafeWindowAtom = atom<boolean>(true);

// VTEX integration atoms
export const vtexLanguageAtom = atom<string>('en');
// TODO: [jason] Replace these with vtex discount when available
export const vtexDiscountAtom = atom<ShopifyAbandonedCartDiscount>({
  discountType: 'DISCOUNT_TYPE_FIXED_AMOUNT' as ShopifyDiscountType,
  discountCode: '',
  discountValue: 0,
} as ShopifyAbandonedCartDiscount);
export const vtexReminderDiscountAtom = atom<ShopifyAbandonedCartDiscount>({
  discountType: 'DISCOUNT_TYPE_FIXED_AMOUNT' as ShopifyDiscountType,
  discountCode: '',
  discountValue: 0,
} as ShopifyAbandonedCartDiscount);
export const vtexIsPersonalMessageAtom = atom<boolean>(true);
export const vtexEnableReminderAtom = atom<boolean>(false);
export const vtexEnableSafeWindowAtom = atom<boolean>(true);
export const vtexStoreDomainAtom = atom<string>('');
export const apiKeyAtom = atom<string>('');
export const apiTokenAtom = atom<string>('');
export const vtexActivationStepAtom = atom<number>(0);
export const vtexAccountNameAtom = atom<string>('');
export const vtexEnableHeaderImageAtom = atom<boolean>(true);
