import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { MediaViewer, Portal } from '@connectlyai-tenets/media-viewer';
import { CHATROOM_DID_INIT } from '../../analytics';
import { ChatRoomDIContext } from '../../context';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import ActionBar from '../actionbar/ActionBar';
import ChatThread from '../chatthread/ChatThread';
import Composer from '../composer/Composer';
import { setRoom, setUser } from '../../state/action';
import { setAgents, setTags } from '../../state/businessSlice';
import { selectLoadingState, startRoomMonitor, toggleMediaViewer } from '../../state/roomSlice';
import { startUserMonitor } from '../../state/userSlice';
import { selectMediaItems, selectIsMediViewerOpen } from '../../state/roomSliceSelectors';
import { setQuickReplyTemplates } from '../../state/quickReplySlice';
import { tryQueryMessageTemplateGroups } from '../../state/thunk';
export const ChatRoomComponentId = '11985960-01f3-4bcf-9095-c41d70dbfc32';
function mapAgentDtoToAgent(dto) {
    return {
        id: dto.id,
        firstName: dto.firstName,
        lastName: dto.lastName,
        profilePicUrl: '',
        username: dto.username,
    };
}
function mapQuickReplyTemplateDto(dto) {
    return {
        id: dto.id,
        title: dto.header,
        body: dto.bodyTemplate,
    };
}
const ChatRoom = ({ logProvider }) => {
    const di = useContext(ChatRoomDIContext);
    if (di === undefined) {
        logProvider().error('DI container not available', () => ({
            component: ChatRoomComponentId,
        }));
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        logProvider().debug(CHATROOM_DID_INIT, () => ({}));
    }, [di.config.roomId]);
    useEffect(() => {
        if (di.config.businessId === '') {
            return undefined;
        }
        const sub = di
            .subscriptionBusinessProvider()(di.config.businessId)
            .subscribe((payload) => {
            dispatch(setAgents(payload.agents.map(mapAgentDtoToAgent)));
            dispatch(setTags(payload.tags));
            dispatch(setQuickReplyTemplates(payload.quickReplyTemplates.map(mapQuickReplyTemplateDto)));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.businessId, di.subscriptionBusinessProvider]);
    useEffect(() => {
        dispatch(tryQueryMessageTemplateGroups({ di }));
    }, [dispatch]);
    useEffect(() => {
        dispatch(startRoomMonitor());
        const sub = di
            .subscriptionRoomProvider()(di.config.roomId)
            .subscribe((payload) => {
            dispatch(setRoom(payload));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.roomId, di.subscriptionRoomProvider]);
    useEffect(() => {
        dispatch(startUserMonitor());
        const sub = di
            .subscriptionUserProvider()(di.config.businessUserId)
            .subscribe((user) => {
            if (user) {
                dispatch(setUser(user));
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.config.businessUserId, di.subscriptionUserProvider]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ChatRoomComponentId);
    const loadingState = useFeatureSelector(selectLoadingState);
    const mediaItems = useFeatureSelector(selectMediaItems);
    const isMediaViewerOpen = useFeatureSelector(selectIsMediViewerOpen);
    const handleOnClose = () => {
        dispatch(toggleMediaViewer());
    };
    return (_jsxs(_Fragment, { children: [isMediaViewerOpen && mediaItems && (_jsx(Portal, { children: _jsx(MediaViewer, { items: mediaItems, onClose: handleOnClose }) })), UIComponent && (_jsx(UIComponent, { ActionBar: ActionBar, ChatThread: ChatThread, Composer: Composer, loadingState: loadingState }))] }));
};
export default ChatRoom;
