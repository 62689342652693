import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CheckIcon,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Label,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
  AttachFileIcon,
  SxProps,
} from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, selectVtexIntegrationId, useSettingsData, useMeData } from '@hooks';
import { CreateAudienceModalProps, UseCreateAudienceModalProps } from './types';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { TEMPLATE_INFO, TEMPLATES } from '../../constants';
import { AudienceType, QueryTemplateType } from '../../types';
import { CreateQueryAudience } from './CreateQueryAudience';
import { CreateSpreadsheetAudience } from './CreateSpreadsheetAudience/CreateSpreadsheetAudience';

const useCreateAudienceModal = ({ audienceTypes }: UseCreateAudienceModalProps) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: vtexIntegrationId } = useSettingsData({
    businessId: businessId || '',
    select: selectVtexIntegrationId,
  });
  const [audienceType, setAudienceType] = useState<AudienceType>(audienceTypes[0]);
  const [templateType, setTemplateType] = useState<QueryTemplateType | undefined>('engaged');
  const loadCohortInterval = useRef<number | NodeJS.Timer | undefined>(undefined);
  useEffect(() => {
    if (audienceTypes.length === 1) {
      setAudienceType(audienceTypes[0]);
    }
  }, [audienceTypes]);
  useEffect(() => {
    return () => {
      if (loadCohortInterval.current !== undefined) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(loadCohortInterval.current as number);
      }
    };
  }, []);
  const theme = useTheme();
  const isMediaMd = useMediaQuery(theme.breakpoints.down('lg'));
  const { createText, cancelText } = useMemo(() => {
    return {
      createText: 'Create new smart audience',
      cancelText: 'Cancel',
    };
  }, []);

  const dimensions = useMemo(() => {
    const width = { left: isMediaMd ? 35 : 45, right: isMediaMd ? 73 : 90 };
    if (audienceTypes.length === 1) {
      width.left = 0;
    }
    return width;
  }, [audienceTypes.length, isMediaMd]);
  const selectOption = useCallback(
    (newAudienceType: AudienceType, template?: QueryTemplateType) => {
      setAudienceType(newAudienceType);
      setTemplateType(template);
    },
    [setAudienceType, setTemplateType],
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('audience.createAudienceModal.title'),
      subtitle: t('audience.createAudienceModal.subtitle'),
    };
  }, [t]);
  const availableTemplates = useMemo(() => {
    return TEMPLATES.filter((temp) => {
      if (!vtexIntegrationId && TEMPLATE_INFO[temp].vtex) {
        return false;
      }
      return true;
    });
  }, [vtexIntegrationId]);

  return {
    availableTemplates,
    title,
    subtitle,
    theme,
    createText,
    cancelText,
    isMediaMd,
    dimensions,
    selectOption,
    audienceType,
    templateType,
  };
};

const Option = ({
  backgroundColor,
  highlightColor,
  icon,
  title,
  selected,
  comingSoon,
  onClick,
  sx,
}: {
  backgroundColor: string;
  highlightColor: string;
  icon: React.ReactNode;
  title: string;
  selected: boolean;
  comingSoon?: boolean;
  onClick: () => void;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const theme = useTheme();
  const comingSoonText = useMemo(() => {
    return t('generic.comingSoon');
  }, [t]);
  return (
    <ListItemButton
      onClick={onClick}
      selected={selected}
      disabled={comingSoon}
      sx={[
        {
          '&.Mui-selected': {
            backgroundColor: highlightColor,
          },
          borderRadius: theme.spacing(1),
          px: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{ cursor: comingSoon ? 'default' : 'pointer' }}
      >
        <Stack direction="row" alignItems="center" gap={SIZE_SPACING_INTER_COMPONENT}>
          <Box
            sx={{
              height: theme.spacing(4),
              width: theme.spacing(4),
              backgroundColor,
              borderRadius: theme.spacing(1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Box>
          <ListItemText primary={<Label variant="h7">{title}</Label>} />
        </Stack>
        {comingSoon && (
          <Label variant="body2" sx={{ mt: 1, color: theme.palette.text.secondary, textTransform: 'uppercase' }}>
            {comingSoonText}
          </Label>
        )}
        {selected && (
          <ListItemIcon sx={{ minWidth: 0 }}>
            <CheckIcon color="primary" />
          </ListItemIcon>
        )}
      </Stack>
    </ListItemButton>
  );
};
const TemplateOption = ({
  templateName,
  ...props
}: {
  templateName: string;
  backgroundColor: string;
  highlightColor: string;
  icon: React.ReactNode;
  selected: boolean;
  comingSoon?: boolean;
  onClick: () => void;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'audience.manage.smartAudience.audiences' });
  const { title } = useMemo(() => {
    return {
      title: t(`${templateName}.optionLabel`),
    };
  }, [t, templateName]);
  return <Option {...props} title={title} />;
};

export const CreateAudienceModal = ({ businessId, isOpen, closeModal, audienceTypes }: CreateAudienceModalProps) => {
  const {
    availableTemplates,
    isMediaMd,
    title,
    subtitle,
    theme,
    createText,
    cancelText,
    dimensions,
    selectOption,
    audienceType,
    templateType,
  } = useCreateAudienceModal({ audienceTypes });
  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="xl">
      <Stack
        direction={isMediaMd ? 'column' : 'row'}
        justifyContent={isMediaMd ? 'flex-start' : 'space-between'}
        alignItems={isMediaMd ? 'space-between' : 'flex-start'}
        p={SIZE_SPACING_INTER_COMPONENT}
        sx={{
          pt: 1,
          width: isMediaMd ? 'auto' : theme.spacing(dimensions.left + dimensions.right),
          maxWidth: '100vw',
        }}
        divider={
          audienceTypes.length > 1 && (
            <Divider
              orientation={isMediaMd ? 'horizontal' : 'vertical'}
              flexItem
              sx={{ borderColor: theme.palette.grey[200] }}
            />
          )
        }
      >
        {audienceTypes.length > 1 && (
          <Stack
            sx={{ flex: isMediaMd ? '0 0 auto' : `1 1 ${theme.spacing(dimensions.left)}`, height: '100%' }}
            justifyContent="flex-start"
          >
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between">
                <Label variant="h4">{title}</Label>
              </Stack>
              <Label variant="body1" color="text.secondary">
                {subtitle}
              </Label>
            </DialogTitle>
            <DialogContent sx={{ pt: 1, pb: isMediaMd ? 0 : 2 }}>
              <Stack divider={<Divider sx={{ borderColor: theme.palette.grey[200] }} />}>
                <Option
                  sx={{ mb: SIZE_SPACING_INTER_COMPONENT }}
                  icon={<AttachFileIcon />}
                  title="Upload a .csv file"
                  selected={audienceType === 'spreadsheet'}
                  comingSoon={false}
                  backgroundColor="rgba(0,0,0,0)"
                  highlightColor={theme.palette.grey[300]}
                  onClick={() => selectOption('spreadsheet')}
                />
                <List sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }}>
                  {availableTemplates.map((name) => {
                    const { icon, backgroundColor, highlightColor, comingSoon } = TEMPLATE_INFO[name];
                    return (
                      <TemplateOption
                        key={name}
                        icon={icon}
                        templateName={name}
                        selected={audienceType === 'query' && templateType === name}
                        backgroundColor={backgroundColor}
                        highlightColor={highlightColor}
                        comingSoon={comingSoon}
                        onClick={() => selectOption('query', name)}
                      />
                    );
                  })}
                </List>
              </Stack>
            </DialogContent>
          </Stack>
        )}
        <IconButton
          size="small"
          onClick={closeModal}
          sx={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(2) }}
        >
          <CloseIcon />
        </IconButton>
        {audienceType === 'spreadsheet' && (
          <CreateSpreadsheetAudience
            title="Upload a CSV file"
            businessId={businessId}
            width={dimensions.right}
            cancelText={cancelText}
            createText={createText}
            isMediaMd={isMediaMd}
            closeModal={closeModal}
          />
        )}
        {audienceType === 'query' && (
          <CreateQueryAudience
            audienceTypes={audienceTypes}
            businessId={businessId || ''}
            closeModal={closeModal}
            width={dimensions.right}
          />
        )}
      </Stack>
    </Dialog>
  );
};
