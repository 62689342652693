import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@connectlyai-tenets/ui-styled-web';
import { DialogActionResult, TagDeleteDialogProps } from './types';

export const TagsDeleteDialog = ({ open, handleDeleteDialogClose }: TagDeleteDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleDeleteDialogClose(DialogActionResult.DISMISS)}
      data-testid="settings/tags/deleteconfirmdialog"
      aria-labelledby="deleteconfirmdialog-title"
      aria-describedby="deleteconfirmdialog-description"
    >
      <DialogTitle id="deleteconfirmdialog-title">Remove Message?</DialogTitle>
      <DialogContent>
        <DialogContentText id="deleteconfirmdialog-description">
          Are you sure you want to delete this tag?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDeleteDialogClose(DialogActionResult.SECONDARY)}
          color="inherit"
          data-testid="settings/tags/secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDeleteDialogClose(DialogActionResult.PRIMARY)}
          color="primary"
          data-testid="settings/tags/primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
