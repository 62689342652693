import React, { useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  DAY_IN_SECONDS,
  selectBusinessId,
  useAnalytics,
  useMeData,
  useMutationResourceLimit,
  useMutationDeleteResource,
  useStandardNotifications,
  useResourceLimitView,
  DEFAULT_RESOURCE_LIMIT,
} from '@hooks';
import {
  Box,
  ConnectlyCard,
  Divider,
  FormControl,
  InfoOutlinedIcon,
  Label,
  MenuItem,
  NewLabel,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { DURATION_OPTIONS, DEFAULT_SETTINGS } from './constants';

const useCampaignsFrequencyCap = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.campaigns.frequencyCap' });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { enabled, duration: capDurationDays, isLoading: capIsLoading } = useResourceLimitView({ businessId });
  const queryClient = useQueryClient();
  const durationSelected: number | undefined = useMemo(() => {
    const match = DURATION_OPTIONS.find((option) => option === capDurationDays);
    if (match) {
      return match;
    }
    return undefined;
  }, [capDurationDays]);
  const { notifySuccess: notifyChangeLimitSuccess, notifyError: notiyChangeLimitError } = useStandardNotifications({
    successMessage: 'Rate limit setting updated.',
    errorMessage: 'Failed to update setting. Please try again',
  });
  const { mutate: mutateLimit, isLoading: changeIsLoading } = useMutationResourceLimit({
    onError: () => {
      notiyChangeLimitError();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['rate-limit'], data);
      notifyChangeLimitSuccess();
    },
  });
  const { notifySuccess: notifyDeleteLimitSuccess, notifyError: notifyDeleteLimitError } = useStandardNotifications({
    successMessage: 'Rate limit removed',
    errorMessage: 'Failed to update setting. Please try again',
  });
  const { mutate: deleteLimit, isLoading: deleteIsLoading } = useMutationDeleteResource({
    onError: () => {
      notifyDeleteLimitError();
    },
    onSuccess: () => {
      queryClient.setQueryData(['rate-limit'], { entity: undefined });
      queryClient.invalidateQueries({ queryKey: ['rate-limit'] });
      notifyDeleteLimitSuccess();
    },
  });
  const { cardTitle, cardDescription, sectionHeader, sectionInfo, actionText, actionFrequency } = useMemo(() => {
    return {
      actionFrequency: t('frequency.day'),
      actionText: t('setFrequencyActionText'),
      cardDescription: t('frequencyCapDescription'),
      cardTitle: t('title'),
      enableTooltip: t('enableFrequencyCapTooltip'),
      sectionHeader: t('setFrequencyCap'),
      sectionInfo: t('setFrequencyCapInfo'),
    };
  }, [t]);

  const { sendAnalytics } = useAnalytics();
  const handleChangeCap = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newCapDuration = DAY_IN_SECONDS * Number(event.target.value);
      sendAnalytics('(settings) change frequency cap', {
        businessId,
        duration: newCapDuration,
        limit: DEFAULT_RESOURCE_LIMIT,
      });
      mutateLimit({ businessId: businessId || '', duration: newCapDuration, limit: DEFAULT_RESOURCE_LIMIT });
    },
    [businessId, mutateLimit, sendAnalytics],
  );
  const changeEnabled = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newEnabled = event.target.checked;
      const trackEvent = newEnabled ? '(settings) enable frequency cap' : '(settings) disable frequency cap';
      sendAnalytics(trackEvent, { businessId, enabled: newEnabled });
      if (newEnabled) {
        mutateLimit({
          businessId: businessId || '',
          ...DEFAULT_SETTINGS,
        });
        queryClient.setQueryData(['rate-limit'], { entity: DEFAULT_SETTINGS });
      } else {
        queryClient.setQueryData(['rate-limit'], { entity: undefined });
        deleteLimit({ businessId: businessId || '' });
      }
    },
    [businessId, deleteLimit, mutateLimit, queryClient, sendAnalytics],
  );
  const inputDisabled = useMemo(() => {
    return capIsLoading || deleteIsLoading || changeIsLoading;
  }, [capIsLoading, changeIsLoading, deleteIsLoading]);
  const switchTooltip = useMemo(() => {
    let tooltip = '';
    if (inputDisabled) {
      tooltip = t('loading');
    } else if (enabled) {
      tooltip = t('disableFrequencyCapTooltip');
    } else {
      tooltip = t('enableFrequencyCapTooltip');
    }
    return tooltip;
  }, [enabled, inputDisabled, t]);
  return {
    actionFrequency,
    actionText,
    capDurationDays,
    cardDescription,
    cardTitle,
    changeEnabled,
    durationSelected,
    enabled,
    handleChangeCap,
    inputDisabled,
    sectionHeader,
    sectionInfo,
    switchTooltip,
  };
};

export const CampaignsFrequencyCap = () => {
  const {
    actionFrequency,
    actionText,
    cardDescription,
    cardTitle,
    changeEnabled,
    durationSelected,
    enabled,
    handleChangeCap,
    inputDisabled,
    sectionHeader,
    sectionInfo,
    switchTooltip,
  } = useCampaignsFrequencyCap();
  const theme = useTheme();
  return (
    <ConnectlyCard>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Label variant="h5">{cardTitle}</Label>
            <NewLabel sx={{ ml: 1 }} />
          </Stack>
          <Tooltip title={switchTooltip} placement="top" arrow>
            <Box>
              <Switch checked={enabled} onChange={changeEnabled} disabled={inputDisabled} />
            </Box>
          </Tooltip>
        </Stack>
        <Label variant="body1">{cardDescription}</Label>
        <Divider sx={{ my: 1 }} />
        <Label variant="h7" sx={{ fontWeight: '700 !important' }}>
          {sectionHeader}
        </Label>
        <Stack direction="row" sx={{ p: 2, background: theme.palette.grey[200], borderRadius: '10px' }}>
          <InfoOutlinedIcon sx={{ mr: 1 }} />
          <Label variant="subtitle2">{sectionInfo}</Label>
        </Stack>
        {enabled && (
          <Stack direction="row" alignItems="center">
            <Label variant="subtitle2">{actionText}</Label>
            <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0, mx: 1, width: 'fit-content' }}>
              <Select
                fullWidth
                variant="outlined"
                value={durationSelected}
                onChange={handleChangeCap}
                disabled={inputDisabled}
                sx={{
                  borderRadius: '10px',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  width: 'fit-content',
                }}
                inputProps={{ width: 'fit-content' }}
              >
                {DURATION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Label variant="subtitle2">{`${actionFrequency}${durationSelected === 1 ? '' : 's'}`}</Label>
          </Stack>
        )}
      </Stack>
    </ConnectlyCard>
  );
};
