import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { buildDIContainer } from './di';
import storeProvider from './state/store';
import { setActiveFilter, setInactiveFilter, setTags } from './state/filtersSlice';
const CONTEXT_INITIAL = {
    filters: [],
};
function maybeEmitContextState(state) {
    const filters = Object.keys(state.filtersSlice.enabled.byId).map((id) => state.filtersSlice.items.byId[id]);
    return {
        filters,
    };
}
function maybeEmitFilterState(state) {
    const filters = Object.keys(state.filtersSlice.items.byId).map((id) => state.filtersSlice.items.byId[id]);
    return filters;
}
export default class RealContextDataStore {
    constructor(deps) {
        this.di = buildDIContainer(deps);
        this.contextSubject = new BehaviorSubject(CONTEXT_INITIAL);
        this.filtersSubject = new BehaviorSubject([]);
        this.store = storeProvider();
        this.store.subscribe(() => {
            const newState = this.store.getState();
            const maybeContextEmission = maybeEmitContextState(newState);
            if (maybeContextEmission) {
                this.contextSubject.next(maybeContextEmission);
            }
            const maybeFiltersEmission = maybeEmitFilterState(newState);
            if (maybeFiltersEmission) {
                this.filtersSubject.next(maybeFiltersEmission);
            }
        });
        this.contextObservable = this.contextSubject.pipe(distinctUntilChanged(isEqual));
        this.filtersObservable = this.filtersSubject;
        deps
            .businessDataStoreProvider()
            .subscribeBusiness(deps.config.businessId)
            .subscribe((businessData) => {
            businessData.fold((business) => {
                this.store.dispatch(setTags(business.tags));
            }, () => { }, () => { });
        });
    }
    mutationSetFilter(filterId) {
        this.store.dispatch(setActiveFilter(filterId));
        return Promise.resolve();
    }
    mutationUnsetFilter(filterId) {
        this.store.dispatch(setInactiveFilter(filterId));
        return Promise.resolve();
    }
    subscriptionContext() {
        return this.contextObservable;
    }
    subscriptionFilters() {
        return this.filtersObservable;
    }
}
