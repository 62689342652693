import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { FlowEventRequestSubmitResponse, FlowEventRequestSubmitMutation, FlowEventRequestSubmitOptions } from './types';

export const useEventRequestSubmitReactFlowDocument = (options?: FlowEventRequestSubmitOptions) => {
  return useMutation(
    (variables: FlowEventRequestSubmitMutation) =>
      api.flowV1(
        '/internal/v1/businesses/{input.businessId}/event_request_submit_react_flow_document/flow_documents',
        'post',
        {
          path: { 'input.businessId': variables.businessId },
          body: {
            input: {
              ...variables,
            },
          },
        },
      ) as Promise<FlowEventRequestSubmitResponse>,
    {
      ...options,
    },
  );
};
