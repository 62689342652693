import type { QueryCampaignUTMConfiguration } from './types';

export const DEFAULT_SETTINGS: QueryCampaignUTMConfiguration = {
  v1: {
    params: [
      {
        type: 'UTM_PARAM_TYPE_SOURCE',
        value: {
          resolverType: 'UTM_PARAM_VALUE_RESOLVER_TYPE_CONNECTLY',
        },
      },
      {
        type: 'UTM_PARAM_TYPE_MEDIUM',
        value: {
          resolverType: 'UTM_PARAM_VALUE_RESOLVER_TYPE_STATIC',
          static: {
            value: 'whatsapp',
          },
        },
      },
      {
        type: 'UTM_PARAM_TYPE_CAMPAIGN',
        value: {
          resolverType: 'UTM_PARAM_VALUE_RESOLVER_TYPE_CAMPAIGN_NAME',
        },
      },
    ],
  },
};
