import React, { FC, cloneElement, isValidElement, ReactElement, PropsWithChildren, SyntheticEvent } from 'react';
import { track } from 'src/utils';
import { AnalyticsType } from 'src/utils/analytics';

export const WithAnalytics: FC<PropsWithChildren<{ type: AnalyticsType; data: Record<string, unknown> }>> = ({
  children,
  type,
  data,
}) => {
  if (isValidElement(children)) {
    return cloneElement(children as ReactElement, {
      onClick: (e: SyntheticEvent) => {
        track(type, data);
        children.props.onClick?.(e);
      },
    });
  }
  return children as unknown as ReactElement;
};
