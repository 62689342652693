import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { ADD_USER_URL, AddUserQuery, AddUserResponse } from './types';

export const useAddUserMutation = (
  options?: Omit<
    UseMutationOptions<AddUserResponse, ConnectlyError, AddUserQuery, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    (input: AddUserQuery): Promise<AddUserResponse> =>
      api.profileV1(ADD_USER_URL, 'post', {
        path: { businessId: input.businessId || '' },
        body: { input },
      }) as Promise<AddUserResponse>,
    {
      ...options,
    },
  );
