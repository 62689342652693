import React, { useMemo, useContext, useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  userManagementAnchorElementAtom as anchorElAtom,
  userManagementUserOpenAtom as userOpenAtom,
  userManagementRemoveOpenAtom as removeOpenAtom,
  userManagementModalOpenAtom as modalOpenAtom,
  userManagementErrorMessageAtom as errorMessageAtom,
} from '@atoms/settings';
import { useTranslation } from 'react-i18next';
import { Label, Box, Button, Card, Popover, Divider, useTheme, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { UserCell, EnhancedTable, EnhancedTableRowProps } from '@components/EnhancedTable';
import { useQueryClient } from '@tanstack/react-query';
import { AgentsResponse } from '@hooks/useAgentsData/types';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useMeData, useAgentsData, selectBusinessId, selectUserId, selectRole, useFeatureFlag } from '@hooks';
import { Role, UserList, UseUserManagement } from './types';
import { ROLE_DICT, TABLE_HEAD } from './constants';
import { AddUserModal } from './AddUserModal';
import { NotificationContext } from '../../../../contexts';
import { useChangeUserRoleMutation } from '../../hooks/useChangeRoleMutation';
import { useRemoveUserMutation } from '../../hooks/useRemoveUserMutation';

const selectUsersView = (data: AgentsResponse): UserList => {
  if (!data) return [];
  const agents = data.entity?.agents ?? [];
  return agents.map((agent) => {
    const roles = agent?.scope?.roles || [];
    let role: Role = 'ROLE_AGENT';
    if (roles.includes('ROLE_OWNER')) {
      role = 'ROLE_OWNER';
    } else if (roles.includes('ROLE_MARKETING')) {
      role = 'ROLE_MARKETING';
    }
    return {
      id: agent.id || '',
      name: agent.displayName || '',
      email: agent.username || '',
      initials: `${agent.firstName?.charAt(0).toUpperCase() || ''}${agent.lastName?.charAt(0).toUpperCase() || ''}`,
      role,
    };
  });
};

const useUserManagement = (): UseUserManagement => {
  const { notificationProvider } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = useAtom(anchorElAtom);
  const [userOpen, setUserOpen] = useAtom(userOpenAtom);
  const [removeOpen, setRemoveOpen] = useAtom(removeOpenAtom);
  const [modalOpen, setModalOpen] = useAtom(modalOpenAtom);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);
  const queryClient = useQueryClient();
  const { mutate: removeUser } = useRemoveUserMutation({
    onError: (data) => {
      const message = data?.message || 'Something went wrong';
      setErrorMessage(message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['agents']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'User Removed',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });
  const { mutate: changeRole } = useChangeUserRoleMutation({
    onError: (data) => {
      const message = data?.message || 'Something went wrong';
      setErrorMessage(message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['agents']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Role Updated',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });

  const { data: businessId } = useMeData({
    select: selectBusinessId,
  });
  const { data: userId } = useMeData({
    select: selectUserId,
  });
  const { data: users } = useAgentsData({ businessId: businessId || '', select: selectUsersView });
  const { data: role } = useMeData({
    select: selectRole,
  });

  const viewOnly = useMemo(() => {
    if (role !== 'ROLE_OWNER') return true;
    return false;
  }, [role]);

  const rows: EnhancedTableRowProps[] = useMemo(() => {
    if (!users) return [];
    return users.map((user) => {
      return {
        id: user.id,
        data: [
          {
            value: user.name,
            display: <UserCell {...user} />,
          },
          {
            value: user.role,
            display: ROLE_DICT[user.role].display,
            dropdownClick: (event) => {
              if (viewOnly) return;
              setAnchorEl(event.currentTarget);
              setUserOpen(user);
            },
          },
        ],
      };
    });
  }, [users, viewOnly, setAnchorEl, setUserOpen]);
  const cancelRemoveUser = useCallback(() => {
    setUserOpen(undefined);
    setRemoveOpen(undefined);
    setAnchorEl(undefined);
  }, [setUserOpen, setRemoveOpen, setAnchorEl]);
  const handleRemoveUser = useCallback(() => {
    const id = removeOpen?.id;
    setUserOpen(undefined);
    setRemoveOpen(undefined);
    setAnchorEl(undefined);
    if (!id || !businessId) return;
    removeUser({ businessId, userId: id });
  }, [removeOpen, businessId, removeUser, setUserOpen, setRemoveOpen, setAnchorEl]);
  const addMember = useCallback(() => {
    cancelRemoveUser();
    setModalOpen(true);
  }, [cancelRemoveUser, setModalOpen]);
  const closeModal = useCallback(() => {
    cancelRemoveUser();
    setModalOpen(false);
  }, [cancelRemoveUser, setModalOpen]);
  const onPopoverClose = useCallback(() => {
    setUserOpen(undefined);
    setRemoveOpen(undefined);
    setAnchorEl(undefined);
  }, [setUserOpen, setRemoveOpen, setAnchorEl]);

  const handleOpenRemoveUser = useCallback(() => {
    if (!userOpen) return;
    setRemoveOpen({ ...userOpen });
  }, [userOpen, setRemoveOpen]);

  const { t } = useTranslation('translation', { keyPrefix: 'settings.userManagement' });
  const { removeUserWarning } = useMemo(() => {
    return {
      removeUserWarning: t('removeUserWarning'),
    };
  }, [t]);
  return {
    removeUserWarning,
    businessId: businessId || '',
    users: users || [],
    rows,
    userId,
    viewOnly,
    errorMessage,
    modalOpen,
    userOpen,
    anchorEl,
    removeOpen,
    setUserOpen,
    cancelRemoveUser,
    changeRole,
    handleRemoveUser,
    handleOpenRemoveUser,
    addMember,
    closeModal,
    onPopoverClose,
  };
};

export const UserManagement = () => {
  const {
    removeUserWarning,
    rows,
    businessId,
    users,
    viewOnly,
    errorMessage,
    modalOpen,
    userOpen,
    anchorEl,
    removeOpen,
    changeRole,
    handleRemoveUser,
    cancelRemoveUser,
    addMember,
    closeModal,
    setUserOpen,
    handleOpenRemoveUser,
    onPopoverClose,
  }: UseUserManagement = useUserManagement();
  const theme = useTheme();
  return (
    <>
      <AddUserModal businessId={businessId} isOpen={modalOpen} closeModal={closeModal} />
      {errorMessage && (
        <Label
          variant="body2"
          sx={{
            userSelect: 'text',
            color: theme.palette.error.main,
          }}
        >
          {errorMessage}
        </Label>
      )}
      <ConnectlyCard>
        <Box
          id="user-settings-header"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: '0 0 auto',
            px: 1,
          }}
        >
          <Label variant="h6">{`${users.length} Users`}</Label>
          {!viewOnly && (
            <Button variant="outlined" color="secondary" onClick={addMember}>
              Add User
            </Button>
          )}
        </Box>
        <EnhancedTable rows={rows} headers={TABLE_HEAD} />
      </ConnectlyCard>
      <Popover
        open={!!userOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: -10,
          horizontal: 'left',
        }}
      >
        {removeOpen ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 3,
              pb: 2,
              width: '350px',
              maxWidth: '100vw',
            }}
          >
            <Label variant="h6" sx={{ pb: 2 }}>
              Remove User: {removeOpen.name}?
            </Label>
            <Label
              variant="body2"
              sx={{
                color: theme.palette.text.secondary,
                pt: 1,
                pb: 2,
              }}
            >
              {removeUserWarning}
            </Label>
            <Box sx={{ textAlign: 'right' }}>
              <Button variant="text" onClick={cancelRemoveUser}>
                Cancel
              </Button>
              <Button variant="text" onClick={handleRemoveUser}>
                Remove
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: 2,
              px: 2,
              width: '14.5rem',
            }}
          >
            {Object.values(ROLE_DICT)
              .slice(0, 3)
              .map((role) => {
                const handleClick = () => {
                  if (userOpen?.role === role.id) return;
                  changeRole({
                    businessId,
                    userId: userOpen?.id || '',
                    roles: [role.id],
                  });
                  setUserOpen(undefined);
                };
                return (
                  <Box
                    key={role.id}
                    sx={{
                      cursor: userOpen && userOpen.role === role.id ? 'default' : 'pointer',
                      py: 1.5,
                      px: 1,
                      mx: -1,
                      '&:hover':
                        !userOpen || userOpen.role === role.id ? undefined : { background: theme.palette.grey.A100 },
                    }}
                    onClick={handleClick}
                  >
                    <Label
                      variant="body1"
                      sx={{
                        fontWeight: userOpen && userOpen.role === role.id ? 'bold !important' : 'normal',
                      }}
                    >
                      {role.display}
                    </Label>
                    <Label
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {role.description}
                    </Label>
                  </Box>
                );
              })}
            <Divider sx={{ pt: 1 }} />
            <Box sx={{ textAlign: 'left' }}>
              <Button sx={{ mt: 2, textAlign: 'left' }} onClick={handleOpenRemoveUser}>
                Remove User
              </Button>
            </Box>
          </Box>
        )}
      </Popover>
    </>
  );
};
