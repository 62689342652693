import { useCallback, DragEvent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Node, OnConnect, OnEdgeUpdateFunc, Edge, ReactFlowInstance, XYPosition } from 'react-flow-renderer';
import { ulid } from 'ulid';
import produce from 'immer';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { createEdge } from '../../sdks/flow/createEdge';
import { deleteNode, duplicateNode } from '../../state/actions';
import { DUPLICATED_NODE_POSITION_OFFSET } from './constants';
import { FlowHandlers, FlowHandlersParams } from './types';
import { useCreateNode } from '../useCreateNode';
import { NodeType } from '../../sdks/flow/createNode';
import { selectIsEdgeAttached, setIsEdgeAttached } from '../../state/flow';
import appStore from '../../state/store';
import { NotificationContext } from '../../contexts';

export const useFlowHandlers = ({ flowChangeAppliers }: FlowHandlersParams): FlowHandlers => {
  const { notificationProvider } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { createNode, templateName } = useCreateNode();
  const isEdgeAttached = useSelector(selectIsEdgeAttached);

  const {
    onEdgesChangeProtected: onEdgesChange,
    onNodesChangeProtected: onNodesChange,
    selectNode,
  } = flowChangeAppliers();

  // use getState rather than selector to get nodes and edges immediately
  const nodes = useCallback(() => appStore.getState().flowDocument.nodes, []);
  const edges = useCallback(() => appStore.getState().flowDocument.edges, []);

  const createDuplicateHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToDuplicate = nodes().find((node) => node.id === id);
      if (!nodeToDuplicate || !nodeToDuplicate?.position) {
        return;
      }

      const positionWithOffset = {
        x: nodeToDuplicate.position.x,
        y: nodeToDuplicate.position.y + DUPLICATED_NODE_POSITION_OFFSET + (nodeToDuplicate.height ?? 0),
      };

      const name = templateName();

      const newNode = produce(nodeToDuplicate, (draft) => {
        draft.id = ulid();
        draft.data.v1.name = name;
        if (draft.data.v1.messageTemplateInput) draft.data.v1.messageTemplateInput.name = name;

        draft.position = positionWithOffset;
        return draft;
      });

      onNodesChange([{ type: 'add', item: newNode }]);

      dispatch(duplicateNode(nodeToDuplicate));
      event.stopPropagation();
    },
    [dispatch, nodes, onNodesChange, templateName],
  );

  const createDeleteHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToDelete = nodes().find((node) => node.id === id);
      if (!nodeToDelete) {
        return;
      }

      onNodesChange([
        {
          id,
          type: 'remove',
        },
      ]);

      dispatch(deleteNode(nodeToDelete));
      event.stopPropagation();
    },
    [dispatch, nodes, onNodesChange],
  );

  const createDeleteEdgeHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const edgeToDelete = edges().find((edge) => edge.id === id);
      if (!edgeToDelete) {
        return;
      }

      onEdgesChange([
        {
          id,
          type: 'remove',
        },
      ]);
      event.stopPropagation();
    },
    [onEdgesChange, edges],
  );

  const createEditHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToEdit = nodes().find((node) => node.id === id);
      if (!nodeToEdit) {
        return;
      }

      selectNode(nodeToEdit.id);
      event.stopPropagation();
    },
    [nodes, selectNode],
  );

  const handleConnect: OnConnect = useCallback(
    (newConnection) => {
      const sourceNode = nodes().find((node) => node.id === newConnection.source);
      const targetNode = nodes().find((node) => node.id === newConnection.target);
      if (
        sourceNode?.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' &&
        targetNode?.data?.v1?.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE'
      ) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'The list message cannot be the first message of a campaign. Please connect to a button message.',
            icon: '',
            severity: NotificationSeverity.INFO,
          },
        });
        return;
      }
      onEdgesChange([
        {
          type: 'add',
          item: createEdge({
            ...newConnection,
            source: newConnection.source || '',
            target: newConnection.target || '',
          }),
        },
      ]);
    },
    [onEdgesChange, nodes, notificationProvider],
  );

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const projectPosition = useCallback(
    (pos: XYPosition, reactFlowInstance: ReactFlowInstance | undefined, flowRef: HTMLDivElement | null): XYPosition => {
      if (!flowRef || !reactFlowInstance) return { ...pos };

      const reactFlowBounds = flowRef.getBoundingClientRect();
      if (!reactFlowBounds) return { ...pos };

      return reactFlowInstance.project({
        x: pos.x - reactFlowBounds.left,
        y: pos.y - reactFlowBounds.top,
      });
    },
    [],
  );

  const handleNodeCreate = useCallback(
    (nodeType: NodeType, reactFlowInstance: ReactFlowInstance | undefined, flowRef: HTMLDivElement | null) => {
      const clientX = 600 + (nodes().length - 1) * 550;
      const clientY = 500;

      const position = projectPosition({ x: clientX, y: clientY }, reactFlowInstance, flowRef);

      const node = createNode(nodeType, {
        position,
      }) as Node;

      onNodesChange([{ type: 'add', item: node }]);

      const campaignNode = nodes().find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN');
      if (nodes().length === 2 && campaignNode) {
        handleConnect({
          source: campaignNode.id,
          sourceHandle: `${campaignNode.id}:send`,
          target: node.id,
          targetHandle: `${node.id}:on-execute`,
        });
      }

      setTimeout(() => {
        reactFlowInstance?.setCenter(node.position.x + 150, node.position.y + 150, {
          duration: 600,
          zoom: reactFlowInstance?.getZoom(),
        });
      }, 250);
    },
    [createNode, handleConnect, nodes, onNodesChange, projectPosition],
  );

  const handleDrop = useCallback(
    (
      event: DragEvent<HTMLDivElement>,
      reactFlowInstance: ReactFlowInstance | undefined,
      flowRef: HTMLDivElement | null,
    ) => {
      event.preventDefault();
      const position = projectPosition({ x: event.clientX, y: event.clientY }, reactFlowInstance, flowRef);

      const node = createNode(event.dataTransfer.getData('application/reactflow') as NodeType, {
        position,
      }) as Node;

      onNodesChange([{ type: 'add', item: node }]);

      const campaignNode = nodes().find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN');
      if (nodes().length === 2 && campaignNode) {
        handleConnect({
          source: campaignNode.id,
          sourceHandle: `${campaignNode.id}:send`,
          target: node.id,
          targetHandle: `${node.id}:on-execute`,
        });
      }
    },
    [createNode, nodes, onNodesChange, projectPosition, handleConnect],
  );

  const handleEdgeUpdateStart = useCallback(() => {
    dispatch(setIsEdgeAttached(false));
  }, [dispatch]);

  const handleEdgeUpdate: OnEdgeUpdateFunc = useCallback(
    (oldEdge, newConnection) => {
      dispatch(setIsEdgeAttached(true));
      onEdgesChange([
        {
          id: oldEdge.id,
          type: 'remove',
        },
      ]);
      handleConnect(newConnection);
    },
    [dispatch, onEdgesChange, handleConnect],
  );

  const handleEdgeUpdateEnd = useCallback(
    (_event: MouseEvent, edge: Edge) => {
      if (!isEdgeAttached) {
        // edge was dropped on to the blank space, so remove it
        onEdgesChange([
          {
            id: edge.id,
            type: 'remove',
          },
        ]);
      }

      dispatch(setIsEdgeAttached(true));
    },
    [dispatch, isEdgeAttached, onEdgesChange],
  );
  const handleNodesDelete = useCallback(
    (deathNode: Node[]) =>
      deathNode
        .filter((node) => node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN')
        .forEach((node) => {
          onNodesChange([
            {
              id: node.id,
              type: 'remove',
            },
          ]);
        }),
    [onNodesChange],
  );

  return {
    createDuplicateHandler,
    createDeleteHandler,
    createDeleteEdgeHandler,
    createEditHandler,
    handleConnect,
    handleDragOver,
    handleDrop,
    handleEdgeUpdateStart,
    handleEdgeUpdate,
    handleEdgeUpdateEnd,
    handleNodeCreate,
    handleNodesDelete,
  };
};
