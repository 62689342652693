import React, { PropsWithChildren } from 'react';
import { AIAssistantIcon, Box, Card, Label, QuestionAnswerAIIcon, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TargetHandle } from '@components/TargetHandle';
import { SIZE_MEDIA_S, SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

export const FlowChartAiAssistantNode = ({ id }: PropsWithChildren<{ id: string }>) => {
  const theme = useTheme();
  const iconSize = `${parseFloat(theme.spacing(SIZE_MEDIA_XS)) + 12}px`;
  return (
    <Card
      sx={{
        width: 300,
        padding: 1.5,
        borderRadius: 2,
        overflow: 'visible',
        background: 'linear-gradient(114deg, #FF01E6 1.48%, #9A08C1 8.49%, #360F9C 27.19%, #360F9C 65.6%)',
      }}
    >
      <TargetHandle nodeId={id} nodeType="FLOW_OBJECT_TYPE_AI_ASSISTANT" actionType="on-execute" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: SIZE_SPACING_INTER_COMPONENT,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AIAssistantIcon width={theme.spacing(SIZE_MEDIA_S)} height={theme.spacing(SIZE_MEDIA_S)} />
          <Label
            variant="h6"
            sx={{
              color: theme.palette.common.white,
            }}
          >
            Sofia AI
          </Label>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexAlign: 'center',
            padding: 1.5,
            borderRadius: 1,
            background: theme.palette.common.white,
            alignItems: 'center',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              border: 'solid 1.5px #000',
              width: iconSize,
              height: iconSize,
            }}
          >
            <QuestionAnswerAIIcon width={iconSize} height={iconSize} />
          </Box>
          <Label variant="body1" sx={{ fontWeight: 'bold' }}>
            AI takes over conversation
          </Label>
        </Box>
      </Box>
    </Card>
  );
};
