import { TemplateComponent } from '@hooks';
import { set } from 'lodash';
import { Node } from 'react-flow-renderer';

export const cleanUpCarouselRaceConditions = (nodes: Array<Node>): Array<Node> => {
  const prepared = nodes.map((node) => {
    if (node?.data?.v1?.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE') {
      return node;
    }
    if (Array.isArray(node.data.v1?.messageTemplateInput?.templateComponents)) {
      return set(
        node,
        'data.v1.messageTemplateInput.templateComponents',
        node.data.v1?.messageTemplateInput?.templateComponents.filter((comp: TemplateComponent) => !comp.carousel),
      );
    }
    return node;
  });
  return prepared;
};
