import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { NodeType } from '@sdks/flow/createNode';
import { produce } from 'immer';
import { nodesUIStateAtom, selectedNodeUIStateAtom } from '@atoms/flow';
import { NodeUIState } from './types';
import { NodeData } from '../useNodePersistence/types';
import { mapNodeDataToUIState, mapNodeTypeToDefaultUIState } from './mappers';

// This hook is to access and set node ui states with type safety
//
// Example usage:
// const { nodeUIState, produceNodeUIState, setNodeUIStateKey }
// = useNodeUIState<MyNodeUIState>({ nodeType:'MY_NODE_TYPE', nodeData, nodeId });
//
// If nodeId is provided, it use only specified node and not selected node. This is used for node UI in canvas
// If nodeData is provided, it will convert nodeData to UI state and use it. This is used for read only flow builder
// If none of the above is provided it will use selected node's UI state. This is to be used with node editor
//
export const useNodeUIState = <NodeUIStateType extends NodeUIState>({
  nodeType,
  nodeData,
  nodeId,
}: {
  nodeType: NodeType;
  nodeData?: NodeData;
  nodeId?: string;
}) => {
  const [selectedNodeUI, setSelectedNodeUI] = useAtom(selectedNodeUIStateAtom);
  const [nodesUIState, setNodesUIState] = useAtom(nodesUIStateAtom);

  const nodeUIState: NodeUIStateType = useMemo(() => {
    let fallback: NodeUIStateType = mapNodeTypeToDefaultUIState(nodeType) as NodeUIStateType;

    if (nodeData) fallback = mapNodeDataToUIState<NodeUIStateType>(nodeData, nodeType);

    if (nodeId) return (nodesUIState[nodeId] as NodeUIStateType) || fallback;

    return (selectedNodeUI as NodeUIStateType) || fallback;
  }, [selectedNodeUI, nodeData, nodeId, nodeType, nodesUIState]);

  // setNodeUIState replaces nodeUIState (old state is deleted)
  //
  // prefer to use produceNodeUIState or setNodeUIStateKey, in order to update nodeUIState
  //
  // example:
  // setNodeUIState({ name, date });
  //
  const setNodeUIState = useCallback(
    (newNodeUI: NodeUIStateType) => {
      if (nodeId) {
        setNodesUIState((oldNodesUIState) => ({
          ...oldNodesUIState,
          [nodeId]: newNodeUI,
        }));
        return;
      }

      // if nodeId is NOT provided, this function sets selected node's ui state
      setSelectedNodeUI(newNodeUI);
    },
    [setSelectedNodeUI, setNodesUIState, nodeId],
  );

  // produceNodeUIState updates nodeUIState with immer produce (old state is preserved)
  //
  // example:
  // produceNodeUIState((draft: MyNodeUIState) => {
  //   draft.name = name;
  //   if( draft.date > DATE_THRESHOLD ) {
  //      draft.date = new Date();
  //   }
  // });
  //
  const produceNodeUIState = useCallback(
    (producer: (draft: NodeUIStateType) => NodeUIStateType | void) => {
      setNodeUIState(produce(nodeUIState, producer));
    },
    [setNodeUIState, nodeUIState],
  );

  // setNodeUIStateKey changes only one key of nodeUIState while preserving rest (still typesafe)
  //
  // example:
  // setNodeUIStateKey('name', value);
  //
  const setNodeUIStateKey = useCallback(
    <Key extends keyof NodeUIStateType>(key: Key, value: NodeUIStateType[Key]) => {
      produceNodeUIState((draft) => {
        draft[key] = value;
      });
    },
    [produceNodeUIState],
  );

  return { nodeUIState, produceNodeUIState, setNodeUIState, setNodeUIStateKey };
};
