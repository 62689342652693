import React, { FC, useCallback, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  IconButton,
  Label,
  Link,
  SettingsIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  CloseIcon,
  Slider,
  useTheme,
  Card,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { webWidgetPositionRightAtom, showWebWidgetSettingsAtom } from '@atoms/settings';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { selectBusinessId, selectShopifyChannel, useFeatureFlag, useMeData, useSettingsData, useWidget } from '@hooks';
import { useMutationInstallWidget } from '../../hooks/useMutationInstallWidget';
import { NotificationContext } from '../../../../contexts';
import { getShopifyConnectUrl } from '../../../../utils';
import { SIZE_CONTENT_L } from '../../../../ui-theme';
import { LEARN_MORE_LINK_WIDGET } from './constants';

const useWebWidget = () => {
  const [positionRight, setPositionRight] = useAtom(webWidgetPositionRightAtom);
  const [showWidgetSettings, setShowWidgetSettings] = useAtom(showWebWidgetSettingsAtom);

  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: shopifyChannel } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectShopifyChannel,
  });

  const positionRegex = useMemo(() => /positionRight:.*px/, []);

  const { script } = useWidget();
  const modifiedScript = useMemo(
    () => script.replace(positionRegex, `positionRight:"${(positionRight - 1) * 40 + 8}px`),
    [script, positionRight, positionRegex],
  );

  const positionMarks = useMemo(() => Array.from(Array(10).keys()).map((v) => ({ value: v, label: v })), []);

  const { mutate: mutateInstallWidget } = useMutationInstallWidget({
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully installed application',
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to install widget (please check permissions).',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const handleInstallWidget = useCallback(() => {
    mutateInstallWidget({
      businessId: businessId ?? '',
      businessChannelId: shopifyChannel?.id,
      script: modifiedScript,
    });
  }, [mutateInstallWidget, businessId, shopifyChannel?.id, modifiedScript]);

  const handleChangePosition = useCallback(
    (event: Event, newPositionRight: number | number[]) => {
      setPositionRight(newPositionRight as number);
    },
    [setPositionRight],
  );

  const handleCopyScript = () => {
    navigator.clipboard.writeText(modifiedScript);

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Copied to clipboard',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  };

  const permissionURL = getShopifyConnectUrl(
    businessId ?? '',
    shopifyChannel?.remoteProfile?.shopifyProfile?.shop?.myshopifyDomain ?? '',
  );

  return {
    handleChangePosition,
    handleCopyScript,
    handleInstallWidget,
    modifiedScript,
    permissionURL,
    positionMarks,
    positionRight,
    setShowWidgetSettings,
    showWidgetSettings,
  };
};

export const WebWidget: FC = () => {
  const {
    handleChangePosition,
    handleCopyScript,
    handleInstallWidget,
    modifiedScript,
    permissionURL,
    positionMarks,
    positionRight,
    setShowWidgetSettings,
    showWidgetSettings,
  } = useWebWidget();

  const theme = useTheme();
  return (
    <SaveActionCard
      title="Web Widget"
      subtitle={
        <Label variant="body1">
          Copy the global site tag into the &lt;footer&gt; section of your HTML. See&nbsp;
          <Link href={LEARN_MORE_LINK_WIDGET} target="_blank" underline="hover">
            here
          </Link>
          &nbsp;for detailed implementation directions.
        </Label>
      }
      sx={{ gap: 2 }}
    >
      <Dialog open={showWidgetSettings} onClose={() => setShowWidgetSettings(false)} maxWidth="xl">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Label variant="h6">Widget Settings</Label>
          <IconButton size="small" onClick={() => setShowWidgetSettings(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Label variant="subtitle1">Position Right</Label>
          <Slider
            aria-label="Position Right"
            value={positionRight}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={10}
            onChange={handleChangePosition}
            marks={positionMarks}
            getAriaValueText={(value: number) => `${value}`}
          />
        </DialogContent>
      </Dialog>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            borderRadius: theme.spacing(1.25),
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Label variant="body1">Site Tag</Label>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="text"
                color="primary"
                disableRipple
                onClick={handleCopyScript}
                sx={{
                  flexShrink: 0,
                  marginLeft: theme.spacing(1),
                  padding: 0,
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
              >
                COPY TEXT
              </Button>
              <IconButton onClick={() => setShowWidgetSettings(true)}>
                <SettingsIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '10px',
              width: '100%',
              boxSizing: 'border-box',
              marginTop: theme.spacing(0.5),
              padding: theme.spacing(2),
              border: `1px solid ${theme.palette.divider}`,
              background: theme.palette.common.white,
            }}
          >
            <Box
              component="p"
              sx={{
                padding: 0,
                margin: 0,
                height: theme.spacing(12),
                color: 'var(--activitybarColorDark)',
                wordBreak: 'break-word',
                fontFamily: 'monospace',
                cursor: 'text',
                overflowY: 'auto',
              }}
            >
              <Label variant="body1">{modifiedScript}</Label>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ position: 'absolute', right: theme.spacing(6), bottom: theme.spacing(6), userSelect: 'none' }}
          className="connectlyai-widget-parent"
        />
      </Card>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1 }}>
        <Button variant="contained" onClick={handleInstallWidget}>
          Install Widget
        </Button>
        <Button href={permissionURL?.href ?? ''} target="_blank">
          Update Permissions
        </Button>
      </Box>
    </SaveActionCard>
  );
};
