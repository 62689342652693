import { atom } from 'jotai';
import type { TagCreateState } from './types';

export const createDialogOpenAtom = atom(false);
export const isDeleteDialogOpenAtom = atom(false);
export const deleteDialogItemAtom = atom<string | null>(null);

export const createStateAtom = atom<TagCreateState>({
  name: '',
  colorId: '',
});

export const createNameErrorAtom = atom('');
