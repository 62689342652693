export const validatePassword = (password: string): { isValid: boolean; message: string } => {
  // Rule 1: Check if password is at least 8 characters long
  if (password.length < 8) {
    return {
      isValid: false,
      message: 'Password must be at least 8 characters long',
    };
  }

  // Rule 2: Check if password contains at least 3 of the 4 types of characters
  const checks = [
    /[a-z]/, // lower case letters
    /[A-Z]/, // upper case letters
    /[0-9]/, // numbers
    /[!@#$%^&*]/, // special characters
  ];

  let passConditions = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const check of checks) {
    if (check.test(password)) {
      passConditions += 1;
    }
  }

  if (passConditions < 3) {
    return {
      isValid: false,
      message:
        // eslint-disable-next-line max-len
        'Must contain at least 3 of the following: lower case (a-z), upper case (A-Z), number (0-9), special character (!@#$%^&*)',
    };
  }
  return { isValid: true, message: '' };
};
