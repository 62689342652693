import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { INSTALL_WIDGET_URL, InstallWidgetResponse, InstallWidgetVariables } from './types';

export const useMutationInstallWidget = (
  options?: Omit<
    UseMutationOptions<InstallWidgetResponse, ConnectlyError, InstallWidgetVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, ...others }: InstallWidgetVariables) =>
      api.profileV1(INSTALL_WIDGET_URL, 'post', {
        path: { businessId },
        body: { body: others },
      }) as Promise<InstallWidgetResponse>,
    options,
  );
