import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { NotificationContext } from '../../contexts';
import { selectBusinessId, useMeData } from '../useMeData';
import { selectWhatsappBusinessChannel, useBusinessChannelsData } from '../useBusinessChannelsData';
import { useBusinessChannelMutation } from '../useBusinessChannelMutation';
import { usePrevious } from '../usePrevious';

export const useUpdateWhatsAppProfilePicture = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppBusinessProfilePicture' });

  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const [businessProfilePicture, setBusinessProfilePicture] = useState('');

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: whatsappBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappBusinessChannel,
  });
  const previousWhatsAppBusinessChannel = usePrevious(whatsappBusinessChannel);
  const { mutate: mutateBusinessChannel } = useBusinessChannelMutation({
    onSuccess: () => {
      setBusinessProfilePicture('');
      queryClient.invalidateQueries(['businessChannels']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('updateProfilePictureSuccess'),
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('updateProfilePictureError'),
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const updateWhatsAppChannel = useCallback(() => {
    if (!whatsappBusinessChannel) {
      return;
    }

    const newWhatsappBusinessChannel = produce(whatsappBusinessChannel, (draft) => {
      if (draft?.remoteProfile?.wabaPhoneNumber)
        draft.remoteProfile.wabaPhoneNumber.profilePictureUrl = businessProfilePicture;
    });
    mutateBusinessChannel({
      businessId: businessId as string,
      channel: {
        byTypeAndExternalId: {
          externalId: whatsappBusinessChannel?.externalId,
          channelType: whatsappBusinessChannel?.channelType,
        },
      },
      ...newWhatsappBusinessChannel,
    });
  }, [businessId, businessProfilePicture, mutateBusinessChannel, whatsappBusinessChannel]);

  useEffect(() => {
    if (businessProfilePicture && whatsappBusinessChannel && !previousWhatsAppBusinessChannel) {
      updateWhatsAppChannel();
    }
  }, [
    businessId,
    businessProfilePicture,
    mutateBusinessChannel,
    previousWhatsAppBusinessChannel,
    updateWhatsAppChannel,
    whatsappBusinessChannel,
  ]);

  const updatePicture = useCallback(
    (picture: string) => {
      setBusinessProfilePicture(picture);
      if (whatsappBusinessChannel) {
        updateWhatsAppChannel();
      } else {
        queryClient.invalidateQueries(['businessChannels']);
      }
    },
    [queryClient, updateWhatsAppChannel, whatsappBusinessChannel],
  );

  return { updatePicture };
};
