import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_UPSERT_TAG_URL = '/v1/businesses/{input.businessId}/tags/{input.tagId}';

type MutationUpsertTagUrl = typeof MUTATION_UPSERT_TAG_URL;

export type MutationUpsertTagResponse = Paths['profileV1'][MutationUpsertTagUrl]['put']['responses']['200']['schema'];
type MutationUpsertTagBody = NonNullable<
  NonNullable<Paths['profileV1'][MutationUpsertTagUrl]['put']['parameters']['body']['input']['config']>['v1']
>;
export type MutationUpsertTagInput = // Paths['profileV1'][MutationUpsertTagUrl]['put']['parameters']['path']
  { id: string; businessId: string } & MutationUpsertTagBody;
export type MutationUpsertTagOptions = Omit<
  UseMutationOptions<MutationUpsertTagResponse, ConnectlyError, MutationUpsertTagInput, unknown>,
  'mutationKey' | 'mutationFn'
>;
