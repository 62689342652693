import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const UPDATE_EXECUTION_URL =
  '/internal/v3/businesses/{input.businessId}/update_execution_options/sendouts/{input.sendoutId}';
type UpdateExecutionUrl = typeof UPDATE_EXECUTION_URL;

export type UpdateExecutionPath = Paths['campaignV3'][UpdateExecutionUrl]['post']['parameters']['path'];
export type UpdateExecutionBodyInput = Paths['campaignV3'][UpdateExecutionUrl]['post']['parameters']['body']['input'];

export type UpdateExecutionParameters = { businessId: string; sendoutId: string } & UpdateExecutionBodyInput;

export type UpdateExecutionResponse = Paths['campaignV3'][UpdateExecutionUrl]['post']['responses']['200']['schema'];

export type UpdateExecutionOptions = Omit<
  UseMutationOptions<UpdateExecutionResponse, ConnectlyError, UpdateExecutionParameters, unknown>,
  'mutationKey' | 'mutupdateFn'
>;
