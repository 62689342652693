import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QUERY_SUBSCRIPTION_URL, QuerySubscriptionOptions } from './types';

export const useQuerySubscriptionURL = (businessId: string, options?: QuerySubscriptionOptions) =>
  useQuery(
    ['subscriptionURL'],
    () =>
      api.billingV1(QUERY_SUBSCRIPTION_URL, 'get', {
        path: { businessId },
      }),
    options,
  );
