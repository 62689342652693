import React, { FC } from 'react';
import {
  Box,
  Label,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  RocketIcon,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from '@connectlyai-tenets/ui-styled-web';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { FlowChartConclusionDialogProps } from './types';

export const FlowChartConclusionDialog: FC<FlowChartConclusionDialogProps> = ({
  campaignDate,
  isSubmitSuccess,
  onStartOver,
  open,
  submitProgress,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Dialog open={open} maxWidth="md">
      <Box sx={{ width: theme.spacing(SIZE_CONTENT_L) }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isLargeScreen ? 3 : 1,
            paddingTop: isLargeScreen ? 4 : 2,
            gap: isLargeScreen ? 2 : 1,
          }}
        >
          {!isSubmitSuccess && (
            <>
              <DialogTitle id="alert-dialog-title">
                <Label variant="h5">{campaignDate ? 'Scheduling campaign...' : 'Sending campaign...'}</Label>
              </DialogTitle>
              <DialogContent sx={{ width: '100%' }}>
                {submitProgress && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mx: 3 }}>
                      <LinearProgress variant="determinate" value={submitProgress.completed} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Label variant="body2" color="text.secondary">{`${submitProgress.completed}%`}</Label>
                    </Box>
                  </Box>
                )}
              </DialogContent>
            </>
          )}
          {isSubmitSuccess && (
            <>
              <RocketIcon width={isLargeScreen ? 154 : 96} height={isLargeScreen ? 154 : 96} />
              <DialogTitle id="alert-dialog-title">
                <Label variant="h5">
                  {campaignDate
                    ? 'Congrats, your campaign has been scheduled!'
                    : 'Congrats, your campaign has been sent!'}
                </Label>
              </DialogTitle>
              <DialogContent>
                <Button variant="contained" onClick={() => onStartOver()}>
                  Go to campaign overview
                </Button>
              </DialogContent>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
