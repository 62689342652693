import React, { FC, useCallback, useMemo, useRef, useState, MouseEventHandler } from 'react';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { AddVariable } from '@components/AddVariable';
import { HideableAlert } from '@components/HideableAlert';
import {
  Box,
  CodeIcon,
  FormatBoldIcon,
  FormatItalicIcon,
  FormatStrikethroughIcon,
  IconButton,
  InputAdornment,
  Label,
  TextField,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { v4 as uuidv4 } from 'uuid';
import { useAtomValue } from 'jotai';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { SelectionRange, EditorSelection } from '@codemirror/state';
import { selectedNodeAtom } from '@atoms/flow';
import { useFeatureFlag } from '@hooks';
import { useTranslation } from 'react-i18next';
import { BODY_MAX_LENGTH } from './constants';
import { isAppleDevice } from '../../utils';
import EmojiPickerButton from '../../presentation/EmojiPicker';
import { findNextVariable } from '../../presentation/preview/utils';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { CodeMirrorTextField } from '../CodeMirrorTextField';
import { FlowVariableDialog, FlowVariableDialogRef } from '../FlowVariableDialog';
import { parseVariables, populateVariablesV2, useFlowVariables } from '../../hooks/useFlowVariables';
import { FlowVariableExtended } from '../../hooks/useFlowVariables/types';

const isAutoReplyBodyVariablesEnabled = getFlagByName('ffEnableAutoReplyBodyVariables');

const useTemplateBuilderBodyCard = ({
  minimized,
  value: body,
  onInput,
  active,
}: {
  minimized?: boolean;
  value: string;
  onInput: (newValue: string) => void;
  active: number;
}) => {
  const [focused, setFocused] = useState(false);
  const shouldBeMinimized = minimized && !focused;
  const { canonicalize, variablesSet, addVariables } = useFlowVariables();

  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const shouldUseCodeMirror = isAutoReplyBodyVariablesEnabled || ffEnableETA;

  const flowVariableDialogRef = useRef<FlowVariableDialogRef>(null);
  const addVariableRef = useRef<HTMLButtonElement>(null);
  const activeVariableRef = useRef<HTMLElement | null>(null);
  const codeMirrorRef = useRef<ReactCodeMirrorRef | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickOutside = useCallback(() => {
    setFocused(false);
  }, []);

  const handleBodyInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInput(event.target.value);
    },
    [onInput],
  );

  const handleBodyEditorChange = useCallback(
    (value: string) => {
      const newVariables = parseVariables(value).reduce((acc: Array<FlowVariableExtended>, variable) => {
        if (!variablesSet.has(variable)) {
          acc.push({
            id: uuidv4(),
            type: 'FLOW_TYPE_STRING',
            namespace: '',
            namespaceCanonical: '',
            simpleName: variable,
            simpleNameCanonical: canonicalize(variable),
            validators: [],
          });
        }
        return acc;
      }, []);
      addVariables(newVariables);
      onInput(value);
    },
    [addVariables, onInput, variablesSet, canonicalize],
  );

  const selectedNode = useAtomValue(selectedNodeAtom);
  const populatedBody = useMemo(() => {
    if (selectedNode?.data?.v1?.parameterMapping?.mappings) {
      return populateVariablesV2(body, `card.${active}.body.`, selectedNode?.data?.v1?.parameterMapping?.mappings);
    }
    return body;
  }, [body, selectedNode?.data?.v1?.parameterMapping?.mappings, active]);

  const handleAddVariable = useCallback(() => {
    if (isAutoReplyBodyVariablesEnabled || ffEnableETA) {
      flowVariableDialogRef.current?.handleOpenDialog();
      return;
    }

    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    element.focus();

    const unselectedTextFrom = element.selectionStart === null ? undefined : element.selectionStart;
    const unselectedTextTo = element.selectionEnd === null ? element.value.length : element.selectionEnd;
    const unselectedText = element.value.substring(0, unselectedTextFrom) + element.value.substring(unselectedTextTo);

    const nextVariable = findNextVariable(unselectedText);

    document.execCommand('insertText', false, `{{${nextVariable}}}`);
  }, []);

  const handleSelectEmojiV3 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cmRef: React.MutableRefObject<ReactCodeMirrorRef | null>, { native }: any) => {
      if (!cmRef || !cmRef.current) return;
      const { view } = cmRef.current;
      if (!view) return;
      const transaction = view.state.changeByRange((range: SelectionRange) => {
        const insert = native;
        const changes = {
          from: range.from,
          to: range.to,
          insert,
        };

        // If no text is selected, insert the emoji and set the cursor position after it
        if (range.from === range.to) {
          return {
            changes,
            range: EditorSelection.cursor(range.from + insert.length),
          };
        }
        // Calculate the new selection range
        const newSelectionFrom = range.from;
        const newSelectionTo = range.from + insert.length;

        return {
          changes,
          range: EditorSelection.range(newSelectionFrom, newSelectionTo),
        };
      });
      view.dispatch(transaction);
      view.focus();
    },
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectEmoji = useCallback(({ native }: any) => {
    if (shouldUseCodeMirror) {
      handleSelectEmojiV3(codeMirrorRef, { native });
      return;
    }
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    if (document.activeElement !== element) {
      element.focus();
    }

    document.execCommand('insertText', false, native);
  }, []);

  const handleFormatting = useCallback((format: string) => {
    const element = document.getElementById('message-template-body') as HTMLInputElement;
    if (!element) {
      return;
    }

    element.focus();

    if (element.selectionStart === null || element.selectionEnd === null) {
      return;
    }

    const selectedText = element.value.substring(element.selectionStart, element.selectionEnd);

    const { selectionStart: prevSelectionStart, selectionEnd: prevSelectionEnd } = element;
    document.execCommand('insertText', false, `${format}${selectedText}${format}`);
    if (!selectedText) {
      // move cursor between marks
      element.selectionStart -= format.length;
      element.selectionEnd -= format.length;
    } else {
      /// keep text selected
      element.selectionStart = prevSelectionStart + format.length;
      element.selectionEnd = prevSelectionEnd + format.length;
    }
  }, []);

  const handleFormattingV3 = useCallback((cmRef: React.MutableRefObject<ReactCodeMirrorRef | null>, format: string) => {
    if (!cmRef || !cmRef.current) return;
    const { view } = cmRef.current;
    if (!view) return;
    const transaction = view.state.changeByRange((range: SelectionRange) => {
      // If no text is selected, insert the formatting characters and set the cursor position between them
      if (range.from === range.to) {
        const changes = {
          from: range.from,
          to: range.to,
          insert: `${format}${format}`,
        };

        // Set the cursor position between the formatting characters
        const cursorPosition = range.from + format.length;

        return {
          changes,
          range: EditorSelection.cursor(cursorPosition),
        };
      }
      const selectedText = view.state.sliceDoc(range.from, range.to);
      let insert = '';
      if (
        selectedText &&
        selectedText.length >= 2 * format.length &&
        selectedText.substring(0, format.length) === format &&
        selectedText.substring(selectedText.length - format.length) === format
      ) {
        // remove format
        insert = `${selectedText.substring(format.length, selectedText.length - format.length)}`;
      } else {
        // add format
        insert = `${format}${selectedText}${format}`;
      }
      const changes = {
        from: range.from,
        to: range.to,
        insert,
      };
      // Calculate the new selection range
      const newSelectionFrom = range.from;
      const newSelectionTo = range.from + insert.length;

      return {
        changes,
        range: EditorSelection.range(newSelectionFrom, newSelectionTo),
      };
    });
    view.dispatch(transaction);
    view.focus();
  }, []);

  const handleBold = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '*');
      return;
    }
    handleFormatting('*');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleItalic = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '_');
      return;
    }
    handleFormatting('_');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleStrikethrough = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '~');
      return;
    }
    handleFormatting('~');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleMonospace = useCallback(() => {
    if (shouldUseCodeMirror) {
      handleFormattingV3(codeMirrorRef, '```');
      return;
    }
    handleFormatting('```');
  }, [shouldUseCodeMirror, handleFormatting, handleFormattingV3, codeMirrorRef]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let handled = false;
      switch (e.code) {
        case 'KeyI': {
          if (shouldUseCodeMirror) {
            // TODO italic shortcut for CodeMirror is jumping to beginning of editor
            break;
          }
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleItalic();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleItalic();
            handled = true;
          }
          break;
        }
        case 'KeyB': {
          if (isAppleDevice() && !e.altKey && !e.ctrlKey && !e.shiftKey && e.metaKey) {
            handleBold();
            handled = true;
          } else if (!isAppleDevice() && !e.altKey && e.ctrlKey && !e.shiftKey && !e.metaKey) {
            handleBold();
            handled = true;
          }
          break;
        }
        default: {
          break;
        }
      }

      if (handled) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [shouldUseCodeMirror, handleBold, handleItalic],
  );

  return {
    shouldUseCodeMirror,
    activeVariableRef,
    addVariableRef,
    body: populatedBody,
    codeMirrorRef,
    handleBodyEditorChange,
    handleBodyInputChange,
    onAddVariable: handleAddVariable,
    onBold: handleBold,
    onItalic: handleItalic,
    onKeyDown: handleKeyDown,
    onMonospace: handleMonospace,
    onSelectEmoji: handleSelectEmoji,
    onStrikethrough: handleStrikethrough,
    onClick: handleClick,
    onClickOutside: handleClickOutside,
    flowVariableDialogRef,
    focused,
    shouldBeMinimized,
  };
};

export const TemplateBuilderBodyCard: FC<{
  minimized?: boolean;
  value: string;
  onInput: (newValue: string) => void;
  active: number;
}> = ({ minimized, value, onInput, active }) => {
  const {
    shouldUseCodeMirror,
    activeVariableRef,
    addVariableRef,
    body,
    codeMirrorRef,
    handleBodyEditorChange,
    handleBodyInputChange,
    onAddVariable,
    onBold,
    onItalic,
    onKeyDown,
    onMonospace,
    onSelectEmoji,
    onStrikethrough,
    flowVariableDialogRef,
    onClick,
    onClickOutside,
    shouldBeMinimized,
  } = useTemplateBuilderBodyCard({ minimized, value, onInput, active });
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'carouselBuilder' });

  return (
    <TemplateBuilderItem
      onClick={onClick}
      onClickOutside={onClickOutside}
      sx={{
        borderRadius: 0,
        px: 0,
        p: 0,
        border: 0,
      }}
    >
      <Label variant="body1" sx={{ color: theme.palette.text.secondary, wordBreak: 'break-word', mb: 1 }}>
        {t('messageTitle')}
      </Label>
      {shouldUseCodeMirror && (
        <>
          <CodeMirrorTextField
            value={body}
            codeMirrorUpdate={flowVariableDialogRef?.current?.handleCodeMirrorUpdate}
            editable
            multiline
            handleVariableClick={flowVariableDialogRef?.current?.handleVariableClick}
            setValue={handleBodyEditorChange}
            ref={codeMirrorRef}
            onKeyDown={onKeyDown}
            minimized={shouldBeMinimized}
            containerStyle={{
              borderRadius: 1,
            }}
            endAdornment={
              shouldBeMinimized ? null : (
                <InputAdornment
                  sx={{
                    color: body.length > BODY_MAX_LENGTH ? 'error.main' : 'text.secondary',
                    userSelect: 'none',
                    height: '1.5rem',
                    position: 'relative',
                    borderRadius: 0,
                  }}
                  disableTypography
                  disablePointerEvents
                  position="end"
                >
                  <Label variant="body2" sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                    {`${body.length}/${BODY_MAX_LENGTH}`}
                  </Label>
                </InputAdornment>
              )
            }
            placeholder="Enter text"
          />
          <FlowVariableDialog
            anchorEl={activeVariableRef?.current || addVariableRef?.current}
            codeMirrorRef={codeMirrorRef}
            value={body}
            handleEditorChange={handleBodyEditorChange}
            ref={flowVariableDialogRef}
          />
        </>
      )}
      {!shouldUseCodeMirror && (
        <TextField
          id="message-template-body"
          autoComplete="off"
          autoFocus
          placeholder="Enter text"
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          value={body}
          onChange={handleBodyInputChange}
          variant="outlined"
          inputProps={{
            maxLength: BODY_MAX_LENGTH * 2,
          }}
          InputProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              padding: 1.5,
              borderRadius: 0,
            },
            endAdornment: (
              <InputAdornment
                sx={{
                  height: 'auto',
                  alignSelf: 'flex-end',
                  mt: 1.5,
                  color: body.length > BODY_MAX_LENGTH ? 'error.main' : 'text.secondary',
                  userSelect: 'none',
                }}
                disableTypography
                disablePointerEvents
                position="end"
              >
                <Label variant="body2">{`${body.length}/${BODY_MAX_LENGTH}`}</Label>
              </InputAdornment>
            ),
          }}
          onKeyDown={onKeyDown}
          sx={{ mt: 2 }}
        />
      )}
      {!shouldBeMinimized && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <AddVariable onClick={onAddVariable} ref={addVariableRef} />
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <Tooltip title={`Bold (${isAppleDevice() ? '⌘' : 'Ctrl'} + b)`}>
              <IconButton sx={{ p: 0.5 }} onClick={onBold}>
                <FormatBoldIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Italic${shouldUseCodeMirror ? '' : `(${isAppleDevice() ? '⌘' : 'Ctrl'} + i)`}`}>
              <IconButton sx={{ p: 0.5 }} onClick={onItalic}>
                <FormatItalicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Strikethrough">
              <IconButton sx={{ p: 0.5 }} onClick={onStrikethrough}>
                <FormatStrikethroughIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mono">
              <IconButton sx={{ p: 0.5 }} onClick={onMonospace}>
                <CodeIcon />
              </IconButton>
            </Tooltip>
            <EmojiPickerButton
              anchorOrigin={{
                vertical: -parseInt(theme.spacing(1), 10),
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              disabled={false}
              tooltip="Insert emoji"
              onSelect={onSelectEmoji}
            />
          </Box>
        </Box>
      )}
      {!shouldUseCodeMirror && (
        <HideableAlert
          title="What are variables?"
          // eslint-disable-next-line max-len
          storageKey="tooltip-template-body-variables"
          sx={{ mt: 3 }}
        >
          Variables are placeholders for dynamic content that help personalize your campaign, for example: customer
          names or coupon codes.
        </HideableAlert>
      )}
    </TemplateBuilderItem>
  );
};
