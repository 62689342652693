import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QUERY_SENDOUT_ANALYTICS_URL, QuerySendoutAnalyticsParams, QuerySendoutAnalyticsOptions } from './types';

export const useQuerySendoutAnalytics = (params: QuerySendoutAnalyticsParams, options?: QuerySendoutAnalyticsOptions) =>
  useQuery(
    [
      'sendout_analytics',
      {
        businessId: params.businessId || '',
        timeRangeStart: params.timeRangeStart || '',
        timeRangeEnd: params.timeRangeEnd || '',
      },
    ],
    () =>
      api.analyticsV1(QUERY_SENDOUT_ANALYTICS_URL, 'post', {
        path: { 'query.businessId': params.businessId },
        body: { query: { ...params } },
      }),
    options,
  );
