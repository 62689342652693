export enum DialogActionResult {
  DISMISS,
  PRIMARY,
  SECONDARY,
}

export type QuickReplyTemplateFields = {
  title: string;
  body: string;
};

type QuickReplyTemplateFieldKey = keyof QuickReplyTemplateFields;

export interface QuickReplyEditState {
  itemId: string;
  propertyKey: QuickReplyTemplateFieldKey;
  input: string;
}

export type QuickReplyTemplate = QuickReplyTemplateFields & {
  id: string;
};

export type QuickReplyTemplateView = QuickReplyTemplate & {
  isLoading: boolean;
};

export interface QuickReplyTemplateState {
  item: QuickReplyTemplate;
  state: 'processing' | 'idle';
}

export interface QuickReplyState {
  items: {
    ids: string[];
    byId: {
      [key: string]: QuickReplyTemplateState;
    };
  };
}

export type QuickReplyDleteDialogProps = {
  isOpen: boolean;
  itemId: string | undefined;
};
export type QuickReplyTemplateDialogProps = {
  deleteConfirmDialog: QuickReplyDleteDialogProps;
  onDeleteConfirmDialogClose: (result: DialogActionResult) => void;
};
export type QuickReplyTemplateProps = QuickReplyTemplateView & {
  hoverKey: string;
  editState: QuickReplyEditState | undefined;
  isDeletingQuickReplyItem: boolean;
  handleMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseLeave: (_e: React.MouseEvent<HTMLDivElement>) => void;
  handleDeleteButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleEditPropertyFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEditPropertyBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEditPropertyChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClickEmoji: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseEmoji: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectEmoji: ({ native }: any) => void;
};
