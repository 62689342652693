import React, { useMemo } from 'react';
import { Loader } from '@connectlyai-sdks/components';
import {
  documentTypeAtom,
  documentIdAtom,
  isCampaignNameDialogOpenAtom,
  campaignStepAtom,
  starterAtom,
  duplicateDocumentAtom,
  campaignTemplateAtom,
  isAISidebarOpenAtom,
  getStartedCategoryAtom,
  campaignNameAtom,
  sendoutIdAtom,
} from '@atoms/flow';
import { adminBusinessIdAtom } from '@atoms/app';
import { selectedPhoneListAudienceAtom, selectedSmartAudienceAtom } from '@atoms/audience';
import { Provider as StateProvider, useAtomValue } from 'jotai';
import { useSelector } from 'react-redux';
import { FlowChart } from '@scenes/FlowChart/FlowChart';
import { FusedStateProvider } from '@components/FusedStateProvider';
import { FlowChartAutoReplyV1 } from '@scenes/FlowChart/autoreplyv1/FlowChartAutoReplyV1';
import { useMeData, useFlowNavigator, useFlowDocumentData, selectBusinessId, useLogger } from '@hooks';
import { GetStarted } from '../GetStarted';
import { selectDocumentId as selectDocumentIdAutoReply } from '../../../../features/flow-autoreply/state';
import { LoggerContext } from '../../../../contexts';
import { FlowChartCampaignV3 } from '../../FlowChartCampaignV3';
import { CampaignPresets } from '../CampaignPresets';

export const useFlowWrapper = () => {
  const { flowDocumentIdRouteMatch } = useFlowNavigator();
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const campaignStep = useAtomValue(campaignStepAtom);
  const category = useAtomValue(getStartedCategoryAtom);
  const campaignName = useAtomValue(campaignNameAtom);
  const documentId = useAtomValue(documentIdAtom);
  const documentType = useAtomValue(documentTypeAtom);
  const sendoutId = useAtomValue(sendoutIdAtom);

  const routeDocumentId = useMemo(
    () => flowDocumentIdRouteMatch?.params?.flowDocumentId || '',
    [flowDocumentIdRouteMatch],
  );

  const { sugar } = useLogger();

  const { loggerDeps: campaignLoggerDeps } = useMemo(
    () =>
      sugar({
        logger: 'FlowChart',
        campaignName,
        campaignStep,
        documentId,
        sendoutId,
      }),
    [campaignName, campaignStep, documentId, sendoutId, sugar],
  );

  const documentIdAutoReply = useSelector(selectDocumentIdAutoReply);
  const { loggerDeps: autoReplyLoggerDeps } = useMemo(
    () =>
      sugar({
        logger: 'FlowChartAutoReplyV1',
        flow_id: documentIdAutoReply,
      }),
    [documentIdAutoReply, sugar],
  );

  const { data: document, isLoading: isLoadingDocument } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: routeDocumentId,
    enabled: !!businessId && !!routeDocumentId,
  });

  const componentType = useMemo(() => {
    if (!!routeDocumentId && isLoadingDocument) return 'LOADER';
    const prioritizedDocumentType = (!!routeDocumentId && document?.entity?.type) || documentType;
    if (
      !!prioritizedDocumentType &&
      (prioritizedDocumentType === 'FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1' || campaignStep !== 'preface')
    )
      return prioritizedDocumentType;
    return 'GET_STARTED';
  }, [documentType, routeDocumentId, isLoadingDocument, document, campaignStep]);

  return {
    autoReplyLoggerDeps,
    campaignLoggerDeps,
    category,
    componentType,
    documentId,
    flowDocumentIdRouteMatch,
  };
};

export const FlowWrapperStateless = () => {
  const { autoReplyLoggerDeps, campaignLoggerDeps, category, componentType, documentId, flowDocumentIdRouteMatch } =
    useFlowWrapper();

  const component = useMemo(() => {
    // Branch out to specific component implementations per document type
    switch (componentType) {
      case 'FLOW_DOCUMENT_TYPE_SENDOUT_V3':
        return (
          <FusedStateProvider
            atoms={[
              adminBusinessIdAtom,
              campaignNameAtom,
              campaignStepAtom,
              campaignTemplateAtom,
              getStartedCategoryAtom,
              documentIdAtom,
              documentTypeAtom,
              duplicateDocumentAtom,
              isAISidebarOpenAtom,
              isCampaignNameDialogOpenAtom,
              starterAtom,
            ]}
          >
            <LoggerContext.Provider value={campaignLoggerDeps}>
              <FlowChartCampaignV3 />
            </LoggerContext.Provider>
          </FusedStateProvider>
        );
      case 'FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1':
        return (
          <LoggerContext.Provider value={campaignLoggerDeps}>
            <FlowChart />
          </LoggerContext.Provider>
        );
      case 'FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1':
        return (
          <LoggerContext.Provider value={autoReplyLoggerDeps}>
            <FlowChartAutoReplyV1 documentId={flowDocumentIdRouteMatch?.params.flowDocumentId || documentId} />
          </LoggerContext.Provider>
        );
      case 'LOADER':
        return <Loader />;
      default:
        if (category === 'campaign-presets') {
          return <CampaignPresets />;
        }
        return <GetStarted />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, componentType]);
  return component;
};

export const FlowWrapper = () => (
  <StateProvider>
    <FlowWrapperStateless />
  </StateProvider>
);
