import {
  SidebarAnalyticsIcon,
  SidebarAudiencesIcon,
  SidebarAutomationsIcon,
  SidebarCampaignsIcon,
  SidebarHomeIcon,
  SidebarInboxIcon,
  SidebarSettingsIcon,
  SidebarSofiaIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { AppSidebarItemList } from './types';

export const APP_SIDEBAR_ITEMS: AppSidebarItemList = [
  {
    title: 'Home',
    to: '/home',
    icon: SidebarHomeIcon,
    placement: 'middle',
    restricted: true,
    narrow: false,
  },
  {
    title: 'Inbox',
    to: '/inbox',
    icon: SidebarInboxIcon,
    placement: 'middle',
    restricted: false,
    narrow: true,
  },
  {
    title: 'Campaigns',
    to: '/campaigns',
    icon: SidebarCampaignsIcon,
    placement: 'middle',
    restricted: true,
    narrow: false,
  },
  {
    title: 'Analytics',
    to: '/analytics/campaign',
    icon: SidebarAnalyticsIcon,
    placement: 'middle',
    restricted: true,
    narrow: false,
  },
  {
    title: 'Automations',
    to: '/automations',
    icon: SidebarAutomationsIcon,
    placement: 'middle',
    restricted: false,
    narrow: false,
  },
  {
    title: 'Audiences',
    to: '/audience/manage',
    icon: SidebarAudiencesIcon,
    placement: 'middle',
    restricted: true,
    narrow: false,
  },
  {
    title: 'Sofia AI',
    to: '/sofia',
    icon: SidebarSofiaIcon,
    placement: 'middle',
    restricted: true,
    narrow: false,
  },

  {
    title: 'Settings',
    to: '/settings/general',
    icon: SidebarSettingsIcon,
    placement: 'bottom',
    restricted: false,
    narrow: false,
  },
];
