import { Node, Edge } from 'react-flow-renderer';
import { NodeType } from '@sdks/flow/createNode';
import { TemplateComponents, selectMessageTemplateIndex } from '@hooks/useMessageTemplateGroupsData';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useDetectVariables } from '../useDetectVariables';
import { getMediaHeaderType } from '../../../../utils';

export const useMigrateToV3 = ({
  nodes,
  edges,
  templates,
}: {
  nodes: Node[];
  edges: Edge[];
  templates: ReturnType<typeof selectMessageTemplateIndex>;
}) => {
  const { detectVariables, getVariableDefinitions, prepareDetectVariables } = useDetectVariables();
  const nodesV3 = useMemo(() => {
    prepareDetectVariables();

    const newNodes = cloneDeep(nodes);

    newNodes
      .filter((node) => node.type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE')
      .forEach((node) => {
        const oldV1 = cloneDeep(node.data.v1 || {});

        node.type = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE' as NodeType;
        const waMessageTemplateType = oldV1.waMessageTemplateType || 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE';

        node.data.v1 = { messageTemplateInput: { waMessageTemplateType } };

        if (oldV1.name) {
          node.data.v1.name = oldV1.name;
          node.data.v1.messageTemplateInput.name = oldV1.name;
        }

        if (oldV1.waMessageTemplateId) {
          node.data.v1.templateId = oldV1.waMessageTemplateId;
        }

        if (oldV1.prompt) node.data.v1.prompt = oldV1.prompt;

        const templateComponents: TemplateComponents = [];
        if (oldV1.body) templateComponents.push({ body: { text: { text: oldV1.body } } });
        if (oldV1.footer) templateComponents.push({ footer: { text: { text: oldV1.footer } } });
        if (oldV1.textHeader) templateComponents.push({ header: { text: { text: oldV1.textHeader } } });
        if (oldV1.buttons) templateComponents.push(...oldV1.buttons);

        if (oldV1.mediaHeader && oldV1.headerType) {
          templateComponents.push({
            header: {
              media: {
                type: getMediaHeaderType(oldV1.headerType),
                example: oldV1.mediaHeader,
              },
            },
          });
        }
        node.data.v1.messageTemplateInput.templateComponents = templateComponents;
      });

    newNodes
      .filter((node) => node.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE')
      .forEach((node) => {
        let templateComponents: TemplateComponents = node.data.v1?.messageTemplateInput?.templateComponents;

        const templateId = node.data.v1?.waMessageTemplateId || node.data.v1?.templateId || '';
        if ((!templateComponents || templateComponents.length === 0) && templateId) {
          templateComponents = templates?.[templateId]?.template?.templateComponents || [];

          if (!node.data.v1) node.data.v1 = {};
          if (!node.data.v1.messageTemplateInput) node.data.v1.messageTemplateInput = {};
          node.data.v1.messageTemplateInput.templateComponents = templateComponents;
        }

        if (!templateComponents || templateComponents.length === 0) return;

        const { mappings } = detectVariables(templateComponents);

        node.data.v1.parameterMapping = {
          mappings,
        };
      });

    return newNodes.map((node) => {
      if (node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN') return node;
      return {
        ...node,
        type: 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT',
        data: { v3: { enterVariables: getVariableDefinitions() } },
      };
    });
  }, [detectVariables, getVariableDefinitions, nodes, prepareDetectVariables, templates]);

  return { nodes: nodesV3, edges };
};
