import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const FLOW_EVENT_REQUEST_DEPLOY_URL =
  '/internal/v1/businesses/{input.businessId}/request_deploy_react_flow_document/flow_documents';

type FlowEventRequestDeployURL = typeof FLOW_EVENT_REQUEST_DEPLOY_URL;

export type FlowEventRequestDeployResponse = Paths['flowV1'][FlowEventRequestDeployURL]['post']['responses']['202'];
export type FlowEventRequestDeployInput = {
  businessId: string;
} & Paths['flowV1'][FlowEventRequestDeployURL]['post']['parameters']['body']['input'];
export type FlowEventRequestDeployOptions = Omit<
  UseMutationOptions<FlowEventRequestDeployResponse, unknown, FlowEventRequestDeployInput, unknown>,
  'mutationKey' | 'mutationFn'
>;
