import { generatePath } from 'react-router';
import { BackRouteId } from './navigation';
export default class HistoryNavigator {
    constructor(history, routerId = '') {
        this.history = history;
        this.routerId = routerId;
    }
    generateGlobalRouteId(to) {
        const globalToId = to.startsWith(this.routerId) ? to : `${this.routerId}${to}`;
        return globalToId;
    }
    generateGlobalPath(to) {
        const localPath = generatePath(to.id, to.params);
        const globalPath = localPath.startsWith(this.routerId) ? localPath : `${this.routerId}${localPath}`;
        return globalPath;
    }
    navigate(to) {
        const localId = to.id.startsWith(this.routerId) ? to.id.slice(this.routerId.length) : to.id;
        if (localId === BackRouteId) {
            this.history.goBack();
            return;
        }
        const finalRoute = this.generateGlobalPath(to);
        this.history.push(finalRoute);
    }
}
