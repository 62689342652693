import { Observable } from 'rxjs';
import { filter, share, shareReplay, switchMap } from 'rxjs/operators';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { CONNECTLY_BUSINESS_ID } from './constants';
import { DtoUpdate, RoomDto } from './entities';
export default class RealDtoUpdateAccessor {
    constructor(deps) {
        this.deps = deps;
        const { businessId, accessToken, isAdminUser } = deps.config;
        const inGodMode = isAdminUser && businessId !== CONNECTLY_BUSINESS_ID;
        const socketObservable = new Observable((subscriber) => {
            const log = deps.logProvider();
            if (!accessToken) {
                return () => { };
            }
            const socket = deps.realtimeClientProvider()(accessToken);
            socket.on('connect', () => {
                log.debug('dtoUpdates@connect', () => ({}));
                if (inGodMode) {
                    socket.emit('join room', businessId);
                }
            });
            if (inGodMode) {
                socket.on('left room', (id) => {
                    log.debug('dtoUpdates@leftroom', () => ({ businessId: id }));
                    RealDtoUpdateAccessor.joinedRooms = RealDtoUpdateAccessor.joinedRooms.filter((room) => room !== id);
                });
                RealDtoUpdateAccessor.joinedRooms.forEach((id) => {
                    log.debug('dtoUpdates@leaveroom', () => ({ businessId: id }));
                    socket.emit('leave room', id);
                });
                socket.on('joined room', (id) => {
                    RealDtoUpdateAccessor.joinedRooms = [
                        ...RealDtoUpdateAccessor.joinedRooms.filter((room) => room !== id),
                        id,
                    ];
                    log.debug('dtoUpdates@joinedroom', () => ({ businessId: id }));
                });
            }
            socket.on('disconnect', (reason) => {
                log.debug('dtoUpdates@disconnect', () => ({
                    reason,
                }));
                deps.onDisconnect(reason);
                switch (reason) {
                    case 'io server disconnect':
                        socket.connect();
                        break;
                    case 'io client disconnect':
                        break;
                    case 'ping timeout':
                    case 'transport close':
                    case 'transport error': {
                        break;
                    }
                    default:
                        assertUnreachable(reason);
                }
            });
            subscriber.next(socket);
            return () => {
                socket.off('connect');
                socket.close();
            };
        }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
        this.roomDto = socketObservable.pipe(switchMap((socket) => {
            const log = deps.logProvider();
            return new Observable((subscriber) => {
                const listener = (payload) => {
                    log.debug('dtoUpdates@roomDto', () => ({
                        payload,
                    }));
                    subscriber.next(RoomDto.fromJSON(payload));
                };
                socket.on('roomDto', listener);
                return () => {
                    socket.off('roomDto', listener);
                };
            });
        }), share());
        this.dtoUpdate = socketObservable.pipe(switchMap((socket) => {
            const log = deps.logProvider();
            return new Observable((subscriber) => {
                const listener = (payload) => {
                    log.debug('dtoUpdates@dtoUpdateV1', () => ({
                        payload,
                    }));
                    subscriber.next(DtoUpdate.fromJSON(payload));
                };
                socket.on('dtoUpdateV1', listener);
                return () => {
                    socket.off('dtoUpdateV1', listener);
                };
            });
        }), share());
        this.serializedDto = socketObservable.pipe(switchMap((socket) => {
            const log = deps.logProvider();
            return new Observable((subscriber) => {
                const listener = (payload) => {
                    log.debug('dtoUpdates@serializedDtoV1', () => ({
                        payload,
                    }));
                    subscriber.next(JSON.stringify(payload));
                };
                socket.on('dtoUpdateV1', listener);
                return () => {
                    socket.off('dtoUpdateV1', listener);
                };
            });
        }), share());
    }
    subscriptionDtoUpdate() {
        return this.dtoUpdate.pipe(filter((dtoUpdate) => dtoUpdate.clientId === undefined ||
            dtoUpdate.clientId === '' ||
            dtoUpdate.clientId === this.deps.config.clientId));
    }
    subscriptionSerializedDto() {
        return this.serializedDto.pipe(filter((serialized) => {
            const dtoUpdate = JSON.parse(serialized);
            return (dtoUpdate.clientId === undefined ||
                dtoUpdate.clientId === '' ||
                dtoUpdate.clientId === this.deps.config.clientId);
        }));
    }
    subscriptionRoomDto() {
        return this.roomDto;
    }
}
RealDtoUpdateAccessor.joinedRooms = [];
