import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHasPendingTemplates,
  selectHasRejectedTemplates,
  selectHasUnsentTemplates,
  selectTemplateApprovalDialogOpen,
  setTemplateApprovalDialogOpen,
} from '../../state/messageTemplates';
import { selectFlowState } from '../../state/flow';

export const useTemplateApprovalDialog = () => {
  const flowState = useSelector(selectFlowState);
  const hasRejectedTemplates = useSelector(selectHasRejectedTemplates);
  const hasPendingTemplates = useSelector(selectHasPendingTemplates);
  const hasUnsentTemplates = useSelector(selectHasUnsentTemplates);
  const templateApprovalDialogOpen = useSelector(selectTemplateApprovalDialogOpen);
  const dispatch = useDispatch();
  useEffect(() => {
    if (flowState === 'approveTemplates' && !templateApprovalDialogOpen) {
      dispatch(setTemplateApprovalDialogOpen(true));
    } else if (flowState !== 'approveTemplates' && templateApprovalDialogOpen) {
      dispatch(setTemplateApprovalDialogOpen(false));
    }
  }, [dispatch, flowState, templateApprovalDialogOpen]);

  const isNeedingTemplateApproval = useMemo(
    () => hasRejectedTemplates || hasPendingTemplates || hasUnsentTemplates,
    [hasRejectedTemplates, hasPendingTemplates, hasUnsentTemplates],
  );

  return {
    hasRejectedTemplates,
    hasPendingTemplates,
    hasUnsentTemplates,
    isNeedingTemplateApproval,
  };
};
