import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const QUERY_SENDOUT_URL = '/internal/v2/businesses/{businessId}/query/sendouts';
type QuerySendoutURL = typeof QUERY_SENDOUT_URL;

export type QuerySendoutResponse = Paths['campaignV2'][QuerySendoutURL]['post']['responses']['200']['schema'];
export type QuerySendoutInput = Paths['campaignV2'][QuerySendoutURL]['post']['parameters']['body']['body'];
export type QuerySendoutParams = QuerySendoutInput & Paths['campaignV2'][QuerySendoutURL]['post']['parameters']['path'];

export type QuerySendoutOptions = Omit<
  UseQueryOptions<QuerySendoutResponse, ConnectlyError, QuerySendoutResponse, readonly ['sendout', QuerySendoutParams]>,
  'initialData' | 'queryFn' | 'queryKey'
>;
