export { ChannelIdentifier, ChannelType } from '@connectlyai-sdks/data-customer';
export { SearchCustomerQuery, SearchCustomerResult } from '@connectlyai-idl/models/dist/models/profile/search';
export const BLANK_DASH = '-';
export const UpdateShopifyProfileByOptions = {
    BY_SHOPIFY_ID: {
        key: 'BY_SHOPIFY_ID',
        inputLabel: 'Shopify ID',
        optionLabel: 'Update profile with Shopify ID',
        backendQueryFields: ['id'],
    },
    BY_SHOPIFY_LAST_ORDER: {
        key: 'BY_SHOPIFY_LAST_ORDER',
        inputLabel: 'Shopify last order # or ID',
        optionLabel: 'Update profile with last order # or ID',
        backendQueryFields: ['lastOrderName', 'lastOrderId'],
    },
    BY_SHOPIFY_EMAIL: {
        key: 'BY_SHOPIFY_EMAIL',
        inputLabel: 'Shopify Email',
        optionLabel: 'Update profile with email address',
        backendQueryFields: ['email'],
    },
};
