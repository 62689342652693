import { errorTypeFromJSON, errorTypeToJSON } from "./type";
import { errorCodeFromJSON, errorCodeToJSON } from "./code";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.error";
function createBaseError() {
    return {
        message: "",
        type: 0,
        code: 0,
        userTitle: "",
        userMessage: "",
        cntTraceId: "",
        details: {},
    };
}
export const Error = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.message !== "") {
            writer.uint32(10).string(message.message);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.code !== 0) {
            writer.uint32(24).int32(message.code);
        }
        if (message.userTitle !== "") {
            writer.uint32(34).string(message.userTitle);
        }
        if (message.userMessage !== "") {
            writer.uint32(42).string(message.userMessage);
        }
        if (message.cntTraceId !== "") {
            writer.uint32(50).string(message.cntTraceId);
        }
        Object.entries(message.details).forEach(([key, value]) => {
            Error_DetailsEntry.encode({ key: key, value }, writer.uint32(58).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.code = reader.int32();
                    break;
                case 4:
                    message.userTitle = reader.string();
                    break;
                case 5:
                    message.userMessage = reader.string();
                    break;
                case 6:
                    message.cntTraceId = reader.string();
                    break;
                case 7:
                    const entry7 = Error_DetailsEntry.decode(reader, reader.uint32());
                    if (entry7.value !== undefined) {
                        message.details[entry7.key] = entry7.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            message: isSet(object.message) ? String(object.message) : "",
            type: isSet(object.type) ? errorTypeFromJSON(object.type) : 0,
            code: isSet(object.code) ? errorCodeFromJSON(object.code) : 0,
            userTitle: isSet(object.userTitle) ? String(object.userTitle) : "",
            userMessage: isSet(object.userMessage) ? String(object.userMessage) : "",
            cntTraceId: isSet(object.cntTraceId) ? String(object.cntTraceId) : "",
            details: isObject(object.details)
                ? Object.entries(object.details).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.message !== undefined && (obj.message = message.message);
        message.type !== undefined && (obj.type = errorTypeToJSON(message.type));
        message.code !== undefined && (obj.code = errorCodeToJSON(message.code));
        message.userTitle !== undefined && (obj.userTitle = message.userTitle);
        message.userMessage !== undefined &&
            (obj.userMessage = message.userMessage);
        message.cntTraceId !== undefined && (obj.cntTraceId = message.cntTraceId);
        obj.details = {};
        if (message.details) {
            Object.entries(message.details).forEach(([k, v]) => {
                obj.details[k] = v;
            });
        }
        return obj;
    },
};
function createBaseError_DetailsEntry() {
    return { key: "", value: "" };
}
export const Error_DetailsEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseError_DetailsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBasePartialError() {
    return { message: "", type: 0, code: 0, userTitle: "", userMessage: "" };
}
export const PartialError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.message !== "") {
            writer.uint32(10).string(message.message);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.code !== 0) {
            writer.uint32(24).int32(message.code);
        }
        if (message.userTitle !== "") {
            writer.uint32(34).string(message.userTitle);
        }
        if (message.userMessage !== "") {
            writer.uint32(42).string(message.userMessage);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePartialError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.code = reader.int32();
                    break;
                case 4:
                    message.userTitle = reader.string();
                    break;
                case 5:
                    message.userMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            message: isSet(object.message) ? String(object.message) : "",
            type: isSet(object.type) ? errorTypeFromJSON(object.type) : 0,
            code: isSet(object.code) ? errorCodeFromJSON(object.code) : 0,
            userTitle: isSet(object.userTitle) ? String(object.userTitle) : "",
            userMessage: isSet(object.userMessage) ? String(object.userMessage) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.message !== undefined && (obj.message = message.message);
        message.type !== undefined && (obj.type = errorTypeToJSON(message.type));
        message.code !== undefined && (obj.code = errorCodeToJSON(message.code));
        message.userTitle !== undefined && (obj.userTitle = message.userTitle);
        message.userMessage !== undefined &&
            (obj.userMessage = message.userMessage);
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
