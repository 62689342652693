import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState, useCallback } from 'react';
import { format, formatDistance } from 'date-fns';
import { SidebarDIContext } from '../../context';
export const RoomCardComponentId = '25704922-9f56-4bb8-a89c-d9edc38d5722';
const RoomCard = ({ room }) => {
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        return null;
    }
    const noRefreshTime = undefined;
    const timeDisplayRefreshInterval = 15 * 1000;
    const computeDisplayTime = useCallback((lastEventTime) => {
        if (lastEventTime === undefined) {
            return {
                time: '',
                refreshIn: undefined,
            };
        }
        const time = Date.parse(lastEventTime);
        const now = Date.now();
        const timeToNow = now - time;
        const shouldDisplayLocalTime = timeToNow > 30 * 60 * 1000;
        const shouldDisplayRelativeTime = timeToNow > 30 * 1000;
        const shouldDisplayDate = timeToNow > 24 * 60 * 60 * 1000;
        const shouldDisplayYear = timeToNow > 365 * 24 * 60 * 60 * 1000;
        let refreshIn = timeDisplayRefreshInterval;
        let timeStr = 'Just now';
        if (shouldDisplayYear) {
            timeStr = format(time, 'PP');
        }
        else if (shouldDisplayDate) {
            refreshIn = noRefreshTime;
            timeStr = format(time, 'LLL dd');
        }
        else if (shouldDisplayLocalTime) {
            refreshIn = noRefreshTime;
            timeStr = format(time, 'HH:mm');
        }
        else if (shouldDisplayRelativeTime) {
            timeStr = formatDistance(time, now);
        }
        return {
            time: timeStr,
            refreshIn,
        };
    }, [noRefreshTime, timeDisplayRefreshInterval]);
    const [displayTime, setDisplayTime] = useState(computeDisplayTime(room.lastEventTime));
    useEffect(() => {
        let timer;
        setDisplayTime(computeDisplayTime(room.lastEventTime));
        const scheduleRecompute = () => {
            if (displayTime.refreshIn !== noRefreshTime) {
                timer = setTimeout(() => {
                    setDisplayTime(computeDisplayTime(room.lastEventTime));
                }, displayTime.refreshIn);
            }
        };
        scheduleRecompute();
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [computeDisplayTime, displayTime.refreshIn, noRefreshTime, room.lastEventTime]);
    const { assignedAgent } = room;
    const roomViewModel = {
        id: room.id,
        title: room.title,
        displayTime: displayTime.time,
        preview: room.preview,
        avatarUrl: room.avatarUrl,
        avatarBadge: room.avatarBadgeIconKind,
        assignedAgent,
        primaryColor: room.primaryColor,
        roomStatus: room.roomStatus,
        unreadCount: room.unreadCount,
    };
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(RoomCardComponentId);
    return UIComponent ? _jsx(UIComponent, { room: roomViewModel, state: room.state }) : null;
};
export default RoomCard;
