import React from 'react';
import { AccessTimeIcon, Card, Label, IconButton, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TargetHandle } from '@components/TargetHandle';
import { SourceHandle } from '@components/SourceHandle';
import { APICallNodeType } from './constants';
import { useIsHoveringNode } from '../../hooks/useIsHoveringNode';
import { useNodeUIState } from '../../hooks/useNodeUIState';
import { TimeDelayNodeUIState } from '../TimeDelayNodeEditor/types';
import { TimeDelayNodeProps } from './types';

const useTimeDelayNodeEditor = ({ id, data }: TimeDelayNodeProps) => {
  const { nodeUIState } = useNodeUIState<TimeDelayNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_TIME_DELAY',
    nodeId: id,
    nodeData: data,
  });

  return {
    label: `Wait ${nodeUIState.delayNumber} ${nodeUIState.delayType}`,
  };
};

export const TimeDelayNode = (props: TimeDelayNodeProps) => {
  const { id } = props;
  const { label } = useTimeDelayNodeEditor(props);
  const theme = useTheme();
  const { isHoveringNode, handleMouseEnter, handleMouseLeave } = useIsHoveringNode(id);
  // TODO FIX APINodeCanvasIcon
  return (
    <Card
      sx={{
        alignItems: 'center',
        background: theme.palette.common.white,
        borderRadius: '10px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        display: 'flex',
        gap: 1,
        ':hover': {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
        },
        justifyContent: 'center',
        padding: 2,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TargetHandle nodeId={id} nodeType={APICallNodeType} actionType="on-execute" />
      <SourceHandle nodeId={id} nodeType={APICallNodeType} actionType="event" isHidden={!isHoveringNode} />
      <IconButton disableRipple sx={{ background: '#D1D8DC', width: '28px', height: '28px' }}>
        <AccessTimeIcon style={{ width: '20px', height: '20px' }} />
      </IconButton>
      <Label variant="h7">{label}</Label>
    </Card>
  );
};
