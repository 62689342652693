import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { BroadcastDIContext } from '../../context';
import { selectAudiences, selectLoadingState } from '../../state/audiencesSlice';
import { tryQueryAudiences } from '../../state/thunk';
import { mapAudienceToViewModel } from '../common/presenter';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
export const BroadcastAudiencesComponentId = 'e4386fec-ca72-4f89-89c4-b24413013dcd';
const BroadcastAudiences = (_props) => {
    const di = useContext(BroadcastDIContext);
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        if (di && di.config.businessId) {
            dispatch(tryQueryAudiences({ di }));
        }
    }, [di, di === null || di === void 0 ? void 0 : di.config.businessId, dispatch]);
    const loadingState = useFeatureSelector(selectLoadingState);
    const audiences = useFeatureSelector(selectAudiences).map(mapAudienceToViewModel);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastAudiencesComponentId);
    if (!UIComponent)
        return null;
    return _jsx(UIComponent, { loadingState: loadingState, audiences: audiences });
};
export default BroadcastAudiences;
