import React, { useState, useMemo, useContext, useCallback } from 'react';
import { useAtom } from 'jotai';
import { sofiaOnboardingDocumentsAtom } from '@atoms/sofia';
import { useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { DropZone } from '@components';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { selectBusinessId, useMeData } from '@hooks';
import { useSofiaDocuments, useRemoveSofiaDocumentAssociation, useUploadSofiaDocument } from '../../hooks';
import { NotificationContext } from '../../../../contexts';
import { selectSofiaDocuments } from '../../selectors';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from './constants';
import { DataPillItem } from '../DataPill';
import { SettingsDataSection } from '../SettingsDataSection';
import { InputDocumentsProps } from './types';
import { mapDocumentToDataPillItem } from '../../utils';

const useInputDocuments = ({ isOnboarding }: { isOnboarding: boolean }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadErrorText, setUploadErrorText] = useState<string | undefined>('');
  const [isRemovingDocument, setIsRemovingDocument] = useState(false);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [onboardingDocuments, setOnboardingDocuments] = useAtom(sofiaOnboardingDocumentsAtom);
  const { data: documents, isLoading } = useSofiaDocuments({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSofiaDocuments,
  });
  const documentsData: DataPillItem[] = useMemo(() => {
    if (isOnboarding) {
      return onboardingDocuments.map((doc) => ({ value: doc.id, display: doc.name || doc.id }));
    }
    if (!documents) return [];
    return documents.map(mapDocumentToDataPillItem);
  }, [documents, isOnboarding, JSON.stringify(onboardingDocuments)]);
  const { uploadFile, linkDocument, storeDocument } = useUploadSofiaDocument({
    businessId: businessId || '',
    acceptedFileTypes: ACCEPTED_FILE_TYPES,
    maxFileSize: MAX_FILE_SIZE,
    documents: documents || [],
  });
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const { mutate: removeDocument } = useRemoveSofiaDocumentAssociation({
    onSuccess() {
      queryClient.invalidateQueries(['sofia-documents', businessId]);
      setTimeout(() => setIsRemovingDocument(false), 100);
      setIsRemovingDocument(false);
    },
    onError() {
      setIsRemovingDocument(false);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error removing document',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const handleRemove = useCallback(
    async (id: string) => {
      if (!businessId) {
        return;
      }

      setIsUploaded(false);
      if (isOnboarding) {
        // remove doc from atom
        setOnboardingDocuments((docs) => docs.filter((doc) => doc.id !== id));
      } else {
        setIsRemovingDocument(true);
        await removeDocument({
          businessId,
          assetId: id,
        });
      }
    },
    [businessId, isOnboarding, removeDocument, setOnboardingDocuments],
  );

  const handleUploadFile = useCallback(
    async (file: File) => {
      if (!businessId) {
        return;
      }
      const { success, message, id } = await uploadFile(file);
      if (!success || !id) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            icon: '',
            message: message || 'Error uploading file',
            severity: NotificationSeverity.ERROR,
          },
        });
        setHasUploadError(true);
        setUploadErrorText(message);
        return;
      }
      setIsUploaded(success);
      setHasUploadError(false);
      setUploadErrorText('');
      // uploaded docs cannot be linked until the bot is first created
      if (isOnboarding) {
        await storeDocument({ id, name: file.name });
      } else {
        await linkDocument({ id, name: file.name });
      }
    },
    [businessId, isOnboarding, linkDocument, notificationProvider, storeDocument, uploadFile],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });

  return {
    handleUploadFile,
    handleRemove,
    documentsData,
    disabled: isRemovingDocument || isLoading,
    acceptedFileTypesText: t('inputDocumentsAcceptedFileTypes'),
    uploadLimitText: `${t('inputDocumentsFileSizeLimit')}: ${MAX_FILE_SIZE}MB.`,
    isUploaded,
    hasUploadError,
    uploadErrorText,
  };
};

export const InputDocuments = ({ isOnboarding }: InputDocumentsProps) => {
  const theme = useTheme();
  const {
    handleUploadFile,
    handleRemove,
    documentsData,
    disabled,
    acceptedFileTypesText,
    uploadLimitText,
    isUploaded,
    hasUploadError,
    uploadErrorText,
  } = useInputDocuments({ isOnboarding });

  return (
    <>
      {documentsData?.length ? (
        <SettingsDataSection
          label="inputDocumentsLabel"
          data={documentsData}
          isDocument
          onRemove={handleRemove}
          removeDisabled={disabled}
          sx={{ mb: 2, background: theme.palette.common.white }}
        />
      ) : null}
      <DropZone
        autoFocus
        errorHelperText={uploadErrorText}
        isSuccess={isUploaded}
        hasError={hasUploadError}
        acceptedFileTypesText={acceptedFileTypesText}
        uploadLimitText={uploadLimitText}
        handleUploadFile={handleUploadFile}
        sx={{ height: theme.spacing(24.4), width: theme.spacing(60) }}
        acceptedFileTypes={ACCEPTED_FILE_TYPES}
      />
    </>
  );
};
