import { Box, Label, LaunchIcon, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDiscountAmountString } from '../CartAbandonmentDiscount/utils';
import { WhatsAppMessageTemplatePreviewProps } from './types';

export const WhatsAppMessageTemplatePreview = ({
  bodyText,
  img,
  discount,
  language,
  isPersonalMessage,
}: WhatsAppMessageTemplatePreviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart.editIntegration' });

  const DEFAULT_AI_MESSAGE = `👋 Hey there!\nWe noticed you left the Air Jordan in your cart.👟 
            Don't let them slip away!\n
            Complete your purchase now and step up your sneaker game. Hurry, limited stock available.💨🛒`;

  const DEFAULT_AI_MESSAGE_ES = `👋¡Hola!\n
        Notamos que dejaste los Air Jordan en tu carrito.👟 ¡No dejes que se te escapen!\n
        Completa tu compra ahora y eleva tu nivel en el juego de zapatillas. Date prisa, hay stock limitado disponible.💨🛒`;

  const DEFAULT_AI_MESSAGE_PT = `
  Olá {{1}}!👋\n
  Você esqueceu algo no seu carrinho: o Air Jordan está esperando por você!🛒
  Não perca a chance, garanta o seu antes que acabe. Nosso estoque está voando!🚀\n
  Finalize sua compra agora e garanta estilo e conforto.👟
  `;

  const DEFAULT_AI_DISCOUNT_MESSAGE = `👋 Hey there!\nWe noticed you left the Air Jordan in your cart.👟 
    Don't let them slip away!\n
    Use discount code ${discount?.discountCode} to get an amazing ${
      discount && getDiscountAmountString(discount)
    } on your order! 
    This discount will be automatically applied`;

  const DEFAULT_AI_DISCOUNT_MESSAGE_ES = `👋¡Hola!\nNotamos que dejaste los Air Jordan en tu carrito.👟
    ¡No dejes que se te escapen!\n
    Usa el código de descuento ${discount?.discountCode} para obtener un increíble ${
      discount && getDiscountAmountString(discount)
    } en tu pedido!
    Este descuento se aplicará automáticamente.`;

  const DEFAULT_AI_DISCOUNT_MESSAGE_PT = `👋Olá!\n Notamos que você deixou o Air Jordan em seu carrinho.👟
  Não deixe que eles escapem!\n
  Use o código de desconto ${discount?.discountCode} para obter um incrível desconto de ${
    discount && getDiscountAmountString(discount)
  } em seu pedido! 
  Esse desconto será aplicado automaticamente`;

  const DEFAULT_MESSAGE = (
    <>
      Hi{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        {`Customer's first name`}
      </Box>
      👋.{'\n\n'}
      We noticed you left the following items in your cart!🛒{'\n\n'}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        List of products
      </Box>
      {'\n\n'}-{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Shop Name
      </Box>
    </>
  );

  const DEFAULT_MESSAGE_ES = (
    <>
      ¡Hola{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Nombre del cliente
      </Box>
      👋.!{'\n\n'}Notamos que dejaste los siguientes artículos en tu carrito!🛒{'\n\n'}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Lista de productos
      </Box>
      {'\n\n'}-{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Nombre de la Tienda
      </Box>
    </>
  );

  const DEFAULT_MESSAGE_PT = `
  Olá {{1}}👋\n
  Percebemos que você deixou alguns itens no seu carrinho🛒\n
  Compre agora antes que acabem👇
  `;

  const DEFAULT_DISCOUNT_MESSAGE = (
    <>
      Hi{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        {`Customer's first name`}
      </Box>
      👋.
      {'\n\n'}We noticed you left the following items in your cart!🛒{'\n\n'}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        List of products
      </Box>
      {'\n\n'}Buy now before they are gone and get{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Discount Info
      </Box>{' '}
      discount!{'\n\n'}-{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Shop Name
      </Box>
    </>
  );

  const DEFAULT_DISCOUNT_MESSAGE_ES = (
    <>
      ¡Hola{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Nombre del cliente
      </Box>
      👋.{'\n\n'}Notamos que dejaste los siguientes artículos en tu carrito!🛒{'\n\n'}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Lista de productos
      </Box>
      .{'\n\n'}
      ¡Compra ahora antes de que se agoten y obtén un descuento de{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Información de descuento
      </Box>
      !{'\n\n'}-{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Nombre de la Tienda
      </Box>
    </>
  );

  const DEFAULT_DISCOUNT_MESSAGE_PT = (
    <>
      ¡Olá{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        {`Customer's first name`}
      </Box>
      👋.
      {'\n\n'}Percebemos que você deixou os seguintes itens em seu carrinho!🛒{'\n\n'}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Lista de produtos
      </Box>
      {'\n\n'}Compre agora antes que acabem e ganhe{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Informações sobre descontos
      </Box>{' '}
      desconto!{'\n\n'}-{' '}
      <Box component="span" m="{1}" sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}>
        Nome da loja
      </Box>
    </>
  );

  const getText = useMemo(() => {
    if (bodyText) {
      return <Label sx={{ fontWeight: 400, fontSize: '14px', whiteSpace: 'pre-line' }}>{bodyText}</Label>;
    }
    if (!language) {
      return DEFAULT_AI_MESSAGE;
    }
    if (language === 'es') {
      if (discount && discount?.discountCode) {
        if (isPersonalMessage) {
          return DEFAULT_AI_DISCOUNT_MESSAGE_ES;
        }
        return DEFAULT_DISCOUNT_MESSAGE_ES;
      }
      if (isPersonalMessage) {
        return DEFAULT_AI_MESSAGE_ES;
      }
      return DEFAULT_MESSAGE_ES;
    }
    if (language === 'pt_BR') {
      if (discount && discount?.discountCode) {
        if (isPersonalMessage) {
          return DEFAULT_AI_DISCOUNT_MESSAGE_PT;
        }
        return DEFAULT_DISCOUNT_MESSAGE_PT;
      }
      if (isPersonalMessage) {
        return DEFAULT_AI_MESSAGE_PT;
      }
      return DEFAULT_MESSAGE_PT;
    }
    if (discount && discount?.discountCode) {
      if (isPersonalMessage) {
        return DEFAULT_AI_DISCOUNT_MESSAGE;
      }
      return DEFAULT_DISCOUNT_MESSAGE;
    }
    if (isPersonalMessage) {
      return DEFAULT_AI_MESSAGE;
    }
    return DEFAULT_MESSAGE;
  }, [bodyText, language, isPersonalMessage, discount]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 1.5,
          background: theme.palette.common.white,
          borderRadius: 1,
          width: '240px',
          boxShadow: '0px 1px 1px 0px #00000033',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            left: theme.spacing(-1),
            top: 0,
            bottom: 'auto',
            border: '12px solid',
            borderColor: '#fff transparent transparent transparent',
            zIndex: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {img && <Box component="img" src={img} sx={{ width: '100%', borderRadius: 0.5 }} />}
          <Label variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {getText}
          </Label>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 1,
          px: 1.5,
          gap: 1,
          background: theme.palette.common.white,
          borderRadius: 1,
          width: theme.spacing(30),
          alignItems: 'center',
          color: theme.palette.primary.main,
          boxShadow: '0px 1px 1px 0px #00000033',
        }}
      >
        <LaunchIcon />
        <Label variant="subtitle1">{t('buyNow')}</Label>
      </Box>
    </Box>
  );
};
