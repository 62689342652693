export const mutationCreateQuickReplyTemplateProvider = (log, networkClient) => async (input) => {
    const url = `/v1/businesses/${input.business_id}/create/quick_reply_templates`;
    log.debug('mutationCreateQuickReplyTemplate@start', () => ({
        url,
    }));
    try {
        const serverInput = {
            entity: {
                id: input.id,
                businessId: input.business_id,
                authorId: undefined,
                createdAt: undefined,
                updatedAt: undefined,
                header: input.header,
                bodyTemplate: input.bodyTemplate,
                variables: [],
            },
        };
        const resp = await networkClient.post(url, serverInput);
        log.debug('mutationCreateQuickReplyTemplate@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationCreateQuickReplyTemplate@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
