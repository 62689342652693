import React, { PropsWithChildren } from 'react';
import {
  Card,
  Label,
  useTheme,
  CustomerRepliesNodeCanvasIcon,
  Box,
  SelectAllIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { TargetHandle } from '@components/TargetHandle';
import { SourceHandle } from '@components/SourceHandle';
import { TextVariable } from '@components/TextVariable';
import { useFlowVariables } from '@hooks';
import { useIsHoveringNode } from '../../hooks/useIsHoveringNode';
import { CUSTOMER_REPLIES_NODE_TYPE } from './constants';
import { useNodeUIState } from '../../hooks/useNodeUIState';
import { CustomerRepliesNodeUIState } from '../CustomerRepliesNodeEditor/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCustomerRepliesNode = ({ id, data }: { id: string; data?: any }) => {
  const { nodeUIState } = useNodeUIState<CustomerRepliesNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT',
    nodeId: id,
    nodeData: data,
  });
  return { nodeUIState };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomerRepliesNode = ({ id, data }: PropsWithChildren<{ id: string; data?: any }>) => {
  const { nodeUIState } = useCustomerRepliesNode({ id, data });
  const theme = useTheme();
  const { isHoveringNode, handleMouseEnter, handleMouseLeave } = useIsHoveringNode(id);
  const { trimCurly } = useFlowVariables();

  return (
    <Card
      sx={{
        alignItems: 'center',
        background: theme.palette.common.white,
        borderRadius: '100px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        display: 'flex',
        gap: 1,
        ':hover': {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
        },
        justifyContent: 'center',
        padding: 2,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TargetHandle nodeId={id} nodeType={CUSTOMER_REPLIES_NODE_TYPE} actionType="on-execute" />
      <SourceHandle nodeId={id} nodeType={CUSTOMER_REPLIES_NODE_TYPE} actionType="event" isHidden={!isHoveringNode} />
      <CustomerRepliesNodeCanvasIcon />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Label variant="h7">If customer replies</Label>
        {/* after enabling image replies mapFormatToCanvasText(nodeUIState?.format) */}
        {nodeUIState?.saveAsVariable && !!nodeUIState?.variable && (
          <>
            <Label variant="h7">save as</Label>
            <TextVariable
              icon={<SelectAllIcon sx={{ width: theme.spacing(1.5), height: theme.spacing(1.5) }} />}
              variable={<Label variant="body2">{trimCurly(nodeUIState?.variable)}</Label>}
              variableType="SESSION"
              style={{
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                paddingTop: theme.spacing(0.25),
                paddingBottom: theme.spacing(0.25),
                marginLeft: theme.spacing(0.5),
              }}
            />
          </>
        )}
      </Box>
    </Card>
  );
};
