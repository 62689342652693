import { channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import { CampaignOutcome, CampaignStatus, SendoutConfig, SendoutOutcome, MTBBroadcastInputNode, SendSendoutNow, ScheduleSendout, SendoutExecutionDataV1, campaignTypeFromJSON, campaignTypeToJSON, sendoutStateFromJSON, sendoutStateToJSON, sendoutInputTypeFromJSON, sendoutInputTypeToJSON, } from "./models";
import { SimplePaging, mutationStatusFromJSON, mutationStatusToJSON, } from "../common/api/models";
import { Timestamp } from "../../google/protobuf/timestamp";
import { ProgressStatus } from "../event/connectly/models";
import { Error } from "../error/error";
import { SendoutConfigV3 } from "./models_v3";
import * as _m0 from "protobufjs/minimal";
import { BoolValue, UInt32Value } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.campaign";
export var CampaignOrder;
(function (CampaignOrder) {
    CampaignOrder[CampaignOrder["CAMPAIGN_ORDER_UNSPECIFIED"] = 0] = "CAMPAIGN_ORDER_UNSPECIFIED";
    CampaignOrder[CampaignOrder["CAMPAIGN_ORDER_NAME"] = 1] = "CAMPAIGN_ORDER_NAME";
    CampaignOrder[CampaignOrder["CAMPAIGN_ORDER_NAME_DESC"] = 2] = "CAMPAIGN_ORDER_NAME_DESC";
    CampaignOrder[CampaignOrder["CAMPAIGN_ORDER_CREATED_AT"] = 3] = "CAMPAIGN_ORDER_CREATED_AT";
    CampaignOrder[CampaignOrder["CAMPAIGN_ORDER_CREATED_AT_DESC"] = 4] = "CAMPAIGN_ORDER_CREATED_AT_DESC";
    CampaignOrder[CampaignOrder["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CampaignOrder || (CampaignOrder = {}));
export function campaignOrderFromJSON(object) {
    switch (object) {
        case 0:
        case "CAMPAIGN_ORDER_UNSPECIFIED":
            return CampaignOrder.CAMPAIGN_ORDER_UNSPECIFIED;
        case 1:
        case "CAMPAIGN_ORDER_NAME":
            return CampaignOrder.CAMPAIGN_ORDER_NAME;
        case 2:
        case "CAMPAIGN_ORDER_NAME_DESC":
            return CampaignOrder.CAMPAIGN_ORDER_NAME_DESC;
        case 3:
        case "CAMPAIGN_ORDER_CREATED_AT":
            return CampaignOrder.CAMPAIGN_ORDER_CREATED_AT;
        case 4:
        case "CAMPAIGN_ORDER_CREATED_AT_DESC":
            return CampaignOrder.CAMPAIGN_ORDER_CREATED_AT_DESC;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignOrder.UNRECOGNIZED;
    }
}
export function campaignOrderToJSON(object) {
    switch (object) {
        case CampaignOrder.CAMPAIGN_ORDER_UNSPECIFIED:
            return "CAMPAIGN_ORDER_UNSPECIFIED";
        case CampaignOrder.CAMPAIGN_ORDER_NAME:
            return "CAMPAIGN_ORDER_NAME";
        case CampaignOrder.CAMPAIGN_ORDER_NAME_DESC:
            return "CAMPAIGN_ORDER_NAME_DESC";
        case CampaignOrder.CAMPAIGN_ORDER_CREATED_AT:
            return "CAMPAIGN_ORDER_CREATED_AT";
        case CampaignOrder.CAMPAIGN_ORDER_CREATED_AT_DESC:
            return "CAMPAIGN_ORDER_CREATED_AT_DESC";
        case CampaignOrder.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseQueryCampaigns() {
    return {
        channelTypes: [],
        types: [],
        search: undefined,
        orderBy: 0,
        flowDocumentId: "",
        hasFlowDocumentId: undefined,
        paging: undefined,
    };
}
export const QueryCampaigns = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.channelTypes) {
            writer.int32(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.types) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.search !== undefined) {
            CampaignSearchInput.encode(message.search, writer.uint32(26).fork()).ldelim();
        }
        if (message.orderBy !== 0) {
            writer.uint32(32).int32(message.orderBy);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(42).string(message.flowDocumentId);
        }
        if (message.hasFlowDocumentId !== undefined) {
            BoolValue.encode({ value: message.hasFlowDocumentId }, writer.uint32(50).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            SimplePaging.encode(message.paging, writer.uint32(162).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryCampaigns();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.channelTypes.push(reader.int32());
                        }
                    }
                    else {
                        message.channelTypes.push(reader.int32());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.types.push(reader.int32());
                        }
                    }
                    else {
                        message.types.push(reader.int32());
                    }
                    break;
                case 3:
                    message.search = CampaignSearchInput.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.orderBy = reader.int32();
                    break;
                case 5:
                    message.flowDocumentId = reader.string();
                    break;
                case 6:
                    message.hasFlowDocumentId = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 20:
                    message.paging = SimplePaging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelTypes: Array.isArray(object === null || object === void 0 ? void 0 : object.channelTypes)
                ? object.channelTypes.map((e) => channelTypeFromJSON(e))
                : [],
            types: Array.isArray(object === null || object === void 0 ? void 0 : object.types)
                ? object.types.map((e) => campaignTypeFromJSON(e))
                : [],
            search: isSet(object.search)
                ? CampaignSearchInput.fromJSON(object.search)
                : undefined,
            orderBy: isSet(object.orderBy)
                ? campaignOrderFromJSON(object.orderBy)
                : 0,
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            hasFlowDocumentId: isSet(object.hasFlowDocumentId)
                ? Boolean(object.hasFlowDocumentId)
                : undefined,
            paging: isSet(object.paging)
                ? SimplePaging.fromJSON(object.paging)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.channelTypes) {
            obj.channelTypes = message.channelTypes.map((e) => channelTypeToJSON(e));
        }
        else {
            obj.channelTypes = [];
        }
        if (message.types) {
            obj.types = message.types.map((e) => campaignTypeToJSON(e));
        }
        else {
            obj.types = [];
        }
        message.search !== undefined &&
            (obj.search = message.search
                ? CampaignSearchInput.toJSON(message.search)
                : undefined);
        message.orderBy !== undefined &&
            (obj.orderBy = campaignOrderToJSON(message.orderBy));
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.hasFlowDocumentId !== undefined &&
            (obj.hasFlowDocumentId = message.hasFlowDocumentId);
        message.paging !== undefined &&
            (obj.paging = message.paging
                ? SimplePaging.toJSON(message.paging)
                : undefined);
        return obj;
    },
};
function createBaseCampaignSearchInput() {
    return { name: "", exactMatch: false };
}
export const CampaignSearchInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.exactMatch === true) {
            writer.uint32(16).bool(message.exactMatch);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignSearchInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.exactMatch = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            exactMatch: isSet(object.exactMatch) ? Boolean(object.exactMatch) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.exactMatch !== undefined && (obj.exactMatch = message.exactMatch);
        return obj;
    },
};
function createBaseSendoutName() {
    return { sendoutId: "", name: "" };
}
export const SendoutName = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sendoutId !== "") {
            writer.uint32(10).string(message.sendoutId);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutName();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendoutId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseQuerySendouts() {
    return {
        channelType: 0,
        types: [],
        states: [],
        substates: [],
        campaignId: "",
        search: undefined,
        orderBy: 0,
        flowDocumentId: "",
        paging: undefined,
    };
}
export const QuerySendouts = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channelType !== 0) {
            writer.uint32(8).int32(message.channelType);
        }
        writer.uint32(18).fork();
        for (const v of message.types) {
            writer.int32(v);
        }
        writer.ldelim();
        writer.uint32(26).fork();
        for (const v of message.states) {
            writer.int32(v);
        }
        writer.ldelim();
        for (const v of message.substates) {
            writer.uint32(34).string(v);
        }
        if (message.campaignId !== "") {
            writer.uint32(42).string(message.campaignId);
        }
        if (message.search !== undefined) {
            CampaignSearchInput.encode(message.search, writer.uint32(50).fork()).ldelim();
        }
        if (message.orderBy !== 0) {
            writer.uint32(56).int32(message.orderBy);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(66).string(message.flowDocumentId);
        }
        if (message.paging !== undefined) {
            SimplePaging.encode(message.paging, writer.uint32(162).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuerySendouts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channelType = reader.int32();
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.types.push(reader.int32());
                        }
                    }
                    else {
                        message.types.push(reader.int32());
                    }
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.states.push(reader.int32());
                        }
                    }
                    else {
                        message.states.push(reader.int32());
                    }
                    break;
                case 4:
                    message.substates.push(reader.string());
                    break;
                case 5:
                    message.campaignId = reader.string();
                    break;
                case 6:
                    message.search = CampaignSearchInput.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.orderBy = reader.int32();
                    break;
                case 8:
                    message.flowDocumentId = reader.string();
                    break;
                case 20:
                    message.paging = SimplePaging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            types: Array.isArray(object === null || object === void 0 ? void 0 : object.types)
                ? object.types.map((e) => campaignTypeFromJSON(e))
                : [],
            states: Array.isArray(object === null || object === void 0 ? void 0 : object.states)
                ? object.states.map((e) => sendoutStateFromJSON(e))
                : [],
            substates: Array.isArray(object === null || object === void 0 ? void 0 : object.substates)
                ? object.substates.map((e) => String(e))
                : [],
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            search: isSet(object.search)
                ? CampaignSearchInput.fromJSON(object.search)
                : undefined,
            orderBy: isSet(object.orderBy)
                ? campaignOrderFromJSON(object.orderBy)
                : 0,
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            paging: isSet(object.paging)
                ? SimplePaging.fromJSON(object.paging)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        if (message.types) {
            obj.types = message.types.map((e) => campaignTypeToJSON(e));
        }
        else {
            obj.types = [];
        }
        if (message.states) {
            obj.states = message.states.map((e) => sendoutStateToJSON(e));
        }
        else {
            obj.states = [];
        }
        if (message.substates) {
            obj.substates = message.substates.map((e) => e);
        }
        else {
            obj.substates = [];
        }
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.search !== undefined &&
            (obj.search = message.search
                ? CampaignSearchInput.toJSON(message.search)
                : undefined);
        message.orderBy !== undefined &&
            (obj.orderBy = campaignOrderToJSON(message.orderBy));
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.paging !== undefined &&
            (obj.paging = message.paging
                ? SimplePaging.toJSON(message.paging)
                : undefined);
        return obj;
    },
};
function createBaseCampaignDtoCollection() {
    return { campaigns: [] };
}
export const CampaignDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.campaigns) {
            CampaignDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaigns.push(CampaignDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaigns: Array.isArray(object === null || object === void 0 ? void 0 : object.campaigns)
                ? object.campaigns.map((e) => CampaignDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.campaigns) {
            obj.campaigns = message.campaigns.map((e) => e ? CampaignDto.toJSON(e) : undefined);
        }
        else {
            obj.campaigns = [];
        }
        return obj;
    },
};
function createBaseCampaignDto() {
    return {
        id: "",
        businessId: "",
        name: "",
        description: "",
        outcome: undefined,
        status: undefined,
        authorId: "",
        flowDocumentId: "",
        originalName: "",
        nameNormalized: "",
        createdAt: undefined,
        updatedAt: undefined,
    };
}
export const CampaignDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.description !== "") {
            writer.uint32(34).string(message.description);
        }
        if (message.outcome !== undefined) {
            CampaignOutcome.encode(message.outcome, writer.uint32(42).fork()).ldelim();
        }
        if (message.status !== undefined) {
            CampaignStatus.encode(message.status, writer.uint32(50).fork()).ldelim();
        }
        if (message.authorId !== "") {
            writer.uint32(58).string(message.authorId);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(66).string(message.flowDocumentId);
        }
        if (message.originalName !== "") {
            writer.uint32(74).string(message.originalName);
        }
        if (message.nameNormalized !== "") {
            writer.uint32(82).string(message.nameNormalized);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(162).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    message.outcome = CampaignOutcome.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.status = CampaignStatus.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.authorId = reader.string();
                    break;
                case 8:
                    message.flowDocumentId = reader.string();
                    break;
                case 9:
                    message.originalName = reader.string();
                    break;
                case 10:
                    message.nameNormalized = reader.string();
                    break;
                case 20:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            name: isSet(object.name) ? String(object.name) : "",
            description: isSet(object.description) ? String(object.description) : "",
            outcome: isSet(object.outcome)
                ? CampaignOutcome.fromJSON(object.outcome)
                : undefined,
            status: isSet(object.status)
                ? CampaignStatus.fromJSON(object.status)
                : undefined,
            authorId: isSet(object.authorId) ? String(object.authorId) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            originalName: isSet(object.originalName)
                ? String(object.originalName)
                : "",
            nameNormalized: isSet(object.nameNormalized)
                ? String(object.nameNormalized)
                : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.name !== undefined && (obj.name = message.name);
        message.description !== undefined &&
            (obj.description = message.description);
        message.outcome !== undefined &&
            (obj.outcome = message.outcome
                ? CampaignOutcome.toJSON(message.outcome)
                : undefined);
        message.status !== undefined &&
            (obj.status = message.status
                ? CampaignStatus.toJSON(message.status)
                : undefined);
        message.authorId !== undefined && (obj.authorId = message.authorId);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.originalName !== undefined &&
            (obj.originalName = message.originalName);
        message.nameNormalized !== undefined &&
            (obj.nameNormalized = message.nameNormalized);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
};
function createBaseSendoutDtoCollection() {
    return { sendouts: [] };
}
export const SendoutDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.sendouts) {
            SendoutDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendouts.push(SendoutDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendouts: Array.isArray(object === null || object === void 0 ? void 0 : object.sendouts)
                ? object.sendouts.map((e) => SendoutDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.sendouts) {
            obj.sendouts = message.sendouts.map((e) => e ? SendoutDto.toJSON(e) : undefined);
        }
        else {
            obj.sendouts = [];
        }
        return obj;
    },
};
function createBaseSendoutConfigFromV3() {
    return { configOneof: undefined };
}
export const SendoutConfigFromV3 = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v3") {
            SendoutConfigV3.encode(message.configOneof.v3, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutConfigFromV3();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "v3",
                        v3: SendoutConfigV3.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.v3)
                ? { $case: "v3", v3: SendoutConfigV3.fromJSON(object.v3) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v3" &&
            (obj.v3 = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.v3)
                ? SendoutConfigV3.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.v3)
                : undefined);
        return obj;
    },
};
function createBaseSendoutDto() {
    return {
        id: "",
        businessId: "",
        campaignId: "",
        authorId: "",
        config: undefined,
        configV3: undefined,
        type: 0,
        state: 0,
        outcome: undefined,
        readableName: "",
        name: "",
        campaign: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        verifiedAt: undefined,
        submittedAt: undefined,
        scheduledAt: undefined,
        dispatchedAt: undefined,
        completedAt: undefined,
        cancelledAt: undefined,
        abortedAt: undefined,
    };
}
export const SendoutDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.campaignId !== "") {
            writer.uint32(26).string(message.campaignId);
        }
        if (message.authorId !== "") {
            writer.uint32(66).string(message.authorId);
        }
        if (message.config !== undefined) {
            SendoutConfig.encode(message.config, writer.uint32(34).fork()).ldelim();
        }
        if (message.configV3 !== undefined) {
            SendoutConfigFromV3.encode(message.configV3, writer.uint32(82).fork()).ldelim();
        }
        if (message.type !== 0) {
            writer.uint32(40).int32(message.type);
        }
        if (message.state !== 0) {
            writer.uint32(48).int32(message.state);
        }
        if (message.outcome !== undefined) {
            SendoutOutcome.encode(message.outcome, writer.uint32(58).fork()).ldelim();
        }
        if (message.readableName !== "") {
            writer.uint32(74).string(message.readableName);
        }
        if (message.name !== "") {
            writer.uint32(794).string(message.name);
        }
        if (message.campaign !== undefined) {
            CampaignDto.encode(message.campaign, writer.uint32(154).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(162).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(170).fork()).ldelim();
        }
        if (message.verifiedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.verifiedAt), writer.uint32(178).fork()).ldelim();
        }
        if (message.submittedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.submittedAt), writer.uint32(186).fork()).ldelim();
        }
        if (message.scheduledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.scheduledAt), writer.uint32(194).fork()).ldelim();
        }
        if (message.dispatchedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.dispatchedAt), writer.uint32(202).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(210).fork()).ldelim();
        }
        if (message.cancelledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.cancelledAt), writer.uint32(218).fork()).ldelim();
        }
        if (message.abortedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.abortedAt), writer.uint32(226).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.campaignId = reader.string();
                    break;
                case 8:
                    message.authorId = reader.string();
                    break;
                case 4:
                    message.config = SendoutConfig.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.configV3 = SendoutConfigFromV3.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.state = reader.int32();
                    break;
                case 7:
                    message.outcome = SendoutOutcome.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.readableName = reader.string();
                    break;
                case 99:
                    message.name = reader.string();
                    break;
                case 19:
                    message.campaign = CampaignDto.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.verifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 23:
                    message.submittedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 24:
                    message.scheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 25:
                    message.dispatchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 27:
                    message.cancelledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 28:
                    message.abortedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            authorId: isSet(object.authorId) ? String(object.authorId) : "",
            config: isSet(object.config)
                ? SendoutConfig.fromJSON(object.config)
                : undefined,
            configV3: isSet(object.configV3)
                ? SendoutConfigFromV3.fromJSON(object.configV3)
                : undefined,
            type: isSet(object.type) ? campaignTypeFromJSON(object.type) : 0,
            state: isSet(object.state) ? sendoutStateFromJSON(object.state) : 0,
            outcome: isSet(object.outcome)
                ? SendoutOutcome.fromJSON(object.outcome)
                : undefined,
            readableName: isSet(object.readableName)
                ? String(object.readableName)
                : "",
            name: isSet(object.name) ? String(object.name) : "",
            campaign: isSet(object.campaign)
                ? CampaignDto.fromJSON(object.campaign)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            verifiedAt: isSet(object.verifiedAt)
                ? String(object.verifiedAt)
                : undefined,
            submittedAt: isSet(object.submittedAt)
                ? String(object.submittedAt)
                : undefined,
            scheduledAt: isSet(object.scheduledAt)
                ? String(object.scheduledAt)
                : undefined,
            dispatchedAt: isSet(object.dispatchedAt)
                ? String(object.dispatchedAt)
                : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
            cancelledAt: isSet(object.cancelledAt)
                ? String(object.cancelledAt)
                : undefined,
            abortedAt: isSet(object.abortedAt) ? String(object.abortedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.authorId !== undefined && (obj.authorId = message.authorId);
        message.config !== undefined &&
            (obj.config = message.config
                ? SendoutConfig.toJSON(message.config)
                : undefined);
        message.configV3 !== undefined &&
            (obj.configV3 = message.configV3
                ? SendoutConfigFromV3.toJSON(message.configV3)
                : undefined);
        message.type !== undefined && (obj.type = campaignTypeToJSON(message.type));
        message.state !== undefined &&
            (obj.state = sendoutStateToJSON(message.state));
        message.outcome !== undefined &&
            (obj.outcome = message.outcome
                ? SendoutOutcome.toJSON(message.outcome)
                : undefined);
        message.readableName !== undefined &&
            (obj.readableName = message.readableName);
        message.name !== undefined && (obj.name = message.name);
        message.campaign !== undefined &&
            (obj.campaign = message.campaign
                ? CampaignDto.toJSON(message.campaign)
                : undefined);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.verifiedAt !== undefined && (obj.verifiedAt = message.verifiedAt);
        message.submittedAt !== undefined &&
            (obj.submittedAt = message.submittedAt);
        message.scheduledAt !== undefined &&
            (obj.scheduledAt = message.scheduledAt);
        message.dispatchedAt !== undefined &&
            (obj.dispatchedAt = message.dispatchedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        message.cancelledAt !== undefined &&
            (obj.cancelledAt = message.cancelledAt);
        message.abortedAt !== undefined && (obj.abortedAt = message.abortedAt);
        return obj;
    },
};
function createBaseInitializeCampaignInput() {
    return {
        sendoutId: "",
        businessId: "",
        campaignConfig: undefined,
        sendoutConfig: undefined,
    };
}
export const InitializeCampaignInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sendoutId !== "") {
            writer.uint32(10).string(message.sendoutId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.campaignConfig !== undefined) {
            CampaignConfigInput.encode(message.campaignConfig, writer.uint32(26).fork()).ldelim();
        }
        if (message.sendoutConfig !== undefined) {
            SendoutConfigInput.encode(message.sendoutConfig, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInitializeCampaignInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendoutId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.campaignConfig = CampaignConfigInput.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sendoutConfig = SendoutConfigInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            campaignConfig: isSet(object.campaignConfig)
                ? CampaignConfigInput.fromJSON(object.campaignConfig)
                : undefined,
            sendoutConfig: isSet(object.sendoutConfig)
                ? SendoutConfigInput.fromJSON(object.sendoutConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.campaignConfig !== undefined &&
            (obj.campaignConfig = message.campaignConfig
                ? CampaignConfigInput.toJSON(message.campaignConfig)
                : undefined);
        message.sendoutConfig !== undefined &&
            (obj.sendoutConfig = message.sendoutConfig
                ? SendoutConfigInput.toJSON(message.sendoutConfig)
                : undefined);
        return obj;
    },
};
function createBaseInitializeSendoutInput() {
    return {
        id: "",
        businessId: "",
        campaignConfig: undefined,
        sendoutConfig: undefined,
    };
}
export const InitializeSendoutInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.campaignConfig !== undefined) {
            CampaignConfigInput.encode(message.campaignConfig, writer.uint32(26).fork()).ldelim();
        }
        if (message.sendoutConfig !== undefined) {
            SendoutConfigInput.encode(message.sendoutConfig, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInitializeSendoutInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.campaignConfig = CampaignConfigInput.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sendoutConfig = SendoutConfigInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            campaignConfig: isSet(object.campaignConfig)
                ? CampaignConfigInput.fromJSON(object.campaignConfig)
                : undefined,
            sendoutConfig: isSet(object.sendoutConfig)
                ? SendoutConfigInput.fromJSON(object.sendoutConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.campaignConfig !== undefined &&
            (obj.campaignConfig = message.campaignConfig
                ? CampaignConfigInput.toJSON(message.campaignConfig)
                : undefined);
        message.sendoutConfig !== undefined &&
            (obj.sendoutConfig = message.sendoutConfig
                ? SendoutConfigInput.toJSON(message.sendoutConfig)
                : undefined);
        return obj;
    },
};
function createBaseSendoutConfigInput() {
    return {
        inputType: 0,
        audienceSegmentId: "",
        inputCohortUrl: "",
        flowDocumentId: "",
        flowDocumentVersionId: "",
        configOneof: undefined,
    };
}
export const SendoutConfigInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e;
        if (message.inputType !== 0) {
            writer.uint32(8).int32(message.inputType);
        }
        if (message.audienceSegmentId !== "") {
            writer.uint32(18).string(message.audienceSegmentId);
        }
        if (message.inputCohortUrl !== "") {
            writer.uint32(26).string(message.inputCohortUrl);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(34).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(42).string(message.flowDocumentVersionId);
        }
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcast") {
            WABroadcastInputV1.encode(message.configOneof.waBroadcast, writer.uint32(170).fork()).ldelim();
        }
        if (((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.$case) === "stbBroadcastV1") {
            STBBroadcastInputV1.encode(message.configOneof.stbBroadcastV1, writer.uint32(178).fork()).ldelim();
        }
        if (((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.$case) === "mtbBroadcastV1") {
            MTBBroadcastInputV1.encode(message.configOneof.mtbBroadcastV1, writer.uint32(186).fork()).ldelim();
        }
        if (((_d = message.configOneof) === null || _d === void 0 ? void 0 : _d.$case) === "mtbBroadcastV2") {
            MTBBroadcastInputV2.encode(message.configOneof.mtbBroadcastV2, writer.uint32(194).fork()).ldelim();
        }
        if (((_e = message.configOneof) === null || _e === void 0 ? void 0 : _e.$case) === "recommendationBroadcastV1") {
            RecommendationBroadcastInputV1.encode(message.configOneof.recommendationBroadcastV1, writer.uint32(202).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutConfigInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputType = reader.int32();
                    break;
                case 2:
                    message.audienceSegmentId = reader.string();
                    break;
                case 3:
                    message.inputCohortUrl = reader.string();
                    break;
                case 4:
                    message.flowDocumentId = reader.string();
                    break;
                case 5:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 21:
                    message.configOneof = {
                        $case: "waBroadcast",
                        waBroadcast: WABroadcastInputV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 22:
                    message.configOneof = {
                        $case: "stbBroadcastV1",
                        stbBroadcastV1: STBBroadcastInputV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 23:
                    message.configOneof = {
                        $case: "mtbBroadcastV1",
                        mtbBroadcastV1: MTBBroadcastInputV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 24:
                    message.configOneof = {
                        $case: "mtbBroadcastV2",
                        mtbBroadcastV2: MTBBroadcastInputV2.decode(reader, reader.uint32()),
                    };
                    break;
                case 25:
                    message.configOneof = {
                        $case: "recommendationBroadcastV1",
                        recommendationBroadcastV1: RecommendationBroadcastInputV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputType: isSet(object.inputType)
                ? sendoutInputTypeFromJSON(object.inputType)
                : 0,
            audienceSegmentId: isSet(object.audienceSegmentId)
                ? String(object.audienceSegmentId)
                : "",
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            configOneof: isSet(object.waBroadcast)
                ? {
                    $case: "waBroadcast",
                    waBroadcast: WABroadcastInputV1.fromJSON(object.waBroadcast),
                }
                : isSet(object.stbBroadcastV1)
                    ? {
                        $case: "stbBroadcastV1",
                        stbBroadcastV1: STBBroadcastInputV1.fromJSON(object.stbBroadcastV1),
                    }
                    : isSet(object.mtbBroadcastV1)
                        ? {
                            $case: "mtbBroadcastV1",
                            mtbBroadcastV1: MTBBroadcastInputV1.fromJSON(object.mtbBroadcastV1),
                        }
                        : isSet(object.mtbBroadcastV2)
                            ? {
                                $case: "mtbBroadcastV2",
                                mtbBroadcastV2: MTBBroadcastInputV2.fromJSON(object.mtbBroadcastV2),
                            }
                            : isSet(object.recommendationBroadcastV1)
                                ? {
                                    $case: "recommendationBroadcastV1",
                                    recommendationBroadcastV1: RecommendationBroadcastInputV1.fromJSON(object.recommendationBroadcastV1),
                                }
                                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        const obj = {};
        message.inputType !== undefined &&
            (obj.inputType = sendoutInputTypeToJSON(message.inputType));
        message.audienceSegmentId !== undefined &&
            (obj.audienceSegmentId = message.audienceSegmentId);
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcast" &&
            (obj.waBroadcast = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.waBroadcast)
                ? WABroadcastInputV1.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.waBroadcast)
                : undefined);
        ((_d = message.configOneof) === null || _d === void 0 ? void 0 : _d.$case) === "stbBroadcastV1" &&
            (obj.stbBroadcastV1 = ((_e = message.configOneof) === null || _e === void 0 ? void 0 : _e.stbBroadcastV1)
                ? STBBroadcastInputV1.toJSON((_f = message.configOneof) === null || _f === void 0 ? void 0 : _f.stbBroadcastV1)
                : undefined);
        ((_g = message.configOneof) === null || _g === void 0 ? void 0 : _g.$case) === "mtbBroadcastV1" &&
            (obj.mtbBroadcastV1 = ((_h = message.configOneof) === null || _h === void 0 ? void 0 : _h.mtbBroadcastV1)
                ? MTBBroadcastInputV1.toJSON((_j = message.configOneof) === null || _j === void 0 ? void 0 : _j.mtbBroadcastV1)
                : undefined);
        ((_k = message.configOneof) === null || _k === void 0 ? void 0 : _k.$case) === "mtbBroadcastV2" &&
            (obj.mtbBroadcastV2 = ((_l = message.configOneof) === null || _l === void 0 ? void 0 : _l.mtbBroadcastV2)
                ? MTBBroadcastInputV2.toJSON((_m = message.configOneof) === null || _m === void 0 ? void 0 : _m.mtbBroadcastV2)
                : undefined);
        ((_o = message.configOneof) === null || _o === void 0 ? void 0 : _o.$case) === "recommendationBroadcastV1" &&
            (obj.recommendationBroadcastV1 = ((_p = message.configOneof) === null || _p === void 0 ? void 0 : _p.recommendationBroadcastV1)
                ? RecommendationBroadcastInputV1.toJSON((_q = message.configOneof) === null || _q === void 0 ? void 0 : _q.recommendationBroadcastV1)
                : undefined);
        return obj;
    },
};
function createBaseCampaignConfigInput() {
    return { name: "", type: 0, id: "", flowDocumentId: "", language: "" };
}
export const CampaignConfigInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.id !== "") {
            writer.uint32(26).string(message.id);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(34).string(message.flowDocumentId);
        }
        if (message.language !== "") {
            writer.uint32(42).string(message.language);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignConfigInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.flowDocumentId = reader.string();
                    break;
                case 5:
                    message.language = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            type: isSet(object.type) ? campaignTypeFromJSON(object.type) : 0,
            id: isSet(object.id) ? String(object.id) : "",
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            language: isSet(object.language) ? String(object.language) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = campaignTypeToJSON(message.type));
        message.id !== undefined && (obj.id = message.id);
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.language !== undefined && (obj.language = message.language);
        return obj;
    },
};
function createBaseRecommendationBroadcastInputV1() {
    return { audienceId: "", attributionId: "", integrationId: "" };
}
export const RecommendationBroadcastInputV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.audienceId !== "") {
            writer.uint32(10).string(message.audienceId);
        }
        if (message.attributionId !== "") {
            writer.uint32(18).string(message.attributionId);
        }
        if (message.integrationId !== "") {
            writer.uint32(26).string(message.integrationId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecommendationBroadcastInputV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.audienceId = reader.string();
                    break;
                case 2:
                    message.attributionId = reader.string();
                    break;
                case 3:
                    message.integrationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            audienceId: isSet(object.audienceId) ? String(object.audienceId) : "",
            attributionId: isSet(object.attributionId)
                ? String(object.attributionId)
                : "",
            integrationId: isSet(object.integrationId)
                ? String(object.integrationId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.audienceId !== undefined && (obj.audienceId = message.audienceId);
        message.attributionId !== undefined &&
            (obj.attributionId = message.attributionId);
        message.integrationId !== undefined &&
            (obj.integrationId = message.integrationId);
        return obj;
    },
};
function createBaseWABroadcastInputV1() {
    return { waMessageTemplateId: "" };
}
export const WABroadcastInputV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWABroadcastInputV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        return obj;
    },
};
function createBaseSTBBroadcastInputV1() {
    return { messageTemplates: [] };
}
export const STBBroadcastInputV1 = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.messageTemplates) {
            STBBroadcastInputV1_MessageTemplateIdentifier.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastInputV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageTemplates.push(STBBroadcastInputV1_MessageTemplateIdentifier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messageTemplates: Array.isArray(object === null || object === void 0 ? void 0 : object.messageTemplates)
                ? object.messageTemplates.map((e) => STBBroadcastInputV1_MessageTemplateIdentifier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.messageTemplates) {
            obj.messageTemplates = message.messageTemplates.map((e) => e ? STBBroadcastInputV1_MessageTemplateIdentifier.toJSON(e) : undefined);
        }
        else {
            obj.messageTemplates = [];
        }
        return obj;
    },
};
function createBaseSTBBroadcastInputV1_MessageTemplateIdentifier() {
    return { waMessageTemplateId: "", stbName: "" };
}
export const STBBroadcastInputV1_MessageTemplateIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.stbName !== "") {
            writer.uint32(34).string(message.stbName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastInputV1_MessageTemplateIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 4:
                    message.stbName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            stbName: isSet(object.stbName) ? String(object.stbName) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.stbName !== undefined && (obj.stbName = message.stbName);
        return obj;
    },
};
function createBaseMTBBroadcastInputV1() {
    return { nodeHeadId: "", nodes: {} };
}
export const MTBBroadcastInputV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nodeHeadId !== "") {
            writer.uint32(10).string(message.nodeHeadId);
        }
        Object.entries(message.nodes).forEach(([key, value]) => {
            MTBBroadcastInputV1_NodesEntry.encode({ key: key, value }, writer.uint32(18).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeHeadId = reader.string();
                    break;
                case 2:
                    const entry2 = MTBBroadcastInputV1_NodesEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.nodes[entry2.key] = entry2.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeHeadId: isSet(object.nodeHeadId) ? String(object.nodeHeadId) : "",
            nodes: isObject(object.nodes)
                ? Object.entries(object.nodes).reduce((acc, [key, value]) => {
                    acc[key] = MTBBroadcastInputV1_MTBBroadcastNode.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.nodeHeadId !== undefined && (obj.nodeHeadId = message.nodeHeadId);
        obj.nodes = {};
        if (message.nodes) {
            Object.entries(message.nodes).forEach(([k, v]) => {
                obj.nodes[k] = MTBBroadcastInputV1_MTBBroadcastNode.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseMTBBroadcastInputV1_MTBBroadcastNode() {
    return { nodeId: "", waMessageTemplateId: "", mtbName: "", nextNodes: [] };
}
export const MTBBroadcastInputV1_MTBBroadcastNode = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nodeId !== "") {
            writer.uint32(10).string(message.nodeId);
        }
        if (message.waMessageTemplateId !== "") {
            writer.uint32(18).string(message.waMessageTemplateId);
        }
        if (message.mtbName !== "") {
            writer.uint32(26).string(message.mtbName);
        }
        for (const v of message.nextNodes) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputV1_MTBBroadcastNode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeId = reader.string();
                    break;
                case 2:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 3:
                    message.mtbName = reader.string();
                    break;
                case 4:
                    message.nextNodes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeId: isSet(object.nodeId) ? String(object.nodeId) : "",
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            mtbName: isSet(object.mtbName) ? String(object.mtbName) : "",
            nextNodes: Array.isArray(object === null || object === void 0 ? void 0 : object.nextNodes)
                ? object.nextNodes.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.nodeId !== undefined && (obj.nodeId = message.nodeId);
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.mtbName !== undefined && (obj.mtbName = message.mtbName);
        if (message.nextNodes) {
            obj.nextNodes = message.nextNodes.map((e) => e);
        }
        else {
            obj.nextNodes = [];
        }
        return obj;
    },
};
function createBaseMTBBroadcastInputV1_NodesEntry() {
    return { key: "", value: undefined };
}
export const MTBBroadcastInputV1_NodesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MTBBroadcastInputV1_MTBBroadcastNode.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputV1_NodesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MTBBroadcastInputV1_MTBBroadcastNode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? MTBBroadcastInputV1_MTBBroadcastNode.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? MTBBroadcastInputV1_MTBBroadcastNode.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseMTBBroadcastInputV2() {
    return { nodeHeadId: "", nodes: {} };
}
export const MTBBroadcastInputV2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nodeHeadId !== "") {
            writer.uint32(10).string(message.nodeHeadId);
        }
        Object.entries(message.nodes).forEach(([key, value]) => {
            MTBBroadcastInputV2_NodesEntry.encode({ key: key, value }, writer.uint32(18).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeHeadId = reader.string();
                    break;
                case 2:
                    const entry2 = MTBBroadcastInputV2_NodesEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.nodes[entry2.key] = entry2.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeHeadId: isSet(object.nodeHeadId) ? String(object.nodeHeadId) : "",
            nodes: isObject(object.nodes)
                ? Object.entries(object.nodes).reduce((acc, [key, value]) => {
                    acc[key] = MTBBroadcastInputNode.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.nodeHeadId !== undefined && (obj.nodeHeadId = message.nodeHeadId);
        obj.nodes = {};
        if (message.nodes) {
            Object.entries(message.nodes).forEach(([k, v]) => {
                obj.nodes[k] = MTBBroadcastInputNode.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseMTBBroadcastInputV2_NodesEntry() {
    return { key: "", value: undefined };
}
export const MTBBroadcastInputV2_NodesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MTBBroadcastInputNode.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputV2_NodesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MTBBroadcastInputNode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? MTBBroadcastInputNode.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? MTBBroadcastInputNode.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseVerifySendout() {
    return { inputCohortUrl: "" };
}
export const VerifySendout = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.inputCohortUrl !== "") {
            writer.uint32(10).string(message.inputCohortUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifySendout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputCohortUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        return obj;
    },
};
function createBaseSubmitSendout() {
    return { submitOneof: undefined };
}
export const SubmitSendout = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.submitOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now") {
            SendSendoutNow.encode(message.submitOneof.now, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.submitOneof) === null || _b === void 0 ? void 0 : _b.$case) === "schedule") {
            ScheduleSendout.encode(message.submitOneof.schedule, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitSendout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.submitOneof = {
                        $case: "now",
                        now: SendSendoutNow.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.submitOneof = {
                        $case: "schedule",
                        schedule: ScheduleSendout.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            submitOneof: isSet(object.now)
                ? { $case: "now", now: SendSendoutNow.fromJSON(object.now) }
                : isSet(object.schedule)
                    ? {
                        $case: "schedule",
                        schedule: ScheduleSendout.fromJSON(object.schedule),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.submitOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now" &&
            (obj.now = ((_b = message.submitOneof) === null || _b === void 0 ? void 0 : _b.now)
                ? SendSendoutNow.toJSON((_c = message.submitOneof) === null || _c === void 0 ? void 0 : _c.now)
                : undefined);
        ((_d = message.submitOneof) === null || _d === void 0 ? void 0 : _d.$case) === "schedule" &&
            (obj.schedule = ((_e = message.submitOneof) === null || _e === void 0 ? void 0 : _e.schedule)
                ? ScheduleSendout.toJSON((_f = message.submitOneof) === null || _f === void 0 ? void 0 : _f.schedule)
                : undefined);
        return obj;
    },
};
function createBaseUnscheduleSendout() {
    return {};
}
export const UnscheduleSendout = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUnscheduleSendout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseScheduleSendoutDto() {
    return { scheduleOneof: undefined };
}
export const ScheduleSendoutDto = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "schedule") {
            ScheduleSendout.encode(message.scheduleOneof.schedule, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.$case) === "unschedule") {
            UnscheduleSendout.encode(message.scheduleOneof.unschedule, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScheduleSendoutDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.scheduleOneof = {
                        $case: "schedule",
                        schedule: ScheduleSendout.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.scheduleOneof = {
                        $case: "unschedule",
                        unschedule: UnscheduleSendout.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            scheduleOneof: isSet(object.schedule)
                ? {
                    $case: "schedule",
                    schedule: ScheduleSendout.fromJSON(object.schedule),
                }
                : isSet(object.unschedule)
                    ? {
                        $case: "unschedule",
                        unschedule: UnscheduleSendout.fromJSON(object.unschedule),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "schedule" &&
            (obj.schedule = ((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.schedule)
                ? ScheduleSendout.toJSON((_c = message.scheduleOneof) === null || _c === void 0 ? void 0 : _c.schedule)
                : undefined);
        ((_d = message.scheduleOneof) === null || _d === void 0 ? void 0 : _d.$case) === "unschedule" &&
            (obj.unschedule = ((_e = message.scheduleOneof) === null || _e === void 0 ? void 0 : _e.unschedule)
                ? UnscheduleSendout.toJSON((_f = message.scheduleOneof) === null || _f === void 0 ? void 0 : _f.unschedule)
                : undefined);
        return obj;
    },
};
function createBaseVerifySendoutResult() {
    return {
        status: 0,
        message: "",
        entity: undefined,
        progress: undefined,
        startedAt: undefined,
        completedAt: undefined,
        error: undefined,
    };
}
export const VerifySendoutResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.entity !== undefined) {
            SendoutDto.encode(message.entity, writer.uint32(26).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(34).fork()).ldelim();
        }
        if (message.startedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(50).fork()).ldelim();
        }
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifySendoutResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.entity = SendoutDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            entity: isSet(object.entity)
                ? SendoutDto.fromJSON(object.entity)
                : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            startedAt: isSet(object.startedAt) ? String(object.startedAt) : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? SendoutDto.toJSON(message.entity)
                : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        message.startedAt !== undefined && (obj.startedAt = message.startedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        return obj;
    },
};
function createBaseSubmitSendoutResult() {
    return {
        status: 0,
        message: "",
        entity: undefined,
        progress: undefined,
        startedAt: undefined,
        completedAt: undefined,
        error: undefined,
    };
}
export const SubmitSendoutResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.entity !== undefined) {
            SendoutDto.encode(message.entity, writer.uint32(26).fork()).ldelim();
        }
        if (message.progress !== undefined) {
            ProgressStatus.encode(message.progress, writer.uint32(34).fork()).ldelim();
        }
        if (message.startedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.completedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(50).fork()).ldelim();
        }
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubmitSendoutResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.entity = SendoutDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.progress = ProgressStatus.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? mutationStatusFromJSON(object.status) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            entity: isSet(object.entity)
                ? SendoutDto.fromJSON(object.entity)
                : undefined,
            progress: isSet(object.progress)
                ? ProgressStatus.fromJSON(object.progress)
                : undefined,
            startedAt: isSet(object.startedAt) ? String(object.startedAt) : undefined,
            completedAt: isSet(object.completedAt)
                ? String(object.completedAt)
                : undefined,
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = mutationStatusToJSON(message.status));
        message.message !== undefined && (obj.message = message.message);
        message.entity !== undefined &&
            (obj.entity = message.entity
                ? SendoutDto.toJSON(message.entity)
                : undefined);
        message.progress !== undefined &&
            (obj.progress = message.progress
                ? ProgressStatus.toJSON(message.progress)
                : undefined);
        message.startedAt !== undefined && (obj.startedAt = message.startedAt);
        message.completedAt !== undefined &&
            (obj.completedAt = message.completedAt);
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        return obj;
    },
};
function createBaseSendoutExecutionDataInput() {
    return { dataOneof: undefined };
}
export const SendoutExecutionDataInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            SendoutExecutionDataV1.encode(message.dataOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "v1Input") {
            SendoutExecutionDataV1Input.encode(message.dataOneof.v1Input, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutExecutionDataInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.dataOneof = {
                        $case: "v1",
                        v1: SendoutExecutionDataV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.dataOneof = {
                        $case: "v1Input",
                        v1Input: SendoutExecutionDataV1Input.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            dataOneof: isSet(object.v1)
                ? { $case: "v1", v1: SendoutExecutionDataV1.fromJSON(object.v1) }
                : isSet(object.v1Input)
                    ? {
                        $case: "v1Input",
                        v1Input: SendoutExecutionDataV1Input.fromJSON(object.v1Input),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? SendoutExecutionDataV1.toJSON((_c = message.dataOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        ((_d = message.dataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "v1Input" &&
            (obj.v1Input = ((_e = message.dataOneof) === null || _e === void 0 ? void 0 : _e.v1Input)
                ? SendoutExecutionDataV1Input.toJSON((_f = message.dataOneof) === null || _f === void 0 ? void 0 : _f.v1Input)
                : undefined);
        return obj;
    },
};
function createBaseSendoutExecutionDataV1Input() {
    return { numDispatchedToAdd: 0, numCohortRecipients: undefined };
}
export const SendoutExecutionDataV1Input = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.numDispatchedToAdd !== 0) {
            writer.uint32(8).uint32(message.numDispatchedToAdd);
        }
        if (message.numCohortRecipients !== undefined) {
            UInt32Value.encode({ value: message.numCohortRecipients }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutExecutionDataV1Input();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.numDispatchedToAdd = reader.uint32();
                    break;
                case 2:
                    message.numCohortRecipients = UInt32Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            numDispatchedToAdd: isSet(object.numDispatchedToAdd)
                ? Number(object.numDispatchedToAdd)
                : 0,
            numCohortRecipients: isSet(object.numCohortRecipients)
                ? Number(object.numCohortRecipients)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.numDispatchedToAdd !== undefined &&
            (obj.numDispatchedToAdd = Math.round(message.numDispatchedToAdd));
        message.numCohortRecipients !== undefined &&
            (obj.numCohortRecipients = message.numCohortRecipients);
        return obj;
    },
};
function createBaseSendoutMetadataInput() {
    return { flowMd: undefined };
}
export const SendoutMetadataInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowMd !== undefined) {
            SendoutFlowMetadataInput.encode(message.flowMd, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutMetadataInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowMd = SendoutFlowMetadataInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowMd: isSet(object.flowMd)
                ? SendoutFlowMetadataInput.fromJSON(object.flowMd)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowMd !== undefined &&
            (obj.flowMd = message.flowMd
                ? SendoutFlowMetadataInput.toJSON(message.flowMd)
                : undefined);
        return obj;
    },
};
function createBaseSendoutFlowMetadataInput() {
    return { flowDocumentId: "", flowDocumentVersionId: "" };
}
export const SendoutFlowMetadataInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocumentId !== "") {
            writer.uint32(10).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(18).string(message.flowDocumentVersionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutFlowMetadataInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocumentId = reader.string();
                    break;
                case 2:
                    message.flowDocumentVersionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        return obj;
    },
};
function createBaseSendoutMarkDto() {
    return { sendoutId: "", running: "" };
}
export const SendoutMarkDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sendoutId !== "") {
            writer.uint32(10).string(message.sendoutId);
        }
        if (message.running !== "") {
            writer.uint32(18).string(message.running);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutMarkDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendoutId = reader.string();
                    break;
                case 2:
                    message.running = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendoutId: isSet(object.sendoutId) ? String(object.sendoutId) : "",
            running: isSet(object.running) ? String(object.running) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sendoutId !== undefined && (obj.sendoutId = message.sendoutId);
        message.running !== undefined && (obj.running = message.running);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
