import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_AGENT_DESCRIPTOR_REGISTER_URL =
  '/internal/v1/businesses/{input.agentDescriptor.businessId}/ai/agent_descriptor_v1/register/v1';

type MutationAgentDescriptorUrl = typeof MUTATION_AGENT_DESCRIPTOR_REGISTER_URL;

export type MutationAgentDescriptorResponse =
  Paths['aiV1'][MutationAgentDescriptorUrl]['post']['responses']['200']['schema'];

export type MutationAgentDescriptorQuery =
  Paths['aiV1'][MutationAgentDescriptorUrl]['post']['parameters']['body']['input'];

export type MutationAgentDescriptorParams = { businessId: string } & MutationAgentDescriptorQuery;

export type MutationAgentDescriptorOptions = Omit<
  UseMutationOptions<MutationAgentDescriptorResponse, ConnectlyError, MutationAgentDescriptorParams, unknown>,
  'mutationKey' | 'mutationFn'
>;
