import { Box, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React, { useEffect, useRef, useState } from 'react';
import { CarouselProps } from './types';

export const Carousel = ({ children, width = 340, color }: CarouselProps) => {
  const [current, setCurrent] = useState(0);
  const ref = useRef<HTMLElement | null>(null);
  const theme = useTheme();
  const { length } = children;
  const buttons = [...Array(length).keys()];

  const desired = (idx: number) => {
    setCurrent(idx);
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.style.transition = 'all 0.2s ease-in-out';
      ref.current.style.transform = `translateX(-${current}00%)`;
    }
  }, [current]);

  const defaultColor = color ?? theme.palette.secondary.main;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
        width: `${width}px`,
      }}
    >
      <Box sx={{ overflow: 'hidden', width: `${width + 10}px` }}>
        <Box ref={ref} sx={{ display: 'flex', gap: 2, p: 1 }}>
          {children}
        </Box>
      </Box>
      <Box sx={{ gap: 2, display: 'flex' }}>
        {buttons.map((button) => (
          <Box
            key={button}
            onClick={() => desired(button)}
            sx={{
              width: theme.spacing(1),
              height: theme.spacing(1),
              cursor: 'pointer',
              background: button === current ? theme.palette.secondary.dark : defaultColor,
              borderRadius: '50%',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
