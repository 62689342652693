import React, { FC } from 'react';
import { Label, TableRow, TableCell, Tooltip, IconButton, EventBusyIcon } from '@connectlyai-tenets/ui-styled-web';
import { RowProps } from './types';

const ScheduledRow: FC<RowProps> = ({ id, readableName, config, createdAt, scheduledAt, onUnschedule }) => (
  <TableRow>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
      }}
    >
      <Label variant="subtitle2">{readableName}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1" sx={{ color: 'success.light' }}>
        Scheduled
      </Label>
      <Label color="textSecondary" variant="body2">
        {scheduledAt && new Date(scheduledAt).toLocaleString()}
      </Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      {config?.numRecipients && <Label variant="body1">{config?.numRecipients}</Label>}
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
        textAlign: 'right',
      }}
    >
      <Tooltip title="Cancel Sendout" arrow placement="top">
        <IconButton onClick={() => onUnschedule?.(id as string)}>
          <EventBusyIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  </TableRow>
);

export default ScheduledRow;
