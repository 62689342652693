import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingState, OnboardingStep, ShopifyOnboardingOptions } from './types';

const initialState: OnboardingState = {
  businessName: '',
  businessProfilePicture: '',
  confirmation: false,
  isCurrentlyUsed: true,
  phoneNumber: '',
  shopifyOptions: undefined,
  step: 'phoneNumberSelector',
  useNewPhoneNumber: true,
};

export const slice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    goToNextStep: (state) => {
      switch (state.step) {
        case 'phoneNumberSelector': {
          state.step = state.useNewPhoneNumber ? 'businessName' : 'phoneNumberOld';
          break;
        }
        case 'phoneNumberOld': {
          state.step = 'businessName';
          break;
        }
        case 'businessName': {
          state.step = 'businessProfilePicture';
          break;
        }
        case 'businessProfilePicture': {
          state.step = 'connectWhatsApp';
          break;
        }
        case 'connectWhatsApp': {
          state.step = 'connectShopify';
          break;
        }
        case 'connectShopify': {
          state.step = 'payment';
          break;
        }
        default: {
          break;
        }
      }
    },
    goToPreviousStep: (state) => {
      switch (state.step) {
        case 'phoneNumberSelector': {
          break;
        }
        case 'phoneNumberOld': {
          state.step = 'phoneNumberSelector';
          break;
        }
        case 'businessName': {
          state.step = state.useNewPhoneNumber ? 'phoneNumberSelector' : 'phoneNumberOld';
          break;
        }
        case 'businessProfilePicture': {
          state.step = 'businessName';
          break;
        }
        case 'connectWhatsApp': {
          state.step = 'businessProfilePicture';
          break;
        }
        case 'connectShopify': {
          state.step = 'connectWhatsApp';
          break;
        }
        default: {
          break;
        }
      }
    },
    goToStep: (state, action: PayloadAction<OnboardingStep>) => {
      state.step = action.payload;
    },
    setBusinessName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setBusinessProfilePicture: (state, action: PayloadAction<string>) => {
      state.businessProfilePicture = action.payload;
    },
    setConfirmation: (state, action: PayloadAction<boolean>) => {
      state.confirmation = action.payload;
    },
    setIsCurrentlyUsed: (state, action: PayloadAction<boolean>) => {
      state.isCurrentlyUsed = action.payload;
    },
    setIsNewPhoneNumber: (state, action: PayloadAction<boolean>) => {
      state.useNewPhoneNumber = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setShopifyOptions: (state, action: PayloadAction<ShopifyOnboardingOptions>) => {
      state.shopifyOptions = action.payload;
    },
  },
});

export const {
  goToNextStep,
  goToPreviousStep,
  goToStep,
  setBusinessName,
  setBusinessProfilePicture,
  setConfirmation,
  setIsNewPhoneNumber,
  setPhoneNumber,
  setShopifyOptions,
} = slice.actions;

export default slice.reducer;
