import { Paths } from '@connectlyai-tenets/sdk';

export const CONVERSATION_OVERVIEW_URL = '/internal/v1/businesses/{businessId}/query_conversation_overview';

type ConversationOverviewURL = typeof CONVERSATION_OVERVIEW_URL;

export type ConversationOverviewQuery =
  Paths['billingV1'][ConversationOverviewURL]['post']['parameters']['body']['body'];

export type ConversationOverviewUrlParameters = { businessId: string };

export type ConversationOverviewResponse =
  Paths['billingV1'][ConversationOverviewURL]['post']['responses']['200']['schema'];
