import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { AudienceSegmentsOptions, AudienceSegmentsParameters, AudienceSegmentsResponse } from './types';

export const useAudienceSegmentsData = <TData = AudienceSegmentsResponse>(
  input: AudienceSegmentsParameters,
  options: AudienceSegmentsOptions<TData> = {},
) =>
  useQuery(
    ['audienceSegments'],
    () =>
      api.audienceV1('/internal/v1/businesses/{query.businessId}/query/audience_segments', 'post', {
        path: { 'query.businessId': input.businessId },
        body: { query: { ...input } },
      }),
    options,
  );
