import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import {
  MUTATION_AGENT_DESCRIPTOR_REGISTER_URL,
  MutationAgentDescriptorParams,
  MutationAgentDescriptorOptions,
} from './types';

export const useMutationAgentDescriptor = (options?: MutationAgentDescriptorOptions) => {
  return useMutation(
    [],
    ({ businessId, agentDescriptor }: MutationAgentDescriptorParams) =>
      api.aiV1(MUTATION_AGENT_DESCRIPTOR_REGISTER_URL, 'post', {
        path: { 'input.agentDescriptor.businessId': businessId },
        body: { input: { agentDescriptor } },
      }),
    options,
  );
};
