import { HeaderType, UnsentMessageTemplate, MediaTypeDict } from './types';

export const DEFAULT_HEADER_TYPE = HeaderType.Image;
export const DEFAULT_UNSENT_MESSAGE_TEMPLATE: () => UnsentMessageTemplate = () => ({
  id: '',
  templateComponents: [{ body: { text: { text: '' } } }],
  name: '',
  errors: undefined,
});

export const EMPTY_TEMPLATE_ID = null;
export const CREATE_NEW_TEMPLATE_ID = '';

export const MEDIA_TYPES_DICT: MediaTypeDict = {
  VARIABLE: 'Use a variable',
  MEDIA: 'Upload a file',
};
