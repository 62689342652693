import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { sofiaFreeTextAtom } from '@atoms/sofia';
import { FormControl, useTheme, TextField } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { InputFreeTextProps } from './types';

const useInputFreeText = ({ freeText, setFreeText }: InputFreeTextProps) => {
  const [text, setText] = useAtom(sofiaFreeTextAtom);
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newText = e.target.value;
      if (setFreeText) setFreeText(newText);
      else setText(newText);
    },
    [setFreeText, setText],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const placeholder = useMemo(() => {
    return t('inputFreeTextPlaceholder');
  }, [t]);
  return {
    placeholder,
    content: freeText || text,
    handleChange,
  };
};
export const InputFreeText = ({ freeText, setFreeText }: InputFreeTextProps) => {
  const { placeholder, content, handleChange } = useInputFreeText({ freeText, setFreeText });
  const theme = useTheme();
  return (
    <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
      <TextField
        autoFocus
        name="free-text"
        variant="outlined"
        margin="normal"
        value={content}
        placeholder={placeholder}
        fullWidth
        multiline
        onChange={handleChange}
        rows={15}
        sx={{
          m: 0,
          background: theme.palette.common.white,
        }}
      />
    </FormControl>
  );
};
