import { Paths } from '@connectlyai-tenets/sdk';

export type InstallWidgetParameters = {
  businessId: string;
};

export const INSTALL_WIDGET_URL = '/v1/businesses/{businessId}/organization/install_widget';

type InstallWidgetUrl = typeof INSTALL_WIDGET_URL;

export type InstallWidgetInput = Paths['profileV1'][InstallWidgetUrl]['post']['parameters']['body']['body'];

export type InstallWidgetVariables = InstallWidgetParameters & InstallWidgetInput;

export type InstallWidgetResponse = Paths['profileV1'][InstallWidgetUrl]['post']['responses']['200']['schema'];
