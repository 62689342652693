import React, { useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { businessNameAtom, useNewPhoneNumberAtom } from '@atoms/home';
import { Box, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { BusinessNameProps } from './types';
import { BUSINESS_NAME_MAX_LENGTH } from './constants';
import { useEffectOnce } from '../../../../../../hooks';

const usePhoneNumberOld = () => {
  const [businessName, setBusinessName] = useAtom(businessNameAtom);
  const useNewPhoneNumber = useAtomValue(useNewPhoneNumberAtom);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBusinessName(e.currentTarget.value);
    },
    [setBusinessName],
  );

  return {
    useNewPhoneNumber,
    businessName,
    onChange: handleChange,
  };
};

export const BusinessName = ({ claimedPhoneNumber, claimNumber }: BusinessNameProps) => {
  const { useNewPhoneNumber, businessName, onChange } = usePhoneNumberOld();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessName' });
  useEffectOnce(() => {
    if (useNewPhoneNumber && !claimedPhoneNumber) {
      claimNumber();
    }
  });

  return (
    <Box>
      <Label>{t('title')}</Label>
      <TextField
        autoFocus
        autoComplete="off"
        placeholder={t('enterBusinessName') || ''}
        fullWidth
        value={businessName}
        onChange={onChange}
        variant="outlined"
        inputProps={{ maxLength: BUSINESS_NAME_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${businessName.length}/${BUSINESS_NAME_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Box>
  );
};
