import React, { useCallback } from 'react';
import {
  alpha,
  Box,
  Button,
  ConnectlyDeleteIcon,
  Label,
  Stack,
  TextField,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';
import { usePartialAgentDescriptor } from './usePartialAgentDescriptor';

export const QuickResponses = () => {
  const {
    value: responses,
    setValue: setResponses,
    onSave,
  } = usePartialAgentDescriptor<string[]>({
    keyPath: 'defaultCustomerQuestions',
    defaultValue: ['Fancy summer dress 👗', 'Minimal white sneakers 👟', 'Cocktail party costume 👨‍💼'],
  });

  const addResponse = useCallback(() => {
    setResponses([...responses, '']);
  }, [responses, setResponses]);

  const removeResponse = useCallback(
    (index: number) => {
      setResponses(responses.filter((_, i) => i !== index));
    },
    [responses, setResponses],
  );

  const updateResponse = useCallback(
    (index: number, value: string) => {
      setResponses(responses.map((response, i) => (i === index ? value : response)));
    },
    [responses, setResponses],
  );

  return (
    <SaveActionCard
      onSubmit={onSave}
      title="Quick responses"
      subtitle="Quick responses will display once at the start of the conversation for fast customer engagement with pre-determined answers. Customers can always use WebSearch as an alternative."
    >
      <Label variant="body1" sx={{ fontWeight: 700, mt: 2 }}>
        Preview
      </Label>
      <Box
        sx={{
          pt: '76px',
          pb: '60px',
          background: '#F5F5F5',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Label sx={{ fontWeight: 700, mb: 2.5, fontSize: '24px' }}>Hello 👋, what can we help you find today?</Label>
        {responses.map((response) => (
          <Box
            key={response}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
              border: (theme) => `1px ${alpha(theme.palette.common.black, 0.2)}`,
              borderStyle: 'dashed',
              background: (theme) => `1px ${alpha(theme.palette.common.black, 0.05)}`,
              height: '80px',
              width: '500px',
            }}
          >
            {response}
          </Box>
        ))}
      </Box>

      <Stack direction="column" spacing={3} mt={1}>
        {responses.map((response, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack direction="row" spacing={2} key={index} alignItems="center">
            <TextField
              value={response}
              onChange={(e) => updateResponse(index, e.target.value)}
              fullWidth
              placeholder="Enter response to allow"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{
                mt: 1.5,
                '& .MuiInputBase-root': { borderRadius: '10px' },
                '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
              }}
            />
            {responses.length > 1 && (
              <Box onClick={() => removeResponse(index)}>
                <ConnectlyDeleteIcon />
              </Box>
            )}
          </Stack>
        ))}
        <Stack direction="row" spacing={3} mt={1}>
          <Button variant="outlined" color="secondary" sx={{ mt: 1.5 }} onClick={addResponse}>
            Add new response
          </Button>
        </Stack>
      </Stack>
    </SaveActionCard>
  );
};
