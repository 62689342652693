import { businessKeyDialogOpenForAtom, isKeysVisibleAtom } from '@atoms/settings';
import { useSetAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { selectBusinessId, selectRole, useBusinessKeyData, useMeData } from '@hooks';
import { Key } from './types';

export const useWhatsappAPIKey = () => {
  const setKeysVisible = useSetAtom(isKeysVisibleAtom);
  const setBusinessKeyDialogOpenFor = useSetAtom(businessKeyDialogOpenForAtom);
  const [enableFetchData, setFetchData] = useState<string[]>([]);

  const handleRevealKeysButton = () => {
    setKeysVisible(true);
  };
  const handleDialogCancel = () => {
    setBusinessKeyDialogOpenFor(undefined);
  };
  const handleSetDialogOpenFor = (key: Key) => {
    setBusinessKeyDialogOpenFor(key);
  };
  const handleDialogAgree = (key: Key) => {
    setFetchData([...enableFetchData, key.type]);
    setBusinessKeyDialogOpenFor(undefined);
  };

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: role } = useMeData({ select: selectRole });
  const isOwner = useMemo(() => role === 'ROLE_OWNER', [role]);
  const { data: apiKeyData } = useBusinessKeyData({
    entityBusinessId: businessId ?? '',
    type: 'BUSINESS_KEY_TYPE_API_KEY',
    enabled: enableFetchData.includes('API Key') && !!businessId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  const { data: whatsappKeyData } = useBusinessKeyData({
    entityBusinessId: businessId ?? '',
    type: 'BUSINESS_KEY_TYPE_WEBHOOK_SECRET',
    enabled: enableFetchData.includes('Webhook Secret') && !!businessId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const keys = useMemo(() => {
    return [
      {
        type: 'API Key',
        key: apiKeyData?.entity?.key ?? '',
      },
      { type: 'Webhook Secret', key: whatsappKeyData?.entity?.key ?? '' },
      { type: 'Business ID', key: businessId ?? '' },
    ];
  }, [businessId, apiKeyData, whatsappKeyData]);

  const handleCopyKey = (key: Key) => {
    navigator.clipboard.writeText(key.key);
  };

  return {
    handleCopyKey,
    handleRevealKeysButton,
    handleDialogAgree,
    handleDialogCancel,
    handleSetDialogOpenFor,
    keys,
    isOwner,
  };
};
