import React, { useMemo, useEffect, useCallback } from 'react';
import { selectedPhoneListAudienceAtom, selectedSmartAudienceAtom, cohortsGeneratingAtom } from '@atoms/audience';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  WithSkeleton,
  Stack,
  Label,
  Tooltip,
  GroupIcon,
  Autocomplete,
  TextField,
  ListItem,
} from '@connectlyai-tenets/ui-styled-web';
import {
  selectLatestCohort,
  useAudienceSegmentCohorts,
  AudienceSegment,
  useAudienceSegmentsData,
  selectPhoneListSegments,
  selectMostEngagedSegments,
} from '@hooks';
import { formatDate } from '../../utils';
import { SelectAudienceProps } from './types';

const useAudienceTypeAtom = ({ type }: { type: 'dynamicV2' | 'phoneList' }): [string, (id: string) => void] => {
  const [phoneListId, setPhoneListId] = useAtom(selectedPhoneListAudienceAtom);
  const [smartId, setSmartId] = useAtom(selectedSmartAudienceAtom);
  const [selected, setSelected] = useMemo(() => {
    if (type === 'dynamicV2') {
      return [smartId, setSmartId];
    }
    return [phoneListId, setPhoneListId];
  }, [phoneListId, setPhoneListId, smartId, setSmartId, type]);
  return [selected, setSelected];
};
const useAudienceOptions = ({ businessId, type }: { businessId: string; type: 'dynamicV2' | 'phoneList' }) => {
  const { data: phoneListSegments = [], isLoading: isLoadingPhoneListSegments } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectPhoneListSegments, enabled: !!businessId },
  );
  const { data: engagedSegments = [], isLoading: isLoadingEngagedSegments } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectMostEngagedSegments, enabled: !!businessId },
  );
  const { data, isLoading } = useMemo(() => {
    return {
      data: type === 'dynamicV2' ? engagedSegments : phoneListSegments,
      isLoading: type === 'dynamicV2' ? isLoadingEngagedSegments : isLoadingPhoneListSegments,
    };
  }, [type, engagedSegments, phoneListSegments, isLoadingEngagedSegments, isLoadingPhoneListSegments]);
  return { data, isLoading };
};

const AudienceMenuItem = ({
  name,
  size,
  tooltip,
  isLoading,
}: {
  name: string;
  size?: number;
  tooltip?: string;
  isLoading?: boolean;
}) => {
  return (
    <Stack direction="row" width="100%" justifyContent="space-between">
      <Tooltip title={tooltip || ''} placement="top">
        <Label noWrap>{name}</Label>
      </Tooltip>
      <Stack direction="row" alignItems="center" gap={0.5} color="text.secondary">
        <GroupIcon sx={{ width: '18px', height: '18px' }} />
        {isLoading ||
          (size !== undefined && (
            <WithSkeleton wrap={Boolean(isLoading)}>
              <Label>{isLoading ? 50 : size}</Label>
            </WithSkeleton>
          ))}
      </Stack>
    </Stack>
  );
};
const SmartAudienceMenuItem = ({
  businessId,
  segmentId,
  displayName,
}: {
  businessId: string;
  segmentId: string;
  displayName: string;
}) => {
  // get cohort data from segment, and render option as below
  const setCohortsGenerating = useSetAtom(cohortsGeneratingAtom);
  const { data: cohort, isLoading } = useAudienceSegmentCohorts(
    { businessId, segmentId },
    {
      select: selectLatestCohort,
      enabled: Boolean(businessId && segmentId),
      refetchInterval: (fetchedData, _) => {
        if (fetchedData?.id && fetchedData?.segmentId) {
          setCohortsGenerating((prev: string[]) => prev.filter((id: string) => id !== fetchedData.segmentId));
          return false;
        }
        return 5000;
      },
    },
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const tooltipTitle = useMemo(() => {
    const createdAt = cohort?.createdAt;
    return isLoading || !createdAt ? '' : `${t('generic.updatedAt')}: ${formatDate(createdAt)}`;
  }, [cohort?.createdAt, isLoading, t]);
  return <AudienceMenuItem name={displayName} isLoading={isLoading} size={cohort?.size} tooltip={tooltipTitle} />;
};

export const SelectAudience = ({
  id,
  businessId,
  inputPlaceholder,
  getOptionLabel,
  isLoading,
  audienceType,
}: SelectAudienceProps) => {
  const [selected, setSelected] = useAudienceTypeAtom({ type: audienceType });
  const { data: options } = useAudienceOptions({ businessId, type: audienceType });
  const { selectedSegment } = useMemo(() => {
    const match = options.find((x) => x.segment?.id === selected);
    return {
      selectedSegment: match,
    };
  }, [options, selected]);
  useEffect(() => {
    if (isLoading || !options || !options.length || selected) {
      return;
    }
    setSelected(options[0].segment?.id || '');
  }, [isLoading, options, selected, setSelected]);
  const onSegmentChange = useCallback(
    (newSegment: AudienceSegment | null) => {
      if (newSegment?.segment?.id) {
        setSelected(newSegment.segment.id);
      }
    },
    [setSelected],
  );
  return (
    <Autocomplete
      key={selectedSegment?.segment?.id}
      id={id}
      fullWidth
      loading={isLoading}
      value={selectedSegment}
      getOptionLabel={(option) => (getOptionLabel ? getOptionLabel(option) : option.segment?.name || '')}
      renderOption={(props, option, _state) => (
        <WithSkeleton wrap={Boolean(isLoading)}>
          <ListItem {...props} key={option.segment?.id}>
            {audienceType === 'dynamicV2' && (
              <SmartAudienceMenuItem
                businessId={businessId}
                segmentId={option.segment?.id || ''}
                displayName={getOptionLabel ? getOptionLabel(option) : option.segment?.name || ''}
              />
            )}
            {audienceType === 'phoneList' && (
              <AudienceMenuItem name={option.segment?.name || ''} size={option?.stats?.size || 0} />
            )}
          </ListItem>
        </WithSkeleton>
      )}
      options={options}
      onChange={(_, v) => v && onSegmentChange(v)}
      renderInput={(params) => {
        return (
          <TextField
            key={selectedSegment?.segment?.id}
            {...params}
            // error={Boolean(error)}
            // helperText={error}
            placeholder={inputPlaceholder || 'Select Audience'}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }}
      sx={{ mt: 1.5, '& .MuiInputBase-root': { p: '1px 6px', borderRadius: '4px' } }}
    />
  );
};
