import { map, filter } from 'rxjs/operators';
const WA_REGISTRATION_OTP_REGEX = /whatsapp.*code.*?([\d]+-?[\d]+)/i;
function isOtp(logger, channelId, roomDto) {
    var _a, _b;
    if (roomDto.events.length !== 1) {
        return false;
    }
    if (!roomDto.businessChannelsById[channelId]) {
        return false;
    }
    const event = roomDto.events[0];
    switch ((_b = (_a = event.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'message': {
            const { message } = event.connectlyEvent.eventOneof;
            return message.isOtp || false;
        }
        default:
            return false;
    }
}
function code(event) {
    var _a, _b;
    switch ((_b = (_a = event.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) {
        case 'message': {
            const { message } = event.connectlyEvent.eventOneof;
            const match = message.text.match(WA_REGISTRATION_OTP_REGEX);
            if (match) {
                return match[1];
            }
            return '';
        }
        default:
            return '';
    }
}
export const subscriptionOTPProvider = (logger, dtoUpdateAccessor) => (channelId) => dtoUpdateAccessor
    .subscriptionRoomDto().pipe(filter(isOtp.bind(undefined, logger, channelId)), map((roomDto) => code(roomDto.events[0])));
