import { useState, useEffect, useCallback } from 'react';
import { fetchQueryAgents } from '@hooks/useQueryAgents';
import { useAgentPerformanceProps, AgentPerformance, AgentPerformanceList } from './types';

export const useAgentPerformanceData = ({
  businessId,
  username,
  timeRanges,
}: useAgentPerformanceProps): {
  data: AgentPerformanceList;
  isLoading: boolean;
  error: string;
} => {
  const [data, setData] = useState<AgentPerformanceList>([]);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setIsFetching(true);
    const requestList = timeRanges.map(async (timeRange) => {
      return {
        timeRange,
        result: await fetchQueryAgents(
          { 'query.businessId': businessId },
          {
            timeRangeStart: timeRange.timeRangeStart,
            timeRangeEnd: timeRange.timeRangeEnd,
          },
        ),
      };
    });
    const allData = await Promise.all(requestList).catch(() => {
      setError('An error occurred while fetching your data.');
      return [];
    });
    const agentData = allData.map((item) => {
      const { timeRange, result } = item;
      const returnData: AgentPerformance = {
        timeRangeStart: timeRange.timeRangeStart,
        timeRangeEnd: timeRange.timeRangeEnd,
        conversations: undefined,
        avgResolutionTime: undefined,
      };
      if (result.entity && result.entity.items && result.entity.items.length) {
        const match = result.entity.items.find((agent) => agent.username === username);
        if (match) {
          returnData.conversations = Number(match.conversations);
          returnData.avgResolutionTime = Number(match.avgResolutionTime);
        }
      }
      return returnData;
    });
    setData(agentData);
    setIsFetching(false);
  }, [setData, setIsFetching, timeRanges, businessId]);

  useEffect(() => {
    if (!isFetching && timeRanges.length && businessId && username && !error) {
      loadData();
    }
  }, [JSON.stringify(timeRanges)]);

  return {
    data,
    isLoading: isFetching,
    error,
  };
};
