import { errorCodeFromJSON, errorCodeToJSON } from "../error/code";
import { Response } from "../../events/facebook/graphapi/v10_0/messenger/models";
import { MessageResponse } from "../../events/twilio/message/v20100401/models";
import { WhatsAppMessageResponse } from "../../events/facebook/graphapi/v12_0/whatsapp/models";
import { Error } from "../../events/facebook/graphapi/v10_0/error/models";
import { WhatsAppWebhookStatusError } from "../../events/facebook/webhook/v12_0/whatsapp/models";
import { Error as Error1 } from "../../events/twilio/v20100401/error/models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.platformapi";
export var PlatformError_Source;
(function (PlatformError_Source) {
    PlatformError_Source[PlatformError_Source["SOURCE_UNSPECIFIED"] = 0] = "SOURCE_UNSPECIFIED";
    PlatformError_Source[PlatformError_Source["SOURCE_TWILIO_API"] = 1] = "SOURCE_TWILIO_API";
    PlatformError_Source[PlatformError_Source["SOURCE_TWILIO_WEBHOOK"] = 2] = "SOURCE_TWILIO_WEBHOOK";
    PlatformError_Source[PlatformError_Source["SOURCE_GRAPH_API"] = 3] = "SOURCE_GRAPH_API";
    PlatformError_Source[PlatformError_Source["SOURCE_WHATSAPP_WEBHOOK"] = 4] = "SOURCE_WHATSAPP_WEBHOOK";
    PlatformError_Source[PlatformError_Source["SOURCE_WEBHOOK_DELIVERY"] = 5] = "SOURCE_WEBHOOK_DELIVERY";
    PlatformError_Source[PlatformError_Source["SOURCE_INTERNAL_CONNECTLY"] = 6] = "SOURCE_INTERNAL_CONNECTLY";
    PlatformError_Source[PlatformError_Source["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PlatformError_Source || (PlatformError_Source = {}));
export function platformError_SourceFromJSON(object) {
    switch (object) {
        case 0:
        case "SOURCE_UNSPECIFIED":
            return PlatformError_Source.SOURCE_UNSPECIFIED;
        case 1:
        case "SOURCE_TWILIO_API":
            return PlatformError_Source.SOURCE_TWILIO_API;
        case 2:
        case "SOURCE_TWILIO_WEBHOOK":
            return PlatformError_Source.SOURCE_TWILIO_WEBHOOK;
        case 3:
        case "SOURCE_GRAPH_API":
            return PlatformError_Source.SOURCE_GRAPH_API;
        case 4:
        case "SOURCE_WHATSAPP_WEBHOOK":
            return PlatformError_Source.SOURCE_WHATSAPP_WEBHOOK;
        case 5:
        case "SOURCE_WEBHOOK_DELIVERY":
            return PlatformError_Source.SOURCE_WEBHOOK_DELIVERY;
        case 6:
        case "SOURCE_INTERNAL_CONNECTLY":
            return PlatformError_Source.SOURCE_INTERNAL_CONNECTLY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PlatformError_Source.UNRECOGNIZED;
    }
}
export function platformError_SourceToJSON(object) {
    switch (object) {
        case PlatformError_Source.SOURCE_UNSPECIFIED:
            return "SOURCE_UNSPECIFIED";
        case PlatformError_Source.SOURCE_TWILIO_API:
            return "SOURCE_TWILIO_API";
        case PlatformError_Source.SOURCE_TWILIO_WEBHOOK:
            return "SOURCE_TWILIO_WEBHOOK";
        case PlatformError_Source.SOURCE_GRAPH_API:
            return "SOURCE_GRAPH_API";
        case PlatformError_Source.SOURCE_WHATSAPP_WEBHOOK:
            return "SOURCE_WHATSAPP_WEBHOOK";
        case PlatformError_Source.SOURCE_WEBHOOK_DELIVERY:
            return "SOURCE_WEBHOOK_DELIVERY";
        case PlatformError_Source.SOURCE_INTERNAL_CONNECTLY:
            return "SOURCE_INTERNAL_CONNECTLY";
        case PlatformError_Source.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBasePlatformResponse() {
    return { responseOneof: undefined };
}
export const PlatformResponse = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.responseOneof) === null || _a === void 0 ? void 0 : _a.$case) === "messenger") {
            Response.encode(message.responseOneof.messenger, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.responseOneof) === null || _b === void 0 ? void 0 : _b.$case) === "twilio") {
            MessageResponse.encode(message.responseOneof.twilio, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.responseOneof) === null || _c === void 0 ? void 0 : _c.$case) === "whatsapp") {
            WhatsAppMessageResponse.encode(message.responseOneof.whatsapp, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlatformResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.responseOneof = {
                        $case: "messenger",
                        messenger: Response.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.responseOneof = {
                        $case: "twilio",
                        twilio: MessageResponse.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.responseOneof = {
                        $case: "whatsapp",
                        whatsapp: WhatsAppMessageResponse.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            responseOneof: isSet(object.messenger)
                ? { $case: "messenger", messenger: Response.fromJSON(object.messenger) }
                : isSet(object.twilio)
                    ? { $case: "twilio", twilio: MessageResponse.fromJSON(object.twilio) }
                    : isSet(object.whatsapp)
                        ? {
                            $case: "whatsapp",
                            whatsapp: WhatsAppMessageResponse.fromJSON(object.whatsapp),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.responseOneof) === null || _a === void 0 ? void 0 : _a.$case) === "messenger" &&
            (obj.messenger = ((_b = message.responseOneof) === null || _b === void 0 ? void 0 : _b.messenger)
                ? Response.toJSON((_c = message.responseOneof) === null || _c === void 0 ? void 0 : _c.messenger)
                : undefined);
        ((_d = message.responseOneof) === null || _d === void 0 ? void 0 : _d.$case) === "twilio" &&
            (obj.twilio = ((_e = message.responseOneof) === null || _e === void 0 ? void 0 : _e.twilio)
                ? MessageResponse.toJSON((_f = message.responseOneof) === null || _f === void 0 ? void 0 : _f.twilio)
                : undefined);
        ((_g = message.responseOneof) === null || _g === void 0 ? void 0 : _g.$case) === "whatsapp" &&
            (obj.whatsapp = ((_h = message.responseOneof) === null || _h === void 0 ? void 0 : _h.whatsapp)
                ? WhatsAppMessageResponse.toJSON((_j = message.responseOneof) === null || _j === void 0 ? void 0 : _j.whatsapp)
                : undefined);
        return obj;
    },
};
function createBasePlatformError() {
    return {
        source: 0,
        code: "",
        subCode: "",
        message: "",
        nativeError: "",
        nativeErrorV2: undefined,
        httpCode: 0,
    };
}
export const PlatformError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.source !== 0) {
            writer.uint32(8).int32(message.source);
        }
        if (message.code !== "") {
            writer.uint32(18).string(message.code);
        }
        if (message.subCode !== "") {
            writer.uint32(26).string(message.subCode);
        }
        if (message.message !== "") {
            writer.uint32(34).string(message.message);
        }
        if (message.nativeError !== "") {
            writer.uint32(42).string(message.nativeError);
        }
        if (message.nativeErrorV2 !== undefined) {
            NativeError.encode(message.nativeErrorV2, writer.uint32(58).fork()).ldelim();
        }
        if (message.httpCode !== 0) {
            writer.uint32(48).int32(message.httpCode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlatformError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.source = reader.int32();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.subCode = reader.string();
                    break;
                case 4:
                    message.message = reader.string();
                    break;
                case 5:
                    message.nativeError = reader.string();
                    break;
                case 7:
                    message.nativeErrorV2 = NativeError.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.httpCode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            source: isSet(object.source)
                ? platformError_SourceFromJSON(object.source)
                : 0,
            code: isSet(object.code) ? String(object.code) : "",
            subCode: isSet(object.subCode) ? String(object.subCode) : "",
            message: isSet(object.message) ? String(object.message) : "",
            nativeError: isSet(object.nativeError) ? String(object.nativeError) : "",
            nativeErrorV2: isSet(object.nativeErrorV2)
                ? NativeError.fromJSON(object.nativeErrorV2)
                : undefined,
            httpCode: isSet(object.httpCode) ? Number(object.httpCode) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.source !== undefined &&
            (obj.source = platformError_SourceToJSON(message.source));
        message.code !== undefined && (obj.code = message.code);
        message.subCode !== undefined && (obj.subCode = message.subCode);
        message.message !== undefined && (obj.message = message.message);
        message.nativeError !== undefined &&
            (obj.nativeError = message.nativeError);
        message.nativeErrorV2 !== undefined &&
            (obj.nativeErrorV2 = message.nativeErrorV2
                ? NativeError.toJSON(message.nativeErrorV2)
                : undefined);
        message.httpCode !== undefined &&
            (obj.httpCode = Math.round(message.httpCode));
        return obj;
    },
};
function createBaseNativeError() {
    return { errorOneof: undefined };
}
export const NativeError = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        if (((_a = message.errorOneof) === null || _a === void 0 ? void 0 : _a.$case) === "graphapi") {
            Error.encode(message.errorOneof.graphapi, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.errorOneof) === null || _b === void 0 ? void 0 : _b.$case) === "waWebhookStatus") {
            WhatsAppWebhookStatusError.encode(message.errorOneof.waWebhookStatus, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.errorOneof) === null || _c === void 0 ? void 0 : _c.$case) === "twilio") {
            Error1.encode(message.errorOneof.twilio, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.errorOneof) === null || _d === void 0 ? void 0 : _d.$case) === "webhookDelivery") {
            WebhookDeliveryError.encode(message.errorOneof.webhookDelivery, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNativeError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.errorOneof = {
                        $case: "graphapi",
                        graphapi: Error.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.errorOneof = {
                        $case: "waWebhookStatus",
                        waWebhookStatus: WhatsAppWebhookStatusError.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.errorOneof = {
                        $case: "twilio",
                        twilio: Error1.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.errorOneof = {
                        $case: "webhookDelivery",
                        webhookDelivery: WebhookDeliveryError.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            errorOneof: isSet(object.graphapi)
                ? { $case: "graphapi", graphapi: Error.fromJSON(object.graphapi) }
                : isSet(object.waWebhookStatus)
                    ? {
                        $case: "waWebhookStatus",
                        waWebhookStatus: WhatsAppWebhookStatusError.fromJSON(object.waWebhookStatus),
                    }
                    : isSet(object.twilio)
                        ? { $case: "twilio", twilio: Error1.fromJSON(object.twilio) }
                        : isSet(object.webhookDelivery)
                            ? {
                                $case: "webhookDelivery",
                                webhookDelivery: WebhookDeliveryError.fromJSON(object.webhookDelivery),
                            }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        ((_a = message.errorOneof) === null || _a === void 0 ? void 0 : _a.$case) === "graphapi" &&
            (obj.graphapi = ((_b = message.errorOneof) === null || _b === void 0 ? void 0 : _b.graphapi)
                ? Error.toJSON((_c = message.errorOneof) === null || _c === void 0 ? void 0 : _c.graphapi)
                : undefined);
        ((_d = message.errorOneof) === null || _d === void 0 ? void 0 : _d.$case) === "waWebhookStatus" &&
            (obj.waWebhookStatus = ((_e = message.errorOneof) === null || _e === void 0 ? void 0 : _e.waWebhookStatus)
                ? WhatsAppWebhookStatusError.toJSON((_f = message.errorOneof) === null || _f === void 0 ? void 0 : _f.waWebhookStatus)
                : undefined);
        ((_g = message.errorOneof) === null || _g === void 0 ? void 0 : _g.$case) === "twilio" &&
            (obj.twilio = ((_h = message.errorOneof) === null || _h === void 0 ? void 0 : _h.twilio)
                ? Error1.toJSON((_j = message.errorOneof) === null || _j === void 0 ? void 0 : _j.twilio)
                : undefined);
        ((_k = message.errorOneof) === null || _k === void 0 ? void 0 : _k.$case) === "webhookDelivery" &&
            (obj.webhookDelivery = ((_l = message.errorOneof) === null || _l === void 0 ? void 0 : _l.webhookDelivery)
                ? WebhookDeliveryError.toJSON((_m = message.errorOneof) === null || _m === void 0 ? void 0 : _m.webhookDelivery)
                : undefined);
        return obj;
    },
};
function createBaseWebhookDeliveryError() {
    return { code: 0, message: "", httpCode: 0, httpBody: "" };
}
export const WebhookDeliveryError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).int32(message.code);
        }
        if (message.message !== "") {
            writer.uint32(26).string(message.message);
        }
        if (message.httpCode !== 0) {
            writer.uint32(32).int32(message.httpCode);
        }
        if (message.httpBody !== "") {
            writer.uint32(42).string(message.httpBody);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWebhookDeliveryError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.int32();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.httpCode = reader.int32();
                    break;
                case 5:
                    message.httpBody = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? errorCodeFromJSON(object.code) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            httpCode: isSet(object.httpCode) ? Number(object.httpCode) : 0,
            httpBody: isSet(object.httpBody) ? String(object.httpBody) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = errorCodeToJSON(message.code));
        message.message !== undefined && (obj.message = message.message);
        message.httpCode !== undefined &&
            (obj.httpCode = Math.round(message.httpCode));
        message.httpBody !== undefined && (obj.httpBody = message.httpBody);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
