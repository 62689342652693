import { Node, Edge, ReactFlowInstance } from 'react-flow-renderer';
import { DragEvent } from 'react';
import { NodeType } from '../../sdks/flow/createNode';

export const useReadOnlyFlowHandlers = () => {
  return {
    createDuplicateHandler: (_: string) => (_2: React.MouseEvent<HTMLElement>) => {},
    createDeleteHandler: (_: string) => (_2: React.MouseEvent<HTMLElement>) => {},
    createDeleteEdgeHandler: (_: string) => (_2: React.MouseEvent<HTMLElement>) => {},
    createEditHandler: (_: string) => (_2: React.MouseEvent<HTMLElement>) => {},
    handleConnect: () => {},
    handleDragOver: (_: DragEvent<HTMLDivElement>) => {},
    handleDrop: (_: DragEvent<HTMLDivElement>, _2: ReactFlowInstance | undefined, _3: HTMLDivElement | null) => {},
    handleEdgeUpdateStart: () => {},
    handleEdgeUpdate: () => {},
    handleEdgeUpdateEnd: (_event: MouseEvent, _: Edge) => {},
    handleNodeCreate: (_: NodeType, _2: ReactFlowInstance | undefined, _3: HTMLDivElement | null) => () => {},
    handleNodesDelete: (_: Node[]) => {},
  };
};
