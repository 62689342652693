import React, { FC, useMemo } from 'react';
import {
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Label,
  CircularProgress,
  Alert,
} from '@connectlyai-tenets/ui-styled-web';
import { useFlowDocumentData } from '../../hooks/useFlowDocumentData';
import { SIZE_CONTENT_L, SIZE_CONTENT_S } from '../../ui-theme';
import { selectBusinessId, useMeData } from '../../hooks';
import { CampaignAPIDialogV2 } from './CampaignAPIDialogV2';
import { CampaignAPIDialogV3 } from './CampaignAPIDialogV3';
import { CampaignAPIDialogProps } from './types';

export const useCampaignAPIDialog = ({ open = false, flowDocumentId, onClose }: Partial<CampaignAPIDialogProps>) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: flowDocument, isLoading } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: flowDocumentId || '',
    enabled: !!businessId && !!flowDocumentId,
  });

  const isV2Supported = useMemo(
    () => flowDocument?.entity?.type === 'FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1',
    [flowDocument],
  );
  const isV3Supported = useMemo(() => flowDocument?.entity?.type === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3', [flowDocument]);

  const isOpen = open || !!flowDocumentId;

  return {
    isOpen,
    isOpenLock: open,
    isLoading,
    isV2Supported,
    isV3Supported,
    handleClose: onClose,
  };
};
export const CampaignAPIDialog: FC<CampaignAPIDialogProps> = (props) => {
  const { isOpen, isOpenLock, isLoading, isV2Supported, isV3Supported, handleClose } = useCampaignAPIDialog(props);

  return (
    <Dialog open={isOpen} onClose={isOpenLock ? undefined : handleClose} maxWidth="xl">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Label variant="h5">Send Campaign via API</Label>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {isLoading && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_L),
          }}
        >
          <CircularProgress />
        </DialogContent>
      )}

      {!isLoading && isV2Supported && <CampaignAPIDialogV2 {...props} />}
      {!isLoading && isV3Supported && <CampaignAPIDialogV3 {...props} />}

      {!isLoading && !isV2Supported && !isV3Supported && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_S),
          }}
        >
          <Alert severity="warning" sx={{ width: 'fit-content', mx: 'auto', mt: 2 }}>
            <Label variant="subtitle1">Sorry, this campaign is not supported in API Builder.</Label>
          </Alert>
        </DialogContent>
      )}
    </Dialog>
  );
};
