import { SettingsResponse } from '@hooks/useSettingsData/types';
import {
  selectWAIsActive,
  selectSMSNumber,
  selectShopifyChannel,
  selectShopifyRecommender,
  selectShopifyShopName,
} from '@hooks';
import { SMBWrapperView } from './types';
import { DEFAULT_SMB_WRAPPER_VIEW } from './constants';

export const selectSMBWrapperView = (data: SettingsResponse): SMBWrapperView => {
  const entity = data?.entity;
  const shopifyChannel = selectShopifyChannel(data);
  if (!entity) return DEFAULT_SMB_WRAPPER_VIEW;
  const shopifyRecommender = selectShopifyRecommender(data);
  const storeName = selectShopifyShopName(data);

  return {
    shopifyIsActive: shopifyChannel ? Boolean(shopifyChannel.accessToken) : false,
    recommendationIntegration: shopifyRecommender || {},
    whatsAppIsActive: selectWAIsActive(data),
    whatsAppClaimedNumber: selectSMSNumber(data),
    storeName,
  };
};
