import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { RATE_LIMIT_QUERY_URL, RateLimitQueryOptions, RateLimitQueryParams, RateLimitQueryResponse } from './types';
import { DEFAULT_QUERY_RESOURCE } from './constants';

export const useResourceRateLimit = <TData = RateLimitQueryResponse>(
  { businessId, resource = DEFAULT_QUERY_RESOURCE }: RateLimitQueryParams,
  options: RateLimitQueryOptions<TData> = {},
) =>
  useQuery(
    ['rate-limit'],
    () =>
      api.profileV1(RATE_LIMIT_QUERY_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { resource } },
      }),
    options,
  );
