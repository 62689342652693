import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SendoutsQuery, SendoutsResponse, SendoutsUrlParameters } from './types';

export const useSendoutsData = <TData = SendoutsResponse>({
  channelType = 'CHANNEL_TYPE_WHATSAPP_CLOUD',
  businessId = '',
  states = ['SENDOUT_STATE_UNSPECIFIED'],
  types,
  substates,
  search,
  orderBy,
  campaignId,
  ...options
}: Omit<
  UseQueryOptions<SendoutsResponse, unknown, TData, readonly ['sendouts', SendoutsUrlParameters, SendoutsQuery]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<SendoutsQuery> &
  SendoutsUrlParameters) => {
  return useQuery(
    ['sendouts', { businessId }, { channelType, states, types, substates, campaignId, search, orderBy }],
    () =>
      api.campaignV2('/internal/v2/businesses/{businessId}/list/sendouts', 'post', {
        path: { businessId },
        body: { entity: { channelType, states, types, substates, campaignId, search, orderBy } },
      }),
    options,
  );
};
