import { ReactFlowInstance, Node } from 'react-flow-renderer';
import { createContext } from 'use-context-selector';
import { UseVerifySendoutResultData } from '../../../hooks/useVerifySendoutResultData';

export type FlowCampaignDeps = {
  centerToNode: (node: Node) => void;
  goToNextState: () => void;
  goToPreviousState: () => void;
  reactFlowInstance?: ReactFlowInstance;
  handleVerifySpreadsheet: () => void;
  verifySendoutResultData: UseVerifySendoutResultData;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FlowCampaignContext = createContext<FlowCampaignDeps>(undefined!);
