import React, { useMemo } from 'react';
import {
  Box,
  ConnectlyCard,
  FilterAltIcon,
  Label,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { SofiaAnalyticsFunnelCategory } from '@scenes/Sofia/types';
import { useAnalysisDataInRanges } from '../../hooks';
import { selectFunnelAnalysis } from '../../selectors';

const useFunnelAnalysis = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('funnelAnalysis.title', 'Sales Funnel'),
      subtitle: t('funnelAnalysis.subtitle', 'Learn how your customers are distributed accross your sales funnel.'),
    };
  }, [t]);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const analyticsData = useAnalysisDataInRanges({});
  const rawData = useMemo(() => {
    return selectFunnelAnalysis(analyticsData);
  }, [analyticsData]);
  const data = useMemo(() => {
    const categories: SofiaAnalyticsFunnelCategory[] = [
      'all',
      'awareness',
      'consideration',
      'decision',
      'postPurchase',
      'spam',
      'undefined',
    ];
    return categories.map((category) => {
      let changeIcon: React.ReactNode = null;
      let changeLabel = '';
      const { change } = rawData[category];
      const iconStyles = {
        width: theme.spacing(isLg ? 2.5 : 2),
        height: theme.spacing(isLg ? 2.5 : 2),
        color: theme.palette.grey[500],
        mr: 0.5,
      };
      if (change) {
        iconStyles.color = change > 0 ? theme.palette.success.main : theme.palette.error.main;
        changeIcon = change > 0 ? <TrendingUpIcon sx={iconStyles} /> : <TrendingDownIcon sx={iconStyles} />;
        changeLabel = t('funnelAnalysis.changeLabel', {
          sign: change > 0 ? '+' : '',
          change: Math.round(change),
          defaultValue: `${change > 0 ? '+' : ''}${change} from last period`,
        });
      } else if (change === 0) {
        changeLabel = t('noChange', 'no change');
      }
      return {
        id: category,
        title: t(`funnelAnalysis.categories.${category}.title`, category),
        subtitle: t(`funnelAnalysis.categories.${category}.subtitle`, ''),
        unit:
          rawData[category].count === 1
            ? t('funnelAnalysis.unit', 'session')
            : t('funnelAnalysis.unitPlural', 'sessions'),
        data: rawData[category],
        changeIcon,
        changeLabel,
      };
    });
  }, [isLg, rawData, t, theme]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return {
    theme,
    title,
    subtitle,
    data,
    isMd,
  };
};

export const FunnelAnalysis = () => {
  const { theme, title, subtitle, data, isMd } = useFunnelAnalysis();
  return (
    <ConnectlyCard
      sx={{ flexDirection: 'column', userSelect: 'none' }}
      icon={<FilterAltIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />}
      title={title}
      subtitle={subtitle}
      isNew
      contentSx={{ flexWrap: isMd ? 'nowrap' : 'wrap', flexDirection: 'row' }}
    >
      <Stack width="100%">
        {data.map(
          ({ id, title: catTitle, subtitle: catSubtitle, unit, changeIcon, changeLabel, data: catData }, index) => {
            return (
              <Stack alignItems="center" direction="row" gap={2} key={id} width="100%">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: theme.spacing(5),
                    height: theme.spacing(5),
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: '50%',
                  }}
                >
                  <Label variant="body1" sx={{ fontWeight: 'bold !important' }}>
                    {index}
                  </Label>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  height={theme.spacing(12)}
                  sx={{
                    flex: '1 1 auto',
                    borderBottom: index < data.length - 1 ? `1px solid ${theme.palette.grey[300]}` : 'none',
                  }}
                >
                  <Box
                    sx={{
                      width: theme.spacing(16.5),
                      height: theme.spacing(5),
                      borderRadius: theme.spacing(1),
                      backgroundColor: '#F8FAFC',
                      border: '1px solid #E2E8F0',
                    }}
                  >
                    <Box
                      sx={{
                        width: `${Math.round(catData.percent)}%`,
                        height: `100%`,
                        borderRadius: theme.spacing(1),
                        backgroundColor: '#CBD5E1',
                      }}
                    />
                  </Box>
                  <Stack sx={{ flex: '1 1 auto' }}>
                    <Label
                      variant="body1"
                      sx={{ fontWeight: 'bold !important' }}
                    >{`${Math.round(catData.percent * 10) / 10}% ${catTitle}`}</Label>
                    <Label variant="body1" color="text.secondary">
                      {catSubtitle}
                    </Label>
                    <Label variant="body1" color="text.secondary">
                      {`${catData.count} ${unit}`}
                    </Label>
                  </Stack>
                  <Stack direction="row" gap={0.5} sx={{ flex: '0 0 25%' }}>
                    {changeIcon}
                    <Label variant="body1" color="text.secondary">
                      {changeLabel}
                    </Label>
                  </Stack>
                </Stack>
              </Stack>
            );
          },
        )}
      </Stack>
    </ConnectlyCard>
  );
};
