import React, { FC, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  useTheme,
  Autocomplete,
} from '@connectlyai-tenets/ui-styled-web';
import { useSelector, useDispatch } from 'react-redux';
import { CAMPAIGN_NAME_MAX_LENGTH } from './constants';
import { CampaignNameHintColor } from '../../hooks/useCampaignName/types';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { Languages as LanguageSource, getLanguage } from '../../utils';
import { selectLanguage, setLanguage } from '../../state/flow';

type FlowChartCampaignNameDialogProps = {
  campaignName: string;
  campaignNameColor: CampaignNameHintColor;
  campaignNameHint: string;
  isConfirmDisabled: boolean;
  onCancel: () => void;
  onSave: () => void;
  open: boolean;
  setCampaignName: (name: string) => void;
};

const useCampaignNameDialog = (): {
  language: string;
  handleLanguageChange: (_event: React.BaseSyntheticEvent, newValue: string | null) => void;
} => {
  const dispatch = useDispatch();

  const language = useSelector(selectLanguage);

  const handleLanguageChange = useCallback(
    (_event: React.BaseSyntheticEvent, newValue: string | null) => {
      if (!newValue) return;
      dispatch(setLanguage(newValue));
    },
    [dispatch],
  );
  return {
    language,
    handleLanguageChange,
  };
};

export const FlowChartCampaignNameDialog: FC<FlowChartCampaignNameDialogProps> = ({
  campaignName,
  campaignNameColor,
  campaignNameHint,
  isConfirmDisabled,
  onCancel,
  onSave,
  open,
  setCampaignName,
}) => {
  const theme = useTheme();
  const { language, handleLanguageChange } = useCampaignNameDialog();

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>Campaign name</DialogTitle>
      <DialogContent
        sx={{
          minHeight: '92px',
          width: theme.spacing(SIZE_CONTENT_L),
        }}
      >
        <TextField
          autoFocus
          fullWidth
          placeholder="Campaign name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          error={campaignNameColor === 'error'}
          color={campaignNameColor}
          helperText={campaignNameHint || ' '}
          inputProps={{
            maxLength: CAMPAIGN_NAME_MAX_LENGTH,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{`${campaignName.length}/${CAMPAIGN_NAME_MAX_LENGTH}`}</InputAdornment>
            ),
          }}
          sx={{
            mt: 2.5,
            mb: 1,
            '& .MuiInputBase-input': { p: '11px 12px' },
            '& .MuiInputBase-root': {
              pr: '12px',
              borderRadius: '10px',
              '& fieldset': {
                borderColor: campaignNameColor === 'success' ? 'green' : '',
              },
            },
            '& .MuiFormHelperText-root': { color: campaignNameColor === 'success' ? 'green' : '' },
          }}
        />
        <Autocomplete
          id="campaign-name-language"
          fullWidth
          value={language}
          getOptionLabel={(x) => getLanguage(x)}
          options={LanguageSource}
          onChange={handleLanguageChange}
          renderInput={(params) => <TextField {...params} placeholder="Select language" variant="outlined" />}
          sx={{ '& .MuiInputBase-root': { p: '3.5px 6px', borderRadius: '10px' } }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isConfirmDisabled} onClick={onSave}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
