import React, { FC, useMemo } from 'react';
import { Loader } from '@connectlyai-sdks/components';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { Provider as StateProvider } from 'jotai';
import { useSelector, useDispatch } from 'react-redux';
import {
  useMeData,
  useFlowNavigator,
  useFlowDocumentData,
  selectBusinessId,
  useLogger,
  useEffectOnce,
  useFeatureFlag,
} from '@hooks';
import { FlowChart } from './FlowChart';
import { FlowChartAutoReplyV1 } from './autoreplyv1/FlowChartAutoReplyV1';
import { FlowChartGetStarted } from '../FlowChartGetStarted';
import {
  selectCampaignName,
  selectCategory,
  selectDocumentId,
  selectFlowState,
  selectSendoutId,
  setCategory,
  setPreviewDocumentId,
} from '../../state/flow';
import { selectDocumentId as selectDocumentIdAutoReply } from '../../features/flow-autoreply/state';
import { LoggerContext } from '../../contexts';
import { FlowChartCampaignV3 } from '../../components/FlowChartCampaignV3';
import { FlowWrapper } from '../../components/FlowChartCampaignV3/components/FlowWrapper';

export const FlowChartWrap: FC = () => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);

  const { flowDocumentIdRouteMatch } = useFlowNavigator();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const campaignName = useSelector(selectCampaignName);
  const category = useSelector(selectCategory);
  const documentId = useSelector(selectDocumentId);
  const flowState = useSelector(selectFlowState);
  const sendoutId = useSelector(selectSendoutId);

  const { sugar } = useLogger();

  const { loggerDeps: campaignLoggerDeps } = useMemo(
    () =>
      sugar({
        logger: 'FlowChart',
        campaign_name: campaignName,
        flow_id: documentId,
        flow_state: flowState,
        sendout_id: sendoutId,
      }),
    [campaignName, documentId, flowState, sendoutId, sugar],
  );

  const documentIdAutoReply = useSelector(selectDocumentIdAutoReply);
  const { loggerDeps: autoReplyLoggerDeps } = useMemo(
    () =>
      sugar({
        logger: 'FlowChartAutoReplyV1',
        flow_id: documentIdAutoReply,
      }),
    [documentIdAutoReply, sugar],
  );

  const {
    data: document,
    isLoading: isLoadingDocument,
    isSuccess: isSuccessLoadingDocument,
    isError: isErrorLoadingDocument,
    error: errorLoadingDocument,
  } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: flowDocumentIdRouteMatch?.params.flowDocumentId || documentId,
    enabled:
      flowDocumentIdRouteMatch != null && businessId !== undefined && !!flowDocumentIdRouteMatch?.params.flowDocumentId,
  });
  const dispatch = useDispatch();

  useEffectOnce(() => {
    // componentWillUnmount
    return () => {
      dispatch(setPreviewDocumentId(''));
      dispatch(setCategory('new-campaign'));
    };
  });

  const campaignFlowChart = useMemo(() => {
    if (document?.entity?.type === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3' || (document === undefined && ffEnableETA)) {
      return (
        <LoggerContext.Provider value={campaignLoggerDeps}>
          <StateProvider>
            <FlowChartCampaignV3 />
          </StateProvider>
        </LoggerContext.Provider>
      );
    }
    return (
      <LoggerContext.Provider value={campaignLoggerDeps}>
        <FlowChart />
      </LoggerContext.Provider>
    );
  }, [campaignLoggerDeps, document, ffEnableETA]);

  if (ffEnableETA) {
    return <FlowWrapper />;
  }

  // Branch out to specific component implementations per document type
  if (flowDocumentIdRouteMatch || document) {
    if (isLoadingDocument) {
      return <Loader />;
    }
    if (isErrorLoadingDocument) {
      // TODO(anomikos): add error handling
      return <Box>{JSON.stringify(errorLoadingDocument)}</Box>;
    }
    if (isSuccessLoadingDocument) {
      switch (document.entity?.type) {
        case 'FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1':
        case 'FLOW_DOCUMENT_TYPE_SENDOUT_V3':
          if (flowDocumentIdRouteMatch?.params.flowDocumentId && ffEnableETA) {
            if (isLoadingDocument) return <Loader />;
            return campaignFlowChart;
          }
          if (flowState === 'preface') {
            if (category !== 'resend-campaign') dispatch(setCategory('resend-campaign'));
            return <FlowChartGetStarted />;
          }
          return campaignFlowChart;
        case 'FLOW_DOCUMENT_TYPE_AUTO_REPLY_V1':
          return (
            <LoggerContext.Provider value={autoReplyLoggerDeps}>
              <FlowChartAutoReplyV1 documentId={flowDocumentIdRouteMatch?.params.flowDocumentId || documentId} />
            </LoggerContext.Provider>
          );
        default:
          // TODO(anomikos): add error handling
          return <Box>Unsupported Document Type</Box>;
      }
    }
  }
  if (flowState === 'preface') {
    return <FlowChartGetStarted />;
  }
  return campaignFlowChart;
};
