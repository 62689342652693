import { ApiError } from '@connectlyai-tenets/error';
import { ErrorCode } from '@connectlyai-idl/models/dist/models/error/code';
export function toApiError(error) {
    if (error.code === ErrorCode.ERROR_CODE_UNSPECIFIED || !error.code) {
        const errorMessage = `Unexpected error has occurred.
    Please contact the team and provide trace_id ${error.cntTraceId}`;
        return new ApiError(error.code.valueOf(), errorMessage, true, error.details);
    }
    return new ApiError(error.code.valueOf(), error.userMessage, false, error.details);
}
