import React, { FC, PropsWithChildren } from 'react';
import { useAnalytics, useFeatureFlag } from '@hooks';
import { Label, useTheme, Stack, Button, ArrowBackIcon, useMediaQuery, Box } from '@connectlyai-tenets/ui-styled-web';
import { PageLayoutProps } from './types';
import { NavigationTabs } from '../NavigationTabs';
import { SIZE_SPACING_INTER_SECTION, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

export const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  title,
  primaryButton,
  secondaryButton,
  maxWidth,
  testId,
  children,
  routes,
  navigateBack,
  fullScreen,
}) => {
  const theme = useTheme();
  const { sendAnalytics } = useAnalytics();
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);

  const isMediaMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isMediaSm = useMediaQuery(theme.breakpoints.down('md'));
  // eslint-disable-next-line no-nested-ternary
  const minWidth = isMediaSm ? 0 : isMediaMd ? 60 : 120;

  return (
    <Stack
      data-testid={testId}
      sx={{
        flex: '1 1 auto',
        minHeight: 0,
        width: '100%',
      }}
    >
      {(title || primaryButton || secondaryButton || navigateBack) && (
        <Stack
          direction="row"
          sx={{
            backgroundColor: theme.palette.common.white,
            flex: `0 0 ${theme.spacing(8)}`,
            alignItems: 'center',
            borderBottom: '1px solid #E4E4E7',
            boxSizing: 'border-box',
            px: 3,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center">
              {navigateBack && (
                <Button variant="text" onClick={navigateBack} color="secondary" sx={{ width: 'fit-content' }}>
                  <ArrowBackIcon sx={{ color: '#64748B' }} />
                </Button>
              )}
              {title && (
                <>
                  {typeof title === 'string' && (
                    <Label variant="h5" sx={{ fontWeight: 'bold !important' }}>
                      {title}
                    </Label>
                  )}
                  {typeof title === 'object' && title}
                </>
              )}
            </Stack>
            {(primaryButton || secondaryButton) && (
              <Stack direction="row" gap={SIZE_SPACING_INTER_COMPONENT}>
                {secondaryButton && (
                  <Button
                    variant="contained"
                    color="secondary"
                    data-test-id={secondaryButton?.dataTestId}
                    {...secondaryButton}
                    onClick={(e) => {
                      if (secondaryButton.track) {
                        sendAnalytics(...secondaryButton.track);
                      }
                      if (secondaryButton.onClick) {
                        secondaryButton.onClick(e);
                      }
                    }}
                  />
                )}
                {primaryButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    data-test-id={primaryButton?.dataTestId}
                    {...primaryButton}
                    onClick={(e) => {
                      if (primaryButton.track) {
                        sendAnalytics(...primaryButton.track);
                      }
                      if (primaryButton.onClick) {
                        primaryButton.onClick(e);
                      }
                    }}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {ffEnableAnalyticsRedesign && (
        <Box
          sx={{
            px: 3,
            width: '100%',
            borderBottom: '1px solid #E4E4E7',
          }}
        >
          <Box
            sx={{
              maxWidth: fullScreen ? 'none' : theme.spacing(maxWidth || 120),
              minWidth: theme.spacing(minWidth),
              mx: fullScreen ? 'none' : 'auto',
            }}
          >
            {routes && !fullScreen && <NavigationTabs routes={routes} />}
          </Box>
        </Box>
      )}
      <Stack
        gap={SIZE_SPACING_INTER_SECTION}
        sx={{
          minHeight: 0,
          px: fullScreen ? 0 : theme.spacing(3),
          pt: fullScreen ? 0 : theme.spacing(3),
          pb: fullScreen ? 0 : theme.spacing(3),
          maxWidth: fullScreen ? 'none' : theme.spacing(maxWidth || 120),
          minWidth: theme.spacing(minWidth),
          mx: fullScreen ? 'none' : 'auto',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {routes && !fullScreen && !ffEnableAnalyticsRedesign && <NavigationTabs routes={routes} />}
        <Stack
          gap={SIZE_SPACING_INTER_SECTION}
          sx={{
            px: 2,
            mx: -2,
            boxSizing: 'border-box',
            overflow: 'hidden',
            scrollbarGutter: 'stable',
            '&:hover': {
              overflow: 'auto',
            },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
