import React, { FC, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Button,
  CloseIcon,
  Collapse,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import produce from 'immer';
import { FlowContext } from '../../contexts';
import {
  CREATE_NEW_TEMPLATE_ID,
  HeaderType,
  replaceUnsentMessageTemplate,
  selectEditedNodeId,
  selectErrors,
  selectIsEditable,
  selectTemplateId,
  setTemplateId,
} from '../../state/messageTemplates';
import { FlowChartSidebarTemplateBuilderProps } from './types';
import { applyHook } from '../../utils/applyHook';
import { TemplateBuilderHeader } from '../TemplateBuilderHeader';
import { TemplateBuilderBody } from '../TemplateBuilderBody';
import { TemplateBuilderFooter } from '../TemplateBuilderFooter';
import { TemplateBuilderButtons } from '../TemplateBuilderButtons';
import { TemplateBuilderList } from '../TemplateBuilderList';
import { scrollToHTMLInputElement } from '../../utils';
import { TemplateBuilderTemplateSelector } from '../TemplateBuilderTemplateSelector';
import { selectNodes } from '../../state/flow';
import { TemplateType } from '../../sdks/flow/createNode';
import {
  selectBusinessId,
  selectMessageTemplateById,
  useCreateNode,
  useMeData,
  useMessageTemplateGroupsData,
} from '../../hooks';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { editNode } from '../../state/actions';

function hasTemplateSelector(type: TemplateType | undefined) {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return false;
    }
    default: {
      return true;
    }
  }
}

function hasHeader(type: TemplateType | undefined) {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
}

function hasBody(type: TemplateType | undefined) {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
}

function hasFooter(type: TemplateType | undefined) {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
}

function hasButtons(type: TemplateType | undefined) {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return false;
    }
    default: {
      return false;
    }
  }
}

function hasList(type: TemplateType | undefined) {
  if (type === undefined) {
    return false;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
}

const useFlowChartSidebarTemplateBuilder = () => {
  const { resetSelection } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);

  const selectedTemplateId = useSelector(selectTemplateId);
  const isEditable = useSelector(selectIsEditable);

  const nodeId = useSelector(selectEditedNodeId);
  const nodes = useSelector(selectNodes);
  const node = nodes.find((n) => n.id === nodeId);
  const templateType: TemplateType | undefined = node?.data.v1.waMessageTemplateType;

  const errors = useSelector(selectErrors, shallowEqual);
  useEffect(() => {
    if (errors) {
      const { templateId, header, body, footer, buttons } = errors;
      if (templateId) {
        scrollToHTMLInputElement('message-template-selector', true, 0);
        return;
      }

      if (header) {
        scrollToHTMLInputElement('message-template-header', true);
        return;
      }

      if (body) {
        scrollToHTMLInputElement('message-template-body', true);
        return;
      }

      if (footer) {
        scrollToHTMLInputElement('message-template-footer', true);
        return;
      }

      if (buttons) {
        const errorButtonIndex = buttons.findIndex((x) => x.text || x.url || x.phoneNumber);
        if (errorButtonIndex !== -1) {
          let errorButtonElement = 'text';
          if (buttons[errorButtonIndex].url) {
            errorButtonElement = 'url';
          } else if (buttons[errorButtonIndex].phoneNumber) {
            errorButtonElement = 'phone';
          }

          scrollToHTMLInputElement(`message-template-button${errorButtonIndex}-${errorButtonElement}`, true);
        }
      }
    }
  }, [errors]);

  const handleSave = useCallback(() => {
    resetSelection();
  }, [resetSelection]);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: selectedTemplate } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(selectedTemplateId || ''),
    keepPreviousData: true,
    filterByTemplateIdOrExternalTemplateId: {
      templateId: selectedTemplateId || '',
    },
  });
  const dispatch = useDispatch();
  const { onNodeObjectChange } = useCampaignFlowChangeAppliers();
  const { templateName } = useCreateNode();

  const handleEditApprovedTemplate = useCallback(() => {
    if (!nodeId || !node || !selectedTemplate?.template) return;

    dispatch(setTemplateId(CREATE_NEW_TEMPLATE_ID));

    const {
      template: { templateComponents },
    } = selectedTemplate;

    const name = templateName();

    dispatch(
      replaceUnsentMessageTemplate({
        nodeId,
        template: {
          id: CREATE_NEW_TEMPLATE_ID,
          errors: undefined,
          name,
          templateComponents,
        },
      }),
    );

    let updatedNode;

    switch (node.type) {
      case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
        updatedNode = produce(node, (draft) => {
          draft.data.v1 = { templateId: CREATE_NEW_TEMPLATE_ID };
          draft.data.v1.name = name;

          if (templateComponents) {
            draft.data.v1.messageTemplateInput = {
              templateComponents,
              name,
            };
          }
          return draft;
        });
        break;
      }
      case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
        updatedNode = produce(node, (draft) => {
          draft.data.v1 = { waMessageTemplateId: CREATE_NEW_TEMPLATE_ID };
          draft.data.v1.name = name;

          const body = templateComponents?.find((x) => x.body)?.body?.text?.text || '';
          const footer = templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
          const buttons = templateComponents?.filter((x) => x.button) || [];
          const mediaHeader = templateComponents?.find((x) => x.header)?.header?.media?.example || [];
          const textHeader = templateComponents?.find((x) => x.header)?.header?.text?.text || '';
          const headerComponent = templateComponents?.find((x) => x.header);
          const mediaHeaderType = headerComponent?.header?.media?.type;

          if (body) draft.data.v1.body = body;
          if (footer) draft.data.v1.footer = footer;
          if (buttons.length > 0) draft.data.v1.buttons = buttons;
          if (mediaHeader.length > 0) draft.data.v1.mediaHeader = mediaHeader;
          if (textHeader) draft.data.v1.textHeader = textHeader;

          switch (mediaHeaderType) {
            case 'TYPE_IMAGE': {
              draft.data.v1.headerType = HeaderType.Image;
              break;
            }
            case 'TYPE_VIDEO': {
              draft.data.v1.headerType = HeaderType.Video;
              break;
            }
            case 'TYPE_DOCUMENT': {
              draft.data.v1.headerType = HeaderType.Document;
              break;
            }
            default: {
              if (textHeader) draft.data.v1.headerType = HeaderType.Text;
              break;
            }
          }
          return draft;
        });
        break;
      }
      default: {
        break;
      }
    }

    if (!updatedNode) return;

    if (node.data.v1.prompt) {
      updatedNode = produce(updatedNode, (draft) => {
        draft.data.v1.prompt = node.data.v1.prompt;
      });
    }

    onNodeObjectChange([
      {
        id: updatedNode.id,
        item: updatedNode,
      },
    ]);
    dispatch(editNode({ node: updatedNode }));
  }, [dispatch, nodeId, selectedTemplate, node, onNodeObjectChange, templateName]);

  return {
    isEditable,
    handleEditApprovedTemplate,
    templateType,
    templateId: selectedTemplateId,
    onSave: handleSave,
  };
};

const FlowChartSidebarTemplateBuilderPresentation: FC<FlowChartSidebarTemplateBuilderProps> = ({
  isEditable,
  handleEditApprovedTemplate,
  templateType,
  onSave,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          gap: 1,
          flex: '0 0 auto',
          px: isLargeScreen ? 3 : 2,
          mt: isLargeScreen ? 3.5 : 2,
          mb: isLargeScreen ? 2 : 1,
        }}
      >
        <Label variant="h6">Edit Message</Label>
        <IconButton aria-label="close" onClick={onSave}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        id="message-template-content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: isLargeScreen ? 3 : 2,
          flex: '1 1 auto',
          gap: isLargeScreen ? 3 : 2,
          overflowY: 'scroll',
        }}
      >
        {hasTemplateSelector(templateType) && <TemplateBuilderTemplateSelector />}
        <Collapse in={isEditable}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {hasHeader(templateType) && <TemplateBuilderHeader />}
              {hasBody(templateType) && <TemplateBuilderBody />}
              {hasFooter(templateType) && <TemplateBuilderFooter />}
              {hasButtons(templateType) && <TemplateBuilderButtons />}
              {hasList(templateType) && <TemplateBuilderList />}
            </Box>
          </Box>
        </Collapse>
        {!isEditable && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0.9,
              gap: 3,
              justifyContent: 'center',
              mb: 10,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Label variant="body1">Changes to this message will require it to be approved again.</Label>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleEditApprovedTemplate}>
              Edit message
            </Button>
            <Label variant="body2" sx={{ color: theme.palette.grey[600] }}>
              You can always go back to the approved template by selecting it from dropdown above.
            </Label>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          p: 3,
          flex: '0 0 auto',
        }}
      >
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export const FlowChartSidebarTemplateBuilder = applyHook(
  FlowChartSidebarTemplateBuilderPresentation,
  useFlowChartSidebarTemplateBuilder,
);
