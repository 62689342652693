import React, { useMemo } from 'react';
import { selectBusinessId, useMeData, useSettingsData, selectIsShopifyFocus } from '@hooks';
import { ShopifyAbandonedCart, VtexAbandonedCart, AutomationAutoReply, SMBAutoReply } from '@components';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@components/PageLayout';

const useAutomation = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: isShopifyFocus } = useSettingsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectIsShopifyFocus,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'automations' });
  const title = useMemo(() => t('title'), [t]);
  return {
    isShopifyFocus,
    title,
  };
};

export const Automation = () => {
  const { isShopifyFocus, title } = useAutomation();

  const content = (
    <>
      {isShopifyFocus && <SMBAutoReply />}
      {!isShopifyFocus && (
        <>
          <AutomationAutoReply />
          <VtexAbandonedCart />
        </>
      )}
      <ShopifyAbandonedCart />
    </>
  );

  return <PageLayout title={title}>{content}</PageLayout>;
};
