import { useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  campaignNameAtom,
  documentIdAtom,
  isCampaignNameDirtyAtom,
  isFlowPopulatedAtom,
  savedCampaignNameAtom,
  starterAtom,
} from '@atoms/flow';
import { useQueryClient } from '@tanstack/react-query';
import {
  MutationCreateOrUpdateReactFlowDocumentResponse,
  selectBusinessId,
  useMeData,
  useCreateOrUpdateFlowDocumentMutation,
  MutationCreateOrUpdateReactFlowDocumentInput,
} from '@hooks';
import { usePopulateCampaign } from '../usePopulateCampaign';
import { useCampaignV3 } from '../useCampaignV3';
import { UseCreateCampaignResult } from './types';

export const useCreateCampaign = (): UseCreateCampaignResult => {
  const [documentId, setDocumentId] = useAtom(documentIdAtom);
  const campaignName = useAtomValue(campaignNameAtom);
  const isFlowPopulated = useAtomValue(isFlowPopulatedAtom);
  const savedCampaignName = useAtomValue(savedCampaignNameAtom);
  const setIsCampaignNameDirty = useSetAtom(isCampaignNameDirtyAtom);
  const setSavedCampaignName = useSetAtom(savedCampaignNameAtom);
  const starter = useAtomValue(starterAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { flowDocumentData } = useCampaignV3();
  const { populateCampaign } = usePopulateCampaign();

  const queryClient = useQueryClient();

  const createOrUpdateFlowHookReturns = useCreateOrUpdateFlowDocumentMutation({
    onSuccess: (data: MutationCreateOrUpdateReactFlowDocumentResponse) => {
      // every time campaign creation or update is successful
      // invalidate listCampaigns query to refetch names
      // to be used by useGenerateCampaignName for next document
      queryClient.refetchQueries({
        queryKey: ['listCampaigns', { businessId, entity: { filterDeleted: { option: 'DELETED_OPTION_INCLUDE' } } }],
      });

      if (!isFlowPopulated) {
        // flow is not yet populated
        // use data for new flow document
        // this step is done for blank canvas, ai builder, duplication or campaign presets
        populateCampaign(data);
        return;
      }

      // if flow is already populated, only name is updated
      const name = data?.entity?.name;
      if (!name) return;
      setSavedCampaignName(name);
    },
    onError: () => {
      // if flow document creation is failed, list of campaign names might be outdated
      queryClient.refetchQueries({
        queryKey: ['listCampaigns', { businessId, entity: { filterDeleted: { option: 'DELETED_OPTION_INCLUDE' } } }],
      });
    },
  });

  const { mutate, isLoading: isLoadingCreateOrUpdate } = createOrUpdateFlowHookReturns;

  const createOrUpdateFlow = useCallback(
    (input: Omit<MutationCreateOrUpdateReactFlowDocumentInput, 'category' | 'type'>) =>
      mutate({
        ...input,
        category: 'FLOW_DOCUMENT_CATEGORY_CAMPAIGN',
        type: 'FLOW_DOCUMENT_TYPE_SENDOUT_V3',
      }),
    [mutate],
  );

  useEffect(() => {
    if (isFlowPopulated) return;
    if (isLoadingCreateOrUpdate) return;
    // looks like flow chart component is recently created

    if (['edit', 'resend'].includes(starter)) {
      // flow builder is opening an already existing flow document to be edited or resend
      if (!flowDocumentData) return;

      // populating the campaign with an existing flow document
      populateCampaign(flowDocumentData);

      // no new flow document is created
      return;
    }

    // the rest of the function is run only when flow document is opened for
    // either blank canvas, ai builder, duplication or campaign presets

    // if there is a document id, then the document creation request is already sent
    if (documentId) return;

    // either blank canvas, ai builder, duplication
    // or a campaign from presets is being created
    if (!businessId || !campaignName) return;

    // create new document id
    const id = uuidv4();
    setDocumentId(id);

    // creating new flow document
    createOrUpdateFlow({ businessId, name: campaignName, id });
  }, [
    businessId,
    campaignName,
    createOrUpdateFlow,
    documentId,
    flowDocumentData,
    isLoadingCreateOrUpdate,
    isFlowPopulated,
    populateCampaign,
    savedCampaignName,
    setDocumentId,
    starter,
  ]);

  const updateCampaignName = useCallback(() => {
    if (!businessId || !campaignName) return;

    setIsCampaignNameDirty(false);

    // user has changed campaign name
    // using the same mutation as above but only for updating flow document name
    // so use existing document id
    createOrUpdateFlow({ businessId, name: campaignName, id: documentId });
  }, [businessId, campaignName, createOrUpdateFlow, documentId, setIsCampaignNameDirty]);

  return { createOrUpdateFlowHookReturns, updateCampaignName };
};
