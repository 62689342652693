import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { ConnectlyIconKind } from '@connectlyai-tenets/ui-icons';
import { ChannelType } from '@connectlyai-idl/models/dist/models/common/channel/models';
export { ChannelIdentifier, ChannelType, channelTypeToJSON, } from '@connectlyai-idl/models/dist/models/common/channel/models';
export { Customer, CustomerChannel, Eligibility, EligibilityType, } from '@connectlyai-idl/models/dist/models/profile/models';
export { CreateEventInput, RoomDto, RoomDtos, RoomEventDto, RoomUser, } from '@connectlyai-idl/models/dist/models/room/models';
export { Attachment, AttachmentType } from '@connectlyai-idl/models/dist/models/event/connectly/models';
export { ConnectlyEventSkip } from '@connectlyai-idl/models/dist/models/room/models';
export { RoomStatus, WebhookTopic } from '@connectlyai-idl/models/dist/models/common/models';
export function mapChannelTypeToIconKind(type) {
    switch (type) {
        case ChannelType.CHANNEL_TYPE_FB_MESSENGER:
            return ConnectlyIconKind.MESSENGER;
        case ChannelType.CHANNEL_TYPE_INSTAGRAM:
            return ConnectlyIconKind.INSTAGRAM;
        case ChannelType.CHANNEL_TYPE_SMS:
            return ConnectlyIconKind.SMS;
        case ChannelType.CHANNEL_TYPE_CONNECTLY:
            return ConnectlyIconKind.CONNECTLY;
        case ChannelType.CHANNEL_TYPE_WHATSAPP:
        case ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD:
            return ConnectlyIconKind.WHATSAPP;
        case ChannelType.CHANNEL_TYPE_EMAIL:
            return undefined;
        case ChannelType.CHANNEL_TYPE_SHOPIFY:
            return undefined;
        case ChannelType.CHANNEL_TYPE_UNSPECIFIED:
            return undefined;
        case ChannelType.CHANNEL_TYPE_ZENDESK:
            return undefined;
        case ChannelType.CHANNEL_TYPE_NUBANK:
            return undefined;
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT:
            return undefined;
        case ChannelType.CHANNEL_TYPE_CUSTOM_ECOMMERCE:
            return undefined;
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT_STAGING:
            return undefined;
        case ChannelType.CHANNEL_TYPE_VTEX:
            return undefined;
        case ChannelType.UNRECOGNIZED:
            return undefined;
        default:
            return assertUnreachable(type);
    }
}
export function mapChannelTypeToColor(type) {
    if (type === undefined) {
        return undefined;
    }
    switch (type) {
        case ChannelType.CHANNEL_TYPE_FB_MESSENGER:
            return '#006AFF';
        case ChannelType.CHANNEL_TYPE_INSTAGRAM:
            return '#833AB4';
        case ChannelType.CHANNEL_TYPE_SMS:
            return '#19C562';
        case ChannelType.CHANNEL_TYPE_CONNECTLY:
            return '000000';
        case ChannelType.CHANNEL_TYPE_WHATSAPP:
        case ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD:
            return '#DCFCE7';
        case ChannelType.CHANNEL_TYPE_EMAIL:
            return '#000000';
        case ChannelType.CHANNEL_TYPE_SHOPIFY:
            return '#000000';
        case ChannelType.CHANNEL_TYPE_ZENDESK:
            return undefined;
        case ChannelType.CHANNEL_TYPE_NUBANK:
            return undefined;
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT:
            return undefined;
        case ChannelType.CHANNEL_TYPE_UNSPECIFIED:
            return undefined;
        case ChannelType.CHANNEL_TYPE_CUSTOM_ECOMMERCE:
            return undefined;
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT_STAGING:
            return undefined;
        case ChannelType.CHANNEL_TYPE_VTEX:
            return undefined;
        case ChannelType.UNRECOGNIZED:
            return undefined;
        default:
            return assertUnreachable(type);
    }
}
export function defaultCustomerChannel(dto) {
    var _a, _b, _c, _d;
    const lastCustomerEvent = dto.events.find((event) => event.id === dto.latestCustomerEventId);
    const lastCustomerEventChannelId = lastCustomerEvent === null || lastCustomerEvent === void 0 ? void 0 : lastCustomerEvent.customerChannelId;
    if (lastCustomerEventChannelId !== undefined) {
        return (_a = dto.customer) === null || _a === void 0 ? void 0 : _a.channels.find((channel) => channel.id === lastCustomerEventChannelId);
    }
    const numCustomerChannels = (_c = (_b = dto.customer) === null || _b === void 0 ? void 0 : _b.channels) === null || _c === void 0 ? void 0 : _c.length;
    if (numCustomerChannels !== undefined && numCustomerChannels > 0) {
        return (_d = dto.customer) === null || _d === void 0 ? void 0 : _d.channels[0];
    }
    return undefined;
}
