import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';
import { definitions } from '@connectlyai-tenets/sdk/src/schemes/integrationV1.generated';

export const INSTALL_INTEGRATION_URL = '/internal/v1/businesses/{input.businessId}/install/integrations';

type InstallIntegrationURL = typeof INSTALL_INTEGRATION_URL;

export type InstallIntegrationResponse =
  Paths['integrationV1'][InstallIntegrationURL]['post']['responses']['200']['schema'];
export type InstallIntegrationInput =
  Paths['integrationV1'][InstallIntegrationURL]['post']['parameters']['body']['input'];
export type InstallIntegrationVariables = { businessId: string } & InstallIntegrationInput;

export type InstallIntegrationOptions = Omit<
  UseMutationOptions<InstallIntegrationResponse, unknown, InstallIntegrationVariables, unknown>,
  'mutationKey' | 'mutationFn'
>;

export type ReminderParametersList = definitions['parametersCustomShopifyAbandonedCheckoutParametersAlwaysOnReminder'];

export type ACIntegrationType = definitions['integrationIntegrationType'];
