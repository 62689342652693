import { Tag, TagInput } from '../entities';
export const mutationCreateTagProvider = (log, networkClient) => async (input) => {
    const url = `/v1/businesses/${input.businessId}/tags/${input.tagId}`;
    log.debug('mutationCreateTag@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.put(url, TagInput.toJSON(input));
        log.debug('mutationCreateTag@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return Tag.fromJSON(resp.data.entity);
    }
    catch (err) {
        log.warn('mutationCreateTag@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
