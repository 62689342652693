export type WhatsAppOnboardingStep =
  | 'phoneNumberSelector'
  | 'phoneNumberOld'
  | 'businessName'
  | 'businessProfilePicture'
  | 'connectWhatsApp';

export type ShopifyOnboardingStep = 'connectShopify';

export type PaymentOnboardingStep = 'payment';

export type OnboardingStep = WhatsAppOnboardingStep | ShopifyOnboardingStep | PaymentOnboardingStep;

export type ShopifyOnboardingOptions = {
  connectUrl: string;
  storeUrl: string;
};

export type OnboardingState = {
  businessName: string;
  businessProfilePicture: string;
  confirmation: boolean;
  isCurrentlyUsed: boolean;
  phoneNumber: string;
  shopifyOptions: ShopifyOnboardingOptions | undefined;
  step: OnboardingStep;
  useNewPhoneNumber: boolean;
};

export enum ShopifyAbandonedCheckoutOnboardingStep {
  ConnectWhatsAppStep = 0,
  ConnectShopifyStep = 1,
}
