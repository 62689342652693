import React, { useMemo } from 'react';
import { Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { VARIABLE_REGEX_NONSTATEFUL } from '@hooks/useFlowVariables/constants';
import { TextVariable } from '@components/TextVariable';
import { useFlowVariables } from '@hooks/useFlowVariables';

export const TextOrVariable = ({ value }: { value: string }) => {
  const { variableTypeOf, trimCurly } = useFlowVariables();
  const isVariable = useMemo(() => VARIABLE_REGEX_NONSTATEFUL.test(value), [value]);
  const variableTrimmed = trimCurly(value);
  const theme = useTheme();
  if (isVariable)
    return (
      <TextVariable
        variable={<Label variant="body1">{variableTrimmed}</Label>}
        variableType={variableTypeOf(variableTrimmed)}
        style={{
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(0.25),
          paddingBottom: theme.spacing(0.25),
        }}
      />
    );
  return (
    <Label
      variant="body1"
      sx={{
        wordBreak: 'break-word',
      }}
    >
      {value}
    </Label>
  );
};
