import { DataOrigin } from './api';
const Loading = '$type$Loading';
export default class DataResponse {
    constructor(origin, data) {
        this.origin = origin;
        if (data instanceof Error) {
            this.error = data;
        }
        else if (data === Loading) {
            this.loading = true;
        }
        else {
            this.data = data;
        }
    }
    static data(data, origin = DataOrigin.Fetcher) {
        return new DataResponse(origin, data);
    }
    static loading(origin = DataOrigin.Fetcher) {
        return new DataResponse(origin, Loading);
    }
    static error(error, origin = DataOrigin.Fetcher) {
        return new DataResponse(origin, error);
    }
    isDataReady() {
        return this.data !== undefined;
    }
    isLoading() {
        return this.loading === true;
    }
    isError() {
        return this.error !== undefined;
    }
    requireData() {
        if (this.data === undefined) {
            throw new Error('data was expected to be present');
        }
        return this.data;
    }
    getOrNull() {
        if (this.data === undefined) {
            return null;
        }
        return this.data;
    }
    getOrDefault(defaultValue) {
        if (this.data === undefined) {
            return defaultValue;
        }
        return this.data;
    }
    fold(onData, onLoading, onError) {
        if (this.error !== undefined) {
            return onError(this.error);
        }
        if (this.loading !== undefined) {
            return onLoading();
        }
        if (this.data !== undefined) {
            return onData(this.data);
        }
        throw new Error('unreachable');
    }
}
