import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const UNINSTALL_INTEGRATION_URL = '/internal/v1/businesses/{businessId}/uninstall/integrations/{integrationId}';

type UninstallIntegrationURL = typeof UNINSTALL_INTEGRATION_URL;

export type UninstallIntegrationResponse =
  Paths['integrationV1'][UninstallIntegrationURL]['post']['responses']['200']['schema'];
export type UninstallIntegrationVariables = { businessId: string; integrationId: string };

export type UninstallIntegrationOptions = Omit<
  UseMutationOptions<UninstallIntegrationResponse, unknown, UninstallIntegrationVariables, unknown>,
  'mutationKey' | 'mutationFn'
>;
