import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Label,
  Button,
  TextField,
  Slide,
  useTheme,
  DocumentStarsCloudsIcon,
  useMediaQuery,
} from '@connectlyai-tenets/ui-styled-web';
import produce from 'immer';
import { snakeCase } from 'lodash';
import { track } from 'src/utils';
import type { CamelToSnakeCase } from 'src/types';
import { selectBusinessId, useMeData } from '../../hooks';
import { useAICampaign } from '../../hooks/useAICampaign';

export const FlowChartSidebarAICampaign: FC = () => {
  const {
    aiState,
    campaignTemplate,
    formInputs,
    formPlaceholderOf,
    generateAIFromTemplate,
    isAIGenerating,
    isAISidebarOpen,
    setIsAISidebarOpen,
  } = useAICampaign();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [formData, setFormData] = useState<Record<string, string>>({});
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const handleFormInput = useCallback(
    (label: string, value: string) => {
      setFormData((oldData) =>
        produce(oldData, (draft) => {
          draft[label] = value;
        }),
      );
    },
    [setFormData],
  );

  const handleAIGenerate = useCallback(() => {
    if (!campaignTemplate) {
      return;
    }
    const tmpl = snakeCase(campaignTemplate) as CamelToSnakeCase<typeof campaignTemplate>;
    track(`clicks generate_ai_campaign_template_${tmpl}`, { businessId });
    generateAIFromTemplate(formData);
  }, [campaignTemplate, formData, generateAIFromTemplate]);

  const handleCloseAISidebar = useCallback(() => {
    if (!isAIGenerating) {
      setIsAISidebarOpen(false);
    }
  }, [isAIGenerating, setIsAISidebarOpen]);

  return (
    <Slide
      in={isAISidebarOpen}
      direction="right"
      timeout={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: theme.palette.background.paper,
          willChange: 'transform',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            boxShadow: '1px 0px 0px rgba(0, 0, 0, 0.08)',
            px: isLargeScreen ? 3 : 2,
            py: 3.5,
            boxSizing: 'border-box',
            width: '100%',
            background: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 2,
            height: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'start',
              justifyContent: 'space-between',
              display: 'flex',
              gap: 1,
              flex: '0 0 auto',
            }}
          >
            <DocumentStarsCloudsIcon />
          </Box>
          <Label variant="h6">AI Campaign</Label>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%', overflowY: 'scroll' }}>
            <Label variant="body2">We will generate messages based on your information below.</Label>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {formInputs
                .filter((input) => !['Language', 'Webpagetext', 'Webpageimage', 'Webpageimagealt'].includes(input))
                .map((input) => (
                  <TextField
                    key={input}
                    label={input}
                    value={formData[input]}
                    placeholder={formPlaceholderOf(input)}
                    onChange={(e) => handleFormInput(input, e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                ))}
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Button
              sx={{
                background: isAIGenerating ? '' : theme.palette.midnightIndigo,
                color: theme.palette.common.white,
              }}
              variant="contained"
              onClick={handleAIGenerate}
              disabled={isAIGenerating}
            >
              Generate with AI
            </Button>
            {aiState === 'completed' && (
              <Button variant="contained" onClick={handleCloseAISidebar}>
                Continue to Edit Campaign
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};
