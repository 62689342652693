export const blueGrey = {
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
    A100: '#E6F0F3',
    A200: '#B3C9D6',
    A400: '#8DA9C4',
    A700: '#6F8FAF',
};
