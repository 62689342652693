import { useMutation } from '@tanstack/react-query';
import { v4 as uuidV4 } from 'uuid';
import { api } from '../../../../api';
import { CreateCohortResponse, CreateCohortOptions, CreateCohortVariables, CREATE_COHORT_URL } from './types';

export const useMutationCreateCohort = (options?: CreateCohortOptions) =>
  useMutation(({ businessId, segmentId }: CreateCohortVariables) => {
    const clientRequestId = uuidV4();
    return api.audienceV1(CREATE_COHORT_URL, 'post', {
      path: { 'input.businessId': businessId },
      body: { input: { segmentId, clientRequestId } },
    }) as Promise<CreateCohortResponse>;
  }, options);
