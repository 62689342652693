import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "exp.eta.events";
function createBaseAPIResult() {
    return { req: undefined, statusCode: 0, contentType: "", body: "" };
}
export const APIResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.req !== undefined) {
            APIRequest.encode(message.req, writer.uint32(10).fork()).ldelim();
        }
        if (message.statusCode !== 0) {
            writer.uint32(16).int32(message.statusCode);
        }
        if (message.contentType !== "") {
            writer.uint32(26).string(message.contentType);
        }
        if (message.body !== "") {
            writer.uint32(34).string(message.body);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAPIResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.req = APIRequest.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.statusCode = reader.int32();
                    break;
                case 3:
                    message.contentType = reader.string();
                    break;
                case 4:
                    message.body = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            req: isSet(object.req) ? APIRequest.fromJSON(object.req) : undefined,
            statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
            contentType: isSet(object.contentType) ? String(object.contentType) : "",
            body: isSet(object.body) ? String(object.body) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.req !== undefined &&
            (obj.req = message.req ? APIRequest.toJSON(message.req) : undefined);
        message.statusCode !== undefined &&
            (obj.statusCode = Math.round(message.statusCode));
        message.contentType !== undefined &&
            (obj.contentType = message.contentType);
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function createBaseAPIRequest() {
    return { url: "", method: "", body: "" };
}
export const APIRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.method !== "") {
            writer.uint32(18).string(message.method);
        }
        if (message.body !== "") {
            writer.uint32(26).string(message.body);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAPIRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.method = reader.string();
                    break;
                case 3:
                    message.body = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : "",
            method: isSet(object.method) ? String(object.method) : "",
            body: isSet(object.body) ? String(object.body) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        message.method !== undefined && (obj.method = message.method);
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
