import amplitude from 'amplitude-js';
import { format } from 'date-fns';
import { TimeRange, AnalyticsType } from './types';
import { DateRange } from '../../components/DateRangePicker/types';

const instance = amplitude.getInstance();
instance.init(process.env.REACT_APP_INBOX_AMPLITUDE_API_KEY || 'connectly');

export const track = (type: AnalyticsType, eventProperties?: Record<string, unknown>) =>
  instance.logEvent(type, eventProperties);

export const setUserId = (userId: string) => instance.setUserId(userId);

export const setUserProperties = (userProperties: Record<string, unknown>) =>
  instance.setUserProperties(userProperties);

const offsetDate = (date: Date, days: number): Date => {
  // Adjust start date based on the period
  const newDate: Date = new Date(date);
  newDate.setUTCDate(date.getUTCDate() - days);
  return newDate;
};

export const timeZoneOffset = (date: Date): string => {
  const timezoneOffset = date.getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const offsetMinutes = Math.abs(timezoneOffset) % 60;

  // Determine if the offset is positive or negative
  const sign = timezoneOffset > 0 ? '-' : '+';

  // Format the offset as "+HH:MM" or "-HH:MM"
  const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
  return formattedOffset;
};

export const buildQueryRanges = (range: DateRange, numQueryRanges = 0, useZuluTime = false): TimeRange[] => {
  // calculate the number of days in the range
  const rangeCopy = { ...range, startDate: new Date(range.startDate), endDate: new Date(range.endDate) };
  if (useZuluTime) {
    // remove the timezone offset so that the start date is at 00:00:00Z and endDate is at 23:59:59Z with the same date
    const { startDate, endDate } = rangeCopy;
    const startY = startDate.getFullYear();
    const startM = startDate.getMonth();
    const startD = startDate.getDate();

    // Create a new Date object in UTC for the same date
    const startUtcDate = new Date(Date.UTC(startY, startM, startD));

    // Set the time to the start of the day in UTC
    startUtcDate.setUTCHours(0, 0, 0, 0);

    const endY = endDate.getFullYear();
    const endM = endDate.getMonth();
    const endD = endDate.getDate();

    // Create a new Date object in UTC for the same date
    const endUtcDate = new Date(Date.UTC(endY, endM, endD));

    // Set the time to the start of the day in UTC
    endUtcDate.setUTCHours(23, 59, 59, 999);
    rangeCopy.startDate = startUtcDate;
    rangeCopy.endDate = endUtcDate;
  }

  const rangeList: DateRange[] = [];
  if (numQueryRanges && rangeCopy.startDate && rangeCopy.endDate) {
    const daysInWindow = Math.ceil(
      (rangeCopy.endDate.getTime() - rangeCopy.startDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    for (let i = 0; i < numQueryRanges; i += 1) {
      rangeList.push({
        startDate: i ? offsetDate(rangeCopy.startDate, daysInWindow * i) : rangeCopy.startDate,
        endDate: i ? offsetDate(rangeCopy.endDate, daysInWindow * i) : rangeCopy.endDate,
        key: '',
      });
    }
  }
  return rangeList.map((r) => ({ timeRangeStart: r.startDate.toISOString(), timeRangeEnd: r.endDate.toISOString() }));
};

export const getDatePickerLabelValue = (start: Date, end: Date) => {
  const startYear = format(start, 'Y');
  const endYear = format(end, 'Y');

  const isYearDiff = startYear !== endYear;

  const startDate = `${format(start, 'MMM d')}${isYearDiff ? `, ${startYear}` : ''}`;
  const endDate = `${format(end, 'MMM d')}, ${endYear}`;

  return `${startDate}-${endDate}`;
};
