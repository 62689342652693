import { CreateEventInput, RoomEventDto } from '@connectlyai-idl/models/dist/models/room/models';
export const mutationCreateEventProvider = (log, networkClient) => async (businessId, roomId, input) => {
    const url = `/v1/businesses/${businessId}/rooms/${roomId}/events`;
    log.debug('mutationCreateEvent@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.post(url, CreateEventInput.toJSON(input));
        log.debug('mutationCreateEvent@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return RoomEventDto.fromJSON(resp.data.entity);
    }
    catch (err) {
        log.error('mutationCreateEvent@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
