import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, Stack } from '@mui/material';
export const Actions = (actions) => {
    const position = actions ? actions.position : undefined;
    const [justifyContent, buttonSx] = useMemo(() => {
        let justify = 'flex-start';
        let sx = {};
        if (!position) {
            return [justify, sx];
        }
        switch (position) {
            case 'start':
            case 'flex-start':
                justify = 'flex-start';
                break;
            case 'end':
            case 'flex-end':
                justify = 'flex-end';
                break;
            case 'center':
                justify = 'center';
                break;
            case 'full':
                justify = 'center';
                sx = { flex: '1 1 auto' };
                break;
            default:
                justify = 'flex-start';
                break;
        }
        return [justify, sx];
    }, [position]);
    if (!actions) {
        return null;
    }
    const { primaryButton, secondaryButton, tertiaryButton, destructiveButton } = actions;
    return (_jsxs(Stack, { direction: "row", spacing: 2, justifyContent: justifyContent, width: "100%", children: [tertiaryButton && (_jsx(Button, { variant: "contained", color: "secondary", sx: [buttonSx, ...(Array.isArray(tertiaryButton.sx) ? tertiaryButton.sx : [buttonSx])], ...tertiaryButton })), secondaryButton && (_jsx(Button, { variant: "outlined", color: "secondary", sx: [buttonSx, ...(Array.isArray(secondaryButton.sx) ? secondaryButton.sx : [buttonSx])], ...secondaryButton })), primaryButton && (_jsx(Button, { variant: "contained", color: "primary", sx: [buttonSx, ...(Array.isArray(primaryButton.sx) ? primaryButton.sx : [buttonSx])], ...primaryButton })), destructiveButton && (_jsx(Button, { variant: "outlined", color: "error", sx: [buttonSx, ...(Array.isArray(destructiveButton.sx) ? destructiveButton.sx : [buttonSx])], ...destructiveButton }))] }));
};
