import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress } from '@mui/material';
import { ConnectlyCard } from '../ConnectlyCard';
export const SaveActionCard = ({ onSubmit, saveDisabled, isLoading, children, ...props }) => {
    const theme = useTheme();
    return (_jsxs(ConnectlyCard, { ...props, headerSx: {
            pb: children ? 1 : 0,
        }, action: onSubmit ? (_jsx(Button, { variant: "outlined", color: "secondary", disabled: saveDisabled, onClick: onSubmit, children: "Save" })) : undefined, children: [isLoading && (_jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    mb: 1,
                }, children: _jsx(CircularProgress, { sx: { ml: 1.5 }, size: theme.spacing(2.5) }) })), children] }));
};
