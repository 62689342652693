import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePeriodKey } from './types';

export function useDatePeriod({ defaultDatePeriod = 'all' }: { defaultDatePeriod?: DatePeriodKey } = {}) {
  const [datePeriod, setDatePeriod] = React.useState<DatePeriodKey>(defaultDatePeriod);
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.datePeriods' });
  const datePeriodToString = React.useCallback(
    (key: DatePeriodKey, start?: Date, end?: Date) => {
      let title = '';
      switch (key) {
        case 'all': {
          title = t('all');
          break;
        }
        case 'today': {
          title = t('today');
          break;
        }
        case 'last7Days': {
          title = t('last7Days');
          break;
        }
        case 'last1Month': {
          title = t('last1Month');
          break;
        }
        case 'last3Months': {
          title = t('last3Months');
          break;
        }
        case 'last6Months': {
          title = t('last6Months');
          break;
        }
        case 'calMonth': {
          title = t('calMonth');
          break;
        }
        case 'prevCalMonth': {
          title = t('prevCalMonth');
          break;
        }
        default: {
          assertUnreachable(key);
        }
      }
      if (start === undefined && end === undefined) {
        return title;
      }

      const startString = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      }).format(start);
      const endString = new Intl.DateTimeFormat(undefined, { month: 'short', day: 'numeric', timeZone: 'UTC' }).format(
        end,
      );
      if (key === 'all') {
        return `${title}`;
      }
      return `${title} (${startString} — ${endString})`;
    },
    [t],
  );
  return { datePeriod, datePeriodToString, setDatePeriod };
}
