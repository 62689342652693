import * as _m0 from "protobufjs/minimal";
import { StringValue, BoolValue } from "../../../../google/protobuf/wrappers";
export const protobufPackage = "events.twilio.v20100401.accounts";
function createBaseAvailablePhoneNumbersRequest() {
    return { countryCode: "", params: undefined };
}
export const AvailablePhoneNumbersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.countryCode !== "") {
            writer.uint32(10).string(message.countryCode);
        }
        if (message.params !== undefined) {
            AvailablePhoneNumbersRequest_Params.encode(message.params, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAvailablePhoneNumbersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.countryCode = reader.string();
                    break;
                case 2:
                    message.params = AvailablePhoneNumbersRequest_Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            countryCode: isSet(object.countryCode) ? String(object.countryCode) : "",
            params: isSet(object.params)
                ? AvailablePhoneNumbersRequest_Params.fromJSON(object.params)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.countryCode !== undefined &&
            (obj.countryCode = message.countryCode);
        message.params !== undefined &&
            (obj.params = message.params
                ? AvailablePhoneNumbersRequest_Params.toJSON(message.params)
                : undefined);
        return obj;
    },
};
function createBaseAvailablePhoneNumbersRequest_Params() {
    return {
        areaCode: undefined,
        smsEnabled: undefined,
        mmsEnabled: undefined,
        voiceEnabled: undefined,
        faxEnabled: undefined,
    };
}
export const AvailablePhoneNumbersRequest_Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.areaCode !== undefined) {
            StringValue.encode({ value: message.areaCode }, writer.uint32(10).fork()).ldelim();
        }
        if (message.smsEnabled !== undefined) {
            BoolValue.encode({ value: message.smsEnabled }, writer.uint32(18).fork()).ldelim();
        }
        if (message.mmsEnabled !== undefined) {
            BoolValue.encode({ value: message.mmsEnabled }, writer.uint32(26).fork()).ldelim();
        }
        if (message.voiceEnabled !== undefined) {
            BoolValue.encode({ value: message.voiceEnabled }, writer.uint32(34).fork()).ldelim();
        }
        if (message.faxEnabled !== undefined) {
            BoolValue.encode({ value: message.faxEnabled }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAvailablePhoneNumbersRequest_Params();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.areaCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.smsEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.mmsEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.voiceEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.faxEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            areaCode: isSet(object.areaCode) ? String(object.areaCode) : undefined,
            smsEnabled: isSet(object.smsEnabled)
                ? Boolean(object.smsEnabled)
                : undefined,
            mmsEnabled: isSet(object.mmsEnabled)
                ? Boolean(object.mmsEnabled)
                : undefined,
            voiceEnabled: isSet(object.voiceEnabled)
                ? Boolean(object.voiceEnabled)
                : undefined,
            faxEnabled: isSet(object.faxEnabled)
                ? Boolean(object.faxEnabled)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.areaCode !== undefined && (obj.areaCode = message.areaCode);
        message.smsEnabled !== undefined && (obj.smsEnabled = message.smsEnabled);
        message.mmsEnabled !== undefined && (obj.mmsEnabled = message.mmsEnabled);
        message.voiceEnabled !== undefined &&
            (obj.voiceEnabled = message.voiceEnabled);
        message.faxEnabled !== undefined && (obj.faxEnabled = message.faxEnabled);
        return obj;
    },
};
function createBaseAvailablePhoneNumberCollection() {
    return { availablePhoneNumbers: [] };
}
export const AvailablePhoneNumberCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.availablePhoneNumbers) {
            PhoneNumber.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAvailablePhoneNumberCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.availablePhoneNumbers.push(PhoneNumber.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            availablePhoneNumbers: Array.isArray(object === null || object === void 0 ? void 0 : object.availablePhoneNumbers)
                ? object.availablePhoneNumbers.map((e) => PhoneNumber.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.availablePhoneNumbers) {
            obj.availablePhoneNumbers = message.availablePhoneNumbers.map((e) => e ? PhoneNumber.toJSON(e) : undefined);
        }
        else {
            obj.availablePhoneNumbers = [];
        }
        return obj;
    },
};
function createBasePhoneNumber() {
    return {
        friendlyName: "",
        phoneNumber: "",
        capabilities: undefined,
        isoCountry: "",
        locality: "",
        latitude: "",
        longitude: "",
        postalCode: "",
        region: "",
    };
}
export const PhoneNumber = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.friendlyName !== "") {
            writer.uint32(10).string(message.friendlyName);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(18).string(message.phoneNumber);
        }
        if (message.capabilities !== undefined) {
            Capabilities.encode(message.capabilities, writer.uint32(26).fork()).ldelim();
        }
        if (message.isoCountry !== "") {
            writer.uint32(34).string(message.isoCountry);
        }
        if (message.locality !== "") {
            writer.uint32(42).string(message.locality);
        }
        if (message.latitude !== "") {
            writer.uint32(50).string(message.latitude);
        }
        if (message.longitude !== "") {
            writer.uint32(58).string(message.longitude);
        }
        if (message.postalCode !== "") {
            writer.uint32(66).string(message.postalCode);
        }
        if (message.region !== "") {
            writer.uint32(74).string(message.region);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePhoneNumber();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.friendlyName = reader.string();
                    break;
                case 2:
                    message.phoneNumber = reader.string();
                    break;
                case 3:
                    message.capabilities = Capabilities.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.isoCountry = reader.string();
                    break;
                case 5:
                    message.locality = reader.string();
                    break;
                case 6:
                    message.latitude = reader.string();
                    break;
                case 7:
                    message.longitude = reader.string();
                    break;
                case 8:
                    message.postalCode = reader.string();
                    break;
                case 9:
                    message.region = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            friendlyName: isSet(object.friendlyName)
                ? String(object.friendlyName)
                : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
            capabilities: isSet(object.capabilities)
                ? Capabilities.fromJSON(object.capabilities)
                : undefined,
            isoCountry: isSet(object.isoCountry) ? String(object.isoCountry) : "",
            locality: isSet(object.locality) ? String(object.locality) : "",
            latitude: isSet(object.latitude) ? String(object.latitude) : "",
            longitude: isSet(object.longitude) ? String(object.longitude) : "",
            postalCode: isSet(object.postalCode) ? String(object.postalCode) : "",
            region: isSet(object.region) ? String(object.region) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.friendlyName !== undefined &&
            (obj.friendlyName = message.friendlyName);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.capabilities !== undefined &&
            (obj.capabilities = message.capabilities
                ? Capabilities.toJSON(message.capabilities)
                : undefined);
        message.isoCountry !== undefined && (obj.isoCountry = message.isoCountry);
        message.locality !== undefined && (obj.locality = message.locality);
        message.latitude !== undefined && (obj.latitude = message.latitude);
        message.longitude !== undefined && (obj.longitude = message.longitude);
        message.postalCode !== undefined && (obj.postalCode = message.postalCode);
        message.region !== undefined && (obj.region = message.region);
        return obj;
    },
};
function createBaseCapabilities() {
    return { mms: false, sms: false, voice: false, fax: false };
}
export const Capabilities = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.mms === true) {
            writer.uint32(8).bool(message.mms);
        }
        if (message.sms === true) {
            writer.uint32(16).bool(message.sms);
        }
        if (message.voice === true) {
            writer.uint32(24).bool(message.voice);
        }
        if (message.fax === true) {
            writer.uint32(32).bool(message.fax);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCapabilities();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.mms = reader.bool();
                    break;
                case 2:
                    message.sms = reader.bool();
                    break;
                case 3:
                    message.voice = reader.bool();
                    break;
                case 4:
                    message.fax = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            mms: isSet(object.mms) ? Boolean(object.mms) : false,
            sms: isSet(object.sms) ? Boolean(object.sms) : false,
            voice: isSet(object.voice) ? Boolean(object.voice) : false,
            fax: isSet(object.fax) ? Boolean(object.fax) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.mms !== undefined && (obj.mms = message.mms);
        message.sms !== undefined && (obj.sms = message.sms);
        message.voice !== undefined && (obj.voice = message.voice);
        message.fax !== undefined && (obj.fax = message.fax);
        return obj;
    },
};
function createBaseProvisionPhoneNumberRequest() {
    return { phoneNumber: "" };
}
export const ProvisionPhoneNumberRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.phoneNumber !== "") {
            writer.uint32(10).string(message.phoneNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProvisionPhoneNumberRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        return obj;
    },
};
function createBaseProvisionedPhoneNumber() {
    return {
        sid: "",
        accountSid: "",
        addressSid: "",
        identitySid: "",
        phoneNumber: "",
        friendlyName: "",
        capabilities: undefined,
        apiVersion: "",
        status: "",
    };
}
export const ProvisionedPhoneNumber = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sid !== "") {
            writer.uint32(10).string(message.sid);
        }
        if (message.accountSid !== "") {
            writer.uint32(18).string(message.accountSid);
        }
        if (message.addressSid !== "") {
            writer.uint32(26).string(message.addressSid);
        }
        if (message.identitySid !== "") {
            writer.uint32(34).string(message.identitySid);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(42).string(message.phoneNumber);
        }
        if (message.friendlyName !== "") {
            writer.uint32(50).string(message.friendlyName);
        }
        if (message.capabilities !== undefined) {
            Capabilities.encode(message.capabilities, writer.uint32(58).fork()).ldelim();
        }
        if (message.apiVersion !== "") {
            writer.uint32(66).string(message.apiVersion);
        }
        if (message.status !== "") {
            writer.uint32(74).string(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProvisionedPhoneNumber();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sid = reader.string();
                    break;
                case 2:
                    message.accountSid = reader.string();
                    break;
                case 3:
                    message.addressSid = reader.string();
                    break;
                case 4:
                    message.identitySid = reader.string();
                    break;
                case 5:
                    message.phoneNumber = reader.string();
                    break;
                case 6:
                    message.friendlyName = reader.string();
                    break;
                case 7:
                    message.capabilities = Capabilities.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.apiVersion = reader.string();
                    break;
                case 9:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sid: isSet(object.sid) ? String(object.sid) : "",
            accountSid: isSet(object.accountSid) ? String(object.accountSid) : "",
            addressSid: isSet(object.addressSid) ? String(object.addressSid) : "",
            identitySid: isSet(object.identitySid) ? String(object.identitySid) : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
            friendlyName: isSet(object.friendlyName)
                ? String(object.friendlyName)
                : "",
            capabilities: isSet(object.capabilities)
                ? Capabilities.fromJSON(object.capabilities)
                : undefined,
            apiVersion: isSet(object.apiVersion) ? String(object.apiVersion) : "",
            status: isSet(object.status) ? String(object.status) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sid !== undefined && (obj.sid = message.sid);
        message.accountSid !== undefined && (obj.accountSid = message.accountSid);
        message.addressSid !== undefined && (obj.addressSid = message.addressSid);
        message.identitySid !== undefined &&
            (obj.identitySid = message.identitySid);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.friendlyName !== undefined &&
            (obj.friendlyName = message.friendlyName);
        message.capabilities !== undefined &&
            (obj.capabilities = message.capabilities
                ? Capabilities.toJSON(message.capabilities)
                : undefined);
        message.apiVersion !== undefined && (obj.apiVersion = message.apiVersion);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
};
function createBaseDeletePhoneNumberRequest() {
    return { accountSid: "", phoneNumberSid: "" };
}
export const DeletePhoneNumberRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountSid !== "") {
            writer.uint32(10).string(message.accountSid);
        }
        if (message.phoneNumberSid !== "") {
            writer.uint32(18).string(message.phoneNumberSid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeletePhoneNumberRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountSid = reader.string();
                    break;
                case 2:
                    message.phoneNumberSid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accountSid: isSet(object.accountSid) ? String(object.accountSid) : "",
            phoneNumberSid: isSet(object.phoneNumberSid)
                ? String(object.phoneNumberSid)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.accountSid !== undefined && (obj.accountSid = message.accountSid);
        message.phoneNumberSid !== undefined &&
            (obj.phoneNumberSid = message.phoneNumberSid);
        return obj;
    },
};
function createBaseDeletePhoneNumberResponse() {
    return {};
}
export const DeletePhoneNumberResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeletePhoneNumberResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
