import React, { useMemo } from 'react';
import { useFeatureFlag } from '@hooks';
import {
  Button,
  EmojiEmotionsIcon,
  Box,
  Label,
  useTheme,
  ExtensionIcon,
  ShoppingBasketIcon,
  ConnectlyCard,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { InfoProps } from './types';
import { SIZE_SPACING_INTER_SECTION } from '../../../../ui-theme';

const useInfo = () => {
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const theme = useTheme();
  const { title, setupText, useCases } = useMemo(() => {
    const iconProps = {
      style: { color: '#37109D' },
      width: theme.spacing(SIZE_SPACING_INTER_SECTION),
      height: theme.spacing(SIZE_SPACING_INTER_SECTION),
    };
    return {
      title: t('sofia.infoTitle'),
      setupText: t('generic.setUpNow'),
      useCases: [
        {
          icon: <EmojiEmotionsIcon {...iconProps} />,
          description: t('sofia.usecase1'),
        },
        {
          icon: <ExtensionIcon {...iconProps} />,
          description: t('sofia.usecase2'),
        },
        {
          icon: <ShoppingBasketIcon {...iconProps} />,
          description: t('sofia.usecase3'),
          comingSoon: t('generic.comingSoon'),
        },
      ],
    };
  }, [t, theme]);
  return {
    title,
    setupText,
    useCases,
    theme,
  };
};

export const Info = ({ goToOnboarding }: InfoProps) => {
  const { title, setupText, useCases, theme } = useInfo();
  return (
    <ConnectlyCard>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          gap: 2,
        }}
      >
        <Box
          sx={{
            flex: '0 0 405px',
            maxWidth: '50%',
            p: 1,
            pt: 2,
          }}
        >
          <Box
            component="img"
            sx={{ width: '100%', height: 'auto' }}
            src={`${process.env.PUBLIC_URL}/assets/automation/sofia_convo.png`}
          />
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
            pt: 3,
          }}
        >
          <Label variant="h4">{title}</Label>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" onClick={goToOnboarding} sx={{ my: 2 }}>
              {setupText}
            </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {useCases.map((useCase, index) => {
              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`usecase-${index}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 2,
                    p: 1.5,
                  }}
                >
                  {useCase.icon}
                  <Label variant="body1" sx={{ mt: 1 }}>
                    {useCase.description}
                  </Label>
                  {useCase.comingSoon && (
                    <Label
                      variant="body2"
                      sx={{ mt: 1, color: theme.palette.text.secondary, textTransform: 'uppercase' }}
                    >
                      {useCase.comingSoon}
                    </Label>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </ConnectlyCard>
  );
};
