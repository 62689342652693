import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import {
  generateSettingsCategoryRoute,
  generateSettingsRoute,
  SETTINGS_CATEGORY_GENERAL,
  SETTINGS_CATEGORY_CAMPAIGNS,
  SETTINGS_CATEGORY_FEATURES,
  SETTINGS_CATEGORY_QUICK_REPLY,
  SETTINGS_CATEGORY_USERS,
  SETTINGS_CATEGORY_TAG,
  SETTINGS_VTEX,
  SettingsCategoryRouteId,
  SubpageRouteArgs,
} from '@connectlyai-sdks/inbox-navigation';
import { useContextSelector } from 'use-context-selector';
import { NavigationContext } from '../../../../contexts';

const homeRoute = generateSettingsRoute();
const generalRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_GENERAL });
const campaignsRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_CAMPAIGNS });
const featuresRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_FEATURES });
const quickReplyRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_QUICK_REPLY });
const usersRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_USERS });
const tagRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_CATEGORY_TAG });
const vtexRoute = generateSettingsCategoryRoute({ categoryId: SETTINGS_VTEX });

export const useNavigator = () => {
  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForSettings);

  const settingsIdRouteMatch = useRouteMatch<SubpageRouteArgs>(
    navigatorProvider().generateGlobalRouteId(SettingsCategoryRouteId),
  );

  const navigateToSettingsCategoryRoute = (args: SubpageRouteArgs) =>
    navigatorProvider().navigate(generateSettingsCategoryRoute(args));

  const routes = useMemo(() => {
    return {
      home: {
        pathname: navigatorProvider().generateGlobalPath(homeRoute),
        navigate: () => navigatorProvider().navigate(homeRoute),
      },
      general: {
        pathname: navigatorProvider().generateGlobalPath(generalRoute),
        navigate: () => navigatorProvider().navigate(generalRoute),
      },
      campaigns: {
        pathname: navigatorProvider().generateGlobalPath(campaignsRoute),
        navigate: () => navigatorProvider().navigate(campaignsRoute),
      },
      features: {
        pathname: navigatorProvider().generateGlobalPath(featuresRoute),
        navigate: () => navigatorProvider().navigate(featuresRoute),
      },
      quickReply: {
        pathname: navigatorProvider().generateGlobalPath(quickReplyRoute),
        navigate: () => navigatorProvider().navigate(quickReplyRoute),
      },
      users: {
        pathname: navigatorProvider().generateGlobalPath(usersRoute),
        navigate: () => navigatorProvider().navigate(usersRoute),
      },
      tags: {
        pathname: navigatorProvider().generateGlobalPath(tagRoute),
        navigate: () => navigatorProvider().navigate(tagRoute),
      },
      vtex: {
        pathname: navigatorProvider().generateGlobalPath(vtexRoute),
        navigate: () => navigatorProvider().navigate(vtexRoute),
      },
    };
  }, [navigatorProvider]);
  return { routes, settingsIdRouteMatch, navigateToSettingsCategoryRoute };
};
