import Cookie from 'js-cookie';
export const defaultFeatureFlags = {
    ffEnableAutoReplyBodyVariables: false,
    ffEnableWebpageToProduct: false,
    ffEnableDebugFlow: false,
    ffEnableForceSMB: false,
    ffEnableRecommendedCampaign: false,
    ffEnableOldAudience: false,
    ffEnableReminders: false,
    ffEnableDebugTypography: false,
    ffEnableNewTemplateButtons: false,
    ffEnableETA: true,
    ffEnableAINode: false,
    enableInboundCommand: false,
    ffEnableNewSettings: true,
    ffEnableSpacesInHeaderVariable: false,
    ffEnableNonButtonSourceHandle: false,
    ffEnableETAProductReviews: true,
    ffEnableAPINode: false,
    ffEnableNewHome: true,
    ffEnableSofiaOnboarding: true,
    ffBusinessServiceProviderVTEX: false,
    ffEnableRemoveSofia: false,
    ffEnableRemoveSofiaOverride: false,
    ffEnableOptOutUpload: true,
    ffEnableOptOutDelete: true,
    ffEnableSofiaAnalytics: true,
    ffEnableToggleAutoReply: true,
    ffBrazilCreditLine: false,
    ffEnableTimeDelayNode: true,
    ffEnableCampaignSettings: true,
    ffEnableSmartAudiences: false,
    ffEnableCampaignPresets: true,
    ffEnableGenerateCohort: false,
    ffEnableDeleteSmartAudience: false,
    ffEnableNewMessageNode: false,
    ffEnableWhatsAppV19: false,
    ffEnableNewTheme: true,
    ffEnableFlowBuilderAnnouncement: true,
    ffEnableNewActivityBar: true,
    ffHideVTEXSetup: true,
    ffHideShopifySetup: true,
    ffEnableHeaderSessionId: true,
    ffEnableSofiaWebchatTestButton: false,
    ffEnableCleanupVariables: true,
    ffEnableSofiaAnalyticsTimeline: true,
    ffEnableSofiaAnalyticsSentimentAnalysis: true,
    ffEnableSofiaAnalyticsFunnelAnalysis: true,
    ffEnableSofiaAnalyticsTopicsAnalysis: true,
    ffEnableSofiaAnalyticsWordsAnalysis: true,
    ffEnableCarousel: true,
    ffEnableCSAT: true,
    ffEnableVtexSettings: true,
    ffEnableCampaignsUTMSettings: false,
    ffEnableVirtualizedRoomsList: false,
    ffEnableSofiaSubpages: false,
    ffDevOnlyLimitCampaignsLoaded: false,
    ffEnableTemplateAutofix: false,
    ffEnableSofiaAIByDefault: false,
    ffEnableAnalyticsRedesign: false,
};
export const loadFeatureFlags = () => {
    const { search } = window.location;
    const query = new URLSearchParams(search);
    return Object.keys(defaultFeatureFlags).reduce((obj, key) => {
        const queryValue = query.get(key);
        if (queryValue !== null) {
            Cookie.set(key, queryValue);
            return {
                ...obj,
                [key]: queryValue === '1',
            };
        }
        const cookieValue = Cookie.get(key);
        if (cookieValue !== undefined) {
            return {
                ...obj,
                [key]: cookieValue === '1',
            };
        }
        return obj;
    }, { ...defaultFeatureFlags });
};
export const getFlagByName = (name) => {
    return getFlagByName.state[name];
};
export const setFlagByName = (name, value) => {
    getFlagByName.state[name] = value;
    Cookie.set(name, value ? '1' : '0');
};
getFlagByName.state = loadFeatureFlags();
