import React, { PropsWithChildren, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  ListItem,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { List, ListRowProps } from 'react-virtualized';
import { SIZE_CONTENT_S, SIZE_INPUT_XXL } from 'src/ui-theme';
import { CampaignAutocompleteProps, CampaignOption } from './types';

// state for this component should be managed by the parent component, it should provide the CampaignAutocompleteProps
// export const useCampaignAutocomplete = () => {};

// TODO: [jason] Create a ListBoxProps instead of any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomListboxComponent = React.forwardRef(function CustomListboxComponent(props: PropsWithChildren<any>, ref) {
  const { children, selectedOptions, options, handleSelectAll, role, hideSelectAllCampaigns = false, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;
  const { checked, indeterminate, checkboxLabel } = useMemo(() => {
    const visibleIds = options.map((option: CampaignOption) => option.id);
    const selectedIds = selectedOptions.map((option: CampaignOption) => option.id);
    const allSelected = visibleIds.every((item: string) => selectedIds.includes(item));
    const isIndeterminate = visibleIds.some((item: string) => selectedIds.includes(item));
    return {
      checked: allSelected,
      indeterminate: isIndeterminate,
      // eslint-disable-next-line no-nested-ternary
      checkboxLabel: allSelected ? 'Deselect all' : isIndeterminate ? 'Remove filtered selection' : 'Select all',
    };
  }, [selectedOptions, options]);

  return (
    <Box ref={ref}>
      <Box {...other}>
        {!hideSelectAllCampaigns && (
          <>
            <ListItem>
              <Checkbox checked={checked} indeterminate={indeterminate} onChange={handleSelectAll} />
              {checkboxLabel}
            </ListItem>
            <Divider />
          </>
        )}
        <List
          height={SIZE_CONTENT_S * 8}
          width={SIZE_INPUT_XXL * 8}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(rowProps: ListRowProps) => {
            return React.cloneElement(children[rowProps.index], {
              style: rowProps.style,
            });
          }}
          role={role}
        />
      </Box>
    </Box>
  );
});

const CampaignAutocomplete = ({
  hasFilter,
  handleInputChange,
  inputValue,
  campaignFilter,
  campaignOptions = [],
  handleCampaignChange,
  handleSelectAll,
  visibleOptions,
  hideSelectAllCampaigns = false,
  isFullWidth = false,
  onCheckboxChange,
}: CampaignAutocompleteProps) => {
  const theme = useTheme();

  return (
    <Autocomplete
      sx={{
        width: isFullWidth ? '100%' : '260px',
        minWidth: '260px',
        maxWidth: isFullWidth ? '100%' : '260px',

        background: theme.palette.common.white,
        '& .MuiAutocomplete-inputRoot': {
          padding: '0px 6px',
          minHeight: theme.spacing(4.75),
        },
        '& .MuiAutocomplete-endAdornment': {
          padding: '4px 6px',
        },
        '& .MuiAutocomplete-startAdornment': {
          padding: '4px 6px',
        },
        '& .MuiButtonBase-root': {
          height: '20px',
        },
        '& .MuiSvgIcon-root': {
          height: '20px',
        },
        '& .MuiInputLabel-root': {
          transform: hasFilter ? 'translate(14px, -9px) scale(0.75)' : 'translate(14px, 10px) scale(1)',
        },
        '& label.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '& label.MuiFormLabel-filled': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '& input.MuiAutocomplete-input': {
          padding: '4px 6px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[300],
        },
      }}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      multiple
      disableCloseOnSelect
      disableListWrap
      limitTags={1}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option: CampaignOption, index: number) => (
              <Chip
                {...getTagProps({ index })}
                key={option.id}
                label={option.label}
                // maxWidth hardcoded for now until design department rethinks this components
                sx={{ maxWidth: '125px!important' }}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={campaignFilter}
      options={campaignOptions}
      getOptionLabel={(option) => option.label || ''}
      onChange={handleCampaignChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={hasFilter ? 'Campaign' : 'All Campaigns'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
        />
      )}
      // eslint-disable-next-line react/no-unstable-nested-components
      ListboxComponent={(props) => (
        <CustomListboxComponent
          handleSelectAll={handleSelectAll}
          options={visibleOptions}
          selectedOptions={campaignFilter}
          hideSelectAllCampaigns={hideSelectAllCampaigns}
          {...props}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem {...props} key={option.id}>
            {Boolean(onCheckboxChange) && (
              <Checkbox
                style={{ marginRight: 1 }}
                checked={selected}
                onChange={(e, checked) => {
                  if (onCheckboxChange) {
                    onCheckboxChange(option?.id || '', checked);
                  }
                }}
              />
            )}
            {
              // eslint-disable-next-line no-extra-boolean-cast
              !Boolean(onCheckboxChange) && <Checkbox style={{ marginRight: 1 }} checked={selected} />
            }
            {option.label}
          </ListItem>
        );
      }}
    />
  );
};

export default CampaignAutocomplete;
