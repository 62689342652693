import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMeData,
  selectBusinessId,
  useAnalytics,
  selectVtexIntegrationId,
  useSettingsData,
  useFeatureFlag,
} from '@hooks';
import {
  Box,
  Card,
  ConnectlyCard,
  Label,
  Stack,
  TargetIcon,
  VtexLogoGreyIcon,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { CreateAudienceModal } from '@scenes/Audience/components';
import { PillDisplay } from 'src/presentation/common/PillDisplay';
import { SIZE_SPACING_INTER_COMPONENT } from 'src/ui-theme';
import { useSetAtom } from 'jotai';
import { audiencePageEnhancementsAtom } from '@atoms/audience';
import { QueryTemplateType } from './types';
import { TEMPLATES, TEMPLATE_INFO } from '../../constants';

const Content = ({
  businessId,
  modalOpen,
  openModal,
  closeModal,
}: {
  businessId: string;
  modalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}) => {
  const { data: vtexIntegrationId } = useSettingsData({
    businessId: businessId || '',
    select: selectVtexIntegrationId,
  });
  const theme = useTheme();
  const { sendAnalytics } = useAnalytics();
  const trackFeatureInterest = useCallback(
    (templateName: QueryTemplateType) => {
      sendAnalytics('(audience) click coming soon template', { businessId, templateName });
    },
    [businessId, sendAnalytics],
  );
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { onboardingTitle, onboardingSubtitle } = useMemo(() => {
    return {
      onboardingTitle: t('audience.templates.onboardingTitle'),
      onboardingSubtitle: t('audience.templates.onboardingSubtitle'),
    };
  }, [t]);
  const templates = useMemo(() => {
    return TEMPLATES.filter((temp) => {
      if (!vtexIntegrationId && TEMPLATE_INFO[temp].vtex) {
        return false;
      }
      return true;
    }).map((temp) => {
      const { icon, backgroundColor, comingSoon, vtex } = TEMPLATE_INFO[temp];
      return {
        type: temp,
        title: t(`audience.templates.options.${temp}.title`),
        description: t(`audience.templates.options.${temp}.description`),
        icon,
        backgroundColor,
        comingSoon,
        vtex,
      };
    });
  }, [t, vtexIntegrationId]);
  const comingSoonText = useMemo(() => {
    return t('generic.comingSoon');
  }, [t]);

  return (
    <>
      <CreateAudienceModal
        businessId={businessId || ''}
        isOpen={modalOpen}
        closeModal={closeModal}
        audienceTypes={['query']}
      />
      <ConnectlyCard
        media={{ children: <TargetIcon width={theme.spacing(5)} height={theme.spacing(5)} /> }}
        title={onboardingTitle}
        subtitle={onboardingSubtitle}
        sx={{
          backgroundColor: 'rgba(130, 93, 225, 0.08)',
          boxShadow: 'none',
        }}
        p={2}
      />
      <Stack direction="row" gap={2} sx={{ flexWrap: 'wrap' }}>
        {templates.map((template) => {
          return (
            <Card
              variant="main"
              key={`template-${template.title}`}
              onClick={template.comingSoon ? () => trackFeatureInterest(template.type) : openModal}
              sx={{
                opacity: template.comingSoon ? 0.5 : 1,
                flex: `1 1 ${theme.spacing(38)}`,
                maxWidth: theme.spacing(38),
                p: 2,
                cursor: template.comingSoon ? 'default' : 'pointer',
              }}
            >
              <Box
                sx={{
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                  flex: `0 0 ${theme.spacing(4)}`,
                  backgroundColor: template.backgroundColor,
                  borderRadius: theme.spacing(1),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {template.icon}
              </Box>
              <Stack justifyContent="space-between" width="100%">
                <Box sx={{ mb: 2 }}>
                  <Label variant="body1" sx={{ fontWeight: '700 !important', mb: SIZE_SPACING_INTER_COMPONENT }}>
                    {template.title}
                  </Label>
                  <Label variant="body1">{template.description}</Label>
                </Box>
                {(template.comingSoon || template.vtex) && (
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    {template.comingSoon && (
                      <Box width="fit-content">
                        <PillDisplay sx={{ textTransform: 'uppercase' }}>
                          <Label variant="body2">{comingSoonText}</Label>
                        </PillDisplay>
                      </Box>
                    )}
                    {template.vtex && <VtexLogoGreyIcon width={theme.spacing(6)} height={theme.spacing(3)} />}
                  </Stack>
                )}
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </>
  );
};

const useTemplates = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'audience.templates' });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const { buttonText } = useMemo(() => {
    return {
      buttonText: t('createAudienceText'),
    };
  }, [t]);
  return {
    businessId,
    buttonText,
    closeModal,
    modalOpen,
    openModal,
  };
};

export const Templates = () => {
  const { buttonText, businessId, openModal, modalOpen, closeModal } = useTemplates();
  const updatePageEnhancements = useSetAtom(audiencePageEnhancementsAtom);
  useEffect(() => {
    updatePageEnhancements({
      primaryButton: {
        children: buttonText,
        track: [
          'click new_audience_button',
          {
            businessId,
            from: 'list',
          },
        ],
        onClick: openModal,
      },
    });
  }, [businessId, buttonText, openModal, updatePageEnhancements]);
  useEffect(() => {
    return () => {
      updatePageEnhancements({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Content businessId={businessId || ''} modalOpen={modalOpen} openModal={openModal} closeModal={closeModal} />;
};
