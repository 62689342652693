import { BusinessDataStore } from '@connectlyai-sdks/data-business';
import { createContext } from 'use-context-selector';

export type DataDeps = {
  businessDataStoreProvider: () => BusinessDataStore;
};

// TODO the following is a bit hacky
// consider the solution outlined in
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DataContext = createContext<DataDeps>(undefined!);
