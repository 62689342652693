import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_DELETE_TAG_URL = '/v1/businesses/{businessId}/tags/{tagId}';

type MutationDeleteTagUrl = typeof MUTATION_DELETE_TAG_URL;

export type MutationDeleteTagResponse =
  Paths['profileV1'][MutationDeleteTagUrl]['delete']['responses']['200']['schema'];
export type MutationDeleteTagParameters = Paths['profileV1'][MutationDeleteTagUrl]['delete']['parameters']['path'];

export type MutationDeleteTagOptions = Omit<
  UseMutationOptions<MutationDeleteTagResponse, ConnectlyError, MutationDeleteTagParameters, unknown>,
  'mutationKey' | 'mutationFn'
>;
