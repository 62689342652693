import { Business } from '@connectlyai-idl/models/dist/models/profile/models';
export const queryBusinessProvider = (log, networkClient) => async (businessId) => {
    const url = `/v1/businesses/${businessId}`;
    log.debug('queryBusiness@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.get(url);
        log.debug('queryBusiness@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return Business.fromJSON(resp.data.entity);
    }
    catch (err) {
        log.warn('queryBusiness@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
