import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { flatMap, startCase } from 'lodash';
import { selectEdges, selectNodes } from '../../state/flow';
import { HandleActionType, createHandleId } from '../../sdks/flow/createHandleId';
import {
  TemplateComponent,
  selectMessageTemplateIndex,
  useMessageTemplateGroupsData,
} from '../useMessageTemplateGroupsData';
import { useMeData } from '../useMeData';
import { selectBusinessId } from '../useMeData/selectors';
import { ButtonError, selectInvalidTemplates, selectPendingTemplates } from '../../state/messageTemplates';
import { Warning } from './types';

export const useFlowChecks = () => {
  const nodes = useSelector(selectNodes);
  const edges = useSelector(selectEdges);
  const invalidTemplates = useSelector(selectInvalidTemplates);
  const pendingTemplates = useSelector(selectPendingTemplates);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: templateIndex = {}, isFetching: isTemplateFetching } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateIndex,
    keepPreviousData: true,
  });

  const templateNotFound = useMemo(
    () =>
      nodes.filter((node) => {
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN') return false;
        if (node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT') return false;
        if (!node?.data?.v1?.templateId) return false;
        if (templateIndex[node?.data?.v1?.templateId] || templateIndex[node?.data?.v1?.waMessageTemplateId])
          return false;
        if (pendingTemplates.find(([key]) => node?.id === key)) return false;
        return true;
      }),
    [nodes, templateIndex],
  );

  const strayNodes = useMemo(
    () =>
      nodes.filter((node) => {
        if (
          node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN' ||
          node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT'
        )
          return false;
        return !edges.some((edge) => edge.target === node.id);
      }),
    [nodes, edges],
  );

  const quickReplyButtons = useMemo(
    () =>
      flatMap(
        nodes.map((node) =>
          node?.data?.v1?.messageTemplateInput?.templateComponents
            ? node.data.v1.messageTemplateInput.templateComponents
                .filter((component: TemplateComponent) => !!component.button && !!component.button.quickReply)
                .map((button: TemplateComponent, buttonIndex: number) => ({
                  button,
                  node,
                  buttonIndex,
                }))
            : [],
        ),
      ),
    [nodes],
  );

  const unconnectedQuickReplyButtons = useMemo(
    () =>
      quickReplyButtons.filter(({ node, buttonIndex }) => {
        const handleType = {
          nodeId: node.id,
          nodeType: node.type,
          actionType: 'button-click' as HandleActionType,
          buttonIndex,
        };
        const handleId = createHandleId(handleType);
        return !edges.find((edge) => edge.sourceHandle === handleId);
      }),
    [quickReplyButtons, edges],
  );

  const nodesWithTemplateErrors = useMemo(
    () =>
      flatMap(
        nodes
          .map((node) => ({
            node,
            invalidTemplate: invalidTemplates.find(([key]) => node?.id === key)?.[1],
          }))
          .filter(({ invalidTemplate }) => !!invalidTemplate)
          .map(({ node, invalidTemplate }) =>
            invalidTemplate?.errors
              ? Object.entries(invalidTemplate?.errors)
                  .filter(([_, error]) => !!error)
                  .map(([part, error]) => ({ part, error, node }))
              : [],
          ),
      ),
    [nodes, invalidTemplates],
  );

  const nodesWithUnconfiguredTemplates = useMemo(
    () =>
      nodes.filter(
        (node) =>
          node?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE' && !node?.data?.v1?.messageTemplateInput,
      ),
    [nodes],
  );

  const nodesWithPendingTemplates = useMemo(
    () => nodes.filter((node) => pendingTemplates.find(([key]) => node?.id === key)),
    [nodes, pendingTemplates],
  );

  const warnings: Warning[] = useMemo(
    () =>
      [
        ...nodesWithTemplateErrors.map(({ node, part, error }) => {
          const nodeName = node?.data?.v1?.messageTemplateInput?.name;
          const warning = `${startCase(part)} component in this message is incomplete.`;
          const key = `invalid-template-${node.id}`;
          let details;
          try {
            switch (part) {
              case 'buttons':
                details = (error as Partial<ButtonError>[])
                  .map((buttonError) => Object.values(buttonError).join(', '))
                  .filter(Boolean)
                  .join(', ');
                break;
              case 'list':
                details = (error as string[]).join(', ');
                break;
              default:
                details = error as string;
                break;
            }
          } finally {
            details = 'Click on the message to fix errors.';
          }
          return { warning, node, nodeName, key, details };
        }),
        ...unconnectedQuickReplyButtons.map(({ node, button, buttonIndex }) => {
          const nodeName = node?.data?.v1?.messageTemplateInput?.name;
          const warning = `Quick reply button is not connected to a message.`;
          const key = `unconnected-quick-reply-button-${node.id}-${buttonIndex}`;
          const label = button?.button?.quickReply?.text;
          const details = `Connect button ${label ? `"${label}" ` : ''}to a message`;
          return { warning, node, nodeName, button, buttonIndex, key, details };
        }),
        ...strayNodes.map((node) => {
          const nodeName = node?.data?.v1?.messageTemplateInput?.name;
          const warning = 'This message is not linked to another message.';
          const key = `stray-node-${node.id}`;
          const details = 'Connect a button to this message.';
          return { warning, node, nodeName, key, details };
        }),
        ...templateNotFound.map((node) => {
          const nodeName = node?.data?.v1?.name;
          const warning = 'This message does not exist anymore.';
          const details = 'Create a new message to replace this one.';
          const key = `template-not-found-${node.id}`;
          return { warning, node, nodeName, key, details };
        }),
        ...nodesWithUnconfiguredTemplates.map((node) => {
          const nodeName = node?.data?.v1?.name;
          const warning = 'This message has no content.';
          const details = 'Click on the message to fill in content.';
          const key = `template-not-configured-${node.id}`;
          return { warning, node, nodeName, key, details };
        }),
        ...nodesWithPendingTemplates.map((node) => {
          const nodeName = node?.data?.v1?.messageTemplateInput?.name;
          const warning = 'Pending Whatsapp approval for this message.';
          const details = 'Please wait until this message is approved.';
          const key = `template-pending-${node.id}`;
          return { warning, node, nodeName, key, details };
        }),
      ].sort((a, b) => a.nodeName?.localeCompare(b.nodeName)),
    [
      unconnectedQuickReplyButtons,
      strayNodes,
      templateNotFound,
      nodesWithTemplateErrors,
      nodesWithUnconfiguredTemplates,
      nodesWithPendingTemplates,
    ],
  );

  return { warnings, isIdle: isTemplateFetching && templateNotFound.length > 0 };
};
