import { useCallback } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  audienceAtom,
  campaignStepAtom,
  documentTypeAtom,
  documentVersionIdAtom,
  isFlowChecksOpenAtom,
  isSpreadsheetOpenAtom,
  nodesAtom,
  spreadsheetURLAtom,
  templateApprovalDialogOpenAtom,
} from '@atoms/flow';
import { useSelector } from 'react-redux';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { useTemplateApprovalDialog } from '@hooks/useTemplateApprovalDialog';
import { AudienceOptionType } from '@components/FlowChartCampaignV3/components/ChooseAudience';
import { useVerifySendoutResultData, useAnalytics } from '@hooks';
import { useCampaignV3 } from '../useCampaignV3';
import { useFlowChecks } from '../useFlowChecks';
import { mapToNextStepDefault, mapToPreviousStep } from './mappers';
import { selectCampaignDate } from '../../../../state/flow';

export const useCampaignStep = () => {
  const [campaignStep, setCampaignStep] = useAtom(campaignStepAtom);
  const nodes = useAtomValue(nodesAtom);

  const audience = useAtomValue(audienceAtom);
  const setIsFlowChecksOpen = useSetAtom(isFlowChecksOpenAtom);
  const setIsSpreadsheetOpen = useSetAtom(isSpreadsheetOpenAtom);
  const setTemplateApprovalDialogOpen = useSetAtom(templateApprovalDialogOpenAtom);
  const setDocumentType = useSetAtom(documentTypeAtom);
  const setDocumentVersionId = useSetAtom(documentVersionIdAtom);
  const setSpreadsheetURL = useSetAtom(spreadsheetURLAtom);

  // TODO: Migrate campaignDate to atom
  const campaignDate = useSelector(selectCampaignDate);

  const { isNeedingTemplateApproval } = useTemplateApprovalDialog();

  const { warnings } = useFlowChecks();
  const { handleDeploy, handleInitializeSpreadsheet } = useCampaignV3();
  const { reset: resetVerify } = useVerifySendoutResultData();

  const { sendAnalytics } = useAnalytics();

  const sendAnalyticsForStep = useCallback(() => {
    switch (campaignStep) {
      case 'build': {
        sendAnalytics('clicks continue_to_audience v3');
        break;
      }
      case 'audience': {
        sendAnalytics('clicks continue_to_cohort v3', { audienceType: audience.type });
        break;
      }
      case 'recipients': {
        sendAnalytics('clicks continue_to_schedule v3');
        break;
      }
      case 'schedule': {
        sendAnalytics('clicks submit_campaign_template_custom v3');

        // #Deprecated
        const aiUsed = nodes.some((node) => Boolean(node?.data?.v1?.prompt));
        if (aiUsed) {
          sendAnalytics('clicks submit_campaign_with_ai v3');
        } else {
          sendAnalytics('clicks submit_campaign_no_ai v3');
        }
        if (campaignDate) {
          sendAnalytics('clicks submit_campaign_with_schedule v3');
        } else {
          sendAnalytics('clicks submit_campaign_with_submit_now v3');
        }
        const listMesageUsed = nodes.some((node) =>
          Boolean(node?.data?.v1?.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE'),
        );
        if (listMesageUsed) {
          sendAnalytics('clicks submit_campaign_with_list_message v3');
        }
        const optOutUsed = nodes.some((node) => Boolean(node?.type === 'FLOW_OBJECT_TYPE_OPT_OUT'));
        if (optOutUsed) {
          sendAnalytics('clicks submit_campaign_with_opt_out v3');
        }
        // #end

        const messageTypesUsed = Object.keys(
          nodes.reduce<Record<string, number>>((map, val) => {
            const key = val?.data?.v1?.waMessageTemplateType;
            if (key) {
              const currVal = map[key];
              if (currVal) {
                map[key] = currVal + 1;
              } else {
                map[key] = 1;
              }
            }
            return map;
          }, {}),
        );
        const nodeTypesUsed = Object.keys(
          nodes.reduce<Record<string, number>>((map, val) => {
            const key = val.type;
            if (key) {
              const currVal = map[key];
              if (currVal) {
                map[key] = currVal + 1;
              } else {
                map[key] = 1;
              }
            }
            return map;
          }, {}),
        );
        sendAnalytics('clicks submit_campaign_any v3', {
          nodeTypesUsed,
          messageTypesUsed,
          withAi: aiUsed,
          withSchedule: Boolean(campaignDate),
        });
        break;
      }
      case 'preface':
      case 'compile':
      case 'approval':
      case 'conclusion':
        break;
      default: {
        assertUnreachable(campaignStep);
      }
    }
  }, [audience, campaignDate, campaignStep, nodes, sendAnalytics]);

  const goToNextCampaignStep = useCallback(() => {
    sendAnalyticsForStep();
    if (campaignStep === 'build') {
      if (warnings.length > 0) {
        setIsFlowChecksOpen(true);
        return;
      }
      setDocumentVersionId('');
      setSpreadsheetURL('');
    }
    if (campaignStep === 'compile') {
      if (isNeedingTemplateApproval) {
        setTemplateApprovalDialogOpen(true);
        setCampaignStep('approval');
        return;
      }
    }
    if (campaignStep === 'audience') {
      if (audience.type === AudienceOptionType.API) {
        // In the case of an API audience skip the spreadsheet and schedule steps.
        handleDeploy();
        setCampaignStep('conclusion');
        return;
      }
      setIsSpreadsheetOpen(false);
      setSpreadsheetURL('');
      handleInitializeSpreadsheet();
      resetVerify();
    }
    if (campaignStep === 'schedule') {
      handleDeploy();
    }

    setCampaignStep(mapToNextStepDefault(campaignStep));
  }, [
    campaignStep,
    handleDeploy,
    handleInitializeSpreadsheet,
    isNeedingTemplateApproval,
    resetVerify,
    sendAnalyticsForStep,
    setCampaignStep,
    setDocumentVersionId,
    setIsFlowChecksOpen,
    setIsSpreadsheetOpen,
    setSpreadsheetURL,
    setTemplateApprovalDialogOpen,
    warnings,
    audience.type,
  ]);

  const goToPreviousCampaignStep = useCallback(() => {
    setCampaignStep(mapToPreviousStep(campaignStep));
  }, [campaignStep, setCampaignStep]);

  const goBackToPreface = useCallback(() => {
    setDocumentType(undefined);
    setCampaignStep('preface');
  }, [setCampaignStep, setDocumentType]);

  return { campaignStep, goBackToPreface, goToNextCampaignStep, goToPreviousCampaignStep };
};
