import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_SOFIA_STORE_DESCRIPTOR_V2 =
  '/internal/v1/businesses/{input.businessId}/ai/store_descriptor_v2/register/v1';

type MutationUpdateSofiaData = typeof MUTATION_SOFIA_STORE_DESCRIPTOR_V2;

export type MutationUpdateSofiaV2Response =
  Paths['aiV1'][MutationUpdateSofiaData]['post']['responses']['200']['schema'];

export type MutationUpdateSofiaV2Query = Paths['aiV1'][MutationUpdateSofiaData]['post']['parameters']['body']['input'];

export type MutationUpdateSofiaV2Params = { businessId: string } & MutationUpdateSofiaV2Query;

export type MutationUpdateSofiaV2DataOptions = Omit<
  UseMutationOptions<MutationUpdateSofiaV2Response, ConnectlyError, MutationUpdateSofiaV2Params, unknown>,
  'mutationKey' | 'mutationFn'
>;
