import React, { useMemo } from 'react';
import { useAnalytics } from '@hooks';
import {
  NavigationTabs as NavigationTabsComponent,
  NavigationTabsProps as NavigationTabsComponentProps,
} from '@connectlyai-tenets/ui-styled-web';
import { useLocation } from 'react-router';
import { NavigationTabsProps } from './types';

export const NavigationTabs = ({ routes }: NavigationTabsProps) => {
  const { sendAnalytics } = useAnalytics();
  const navigationRoutes: NavigationTabsComponentProps['routes'] = useMemo(() => {
    return routes.map((route: NavigationTabsProps['routes'][0]) => {
      return {
        display: route.display,
        pathname: route.pathname,
        onClick: () => {
          if (route.track) {
            sendAnalytics(...route.track);
          }
          if (route.navigate) {
            route.navigate();
          }
        },
      };
    });
  }, [routes, sendAnalytics]);

  const { pathname } = useLocation();
  return <NavigationTabsComponent currentPathname={pathname} routes={navigationRoutes} />;
};
