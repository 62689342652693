import { selectBusinessId, useMeData } from '@hooks';
import { useAtomValue } from 'jotai';
import { sofiaAnalyticsTimeRangesAtom as timeRangesAtom } from '@atoms/sofia';
import { useTableAnalyticsData } from '../useTableAnalyticsData';
import { useTableDataInRangesReturn, useTableDataInRangesProps } from './types';

export const useTableDataInRanges = ({
  timeRangeAtom = timeRangesAtom,
}: useTableDataInRangesProps): useTableDataInRangesReturn => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const timeRange = useAtomValue(timeRangeAtom);
  const { data: currentData } = useTableAnalyticsData({
    businessId: businessId || '',
    query: {
      timeRangeStart: timeRange[0].timeRangeStart,
      timeRangeEnd: timeRange[0].timeRangeEnd,
    },
    enabled: !!businessId,
  });
  const { data: previousData } = useTableAnalyticsData({
    businessId: businessId || '',
    query: {
      timeRangeStart: timeRange[1].timeRangeStart,
      timeRangeEnd: timeRange[1].timeRangeEnd,
    },
    enabled: !!businessId,
  });
  return {
    currentData: currentData || {},
    previousData: previousData || {},
    timeRangeStart: timeRange[0].timeRangeStart,
    timeRangeEnd: timeRange[0].timeRangeEnd,
  };
};
