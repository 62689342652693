import React, { useMemo } from 'react';
import { MessagingChannels } from '@scenes/Settings/components/MessagingChannels';
import { useMeData, selectRole } from '@hooks';
import {
  BusinessProfile,
  Profile,
  ChangePassword,
  WhatsappBusinessProfile,
  SettingsWhatsapp,
  SettingsShopify,
  WhatsappAPIKey,
  WebWidget,
} from '../../components';

const useGeneral = () => {
  const { data: role } = useMeData({ select: selectRole });
  const isAgent = useMemo(() => !role || role === 'ROLE_AGENT', [role]);
  return {
    isAgent,
  };
};

export const General = () => {
  const { isAgent } = useGeneral();

  return (
    <>
      <Profile />
      <ChangePassword />
      <BusinessProfile />
      <WhatsappBusinessProfile readOnly={isAgent} />
      <WhatsappAPIKey />
      <MessagingChannels readOnly={isAgent} />
      <SettingsWhatsapp />
      <SettingsShopify />
      <WebWidget />
    </>
  );
};
