import { Customer } from '@connectlyai-idl/models/dist/models/profile/models';
export const queryCustomerProvider = (log, networkClient) => async (businessId, customerId) => {
    const url = `/v1/businesses/${businessId}/customers/${customerId}`;
    log.debug('queryCustomer@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.get(url);
        log.debug('queryCustomer@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return Customer.fromJSON(resp.data.entity);
    }
    catch (err) {
        log.error('queryCustomer@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
