import React, { FC } from 'react';
import {
  Box,
  DeleteOutlineIcon,
  IconButton,
  InputAdornment,
  Label,
  ReplyIcon,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { QuickReplyProps } from './types';
import { BUTTON_TEXT_MAX_LENGTH } from '../constants';
import { getButtonLabel, getButtonText } from '../../../utils';

export const QuickReply: FC<QuickReplyProps> = ({ button, error, index, onChangeText, onDelete }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} id={`message-template-button${index}-content`}>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <ReplyIcon color="action" sx={{ width: 24, height: 24 }} />
        <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium', flex: '1 1 auto' }}>
          {getButtonLabel(button, index)}
        </Label>
        <IconButton size="small" onClick={() => onDelete(index)} sx={{ m: '-5px 0' }}>
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <TextField
        id={`message-template-button${index}-text`}
        fullWidth
        placeholder="Enter text"
        error={Boolean(error?.[index]?.text)}
        helperText={error?.[index]?.text}
        variant="outlined"
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        value={getButtonText(button)}
        inputProps={{ maxLength: BUTTON_TEXT_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${
              getButtonText(button).length
            }/${BUTTON_TEXT_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeText(e, index)}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { borderRadius: '10px' },
          '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
        }}
      />
    </Box>
  );
};
