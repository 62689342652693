import { Method, Parameter, APICallNodeUIState } from './types';

export const DEFAULT_API_CALL_NODE_UI: APICallNodeUIState = {
  url: {
    value: '',
    error: '',
  },
  method: {
    value: 'POST',
    error: '',
  },
  query: {
    active: false,
    parameters: [],
  },
  headers: {
    active: false,
    parameters: [],
  },
  body: {
    active: false,
    parameters: [],
  },
  dialogParam: null,
};

export const APICallParametersByMethod: Record<Method, Parameter[]> = {
  GET: ['query', 'headers'],
  POST: ['query', 'headers', 'body'],
  PUT: ['query', 'headers', 'body'],
  PATCH: ['query', 'headers', 'body'],
  DELETE: ['query', 'headers'],
};

export const Methods: Method[] = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
