import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ConnectlyCard, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { ConnectWhatsApp } from '../ConnectWhatsApp';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { SetupWhatsAppProps } from './types';

export const SetupWhatsApp = ({
  whatsAppClaimedNumber,
  whatsAppIsActive,
  whatsAppConnectVerificationCode,
}: SetupWhatsAppProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.home' });
  const theme = useTheme();
  return (
    <ConnectlyCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0',
          gap: SIZE_SPACING_INTER_COMPONENT,
        }}
      >
        <ConnectWhatsApp
          claimedNumber={whatsAppClaimedNumber}
          isActive={whatsAppIsActive}
          verificationCode={whatsAppConnectVerificationCode}
          title={t('campaign.connectWhatsapp')}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            width: theme.spacing(32),
            height: 'auto',
            objectFit: 'cover',
            objectPosition: 'top',
          }}
          draggable={false}
          component="img"
          alt=""
          src={`${process.env.PUBLIC_URL}/assets/welcome/phone.png`}
        />
      </Box>
    </ConnectlyCard>
  );
};
