import React, { useState, useCallback, useContext } from 'react';
import {
  Button,
  Label,
  Box,
  useTheme,
  CircularProgress,
  SpeechBubblesIcon,
  ConnectlyCard,
} from '@connectlyai-tenets/ui-styled-web';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useTranslation } from 'react-i18next';
import {
  selectBusinessId,
  selectIsSMBAutoReplyActive,
  selectSMBAutoReplyMessage,
  useMeData,
  useSMBAutoReplyQuery,
  useSMBAutoReplyMutation,
} from '../../hooks';
import { MessageEditorDialog } from './MessageEditorDialog';
import { DropdownToggle } from '../DropdownToggle/DropdownToggle';
import { NotificationContext } from '../../contexts';
import { SIZE_MEDIA_L } from '../../ui-theme';

const useSMBAutoReply = () => {
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCachedSMBAutoReplyActive, setCachedSMBAutoReplyActive] = useState(false);
  const [message, setMessage] = useState('');

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading: isSMBAutoReplyLoading } = useSMBAutoReplyQuery({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectIsSMBAutoReplyActive,
    onSuccess: (data) => {
      setCachedSMBAutoReplyActive(data || false);
    },
  });
  useSMBAutoReplyQuery({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectSMBAutoReplyMessage,
    onSuccess: (data) => {
      setMessage(data || '');
    },
  });

  const { mutate: mutateSMBAutoReply } = useSMBAutoReplyMutation({
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['SMBAutoReply'] });

      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to save auto-reply message.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });

  const handleChangeActive = useCallback(
    (active: boolean) => {
      setCachedSMBAutoReplyActive(active);
      mutateSMBAutoReply({
        businessId: businessId as string,
        autoReplyMessage: message,
        deployIntent: active ? 'FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY' : 'FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT',
      });
    },
    [businessId, mutateSMBAutoReply, message],
  );

  const handleEditMessage = useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);
  const handleMessageDialogSave = useCallback(
    (_message: string) => {
      if (_message && !message) setCachedSMBAutoReplyActive(true);
      setMessage(_message);
      setIsDialogOpen(false);
      mutateSMBAutoReply({
        businessId: businessId as string,
        autoReplyMessage: _message,
        deployIntent:
          isCachedSMBAutoReplyActive || !message
            ? 'FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY'
            : 'FLOW_DOCUMENT_DEPLOY_INTENT_DRAFT',
      });
    },
    [businessId, message, mutateSMBAutoReply, isCachedSMBAutoReplyActive, setIsDialogOpen],
  );

  const handleMessageDialogCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  return {
    handleChangeActive,
    handleEditMessage,
    handleMessageDialogSave,
    isCachedSMBAutoReplyActive,
    isDialogOpen,
    isSMBAutoReplyLoading,
    message,
    handleMessageDialogCancel,
  };
};

export const SMBAutoReply = () => {
  const theme = useTheme();
  const {
    handleChangeActive,
    handleEditMessage,
    handleMessageDialogSave,
    isCachedSMBAutoReplyActive,
    isDialogOpen,
    isSMBAutoReplyLoading,
    message,
    handleMessageDialogCancel,
  } = useSMBAutoReply();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.autoReplySection' });

  return (
    <ConnectlyCard title={t('autoReply')}>
      <Label variant="h6">{t('autoReply')}</Label>
      <Label color="text-secondary" variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {t('content')}
      </Label>
      {isSMBAutoReplyLoading && <CircularProgress size={32} />}
      {!isSMBAutoReplyLoading && message && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <DropdownToggle onChangeActive={handleChangeActive} isActive={isCachedSMBAutoReplyActive} />
          <Button variant="text" onClick={handleEditMessage}>
            {t('editMessage')}
          </Button>
        </Box>
      )}
      {!isSMBAutoReplyLoading && !message && (
        <Box>
          <Button variant="contained" onClick={handleEditMessage} sx={{ width: theme.spacing(12) }}>
            {t('activate')}
          </Button>
        </Box>
      )}
      <MessageEditorDialog
        open={isDialogOpen}
        message={message}
        onSave={handleMessageDialogSave}
        onCancel={handleMessageDialogCancel}
      />
      <Box sx={{ position: 'absolute', right: theme.spacing(2), bottom: theme.spacing(2) }}>
        <SpeechBubblesIcon width={theme.spacing(SIZE_MEDIA_L)} />
      </Box>
    </ConnectlyCard>
  );
};
