import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  UNINSTALL_INTEGRATION_URL,
  UninstallIntegrationVariables,
  UninstallIntegrationResponse,
  UninstallIntegrationOptions,
} from './types';

export const useUninstallIntegrationMutation = (options?: UninstallIntegrationOptions) => {
  return useMutation(
    (variables: UninstallIntegrationVariables) =>
      api.integrationV1(UNINSTALL_INTEGRATION_URL, 'post', {
        path: { businessId: variables.businessId, integrationId: variables.integrationId },
      }) as Promise<UninstallIntegrationResponse>,
    {
      ...options,
    },
  );
};
