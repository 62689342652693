import React, { useMemo, useRef } from 'react';
import ReactFlow, { MarkerType, Background, Controls, ReactFlowInstance } from 'react-flow-renderer';
import { useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useNodeTypes } from '@components/FlowChartCampaignV3/hooks/useNodeTypes/useNodeTypes';
import { FlowChartCustomEdge } from '@components/FlowChartCustomEdge';
import { useReadOnlyFlowChangeAppliers, useReadOnlyFlowHandlers } from '@hooks';
import { FlowContext, FlowDeps } from '../../../../contexts';
import { ReadOnlyFlowProps } from './types';

export const useReadOnlyFlow = ({ edges, nodes }: ReadOnlyFlowProps) => {
  const flowChangeAppliers = useReadOnlyFlowChangeAppliers();
  const flowHandlers = useReadOnlyFlowHandlers();

  const flowDeps: FlowDeps = useMemo(
    () => ({
      flowChangeAppliers,
      flowHandlers,
      isDocumentEditable: false,
      isNodePotentialTarget: () => false,
      supportsLinkTracking: false,
      supportsVariables: false,
    }),
    [flowChangeAppliers, flowHandlers],
  );

  const theme = useTheme();
  const defaultEdgeOptions = useMemo(
    () => ({
      markerEnd: { type: MarkerType.ArrowClosed, color: theme.palette.connectionLine },
      style: { stroke: theme.palette.connectionLine, strokeWidth: 2, fill: 'none' },
      type: 'FLOW_OBJECT_TYPE_SIMPLE_EDGE',
    }),
    [theme.palette.connectionLine],
  );

  const nodeTypes = useNodeTypes();
  const edgeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SIMPLE_EDGE: FlowChartCustomEdge,
    }),
    [],
  );

  const reactFlowInstance = useRef<ReactFlowInstance>();
  const onInit = (instance: ReactFlowInstance) => {
    reactFlowInstance.current = instance;
  };

  const reactFlowProps = {
    defaultEdgeOptions,
    deleteKeyCode: null,
    edges,
    edgeTypes,
    defaultZoom: 0.8,
    maxZoom: 2.5,
    minZoom: 0.1,
    multiSelectionKeyCode: null,
    nodes,
    nodeTypes,
    onInit,
    proOptions: { account: 'paid-custom', hideAttribution: true },
    selectionKeyCode: null,
    selectNodesOnDrag: false,
    snapToGrid: true,
  };

  return {
    flowDeps,
    reactFlowProps,
  };
};

export const ReadOnlyFlow = ({ edges, nodes }: ReadOnlyFlowProps) => {
  const { flowDeps, reactFlowProps } = useReadOnlyFlow({ edges, nodes });

  return (
    <FlowContext.Provider value={flowDeps}>
      <ReactFlow {...reactFlowProps}>
        <Background />
        <Controls showInteractive={false} />
      </ReactFlow>
    </FlowContext.Provider>
  );
};
