import { useCallback } from 'react';
import { Node } from 'react-flow-renderer';
import { useAtomValue } from 'jotai';
import { reactFlowInstanceAtom } from '@atoms/flow';

export const useCenterToNode = () => {
  const reactFlowInstance = useAtomValue(reactFlowInstanceAtom);
  const centerToNode = useCallback(
    (node: Node | undefined, zoomIn?: boolean) => {
      if (!node || !reactFlowInstance) return;

      reactFlowInstance?.setCenter(node.position.x + 150, node.position.y + 150, {
        zoom: zoomIn ? 1.75 : reactFlowInstance?.getZoom(),
        duration: 600,
      });

      if (!zoomIn) return;

      setTimeout(() => {
        reactFlowInstance?.setCenter(node.position.x + 150, node.position.y + 150, {
          zoom: zoomIn ? 2 : reactFlowInstance?.getZoom(),
          duration: 600,
        });
      }, 600);
    },
    [reactFlowInstance],
  );
  return { centerToNode };
};
