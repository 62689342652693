import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.error";
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["ERROR_CODE_UNSPECIFIED"] = 0] = "ERROR_CODE_UNSPECIFIED";
    ErrorCode[ErrorCode["ERROR_CODE_BAD_REQUEST"] = 1] = "ERROR_CODE_BAD_REQUEST";
    ErrorCode[ErrorCode["ERROR_CODE_NOT_FOUND"] = 2] = "ERROR_CODE_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_UNAUTHORIZED"] = 3] = "ERROR_CODE_UNAUTHORIZED";
    ErrorCode[ErrorCode["ERROR_CODE_UNAUTHENTICATED"] = 4] = "ERROR_CODE_UNAUTHENTICATED";
    ErrorCode[ErrorCode["ERROR_CODE_CONFLICT"] = 5] = "ERROR_CODE_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_CONTEXT_CANCELED"] = 6] = "ERROR_CODE_CONTEXT_CANCELED";
    ErrorCode[ErrorCode["ERROR_CODE_DEADLINE_EXCEEDED"] = 7] = "ERROR_CODE_DEADLINE_EXCEEDED";
    ErrorCode[ErrorCode["ERROR_CODE_UNCONFIRMED_USER"] = 1001] = "ERROR_CODE_UNCONFIRMED_USER";
    ErrorCode[ErrorCode["ERROR_CODE_NONCOMPLIANT_PASSWORD"] = 1002] = "ERROR_CODE_NONCOMPLIANT_PASSWORD";
    ErrorCode[ErrorCode["ERROR_CODE_CREATE_USER_CONFLICT"] = 1003] = "ERROR_CODE_CREATE_USER_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_CREATE_BUSINESS_CONFLICT"] = 1004] = "ERROR_CODE_CREATE_BUSINESS_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_ASSOCIATE_SMS_CONFLICT"] = 1005] = "ERROR_CODE_ASSOCIATE_SMS_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT"] = 1006] = "ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_BUSINESS_NOT_FOUND"] = 1007] = "ERROR_CODE_BUSINESS_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_CUSTOMER_NOT_FOUND"] = 1008] = "ERROR_CODE_CUSTOMER_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_UNAUTHORIZED_USER"] = 1009] = "ERROR_CODE_UNAUTHORIZED_USER";
    ErrorCode[ErrorCode["ERROR_CODE_INVALID_BUSINESS_CHANNEL"] = 1010] = "ERROR_CODE_INVALID_BUSINESS_CHANNEL";
    ErrorCode[ErrorCode["ERROR_CODE_INVALID_CUSTOMER_CHANNEL"] = 1011] = "ERROR_CODE_INVALID_CUSTOMER_CHANNEL";
    ErrorCode[ErrorCode["ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED"] = 2001] = "ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED";
    ErrorCode[ErrorCode["ERROR_CODE_EVENT_ALREADY_MARKED_SENT"] = 2002] = "ERROR_CODE_EVENT_ALREADY_MARKED_SENT";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TOO_LONG"] = 2003] = "ERROR_CODE_MESSAGE_TOO_LONG";
    ErrorCode[ErrorCode["ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND"] = 2004] = "ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID"] = 2005] = "ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_MISSING_BUSINESS_CHANNEL"] = 2006] = "ERROR_CODE_MISSING_BUSINESS_CHANNEL";
    ErrorCode[ErrorCode["ERROR_CODE_PLATFORM_INVOCATION_ERROR"] = 3001] = "ERROR_CODE_PLATFORM_INVOCATION_ERROR";
    ErrorCode[ErrorCode["ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST"] = 3002] = "ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST";
    ErrorCode[ErrorCode["ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT"] = 3003] = "ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT";
    ErrorCode[ErrorCode["ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT"] = 4001] = "ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT";
    ErrorCode[ErrorCode["ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME"] = 4002] = "ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME";
    ErrorCode[ErrorCode["ERROR_CODE_ANOTHER_SENDOUT_RUNNING"] = 4003] = "ERROR_CODE_ANOTHER_SENDOUT_RUNNING";
    ErrorCode[ErrorCode["ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE"] = 4004] = "ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE";
    ErrorCode[ErrorCode["ERROR_CODE_ASSET_FETCHING_ERROR"] = 5001] = "ERROR_CODE_ASSET_FETCHING_ERROR";
    ErrorCode[ErrorCode["ERROR_CODE_ASSET_STAGING_ERROR"] = 5002] = "ERROR_CODE_ASSET_STAGING_ERROR";
    ErrorCode[ErrorCode["ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT"] = 5003] = "ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT";
    ErrorCode[ErrorCode["ERROR_CODE_INVALID_SENDER_ID"] = 6001] = "ERROR_CODE_INVALID_SENDER_ID";
    ErrorCode[ErrorCode["ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT"] = 6002] = "ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT";
    ErrorCode[ErrorCode["ERROR_CODE_PHONE_NUMBER_BAD_FORMAT"] = 7001] = "ERROR_CODE_PHONE_NUMBER_BAD_FORMAT";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID"] = 7002] = "ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEXT_EMPTY"] = 7003] = "ERROR_CODE_MESSAGE_TEXT_EMPTY";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND"] = 7004] = "ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID"] = 7005] = "ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY"] = 7006] = "ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_ATTACHMENT_INVALID"] = 7007] = "ERROR_CODE_MESSAGE_ATTACHMENT_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL"] = 7008] = "ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW"] = 7009] = "ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW";
    ErrorCode[ErrorCode["ERROR_CODE_BUSINESS_REACHED_LIMITS"] = 7010] = "ERROR_CODE_BUSINESS_REACHED_LIMITS";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_SELF_INVALID"] = 7011] = "ERROR_CODE_MESSAGE_SELF_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID"] = 7012] = "ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT"] = 7013] = "ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION"] = 7014] = "ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION";
    ErrorCode[ErrorCode["ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT"] = 7015] = "ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_DELETED"] = 7016] = "ERROR_CODE_MESSAGE_TEMPLATE_DELETED";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP"] = 7017] = "ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE"] = 70017] = "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME"] = 70018] = "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE"] = 70019] = "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND"] = 70020] = "ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM"] = 70021] = "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH"] = 70022] = "ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH";
    ErrorCode[ErrorCode["ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED"] = 70023] = "ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM"] = 70024] = "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM"] = 70026] = "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED"] = 70027] = "ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT"] = 70028] = "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS"] = 70029] = "ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS";
    ErrorCode[ErrorCode["ERROR_CODE_MISSING_PERMISSIONS"] = 70030] = "ERROR_CODE_MISSING_PERMISSIONS";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST"] = 70031] = "ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE"] = 70032] = "ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE";
    ErrorCode[ErrorCode["ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED"] = 70033] = "ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED";
    ErrorCode[ErrorCode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ErrorCode || (ErrorCode = {}));
export function errorCodeFromJSON(object) {
    switch (object) {
        case 0:
        case "ERROR_CODE_UNSPECIFIED":
            return ErrorCode.ERROR_CODE_UNSPECIFIED;
        case 1:
        case "ERROR_CODE_BAD_REQUEST":
            return ErrorCode.ERROR_CODE_BAD_REQUEST;
        case 2:
        case "ERROR_CODE_NOT_FOUND":
            return ErrorCode.ERROR_CODE_NOT_FOUND;
        case 3:
        case "ERROR_CODE_UNAUTHORIZED":
            return ErrorCode.ERROR_CODE_UNAUTHORIZED;
        case 4:
        case "ERROR_CODE_UNAUTHENTICATED":
            return ErrorCode.ERROR_CODE_UNAUTHENTICATED;
        case 5:
        case "ERROR_CODE_CONFLICT":
            return ErrorCode.ERROR_CODE_CONFLICT;
        case 6:
        case "ERROR_CODE_CONTEXT_CANCELED":
            return ErrorCode.ERROR_CODE_CONTEXT_CANCELED;
        case 7:
        case "ERROR_CODE_DEADLINE_EXCEEDED":
            return ErrorCode.ERROR_CODE_DEADLINE_EXCEEDED;
        case 1001:
        case "ERROR_CODE_UNCONFIRMED_USER":
            return ErrorCode.ERROR_CODE_UNCONFIRMED_USER;
        case 1002:
        case "ERROR_CODE_NONCOMPLIANT_PASSWORD":
            return ErrorCode.ERROR_CODE_NONCOMPLIANT_PASSWORD;
        case 1003:
        case "ERROR_CODE_CREATE_USER_CONFLICT":
            return ErrorCode.ERROR_CODE_CREATE_USER_CONFLICT;
        case 1004:
        case "ERROR_CODE_CREATE_BUSINESS_CONFLICT":
            return ErrorCode.ERROR_CODE_CREATE_BUSINESS_CONFLICT;
        case 1005:
        case "ERROR_CODE_ASSOCIATE_SMS_CONFLICT":
            return ErrorCode.ERROR_CODE_ASSOCIATE_SMS_CONFLICT;
        case 1006:
        case "ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT":
            return ErrorCode.ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT;
        case 1007:
        case "ERROR_CODE_BUSINESS_NOT_FOUND":
            return ErrorCode.ERROR_CODE_BUSINESS_NOT_FOUND;
        case 1008:
        case "ERROR_CODE_CUSTOMER_NOT_FOUND":
            return ErrorCode.ERROR_CODE_CUSTOMER_NOT_FOUND;
        case 1009:
        case "ERROR_CODE_UNAUTHORIZED_USER":
            return ErrorCode.ERROR_CODE_UNAUTHORIZED_USER;
        case 1010:
        case "ERROR_CODE_INVALID_BUSINESS_CHANNEL":
            return ErrorCode.ERROR_CODE_INVALID_BUSINESS_CHANNEL;
        case 1011:
        case "ERROR_CODE_INVALID_CUSTOMER_CHANNEL":
            return ErrorCode.ERROR_CODE_INVALID_CUSTOMER_CHANNEL;
        case 2001:
        case "ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED":
            return ErrorCode.ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED;
        case 2002:
        case "ERROR_CODE_EVENT_ALREADY_MARKED_SENT":
            return ErrorCode.ERROR_CODE_EVENT_ALREADY_MARKED_SENT;
        case 2003:
        case "ERROR_CODE_MESSAGE_TOO_LONG":
            return ErrorCode.ERROR_CODE_MESSAGE_TOO_LONG;
        case 2004:
        case "ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND":
            return ErrorCode.ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND;
        case 2005:
        case "ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID":
            return ErrorCode.ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID;
        case 2006:
        case "ERROR_CODE_MISSING_BUSINESS_CHANNEL":
            return ErrorCode.ERROR_CODE_MISSING_BUSINESS_CHANNEL;
        case 3001:
        case "ERROR_CODE_PLATFORM_INVOCATION_ERROR":
            return ErrorCode.ERROR_CODE_PLATFORM_INVOCATION_ERROR;
        case 3002:
        case "ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST":
            return ErrorCode.ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST;
        case 3003:
        case "ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT":
            return ErrorCode.ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT;
        case 4001:
        case "ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT":
            return ErrorCode.ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT;
        case 4002:
        case "ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME":
            return ErrorCode.ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME;
        case 4003:
        case "ERROR_CODE_ANOTHER_SENDOUT_RUNNING":
            return ErrorCode.ERROR_CODE_ANOTHER_SENDOUT_RUNNING;
        case 4004:
        case "ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE":
            return ErrorCode.ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE;
        case 5001:
        case "ERROR_CODE_ASSET_FETCHING_ERROR":
            return ErrorCode.ERROR_CODE_ASSET_FETCHING_ERROR;
        case 5002:
        case "ERROR_CODE_ASSET_STAGING_ERROR":
            return ErrorCode.ERROR_CODE_ASSET_STAGING_ERROR;
        case 5003:
        case "ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT":
            return ErrorCode.ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT;
        case 6001:
        case "ERROR_CODE_INVALID_SENDER_ID":
            return ErrorCode.ERROR_CODE_INVALID_SENDER_ID;
        case 6002:
        case "ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT":
            return ErrorCode.ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT;
        case 7001:
        case "ERROR_CODE_PHONE_NUMBER_BAD_FORMAT":
            return ErrorCode.ERROR_CODE_PHONE_NUMBER_BAD_FORMAT;
        case 7002:
        case "ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID":
            return ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID;
        case 7003:
        case "ERROR_CODE_MESSAGE_TEXT_EMPTY":
            return ErrorCode.ERROR_CODE_MESSAGE_TEXT_EMPTY;
        case 7004:
        case "ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND;
        case 7005:
        case "ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID;
        case 7006:
        case "ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY":
            return ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY;
        case 7007:
        case "ERROR_CODE_MESSAGE_ATTACHMENT_INVALID":
            return ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENT_INVALID;
        case 7008:
        case "ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL":
            return ErrorCode.ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL;
        case 7009:
        case "ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW":
            return ErrorCode.ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW;
        case 7010:
        case "ERROR_CODE_BUSINESS_REACHED_LIMITS":
            return ErrorCode.ERROR_CODE_BUSINESS_REACHED_LIMITS;
        case 7011:
        case "ERROR_CODE_MESSAGE_SELF_INVALID":
            return ErrorCode.ERROR_CODE_MESSAGE_SELF_INVALID;
        case 7012:
        case "ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID":
            return ErrorCode.ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID;
        case 7013:
        case "ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT":
            return ErrorCode.ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT;
        case 7014:
        case "ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION":
            return ErrorCode.ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION;
        case 7015:
        case "ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT":
            return ErrorCode.ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT;
        case 7016:
        case "ERROR_CODE_MESSAGE_TEMPLATE_DELETED":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DELETED;
        case 7017:
        case "ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP":
            return ErrorCode.ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP;
        case 70017:
        case "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE;
        case 70018:
        case "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME;
        case 70019:
        case "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE;
        case 70020:
        case "ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND;
        case 70021:
        case "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM;
        case 70022:
        case "ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH;
        case 70023:
        case "ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED":
            return ErrorCode.ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED;
        case 70024:
        case "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM":
            return ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM;
        case 70026:
        case "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM":
            return ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM;
        case 70027:
        case "ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED":
            return ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED;
        case 70028:
        case "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT;
        case 70029:
        case "ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS;
        case 70030:
        case "ERROR_CODE_MISSING_PERMISSIONS":
            return ErrorCode.ERROR_CODE_MISSING_PERMISSIONS;
        case 70031:
        case "ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST":
            return ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST;
        case 70032:
        case "ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE":
            return ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE;
        case 70033:
        case "ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED":
            return ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ErrorCode.UNRECOGNIZED;
    }
}
export function errorCodeToJSON(object) {
    switch (object) {
        case ErrorCode.ERROR_CODE_UNSPECIFIED:
            return "ERROR_CODE_UNSPECIFIED";
        case ErrorCode.ERROR_CODE_BAD_REQUEST:
            return "ERROR_CODE_BAD_REQUEST";
        case ErrorCode.ERROR_CODE_NOT_FOUND:
            return "ERROR_CODE_NOT_FOUND";
        case ErrorCode.ERROR_CODE_UNAUTHORIZED:
            return "ERROR_CODE_UNAUTHORIZED";
        case ErrorCode.ERROR_CODE_UNAUTHENTICATED:
            return "ERROR_CODE_UNAUTHENTICATED";
        case ErrorCode.ERROR_CODE_CONFLICT:
            return "ERROR_CODE_CONFLICT";
        case ErrorCode.ERROR_CODE_CONTEXT_CANCELED:
            return "ERROR_CODE_CONTEXT_CANCELED";
        case ErrorCode.ERROR_CODE_DEADLINE_EXCEEDED:
            return "ERROR_CODE_DEADLINE_EXCEEDED";
        case ErrorCode.ERROR_CODE_UNCONFIRMED_USER:
            return "ERROR_CODE_UNCONFIRMED_USER";
        case ErrorCode.ERROR_CODE_NONCOMPLIANT_PASSWORD:
            return "ERROR_CODE_NONCOMPLIANT_PASSWORD";
        case ErrorCode.ERROR_CODE_CREATE_USER_CONFLICT:
            return "ERROR_CODE_CREATE_USER_CONFLICT";
        case ErrorCode.ERROR_CODE_CREATE_BUSINESS_CONFLICT:
            return "ERROR_CODE_CREATE_BUSINESS_CONFLICT";
        case ErrorCode.ERROR_CODE_ASSOCIATE_SMS_CONFLICT:
            return "ERROR_CODE_ASSOCIATE_SMS_CONFLICT";
        case ErrorCode.ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT:
            return "ERROR_CODE_ASSOCIATE_EMAIL_CONFLICT";
        case ErrorCode.ERROR_CODE_BUSINESS_NOT_FOUND:
            return "ERROR_CODE_BUSINESS_NOT_FOUND";
        case ErrorCode.ERROR_CODE_CUSTOMER_NOT_FOUND:
            return "ERROR_CODE_CUSTOMER_NOT_FOUND";
        case ErrorCode.ERROR_CODE_UNAUTHORIZED_USER:
            return "ERROR_CODE_UNAUTHORIZED_USER";
        case ErrorCode.ERROR_CODE_INVALID_BUSINESS_CHANNEL:
            return "ERROR_CODE_INVALID_BUSINESS_CHANNEL";
        case ErrorCode.ERROR_CODE_INVALID_CUSTOMER_CHANNEL:
            return "ERROR_CODE_INVALID_CUSTOMER_CHANNEL";
        case ErrorCode.ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED:
            return "ERROR_CODE_EVENT_ALREADY_MARKED_MAPPED";
        case ErrorCode.ERROR_CODE_EVENT_ALREADY_MARKED_SENT:
            return "ERROR_CODE_EVENT_ALREADY_MARKED_SENT";
        case ErrorCode.ERROR_CODE_MESSAGE_TOO_LONG:
            return "ERROR_CODE_MESSAGE_TOO_LONG";
        case ErrorCode.ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND:
            return "ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND";
        case ErrorCode.ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID:
            return "ERROR_CODE_WABA_TEMPLATED_MESSAGE_INPUT_INVALID";
        case ErrorCode.ERROR_CODE_MISSING_BUSINESS_CHANNEL:
            return "ERROR_CODE_MISSING_BUSINESS_CHANNEL";
        case ErrorCode.ERROR_CODE_PLATFORM_INVOCATION_ERROR:
            return "ERROR_CODE_PLATFORM_INVOCATION_ERROR";
        case ErrorCode.ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST:
            return "ERROR_CODE_BAD_FACEBOOK_VERIFICATION_REQUEST";
        case ErrorCode.ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT:
            return "ERROR_CODE_PLATFORM_EXTERNAL_WEBHOOK_DELIVERY_TIMEOUT";
        case ErrorCode.ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT:
            return "ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT";
        case ErrorCode.ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME:
            return "ERROR_CODE_INVALID_CAMPAIGN_CONFIG_NAME";
        case ErrorCode.ERROR_CODE_ANOTHER_SENDOUT_RUNNING:
            return "ERROR_CODE_ANOTHER_SENDOUT_RUNNING";
        case ErrorCode.ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE:
            return "ERROR_CODE_CAMPAIGN_NO_LONGER_EDITABLE";
        case ErrorCode.ERROR_CODE_ASSET_FETCHING_ERROR:
            return "ERROR_CODE_ASSET_FETCHING_ERROR";
        case ErrorCode.ERROR_CODE_ASSET_STAGING_ERROR:
            return "ERROR_CODE_ASSET_STAGING_ERROR";
        case ErrorCode.ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT:
            return "ERROR_CODE_ASSET_EXCEEDS_SIZE_LIMIT";
        case ErrorCode.ERROR_CODE_INVALID_SENDER_ID:
            return "ERROR_CODE_INVALID_SENDER_ID";
        case ErrorCode.ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT:
            return "ERROR_CODE_FAILED_TO_DELIVER_WEBHOOK_EVENT";
        case ErrorCode.ERROR_CODE_PHONE_NUMBER_BAD_FORMAT:
            return "ERROR_CODE_PHONE_NUMBER_BAD_FORMAT";
        case ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID:
            return "ERROR_CODE_MESSAGE_ATTACHMENTS_NUMBER_INVALID";
        case ErrorCode.ERROR_CODE_MESSAGE_TEXT_EMPTY:
            return "ERROR_CODE_MESSAGE_TEXT_EMPTY";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND:
            return "ERROR_CODE_MESSAGE_TEMPLATE_NOT_FOUND";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID:
            return "ERROR_CODE_MESSAGE_TEMPLATE_INPUT_INVALID";
        case ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY:
            return "ERROR_CODE_MESSAGE_ATTACHMENT_EMPTY";
        case ErrorCode.ERROR_CODE_MESSAGE_ATTACHMENT_INVALID:
            return "ERROR_CODE_MESSAGE_ATTACHMENT_INVALID";
        case ErrorCode.ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL:
            return "ERROR_CODE_BUSINESS_MISSES_MATCHING_CHANNEL";
        case ErrorCode.ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW:
            return "ERROR_CODE_MESSAGE_OUTSIDE_OF_ELIGIBILITY_WINDOW";
        case ErrorCode.ERROR_CODE_BUSINESS_REACHED_LIMITS:
            return "ERROR_CODE_BUSINESS_REACHED_LIMITS";
        case ErrorCode.ERROR_CODE_MESSAGE_SELF_INVALID:
            return "ERROR_CODE_MESSAGE_SELF_INVALID";
        case ErrorCode.ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID:
            return "ERROR_CODE_MESSAGE_PHONE_NUMBER_INVALID";
        case ErrorCode.ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT:
            return "ERROR_CODE_MESSAGE_CUSTOMER_OPTED_OUT";
        case ErrorCode.ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION:
            return "ERROR_CODE_MESSAGE_CUSTOMER_NO_CONVERSATION_INITIATION";
        case ErrorCode.ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT:
            return "ERROR_CODE_BUSINESS_KEY_SECRET_ABSENT";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DELETED:
            return "ERROR_CODE_MESSAGE_TEMPLATE_DELETED";
        case ErrorCode.ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP:
            return "ERROR_CODE_MESSAGE_PHONE_NUMBER_NOT_ON_WHATSAPP";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE:
            return "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME:
            return "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_NAME";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE:
            return "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_LANGUAGE";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND:
            return "ERROR_CODE_MESSAGE_TEMPLATE_DELETE_NOT_FOUND";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM:
            return "ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH:
            return "ERROR_CODE_MESSAGE_TEMPLATE_CATEGORY_NOT_MATCH";
        case ErrorCode.ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED:
            return "ERROR_CODE_BUSINESS_RATE_LIMIT_REACHED";
        case ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM:
            return "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_BUTTON_PARAM";
        case ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM:
            return "ERROR_CODE_MESSAGE_WABA_TEMPLATE_INVALID_HEADER_PARAM";
        case ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED:
            return "ERROR_CODE_MESSAGE_WABA_TEMPLATES_LIMIT_REACHED";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT:
            return "ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_CONTENT";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS:
            return "ERROR_CODE_MESSAGE_TEMPLATE_VARIABLE_LENGTH_EXCEEDS";
        case ErrorCode.ERROR_CODE_MISSING_PERMISSIONS:
            return "ERROR_CODE_MISSING_PERMISSIONS";
        case ErrorCode.ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST:
            return "ERROR_CODE_MESSAGE_TEMPLATE_FAILED_TO_PERSIST";
        case ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE:
            return "ERROR_CODE_MESSAGE_WABA_TEMPLATE_STATUS_IS_NOT_EDITABLE";
        case ErrorCode.ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED:
            return "ERROR_CODE_MESSAGE_WABA_TEMPLATE_EDIT_REJECTED";
        case ErrorCode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseErrorCodeDetail() {
    return { errorType: 0, message: "", userTitle: "", userMessage: "" };
}
export const ErrorCodeDetail = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.errorType !== 0) {
            writer.uint32(8).int32(message.errorType);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        if (message.userTitle !== "") {
            writer.uint32(26).string(message.userTitle);
        }
        if (message.userMessage !== "") {
            writer.uint32(34).string(message.userMessage);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseErrorCodeDetail();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.errorType = reader.int32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.userTitle = reader.string();
                    break;
                case 4:
                    message.userMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            errorType: isSet(object.errorType) ? Number(object.errorType) : 0,
            message: isSet(object.message) ? String(object.message) : "",
            userTitle: isSet(object.userTitle) ? String(object.userTitle) : "",
            userMessage: isSet(object.userMessage) ? String(object.userMessage) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.errorType !== undefined &&
            (obj.errorType = Math.round(message.errorType));
        message.message !== undefined && (obj.message = message.message);
        message.userTitle !== undefined && (obj.userTitle = message.userTitle);
        message.userMessage !== undefined &&
            (obj.userMessage = message.userMessage);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
