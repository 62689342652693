import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.graphapi.v10_0.profile";
function createBaseProfile() {
    return {
        firstName: "",
        lastName: "",
        profilePic: "",
        locale: "",
        timezone: 0,
        gender: "",
        name: "",
    };
}
export const Profile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstName !== "") {
            writer.uint32(10).string(message.firstName);
        }
        if (message.lastName !== "") {
            writer.uint32(18).string(message.lastName);
        }
        if (message.profilePic !== "") {
            writer.uint32(26).string(message.profilePic);
        }
        if (message.locale !== "") {
            writer.uint32(34).string(message.locale);
        }
        if (message.timezone !== 0) {
            writer.uint32(40).int32(message.timezone);
        }
        if (message.gender !== "") {
            writer.uint32(50).string(message.gender);
        }
        if (message.name !== "") {
            writer.uint32(58).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.profilePic = reader.string();
                    break;
                case 4:
                    message.locale = reader.string();
                    break;
                case 5:
                    message.timezone = reader.int32();
                    break;
                case 6:
                    message.gender = reader.string();
                    break;
                case 7:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstName: isSet(object.firstName) ? String(object.firstName) : "",
            lastName: isSet(object.lastName) ? String(object.lastName) : "",
            profilePic: isSet(object.profilePic) ? String(object.profilePic) : "",
            locale: isSet(object.locale) ? String(object.locale) : "",
            timezone: isSet(object.timezone) ? Number(object.timezone) : 0,
            gender: isSet(object.gender) ? String(object.gender) : "",
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.profilePic !== undefined && (obj.profilePic = message.profilePic);
        message.locale !== undefined && (obj.locale = message.locale);
        message.timezone !== undefined &&
            (obj.timezone = Math.round(message.timezone));
        message.gender !== undefined && (obj.gender = message.gender);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBasePage() {
    return {
        id: "",
        bio: "",
        name: "",
        username: "",
        website: "",
        generalInfo: "",
        hasWhatsappBusinessNumber: false,
        hasWhatsappNumber: false,
        whatsappNumber: "",
    };
}
export const Page = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.bio !== "") {
            writer.uint32(18).string(message.bio);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.username !== "") {
            writer.uint32(34).string(message.username);
        }
        if (message.website !== "") {
            writer.uint32(42).string(message.website);
        }
        if (message.generalInfo !== "") {
            writer.uint32(50).string(message.generalInfo);
        }
        if (message.hasWhatsappBusinessNumber === true) {
            writer.uint32(56).bool(message.hasWhatsappBusinessNumber);
        }
        if (message.hasWhatsappNumber === true) {
            writer.uint32(64).bool(message.hasWhatsappNumber);
        }
        if (message.whatsappNumber !== "") {
            writer.uint32(74).string(message.whatsappNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.bio = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.username = reader.string();
                    break;
                case 5:
                    message.website = reader.string();
                    break;
                case 6:
                    message.generalInfo = reader.string();
                    break;
                case 7:
                    message.hasWhatsappBusinessNumber = reader.bool();
                    break;
                case 8:
                    message.hasWhatsappNumber = reader.bool();
                    break;
                case 9:
                    message.whatsappNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            bio: isSet(object.bio) ? String(object.bio) : "",
            name: isSet(object.name) ? String(object.name) : "",
            username: isSet(object.username) ? String(object.username) : "",
            website: isSet(object.website) ? String(object.website) : "",
            generalInfo: isSet(object.generalInfo) ? String(object.generalInfo) : "",
            hasWhatsappBusinessNumber: isSet(object.hasWhatsappBusinessNumber)
                ? Boolean(object.hasWhatsappBusinessNumber)
                : false,
            hasWhatsappNumber: isSet(object.hasWhatsappNumber)
                ? Boolean(object.hasWhatsappNumber)
                : false,
            whatsappNumber: isSet(object.whatsappNumber)
                ? String(object.whatsappNumber)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.bio !== undefined && (obj.bio = message.bio);
        message.name !== undefined && (obj.name = message.name);
        message.username !== undefined && (obj.username = message.username);
        message.website !== undefined && (obj.website = message.website);
        message.generalInfo !== undefined &&
            (obj.generalInfo = message.generalInfo);
        message.hasWhatsappBusinessNumber !== undefined &&
            (obj.hasWhatsappBusinessNumber = message.hasWhatsappBusinessNumber);
        message.hasWhatsappNumber !== undefined &&
            (obj.hasWhatsappNumber = message.hasWhatsappNumber);
        message.whatsappNumber !== undefined &&
            (obj.whatsappNumber = message.whatsappNumber);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
