export const constantToTitleCase = (str: string) => {
  return str
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
};

export const upperFirst = (str: string) => {
  if (!str.length) return str;

  const lowercaseStr = str.toLowerCase();

  return lowercaseStr[0].toUpperCase() + lowercaseStr.slice(1);
};
