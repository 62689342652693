import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { OPT_OUT_DATA_URL, OptOutDataPathParameters, OptOutDataResponse } from './types';

export const useOptOutData = <TData = OptOutDataResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<OptOutDataResponse, unknown, TData, readonly [target: 'opt-out-data', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  OptOutDataPathParameters) => {
  return useQuery(
    ['opt-out-data', businessId],
    () =>
      api.profileV1(OPT_OUT_DATA_URL, 'post', {
        path: { businessId },
      }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
