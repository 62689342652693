import {
  ACMessageIcon,
  AIMessageIcon,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Label,
  Radio,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from 'src/utils';
import { selectBusinessId, useMeData } from '../../hooks';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { WhatsAppMessageTemplatePreview } from '../WhatsAppMessageTemplatePreview';
import { AbandonedCartEditMessageTypeProps } from './types';

export const AbandonedCartEditMessageType = ({
  discount,
  language,
  isPersonalMessage,
  setMessageType,
}: AbandonedCartEditMessageTypeProps) => {
  const theme = useTheme();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart.editIntegration' });
  const [isPersonal, setIsPersonal] = useState(isPersonalMessage);

  const messageTitle = useMemo(() => (isPersonal ? t('aiExample') : t('standardExample')), [isPersonal, t]);

  return (
    <>
      <DialogContent
        sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2, py: 0 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid container spacing={2} columns={2}>
            <Grid item xs onClick={() => setIsPersonal(true)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                  borderRadius: '10px',
                  height: '100%',
                  p: 2,
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <AIMessageIcon fill={theme.palette.royalViolet} width={24} height={24} />
                    <Radio checked={isPersonal} />
                  </Box>
                  <Label sx={{ fontWeight: 500, fontSize: '16px' }}>{t('aiMessage')}</Label>
                  <Box
                    sx={{
                      background: theme.palette.royalViolet,
                      borderRadius: '16px',
                      px: 1,
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                      gap: 0.5,
                      color: 'white',
                    }}
                  >
                    <Label variant="caption">{t('performance')}</Label>
                  </Box>
                  <Label sx={{ fontSize: '14px', fontWeight: 400, color: theme.palette.text.secondary }}>
                    {t('aiMessageDescription')}
                  </Label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs onClick={() => setIsPersonal(false)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                  borderRadius: '10px',
                  height: '100%',
                  p: 2,
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <ACMessageIcon fill="black" width={24} height={24} />
                    <Radio checked={!isPersonal} />
                  </Box>
                  <Label sx={{ fontWeight: 500, fontSize: '16px' }}>{t('standardMessage')}</Label>
                  <Label sx={{ fontWeight: 400, fontSize: '14px', color: theme.palette.text.secondary }}>
                    {t('standardMessageDescription')}
                  </Label>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              background: theme.palette.grey.A100,
              borderRadius: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
              gap: 1,
            }}
          >
            <Label variant="subtitle1" sx={{ lineHeight: '20px' }}>
              {messageTitle}
            </Label>
            <Label variant="caption" sx={{ color: theme.palette.grey.A700 }}>
              {t('messageVaries')}
            </Label>
            <WhatsAppMessageTemplatePreview
              discount={discount}
              language={language}
              isPersonalMessage={isPersonal}
              img={`${process.env.PUBLIC_URL}/assets/template_sample_message_image.png`}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
        <Button
          variant="contained"
          onClick={() => {
            if (isPersonal) {
              track('(AC) AI Message On', { businessId });
            }
            setMessageType(isPersonal);
          }}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};
