import React, { useContext, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  CircularProgress,
  ConnectlyCard,
  Label,
  Link,
  Tooltip,
  WhatsAppIcon,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { selectBusinessId, useFeatureFlag, useMeData, useSettingsData, useUpdateWAToken } from '@hooks';
import { whatsAppCloudNumbersAtom } from '@atoms/settings';
import { NotificationContext } from '../../../../contexts';
import { SIZE_INPUT_M, SIZE_INPUT_XXL, SIZE_MEDIA_XXS, SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { useConnectWhatsApp, getAppId, formatPhoneNumber } from '../../../../utils';

const URL_PARAM_WA_CLOUD = 'wa_cloud';
const LEARN_MORE_LINK_WA_HEADER = 'https://www.connectly.ai/helpcenter/whatsapp-for-business-api';
const LEARN_MORE_LINK_WA_PHONE = 'https://www.connectly.ai/helpcenter/whatsapp-phone-numbers';
const LEARN_MORE_LINK_WA_VERIFY_BUSINESS = 'https://www.connectly.ai/helpcenter/verify-your-business-on-facebook';
const LEARN_MORE_LINK_WA_DISPLAY_NAME = 'https://www.connectly.ai/helpcenter/whatsapp-display-name';

const useSettingsWhatsapp = () => {
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: profile } = useSettingsData({
    businessId: businessId as string,
  });

  const [whatsAppCloudNumbers, setWhatsAppCloudNumbers] = useAtom(whatsAppCloudNumbersAtom);

  // Data connector for monitoring profile and extracting the phone numbers of wa cloud channels.
  // CAUTION: Assumes that every profile update has more recent data than local state.
  // TODO: FBF-81. Selectors. Don't need an atom for channels. Should just select the data from useSettingsData
  useEffect(() => {
    const waCloudChannels = (profile?.entity?.channels || []).filter(
      (channel) => channel.channelType === 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    );
    const result: string[] = [];
    waCloudChannels.forEach((channel) => {
      const { remoteProfile } = channel;
      if (remoteProfile) {
        if (remoteProfile.wabaPhoneNumber) {
          const phoneNumber = remoteProfile?.wabaPhoneNumber?.phoneNumber || '';
          if (phoneNumber && !result.includes(phoneNumber)) {
            result.push(phoneNumber);
          }
        }
      }
    });
    setWhatsAppCloudNumbers(result);
  }, [profile, setWhatsAppCloudNumbers]);

  const { isLoading: isWaTokenUpdating, update } = useUpdateWAToken();
  const { ffBusinessServiceProviderVTEX, ffBrazilCreditLine } = useFeatureFlag([
    'ffBusinessServiceProviderVTEX',
    'ffBrazilCreditLine',
  ]);

  const handleCompleteWAConnect = (
    accessToken: string,
    userId: number,
    dataAccessExpirationTime: number,
    expiresIn: number,
  ) => {
    update({
      businessId: businessId || '',
      accessToken,
      userId,
      dataAccessExpirationTime,
      expiresIn,
      businessServiceProvider: ffBusinessServiceProviderVTEX
        ? 'BUSINESS_SERVICE_PROVIDER_VTEX'
        : 'BUSINESS_SERVICE_PROVIDER_CONNECTLY',
      useBrazilCreditLine: ffBrazilCreditLine,
    });
  };

  const handleCancelWAConnect = () => {
    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Cancel connecting WhatsApp',
        icon: '',
        severity: NotificationSeverity.INFO,
      },
    });
  };

  const handleComplete = (accessToken: string, userId: number, expirationTime: number, expiresIn: number): void => {
    handleCompleteWAConnect(accessToken, userId, expirationTime, expiresIn);

    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'WhatsApp connected',
        severity: NotificationSeverity.SUCCESS,
        icon: '',
      },
    });
  };

  const [startConnect] = useConnectWhatsApp(
    { businessId: businessId || '', appId: getAppId(ffBusinessServiceProviderVTEX) },
    handleComplete,
    handleCancelWAConnect,
  );

  let phoneNumbers = whatsAppCloudNumbers
    .map((number) => formatPhoneNumber(number))
    .filter((x): x is string => Boolean(x));

  const urlParams = new URLSearchParams(window.location.search.toLowerCase());
  if (urlParams.get(URL_PARAM_WA_CLOUD) === 'true') {
    phoneNumbers = [];
  }

  return {
    phoneNumbers,
    isWaTokenUpdating,
    handleStartConnect: startConnect,
  };
};

export const SettingsWhatsapp = () => {
  const { phoneNumbers, isWaTokenUpdating, handleStartConnect } = useSettingsWhatsapp();
  const theme = useTheme();
  return (
    <ConnectlyCard>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Label variant="h6">WhatsApp for Business API</Label>
        </Box>
        <Button
          variant="text"
          color="primary"
          disableRipple
          disableTouchRipple
          target="_blank"
          href={LEARN_MORE_LINK_WA_HEADER}
          sx={{
            flex: '0 0 auto',
            padding: 0,
            '&:hover': {
              background: 'transparent',
            },
          }}
        >
          Learn More
        </Button>
      </Box>
      {phoneNumbers.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Label variant="caption" color="textSecondary">
            WhatsApp Numbers
          </Label>
          {phoneNumbers.map((number) => {
            return (
              <Tooltip title="Your assigned WhatsApp numbers" key={number}>
                {/* same styles as in MessagingChannels. Create common component */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 2,
                    gap: 1,
                    height: theme.spacing(SIZE_INPUT_M),
                    borderRadius: 5,
                  }}
                >
                  <WhatsAppIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
                  <Label>{number}</Label>
                </Box>
              </Tooltip>
            );
          })}
        </Box>
      ) : (
        <>
          <Tooltip title="Connect to a new WhatsApp number">
            <Button
              variant="outlined"
              color="secondary"
              disabled={isWaTokenUpdating}
              onClick={handleStartConnect}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                width: theme.spacing(SIZE_INPUT_XXL),
                height: theme.spacing(SIZE_INPUT_M),
              }}
            >
              {isWaTokenUpdating ? (
                <CircularProgress
                  sx={{
                    marginRight: SIZE_SPACING_INTER_COMPONENT,
                    filter: isWaTokenUpdating ? 'grayscale(1)' : undefined,
                  }}
                  size={theme.spacing(SIZE_MEDIA_XXS)}
                />
              ) : (
                <WhatsAppIcon
                  width={theme.spacing(SIZE_MEDIA_XXS)}
                  height={theme.spacing(SIZE_MEDIA_XXS)}
                  filter={isWaTokenUpdating ? 'grayscale(1)' : undefined}
                />
              )}
              <Label
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Connect WhatsApp
              </Label>
            </Button>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
              gap: SIZE_SPACING_INTER_COMPONENT,
            }}
          >
            <Label variant="caption" color="textSecondary">
              What you’ll need
            </Label>
            <Label variant="body2">1. Admin access to Facebook Business Manager.</Label>
            <Label variant="body2">
              2. A viable Phone Number for WhatsApp.&nbsp;
              <Link href={LEARN_MORE_LINK_WA_PHONE} target="_blank" underline="hover">
                Learn More
              </Link>
            </Label>
            <Label variant="body2">3. Your business’ legal address and details.</Label>
            <Label variant="body2">
              4. Verify your Facebook Business.&nbsp;
              <Link href={LEARN_MORE_LINK_WA_VERIFY_BUSINESS} target="_blank" underline="hover">
                Learn More
              </Link>
            </Label>
            <Label variant="body2">
              5. Your brand’d display name, shown to customers you chat with.&nbsp;
              <Link href={LEARN_MORE_LINK_WA_DISPLAY_NAME} target="_blank" underline="hover">
                Learn More
              </Link>
            </Label>
          </Box>
        </>
      )}
    </ConnectlyCard>
  );
};
