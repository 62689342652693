import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
import { tryQueryMessageTemplateGroups, tryMutationDeleteMessageTemplate } from './thunk';
const initialState = {
    status: 'empty',
    groups: [],
    items: {
        ids: [],
        byId: {},
    },
    attachment: {
        state: 'idle',
        attachmentState: undefined,
    },
};
function unrollMessageTemplateGroups(templateGroups) {
    const results = [];
    templateGroups.forEach((group) => {
        results.push(...group.templates.map((dto) => ({ dto, group })));
    });
    return results;
}
export const messageTemplateGroupSlice = createSlice({
    name: 'messageTemplateGroup',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => {
        });
        builder.addCase(tryQueryMessageTemplateGroups.pending, (state, _action) => {
            state.status = 'loading';
            state.groups = [];
            state.items = {
                ids: [],
                byId: {},
            };
        });
        builder.addCase(tryQueryMessageTemplateGroups.fulfilled, (state, action) => {
            state.status = 'success';
            state.groups = action.payload;
            state.items.ids = [];
            state.items.byId = {};
            unrollMessageTemplateGroups(action.payload).forEach((template) => {
                const { id } = template.dto;
                state.items.ids.push(id);
                state.items.byId[id] = template;
            });
        });
        builder.addCase(tryQueryMessageTemplateGroups.rejected, (state, _action) => {
            state.status = 'failure';
        });
        builder.addCase(tryMutationDeleteMessageTemplate.fulfilled, (state, action) => {
            const { templateGroupId } = action.meta.arg;
            const templateGroupIndex = state.groups.findIndex((x) => x.id === templateGroupId);
            if (templateGroupIndex !== -1) {
                state.groups.splice(templateGroupIndex, 1);
            }
            const templateIds = {};
            Object.keys(state.items.byId).forEach((x) => {
                if (state.items.byId[x].group.name === templateGroupId) {
                    templateIds[x] = x;
                }
            });
            state.items.ids = state.items.ids.filter((templateId) => templateIds[templateId] !== templateId);
            Object.keys(templateIds).forEach((templateId) => {
                delete state.items.byId[templateId];
            });
        });
    },
});
export const { reset } = messageTemplateGroupSlice.actions;
export const selectMessageTemplateGroups = (state) => state.messageTemplateGroupSlice.groups;
export const selectMessageTemplates = (state) => state.messageTemplateGroupSlice.items.ids.map((val) => state.messageTemplateGroupSlice.items.byId[val]);
export default messageTemplateGroupSlice.reducer;
