import { useContext } from 'react';
import { useRouteMatch } from 'react-router';
import {
  BroadcastRouteId,
  FlowDocumentRouteArgs,
  FLOW_DOCUMENT_ROUTE,
  generateFlowRoute,
  generateFlowHome,
} from '@connectlyai-sdks/inbox-navigation';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';

export const useFlowNavigator = () => {
  const { navigatorProvider } = useContext(FlowDIContext) as FlowDIContextProps;

  const flowDocumentIdRouteMatch = useRouteMatch<FlowDocumentRouteArgs>(
    navigatorProvider().generateGlobalRouteId(FLOW_DOCUMENT_ROUTE),
  );

  const navigateToFlowDocument = (id: string) => navigatorProvider().navigate(generateFlowRoute(id));
  const navigateToFlowHome = () => navigatorProvider().navigate(generateFlowHome());

  const navigateToCampaignsList = () =>
    navigatorProvider().navigate({
      id: BroadcastRouteId,
      params: {},
    });

  return { flowDocumentIdRouteMatch, navigateToCampaignsList, navigateToFlowDocument, navigateToFlowHome };
};
