import React, { useCallback, useContext, useEffect } from 'react';
import { DtoUpdate } from '@connectlyai-tenets/sdk';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';

export const useVerifySpreadsheetData = (sendoutId: string) => {
  const { dtoUpdateAccessorProvider } = useContext(FlowDIContext) as FlowDIContextProps;
  const [result, setResult] = React.useState<DtoUpdate['verifySendoutResult']>(undefined);
  const reset = useCallback(() => {
    setResult(undefined);
  }, [setResult]);

  useEffect(() => {
    let sub: Subscription;
    if (sendoutId) {
      sub = dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => !!dtoUpdate?.verifySendoutResult && dtoUpdate?.objectId === sendoutId),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate?.verifySendoutResult === undefined) {
              return unreachable();
            }
            return dtoUpdate?.verifySendoutResult;
          }),
        )
        .subscribe((verifySendoutResult) => {
          if (verifySendoutResult) {
            setResult(verifySendoutResult);
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [sendoutId, dtoUpdateAccessorProvider, setResult]);

  return { reset, result };
};
