import React, { useMemo } from 'react';
import { ConnectlyCard, Label, Stack, useTheme, AccessTimeIcon, Button } from '@connectlyai-tenets/ui-styled-web';
import { EnhancedTableRowProps as ETableRowProps, TableHeadCell as THeadCell } from '@components/EnhancedTable';
import { useNavigator } from '@scenes/Analytics/hooks';
import { UseNewAnalyticsSendoutsTableProps } from '@scenes/Analytics/AnalyticsSendoutsTable/types';
import { SendoutAnalytics } from '@hooks/useQuerySendoutAnalytics/types';
import { useAnalytics } from '@hooks';
import { useTranslation } from 'react-i18next';
import { SimpleTable } from './SimpleTable';
import { sortByDescendingDate } from './utils';

export type RecentSendoutsProps = {
  filteredData: SendoutAnalytics[];
  isLoading: boolean;
};

const useNewAnalyticsSendoutsTable = ({ data }: UseNewAnalyticsSendoutsTableProps) => {
  const { routes: analyticsRoutes } = useNavigator();
  const { sendAnalytics } = useAnalytics();
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });
  const { t: tHome } = useTranslation('translation', { keyPrefix: 'home.analytics' });

  const title = tHome('recentSendouts', 'Recent sendouts');
  const theme = useTheme();
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'campaignName',
        label: t('table.columns.campaignName'),
        width: '28%',
        align: 'left',
      },
      {
        id: 'sentCount',
        label: t('table.columns.sent'),
        width: '9%',
        align: 'right',
      },
      {
        id: 'readCount',
        label: t('table.columns.opened'),
        width: '9%',
      },
      {
        id: 'engaged',
        label: t('table.columns.engaged'),
        width: '9%',
        align: 'right',
      },
    ];
  }, [t]);
  const { openSendoutReport } = useNavigator();
  const rows: ETableRowProps[] = useMemo(() => {
    if (!data) return [];
    return data.map((item) => {
      return {
        id: item.sendoutId || '',
        selectable: true,
        onClick: () => {
          openSendoutReport(item.sendoutId || '');
        },

        data: [
          {
            value: item.campaignName,
            display: (
              <Stack direction="column" style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>
                <Label variant="body1" noWrap>
                  {item.campaignName}
                </Label>
                <Label noWrap variant="caption" sx={{ color: theme.palette.text.secondary }}>
                  {item.sendoutId}
                </Label>
              </Stack>
            ),
          },
          {
            value: item.sentCount,
            display: item.sentCount,
            align: 'right',
          },
          {
            value: item.readCount,
            display: item.readCount,
            align: 'right',
          },
          {
            value: item.sendoutEngagement?.uniqueSessionEngagement,
            display: item.sendoutEngagement?.uniqueSessionEngagement,
            align: 'right',
          },
        ],
      };
    });
  }, [data, openSendoutReport, theme.palette.text.secondary]);

  const handleShowAllRecentSendouts = () => {
    sendAnalytics('homepage_analytics.recent_sendouts.show_all_clicked');

    return analyticsRoutes.campaigns.navigate();
  };

  return {
    rows,
    tableHead,
    title,
    theme,
    handleShowAllRecentSendouts,
  };
};

export const RecentSendouts = ({ filteredData, isLoading }: RecentSendoutsProps) => {
  const { t: tHome } = useTranslation('translation', { keyPrefix: 'generic' });
  const { rows, title, theme, tableHead, handleShowAllRecentSendouts } = useNewAnalyticsSendoutsTable({
    data: sortByDescendingDate(filteredData),
    isLoading,
  });

  const data = rows.slice(0, 3);

  return (
    <ConnectlyCard icon={<AccessTimeIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />} title={title}>
      <SimpleTable rows={data} headers={tableHead} isLoading={isLoading} />

      {!!rows.length && (
        <Stack direction="row" justifyContent="center">
          <Button sx={{ mt: 2 }} onClick={handleShowAllRecentSendouts} variant="contained" color="secondary">
            {tHome('showAll', 'Show all')}
          </Button>
        </Stack>
      )}
    </ConnectlyCard>
  );
};
