import React from 'react';
import { ColorCustomization } from './ColorCustomization';
import { WidgetPromptAnimation } from './WidgetPromptAnimation';
import { WelcomeMessage } from './WelcomeMessage';
import { QuickResponses } from './QuickResponses';
import { IncludedURLs } from './IncludedURLs';
import { ExcludedURLs } from './ExcludedURLs';

export const Websearch = () => {
  return (
    <>
      <ColorCustomization />
      <WidgetPromptAnimation />
      <WelcomeMessage />
      <QuickResponses />
      <IncludedURLs />
      <ExcludedURLs />
    </>
  );
};
