import { SendSendoutNow, ScheduleSendout, sendoutInputTypeFromJSON, sendoutInputTypeToJSON, } from "./models";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.campaign";
function createBaseSendoutConfigV3() {
    return {
        flowDocument: undefined,
        cohort: undefined,
        schedule: undefined,
        audience: undefined,
    };
}
export const SendoutConfigV3 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocument !== undefined) {
            FlowDocumentConfig.encode(message.flowDocument, writer.uint32(10).fork()).ldelim();
        }
        if (message.cohort !== undefined) {
            CohortConfig.encode(message.cohort, writer.uint32(18).fork()).ldelim();
        }
        if (message.schedule !== undefined) {
            ScheduleConfig.encode(message.schedule, writer.uint32(26).fork()).ldelim();
        }
        if (message.audience !== undefined) {
            AudienceConfig.encode(message.audience, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutConfigV3();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocument = FlowDocumentConfig.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cohort = CohortConfig.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.schedule = ScheduleConfig.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.audience = AudienceConfig.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocument: isSet(object.flowDocument)
                ? FlowDocumentConfig.fromJSON(object.flowDocument)
                : undefined,
            cohort: isSet(object.cohort)
                ? CohortConfig.fromJSON(object.cohort)
                : undefined,
            schedule: isSet(object.schedule)
                ? ScheduleConfig.fromJSON(object.schedule)
                : undefined,
            audience: isSet(object.audience)
                ? AudienceConfig.fromJSON(object.audience)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocument !== undefined &&
            (obj.flowDocument = message.flowDocument
                ? FlowDocumentConfig.toJSON(message.flowDocument)
                : undefined);
        message.cohort !== undefined &&
            (obj.cohort = message.cohort
                ? CohortConfig.toJSON(message.cohort)
                : undefined);
        message.schedule !== undefined &&
            (obj.schedule = message.schedule
                ? ScheduleConfig.toJSON(message.schedule)
                : undefined);
        message.audience !== undefined &&
            (obj.audience = message.audience
                ? AudienceConfig.toJSON(message.audience)
                : undefined);
        return obj;
    },
};
function createBaseFlowDocumentConfig() {
    return { flowDocumentId: "", flowDocumentVersionId: "" };
}
export const FlowDocumentConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocumentId !== "") {
            writer.uint32(10).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(18).string(message.flowDocumentVersionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlowDocumentConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocumentId = reader.string();
                    break;
                case 2:
                    message.flowDocumentVersionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        return obj;
    },
};
function createBaseAudienceConfig() {
    return { configOneof: undefined };
}
export const AudienceConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            AudienceConfigV1.encode(message.configOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAudienceConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "v1",
                        v1: AudienceConfigV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.v1)
                ? { $case: "v1", v1: AudienceConfigV1.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? AudienceConfigV1.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function createBaseAudienceConfigV1() {
    return { audienceId: "" };
}
export const AudienceConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.audienceId !== "") {
            writer.uint32(10).string(message.audienceId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAudienceConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.audienceId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            audienceId: isSet(object.audienceId) ? String(object.audienceId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.audienceId !== undefined && (obj.audienceId = message.audienceId);
        return obj;
    },
};
function createBaseSpreadsheetConfigV1() {
    return { spreadsheetId: "", spreadsheetUrl: "" };
}
export const SpreadsheetConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spreadsheetId !== "") {
            writer.uint32(10).string(message.spreadsheetId);
        }
        if (message.spreadsheetUrl !== "") {
            writer.uint32(18).string(message.spreadsheetUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpreadsheetConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spreadsheetId = reader.string();
                    break;
                case 2:
                    message.spreadsheetUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spreadsheetId: isSet(object.spreadsheetId)
                ? String(object.spreadsheetId)
                : "",
            spreadsheetUrl: isSet(object.spreadsheetUrl)
                ? String(object.spreadsheetUrl)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spreadsheetId !== undefined &&
            (obj.spreadsheetId = message.spreadsheetId);
        message.spreadsheetUrl !== undefined &&
            (obj.spreadsheetUrl = message.spreadsheetUrl);
        return obj;
    },
};
function createBaseCSVConfigV1() {
    return { inputCohortUrl: "" };
}
export const CSVConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.inputCohortUrl !== "") {
            writer.uint32(10).string(message.inputCohortUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCSVConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputCohortUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        return obj;
    },
};
function createBaseCohortConfig() {
    return { configOneof: undefined };
}
export const CohortConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            CohortConfigV1.encode(message.configOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCohortConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "v1",
                        v1: CohortConfigV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.v1)
                ? { $case: "v1", v1: CohortConfigV1.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? CohortConfigV1.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function createBaseCohortConfigV1() {
    return { resultCohortUrl: "", inputType: 0, inputOneof: undefined };
}
export const CohortConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.resultCohortUrl !== "") {
            writer.uint32(10).string(message.resultCohortUrl);
        }
        if (message.inputType !== 0) {
            writer.uint32(16).int32(message.inputType);
        }
        if (((_a = message.inputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "spreadsheetV1") {
            SpreadsheetConfigV1.encode(message.inputOneof.spreadsheetV1, writer.uint32(26).fork()).ldelim();
        }
        if (((_b = message.inputOneof) === null || _b === void 0 ? void 0 : _b.$case) === "csvV1") {
            CSVConfigV1.encode(message.inputOneof.csvV1, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCohortConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.resultCohortUrl = reader.string();
                    break;
                case 2:
                    message.inputType = reader.int32();
                    break;
                case 3:
                    message.inputOneof = {
                        $case: "spreadsheetV1",
                        spreadsheetV1: SpreadsheetConfigV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.inputOneof = {
                        $case: "csvV1",
                        csvV1: CSVConfigV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            resultCohortUrl: isSet(object.resultCohortUrl)
                ? String(object.resultCohortUrl)
                : "",
            inputType: isSet(object.inputType)
                ? sendoutInputTypeFromJSON(object.inputType)
                : 0,
            inputOneof: isSet(object.spreadsheetV1)
                ? {
                    $case: "spreadsheetV1",
                    spreadsheetV1: SpreadsheetConfigV1.fromJSON(object.spreadsheetV1),
                }
                : isSet(object.csvV1)
                    ? { $case: "csvV1", csvV1: CSVConfigV1.fromJSON(object.csvV1) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        message.resultCohortUrl !== undefined &&
            (obj.resultCohortUrl = message.resultCohortUrl);
        message.inputType !== undefined &&
            (obj.inputType = sendoutInputTypeToJSON(message.inputType));
        ((_a = message.inputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "spreadsheetV1" &&
            (obj.spreadsheetV1 = ((_b = message.inputOneof) === null || _b === void 0 ? void 0 : _b.spreadsheetV1)
                ? SpreadsheetConfigV1.toJSON((_c = message.inputOneof) === null || _c === void 0 ? void 0 : _c.spreadsheetV1)
                : undefined);
        ((_d = message.inputOneof) === null || _d === void 0 ? void 0 : _d.$case) === "csvV1" &&
            (obj.csvV1 = ((_e = message.inputOneof) === null || _e === void 0 ? void 0 : _e.csvV1)
                ? CSVConfigV1.toJSON((_f = message.inputOneof) === null || _f === void 0 ? void 0 : _f.csvV1)
                : undefined);
        return obj;
    },
};
function createBaseScheduleConfig() {
    return { scheduleOneof: undefined };
}
export const ScheduleConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now") {
            SendSendoutNow.encode(message.scheduleOneof.now, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.$case) === "schedule") {
            ScheduleSendout.encode(message.scheduleOneof.schedule, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScheduleConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.scheduleOneof = {
                        $case: "now",
                        now: SendSendoutNow.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.scheduleOneof = {
                        $case: "schedule",
                        schedule: ScheduleSendout.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            scheduleOneof: isSet(object.now)
                ? { $case: "now", now: SendSendoutNow.fromJSON(object.now) }
                : isSet(object.schedule)
                    ? {
                        $case: "schedule",
                        schedule: ScheduleSendout.fromJSON(object.schedule),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.scheduleOneof) === null || _a === void 0 ? void 0 : _a.$case) === "now" &&
            (obj.now = ((_b = message.scheduleOneof) === null || _b === void 0 ? void 0 : _b.now)
                ? SendSendoutNow.toJSON((_c = message.scheduleOneof) === null || _c === void 0 ? void 0 : _c.now)
                : undefined);
        ((_d = message.scheduleOneof) === null || _d === void 0 ? void 0 : _d.$case) === "schedule" &&
            (obj.schedule = ((_e = message.scheduleOneof) === null || _e === void 0 ? void 0 : _e.schedule)
                ? ScheduleSendout.toJSON((_f = message.scheduleOneof) === null || _f === void 0 ? void 0 : _f.schedule)
                : undefined);
        return obj;
    },
};
function createBaseVarEntry() {
    return { key: "", value: "" };
}
export const VarEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVarEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
