import { atom } from 'jotai';
import { SETTINGS_CATEGORY_GENERAL } from '@connectlyai-sdks/inbox-navigation';
import { AgentProfileViewState } from '@scenes/Settings/components/Profile/types';
import { EditChannels } from '@scenes/Settings/components/MessagingChannels/types';
import { User } from '@scenes/Settings/components/UserManagement/types';
import {
  QuickReplyDleteDialogProps,
  QuickReplyEditState,
  QuickReplyState,
} from '@scenes/Settings/pages/QuickReply/types';
import { TagsState, TagEditState } from '@scenes/Settings/pages/TagsManager/types';
import { WhatsappAPIKey } from '@scenes/Settings/components/WhatsappAPIKey/types';
import { PageLayoutEnhancements } from '../components/PageLayout/types';

export const settingsPageEnhancementsAtom = atom<PageLayoutEnhancements>({});

export const businessProfileNameAtom = atom<string>('');
export const businessProfileEditedAtom = atom<boolean>(false);
export const businessProfileErrorAtom = atom<boolean>(false);
export const changePasswordNewPasswordErrorAtom = atom<string>('');
export const changePasswordConfirmPasswordErrorAtom = atom<string>('');
export const selectedMenuItemKeyAtom = atom<string>(SETTINGS_CATEGORY_GENERAL);
export const agentStateAtom = atom<AgentProfileViewState>({
  id: '',
  displayName: '',
});
export const displayRequirementsAtom = atom(false);
export const savePreviewedFileAtom = atom(false);

export const isKeysVisibleAtom = atom<boolean>(false);

export const businessKeyDialogOpenForAtom = atom<WhatsappAPIKey | undefined>(undefined);
export const editChannelsAtom = atom<EditChannels>({
  editFBChannelName: false,
  editIgChannelName: false,
});
export const editShopifyStoreNameAtom = atom<boolean>(false);
export const shopifyUrlAtom = atom<string>('');

// Local state of phone numbers for WA_CLOUD channels (may be mutated by mutations)
export const whatsAppCloudNumbersAtom = atom<string[]>([]);

export const webWidgetPositionRightAtom = atom(1);
export const showWebWidgetSettingsAtom = atom(false);

export const quickReplyHoverKeyAtom = atom<string>('');

export const quickReplyEditStateAtom = atom<QuickReplyEditState | undefined>(undefined);
export const quickReplyStateAtom = atom<QuickReplyState>({
  items: { ids: [], byId: {} },
});
export const deleteConfirmDialogAtom = atom<QuickReplyDleteDialogProps>({
  isOpen: false,
  itemId: undefined,
});

export const showAllFeatureFlagsAtom = atom(false);

export const tagsStateAtom = atom<TagsState>({
  items: { ids: [], byId: {} },
});
export const tagEditStateAtom = atom<TagEditState | undefined>(undefined);

// User Management
export const userManagementAnchorElementAtom = atom<Element | undefined>(undefined);
export const userManagementUserOpenAtom = atom<User | undefined>(undefined);
export const userManagementRemoveOpenAtom = atom<User | undefined>(undefined);
export const userManagementModalOpenAtom = atom(false);
export const userManagementErrorMessageAtom = atom<string | undefined>(undefined);
