import { Paths } from '@connectlyai-tenets/sdk';

export type SMBAutoReplyAdditionalParameters = {
  businessId: string;
};

export const SMB_AUTO_REPLY_UPDATE_URL =
  '/internal/v1/businesses/{input.businessId}/update_auto_reply_flow_document/smb';

type SMBAutoReplyUpdateURL = typeof SMB_AUTO_REPLY_UPDATE_URL;

export type SMBAutoReplyQuery = Paths['smbV1'][SMBAutoReplyUpdateURL]['post']['parameters']['body']['input'];

export type SMBAutoReplyVariables = SMBAutoReplyQuery & SMBAutoReplyAdditionalParameters;

export type SMBAutoReplyResponse = Paths['smbV1'][SMBAutoReplyUpdateURL]['post']['responses']['200']['schema'];
