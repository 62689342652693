import { ProvisionedPhoneNumber } from "./accounts/phone_number";
import { PhoneNumberToMessagingServiceAssociation } from "./messagingservices/phone_number";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.twilio.v20100401";
function createBaseAccount() {
    return { sid: "" };
}
export const Account = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sid !== "") {
            writer.uint32(10).string(message.sid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sid: isSet(object.sid) ? String(object.sid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sid !== undefined && (obj.sid = message.sid);
        return obj;
    },
};
function createBasePhoneNumberProfile() {
    return { phoneNumber: undefined, serviceAssociation: undefined };
}
export const PhoneNumberProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.phoneNumber !== undefined) {
            ProvisionedPhoneNumber.encode(message.phoneNumber, writer.uint32(10).fork()).ldelim();
        }
        if (message.serviceAssociation !== undefined) {
            PhoneNumberToMessagingServiceAssociation.encode(message.serviceAssociation, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePhoneNumberProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = ProvisionedPhoneNumber.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.serviceAssociation =
                        PhoneNumberToMessagingServiceAssociation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            phoneNumber: isSet(object.phoneNumber)
                ? ProvisionedPhoneNumber.fromJSON(object.phoneNumber)
                : undefined,
            serviceAssociation: isSet(object.serviceAssociation)
                ? PhoneNumberToMessagingServiceAssociation.fromJSON(object.serviceAssociation)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber
                ? ProvisionedPhoneNumber.toJSON(message.phoneNumber)
                : undefined);
        message.serviceAssociation !== undefined &&
            (obj.serviceAssociation = message.serviceAssociation
                ? PhoneNumberToMessagingServiceAssociation.toJSON(message.serviceAssociation)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
