import React, { useCallback, useState } from 'react';
import { AbandonedCartBody, VTEX_INTEGRATION_TYPE } from '@components/AbandonedCart';
import {
  Box,
  Button,
  ConnectlyCard,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Label,
  useTheme,
  CartIcon,
  VtexLogoIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useAbandonedCart } from '@hooks/useAbandonedCart';
import { AbandonedCartActivation } from '@components/AbandonedCartActivation';
import { VtexTriggerSetup } from '@components/VtexTriggerSetup';
import { useSetAtom } from 'jotai';
import { vtexActivationStepAtom } from '@atoms/abandonedcart';
import { Loader } from '@connectlyai-sdks/components';
import { selectVtexBusinessChannel, useBusinessChannelsData, useFeatureFlag } from '@hooks';
import { track } from 'src/utils';
import { SIZE_MEDIA_L, SIZE_SPACING_ANNOTATION } from '../../ui-theme';

export const VtexAbandonedCart = () => {
  const theme = useTheme();
  const [openTriggerSetup, setOpenTriggerSetup] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.vtexAbandonedCart' });
  const {
    businessId,
    integrationId,
    language,
    discount,
    reminder,
    firstMessage,
    secondMessage,
    discountAmount,
    isActivated,
    deactivatePopupOpen,
    isLoading,
    integrationParams,
    open,
    isDeactivating,
    status,
    onDeactivate,
    onOpen,
    onClose,
    onChangeDeactivatePopup,
  } = useAbandonedCart(VTEX_INTEGRATION_TYPE);
  const { data: vtexBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectVtexBusinessChannel,
  });
  const setCurrentStep = useSetAtom(vtexActivationStepAtom);
  const onSetupVtex = useCallback(() => {
    setOpenTriggerSetup(true);
  }, []);
  const onCloseTriggerSetup = useCallback(() => {
    setOpenTriggerSetup(false);
    setCurrentStep(0);
  }, [setCurrentStep]);
  // Get template status
  const { ffHideVTEXSetup } = useFeatureFlag(['ffHideVTEXSetup']);
  const ActivationBody = (
    <>
      <Label variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {t('description')}
      </Label>
      <Button
        sx={{ width: theme.spacing(12) }}
        variant="contained"
        onClick={() => {
          track('(AC) Activate', { businessId });
          onSetupVtex();
        }}
        disabled={isDeactivating}
      >
        {t('setupNow')}
      </Button>
    </>
  );

  if (ffHideVTEXSetup && !language) {
    return null;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Dialog open={deactivatePopupOpen} onClose={() => onChangeDeactivatePopup(true)}>
        <DialogTitle>{t('deactivateAbandonedCart.deactivate')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-deactivate-description">{t('deactivateAbandonedCart.body')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onChangeDeactivatePopup(true)}>{t('deactivateAbandonedCart.cancel')}</Button>
          <Button onClick={onDeactivate}>{t('deactivateAbandonedCart.ok')}</Button>
        </DialogActions>
      </Dialog>
      <VtexTriggerSetup open={openTriggerSetup} onClose={onCloseTriggerSetup} onOpenActivation={onOpen} />
      <AbandonedCartActivation
        open={open}
        integrationParameters={integrationParams}
        reminder={reminder}
        firstMessageTemplate={firstMessage}
        secondMessageTemplate={secondMessage}
        integrationType="INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT"
        onClose={onClose}
      />
      <ConnectlyCard
        media={{
          children: (
            <Box
              sx={{
                background: theme.palette.grey.A100,
                borderRadius: '16px',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                gap: SIZE_SPACING_ANNOTATION,
              }}
            >
              <VtexLogoIcon width={theme.spacing(2)} />
              <Label variant="body2">VTEX</Label>
            </Box>
          ),
        }}
        title={t('title')}
      >
        {language ? (
          <AbandonedCartBody
            language={language}
            integrationId={integrationId}
            businessChannelId={vtexBusinessChannel?.id ?? ''}
            discount={discount}
            status={status}
            discountAmount={discountAmount}
            isActivated={isActivated}
            isDeactivating={isDeactivating}
            onOpen={onOpen}
            onChangeDeactivatePopup={onChangeDeactivatePopup}
          />
        ) : (
          ActivationBody
        )}
        <Box sx={{ position: 'absolute', right: theme.spacing(2), bottom: theme.spacing(2) }}>
          <CartIcon width={theme.spacing(SIZE_MEDIA_L)} />
        </Box>
      </ConnectlyCard>
    </>
  );
};
