import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Button, LogoutIcon } from '@connectlyai-tenets/ui-styled-web';
const LogoutButton = ({ ...props }) => {
    const { logout } = useAuth0();
    return (_jsxs(Button, { color: "inherit", sx: {
            textTransform: 'none',
            p: 2,
        }, onClick: () => logout({
            returnTo: typeof window !== 'undefined' ? window.location.origin : '/',
        }), ...props, children: [_jsx(LogoutIcon, { sx: { mr: 1.25 } }), "Sign Out"] }));
};
export default LogoutButton;
