export const WelcomeRouteId = '/welcome';
export const HomeRouteId = '/home';
export function generateWelcomeRoute() {
    return {
        id: WelcomeRouteId,
        params: {},
    };
}
export function generateHomeRoute() {
    return {
        id: HomeRouteId,
        params: {},
    };
}
