import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Tooltip, SendIcon, CodeIcon, IconButton, AssessmentIcon } from '@connectlyai-tenets/ui-styled-web';
import { useAnalytics } from '@hooks';
import { useTranslation } from 'react-i18next';

export const RowActions = ({
  flowDocumentId,
  handleOpenAPI,
  handleViewResultsButtonClick,
  isAnalyticsPage,
}: {
  flowDocumentId: string;
  handleOpenAPI: (id: string) => void;
  handleViewResultsButtonClick?: () => void;
  isAnalyticsPage?: boolean;
}) => {
  const { sendAnalytics } = useAnalytics();
  const { t } = useTranslation('translation', { keyPrefix: 'home.analytics' });

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: isAnalyticsPage ? 1 : 2,
        width: 'auto',
        px: 1,
        background: isAnalyticsPage ? 'transparent' : (theme) => theme.palette.grey.A100,
      }}
    >
      <Tooltip title={t('createNewSendout', '"Create new sendout')} arrow placement="top">
        <IconButton
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            sendAnalytics('homepage_analytics.best_performing_campaigns.create_sendout_clicked');

            e.stopPropagation();
            return null;
          }}
          component={Link}
          to={`/flow/${flowDocumentId}`}
          disabled={!flowDocumentId}
        >
          <SendIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Tooltip>

      {isAnalyticsPage && (
        <Tooltip title={t('viewResults', 'View Results')} arrow placement="top">
          <IconButton
            onClick={() => {
              if (handleViewResultsButtonClick) {
                handleViewResultsButtonClick();

                sendAnalytics('analytics.campaigns.show_best_performing_campaign');
              }
            }}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('openApiInfo', 'Open API Info')} arrow placement="top">
        <IconButton
          onClick={(e: React.MouseEvent<HTMLElement | MouseEvent>) => {
            e.stopPropagation();

            handleOpenAPI(flowDocumentId as string);

            sendAnalytics('homepage_analytics.best_performing_campaigns.open_api_clicked');
          }}
          disabled={!flowDocumentId}
        >
          <CodeIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
