import * as _m0 from "protobufjs/minimal";
import { FloatValue, StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "models.event.connectly";
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType[AttachmentType["ATTACHMENT_TYPE_UNSPECIFIED"] = 0] = "ATTACHMENT_TYPE_UNSPECIFIED";
    AttachmentType[AttachmentType["ATTACHMENT_TYPE_IMAGE"] = 1] = "ATTACHMENT_TYPE_IMAGE";
    AttachmentType[AttachmentType["ATTACHMENT_TYPE_VIDEO"] = 2] = "ATTACHMENT_TYPE_VIDEO";
    AttachmentType[AttachmentType["ATTACHMENT_TYPE_AUDIO"] = 3] = "ATTACHMENT_TYPE_AUDIO";
    AttachmentType[AttachmentType["ATTACHMENT_TYPE_FILE"] = 4] = "ATTACHMENT_TYPE_FILE";
    AttachmentType[AttachmentType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(AttachmentType || (AttachmentType = {}));
export function attachmentTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "ATTACHMENT_TYPE_UNSPECIFIED":
            return AttachmentType.ATTACHMENT_TYPE_UNSPECIFIED;
        case 1:
        case "ATTACHMENT_TYPE_IMAGE":
            return AttachmentType.ATTACHMENT_TYPE_IMAGE;
        case 2:
        case "ATTACHMENT_TYPE_VIDEO":
            return AttachmentType.ATTACHMENT_TYPE_VIDEO;
        case 3:
        case "ATTACHMENT_TYPE_AUDIO":
            return AttachmentType.ATTACHMENT_TYPE_AUDIO;
        case 4:
        case "ATTACHMENT_TYPE_FILE":
            return AttachmentType.ATTACHMENT_TYPE_FILE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AttachmentType.UNRECOGNIZED;
    }
}
export function attachmentTypeToJSON(object) {
    switch (object) {
        case AttachmentType.ATTACHMENT_TYPE_UNSPECIFIED:
            return "ATTACHMENT_TYPE_UNSPECIFIED";
        case AttachmentType.ATTACHMENT_TYPE_IMAGE:
            return "ATTACHMENT_TYPE_IMAGE";
        case AttachmentType.ATTACHMENT_TYPE_VIDEO:
            return "ATTACHMENT_TYPE_VIDEO";
        case AttachmentType.ATTACHMENT_TYPE_AUDIO:
            return "ATTACHMENT_TYPE_AUDIO";
        case AttachmentType.ATTACHMENT_TYPE_FILE:
            return "ATTACHMENT_TYPE_FILE";
        case AttachmentType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseAttachment() {
    return { type: 0, attachmentOneof: undefined };
}
export const Attachment = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (((_a = message.attachmentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "asset") {
            AssetAttachment.encode(message.attachmentOneof.asset, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAttachment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.attachmentOneof = {
                        $case: "asset",
                        asset: AssetAttachment.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? attachmentTypeFromJSON(object.type) : 0,
            attachmentOneof: isSet(object.asset)
                ? { $case: "asset", asset: AssetAttachment.fromJSON(object.asset) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.type !== undefined &&
            (obj.type = attachmentTypeToJSON(message.type));
        ((_a = message.attachmentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "asset" &&
            (obj.asset = ((_b = message.attachmentOneof) === null || _b === void 0 ? void 0 : _b.asset)
                ? AssetAttachment.toJSON((_c = message.attachmentOneof) === null || _c === void 0 ? void 0 : _c.asset)
                : undefined);
        return obj;
    },
};
function createBaseAssetAttachment() {
    return { id: "", url: "", caption: "", filename: "" };
}
export const AssetAttachment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        if (message.caption !== "") {
            writer.uint32(26).string(message.caption);
        }
        if (message.filename !== "") {
            writer.uint32(34).string(message.filename);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetAttachment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.caption = reader.string();
                    break;
                case 4:
                    message.filename = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            url: isSet(object.url) ? String(object.url) : "",
            caption: isSet(object.caption) ? String(object.caption) : "",
            filename: isSet(object.filename) ? String(object.filename) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.url !== undefined && (obj.url = message.url);
        message.caption !== undefined && (obj.caption = message.caption);
        message.filename !== undefined && (obj.filename = message.filename);
        return obj;
    },
};
function createBaseProgressStatus() {
    return { total: 0, completed: 0 };
}
export const ProgressStatus = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== 0) {
            writer.uint32(8).int32(message.total);
        }
        if (message.completed !== 0) {
            writer.uint32(16).int32(message.completed);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProgressStatus();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = reader.int32();
                    break;
                case 2:
                    message.completed = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? Number(object.total) : 0,
            completed: isSet(object.completed) ? Number(object.completed) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = Math.round(message.total));
        message.completed !== undefined &&
            (obj.completed = Math.round(message.completed));
        return obj;
    },
};
function createBaseLocation() {
    return {
        latitude: undefined,
        longitude: undefined,
        name: undefined,
        address: undefined,
    };
}
export const Location = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.latitude !== undefined) {
            FloatValue.encode({ value: message.latitude }, writer.uint32(10).fork()).ldelim();
        }
        if (message.longitude !== undefined) {
            FloatValue.encode({ value: message.longitude }, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(26).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLocation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.latitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.longitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
            longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.latitude !== undefined && (obj.latitude = message.latitude);
        message.longitude !== undefined && (obj.longitude = message.longitude);
        message.name !== undefined && (obj.name = message.name);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
};
function createBaseInteractive() {
    return { dataOneof: undefined };
}
export const Interactive = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        if (((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "listMessage") {
            ListMessage.encode(message.dataOneof.listMessage, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "replyButtonMessage") {
            ReplyButtonMessage.encode(message.dataOneof.replyButtonMessage, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.dataOneof) === null || _c === void 0 ? void 0 : _c.$case) === "singleProductMessage") {
            SingleProductMessage.encode(message.dataOneof.singleProductMessage, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.dataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "multiProductMessage") {
            MultiProductMessage.encode(message.dataOneof.multiProductMessage, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInteractive();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.dataOneof = {
                        $case: "listMessage",
                        listMessage: ListMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.dataOneof = {
                        $case: "replyButtonMessage",
                        replyButtonMessage: ReplyButtonMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.dataOneof = {
                        $case: "singleProductMessage",
                        singleProductMessage: SingleProductMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.dataOneof = {
                        $case: "multiProductMessage",
                        multiProductMessage: MultiProductMessage.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            dataOneof: isSet(object.listMessage)
                ? {
                    $case: "listMessage",
                    listMessage: ListMessage.fromJSON(object.listMessage),
                }
                : isSet(object.replyButtonMessage)
                    ? {
                        $case: "replyButtonMessage",
                        replyButtonMessage: ReplyButtonMessage.fromJSON(object.replyButtonMessage),
                    }
                    : isSet(object.singleProductMessage)
                        ? {
                            $case: "singleProductMessage",
                            singleProductMessage: SingleProductMessage.fromJSON(object.singleProductMessage),
                        }
                        : isSet(object.multiProductMessage)
                            ? {
                                $case: "multiProductMessage",
                                multiProductMessage: MultiProductMessage.fromJSON(object.multiProductMessage),
                            }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        ((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "listMessage" &&
            (obj.listMessage = ((_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.listMessage)
                ? ListMessage.toJSON((_c = message.dataOneof) === null || _c === void 0 ? void 0 : _c.listMessage)
                : undefined);
        ((_d = message.dataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "replyButtonMessage" &&
            (obj.replyButtonMessage = ((_e = message.dataOneof) === null || _e === void 0 ? void 0 : _e.replyButtonMessage)
                ? ReplyButtonMessage.toJSON((_f = message.dataOneof) === null || _f === void 0 ? void 0 : _f.replyButtonMessage)
                : undefined);
        ((_g = message.dataOneof) === null || _g === void 0 ? void 0 : _g.$case) === "singleProductMessage" &&
            (obj.singleProductMessage = ((_h = message.dataOneof) === null || _h === void 0 ? void 0 : _h.singleProductMessage)
                ? SingleProductMessage.toJSON((_j = message.dataOneof) === null || _j === void 0 ? void 0 : _j.singleProductMessage)
                : undefined);
        ((_k = message.dataOneof) === null || _k === void 0 ? void 0 : _k.$case) === "multiProductMessage" &&
            (obj.multiProductMessage = ((_l = message.dataOneof) === null || _l === void 0 ? void 0 : _l.multiProductMessage)
                ? MultiProductMessage.toJSON((_m = message.dataOneof) === null || _m === void 0 ? void 0 : _m.multiProductMessage)
                : undefined);
        return obj;
    },
};
function createBaseListMessage() {
    return {
        header: undefined,
        footer: undefined,
        button: undefined,
        sections: [],
    };
}
export const ListMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== undefined) {
            MessageHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
        }
        if (message.footer !== undefined) {
            MessageFooter.encode(message.footer, writer.uint32(18).fork()).ldelim();
        }
        if (message.button !== undefined) {
            MessageButton.encode(message.button, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.sections) {
            ListSection.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = MessageHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.footer = MessageFooter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.button = MessageButton.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sections.push(ListSection.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header)
                ? MessageHeader.fromJSON(object.header)
                : undefined,
            footer: isSet(object.footer)
                ? MessageFooter.fromJSON(object.footer)
                : undefined,
            button: isSet(object.button)
                ? MessageButton.fromJSON(object.button)
                : undefined,
            sections: Array.isArray(object === null || object === void 0 ? void 0 : object.sections)
                ? object.sections.map((e) => ListSection.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined &&
            (obj.header = message.header
                ? MessageHeader.toJSON(message.header)
                : undefined);
        message.footer !== undefined &&
            (obj.footer = message.footer
                ? MessageFooter.toJSON(message.footer)
                : undefined);
        message.button !== undefined &&
            (obj.button = message.button
                ? MessageButton.toJSON(message.button)
                : undefined);
        if (message.sections) {
            obj.sections = message.sections.map((e) => e ? ListSection.toJSON(e) : undefined);
        }
        else {
            obj.sections = [];
        }
        return obj;
    },
};
function createBaseReplyButtonMessage() {
    return { header: undefined, footer: undefined, buttons: [] };
}
export const ReplyButtonMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== undefined) {
            MessageHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
        }
        if (message.footer !== undefined) {
            MessageFooter.encode(message.footer, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.buttons) {
            MessageButton.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReplyButtonMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = MessageHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.footer = MessageFooter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.buttons.push(MessageButton.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header)
                ? MessageHeader.fromJSON(object.header)
                : undefined,
            footer: isSet(object.footer)
                ? MessageFooter.fromJSON(object.footer)
                : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => MessageButton.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined &&
            (obj.header = message.header
                ? MessageHeader.toJSON(message.header)
                : undefined);
        message.footer !== undefined &&
            (obj.footer = message.footer
                ? MessageFooter.toJSON(message.footer)
                : undefined);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? MessageButton.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        return obj;
    },
};
function createBaseMessageHeader() {
    return { typeOneof: undefined };
}
export const MessageHeader = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text") {
            writer.uint32(10).string(message.typeOneof.text);
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "attachment") {
            Attachment.encode(message.typeOneof.attachment, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageHeader();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeOneof = { $case: "text", text: reader.string() };
                    break;
                case 2:
                    message.typeOneof = {
                        $case: "attachment",
                        attachment: Attachment.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeOneof: isSet(object.text)
                ? { $case: "text", text: String(object.text) }
                : isSet(object.attachment)
                    ? {
                        $case: "attachment",
                        attachment: Attachment.fromJSON(object.attachment),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e;
        const obj = {};
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text" && (obj.text = (_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.text);
        ((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "attachment" &&
            (obj.attachment = ((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.attachment)
                ? Attachment.toJSON((_e = message.typeOneof) === null || _e === void 0 ? void 0 : _e.attachment)
                : undefined);
        return obj;
    },
};
function createBaseMessageFooter() {
    return { text: "" };
}
export const MessageFooter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageFooter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseMessageButton() {
    return { id: "", text: "" };
}
export const MessageButton = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageButton();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseListSection() {
    return { title: "", rows: [] };
}
export const ListSection = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        for (const v of message.rows) {
            ListRow.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListSection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.rows.push(ListRow.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            rows: Array.isArray(object === null || object === void 0 ? void 0 : object.rows)
                ? object.rows.map((e) => ListRow.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        if (message.rows) {
            obj.rows = message.rows.map((e) => (e ? ListRow.toJSON(e) : undefined));
        }
        else {
            obj.rows = [];
        }
        return obj;
    },
};
function createBaseListRow() {
    return { id: "", text: "", description: "" };
}
export const ListRow = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListRow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            text: isSet(object.text) ? String(object.text) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.text !== undefined && (obj.text = message.text);
        message.description !== undefined &&
            (obj.description = message.description);
        return obj;
    },
};
function createBaseProductItem() {
    return { productRetailerId: "" };
}
export const ProductItem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.productRetailerId !== "") {
            writer.uint32(10).string(message.productRetailerId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProductItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.productRetailerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            productRetailerId: isSet(object.productRetailerId)
                ? String(object.productRetailerId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.productRetailerId !== undefined &&
            (obj.productRetailerId = message.productRetailerId);
        return obj;
    },
};
function createBaseSingleProductMessage() {
    return {
        footer: undefined,
        actionButton: undefined,
        buttons: [],
        catalogId: "",
        productItem: undefined,
    };
}
export const SingleProductMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.footer !== undefined) {
            MessageFooter.encode(message.footer, writer.uint32(10).fork()).ldelim();
        }
        if (message.actionButton !== undefined) {
            MessageButton.encode(message.actionButton, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.buttons) {
            MessageButton.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.catalogId !== "") {
            writer.uint32(34).string(message.catalogId);
        }
        if (message.productItem !== undefined) {
            ProductItem.encode(message.productItem, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSingleProductMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.footer = MessageFooter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.actionButton = MessageButton.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.buttons.push(MessageButton.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.catalogId = reader.string();
                    break;
                case 5:
                    message.productItem = ProductItem.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            footer: isSet(object.footer)
                ? MessageFooter.fromJSON(object.footer)
                : undefined,
            actionButton: isSet(object.actionButton)
                ? MessageButton.fromJSON(object.actionButton)
                : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => MessageButton.fromJSON(e))
                : [],
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
            productItem: isSet(object.productItem)
                ? ProductItem.fromJSON(object.productItem)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.footer !== undefined &&
            (obj.footer = message.footer
                ? MessageFooter.toJSON(message.footer)
                : undefined);
        message.actionButton !== undefined &&
            (obj.actionButton = message.actionButton
                ? MessageButton.toJSON(message.actionButton)
                : undefined);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? MessageButton.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        message.productItem !== undefined &&
            (obj.productItem = message.productItem
                ? ProductItem.toJSON(message.productItem)
                : undefined);
        return obj;
    },
};
function createBaseProductSection() {
    return { title: "", productItems: [] };
}
export const ProductSection = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        for (const v of message.productItems) {
            ProductItem.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProductSection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.productItems.push(ProductItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            productItems: Array.isArray(object === null || object === void 0 ? void 0 : object.productItems)
                ? object.productItems.map((e) => ProductItem.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        if (message.productItems) {
            obj.productItems = message.productItems.map((e) => e ? ProductItem.toJSON(e) : undefined);
        }
        else {
            obj.productItems = [];
        }
        return obj;
    },
};
function createBaseMultiProductMessage() {
    return {
        header: undefined,
        footer: undefined,
        actionButton: undefined,
        buttons: [],
        catalogId: "",
        sections: [],
    };
}
export const MultiProductMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== undefined) {
            MessageHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
        }
        if (message.footer !== undefined) {
            MessageFooter.encode(message.footer, writer.uint32(18).fork()).ldelim();
        }
        if (message.actionButton !== undefined) {
            MessageButton.encode(message.actionButton, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.buttons) {
            MessageButton.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.catalogId !== "") {
            writer.uint32(42).string(message.catalogId);
        }
        for (const v of message.sections) {
            ProductSection.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMultiProductMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = MessageHeader.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.footer = MessageFooter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.actionButton = MessageButton.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.buttons.push(MessageButton.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.catalogId = reader.string();
                    break;
                case 6:
                    message.sections.push(ProductSection.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header)
                ? MessageHeader.fromJSON(object.header)
                : undefined,
            footer: isSet(object.footer)
                ? MessageFooter.fromJSON(object.footer)
                : undefined,
            actionButton: isSet(object.actionButton)
                ? MessageButton.fromJSON(object.actionButton)
                : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => MessageButton.fromJSON(e))
                : [],
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
            sections: Array.isArray(object === null || object === void 0 ? void 0 : object.sections)
                ? object.sections.map((e) => ProductSection.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined &&
            (obj.header = message.header
                ? MessageHeader.toJSON(message.header)
                : undefined);
        message.footer !== undefined &&
            (obj.footer = message.footer
                ? MessageFooter.toJSON(message.footer)
                : undefined);
        message.actionButton !== undefined &&
            (obj.actionButton = message.actionButton
                ? MessageButton.toJSON(message.actionButton)
                : undefined);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? MessageButton.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        if (message.sections) {
            obj.sections = message.sections.map((e) => e ? ProductSection.toJSON(e) : undefined);
        }
        else {
            obj.sections = [];
        }
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
