import React, { useCallback, useState } from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { DateRangePicker } from '@components/DateRangePicker';
import { useAtom } from 'jotai';
import { dateRangeLast7DaysAtom } from '@atoms/home';
import { DateRange } from '@components/DateRangePicker/types';
import { useAnalytics } from '@hooks';
import { SofiaSummaryV2 } from './SofiaSummary';
import { CampaignsOverviewV2 } from './CampaignsOverview/CampaignsOverview';
import { ActionCards } from './ActionCards';
import { TablesGroup } from './TablesGroup';

const useHomepageV2 = () => {
  const [dateRange, setDateRange] = useAtom(dateRangeLast7DaysAtom);
  const { sendAnalytics } = useAnalytics();
  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      sendAnalytics('homepage_analytics.datepicker.changed');

      setDateRange(newDateRange);
    },
    [setDateRange, sendAnalytics],
  );

  return {
    dateRange,
    handleDateRangeChange,
  };
};

export const HomepageV2 = () => {
  const { dateRange, handleDateRangeChange } = useHomepageV2();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <DateRangePicker dateRange={dateRange} setDateRange={handleDateRangeChange} />
      <CampaignsOverviewV2 />
      <SofiaSummaryV2 />
      <TablesGroup />
      <ActionCards />
    </Box>
  );
};
