import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { AgentsUrlParameters, AgentsResponse } from './types';

export const useAgentsData = <TData = AgentsResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<AgentsResponse, unknown, TData, readonly ['agents', AgentsUrlParameters]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  AgentsUrlParameters) =>
  useQuery(
    ['agents', { businessId }],
    () => api.profileV1('/v1/businesses/{businessId}/agents', 'get', { path: { businessId } }),
    options,
  );
