import React, { useCallback, useContext, useMemo } from 'react';
import { Box, TextField, SaveActionCard } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, selectRole, useMeData } from '@hooks';
import { useUpdateAgentField } from '@scenes/Settings/hooks/useUpdateAgentField';
import { useUpdateUserMutation } from '@scenes/Settings/hooks/useUpdateUserMutation';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useQueryClient } from '@tanstack/react-query';
import { MAX_DISPLAY_NAME_LENGTH } from './constants';
import { NotificationContext } from '../../../../contexts';

const useProfile = () => {
  const queryClient = useQueryClient();
  const { data: role } = useMeData({ select: selectRole });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);
  const isAgent = useMemo(() => !role || role === 'ROLE_AGENT', [role]);

  const { mutate: updateUserMutate, isLoading: isUserUpdating } = useUpdateUserMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['agents', 'me']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully updated display name',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error saving user profile',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });
  const { id, displayName, isAgentStateLoading, updateAgentField } = useUpdateAgentField();

  const updateUserProfile = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();
      updateUserMutate({
        businessId: businessId as string,
        userId: id,
        displayName,
      });
    },
    [businessId, id, displayName, updateUserMutate],
  );

  const error = useMemo(() => displayName === '', [displayName]);
  return {
    updateUserProfile,
    error,
    isAgent,
    isAgentStateLoading,
    isUserUpdating,
    displayName,
    updateAgentField,
  };
};

export const Profile = () => {
  const { updateUserProfile, error, isAgent, isAgentStateLoading, isUserUpdating, displayName, updateAgentField } =
    useProfile();

  return (
    <SaveActionCard
      onSubmit={updateUserProfile}
      saveDisabled={Boolean(error || isAgent || isAgentStateLoading || isUserUpdating)}
      title="Profile"
      isLoading={isAgentStateLoading}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="agent-display-name"
          name="displayName"
          label="Display Name"
          value={displayName}
          variant="outlined"
          helperText={error ? 'Display name cannot be empty' : ' '}
          error={error}
          inputProps={{ maxLength: MAX_DISPLAY_NAME_LENGTH }}
          onChange={updateAgentField}
          disabled={isAgent}
        />
      </Box>
    </SaveActionCard>
  );
};
