import { createAsyncThunk } from '@reduxjs/toolkit';
export const fetchAgents = createAsyncThunk('business/fetchAgents', async ({ di, businessId }) => {
    if (businessId) {
        const queryAgents = di.queryAgentsProvider();
        const agents = await queryAgents(businessId);
        return agents;
    }
    throw new Error('invalid business id');
});
export const fetchBusiness = createAsyncThunk('business/fetchBusiness', async ({ di, businessId }) => {
    const queryBusiness = di.queryBusinessProvider();
    if (businessId) {
        const business = await queryBusiness(businessId);
        return business;
    }
    throw new Error('invalid business id');
});
export const tryCreateQuickReplyTemplate = createAsyncThunk('business/tryCreateQuickReplyTemplate', async ({ di, input }) => {
    const mutationCreateQuickReplyTemplate = di.mutationCreateQuickReplyTemplateProvider();
    await mutationCreateQuickReplyTemplate(input);
});
export const tryCreateTag = createAsyncThunk('business/tryCreateTag', async ({ di, input }) => {
    const mutationCreateTagProvider = di.mutationCreateTagProvider();
    const tag = await mutationCreateTagProvider(input);
    return tag;
});
export const tryUpdateQuickReplyTemplate = createAsyncThunk('business/tryUpdateQuickReplyTemplate', async ({ di, input }) => {
    const mutationUpdateQuickReplyTemplate = di.mutationUpdateQuickReplyTemplateProvider();
    await mutationUpdateQuickReplyTemplate(input);
});
export const tryUpdateTag = createAsyncThunk('business/tryUpdateTag', async ({ di, input }) => {
    const mutationUpdateTag = di.mutationUpdateTagProvider();
    await mutationUpdateTag(input);
});
export const tryDeleteQuickReplyTemplate = createAsyncThunk('business/tryDeleteQuickReplyTemplate', async ({ di, businessId, templateId }) => {
    const mutationDeleteQuickReplyTemplate = di.mutationDeleteQuickReplyTemplateProvider();
    await mutationDeleteQuickReplyTemplate(businessId, templateId);
});
export const tryDeleteTag = createAsyncThunk('business/tryDeleteTag', async ({ di, businessId, tagId }) => {
    const mutationDeleteTag = di.mutationDeleteTagProvider();
    await mutationDeleteTag(businessId, tagId);
});
