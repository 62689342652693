import React, { FC, PropsWithChildren } from 'react';
import { Box, useTheme, SxProps } from '@connectlyai-tenets/ui-styled-web';

export type PillDisplayProps = {
  sx?: SxProps;
};
export const PillDisplay: FC<PropsWithChildren<PillDisplayProps>> = ({ sx, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          background: theme.palette.grey[100],
          borderRadius: 2,
          px: 1,
          height: theme.spacing(3.25),
          transition: 'background 0.3s',
          cursor: 'pointer',
          '&:hover': {
            background: theme.palette.grey[200],
          },
          flex: '1 1 auto',
          minWidth: 0,
          flexWrap: 'nowrap',
          textOverflow: 'ellipsis',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
