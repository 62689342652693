import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { BusinessOrganizationOptions, BusinessOrganizationMutation, BusinessOrganizationResponse } from './types';

export const useBusinessOrganizationMutation = (options?: BusinessOrganizationOptions) => {
  return useMutation(
    (variables: BusinessOrganizationMutation) =>
      api.profileV1('/v1/businesses/{businessId}/organization', 'patch', {
        path: { businessId: variables.businessId },
        body: {
          entity: {
            ...variables,
          },
        },
      }) as Promise<BusinessOrganizationResponse>,
    {
      ...options,
    },
  );
};
