import { ChannelType } from '@connectlyai-sdks/data-rooms';
import { ConnectlyIconKind } from '@connectlyai-tenets/ui-icons';
export { RoomStatus } from '@connectlyai-idl/models/dist/models/common/models';
export { SearchCustomerQuery, SearchCustomerResult } from '@connectlyai-idl/models/dist/models/profile/search';
export { ConnectlyIconKind } from '@connectlyai-tenets/ui-icons';
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["REGULAR"] = 1] = "REGULAR";
    MessageType[MessageType["MARKETING"] = 2] = "MARKETING";
})(MessageType || (MessageType = {}));
export const FilterByChannelOptions = {
    FBMESSENGER: {
        key: 'FBMESSENGER',
        backendType: ChannelType.CHANNEL_TYPE_FB_MESSENGER,
        iconKind: ConnectlyIconKind.MESSENGER,
        label: 'MESSENGER',
    },
    INSTAGRAM: {
        key: 'INSTAGRAM',
        backendType: ChannelType.CHANNEL_TYPE_INSTAGRAM,
        iconKind: ConnectlyIconKind.INSTAGRAM,
        label: 'INSTAGRAM',
    },
    SMS: {
        key: 'SMS',
        backendType: ChannelType.CHANNEL_TYPE_SMS,
        iconKind: ConnectlyIconKind.SMS,
        label: 'SMS',
    },
    WHATSAPP: {
        key: 'WHATSAPP',
        backendType: ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
        iconKind: ConnectlyIconKind.WHATSAPP,
        label: 'WHATSAPP',
    },
};
export const FilterByStatusOptions = {
    ALL: {
        key: 'ALL',
        iconKind: undefined,
        label: 'ALL',
    },
    UNREAD: {
        key: 'UNREAD',
        iconKind: undefined,
        label: 'UNREAD',
    },
    READ: {
        key: 'READ',
        iconKind: undefined,
        label: 'READ',
    },
};
export const RoomStatusOptions = {
    OPEN: {
        key: 'OPEN',
        color: '#EF5350',
        label: 'Open',
    },
    IN_PROGRESS: {
        key: 'IN_PROGRESS',
        color: '#007DFF',
        label: 'In Progress',
    },
    CLOSED: {
        key: 'CLOSED',
        color: '#616161',
        label: 'Closed',
    },
    CUSTOM: {
        key: 'CUSTOM',
        color: '#FFFFFF',
        label: 'Custom',
    },
};
export const FilterByRoomStatusOptions = {
    ...RoomStatusOptions,
};
export const FilterByChannelOptionsAll = [
    FilterByChannelOptions.SMS.key,
    FilterByChannelOptions.INSTAGRAM.key,
    FilterByChannelOptions.FBMESSENGER.key,
    FilterByChannelOptions.WHATSAPP.key,
];
export const FilterByRoomStatusOptionsAll = [
    RoomStatusOptions.OPEN.key,
    RoomStatusOptions.IN_PROGRESS.key,
    RoomStatusOptions.CLOSED.key,
    RoomStatusOptions.CUSTOM.key,
];
