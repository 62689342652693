import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { SofiaStatusUrlParameters, SofiaStatusResponse, SOFIA_STATUS_URL } from './types';

export const useSofiaStatus = <TData = SofiaStatusResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<SofiaStatusResponse, unknown, TData, readonly [target: 'sofia-status', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  SofiaStatusUrlParameters) => {
  return useQuery(['sofia-status', businessId], () => api.aiV1(SOFIA_STATUS_URL, 'get', { path: { businessId } }), {
    enabled: !!businessId,
    ...options,
  });
};
