import React from 'react';
import {
  Box,
  Card,
  Label,
  useTheme,
  MessageNodeCanvasIcon,
  MessageNodeCornerCanvasIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { SourceHandle } from '@components/SourceHandle';
import { TargetHandle } from '@components/TargetHandle';
import { MESSAGE_NODE_TYPE } from './constants';
import { MessageNodeProps } from './types';
import { useNodeUIState } from '../../hooks/useNodeUIState';
import { MessageNodeUIState } from '../MessageNodeEditor/types';

export const useMessageNode = ({ id, data }: MessageNodeProps) => {
  const { nodeUIState } = useNodeUIState<MessageNodeUIState>({
    nodeType: MESSAGE_NODE_TYPE,
    nodeId: id,
    nodeData: data,
  });
  return { nodeUIState };
};

export const MessageNode = ({ id, data }: MessageNodeProps) => {
  const { nodeUIState } = useMessageNode({ id, data });
  const theme = useTheme();
  return (
    <Card
      sx={{
        background: theme.palette.common.white,
        borderRadius: '10px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        ':hover': {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
        },
        padding: 1.5,
        width: 248,
      }}
    >
      <TargetHandle nodeId={id} nodeType={MESSAGE_NODE_TYPE} actionType="on-execute" />
      <SourceHandle nodeId={id} nodeType={MESSAGE_NODE_TYPE} actionType="after-execute" />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <MessageNodeCanvasIcon />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Label variant="h7">Send Message</Label>
          <Label variant="body2" color={theme.palette.text.secondary}>
            Simple Message
          </Label>
        </Box>
      </Box>
      <Box sx={{ background: theme.palette.grey[100], p: 1.5, borderRadius: '8px', display: 'flex' }}>
        <Box sx={{ filter: 'drop-shadow(0px 1px 0.5px rgba(0, 0, 0, 0.20))', display: 'flex', flexGrow: 1 }}>
          <MessageNodeCornerCanvasIcon style={{ flexShrink: 0 }} />
          <Box
            sx={{
              background: theme.palette.common.white,
              borderRadius: '0px 6px 6px 6px',
              p: 1,
              flexGrow: 1,
              ml: -0.1,
            }}
          >
            <Label variant="body2">{nodeUIState?.body}</Label>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
