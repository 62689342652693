import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const DELETE_RESOURCE_URL = '/v1/businesses/{input.businessId}/delete_by_resource/ratelimit';
type DeleteResourceLimit = typeof DELETE_RESOURCE_URL;

export type DeleteResourceBodyInput = Paths['profileV1'][DeleteResourceLimit]['post']['parameters']['body']['input'];

export type DeleteResourceParameters = DeleteResourceBodyInput & {
  businessId: string;
};

export type DeleteResourceResponse = Paths['profileV1'][DeleteResourceLimit]['post']['responses']['200']['schema'];

export type DeleteResourceOptions = Omit<
  UseMutationOptions<DeleteResourceResponse, ConnectlyError, DeleteResourceParameters, unknown>,
  'mutationKey' | 'mutupdateFn'
>;
