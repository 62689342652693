import { useCallback, useContext, useState } from 'react';
import { useSetAtom } from 'jotai';
import { useMediaUploadMutation } from '@hooks';
import { sofiaOnboardingDocumentsAtom } from '@atoms/sofia';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateSofiaDocumentAssociation } from '../useCreateSofiaDocumentAssociation';
import { useReplaceSofiaDocumentAssociation } from '../useReplaceSofiaDocumentAssociation';
import { NotificationContext } from '../../../../contexts';
import { Document } from '../../types';
import { UseUploadSofiaDocumentProps } from './types';

export const useUploadSofiaDocument = ({
  businessId,
  acceptedFileTypes,
  maxFileSize,
  documents,
}: UseUploadSofiaDocumentProps) => {
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const [hasFileTypeError, setHasFileTypeError] = useState(false);
  const [hasFileSizeError, setHasFileSizeError] = useState(false);
  const { isLoading: isUploading, mutate: upload } = useMediaUploadMutation();
  const setOnboardingDocuments = useSetAtom(sofiaOnboardingDocumentsAtom);
  const { mutate: addDocument } = useCreateSofiaDocumentAssociation({
    onSuccess() {
      queryClient.invalidateQueries(['sofia-documents', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error adding Document',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const { mutate: replaceDocument } = useReplaceSofiaDocumentAssociation({
    onSuccess() {
      queryClient.invalidateQueries(['sofia-documents', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error replacing Document',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const uploadFile = useCallback(
    async (file: File, bypassFileTypeCheck?: boolean): Promise<{ success: boolean; message?: string; id?: string }> => {
      if (!businessId) {
        return { success: false, message: 'Invalid business id' };
      }
      if (isUploading) {
        return { success: false, message: 'Uploading in progress' };
      }
      if (!acceptedFileTypes.includes(file.type) && !bypassFileTypeCheck) {
        setHasFileTypeError(true);
        return { success: false, message: 'Invalid file type' };
      }
      if (file.size > maxFileSize * 1024 * 1024) {
        setHasFileSizeError(true);
        return { success: false, message: 'File exceeds size limit' };
      }

      return new Promise((resolve) => {
        upload(
          {
            businessId,
            file,
          },
          {
            onSettled: (data, _e) => {
              if (data) {
                const id = data?.id;
                return resolve({ success: true, id });
              }
              return resolve({ success: false, message: 'Error uploading file' });
            },
          },
        );
      });
    },
    [businessId, isUploading, acceptedFileTypes, maxFileSize, upload],
  );
  const linkFile = useCallback(
    async (doc: Document) => {
      const old = documents.find((d) => d.name === doc.name);
      if (old) {
        return replaceDocument({
          businessId,
          oldAssetId: old.id,
          newAssetId: doc.id,
          filename: doc.name,
        });
      }
      return addDocument({
        businessId,
        assetId: doc.id,
        filename: doc.name,
      });
    },
    [addDocument, businessId, documents, replaceDocument],
  );
  const storeDocument = useCallback(
    (doc: Document) => {
      setOnboardingDocuments((docs) => [...docs, doc]);
    },
    [setOnboardingDocuments],
  );
  return {
    uploadFile,
    linkDocument: linkFile,
    storeDocument,
    hasFileTypeError,
    hasFileSizeError,
  };
};
