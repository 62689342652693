import { tagIsVisibleInInbox, tagName } from '@connectlyai-sdks/data-business';
import { createSlice } from '@reduxjs/toolkit';
import { CUSTOM_FILTER_ALL_TASKS, CUSTOM_FILTER_MY_TASKS } from '../entities';
const DEFAULT_FILTERS = [
    {
        id: CUSTOM_FILTER_ALL_TASKS,
        name: 'All Tasks',
        type: 'ownership',
    },
    {
        id: CUSTOM_FILTER_MY_TASKS,
        name: 'My Tasks',
        type: 'ownership',
    },
];
const initialState = {
    items: {
        ids: [],
        byId: {},
    },
    enabled: {
        byId: {},
    },
};
export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setActiveFilter: (state, action) => {
            state.enabled.byId = {};
            state.enabled.byId[action.payload] = true;
        },
        setInactiveFilter: (state, action) => {
            delete state.enabled.byId[action.payload];
        },
        setTags: (state, action) => {
            state.items.ids = [];
            state.items.byId = {};
            DEFAULT_FILTERS.forEach((filter) => {
                state.items.ids.push(filter.id);
                state.items.byId[filter.id] = filter;
            });
            action.payload.filter(tagIsVisibleInInbox).forEach((tag) => {
                state.items.ids.push(tag.id);
                state.items.byId[tag.id] = {
                    id: tag.id,
                    name: tagName(tag),
                    type: 'tag',
                };
            });
        },
        reset: () => initialState,
    },
});
export const { reset, setActiveFilter, setInactiveFilter, setTags } = tagsSlice.actions;
export default tagsSlice.reducer;
