import { Timestamp } from "../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.billing";
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_UNSPECIFIED"] = 0] = "SUBSCRIPTION_STATUS_UNSPECIFIED";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_ACTIVE"] = 1] = "SUBSCRIPTION_STATUS_ACTIVE";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_CANCELLED"] = 2] = "SUBSCRIPTION_STATUS_CANCELLED";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_INCOMPLETE"] = 3] = "SUBSCRIPTION_STATUS_INCOMPLETE";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED"] = 4] = "SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_PAST_DUE"] = 5] = "SUBSCRIPTION_STATUS_PAST_DUE";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_PAUSED"] = 6] = "SUBSCRIPTION_STATUS_PAUSED";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_TRIALING"] = 7] = "SUBSCRIPTION_STATUS_TRIALING";
    SubscriptionStatus[SubscriptionStatus["SUBSCRIPTION_STATUS_UNPAID"] = 8] = "SUBSCRIPTION_STATUS_UNPAID";
    SubscriptionStatus[SubscriptionStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export function subscriptionStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "SUBSCRIPTION_STATUS_UNSPECIFIED":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED;
        case 1:
        case "SUBSCRIPTION_STATUS_ACTIVE":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE;
        case 2:
        case "SUBSCRIPTION_STATUS_CANCELLED":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_CANCELLED;
        case 3:
        case "SUBSCRIPTION_STATUS_INCOMPLETE":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_INCOMPLETE;
        case 4:
        case "SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED;
        case 5:
        case "SUBSCRIPTION_STATUS_PAST_DUE":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_PAST_DUE;
        case 6:
        case "SUBSCRIPTION_STATUS_PAUSED":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_PAUSED;
        case 7:
        case "SUBSCRIPTION_STATUS_TRIALING":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_TRIALING;
        case 8:
        case "SUBSCRIPTION_STATUS_UNPAID":
            return SubscriptionStatus.SUBSCRIPTION_STATUS_UNPAID;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SubscriptionStatus.UNRECOGNIZED;
    }
}
export function subscriptionStatusToJSON(object) {
    switch (object) {
        case SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED:
            return "SUBSCRIPTION_STATUS_UNSPECIFIED";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE:
            return "SUBSCRIPTION_STATUS_ACTIVE";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_CANCELLED:
            return "SUBSCRIPTION_STATUS_CANCELLED";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_INCOMPLETE:
            return "SUBSCRIPTION_STATUS_INCOMPLETE";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED:
            return "SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_PAST_DUE:
            return "SUBSCRIPTION_STATUS_PAST_DUE";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_PAUSED:
            return "SUBSCRIPTION_STATUS_PAUSED";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_TRIALING:
            return "SUBSCRIPTION_STATUS_TRIALING";
        case SubscriptionStatus.SUBSCRIPTION_STATUS_UNPAID:
            return "SUBSCRIPTION_STATUS_UNPAID";
        case SubscriptionStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_UNSPECIFIED"] = 0] = "INVOICE_STATUS_UNSPECIFIED";
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_DRAFT"] = 1] = "INVOICE_STATUS_DRAFT";
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_OPEN"] = 2] = "INVOICE_STATUS_OPEN";
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_PAID"] = 3] = "INVOICE_STATUS_PAID";
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_UNCOLLECTIBLE"] = 4] = "INVOICE_STATUS_UNCOLLECTIBLE";
    InvoiceStatus[InvoiceStatus["INVOICE_STATUS_VOID"] = 5] = "INVOICE_STATUS_VOID";
    InvoiceStatus[InvoiceStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(InvoiceStatus || (InvoiceStatus = {}));
export function invoiceStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "INVOICE_STATUS_UNSPECIFIED":
            return InvoiceStatus.INVOICE_STATUS_UNSPECIFIED;
        case 1:
        case "INVOICE_STATUS_DRAFT":
            return InvoiceStatus.INVOICE_STATUS_DRAFT;
        case 2:
        case "INVOICE_STATUS_OPEN":
            return InvoiceStatus.INVOICE_STATUS_OPEN;
        case 3:
        case "INVOICE_STATUS_PAID":
            return InvoiceStatus.INVOICE_STATUS_PAID;
        case 4:
        case "INVOICE_STATUS_UNCOLLECTIBLE":
            return InvoiceStatus.INVOICE_STATUS_UNCOLLECTIBLE;
        case 5:
        case "INVOICE_STATUS_VOID":
            return InvoiceStatus.INVOICE_STATUS_VOID;
        case -1:
        case "UNRECOGNIZED":
        default:
            return InvoiceStatus.UNRECOGNIZED;
    }
}
export function invoiceStatusToJSON(object) {
    switch (object) {
        case InvoiceStatus.INVOICE_STATUS_UNSPECIFIED:
            return "INVOICE_STATUS_UNSPECIFIED";
        case InvoiceStatus.INVOICE_STATUS_DRAFT:
            return "INVOICE_STATUS_DRAFT";
        case InvoiceStatus.INVOICE_STATUS_OPEN:
            return "INVOICE_STATUS_OPEN";
        case InvoiceStatus.INVOICE_STATUS_PAID:
            return "INVOICE_STATUS_PAID";
        case InvoiceStatus.INVOICE_STATUS_UNCOLLECTIBLE:
            return "INVOICE_STATUS_UNCOLLECTIBLE";
        case InvoiceStatus.INVOICE_STATUS_VOID:
            return "INVOICE_STATUS_VOID";
        case InvoiceStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType[InvoiceType["INVOICE_TYPE_UNSPECIFIED"] = 0] = "INVOICE_TYPE_UNSPECIFIED";
    InvoiceType[InvoiceType["INVOICE_TYPE_SUBSCRIPTION"] = 1] = "INVOICE_TYPE_SUBSCRIPTION";
    InvoiceType[InvoiceType["INVOICE_TYPE_USAGE"] = 2] = "INVOICE_TYPE_USAGE";
    InvoiceType[InvoiceType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(InvoiceType || (InvoiceType = {}));
export function invoiceTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "INVOICE_TYPE_UNSPECIFIED":
            return InvoiceType.INVOICE_TYPE_UNSPECIFIED;
        case 1:
        case "INVOICE_TYPE_SUBSCRIPTION":
            return InvoiceType.INVOICE_TYPE_SUBSCRIPTION;
        case 2:
        case "INVOICE_TYPE_USAGE":
            return InvoiceType.INVOICE_TYPE_USAGE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return InvoiceType.UNRECOGNIZED;
    }
}
export function invoiceTypeToJSON(object) {
    switch (object) {
        case InvoiceType.INVOICE_TYPE_UNSPECIFIED:
            return "INVOICE_TYPE_UNSPECIFIED";
        case InvoiceType.INVOICE_TYPE_SUBSCRIPTION:
            return "INVOICE_TYPE_SUBSCRIPTION";
        case InvoiceType.INVOICE_TYPE_USAGE:
            return "INVOICE_TYPE_USAGE";
        case InvoiceType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var BillingPlatform;
(function (BillingPlatform) {
    BillingPlatform[BillingPlatform["BILLING_PLATFORM_UNSPECIFIED"] = 0] = "BILLING_PLATFORM_UNSPECIFIED";
    BillingPlatform[BillingPlatform["BILLING_PLATFORM_STRIPE"] = 1] = "BILLING_PLATFORM_STRIPE";
    BillingPlatform[BillingPlatform["BILLING_PLATFORM_SHOPIFY"] = 2] = "BILLING_PLATFORM_SHOPIFY";
    BillingPlatform[BillingPlatform["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(BillingPlatform || (BillingPlatform = {}));
export function billingPlatformFromJSON(object) {
    switch (object) {
        case 0:
        case "BILLING_PLATFORM_UNSPECIFIED":
            return BillingPlatform.BILLING_PLATFORM_UNSPECIFIED;
        case 1:
        case "BILLING_PLATFORM_STRIPE":
            return BillingPlatform.BILLING_PLATFORM_STRIPE;
        case 2:
        case "BILLING_PLATFORM_SHOPIFY":
            return BillingPlatform.BILLING_PLATFORM_SHOPIFY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return BillingPlatform.UNRECOGNIZED;
    }
}
export function billingPlatformToJSON(object) {
    switch (object) {
        case BillingPlatform.BILLING_PLATFORM_UNSPECIFIED:
            return "BILLING_PLATFORM_UNSPECIFIED";
        case BillingPlatform.BILLING_PLATFORM_STRIPE:
            return "BILLING_PLATFORM_STRIPE";
        case BillingPlatform.BILLING_PLATFORM_SHOPIFY:
            return "BILLING_PLATFORM_SHOPIFY";
        case BillingPlatform.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBasePricingPlan() {
    return {
        id: "",
        name: "",
        price: 0,
        currency: "",
        recurringInterval: "",
        products: [],
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
    };
}
export const PricingPlan = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.price !== 0) {
            writer.uint32(61).float(message.price);
        }
        if (message.currency !== "") {
            writer.uint32(66).string(message.currency);
        }
        if (message.recurringInterval !== "") {
            writer.uint32(74).string(message.recurringInterval);
        }
        for (const v of message.products) {
            writer.uint32(82).string(v);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePricingPlan();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 7:
                    message.price = reader.float();
                    break;
                case 8:
                    message.currency = reader.string();
                    break;
                case 9:
                    message.recurringInterval = reader.string();
                    break;
                case 10:
                    message.products.push(reader.string());
                    break;
                case 4:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            price: isSet(object.price) ? Number(object.price) : 0,
            currency: isSet(object.currency) ? String(object.currency) : "",
            recurringInterval: isSet(object.recurringInterval)
                ? String(object.recurringInterval)
                : "",
            products: Array.isArray(object === null || object === void 0 ? void 0 : object.products)
                ? object.products.map((e) => String(e))
                : [],
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.price !== undefined && (obj.price = message.price);
        message.currency !== undefined && (obj.currency = message.currency);
        message.recurringInterval !== undefined &&
            (obj.recurringInterval = message.recurringInterval);
        if (message.products) {
            obj.products = message.products.map((e) => e);
        }
        else {
            obj.products = [];
        }
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBasePaymentMethod() {
    return { brand: "", last4: "" };
}
export const PaymentMethod = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.brand !== "") {
            writer.uint32(10).string(message.brand);
        }
        if (message.last4 !== "") {
            writer.uint32(18).string(message.last4);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaymentMethod();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.brand = reader.string();
                    break;
                case 2:
                    message.last4 = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            brand: isSet(object.brand) ? String(object.brand) : "",
            last4: isSet(object.last4) ? String(object.last4) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.brand !== undefined && (obj.brand = message.brand);
        message.last4 !== undefined && (obj.last4 = message.last4);
        return obj;
    },
};
function createBaseSubscription() {
    return {
        id: "",
        orgId: "",
        pricingPlanId: "",
        pricingPlan: undefined,
        platformCustomerId: "",
        billingPlatform: 0,
        platformSubscriptionId: "",
        domain: "",
        accessToken: "",
        status: 0,
        currentPeriodStart: undefined,
        currentPeriodEnd: undefined,
        cancelledAt: undefined,
        trialExpiredAt: undefined,
        trialEndedAt: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
    };
}
export const Subscription = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.orgId !== "") {
            writer.uint32(18).string(message.orgId);
        }
        if (message.pricingPlanId !== "") {
            writer.uint32(26).string(message.pricingPlanId);
        }
        if (message.pricingPlan !== undefined) {
            PricingPlan.encode(message.pricingPlan, writer.uint32(98).fork()).ldelim();
        }
        if (message.platformCustomerId !== "") {
            writer.uint32(106).string(message.platformCustomerId);
        }
        if (message.billingPlatform !== 0) {
            writer.uint32(128).int32(message.billingPlatform);
        }
        if (message.platformSubscriptionId !== "") {
            writer.uint32(138).string(message.platformSubscriptionId);
        }
        if (message.domain !== "") {
            writer.uint32(146).string(message.domain);
        }
        if (message.accessToken !== "") {
            writer.uint32(154).string(message.accessToken);
        }
        if (message.status !== 0) {
            writer.uint32(40).int32(message.status);
        }
        if (message.currentPeriodStart !== undefined) {
            Timestamp.encode(toTimestamp(message.currentPeriodStart), writer.uint32(50).fork()).ldelim();
        }
        if (message.currentPeriodEnd !== undefined) {
            Timestamp.encode(toTimestamp(message.currentPeriodEnd), writer.uint32(58).fork()).ldelim();
        }
        if (message.cancelledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.cancelledAt), writer.uint32(66).fork()).ldelim();
        }
        if (message.trialExpiredAt !== undefined) {
            Timestamp.encode(toTimestamp(message.trialExpiredAt), writer.uint32(114).fork()).ldelim();
        }
        if (message.trialEndedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.trialEndedAt), writer.uint32(122).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(74).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(82).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubscription();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orgId = reader.string();
                    break;
                case 3:
                    message.pricingPlanId = reader.string();
                    break;
                case 12:
                    message.pricingPlan = PricingPlan.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.platformCustomerId = reader.string();
                    break;
                case 16:
                    message.billingPlatform = reader.int32();
                    break;
                case 17:
                    message.platformSubscriptionId = reader.string();
                    break;
                case 18:
                    message.domain = reader.string();
                    break;
                case 19:
                    message.accessToken = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.currentPeriodStart = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.currentPeriodEnd = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.cancelledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.trialExpiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 15:
                    message.trialEndedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            orgId: isSet(object.orgId) ? String(object.orgId) : "",
            pricingPlanId: isSet(object.pricingPlanId)
                ? String(object.pricingPlanId)
                : "",
            pricingPlan: isSet(object.pricingPlan)
                ? PricingPlan.fromJSON(object.pricingPlan)
                : undefined,
            platformCustomerId: isSet(object.platformCustomerId)
                ? String(object.platformCustomerId)
                : "",
            billingPlatform: isSet(object.billingPlatform)
                ? billingPlatformFromJSON(object.billingPlatform)
                : 0,
            platformSubscriptionId: isSet(object.platformSubscriptionId)
                ? String(object.platformSubscriptionId)
                : "",
            domain: isSet(object.domain) ? String(object.domain) : "",
            accessToken: isSet(object.accessToken) ? String(object.accessToken) : "",
            status: isSet(object.status)
                ? subscriptionStatusFromJSON(object.status)
                : 0,
            currentPeriodStart: isSet(object.currentPeriodStart)
                ? String(object.currentPeriodStart)
                : undefined,
            currentPeriodEnd: isSet(object.currentPeriodEnd)
                ? String(object.currentPeriodEnd)
                : undefined,
            cancelledAt: isSet(object.cancelledAt)
                ? String(object.cancelledAt)
                : undefined,
            trialExpiredAt: isSet(object.trialExpiredAt)
                ? String(object.trialExpiredAt)
                : undefined,
            trialEndedAt: isSet(object.trialEndedAt)
                ? String(object.trialEndedAt)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.orgId !== undefined && (obj.orgId = message.orgId);
        message.pricingPlanId !== undefined &&
            (obj.pricingPlanId = message.pricingPlanId);
        message.pricingPlan !== undefined &&
            (obj.pricingPlan = message.pricingPlan
                ? PricingPlan.toJSON(message.pricingPlan)
                : undefined);
        message.platformCustomerId !== undefined &&
            (obj.platformCustomerId = message.platformCustomerId);
        message.billingPlatform !== undefined &&
            (obj.billingPlatform = billingPlatformToJSON(message.billingPlatform));
        message.platformSubscriptionId !== undefined &&
            (obj.platformSubscriptionId = message.platformSubscriptionId);
        message.domain !== undefined && (obj.domain = message.domain);
        message.accessToken !== undefined &&
            (obj.accessToken = message.accessToken);
        message.status !== undefined &&
            (obj.status = subscriptionStatusToJSON(message.status));
        message.currentPeriodStart !== undefined &&
            (obj.currentPeriodStart = message.currentPeriodStart);
        message.currentPeriodEnd !== undefined &&
            (obj.currentPeriodEnd = message.currentPeriodEnd);
        message.cancelledAt !== undefined &&
            (obj.cancelledAt = message.cancelledAt);
        message.trialExpiredAt !== undefined &&
            (obj.trialExpiredAt = message.trialExpiredAt);
        message.trialEndedAt !== undefined &&
            (obj.trialEndedAt = message.trialEndedAt);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBaseConversationPrice() {
    return {
        id: "",
        country: "",
        countryCode: "",
        currency: "",
        metaPrice: 0,
        price: 0,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
    };
}
export const ConversationPrice = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.country !== "") {
            writer.uint32(18).string(message.country);
        }
        if (message.countryCode !== "") {
            writer.uint32(26).string(message.countryCode);
        }
        if (message.currency !== "") {
            writer.uint32(34).string(message.currency);
        }
        if (message.metaPrice !== 0) {
            writer.uint32(45).float(message.metaPrice);
        }
        if (message.price !== 0) {
            writer.uint32(53).float(message.price);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(82).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(90).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConversationPrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.country = reader.string();
                    break;
                case 3:
                    message.countryCode = reader.string();
                    break;
                case 4:
                    message.currency = reader.string();
                    break;
                case 5:
                    message.metaPrice = reader.float();
                    break;
                case 6:
                    message.price = reader.float();
                    break;
                case 10:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            country: isSet(object.country) ? String(object.country) : "",
            countryCode: isSet(object.countryCode) ? String(object.countryCode) : "",
            currency: isSet(object.currency) ? String(object.currency) : "",
            metaPrice: isSet(object.metaPrice) ? Number(object.metaPrice) : 0,
            price: isSet(object.price) ? Number(object.price) : 0,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.country !== undefined && (obj.country = message.country);
        message.countryCode !== undefined &&
            (obj.countryCode = message.countryCode);
        message.currency !== undefined && (obj.currency = message.currency);
        message.metaPrice !== undefined && (obj.metaPrice = message.metaPrice);
        message.price !== undefined && (obj.price = message.price);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBaseInvoice() {
    return {
        id: "",
        orgId: "",
        subscriptionId: "",
        type: 0,
        billingPlatform: 0,
        platformInvoiceId: "",
        periodStart: undefined,
        periodEnd: undefined,
        status: 0,
        currency: "",
        amount: 0,
        lines: undefined,
        finalizedAt: undefined,
        paidAt: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
    };
}
export const Invoice = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.orgId !== "") {
            writer.uint32(18).string(message.orgId);
        }
        if (message.subscriptionId !== "") {
            writer.uint32(26).string(message.subscriptionId);
        }
        if (message.type !== 0) {
            writer.uint32(136).int32(message.type);
        }
        if (message.billingPlatform !== 0) {
            writer.uint32(128).int32(message.billingPlatform);
        }
        if (message.platformInvoiceId !== "") {
            writer.uint32(34).string(message.platformInvoiceId);
        }
        if (message.periodStart !== undefined) {
            Timestamp.encode(toTimestamp(message.periodStart), writer.uint32(42).fork()).ldelim();
        }
        if (message.periodEnd !== undefined) {
            Timestamp.encode(toTimestamp(message.periodEnd), writer.uint32(50).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(56).int32(message.status);
        }
        if (message.currency !== "") {
            writer.uint32(114).string(message.currency);
        }
        if (message.amount !== 0) {
            writer.uint32(125).float(message.amount);
        }
        if (message.lines !== undefined) {
            InvoiceLines.encode(message.lines, writer.uint32(66).fork()).ldelim();
        }
        if (message.finalizedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.finalizedAt), writer.uint32(74).fork()).ldelim();
        }
        if (message.paidAt !== undefined) {
            Timestamp.encode(toTimestamp(message.paidAt), writer.uint32(82).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(90).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(98).fork()).ldelim();
        }
        if (message.deletedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInvoice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orgId = reader.string();
                    break;
                case 3:
                    message.subscriptionId = reader.string();
                    break;
                case 17:
                    message.type = reader.int32();
                    break;
                case 16:
                    message.billingPlatform = reader.int32();
                    break;
                case 4:
                    message.platformInvoiceId = reader.string();
                    break;
                case 5:
                    message.periodStart = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.periodEnd = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.status = reader.int32();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.amount = reader.float();
                    break;
                case 8:
                    message.lines = InvoiceLines.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.finalizedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.paidAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            orgId: isSet(object.orgId) ? String(object.orgId) : "",
            subscriptionId: isSet(object.subscriptionId)
                ? String(object.subscriptionId)
                : "",
            type: isSet(object.type) ? invoiceTypeFromJSON(object.type) : 0,
            billingPlatform: isSet(object.billingPlatform)
                ? billingPlatformFromJSON(object.billingPlatform)
                : 0,
            platformInvoiceId: isSet(object.platformInvoiceId)
                ? String(object.platformInvoiceId)
                : "",
            periodStart: isSet(object.periodStart)
                ? String(object.periodStart)
                : undefined,
            periodEnd: isSet(object.periodEnd) ? String(object.periodEnd) : undefined,
            status: isSet(object.status) ? invoiceStatusFromJSON(object.status) : 0,
            currency: isSet(object.currency) ? String(object.currency) : "",
            amount: isSet(object.amount) ? Number(object.amount) : 0,
            lines: isSet(object.lines)
                ? InvoiceLines.fromJSON(object.lines)
                : undefined,
            finalizedAt: isSet(object.finalizedAt)
                ? String(object.finalizedAt)
                : undefined,
            paidAt: isSet(object.paidAt) ? String(object.paidAt) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.orgId !== undefined && (obj.orgId = message.orgId);
        message.subscriptionId !== undefined &&
            (obj.subscriptionId = message.subscriptionId);
        message.type !== undefined && (obj.type = invoiceTypeToJSON(message.type));
        message.billingPlatform !== undefined &&
            (obj.billingPlatform = billingPlatformToJSON(message.billingPlatform));
        message.platformInvoiceId !== undefined &&
            (obj.platformInvoiceId = message.platformInvoiceId);
        message.periodStart !== undefined &&
            (obj.periodStart = message.periodStart);
        message.periodEnd !== undefined && (obj.periodEnd = message.periodEnd);
        message.status !== undefined &&
            (obj.status = invoiceStatusToJSON(message.status));
        message.currency !== undefined && (obj.currency = message.currency);
        message.amount !== undefined && (obj.amount = message.amount);
        message.lines !== undefined &&
            (obj.lines = message.lines
                ? InvoiceLines.toJSON(message.lines)
                : undefined);
        message.finalizedAt !== undefined &&
            (obj.finalizedAt = message.finalizedAt);
        message.paidAt !== undefined && (obj.paidAt = message.paidAt);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
        return obj;
    },
};
function createBaseInvoiceLines() {
    return { items: [] };
}
export const InvoiceLines = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.items) {
            InvoiceItem.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInvoiceLines();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.items.push(InvoiceItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            items: Array.isArray(object === null || object === void 0 ? void 0 : object.items)
                ? object.items.map((e) => InvoiceItem.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.items) {
            obj.items = message.items.map((e) => e ? InvoiceItem.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        return obj;
    },
};
function createBaseInvoiceItem() {
    return {
        id: "",
        periodStart: undefined,
        periodEnd: undefined,
        conversations: 0,
        amount: 0,
        currency: "",
        description: "",
    };
}
export const InvoiceItem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.periodStart !== undefined) {
            Timestamp.encode(toTimestamp(message.periodStart), writer.uint32(18).fork()).ldelim();
        }
        if (message.periodEnd !== undefined) {
            Timestamp.encode(toTimestamp(message.periodEnd), writer.uint32(26).fork()).ldelim();
        }
        if (message.conversations !== 0) {
            writer.uint32(56).uint32(message.conversations);
        }
        if (message.amount !== 0) {
            writer.uint32(37).float(message.amount);
        }
        if (message.currency !== "") {
            writer.uint32(42).string(message.currency);
        }
        if (message.description !== "") {
            writer.uint32(50).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInvoiceItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.periodStart = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.periodEnd = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.conversations = reader.uint32();
                    break;
                case 4:
                    message.amount = reader.float();
                    break;
                case 5:
                    message.currency = reader.string();
                    break;
                case 6:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            periodStart: isSet(object.periodStart)
                ? String(object.periodStart)
                : undefined,
            periodEnd: isSet(object.periodEnd) ? String(object.periodEnd) : undefined,
            conversations: isSet(object.conversations)
                ? Number(object.conversations)
                : 0,
            amount: isSet(object.amount) ? Number(object.amount) : 0,
            currency: isSet(object.currency) ? String(object.currency) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.periodStart !== undefined &&
            (obj.periodStart = message.periodStart);
        message.periodEnd !== undefined && (obj.periodEnd = message.periodEnd);
        message.conversations !== undefined &&
            (obj.conversations = Math.round(message.conversations));
        message.amount !== undefined && (obj.amount = message.amount);
        message.currency !== undefined && (obj.currency = message.currency);
        message.description !== undefined &&
            (obj.description = message.description);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
