import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_UPSERT_CAMPAIGN_UTM_CONFIGURATION =
  '/internal/v1/businesses/{input.businessId}/upsert_utm_configuration/campaigns';
type MutationUpsertCampaignUTMConfigurationURL = typeof MUTATION_UPSERT_CAMPAIGN_UTM_CONFIGURATION;

export type MutationUpsertCampaignUTMConfigurationResponse =
  Paths['campaignproductV1'][MutationUpsertCampaignUTMConfigurationURL]['post']['responses']['200']['schema'];
type MutationUpsertCampaignUTMConfigurationBody = NonNullable<
  Paths['campaignproductV1'][MutationUpsertCampaignUTMConfigurationURL]['post']['parameters']['body']['input']
>;
export type MutationUpsertCampaignUTMConfigurationInput = {
  businessId: string;
} & MutationUpsertCampaignUTMConfigurationBody;
export type MutationUpsertCampaignUTMConfigurationOptions = Omit<
  UseMutationOptions<
    MutationUpsertCampaignUTMConfigurationResponse,
    ConnectlyError,
    MutationUpsertCampaignUTMConfigurationInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
