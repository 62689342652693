import React from 'react';
import { InputAdornment, TextField, SaveActionCard } from '@connectlyai-tenets/ui-styled-web';
import { usePartialAgentDescriptor } from './usePartialAgentDescriptor';

export const WelcomeMessage = () => {
  const {
    value: welcomeMessage,
    setValue: setWelcomeMessage,
    onSave,
  } = usePartialAgentDescriptor<string>({
    keyPath: 'defaultWelcomeMessage',
    defaultValue: 'Hello👋, what can we help you find today?',
  });
  return (
    <SaveActionCard
      onSubmit={onSave}
      title="Welcome message"
      subtitle="This is the first message customers will see when they open the WebSearch. Make it welcoming and informative!"
    >
      <TextField
        fullWidth
        placeholder="Enter phone number"
        variant="outlined"
        autoComplete="new-password"
        InputLabelProps={{ shrink: true }}
        value={welcomeMessage}
        onChange={(e) => setWelcomeMessage(e.target.value)}
        inputProps={{ maxLength: 400 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{`${welcomeMessage.length}/${400}`}</InputAdornment>,
        }}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { borderRadius: '10px' },
          '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
        }}
      />
    </SaveActionCard>
  );
};
