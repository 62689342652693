import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  INSTALL_INTEGRATION_URL,
  InstallIntegrationOptions,
  InstallIntegrationVariables,
  InstallIntegrationResponse,
} from './types';

export const useInstallIntegrationMutation = (options?: InstallIntegrationOptions) => {
  return useMutation(
    (variables: InstallIntegrationVariables) =>
      api.integrationV1(INSTALL_INTEGRATION_URL, 'post', {
        path: { 'input.businessId': variables.businessId },
        body: {
          input: {
            ...variables,
          },
        },
      }) as Promise<InstallIntegrationResponse>,
    {
      ...options,
    },
  );
};
