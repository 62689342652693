import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { UpdateMessageTemplateVariables, UpdateMessageTemplateResponse } from './types';

export const useUpdateMessageTemplateMutation = (
  options?: Omit<
    UseMutationOptions<UpdateMessageTemplateResponse, ConnectlyError, UpdateMessageTemplateVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, byExternalTemplateId, templateComponents }: UpdateMessageTemplateVariables) =>
      api.profileV1('/internal/v1/businesses/{businessId}/update/message_template', 'put', {
        path: { businessId },
        body: { input: { byExternalTemplateId, templateComponents } },
      }) as Promise<UpdateMessageTemplateResponse>,
    options,
  );
