import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { BusinessChannelsQuery, BusinessChannelsResponse, BusinessChannelsUrlParameters } from './types';

export const useBusinessChannelsData = <TData = BusinessChannelsResponse>({
  businessId,
  filter,
  ...options
}: Omit<
  UseQueryOptions<BusinessChannelsResponse, unknown, TData, readonly [target: 'businessChannels', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<BusinessChannelsQuery> &
  BusinessChannelsUrlParameters) =>
  useQuery(
    ['businessChannels', businessId],
    () =>
      api.profileV1('/v1/businesses/{input.businessId}/query/channels', 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { filter } },
      }),
    options,
  );
