export const mutationDeleteQuickReplyTemplateProvider = (log, networkClient) => async (businessId, itemId) => {
    const url = `/v1/businesses/${businessId}/quick_reply_templates/${itemId}`;
    log.debug('mutationDeleteQuickReplyTemplate@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.delete(url, {});
        log.debug('mutationDeleteQuickReplyTemplate@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationDeleteQuickReplyTemplate@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
