import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.notification";
export var TemplateStatusEmail;
(function (TemplateStatusEmail) {
    TemplateStatusEmail[TemplateStatusEmail["TEMPLATE_STATUS_EMAIL_UNSPECIFIED"] = 0] = "TEMPLATE_STATUS_EMAIL_UNSPECIFIED";
    TemplateStatusEmail[TemplateStatusEmail["TEMPLATE_STATUS_EMAIL_SEND"] = 1] = "TEMPLATE_STATUS_EMAIL_SEND";
    TemplateStatusEmail[TemplateStatusEmail["TEMPLATE_STATUS_EMAIL_SKIP"] = 2] = "TEMPLATE_STATUS_EMAIL_SKIP";
    TemplateStatusEmail[TemplateStatusEmail["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TemplateStatusEmail || (TemplateStatusEmail = {}));
export function templateStatusEmailFromJSON(object) {
    switch (object) {
        case 0:
        case "TEMPLATE_STATUS_EMAIL_UNSPECIFIED":
            return TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_UNSPECIFIED;
        case 1:
        case "TEMPLATE_STATUS_EMAIL_SEND":
            return TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_SEND;
        case 2:
        case "TEMPLATE_STATUS_EMAIL_SKIP":
            return TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_SKIP;
        case -1:
        case "UNRECOGNIZED":
        default:
            return TemplateStatusEmail.UNRECOGNIZED;
    }
}
export function templateStatusEmailToJSON(object) {
    switch (object) {
        case TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_UNSPECIFIED:
            return "TEMPLATE_STATUS_EMAIL_UNSPECIFIED";
        case TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_SEND:
            return "TEMPLATE_STATUS_EMAIL_SEND";
        case TemplateStatusEmail.TEMPLATE_STATUS_EMAIL_SKIP:
            return "TEMPLATE_STATUS_EMAIL_SKIP";
        case TemplateStatusEmail.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseNotificationSettingsV1() {
    return { templateStatusEmail: 0 };
}
export const NotificationSettingsV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.templateStatusEmail !== 0) {
            writer.uint32(8).int32(message.templateStatusEmail);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNotificationSettingsV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateStatusEmail = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateStatusEmail: isSet(object.templateStatusEmail)
                ? templateStatusEmailFromJSON(object.templateStatusEmail)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.templateStatusEmail !== undefined &&
            (obj.templateStatusEmail = templateStatusEmailToJSON(message.templateStatusEmail));
        return obj;
    },
};
function createBaseNotificationSettings() {
    return { notificationSettingsOneof: undefined };
}
export const NotificationSettings = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.notificationSettingsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            NotificationSettingsV1.encode(message.notificationSettingsOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNotificationSettings();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.notificationSettingsOneof = {
                        $case: "v1",
                        v1: NotificationSettingsV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            notificationSettingsOneof: isSet(object.v1)
                ? { $case: "v1", v1: NotificationSettingsV1.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.notificationSettingsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.notificationSettingsOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? NotificationSettingsV1.toJSON((_c = message.notificationSettingsOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
