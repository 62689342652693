import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { WidgetType } from '@codemirror/view';
import { TextVariable } from '../TextVariable/TextVariable';
import { VariableWidgetState } from './types';

export class VariableWidget extends WidgetType {
  private readonly state: VariableWidgetState;

  constructor(state: VariableWidgetState) {
    super();
    this.state = state;
  }

  toDOM() {
    const dom = document.createElement('span');
    dom.innerHTML = renderToStaticMarkup(<TextVariable {...this.state} />);
    dom.onclick = () => this.state.onClick({ ...this.state, dom });
    return dom;
  }

  updateDOM(dom: HTMLElement) {
    dom.innerHTML = renderToStaticMarkup(<TextVariable {...this.state} />);
    dom.onclick = () => this.state.onClick({ ...this.state, dom });
    return true;
  }
}
