import React, { FC } from 'react';
import { Box, Label, Chip, useTheme, useMediaQuery, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartGetStartedCampaignCardProps } from './types';

export const FlowChartGetStartedCampaignCard: FC<FlowChartGetStartedCampaignCardProps> = ({
  exampleText,
  handleChooseCampaignTemplate,
  image,
  subtitle,
  tags,
  title,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ConnectlyCard
      title={title}
      sx={{
        flex: '0 0 auto',
        flexBasis: `calc(50% - ${theme.spacing(3)})`,
      }}
      subtitle={isLargeScreen ? subtitle : undefined}
      media={{
        children: (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            {tags.map((tag) => (
              <Chip
                key={tag}
                label={<Label variant="body2">{tag}</Label>}
                sx={{ background: 'grey[100]', color: tag === 'Marketing' ? 'success.dark' : 'inherit' }}
              />
            ))}
          </Box>
        ),
      }}
      onClick={handleChooseCampaignTemplate}
    >
      {isLargeScreen && (
        <Box
          sx={{
            borderRadius: '8px',
            display: 'flex',
            gap: 1.5,
            mt: 3,
            px: 1.5,
            py: 1.5,
            border: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey[300],
            background: theme.palette.common.white,
          }}
        >
          <Box component="img" src={image} alt="image" sx={{ width: 54, height: 54, borderRadius: '8px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              maxHeight: 54,
            }}
          >
            <Label variant="body2" component="div" overflow="hidden" textOverflow="ellipsis" sx={{ maxHeight: 36 }}>
              {exampleText}
            </Label>
          </Box>
        </Box>
      )}
    </ConnectlyCard>
  );
};
