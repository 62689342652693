import React, { useRef, useEffect, useCallback, useContext, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTheme, CloseIcon, Box, TextField, InputAdornment, SaveActionCard } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, selectRole, useMeData } from '@hooks';
import { useUpdateBusinessProfileMutation } from '@scenes/Settings/hooks/useUpdateBusinessProfileMutation';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useQueryClient } from '@tanstack/react-query';
import {
  businessProfileNameAtom as nameAtom,
  businessProfileEditedAtom as editedAtom,
  businessProfileErrorAtom as errorAtom,
} from '@atoms/settings';
import { MAX_DISPLAY_NAME_BUSINESS_LENGTH } from './constants';
import { NotificationContext } from '../../../../contexts';
import { useSettingsData } from '../../../../hooks/useSettingsData';

const useBusinessProfile = () => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useAtom(nameAtom);
  const [edited, setEdited] = useAtom(editedAtom);
  const [error, setError] = useAtom(errorAtom);
  const queryClient = useQueryClient();
  const { data: role } = useMeData({ select: selectRole });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading: isProfileLoading, data: profile } = useSettingsData({
    businessId: businessId as string,
  });
  useEffect(() => {
    // profile name first loaded
    const newName = profile?.entity?.name;
    if (newName && newName !== name) {
      setName(newName);
    }
  }, [name, setName, profile]);

  const resetInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = name;
      inputRef.current.focus();
    }
    setEdited(false);
    setError(false);
  }, [name]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const editedNew = newValue !== name;
      if (edited !== editedNew) {
        setEdited(editedNew);
      }
      if (error) {
        setError(false);
      }
    },
    [error, setError, setEdited, edited, name],
  );

  const { notificationProvider } = useContext(NotificationContext);
  const isAgent = useMemo(() => !role || role === 'ROLE_AGENT', [role]);

  const { mutate: updateProfileMutate, isLoading: isProfileUpdating } = useUpdateBusinessProfileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['settings']);
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully updated business name',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
      setEdited(false);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error saving user profile',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();
      const newName = inputRef.current?.value;
      if (!profile) return;
      if (!newName) {
        setError(true);
        return;
      }
      updateProfileMutate({
        businessId: businessId as string,
        ...profile.entity,
        profilePicId: profile.entity?.profilePicId || '',
        handle: profile.entity?.handle || '',
        name: newName,
      });
    },
    [inputRef, businessId, profile, updateProfileMutate, setError],
  );

  return {
    edited,
    error,
    handleChange,
    handleSubmit,
    inputRef,
    isAgent,
    isProfileLoading,
    isProfileUpdating,
    name: profile?.entity?.name,
    resetInput,
    theme,
  };
};

export const BusinessProfile = () => {
  const {
    edited,
    error,
    handleChange,
    handleSubmit,
    inputRef,
    isAgent,
    isProfileLoading,
    isProfileUpdating,
    name,
    resetInput,
    theme,
  } = useBusinessProfile();

  return (
    <SaveActionCard
      onSubmit={handleSubmit}
      saveDisabled={Boolean(error || isAgent || isProfileUpdating)}
      title="Business Profile"
      isLoading={isProfileLoading}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          inputRef={inputRef}
          id="business-name"
          name="name"
          label="Business Name"
          defaultValue={name}
          // rerender when name is loaded to set defaultValue
          // rerender when error state changes to fix input label bug
          key={`${name}-${error}`}
          variant="outlined"
          inputProps={{ maxLength: MAX_DISPLAY_NAME_BUSINESS_LENGTH }}
          onChange={handleChange}
          disabled={isAgent}
          helperText={error ? 'Name cannot be empty' : ' '}
          error={Boolean(error)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    cursor: edited ? 'pointer' : 'default',
                    p: 1,
                    visibility: edited ? 'visible' : 'hidden',
                  }}
                  onClick={resetInput}
                >
                  <CloseIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </SaveActionCard>
  );
};
