import { createSlice } from '@reduxjs/toolkit';
import { UpdateShopifyProfileByOptions } from '../entities';
import { mergeCustomers, searchShopifyCustomerChannels } from './thunk';
import { resetStore, resetUpdate } from './action';
const initialState = {
    isActive: false,
    isError: false,
    searchConfirmActive: false,
    searchHelperText: '',
    searchInput: '',
    updatePotentialMatchCustomerId: null,
    updateShopifyProfileByOption: null,
};
export const updateShopifyProfileSlice = createSlice({
    name: 'updateShopifyProfile',
    initialState,
    reducers: {
        setUpdateActive(state, action) {
            if (state.isActive && !action.payload) {
                state.isError = false;
                state.searchConfirmActive = false;
                state.searchHelperText = '';
                state.searchInput = '';
                state.updateShopifyProfileByOption = null;
                state.updatePotentialMatchCustomerId = null;
            }
            if (!state.isActive && action.payload) {
                state.isError = false;
                state.searchConfirmActive = false;
                state.searchHelperText = '';
                state.searchInput = '';
                state.updateShopifyProfileByOption = UpdateShopifyProfileByOptions.BY_SHOPIFY_ID.key;
                state.updatePotentialMatchCustomerId = null;
            }
            state.isActive = action.payload;
        },
        setSearchInput(state, action) {
            if (action.payload === '') {
                state.isError = false;
                state.searchConfirmActive = false;
                state.searchHelperText = '';
                state.updatePotentialMatchCustomerId = null;
            }
            state.searchInput = action.payload;
        },
        setUpdateShopifyProfileByOption: (state, action) => {
            state.isError = false;
            state.isActive = action.payload !== null;
            state.searchConfirmActive = false;
            state.searchHelperText = '';
            state.searchInput = '';
            state.updateShopifyProfileByOption = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(searchShopifyCustomerChannels.pending, (state, _action) => {
            state.searchConfirmActive = false;
            state.isError = false;
            state.searchHelperText = '';
        });
        builder.addCase(searchShopifyCustomerChannels.fulfilled, (state, action) => {
            state.searchConfirmActive = false;
            state.isError = false;
            state.searchHelperText = '';
            const result = action.payload;
            if (result.entries.length > 1) {
                state.isError = true;
                state.searchHelperText = `Too many matching entries found: ${result.entries.length}`;
            }
            else if (result.entries.length === 0) {
                state.isError = true;
                state.searchHelperText = 'No matching entries found';
            }
            else {
                state.searchHelperText = '1 matching entry found';
                state.searchConfirmActive = true;
                state.updatePotentialMatchCustomerId = result.entries[0].customerId;
            }
        });
        builder.addCase(searchShopifyCustomerChannels.rejected, (state, _action) => {
            state.searchConfirmActive = false;
            state.isError = true;
            state.searchHelperText = 'Error: Please try again!';
            state.updatePotentialMatchCustomerId = null;
        });
        builder.addCase(mergeCustomers.fulfilled, (state, _action) => {
            state.isActive = false;
            state.isError = false;
            state.searchConfirmActive = false;
            state.searchHelperText = '';
            state.searchInput = '';
            state.updateShopifyProfileByOption = null;
            state.updatePotentialMatchCustomerId = null;
        });
        builder.addCase(mergeCustomers.rejected, (state, _action) => {
            state.isError = true;
            state.searchConfirmActive = false;
            state.searchHelperText = 'Error: Please try again!';
            state.searchInput = '';
            state.updatePotentialMatchCustomerId = null;
        });
        builder.addCase(resetUpdate, () => initialState);
    },
});
export const { reset, setSearchInput, setUpdateActive, setUpdateShopifyProfileByOption } = updateShopifyProfileSlice.actions;
export const selectUpdateShopifyProfileState = (state) => state.updateShopifyProfileSlice;
export default updateShopifyProfileSlice.reducer;
