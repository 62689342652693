import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const FLOW_EVENT_REQUEST_COMPILE_URL =
  '/internal/v1/businesses/{input.businessId}/request_compile_react_flow_document/flow_documents';

type FlowEventRequestCompileURL = typeof FLOW_EVENT_REQUEST_COMPILE_URL;

export type FlowEventRequestCompileResponse = Paths['flowV1'][FlowEventRequestCompileURL]['post']['responses']['202'];
export type FlowEventRequestCompileInput = {
  businessId: string;
} & Paths['flowV1'][FlowEventRequestCompileURL]['post']['parameters']['body']['input'];
export type FlowEventRequestCompileOptions = Omit<
  UseMutationOptions<FlowEventRequestCompileResponse, unknown, FlowEventRequestCompileInput, unknown>,
  'mutationKey' | 'mutationFn'
>;
