import React, { FC, ChangeEventHandler, useRef, useCallback } from 'react';
import {
  Box,
  Checkbox,
  DeleteOutlineIcon,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Label,
  OpenInNewIcon,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { BUTTON_TEXT_MAX_LENGTH } from '@components/TemplateBuilderButtons';
import { CodeMirrorTextField } from '@components/CodeMirrorTextField';
import { AddVariable } from '@components/AddVariable';
import { FlowVariableDialog, FlowVariableDialogRef } from '@components/FlowVariableDialog';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';

const LinkToUrl: FC<{
  onDelete?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChangeUrl: (value: string) => void;
  onChangeTracked: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disableDelete?: boolean;
  index: number;
  errorText?: string;
  errorUrl?: string;
  value: string;
  valueUrl: string;
  tracked: boolean;
}> = ({
  onDelete,
  onChange,
  onChangeUrl,
  onChangeTracked,
  index,
  errorText,
  errorUrl,
  value,
  valueUrl,
  disableDelete,
  tracked,
}) => {
  const urlVarDialogRef = useRef<FlowVariableDialogRef>(null);
  const urlAddVarRef = useRef<HTMLButtonElement>(null);
  const urlActiveVarRef = useRef<HTMLElement | null>(null);
  const urlCodeMirrorRef = useRef<ReactCodeMirrorRef>({});

  const handleUrlOpenDialog = useCallback(() => {
    urlVarDialogRef.current?.handleOpenDialog();
  }, [urlVarDialogRef]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} id={`message-template-button${index}-content`}>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 0.5,
        }}
      >
        <OpenInNewIcon color="action" sx={{ width: 24, height: 24 }} />
        <Label variant="body1" sx={{ fontWeight: 'fontWeightMedium', flex: '1 1 auto' }}>
          Link to website
        </Label>
        {!disableDelete && (
          <IconButton size="small" onClick={onDelete} sx={{ m: '-5px 0' }}>
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Box>
      <TextField
        id={`message-template-button${index}-text`}
        fullWidth
        placeholder="Enter text"
        error={Boolean(errorText)}
        helperText={errorText}
        variant="outlined"
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        value={value}
        inputProps={{ maxLength: BUTTON_TEXT_MAX_LENGTH }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{`${value.length}/${BUTTON_TEXT_MAX_LENGTH}`}</InputAdornment>,
        }}
        onChange={onChange}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { borderRadius: '10px' },
          '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
        }}
      />
      <CodeMirrorTextField
        value={valueUrl}
        codeMirrorUpdate={urlVarDialogRef?.current?.handleCodeMirrorUpdate}
        editable
        handleVariableClick={urlVarDialogRef?.current?.handleVariableClick}
        placeholder="www.connectly.ai/{{variable}}"
        setValue={onChangeUrl}
        ref={urlCodeMirrorRef}
        containerStyle={{
          marginTop: 1,
          paddingLeft: 0.5,
        }}
      />
      <InputLabel error={Boolean(errorUrl)}>{errorUrl}</InputLabel>
      <AddVariable
        onClick={handleUrlOpenDialog}
        ref={urlAddVarRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      />
      <FlowVariableDialog
        anchorEl={urlActiveVarRef?.current || urlAddVarRef?.current}
        codeMirrorRef={urlCodeMirrorRef}
        value={valueUrl}
        handleEditorChange={onChangeUrl}
        ref={urlVarDialogRef}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <FormGroup>
          <FormControlLabel
            sx={{
              mr: 0,
            }}
            control={<Checkbox checked={tracked} onChange={onChangeTracked} />}
            label={<Label variant="body2">Smart link tracking</Label>}
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

export default LinkToUrl;
