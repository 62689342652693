import { createSlice } from '@reduxjs/toolkit';
import { ChannelType } from '@connectlyai-idl/models/dist/models/common/channel/models';
import { DeliveryStatus } from '@connectlyai-idl/models/dist/models/common/models';
import { hasTransientEvent } from '@connectlyai-idl/models/dist/utils';
import { fetchRooms, fetchRoom } from './fetchThunk';
const initialState = {
    requestId: '',
    status: 'empty',
    pageStatus: 'empty',
    timestamp: 0,
    filter: '',
    offset: undefined,
    activeRoomsById: {},
    messages: {
        byId: {},
    },
    rooms: {
        roomsById: {},
    },
};
function mergeEventCollectionDto(oldDto, newDto) {
    const seen = {};
    const out = [];
    function checkDups(events) {
        for (let i = 0; i < events.length; i += 1) {
            const item = events[i];
            const key = item.id;
            if (seen[key] !== 1) {
                seen[key] = 1;
                out.push(item);
            }
        }
    }
    checkDups(newDto);
    checkDups(oldDto);
    out.sort((a, b) => a.id.localeCompare(b.id));
    return out;
}
function mergeRoomDto(oldDto, newDto) {
    if (!oldDto) {
        return newDto;
    }
    const out = {
        ...newDto,
        events: mergeEventCollectionDto(oldDto.events, newDto.events),
    };
    return out;
}
export const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        optimisticMessage: (state, action) => {
            var _a, _b, _c;
            const room = state.rooms.roomsById[action.payload.roomId];
            const optimisticMessage = {
                id: 'optimisticId',
                roomId: action.payload.roomId,
                createdAt: undefined,
                sentAt: undefined,
                deliveredAt: undefined,
                readAt: undefined,
                authorId: action.payload.businessUserId,
                businessUserId: action.payload.businessUserId,
                channelType: ((_a = action.payload.senderChannel) === null || _a === void 0 ? void 0 : _a.channelType) || ChannelType.CHANNEL_TYPE_UNSPECIFIED,
                businessChannelId: ((_b = action.payload.senderChannel) === null || _b === void 0 ? void 0 : _b.id) || '',
                customerChannelId: ((_c = action.payload.recipientChannel) === null || _c === void 0 ? void 0 : _c.id) || '',
                connectlyEvent: action.payload.connectlyEvent,
                platformError: undefined,
                tags: undefined,
                externalId: undefined,
                replyTo: undefined,
            };
            if (room === undefined) {
                state.rooms.roomsById[action.payload.roomId] = {
                    roomId: action.payload.roomId,
                    events: [optimisticMessage],
                    lastEventTime: undefined,
                    latestCustomerEventId: '',
                    latestUserEventId: '',
                    users: [],
                    businessChannelsById: {},
                    customerChannelsById: {},
                    customer: undefined,
                    tags: [],
                    assignedUserId: '',
                    read: false,
                    unreadCount: 0,
                    status: undefined,
                    tagsV2: undefined,
                    metadata: undefined,
                };
                return;
            }
            room.events.push(optimisticMessage);
        },
        roomDtoUpdate: (state, action) => {
            const newArrival = action.payload;
            const existingRoom = state.rooms.roomsById[newArrival.roomId];
            state.rooms.roomsById[newArrival.roomId] = mergeRoomDto(existingRoom, newArrival);
        },
        newEventArrival: (state, action) => {
            var _a, _b;
            state.status = 'newevent';
            const newArrival = action.payload;
            if (!hasTransientEvent(newArrival)) {
                const existingRoom = state.rooms.roomsById[newArrival.roomId];
                newArrival.events.forEach((event) => {
                    state.messages.byId[event.id] = event;
                });
                if (existingRoom) {
                    const roomEvents = existingRoom.events;
                    Array.prototype.push.apply(roomEvents, newArrival.events);
                    const roomEventsMap = new Map();
                    const uniqueEvents = roomEvents.filter((x) => {
                        if (roomEventsMap.has(x.id)) {
                            return false;
                        }
                        roomEventsMap.set(x.id, x.id);
                        return true;
                    });
                    const roomUsers = existingRoom.users;
                    Array.prototype.push.apply(roomUsers, newArrival.users);
                    const roomUsersMap = new Map();
                    const uniqueUsers = roomUsers.filter((x) => {
                        if (roomUsersMap.has(x.userId)) {
                            return false;
                        }
                        roomUsersMap.set(x.userId, x.userId);
                        return true;
                    });
                    const { tags } = existingRoom;
                    Array.prototype.push.apply(tags, newArrival.tags);
                    const uniqueTags = [...new Set(tags)];
                    const customerChannelsById = {
                        ...existingRoom.customerChannelsById,
                        ...newArrival.customerChannelsById,
                    };
                    const businessChannelsById = {
                        ...existingRoom.businessChannelsById,
                        ...newArrival.businessChannelsById,
                    };
                    state.rooms.roomsById[newArrival.roomId] = {
                        roomId: newArrival.roomId,
                        lastEventTime: newArrival.lastEventTime,
                        latestCustomerEventId: newArrival.latestCustomerEventId,
                        latestUserEventId: newArrival.latestUserEventId,
                        users: uniqueUsers,
                        businessChannelsById,
                        customerChannelsById,
                        customer: newArrival.customer,
                        tags: uniqueTags,
                        events: uniqueEvents,
                        assignedUserId: existingRoom.assignedUserId,
                        read: existingRoom.read,
                        unreadCount: existingRoom.unreadCount,
                        status: existingRoom.status,
                        tagsV2: existingRoom.tagsV2,
                        metadata: undefined,
                    };
                }
                else {
                    state.rooms.roomsById[newArrival.roomId] = {
                        ...newArrival,
                        events: newArrival.events,
                    };
                }
            }
            if (newArrival.events.length === 1) {
                switch ((_b = (_a = newArrival.events[0].connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) {
                    case 'eventDeliveryStatus': {
                        const { eventId, deliveryStatus } = newArrival.events[0].connectlyEvent.eventOneof.eventDeliveryStatus;
                        const event = state.messages.byId[eventId];
                        if (event) {
                            switch (deliveryStatus) {
                                case DeliveryStatus.DELIVERY_STATUS_DELIVERED: {
                                    event.deliveredAt = new Date().toISOString();
                                    break;
                                }
                                case DeliveryStatus.DELIVERY_STATUS_FAILED_TO_DELIVER:
                                case DeliveryStatus.DELIVERY_STATUS_FAILURE:
                                case DeliveryStatus.DELIVERY_STATUS_UNSPECIFIED: {
                                    break;
                                }
                                case DeliveryStatus.DELIVERY_STATUS_READ: {
                                    event.readAt = new Date().toISOString();
                                    break;
                                }
                                case DeliveryStatus.DELIVERY_STATUS_SENT: {
                                    event.sentAt = new Date().toISOString();
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                        break;
                    }
                    case 'roomAssignment': {
                        const { roomId, assignedUserId } = newArrival.events[0].connectlyEvent.eventOneof.roomAssignment;
                        if (state.rooms.roomsById[roomId]) {
                            state.rooms.roomsById[roomId].assignedUserId = assignedUserId;
                        }
                        break;
                    }
                    case 'roomStatusChange': {
                        const { roomId, to: status } = newArrival.events[0].connectlyEvent.eventOneof.roomStatusChange;
                        if (state.rooms.roomsById[roomId]) {
                            state.rooms.roomsById[roomId].status = {
                                status,
                                displayReadyStatus: '',
                            };
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        setAgentRoom: (state, action) => {
            const { roomId, agentId } = action.payload;
            const room = state.rooms.roomsById[roomId];
            if (room) {
                room.assignedUserId = agentId;
            }
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
            state.offset = undefined;
        },
        setRoomStatus: (state, action) => {
            const { roomId, status } = action.payload;
            const room = state.rooms.roomsById[roomId];
            if (room) {
                room.status = status;
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRooms.pending, (state, action) => {
            if (state.timestamp === 0) {
                state.status = 'loading';
            }
            state.pageStatus = 'loading';
            state.requestId = action.meta.requestId;
        });
        builder.addCase(fetchRooms.fulfilled, (state, action) => {
            if (state.requestId !== action.meta.requestId) {
                return;
            }
            state.status = 'succeeded';
            state.timestamp += 1;
            const { started, completed, result, prev } = action.payload;
            if (started) {
                state.activeRoomsById = {};
            }
            Object.keys(result.roomsById).forEach((roomId) => {
                const room = result.roomsById[roomId];
                state.activeRoomsById[roomId] = roomId;
                state.rooms.roomsById[roomId] = mergeRoomDto(state.rooms.roomsById[roomId], room);
                room.events.forEach((event) => {
                    state.messages.byId[event.id] = event;
                });
            });
            state.pageStatus = 'succeeded';
            if (completed) {
                state.pageStatus = 'completed';
            }
            state.offset = prev === null || prev === void 0 ? void 0 : prev.offset;
        });
        builder.addCase(fetchRooms.rejected, (state, _action) => {
            state.status = 'failed';
            state.pageStatus = 'failed';
        });
        builder.addCase(fetchRoom.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.timestamp += 1;
            const room = action.payload;
            state.rooms.roomsById[action.meta.arg.roomId] = mergeRoomDto(undefined, room);
            room.events.forEach((event) => {
                state.messages.byId[event.id] = event;
            });
        });
    },
});
export const { newEventArrival, optimisticMessage, reset, roomDtoUpdate, setAgentRoom, setFilter, setRoomStatus } = roomsSlice.actions;
