import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
const ffEnableDebugTypography = getFlagByName('ffEnableDebugTypography');
export const Label = ffEnableDebugTypography
    ? (props) => (_jsxs(Typography, { ...props, children: [props.children, !!props.children && (_jsx(Typography, { variant: "tooltip", sx: {
                    background: (theme) => theme.palette.grey[800],
                    color: (theme) => theme.palette.common.white,
                    verticalAlign: 'super',
                    ml: 0.5,
                    px: 0.5,
                    py: 0.25,
                    borderRadius: 2,
                }, children: props.variant }))] }))
    : Typography;
