import { LoadingState } from '@connectlyai-sdks/loading';
import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
const initialState = {
    loadingState: LoadingState.UNINITIALIZED,
    rooms: {
        data: [],
    },
    selectedRoomId: '',
};
export const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        setRooms: (state, action) => {
            state.rooms = action.payload;
            state.loadingState = LoadingState.DATA;
        },
        setSelectedRoomId: (state, action) => {
            state.selectedRoomId = action.payload;
        },
        startRoomMonitor: (state, _action) => {
            state.loadingState = LoadingState.FULL_LOADING;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
    },
});
export const { setRooms, setSelectedRoomId, startRoomMonitor, reset } = roomsSlice.actions;
export const selectChatRooms = (state) => state.rooms.rooms.data;
export const selectSelectedRoomId = (state) => state.rooms.selectedRoomId;
export const selectLoadingState = (state) => state.rooms.loadingState;
export default roomsSlice.reducer;
