export const FLOW_DOCUMENT_ROUTE = '/flow/:flowDocumentId';
export const FLOW_HOME_ROUTE = '/flow';
export const generateFlowRoute = (flowDocumentId) => ({
    id: FLOW_DOCUMENT_ROUTE,
    params: { flowDocumentId },
});
export const generateFlowHome = () => ({
    id: FLOW_HOME_ROUTE,
    params: {},
});
