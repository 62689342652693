import { uniq } from 'lodash';
import { trimCurly, VARIABLE_REGEX } from '@hooks/useFlowVariables';
import { TemplateComponents } from '@hooks/useMessageTemplateGroupsData/types';
import { DetectedVariable } from './types';

export const mapLocationToMapPrefix = (location: 'body' | 'header' | 'link') => {
  return location === 'body' ? 'variable' : `${location}Variable`;
};

export const mapComponentsToVariables = (components: TemplateComponents) => {
  const detectedVariables: DetectedVariable[] = [];

  const bodyComponent = components.find((x) => x.body);
  if (bodyComponent) {
    const body = bodyComponent.body?.text?.text || '';
    uniq(body.match(VARIABLE_REGEX))?.forEach((variable) => {
      detectedVariables.push({ variable: trimCurly(variable), location: 'body', prefix: 'body_' });
    });
  }

  const headerComponent = components.find((x) => x.header);
  if (headerComponent) {
    const header = headerComponent.header?.text?.text || '';
    uniq(header.match(VARIABLE_REGEX))?.forEach((variable) => {
      detectedVariables.push({
        variable: trimCurly(variable),
        location: 'header',
        prefix: 'header_text.',
      });
    });
  }

  const buttonComponents = components.filter((x) => x.button);
  buttonComponents.forEach((buttonComponent, buttonIndex) => {
    if (!buttonComponent.button?.url) return;
    const link = buttonComponent.button?.url?.trackedUrl || buttonComponent.button?.url?.url || '';
    uniq(link.match(VARIABLE_REGEX))?.forEach((variable) => {
      detectedVariables.push({
        variable: trimCurly(variable),
        location: 'link',
        prefix: `button_${buttonIndex + 1}_url_suffix.`,
      });
    });
  });

  return detectedVariables;
};
