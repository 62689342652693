import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Position, Handle, useStore, ReactFlowState } from 'react-flow-renderer';
import { useTheme, Box, Label } from '@connectlyai-tenets/ui-styled-web';
import { createHandleId, HandleType } from '../../sdks/flow/createHandleId';
import { FlowContext } from '../../contexts';

const useTargetHandle = (handleType: HandleType) => {
  const { nodeId } = handleType;
  const handleId = createHandleId(handleType);

  const connectionNodeId = useStore((state: ReactFlowState) => state.connectionNodeId);
  const isNodePotentialTarget = useContextSelector(FlowContext, (context) => context.isNodePotentialTarget);

  const isPotentialTarget = useMemo(
    () => isNodePotentialTarget(connectionNodeId, nodeId),
    [isNodePotentialTarget, connectionNodeId, nodeId],
  );

  return { handleId, isPotentialTarget };
};

export const TargetHandle = (handleType: HandleType) => {
  const { handleId, isPotentialTarget } = useTargetHandle(handleType);

  const theme = useTheme();

  if (!isPotentialTarget)
    return (
      <Handle
        type="target"
        position={Position.Left}
        id={handleId}
        style={{
          opacity: 0,
        }}
      />
    );

  return (
    <Handle
      type="target"
      position={Position.Left}
      id={handleId}
      style={{
        background: theme.palette.connectionLine,
        borderRadius: 'inherit',
        height: '100%',
        left: -1,
        opacity: 0.85,
        position: 'absolute',
        width: '100%',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          color: theme.palette.common.white,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          left: -1,
          padding: 3,
          pointerEvents: 'none',
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
          zIndex: 2,
        }}
      >
        <Label variant="h6">Drop here</Label>
      </Box>
    </Handle>
  );
};
