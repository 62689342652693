import { AppState } from '../store';

export const selectCategory = (state: AppState) => state.flowDocument.category;
export const selectAiConversation = (state: AppState) => state.flow.aiConversation;
export const selectAiState = (state: AppState) => state.flow.aiState;
export const selectAudience = (state: AppState) => state.flow.audience;
export const selectCampaignDate = (state: AppState) => state.flow.campaignDate;
export const selectCampaignName = (state: AppState) => state.flow.campaignName;
export const selectCampaignTemplate = (state: AppState) => state.flow.campaignTemplate;
export const selectChangesQueue = (state: AppState) => state.flowDocument.changesQueue;
export const selectDocumentId = (state: AppState) => state.flow.documentId;
export const selectDocumentVerificationId = (state: AppState) => state.flow.documentVerificationId;
export const selectDocumentVerificationResult = (state: AppState) => state.flow.documentVerificationResult;
export const selectEdges = (state: AppState) => state.flowDocument.edges;
export const selectFetchCampaignSendNode = (state: AppState) => state.flow.fetchCampaignSendNode;
export const selectFlowState = (state: AppState) => state.flow.flowState;
export const selectHasUnsavedChanges = (state: AppState) => state.flowDocument.hasUnsavedChanges;
export const selectIsAIGenerating = (state: AppState) => state.flow.aiState === 'generating';
export const selectIsAISidebarOpen = (state: AppState) => state.flow.isAISidebarOpen;
export const selectIsCampaignNameDialogOpen = (state: AppState) => state.flow.isCampaignNameDialogOpen;
export const selectIsEdgeAttached = (state: AppState) => state.flow.isEdgeAttached;
export const selectIsSpreadsheetOpen = (state: AppState) => state.flow.isSpreadsheetOpen;
export const selectLanguage = (state: AppState) => state.flowDocument.language;
export const selectMovingNodeIds = (state: AppState) => state.flowDocument.movingNodeIds;
export const selectNodes = (state: AppState) => state.flowDocument.nodes;
export const selectOnlineIds = (state: AppState) => state.flowDocument.onlineIds;
export const selectRevisionId = (state: AppState) => state.flowDocument.revisionId;
export const selectPreviewDocumentId = (state: AppState) => state.flow.previewDocumentId;
export const selectSavedCampaignName = (state: AppState) => state.flow.savedCampaignName;
export const selectStarter = (state: AppState) => state.flow.starter;
export const selectSubmitError = (state: AppState) => state.flow.submitError;

export const selectCampaignSendNode = (state: AppState) =>
  state.flowDocument.nodes.find((node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN');

export const selectSendoutId = (state: AppState) =>
  state.flowDocument.nodes.find((node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN')?.data?.v1?.sendoutId;

export const selectSelectedNode = (state: AppState) => state.flowDocument.nodes.find((node) => node.selected);

export const selectIsDocumentEditable = (state: AppState) =>
  state.flow.flowState === 'buildCampaign' && state.flow.aiState !== 'generating';

export const selectCampaignFlow = (state: AppState) => ({
  audienceListBuilder: state.audienceListBuilder,
  flow: state.flow,
  flowDocument: state.flowDocument,
  messageTemplates: state.messageTemplates,
});
