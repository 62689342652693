import { WhatsAppLocation, Section_Row, Action_ButtonReply, messageTypeFromJSON, messageTypeToJSON, } from "../../../graphapi/v12_0/whatsapp/models";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
import { StringValue, BoolValue, Int64Value, DoubleValue, } from "../../../../../google/protobuf/wrappers";
import { Struct } from "../../../../../google/protobuf/struct";
export const protobufPackage = "events.facebook.webhook.v12_0.whatsapp";
export var WebhookStatus;
(function (WebhookStatus) {
    WebhookStatus[WebhookStatus["unspecified"] = 0] = "unspecified";
    WebhookStatus[WebhookStatus["sent"] = 1] = "sent";
    WebhookStatus[WebhookStatus["delivered"] = 2] = "delivered";
    WebhookStatus[WebhookStatus["read"] = 3] = "read";
    WebhookStatus[WebhookStatus["failed"] = 4] = "failed";
    WebhookStatus[WebhookStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WebhookStatus || (WebhookStatus = {}));
export function webhookStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return WebhookStatus.unspecified;
        case 1:
        case "sent":
            return WebhookStatus.sent;
        case 2:
        case "delivered":
            return WebhookStatus.delivered;
        case 3:
        case "read":
            return WebhookStatus.read;
        case 4:
        case "failed":
            return WebhookStatus.failed;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WebhookStatus.UNRECOGNIZED;
    }
}
export function webhookStatusToJSON(object) {
    switch (object) {
        case WebhookStatus.unspecified:
            return "unspecified";
        case WebhookStatus.sent:
            return "sent";
        case WebhookStatus.delivered:
            return "delivered";
        case WebhookStatus.read:
            return "read";
        case WebhookStatus.failed:
            return "failed";
        case WebhookStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var ConversationOriginTypeWrapper_ConversationOriginType;
(function (ConversationOriginTypeWrapper_ConversationOriginType) {
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["unspecified"] = 0] = "unspecified";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["business_initiated"] = 1] = "business_initiated";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["user_initiated"] = 2] = "user_initiated";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["referral_conversion"] = 3] = "referral_conversion";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["authentication"] = 4] = "authentication";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["marketing"] = 5] = "marketing";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["utility"] = 6] = "utility";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["service"] = 7] = "service";
    ConversationOriginTypeWrapper_ConversationOriginType[ConversationOriginTypeWrapper_ConversationOriginType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ConversationOriginTypeWrapper_ConversationOriginType || (ConversationOriginTypeWrapper_ConversationOriginType = {}));
export function conversationOriginTypeWrapper_ConversationOriginTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return ConversationOriginTypeWrapper_ConversationOriginType.unspecified;
        case 1:
        case "business_initiated":
            return ConversationOriginTypeWrapper_ConversationOriginType.business_initiated;
        case 2:
        case "user_initiated":
            return ConversationOriginTypeWrapper_ConversationOriginType.user_initiated;
        case 3:
        case "referral_conversion":
            return ConversationOriginTypeWrapper_ConversationOriginType.referral_conversion;
        case 4:
        case "authentication":
            return ConversationOriginTypeWrapper_ConversationOriginType.authentication;
        case 5:
        case "marketing":
            return ConversationOriginTypeWrapper_ConversationOriginType.marketing;
        case 6:
        case "utility":
            return ConversationOriginTypeWrapper_ConversationOriginType.utility;
        case 7:
        case "service":
            return ConversationOriginTypeWrapper_ConversationOriginType.service;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ConversationOriginTypeWrapper_ConversationOriginType.UNRECOGNIZED;
    }
}
export function conversationOriginTypeWrapper_ConversationOriginTypeToJSON(object) {
    switch (object) {
        case ConversationOriginTypeWrapper_ConversationOriginType.unspecified:
            return "unspecified";
        case ConversationOriginTypeWrapper_ConversationOriginType.business_initiated:
            return "business_initiated";
        case ConversationOriginTypeWrapper_ConversationOriginType.user_initiated:
            return "user_initiated";
        case ConversationOriginTypeWrapper_ConversationOriginType.referral_conversion:
            return "referral_conversion";
        case ConversationOriginTypeWrapper_ConversationOriginType.authentication:
            return "authentication";
        case ConversationOriginTypeWrapper_ConversationOriginType.marketing:
            return "marketing";
        case ConversationOriginTypeWrapper_ConversationOriginType.utility:
            return "utility";
        case ConversationOriginTypeWrapper_ConversationOriginType.service:
            return "service";
        case ConversationOriginTypeWrapper_ConversationOriginType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var ConversationPricingCategoryWrapper_ConversationPricingCategory;
(function (ConversationPricingCategoryWrapper_ConversationPricingCategory) {
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["unspecified"] = 0] = "unspecified";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["business_initiated"] = 1] = "business_initiated";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["user_initiated"] = 2] = "user_initiated";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["referral_conversion"] = 3] = "referral_conversion";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["authentication"] = 4] = "authentication";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["marketing"] = 5] = "marketing";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["utility"] = 6] = "utility";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["service"] = 7] = "service";
    ConversationPricingCategoryWrapper_ConversationPricingCategory[ConversationPricingCategoryWrapper_ConversationPricingCategory["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ConversationPricingCategoryWrapper_ConversationPricingCategory || (ConversationPricingCategoryWrapper_ConversationPricingCategory = {}));
export function conversationPricingCategoryWrapper_ConversationPricingCategoryFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.unspecified;
        case 1:
        case "business_initiated":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.business_initiated;
        case 2:
        case "user_initiated":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.user_initiated;
        case 3:
        case "referral_conversion":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.referral_conversion;
        case 4:
        case "authentication":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.authentication;
        case 5:
        case "marketing":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.marketing;
        case 6:
        case "utility":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.utility;
        case 7:
        case "service":
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.service;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ConversationPricingCategoryWrapper_ConversationPricingCategory.UNRECOGNIZED;
    }
}
export function conversationPricingCategoryWrapper_ConversationPricingCategoryToJSON(object) {
    switch (object) {
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.unspecified:
            return "unspecified";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.business_initiated:
            return "business_initiated";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.user_initiated:
            return "user_initiated";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.referral_conversion:
            return "referral_conversion";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.authentication:
            return "authentication";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.marketing:
            return "marketing";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.utility:
            return "utility";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.service:
            return "service";
        case ConversationPricingCategoryWrapper_ConversationPricingCategory.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValueMessageTemplateStatusUpdate_Event;
(function (WhatsAppWebhookValueMessageTemplateStatusUpdate_Event) {
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["Event_unspecified"] = 0] = "Event_unspecified";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["APPROVED"] = 1] = "APPROVED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["IN_APPEAL"] = 2] = "IN_APPEAL";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["PENDING"] = 3] = "PENDING";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["REJECTED"] = 4] = "REJECTED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["PENDING_DELETION"] = 5] = "PENDING_DELETION";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["DELETED"] = 6] = "DELETED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["DISABLED"] = 7] = "DISABLED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["FLAGGED"] = 8] = "FLAGGED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["REINSTATED"] = 9] = "REINSTATED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["PAUSED"] = 10] = "PAUSED";
    WhatsAppWebhookValueMessageTemplateStatusUpdate_Event[WhatsAppWebhookValueMessageTemplateStatusUpdate_Event["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValueMessageTemplateStatusUpdate_Event || (WhatsAppWebhookValueMessageTemplateStatusUpdate_Event = {}));
export function whatsAppWebhookValueMessageTemplateStatusUpdate_EventFromJSON(object) {
    switch (object) {
        case 0:
        case "Event_unspecified":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.Event_unspecified;
        case 1:
        case "APPROVED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.APPROVED;
        case 2:
        case "IN_APPEAL":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.IN_APPEAL;
        case 3:
        case "PENDING":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PENDING;
        case 4:
        case "REJECTED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.REJECTED;
        case 5:
        case "PENDING_DELETION":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PENDING_DELETION;
        case 6:
        case "DELETED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.DELETED;
        case 7:
        case "DISABLED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.DISABLED;
        case 8:
        case "FLAGGED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.FLAGGED;
        case 9:
        case "REINSTATED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.REINSTATED;
        case 10:
        case "PAUSED":
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PAUSED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValueMessageTemplateStatusUpdate_EventToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.Event_unspecified:
            return "Event_unspecified";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.APPROVED:
            return "APPROVED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.IN_APPEAL:
            return "IN_APPEAL";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PENDING:
            return "PENDING";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.REJECTED:
            return "REJECTED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PENDING_DELETION:
            return "PENDING_DELETION";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.DELETED:
            return "DELETED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.DISABLED:
            return "DISABLED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.FLAGGED:
            return "FLAGGED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.REINSTATED:
            return "REINSTATED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.PAUSED:
            return "PAUSED";
        case WhatsAppWebhookValueMessageTemplateStatusUpdate_Event.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState;
(function (WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState) {
    WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState[WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState["unspecified"] = 0] = "unspecified";
    WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState[WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState["SCHEDULE_FOR_DISABLE"] = 1] = "SCHEDULE_FOR_DISABLE";
    WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState[WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState["DISABLE"] = 2] = "DISABLE";
    WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState[WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState["REINSTATE"] = 3] = "REINSTATE";
    WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState[WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState || (WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState = {}));
export function whatsAppWebhookValueAccountUpdateBanInfo_WabaBanStateFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.unspecified;
        case 1:
        case "SCHEDULE_FOR_DISABLE":
            return WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.SCHEDULE_FOR_DISABLE;
        case 2:
        case "DISABLE":
            return WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.DISABLE;
        case 3:
        case "REINSTATE":
            return WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.REINSTATE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValueAccountUpdateBanInfo_WabaBanStateToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.unspecified:
            return "unspecified";
        case WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.SCHEDULE_FOR_DISABLE:
            return "SCHEDULE_FOR_DISABLE";
        case WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.DISABLE:
            return "DISABLE";
        case WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.REINSTATE:
            return "REINSTATE";
        case WhatsAppWebhookValueAccountUpdateBanInfo_WabaBanState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValueAccountUpdate_Event;
(function (WhatsAppWebhookValueAccountUpdate_Event) {
    WhatsAppWebhookValueAccountUpdate_Event[WhatsAppWebhookValueAccountUpdate_Event["Event_unspecified"] = 0] = "Event_unspecified";
    WhatsAppWebhookValueAccountUpdate_Event[WhatsAppWebhookValueAccountUpdate_Event["DISABLED_UPDATE"] = 1] = "DISABLED_UPDATE";
    WhatsAppWebhookValueAccountUpdate_Event[WhatsAppWebhookValueAccountUpdate_Event["VERIFIED_ACCOUNT"] = 2] = "VERIFIED_ACCOUNT";
    WhatsAppWebhookValueAccountUpdate_Event[WhatsAppWebhookValueAccountUpdate_Event["ACCOUNT_RESTRICTION"] = 3] = "ACCOUNT_RESTRICTION";
    WhatsAppWebhookValueAccountUpdate_Event[WhatsAppWebhookValueAccountUpdate_Event["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValueAccountUpdate_Event || (WhatsAppWebhookValueAccountUpdate_Event = {}));
export function whatsAppWebhookValueAccountUpdate_EventFromJSON(object) {
    switch (object) {
        case 0:
        case "Event_unspecified":
            return WhatsAppWebhookValueAccountUpdate_Event.Event_unspecified;
        case 1:
        case "DISABLED_UPDATE":
            return WhatsAppWebhookValueAccountUpdate_Event.DISABLED_UPDATE;
        case 2:
        case "VERIFIED_ACCOUNT":
            return WhatsAppWebhookValueAccountUpdate_Event.VERIFIED_ACCOUNT;
        case 3:
        case "ACCOUNT_RESTRICTION":
            return WhatsAppWebhookValueAccountUpdate_Event.ACCOUNT_RESTRICTION;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValueAccountUpdate_Event.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValueAccountUpdate_EventToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValueAccountUpdate_Event.Event_unspecified:
            return "Event_unspecified";
        case WhatsAppWebhookValueAccountUpdate_Event.DISABLED_UPDATE:
            return "DISABLED_UPDATE";
        case WhatsAppWebhookValueAccountUpdate_Event.VERIFIED_ACCOUNT:
            return "VERIFIED_ACCOUNT";
        case WhatsAppWebhookValueAccountUpdate_Event.ACCOUNT_RESTRICTION:
            return "ACCOUNT_RESTRICTION";
        case WhatsAppWebhookValueAccountUpdate_Event.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValueAccountUpdate_RestrictionType;
(function (WhatsAppWebhookValueAccountUpdate_RestrictionType) {
    WhatsAppWebhookValueAccountUpdate_RestrictionType[WhatsAppWebhookValueAccountUpdate_RestrictionType["RestrictionType_unspecified"] = 0] = "RestrictionType_unspecified";
    WhatsAppWebhookValueAccountUpdate_RestrictionType[WhatsAppWebhookValueAccountUpdate_RestrictionType["RESTRICTED_ADD_PHONE_NUMBER_ACTION"] = 1] = "RESTRICTED_ADD_PHONE_NUMBER_ACTION";
    WhatsAppWebhookValueAccountUpdate_RestrictionType[WhatsAppWebhookValueAccountUpdate_RestrictionType["RESTRICTED_BIZ_INITIATED_MESSAGING"] = 2] = "RESTRICTED_BIZ_INITIATED_MESSAGING";
    WhatsAppWebhookValueAccountUpdate_RestrictionType[WhatsAppWebhookValueAccountUpdate_RestrictionType["RESTRICTED_CUSTOMER_INITIATED_MESSAGING"] = 3] = "RESTRICTED_CUSTOMER_INITIATED_MESSAGING";
    WhatsAppWebhookValueAccountUpdate_RestrictionType[WhatsAppWebhookValueAccountUpdate_RestrictionType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValueAccountUpdate_RestrictionType || (WhatsAppWebhookValueAccountUpdate_RestrictionType = {}));
export function whatsAppWebhookValueAccountUpdate_RestrictionTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "RestrictionType_unspecified":
            return WhatsAppWebhookValueAccountUpdate_RestrictionType.RestrictionType_unspecified;
        case 1:
        case "RESTRICTED_ADD_PHONE_NUMBER_ACTION":
            return WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_ADD_PHONE_NUMBER_ACTION;
        case 2:
        case "RESTRICTED_BIZ_INITIATED_MESSAGING":
            return WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_BIZ_INITIATED_MESSAGING;
        case 3:
        case "RESTRICTED_CUSTOMER_INITIATED_MESSAGING":
            return WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_CUSTOMER_INITIATED_MESSAGING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValueAccountUpdate_RestrictionType.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValueAccountUpdate_RestrictionTypeToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValueAccountUpdate_RestrictionType.RestrictionType_unspecified:
            return "RestrictionType_unspecified";
        case WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_ADD_PHONE_NUMBER_ACTION:
            return "RESTRICTED_ADD_PHONE_NUMBER_ACTION";
        case WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_BIZ_INITIATED_MESSAGING:
            return "RESTRICTED_BIZ_INITIATED_MESSAGING";
        case WhatsAppWebhookValueAccountUpdate_RestrictionType.RESTRICTED_CUSTOMER_INITIATED_MESSAGING:
            return "RESTRICTED_CUSTOMER_INITIATED_MESSAGING";
        case WhatsAppWebhookValueAccountUpdate_RestrictionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValuePhoneNumberNameUpdate_Decision;
(function (WhatsAppWebhookValuePhoneNumberNameUpdate_Decision) {
    WhatsAppWebhookValuePhoneNumberNameUpdate_Decision[WhatsAppWebhookValuePhoneNumberNameUpdate_Decision["Decision_unspecified"] = 0] = "Decision_unspecified";
    WhatsAppWebhookValuePhoneNumberNameUpdate_Decision[WhatsAppWebhookValuePhoneNumberNameUpdate_Decision["APPROVED"] = 1] = "APPROVED";
    WhatsAppWebhookValuePhoneNumberNameUpdate_Decision[WhatsAppWebhookValuePhoneNumberNameUpdate_Decision["REJECTED"] = 2] = "REJECTED";
    WhatsAppWebhookValuePhoneNumberNameUpdate_Decision[WhatsAppWebhookValuePhoneNumberNameUpdate_Decision["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValuePhoneNumberNameUpdate_Decision || (WhatsAppWebhookValuePhoneNumberNameUpdate_Decision = {}));
export function whatsAppWebhookValuePhoneNumberNameUpdate_DecisionFromJSON(object) {
    switch (object) {
        case 0:
        case "Decision_unspecified":
            return WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.Decision_unspecified;
        case 1:
        case "APPROVED":
            return WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.APPROVED;
        case 2:
        case "REJECTED":
            return WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.REJECTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValuePhoneNumberNameUpdate_DecisionToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.Decision_unspecified:
            return "Decision_unspecified";
        case WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.APPROVED:
            return "APPROVED";
        case WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.REJECTED:
            return "REJECTED";
        case WhatsAppWebhookValuePhoneNumberNameUpdate_Decision.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit;
(function (WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit) {
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["CurrentLimit_unspecified"] = 0] = "CurrentLimit_unspecified";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["TIER_1K"] = 1] = "TIER_1K";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["TIER_10K"] = 2] = "TIER_10K";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["TIER_100K"] = 3] = "TIER_100K";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["TIER_UNLIMITED"] = 4] = "TIER_UNLIMITED";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["TIER_NOT_SET"] = 5] = "TIER_NOT_SET";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit[WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit || (WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit = {}));
export function whatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimitFromJSON(object) {
    switch (object) {
        case 0:
        case "CurrentLimit_unspecified":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.CurrentLimit_unspecified;
        case 1:
        case "TIER_1K":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_1K;
        case 2:
        case "TIER_10K":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_10K;
        case 3:
        case "TIER_100K":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_100K;
        case 4:
        case "TIER_UNLIMITED":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_UNLIMITED;
        case 5:
        case "TIER_NOT_SET":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_NOT_SET;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimitToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.CurrentLimit_unspecified:
            return "CurrentLimit_unspecified";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_1K:
            return "TIER_1K";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_10K:
            return "TIER_10K";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_100K:
            return "TIER_100K";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_UNLIMITED:
            return "TIER_UNLIMITED";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.TIER_NOT_SET:
            return "TIER_NOT_SET";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimit.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookValuePhoneNumberQualityUpdate_Event;
(function (WhatsAppWebhookValuePhoneNumberQualityUpdate_Event) {
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["Event_unspecified"] = 0] = "Event_unspecified";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["ONBOARDING"] = 1] = "ONBOARDING";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["UPGRADE"] = 2] = "UPGRADE";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["DOWNGRADE"] = 3] = "DOWNGRADE";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["FLAGGED"] = 4] = "FLAGGED";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["UNFLAGGED"] = 5] = "UNFLAGGED";
    WhatsAppWebhookValuePhoneNumberQualityUpdate_Event[WhatsAppWebhookValuePhoneNumberQualityUpdate_Event["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookValuePhoneNumberQualityUpdate_Event || (WhatsAppWebhookValuePhoneNumberQualityUpdate_Event = {}));
export function whatsAppWebhookValuePhoneNumberQualityUpdate_EventFromJSON(object) {
    switch (object) {
        case 0:
        case "Event_unspecified":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.Event_unspecified;
        case 1:
        case "ONBOARDING":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.ONBOARDING;
        case 2:
        case "UPGRADE":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UPGRADE;
        case 3:
        case "DOWNGRADE":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.DOWNGRADE;
        case 4:
        case "FLAGGED":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.FLAGGED;
        case 5:
        case "UNFLAGGED":
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UNFLAGGED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UNRECOGNIZED;
    }
}
export function whatsAppWebhookValuePhoneNumberQualityUpdate_EventToJSON(object) {
    switch (object) {
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.Event_unspecified:
            return "Event_unspecified";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.ONBOARDING:
            return "ONBOARDING";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UPGRADE:
            return "UPGRADE";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.DOWNGRADE:
            return "DOWNGRADE";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.FLAGGED:
            return "FLAGGED";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UNFLAGGED:
            return "UNFLAGGED";
        case WhatsAppWebhookValuePhoneNumberQualityUpdate_Event.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppWebhookInteractive_Addendum_Type;
(function (WhatsAppWebhookInteractive_Addendum_Type) {
    WhatsAppWebhookInteractive_Addendum_Type[WhatsAppWebhookInteractive_Addendum_Type["unspecified"] = 0] = "unspecified";
    WhatsAppWebhookInteractive_Addendum_Type[WhatsAppWebhookInteractive_Addendum_Type["list_reply"] = 1] = "list_reply";
    WhatsAppWebhookInteractive_Addendum_Type[WhatsAppWebhookInteractive_Addendum_Type["button_reply"] = 2] = "button_reply";
    WhatsAppWebhookInteractive_Addendum_Type[WhatsAppWebhookInteractive_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppWebhookInteractive_Addendum_Type || (WhatsAppWebhookInteractive_Addendum_Type = {}));
export function whatsAppWebhookInteractive_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return WhatsAppWebhookInteractive_Addendum_Type.unspecified;
        case 1:
        case "list_reply":
            return WhatsAppWebhookInteractive_Addendum_Type.list_reply;
        case 2:
        case "button_reply":
            return WhatsAppWebhookInteractive_Addendum_Type.button_reply;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppWebhookInteractive_Addendum_Type.UNRECOGNIZED;
    }
}
export function whatsAppWebhookInteractive_Addendum_TypeToJSON(object) {
    switch (object) {
        case WhatsAppWebhookInteractive_Addendum_Type.unspecified:
            return "unspecified";
        case WhatsAppWebhookInteractive_Addendum_Type.list_reply:
            return "list_reply";
        case WhatsAppWebhookInteractive_Addendum_Type.button_reply:
            return "button_reply";
        case WhatsAppWebhookInteractive_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseWhatsAppContactProfile() {
    return { name: undefined };
}
export const WhatsAppContactProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppContactProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseWhatsAppWebhookContacts() {
    return { profile: undefined, waId: undefined };
}
export const WhatsAppWebhookContacts = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.profile !== undefined) {
            WhatsAppContactProfile.encode(message.profile, writer.uint32(10).fork()).ldelim();
        }
        if (message.waId !== undefined) {
            StringValue.encode({ value: message.waId }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookContacts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.profile = WhatsAppContactProfile.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.waId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            profile: isSet(object.profile)
                ? WhatsAppContactProfile.fromJSON(object.profile)
                : undefined,
            waId: isSet(object.waId) ? String(object.waId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.profile !== undefined &&
            (obj.profile = message.profile
                ? WhatsAppContactProfile.toJSON(message.profile)
                : undefined);
        message.waId !== undefined && (obj.waId = message.waId);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueMessagesMetadata() {
    return { displayPhoneNumber: undefined, phoneNumberId: undefined };
}
export const WhatsAppWebhookValueMessagesMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.displayPhoneNumber !== undefined) {
            StringValue.encode({ value: message.displayPhoneNumber }, writer.uint32(10).fork()).ldelim();
        }
        if (message.phoneNumberId !== undefined) {
            StringValue.encode({ value: message.phoneNumberId }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueMessagesMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.displayPhoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.phoneNumberId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            displayPhoneNumber: isSet(object.displayPhoneNumber)
                ? String(object.displayPhoneNumber)
                : undefined,
            phoneNumberId: isSet(object.phoneNumberId)
                ? String(object.phoneNumberId)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.displayPhoneNumber !== undefined &&
            (obj.displayPhoneNumber = message.displayPhoneNumber);
        message.phoneNumberId !== undefined &&
            (obj.phoneNumberId = message.phoneNumberId);
        return obj;
    },
};
function createBaseWhatsAppWebhookText() {
    return { body: undefined };
}
export const WhatsAppWebhookText = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookText();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            body: isSet(object.body) ? String(object.body) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function createBaseWhatsAppWebhookMedia() {
    return {
        caption: undefined,
        mimeType: undefined,
        sha256: undefined,
        id: undefined,
        filename: undefined,
    };
}
export const WhatsAppWebhookMedia = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.caption !== undefined) {
            StringValue.encode({ value: message.caption }, writer.uint32(10).fork()).ldelim();
        }
        if (message.mimeType !== undefined) {
            StringValue.encode({ value: message.mimeType }, writer.uint32(18).fork()).ldelim();
        }
        if (message.sha256 !== undefined) {
            StringValue.encode({ value: message.sha256 }, writer.uint32(26).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(34).fork()).ldelim();
        }
        if (message.filename !== undefined) {
            StringValue.encode({ value: message.filename }, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookMedia();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.caption = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.mimeType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.sha256 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.filename = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            caption: isSet(object.caption) ? String(object.caption) : undefined,
            mimeType: isSet(object.mimeType) ? String(object.mimeType) : undefined,
            sha256: isSet(object.sha256) ? String(object.sha256) : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
            filename: isSet(object.filename) ? String(object.filename) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.caption !== undefined && (obj.caption = message.caption);
        message.mimeType !== undefined && (obj.mimeType = message.mimeType);
        message.sha256 !== undefined && (obj.sha256 = message.sha256);
        message.id !== undefined && (obj.id = message.id);
        message.filename !== undefined && (obj.filename = message.filename);
        return obj;
    },
};
function createBaseWhatsAppWebhookSticker() {
    return {
        mimeType: undefined,
        sha256: undefined,
        id: undefined,
        animated: undefined,
    };
}
export const WhatsAppWebhookSticker = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.mimeType !== undefined) {
            StringValue.encode({ value: message.mimeType }, writer.uint32(10).fork()).ldelim();
        }
        if (message.sha256 !== undefined) {
            StringValue.encode({ value: message.sha256 }, writer.uint32(18).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(26).fork()).ldelim();
        }
        if (message.animated !== undefined) {
            BoolValue.encode({ value: message.animated }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookSticker();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.mimeType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.sha256 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.animated = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            mimeType: isSet(object.mimeType) ? String(object.mimeType) : undefined,
            sha256: isSet(object.sha256) ? String(object.sha256) : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
            animated: isSet(object.animated) ? Boolean(object.animated) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.mimeType !== undefined && (obj.mimeType = message.mimeType);
        message.sha256 !== undefined && (obj.sha256 = message.sha256);
        message.id !== undefined && (obj.id = message.id);
        message.animated !== undefined && (obj.animated = message.animated);
        return obj;
    },
};
function createBaseWhatsAppWebhookButton() {
    return { text: undefined, payload: undefined };
}
export const WhatsAppWebhookButton = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            StringValue.encode({ value: message.text }, writer.uint32(10).fork()).ldelim();
        }
        if (message.payload !== undefined) {
            StringValue.encode({ value: message.payload }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookButton();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.payload = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : undefined,
            payload: isSet(object.payload) ? String(object.payload) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
function createBaseWhatsAppWebhookContext() {
    return { from: undefined, id: undefined };
}
export const WhatsAppWebhookContext = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.from !== undefined) {
            StringValue.encode({ value: message.from }, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookContext();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.from = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            from: isSet(object.from) ? String(object.from) : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.from !== undefined && (obj.from = message.from);
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppWebhookIdentity() {
    return {
        acknowledgement: undefined,
        createdTimestamp: undefined,
        hash: undefined,
    };
}
export const WhatsAppWebhookIdentity = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.acknowledgement !== undefined) {
            BoolValue.encode({ value: message.acknowledgement }, writer.uint32(10).fork()).ldelim();
        }
        if (message.createdTimestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.createdTimestamp), writer.uint32(18).fork()).ldelim();
        }
        if (message.hash !== undefined) {
            StringValue.encode({ value: message.hash }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookIdentity();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.acknowledgement = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.createdTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.hash = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            acknowledgement: isSet(object.acknowledgement)
                ? Boolean(object.acknowledgement)
                : undefined,
            createdTimestamp: isSet(object.createdTimestamp)
                ? String(object.createdTimestamp)
                : undefined,
            hash: isSet(object.hash) ? String(object.hash) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.acknowledgement !== undefined &&
            (obj.acknowledgement = message.acknowledgement);
        message.createdTimestamp !== undefined &&
            (obj.createdTimestamp = message.createdTimestamp);
        message.hash !== undefined && (obj.hash = message.hash);
        return obj;
    },
};
function createBaseWhatsAppWebhookError() {
    return { code: 0, details: undefined, title: undefined };
}
export const WhatsAppWebhookError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).uint32(message.code);
        }
        if (message.details !== undefined) {
            StringValue.encode({ value: message.details }, writer.uint32(18).fork()).ldelim();
        }
        if (message.title !== undefined) {
            StringValue.encode({ value: message.title }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 2:
                    message.details = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.title = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            details: isSet(object.details) ? String(object.details) : undefined,
            title: isSet(object.title) ? String(object.title) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.details !== undefined && (obj.details = message.details);
        message.title !== undefined && (obj.title = message.title);
        return obj;
    },
};
function createBaseWhatsAppWebhookSystem() {
    return { body: undefined, newWaId: undefined, type: undefined };
}
export const WhatsAppWebhookSystem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(10).fork()).ldelim();
        }
        if (message.newWaId !== undefined) {
            StringValue.encode({ value: message.newWaId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.type !== undefined) {
            StringValue.encode({ value: message.type }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookSystem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.newWaId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.type = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            body: isSet(object.body) ? String(object.body) : undefined,
            newWaId: isSet(object.newWaId) ? String(object.newWaId) : undefined,
            type: isSet(object.type) ? String(object.type) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.body !== undefined && (obj.body = message.body);
        message.newWaId !== undefined && (obj.newWaId = message.newWaId);
        message.type !== undefined && (obj.type = message.type);
        return obj;
    },
};
function createBaseWhatsAppWebhookReaction() {
    return { messageId: undefined, emoji: undefined };
}
export const WhatsAppWebhookReaction = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messageId !== undefined) {
            StringValue.encode({ value: message.messageId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.emoji !== undefined) {
            StringValue.encode({ value: message.emoji }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookReaction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.emoji = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messageId: isSet(object.messageId) ? String(object.messageId) : undefined,
            emoji: isSet(object.emoji) ? String(object.emoji) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messageId !== undefined && (obj.messageId = message.messageId);
        message.emoji !== undefined && (obj.emoji = message.emoji);
        return obj;
    },
};
function createBaseWhatsAppWebhookReferral() {
    return {
        sourceUrl: undefined,
        sourceType: undefined,
        sourceId: undefined,
        headline: undefined,
        mediaType: undefined,
        imageUrl: undefined,
        videoUrl: undefined,
        thumbnailUrl: undefined,
        body: undefined,
    };
}
export const WhatsAppWebhookReferral = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sourceUrl !== undefined) {
            StringValue.encode({ value: message.sourceUrl }, writer.uint32(10).fork()).ldelim();
        }
        if (message.sourceType !== undefined) {
            StringValue.encode({ value: message.sourceType }, writer.uint32(18).fork()).ldelim();
        }
        if (message.sourceId !== undefined) {
            StringValue.encode({ value: message.sourceId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.headline !== undefined) {
            StringValue.encode({ value: message.headline }, writer.uint32(34).fork()).ldelim();
        }
        if (message.mediaType !== undefined) {
            StringValue.encode({ value: message.mediaType }, writer.uint32(42).fork()).ldelim();
        }
        if (message.imageUrl !== undefined) {
            StringValue.encode({ value: message.imageUrl }, writer.uint32(50).fork()).ldelim();
        }
        if (message.videoUrl !== undefined) {
            StringValue.encode({ value: message.videoUrl }, writer.uint32(58).fork()).ldelim();
        }
        if (message.thumbnailUrl !== undefined) {
            StringValue.encode({ value: message.thumbnailUrl }, writer.uint32(66).fork()).ldelim();
        }
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookReferral();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sourceUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.sourceType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.sourceId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.headline = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.mediaType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.imageUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.videoUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.thumbnailUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sourceUrl: isSet(object.sourceUrl) ? String(object.sourceUrl) : undefined,
            sourceType: isSet(object.sourceType)
                ? String(object.sourceType)
                : undefined,
            sourceId: isSet(object.sourceId) ? String(object.sourceId) : undefined,
            headline: isSet(object.headline) ? String(object.headline) : undefined,
            mediaType: isSet(object.mediaType) ? String(object.mediaType) : undefined,
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : undefined,
            videoUrl: isSet(object.videoUrl) ? String(object.videoUrl) : undefined,
            thumbnailUrl: isSet(object.thumbnailUrl)
                ? String(object.thumbnailUrl)
                : undefined,
            body: isSet(object.body) ? String(object.body) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sourceUrl !== undefined && (obj.sourceUrl = message.sourceUrl);
        message.sourceType !== undefined && (obj.sourceType = message.sourceType);
        message.sourceId !== undefined && (obj.sourceId = message.sourceId);
        message.headline !== undefined && (obj.headline = message.headline);
        message.mediaType !== undefined && (obj.mediaType = message.mediaType);
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        message.videoUrl !== undefined && (obj.videoUrl = message.videoUrl);
        message.thumbnailUrl !== undefined &&
            (obj.thumbnailUrl = message.thumbnailUrl);
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function createBaseWhatsAppWebhookStatusError() {
    return { code: 0, title: undefined };
}
export const WhatsAppWebhookStatusError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).uint32(message.code);
        }
        if (message.title !== undefined) {
            StringValue.encode({ value: message.title }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookStatusError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 2:
                    message.title = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            title: isSet(object.title) ? String(object.title) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.title !== undefined && (obj.title = message.title);
        return obj;
    },
};
function createBaseConversationOriginTypeWrapper() {
    return {};
}
export const ConversationOriginTypeWrapper = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConversationOriginTypeWrapper();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseConversationPricingCategoryWrapper() {
    return {};
}
export const ConversationPricingCategoryWrapper = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConversationPricingCategoryWrapper();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseWhatsAppWebhookStatusConversationOrigin() {
    return { type: 0 };
}
export const WhatsAppWebhookStatusConversationOrigin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookStatusConversationOrigin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? conversationOriginTypeWrapper_ConversationOriginTypeFromJSON(object.type)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = conversationOriginTypeWrapper_ConversationOriginTypeToJSON(message.type));
        return obj;
    },
};
function createBaseWhatsAppWebhookStatusConversation() {
    return { id: undefined, expirationTimestamp: undefined, origin: undefined };
}
export const WhatsAppWebhookStatusConversation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.expirationTimestamp !== undefined) {
            StringValue.encode({ value: message.expirationTimestamp }, writer.uint32(18).fork()).ldelim();
        }
        if (message.origin !== undefined) {
            WhatsAppWebhookStatusConversationOrigin.encode(message.origin, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookStatusConversation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.expirationTimestamp = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.origin = WhatsAppWebhookStatusConversationOrigin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            expirationTimestamp: isSet(object.expirationTimestamp)
                ? String(object.expirationTimestamp)
                : undefined,
            origin: isSet(object.origin)
                ? WhatsAppWebhookStatusConversationOrigin.fromJSON(object.origin)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.expirationTimestamp !== undefined &&
            (obj.expirationTimestamp = message.expirationTimestamp);
        message.origin !== undefined &&
            (obj.origin = message.origin
                ? WhatsAppWebhookStatusConversationOrigin.toJSON(message.origin)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppWebhookStatusPricing() {
    return { pricingModel: undefined, billable: undefined, category: 0 };
}
export const WhatsAppWebhookStatusPricing = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.pricingModel !== undefined) {
            StringValue.encode({ value: message.pricingModel }, writer.uint32(10).fork()).ldelim();
        }
        if (message.billable !== undefined) {
            BoolValue.encode({ value: message.billable }, writer.uint32(18).fork()).ldelim();
        }
        if (message.category !== 0) {
            writer.uint32(24).int32(message.category);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookStatusPricing();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pricingModel = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.billable = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.category = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            pricingModel: isSet(object.pricingModel)
                ? String(object.pricingModel)
                : undefined,
            billable: isSet(object.billable) ? Boolean(object.billable) : undefined,
            category: isSet(object.category)
                ? conversationPricingCategoryWrapper_ConversationPricingCategoryFromJSON(object.category)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.pricingModel !== undefined &&
            (obj.pricingModel = message.pricingModel);
        message.billable !== undefined && (obj.billable = message.billable);
        message.category !== undefined &&
            (obj.category =
                conversationPricingCategoryWrapper_ConversationPricingCategoryToJSON(message.category));
        return obj;
    },
};
function createBaseWhatsAppWebhookStatus() {
    return {
        id: undefined,
        status: 0,
        timestamp: undefined,
        recipientId: undefined,
        errors: [],
        conversation: undefined,
        pricing: undefined,
    };
}
export const WhatsAppWebhookStatus = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(16).int32(message.status);
        }
        if (message.timestamp !== undefined) {
            StringValue.encode({ value: message.timestamp }, writer.uint32(26).fork()).ldelim();
        }
        if (message.recipientId !== undefined) {
            StringValue.encode({ value: message.recipientId }, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.errors) {
            WhatsAppWebhookStatusError.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.conversation !== undefined) {
            WhatsAppWebhookStatusConversation.encode(message.conversation, writer.uint32(50).fork()).ldelim();
        }
        if (message.pricing !== undefined) {
            WhatsAppWebhookStatusPricing.encode(message.pricing, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookStatus();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.status = reader.int32();
                    break;
                case 3:
                    message.timestamp = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.recipientId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.errors.push(WhatsAppWebhookStatusError.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.conversation = WhatsAppWebhookStatusConversation.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.pricing = WhatsAppWebhookStatusPricing.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            status: isSet(object.status) ? webhookStatusFromJSON(object.status) : 0,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            recipientId: isSet(object.recipientId)
                ? String(object.recipientId)
                : undefined,
            errors: Array.isArray(object === null || object === void 0 ? void 0 : object.errors)
                ? object.errors.map((e) => WhatsAppWebhookStatusError.fromJSON(e))
                : [],
            conversation: isSet(object.conversation)
                ? WhatsAppWebhookStatusConversation.fromJSON(object.conversation)
                : undefined,
            pricing: isSet(object.pricing)
                ? WhatsAppWebhookStatusPricing.fromJSON(object.pricing)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.status !== undefined &&
            (obj.status = webhookStatusToJSON(message.status));
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.recipientId !== undefined &&
            (obj.recipientId = message.recipientId);
        if (message.errors) {
            obj.errors = message.errors.map((e) => e ? WhatsAppWebhookStatusError.toJSON(e) : undefined);
        }
        else {
            obj.errors = [];
        }
        message.conversation !== undefined &&
            (obj.conversation = message.conversation
                ? WhatsAppWebhookStatusConversation.toJSON(message.conversation)
                : undefined);
        message.pricing !== undefined &&
            (obj.pricing = message.pricing
                ? WhatsAppWebhookStatusPricing.toJSON(message.pricing)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueMessagesMessage() {
    return {
        from: undefined,
        id: undefined,
        timestamp: undefined,
        text: undefined,
        type: 0,
        identity: undefined,
        image: undefined,
        document: undefined,
        voice: undefined,
        video: undefined,
        sticker: undefined,
        location: undefined,
        errors: [],
        system: undefined,
        button: undefined,
        audio: undefined,
        context: undefined,
        interactive: undefined,
        order: undefined,
        reaction: undefined,
        referral: undefined,
    };
}
export const WhatsAppWebhookValueMessagesMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.from !== undefined) {
            StringValue.encode({ value: message.from }, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(18).fork()).ldelim();
        }
        if (message.timestamp !== undefined) {
            StringValue.encode({ value: message.timestamp }, writer.uint32(26).fork()).ldelim();
        }
        if (message.text !== undefined) {
            WhatsAppWebhookText.encode(message.text, writer.uint32(34).fork()).ldelim();
        }
        if (message.type !== 0) {
            writer.uint32(40).int32(message.type);
        }
        if (message.identity !== undefined) {
            WhatsAppWebhookIdentity.encode(message.identity, writer.uint32(50).fork()).ldelim();
        }
        if (message.image !== undefined) {
            WhatsAppWebhookMedia.encode(message.image, writer.uint32(58).fork()).ldelim();
        }
        if (message.document !== undefined) {
            WhatsAppWebhookMedia.encode(message.document, writer.uint32(66).fork()).ldelim();
        }
        if (message.voice !== undefined) {
            WhatsAppWebhookMedia.encode(message.voice, writer.uint32(74).fork()).ldelim();
        }
        if (message.video !== undefined) {
            WhatsAppWebhookMedia.encode(message.video, writer.uint32(82).fork()).ldelim();
        }
        if (message.sticker !== undefined) {
            WhatsAppWebhookSticker.encode(message.sticker, writer.uint32(90).fork()).ldelim();
        }
        if (message.location !== undefined) {
            WhatsAppLocation.encode(message.location, writer.uint32(106).fork()).ldelim();
        }
        for (const v of message.errors) {
            WhatsAppWebhookError.encode(v, writer.uint32(122).fork()).ldelim();
        }
        if (message.system !== undefined) {
            WhatsAppWebhookSystem.encode(message.system, writer.uint32(130).fork()).ldelim();
        }
        if (message.button !== undefined) {
            WhatsAppWebhookButton.encode(message.button, writer.uint32(138).fork()).ldelim();
        }
        if (message.audio !== undefined) {
            WhatsAppWebhookMedia.encode(message.audio, writer.uint32(146).fork()).ldelim();
        }
        if (message.context !== undefined) {
            WhatsAppWebhookContext.encode(message.context, writer.uint32(154).fork()).ldelim();
        }
        if (message.interactive !== undefined) {
            WhatsAppWebhookInteractive.encode(message.interactive, writer.uint32(162).fork()).ldelim();
        }
        if (message.order !== undefined) {
            WhatsAppWebhookOrder.encode(message.order, writer.uint32(170).fork()).ldelim();
        }
        if (message.reaction !== undefined) {
            WhatsAppWebhookReaction.encode(message.reaction, writer.uint32(178).fork()).ldelim();
        }
        if (message.referral !== undefined) {
            WhatsAppWebhookReferral.encode(message.referral, writer.uint32(186).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueMessagesMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.from = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.timestamp = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.text = WhatsAppWebhookText.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.identity = WhatsAppWebhookIdentity.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.image = WhatsAppWebhookMedia.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.document = WhatsAppWebhookMedia.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.voice = WhatsAppWebhookMedia.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.video = WhatsAppWebhookMedia.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.sticker = WhatsAppWebhookSticker.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.location = WhatsAppLocation.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.errors.push(WhatsAppWebhookError.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.system = WhatsAppWebhookSystem.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.button = WhatsAppWebhookButton.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.audio = WhatsAppWebhookMedia.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.context = WhatsAppWebhookContext.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.interactive = WhatsAppWebhookInteractive.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.order = WhatsAppWebhookOrder.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.reaction = WhatsAppWebhookReaction.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.referral = WhatsAppWebhookReferral.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            from: isSet(object.from) ? String(object.from) : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            text: isSet(object.text)
                ? WhatsAppWebhookText.fromJSON(object.text)
                : undefined,
            type: isSet(object.type) ? messageTypeFromJSON(object.type) : 0,
            identity: isSet(object.identity)
                ? WhatsAppWebhookIdentity.fromJSON(object.identity)
                : undefined,
            image: isSet(object.image)
                ? WhatsAppWebhookMedia.fromJSON(object.image)
                : undefined,
            document: isSet(object.document)
                ? WhatsAppWebhookMedia.fromJSON(object.document)
                : undefined,
            voice: isSet(object.voice)
                ? WhatsAppWebhookMedia.fromJSON(object.voice)
                : undefined,
            video: isSet(object.video)
                ? WhatsAppWebhookMedia.fromJSON(object.video)
                : undefined,
            sticker: isSet(object.sticker)
                ? WhatsAppWebhookSticker.fromJSON(object.sticker)
                : undefined,
            location: isSet(object.location)
                ? WhatsAppLocation.fromJSON(object.location)
                : undefined,
            errors: Array.isArray(object === null || object === void 0 ? void 0 : object.errors)
                ? object.errors.map((e) => WhatsAppWebhookError.fromJSON(e))
                : [],
            system: isSet(object.system)
                ? WhatsAppWebhookSystem.fromJSON(object.system)
                : undefined,
            button: isSet(object.button)
                ? WhatsAppWebhookButton.fromJSON(object.button)
                : undefined,
            audio: isSet(object.audio)
                ? WhatsAppWebhookMedia.fromJSON(object.audio)
                : undefined,
            context: isSet(object.context)
                ? WhatsAppWebhookContext.fromJSON(object.context)
                : undefined,
            interactive: isSet(object.interactive)
                ? WhatsAppWebhookInteractive.fromJSON(object.interactive)
                : undefined,
            order: isSet(object.order)
                ? WhatsAppWebhookOrder.fromJSON(object.order)
                : undefined,
            reaction: isSet(object.reaction)
                ? WhatsAppWebhookReaction.fromJSON(object.reaction)
                : undefined,
            referral: isSet(object.referral)
                ? WhatsAppWebhookReferral.fromJSON(object.referral)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.from !== undefined && (obj.from = message.from);
        message.id !== undefined && (obj.id = message.id);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.text !== undefined &&
            (obj.text = message.text
                ? WhatsAppWebhookText.toJSON(message.text)
                : undefined);
        message.type !== undefined && (obj.type = messageTypeToJSON(message.type));
        message.identity !== undefined &&
            (obj.identity = message.identity
                ? WhatsAppWebhookIdentity.toJSON(message.identity)
                : undefined);
        message.image !== undefined &&
            (obj.image = message.image
                ? WhatsAppWebhookMedia.toJSON(message.image)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? WhatsAppWebhookMedia.toJSON(message.document)
                : undefined);
        message.voice !== undefined &&
            (obj.voice = message.voice
                ? WhatsAppWebhookMedia.toJSON(message.voice)
                : undefined);
        message.video !== undefined &&
            (obj.video = message.video
                ? WhatsAppWebhookMedia.toJSON(message.video)
                : undefined);
        message.sticker !== undefined &&
            (obj.sticker = message.sticker
                ? WhatsAppWebhookSticker.toJSON(message.sticker)
                : undefined);
        message.location !== undefined &&
            (obj.location = message.location
                ? WhatsAppLocation.toJSON(message.location)
                : undefined);
        if (message.errors) {
            obj.errors = message.errors.map((e) => e ? WhatsAppWebhookError.toJSON(e) : undefined);
        }
        else {
            obj.errors = [];
        }
        message.system !== undefined &&
            (obj.system = message.system
                ? WhatsAppWebhookSystem.toJSON(message.system)
                : undefined);
        message.button !== undefined &&
            (obj.button = message.button
                ? WhatsAppWebhookButton.toJSON(message.button)
                : undefined);
        message.audio !== undefined &&
            (obj.audio = message.audio
                ? WhatsAppWebhookMedia.toJSON(message.audio)
                : undefined);
        message.context !== undefined &&
            (obj.context = message.context
                ? WhatsAppWebhookContext.toJSON(message.context)
                : undefined);
        message.interactive !== undefined &&
            (obj.interactive = message.interactive
                ? WhatsAppWebhookInteractive.toJSON(message.interactive)
                : undefined);
        message.order !== undefined &&
            (obj.order = message.order
                ? WhatsAppWebhookOrder.toJSON(message.order)
                : undefined);
        message.reaction !== undefined &&
            (obj.reaction = message.reaction
                ? WhatsAppWebhookReaction.toJSON(message.reaction)
                : undefined);
        message.referral !== undefined &&
            (obj.referral = message.referral
                ? WhatsAppWebhookReferral.toJSON(message.referral)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueMessages() {
    return {
        messagingProduct: undefined,
        metadata: undefined,
        contacts: [],
        messages: [],
        statuses: [],
        errors: [],
    };
}
export const WhatsAppWebhookValueMessages = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.metadata !== undefined) {
            WhatsAppWebhookValueMessagesMetadata.encode(message.metadata, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.contacts) {
            WhatsAppWebhookContacts.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.messages) {
            WhatsAppWebhookValueMessagesMessage.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.statuses) {
            WhatsAppWebhookStatus.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.errors) {
            WhatsAppWebhookError.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueMessages();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.metadata = WhatsAppWebhookValueMessagesMetadata.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.contacts.push(WhatsAppWebhookContacts.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.messages.push(WhatsAppWebhookValueMessagesMessage.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.statuses.push(WhatsAppWebhookStatus.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.errors.push(WhatsAppWebhookError.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            metadata: isSet(object.metadata)
                ? WhatsAppWebhookValueMessagesMetadata.fromJSON(object.metadata)
                : undefined,
            contacts: Array.isArray(object === null || object === void 0 ? void 0 : object.contacts)
                ? object.contacts.map((e) => WhatsAppWebhookContacts.fromJSON(e))
                : [],
            messages: Array.isArray(object === null || object === void 0 ? void 0 : object.messages)
                ? object.messages.map((e) => WhatsAppWebhookValueMessagesMessage.fromJSON(e))
                : [],
            statuses: Array.isArray(object === null || object === void 0 ? void 0 : object.statuses)
                ? object.statuses.map((e) => WhatsAppWebhookStatus.fromJSON(e))
                : [],
            errors: Array.isArray(object === null || object === void 0 ? void 0 : object.errors)
                ? object.errors.map((e) => WhatsAppWebhookError.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.metadata !== undefined &&
            (obj.metadata = message.metadata
                ? WhatsAppWebhookValueMessagesMetadata.toJSON(message.metadata)
                : undefined);
        if (message.contacts) {
            obj.contacts = message.contacts.map((e) => e ? WhatsAppWebhookContacts.toJSON(e) : undefined);
        }
        else {
            obj.contacts = [];
        }
        if (message.messages) {
            obj.messages = message.messages.map((e) => e ? WhatsAppWebhookValueMessagesMessage.toJSON(e) : undefined);
        }
        else {
            obj.messages = [];
        }
        if (message.statuses) {
            obj.statuses = message.statuses.map((e) => e ? WhatsAppWebhookStatus.toJSON(e) : undefined);
        }
        else {
            obj.statuses = [];
        }
        if (message.errors) {
            obj.errors = message.errors.map((e) => e ? WhatsAppWebhookError.toJSON(e) : undefined);
        }
        else {
            obj.errors = [];
        }
        return obj;
    },
};
function createBaseWhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo() {
    return { disableDate: undefined };
}
export const WhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.disableDate !== undefined) {
            StringValue.encode({ value: message.disableDate }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.disableDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            disableDate: isSet(object.disableDate)
                ? String(object.disableDate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.disableDate !== undefined &&
            (obj.disableDate = message.disableDate);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueMessageTemplateStatusUpdate() {
    return {
        event: 0,
        messageTemplateId: undefined,
        messageTemplateName: undefined,
        messageTemplateLanguage: undefined,
        reason: undefined,
        disableInfo: undefined,
    };
}
export const WhatsAppWebhookValueMessageTemplateStatusUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.event !== 0) {
            writer.uint32(8).int32(message.event);
        }
        if (message.messageTemplateId !== undefined) {
            Int64Value.encode({ value: message.messageTemplateId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.messageTemplateName !== undefined) {
            StringValue.encode({ value: message.messageTemplateName }, writer.uint32(26).fork()).ldelim();
        }
        if (message.messageTemplateLanguage !== undefined) {
            StringValue.encode({ value: message.messageTemplateLanguage }, writer.uint32(34).fork()).ldelim();
        }
        if (message.reason !== undefined) {
            StringValue.encode({ value: message.reason }, writer.uint32(42).fork()).ldelim();
        }
        if (message.disableInfo !== undefined) {
            WhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo.encode(message.disableInfo, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueMessageTemplateStatusUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event = reader.int32();
                    break;
                case 2:
                    message.messageTemplateId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.messageTemplateName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.messageTemplateLanguage = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.reason = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.disableInfo =
                        WhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            event: isSet(object.event)
                ? whatsAppWebhookValueMessageTemplateStatusUpdate_EventFromJSON(object.event)
                : 0,
            messageTemplateId: isSet(object.messageTemplateId)
                ? Number(object.messageTemplateId)
                : undefined,
            messageTemplateName: isSet(object.messageTemplateName)
                ? String(object.messageTemplateName)
                : undefined,
            messageTemplateLanguage: isSet(object.messageTemplateLanguage)
                ? String(object.messageTemplateLanguage)
                : undefined,
            reason: isSet(object.reason) ? String(object.reason) : undefined,
            disableInfo: isSet(object.disableInfo)
                ? WhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo.fromJSON(object.disableInfo)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.event !== undefined &&
            (obj.event = whatsAppWebhookValueMessageTemplateStatusUpdate_EventToJSON(message.event));
        message.messageTemplateId !== undefined &&
            (obj.messageTemplateId = message.messageTemplateId);
        message.messageTemplateName !== undefined &&
            (obj.messageTemplateName = message.messageTemplateName);
        message.messageTemplateLanguage !== undefined &&
            (obj.messageTemplateLanguage = message.messageTemplateLanguage);
        message.reason !== undefined && (obj.reason = message.reason);
        message.disableInfo !== undefined &&
            (obj.disableInfo = message.disableInfo
                ? WhatsAppWebhookValueMessageTemplateStatusUpdateDisableInfo.toJSON(message.disableInfo)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueAccountUpdateBanInfo() {
    return { wabaBanState: 0, wabaBanDate: undefined };
}
export const WhatsAppWebhookValueAccountUpdateBanInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.wabaBanState !== 0) {
            writer.uint32(8).int32(message.wabaBanState);
        }
        if (message.wabaBanDate !== undefined) {
            StringValue.encode({ value: message.wabaBanDate }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueAccountUpdateBanInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.wabaBanState = reader.int32();
                    break;
                case 2:
                    message.wabaBanDate = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            wabaBanState: isSet(object.wabaBanState)
                ? whatsAppWebhookValueAccountUpdateBanInfo_WabaBanStateFromJSON(object.wabaBanState)
                : 0,
            wabaBanDate: isSet(object.wabaBanDate)
                ? String(object.wabaBanDate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.wabaBanState !== undefined &&
            (obj.wabaBanState =
                whatsAppWebhookValueAccountUpdateBanInfo_WabaBanStateToJSON(message.wabaBanState));
        message.wabaBanDate !== undefined &&
            (obj.wabaBanDate = message.wabaBanDate);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueAccountUpdate() {
    return {
        event: 0,
        phoneNumber: undefined,
        banInfo: undefined,
        restrictionInfo: [],
    };
}
export const WhatsAppWebhookValueAccountUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.event !== 0) {
            writer.uint32(8).int32(message.event);
        }
        if (message.phoneNumber !== undefined) {
            StringValue.encode({ value: message.phoneNumber }, writer.uint32(18).fork()).ldelim();
        }
        if (message.banInfo !== undefined) {
            WhatsAppWebhookValueAccountUpdateBanInfo.encode(message.banInfo, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.restrictionInfo) {
            WhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueAccountUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event = reader.int32();
                    break;
                case 2:
                    message.phoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.banInfo = WhatsAppWebhookValueAccountUpdateBanInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.restrictionInfo.push(WhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            event: isSet(object.event)
                ? whatsAppWebhookValueAccountUpdate_EventFromJSON(object.event)
                : 0,
            phoneNumber: isSet(object.phoneNumber)
                ? String(object.phoneNumber)
                : undefined,
            banInfo: isSet(object.banInfo)
                ? WhatsAppWebhookValueAccountUpdateBanInfo.fromJSON(object.banInfo)
                : undefined,
            restrictionInfo: Array.isArray(object === null || object === void 0 ? void 0 : object.restrictionInfo)
                ? object.restrictionInfo.map((e) => WhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.event !== undefined &&
            (obj.event = whatsAppWebhookValueAccountUpdate_EventToJSON(message.event));
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.banInfo !== undefined &&
            (obj.banInfo = message.banInfo
                ? WhatsAppWebhookValueAccountUpdateBanInfo.toJSON(message.banInfo)
                : undefined);
        if (message.restrictionInfo) {
            obj.restrictionInfo = message.restrictionInfo.map((e) => e
                ? WhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo.toJSON(e)
                : undefined);
        }
        else {
            obj.restrictionInfo = [];
        }
        return obj;
    },
};
function createBaseWhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo() {
    return { restrictionType: 0, expiration: undefined };
}
export const WhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.restrictionType !== 0) {
            writer.uint32(8).int32(message.restrictionType);
        }
        if (message.expiration !== undefined) {
            Timestamp.encode(toTimestamp(message.expiration), writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueAccountUpdate_WhatsAppWebhookValueAccountUpdateRestrictionInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.restrictionType = reader.int32();
                    break;
                case 2:
                    message.expiration = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            restrictionType: isSet(object.restrictionType)
                ? whatsAppWebhookValueAccountUpdate_RestrictionTypeFromJSON(object.restrictionType)
                : 0,
            expiration: isSet(object.expiration)
                ? String(object.expiration)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.restrictionType !== undefined &&
            (obj.restrictionType =
                whatsAppWebhookValueAccountUpdate_RestrictionTypeToJSON(message.restrictionType));
        message.expiration !== undefined && (obj.expiration = message.expiration);
        return obj;
    },
};
function createBaseWhatsAppWebhookValueAccountReviewUpdate() {
    return { decision: undefined };
}
export const WhatsAppWebhookValueAccountReviewUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.decision !== undefined) {
            StringValue.encode({ value: message.decision }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueAccountReviewUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.decision = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            decision: isSet(object.decision) ? String(object.decision) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.decision !== undefined && (obj.decision = message.decision);
        return obj;
    },
};
function createBaseWhatsAppWebhookValuePhoneNumberNameUpdate() {
    return {
        displayPhoneNumber: undefined,
        decision: 0,
        requestedVerifiedName: undefined,
        rejectionReason: undefined,
    };
}
export const WhatsAppWebhookValuePhoneNumberNameUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.displayPhoneNumber !== undefined) {
            StringValue.encode({ value: message.displayPhoneNumber }, writer.uint32(10).fork()).ldelim();
        }
        if (message.decision !== 0) {
            writer.uint32(16).int32(message.decision);
        }
        if (message.requestedVerifiedName !== undefined) {
            StringValue.encode({ value: message.requestedVerifiedName }, writer.uint32(26).fork()).ldelim();
        }
        if (message.rejectionReason !== undefined) {
            StringValue.encode({ value: message.rejectionReason }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValuePhoneNumberNameUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.displayPhoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.decision = reader.int32();
                    break;
                case 3:
                    message.requestedVerifiedName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.rejectionReason = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            displayPhoneNumber: isSet(object.displayPhoneNumber)
                ? String(object.displayPhoneNumber)
                : undefined,
            decision: isSet(object.decision)
                ? whatsAppWebhookValuePhoneNumberNameUpdate_DecisionFromJSON(object.decision)
                : 0,
            requestedVerifiedName: isSet(object.requestedVerifiedName)
                ? String(object.requestedVerifiedName)
                : undefined,
            rejectionReason: isSet(object.rejectionReason)
                ? String(object.rejectionReason)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.displayPhoneNumber !== undefined &&
            (obj.displayPhoneNumber = message.displayPhoneNumber);
        message.decision !== undefined &&
            (obj.decision = whatsAppWebhookValuePhoneNumberNameUpdate_DecisionToJSON(message.decision));
        message.requestedVerifiedName !== undefined &&
            (obj.requestedVerifiedName = message.requestedVerifiedName);
        message.rejectionReason !== undefined &&
            (obj.rejectionReason = message.rejectionReason);
        return obj;
    },
};
function createBaseWhatsAppWebhookValuePhoneNumberQualityUpdate() {
    return { event: 0, displayPhoneNumber: undefined, currentLimit: 0 };
}
export const WhatsAppWebhookValuePhoneNumberQualityUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.event !== 0) {
            writer.uint32(8).int32(message.event);
        }
        if (message.displayPhoneNumber !== undefined) {
            StringValue.encode({ value: message.displayPhoneNumber }, writer.uint32(18).fork()).ldelim();
        }
        if (message.currentLimit !== 0) {
            writer.uint32(24).int32(message.currentLimit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValuePhoneNumberQualityUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event = reader.int32();
                    break;
                case 2:
                    message.displayPhoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.currentLimit = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            event: isSet(object.event)
                ? whatsAppWebhookValuePhoneNumberQualityUpdate_EventFromJSON(object.event)
                : 0,
            displayPhoneNumber: isSet(object.displayPhoneNumber)
                ? String(object.displayPhoneNumber)
                : undefined,
            currentLimit: isSet(object.currentLimit)
                ? whatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimitFromJSON(object.currentLimit)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.event !== undefined &&
            (obj.event = whatsAppWebhookValuePhoneNumberQualityUpdate_EventToJSON(message.event));
        message.displayPhoneNumber !== undefined &&
            (obj.displayPhoneNumber = message.displayPhoneNumber);
        message.currentLimit !== undefined &&
            (obj.currentLimit =
                whatsAppWebhookValuePhoneNumberQualityUpdate_CurrentLimitToJSON(message.currentLimit));
        return obj;
    },
};
function createBaseWhatsAppWebhookValueBusinessCapabilityUpdate() {
    return {
        maxDailyConversationPerPhone: undefined,
        maxPhoneNumbersPerBusiness: undefined,
    };
}
export const WhatsAppWebhookValueBusinessCapabilityUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.maxDailyConversationPerPhone !== undefined) {
            Int64Value.encode({ value: message.maxDailyConversationPerPhone }, writer.uint32(10).fork()).ldelim();
        }
        if (message.maxPhoneNumbersPerBusiness !== undefined) {
            Int64Value.encode({ value: message.maxPhoneNumbersPerBusiness }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookValueBusinessCapabilityUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.maxDailyConversationPerPhone = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.maxPhoneNumbersPerBusiness = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            maxDailyConversationPerPhone: isSet(object.maxDailyConversationPerPhone)
                ? Number(object.maxDailyConversationPerPhone)
                : undefined,
            maxPhoneNumbersPerBusiness: isSet(object.maxPhoneNumbersPerBusiness)
                ? Number(object.maxPhoneNumbersPerBusiness)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.maxDailyConversationPerPhone !== undefined &&
            (obj.maxDailyConversationPerPhone = message.maxDailyConversationPerPhone);
        message.maxPhoneNumbersPerBusiness !== undefined &&
            (obj.maxPhoneNumbersPerBusiness = message.maxPhoneNumbersPerBusiness);
        return obj;
    },
};
function createBaseWhatsAppWebhookChange() {
    return { value: undefined, field: undefined };
}
export const WhatsAppWebhookChange = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.value !== undefined) {
            Struct.encode(Struct.wrap(message.value), writer.uint32(10).fork()).ldelim();
        }
        if (message.field !== undefined) {
            StringValue.encode({ value: message.field }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookChange();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.field = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            value: isObject(object.value) ? object.value : undefined,
            field: isSet(object.field) ? String(object.field) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.value !== undefined && (obj.value = message.value);
        message.field !== undefined && (obj.field = message.field);
        return obj;
    },
};
function createBaseWhatsAppWebhookEntry() {
    return { id: undefined, changes: [] };
}
export const WhatsAppWebhookEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.changes) {
            WhatsAppWebhookChange.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.changes.push(WhatsAppWebhookChange.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            changes: Array.isArray(object === null || object === void 0 ? void 0 : object.changes)
                ? object.changes.map((e) => WhatsAppWebhookChange.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.changes) {
            obj.changes = message.changes.map((e) => e ? WhatsAppWebhookChange.toJSON(e) : undefined);
        }
        else {
            obj.changes = [];
        }
        return obj;
    },
};
function createBaseWhatsAppWebhookEnvelope() {
    return { object: undefined, entry: [] };
}
export const WhatsAppWebhookEnvelope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.object !== undefined) {
            StringValue.encode({ value: message.object }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.entry) {
            WhatsAppWebhookEntry.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookEnvelope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.object = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.entry.push(WhatsAppWebhookEntry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            object: isSet(object.object) ? String(object.object) : undefined,
            entry: Array.isArray(object === null || object === void 0 ? void 0 : object.entry)
                ? object.entry.map((e) => WhatsAppWebhookEntry.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.object !== undefined && (obj.object = message.object);
        if (message.entry) {
            obj.entry = message.entry.map((e) => e ? WhatsAppWebhookEntry.toJSON(e) : undefined);
        }
        else {
            obj.entry = [];
        }
        return obj;
    },
};
function createBaseWhatsAppWebhookInteractive() {
    return { type: 0, listReply: undefined, buttonReply: undefined };
}
export const WhatsAppWebhookInteractive = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.listReply !== undefined) {
            Section_Row.encode(message.listReply, writer.uint32(18).fork()).ldelim();
        }
        if (message.buttonReply !== undefined) {
            Action_ButtonReply.encode(message.buttonReply, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookInteractive();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.listReply = Section_Row.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.buttonReply = Action_ButtonReply.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? whatsAppWebhookInteractive_Addendum_TypeFromJSON(object.type)
                : 0,
            listReply: isSet(object.listReply)
                ? Section_Row.fromJSON(object.listReply)
                : undefined,
            buttonReply: isSet(object.buttonReply)
                ? Action_ButtonReply.fromJSON(object.buttonReply)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = whatsAppWebhookInteractive_Addendum_TypeToJSON(message.type));
        message.listReply !== undefined &&
            (obj.listReply = message.listReply
                ? Section_Row.toJSON(message.listReply)
                : undefined);
        message.buttonReply !== undefined &&
            (obj.buttonReply = message.buttonReply
                ? Action_ButtonReply.toJSON(message.buttonReply)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppWebhookInteractive_Addendum() {
    return {};
}
export const WhatsAppWebhookInteractive_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookInteractive_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseWhatsAppWebhookOrder() {
    return { catalogId: undefined, productItems: [], text: undefined };
}
export const WhatsAppWebhookOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.catalogId !== undefined) {
            StringValue.encode({ value: message.catalogId }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.productItems) {
            WhatsAppWebhookOrder_ProductItems.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.text !== undefined) {
            StringValue.encode({ value: message.text }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.catalogId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.productItems.push(WhatsAppWebhookOrder_ProductItems.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.text = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : undefined,
            productItems: Array.isArray(object === null || object === void 0 ? void 0 : object.productItems)
                ? object.productItems.map((e) => WhatsAppWebhookOrder_ProductItems.fromJSON(e))
                : [],
            text: isSet(object.text) ? String(object.text) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        if (message.productItems) {
            obj.productItems = message.productItems.map((e) => e ? WhatsAppWebhookOrder_ProductItems.toJSON(e) : undefined);
        }
        else {
            obj.productItems = [];
        }
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseWhatsAppWebhookOrder_ProductItems() {
    return {
        productRetailerId: undefined,
        quantity: undefined,
        itemPrice: undefined,
        currency: undefined,
    };
}
export const WhatsAppWebhookOrder_ProductItems = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.productRetailerId !== undefined) {
            StringValue.encode({ value: message.productRetailerId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.quantity !== undefined) {
            Int64Value.encode({ value: message.quantity }, writer.uint32(18).fork()).ldelim();
        }
        if (message.itemPrice !== undefined) {
            DoubleValue.encode({ value: message.itemPrice }, writer.uint32(26).fork()).ldelim();
        }
        if (message.currency !== undefined) {
            StringValue.encode({ value: message.currency }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppWebhookOrder_ProductItems();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.productRetailerId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.quantity = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.itemPrice = DoubleValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.currency = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            productRetailerId: isSet(object.productRetailerId)
                ? String(object.productRetailerId)
                : undefined,
            quantity: isSet(object.quantity) ? Number(object.quantity) : undefined,
            itemPrice: isSet(object.itemPrice) ? Number(object.itemPrice) : undefined,
            currency: isSet(object.currency) ? String(object.currency) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.productRetailerId !== undefined &&
            (obj.productRetailerId = message.productRetailerId);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.itemPrice !== undefined && (obj.itemPrice = message.itemPrice);
        message.currency !== undefined && (obj.currency = message.currency);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
