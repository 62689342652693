import { Pattern, AllPatterns, DecorationStyleCombination } from './types';

export const ALLOWED_STYLES: DecorationStyleCombination[] = [
  'bold',
  'ital',
  'stri',
  'bold-ital',
  'bold-stri',
  'bold-ital-stri',
  'ital-stri',
  'vari',
  'bold-vari',
  'ital-vari',
  'stri-vari',
  'bold-ital-vari',
  'bold-stri-vari',
  'ital-stri-vari',
  'bold-ital-stri-vari',
  'mono',
  'bold-mono',
  'ital-mono',
  'mono-stri',
  'bold-ital-mono',
  'bold-mono-stri',
  'ital-mono-stri',
  'mono-vari',
  'bold-mono-vari',
  'ital-mono-vari',
  'mono-stri-vari',
  'bold-ital-mono-vari',
  'bold-mono-stri-vari',
  'ital-mono-stri-vari',
  'bold-ital-mono-stri-vari',
];

export const SPECIAL_PATTERNS: Pattern[] = [
  { name: 'vari', open: '{{', close: '}}' },
  { name: 'mono', open: '```', close: '```' },
];
export const BASIC_PATTERNS: Pattern[] = [
  { name: 'bold', open: '*', close: '*' },
  { name: 'ital', open: '_', close: '_' },
  { name: 'stri', open: '~', close: '~' },
];

export const ALL_PATTERNS: AllPatterns = {
  bold: {
    open: '*',
    close: '*',
  },
  ital: {
    open: '_',
    close: '_',
  },
  stri: {
    open: '~',
    close: '~',
  },
  mono: {
    open: '```',
    close: '```',
  },
  vari: {
    open: '{{',
    close: '}}',
  },
};
