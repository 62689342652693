import { ACMessageIcon, Box, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WhatsAppMessageTemplatePreview } from '@components/WhatsAppMessageTemplatePreview';
import { SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { AbandonedCartReminderPreviewProps } from './types';

export const AbandonedCartReminderPreview = ({ language }: AbandonedCartReminderPreviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart' });
  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        borderRadius: '10px',
        px: 1.5,
        paddingBottom: 1.5,
        display: 'flex',
        flexDirection: 'column',
        gap: SIZE_SPACING_INTER_COMPONENT,
        boxShadow: '0px 2px 6px 0px #0000002E',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', p: 1.5 }}>
        <ACMessageIcon fill={theme.palette.common.black} width={32} height={32} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Label sx={{ fontWeight: 500, fontSize: '16px' }}>{t('editIntegration.standardMessage')}</Label>
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.secondary.light,
          borderRadius: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
          px: 3,
          height: '100%',
        }}
      >
        <WhatsAppMessageTemplatePreview
          bodyText={
            language === 'es' ? (
              <>
                ¿Sigues pensando en esos artículos? ¡No lo pienses más, es hora de hacerlos tuyos! 💥🔥{'\n\n'}
                <Box
                  component="span"
                  m="{1}"
                  sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}
                >
                  Nombre Comercial
                </Box>
              </>
            ) : (
              <>
                {`Still thinking about those items? Think no more, it's time to make them yours!`} 💥🔥{'\n\n'}
                <Box
                  component="span"
                  m="{1}"
                  sx={{ backgroundColor: theme.palette.secondary.light, p: 0.5, borderRadius: 0.5 }}
                >
                  Business Name
                </Box>
              </>
            )
          }
        />
      </Box>
    </Box>
  );
};
