import React, { FC, useState, useCallback, useMemo } from 'react';
import { Button, TrashIcon } from '@connectlyai-tenets/ui-styled-web';
import { EdgeProps, useStore } from 'react-flow-renderer';
import { useContextSelector } from 'use-context-selector';
import getSmoothStepPath from '../../utils/getSmoothStepPath';
import { EDGE_HOVER_TRIGGER_DISTANCE } from './constants';
import { FlowContext } from '../../contexts';

const useFlowChartCustomEdge = ({
  id,
  markerEnd,
  sourcePosition,
  sourceX,
  sourceY,
  style = {},
  targetPosition,
  targetX,
  targetY,
  target,
}: EdgeProps) => {
  const [edgeStepPath, edgeStepCenterX, edgeStepCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 40,
  });

  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const targetHeight = targetNode?.height || 0;

  const halfHeight = 0.5 * targetHeight;

  const [edgePath, edgeCenterX, edgeCenterY] = useMemo(() => {
    // if y coordinates are very close return an horizontal line
    if (targetY + halfHeight > sourceY && targetY - halfHeight < sourceY) {
      return [`M${sourceX},${sourceY} L${targetX},${sourceY}`, 0.5 * (sourceX + targetX), sourceY];
    }
    return [edgeStepPath, edgeStepCenterX, edgeStepCenterY];
  }, [edgeStepPath, edgeStepCenterX, edgeStepCenterY, halfHeight, sourceX, sourceY, targetX, targetY]);

  const isDocumentEditable = useContextSelector(FlowContext, (context) => context.isDocumentEditable);
  const [isHovering, setIsHovering] = useState(false);

  const { createDeleteEdgeHandler } = useContextSelector(FlowContext, (context) => context.flowHandlers);
  const deleteEdgeHandler = createDeleteEdgeHandler(id);

  return {
    deleteEdgeHandler,
    edgeCenterX,
    edgeCenterY,
    edgePath,
    id,
    isDocumentEditable,
    isHovering,
    markerEnd,
    setIsHovering,
    style,
  };
};

export const FlowChartCustomEdge: FC<EdgeProps> = (edgeProps: EdgeProps) => {
  const {
    deleteEdgeHandler,
    edgeCenterX,
    edgeCenterY,
    edgePath,
    id,
    isDocumentEditable,
    isHovering,
    markerEnd,
    setIsHovering,
    style,
  } = useFlowChartCustomEdge(edgeProps);

  return (
    <g
      style={{
        pointerEvents: 'all',
      }}
      onMouseOver={() => setIsHovering(isDocumentEditable)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <path id={id} style={style} d={edgePath} markerEnd={markerEnd} />
      <path
        id={`${id}-hover`}
        style={{
          ...style,
          strokeLinecap: 'square',
          strokeLinejoin: 'miter',
          strokeOpacity: 0,
          strokeWidth: EDGE_HOVER_TRIGGER_DISTANCE,
          fill: 'none',
        }}
        d={edgePath}
      />
      {isDocumentEditable && isHovering && (
        <foreignObject width={32} height={32} x={edgeCenterX - 16} y={edgeCenterY - 16}>
          <Button
            variant="contained"
            sx={{
              width: 32,
              minWidth: 32,
              height: 32,
              p: 1,
            }}
            onClick={deleteEdgeHandler}
          >
            <TrashIcon />
          </Button>
        </foreignObject>
      )}
    </g>
  );
};
