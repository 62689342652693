import React, { useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';
import { shopifyAbandonedCheckoutOnboardingStepAtom } from '@atoms/home';
import { Box, ConnectlyCard, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { ShopifyAbandonedCheckoutOnboardingStep } from '../../../../features/welcome';
import { ShopifyAbandonedCheckoutOnboardingProps } from './types';
import { SMBOnboarding } from './SMBOnboarding';

export const ShopifyAbandonedCheckoutOnboarding = ({ shopify, whatsApp }: ShopifyAbandonedCheckoutOnboardingProps) => {
  const { isShopifyActive, storeName } = shopify;
  const { claimedNumber, isWhatsAppActive, verificationCode } = whatsApp;

  const [step, setStep] = useAtom(shopifyAbandonedCheckoutOnboardingStepAtom);
  useEffect(() => {
    setStep(ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep);
  }, []);

  const handleNextStep = useCallback(() => {
    switch (step) {
      case ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep: {
        setStep(ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep);
        break;
      }
      case ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep: {
        break;
      }
      default: {
        break;
      }
    }
  }, [step]);

  let content;
  let media;
  const theme = useTheme();
  switch (step) {
    case ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep:
    case ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep: {
      content = (
        <Box>
          <SMBOnboarding
            shopify={{
              isShopifyActive,
              storeName,
            }}
            whatsApp={{
              claimedNumber,
              isWhatsAppActive,
              verificationCode,
            }}
            onNextStep={handleNextStep}
          />
        </Box>
      );
      return (
        <Box
          sx={{
            m: 1,
            alignSelf: 'center',
            borderRadius: theme.spacing(1.5),
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          {content}
        </Box>
      );
    }
    default: {
      break;
    }
  }

  return (
    <ConnectlyCard>
      <Box sx={{ display: 'flex', gap: 6, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>{content}</Box>
        <Box>{media}</Box>
      </Box>
    </ConnectlyCard>
  );
};
