/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropZone as BaseDropZone, DropZoneProps } from '@connectlyai-tenets/ui-styled-web';

export const DropZone = ({
  handleUploadFile,
  acceptedFileTypes,
  acceptedFileTypesText,
  uploadLimitText,
  autoFocus,
  errorHelperText,
  isSuccess = false,
  hasError = false,
  sx,
}: Omit<
  DropZoneProps,
  | 'initialText'
  | 'initialButtonText'
  | 'successText'
  | 'successButtonText'
  | 'failedText'
  | 'failedButtonText'
  | 'loadingText'
  | 'dragingText'
>) => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.dropzone' });

  const initialText = t('initialText', 'Drop files here to upload');
  const initialButtonText = t('initialButtonText', 'Browse files');
  const successText = t('successText', 'Upload completed');
  const successButtonText = t('successButtonText', 'Browse more files');
  const failedText = t('failedText', 'Upload failed');
  const failedButtonText = t('failedButtonText', 'Try again');
  const loadingText = t('loadingText', 'Uploading your files...');
  const dragingText = t('dragingText', 'Drop files here to upload...');

  return (
    <BaseDropZone
      handleUploadFile={handleUploadFile}
      initialText={initialText}
      initialButtonText={initialButtonText}
      successText={successText}
      successButtonText={successButtonText}
      failedText={failedText}
      failedButtonText={failedButtonText}
      loadingText={loadingText}
      dragingText={dragingText}
      sx={sx}
      acceptedFileTypes={acceptedFileTypes}
      acceptedFileTypesText={acceptedFileTypesText}
      uploadLimitText={uploadLimitText}
      autoFocus={autoFocus}
      errorHelperText={errorHelperText}
      isSuccess={isSuccess}
      hasError={hasError}
    />
  );
};
