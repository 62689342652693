import { DependencyList, useEffect } from 'react';
import { useTimeoutFn } from '../useTimeoutFn';

export const useDebounce = <T extends () => void = () => void>(fn: T, ms = 0, deps: DependencyList = []) => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);

  useEffect(reset, [reset, ...deps]);

  return [isReady, cancel];
};
