import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as MainRouter } from 'react-router-dom';
import { io } from 'socket.io-client';
import { LoggerImpl } from '@connectlyai-tenets/logging';
import { AxiosInstanceBuilder } from '@connectlyai-tenets/network-request';
import { MuiThemeProvider, StyledEngineProvider, StyledThemeProvider, theme } from '@connectlyai-tenets/ui-styled-web';
import { AuthProvider } from '@connectlyai-sdks/auth';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import App from './App';
import './index.css';
import uiRegistry from './ui-registry';
import { accessTokenService } from './api';
import packageJson from '../package.json';
import './i18n';

const NETWORK_CLIENT_TIMEOUT_MS = 60000;
const WIDGET_CLIENT_TIMEOUT_MS = 60000;

const log = new LoggerImpl();
const networkClient = new AxiosInstanceBuilder()
  .withBaseURL(process.env.REACT_APP_INBOX_API_GATEWAY)
  .withTimeout(NETWORK_CLIENT_TIMEOUT_MS)
  .build();
const widgetClient = new AxiosInstanceBuilder()
  .withBaseURL(process.env.REACT_APP_INBOX_WIDGET_BASELOADER_URI)
  .withTimeout(WIDGET_CLIENT_TIMEOUT_MS)
  .build();

const realtimeClient = (accessToken: string) => {
  accessTokenService.tempSolution = () => Promise.resolve(accessToken);
  return io(process.env.REACT_APP_INBOX_REALTIME_API || '', {
    // using websocket instead of http long polling to remove requirement for sticky load balancing.
    transports: ['websocket'],
    auth: {
      accessToken,
    },
  });
};

datadogLogs.init({
  clientToken: 'pubc871afa6cbb48a1145227c62928f8818',
  site: 'datadoghq.com',
  service: 'inbox',
  env: process.env.NODE_ENV,
  version: packageJson.version,
  forwardErrorsToLogs: false,
  telemetrySampleRate: 0,
  beforeSend: (data) => {
    if (data?.view?.referrer) delete data.view.referrer;
    if (data?.view?.referrer_details) delete data.view.referrer_details;
  },
});

datadogRum.init({
  applicationId: '60f763fc-f058-41b7-9995-82751ccd74da',
  clientToken: 'pubf911eb43a406685b4afa7e6f0e429815',
  site: 'datadoghq.com',
  service: 'inbox',
  env: process.env.NODE_ENV,
  version: packageJson.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackFrustrations: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  enableExperimentalFeatures: ['clickmap'],
  beforeSend: (data) => {
    if (data?.view?.referrer) delete data.view.referrer;
  },
});

const AppWithAuth = () => {
  const domain = process.env.REACT_APP_INBOX_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_INBOX_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_INBOX_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    return <div>Something went wrong</div>;
  }

  const redirectUri = `${window.location.origin}`;
  return (
    <AuthProvider domain={domain} clientId={clientId} redirectUri={redirectUri} audience={audience}>
      <App
        config={{
          audience,
        }}
        log={log}
        networkClient={networkClient}
        widgetClient={widgetClient}
        realtimeClient={realtimeClient}
        uiRegistry={uiRegistry}
      />
    </AuthProvider>
  );
};
const container = document.getElementById('root');
if (!container) throw new Error('No root element found');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <MainRouter>
            <AppWithAuth />
          </MainRouter>
        </StyledEngineProvider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
);
