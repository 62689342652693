import { useAtomValue } from 'jotai';
import type { FeatureFlagState, FeatureFlagKey } from '@connectlyai-tenets/feature-flag';
import { featureFlagsAtom } from '@atoms/app';

/**
 * Hook to get the state of one, multiple, or all feature flags.
 *
 * @param flags - An array of feature flag keys or '*' to get all feature flags.
 * @returns An object containing the requested feature flags or all feature flags.
 *
 * @example
 * // Get specific feature flags
 * const flags = useFeatureFlag(['ffEnableCarousel', 'ffEnableETAProductReviews']);
 * // flags is of type Pick<FeatureFlagState, 'ffEnableCarousel' | 'ffEnableETAProductReviews'>
 *
 * @example
 * // Get all feature flags
 * const allFlags = useFeatureFlag('*');
 * // allFlags is of type FeatureFlagState
 */
export const useFeatureFlag = <T extends Array<FeatureFlagKey> | '*' = Array<FeatureFlagKey>>(
  flags: T,
): T extends '*' ? FeatureFlagState : Pick<FeatureFlagState, Extract<T, Array<FeatureFlagKey>>[number]> => {
  const state = useAtomValue(featureFlagsAtom);

  if (flags === '*') {
    return state as T extends '*'
      ? FeatureFlagState
      : Pick<FeatureFlagState, Extract<T, Array<FeatureFlagKey>>[number]>;
  }

  return flags.reduce(
    (acc, flag) => ({ ...acc, [flag]: state[flag] }),
    {} as T extends '*' ? FeatureFlagState : Pick<FeatureFlagState, Extract<T, Array<FeatureFlagKey>>[number]>,
  );
};
