import React, { VFC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@connectlyai-tenets/ui-styled-web';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { WhatsAppBroadcastTabIndex } from '../WhatsAppBroadcast/types';

export const DeleteSendoutDialog: VFC<{
  tabIndex: WhatsAppBroadcastTabIndex;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ tabIndex, onConfirm, onClose, open }) => {
  let item = 'item';
  switch (tabIndex) {
    case 'CAMPAIGN': {
      item = 'campaign';
      break;
    }
    case 'DRAFT': {
      item = 'draft';
      break;
    }
    case 'SENDOUT': {
      item = 'sendout';
      break;
    }
    default: {
      assertUnreachable(tabIndex);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Are you sure you want to delete ${item}?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
