import { SettingsResponse } from '@hooks/useSettingsData/types';
import { selectWAIsActive, selectSMSNumber } from '@hooks';
import { WhatsAppInfo } from './types';

// WhatsApp is active if there is a WhatsApp phone number
const defaultWhatsAppInfo: WhatsAppInfo = {
  whatsAppIsActive: false,
};

export const selectWhatsAppInfo = (data: SettingsResponse): WhatsAppInfo => {
  const entity = data?.entity;
  if (!entity) return defaultWhatsAppInfo;
  return {
    whatsAppIsActive: selectWAIsActive(data),
    whatsAppClaimedNumber: selectSMSNumber(data),
  };
};
