import React, { useMemo, useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { selectedSmartAudienceAtom, cohortsGeneratingAtom } from '@atoms/audience';
import { useSetAtom } from 'jotai';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Label,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import {
  selectMostEngagedSegmentOptions,
  useAnalytics,
  useAudienceSegmentsData,
  useCreateAudienceSegmentMutation,
  useStandardNotifications,
} from '@hooks';
import { SIZE_SPACING_INTER_COMPONENT, SIZE_SPACING_INTER_SECTION } from '../../../../../ui-theme';
import { AudienceType, QueryTemplateType } from '../../../types';
import { CreateQueryAudienceProps, UseCreateQueryAudienceProps } from './types';
import { buildSegmentInput, queryExists } from '../utils';
import { useMutationCreateCohort } from '../../../hooks';

const DEFAULT_VALUE = 2;
const DEFAULT_LOOKBACK = 30;
const DEFAULT_QUERY_NAME = 'Engaged Customers';

const useCreateQueryAudience = ({ audienceTypes, businessId, closeModal }: UseCreateQueryAudienceProps) => {
  const [queryName, setQueryName] = useState('');
  const [audienceType, setAudienceType] = useState<AudienceType>('query');
  const [templateType, setTemplateType] = useState<QueryTemplateType | undefined>('engaged');
  const [lookbackSelected, setLookbackSelected] = useState(DEFAULT_LOOKBACK);
  const [valueSelected, setValueSelected] = useState(DEFAULT_VALUE);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const setSelectedSegment = useSetAtom(selectedSmartAudienceAtom);
  const setCohortsGenerating = useSetAtom(cohortsGeneratingAtom);
  const loadCohortInterval = useRef<number | NodeJS.Timer | undefined>(undefined);
  const { data: existingQueries = [], isLoading: isLoadingOptions } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    { select: selectMostEngagedSegmentOptions, enabled: !!businessId },
  );
  useEffect(() => {
    return () => {
      if (loadCohortInterval.current !== undefined) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(loadCohortInterval.current as number);
      }
    };
  }, []);
  const valueOptions = useMemo(() => {
    return [
      { value: 1, display: 'one' },
      { value: 2, display: 'two' },
      { value: 3, display: 'three' },
      { value: 4, display: 'four' },
      { value: 5, display: 'five' },
    ];
  }, []);
  const lookbackOptions = useMemo(() => {
    return [
      { value: 1, display: 'day' },
      { value: 2, display: 'two days' },
      { value: 3, display: 'three days' },
      { value: 7, display: 'one week' },
      { value: 14, display: 'two weeks' },
      { value: 30, display: 'one month' },
      { value: 90, display: 'three months' },
    ];
  }, []);
  const queryText = useMemo(() => {
    const valueIsSingle = valueSelected === 1;
    const timeText = valueIsSingle ? 'time' : 'times';
    return {
      first: 'Anyone who clicked on a button or link at least',
      second: `${timeText} over the past`,
    };
  }, [valueSelected]);
  const handleSelectValue = useCallback(
    (e: SelectChangeEvent<number>) => {
      const newValue = Number(e.target.value);
      setValueSelected(newValue);
    },
    [setValueSelected],
  );
  const handleSelectLookback = useCallback(
    (e: SelectChangeEvent<number>) => {
      const newValue = Number(e.target.value);
      setLookbackSelected(newValue);
    },
    [setLookbackSelected],
  );
  const handleChangeQueryName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      setQueryName(newName);
    },
    [setQueryName],
  );
  const nameError = useMemo(() => {
    const existingNames = existingQueries.map((query) => query.name);
    const conflict = existingNames.includes(queryName);
    return conflict ? 'Name already used by another smart audience' : '';
  }, [queryName, existingQueries]);
  const queryError = useMemo(() => {
    const matchFound = queryExists({
      queries: existingQueries,
      lookbackValue: lookbackSelected,
      frequencyValue: valueSelected,
    });
    if (matchFound) {
      return 'Query already exists';
    }
    return '';
  }, [existingQueries, lookbackSelected, valueSelected]);
  const theme = useTheme();
  const isMediaMd = useMediaQuery(theme.breakpoints.down('lg'));
  const { createText, cancelText } = useMemo(() => {
    return {
      createText: 'Create new smart audience',
      cancelText: 'Cancel',
    };
  }, []);

  // find unused query name
  const uniqueQueryName = useMemo(() => {
    let name = DEFAULT_QUERY_NAME;
    let i = 1;
    const existingNames = existingQueries.map((query) => query.name);
    while (existingNames.includes(name)) {
      name = `${DEFAULT_QUERY_NAME}${i === 1 ? '' : ` ${i}`}`;
      i += 1;
    }
    return name;
  }, [existingQueries]);

  // find unused query parameters
  const uniqueQuery = useMemo(() => {
    let matchFound = queryExists({
      queries: existingQueries,
      lookbackValue: lookbackSelected,
      frequencyValue: valueSelected,
    });
    if (matchFound) {
      let frequencyIndex = 0;
      while (frequencyIndex < valueOptions.length) {
        let lookbackIndex = 0;
        while (lookbackIndex < lookbackOptions.length) {
          matchFound = queryExists({
            queries: existingQueries,
            lookbackValue: lookbackOptions[lookbackIndex].value,
            frequencyValue: valueOptions[frequencyIndex].value,
          });
          if (!matchFound) {
            return {
              lookback: lookbackOptions[lookbackIndex].value,
              frequency: valueOptions[frequencyIndex].value,
            };
          }
          lookbackIndex += 1;
        }
        frequencyIndex += 1;
      }
    }
    return {
      lookback: lookbackSelected,
      frequency: valueSelected,
    };
  }, [existingQueries, lookbackOptions, lookbackSelected, valueOptions, valueSelected]);
  useLayoutEffect(() => {
    setQueryName(uniqueQueryName);
    setLookbackSelected(uniqueQuery.lookback);
    setValueSelected(uniqueQuery.frequency);
  }, []);

  const onCancel = useCallback(() => {
    setQueryName('');
    setLookbackSelected(DEFAULT_LOOKBACK);
    setValueSelected(DEFAULT_VALUE);
    setSubmitDisabled(false);
    closeModal();
  }, [closeModal]);

  const queryClient = useQueryClient();
  const { notifySuccess: notifyCohortSuccess, notifyError: notifyCohortError } = useStandardNotifications({
    successMessage: 'Cohort Is Generating',
    errorMessage: 'Failed to create new Cohort',
  });
  const { mutate: createCohort } = useMutationCreateCohort({
    onError: () => {
      notifyCohortError();
    },
    onSuccess: (_, arg) => {
      const { segmentId } = arg;
      onCancel();
      notifyCohortSuccess();
      setCohortsGenerating((prev) => [...prev, segmentId || '']);
      // timeout used to avoid query conflict error showing during hide transition
      setTimeout(() => setSubmitting(false), 1000);
      setSelectedSegment(segmentId || '');
    },
  });

  const { notifySuccess, notifyError } = useStandardNotifications({
    successMessage: 'Audience Created',
    errorMessage: 'Failed to create audience',
  });
  const { sendAnalytics } = useAnalytics();
  const { mutate: createSegment, isLoading: createIsLoading } = useCreateAudienceSegmentMutation({
    onError: () => {
      notifyError();
      setSubmitting(false);
    },
    onSuccess: (_, args) => {
      const { segmentId } = args;
      notifySuccess();
      queryClient.invalidateQueries(['audienceSegments']);
      createCohort({ businessId, segmentId });
      sendAnalytics('(flow) select smart audience', { businessId, segmentId, templateType });
    },
  });

  const handleSubmit = useCallback(() => {
    if (!businessId) {
      return;
    }
    setSubmitting(true);
    const uuid = uuidv4();
    const { input } = buildSegmentInput({
      name: queryName,
      lookbackValue: lookbackSelected,
      frequencyValue: valueSelected,
    });
    createSegment({
      businessId,
      segmentId: uuid,
      input,
    });
  }, [businessId, createSegment, lookbackSelected, queryName, valueSelected]);
  const dimensions = useMemo(() => {
    const width = { left: isMediaMd ? 35 : 45, right: isMediaMd ? 73 : 87 };
    if (audienceTypes.length === 1) {
      width.left = 0;
    }
    return width;
  }, [audienceTypes.length, isMediaMd]);
  const selectOption = useCallback(
    (newAudienceType: AudienceType, template?: QueryTemplateType) => {
      setAudienceType(newAudienceType);
      setTemplateType(template);
    },
    [setAudienceType, setTemplateType],
  );
  const { t: genericT } = useTranslation('translation', { keyPrefix: 'generic' });
  const nameHeader = useMemo(() => {
    return genericT('name');
  }, [genericT]);

  const { t } = useTranslation('translation', { keyPrefix: 'audience.manage.smartAudience.audiences' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t(`${templateType}.title`),
      subtitle: t(`${templateType}.description`),
    };
  }, [t, templateType]);

  return {
    audienceType,
    cancelText,
    createText,
    dimensions,
    handleChangeQueryName,
    handleSelectLookback,
    handleSelectValue,
    handleSubmit,
    isMediaMd,
    lookbackOptions,
    lookbackSelected,
    nameError: createIsLoading || submitting ? '' : nameError,
    nameHeader,
    onCancel,
    onClose: closeModal,
    queryError: createIsLoading || submitting ? '' : queryError,
    queryName,
    queryText,
    selectOption,
    submitDisabled: submitDisabled || !!nameError || !!queryError || createIsLoading,
    subtitle,
    theme,
    title,
    valueOptions,
    valueSelected,
  };
};

export const CreateQueryAudience = ({ businessId, audienceTypes, closeModal, width }: CreateQueryAudienceProps) => {
  const {
    cancelText,
    createText,
    handleChangeQueryName,
    handleSelectLookback,
    handleSelectValue,
    handleSubmit,
    isMediaMd,
    lookbackOptions,
    lookbackSelected,
    nameError,
    nameHeader,
    onCancel,
    queryError,
    queryName,
    queryText,
    submitDisabled,
    subtitle,
    title,
    valueOptions,
    valueSelected,
  } = useCreateQueryAudience({
    businessId,
    audienceTypes,
    closeModal,
  });
  const theme = useTheme();
  return (
    <Stack sx={{ flex: isMediaMd ? '0 0 auto' : `1 1 ${theme.spacing(width)}` }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Label variant="h4">{title}</Label>
        </Stack>
        <Label variant="body1" color="text.secondary">
          {subtitle}
        </Label>
      </DialogTitle>
      <Divider
        sx={{
          mx: SIZE_SPACING_INTER_SECTION,
          my: SIZE_SPACING_INTER_COMPONENT,
          borderColor: theme.palette.grey[200],
        }}
      />
      <DialogContent sx={{ pt: 1 }}>
        <Stack gap={SIZE_SPACING_INTER_SECTION}>
          <FormControl variant="standard" sx={{ width: 'auto', flex: '0 0 auto' }}>
            <Label sx={{ mb: 0.5, fontWeight: 600 }}>{nameHeader}</Label>
            <TextField
              id="name-input"
              autoFocus
              value={queryName}
              onChange={handleChangeQueryName}
              sx={{
                '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
                '& .MuiInputBase-input': { p: '11px 12px' },
              }}
            />
            {nameError && (
              <FormHelperText id="name-helper-text" sx={{ color: theme.palette.error.main }}>
                {nameError}
              </FormHelperText>
            )}
          </FormControl>
          <Box>
            <Label sx={{ fontWeight: 600 }}>Who is Included</Label>
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <Label variant="body1">{queryText.first}</Label>
              <Select
                size="small"
                value={valueSelected}
                onChange={handleSelectValue}
                sx={{ mx: SIZE_SPACING_INTER_COMPONENT }}
              >
                {valueOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display}
                    </MenuItem>
                  );
                })}
              </Select>
              <Label variant="body1">{queryText.second}</Label>
              <Select
                size="small"
                value={lookbackSelected}
                onChange={handleSelectLookback}
                sx={{ mx: SIZE_SPACING_INTER_COMPONENT }}
              >
                {lookbackOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
            {queryError && (
              <Label variant="body1" sx={{ color: theme.palette.error.main }}>
                {queryError}
              </Label>
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          flex: '0 0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
          pt: isMediaMd ? 2 : 21.5,
        }}
      >
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="contained" color="primary" disabled={submitDisabled} onClick={handleSubmit}>
          {createText}
        </Button>
      </DialogActions>
    </Stack>
  );
};
