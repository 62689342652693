export { ComposerSendBehavior } from '@connectlyai-idl/models/dist/models/inbox/models';
export const USER_EMPTY = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    displayName: '',
    avatarUrl: '',
    business: {
        id: '',
        name: '',
    },
    accessToken: '',
};
