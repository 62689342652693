export const AudienceRouteId = '/audience';
export const AudienceCategoryRouteId = '/audience/:categoryId';
export const AUDIENCE_SUBPAGE_MANAGE = 'manage';
export const AUDIENCE_SUBPAGE_OPT_OUT = 'opt-out';
export const AUDIENCE_SUBPAGE_TEMPLATES = 'templates';
export function generateAudienceRoute() {
    return {
        id: AudienceRouteId,
        params: {},
    };
}
export function generateAudienceCategoryRoute(args) {
    return {
        id: AudienceCategoryRouteId,
        params: {
            categoryId: args.categoryId,
        },
    };
}
