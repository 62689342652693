import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { hoveringNodeIdAtom } from '@atoms/flow';

export const useIsHoveringNode = (nodeId: string) => {
  const [hoveringNodeId, setHoveringNodeId] = useAtom(hoveringNodeIdAtom);

  const setIsHoveringNode = useCallback(
    (isHovering: boolean) => {
      if (isHovering) {
        setHoveringNodeId(nodeId);
        return;
      }

      if (hoveringNodeId === nodeId) setHoveringNodeId('');
    },
    [nodeId, hoveringNodeId, setHoveringNodeId],
  );

  const handleMouseEnter = useCallback(() => setIsHoveringNode(true), [setIsHoveringNode]);
  const handleMouseLeave = useCallback(() => setIsHoveringNode(false), [setIsHoveringNode]);

  const isHoveringNode = useMemo(() => hoveringNodeId === nodeId, [hoveringNodeId, nodeId]);

  return { isHoveringNode, handleMouseEnter, handleMouseLeave };
};
