import React from 'react';
import {
  ActionsProps,
  Box,
  Button,
  ConnectlyCard,
  ErrorIcon,
  Label,
  useTheme,
  WhatsAppCheckIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import {
  selectAccountStatus,
  selectBusinessId,
  selectWAChannelId,
  useBusinessChannelMetaData,
  useFeatureFlag,
  useMeData,
  useSettingsData,
} from '@hooks';
import { SIZE_SPACING_INTER_COMPONENT } from 'src/ui-theme';
import {
  META_VERIFICATION_GET_STARTED_URL,
  META_VERIFICATION_LEARN_MORE_URL,
  META_VERIFICATION_URL,
} from '@components/WhatsAppOBAStatus/constants';

export const WhatsAppOBAStatusV2 = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.accountStatus' });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: businessChannelId } = useSettingsData({
    businessId: businessId as string,
    select: selectWAChannelId,
    enabled: !!businessId,
  });
  const { data: accountStatusMetaData } = useBusinessChannelMetaData({
    businessId: businessId as string,
    businessChannelId: businessChannelId as string,
    enabled: !!businessId && !!businessChannelId,
    select: selectAccountStatus,
  });

  if (accountStatusMetaData?.isOfficialBusinessAccount) {
    return (
      <ConnectlyCard
        icon={
          <Box
            component="img"
            sx={{ width: theme.spacing(4.5), height: theme.spacing(4.5) }}
            src={`${process.env.PUBLIC_URL}/assets/welcome/whatsapp_verification.png`}
          />
        }
        title={t('verifiedTitle')}
        subtitle={t('verifiedSubtitle')}
      />
    );
  }

  let content = null;
  let actions: ActionsProps | undefined;
  switch (accountStatusMetaData?.reviewStatus) {
    case 'WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED':
      content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '20px 1fr .7fr',
              backgroundColor: theme.palette.skyBlue,
              p: 1,
              gap: 1,
              alignItems: 'center',
            }}
          >
            <ErrorIcon sx={{ color: theme.palette.warning.light, width: theme.spacing(2), height: theme.spacing(2) }} />
            <Label variant="body1">{t('businessVerificationRequired')}</Label>
            <Button sx={{ fontSize: 12 }} href={META_VERIFICATION_URL}>
              {t('verifyNow')}
            </Button>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            href={META_VERIFICATION_LEARN_MORE_URL}
            target="_blank"
            sx={{ alignSelf: 'flex-start' }}
          >
            {t('learnMore')}
          </Button>
        </Box>
      );
      break;
    case 'WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED':
      content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                p: 1,
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: '10px',
                color: theme.palette.error.light,
              }}
            >
              <Label variant="body1">{t('rejected')}</Label>
            </Box>
          </Box>
          <Button
            variant="contained"
            href={META_VERIFICATION_GET_STARTED_URL}
            target="_blank"
            sx={{ alignSelf: 'flex-start' }}
          >
            {t('applyAgain')}
          </Button>
        </Box>
      );
      break;
    case 'WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING':
      content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: SIZE_SPACING_INTER_COMPONENT }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                p: 1,
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: '10px',
                color: theme.palette.warning.light,
              }}
            >
              <Label variant="body1">{t('pending')}</Label>
            </Box>
            <Box sx={{ p: 1, backgroundColor: 'grey.100', borderRadius: '10px' }}>
              <Label variant="body1">{t('waitingForApproval')}</Label>
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            href={META_VERIFICATION_LEARN_MORE_URL}
            target="_blank"
            sx={{ alignSelf: 'flex-start' }}
          >
            {t('learnMore')}
          </Button>
        </Box>
      );
      break;
    default:
      actions = {
        primaryButton: {
          variant: 'contained',
          href: META_VERIFICATION_GET_STARTED_URL,
          target: '_blank',
          sx: { alignSelf: 'flex-start' },
          children: t('getStarted'),
        },
      };
      content = null;
      break;
  }

  return (
    <ConnectlyCard title={t('title')} subtitle={t('subtitle')} icon={<WhatsAppCheckIcon />} actions={actions}>
      {content}
    </ConnectlyCard>
  );
};
