import { inboxExperienceFromJSON, inboxExperienceToJSON, } from "../inbox/models";
import Long from "long";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.profile";
function createBaseStartSignUpInput() {
    return {
        email: "",
        displayName: "",
        businessName: "",
        purchasesPerMonth: 0,
        experience: 0,
    };
}
export const StartSignUpInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.email !== "") {
            writer.uint32(10).string(message.email);
        }
        if (message.displayName !== "") {
            writer.uint32(18).string(message.displayName);
        }
        if (message.businessName !== "") {
            writer.uint32(26).string(message.businessName);
        }
        if (message.purchasesPerMonth !== 0) {
            writer.uint32(40).int64(message.purchasesPerMonth);
        }
        if (message.experience !== 0) {
            writer.uint32(32).int32(message.experience);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStartSignUpInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.displayName = reader.string();
                    break;
                case 3:
                    message.businessName = reader.string();
                    break;
                case 5:
                    message.purchasesPerMonth = longToNumber(reader.int64());
                    break;
                case 4:
                    message.experience = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            email: isSet(object.email) ? String(object.email) : "",
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
            businessName: isSet(object.businessName)
                ? String(object.businessName)
                : "",
            purchasesPerMonth: isSet(object.purchasesPerMonth)
                ? Number(object.purchasesPerMonth)
                : 0,
            experience: isSet(object.experience)
                ? inboxExperienceFromJSON(object.experience)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.email !== undefined && (obj.email = message.email);
        message.displayName !== undefined &&
            (obj.displayName = message.displayName);
        message.businessName !== undefined &&
            (obj.businessName = message.businessName);
        message.purchasesPerMonth !== undefined &&
            (obj.purchasesPerMonth = Math.round(message.purchasesPerMonth));
        message.experience !== undefined &&
            (obj.experience = inboxExperienceToJSON(message.experience));
        return obj;
    },
};
function createBaseCreateUserPasswordInput() {
    return { confirmToken: "", password: "" };
}
export const CreateUserPasswordInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.confirmToken !== "") {
            writer.uint32(10).string(message.confirmToken);
        }
        if (message.password !== "") {
            writer.uint32(18).string(message.password);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateUserPasswordInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.confirmToken = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            confirmToken: isSet(object.confirmToken)
                ? String(object.confirmToken)
                : "",
            password: isSet(object.password) ? String(object.password) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.confirmToken !== undefined &&
            (obj.confirmToken = message.confirmToken);
        message.password !== undefined && (obj.password = message.password);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
