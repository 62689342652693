import React, { useCallback, useMemo, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  sortString,
  sortPhoneNumber,
  sortDate,
  EnhancedTable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
} from '@components/EnhancedTable';
import { GroupIcon, Box } from '@connectlyai-tenets/ui-styled-web';
import { useMeData, selectBusinessId, useFeatureFlag, selectRole, useAnalytics } from '@hooks';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useTranslation } from 'react-i18next';
import { OptOutDataItem } from '../../hooks/useOptOutData';
import { OptOutTableProps } from './types';
import { useMutationDeleteOptOut } from '../../hooks';
import { NotificationContext } from '../../../../contexts';
import { OptOutChannelType, UPLOAD_CHANNEL_TYPE } from '../UploadOptOutModal/constants';
import { formatPhoneNumber, formatDate } from '../../../../utils';
import { UploadOptOutButton } from '../UploadOptOutModal';
import { DownloadOptOut } from '../DownloadOptOut';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';

const useOptOutTable = ({ data }: { data: OptOutDataItem[] }) => {
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { ffEnableOptOutDelete, ffEnableOptOutUpload } = useFeatureFlag([
    'ffEnableOptOutDelete',
    'ffEnableOptOutUpload',
  ]);
  const { data: role } = useMeData({ select: selectRole });
  const isAdmin = useMemo(() => role === 'ROLE_OWNER', [role]);
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'externalId',
        label: t('audience.optOut.numberHeader'),
        width: '50%',
        sort: sortPhoneNumber,
        align: 'left',
      },
      {
        id: 'sourceType',
        label: t('audience.optOut.typeHeader'),
        width: '25%',
        sort: sortString,
        align: 'left',
      },
      {
        id: 'createdAt',
        label: t('audience.optOut.createdAtHeader'),
        width: '25%',
        sort: sortDate,
        align: 'right',
      },
    ];
  }, [t]);
  const rows: ETableRowProps[] = useMemo(() => {
    if (!data) return [];
    return data.map((item) => {
      return {
        id: item.externalId || '',
        selectable: isAdmin
          ? true
          : !['OPT_OUT_SOURCE_TYPE_KEYWORD', 'OPT_OUT_SOURCE_TYPE_BUTTON'].includes(item.sourceType || ''),
        data: [
          {
            value: item.externalId,
            display: formatPhoneNumber(item.externalId || '', true),
            align: 'left',
          },
          {
            value: item.sourceType,
            display: t(`audience.optOut.optOutType.${item.sourceType}`),
            align: 'left',
          },
          {
            value: item.createdAt,
            display: formatDate(item.createdAt || ''),
            align: 'right',
          },
        ],
      };
    });
  }, [data, isAdmin, t]);
  const queryClient = useQueryClient();
  const { notificationProvider } = useContext(NotificationContext);
  const { sendAnalytics } = useAnalytics();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { mutate: mutateDeleteOptOut } = useMutationDeleteOptOut({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['opt-out-data', businessId] });
      sendAnalytics('(audience) delete opt-out entries', { businessId });
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully updated opt-out list',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Failed to delete from opt-out list',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });
  const handleDelete = useCallback(
    async (deleteIds: string[]) => {
      if (!businessId) return;
      const input = {
        optOutList: deleteIds.map((id) => ({
          externalId: id,
          channelType: UPLOAD_CHANNEL_TYPE as OptOutChannelType,
        })),
      };
      await mutateDeleteOptOut({
        businessId: businessId || '',
        input,
      });
      queryClient.invalidateQueries({ queryKey: ['opt-out-data', businessId] });
    },
    [businessId, mutateDeleteOptOut, queryClient],
  );
  const icon = <GroupIcon />;
  const title = useCallback(
    (length: number | undefined) => {
      if (length === undefined) return t('generic.loading');
      if (length === 0) return `Zero ${t('audience.optOut.multipleDataHeader')}`;
      if (length === 1) return t('audience.optOut.oneDataHeader');
      return `${length} ${t('audience.optOut.multipleDataHeader')}`;
    },
    [t],
  );
  const searchFn = useCallback((row: ETableRowProps, search: string) => {
    const searchNumber = search.replace(/[^0-9]/g, '');
    const itemNumberString = (row.id || '').replace(/[^0-9]/g, '');
    return itemNumberString.includes(searchNumber);
  }, []);
  const deleteSupport = useMemo(() => {
    if (!handleDelete || !ffEnableOptOutDelete) return undefined;
    return {
      handleAction: handleDelete,
      confirmModal: {
        title: (numRows: number) => {
          const isPlural = numRows > 1;
          return `Are you sure you want to delete ${isPlural ? 'these entries' : 'this entry'}?`;
        },
        subtitle: (numRows: number) => {
          const isPlural = numRows > 1;
          return `${numRows} contact${isPlural ? 's' : ''} will be removed from the opt-out list.`;
        },
      },
      controls: {
        actionText: (numRows: number, editing: boolean) => {
          if (!editing) return 'Edit';
          return `Delete ${numRows === 1 ? 'entry' : `${numRows} entries`}`;
        },
      },
    };
  }, [ffEnableOptOutDelete, handleDelete]);
  const searchSupport = useMemo(() => {
    return {
      searchFn,
      placeholder: t('audience.optOut.searchPlaceholder'),
    };
  }, [searchFn, t]);
  return {
    enableOptOutUpload: ffEnableOptOutUpload,
    deleteSupport,
    title,
    icon,
    searchSupport,
    tableHead,
    rows,
  };
};

const TableActions = ({ data, enableOptOutUpload }: { data?: OptOutDataItem[]; enableOptOutUpload: boolean }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: SIZE_SPACING_INTER_COMPONENT }}>
      {enableOptOutUpload && <UploadOptOutButton />}
      <DownloadOptOut data={data} />
    </Box>
  );
};

export const OptOutTable = ({ data, isLoading }: OptOutTableProps) => {
  const { deleteSupport, searchSupport, icon, title, tableHead, rows, enableOptOutUpload } = useOptOutTable({ data });
  return (
    <EnhancedTable
      rows={rows}
      headers={tableHead}
      sortDefault={{ by: 'createdAt', order: 'desc' }}
      selectSupport={deleteSupport}
      title={title}
      icon={icon}
      searchSupport={searchSupport}
      isLoading={isLoading}
      actions={<TableActions data={data} enableOptOutUpload={enableOptOutUpload} />}
    />
  );
};
