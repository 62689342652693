import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { IntegrationMetricsQuery, IntegrationMetricsResponse, IntegrationMetricsUrlParameters } from './types';

export const useIntegrationMetricsData = <TData = IntegrationMetricsResponse>({
  businessId = '',
  integrationId = '',
  startDate,
  endDate,
  ...options
}: Omit<
  UseQueryOptions<
    IntegrationMetricsResponse,
    unknown,
    TData,
    readonly ['integrationMetrics', IntegrationMetricsUrlParameters, IntegrationMetricsQuery]
  >,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<IntegrationMetricsQuery> &
  IntegrationMetricsUrlParameters) => {
  return useQuery(
    ['integrationMetrics', { businessId, integrationId }, { startDate, endDate }],
    () =>
      api.integrationV1('/internal/v1/businesses/{input.businessId}/query/metrics/{input.integrationId}', 'post', {
        path: { 'input.businessId': businessId, 'input.integrationId': integrationId },
        body: { input: { startDate, endDate } },
      }),
    options,
  );
};
