import React, { FC } from 'react';
import { Box, Button, CheckIcon, Dialog, DialogContentText, Label } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { ApprovalDialogProps } from './types';

export const ApprovalDialog: FC<ApprovalDialogProps> = ({ content, onClose, open, title }: ApprovalDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.approvalDialog' });
  return (
    <Dialog open={open}>
      <Box sx={{ p: 3, pt: 1 }}>
        <Box
          sx={{
            py: 4.5,
            px: 3,
            width: 552,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: 44,
              height: 44,
              backgroundColor: (theme) => theme.palette.success.main,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              '& > svg': {
                width: 24,
                fill: (theme) => theme.palette.common.white,
              },
            }}
          >
            <CheckIcon />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            {title && (
              <Label variant="h5" textAlign="center">
                {title}
              </Label>
            )}
            {content && <DialogContentText align="center">{content}</DialogContentText>}
            <Button variant="contained" onClick={onClose}>
              {t('continue')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ApprovalDialog.defaultProps = {
  title: '',
  content: '',
};
