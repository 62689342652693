import { createContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { Logger, LoggerImpl } from '@connectlyai-tenets/logging';

export type LoggerDeps = {
  logger: Logger;
};

export const LoggerContext = createContext<LoggerDeps>({
  logger: new LoggerImpl(datadogLogs.createLogger('', { handler: ['http', 'console'] })),
});
