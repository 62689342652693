import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { submitSignUp } from './thunk';
import { resetStore } from './action';
const defaultErrorMessage = 'Some error happened. Contact Connectly for more information.';
const defaultSuccessMessage = 'Your account has been created. Please log in again.';
const initialState = {
    loadingState: LoadingState.UNINITIALIZED,
    nextAction: '',
    errorMessage: '',
    successMessage: '',
};
export const signUpSlice = createSlice({
    name: 'signUpSlice',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(submitSignUp.pending, (state, _action) => {
            Object.assign(state, initialState);
        });
        builder.addCase(submitSignUp.fulfilled, (state, _action) => {
            state.loadingState = LoadingState.FULL_LOADING;
            state.errorMessage = '';
            state.successMessage = defaultSuccessMessage;
        });
        builder.addCase(submitSignUp.rejected, (state, action) => {
            const payload = action.payload;
            state.loadingState = LoadingState.FULL_ERROR;
            state.nextAction = (payload === null || payload === void 0 ? void 0 : payload.nextAction) || '';
            state.errorMessage = (payload === null || payload === void 0 ? void 0 : payload.message) || defaultErrorMessage;
            state.successMessage = '';
        });
    },
});
export const selectSignUpState = (state) => state.signUp;
export const { reset } = signUpSlice.actions;
export default signUpSlice.reducer;
