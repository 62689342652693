import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  FLOW_EVENT_REQUEST_COMPILE_URL,
  FlowEventRequestCompileResponse,
  FlowEventRequestCompileOptions,
  FlowEventRequestCompileInput,
} from './types';

export const useEventRequestCompileReactFlowDocument = (options?: FlowEventRequestCompileOptions) => {
  return useMutation(
    (input: FlowEventRequestCompileInput) =>
      api.flowV1(FLOW_EVENT_REQUEST_COMPILE_URL, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }) as Promise<FlowEventRequestCompileResponse>,
    {
      ...options,
    },
  );
};
