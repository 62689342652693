import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Label,
  Switch,
  useTheme,
  VerifiedUserIcon,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIZE_CONTENT_L } from '../../ui-theme';
import { AbandonedCartEditDeliveryWindowProps } from './types';

export const AbandonedCartEditDeliveryWindow = ({
  enableSafeWindow,
  onSetSafeWindow,
}: AbandonedCartEditDeliveryWindowProps) => {
  const [enableSafeDelivery, setEnableSafeDelivery] = useState(enableSafeWindow);
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart.editIntegration' });
  const onSave = () => {
    onSetSafeWindow(enableSafeDelivery);
  };
  const handleSafeDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableSafeDelivery(event.target.checked);
  };
  return (
    <>
      <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Label variant="body1" sx={{ color: theme.palette.text.secondary, margin: 1, fontSize: '14px' }}>
          {t('safetyWindow.caption')}
        </Label>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: '10px',
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <VerifiedUserIcon
                sx={{ color: enableSafeDelivery ? theme.palette.success.light : theme.palette.warning.light }}
              />
              <Label variant="body1" sx={{ fontWeight: 500 }}>
                {enableSafeDelivery ? t('safetyWindow.enabled') : t('safetyWindow.disabled')}
              </Label>
            </Box>
            <Label variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
              {enableSafeDelivery ? t('safetyWindow.enabledMessage') : t('safetyWindow.disabledMessage')}
            </Label>
          </Box>
          <Switch checked={enableSafeDelivery} onChange={handleSafeDeliveryChange} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 3 }}>
        <Button variant="contained" onClick={onSave}>
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};
