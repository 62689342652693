import React, { useCallback } from 'react';
import {
  generateBroadcastRoute,
  generateFlowHome,
  generateAutomationsRoute,
  generateSofiaRoute,
  generateAnalyticsAccountOverviewRoute,
} from '@connectlyai-sdks/inbox-navigation';
import { useContextSelector } from 'use-context-selector';
import { NavigationContext } from '../../../../contexts';
import { ProductVariant } from '../../types';

export const useHomeNavigator = () => {
  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForActivitybar);

  const handleCreateCampaign = useCallback(() => {
    navigatorProvider().navigate(generateFlowHome());
  }, [navigatorProvider]);

  const handleOpenCampaigns = useCallback(() => {
    navigatorProvider().navigate(generateBroadcastRoute());
  }, [navigatorProvider]);

  const handleOpenAutomations = useCallback(() => {
    navigatorProvider().navigate(generateAutomationsRoute());
  }, [navigatorProvider]);

  const handleOpenSofia = useCallback(() => {
    navigatorProvider().navigate(generateSofiaRoute());
  }, [navigatorProvider]);

  const handleRedirectToAccountOverview = useCallback(() => {
    navigatorProvider().navigate(generateAnalyticsAccountOverviewRoute());
  }, [navigatorProvider]);

  return {
    handleOpenSofia,
    handleCreateCampaign,
    handleOpenCampaigns,
    handleOpenAutomations,
    handleRedirectToAccountOverview,
  };
};
