import { useMemo } from 'react';
import { Range, createStaticRanges } from '@connectlyai-tenets/ui-styled-web';
import { DatePeriodKey } from '@hooks/useDatePeriod';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DATE_RANGES } from './constants';

const offsetDate = (date: Date, days: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setUTCDate(date.getUTCDate() - days);
  return newDate;
};

export const buildDateRange = (period: DatePeriodKey, dayOffset = 0) => {
  const defaultRange = DATE_RANGES[period];
  const { startDate, endDate } = defaultRange;
  if (dayOffset) {
    return {
      startDate: offsetDate(startDate, dayOffset),
      endDate: offsetDate(endDate, dayOffset),
    };
  }
  return defaultRange;
};

// Hook used to generate list of possible date range list. Required to pre-load translation files
// Used this preset range to construct custom range lists with new values
// dayOffset is used to offset the date range by a certain number of days
// omitRangeAll is used to remove the 'all' range option from the list. There are case
// where we don't want a user to select everything e.g. in sendouts report
export const useDateRange = (dayOffset = 0, omitRangeAll = false) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.datePeriods' });
  const dateRangeList = useMemo(
    () => ({
      all: {
        label: t('all'),
        range: () => buildDateRange('all', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.all.startDate) && isSameDay(range.endDate, DATE_RANGES.all.endDate)
            );
          }
          return false;
        },
      },
      today: {
        label: t('today'),
        range: () => buildDateRange('today', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.today.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.today.endDate)
            );
          }
          return false;
        },
      },
      last7Days: {
        label: t('last7Days'),
        range: () => buildDateRange('last7Days', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.last7Days.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.last7Days.endDate)
            );
          }
          return false;
        },
      },
      last1Month: {
        label: t('last1Month'),
        range: () => buildDateRange('last1Month', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.last1Month.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.last1Month.endDate)
            );
          }
          return false;
        },
      },
      last3Months: {
        label: t('last3Months'),
        range: () => buildDateRange('last3Months', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.last3Months.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.last3Months.endDate)
            );
          }
          return false;
        },
      },
      last6Months: {
        label: t('last6Months'),
        range: () => buildDateRange('last6Months', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.last6Months.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.last6Months.endDate)
            );
          }
          return false;
        },
      },
      calMonth: {
        label: t('calMonth'),
        range: () => buildDateRange('calMonth', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.calMonth.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.calMonth.endDate)
            );
          }
          return false;
        },
      },
      prevCalMonth: {
        label: t('prevCalMonth'),
        range: () => buildDateRange('prevCalMonth', dayOffset),
        isSelected: (range: Range) => {
          if (range.startDate && range.endDate) {
            return (
              isSameDay(range.startDate, DATE_RANGES.prevCalMonth.startDate) &&
              isSameDay(range.endDate, DATE_RANGES.prevCalMonth.endDate)
            );
          }
          return false;
        },
      },
    }),
    [t, dayOffset],
  );

  const defaultDateRangeList = useMemo(() => {
    let ranges = [
      dateRangeList.today,
      dateRangeList.last7Days,
      dateRangeList.last1Month,
      dateRangeList.last3Months,
      dateRangeList.last6Months,
      dateRangeList.calMonth,
      dateRangeList.prevCalMonth,
    ];

    if (!omitRangeAll) {
      ranges = [dateRangeList.all, ...ranges];
    }
    return createStaticRanges(ranges);
  }, [
    omitRangeAll,
    dateRangeList.all,
    dateRangeList.today,
    dateRangeList.last7Days,
    dateRangeList.last1Month,
    dateRangeList.last3Months,
    dateRangeList.last6Months,
    dateRangeList.calMonth,
    dateRangeList.prevCalMonth,
  ]);

  const defaultDateRange = useMemo(
    () => ({
      ...dateRangeList.last7Days.range(),
    }),
    [dateRangeList.last7Days],
  );

  return {
    defaultDateRange,
    defaultDateRangeList,
  };
};
