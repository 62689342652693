import React, { useMemo, forwardRef } from 'react';
import { useFeatureFlag } from '@hooks';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { Box, useTheme, useMediaQuery, purple, blueGrey } from '@connectlyai-tenets/ui-styled-web';
import { EditorView } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { useFlowVariables } from '@hooks/useFlowVariables';
import { VariableViewPlugin } from './VariableViewPlugin';
import { FormatViewPlugin } from './FormatViewPlugin';
import { CodeMirrorTextFieldProps } from './types';
import { calcStyles } from './utils';

const singleLineTransactionFilter = () => {
  return EditorState.transactionFilter.of((tr) => (tr.newDoc.lines > 1 ? [] : tr));
};

// eslint-disable-next-line react/display-name
export const CodeMirrorTextField = forwardRef<ReactCodeMirrorRef, CodeMirrorTextFieldProps>(
  (
    {
      value,
      codeMirrorUpdate,
      handleVariableClick = () => {},
      setValue,
      editable,
      multiline,
      error,
      endAdornment,
      containerStyle,
      placeholder,
      displayFormatting,
      onKeyDown,
      minimized,
      isLoading = false,
    },
    ref,
  ) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const { fontSize, lineHeight } = useMemo(() => {
      return calcStyles(containerStyle, isLargeScreen);
    }, [containerStyle?.fontSize, containerStyle?.lineHeight, isLargeScreen]);

    const { variableTypeOf } = useFlowVariables();

    const variableViewPlugin = useMemo(
      () => VariableViewPlugin({ handleVariableClick, lineHeight, variableTypeOf }),
      [handleVariableClick, lineHeight, variableTypeOf],
    );

    const formatViewPlugin = useMemo(
      () => FormatViewPlugin({ lineHeight, variableTypeOf }),
      [lineHeight, variableTypeOf],
    );

    const extensions = useMemo(() => {
      const result = [];
      if (displayFormatting) {
        result.push(formatViewPlugin);
      } else {
        result.push(variableViewPlugin);
      }
      result.push(EditorView.lineWrapping);
      if (!multiline) {
        result.push(singleLineTransactionFilter());
      }
      return result;
    }, [variableViewPlugin, multiline, displayFormatting]);
    const height = useMemo(() => {
      if (minimized) return 'auto';
      if (!editable) return 'auto';
      if (multiline) return '182px';
      if (lineHeight) {
        return `${lineHeight}px`;
      }
      return 'auto';
    }, [editable, multiline, minimized]);

    const paddingY = useMemo(() => {
      if (minimized) return theme.spacing(0.5);
      if (multiline) return theme.spacing(1.5);
      if (isLargeScreen) return theme.spacing(1.32);
      return theme.spacing(1.33);
    }, [multiline, isLargeScreen, minimized, theme]);

    const borderColorLoading = isLoading ? 'transparent' : 'rgba(0, 0, 0, .23)';
    const borderColor = error ? theme.palette.error.main : borderColorLoading;

    return (
      <Box
        sx={{
          position: 'relative',
          boxSizing: 'border-box',
          width: '100%',
          '& .cm-scroller': {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
          },
          ...(editable
            ? {
                border: `1px solid ${borderColor}`,
                borderRadius: '10px',
                padding: minimized ? theme.spacing(0.5) : theme.spacing(1.5),
                paddingY,
                '&:hover': !isLoading && {
                  border: `1px solid ${blueGrey[300]}`,
                  backgroundColor: blueGrey[50],
                },
                '&:hover .cm-editor': !isLoading && {
                  backgroundColor: blueGrey[50],
                },
                '&:has(div > div.cm-focused)': {
                  border: `1px solid ${purple[700]}`,
                  outline: `1px solid ${purple[700]}`,
                },
                '& div.cm-focused': {
                  outline: `0px`,
                },
                '& .cm-cursors': {
                  visibility: 'hidden !important',
                  cursor: 'pointer',
                },
                '& .cm-content': {
                  paddingY: multiline ? undefined : 0,
                },
                '& .cm-editor .cm-placeholder': {
                  opacity: 0.42,
                  color: theme.palette.text.primary,
                },
              }
            : {}),
          ...containerStyle,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CodeMirror
            ref={ref}
            value={value}
            width="auto"
            height={height}
            basicSetup={{
              lineNumbers: false,
              foldGutter: false,
              highlightActiveLine: false,
              closeBrackets: false,
            }}
            placeholder={placeholder}
            extensions={extensions}
            onChange={setValue}
            onUpdate={codeMirrorUpdate}
            onKeyDown={onKeyDown}
            editable={editable}
          />
        </Box>
        {endAdornment && endAdornment}
      </Box>
    );
  },
);

CodeMirrorTextField.defaultProps = {
  multiline: false,
};
