import * as _m0 from "protobufjs/minimal";
import { BoolValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.tags";
export var TagAssocStatus;
(function (TagAssocStatus) {
    TagAssocStatus[TagAssocStatus["TAG_ASSOC_STATUS_UNSPECIFIED"] = 0] = "TAG_ASSOC_STATUS_UNSPECIFIED";
    TagAssocStatus[TagAssocStatus["TAG_ASSOC_STATUS_ACTIVE"] = 1] = "TAG_ASSOC_STATUS_ACTIVE";
    TagAssocStatus[TagAssocStatus["TAG_ASSOC_STATUS_SUGGESTED"] = 2] = "TAG_ASSOC_STATUS_SUGGESTED";
    TagAssocStatus[TagAssocStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TagAssocStatus || (TagAssocStatus = {}));
export function tagAssocStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "TAG_ASSOC_STATUS_UNSPECIFIED":
            return TagAssocStatus.TAG_ASSOC_STATUS_UNSPECIFIED;
        case 1:
        case "TAG_ASSOC_STATUS_ACTIVE":
            return TagAssocStatus.TAG_ASSOC_STATUS_ACTIVE;
        case 2:
        case "TAG_ASSOC_STATUS_SUGGESTED":
            return TagAssocStatus.TAG_ASSOC_STATUS_SUGGESTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return TagAssocStatus.UNRECOGNIZED;
    }
}
export function tagAssocStatusToJSON(object) {
    switch (object) {
        case TagAssocStatus.TAG_ASSOC_STATUS_UNSPECIFIED:
            return "TAG_ASSOC_STATUS_UNSPECIFIED";
        case TagAssocStatus.TAG_ASSOC_STATUS_ACTIVE:
            return "TAG_ASSOC_STATUS_ACTIVE";
        case TagAssocStatus.TAG_ASSOC_STATUS_SUGGESTED:
            return "TAG_ASSOC_STATUS_SUGGESTED";
        case TagAssocStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseTagConfigV1() {
    return { name: "", color: "" };
}
export const TagConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.color !== "") {
            writer.uint32(18).string(message.color);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.color = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            color: isSet(object.color) ? String(object.color) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.color !== undefined && (obj.color = message.color);
        return obj;
    },
};
function createBaseTagConfig() {
    return { configOneof: undefined, groups: [] };
}
export const TagConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            TagConfigV1.encode(message.configOneof.v1, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.groups) {
            writer.uint32(794).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configOneof = {
                        $case: "v1",
                        v1: TagConfigV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 99:
                    message.groups.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            configOneof: isSet(object.v1)
                ? { $case: "v1", v1: TagConfigV1.fromJSON(object.v1) }
                : undefined,
            groups: Array.isArray(object === null || object === void 0 ? void 0 : object.groups)
                ? object.groups.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? TagConfigV1.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        if (message.groups) {
            obj.groups = message.groups.map((e) => e);
        }
        else {
            obj.groups = [];
        }
        return obj;
    },
};
function createBaseTagInput() {
    return { tagId: "", businessId: "", config: undefined, isMutable: undefined };
}
export const TagInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tagId !== "") {
            writer.uint32(10).string(message.tagId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.config !== undefined) {
            TagConfig.encode(message.config, writer.uint32(26).fork()).ldelim();
        }
        if (message.isMutable !== undefined) {
            BoolValue.encode({ value: message.isMutable }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tagId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.config = TagConfig.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.isMutable = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tagId: isSet(object.tagId) ? String(object.tagId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            config: isSet(object.config)
                ? TagConfig.fromJSON(object.config)
                : undefined,
            isMutable: isSet(object.isMutable)
                ? Boolean(object.isMutable)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.tagId !== undefined && (obj.tagId = message.tagId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.config !== undefined &&
            (obj.config = message.config
                ? TagConfig.toJSON(message.config)
                : undefined);
        message.isMutable !== undefined && (obj.isMutable = message.isMutable);
        return obj;
    },
};
function createBaseTagAssociationInput() {
    return { tagId: "", businessId: "", entityId: "" };
}
export const TagAssociationInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tagId !== "") {
            writer.uint32(10).string(message.tagId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.entityId !== "") {
            writer.uint32(26).string(message.entityId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagAssociationInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tagId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.entityId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tagId: isSet(object.tagId) ? String(object.tagId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            entityId: isSet(object.entityId) ? String(object.entityId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tagId !== undefined && (obj.tagId = message.tagId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.entityId !== undefined && (obj.entityId = message.entityId);
        return obj;
    },
};
function createBaseTagAssociationNewEntityInput() {
    return { tagId: "", businessId: "" };
}
export const TagAssociationNewEntityInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tagId !== "") {
            writer.uint32(10).string(message.tagId);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagAssociationNewEntityInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tagId = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tagId: isSet(object.tagId) ? String(object.tagId) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tagId !== undefined && (obj.tagId = message.tagId);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        return obj;
    },
};
function createBaseTagDto() {
    return { id: "", businessId: "", config: undefined, isMutable: undefined };
}
export const TagDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.businessId !== "") {
            writer.uint32(18).string(message.businessId);
        }
        if (message.config !== undefined) {
            TagConfig.encode(message.config, writer.uint32(26).fork()).ldelim();
        }
        if (message.isMutable !== undefined) {
            BoolValue.encode({ value: message.isMutable }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.businessId = reader.string();
                    break;
                case 3:
                    message.config = TagConfig.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.isMutable = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            config: isSet(object.config)
                ? TagConfig.fromJSON(object.config)
                : undefined,
            isMutable: isSet(object.isMutable)
                ? Boolean(object.isMutable)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.config !== undefined &&
            (obj.config = message.config
                ? TagConfig.toJSON(message.config)
                : undefined);
        message.isMutable !== undefined && (obj.isMutable = message.isMutable);
        return obj;
    },
};
function createBaseTagDtoCollection() {
    return { entries: [] };
}
export const TagDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            TagDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(TagDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => TagDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? TagDto.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseTagAssociation() {
    return { id: "", tagId: "", status: 0, tag: undefined };
}
export const TagAssociation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.tagId !== "") {
            writer.uint32(18).string(message.tagId);
        }
        if (message.status !== 0) {
            writer.uint32(24).int32(message.status);
        }
        if (message.tag !== undefined) {
            TagDto.encode(message.tag, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagAssociation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tagId = reader.string();
                    break;
                case 3:
                    message.status = reader.int32();
                    break;
                case 4:
                    message.tag = TagDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            tagId: isSet(object.tagId) ? String(object.tagId) : "",
            status: isSet(object.status) ? tagAssocStatusFromJSON(object.status) : 0,
            tag: isSet(object.tag) ? TagDto.fromJSON(object.tag) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.tagId !== undefined && (obj.tagId = message.tagId);
        message.status !== undefined &&
            (obj.status = tagAssocStatusToJSON(message.status));
        message.tag !== undefined &&
            (obj.tag = message.tag ? TagDto.toJSON(message.tag) : undefined);
        return obj;
    },
};
function createBaseTagAssociationCollection() {
    return { entries: [] };
}
export const TagAssociationCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            TagAssociation.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTagAssociationCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(TagAssociation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => TagAssociation.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? TagAssociation.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
