import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const CREATE_COHORT_URL = '/internal/v1/businesses/{input.businessId}/generate/audience_cohorts';
type CreateCohortUrl = typeof CREATE_COHORT_URL;

export type CreateCohortInput = Paths['audienceV1'][CreateCohortUrl]['post']['parameters']['body']['input'];

export type CreateCohortVariables = CreateCohortInput & {
  businessId: string;
};

export type CreateCohortResponse = Paths['audienceV1'][CreateCohortUrl]['post']['responses']['200']['schema'];

export type CreateCohortOptions = Omit<
  UseMutationOptions<CreateCohortResponse, ConnectlyError, CreateCohortVariables, unknown>,
  'mutationKey' | 'mutationFn'
>;
