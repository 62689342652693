import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../../google/protobuf/wrappers";
export const protobufPackage = "models.profile.contact.dto";
function createBaseBusinessPhoneNumberCollection() {
    return { phoneNumbers: [] };
}
export const BusinessPhoneNumberCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.phoneNumbers) {
            BusinessPhoneNumber.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessPhoneNumberCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.phoneNumbers.push(BusinessPhoneNumber.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            phoneNumbers: Array.isArray(object === null || object === void 0 ? void 0 : object.phoneNumbers)
                ? object.phoneNumbers.map((e) => BusinessPhoneNumber.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.phoneNumbers) {
            obj.phoneNumbers = message.phoneNumbers.map((e) => e ? BusinessPhoneNumber.toJSON(e) : undefined);
        }
        else {
            obj.phoneNumbers = [];
        }
        return obj;
    },
};
function createBaseBusinessPhoneNumber() {
    return { phoneNumber: "", friendlyName: "", isoCountry: "", region: "" };
}
export const BusinessPhoneNumber = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.phoneNumber !== "") {
            writer.uint32(10).string(message.phoneNumber);
        }
        if (message.friendlyName !== "") {
            writer.uint32(18).string(message.friendlyName);
        }
        if (message.isoCountry !== "") {
            writer.uint32(26).string(message.isoCountry);
        }
        if (message.region !== "") {
            writer.uint32(34).string(message.region);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessPhoneNumber();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = reader.string();
                    break;
                case 2:
                    message.friendlyName = reader.string();
                    break;
                case 3:
                    message.isoCountry = reader.string();
                    break;
                case 4:
                    message.region = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
            friendlyName: isSet(object.friendlyName)
                ? String(object.friendlyName)
                : "",
            isoCountry: isSet(object.isoCountry) ? String(object.isoCountry) : "",
            region: isSet(object.region) ? String(object.region) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.friendlyName !== undefined &&
            (obj.friendlyName = message.friendlyName);
        message.isoCountry !== undefined && (obj.isoCountry = message.isoCountry);
        message.region !== undefined && (obj.region = message.region);
        return obj;
    },
};
function createBaseSearchPhoneNumbersInput() {
    return { isoCountry: "", areaCode: undefined };
}
export const SearchPhoneNumbersInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.isoCountry !== "") {
            writer.uint32(10).string(message.isoCountry);
        }
        if (message.areaCode !== undefined) {
            StringValue.encode({ value: message.areaCode }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSearchPhoneNumbersInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isoCountry = reader.string();
                    break;
                case 2:
                    message.areaCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            isoCountry: isSet(object.isoCountry) ? String(object.isoCountry) : "",
            areaCode: isSet(object.areaCode) ? String(object.areaCode) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.isoCountry !== undefined && (obj.isoCountry = message.isoCountry);
        message.areaCode !== undefined && (obj.areaCode = message.areaCode);
        return obj;
    },
};
function createBaseProvisionPhoneNumberInput() {
    return { selectorOneof: undefined };
}
export const ProvisionPhoneNumberInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.selectorOneof) === null || _a === void 0 ? void 0 : _a.$case) === "phoneNumber") {
            writer.uint32(10).string(message.selectorOneof.phoneNumber);
        }
        if (((_b = message.selectorOneof) === null || _b === void 0 ? void 0 : _b.$case) === "criteria") {
            SearchPhoneNumbersInput.encode(message.selectorOneof.criteria, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProvisionPhoneNumberInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.selectorOneof = {
                        $case: "phoneNumber",
                        phoneNumber: reader.string(),
                    };
                    break;
                case 2:
                    message.selectorOneof = {
                        $case: "criteria",
                        criteria: SearchPhoneNumbersInput.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            selectorOneof: isSet(object.phoneNumber)
                ? { $case: "phoneNumber", phoneNumber: String(object.phoneNumber) }
                : isSet(object.criteria)
                    ? {
                        $case: "criteria",
                        criteria: SearchPhoneNumbersInput.fromJSON(object.criteria),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e;
        const obj = {};
        ((_a = message.selectorOneof) === null || _a === void 0 ? void 0 : _a.$case) === "phoneNumber" &&
            (obj.phoneNumber = (_b = message.selectorOneof) === null || _b === void 0 ? void 0 : _b.phoneNumber);
        ((_c = message.selectorOneof) === null || _c === void 0 ? void 0 : _c.$case) === "criteria" &&
            (obj.criteria = ((_d = message.selectorOneof) === null || _d === void 0 ? void 0 : _d.criteria)
                ? SearchPhoneNumbersInput.toJSON((_e = message.selectorOneof) === null || _e === void 0 ? void 0 : _e.criteria)
                : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
