import { MessageNodeUIState } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';
import { substituteVariables } from '@hooks';
import { cloneDeep, set } from 'lodash';
import addHttps from 'src/utils/addHttps';

type Card = NonNullable<MessageNodeUIState['carousel']>['cards'][number];

const extractBody = (card: Card) => {
  if (!card.body) {
    return [];
  }
  return [{ body: { text: { text: substituteVariables(card.body) } } }];
};

const extractHeader = (card: Card) => {
  if (!card.header) {
    return [];
  }

  if (card.header.variable) {
    return [{ header: { media: { type: 'TYPE_IMAGE', example: [] } } }];
  }

  return [{ header: card.header }];
};

const extractButtons = (card: Card) => {
  if (!card.buttons) {
    return [];
  }
  return card.buttons.map((comp) => {
    if (comp.button?.url?.url) {
      const newComp = set(cloneDeep(comp), 'button.url.url', substituteVariables(comp.button.url.url));
      newComp!.button!.url!.url = addHttps(String(newComp!.button!.url!.url));
      if (newComp.button?.url?.trackedUrl) {
        return set(newComp, 'button.url.trackedUrl', newComp.button.url.url);
      }
      return newComp;
    }
    return comp;
  });
};

const mapCarousel = (state: MessageNodeUIState) => {
  const cards = state.carousel?.cards.map((card, cardIndex) => ({
    cardIndex,
    components: [...extractBody(card), ...extractHeader(card), ...extractButtons(card)],
  }));

  return [{ carousel: { cards } }];
};

export default mapCarousel;
