import { Box, Link } from '@connectlyai-tenets/ui-styled-web';
import React, { FC, PropsWithChildren, ChangeEventHandler } from 'react';

export const UploadButton: FC<PropsWithChildren & { onChange?: ChangeEventHandler<HTMLInputElement> }> = ({
  children,
  onChange,
}) => {
  return (
    <Link sx={{ position: 'relative' }}>
      {children}
      <Box
        component="input"
        type="file"
        sx={{ position: 'absolute', opacity: 0, top: 0, lef: 0, right: 0, bottom: 0, cursor: 'pointer' }}
        onChange={onChange}
        accept="image/jpeg, image/png, video/mp4"
      />
    </Link>
  );
};
