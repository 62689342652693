import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useState,
  SyntheticEvent,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Label,
  Popover,
  ClickAwayListener,
  Box,
  Tabs,
  Tab,
  useTheme,
  Autocomplete,
  TextField,
  InputAdornment,
  SearchIcon,
  Button,
} from '@connectlyai-tenets/ui-styled-web';
import type { FlowVariableType, FlowVariableValidator } from '@connectlyai-tenets/sdk/src';
import { sortedUniq } from 'lodash';
import { ViewUpdate } from '@codemirror/view';
import { useFlowVariables } from '@hooks';
import { variableFqn } from '@hooks/useFlowVariables';
import { PREDEFINED_VARIABLES } from './constants';
import { HandleVariableClickProps } from '../CodeMirrorTextField/types';
import { FlowVariableDialogProps, FlowVariableDialogRef } from './types';

export const useFlowVariableDialog = ({
  value,
  handleEditorChange,
  codeMirrorRef,
  kind,
  declaredAt,
  isSessionVariable,
  clearOnChange,
  allowOnlyNew,
}: FlowVariableDialogProps) => {
  const { variablesList, addVariable, canonicalize } = useFlowVariables();

  type TabPanel = 'standard' | 'fromAPIs';
  const [tab, setTab] = useState<TabPanel>('standard');
  const onChangeTab = useCallback((event: SyntheticEvent, newValue: TabPanel) => {
    setTab(newValue);
  }, []);

  const textFieldRef = useRef<HTMLInputElement>(null);
  const activeVariableRef = useRef<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [variable, setVariable] = useState('');
  const [variableLoc, setVariableLoc] = useState({ from: 0, to: 0 });

  const activeVariableFqns = useMemo(() => variablesList.map((val) => variableFqn(val)), [variablesList]);
  const isVariableNew = useMemo(() => !activeVariableFqns.includes(variable), [activeVariableFqns, variable]);

  const variableOptions = useMemo(() => {
    if (allowOnlyNew) return [];
    let sortedOptions = sortedUniq([...activeVariableFqns, ...PREDEFINED_VARIABLES].sort((a, b) => a.localeCompare(b)));

    // When adding a variable show an item with new item name, in variable dropdown
    sortedOptions = sortedOptions.includes(variable) ? sortedOptions : [variable, ...sortedOptions];

    return sortedOptions;
  }, [activeVariableFqns, allowOnlyNew, variable]);

  const error = useMemo(() => {
    if (
      allowOnlyNew &&
      variablesList.find((v) => v.simpleNameCanonical === canonicalize(variable) && v.declaredAt !== declaredAt)
    ) {
      return 'Variable already exists';
    }
    return '';
  }, [allowOnlyNew, variablesList, variable, canonicalize, declaredAt]);

  const handleClose = useCallback(
    (newVariable?: string) => {
      if (error) {
        setVariable('');
        return;
      }

      const simpleName = newVariable || variable;

      if (simpleName && !variablesList.some((v) => v.simpleNameCanonical === canonicalize(simpleName))) {
        const validators: FlowVariableValidator[] = [];
        let type: FlowVariableType = 'FLOW_TYPE_UNSPECIFIED';
        switch (kind) {
          case 'STRING_URL':
            validators.push({
              type: 'VALIDATOR_TYPE_URL',
              urlParams: {},
            });
            type = 'FLOW_TYPE_STRING';
            break;
          default:
            type = 'FLOW_TYPE_STRING';
        }
        const variableDeclaration = {
          id: uuidv4(),
          type,
          namespace: '',
          namespaceCanonical: '',
          simpleName,
          simpleNameCanonical: canonicalize(simpleName),
          validators,
          initializer: undefined,
          declaredAt,
          isSessionVariable,
        };
        addVariable(variableDeclaration);
      }

      setOpen(false);
      activeVariableRef.current = null;
      codeMirrorRef?.current?.view?.focus();
      codeMirrorRef?.current?.view?.dispatch({
        selection: { anchor: variableLoc.to },
      });
    },
    [
      addVariable,
      canonicalize,
      codeMirrorRef,
      declaredAt,
      error,
      isSessionVariable,
      kind,
      setOpen,
      variable,
      variableLoc.to,
      variablesList,
    ],
  );

  const [selection, setSelection] = useState({ from: 0, to: 0 });

  const handleCodeMirrorUpdate = useCallback(
    (viewUpdate?: ViewUpdate | undefined) => {
      if (viewUpdate && viewUpdate.selectionSet) {
        const mainSelection = viewUpdate.state.selection.main;
        setSelection({ from: mainSelection.from, to: mainSelection.to });
      }
    },
    [setSelection],
  );

  const handleOpenDialogFor = useCallback(
    ({ from, to, variable: v, dom }: Partial<HandleVariableClickProps>) => {
      if (from === undefined || to === undefined) setVariableLoc(selection);
      else setVariableLoc({ from, to });

      if (dom) activeVariableRef.current = dom;

      setVariable(v || '');
      setOpen(true);
    },
    [setOpen, setVariableLoc, setVariable, selection],
  );

  const handleOpenDialog = useCallback(() => {
    handleOpenDialogFor({});
  }, [handleOpenDialogFor]);

  const handleVariableClick = useCallback(
    (props: HandleVariableClickProps) => {
      handleOpenDialogFor(props);
    },
    [handleOpenDialogFor],
  );

  const handleChange = useCallback(
    (v: string, reason?: string) => {
      const trimmedValue = v.trim().replace(/{|}/g, '');
      const curlyValue = trimmedValue ? `{{${trimmedValue}}}` : '';

      setVariable(trimmedValue);

      if (clearOnChange) {
        setVariableLoc({ from: 0, to: curlyValue.length });
        handleEditorChange(curlyValue);
      } else {
        const newFullText = `${value.slice(0, variableLoc.from)}${curlyValue}${value.slice(variableLoc.to)}`;
        setVariableLoc({ from: variableLoc.from, to: variableLoc.from + curlyValue.length });
        handleEditorChange(newFullText);
      }

      // When variable is selected from list add it immediately and close variable dialog
      if (reason === 'selectOption') handleClose(trimmedValue);
    },
    [clearOnChange, handleClose, handleEditorChange, setVariable, setVariableLoc, value, variableLoc],
  );

  // when { is pressed open the text variable dialog
  useEffect(() => {
    if (selection.from !== selection.to) return;
    if (value[selection.from - 1] !== '{') return;
    if (value[selection.from] === '{' && value[selection.from + 1] !== '{') return;

    const newValue = `${value.slice(0, selection.from - 1)}${value.slice(selection.from)}`;
    handleEditorChange(newValue);

    setSelection({ from: selection.from - 1, to: selection.from - 1 });
    setVariableLoc({ from: selection.from - 1, to: selection.from - 1 });
    handleOpenDialogFor({ from: selection.from - 1, to: selection.from - 1 });
  }, [value, handleEditorChange, selection, setSelection, setVariableLoc, handleOpenDialogFor]);

  return {
    error,
    handleOpenDialog,
    handleClose,
    handleChange,
    handleCodeMirrorUpdate,
    handleVariableClick,
    isVariableNew,
    onChangeTab,
    open,
    tab,
    textFieldRef,
    variable,
    variableOptions,
  };
};

// eslint-disable-next-line react/display-name
export const FlowVariableDialog = forwardRef<FlowVariableDialogRef, FlowVariableDialogProps>((props, ref) => {
  const { anchorEl } = props;
  const {
    error,
    variableOptions,
    handleOpenDialog,
    handleClose,
    handleChange,
    handleCodeMirrorUpdate,
    handleVariableClick,
    isVariableNew,
    onChangeTab,
    open,
    tab,
    textFieldRef,
    variable,
  } = useFlowVariableDialog(props);

  useImperativeHandle(ref, () => ({
    handleVariableClick,
    handleCodeMirrorUpdate,
    handleOpenDialog,
  }));

  const theme = useTheme();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ '& .MuiPopover-paper': { borderRadius: '14px', maxWidth: theme.spacing(45) } }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <ClickAwayListener onClickAway={() => () => handleClose()}>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={onChangeTab}>
              <Tab label="Standard" value="standard" />
              <Tab label="From APIs" value="fromAPIs" disabled />
            </Tabs>
          </Box>
          <Label variant="body2" sx={{ color: 'text.secondary' }}>
            Variables that help personalize your campaign, for example: customer names or coupon codes.
          </Label>
          <Autocomplete
            fullWidth
            freeSolo
            value={variable}
            options={variableOptions}
            onChange={(_e, value, reason) => handleChange(value || '', reason)}
            onInputChange={(_e, value) => handleChange(value)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                error={Boolean(error)}
                helperText={error}
                ref={textFieldRef}
                placeholder="Select from below or type here"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!variable.trim() && (
                    <InputAdornment position="end">
                      {isVariableNew && (
                        <Button variant="contained" onClick={() => handleClose()} disabled={!!error}>
                          Create
                        </Button>
                      )}
                      {!isVariableNew && (
                        <Button variant="text" onClick={() => handleClose()} disabled={!!error}>
                          Insert
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                  onKeyDown: (e) => {
                    if (e.code === 'Enter' || e.code === 'BracketRight') {
                      handleClose();
                      e.preventDefault();
                    }
                    if (e.code === 'BracketLeft') {
                      e.preventDefault();
                    }
                  },
                }}
              />
            )}
            sx={{
              '& .MuiInputBase-root': { py: 1, px: 2, borderRadius: '10px' },
            }}
          />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
});
