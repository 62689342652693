import { agentStateAtom } from '@atoms/settings';
import { selectDisplayName, selectUserId, useMeData } from '@hooks';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

export const useUpdateAgentField = () => {
  const [agentState, setAgentState] = useAtom(agentStateAtom);
  const { data: userId } = useMeData({
    select: selectUserId,
  });
  const { data: displayName, isLoading: isAgentStateLoading } = useMeData({ select: selectDisplayName });

  useEffect(() => {
    setAgentState({
      id: userId ?? '',
      displayName: displayName ?? '',
    });
  }, [userId, displayName, setAgentState]);

  const updateAgentField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentState({
      ...agentState,
      [e.target.name]: e.target.value,
    });
  };
  return {
    id: agentState.id,
    displayName: agentState.displayName,
    isAgentStateLoading,
    updateAgentField,
  };
};
