import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CheckIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Label,
  LinearProgress,
  MessageVerificationIcon,
  ReportIcon,
  DotIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { TEMPLATE_CATEGORIES, DEFAULT_TEMPLATE_CATEGORY } from '@hooks/useTemplateCategory/constants';
import { TemplateCategory } from '@connectlyai-tenets/sdk';
import produce from 'immer';
import { useContextSelector } from 'use-context-selector';
import { FlowChartTemplateApprovalDialogProps } from './types';
import {
  selectFirstInvalidTemplate,
  selectHasPendingTemplates,
  selectHasRejectedTemplates,
  selectHasUnsentTemplates,
  selectRejectedTemplates,
  selectTemplateApprovalDialogOpen,
  selectUnsentMessageTemplatesIndex,
  UnsentMessageTemplate,
  setMessageTemplateFromNode,
} from '../../state/messageTemplates';
import { applyHook, getRejectedReasonText, getTemplateStatusColor } from '../../utils';
import { selectBusinessId, useFlowWaMessageTemplateResource, useMeData, useCreateNode } from '../../hooks';
import { selectNodes, selectLanguage } from '../../state/flow';
import { NodeObjectUpdate } from '../../hooks/useChangesQueue';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import appStore from '../../state/store';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import { selectFirstRejectedTemplate } from '../../state/messageTemplates/selectors';
import { denormalizeName } from '../../presentation/preview/utils';

type RejectedMessageTemplateProps = {
  template: UnsentMessageTemplate;
};

const RejectedMessageTemplate: FC<RejectedMessageTemplateProps> = ({ template }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Label variant="body1" fontWeight="fontWeightMedium">
        {denormalizeName(template.name)}
      </Label>
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <DotIcon
          sx={{
            width: '12px',
            height: '12px',
            color: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED'),
            mr: 0.25,
          }}
        />
        <Label sx={{ fontSize: 12, lineHeight: '16px', letterSpacing: 0.15 }}>Rejected by WhatsApp</Label>
      </Box>
      <Label color="textSecondary" sx={{ fontSize: 12, lineHeight: '16px', letterSpacing: 0.15 }}>
        {`Reason: ${getRejectedReasonText(template.rejectionReason?.reason)}`}
      </Label>
    </Box>
  );
};

const useFlowChartTemplateApprovalDialog = ({
  onNextState = () => {},
  onPreviousState = () => {},
  selectNode = () => {},
}: Partial<FlowChartTemplateApprovalDialogProps>): FlowChartTemplateApprovalDialogProps => {
  const nodes = useSelector(selectNodes);
  const templatesIndex = useCallback(() => selectUnsentMessageTemplatesIndex(appStore.getState()), []);
  const open = useSelector(selectTemplateApprovalDialogOpen);
  const hasPendingTemplates = useSelector(selectHasPendingTemplates);
  const hasRejectedTemplates = useSelector(selectHasRejectedTemplates);
  const hasUnsentTemplates = useSelector(selectHasUnsentTemplates);
  const language = useSelector(selectLanguage);
  const rejectedTemplates = useSelector(selectRejectedTemplates);
  const firstInvalidTemplate = useSelector(selectFirstInvalidTemplate);
  const firstRejectedTemplate = useSelector(selectFirstRejectedTemplate);

  const dispatch = useDispatch();

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { mutateCreateWaTemplate, mutateUpdateWaTemplate } = useFlowWaMessageTemplateResource();
  const { templateName, remoteTemplateNames } = useCreateNode();
  const { onNodeObjectChange } = useCampaignFlowChangeAppliers();

  const centerToNode = useContextSelector(FlowCampaignContext, (context) => context?.centerToNode);

  const handleSendTemplates = useCallback(() => {
    if (!businessId) {
      return;
    }

    // find duplicates names and assign a new name to them
    const templateNames: string[] = [...(remoteTemplateNames || [])];
    Object.entries(templatesIndex()).forEach(([nodeId, unsentMessageTemplate]) => {
      const name = unsentMessageTemplate?.name;
      if (name && !templateNames.includes(name)) {
        templateNames.push(name);
        return;
      }
      if (unsentMessageTemplate?.status !== undefined) return;

      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return;

      const newName = templateName();
      templateNames.push(newName);

      const updatedNode = produce(node, (draft) => {
        draft.data.v1.name = newName;
        if (draft.data.v1.messageTemplateInput) draft.data.v1.messageTemplateInput.name = newName;
        return draft;
      });

      const update: NodeObjectUpdate = {
        id: updatedNode.id,
        item: updatedNode,
      };
      onNodeObjectChange([update]);
      dispatch(setMessageTemplateFromNode({ node: updatedNode }));
    });

    Object.entries(templatesIndex())
      .filter(
        (x): x is [string, UnsentMessageTemplate] =>
          !!x[1] && (x[1].status === undefined || x[1].status === 'MESSAGE_TEMPLATE_STATUS_PENDING'),
      )
      .forEach(([nodeId, x]) => {
        const node = nodes.find((n) => n.id === nodeId);

        const templateCategory: TemplateCategory = TEMPLATE_CATEGORIES.includes(node?.data?.v1?.templateCategory)
          ? node?.data?.v1?.templateCategory
          : DEFAULT_TEMPLATE_CATEGORY;

        const { id, name } = x;
        if (id) {
          mutateUpdateWaTemplate({
            nodeId,
            businessId,
            byExternalTemplateId: {
              templateId: id,
            },
            templateComponents: x.templateComponents,
          });
        } else {
          mutateCreateWaTemplate({
            nodeId,
            businessId,
            template: { ...x, language: { code: language } },
            templateGroup: { id: name, name, category: templateCategory },
          });
        }
      });
  }, [
    businessId,
    dispatch,
    language,
    mutateCreateWaTemplate,
    mutateUpdateWaTemplate,
    nodes,
    onNodeObjectChange,
    remoteTemplateNames,
    templateName,
    templatesIndex,
  ]);

  const handleEditInvalidTemplate = useCallback(() => {
    onPreviousState();
    if (!firstInvalidTemplate) {
      return;
    }

    const [nodeId, template] = firstInvalidTemplate;
    if (!nodeId || !template) {
      return;
    }

    const actualNode = nodes.find((n) => n.id === nodeId);
    if (!actualNode) {
      return;
    }
    selectNode(nodeId);
    centerToNode(actualNode);
  }, [centerToNode, firstInvalidTemplate, nodes, onPreviousState, selectNode]);

  const handleEditRejectedTemplate = useCallback(() => {
    onPreviousState();
    if (!firstRejectedTemplate) {
      return;
    }

    const [nodeId, template] = firstRejectedTemplate;
    if (!nodeId || !template) {
      return;
    }

    const actualNode = nodes.find((n) => n.id === nodeId);
    if (!actualNode) {
      return;
    }
    selectNode(nodeId);
    centerToNode(actualNode);
  }, [centerToNode, firstRejectedTemplate, nodes, onPreviousState, selectNode]);

  return {
    hasInvalidTemplates: !!firstInvalidTemplate,
    hasPendingTemplates,
    hasRejectedTemplates,
    hasUnsentTemplates,
    onApprove: handleSendTemplates,
    onNextState,
    onPreviousState,
    onEditInvalidTemplate: handleEditInvalidTemplate,
    onEditRejectedTemplate: handleEditRejectedTemplate,
    open,
    rejectedTemplates,
    selectNode,
    templates: [],
  };
};

export const FlowChartTemplateApprovalDialogPresentation: FC<FlowChartTemplateApprovalDialogProps> = ({
  hasInvalidTemplates,
  hasPendingTemplates,
  hasRejectedTemplates,
  hasUnsentTemplates,
  onNextState,
  onPreviousState,
  onApprove,
  onEditInvalidTemplate,
  onEditRejectedTemplate,
  open,
  rejectedTemplates,
}) => {
  let dialogContent = null;
  if (hasInvalidTemplates) {
    dialogContent = (
      <>
        <DialogContent>
          <DialogContentText>Please correct template errors before approval.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditInvalidTemplate}>Ok</Button>
        </DialogActions>
      </>
    );
  } else if (hasPendingTemplates) {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <DialogContent sx={{ px: 3, py: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LinearProgress sx={{ width: 552 }} />
          <DialogContentText>In Progress</DialogContentText>
        </DialogContent>
        <DialogActions />
      </>
    );
  } else if (hasUnsentTemplates) {
    dialogContent = (
      <Box sx={{ px: 3, py: 5.5, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <MessageVerificationIcon />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Label variant="h5">Message Verification</Label>
          <DialogContentText align="center">
            Your messages will be submitted to WhatsApp server for automatic approvals. The review process takes only a
            seconds. Your campaign will not be created until it’s submitted to WhatsApp.
          </DialogContentText>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={onApprove}>
            Confirm
          </Button>
          <Button variant="text" onClick={onPreviousState}>
            Cancel
          </Button>
        </Box>
      </Box>
    );
  } else if (hasRejectedTemplates) {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <Box sx={{ p: 3, pt: 1 }}>
          <Box
            sx={{
              width: 552,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              py: 4.5,
            }}
          >
            <Box
              sx={{
                width: 44,
                height: 44,
                backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED'),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                '& > svg': {
                  width: 24,
                  fill: (theme) => theme.palette.common.white,
                },
              }}
            >
              <ReportIcon />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 2,
              }}
            >
              <Label variant="h5">
                {rejectedTemplates.length === 1
                  ? '1 message need your attention.'
                  : `${rejectedTemplates.length} messages need your attention.`}
              </Label>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    maxHeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: '10px',
                    backgroundColor: (theme) => theme.palette.grey[100],
                    px: 3,
                    py: 2,
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                  }}
                >
                  {rejectedTemplates.map((x) => (
                    <RejectedMessageTemplate key={x.name} template={x} />
                  ))}
                </Box>
              </Box>
              <Button variant="contained" onClick={onEditRejectedTemplate}>
                Edit Campaign
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    dialogContent = (
      <>
        <DialogTitle>Message Verification</DialogTitle>
        <Box sx={{ p: 3, pt: 1 }}>
          <Box
            sx={{
              py: 4.5,
              px: 3,
              width: 552,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 44,
                height: 44,
                backgroundColor: getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_APPROVED'),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                '& > svg': {
                  width: 24,
                  fill: (theme) => theme.palette.common.white,
                },
              }}
            >
              <CheckIcon />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Label variant="h5">All messages are approved!</Label>
              <Button variant="contained" onClick={onNextState}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={onPreviousState}>
      {dialogContent}
    </Dialog>
  );
};

export const FlowChartTemplateApprovalDialog = applyHook(
  FlowChartTemplateApprovalDialogPresentation,
  useFlowChartTemplateApprovalDialog,
);
