import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { CreateAudienceSegmentVariables, CreateAudienceSegmentResponse } from './types';

export const useCreateAudienceSegmentMutation = (
  options?: Omit<
    UseMutationOptions<CreateAudienceSegmentResponse, ConnectlyError, CreateAudienceSegmentVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, segmentId, input }: CreateAudienceSegmentVariables) =>
      api.audienceV1('/internal/v1/businesses/{input.businessId}/audience_segments/{input.segmentId}', 'put', {
        path: { 'input.businessId': businessId, 'input.segmentId': segmentId },
        body: { input },
      }) as Promise<CreateAudienceSegmentResponse>,
    options,
  );
