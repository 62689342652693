import { startCase } from 'lodash';
import { Node } from 'react-flow-renderer';
import { ButtonError } from '../../../../state/messageTemplates';
import { Warning, CompileResultItem } from './types';

export const mapNodesWithTemplateErrorsToWarning = ({
  node,
  part,
  error,
}: {
  node: Node;
  part: string;
  error: Partial<ButtonError>[] | string[] | string;
}): Warning => {
  const nodeName = node?.data?.v1?.messageTemplateInput?.name;
  const warning = `${startCase(part)} component in this message is incomplete.`;
  const key = `invalid-template-${node.id}`;
  let details;
  try {
    switch (part) {
      case 'buttons':
        details = (error as Partial<ButtonError>[])
          .map((buttonError) => Object.values(buttonError).join(', '))
          .filter(Boolean)
          .join(', ');
        break;
      case 'list':
        details = (error as string[]).join(', ');
        break;
      default:
        details = error as string;
        break;
    }
  } finally {
    details = 'Click on the message to fix errors.';
  }
  return { warning, node, nodeName, key, details };
};

export const mapUnconnectedButtonsToWarning = ({
  buttonIndex,
  label,
  node,
}: {
  buttonIndex: number;
  label: string;
  node: Node;
}): Warning => {
  const nodeName = node?.data?.v1?.messageTemplateInput?.name;
  const warning = `Button is not connected to a message.`;
  const key = `unconnected-button-${node.id}-${buttonIndex}`;
  const details = `Connect button ${label ? `"${label}" ` : ''}to a message`;
  return { warning, node, nodeName, buttonIndex, key, details };
};

export const mapStrayNodesToWarning = (node: Node): Warning => {
  const nodeName = node?.data?.v1?.messageTemplateInput?.name;
  const warning = 'This message is not linked to another message.';
  const key = `stray-node-${node.id}`;
  const details = 'Connect a button to this message.';
  return { warning, node, nodeName, key, details };
};

export const mapTemplateNotFoundToWarning = (node: Node): Warning => {
  const nodeName = node?.data?.v1?.name;
  const warning = 'This message does not exist anymore.';
  const details = 'Create a new message to replace this one.';
  const key = `template-not-found-${node.id}`;
  return { warning, node, nodeName, key, details };
};

export const mapNodesWithUnconfiguredTemplatesToWarning = (node: Node): Warning => {
  const nodeName = node?.data?.v1?.name;
  const warning = 'This message has no content.';
  const details = 'Click on the message to fill in content.';
  const key = `template-not-configured-${node.id}`;
  return { warning, node, nodeName, key, details };
};

export const mapNodesWithPendingTemplatesToWarning = (node: Node): Warning => {
  const nodeName = node?.data?.v1?.messageTemplateInput?.name;
  const warning = 'Pending Whatsapp approval for this message.';
  const details = 'Please wait until this message is approved.';
  const key = `template-pending-${node.id}`;
  return { warning, node, nodeName, key, details };
};

export const mapCompileResultErrorsToWarning = ({ node, error }: { node: Node; error: CompileResultItem }): Warning => {
  const nodeName = node?.data?.v1?.messageTemplateInput?.name;
  const warning = 'Compile error on node';
  const details = error.message || '';
  const key = `compile-error-${error.id}-${error.message}`;
  return { warning, node, nodeName, key, details };
};
