import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { AutocompleteChangeReason, Box, Button, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { getOneYearBackFromToday } from 'src/utils';
import CampaignAutocomplete from '@components/CampaignAutocomplete/CampaignAutocomplete';
import { CampaignOption } from '@components/CampaignAutocomplete';
import { selectBusinessId, useMeData, useCampaignsData, useAnalytics } from '../../hooks';
import { SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import {
  dateRangeAtom,
  selectedAtom,
  recentCampaignReportsDialogOpenAtom,
  generateCampaignReportDialogOpenAtom,
  campaignFilterV2Atom,
  sendoutsExportDataAtom,
} from '../../atoms/analytics';
import { DateRange, DateRangePicker } from '../../components/DateRangePicker';

type AnalyticsSendoutsOverviewControlsProps = Record<never, unknown>;

const useAnalyticsSendoutsOverviewControls = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const [campaignFilter, setCampaignFilter] = useAtom(campaignFilterV2Atom);
  const [sendoutsExportData, setSendoutsExportData] = useAtom(sendoutsExportDataAtom);
  const selected = useAtomValue(selectedAtom);
  const setRecentCampaignReportsDialogOpen = useSetAtom(recentCampaignReportsDialogOpenAtom);
  const setGenerateCampaignReportDialogOpen = useSetAtom(generateCampaignReportDialogOpenAtom);
  const { sendAnalytics } = useAnalytics();

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: campaigns } = useCampaignsData({
    select: (data) => data.entity?.campaigns || [],
    enabled: !!businessId,
    businessId: businessId || '',
  });

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDateRange(newDateRange);
    },
    [setDateRange],
  );

  const handleSelectToExportClick = useCallback(() => {
    sendAnalytics('analytics.campaigns.send_report_clicked');

    setSendoutsExportData(true);
    handleExportClose();
  }, [setSendoutsExportData, sendAnalytics]);

  const handleCancel = useCallback(() => {
    setSendoutsExportData(false);
  }, [setSendoutsExportData]);

  const campaignOptions = useMemo<CampaignOption[]>(() => {
    return campaigns?.map((campaign) => ({ id: campaign.id, label: campaign.originalName })) || [];
  }, [campaigns]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, value: string, _reason: string) => {
      setInputValue(value);
    },
    [setInputValue],
  );
  const visibleOptions = useMemo(() => {
    const filtered = campaignOptions.filter((option) =>
      (option.label || '').toLowerCase().includes(inputValue.toLowerCase()),
    );
    return filtered;
  }, [campaignOptions, inputValue]);

  const handleShowRecentCampaignReports = useCallback(() => {
    sendAnalytics('analytics.campaigns.view_past_reports_clicked');

    setRecentCampaignReportsDialogOpen(true);
    handleExportClose();
  }, [setRecentCampaignReportsDialogOpen, sendAnalytics]);

  const handleShowGenerateCampaignReport = useCallback(() => {
    setGenerateCampaignReportDialogOpen(true);
  }, [setGenerateCampaignReportDialogOpen]);

  const handleCampaignChange = useCallback(
    (_event: React.SyntheticEvent, value: CampaignOption[], _reason: AutocompleteChangeReason) => {
      setCampaignFilter(value);
    },
    [setCampaignFilter],
  );

  const handleSelectAll = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const visibleIds = visibleOptions.map((option: CampaignOption) => option.id || '');
      const selectedIds = campaignFilter.map((option: CampaignOption) => option.id || '');
      const checked = visibleIds.every((item: string) => selectedIds.includes(item));
      if (checked) {
        setCampaignFilter([]);
        return;
      }
      const indeterminate = visibleIds.some((item: string) => selectedIds.includes(item));
      if (indeterminate) {
        const newSelected = campaignFilter.filter((option) => !visibleIds.includes(option.id || ''));
        setCampaignFilter(newSelected);
        return;
      }
      const newItems = visibleOptions.filter((option) => !selectedIds.includes(option.id || ''));
      const newSelected = [...campaignFilter, ...newItems];
      setCampaignFilter(newSelected);
    },
    [campaignFilter, setCampaignFilter, visibleOptions],
  );

  return {
    anchorEl,
    campaignFilter,
    campaignOptions,
    dateRange,
    handleCampaignChange,
    handleCancel,
    handleDateRangeChange,
    handleExportClick,
    handleExportClose,
    handleInputChange,
    handleSelectAll,
    handleSelectToExportClick,
    handleShowGenerateCampaignReport,
    handleShowRecentCampaignReports,
    inputValue,
    numSelected: selected.length,
    sendoutsExportData,
    visibleOptions,
  };
};

export const AnalyticsSendoutsOverviewControls: FC<AnalyticsSendoutsOverviewControlsProps> = () => {
  const {
    campaignFilter,
    campaignOptions,
    dateRange,
    handleCampaignChange,
    handleCancel,
    handleDateRangeChange,
    handleInputChange,
    handleSelectAll,
    handleSelectToExportClick,
    handleShowGenerateCampaignReport,
    handleShowRecentCampaignReports,
    inputValue,
    numSelected,
    sendoutsExportData,
    visibleOptions,
  } = useAnalyticsSendoutsOverviewControls();
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });

  const oneYearBackLimit = getOneYearBackFromToday();

  const theme = useTheme();
  const hasFilter = useMemo(() => campaignFilter.length > 0, [campaignFilter]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
          pt: 1,
        }}
      >
        <DateRangePicker
          minDate={sendoutsExportData ? oneYearBackLimit : undefined}
          omitRangeAll={sendoutsExportData}
          dateRange={dateRange}
          setDateRange={handleDateRangeChange}
        />
        <CampaignAutocomplete
          hasFilter={hasFilter}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          campaignFilter={campaignFilter}
          campaignOptions={campaignOptions}
          handleCampaignChange={handleCampaignChange}
          handleSelectAll={handleSelectAll}
          visibleOptions={visibleOptions}
        />
      </Box>
      <Box
        sx={{
          pt: 1,
          display: 'flex',
          gap: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
        }}
      >
        {sendoutsExportData && (
          <Button
            variant="text"
            onClick={handleCancel}
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '12px',
              height: theme.spacing(4.75),
            }}
          >
            CANCEL
          </Button>
        )}
        {sendoutsExportData && (
          <Button
            variant="contained"
            disabled={numSelected === 0}
            onClick={handleShowGenerateCampaignReport}
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '12px',
              height: theme.spacing(4.75),
            }}
          >
            NEXT
          </Button>
        )}
        {!sendoutsExportData && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleShowRecentCampaignReports}
              sx={{
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: theme.spacing(4.75),
              }}
            >
              {(t('viewPastReports'), 'View past reports')}
            </Button>

            <Button
              variant="contained"
              onClick={handleSelectToExportClick}
              sx={{
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: theme.spacing(4.75),
              }}
            >
              {(t('sendReport'), 'Send report')}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
