import { createTheme } from '@mui/material/styles';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { shadows } from './shadows';
import { spacing } from './spacing';
import { purple, blueGrey, red, amber, green } from './colors';
const ffEnableAnalyticsRedesign = getFlagByName('ffEnableAnalyticsRedesign');
export const theme = createTheme({
    shadows: shadows,
    spacing,
    typography: {
        fontFamily: 'Arial, sans-serif',
        h1: {
            fontWeight: 700,
            fontSize: 96,
        },
        h2: {
            fontWeight: 700,
            fontSize: 60,
        },
        h3: {
            fontWeight: 700,
            fontSize: 40,
        },
        h4: {
            fontWeight: 700,
            fontSize: 28,
        },
        h5: {
            fontWeight: 600,
            fontSize: 20,
        },
        h6: {
            fontWeight: 600,
            fontSize: 16,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 14,
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: 12,
        },
        body1: {
            fontWeight: 400,
            fontSize: 14,
        },
        body2: {
            fontWeight: 400,
            fontSize: 12,
        },
    },
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[500],
            dark: blueGrey[700],
            light: blueGrey[100],
        },
        text: {
            primary: blueGrey[900],
            secondary: blueGrey[600],
        },
        action: {
            hover: blueGrey[50],
        },
        background: {
            default: `linear-gradient(to bottom, ${blueGrey[50]}, 70%, #fff)`,
            paper: '#fff',
        },
        mode: 'light',
        success: { main: green[600] },
        warning: { main: amber[900] },
        error: { main: red[600] },
        info: { main: purple[900] },
        divider: blueGrey[200],
        skyBlue: blueGrey[50],
        connectionLine: '#7986CB',
        midnightIndigo: '#360F9B',
        royalViolet: '#825DE1',
        yellow: '#FDD835',
        flowGradient: 'linear-gradient(180deg, rgba(121, 134, 203, 0.2) 0%, rgba(255, 255, 255, 0) 41.15%), #FFFFFF',
    },
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: blueGrey[500],
                    color: '#fff',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: blueGrey[50],
                    },
                    '&.Mui-selected': {
                        background: blueGrey[100],
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper': {
                        borderRadius: spacing(2),
                    },
                    '& .MuiDialogTitle-root': {
                        padding: spacing(3),
                    },
                    '& .MuiDialogContent-root': {
                        padding: spacing(3),
                    },
                    '& .MuiDialogActions-root': {
                        padding: spacing(3),
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: '7px',
                    marginRight: '-7px',
                    '& .MuiSwitch-thumb': {
                        boxShadow: 'none',
                    },
                    '& .MuiSwitch-switchBase': {
                        color: '#fff',
                    },
                    '& .Mui-checked.MuiSwitch-switchBase': {
                        color: '#fff',
                        transform: 'translateX(19px)',
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: blueGrey[300],
                    },
                    '&:hover .MuiSwitch-track': {
                        backgroundColor: blueGrey[400],
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                        backgroundColor: `${purple[700]} !important`,
                        opacity: '1 !important',
                    },
                },
                track: {
                    backgroundColor: blueGrey[200],
                    borderRadius: spacing(2.5),
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: spacing(1.5),
                    '& > div': {
                        lineHeight: '22px',
                    },
                },
                filledSuccess: {
                    backgroundColor: green[50],
                    border: `1px solid ${green[200]}`,
                    color: green[900],
                },
                filledError: {
                    backgroundColor: red[50],
                    border: `1px solid ${red[200]}`,
                    color: red[900],
                },
                filledWarning: {
                    backgroundColor: amber[50],
                    border: `1px solid ${amber[200]}`,
                    color: amber[900],
                },
                filledInfo: {
                    backgroundColor: purple[50],
                    border: `2px solid ${purple[200]}`,
                    color: purple[900],
                },
                standardSuccess: {
                    backgroundColor: '#fff',
                    border: `1px solid ${blueGrey[200]}`,
                    color: blueGrey[900],
                    boxShadow: shadows[3],
                    '& .MuiAlert-icon': {
                        color: green[700],
                        padding: 0,
                        '& svg': {
                            backgroundColor: green[50],
                            padding: spacing(1),
                            borderRadius: '50%',
                        },
                    },
                },
                standardError: {
                    backgroundColor: '#fff',
                    border: `1px solid ${blueGrey[200]}`,
                    color: blueGrey[900],
                    boxShadow: shadows[3],
                    '& .MuiAlert-icon': {
                        color: red[700],
                        padding: 0,
                        '& svg': {
                            backgroundColor: red[50],
                            padding: spacing(1),
                            borderRadius: '50%',
                        },
                    },
                },
                standardWarning: {
                    backgroundColor: '#fff',
                    border: `1px solid ${blueGrey[200]}`,
                    color: blueGrey[900],
                    boxShadow: shadows[3],
                    '& .MuiAlert-icon': {
                        color: amber[700],
                        padding: 0,
                        '& svg': {
                            backgroundColor: amber[50],
                            padding: spacing(1),
                            borderRadius: '50%',
                        },
                    },
                },
                standardInfo: {
                    backgroundColor: '#fff',
                    border: `1px solid ${blueGrey[200]}`,
                    color: blueGrey[900],
                    boxShadow: shadows[3],
                    '& .MuiAlert-icon': {
                        color: purple[700],
                        padding: 0,
                        '& svg': {
                            backgroundColor: purple[50],
                            padding: spacing(1),
                            borderRadius: '50%',
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-root': {
                        backgroundColor: blueGrey[50],
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-root': {
                        backgroundColor: blueGrey[50],
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    transition: 'border-color 150s',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: blueGrey[200],
                    },
                    '&:hover .MuiOutlinedInput-input': {
                        backgroundColor: blueGrey[50],
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: blueGrey[300],
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: purple[700],
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: red[600],
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: purple[700],
                    '&.Mui-checked': {
                        color: purple[700],
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: purple[700],
                    '&.Mui-checked': {
                        color: purple[700],
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    width: 'fit-content',
                    minHeight: spacing(5),
                    padding: 0,
                    backgroundColor: ffEnableAnalyticsRedesign ? '' : blueGrey[100],
                    borderRadius: ffEnableAnalyticsRedesign ? 0 : spacing(1),
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: ffEnableAnalyticsRedesign
                    ? {
                        textTransform: 'none',
                        padding: `0 ${spacing(1.25)} 0 ${spacing(1.25)}`,
                        borderBottom: `2px solid transparent`,
                        color: blueGrey[700],
                        '&.Mui-selected': {
                            borderBottom: `2px solid ${purple[500]}`,
                            color: blueGrey[900],
                        },
                    }
                    : {
                        textTransform: 'none',
                        minHeight: spacing(4),
                        borderRadius: 8,
                        padding: `0 ${spacing(1.25)} 0 ${spacing(1.25)}`,
                        margin: spacing(0.5),
                        color: blueGrey[700],
                        '&.Mui-selected': {
                            backgroundColor: '#ffffff',
                            border: `1px solid ${blueGrey[200]}`,
                            boxShadow: shadows[1],
                            color: blueGrey[900],
                        },
                    },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root:hover': {
                        background: blueGrey[100],
                    },
                    '&.MuiButtonBase-root.Mui-selected': {
                        backgroundColor: blueGrey[300],
                        '&.MuiButtonBase-root:hover': {
                            background: blueGrey[200],
                        },
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: blueGrey[500],
                    '&.Mui-active': {
                        color: purple[900],
                    },
                    '&.Mui-completed': {
                        color: purple[600],
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    userSelect: 'all',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: spacing(1.5),
                    backgroundColor: '#fff',
                    boxShadow: shadows[0],
                },
            },
            variants: [
                {
                    props: { variant: 'flow' },
                    style: {
                        position: 'relative',
                        padding: spacing(1.5),
                        display: 'flex',
                        gap: spacing(1),
                        borderRadius: spacing(1.25),
                        boxShadow: shadows[1],
                        alignItems: 'center',
                        '& > svg': {
                            fill: '#7986CB',
                        },
                        cursor: 'grab',
                    },
                },
                {
                    props: { variant: 'main' },
                    style: {
                        position: 'relative',
                        flex: '0 0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: spacing(1.5),
                        border: `1px solid ${blueGrey[200]}`,
                    },
                },
                {
                    props: { variant: 'action' },
                    style: {
                        flex: '0 0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: spacing(1),
                        borderRadius: spacing(1.5),
                    },
                },
            ],
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    '& .MuiCardHeader-title': {
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: spacing(0.5),
                    },
                    '& .MuiCardHeader-subheader': {
                        color: blueGrey[700],
                        fontSize: 14,
                        marginBottom: spacing(0.5),
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                },
            },
        },
        MuiCardActionArea: {
            defaultProps: {
                disableRipple: true,
                focusRipple: false,
            },
            styleOverrides: {
                root: {
                    border: `1px solid rgba(0, 0, 0, 0)`,
                    '&:hover': {
                        backgroundColor: blueGrey[50],
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: blueGrey[50],
                        background: blueGrey[50],
                        outline: `1px solid ${blueGrey[900]}`,
                        border: `1px solid ${blueGrey[900]}`,
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
            variants: [
                {
                    props: { variant: 'tag' },
                    style: {
                        textTransform: 'none',
                        background: '#fff',
                        borderRadius: 50,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 8,
                        paddingBottom: 8,
                    },
                },
                {
                    props: { variant: 'tag', color: 'secondary' },
                    style: {
                        background: '#007dff',
                        color: '#fff',
                        '&:hover': {
                            background: '#0069d9',
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: spacing(1),
                    '&:focus-visible': {
                        outline: `2px solid ${blueGrey[900]}`,
                    },
                },
                containedPrimary: {
                    backgroundColor: purple[700],
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: purple[800],
                    },
                    '&:active': {
                        backgroundColor: purple[900],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: blueGrey[300],
                    },
                },
                textPrimary: {
                    color: purple[900],
                },
                containedSecondary: {
                    backgroundColor: '#fff',
                    color: blueGrey[600],
                    '&:hover': {
                        backgroundColor: blueGrey[50],
                        color: blueGrey[900],
                    },
                    '&:active': {
                        backgroundColor: blueGrey[100],
                        color: blueGrey[900],
                    },
                    '&.Mui-disabled': {
                        color: blueGrey[300],
                        backgroundColor: '#fff',
                    },
                },
                outlinedPrimary: {
                    borderColor: purple[900],
                    '&:hover': {
                        backgroundColor: purple[50],
                    },
                    '&:active': {
                        backgroundColor: purple[100],
                    },
                    '&.Mui-disabled': {
                        borderColor: blueGrey[200],
                        color: blueGrey[300],
                    },
                },
                outlinedSecondary: {
                    backgroundColor: '#fff',
                    color: blueGrey[600],
                    '&:hover': {
                        backgroundColor: blueGrey[50],
                        color: blueGrey[900],
                    },
                    '&:active': {
                        backgroundColor: blueGrey[100],
                        color: blueGrey[900],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: blueGrey[100],
                        color: blueGrey[300],
                    },
                },
                outlinedError: {
                    backgroundColor: '#fff',
                    color: red[700],
                    '&:hover': {
                        backgroundColor: red[50],
                        color: red[900],
                    },
                    '&:active': {
                        backgroundColor: red[100],
                        color: red[900],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#fff',
                        color: blueGrey[300],
                    },
                },
                containedSuccess: {
                    backgroundColor: green[500],
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: green[600],
                    },
                    '&:active': {
                        backgroundColor: green[700],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: green[200],
                        color: green[600],
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h7: 'p',
                },
            },
            variants: [
                {
                    props: { variant: 'h7' },
                    style: {
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '20px',
                    },
                },
                {
                    props: { variant: 'inputLabel' },
                    style: {
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: '18px',
                    },
                },
                {
                    props: { variant: 'helperText' },
                    style: {
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: '18px',
                    },
                },
                {
                    props: { variant: 'inputText' },
                    style: {
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: '22px',
                    },
                },
                {
                    props: { variant: 'tooltip' },
                    style: {
                        fontWeight: 400,
                        fontSize: 10,
                        lineHeight: '16px',
                    },
                },
            ],
        },
    },
});
