export const JSON_TREE_THEME = {
  base00: '#FFFFFF',
  base01: '#3971ED',
  base02: '#B4B7B4',
  base03: '#3971ED',
  base04: '#3971ED',
  base05: '#198844',
  base06: '#A36AC7',
  base07: '#CC342B',
  base08: '#1D1F21',
  base09: '#FBA922',
  base0A: '#3971ED',
  base0B: '#198844',
  base0C: '#A36AC7',
  base0D: '#CC342B',
  base0E: '#373B41',
  base0F: '#FBA922',
};
