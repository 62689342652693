import { atom } from 'jotai';
import { DATA_LAG_DAYS } from '@scenes/Inbox/constants';
import { TimeRange, buildQueryRanges } from '../../utils/analytics';
import { DateRange } from '../../components/DateRangePicker';
import { buildDateRange } from '../../hooks/useDateRange';

export const dateRangeAtom = atom<DateRange>({
  ...buildDateRange('last7Days', DATA_LAG_DAYS),
  key: 'selection',
});

export const inboxAnalyticsTimeRangesAtom = atom<[TimeRange, TimeRange]>((get) => {
  const dateRange = get(dateRangeAtom);
  const defaultRange = { timeRangeStart: '', timeRangeEnd: '' };
  const queryRanges = buildQueryRanges(dateRange, 2);
  return [queryRanges[0] || defaultRange, queryRanges[1] || defaultRange];
});
