import React from 'react';
import { AbandonedCartBody, SHOPIFY_INTEGRATION_TYPE } from '@components/AbandonedCart';
import {
  Box,
  Button,
  ConnectlyCard,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Label,
  useTheme,
  CartIcon,
  ShopifyIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useAbandonedCart } from '@hooks/useAbandonedCart';
import { AbandonedCartActivation } from '@components/AbandonedCartActivation';
import { Loader } from '@connectlyai-sdks/components';
import { selectShopifyBusinessChannel, useBusinessChannelsData, useFeatureFlag } from '@hooks';
import { track } from 'src/utils';
import { SIZE_MEDIA_L, SIZE_SPACING_ANNOTATION, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

export const ShopifyAbandonedCart = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart' });
  const {
    businessId,
    integrationId,
    language,
    discount,
    reminder,
    firstMessage,
    secondMessage,
    discountAmount,
    isActivated,
    deactivatePopupOpen,
    isLoading,
    integrationParams,
    open,
    isDeactivating,
    status,
    onDeactivate,
    onOpen,
    onClose,
    onChangeDeactivatePopup,
  } = useAbandonedCart(SHOPIFY_INTEGRATION_TYPE);
  const { data: shopifyBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectShopifyBusinessChannel,
  });
  const { ffHideShopifySetup } = useFeatureFlag(['ffHideShopifySetup']);

  // Get template status
  const ActivationBody = (
    <>
      <Label variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {t('description')}
      </Label>
      <Button
        sx={{ width: theme.spacing(12) }}
        variant="contained"
        onClick={() => {
          track('(AC) Activate', { businessId });
          onOpen();
        }}
        disabled={isDeactivating}
      >
        {t('activateNow')}
      </Button>
    </>
  );

  if (ffHideShopifySetup && !language) {
    return null;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Dialog open={deactivatePopupOpen} onClose={() => onChangeDeactivatePopup(true)}>
        <DialogTitle>{t('deactivateAbandonedCart.deactivate')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-deactivate-description">{t('deactivateAbandonedCart.body')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onChangeDeactivatePopup(true)}>{t('deactivateAbandonedCart.cancel')}</Button>
          <Button onClick={onDeactivate}>{t('deactivateAbandonedCart.ok')}</Button>
        </DialogActions>
      </Dialog>
      <AbandonedCartActivation
        open={open}
        integrationParameters={integrationParams}
        reminder={reminder}
        firstMessageTemplate={firstMessage}
        secondMessageTemplate={secondMessage}
        integrationType={SHOPIFY_INTEGRATION_TYPE}
        onClose={onClose}
      />
      <ConnectlyCard
        media={{
          children: (
            <Box
              sx={{
                background: theme.palette.grey.A100,
                borderRadius: '16px',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                gap: SIZE_SPACING_ANNOTATION,
              }}
            >
              <ShopifyIcon width={theme.spacing(2)} />
              <Label variant="body2">Shopify</Label>
            </Box>
          ),
        }}
        title={t('title')}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: '1 0',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          {language ? (
            <AbandonedCartBody
              language={language}
              integrationId={integrationId}
              businessChannelId={shopifyBusinessChannel?.id ?? ''}
              discount={discount}
              status={status}
              discountAmount={discountAmount}
              isActivated={isActivated}
              isDeactivating={isDeactivating}
              onOpen={onOpen}
              onChangeDeactivatePopup={onChangeDeactivatePopup}
            />
          ) : (
            ActivationBody
          )}
        </Box>
        <Box sx={{ position: 'absolute', right: theme.spacing(2), bottom: theme.spacing(2) }}>
          <CartIcon width={theme.spacing(SIZE_MEDIA_L)} />
        </Box>
      </ConnectlyCard>
    </>
  );
};
