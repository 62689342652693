import { TemplateType } from '../../../../sdks/flow/createNode';

const negativeTemplateSet = new Set<TemplateType | undefined>([
  'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE',
  'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE',
]);

export const hasTemplateSelector = (type?: TemplateType) => !negativeTemplateSet.has(type);

export const hasHeader = (type: TemplateType | undefined) => {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return false;
    }
    default: {
      return false;
    }
  }
};

const positiveBodySet = new Set<TemplateType | undefined>([
  undefined,
  'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
  'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
  'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
  'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE',
  'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE',
]);

export const hasBody = (type?: TemplateType) => positiveBodySet.has(type);

export const hasFooter = (type: TemplateType | undefined) => {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const hasButtons = (type: TemplateType | undefined) => {
  if (type === undefined) {
    return true;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return true;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return false;
    }
    default: {
      return false;
    }
  }
};

export const hasList = (type: TemplateType | undefined) => {
  if (type === undefined) {
    return false;
  }

  switch (type) {
    case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
      return false;
    }
    case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const hasCarousel = (type?: TemplateType) => type === 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE';
