import { SendoutAnalytics } from '@hooks/useQuerySendoutAnalytics/types';

export const sortByDescendingDate = (array: SendoutAnalytics[]) => {
  return array.sort((a: SendoutAnalytics, b: SendoutAnalytics) => {
    const dateA = new Date(a.sendoutCompletedAt as string);
    const dateB = new Date(b.sendoutCompletedAt as string);
    return Number(dateB) - Number(dateA);
  });
};

export const getSecondsToMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = Math.round(seconds) % 60;
  const paddedSeconds = `0${secondsRemainder}`.slice(-2);
  return `${minutes}:${paddedSeconds}`;
};
