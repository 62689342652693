import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const QUERY_SENDOUT_ANALYTICS_URL =
  '/internal/v1/businesses/{query.businessId}/query_sendout_analytics/sendouts';
type QuerySendoutAnalyticsURL = typeof QUERY_SENDOUT_ANALYTICS_URL;

export type QuerySendoutAnalyticsResponse =
  Paths['analyticsV1'][QuerySendoutAnalyticsURL]['post']['responses']['200']['schema'];
export type SendoutAnalytics = NonNullable<
  NonNullable<NonNullable<QuerySendoutAnalyticsResponse['entity']>['items']>[number]
>;
export type QuerySendoutAnalyticsInput =
  Paths['analyticsV1'][QuerySendoutAnalyticsURL]['post']['parameters']['body']['query'];
export type QuerySendoutAnalyticsParams = { businessId: string } & QuerySendoutAnalyticsInput;

export type QuerySendoutAnalyticsOptions = Omit<
  UseQueryOptions<
    QuerySendoutAnalyticsResponse,
    ConnectlyError,
    QuerySendoutAnalyticsResponse,
    readonly ['sendout_analytics', QuerySendoutAnalyticsParams]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;
