import { NodeType } from './createNode';

export type HandleActionType =
  | 'on-execute' // Typical input handle for actions
  | 'on-happens' // Typical input handle for events
  | 'send' // Output handle/event for sending a sendout
  | 'button-click' // Output handle/event for clicking buttons
  | 'start' // Typical output handle/event for custom start nodes
  | 'event' // Typical output handle/event for event nodes
  | 'after-execute'; // Typical output handle/event for action nodes

export type HandleType = {
  nodeType?: NodeType;
  nodeId: string;
  actionType: HandleActionType;
  buttonIndex?: number;
  cardIndex?: number;
};

export const createHandleId = ({ nodeId, actionType, buttonIndex, cardIndex }: HandleType): string => {
  if (actionType === 'button-click' && cardIndex !== undefined) {
    return `${nodeId}:${actionType}:${cardIndex}.${buttonIndex}`;
  }
  if (actionType === 'button-click') {
    return `${nodeId}:${actionType}:${buttonIndex}`;
  }
  return `${nodeId}:${actionType}`;
};
