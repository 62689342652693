import { MessageTemplateGroupsResponse, MessageTemplate, MessageTemplateGroup, SelectedMessageTemplate } from './types';

// On templateGroups data and several other endpoints
// template.id used to be as same as template.externalTemplate.whatsapp.id
// but now template.id is an uuid instead of whatsapp assigned 16 digit number
//
// Examples
// template.id : 922a45ab-aebd-4061-9456-791fd0494347
// template.externalTemplate.whatsapp.id : 1337215083868993
//
// So for backwards compatibility of flow documents
// we need to compare template ids in the documents
// to both of these values while finding a template by id

export const selectApprovedMessageTemplateIds = (data: MessageTemplateGroupsResponse) =>
  data.entity?.templateGroups?.reduce<string[]>((templates, current) => {
    current.templates?.forEach((template) => {
      if (template.status === 'MESSAGE_TEMPLATE_STATUS_APPROVED') {
        if (template.id) {
          templates.push(template.id);
        }
        // see comment at the top of the file
        // commenting this out for this selector is only used for template selector
        // uncomment if we need to use this selector as a hashmap for all template ids
        // if (template?.externalTemplate?.whatsapp?.id) {
        //   templates.push(template?.externalTemplate?.whatsapp?.id);
        // }
      }
    });

    return templates;
  }, []) || [];

export const selectMessageTemplateById = (templateId: string) => (data: MessageTemplateGroupsResponse) => {
  let returnTemplate: SelectedMessageTemplate | undefined;

  data.entity?.templateGroups?.forEach((group) => {
    group.templates?.forEach((template) => {
      // see comment at the top of the file
      if (template.id === templateId || template?.externalTemplate?.whatsapp?.id === templateId) {
        returnTemplate = { group, template };
      }
    });
  });

  return returnTemplate;
};

export const selectMessageTemplateByIds = (templateIds: string[]) => (data: MessageTemplateGroupsResponse) => {
  const returnTemplates: SelectedMessageTemplate[] = [];

  data.entity?.templateGroups?.forEach((group) => {
    group.templates?.forEach((template) => {
      if (
        templateIds.includes(template.id ?? '') ||
        // see comment at the top of the file
        templateIds.includes(template?.externalTemplate?.whatsapp?.id ?? '')
      ) {
        returnTemplates.push({ group, template });
      }
    });
  });

  return returnTemplates;
};

export const selectMessageTemplateIndex = (data: MessageTemplateGroupsResponse) => {
  const index: Record<string, { group: MessageTemplateGroup; template: MessageTemplate }> = {};

  data.entity?.templateGroups?.forEach((group) => {
    group.templates?.forEach((template) => {
      if (template.id) {
        index[template.id] = { group, template };
      }

      // see comment at the top of the file
      if (template?.externalTemplate?.whatsapp?.id) {
        index[template.externalTemplate.whatsapp.id] = { group, template };
      }
    });
  });

  return index;
};

export const selectAllMessageTemplateNames = (data: MessageTemplateGroupsResponse) => {
  const names: string[] = [];
  data.entity?.templateGroups?.forEach((group) => {
    group.templates?.forEach((template) => {
      if (template.externalTemplate?.whatsapp?.name) {
        names.push(template.externalTemplate?.whatsapp?.name);
      }
    });
  });

  return names;
};
