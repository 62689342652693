export const BROADCAST_DID_INIT = 'Broadcast init';
export const BROADCAST_DID_RENDER = 'Broadcast rendered';
export const GOTO_VAR_SHEET = 'goto var_sheet';
export const VERIFY_CAMPAIGN = 'verify campaign';
export const VERIFY_CAMPAIGN_ERROR = 'verify_campaign error';
export const SCHEDULE_CAMPAIGN = 'schedule campaign';
export const SCHEDULE_CAMPAIGN_ERROR = 'schedule_campaign error';
export const SCHEDULE_CAMPAIGN_STARTED = 'schedule_campaign started';
export const SUBMIT_CAMPAIGN = 'submit campaign';
export const SUBMIT_CAMPAIGN_ERROR = 'submit_campaign error';
export const CREATE_AUDIENCE = 'create audience';
export const CREATE_AUDIENCE_ERROR = 'create_audience error';
export const UPDATE_AUDIENCE = 'update audience';
export const UPDATE_AUDIENCE_ERROR = 'update_audience error';
export const CREATE_TEMPLATE = 'create template';
export const CREATE_TEMPLATE_ERROR = 'create_template error';
