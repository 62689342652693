import { slice } from './slice';

export const {
  approveConnectlyTemplates,
  checkCurrentTemplateErrors,
  normalize,
  recalculateAllErrors,
  save,
  setTextHeader,
  setMediaHeaderExample,
  setMediaHeaderMapping,
  setBody,
  setFooter,
  setButtons,
  setButtonsGroup,
  setHeaderType,
  setName,
  setIsComponentEnabled,
  setList,
  setTemplateId,
  setPreviewTemplateId,
  setTemplateApprovalDialogOpen,
  setMessageTemplateStatusUpdate,
  replaceUnsentMessageTemplate,
  setMessageTemplateStatus,
  setMessageTemplateFromNode,
  setMappings,
  setTemplateComponents,
} = slice.actions;
