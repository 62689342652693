import React from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { useSelector } from 'react-redux';
import { SMBOnboardingContentProps } from './types';
import { WhatsAppOnboarding } from '../WhatsAppOnboarding';
import { ShopifyOnboarding } from '../ShopifyOnboarding';
import { PaymentOnboarding } from '../PaymentOnboarding';
import { selectStep } from '../../features/welcome';

export const SMBOnboardingContent = ({ onNextStep, shopify, whatsApp }: SMBOnboardingContentProps) => {
  let content = null;
  const step = useSelector(selectStep);

  switch (step) {
    case 'phoneNumberSelector':
    case 'phoneNumberOld':
    case 'businessName':
    case 'businessProfilePicture':
    case 'connectWhatsApp': {
      content = <WhatsAppOnboarding onNextStep={onNextStep} whatsApp={whatsApp} />;
      break;
    }
    case 'connectShopify': {
      content = <ShopifyOnboarding onNextStep={onNextStep} shopify={shopify} />;
      break;
    }
    case 'payment': {
      content = <PaymentOnboarding />;
      break;
    }
    default: {
      break;
    }
  }

  return <Box sx={{ flex: '1 1 auto' }}>{content}</Box>;
};
