import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UseCampaignName, UseCampaignNameParams, CampaignNameHintColor } from './types';
import { useDebounce } from '../useDebounce';
import { CAMPAIGN_NAME_DEBOUNCE_DELAY, CAMPAIGN_NAME_VALID_CHARS_REGEXP } from './constants';
import {
  selectCampaignName,
  selectFlowState,
  selectIsAISidebarOpen,
  selectIsCampaignNameDialogOpen,
  selectSavedCampaignName,
  setCampaignName,
  setIsCampaignNameDialogOpen as setIsCampaignNameDialogOpenAction,
} from '../../state/flow';

export const useCampaignName = ({
  afterNameChange,
  isCampaignNameTaken,
  isCreateFlowDocumentSuccess,
  goBackToPreface,
  onFlowConfirmForCampaignName,
}: UseCampaignName): UseCampaignNameParams => {
  const campaignName = useSelector(selectCampaignName);
  const flowState = useSelector(selectFlowState);
  const savedCampaignName = useSelector(selectSavedCampaignName);
  const isCampaignNameDialogOpen = useSelector(selectIsCampaignNameDialogOpen);
  const isAISidebarOpen = useSelector(selectIsAISidebarOpen);
  const dispatch = useDispatch();
  const setIsCampaignNameDialogOpen = useCallback(
    (b: boolean) => dispatch(setIsCampaignNameDialogOpenAction(b)),
    [dispatch],
  );

  const [campaignNameInput, setCampaignNameInput] = useState(campaignName);
  const [oldCampaignName, setOldCampaignName] = useState('');

  const trim = (s: string) => s.replace(/  +/g, ' ').trim();

  useDebounce(
    () => {
      dispatch(setCampaignName(trim(campaignNameInput)));
    },
    CAMPAIGN_NAME_DEBOUNCE_DELAY,
    [dispatch, campaignNameInput],
  );

  const hasInvalidChars = useMemo(() => !CAMPAIGN_NAME_VALID_CHARS_REGEXP.test(campaignNameInput), [campaignNameInput]);

  const [campaignNameColor, campaignNameHint]: [CampaignNameHintColor, string] = useMemo(
    () =>
      campaignNameInput === ''
        ? [undefined, '']
        : hasInvalidChars
          ? ['error', 'Please only use letters, numbers, spaces, dots and underscores in campaign names.']
          : isCampaignNameTaken
            ? ['error', 'This name is already taken. Please choose another one.']
            : isCreateFlowDocumentSuccess
              ? ['success', 'Campaign name is available.']
              : [undefined, ''],
    [campaignNameInput, hasInvalidChars, isCampaignNameTaken, isCreateFlowDocumentSuccess],
  );

  const isCampaignNameDialogConfirmDisabled = useMemo(
    () =>
      campaignName === '' ||
      campaignName !== trim(campaignNameInput) ||
      campaignName !== savedCampaignName ||
      isCampaignNameTaken ||
      hasInvalidChars,
    [campaignName, campaignNameInput, isCampaignNameTaken, hasInvalidChars, savedCampaignName],
  );

  const canOpenCampaignNameDialog = useMemo(
    () => !isCampaignNameDialogOpen && flowState === 'buildCampaign' && !isAISidebarOpen,
    [flowState, isAISidebarOpen, isCampaignNameDialogOpen],
  );

  const openCampaignNameDialog = useCallback(() => {
    setOldCampaignName(campaignName);
    setCampaignNameInput(campaignName);
    setIsCampaignNameDialogOpen(true);
  }, [campaignName, setIsCampaignNameDialogOpen, setOldCampaignName]);

  const handleCampaignNameDialogSave = useCallback(() => {
    onFlowConfirmForCampaignName();

    setIsCampaignNameDialogOpen(false);
    afterNameChange();
  }, [afterNameChange, onFlowConfirmForCampaignName, setIsCampaignNameDialogOpen]);

  const handleCampaignNameDialogCancel = useCallback(() => {
    setIsCampaignNameDialogOpen(false);
    if (oldCampaignName === '') {
      // if oldCampaignName is empty, it means that the user has not yet started the campaign
      // return to preface
      goBackToPreface();
    } else {
      dispatch(setCampaignName(oldCampaignName));
    }
  }, [dispatch, goBackToPreface, oldCampaignName, setIsCampaignNameDialogOpen]);

  const resetNames = useCallback(() => {
    setOldCampaignName('');
    setCampaignNameInput('');
  }, [setOldCampaignName, setCampaignNameInput]);

  return {
    canOpenCampaignNameDialog,
    campaignNameColor,
    campaignNameHint,
    campaignNameInput,
    handleCampaignNameDialogCancel,
    handleCampaignNameDialogSave,
    hasInvalidChars,
    isCampaignNameDialogOpen,
    isCampaignNameDialogConfirmDisabled,
    oldCampaignName,
    openCampaignNameDialog,
    resetNames,
    setCampaignNameInput,
    setOldCampaignName,
  };
};
