import { Role, MeResponse } from './types';

export const selectUserId = (data: MeResponse) => data.entity?.id;
export const selectBusinessId = (data: MeResponse) => data.entity?.businesses?.[0].id;
export const selectBusinessInfo = (data: MeResponse) => {
  return {
    businessId: data.entity?.businesses?.[0].id || '',
    name: data.entity?.businesses?.[0].name || '',
  };
};
export const selectDisplayName = (data: MeResponse) => data.entity?.displayName;
export const selectUsername = (data: MeResponse) => data.entity?.username;
export const selectRole = (data: MeResponse): Role => {
  const roles = data.entity?.roles || [];
  if (roles.includes('ROLE_OWNER')) return 'ROLE_OWNER';
  if (roles.includes('ROLE_MARKETING')) return 'ROLE_MARKETING';
  return 'ROLE_AGENT';
};
