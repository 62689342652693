import React, { useCallback, useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import {
  campaignStepAtom,
  campaignNameAtom,
  documentIdAtom,
  documentTypeAtom,
  duplicateDocumentAtom,
  getStartedCategoryAtom,
  previewDocumentIdAtom,
  starterAtom,
} from '@atoms/flow';
import {
  Box,
  Button,
  StartFromScratchIcon,
  CircularProgress,
  CloseIcon,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  CheckIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { Link } from 'react-router-dom';
import { selectBusinessId, useMeData, useAnalytics, useFeatureFlag } from '@hooks';
import { FlowChartHeader } from '@components/FlowChartHeader';
import { useListCampaignsPresetsData } from '../../hooks/useListCampaignsPresetsData';
import { ReadOnlyFlow } from '../ReadOnlyFlow';
import { useGenerateCampaignName } from '../../hooks/useGenerateCampaignName';
import { removeTemplateIds } from './utils';

export const useCampaignPresets = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const [previewDocumentId, setPreviewDocumentId] = useAtom(previewDocumentIdAtom);
  const setCategory = useSetAtom(getStartedCategoryAtom);
  const setDocumentType = useSetAtom(documentTypeAtom);
  const setDocumentId = useSetAtom(documentIdAtom);
  const setCampaignStep = useSetAtom(campaignStepAtom);
  const setCampaignName = useSetAtom(campaignNameAtom);
  const setDuplicateDocument = useSetAtom(duplicateDocumentAtom);
  const setStarter = useSetAtom(starterAtom);

  const { generateCampaignName, isLoadingCampaigns } = useGenerateCampaignName();

  const { data: campaignPresets, isLoading: isLoadingCampaignPresets } = useListCampaignsPresetsData({
    businessId: businessId as string,
    enabled: !!businessId,
  });

  const selectedPreset = useMemo(() => {
    return campaignPresets?.entity?.entries?.find((entry) => entry.id === previewDocumentId);
  }, [campaignPresets, previewDocumentId]);

  const flowObjects = useMemo(
    () => ({
      nodes:
        selectedPreset?.nodes?.map((node) => (node.options ? removeTemplateIds(JSON.parse(node.options)) : {})) || [],

      edges: selectedPreset?.edges?.map((edge) => (edge.options ? JSON.parse(edge.options) : {})) || [],
    }),
    [selectedPreset],
  );

  const handleCloseCampaignPresets = useCallback(() => {
    setCategory('new-campaign');
  }, [setCategory]);

  const campaignPresetsList = useMemo(
    () => campaignPresets?.entity?.entries?.map((preset) => ({ flowDocumentId: preset.id, name: preset.name })) || [],
    [campaignPresets],
  );

  const handleCampaignPresetClick = useCallback(
    (flowDocumentId: string) => {
      setPreviewDocumentId(flowDocumentId);
    },
    [setPreviewDocumentId],
  );

  const { sendAnalytics } = useAnalytics();

  const handleCreateFromPreset = useCallback(() => {
    const name = generateCampaignName(selectedPreset?.name);
    setDuplicateDocument({
      ...flowObjects,
      name,
    });

    setCampaignStep('build');
    setDocumentType('FLOW_DOCUMENT_TYPE_SENDOUT_V3');
    setDocumentId('');
    setCampaignName(name);
    setStarter('duplicate');
    sendAnalytics('clicks choose_preset', { presetName: selectedPreset?.name || '' });
  }, [
    flowObjects,
    generateCampaignName,
    selectedPreset,
    sendAnalytics,
    setCampaignName,
    setCampaignStep,
    setDocumentId,
    setDocumentType,
    setDuplicateDocument,
    setStarter,
  ]);

  const handleCreateEmptyCampaign = useCallback(() => {
    const name = generateCampaignName('Campaign');

    setDocumentType('FLOW_DOCUMENT_TYPE_SENDOUT_V3');
    setCampaignStep('build');
    setDocumentId('');
    setCampaignName(name);
    setStarter('empty');
    sendAnalytics('clicks choose_preset', { presetName: 'empty' });
  }, [
    generateCampaignName,
    sendAnalytics,
    setCampaignName,
    setCampaignStep,
    setDocumentType,
    setDocumentId,
    setStarter,
  ]);

  const handleCreate = useCallback(() => {
    if (selectedPreset) {
      handleCreateFromPreset();
    } else {
      handleCreateEmptyCampaign();
    }
  }, [handleCreateEmptyCampaign, handleCreateFromPreset, selectedPreset]);

  const isLoading = useMemo(
    () => isLoadingCampaignPresets || isLoadingCampaigns,
    [isLoadingCampaigns, isLoadingCampaignPresets],
  );

  return {
    campaignPresetsList,
    handleCampaignPresetClick,
    handleCloseCampaignPresets,
    handleCreate,
    isLoading,
    previewDocumentId,
    flowObjects,
    generateCampaignName,
    selectedPreset,
  };
};

export const CampaignPresets = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const {
    campaignPresetsList,
    handleCampaignPresetClick,
    handleCloseCampaignPresets,
    handleCreate,
    isLoading,
    previewDocumentId,
    flowObjects,
  } = useCampaignPresets();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <FlowChartHeader>
        <Label variant="h6"> Create custom campaign</Label>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <IconButton aria-label="close" component={Link} to="/flow/" onClick={handleCloseCampaignPresets}>
            <CloseIcon />
          </IconButton>
        </Box>
      </FlowChartHeader>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          minHeight: 0,
          background: theme.palette.flowGradient,
        }}
      >
        <Box
          sx={{
            background: theme.palette.background.paper,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
            display: 'flex',
            flexDirection: 'column',
            padding: isLargeScreen ? 3 : 2,
            gap: isLargeScreen ? 2 : 1.5,
            flexGrow: 0,
            margin: isLargeScreen ? 4 : 3,
            width: isLargeScreen ? 360 : 280,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: isLargeScreen ? 2 : 1.5,
              flexGrow: 1,
              minHeight: 0,
            }}
          >
            <Label variant="h6">How do you want to get started?</Label>
            <Label variant="body1" color={theme.palette.text.secondary}>
              Save time by selecting a preset, or create a new campaign from scratch.
            </Label>
            <List
              sx={{
                overflow: 'hidden',
                ':hover': {
                  overflowY: 'auto',
                },
              }}
            >
              <ListItemButton
                selected={previewDocumentId === ''}
                onClick={(_) => handleCampaignPresetClick('')}
                sx={{ borderRadius: '10px', py: 1.5 }}
              >
                <ListItemText primary={<Label variant="h7">Start from scratch</Label>} />
                {previewDocumentId === '' && (
                  <ListItemIcon sx={{ mr: -4.5 }}>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                )}
              </ListItemButton>
              {campaignPresetsList.map((preset) => (
                <ListItemButton
                  key={preset.flowDocumentId}
                  selected={previewDocumentId === preset.flowDocumentId}
                  onClick={(_) => handleCampaignPresetClick(preset.flowDocumentId || '')}
                  sx={{ borderRadius: '10px', py: 1.5, mt: 1 }}
                >
                  <ListItemText primary={<Label variant="h7">{preset.name}</Label>} />
                  {previewDocumentId === preset.flowDocumentId && (
                    <ListItemIcon sx={{ mr: -4.5 }}>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Button
            variant="contained"
            sx={{ height: 44, borderRadius: '10px' }}
            onClick={handleCreate}
            disabled={isLoading}
          >
            Create Campaign
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {!!previewDocumentId && <ReadOnlyFlow {...flowObjects} />}
          {!previewDocumentId && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <StartFromScratchIcon />
              <Label variant="h4" sx={{ mt: 3 }}>
                Start from scratch
              </Label>
              <Label variant="subtitle1" color={theme.palette.grey[700]}>
                Build your campaign from the ground up.
              </Label>
            </Box>
          )}
          {isLoading && (
            <CircularProgress sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
