import axios from 'axios';
class AxiosInstanceBuilder {
    withBaseURL(url) {
        this.baseUrl = url;
        return this;
    }
    withTimeout(timeoutMs) {
        this.timeoutMs = timeoutMs;
        return this;
    }
    build() {
        return axios.create({
            baseURL: this.baseUrl,
            timeout: this.timeoutMs,
        });
    }
}
export default AxiosInstanceBuilder;
