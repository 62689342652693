export enum ShopifyAbandonedCheckoutOnboardingStep {
  ConnectWhatsAppStep = 0,
  ConnectShopifyStep = 1,
}

export type WhatsAppProps = {
  claimedNumber: string | undefined;
  isWhatsAppActive: boolean;
  verificationCode: string | undefined;
};

export type ShopifyProps = {
  isShopifyActive: boolean;
  // onConnectShopify: (storeName: string) => void;
  storeName: string;
};

export type ShopifyAbandonedCheckoutOnboardingProps = {
  shopify: ShopifyProps;
  whatsApp: WhatsAppProps;
};
