import { LoadingState } from '@connectlyai-sdks/loading';
import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
import { searchCustomers } from './thunk';
const initialState = {
    searchInput: '',
    searchText: '',
    isActive: false,
    loadingState: LoadingState.UNINITIALIZED,
    resultItems: [],
};
export const searchSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        setSearchInput(state, action) {
            if (action.payload === '') {
                state.loadingState = LoadingState.UNINITIALIZED;
                state.searchText = '';
                state.resultItems = [];
                state.isActive = false;
            }
            else {
                state.isActive = true;
            }
            state.searchInput = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(searchCustomers.pending, (state, _action) => {
            state.loadingState = LoadingState.FULL_LOADING;
        });
        builder.addCase(searchCustomers.fulfilled, (state, action) => {
            const { searchInput, result } = action.payload;
            if (searchInput === state.searchInput) {
                state.loadingState = LoadingState.DATA;
                state.searchText = action.payload.searchInput;
                state.resultItems = (result === null || result === void 0 ? void 0 : result.entries) || [];
            }
        });
        builder.addCase(searchCustomers.rejected, (state, action) => {
            const { searchInput } = action.payload;
            if (searchInput === state.searchInput) {
                state.loadingState = LoadingState.FULL_ERROR;
                state.searchText = '';
                state.resultItems = [];
            }
        });
    },
});
export const { setSearchInput, reset } = searchSlice.actions;
export const selectSearchState = (state) => state.search;
export const selectSearchIsActive = (state) => state.search.isActive;
export default searchSlice.reducer;
