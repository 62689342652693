import React, { FC } from 'react';
import {
  Box,
  CheckIcon,
  Label,
  Modal,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';

const Item: FC<{ title: string }> = ({ title }) => (
  <ListItem>
    <ListItemIcon>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 32,
          height: 32,
          borderRadius: '100%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        <CheckIcon
          sx={{
            color: (theme) => theme.palette.success.dark,
          }}
        />
      </Box>
    </ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

export const BeforeYouContinue: FC<{ onClose: () => void; onNext?: () => void }> = ({ onClose, onNext }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.beforeYouContinue' });

  return (
    <Modal
      dialog={{
        open: true,
        onClose,
      }}
      title={t('title')}
      actions={{
        tertiaryButton: {
          children: t('cancel', "I'll do this later"),
          onClick: onClose,
        },
        primaryButton: {
          children: t('next', 'Next'),
          onClick: onNext,
        },
      }}
      closeButton={{
        onClick: onClose,
      }}
      subtitle={t(
        'subTitle',
        'Boost your sales and never miss a message with AI-powered product recommendations tailored for each user.',
      )}
    >
      <Label variant="h7" sx={{ minWidth: 552 }}>
        {t('contentTitle', 'Get started today')}
      </Label>
      <List>
        <Item title={t('option1', 'Tailored product recommendations to each customer')} />
        <Item title={t('option2', '100% of customer messages answered')} />
        <Item title={t('option3', 'Aligned with your brand tone and identity')} />
        <Item title={t('option4', 'Real-time insights on customer queries and top products')} />
      </List>
    </Modal>
  );
};
