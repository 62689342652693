// Date when this feature is launched
export const MIN_DATE = new Date(2023, 11, 12);

// data for the preceding day is made available at 1:00 AM UTC
// users will query for data based on day ranges in their local time zone.
// assume that the data is available by 2:00 AM UTC

// data lag in date range depends on if local time is after 02:00 UTC of the same day
export const DATA_LAG_DAYS = new Date().getUTCHours() >= 2 ? 1 : 2;
export const MAX_DATE = new Date(new Date().setUTCDate(new Date().getUTCDate() - DATA_LAG_DAYS));
