import { DAY_IN_SECONDS, EngagedSegmentDisplayInfo, CreateAudienceSegmentQuery } from '@hooks';

// number assumes gte, but gt is used for request.
export const buildSegmentInput = ({
  name,
  lookbackValue,
  frequencyValue,
}: {
  name: string;
  lookbackValue: number;
  frequencyValue: number;
}): CreateAudienceSegmentQuery => {
  const lookbackWindow: string = `${lookbackValue * DAY_IN_SECONDS}s`;
  const value = String(frequencyValue - 1); // convert from gte to gt
  return {
    input: {
      name,
      query: {
        dynamicV2: {
          clause: {
            conditions: [
              {
                anyOf: [
                  {
                    campaignEngagement: {
                      timeframe: {
                        lookbackWindow,
                      },
                      frequency: {
                        operator: 'COMPARISON_OPERATOR_GT',
                        value,
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      },
      type: 'AUDIENCE_SEGMENT_TYPE_CUSTOM',
    },
  };
};

export const queryExists = ({
  queries,
  lookbackValue,
  frequencyValue,
}: {
  queries: EngagedSegmentDisplayInfo[];
  lookbackValue: number;
  frequencyValue: number;
}): boolean => {
  const views = queries.map((query) => query.queryView);
  const match = views.find((query) => {
    if (!query) {
      return false;
    }
    const { timeframe, frequency } = query;
    if (!timeframe || !frequency) {
      return false;
    }
    const { lookbackWindow } = timeframe;
    const { operator, value } = frequency;
    if (!lookbackWindow || !operator || value === undefined) {
      return false;
    }
    const gteValue = operator === 'COMPARISON_OPERATOR_GT' ? value + 1 : value;
    const lookbackSeconds = lookbackValue * DAY_IN_SECONDS;
    if (lookbackWindow === lookbackSeconds && gteValue === frequencyValue) {
      return true;
    }
    return false;
  });
  return !!match;
};
