import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  EVENT_REQUEST_GENERATE_ABANDONED_CART_REPORT_URL,
  EventRequestGenerateAbandonedCartReportResponse,
  EventRequestGenerateAbandonedCartReportInput,
  EventRequestGenerateAbandonedCartReportOptions,
} from './types';

export const useEventRequestGenerateAbandonedCartReport = (
  options?: EventRequestGenerateAbandonedCartReportOptions,
) => {
  return useMutation(
    (input: EventRequestGenerateAbandonedCartReportInput) =>
      api.reportV1(EVENT_REQUEST_GENERATE_ABANDONED_CART_REPORT_URL, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }) as Promise<EventRequestGenerateAbandonedCartReportResponse>,
    {
      ...options,
    },
  );
};
