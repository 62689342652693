import { useCallback, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { flowVariablesAtom, flowVariablesListDerivedAtom, nodesAtom } from '@atoms/flow';
import { useGetAtom } from '@hooks/useGetAtom';
import { FlowVariableDeclaration } from '@connectlyai-tenets/sdk';
import { FlowVariableExtended, FlowVariableExtension, EXAMPLE_FLOW_VARIABLE_EXTENSION } from './types';
import {
  canonicalize,
  mappingsOf,
  mapToVariableHash,
  populateVariables,
  substituteVariables,
  trimCurly,
  variableFqn,
} from './mappers';

export const useFlowVariables = () => {
  const [variables, setVariables] = useAtom(flowVariablesAtom);
  const variablesList = useAtomValue(flowVariablesListDerivedAtom);
  const variablesSet = useMemo(
    () => new Set(variablesList.map(({ simpleNameCanonical }) => simpleNameCanonical)),
    [variablesList],
  );
  const getVariablesList = useGetAtom(flowVariablesListDerivedAtom);
  const getNodes = useGetAtom(nodesAtom);

  const addVariable = useCallback(
    (value: FlowVariableExtended) => {
      if (!value.declaredAt && variablesList.some((item) => variableFqn(item) === variableFqn(value))) return;
      const fqn = variableFqn(value);
      setVariables((prev) => ({ ...prev, [fqn]: value }));
    },
    [setVariables, variablesList],
  );

  const addVariables = useCallback(
    (list: FlowVariableExtended[]) => {
      list.forEach(addVariable);
    },
    [addVariable],
  );

  const enterVariables = useMemo(() => {
    const resultWithType: FlowVariableDeclaration[] = variablesList
      .filter((variable) => !variable.isSessionVariable)
      .map<FlowVariableDeclaration>((variable) => {
        // because typescript does not remove values when we type cast
        Object.keys(EXAMPLE_FLOW_VARIABLE_EXTENSION).forEach((key) => {
          delete variable[key as keyof FlowVariableExtension];
        });

        return variable;
      });
    return resultWithType;
  }, [variablesList]);

  const removeVariable = useCallback(
    (fqn: string) => {
      setVariables((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fqn]: _, ...rest } = prev;
        return rest;
      });
    },
    [setVariables],
  );

  const cleanupVariables = useCallback(() => {
    const varsUsedInFlow: string[] = [];
    getNodes().forEach((node) => {
      const mappings = node?.data?.v1?.parameterMapping?.mappings;
      if (Array.isArray(mappings)) {
        varsUsedInFlow.push(...mappings.map((mapping) => mapping?.fullQualifier));
      }
    });
    getVariablesList().forEach((variable) => {
      const { simpleNameCanonical } = variable;
      if (simpleNameCanonical && !varsUsedInFlow.includes(simpleNameCanonical)) {
        removeVariable(simpleNameCanonical);
      }
    });
  }, [getNodes, getVariablesList, removeVariable]);

  const setFlowVariables = useCallback(
    (newVariables: FlowVariableExtended[]) => {
      const newValue = newVariables.reduce<{ [key: string]: FlowVariableExtended }>((acc, variable) => {
        const key = variableFqn(variable);
        acc[key] = variable;
        return acc;
      }, {});
      setVariables(newValue);
    },
    [setVariables],
  );

  const sourceOfVariable = useCallback(
    (variable: string) => {
      const item = variablesList.find((x) => variableFqn(x) === variable);
      return item?.declaredAt || '';
    },
    [variablesList],
  );

  const variableTypeOf = useCallback(
    (variable: string) => (sourceOfVariable(variable) ? 'SESSION' : 'STANDARD'),
    [sourceOfVariable],
  );

  const trimCurlyCallback = useCallback(trimCurly, []);

  const canonicalizeCallback = useCallback(canonicalize, []);

  const mappingsOfCallback = useCallback(mappingsOf, []);

  const mapToVariableHashCallback = useCallback(mapToVariableHash, []);

  const substituteVariablesCallback = useCallback(substituteVariables, []);

  const populateVariablesCallback = useCallback(populateVariables, []);

  return {
    variables,
    variablesList,
    variablesSet,
    addVariable,
    addVariables,
    enterVariables,
    removeVariable,
    cleanupVariables,
    setFlowVariables,
    sourceOfVariable,
    variableTypeOf,
    // one render
    canonicalize: canonicalizeCallback,
    mappingsOf: mappingsOfCallback,
    mapToVariableHash: mapToVariableHashCallback,
    substituteVariables: substituteVariablesCallback,
    populateVariables: populateVariablesCallback,
    trimCurly: trimCurlyCallback,
  };
};
