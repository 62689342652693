import React, { useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtomValue } from 'jotai';
import { hasUnsavedChangesAtom, documentVersionIdAtom, sendoutIdAtom, starterAtom } from '@atoms/flow';
import { NodeToolbar } from '../NodeToolbar';
import { RecipientDetails } from '../RecipientDetails';
import { ChooseTime } from '../ChooseTime';
import { ChooseAudience } from '../ChooseAudience';
import { useCampaignDate } from '../../../../hooks';
import { useFlowChecks } from '../../../../hooks/useFlowChecks';
import { useCampaignV3 } from '../../hooks/useCampaignV3';
import { useCampaignStep, CampaignStep } from '../../hooks/useCampaignStep';

export type FlowStep = {
  step: number;
  label: string;
  status: CampaignStep | CampaignStep[];
};

export const Sidebar = () => {
  const documentVersionId = useAtomValue(documentVersionIdAtom);
  const hasUnsavedChanges = useAtomValue(hasUnsavedChangesAtom);
  const sendoutId = useAtomValue(sendoutIdAtom);
  const starter = useAtomValue(starterAtom);

  const { isIdle: isFlowChecksIdle } = useFlowChecks();

  const { campaignStep, goToNextCampaignStep, goToPreviousCampaignStep } = useCampaignStep();

  const { isCampaignDateValid } = useCampaignDate();

  const { verifySpreadsheetResult } = useCampaignV3();

  const isVerifySpreadsheetSuccess = useMemo(
    () => verifySpreadsheetResult?.status === 'MUTATION_STATUS_SUCCESS',
    [verifySpreadsheetResult],
  );

  const steps: FlowStep[] = [
    { step: 0, label: 'Build Campaign', status: ['build', 'compile', 'approval'] },
    { step: 1, label: 'Choose Audience', status: 'audience' },
    { step: 2, label: 'Finalize Recipient Details', status: 'recipients' },
    { step: 3, label: 'Choose Time', status: ['schedule', 'conclusion'] },
  ];
  const currentStepIndex = steps.findIndex((s) => s.status === campaignStep || s.status.includes(campaignStep));

  const [isFlowChartValid, nextButtonHint] = useMemo(() => {
    if (hasUnsavedChanges) return [false, 'Please wait while saving changes.'];
    if (isFlowChecksIdle) return [false, 'Loading templates, please wait.'];
    return [true, ''];
  }, [hasUnsavedChanges, isFlowChecksIdle]);

  const nextButtonLabel = useMemo(() => {
    switch (campaignStep) {
      case 'compile': {
        return 'Verifying...';
      }
      case 'schedule':
      case 'conclusion': {
        return 'Submit Campaign';
      }
      default: {
        return 'Next';
      }
    }
  }, [campaignStep]);

  const isBackButtonDisabled = useMemo(
    () => ['build', 'approval'].includes(campaignStep) || (starter === 'resend' && campaignStep === 'audience'),
    [campaignStep, starter],
  );

  const isNextButtonDisabled = useMemo(() => {
    if (hasUnsavedChanges) {
      return true;
    }

    switch (campaignStep) {
      case 'build': {
        return !isFlowChartValid;
      }
      case 'compile': {
        return true;
      }
      case 'approval': {
        return true;
      }
      case 'audience': {
        return !sendoutId || !documentVersionId;
      }
      case 'recipients': {
        return !isVerifySpreadsheetSuccess;
      }
      case 'schedule': {
        return !isCampaignDateValid;
      }
      case 'conclusion': {
        return true;
      }
      default: {
        return false;
      }
    }
  }, [
    campaignStep,
    documentVersionId,
    hasUnsavedChanges,
    isCampaignDateValid,
    isFlowChartValid,
    isVerifySpreadsheetSuccess,
    sendoutId,
  ]);

  let sidebarContent = null;
  switch (currentStepIndex) {
    case 1: {
      sidebarContent = <ChooseAudience />;
      break;
    }
    case 2: {
      sidebarContent = <RecipientDetails />;
      break;
    }
    case 3: {
      sidebarContent = <ChooseTime />;
      break;
    }
    default: {
      sidebarContent = <NodeToolbar />;
      break;
    }
  }

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        p: isLargeScreen ? 3 : 2,
        boxSizing: 'border-box',
        width: '100%',
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 1.5,
        overflow: 'hidden',
      }}
    >
      <Stepper
        sx={{ width: theme.spacing(25), marginBottom: isLargeScreen ? 1 : 0, marginLeft: -1 }}
        activeStep={steps[currentStepIndex]?.step}
      >
        {steps.map((step) => (
          <Step key={step.step}>
            <Tooltip placement="top" title={step.label}>
              <StepLabel
                sx={{
                  boxSizing: 'border-box',
                  '& .MuiStepLabel-iconContainer': {
                    padding: 0,
                  },
                }}
              />
            </Tooltip>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'hidden',
          scrollbarGutter: 'stable',
          ':hover': {
            overflowY: 'auto',
          },
          mx: isLargeScreen ? -3 : -2,
        }}
      >
        <Box
          sx={{
            mx: isLargeScreen ? 3 : 2,
            my: isLargeScreen ? 3 : 1,
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: isLargeScreen ? 3 : 2,
          }}
        >
          {sidebarContent}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 auto', mt: 1.5, gap: 1.5 }}>
        {nextButtonHint && <Alert severity="warning">{nextButtonHint}</Alert>}
        <Button variant="contained" onClick={goToNextCampaignStep} disabled={isNextButtonDisabled}>
          {nextButtonLabel}
        </Button>
        <Button variant="outlined" color="secondary" onClick={goToPreviousCampaignStep} disabled={isBackButtonDisabled}>
          Back
        </Button>
      </Box>
    </Box>
  );
};
