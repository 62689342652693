import { generateRandomText } from '../../utils';
import {
  AudienceSegmentsResponse,
  AudienceSegment,
  EngagedCustomerQuery,
  EngagedCustomerQueryView,
  EngagedSegmentDisplayInfo,
} from './types';
import { DEFAULT_AUDIENCE_SEGMENT_NAME } from './constants';

export const selectPhoneListSegments = (data: AudienceSegmentsResponse): AudienceSegment[] =>
  data.entity?.segments?.filter((x) => x.segment?.query?.phoneList) || [];

export const selectDynamicV2Segments = (data: AudienceSegmentsResponse): AudienceSegment[] =>
  data.entity?.segments
    ?.filter((x) => x.segment?.query?.dynamicV2)
    // stats.size is not available for smart segments but says 0 in the response
    .map((item) => ({ ...item, stats: { size: undefined } })) || [];

export const selectSegmentIsCampaignEngagementLastWeek = (data: AudienceSegment): boolean => {
  return data.segment?.type === 'AUDIENCE_SEGMENT_TYPE_CAMPAIGN_ENGAGEMENT_LAST_WEEK';
};

export const selectMostEngagedSegment = (data: AudienceSegmentsResponse): AudienceSegment | undefined => {
  const segments = selectDynamicV2Segments(data);
  return segments.find(selectSegmentIsCampaignEngagementLastWeek);
};

export const selectCustomTypeDynamicV2Segments = (data: AudienceSegmentsResponse): AudienceSegment[] => {
  let segments = selectDynamicV2Segments(data);
  segments = segments.filter((item) => item?.segment?.type === 'AUDIENCE_SEGMENT_TYPE_CUSTOM');
  return segments;
};

export const selectMostEngagedSegmentId = (data: AudienceSegmentsResponse): string | undefined => {
  const segment = selectMostEngagedSegment(data);
  return segment?.segment?.id;
};

export const selectEngagedSegmentQuery = (data: AudienceSegment): EngagedCustomerQuery | undefined => {
  const conditions = data.segment?.query?.dynamicV2?.clause?.conditions || [];
  if (conditions[0]) {
    const anyOf = conditions[0]?.anyOf || [];
    if (anyOf[0]) {
      const { campaignEngagement } = anyOf[0];
      if (campaignEngagement && Object.keys(campaignEngagement).length === 2) {
        const { timeframe, frequency } = campaignEngagement;
        if (timeframe && frequency) {
          const lookbackWindow = timeframe.lookbackWindow ?? '';
          const operator = frequency.operator ?? '';
          const value = frequency.value ?? '';
          if (lookbackWindow && operator && value) {
            return {
              timeframe: { lookbackWindow },
              frequency: { operator, value },
            };
          }
        }
      }
    }
  }
  return undefined;
};

export const selectMostEngagedSegments = (data: AudienceSegmentsResponse): AudienceSegment[] => {
  let segments = selectDynamicV2Segments(data);
  segments = segments.filter((item) => {
    if (
      ['AUDIENCE_SEGMENT_TYPE_CUSTOM', 'AUDIENCE_SEGMENT_TYPE_CAMPAIGN_ENGAGEMENT_LAST_WEEK'].includes(
        item?.segment?.type || '',
      )
    ) {
      const queryView = selectEngagedSegmentQuery(item);
      if (queryView) {
        return true;
      }
    }
    return false;
  });
  return segments.sort((a, b) => {
    const typeA = a.segment?.type === 'AUDIENCE_SEGMENT_TYPE_CAMPAIGN_ENGAGEMENT_LAST_WEEK' ? 1 : 0;
    const typeB = b.segment?.type === 'AUDIENCE_SEGMENT_TYPE_CAMPAIGN_ENGAGEMENT_LAST_WEEK' ? 1 : 0;
    if (typeA !== typeB) {
      return typeB - typeA;
    }
    return 0;
  });
};

export const selectEngagedSegmentQueryView = (data: AudienceSegment): EngagedCustomerQueryView | undefined => {
  const query = selectEngagedSegmentQuery(data);
  if (!query) return undefined;
  const { timeframe, frequency } = query;
  const { lookbackWindow } = timeframe;
  const { operator, value } = frequency;
  if (lookbackWindow && operator && value) {
    const lookbackSplit = lookbackWindow.split('s');
    if (lookbackSplit.length === 2 && !Number.isNaN(Number(lookbackSplit[0])) && lookbackSplit[1] === '') {
      const lookbackSeconds = Number(lookbackSplit[0]);
      const frequencyValue = Number(value);
      if (operator === 'COMPARISON_OPERATOR_GT' || operator === 'COMPARISON_OPERATOR_GTE') {
        return {
          timeframe: { lookbackWindow: lookbackSeconds },
          frequency: { operator, value: frequencyValue },
        };
      }
    }
  }
  return undefined;
};

const selectMostEngagedSegmentDisplayInfo = (data: AudienceSegmentsResponse): EngagedSegmentDisplayInfo | undefined => {
  const segment = selectMostEngagedSegment(data);
  if (!segment) {
    return undefined;
  }
  const id = segment?.segment?.id ?? '';
  const queryView = selectEngagedSegmentQueryView(segment);
  if (!queryView) {
    return undefined;
  }
  return { id, name: DEFAULT_AUDIENCE_SEGMENT_NAME, queryView };
};

export const selectSegmentDisplayName = (segment: AudienceSegment): string => {
  const isDefault = selectSegmentIsCampaignEngagementLastWeek(segment);
  if (isDefault) {
    return DEFAULT_AUDIENCE_SEGMENT_NAME;
  }
  return segment.segment?.name || '';
};

export const selectCustomSegmentsMostEngagedQueryType = (
  data: AudienceSegmentsResponse,
): EngagedSegmentDisplayInfo[] => {
  const segments = selectDynamicV2Segments(data);
  return segments
    .filter((item) => {
      const type = item?.segment?.type;
      if (type === 'AUDIENCE_SEGMENT_TYPE_CUSTOM') {
        const engagementQuery = selectEngagedSegmentQuery(item);
        if (engagementQuery) {
          return true;
        }
      }
      return false;
    })
    .map((item) => {
      const id = item.segment?.id ?? '';
      const name = item.segment?.name ?? '';
      const queryView = selectEngagedSegmentQueryView(item);
      return { id, name, queryView };
    });
};

export const selectMostEngagedSegmentOptions = (data: AudienceSegmentsResponse): EngagedSegmentDisplayInfo[] => {
  const options: EngagedSegmentDisplayInfo[] = [];
  const mostEngaged = selectMostEngagedSegmentDisplayInfo(data);
  if (mostEngaged) {
    options.push(mostEngaged);
  }
  const customSegments = selectCustomSegmentsMostEngagedQueryType(data);
  options.push(...customSegments);
  return options;
};

export const selectDeleteableSegments = (data: AudienceSegmentsResponse) =>
  data.entity?.segments?.filter((x) => !x.segment?.query?.dynamicV2) || [];

type SegmentTableRowView = {
  id?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  size?: number;
  query?: EngagedCustomerQueryView;
  selectable?: boolean;
};

export const selectTableSegments = (data: AudienceSegmentsResponse): SegmentTableRowView[] => {
  return (
    data.entity?.segments?.map((item) => {
      const { query, type, id, name, createdAt, updatedAt } = item.segment || {};
      const { size } = item.stats || {};
      const { dynamicV2 } = query || {};
      return {
        id,
        name: selectSegmentDisplayName(item),
        createdAt,
        updatedAt,
        size,
        query: dynamicV2 ? selectEngagedSegmentQueryView(item) : undefined,
        selectable: type !== 'AUDIENCE_SEGMENT_TYPE_CAMPAIGN_ENGAGEMENT_LAST_WEEK',
      };
    }) || []
  );
};

export const generateUseAudienceSegmentsPlaceholderData = (count = 5): AudienceSegmentsResponse => {
  return {
    entity: {
      segments: Array.from({ length: count }, () => ({
        segment: {
          id: `SKELETON-${generateRandomText(5)}`,
          businessId: generateRandomText(10),
          name: generateRandomText(10),
          query: undefined,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        stats: {
          size: 100,
        },
      })),
    },
  };
};
