import React, { FC } from 'react';
import { Label, TableHead, TableRow, TableCell } from '@connectlyai-tenets/ui-styled-web';
import { FormattedTooltip } from '@components/Tooltips';
import { EnhancedTableHeadProps } from '@components/EnhancedTable/types';

type SimpleTableHeadProps = Pick<EnhancedTableHeadProps, 'cells'>;

export const SimpleTableHead: FC<SimpleTableHeadProps> = ({ cells }: SimpleTableHeadProps) => {
  return (
    <TableHead
      sx={{
        boxSizing: 'border-box',
        background: 'white',
      }}
    >
      <TableRow>
        {cells.map((item) => {
          const align = item.align || 'center';
          return (
            <TableCell
              key={item.id}
              variant="head"
              align={align}
              sx={{
                background: 'none',
                width: item.width,
                px: 1,
              }}
            >
              <FormattedTooltip title={item.tooltip || ''} arrow>
                <Label>{item.label}</Label>
              </FormattedTooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
