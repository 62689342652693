import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { analyticsPageEnhancementsAtom } from '@atoms/analytics';
import { useAtomValue } from 'jotai';
import { useSofiaEnabled } from '@scenes/Sofia/hooks';
import { PageLayout, NavigationRoute } from '../../../components/PageLayout';
import { Sofia } from './Sofia';
import { SendoutReport } from './SendoutReport';
import { Campaigns } from './Campaigns';
import { Inbox } from './Inbox';
import { AccountOverview } from './AccountOverview';
import { useNavigator } from '../hooks';

const Content = () => {
  const { routes } = useNavigator();
  return (
    <Switch>
      <Route path={routes.home.pathname} exact>
        <Campaigns />
      </Route>
      <Route path={routes.campaigns.pathname}>
        <Campaigns />
      </Route>
      <Route path={routes.sendoutReport.pathname}>
        <SendoutReport />
      </Route>
      <Route path={routes.sofia.pathname}>
        <Sofia />
      </Route>
      <Route path={routes.inbox.pathname}>
        <Inbox />
      </Route>
      <Route path={routes.accountOverview.pathname}>
        <AccountOverview />
      </Route>
    </Switch>
  );
};

export const useAnalyticsScene = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.pages' });
  const { routes: analyticsRoutes } = useNavigator();
  const sofiaEnabled = useSofiaEnabled();
  const routes: NavigationRoute[] = useMemo(() => {
    const returnRoutes: NavigationRoute[] = [
      {
        ...analyticsRoutes.campaigns,
        display: t('tabs.campaign.tabDisplay', 'Campaigns'),
        track: ['analytics.navigation.tab_clicked', { tabName: 'campaigns' }],
      },
      {
        ...analyticsRoutes.inbox,
        display: t('tabs.inbox.tabDisplay', 'Inbox'),
        track: ['analytics.navigation.tab_clicked', { tabName: 'inbox' }],
      },
      {
        ...analyticsRoutes.accountOverview,
        display: t('tabs.accountOverview.tabDisplay', 'Account overview'),
        track: ['analytics.navigation.tab_clicked', { tabName: 'account_overview' }],
      },
    ];
    if (sofiaEnabled) {
      returnRoutes.splice(1, 0, {
        ...analyticsRoutes.sofia,
        display: t('tabs.sofia.tabDisplay', 'Sofia AI'),
        track: ['analytics.navigation.tab_clicked', { tabName: 'sofia' }],
      });
    }
    return returnRoutes;
  }, [
    analyticsRoutes.campaigns,
    analyticsRoutes.inbox,
    analyticsRoutes.sofia,
    analyticsRoutes.accountOverview,
    sofiaEnabled,
    t,
  ]);

  const { fullScreen, navigateBack, title: pageTitle } = useAtomValue(analyticsPageEnhancementsAtom);
  const { title } = useMemo(() => {
    return {
      title: pageTitle || t('title'),
    };
  }, [t, pageTitle]);

  return {
    title,
    routes,
    fullScreen,
    navigateBack,
  };
};

export const Analytics = () => {
  const { title, routes, fullScreen, navigateBack } = useAnalyticsScene();
  return (
    <PageLayout title={title} testId="analytics" routes={routes} fullScreen={fullScreen} navigateBack={navigateBack}>
      <Content />
    </PageLayout>
  );
};
