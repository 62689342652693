import React, { useRef, useMemo, useContext, useEffect, useCallback } from 'react';
import { produce } from 'immer';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import {
  sofiaWebsiteScanAtom,
  sofiaFreeTextAtom,
  sofiaInOnboardingAtom,
  sofiaOnboardingKnowledgeChoiceAtom,
  sofiaOnboardingDocumentsAtom,
  sofiaOnboardingFinishingAtom,
  sofiaStoreDescriptorAtom,
  sofiaWelcomeMessageAtom,
  sofiaPromptAdditionAtom,
  deactivateBotModalIsOpenAtom,
} from '@atoms/sofia';
import { Button, useTheme, ConnectlyCard, LoadingGif } from '@connectlyai-tenets/ui-styled-web';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import {
  useSettingsData,
  selectBusinessId,
  useMeData,
  useFeatureFlag,
  useInstallIntegrationMutation,
  useUninstallIntegrationMutation,
  selectBusinessProfileName,
  selectIntegrationsLoaded,
  selectSofiaIntegrationSettings,
  InstallIntegrationVariables,
  selectWhatsAppSofiaAssistantLink,
} from '@hooks';
import { SettingsResponse } from '@hooks/useSettingsData/types';
import { PageLayout } from '@components';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { useWebchatSofiaLink } from 'src/scenes/Sofia/hooks/useWebchatSofiaLink/useWebchatSofiaLink';
import {
  useAssetUri,
  useAssetContent,
  useSofiaDocuments,
  useSofiaStoreDescriptorV2,
  useMutationSofiaStoreDescriptorV2,
  useCreateScrapingJob,
  mapSofiaViewDataToStoreDescriptorV2,
  useRemoveSofiaDocumentAssociation,
  useUploadSofiaDocument,
  selectSofiaStoreDescriptorsV2List,
  useAgentDescriptor,
  selectAgentDescriptors,
  useMutationAgentDescriptor,
  selectDeployedAgentPromptAddition,
  SofiaV2Response,
} from '../../hooks';
import { SIZE_CONTENT_L } from '../../../../ui-theme';
import { NotificationContext } from '../../../../contexts';
import {
  selectSofiaAllDocuments,
  selectSofiaFreeTextDocumentAssetId,
  selectAssetUri,
  selectSofiaStoreDescriptorViewFromList,
} from '../../selectors';
import { stringToFile } from '../../utils';
import { DeactivateBotModal } from '../DeactivateBotModal';
import { Settings } from '../Settings';
import { OnboardingStatus, Onboarding } from '../Onboarding';
import { InputDataModal } from '../InputDataModal';
import { Info } from '../Info';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '../InputDocuments';
import { getLanguageCode } from '../../../../utils';
import { DEFAULT_WELCOME_MESSAGE, DEFAULT_SOFIA_DATA, FREE_TEXT_DOCUMENT_FILENAME } from '../../constants';

const enableRemoveSofia = getFlagByName('ffEnableRemoveSofia');
const enableRemoveSofiaOverride = getFlagByName('ffEnableRemoveSofiaOverride');

const useHome = () => {
  const queryClient = useQueryClient();

  // ref should have type of an interval or null
  const loadIntegrationInterval = useRef<NodeJS.Timeout | null>(null);
  const [inOnboarding, setInOnboarding] = useAtom(sofiaInOnboardingAtom);
  const [isFinishing, setIsFinishing] = useAtom(sofiaOnboardingFinishingAtom);
  const [storeDescriptorView, setStoreDescriptorView] = useAtom(sofiaStoreDescriptorAtom);
  const setWelcomeMessage = useSetAtom(sofiaWelcomeMessageAtom);
  const setPromptAddition = useSetAtom(sofiaPromptAdditionAtom);
  const [freeText, setFreeText] = useAtom(sofiaFreeTextAtom);
  const setDeactivateModalIsOpen = useSetAtom(deactivateBotModalIsOpenAtom);
  const [onboardingDocuments, setOnboardingDocuments] = useAtom(sofiaOnboardingDocumentsAtom);
  const [websiteScan, setWebsiteScan] = useAtom(sofiaWebsiteScanAtom);
  const onboardingKnowledgeChoice = useAtomValue(sofiaOnboardingKnowledgeChoiceAtom);

  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: businessProfileName } = useSettingsData({
    businessId: businessId || '',
    select: selectBusinessProfileName,
  });
  const { data: sofiaIntegrationSettings, isLoading: integrationSettingsIsLoading } = useSettingsData({
    businessId: businessId || '',
    select: selectSofiaIntegrationSettings,
  });
  const { data: integrationsLoaded } = useSettingsData({
    businessId: businessId || '',
    select: selectIntegrationsLoaded,
  });
  const { data: allDocuments } = useSofiaDocuments({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSofiaAllDocuments,
  });
  const { uploadFile, linkDocument } = useUploadSofiaDocument({
    businessId: businessId || '',
    acceptedFileTypes: ACCEPTED_FILE_TYPES,
    maxFileSize: MAX_FILE_SIZE,
    documents: allDocuments || [],
  });
  const { data: agentDescriptors, isLoading: agentDescriptorsLoading } = useAgentDescriptor({
    businessId: businessId || '',
    select: selectAgentDescriptors,
  });
  const { data: promptAddition } = useAgentDescriptor({
    businessId: businessId || '',
    select: selectDeployedAgentPromptAddition,
  });
  const agentsReady = useMemo(() => {
    return Boolean(!agentDescriptorsLoading && agentDescriptors?.deployed && agentDescriptors?.staged);
  }, [agentDescriptors?.deployed, agentDescriptors?.staged, agentDescriptorsLoading]);
  const { mutate: removeDocument } = useRemoveSofiaDocumentAssociation({
    onSuccess() {
      queryClient.invalidateQueries(['sofia-documents', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error removing document',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
  });
  const { ffEnableSofiaWebchatTestButton } = useFeatureFlag(['ffEnableSofiaWebchatTestButton']);
  const { data: testWhatsappLink } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectWhatsAppSofiaAssistantLink,
  });
  const testWebchatLink = useWebchatSofiaLink({ businessId: businessId || '' });
  const { data: storedStoreDescriptorV2List, isLoading: storeDescriptorV2IsLoading } = useSofiaStoreDescriptorV2({
    businessId: businessId || '',
    enabled: !!businessId && sofiaIntegrationSettings?.integrationType === 'INTEGRATION_TYPE_SALES_ASSISTANT',
    select: selectSofiaStoreDescriptorsV2List,
    refetchInterval: (fetchedData, _) => {
      if (fetchedData?.length) {
        return false;
      }
      return 5000;
    },
  });
  const storedStoreDescriptor = useMemo(() => {
    const storeDescriptor = selectSofiaStoreDescriptorViewFromList(
      storedStoreDescriptorV2List || [],
      sofiaIntegrationSettings?.storeDescriptorId || '',
    );
    return storeDescriptor;
  }, [storedStoreDescriptorV2List, sofiaIntegrationSettings?.storeDescriptorId]);
  const { data: freeTextAssetId } = useSofiaDocuments({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSofiaFreeTextDocumentAssetId,
  });
  const { data: freeTextUri } = useAssetUri({
    businessId: businessId || '',
    assetId: freeTextAssetId || '',
    enabled: !!freeTextAssetId,
    select: selectAssetUri,
  });
  const { data: freeTextContent } = useAssetContent({
    url: freeTextUri || '',
    enabled: !!freeTextAssetId,
  });

  const onboardingStatus: OnboardingStatus = useMemo(() => {
    if (!businessId || integrationSettingsIsLoading || storeDescriptorV2IsLoading) {
      return 'init';
    }
    if (inOnboarding) {
      return 'in_progress';
    }
    if (!storedStoreDescriptor || (!storedStoreDescriptor.name && !inOnboarding)) {
      return 'not_started';
    }
    if (storedStoreDescriptor.name && storedStoreDescriptor.url) {
      return 'completed';
    }
    return 'in_progress';
  }, [businessId, integrationSettingsIsLoading, storeDescriptorV2IsLoading, inOnboarding, storedStoreDescriptor]);

  // scroll to top of page when onboarding is completed
  useEffect(() => {
    if (onboardingStatus === 'completed') {
      document.getElementById('sofia-container')?.scrollTo(0, 0);
    }
  }, [onboardingStatus]);
  // After onboarding, there is a delay before the integration is installed
  // so we poll the settings endpoint to check if the integration is installed
  useEffect(() => {
    if (loadIntegrationInterval.current) {
      clearInterval(loadIntegrationInterval.current);
    }
    if (onboardingStatus === 'completed' && !sofiaIntegrationSettings?.integrationId) {
      loadIntegrationInterval.current = setInterval(() => {
        queryClient.invalidateQueries(['settings', businessId]);
      }, 10000);
    }
    return () => {
      if (loadIntegrationInterval.current) {
        clearInterval(loadIntegrationInterval.current as NodeJS.Timeout);
      }
    };
  }, [integrationsLoaded, sofiaIntegrationSettings?.integrationId, onboardingStatus, queryClient, businessId]);

  // Data connector: Initialize atoms for sofia data
  // Free Text content is loaded separately from store descript data
  useEffect(() => {
    if (freeTextContent && freeTextContent !== freeText) {
      setFreeText(freeTextContent);
    }
    // only run when stored freeTextContent changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeTextContent]);
  useEffect(() => {
    if (!storedStoreDescriptor) return;
    if (storedStoreDescriptor.name !== storeDescriptorView.name) {
      setStoreDescriptorView({
        ...storedStoreDescriptor,
        language: getLanguageCode(storedStoreDescriptor.language),
      });
    }
    // only run when stored data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedStoreDescriptor?.name]);
  // Initialize welcome message atom
  useEffect(() => {
    if (integrationsLoaded && sofiaIntegrationSettings?.welcomeMessage) {
      setWelcomeMessage(sofiaIntegrationSettings.welcomeMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationsLoaded, setWelcomeMessage, Boolean(sofiaIntegrationSettings?.welcomeMessage)]);
  // Initialize prompt addition
  useEffect(() => {
    if (promptAddition) {
      setPromptAddition(promptAddition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPromptAddition, Boolean(promptAddition)]);

  // Unsaved changes are reverted when user selects cancel in modal
  const revertChanges = useCallback(() => {
    if (!storedStoreDescriptor) return;
    setStoreDescriptorView(storedStoreDescriptor);
    setFreeText(freeTextContent || '');
  }, [freeTextContent, setFreeText, setStoreDescriptorView, storedStoreDescriptor]);

  const { mutate: mutateUpdateAgentDescriptor } = useMutationAgentDescriptor({
    onSuccess() {
      queryClient.invalidateQueries(['sofia-agent', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error updating agent',
          severity: NotificationSeverity.ERROR,
        },
      });
      revertChanges();
    },
  });
  const { mutate: mutateUpdateSofiaV2, isLoading: isUpdatingSofiaV2 } = useMutationSofiaStoreDescriptorV2({
    onSuccess() {
      queryClient.invalidateQueries(['sofiaV2', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error saving store information V2',
          severity: NotificationSeverity.ERROR,
        },
      });
      revertChanges();
    },
  });
  const { mutate: mutateScrapeWebsite } = useCreateScrapingJob({
    onSuccess() {
      queryClient.invalidateQueries(['sofia', businessId]);
      queryClient.invalidateQueries(['sofiaV2', businessId]);
    },
    onError() {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: 'Error requesting website scan',
          severity: NotificationSeverity.ERROR,
        },
      });
      revertChanges();
    },
  });

  const saveStoreDescriptor = useAtomCallback(
    useCallback(
      async (get) => {
        const currDescriptor = get(sofiaStoreDescriptorAtom);
        const storeDescriptor = mapSofiaViewDataToStoreDescriptorV2(currDescriptor);
        // avoid creating a new store descriptor if one exists
        const id = sofiaIntegrationSettings?.storeDescriptorId || storedStoreDescriptor?.id;
        if (id && id !== currDescriptor.id) {
          storeDescriptor.id = id;
          setStoreDescriptorView({
            ...currDescriptor,
            id,
          });
        }
        await mutateUpdateSofiaV2({
          businessId: businessId || '',
          storeDescriptor,
        });
      },
      [
        businessId,
        mutateUpdateSofiaV2,
        setStoreDescriptorView,
        sofiaIntegrationSettings?.storeDescriptorId,
        storedStoreDescriptor?.id,
      ],
    ),
  );
  const createDocumentAssociations = useAtomCallback(
    useCallback(
      async (get) => {
        const list = get(sofiaOnboardingDocumentsAtom);
        await Promise.all(list.map(linkDocument));
      },
      [linkDocument],
    ),
  );
  const removeAllDocumentAssociations = useCallback(async () => {
    if (!allDocuments || !businessId) return;
    await Promise.all(allDocuments.map((doc) => removeDocument({ businessId, assetId: doc.id })));
  }, [allDocuments, businessId, removeDocument]);

  // Upload document then link to sofia
  const createFreeTextDocument = useCallback(async () => {
    if (!freeText) {
      removeDocument({ businessId: businessId || '', assetId: freeTextAssetId || '' });
      return;
    }
    const file = stringToFile(freeText, FREE_TEXT_DOCUMENT_FILENAME);
    const { success, message, id } = await uploadFile(file, true);
    if (!success || !id) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          icon: '',
          message: message || 'Error uploading file',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }
    linkDocument({ id, name: file.name });
  }, [businessId, freeText, freeTextAssetId, linkDocument, notificationProvider, removeDocument, uploadFile]);

  const finishDisabled = useMemo(() => {
    if (!storeDescriptorView.language || !storeDescriptorView.name || !storeDescriptorView.url) {
      return true;
    }
    // store description (about) is required for v2 customers
    if (!storeDescriptorView.about) {
      return true;
    }
    let disabled = false;
    switch (onboardingKnowledgeChoice) {
      case 'text':
        disabled = !freeText;
        break;
      case 'document':
        disabled = !onboardingDocuments?.length;
        break;
      case 'website':
        disabled = websiteScan === '';
        break;
      default:
        break;
    }
    return disabled;
  }, [storeDescriptorView, onboardingKnowledgeChoice, freeText, onboardingDocuments?.length, websiteScan]);
  const { mutate: mutateInstallIntegration } = useInstallIntegrationMutation();
  const { mutate: mutateUnInstallIntegration } = useUninstallIntegrationMutation();
  const addIntegration = useCallback(
    ({ welcomeMessage }: { welcomeMessage: string }) => {
      if (!businessId || !businessProfileName) return;

      const installIntegrationVariables: InstallIntegrationVariables = {
        integrationType: sofiaIntegrationSettings?.integrationType,
        businessId,
        integrationParameters: {
          salesAssistantParameters: {
            v1: {
              storeDescriptorId: storedStoreDescriptor?.id,
              welcomeMessage,
            },
          },
        },
      };
      mutateInstallIntegration(installIntegrationVariables, {
        onError: () => {
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: 'Unable to install Sofia. Please try again.',
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          });
        },
        onSuccess(_, _variables, _context) {
          queryClient.invalidateQueries(['settings']);
          queryClient.invalidateQueries(['sofia-agent']);
        },
      });
    },
    [
      businessId,
      businessProfileName,
      mutateInstallIntegration,
      notificationProvider,
      queryClient,
      sofiaIntegrationSettings?.integrationType,
      storedStoreDescriptor?.id,
    ],
  );
  const removeIntegration = useCallback(
    (successCallback?: () => void) => {
      if (!businessId) return;
      if (!sofiaIntegrationSettings?.integrationId) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'Sofia integration not found',
            severity: NotificationSeverity.ERROR,
            icon: '',
          },
        });
        return;
      }
      mutateUnInstallIntegration(
        {
          businessId,
          integrationId: sofiaIntegrationSettings?.integrationId,
        },
        {
          onSuccess: async () => {
            notificationProvider().notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Successfully deactivated Sofia',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
              },
            });
            if (successCallback) {
              successCallback();
            }
            // queryClient.invalidateQueries(['settings']);
            // remove store descriptor from useQuery cache
            queryClient.setQueryData<SofiaV2Response>(['sofiaV2', businessId], (data) => {
              return produce(data, (draft: SofiaV2Response) => {
                if (draft?.response?.storeDescriptors) {
                  draft.response.storeDescriptors = draft.response.storeDescriptors.filter(
                    (descriptor) => descriptor.id !== storedStoreDescriptor?.id,
                  );
                }
                return draft;
              });
            });
            queryClient.setQueryData<SettingsResponse>(['settings'], (data) => {
              return produce(data, (draft: SettingsResponse) => {
                if (draft?.entity?.integrations?.entries) {
                  draft.entity.integrations.entries = draft.entity.integrations.entries.filter(
                    (entry) => entry.type !== 'INTEGRATION_TYPE_SALES_ASSISTANT',
                  );
                }
                return draft;
              });
            });
          },
          onError: () => {
            notificationProvider().notify({
              surface: NotificationSurface.SNACKBAR,
              notification: {
                message: 'Error deactivating Sofia',
                severity: NotificationSeverity.ERROR,
                icon: '',
              },
            });
          },
        },
      );
    },
    [
      businessId,
      sofiaIntegrationSettings?.integrationId,
      mutateUnInstallIntegration,
      notificationProvider,
      queryClient,
      storedStoreDescriptor?.id,
    ],
  );

  const finishOnboarding = useCallback(async () => {
    setIsFinishing(true);
    // Step 1: Save store description
    await saveStoreDescriptor();

    // Step 2: Save knowledge base
    if (onboardingKnowledgeChoice === 'text') {
      await createFreeTextDocument(); // upload then link text file
    } else if (onboardingKnowledgeChoice === 'website') {
      await mutateScrapeWebsite({
        businessId: businessId || '',
        urls: [storeDescriptorView.url],
      });
    } else if (onboardingKnowledgeChoice === 'document') {
      // link uploaded documents
      await createDocumentAssociations();
    }

    // Step 3: Install integration
    // will happen from useEffect hook once store descriptor is saved

    setIsFinishing(false);
    setInOnboarding(false);
  }, [
    setIsFinishing,
    saveStoreDescriptor,
    onboardingKnowledgeChoice,
    setInOnboarding,
    createFreeTextDocument,
    mutateScrapeWebsite,
    businessId,
    storeDescriptorView.url,
    createDocumentAssociations,
  ]);
  // For automatically adding integration if it is missing
  useEffect(() => {
    if (businessId && !sofiaIntegrationSettings?.integrationId && !integrationSettingsIsLoading && integrationsLoaded) {
      if (storedStoreDescriptor?.name) {
        // v2 requires store descriptor id
        if (storedStoreDescriptor?.id) {
          addIntegration({
            welcomeMessage: DEFAULT_WELCOME_MESSAGE,
          });
        }
      }
    }
  }, [
    integrationsLoaded,
    integrationSettingsIsLoading,
    businessId,
    sofiaIntegrationSettings?.integrationId,
    addIntegration,
    storeDescriptorView.name,
    storedStoreDescriptor?.name,
    storedStoreDescriptor?.id,
  ]);

  // The welcome message is stored on the integration
  // To update this, need to uninstall and reinstall the integration
  const saveWelcomeMessage = useAtomCallback(
    useCallback(
      async (get) => {
        if (!businessId || !businessProfileName || !sofiaIntegrationSettings?.integrationId) return;
        const welcomeMessage = get(sofiaWelcomeMessageAtom) || DEFAULT_WELCOME_MESSAGE;
        addIntegration({ welcomeMessage });
      },
      [addIntegration, businessId, businessProfileName, sofiaIntegrationSettings?.integrationId],
    ),
  );

  const saveCustomPrompt = useAtomCallback(
    useCallback(
      async (get) => {
        if (!businessId || !businessProfileName || !sofiaIntegrationSettings?.integrationId || !agentDescriptors) {
          return;
        }
        const newPromptAddition = get(sofiaPromptAdditionAtom) || '';
        const { deployed, staged } = agentDescriptors;
        if (deployed && deployed.resources) {
          deployed.resources = deployed.resources.filter((resource) => !resource.promptAddition);
          if (newPromptAddition) {
            deployed.resources.push({ promptAddition: { promptAddition: newPromptAddition } });
          }
          await mutateUpdateAgentDescriptor({ businessId, agentDescriptor: deployed });
        }
        if (staged && staged.resources) {
          staged.resources = staged.resources.filter((resource) => !resource.promptAddition);
          if (newPromptAddition) {
            staged.resources.push({ promptAddition: { promptAddition: newPromptAddition } });
          }
          await mutateUpdateAgentDescriptor({ businessId, agentDescriptor: staged });
        }
      },
      [
        agentDescriptors,
        businessId,
        businessProfileName,
        mutateUpdateAgentDescriptor,
        sofiaIntegrationSettings?.integrationId,
      ],
    ),
  );

  const goToOnboarding = useCallback(() => {
    setInOnboarding(true);
  }, [setInOnboarding]);

  const openDeactivateModal = useCallback(() => {
    setDeactivateModalIsOpen(true);
  }, [setDeactivateModalIsOpen]);
  const removeSofiaData = useCallback(async () => {
    if (!businessId) return;

    // Remove bot step 1: Remove associated documents
    await removeAllDocumentAssociations();

    // Remove bot step 2: Reset state
    setStoreDescriptorView(DEFAULT_SOFIA_DATA);
    setFreeText('');
    setWebsiteScan('');
    setOnboardingDocuments([]);
  }, [
    businessId,
    removeAllDocumentAssociations,
    setFreeText,
    setOnboardingDocuments,
    setStoreDescriptorView,
    setWebsiteScan,
  ]);
  const deactivateBot = useCallback(async () => {
    if (!businessId || !integrationsLoaded) return;
    await removeSofiaData();
    if (sofiaIntegrationSettings?.integrationId) {
      // Remove bot step 1: Remove integration (then remove store descriptor and associated documents)
      await removeIntegration();
    }
  }, [businessId, integrationsLoaded, removeIntegration, removeSofiaData, sofiaIntegrationSettings?.integrationId]);

  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const { title, deactivateText, buildingText, testWhatsappText, testWebchatText } = useMemo(() => {
    return {
      title: t('title'),
      deactivateText: t('deactivateBotButtonText'),
      buildingText: t('buildingBotText'),
      testWhatsappText: t('settingsOverviewTestWhatsApp'),
      testWebchatText: t('settingsOverviewTestWebchat'),
    };
  }, [t]);

  const ffEnableSofiaSubpages = getFlagByName('ffEnableSofiaSubpages');

  return {
    agentsReady,
    buildingText,
    createFreeTextDocument,
    deactivateBot,
    deactivateText,
    ffEnableSofiaSubpages,
    ffEnableSofiaWebchatTestButton,
    finishDisabled: finishDisabled || isUpdatingSofiaV2 || isFinishing,
    finishOnboarding,
    freeText,
    freeTextAssetId,
    goToOnboarding,
    hasIntegration: !!sofiaIntegrationSettings?.integrationId,
    integrationsLoaded,
    onboardingStatus,
    openDeactivateModal,
    revertChanges,
    saveCustomPrompt,
    saveStoreDescriptor,
    saveWelcomeMessage,
    setFreeText,
    testWhatsappLink,
    testWhatsappText,
    testWebchatText,
    testWebchatLink,
    title,
  };
};

export const Home = () => {
  const theme = useTheme();
  const {
    agentsReady,
    createFreeTextDocument,
    deactivateBot,
    deactivateText,
    ffEnableSofiaSubpages,
    ffEnableSofiaWebchatTestButton,
    finishDisabled,
    finishOnboarding,
    freeText,
    freeTextAssetId,
    goToOnboarding,
    hasIntegration,
    integrationsLoaded,
    onboardingStatus,
    openDeactivateModal,
    revertChanges,
    saveCustomPrompt,
    saveStoreDescriptor,
    saveWelcomeMessage,
    setFreeText,
    testWhatsappLink,
    testWhatsappText,
    testWebchatText,
    testWebchatLink,
    title,
  } = useHome();
  const content = (
    <>
      <DeactivateBotModal deactivateBot={deactivateBot} />
      <InputDataModal
        revertChanges={revertChanges}
        saveStoreDescriptor={saveStoreDescriptor}
        saveWelcomeMessage={saveWelcomeMessage}
        saveCustomPrompt={saveCustomPrompt}
        saveFreeText={createFreeTextDocument}
        freeTextAssetId={freeTextAssetId || ''}
      />
      {onboardingStatus === 'init' && (
        <ConnectlyCard
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: theme.spacing(SIZE_CONTENT_L),
          }}
        >
          <LoadingGif />
        </ConnectlyCard>
      )}
      {onboardingStatus === 'not_started' && <Info goToOnboarding={goToOnboarding} />}
      {onboardingStatus === 'in_progress' && (
        <Onboarding
          finishDisabled={finishDisabled}
          finishOnboarding={finishOnboarding}
          freeText={freeText}
          setFreeText={setFreeText}
        />
      )}
      {onboardingStatus === 'completed' && (
        <Settings freeText={freeText} hasIntegration={hasIntegration} agentsReady={agentsReady} />
      )}
      {enableRemoveSofia && onboardingStatus === 'completed' && (
        <Button
          variant="contained"
          color="warning"
          sx={{ mt: 2, width: 'fit-content' }}
          onClick={openDeactivateModal}
          disabled={!integrationsLoaded || (!hasIntegration && !enableRemoveSofiaOverride)}
        >
          {deactivateText}
        </Button>
      )}
    </>
  );

  if (ffEnableSofiaSubpages) return content;

  const primaryButton =
    onboardingStatus === 'completed'
      ? {
          children: testWhatsappText,
          href: testWhatsappLink,
          sx: { textTransform: 'none' },
          target: '_blank',
        }
      : undefined;

  const secondaryButton =
    ffEnableSofiaWebchatTestButton && onboardingStatus === 'completed'
      ? {
          children: testWebchatText,
          href: testWebchatLink,
          sx: { textTransform: 'none' },
          target: '_blank',
        }
      : undefined;

  return (
    <PageLayout title={title} primaryButton={primaryButton} secondaryButton={secondaryButton}>
      {content}
    </PageLayout>
  );
};
