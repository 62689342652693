import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useAtom } from 'jotai';
import {
  useTheme,
  Label,
  Stack,
  ConnectlyBroadcastIcon,
  Alert,
  ConnectlyCard,
  ConnectlySection,
} from '@connectlyai-tenets/ui-styled-web';
import {
  sortString,
  sortNumber,
  sortDate,
  EnhancedTable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
} from '@components/EnhancedTable';
import { useAnalytics, useFeatureFlag } from '@hooks';
import { selectedAtom, sendoutsExportDataAtom } from '../../../atoms/analytics';
import { AnalyticsSendoutsTableProps, UseNewAnalyticsSendoutsTableProps } from './types';
import { formatDate } from '../../../utils';
import { useNavigator } from '../hooks';
import { SENDOUTS_LIMIT } from '../constants';

const useNewAnalyticsSendoutsTable = ({ data }: UseNewAnalyticsSendoutsTableProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });
  const [selected, setSelected] = useAtom(selectedAtom);
  const isSelecting = useAtomValue(sendoutsExportDataAtom);
  const sendoutsExportData = useAtomValue(sendoutsExportDataAtom);
  const theme = useTheme();
  const { sendAnalytics } = useAnalytics();
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'campaignName',
        label: t('table.columns.campaignName'),
        width: '28%',
        align: 'left',
        sort: sortString,
      },
      {
        id: 'sendoutCompletedAt',
        label: t('table.columns.sendoutComplete'),
        width: '18%',
        align: 'center',
        sort: sortDate,
      },
      {
        id: 'sentCount',
        label: t('table.columns.sent'),
        width: '9%',
        align: 'right',
        sort: sortNumber,
      },
      {
        id: 'deliveredCount',
        label: t('table.columns.delivered'),
        width: '9%',
        align: 'right',
        sort: sortNumber,
      },
      {
        id: 'readCount',
        label: t('table.columns.opened'),
        width: '9%',
        align: 'right',
        sort: sortNumber,
      },
      {
        id: 'engaged',
        label: t('table.columns.engaged'),
        width: '9%',
        align: 'right',
        sort: sortNumber,
      },
    ];
  }, [t]);
  const { openSendoutReport } = useNavigator();
  const rows: ETableRowProps[] = useMemo(() => {
    if (!data) return [];
    return data.map((item) => {
      return {
        id: item.sendoutId || '',
        selectable: true,
        onClick: () => {
          sendAnalytics('analytics.sendout_reporting.click', { sendoutId: item.sendoutId });
          openSendoutReport(item.sendoutId || '');
        },

        data: [
          {
            value: item.campaignName,
            display: (
              <Stack direction="column">
                <Label variant="body1">{item.campaignName}</Label>
                <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
                  {item.sendoutId}
                </Label>
              </Stack>
            ),
          },
          {
            value: item.sendoutCompletedAt,
            align: 'center',
            display: (
              <Stack direction="column" justifyContent="center">
                <Label variant="body1">
                  {formatDate(item.sendoutCompletedAt || '', { year: '2-digit', month: '2-digit', day: '2-digit' })}
                </Label>
                <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
                  {formatDate(item.sendoutCompletedAt || '', { hour: '2-digit', minute: '2-digit', hour12: false })}
                </Label>
              </Stack>
            ),
          },
          {
            value: item.sentCount,
            display: item.sentCount,
            align: 'right',
          },
          {
            value: item.deliveredCount,
            display: item.deliveredCount,
            align: 'right',
          },
          {
            value: item.readCount,
            display: item.readCount,
            align: 'right',
          },
          {
            value: item.sendoutEngagement?.uniqueSessionEngagement,
            display: item.sendoutEngagement?.uniqueSessionEngagement,
            align: 'right',
          },
        ],
      };
    });
  }, [data, openSendoutReport, sendAnalytics, theme.palette.text.secondary]);

  const selectSupport = useMemo(() => {
    return {
      setSelected: (ids: string[]) => {
        const selectedData = data
          .filter(
            (val, i) => val.sendoutId && val.campaignId && ids.includes(val.sendoutId || '') && i < SENDOUTS_LIMIT,
          )
          .map(({ sendoutId, campaignId }) => ({ sendoutId: sendoutId || '', campaignId: campaignId || '' }));
        setSelected(selectedData);
      },
      overrideControls: {
        selecting: isSelecting,
      },
    };
  }, [data, isSelecting, setSelected]);
  const searchFn = useCallback((row: ETableRowProps, search: string) => {
    const itemLookup = String(row.data[0].value) || '';
    return itemLookup.includes(search);
  }, []);
  const searchSupport = useMemo(() => {
    return {
      searchFn,
      placeholder: t('table.searchPlaceholder'),
    };
  }, [searchFn, t]);
  const title = useCallback(
    (len: number) => {
      const sendoutText = len === 1 ? t('table.title.singular') : t('table.title.plural');
      return `${len} ${sendoutText}${isSelecting ? ` - ${selected.length} ${t('table.title.selected')}` : ''}`;
    },
    [isSelecting, selected.length, t],
  );
  return {
    rows,
    tableHead,
    title,
    selectSupport,
    searchSupport,
    hasReachedLimit: selected.length === SENDOUTS_LIMIT,
    sendoutsExportData,
  };
};

export const AnalyticsSendoutsTable = ({ filteredRows, isLoading }: AnalyticsSendoutsTableProps) => {
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);
  const { rows, tableHead, title, selectSupport, searchSupport, hasReachedLimit, sendoutsExportData } =
    useNewAnalyticsSendoutsTable({
      data: filteredRows,
      isLoading,
    });

  const Section = ffEnableAnalyticsRedesign ? ConnectlySection : ConnectlyCard;

  return (
    <Section
      title={ffEnableAnalyticsRedesign && !sendoutsExportData ? 'Sendouts' : ''}
      icon={ffEnableAnalyticsRedesign ? <ConnectlyBroadcastIcon /> : null}
    >
      {hasReachedLimit && <Alert severity="info">You can only select up to {SENDOUTS_LIMIT} sendouts to send.</Alert>}

      <EnhancedTable
        rows={rows}
        headers={tableHead}
        sortDefault={{ by: 'sendoutCompletedAt', order: 'desc' }}
        selectSupport={selectSupport}
        // eslint-disable-next-line no-nested-ternary
        title={ffEnableAnalyticsRedesign ? (sendoutsExportData ? title : '') : title}
        icon={<ConnectlyBroadcastIcon />}
        searchSupport={searchSupport}
        isLoading={isLoading}
      />
    </Section>
  );
};
