import React, { ChangeEvent, VFC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  ErrorIcon,
  FormControlLabel,
  IconButton,
  Label,
  Paper,
  RefreshIcon,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { ConnectProps } from './types';
import { applyHook } from '../../../../utils';
import { SIZE_MEDIA_XXS } from '../../../../ui-theme';
import {
  selectConfirmation,
  selectPhoneNumber,
  selectUseNewPhoneNumber,
  setConfirmation,
} from '../../../../features/welcome';
import { PhoneNumber } from '../../../PhoneNumber';

const useConnect = ({ claimedPhoneNumber, verificationCode }: Partial<ConnectProps>): Partial<ConnectProps> => {
  const dispatch = useDispatch();

  const confirmation = useSelector(selectConfirmation);
  const phoneNumber = useSelector(selectPhoneNumber);
  const useNewPhoneNumber = useSelector(selectUseNewPhoneNumber);

  const handleConfirmationChange = useCallback(
    (value: boolean) => {
      dispatch(setConfirmation(value));
    },
    [dispatch],
  );

  return {
    claimedPhoneNumber,
    confirmation,
    onConfirmationChange: handleConfirmationChange,
    phoneNumber,
    useNewPhoneNumber,
    verificationCode,
  };
};

export const ConnectPresentation: VFC<ConnectProps> = ({
  claimedPhoneNumber,
  confirmation,
  onClaimNumber,
  onConfirmationChange,
  phoneNumber,
  useNewPhoneNumber,
  verificationCode,
  isClaiming,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.connect' });
  const theme = useTheme();
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onConfirmationChange(e.target.checked);
    },
    [onConfirmationChange],
  );

  const number = useNewPhoneNumber ? claimedPhoneNumber : phoneNumber;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Label>{t('title')}</Label>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ width: 240 }}>
          <PhoneNumber number={isClaiming ? 'Loading...' : number} />
        </Box>
        {!isClaiming && !number && (
          <IconButton
            style={{
              width: theme.spacing(SIZE_MEDIA_XXS),
              height: theme.spacing(SIZE_MEDIA_XXS),
            }}
            onClick={onClaimNumber}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px', p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <ErrorIcon />
            <Label>{t('verificationCodeHint')}</Label>
            <FormControlLabel
              control={<Checkbox checked={confirmation} onChange={handleChange} />}
              label={t('verificationCodeCheckBox')}
            />
          </Box>
          <Box sx={{ borderRadius: '10px', backgroundColor: 'secondary.light', width: '170px', flexShrink: 0, p: 2 }}>
            <Label>Verification Code</Label>
            <TextField
              disabled
              placeholder="000000"
              value={verificationCode}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                  borderColor: 'transparent',
                  fontSize: '48px',
                },
                '& .MuiInputBase-input': {
                  px: 0,
                  py: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export const Connect = applyHook(ConnectPresentation, useConnect);
