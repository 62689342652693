import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { FlowDIContext } from '../../features/flow/contexts/FlowDIContext';
import { selectDocumentId, setSubmitError } from '../../state/flow';
import { useEventRequestSubmitReactFlowDocument } from '../useEventRequestSubmitReactFlowDocument';
import { usePrevious } from '../usePrevious';
import { DtoUpdate, SubmitReactFlowDocumentResult } from './types';

export const useRequestSubmitReactFlowDocument = () => {
  const dispatch = useDispatch();
  const [clientRequestId, setClientRequestId] = React.useState<string | undefined>(undefined);
  const [result, setResult] = React.useState<SubmitReactFlowDocumentResult | null>(null);
  const [, setProgress] = React.useState<SubmitReactFlowDocumentResult | null>(null);

  const di = useContext(FlowDIContext);

  const { mutate } = useEventRequestSubmitReactFlowDocument({
    onError: () => {
      di?.notificationServiceProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Unable to submit. Please try again.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onMutate: (input) => {
      setProgress(null);
      setResult(null);
      setSubmitError(null);
      setClientRequestId(input.clientRequestId);
    },
  });

  const documentId = useSelector(selectDocumentId);

  useEffect(() => {
    let sub: Subscription;
    if (di && documentId) {
      sub = di
        .dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => {
            return dtoUpdate.objectId === documentId && dtoUpdate.submitReactFlowDocumentResult !== undefined;
          }),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate.submitReactFlowDocumentResult === undefined) {
              return unreachable();
            }
            return dtoUpdate.submitReactFlowDocumentResult;
          }),
        )
        .subscribe((submitReactFlowDocumentResult) => {
          if (clientRequestId === submitReactFlowDocumentResult.clientRequestId) {
            if (submitReactFlowDocumentResult.progress?.completed === submitReactFlowDocumentResult.progress?.total) {
              setResult(submitReactFlowDocumentResult);
            }
            setProgress(submitReactFlowDocumentResult);
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [documentId, di, clientRequestId]);

  const reset = useCallback(() => {
    setClientRequestId(undefined);
    setProgress(null);
    setResult(null);
    setSubmitError(null);
  }, []);

  // reset data when document id is changed
  const prevDocumentId = usePrevious(documentId);
  useEffect(() => {
    if (documentId !== prevDocumentId) {
      reset();
    }
  }, [documentId, prevDocumentId, reset]);

  const isSuccess = useMemo(() => result?.status === 'MUTATION_STATUS_SUCCESS', [result]);
  const isLoading = useMemo(() => clientRequestId !== undefined && result === null, [clientRequestId, result]);
  const isError = useMemo(() => result?.status === 'MUTATION_STATUS_FAILURE', [result]);
  const error = useMemo(() => (result?.status === 'MUTATION_STATUS_FAILURE' ? result?.error : null), [result]);

  useEffect(() => {
    dispatch(setSubmitError(error || null));
  }, [dispatch, error]);

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
    data: result,
    error,
    reset,
  };
};
