import React, { useState, useCallback } from 'react';
import {
  TextField,
  TextFieldProps,
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
} from '@connectlyai-tenets/ui-styled-web';

export const PasswordInput = (props: TextFieldProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const changeVisibility = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);
  return (
    <TextField
      {...props}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="view password" onClick={changeVisibility} sx={{ width: (theme) => theme.spacing(5) }}>
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        ),
      }}
    />
  );
};
