import { OnboardingStep } from '../../features/welcome';

export const getNextOnboardingStep = (step: OnboardingStep, useNewPhoneNumber: boolean): OnboardingStep => {
  let nextStep: OnboardingStep = 'phoneNumberSelector';
  switch (step) {
    case 'phoneNumberSelector': {
      nextStep = useNewPhoneNumber ? 'businessName' : 'phoneNumberOld';
      break;
    }
    case 'phoneNumberOld': {
      nextStep = 'businessName';
      break;
    }
    case 'businessName': {
      nextStep = 'businessProfilePicture';
      break;
    }
    case 'businessProfilePicture': {
      nextStep = 'connectWhatsApp';
      break;
    }
    case 'connectWhatsApp': {
      nextStep = 'connectShopify';
      break;
    }
    case 'connectShopify': {
      nextStep = 'payment';
      break;
    }
    default: {
      break;
    }
  }
  return nextStep;
};

export const getPreviousOnboardingStep = (step: OnboardingStep, useNewPhoneNumber: boolean): OnboardingStep => {
  let prevStep: OnboardingStep = 'phoneNumberSelector';
  switch (step) {
    case 'phoneNumberOld': {
      prevStep = 'phoneNumberSelector';
      break;
    }
    case 'businessName': {
      prevStep = useNewPhoneNumber ? 'phoneNumberSelector' : 'phoneNumberOld';
      break;
    }
    case 'businessProfilePicture': {
      prevStep = 'businessName';
      break;
    }
    case 'connectWhatsApp': {
      prevStep = 'businessProfilePicture';
      break;
    }
    case 'connectShopify': {
      prevStep = 'connectWhatsApp';
      break;
    }
    default: {
      break;
    }
  }
  return prevStep;
};

export const getIsNextStepDisabled = (
  step: OnboardingStep,
  businessName: string,
  phoneNumber: string,
  connectUrl: string,
  confirmation: boolean,
): boolean => {
  switch (step) {
    case 'businessName': {
      return businessName.trim().length === 0;
    }
    case 'phoneNumberOld': {
      return phoneNumber.trim().length === 0;
    }
    case 'connectShopify': {
      return !connectUrl;
    }
    case 'connectWhatsApp': {
      return !confirmation;
    }
    default: {
      return false;
    }
  }
};
