import { SendoutsResponse } from './types';
import { generateRandomText } from '../../utils/generateRandomText';
import { randomNumber } from '../../utils/randomNumber';

export const selectSendouts = (data: SendoutsResponse) => data.entity?.sendouts;

export const generateUseSendoutsPlaceholderData = (count = 5): SendoutsResponse => {
  return {
    entity: {
      sendouts: Array.from({ length: count }, () => ({
        id: `SKELETON-${generateRandomText(5)}`,
        businessId: generateRandomText(10),
        name: generateRandomText(randomNumber(5, 20)),
        campaign: {
          name: generateRandomText(randomNumber(5, 20)),
        },
        description: generateRandomText(20),
        outcome: {
          reportV1: {
            url: generateRandomText(20),
          },
        },
        status: {
          sendoutCount: 2,
          sentCount: 3,
        },
        state: 'SENDOUT_STATE_UNSPECIFIED',
        authorId: '00000000-0000-0000-0000-000000000000',
        authorName: generateRandomText(12),
        authorSkeleton: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        scheduledAt: new Date().toISOString(),
      })),
    },
  };
};
