import React, { FC, useState, memo, useMemo, useCallback } from 'react';
import {
  Label,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Box,
  IconButton,
  DeleteIcon,
  AssessmentIcon,
  SendIcon,
  Tooltip,
  useTheme,
  CodeIcon,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { Link } from 'react-router-dom';
import { generateUrlRoute } from '@connectlyai-tenets/navigation';
import {
  useCampaignsData,
  useAgentsData,
  selectBusinessId,
  createCampaignsWithAuthorsSelector,
  selectAgentsMap,
  generateUseCampaignsPlaceholderData,
  useMeData,
  CampaignsQuery,
} from '@hooks';
import { track } from 'src/utils';
import { CampaignAPIDialog, WithAnalytics } from '../../components';
import { applyHook } from '../../utils/applyHook';
import { DIRECTION_DICTIONARY } from './constants';

import { CampaignsTableProps } from './types';
import { useCampaignsDIContainer } from '../../features/campaigns';

const placeholderData = generateUseCampaignsPlaceholderData(10);

const useCampaignsTable = ({ search }: Partial<CampaignsTableProps>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = useState<CampaignsQuery['orderBy']>('CAMPAIGN_ORDER_CREATED_AT_DESC');

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: agents } = useAgentsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectAgentsMap,
  });
  const selectCampaignsWithAuthors = useMemo(() => createCampaignsWithAuthorsSelector(agents), [agents]);
  const { data: campaigns } = useCampaignsData({
    select: selectCampaignsWithAuthors,
    placeholderData,
    enabled: !!businessId,
    businessId: businessId as string,
    search: { name: search },
    orderBy,
  });

  const rows = React.useMemo(() => campaigns || [], [campaigns]);

  const visibleRows = React.useMemo(() => {
    const paginated = rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;
    return paginated;
  }, [rows, page, rowsPerPage]);

  const rowsCount = rows.length;

  // Reset the paging and selection if a new filter is applied
  React.useEffect(() => {
    setPage(0);
  }, [search, orderBy]);

  const createSortHandler = (asc: CampaignsQuery['orderBy'], desc: CampaignsQuery['orderBy']) => () => {
    const isAsc = orderBy === asc;
    setOrderBy(isAsc ? desc : asc);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [apiDocumentId, setApiDocumentId] = useState<string | undefined>(undefined);
  const handleOpenAPI = useCallback(
    (flowDocumentId: string) => {
      setApiDocumentId(flowDocumentId);
    },
    [setApiDocumentId],
  );
  const closeAPIDialog = useCallback(() => {
    setApiDocumentId(undefined);
  }, [setApiDocumentId]);

  return {
    apiDocumentId,
    businessId,
    closeAPIDialog,
    orderBy,
    search,
    rowsPerPage,
    page,
    rowsCount,
    visibleRows,
    createSortHandler,
    handleChangePage,
    handleRowsPerPageChange,
    handleOpenAPI,
  };
};

export const CampaignsTableRepresentation: FC<CampaignsTableProps> = ({
  apiDocumentId,
  orderBy,
  closeAPIDialog,
  createSortHandler,
  page,
  rowsCount,
  rowsPerPage,
  visibleRows,
  businessId,
  onDelete,
  handleChangePage,
  handleRowsPerPageChange,
  handleOpenAPI,
}) => {
  const di = useCampaignsDIContainer();
  const theme = useTheme();
  if (!di) {
    return null;
  }
  if (!visibleRows?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          userSelect: 'none',
        }}
      >
        <Box
          sx={{
            fontSize: 96,
          }}
        >
          🕵️‍♂️
        </Box>
        <Label variant="body1">No campaigns</Label>
      </Box>
    );
  }

  const handleViewResultsButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { campaignId, url } = e.currentTarget.dataset;
    track('click edit_campaign_button', { businessId, campaignId });
    if (e.currentTarget.dataset.url) {
      try {
        const navigator = di.navigatorProvider();
        const urlRoute = generateUrlRoute(url || '');
        navigator.navigate(urlRoute);
      } catch (err) {
        const log = di.logProvider();
        log.error('Invalid link', () => ({ url }));
      }
    }
  };

  const headerState = useMemo(() => {
    const nameActive = orderBy === 'CAMPAIGN_ORDER_NAME' || orderBy === 'CAMPAIGN_ORDER_NAME_DESC';
    const createdAtActive = orderBy === 'CAMPAIGN_ORDER_CREATED_AT' || orderBy === 'CAMPAIGN_ORDER_CREATED_AT_DESC';
    return {
      name: {
        active: nameActive,
        direction: nameActive ? DIRECTION_DICTIONARY[orderBy] : undefined,
      },
      createdAt: {
        active: createdAtActive,
        direction: createdAtActive ? DIRECTION_DICTIONARY[orderBy] : undefined,
      },
    };
  }, [orderBy]);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        minWidth: theme.breakpoints.values.md,
        height: '100%',
      }}
    >
      <CampaignAPIDialog flowDocumentId={apiDocumentId} onClose={closeAPIDialog} />
      <Table stickyHeader sx={{ tableLayout: 'fixed', whiteSpace: 'nowrap', borderSpacing: theme.spacing(0, 1.5) }}>
        <TableHead sx={{ background: 'transparent' }}>
          <TableRow>
            <TableCell width={350}>
              <TableSortLabel
                active={headerState.name.active}
                onClick={createSortHandler('CAMPAIGN_ORDER_NAME', 'CAMPAIGN_ORDER_NAME_DESC')}
                direction={headerState.name.direction}
              >
                <Label variant="body2">Campaigns</Label>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={headerState.createdAt.active}
                onClick={createSortHandler('CAMPAIGN_ORDER_CREATED_AT', 'CAMPAIGN_ORDER_CREATED_AT_DESC')}
                direction={headerState.createdAt.direction}
              >
                <Label variant="body2">Created Date</Label>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <Label variant="body2">Sendouts</Label>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows?.map(
            ({ id, name, description, createdAt, status, authorSkeleton, authorName, outcome, flowDocumentId }) => (
              <TableRow key={id}>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    background: 'white',
                    borderRadius: theme.spacing(2, 0, 0, 2),
                  }}
                >
                  <WithSkeleton wrap={id?.includes('SKELETON') || false}>
                    <Label
                      variant="subtitle2"
                      sx={{
                        '& > a': { color: theme.palette.primary.main, textDecoration: 'none' },
                      }}
                    >
                      {name}
                    </Label>
                  </WithSkeleton>
                  <WithSkeleton wrap={id?.includes('SKELETON') || false}>
                    <Label color="textSecondary" variant="body2">
                      {description}
                    </Label>
                  </WithSkeleton>
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    background: 'white',
                  }}
                >
                  <WithSkeleton wrap={id?.includes('SKELETON') || false}>
                    <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
                  </WithSkeleton>
                  <WithSkeleton wrap={authorSkeleton}>
                    <Label color="textSecondary" variant="body2">
                      {authorName}
                    </Label>
                  </WithSkeleton>
                </TableCell>
                <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', background: 'white' }}>
                  <WithSkeleton wrap={id?.includes('SKELETON') || false}>
                    <Label variant="body1">Sent {status?.sentCount || 0} times</Label>
                  </WithSkeleton>
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    background: 'white',
                    borderRadius: theme.spacing(0, 2, 2, 0),
                    textAlign: 'right',
                  }}
                >
                  <WithSkeleton wrap={id?.includes('SKELETON') || false}>
                    <WithAnalytics type="clicks edit_campaign_button" data={{ businessId, campaignId: id }}>
                      <Tooltip title="New Sendout" arrow placement="top">
                        <IconButton component={Link} to={`/flow/${flowDocumentId}`} disabled={!flowDocumentId}>
                          <SendIcon />
                        </IconButton>
                      </Tooltip>
                    </WithAnalytics>
                    {outcome?.reportV1?.url && (
                      <Tooltip title="View Results" arrow placement="top">
                        <IconButton
                          onClick={handleViewResultsButtonClick}
                          data-url={outcome?.reportV1?.url || ''}
                          data-campaignId={id}
                        >
                          <AssessmentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Open API Info" arrow placement="top">
                      <IconButton onClick={() => handleOpenAPI(flowDocumentId as string)} disabled={!flowDocumentId}>
                        <CodeIcon />
                      </IconButton>
                    </Tooltip>
                    <WithAnalytics type="clicks delete_campaign_button" data={{ businessId, campaignId: id }}>
                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton onClick={() => onDelete?.(id as string)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </WithAnalytics>
                  </WithSkeleton>
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            sx={{
              '& .MuiToolbar-root': {
                px: 'calc(24px + 64px)', // the dimensions of a typical bottom-left overview item. e.g. zendesk
              },
            }}
            rowsPerPageOptions={[10, 25, 50]}
            colSpan={4} // SHOULD be the same as number of TableCell above
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            component="td"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableFooter>
      </Table>
    </Box>
  );
};

export const CampaignsTable = memo(
  applyHook(CampaignsTableRepresentation, useCampaignsTable),
  (prevProps, nextProps) => prevProps.search === nextProps.search,
);
