import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Card,
  Label,
  Button,
  useTheme,
  CircularProgress,
  SpeechBubblesIcon,
  ConnectlyCard,
} from '@connectlyai-tenets/ui-styled-web';
import { generateFlowRoute } from '@connectlyai-sdks/inbox-navigation';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useQueryClient } from '@tanstack/react-query';
import {
  selectBusinessId,
  useMeData,
  useInstallIntegrationMutation,
  useBusinessData,
  useFeatureFlag,
} from '../../hooks';
import { IntegrationType } from '../../hooks/useBusinessData';
import { AutomationsAutoReplyProps } from './types';
import { SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT, SIZE_MEDIA_L } from '../../ui-theme';
import { NotificationContext, NavigationContext } from '../../contexts';

const useAutomationAutoReply = ({ onRequestCreateAutoReply }: AutomationsAutoReplyProps) => {
  const { notificationProvider } = useContext(NotificationContext);
  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForFlow);

  const queryClient = useQueryClient();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { business } = useBusinessData({ businessId });

  const [autoReplyIntegrationId, setAutoReplyIntegrationId] = useState('');
  const autoReplyIntegration = useMemo(() => {
    return business?.integrations.find(
      (integration) => integration.type === IntegrationType.INTEGRATION_TYPE_AUTO_REPLY_V1,
    );
  }, [business]);

  useEffect(() => {
    setAutoReplyIntegrationId(autoReplyIntegration?.id || '');
  }, [autoReplyIntegration]);

  const autoReplyFlowDocumentId = useMemo(() => {
    switch (autoReplyIntegration?.installMetadata?.metadataOneof?.$case) {
      case 'autoReplyV1Metadata':
        return autoReplyIntegration?.installMetadata?.metadataOneof.autoReplyV1Metadata.flowDocumentId;
      default:
        return '';
    }
  }, [autoReplyIntegration]);

  const { mutate: mutateInstallIntegration, isLoading: isLoadingInstallIntegration } = useInstallIntegrationMutation();

  const handleCreateAutoReply = useCallback(() => {
    if (!businessId) {
      return;
    }

    if (onRequestCreateAutoReply) {
      onRequestCreateAutoReply();
    }

    mutateInstallIntegration(
      {
        businessId,
        integrationType: 'INTEGRATION_TYPE_AUTO_REPLY_V1',
        integrationParameters: {
          autoReplyV1Parameters: {},
        },
      },
      {
        onError: () => {
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: 'Unable to create. Please try again.',
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          });
        },
        onSuccess(data, _variables, _context) {
          setAutoReplyIntegrationId(data.integrationId || '');
          queryClient.invalidateQueries(['settings']);
        },
      },
    );
  }, [businessId, mutateInstallIntegration, onRequestCreateAutoReply, queryClient]);

  const handleEditAutoReply = useCallback(() => {
    const route = generateFlowRoute(autoReplyFlowDocumentId);
    navigatorProvider().navigate(route);
  }, [autoReplyFlowDocumentId]);

  const { t } = useTranslation('translation', { keyPrefix: 'welcome.autoReplySection' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('autoReply'),
      subtitle: t('content'),
    };
  }, [t]);

  return {
    title,
    subtitle,
    autoReplyIntegration,
    autoReplyIntegrationId,
    autoReplyFlowDocumentId,
    isLoadingInstallIntegration,
    handleCreateAutoReply,
    handleEditAutoReply,
  };
};

export const AutomationAutoReply: FC<AutomationsAutoReplyProps> = (props) => {
  const {
    title,
    subtitle,
    autoReplyFlowDocumentId,
    autoReplyIntegrationId,
    isLoadingInstallIntegration,
    handleCreateAutoReply,
    handleEditAutoReply,
  } = useAutomationAutoReply(props);

  const theme = useTheme();
  const shouldEdit = autoReplyIntegrationId !== '';
  const isDisabled = isLoadingInstallIntegration || (shouldEdit && autoReplyFlowDocumentId === '');
  return (
    <ConnectlyCard title={title} subtitle={subtitle}>
      <Button
        sx={{ width: theme.spacing(12) }}
        variant="contained"
        onClick={shouldEdit ? handleEditAutoReply : handleCreateAutoReply}
        disabled={isDisabled}
      >
        {isLoadingInstallIntegration && <CircularProgress size={theme.spacing(SIZE_MEDIA_XS)} color="inherit" />}
        {!isLoadingInstallIntegration && shouldEdit ? 'Edit' : 'Set up'}
      </Button>
    </ConnectlyCard>
  );
};
