import { useMemo } from 'react';
import { selectBusinessId, useMeData, fetchCampaigns, useListCampaignsWithFlowData } from '@hooks';
import { ListCampaignsResponse } from '@hooks/useListCampaignsWithFlowData/types';

const isSentBefore = (data: ListCampaignsResponse, documentId: string): boolean => {
  const previewCampaign = data?.entity?.campaigns?.find((campaign) => campaign.flowDocumentId === documentId);
  if (!previewCampaign) return false;
  if (previewCampaign.status?.sentCount && previewCampaign.status?.sentCount > 0) return true;
  if (previewCampaign.status?.activeCount && previewCampaign.status?.activeCount > 0) return true;
  return false;
};
export const useIsCampaignSentBefore = (documentId: string) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: campaignsWithFlowList } = useListCampaignsWithFlowData({
    businessId: businessId || '',
    enabled: !!businessId,
    flowDocumentId: documentId,
    refetchOnMount: true,
  });
  const isCampaignSentBefore = useMemo(() => {
    if (!campaignsWithFlowList) return false;
    return isSentBefore(campaignsWithFlowList, documentId);
  }, [campaignsWithFlowList, documentId]);

  return { isCampaignSentBefore };
};

// used for checking immediately just before trying to open a campaign to edit
export const checkIfCampaignSentBefore = async ({
  businessId,
  flowDocumentId,
}: {
  businessId: string;
  flowDocumentId: string;
}) => {
  const response = await fetchCampaigns({ businessId, flowDocumentId });
  return isSentBefore(response, flowDocumentId);
};
