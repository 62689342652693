import { ChannelIdentifier, channelTypeFromJSON, channelTypeToJSON, } from "../../common/channel/models";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { MessageTemplate as MessageTemplate1 } from "../../../events/facebook/graphapi/v12_0/whatsapp/models";
import * as _m0 from "protobufjs/minimal";
import { BoolValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "models.messaging.template";
export var MessageTemplateStatus;
(function (MessageTemplateStatus) {
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_UNSPECIFIED"] = 0] = "MESSAGE_TEMPLATE_STATUS_UNSPECIFIED";
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_APPROVED"] = 1] = "MESSAGE_TEMPLATE_STATUS_APPROVED";
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_REJECTED"] = 2] = "MESSAGE_TEMPLATE_STATUS_REJECTED";
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_PENDING"] = 3] = "MESSAGE_TEMPLATE_STATUS_PENDING";
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_PAUSED"] = 4] = "MESSAGE_TEMPLATE_STATUS_PAUSED";
    MessageTemplateStatus[MessageTemplateStatus["MESSAGE_TEMPLATE_STATUS_DISABLED"] = 5] = "MESSAGE_TEMPLATE_STATUS_DISABLED";
    MessageTemplateStatus[MessageTemplateStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplateStatus || (MessageTemplateStatus = {}));
export function messageTemplateStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "MESSAGE_TEMPLATE_STATUS_UNSPECIFIED":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_UNSPECIFIED;
        case 1:
        case "MESSAGE_TEMPLATE_STATUS_APPROVED":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_APPROVED;
        case 2:
        case "MESSAGE_TEMPLATE_STATUS_REJECTED":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_REJECTED;
        case 3:
        case "MESSAGE_TEMPLATE_STATUS_PENDING":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PENDING;
        case 4:
        case "MESSAGE_TEMPLATE_STATUS_PAUSED":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PAUSED;
        case 5:
        case "MESSAGE_TEMPLATE_STATUS_DISABLED":
            return MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_DISABLED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplateStatus.UNRECOGNIZED;
    }
}
export function messageTemplateStatusToJSON(object) {
    switch (object) {
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_UNSPECIFIED:
            return "MESSAGE_TEMPLATE_STATUS_UNSPECIFIED";
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_APPROVED:
            return "MESSAGE_TEMPLATE_STATUS_APPROVED";
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_REJECTED:
            return "MESSAGE_TEMPLATE_STATUS_REJECTED";
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PENDING:
            return "MESSAGE_TEMPLATE_STATUS_PENDING";
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_PAUSED:
            return "MESSAGE_TEMPLATE_STATUS_PAUSED";
        case MessageTemplateStatus.MESSAGE_TEMPLATE_STATUS_DISABLED:
            return "MESSAGE_TEMPLATE_STATUS_DISABLED";
        case MessageTemplateStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessageTemplateGroupCategory;
(function (MessageTemplateGroupCategory) {
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED"] = 0] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE"] = 1] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE"] = 2] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE"] = 3] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE"] = 4] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE"] = 5] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION"] = 6] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE"] = 7] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE"] = 8] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE"] = 9] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE"] = 10] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY"] = 11] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL"] = 12] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING"] = 13] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP"] = 14] = "MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP";
    MessageTemplateGroupCategory[MessageTemplateGroupCategory["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplateGroupCategory || (MessageTemplateGroupCategory = {}));
export function messageTemplateGroupCategoryFromJSON(object) {
    switch (object) {
        case 0:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED;
        case 1:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE;
        case 2:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE;
        case 3:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE;
        case 4:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE;
        case 5:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE;
        case 6:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION;
        case 7:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE;
        case 8:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE;
        case 9:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE;
        case 10:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE;
        case 11:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY;
        case 12:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL;
        case 13:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING;
        case 14:
        case "MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP":
            return MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplateGroupCategory.UNRECOGNIZED;
    }
}
export function messageTemplateGroupCategoryToJSON(object) {
    switch (object) {
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_UNSPECIFIED";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_ACCOUNT_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_PAYMENT_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_PERSONAL_FINANCE_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_SHIPPING_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_RESERVATION_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_ISSUE_RESOLUTION";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_APPOINTMENT_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSPORTATION_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_TICKET_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_ALERT_UPDATE";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_AUTO_REPLY";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_TRANSACTIONAL";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING";
        case MessageTemplateGroupCategory.MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP:
            return "MESSAGE_TEMPLATE_GROUP_CATEGORY_OTP";
        case MessageTemplateGroupCategory.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var PrefillRule;
(function (PrefillRule) {
    PrefillRule[PrefillRule["PREFILL_RULE_UNSPECIFIED"] = 0] = "PREFILL_RULE_UNSPECIFIED";
    PrefillRule[PrefillRule["PREFILL_RULE_SHORTLINK_URL_QUERY"] = 1] = "PREFILL_RULE_SHORTLINK_URL_QUERY";
    PrefillRule[PrefillRule["PREFILL_RULE_SHORTLINK_URL_QUERY_RAW"] = 2] = "PREFILL_RULE_SHORTLINK_URL_QUERY_RAW";
    PrefillRule[PrefillRule["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PrefillRule || (PrefillRule = {}));
export function prefillRuleFromJSON(object) {
    switch (object) {
        case 0:
        case "PREFILL_RULE_UNSPECIFIED":
            return PrefillRule.PREFILL_RULE_UNSPECIFIED;
        case 1:
        case "PREFILL_RULE_SHORTLINK_URL_QUERY":
            return PrefillRule.PREFILL_RULE_SHORTLINK_URL_QUERY;
        case 2:
        case "PREFILL_RULE_SHORTLINK_URL_QUERY_RAW":
            return PrefillRule.PREFILL_RULE_SHORTLINK_URL_QUERY_RAW;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PrefillRule.UNRECOGNIZED;
    }
}
export function prefillRuleToJSON(object) {
    switch (object) {
        case PrefillRule.PREFILL_RULE_UNSPECIFIED:
            return "PREFILL_RULE_UNSPECIFIED";
        case PrefillRule.PREFILL_RULE_SHORTLINK_URL_QUERY:
            return "PREFILL_RULE_SHORTLINK_URL_QUERY";
        case PrefillRule.PREFILL_RULE_SHORTLINK_URL_QUERY_RAW:
            return "PREFILL_RULE_SHORTLINK_URL_QUERY_RAW";
        case PrefillRule.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MediaParameter_Type;
(function (MediaParameter_Type) {
    MediaParameter_Type[MediaParameter_Type["TYPE_UNSPECIFIED"] = 0] = "TYPE_UNSPECIFIED";
    MediaParameter_Type[MediaParameter_Type["TYPE_IMAGE"] = 1] = "TYPE_IMAGE";
    MediaParameter_Type[MediaParameter_Type["TYPE_DOCUMENT"] = 2] = "TYPE_DOCUMENT";
    MediaParameter_Type[MediaParameter_Type["TYPE_AUDIO"] = 3] = "TYPE_AUDIO";
    MediaParameter_Type[MediaParameter_Type["TYPE_VIDEO"] = 4] = "TYPE_VIDEO";
    MediaParameter_Type[MediaParameter_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MediaParameter_Type || (MediaParameter_Type = {}));
export function mediaParameter_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "TYPE_UNSPECIFIED":
            return MediaParameter_Type.TYPE_UNSPECIFIED;
        case 1:
        case "TYPE_IMAGE":
            return MediaParameter_Type.TYPE_IMAGE;
        case 2:
        case "TYPE_DOCUMENT":
            return MediaParameter_Type.TYPE_DOCUMENT;
        case 3:
        case "TYPE_AUDIO":
            return MediaParameter_Type.TYPE_AUDIO;
        case 4:
        case "TYPE_VIDEO":
            return MediaParameter_Type.TYPE_VIDEO;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MediaParameter_Type.UNRECOGNIZED;
    }
}
export function mediaParameter_TypeToJSON(object) {
    switch (object) {
        case MediaParameter_Type.TYPE_UNSPECIFIED:
            return "TYPE_UNSPECIFIED";
        case MediaParameter_Type.TYPE_IMAGE:
            return "TYPE_IMAGE";
        case MediaParameter_Type.TYPE_DOCUMENT:
            return "TYPE_DOCUMENT";
        case MediaParameter_Type.TYPE_AUDIO:
            return "TYPE_AUDIO";
        case MediaParameter_Type.TYPE_VIDEO:
            return "TYPE_VIDEO";
        case MediaParameter_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseLanguage() {
    return { code: "" };
}
export const Language = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== "") {
            writer.uint32(10).string(message.code);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLanguage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? String(object.code) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = message.code);
        return obj;
    },
};
function createBaseMessageTemplateGroupCollection() {
    return { templateGroups: [] };
}
export const MessageTemplateGroupCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.templateGroups) {
            MessageTemplateGroup.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateGroupCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateGroups.push(MessageTemplateGroup.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateGroups: Array.isArray(object === null || object === void 0 ? void 0 : object.templateGroups)
                ? object.templateGroups.map((e) => MessageTemplateGroup.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.templateGroups) {
            obj.templateGroups = message.templateGroups.map((e) => e ? MessageTemplateGroup.toJSON(e) : undefined);
        }
        else {
            obj.templateGroups = [];
        }
        return obj;
    },
};
function createBaseMessageTemplateGroup() {
    return {
        id: "",
        templates: [],
        name: "",
        category: "",
        createdAt: undefined,
        updatedAt: undefined,
        isSystemGroup: false,
    };
}
export const MessageTemplateGroup = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        for (const v of message.templates) {
            MessageTemplate.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.category !== "") {
            writer.uint32(34).string(message.category);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
        }
        if (message.isSystemGroup === true) {
            writer.uint32(56).bool(message.isSystemGroup);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.templates.push(MessageTemplate.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.category = reader.string();
                    break;
                case 5:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.isSystemGroup = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            templates: Array.isArray(object === null || object === void 0 ? void 0 : object.templates)
                ? object.templates.map((e) => MessageTemplate.fromJSON(e))
                : [],
            name: isSet(object.name) ? String(object.name) : "",
            category: isSet(object.category) ? String(object.category) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            isSystemGroup: isSet(object.isSystemGroup)
                ? Boolean(object.isSystemGroup)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.templates) {
            obj.templates = message.templates.map((e) => e ? MessageTemplate.toJSON(e) : undefined);
        }
        else {
            obj.templates = [];
        }
        message.name !== undefined && (obj.name = message.name);
        message.category !== undefined && (obj.category = message.category);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.isSystemGroup !== undefined &&
            (obj.isSystemGroup = message.isSystemGroup);
        return obj;
    },
};
function createBaseMessageTemplate() {
    return {
        channel: undefined,
        id: "",
        language: undefined,
        status: 0,
        createdAt: undefined,
        updatedAt: undefined,
        externalTemplate: undefined,
        components: [],
        templateComponents: [],
        rejectionReason: undefined,
    };
}
export const MessageTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channel !== undefined) {
            ChannelIdentifier.encode(message.channel, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(26).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(32).int32(message.status);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
        }
        if (message.externalTemplate !== undefined) {
            ExternalMessageTemplate.encode(message.externalTemplate, writer.uint32(90).fork()).ldelim();
        }
        for (const v of message.components) {
            Component.encode(v, writer.uint32(98).fork()).ldelim();
        }
        for (const v of message.templateComponents) {
            TemplateComponent.encode(v, writer.uint32(106).fork()).ldelim();
        }
        if (message.rejectionReason !== undefined) {
            MessageTemplateRejection.encode(message.rejectionReason, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.externalTemplate = ExternalMessageTemplate.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.components.push(Component.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.templateComponents.push(TemplateComponent.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.rejectionReason = MessageTemplateRejection.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channel: isSet(object.channel)
                ? ChannelIdentifier.fromJSON(object.channel)
                : undefined,
            id: isSet(object.id) ? String(object.id) : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            status: isSet(object.status)
                ? messageTemplateStatusFromJSON(object.status)
                : 0,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            externalTemplate: isSet(object.externalTemplate)
                ? ExternalMessageTemplate.fromJSON(object.externalTemplate)
                : undefined,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => Component.fromJSON(e))
                : [],
            templateComponents: Array.isArray(object === null || object === void 0 ? void 0 : object.templateComponents)
                ? object.templateComponents.map((e) => TemplateComponent.fromJSON(e))
                : [],
            rejectionReason: isSet(object.rejectionReason)
                ? MessageTemplateRejection.fromJSON(object.rejectionReason)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channel !== undefined &&
            (obj.channel = message.channel
                ? ChannelIdentifier.toJSON(message.channel)
                : undefined);
        message.id !== undefined && (obj.id = message.id);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        message.status !== undefined &&
            (obj.status = messageTemplateStatusToJSON(message.status));
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.externalTemplate !== undefined &&
            (obj.externalTemplate = message.externalTemplate
                ? ExternalMessageTemplate.toJSON(message.externalTemplate)
                : undefined);
        if (message.components) {
            obj.components = message.components.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        if (message.templateComponents) {
            obj.templateComponents = message.templateComponents.map((e) => e ? TemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.templateComponents = [];
        }
        message.rejectionReason !== undefined &&
            (obj.rejectionReason = message.rejectionReason
                ? MessageTemplateRejection.toJSON(message.rejectionReason)
                : undefined);
        return obj;
    },
};
function createBaseMessageTemplateInput() {
    return { templateComponents: [], name: "", language: undefined };
}
export const MessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.templateComponents) {
            TemplateComponent.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateComponents.push(TemplateComponent.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateComponents: Array.isArray(object === null || object === void 0 ? void 0 : object.templateComponents)
                ? object.templateComponents.map((e) => TemplateComponent.fromJSON(e))
                : [],
            name: isSet(object.name) ? String(object.name) : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.templateComponents) {
            obj.templateComponents = message.templateComponents.map((e) => e ? TemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.templateComponents = [];
        }
        message.name !== undefined && (obj.name = message.name);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        return obj;
    },
};
function createBaseExternalMessageTemplate() {
    return { channelOneof: undefined };
}
export const ExternalMessageTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.channelOneof) === null || _a === void 0 ? void 0 : _a.$case) === "whatsapp") {
            MessageTemplate1.encode(message.channelOneof.whatsapp, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExternalMessageTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channelOneof = {
                        $case: "whatsapp",
                        whatsapp: MessageTemplate1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelOneof: isSet(object.whatsapp)
                ? {
                    $case: "whatsapp",
                    whatsapp: MessageTemplate1.fromJSON(object.whatsapp),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.channelOneof) === null || _a === void 0 ? void 0 : _a.$case) === "whatsapp" &&
            (obj.whatsapp = ((_b = message.channelOneof) === null || _b === void 0 ? void 0 : _b.whatsapp)
                ? MessageTemplate1.toJSON((_c = message.channelOneof) === null || _c === void 0 ? void 0 : _c.whatsapp)
                : undefined);
        return obj;
    },
};
function createBaseComponent() {
    return { componentOneof: undefined };
}
export const Component = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        if (((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header") {
            HeaderComponent.encode(message.componentOneof.header, writer.uint32(18).fork()).ldelim();
        }
        if (((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.$case) === "body") {
            BodyComponent.encode(message.componentOneof.body, writer.uint32(26).fork()).ldelim();
        }
        if (((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.$case) === "button") {
            ButtonComponent.encode(message.componentOneof.button, writer.uint32(34).fork()).ldelim();
        }
        if (((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "carousel") {
            CarouselComponent.encode(message.componentOneof.carousel, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.componentOneof = {
                        $case: "header",
                        header: HeaderComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.componentOneof = {
                        $case: "body",
                        body: BodyComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.componentOneof = {
                        $case: "button",
                        button: ButtonComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.componentOneof = {
                        $case: "carousel",
                        carousel: CarouselComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            componentOneof: isSet(object.header)
                ? { $case: "header", header: HeaderComponent.fromJSON(object.header) }
                : isSet(object.body)
                    ? { $case: "body", body: BodyComponent.fromJSON(object.body) }
                    : isSet(object.button)
                        ? { $case: "button", button: ButtonComponent.fromJSON(object.button) }
                        : isSet(object.carousel)
                            ? {
                                $case: "carousel",
                                carousel: CarouselComponent.fromJSON(object.carousel),
                            }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        ((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header" &&
            (obj.header = ((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.header)
                ? HeaderComponent.toJSON((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.header)
                : undefined);
        ((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "body" &&
            (obj.body = ((_e = message.componentOneof) === null || _e === void 0 ? void 0 : _e.body)
                ? BodyComponent.toJSON((_f = message.componentOneof) === null || _f === void 0 ? void 0 : _f.body)
                : undefined);
        ((_g = message.componentOneof) === null || _g === void 0 ? void 0 : _g.$case) === "button" &&
            (obj.button = ((_h = message.componentOneof) === null || _h === void 0 ? void 0 : _h.button)
                ? ButtonComponent.toJSON((_j = message.componentOneof) === null || _j === void 0 ? void 0 : _j.button)
                : undefined);
        ((_k = message.componentOneof) === null || _k === void 0 ? void 0 : _k.$case) === "carousel" &&
            (obj.carousel = ((_l = message.componentOneof) === null || _l === void 0 ? void 0 : _l.carousel)
                ? CarouselComponent.toJSON((_m = message.componentOneof) === null || _m === void 0 ? void 0 : _m.carousel)
                : undefined);
        return obj;
    },
};
function createBaseListSection() {
    return { title: "", rows: [] };
}
export const ListSection = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        for (const v of message.rows) {
            ListRow.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListSection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.rows.push(ListRow.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            rows: Array.isArray(object === null || object === void 0 ? void 0 : object.rows)
                ? object.rows.map((e) => ListRow.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        if (message.rows) {
            obj.rows = message.rows.map((e) => (e ? ListRow.toJSON(e) : undefined));
        }
        else {
            obj.rows = [];
        }
        return obj;
    },
};
function createBaseListRow() {
    return { id: "", text: "", description: "" };
}
export const ListRow = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListRow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            text: isSet(object.text) ? String(object.text) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.text !== undefined && (obj.text = message.text);
        message.description !== undefined &&
            (obj.description = message.description);
        return obj;
    },
};
function createBaseTemplateComponent() {
    return { componentOneof: undefined };
}
export const TemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header") {
            HeaderTemplateComponent.encode(message.componentOneof.header, writer.uint32(18).fork()).ldelim();
        }
        if (((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.$case) === "body") {
            BodyTemplateComponent.encode(message.componentOneof.body, writer.uint32(26).fork()).ldelim();
        }
        if (((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.$case) === "button") {
            ButtonTemplateComponent.encode(message.componentOneof.button, writer.uint32(34).fork()).ldelim();
        }
        if (((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "footer") {
            FooterTemplateComponent.encode(message.componentOneof.footer, writer.uint32(42).fork()).ldelim();
        }
        if (((_e = message.componentOneof) === null || _e === void 0 ? void 0 : _e.$case) === "list") {
            ListTemplateComponent.encode(message.componentOneof.list, writer.uint32(50).fork()).ldelim();
        }
        if (((_f = message.componentOneof) === null || _f === void 0 ? void 0 : _f.$case) === "carousel") {
            CarouselTemplateComponent.encode(message.componentOneof.carousel, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.componentOneof = {
                        $case: "header",
                        header: HeaderTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.componentOneof = {
                        $case: "body",
                        body: BodyTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.componentOneof = {
                        $case: "button",
                        button: ButtonTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.componentOneof = {
                        $case: "footer",
                        footer: FooterTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.componentOneof = {
                        $case: "list",
                        list: ListTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.componentOneof = {
                        $case: "carousel",
                        carousel: CarouselTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            componentOneof: isSet(object.header)
                ? {
                    $case: "header",
                    header: HeaderTemplateComponent.fromJSON(object.header),
                }
                : isSet(object.body)
                    ? { $case: "body", body: BodyTemplateComponent.fromJSON(object.body) }
                    : isSet(object.button)
                        ? {
                            $case: "button",
                            button: ButtonTemplateComponent.fromJSON(object.button),
                        }
                        : isSet(object.footer)
                            ? {
                                $case: "footer",
                                footer: FooterTemplateComponent.fromJSON(object.footer),
                            }
                            : isSet(object.list)
                                ? { $case: "list", list: ListTemplateComponent.fromJSON(object.list) }
                                : isSet(object.carousel)
                                    ? {
                                        $case: "carousel",
                                        carousel: CarouselTemplateComponent.fromJSON(object.carousel),
                                    }
                                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        const obj = {};
        ((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header" &&
            (obj.header = ((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.header)
                ? HeaderTemplateComponent.toJSON((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.header)
                : undefined);
        ((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "body" &&
            (obj.body = ((_e = message.componentOneof) === null || _e === void 0 ? void 0 : _e.body)
                ? BodyTemplateComponent.toJSON((_f = message.componentOneof) === null || _f === void 0 ? void 0 : _f.body)
                : undefined);
        ((_g = message.componentOneof) === null || _g === void 0 ? void 0 : _g.$case) === "button" &&
            (obj.button = ((_h = message.componentOneof) === null || _h === void 0 ? void 0 : _h.button)
                ? ButtonTemplateComponent.toJSON((_j = message.componentOneof) === null || _j === void 0 ? void 0 : _j.button)
                : undefined);
        ((_k = message.componentOneof) === null || _k === void 0 ? void 0 : _k.$case) === "footer" &&
            (obj.footer = ((_l = message.componentOneof) === null || _l === void 0 ? void 0 : _l.footer)
                ? FooterTemplateComponent.toJSON((_m = message.componentOneof) === null || _m === void 0 ? void 0 : _m.footer)
                : undefined);
        ((_o = message.componentOneof) === null || _o === void 0 ? void 0 : _o.$case) === "list" &&
            (obj.list = ((_p = message.componentOneof) === null || _p === void 0 ? void 0 : _p.list)
                ? ListTemplateComponent.toJSON((_q = message.componentOneof) === null || _q === void 0 ? void 0 : _q.list)
                : undefined);
        ((_r = message.componentOneof) === null || _r === void 0 ? void 0 : _r.$case) === "carousel" &&
            (obj.carousel = ((_s = message.componentOneof) === null || _s === void 0 ? void 0 : _s.carousel)
                ? CarouselTemplateComponent.toJSON((_t = message.componentOneof) === null || _t === void 0 ? void 0 : _t.carousel)
                : undefined);
        return obj;
    },
};
function createBaseListTemplateComponent() {
    return { sections: [], button: "" };
}
export const ListTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.sections) {
            ListSection.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.button !== "") {
            writer.uint32(18).string(message.button);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sections.push(ListSection.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.button = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sections: Array.isArray(object === null || object === void 0 ? void 0 : object.sections)
                ? object.sections.map((e) => ListSection.fromJSON(e))
                : [],
            button: isSet(object.button) ? String(object.button) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.sections) {
            obj.sections = message.sections.map((e) => e ? ListSection.toJSON(e) : undefined);
        }
        else {
            obj.sections = [];
        }
        message.button !== undefined && (obj.button = message.button);
        return obj;
    },
};
function createBaseHeaderComponent() {
    return { parameters: [] };
}
export const HeaderComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.parameters) {
            HeaderComponent_Parameter.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeaderComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.parameters.push(HeaderComponent_Parameter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            parameters: Array.isArray(object === null || object === void 0 ? void 0 : object.parameters)
                ? object.parameters.map((e) => HeaderComponent_Parameter.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.parameters) {
            obj.parameters = message.parameters.map((e) => e ? HeaderComponent_Parameter.toJSON(e) : undefined);
        }
        else {
            obj.parameters = [];
        }
        return obj;
    },
};
function createBaseHeaderComponent_Parameter() {
    return { paramOneof: undefined };
}
export const HeaderComponent_Parameter = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text") {
            TextParameter.encode(message.paramOneof.text, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.paramOneof) === null || _b === void 0 ? void 0 : _b.$case) === "media") {
            MediaParameter.encode(message.paramOneof.media, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeaderComponent_Parameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paramOneof = {
                        $case: "text",
                        text: TextParameter.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.paramOneof = {
                        $case: "media",
                        media: MediaParameter.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paramOneof: isSet(object.text)
                ? { $case: "text", text: TextParameter.fromJSON(object.text) }
                : isSet(object.media)
                    ? { $case: "media", media: MediaParameter.fromJSON(object.media) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text" &&
            (obj.text = ((_b = message.paramOneof) === null || _b === void 0 ? void 0 : _b.text)
                ? TextParameter.toJSON((_c = message.paramOneof) === null || _c === void 0 ? void 0 : _c.text)
                : undefined);
        ((_d = message.paramOneof) === null || _d === void 0 ? void 0 : _d.$case) === "media" &&
            (obj.media = ((_e = message.paramOneof) === null || _e === void 0 ? void 0 : _e.media)
                ? MediaParameter.toJSON((_f = message.paramOneof) === null || _f === void 0 ? void 0 : _f.media)
                : undefined);
        return obj;
    },
};
function createBaseHeaderTemplateComponent() {
    return { formatOneof: undefined };
}
export const HeaderTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.formatOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text") {
            TextTemplateComponent.encode(message.formatOneof.text, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.formatOneof) === null || _b === void 0 ? void 0 : _b.$case) === "media") {
            MediaTemplateComponent.encode(message.formatOneof.media, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeaderTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.formatOneof = {
                        $case: "text",
                        text: TextTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.formatOneof = {
                        $case: "media",
                        media: MediaTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            formatOneof: isSet(object.text)
                ? { $case: "text", text: TextTemplateComponent.fromJSON(object.text) }
                : isSet(object.media)
                    ? {
                        $case: "media",
                        media: MediaTemplateComponent.fromJSON(object.media),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.formatOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text" &&
            (obj.text = ((_b = message.formatOneof) === null || _b === void 0 ? void 0 : _b.text)
                ? TextTemplateComponent.toJSON((_c = message.formatOneof) === null || _c === void 0 ? void 0 : _c.text)
                : undefined);
        ((_d = message.formatOneof) === null || _d === void 0 ? void 0 : _d.$case) === "media" &&
            (obj.media = ((_e = message.formatOneof) === null || _e === void 0 ? void 0 : _e.media)
                ? MediaTemplateComponent.toJSON((_f = message.formatOneof) === null || _f === void 0 ? void 0 : _f.media)
                : undefined);
        return obj;
    },
};
function createBaseFooterTemplateComponent() {
    return { text: undefined };
}
export const FooterTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            TextTemplateComponent.encode(message.text, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFooterTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = TextTemplateComponent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text)
                ? TextTemplateComponent.fromJSON(object.text)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined &&
            (obj.text = message.text
                ? TextTemplateComponent.toJSON(message.text)
                : undefined);
        return obj;
    },
};
function createBaseBodyComponent() {
    return { parameters: [], text: "" };
}
export const BodyComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.parameters) {
            BodyComponent_Parameter.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBodyComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.parameters.push(BodyComponent_Parameter.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            parameters: Array.isArray(object === null || object === void 0 ? void 0 : object.parameters)
                ? object.parameters.map((e) => BodyComponent_Parameter.fromJSON(e))
                : [],
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.parameters) {
            obj.parameters = message.parameters.map((e) => e ? BodyComponent_Parameter.toJSON(e) : undefined);
        }
        else {
            obj.parameters = [];
        }
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseBodyComponent_Parameter() {
    return { paramOneof: undefined };
}
export const BodyComponent_Parameter = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text") {
            TextParameter.encode(message.paramOneof.text, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBodyComponent_Parameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paramOneof = {
                        $case: "text",
                        text: TextParameter.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paramOneof: isSet(object.text)
                ? { $case: "text", text: TextParameter.fromJSON(object.text) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text" &&
            (obj.text = ((_b = message.paramOneof) === null || _b === void 0 ? void 0 : _b.text)
                ? TextParameter.toJSON((_c = message.paramOneof) === null || _c === void 0 ? void 0 : _c.text)
                : undefined);
        return obj;
    },
};
function createBaseBodyTemplateComponent() {
    return { text: undefined };
}
export const BodyTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== undefined) {
            TextTemplateComponent.encode(message.text, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBodyTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = TextTemplateComponent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text)
                ? TextTemplateComponent.fromJSON(object.text)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined &&
            (obj.text = message.text
                ? TextTemplateComponent.toJSON(message.text)
                : undefined);
        return obj;
    },
};
function createBaseButtonComponent() {
    return { index: 0, parameters: [] };
}
export const ButtonComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.index !== 0) {
            writer.uint32(8).uint32(message.index);
        }
        for (const v of message.parameters) {
            ButtonComponent_Parameter.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.index = reader.uint32();
                    break;
                case 2:
                    message.parameters.push(ButtonComponent_Parameter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            index: isSet(object.index) ? Number(object.index) : 0,
            parameters: Array.isArray(object === null || object === void 0 ? void 0 : object.parameters)
                ? object.parameters.map((e) => ButtonComponent_Parameter.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.index !== undefined && (obj.index = Math.round(message.index));
        if (message.parameters) {
            obj.parameters = message.parameters.map((e) => e ? ButtonComponent_Parameter.toJSON(e) : undefined);
        }
        else {
            obj.parameters = [];
        }
        return obj;
    },
};
function createBaseButtonComponent_Parameter() {
    return { paramOneof: undefined };
}
export const ButtonComponent_Parameter = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "quickReplyPayload") {
            ButtonQuickReplyPayloadParameter.encode(message.paramOneof.quickReplyPayload, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.paramOneof) === null || _b === void 0 ? void 0 : _b.$case) === "urlPostfix") {
            ButtonURLPostfixParameter.encode(message.paramOneof.urlPostfix, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonComponent_Parameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paramOneof = {
                        $case: "quickReplyPayload",
                        quickReplyPayload: ButtonQuickReplyPayloadParameter.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.paramOneof = {
                        $case: "urlPostfix",
                        urlPostfix: ButtonURLPostfixParameter.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paramOneof: isSet(object.quickReplyPayload)
                ? {
                    $case: "quickReplyPayload",
                    quickReplyPayload: ButtonQuickReplyPayloadParameter.fromJSON(object.quickReplyPayload),
                }
                : isSet(object.urlPostfix)
                    ? {
                        $case: "urlPostfix",
                        urlPostfix: ButtonURLPostfixParameter.fromJSON(object.urlPostfix),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.paramOneof) === null || _a === void 0 ? void 0 : _a.$case) === "quickReplyPayload" &&
            (obj.quickReplyPayload = ((_b = message.paramOneof) === null || _b === void 0 ? void 0 : _b.quickReplyPayload)
                ? ButtonQuickReplyPayloadParameter.toJSON((_c = message.paramOneof) === null || _c === void 0 ? void 0 : _c.quickReplyPayload)
                : undefined);
        ((_d = message.paramOneof) === null || _d === void 0 ? void 0 : _d.$case) === "urlPostfix" &&
            (obj.urlPostfix = ((_e = message.paramOneof) === null || _e === void 0 ? void 0 : _e.urlPostfix)
                ? ButtonURLPostfixParameter.toJSON((_f = message.paramOneof) === null || _f === void 0 ? void 0 : _f.urlPostfix)
                : undefined);
        return obj;
    },
};
function createBaseCarouselComponent() {
    return { cards: [] };
}
export const CarouselComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.cards) {
            CarouselCardComponent.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cards.push(CarouselCardComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cards: Array.isArray(object === null || object === void 0 ? void 0 : object.cards)
                ? object.cards.map((e) => CarouselCardComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cards) {
            obj.cards = message.cards.map((e) => e ? CarouselCardComponent.toJSON(e) : undefined);
        }
        else {
            obj.cards = [];
        }
        return obj;
    },
};
function createBaseCarouselCardComponent() {
    return { cardIndex: 0, components: [] };
}
export const CarouselCardComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cardIndex !== 0) {
            writer.uint32(8).uint32(message.cardIndex);
        }
        for (const v of message.components) {
            CarouselCardSubComponent.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselCardComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cardIndex = reader.uint32();
                    break;
                case 2:
                    message.components.push(CarouselCardSubComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cardIndex: isSet(object.cardIndex) ? Number(object.cardIndex) : 0,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => CarouselCardSubComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.cardIndex !== undefined &&
            (obj.cardIndex = Math.round(message.cardIndex));
        if (message.components) {
            obj.components = message.components.map((e) => e ? CarouselCardSubComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseCarouselCardSubComponent() {
    return { componentOneof: undefined };
}
export const CarouselCardSubComponent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header") {
            HeaderComponent.encode(message.componentOneof.header, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.$case) === "body") {
            BodyComponent.encode(message.componentOneof.body, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.$case) === "button") {
            ButtonComponent.encode(message.componentOneof.button, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselCardSubComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.componentOneof = {
                        $case: "header",
                        header: HeaderComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.componentOneof = {
                        $case: "body",
                        body: BodyComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.componentOneof = {
                        $case: "button",
                        button: ButtonComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            componentOneof: isSet(object.header)
                ? { $case: "header", header: HeaderComponent.fromJSON(object.header) }
                : isSet(object.body)
                    ? { $case: "body", body: BodyComponent.fromJSON(object.body) }
                    : isSet(object.button)
                        ? { $case: "button", button: ButtonComponent.fromJSON(object.button) }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header" &&
            (obj.header = ((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.header)
                ? HeaderComponent.toJSON((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.header)
                : undefined);
        ((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "body" &&
            (obj.body = ((_e = message.componentOneof) === null || _e === void 0 ? void 0 : _e.body)
                ? BodyComponent.toJSON((_f = message.componentOneof) === null || _f === void 0 ? void 0 : _f.body)
                : undefined);
        ((_g = message.componentOneof) === null || _g === void 0 ? void 0 : _g.$case) === "button" &&
            (obj.button = ((_h = message.componentOneof) === null || _h === void 0 ? void 0 : _h.button)
                ? ButtonComponent.toJSON((_j = message.componentOneof) === null || _j === void 0 ? void 0 : _j.button)
                : undefined);
        return obj;
    },
};
function createBaseButtonTemplateComponent() {
    return { typeOneof: undefined };
}
export const ButtonTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "quickReply") {
            ButtonQuickReplyTemplateComponent.encode(message.typeOneof.quickReply, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "url") {
            ButtonURLTemplateComponent.encode(message.typeOneof.url, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "phoneNumber") {
            ButtonPhoneNumberTemplateComponent.encode(message.typeOneof.phoneNumber, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeOneof = {
                        $case: "quickReply",
                        quickReply: ButtonQuickReplyTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.typeOneof = {
                        $case: "url",
                        url: ButtonURLTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.typeOneof = {
                        $case: "phoneNumber",
                        phoneNumber: ButtonPhoneNumberTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeOneof: isSet(object.quickReply)
                ? {
                    $case: "quickReply",
                    quickReply: ButtonQuickReplyTemplateComponent.fromJSON(object.quickReply),
                }
                : isSet(object.url)
                    ? { $case: "url", url: ButtonURLTemplateComponent.fromJSON(object.url) }
                    : isSet(object.phoneNumber)
                        ? {
                            $case: "phoneNumber",
                            phoneNumber: ButtonPhoneNumberTemplateComponent.fromJSON(object.phoneNumber),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "quickReply" &&
            (obj.quickReply = ((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.quickReply)
                ? ButtonQuickReplyTemplateComponent.toJSON((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.quickReply)
                : undefined);
        ((_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.$case) === "url" &&
            (obj.url = ((_e = message.typeOneof) === null || _e === void 0 ? void 0 : _e.url)
                ? ButtonURLTemplateComponent.toJSON((_f = message.typeOneof) === null || _f === void 0 ? void 0 : _f.url)
                : undefined);
        ((_g = message.typeOneof) === null || _g === void 0 ? void 0 : _g.$case) === "phoneNumber" &&
            (obj.phoneNumber = ((_h = message.typeOneof) === null || _h === void 0 ? void 0 : _h.phoneNumber)
                ? ButtonPhoneNumberTemplateComponent.toJSON((_j = message.typeOneof) === null || _j === void 0 ? void 0 : _j.phoneNumber)
                : undefined);
        return obj;
    },
};
function createBaseCarouselTemplateComponent() {
    return { cards: [] };
}
export const CarouselTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.cards) {
            CarouselCardTemplateComponent.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cards.push(CarouselCardTemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cards: Array.isArray(object === null || object === void 0 ? void 0 : object.cards)
                ? object.cards.map((e) => CarouselCardTemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cards) {
            obj.cards = message.cards.map((e) => e ? CarouselCardTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.cards = [];
        }
        return obj;
    },
};
function createBaseCarouselCardTemplateComponent() {
    return { components: [] };
}
export const CarouselCardTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.components) {
            CarouselCardComponentTemplateComponent.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselCardTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.components.push(CarouselCardComponentTemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => CarouselCardComponentTemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.components) {
            obj.components = message.components.map((e) => e ? CarouselCardComponentTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseCarouselCardComponentTemplateComponent() {
    return { componentOneof: undefined };
}
export const CarouselCardComponentTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header") {
            HeaderTemplateComponent.encode(message.componentOneof.header, writer.uint32(18).fork()).ldelim();
        }
        if (((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.$case) === "body") {
            BodyTemplateComponent.encode(message.componentOneof.body, writer.uint32(26).fork()).ldelim();
        }
        if (((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.$case) === "button") {
            ButtonTemplateComponent.encode(message.componentOneof.button, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCarouselCardComponentTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.componentOneof = {
                        $case: "header",
                        header: HeaderTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.componentOneof = {
                        $case: "body",
                        body: BodyTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.componentOneof = {
                        $case: "button",
                        button: ButtonTemplateComponent.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            componentOneof: isSet(object.header)
                ? {
                    $case: "header",
                    header: HeaderTemplateComponent.fromJSON(object.header),
                }
                : isSet(object.body)
                    ? { $case: "body", body: BodyTemplateComponent.fromJSON(object.body) }
                    : isSet(object.button)
                        ? {
                            $case: "button",
                            button: ButtonTemplateComponent.fromJSON(object.button),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === "header" &&
            (obj.header = ((_b = message.componentOneof) === null || _b === void 0 ? void 0 : _b.header)
                ? HeaderTemplateComponent.toJSON((_c = message.componentOneof) === null || _c === void 0 ? void 0 : _c.header)
                : undefined);
        ((_d = message.componentOneof) === null || _d === void 0 ? void 0 : _d.$case) === "body" &&
            (obj.body = ((_e = message.componentOneof) === null || _e === void 0 ? void 0 : _e.body)
                ? BodyTemplateComponent.toJSON((_f = message.componentOneof) === null || _f === void 0 ? void 0 : _f.body)
                : undefined);
        ((_g = message.componentOneof) === null || _g === void 0 ? void 0 : _g.$case) === "button" &&
            (obj.button = ((_h = message.componentOneof) === null || _h === void 0 ? void 0 : _h.button)
                ? ButtonTemplateComponent.toJSON((_j = message.componentOneof) === null || _j === void 0 ? void 0 : _j.button)
                : undefined);
        return obj;
    },
};
function createBaseTextParameter() {
    return { text: "" };
}
export const TextParameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTextParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseTextTemplateComponent() {
    return { text: "", example: [] };
}
export const TextTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        for (const v of message.example) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTextTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.example.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            example: Array.isArray(object === null || object === void 0 ? void 0 : object.example)
                ? object.example.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        if (message.example) {
            obj.example = message.example.map((e) => e);
        }
        else {
            obj.example = [];
        }
        return obj;
    },
};
function createBaseMediaParameter() {
    return { type: 0, link: "", filename: undefined };
}
export const MediaParameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.link !== "") {
            writer.uint32(18).string(message.link);
        }
        if (message.filename !== undefined) {
            writer.uint32(26).string(message.filename);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMediaParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.link = reader.string();
                    break;
                case 3:
                    message.filename = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? mediaParameter_TypeFromJSON(object.type) : 0,
            link: isSet(object.link) ? String(object.link) : "",
            filename: isSet(object.filename) ? String(object.filename) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = mediaParameter_TypeToJSON(message.type));
        message.link !== undefined && (obj.link = message.link);
        message.filename !== undefined && (obj.filename = message.filename);
        return obj;
    },
};
function createBaseMediaTemplateComponent() {
    return { type: 0, example: [], prefilledParams: [] };
}
export const MediaTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        for (const v of message.example) {
            writer.uint32(18).string(v);
        }
        for (const v of message.prefilledParams) {
            PrefilledParam.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMediaTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.example.push(reader.string());
                    break;
                case 3:
                    message.prefilledParams.push(PrefilledParam.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? mediaParameter_TypeFromJSON(object.type) : 0,
            example: Array.isArray(object === null || object === void 0 ? void 0 : object.example)
                ? object.example.map((e) => String(e))
                : [],
            prefilledParams: Array.isArray(object === null || object === void 0 ? void 0 : object.prefilledParams)
                ? object.prefilledParams.map((e) => PrefilledParam.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = mediaParameter_TypeToJSON(message.type));
        if (message.example) {
            obj.example = message.example.map((e) => e);
        }
        else {
            obj.example = [];
        }
        if (message.prefilledParams) {
            obj.prefilledParams = message.prefilledParams.map((e) => e ? PrefilledParam.toJSON(e) : undefined);
        }
        else {
            obj.prefilledParams = [];
        }
        return obj;
    },
};
function createBaseButtonQuickReplyPayloadParameter() {
    return { payload: "" };
}
export const ButtonQuickReplyPayloadParameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.payload !== "") {
            writer.uint32(10).string(message.payload);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonQuickReplyPayloadParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.payload = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            payload: isSet(object.payload) ? String(object.payload) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
function createBaseButtonQuickReplyTemplateComponent() {
    return { text: "" };
}
export const ButtonQuickReplyTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonQuickReplyTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseButtonURLPostfixParameter() {
    return { urlPostfix: "" };
}
export const ButtonURLPostfixParameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.urlPostfix !== "") {
            writer.uint32(10).string(message.urlPostfix);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonURLPostfixParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.urlPostfix = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            urlPostfix: isSet(object.urlPostfix) ? String(object.urlPostfix) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.urlPostfix !== undefined && (obj.urlPostfix = message.urlPostfix);
        return obj;
    },
};
function createBaseButtonURLTemplateComponent() {
    return {
        text: "",
        url: "",
        example: [],
        trackedUrl: "",
        prefilledParams: [],
        trackedUrlLinkId: "",
    };
}
export const ButtonURLTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        for (const v of message.example) {
            writer.uint32(26).string(v);
        }
        if (message.trackedUrl !== "") {
            writer.uint32(34).string(message.trackedUrl);
        }
        for (const v of message.prefilledParams) {
            PrefilledParam.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.trackedUrlLinkId !== "") {
            writer.uint32(50).string(message.trackedUrlLinkId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonURLTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.example.push(reader.string());
                    break;
                case 4:
                    message.trackedUrl = reader.string();
                    break;
                case 5:
                    message.prefilledParams.push(PrefilledParam.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.trackedUrlLinkId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            url: isSet(object.url) ? String(object.url) : "",
            example: Array.isArray(object === null || object === void 0 ? void 0 : object.example)
                ? object.example.map((e) => String(e))
                : [],
            trackedUrl: isSet(object.trackedUrl) ? String(object.trackedUrl) : "",
            prefilledParams: Array.isArray(object === null || object === void 0 ? void 0 : object.prefilledParams)
                ? object.prefilledParams.map((e) => PrefilledParam.fromJSON(e))
                : [],
            trackedUrlLinkId: isSet(object.trackedUrlLinkId)
                ? String(object.trackedUrlLinkId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.url !== undefined && (obj.url = message.url);
        if (message.example) {
            obj.example = message.example.map((e) => e);
        }
        else {
            obj.example = [];
        }
        message.trackedUrl !== undefined && (obj.trackedUrl = message.trackedUrl);
        if (message.prefilledParams) {
            obj.prefilledParams = message.prefilledParams.map((e) => e ? PrefilledParam.toJSON(e) : undefined);
        }
        else {
            obj.prefilledParams = [];
        }
        message.trackedUrlLinkId !== undefined &&
            (obj.trackedUrlLinkId = message.trackedUrlLinkId);
        return obj;
    },
};
function createBaseButtonPhoneNumberTemplateComponent() {
    return { text: "", phoneNumber: "" };
}
export const ButtonPhoneNumberTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(18).string(message.phoneNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonPhoneNumberTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.phoneNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        return obj;
    },
};
function createBasePrefilledParam() {
    return { key: "", value: "", input: false, rule: 0 };
}
export const PrefilledParam = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        if (message.input === true) {
            writer.uint32(24).bool(message.input);
        }
        if (message.rule !== 0) {
            writer.uint32(32).int32(message.rule);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrefilledParam();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.input = reader.bool();
                    break;
                case 4:
                    message.rule = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
            input: isSet(object.input) ? Boolean(object.input) : false,
            rule: isSet(object.rule) ? prefillRuleFromJSON(object.rule) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        message.input !== undefined && (obj.input = message.input);
        message.rule !== undefined && (obj.rule = prefillRuleToJSON(message.rule));
        return obj;
    },
};
function createBaseQueryMessageTemplateGroupsInput() {
    return {
        channelType: 0,
        language: undefined,
        filterStatus: 0,
        includeSystemTemplates: false,
        names: [],
        filterHasVariables: undefined,
    };
}
export const QueryMessageTemplateGroupsInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channelType !== 0) {
            writer.uint32(8).int32(message.channelType);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(18).fork()).ldelim();
        }
        if (message.filterStatus !== 0) {
            writer.uint32(24).int32(message.filterStatus);
        }
        if (message.includeSystemTemplates === true) {
            writer.uint32(32).bool(message.includeSystemTemplates);
        }
        for (const v of message.names) {
            writer.uint32(42).string(v);
        }
        if (message.filterHasVariables !== undefined) {
            BoolValue.encode({ value: message.filterHasVariables }, writer.uint32(794).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryMessageTemplateGroupsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channelType = reader.int32();
                    break;
                case 2:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.filterStatus = reader.int32();
                    break;
                case 4:
                    message.includeSystemTemplates = reader.bool();
                    break;
                case 5:
                    message.names.push(reader.string());
                    break;
                case 99:
                    message.filterHasVariables = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            filterStatus: isSet(object.filterStatus)
                ? messageTemplateStatusFromJSON(object.filterStatus)
                : 0,
            includeSystemTemplates: isSet(object.includeSystemTemplates)
                ? Boolean(object.includeSystemTemplates)
                : false,
            names: Array.isArray(object === null || object === void 0 ? void 0 : object.names)
                ? object.names.map((e) => String(e))
                : [],
            filterHasVariables: isSet(object.filterHasVariables)
                ? Boolean(object.filterHasVariables)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        message.filterStatus !== undefined &&
            (obj.filterStatus = messageTemplateStatusToJSON(message.filterStatus));
        message.includeSystemTemplates !== undefined &&
            (obj.includeSystemTemplates = message.includeSystemTemplates);
        if (message.names) {
            obj.names = message.names.map((e) => e);
        }
        else {
            obj.names = [];
        }
        message.filterHasVariables !== undefined &&
            (obj.filterHasVariables = message.filterHasVariables);
        return obj;
    },
};
function createBaseMutationCreateMessageTemplateInput() {
    return { template: undefined, templateGroup: undefined };
}
export const MutationCreateMessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.template !== undefined) {
            CreateMessageTemplateInput.encode(message.template, writer.uint32(10).fork()).ldelim();
        }
        if (message.templateGroup !== undefined) {
            CreateMessageTemplateGroupInput.encode(message.templateGroup, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationCreateMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.template = CreateMessageTemplateInput.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.templateGroup = CreateMessageTemplateGroupInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            template: isSet(object.template)
                ? CreateMessageTemplateInput.fromJSON(object.template)
                : undefined,
            templateGroup: isSet(object.templateGroup)
                ? CreateMessageTemplateGroupInput.fromJSON(object.templateGroup)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.template !== undefined &&
            (obj.template = message.template
                ? CreateMessageTemplateInput.toJSON(message.template)
                : undefined);
        message.templateGroup !== undefined &&
            (obj.templateGroup = message.templateGroup
                ? CreateMessageTemplateGroupInput.toJSON(message.templateGroup)
                : undefined);
        return obj;
    },
};
function createBaseCreateMessageTemplateInput() {
    return { channel: undefined, language: undefined, templateComponents: [] };
}
export const CreateMessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channel !== undefined) {
            ChannelIdentifier.encode(message.channel, writer.uint32(10).fork()).ldelim();
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.templateComponents) {
            TemplateComponent.encode(v, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.templateComponents.push(TemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channel: isSet(object.channel)
                ? ChannelIdentifier.fromJSON(object.channel)
                : undefined,
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            templateComponents: Array.isArray(object === null || object === void 0 ? void 0 : object.templateComponents)
                ? object.templateComponents.map((e) => TemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.channel !== undefined &&
            (obj.channel = message.channel
                ? ChannelIdentifier.toJSON(message.channel)
                : undefined);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        if (message.templateComponents) {
            obj.templateComponents = message.templateComponents.map((e) => e ? TemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.templateComponents = [];
        }
        return obj;
    },
};
function createBaseMutationUpdateMessageTemplateInput() {
    return { predicateOneof: undefined, templateComponents: [] };
}
export const MutationUpdateMessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byExternalTemplateId") {
            MutationUpdateMessageTemplateInput_ByExternalID.encode(message.predicateOneof.byExternalTemplateId, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.templateComponents) {
            TemplateComponent.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationUpdateMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.predicateOneof = {
                        $case: "byExternalTemplateId",
                        byExternalTemplateId: MutationUpdateMessageTemplateInput_ByExternalID.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.templateComponents.push(TemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            predicateOneof: isSet(object.byExternalTemplateId)
                ? {
                    $case: "byExternalTemplateId",
                    byExternalTemplateId: MutationUpdateMessageTemplateInput_ByExternalID.fromJSON(object.byExternalTemplateId),
                }
                : undefined,
            templateComponents: Array.isArray(object === null || object === void 0 ? void 0 : object.templateComponents)
                ? object.templateComponents.map((e) => TemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.predicateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "byExternalTemplateId" &&
            (obj.byExternalTemplateId = ((_b = message.predicateOneof) === null || _b === void 0 ? void 0 : _b.byExternalTemplateId)
                ? MutationUpdateMessageTemplateInput_ByExternalID.toJSON((_c = message.predicateOneof) === null || _c === void 0 ? void 0 : _c.byExternalTemplateId)
                : undefined);
        if (message.templateComponents) {
            obj.templateComponents = message.templateComponents.map((e) => e ? TemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.templateComponents = [];
        }
        return obj;
    },
};
function createBaseMutationUpdateMessageTemplateInput_ByExternalID() {
    return { templateId: "" };
}
export const MutationUpdateMessageTemplateInput_ByExternalID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.templateId !== "") {
            writer.uint32(10).string(message.templateId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationUpdateMessageTemplateInput_ByExternalID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateId: isSet(object.templateId) ? String(object.templateId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.templateId !== undefined && (obj.templateId = message.templateId);
        return obj;
    },
};
function createBaseCreateMessageTemplateGroupInput() {
    return { id: "", name: "", category: "" };
}
export const CreateMessageTemplateGroupInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.category !== "") {
            writer.uint32(26).string(message.category);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateMessageTemplateGroupInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.category = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            category: isSet(object.category) ? String(object.category) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.category !== undefined && (obj.category = message.category);
        return obj;
    },
};
function createBaseMutationDeleteMessageTemplateInput() {
    return { typeOneof: undefined };
}
export const MutationDeleteMessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "templateId") {
            writer.uint32(10).string(message.typeOneof.templateId);
        }
        if (((_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.$case) === "templateGroupId") {
            writer.uint32(18).string(message.typeOneof.templateGroupId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationDeleteMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeOneof = {
                        $case: "templateId",
                        templateId: reader.string(),
                    };
                    break;
                case 2:
                    message.typeOneof = {
                        $case: "templateGroupId",
                        templateGroupId: reader.string(),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeOneof: isSet(object.templateId)
                ? { $case: "templateId", templateId: String(object.templateId) }
                : isSet(object.templateGroupId)
                    ? {
                        $case: "templateGroupId",
                        templateGroupId: String(object.templateGroupId),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d;
        const obj = {};
        ((_a = message.typeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "templateId" &&
            (obj.templateId = (_b = message.typeOneof) === null || _b === void 0 ? void 0 : _b.templateId);
        ((_c = message.typeOneof) === null || _c === void 0 ? void 0 : _c.$case) === "templateGroupId" &&
            (obj.templateGroupId = (_d = message.typeOneof) === null || _d === void 0 ? void 0 : _d.templateGroupId);
        return obj;
    },
};
function createBaseMessageTemplateRejection() {
    return { reason: "", rejectionTimestamp: undefined };
}
export const MessageTemplateRejection = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reason !== "") {
            writer.uint32(10).string(message.reason);
        }
        if (message.rejectionTimestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.rejectionTimestamp), writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateRejection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = reader.string();
                    break;
                case 2:
                    message.rejectionTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reason: isSet(object.reason) ? String(object.reason) : "",
            rejectionTimestamp: isSet(object.rejectionTimestamp)
                ? String(object.rejectionTimestamp)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reason !== undefined && (obj.reason = message.reason);
        message.rejectionTimestamp !== undefined &&
            (obj.rejectionTimestamp = message.rejectionTimestamp);
        return obj;
    },
};
function createBaseMutationStageMessageTemplateInput() {
    return {
        channel: undefined,
        id: "",
        groupId: "",
        externalTemplateGroupId: "",
        externalTemplateId: "",
        language: undefined,
        status: 0,
        templateComponents: [],
        name: "",
        rejectionDetails: undefined,
    };
}
export const MutationStageMessageTemplateInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channel !== undefined) {
            ChannelIdentifier.encode(message.channel, writer.uint32(10).fork()).ldelim();
        }
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (message.groupId !== "") {
            writer.uint32(26).string(message.groupId);
        }
        if (message.externalTemplateGroupId !== "") {
            writer.uint32(34).string(message.externalTemplateGroupId);
        }
        if (message.externalTemplateId !== "") {
            writer.uint32(42).string(message.externalTemplateId);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(50).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(56).int32(message.status);
        }
        for (const v of message.templateComponents) {
            TemplateComponent.encode(v, writer.uint32(66).fork()).ldelim();
        }
        if (message.name !== "") {
            writer.uint32(74).string(message.name);
        }
        if (message.rejectionDetails !== undefined) {
            MessageTemplateRejection.encode(message.rejectionDetails, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMutationStageMessageTemplateInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.groupId = reader.string();
                    break;
                case 4:
                    message.externalTemplateGroupId = reader.string();
                    break;
                case 5:
                    message.externalTemplateId = reader.string();
                    break;
                case 6:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.status = reader.int32();
                    break;
                case 8:
                    message.templateComponents.push(TemplateComponent.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.name = reader.string();
                    break;
                case 10:
                    message.rejectionDetails = MessageTemplateRejection.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channel: isSet(object.channel)
                ? ChannelIdentifier.fromJSON(object.channel)
                : undefined,
            id: isSet(object.id) ? String(object.id) : "",
            groupId: isSet(object.groupId) ? String(object.groupId) : "",
            externalTemplateGroupId: isSet(object.externalTemplateGroupId)
                ? String(object.externalTemplateGroupId)
                : "",
            externalTemplateId: isSet(object.externalTemplateId)
                ? String(object.externalTemplateId)
                : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            status: isSet(object.status)
                ? messageTemplateStatusFromJSON(object.status)
                : 0,
            templateComponents: Array.isArray(object === null || object === void 0 ? void 0 : object.templateComponents)
                ? object.templateComponents.map((e) => TemplateComponent.fromJSON(e))
                : [],
            name: isSet(object.name) ? String(object.name) : "",
            rejectionDetails: isSet(object.rejectionDetails)
                ? MessageTemplateRejection.fromJSON(object.rejectionDetails)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channel !== undefined &&
            (obj.channel = message.channel
                ? ChannelIdentifier.toJSON(message.channel)
                : undefined);
        message.id !== undefined && (obj.id = message.id);
        message.groupId !== undefined && (obj.groupId = message.groupId);
        message.externalTemplateGroupId !== undefined &&
            (obj.externalTemplateGroupId = message.externalTemplateGroupId);
        message.externalTemplateId !== undefined &&
            (obj.externalTemplateId = message.externalTemplateId);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        message.status !== undefined &&
            (obj.status = messageTemplateStatusToJSON(message.status));
        if (message.templateComponents) {
            obj.templateComponents = message.templateComponents.map((e) => e ? TemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.templateComponents = [];
        }
        message.name !== undefined && (obj.name = message.name);
        message.rejectionDetails !== undefined &&
            (obj.rejectionDetails = message.rejectionDetails
                ? MessageTemplateRejection.toJSON(message.rejectionDetails)
                : undefined);
        return obj;
    },
};
function createBaseMessageTemplateStatusUpdate() {
    return {
        status: 0,
        rejectionDetails: undefined,
        templateId: "",
        templateGroupId: "",
        templateName: "",
    };
}
export const MessageTemplateStatusUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.rejectionDetails !== undefined) {
            MessageTemplateRejection.encode(message.rejectionDetails, writer.uint32(18).fork()).ldelim();
        }
        if (message.templateId !== "") {
            writer.uint32(26).string(message.templateId);
        }
        if (message.templateGroupId !== "") {
            writer.uint32(34).string(message.templateGroupId);
        }
        if (message.templateName !== "") {
            writer.uint32(42).string(message.templateName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateStatusUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.rejectionDetails = MessageTemplateRejection.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.templateId = reader.string();
                    break;
                case 4:
                    message.templateGroupId = reader.string();
                    break;
                case 5:
                    message.templateName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status)
                ? messageTemplateStatusFromJSON(object.status)
                : 0,
            rejectionDetails: isSet(object.rejectionDetails)
                ? MessageTemplateRejection.fromJSON(object.rejectionDetails)
                : undefined,
            templateId: isSet(object.templateId) ? String(object.templateId) : "",
            templateGroupId: isSet(object.templateGroupId)
                ? String(object.templateGroupId)
                : "",
            templateName: isSet(object.templateName)
                ? String(object.templateName)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = messageTemplateStatusToJSON(message.status));
        message.rejectionDetails !== undefined &&
            (obj.rejectionDetails = message.rejectionDetails
                ? MessageTemplateRejection.toJSON(message.rejectionDetails)
                : undefined);
        message.templateId !== undefined && (obj.templateId = message.templateId);
        message.templateGroupId !== undefined &&
            (obj.templateGroupId = message.templateGroupId);
        message.templateName !== undefined &&
            (obj.templateName = message.templateName);
        return obj;
    },
};
function createBaseWAMessageTemplateIdentifier() {
    return {
        waMessageTemplateId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
    };
}
export const WAMessageTemplateIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(18).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(26).string(message.waMessageTemplateLanguage);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWAMessageTemplateIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 2:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
