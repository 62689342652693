import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaignDate } from '../../state/flow';
import { clampDate, isSameDay, minMaxDate } from './utils';

export const useCampaignDate = (): {
  dateRestrictions: {
    minDate: Date;
    maxDate: Date;
    minTime: Date | undefined;
    maxTime: Date | undefined;
  };
  isCampaignDateValid: boolean;
} => {
  const campaignDate = useSelector(selectCampaignDate);
  const campaignDateMemo = useMemo(() => (campaignDate ? new Date(campaignDate) : null), [campaignDate]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  // to update validity after date has passed
  useEffect(() => {
    if (campaignDateMemo === null) return () => {};
    const refreshAfter = campaignDateMemo.getTime() - new Date().getTime();
    const doAfter = setTimeout(() => {
      setCurrentDate(new Date());
    }, refreshAfter);
    return () => {
      clearTimeout(doAfter);
    };
  }, [campaignDateMemo]);

  const [minDate, maxDate] = useMemo(() => minMaxDate(currentDate), [currentDate]);

  const [minTime, maxTime] = useMemo(() => {
    const min = isSameDay(campaignDateMemo, minDate) ? minDate : undefined;
    const max = isSameDay(campaignDateMemo, maxDate) ? maxDate : undefined;

    return [min, max];
  }, [campaignDateMemo, minDate, maxDate]);

  const isCampaignDateValid = useMemo(
    () => clampDate(campaignDateMemo, minDate, maxDate) === campaignDateMemo,
    [minDate, maxDate, campaignDateMemo],
  );

  return {
    dateRestrictions: { minDate, maxDate, minTime, maxTime },
    isCampaignDateValid,
  };
};
