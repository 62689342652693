export enum TimeDelayDurationType {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
}

export const TimeDelayDurationOptions = {
  [TimeDelayDurationType.MINUTES]: {
    type: TimeDelayDurationType.MINUTES as const,
  },
  [TimeDelayDurationType.HOURS]: {
    type: TimeDelayDurationType.HOURS as const,
  },
};

export type TimeDelayNodeUIState = {
  delayNumber: number;
  delayNumberInput: string;
  delayNumberError?: string;
  delayType: TimeDelayDurationType;
};
