import React, { useCallback, useContext, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { Box, Button, CircularProgress, LinearProgress, Tooltip } from '@connectlyai-tenets/ui-styled-web';
import { SMBOnboardingFooterButton, SMBOnboardingFooterProps } from './types';
import { applyHook, getAppId, track, useConnectWhatsApp } from '../../utils';
import {
  goToNextStep,
  goToPreviousStep,
  OnboardingStep,
  selectBusinessName,
  selectConnectUrl,
  selectIsNextStepDisabled,
  selectIsPreviousStepDisabled,
  selectPhoneNumber,
  selectProgress,
  selectStep,
  selectUseNewPhoneNumber,
} from '../../features/welcome';
import { selectBusinessId, useFeatureFlag, useMeData } from '../../hooks';
import { NotificationContext } from '../../contexts';
import { useWhatsAppConnection } from '../../hooks/useWhatsAppConnection';

const useSMBOnboardingFooter = ({
  whatsApp,
  link,
  isLoading,
}: Partial<SMBOnboardingFooterProps>): Partial<SMBOnboardingFooterProps> => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.smbOnboardingFooter' });
  const dispatch = useDispatch();
  const { notificationProvider } = useContext(NotificationContext);

  const isNextStepDisabled = useSelector(selectIsNextStepDisabled);
  const isPreviousStepDisabled = useSelector(selectIsPreviousStepDisabled);
  const step = useSelector(selectStep) as OnboardingStep;
  const progressStep = useSelector(selectProgress);
  const connectUrl = useSelector(selectConnectUrl);

  const phoneNumber = useSelector(selectPhoneNumber);
  const useNewPhoneNumber = useSelector(selectUseNewPhoneNumber);
  const businessName = useSelector(selectBusinessName);
  const isBillingStep = step === 'payment';
  const progress = progressStep;

  const { ffBusinessServiceProviderVTEX, ffBrazilCreditLine } = useFeatureFlag([
    'ffBusinessServiceProviderVTEX',
    'ffBrazilCreditLine',
  ]);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { onConnectWhatsApp } = useWhatsAppConnection();
  const [startConnect, isConnecting] = useConnectWhatsApp(
    {
      businessId: businessId as string,
      phoneNumber: useNewPhoneNumber ? whatsApp?.claimedNumber : phoneNumber,
      name: businessName,
      appId: getAppId(ffBusinessServiceProviderVTEX),
    },
    (accessToken, userId, dataAccessExpirationTime, expiresIn) =>
      onConnectWhatsApp(
        accessToken,
        userId,
        dataAccessExpirationTime,
        expiresIn,
        ffBusinessServiceProviderVTEX ? 'BUSINESS_SERVICE_PROVIDER_VTEX' : 'BUSINESS_SERVICE_PROVIDER_CONNECTLY',
        ffBrazilCreditLine,
      ),
    () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Cancelled connecting WhatsApp',
          icon: '',
          severity: NotificationSeverity.INFO,
        },
      });
    },
  );

  const handleNextStep = useCallback(() => {
    // TODO: Investigate why we need this cast. Typescript is breaking even though no changes were made
    switch (step as OnboardingStep) {
      case 'phoneNumberSelector': {
        if (useNewPhoneNumber) {
          track('(onboarding) business name', { businessId });
        } else {
          track('(onboarding) phone number old', { businessId });
        }
        break;
      }
      case 'phoneNumberOld': {
        track('(onboarding) business name', { businessId });
        break;
      }
      case 'businessName': {
        track('(onboarding) business profile picture', { businessId });
        break;
      }
      case 'businessProfilePicture': {
        track('(onboarding) connect whatsapp', { businessId });
        break;
      }
      case 'connectWhatsApp': {
        track('(onboarding) connect shopify', { businessId });
        startConnect();
        break;
      }
      case 'connectShopify': {
        track('(onboarding) authorize payment', { businessId });
        break;
      }
      case 'payment': {
        track('(onboarding) complete', { businessId });
        break;
      }
      default: {
        break;
      }
    }
    switch (step as OnboardingStep) {
      case 'connectWhatsApp': {
        startConnect();
        break;
      }
      case 'payment': {
        break;
      }
      default: {
        dispatch(goToNextStep());
      }
    }
  }, [dispatch, startConnect, useNewPhoneNumber, businessId, step]);

  const handlePreviousStep = useCallback(() => {
    dispatch(goToPreviousStep());
  }, [dispatch]);

  const getLink = () => {
    if (step === 'connectShopify') {
      return connectUrl;
    }
    return '';
  };

  const getNextButtonLabel = () => {
    if (isLoading) {
      return t('loading');
    }
    if (isBillingStep) {
      return t('authorizePayment');
    }
    return step === 'connectWhatsApp' || step === 'connectShopify' ? t('connect') : t('next');
  };

  const previousButton: SMBOnboardingFooterButton | undefined =
    step === 'phoneNumberSelector' || isBillingStep
      ? undefined
      : {
          disabled: isPreviousStepDisabled,
          href: '',
          onClick: handlePreviousStep,
          title: t('back'),
        };

  const nextButton: SMBOnboardingFooterButton = {
    disabled: isNextStepDisabled || Boolean(isLoading),
    href: link ?? getLink(),
    onClick: handleNextStep,
    title: getNextButtonLabel(),
  };

  return {
    isConnecting,
    previousButton,
    nextButton,
    progress,
    whatsApp,
  };
};

export const SMBOnboardingFooterPresentation: VFC<SMBOnboardingFooterProps> = ({
  isConnecting,
  nextButton,
  previousButton,
  progress,
}) => {
  const {
    disabled: isPreviousStepDisabled,
    href: prevStepHref,
    title: previousStepTitle,
    onClick: onPreviousStep,
  } = previousButton || {};
  const {
    disabled: isNextStepDisabled,
    href: nextStepHref,
    title: nextStepTitle,
    onClick: onNextStep,
  } = nextButton || {};
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyOnboarding' });
  const step = useSelector(selectStep);
  const isConnectingWhatsapp = isConnecting && step === 'connectWhatsApp';
  const NextButton = (
    <Button
      variant="contained"
      disabled={isNextStepDisabled || isConnectingWhatsapp}
      href={nextStepHref || ''}
      target="_blank"
      onClick={onNextStep}
    >
      {isConnectingWhatsapp ? <CircularProgress size={16} /> : nextStepTitle}
    </Button>
  );
  return (
    <Box
      sx={{
        height: 68,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ mx: 3, display: 'flex', gap: 1, alignSelf: 'flex-end' }}>
        {previousButton && (
          <Button disabled={isPreviousStepDisabled} href={prevStepHref} onClick={onPreviousStep}>
            {previousStepTitle}
          </Button>
        )}
        {nextButton &&
          (isConnectingWhatsapp ? (
            <Tooltip arrow placement="top" title={t('loadingMessage')}>
              <Box>{NextButton}</Box>
            </Tooltip>
          ) : (
            NextButton
          ))}
      </Box>
      <LinearProgress variant="determinate" value={progress} sx={{ height: 10 }} />
    </Box>
  );
};

export const SMBOnboardingFooter = applyHook(SMBOnboardingFooterPresentation, useSMBOnboardingFooter);
