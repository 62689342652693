import React, { useMemo } from 'react';
import { ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { useMeData, selectBusinessId } from '@hooks';
import { useTranslation } from 'react-i18next';
import { useOptOutData } from '../../hooks/useOptOutData';
import { selectOptOutData } from '../../selectors';
import { OptOutTable } from '../../components/OptOutTable';
import { UploadOptOutModal } from '../../components/UploadOptOutModal';

const useOptOut = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: optOutData } = useOptOutData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectOptOutData,
  });
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('audience.optOut.title'),
      subtitle: t('audience.optOut.subtitle'),
    };
  }, [t]);
  return {
    optOutData,
    title,
    subtitle,
  };
};

export const OptOut = () => {
  const { optOutData } = useOptOut();
  return (
    <>
      <UploadOptOutModal />
      <ConnectlyCard>
        <OptOutTable data={optOutData || []} isLoading={!optOutData} />
      </ConnectlyCard>
    </>
  );
};
