export { IntegrationType } from '@connectlyai-idl/models/dist/models/integration/models';
export { AgentDto, AgentDtoCollection } from '@connectlyai-idl/models/dist/models/profile/presentations';
export { Business as BusinessDto, BusinessChannel, Role } from '@connectlyai-idl/models/dist/models/profile/models';
export { TagDto as Tag, TagInput } from '@connectlyai-idl/models/dist/models/tags/models';
export function tagName(tag) {
    var _a, _b;
    return ((_b = (_a = tag.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.v1.name) || '';
}
export function tagColor(tag) {
    var _a, _b;
    return ((_b = (_a = tag.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.v1.color) || '#FFF';
}
export function tagMutable(tag) {
    return tag.isMutable !== false;
}
export function tagIsVisibleInInbox(tag) {
    var _a, _b;
    return ((_b = (_a = tag.config) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.includes('inbox_hidden_from_tag_bar')) === false;
}
