import React from 'react';
import { ConnectlyCard, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SIZE_MEDIA_L } from '../../../../ui-theme';

export type LinkCardProps = {
  title: string;
  subtitle: string;
  navigate: () => void;
  icon?: ({ width, height }: { width: string | number; height: string | number }) => React.ReactNode;
};

export const LinkCardV2 = ({ title, subtitle, icon, navigate }: LinkCardProps) => {
  const theme = useTheme();
  return (
    <ConnectlyCard
      onClick={navigate}
      title={title}
      subtitle={subtitle}
      icon={icon ? icon({ width: theme.spacing(SIZE_MEDIA_L), height: theme.spacing(SIZE_MEDIA_L) }) : undefined}
    />
  );
};
