import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { CHANGE_ROLE_URL, ChangeUserRoleQuery, ChangeUserRolePath, ChangeUserRoleResponse } from './types';

export const useChangeUserRoleMutation = (
  options?: Omit<
    UseMutationOptions<ChangeUserRoleResponse, ConnectlyError, ChangeUserRoleQuery & ChangeUserRolePath, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    (input: ChangeUserRoleQuery & ChangeUserRolePath) =>
      api.profileV1(CHANGE_ROLE_URL, 'patch', {
        body: { input: { roles: input.roles } },
        path: { businessId: input.businessId, userId: input.userId },
      }),
    {
      ...options,
    },
  );
