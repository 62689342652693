import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { sofiaPromptAdditionAtom } from '@atoms/sofia';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../TextInput';

export const PromptAdditionTextInput = () => {
  const [value, setValue] = useAtom(sofiaPromptAdditionAtom);
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValue(newValue);
    },
    [setValue],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const { label, name, placeholder } = useMemo(() => {
    const labelText = t('promptAdditionInputLabel');
    const nameText: string = labelText.toLowerCase().replace(' ', '-');
    return {
      label: labelText,
      name: nameText,
      placeholder: t('promptAdditionPlaceholder'),
    };
  }, [t]);
  return (
    <TextInput
      label={label}
      name={name}
      value={value}
      autoFocus
      multiline
      rows={18}
      placeholder={placeholder}
      handleChange={handleChange}
    />
  );
};
