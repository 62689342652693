import { accessTokenService } from 'src/api';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { REPHRASE_PROMPT } from './constants';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';

export const useRephraseWithAI = () => {
  const prompt = (text: string) => `${REPHRASE_PROMPT} Message: ${text}`;
  const { networkClientProvider } = useContext(FlowDIContext) as FlowDIContextProps;

  return useMutation({
    mutationFn: async (text: string) => {
      const { data } = await networkClientProvider().post(
        `${process.env.REACT_APP_INBOX_API_GATEWAY}/proxy/open_ai/v1/chat/completions`,
        {
          model: 'gpt-4-turbo',
          messages: [{ role: 'user', content: prompt(text) }],
          temperature: 0.4,
          max_tokens: 200,
        },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await accessTokenService.tempSolution()}`,
          },
          timeout: 10000,
        },
      );

      return data.choices[0].message.content;
    },
  });
};
