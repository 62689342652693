// unreduxified, has only messageTemplates redux

import { useCallback, DragEvent, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Node, OnConnect, OnEdgeUpdateFunc, Edge, ReactFlowInstance, XYPosition } from 'react-flow-renderer';
import { useAtom, useAtomValue } from 'jotai';
import { ulid } from 'ulid';
import produce from 'immer';
import { useGetAtom } from '@hooks/useGetAtom';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { edgesAtom, isEdgeAttachedAtom, nodesAtom } from '@atoms/flow';
import { useCreateNode } from '../useCreateNode';
import { createEdge } from '../../../../sdks/flow/createEdge';
import { deleteNode, duplicateNode } from '../../../../state/actions';
import { DUPLICATED_NODE_POSITION_OFFSET } from './constants';
import { FlowHandlers, FlowHandlersParams } from './types';
import { NodeType } from '../../../../sdks/flow/createNode';
import { NotificationContext } from '../../../../contexts';
import { useCenterToNode } from '../useCenterToNode';

export const useFlowHandlers = ({ flowChangeAppliers }: FlowHandlersParams): FlowHandlers => {
  const { notificationProvider } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { createNode, templateName } = useCreateNode();
  const [isEdgeAttached, setIsEdgeAttached] = useAtom(isEdgeAttachedAtom);
  const nodes = useAtomValue(nodesAtom);
  const getNodes = useGetAtom(nodesAtom);
  const edges = useAtomValue(edgesAtom);

  const { centerToNode } = useCenterToNode();

  const startingEdge = useMemo(() => {
    const campaignNode = nodes.find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');
    return edges.find((edge) => edge.source === campaignNode?.id);
  }, [edges, nodes]);

  const {
    onEdgesChangeProtected: onEdgesChange,
    onNodesChangeProtected: onNodesChange,
    selectNode,
  } = flowChangeAppliers();

  const createDuplicateHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToDuplicate = nodes.find((node) => node.id === id);
      if (!nodeToDuplicate || !nodeToDuplicate?.position) {
        return;
      }

      const positionWithOffset = {
        x: nodeToDuplicate.position.x,
        y: nodeToDuplicate.position.y + DUPLICATED_NODE_POSITION_OFFSET + (nodeToDuplicate.height ?? 0),
      };

      const name = templateName();

      const newNode = produce(nodeToDuplicate, (draft) => {
        draft.id = ulid();
        draft.data.v1.name = name;
        if (draft.data.v1.messageTemplateInput) draft.data.v1.messageTemplateInput.name = name;

        draft.position = positionWithOffset;
        return draft;
      });

      onNodesChange([{ type: 'add', item: newNode }]);

      dispatch(duplicateNode(nodeToDuplicate));
      event.stopPropagation();
    },
    [dispatch, nodes, onNodesChange, templateName],
  );

  const createDeleteHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToDelete = nodes.find((node) => node.id === id);
      if (!nodeToDelete) {
        return;
      }

      onNodesChange([
        {
          id,
          type: 'remove',
        },
      ]);

      dispatch(deleteNode(nodeToDelete));
      event.stopPropagation();
    },
    [dispatch, nodes, onNodesChange],
  );

  const createDeleteEdgeHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const edgeToDelete = edges.find((edge) => edge.id === id);
      if (!edgeToDelete) {
        return;
      }

      onEdgesChange([
        {
          id,
          type: 'remove',
        },
      ]);
      event.stopPropagation();
    },
    [onEdgesChange, edges],
  );

  const createEditHandler = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      const nodeToEdit = nodes.find((node) => node.id === id);
      if (!nodeToEdit) {
        return;
      }

      selectNode(nodeToEdit.id);
      event.stopPropagation();
    },
    [nodes, selectNode],
  );

  const handleConnect: OnConnect = useCallback(
    (newConnection) => {
      const sourceNode = getNodes().find((node) => node.id === newConnection.source);
      const targetNode = getNodes().find((node) => node.id === newConnection.target);
      if (
        sourceNode?.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT' &&
        (targetNode?.data?.v1?.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE' ||
          targetNode?.type === 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT')
      ) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'This node cannot be the node of a campaign. Please connect to another node first.',
            icon: '',
            severity: NotificationSeverity.INFO,
          },
        });
        return;
      }
      onEdgesChange([
        {
          type: 'add',
          item: createEdge({
            ...newConnection,
            source: newConnection.source || '',
            target: newConnection.target || '',
          }),
        },
      ]);
    },
    [onEdgesChange, getNodes, notificationProvider],
  );

  const createStartingEdge = useCallback(
    (node: Node) => {
      const campaignNode = nodes.find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');
      if (!startingEdge && campaignNode) {
        handleConnect({
          source: campaignNode.id,
          sourceHandle: `${campaignNode.id}:send`,
          target: node.id,
          targetHandle: `${node.id}:on-execute`,
        });
      }
    },
    [handleConnect, nodes, startingEdge],
  );

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const projectPosition = useCallback(
    (pos: XYPosition, reactFlowInstance: ReactFlowInstance | undefined, flowRef: HTMLDivElement | null): XYPosition => {
      if (!flowRef || !reactFlowInstance) return { ...pos };

      const reactFlowBounds = flowRef.getBoundingClientRect();
      if (!reactFlowBounds) return { ...pos };

      return reactFlowInstance.project({
        x: pos.x - reactFlowBounds.left,
        y: pos.y - reactFlowBounds.top,
      });
    },
    [],
  );

  const handleNodeCreate = useCallback(
    (nodeType: NodeType, reactFlowInstance: ReactFlowInstance | undefined, flowRef: HTMLDivElement | null) => {
      const clientX = 600 + (nodes.length - 1) * 550;
      const clientY = 500;

      const position = projectPosition({ x: clientX, y: clientY }, reactFlowInstance, flowRef);

      const node = createNode(nodeType, {
        position,
      }) as Node;

      onNodesChange([{ type: 'add', item: node }]);

      createStartingEdge(node);
      centerToNode(node);
    },
    [createNode, createStartingEdge, nodes, onNodesChange, projectPosition, centerToNode],
  );

  const handleDrop = useCallback(
    (
      event: DragEvent<HTMLDivElement>,
      reactFlowInstance: ReactFlowInstance | undefined,
      flowRef: HTMLDivElement | null,
    ) => {
      event.preventDefault();
      const position = projectPosition({ x: event.clientX, y: event.clientY }, reactFlowInstance, flowRef);

      const node = createNode(event.dataTransfer.getData('application/reactflow') as NodeType, {
        position,
      }) as Node;

      onNodesChange([{ type: 'add', item: node }]);

      createStartingEdge(node);
      centerToNode(node);
    },
    [createNode, createStartingEdge, onNodesChange, projectPosition, centerToNode],
  );

  const handleEdgeUpdateStart = useCallback(() => {
    setIsEdgeAttached(false);
  }, [setIsEdgeAttached]);

  const handleEdgeUpdate: OnEdgeUpdateFunc = useCallback(
    (oldEdge, newConnection) => {
      setIsEdgeAttached(true);
      onEdgesChange([
        {
          id: oldEdge.id,
          type: 'remove',
        },
      ]);
      handleConnect(newConnection);
    },
    [setIsEdgeAttached, onEdgesChange, handleConnect],
  );

  const handleEdgeUpdateEnd = useCallback(
    (_event: MouseEvent, edge: Edge) => {
      if (!isEdgeAttached) {
        // edge was dropped on to the blank space, so remove it
        onEdgesChange([
          {
            id: edge.id,
            type: 'remove',
          },
        ]);
      }

      setIsEdgeAttached(true);
    },
    [setIsEdgeAttached, isEdgeAttached, onEdgesChange],
  );
  const handleNodesDelete = useCallback(
    (deathNode: Node[]) =>
      deathNode
        .filter((node) => node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT')
        .forEach((node) => {
          onNodesChange([
            {
              id: node.id,
              type: 'remove',
            },
          ]);
        }),
    [onNodesChange],
  );

  return {
    createDuplicateHandler,
    createDeleteHandler,
    createDeleteEdgeHandler,
    createEditHandler,
    handleConnect,
    handleDragOver,
    handleDrop,
    handleEdgeUpdateStart,
    handleEdgeUpdate,
    handleEdgeUpdateEnd,
    handleNodeCreate,
    handleNodesDelete,
  };
};
