import React from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { useAtomValue } from 'jotai';
import { smbOnboardingStepAtom } from '@atoms/home';
import { SMBOnboardingProps } from './types';
import { WhatsAppOnboarding } from '../WhatsAppOnboarding';
import { ShopifyOnboarding } from '../ShopifyOnboarding';
import { PaymentOnboarding } from '../../../../components/PaymentOnboarding';

export const SMBOnboardingContent = ({ onNextStep, shopify, whatsApp }: SMBOnboardingProps) => {
  let content = null;
  const step = useAtomValue(smbOnboardingStepAtom);

  switch (step) {
    case 'phoneNumberSelector':
    case 'phoneNumberOld':
    case 'businessName':
    case 'businessProfilePicture':
    case 'connectWhatsApp': {
      content = <WhatsAppOnboarding onNextStep={onNextStep} whatsApp={whatsApp} />;
      break;
    }
    case 'connectShopify': {
      content = <ShopifyOnboarding onNextStep={onNextStep} shopify={shopify} />;
      break;
    }
    case 'payment': {
      content = <PaymentOnboarding />;
      break;
    }
    default: {
      break;
    }
  }

  return <Box sx={{ flex: '1 1 auto' }}>{content}</Box>;
};
