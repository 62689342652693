import React from 'react';
import { Box, ConnectlyCard, Stack } from '@connectlyai-tenets/ui-styled-web';
import { useFeatureFlag } from '@hooks';
import {
  AnalyticsControls,
  DataTable,
  SentimentAnalysis,
  MessagesAnalysis,
  FunnelAnalysis,
  WordsAnalysis,
  TopicsAnalysis,
} from '@scenes/Sofia/components';
import { SIZE_SPACING_INTER_SECTION } from 'src/ui-theme';

const HeaderActions = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 3,
        pt: 1,
      }}
    >
      <AnalyticsControls />
    </Box>
  );
};

// Used with redesigned navigation system
export const Sofia = () => {
  const {
    ffEnableSofiaAnalyticsTimeline,
    ffEnableSofiaAnalyticsSentimentAnalysis,
    ffEnableSofiaAnalyticsFunnelAnalysis,
    ffEnableSofiaAnalyticsTopicsAnalysis,
    ffEnableSofiaAnalyticsWordsAnalysis,
  } = useFeatureFlag([
    'ffEnableSofiaAnalyticsTimeline',
    'ffEnableSofiaAnalyticsSentimentAnalysis',
    'ffEnableSofiaAnalyticsFunnelAnalysis',
    'ffEnableSofiaAnalyticsTopicsAnalysis',
    'ffEnableSofiaAnalyticsWordsAnalysis',
  ]);
  return (
    <>
      <HeaderActions />
      {ffEnableSofiaAnalyticsTimeline && <MessagesAnalysis />}
      {ffEnableSofiaAnalyticsSentimentAnalysis && <SentimentAnalysis />}
      {ffEnableSofiaAnalyticsFunnelAnalysis && <FunnelAnalysis />}
      {ffEnableSofiaAnalyticsTopicsAnalysis && ffEnableSofiaAnalyticsWordsAnalysis && (
        <Stack direction="row" gap={SIZE_SPACING_INTER_SECTION}>
          <TopicsAnalysis />
          <WordsAnalysis />
        </Stack>
      )}
      <ConnectlyCard>
        <DataTable />
      </ConnectlyCard>
    </>
  );
};
