import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocumentId, setDocumentDto, setEdges, setNodes, setRevisionId } from '../../state';
import { resetFlowChart } from '../../../../state/actions/flowChart';
import { useFlowDocumentData } from '../../../../hooks/useFlowDocumentData';
import { useChangesQueue } from '../../../../hooks/useChangesQueue';
import { selectBusinessId, useMeData } from '../../../../hooks/useMeData';
import { updateUnsentMessageTemplate } from '../../../../hooks/useCampaignFromPreview';

export const useFlowFromPreview = (): {
  loadFlowFromPreview: () => void;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const documentId = useSelector(selectDocumentId);
  const { markIdsOnline } = useChangesQueue();
  const dispatch = useDispatch();
  const {
    data: flowDocument,
    isLoading,
    isSuccess,
  } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: documentId || '',
    enabled: !!businessId && !!documentId,
  });

  const loadFlowFromPreview = useCallback(() => {
    if (flowDocument?.entity?.nodes && flowDocument?.entity?.edges) {
      const fetchedNodes = flowDocument?.entity?.nodes.map((node) => (node.options ? JSON.parse(node.options) : {}));
      const fetchedEdges = flowDocument?.entity?.edges.map((edge) => (edge.options ? JSON.parse(edge.options) : {}));

      dispatch(setNodes(fetchedNodes));
      dispatch(setEdges(fetchedEdges));
      dispatch(setRevisionId(flowDocument.entity?.lastRevisionId || ''));

      markIdsOnline(fetchedNodes.map((node) => node.id));
      markIdsOnline(fetchedEdges.map((edge) => edge.id));

      dispatch(resetFlowChart());

      // deal with unsent templates
      // load unsent templates into template slice
      fetchedNodes.forEach((node) => {
        updateUnsentMessageTemplate(node, dispatch);
      });
    }
    if (flowDocument?.entity) {
      dispatch(setDocumentDto(flowDocument.entity));
    }
  }, [dispatch, markIdsOnline, flowDocument]);

  return { loadFlowFromPreview, isLoading, isSuccess };
};
