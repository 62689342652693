import React, { VFC } from 'react';
import { Alert } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartSidebarRecipientDetailsHintProps } from './types';

export const FlowChartSidebarRecipientDetailsHint: VFC<FlowChartSidebarRecipientDetailsHintProps> = ({ content }) => {
  return (
    <Alert severity="info" color="info">
      {content}
    </Alert>
  );
};
