import React, { useContext } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { selectBusinessId, useMeData } from '@hooks';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useEventRequestGenerateAbandonedCartReport } from '@hooks/useEventRequestGenerateAbandonedCartReport';
import { AbandonedCartGenerateReportDialogProps } from './types';
import { SIZE_CONTENT_L, SIZE_CONTENT_M, SIZE_MEDIA_XL, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { isEmail } from '../../presentation/preview/utils';
import { NotificationContext } from '../../contexts';

const useAbandonedCartGenerateReportDialg = ({
  startDate,
  endDate,
  businessChannelId,
  onClose,
}: AbandonedCartGenerateReportDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  const { mutate, isLoading } = useEventRequestGenerateAbandonedCartReport({
    onSuccess: () => {
      setError('');
      setEmail('');
      onClose();
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully began processing report',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('generateReportError'),
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  const handleRequestGenerateReport = React.useCallback(() => {
    if (isEmail(email)) {
      mutate({
        businessId: businessId || '',
        emails: [email],
        startDate,
        endDate,
        businessChannelId,
      });
    } else {
      setError(t('invalidEmail'));
    }
  }, [mutate, businessId, email, businessChannelId, startDate, endDate, t]);

  const handleChangeEmail = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newEmailValue = event.target.value;
      setEmail(newEmailValue);
      setError('');
    },
    [setEmail, setError],
  );

  return {
    email,
    error,
    isLoading,
    handleChangeEmail,
    handleRequestGenerateReport,
  };
};
export const AbandonedCartGenerateReportDialog = ({
  open,
  startDate,
  endDate,
  businessChannelId,
  onClose,
}: AbandonedCartGenerateReportDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.sendoutOverview' });
  const { email, error, isLoading, handleChangeEmail, handleRequestGenerateReport } =
    useAbandonedCartGenerateReportDialg({ open, startDate, endDate, businessChannelId, onClose });

  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle>
        Provide an email to receive the report
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
            top: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          maxHeight: theme.spacing(SIZE_CONTENT_M),
          width: theme.spacing(SIZE_CONTENT_L),
          py: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
        }}
      >
        <Box
          sx={{
            width: '100%',
            py: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
          }}
        >
          <TextField
            error={Boolean(error)}
            helperText={error}
            required
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            sx={{
              width: '100%',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={isLoading} onClick={handleRequestGenerateReport}>
          {isLoading && <CircularProgress size={theme.spacing(SIZE_MEDIA_XL)} color="inherit" />}
          {!isLoading && t('sendReport')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
