import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { CustomerDIContext } from '../../context';
import { BLANK_DASH } from '../../entities';
import { selectCustomerDescription } from '../../state/customerSlice';
import { useFeatureSelector } from '../../state/hooks';
import { selectUpdateCustomerProfileState } from '../../state/updateCustomerProfileSlice';
export const BasicProfileComponentId = 'db5214c0-6eff-418a-b318-32e4ec41dd29';
function mapCustomerDescriptionAndStateToViewModel(entity, updateState) {
    return {
        description: {
            customerId: entity.customerId,
            subject: entity.subject || BLANK_DASH,
            firstName: entity.firstName || BLANK_DASH,
            lastName: entity.lastName || BLANK_DASH,
            phone: entity.phone || BLANK_DASH,
            email: entity.email || BLANK_DASH,
        },
        update: {
            firstName: updateState.firstName || '',
            lastName: updateState.lastName || '',
            email: updateState.email || '',
            phone: updateState.phone || '',
        },
        updateIsActive: updateState.isActive,
        updateIsError: updateState.isError,
        updateErrorMessage: updateState.errorMessage,
    };
}
const BasicProfile = (_props) => {
    const di = useContext(CustomerDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BasicProfileComponentId);
    const basicProfileViewModel = mapCustomerDescriptionAndStateToViewModel(useFeatureSelector(selectCustomerDescription), useFeatureSelector(selectUpdateCustomerProfileState));
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { basicProfileViewModel: basicProfileViewModel }) });
};
export default BasicProfile;
