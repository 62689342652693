import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SMBAutoReplyVariables, SMBAutoReplyResponse, SMB_AUTO_REPLY_UPDATE_URL } from './types';

export const useSMBAutoReplyMutation = (
  options?: Omit<
    UseMutationOptions<SMBAutoReplyResponse, ConnectlyError, SMBAutoReplyVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, ...others }: SMBAutoReplyVariables) =>
      api.smbV1(SMB_AUTO_REPLY_UPDATE_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: others },
      }) as Promise<SMBAutoReplyResponse>,
    options,
  );
