import { MessageNodeUIState } from './types';

export const DEFAULT_MESSAGE_NODE_UI: MessageNodeUIState = {
  body: '',
  carousel: {
    active: 0,
    cards: [
      { body: '', buttons: [{ button: { quickReply: { text: '' } } }] },
      { body: '', buttons: [{ button: { quickReply: { text: '' } } }] },
    ],
  },
};
