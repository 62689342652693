import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { sofiaStoreDescriptorAtom } from '@atoms/sofia';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  SelectChangeEvent,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import {
  SOFIA_GENDER_DISPLAY,
  SOFIA_AGE_DISPLAY,
  SOFIA_SUPPORTED_GENDERS,
  SOFIA_SUPPORTED_AGES,
} from '../../constants';
import { SofiaStoreDescriptionView, Gender, Age } from '../../types';
import { Selector } from '../Selector';
import { StoreDescriptorTextInput } from '../StoreDescriptorTextInput';
import { Languages, getLanguage } from '../../../../utils';
import { SIZE_CONTENT_S } from '../../../../ui-theme';
import { sortAge } from '../../utils';

export const InputLanguage = ({ autoFocus, disabled }: { autoFocus?: boolean; disabled?: boolean }) => {
  const theme = useTheme();
  const [descriptor, setDescriptor] = useAtom(sofiaStoreDescriptorAtom);
  const language = useMemo(() => {
    return descriptor.language;
  }, [descriptor]);
  const handleSelectChange = React.useCallback(
    (e: SelectChangeEvent<string>) => {
      setDescriptor((current: SofiaStoreDescriptionView) => ({ ...current, language: e.target.value }));
    },
    [setDescriptor],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const label = useMemo(() => {
    return t('languageSelectLabel');
  }, [t]);
  const options = useMemo(() => {
    return Languages.map((value) => ({ value, display: getLanguage(value) }));
  }, []);
  return (
    <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 1.5 }}>
      <InputLabel id="sofia-language">{label}</InputLabel>
      <Select
        autoFocus={autoFocus}
        key={language}
        name="language"
        value={language}
        onChange={handleSelectChange}
        label="sofia-language"
        disabled={disabled}
        sx={{ background: theme.palette.common.white }}
        MenuProps={{ sx: { maxHeight: theme.spacing(SIZE_CONTENT_S) } }}
      >
        {options.map((lang) => {
          return (
            <MenuItem key={lang.value} value={lang.value}>
              {lang.display}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const InputStoreName = ({ autoFocus }: { autoFocus?: boolean }) => {
  return <StoreDescriptorTextInput descriptorKey="name" autoFocus={autoFocus} requireValue />;
};

export const InputStoreUrl = ({ autoFocus }: { autoFocus?: boolean }) => {
  return <StoreDescriptorTextInput descriptorKey="url" autoFocus={autoFocus} requireValue />;
};

export const InputStoreDescription = ({ autoFocus, requireValue }: { autoFocus?: boolean; requireValue?: boolean }) => {
  return (
    <StoreDescriptorTextInput
      descriptorKey="about"
      autoFocus={autoFocus}
      multiline
      rows={4}
      requireValue={requireValue}
    />
  );
};

export const InputCustomersGender = ({ autoFocus }: { autoFocus?: boolean }) => {
  const [descriptor, setDescriptor] = useAtom(sofiaStoreDescriptorAtom);
  const gender = useMemo(() => {
    return descriptor.customersGender;
  }, [descriptor]);
  const handleSelectChange = React.useCallback(
    (choice: string) => {
      const newGender = choice as Gender;
      setDescriptor((current: SofiaStoreDescriptionView) => ({ ...current, customersGender: newGender }));
    },
    [setDescriptor],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const label = useMemo(() => {
    return t('customersInfoGenderLabel');
  }, [t]);
  return (
    <Selector
      autoFocus={autoFocus}
      value={gender}
      options={SOFIA_SUPPORTED_GENDERS.map((value) => ({ value, label: SOFIA_GENDER_DISPLAY[value] }))}
      onChange={handleSelectChange}
      label={label}
    />
  );
};

export const InputCustomersAge = () => {
  const [descriptor, setDescriptor] = useAtom(sofiaStoreDescriptorAtom);
  const age = useMemo(() => {
    return descriptor.customersAge;
  }, [descriptor]);
  const handleSelectChange = React.useCallback(
    (choices: string[]) => {
      const newAges = choices as Age[];
      setDescriptor((current: SofiaStoreDescriptionView) => ({ ...current, customersAge: newAges.sort(sortAge) }));
    },
    [setDescriptor],
  );
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const label = useMemo(() => {
    return t('customersInfoAgeLabel');
  }, [t]);
  return (
    <Selector
      value={age}
      options={SOFIA_SUPPORTED_AGES.map((value) => ({ value, label: SOFIA_AGE_DISPLAY[value] }))}
      onChange={handleSelectChange}
      label={label}
    />
  );
};

export const InputCustomersDescription = ({ autoFocus }: { autoFocus?: boolean }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const title = useMemo(() => {
    return t('customersDescriptionInputLabel');
  }, [t]);
  const placeholder = useMemo(() => {
    return t('customersDescriptionPlaceholder');
  }, [t]);
  return (
    <StoreDescriptorTextInput
      descriptorKey="customersDescription"
      noLabel
      title={title}
      autoFocus={autoFocus}
      placeholder={placeholder}
      multiline
      rows={4}
    />
  );
};

export const InputEmail = ({ autoFocus }: { autoFocus?: boolean }) => {
  return <StoreDescriptorTextInput descriptorKey="email" autoFocus={autoFocus} />;
};

export const InputPhone = () => {
  return <StoreDescriptorTextInput descriptorKey="phone" />;
};

export const InputWhatsAppLink = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const info = useMemo(() => t('supportWhatsAppLinkInfo'), [t]);
  return <StoreDescriptorTextInput descriptorKey="whatsAppLink" info={info} />;
};

export const InputFacebook = ({ autoFocus }: { autoFocus?: boolean }) => {
  return <StoreDescriptorTextInput descriptorKey="facebook" autoFocus={autoFocus} />;
};

export const InputInstagram = ({ autoFocus }: { autoFocus?: boolean }) => {
  return <StoreDescriptorTextInput descriptorKey="instagram" autoFocus={autoFocus} />;
};
