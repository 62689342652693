import { FlowVariableDeclaration, ParameterValueMapping } from '@connectlyai-tenets/sdk';
import { FlowVariableExtended, VariableMapping } from './types';
import { VARIABLE_REGEX } from './constants';

// using same algorithm as CanonicalizeIdentifier in go/tenets/fmt/fmt.go
export const canonicalize = (identifier: string) => identifier.toLowerCase().replace(/[^a-z0-9]+/g, '_');

export const mapToVariableParamMapping = (map: {
  [key: string]: string;
}): NonNullable<ParameterValueMapping['mappings']> =>
  Object.keys(map).map((key) => ({
    rawParam: key,
    fullQualifier: canonicalize(map[key]),
  }));

export const variableFqn = (variable: FlowVariableDeclaration | FlowVariableExtended) => {
  return `${variable.namespaceCanonical}${variable.namespaceCanonical ? '.' : ''}${variable.simpleNameCanonical}`;
};

export const mapToVariableHash = (text: string, rawParamPrefix: string, valueWrapper?: null | '{{}}') => {
  const flowVariables = text.match(VARIABLE_REGEX) || [];
  const out: { [key: string]: string } = {};
  flowVariables.forEach((variable, index) => {
    let value = canonicalize(variable.replace(VARIABLE_REGEX, '$1'));
    value = valueWrapper === '{{}}' ? `{{${value}}}` : value;
    out[`${rawParamPrefix}${index + 1}`] = value;
  });
  return out;
};

export const mapToVariableHashV2 = (
  text: string,
  rawParamPrefix: string,
  valueWrapper?: null | '{{}}',
  noIndex = false,
) => {
  const flowVariables = text.match(VARIABLE_REGEX) || [];
  const out: { [key: string]: string } = {};
  flowVariables.forEach((variable, index) => {
    let value = canonicalize(variable.replace(VARIABLE_REGEX, '$1'));
    value = valueWrapper === '{{}}' ? `{{${value}}}` : value;
    if (noIndex) {
      out[`${rawParamPrefix}`] = value;
    } else {
      out[`${rawParamPrefix}${index}`] = value;
    }
  });
  return out;
};

export const mappingsOf = (text: string, rawParamPrefix: string) => {
  const variablesMapped = mapToVariableHash(text, rawParamPrefix);
  return mapToVariableParamMapping(variablesMapped);
};

export const populateVariables = (
  text: string,
  rawParamPrefix: string,
  mappings?: { rawParam?: string; fullQualifier?: string }[],
) => {
  if (!mappings) return text;

  let result = text;
  mappings.forEach((variable) => {
    if (variable.rawParam?.startsWith(rawParamPrefix)) {
      const index = variable.rawParam?.replace(rawParamPrefix, '');
      result = result.replace(`{{${index}}}`, `{{${variable.fullQualifier}}}`);
    }
  });
  return result;
};

export const populateVariablesV2 = (
  text: string,
  rawParamPrefix: string,
  mappings?: { rawParam?: string; fullQualifier?: string }[],
) => {
  if (!mappings) return text;

  let result = text;
  mappings.forEach((variable) => {
    if (variable.rawParam?.startsWith(rawParamPrefix)) {
      const index = Number(variable.rawParam?.replace(rawParamPrefix, ''));
      result = result.replace(`{{${index + 1}}}`, `{{${variable.fullQualifier}}}`);
    }
  });
  return result;
};

export const substituteVariables = (text: string) => {
  const flowVariables = text.match(VARIABLE_REGEX) || [];
  let result = text;
  flowVariables.forEach((variable, index) => {
    result = result.replace(variable, `{{${index + 1}}}`);
  });
  return result;
};

export const trimCurly = (variable: string) => variable.replace(/{{|}}/g, '');

export const mappingsToHash = (mappings: VariableMapping[]) =>
  mappings.reduce((acc, mapping) => ({ ...acc, [mapping.rawParam]: mapping.fullQualifier }), {});

export const parseVariables = (str: string) =>
  Array.from(str.matchAll(/{{([^{}]*)}}/gi)).map((match) => match[1].trim());
