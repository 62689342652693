export const mutationUpdateProfileProvider = (log, networkClient) => async ({ businessId, userId, displayName }) => {
    const url = `/v1/businesses/${businessId}/users/${userId}`;
    log.debug('mutationUpdateProfile@start', () => ({
        url,
    }));
    try {
        const body = {
            businessId,
            userId,
            displayName,
        };
        const resp = await networkClient.patch(url, body);
        log.debug('mutationUpdateProfile@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationUpdateProfile@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
