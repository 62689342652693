import { AddIcon, Box, alpha } from '@connectlyai-tenets/ui-styled-web';
import React, { FC, useState } from 'react';
import { MessageNodeUIState } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';

const useCarouselFooterNavigation = (onClick?: (index: number) => void) => {
  const createHandleClick = (index: number) => () => {
    onClick?.(index);
  };

  return createHandleClick;
};

export const CarouselFooterNavigation: FC<{
  active: number;
  onClick?: (index: number) => void;
  onAdd?: () => void;
  onReorder?: (from: number, to: number) => void;
  cards: NonNullable<MessageNodeUIState['carousel']>['cards'];
  outOfLimit: boolean;
}> = ({ active, onClick, onAdd, cards, onReorder, outOfLimit }) => {
  const createHandleClick = useCarouselFooterNavigation(onClick);
  const [dragOverIndex, setDragOverIndex] = useState<number>();
  const [fromIndex, setFromIndex] = useState<number>();

  const handleDragEnd = () => {
    if (fromIndex !== undefined && dragOverIndex !== undefined && fromIndex !== dragOverIndex) {
      onReorder?.(fromIndex, dragOverIndex);
    }
    setDragOverIndex(undefined);
  };

  const createDragOverHandler = (index: number) => () => {
    if (fromIndex === index) {
      return;
    }
    if (Number(fromIndex) + 1 === index) {
      return;
    }
    setDragOverIndex(index);
  };

  const createDragStartHandler = (index: number) => () => {
    setFromIndex(index);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {cards.map((card, index) => (
        <>
          {dragOverIndex === index && (
            <Box
              sx={{
                width: 24,
                height: 28,
                borderRadius: 1,
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: 'grey.300',
                mr: 1,
              }}
            />
          )}
          <Box
            onClick={createHandleClick(index)}
            draggable
            onDragStart={createDragStartHandler(index)}
            onDragEnd={handleDragEnd}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onDragOver={createDragOverHandler(index)}
            sx={{
              width: 24,
              height: 28,
              borderRadius: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              cursor: 'pointer',
              mr: 1,
              borderColor: (theme) =>
                index === active ? theme.palette.primary.main : alpha(theme.palette.common.black, 0.12),
              background: (theme) => {
                if (
                  card.header &&
                  card.header.media?.type === 'TYPE_IMAGE' &&
                  Array.isArray(card.header.media.example)
                ) {
                  return `url(${card.header.media.example[0]})`;
                }
                return theme.palette.grey[100];
              },
              ...(index === active && {
                outlineColor: (theme) => theme.palette.primary.main,
                outlineStyle: 'solid',
                outlineWidth: 2,
              }),
              backgroundSize: 'cover',
            }}
          />
        </>
      ))}
      {dragOverIndex === cards.length && (
        <Box
          sx={{
            width: 24,
            height: 28,
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: 'grey.300',
            mr: 1,
          }}
        />
      )}
      {!outOfLimit && (
        <Box
          onClick={onAdd}
          onDragOver={createDragOverHandler(cards.length)}
          sx={{
            width: 24,
            height: 28,
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: 'solid',
            cursor: 'pointer',
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: (theme) => theme.palette.common.white,
          }}
        >
          <AddIcon />
        </Box>
      )}
    </Box>
  );
};
