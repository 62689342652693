import { ulid } from 'ulid';
import { Node } from 'react-flow-renderer';
import type { TimeDelayData } from '@connectlyai-tenets/sdk';

import { CREATE_NEW_TEMPLATE_ID } from '../../state/messageTemplates/constants';

export type NodeType =
  | 'FLOW_OBJECT_TYPE_AI_ASSISTANT'
  | 'FLOW_OBJECT_TYPE_CALL_API'
  | 'FLOW_OBJECT_TYPE_OPT_OUT'
  | 'FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY'
  | 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN'
  | 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT'
  | 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_LIST_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_WA_CTA_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE'
  | 'FLOW_OBJECT_TYPE_SEND_WA_QUICK_REPLY_MESSAGE'
  | 'FLOW_OBJECT_TYPE_TIME_DELAY'
  | 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE';

export type TemplateType =
  | 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE'
  | 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE'
  | 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE'
  | 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE'
  | 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE';

const timeDelayDataAtCreate: TimeDelayData = {
  v1: {
    input: {
      delayInSeconds: 10 * 60 * 60, // 10 hours
    },
  },
};

const nodesDictionary: Record<NodeType, Node<Record<string, unknown>>> = {
  FLOW_OBJECT_TYPE_AI_ASSISTANT: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_AI_ASSISTANT',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_CALL_API: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_CALL_API',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_OPT_OUT: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_OPT_OUT',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_SEND_WA_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        waMessageTemplateId: CREATE_NEW_TEMPLATE_ID,
      },
    },
  },
  FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY',
    position: { x: 0, y: 0 },
    data: {},
  },
  FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        waMessageTemplateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_WA_QUICK_REPLY_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        waMessageTemplateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_WA_CTA_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        waMessageTemplateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
      },
    },
  },
  FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
        roomEventType: 'ROOM_EVENT_TYPE_MESSAGE',
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        templateId: CREATE_NEW_TEMPLATE_ID,
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        templateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE',
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        templateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE',
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_LIST_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        waMessageTemplateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE',
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        templateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE',
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      v1: {
        templateId: CREATE_NEW_TEMPLATE_ID,
        waMessageTemplateType: 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE',
        messageTemplateInput: undefined,
      },
    },
  },
  FLOW_OBJECT_TYPE_TIME_DELAY: {
    id: '',
    type: 'FLOW_OBJECT_TYPE_TIME_DELAY',
    position: { x: 0, y: 0 },
    data: timeDelayDataAtCreate,
  },
};

export const createNode = (type: NodeType): Node<Record<string, unknown>> | undefined => {
  const newNode = nodesDictionary[type];
  if (newNode) {
    return {
      ...newNode,
      id: ulid(),
    };
  }
  return undefined;
};
