import { jsx as _jsx } from "react/jsx-runtime";
import SvgIcon from '@mui/material/SvgIcon';
import { ConnectlyIconKind } from '@connectlyai-tenets/ui-icons';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactComponent as ACMessageIcon } from './ac-message.svg';
import { ReactComponent as AIAssistantIcon } from './ai-assistant.svg';
import { ReactComponent as AIBubbleIcon } from './ai-bubble.svg';
import { ReactComponent as AIMessageIcon } from './ai-message.svg';
import { ReactComponent as AnalyticsIcon } from './analytics.svg';
import { ReactComponent as AnalyticsIconV2 } from './analyticsV2.svg';
import { ReactComponent as APIIcon } from './api-call.svg';
import { ReactComponent as AudienceIcon } from './audience.svg';
import { ReactComponent as AutomationIcon } from './automation.svg';
import { ReactComponent as AutomationNavIcon } from './automation-icon.svg';
import { ReactComponent as CampaignBlackIcon } from './campaign-black.svg';
import { ReactComponent as CampaignIcon } from './campaign.svg';
import { ReactComponent as CampaignStartIcon } from './campaign-start.svg';
import { ReactComponent as CampaignStartIconV2 } from './campaign-start-v2.svg';
import { ReactComponent as CarouselIcon } from './carousel-icon.svg';
import { ReactComponent as CartIcon } from './cart.svg';
import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as ConnectlyBroadcastIcon } from './connectly-broadcast.svg';
import { ReactComponent as ConnectlyCelebrationIcon } from './connectly-celebration.svg';
import { ReactComponent as ConnectlyDeleteIcon } from './connectly-delete.svg';
import { ReactComponent as ConnectlyFestiveIcon } from './connectly-festive.svg';
import { ReactComponent as ConnectlyMessageIcon } from './connectly-message.svg';
import { ReactComponent as DesktopIcon } from './desktop.svg';
import { ReactComponent as DocumentBlueIcon } from './document-blue.svg';
import { ReactComponent as DocumentIcon } from './document.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { ReactComponent as DocumentStarsCloudsIcon } from './document-stars-clouds.svg';
import { ReactComponent as DocumentStarsIcon } from './document-stars.svg';
import { ReactComponent as DraggableDotsIcon } from './draggable-dots.svg';
import { ReactComponent as DragIcon } from './drag.svg';
import { ReactComponent as EmptyCanvasIcon } from './empty-canvas.svg';
import { ReactComponent as EmptyProfilePictureIcon } from './empty-profile-picture.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as FlowStartIcon } from './flow-start.svg';
import { ReactComponent as FreeTextIcon } from './free-text.svg';
import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as IfCustomerRepliesNodeIcon } from './if-customer-replies-node.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as LargeDeviceIcon } from './large-device.svg';
import { ReactComponent as LightningIcon } from './lightning.svg';
import { ReactComponent as MessageCornerIcon } from './message-corner.svg';
import { ReactComponent as MessageEventIcon } from './message-event.svg';
import { ReactComponent as MessageVerificationIcon } from './message-verification.svg';
import { ReactComponent as MessengerIcon } from './messenger.svg';
import { ReactComponent as OptOutIcon } from './opt-out.svg';
import { ReactComponent as OptOutIndigoIcon } from './opt-out-indigo.svg';
import { ReactComponent as PencilIcon } from './pencil.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as PrefaceIcon } from './preface.svg';
import { ReactComponent as ProfilePencilBackgroundIcon } from './profile-pencil-background.svg';
import { ReactComponent as QuestionAnswerAIIcon } from './question-answer-ai.svg';
import { ReactComponent as QuestionIcon } from './question.svg';
import { ReactComponent as RocketIcon } from './rocket.svg';
import { ReactComponent as ShopifyIcon } from './shopify.svg';
import { ReactComponent as SmallDeviceIcon } from './small-device.svg';
import { ReactComponent as SMSIcon } from './sms.svg';
import { ReactComponent as SofiaIcon } from './sofia.svg';
import { ReactComponent as SofiaPurpleIcon } from './sofia-purple.svg';
import { ReactComponent as SofiaStarsIcon } from './sofia-stars.svg';
import { ReactComponent as SpeechBubblesIcon } from './speech-bubbles.svg';
import { ReactComponent as StartFromScratchIcon } from './start-from-scratch.svg';
import { ReactComponent as TargetIcon } from './target.svg';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as VtexLogoGreyIcon } from './vtex-logo-grey.svg';
import { ReactComponent as VtexLogoIcon } from './vtex-logo.svg';
import { ReactComponent as WhatsAppCheckIcon } from './whatsapp-check.svg';
import { ReactComponent as WhatsAppIcon } from './whatsapp.svg';
import { ReactComponent as WhatsAppMessageTemplateIcon } from './whatsapp-message-template.svg';
import { ReactComponent as WhatsAppMonoIcon } from './whatsapp-mono.svg';
import { ReactComponent as WhatsAppOutlineIcon } from './whatsapp-outline.svg';
import { ReactComponent as WhitePencilIcon } from './white-pencil.svg';
import { ReactComponent as WidgetBorderBlackIcon } from './widget-border-black.svg';
import { ReactComponent as WidgetBorderGradientIcon } from './widget-border-gradient.svg';
import { ReactComponent as WidgetBorderRainbowIcon } from './widget-border-rainbow.svg';
import { ReactComponent as WidgetIcon } from './widget.svg';
export { ACMessageIcon, AIAssistantIcon, AIBubbleIcon, AIMessageIcon, AnalyticsIcon, AnalyticsIconV2, APIIcon, AudienceIcon, AutomationIcon, AutomationNavIcon, BarChartIcon, CampaignBlackIcon, CampaignIcon, CampaignStartIcon, CampaignStartIconV2, CarouselIcon, CartIcon, CompanyIcon, ConnectlyBroadcastIcon, ConnectlyCelebrationIcon, ConnectlyDeleteIcon, ConnectlyFestiveIcon, ConnectlyMessageIcon, DesktopIcon, DocumentBlueIcon, DocumentIcon, DocumentsIcon, DocumentStarsCloudsIcon, DocumentStarsIcon, DraggableDotsIcon, DragIcon, EmptyCanvasIcon, EmptyProfilePictureIcon, FacebookIcon, FlowStartIcon, FreeTextIcon, IfCustomerRepliesNodeIcon, InfoIcon, InstagramIcon, LargeDeviceIcon, LightningIcon, MessageCornerIcon, MessageEventIcon, MessageVerificationIcon, MessengerIcon, OptOutIcon, OptOutIndigoIcon, PencilIcon, PlusIcon, PrefaceIcon, ProfilePencilBackgroundIcon, QuestionAnswerAIIcon, QuestionIcon, RocketIcon, SettingsIcon, ShopifyIcon, SmallDeviceIcon, SMSIcon, SofiaIcon, SofiaPurpleIcon, SofiaStarsIcon, SpeechBubblesIcon, StartFromScratchIcon, TargetIcon, TrashIcon, VtexLogoGreyIcon, VtexLogoIcon, WhatsAppCheckIcon, WhatsAppIcon, WhatsAppMessageTemplateIcon, WhatsAppMonoIcon, WhatsAppOutlineIcon, WhitePencilIcon, WidgetBorderBlackIcon, WidgetBorderGradientIcon, WidgetBorderRainbowIcon, WidgetIcon, };
const iconsDictionary = {
    [ConnectlyIconKind.UNKNOWN]: null,
    [ConnectlyIconKind.AC_MESSAGE]: ACMessageIcon,
    [ConnectlyIconKind.AI_MESSAGE]: AIMessageIcon,
    [ConnectlyIconKind.CONNECTLY]: CompanyIcon,
    [ConnectlyIconKind.INSTAGRAM]: InstagramIcon,
    [ConnectlyIconKind.MESSENGER]: MessengerIcon,
    [ConnectlyIconKind.SMS]: SMSIcon,
    [ConnectlyIconKind.AUTOMATION_NAV_ICON]: AutomationNavIcon,
    [ConnectlyIconKind.WHATSAPP]: WhatsAppIcon,
    [ConnectlyIconKind.FACEBOOK]: FacebookIcon,
    [ConnectlyIconKind.CONNECTLY_FESTIVE]: ConnectlyFestiveIcon,
    [ConnectlyIconKind.CONNECTLY_CELEBRATION]: ConnectlyCelebrationIcon,
    [ConnectlyIconKind.INBOX]: ConnectlyMessageIcon,
    [ConnectlyIconKind.SETTINGS]: SettingsIcon,
    [ConnectlyIconKind.BROADCAST]: ConnectlyBroadcastIcon,
    [ConnectlyIconKind.WIDGET]: WidgetIcon,
    [ConnectlyIconKind.HOME]: HomeIcon,
    [ConnectlyIconKind.QUESTION]: QuestionIcon,
    [ConnectlyIconKind.ANALYTICS]: BarChartIcon,
};
export const ConnectlyIcon = ({ kind, ...rest }) => {
    const Comp = iconsDictionary[kind];
    if (!Comp) {
        return null;
    }
    return _jsx(SvgIcon, { component: Comp, ...rest });
};
