import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ReactFlowInstance } from 'react-flow-renderer';
import { MutationCreateOrUpdateReactFlowDocumentResponse } from '@hooks/useCreateOrUpdateFlowDocumentMutation/types';
import { CompileReactFlowDocumentResult } from '@hooks/useRequestCompileReactFlowDocument/types';
import { FlowChange } from '@components/FlowChartCampaignV3/hooks/useChangesQueue/types';
import { CampaignNameHintColor } from '@components/FlowChartCampaignV3/components/CampaignName/types';
import { CampaignStep } from '@components/FlowChartCampaignV3/hooks/useCampaignStep/types';
import { DuplicateFrom } from '@components/FlowChartCampaignV3/components/ChooseCampaign/types';
import { AudienceState, AudienceOptionType } from '@components/FlowChartCampaignV3/components/ChooseAudience/types';
import { CampaignStarter } from '@components/FlowChartCampaignV3/types';
import type { Edge, Node } from 'react-flow-renderer';
import { AIMessage, AIState, CampaignTemplateType } from '@hooks/useAICampaign/types';
import { NodeUIState } from '@components/FlowChartCampaignV3/hooks/useNodeUIState/types';
import { FlowVariableExtended } from '@hooks/useFlowVariables/types';
import { SpreadsheetUrl, SpreadsheetFrquencyCapOption } from '@hooks/useInitializeSpreadsheetMutation/types';

export const aiStateAtom = atom<AIState>('planning');
export const aiConversationAtom = atom<AIMessage[]>([]);
export const audienceAtom = atom<AudienceState>({
  type: AudienceOptionType.SPREADSHEET,
  [AudienceOptionType.AUDIENCE]: { segmentId: '' },
});
export const campaignDateAtom = atom<Date | null>(null);
export const campaignDropdownInputIdAtom = atom('');
export const campaignDropdownHoverIdAtom = atom('');
export const campaignLanguageAtom = atomWithStorage('campaignLanguage', 'en');
export const campaignNameAtom = atom('');
export const campaignNameColorAtom = atom<CampaignNameHintColor>(undefined);
export const campaignNameHintAtom = atom('');
export const campaignStepAtom = atom<CampaignStep>('preface');
export const campaignTemplateAtom = atom<CampaignTemplateType | null>(null);
export const changesQueueAtom = atom<FlowChange[]>([]);
export const compileResultAtom = atom<CompileReactFlowDocumentResult>({});
export const documentIdAtom = atom('');
export const documentTypeAtom = atom<NonNullable<MutationCreateOrUpdateReactFlowDocumentResponse['entity']>['type']>(
  'FLOW_DOCUMENT_TYPE_STB_SENDOUT_V1',
);
export const documentVersionIdAtom = atom('');
export const duplicateDocumentAtom = atom<DuplicateFrom | null>(null);
export const edgesAtom = atom<Edge[]>([]);
export const exitFlowModalOpenAtom = atom<boolean>(false);
export const getStartedCategoryAtom = atom('new-campaign');
export const hoveringNodeIdAtom = atom('');
export const isAISidebarOpenAtom = atom(false);
export const isCampaignNameDialogOpenAtom = atom(false);
export const isCampaignNameDirtyAtom = atom(false);
export const isEdgeAttachedAtom = atom(false);
export const isFlowChecksOpenAtom = atom<boolean>(false);
export const isFlowPopulatedAtom = atom<boolean>(false);
export const isSendingChangeAtom = atom(false);
export const isSpreadsheetOpenAtom = atom(false);
export const movingNodeIdsAtom = atom<string[]>([]);
export const nodesAtom = atom<Node[]>([]);
export const nodesUIStateAtom = atom<Record<string, NodeUIState>>({});
export const onlineIdsAtom = atom<string[]>([]);
export const prevCampaignNameAtom = atom('');
export const previewDocumentIdAtom = atom('');
export const reactFlowInstanceAtom = atom<ReactFlowInstance | null>(null);
export const revisionIdAtom = atom('');
export const savedCampaignNameAtom = atom('');
export const sendoutIdAtom = atom('');
export const spreadsheetURLAtom = atom<SpreadsheetUrl>(undefined);
export const frequencyCapAtom = atom<SpreadsheetFrquencyCapOption>(undefined);
export const starterAtom = atom<CampaignStarter>('empty');
export const templateApprovalDialogOpenAtom = atom(false);
export const flowVariablesAtom = atom<{ [fqn: string]: FlowVariableExtended }>({});
export const flowVariablesListDerivedAtom = atom((get) => {
  const variables = get(flowVariablesAtom);
  return Object.keys(variables).map((key) => variables[key]);
});
export const mappingsAtom = atom<{
  [key: string]: string;
}>({});

// derived atoms
export const customSendoutNodeAtom = atom((get) =>
  get(nodesAtom).find((node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT'),
);
export const selectedNodeAtom = atom((get) => get(nodesAtom).find((node) => node.selected));
export const selectedNodeIdAtom = atom((get) => get(selectedNodeAtom)?.id);

export const selectedNodeUIStateAtom = atom(
  (get) => {
    return get(nodesUIStateAtom)[get(selectedNodeAtom)?.id || ''] || undefined;
  },
  (get, set, newValue: NodeUIState) => {
    const id = get(selectedNodeAtom)?.id;
    if (!id) return;
    set(nodesUIStateAtom, { ...get(nodesUIStateAtom), [id]: newValue });
  },
);

export const hasUnsavedChangesAtom = atom((get) => get(changesQueueAtom).length > 0);
export const isAIGeneratingAtom = atom((get) => get(aiStateAtom) === 'generating');
export const isDocumentEditableAtom = atom(
  (get) => get(campaignStepAtom) === 'build' && get(aiStateAtom) !== 'generating',
);
