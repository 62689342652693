import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Label, Radio, RadioGroup } from '@connectlyai-tenets/ui-styled-web';
import { PhoneNumberSelectorProps } from './types';
import { applyHook } from '../../../../utils';
import { selectUseNewPhoneNumber, setIsNewPhoneNumber } from '../../../../features/welcome';

const usePhoneNumberSelector = (_props: Partial<PhoneNumberSelectorProps>): PhoneNumberSelectorProps => {
  const useNewPhoneNumber = useSelector(selectUseNewPhoneNumber);

  const dispatch = useDispatch();
  const handleChange = useCallback(
    (value: boolean) => {
      dispatch(setIsNewPhoneNumber(value));
    },
    [dispatch],
  );

  return {
    onChange: handleChange,
    useNewPhoneNumber,
  };
};

export const PhoneNumberSelectorPresentation = ({ onChange, useNewPhoneNumber }: PhoneNumberSelectorProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.phoneNumberSelector' });
  const handleChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(value === 'true');
    },
    [onChange],
  );

  return (
    <Box>
      <Label>{t('title1')}</Label>
      <RadioGroup value={useNewPhoneNumber ? 'true' : 'false'} onChange={handleChange}>
        <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
        <FormControlLabel value="false" control={<Radio />} label={t('no')} />
      </RadioGroup>
    </Box>
  );
};

export const PhoneNumberSelector = applyHook(PhoneNumberSelectorPresentation, usePhoneNumberSelector);
