import Long from "long";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.webhook.v6_0";
export var FbLoginType;
(function (FbLoginType) {
    FbLoginType[FbLoginType["FB_LOGIN_TYPE_UNSPECIFIED"] = 0] = "FB_LOGIN_TYPE_UNSPECIFIED";
    FbLoginType[FbLoginType["FB_LOGIN_TYPE_FACEBOOK"] = 1] = "FB_LOGIN_TYPE_FACEBOOK";
    FbLoginType[FbLoginType["FB_LOGIN_TYPE_INSTAGRAM"] = 2] = "FB_LOGIN_TYPE_INSTAGRAM";
    FbLoginType[FbLoginType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FbLoginType || (FbLoginType = {}));
export function fbLoginTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "FB_LOGIN_TYPE_UNSPECIFIED":
            return FbLoginType.FB_LOGIN_TYPE_UNSPECIFIED;
        case 1:
        case "FB_LOGIN_TYPE_FACEBOOK":
            return FbLoginType.FB_LOGIN_TYPE_FACEBOOK;
        case 2:
        case "FB_LOGIN_TYPE_INSTAGRAM":
            return FbLoginType.FB_LOGIN_TYPE_INSTAGRAM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FbLoginType.UNRECOGNIZED;
    }
}
export function fbLoginTypeToJSON(object) {
    switch (object) {
        case FbLoginType.FB_LOGIN_TYPE_UNSPECIFIED:
            return "FB_LOGIN_TYPE_UNSPECIFIED";
        case FbLoginType.FB_LOGIN_TYPE_FACEBOOK:
            return "FB_LOGIN_TYPE_FACEBOOK";
        case FbLoginType.FB_LOGIN_TYPE_INSTAGRAM:
            return "FB_LOGIN_TYPE_INSTAGRAM";
        case FbLoginType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var FbMessengerAttachment_AttachmentType;
(function (FbMessengerAttachment_AttachmentType) {
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["unspecified"] = 0] = "unspecified";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["image"] = 1] = "image";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["video"] = 2] = "video";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["audio"] = 3] = "audio";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["file"] = 4] = "file";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["template"] = 5] = "template";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["fallback"] = 6] = "fallback";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["location"] = 7] = "location";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["story_mention"] = 8] = "story_mention";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["share"] = 9] = "share";
    FbMessengerAttachment_AttachmentType[FbMessengerAttachment_AttachmentType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FbMessengerAttachment_AttachmentType || (FbMessengerAttachment_AttachmentType = {}));
export function fbMessengerAttachment_AttachmentTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return FbMessengerAttachment_AttachmentType.unspecified;
        case 1:
        case "image":
            return FbMessengerAttachment_AttachmentType.image;
        case 2:
        case "video":
            return FbMessengerAttachment_AttachmentType.video;
        case 3:
        case "audio":
            return FbMessengerAttachment_AttachmentType.audio;
        case 4:
        case "file":
            return FbMessengerAttachment_AttachmentType.file;
        case 5:
        case "template":
            return FbMessengerAttachment_AttachmentType.template;
        case 6:
        case "fallback":
            return FbMessengerAttachment_AttachmentType.fallback;
        case 7:
        case "location":
            return FbMessengerAttachment_AttachmentType.location;
        case 8:
        case "story_mention":
            return FbMessengerAttachment_AttachmentType.story_mention;
        case 9:
        case "share":
            return FbMessengerAttachment_AttachmentType.share;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FbMessengerAttachment_AttachmentType.UNRECOGNIZED;
    }
}
export function fbMessengerAttachment_AttachmentTypeToJSON(object) {
    switch (object) {
        case FbMessengerAttachment_AttachmentType.unspecified:
            return "unspecified";
        case FbMessengerAttachment_AttachmentType.image:
            return "image";
        case FbMessengerAttachment_AttachmentType.video:
            return "video";
        case FbMessengerAttachment_AttachmentType.audio:
            return "audio";
        case FbMessengerAttachment_AttachmentType.file:
            return "file";
        case FbMessengerAttachment_AttachmentType.template:
            return "template";
        case FbMessengerAttachment_AttachmentType.fallback:
            return "fallback";
        case FbMessengerAttachment_AttachmentType.location:
            return "location";
        case FbMessengerAttachment_AttachmentType.story_mention:
            return "story_mention";
        case FbMessengerAttachment_AttachmentType.share:
            return "share";
        case FbMessengerAttachment_AttachmentType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseFbMessengerHub() {
    return { mode: "", verifyToken: "", challenge: "" };
}
export const FbMessengerHub = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.mode !== "") {
            writer.uint32(10).string(message.mode);
        }
        if (message.verifyToken !== "") {
            writer.uint32(18).string(message.verifyToken);
        }
        if (message.challenge !== "") {
            writer.uint32(26).string(message.challenge);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerHub();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.mode = reader.string();
                    break;
                case 2:
                    message.verifyToken = reader.string();
                    break;
                case 3:
                    message.challenge = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            mode: isSet(object.mode) ? String(object.mode) : "",
            verifyToken: isSet(object.verifyToken) ? String(object.verifyToken) : "",
            challenge: isSet(object.challenge) ? String(object.challenge) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.mode !== undefined && (obj.mode = message.mode);
        message.verifyToken !== undefined &&
            (obj.verifyToken = message.verifyToken);
        message.challenge !== undefined && (obj.challenge = message.challenge);
        return obj;
    },
};
function createBaseFbLoginStateParameter() {
    return { id: "", type: 0, businessId: "" };
}
export const FbLoginStateParameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.businessId !== "") {
            writer.uint32(26).string(message.businessId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbLoginStateParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.businessId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            type: isSet(object.type) ? fbLoginTypeFromJSON(object.type) : 0,
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.type !== undefined && (obj.type = fbLoginTypeToJSON(message.type));
        message.businessId !== undefined && (obj.businessId = message.businessId);
        return obj;
    },
};
function createBaseFbMessengerEntryMessagingSender() {
    return { id: "" };
}
export const FbMessengerEntryMessagingSender = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerEntryMessagingSender();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseFbMessengerEntryMessagingRecipient() {
    return { id: "" };
}
export const FbMessengerEntryMessagingRecipient = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerEntryMessagingRecipient();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseFbMessengerMessage() {
    return {
        mid: "",
        text: "",
        isEcho: false,
        attachments: [],
        replyTo: undefined,
    };
}
export const FbMessengerMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.mid !== "") {
            writer.uint32(10).string(message.mid);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        if (message.isEcho === true) {
            writer.uint32(24).bool(message.isEcho);
        }
        for (const v of message.attachments) {
            FbMessengerAttachment.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.replyTo !== undefined) {
            FbMessengerReply.encode(message.replyTo, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.mid = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.isEcho = reader.bool();
                    break;
                case 4:
                    message.attachments.push(FbMessengerAttachment.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.replyTo = FbMessengerReply.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            mid: isSet(object.mid) ? String(object.mid) : "",
            text: isSet(object.text) ? String(object.text) : "",
            isEcho: isSet(object.isEcho) ? Boolean(object.isEcho) : false,
            attachments: Array.isArray(object === null || object === void 0 ? void 0 : object.attachments)
                ? object.attachments.map((e) => FbMessengerAttachment.fromJSON(e))
                : [],
            replyTo: isSet(object.replyTo)
                ? FbMessengerReply.fromJSON(object.replyTo)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.mid !== undefined && (obj.mid = message.mid);
        message.text !== undefined && (obj.text = message.text);
        message.isEcho !== undefined && (obj.isEcho = message.isEcho);
        if (message.attachments) {
            obj.attachments = message.attachments.map((e) => e ? FbMessengerAttachment.toJSON(e) : undefined);
        }
        else {
            obj.attachments = [];
        }
        message.replyTo !== undefined &&
            (obj.replyTo = message.replyTo
                ? FbMessengerReply.toJSON(message.replyTo)
                : undefined);
        return obj;
    },
};
function createBaseFbMessengerReply() {
    return { story: undefined };
}
export const FbMessengerReply = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.story !== undefined) {
            FbMessengerStory.encode(message.story, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerReply();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.story = FbMessengerStory.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            story: isSet(object.story)
                ? FbMessengerStory.fromJSON(object.story)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.story !== undefined &&
            (obj.story = message.story
                ? FbMessengerStory.toJSON(message.story)
                : undefined);
        return obj;
    },
};
function createBaseFbMessengerStory() {
    return { id: "", url: "" };
}
export const FbMessengerStory = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerStory();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            url: isSet(object.url) ? String(object.url) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.url !== undefined && (obj.url = message.url);
        return obj;
    },
};
function createBaseFbMessengerAttachment() {
    return { type: 0, payload: undefined };
}
export const FbMessengerAttachment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.payload !== undefined) {
            FbMessengerAttachmentPayload.encode(message.payload, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerAttachment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.payload = FbMessengerAttachmentPayload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? fbMessengerAttachment_AttachmentTypeFromJSON(object.type)
                : 0,
            payload: isSet(object.payload)
                ? FbMessengerAttachmentPayload.fromJSON(object.payload)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = fbMessengerAttachment_AttachmentTypeToJSON(message.type));
        message.payload !== undefined &&
            (obj.payload = message.payload
                ? FbMessengerAttachmentPayload.toJSON(message.payload)
                : undefined);
        return obj;
    },
};
function createBaseFbMessengerAttachmentPayload() {
    return { url: "" };
}
export const FbMessengerAttachmentPayload = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerAttachmentPayload();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        return obj;
    },
};
function createBaseFbMessengerEntryMessaging() {
    return {
        sender: undefined,
        recipient: undefined,
        message: undefined,
        postback: undefined,
    };
}
export const FbMessengerEntryMessaging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== undefined) {
            FbMessengerEntryMessagingSender.encode(message.sender, writer.uint32(10).fork()).ldelim();
        }
        if (message.recipient !== undefined) {
            FbMessengerEntryMessagingRecipient.encode(message.recipient, writer.uint32(18).fork()).ldelim();
        }
        if (message.message !== undefined) {
            FbMessengerMessage.encode(message.message, writer.uint32(26).fork()).ldelim();
        }
        if (message.postback !== undefined) {
            FbMessengerPostback.encode(message.postback, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerEntryMessaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = FbMessengerEntryMessagingSender.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.recipient = FbMessengerEntryMessagingRecipient.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.message = FbMessengerMessage.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.postback = FbMessengerPostback.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender)
                ? FbMessengerEntryMessagingSender.fromJSON(object.sender)
                : undefined,
            recipient: isSet(object.recipient)
                ? FbMessengerEntryMessagingRecipient.fromJSON(object.recipient)
                : undefined,
            message: isSet(object.message)
                ? FbMessengerMessage.fromJSON(object.message)
                : undefined,
            postback: isSet(object.postback)
                ? FbMessengerPostback.fromJSON(object.postback)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined &&
            (obj.sender = message.sender
                ? FbMessengerEntryMessagingSender.toJSON(message.sender)
                : undefined);
        message.recipient !== undefined &&
            (obj.recipient = message.recipient
                ? FbMessengerEntryMessagingRecipient.toJSON(message.recipient)
                : undefined);
        message.message !== undefined &&
            (obj.message = message.message
                ? FbMessengerMessage.toJSON(message.message)
                : undefined);
        message.postback !== undefined &&
            (obj.postback = message.postback
                ? FbMessengerPostback.toJSON(message.postback)
                : undefined);
        return obj;
    },
};
function createBaseFbMessengerEntry() {
    return { id: "", time: 0, messaging: [] };
}
export const FbMessengerEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.time !== 0) {
            writer.uint32(16).uint64(message.time);
        }
        for (const v of message.messaging) {
            FbMessengerEntryMessaging.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.time = longToNumber(reader.uint64());
                    break;
                case 3:
                    message.messaging.push(FbMessengerEntryMessaging.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            time: isSet(object.time) ? Number(object.time) : 0,
            messaging: Array.isArray(object === null || object === void 0 ? void 0 : object.messaging)
                ? object.messaging.map((e) => FbMessengerEntryMessaging.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.time !== undefined && (obj.time = Math.round(message.time));
        if (message.messaging) {
            obj.messaging = message.messaging.map((e) => e ? FbMessengerEntryMessaging.toJSON(e) : undefined);
        }
        else {
            obj.messaging = [];
        }
        return obj;
    },
};
function createBaseFbMessengerWebhookEnvelope() {
    return { object: "", entry: [] };
}
export const FbMessengerWebhookEnvelope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.object !== "") {
            writer.uint32(10).string(message.object);
        }
        for (const v of message.entry) {
            FbMessengerEntry.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerWebhookEnvelope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.object = reader.string();
                    break;
                case 2:
                    message.entry.push(FbMessengerEntry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            object: isSet(object.object) ? String(object.object) : "",
            entry: Array.isArray(object === null || object === void 0 ? void 0 : object.entry)
                ? object.entry.map((e) => FbMessengerEntry.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.object !== undefined && (obj.object = message.object);
        if (message.entry) {
            obj.entry = message.entry.map((e) => e ? FbMessengerEntry.toJSON(e) : undefined);
        }
        else {
            obj.entry = [];
        }
        return obj;
    },
};
function createBaseFbMessengerPostback() {
    return { mid: "", title: "", payload: "" };
}
export const FbMessengerPostback = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.mid !== "") {
            writer.uint32(10).string(message.mid);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        if (message.payload !== "") {
            writer.uint32(26).string(message.payload);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFbMessengerPostback();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.mid = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.payload = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            mid: isSet(object.mid) ? String(object.mid) : "",
            title: isSet(object.title) ? String(object.title) : "",
            payload: isSet(object.payload) ? String(object.payload) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.mid !== undefined && (obj.mid = message.mid);
        message.title !== undefined && (obj.title = message.title);
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
