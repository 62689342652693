import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { mapChannelTypeToColor } from '@connectlyai-sdks/data-rooms';
import { ChatRoomDIContext } from '../../context';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectDescription, selectMessages } from '../../state/roomSlice';
import { updateMessageReadWatermarkInput } from '../../state/thunk';
import ChatMessage from '../chatmessage/ChatMessage';
export const ChatThreadComponentId = '34381d27-eb9b-4bf9-932d-b9c3ba2e2711';
const mapMessageToMessageViewModel = (val) => ({
    id: val.id,
    sentAt: val.sentAtInISO !== undefined ? new Date(val.sentAtInISO) : undefined,
});
const ChatThread = (_props) => {
    var _a;
    const di = useContext(ChatRoomDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    const messages = useFeatureSelector(selectMessages).map(mapMessageToMessageViewModel);
    const lastMessage = messages[messages.length - 1];
    useEffect(() => {
        if (lastMessage) {
            dispatch(updateMessageReadWatermarkInput({ di, eventId: lastMessage.id }));
        }
    }, [lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.id]);
    const description = useFeatureSelector(selectDescription);
    const viewerColor = mapChannelTypeToColor((_a = description.lastChannel) === null || _a === void 0 ? void 0 : _a.channelType);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ChatThreadComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { ChatMessage: ChatMessage, messages: messages, viewerColor: viewerColor }) });
};
export default ChatThread;
