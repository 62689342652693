export const ACCEPTED_FILE_TYPES = ['text/csv'];

export const UPLOAD_SOURCE_TYPE = 'OPT_OUT_SOURCE_TYPE_IMPORT';
export type OptOutSourceType = typeof UPLOAD_SOURCE_TYPE;

export const UPLOAD_CHANNEL_TYPE = 'CHANNEL_TYPE_WHATSAPP_CLOUD';
export type OptOutChannelType = typeof UPLOAD_CHANNEL_TYPE;

export const SAMPLE_PHONE_NUMBERS = [
  ['61779163007'],
  ['815987129013'],
  ['390947733503'],
  ['203513128446'],
  ['397701123777'],
  ['17602634675'],
  ['16775904659'],
  ['34208593942'],
  ['75530118739'],
  ['490585104324'],
  ['33566286543'],
  ['818385657719'],
  ['34506811529'],
  ['903156281566'],
  ['207514011564'],
  ['8645530484201'],
  ['916900293942'],
  ['905622852986'],
  ['8646618826004'],
  ['209766339840'],
  ['495477735182'],
  ['2342295659359'],
  ['27386130391'],
  ['27346451486'],
  ['910629587483'],
  ['34830507768'],
  ['559262420760'],
  ['61319995277'],
  ['2348305888638'],
  ['8630667039941'],
  ['8665954239007'],
  ['33388750085'],
  ['2349424325377'],
  ['551313191499'],
  ['398251294871'],
  ['82377256395'],
  ['72782297845'],
  ['206579932208'],
  ['2348317858405'],
  ['552756001605'],
  ['34960044759'],
  ['2346181130208'],
  ['522527057610'],
  ['82313994699'],
  ['900518225836'],
  ['625239463004'],
  ['622005231766'],
  ['12564439309'],
  ['2341682647553'],
  ['620499398314'],
];
