import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ChatRoomDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectDescription, selectRoomDto } from '../../state/roomSlice';
import { mapToMessage } from '../../usecases/mapper';
export const ChatMessageComponentId = 'a8f4fc0f-83ea-4be2-a4dd-12a6c75874f1';
const mapMessageContentToViewModel = (content) => {
    switch (content.type) {
        case 'text':
            return {
                type: 'text',
                text: content.text,
                attachments: content.attachments,
                location: content.location,
            };
        case 'structured': {
            const { header, body, buttons } = content;
            return {
                type: 'structured',
                header: {
                    type: 'header',
                    text: (header === null || header === void 0 ? void 0 : header.text) || '',
                    mediaUrl: (header === null || header === void 0 ? void 0 : header.mediaUrl) || '',
                },
                body: {
                    type: 'body',
                    text: (body === null || body === void 0 ? void 0 : body.text) || '',
                },
                buttons,
            };
        }
        default:
            return {
                type: 'text',
                text: '',
                attachments: [],
            };
    }
};
const mapMessageToMessageViewModel = (message, roomId) => ({
    roomId,
    id: message.id,
    class: message.class,
    content: mapMessageContentToViewModel(message.content),
    sender: {
        id: message.sender.id,
        avatarUrl: message.sender.avatarUrl,
        name: message.sender.name,
    },
    sentAt: message.sentAtInISO !== undefined ? new Date(message.sentAtInISO) : undefined,
    deliveredAt: message.deliveredAtInISO !== undefined ? new Date(message.deliveredAtInISO) : undefined,
    readAt: message.readAtInISO !== undefined ? new Date(message.readAtInISO) : undefined,
    error: message.error,
});
const ChatMessage = ({ eventId }) => {
    const di = React.useContext(ChatRoomDIContext);
    const [eventDto, setEventDto] = React.useState(undefined);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    React.useEffect(() => {
        const sub = di
            .roomsDataStoreProvider()
            .subscribeMessage(eventId)
            .subscribe((data) => {
            data.fold((dto) => {
                setEventDto(dto);
            }, () => { }, (_err) => { });
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di.subscriptionRoomProvider]);
    const roomDto = useFeatureSelector(selectRoomDto);
    const description = useFeatureSelector(selectDescription);
    const model = React.useMemo(() => {
        if (!eventDto || !roomDto) {
            return undefined;
        }
        const entity = mapToMessage(roomDto, eventDto, {
            logProvider: di.logProvider,
        });
        return mapMessageToMessageViewModel(entity, description.id);
    }, [roomDto, eventDto]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ChatMessageComponentId);
    return _jsx(_Fragment, { children: UIComponent && model && _jsx(UIComponent, { model: model }) });
};
export default ChatMessage;
