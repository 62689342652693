import React, { FC, useState } from 'react';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { BeforeYouContinue } from './BeforeYouContinue';

export const OnboardingModals: FC = () => {
  const { ffEnableSofiaAIByDefault } = useFeatureFlag(['ffEnableSofiaAIByDefault']);
  const [showBeforeYouContinue, setShowBeforeYouContinue] = useState(ffEnableSofiaAIByDefault);

  const handleCloseBeforeYouContinue = () => {
    setShowBeforeYouContinue(false);
  };
  if (!showBeforeYouContinue) {
    return null;
  }
  return <BeforeYouContinue onClose={handleCloseBeforeYouContinue} />;
};
