import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import { MUTATION_DELETE_OPT_OUT_URL, MutationDeleteOptOutParameters, MutationDeleteOptOutOptions } from './types';

export const useMutationDeleteOptOut = (options?: MutationDeleteOptOutOptions) =>
  useMutation(
    ({ businessId, input }: MutationDeleteOptOutParameters) =>
      api.profileV1(MUTATION_DELETE_OPT_OUT_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input },
      }),
    {
      ...options,
    },
  );
