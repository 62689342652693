import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { ResendCampaignVariables, ResendCampaignResponse, RESEND_CAMPAIGN_URL } from './types';

export const useResendCampaignMutation = (
  options?: Omit<
    UseMutationOptions<ResendCampaignResponse, ConnectlyError, ResendCampaignVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, ...rest }: ResendCampaignVariables) =>
      api.flowV1(RESEND_CAMPAIGN_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: rest },
      }) as Promise<ResendCampaignResponse>,
    options,
  );
