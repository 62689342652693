import React from 'react';
import { useSetAtom } from 'jotai';
import { adminModalOpenAtom } from '@atoms/app';
import { AdminPanelSettingsIcon, Button } from '@connectlyai-tenets/ui-styled-web';

export const Admin = ({ godMode = false }: { godMode: boolean }) => {
  const setAdminModalOpen = useSetAtom(adminModalOpenAtom);
  return (
    <Button
      variant={godMode ? 'contained' : 'text'}
      color={godMode ? 'warning' : 'primary'}
      sx={{
        borderRadius: 0,
        color: godMode ? 'black' : 'white',
        width: '100%',
      }}
      onClick={() => {
        setAdminModalOpen(true);
      }}
    >
      <AdminPanelSettingsIcon
        sx={{
          color: (theme) => theme.palette.common.black,
        }}
      />
    </Button>
  );
};
