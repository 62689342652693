import React from 'react';
import { ArticleIcon, ImageIcon, VideocamIcon } from '@connectlyai-tenets/ui-styled-web';
import { MediaHeaderTemplateComponentType } from '../../hooks';
import { MessageNodeProps } from './types';
import { mapMediaHeaderTypeStringToTemplateParamName } from '../../utils';

export const selectHeaderMediaMessageNodeData = (
  v1: MessageNodeProps['data']['v1'],
  reduxHeaderMediaType: MediaHeaderTemplateComponentType | '',
  reduxHeaderMediaMapping: string | undefined,
  editing: boolean,
): {
  headerMediaType: MediaHeaderTemplateComponentType | undefined;
  headerMediaMapping: string | undefined;
} => {
  const reduxReturnValue = {
    headerMediaType: reduxHeaderMediaType || undefined,
    headerMediaMapping: reduxHeaderMediaMapping,
  };
  const v1ReturnValue = (() => {
    const { messageTemplateInput: connectlyTemplate, parameterMapping } = v1 || {};
    const mediaType: MediaHeaderTemplateComponentType | undefined = connectlyTemplate?.templateComponents?.find(
      (x) => x.header,
    )?.header?.media?.type;

    if (!mediaType) return { headerMediaType: undefined, headerMediaMapping: undefined };

    let mediaMapping;
    if (parameterMapping && parameterMapping.mappings) {
      const param = parameterMapping.mappings.find(
        (p) => p.rawParam === mapMediaHeaderTypeStringToTemplateParamName(mediaType),
      );
      if (param && param.fullQualifier) {
        mediaMapping = param.fullQualifier;
      }
    }
    return {
      headerMediaType: mediaType,
      headerMediaMapping: mediaMapping ? `{{${mediaMapping}}}` : undefined,
    };
  })();
  if (editing) {
    return reduxReturnValue;
  }
  if (v1ReturnValue) {
    return v1ReturnValue;
  }
  return reduxReturnValue;
};

export const selectHeaderMediaIcon = (headerMediaType: MediaHeaderTemplateComponentType | undefined) => {
  if (!headerMediaType) return null;
  if (headerMediaType === 'TYPE_IMAGE') {
    return <ImageIcon sx={{ mb: 0.5, color: (theme) => theme.palette.grey[600] }} />;
  }
  if (headerMediaType === 'TYPE_VIDEO') {
    return <VideocamIcon sx={{ mb: 0.5, color: (theme) => theme.palette.grey[600] }} />;
  }
  if (headerMediaType === 'TYPE_DOCUMENT') {
    return <ArticleIcon sx={{ mb: 0.5, color: (theme) => theme.palette.grey[600] }} />;
  }
  return null;
};
