import React, { useMemo } from 'react';
import {
  Box,
  Label,
  ListItem,
  IconButton,
  DeleteOutlineIcon,
  CircularProgress,
  InputAdornment,
  useTheme,
  TextField,
  Stack,
} from '@connectlyai-tenets/ui-styled-web';
import { useFeatureFlag } from '@hooks';
import EmojiPickerButton from '../../../../presentation/EmojiPicker';
import { SIZE_MEDIA_XS } from '../../../../ui-theme';
import { QuickReplyTemplateProps } from './types';

export const QuickReplyTemplate = ({
  id,
  title,
  body,
  isLoading,
  editState,
  isDeletingQuickReplyItem,
  handleDeleteButtonClick,
  handleEditPropertyBlur,
  handleEditPropertyChange,
  handleEditPropertyFocus,
  handleMouseEnter,
  handleMouseLeave,
  hoverKey,
  onClickEmoji,
  onCloseEmoji,
  onSelectEmoji,
}: QuickReplyTemplateProps) => {
  const theme = useTheme();
  const isEditTitle = useMemo(() => {
    return id === editState?.itemId && editState.propertyKey === 'title';
  }, [id, editState]);
  const isEditBody = useMemo(() => {
    return id === editState?.itemId && editState.propertyKey === 'body';
  }, [id, editState]);
  const titleValue = useMemo(() => (isEditTitle ? editState?.input : title), [isEditTitle, editState, title]);
  const bodyValue = useMemo(() => (isEditBody ? editState?.input : body), [isEditBody, editState, body]);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="1" width="100%">
      <Stack
        key={id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{ width: '100%' }}
          data-hoverkey={`${id}/title`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isEditTitle || title === '' || hoverKey === `${id}/title` ? (
            <TextField
              sx={{
                width: '100%',
                padding: 0.5,
                '& .MuiInput-input': {
                  padding: 0,
                  fontWeight: theme.typography.body1.fontWeight,
                  fontSize: theme.typography.body1.fontSize,
                  lineHeight: theme.typography.body1.lineHeight,
                  letterSpacing: theme.typography.body1.letterSpacing,
                  height: 'fit-content',
                },
                borderRadius: '10px',
                background: theme.palette.common.white,
                '&:focused .MuiInput-input': {
                  cursor: 'pointer',
                },
                border: `1px solid ${theme.palette.divider}`,
              }}
              data-key={id}
              data-propertykey="title"
              placeholder="Title"
              value={titleValue}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                onFocus: handleEditPropertyFocus,
                onBlur: handleEditPropertyBlur,
              }}
              onChange={handleEditPropertyChange}
            />
          ) : (
            <Label
              sx={{
                width: '100%',
                padding: 0.5,
                '& .MuiInput-input': {
                  padding: 0,
                },
                border: `1px solid ${theme.palette.common.white}`,
              }}
              noWrap
              variant="body1"
            >
              {title}
            </Label>
          )}
        </Box>
        <Box
          style={{
            width: '100%',
            marginTop: theme.spacing(1),
          }}
          data-hoverkey={`${id}/body`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isEditBody || body === '' || hoverKey === `${id}/body` ? (
            <TextField
              sx={{
                width: '100%',
                padding: 0.5,
                color: '#5C5C5C',
                whiteSpace: 'pre-wrap',
                '& .MuiInput-input': {
                  padding: 0,
                  color: '#5C5C5C',
                  fontWeight: theme.typography.body1.fontWeight,
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.body2.lineHeight,
                  letterSpacing: theme.typography.body1.letterSpacing,
                  height: 'fit-content',
                },
                borderRadius: '10px',
                border: `1px solid ${theme.palette.divider}`,
                background: theme.palette.common.white,
                '& .MuiInput-root': {
                  padding: 0,
                },
                '&:focused .MuiInput-input': {
                  cursor: 'pointer',
                },
              }}
              data-key={id}
              data-propertykey="body"
              placeholder="Body"
              value={bodyValue}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                onFocus: handleEditPropertyFocus,
                onBlur: handleEditPropertyBlur,
                endAdornment: (
                  <InputAdornment position="end">
                    <EmojiPickerButton
                      anchorOrigin={{
                        // assumes total button size of spacing 4 check EmojiPicker
                        vertical: parseInt(theme.spacing(6), 10), // bottom + spacing 2 margin
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      disabled={false}
                      onClick={onClickEmoji}
                      onClose={onCloseEmoji}
                      onSelect={onSelectEmoji}
                    />
                  </InputAdornment>
                ),
              }}
              multiline
              onChange={handleEditPropertyChange}
            />
          ) : (
            <Label
              sx={{
                width: '100%',
                padding: 0.5,
                color: '#5C5C5C',
                whiteSpace: 'pre-wrap',
                border: `1px solid ${theme.palette.common.white}`,
                '& .MuiInput-input': {
                  padding: 0,
                  color: '#5C5C5C',
                  fontWeight: theme.typography.body1.fontWeight,
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.body2.lineHeight,
                  letterSpacing: theme.typography.body1.letterSpacing,
                },
              }}
              variant="body2"
            >
              {body}
            </Label>
          )}
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '1005',
          mx: 1,
        }}
      >
        {isLoading && <CircularProgress style={{ padding: theme.spacing(1.5) }} size={theme.spacing(SIZE_MEDIA_XS)} />}
        {!isLoading && (
          <IconButton
            size="large"
            data-itemid={id}
            onClick={handleDeleteButtonClick}
            disabled={isDeletingQuickReplyItem}
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};
