import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NavigationRoute, PageLayout } from '../../../components/PageLayout';
import { Sofia } from '../Sofia';
import { useNavigator } from '../hooks';
import { Websearch } from './Websearch';

const Content = () => {
  const { routes } = useNavigator();
  return (
    <Switch>
      <Route path={routes.home.pathname} exact>
        <Sofia />
      </Route>
      <Route path={routes.websearch.pathname}>
        <Websearch />
      </Route>
    </Switch>
  );
};

export const useSofiaScene = () => {
  const { routes: sofiaRoutes } = useNavigator();
  const routes: NavigationRoute[] = useMemo(() => {
    const returnRoutes: NavigationRoute[] = [
      {
        ...sofiaRoutes.home,
        display: 'Sofia',
      },
      {
        ...sofiaRoutes.websearch,
        display: 'Websearch',
      },
    ];
    return returnRoutes;
  }, [sofiaRoutes.home, sofiaRoutes.websearch]);

  return {
    routes,
  };
};

export const SofiaNew = () => {
  const { routes } = useSofiaScene();
  return (
    <PageLayout title="Sofia" testId="sofia" routes={routes}>
      <Content />
    </PageLayout>
  );
};
