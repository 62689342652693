import { InboxExperience } from '@connectlyai-idl/models/dist/models/inbox/models';
import { SettingsResponse, SofiaIntegrationSettings } from './types';
import { TagView } from '../../presentation/tags';
import { HIDDEN_TAG_IDS } from './constants';

export const selectWAChannel = (data: SettingsResponse) =>
  data.entity?.channels?.find((channel) => channel.channelType === 'CHANNEL_TYPE_WHATSAPP_CLOUD');

export const selectWAChannelId = (data: SettingsResponse) => selectWAChannel(data)?.id;

export const selectWAIsActive = (data: SettingsResponse) => !!selectWAChannelId(data);

export const selectShopifyChannel = (data: SettingsResponse) =>
  data.entity?.channels?.find((channel) => channel.channelType === 'CHANNEL_TYPE_SHOPIFY');

export const selectShopifyStoreName = (data: SettingsResponse): string => {
  const channel = selectShopifyChannel(data);
  return channel?.remoteProfile?.shopifyProfile?.shop?.name || '';
};

export const selectShopifyStoreAccessToken = (data: SettingsResponse): string => {
  const channel = selectShopifyChannel(data);
  return channel?.accessToken || '';
};

export const selectSMSChannel = (data: SettingsResponse) =>
  data.entity?.channels?.find((channel) => channel.channelType === 'CHANNEL_TYPE_SMS');

export const selectFBMessengerChannel = (data: SettingsResponse) =>
  data.entity?.channels?.find((channel) => channel.channelType === 'CHANNEL_TYPE_FB_MESSENGER');

export const selectInstagramChannel = (data: SettingsResponse) =>
  data.entity?.channels?.find((channel) => channel.channelType === 'CHANNEL_TYPE_INSTAGRAM');

export const selectQuickReplyTemplates = (data: SettingsResponse) => data.entity?.quickReplyTemplates?.templates || [];

export const selectTags = (data: SettingsResponse) => data.entity?.tags?.entries || [];

export const selectVisibleTags = (data: SettingsResponse) => {
  return selectTags(data).filter((tag) => {
    return !tag.config?.groups?.includes('inbox_hidden_from_tag_bar') && !HIDDEN_TAG_IDS.includes(tag.id || '');
  });
};

export const selectVisibleTagsView = (data: SettingsResponse): TagView[] => {
  const visibleTags = selectVisibleTags(data).filter((tag) => tag.config?.v1?.name && tag.id);
  return visibleTags.map((tag) => {
    return {
      display: tag.config?.v1?.name || '',
      id: tag.id || '',
      color: tag.config?.v1?.color || '',
    };
  });
};

export const selectSMSNumber = (data: SettingsResponse) => selectSMSChannel(data)?.externalId;

export const selectSMSChannelId = (data: SettingsResponse) => selectSMSChannel(data)?.id;

export const selectIsShopifyFocus = (data: SettingsResponse): boolean => {
  if (!data.entity) {
    return false;
  }
  const experience = data.entity.inboxConfig?.configV1?.experience;
  if (!experience) {
    return false;
  }
  const inboxExperience: keyof typeof InboxExperience = experience;
  return (
    InboxExperience[inboxExperience] === InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS ||
    InboxExperience[inboxExperience] === InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS
  );
};

export const selectSofiaLiteIntegration = (data: SettingsResponse) => {
  return data.entity?.integrations?.entries?.find((x) => x.type === 'INTEGRATION_TYPE_SALES_BOT_LITE');
};

export const selectSofiaAssistantIntegration = (data: SettingsResponse) => {
  return data.entity?.integrations?.entries?.find((x) => x.type === 'INTEGRATION_TYPE_SALES_ASSISTANT');
};

export const selectVtexIntegration = (data: SettingsResponse) => {
  return data.entity?.integrations?.entries?.find((x) => x.type === 'INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT');
};

export const selectVtexIntegrationId = (data: SettingsResponse) => {
  const integration = selectVtexIntegration(data);
  return integration?.id;
};

export const selectSofiaLiteIntegrationId = (data: SettingsResponse) => {
  const integration = selectSofiaLiteIntegration(data);
  return integration?.id;
};

export const selectSofiaAssistantIntegrationId = (data: SettingsResponse) => {
  const integration = selectSofiaLiteIntegration(data);
  return integration?.id;
};

export const selectSofiaWelcomeMessage = (data: SettingsResponse): string => {
  const sofiaLiteIntegration = selectSofiaLiteIntegration(data);
  const sofiaAssistantIntegration = selectSofiaAssistantIntegration(data);
  const integrationType =
    sofiaLiteIntegration?.id && !sofiaAssistantIntegration?.id
      ? 'INTEGRATION_TYPE_SALES_BOT_LITE'
      : 'INTEGRATION_TYPE_SALES_ASSISTANT';
  const integration =
    integrationType === 'INTEGRATION_TYPE_SALES_BOT_LITE' ? sofiaLiteIntegration : sofiaAssistantIntegration;
  return integration?.parameters?.salesAssistantParameters?.v1?.welcomeMessage || '';
};

export const selectSofiaIntegrationSettings = (data: SettingsResponse): SofiaIntegrationSettings => {
  const sofiaLiteIntegration = selectSofiaLiteIntegration(data);
  const sofiaAssistantIntegration = selectSofiaAssistantIntegration(data);
  const integrationType =
    sofiaLiteIntegration?.id && !sofiaAssistantIntegration?.id
      ? 'INTEGRATION_TYPE_SALES_BOT_LITE'
      : 'INTEGRATION_TYPE_SALES_ASSISTANT';
  const integration =
    integrationType === 'INTEGRATION_TYPE_SALES_BOT_LITE' ? sofiaLiteIntegration : sofiaAssistantIntegration;
  return {
    integrationType,
    integrationId: integration?.id,
    storeDescriptorId: integration?.parameters?.salesAssistantParameters?.v1?.storeDescriptorId,
    welcomeMessage: integration?.parameters?.salesAssistantParameters?.v1?.welcomeMessage,
  };
};

export const selectIntegrationsLoaded = (data: SettingsResponse): boolean => {
  return Array.isArray(data.entity?.integrations?.entries);
};

export const selectShopifyRecommender = (data: SettingsResponse) => {
  return data.entity?.integrations?.entries?.find((x) => x.type === 'INTEGRATION_TYPE_SHOPIFY_RECOMMENDER');
};

export const selectShopifyShopName = (data: SettingsResponse): string => {
  const shopifyChannel = selectShopifyChannel(data);
  if (!shopifyChannel) {
    return '';
  }
  return shopifyChannel?.remoteProfile?.shopifyProfile?.shop?.name || '';
};

export const selectBusinessProfileName = (data: SettingsResponse): string => {
  return data.entity?.name || '';
};

export const selectWhatsAppPhoneNumber = (data: SettingsResponse): string => {
  const waChannel = selectWAChannel(data);
  if (!waChannel) {
    return '';
  }
  return waChannel?.remoteProfile?.wabaPhoneNumber?.phoneNumber || '';
};

export const selectWhatsAppSofiaAssistantLink = (data: SettingsResponse): string => {
  const sofiaIntegration = selectSofiaAssistantIntegration(data);
  const phoneNumber = selectWhatsAppPhoneNumber(data).replace(/\D/g, '');
  let command = sofiaIntegration?.installMetadata?.salesAssistantMetadata?.v1?.command || '';
  if (!command) {
    command = '%2Fsofia';
  }
  command = `https://wa.me/${phoneNumber}?text=${command}`;
  return command;
};
