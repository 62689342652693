import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InfoIcon,
  Label,
  MessageIcon,
  ShoppingBasketIcon,
  Switch,
  Tooltip,
  TranslateIcon,
  useTheme,
  VerifiedUserIcon,
  VtexLogoIcon,
} from '@connectlyai-tenets/ui-styled-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageTemplateStatus, selectBusinessId, useMeData } from '../../hooks';
import { SIZE_CONTENT_L, SIZE_SPACING_ANNOTATION } from '../../ui-theme';
import { getCartAbandonmentLanguage, getTemplateStatusColor, getTemplateStatusShortLabel, track } from '../../utils';
import { ActivationScreen } from '../AbandonedCartActivation/constants';
import { getDiscountAmountString } from '../CartAbandonmentDiscount/utils';
import { AbandonedCartEditProps } from './types';

export const AbandonedCartEdit = ({
  discount,
  reminderDiscount,
  enableHeaderImage,
  enableSafeWindow,
  language,
  isLoading,
  isPersonalMessage,
  reminder,
  firstMessageTemplate,
  secondMessageTemplate,
  onEdit,
  onEditReminder,
  onActivate,
  onSetHeaderImage,
  integrationType,
}: AbandonedCartEditProps) => {
  const isVtexIntegration = integrationType === 'INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT';
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome' });
  const { data: businessId } = useMeData({ select: selectBusinessId });

  const handleReminderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      track('(AC) Second Message On', { businessId });
    }
    onEditReminder(event.target.checked);
  };

  const getStatusHint = (status: MessageTemplateStatus) => {
    if (status === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
      return (
        <Tooltip arrow placement="top" title={t('shopifyAbandonedCart.editIntegration.rejectedHint')}>
          <InfoIcon fill={theme.palette.secondary.dark} />
        </Tooltip>
      );
    }
    return null;
  };

  const firstMessageStatus = firstMessageTemplate?.template?.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
  const secondMessageStatus = secondMessageTemplate?.template?.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';

  return (
    <>
      <DialogContent
        sx={{ width: theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 2, py: 0 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: '10px',
            p: 2,
          }}
        >
          {isVtexIntegration ? (
            <Box
              sx={{
                background: theme.palette.grey.A100,
                borderRadius: '16px',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                gap: SIZE_SPACING_ANNOTATION,
              }}
            >
              <VtexLogoIcon width={theme.spacing(2)} />
              <Label variant="body2">VTEX</Label>
            </Box>
          ) : (
            <Box
              component="img"
              sx={{ width: '58px', height: '48px' }}
              src={`${process.env.PUBLIC_URL}/assets/welcome/cart_abandonment_workflow.png`}
            />
          )}
          <Label variant="h5" sx={{ whiteSpace: 'pre-wrap' }}>
            {t('shopifyAbandonedCart.title')}
          </Label>
          <Label variant="body1" sx={{ color: theme.palette.text.secondary, fontSize: '14px', fontWeight: 400 }}>
            {isVtexIntegration
              ? t('shopifyAbandonedCart.editIntegration.descriptionVtex')
              : t('shopifyAbandonedCart.editIntegration.description')}
          </Label>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: '10px',
              px: 2,
              py: 1,
            }}
          >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <VerifiedUserIcon
                sx={{ color: enableSafeWindow ? theme.palette.success.light : theme.palette.warning.light }}
              />
              {enableSafeWindow
                ? t('shopifyAbandonedCart.editIntegration.safetyWindowEnabled')
                : t('shopifyAbandonedCart.editIntegration.safetyWindowDisabled')}
              <Tooltip
                arrow
                placement="top"
                title={
                  enableSafeWindow
                    ? t('shopifyAbandonedCart.editIntegration.safetyWindowHintEnabled')
                    : t('shopifyAbandonedCart.editIntegration.safetyWindowHintDisabled')
                }
              >
                <InfoIcon fill={theme.palette.secondary.dark} />
              </Tooltip>
            </Box>
            <Button
              variant="text"
              onClick={() => {
                onEdit(ActivationScreen.EditSafetyWindow);
              }}
              disabled={isLoading}
            >
              {t('shopifyAbandonedCart.edit')}
            </Button>
          </Box>
          {isVtexIntegration && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: '10px',
                px: 2,
                py: 1,
              }}
            >
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Label variant="body1">{t('shopifyAbandonedCart.editIntegration.enableHeaderImage')}</Label>
              </Box>
              <Switch
                checked={enableHeaderImage}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSetHeaderImage(event.target.checked)}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: SIZE_SPACING_ANNOTATION,
                background: theme.palette.grey.A100,
                borderRadius: '10px',
                px: 2,
                py: 1,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TranslateIcon />
                  <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {t('shopifyAbandonedCart.editIntegration.language')}
                  </Label>
                </Box>
                <Button // TODO
                  variant="text"
                  onClick={() => {
                    onEdit(ActivationScreen.EditLanguage);
                  }}
                  disabled={isLoading}
                >
                  {t('shopifyAbandonedCart.edit')}
                </Button>
              </Box>
              <Label variant="subtitle1">{getCartAbandonmentLanguage(language)}</Label>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: SIZE_SPACING_ANNOTATION,
                background: theme.palette.grey.A100,
                borderRadius: '10px',
                px: 2,
                py: 1,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <MessageIcon />
                  <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {t('shopifyAbandonedCart.editIntegration.messageType')}
                  </Label>
                </Box>
                <Button
                  variant="text"
                  disabled={isLoading}
                  onClick={() => {
                    onEdit(ActivationScreen.EditMessageType);
                  }}
                >
                  {t('shopifyAbandonedCart.edit')}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {isPersonalMessage ? (
                  <>
                    <Label variant="subtitle1">{t('shopifyAbandonedCart.editIntegration.aiMessage')}</Label>
                    <Box
                      sx={{
                        background: theme.palette.royalViolet,
                        borderRadius: '16px',
                        px: 1,
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        gap: SIZE_SPACING_ANNOTATION,
                        color: 'white',
                      }}
                    >
                      <Label variant="caption">{t('shopifyAbandonedCart.editIntegration.performance')}</Label>
                    </Box>
                  </>
                ) : (
                  <Label variant="subtitle1">{t('shopifyAbandonedCart.editIntegration.standardMessage')}</Label>
                )}
              </Box>
            </Box>
            {!isVtexIntegration && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: SIZE_SPACING_ANNOTATION,
                  background: theme.palette.grey.A100,
                  borderRadius: '10px',
                  px: 2,
                  py: 1,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MessageIcon />
                    <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                      {t('shopifyAbandonedCart.editIntegration.firstMessage')}
                    </Label>
                    <Tooltip arrow placement="top" title={t('shopifyAbandonedCart.editIntegration.firstMessageHint')}>
                      <InfoIcon fill={theme.palette.secondary.dark} />
                    </Tooltip>
                    <Label
                      variant="body2"
                      sx={{
                        color: getTemplateStatusColor(firstMessageStatus),
                      }}
                    >
                      {getTemplateStatusShortLabel(firstMessageStatus)}
                    </Label>
                    {getStatusHint(firstMessageStatus)}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Button
                      variant="text"
                      disabled={isLoading}
                      onClick={() => {
                        onEdit(ActivationScreen.MessagePreview);
                      }}
                    >
                      {t('shopifyAbandonedCart.editIntegration.preview')}
                    </Button>
                    <Switch disabled defaultChecked />
                    <Label variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {t('shopifyAbandonedCart.editIntegration.on')}
                    </Label>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: SIZE_SPACING_ANNOTATION,
                    borderRadius: '10px',
                    background: theme.palette.common.white,
                    px: 2,
                    py: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ShoppingBasketIcon />
                      <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                        {t('shopifyAbandonedCart.editIntegration.discount')}
                      </Label>
                    </Box>
                    <Button
                      variant="text"
                      disabled={isLoading || isVtexIntegration}
                      onClick={() => {
                        onEdit(ActivationScreen.EditDiscount);
                      }}
                    >
                      {discount.discountCode
                        ? t('cartAbandonmentDiscount.editDiscount')
                        : t('cartAbandonmentDiscount.addDiscount')}
                    </Button>
                  </Box>
                  <Box>
                    {discount.discountCode ? (
                      <>
                        <Label sx={{ fontWeight: 500, fontSize: '16px' }}>{discount.discountCode}</Label>
                        <Label variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
                          {getDiscountAmountString(discount)}
                        </Label>
                      </>
                    ) : (
                      <Label variant="subtitle1">{t('cartAbandonmentDiscount.none')}</Label>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {!isVtexIntegration && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: SIZE_SPACING_ANNOTATION,
                  background: theme.palette.grey.A100,
                  borderRadius: '10px',
                  px: 2,
                  py: 1,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MessageIcon />
                    <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                      {t('shopifyAbandonedCart.editIntegration.secondMessage')}
                    </Label>
                    <Tooltip arrow placement="top" title={t('shopifyAbandonedCart.editIntegration.secondMessageHint')}>
                      <InfoIcon fill={theme.palette.secondary.dark} />
                    </Tooltip>
                    <Label
                      variant="body2"
                      sx={{
                        color: getTemplateStatusColor(secondMessageStatus),
                      }}
                    >
                      {getTemplateStatusShortLabel(secondMessageStatus)}
                    </Label>
                    {getStatusHint(secondMessageStatus)}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Button
                      variant="text"
                      disabled={isLoading}
                      onClick={() => {
                        onEdit(ActivationScreen.ReminderPreview);
                      }}
                    >
                      {t('shopifyAbandonedCart.editIntegration.preview')}
                    </Button>
                    <Switch checked={reminder} onChange={handleReminderChange} />
                    <Label variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {t('shopifyAbandonedCart.editIntegration.on')}
                    </Label>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: SIZE_SPACING_ANNOTATION,
                    borderRadius: '10px',
                    background: theme.palette.common.white,
                    px: 2,
                    py: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ShoppingBasketIcon />
                      <Label sx={{ fontWeight: 500, fontSize: '16px' }}>
                        {t('shopifyAbandonedCart.editIntegration.discount')}
                      </Label>
                    </Box>
                    <Button
                      variant="text"
                      disabled={isLoading || isVtexIntegration}
                      onClick={() => {
                        onEdit(ActivationScreen.EditReminderDiscount);
                      }}
                    >
                      {reminderDiscount.discountCode
                        ? t('cartAbandonmentDiscount.editDiscount')
                        : t('cartAbandonmentDiscount.addDiscount')}
                    </Button>
                  </Box>
                  <Box>
                    {reminderDiscount.discountCode ? (
                      <>
                        <Label sx={{ fontWeight: 500, fontSize: '16px' }}>{reminderDiscount.discountCode}</Label>
                        <Label variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
                          {getDiscountAmountString(reminderDiscount)}
                        </Label>
                      </>
                    ) : (
                      <Label variant="subtitle1">{t('cartAbandonmentDiscount.none')}</Label>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'end', p: 3 }}>
        <Button variant="contained" onClick={onActivate} disabled={isLoading}>
          {t('shopifyAbandonedCart.editIntegration.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
