import { InboxSettingsInput } from '@connectlyai-idl/models/dist/models/inbox/models';
export const mutationUpdateInboxSettingsProvider = (log, networkClient) => async (input) => {
    const url = '/v1/users/me/inbox_settings';
    log.debug('mutationUpdateInboxSettings@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.put(url, InboxSettingsInput.toJSON(input));
        log.debug('mutationUpdateInboxSettings@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationUpdateInboxSettings@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
