import Long from "long";
import { Paging } from "../../v10_0/paging/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue, BoolValue, FloatValue, UInt32Value, } from "../../../../../google/protobuf/wrappers";
import { ListValue } from "../../../../../google/protobuf/struct";
export const protobufPackage = "events.facebook.graphapi.v12_0.whatsapp";
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["text"] = 0] = "text";
    MessageType[MessageType["image"] = 1] = "image";
    MessageType[MessageType["document"] = 2] = "document";
    MessageType[MessageType["voice"] = 3] = "voice";
    MessageType[MessageType["video"] = 4] = "video";
    MessageType[MessageType["sticker"] = 5] = "sticker";
    MessageType[MessageType["location"] = 6] = "location";
    MessageType[MessageType["system"] = 7] = "system";
    MessageType[MessageType["contacts"] = 8] = "contacts";
    MessageType[MessageType["unknown"] = 9] = "unknown";
    MessageType[MessageType["button"] = 10] = "button";
    MessageType[MessageType["template"] = 11] = "template";
    MessageType[MessageType["audio"] = 12] = "audio";
    MessageType[MessageType["unsupported"] = 13] = "unsupported";
    MessageType[MessageType["interactive"] = 14] = "interactive";
    MessageType[MessageType["reaction"] = 15] = "reaction";
    MessageType[MessageType["order"] = 16] = "order";
    MessageType[MessageType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageType || (MessageType = {}));
export function messageTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "text":
            return MessageType.text;
        case 1:
        case "image":
            return MessageType.image;
        case 2:
        case "document":
            return MessageType.document;
        case 3:
        case "voice":
            return MessageType.voice;
        case 4:
        case "video":
            return MessageType.video;
        case 5:
        case "sticker":
            return MessageType.sticker;
        case 6:
        case "location":
            return MessageType.location;
        case 7:
        case "system":
            return MessageType.system;
        case 8:
        case "contacts":
            return MessageType.contacts;
        case 9:
        case "unknown":
            return MessageType.unknown;
        case 10:
        case "button":
            return MessageType.button;
        case 11:
        case "template":
            return MessageType.template;
        case 12:
        case "audio":
            return MessageType.audio;
        case 13:
        case "unsupported":
            return MessageType.unsupported;
        case 14:
        case "interactive":
            return MessageType.interactive;
        case 15:
        case "reaction":
            return MessageType.reaction;
        case 16:
        case "order":
            return MessageType.order;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageType.UNRECOGNIZED;
    }
}
export function messageTypeToJSON(object) {
    switch (object) {
        case MessageType.text:
            return "text";
        case MessageType.image:
            return "image";
        case MessageType.document:
            return "document";
        case MessageType.voice:
            return "voice";
        case MessageType.video:
            return "video";
        case MessageType.sticker:
            return "sticker";
        case MessageType.location:
            return "location";
        case MessageType.system:
            return "system";
        case MessageType.contacts:
            return "contacts";
        case MessageType.unknown:
            return "unknown";
        case MessageType.button:
            return "button";
        case MessageType.template:
            return "template";
        case MessageType.audio:
            return "audio";
        case MessageType.unsupported:
            return "unsupported";
        case MessageType.interactive:
            return "interactive";
        case MessageType.reaction:
            return "reaction";
        case MessageType.order:
            return "order";
        case MessageType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppAccountBusinessVerificationStatus;
(function (WhatsAppAccountBusinessVerificationStatus) {
    WhatsAppAccountBusinessVerificationStatus[WhatsAppAccountBusinessVerificationStatus["WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED"] = 0] = "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED";
    WhatsAppAccountBusinessVerificationStatus[WhatsAppAccountBusinessVerificationStatus["WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED"] = 1] = "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED";
    WhatsAppAccountBusinessVerificationStatus[WhatsAppAccountBusinessVerificationStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppAccountBusinessVerificationStatus || (WhatsAppAccountBusinessVerificationStatus = {}));
export function whatsAppAccountBusinessVerificationStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED":
            return WhatsAppAccountBusinessVerificationStatus.WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED;
        case 1:
        case "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED":
            return WhatsAppAccountBusinessVerificationStatus.WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppAccountBusinessVerificationStatus.UNRECOGNIZED;
    }
}
export function whatsAppAccountBusinessVerificationStatusToJSON(object) {
    switch (object) {
        case WhatsAppAccountBusinessVerificationStatus.WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED:
            return "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_UNSPECIFIED";
        case WhatsAppAccountBusinessVerificationStatus.WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED:
            return "WHATS_APP_ACCOUNT_BUSINESS_VERIFICATION_STATUS_VERIFIED";
        case WhatsAppAccountBusinessVerificationStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppPhoneNumberStatus;
(function (WhatsAppPhoneNumberStatus) {
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED"] = 0] = "WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN"] = 1] = "WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_PENDING"] = 2] = "WHATS_APP_PHONE_NUMBER_STATUS_PENDING";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_DELETED"] = 3] = "WHATS_APP_PHONE_NUMBER_STATUS_DELETED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED"] = 4] = "WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_BANNED"] = 5] = "WHATS_APP_PHONE_NUMBER_STATUS_BANNED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED"] = 6] = "WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED"] = 7] = "WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED"] = 8] = "WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED"] = 9] = "WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED"] = 10] = "WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED"] = 11] = "WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED";
    WhatsAppPhoneNumberStatus[WhatsAppPhoneNumberStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppPhoneNumberStatus || (WhatsAppPhoneNumberStatus = {}));
export function whatsAppPhoneNumberStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED;
        case 1:
        case "WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN;
        case 2:
        case "WHATS_APP_PHONE_NUMBER_STATUS_PENDING":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_PENDING;
        case 3:
        case "WHATS_APP_PHONE_NUMBER_STATUS_DELETED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_DELETED;
        case 4:
        case "WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED;
        case 5:
        case "WHATS_APP_PHONE_NUMBER_STATUS_BANNED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_BANNED;
        case 6:
        case "WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED;
        case 7:
        case "WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED;
        case 8:
        case "WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED;
        case 9:
        case "WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED;
        case 10:
        case "WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED;
        case 11:
        case "WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED":
            return WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppPhoneNumberStatus.UNRECOGNIZED;
    }
}
export function whatsAppPhoneNumberStatusToJSON(object) {
    switch (object) {
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_UNSPECIFIED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN:
            return "WHATS_APP_PHONE_NUMBER_STATUS_UNKNOWN";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_PENDING:
            return "WHATS_APP_PHONE_NUMBER_STATUS_PENDING";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_DELETED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_DELETED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_MIGRATED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_BANNED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_BANNED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_RESTRICTED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_RATE_LIMITED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_FLAGGED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_CONNECTED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_DISCONNECTED";
        case WhatsAppPhoneNumberStatus.WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED:
            return "WHATS_APP_PHONE_NUMBER_STATUS_UNVERIFIED";
        case WhatsAppPhoneNumberStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppAccountReviewStatus;
(function (WhatsAppAccountReviewStatus) {
    WhatsAppAccountReviewStatus[WhatsAppAccountReviewStatus["WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED"] = 0] = "WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED";
    WhatsAppAccountReviewStatus[WhatsAppAccountReviewStatus["WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING"] = 1] = "WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING";
    WhatsAppAccountReviewStatus[WhatsAppAccountReviewStatus["WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED"] = 2] = "WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED";
    WhatsAppAccountReviewStatus[WhatsAppAccountReviewStatus["WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED"] = 3] = "WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED";
    WhatsAppAccountReviewStatus[WhatsAppAccountReviewStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppAccountReviewStatus || (WhatsAppAccountReviewStatus = {}));
export function whatsAppAccountReviewStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED":
            return WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED;
        case 1:
        case "WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING":
            return WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING;
        case 2:
        case "WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED":
            return WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED;
        case 3:
        case "WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED":
            return WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppAccountReviewStatus.UNRECOGNIZED;
    }
}
export function whatsAppAccountReviewStatusToJSON(object) {
    switch (object) {
        case WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED:
            return "WHATS_APP_ACCOUNT_REVIEW_STATUS_UNSPECIFIED";
        case WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING:
            return "WHATS_APP_ACCOUNT_REVIEW_STATUS_PENDING";
        case WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED:
            return "WHATS_APP_ACCOUNT_REVIEW_STATUS_APPROVED";
        case WhatsAppAccountReviewStatus.WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED:
            return "WHATS_APP_ACCOUNT_REVIEW_STATUS_REJECTED";
        case WhatsAppAccountReviewStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Component_Addendum_Type;
(function (Component_Addendum_Type) {
    Component_Addendum_Type[Component_Addendum_Type["unspecified"] = 0] = "unspecified";
    Component_Addendum_Type[Component_Addendum_Type["header"] = 1] = "header";
    Component_Addendum_Type[Component_Addendum_Type["body"] = 2] = "body";
    Component_Addendum_Type[Component_Addendum_Type["button"] = 3] = "button";
    Component_Addendum_Type[Component_Addendum_Type["carousel"] = 4] = "carousel";
    Component_Addendum_Type[Component_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Component_Addendum_Type || (Component_Addendum_Type = {}));
export function component_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return Component_Addendum_Type.unspecified;
        case 1:
        case "header":
            return Component_Addendum_Type.header;
        case 2:
        case "body":
            return Component_Addendum_Type.body;
        case 3:
        case "button":
            return Component_Addendum_Type.button;
        case 4:
        case "carousel":
            return Component_Addendum_Type.carousel;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Component_Addendum_Type.UNRECOGNIZED;
    }
}
export function component_Addendum_TypeToJSON(object) {
    switch (object) {
        case Component_Addendum_Type.unspecified:
            return "unspecified";
        case Component_Addendum_Type.header:
            return "header";
        case Component_Addendum_Type.body:
            return "body";
        case Component_Addendum_Type.button:
            return "button";
        case Component_Addendum_Type.carousel:
            return "carousel";
        case Component_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Component_Addendum_SubType;
(function (Component_Addendum_SubType) {
    Component_Addendum_SubType[Component_Addendum_SubType["subtype_unspecified"] = 0] = "subtype_unspecified";
    Component_Addendum_SubType[Component_Addendum_SubType["quick_reply"] = 1] = "quick_reply";
    Component_Addendum_SubType[Component_Addendum_SubType["url"] = 2] = "url";
    Component_Addendum_SubType[Component_Addendum_SubType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Component_Addendum_SubType || (Component_Addendum_SubType = {}));
export function component_Addendum_SubTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "subtype_unspecified":
            return Component_Addendum_SubType.subtype_unspecified;
        case 1:
        case "quick_reply":
            return Component_Addendum_SubType.quick_reply;
        case 2:
        case "url":
            return Component_Addendum_SubType.url;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Component_Addendum_SubType.UNRECOGNIZED;
    }
}
export function component_Addendum_SubTypeToJSON(object) {
    switch (object) {
        case Component_Addendum_SubType.subtype_unspecified:
            return "subtype_unspecified";
        case Component_Addendum_SubType.quick_reply:
            return "quick_reply";
        case Component_Addendum_SubType.url:
            return "url";
        case Component_Addendum_SubType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Parameter_Addendum_Type;
(function (Parameter_Addendum_Type) {
    Parameter_Addendum_Type[Parameter_Addendum_Type["unspecified"] = 0] = "unspecified";
    Parameter_Addendum_Type[Parameter_Addendum_Type["text"] = 1] = "text";
    Parameter_Addendum_Type[Parameter_Addendum_Type["image"] = 2] = "image";
    Parameter_Addendum_Type[Parameter_Addendum_Type["document"] = 3] = "document";
    Parameter_Addendum_Type[Parameter_Addendum_Type["video"] = 4] = "video";
    Parameter_Addendum_Type[Parameter_Addendum_Type["payload"] = 5] = "payload";
    Parameter_Addendum_Type[Parameter_Addendum_Type["location"] = 6] = "location";
    Parameter_Addendum_Type[Parameter_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Parameter_Addendum_Type || (Parameter_Addendum_Type = {}));
export function parameter_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return Parameter_Addendum_Type.unspecified;
        case 1:
        case "text":
            return Parameter_Addendum_Type.text;
        case 2:
        case "image":
            return Parameter_Addendum_Type.image;
        case 3:
        case "document":
            return Parameter_Addendum_Type.document;
        case 4:
        case "video":
            return Parameter_Addendum_Type.video;
        case 5:
        case "payload":
            return Parameter_Addendum_Type.payload;
        case 6:
        case "location":
            return Parameter_Addendum_Type.location;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Parameter_Addendum_Type.UNRECOGNIZED;
    }
}
export function parameter_Addendum_TypeToJSON(object) {
    switch (object) {
        case Parameter_Addendum_Type.unspecified:
            return "unspecified";
        case Parameter_Addendum_Type.text:
            return "text";
        case Parameter_Addendum_Type.image:
            return "image";
        case Parameter_Addendum_Type.document:
            return "document";
        case Parameter_Addendum_Type.video:
            return "video";
        case Parameter_Addendum_Type.payload:
            return "payload";
        case Parameter_Addendum_Type.location:
            return "location";
        case Parameter_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessageTemplate_Status;
(function (MessageTemplate_Status) {
    MessageTemplate_Status[MessageTemplate_Status["APPROVED"] = 0] = "APPROVED";
    MessageTemplate_Status[MessageTemplate_Status["PENDING"] = 1] = "PENDING";
    MessageTemplate_Status[MessageTemplate_Status["REJECTED"] = 2] = "REJECTED";
    MessageTemplate_Status[MessageTemplate_Status["IN_APPEAL"] = 3] = "IN_APPEAL";
    MessageTemplate_Status[MessageTemplate_Status["PAUSED"] = 4] = "PAUSED";
    MessageTemplate_Status[MessageTemplate_Status["DISABLED"] = 5] = "DISABLED";
    MessageTemplate_Status[MessageTemplate_Status["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplate_Status || (MessageTemplate_Status = {}));
export function messageTemplate_StatusFromJSON(object) {
    switch (object) {
        case 0:
        case "APPROVED":
            return MessageTemplate_Status.APPROVED;
        case 1:
        case "PENDING":
            return MessageTemplate_Status.PENDING;
        case 2:
        case "REJECTED":
            return MessageTemplate_Status.REJECTED;
        case 3:
        case "IN_APPEAL":
            return MessageTemplate_Status.IN_APPEAL;
        case 4:
        case "PAUSED":
            return MessageTemplate_Status.PAUSED;
        case 5:
        case "DISABLED":
            return MessageTemplate_Status.DISABLED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplate_Status.UNRECOGNIZED;
    }
}
export function messageTemplate_StatusToJSON(object) {
    switch (object) {
        case MessageTemplate_Status.APPROVED:
            return "APPROVED";
        case MessageTemplate_Status.PENDING:
            return "PENDING";
        case MessageTemplate_Status.REJECTED:
            return "REJECTED";
        case MessageTemplate_Status.IN_APPEAL:
            return "IN_APPEAL";
        case MessageTemplate_Status.PAUSED:
            return "PAUSED";
        case MessageTemplate_Status.DISABLED:
            return "DISABLED";
        case MessageTemplate_Status.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessageTemplateComponent_Type;
(function (MessageTemplateComponent_Type) {
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["TYPE_UNSPECIFIED"] = 0] = "TYPE_UNSPECIFIED";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["HEADER"] = 1] = "HEADER";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["BODY"] = 2] = "BODY";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["FOOTER"] = 3] = "FOOTER";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["BUTTONS"] = 4] = "BUTTONS";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["CAROUSEL"] = 5] = "CAROUSEL";
    MessageTemplateComponent_Type[MessageTemplateComponent_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplateComponent_Type || (MessageTemplateComponent_Type = {}));
export function messageTemplateComponent_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "TYPE_UNSPECIFIED":
            return MessageTemplateComponent_Type.TYPE_UNSPECIFIED;
        case 1:
        case "HEADER":
            return MessageTemplateComponent_Type.HEADER;
        case 2:
        case "BODY":
            return MessageTemplateComponent_Type.BODY;
        case 3:
        case "FOOTER":
            return MessageTemplateComponent_Type.FOOTER;
        case 4:
        case "BUTTONS":
            return MessageTemplateComponent_Type.BUTTONS;
        case 5:
        case "CAROUSEL":
            return MessageTemplateComponent_Type.CAROUSEL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplateComponent_Type.UNRECOGNIZED;
    }
}
export function messageTemplateComponent_TypeToJSON(object) {
    switch (object) {
        case MessageTemplateComponent_Type.TYPE_UNSPECIFIED:
            return "TYPE_UNSPECIFIED";
        case MessageTemplateComponent_Type.HEADER:
            return "HEADER";
        case MessageTemplateComponent_Type.BODY:
            return "BODY";
        case MessageTemplateComponent_Type.FOOTER:
            return "FOOTER";
        case MessageTemplateComponent_Type.BUTTONS:
            return "BUTTONS";
        case MessageTemplateComponent_Type.CAROUSEL:
            return "CAROUSEL";
        case MessageTemplateComponent_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessageTemplateComponent_Format;
(function (MessageTemplateComponent_Format) {
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["FORMAT_UNSPECIFIED"] = 0] = "FORMAT_UNSPECIFIED";
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["TEXT"] = 1] = "TEXT";
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["IMAGE"] = 2] = "IMAGE";
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["VIDEO"] = 3] = "VIDEO";
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["DOCUMENT"] = 4] = "DOCUMENT";
    MessageTemplateComponent_Format[MessageTemplateComponent_Format["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplateComponent_Format || (MessageTemplateComponent_Format = {}));
export function messageTemplateComponent_FormatFromJSON(object) {
    switch (object) {
        case 0:
        case "FORMAT_UNSPECIFIED":
            return MessageTemplateComponent_Format.FORMAT_UNSPECIFIED;
        case 1:
        case "TEXT":
            return MessageTemplateComponent_Format.TEXT;
        case 2:
        case "IMAGE":
            return MessageTemplateComponent_Format.IMAGE;
        case 3:
        case "VIDEO":
            return MessageTemplateComponent_Format.VIDEO;
        case 4:
        case "DOCUMENT":
            return MessageTemplateComponent_Format.DOCUMENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplateComponent_Format.UNRECOGNIZED;
    }
}
export function messageTemplateComponent_FormatToJSON(object) {
    switch (object) {
        case MessageTemplateComponent_Format.FORMAT_UNSPECIFIED:
            return "FORMAT_UNSPECIFIED";
        case MessageTemplateComponent_Format.TEXT:
            return "TEXT";
        case MessageTemplateComponent_Format.IMAGE:
            return "IMAGE";
        case MessageTemplateComponent_Format.VIDEO:
            return "VIDEO";
        case MessageTemplateComponent_Format.DOCUMENT:
            return "DOCUMENT";
        case MessageTemplateComponent_Format.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessageTemplateComponent_Button_Type;
(function (MessageTemplateComponent_Button_Type) {
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["TYPE_UNSPECIFIED"] = 0] = "TYPE_UNSPECIFIED";
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["QUICK_REPLY"] = 1] = "QUICK_REPLY";
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["URL"] = 2] = "URL";
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["PHONE_NUMBER"] = 3] = "PHONE_NUMBER";
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["CATALOG"] = 4] = "CATALOG";
    MessageTemplateComponent_Button_Type[MessageTemplateComponent_Button_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessageTemplateComponent_Button_Type || (MessageTemplateComponent_Button_Type = {}));
export function messageTemplateComponent_Button_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "TYPE_UNSPECIFIED":
            return MessageTemplateComponent_Button_Type.TYPE_UNSPECIFIED;
        case 1:
        case "QUICK_REPLY":
            return MessageTemplateComponent_Button_Type.QUICK_REPLY;
        case 2:
        case "URL":
            return MessageTemplateComponent_Button_Type.URL;
        case 3:
        case "PHONE_NUMBER":
            return MessageTemplateComponent_Button_Type.PHONE_NUMBER;
        case 4:
        case "CATALOG":
            return MessageTemplateComponent_Button_Type.CATALOG;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessageTemplateComponent_Button_Type.UNRECOGNIZED;
    }
}
export function messageTemplateComponent_Button_TypeToJSON(object) {
    switch (object) {
        case MessageTemplateComponent_Button_Type.TYPE_UNSPECIFIED:
            return "TYPE_UNSPECIFIED";
        case MessageTemplateComponent_Button_Type.QUICK_REPLY:
            return "QUICK_REPLY";
        case MessageTemplateComponent_Button_Type.URL:
            return "URL";
        case MessageTemplateComponent_Button_Type.PHONE_NUMBER:
            return "PHONE_NUMBER";
        case MessageTemplateComponent_Button_Type.CATALOG:
            return "CATALOG";
        case MessageTemplateComponent_Button_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var WhatsAppMessageInteractive_Addendum_Type;
(function (WhatsAppMessageInteractive_Addendum_Type) {
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["type_unspecified"] = 0] = "type_unspecified";
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["list"] = 1] = "list";
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["button"] = 2] = "button";
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["product"] = 3] = "product";
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["product_list"] = 4] = "product_list";
    WhatsAppMessageInteractive_Addendum_Type[WhatsAppMessageInteractive_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WhatsAppMessageInteractive_Addendum_Type || (WhatsAppMessageInteractive_Addendum_Type = {}));
export function whatsAppMessageInteractive_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "type_unspecified":
            return WhatsAppMessageInteractive_Addendum_Type.type_unspecified;
        case 1:
        case "list":
            return WhatsAppMessageInteractive_Addendum_Type.list;
        case 2:
        case "button":
            return WhatsAppMessageInteractive_Addendum_Type.button;
        case 3:
        case "product":
            return WhatsAppMessageInteractive_Addendum_Type.product;
        case 4:
        case "product_list":
            return WhatsAppMessageInteractive_Addendum_Type.product_list;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WhatsAppMessageInteractive_Addendum_Type.UNRECOGNIZED;
    }
}
export function whatsAppMessageInteractive_Addendum_TypeToJSON(object) {
    switch (object) {
        case WhatsAppMessageInteractive_Addendum_Type.type_unspecified:
            return "type_unspecified";
        case WhatsAppMessageInteractive_Addendum_Type.list:
            return "list";
        case WhatsAppMessageInteractive_Addendum_Type.button:
            return "button";
        case WhatsAppMessageInteractive_Addendum_Type.product:
            return "product";
        case WhatsAppMessageInteractive_Addendum_Type.product_list:
            return "product_list";
        case WhatsAppMessageInteractive_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Header_Addendum_Type;
(function (Header_Addendum_Type) {
    Header_Addendum_Type[Header_Addendum_Type["unspecified"] = 0] = "unspecified";
    Header_Addendum_Type[Header_Addendum_Type["text"] = 1] = "text";
    Header_Addendum_Type[Header_Addendum_Type["video"] = 2] = "video";
    Header_Addendum_Type[Header_Addendum_Type["image"] = 3] = "image";
    Header_Addendum_Type[Header_Addendum_Type["document"] = 4] = "document";
    Header_Addendum_Type[Header_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Header_Addendum_Type || (Header_Addendum_Type = {}));
export function header_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return Header_Addendum_Type.unspecified;
        case 1:
        case "text":
            return Header_Addendum_Type.text;
        case 2:
        case "video":
            return Header_Addendum_Type.video;
        case 3:
        case "image":
            return Header_Addendum_Type.image;
        case 4:
        case "document":
            return Header_Addendum_Type.document;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Header_Addendum_Type.UNRECOGNIZED;
    }
}
export function header_Addendum_TypeToJSON(object) {
    switch (object) {
        case Header_Addendum_Type.unspecified:
            return "unspecified";
        case Header_Addendum_Type.text:
            return "text";
        case Header_Addendum_Type.video:
            return "video";
        case Header_Addendum_Type.image:
            return "image";
        case Header_Addendum_Type.document:
            return "document";
        case Header_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Action_ButtonType;
(function (Action_ButtonType) {
    Action_ButtonType[Action_ButtonType["button_unspecified"] = 0] = "button_unspecified";
    Action_ButtonType[Action_ButtonType["reply"] = 1] = "reply";
    Action_ButtonType[Action_ButtonType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Action_ButtonType || (Action_ButtonType = {}));
export function action_ButtonTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "button_unspecified":
            return Action_ButtonType.button_unspecified;
        case 1:
        case "reply":
            return Action_ButtonType.reply;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Action_ButtonType.UNRECOGNIZED;
    }
}
export function action_ButtonTypeToJSON(object) {
    switch (object) {
        case Action_ButtonType.button_unspecified:
            return "button_unspecified";
        case Action_ButtonType.reply:
            return "reply";
        case Action_ButtonType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseWhatsAppRegistrationRequest() {
    return { messagingProduct: undefined, pin: undefined };
}
export const WhatsAppRegistrationRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.pin !== undefined) {
            StringValue.encode({ value: message.pin }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppRegistrationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.pin = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            pin: isSet(object.pin) ? String(object.pin) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.pin !== undefined && (obj.pin = message.pin);
        return obj;
    },
};
function createBaseWhatsAppUpdateResponse() {
    return { success: undefined };
}
export const WhatsAppUpdateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.success !== undefined) {
            BoolValue.encode({ value: message.success }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppUpdateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.success = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            success: isSet(object.success) ? Boolean(object.success) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.success !== undefined && (obj.success = message.success);
        return obj;
    },
};
function createBaseWhatsAppMessageRequest() {
    return {
        messagingProduct: undefined,
        recipientType: undefined,
        to: undefined,
        type: 0,
        text: undefined,
        image: undefined,
        document: undefined,
        video: undefined,
        template: undefined,
        audio: undefined,
        location: undefined,
        interactive: undefined,
    };
}
export const WhatsAppMessageRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.recipientType !== undefined) {
            StringValue.encode({ value: message.recipientType }, writer.uint32(18).fork()).ldelim();
        }
        if (message.to !== undefined) {
            StringValue.encode({ value: message.to }, writer.uint32(26).fork()).ldelim();
        }
        if (message.type !== 0) {
            writer.uint32(32).int32(message.type);
        }
        if (message.text !== undefined) {
            WhatsAppText.encode(message.text, writer.uint32(42).fork()).ldelim();
        }
        if (message.image !== undefined) {
            WhatsAppMedia.encode(message.image, writer.uint32(50).fork()).ldelim();
        }
        if (message.document !== undefined) {
            WhatsAppMedia.encode(message.document, writer.uint32(58).fork()).ldelim();
        }
        if (message.video !== undefined) {
            WhatsAppMedia.encode(message.video, writer.uint32(66).fork()).ldelim();
        }
        if (message.template !== undefined) {
            WhatsAppTemplate.encode(message.template, writer.uint32(74).fork()).ldelim();
        }
        if (message.audio !== undefined) {
            WhatsAppMedia.encode(message.audio, writer.uint32(82).fork()).ldelim();
        }
        if (message.location !== undefined) {
            WhatsAppLocation.encode(message.location, writer.uint32(90).fork()).ldelim();
        }
        if (message.interactive !== undefined) {
            WhatsAppMessageInteractive.encode(message.interactive, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.recipientType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.to = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.text = WhatsAppText.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.image = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.document = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.video = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.template = WhatsAppTemplate.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.audio = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.location = WhatsAppLocation.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.interactive = WhatsAppMessageInteractive.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            recipientType: isSet(object.recipientType)
                ? String(object.recipientType)
                : undefined,
            to: isSet(object.to) ? String(object.to) : undefined,
            type: isSet(object.type) ? messageTypeFromJSON(object.type) : 0,
            text: isSet(object.text) ? WhatsAppText.fromJSON(object.text) : undefined,
            image: isSet(object.image)
                ? WhatsAppMedia.fromJSON(object.image)
                : undefined,
            document: isSet(object.document)
                ? WhatsAppMedia.fromJSON(object.document)
                : undefined,
            video: isSet(object.video)
                ? WhatsAppMedia.fromJSON(object.video)
                : undefined,
            template: isSet(object.template)
                ? WhatsAppTemplate.fromJSON(object.template)
                : undefined,
            audio: isSet(object.audio)
                ? WhatsAppMedia.fromJSON(object.audio)
                : undefined,
            location: isSet(object.location)
                ? WhatsAppLocation.fromJSON(object.location)
                : undefined,
            interactive: isSet(object.interactive)
                ? WhatsAppMessageInteractive.fromJSON(object.interactive)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.recipientType !== undefined &&
            (obj.recipientType = message.recipientType);
        message.to !== undefined && (obj.to = message.to);
        message.type !== undefined && (obj.type = messageTypeToJSON(message.type));
        message.text !== undefined &&
            (obj.text = message.text ? WhatsAppText.toJSON(message.text) : undefined);
        message.image !== undefined &&
            (obj.image = message.image
                ? WhatsAppMedia.toJSON(message.image)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? WhatsAppMedia.toJSON(message.document)
                : undefined);
        message.video !== undefined &&
            (obj.video = message.video
                ? WhatsAppMedia.toJSON(message.video)
                : undefined);
        message.template !== undefined &&
            (obj.template = message.template
                ? WhatsAppTemplate.toJSON(message.template)
                : undefined);
        message.audio !== undefined &&
            (obj.audio = message.audio
                ? WhatsAppMedia.toJSON(message.audio)
                : undefined);
        message.location !== undefined &&
            (obj.location = message.location
                ? WhatsAppLocation.toJSON(message.location)
                : undefined);
        message.interactive !== undefined &&
            (obj.interactive = message.interactive
                ? WhatsAppMessageInteractive.toJSON(message.interactive)
                : undefined);
        return obj;
    },
};
function createBaseWhatsAppMessageResponse() {
    return { messagingProduct: undefined, messages: [] };
}
export const WhatsAppMessageResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.messages) {
            WhatsAppMessageResponse_Message.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.messages.push(WhatsAppMessageResponse_Message.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            messages: Array.isArray(object === null || object === void 0 ? void 0 : object.messages)
                ? object.messages.map((e) => WhatsAppMessageResponse_Message.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        if (message.messages) {
            obj.messages = message.messages.map((e) => e ? WhatsAppMessageResponse_Message.toJSON(e) : undefined);
        }
        else {
            obj.messages = [];
        }
        return obj;
    },
};
function createBaseWhatsAppMessageResponse_Message() {
    return { id: undefined };
}
export const WhatsAppMessageResponse_Message = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageResponse_Message();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppText() {
    return { previewUrl: undefined, body: undefined };
}
export const WhatsAppText = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.previewUrl !== undefined) {
            BoolValue.encode({ value: message.previewUrl }, writer.uint32(10).fork()).ldelim();
        }
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppText();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.previewUrl = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            previewUrl: isSet(object.previewUrl)
                ? Boolean(object.previewUrl)
                : undefined,
            body: isSet(object.body) ? String(object.body) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.previewUrl !== undefined && (obj.previewUrl = message.previewUrl);
        message.body !== undefined && (obj.body = message.body);
        return obj;
    },
};
function createBaseWhatsAppMedia() {
    return {
        id: undefined,
        link: undefined,
        caption: undefined,
        filename: undefined,
    };
}
export const WhatsAppMedia = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.link !== undefined) {
            StringValue.encode({ value: message.link }, writer.uint32(18).fork()).ldelim();
        }
        if (message.caption !== undefined) {
            StringValue.encode({ value: message.caption }, writer.uint32(26).fork()).ldelim();
        }
        if (message.filename !== undefined) {
            StringValue.encode({ value: message.filename }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMedia();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.link = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.caption = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.filename = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            link: isSet(object.link) ? String(object.link) : undefined,
            caption: isSet(object.caption) ? String(object.caption) : undefined,
            filename: isSet(object.filename) ? String(object.filename) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.link !== undefined && (obj.link = message.link);
        message.caption !== undefined && (obj.caption = message.caption);
        message.filename !== undefined && (obj.filename = message.filename);
        return obj;
    },
};
function createBaseWhatsAppLocation() {
    return {
        latitude: undefined,
        longitude: undefined,
        name: undefined,
        address: undefined,
    };
}
export const WhatsAppLocation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.latitude !== undefined) {
            FloatValue.encode({ value: message.latitude }, writer.uint32(10).fork()).ldelim();
        }
        if (message.longitude !== undefined) {
            FloatValue.encode({ value: message.longitude }, writer.uint32(18).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(26).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppLocation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.latitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.longitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
            longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.latitude !== undefined && (obj.latitude = message.latitude);
        message.longitude !== undefined && (obj.longitude = message.longitude);
        message.name !== undefined && (obj.name = message.name);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
};
function createBaseWhatsAppTemplate() {
    return { name: "", language: undefined, components: [] };
}
export const WhatsAppTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.language !== undefined) {
            Language.encode(message.language, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.components) {
            Component.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.language = Language.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.components.push(Component.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            language: isSet(object.language)
                ? Language.fromJSON(object.language)
                : undefined,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => Component.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.language !== undefined &&
            (obj.language = message.language
                ? Language.toJSON(message.language)
                : undefined);
        if (message.components) {
            obj.components = message.components.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseLanguage() {
    return { policy: undefined, code: "" };
}
export const Language = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.policy !== undefined) {
            StringValue.encode({ value: message.policy }, writer.uint32(10).fork()).ldelim();
        }
        if (message.code !== "") {
            writer.uint32(18).string(message.code);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLanguage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.policy = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            policy: isSet(object.policy) ? String(object.policy) : undefined,
            code: isSet(object.code) ? String(object.code) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.policy !== undefined && (obj.policy = message.policy);
        message.code !== undefined && (obj.code = message.code);
        return obj;
    },
};
function createBaseComponent() {
    return {
        type: 0,
        subType: undefined,
        parameters: [],
        index: undefined,
        cardIndex: undefined,
        components: [],
        cards: [],
    };
}
export const Component = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.subType !== undefined) {
            StringValue.encode({ value: message.subType }, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.parameters) {
            Parameter.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.index !== undefined) {
            UInt32Value.encode({ value: message.index }, writer.uint32(34).fork()).ldelim();
        }
        if (message.cardIndex !== undefined) {
            UInt32Value.encode({ value: message.cardIndex }, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.components) {
            Component.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.cards) {
            Component.encode(v, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.subType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.parameters.push(Parameter.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.index = UInt32Value.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.cardIndex = UInt32Value.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.components.push(Component.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.cards.push(Component.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? component_Addendum_TypeFromJSON(object.type)
                : 0,
            subType: isSet(object.subType) ? String(object.subType) : undefined,
            parameters: Array.isArray(object === null || object === void 0 ? void 0 : object.parameters)
                ? object.parameters.map((e) => Parameter.fromJSON(e))
                : [],
            index: isSet(object.index) ? Number(object.index) : undefined,
            cardIndex: isSet(object.cardIndex) ? Number(object.cardIndex) : undefined,
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => Component.fromJSON(e))
                : [],
            cards: Array.isArray(object === null || object === void 0 ? void 0 : object.cards)
                ? object.cards.map((e) => Component.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = component_Addendum_TypeToJSON(message.type));
        message.subType !== undefined && (obj.subType = message.subType);
        if (message.parameters) {
            obj.parameters = message.parameters.map((e) => e ? Parameter.toJSON(e) : undefined);
        }
        else {
            obj.parameters = [];
        }
        message.index !== undefined && (obj.index = message.index);
        message.cardIndex !== undefined && (obj.cardIndex = message.cardIndex);
        if (message.components) {
            obj.components = message.components.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        if (message.cards) {
            obj.cards = message.cards.map((e) => e ? Component.toJSON(e) : undefined);
        }
        else {
            obj.cards = [];
        }
        return obj;
    },
};
function createBaseComponent_Addendum() {
    return {};
}
export const Component_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseComponent_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseParameter() {
    return {
        type: 0,
        text: undefined,
        payload: undefined,
        image: undefined,
        document: undefined,
        video: undefined,
        location: undefined,
    };
}
export const Parameter = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.text !== undefined) {
            StringValue.encode({ value: message.text }, writer.uint32(18).fork()).ldelim();
        }
        if (message.payload !== undefined) {
            StringValue.encode({ value: message.payload }, writer.uint32(26).fork()).ldelim();
        }
        if (message.image !== undefined) {
            WhatsAppMedia.encode(message.image, writer.uint32(34).fork()).ldelim();
        }
        if (message.document !== undefined) {
            WhatsAppMedia.encode(message.document, writer.uint32(42).fork()).ldelim();
        }
        if (message.video !== undefined) {
            WhatsAppMedia.encode(message.video, writer.uint32(50).fork()).ldelim();
        }
        if (message.location !== undefined) {
            WhatsAppLocation.encode(message.location, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParameter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.text = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.payload = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.image = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.document = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.video = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.location = WhatsAppLocation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? parameter_Addendum_TypeFromJSON(object.type)
                : 0,
            text: isSet(object.text) ? String(object.text) : undefined,
            payload: isSet(object.payload) ? String(object.payload) : undefined,
            image: isSet(object.image)
                ? WhatsAppMedia.fromJSON(object.image)
                : undefined,
            document: isSet(object.document)
                ? WhatsAppMedia.fromJSON(object.document)
                : undefined,
            video: isSet(object.video)
                ? WhatsAppMedia.fromJSON(object.video)
                : undefined,
            location: isSet(object.location)
                ? WhatsAppLocation.fromJSON(object.location)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = parameter_Addendum_TypeToJSON(message.type));
        message.text !== undefined && (obj.text = message.text);
        message.payload !== undefined && (obj.payload = message.payload);
        message.image !== undefined &&
            (obj.image = message.image
                ? WhatsAppMedia.toJSON(message.image)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? WhatsAppMedia.toJSON(message.document)
                : undefined);
        message.video !== undefined &&
            (obj.video = message.video
                ? WhatsAppMedia.toJSON(message.video)
                : undefined);
        message.location !== undefined &&
            (obj.location = message.location
                ? WhatsAppLocation.toJSON(message.location)
                : undefined);
        return obj;
    },
};
function createBaseParameter_Addendum() {
    return {};
}
export const Parameter_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParameter_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseWhatsAppBusinessProfileResponse() {
    return { data: [] };
}
export const WhatsAppBusinessProfileResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.data) {
            WhatsAppBusinessProfileResponse_Data.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppBusinessProfileResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data.push(WhatsAppBusinessProfileResponse_Data.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            data: Array.isArray(object === null || object === void 0 ? void 0 : object.data)
                ? object.data.map((e) => WhatsAppBusinessProfileResponse_Data.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.data) {
            obj.data = message.data.map((e) => e ? WhatsAppBusinessProfileResponse_Data.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
};
function createBaseWhatsAppBusinessProfileResponse_Data() {
    return {
        messagingProduct: undefined,
        address: undefined,
        description: undefined,
        vertical: undefined,
        about: undefined,
        email: undefined,
        websites: [],
        profilePictureUrl: undefined,
        id: undefined,
    };
}
export const WhatsAppBusinessProfileResponse_Data = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(18).fork()).ldelim();
        }
        if (message.description !== undefined) {
            StringValue.encode({ value: message.description }, writer.uint32(26).fork()).ldelim();
        }
        if (message.vertical !== undefined) {
            StringValue.encode({ value: message.vertical }, writer.uint32(34).fork()).ldelim();
        }
        if (message.about !== undefined) {
            StringValue.encode({ value: message.about }, writer.uint32(42).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.websites) {
            StringValue.encode({ value: v }, writer.uint32(58).fork()).ldelim();
        }
        if (message.profilePictureUrl !== undefined) {
            StringValue.encode({ value: message.profilePictureUrl }, writer.uint32(66).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppBusinessProfileResponse_Data();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.description = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.vertical = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.about = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.websites.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 8:
                    message.profilePictureUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
            description: isSet(object.description)
                ? String(object.description)
                : undefined,
            vertical: isSet(object.vertical) ? String(object.vertical) : undefined,
            about: isSet(object.about) ? String(object.about) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            websites: Array.isArray(object === null || object === void 0 ? void 0 : object.websites)
                ? object.websites.map((e) => String(e))
                : [],
            profilePictureUrl: isSet(object.profilePictureUrl)
                ? String(object.profilePictureUrl)
                : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.address !== undefined && (obj.address = message.address);
        message.description !== undefined &&
            (obj.description = message.description);
        message.vertical !== undefined && (obj.vertical = message.vertical);
        message.about !== undefined && (obj.about = message.about);
        message.email !== undefined && (obj.email = message.email);
        if (message.websites) {
            obj.websites = message.websites.map((e) => e);
        }
        else {
            obj.websites = [];
        }
        message.profilePictureUrl !== undefined &&
            (obj.profilePictureUrl = message.profilePictureUrl);
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppUpdateBusinessProfileRequest() {
    return {
        messagingProduct: undefined,
        address: undefined,
        description: undefined,
        vertical: undefined,
        about: undefined,
        email: undefined,
        websites: [],
        profilePictureHandle: undefined,
    };
}
export const WhatsAppUpdateBusinessProfileRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(18).fork()).ldelim();
        }
        if (message.description !== undefined) {
            StringValue.encode({ value: message.description }, writer.uint32(26).fork()).ldelim();
        }
        if (message.vertical !== undefined) {
            StringValue.encode({ value: message.vertical }, writer.uint32(34).fork()).ldelim();
        }
        if (message.about !== undefined) {
            StringValue.encode({ value: message.about }, writer.uint32(42).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.websites) {
            StringValue.encode({ value: v }, writer.uint32(58).fork()).ldelim();
        }
        if (message.profilePictureHandle !== undefined) {
            StringValue.encode({ value: message.profilePictureHandle }, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppUpdateBusinessProfileRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.description = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.vertical = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.about = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.websites.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 8:
                    message.profilePictureHandle = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
            description: isSet(object.description)
                ? String(object.description)
                : undefined,
            vertical: isSet(object.vertical) ? String(object.vertical) : undefined,
            about: isSet(object.about) ? String(object.about) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            websites: Array.isArray(object === null || object === void 0 ? void 0 : object.websites)
                ? object.websites.map((e) => String(e))
                : [],
            profilePictureHandle: isSet(object.profilePictureHandle)
                ? String(object.profilePictureHandle)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.address !== undefined && (obj.address = message.address);
        message.description !== undefined &&
            (obj.description = message.description);
        message.vertical !== undefined && (obj.vertical = message.vertical);
        message.about !== undefined && (obj.about = message.about);
        message.email !== undefined && (obj.email = message.email);
        if (message.websites) {
            obj.websites = message.websites.map((e) => e);
        }
        else {
            obj.websites = [];
        }
        message.profilePictureHandle !== undefined &&
            (obj.profilePictureHandle = message.profilePictureHandle);
        return obj;
    },
};
function createBaseWhatsAppBusinessAccountRequest() {
    return { id: undefined };
}
export const WhatsAppBusinessAccountRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppBusinessAccountRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppBusinessAccountResponse() {
    return {
        id: undefined,
        accountReviewStatus: undefined,
        businessVerificationStatus: undefined,
    };
}
export const WhatsAppBusinessAccountResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.accountReviewStatus !== undefined) {
            StringValue.encode({ value: message.accountReviewStatus }, writer.uint32(18).fork()).ldelim();
        }
        if (message.businessVerificationStatus !== undefined) {
            StringValue.encode({ value: message.businessVerificationStatus }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppBusinessAccountResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.accountReviewStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.businessVerificationStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            accountReviewStatus: isSet(object.accountReviewStatus)
                ? String(object.accountReviewStatus)
                : undefined,
            businessVerificationStatus: isSet(object.businessVerificationStatus)
                ? String(object.businessVerificationStatus)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.accountReviewStatus !== undefined &&
            (obj.accountReviewStatus = message.accountReviewStatus);
        message.businessVerificationStatus !== undefined &&
            (obj.businessVerificationStatus = message.businessVerificationStatus);
        return obj;
    },
};
function createBaseGetWhatsAppNumberResponse() {
    return {
        verifiedName: undefined,
        codeVerificationStatus: undefined,
        displayPhoneNumber: undefined,
        qualityRating: undefined,
        id: undefined,
        isOfficialBusinessAccount: false,
        status: undefined,
    };
}
export const GetWhatsAppNumberResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.verifiedName !== undefined) {
            StringValue.encode({ value: message.verifiedName }, writer.uint32(10).fork()).ldelim();
        }
        if (message.codeVerificationStatus !== undefined) {
            StringValue.encode({ value: message.codeVerificationStatus }, writer.uint32(18).fork()).ldelim();
        }
        if (message.displayPhoneNumber !== undefined) {
            StringValue.encode({ value: message.displayPhoneNumber }, writer.uint32(26).fork()).ldelim();
        }
        if (message.qualityRating !== undefined) {
            StringValue.encode({ value: message.qualityRating }, writer.uint32(34).fork()).ldelim();
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(42).fork()).ldelim();
        }
        if (message.isOfficialBusinessAccount === true) {
            writer.uint32(48).bool(message.isOfficialBusinessAccount);
        }
        if (message.status !== undefined) {
            StringValue.encode({ value: message.status }, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWhatsAppNumberResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.verifiedName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.codeVerificationStatus = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.displayPhoneNumber = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.qualityRating = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.isOfficialBusinessAccount = reader.bool();
                    break;
                case 7:
                    message.status = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            verifiedName: isSet(object.verifiedName)
                ? String(object.verifiedName)
                : undefined,
            codeVerificationStatus: isSet(object.codeVerificationStatus)
                ? String(object.codeVerificationStatus)
                : undefined,
            displayPhoneNumber: isSet(object.displayPhoneNumber)
                ? String(object.displayPhoneNumber)
                : undefined,
            qualityRating: isSet(object.qualityRating)
                ? String(object.qualityRating)
                : undefined,
            id: isSet(object.id) ? String(object.id) : undefined,
            isOfficialBusinessAccount: isSet(object.isOfficialBusinessAccount)
                ? Boolean(object.isOfficialBusinessAccount)
                : false,
            status: isSet(object.status) ? String(object.status) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.verifiedName !== undefined &&
            (obj.verifiedName = message.verifiedName);
        message.codeVerificationStatus !== undefined &&
            (obj.codeVerificationStatus = message.codeVerificationStatus);
        message.displayPhoneNumber !== undefined &&
            (obj.displayPhoneNumber = message.displayPhoneNumber);
        message.qualityRating !== undefined &&
            (obj.qualityRating = message.qualityRating);
        message.id !== undefined && (obj.id = message.id);
        message.isOfficialBusinessAccount !== undefined &&
            (obj.isOfficialBusinessAccount = message.isOfficialBusinessAccount);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
};
function createBaseRetrieveMediaResponse() {
    return {
        messagingProduct: undefined,
        url: undefined,
        mimeType: undefined,
        sha256: undefined,
        fileSize: 0,
        id: undefined,
    };
}
export const RetrieveMediaResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== undefined) {
            StringValue.encode({ value: message.messagingProduct }, writer.uint32(10).fork()).ldelim();
        }
        if (message.url !== undefined) {
            StringValue.encode({ value: message.url }, writer.uint32(18).fork()).ldelim();
        }
        if (message.mimeType !== undefined) {
            StringValue.encode({ value: message.mimeType }, writer.uint32(26).fork()).ldelim();
        }
        if (message.sha256 !== undefined) {
            StringValue.encode({ value: message.sha256 }, writer.uint32(34).fork()).ldelim();
        }
        if (message.fileSize !== 0) {
            writer.uint32(40).uint64(message.fileSize);
        }
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRetrieveMediaResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.url = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.mimeType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.sha256 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.fileSize = longToNumber(reader.uint64());
                    break;
                case 6:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : undefined,
            url: isSet(object.url) ? String(object.url) : undefined,
            mimeType: isSet(object.mimeType) ? String(object.mimeType) : undefined,
            sha256: isSet(object.sha256) ? String(object.sha256) : undefined,
            fileSize: isSet(object.fileSize) ? Number(object.fileSize) : 0,
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.url !== undefined && (obj.url = message.url);
        message.mimeType !== undefined && (obj.mimeType = message.mimeType);
        message.sha256 !== undefined && (obj.sha256 = message.sha256);
        message.fileSize !== undefined &&
            (obj.fileSize = Math.round(message.fileSize));
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppMessageTemplatesResponse() {
    return { data: [], paging: undefined };
}
export const WhatsAppMessageTemplatesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.data) {
            MessageTemplate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageTemplatesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data.push(MessageTemplate.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            data: Array.isArray(object === null || object === void 0 ? void 0 : object.data)
                ? object.data.map((e) => MessageTemplate.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.data) {
            obj.data = message.data.map((e) => e ? MessageTemplate.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        message.paging !== undefined &&
            (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
};
function createBaseMessageTemplate() {
    return {
        id: "",
        name: "",
        language: "",
        status: 0,
        category: "",
        components: [],
        lastUpdatedTime: "",
        rejectedReason: "",
        qualityScore: undefined,
    };
}
export const MessageTemplate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.language !== "") {
            writer.uint32(26).string(message.language);
        }
        if (message.status !== 0) {
            writer.uint32(32).int32(message.status);
        }
        if (message.category !== "") {
            writer.uint32(42).string(message.category);
        }
        for (const v of message.components) {
            MessageTemplateComponent.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.lastUpdatedTime !== "") {
            writer.uint32(58).string(message.lastUpdatedTime);
        }
        if (message.rejectedReason !== "") {
            writer.uint32(66).string(message.rejectedReason);
        }
        if (message.qualityScore !== undefined) {
            MessageTemplateQualityScope.encode(message.qualityScore, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.language = reader.string();
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.category = reader.string();
                    break;
                case 6:
                    message.components.push(MessageTemplateComponent.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.lastUpdatedTime = reader.string();
                    break;
                case 8:
                    message.rejectedReason = reader.string();
                    break;
                case 9:
                    message.qualityScore = MessageTemplateQualityScope.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            language: isSet(object.language) ? String(object.language) : "",
            status: isSet(object.status)
                ? messageTemplate_StatusFromJSON(object.status)
                : 0,
            category: isSet(object.category) ? String(object.category) : "",
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => MessageTemplateComponent.fromJSON(e))
                : [],
            lastUpdatedTime: isSet(object.lastUpdatedTime)
                ? String(object.lastUpdatedTime)
                : "",
            rejectedReason: isSet(object.rejectedReason)
                ? String(object.rejectedReason)
                : "",
            qualityScore: isSet(object.qualityScore)
                ? MessageTemplateQualityScope.fromJSON(object.qualityScore)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.language !== undefined && (obj.language = message.language);
        message.status !== undefined &&
            (obj.status = messageTemplate_StatusToJSON(message.status));
        message.category !== undefined && (obj.category = message.category);
        if (message.components) {
            obj.components = message.components.map((e) => e ? MessageTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        message.lastUpdatedTime !== undefined &&
            (obj.lastUpdatedTime = message.lastUpdatedTime);
        message.rejectedReason !== undefined &&
            (obj.rejectedReason = message.rejectedReason);
        message.qualityScore !== undefined &&
            (obj.qualityScore = message.qualityScore
                ? MessageTemplateQualityScope.toJSON(message.qualityScore)
                : undefined);
        return obj;
    },
};
function createBaseMessageTemplateComponent() {
    return {
        type: 0,
        format: 0,
        text: undefined,
        buttons: [],
        example: undefined,
        cards: [],
    };
}
export const MessageTemplateComponent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.format !== 0) {
            writer.uint32(16).int32(message.format);
        }
        if (message.text !== undefined) {
            StringValue.encode({ value: message.text }, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.buttons) {
            MessageTemplateComponent_Button.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.example !== undefined) {
            MessageTemplateComponent_Example.encode(message.example, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.cards) {
            MessageTemplateComponent_CarouselCard.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateComponent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.format = reader.int32();
                    break;
                case 3:
                    message.text = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.buttons.push(MessageTemplateComponent_Button.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.example = MessageTemplateComponent_Example.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.cards.push(MessageTemplateComponent_CarouselCard.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? messageTemplateComponent_TypeFromJSON(object.type)
                : 0,
            format: isSet(object.format)
                ? messageTemplateComponent_FormatFromJSON(object.format)
                : 0,
            text: isSet(object.text) ? String(object.text) : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => MessageTemplateComponent_Button.fromJSON(e))
                : [],
            example: isSet(object.example)
                ? MessageTemplateComponent_Example.fromJSON(object.example)
                : undefined,
            cards: Array.isArray(object === null || object === void 0 ? void 0 : object.cards)
                ? object.cards.map((e) => MessageTemplateComponent_CarouselCard.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = messageTemplateComponent_TypeToJSON(message.type));
        message.format !== undefined &&
            (obj.format = messageTemplateComponent_FormatToJSON(message.format));
        message.text !== undefined && (obj.text = message.text);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? MessageTemplateComponent_Button.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        message.example !== undefined &&
            (obj.example = message.example
                ? MessageTemplateComponent_Example.toJSON(message.example)
                : undefined);
        if (message.cards) {
            obj.cards = message.cards.map((e) => e ? MessageTemplateComponent_CarouselCard.toJSON(e) : undefined);
        }
        else {
            obj.cards = [];
        }
        return obj;
    },
};
function createBaseMessageTemplateComponent_Button() {
    return { type: 0, text: "", url: "", phoneNumber: "", example: [] };
}
export const MessageTemplateComponent_Button = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        if (message.url !== "") {
            writer.uint32(26).string(message.url);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(34).string(message.phoneNumber);
        }
        for (const v of message.example) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateComponent_Button();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.url = reader.string();
                    break;
                case 4:
                    message.phoneNumber = reader.string();
                    break;
                case 5:
                    message.example.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? messageTemplateComponent_Button_TypeFromJSON(object.type)
                : 0,
            text: isSet(object.text) ? String(object.text) : "",
            url: isSet(object.url) ? String(object.url) : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
            example: Array.isArray(object === null || object === void 0 ? void 0 : object.example)
                ? object.example.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = messageTemplateComponent_Button_TypeToJSON(message.type));
        message.text !== undefined && (obj.text = message.text);
        message.url !== undefined && (obj.url = message.url);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        if (message.example) {
            obj.example = message.example.map((e) => e);
        }
        else {
            obj.example = [];
        }
        return obj;
    },
};
function createBaseMessageTemplateComponent_Example() {
    return { headerText: [], headerHandle: [], bodyText: [] };
}
export const MessageTemplateComponent_Example = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.headerText) {
            writer.uint32(10).string(v);
        }
        for (const v of message.headerHandle) {
            writer.uint32(18).string(v);
        }
        for (const v of message.bodyText) {
            ListValue.encode(ListValue.wrap(v), writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateComponent_Example();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.headerText.push(reader.string());
                    break;
                case 2:
                    message.headerHandle.push(reader.string());
                    break;
                case 3:
                    message.bodyText.push(ListValue.unwrap(ListValue.decode(reader, reader.uint32())));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            headerText: Array.isArray(object === null || object === void 0 ? void 0 : object.headerText)
                ? object.headerText.map((e) => String(e))
                : [],
            headerHandle: Array.isArray(object === null || object === void 0 ? void 0 : object.headerHandle)
                ? object.headerHandle.map((e) => String(e))
                : [],
            bodyText: Array.isArray(object === null || object === void 0 ? void 0 : object.bodyText)
                ? object.bodyText.map((e) => [...e])
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.headerText) {
            obj.headerText = message.headerText.map((e) => e);
        }
        else {
            obj.headerText = [];
        }
        if (message.headerHandle) {
            obj.headerHandle = message.headerHandle.map((e) => e);
        }
        else {
            obj.headerHandle = [];
        }
        if (message.bodyText) {
            obj.bodyText = message.bodyText.map((e) => e);
        }
        else {
            obj.bodyText = [];
        }
        return obj;
    },
};
function createBaseMessageTemplateComponent_CarouselCard() {
    return { components: [] };
}
export const MessageTemplateComponent_CarouselCard = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.components) {
            MessageTemplateComponent.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateComponent_CarouselCard();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.components.push(MessageTemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => MessageTemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.components) {
            obj.components = message.components.map((e) => e ? MessageTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseMessageTemplateQualityScope() {
    return { score: "" };
}
export const MessageTemplateQualityScope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.score !== "") {
            writer.uint32(10).string(message.score);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessageTemplateQualityScope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.score = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            score: isSet(object.score) ? String(object.score) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.score !== undefined && (obj.score = message.score);
        return obj;
    },
};
function createBaseWhatsAppCreateMessageTemplateRequest() {
    return { category: "", name: "", language: "", components: [] };
}
export const WhatsAppCreateMessageTemplateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.category !== "") {
            writer.uint32(10).string(message.category);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.language !== "") {
            writer.uint32(26).string(message.language);
        }
        for (const v of message.components) {
            MessageTemplateComponent.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppCreateMessageTemplateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.category = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.language = reader.string();
                    break;
                case 4:
                    message.components.push(MessageTemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            category: isSet(object.category) ? String(object.category) : "",
            name: isSet(object.name) ? String(object.name) : "",
            language: isSet(object.language) ? String(object.language) : "",
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => MessageTemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.category !== undefined && (obj.category = message.category);
        message.name !== undefined && (obj.name = message.name);
        message.language !== undefined && (obj.language = message.language);
        if (message.components) {
            obj.components = message.components.map((e) => e ? MessageTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseWhatsAppCreateMessageTemplateResponse() {
    return { id: undefined };
}
export const WhatsAppCreateMessageTemplateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppCreateMessageTemplateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseWhatsAppUpdateMessageTemplateRequest() {
    return { components: [] };
}
export const WhatsAppUpdateMessageTemplateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.components) {
            MessageTemplateComponent.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppUpdateMessageTemplateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 4:
                    message.components.push(MessageTemplateComponent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            components: Array.isArray(object === null || object === void 0 ? void 0 : object.components)
                ? object.components.map((e) => MessageTemplateComponent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.components) {
            obj.components = message.components.map((e) => e ? MessageTemplateComponent.toJSON(e) : undefined);
        }
        else {
            obj.components = [];
        }
        return obj;
    },
};
function createBaseWhatsAppUpdateMessageTemplateResponse() {
    return { success: undefined };
}
export const WhatsAppUpdateMessageTemplateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.success !== undefined) {
            BoolValue.encode({ value: message.success }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppUpdateMessageTemplateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.success = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            success: isSet(object.success) ? Boolean(object.success) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.success !== undefined && (obj.success = message.success);
        return obj;
    },
};
function createBaseWhatsAppDeleteMessageTemplateRequest() {
    return { name: "" };
}
export const WhatsAppDeleteMessageTemplateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppDeleteMessageTemplateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseWhatsAppDeleteMessageTemplateResponse() {
    return { success: undefined };
}
export const WhatsAppDeleteMessageTemplateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.success !== undefined) {
            BoolValue.encode({ value: message.success }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppDeleteMessageTemplateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.success = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            success: isSet(object.success) ? Boolean(object.success) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.success !== undefined && (obj.success = message.success);
        return obj;
    },
};
function createBaseWhatsAppMessageInteractive() {
    return {
        type: 0,
        header: undefined,
        body: undefined,
        footer: undefined,
        action: undefined,
    };
}
export const WhatsAppMessageInteractive = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.header !== undefined) {
            Header.encode(message.header, writer.uint32(18).fork()).ldelim();
        }
        if (message.body !== undefined) {
            WhatsAppMessageInteractive_Body.encode(message.body, writer.uint32(26).fork()).ldelim();
        }
        if (message.footer !== undefined) {
            Footer.encode(message.footer, writer.uint32(34).fork()).ldelim();
        }
        if (message.action !== undefined) {
            Action.encode(message.action, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageInteractive();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.header = Header.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.body = WhatsAppMessageInteractive_Body.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.footer = Footer.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.action = Action.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? whatsAppMessageInteractive_Addendum_TypeFromJSON(object.type)
                : 0,
            header: isSet(object.header) ? Header.fromJSON(object.header) : undefined,
            body: isSet(object.body)
                ? WhatsAppMessageInteractive_Body.fromJSON(object.body)
                : undefined,
            footer: isSet(object.footer) ? Footer.fromJSON(object.footer) : undefined,
            action: isSet(object.action) ? Action.fromJSON(object.action) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = whatsAppMessageInteractive_Addendum_TypeToJSON(message.type));
        message.header !== undefined &&
            (obj.header = message.header ? Header.toJSON(message.header) : undefined);
        message.body !== undefined &&
            (obj.body = message.body
                ? WhatsAppMessageInteractive_Body.toJSON(message.body)
                : undefined);
        message.footer !== undefined &&
            (obj.footer = message.footer ? Footer.toJSON(message.footer) : undefined);
        message.action !== undefined &&
            (obj.action = message.action ? Action.toJSON(message.action) : undefined);
        return obj;
    },
};
function createBaseWhatsAppMessageInteractive_Addendum() {
    return {};
}
export const WhatsAppMessageInteractive_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageInteractive_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseWhatsAppMessageInteractive_Body() {
    return { text: "" };
}
export const WhatsAppMessageInteractive_Body = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWhatsAppMessageInteractive_Body();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseHeader() {
    return {
        type: 0,
        text: "",
        video: undefined,
        image: undefined,
        document: undefined,
    };
}
export const Header = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.text !== "") {
            writer.uint32(18).string(message.text);
        }
        if (message.video !== undefined) {
            WhatsAppMedia.encode(message.video, writer.uint32(26).fork()).ldelim();
        }
        if (message.image !== undefined) {
            WhatsAppMedia.encode(message.image, writer.uint32(34).fork()).ldelim();
        }
        if (message.document !== undefined) {
            WhatsAppMedia.encode(message.document, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeader();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.video = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.image = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.document = WhatsAppMedia.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? header_Addendum_TypeFromJSON(object.type) : 0,
            text: isSet(object.text) ? String(object.text) : "",
            video: isSet(object.video)
                ? WhatsAppMedia.fromJSON(object.video)
                : undefined,
            image: isSet(object.image)
                ? WhatsAppMedia.fromJSON(object.image)
                : undefined,
            document: isSet(object.document)
                ? WhatsAppMedia.fromJSON(object.document)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = header_Addendum_TypeToJSON(message.type));
        message.text !== undefined && (obj.text = message.text);
        message.video !== undefined &&
            (obj.video = message.video
                ? WhatsAppMedia.toJSON(message.video)
                : undefined);
        message.image !== undefined &&
            (obj.image = message.image
                ? WhatsAppMedia.toJSON(message.image)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? WhatsAppMedia.toJSON(message.document)
                : undefined);
        return obj;
    },
};
function createBaseHeader_Addendum() {
    return {};
}
export const Header_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeader_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseFooter() {
    return { text: "" };
}
export const Footer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFooter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseAction() {
    return {
        button: "",
        buttons: [],
        sections: [],
        catalogId: "",
        productRetailerId: "",
    };
}
export const Action = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.button !== "") {
            writer.uint32(10).string(message.button);
        }
        for (const v of message.buttons) {
            Action_Button.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.sections) {
            Section.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.catalogId !== "") {
            writer.uint32(34).string(message.catalogId);
        }
        if (message.productRetailerId !== "") {
            writer.uint32(42).string(message.productRetailerId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.button = reader.string();
                    break;
                case 2:
                    message.buttons.push(Action_Button.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sections.push(Section.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.catalogId = reader.string();
                    break;
                case 5:
                    message.productRetailerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            button: isSet(object.button) ? String(object.button) : "",
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => Action_Button.fromJSON(e))
                : [],
            sections: Array.isArray(object === null || object === void 0 ? void 0 : object.sections)
                ? object.sections.map((e) => Section.fromJSON(e))
                : [],
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
            productRetailerId: isSet(object.productRetailerId)
                ? String(object.productRetailerId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.button !== undefined && (obj.button = message.button);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? Action_Button.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        if (message.sections) {
            obj.sections = message.sections.map((e) => e ? Section.toJSON(e) : undefined);
        }
        else {
            obj.sections = [];
        }
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        message.productRetailerId !== undefined &&
            (obj.productRetailerId = message.productRetailerId);
        return obj;
    },
};
function createBaseAction_Button() {
    return { type: 0, reply: undefined };
}
export const Action_Button = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.reply !== undefined) {
            Action_ButtonReply.encode(message.reply, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAction_Button();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.reply = Action_ButtonReply.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? action_ButtonTypeFromJSON(object.type) : 0,
            reply: isSet(object.reply)
                ? Action_ButtonReply.fromJSON(object.reply)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = action_ButtonTypeToJSON(message.type));
        message.reply !== undefined &&
            (obj.reply = message.reply
                ? Action_ButtonReply.toJSON(message.reply)
                : undefined);
        return obj;
    },
};
function createBaseAction_ButtonReply() {
    return { id: "", title: "" };
}
export const Action_ButtonReply = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAction_ButtonReply();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            title: isSet(object.title) ? String(object.title) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.title !== undefined && (obj.title = message.title);
        return obj;
    },
};
function createBaseSection() {
    return { title: "", rows: [], productItems: [] };
}
export const Section = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        for (const v of message.rows) {
            Section_Row.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.productItems) {
            Section_Product.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.rows.push(Section_Row.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.productItems.push(Section_Product.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            rows: Array.isArray(object === null || object === void 0 ? void 0 : object.rows)
                ? object.rows.map((e) => Section_Row.fromJSON(e))
                : [],
            productItems: Array.isArray(object === null || object === void 0 ? void 0 : object.productItems)
                ? object.productItems.map((e) => Section_Product.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        if (message.rows) {
            obj.rows = message.rows.map((e) => e ? Section_Row.toJSON(e) : undefined);
        }
        else {
            obj.rows = [];
        }
        if (message.productItems) {
            obj.productItems = message.productItems.map((e) => e ? Section_Product.toJSON(e) : undefined);
        }
        else {
            obj.productItems = [];
        }
        return obj;
    },
};
function createBaseSection_Row() {
    return { id: "", title: "", description: "" };
}
export const Section_Row = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSection_Row();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined &&
            (obj.description = message.description);
        return obj;
    },
};
function createBaseSection_Product() {
    return { productRetailerId: "" };
}
export const Section_Product = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.productRetailerId !== "") {
            writer.uint32(10).string(message.productRetailerId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSection_Product();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.productRetailerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            productRetailerId: isSet(object.productRetailerId)
                ? String(object.productRetailerId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.productRetailerId !== undefined &&
            (obj.productRetailerId = message.productRetailerId);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
