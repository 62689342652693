import { channelTypeFromJSON, channelTypeToJSON, } from "../../common/channel/models";
import { Duration } from "../../../google/protobuf/duration";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.integration.parameters";
export var ShopifyDiscount_DiscountType;
(function (ShopifyDiscount_DiscountType) {
    ShopifyDiscount_DiscountType[ShopifyDiscount_DiscountType["DISCOUNT_TYPE_UNSPECIFIED"] = 0] = "DISCOUNT_TYPE_UNSPECIFIED";
    ShopifyDiscount_DiscountType[ShopifyDiscount_DiscountType["DISCOUNT_TYPE_FIXED_AMOUNT"] = 1] = "DISCOUNT_TYPE_FIXED_AMOUNT";
    ShopifyDiscount_DiscountType[ShopifyDiscount_DiscountType["DISCOUNT_TYPE_PERCENTAGE"] = 2] = "DISCOUNT_TYPE_PERCENTAGE";
    ShopifyDiscount_DiscountType[ShopifyDiscount_DiscountType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ShopifyDiscount_DiscountType || (ShopifyDiscount_DiscountType = {}));
export function shopifyDiscount_DiscountTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "DISCOUNT_TYPE_UNSPECIFIED":
            return ShopifyDiscount_DiscountType.DISCOUNT_TYPE_UNSPECIFIED;
        case 1:
        case "DISCOUNT_TYPE_FIXED_AMOUNT":
            return ShopifyDiscount_DiscountType.DISCOUNT_TYPE_FIXED_AMOUNT;
        case 2:
        case "DISCOUNT_TYPE_PERCENTAGE":
            return ShopifyDiscount_DiscountType.DISCOUNT_TYPE_PERCENTAGE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ShopifyDiscount_DiscountType.UNRECOGNIZED;
    }
}
export function shopifyDiscount_DiscountTypeToJSON(object) {
    switch (object) {
        case ShopifyDiscount_DiscountType.DISCOUNT_TYPE_UNSPECIFIED:
            return "DISCOUNT_TYPE_UNSPECIFIED";
        case ShopifyDiscount_DiscountType.DISCOUNT_TYPE_FIXED_AMOUNT:
            return "DISCOUNT_TYPE_FIXED_AMOUNT";
        case ShopifyDiscount_DiscountType.DISCOUNT_TYPE_PERCENTAGE:
            return "DISCOUNT_TYPE_PERCENTAGE";
        case ShopifyDiscount_DiscountType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCustomZendeskHandoverParameters() {
    return { language: "", storeName: "" };
}
export const CustomZendeskHandoverParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.language !== "") {
            writer.uint32(10).string(message.language);
        }
        if (message.storeName !== "") {
            writer.uint32(18).string(message.storeName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomZendeskHandoverParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.language = reader.string();
                    break;
                case 2:
                    message.storeName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            language: isSet(object.language) ? String(object.language) : "",
            storeName: isSet(object.storeName) ? String(object.storeName) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.language !== undefined && (obj.language = message.language);
        message.storeName !== undefined && (obj.storeName = message.storeName);
        return obj;
    },
};
function createBaseRRuleWindow() {
    return { rrule: "", duration: undefined };
}
export const RRuleWindow = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rrule !== "") {
            writer.uint32(10).string(message.rrule);
        }
        if (message.duration !== undefined) {
            Duration.encode(message.duration, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRRuleWindow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rrule = reader.string();
                    break;
                case 2:
                    message.duration = Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rrule: isSet(object.rrule) ? String(object.rrule) : "",
            duration: isSet(object.duration)
                ? Duration.fromJSON(object.duration)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.rrule !== undefined && (obj.rrule = message.rrule);
        message.duration !== undefined &&
            (obj.duration = message.duration
                ? Duration.toJSON(message.duration)
                : undefined);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutParameters() {
    return {
        shopifyBusinessChannelId: "",
        waBusinessChannelId: "",
        alwaysOnReminderParameters: undefined,
        reminderParametersList: [],
        semanticVersion: "",
    };
}
export const CustomShopifyAbandonedCheckoutParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopifyBusinessChannelId !== "") {
            writer.uint32(10).string(message.shopifyBusinessChannelId);
        }
        if (message.waBusinessChannelId !== "") {
            writer.uint32(18).string(message.waBusinessChannelId);
        }
        if (message.alwaysOnReminderParameters !== undefined) {
            CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.encode(message.alwaysOnReminderParameters, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.reminderParametersList) {
            CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.semanticVersion !== "") {
            writer.uint32(34).string(message.semanticVersion);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopifyBusinessChannelId = reader.string();
                    break;
                case 2:
                    message.waBusinessChannelId = reader.string();
                    break;
                case 3:
                    message.alwaysOnReminderParameters =
                        CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.reminderParametersList.push(CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.semanticVersion = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopifyBusinessChannelId: isSet(object.shopifyBusinessChannelId)
                ? String(object.shopifyBusinessChannelId)
                : "",
            waBusinessChannelId: isSet(object.waBusinessChannelId)
                ? String(object.waBusinessChannelId)
                : "",
            alwaysOnReminderParameters: isSet(object.alwaysOnReminderParameters)
                ? CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.fromJSON(object.alwaysOnReminderParameters)
                : undefined,
            reminderParametersList: Array.isArray(object === null || object === void 0 ? void 0 : object.reminderParametersList)
                ? object.reminderParametersList.map((e) => CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.fromJSON(e))
                : [],
            semanticVersion: isSet(object.semanticVersion)
                ? String(object.semanticVersion)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopifyBusinessChannelId !== undefined &&
            (obj.shopifyBusinessChannelId = message.shopifyBusinessChannelId);
        message.waBusinessChannelId !== undefined &&
            (obj.waBusinessChannelId = message.waBusinessChannelId);
        message.alwaysOnReminderParameters !== undefined &&
            (obj.alwaysOnReminderParameters = message.alwaysOnReminderParameters
                ? CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.toJSON(message.alwaysOnReminderParameters)
                : undefined);
        if (message.reminderParametersList) {
            obj.reminderParametersList = message.reminderParametersList.map((e) => e
                ? CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder.toJSON(e)
                : undefined);
        }
        else {
            obj.reminderParametersList = [];
        }
        message.semanticVersion !== undefined &&
            (obj.semanticVersion = message.semanticVersion);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder() {
    return {
        reminderTemplateId: "",
        reminderTemplateLanguage: "",
        trackingCode: "",
        waitBeforeRemind: undefined,
        headerImageEnabled: false,
        linkTrackingEnabled: false,
        conversionTrackingEnabled: false,
        personalizedMessageEnabled: false,
        discount: undefined,
        salesBotHandoverEnabled: false,
        rruleWindow: undefined,
    };
}
export const CustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reminderTemplateId !== "") {
            writer.uint32(10).string(message.reminderTemplateId);
        }
        if (message.reminderTemplateLanguage !== "") {
            writer.uint32(18).string(message.reminderTemplateLanguage);
        }
        if (message.trackingCode !== "") {
            writer.uint32(34).string(message.trackingCode);
        }
        if (message.waitBeforeRemind !== undefined) {
            Duration.encode(message.waitBeforeRemind, writer.uint32(42).fork()).ldelim();
        }
        if (message.headerImageEnabled === true) {
            writer.uint32(48).bool(message.headerImageEnabled);
        }
        if (message.linkTrackingEnabled === true) {
            writer.uint32(56).bool(message.linkTrackingEnabled);
        }
        if (message.conversionTrackingEnabled === true) {
            writer.uint32(64).bool(message.conversionTrackingEnabled);
        }
        if (message.personalizedMessageEnabled === true) {
            writer.uint32(72).bool(message.personalizedMessageEnabled);
        }
        if (message.discount !== undefined) {
            ShopifyDiscount.encode(message.discount, writer.uint32(82).fork()).ldelim();
        }
        if (message.salesBotHandoverEnabled === true) {
            writer.uint32(88).bool(message.salesBotHandoverEnabled);
        }
        if (message.rruleWindow !== undefined) {
            RRuleWindow.encode(message.rruleWindow, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutParameters_AlwaysOnReminder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminderTemplateId = reader.string();
                    break;
                case 2:
                    message.reminderTemplateLanguage = reader.string();
                    break;
                case 4:
                    message.trackingCode = reader.string();
                    break;
                case 5:
                    message.waitBeforeRemind = Duration.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.headerImageEnabled = reader.bool();
                    break;
                case 7:
                    message.linkTrackingEnabled = reader.bool();
                    break;
                case 8:
                    message.conversionTrackingEnabled = reader.bool();
                    break;
                case 9:
                    message.personalizedMessageEnabled = reader.bool();
                    break;
                case 10:
                    message.discount = ShopifyDiscount.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.salesBotHandoverEnabled = reader.bool();
                    break;
                case 12:
                    message.rruleWindow = RRuleWindow.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminderTemplateId: isSet(object.reminderTemplateId)
                ? String(object.reminderTemplateId)
                : "",
            reminderTemplateLanguage: isSet(object.reminderTemplateLanguage)
                ? String(object.reminderTemplateLanguage)
                : "",
            trackingCode: isSet(object.trackingCode)
                ? String(object.trackingCode)
                : "",
            waitBeforeRemind: isSet(object.waitBeforeRemind)
                ? Duration.fromJSON(object.waitBeforeRemind)
                : undefined,
            headerImageEnabled: isSet(object.headerImageEnabled)
                ? Boolean(object.headerImageEnabled)
                : false,
            linkTrackingEnabled: isSet(object.linkTrackingEnabled)
                ? Boolean(object.linkTrackingEnabled)
                : false,
            conversionTrackingEnabled: isSet(object.conversionTrackingEnabled)
                ? Boolean(object.conversionTrackingEnabled)
                : false,
            personalizedMessageEnabled: isSet(object.personalizedMessageEnabled)
                ? Boolean(object.personalizedMessageEnabled)
                : false,
            discount: isSet(object.discount)
                ? ShopifyDiscount.fromJSON(object.discount)
                : undefined,
            salesBotHandoverEnabled: isSet(object.salesBotHandoverEnabled)
                ? Boolean(object.salesBotHandoverEnabled)
                : false,
            rruleWindow: isSet(object.rruleWindow)
                ? RRuleWindow.fromJSON(object.rruleWindow)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reminderTemplateId !== undefined &&
            (obj.reminderTemplateId = message.reminderTemplateId);
        message.reminderTemplateLanguage !== undefined &&
            (obj.reminderTemplateLanguage = message.reminderTemplateLanguage);
        message.trackingCode !== undefined &&
            (obj.trackingCode = message.trackingCode);
        message.waitBeforeRemind !== undefined &&
            (obj.waitBeforeRemind = message.waitBeforeRemind
                ? Duration.toJSON(message.waitBeforeRemind)
                : undefined);
        message.headerImageEnabled !== undefined &&
            (obj.headerImageEnabled = message.headerImageEnabled);
        message.linkTrackingEnabled !== undefined &&
            (obj.linkTrackingEnabled = message.linkTrackingEnabled);
        message.conversionTrackingEnabled !== undefined &&
            (obj.conversionTrackingEnabled = message.conversionTrackingEnabled);
        message.personalizedMessageEnabled !== undefined &&
            (obj.personalizedMessageEnabled = message.personalizedMessageEnabled);
        message.discount !== undefined &&
            (obj.discount = message.discount
                ? ShopifyDiscount.toJSON(message.discount)
                : undefined);
        message.salesBotHandoverEnabled !== undefined &&
            (obj.salesBotHandoverEnabled = message.salesBotHandoverEnabled);
        message.rruleWindow !== undefined &&
            (obj.rruleWindow = message.rruleWindow
                ? RRuleWindow.toJSON(message.rruleWindow)
                : undefined);
        return obj;
    },
};
function createBaseCustomVtexAbandonedCheckoutParameters() {
    return {
        vtexBusinessChannelId: "",
        waBusinessChannelId: "",
        reminderParametersList: [],
        semanticVersion: "",
        vtexStoreDomain: "",
    };
}
export const CustomVtexAbandonedCheckoutParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.vtexBusinessChannelId !== "") {
            writer.uint32(10).string(message.vtexBusinessChannelId);
        }
        if (message.waBusinessChannelId !== "") {
            writer.uint32(18).string(message.waBusinessChannelId);
        }
        for (const v of message.reminderParametersList) {
            CustomVtexAbandonedCheckoutParameters_AlwaysOnReminder.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.semanticVersion !== "") {
            writer.uint32(34).string(message.semanticVersion);
        }
        if (message.vtexStoreDomain !== "") {
            writer.uint32(50).string(message.vtexStoreDomain);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomVtexAbandonedCheckoutParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vtexBusinessChannelId = reader.string();
                    break;
                case 2:
                    message.waBusinessChannelId = reader.string();
                    break;
                case 5:
                    message.reminderParametersList.push(CustomVtexAbandonedCheckoutParameters_AlwaysOnReminder.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.semanticVersion = reader.string();
                    break;
                case 6:
                    message.vtexStoreDomain = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            vtexBusinessChannelId: isSet(object.vtexBusinessChannelId)
                ? String(object.vtexBusinessChannelId)
                : "",
            waBusinessChannelId: isSet(object.waBusinessChannelId)
                ? String(object.waBusinessChannelId)
                : "",
            reminderParametersList: Array.isArray(object === null || object === void 0 ? void 0 : object.reminderParametersList)
                ? object.reminderParametersList.map((e) => CustomVtexAbandonedCheckoutParameters_AlwaysOnReminder.fromJSON(e))
                : [],
            semanticVersion: isSet(object.semanticVersion)
                ? String(object.semanticVersion)
                : "",
            vtexStoreDomain: isSet(object.vtexStoreDomain)
                ? String(object.vtexStoreDomain)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.vtexBusinessChannelId !== undefined &&
            (obj.vtexBusinessChannelId = message.vtexBusinessChannelId);
        message.waBusinessChannelId !== undefined &&
            (obj.waBusinessChannelId = message.waBusinessChannelId);
        if (message.reminderParametersList) {
            obj.reminderParametersList = message.reminderParametersList.map((e) => e
                ? CustomVtexAbandonedCheckoutParameters_AlwaysOnReminder.toJSON(e)
                : undefined);
        }
        else {
            obj.reminderParametersList = [];
        }
        message.semanticVersion !== undefined &&
            (obj.semanticVersion = message.semanticVersion);
        message.vtexStoreDomain !== undefined &&
            (obj.vtexStoreDomain = message.vtexStoreDomain);
        return obj;
    },
};
function createBaseCustomVtexAbandonedCheckoutParameters_AlwaysOnReminder() {
    return {
        reminderTemplateId: "",
        reminderTemplateLanguage: "",
        trackingCode: "",
        waitBeforeRemind: undefined,
        headerImageEnabled: false,
        linkTrackingEnabled: false,
        conversionTrackingEnabled: false,
        personalizedMessageEnabled: false,
    };
}
export const CustomVtexAbandonedCheckoutParameters_AlwaysOnReminder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reminderTemplateId !== "") {
            writer.uint32(10).string(message.reminderTemplateId);
        }
        if (message.reminderTemplateLanguage !== "") {
            writer.uint32(18).string(message.reminderTemplateLanguage);
        }
        if (message.trackingCode !== "") {
            writer.uint32(34).string(message.trackingCode);
        }
        if (message.waitBeforeRemind !== undefined) {
            Duration.encode(message.waitBeforeRemind, writer.uint32(42).fork()).ldelim();
        }
        if (message.headerImageEnabled === true) {
            writer.uint32(48).bool(message.headerImageEnabled);
        }
        if (message.linkTrackingEnabled === true) {
            writer.uint32(56).bool(message.linkTrackingEnabled);
        }
        if (message.conversionTrackingEnabled === true) {
            writer.uint32(64).bool(message.conversionTrackingEnabled);
        }
        if (message.personalizedMessageEnabled === true) {
            writer.uint32(72).bool(message.personalizedMessageEnabled);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomVtexAbandonedCheckoutParameters_AlwaysOnReminder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminderTemplateId = reader.string();
                    break;
                case 2:
                    message.reminderTemplateLanguage = reader.string();
                    break;
                case 4:
                    message.trackingCode = reader.string();
                    break;
                case 5:
                    message.waitBeforeRemind = Duration.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.headerImageEnabled = reader.bool();
                    break;
                case 7:
                    message.linkTrackingEnabled = reader.bool();
                    break;
                case 8:
                    message.conversionTrackingEnabled = reader.bool();
                    break;
                case 9:
                    message.personalizedMessageEnabled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminderTemplateId: isSet(object.reminderTemplateId)
                ? String(object.reminderTemplateId)
                : "",
            reminderTemplateLanguage: isSet(object.reminderTemplateLanguage)
                ? String(object.reminderTemplateLanguage)
                : "",
            trackingCode: isSet(object.trackingCode)
                ? String(object.trackingCode)
                : "",
            waitBeforeRemind: isSet(object.waitBeforeRemind)
                ? Duration.fromJSON(object.waitBeforeRemind)
                : undefined,
            headerImageEnabled: isSet(object.headerImageEnabled)
                ? Boolean(object.headerImageEnabled)
                : false,
            linkTrackingEnabled: isSet(object.linkTrackingEnabled)
                ? Boolean(object.linkTrackingEnabled)
                : false,
            conversionTrackingEnabled: isSet(object.conversionTrackingEnabled)
                ? Boolean(object.conversionTrackingEnabled)
                : false,
            personalizedMessageEnabled: isSet(object.personalizedMessageEnabled)
                ? Boolean(object.personalizedMessageEnabled)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reminderTemplateId !== undefined &&
            (obj.reminderTemplateId = message.reminderTemplateId);
        message.reminderTemplateLanguage !== undefined &&
            (obj.reminderTemplateLanguage = message.reminderTemplateLanguage);
        message.trackingCode !== undefined &&
            (obj.trackingCode = message.trackingCode);
        message.waitBeforeRemind !== undefined &&
            (obj.waitBeforeRemind = message.waitBeforeRemind
                ? Duration.toJSON(message.waitBeforeRemind)
                : undefined);
        message.headerImageEnabled !== undefined &&
            (obj.headerImageEnabled = message.headerImageEnabled);
        message.linkTrackingEnabled !== undefined &&
            (obj.linkTrackingEnabled = message.linkTrackingEnabled);
        message.conversionTrackingEnabled !== undefined &&
            (obj.conversionTrackingEnabled = message.conversionTrackingEnabled);
        message.personalizedMessageEnabled !== undefined &&
            (obj.personalizedMessageEnabled = message.personalizedMessageEnabled);
        return obj;
    },
};
function createBaseCustomShopifyMultiProductMarketingParameters() {
    return {
        campaignName: "",
        messageParameters: undefined,
        checkoutTemplateId: "",
        checkoutTemplateLanguage: "",
        fbBusinessChannelId: "",
    };
}
export const CustomShopifyMultiProductMarketingParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignName !== "") {
            writer.uint32(10).string(message.campaignName);
        }
        if (message.messageParameters !== undefined) {
            MultiProductMessageParameters.encode(message.messageParameters, writer.uint32(18).fork()).ldelim();
        }
        if (message.checkoutTemplateId !== "") {
            writer.uint32(26).string(message.checkoutTemplateId);
        }
        if (message.checkoutTemplateLanguage !== "") {
            writer.uint32(34).string(message.checkoutTemplateLanguage);
        }
        if (message.fbBusinessChannelId !== "") {
            writer.uint32(42).string(message.fbBusinessChannelId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyMultiProductMarketingParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignName = reader.string();
                    break;
                case 2:
                    message.messageParameters = MultiProductMessageParameters.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.checkoutTemplateId = reader.string();
                    break;
                case 4:
                    message.checkoutTemplateLanguage = reader.string();
                    break;
                case 5:
                    message.fbBusinessChannelId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignName: isSet(object.campaignName)
                ? String(object.campaignName)
                : "",
            messageParameters: isSet(object.messageParameters)
                ? MultiProductMessageParameters.fromJSON(object.messageParameters)
                : undefined,
            checkoutTemplateId: isSet(object.checkoutTemplateId)
                ? String(object.checkoutTemplateId)
                : "",
            checkoutTemplateLanguage: isSet(object.checkoutTemplateLanguage)
                ? String(object.checkoutTemplateLanguage)
                : "",
            fbBusinessChannelId: isSet(object.fbBusinessChannelId)
                ? String(object.fbBusinessChannelId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignName !== undefined &&
            (obj.campaignName = message.campaignName);
        message.messageParameters !== undefined &&
            (obj.messageParameters = message.messageParameters
                ? MultiProductMessageParameters.toJSON(message.messageParameters)
                : undefined);
        message.checkoutTemplateId !== undefined &&
            (obj.checkoutTemplateId = message.checkoutTemplateId);
        message.checkoutTemplateLanguage !== undefined &&
            (obj.checkoutTemplateLanguage = message.checkoutTemplateLanguage);
        message.fbBusinessChannelId !== undefined &&
            (obj.fbBusinessChannelId = message.fbBusinessChannelId);
        return obj;
    },
};
function createBaseMultiProductMessageParameters() {
    return { header: "", body: "", footer: "", productNames: [], catalogId: "" };
}
export const MultiProductMessageParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== "") {
            writer.uint32(10).string(message.header);
        }
        if (message.body !== "") {
            writer.uint32(18).string(message.body);
        }
        if (message.footer !== "") {
            writer.uint32(26).string(message.footer);
        }
        for (const v of message.productNames) {
            writer.uint32(34).string(v);
        }
        if (message.catalogId !== "") {
            writer.uint32(42).string(message.catalogId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMultiProductMessageParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = reader.string();
                    break;
                case 2:
                    message.body = reader.string();
                    break;
                case 3:
                    message.footer = reader.string();
                    break;
                case 4:
                    message.productNames.push(reader.string());
                    break;
                case 5:
                    message.catalogId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header) ? String(object.header) : "",
            body: isSet(object.body) ? String(object.body) : "",
            footer: isSet(object.footer) ? String(object.footer) : "",
            productNames: Array.isArray(object === null || object === void 0 ? void 0 : object.productNames)
                ? object.productNames.map((e) => String(e))
                : [],
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined && (obj.header = message.header);
        message.body !== undefined && (obj.body = message.body);
        message.footer !== undefined && (obj.footer = message.footer);
        if (message.productNames) {
            obj.productNames = message.productNames.map((e) => e);
        }
        else {
            obj.productNames = [];
        }
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        return obj;
    },
};
function createBaseAutoReplyV1Parameters() {
    return { flowDocumentId: "" };
}
export const AutoReplyV1Parameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocumentId !== "") {
            writer.uint32(10).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAutoReplyV1Parameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseShopifyRecommenderParameters() {
    return {
        shopifyBusinessChannelId: "",
        waBusinessChannelId: "",
        etaParameters: undefined,
    };
}
export const ShopifyRecommenderParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopifyBusinessChannelId !== "") {
            writer.uint32(10).string(message.shopifyBusinessChannelId);
        }
        if (message.waBusinessChannelId !== "") {
            writer.uint32(18).string(message.waBusinessChannelId);
        }
        if (message.etaParameters !== undefined) {
            ShopifyRecommenderParameters_ShopifyRecommenderETAParameters.encode(message.etaParameters, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRecommenderParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopifyBusinessChannelId = reader.string();
                    break;
                case 2:
                    message.waBusinessChannelId = reader.string();
                    break;
                case 3:
                    message.etaParameters =
                        ShopifyRecommenderParameters_ShopifyRecommenderETAParameters.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopifyBusinessChannelId: isSet(object.shopifyBusinessChannelId)
                ? String(object.shopifyBusinessChannelId)
                : "",
            waBusinessChannelId: isSet(object.waBusinessChannelId)
                ? String(object.waBusinessChannelId)
                : "",
            etaParameters: isSet(object.etaParameters)
                ? ShopifyRecommenderParameters_ShopifyRecommenderETAParameters.fromJSON(object.etaParameters)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopifyBusinessChannelId !== undefined &&
            (obj.shopifyBusinessChannelId = message.shopifyBusinessChannelId);
        message.waBusinessChannelId !== undefined &&
            (obj.waBusinessChannelId = message.waBusinessChannelId);
        message.etaParameters !== undefined &&
            (obj.etaParameters = message.etaParameters
                ? ShopifyRecommenderParameters_ShopifyRecommenderETAParameters.toJSON(message.etaParameters)
                : undefined);
        return obj;
    },
};
function createBaseShopifyRecommenderParameters_ShopifyRecommenderETAParameters() {
    return {
        language: "",
        headerImageEnabled: false,
        linkTrackingEnabled: false,
        withCheckout: false,
    };
}
export const ShopifyRecommenderParameters_ShopifyRecommenderETAParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.language !== "") {
            writer.uint32(10).string(message.language);
        }
        if (message.headerImageEnabled === true) {
            writer.uint32(16).bool(message.headerImageEnabled);
        }
        if (message.linkTrackingEnabled === true) {
            writer.uint32(24).bool(message.linkTrackingEnabled);
        }
        if (message.withCheckout === true) {
            writer.uint32(32).bool(message.withCheckout);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRecommenderParameters_ShopifyRecommenderETAParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.language = reader.string();
                    break;
                case 2:
                    message.headerImageEnabled = reader.bool();
                    break;
                case 3:
                    message.linkTrackingEnabled = reader.bool();
                    break;
                case 4:
                    message.withCheckout = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            language: isSet(object.language) ? String(object.language) : "",
            headerImageEnabled: isSet(object.headerImageEnabled)
                ? Boolean(object.headerImageEnabled)
                : false,
            linkTrackingEnabled: isSet(object.linkTrackingEnabled)
                ? Boolean(object.linkTrackingEnabled)
                : false,
            withCheckout: isSet(object.withCheckout)
                ? Boolean(object.withCheckout)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.language !== undefined && (obj.language = message.language);
        message.headerImageEnabled !== undefined &&
            (obj.headerImageEnabled = message.headerImageEnabled);
        message.linkTrackingEnabled !== undefined &&
            (obj.linkTrackingEnabled = message.linkTrackingEnabled);
        message.withCheckout !== undefined &&
            (obj.withCheckout = message.withCheckout);
        return obj;
    },
};
function createBaseShopifyDiscount() {
    return { discountCode: "", discountType: 0, discountValue: 0 };
}
export const ShopifyDiscount = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.discountCode !== "") {
            writer.uint32(10).string(message.discountCode);
        }
        if (message.discountType !== 0) {
            writer.uint32(16).int32(message.discountType);
        }
        if (message.discountValue !== 0) {
            writer.uint32(29).float(message.discountValue);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyDiscount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.discountCode = reader.string();
                    break;
                case 2:
                    message.discountType = reader.int32();
                    break;
                case 3:
                    message.discountValue = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            discountCode: isSet(object.discountCode)
                ? String(object.discountCode)
                : "",
            discountType: isSet(object.discountType)
                ? shopifyDiscount_DiscountTypeFromJSON(object.discountType)
                : 0,
            discountValue: isSet(object.discountValue)
                ? Number(object.discountValue)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.discountCode !== undefined &&
            (obj.discountCode = message.discountCode);
        message.discountType !== undefined &&
            (obj.discountType = shopifyDiscount_DiscountTypeToJSON(message.discountType));
        message.discountValue !== undefined &&
            (obj.discountValue = message.discountValue);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters() {
    return {
        catalogId: "",
        multiProductMessageParams: undefined,
        summaryMessageParams: undefined,
        paymentTemplateParams: undefined,
        semanticVersion: "",
        altoPaymentParams: undefined,
    };
}
export const BCAMerchantCatalogParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.catalogId !== "") {
            writer.uint32(10).string(message.catalogId);
        }
        if (message.multiProductMessageParams !== undefined) {
            BCAMerchantCatalogParameters_MultiProductMessageParams.encode(message.multiProductMessageParams, writer.uint32(18).fork()).ldelim();
        }
        if (message.summaryMessageParams !== undefined) {
            BCAMerchantCatalogParameters_SummaryMessageParams.encode(message.summaryMessageParams, writer.uint32(26).fork()).ldelim();
        }
        if (message.paymentTemplateParams !== undefined) {
            BCAMerchantCatalogParameters_PaymentTemplateParams.encode(message.paymentTemplateParams, writer.uint32(34).fork()).ldelim();
        }
        if (message.semanticVersion !== "") {
            writer.uint32(42).string(message.semanticVersion);
        }
        if (message.altoPaymentParams !== undefined) {
            BCAMerchantCatalogParameters_ALTOPaymentParams.encode(message.altoPaymentParams, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.catalogId = reader.string();
                    break;
                case 2:
                    message.multiProductMessageParams =
                        BCAMerchantCatalogParameters_MultiProductMessageParams.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.summaryMessageParams =
                        BCAMerchantCatalogParameters_SummaryMessageParams.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.paymentTemplateParams =
                        BCAMerchantCatalogParameters_PaymentTemplateParams.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.semanticVersion = reader.string();
                    break;
                case 6:
                    message.altoPaymentParams =
                        BCAMerchantCatalogParameters_ALTOPaymentParams.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
            multiProductMessageParams: isSet(object.multiProductMessageParams)
                ? BCAMerchantCatalogParameters_MultiProductMessageParams.fromJSON(object.multiProductMessageParams)
                : undefined,
            summaryMessageParams: isSet(object.summaryMessageParams)
                ? BCAMerchantCatalogParameters_SummaryMessageParams.fromJSON(object.summaryMessageParams)
                : undefined,
            paymentTemplateParams: isSet(object.paymentTemplateParams)
                ? BCAMerchantCatalogParameters_PaymentTemplateParams.fromJSON(object.paymentTemplateParams)
                : undefined,
            semanticVersion: isSet(object.semanticVersion)
                ? String(object.semanticVersion)
                : "",
            altoPaymentParams: isSet(object.altoPaymentParams)
                ? BCAMerchantCatalogParameters_ALTOPaymentParams.fromJSON(object.altoPaymentParams)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.catalogId !== undefined && (obj.catalogId = message.catalogId);
        message.multiProductMessageParams !== undefined &&
            (obj.multiProductMessageParams = message.multiProductMessageParams
                ? BCAMerchantCatalogParameters_MultiProductMessageParams.toJSON(message.multiProductMessageParams)
                : undefined);
        message.summaryMessageParams !== undefined &&
            (obj.summaryMessageParams = message.summaryMessageParams
                ? BCAMerchantCatalogParameters_SummaryMessageParams.toJSON(message.summaryMessageParams)
                : undefined);
        message.paymentTemplateParams !== undefined &&
            (obj.paymentTemplateParams = message.paymentTemplateParams
                ? BCAMerchantCatalogParameters_PaymentTemplateParams.toJSON(message.paymentTemplateParams)
                : undefined);
        message.semanticVersion !== undefined &&
            (obj.semanticVersion = message.semanticVersion);
        message.altoPaymentParams !== undefined &&
            (obj.altoPaymentParams = message.altoPaymentParams
                ? BCAMerchantCatalogParameters_ALTOPaymentParams.toJSON(message.altoPaymentParams)
                : undefined);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_MultiProductMessageParams() {
    return { header: "", body: "", footer: "" };
}
export const BCAMerchantCatalogParameters_MultiProductMessageParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.header !== "") {
            writer.uint32(10).string(message.header);
        }
        if (message.body !== "") {
            writer.uint32(18).string(message.body);
        }
        if (message.footer !== "") {
            writer.uint32(26).string(message.footer);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_MultiProductMessageParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.header = reader.string();
                    break;
                case 2:
                    message.body = reader.string();
                    break;
                case 3:
                    message.footer = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            header: isSet(object.header) ? String(object.header) : "",
            body: isSet(object.body) ? String(object.body) : "",
            footer: isSet(object.footer) ? String(object.footer) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.header !== undefined && (obj.header = message.header);
        message.body !== undefined && (obj.body = message.body);
        message.footer !== undefined && (obj.footer = message.footer);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_Button() {
    return { type: undefined };
}
export const BCAMerchantCatalogParameters_Button = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.type) === null || _a === void 0 ? void 0 : _a.$case) === "checkoutButton") {
            BCAMerchantCatalogParameters_CheckoutButton.encode(message.type.checkoutButton, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.type) === null || _b === void 0 ? void 0 : _b.$case) === "clearCartButton") {
            BCAMerchantCatalogParameters_ClearCartButton.encode(message.type.clearCartButton, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_Button();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = {
                        $case: "checkoutButton",
                        checkoutButton: BCAMerchantCatalogParameters_CheckoutButton.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.type = {
                        $case: "clearCartButton",
                        clearCartButton: BCAMerchantCatalogParameters_ClearCartButton.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.checkoutButton)
                ? {
                    $case: "checkoutButton",
                    checkoutButton: BCAMerchantCatalogParameters_CheckoutButton.fromJSON(object.checkoutButton),
                }
                : isSet(object.clearCartButton)
                    ? {
                        $case: "clearCartButton",
                        clearCartButton: BCAMerchantCatalogParameters_ClearCartButton.fromJSON(object.clearCartButton),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.type) === null || _a === void 0 ? void 0 : _a.$case) === "checkoutButton" &&
            (obj.checkoutButton = ((_b = message.type) === null || _b === void 0 ? void 0 : _b.checkoutButton)
                ? BCAMerchantCatalogParameters_CheckoutButton.toJSON((_c = message.type) === null || _c === void 0 ? void 0 : _c.checkoutButton)
                : undefined);
        ((_d = message.type) === null || _d === void 0 ? void 0 : _d.$case) === "clearCartButton" &&
            (obj.clearCartButton = ((_e = message.type) === null || _e === void 0 ? void 0 : _e.clearCartButton)
                ? BCAMerchantCatalogParameters_ClearCartButton.toJSON((_f = message.type) === null || _f === void 0 ? void 0 : _f.clearCartButton)
                : undefined);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_CheckoutButton() {
    return { text: "" };
}
export const BCAMerchantCatalogParameters_CheckoutButton = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_CheckoutButton();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_ClearCartButton() {
    return { text: "" };
}
export const BCAMerchantCatalogParameters_ClearCartButton = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_ClearCartButton();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_SummaryMessageParams() {
    return { body: "", buttons: [] };
}
export const BCAMerchantCatalogParameters_SummaryMessageParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.body !== "") {
            writer.uint32(10).string(message.body);
        }
        for (const v of message.buttons) {
            BCAMerchantCatalogParameters_Button.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_SummaryMessageParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.body = reader.string();
                    break;
                case 2:
                    message.buttons.push(BCAMerchantCatalogParameters_Button.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            body: isSet(object.body) ? String(object.body) : "",
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => BCAMerchantCatalogParameters_Button.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.body !== undefined && (obj.body = message.body);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? BCAMerchantCatalogParameters_Button.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_PaymentTemplateParams() {
    return { lang: "" };
}
export const BCAMerchantCatalogParameters_PaymentTemplateParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.lang !== "") {
            writer.uint32(10).string(message.lang);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_PaymentTemplateParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lang = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            lang: isSet(object.lang) ? String(object.lang) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.lang !== undefined && (obj.lang = message.lang);
        return obj;
    },
};
function createBaseBCAMerchantCatalogParameters_ALTOPaymentParams() {
    return { clientId: "", channelType: 0 };
}
export const BCAMerchantCatalogParameters_ALTOPaymentParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientId !== "") {
            writer.uint32(10).string(message.clientId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogParameters_ALTOPaymentParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientId: isSet(object.clientId) ? String(object.clientId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientId !== undefined && (obj.clientId = message.clientId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        return obj;
    },
};
function createBaseSalesBotParameters() {
    return {
        waBusinessChannelId: "",
        language: "",
        overrideContext: "",
        fallbackMessage: "",
    };
}
export const SalesBotParameters = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waBusinessChannelId !== "") {
            writer.uint32(10).string(message.waBusinessChannelId);
        }
        if (message.language !== "") {
            writer.uint32(18).string(message.language);
        }
        if (message.overrideContext !== "") {
            writer.uint32(26).string(message.overrideContext);
        }
        if (message.fallbackMessage !== "") {
            writer.uint32(34).string(message.fallbackMessage);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSalesBotParameters();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waBusinessChannelId = reader.string();
                    break;
                case 2:
                    message.language = reader.string();
                    break;
                case 3:
                    message.overrideContext = reader.string();
                    break;
                case 4:
                    message.fallbackMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waBusinessChannelId: isSet(object.waBusinessChannelId)
                ? String(object.waBusinessChannelId)
                : "",
            language: isSet(object.language) ? String(object.language) : "",
            overrideContext: isSet(object.overrideContext)
                ? String(object.overrideContext)
                : "",
            fallbackMessage: isSet(object.fallbackMessage)
                ? String(object.fallbackMessage)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.waBusinessChannelId !== undefined &&
            (obj.waBusinessChannelId = message.waBusinessChannelId);
        message.language !== undefined && (obj.language = message.language);
        message.overrideContext !== undefined &&
            (obj.overrideContext = message.overrideContext);
        message.fallbackMessage !== undefined &&
            (obj.fallbackMessage = message.fallbackMessage);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
