import React, { useCallback, useContext, useEffect, useMemo, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from 'use-file-picker';
import { Box, Button, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { BusinessProfilePictureProps } from './types';
import { applyHook } from '../../../../utils';
import {
  selectBusinessId,
  selectWhatsappBusinessChannel,
  selectWhatsappProfilePicture,
  useBusinessChannelsData,
  useDebounce,
  useMediaUploadMutation,
  useMeData,
  usePrevious,
} from '../../../../hooks';
import { NotificationContext } from '../../../../contexts';
import { IMAGE_MAX_FILE_SIZE_MB } from '../../../../utils/messageTemplates';
import { selectBusinessProfilePicture, setBusinessProfilePicture } from '../../../../features/welcome';

const useBusinessProfilePicture = (_props: Partial<BusinessProfilePictureProps>): BusinessProfilePictureProps => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessProfilePicture' });
  const dispatch = useDispatch();

  const businessProfilePicture = useSelector(selectBusinessProfilePicture);

  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [openFileSelector, { filesContent, plainFiles, loading: isFileSelectorLoading, errors, clear }] = useFilePicker(
    {
      accept: ['.png', '.jpg', '.jpeg'],
      multiple: false,
      maxFileSize: IMAGE_MAX_FILE_SIZE_MB,
    },
  );
  const previousIsFileSelectorLoading = usePrevious(isFileSelectorLoading);
  const { isLoading: isUploading, mutate: uploadFile } = useMediaUploadMutation();
  const { isFetching: isFetchingProfilePicture, isLoading: isLoadingProfilePicture } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappProfilePicture,
  });
  const { data: whatsappBusinessChannel } = useBusinessChannelsData({
    businessId: businessId as string,
    enabled: !!businessId,
    select: selectWhatsappBusinessChannel,
  });

  const isLoading = useMemo(() => {
    return (
      isFetchingProfilePicture ||
      isLoadingProfilePicture ||
      isUploading ||
      // isMutatingProfilePicture ||
      isFileSelectorLoading
    );
  }, [isFetchingProfilePicture, isLoadingProfilePicture, isUploading, isFileSelectorLoading]);

  const [isLoadingDebounced, setIsLoadingDebounced] = useState(false);
  useDebounce(
    () => {
      setIsLoadingDebounced(isLoading);
    },
    250,
    [isLoading],
  );

  useEffect(() => {
    if (isFileSelectorLoading || !previousIsFileSelectorLoading) return;

    if (errors.length > 0) {
      const { fileSizeToolarge: fileSizeTooLarge } = errors[0];
      if (fileSizeTooLarge) {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: t('errorFileSize', { size: IMAGE_MAX_FILE_SIZE_MB }),
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      } else {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: t('errorFile'),
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
      }
      return;
    }

    const imageRegExp = /\.(jpg|jpeg|png)$/i;
    if (!imageRegExp.test(plainFiles[0].name)) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('errorFileType'),
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }

    if (!plainFiles.length || !filesContent.length) return;
    if (!businessId) {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: t('errorBeforeUploadFile'),
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
      return;
    }

    uploadFile(
      {
        businessId,
        file: plainFiles[0],
      },
      {
        onSuccess: (data) => {
          if (data && data.uri) {
            dispatch(setBusinessProfilePicture(data.uri));
          }
        },
        onError: () =>
          notificationProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
              message: t('errorUploadFile'),
              icon: '',
              severity: NotificationSeverity.ERROR,
            },
          }),
      },
    );
  }, [
    businessId,
    clear,
    errors,
    filesContent.length,
    isFileSelectorLoading,
    notificationProvider,
    previousIsFileSelectorLoading,
    plainFiles,
    uploadFile,
    whatsappBusinessChannel,
    dispatch,
    t,
  ]);

  const handleUpload = useCallback(() => {
    openFileSelector();
  }, [openFileSelector]);

  return {
    businessProfilePicture,
    isUploading,
    onUpload: handleUpload,
  };
};

export const BusinessProfilePicturePresentation: VFC<BusinessProfilePictureProps> = ({
  businessProfilePicture,
  isUploading,
  onUpload,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessProfilePicture' });
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'flex-start' }}>
      <Box>
        <Label>
          {t('title')}
          <span
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            {` (${t('optional')})`}
          </span>
        </Label>
        <Label color="textSecondary" variant="body1">
          {t('subtitle')}
        </Label>
      </Box>
      <Button disabled={isUploading} variant={businessProfilePicture ? 'outlined' : 'contained'} onClick={onUpload}>
        {isUploading ? t('uploading') : businessProfilePicture ? t('reupload') : t('upload')}
      </Button>
    </Box>
  );
};

export const BusinessProfilePicture = applyHook(BusinessProfilePicturePresentation, useBusinessProfilePicture);
