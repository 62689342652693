import { DAY_IN_SECONDS, EngagedCustomerQueryView } from '@hooks';

export const selectQueryText = ({ queryView }: { queryView: EngagedCustomerQueryView }) => {
  const { timeframe, frequency } = queryView;
  const { lookbackWindow } = timeframe;
  const { operator, value } = frequency;
  let gteValue = value;
  if (operator === 'COMPARISON_OPERATOR_GT') {
    gteValue += 1;
  }
  // Includes anyone who clicked on a button or link 1 time over the past week
  let text = `Includes anyone who clicked on a button or link at least ${gteValue} time`;
  if (gteValue !== 1) {
    text += 's';
  }
  const numDays = Math.floor(lookbackWindow / DAY_IN_SECONDS);
  text += ` over the past ${numDays === 1 ? '' : `${numDays} `}day`;
  if (numDays !== 1) {
    text += 's';
  }
  return text;
};
