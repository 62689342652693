export const CONNECTLY_URL_PROTOCOL = 'cnctl:';
export const EXTERNAL_URL_PROTOCOL = 'https:';
export const CloseRouteId = '/close';
export function generateCloseRoute() {
    return {
        id: CloseRouteId,
        params: {},
    };
}
export const BackRouteId = '/back';
export function generateBackRoute() {
    return {
        id: BackRouteId,
        params: {},
    };
}
export function generateUrlRoute(url) {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol === CONNECTLY_URL_PROTOCOL) {
        if (parsedUrl.pathname === '' || parsedUrl.pathname === '/') {
            throw new Error('Unsupported url');
        }
        const params = {};
        const pathname = parsedUrl.pathname.replace(/^\/*/, '/');
        parsedUrl.searchParams.forEach((value, key) => {
            params[key] = value;
        });
        return {
            id: pathname,
            params,
        };
    }
    if (parsedUrl.protocol === EXTERNAL_URL_PROTOCOL) {
        return {
            id: url,
            params: {
                isExternal: 'true',
            },
        };
    }
    throw new Error('Unsupported url');
}
