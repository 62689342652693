import React, { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNewPhoneNumberAtom } from '@atoms/home';
import { Box, FormControlLabel, Label, Radio, RadioGroup } from '@connectlyai-tenets/ui-styled-web';

const usePhoneNumberSelector = () => {
  const [useNewPhoneNumber, setUseNewPhoneNumber] = useAtom(useNewPhoneNumberAtom);
  const handleChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setUseNewPhoneNumber(value === 'true');
    },
    [setUseNewPhoneNumber],
  );

  return {
    onChange: handleChange,
    useNewPhoneNumber,
  };
};

export const PhoneNumberSelector = () => {
  const { onChange, useNewPhoneNumber } = usePhoneNumberSelector();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.phoneNumberSelector' });

  return (
    <Box>
      <Label>{t('title1')}</Label>
      <RadioGroup value={useNewPhoneNumber ? 'true' : 'false'} onChange={onChange}>
        <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
        <FormControlLabel value="false" control={<Radio />} label={t('no')} />
      </RadioGroup>
    </Box>
  );
};
