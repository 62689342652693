import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { definitions } from '@connectlyai-tenets/sdk/src/schemes/billingV1.generated';
import { api } from '../../api';
import { PricingPlansResponse } from './types';

export const usePricingPlansData = <TData = PricingPlansResponse>(
  options: Omit<
    UseQueryOptions<PricingPlansResponse, unknown, TData, readonly ['pricingPlans']>,
    'initialData' | 'queryFn' | 'queryKey'
  > = {},
) => useQuery(['pricingPlans'], () => api.billingV1('/v1/pricing_plans', 'get'), options);

export type PricingPlans = definitions['billingPricingPlan'][];
export type Prices = definitions['billingConversationPrice'][];
