import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string, international = false): string => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const phoneNumberString = international
      ? parsedPhoneNumber.formatInternational()
      : parsedPhoneNumber.formatNational();
    return phoneNumberString || phoneNumber;
  } catch (e) {
    return phoneNumber;
  }
};
