import React, { useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';
import { cohortsGeneratingAtom } from '@atoms/audience';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useStandardNotifications, useFeatureFlag } from '@hooks';
import {
  IconButton,
  Tooltip,
  Box,
  Label,
  GroupIcon,
  useTheme,
  AddCircleIcon,
  RefreshIcon,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { formatDate } from '../../../../utils';
import { PillDisplay } from '../../../../presentation/common/PillDisplay';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { useMutationCreateCohort } from '../../hooks';
import { CohortSizeProps } from './types';

const useCohortSize = ({ businessId, segmentId, cohort }: CohortSizeProps) => {
  const { ffEnableGenerateCohort } = useFeatureFlag(['ffEnableGenerateCohort']);
  const [cohortsGenerating, setCohortsGenerating] = useAtom(cohortsGeneratingAtom);
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });
  const { notifySuccess, notifyError } = useStandardNotifications({
    successMessage: 'Cohort Is Generating',
    errorMessage: 'Failed to create new Cohort',
  });
  const queryClient = useQueryClient();
  const { mutate, isLoading: createIsLoading } = useMutationCreateCohort({
    onError: () => {
      notifyError();
    },
    onSuccess: () => {
      notifySuccess();
      queryClient.invalidateQueries(['audienceCohort', cohort?.segmentId]);
    },
  });
  const createCohort = useCallback(() => {
    if (businessId && segmentId) {
      mutate({ businessId, segmentId });
      setCohortsGenerating([...cohortsGenerating, segmentId]);
    }
  }, [businessId, cohortsGenerating, mutate, segmentId, setCohortsGenerating]);
  const cohortIsGenerating = cohortsGenerating.includes(segmentId || '');
  const mostEngaged = useMemo(() => {
    const updatedAt = cohort?.createdAt;
    let updatedAtLabel = '';
    if (updatedAt) {
      updatedAtLabel = `${t('updatedAt')}: ${formatDate(updatedAt)}`;
    }
    let sizeLabel = '';
    if (cohortIsGenerating) {
      sizeLabel = 'Loading Cohort...';
    } else if (!cohort) {
      sizeLabel = 'Cohort Not Created';
    } else {
      const size = cohort?.size === undefined ? 100 : cohort.size;
      const text = size === 1 ? 'customer' : 'customers';
      sizeLabel = `${size} ${text}`;
    }
    return {
      updatedAtLabel,
      sizeLabel,
    };
  }, [cohort, cohortIsGenerating, t]);
  return {
    cohortExists: !!cohort,
    cohortIsGenerating,
    createCohort,
    createIsLoading,
    ffEnableGenerateCohort,
    mostEngaged,
    theme,
  };
};

export const CohortSize = ({ businessId, segmentId, cohort, isLoading }: CohortSizeProps) => {
  const {
    cohortExists,
    cohortIsGenerating,
    createCohort,
    createIsLoading,
    ffEnableGenerateCohort,
    mostEngaged,
    theme,
  } = useCohortSize({
    businessId,
    cohort,
    segmentId,
  });
  return (
    <WithSkeleton wrap={!!isLoading}>
      <Tooltip title={mostEngaged.updatedAtLabel} placement="top">
        <Box sx={{ width: 'fit-content', mt: 2, userSelect: 'none' }}>
          <PillDisplay>
            <GroupIcon sx={{ color: theme.palette.grey[700], mr: SIZE_SPACING_INTER_COMPONENT }} />
            <Label
              variant="body1"
              sx={{
                color: theme.palette.grey[700],
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {mostEngaged.sizeLabel}
            </Label>
            {!cohortIsGenerating && (!cohortExists || ffEnableGenerateCohort) && (
              <IconButton size="small" onClick={createCohort} disabled={createIsLoading} sx={{ ml: 0.25 }}>
                {cohortExists ? (
                  <RefreshIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />
                ) : (
                  <AddCircleIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />
                )}
              </IconButton>
            )}
          </PillDisplay>
        </Box>
      </Tooltip>
    </WithSkeleton>
  );
};
