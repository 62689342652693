import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useAtomValue } from 'jotai';
import { selectedNodeIdAtom } from '@atoms/flow';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import {
  Box,
  Button,
  CloseIcon,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  SelectChangeEvent,
  Collapse,
} from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderItem } from '@components/TemplateBuilderItem';
import { TemplateBuilderTitle } from '@components/TemplateBuilderTitle';
import { BODY_MAX_LENGTH } from '@components/TemplateBuilderBody/constants';
import { CodeMirrorTextField } from '@components/CodeMirrorTextField';
import { AddVariable } from '@components/AddVariable';
import { FlowContext } from '../../../../contexts';
import { FlowVariableDialog, FlowVariableDialogRef } from '../../../FlowVariableDialog';
import { CustomerRepliesNodeUIState } from './types';
import { useNodeUIState } from '../../hooks/useNodeUIState';

const useCustomerRepliesNodeEditor = () => {
  const { resetSelection } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);
  const { nodeUIState, setNodeUIStateKey } = useNodeUIState<CustomerRepliesNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT',
  });
  const selectedNodeId = useAtomValue(selectedNodeIdAtom);

  const handleChangeFormatType = useCallback(
    (e: SelectChangeEvent<CustomerRepliesNodeUIState['format']>) => {
      const value = e?.target?.value as CustomerRepliesNodeUIState['format'];
      if (!value) return;
      setNodeUIStateKey('format', value);
    },
    [setNodeUIStateKey],
  );

  const handleSave = useCallback(() => {
    resetSelection();
  }, [resetSelection]);

  const localVarDialogRef = useRef<FlowVariableDialogRef>(null);
  const localVarCodeMirrorRef = useRef<ReactCodeMirrorRef>({});
  const flowVariableDialogAnchorRef = useRef<HTMLDivElement>(null);

  const handleLocalVarEditorChange = useCallback(
    (value: string | null) => {
      value = value ? value.trim() : null;
      if (value) {
        value = value.replace(/\s/g, '_');
      }
      setNodeUIStateKey('variable', value);
    },
    [setNodeUIStateKey],
  );

  const handleLocalVarOpenDialog = useCallback(() => {
    localVarDialogRef.current?.handleOpenDialog();
  }, [localVarDialogRef]);

  const handleSaveAsLocalVarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (!checked) {
        handleLocalVarEditorChange(null);
      }
      setNodeUIStateKey('saveAsVariable', checked);
    },
    [handleLocalVarEditorChange, setNodeUIStateKey],
  );

  const handleChangeAdvancedTextSettings = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setNodeUIStateKey('advancedTextSettings', checked);
    },
    [setNodeUIStateKey],
  );

  const handleChangeMinCharacters = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNodeUIStateKey('minCharacters', value);
    },
    [setNodeUIStateKey],
  );

  const handleChangeMaxCharacters = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNodeUIStateKey('maxCharacters', value);
    },
    [setNodeUIStateKey],
  );

  const handleChangeRegex = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNodeUIStateKey('regex', value);
    },
    [setNodeUIStateKey],
  );

  const handleChangeEnableErrorMessage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setNodeUIStateKey('enableErrorMessage', checked);
    },
    [setNodeUIStateKey],
  );

  const handleChangeErrorMessage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNodeUIStateKey('errorMessage', value);
    },
    [setNodeUIStateKey],
  );

  return {
    onSave: handleSave,
    flowVariableDialogAnchorRef,
    handleChangeAdvancedTextSettings,
    handleChangeEnableErrorMessage,
    handleChangeErrorMessage,
    handleChangeFormatType,
    handleChangeMaxCharacters,
    handleChangeMinCharacters,
    handleChangeRegex,
    handleLocalVarEditorChange,
    handleLocalVarOpenDialog,
    handleSaveAsLocalVarChange,
    localVarCodeMirrorRef,
    localVarDialogRef,
    nodeUIState,
    selectedNodeId,
  };
};

export const CustomerRepliesNodeEditor = () => {
  const {
    onSave,
    flowVariableDialogAnchorRef,
    handleChangeAdvancedTextSettings,
    handleChangeEnableErrorMessage,
    handleChangeErrorMessage,
    handleChangeMaxCharacters,
    handleChangeMinCharacters,
    handleChangeRegex,
    handleLocalVarEditorChange,
    handleLocalVarOpenDialog,
    handleSaveAsLocalVarChange,
    localVarCodeMirrorRef,
    localVarDialogRef,
    nodeUIState,
    selectedNodeId,
  } = useCustomerRepliesNodeEditor();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          gap: 1,
          flex: '0 0 auto',
          px: isLargeScreen ? 3 : 2,
          mt: isLargeScreen ? 3.5 : 2,
          mb: isLargeScreen ? 2 : 1,
        }}
      >
        <Label variant="h6">If customer replies</Label>
        <IconButton aria-label="close" onClick={onSave}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: isLargeScreen ? 3 : 2,
          flex: '1 1 auto',
          gap: isLargeScreen ? 3 : 2,
          overflowY: 'scroll',
        }}
      >
        <TemplateBuilderItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* Removed until BE implements format selection
          <TemplateBuilderTitle hint="The type of message that flow path will be triggered.">
            Format
          </TemplateBuilderTitle>
          <Select
            fullWidth
            value={nodeUIState?.format || 'Text only'}
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            onChange={handleChangeFormatType}
            sx={{
              borderRadius: '10px',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              '& .MuiSelect-select': { py: '11px', pl: '12px' },
              height: theme.spacing(6),
            }}
          >
            {['All', 'Text only', 'Image only'].map((t) => (
              <MenuItem key={t} value={t.toString()}>
                {t}
              </MenuItem>
            ))}
          </Select> */}

          <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column' }} ref={flowVariableDialogAnchorRef}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ py: 0, pr: 0.5 }}
                  color="primary"
                  onChange={handleSaveAsLocalVarChange}
                  checked={nodeUIState?.saveAsVariable}
                />
              }
              label={
                <Label
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    userSelect: 'none',
                  }}
                  variant="body1"
                >
                  Save as a local variable
                </Label>
              }
            />
            <CodeMirrorTextField
              value={nodeUIState?.variable || ''}
              editable={false}
              handleVariableClick={localVarDialogRef?.current?.handleVariableClick}
              setValue={handleLocalVarEditorChange}
              ref={localVarCodeMirrorRef}
              containerStyle={{
                display: nodeUIState?.saveAsVariable ? 'auto' : 'none',
                height: theme.spacing(5),
                border: '1px solid rgb(192, 192, 192)',
                borderRadius: '10px 10px 10px 10px',
                padding: `${theme.spacing(0.8125)} 
                ${theme.spacing(1.5)} 
                ${theme.spacing(0.8125)} 
                ${theme.spacing(0.5)}`,
              }}
              endAdornment={
                nodeUIState?.variable ? (
                  <Button
                    sx={{
                      fontSize: '12px',
                      textTransform: 'none',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translate(0, -50%)',
                    }}
                    onClick={() => {
                      handleLocalVarEditorChange(null);
                    }}
                  >
                    Remove
                  </Button>
                ) : (
                  <AddVariable
                    onClick={handleLocalVarOpenDialog}
                    text="Add"
                    sx={{
                      position: 'absolute',
                      left: '4px',
                      top: '50%',
                      transform: 'translate(0, -50%)',
                    }}
                  />
                )
              }
            />
            <FlowVariableDialog
              anchorEl={flowVariableDialogAnchorRef?.current}
              codeMirrorRef={localVarCodeMirrorRef}
              value={nodeUIState?.variable || ''}
              handleEditorChange={handleLocalVarEditorChange}
              declaredAt={selectedNodeId}
              isSessionVariable
              ref={localVarDialogRef}
              allowOnlyNew
            />
          </Box>

          <Collapse in={nodeUIState?.format === 'Text only'}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.grey[100],
                p: 2,
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
                <Label variant="body1">Advanced text settings</Label>
                <Switch
                  size="small"
                  checked={nodeUIState?.advancedTextSettings}
                  onChange={handleChangeAdvancedTextSettings}
                />
              </Box>
              <Collapse in={nodeUIState?.advancedTextSettings}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Min. characters"
                    value={nodeUIState?.minCharacters}
                    onChange={handleChangeMinCharacters}
                    sx={{
                      '& .MuiInputBase-root': { borderRadius: '10px', height: theme.spacing(6) },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Max. characters"
                    value={nodeUIState?.maxCharacters}
                    onChange={handleChangeMaxCharacters}
                    sx={{
                      '& .MuiInputBase-root': { borderRadius: '10px', height: theme.spacing(6) },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Regex pattern"
                    value={nodeUIState?.regex}
                    onChange={handleChangeRegex}
                    sx={{
                      '& .MuiInputBase-root': { borderRadius: '10px', height: theme.spacing(6) },
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </Collapse>
        </TemplateBuilderItem>
        <TemplateBuilderItem sx={{ pb: isLargeScreen ? 2 : 1 }}>
          <TemplateBuilderTitle
            hint="This message will be sent if incoming message does not fit desired format"
            optional={{ checked: nodeUIState?.enableErrorMessage, onChange: handleChangeEnableErrorMessage }}
            showOptional={false}
          >
            Validation error message
          </TemplateBuilderTitle>
          <Collapse in={nodeUIState?.enableErrorMessage}>
            <TextField
              id="message-template-body"
              autoComplete="off"
              autoFocus
              placeholder="Enter text"
              // error={Boolean(error)}
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              value={nodeUIState?.errorMessage}
              onChange={handleChangeErrorMessage}
              variant="outlined"
              inputProps={{
                maxLength: BODY_MAX_LENGTH * 2,
              }}
              InputProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 1.5,
                  borderRadius: '10px',
                },
                endAdornment: (
                  <InputAdornment
                    sx={{
                      height: 'auto',
                      alignSelf: 'flex-end',
                      mt: 1.5,
                      // color: body.length > BODY_MAX_LENGTH ? 'error.main' : 'text.secondary',
                      userSelect: 'none',
                    }}
                    disableTypography
                    disablePointerEvents
                    position="end"
                  >
                    <Label variant="body2">{`${nodeUIState?.errorMessage.length}/${BODY_MAX_LENGTH}`}</Label>
                  </InputAdornment>
                ),
              }}
              // onKeyDown={onKeyDown}
              sx={{ mt: 2 }}
            />
          </Collapse>
        </TemplateBuilderItem>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          p: 3,
          flex: '0 0 auto',
        }}
      >
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};
