export function formatPercent(num: number): string {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num)) {
    return '';
  }

  if (num < 0) {
    return `⭣${num}%`;
  }

  if (num > 0) {
    return `⭡+${num}%`;
  }

  return `${num}%`;
}
