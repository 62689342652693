var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _LoggerImpl_instances, _LoggerImpl_resolveMessageContext;
import { datadogLogs } from '@datadog/browser-logs';
import { v4 as uuidv4 } from 'uuid';
const FORMAT_DEFAULT = '%s: %s';
export default class LoggerImpl {
    constructor(logger) {
        _LoggerImpl_instances.add(this);
        if (logger !== undefined) {
            this.logger = logger;
        }
    }
    debug(message, messageContext) {
        const resolvedMessage = __classPrivateFieldGet(this, _LoggerImpl_instances, "m", _LoggerImpl_resolveMessageContext).call(this, messageContext);
        console.debug(FORMAT_DEFAULT, message, JSON.stringify(resolvedMessage));
    }
    info(message, messageContext) {
        const resolvedMessage = __classPrivateFieldGet(this, _LoggerImpl_instances, "m", _LoggerImpl_resolveMessageContext).call(this, messageContext);
        if (this.logger)
            this.logger.info(message, resolvedMessage);
        else
            console.info(FORMAT_DEFAULT, message, JSON.stringify(resolvedMessage));
    }
    warn(message, messageContext) {
        const resolvedMessage = __classPrivateFieldGet(this, _LoggerImpl_instances, "m", _LoggerImpl_resolveMessageContext).call(this, messageContext);
        if (this.logger)
            this.logger.warn(message, resolvedMessage);
        else
            console.warn(FORMAT_DEFAULT, message, JSON.stringify(resolvedMessage));
    }
    error(message, messageContext) {
        const resolvedMessage = __classPrivateFieldGet(this, _LoggerImpl_instances, "m", _LoggerImpl_resolveMessageContext).call(this, messageContext);
        if (this.logger)
            this.logger.error(message, resolvedMessage);
        else
            console.error(FORMAT_DEFAULT, message, JSON.stringify(resolvedMessage));
    }
    sugar(additionalContext) {
        const loggerName = additionalContext.logger ? additionalContext.logger : uuidv4();
        delete additionalContext.logger;
        const newLogger = datadogLogs.createLogger(loggerName);
        if (this.logger) {
            newLogger.setLevel(this.logger.getLevel());
            newLogger.setHandler(this.logger.getHandler());
            newLogger.setContext({ ...this.logger.getContext(), ...additionalContext });
        }
        else {
            newLogger.setContext(additionalContext);
        }
        return new LoggerImpl(newLogger);
    }
}
_LoggerImpl_instances = new WeakSet(), _LoggerImpl_resolveMessageContext = function _LoggerImpl_resolveMessageContext(messageContext) {
    if (!messageContext)
        return {};
    if (typeof messageContext === 'function')
        return messageContext();
    return messageContext;
};
