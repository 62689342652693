import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';
import { selectBusinessId, useMeData } from '../useMeData';
import { usePrevious } from '../usePrevious';
import { selectDocumentId } from '../../state/flow/selectors';
import { useLogger } from '../useLogger';
import { DtoUpdate, SubmitSendoutResult, ProgressStatus } from './types';

export const useSubmitSendoutResultData = (sendoutId: string) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { dtoUpdateAccessorProvider } = useContext(FlowDIContext) as FlowDIContextProps;
  const [result, setResult] = React.useState<SubmitSendoutResult | null>(null);
  const [submitProgress, setSubmitProgress] = React.useState<ProgressStatus | undefined>();

  const { logger } = useLogger();

  useEffect(() => {
    let sub: Subscription;
    if (sendoutId) {
      sub = dtoUpdateAccessorProvider()
        .subscriptionSerializedDto()
        .pipe(
          map((serialized) => {
            return JSON.parse(serialized) as DtoUpdate;
          }),
          filter((dtoUpdate) => {
            return dtoUpdate.objectId === sendoutId && dtoUpdate.submitSendoutResult !== undefined;
          }),
          map((dtoUpdate: DtoUpdate) => {
            if (dtoUpdate.submitSendoutResult === undefined) {
              return unreachable();
            }
            return dtoUpdate.submitSendoutResult;
          }),
        )
        .subscribe((submitSendoutResult) => {
          if (submitSendoutResult) {
            setResult(submitSendoutResult);
            setSubmitProgress(submitSendoutResult.progress);
            if (submitSendoutResult.status === 'MUTATION_STATUS_SUCCESS') {
              logger.info(`Received dtoUpdate campaign submit success for sendoutId: ${sendoutId}`);
            }
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [businessId, sendoutId, dtoUpdateAccessorProvider, logger]);

  const isSuccess = useMemo(() => result?.status === 'MUTATION_STATUS_SUCCESS', [result]);
  const reset = useMemo(() => () => setResult(null), []);

  // reset data when document id is changed
  const documentId = useSelector(selectDocumentId);
  const prevDocumentId = usePrevious(documentId);
  useEffect(() => {
    if (documentId !== prevDocumentId) {
      reset();
      setSubmitProgress({
        total: 100,
        completed: 0,
      });
    }
  }, [documentId, prevDocumentId, reset]);

  return { isSuccess, reset, submitProgress };
};
