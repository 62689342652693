import React, { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  ConnectlyCard,
  InputAdornment,
  Label,
  ShopifyIcon,
  TextField,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { validateShopifyDomain } from '@connectlyai-sdks/provider-shopify';
import {
  selectShopifyStoreName,
  selectShopifyStoreAccessToken,
  selectBusinessId,
  useMeData,
  useSettingsData,
} from '@hooks';
import { selectRole } from '@hooks/useMeData';
import { editShopifyStoreNameAtom, shopifyUrlAtom } from '@atoms/settings';
import {
  SIZE_INPUT_M,
  SIZE_INPUT_XXL,
  SIZE_MEDIA_XXS,
  SIZE_SPACING_INTER_COMPONENT,
  SIZE_SPACING_INTER_SECTION,
} from '../../../../ui-theme';
import { SHOPIFY_INTEGRATION_SCOPE } from '../../../../utils';

const LEARN_MORE_LINK_SHOPIFY = 'https://www.connectly.ai/helpcenter/how-do-i-put-my-connectly-chat-widget-on-shopify';

const useSettingsShopify = () => {
  const [editStoreName, setEditStoreName] = useAtom(editShopifyStoreNameAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: shopifyStoreName } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectShopifyStoreName,
  });
  const { data: accessToken } = useSettingsData({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectShopifyStoreAccessToken,
  });
  const { data: role } = useMeData({ select: selectRole });
  const isAgent = useMemo(() => !role || role === 'ROLE_AGENT', [role]);

  const [shopifyUrl, setShopifyUrl] = useAtom(shopifyUrlAtom);
  const shopifyDomain = validateShopifyDomain(shopifyUrl);
  const shopifyLoginUrl = new URL(`https://${shopifyDomain}/admin/oauth/authorize`);
  const shopifyLoginParams = new URLSearchParams({
    'grant_options[]': 'value',
    scope: SHOPIFY_INTEGRATION_SCOPE.join(','),
    client_id: process.env.REACT_APP_SHOPIFY_CLIENT_ID || '',
    redirect_uri: process.env.REACT_APP_SHOPIFY_REDIRECT_URI || '',
    state: JSON.stringify({
      business_id: businessId,
      client_id: process.env.REACT_APP_SHOPIFY_CLIENT_ID || '',
    }),
  });
  shopifyLoginUrl.search = shopifyLoginParams.toString();

  const isEditEnabled = useMemo(() => {
    return !isAgent && (!shopifyStoreName || editStoreName || !accessToken);
  }, [accessToken, editStoreName, isAgent, shopifyStoreName]);

  const handleUpdateStoreUrl = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShopifyUrl(e.target.value);
    },
    [setShopifyUrl],
  );
  const handleEditStoreName = useCallback(() => {
    setEditStoreName(true);
  }, [setEditStoreName]);
  const handleCancelEditStoreName = useCallback(() => {
    setEditStoreName(false);
  }, [setEditStoreName]);

  return {
    isEditEnabled,
    shopifyDomain,
    shopifyLoginUrl,
    shopifyStoreName,
    shopifyUrl,
    handleUpdateStoreUrl,
    handleEditStoreName,
    handleCancelEditStoreName,
  };
};

export const SettingsShopify = () => {
  const {
    isEditEnabled,
    shopifyDomain,
    shopifyLoginUrl,
    shopifyStoreName,
    shopifyUrl,
    handleUpdateStoreUrl,
    handleEditStoreName,
    handleCancelEditStoreName,
  } = useSettingsShopify();
  const theme = useTheme();
  return (
    <ConnectlyCard>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Label variant="h6">Shopify Integration</Label>
        </Box>
        <Button
          variant="text"
          color="primary"
          disableRipple
          target="_blank"
          href={LEARN_MORE_LINK_SHOPIFY}
          sx={{
            flexShrink: 0,
            p: 0,
            ml: SIZE_SPACING_INTER_COMPONENT,
            '&:hover': {
              background: 'transparent',
            },
          }}
        >
          Learn More
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          mt: SIZE_SPACING_INTER_SECTION,
          gap: SIZE_SPACING_INTER_SECTION,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Label variant="caption" color="textSecondary">
            How Connectly works with Shopify
          </Label>
          <Label variant="body2">
            See Shopify profiles next to your incoming messages and match anonymous conversations with existing Shopify
            customers. Let&apos;s connect your store to Connectly. We&apos;ll import your Shopify customers in
            Connectly, along with their order information. This way, when they contact you, you&apos;ll be able to see
            their Shopify information in their customer profile.
          </Label>
        </Box>
        {isEditEnabled && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
                gap: SIZE_SPACING_INTER_SECTION,
              }}
            >
              <TextField
                id="shopify-url"
                name="shopifyUrl"
                label="Shopify store name"
                InputProps={{
                  endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
                }}
                value={shopifyUrl}
                variant="standard"
                onChange={handleUpdateStoreUrl}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Tooltip title="Connect">
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={!shopifyDomain}
                    href={shopifyLoginUrl.href}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: theme.spacing(SIZE_INPUT_XXL),
                      height: theme.spacing(SIZE_INPUT_M),
                      marginTop: theme.spacing(SIZE_SPACING_INTER_COMPONENT),
                      borderRadius: theme.spacing(0.5),
                      padding: theme.spacing(1, 2.75),
                      color: theme.palette.primary.main,
                    }}
                  >
                    <ShopifyIcon
                      width={theme.spacing(SIZE_MEDIA_XXS)}
                      height={theme.spacing(SIZE_MEDIA_XXS)}
                      filter={!shopifyDomain ? 'grayscale(1)' : undefined}
                    />
                    <Label
                      sx={{
                        ml: SIZE_SPACING_INTER_COMPONENT,
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      Connect Shopify
                    </Label>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            {shopifyStoreName && (
              <Button
                variant="text"
                color="primary"
                disableRipple
                onClick={handleCancelEditStoreName}
                sx={{ textTransform: 'none' }}
              >
                Cancel Change
              </Button>
            )}
          </Box>
        )}
        {!isEditEnabled && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              gap: SIZE_SPACING_INTER_SECTION,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Label variant="caption" color="textSecondary">
                Shopify Store
              </Label>
              <Tooltip title="Your connected Shopify store">
                {/* same styles as in MessagingChannels. Create common component */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.divider}`,
                    pl: 2,
                    gap: 1,
                    width: theme.spacing(SIZE_INPUT_XXL),
                    height: theme.spacing(SIZE_INPUT_M),
                    borderRadius: 5,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ShopifyIcon width={theme.spacing(SIZE_MEDIA_XXS)} height={theme.spacing(SIZE_MEDIA_XXS)} />
                    <Label sx={{ ml: SIZE_SPACING_INTER_COMPONENT }}>{shopifyStoreName}</Label>
                  </Box>
                  <Button
                    variant="text"
                    color="primary"
                    disableRipple
                    onClick={handleEditStoreName}
                    sx={{ textTransform: 'none', mr: 2 }}
                  >
                    Change
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
    </ConnectlyCard>
  );
};
