export const REPHRASE_PROMPT = `You are tasked with improving the following marketing message for a WhatsApp campaign. 
Your goal is to make the message clear, engaging, and appealing to end users. 

Make sure to follow these guidelines when improving the message:

1. Use emojis sparingly to enhance the message. Add no more than 2-3 relevant emojis throughout the entire message. 
Do not place emojis after every sentence or randomly throughout the text.
2. Apply formatting with asterisks around the text for bold (e.g. "*text*") or double underline around the text for 
italics (e.g. "_text_") where appropriate to emphasize key points.
3. Keep the message concise and appropriate for WhatsApp. Aim for a length that is easy to read on a mobile device.
4. Maintain the original language of the message. If the original message is not in English, 
do not translate it to English.
5. Ensure that the rephrased message is appealing to end users, exciting, and likely to encourage engagement or action.

After improving the message, provide only the rephrased text without any additional commentary or explanations. 
Do not include the original message in your response. Return the text in markdown so there are spaces 
and line breaks when necessary.`;
