import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  campaignNameAtom,
  campaignStepAtom,
  documentIdAtom,
  documentTypeAtom,
  duplicateDocumentAtom,
  previewDocumentIdAtom,
  starterAtom,
} from '@atoms/flow';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, CircularProgress, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';
import {
  selectBusinessId,
  useAnalytics,
  useFlowNavigator,
  useMeData,
  useResendCampaignMutation,
  useFlowDocumentData,
  useListCampaignsWithFlowData,
  useMessageTemplateGroupsData,
  selectMessageTemplateIndex,
  useFeatureFlag,
} from '@hooks';
import { usePopulateCampaign } from '@components/FlowChartCampaignV3/hooks/usePopulateCampaign';
import { CampaignDropdown } from '../CampaignDropdown';
import { CampaignStarter } from '../../types';
import { ReadOnlyFlow } from '../ReadOnlyFlow';
import { checkIfCampaignSentBefore, useIsCampaignSentBefore } from '../../hooks/useIsCampaignSentBefore';
import { ReadOnlyFlowProps } from '../ReadOnlyFlow/types';
import { useMigrateToV3 } from '../../hooks/useMigrateToV3';
import { useGenerateCampaignName } from '../../hooks/useGenerateCampaignName';

export const useChooseCampaign = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { sendAnalytics } = useAnalytics();

  const previewDocumentId = useAtomValue(previewDocumentIdAtom);
  const setDocumentType = useSetAtom(documentTypeAtom);
  const setDocumentId = useSetAtom(documentIdAtom);
  const setCampaignStep = useSetAtom(campaignStepAtom);
  const setCampaignName = useSetAtom(campaignNameAtom);
  const setDuplicateDocument = useSetAtom(duplicateDocumentAtom);
  const setStarter = useSetAtom(starterAtom);
  const [tryEditing, setTryEditing] = useState(false);
  const { populateCampaign } = usePopulateCampaign();

  const {
    data: previewDocument,
    isLoading: isLoadingPreviewDocument,
    refetch,
  } = useFlowDocumentData({
    businessId: businessId as string,
    flowDocumentId: previewDocumentId,
    enabled: !!businessId && !!previewDocumentId,
  });

  const { isCampaignSentBefore } = useIsCampaignSentBefore(previewDocumentId);

  const loadCampaignV3 = useCallback(
    (starter: CampaignStarter) => {
      setCampaignStep(starter === 'resend' ? 'compile' : 'build');
      setDocumentType('FLOW_DOCUMENT_TYPE_SENDOUT_V3');
      setDocumentId(starter === 'duplicate' ? '' : previewDocumentId);
      setStarter(starter);
    },
    [previewDocumentId, setCampaignStep, setDocumentId, setDocumentType, setStarter],
  );

  const handleEdit = useCallback(async () => {
    setTryEditing(true);
    // first confirm that the campaign is not sent before
    const sentBefore = await checkIfCampaignSentBefore({
      businessId: businessId || '',
      flowDocumentId: previewDocumentId,
    });
    if (sentBefore) {
      refetch();
      setTryEditing(false);
      return;
    }
    setTryEditing(false);
    loadCampaignV3('edit');
    sendAnalytics('clicks edit_existing_campaign v3');
  }, [businessId, loadCampaignV3, previewDocumentId, refetch, sendAnalytics]);

  const queryClient = useQueryClient();
  const { mutate: resendCampaign } = useResendCampaignMutation({
    onSuccess: () => {
      queryClient.refetchQueries(['flowDocument', { flowDocumentId: previewDocumentId }]).then(() => {
        loadCampaignV3('resend');
      });
    },
  });

  const handleResend = useCallback(() => {
    if (!businessId) return;
    resendCampaign({
      businessId,
      flowDocumentId: previewDocumentId,
    });
    sendAnalytics('clicks resend_existing_campaign v3');
  }, [businessId, previewDocumentId, resendCampaign, sendAnalytics]);

  const [isOpen, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const flowObjects = useMemo(
    () => ({
      nodes: previewDocument?.entity?.nodes?.map((node) => (node.options ? JSON.parse(node.options) : {})) || [],
      edges: previewDocument?.entity?.edges?.map((edge) => (edge.options ? JSON.parse(edge.options) : {})) || [],
    }),
    [previewDocument],
  );

  const isV3 = useMemo(() => previewDocument?.entity?.type === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3', [previewDocument]);

  const { data: templates = {}, isLoading: isTemplatesLoading } = useMessageTemplateGroupsData({
    businessId: businessId || '',
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateIndex,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });

  const migrateToV3 = useMigrateToV3({ ...flowObjects, templates });

  const { generateCampaignName, isLoadingCampaigns } = useGenerateCampaignName();

  const handleDuplicate = useCallback(() => {
    if (!previewDocument) return;

    const name = generateCampaignName(`${previewDocument?.entity?.name} copy`);
    setDuplicateDocument({
      ...flowObjects,
      name,
    });
    setCampaignName(name);
    loadCampaignV3('duplicate');
    sendAnalytics('clicks duplicate_existing_campaign v3');
  }, [
    flowObjects,
    generateCampaignName,
    loadCampaignV3,
    previewDocument,
    sendAnalytics,
    setCampaignName,
    setDuplicateDocument,
  ]);

  const { flowDocumentIdRouteMatch, navigateToFlowHome } = useFlowNavigator();

  const handleUpdateVersion = useCallback(() => {
    if (!previewDocument) return;

    const name = generateCampaignName(`V3 ${previewDocument?.entity?.name}`);
    setDuplicateDocument({
      ...migrateToV3,
      name,
    });
    setCampaignName(name);
    loadCampaignV3('duplicate');
    sendAnalytics('clicks update_version_of_campaign v3');

    if (flowDocumentIdRouteMatch) navigateToFlowHome();
  }, [
    flowDocumentIdRouteMatch,
    generateCampaignName,
    loadCampaignV3,
    migrateToV3,
    navigateToFlowHome,
    previewDocument,
    sendAnalytics,
    setCampaignName,
    setDuplicateDocument,
  ]);

  const readOnlyFlowProps: ReadOnlyFlowProps = useMemo(
    () => (isV3 ? flowObjects : migrateToV3),
    [flowObjects, isV3, migrateToV3],
  );
  const { ffDevOnlyLimitCampaignsLoaded } = useFeatureFlag(['ffDevOnlyLimitCampaignsLoaded']);
  const { data: campaignsWithFlowList } = useListCampaignsWithFlowData({
    businessId: businessId as string,
    enabled: !!businessId,
    paging: ffDevOnlyLimitCampaignsLoaded ? { page: 0, perPage: 50 } : undefined,
  });

  const isInteractionDisabled = useMemo(
    () => tryEditing || isOpen || isLoadingPreviewDocument || (!isV3 && isTemplatesLoading),
    [tryEditing, isOpen, isLoadingPreviewDocument, isV3, isTemplatesLoading],
  );

  const showEditButton = useMemo(() => isV3 && !isCampaignSentBefore, [isCampaignSentBefore, isV3]);
  const showDuplicateButton = useMemo(() => isV3 && isCampaignSentBefore, [isCampaignSentBefore, isV3]);
  const showUpdateVersionButton = useMemo(() => !isV3, [isV3]);
  const showResendButton = useMemo(() => isV3 && isCampaignSentBefore, [isCampaignSentBefore, isV3]);

  useEffect(() => {
    if (!previewDocument) return;
    populateCampaign(previewDocument);
  }, [previewDocument, populateCampaign]);

  return {
    campaignsWithFlowList,
    handleClose,
    handleDuplicate,
    handleEdit,
    handleOpen,
    handleResend,
    handleUpdateVersion,
    isLoadingCampaigns,
    isInteractionDisabled,
    isLoadingPreviewDocument,
    isTemplatesLoading,
    isV3,
    showEditButton,
    showDuplicateButton,
    showUpdateVersionButton,
    showResendButton,
    readOnlyFlowProps,
  };
};

export const ChooseCampaign = () => {
  const theme = useTheme();
  const {
    campaignsWithFlowList,
    handleClose,
    handleDuplicate,
    handleEdit,
    handleOpen,
    handleResend,
    handleUpdateVersion,
    isLoadingCampaigns,
    isInteractionDisabled,
    isLoadingPreviewDocument,
    isTemplatesLoading,
    isV3,
    showEditButton,
    showDuplicateButton,
    showResendButton,
    showUpdateVersionButton,
    readOnlyFlowProps,
  } = useChooseCampaign();

  return (
    <Box sx={{ width: '100%', height: '100%', position: 'relative', background: theme.palette.flowGradient }}>
      <Box sx={{ width: '100%', position: 'absolute', zIndex: 10 }}>
        <Box
          sx={{
            alignItems: 'center',
            background: theme.palette.background.paper,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            display: 'flex',
            gap: 2,
            maxWidth: theme.spacing(120), // 960px
            mt: 4,
            mx: useMediaQuery(theme.breakpoints.up('xl')) ? 'auto' : 5,
            p: 2,
          }}
        >
          <CampaignDropdown
            campaigns={campaignsWithFlowList?.entity?.campaigns || []}
            onOpen={handleOpen}
            onClose={handleClose}
          />
          {!isLoadingPreviewDocument && (
            <>
              {showEditButton && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEdit}
                  disabled={isInteractionDisabled || !isV3}
                >
                  Edit
                </Button>
              )}
              {showResendButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleResend}
                  disabled={isInteractionDisabled || !isV3}
                >
                  Resend
                </Button>
              )}
              {showDuplicateButton && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDuplicate}
                  disabled={isInteractionDisabled || isLoadingCampaigns}
                >
                  Duplicate & Edit
                </Button>
              )}
              {showUpdateVersionButton && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleUpdateVersion}
                  disabled={isInteractionDisabled || isLoadingCampaigns}
                >
                  Update Version
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <ReadOnlyFlow {...readOnlyFlowProps} />
      {(isLoadingPreviewDocument || isLoadingCampaigns || (!isV3 && isTemplatesLoading)) && (
        <CircularProgress sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />
      )}
    </Box>
  );
};
