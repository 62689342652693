import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { UploadParameters, UploadResponse } from './types';
import { accessTokenService } from '../../api';

export const useFileUploadMutation = (
  options?: Omit<UseMutationOptions<UploadResponse, unknown, UploadParameters, unknown>, 'mutationKey' | 'mutationFn'>,
) => {
  return useMutation(
    ['uploadFile'],
    async ({ businessId, file, controller }: UploadParameters) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(
        `${process.env.REACT_APP_INBOX_API_GATEWAY}/v1/businesses/${businessId}/upload/file`,
        {
          signal: controller?.signal,
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${await accessTokenService.tempSolution()}`,
          },
        },
      );

      if (response.ok) {
        return (await response.json()).entity as UploadResponse;
      }
      throw await response.json();
    },
    { ...options },
  );
};
