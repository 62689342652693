import { DtoUpdate } from '@connectlyai-tenets/sdk';

export type ErrorProps = NonNullable<NonNullable<DtoUpdate['compileReactFlowDocumentResult']>['items']>[0];

export const isDiagnosticMissingTemplate = ({ code }: ErrorProps) => {
  if (code === 'DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID') return true;
  if (code === 'DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED') return true;
  return false;
};

export const isDiagnosticApprovedTemplate = ({ code }: ErrorProps) => {
  return code === 'DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED';
};

export const isDiagnosticIgnorable = (errorProps: ErrorProps) => {
  return errorProps.kind === 'DIAGNOSTIC_KIND_WARN';
};

export const shouldShowError = (errorProps: ErrorProps) => {
  if (isDiagnosticMissingTemplate(errorProps)) return false;
  if (isDiagnosticApprovedTemplate(errorProps)) return false;
  if (isDiagnosticIgnorable(errorProps)) return false;
  return true;
};
