import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { WidgetType } from '@codemirror/view';
import { VariableType } from '@hooks/useFlowVariables/types';
import { TextVariable } from '../TextVariable/TextVariable';
import { DecorationStyleCombination } from './formatter';

export class FormatWidget extends WidgetType {
  private readonly text: string;

  private readonly lineHeight: number;

  private readonly format: DecorationStyleCombination;

  private readonly variableType: VariableType;

  constructor({
    text,
    lineHeight,
    format,
    variableType,
  }: {
    text: string;
    lineHeight: number;
    format: DecorationStyleCombination;
    variableType: VariableType;
  }) {
    super();
    this.text = text;
    this.lineHeight = lineHeight;
    this.format = format;
    this.variableType = variableType;
  }

  createNode() {
    const variableProps = { lineHeight: this.lineHeight, variableType: this.variableType };

    let node = <></>;
    switch (this.format) {
      case 'bold':
        node = <b>{this.text}</b>;
        break;
      case 'ital':
        node = <i>{this.text}</i>;
        break;
      case 'stri':
        node = <s>{this.text}</s>;
        break;
      case 'bold-ital':
        node = (
          <b>
            <i>{this.text}</i>
          </b>
        );
        break;
      case 'bold-stri':
        node = (
          <b>
            <s>{this.text}</s>
          </b>
        );
        break;
      case 'ital-stri':
        node = (
          <i>
            <s>{this.text}</s>
          </i>
        );
        break;
      case 'bold-ital-stri':
        node = (
          <b>
            <i>
              <s>{this.text}</s>
            </i>
          </b>
        );
        break;
      case 'mono':
        node = <code>{this.text}</code>;
        break;
      case 'bold-mono':
        node = (
          <b>
            <code>{this.text}</code>
          </b>
        );
        break;
      case 'ital-mono':
        node = (
          <i>
            <code>{this.text}</code>
          </i>
        );
        break;
      case 'mono-stri':
        node = (
          <s>
            <code>{this.text}</code>
          </s>
        );
        break;
      case 'bold-ital-mono':
        node = (
          <b>
            <i>
              <code>{this.text}</code>
            </i>
          </b>
        );
        break;
      case 'bold-mono-stri':
        node = (
          <b>
            <s>
              <code>{this.text}</code>
            </s>
          </b>
        );
        break;
      case 'ital-mono-stri':
        node = (
          <i>
            <s>
              <code>{this.text}</code>
            </s>
          </i>
        );
        break;
      case 'bold-ital-mono-stri':
        node = (
          <b>
            <i>
              <s>
                <code>{this.text}</code>
              </s>
            </i>
          </b>
        );
        break;
      case 'vari':
        node = <TextVariable variable={this.text} {...variableProps} />;
        break;
      case 'bold-vari':
        node = <TextVariable variable={<b>{this.text}</b>} {...variableProps} />;
        break;
      case 'ital-vari':
        node = <TextVariable variable={<i>{this.text}</i>} {...variableProps} />;
        break;
      case 'stri-vari':
        node = <TextVariable variable={<s>{this.text}</s>} {...variableProps} />;
        break;
      case 'bold-ital-vari':
        node = (
          <TextVariable
            variable={
              <i>
                <b>{this.text}</b>
              </i>
            }
            {...variableProps}
          />
        );
        break;
      case 'bold-stri-vari':
        node = (
          <TextVariable
            variable={
              <s>
                <b>{this.text}</b>
              </s>
            }
            {...variableProps}
          />
        );
        break;
      case 'ital-stri-vari':
        node = (
          <TextVariable
            variable={
              <i>
                <s>{this.text}</s>
              </i>
            }
            {...variableProps}
          />
        );
        break;
      case 'bold-ital-stri-vari':
        node = (
          <TextVariable
            variable={
              <b>
                <i>
                  <s>{this.text}</s>
                </i>
              </b>
            }
            {...variableProps}
          />
        );
        break;
      case 'mono-vari':
        node = <TextVariable variable={<code>{this.text}</code>} {...variableProps} />;
        break;
      case 'bold-mono-vari':
        node = (
          <TextVariable
            variable={
              <b>
                <code>{this.text}</code>
              </b>
            }
            {...variableProps}
          />
        );
        break;
      case 'ital-mono-vari':
        node = (
          <TextVariable
            variable={
              <i>
                <code>{this.text}</code>
              </i>
            }
            {...variableProps}
          />
        );
        break;
      case 'mono-stri-vari':
        node = (
          <TextVariable
            variable={
              <s>
                <code>{this.text}</code>
              </s>
            }
            {...variableProps}
          />
        );
        break;
      case 'bold-ital-mono-vari':
        node = (
          <TextVariable
            variable={
              <b>
                <i>
                  <code>{this.text}</code>
                </i>
              </b>
            }
            {...variableProps}
          />
        );
        break;
      case 'bold-mono-stri-vari':
        node = (
          <TextVariable
            variable={
              <b>
                <s>
                  <code>{this.text}</code>
                </s>
              </b>
            }
            {...variableProps}
          />
        );
        break;
      case 'ital-mono-stri-vari':
        node = (
          <TextVariable
            variable={
              <i>
                <s>
                  <code>{this.text}</code>
                </s>
              </i>
            }
            {...variableProps}
          />
        );
        break;
      case 'bold-ital-mono-stri-vari':
        node = (
          <TextVariable
            variable={
              <b>
                <i>
                  <s>
                    <code>{this.text}</code>
                  </s>
                </i>
              </b>
            }
            {...variableProps}
          />
        );
        break;
      default:
        break;
    }
    return node;
  }

  toDOM() {
    const dom = document.createElement('span');
    const node = this.createNode();
    dom.innerHTML = renderToStaticMarkup(node);
    return dom;
  }

  updateDOM(dom: HTMLElement) {
    const node = this.createNode();
    dom.innerHTML = renderToStaticMarkup(node);
    return true;
  }
}
