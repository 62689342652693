import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_SOFIA_DOCUMENTS =
  '/internal/v1/businesses/{input.businessId}/sofia/ocean_beach_document_association/replace';

type MutationSofiaDocuments = typeof MUTATION_SOFIA_DOCUMENTS;

export type MutationSofiaDocumentsResponse =
  Paths['sofiaV1'][MutationSofiaDocuments]['post']['responses']['200']['schema'];

export type MutationSofiaDocumentsQuery =
  Paths['sofiaV1'][MutationSofiaDocuments]['post']['parameters']['body']['input'];

export type MutationSofiaDocumentsParams = { businessId: string } & MutationSofiaDocumentsQuery;

export type MutationSofiaDocumentsOptions = Omit<
  UseMutationOptions<MutationSofiaDocumentsResponse, ConnectlyError, MutationSofiaDocumentsParams, unknown>,
  'mutationKey' | 'mutationFn'
>;
