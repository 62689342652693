import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.graphapi.v10_0.error";
function createBaseError() {
    return {
        message: "",
        type: "",
        code: 0,
        errorData: undefined,
        errorSubcode: 0,
        errorUserTitle: "",
        errorUserMsg: "",
        fbtraceId: "",
    };
}
export const Error = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.message !== "") {
            writer.uint32(10).string(message.message);
        }
        if (message.type !== "") {
            writer.uint32(18).string(message.type);
        }
        if (message.code !== 0) {
            writer.uint32(24).int32(message.code);
        }
        if (message.errorData !== undefined) {
            ErrorData.encode(message.errorData, writer.uint32(66).fork()).ldelim();
        }
        if (message.errorSubcode !== 0) {
            writer.uint32(32).int32(message.errorSubcode);
        }
        if (message.errorUserTitle !== "") {
            writer.uint32(42).string(message.errorUserTitle);
        }
        if (message.errorUserMsg !== "") {
            writer.uint32(50).string(message.errorUserMsg);
        }
        if (message.fbtraceId !== "") {
            writer.uint32(58).string(message.fbtraceId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.code = reader.int32();
                    break;
                case 8:
                    message.errorData = ErrorData.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.errorSubcode = reader.int32();
                    break;
                case 5:
                    message.errorUserTitle = reader.string();
                    break;
                case 6:
                    message.errorUserMsg = reader.string();
                    break;
                case 7:
                    message.fbtraceId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            message: isSet(object.message) ? String(object.message) : "",
            type: isSet(object.type) ? String(object.type) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            errorData: isSet(object.errorData)
                ? ErrorData.fromJSON(object.errorData)
                : undefined,
            errorSubcode: isSet(object.errorSubcode)
                ? Number(object.errorSubcode)
                : 0,
            errorUserTitle: isSet(object.errorUserTitle)
                ? String(object.errorUserTitle)
                : "",
            errorUserMsg: isSet(object.errorUserMsg)
                ? String(object.errorUserMsg)
                : "",
            fbtraceId: isSet(object.fbtraceId) ? String(object.fbtraceId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.message !== undefined && (obj.message = message.message);
        message.type !== undefined && (obj.type = message.type);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.errorData !== undefined &&
            (obj.errorData = message.errorData
                ? ErrorData.toJSON(message.errorData)
                : undefined);
        message.errorSubcode !== undefined &&
            (obj.errorSubcode = Math.round(message.errorSubcode));
        message.errorUserTitle !== undefined &&
            (obj.errorUserTitle = message.errorUserTitle);
        message.errorUserMsg !== undefined &&
            (obj.errorUserMsg = message.errorUserMsg);
        message.fbtraceId !== undefined && (obj.fbtraceId = message.fbtraceId);
        return obj;
    },
};
function createBaseErrorData() {
    return { messagingProduct: "", details: "" };
}
export const ErrorData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingProduct !== "") {
            writer.uint32(10).string(message.messagingProduct);
        }
        if (message.details !== "") {
            writer.uint32(18).string(message.details);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseErrorData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingProduct = reader.string();
                    break;
                case 2:
                    message.details = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingProduct: isSet(object.messagingProduct)
                ? String(object.messagingProduct)
                : "",
            details: isSet(object.details) ? String(object.details) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingProduct !== undefined &&
            (obj.messagingProduct = message.messagingProduct);
        message.details !== undefined && (obj.details = message.details);
        return obj;
    },
};
function createBaseResponse() {
    return { error: undefined };
}
export const Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.error !== undefined) {
            Error.encode(message.error, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.error = Error.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.error !== undefined &&
            (obj.error = message.error ? Error.toJSON(message.error) : undefined);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
