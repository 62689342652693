import { atom } from 'jotai';
import { SofiaEditSetting, SofiaStoreDescriptionView } from '@scenes/Sofia/types';
import { DEFAULT_SOFIA_DATA, DATA_LAG_DAYS } from '@scenes/Sofia/constants';
import { DateRange } from '../../components/DateRangePicker';
import { buildDateRange } from '../../hooks/useDateRange';
import { TimeRange, buildQueryRanges } from '../../utils/analytics';

// Onboarding
export const sofiaInOnboardingAtom = atom<boolean>(false);
export const sofiaOnboardingKnowledgeChoiceAtom = atom<'website' | 'text' | 'document'>('text');
export const sofiaOnboardingFinishingAtom = atom<boolean>(false);

export const sofiaEditSettingAtom = atom<SofiaEditSetting>(null);

// Store Descriptor
export const sofiaStoreDescriptorAtom = atom<SofiaStoreDescriptionView>(DEFAULT_SOFIA_DATA);

// Welcome Message
export const sofiaWelcomeMessageAtom = atom<string>('');
export const sofiaPromptAdditionAtom = atom<string>('');

// Documents
export const sofiaOnboardingDocumentsAtom = atom<{ id: string; name?: string; mimeType?: string }[]>([]);
export const sofiaFreeTextAtom = atom<string>('');

// Website
export const sofiaWebsiteScanAtom = atom<string | undefined>(undefined);

// Deactive Bot
export const deactivateBotModalIsOpenAtom = atom<boolean>(false);

// Analytics
export const dateRangeAtom = atom<DateRange>({
  ...buildDateRange('last7Days', DATA_LAG_DAYS),
  key: 'selection',
});

// Sofia Analytics Table uses query range that includes time zone difference
export const sofiaAnalyticsTimeRangesAtom = atom<[TimeRange, TimeRange]>((get) => {
  const dateRange = get(dateRangeAtom);
  const defaultRange = { timeRangeStart: '', timeRangeEnd: '' };
  const queryRanges = buildQueryRanges(dateRange, 2);
  return [queryRanges[0] || defaultRange, queryRanges[1] || defaultRange];
});

// Other Sofia Analytics views use the Zulu times and a timezone parameter
export const sofiaAnalysisTimeRangesAtom = atom<[TimeRange, TimeRange]>((get) => {
  const dateRange = get(dateRangeAtom);
  const defaultRange = { timeRangeStart: '', timeRangeEnd: '' };
  const queryRanges = buildQueryRanges(dateRange, 2, true);
  return [queryRanges[0] || defaultRange, queryRanges[1] || defaultRange];
});
