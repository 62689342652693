import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const UPDATE_AUDIENCE_URL =
  '/internal/v3/businesses/{input.businessId}/update_audience/sendouts/{input.sendoutId}';
type UpdateAudienceURL = typeof UPDATE_AUDIENCE_URL;

export type UpdateAudienceInput = Paths['campaignV3'][UpdateAudienceURL]['post']['parameters']['body']['input'];

export type UpdateAudienceVariables = UpdateAudienceInput & {
  businessId: string;
  sendoutId: string;
};

export type UpdateAudienceResponse = Paths['campaignV3'][UpdateAudienceURL]['post']['responses']['200']['schema'];

export type UpdateAudienceOptions = Omit<
  UseMutationOptions<UpdateAudienceResponse, ConnectlyError, UpdateAudienceVariables, unknown>,
  'mutationKey' | 'mutationFn'
>;
