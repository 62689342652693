import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateWelcomeRoute } from '@connectlyai-sdks/inbox-navigation';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { ErrorCode } from '@connectlyai-idl/models/dist/models/error/code';
import { mutationDeactivateIntegrationProvider, mutationProvisionNumberProvider, mutationInstallIntegrationProvider, } from '../usecases';
export const updateSettings = createAsyncThunk('welcome/updateSettings', async ({ di, name }) => {
    const mutationUpdateSettingsPresentation = di.mutationUpdateSettingsPresentationProvider();
    return mutationUpdateSettingsPresentation({
        id: di.config.businessId,
        profilePicId: '',
        handle: '',
        name,
        createdAt: undefined,
        updatedAt: new Date().toISOString(),
        deletedAt: undefined,
        channels: [],
        widgetConfigs: undefined,
        quickReplyTemplates: undefined,
        systemRobotUserId: '',
        tags: undefined,
        properties: undefined,
        inboxConfig: undefined,
        integrations: undefined,
        organization: undefined,
    });
});
export const navigateToSettings = createAsyncThunk('welcome/navigation', async ({ navigator }) => {
    navigator.navigate(generateWelcomeRoute());
});
export const updateWAToken = createAsyncThunk('welcome/updateWAToken', async ({ di, accessToken, userId, dataAccessExpirationTime, expiresIn, setIsLoading, businessServiceProvider, useBrazilCreditLine, }) => {
    const mutationUpdateWAToken = di.mutationUpdateWATokenProvider();
    try {
        setIsLoading(true);
        return await mutationUpdateWAToken({
            businessId: di.config.businessId,
            accessToken,
            userId,
            dataAccessExpirationTime,
            expiresIn,
            businessServiceProvider,
            useBrazilCreditLine,
        });
    }
    catch (e) {
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Error connecting WhatsApp',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
        throw e;
    }
    finally {
        setIsLoading(false);
    }
});
export const provisionNumber = createAsyncThunk('welcome/provisionNumber', async ({ di, setIsLoading }) => {
    const mutationProvisionNumber = mutationProvisionNumberProvider(di.logProvider(), di.networkClientProvider());
    const { businessId } = di.config;
    if (!businessId) {
        throw new Error('null business id');
    }
    try {
        setIsLoading(true);
        const result = await mutationProvisionNumber(businessId, {
            selectorOneof: {
                $case: 'criteria',
                criteria: {
                    isoCountry: 'US',
                    areaCode: undefined,
                },
            },
        });
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Phone number provisioned',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
            },
        });
        return result;
    }
    catch (e) {
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Error provisioning phone number',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
        throw e;
    }
    finally {
        setIsLoading(false);
    }
});
export const installIntegration = createAsyncThunk('welcome/installIntegration', async ({ di, type: integrationType, parameters: integrationParameters }) => {
    const mutation = mutationInstallIntegrationProvider(di.logProvider(), di.networkClientProvider());
    const { businessId } = di.config;
    if (!businessId) {
        throw new Error('null business id');
    }
    try {
        const result = await mutation({
            businessId,
            integrationType,
            integrationParameters,
            forceReinstall: false,
        });
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Integration installed',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
            },
        });
        return result;
    }
    catch (e) {
        const error = e;
        if ((error === null || error === void 0 ? void 0 : error.getCode()) === ErrorCode.ERROR_CODE_APPROVED_WABA_TEMPLATE_NOT_FOUND) {
            di.notificationServiceProvider().notify({
                surface: NotificationSurface.SNACKBAR,
                notification: {
                    message: 'Integration installed',
                    severity: NotificationSeverity.SUCCESS,
                    icon: '',
                },
            });
            return { integrationId: '' };
        }
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Error installing integration',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
        throw e;
    }
});
export const deactivateIntegration = createAsyncThunk('welcome/deactivateIntegration', async ({ di, integrationId }) => {
    const mutation = mutationDeactivateIntegrationProvider(di.logProvider(), di.networkClientProvider());
    const { businessId } = di.config;
    try {
        if (!businessId) {
            throw new Error('Empty business id');
        }
        if (!integrationId) {
            throw new Error('Empty integration id');
        }
        await mutation({
            businessId,
            integrationId,
        });
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Workflow deactivated',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
            },
        });
    }
    catch (e) {
        di.notificationServiceProvider().notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Error deactivating workflow',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
        throw e;
    }
});
