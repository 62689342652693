import { RoomDto } from '@connectlyai-idl/models/dist/models/room/models';
export const queryRoomProvider = (log, networkClient) => async (businessId, roomId) => {
    const url = `/v1/businesses/${businessId}/rooms/${roomId}`;
    log.debug('queryRoom@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.get(url);
        log.debug('queryRoom@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return RoomDto.fromJSON(resp.data.entity);
    }
    catch (err) {
        log.error('queryRoom@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
