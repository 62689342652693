/* eslint-disable react/no-unused-prop-types */
import React, { FC, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  DotIcon,
  InputLabel,
  FormControl,
  MenuItem,
  Label,
  ListItemIcon,
  ListItemText,
  Select,
  SelectChangeEvent,
  TextField,
  Box,
  useTheme,
  Modal,
  Breakpoint,
} from '@connectlyai-tenets/ui-styled-web';
import { SIZE_MEDIA_XXS, SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { ColorViewModel } from './colors';
import { createStateAtom, createNameErrorAtom } from './atoms';

export type TagsCreateDialogResult = {
  name: string;
  color: string;
};

export type TagsCreateDialogProps = {
  colors: ColorViewModel[];
  open: boolean;
  onClose: () => void;
  onSubmit: (result: TagsCreateDialogResult) => void;
};

const useTagsCreateDialog = ({ colors, open, onClose, onSubmit }: TagsCreateDialogProps) => {
  const [formState, setFormState] = useAtom(createStateAtom);

  const [nameError, setNameError] = useAtom(createNameErrorAtom);
  useEffect(() => {
    if (!open) {
      setFormState({
        name: '',
        colorId: '',
      });
    }
  }, [open, setFormState]);

  const colorsMap = useMemo(
    () =>
      colors.reduce<{ [id: string]: ColorViewModel }>((map, val) => {
        map[val.id] = val;
        return map;
      }, {}),
    [colors],
  );

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const attr = e.currentTarget.name;
    if (attr === 'name') {
      setNameError('');
    }
    setFormState((prev) => ({
      ...prev,
      [e.currentTarget.name]: e.currentTarget.value,
    }));
  };
  const handleSelectChange = (e: SelectChangeEvent) => {
    setFormState((prev) => ({
      ...prev,
      colorId: e.target.value as string,
    }));
  };

  const handleOnSubmit = () => {
    const { name } = formState;
    if (name === '') {
      setNameError('Name is required');
      return;
    }
    const color = colorsMap[formState.colorId]?.colorHex || colorsMap.green.colorHex;
    onSubmit({
      name,
      color,
    });
  };
  return {
    open,
    colors,
    colorsMap,
    nameError,
    formState,
    handleInputChange,
    handleSelectChange,
    handleOnSubmit,
    handleClose: onClose,
  };
};

export const TagsCreateDialog: FC<TagsCreateDialogProps> = (props: TagsCreateDialogProps) => {
  const {
    open,
    colors,
    colorsMap,
    nameError,
    formState,
    handleInputChange,
    handleSelectChange,
    handleOnSubmit,
    handleClose,
  } = useTagsCreateDialog(props);

  const theme = useTheme();
  const dialog = {
    open,
    onClose: handleClose,
    maxWidth: 'sm' as Breakpoint,
    fullWidth: true,
    'data-testid': 'tags/createtagdialog',
    'aria-labelledby': 'tags-create-dialog-title',
  };
  return (
    <Modal
      dialog={dialog}
      title="Create a New Tag"
      actions={{
        primaryButton: {
          'data-testid': 'tags/createtagdialog/primary',
          onClick: handleOnSubmit,
          children: 'Add',
        },
        tertiaryButton: {
          'data-testid': 'tags/createtagdialog/secondary',
          onClick: handleClose,
          children: 'Cancel',
        },
      }}
    >
      <form noValidate>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
          }}
        >
          <TextField
            id="tags-create-dialog-name"
            autoComplete="off"
            label="Name"
            name="name"
            error={nameError !== ''}
            helperText={nameError}
            value={formState.name}
            variant="outlined"
            onChange={handleInputChange}
          />
          <FormControl
            variant="outlined"
            sx={{
              mt: SIZE_SPACING_INTER_COMPONENT,
              '& label.Mui-focused.MuiInputLabel-root.MuiInputLabel-shrink': {
                // solves problem where label for <Select> component shows border running through it.
                // think related to renderValue used
                backgroundColor: '#fff',
              },
            }}
          >
            <InputLabel htmlFor="tags-create-dialog-color-label">Color</InputLabel>
            <Select
              labelId="tags-create-dialog-color-label"
              value={formState.colorId}
              renderValue={(val) => {
                const color = colorsMap[val as string];
                if (!color) {
                  return 'Color';
                }
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DotIcon
                      htmlColor={color.colorHex}
                      sx={{
                        width: theme.spacing(SIZE_MEDIA_XXS),
                        height: theme.spacing(SIZE_MEDIA_XXS),
                      }}
                    />
                    <Label>{color.name}</Label>
                  </Box>
                );
              }}
              variant="outlined"
              onChange={handleSelectChange}
              sx={{
                '.MuiSelect-select:focus': {
                  background: 'transparent',
                },
              }}
            >
              {colors.map((val) => (
                <MenuItem
                  key={val.id}
                  value={val.id}
                  sx={{
                    '&.Mui-selected': {
                      background: theme.palette.primary.light,
                    },
                    '&.Mui-selected:hover': {
                      background: theme.palette.primary.light,
                    },
                  }}
                >
                  <ListItemIcon>
                    <DotIcon
                      htmlColor={val.colorHex}
                      sx={{
                        width: theme.spacing(SIZE_MEDIA_XXS),
                        height: theme.spacing(SIZE_MEDIA_XXS),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={val.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </form>
    </Modal>
  );
};
