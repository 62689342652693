import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Card, Divider, List, ListItem } from '@mui/material';
import { blueGrey } from '../../theme/colors';
const Items = ({ items, itemSx }) => {
    return (_jsx(_Fragment, { children: items.map((item, index) => {
            return (_jsxs(Fragment, { children: [index > 0 && _jsx(Divider, {}), _jsx(ListItem, { sx: [
                            {
                                p: 3,
                            },
                            ...(Array.isArray(itemSx) ? itemSx : [itemSx]),
                        ], children: item.node })] }, item.id));
        }) }));
};
export const StackCard = ({ items, sx, listSx, itemSx, containerless }) => {
    if (containerless) {
        return _jsx(Items, { items: items, itemSx: itemSx });
    }
    return (_jsx(Card, { className: "stack-card", sx: [
            {
                border: `1px solid ${blueGrey[200]}`,
                overflow: 'hidden',
                '&:hover': {
                    overflow: 'auto',
                },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: _jsx(List, { sx: [
                {
                    padding: 0,
                },
                ...(Array.isArray(listSx) ? listSx : [listSx]),
            ], children: _jsx(Items, { items: items, itemSx: itemSx }) }) }));
};
