import { useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TemplateComponents } from '@hooks/useMessageTemplateGroupsData/types';
import { FlowVariableExtended, canonicalize, VariableMapping } from '@hooks/useFlowVariables';
import { mapComponentsToVariables, mapLocationToMapPrefix } from './mappers';
import { VARIABLE_COUNTERS_DEFAULT } from './constants';

export const useDetectVariables = () => {
  const variableCounters = useRef(VARIABLE_COUNTERS_DEFAULT);
  const variableDefinitions = useRef<FlowVariableExtended[]>([]);

  const replaceToMatchMax = useCallback((name: string, location: 'body' | 'header' | 'link') => {
    const namePrefix = mapLocationToMapPrefix(location);
    const parsedIndex = parseInt(name.replace(namePrefix, ''), 10);

    variableCounters.current = {
      ...variableCounters.current,
      [location]: Math.max(variableCounters.current.body, parsedIndex + 1),
    };
  }, []);

  const prepareDetectVariables = useCallback(
    (existingVariableDefinitions?: FlowVariableExtended[]) => {
      variableCounters.current = { ...VARIABLE_COUNTERS_DEFAULT };
      variableDefinitions.current = [];

      if (!existingVariableDefinitions) return;

      existingVariableDefinitions
        .map((variable) => variable.simpleName)
        .forEach((name) => {
          if (name?.startsWith('variable')) {
            replaceToMatchMax(name, 'body');
          } else if (name?.startsWith('headerVariable')) {
            replaceToMatchMax(name, 'header');
          } else if (name?.startsWith('linkVariable')) {
            replaceToMatchMax(name, 'link');
          }
        });
    },
    [replaceToMatchMax],
  );

  const detectVariables = useCallback(
    (components: TemplateComponents) => {
      const detectedVariables = mapComponentsToVariables(components);
      const mappings: VariableMapping[] = [];

      detectedVariables.forEach(({ variable, location, prefix }) => {
        const namePrefix = mapLocationToMapPrefix(location);
        const variableName = `${namePrefix}${variableCounters.current[location]}`;

        variableDefinitions.current.push({
          type: 'FLOW_TYPE_STRING',
          id: uuidv4(),
          simpleName: variableName,
          simpleNameCanonical: canonicalize(variableName),
        });

        mappings.push({
          rawParam: `${prefix}${variable}`,
          fullQualifier: canonicalize(variableName),
        });

        variableCounters.current[location] += 1;
      });

      return { mappings, variableDefinitions: variableDefinitions.current };
    },
    [variableCounters],
  );

  const getVariableDefinitions = useCallback(() => variableDefinitions.current, []);

  return { detectVariables, getVariableDefinitions, prepareDetectVariables };
};
