import { Timestamp } from "../../google/protobuf/timestamp";
import { ChannelIdentifier } from "../common/channel/models";
import { MessageTemplate, WAMessageTemplateIdentifier, MessageTemplateInput, } from "../messaging/template/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.campaign";
export var CampaignType;
(function (CampaignType) {
    CampaignType[CampaignType["CAMPAIGN_TYPE_UNSPECIFIED"] = 0] = "CAMPAIGN_TYPE_UNSPECIFIED";
    CampaignType[CampaignType["CAMPAIGN_TYPE_MARKETING"] = 1] = "CAMPAIGN_TYPE_MARKETING";
    CampaignType[CampaignType["CAMPAIGN_TYPE_CSAT"] = 2] = "CAMPAIGN_TYPE_CSAT";
    CampaignType[CampaignType["CAMPAIGN_TYPE_CUSTOM"] = 3] = "CAMPAIGN_TYPE_CUSTOM";
    CampaignType[CampaignType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CampaignType || (CampaignType = {}));
export function campaignTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "CAMPAIGN_TYPE_UNSPECIFIED":
            return CampaignType.CAMPAIGN_TYPE_UNSPECIFIED;
        case 1:
        case "CAMPAIGN_TYPE_MARKETING":
            return CampaignType.CAMPAIGN_TYPE_MARKETING;
        case 2:
        case "CAMPAIGN_TYPE_CSAT":
            return CampaignType.CAMPAIGN_TYPE_CSAT;
        case 3:
        case "CAMPAIGN_TYPE_CUSTOM":
            return CampaignType.CAMPAIGN_TYPE_CUSTOM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignType.UNRECOGNIZED;
    }
}
export function campaignTypeToJSON(object) {
    switch (object) {
        case CampaignType.CAMPAIGN_TYPE_UNSPECIFIED:
            return "CAMPAIGN_TYPE_UNSPECIFIED";
        case CampaignType.CAMPAIGN_TYPE_MARKETING:
            return "CAMPAIGN_TYPE_MARKETING";
        case CampaignType.CAMPAIGN_TYPE_CSAT:
            return "CAMPAIGN_TYPE_CSAT";
        case CampaignType.CAMPAIGN_TYPE_CUSTOM:
            return "CAMPAIGN_TYPE_CUSTOM";
        case CampaignType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var SendoutInputType;
(function (SendoutInputType) {
    SendoutInputType[SendoutInputType["SENDOUT_INPUT_TYPE_UNSPECIFIED"] = 0] = "SENDOUT_INPUT_TYPE_UNSPECIFIED";
    SendoutInputType[SendoutInputType["SENDOUT_INPUT_TYPE_SPREADSHEET"] = 1] = "SENDOUT_INPUT_TYPE_SPREADSHEET";
    SendoutInputType[SendoutInputType["SENDOUT_INPUT_TYPE_CSV"] = 2] = "SENDOUT_INPUT_TYPE_CSV";
    SendoutInputType[SendoutInputType["SENDOUT_INPUT_TYPE_AUDIENCE"] = 3] = "SENDOUT_INPUT_TYPE_AUDIENCE";
    SendoutInputType[SendoutInputType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SendoutInputType || (SendoutInputType = {}));
export function sendoutInputTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "SENDOUT_INPUT_TYPE_UNSPECIFIED":
            return SendoutInputType.SENDOUT_INPUT_TYPE_UNSPECIFIED;
        case 1:
        case "SENDOUT_INPUT_TYPE_SPREADSHEET":
            return SendoutInputType.SENDOUT_INPUT_TYPE_SPREADSHEET;
        case 2:
        case "SENDOUT_INPUT_TYPE_CSV":
            return SendoutInputType.SENDOUT_INPUT_TYPE_CSV;
        case 3:
        case "SENDOUT_INPUT_TYPE_AUDIENCE":
            return SendoutInputType.SENDOUT_INPUT_TYPE_AUDIENCE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SendoutInputType.UNRECOGNIZED;
    }
}
export function sendoutInputTypeToJSON(object) {
    switch (object) {
        case SendoutInputType.SENDOUT_INPUT_TYPE_UNSPECIFIED:
            return "SENDOUT_INPUT_TYPE_UNSPECIFIED";
        case SendoutInputType.SENDOUT_INPUT_TYPE_SPREADSHEET:
            return "SENDOUT_INPUT_TYPE_SPREADSHEET";
        case SendoutInputType.SENDOUT_INPUT_TYPE_CSV:
            return "SENDOUT_INPUT_TYPE_CSV";
        case SendoutInputType.SENDOUT_INPUT_TYPE_AUDIENCE:
            return "SENDOUT_INPUT_TYPE_AUDIENCE";
        case SendoutInputType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var SendoutState;
(function (SendoutState) {
    SendoutState[SendoutState["SENDOUT_STATE_UNSPECIFIED"] = 0] = "SENDOUT_STATE_UNSPECIFIED";
    SendoutState[SendoutState["SENDOUT_STATE_INACTIVE"] = 1] = "SENDOUT_STATE_INACTIVE";
    SendoutState[SendoutState["SENDOUT_STATE_ACTIVE"] = 2] = "SENDOUT_STATE_ACTIVE";
    SendoutState[SendoutState["SENDOUT_STATE_DRAFT"] = 3] = "SENDOUT_STATE_DRAFT";
    SendoutState[SendoutState["SENDOUT_STATE_COMPLETED"] = 4] = "SENDOUT_STATE_COMPLETED";
    SendoutState[SendoutState["SENDOUT_STATE_ABORTED"] = 5] = "SENDOUT_STATE_ABORTED";
    SendoutState[SendoutState["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SendoutState || (SendoutState = {}));
export function sendoutStateFromJSON(object) {
    switch (object) {
        case 0:
        case "SENDOUT_STATE_UNSPECIFIED":
            return SendoutState.SENDOUT_STATE_UNSPECIFIED;
        case 1:
        case "SENDOUT_STATE_INACTIVE":
            return SendoutState.SENDOUT_STATE_INACTIVE;
        case 2:
        case "SENDOUT_STATE_ACTIVE":
            return SendoutState.SENDOUT_STATE_ACTIVE;
        case 3:
        case "SENDOUT_STATE_DRAFT":
            return SendoutState.SENDOUT_STATE_DRAFT;
        case 4:
        case "SENDOUT_STATE_COMPLETED":
            return SendoutState.SENDOUT_STATE_COMPLETED;
        case 5:
        case "SENDOUT_STATE_ABORTED":
            return SendoutState.SENDOUT_STATE_ABORTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SendoutState.UNRECOGNIZED;
    }
}
export function sendoutStateToJSON(object) {
    switch (object) {
        case SendoutState.SENDOUT_STATE_UNSPECIFIED:
            return "SENDOUT_STATE_UNSPECIFIED";
        case SendoutState.SENDOUT_STATE_INACTIVE:
            return "SENDOUT_STATE_INACTIVE";
        case SendoutState.SENDOUT_STATE_ACTIVE:
            return "SENDOUT_STATE_ACTIVE";
        case SendoutState.SENDOUT_STATE_DRAFT:
            return "SENDOUT_STATE_DRAFT";
        case SendoutState.SENDOUT_STATE_COMPLETED:
            return "SENDOUT_STATE_COMPLETED";
        case SendoutState.SENDOUT_STATE_ABORTED:
            return "SENDOUT_STATE_ABORTED";
        case SendoutState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var SendoutSubstate;
(function (SendoutSubstate) {
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_UNSPECIFIED"] = 0] = "SENDOUT_SUBSTATE_UNSPECIFIED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_VERIFIED"] = 1] = "SENDOUT_SUBSTATE_VERIFIED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_SUBMITTED"] = 2] = "SENDOUT_SUBSTATE_SUBMITTED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_SCHEDULED"] = 3] = "SENDOUT_SUBSTATE_SCHEDULED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_DISPATCHED"] = 4] = "SENDOUT_SUBSTATE_DISPATCHED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_COMPLETED"] = 5] = "SENDOUT_SUBSTATE_COMPLETED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_CANCELLED"] = 6] = "SENDOUT_SUBSTATE_CANCELLED";
    SendoutSubstate[SendoutSubstate["SENDOUT_SUBSTATE_ABORTED"] = 7] = "SENDOUT_SUBSTATE_ABORTED";
    SendoutSubstate[SendoutSubstate["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SendoutSubstate || (SendoutSubstate = {}));
export function sendoutSubstateFromJSON(object) {
    switch (object) {
        case 0:
        case "SENDOUT_SUBSTATE_UNSPECIFIED":
            return SendoutSubstate.SENDOUT_SUBSTATE_UNSPECIFIED;
        case 1:
        case "SENDOUT_SUBSTATE_VERIFIED":
            return SendoutSubstate.SENDOUT_SUBSTATE_VERIFIED;
        case 2:
        case "SENDOUT_SUBSTATE_SUBMITTED":
            return SendoutSubstate.SENDOUT_SUBSTATE_SUBMITTED;
        case 3:
        case "SENDOUT_SUBSTATE_SCHEDULED":
            return SendoutSubstate.SENDOUT_SUBSTATE_SCHEDULED;
        case 4:
        case "SENDOUT_SUBSTATE_DISPATCHED":
            return SendoutSubstate.SENDOUT_SUBSTATE_DISPATCHED;
        case 5:
        case "SENDOUT_SUBSTATE_COMPLETED":
            return SendoutSubstate.SENDOUT_SUBSTATE_COMPLETED;
        case 6:
        case "SENDOUT_SUBSTATE_CANCELLED":
            return SendoutSubstate.SENDOUT_SUBSTATE_CANCELLED;
        case 7:
        case "SENDOUT_SUBSTATE_ABORTED":
            return SendoutSubstate.SENDOUT_SUBSTATE_ABORTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SendoutSubstate.UNRECOGNIZED;
    }
}
export function sendoutSubstateToJSON(object) {
    switch (object) {
        case SendoutSubstate.SENDOUT_SUBSTATE_UNSPECIFIED:
            return "SENDOUT_SUBSTATE_UNSPECIFIED";
        case SendoutSubstate.SENDOUT_SUBSTATE_VERIFIED:
            return "SENDOUT_SUBSTATE_VERIFIED";
        case SendoutSubstate.SENDOUT_SUBSTATE_SUBMITTED:
            return "SENDOUT_SUBSTATE_SUBMITTED";
        case SendoutSubstate.SENDOUT_SUBSTATE_SCHEDULED:
            return "SENDOUT_SUBSTATE_SCHEDULED";
        case SendoutSubstate.SENDOUT_SUBSTATE_DISPATCHED:
            return "SENDOUT_SUBSTATE_DISPATCHED";
        case SendoutSubstate.SENDOUT_SUBSTATE_COMPLETED:
            return "SENDOUT_SUBSTATE_COMPLETED";
        case SendoutSubstate.SENDOUT_SUBSTATE_CANCELLED:
            return "SENDOUT_SUBSTATE_CANCELLED";
        case SendoutSubstate.SENDOUT_SUBSTATE_ABORTED:
            return "SENDOUT_SUBSTATE_ABORTED";
        case SendoutSubstate.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseSendoutConfig() {
    return {
        inputCohortUrl: "",
        resultCohortUrl: "",
        inputType: 0,
        numRecipients: 0,
        flowDocumentId: "",
        flowDocumentVersionId: "",
        configOneof: undefined,
    };
}
export const SendoutConfig = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (message.inputCohortUrl !== "") {
            writer.uint32(10).string(message.inputCohortUrl);
        }
        if (message.resultCohortUrl !== "") {
            writer.uint32(18).string(message.resultCohortUrl);
        }
        if (message.inputType !== 0) {
            writer.uint32(24).int32(message.inputType);
        }
        if (message.numRecipients !== 0) {
            writer.uint32(32).int32(message.numRecipients);
        }
        if (message.flowDocumentId !== "") {
            writer.uint32(42).string(message.flowDocumentId);
        }
        if (message.flowDocumentVersionId !== "") {
            writer.uint32(50).string(message.flowDocumentVersionId);
        }
        if (((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "broadcast") {
            CampaignBroadcast.encode(message.configOneof.broadcast, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputCohortUrl = reader.string();
                    break;
                case 2:
                    message.resultCohortUrl = reader.string();
                    break;
                case 3:
                    message.inputType = reader.int32();
                    break;
                case 4:
                    message.numRecipients = reader.int32();
                    break;
                case 5:
                    message.flowDocumentId = reader.string();
                    break;
                case 6:
                    message.flowDocumentVersionId = reader.string();
                    break;
                case 21:
                    message.configOneof = {
                        $case: "broadcast",
                        broadcast: CampaignBroadcast.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputCohortUrl: isSet(object.inputCohortUrl)
                ? String(object.inputCohortUrl)
                : "",
            resultCohortUrl: isSet(object.resultCohortUrl)
                ? String(object.resultCohortUrl)
                : "",
            inputType: isSet(object.inputType)
                ? sendoutInputTypeFromJSON(object.inputType)
                : 0,
            numRecipients: isSet(object.numRecipients)
                ? Number(object.numRecipients)
                : 0,
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
            flowDocumentVersionId: isSet(object.flowDocumentVersionId)
                ? String(object.flowDocumentVersionId)
                : "",
            configOneof: isSet(object.broadcast)
                ? {
                    $case: "broadcast",
                    broadcast: CampaignBroadcast.fromJSON(object.broadcast),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        message.inputCohortUrl !== undefined &&
            (obj.inputCohortUrl = message.inputCohortUrl);
        message.resultCohortUrl !== undefined &&
            (obj.resultCohortUrl = message.resultCohortUrl);
        message.inputType !== undefined &&
            (obj.inputType = sendoutInputTypeToJSON(message.inputType));
        message.numRecipients !== undefined &&
            (obj.numRecipients = Math.round(message.numRecipients));
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        message.flowDocumentVersionId !== undefined &&
            (obj.flowDocumentVersionId = message.flowDocumentVersionId);
        ((_a = message.configOneof) === null || _a === void 0 ? void 0 : _a.$case) === "broadcast" &&
            (obj.broadcast = ((_b = message.configOneof) === null || _b === void 0 ? void 0 : _b.broadcast)
                ? CampaignBroadcast.toJSON((_c = message.configOneof) === null || _c === void 0 ? void 0 : _c.broadcast)
                : undefined);
        return obj;
    },
};
function createBaseCampaignOutcome() {
    return { outcomeOneof: undefined, executionDataOneof: undefined };
}
export const CampaignOutcome = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1") {
            ReportV1.encode(message.outcomeOneof.reportV1, writer.uint32(162).fork()).ldelim();
        }
        if (((_b = message.executionDataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "executionDataV1") {
            SendoutExecutionDataV1.encode(message.executionDataOneof.executionDataV1, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignOutcome();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 20:
                    message.outcomeOneof = {
                        $case: "reportV1",
                        reportV1: ReportV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 21:
                    message.executionDataOneof = {
                        $case: "executionDataV1",
                        executionDataV1: SendoutExecutionDataV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            outcomeOneof: isSet(object.reportV1)
                ? { $case: "reportV1", reportV1: ReportV1.fromJSON(object.reportV1) }
                : undefined,
            executionDataOneof: isSet(object.executionDataV1)
                ? {
                    $case: "executionDataV1",
                    executionDataV1: SendoutExecutionDataV1.fromJSON(object.executionDataV1),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1" &&
            (obj.reportV1 = ((_b = message.outcomeOneof) === null || _b === void 0 ? void 0 : _b.reportV1)
                ? ReportV1.toJSON((_c = message.outcomeOneof) === null || _c === void 0 ? void 0 : _c.reportV1)
                : undefined);
        ((_d = message.executionDataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "executionDataV1" &&
            (obj.executionDataV1 = ((_e = message.executionDataOneof) === null || _e === void 0 ? void 0 : _e.executionDataV1)
                ? SendoutExecutionDataV1.toJSON((_f = message.executionDataOneof) === null || _f === void 0 ? void 0 : _f.executionDataV1)
                : undefined);
        return obj;
    },
};
function createBaseCampaignStatus() {
    return { sendoutCount: 0, sentCount: 0, activeCount: 0 };
}
export const CampaignStatus = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sendoutCount !== 0) {
            writer.uint32(8).int32(message.sendoutCount);
        }
        if (message.sentCount !== 0) {
            writer.uint32(16).int32(message.sentCount);
        }
        if (message.activeCount !== 0) {
            writer.uint32(24).int32(message.activeCount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignStatus();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sendoutCount = reader.int32();
                    break;
                case 2:
                    message.sentCount = reader.int32();
                    break;
                case 3:
                    message.activeCount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sendoutCount: isSet(object.sendoutCount)
                ? Number(object.sendoutCount)
                : 0,
            sentCount: isSet(object.sentCount) ? Number(object.sentCount) : 0,
            activeCount: isSet(object.activeCount) ? Number(object.activeCount) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sendoutCount !== undefined &&
            (obj.sendoutCount = Math.round(message.sendoutCount));
        message.sentCount !== undefined &&
            (obj.sentCount = Math.round(message.sentCount));
        message.activeCount !== undefined &&
            (obj.activeCount = Math.round(message.activeCount));
        return obj;
    },
};
function createBaseSendoutOutcome() {
    return { outcomeOneof: undefined, executionDataOneof: undefined };
}
export const SendoutOutcome = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1") {
            ReportV1.encode(message.outcomeOneof.reportV1, writer.uint32(162).fork()).ldelim();
        }
        if (((_b = message.executionDataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "executionDataV1") {
            SendoutExecutionDataV1.encode(message.executionDataOneof.executionDataV1, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutOutcome();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 20:
                    message.outcomeOneof = {
                        $case: "reportV1",
                        reportV1: ReportV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 21:
                    message.executionDataOneof = {
                        $case: "executionDataV1",
                        executionDataV1: SendoutExecutionDataV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            outcomeOneof: isSet(object.reportV1)
                ? { $case: "reportV1", reportV1: ReportV1.fromJSON(object.reportV1) }
                : undefined,
            executionDataOneof: isSet(object.executionDataV1)
                ? {
                    $case: "executionDataV1",
                    executionDataV1: SendoutExecutionDataV1.fromJSON(object.executionDataV1),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.outcomeOneof) === null || _a === void 0 ? void 0 : _a.$case) === "reportV1" &&
            (obj.reportV1 = ((_b = message.outcomeOneof) === null || _b === void 0 ? void 0 : _b.reportV1)
                ? ReportV1.toJSON((_c = message.outcomeOneof) === null || _c === void 0 ? void 0 : _c.reportV1)
                : undefined);
        ((_d = message.executionDataOneof) === null || _d === void 0 ? void 0 : _d.$case) === "executionDataV1" &&
            (obj.executionDataV1 = ((_e = message.executionDataOneof) === null || _e === void 0 ? void 0 : _e.executionDataV1)
                ? SendoutExecutionDataV1.toJSON((_f = message.executionDataOneof) === null || _f === void 0 ? void 0 : _f.executionDataV1)
                : undefined);
        return obj;
    },
};
function createBaseCampaignBroadcast() {
    return {
        cohortCsv: "",
        audienceChannel: undefined,
        tagId: undefined,
        templateOneof: undefined,
    };
}
export const CampaignBroadcast = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e;
        if (message.cohortCsv !== "") {
            writer.uint32(10).string(message.cohortCsv);
        }
        if (message.audienceChannel !== undefined) {
            ChannelIdentifier.encode(message.audienceChannel, writer.uint32(18).fork()).ldelim();
        }
        if (message.tagId !== undefined) {
            StringValue.encode({ value: message.tagId }, writer.uint32(26).fork()).ldelim();
        }
        if (((_a = message.templateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcastTemplateV1") {
            WABroadcastTemplateV1.encode(message.templateOneof.waBroadcastTemplateV1, writer.uint32(170).fork()).ldelim();
        }
        if (((_b = message.templateOneof) === null || _b === void 0 ? void 0 : _b.$case) === "stbBroadcastTemplateV1") {
            STBBroadcastTemplateV1.encode(message.templateOneof.stbBroadcastTemplateV1, writer.uint32(178).fork()).ldelim();
        }
        if (((_c = message.templateOneof) === null || _c === void 0 ? void 0 : _c.$case) === "mtbBroadcastTemplateV1") {
            MTBBroadcastTemplateV1.encode(message.templateOneof.mtbBroadcastTemplateV1, writer.uint32(186).fork()).ldelim();
        }
        if (((_d = message.templateOneof) === null || _d === void 0 ? void 0 : _d.$case) === "mtbBroadcastConnectlyTemplateMessageV1") {
            MTBBroadcastConnectlyTemplateMessageV1.encode(message.templateOneof.mtbBroadcastConnectlyTemplateMessageV1, writer.uint32(194).fork()).ldelim();
        }
        if (((_e = message.templateOneof) === null || _e === void 0 ? void 0 : _e.$case) === "recommendationBroadcastV1") {
            RecommendationBroadcastV1.encode(message.templateOneof.recommendationBroadcastV1, writer.uint32(202).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignBroadcast();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cohortCsv = reader.string();
                    break;
                case 2:
                    message.audienceChannel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.tagId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 21:
                    message.templateOneof = {
                        $case: "waBroadcastTemplateV1",
                        waBroadcastTemplateV1: WABroadcastTemplateV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 22:
                    message.templateOneof = {
                        $case: "stbBroadcastTemplateV1",
                        stbBroadcastTemplateV1: STBBroadcastTemplateV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 23:
                    message.templateOneof = {
                        $case: "mtbBroadcastTemplateV1",
                        mtbBroadcastTemplateV1: MTBBroadcastTemplateV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 24:
                    message.templateOneof = {
                        $case: "mtbBroadcastConnectlyTemplateMessageV1",
                        mtbBroadcastConnectlyTemplateMessageV1: MTBBroadcastConnectlyTemplateMessageV1.decode(reader, reader.uint32()),
                    };
                    break;
                case 25:
                    message.templateOneof = {
                        $case: "recommendationBroadcastV1",
                        recommendationBroadcastV1: RecommendationBroadcastV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cohortCsv: isSet(object.cohortCsv) ? String(object.cohortCsv) : "",
            audienceChannel: isSet(object.audienceChannel)
                ? ChannelIdentifier.fromJSON(object.audienceChannel)
                : undefined,
            tagId: isSet(object.tagId) ? String(object.tagId) : undefined,
            templateOneof: isSet(object.waBroadcastTemplateV1)
                ? {
                    $case: "waBroadcastTemplateV1",
                    waBroadcastTemplateV1: WABroadcastTemplateV1.fromJSON(object.waBroadcastTemplateV1),
                }
                : isSet(object.stbBroadcastTemplateV1)
                    ? {
                        $case: "stbBroadcastTemplateV1",
                        stbBroadcastTemplateV1: STBBroadcastTemplateV1.fromJSON(object.stbBroadcastTemplateV1),
                    }
                    : isSet(object.mtbBroadcastTemplateV1)
                        ? {
                            $case: "mtbBroadcastTemplateV1",
                            mtbBroadcastTemplateV1: MTBBroadcastTemplateV1.fromJSON(object.mtbBroadcastTemplateV1),
                        }
                        : isSet(object.mtbBroadcastConnectlyTemplateMessageV1)
                            ? {
                                $case: "mtbBroadcastConnectlyTemplateMessageV1",
                                mtbBroadcastConnectlyTemplateMessageV1: MTBBroadcastConnectlyTemplateMessageV1.fromJSON(object.mtbBroadcastConnectlyTemplateMessageV1),
                            }
                            : isSet(object.recommendationBroadcastV1)
                                ? {
                                    $case: "recommendationBroadcastV1",
                                    recommendationBroadcastV1: RecommendationBroadcastV1.fromJSON(object.recommendationBroadcastV1),
                                }
                                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        const obj = {};
        message.cohortCsv !== undefined && (obj.cohortCsv = message.cohortCsv);
        message.audienceChannel !== undefined &&
            (obj.audienceChannel = message.audienceChannel
                ? ChannelIdentifier.toJSON(message.audienceChannel)
                : undefined);
        message.tagId !== undefined && (obj.tagId = message.tagId);
        ((_a = message.templateOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waBroadcastTemplateV1" &&
            (obj.waBroadcastTemplateV1 = ((_b = message.templateOneof) === null || _b === void 0 ? void 0 : _b.waBroadcastTemplateV1)
                ? WABroadcastTemplateV1.toJSON((_c = message.templateOneof) === null || _c === void 0 ? void 0 : _c.waBroadcastTemplateV1)
                : undefined);
        ((_d = message.templateOneof) === null || _d === void 0 ? void 0 : _d.$case) === "stbBroadcastTemplateV1" &&
            (obj.stbBroadcastTemplateV1 = ((_e = message.templateOneof) === null || _e === void 0 ? void 0 : _e.stbBroadcastTemplateV1)
                ? STBBroadcastTemplateV1.toJSON((_f = message.templateOneof) === null || _f === void 0 ? void 0 : _f.stbBroadcastTemplateV1)
                : undefined);
        ((_g = message.templateOneof) === null || _g === void 0 ? void 0 : _g.$case) === "mtbBroadcastTemplateV1" &&
            (obj.mtbBroadcastTemplateV1 = ((_h = message.templateOneof) === null || _h === void 0 ? void 0 : _h.mtbBroadcastTemplateV1)
                ? MTBBroadcastTemplateV1.toJSON((_j = message.templateOneof) === null || _j === void 0 ? void 0 : _j.mtbBroadcastTemplateV1)
                : undefined);
        ((_k = message.templateOneof) === null || _k === void 0 ? void 0 : _k.$case) === "mtbBroadcastConnectlyTemplateMessageV1" &&
            (obj.mtbBroadcastConnectlyTemplateMessageV1 = ((_l = message.templateOneof) === null || _l === void 0 ? void 0 : _l.mtbBroadcastConnectlyTemplateMessageV1)
                ? MTBBroadcastConnectlyTemplateMessageV1.toJSON((_m = message.templateOneof) === null || _m === void 0 ? void 0 : _m.mtbBroadcastConnectlyTemplateMessageV1)
                : undefined);
        ((_o = message.templateOneof) === null || _o === void 0 ? void 0 : _o.$case) === "recommendationBroadcastV1" &&
            (obj.recommendationBroadcastV1 = ((_p = message.templateOneof) === null || _p === void 0 ? void 0 : _p.recommendationBroadcastV1)
                ? RecommendationBroadcastV1.toJSON((_q = message.templateOneof) === null || _q === void 0 ? void 0 : _q.recommendationBroadcastV1)
                : undefined);
        return obj;
    },
};
function createBaseRecommendationBroadcastV1() {
    return {
        audienceId: "",
        attributionId: "",
        integrationId: "",
        gsheetsSpreadsheetId: "",
    };
}
export const RecommendationBroadcastV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.audienceId !== "") {
            writer.uint32(10).string(message.audienceId);
        }
        if (message.attributionId !== "") {
            writer.uint32(18).string(message.attributionId);
        }
        if (message.integrationId !== "") {
            writer.uint32(26).string(message.integrationId);
        }
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(42).string(message.gsheetsSpreadsheetId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecommendationBroadcastV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.audienceId = reader.string();
                    break;
                case 2:
                    message.attributionId = reader.string();
                    break;
                case 3:
                    message.integrationId = reader.string();
                    break;
                case 5:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            audienceId: isSet(object.audienceId) ? String(object.audienceId) : "",
            attributionId: isSet(object.attributionId)
                ? String(object.attributionId)
                : "",
            integrationId: isSet(object.integrationId)
                ? String(object.integrationId)
                : "",
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.audienceId !== undefined && (obj.audienceId = message.audienceId);
        message.attributionId !== undefined &&
            (obj.attributionId = message.attributionId);
        message.integrationId !== undefined &&
            (obj.integrationId = message.integrationId);
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        return obj;
    },
};
function createBaseWABroadcastTemplateV1() {
    return {
        waMessageTemplateId: "",
        gsheetsSpreadsheetId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
        messageTemplate: undefined,
    };
}
export const WABroadcastTemplateV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(18).string(message.gsheetsSpreadsheetId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(26).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(34).string(message.waMessageTemplateLanguage);
        }
        if (message.messageTemplate !== undefined) {
            MessageTemplate.encode(message.messageTemplate, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWABroadcastTemplateV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 2:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 4:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                case 5:
                    message.messageTemplate = MessageTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
            messageTemplate: isSet(object.messageTemplate)
                ? MessageTemplate.fromJSON(object.messageTemplate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate
                ? MessageTemplate.toJSON(message.messageTemplate)
                : undefined);
        return obj;
    },
};
function createBaseSTBBroadcastTemplateV1() {
    return { gsheetsSpreadsheetId: "", messageTemplates: [] };
}
export const STBBroadcastTemplateV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(10).string(message.gsheetsSpreadsheetId);
        }
        for (const v of message.messageTemplates) {
            STBBroadcastTemplateV1_MessageTemplateIdentifier.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastTemplateV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 2:
                    message.messageTemplates.push(STBBroadcastTemplateV1_MessageTemplateIdentifier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            messageTemplates: Array.isArray(object === null || object === void 0 ? void 0 : object.messageTemplates)
                ? object.messageTemplates.map((e) => STBBroadcastTemplateV1_MessageTemplateIdentifier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        if (message.messageTemplates) {
            obj.messageTemplates = message.messageTemplates.map((e) => e
                ? STBBroadcastTemplateV1_MessageTemplateIdentifier.toJSON(e)
                : undefined);
        }
        else {
            obj.messageTemplates = [];
        }
        return obj;
    },
};
function createBaseSTBBroadcastTemplateV1_MessageTemplateIdentifier() {
    return {
        waMessageTemplateId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
        stbName: "",
        messageTemplate: undefined,
    };
}
export const STBBroadcastTemplateV1_MessageTemplateIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.waMessageTemplateId !== "") {
            writer.uint32(10).string(message.waMessageTemplateId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(18).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(26).string(message.waMessageTemplateLanguage);
        }
        if (message.stbName !== "") {
            writer.uint32(34).string(message.stbName);
        }
        if (message.messageTemplate !== undefined) {
            MessageTemplate.encode(message.messageTemplate, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSTBBroadcastTemplateV1_MessageTemplateIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 2:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                case 4:
                    message.stbName = reader.string();
                    break;
                case 5:
                    message.messageTemplate = MessageTemplate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
            stbName: isSet(object.stbName) ? String(object.stbName) : "",
            messageTemplate: isSet(object.messageTemplate)
                ? MessageTemplate.fromJSON(object.messageTemplate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        message.stbName !== undefined && (obj.stbName = message.stbName);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate
                ? MessageTemplate.toJSON(message.messageTemplate)
                : undefined);
        return obj;
    },
};
function createBaseMTBBroadcastTemplateV1() {
    return { gsheetsSpreadsheetId: "", nodeHeadId: "", nodes: {} };
}
export const MTBBroadcastTemplateV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(10).string(message.gsheetsSpreadsheetId);
        }
        if (message.nodeHeadId !== "") {
            writer.uint32(18).string(message.nodeHeadId);
        }
        Object.entries(message.nodes).forEach(([key, value]) => {
            MTBBroadcastTemplateV1_NodesEntry.encode({ key: key, value }, writer.uint32(26).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastTemplateV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 2:
                    message.nodeHeadId = reader.string();
                    break;
                case 3:
                    const entry3 = MTBBroadcastTemplateV1_NodesEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.nodes[entry3.key] = entry3.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            nodeHeadId: isSet(object.nodeHeadId) ? String(object.nodeHeadId) : "",
            nodes: isObject(object.nodes)
                ? Object.entries(object.nodes).reduce((acc, [key, value]) => {
                    acc[key] = MTBBroadcastTemplateV1_MTBBroadcastNode.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        message.nodeHeadId !== undefined && (obj.nodeHeadId = message.nodeHeadId);
        obj.nodes = {};
        if (message.nodes) {
            Object.entries(message.nodes).forEach(([k, v]) => {
                obj.nodes[k] = MTBBroadcastTemplateV1_MTBBroadcastNode.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseMTBBroadcastTemplateV1_MTBBroadcastNode() {
    return {
        nodeId: "",
        waMessageTemplateId: "",
        waMessageTemplateName: "",
        waMessageTemplateLanguage: "",
        mtbName: "",
        messageTemplate: undefined,
        nextNodes: [],
    };
}
export const MTBBroadcastTemplateV1_MTBBroadcastNode = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nodeId !== "") {
            writer.uint32(10).string(message.nodeId);
        }
        if (message.waMessageTemplateId !== "") {
            writer.uint32(18).string(message.waMessageTemplateId);
        }
        if (message.waMessageTemplateName !== "") {
            writer.uint32(26).string(message.waMessageTemplateName);
        }
        if (message.waMessageTemplateLanguage !== "") {
            writer.uint32(34).string(message.waMessageTemplateLanguage);
        }
        if (message.mtbName !== "") {
            writer.uint32(42).string(message.mtbName);
        }
        if (message.messageTemplate !== undefined) {
            MessageTemplate.encode(message.messageTemplate, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.nextNodes) {
            writer.uint32(58).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastTemplateV1_MTBBroadcastNode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeId = reader.string();
                    break;
                case 2:
                    message.waMessageTemplateId = reader.string();
                    break;
                case 3:
                    message.waMessageTemplateName = reader.string();
                    break;
                case 4:
                    message.waMessageTemplateLanguage = reader.string();
                    break;
                case 5:
                    message.mtbName = reader.string();
                    break;
                case 6:
                    message.messageTemplate = MessageTemplate.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.nextNodes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeId: isSet(object.nodeId) ? String(object.nodeId) : "",
            waMessageTemplateId: isSet(object.waMessageTemplateId)
                ? String(object.waMessageTemplateId)
                : "",
            waMessageTemplateName: isSet(object.waMessageTemplateName)
                ? String(object.waMessageTemplateName)
                : "",
            waMessageTemplateLanguage: isSet(object.waMessageTemplateLanguage)
                ? String(object.waMessageTemplateLanguage)
                : "",
            mtbName: isSet(object.mtbName) ? String(object.mtbName) : "",
            messageTemplate: isSet(object.messageTemplate)
                ? MessageTemplate.fromJSON(object.messageTemplate)
                : undefined,
            nextNodes: Array.isArray(object === null || object === void 0 ? void 0 : object.nextNodes)
                ? object.nextNodes.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.nodeId !== undefined && (obj.nodeId = message.nodeId);
        message.waMessageTemplateId !== undefined &&
            (obj.waMessageTemplateId = message.waMessageTemplateId);
        message.waMessageTemplateName !== undefined &&
            (obj.waMessageTemplateName = message.waMessageTemplateName);
        message.waMessageTemplateLanguage !== undefined &&
            (obj.waMessageTemplateLanguage = message.waMessageTemplateLanguage);
        message.mtbName !== undefined && (obj.mtbName = message.mtbName);
        message.messageTemplate !== undefined &&
            (obj.messageTemplate = message.messageTemplate
                ? MessageTemplate.toJSON(message.messageTemplate)
                : undefined);
        if (message.nextNodes) {
            obj.nextNodes = message.nextNodes.map((e) => e);
        }
        else {
            obj.nextNodes = [];
        }
        return obj;
    },
};
function createBaseMTBBroadcastTemplateV1_NodesEntry() {
    return { key: "", value: undefined };
}
export const MTBBroadcastTemplateV1_NodesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MTBBroadcastTemplateV1_MTBBroadcastNode.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastTemplateV1_NodesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MTBBroadcastTemplateV1_MTBBroadcastNode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? MTBBroadcastTemplateV1_MTBBroadcastNode.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? MTBBroadcastTemplateV1_MTBBroadcastNode.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseMTBBroadcastInputNode() {
    return { nodeId: "", mtbName: "", nextNodes: [], inputOneof: undefined };
}
export const MTBBroadcastInputNode = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.nodeId !== "") {
            writer.uint32(10).string(message.nodeId);
        }
        if (message.mtbName !== "") {
            writer.uint32(18).string(message.mtbName);
        }
        for (const v of message.nextNodes) {
            writer.uint32(26).string(v);
        }
        if (((_a = message.inputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waMessageTemplateIdentifier") {
            WAMessageTemplateIdentifier.encode(message.inputOneof.waMessageTemplateIdentifier, writer.uint32(34).fork()).ldelim();
        }
        if (((_b = message.inputOneof) === null || _b === void 0 ? void 0 : _b.$case) === "messageTemplateInput") {
            MessageTemplateInput.encode(message.inputOneof.messageTemplateInput, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastInputNode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeId = reader.string();
                    break;
                case 2:
                    message.mtbName = reader.string();
                    break;
                case 3:
                    message.nextNodes.push(reader.string());
                    break;
                case 4:
                    message.inputOneof = {
                        $case: "waMessageTemplateIdentifier",
                        waMessageTemplateIdentifier: WAMessageTemplateIdentifier.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.inputOneof = {
                        $case: "messageTemplateInput",
                        messageTemplateInput: MessageTemplateInput.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nodeId: isSet(object.nodeId) ? String(object.nodeId) : "",
            mtbName: isSet(object.mtbName) ? String(object.mtbName) : "",
            nextNodes: Array.isArray(object === null || object === void 0 ? void 0 : object.nextNodes)
                ? object.nextNodes.map((e) => String(e))
                : [],
            inputOneof: isSet(object.waMessageTemplateIdentifier)
                ? {
                    $case: "waMessageTemplateIdentifier",
                    waMessageTemplateIdentifier: WAMessageTemplateIdentifier.fromJSON(object.waMessageTemplateIdentifier),
                }
                : isSet(object.messageTemplateInput)
                    ? {
                        $case: "messageTemplateInput",
                        messageTemplateInput: MessageTemplateInput.fromJSON(object.messageTemplateInput),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        message.nodeId !== undefined && (obj.nodeId = message.nodeId);
        message.mtbName !== undefined && (obj.mtbName = message.mtbName);
        if (message.nextNodes) {
            obj.nextNodes = message.nextNodes.map((e) => e);
        }
        else {
            obj.nextNodes = [];
        }
        ((_a = message.inputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "waMessageTemplateIdentifier" &&
            (obj.waMessageTemplateIdentifier = ((_b = message.inputOneof) === null || _b === void 0 ? void 0 : _b.waMessageTemplateIdentifier)
                ? WAMessageTemplateIdentifier.toJSON((_c = message.inputOneof) === null || _c === void 0 ? void 0 : _c.waMessageTemplateIdentifier)
                : undefined);
        ((_d = message.inputOneof) === null || _d === void 0 ? void 0 : _d.$case) === "messageTemplateInput" &&
            (obj.messageTemplateInput = ((_e = message.inputOneof) === null || _e === void 0 ? void 0 : _e.messageTemplateInput)
                ? MessageTemplateInput.toJSON((_f = message.inputOneof) === null || _f === void 0 ? void 0 : _f.messageTemplateInput)
                : undefined);
        return obj;
    },
};
function createBaseMTBBroadcastConnectlyTemplateMessageV1() {
    return { gsheetsSpreadsheetId: "", nodeHeadId: "", nodes: {} };
}
export const MTBBroadcastConnectlyTemplateMessageV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.gsheetsSpreadsheetId !== "") {
            writer.uint32(10).string(message.gsheetsSpreadsheetId);
        }
        if (message.nodeHeadId !== "") {
            writer.uint32(18).string(message.nodeHeadId);
        }
        Object.entries(message.nodes).forEach(([key, value]) => {
            MTBBroadcastConnectlyTemplateMessageV1_NodesEntry.encode({ key: key, value }, writer.uint32(26).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastConnectlyTemplateMessageV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.gsheetsSpreadsheetId = reader.string();
                    break;
                case 2:
                    message.nodeHeadId = reader.string();
                    break;
                case 3:
                    const entry3 = MTBBroadcastConnectlyTemplateMessageV1_NodesEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.nodes[entry3.key] = entry3.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            gsheetsSpreadsheetId: isSet(object.gsheetsSpreadsheetId)
                ? String(object.gsheetsSpreadsheetId)
                : "",
            nodeHeadId: isSet(object.nodeHeadId) ? String(object.nodeHeadId) : "",
            nodes: isObject(object.nodes)
                ? Object.entries(object.nodes).reduce((acc, [key, value]) => {
                    acc[key] = MTBBroadcastInputNode.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.gsheetsSpreadsheetId !== undefined &&
            (obj.gsheetsSpreadsheetId = message.gsheetsSpreadsheetId);
        message.nodeHeadId !== undefined && (obj.nodeHeadId = message.nodeHeadId);
        obj.nodes = {};
        if (message.nodes) {
            Object.entries(message.nodes).forEach(([k, v]) => {
                obj.nodes[k] = MTBBroadcastInputNode.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseMTBBroadcastConnectlyTemplateMessageV1_NodesEntry() {
    return { key: "", value: undefined };
}
export const MTBBroadcastConnectlyTemplateMessageV1_NodesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MTBBroadcastInputNode.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMTBBroadcastConnectlyTemplateMessageV1_NodesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MTBBroadcastInputNode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? MTBBroadcastInputNode.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? MTBBroadcastInputNode.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseReportV1() {
    return { url: "" };
}
export const ReportV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReportV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        return obj;
    },
};
function createBaseSendoutExecutionDataV1() {
    return { numDispatched: 0, numRecipients: 0 };
}
export const SendoutExecutionDataV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.numDispatched !== 0) {
            writer.uint32(8).uint32(message.numDispatched);
        }
        if (message.numRecipients !== 0) {
            writer.uint32(16).uint32(message.numRecipients);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendoutExecutionDataV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.numDispatched = reader.uint32();
                    break;
                case 2:
                    message.numRecipients = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            numDispatched: isSet(object.numDispatched)
                ? Number(object.numDispatched)
                : 0,
            numRecipients: isSet(object.numRecipients)
                ? Number(object.numRecipients)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.numDispatched !== undefined &&
            (obj.numDispatched = Math.round(message.numDispatched));
        message.numRecipients !== undefined &&
            (obj.numRecipients = Math.round(message.numRecipients));
        return obj;
    },
};
function createBaseSendSendoutNow() {
    return {};
}
export const SendSendoutNow = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendSendoutNow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseScheduleSendout() {
    return { scheduledAt: undefined };
}
export const ScheduleSendout = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.scheduledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.scheduledAt), writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScheduleSendout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.scheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            scheduledAt: isSet(object.scheduledAt)
                ? String(object.scheduledAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.scheduledAt !== undefined &&
            (obj.scheduledAt = message.scheduledAt);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
