export const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/msword',
  'application/xml',
  'text/plain',
  'text/rtf',
  'text/xml',
  'text/csv',
  'text/html',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
];
export const MAX_FILE_SIZE = 100; // MB
