import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { SMBAutoReplyResponse, SMBAutoReplyUrlParameters, SMB_AUTO_REPLY_QUERY_URL } from './types';

export const useSMBAutoReplyQuery = <TData = SMBAutoReplyResponse>(
  options: Omit<
    UseQueryOptions<SMBAutoReplyResponse, unknown, TData, readonly ['SMBAutoReply']>,
    'initialData' | 'queryFn' | 'queryKey'
  > &
    SMBAutoReplyUrlParameters,
) =>
  useQuery(
    ['SMBAutoReply'],
    () =>
      api.smbV1(SMB_AUTO_REPLY_QUERY_URL, 'post', {
        path: { businessId: options.businessId },
      }),
    options,
  );
