import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { SignUpDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectSignUpState } from '../../state/signUpSlice';
export const SignUpComponentId = '3770d22e-4577-4f9b-a139-87df357b57fb';
const SignUp = ({ token }) => {
    const di = useContext(SignUpDIContext);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(SignUpComponentId);
    const state = useFeatureSelector(selectSignUpState);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { state: state, token: token }) });
};
export default SignUp;
