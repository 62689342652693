import React, { FC, useEffect } from 'react';
import { NotificationSeverity, NotificationSurface, NotificationAction } from '@connectlyai-sdks/notification';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { Alert, AlertColor, Button, Portal, Snackbar, SnackbarCloseReason } from '@connectlyai-tenets/ui-styled-web';
import { NotificationProps } from '../../api';
import AudioNotifications from './AudioNotifications';
import TabNotifications from './TabNotifications';

const AUTOHIDE_DURATION_DEFAULT_MS = 6000;

const tabNotifications = new TabNotifications();
const audioNotifications = new AudioNotifications();

interface SnackbarMessage {
  message: string;
  title?: string;
  key: number;
  color: AlertColor;
  action?: NotificationAction;
}

function mapSeverityToColor(severity: NotificationSeverity): AlertColor {
  switch (severity) {
    case NotificationSeverity.INFO:
      return 'info';
    case NotificationSeverity.SUCCESS:
      return 'success';
    case NotificationSeverity.WARNING:
      return 'warning';
    case NotificationSeverity.ERROR:
      return 'error';
    case NotificationSeverity.UNKNOWN:
      return 'info';
    default:
      return assertUnreachable(severity);
  }
}

/**
 * Notification main component.
 */
const Notification: FC<NotificationProps> = ({ notificationServiceProvider }: NotificationProps) => {
  // Small message buffer to hold notification until the ui animations can cycle through them.
  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  // Open state of the snackbar.
  const [open, setOpen] = React.useState(false);
  // Currently displayed notification or undefined if none displayed.
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);

  const notificationService = notificationServiceProvider();
  useEffect(() => {
    const sub = notificationService.notifications().subscribe((request) => {
      const { surface, notification } = request;

      switch (surface) {
        case NotificationSurface.SNACKBAR: {
          const { severity, message, title, action } = notification;
          setSnackPack((prev) => [
            ...prev,
            {
              key: new Date().getTime(),
              title,
              message,
              color: mapSeverityToColor(severity),
              action,
            },
          ]);
          break;
        }
        case NotificationSurface.TAB: {
          const { message } = notification;
          switch (message) {
            case 'MESSAGE_INCOMING': {
              tabNotifications.update();
              audioNotifications.update();
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  });

  useEffect(() => {
    if (snackPack.length > 0 && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length > 0 && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event: Event | React.SyntheticEvent<Element, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleAlertClose = (_event: React.SyntheticEvent<Element, Event>) => {
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Portal>
      <Snackbar
        key={messageInfo?.key}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={AUTOHIDE_DURATION_DEFAULT_MS}
        onClose={handleClose}
        TransitionProps={{
          onExited: handleExited,
        }}
      >
        <Alert
          key={messageInfo?.message}
          title={messageInfo?.title}
          severity={messageInfo?.color}
          color={messageInfo?.color}
          variant="standard"
          // TODO: refactor this flow a bit more e.g. font size etc.
          action={
            messageInfo?.action && (
              <Button color="inherit" size="small" onClick={messageInfo?.action.callback}>
                {messageInfo?.action.text}
              </Button>
            )
          }
          onClose={handleAlertClose}
        >
          {messageInfo?.message || ''}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default Notification;
