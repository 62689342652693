import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
export const NavigationTabs = ({ currentPathname, routes }) => {
    const handleChange = useCallback((event, newValue) => {
        const newRoute = routes[Number(newValue)];
        if (newRoute.onClick) {
            newRoute.onClick();
        }
    }, [routes]);
    const activeIndex = useMemo(() => {
        let matchIndex = -1;
        if (!routes) {
            return null;
        }
        matchIndex = routes.findIndex((route) => currentPathname === route.pathname);
        if (matchIndex === -1) {
            let bestMatch = [-1, 0];
            routes.forEach((route, index) => {
                if (currentPathname.startsWith(route.pathname) && route.pathname.length > bestMatch[1]) {
                    bestMatch = [index, route.pathname.length];
                }
            });
            if (bestMatch[0] !== -1) {
                return bestMatch[0];
            }
            return 0;
        }
        return matchIndex;
    }, [currentPathname, routes]);
    return (_jsx(Tabs, { value: activeIndex, onChange: handleChange, children: routes.map((route) => {
            return _jsx(Tab, { label: route.display }, route.display);
        }) }));
};
