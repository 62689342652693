import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Button,
  CloseIcon,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  FormControl,
} from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderItem } from '@components/TemplateBuilderItem';
import { HideableAlert } from '@components/HideableAlert';
import { FlowContext } from '../../../../contexts';
import { TimeDelayDurationOptions, TimeDelayDurationType, TimeDelayNodeUIState } from './types';
import { labels as labelsFn } from './labels';
import { useNodeUIState } from '../../hooks/useNodeUIState';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';

const useTimeDelayNodeEditor = () => {
  const { resetSelection } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);
  const { nodeUIState, setNodeUIState } = useNodeUIState<TimeDelayNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_TIME_DELAY',
  });

  const handleSave = useCallback(() => {
    // TODO save settings
    resetSelection();
  }, [resetSelection]);

  const { t } = useTranslation('translation', { keyPrefix: '' });
  const labels = useMemo(() => labelsFn(t), [t]);

  const handleChangeType = useCallback(
    (e: SelectChangeEvent) => {
      const delayType = TimeDelayDurationType[e.target.value as keyof typeof TimeDelayDurationType];
      const newState: TimeDelayNodeUIState = {
        ...nodeUIState,
        delayType,
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const handleChangeNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let delayNumberError;
      let newState: TimeDelayNodeUIState;
      const delayNumberInput = e.target.value;
      if (!delayNumberInput.match(/[0-9]+/g)) {
        delayNumberError = 'Must contain only numbers (0-9)';
        newState = {
          ...nodeUIState,
          delayNumberInput,
          delayNumberError,
        };
      } else {
        const delayNumber = parseInt(delayNumberInput, 10);
        newState = {
          ...nodeUIState,
          delayNumber,
          delayNumberInput,
          delayNumberError,
        };
      }

      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  return {
    labels,
    nodeUIState,
    handleChangeNumber,
    handleChangeType,
    handleSave,
  };
};

export const TimeDelayNodeEditor = () => {
  const { labels, nodeUIState, handleChangeNumber, handleChangeType, handleSave } = useTimeDelayNodeEditor();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          flex: '0 0 auto',
          px: isLargeScreen ? 3 : 2,
          mt: isLargeScreen ? 3.5 : 2,
          mb: isLargeScreen ? 2 : 1,
        }}
      >
        <Label variant="h6">{labels.title}</Label>
        <Box>
          <IconButton aria-label="close" onClick={handleSave}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 3,
        }}
      >
        <TemplateBuilderItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          <Label variant="h7">{labels.delayTitle}</Label>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: SIZE_SPACING_INTER_COMPONENT,
            }}
          >
            <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
              <TextField
                id="time-delay-node-number"
                autoComplete="off"
                placeholder="10"
                error={Boolean(nodeUIState.delayNumberError)}
                helperText={nodeUIState.delayNumberError}
                fullWidth
                value={nodeUIState.delayNumberInput}
                onChange={handleChangeNumber}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': { borderRadius: '10px' },
                }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
              <Select
                id="time-delay-node-type"
                fullWidth
                variant="outlined"
                value={nodeUIState.delayType}
                onChange={handleChangeType}
                sx={{
                  borderRadius: '10px',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
              >
                {Object.keys(TimeDelayDurationOptions).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Label variant="caption" color="textSecondary">
            {labels.tip}
          </Label>
        </TemplateBuilderItem>
        <HideableAlert
          storageKey="tooltip-time-delay-condition-interaction"
          title={labels.conditionInteractionTitle}
          sx={{ mt: 3 }}
        >
          {labels.conditionInteractionHint}
        </HideableAlert>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          p: 3,
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="contained" onClick={handleSave}>
          {labels.save}
        </Button>
      </Box>
    </Box>
  );
};
