import React, { FC } from 'react';
import { Label } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';

export const CardCounter: FC<{ current: number; limit: number }> = ({ current, limit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'carouselBuilder' });
  return (
    <Label variant="subtitle2" sx={{ fontWeight: 700 }}>
      {t('cardCounterTitle')} {current}/{limit}
    </Label>
  );
};
