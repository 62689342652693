import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { SOFIA_DOCUMENTS_URL, SofiaDocumentsUrlParameters, SofiaDocumentsResponse } from './types';

export const useSofiaDocuments = <TData = SofiaDocumentsResponse>({
  businessId,
  ...options
}: Omit<
  UseQueryOptions<SofiaDocumentsResponse, unknown, TData, readonly [target: 'sofia-documents', businessId: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  SofiaDocumentsUrlParameters) => {
  return useQuery(
    ['sofia-documents', businessId],
    () => api.sofiaV1(SOFIA_DOCUMENTS_URL, 'post', { path: { 'input.businessId': businessId } }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
