export const purple = {
    50: '#FAF5FF',
    100: '#F3E8FF',
    200: '#E9D5FF',
    300: '#D8B4FE',
    400: '#C084FC',
    500: '#A855F7',
    600: '#9333EA',
    700: '#7E22CE',
    800: '#6B21A8',
    900: '#581C87',
    A100: '#F8E7FF',
    A200: '#D05BFF',
    A400: '#AE3AFF',
    A700: '#9C1AFF',
};
