import React, { FC, PropsWithChildren } from 'react';
import { Box, InfoIcon, Label, Switch, Tooltip, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderTitleProps } from './types';

const Optional: FC<PropsWithChildren> = ({ children }) => (
  <Label
    component="span"
    sx={{
      ml: 1,
      py: 0.5,
      px: 1,
      backgroundColor: 'grey.100',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.4px',
      fontWeight: 'fontWeightBold',
    }}
  >
    {children}
  </Label>
);

const TitleOptional: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Box>
);

export const TemplateBuilderTitle: FC<PropsWithChildren<TemplateBuilderTitleProps>> = ({
  children,
  icon,
  hint,
  optional,
  showOptional = true,
  sx = [],
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Label
      variant="subtitle2"
      component="div"
      sx={[
        {
          flex: '1 1 auto',
          minWidth: 0,
          display: 'inline-flex',
          alignItems: 'center',
          userSelect: 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }}>
        {icon}
        <span>{children}</span>
        {hint && (
          <Tooltip title={hint || ''} arrow>
            <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
          </Tooltip>
        )}
      </Box>
      {optional && (
        <TitleOptional>
          {isLargeScreen && showOptional ? <Optional>OPTIONAL</Optional> : <Box sx={{ flex: '1 1 auto' }} />}
          <Switch checked={optional.checked} onChange={optional.onChange} sx={{ mr: -1.5 }} />
        </TitleOptional>
      )}
    </Label>
  );
};
