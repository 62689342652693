import React from 'react';
import { useAtom } from 'jotai';
import { savePreviewedFileAtom, displayRequirementsAtom } from '@atoms/settings';
import { Label, WhatsAppIcon, SaveActionCard } from '@connectlyai-tenets/ui-styled-web';
import { WhatsappBusinessProfilePicture } from '@components';
import { WhatsappBusinessProfileProps } from './types';

const useWhatsappBusinessProfile = () => {
  const [displayRequirements, setDisplayRequirements] = useAtom(displayRequirementsAtom);
  const [savePreviewedFile, setSavePreviewedFile] = useAtom(savePreviewedFileAtom);

  const handleSave = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    setSavePreviewedFile(true);
  };
  return {
    displayRequirements,
    setDisplayRequirements,
    savePreviewedFile,
    setSavePreviewedFile,
    handleSave,
  };
};

export const WhatsappBusinessProfile = ({ readOnly }: WhatsappBusinessProfileProps) => {
  const { displayRequirements, setDisplayRequirements, savePreviewedFile, setSavePreviewedFile, handleSave } =
    useWhatsappBusinessProfile();

  return (
    <SaveActionCard
      onSubmit={readOnly ? undefined : handleSave}
      icon={<WhatsAppIcon style={{ width: 24, height: 24 }} />}
      title="Whatsapp Business Profile"
    >
      <WhatsappBusinessProfilePicture
        supportPreview
        readOnly={readOnly}
        savePreviewedFile={savePreviewedFile}
        setSavePreviewedFile={setSavePreviewedFile}
        handleChangePictureSuccess={() => {
          setDisplayRequirements(false);
        }}
        handleChangePictureError={() => {
          setDisplayRequirements(true);
        }}
      />
      {displayRequirements && (
        <Label variant="body2" color="error" sx={{ mt: 1 }}>
          Please ensure that the uploaded image is a square with dimensions of at least 400x400 pixels.
        </Label>
      )}
    </SaveActionCard>
  );
};
