import { Box, Button, ErrorOutlineIcon, InfoIcon, Label, Tooltip, useTheme } from '@connectlyai-tenets/ui-styled-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AbandonedCartAnalytics } from '@components/AbandonedCartAnalytics';
import { DropdownToggle } from '@components/DropdownToggle';
import { selectBusinessId, useMeData } from '@hooks';
import { AbandonedCartBodyProps } from './types';
import { SIZE_SPACING_ANNOTATION } from '../../ui-theme';
import { MessageStatus } from '../../utils';

export const AbandonedCartBody = ({
  language,
  integrationId,
  discount,
  status,
  discountAmount,
  isActivated,
  isDeactivating,
  businessChannelId,
  onOpen,
  onChangeDeactivatePopup,
}: AbandonedCartBodyProps) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const theme = useTheme();

  const { t } = useTranslation('translation', { keyPrefix: 'welcome.shopifyAbandonedCart' });
  return (
    <>
      {discount?.discountCode && (
        <Box
          sx={{
            background: theme.palette.common.black,
            borderRadius: '16px',
            px: 2,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            gap: SIZE_SPACING_ANNOTATION,
            color: 'white',
          }}
        >
          <Label variant="caption">{`${t('editIntegration.discount')}: ${discountAmount}`}</Label>
        </Box>
      )}
      {isActivated && (
        <AbandonedCartAnalytics
          businessId={businessId}
          integrationId={integrationId}
          businessChannelId={businessChannelId}
        />
      )}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <DropdownToggle
          onChangeActive={onChangeDeactivatePopup}
          isActive={!!language}
          disabled={isDeactivating}
          messageStatus={status}
        />
        {status === MessageStatus.PENDING && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: '10px',
              px: 2,
              py: 1,
            }}
          >
            <Label variant="body2">{t('pendingMessage')}</Label>
            <Tooltip arrow placement="top" title={t('pendingMessageHint')}>
              <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
            </Tooltip>
          </Box>
        )}
        {status === MessageStatus.REJECTED && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: '10px',
              px: 2,
              py: 1,
            }}
          >
            <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />
            <Label variant="body2">{t('rejectedMessage')}</Label>
            <Tooltip arrow placement="top" title={t('rejectedMessageHint')}>
              <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
            </Tooltip>
          </Box>
        )}
        {status === MessageStatus.PARTIALLY_APPROVED && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: '10px',
              px: 2,
              py: 1,
            }}
          >
            <ErrorOutlineIcon sx={{ color: theme.palette.warning.light }} />
            <Label variant="body2">{t('warningMessage')}</Label>
            <Tooltip arrow placement="top" title={t('warningMessageHint')}>
              <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
            </Tooltip>
          </Box>
        )}
        <Button variant="text" onClick={onOpen} disabled={isDeactivating}>
          {t('edit')}
        </Button>
      </Box>
    </>
  );
};
