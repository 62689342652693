import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import {
  QUERY_CAMPAIGN_UTM_CONFIGURATION_URL,
  QueryCampaignUTMConfigurationInput,
  QueryCampaignUTMConfigurationOptions,
} from './types';

export const useQueryCampaignUTMConfiguration = (
  input: QueryCampaignUTMConfigurationInput,
  options?: QueryCampaignUTMConfigurationOptions,
) =>
  useQuery(
    ['config.campaigns.utm', { businessId: input.businessId }],
    () =>
      api.campaignproductV1(QUERY_CAMPAIGN_UTM_CONFIGURATION_URL, 'post', {
        path: { 'query.businessId': input.businessId },
        body: {
          query: { ...input },
        },
      }),
    options,
  );
