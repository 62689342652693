import React, { FC, forwardRef, useMemo, useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Autocomplete, TextField, Box } from '@connectlyai-tenets/ui-styled-web';
import { resetFlowToPreface, selectPreviewDocumentId, setPreviewDocumentId } from '../../state/flow';
import { useDebounce, useFlowNavigator, ListCampaignsResponse } from '../../hooks';
import {
  HIGHLIGHT_PREVIEW_DEBOUNCE_DELAY_MS,
  CAMPAIGN_LIST_REFETCH_ON_MOUNT_DELAY_MS,
  CAMPAIGN_LIST_ITEM_SIZE,
} from './constants';

function renderRow({ data, index, style }: ListChildComponentProps) {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return <Box style={inlineStyle}>{dataSet}</Box>;
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData = children as [];
    const itemCount = itemData.length;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * CAMPAIGN_LIST_ITEM_SIZE;
      }
      return itemCount * CAMPAIGN_LIST_ITEM_SIZE;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            overscanCount={50}
            itemSize={() => CAMPAIGN_LIST_ITEM_SIZE}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export const useCampaignAutoComplete = (campaignsWithFlowList: ListCampaignsResponse | undefined) => {
  const previewDocumentId = useSelector(selectPreviewDocumentId);

  const [inputId, setInputId] = React.useState(previewDocumentId);
  const [hoverId, setHoverId] = React.useState(previewDocumentId);

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  // on mount refetch campaign list with delay
  useEffect(() => {
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['listCampaigns'] });
      queryClient.invalidateQueries({ queryKey: ['flowDocument'] });
    }, CAMPAIGN_LIST_REFETCH_ON_MOUNT_DELAY_MS);
  }, [queryClient]);

  const { flowDocumentIdRouteMatch, navigateToFlowDocument, navigateToFlowHome } = useFlowNavigator();

  useEffect(() => {
    if (!previewDocumentId) {
      let newPreviewId = '';
      if (flowDocumentIdRouteMatch) {
        newPreviewId = flowDocumentIdRouteMatch.params.flowDocumentId;
      } else {
        // no url params found for preview document id
        // auto select first campaign in campaign choose menu
        const campaigns = campaignsWithFlowList?.entity?.campaigns;
        if (campaigns && campaigns.length > 0 && campaigns[0].flowDocumentId !== undefined) {
          newPreviewId = campaigns[0].flowDocumentId;
        }
      }
      if (newPreviewId) {
        dispatch(setPreviewDocumentId(newPreviewId));
        setInputId(newPreviewId);
        setHoverId(newPreviewId);
      }
    }
  }, [campaignsWithFlowList, dispatch, flowDocumentIdRouteMatch, navigateToFlowDocument, previewDocumentId]);

  useDebounce(
    () => {
      if (previewDocumentId !== hoverId) {
        dispatch(setPreviewDocumentId(hoverId));
      }
    },
    HIGHLIGHT_PREVIEW_DEBOUNCE_DELAY_MS,
    [hoverId, dispatch],
  );

  const ids: string[] = useMemo(
    () =>
      (campaignsWithFlowList?.entity?.campaigns || [])
        .filter((campaign) => campaign.flowDocumentId !== undefined)
        .map((campaign) => campaign.flowDocumentId as string),
    [campaignsWithFlowList],
  );

  const labelOf = useCallback(
    (id: string) =>
      campaignsWithFlowList?.entity?.campaigns?.find((campaign) => campaign.flowDocumentId === id)?.name ?? '',
    [campaignsWithFlowList],
  );

  const handleHighlightChange = useCallback(
    (_: React.SyntheticEvent, id: string | null) => {
      if (!id) return;
      if (hoverId !== id) {
        setHoverId(id);
      }
    },
    [hoverId, setHoverId],
  );
  const handleChange = useCallback(
    (_: React.SyntheticEvent, id: string | null) => {
      if (!id) return;
      setInputId(id);
      if (hoverId !== id) {
        setHoverId(id);
      }

      if (flowDocumentIdRouteMatch && flowDocumentIdRouteMatch.params.flowDocumentId !== id) {
        dispatch(resetFlowToPreface());
        navigateToFlowHome();
        dispatch(setPreviewDocumentId(id));
      }
    },
    [hoverId, setHoverId, setInputId, flowDocumentIdRouteMatch, navigateToFlowHome, dispatch],
  );
  const handleBlur = useCallback(() => {
    dispatch(setPreviewDocumentId(inputId));
    setHoverId(inputId);
  }, [dispatch, inputId, setHoverId]);

  return { handleBlur, handleChange, handleHighlightChange, ids, inputId, labelOf };
};

export const CampaignAutoComplete: FC<{
  campaignsWithFlowList: ListCampaignsResponse | undefined;
  onClose: () => void;
  onOpen: () => void;
}> = ({ campaignsWithFlowList, onClose, onOpen }) => {
  const { handleBlur, handleChange, handleHighlightChange, ids, inputId, labelOf } =
    useCampaignAutoComplete(campaignsWithFlowList);

  return (
    <Autocomplete
      value={inputId}
      getOptionLabel={(id) => labelOf(id)}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option, _state) => (
        <span
          {...props}
          key={option}
          style={{
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {labelOf(option)}
        </span>
      )}
      options={ids}
      onBlur={handleBlur}
      onChange={handleChange}
      onHighlightChange={handleHighlightChange}
      onClose={onClose}
      onOpen={onOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Campaign"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': { p: '3.5px 6px', borderRadius: '10px' },
        flexGrow: 1,
      }}
    />
  );
};
