import React, { FC } from 'react';
import { Modal } from '@connectlyai-tenets/ui-styled-web';

export const UnscheduleCampaignDialog: FC<{
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ onConfirm, onClose, open }) => {
  return (
    <Modal
      dialog={{ open, onClose }}
      title="Confirm"
      contentText="Are you sure you want to cancel this sendout?"
      actions={{
        tertiaryButton: { onClick: onClose, children: 'Dismiss' },
        destructiveButton: { onClick: onConfirm, children: 'OK' },
      }}
    />
  );
};
