// unreduxified, has only messageTemplates redux

import { Node, Edge, NodeChange, EdgeChange } from 'react-flow-renderer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import produce from 'immer';
import { CustomSendSendoutData } from '@connectlyai-tenets/sdk';
import { v4 as uuidv4 } from 'uuid';
import {
  campaignLanguageAtom,
  campaignNameAtom,
  documentIdAtom,
  duplicateDocumentAtom,
  edgesAtom,
  isFlowPopulatedAtom,
  nodesAtom,
  revisionIdAtom,
  savedCampaignNameAtom,
  sendoutIdAtom,
  starterAtom,
} from '@atoms/flow';
import {
  updateUnsentMessageTemplate,
  MutationCreateOrUpdateReactFlowDocumentResponse,
  FlowDocumentResponse,
  useFlowVariables,
  NodeObjectUpdate,
} from '@hooks';
import { immutableReplace } from '../../../../utils/immutableReplace';
import { useChangesQueue } from '../useChangesQueue';
import { recalculateAllErrors } from '../../../../state/messageTemplates';
import { useCampaignFlowChangeAppliers } from '../useCampaignFlowChangeAppliers';
import { resetFlowChart } from '../../../../state/actions/flowChart';
import { useNodePersistence } from '../useNodePersistence';
import { cleanUpCarouselRaceConditions } from './cleanUpCarouselRaceConditions';

export const usePopulateCampaign = () => {
  const dispatch = useDispatch();

  const [duplicateDocument, setDuplicateDocument] = useAtom(duplicateDocumentAtom);
  const starter = useAtomValue(starterAtom);
  const setCampaignName = useSetAtom(campaignNameAtom);
  const setCampaignLanguage = useSetAtom(campaignLanguageAtom);
  const setDocumentId = useSetAtom(documentIdAtom);
  const setEdges = useSetAtom(edgesAtom);
  const setIsFlowPopulated = useSetAtom(isFlowPopulatedAtom);
  const setNodes = useSetAtom(nodesAtom);
  const setRevisionId = useSetAtom(revisionIdAtom);
  const setSavedCampaignName = useSetAtom(savedCampaignNameAtom);
  const setSendoutId = useSetAtom(sendoutIdAtom);
  const { setFlowVariables } = useFlowVariables();

  const { markIdsOnline } = useChangesQueue({});
  const { onNodesChange, onEdgesChange, onNodeObjectChange } = useCampaignFlowChangeAppliers();
  const { loadAllNodesIntoUIState } = useNodePersistence();

  const populateFromSendoutNode = useCallback(
    (customSendoutNode: Node<CustomSendSendoutData>) => {
      const customSendoutDataV3 = customSendoutNode.data.v3;
      if (!customSendoutDataV3) return;

      setSendoutId(customSendoutDataV3.sendoutId || '');

      if (customSendoutDataV3.language) {
        setCampaignLanguage(customSendoutDataV3.language);
      }

      if (customSendoutDataV3.enterVariables) {
        setFlowVariables(customSendoutDataV3.enterVariables);
      }
    },
    [setCampaignLanguage, setFlowVariables, setSendoutId],
  );

  const populateCampaign = useCallback(
    (data: MutationCreateOrUpdateReactFlowDocumentResponse | FlowDocumentResponse) => {
      const resName = data?.entity?.name;
      if (resName) {
        setCampaignName(resName);
        setSavedCampaignName(resName);
      }

      const resDocumentId = data?.entity?.id;
      if (resDocumentId) {
        setDocumentId(resDocumentId);
      }

      setRevisionId(data.entity?.lastRevisionId || '');

      if (!data.entity?.nodes || !data.entity?.edges) return;

      // if following line is deleted the templates will be duplicated
      // since they were already populated from preview flow chart
      dispatch(resetFlowChart());
      // Temporary stuff for the carousel race condition issue.
      // Remove it after the issue is fixed on the BE side.
      const nodes = cleanUpCarouselRaceConditions(
        data.entity?.nodes.map((node) => (node.options ? JSON.parse(node.options) : {})),
      );
      const edges: Edge[] = data.entity?.edges.map((edge) => (edge.options ? JSON.parse(edge.options) : {}));

      setNodes(nodes);
      setEdges(edges);

      markIdsOnline(nodes.map((node) => node.id));
      markIdsOnline(edges.map((edge) => edge.id));

      // load unsent templates into template slice
      nodes.forEach((node) => updateUnsentMessageTemplate(node, dispatch));

      loadAllNodesIntoUIState(nodes);

      const customSendoutNode = nodes.find((node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');
      if (!customSendoutNode) return;

      populateFromSendoutNode(customSendoutNode);

      // TODO: remove after template redux
      dispatch(recalculateAllErrors(nodes));

      // the rest of the function is for duplicating campaign only
      if (starter !== 'duplicate' || !duplicateDocument || !customSendoutNode) {
        setIsFlowPopulated(true);
        return;
      }

      let nodesToDup = duplicateDocument?.nodes;
      let edgesToDup = duplicateDocument?.edges;

      const sendoutToDup = nodesToDup?.find(
        (node: Node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT',
      ) as Node<CustomSendSendoutData>;
      nodesToDup = nodesToDup?.filter((node: Node) => node.type !== 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');

      // need to regenerate all ids for nodes and edges
      nodesToDup?.forEach((node) => {
        const newId = uuidv4();
        nodesToDup = immutableReplace(nodesToDup, node.id, newId);
        edgesToDup = immutableReplace(edgesToDup, node.id, newId);
      });
      edgesToDup?.forEach((edge) => {
        const newId = uuidv4();
        nodesToDup = immutableReplace(nodesToDup, edge.id, newId);
        edgesToDup = immutableReplace(edgesToDup, edge.id, newId);
      });

      // replace all customsendsendout ids in edges
      edgesToDup = immutableReplace(edgesToDup, sendoutToDup.id, customSendoutNode.id);

      const newSendoutNode = produce(sendoutToDup, (draft) => {
        draft.id = customSendoutNode?.id as string;

        if (!draft.data.v3) draft.data.v3 = {};
        draft.data.v3.sendoutId = customSendoutNode?.data.v3?.sendoutId as string;
      });

      populateFromSendoutNode(newSendoutNode);

      const nodeObjectUpdates: NodeObjectUpdate[] = [
        {
          id: newSendoutNode.id,
          item: newSendoutNode,
        },
      ];

      const nodeChanges: NodeChange[] =
        nodesToDup?.map((node) => ({
          type: 'add',
          id: node.id as string,
          item: { ...node, selected: false } as Node,
        })) ?? [];

      const edgeChanges: EdgeChange[] =
        edgesToDup?.map((edge) => ({
          type: 'add',
          id: edge.id as string,
          item: edge as Edge,
        })) ?? [];

      onNodeObjectChange(nodeObjectUpdates);
      onNodesChange(nodeChanges);
      onEdgesChange(edgeChanges);

      nodesToDup?.forEach((node) => {
        // load unsent templates into template slice
        updateUnsentMessageTemplate(node, dispatch);
      });

      loadAllNodesIntoUIState(nodesToDup || []);

      dispatch(recalculateAllErrors(nodesToDup || []));
      setDuplicateDocument(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      duplicateDocument,
      loadAllNodesIntoUIState,
      markIdsOnline,
      onEdgesChange,
      onNodeObjectChange,
      // Including this dependencies seems to cause a ciruclar dependency.
      // this onNodesChange function is already dependent on onEdgesChange
      // onNodesChange, // causes circular dependency of updates.
      populateFromSendoutNode,
      setCampaignName,
      setDocumentId,
      setDuplicateDocument,
      setEdges,
      setIsFlowPopulated,
      setNodes,
      setRevisionId,
      setSavedCampaignName,
      starter,
    ],
  );
  return { populateCampaign };
};
