import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api';
import { DeleteCampaignUrlParameters, DeleteCampaignResponse } from './types';

export const useDeleteCampaignMutation = (
  options?: Omit<
    UseMutationOptions<DeleteCampaignResponse, unknown, DeleteCampaignUrlParameters, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteCampaign'],
    ({ businessId, campaignId }: DeleteCampaignUrlParameters) =>
      api.campaignV2('/internal/v2/businesses/{businessId}/delete/campaigns/{campaignId}', 'post', {
        path: { businessId, campaignId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
      ...options,
    },
  );
};
