import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { SidebarDIContext } from '../../context';
import { RoomViewState } from '../sidebar/presenter';
export const OnboardingRoomId = '00000000-0000-0000-0000-000000000000';
export const OnboardingRoomCardComponentId = 'd120ff4f-6b55-422a-9437-f87cc2a409c0';
const OnboardingRoomCard = ({ isSelected }) => {
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const roomViewModel = {
        id: OnboardingRoomId,
        title: 'Welcome to Connectly!',
        preview: 'Review your onboarding tasks',
        avatarUrl: '',
        avatarBadge: undefined,
        displayTime: '',
        primaryColor: undefined,
        roomStatus: undefined,
        unreadCount: 0,
    };
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(OnboardingRoomCardComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { room: roomViewModel, state: isSelected ? RoomViewState.SELECTED : RoomViewState.NORMAL })) }));
};
export default OnboardingRoomCard;
