import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { UpdateResourceLimitOptions, UpdateResourceLimitParameters, UPDATE_RESOURCE_LIMIT_URL } from './types';
import { DEFAULT_RESOURCE, DEFAULT_RESOURCE_LIMIT, FIVE_MINUTES_IN_SECONDS } from './constants';

export const useMutationResourceLimit = (options?: UpdateResourceLimitOptions) =>
  useMutation(
    ({
      businessId,
      resource = DEFAULT_RESOURCE,
      limit = DEFAULT_RESOURCE_LIMIT,
      duration = FIVE_MINUTES_IN_SECONDS,
    }: UpdateResourceLimitParameters) =>
      api.profileV1(UPDATE_RESOURCE_LIMIT_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { resource, limit, duration } },
      }),
    options,
  );
