import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { PROVISION_PHONE_NUMBER_URL, ProvisionPhoneNumberVariables, ProvisionPhoneNumberResponse } from './types';
import { api } from '../../../../api';

export const useMutationProvisionPhoneNumber = (
  options?: Omit<
    UseMutationOptions<ProvisionPhoneNumberResponse, ConnectlyError, ProvisionPhoneNumberVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, ...others }: ProvisionPhoneNumberVariables) =>
      api.profileV1(PROVISION_PHONE_NUMBER_URL, 'post', {
        path: { businessId },
        body: { input: others },
      }) as Promise<ProvisionPhoneNumberResponse>,
    options,
  );
