import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_UPSERT_CAMPAIGN_UTM_CONFIGURATION,
  MutationUpsertCampaignUTMConfigurationInput,
  MutationUpsertCampaignUTMConfigurationOptions,
} from './types';

export const useMutationUpsertCampaignUTMConfiguration = (options?: MutationUpsertCampaignUTMConfigurationOptions) => {
  return useMutation(
    [],
    (input: MutationUpsertCampaignUTMConfigurationInput) =>
      api.campaignproductV1(MUTATION_UPSERT_CAMPAIGN_UTM_CONFIGURATION, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }),
    options,
  );
};
