import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import { MUTATION_SOFIA_DOCUMENTS, MutationSofiaDocumentsParams, MutationSofiaDocumentsOptions } from './types';

export const useCreateSofiaDocumentAssociation = (options?: MutationSofiaDocumentsOptions) => {
  return useMutation(
    [],
    (input: MutationSofiaDocumentsParams) =>
      api.sofiaV1(MUTATION_SOFIA_DOCUMENTS, 'post', {
        path: { 'input.businessId': input.businessId },
        body: { input: { assetId: input.assetId, filename: input.filename } },
      }),
    options,
  );
};
