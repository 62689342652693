import { Subject } from 'rxjs';
export default class RealNotificationService {
    constructor() {
        this.subject = new Subject();
    }
    notify(request) {
        this.subject.next(request);
    }
    notifications() {
        return this.subject;
    }
}
