import React, { VFC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import { v4 as uuidv4 } from 'uuid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpandMoreIcon,
  Label,
} from '@connectlyai-tenets/ui-styled-web';
import { FlowChartCampaignVerificationDialogProps } from './types';
import { applyHook } from '../../utils';
import { selectBusinessId, useMeData, usePrevious, useRequestVerifyReactFlowDocument } from '../../hooks';
import { selectDocumentId, selectFlowState } from '../../state/flow';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import { approveConnectlyTemplates } from '../../state/messageTemplates/actions';
import { VerifyReactFlowDocumentResult } from '../../hooks/useRequestVerifyReactFlowDocument/types';
import { COPY_BUTTON_NOTIFICATION_TIMEOUT_MS, KNOWN_DIAGNOSTIC_CODE_LIST } from './constants';

type CopyButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const CopyButton: VFC<CopyButtonProps> = ({ onClick }) => {
  const [label, setLabel] = useState('Copy');
  const timeoutRef = useRef<NodeJS.Timeout | 0>(0);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      clearTimeout(timeoutRef.current);
      setLabel('Copied');
      timeoutRef.current = setTimeout(() => {
        setLabel('Copy');
      }, COPY_BUTTON_NOTIFICATION_TIMEOUT_MS);

      onClick(e);
    },
    [onClick],
  );

  return <Button onClick={handleClick}>{label}</Button>;
};

const useFlowChartCampaignVerificationDialog = ({
  onNextState = () => {},
  onPreviousState = () => {},
}: Partial<FlowChartCampaignVerificationDialogProps>): FlowChartCampaignVerificationDialogProps => {
  onNextState = useContextSelector(FlowCampaignContext, (context) => context?.goToNextState);
  onPreviousState = useContextSelector(FlowCampaignContext, (context) => context?.goToPreviousState);

  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState<VerifyReactFlowDocumentResult | undefined>(undefined);
  const dispatch = useDispatch();

  const handleVerifyReactFlowDocument = (data: VerifyReactFlowDocumentResult | undefined) => {
    if (!data) {
      return;
    }
    const { items = [] } = data;
    const hasUnknownErrors = items.find(
      ({ kind, code }) => kind === 'DIAGNOSTIC_KIND_ERROR' && code && !KNOWN_DIAGNOSTIC_CODE_LIST.includes(code),
    );
    if (hasUnknownErrors) {
      setExpanded(false);
      setError(data);
    } else {
      setError(undefined);

      const nodeIds = items
        .filter(
          ({ code, message }) =>
            (code && code === 'DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED') ||
            message === 'connectly template is approved',
        )
        .map((x) => x.id as string);
      dispatch(approveConnectlyTemplates({ nodeIds }));

      onNextState();
    }
  };

  const { mutate: verifyCampaign, data } = useRequestVerifyReactFlowDocument({
    onSuccess: () => handleVerifyReactFlowDocument(data),
    onError: () => handleVerifyReactFlowDocument(data),
  });

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const flowDocumentId = useSelector(selectDocumentId);
  const flowState = useSelector(selectFlowState);
  const prevFlowState = usePrevious(flowState);
  useEffect(() => {
    if (prevFlowState === 'buildCampaign' && flowState === 'verifyCampaign') {
      if (!businessId) {
        return;
      }

      const clientRequestId = uuidv4();
      verifyCampaign({
        businessId,
        flowDocumentId,
        clientRequestId,
      });
    }
  }, [prevFlowState, flowState, businessId, verifyCampaign, flowDocumentId]);

  const handleClose = useCallback(() => {
    setError(undefined);
    onPreviousState();
  }, [onPreviousState]);

  const handleCopy = useCallback(() => {
    if (!error) {
      return;
    }

    navigator.clipboard.writeText(JSON.stringify(error, null, 2));
  }, [error]);

  const handleSwitchExpanded = useCallback((value: boolean) => {
    setExpanded(value);
  }, []);

  return {
    error,
    expanded,
    onClose: handleClose,
    onCopy: handleCopy,
    onNextState,
    onPreviousState,
    onSwitchExpanded: handleSwitchExpanded,
  };
};

export const FlowChartCampaignVerificationDialogPresentation: VFC<FlowChartCampaignVerificationDialogProps> = ({
  error,
  expanded,
  onClose,
  onCopy,
  onSwitchExpanded,
}) => {
  return (
    <Dialog
      transitionDuration={0}
      open={!!error}
      onClose={onClose}
      aria-labelledby="campaign-verification-dialog-title"
    >
      <DialogTitle id="campaign-verification-dialog-title">
        {error?.items?.find((x) => x.kind === 'DIAGNOSTIC_KIND_ERROR')?.userMessage?.title || 'Unexpected error'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {error?.items?.find((x) => x.kind === 'DIAGNOSTIC_KIND_ERROR')?.userMessage?.message}
        </DialogContentText>
        <Accordion expanded={expanded} disableGutters onChange={(_e, value) => onSwitchExpanded(value)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Label>{expanded ? 'Hide Details' : 'Show Details'}</Label>
          </AccordionSummary>
          <AccordionDetails>
            <Label
              sx={{
                overflow: 'hidden',
                scrollbarGutter: 'stable',
                '&:hover': {
                  overflow: 'auto',
                },
                fontSize: '10px',
                fontFamily: 'monospace',
                whiteSpace: 'pre',
                maxHeight: '250px',
              }}
            >
              {JSON.stringify(error, null, 2)}
            </Label>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        {expanded && <CopyButton onClick={onCopy} />}
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export const FlowChartCampaignVerificationDialog = applyHook(
  FlowChartCampaignVerificationDialogPresentation,
  useFlowChartCampaignVerificationDialog,
);
