import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@connectlyai-tenets/ui-styled-web';
const LoginButton = ({ audience }) => {
    const { loginWithRedirect } = useAuth0();
    return (_jsx(Button, { color: "inherit", onClick: () => loginWithRedirect({
            audience,
        }), children: "Login" }));
};
export default LoginButton;
