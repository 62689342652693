import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { CreateMessageTemplateVariables, CreateMessageTemplateResponse } from './types';

export const useCreateMessageTemplateMutation = (
  options?: Omit<
    UseMutationOptions<CreateMessageTemplateResponse, ConnectlyError, CreateMessageTemplateVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, template, templateGroup }: CreateMessageTemplateVariables) =>
      api.profileV1('/internal/v1/businesses/{businessId}/create/message_template', 'post', {
        path: { businessId },
        body: { input: { template, templateGroup } },
      }) as Promise<CreateMessageTemplateResponse>,
    options,
  );
