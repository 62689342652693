import { Timestamp } from "../../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "events.nubank.nupay";
function createBaseNotification() {
    return {
        referenceId: undefined,
        pspReferenceId: undefined,
        timestamp: undefined,
        paymentMethodType: undefined,
    };
}
export const Notification = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.referenceId !== undefined) {
            StringValue.encode({ value: message.referenceId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.pspReferenceId !== undefined) {
            StringValue.encode({ value: message.pspReferenceId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(26).fork()).ldelim();
        }
        if (message.paymentMethodType !== undefined) {
            StringValue.encode({ value: message.paymentMethodType }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.referenceId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.pspReferenceId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.paymentMethodType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            referenceId: isSet(object.referenceId)
                ? String(object.referenceId)
                : undefined,
            pspReferenceId: isSet(object.pspReferenceId)
                ? String(object.pspReferenceId)
                : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            paymentMethodType: isSet(object.paymentMethodType)
                ? String(object.paymentMethodType)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.referenceId !== undefined &&
            (obj.referenceId = message.referenceId);
        message.pspReferenceId !== undefined &&
            (obj.pspReferenceId = message.pspReferenceId);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.paymentMethodType !== undefined &&
            (obj.paymentMethodType = message.paymentMethodType);
        return obj;
    },
};
function createBaseNuPayAuth() {
    return { merchantToken: undefined };
}
export const NuPayAuth = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.merchantToken !== undefined) {
            StringValue.encode({ value: message.merchantToken }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNuPayAuth();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.merchantToken = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            merchantToken: isSet(object.merchantToken)
                ? String(object.merchantToken)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.merchantToken !== undefined &&
            (obj.merchantToken = message.merchantToken);
        return obj;
    },
};
function createBaseNuPayProfile() {
    return {
        authDetails: undefined,
        merchantName: undefined,
        storeName: undefined,
    };
}
export const NuPayProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.authDetails !== undefined) {
            NuPayAuth.encode(message.authDetails, writer.uint32(10).fork()).ldelim();
        }
        if (message.merchantName !== undefined) {
            StringValue.encode({ value: message.merchantName }, writer.uint32(18).fork()).ldelim();
        }
        if (message.storeName !== undefined) {
            StringValue.encode({ value: message.storeName }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNuPayProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authDetails = NuPayAuth.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.merchantName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.storeName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            authDetails: isSet(object.authDetails)
                ? NuPayAuth.fromJSON(object.authDetails)
                : undefined,
            merchantName: isSet(object.merchantName)
                ? String(object.merchantName)
                : undefined,
            storeName: isSet(object.storeName) ? String(object.storeName) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.authDetails !== undefined &&
            (obj.authDetails = message.authDetails
                ? NuPayAuth.toJSON(message.authDetails)
                : undefined);
        message.merchantName !== undefined &&
            (obj.merchantName = message.merchantName);
        message.storeName !== undefined && (obj.storeName = message.storeName);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
