import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../api';
import {
  RecommendedIntegrationStatusParameters,
  RecommendedIntegrationStatusQuery,
  RecommendedIntegrationStatusResponse,
} from './types';

export const useRecommendedIntegrationStatus = <TData = RecommendedIntegrationStatusResponse>({
  businessId,
  integrationId,
  ...options
}: Omit<
  UseQueryOptions<RecommendedIntegrationStatusResponse, unknown, TData, readonly ['recommendedIntegrationStatus']>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  Partial<RecommendedIntegrationStatusQuery> &
  RecommendedIntegrationStatusParameters) =>
  useQuery(
    ['recommendedIntegrationStatus'],
    () =>
      api.integrationV1('/internal/v1/businesses/{input.businessId}/query_state/{input.integrationId}', 'post', {
        path: { 'input.businessId': businessId, 'input.integrationId': integrationId },
        body: { input: {} },
      }),
    options,
  );
