import React, { VFC, DragEventHandler, MouseEventHandler, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import { Box, Label, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useSofiaEnabled } from '@scenes/Sofia/hooks';
import { FlowChartSidebarNodeHint } from '../FlowChartSidebarNodeHint';
import { FlowChartSidebarNode } from '../FlowChartSidebarNode';
import { useFlowHandlers } from '../../hooks/useFlowHandlers';
import { NodeType } from '../../sdks/flow/createNode';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { selectNodes } from '../../state/flow';

export enum FlowChartSidebarType {
  AutoReply,
  Campaign,
}

type FlowChartSidebarBuildFlowProps = {
  title: string;
  type: FlowChartSidebarType;
};

export const FlowChartSidebarBuildFlow: VFC<FlowChartSidebarBuildFlowProps> = ({
  title,
  type: sidebarType,
}: FlowChartSidebarBuildFlowProps) => {
  const { handleNodeCreate } = useFlowHandlers({ flowChangeAppliers: useCampaignFlowChangeAppliers });
  const reactFlowInstance = useContextSelector(FlowCampaignContext, (context) => context?.reactFlowInstance);
  const enableAINode = useSofiaEnabled();

  const handleDragStart =
    (type: string): DragEventHandler<HTMLDivElement> =>
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
    };

  const handleNodeClick =
    (type: string): MouseEventHandler<HTMLDivElement> =>
    (_event) => {
      handleNodeCreate(type as NodeType, reactFlowInstance, null);
    };

  const nodes = useSelector(selectNodes);

  const hasWATemplate = useMemo(() => nodes.some((x) => x.type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE'), [nodes]);

  let simpleMessageType = 'FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE';
  let quickReplyMessageType = 'FLOW_OBJECT_TYPE_SEND_WA_QUICK_REPLY_MESSAGE';
  let ctaMessageType = 'FLOW_OBJECT_TYPE_SEND_WA_CTA_MESSAGE';
  const listMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_LIST_MESSAGE';
  const aiAssistantType = 'FLOW_OBJECT_TYPE_AI_ASSISTANT';

  switch (sidebarType) {
    case FlowChartSidebarType.AutoReply: {
      if (!hasWATemplate) {
        simpleMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
        quickReplyMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE';
        ctaMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE';
      }
      break;
    }
    case FlowChartSidebarType.Campaign: {
      if (!hasWATemplate) {
        simpleMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
        quickReplyMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE';
        ctaMessageType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE';
      }
      break;
    }
    default: {
      break;
    }
  }

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: isLargeScreen ? 3 : 2,
      }}
    >
      <Label variant="h6">{title}</Label>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, paddingBottom: 1 }}>
        <>
          <FlowChartSidebarNodeHint />
          <Label variant="subtitle2" sx={{ marginTop: 2 }}>
            Messages
          </Label>
          <FlowChartSidebarNode
            icon={`${process.env.PUBLIC_URL}/assets/flow/simple_message_node.png`}
            title="Simple Message"
            hint={`This message type needs to be approved by WhatsApp 
                (it usually takes less than 2 minutes after you submit the campaign).`}
            onDragStart={handleDragStart(simpleMessageType)}
            onClick={handleNodeClick(simpleMessageType)}
          />
          <FlowChartSidebarNode
            icon={`${process.env.PUBLIC_URL}/assets/flow/button_reply_message_node.png`}
            title="Button Message"
            hint={`This message type needs to be approved by WhatsApp 
                (it usually takes less than 2 minutes after you submit the campaign).`}
            onDragStart={handleDragStart(quickReplyMessageType)}
            onClick={handleNodeClick(quickReplyMessageType)}
          />
          <FlowChartSidebarNode
            icon={`${process.env.PUBLIC_URL}/assets/flow/cta_message_node.png`}
            title="Link Message"
            hint={`This message type needs to be approved by WhatsApp 
                (it usually takes less than 2 minutes after you submit the campaign).`}
            onDragStart={handleDragStart(ctaMessageType)}
            onClick={handleNodeClick(ctaMessageType)}
          />
          {!hasWATemplate && (
            <FlowChartSidebarNode
              icon={`${process.env.PUBLIC_URL}/assets/flow/list_message_node.png`}
              title="List Message"
              hint=""
              onDragStart={handleDragStart(listMessageType)}
              onClick={handleNodeClick(listMessageType)}
            />
          )}
          {enableAINode && (
            <>
              <Label variant="subtitle2" sx={{ marginTop: 2 }}>
                Actions
              </Label>
              <FlowChartSidebarNode
                icon={`${process.env.PUBLIC_URL}/assets/flow/ai_assistant_node.png`}
                iconSquare
                textWhite
                title="Sofia AI takeover"
                hint="Allow Sofia AI to talk to your customers."
                sx={{
                  background: 'linear-gradient(145deg, #FF01E6 1.48%, #9A08C1 8.49%, #360F9C 27.19%, #360F9C 65.6%)',
                }}
                onDragStart={handleDragStart(aiAssistantType)}
                onClick={handleNodeClick(aiAssistantType)}
              />
            </>
          )}
        </>
      </Box>
    </Box>
  );
};
