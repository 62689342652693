import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { WebhookTopic } from '@connectlyai-idl/models/dist/models/common/models';
import { ConnectlyEventSkip } from '@connectlyai-idl/models/dist/models/room/models';
import { AUTO_REPLY_TAG_ID } from '../constants';
import { insertTextMessageInput, refreshEligibility } from './action';
export const assignRoomAgent = createAsyncThunk('chatroom/assignRoomAgent', async ({ di, roomId, agentId }) => {
    const mutationAssignRoomAgent = di.mutationAssignRoomAgentProvider();
    await mutationAssignRoomAgent({
        businessId: di.config.businessId,
        roomId,
        agentId,
    });
});
export const changeRoomStatus = createAsyncThunk('chatroom/changeRoomStatus', async ({ di, roomId, userId, roomStatus }) => {
    const mutationChangeRoomStatus = di.mutationChangeRoomStatusProvider();
    await mutationChangeRoomStatus({
        businessId: di.config.businessId,
        roomId,
        userId,
        status: {
            status: roomStatus,
            displayReadyStatus: '',
        },
    });
});
export const createMessage = createAsyncThunk('composer/createMessage', async (arg, { getState }) => {
    const mutationCreateMessage = arg.di.mutationCreateMessageProvider();
    const state = getState();
    const { businessId, businessUserId, roomId } = arg.di.config;
    const results = [];
    if (state.composerSlice.textMessageInput !== '') {
        const textMessageInput = {
            businessId,
            roomId,
            businessUserId,
            senderChannel: state.composerSlice.senderChannel,
            recipientChannel: state.composerSlice.recipientChannel,
            connectlyEvent: {
                eventOneof: {
                    $case: 'message',
                    message: {
                        text: state.composerSlice.textMessageInput,
                        attachments: [],
                        isButtonResponse: false,
                        location: undefined,
                        interactive: undefined,
                        referral: undefined,
                        isOtp: undefined,
                    },
                },
                sender: undefined,
                recipient: undefined,
                eligibility: undefined,
                skipEvent: ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_UNSPECIFIED,
                autoresp: undefined,
                webhookTopic: WebhookTopic.WEBHOOK_TOPIC_TOPIC_UNSPECIFIED,
                webhookIds: [],
            },
            processingOrder: undefined,
        };
        const result = await mutationCreateMessage(textMessageInput)
            .then((_val) => textMessageInput)
            .catch((err) => err);
        results.push(result);
    }
    const hasAttachments = Object.keys(state.composerSlice.attachments.byId).length > 0;
    if (hasAttachments) {
        const attachments = Object.values(state.composerSlice.attachments.byId);
        const messageResults = await Promise.all(attachments.map(async (attachment) => {
            if (attachment.state !== 'completed' ||
                attachment.remoteId === undefined ||
                attachment.remoteUrl === undefined) {
                return Promise.resolve(undefined);
            }
            const attachmentMessageInput = {
                businessId: arg.di.config.businessId,
                roomId: arg.di.config.roomId,
                businessUserId: arg.di.config.businessUserId,
                senderChannel: state.composerSlice.senderChannel,
                recipientChannel: state.composerSlice.recipientChannel,
                connectlyEvent: {
                    eventOneof: {
                        $case: 'message',
                        message: {
                            text: '',
                            attachments: [
                                {
                                    type: attachment.type,
                                    attachmentOneof: {
                                        $case: 'asset',
                                        asset: {
                                            id: attachment.remoteId,
                                            url: attachment.remoteUrl,
                                            caption: '',
                                            filename: attachment.fileName || '',
                                        },
                                    },
                                },
                            ],
                            isButtonResponse: false,
                            location: undefined,
                            interactive: undefined,
                            referral: undefined,
                            isOtp: undefined,
                        },
                    },
                    sender: undefined,
                    recipient: undefined,
                    eligibility: undefined,
                    skipEvent: ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_UNSPECIFIED,
                    autoresp: undefined,
                    webhookTopic: WebhookTopic.WEBHOOK_TOPIC_TOPIC_UNSPECIFIED,
                    webhookIds: [],
                },
                processingOrder: undefined,
            };
            return mutationCreateMessage(attachmentMessageInput)
                .then((_val) => attachmentMessageInput)
                .catch((err) => err);
        }));
        const filterUndefined = (item) => !!item;
        results.push(...messageResults.filter(filterUndefined));
    }
    const hasError = results.find((item) => item instanceof Error);
    const filterErrors = (item) => !(item instanceof Error);
    const realResults = results.filter(filterErrors);
    if (hasError) {
        const notificationService = arg.di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to send message',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
    }
    return {
        type: hasError ? 'partial' : 'full',
        sent: realResults,
    };
});
function getBodyText(template) {
    var _a, _b;
    for (let i = 0; i < template.dto.templateComponents.length; i += 1) {
        const component = template.dto.templateComponents[i];
        if (((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'body') {
            return ((_b = component.componentOneof.body.text) === null || _b === void 0 ? void 0 : _b.text) || '';
        }
    }
    return '';
}
function buildComponents(template) {
    var _a;
    const templateComponents = (_a = template === null || template === void 0 ? void 0 : template.dto) === null || _a === void 0 ? void 0 : _a.templateComponents;
    if (!templateComponents) {
        return [];
    }
    const components = [];
    templateComponents.forEach((component) => {
        var _a;
        switch ((_a = component === null || component === void 0 ? void 0 : component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) {
            case 'header':
                components.push({
                    componentOneof: {
                        $case: 'header',
                        header: {
                            parameters: [],
                        },
                    },
                });
                break;
            case 'body':
                components.push({
                    componentOneof: {
                        $case: 'body',
                        body: {
                            parameters: [],
                            text: '',
                        },
                    },
                });
                break;
            case 'button':
                break;
            default:
        }
    });
    return components;
}
export const createTemplatedMessage = createAsyncThunk('composer/createTemplatedMessage', async ({ di, templateId }, { getState }) => {
    var _a;
    const mutationCreateMessage = di.mutationCreateMessageProvider();
    const composeTemplatedMessage = di.composeTemplatedMessageProvider();
    const state = getState();
    const { businessId, businessUserId, roomId } = di.config;
    const results = [];
    const log = di.logProvider();
    const template = state.messageTemplateGroupSlice.items.byId[templateId];
    if (!template) {
        log.error('unknown template', () => ({ templateId }));
        return Promise.reject(new Error('unknown template'));
    }
    const { dto, group } = template;
    const components = buildComponents(template);
    try {
        const composeTemplateInput = {
            channelSelector: {
                predicateOneof: {
                    $case: 'byId',
                    byId: {
                        id: ((_a = state.composerSlice.senderChannel) === null || _a === void 0 ? void 0 : _a.id) ? state.composerSlice.senderChannel.id : '',
                    },
                },
                businessId,
            },
            templateId: group.name,
            language: dto.language,
            components,
            modifiers: [],
            messageTemplateInput: undefined,
        };
        const templatedMessage = await composeTemplatedMessage(composeTemplateInput);
        const eventInput = {
            businessId,
            roomId,
            businessUserId,
            senderChannel: state.composerSlice.senderChannel,
            recipientChannel: state.composerSlice.recipientChannel,
            connectlyEvent: {
                eventOneof: {
                    $case: 'templatedMessage',
                    templatedMessage,
                },
                sender: undefined,
                recipient: undefined,
                eligibility: undefined,
                skipEvent: ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_UNSPECIFIED,
                autoresp: undefined,
                webhookTopic: WebhookTopic.WEBHOOK_TOPIC_TOPIC_UNSPECIFIED,
                webhookIds: [],
            },
            processingOrder: undefined,
        };
        const result = await mutationCreateMessage(eventInput);
        results.push(eventInput);
        return {
            sent: results,
        };
    }
    catch (err) {
        log.error('encounetered errror', () => ({ err }));
        throw err;
    }
});
export const insertQuickReply = createAsyncThunk('composer/insertQuickReply', async (arg, { getState, dispatch }) => {
    const template = getState().quickReplySlice.items.find((v) => v.id === arg.templateId);
    if (template) {
        dispatch(insertTextMessageInput({ data: template.body }));
    }
});
export const tryRefreshEligibility = createAsyncThunk('composer/tryRefreshEligibility', async (_arg, { getState, dispatch }) => {
    dispatch(refreshEligibility(getState().roomSlice.room));
});
export const updateMessageReadWatermarkInput = createAsyncThunk('chatthread/updateMessageReadWatermarkInput', async (arg) => {
    const mutationUpdateMessageReadWatermark = arg.di.mutationUpdateMessageReadWatermarkProvider();
    const input = {
        businessId: arg.di.config.businessId,
        roomId: arg.di.config.roomId,
        eventId: arg.eventId,
        userId: arg.di.config.businessUserId,
    };
    await mutationUpdateMessageReadWatermark(input);
});
export const updateComposerSendBehavior = createAsyncThunk('user/updateComposerSendBehavior', async (arg) => {
    const mutationUpdateUserComposerSendBehavior = arg.di.mutationUpdateUserComposerSendBehaviorProvider();
    const input = {
        userId: arg.di.config.businessUserId,
        sendBehavior: arg.sendBehavior,
    };
    await mutationUpdateUserComposerSendBehavior(input);
});
export const uploadAttachment = createAsyncThunk('composer/uploadAttachment', async ({ di, localFileUrl }) => {
    const mutationUploadAsset = di.mutationUploadAssetProvider();
    const assetDto = await mutationUploadAsset({
        businessId: di.config.businessId,
        localFileUrl,
    });
    return assetDto;
});
export const tryUploadAttachment = createAsyncThunk('composer/tryUploadAttachment', async ({ di, localFileUrl, fileName, type }, { getState, dispatch }) => {
    const { attachments } = getState().composerSlice;
    const attachmentState = Object.values(attachments.byId).find((val) => val.localUrl === localFileUrl);
    if (attachmentState) {
        throw new Error('Attachment is already picked');
    }
    dispatch(uploadAttachment({
        di,
        localId: uuidv4(),
        localFileUrl,
        fileName,
        type,
    }));
});
export const retryUploadAttachment = createAsyncThunk('composer/retryUploadAttachment', async ({ di, localId }, { getState, dispatch }) => {
    const { attachments } = getState().composerSlice;
    const attachmentState = Object.values(attachments.byId).find((val) => val.localId === localId);
    if (attachmentState) {
        dispatch(uploadAttachment({
            di,
            localId,
            localFileUrl: attachmentState.localUrl,
            fileName: attachmentState.fileName,
            type: attachmentState.type,
        }));
    }
    throw new Error('Attachment is missing?');
});
export const tryQueryMessageTemplateGroups = createAsyncThunk('broadcast/tryQueryMessageTemplateGroups', async ({ di }) => {
    const queryMessageTemplateGroups = di.queryMessageTemplateGroupsProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        const result = await queryMessageTemplateGroups(di.config.businessId);
        return result;
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch whatsapp templates.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryAddRoomTag = createAsyncThunk('chatroom/tryAddRoomTag', async ({ di, tagId, onChatRoomAddTag, onChatRoomDisableAutoReply }, { getState }) => {
    const mutationAddRoomTag = di.mutationAddRoomTagProvider();
    const { businessId, roomId } = di.config;
    const state = getState();
    onChatRoomAddTag === null || onChatRoomAddTag === void 0 ? void 0 : onChatRoomAddTag({
        businessId,
        roomId,
        tagId,
        roomTagCount: state.roomSlice.room.tags.length,
    });
    if (tagId === AUTO_REPLY_TAG_ID) {
        onChatRoomDisableAutoReply === null || onChatRoomDisableAutoReply === void 0 ? void 0 : onChatRoomDisableAutoReply({ businessId: di.config.businessId });
    }
    try {
        await mutationAddRoomTag({
            businessId,
            entityId: roomId,
            tagId,
        });
        const roomsDataStore = di.roomsDataStoreProvider();
        roomsDataStore.eventRefreshRoom(roomId);
    }
    catch (error) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to add tag to room',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
    }
});
export const tryCreateTag = createAsyncThunk('chatroom/tryCreateTag', async ({ di, name, color, onChatRoomCreateTag }, { dispatch }) => {
    const mutationCreateTag = di.mutationCreateTagProvider();
    const { businessId, roomId } = di.config;
    const tagId = uuidv4();
    onChatRoomCreateTag === null || onChatRoomCreateTag === void 0 ? void 0 : onChatRoomCreateTag({
        businessId,
        roomId,
        name,
        tagId,
    });
    try {
        await mutationCreateTag({
            businessId: di.config.businessId,
            tagId,
            config: {
                configOneof: {
                    $case: 'v1',
                    v1: {
                        name,
                        color,
                    },
                },
                groups: [],
            },
            isMutable: undefined,
        });
        dispatch(tryAddRoomTag({ di, tagId }));
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'A new tag was added',
                severity: NotificationSeverity.SUCCESS,
                icon: '',
            },
        });
    }
    catch (error) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to create tag',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
    }
});
export const tryRemoveRoomTag = createAsyncThunk('chatroom/tryRemoveRoomTag', async ({ di, tagId, onChatRoomRemoveTag, onChatRoomEnableAutoReply }, { getState }) => {
    const mutationRemoveRoomTag = di.mutationRemoveRoomTagProvider();
    const { businessId, roomId } = di.config;
    const state = getState();
    onChatRoomRemoveTag === null || onChatRoomRemoveTag === void 0 ? void 0 : onChatRoomRemoveTag({
        businessId,
        roomId,
        tagId,
        roomTagCount: state.roomSlice.room.tags.length,
    });
    if (tagId === AUTO_REPLY_TAG_ID) {
        onChatRoomEnableAutoReply === null || onChatRoomEnableAutoReply === void 0 ? void 0 : onChatRoomEnableAutoReply({ businessId: di.config.businessId });
    }
    try {
        await mutationRemoveRoomTag({
            businessId,
            entityId: roomId,
            tagId,
        });
        const roomsDataStore = di.roomsDataStoreProvider();
        roomsDataStore.eventRefreshRoom(roomId);
    }
    catch (error) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to remove tag from room',
                severity: NotificationSeverity.ERROR,
                icon: '',
            },
        });
    }
});
