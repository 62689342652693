import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeData, selectBusinessId } from '@hooks/useMeData';
import { get, set } from 'lodash';
import produce from 'immer';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryClientKeys } from '@scenes/Sofia/hooks/useQueryClientKeys/useQueryClientKeys';
import { useAgentDescriptor, useMutationAgentDescriptor } from '../../hooks';

export const usePartialAgentDescriptor = <T>({ keyPath, defaultValue }: { keyPath: string; defaultValue: T }) => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const [value, setValue] = useState<T>(defaultValue);
  const { data: agentDescriptorResponse } = useAgentDescriptor({ businessId: businessId || '' });

  const queryClient = useQueryClient();

  const { mutate } = useMutationAgentDescriptor({ onSettled: () => queryClient.invalidateQueries(['sofia-agent']) });

  const { data: clientKeysResponse } = useQueryClientKeys({ businessId: businessId || '' });

  const agentDescriptorId = useMemo(
    () => clientKeysResponse?.response?.keys?.[0]?.agentDescriptorId || '',
    [clientKeysResponse],
  );

  const agentDescriptor = useMemo(
    () => agentDescriptorResponse?.response?.agentDescriptors?.find((agent) => agent.id === agentDescriptorId),
    [agentDescriptorResponse, agentDescriptorId],
  );

  const defaultPrefix = 'additionalData.';

  useEffect(() => {
    const remoteValue = get(agentDescriptor, defaultPrefix + keyPath);
    setValue((remoteValue as T) || defaultValue);
    // only update value when agentDescriptor changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentDescriptor]);

  const onSave = useCallback(() => {
    if (!businessId || !agentDescriptorResponse || !agentDescriptor) return;

    const mutatedAgentDescriptor = produce(agentDescriptor, (draft) => {
      set(draft, defaultPrefix + keyPath, value);
    });

    mutate({
      businessId,
      agentDescriptor: {
        ...mutatedAgentDescriptor,
      },
    });
  }, [agentDescriptor, businessId, agentDescriptorResponse, mutate, value, keyPath]);

  return { value, setValue, onSave };
};
