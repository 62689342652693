import React, { useCallback } from 'react';
import { Box, Button, CircularProgress, ConnectlyCard, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { selectBusinessId, useMeData, useBusinessOrganizationData, useFeatureFlag } from '@hooks';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { ShopifyAbandonedCart } from '../../../../components/ShopifyAbandonedCart';
import { RecommendationActivation } from '../../../../components/RecommendationActivation';
import { SMBPayment } from './SMBPayment';
import { WhatsAppOBAStatusV2 } from '../HomepageV2/WhatsAppOBAStatus';
import { WhatsAppAccountStatus } from '../../../../components/WhatsAppAccountStatus';
import { CONNECTLY_HELP_URL } from './constants';
import { SMBProps } from './types';

export const SMB = ({ recommendationIntegration }: SMBProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome' });
  const theme = useTheme();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: org, isFetching: isFetchingOrganization } = useBusinessOrganizationData({
    businessId: businessId || '',
  });

  const subscriptionStatus = org?.entity?.subscription?.status;

  const isSubscribed = subscriptionStatus === 'SUBSCRIPTION_STATUS_ACTIVE';
  const { ffEnableRecommendedCampaign } = useFeatureFlag(['ffEnableRecommendedCampaign']);
  const handleHelp = useCallback(() => {
    window.open(CONNECTLY_HELP_URL, '_blank');
  }, []);

  if (isFetchingOrganization) {
    return (
      <Box sx={{ position: 'absolute', top: 'calc(50%)', left: 'calc(50%)' }}>
        <CircularProgress />
      </Box>
    );
  }

  return isSubscribed ? (
    <>
      {ffEnableRecommendedCampaign && <RecommendationActivation integrationId={recommendationIntegration?.id} />}
      <WhatsAppAccountStatus />
      <ShopifyAbandonedCart />
      <WhatsAppOBAStatusV2 />
      <ConnectlyCard
        icon={
          <Box
            component="img"
            sx={{ width: theme.spacing(5.4), height: theme.spacing(4.5), mt: 'auto' }}
            src={`${process.env.PUBLIC_URL}/assets/welcome/request_upgrade.png`}
          />
        }
        title={t('updateAccountSection.title')}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: '1 0',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
            {t('updateAccountSection.content')}
          </Label>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: SIZE_SPACING_INTER_COMPONENT,
            }}
          >
            <Button variant="contained" onClick={handleHelp}>
              {t('updateAccountSection.requestUpgrade')}
            </Button>
          </Box>
        </Box>
      </ConnectlyCard>
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <SMBPayment subscriptionStatus={subscriptionStatus} />
    </Box>
  );
};
