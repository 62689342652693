import React, { PropsWithChildren } from 'react';
import { Box, FlowStartIcon, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SourceHandle } from '@components/SourceHandle';
import {
  SIZE_CONTENT_XS,
  SIZE_MEDIA_S,
  SIZE_SPACING_GUTTER_H,
  SIZE_SPACING_GUTTER_V,
  SIZE_SPACING_INTER_COMPONENT,
} from '../../ui-theme';

export const FlowChartCustomAutoReplyNode = ({ id }: PropsWithChildren<{ id: string }>) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '100px',
        width: theme.spacing(SIZE_CONTENT_XS),
        px: SIZE_SPACING_GUTTER_H,
        py: SIZE_SPACING_GUTTER_V,
        gap: SIZE_SPACING_INTER_COMPONENT,
        background: theme.palette.common.white,
        boxShadow: `0px 4px 4px ${theme.palette.grey[200]}`,
      }}
    >
      <FlowStartIcon width={theme.spacing(SIZE_MEDIA_S)} height={theme.spacing(SIZE_MEDIA_S)} />

      <Label variant="h6">Flow Starts</Label>
      <SourceHandle nodeId={id} nodeType="FLOW_OBJECT_TYPE_CUSTOM_AUTO_REPLY" actionType="start" />
    </Box>
  );
};
