import { OnboardingStep } from './types';

export const ONBOARDING_STEP_LIST: (OnboardingStep | OnboardingStep[])[] = [
  'phoneNumberSelector',
  ['phoneNumberOld'],
  'businessName',
  'businessProfilePicture',
  'connectWhatsApp',
  'connectShopify',
  'payment',
];
