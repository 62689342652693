import { ProvisionPhoneNumberInput } from '../entities';
export const mutationProvisionNumberProvider = (log, networkClient) => async (businessId, input) => {
    log.debug('mutationProvisionNumber@start', () => ({}));
    const url = `/internal/v1/businesses/${businessId}/provision/phone_numbers`;
    try {
        const resp = await networkClient.post(url, ProvisionPhoneNumberInput.toJSON(input));
        log.debug('mutationProvisionNumber@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.error('mutationProvisionNumber@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
