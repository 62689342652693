import { ChannelType } from '@connectlyai-idl/models/dist/models/common/channel/models';
import { SearchCustomerQuery, SearchCustomerResult } from '../entities';
export const regexpPhoneNumber = /^[-_.()+0-9\s]+$/;
export const regexpPhoneNumberSpace = /[-_.()\s]+/g;
export const querySearchCustomersProvider = (log, networkClient) => async ({ businessId, searchInput }) => {
    const url = `/v1/businesses/${businessId}/search/customers`;
    log.debug('querySearchCustomers@start', () => ({
        url,
    }));
    try {
        searchInput = searchInput.trim();
        const entries = [
            {
                channelType: ChannelType.CHANNEL_TYPE_UNSPECIFIED,
                query: undefined,
                externalId: undefined,
                name: searchInput,
            },
        ];
        if (regexpPhoneNumber.test(searchInput)) {
            const numberInput = searchInput.replace(regexpPhoneNumberSpace, '');
            entries.push({
                channelType: ChannelType.CHANNEL_TYPE_SMS,
                query: undefined,
                externalId: numberInput,
                name: undefined,
            }, {
                channelType: ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
                query: undefined,
                externalId: numberInput,
                name: undefined,
            }, {
                channelType: ChannelType.CHANNEL_TYPE_WHATSAPP,
                query: undefined,
                externalId: numberInput,
                name: undefined,
            });
        }
        const query = { entries };
        const resp = await networkClient.post(url, SearchCustomerQuery.toJSON(query));
        log.debug('querySearchCustomers@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
        return SearchCustomerResult.fromJSON(resp.data.result);
    }
    catch (err) {
        log.error('querySearchCustomers@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
