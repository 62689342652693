// unreduxified, has only messageTemplates redux

import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { nodesAtom } from '@atoms/flow';

export const useCampaignFlowEdges = () => {
  const nodes = useAtomValue(nodesAtom);

  const isNodePotentialTarget = useCallback(
    (connectionNodeId: string | undefined | null, nodeId: string) => {
      if (!connectionNodeId || !nodeId || connectionNodeId === nodeId) {
        return false;
      }
      const startNode = nodes.find((node) => node.id === connectionNodeId);
      const endNode = nodes.find((node) => node.id === nodeId);
      const startNodeType = startNode?.type || '';
      const endNodeType = endNode?.type || '';
      switch (startNodeType) {
        case 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT': {
          const excludeList: string[] = [];
          if (excludeList.includes(endNodeType)) {
            return false;
          }
          const includeList = ['FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE'];
          return includeList.includes(endNodeType);
        }
        case 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT': {
          const excludeList: string[] = [];
          if (excludeList.includes(endNodeType)) {
            return false;
          }
          const includeList = [
            'FLOW_OBJECT_TYPE_AI_ASSISTANT',
            'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
            'FLOW_OBJECT_TYPE_TIME_DELAY',
          ];
          return includeList.includes(endNodeType);
        }
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
          const excludeList: string[] = [];
          if (excludeList.includes(endNodeType)) {
            return false;
          }
          const includeList = [
            'FLOW_OBJECT_TYPE_AI_ASSISTANT',
            'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
            'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT',
            'FLOW_OBJECT_TYPE_OPT_OUT',
            'FLOW_OBJECT_TYPE_TIME_DELAY',
          ];
          return includeList.includes(endNodeType);
        }
        case 'FLOW_OBJECT_TYPE_AI_ASSISTANT':
          return false;
        case 'FLOW_OBJECT_TYPE_OPT_OUT': {
          const excludeList: string[] = [];
          if (excludeList.includes(endNodeType)) {
            return false;
          }
          const includeList = ['FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE'];
          return includeList.includes(endNodeType);
        }
        case 'FLOW_OBJECT_TYPE_CALL_API':
          return false;
        case 'FLOW_OBJECT_TYPE_TIME_DELAY': {
          const excludeList: string[] = [];
          if (excludeList.includes(endNodeType)) {
            return false;
          }
          const includeList = [
            'FLOW_OBJECT_TYPE_AI_ASSISTANT',
            'FLOW_OBJECT_TYPE_CALL_API',
            'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
            'FLOW_OBJECT_TYPE_OPT_OUT',
          ];
          return includeList.includes(endNodeType);
        }
        default:
          return false;
      }
    },
    [nodes],
  );

  return {
    isNodePotentialTarget,
  };
};
