import React, { FC, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useAtom } from 'jotai';
import {
  DialogContent,
  Label,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@connectlyai-tenets/ui-styled-web';
import { CustomSendSendoutData } from '@connectlyai-tenets/sdk';
import { selectBusinessId, useQueryFlowDocumentVersions, useQuerySendout, useMeData } from '@hooks';
import { useFlowDocumentData } from '../../hooks/useFlowDocumentData';
import { SIZE_CONTENT_L, SIZE_CONTENT_S } from '../../ui-theme';
import { apiKeyAtom, targetPhoneAtom, variableValuesAtom } from './atoms';
import { CampaignAPIDialogProps } from './types';

// eslint-disable-next-line max-len
// see https://github.com/connectlyai/connectly-backend/blob/815932f7568d1a397e5d3df67259b18124ead9fe/go/tenets/fmt/fmt.go
function curlyBraceNamespaceFmt(namespace: string | undefined | null, simpleName: string | undefined | null) {
  if (namespace) {
    return `{${namespace}}:${simpleName}`;
  }
  return simpleName || '';
}

export const useCampaignAPIDialogV3 = ({ flowDocumentId }: Partial<CampaignAPIDialogProps>) => {
  const [targetPhone, setTargetPhone] = useAtom(targetPhoneAtom);
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);
  const [variableValues, setVariableValues] = useAtom(variableValuesAtom);
  const setVariableValue = useCallback(
    (key: string, value: string) => {
      setVariableValues((_prev) => ({ ...variableValues, [key]: value }));
    },
    [variableValues, setVariableValues],
  );

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: flowDocument, isLoading: isFlowDocumentDataLoading } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: flowDocumentId || '',
    enabled: !!businessId && !!flowDocumentId,
  });

  const { data: documentVersions, isLoading: isQueryFlowDocumentVersionsLoading } = useQueryFlowDocumentVersions(
    { businessId: businessId || '', flowDocumentId: flowDocumentId || '' },
    { enabled: !!businessId && !!flowDocumentId },
  );

  const isLoading = isFlowDocumentDataLoading || isQueryFlowDocumentVersionsLoading;

  const fetchedNodes = useMemo(
    () => flowDocument?.entity?.nodes?.map((node) => (node.options ? JSON.parse(node.options) : {})),
    [flowDocument],
  );

  // TODO fetch real campaign name
  const sendoutId = useMemo(() => {
    const sendoutNodeOptions = fetchedNodes?.find((x) => x.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');
    const sendoutNodeData = _.get(sendoutNodeOptions, 'data') as CustomSendSendoutData | undefined;
    return sendoutNodeData?.v3?.sendoutId || '';
  }, [fetchedNodes]);

  const { data: querySendoutResp } = useQuerySendout(
    { businessId: businessId || '', sendoutId },
    { enabled: !!businessId && !!sendoutId },
  );

  const candidateVersions = useMemo(() => {
    // In DESC order of version
    const sortedVersions =
      documentVersions?.entity?.entries?.sort((a, b) => (b.versionId || '')?.localeCompare(a?.versionId || '')) || [];
    return sortedVersions.filter((version) => version.deployIntent === 'FLOW_DOCUMENT_DEPLOY_INTENT_DEPLOY');
  }, [documentVersions]);

  const candidateVersion = useMemo(
    () => (candidateVersions.length > 0 ? candidateVersions[0] : null),
    [candidateVersions],
  );

  const variableDeclarations = useMemo(
    () =>
      candidateVersion?.compileOutput?.sendoutV3?.dependencies?.dependencies
        ?.filter((dep) => dep.variable)
        .map((variableDep) => variableDep.variable?.variable) || [],
    [candidateVersion],
  );

  const variablesObject = useMemo(() => {
    const result = {};
    variableDeclarations?.forEach((variableDecl) => {
      _.set(
        result,
        curlyBraceNamespaceFmt(variableDecl?.namespaceCanonical, variableDecl?.simpleNameCanonical),
        'CHANGE_TO_VALUE',
      );
    });
    Object.keys(variableValues).forEach((key) => {
      _.set(result, key, variableValues[key]);
    });
    return result;
  }, [variableDeclarations, variableValues]);

  const requestBody = useMemo(
    () => ({
      entries: [
        {
          client: targetPhone || 'CHANGE_TO_TARGET_PHONE_NUMBER',
          campaignName: querySendoutResp?.entity?.campaign?.nameNormalized || 'CHANGE_TO_CAMPAIGN_NAME',
          variables: variablesObject,
          campaignVersion: candidateVersion?.name,
        },
      ],
    }),
    [variablesObject, targetPhone, candidateVersion, querySendoutResp],
  );

  const curlPath = useMemo(
    () => `${process.env.REACT_APP_INBOX_API_GATEWAY}/v1/businesses/${businessId}/send/campaigns`,
    [businessId],
  );

  const curlSnippet = useMemo(() => {
    if (!businessId) return '';
    return `curl  --location '${curlPath}' \\\n`
      .concat(`  --header 'Content-Type: application/json' \\\n`)
      .concat(`  --header 'x-api-key: ${apiKey || 'CHANGE_TO_API_KEY'}' \\\n`)
      .concat(`  --data '${JSON.stringify(requestBody, null, '\t\t')}'`);
  }, [apiKey, businessId, curlPath, requestBody]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(curlSnippet);
  }, [curlSnippet]);

  const variablesForm = useMemo(
    () =>
      variableDeclarations.map((variableDecl) => {
        const variableKey = curlyBraceNamespaceFmt(variableDecl?.namespaceCanonical, variableDecl?.simpleNameCanonical);
        return (
          <>
            <Label variant="body2">Value for {variableKey}</Label>
            <TextField
              key={variableKey}
              value={variableValues[variableKey]}
              onChange={(e) => setVariableValue(variableKey, e.target.value)}
              sx={{ background: (theme) => theme.palette.common.white }}
              placeholder={variableKey}
            />
          </>
        );
      }),
    [variableDeclarations, variableValues, setVariableValue],
  );

  const isSupported = useMemo(() => flowDocument?.entity?.type === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3', [flowDocument]);

  return {
    isLoading,
    isSupported,
    curlSnippet,
    handleCopy,
    targetPhone,
    setTargetPhone,
    apiKey,
    setApiKey,
    variablesForm,
  };
};
export const CampaignAPIDialogV3: FC<CampaignAPIDialogProps> = (props) => {
  const {
    isLoading,
    isSupported,
    apiKey,
    curlSnippet,
    handleCopy,
    variablesForm,
    setApiKey,
    setTargetPhone,
    targetPhone,
  } = useCampaignAPIDialogV3(props);

  return (
    <>
      {!isSupported && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_S),
          }}
        >
          <Alert severity="warning" sx={{ width: 'fit-content', mx: 'auto', mt: 2 }}>
            <Label variant="subtitle1">Sorry, this campaign is not supported in API Builder.</Label>
          </Alert>
        </DialogContent>
      )}

      {isSupported && isLoading && (
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: (theme) => theme.spacing(SIZE_CONTENT_L),
            minHeight: (theme) => theme.spacing(SIZE_CONTENT_L),
          }}
        >
          <CircularProgress />
        </DialogContent>
      )}
      {isSupported && (
        <DialogContent
          sx={{ width: (theme) => theme.spacing(SIZE_CONTENT_L), display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.secondary.light,
              borderRadius: (theme) => theme.spacing(1),
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              justifyContent: 'flex-start',
              padding: (theme) => theme.spacing(2),
              width: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flex: '0 1 auto',
                justifyContent: 'space-between',
                width: 1,
              }}
            >
              <Label variant="subtitle2">Easy Builder</Label>
            </Box>
            <Label variant="body1">Phone number to send</Label>
            <TextField
              value={targetPhone}
              onChange={(e) => setTargetPhone(e.target.value)}
              sx={{ background: (theme) => theme.palette.common.white, mb: 2 }}
              placeholder='include "+" and country code, e.g. "+1234567890"'
            />
            <Label variant="body1">API key to use</Label>
            <TextField
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              sx={{ background: (theme) => theme.palette.common.white, mb: 2 }}
              placeholder="go to Settings > Business Keys to create a new key"
            />
            {!!variablesForm.length && <Label variant="body1">Template Parameters</Label>}
            {!!variablesForm.length && variablesForm}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              background: (theme) => theme.palette.secondary.light,
              borderRadius: (theme) => theme.spacing(1),
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              justifyContent: 'flex-start',
              padding: (theme) => theme.spacing(2),
              width: 1,
            }}
          >
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flex: '0 1 auto',
                justifyContent: 'space-between',
                width: 1,
              }}
            >
              <Label variant="subtitle2">Curl Snippet</Label>
              <Button
                variant="text"
                color="primary"
                disableRipple
                sx={{
                  display: 'flex',
                  padding: 0,
                }}
                onClick={handleCopy}
              >
                <Label variant="body2">COPY TEXT</Label>
              </Button>
            </Box>
            <Box
              sx={{
                background: (theme) => theme.palette.common.white,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: (theme) => theme.spacing(1),
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                flex: '1 0 auto',
                mt: 1,
                p: 1.5,
                width: 1,
              }}
            >
              <Label
                sx={{
                  borderRadius: '10px',
                  wordBreak: 'break-word',
                  cursor: 'text',
                  display: 'flex',
                  whiteSpace: 'pre-wrap',
                }}
                variant="body2"
              >
                {curlSnippet}
              </Label>
            </Box>
          </Box>
        </DialogContent>
      )}
    </>
  );
};
