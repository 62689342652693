import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { UpdateExecutionOptions, UpdateExecutionParameters, UPDATE_EXECUTION_URL } from './types';

export const useMutationSendoutExecutionOptions = (options?: UpdateExecutionOptions) =>
  useMutation(
    ({ businessId, sendoutId, frequencyCapOption = 'FREQUENCY_CAP_OPTION_DISABLED' }: UpdateExecutionParameters) =>
      api.campaignV3(UPDATE_EXECUTION_URL, 'post', {
        path: { 'input.businessId': businessId, 'input.sendoutId': sendoutId },
        body: { input: { frequencyCapOption } },
      }),
    options,
  );
