import { useAuth } from '@connectlyai-sdks/auth';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { LoginProps } from './types';

export const useOnLogin = ({ audience, redirectPath }: LoginProps) => {
  const [loginInit, setLoginInit] = useState(false);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const onLogin = () => {
    const isAuthCallBack = location.search.includes('code=') && location.search.includes('state=');
    if (!isLoading && !isAuthenticated && !isAuthCallBack && !loginInit) {
      (async function login() {
        await loginWithRedirect({
          audience,
          appState: { returnTo: redirectPath },
        });
        setLoginInit(true);
      })();
    } else if (isAuthenticated) {
      history.replace(redirectPath);
    }
  };

  return {
    onLogin,
  };
};
