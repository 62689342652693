import { createContext } from 'use-context-selector';
import { FlowChangeAppliers } from '../hooks/useFlowChangeAppliers/types';
import { FlowHandlers } from '../hooks/useFlowHandlers/types';

export type FlowDeps = {
  flowChangeAppliers: FlowChangeAppliers;
  flowHandlers: FlowHandlers;
  isDocumentEditable: boolean;
  isNodePotentialTarget: (connectionNodeId: string | undefined | null, nodeId: string) => boolean;
  supportsLinkTracking: boolean;
  supportsVariables: boolean;
};

// TODO the following is a bit hacky
// consider the solution outlined in
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FlowContext = createContext<FlowDeps>(undefined!);
