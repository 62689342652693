import React, { PropsWithChildren } from 'react';
import { Box, Card, Label, MessageEventIcon, WhatsAppIcon, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SourceHandle } from '@components/SourceHandle';
import { TargetHandle } from '@components/TargetHandle';
import { SIZE_MEDIA_S, SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

export const FlowChartIncomingRoomEvent = ({ id }: PropsWithChildren<{ id: string }>) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: 300,
        padding: 1.5,
        borderRadius: 2,
        overflow: 'visible',
        background: theme.palette.common.black,
      }}
    >
      <TargetHandle nodeId={id} nodeType="FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT" actionType="on-happens" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: SIZE_SPACING_INTER_COMPONENT,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MessageEventIcon width={theme.spacing(SIZE_MEDIA_S)} height={theme.spacing(SIZE_MEDIA_S)} color="#303030" />

          <Label
            variant="h6"
            sx={{
              color: theme.palette.common.white,
            }}
          >
            Event
          </Label>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: 1.5,
            borderRadius: 1,
            background: theme.palette.common.white,
            alignItems: 'center',
            gap: SIZE_SPACING_INTER_COMPONENT,
          }}
        >
          <WhatsAppIcon width={theme.spacing(SIZE_MEDIA_XS)} height={theme.spacing(SIZE_MEDIA_XS)} />
          <Label variant="body1">Automatically reply to people who message you on WhatsApp.</Label>
        </Box>
      </Box>
      <SourceHandle nodeId={id} nodeType="FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT" actionType="event" />
    </Card>
  );
};
