import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { QueryAgentMetricsPathParams, QueryAgentMetricsBodyParams, QueryAgentMetricsData } from './types';

export const fetchQueryAgentMetrics = async (
  pathParams: QueryAgentMetricsPathParams,
  bodyParams: QueryAgentMetricsBodyParams,
): Promise<QueryAgentMetricsData> => {
  const endpoint = '/internal/v1/businesses/{query.businessId}/query_agent_metrics';
  if (!pathParams['query.businessId']) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  return api.analyticsV1(endpoint, 'post', {
    path: pathParams,
    body: { query: bodyParams },
  }) as Promise<QueryAgentMetricsData>;
};

export const useQueryAgentMetrics = (
  pathParams: QueryAgentMetricsPathParams,
  bodyParams: QueryAgentMetricsBodyParams,
  options?: UseQueryOptions<QueryAgentMetricsData, Error>,
) => {
  return useQuery<QueryAgentMetricsData, Error>(
    ['agentMetrics', pathParams['query.businessId'], bodyParams.timeRangeStart, bodyParams.timeRangeEnd],
    () => fetchQueryAgentMetrics(pathParams, bodyParams),
    options,
  );
};
