import { createSelector } from '@reduxjs/toolkit';
import { AttachmentType } from '@connectlyai-idl/models/dist/models/event/connectly/models';
export const selectRoomSlice = (state) => state.roomSlice;
export const selectRoom = createSelector(selectRoomSlice, (state) => state.room);
export const selectIsMediViewerOpen = createSelector(selectRoom, (state) => state.isMediaViewerOpen);
export const selectDto = createSelector(selectRoom, (state) => state.dto);
export const selectEvents = createSelector(selectDto, (state) => state === null || state === void 0 ? void 0 : state.events);
export const selectAttachments = createSelector(selectEvents, (state) => {
    return state === null || state === void 0 ? void 0 : state.reduce((acc, event) => {
        var _a, _b;
        if (((_b = (_a = event.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'message') {
            return [...acc, ...event.connectlyEvent.eventOneof.message.attachments];
        }
        return acc;
    }, []);
});
export const selectMediaItems = createSelector(selectAttachments, (state) => state === null || state === void 0 ? void 0 : state.reduce((acc, attachment) => {
    var _a, _b, _c;
    if (attachment.type === AttachmentType.ATTACHMENT_TYPE_IMAGE) {
        return [
            ...acc,
            {
                id: ((_a = attachment.attachmentOneof) === null || _a === void 0 ? void 0 : _a.asset.id) || '',
                original: ((_b = attachment.attachmentOneof) === null || _b === void 0 ? void 0 : _b.asset.url) || '',
                thumbnail: ((_c = attachment.attachmentOneof) === null || _c === void 0 ? void 0 : _c.asset.url) || '',
            },
        ];
    }
    return acc;
}, []));
