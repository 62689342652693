import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFilePicker } from 'use-file-picker';
import { useContextSelector } from 'use-context-selector';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { selectedNodeAtom } from '@atoms/flow';
import { v4 as uuidv4 } from 'uuid';
import { useAtomValue } from 'jotai';
import { AddVariable } from '@components/AddVariable';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { HideableAlert } from '@components/HideableAlert';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  Label,
  MenuItem,
  PhotoIcon,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldsIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import {
  selectEditedNodeId,
  selectHeaderText,
  selectHeaderMediaMapping,
  selectHeaderError,
  selectIsHeaderEnabled,
  setTextHeader,
  setMediaHeaderExample,
  setMediaHeaderMapping,
  selectHeaderType,
  setIsComponentEnabled,
  HeaderType,
  MediaType,
  setHeaderType,
  selectHeaderMedia,
  MEDIA_TYPES_DICT,
} from '../../state/messageTemplates';
import { TemplateBuilderItem } from '../TemplateBuilderItem';
import { TemplateBuilderHint } from '../TemplateBuilderHint';
import { TemplateBuilderTitle } from '../TemplateBuilderTitle';
import { HEADER_TEXT_MAX_LENGTH } from './constants';
import {
  getAcceptedFileFormats,
  getHeaderLabel,
  getMaxFileSize,
  isMediaHeaderType,
  scrollToHTMLInputElement,
} from '../../utils';
import { selectBusinessId, useMediaUploadMutation, useMeData } from '../../hooks';
import { parseVariables, useFlowVariables } from '../../hooks/useFlowVariables';
import { getAcceptedFileExtensionRegex, getMediaHeaderType } from '../../utils/messageTemplates';
import { FlowContext } from '../../contexts';
import { FlowVariableDialog, FlowVariableDialogRef } from '../FlowVariableDialog';
import { CodeMirrorTextField } from '../CodeMirrorTextField';
import { FlowVariableExtended } from '../../hooks/useFlowVariables/types';

const ffEnableSpacesInHeaderVariable = getFlagByName('ffEnableSpacesInHeaderVariable');
const useTemplateBuilderHeader = () => {
  const dispatch = useDispatch();
  const supportsVariables = useContextSelector(FlowContext, (context) => context.supportsVariables);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading: isUploading, mutate: uploadFile } = useMediaUploadMutation();
  const editedNodeId = useSelector(selectEditedNodeId);
  const headerText = useSelector(selectHeaderText);
  const headerMedia = useSelector(selectHeaderMedia, shallowEqual);
  const type = useSelector(selectHeaderType);
  const error = useSelector(selectHeaderError);
  const isEnabled = useSelector(selectIsHeaderEnabled);
  const isLoaded = useRef(false);
  const mediaHeaderVarDialogRef = useRef<FlowVariableDialogRef>(null);
  const mediaHeaderAddVarRef = React.useRef<HTMLButtonElement>(null);
  const mediaHeaderRemoveVarRef = React.useRef<HTMLButtonElement>(null);
  const headerVarContainerRef = React.useRef<HTMLDivElement>(null);
  const mediaHeaderCodeMirrorRef = React.useRef<ReactCodeMirrorRef>({});
  const textHeaderVarDialogRef = useRef<FlowVariableDialogRef>(null);
  const textHeaderAddVarRef = React.useRef<HTMLButtonElement>(null);
  const textHeaderCodeMirrorRef = React.useRef<ReactCodeMirrorRef>({});
  const [filePickerError, setFilePickerError] = useState('');
  const headerMediaMapping = useSelector(selectHeaderMediaMapping);
  const [mediaType, setMediaType] = useState<MediaType>(headerMediaMapping ? 'VARIABLE' : 'MEDIA');

  useEffect(() => {
    if (isLoaded.current && isEnabled) {
      scrollToHTMLInputElement('message-template-header');
    }
  }, [isEnabled]);

  useEffect(() => {
    isLoaded.current = true;
  }, []);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear }] = useFilePicker({
    accept: getAcceptedFileFormats(type),
    multiple: false,
    maxFileSize: getMaxFileSize(type),
  });

  // Initialize media type selector when template is changed
  useEffect(() => {
    setMediaType(headerMediaMapping ? 'VARIABLE' : 'MEDIA');
  }, [editedNodeId]);

  useEffect(() => {
    if (!businessId) {
      return;
    }

    if (loading) {
      return;
    }

    if (errors.length > 0) {
      const { fileSizeToolarge: fileSizeTooLarge } = errors[0];
      if (fileSizeTooLarge) {
        setFilePickerError(
          `File size exceeds limit (${getMaxFileSize(type)}MB) and cannot be uploaded. Please select another one.`,
        );
      } else {
        setFilePickerError(`This file cannot be uploaded. Please select another one.`);
      }
      return;
    }
    setFilePickerError('');

    if (!filesContent.length || !plainFiles.length) {
      return;
    }

    const fileExtensionRegex = getAcceptedFileExtensionRegex(type);
    if (fileExtensionRegex && !fileExtensionRegex.test(plainFiles[0].name)) {
      setFilePickerError(`This file extension cannot be uploaded for header with ${getMediaHeaderType(type)}`);
      return;
    }

    uploadFile(
      {
        businessId,
        file: plainFiles[0],
      },
      {
        onSettled: (data, _e) => {
          if (data) {
            if (headerMediaMapping) {
              dispatch(setMediaHeaderMapping(null));
            }
            dispatch(setMediaHeaderExample(data.uri));
          }
        },
      },
    );
  }, [businessId, dispatch, errors, filesContent.length, loading, plainFiles, type, uploadFile]);

  const selectedNode = useAtomValue(selectedNodeAtom);
  const { populateVariables, canonicalize, addVariables, variablesSet } = useFlowVariables();
  const populatedHeaderText = useMemo(
    () =>
      selectedNode?.data?.v1?.parameterMapping?.mappings
        ? populateVariables(headerText, 'header_text.', selectedNode?.data?.v1?.parameterMapping?.mappings)
        : headerText,
    [headerText, populateVariables, selectedNode?.data?.v1?.parameterMapping?.mappings],
  );

  const headerHint: string[] = useMemo(() => {
    if (mediaType === 'MEDIA') {
      if (type === HeaderType.Image) {
        return ['Select a PNG or JPEG file less than 5MB.', 'Recommended size: 800x418 pixels.'];
      }
      if (type === HeaderType.Video) {
        return ['Select an MP4 file less than 16MB.'];
      }
      if (type === HeaderType.Document) {
        return ['Select a PDF less than 100MB.'];
      }
    }
    return [];
  }, [mediaType, type]);

  const handleTextHeaderChange = useCallback(
    (newHeader: string) => {
      dispatch(setTextHeader(newHeader));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleTextHeaderChange(event.target.value);
    },
    [handleTextHeaderChange],
  );

  const handleTextHeaderEditorChange = useCallback(
    (value: string) => {
      const newVariables = parseVariables(value).reduce((acc: Array<FlowVariableExtended>, variable) => {
        if (!variablesSet.has(variable)) {
          acc.push({
            id: uuidv4(),
            type: 'FLOW_TYPE_STRING',
            namespace: '',
            namespaceCanonical: '',
            simpleName: variable,
            simpleNameCanonical: canonicalize(variable),
            validators: [],
          });
        }
        return acc;
      }, []);

      addVariables(newVariables);
      handleTextHeaderChange(value);
    },
    [handleTextHeaderChange, variablesSet, canonicalize, addVariables],
  );

  const handleMediaHeaderEditorChange = useCallback(
    (value: string | null) => {
      if (headerMedia.length > 0) {
        dispatch(setMediaHeaderExample(null));
      }
      value = value ? value.trim() : value;
      if (value && !ffEnableSpacesInHeaderVariable) {
        value = value.replace(/\s/g, '_');
      }
      dispatch(setMediaHeaderMapping(value));
    },
    [dispatch, headerMedia],
  );

  const handleEnabledChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
      dispatch(setIsComponentEnabled({ header: !isEnabled }));
    },
    [dispatch, isEnabled],
  );

  const handleTextHeaderOpenDialog = useCallback(() => {
    textHeaderVarDialogRef.current?.handleOpenDialog();
  }, [textHeaderVarDialogRef]);
  const handleMediaHeaderOpenDialog = useCallback(() => {
    mediaHeaderVarDialogRef.current?.handleOpenDialog();
  }, [mediaHeaderVarDialogRef]);

  const handleChangeType = useCallback(
    (event: SelectChangeEvent) => {
      const headerType = parseInt(event.target.value, 10) as HeaderType;
      clear();
      if (headerType === HeaderType.Text) {
        requestAnimationFrame(() => {
          const element = document.getElementById('message-template-header') as HTMLInputElement;
          element?.select();
        });
      }
      dispatch(setHeaderType(headerType));
    },
    [clear, dispatch],
  );

  const handleChangeMediaType = useCallback(
    (event: SelectChangeEvent) => {
      setMediaType(event.target.value as MediaType);
    },
    [setMediaType],
  );

  const handleUpload = useCallback(() => {
    clear();
    openFileSelector();
  }, [openFileSelector, clear]);

  const handleCloseFilePickerError = useCallback(() => {
    setFilePickerError('');
  }, [setFilePickerError]);

  return {
    headerVarContainerRef,
    textHeaderAddVarRef,
    textHeaderCodeMirrorRef,
    textHeaderVarDialogRef,
    mediaHeaderAddVarRef,
    mediaHeaderRemoveVarRef,
    mediaHeaderCodeMirrorRef,
    mediaHeaderVarDialogRef,
    headerHint,
    headerText: populatedHeaderText,
    headerMedia,
    headerMediaMapping,
    mediaType,
    type,
    error,
    filePickerError,
    isEnabled,
    isUploading,
    supportsVariables,
    handleTextHeaderOpenDialog,
    handleMediaHeaderOpenDialog,
    onChange: handleChange,
    onChangeType: handleChangeType,
    onChangeMediaType: handleChangeMediaType,
    onCloseFilePickerError: handleCloseFilePickerError,
    onEnabledChange: handleEnabledChange,
    onUpload: handleUpload,
    handleTextHeaderEditorChange,
    handleMediaHeaderEditorChange,
  };
};

export const TemplateBuilderHeader: FC = () => {
  const {
    headerVarContainerRef,
    textHeaderAddVarRef,
    textHeaderCodeMirrorRef,
    textHeaderVarDialogRef,
    mediaHeaderAddVarRef,
    mediaHeaderRemoveVarRef,
    mediaHeaderCodeMirrorRef,
    mediaHeaderVarDialogRef,
    headerHint,
    headerText,
    headerMedia,
    headerMediaMapping,
    type,
    error,
    filePickerError,
    isEnabled,
    isUploading,
    supportsVariables,
    onChange,
    onChangeType,
    onChangeMediaType,
    mediaType,
    onCloseFilePickerError,
    onEnabledChange,
    onUpload,
    handleTextHeaderOpenDialog,
    handleMediaHeaderOpenDialog,
    handleTextHeaderEditorChange,
    handleMediaHeaderEditorChange,
  } = useTemplateBuilderHeader();
  const hint =
    'Add either a title or an image to make your message more compelling. You can only add one header per message.';
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <TemplateBuilderItem error={Boolean(error)}>
      <Dialog open={Boolean(filePickerError)} transitionDuration={0} onClose={onCloseFilePickerError}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{filePickerError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseFilePickerError}>Ok</Button>
        </DialogActions>
      </Dialog>
      <TemplateBuilderTitle
        icon={type === HeaderType.Text ? <TextFieldsIcon color="action" /> : <PhotoIcon color="action" />}
        hint={hint}
        optional={{ checked: isEnabled, onChange: onEnabledChange }}
      >
        {type === HeaderType.Text ? 'Header' : 'Media'}
      </TemplateBuilderTitle>
      <Collapse in={isEnabled}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            fullWidth
            value={type.toString()}
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            onChange={onChangeType}
            sx={{ mt: 1.5, borderRadius: '10px', '& .MuiSelect-select': { py: '11px', pl: '12px' } }}
          >
            {[HeaderType.Text, HeaderType.Image, HeaderType.Video, HeaderType.Document].map((t) => (
              <MenuItem key={t} value={t.toString()}>
                {getHeaderLabel(t)}
              </MenuItem>
            ))}
          </Select>

          <Collapse in={type === HeaderType.Text}>
            {supportsVariables && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <CodeMirrorTextField
                  value={headerText}
                  codeMirrorUpdate={textHeaderVarDialogRef?.current?.handleCodeMirrorUpdate}
                  editable
                  error={Boolean(error)}
                  handleVariableClick={textHeaderVarDialogRef?.current?.handleVariableClick}
                  setValue={handleTextHeaderEditorChange}
                  ref={textHeaderCodeMirrorRef}
                  containerStyle={{ paddingRight: theme.spacing(5) }}
                  endAdornment={
                    <InputAdornment
                      sx={{
                        color: headerText.length > HEADER_TEXT_MAX_LENGTH ? 'error.main' : 'text.secondary',
                        userSelect: 'none',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        height: '18px',
                        right: '.5rem',
                      }}
                      disableTypography
                      disablePointerEvents
                      position="end"
                    >
                      <Label variant="body2" sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                        {`${headerText.length}/${HEADER_TEXT_MAX_LENGTH}`}
                      </Label>
                    </InputAdornment>
                  }
                />
                <AddVariable
                  onClick={handleTextHeaderOpenDialog}
                  ref={textHeaderAddVarRef}
                  sx={{ mt: 1, py: 0.5, px: 1, textTransform: 'none' }}
                />
                <FlowVariableDialog
                  anchorEl={textHeaderAddVarRef?.current}
                  codeMirrorRef={textHeaderCodeMirrorRef}
                  value={headerText}
                  handleEditorChange={handleTextHeaderEditorChange}
                  ref={textHeaderVarDialogRef}
                />
              </Box>
            )}
            {!supportsVariables && (
              <TextField
                id="message-template-header"
                autoComplete="off"
                placeholder="Enter text"
                error={Boolean(error)}
                helperText={error}
                fullWidth
                value={headerText}
                onChange={onChange}
                variant="outlined"
                inputProps={{ maxLength: HEADER_TEXT_MAX_LENGTH }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`${headerText.length}/${HEADER_TEXT_MAX_LENGTH}`}</InputAdornment>
                  ),
                }}
                sx={{
                  mt: 1.5,
                  '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
                  '& .MuiInputBase-input': { p: '11px 12px' },
                }}
              />
            )}
          </Collapse>

          <Collapse in={isMediaHeaderType(type)}>
            {supportsVariables && (
              <Select
                fullWidth
                value={mediaType}
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                onChange={onChangeMediaType}
                sx={{ mt: 1.5, borderRadius: '10px', '& .MuiSelect-select': { py: '11px', pl: '12px' } }}
              >
                {Object.entries(MEDIA_TYPES_DICT).map((entry) => {
                  const [value, label] = entry;
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            <FormControl
              error={Boolean(error) && isMediaHeaderType(type)}
              variant="standard"
              sx={{
                width: '100%',
              }}
            >
              <Box
                ref={headerVarContainerRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'baseline',
                  mt: 1.5,
                  overflow: 'hidden',
                }}
              >
                {mediaType === 'VARIABLE' && supportsVariables && (
                  <>
                    <CodeMirrorTextField
                      value={headerMediaMapping}
                      editable={false}
                      handleVariableClick={mediaHeaderVarDialogRef?.current?.handleVariableClick}
                      setValue={handleMediaHeaderEditorChange}
                      ref={mediaHeaderCodeMirrorRef}
                      containerStyle={{
                        height: theme.spacing(5),
                        border: '1px solid rgb(192, 192, 192)',
                        borderRadius: '10px 10px 10px 10px',
                        padding: `${theme.spacing(0.8125)} 
                        ${theme.spacing(1.5)} 
                        ${theme.spacing(0.8125)} 
                        ${theme.spacing(0.5)}`,
                      }}
                      endAdornment={
                        headerMediaMapping ? (
                          <Button
                            ref={mediaHeaderRemoveVarRef}
                            sx={{
                              fontSize: '12px',
                              textTransform: 'none',
                              position: 'absolute',
                              right: 0,
                              top: '50%',
                              transform: 'translate(0, -50%)',
                            }}
                            onClick={() => {
                              handleMediaHeaderEditorChange(null);
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                          <AddVariable
                            onClick={handleMediaHeaderOpenDialog}
                            ref={mediaHeaderAddVarRef}
                            sx={{
                              position: 'absolute',
                              left: '4px',
                              top: '50%',
                              transform: 'translate(0, -50%)',
                            }}
                          />
                        )
                      }
                    />
                    <Label variant="body2" sx={{ mt: 0.25, color: (t) => t.palette.text.secondary }}>
                      Value should be a URL.
                    </Label>
                    {!ffEnableSpacesInHeaderVariable && (
                      <Label variant="body2" sx={{ mt: 0.25, color: (t) => t.palette.text.secondary }}>
                        No spaces allowed in variable name.
                      </Label>
                    )}
                    <FlowVariableDialog
                      anchorEl={headerVarContainerRef?.current}
                      codeMirrorRef={mediaHeaderCodeMirrorRef}
                      value={headerMediaMapping}
                      handleEditorChange={handleMediaHeaderEditorChange}
                      ref={mediaHeaderVarDialogRef}
                      kind="STRING_URL"
                    />
                  </>
                )}
                {mediaType === 'MEDIA' && (
                  <Button
                    disabled={isUploading}
                    variant="outlined"
                    color="secondary"
                    onClick={onUpload}
                    sx={{ flexShrink: 0 }}
                  >
                    {headerMedia?.length > 0 ? 'Edit' : 'Upload'}
                  </Button>
                )}
              </Box>
            </FormControl>
          </Collapse>
          {error && (
            <Label variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Label>
          )}
          <Collapse in={Boolean(headerHint.length) && isLargeScreen}>
            <TemplateBuilderHint>
              {headerHint.map((h, i) => (
                <>
                  {h}
                  {i < headerHint.length - 1 && <br />}
                </>
              ))}
            </TemplateBuilderHint>
            {type === HeaderType.Image && (
              <HideableAlert
                storageKey="tooltip-template-image-header"
                title="When should I use a header image?"
                sx={{ mt: 3 }}
              >
                Images can enrich the message experience and help maintain engagement. Use eye-catching images that
                summarize the message (eg discounts, gifts etc.)
              </HideableAlert>
            )}
          </Collapse>
        </Box>
      </Collapse>
    </TemplateBuilderItem>
  );
};
