import {
  ACIntegrationType,
  ReminderParametersList,
  useInstallIntegrationMutation,
} from '@hooks/useInstallIntegrationMutation';
import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { useCallback, useContext, useEffect } from 'react';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { useAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';
import { ActivationScreen } from '@components/AbandonedCartActivation/constants';
import { ACAlwaysOnReminder, ShopifyAbandonedCartDiscount } from '@hooks/useSettingsData/types';
import {
  discountAtom,
  enableReminderAtom,
  enableSafeWindowAtom,
  isPersonalMessageAtom,
  languageAtom,
  reminderDiscountAtom,
  screenAtom,
  vtexAccountNameAtom,
  vtexDiscountAtom,
  vtexEnableReminderAtom,
  vtexEnableSafeWindowAtom,
  vtexIsPersonalMessageAtom,
  vtexLanguageAtom,
  vtexReminderDiscountAtom,
  vtexEnableHeaderImageAtom,
} from '@atoms/abandonedcart';
import { track } from 'src/utils';
import { NotificationContext } from '../../contexts';

export const useAbandonedCartActivation = (
  integrationType: ACIntegrationType,
  integrationParameters: ACAlwaysOnReminder | undefined,
  reminder?: ReminderParametersList,
) => {
  const isVtexIntegration = integrationType === 'INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT';
  const [vtexAccountName] = useAtom(vtexAccountNameAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { notificationProvider } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  const [screen, setScreen] = useAtom(screenAtom);
  const [language, setLanguage] = useAtom(isVtexIntegration ? vtexLanguageAtom : languageAtom);
  const [discount, setDiscount] = useAtom(isVtexIntegration ? vtexDiscountAtom : discountAtom);
  const [reminderDiscount, setReminderDiscount] = useAtom(
    isVtexIntegration ? vtexReminderDiscountAtom : reminderDiscountAtom,
  );
  const [isPersonalMessage, setIsPersonalMessage] = useAtom(
    isVtexIntegration ? vtexIsPersonalMessageAtom : isPersonalMessageAtom,
  );
  const [enableReminder, setEnableReminder] = useAtom(isVtexIntegration ? vtexEnableReminderAtom : enableReminderAtom);
  const [enableHeaderImage, setEnableHeaderImage] = useAtom(vtexEnableHeaderImageAtom);
  const [enableSafeWindow, setEnableSafeWindow] = useAtom(
    isVtexIntegration ? vtexEnableSafeWindowAtom : enableSafeWindowAtom,
  );

  const { mutate: mutateInstallIntegration, isLoading: isLoadingInstallIntegration } = useInstallIntegrationMutation({
    onSuccess: () => {
      setScreen(ActivationScreen.Conclusion);
      queryClient.invalidateQueries(['settings']);
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error installing integration',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  useEffect(() => {
    if (integrationParameters && 'discount' in integrationParameters && integrationParameters?.discount) {
      setDiscount(integrationParameters.discount);
    }
    if (integrationParameters?.reminderTemplateLanguage) {
      setLanguage(integrationParameters.reminderTemplateLanguage);
    }
    if (reminder?.discount) {
      setReminderDiscount(reminder.discount);
    }
    if (integrationParameters?.personalizedMessageEnabled) {
      setIsPersonalMessage(integrationParameters.personalizedMessageEnabled);
    }
    if (reminder) {
      setEnableReminder(reminder !== undefined);
    }
    if (
      integrationParameters &&
      'rruleWindow' in integrationParameters &&
      integrationParameters?.rruleWindow !== undefined
    ) {
      setEnableSafeWindow(Boolean(integrationParameters.rruleWindow));
    }
    if (integrationParameters?.headerImageEnabled !== undefined) {
      setEnableHeaderImage(integrationParameters.headerImageEnabled);
    }
  }, [
    integrationParameters,
    reminder,
    setDiscount,
    setLanguage,
    setReminderDiscount,
    setIsPersonalMessage,
    setEnableReminder,
    setEnableSafeWindow,
    setEnableHeaderImage,
  ]);

  const handleBack = useCallback(() => {
    setScreen(ActivationScreen.HomeScreen);
  }, [setScreen]);

  const handleSetScreen = useCallback(
    (page: ActivationScreen) => {
      setScreen(page);
    },
    [setScreen],
  );

  const handleSetReminder = useCallback((newReminder: boolean) => setEnableReminder(newReminder), [setEnableReminder]);

  const handleSetEnableHeaderImage = useCallback(
    (enableImage: boolean) => setEnableHeaderImage(enableImage),
    [setEnableHeaderImage],
  );

  const handleDiscountSave = useCallback(
    (discountInfo: ShopifyAbandonedCartDiscount) => {
      if (discountInfo.discountCode && discountInfo.discountValue) {
        track('(AC) Discount first message', { businessId });
      }
      setDiscount(discountInfo);
      setScreen(ActivationScreen.HomeScreen);
    },
    [businessId, setDiscount, setScreen],
  );

  const handleReminderDiscountSave = useCallback(
    (discountInfo: ShopifyAbandonedCartDiscount) => {
      if (discountInfo.discountCode && discountInfo.discountValue) {
        track('(AC) Discount second message', { businessId });
      }
      setReminderDiscount(discountInfo);
      setScreen(ActivationScreen.HomeScreen);
    },
    [businessId, setReminderDiscount, setScreen],
  );

  const handleSetSafeWindow = useCallback(
    (enableSafeDelivery: boolean) => {
      setEnableSafeWindow(enableSafeDelivery);
      setScreen(ActivationScreen.HomeScreen);
    },
    [setEnableSafeWindow, setScreen],
  );

  const handleLanguageChange = useCallback(
    (newLanguage: string) => {
      setLanguage(newLanguage);
      setScreen(ActivationScreen.HomeScreen);
    },
    [setLanguage, setScreen],
  );

  const handleSetMessageType = useCallback(
    (isPersonal: boolean) => {
      setIsPersonalMessage(isPersonal);
      setScreen(ActivationScreen.HomeScreen);
    },
    [setIsPersonalMessage, setScreen],
  );

  const handleSubmit = useCallback(() => {
    const firstMessage: ReminderParametersList = {
      reminderTemplateId: '',
      reminderTemplateLanguage: language,
      waitBeforeRemind: '3600s',
      trackingCode: '',
      headerImageEnabled: enableHeaderImage,
      linkTrackingEnabled: true,
      conversionTrackingEnabled: true,
      personalizedMessageEnabled: isPersonalMessage,
    };
    if (discount.discountCode && discount.discountValue) {
      firstMessage.discount = {
        discountType: discount?.discountType ?? 'DISCOUNT_TYPE_UNSPECIFIED',
        discountCode: discount?.discountCode ?? '',
        discountValue: discount?.discountValue ?? 0,
      };
    }
    const reminderMessage: ReminderParametersList = {
      reminderTemplateLanguage: language,
      trackingCode: '',
      waitBeforeRemind: '36000s',
    };
    if (reminderDiscount.discountCode && reminderDiscount.discountValue) {
      reminderMessage.discount = {
        discountType: reminderDiscount?.discountType ?? 'DISCOUNT_TYPE_UNSPECIFIED',
        discountCode: reminderDiscount?.discountCode ?? '',
        discountValue: reminderDiscount?.discountValue ?? 0,
      };
    }

    if (enableSafeWindow) {
      // Todo: convert to local 8am to UTC and add to integration
      const time = new Date();
      time.setHours(8, 0, 0); // Set to 8am local time
      // Conver to rrule string https://github.com/jakubroztocil/rrule
      const rrule = `${time
        .toISOString()
        .replace(/[^a-zA-Z0-9]/gm, '')
        .slice(0, -4)}Z`;
      firstMessage.rruleWindow = {
        rrule: `RRULE:FREQ=DAILY;DTSTART=${rrule}`,
        duration: '50400s',
      };
      reminderMessage.rruleWindow = {
        rrule: `RRULE:FREQ=DAILY;DTSTART=${rrule}`,
        duration: '50400s',
      };
    }

    const reminderParams = enableReminder && !isVtexIntegration ? [firstMessage, reminderMessage] : [firstMessage];
    const installationBody = isVtexIntegration
      ? {
          businessId: businessId ?? '',
          integrationType,
          integrationParameters: {
            customVtexAbandonedCheckoutParameters: {
              vtexStoreDomain: `${vtexAccountName}.myvtex.com`,
              reminderParametersList: reminderParams,
              semanticVersion: '0.0.1',
            },
          },
        }
      : {
          businessId: businessId ?? '',
          integrationType,
          integrationParameters: {
            customShopifyAbandonedCheckoutParameters: {
              shopifyBusinessChannelId: '',
              waBusinessChannelId: '',
              reminderParametersList: reminderParams,
              semanticVersion: '2.0.0',
            },
          },
        };
    mutateInstallIntegration(installationBody);
  }, [
    integrationType,
    businessId,
    enableReminder,
    enableHeaderImage,
    discount,
    isPersonalMessage,
    language,
    enableSafeWindow,
    reminderDiscount.discountCode,
    reminderDiscount.discountType,
    reminderDiscount.discountValue,
    isVtexIntegration,
    vtexAccountName,
    mutateInstallIntegration,
  ]);

  return {
    screen,
    language,
    discount,
    reminderDiscount,
    isPersonalMessage,
    enableReminder,
    enableHeaderImage,
    enableSafeWindow,
    isLoading: isLoadingInstallIntegration,
    onBack: handleBack,
    onSetScreen: handleSetScreen,
    onSetReminder: handleSetReminder,
    onLanguageChange: handleLanguageChange,
    onDiscountSave: handleDiscountSave,
    onReminderDiscountSave: handleReminderDiscountSave,
    onSetMessageType: handleSetMessageType,
    onSetSafeWindow: handleSetSafeWindow,
    onSubmit: handleSubmit,
    onSetHeaderImage: handleSetEnableHeaderImage,
  };
};
