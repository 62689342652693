/* eslint-disable no-nested-ternary */
import { TextStyles } from './types';
// Returns the font size in px
const mapSize = (size: number, isLargeScreen: boolean): number => {
  if (!isLargeScreen) return size;
  switch (size) {
    case 12:
      size = 14;
      break;
    case 18:
      size = 21;
      break;
    default:
      break;
  }
  return size;
};

export const calcStyles = (containerStyle: React.CSSProperties | undefined, isLargeScreen: boolean): TextStyles => {
  const defaultStyles: TextStyles = { fontSize: mapSize(12, isLargeScreen), lineHeight: mapSize(18, isLargeScreen) };
  if (!containerStyle) return defaultStyles;
  const { fontSize, lineHeight } = {
    fontSize: containerStyle.fontSize || defaultStyles.fontSize,
    lineHeight: containerStyle.lineHeight || defaultStyles.lineHeight,
  };
  return {
    fontSize: mapSize(
      typeof fontSize === 'number' ? fontSize : Number(fontSize?.toString().replace('px', '')),
      isLargeScreen,
    ),
    lineHeight: mapSize(
      typeof lineHeight === 'number' ? lineHeight : Number(lineHeight?.toString().replace('px', '')),
      isLargeScreen,
    ),
  };
};
