import { createSlice } from '@reduxjs/toolkit';
import { resetStore, setAgents } from './action';
const initialState = {
    business: undefined,
    agents: [],
    businessChannels: [],
};
export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        reset: () => initialState,
        setBusinessChannels: (state, action) => {
            state.businessChannels = action.payload;
        },
        setBusiness: (state, action) => {
            state.business = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(setAgents, (state, action) => {
            state.agents = action.payload;
        });
    },
});
export const { setBusiness, setBusinessChannels, reset } = businessSlice.actions;
export const selectAgents = (state) => state.businessSlice.agents;
export const selectBusinessChannels = (state) => state.businessSlice.businessChannels;
export const selectBusiness = (state) => state.businessSlice.business;
export default businessSlice.reducer;
