import { useMutation } from '@tanstack/react-query';
import { UpdateUserOptions, UpdateUserMutation, UpdateUserResponse } from './types';
import { api } from '../../../../api';

export const useUpdateUserMutation = (options?: UpdateUserOptions) => {
  return useMutation(
    (variables: UpdateUserMutation) =>
      api.profileV1('/v1/businesses/{input.businessId}/users/{input.userId}', 'patch', {
        path: { 'input.businessId': variables.businessId, 'input.userId': variables.userId },
        body: {
          input: {
            ...variables,
          },
        },
      }) as Promise<UpdateUserResponse>,
    {
      ...options,
    },
  );
};
