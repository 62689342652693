import { canonicalize } from '@hooks/useFlowVariables/mappers';
import { VARIABLE_REGEX } from '@hooks/useFlowVariables/constants';
import { APICallData } from '@connectlyai-tenets/sdk';
import { cloneDeep } from 'lodash';
import { CallAPINodeV1Input, CallAPINodeV1ParameterMapping, CallAPIV1Part } from './types';
import { APICallNodeUIState, Method } from '../../components/APICallNodeEditor/types';
import { DEFAULT_API_CALL_NODE_UI } from '../../components/APICallNodeEditor/constants';

export const mapUIStateToDataV1Input = (uiState: APICallNodeUIState): CallAPIV1Part => {
  const input: CallAPINodeV1Input = {};

  input.url = uiState.url.value;
  input.method = uiState.method.value;

  if (uiState.body.active) {
    input.body = {};
    input.body.type = 'BODY_INPUT_TYPE_JSON_BUILDER';
    input.body.jsonBuilder = {
      properties: uiState.body.parameters.map((parameter) => ({
        key: parameter.key.value,
        value: parameter.value.value,
      })),
    };
  }

  if (uiState.headers.active) {
    input.header = {};
    input.header.fields = uiState.headers.parameters.map((parameter) => ({
      name: parameter.key.value,
      value: parameter.value.value,
    }));
  }

  if (uiState.query.active) {
    input.urlQuery = {};
    input.urlQuery.params = uiState.query.parameters.map((parameter) => ({
      key: parameter.key.value,
      value: parameter.value.value,
    }));
  }

  return { input };
};

export const mapUIStateToDataV1ParameterMapping = (uiState: APICallNodeUIState): CallAPIV1Part => {
  let matches: { rawParam: string; match: RegExpMatchArray | null }[] = [];

  if (uiState.body.active)
    matches = matches.concat(
      uiState.body.parameters.map((x, index) => ({
        rawParam: `body.jsonBuilder.properties.${index}.value`,
        match: x.value.value.match(VARIABLE_REGEX),
      })),
    );

  if (uiState.headers.active) {
    matches = matches.concat(
      uiState.headers.parameters.map((x, index) => ({
        rawParam: `header.fields.${index}.value`,
        match: x.value.value.match(VARIABLE_REGEX),
      })),
    );
  }

  if (uiState.query.active) {
    matches = matches.concat(
      uiState.query.parameters.map((x, index) => ({
        rawParam: `urlQuery.params.${index}.value`,
        match: x.value.value.match(VARIABLE_REGEX),
      })),
    );
  }

  const variables = matches.filter((x) => x.match);
  if (variables.length === 0) return {};

  const parameterMapping: CallAPINodeV1ParameterMapping = {};
  parameterMapping.mappings = variables.map((x) => ({
    rawParam: x.rawParam,
    fullQualifier: canonicalize(x.match?.[0].replace(/{{|}}/g, '') || ''),
  }));
  return { parameterMapping };
};

export const apiCallNodeDataToUIState = (nodeData: APICallData): APICallNodeUIState => {
  const { body, query, headers, url, method, ...rest } = cloneDeep(DEFAULT_API_CALL_NODE_UI);

  if (nodeData.v1?.input?.url) url.value = nodeData.v1.input.url;

  if (nodeData.v1?.input?.method) method.value = nodeData.v1.input.method as Method;

  if (nodeData.v1?.input?.urlQuery?.params?.length) {
    query.active = true;
    query.parameters = nodeData.v1.input.urlQuery.params.map((param) => ({
      key: { value: param.key || '', error: '' },
      value: { value: param.value || '', error: '' },
      error: '',
    }));
  }

  if (nodeData.v1?.input?.header?.fields?.length) {
    headers.active = true;
    headers.parameters = nodeData.v1.input.header.fields.map((field) => ({
      key: { value: field.name || '', error: '' },
      value: { value: field.value || '', error: '' },
      error: '',
    }));
  }

  if (nodeData.v1?.input?.body?.jsonBuilder?.properties?.length) {
    body.active = true;
    body.parameters = nodeData.v1.input.body.jsonBuilder.properties.map((property) => ({
      key: { value: property.key || '', error: '' },
      value: { value: property.value || '', error: '' },
      error: '',
    }));
  }

  return { url, method, query, headers, body, ...rest };
};
