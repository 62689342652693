import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../../api';
import { ASSET_URI_URL, AssetUriParameters, AssetUriResponse } from './types';

export const useAssetUri = <TData = unknown>({
  businessId,
  assetId,
  ...options
}: Omit<
  UseQueryOptions<AssetUriResponse, unknown, TData, readonly [target: 'asset-content', uri: string]>,
  'initialData' | 'queryFn' | 'queryKey'
> &
  AssetUriParameters) => {
  return useQuery(
    ['asset-content', assetId],
    () => api.assetV1(ASSET_URI_URL, 'post', { path: { 'input.businessId': businessId, 'input.assetId': assetId } }),
    {
      enabled: !!businessId,
      ...options,
    },
  );
};
