import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { CustomerDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectNotesProfile } from '../../state/customerSlice';
import { selectUpdateNotesProfileState } from '../../state/updateNotesProfileSlice';
export const NotesProfileComponentId = '2dac58f9-5546-4e41-9292-729defbacae3';
const QuickProfile = (_props) => {
    const di = useContext(CustomerDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(NotesProfileComponentId);
    const notesProfile = useFeatureSelector(selectNotesProfile);
    const updateNotesProfile = useFeatureSelector(selectUpdateNotesProfileState);
    const notesProfileViewModel = {
        text: updateNotesProfile.textInput === null ? notesProfile.text : updateNotesProfile.textInput,
        update: {
            isActive: updateNotesProfile.isActive,
            isError: updateNotesProfile.isError,
            errorMessage: updateNotesProfile.errorMessage || '',
        },
    };
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { notesProfileViewModel: notesProfileViewModel }) });
};
export default QuickProfile;
