import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.flow.compiler";
export var Mode;
(function (Mode) {
    Mode[Mode["MODE_UNSPECIFIED"] = 0] = "MODE_UNSPECIFIED";
    Mode[Mode["MODE_DOCUMENT"] = 1] = "MODE_DOCUMENT";
    Mode[Mode["MODE_PARTIAL"] = 2] = "MODE_PARTIAL";
    Mode[Mode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Mode || (Mode = {}));
export function modeFromJSON(object) {
    switch (object) {
        case 0:
        case "MODE_UNSPECIFIED":
            return Mode.MODE_UNSPECIFIED;
        case 1:
        case "MODE_DOCUMENT":
            return Mode.MODE_DOCUMENT;
        case 2:
        case "MODE_PARTIAL":
            return Mode.MODE_PARTIAL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Mode.UNRECOGNIZED;
    }
}
export function modeToJSON(object) {
    switch (object) {
        case Mode.MODE_UNSPECIFIED:
            return "MODE_UNSPECIFIED";
        case Mode.MODE_DOCUMENT:
            return "MODE_DOCUMENT";
        case Mode.MODE_PARTIAL:
            return "MODE_PARTIAL";
        case Mode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var VerificationType;
(function (VerificationType) {
    VerificationType[VerificationType["VERIFICATION_TYPE_UNSPECIFIED"] = 0] = "VERIFICATION_TYPE_UNSPECIFIED";
    VerificationType[VerificationType["VERIFICATION_TYPE_LINT"] = 1] = "VERIFICATION_TYPE_LINT";
    VerificationType[VerificationType["VERIFICATION_TYPE_COMPILE"] = 2] = "VERIFICATION_TYPE_COMPILE";
    VerificationType[VerificationType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VerificationType || (VerificationType = {}));
export function verificationTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "VERIFICATION_TYPE_UNSPECIFIED":
            return VerificationType.VERIFICATION_TYPE_UNSPECIFIED;
        case 1:
        case "VERIFICATION_TYPE_LINT":
            return VerificationType.VERIFICATION_TYPE_LINT;
        case 2:
        case "VERIFICATION_TYPE_COMPILE":
            return VerificationType.VERIFICATION_TYPE_COMPILE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VerificationType.UNRECOGNIZED;
    }
}
export function verificationTypeToJSON(object) {
    switch (object) {
        case VerificationType.VERIFICATION_TYPE_UNSPECIFIED:
            return "VERIFICATION_TYPE_UNSPECIFIED";
        case VerificationType.VERIFICATION_TYPE_LINT:
            return "VERIFICATION_TYPE_LINT";
        case VerificationType.VERIFICATION_TYPE_COMPILE:
            return "VERIFICATION_TYPE_COMPILE";
        case VerificationType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus[VerificationStatus["VERIFICATION_STATUS_UNSPECIFIED"] = 0] = "VERIFICATION_STATUS_UNSPECIFIED";
    VerificationStatus[VerificationStatus["VERIFICATION_STATUS_OK"] = 1] = "VERIFICATION_STATUS_OK";
    VerificationStatus[VerificationStatus["VERIFICATION_STATUS_WARN"] = 2] = "VERIFICATION_STATUS_WARN";
    VerificationStatus[VerificationStatus["VERIFICATION_STATUS_ERROR"] = 3] = "VERIFICATION_STATUS_ERROR";
    VerificationStatus[VerificationStatus["VERIFICATION_STATUS_LINK_ERROR"] = 4] = "VERIFICATION_STATUS_LINK_ERROR";
    VerificationStatus[VerificationStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VerificationStatus || (VerificationStatus = {}));
export function verificationStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "VERIFICATION_STATUS_UNSPECIFIED":
            return VerificationStatus.VERIFICATION_STATUS_UNSPECIFIED;
        case 1:
        case "VERIFICATION_STATUS_OK":
            return VerificationStatus.VERIFICATION_STATUS_OK;
        case 2:
        case "VERIFICATION_STATUS_WARN":
            return VerificationStatus.VERIFICATION_STATUS_WARN;
        case 3:
        case "VERIFICATION_STATUS_ERROR":
            return VerificationStatus.VERIFICATION_STATUS_ERROR;
        case 4:
        case "VERIFICATION_STATUS_LINK_ERROR":
            return VerificationStatus.VERIFICATION_STATUS_LINK_ERROR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VerificationStatus.UNRECOGNIZED;
    }
}
export function verificationStatusToJSON(object) {
    switch (object) {
        case VerificationStatus.VERIFICATION_STATUS_UNSPECIFIED:
            return "VERIFICATION_STATUS_UNSPECIFIED";
        case VerificationStatus.VERIFICATION_STATUS_OK:
            return "VERIFICATION_STATUS_OK";
        case VerificationStatus.VERIFICATION_STATUS_WARN:
            return "VERIFICATION_STATUS_WARN";
        case VerificationStatus.VERIFICATION_STATUS_ERROR:
            return "VERIFICATION_STATUS_ERROR";
        case VerificationStatus.VERIFICATION_STATUS_LINK_ERROR:
            return "VERIFICATION_STATUS_LINK_ERROR";
        case VerificationStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var DiagnosticKind;
(function (DiagnosticKind) {
    DiagnosticKind[DiagnosticKind["DIAGNOSTIC_KIND_UNSPECIFIED"] = 0] = "DIAGNOSTIC_KIND_UNSPECIFIED";
    DiagnosticKind[DiagnosticKind["DIAGNOSTIC_KIND_NOTE"] = 1] = "DIAGNOSTIC_KIND_NOTE";
    DiagnosticKind[DiagnosticKind["DIAGNOSTIC_KIND_WARN"] = 2] = "DIAGNOSTIC_KIND_WARN";
    DiagnosticKind[DiagnosticKind["DIAGNOSTIC_KIND_ERROR"] = 3] = "DIAGNOSTIC_KIND_ERROR";
    DiagnosticKind[DiagnosticKind["DIAGNOSTIC_KIND_LINK_ERROR"] = 4] = "DIAGNOSTIC_KIND_LINK_ERROR";
    DiagnosticKind[DiagnosticKind["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(DiagnosticKind || (DiagnosticKind = {}));
export function diagnosticKindFromJSON(object) {
    switch (object) {
        case 0:
        case "DIAGNOSTIC_KIND_UNSPECIFIED":
            return DiagnosticKind.DIAGNOSTIC_KIND_UNSPECIFIED;
        case 1:
        case "DIAGNOSTIC_KIND_NOTE":
            return DiagnosticKind.DIAGNOSTIC_KIND_NOTE;
        case 2:
        case "DIAGNOSTIC_KIND_WARN":
            return DiagnosticKind.DIAGNOSTIC_KIND_WARN;
        case 3:
        case "DIAGNOSTIC_KIND_ERROR":
            return DiagnosticKind.DIAGNOSTIC_KIND_ERROR;
        case 4:
        case "DIAGNOSTIC_KIND_LINK_ERROR":
            return DiagnosticKind.DIAGNOSTIC_KIND_LINK_ERROR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DiagnosticKind.UNRECOGNIZED;
    }
}
export function diagnosticKindToJSON(object) {
    switch (object) {
        case DiagnosticKind.DIAGNOSTIC_KIND_UNSPECIFIED:
            return "DIAGNOSTIC_KIND_UNSPECIFIED";
        case DiagnosticKind.DIAGNOSTIC_KIND_NOTE:
            return "DIAGNOSTIC_KIND_NOTE";
        case DiagnosticKind.DIAGNOSTIC_KIND_WARN:
            return "DIAGNOSTIC_KIND_WARN";
        case DiagnosticKind.DIAGNOSTIC_KIND_ERROR:
            return "DIAGNOSTIC_KIND_ERROR";
        case DiagnosticKind.DIAGNOSTIC_KIND_LINK_ERROR:
            return "DIAGNOSTIC_KIND_LINK_ERROR";
        case DiagnosticKind.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var DiagnosticCode;
(function (DiagnosticCode) {
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_UNSPECIFIED"] = 0] = "DIAGNOSTIC_CODE_UNSPECIFIED";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_GENERIC"] = 1] = "DIAGNOSTIC_CODE_GENERIC";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_DEPENDENCY_FAILED"] = 2] = "DIAGNOSTIC_CODE_DEPENDENCY_FAILED";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID"] = 3] = "DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED"] = 4] = "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED"] = 5] = "DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING"] = 6] = "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING";
    DiagnosticCode[DiagnosticCode["DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND"] = 7] = "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND";
    DiagnosticCode[DiagnosticCode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(DiagnosticCode || (DiagnosticCode = {}));
export function diagnosticCodeFromJSON(object) {
    switch (object) {
        case 0:
        case "DIAGNOSTIC_CODE_UNSPECIFIED":
            return DiagnosticCode.DIAGNOSTIC_CODE_UNSPECIFIED;
        case 1:
        case "DIAGNOSTIC_CODE_GENERIC":
            return DiagnosticCode.DIAGNOSTIC_CODE_GENERIC;
        case 2:
        case "DIAGNOSTIC_CODE_DEPENDENCY_FAILED":
            return DiagnosticCode.DIAGNOSTIC_CODE_DEPENDENCY_FAILED;
        case 3:
        case "DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID":
            return DiagnosticCode.DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID;
        case 4:
        case "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED":
            return DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED;
        case 5:
        case "DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED":
            return DiagnosticCode.DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED;
        case 6:
        case "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING":
            return DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING;
        case 7:
        case "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND":
            return DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DiagnosticCode.UNRECOGNIZED;
    }
}
export function diagnosticCodeToJSON(object) {
    switch (object) {
        case DiagnosticCode.DIAGNOSTIC_CODE_UNSPECIFIED:
            return "DIAGNOSTIC_CODE_UNSPECIFIED";
        case DiagnosticCode.DIAGNOSTIC_CODE_GENERIC:
            return "DIAGNOSTIC_CODE_GENERIC";
        case DiagnosticCode.DIAGNOSTIC_CODE_DEPENDENCY_FAILED:
            return "DIAGNOSTIC_CODE_DEPENDENCY_FAILED";
        case DiagnosticCode.DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID:
            return "DIAGNOSTIC_CODE_MISSING_WHATSAPP_TEMPLATE_ID";
        case DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED:
            return "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_REJECTED";
        case DiagnosticCode.DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED:
            return "DIAGNOSTIC_CODE_CONNECTLY_TEMPLATE_APPROVED";
        case DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING:
            return "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_PENDING";
        case DiagnosticCode.DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND:
            return "DIAGNOSTIC_CODE_WHATSAPP_TEMPLATE_NOT_FOUND";
        case DiagnosticCode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseDiagnostic() {
    return {
        id: "",
        key: "",
        message: "",
        kind: 0,
        code: 0,
        userMessage: undefined,
        details: {},
    };
}
export const Diagnostic = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.key !== "") {
            writer.uint32(18).string(message.key);
        }
        if (message.message !== "") {
            writer.uint32(26).string(message.message);
        }
        if (message.kind !== 0) {
            writer.uint32(32).int32(message.kind);
        }
        if (message.code !== 0) {
            writer.uint32(40).int32(message.code);
        }
        if (message.userMessage !== undefined) {
            DiagnosticMessage.encode(message.userMessage, writer.uint32(50).fork()).ldelim();
        }
        Object.entries(message.details).forEach(([key, value]) => {
            Diagnostic_DetailsEntry.encode({ key: key, value }, writer.uint32(58).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDiagnostic();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.kind = reader.int32();
                    break;
                case 5:
                    message.code = reader.int32();
                    break;
                case 6:
                    message.userMessage = DiagnosticMessage.decode(reader, reader.uint32());
                    break;
                case 7:
                    const entry7 = Diagnostic_DetailsEntry.decode(reader, reader.uint32());
                    if (entry7.value !== undefined) {
                        message.details[entry7.key] = entry7.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            key: isSet(object.key) ? String(object.key) : "",
            message: isSet(object.message) ? String(object.message) : "",
            kind: isSet(object.kind) ? diagnosticKindFromJSON(object.kind) : 0,
            code: isSet(object.code) ? diagnosticCodeFromJSON(object.code) : 0,
            userMessage: isSet(object.userMessage)
                ? DiagnosticMessage.fromJSON(object.userMessage)
                : undefined,
            details: isObject(object.details)
                ? Object.entries(object.details).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.key !== undefined && (obj.key = message.key);
        message.message !== undefined && (obj.message = message.message);
        message.kind !== undefined &&
            (obj.kind = diagnosticKindToJSON(message.kind));
        message.code !== undefined &&
            (obj.code = diagnosticCodeToJSON(message.code));
        message.userMessage !== undefined &&
            (obj.userMessage = message.userMessage
                ? DiagnosticMessage.toJSON(message.userMessage)
                : undefined);
        obj.details = {};
        if (message.details) {
            Object.entries(message.details).forEach(([k, v]) => {
                obj.details[k] = v;
            });
        }
        return obj;
    },
};
function createBaseDiagnostic_DetailsEntry() {
    return { key: "", value: "" };
}
export const Diagnostic_DetailsEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDiagnostic_DetailsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseDiagnosticMessage() {
    return { title: "", message: "" };
}
export const DiagnosticMessage = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDiagnosticMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            message: isSet(object.message) ? String(object.message) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.message !== undefined && (obj.message = message.message);
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
