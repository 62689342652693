import type { FlowVariableDeclaration, ParameterValueMapping } from '@connectlyai-tenets/sdk';

export type FlowVariableExtension = {
  declaredAt?: string; // the node id where the variable is declared
  isSessionVariable?: boolean;
};

// This is for removing keys from the type while converting it to FlowVariableDeclaration
export const EXAMPLE_FLOW_VARIABLE_EXTENSION: Required<FlowVariableExtension> = {
  declaredAt: 'node-id',
  isSessionVariable: false,
};

export type FlowVariableExtended = FlowVariableDeclaration & FlowVariableExtension;

export type VariableType = 'STANDARD' | 'SESSION';

export type VariableMapping = Required<NonNullable<ParameterValueMapping['mappings']>[0]>;
