import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.error";
export var ErrorType;
(function (ErrorType) {
    ErrorType[ErrorType["ERROR_TYPE_UNSPECIFIED"] = 0] = "ERROR_TYPE_UNSPECIFIED";
    ErrorType[ErrorType["ERROR_TYPE_AUTHENTICATION"] = 1] = "ERROR_TYPE_AUTHENTICATION";
    ErrorType[ErrorType["ERROR_TYPE_AUTHORIZATION"] = 2] = "ERROR_TYPE_AUTHORIZATION";
    ErrorType[ErrorType["ERROR_TYPE_RATE_LIMIT"] = 3] = "ERROR_TYPE_RATE_LIMIT";
    ErrorType[ErrorType["ERROR_TYPE_INVALID_REQUEST"] = 4] = "ERROR_TYPE_INVALID_REQUEST";
    ErrorType[ErrorType["ERROR_TYPE_DEADLINE_EXCEEDED"] = 5] = "ERROR_TYPE_DEADLINE_EXCEEDED";
    ErrorType[ErrorType["ERROR_TYPE_NOT_FOUND"] = 6] = "ERROR_TYPE_NOT_FOUND";
    ErrorType[ErrorType["ERROR_TYPE_CONFLICT"] = 7] = "ERROR_TYPE_CONFLICT";
    ErrorType[ErrorType["ERROR_TYPE_CONTEXT_CANCLED"] = 8] = "ERROR_TYPE_CONTEXT_CANCLED";
    ErrorType[ErrorType["ERROR_TYPE_BUSINESS_LIMIT"] = 9] = "ERROR_TYPE_BUSINESS_LIMIT";
    ErrorType[ErrorType["ERROR_TYPE_WEBHOOK_DELIVERY_FAILED"] = 10] = "ERROR_TYPE_WEBHOOK_DELIVERY_FAILED";
    ErrorType[ErrorType["ERROR_TYPE_FAILED_PRECONDITION"] = 11] = "ERROR_TYPE_FAILED_PRECONDITION";
    ErrorType[ErrorType["ERROR_TYPE_FAILED_TO_PERSIST"] = 12] = "ERROR_TYPE_FAILED_TO_PERSIST";
    ErrorType[ErrorType["ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE"] = 13] = "ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE";
    ErrorType[ErrorType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ErrorType || (ErrorType = {}));
export function errorTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "ERROR_TYPE_UNSPECIFIED":
            return ErrorType.ERROR_TYPE_UNSPECIFIED;
        case 1:
        case "ERROR_TYPE_AUTHENTICATION":
            return ErrorType.ERROR_TYPE_AUTHENTICATION;
        case 2:
        case "ERROR_TYPE_AUTHORIZATION":
            return ErrorType.ERROR_TYPE_AUTHORIZATION;
        case 3:
        case "ERROR_TYPE_RATE_LIMIT":
            return ErrorType.ERROR_TYPE_RATE_LIMIT;
        case 4:
        case "ERROR_TYPE_INVALID_REQUEST":
            return ErrorType.ERROR_TYPE_INVALID_REQUEST;
        case 5:
        case "ERROR_TYPE_DEADLINE_EXCEEDED":
            return ErrorType.ERROR_TYPE_DEADLINE_EXCEEDED;
        case 6:
        case "ERROR_TYPE_NOT_FOUND":
            return ErrorType.ERROR_TYPE_NOT_FOUND;
        case 7:
        case "ERROR_TYPE_CONFLICT":
            return ErrorType.ERROR_TYPE_CONFLICT;
        case 8:
        case "ERROR_TYPE_CONTEXT_CANCLED":
            return ErrorType.ERROR_TYPE_CONTEXT_CANCLED;
        case 9:
        case "ERROR_TYPE_BUSINESS_LIMIT":
            return ErrorType.ERROR_TYPE_BUSINESS_LIMIT;
        case 10:
        case "ERROR_TYPE_WEBHOOK_DELIVERY_FAILED":
            return ErrorType.ERROR_TYPE_WEBHOOK_DELIVERY_FAILED;
        case 11:
        case "ERROR_TYPE_FAILED_PRECONDITION":
            return ErrorType.ERROR_TYPE_FAILED_PRECONDITION;
        case 12:
        case "ERROR_TYPE_FAILED_TO_PERSIST":
            return ErrorType.ERROR_TYPE_FAILED_TO_PERSIST;
        case 13:
        case "ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE":
            return ErrorType.ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ErrorType.UNRECOGNIZED;
    }
}
export function errorTypeToJSON(object) {
    switch (object) {
        case ErrorType.ERROR_TYPE_UNSPECIFIED:
            return "ERROR_TYPE_UNSPECIFIED";
        case ErrorType.ERROR_TYPE_AUTHENTICATION:
            return "ERROR_TYPE_AUTHENTICATION";
        case ErrorType.ERROR_TYPE_AUTHORIZATION:
            return "ERROR_TYPE_AUTHORIZATION";
        case ErrorType.ERROR_TYPE_RATE_LIMIT:
            return "ERROR_TYPE_RATE_LIMIT";
        case ErrorType.ERROR_TYPE_INVALID_REQUEST:
            return "ERROR_TYPE_INVALID_REQUEST";
        case ErrorType.ERROR_TYPE_DEADLINE_EXCEEDED:
            return "ERROR_TYPE_DEADLINE_EXCEEDED";
        case ErrorType.ERROR_TYPE_NOT_FOUND:
            return "ERROR_TYPE_NOT_FOUND";
        case ErrorType.ERROR_TYPE_CONFLICT:
            return "ERROR_TYPE_CONFLICT";
        case ErrorType.ERROR_TYPE_CONTEXT_CANCLED:
            return "ERROR_TYPE_CONTEXT_CANCLED";
        case ErrorType.ERROR_TYPE_BUSINESS_LIMIT:
            return "ERROR_TYPE_BUSINESS_LIMIT";
        case ErrorType.ERROR_TYPE_WEBHOOK_DELIVERY_FAILED:
            return "ERROR_TYPE_WEBHOOK_DELIVERY_FAILED";
        case ErrorType.ERROR_TYPE_FAILED_PRECONDITION:
            return "ERROR_TYPE_FAILED_PRECONDITION";
        case ErrorType.ERROR_TYPE_FAILED_TO_PERSIST:
            return "ERROR_TYPE_FAILED_TO_PERSIST";
        case ErrorType.ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE:
            return "ERROR_TYPE_REQUEST_ENTITY_TOO_LARGE";
        case ErrorType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseErrorTypeDetail() {
    return { type: "" };
}
export const ErrorTypeDetail = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseErrorTypeDetail();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
