import React, { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Label, Box, ConnectlyCard } from '@connectlyai-tenets/ui-styled-web';
import { MIN_DATE } from '@scenes/Inbox/constants';
import { KeyPerformanceIndicator, KeyPerformanceIndicatorV2 } from '@components';
import { selectUsername, selectBusinessId, useMeData, useFeatureFlag } from '@hooks';
import { msToTimeFormat, computeChange } from '@scenes/Inbox/utils';
import { inboxAnalyticsTimeRangesAtom as timeRangesAtom } from '@atoms/inbox';
import { useAgentPerformanceData } from '@scenes/Inbox/hooks/useAgentPerformanceData/useAgentPerformanceData';
import { AgentDisplay } from './types';

const useAgentPerformance = () => {
  const [currentRange, previousRange] = useAtomValue(timeRangesAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: username } = useMeData({ select: selectUsername });
  const {
    data: agentPerformanceData,
    error,
    isLoading,
  } = useAgentPerformanceData({
    businessId: businessId || '',
    username: username || '',
    timeRanges: [currentRange, previousRange],
  });

  const agentData: AgentDisplay = useMemo(() => {
    const returnData = {
      avgResolutionTime: {
        current: '',
      },
      conversations: {
        current: '',
      },
    } as AgentDisplay;
    const [current, previous] = agentPerformanceData;
    if (current && previous) {
      const { avgResolutionTime: currentAvgResolutionTime, conversations: currentConversations } = current || {};
      const { avgResolutionTime: previousAvgResolutionTime, conversations: previousConversations } = previous || {};
      const currentResolution: number = Number(currentAvgResolutionTime) || 0;
      const currentConvo: number = Number(currentConversations) || 0;
      returnData.avgResolutionTime = {
        current: msToTimeFormat(currentResolution),
      };
      returnData.conversations = {
        current: String(currentConvo),
      };
      const outOfRange = new Date(previousRange.timeRangeStart) < MIN_DATE;
      if (!outOfRange) {
        const previousResolution: number = Number(previousAvgResolutionTime) || 0;
        const previousConvo: number = Number(previousConversations) || 0;
        returnData.avgResolutionTime.change = computeChange(currentResolution, previousResolution);
        returnData.conversations.change = computeChange(currentConvo, previousConvo);
      }
    }
    return returnData;
  }, [agentPerformanceData]);

  return {
    isLoading,
    agentData,
    error,
  };
};

export const AgentPerformance = () => {
  const { agentData, isLoading, error } = useAgentPerformance();
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.inbox' });
  const { ffEnableAnalyticsRedesign } = useFeatureFlag(['ffEnableAnalyticsRedesign']);

  return (
    <ConnectlyCard
      title="Your Performance"
      sx={{
        width: '100%',
      }}
    >
      {ffEnableAnalyticsRedesign && (
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
          }}
        >
          <KeyPerformanceIndicatorV2
            title={t('avgResolutionTime.title')}
            data={agentData.avgResolutionTime}
            isLoading={isLoading}
            tooltip={t('avgResolutionTime.tooltip')}
          />
          <KeyPerformanceIndicatorV2
            title={t('conversations.title')}
            data={agentData.conversations}
            isLoading={isLoading}
            tooltip={t('conversations.tooltip')}
          />
        </Box>
      )}

      {!ffEnableAnalyticsRedesign && (
        <Box
          sx={{
            flex: '1 1 100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            gap: 2,
          }}
        >
          <KeyPerformanceIndicator
            title={t('avgResolutionTime.title')}
            data={agentData.avgResolutionTime}
            isLoading={isLoading}
            tooltip={t('avgResolutionTime.tooltip')}
          />
          <KeyPerformanceIndicator
            title={t('conversations.title')}
            data={agentData.conversations}
            isLoading={isLoading}
            tooltip={t('conversations.tooltip')}
          />
        </Box>
      )}

      {error && (
        <Label variant="body1" color="error" sx={{ flex: '0 0 auto' }}>
          {error}
        </Label>
      )}
    </ConnectlyCard>
  );
};
