import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_DEACTIVATE_REACT_FLOW_DOCUMENT_URL,
  MutationDeactivateReactFlowDocumentOptions,
  MutationDeactivateReactFlowDocumentInput,
  MutationDeactivateReactFlowDocumentResponse,
} from './types';

export const useMutationDeactivateReactFlowDocument = (options?: MutationDeactivateReactFlowDocumentOptions) => {
  const queryClient = useQueryClient();
  let newOptions;
  if (options) {
    const { onSuccess, ...restOptions } = options;
    newOptions = {
      onSuccess: (
        data: MutationDeactivateReactFlowDocumentResponse,
        variables: MutationDeactivateReactFlowDocumentInput,
        context: unknown,
      ) => {
        queryClient.setQueryData(['flow_document_versions', { flowDocumentId: variables.flowDocumentId || '' }], data);
        if (onSuccess) {
          onSuccess(data, variables, context);
        }
      },
      ...restOptions,
    };
  } else {
    newOptions = {
      onSuccess: (
        data: MutationDeactivateReactFlowDocumentResponse,
        variables: MutationDeactivateReactFlowDocumentInput,
      ) => {
        queryClient.setQueryData(['flow_document_versions', { flowDocumentId: variables.flowDocumentId || '' }], data);
      },
    };
  }
  return useMutation(
    (variables: MutationDeactivateReactFlowDocumentInput) =>
      api.flowV1(MUTATION_DEACTIVATE_REACT_FLOW_DOCUMENT_URL, 'post', {
        path: { 'input.businessId': variables.businessId },
        body: {
          input: {
            ...variables,
          },
        },
      }) as Promise<MutationDeactivateReactFlowDocumentResponse>,
    {
      ...newOptions,
    },
  );
};
