import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Label,
  useTheme,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { SIZE_CONTENT_L, SIZE_SPACING_GUTTER_H, SIZE_SPACING_INTER_COMPONENT } from 'src/ui-theme';
import { track } from 'src/utils';
import { NotificationContext } from '../../../contexts';
import { csatStateAtom, csatRatingAtom, csatFeedbackAtom, dialogOpenAtom } from '../atoms';
import { CSATOptions } from '../data';

const useCSATDialog = () => {
  const setCSATState = useSetAtom(csatStateAtom);
  const [dialogOpen, setDialogOpen] = useAtom(dialogOpenAtom);
  const [csatRating, setCSATRating] = useAtom(csatRatingAtom);
  const [csatFeedback, setCSATFeedback] = useAtom(csatFeedbackAtom);
  const { notificationProvider } = useContext(NotificationContext);

  const keyPrefix = csatRating ? CSATOptions[csatRating].dialogPrefix : '';
  const { t: tQuestion } = useTranslation('translation', { keyPrefix });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'csat.dialog.common' });
  const { title, question } = useMemo(() => {
    return {
      title: tQuestion('title'),
      question: tQuestion('question'),
    };
  }, [tQuestion]);

  const close = () => {
    setDialogOpen(false);
    setCSATRating(null);
    setCSATFeedback('');
  };

  const handleClose = () => {
    // Treat the closing of the dialog as a dismiss
    track('dismiss csat feedback');

    const timestamp = new Date().toUTCString();
    setCSATState((prev) => {
      return {
        ...prev,
        closeUTCTimestamp: timestamp,
      };
    });

    close();
  };

  const handleSubmit = () => {
    track('submit csat feedback', { rating: csatRating, feedback: csatFeedback });
    const timestamp = new Date().toUTCString();
    setCSATState((prev) => {
      return {
        ...prev,
        submitUTCTimestamp: timestamp,
      };
    });
    close();
    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: 'Thanks for your feeback!',
        severity: NotificationSeverity.SUCCESS,
        icon: '',
      },
    });
  };

  const handleFeedbackChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCSATFeedback(event.target.value);
    },
    [setCSATFeedback],
  );

  return {
    dialogOpen,
    title,
    question,
    csatFeedback,
    handleClose,
    handleFeedbackChange,
    handleSubmit,
    tCommon,
  };
};

export const CSATDialog: FC = () => {
  const { csatFeedback, dialogOpen, title, question, tCommon, handleClose, handleSubmit, handleFeedbackChange } =
    useCSATDialog();

  const theme = useTheme();
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          flexDirection: 'column',
          minWidth: theme.spacing(SIZE_CONTENT_L),
        },
      }}
      open={dialogOpen}
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(SIZE_SPACING_INTER_COMPONENT) }}
      >
        <Label variant="h6">{question}</Label>
        <TextField
          autoComplete="off"
          placeholder={tCommon('feedbackPlaceholder')}
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          value={csatFeedback}
          onChange={handleFeedbackChange}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions sx={{ px: theme.spacing(SIZE_SPACING_GUTTER_H), py: theme.spacing(2) }}>
        <Button
          variant="contained"
          sx={{
            width: '100%',
          }}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};
