import { Paths } from '@connectlyai-tenets/sdk';

export const RESEND_CAMPAIGN_URL = '/internal/v1/businesses/{input.businessId}/resend_campaign/flow_documents';
type ResendCampaignURL = typeof RESEND_CAMPAIGN_URL;

export type ResendCampaignQuery = Paths['flowV1'][ResendCampaignURL]['post']['parameters']['body']['input'];

export type ResendCampaignUrlParameters = {
  businessId: string;
};

export type ResendCampaignVariables = ResendCampaignQuery & ResendCampaignUrlParameters;

export type ResendCampaignResponse = Paths['flowV1'][ResendCampaignURL]['post']['responses']['200']['schema'];
