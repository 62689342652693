import { useCallback, useEffect } from 'react';
import {
  sendoutIdAtom,
  documentIdAtom,
  campaignNameAtom,
  compileResultAtom,
  spreadsheetURLAtom,
  frequencyCapAtom,
  documentVersionIdAtom,
  revisionIdAtom,
} from '@atoms/flow';
import { v4 as uuidv4 } from 'uuid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  selectBusinessId,
  useEventRequestDeployReactFlowDocument,
  useFlowDocumentData,
  useInitializeSpreadsheetMutation,
  selectSpreadsheetURL,
  selectSpreadsheetFrequencyCapOption,
  useMeData,
  useRequestCompileReactFlowDocument,
  useScheduleSendoutMutation,
  useMutationUpdateAudience,
  useMutationUpdateCohort,
  useVerifySpreadsheetData,
  useVerifySpreadsheetMutation,
} from '@hooks';
import type { CampaignSendoutInputType } from '@hooks/useMutationUpdateCohort';
import { useQueryClient } from '@tanstack/react-query';

export const useCampaignV3 = () => {
  const [documentVersionId, setDocumentVersionId] = useAtom(documentVersionIdAtom);
  const campaignName = useAtomValue(campaignNameAtom);
  const documentId = useAtomValue(documentIdAtom);
  const revisionId = useAtomValue(revisionIdAtom);
  const sendoutId = useAtomValue(sendoutIdAtom);
  const setCompileResult = useSetAtom(compileResultAtom);
  const setSpreadsheetURL = useSetAtom(spreadsheetURLAtom);
  const setSpreadsheetFrequencyCapOption = useSetAtom(frequencyCapAtom);

  const queryClient = useQueryClient();

  const { data: businessId } = useMeData({ select: selectBusinessId });

  const { data: flowDocumentData } = useFlowDocumentData({
    businessId: businessId || '',
    flowDocumentId: documentId,
    enabled: !!businessId && !!documentId && !campaignName,
  });

  const {
    data: requestCompileData,
    mutate: requestCompileMutate,
    isLoading: isRequestCompileLoading,
    isTimedout: isRequestCompileTimedout,
  } = useRequestCompileReactFlowDocument();

  useEffect(() => {
    if (!requestCompileData) return;
    setCompileResult(requestCompileData);
    if (requestCompileData.status === 'MUTATION_STATUS_SUCCESS') {
      setDocumentVersionId(requestCompileData.flowDocumentVersionId || '');
    }
  }, [requestCompileData, setDocumentVersionId, setCompileResult]);

  const handleCompile = useCallback(() => {
    setCompileResult({});
    if (businessId && documentId && revisionId) {
      const clientRequestId = uuidv4();
      requestCompileMutate({
        businessId,
        flowDocumentId: documentId,
        clientRequestId,
        flowDocumentRevisionId: revisionId,
        options: {
          version: 'v2',
        },
      });
    }
  }, [businessId, documentId, revisionId, requestCompileMutate, setCompileResult]);

  const { data: initializeSpreadsheetData, mutate: initializeSpreadsheetMutate } = useInitializeSpreadsheetMutation();
  const handleInitializeSpreadsheet = useCallback(() => {
    if (businessId && sendoutId && documentId && documentVersionId) {
      initializeSpreadsheetMutate({
        businessId,
        sendoutId,
        flowDocumentId: documentId,
        flowDocumentVersionId: documentVersionId,
      });
    }
  }, [businessId, sendoutId, documentId, documentVersionId, initializeSpreadsheetMutate]);

  useEffect(() => {
    if (!initializeSpreadsheetData) return;
    const spreadsheetUrl = selectSpreadsheetURL(initializeSpreadsheetData);
    setSpreadsheetURL(spreadsheetUrl);
    const frequencyCapOption = selectSpreadsheetFrequencyCapOption(initializeSpreadsheetData);
    setSpreadsheetFrequencyCapOption(frequencyCapOption);
  }, [initializeSpreadsheetData, setSpreadsheetURL, setSpreadsheetFrequencyCapOption]);

  const { mutate: verifySpreadsheetMutate } = useVerifySpreadsheetMutation();
  const handleVerifySpreadsheet = useCallback(() => {
    if (businessId && sendoutId && documentId && documentVersionId) {
      verifySpreadsheetMutate({
        clientRequestId: uuidv4(),
        businessId,
        sendoutId,
        flowDocumentId: documentId,
        flowDocumentVersionId: documentVersionId,
      });
    }
  }, [businessId, sendoutId, documentId, documentVersionId, verifySpreadsheetMutate]);

  const { result: verifySpreadsheetResult } = useVerifySpreadsheetData(sendoutId);

  const { mutate: scheduleSendoutMutate } = useScheduleSendoutMutation();

  const handleScheduleToNow = useCallback(() => {
    if (businessId && sendoutId) {
      scheduleSendoutMutate({
        schedule: { unschedule: {} },
        businessId,
        sendoutId,
      });
    }
  }, [businessId, sendoutId, scheduleSendoutMutate]);

  const handleScheduleToISODate = useCallback(
    (scheduledAt: string) => {
      if (businessId && sendoutId) {
        scheduleSendoutMutate({
          schedule: { schedule: { scheduledAt } },
          businessId,
          sendoutId,
        });
      }
    },
    [businessId, sendoutId, scheduleSendoutMutate],
  );

  const { mutate: mutateUpdateAudience } = useMutationUpdateAudience();
  const { mutate: mutateUpdateCohort } = useMutationUpdateCohort();

  const handleUpdateCohort = useCallback(
    (inputType: CampaignSendoutInputType) => {
      if (businessId && sendoutId) {
        mutateUpdateCohort({
          businessId,
          sendoutId,
          inputType,
        });
      }
    },
    [businessId, sendoutId, mutateUpdateCohort],
  );

  const handleSetAudience = useCallback(
    (audienceSegmentId: string) => {
      if (businessId && sendoutId) {
        mutateUpdateAudience({
          businessId,
          sendoutId,
          audienceSegmentId,
        });
      }
    },
    [businessId, sendoutId, mutateUpdateAudience],
  );

  const handleEmptyAudience = useCallback(() => {
    if (businessId && sendoutId) {
      mutateUpdateAudience({
        businessId,
        sendoutId,
        // omit audienceSegmentId to empty audience
      });
    }
  }, [businessId, sendoutId, mutateUpdateAudience]);

  const { mutate: deployMutate } = useEventRequestDeployReactFlowDocument();

  const handleDeploy = useCallback(() => {
    if (businessId && documentId && documentVersionId) {
      const clientRequestId = uuidv4();
      deployMutate(
        {
          businessId,
          clientRequestId,
          flowDocumentId: documentId,
          flowDocumentVersionId: documentVersionId,
          allowMultipleDeployedVersions: true,
        },
        {
          onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['listCampaigns'] });
            queryClient.invalidateQueries({ queryKey: ['flowDocument'] });
          },
        },
      );
    }
  }, [deployMutate, businessId, documentId, documentVersionId, queryClient]);

  return {
    flowDocumentData,
    handleCompile,
    handleUpdateCohort,
    handleDeploy,
    handleEmptyAudience,
    handleInitializeSpreadsheet,
    handleScheduleToISODate,
    handleScheduleToNow,
    handleSetAudience,
    handleVerifySpreadsheet,
    isRequestCompileTimedout,
    isRequestCompileLoading,
    revisionId,
    verifySpreadsheetResult,
  };
};
