import { InboxSettings } from "../inbox/models";
import { UserExperimentDtoCollection } from "../experiment/dto/models";
import { NotificationSettings } from "../notification/models";
import { UserScope, roleFromJSON, roleToJSON } from "./models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.profile";
function createBaseBusinessPresentation() {
    return { id: "", name: "" };
}
export const BusinessPresentation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBusinessPresentation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseUserPresentation() {
    return {
        id: "",
        username: "",
        businesses: [],
        inboxSettings: undefined,
        displayName: "",
        firstName: "",
        lastName: "",
        experiments: undefined,
        notificationSettings: undefined,
        roles: [],
    };
}
export const UserPresentation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.username !== "") {
            writer.uint32(18).string(message.username);
        }
        for (const v of message.businesses) {
            BusinessPresentation.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.inboxSettings !== undefined) {
            InboxSettings.encode(message.inboxSettings, writer.uint32(34).fork()).ldelim();
        }
        if (message.displayName !== "") {
            writer.uint32(42).string(message.displayName);
        }
        if (message.firstName !== "") {
            writer.uint32(50).string(message.firstName);
        }
        if (message.lastName !== "") {
            writer.uint32(58).string(message.lastName);
        }
        if (message.experiments !== undefined) {
            UserExperimentDtoCollection.encode(message.experiments, writer.uint32(66).fork()).ldelim();
        }
        if (message.notificationSettings !== undefined) {
            NotificationSettings.encode(message.notificationSettings, writer.uint32(74).fork()).ldelim();
        }
        writer.uint32(82).fork();
        for (const v of message.roles) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserPresentation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.businesses.push(BusinessPresentation.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.inboxSettings = InboxSettings.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.displayName = reader.string();
                    break;
                case 6:
                    message.firstName = reader.string();
                    break;
                case 7:
                    message.lastName = reader.string();
                    break;
                case 8:
                    message.experiments = UserExperimentDtoCollection.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.notificationSettings = NotificationSettings.decode(reader, reader.uint32());
                    break;
                case 10:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roles.push(reader.int32());
                        }
                    }
                    else {
                        message.roles.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            username: isSet(object.username) ? String(object.username) : "",
            businesses: Array.isArray(object === null || object === void 0 ? void 0 : object.businesses)
                ? object.businesses.map((e) => BusinessPresentation.fromJSON(e))
                : [],
            inboxSettings: isSet(object.inboxSettings)
                ? InboxSettings.fromJSON(object.inboxSettings)
                : undefined,
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
            firstName: isSet(object.firstName) ? String(object.firstName) : "",
            lastName: isSet(object.lastName) ? String(object.lastName) : "",
            experiments: isSet(object.experiments)
                ? UserExperimentDtoCollection.fromJSON(object.experiments)
                : undefined,
            notificationSettings: isSet(object.notificationSettings)
                ? NotificationSettings.fromJSON(object.notificationSettings)
                : undefined,
            roles: Array.isArray(object === null || object === void 0 ? void 0 : object.roles)
                ? object.roles.map((e) => roleFromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.username !== undefined && (obj.username = message.username);
        if (message.businesses) {
            obj.businesses = message.businesses.map((e) => e ? BusinessPresentation.toJSON(e) : undefined);
        }
        else {
            obj.businesses = [];
        }
        message.inboxSettings !== undefined &&
            (obj.inboxSettings = message.inboxSettings
                ? InboxSettings.toJSON(message.inboxSettings)
                : undefined);
        message.displayName !== undefined &&
            (obj.displayName = message.displayName);
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.experiments !== undefined &&
            (obj.experiments = message.experiments
                ? UserExperimentDtoCollection.toJSON(message.experiments)
                : undefined);
        message.notificationSettings !== undefined &&
            (obj.notificationSettings = message.notificationSettings
                ? NotificationSettings.toJSON(message.notificationSettings)
                : undefined);
        if (message.roles) {
            obj.roles = message.roles.map((e) => roleToJSON(e));
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
};
function createBaseAgentIdentifier() {
    return { id: undefined, email: undefined };
}
export const AgentIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            StringValue.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAgentIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.email !== undefined && (obj.email = message.email);
        return obj;
    },
};
function createBaseAgentDto() {
    return {
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        scope: undefined,
        displayName: "",
    };
}
export const AgentDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.username !== "") {
            writer.uint32(18).string(message.username);
        }
        if (message.firstName !== "") {
            writer.uint32(26).string(message.firstName);
        }
        if (message.lastName !== "") {
            writer.uint32(34).string(message.lastName);
        }
        if (message.scope !== undefined) {
            UserScope.encode(message.scope, writer.uint32(42).fork()).ldelim();
        }
        if (message.displayName !== "") {
            writer.uint32(50).string(message.displayName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAgentDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.scope = UserScope.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.displayName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            username: isSet(object.username) ? String(object.username) : "",
            firstName: isSet(object.firstName) ? String(object.firstName) : "",
            lastName: isSet(object.lastName) ? String(object.lastName) : "",
            scope: isSet(object.scope) ? UserScope.fromJSON(object.scope) : undefined,
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.username !== undefined && (obj.username = message.username);
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.scope !== undefined &&
            (obj.scope = message.scope ? UserScope.toJSON(message.scope) : undefined);
        message.displayName !== undefined &&
            (obj.displayName = message.displayName);
        return obj;
    },
};
function createBaseAgentDtoCollection() {
    return { agents: [] };
}
export const AgentDtoCollection = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.agents) {
            AgentDto.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAgentDtoCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.agents.push(AgentDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            agents: Array.isArray(object === null || object === void 0 ? void 0 : object.agents)
                ? object.agents.map((e) => AgentDto.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.agents) {
            obj.agents = message.agents.map((e) => e ? AgentDto.toJSON(e) : undefined);
        }
        else {
            obj.agents = [];
        }
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
