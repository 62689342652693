import React from 'react';
import { useTheme, Box, Label } from '@connectlyai-tenets/ui-styled-web';

type UserCellProps = {
  initials: string;
  name: string;
  email: string;
};

export const UserCell = ({ initials, name, email }: UserCellProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        className="user-avatar"
        fontSize={12}
        sx={{
          background: theme.palette.secondary.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          mr: 1,
        }}
      >
        {initials}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Label variant="body1">{name}</Label>
        <Label
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {email}
        </Label>
      </Box>
    </Box>
  );
};
