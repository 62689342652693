import React, { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { nodesAtom } from '@atoms/flow';
import { Node } from 'react-flow-renderer';
import { useContextSelector } from 'use-context-selector';
import { BoltIcon, CampaignMUIIcon } from '@connectlyai-tenets/ui-styled-web';
import produce from 'immer';
import { TemplateCategory } from '@connectlyai-tenets/sdk';
import { DEFAULT_TEMPLATE_CATEGORY, TEMPLATE_CATEGORIES } from './constants';
import { FlowContext } from '../../../../contexts';

export const useTemplateCategory = (nodeId: string) => {
  const nodes = useAtomValue(nodesAtom);
  const node = useMemo(() => nodes.find((n) => n.id === nodeId), [nodes, nodeId]);
  const templateCategory = useMemo(
    () => (node?.data?.v1?.templateCategory as TemplateCategory) || DEFAULT_TEMPLATE_CATEGORY,
    [node?.data?.v1?.templateCategory],
  );
  const showTemplateCategoryButton = useMemo(
    () => node?.type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE',
    [node?.type],
  );
  const canChangeTemplateCategory = useMemo(() => node?.data?.v1?.templateId === '', [node?.data?.v1?.templateId]);
  const { onNodeObjectChange } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);
  const handleChangeTemplateCategory = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const newTemplateCategory: TemplateCategory =
        TEMPLATE_CATEGORIES[(TEMPLATE_CATEGORIES.indexOf(templateCategory) + 1) % TEMPLATE_CATEGORIES.length];

      onNodeObjectChange([
        {
          id: nodeId,
          item: produce(node, (draft: Node) => {
            draft.data.v1.templateCategory = newTemplateCategory;
          }) as unknown as Node,
        },
      ]);
      event.stopPropagation();
    },
    [node, nodeId, onNodeObjectChange, templateCategory],
  );

  const templateCategoryText = useMemo(() => {
    switch (templateCategory) {
      case 'MESSAGE_TEMPLATE_GROUP_CATEGORY_UTILITY':
        return 'Utility Template';
      case 'MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING':
        return 'Marketing Template';
      default:
        return 'Marketing Template';
    }
  }, [templateCategory]);

  const templateCategoryIcon = useMemo(() => {
    switch (templateCategory) {
      case 'MESSAGE_TEMPLATE_GROUP_CATEGORY_UTILITY':
        return <BoltIcon />;
      case 'MESSAGE_TEMPLATE_GROUP_CATEGORY_MARKETING':
        return <CampaignMUIIcon />;
      default:
        return <CampaignMUIIcon />;
    }
  }, [templateCategory]);

  return {
    canChangeTemplateCategory,
    handleChangeTemplateCategory,
    showTemplateCategoryButton,
    templateCategory,
    templateCategoryIcon,
    templateCategoryText,
  };
};
