import { useQuery } from '@tanstack/react-query';
import { QUERY_ALERTS_URL, QueryAlertsOptions, QueryAlertsParametersPath } from './types';
import { api } from '../../api';

export const useQueryAlertsData = ({ businessId }: QueryAlertsParametersPath, options: QueryAlertsOptions) =>
  useQuery(
    ['queryAlerts', businessId],
    () =>
      api.profileV1(QUERY_ALERTS_URL, 'get', {
        path: { businessId },
      }),
    options,
  );
