import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { ScheduleCampaignsQuery, ScheduleCampaignsUrlParameters, ScheduleCampaignsResponse } from './types';

export const useScheduleCampaignMutation = (
  options?: Omit<
    UseMutationOptions<
      ScheduleCampaignsResponse,
      unknown,
      ScheduleCampaignsQuery & ScheduleCampaignsUrlParameters,
      unknown
    >,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, sendoutId, schedule, unschedule }: ScheduleCampaignsQuery & ScheduleCampaignsUrlParameters) =>
      api.campaignV2('/internal/v2/businesses/{businessId}/schedule/sendouts/{sendoutId}', 'post', {
        path: { businessId, sendoutId },
        body: { entity: { schedule, unschedule } },
      }),
    options,
  );
