import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';
import { Box, Button, Card, CloseIcon, IconButton, Label, Tooltip, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SIZE_INPUT_M, SIZE_SPACING_INTER_COMPONENT, SIZE_SPACING_INTER_SECTION } from 'src/ui-theme';
import { track } from 'src/utils';
import { CSATDialog } from './CSATDialog';
import { csatStateAtom, csatRatingAtom, dialogOpenAtom } from '../atoms';
import { CSATOption, CSATOptions } from '../data';

const useCSATSection = () => {
  const [csatState, setCSATState] = useAtom(csatStateAtom);
  const [hovered, setHovered] = React.useState<CSATOption | null>(null);
  const setDialogOpen = useSetAtom(dialogOpenAtom);
  const [csatRating, setCSATRating] = useAtom(csatRatingAtom);

  const { t } = useTranslation('translation', { keyPrefix: 'csat.question' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('title'),
      subtitle: t('subtitle'),
    };
  }, [t]);

  const selected = useMemo(() => (hovered !== null ? hovered : csatRating), [hovered, csatRating]);
  const shouldShow = useMemo(() => {
    const now = new Date().getTime();
    if (csatState) {
      const closeTimestamp = new Date(csatState.closeUTCTimestamp || '2021-01-30T00:00:00.000Z').getTime();
      const submitTimestamp = new Date(csatState.submitUTCTimestamp || '2021-01-30T00:00:00.000Z').getTime();
      // at least 30 days since last time closed and at least 90 days since last time submitted.
      return now - closeTimestamp >= 30 * 24 * 60 * 60 * 1000 && now - submitTimestamp >= 90 * 24 * 60 * 60 * 1000;
    }
    return true;
  }, [csatState]);

  const handleClose = () => {
    track('dismiss csat feedback');

    const timestamp = new Date().toUTCString();
    setCSATState((prev) => {
      return {
        ...prev,
        closeUTCTimestamp: timestamp,
      };
    });
  };

  const handleOptionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const option = e.currentTarget.dataset.option as CSATOption;
    setDialogOpen(true);
    setCSATRating(option);
    track('clicks csat feedback', { rating: option });
  };

  const handleHoverOption = (option: CSATOption) => {
    setHovered(option);
  };

  const handleHoverCloseOption = () => {
    setHovered(null);
  };

  const options = useMemo(() => {
    return (Object.keys(CSATOptions) as Array<keyof typeof CSATOptions>).map((opt) => {
      const option = CSATOptions[opt];
      return { key: opt, emoji: option.emoji, tooltip: t(option.tooltip) };
    });
  }, [t]);

  return {
    shouldShow,
    selected,
    title,
    subtitle,
    options,
    handleClose,
    handleOptionClick,
    handleHoverOption,
    handleHoverCloseOption,
  };
};

export const CSATSection: FC = () => {
  const {
    shouldShow,
    selected,
    title,
    subtitle,
    options,
    handleClose,
    handleOptionClick,
    handleHoverOption,
    handleHoverCloseOption,
  } = useCSATSection();

  const theme = useTheme();
  return (
    <>
      <CSATDialog />
      {shouldShow && (
        <Card
          variant="main"
          sx={{
            p: 3,
            gap: SIZE_SPACING_INTER_SECTION,
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: SIZE_SPACING_INTER_COMPONENT,
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Label sx={{ whiteSpace: 'pre-wrap', fontWeight: '600!important' }} variant="h6">
                {title}
              </Label>
            </Box>
            <Label sx={{ color: 'text.secondary', fontSize: '14px!important' }} variant="body1">
              {subtitle}
            </Label>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: SIZE_SPACING_INTER_COMPONENT,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {options.map((opt) => (
                <Box key={opt.key}>
                  <Tooltip
                    title={opt.tooltip}
                    onOpen={() => handleHoverOption(opt.key)}
                    onClose={handleHoverCloseOption}
                  >
                    <Button
                      sx={{
                        width: theme.spacing(SIZE_INPUT_M),
                        height: theme.spacing(SIZE_INPUT_M),
                      }}
                      disabled={selected !== null && selected !== opt.key}
                      data-option={opt.key}
                      onClick={handleOptionClick}
                    >
                      <Label sx={{ fontSize: selected === opt.key ? '32px!important' : '30px!important' }}>
                        {opt.emoji}
                      </Label>
                    </Button>
                  </Tooltip>
                </Box>
              ))}
            </Box>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Card>
      )}
    </>
  );
};
