import { validateShopifyDomain } from '@connectlyai-sdks/provider-shopify';

export const SHOPIFY_INTEGRATION_SCOPE = [
  'read_all_orders',
  'read_content',
  'read_customers',
  'read_discounts',
  'read_inventory',
  'read_legal_policies',
  'read_locales',
  'read_locations',
  'read_metaobjects',
  'read_orders',
  'read_price_rules',
  'read_products',
  'read_publications',
  'read_purchase_options',
  'read_translations',
  'read_themes',
  'write_themes',
];

export function getShopifyConnectUrl(businessId: string, storeUrl: string): URL | undefined {
  const shopifyDomain = validateShopifyDomain(storeUrl);
  if (!shopifyDomain) {
    return undefined;
  }

  const url = new URL(`https://${shopifyDomain}/admin/oauth/authorize`);
  const search = new URLSearchParams({
    'grant_options[]': 'value',
    scope: SHOPIFY_INTEGRATION_SCOPE.join(','),
    client_id: process.env.REACT_APP_SHOPIFY_CLIENT_ID || '',
    redirect_uri: process.env.REACT_APP_SHOPIFY_REDIRECT_URI || '',
    state: JSON.stringify({
      business_id: businessId || '',
      client_id: process.env.REACT_APP_SHOPIFY_CLIENT_ID || '',
    }),
  });
  url.search = search.toString();

  return url;
}
