import React from 'react';
import { Box, Divider, InfoIcon, Label, Stack, Tooltip, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { TableSectionProps } from './types';

// TODO(baber) make totalCount optional and derive it from dataItems
export const TableSection = ({ title, totalCount, hint = '', dataItems = [], ListItemIcon }: TableSectionProps) => {
  const theme = useTheme();

  return (
    <Stack>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
        <Label
          sx={{
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {title}
        </Label>
        {hint.length > 0 && (
          <Tooltip arrow placement="top" title={hint}>
            <InfoIcon width={12} height={12} fill={theme.palette.secondary.dark} />
          </Tooltip>
        )}
      </Stack>
      <Box
        sx={{
          padding: '12px 16px',
          borderRadius: '10px',
          background: theme.palette.grey.A100,
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ fontSize: '14px' }}>
          <Label sx={{ fontWeight: '400' }}>Total {title.toLowerCase()}</Label>
          <Label sx={{ fontWeight: '500' }}>{totalCount}</Label>
        </Stack>
        {dataItems.length > 0 && (
          <>
            <Divider />
            {dataItems.map((item) => (
              <Stack
                key={`${item.label}-${item.value}`}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  {Boolean(ListItemIcon) && ListItemIcon}
                  <Label sx={{ fontSize: '12px', fontWeight: '400' }}>{item.label}</Label>
                </Stack>
                <Label sx={{ fontSize: '12px', fontWeight: '400' }}>{item.value}</Label>
              </Stack>
            ))}
          </>
        )}
      </Box>
    </Stack>
  );
};
