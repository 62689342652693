export const FOCUS_DELAY_MS = 500;

export function scrollToHTMLInputElement(elementName: string, shake = false, timeout = FOCUS_DELAY_MS) {
  const element = document.getElementById(elementName) as HTMLInputElement;
  element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  setTimeout(() => {
    element?.select();
    if (shake) {
      element?.parentElement?.classList.remove('shake-horizontal');
      setTimeout(() => {
        element?.parentElement?.classList.add('shake-horizontal');
      }, 100);
    }
  }, timeout);
}

export function scrollToBottom(elementName: string, timeout = FOCUS_DELAY_MS) {
  setTimeout(() => {
    const templateContent = document.getElementById(elementName);
    templateContent?.scrollTo({ top: 100000, behavior: 'smooth' });
  }, timeout);
}

export function scrollToBottomAndSelectHTMLInputElement(
  container: string,
  elementName: string,
  timeout = FOCUS_DELAY_MS,
) {
  const templateContent = document.getElementById(container);
  templateContent?.scrollTo({ top: 100000, behavior: 'smooth' });
  setTimeout(() => {
    const element = document.getElementById(elementName) as HTMLInputElement;
    element?.select();
  }, timeout);
}
