import { SofiaStoreDescriptionView } from '../../types';
import { DEFAULT_STORE_DESCRIPTOR_V2 } from '../../constants';
import { StoreDescriptorV2 } from '../useSofiaStoreDescriptorV2';

export const mapSofiaViewDataToStoreDescriptorV2 = (data: SofiaStoreDescriptionView): StoreDescriptorV2 => {
  const storeDescriptor = { ...DEFAULT_STORE_DESCRIPTOR_V2 };
  storeDescriptor.id = data.id;
  storeDescriptor.defaultLanguage = data.language;
  storeDescriptor.name = data.name;
  storeDescriptor.url = data.url;
  storeDescriptor.about = JSON.stringify({
    about_store: data.about,
  });
  return storeDescriptor;
};
