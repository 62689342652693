import React, { useEffect, useCallback, useState, useMemo, FC } from 'react';
import {
  ArrowDropDownIcon,
  Box,
  Button,
  Checkbox,
  Label,
  Table,
  TablePagination,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  useTheme,
  InputAdornment,
  SearchIcon,
  TextField,
  Stack,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import { debounce } from 'lodash';
import { IconWithLoading } from '@components/IconWithLoading';
import { FormattedTooltip } from '@components/Tooltips';
import {
  EnhancedTableHeadProps,
  EnhancedTableRowProps,
  EnhancedTableProps,
  TableHeadCell,
  UseEnhancedTableProps,
  Sort,
} from './types';
import { SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import { DeleteEntriesModal } from './DeleteEntriesModal';
import { useSelectFunctionality } from './useSelectFunctionality';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const EnhancedTableRow: FC<EnhancedTableRowProps> = ({
  id,
  data,
  viewOnly,
  showSelect,
  selected,
  selectable,
  onSelect,
  onClick,
}: EnhancedTableRowProps) => {
  const theme = useTheme();
  return (
    <TableRow
      sx={{
        border: 'none',
        '&:hover': {
          background: theme.palette.grey.A100,
        },
        mx: -1,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick ? () => onClick(id) : undefined}
    >
      {showSelect && onSelect && (
        <TableCell
          sx={{
            borderTop: `1px solid ${theme.palette.grey[200]}`,
            width: theme.spacing(2),
            px: 1,
          }}
        >
          <Checkbox
            checked={selected}
            onChange={() => {
              onSelect(id);
            }}
            onClick={(e) => e.stopPropagation()}
            disabled={!selectable}
            sx={{
              p: 0,
              cursor: viewOnly ? 'default' : 'pointer',
              pointerEvents: viewOnly ? 'none' : 'auto',
            }}
          />
        </TableCell>
      )}
      {data.map((item, i) => {
        let justifyContent = 'flex-start';
        switch (item.align) {
          case 'left':
            justifyContent = 'flex-start';
            break;
          case 'center':
            justifyContent = 'space-around';
            break;
          case 'right':
            justifyContent = 'flex-end';
            break;
          default:
            justifyContent = i === data.length - 1 ? 'flex-end' : 'flex-start';
            break;
        }
        return (
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell-${id}-${i}`}
            sx={{
              borderTop: `1px solid ${theme.palette.grey[200]}`,
              height: theme.spacing(4.5),
              maxHeight: theme.spacing(4.5),
              py: 1.5,
              px: 1,
            }}
          >
            <WithSkeleton wrap={id.includes('SKELETON') || false}>
              <Box
                onClick={item.dropdownClick}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent,
                  cursor: viewOnly || !item.dropdownClick ? 'default' : 'pointer',
                  pointerEvents: viewOnly ? 'none' : 'auto',
                }}
              >
                {item.display || item.value}
                {item.dropdownClick && <ArrowDropDownIcon color={viewOnly ? 'disabled' : 'action'} />}
              </Box>
            </WithSkeleton>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({
  cells,
  onRequestSort,
  sort,
  showSelect,
  selectAll,
  setSelectAll,
}: EnhancedTableHeadProps) => {
  const createSortHandler = useCallback(
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  const theme = useTheme();
  return (
    <TableHead
      sx={{
        boxSizing: 'border-box',
        background: 'white',
      }}
    >
      <TableRow>
        {showSelect && (
          <TableCell
            variant="head"
            sx={{
              background: 'none',
              borderTop: `1px solid ${theme.palette.grey[200]}`,
              width: theme.spacing(2),
              px: 1,
            }}
          >
            <Checkbox
              checked={selectAll}
              onChange={() => setSelectAll && setSelectAll(!selectAll)}
              sx={{
                p: 0,
                cursor: 'pointer',
              }}
            />
          </TableCell>
        )}
        {cells.map((item) => {
          const align = item.align || 'center';
          return (
            <TableCell
              key={item.id}
              variant="head"
              align={align}
              sx={{
                background: 'none',
                width: item.width,
                px: 1,
                fontWeight: sort.by === item.id ? 'bold' : 'normal',
              }}
              sortDirection={sort.by === item.id ? sort.order : undefined}
            >
              <FormattedTooltip title={item.tooltip || ''} arrow>
                {item.sort ? (
                  <TableSortLabel
                    active={sort.by === item.id}
                    direction={sort.by === item.id ? sort.order : undefined}
                    onClick={createSortHandler(item.id)}
                  >
                    <Label
                      sx={{
                        color: sort.by === item.id ? theme.palette.common.black : theme.palette.text.secondary,
                        ml: align === 'center' ? 2.25 : 0, // balance the padding of the TableSortLabel
                      }}
                    >
                      {item.label}
                    </Label>
                  </TableSortLabel>
                ) : (
                  <Label>{item.label}</Label>
                )}
              </FormattedTooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const useEnhancedTable = ({
  rows,
  headers,
  sortDefault,
  selectSupport,
  title,
  searchSupport,
}: UseEnhancedTableProps) => {
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [sort, setSort] = useState<Sort>(sortDefault || { by: headers[0].id || '', order: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dataLength = useMemo(() => {
    return rows.length;
  }, [rows.length]);

  useEffect(() => {
    setPage(0);
  }, [rows.length]);
  const debounceDelay = useMemo(() => {
    if (!dataLength) return 0;
    if (dataLength < 200) return 100;
    if (dataLength < 1000) return 200;
    if (dataLength < 5000) return 350;
    if (dataLength < 10000) return 500;
    return 1000;
  }, [dataLength]);
  const debouncedSearch = debounce((input) => {
    setSearch(input);
    setSearching(false);
  }, debounceDelay);
  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!dataLength) return;
      if (dataLength < 100) {
        setSearch(e.target.value);
        return;
      }
      setSearching(true);
      debouncedSearch(e.target.value);
    },
    [setSearching, debouncedSearch, dataLength],
  );

  const searchedRows: EnhancedTableRowProps[] = useMemo(() => {
    if (!search || !searchSupport) return rows;
    setPage(0);
    return rows.filter((row) => searchSupport.searchFn(row, search));
  }, [search, searchSupport, rows]);
  const sortedRows: EnhancedTableRowProps[] = useMemo(() => {
    const sortColumnIndex = headers.findIndex((h: TableHeadCell) => h.id === sort.by);
    if (sortColumnIndex >= 0) {
      const sortFn = headers[sortColumnIndex].sort;
      if (sortFn) {
        searchedRows.sort((a, b) => {
          return sortFn(sort.order)(a.data[sortColumnIndex]?.value, b.data[sortColumnIndex]?.value);
        });
      }
    }
    return searchedRows;
  }, [headers, searchedRows, sort.by, sort.order]);

  const visibleRows = useMemo(() => {
    const paginated =
      rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;
    return paginated;
    // sort is needed to recalculate visibleRows when sort changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRows, page, rowsPerPage, sort]);

  const onRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = sort.by === property && sort.order === 'asc';
      setSort({
        by: property,
        order: isAsc ? 'desc' : 'asc',
      });
    },
    [setSort, sort],
  );
  const theme = useTheme();
  const titleDisplay = useMemo(() => {
    if (title === undefined) return '';
    if (typeof title === 'string') return title;
    return title(searchedRows.length);
  }, [searchedRows.length, title]);

  // Select functionality
  const selectableRowIds = useMemo(() => {
    return rows.filter((row) => row.selectable).map((row) => row.id);
  }, [rows]);
  const selectFunctionality = useSelectFunctionality({ selectSupport, selectableRowIds });
  useEffect(() => {
    if (!selectFunctionality || !selectFunctionality.onSelectRow) return;
    selectFunctionality.onSelectAll(selectAll);
  }, [selectAll]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(Math.max(0, newPage));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setRowsPerPage(Number(value));
  };

  return {
    titleDisplay,
    search,
    onSearch,
    searching,
    onRequestSort,
    visibleRows,
    sort,
    rowsPerPage,
    page,
    selectFunctionality,
    selectAll,
    setSelectAll,
    theme,
    handleChangePage,
    handleRowsPerPageChange,
  };
};
export const EnhancedTable = ({
  rows,
  headers,
  sortDefault,
  viewOnly,
  selectSupport,
  title,
  icon,
  isLoading,
  searchSupport,
  actions,
  enablePagination = true,
}: EnhancedTableProps) => {
  const {
    titleDisplay,
    selectFunctionality,
    theme,
    search,
    onSearch,
    searching,
    onRequestSort,
    sort,
    rowsPerPage,
    page,
    visibleRows,
    selectAll,
    setSelectAll,
    handleChangePage,
    handleRowsPerPageChange,
  } = useEnhancedTable({
    selectSupport,
    searchSupport,
    rows,
    headers,
    sortDefault,
    title,
  });
  return (
    <>
      {selectFunctionality && selectFunctionality.modal && (
        <DeleteEntriesModal
          open={selectFunctionality.modal.open}
          setOpen={selectFunctionality.modal.setOpen}
          title={selectFunctionality.modal.title}
          subtitle={selectFunctionality.modal.subtitle}
          onConfirm={selectFunctionality.modal.onConfirm}
          loading={selectFunctionality.loading}
        />
      )}
      <Box
        sx={{
          minHeight: 0,
          flex: '1 1 100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          scrollbarGutter: 'stable',
          '&:hover': {
            overflow: 'auto',
          },
        }}
      >
        {(titleDisplay || icon || selectFunctionality) && (
          <Box
            sx={{
              width: '100%',
              flex: '1 1 100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {titleDisplay && (
              <Stack direction="row" spacing={0.5} alignItems="center" flex="0 0 auto">
                {icon && (
                  <IconWithLoading loading={!!isLoading} size={4}>
                    {icon}
                  </IconWithLoading>
                )}
                <Label variant="h6">{titleDisplay}</Label>
              </Stack>
            )}
            {(searchSupport || selectFunctionality || actions) && (
              <Stack
                direction="row"
                spacing={SIZE_SPACING_INTER_COMPONENT}
                alignItems="center"
                justifyContent="flex-end"
                flexWrap="wrap"
                flex="1 1 auto"
              >
                {searchSupport && (!selectFunctionality || !selectFunctionality.selecting) && (
                  <TextField
                    defaultValue={search}
                    onChange={onSearch}
                    type="text"
                    placeholder={searchSupport.placeholder || 'Search Number'}
                    inputProps={{
                      style: {
                        padding: theme.spacing(1.5),
                        paddingLeft: theme.spacing(0),
                        borderRadius: theme.spacing(2),
                      },
                    }}
                    InputProps={{
                      sx: {
                        borderColor: theme.palette.grey[300],
                        borderRadius: theme.spacing(0.5),
                        pl: 1.5,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconWithLoading loading={searching} size={3}>
                            <SearchIcon />
                          </IconWithLoading>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {selectFunctionality && selectFunctionality.actionButtonText && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: SIZE_SPACING_INTER_COMPONENT,
                    }}
                  >
                    {selectFunctionality.selecting && (
                      <Button variant="text" color="primary" onClick={selectFunctionality.cancelEdit}>
                        Cancel
                      </Button>
                    )}
                    <Button
                      variant={selectFunctionality.selecting ? 'contained' : 'outlined'}
                      color="secondary"
                      onClick={() => {
                        if (selectFunctionality.selecting && selectFunctionality.modal) {
                          selectFunctionality.modal.setOpen(true);
                        } else {
                          selectFunctionality.setSelecting(true);
                        }
                      }}
                      disabled={selectFunctionality.selecting && selectFunctionality.selected.length === 0}
                    >
                      {selectFunctionality.actionButtonText}
                    </Button>
                  </Box>
                )}
                {actions && actions}
              </Stack>
            )}
          </Box>
        )}
        <Table
          stickyHeader
          sx={{
            flex: '0 0 auto',
            height: 'auto',
          }}
        >
          <EnhancedTableHead
            cells={headers}
            onRequestSort={onRequestSort}
            sort={sort}
            showSelect={Boolean(selectFunctionality && selectFunctionality.selecting)}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
          <TableBody
            sx={{
              overflow: 'hidden',
              scrollbarGutter: 'stable',
              '&:hover': {
                overflow: 'auto',
              },
            }}
          >
            {visibleRows.map((tableRow: EnhancedTableRowProps) => (
              <EnhancedTableRow
                key={tableRow.id}
                viewOnly={viewOnly}
                id={tableRow.id}
                data={tableRow.data}
                selectable={tableRow.selectable}
                onClick={tableRow.onClick}
                showSelect={Boolean(selectFunctionality && selectFunctionality.selecting)}
                selected={Boolean(selectFunctionality && selectFunctionality.selected.includes(tableRow.id))}
                onSelect={selectFunctionality ? selectFunctionality.onSelectRow : undefined}
                clearSelectAll={() => setSelectAll(false)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      {enablePagination && (
        <TablePagination
          sx={{
            position: 'sticky',
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: '100%',
            '& .MuiToolbar-root': {
              pr: 6, // the dimensions of a typical bottom-left overview item. e.g. zendesk
            },
          }}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};
