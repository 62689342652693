import { TagAssociation, TagAssociationInput } from '@connectlyai-idl/models/dist/models/tags/models';
export { Tag } from '@connectlyai-sdks/data-business';
export { RoomDto } from '@connectlyai-sdks/data-rooms';
export { AssetDto } from '@connectlyai-idl/models/dist/models/asset/models';
export { AttachmentType as AttachmentTypeDto } from '@connectlyai-idl/models/dist/models/event/connectly/models';
export { RoomStatus, roomStatusFromJSON } from '@connectlyai-idl/models/dist/models/common/models';
export { Location } from '@connectlyai-idl/models/dist/models/event/connectly/models';
export { CreateEventInput, ChannelIdentifier, ChannelType } from '@connectlyai-sdks/data-rooms';
export { USER_EMPTY } from '@connectlyai-sdks/data-user';
export { ComposerSendBehavior } from '@connectlyai-sdks/data-user';
export { TagAssociation, TagAssociationInput };
export var MessageClass;
(function (MessageClass) {
    MessageClass[MessageClass["MINE"] = 0] = "MINE";
    MessageClass[MessageClass["THEIRS"] = 1] = "THEIRS";
})(MessageClass || (MessageClass = {}));
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType[AttachmentType["IMAGE"] = 0] = "IMAGE";
    AttachmentType[AttachmentType["VIDEO"] = 1] = "VIDEO";
    AttachmentType[AttachmentType["AUDIO"] = 2] = "AUDIO";
    AttachmentType[AttachmentType["FILE"] = 3] = "FILE";
})(AttachmentType || (AttachmentType = {}));
