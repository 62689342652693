import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { nodesUIStateAtom } from '@atoms/flow';
import { Node } from 'react-flow-renderer';
import { NodeType } from '@sdks/flow/createNode';
import { IncomingRoomEventData, APICallData, TimeDelayData } from '@connectlyai-tenets/sdk';
import { CustomerRepliesNodeUIState } from './types';
import {
  mapUIStateToDataV1ErrorHandler,
  mapUIStateToDataV1Message,
  mapUIStateToMessageDataV1ParameterMapping,
} from './incomingRoomEventMappers';
import { mapUIStateToDataV1Input, mapUIStateToDataV1ParameterMapping } from './apiCallNodeMappers';
import { mapTimeDelayUIStateToDataV1Input } from './timeDelayNodeMappers';
import { APICallNodeUIState } from '../../components/APICallNodeEditor';
import { TimeDelayNodeUIState } from '../../components/TimeDelayNodeEditor';
import { mapNodeDataToUIState } from '../useNodeUIState/mappers';

export const useNodePersistence = () => {
  const setNodesUIState = useSetAtom(nodesUIStateAtom);

  const loadAllNodesIntoUIState = useCallback(
    (nodes: Node[]) => {
      const nodeUIs = nodes
        .map((node) => {
          if (!node.data || !node.type) return {};
          return { [node.id]: mapNodeDataToUIState(node.data, node.type as NodeType) };
        })
        .reduce((acc, state) => {
          acc = { ...acc, ...state };
          return acc;
        }, {});
      setNodesUIState(nodeUIs);
    },
    [setNodesUIState],
  );

  const saveIncomingRoomEventNode = useCallback((uiState: CustomerRepliesNodeUIState): IncomingRoomEventData => {
    return {
      v1: {
        ...mapUIStateToDataV1ErrorHandler(uiState),
        ...mapUIStateToDataV1Message(uiState),
        ...mapUIStateToMessageDataV1ParameterMapping(uiState),
      },
    };
  }, []);

  const saveApiCallNode = useCallback((uiState: APICallNodeUIState): APICallData => {
    return { v1: { ...mapUIStateToDataV1Input(uiState), ...mapUIStateToDataV1ParameterMapping(uiState) } };
  }, []);

  const saveTimeDelayNode = useCallback((uiState: TimeDelayNodeUIState): TimeDelayData => {
    return { v1: { input: { ...mapTimeDelayUIStateToDataV1Input(uiState) } } };
  }, []);

  return {
    loadAllNodesIntoUIState,
    saveApiCallNode,
    saveIncomingRoomEventNode,
    saveTimeDelayNode,
  };
};
