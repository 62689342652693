import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import type { UseMutationOptions } from '@tanstack/react-query';

export const DELETE_SEGMENT_URL = '/internal/v1/businesses/{businessId}/audience_segments/{segmentId}';
type DeleteSegmentUrl = typeof DELETE_SEGMENT_URL;

export type DeleteSegmentPath = Paths['audienceV1'][DeleteSegmentUrl]['delete']['parameters']['path'];

export type DeleteSegmentResponse = Paths['audienceV1'][DeleteSegmentUrl]['delete']['responses']['200']['schema'];

export type DeleteSegmentOptions = Omit<
  UseMutationOptions<DeleteSegmentResponse, ConnectlyError, DeleteSegmentPath, unknown>,
  'mutationKey' | 'mutationFn'
>;
