import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { UpdateCohortOptions, UpdateCohortVariables, UPDATE_COHORT_URL } from './types';

export const useMutationUpdateCohort = (options?: UpdateCohortOptions) =>
  useMutation(
    ({ businessId, sendoutId, ...rest }: UpdateCohortVariables) =>
      api.campaignV3(UPDATE_COHORT_URL, 'post', {
        path: { 'input.businessId': businessId, 'input.sendoutId': sendoutId },
        body: { input: rest },
      }),
    options,
  );
