export var NotificationSeverity;
(function (NotificationSeverity) {
    NotificationSeverity[NotificationSeverity["UNKNOWN"] = 0] = "UNKNOWN";
    NotificationSeverity[NotificationSeverity["INFO"] = 1] = "INFO";
    NotificationSeverity[NotificationSeverity["SUCCESS"] = 2] = "SUCCESS";
    NotificationSeverity[NotificationSeverity["WARNING"] = 3] = "WARNING";
    NotificationSeverity[NotificationSeverity["ERROR"] = 4] = "ERROR";
})(NotificationSeverity || (NotificationSeverity = {}));
export var NotificationSurface;
(function (NotificationSurface) {
    NotificationSurface[NotificationSurface["TAB"] = 0] = "TAB";
    NotificationSurface[NotificationSurface["SNACKBAR"] = 1] = "SNACKBAR";
})(NotificationSurface || (NotificationSurface = {}));
