import { MIN_SCHEDULED_DELAY_MINUTES } from './constants';

export const clampDate = (date: Date | null, minDate: Date, maxDate: Date): Date | null => {
  if (date === null) return null;
  let newDate = minDate !== undefined && date < minDate ? minDate : date;
  newDate = maxDate !== undefined && newDate > maxDate ? maxDate : newDate;
  return newDate;
};

export const isSameDay = (date1: Date | null | undefined, date2: Date | null | undefined): boolean => {
  if (date1 === null || date1 === undefined) {
    return false;
  }
  if (date2 === null || date2 === undefined) {
    return false;
  }

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const minMaxDate = (now: Date) => {
  const min = new Date(now);
  min.setMinutes(min.getMinutes());
  min.setMinutes(min.getMinutes() + MIN_SCHEDULED_DELAY_MINUTES);
  min.setSeconds(0);

  const max = new Date(now);
  max.setMonth(max.getMonth() + 1);
  max.setMinutes(max.getMinutes() + MIN_SCHEDULED_DELAY_MINUTES);
  max.setSeconds(0);

  return [min, max];
};
