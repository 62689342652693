import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MutationStatus } from '@connectlyai-idl/models/dist/models/common/api/models';
import { useTheme } from '@connectlyai-tenets/ui-styled-web';
import { unreachable } from '@connectlyai-tenets/static-analysis';
import { FlowDIContext, FlowDIContextProps } from '../../features/flow/contexts/FlowDIContext';
import { ProgressStatus } from './types';
import { selectSendoutId } from '../../state/flow';
import { usePrevious } from '../usePrevious';
import { selectDocumentId } from '../../state/flow/selectors';
import { selectBusinessId, useMeData } from '../useMeData';
import { useLogger } from '../useLogger';

export const useVerifySendoutResultData = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const sendoutId = useSelector(selectSendoutId);
  const { logger } = useLogger();

  const { dtoUpdateAccessorProvider } = useContext(FlowDIContext) as FlowDIContextProps;
  const [verifyStatus, setVerifyStatus] = React.useState<MutationStatus>(MutationStatus.MUTATION_STATUS_UNSPECIFIED);
  const [verifyProgress, setVerifyProgress] = React.useState<ProgressStatus | undefined>();

  useEffect(() => {
    let sub: Subscription;
    if (sendoutId) {
      sub = dtoUpdateAccessorProvider()
        .subscriptionDtoUpdate()
        .pipe(
          filter(
            (dtoUpdate) => dtoUpdate.objectId === sendoutId && dtoUpdate.resultOneof?.$case === 'verifySendoutResult',
          ),
          map((dtoUpdate) => {
            if (dtoUpdate.resultOneof?.$case !== 'verifySendoutResult') {
              return unreachable();
            }
            return dtoUpdate?.resultOneof?.verifySendoutResult;
          }),
        )
        .subscribe((verifySendoutResult) => {
          if (verifySendoutResult) {
            setVerifyStatus(verifySendoutResult.status);
            setVerifyProgress(verifySendoutResult.progress);

            if (verifySendoutResult.status === MutationStatus.MUTATION_STATUS_SUCCESS) {
              logger.info(`Received dtoUpdate sheet verification success for sendoutId: ${sendoutId}`);
            }
          }
        });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [businessId, sendoutId, dtoUpdateAccessorProvider, logger, setVerifyProgress, setVerifyStatus]);

  const verifyHint = useMemo(() => {
    switch (verifyStatus) {
      case MutationStatus.MUTATION_STATUS_SUCCESS:
        return 'Spreadsheet verified successfully';
      case MutationStatus.MUTATION_STATUS_FAILURE:
        return 'Spreadsheet verification has failed';
      default:
        return '';
    }
  }, [verifyStatus]);

  const theme = useTheme();

  const verifyHintColor = useMemo(() => {
    switch (verifyStatus) {
      case MutationStatus.MUTATION_STATUS_SUCCESS:
        return theme.palette.success.main;
      case MutationStatus.MUTATION_STATUS_FAILURE:
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  }, [verifyStatus, theme]);

  const isSuccess = useMemo(() => verifyStatus === MutationStatus.MUTATION_STATUS_SUCCESS, [verifyStatus]);
  const reset = useMemo(
    () => () => {
      setVerifyStatus(MutationStatus.MUTATION_STATUS_UNSPECIFIED);
      setVerifyProgress(undefined);
    },
    [],
  );

  // reset data when document id is changed
  const documentId = useSelector(selectDocumentId);
  const prevDocumentId = usePrevious(documentId);
  useEffect(() => {
    if (documentId !== prevDocumentId) {
      reset();
    }
  }, [documentId, prevDocumentId, reset]);

  const mutate = useCallback(() => {
    setVerifyStatus(MutationStatus.MUTATION_STATUS_CUSTOM);
    setVerifyProgress({
      total: 100,
      completed: 0,
    });
  }, []);

  return { mutate, isSuccess, reset, verifyHint, verifyHintColor, verifyProgress };
};

export type UseVerifySendoutResultData = ReturnType<typeof useVerifySendoutResultData>;
