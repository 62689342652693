import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_UPDATE_QUICK_REPLY_TEMPLATE_URL,
  MutationUpdateQuickReplyTemplateInput,
  MutationUpdateQuickReplyTemplateOptions,
} from './types';

export const useMutationUpdateQuickReplyTemplate = (options?: MutationUpdateQuickReplyTemplateOptions) => {
  return useMutation(
    [],
    (input: MutationUpdateQuickReplyTemplateInput) =>
      api.profileV1(MUTATION_UPDATE_QUICK_REPLY_TEMPLATE_URL, 'put', {
        path: { businessId: input.businessId, 'input.entity.id': input['input.entity.id'] },
        body: {
          input: {
            entity: {
              ...input,
            },
          },
        },
      }),
    options,
  );
};
