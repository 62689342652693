import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CloseIcon,
  IconButton,
  Label,
  PencilIcon,
  useTheme,
  CampaignIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom, useSetAtom } from 'jotai';
import ReactFlow, { Background, Controls, MarkerType, ReactFlowInstance, useKeyPress, Node } from 'react-flow-renderer';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { diff } from 'deep-object-diff';
import { isFlowChecksOpenAtom, documentTypeAtom } from '@atoms/flow';
import { track } from 'src/utils';
import { snakeCase } from 'lodash';
import { CamelToSnakeCase } from 'src/types';
import { FlowContext } from '../../contexts';
import { FlowCampaignContext, FlowCampaignDeps } from '../../features/flow/contexts/FlowCampaignContext';
import {
  FlowChartChecksSidebar,
  FlowChartSidebarAICampaign,
  FlowChartCampaignNameDialog,
  FlowChartConclusionDialog,
  FlowChartNodeSidebar,
  FlowChartSidebarPlaceholder,
  FlowChartTemplateApprovalDialog,
  FlowChartCampaignVerificationDialog,
} from '../../components';
import { FlowChartSidebar } from '../../components/FlowChartSidebar';
import { useAICampaign } from '../../hooks/useAICampaign';
import {
  MutationCreateOrUpdateReactFlowDocumentResponse,
  selectBusinessId,
  useCampaignName,
  useChangeFlowDocumentMutation,
  useChangesQueue,
  useCreateNode,
  useCreateOrUpdateFlowDocumentMutation,
  useEffectOnce,
  useFlowDocumentData,
  useFlowNavigator,
  useFlowSendChange,
  useMeData,
  usePrevious,
  useReactFlowDocumentChangeData,
  useSubmitSendoutResultData,
  useRequestSubmitReactFlowDocument,
  useUpdateCampaignNode,
  useVerifySendoutResultData,
  useFlowChecks,
} from '../../hooks';
import { useCampaignFlowChangeAppliers } from '../../features/flow/hooks/useCampaignFlowChangeAppliers';
import { FlowHandlers, useFlowHandlers } from '../../hooks/useFlowHandlers';
import { useFlowNodeTypes } from '../../hooks/useFlowNodeTypes';
import { useTemplateApprovalDialog } from '../../hooks/useTemplateApprovalDialog';
import { useCreateMessageTemplateResultData } from '../../hooks/useCreateMessageTemplateResultData';
import { FlowChartCustomEdge } from '../../components/FlowChartCustomEdge/FlowChartCustomEdge';
import {
  selectCampaignDate,
  selectCampaignName,
  selectCampaignTemplate,
  selectDocumentId,
  selectEdges,
  selectFetchCampaignSendNode,
  selectFlowState,
  goToNextFlowState,
  goToPreviousFlowState,
  selectIsAIGenerating,
  selectIsCampaignNameDialogOpen,
  selectNodes,
  selectPreviewDocumentId,
  resetFlowToPreface,
  selectSavedCampaignName,
  selectSendoutId,
  setFetchCampaignSendNode,
  setFlowState,
  setIsSpreadsheetOpen,
  setPreviewDocumentId,
  setSavedCampaignName,
  updateNodeWithId,
  selectIsDocumentEditable,
  selectCampaignFlow,
  selectStarter,
} from '../../state/flow';
import { FlowChartHeader } from '../../components/FlowChartHeader/FlowChartHeader';
import { NodeChange, NodeObjectUpdate } from '../../hooks/useChangesQueue';
import { ChangeFlowDocumentResponse } from '../../hooks/useChangeFlowDocumentMutation';
import { useLogger } from '../../hooks/useLogger';

export const FlowChart: FC = () => {
  // redux selectors
  const campaignDate = useSelector(selectCampaignDate);
  const campaignName = useSelector(selectCampaignName);
  const campaignTemplate = useSelector(selectCampaignTemplate);
  const documentId = useSelector(selectDocumentId);
  const edges = useSelector(selectEdges);
  const fetchCampaignSendNode = useSelector(selectFetchCampaignSendNode);
  const flowState = useSelector(selectFlowState);
  const isAIGenerating = useSelector(selectIsAIGenerating);
  const isCampaignNameDialogOpen = useSelector(selectIsCampaignNameDialogOpen);
  const nodes = useSelector(selectNodes);
  const previewDocumentId = useSelector(selectPreviewDocumentId);
  const savedCampaignName = useSelector(selectSavedCampaignName);
  const sendoutId = useSelector(selectSendoutId);
  const starter = useSelector(selectStarter);

  const { logger } = useLogger();

  const campaignFlowRedux = useSelector(selectCampaignFlow);
  const previousCampaignFlowRedux = usePrevious(campaignFlowRedux);
  useEffect(() => {
    if (!previousCampaignFlowRedux) return;
    const changes = diff(previousCampaignFlowRedux, campaignFlowRedux);

    // return if nothing has changed
    if (!changes || Object.keys(changes).length === 0) return;

    // send redux changes as datadog event with smaller information on title
    let title = JSON.stringify(changes);
    title = title.length < 100 ? title : `${title.substring(0, 100)}...`;
    datadogRum.addAction(title, { changes, last: campaignFlowRedux });
  }, [previousCampaignFlowRedux, campaignFlowRedux]);
  const { createNode } = useCreateNode();
  const { flowDocumentIdRouteMatch, navigateToCampaignsList } = useFlowNavigator();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const {
    mutate: createFlowDocumentMutate,
    isSuccess: isCreateFlowDocumentSuccess,
    isError: isCreateFlowDocumentError,
  } = useCreateOrUpdateFlowDocumentMutation();
  const { mutate: changeFlowDocumentMutate, isLoading: isLoadingChangeFlow } = useChangeFlowDocumentMutation();

  const dispatch = useDispatch();

  const { hasPendingTemplates, hasRejectedTemplates, hasUnsentTemplates } = useTemplateApprovalDialog();

  const verifySendoutResultData = useVerifySendoutResultData();
  const { reset: resetVerify, mutate: mutateVerify } = verifySendoutResultData;

  const fetchCampaignNode = useCallback(() => {
    dispatch(setFetchCampaignSendNode(true));
  }, [dispatch]);

  useReactFlowDocumentChangeData(fetchCampaignNode);

  const {
    mutate: flowEventRequestSubmitMutate,
    error: requestSubmitReactFlowDocumentError,
    reset: flowEventRequestSubmitReset,
  } = useRequestSubmitReactFlowDocument();
  const { isSuccess: isSubmitSuccess, submitProgress } = useSubmitSendoutResultData(sendoutId);

  const isCampaignNameTaken = useMemo(
    () => requestSubmitReactFlowDocumentError?.code === 'ERROR_CODE_CAMPAIGN_CONFIG_NAME_CONFLICT',
    [requestSubmitReactFlowDocumentError],
  );

  useEffect(() => {
    if (isSubmitSuccess) dispatch(setFlowState('conclusion'));
  }, [dispatch, isSubmitSuccess]);

  const deletePressed = useKeyPress(['Delete', 'Backspace']);

  const [documentType, setDocumentType] = useAtom(documentTypeAtom);
  const goBackToPreface = useCallback(() => {
    dispatch(resetFlowToPreface());
    setDocumentType(undefined);
  }, [dispatch, setDocumentType]);

  const { updateCampaignNode } = useUpdateCampaignNode();

  const flowChangeAppliers = useCampaignFlowChangeAppliers();
  const { onNodesChange, onEdgesChangeProtected, onNodesChangeProtected, updateNodeId, updateEdgeId } =
    flowChangeAppliers;
  const flowHandlers: FlowHandlers = useFlowHandlers({ flowChangeAppliers: useCampaignFlowChangeAppliers });
  const isNodePotentialTarget = useCallback((connectionNodeId: string | undefined | null, nodeId: string) => {
    return (
      connectionNodeId !== undefined &&
      connectionNodeId !== null &&
      connectionNodeId !== '' &&
      connectionNodeId !== nodeId
    );
  }, []);

  const isDocumentEditable = useSelector(selectIsDocumentEditable);

  const flowDeps = useMemo(
    () => ({
      flowChangeAppliers,
      flowHandlers,
      isDocumentEditable,
      isNodePotentialTarget,
      supportsLinkTracking: true,
      supportsVariables: false,
    }),
    [flowChangeAppliers, flowHandlers, isDocumentEditable, isNodePotentialTarget],
  );

  const afterNameChange = useCallback(() => {
    if (starter === 'duplicate' && previewDocumentId !== documentId) {
      dispatch(setPreviewDocumentId(documentId));
    }
    if ((starter === 'empty' || starter === 'template') && nodes.length === 0) {
      const campaignNode = createNode('FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN', {
        position: {
          x: 200,
          y: 500,
        },
        selected: false,
        data: {
          v1: {
            campaignConfig: {
              name: campaignName,
              type: 'CAMPAIGN_TYPE_MARKETING',
              flowDocumentId: documentId,
              language: 'en',
            },
          },
        },
      });
      if (!campaignNode) return;
      onNodesChange([{ type: 'add', item: campaignNode }]);
    } else {
      updateCampaignNode('buildCampaign');
    }
  }, [
    dispatch,
    previewDocumentId,
    campaignName,
    createNode,
    documentId,
    nodes.length,
    starter,
    onNodesChange,
    updateCampaignNode,
  ]);

  const {
    canOpenCampaignNameDialog,
    campaignNameColor,
    campaignNameInput,
    campaignNameHint,
    hasInvalidChars,
    handleCampaignNameDialogCancel,
    handleCampaignNameDialogSave,
    isCampaignNameDialogConfirmDisabled,
    openCampaignNameDialog,
    setCampaignNameInput,
  } = useCampaignName({
    afterNameChange,
    isCreateFlowDocumentSuccess,
    isCampaignNameTaken: isCampaignNameTaken || isCreateFlowDocumentError,
    goBackToPreface,
    onFlowConfirmForCampaignName: () => {
      track('clicks flow_confirm_for_campaign_name', { businessId });
    },
  });

  useEffect(() => {
    if (isCampaignNameTaken && !isCampaignNameDialogOpen) {
      setFlowState('buildCampaign');
      openCampaignNameDialog();
    }
  }, [isCampaignNameTaken, isCampaignNameDialogOpen, openCampaignNameDialog, setFlowState]);

  useCreateMessageTemplateResultData();

  const { markIdsOnline, sendableChange } = useChangesQueue();

  // this should be removed after implementing incremental changes
  useFlowDocumentData({
    businessId: businessId as string,
    flowDocumentId: documentId,
    enabled: fetchCampaignSendNode,
    onSuccess: (data) => {
      dispatch(setFetchCampaignSendNode(false));
      if (data && data.entity && data.entity.nodes && data.entity.nodes.length > 0) {
        const nodeData = data.entity.nodes.map((node) => (node.options ? JSON.parse(node.options) : {}));
        const newCampaignSendNode: Node = nodeData.filter(
          (node: Node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN',
        )[0];
        if (!newCampaignSendNode) return;

        markIdsOnline([newCampaignSendNode.id]);

        // only update campaign send node
        dispatch(updateNodeWithId({ id: newCampaignSendNode.id, newNode: newCampaignSendNode }));
      }
    },
  });

  const flowEventRequestSubmit = useCallback(() => {
    const clientRequestId = uuidv4();
    flowEventRequestSubmitMutate({
      flowDocumentId: documentId,
      businessId: businessId as string,
      clientRequestId,
    });
    logger.info(`Sending flow event request for at flow state ${flowState}`, {
      request_id: clientRequestId,
    });
  }, [flowEventRequestSubmitMutate, documentId, flowState, businessId, logger]);

  const useFlowSendChangeOnSuccessNodeChange = useCallback(
    (nodeChange: NodeChange, receivedData: ChangeFlowDocumentResponse) => {
      if (nodeChange.type === 'add' && receivedData.entity?.nodeChange?.options) {
        const parsed = JSON.parse(receivedData.entity?.nodeChange?.options);
        const newId = parsed?.item?.id;
        if (newId) {
          updateNodeId?.(nodeChange.item.id, newId);
          if (nodeChange.item.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN') {
            flowEventRequestSubmit();
          }
        }
      }
    },
    [updateNodeId, flowEventRequestSubmit],
  );
  const useFlowSendChangeOnSuccessNodeObjectUpdate = useCallback(
    (nodeObjectUpdate: NodeObjectUpdate, _receivedData: ChangeFlowDocumentResponse) => {
      if (nodeObjectUpdate.item?.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_CAMPAIGN') {
        flowEventRequestSubmit();
      }
    },
    [flowEventRequestSubmit],
  );
  const { sendChange } = useFlowSendChange({
    businessId,
    changeFlowDocumentMutate,
    flowDocumentId: documentId,
    updateEdgeId,
    onSuccessNodeChange: useFlowSendChangeOnSuccessNodeChange,
    onSuccessNodeObjectUpdate: useFlowSendChangeOnSuccessNodeObjectUpdate,
  });

  // if campaign document is saved and no change is loading send one node change to server
  useEffect(() => {
    if (documentId && savedCampaignName && !isLoadingChangeFlow && !isAIGenerating) {
      const change = sendableChange();
      if (change) {
        sendChange(change);
      }
    }
  }, [documentId, savedCampaignName, sendableChange, isLoadingChangeFlow, sendChange, isAIGenerating]);

  const saveCampaignName = useCallback(
    (name: string) => {
      if (
        businessId &&
        name !== '' &&
        (name !== savedCampaignName || isCreateFlowDocumentError) &&
        documentId !== '' &&
        !hasInvalidChars
      ) {
        createFlowDocumentMutate(
          {
            id: documentId,
            businessId: businessId || '',
            category: 'FLOW_DOCUMENT_CATEGORY_CAMPAIGN',
            name: campaignName,
            type: documentType,
          },
          {
            onSuccess: (newData: MutationCreateOrUpdateReactFlowDocumentResponse) => {
              const resName = newData?.entity?.name;
              if (resName) {
                dispatch(setSavedCampaignName(resName));
                updateCampaignNode('buildCampaign');
              }
            },
          },
        );
      }
    },
    [
      businessId,
      createFlowDocumentMutate,
      dispatch,
      documentId,
      documentType,
      campaignName,
      hasInvalidChars,
      isCreateFlowDocumentError,
      savedCampaignName,
      updateCampaignNode,
    ],
  );

  useEffect(() => {
    saveCampaignName(campaignName);
    // only when campaign name changes save the campaign name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignName]);

  const nodeTypes = useFlowNodeTypes();

  const sendFlowStateAnalytics = useCallback(() => {
    switch (flowState) {
      case 'buildCampaign': {
        track('clicks flow_continue_to_audience', { businessId });
        break;
      }
      case 'chooseTime': {
        const aiUsed = nodes.some((node) => Boolean(node?.data?.v1?.prompt));
        if (aiUsed) {
          track('clicks flow_submit_campaign_with_ai', { businessId });
        } else {
          track('clicks flow_submit_campaign_no_ai', { businessId });
        }
        if (campaignDate) {
          track('clicks flow_submit_campaign_with_schedule', { businessId });
        } else {
          track('clicks flow_submit_campaign_with_submit_now', { businessId });
        }
        if (campaignTemplate) {
          const tmpl = snakeCase(campaignTemplate) as CamelToSnakeCase<typeof campaignTemplate>;
          track(`clicks submit_campaign_template_${tmpl}`, { businessId });
        } else {
          track('clicks submit_campaign_template_custom', { businessId });
        }
        const listMesageUsed = nodes.some((node) =>
          Boolean(node?.data?.v1?.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE'),
        );
        if (listMesageUsed) {
          track('clicks flow_submit_campaign_with_list_message', { businessId });
        }
        track('clicks flow_submit_campaign_any', { businessId });
        break;
      }
      default: {
        break;
      }
    }
  }, [campaignDate, campaignTemplate, flowState, nodes]);

  useEffectOnce(() => {
    if (flowState === 'buildCampaign' && starter === 'resend') {
      updateCampaignNode('startCampaignFromPreview');
      dispatch(goToNextFlowState({ isNeedingTemplateApproval: false }));
    }
  });

  const { warnings } = useFlowChecks();
  const setIsFlowChecksOpen = useSetAtom(isFlowChecksOpenAtom);

  const goToNextState = useCallback(() => {
    if (flowState === 'buildCampaign' && warnings.length > 0) {
      setIsFlowChecksOpen(true);
      return;
    }
    if (flowState === 'chooseAudience') {
      resetVerify();
      dispatch(setIsSpreadsheetOpen(false));
    }

    const isNeedingTemplateApproval =
      ['buildCampaign', 'verifyCampaign', 'approveTemplates'].includes(flowState) &&
      (hasRejectedTemplates || hasPendingTemplates || hasUnsentTemplates);

    if (!isNeedingTemplateApproval) {
      updateCampaignNode(flowState);
    }

    sendFlowStateAnalytics();
    dispatch(goToNextFlowState({ isNeedingTemplateApproval }));
  }, [
    dispatch,
    flowState,
    hasPendingTemplates,
    hasRejectedTemplates,
    hasUnsentTemplates,
    resetVerify,
    sendFlowStateAnalytics,
    setIsFlowChecksOpen,
    updateCampaignNode,
    warnings,
  ]);

  const goToPreviousState = useCallback(() => {
    dispatch(goToPreviousFlowState());
  }, [dispatch]);

  const theme = useTheme();

  const defaultEdgeOptions = useMemo(
    () => ({
      markerEnd: { type: MarkerType.ArrowClosed, color: theme.palette.connectionLine },
      style: { stroke: theme.palette.connectionLine, strokeWidth: 2, fill: 'none' },
      type: 'FLOW_OBJECT_TYPE_SIMPLE_EDGE',
    }),
    [theme.palette.connectionLine],
  );

  const edgeTypes = useMemo(
    () => ({
      FLOW_OBJECT_TYPE_SIMPLE_EDGE: FlowChartCustomEdge,
    }),
    [],
  );

  const flowRef = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const centerToNode = useCallback(
    (node: Node) => {
      reactFlowInstance?.setCenter(node.position.x + 150, node.position.y + 150, { zoom: 1.75, duration: 600 });
      setTimeout(() => {
        reactFlowInstance?.setCenter(node.position.x + 150, node.position.y + 150, { zoom: 2, duration: 600 });
      }, 600);
    },
    [reactFlowInstance],
  );
  const { hideCampaignForAI } = useAICampaign();

  const handleVerifySpreadsheet = useCallback(() => {
    mutateVerify();
    flowEventRequestSubmit();
  }, [mutateVerify, flowEventRequestSubmit]);

  const flowCampaignContext: FlowCampaignDeps = useMemo(
    () => ({
      centerToNode,
      goToNextState,
      goToPreviousState,
      reactFlowInstance,
      handleVerifySpreadsheet,
      verifySendoutResultData,
    }),
    [
      centerToNode,
      handleVerifySpreadsheet,
      goToNextState,
      goToPreviousState,
      reactFlowInstance,
      verifySendoutResultData,
    ],
  );

  useEffect(() => {
    if (deletePressed) {
      const selectedElements = nodes.filter((node) => node.selected);
      flowHandlers.handleNodesDelete(selectedElements);
    }
  }, [deletePressed, flowHandlers, flowHandlers.handleNodesDelete, nodes]);

  useEffectOnce(() => {
    // componentWillUnmount
    return () => {
      flowEventRequestSubmitReset();
      dispatch(resetFlowToPreface());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const reactFlowProps = {
    defaultEdgeOptions,
    deleteKeyCode: null,
    edges,
    edgeTypes,
    minZoom: 0.1,
    maxZoom: 2.5,
    selectionKeyCode: null,
    multiSelectionKeyCode: null,
    nodes,
    nodeTypes,
    onConnect: flowHandlers.handleConnect,
    onDragOver: flowHandlers.handleDragOver,
    onEdgesChange: onEdgesChangeProtected,
    onEdgeUpdate: flowHandlers.handleEdgeUpdate,
    onEdgeUpdateEnd: flowHandlers.handleEdgeUpdateEnd,
    onEdgeUpdateStart: flowHandlers.handleEdgeUpdateStart,
    onInit: setReactFlowInstance,
    onNodesChange: onNodesChangeProtected,
    proOptions: { account: 'paid-custom', hideAttribution: true },
    selectNodesOnDrag: false,
    snapToGrid: true,
  };

  if (!businessId) return null;
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <FlowContext.Provider value={flowDeps}>
        <FlowCampaignContext.Provider value={flowCampaignContext}>
          <FlowChartHeader>
            <Box
              sx={{
                ':hover': { path: { fill: theme.palette.primary.main } },
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
              }}
              onClick={canOpenCampaignNameDialog ? openCampaignNameDialog : () => {}}
            >
              <Label variant="subtitle2">{campaignName || 'Create a Campaign'}</Label>
              {canOpenCampaignNameDialog && <PencilIcon />}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={goBackToPreface}
                component={Link}
                to={flowDocumentIdRouteMatch ? '/campaigns/' : `/flow/`}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </FlowChartHeader>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              background: theme.palette.flowGradient,
              overflow: 'hidden',
            }}
          >
            <FlowChartCampaignNameDialog
              campaignName={campaignNameInput}
              campaignNameColor={campaignNameColor}
              campaignNameHint={campaignNameHint}
              isConfirmDisabled={isCampaignNameDialogConfirmDisabled}
              onCancel={handleCampaignNameDialogCancel}
              onSave={handleCampaignNameDialogSave}
              open={isCampaignNameDialogOpen}
              setCampaignName={setCampaignNameInput}
            />
            <FlowChartConclusionDialog
              campaignDate={campaignDate}
              open={flowState === 'conclusion'}
              isSubmitSuccess={isSubmitSuccess}
              onStartOver={navigateToCampaignsList}
              submitProgress={submitProgress}
            />
            <FlowChartTemplateApprovalDialog
              onNextState={goToNextState}
              onPreviousState={goToPreviousState}
              selectNode={flowChangeAppliers.selectNode}
            />
            <FlowChartCampaignVerificationDialog />
            <FlowChartSidebarPlaceholder>
              <FlowChartSidebar />
              <FlowChartSidebarAICampaign />
              <FlowChartNodeSidebar />
              <FlowChartChecksSidebar />
            </FlowChartSidebarPlaceholder>
            <Box ref={flowRef} sx={{ width: '100%', height: '100%', position: 'relative' }}>
              {!hideCampaignForAI && (
                <ReactFlow
                  {...reactFlowProps}
                  onDrop={(e) => flowHandlers.handleDrop(e, reactFlowInstance, flowRef.current)}
                >
                  <Background />
                  <Controls showInteractive={false} />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      color: theme.palette.grey[500],
                      fontWeight: 700,
                      fontSize: '10px',
                    }}
                  >
                    v1
                  </Box>
                </ReactFlow>
              )}
              {hideCampaignForAI && (
                <Box sx={{ position: 'absolute', top: 'calc(50% - 50px)', left: 'calc(50% - 50px)' }}>
                  <CampaignIcon
                    style={{
                      flexShrink: 0,
                      height: '100',
                      width: '100',
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </FlowCampaignContext.Provider>
      </FlowContext.Provider>
    </Box>
  );
};
