import React, { FC, ChangeEvent, useMemo } from 'react';
import {
  Box,
  DeleteOutlineIcon,
  IconButton,
  ImageOutlinedIcon,
  Label,
  PlayCircleOutlineIcon,
  Tooltip,
  VideocamOutlinedIcon,
  alpha,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation, Trans } from 'react-i18next';
import { selectBusinessId, useMeData } from '@hooks/useMeData';
import { useMediaUploadMutation } from '@hooks';
import { UploadButton } from './UploadButton';
import { VariableButton } from './VariableButton';
import { CardHeader } from '../MessageNodeEditor/types';

export const CarouseUploadBox: FC<{
  onChange: (props?: CardHeader) => void;
  value?: CardHeader;
}> = ({ onChange, value }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'carouselUploadBox' });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading, mutate: uploadFile } = useMediaUploadMutation();

  const previewStyles = useMemo(() => {
    if (value?.media?.type === 'TYPE_IMAGE' && Array.isArray(value.media.example)) {
      return {
        backgroundImage: `url(${value.media.example[0]})`,
        backgroundSize: 'cover',
      };
    }
    if (value?.media?.type === 'TYPE_VIDEO' && Array.isArray(value.media.example)) {
      return {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: 'grey.300',
        backgroundColor: 'common.white',
      };
    }
    return {
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: 'grey.300',
      backgroundColor: 'grey.100',
    };
  }, [value]);

  const contentVisibilityStyles = useMemo(() => {
    if (value) {
      return {
        visibility: 'hidden',
        pointerEvents: 'none',
      };
    }
    return {};
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length < 1) {
      return;
    }
    const [uploadedFile] = event.target.files;
    if (!businessId) {
      return;
    }
    onChange({
      media: {
        type: uploadedFile.type === 'video/mp4' ? 'TYPE_VIDEO' : 'TYPE_IMAGE',
        example: [URL.createObjectURL(uploadedFile)],
      },
    });
    uploadFile(
      {
        businessId,
        file: uploadedFile,
      },
      {
        onSettled: (data, _e) => {
          if (data) {
            onChange({
              media: { type: uploadedFile.type === 'video/mp4' ? 'TYPE_VIDEO' : 'TYPE_IMAGE', example: [data.uri] },
            });
          }
        },
      },
    );
    event.target.value = '';
  };

  const handleDelete = () => {
    onChange(undefined);
  };

  const handleChangeVariable = (newVal: string) => {
    onChange({ variable: newVal });
  };

  return (
    <Box
      id="preview"
      sx={{
        paddingX: 2,
        paddingY: 4,
        borderRadius: 2,
        position: 'relative',
        textAlign: 'center',
        ...previewStyles,
        ...(isLoading
          ? {}
          : {
              '&:hover > div': {
                opacity: 1,
              },
            }),
      }}
    >
      {value?.media?.type === 'TYPE_VIDEO' && (
        <PlayCircleOutlineIcon
          sx={{
            width: 40,
            height: 40,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      )}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'red',
            zIndex: 9,
            borderRadius: 2,
            backgroundColor: 'grey.100',
            opacity: 0.5,
          }}
        />
      )}
      {value?.variable && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.common.black,
              color: (theme) => theme.palette.common.white,
              display: 'flex',
              paddingX: 1,
              paddingY: 0.5,
              borderRadius: 2,
            }}
          >
            {value?.variable}
          </Box>
        </Box>
      )}
      {value && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: 2,
            left: 0,
            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.5),
            backdropFilter: 'blur(10px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
          }}
        >
          <Tooltip title="Delete">
            <IconButton
              sx={{
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: (theme) => alpha(theme.palette.common.white, 0.3),
                background: (theme) => alpha(theme.palette.common.black, 0.7),
                '&:hover': {
                  background: (theme) => alpha(theme.palette.common.black, 0.9),
                },
              }}
              size="large"
              onClick={handleDelete}
            >
              <DeleteOutlineIcon sx={{ color: (theme) => theme.palette.common.white }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <ImageOutlinedIcon
        sx={{
          fontSize: 34,
          color: (theme) => theme.palette.grey[400],
          ...contentVisibilityStyles,
        }}
      />
      <VideocamOutlinedIcon
        sx={{ fontSize: 34, color: (theme) => theme.palette.grey[400], ...contentVisibilityStyles }}
      />
      <Label variant="subtitle2" sx={{ fontWeight: 500, ...contentVisibilityStyles }}>
        {t('title')}
      </Label>
      <Box sx={contentVisibilityStyles}>
        <Trans i18nKey="carouselUploadBox.options">
          <UploadButton onChange={handleChange}>Upload file</UploadButton> or{' '}
          <VariableButton value={value?.variable || ''} onChange={handleChangeVariable}>
            use a variable
          </VariableButton>
        </Trans>
      </Box>
      <Label variant="body2" sx={{ opacity: 0.65, mt: 0.5, ...contentVisibilityStyles }}>
        {t('description')}
      </Label>
    </Box>
  );
};
