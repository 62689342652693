import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  Label,
  TextField,
} from '@connectlyai-tenets/ui-styled-web';
import { documentTypeAtom } from '@atoms/flow';
import { useAtomValue } from 'jotai';
import {
  selectApprovedMessageTemplateIds,
  selectBusinessId,
  selectMessageTemplateIndex,
  useAnalytics,
  useMeData,
  useMessageTemplateGroupsData,
  useFeatureFlag,
} from '@hooks';
import { usePrepareTemplateForReuse } from '@components/FlowChartCampaignV3/hooks/usePrepareTemplateForReuse';
import {
  selectTemplateId,
  setTemplateId,
  CREATE_NEW_TEMPLATE_ID,
  setPreviewTemplateId,
  EMPTY_TEMPLATE_ID,
  selectTemplateIdError,
} from '../../state/messageTemplates';
import { TemplateBuilderTemplateSelectorProps } from './types';
import { applyHook } from '../../utils/applyHook';
import { CREATE_NEW_TEMPLATE_SELECTOR_ID } from './constants';
import { getTemplateLabel } from '../../utils';

const useTemplateBuilderTemplateSelector = (
  _props: Partial<TemplateBuilderTemplateSelectorProps>,
): Partial<TemplateBuilderTemplateSelectorProps> => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { isLoading, data: templateIds } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectApprovedMessageTemplateIds,
    keepPreviousData: true,
  });

  const { data: templateIndex = {} } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateIndex,
    keepPreviousData: true,
  });

  const dispatch = useDispatch();
  const templateId = useSelector(selectTemplateId);
  const error = useSelector(selectTemplateIdError);

  const documentType = useAtomValue(documentTypeAtom);
  const { prepareTemplateForReuse } = usePrepareTemplateForReuse();

  const { sendAnalytics } = useAnalytics();

  const handleChange = useCallback(
    (_event: React.BaseSyntheticEvent, option: string | null, _reason: AutocompleteChangeReason) => {
      dispatch(setTemplateId(option === CREATE_NEW_TEMPLATE_SELECTOR_ID ? CREATE_NEW_TEMPLATE_ID : option));
      dispatch(setPreviewTemplateId(EMPTY_TEMPLATE_ID));

      sendAnalytics(
        option === CREATE_NEW_TEMPLATE_SELECTOR_ID
          ? 'flow_builder_template_dropdown_selected_empty'
          : 'flow_builder_template_dropdown_selected_template',
      );

      if (
        ffEnableETA &&
        !!option &&
        option !== CREATE_NEW_TEMPLATE_SELECTOR_ID &&
        documentType === 'FLOW_DOCUMENT_TYPE_SENDOUT_V3'
      ) {
        prepareTemplateForReuse(templateIndex[option]?.template);
      }
    },
    [dispatch, documentType, prepareTemplateForReuse, templateIndex, sendAnalytics],
  );

  const handleClose = (_event: React.BaseSyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason !== 'selectOption') {
      dispatch(setPreviewTemplateId(EMPTY_TEMPLATE_ID));
    }
  };

  const handleHightlightChange = useCallback(
    (_event: React.BaseSyntheticEvent, option: string | null) => {
      dispatch(setPreviewTemplateId(option === CREATE_NEW_TEMPLATE_SELECTOR_ID ? CREATE_NEW_TEMPLATE_ID : option));
    },
    [dispatch],
  );

  return {
    isLoading,
    templateId: templateId === CREATE_NEW_TEMPLATE_ID ? CREATE_NEW_TEMPLATE_SELECTOR_ID : templateId,
    templateIds,
    templateIndex,
    error,
    onChange: handleChange,
    onClose: handleClose,
    onHighlightChange: handleHightlightChange,
  };
};

const TemplateBuilderTemplateSelectorPresentation: FC<TemplateBuilderTemplateSelectorProps> = ({
  isLoading,
  templateId,
  templateIds,
  templateIndex,
  error,
  onChange,
  onClose,
  onHighlightChange,
}) => {
  return (
    <Autocomplete
      id="message-template-selector"
      fullWidth
      loading={isLoading}
      value={templateId}
      getOptionLabel={(option) =>
        option === CREATE_NEW_TEMPLATE_SELECTOR_ID ? 'Create New Template' : getTemplateLabel(option, templateIndex)
      }
      renderOption={(props, option, _state) => (
        <React.Fragment key={option}>
          <Label
            {...props}
            sx={{
              fontWeight: option === CREATE_NEW_TEMPLATE_SELECTOR_ID ? 'fontWeightBold' : 'fontWeightRegular',
            }}
          >
            {option === CREATE_NEW_TEMPLATE_SELECTOR_ID
              ? 'Create New Template'
              : getTemplateLabel(option, templateIndex)}
          </Label>
        </React.Fragment>
      )}
      groupBy={(option) => (option === CREATE_NEW_TEMPLATE_SELECTOR_ID ? '' : 'Saved Templates')}
      options={[CREATE_NEW_TEMPLATE_SELECTOR_ID, ...(templateIds || [])]}
      onChange={onChange}
      onClose={onClose}
      onHighlightChange={onHighlightChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(error)}
          helperText={error}
          placeholder="Select Template"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      sx={{ mt: 1.5, '& .MuiInputBase-root': { p: '3.5px 6px', borderRadius: '10px' } }}
    />
  );
};

export const TemplateBuilderTemplateSelector = applyHook(
  TemplateBuilderTemplateSelectorPresentation,
  useTemplateBuilderTemplateSelector,
);
