import React from 'react';
import { Box, CircularProgress, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { IconWithLoadingProps } from './types';

export const IconWithLoading = ({ loading, size, children }: IconWithLoadingProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: theme.spacing(size),
        height: theme.spacing(size),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading && <CircularProgress size={theme.spacing(size * 0.75)} sx={{ color: theme.palette.grey[600] }} />}
      {!loading && children}
    </Box>
  );
};
