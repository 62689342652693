import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { ErrorOutline as ErrorOutlineIcon, CheckCircleOutlined as CheckCircleOutlinedIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, InputLabel, Stack } from '@mui/material';
import { Label } from '../Label';
import { purple, blueGrey } from '../../theme/colors';
import { spacing } from '../../theme';
export const DropZone = ({ autoFocus, acceptedFileTypes, acceptedFileTypesText, uploadLimitText, errorHelperText, handleUploadFile, isSuccess = false, hasError = false, sx, initialText, initialButtonText, successText, successButtonText, failedText, failedButtonText, loadingText, dragingText, }) => {
    const [isUploading, setUploading] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const fileUploadRef = useRef(null);
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };
    const onDrop = async (e) => {
        e.preventDefault();
        setDragOver(false);
        setUploading(true);
        const file = e.dataTransfer.files[0];
        await handleUploadFile(file);
        setUploading(false);
    };
    const handleFileSelect = async (e) => {
        var _a;
        if (e.target.files && ((_a = e.target) === null || _a === void 0 ? void 0 : _a.files.length) > 0) {
            setUploading(true);
            await handleUploadFile(e.target.files[0]);
            setUploading(false);
        }
    };
    const handleClick = () => {
        var _a;
        if (fileUploadRef === null || fileUploadRef === void 0 ? void 0 : fileUploadRef.current) {
            (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    const theme = useTheme();
    const Content = () => {
        if (isUploading) {
            return (_jsxs(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }, children: [_jsx(CircularProgress, { size: 20 }), _jsx(Label, { variant: "h6", sx: { marginBottom: 1 }, children: loadingText })] }));
        }
        if (dragOver) {
            return (_jsx(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }, children: _jsx(Label, { variant: "h6", sx: { marginBottom: 1 }, children: dragingText }) }));
        }
        if (!isUploading && !errorHelperText && isSuccess) {
            return (_jsxs(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }, children: [_jsx(CheckCircleOutlinedIcon, { color: "success" }), _jsx(Label, { variant: "h6", sx: { marginBottom: 1 }, children: successText }), _jsx(Button, { variant: "outlined", color: "secondary", onClick: handleClick, sx: { width: 'max-content', textTransform: 'none' }, children: successButtonText }), _jsxs(Label, { children: [acceptedFileTypesText, " ", _jsx("br", {}), " ", uploadLimitText] })] }));
        }
        if (errorHelperText || hasError) {
            return (_jsxs(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }, children: [_jsx(ErrorOutlineIcon, { color: "error" }), _jsx(Label, { variant: "h6", sx: { marginBottom: 1 }, children: failedText }), _jsx(Button, { variant: "outlined", color: "secondary", onClick: handleClick, sx: { width: 'max-content', textTransform: 'none' }, children: failedButtonText }), _jsxs(Label, { children: [acceptedFileTypesText, " ", _jsx("br", {}), " ", uploadLimitText] })] }));
        }
        return (_jsxs(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Label, { variant: "h6", sx: { marginBottom: 1 }, children: initialText }), _jsx(Button, { variant: "outlined", color: "secondary", onClick: handleClick, sx: { width: 'max-content', textTransform: 'none' }, children: initialButtonText }), _jsxs(Label, { children: [acceptedFileTypesText, " ", _jsx("br", {}), " ", uploadLimitText] })] }));
    };
    return (_jsxs(Stack, { children: [_jsx(Box, { autoFocus: autoFocus, sx: [
                    {
                        border: `2px dashed ${dragOver ? purple[700] : theme.palette.divider}`,
                        borderRadius: spacing(1.2),
                        p: 2,
                        textAlign: 'center',
                        backgroundColor: dragOver ? purple[100] : blueGrey[50],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'none',
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ], onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: onDrop, children: _jsxs(Box, { children: [_jsx("input", { type: "file", ref: fileUploadRef, style: { display: 'none' }, onChange: handleFileSelect, accept: acceptedFileTypes ? acceptedFileTypes.join(',') : undefined }), _jsx(Content, {})] }) }), errorHelperText && (_jsx(InputLabel, { error: true, sx: { ml: spacing(1), mt: spacing(0.5), fontSize: '12px' }, children: errorHelperText }))] }));
};
