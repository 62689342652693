import React, { VFC, useState, useCallback } from 'react';
import { Box, Card, Paper } from '@connectlyai-tenets/ui-styled-web';
import { useFeatureFlag } from '@hooks';
import { ShopifyAbandonedCheckoutOnboardingProps, ShopifyAbandonedCheckoutOnboardingStep } from './types';
import { SMBOnboarding } from '../SMBOnboarding';

export const ShopifyAbandonedCheckoutOnboarding: VFC<ShopifyAbandonedCheckoutOnboardingProps> = ({
  shopify,
  whatsApp,
}: ShopifyAbandonedCheckoutOnboardingProps) => {
  const { isShopifyActive, storeName } = shopify;
  const { claimedNumber, isWhatsAppActive, verificationCode } = whatsApp;

  const [step, setStep] = useState(ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep);

  const handleNextStep = useCallback(() => {
    switch (step) {
      case ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep: {
        setStep(ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep);
        break;
      }
      case ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep: {
        break;
      }
      default: {
        break;
      }
    }
  }, [step]);

  let content;
  let media;
  switch (step) {
    case ShopifyAbandonedCheckoutOnboardingStep.ConnectShopifyStep:
    case ShopifyAbandonedCheckoutOnboardingStep.ConnectWhatsAppStep: {
      content = (
        <Box>
          <SMBOnboarding
            shopify={{
              isShopifyActive,
              storeName,
            }}
            whatsApp={{
              claimedNumber,
              isWhatsAppActive,
              verificationCode,
            }}
            onNextStep={handleNextStep}
          />
        </Box>
      );
      return (
        <Paper elevation={5} sx={{ m: 1, alignSelf: 'center', borderRadius: '10px', overflow: 'hidden' }}>
          {content}
        </Paper>
      );
    }
    default: {
      break;
    }
  }

  return (
    <Card variant="main">
      <Box sx={{ display: 'flex', gap: 6, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>{content}</Box>
        <Box>{media}</Box>
      </Box>
    </Card>
  );
};
