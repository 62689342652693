/* eslint-disable react/jsx-key */
import React, { ReactNode } from 'react';
import { NodeType } from '../../../../sdks/flow/createNode';

import { TemplateBuilder } from '../TemplateBuilder';
import { CustomerRepliesNodeEditor } from '../CustomerRepliesNodeEditor';
import { APICallNodeEditor } from '../APICallNodeEditor';
import { TimeDelayNodeEditor } from '../TimeDelayNodeEditor';
import { CUSTOMER_REPLIES_NODE_TYPE } from '../CustomerRepliesNode/constants';
import { APICallNodeType } from '../APICallNode/constants';

export const nodesMap = new Map<NodeType, ReactNode>([
  ['FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE', <TemplateBuilder />],
  [CUSTOMER_REPLIES_NODE_TYPE, <CustomerRepliesNodeEditor />],
  [APICallNodeType, <APICallNodeEditor />],
  ['FLOW_OBJECT_TYPE_TIME_DELAY', <TimeDelayNodeEditor />],
]);
