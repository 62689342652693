import React, { useCallback, useMemo } from 'react';
import { useMeData, selectBusinessId, useAnalytics, useFeatureFlag } from '@hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@connectlyai-tenets/ui-styled-web';
import { CsvRow } from './types';
import { OptOutDataItem } from '../../hooks/useOptOutData';
import { createCsvData, downloadCsv } from './utils';

const useDownload = ({ data }: { data?: OptOutDataItem[] }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'audience.optOut' });
  const mapDataItemToCsvRow = useCallback(
    (item: OptOutDataItem): CsvRow => {
      return [item.externalId, t(`optOutType.${item.sourceType}` || ''), item.createdAt];
    },
    [t],
  );
  const csvData = useMemo(() => {
    return createCsvData({ data: data || [], mapToDisplay: mapDataItemToCsvRow });
  }, [data, mapDataItemToCsvRow]);
  const { sendAnalytics } = useAnalytics();
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const handleDownload = useCallback(() => {
    sendAnalytics('(audience) download opt-out list', { businessId });
    downloadCsv(csvData, 'opt-out-data.csv');
  }, [businessId, csvData, sendAnalytics]);
  return {
    text: 'Download List',
    handleDownload,
  };
};

export const DownloadOptOut = ({ data }: { data?: OptOutDataItem[] }) => {
  const { text, handleDownload } = useDownload({ data });
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      sx={{ width: 'fit-content' }}
      disabled={!data?.length}
    >
      {text}
    </Button>
  );
};
