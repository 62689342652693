import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { validate as uuidValidate } from 'uuid';
import { ChatRoomRouteId } from '@connectlyai-sdks/inbox-navigation';
import { CUSTOM_FILTER_MY_TASKS, CUSTOM_FILTER_PREFIX } from '@connectlyai-sdks/data-context';
import { mapChannelTypeToColor, mapChannelTypeToIconKind, ChannelType } from '@connectlyai-sdks/data-rooms';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { Divider, StackCard } from '@connectlyai-tenets/ui-styled-web';
import { LoadingState } from '@connectlyai-sdks/loading';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { RoomStatus } from '@connectlyai-idl/models/dist/models/common/models';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { SIDEBAR_DID_INIT } from '../../analytics';
import { SidebarDIContext } from '../../context';
import { FilterByChannelOptions, FilterByStatusOptions, RoomStatusOptions, FilterByChannelOptionsAll, FilterByRoomStatusOptionsAll, FilterByRoomStatusOptions, } from '../../entities';
import { setAgents } from '../../state/action';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectAgents, selectBusiness, setBusiness, setBusinessChannels } from '../../state/businessSlice';
import { setContextFilters, selectSelectedFilterByChannel, selectSelectedFilterByMarketingStatus, selectSelectedFilterByStatus, selectSelectedFilterByRoomStatus, selectSelectedFilterByTag, selectPartialFilterQuery, selectContextFilters, } from '../../state/filterSlice';
import { setSelectedRoomId, selectChatRooms, selectLoadingState, selectSelectedRoomId, setRooms, startRoomMonitor, } from '../../state/roomsSlice';
import RoomCard from '../roomcard/RoomCard';
import { MessageStatus, RoomViewState, } from './presenter';
import ActionBar from '../actionbar/Actionbar';
import { mutationSyncMessagesFromSource, tryRefreshRooms, tryRequestNextRoomsPage } from '../../state/thunk';
import { selectSearchIsActive } from '../../state/searchSlice';
import Search from '../search/Search';
import SearchResult from '../search/SearchResult';
export const SidebarComponentId = '2ae5b8ab-61d2-419f-a9bd-8ed910d93dc9';
export const EMPTY_ROOM_DEFAULT_MESSAGE = {
    id: '',
    status: MessageStatus.SENT,
    message: '-- empty room --',
    createdAt: undefined,
};
const previewMaxLength = 40;
const roomPreview = (str, maxLen = previewMaxLength) => {
    if (str.length > maxLen) {
        return `${str.substr(0, maxLen)}...`;
    }
    return str;
};
function mapAgentDtoToAgent(dto) {
    return {
        id: dto.id,
        firstName: dto.firstName,
        lastName: dto.lastName,
        profilePicUrl: '',
    };
}
function mapToChannelBadgeIcon(room) {
    if (room.lastChannel === undefined) {
        return undefined;
    }
    return mapChannelTypeToIconKind(room.lastChannel.type);
}
function mapAgentToViewModel(entity) {
    if (entity === undefined || entity === null) {
        return entity;
    }
    return {
        id: entity.id,
        displayName: `${entity.firstName} ${entity.lastName}`,
        avatarUrl: entity.profilePicUrl,
    };
}
function mapAssignedAgentToViewModel(entity, businessAgents) {
    if (entity === undefined || entity === null) {
        return entity;
    }
    return (businessAgents.find((val) => val.id === entity.id) || {
        id: entity.id,
        displayName: `${entity.firstName} ${entity.lastName}`,
        avatarUrl: entity.profilePicUrl,
    });
}
function mapRoomStatusToViewModel(entity) {
    if (entity === undefined || entity === null) {
        return entity;
    }
    switch (entity) {
        case RoomStatusOptions.OPEN.key:
            return {
                status: RoomStatus.ROOM_STATUS_OPEN,
                color: RoomStatusOptions.OPEN.color,
                displayLabel: RoomStatusOptions.OPEN.label,
            };
        case RoomStatusOptions.IN_PROGRESS.key:
            return {
                status: RoomStatus.ROOM_STATUS_IN_PROGRESS,
                color: RoomStatusOptions.IN_PROGRESS.color,
                displayLabel: RoomStatusOptions.IN_PROGRESS.label,
            };
        case RoomStatusOptions.CLOSED.key:
            return {
                status: RoomStatus.ROOM_STATUS_CLOSED,
                color: RoomStatusOptions.CLOSED.color,
                displayLabel: RoomStatusOptions.CLOSED.label,
            };
        case RoomStatusOptions.CUSTOM.key:
            return {
                status: RoomStatus.ROOM_STATUS_CUSTOM,
                color: RoomStatusOptions.CUSTOM.color,
                displayLabel: RoomStatusOptions.CUSTOM.label,
            };
        default:
            return assertUnreachable(entity);
    }
}
const toChatRoomView = (businessAgents, selectedRoomId, chatRoom) => {
    const lastMessage = chatRoom.messages[chatRoom.messages.length - 1];
    const businessChannel = chatRoom.businessChannels[0];
    const customerChannel = chatRoom.lastChannel || chatRoom.customerChannels[0];
    const profile = chatRoom.customer;
    const fullName = `${profile === null || profile === void 0 ? void 0 : profile.firstName} ${profile === null || profile === void 0 ? void 0 : profile.lastName}`;
    let title = fullName;
    if (title.trim() === '') {
        title = (customerChannel === null || customerChannel === void 0 ? void 0 : customerChannel.externalId) || '';
    }
    let state = selectedRoomId === chatRoom.id ? RoomViewState.SELECTED : RoomViewState.NORMAL;
    if (state === RoomViewState.NORMAL && chatRoom.status === FilterByStatusOptions.UNREAD.key) {
        state = RoomViewState.HIGHLIGHTED;
    }
    function computeAvatarUrl() {
        if (profile === null || profile === void 0 ? void 0 : profile.profilePicUrl) {
            return profile === null || profile === void 0 ? void 0 : profile.profilePicUrl;
        }
        if (customerChannel.type === ChannelType.CHANNEL_TYPE_SMS) {
            return 'https://cdn.connectly.ai/static/sms_avatar_default';
        }
        return undefined;
    }
    const mappedMessage = lastMessage !== undefined
        ? {
            id: lastMessage.id,
            status: lastMessage.sender.id === businessChannel.id ? MessageStatus.SENT : MessageStatus.RECEIVED,
            message: lastMessage.message,
            createdAt: lastMessage.createdAt === undefined ? undefined : Date.parse(lastMessage.createdAt),
        }
        : EMPTY_ROOM_DEFAULT_MESSAGE;
    return {
        id: chatRoom.id,
        businessChannel,
        customerChannel,
        lastMessage: mappedMessage,
        lastEventTime: chatRoom.lastEventTime || '',
        title,
        avatarUrl: computeAvatarUrl(),
        avatarBadgeIconKind: mapToChannelBadgeIcon(chatRoom),
        preview: roomPreview(mappedMessage.message),
        state,
        assignedAgent: mapAssignedAgentToViewModel(chatRoom.assignedAgent, businessAgents),
        primaryColor: mapChannelTypeToColor(customerChannel.type),
        roomStatus: mapRoomStatusToViewModel(chatRoom.roomStatus),
        unreadCount: chatRoom.unreadCount,
    };
};
function filterByChannel(room, selectedFilter) {
    var _a;
    const allSet = new Set(FilterByChannelOptionsAll);
    const filter = new Set(selectedFilter);
    if (isEqual(allSet, filter)) {
        return ((_a = room.customerChannels[0]) === null || _a === void 0 ? void 0 : _a.type) !== ChannelType.CHANNEL_TYPE_CONNECTLY;
    }
    for (let i = 0; i < selectedFilter.length; i += 1) {
        const channel = selectedFilter[i];
        for (let j = 0; j < room.customerChannels.length; j += 1) {
            if (room.customerChannels[j].type === FilterByChannelOptions[channel].backendType) {
                return true;
            }
        }
    }
    return false;
}
function filterByRoomStatus(room, selectedFilter) {
    for (let i = 0; i < selectedFilter.length; i++) {
        const status = selectedFilter[i];
        if (!room.roomStatus && status === FilterByRoomStatusOptions.CLOSED.key) {
            return true;
        }
        if (room.roomStatus === FilterByRoomStatusOptions[status].key) {
            return true;
        }
    }
    return false;
}
function filterByStatus(room, selectedFilter) {
    if (selectedFilter === 'ALL') {
        return true;
    }
    return room.status === selectedFilter;
}
function filterByMarketingStatus(room, selectedFilter) {
    return !selectedFilter || room.marketingStatus === selectedFilter;
}
function filterByTag(userId, room, selectedFilter) {
    var _a;
    if (!selectedFilter || selectedFilter.length === 0) {
        return true;
    }
    for (let j = 0; j < selectedFilter.length; j += 1) {
        const selectedTag = selectedFilter[j].toLowerCase();
        if (!selectedTag.startsWith(CUSTOM_FILTER_PREFIX)) {
            if (uuidValidate(selectedTag)) {
                if (room.tagAssociations.some((val) => val.tagId === selectedTag)) {
                    return true;
                }
            }
            else if (room.tags.some((val) => val.includes(selectedTag))) {
                return true;
            }
        }
        else if (selectedTag === CUSTOM_FILTER_MY_TASKS && ((_a = room.assignedAgent) === null || _a === void 0 ? void 0 : _a.id) !== userId) {
            return false;
        }
        else {
        }
    }
    return false;
}
function sortByRecency(a, b) {
    const aEpoch = a.lastEventTime !== undefined ? Date.parse(a.lastEventTime) : 0;
    const bEpoch = b.lastEventTime !== undefined ? Date.parse(b.lastEventTime) : 0;
    return bEpoch - aEpoch;
}
const ffEnableVirtualizedRoomsList = getFlagByName('ffEnableVirtualizedRoomsList');
const Sidebar = ({ log }) => {
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        log().error('SidebarDIContext is undefined', () => ({
            component: SidebarComponentId,
        }));
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        log().debug(SIDEBAR_DID_INIT, () => ({}));
    }, [log]);
    const match = useRouteMatch(di.navigator().generateGlobalRouteId(ChatRoomRouteId));
    useEffect(() => {
        if (match) {
            dispatch(setSelectedRoomId(match.params.roomId));
        }
        else {
            dispatch(setSelectedRoomId(''));
        }
    }, [dispatch, match, match === null || match === void 0 ? void 0 : match.url]);
    useEffect(() => {
        if (di.config.businessId === '') {
            return undefined;
        }
        const sub = di
            .subscriptionBusinessProvider()(di.config.businessId)
            .subscribe((payload) => {
            dispatch(setBusiness(payload));
            dispatch(setBusinessChannels(payload.channels));
            dispatch(setAgents(payload.agents.map(mapAgentDtoToAgent)));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di, di.config.businessId, di.subscriptionBusinessProvider, dispatch]);
    useEffect(() => {
        const sub = di
            .contextDataStoreProvider()
            .subscriptionContext()
            .subscribe((context) => {
            dispatch(setContextFilters(context.filters));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [dispatch, di.contextDataStoreProvider, di]);
    useEffect(() => {
        dispatch(startRoomMonitor());
        const sub = di
            .subscriptionRooms()()
            .subscribe((payload) => {
            dispatch(setRooms(payload));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di, di.subscriptionRooms, dispatch]);
    const businessAgents = useFeatureSelector(selectAgents).map(mapAgentToViewModel);
    const chatRooms = useFeatureSelector(selectChatRooms);
    const selectedFilterByChannel = useFeatureSelector(selectSelectedFilterByChannel);
    const selectedFilterByStatus = useFeatureSelector(selectSelectedFilterByStatus);
    const selectedFilterByMarketingStatus = useFeatureSelector(selectSelectedFilterByMarketingStatus);
    const selectedFilterByRoomStatus = useFeatureSelector(selectSelectedFilterByRoomStatus);
    const selectedFilterByTag = useFeatureSelector(selectSelectedFilterByTag);
    const partialFilterQuery = useFeatureSelector(selectPartialFilterQuery);
    const contextFilters = useFeatureSelector(selectContextFilters);
    useEffect(() => {
        const filters = [partialFilterQuery];
        contextFilters.forEach((filter) => {
            if (filter.type === 'tag') {
                filters.push(`filter.tags=${filter.id}`);
            }
            else if (filter.type === 'ownership' && filter.id === CUSTOM_FILTER_MY_TASKS) {
                filters.push(`filter.assignee_ids=[${di.config.userId}]`);
            }
        });
        const finalFilter = `${filters.join('&')}`;
        dispatch(tryRefreshRooms({
            di,
            filter: finalFilter,
        }));
    }, [dispatch, partialFilterQuery, contextFilters, di]);
    const selectedRoomId = useFeatureSelector(selectSelectedRoomId);
    const toChatRoomViewInstance = toChatRoomView.bind(this, businessAgents, selectedRoomId);
    const visibleRooms = React.useMemo(() => chatRooms
        .filter((room) => filterByChannel(room, selectedFilterByChannel) &&
        filterByStatus(room, selectedFilterByStatus) &&
        filterByMarketingStatus(room, selectedFilterByMarketingStatus) &&
        filterByRoomStatus(room, selectedFilterByRoomStatus) &&
        filterByTag(di.config.userId, room, selectedFilterByTag))
        .sort(sortByRecency)
        .map(toChatRoomViewInstance), [
        chatRooms,
        toChatRoomViewInstance,
        selectedFilterByChannel,
        selectedFilterByStatus,
        selectedFilterByMarketingStatus,
        selectedFilterByRoomStatus,
        di.config.userId,
        selectedFilterByTag,
    ]);
    const business = useFeatureSelector(selectBusiness);
    const loadingState = useFeatureSelector(selectLoadingState);
    const searchIsActive = useFeatureSelector(selectSearchIsActive);
    const syncInvokedOnce = useRef(false);
    useEffect(() => {
        if (!syncInvokedOnce.current &&
            business !== undefined &&
            loadingState === LoadingState.DATA &&
            visibleRooms.length === 0 &&
            isEqual(new Set(selectedFilterByChannel), new Set(FilterByChannelOptionsAll)) &&
            selectedFilterByStatus === FilterByStatusOptions.ALL.key &&
            !selectedFilterByMarketingStatus &&
            isEqual(new Set(selectedFilterByRoomStatus), new Set(FilterByRoomStatusOptionsAll))) {
            syncInvokedOnce.current = true;
            dispatch(mutationSyncMessagesFromSource({
                di,
                business,
            }));
        }
    }, [
        business,
        di,
        dispatch,
        loadingState,
        selectedFilterByChannel,
        selectedFilterByMarketingStatus,
        selectedFilterByRoomStatus,
        selectedFilterByStatus,
        visibleRooms.length,
    ]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(SidebarComponentId);
    const items = useMemo(() => {
        return visibleRooms.map((roomViewModel) => ({
            id: roomViewModel.id,
            node: _jsx(RoomCard, { room: roomViewModel }, roomViewModel.id),
        }));
    }, [visibleRooms]);
    const virtualizedRoomsRow = ({ index, style }) => {
        return visibleRooms[index] ? (_jsxs("div", { style: { ...style }, children: [index > 0 && _jsx(Divider, {}), _jsx(RoomCard, { room: visibleRooms[index] }, visibleRooms[index].id)] })) : null;
    };
    const roomsCount = visibleRooms ? visibleRooms.length : 0;
    const handleItemsRendered = useCallback((renderedProps) => {
        const { visibleStopIndex } = renderedProps;
        if (visibleStopIndex >= roomsCount - 1) {
            dispatch(tryRequestNextRoomsPage({ di }));
        }
    }, [di, dispatch, roomsCount]);
    return UIComponent ? (_jsxs(UIComponent, { Actionbar: ActionBar, Search: Search, SearchResult: SearchResult, rooms: visibleRooms, loadingState: loadingState, searchIsActive: searchIsActive, children: [ffEnableVirtualizedRoomsList && (_jsx(AutoSizer, { children: ({ height, width }) => (_jsx(FixedSizeList, { className: "List", height: height, width: width, itemCount: roomsCount, itemSize: 75, onItemsRendered: handleItemsRendered, children: virtualizedRoomsRow })) })), !ffEnableVirtualizedRoomsList && (_jsx(StackCard, { items: items, sx: { border: 'none', borderRadius: 'none' }, itemSx: { p: 0 }, containerless: true }))] })) : null;
};
export default Sidebar;
