import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { WithSkeleton } from '../WithSkeleton';
import { Label } from '../Label';
export const ConnectlySection = ({ isLoading, children, sx = [], headerSx = [], contentSx = [], title, subtitle, icon, }) => {
    const Content = (_jsxs(_Fragment, { children: [_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsxs(Box, { sx: [
                        {
                            pb: 2,
                        },
                        ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
                    ], children: [title ? (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [icon, _jsx(Label, { variant: "h6", sx: { fontSize: '18px' }, children: title })] })) : null, subtitle ? _jsx(Label, { variant: "body1", children: typeof subtitle === 'string' ? subtitle : subtitle }) : null] }) }), children && (_jsx(WithSkeleton, { wrap: !!isLoading, children: _jsx(Box, { sx: [
                        {
                            gap: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        ...(Array.isArray(contentSx) ? contentSx : [contentSx]),
                    ], children: children }) }))] }));
    return _jsx(Box, { sx: [...(Array.isArray(sx) ? sx : [sx])], children: Content });
};
