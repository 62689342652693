import React, { useCallback, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { PhoneNumberOldProps } from './types';
import { applyHook } from '../../../../utils';
import { selectPhoneNumber, setPhoneNumber } from '../../../../features/welcome';
import { PHONE_NUMBER_MAX_LENGTH } from './constants';

const usePhoneNumberOld = (_props: Partial<PhoneNumberOldProps>): PhoneNumberOldProps => {
  const dispatch = useDispatch();
  const phoneNumber = useSelector(selectPhoneNumber);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(setPhoneNumber(value));
    },
    [dispatch],
  );

  return {
    phoneNumber,
    onChange: handleChange,
  };
};

export const PhoneNumberOldPresentation: VFC<PhoneNumberOldProps> = ({ phoneNumber, onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.phoneNumberOld' });
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <Box>
      <Label>{t('title')}</Label>
      <TextField
        autoFocus
        autoComplete="off"
        placeholder={t('enterPhoneNumber') as string}
        fullWidth
        value={phoneNumber}
        onChange={handleChange}
        variant="outlined"
        inputProps={{ maxLength: PHONE_NUMBER_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${phoneNumber.length}/${PHONE_NUMBER_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Box>
  );
};

export const PhoneNumberOld = applyHook(PhoneNumberOldPresentation, usePhoneNumberOld);
