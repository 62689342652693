import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Chip } from '@mui/material';
import { blueGrey } from '../../theme';
export const Pills = ({ sx, children }) => {
    return (_jsx(Box, { sx: [
            {
                display: 'flex',
                gap: 2,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: children }));
};
export const PillsItem = ({ item, onClick, isActive }) => {
    return (_jsx(Chip, { sx: {
            cursor: 'pointer',
            background: isActive ? blueGrey[100] : 'transparent',
            '&:hover': {
                background: blueGrey[200],
            },
        }, label: item, onClick: onClick }, item));
};
