// unreduxified, has only messageTemplates redux

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  EdgeAddChange,
  NodeChange,
  Node,
  NodeAddChange,
  NodeRemoveChange,
  NodeSelectionChange,
  EdgeChange,
} from 'react-flow-renderer';
import { useAtom, useAtomValue } from 'jotai';
import produce from 'immer';
import { flatMap } from 'lodash';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { useGetAtom } from '@hooks/useGetAtom';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import {
  edgesAtom,
  isAIGeneratingAtom,
  isAISidebarOpenAtom,
  isDocumentEditableAtom,
  isFlowChecksOpenAtom,
  mappingsAtom,
  nodesAtom,
  selectedNodeAtom,
  selectedNodeUIStateAtom,
} from '@atoms/flow';
import { modifyTemplateComponents } from '@components/FlowChartCampaignV3/utils';
import { FlowChangeAppliers } from './types';
import {
  save,
  selectTemplateId,
  CREATE_NEW_TEMPLATE_ID,
  selectName,
  selectStatus,
  normalize,
  setName,
  checkCurrentTemplateErrors,
} from '../../../../state/messageTemplates';
import { cancel, editNode } from '../../../../state/actions';
import appStore from '../../../../state/store';
import { selectTemplateComponents, selectTemplateMappings } from '../../../../state/messageTemplates/selectors';
import { useFlowChangeAppliers, useNodeObjectChange } from '../useFlowChangeAppliers';
import { createHandleId, HandleType } from '../../../../sdks/flow/createHandleId';
import { useCreateNode } from '../useCreateNode';
import { createEdge } from '../../../../sdks/flow/createEdge';
import { mapToVariableParamMapping, useFlowVariables } from '../../../../hooks/useFlowVariables';
import { NON_EDITABLE_NODE_TYPES } from './constants';
import { useNodePersistence } from '../useNodePersistence';
import type { CustomerRepliesNodeUIState } from '../../components/CustomerRepliesNodeEditor';
import type { APICallNodeUIState } from '../../components/APICallNodeEditor';
import type { TimeDelayNodeUIState } from '../../components/TimeDelayNodeEditor';
import mapCarousel from './mapCarousel';

const ffEnableSpacesInHeaderVariable = getFlagByName('ffEnableSpacesInHeaderVariable');
export const useCampaignFlowChangeAppliers = (): FlowChangeAppliers => {
  const dispatch = useDispatch();

  const [isAISidebarOpen, setIsAISidebarOpen] = useAtom(isAISidebarOpenAtom);
  const [isFlowChecksOpen, setIsFlowChecksOpen] = useAtom(isFlowChecksOpenAtom);
  const isAIGenerating = useAtomValue(isAIGeneratingAtom);
  const isDocumentEditable = useAtomValue(isDocumentEditableAtom);
  const edges = useAtomValue(edgesAtom);
  const nodes = useAtomValue(nodesAtom);
  const getNodes = useGetAtom(nodesAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const selectedNodeUI = useAtomValue(selectedNodeUIStateAtom);
  const [mappingsValue] = useAtom(mappingsAtom);

  const { onNodeObjectChange } = useNodeObjectChange();
  const { templateName } = useCreateNode();

  // display or close template editor sidebar according to changes
  const showEditorFor = useCallback(
    (changes: NodeChange[], currentNodes: Node[]) => {
      // if a new node is added edit the node
      const addedNode = changes.find(
        (change) => change.type === 'add' && (change.item.selected === undefined || change.item.selected),
      ) as NodeAddChange;
      if (addedNode) {
        dispatch(editNode({ node: addedNode.item }));

        // if flow checks open close flow checks
        if (isFlowChecksOpen) {
          setIsFlowChecksOpen(false);
        }

        return;
      }

      // open template edit sidebar if node is selected and close sidebar if current node id is deselected
      const nodeSelectionChanges = changes.filter((change) => change.type === 'select') as NodeSelectionChange[];
      const selectChange = nodeSelectionChanges.find((change) => change.selected);
      if (selectChange) {
        const nodeToSelect = currentNodes.find((node) => node.id === selectChange.id) as Node;
        if (!nodeToSelect) return;
        dispatch(editNode({ node: nodeToSelect, showErrors: true }));

        // if editor is showing for a rejected template rename it
        const status = selectStatus(appStore.getState());
        if (status === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
          const newName = templateName();
          dispatch(setName(newName));
        }

        // after AI stopped generating move to node by selecting the node
        if (isAISidebarOpen && !isAIGenerating) {
          setIsAISidebarOpen(false);
        }

        // if flow checks open close flow checks
        if (isFlowChecksOpen) {
          setIsFlowChecksOpen(false);
        }

        return;
      }

      // rest of the code should only run if a node is already selected
      if (!selectedNode) return;

      const hasDeselect = nodeSelectionChanges.some((change) => !change.selected && change.id === selectedNode?.id);
      if (hasDeselect) {
        dispatch(cancel());
        return;
      }

      // close template edit sidebar if currently selected node is deleted
      const nodeDeleteChanges = changes.filter((change) => change.type === 'remove') as NodeRemoveChange[];
      const isDeletingSelectedNode = nodeDeleteChanges.find((change) => change.id === selectedNode?.id);
      if (isDeletingSelectedNode) {
        dispatch(cancel());
      }
    },
    [
      dispatch,
      isAIGenerating,
      isAISidebarOpen,
      isFlowChecksOpen,
      selectedNode,
      setIsAISidebarOpen,
      setIsFlowChecksOpen,
      templateName,
    ],
  );

  const { enterVariables, substituteVariables, cleanupVariables } = useFlowVariables();

  const { ffEnableCleanupVariables } = useFeatureFlag(['ffEnableCleanupVariables']);
  const prepareVariables = useCallback(() => {
    const sendoutNode = getNodes().find((node) => node.type === 'FLOW_OBJECT_TYPE_CUSTOM_SEND_SENDOUT');

    if (!sendoutNode) return;

    if (!enterVariables || (enterVariables.length === 0 && !sendoutNode.data.v3.enterVariables)) return;

    const modifiedSendoutNode = produce(sendoutNode, (draft) => {
      draft.data.v3.enterVariables = enterVariables;
    });
    if (ffEnableCleanupVariables) {
      cleanupVariables();
    }

    onNodeObjectChange([
      {
        id: modifiedSendoutNode.id,
        item: modifiedSendoutNode,
      },
    ]);
  }, [getNodes, enterVariables, ffEnableCleanupVariables, onNodeObjectChange, cleanupVariables]);

  const { saveApiCallNode, saveIncomingRoomEventNode, saveTimeDelayNode } = useNodePersistence();

  // call this function to save if selected node is going to be changed
  // it returns true if template has no errors, false if it has errors
  const saveTemplate = useCallback(() => {
    if (!selectedNode) {
      return true;
    }

    dispatch(checkCurrentTemplateErrors());
    // use getState rather than selector to immediately get errors
    if (appStore.getState().messageTemplates.templateBuilderParams?.errors) return false;

    const { type } = selectedNode;
    const isSaveSupported =
      type === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE' ||
      type === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE' ||
      type === 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT' ||
      type === 'FLOW_OBJECT_TYPE_CALL_API' ||
      type === 'FLOW_OBJECT_TYPE_TIME_DELAY';
    if (!isSaveSupported) {
      return true;
    }

    dispatch(normalize());

    const appState = appStore.getState();
    const name = selectName(appState);
    const selectedTemplateId = selectTemplateId(appState);
    const templateComponents = selectTemplateComponents(appState);
    const templateMappingsRaw = selectTemplateMappings(appState);
    const templateMappings = { ...templateMappingsRaw, ...mappingsValue };

    const templateExist = selectedTemplateId !== CREATE_NEW_TEMPLATE_ID && selectedTemplateId !== null;
    const updatedNode = produce(selectedNode, (draft) => {
      switch (type) {
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
          if (templateExist) {
            draft.data.v1.templateId = selectedTemplateId;
          } else {
            draft.data.v1.templateId = CREATE_NEW_TEMPLATE_ID;
          }

          if (draft.data.v1.name) draft.data.v1.name = name;
          if (templateComponents) {
            draft.data.v1.messageTemplateInput = {
              templateComponents,
              name,
            };
          }
          const templateMappingsProcessed: { [key: string]: string } = {};
          Object.keys(templateMappings).forEach((key) => {
            // capture variable name within {{ }}, trimming ending spaces and allowing spaces in between
            let variable = templateMappings[key].replace(/{{\s*([\w.\s]+?)\s*}}/, '$1');
            if (!ffEnableSpacesInHeaderVariable) {
              variable = variable.replace(/\s/g, '_');
            }
            templateMappingsProcessed[key] = variable;
          });
          const mappings = mapToVariableParamMapping(templateMappingsProcessed);
          if (mappings.length > 0 || draft.data.v1?.parameterMapping?.mappings?.length > 0) {
            const modifiedTemplateComponents = modifyTemplateComponents(templateComponents, substituteVariables);
            draft.data.v1.messageTemplateInput.templateComponents = modifiedTemplateComponents;

            draft.data.v1.parameterMapping = { mappings };
          }
          const isCarousel = draft.data.v1.waMessageTemplateType === 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE';
          if (selectedNodeUI && 'carousel' in selectedNodeUI && isCarousel) {
            draft.data.v1.messageTemplateInput.templateComponents = [
              ...draft.data.v1.messageTemplateInput.templateComponents.filter(
                (component: { carousel?: unknown }) => !component.carousel,
              ),
              ...mapCarousel(selectedNodeUI),
            ];
          }

          break;
        }
        case 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT': {
          const incomingNodeUI = selectedNodeUI as CustomerRepliesNodeUIState;
          if (!incomingNodeUI) break;
          draft.data = saveIncomingRoomEventNode(incomingNodeUI);
          break;
        }
        case 'FLOW_OBJECT_TYPE_CALL_API': {
          const apiCallNodeUI = selectedNodeUI as APICallNodeUIState;
          if (!apiCallNodeUI) break;
          draft.data = saveApiCallNode(apiCallNodeUI);
          break;
        }
        case 'FLOW_OBJECT_TYPE_TIME_DELAY': {
          const timeDelayNodeUI = selectedNodeUI as TimeDelayNodeUIState;
          if (!timeDelayNodeUI) break;
          draft.data = saveTimeDelayNode(timeDelayNodeUI);
          break;
        }
        default: {
          break;
        }
      }
      return draft;
    });

    onNodeObjectChange([
      {
        id: updatedNode.id,
        item: updatedNode,
      },
    ]);

    prepareVariables();

    dispatch(save());

    return true;
  }, [
    dispatch,
    onNodeObjectChange,
    prepareVariables,
    saveApiCallNode,
    saveTimeDelayNode,
    saveIncomingRoomEventNode,
    selectedNode,
    selectedNodeUI,
    substituteVariables,
  ]);

  // prepare changes to select added node, and deselect all others
  const selectNewNode = useCallback((changes: NodeChange[], currentNodes: Node[]) => {
    return [
      // select added node
      ...changes.map((change) =>
        change.type === 'add' ? { ...change, item: { ...change.item, selected: true } } : change,
      ),
      // deselect all other nodes if a new node is added
      ...currentNodes.map(
        (node) =>
          ({
            id: node.id,
            type: 'select',
            selected: false,
          }) as NodeSelectionChange,
      ),
    ];
  }, []);

  // returns true if a node is selected and going to be changed with current changes
  // returns false when there is no node selected
  const isReplacingSelectedNode = useCallback(
    (changes: NodeChange[]) => {
      if (!selectedNode) return false;

      const addNewNode = changes.some((change) => change.type === 'add');
      if (addNewNode) return true;

      const nodeSelectionChanges = changes.filter((change) => change.type === 'select') as NodeSelectionChange[];

      const newSelection = nodeSelectionChanges.some((change) => change.selected && change.id !== selectedNode?.id);
      if (newSelection) return true;

      const deselectCurrent = nodeSelectionChanges.some((change) => !change.selected && change.id === selectedNode?.id);
      if (deselectCurrent) return true;

      return false;
    },
    [selectedNode],
  );

  const onNodesChangeRequested = useCallback(
    (changes: NodeChange[]) => {
      // Prevent selecting non-editable nodes
      changes = [
        ...changes.filter(
          (change) =>
            change.type !== 'select' ||
            !NON_EDITABLE_NODE_TYPES.includes(nodes.find((node) => node.id === change.id)?.type || ''),
        ),
      ];

      let hasTemplateError = false;
      if (isReplacingSelectedNode(changes)) {
        hasTemplateError = !saveTemplate();
      }
      if (hasTemplateError) {
        changes = [...changes.filter((change) => change.type !== 'select')];
      } else {
        if (changes.find((change) => change.type === 'add' && change.item.selected === undefined)) {
          // if a new node is added select that node
          changes = selectNewNode(changes, nodes);
        }

        // display or close template editor sidebar according to changes
        showEditorFor(changes, nodes);
      }
      return changes;
    },
    [isReplacingSelectedNode, showEditorFor, nodes, saveTemplate, selectNewNode],
  );

  const flowChangeAppliers = useFlowChangeAppliers({
    onNodesChangeRequested,
    selectIsDocumentEditable: () => isDocumentEditable,
  });
  const { isHandleConnected, onNodesChange, onEdgesChange } = flowChangeAppliers;
  const { createNode } = useCreateNode();

  const createNodeAtHandle = useCallback(
    (handleType: HandleType) => {
      const { nodeId: fromNodeId, buttonIndex, nodeType: currentNodeType } = handleType;
      const nodeFrom = nodes.find((node) => node.id === fromNodeId);
      if (!nodeFrom) return;
      if (isHandleConnected(handleType)) return;

      let newNodeType = currentNodeType;
      switch (currentNodeType) {
        case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
          break;
        }
        case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_WA_SIMPLE_MESSAGE';
          break;
        }
        default: {
          newNodeType = 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE';
          break;
        }
      }

      const newNode = createNode(newNodeType, {
        position: {
          x: nodeFrom.position.x + 400 + ((buttonIndex ?? 0) % 2) * 100,
          y: nodeFrom.position.y + (buttonIndex ?? 0) * 200 - 100,
        },
      }) as Node;

      onNodesChange([
        {
          type: 'add',
          item: newNode,
        },
      ]);

      const handleId = createHandleId(handleType);
      const edgeAdd: EdgeAddChange = {
        type: 'add',
        item: createEdge({
          source: fromNodeId,
          sourceHandle: handleId,
          target: newNode.id,
          targetHandle: `${newNode.id}:on-execute`,
        }),
      };
      onEdgesChange([edgeAdd]);
    },
    [createNode, isHandleConnected, nodes, onEdgesChange, onNodesChange],
  );

  const cleanAfterButtonIsDeleted = useCallback(
    (buttonIndex: number) => {
      if (!selectedNode || !selectedNode?.type) return;

      onEdgesChange(
        flatMap(
          edges.map((edge) => {
            if (!edge.sourceHandle?.startsWith(`${selectedNode?.id}:button-click:`)) return [];
            const foundIndexString = edge.sourceHandle.replace(`${selectedNode?.id}:button-click:`, '');
            const foundIndex = parseInt(foundIndexString, 10);
            if (foundIndex < buttonIndex) return [];

            const deleteEdge: EdgeChange[] = [
              {
                id: edge.id,
                type: 'remove',
              },
            ];

            if (foundIndex === buttonIndex) return deleteEdge;

            return [
              ...deleteEdge,
              {
                type: 'add',
                item: createEdge({
                  ...edge,
                  sourceHandle: `${selectedNode?.id}:button-click:${foundIndex - 1}`,
                }),
              },
            ];
          }),
        ),
      );
    },
    [edges, onEdgesChange, selectedNode],
  );

  return {
    ...flowChangeAppliers,
    createNodeAtHandle,
    cleanAfterButtonIsDeleted,
  };
};
