import React from 'react';
import { SxProps, Theme, Box } from '@connectlyai-tenets/ui-styled-web';

interface ProfileContainerProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const ProfileContainer = ({ onSubmit, children, sx }: ProfileContainerProps) => {
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        flex: '1 0 auto',
        alignItems: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '10px',
        padding: 3,
        background: (theme) => theme.palette.common.white,
        '& button[type="submit"]': {
          position: {
            sm: 'relative',
            md: 'absolute',
          },
          mt: (theme) => ({
            sm: theme.spacing(3),
            md: 0,
          }),
          right: (theme) => ({
            sm: 'none',
            md: theme.spacing(3),
          }),
          top: (theme) => ({
            sm: 'none',
            md: theme.spacing(3),
          }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      {children}
    </Box>
  );
};

export { ProfileContainer };
