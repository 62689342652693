import React, { FC } from 'react';
import {
  IconButton,
  EmojiEmotionsIcon,
  Popover,
  PopoverOrigin,
  makeStyles,
  styled,
  Tooltip,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Picker } from 'emoji-mart';
import './EmojiPicker.css';

function isAppleDevice() {
  const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacIntel'];

  if (navigator.platform) {
    return iDevices.indexOf(navigator.platform) > -1;
  }

  return /iPad|iPhone|iPod|Mac\sOS\sX/.test(navigator.userAgent);
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0.5),
  },
}));

const Container = styled.div`
  position: relative;
`;

type EmojiPickerProps = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  tooltip?: string;
  disabled: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (emoji: any) => void;
};

const EmojiPicker: FC<EmojiPickerProps> = ({
  anchorOrigin,
  transformOrigin,
  tooltip,
  disabled,
  onClick,
  onClose,
  onSelect,
}: EmojiPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick(event);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'emoji-popover' : undefined;

  const styles = useStyles();
  const theme = useTheme();

  return (
    <Container>
      <Tooltip title={tooltip || ''}>
        <IconButton
          aria-describedby={id}
          aria-label="Emoticon"
          classes={{ root: styles.button }}
          disabled={disabled}
          onClick={handleClick}
        >
          <EmojiEmotionsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableRestoreFocus
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
          },
          onMouseLeave: handleClose,
        }}
      >
        <Picker
          set="apple"
          theme={theme.palette.mode}
          showPreview={false}
          showSkinTones={false}
          isNative={isAppleDevice()}
          color={theme.palette.primary.main}
          style={{ overflowX: 'hidden', width: 365 }}
          onSelect={onSelect}
        />
      </Popover>
    </Container>
  );
};

EmojiPicker.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  tooltip: '',
  onClick: () => {},
  onClose: () => {},
};

export default EmojiPicker;
