import React, { useMemo } from 'react';
import { Box } from '@connectlyai-tenets/ui-styled-web';
import { useMeData, selectRole } from '@hooks';
import { useTranslation } from 'react-i18next';
import { AnalyticsControls } from '@scenes/Inbox/components/AnalyticsControls';
import { KeyPerformanceIndicators } from '@scenes/Inbox/components/KeyPerformanceIndicators';
import { AgentsPerformance } from '@scenes/Inbox/components/AgentsPerformance';
import { AgentPerformance } from '@scenes/Inbox/components/AgentPerformance';

const useInbox = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.inbox' });
  const { title } = useMemo(() => {
    return {
      title: t('title'),
    };
  }, [t]);
  const { data: role } = useMeData({
    select: selectRole,
  });
  return {
    role,
    title,
  };
};

export const Inbox = () => {
  const { role } = useInbox();
  return (
    <>
      <AnalyticsControls exportEnabled={role !== 'ROLE_AGENT'} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {role !== 'ROLE_AGENT' && <KeyPerformanceIndicators />}
        {role === 'ROLE_OWNER' && <AgentsPerformance />}
        {role === 'ROLE_AGENT' && <AgentPerformance />}
      </Box>
    </>
  );
};
