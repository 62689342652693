import { channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import { CustomerChannel, CustomerProfile } from "./models";
import * as _m0 from "protobufjs/minimal";
import { Struct } from "../../google/protobuf/struct";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.profile";
function createBaseSearchCustomerQuery() {
    return { businessId: "", channelType: 0, query: undefined, entries: [] };
}
export const SearchCustomerQuery = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        if (message.query !== undefined) {
            Struct.encode(Struct.wrap(message.query), writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.entries) {
            SearchCustomerQueryEntry.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSearchCustomerQuery();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                case 3:
                    message.query = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.entries.push(SearchCustomerQueryEntry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            query: isObject(object.query) ? object.query : undefined,
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => SearchCustomerQueryEntry.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.query !== undefined && (obj.query = message.query);
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? SearchCustomerQueryEntry.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseSearchCustomerQueryEntry() {
    return {
        channelType: 0,
        query: undefined,
        externalId: undefined,
        name: undefined,
    };
}
export const SearchCustomerQueryEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channelType !== 0) {
            writer.uint32(8).int32(message.channelType);
        }
        if (message.query !== undefined) {
            Struct.encode(Struct.wrap(message.query), writer.uint32(18).fork()).ldelim();
        }
        if (message.externalId !== undefined) {
            StringValue.encode({ value: message.externalId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSearchCustomerQueryEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.channelType = reader.int32();
                    break;
                case 2:
                    message.query = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.externalId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            query: isObject(object.query) ? object.query : undefined,
            externalId: isSet(object.externalId)
                ? String(object.externalId)
                : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.query !== undefined && (obj.query = message.query);
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseSearchCustomerResult() {
    return { entries: [] };
}
export const SearchCustomerResult = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.entries) {
            SearchCustomerResultEntry.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSearchCustomerResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(SearchCustomerResultEntry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            entries: Array.isArray(object === null || object === void 0 ? void 0 : object.entries)
                ? object.entries.map((e) => SearchCustomerResultEntry.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.entries) {
            obj.entries = message.entries.map((e) => e ? SearchCustomerResultEntry.toJSON(e) : undefined);
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
};
function createBaseSearchCustomerResultEntry() {
    return {
        customerId: "",
        channel: undefined,
        profile: undefined,
        roomId: "",
        channels: [],
    };
}
export const SearchCustomerResultEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.customerId !== "") {
            writer.uint32(10).string(message.customerId);
        }
        if (message.channel !== undefined) {
            CustomerChannel.encode(message.channel, writer.uint32(18).fork()).ldelim();
        }
        if (message.profile !== undefined) {
            CustomerProfile.encode(message.profile, writer.uint32(26).fork()).ldelim();
        }
        if (message.roomId !== "") {
            writer.uint32(34).string(message.roomId);
        }
        for (const v of message.channels) {
            CustomerChannel.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSearchCustomerResultEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customerId = reader.string();
                    break;
                case 2:
                    message.channel = CustomerChannel.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profile = CustomerProfile.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.roomId = reader.string();
                    break;
                case 5:
                    message.channels.push(CustomerChannel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customerId: isSet(object.customerId) ? String(object.customerId) : "",
            channel: isSet(object.channel)
                ? CustomerChannel.fromJSON(object.channel)
                : undefined,
            profile: isSet(object.profile)
                ? CustomerProfile.fromJSON(object.profile)
                : undefined,
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            channels: Array.isArray(object === null || object === void 0 ? void 0 : object.channels)
                ? object.channels.map((e) => CustomerChannel.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.customerId !== undefined && (obj.customerId = message.customerId);
        message.channel !== undefined &&
            (obj.channel = message.channel
                ? CustomerChannel.toJSON(message.channel)
                : undefined);
        message.profile !== undefined &&
            (obj.profile = message.profile
                ? CustomerProfile.toJSON(message.profile)
                : undefined);
        message.roomId !== undefined && (obj.roomId = message.roomId);
        if (message.channels) {
            obj.channels = message.channels.map((e) => e ? CustomerChannel.toJSON(e) : undefined);
        }
        else {
            obj.channels = [];
        }
        return obj;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
