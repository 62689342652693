import { SelectChangeEvent } from '@connectlyai-tenets/ui-styled-web';

export type TagFields = {
  name: string;
  colorHex: string;
};

export type TagCreateState = {
  name: string;
  colorId: string;
};

type TagFieldKey = keyof TagFields;

export interface TagEditState {
  itemId: string;
  propertyKey: TagFieldKey;
  input: string;
}

export type Tag = TagFields & {
  id: string;
  mutable: boolean;
};

export type TagView = Tag & {
  isLoading: boolean;
};

export interface TagState {
  item: Tag;
  state: 'processing' | 'idle';
}

export interface TagsState {
  items: {
    ids: string[];
    byId: {
      [key: string]: TagState;
    };
  };
}

export type UseTagProps = {
  id: string;
  colorHex: string;
  editState: TagEditState | undefined;
};

export type TagProps = {
  item: TagView;
  editState: TagEditState | undefined;
  colorsMap: Record<string, { name: string; colorHex: string }>;
  handleColorChange: (e: SelectChangeEvent, itemId: string) => void;
  handleDeleteDialogOpen: (id: string) => void;
  handleEditTagBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEditTagFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEditTagChange: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export enum DialogActionResult {
  DISMISS,
  PRIMARY,
  SECONDARY,
}

export type TagDeleteDialogProps = {
  open: boolean;
  handleDeleteDialogClose: (result: DialogActionResult) => void;
};
