import { CustomerRepliesNodeUIState } from './types';

export const DEFAULT_INCOMING_ROOM_EVENT_NODE_UI: CustomerRepliesNodeUIState = {
  format: 'Text only',
  saveAsVariable: false,
  advancedTextSettings: false,
  minCharacters: '',
  maxCharacters: '',
  regex: '',
  errorMessage: '',
  enableErrorMessage: false,
};
