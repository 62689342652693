import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Label, TableRow, TableCell, Tooltip, IconButton, EditIcon } from '@connectlyai-tenets/ui-styled-web';
import { WithAnalytics } from '../../components';
import { RowProps } from './types';

const DefaultRow: FC<RowProps> = ({
  id,
  campaign,
  readableName,
  createdAt,
  scheduledAt,
  state,
  businessId,
  outcome,
}) => (
  <TableRow>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
      }}
    >
      <Label variant="subtitle2">{readableName}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      {state === 'SENDOUT_STATE_ACTIVE' && <Label variant="body1">Active</Label>}
      <Label color="textSecondary" variant="body2">
        {scheduledAt && new Date(scheduledAt).toLocaleString()}
      </Label>
    </TableCell>
    {state !== 'SENDOUT_STATE_DRAFT' && (
      <TableCell
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: 'white',
        }}
      >
        {outcome?.executionDataV1?.numDispatched && (
          <Label variant="body1">{outcome?.executionDataV1?.numDispatched}</Label>
        )}
      </TableCell>
    )}
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
        textAlign: 'right',
      }}
    >
      <WithAnalytics type="clicks edit_sendout_button" data={{ businessId, sendoutId: id }}>
        <Tooltip title="Edit" arrow placement="top">
          <IconButton
            component={Link}
            to={
              campaign?.flowDocumentId
                ? `/flow/${campaign?.flowDocumentId}`
                : `/campaigns/whatsapp-broadcast/edit/${id}`
            }
            sx={
              state === 'SENDOUT_STATE_DRAFT'
                ? { textTransform: 'none' }
                : { color: (theme) => theme.palette.text.disabled, pointerEvents: 'none', textTransform: 'none' }
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </WithAnalytics>
    </TableCell>
  </TableRow>
);

export default DefaultRow;
