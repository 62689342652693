import { channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import { Timestamp } from "../../google/protobuf/timestamp";
import { WebhookEventEnvelope } from "../../events/shopify/v2023_01/models";
import { Order as Order1, OrderForm } from "../../events/vtex/v1/models";
import { WhatsAppWebhookValueMessagesMessage } from "../../events/facebook/webhook/v12_0/whatsapp/models";
import * as _m0 from "protobufjs/minimal";
import { StringValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.order";
function createBaseOrder() {
    return {
        id: "",
        orderStatusUrl: "",
        pricing: undefined,
        rawOneof: undefined,
    };
}
export const Order = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.orderStatusUrl !== "") {
            writer.uint32(18).string(message.orderStatusUrl);
        }
        if (message.pricing !== undefined) {
            Pricing.encode(message.pricing, writer.uint32(26).fork()).ldelim();
        }
        if (((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopify") {
            WebhookEventEnvelope.encode(message.rawOneof.shopify, writer.uint32(90).fork()).ldelim();
        }
        if (((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.$case) === "vtex") {
            Order1.encode(message.rawOneof.vtex, writer.uint32(98).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orderStatusUrl = reader.string();
                    break;
                case 3:
                    message.pricing = Pricing.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.rawOneof = {
                        $case: "shopify",
                        shopify: WebhookEventEnvelope.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.rawOneof = {
                        $case: "vtex",
                        vtex: Order1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            orderStatusUrl: isSet(object.orderStatusUrl)
                ? String(object.orderStatusUrl)
                : "",
            pricing: isSet(object.pricing)
                ? Pricing.fromJSON(object.pricing)
                : undefined,
            rawOneof: isSet(object.shopify)
                ? {
                    $case: "shopify",
                    shopify: WebhookEventEnvelope.fromJSON(object.shopify),
                }
                : isSet(object.vtex)
                    ? { $case: "vtex", vtex: Order1.fromJSON(object.vtex) }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.orderStatusUrl !== undefined &&
            (obj.orderStatusUrl = message.orderStatusUrl);
        message.pricing !== undefined &&
            (obj.pricing = message.pricing
                ? Pricing.toJSON(message.pricing)
                : undefined);
        ((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopify" &&
            (obj.shopify = ((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.shopify)
                ? WebhookEventEnvelope.toJSON((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.shopify)
                : undefined);
        ((_d = message.rawOneof) === null || _d === void 0 ? void 0 : _d.$case) === "vtex" &&
            (obj.vtex = ((_e = message.rawOneof) === null || _e === void 0 ? void 0 : _e.vtex)
                ? Order1.toJSON((_f = message.rawOneof) === null || _f === void 0 ? void 0 : _f.vtex)
                : undefined);
        return obj;
    },
};
function createBasePricing() {
    return {
        currency: "",
        presentmentCurrency: "",
        total: 0,
        subtotal: 0,
        totalLineItems: 0,
        totalDiscounts: 0,
    };
}
export const Pricing = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.currency !== "") {
            writer.uint32(10).string(message.currency);
        }
        if (message.presentmentCurrency !== "") {
            writer.uint32(50).string(message.presentmentCurrency);
        }
        if (message.total !== 0) {
            writer.uint32(17).double(message.total);
        }
        if (message.subtotal !== 0) {
            writer.uint32(25).double(message.subtotal);
        }
        if (message.totalLineItems !== 0) {
            writer.uint32(33).double(message.totalLineItems);
        }
        if (message.totalDiscounts !== 0) {
            writer.uint32(41).double(message.totalDiscounts);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePricing();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.currency = reader.string();
                    break;
                case 6:
                    message.presentmentCurrency = reader.string();
                    break;
                case 2:
                    message.total = reader.double();
                    break;
                case 3:
                    message.subtotal = reader.double();
                    break;
                case 4:
                    message.totalLineItems = reader.double();
                    break;
                case 5:
                    message.totalDiscounts = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            currency: isSet(object.currency) ? String(object.currency) : "",
            presentmentCurrency: isSet(object.presentmentCurrency)
                ? String(object.presentmentCurrency)
                : "",
            total: isSet(object.total) ? Number(object.total) : 0,
            subtotal: isSet(object.subtotal) ? Number(object.subtotal) : 0,
            totalLineItems: isSet(object.totalLineItems)
                ? Number(object.totalLineItems)
                : 0,
            totalDiscounts: isSet(object.totalDiscounts)
                ? Number(object.totalDiscounts)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.currency !== undefined && (obj.currency = message.currency);
        message.presentmentCurrency !== undefined &&
            (obj.presentmentCurrency = message.presentmentCurrency);
        message.total !== undefined && (obj.total = message.total);
        message.subtotal !== undefined && (obj.subtotal = message.subtotal);
        message.totalLineItems !== undefined &&
            (obj.totalLineItems = message.totalLineItems);
        message.totalDiscounts !== undefined &&
            (obj.totalDiscounts = message.totalDiscounts);
        return obj;
    },
};
function createBaseCheckout() {
    return { id: "", url: "", token: "", rawOneof: undefined };
}
export const Checkout = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        if (message.token !== "") {
            writer.uint32(26).string(message.token);
        }
        if (((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopify") {
            WebhookEventEnvelope.encode(message.rawOneof.shopify, writer.uint32(90).fork()).ldelim();
        }
        if (((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.$case) === "whatsapp") {
            WhatsAppWebhookValueMessagesMessage.encode(message.rawOneof.whatsapp, writer.uint32(98).fork()).ldelim();
        }
        if (((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.$case) === "vtex") {
            OrderForm.encode(message.rawOneof.vtex, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCheckout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.token = reader.string();
                    break;
                case 11:
                    message.rawOneof = {
                        $case: "shopify",
                        shopify: WebhookEventEnvelope.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.rawOneof = {
                        $case: "whatsapp",
                        whatsapp: WhatsAppWebhookValueMessagesMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 13:
                    message.rawOneof = {
                        $case: "vtex",
                        vtex: OrderForm.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            url: isSet(object.url) ? String(object.url) : "",
            token: isSet(object.token) ? String(object.token) : "",
            rawOneof: isSet(object.shopify)
                ? {
                    $case: "shopify",
                    shopify: WebhookEventEnvelope.fromJSON(object.shopify),
                }
                : isSet(object.whatsapp)
                    ? {
                        $case: "whatsapp",
                        whatsapp: WhatsAppWebhookValueMessagesMessage.fromJSON(object.whatsapp),
                    }
                    : isSet(object.vtex)
                        ? { $case: "vtex", vtex: OrderForm.fromJSON(object.vtex) }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.url !== undefined && (obj.url = message.url);
        message.token !== undefined && (obj.token = message.token);
        ((_a = message.rawOneof) === null || _a === void 0 ? void 0 : _a.$case) === "shopify" &&
            (obj.shopify = ((_b = message.rawOneof) === null || _b === void 0 ? void 0 : _b.shopify)
                ? WebhookEventEnvelope.toJSON((_c = message.rawOneof) === null || _c === void 0 ? void 0 : _c.shopify)
                : undefined);
        ((_d = message.rawOneof) === null || _d === void 0 ? void 0 : _d.$case) === "whatsapp" &&
            (obj.whatsapp = ((_e = message.rawOneof) === null || _e === void 0 ? void 0 : _e.whatsapp)
                ? WhatsAppWebhookValueMessagesMessage.toJSON((_f = message.rawOneof) === null || _f === void 0 ? void 0 : _f.whatsapp)
                : undefined);
        ((_g = message.rawOneof) === null || _g === void 0 ? void 0 : _g.$case) === "vtex" &&
            (obj.vtex = ((_h = message.rawOneof) === null || _h === void 0 ? void 0 : _h.vtex)
                ? OrderForm.toJSON((_j = message.rawOneof) === null || _j === void 0 ? void 0 : _j.vtex)
                : undefined);
        return obj;
    },
};
function createBaseAbandonedCheckoutReminders() {
    return {
        reminders: [],
        checkoutEventId: "",
        orderEventId: undefined,
        sessionObject: "",
    };
}
export const AbandonedCheckoutReminders = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.reminders) {
            AbandonedCheckoutReminder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.checkoutEventId !== "") {
            writer.uint32(18).string(message.checkoutEventId);
        }
        if (message.orderEventId !== undefined) {
            StringValue.encode({ value: message.orderEventId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.sessionObject !== "") {
            writer.uint32(34).string(message.sessionObject);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAbandonedCheckoutReminders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminders.push(AbandonedCheckoutReminder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.checkoutEventId = reader.string();
                    break;
                case 3:
                    message.orderEventId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.sessionObject = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminders: Array.isArray(object === null || object === void 0 ? void 0 : object.reminders)
                ? object.reminders.map((e) => AbandonedCheckoutReminder.fromJSON(e))
                : [],
            checkoutEventId: isSet(object.checkoutEventId)
                ? String(object.checkoutEventId)
                : "",
            orderEventId: isSet(object.orderEventId)
                ? String(object.orderEventId)
                : undefined,
            sessionObject: isSet(object.sessionObject)
                ? String(object.sessionObject)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.reminders) {
            obj.reminders = message.reminders.map((e) => e ? AbandonedCheckoutReminder.toJSON(e) : undefined);
        }
        else {
            obj.reminders = [];
        }
        message.checkoutEventId !== undefined &&
            (obj.checkoutEventId = message.checkoutEventId);
        message.orderEventId !== undefined &&
            (obj.orderEventId = message.orderEventId);
        message.sessionObject !== undefined &&
            (obj.sessionObject = message.sessionObject);
        return obj;
    },
};
function createBaseAbandonedCheckoutReminder() {
    return {
        eventId: "",
        channelType: 0,
        externalId: "",
        remindedAt: undefined,
        reminderFlowId: "",
    };
}
export const AbandonedCheckoutReminder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventId !== "") {
            writer.uint32(10).string(message.eventId);
        }
        if (message.channelType !== 0) {
            writer.uint32(16).int32(message.channelType);
        }
        if (message.externalId !== "") {
            writer.uint32(26).string(message.externalId);
        }
        if (message.remindedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.remindedAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.reminderFlowId !== "") {
            writer.uint32(42).string(message.reminderFlowId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAbandonedCheckoutReminder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventId = reader.string();
                    break;
                case 2:
                    message.channelType = reader.int32();
                    break;
                case 3:
                    message.externalId = reader.string();
                    break;
                case 4:
                    message.remindedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.reminderFlowId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventId: isSet(object.eventId) ? String(object.eventId) : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            remindedAt: isSet(object.remindedAt)
                ? String(object.remindedAt)
                : undefined,
            reminderFlowId: isSet(object.reminderFlowId)
                ? String(object.reminderFlowId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventId !== undefined && (obj.eventId = message.eventId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.remindedAt !== undefined && (obj.remindedAt = message.remindedAt);
        message.reminderFlowId !== undefined &&
            (obj.reminderFlowId = message.reminderFlowId);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
