import memoize from 'lodash/memoize';
import { mutationAssignRoomAgentProvider, mutationChangeRoomStatusProvider, queryRoomsProvider, queryRoomProvider, subscriptionRoomDtoProvider, subscriptionDtoUpdateRoomProvider, } from '../usecases';
import { composeTemplatedMessageProvider } from '../usecases/composeTemplatedMessage';
import { mutationCreateEventProvider } from '../usecases/mutationCreateEvent';
import { mutationUpdateMessageReadWatermarkProvider } from '../usecases/mutationUpdateMessageReadWatermark';
export const buildDIContainer = (deps) => {
    const log = deps.logProvider();
    return {
        config: deps.config,
        logProvider: deps.logProvider,
        notificationServiceProvider: deps.notificationServiceProvider,
        queryRoomsProvider: memoize(() => queryRoomsProvider(log, deps.networkClientProvider(), deps.onDurationRoomsQuery)),
        queryRoomProvider: memoize(() => queryRoomProvider(log, deps.networkClientProvider())),
        mutationAssignRoomAgentProvider: memoize(() => mutationAssignRoomAgentProvider(log, deps.networkClientProvider())),
        mutationChangeRoomStatusProvider: memoize(() => mutationChangeRoomStatusProvider(log, deps.networkClientProvider())),
        mutationCreateEvent: memoize(() => mutationCreateEventProvider(log, deps.networkClientProvider())),
        composeTemplatedMessage: memoize(() => composeTemplatedMessageProvider(log, deps.networkClientProvider())),
        mutationUpdateMessageReadWatermark: memoize(() => mutationUpdateMessageReadWatermarkProvider(log, deps.networkClientProvider())),
        subscriptionDtoUpdateRoomProvider: memoize(() => subscriptionDtoUpdateRoomProvider(deps.logProvider(), deps.dtoUpdateAccessorProvider())),
        subscriptionRoomDtoProvider: memoize(() => subscriptionRoomDtoProvider(deps.logProvider(), deps.dtoUpdateAccessorProvider())),
    };
};
