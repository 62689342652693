import React, { VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, CircularProgress, Label } from '@connectlyai-tenets/ui-styled-web';
import { useContextSelector } from 'use-context-selector';
import { SIZE_MEDIA_XS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';
import {
  setIsSpreadsheetOpen,
  selectIsSpreadsheetOpen,
  selectCampaignSendNode,
  selectSubmitError,
  selectAudience,
} from '../../state/flow';
import { FlowCampaignContext } from '../../features/flow/contexts/FlowCampaignContext';
import { FlowChartSidebarRecipientDetailsHint } from '../FlowChartSidebarRecipientDetailsHint';

export const SPREADSHEET_CHILD_WINDOW = 'spreadsheet-window';

export const FlowChartSidebarRecipientDetails: VFC = () => {
  const submitError = useSelector(selectSubmitError);
  const campaignSendNode = useSelector(selectCampaignSendNode);
  let isSpreadsheetOpen = useSelector(selectIsSpreadsheetOpen);
  const audience = useSelector(selectAudience);
  const dispatch = useDispatch();
  const handleSpreadsheetOpen = () => {
    dispatch(setIsSpreadsheetOpen(true));
  };

  isSpreadsheetOpen = audience.isEnabled && audience.segmentId ? true : isSpreadsheetOpen;

  const { verifyHint, verifyHintColor, verifyProgress } = useContextSelector(
    FlowCampaignContext,
    (context) => context.verifySendoutResultData,
  );

  const handleVerifySpreadsheet = useContextSelector(
    FlowCampaignContext,
    (context) => context?.handleVerifySpreadsheet,
  );
  const spreadsheetLink = campaignSendNode?.data?.v1?.sendoutConfig?.inputCohortUrl;
  const isFailure = submitError !== null;

  return (
    <>
      <Label variant="h6" gutterBottom>
        Finalize Recipient Details
      </Label>
      {isFailure && (
        <Label variant="subtitle2" color="error">
          Please go back to modify campaign nodes and correct errors
        </Label>
      )}
      {!isFailure && !spreadsheetLink && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 10,
            justifyContent: 'center',
            width: 1,
            gap: 4,
          }}
        >
          <CircularProgress />
          <Label>Please wait while generating spreadsheet</Label>
        </Box>
      )}
      {spreadsheetLink && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Avatar
                sx={{
                  width: (theme) => theme.spacing(SIZE_MEDIA_XS),
                  height: (theme) => theme.spacing(SIZE_MEDIA_XS),
                  marginRight: (theme) => theme.spacing(SIZE_SPACING_INTER_COMPONENT),
                  background: (theme) => theme.palette.common.black,
                  fontSize: (theme) => theme.typography.caption.fontSize,
                }}
              >
                1
              </Avatar>

              <Label variant="subtitle2">Edit recipients and variables</Label>
            </Box>
            <Button
              variant={isSpreadsheetOpen ? 'outlined' : 'contained'}
              target={SPREADSHEET_CHILD_WINDOW}
              href={spreadsheetLink}
              onClick={handleSpreadsheetOpen}
              sx={{ alignSelf: 'flex-start' }}
            >
              {isSpreadsheetOpen ? 'Edit recipient list' : 'Add recipient list'}
            </Button>
            <FlowChartSidebarRecipientDetailsHint
              content={`Come back to this screen and continue 
                the process after you’ve completed the recipient list.`}
            />
          </Box>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Avatar
                sx={{
                  width: (theme) => theme.spacing(SIZE_MEDIA_XS),
                  height: (theme) => theme.spacing(SIZE_MEDIA_XS),
                  marginRight: (theme) => theme.spacing(SIZE_SPACING_INTER_COMPONENT),
                  background: (theme) => theme.palette.common.black,
                  fontSize: (theme) => theme.typography.caption.fontSize,
                }}
              >
                2
              </Avatar>

              <Label variant="subtitle2">Verify information and correct errors</Label>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleVerifySpreadsheet}
                disabled={!isSpreadsheetOpen || (!!verifyProgress && verifyProgress.completed < verifyProgress.total)}
              >
                {!!verifyProgress && verifyProgress.completed < verifyProgress.total
                  ? `Verifying... ${verifyProgress.completed}%`
                  : 'Verify'}
              </Button>
              {verifyHint && (
                <Label
                  component="div"
                  variant="body1"
                  sx={{
                    mt: 2,
                    color: verifyHintColor,
                  }}
                >
                  {verifyHint}
                </Label>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
