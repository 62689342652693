import { DEFAULT_STORE_DESCRIPTOR, FREE_TEXT_DOCUMENT_FILENAME } from './constants';
import { SofiaDocumentsResponse, SofiaStatusResponse, AssetUriResponse, useAnalysisDataInRangesReturn } from './hooks';
import {
  Document,
  SofiaStoreDescriptionView,
  SofiaAnalyticsFunnelCategory,
  TopicsOrWordsAnalysis,
  Sentiment,
  CountPercentChange,
} from './types';
import { SofiaStatus } from './hooks/useSofiaStatus/types';
import { SofiaAnalyticsResponse } from './hooks/useTableAnalyticsData';
import { SofiaAnalysisResponse } from './hooks/useAnalysisData';
import { StoreDescriptorV2 } from './hooks/useSofiaStoreDescriptorV2';
import { DataDisplayList, DataExportList } from './components/DataTable/types';
import { selectStoreDescriptorViewV2 } from './mappers';

export const selectSofiaStoreDescriptorViewFromList = (
  data: StoreDescriptorV2[],
  id: string,
): SofiaStoreDescriptionView => {
  let descriptor = data.find((storeDescriptor) => storeDescriptor && storeDescriptor.id === id);
  if (!descriptor) {
    [descriptor] = data;
  }
  return selectStoreDescriptorViewV2(descriptor || DEFAULT_STORE_DESCRIPTOR);
};

export const selectSofiaAllDocuments = (data: SofiaDocumentsResponse): Document[] => {
  const { response } = data;
  return (
    response?.assets?.map((asset) => ({ id: asset.assetId || '', name: asset.filename, mimeType: asset.mimeType })) ||
    []
  );
};

const getFreeTextDocument = (documents: Document[]): Document | null => {
  return (
    documents.filter(
      (document) =>
        ['application/octet-stream', 'text/plain'].includes(document.mimeType || '') &&
        document.name === FREE_TEXT_DOCUMENT_FILENAME,
    )[0] || null
  );
};

export const selectSofiaFreeTextDocument = (data: SofiaDocumentsResponse): Document | null => {
  const documents = selectSofiaAllDocuments(data);
  return getFreeTextDocument(documents);
};

export const selectSofiaDocuments = (data: SofiaDocumentsResponse): Document[] => {
  const documents = selectSofiaAllDocuments(data);
  const freeTextDocument = getFreeTextDocument(documents);
  return documents.filter((document) => document.id !== freeTextDocument?.id);
};

export const selectSofiaFreeTextDocumentAssetId = (data: SofiaDocumentsResponse): string | null => {
  const document = selectSofiaFreeTextDocument(data);
  if (!document) {
    return null;
  }
  return document.id || null;
};

export const selectSofiaStatus = (data: SofiaStatusResponse): SofiaStatus => {
  return data?.response?.status?.indexerStatus;
};

export const selectAssetUri = (data: AssetUriResponse): string | null => {
  return data?.cdnUri || null;
};

export const selectAnalyticsTableData = (data: SofiaAnalyticsResponse): DataDisplayList => {
  if (!data?.entity?.items) {
    return [];
  }
  return data.entity.items.map((item) => {
    return {
      customerExternalId: item.customerExternalId || '',
      createdAt: item.createdAt || '',
      numTurns: item.numTurns,
      countMessages: item.countMessages,
      countAssistantMessages: item.countAssistantMessages,
      sentiment: item.sentiment || '',
      roomId: item.roomId || '',
    };
  });
};

export const selectAnalyticsExportData = (data: SofiaAnalyticsResponse): DataExportList => {
  if (!data?.entity?.items) {
    return [];
  }
  return data.entity.items.map((item) => {
    return {
      customerExternalId: item.customerExternalId || '',
      roomId: item.roomId || '',
      createdAt: item.createdAt || '',
      numTurns: item.numTurns,
      countMessages: item.countMessages,
      countAssistantMessages: item.countAssistantMessages,
      messageText: `"${item.messageText?.join(',').replace(/"/g, "'")}"`.replace(/\n/g, ' '),
      sentiment: item.sentiment || '',
    };
  });
};

type FunnelAnalysis = Record<SofiaAnalyticsFunnelCategory, CountPercentChange>;
export const selectFunnelAnalysis = (analyticsData: useAnalysisDataInRangesReturn): FunnelAnalysis => {
  const results: FunnelAnalysis = {
    all: {
      count: 0,
      percent: 100,
      change: undefined,
    },
    awareness: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    consideration: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    decision: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    postPurchase: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    spam: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    undefined: {
      count: 0,
      percent: 0,
      change: undefined,
    },
  };
  const processJourney = (
    data: SofiaAnalysisResponse,
  ): [number, number, number, number, number, number, number] | undefined => {
    let all = 0;
    let awareness = 0;
    let consideration = 0;
    let decision = 0;
    let postPurchase = 0;
    let spam = 0;
    let undef = 0;
    const entities = data?.entity?.items || undefined;
    if (!entities) {
      return undefined;
    }
    entities.forEach((item) => {
      (item.journeyStats || []).forEach((journey) => {
        all += journey?.sessionCount || 0;
        switch (journey.journey) {
          case 'Awareness':
            awareness += journey?.sessionCount || 0;
            break;
          case 'Consideration':
            consideration += journey?.sessionCount || 0;
            break;
          case 'Decision':
            decision += journey?.sessionCount || 0;
            break;
          case 'Post Purchase':
            postPurchase += journey?.sessionCount || 0;
            break;
          case 'Spam':
            spam += journey?.sessionCount || 0;
            break;
          case 'Undefined':
            undef += journey?.sessionCount || 0;
            break;
          default:
            break;
        }
      });
    });
    return [all, awareness, consideration, decision, postPurchase, spam, undef];
  };
  const { currentData, previousData } = analyticsData;
  const currentJourneys = processJourney(currentData);
  if (currentJourneys) {
    const [
      currentAll,
      currentAwareness,
      currentConsideration,
      currentDecision,
      currentPostPurchase,
      currentSpam,
      currentUndefined,
    ] = currentJourneys;
    results.all.count = currentAll;
    results.awareness.count = currentAwareness;
    results.awareness.percent = currentAwareness ? (currentAwareness / currentAll) * 100 : 0;
    results.consideration.count = currentConsideration;
    results.consideration.percent = currentConsideration ? (currentConsideration / currentAll) * 100 : 0;
    results.decision.count = currentDecision;
    results.decision.percent = currentDecision ? (currentDecision / currentAll) * 100 : 0;
    results.postPurchase.count = currentPostPurchase;
    results.postPurchase.percent = currentPostPurchase ? (currentPostPurchase / currentAll) * 100 : 0;
    results.spam.count = currentSpam;
    results.spam.percent = currentSpam ? (currentSpam / currentAll) * 100 : 0;
    results.undefined.count = currentUndefined;
    results.undefined.percent = currentUndefined ? (currentUndefined / currentAll) * 100 : 0;
  }
  const previousJourneys = processJourney(previousData);
  if (previousJourneys) {
    const [
      previousAll,
      previousAwareness,
      previousConsideration,
      previousDecision,
      previousPostPurchase,
      previousSpam,
      previousUndefined,
    ] = previousJourneys;
    results.all.change = previousAll ? ((results.all.count - previousAll) / previousAll) * 100 : undefined;
    const percentAwareness = previousAwareness ? (previousAwareness / previousAll) * 100 : 0;
    results.awareness.change = percentAwareness
      ? ((results.awareness.percent - percentAwareness) / percentAwareness) * 100
      : undefined;
    const percentConsideration = previousConsideration ? (previousConsideration / previousAll) * 100 : 0;
    results.consideration.change = percentConsideration
      ? ((results.consideration.percent - percentConsideration) / percentConsideration) * 100
      : undefined;
    const percentDecision = previousDecision ? (previousDecision / previousAll) * 100 : 0;
    results.decision.change = percentDecision
      ? ((results.decision.percent - percentDecision) / percentDecision) * 100
      : undefined;
    const percentPostPurchase = previousPostPurchase ? (previousPostPurchase / previousAll) * 100 : 0;
    results.postPurchase.change = percentPostPurchase
      ? ((results.postPurchase.percent - percentPostPurchase) / percentPostPurchase) * 100
      : undefined;
    const percentSpam = previousSpam ? (previousSpam / previousSpam) * 100 : 0;
    results.spam.change = percentSpam ? ((results.spam.percent - percentSpam) / percentSpam) * 100 : undefined;
    const percentUndefined = previousUndefined ? (previousUndefined / previousAll) * 100 : 0;
    results.undefined.change = percentUndefined
      ? ((results.undefined.percent - percentUndefined) / percentUndefined) * 100
      : undefined;
  }

  return results;
};

type StatsDict = { [key: string]: { count: number; change: number | undefined } };
export const selectTopicsAnalysis = (analyticsData: useAnalysisDataInRangesReturn): TopicsOrWordsAnalysis => {
  const results: TopicsOrWordsAnalysis = {
    total: 0,
    list: [],
  };
  const { currentData, previousData } = analyticsData;
  const processTopics = (data: SofiaAnalysisResponse): [number, StatsDict] | undefined => {
    const entities = data?.entity?.items || undefined;
    if (!entities) {
      return undefined;
    }
    let total = 0;
    const topics: StatsDict = {};
    entities.forEach((item) => {
      total += item.sessionCount || 0;
      (item.topicStats || []).forEach((topic) => {
        if (topic.topic) {
          if (!topics[topic.topic]) {
            topics[topic.topic] = {
              count: 0,
              change: undefined,
            };
          }
          topics[topic.topic] = {
            ...topics[topic.topic],
            count: topics[topic.topic].count + (topic?.sessionCount || 0),
          };
        }
      });
    });
    return [total, topics];
  };
  const currentTopics = processTopics(currentData);
  if (currentTopics) {
    const [currentTotal, currentStats] = currentTopics;
    results.total = currentTotal;
    const previousTopics = processTopics(previousData);
    if (previousTopics) {
      const [previousTotal, previousStats] = previousTopics;
      Object.entries(previousStats).forEach(([topic, stats]) => {
        const currentPercent = (currentStats[topic]?.count || 0) / currentTotal;
        const prevPercent = (stats.count || 0) / previousTotal;
        currentStats[topic] = {
          ...stats,
          change: prevPercent ? ((currentPercent - prevPercent) / prevPercent) * 100 : undefined,
        };
      });
    }
    Object.entries(currentStats).forEach(([topic, stats]) => {
      results.list.push({ name: topic, count: stats.count, change: stats.change });
    });
  }
  results.list.sort((a, b) => b.count - a.count);
  return results;
};

export const selectWordsAnalysis = (analyticsData: useAnalysisDataInRangesReturn): TopicsOrWordsAnalysis => {
  const results: TopicsOrWordsAnalysis = {
    total: 0,
    list: [],
  };
  const { currentData, previousData } = analyticsData;
  const processWords = (data: SofiaAnalysisResponse): [number, StatsDict] | undefined => {
    const entities = data?.entity?.items || undefined;
    if (!entities) {
      return undefined;
    }
    let total = 0;
    const words: StatsDict = {};
    entities.forEach((item) => {
      total += item.sessionCount || 0;
      (item.keywordStats || []).forEach((word) => {
        if (word.keyword) {
          if (!words[word.keyword]) {
            words[word.keyword] = {
              count: 0,
              change: undefined,
            };
          }
          words[word.keyword] = {
            ...words[word.keyword],
            count: words[word.keyword].count + (word?.sessionCount || 0),
          };
        }
      });
    });
    return [total, words];
  };
  const currentWords = processWords(currentData);
  if (currentWords) {
    const [currentTotal, currentStats] = currentWords;
    results.total = currentTotal;
    const previousWords = processWords(previousData);
    if (previousWords) {
      const [previousTotal, previousStats] = previousWords;
      Object.entries(previousStats).forEach(([topic, stats]) => {
        const currentPercent = (currentStats[topic]?.count || 0) / currentTotal;
        const prevPercent = (stats.count || 0) / previousTotal;
        currentStats[topic] = {
          ...stats,
          change: prevPercent ? ((currentPercent - prevPercent) / prevPercent) * 100 : undefined,
        };
      });
    }
    Object.entries(currentStats).forEach(([topic, stats]) => {
      results.list.push({ name: topic, count: stats.count, change: stats.change });
    });
  }

  results.list.sort((a, b) => b.count - a.count);
  return results;
};

type SentimentAnalysis = Record<Sentiment, CountPercentChange>;
export const selectSentimentAnalysis = (analyticsData: useAnalysisDataInRangesReturn): SentimentAnalysis => {
  const { currentData, previousData } = analyticsData;
  const results: SentimentAnalysis = {
    'very positive': {
      count: 0,
      percent: 0,
      change: undefined,
    },
    positive: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    mixed: {
      count: 0,
      percent: 0,
      change: undefined,
    },
    negative: {
      count: 0,
      percent: 0,
      change: undefined,
    },
  };
  const processSentiments = (data: SofiaAnalysisResponse): [number, number, number, number, number] | undefined => {
    let veryPositive = 0;
    let positive = 0;
    let mixed = 0;
    let negative = 0;
    let total = 0;
    const entities = data?.entity?.items || undefined;
    if (!entities) {
      return undefined;
    }
    entities.forEach((item) => {
      total += item.sessionCount || 0;
      (item.sentimentStats || []).forEach((sentiment) => {
        switch (sentiment.sentiment) {
          case 'very positive':
            veryPositive += sentiment?.sessionCount || 0;
            break;
          case 'positive':
            positive += sentiment?.sessionCount || 0;
            break;
          case 'negative':
            negative += sentiment?.sessionCount || 0;
            break;
          case 'mixed':
            mixed += sentiment?.sessionCount || 0;
            break;
          default:
            break;
        }
      });
    });
    return [veryPositive, positive, mixed, negative, total];
  };
  // return results;
  const currentSentiments = processSentiments(currentData);
  if (currentSentiments) {
    const [currentVeryPositive, currentPositive, currentMixed, currentNegative, currentTotal] = currentSentiments;
    results['very positive'].count = currentVeryPositive;
    results['very positive'].percent = currentTotal ? (currentVeryPositive / currentTotal) * 100 : 0;
    results.positive.count = currentPositive;
    results.positive.percent = currentTotal ? (currentPositive / currentTotal) * 100 : 0;
    results.mixed.count = currentMixed;
    results.mixed.percent = currentTotal ? (currentMixed / currentTotal) * 100 : 0;
    results.negative.count = currentNegative;
    results.negative.percent = currentTotal ? (currentNegative / currentTotal) * 100 : 0;
  }
  const previousSentiments = processSentiments(previousData);
  if (previousSentiments) {
    const [previousVeryPositive, previousPositive, previousMixed, previousNegative, previousTotal] = previousSentiments;
    const percentPositive = previousTotal ? (previousPositive / previousTotal) * 100 : 0;
    const percentVeryPositive = previousTotal ? (previousVeryPositive / previousTotal) * 100 : 0;
    results['very positive'].change = percentVeryPositive
      ? ((results['very positive'].percent - percentVeryPositive) / percentVeryPositive) * 100
      : undefined;
    results.positive.change = percentPositive
      ? ((results.positive.percent - percentPositive) / percentPositive) * 100
      : undefined;
    const percentMixed = previousTotal ? (previousMixed / previousTotal) * 100 : 0;
    results.mixed.change = percentMixed ? ((results.mixed.percent - percentMixed) / percentMixed) * 100 : undefined;
    const percentNegative = previousTotal ? (previousNegative / previousTotal) * 100 : 0;
    results.negative.change = percentNegative
      ? ((results.negative.percent - percentNegative) / percentNegative) * 100
      : undefined;
  }
  return results;
};

const ONE_DAY = 1000 * 60 * 60 * 24;
type MessageAnalysis = {
  xData: Date[];
  sessionsValues: number[];
  messagesValues: number[];
};

export const selectMessageAnalysis = (analyticsData: useAnalysisDataInRangesReturn): MessageAnalysis => {
  const { currentData, timeRangeStart, timeRangeEnd } = analyticsData;
  const startDate = new Date(timeRangeStart);
  const endDate = new Date(timeRangeEnd);
  const numDays = Math.ceil((endDate.getTime() - startDate.getTime()) / ONE_DAY);

  const entities = currentData?.entity?.items || [];
  // create an array with every date between startDate and endDate
  const xData = [];
  for (let i = 0; i < numDays; i += 1) {
    const date = new Date(startDate.getTime() + i * ONE_DAY);
    xData.push(date);
  }

  const sessionsValues = Array(numDays).fill(0);
  const messagesValues = Array(numDays).fill(0);
  entities.forEach((item) => {
    // find number of days between start date and item date
    const daysFromStart = Math.round((new Date(item.bucket || '').getTime() - startDate.getTime()) / ONE_DAY);
    if (daysFromStart >= 0 && daysFromStart < numDays) {
      sessionsValues[daysFromStart] += item.sessionCount;
      messagesValues[daysFromStart] += item.messageCount;
    }
  });
  return { xData, sessionsValues, messagesValues };
};

type SummaryAnalysis = {
  total: {
    value: string;
    data: {
      count: number;
      change: number | undefined;
    };
  };
  sentiment: {
    value: Sentiment;
    data: CountPercentChange;
  };
  funnel: {
    value: SofiaAnalyticsFunnelCategory;
    data: CountPercentChange;
  };
  topic: {
    value: string;
    data: {
      count: number;
      change: number | undefined;
    };
  };
  word: {
    value: string;
    data: {
      count: number;
      change: number | undefined;
    };
  };
};

export const selectSofiaSummaryAnalysis = (analyticsData: useAnalysisDataInRangesReturn): SummaryAnalysis => {
  const result: SummaryAnalysis = {
    total: {
      value: '',
      data: {
        count: 0,
        change: undefined,
      },
    },
    sentiment: {
      value: 'positive',
      data: {
        count: 0,
        percent: 0,
        change: undefined,
      },
    },
    funnel: {
      value: 'awareness',
      data: {
        count: 0,
        percent: 0,
        change: undefined,
      },
    },
    topic: {
      value: '',
      data: {
        count: 0,
        change: undefined,
      },
    },
    word: {
      value: '',
      data: {
        count: 0,
        change: undefined,
      },
    },
  };
  const sentimentAnalysis = selectSentimentAnalysis(analyticsData);
  const topSentiment = Object.entries(sentimentAnalysis).sort((a, b) => b[1].count - a[1].count)[0];
  result.sentiment = {
    value: topSentiment[0] as Sentiment,
    data: topSentiment[1],
  };
  const funnelAnalysis = selectFunnelAnalysis(analyticsData);
  const topFunnel = Object.entries(funnelAnalysis).sort((a, b) => b[1].count - a[1].count)[1]; // skip 'all'
  result.funnel = {
    value: topFunnel[0] as SofiaAnalyticsFunnelCategory,
    data: topFunnel[1],
  };
  result.total = {
    value: '',
    data: {
      count: funnelAnalysis.all.count,
      change: funnelAnalysis.all.change,
    },
  };
  const topicAnalysis = selectTopicsAnalysis(analyticsData);
  const topTopic = topicAnalysis.list[0];
  result.topic = {
    value: topTopic ? topTopic.name : '',
    data: {
      count: topTopic ? topTopic.count : 0,
      change: topTopic ? topTopic.change : undefined,
    },
  };
  const keywordAnalysis = selectWordsAnalysis(analyticsData);
  const topKeyword = keywordAnalysis.list[0];
  result.word = {
    value: topKeyword ? topKeyword.name : '',
    data: {
      count: topKeyword ? topKeyword.count : 0,
      change: topKeyword ? topKeyword.change : undefined,
    },
  };
  return result;
};
