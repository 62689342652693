import React, { useState, useContext, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { inboxAnalyticsTimeRangesAtom as timeRangesAtom } from '@atoms/inbox';
import { fetchQueryAgents, QueryAgentsData } from '@hooks/useQueryAgents';
import { fetchQueryAgentMetrics, QueryAgentMetricsData } from '@hooks/useQueryAgentMetrics';
import { Button } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { selectBusinessInfo, useMeData } from '../../../../hooks';
import { NotificationContext } from '../../../../contexts';
import { TimeRange } from '../../types';

type CsvRow = [string | number | undefined, string | number | undefined, string | number | undefined];
type CsvData = CsvRow[];

const createCsvData = (
  data: [QueryAgentMetricsData, QueryAgentsData],
  timeRange: TimeRange,
  businessName: string,
): [CsvData, string] => {
  const [metrics, agents] = data;
  const timeNow = new Date().toISOString();
  const csvData: CsvData = [
    ['Last run on:', timeNow, undefined],
    ['Account name', businessName, undefined],
    ['Start date:', timeRange.timeRangeStart, undefined],
    ['End date:', timeRange.timeRangeEnd, undefined],
    [undefined, undefined, undefined],
    ['Aggregate metrics', undefined, undefined],
    ['Average time to close', 'Average waiting time', 'Total active uers per day'],
    [metrics?.entity?.avgResolutionTime, metrics?.entity?.avgWaitTime, metrics?.entity?.activeUsers],
    [undefined, undefined, undefined],
    [undefined, undefined, undefined],
    ['Agent performance', undefined, undefined],
    ['Agent email', 'Average time to close', 'Total conversations'],
  ];
  const agentsData = agents?.entity?.items || [];
  agentsData.forEach((agent) => {
    csvData.push([agent.username, agent.avgResolutionTime, agent.conversations]);
  });
  return [csvData, `${businessName}-agents-data-${timeNow}.csv`];
};
const downloadCsv = (csvData: CsvData, filename: string) => {
  const buildCsvString = (data: CsvData): string => {
    return data.map((row) => row.join(',')).join('\n');
  };
  const csvString = buildCsvString(csvData);
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const useExportData = () => {
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessData } = useMeData({ select: selectBusinessInfo });
  const { businessId, name: businessName } = useMemo(() => {
    if (!businessData) return { businessId: '', name: '' };
    return businessData;
  }, [businessData]);
  const [disabled, setDisabled] = useState(false);
  const timeRange = useAtomValue(timeRangesAtom)[0];
  const handleDownload = async () => {
    setDisabled(true);
    return Promise.all([
      fetchQueryAgentMetrics(
        {
          'query.businessId': businessId || '',
        },
        {
          timeRangeStart: timeRange.timeRangeStart,
          timeRangeEnd: timeRange.timeRangeEnd,
        },
      ),
      fetchQueryAgents(
        {
          'query.businessId': businessId || '',
        },
        {
          timeRangeStart: timeRange.timeRangeStart,
          timeRangeEnd: timeRange.timeRangeEnd,
        },
      ),
    ])
      .catch(() => {
        notificationProvider().notify({
          surface: NotificationSurface.SNACKBAR,
          notification: {
            message: 'Export failed',
            icon: '',
            severity: NotificationSeverity.ERROR,
          },
        });
        setTimeout(() => setDisabled(false), 2000);
      })
      .then((data: void | [QueryAgentMetricsData, QueryAgentsData]) => {
        if (!data) return;
        const [csvData, filename]: [CsvData, string] = createCsvData(data, timeRange, businessName);
        downloadCsv(csvData, filename);
        setTimeout(() => setDisabled(false), 2000);
      });
  };
  return {
    disabled,
    handleDownload,
  };
};

export const ExportData = () => {
  const { disabled, handleDownload } = useExportData();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={disabled}
      sx={{ fontSize: '12px', height: '38px' }}
    >
      Download CSV
    </Button>
  );
};
