import * as _m0 from "protobufjs/minimal";
import { Int64Value, StringValue } from "../../../google/protobuf/wrappers";
export const protobufPackage = "events.zendesk.auth";
function createBaseZendeskZisOAuthClient() {
    return { id: undefined, identifier: undefined, secret: undefined };
}
export const ZendeskZisOAuthClient = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.identifier !== undefined) {
            StringValue.encode({ value: message.identifier }, writer.uint32(18).fork()).ldelim();
        }
        if (message.secret !== undefined) {
            StringValue.encode({ value: message.secret }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisOAuthClient();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.identifier = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.secret = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            identifier: isSet(object.identifier)
                ? String(object.identifier)
                : undefined,
            secret: isSet(object.secret) ? String(object.secret) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.identifier !== undefined && (obj.identifier = message.identifier);
        message.secret !== undefined && (obj.secret = message.secret);
        return obj;
    },
};
function createBaseZendeskZisOAuthClientInformation() {
    return {
        description: undefined,
        jwtPublicKey: undefined,
        zendeskOauthClient: undefined,
    };
}
export const ZendeskZisOAuthClientInformation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.description !== undefined) {
            StringValue.encode({ value: message.description }, writer.uint32(10).fork()).ldelim();
        }
        if (message.jwtPublicKey !== undefined) {
            StringValue.encode({ value: message.jwtPublicKey }, writer.uint32(18).fork()).ldelim();
        }
        if (message.zendeskOauthClient !== undefined) {
            ZendeskZisOAuthClient.encode(message.zendeskOauthClient, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisOAuthClientInformation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.description = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.jwtPublicKey = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.zendeskOauthClient = ZendeskZisOAuthClient.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            description: isSet(object.description)
                ? String(object.description)
                : undefined,
            jwtPublicKey: isSet(object.jwtPublicKey)
                ? String(object.jwtPublicKey)
                : undefined,
            zendeskOauthClient: isSet(object.zendeskOauthClient)
                ? ZendeskZisOAuthClient.fromJSON(object.zendeskOauthClient)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.description !== undefined &&
            (obj.description = message.description);
        message.jwtPublicKey !== undefined &&
            (obj.jwtPublicKey = message.jwtPublicKey);
        message.zendeskOauthClient !== undefined &&
            (obj.zendeskOauthClient = message.zendeskOauthClient
                ? ZendeskZisOAuthClient.toJSON(message.zendeskOauthClient)
                : undefined);
        return obj;
    },
};
function createBaseZendeskZisToken() {
    return { url: undefined, scopes: [], fullToken: undefined };
}
export const ZendeskZisToken = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== undefined) {
            StringValue.encode({ value: message.url }, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.scopes) {
            StringValue.encode({ value: v }, writer.uint32(18).fork()).ldelim();
        }
        if (message.fullToken !== undefined) {
            StringValue.encode({ value: message.fullToken }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisToken();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.scopes.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 3:
                    message.fullToken = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : undefined,
            scopes: Array.isArray(object === null || object === void 0 ? void 0 : object.scopes)
                ? object.scopes.map((e) => String(e))
                : [],
            fullToken: isSet(object.fullToken) ? String(object.fullToken) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        if (message.scopes) {
            obj.scopes = message.scopes.map((e) => e);
        }
        else {
            obj.scopes = [];
        }
        message.fullToken !== undefined && (obj.fullToken = message.fullToken);
        return obj;
    },
};
function createBaseZendeskZisOAuthResponse() {
    return { token: undefined };
}
export const ZendeskZisOAuthResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.token !== undefined) {
            ZendeskZisToken.encode(message.token, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskZisOAuthResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.token = ZendeskZisToken.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            token: isSet(object.token)
                ? ZendeskZisToken.fromJSON(object.token)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.token !== undefined &&
            (obj.token = message.token
                ? ZendeskZisToken.toJSON(message.token)
                : undefined);
        return obj;
    },
};
function createBaseZendeskProfile() {
    return { subdomain: undefined, accountId: undefined, adminEmail: undefined };
}
export const ZendeskProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subdomain !== undefined) {
            StringValue.encode({ value: message.subdomain }, writer.uint32(10).fork()).ldelim();
        }
        if (message.accountId !== undefined) {
            StringValue.encode({ value: message.accountId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.adminEmail !== undefined) {
            StringValue.encode({ value: message.adminEmail }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZendeskProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subdomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.accountId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.adminEmail = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subdomain: isSet(object.subdomain) ? String(object.subdomain) : undefined,
            accountId: isSet(object.accountId) ? String(object.accountId) : undefined,
            adminEmail: isSet(object.adminEmail)
                ? String(object.adminEmail)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subdomain !== undefined && (obj.subdomain = message.subdomain);
        message.accountId !== undefined && (obj.accountId = message.accountId);
        message.adminEmail !== undefined && (obj.adminEmail = message.adminEmail);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
