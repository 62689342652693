import { createContext } from 'react';
import { DtoUpdateAccessor } from '@connectlyai-sdks/data-update';
import { DelegatingNavigator } from '@connectlyai-tenets/navigation';
import { AuthedNetworkClient } from '@connectlyai-sdks/auth';
import { NotificationService } from '@connectlyai-sdks/notification';

export type FlowDIContextProps = {
  dtoUpdateAccessorProvider: () => DtoUpdateAccessor;
  navigatorProvider: () => DelegatingNavigator;
  networkClientProvider: () => AuthedNetworkClient;
  notificationServiceProvider: () => NotificationService;
};

// TODO the following is a bit hacky
// consider the solution outlined in
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FlowDIContext = createContext<FlowDIContextProps>(undefined!);
