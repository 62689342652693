import { Paths } from '@connectlyai-tenets/sdk';

export const OPT_OUT_DATA_URL = '/v1/businesses/{businessId}/query_opt_out_list';
type OptOutUrl = typeof OPT_OUT_DATA_URL;

export type OptOutDataPathParameters = Paths['profileV1'][OptOutUrl]['post']['parameters']['path'];
export type OptOutDataResponse = Paths['profileV1'][OptOutUrl]['post']['responses']['200']['schema'];
export type OptOutDataItem = NonNullable<
  NonNullable<Paths['profileV1'][OptOutUrl]['post']['responses']['200']['schema']['entity']>['optOutList']
>[0];
