import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { CustomerDIContext } from '../../context';
import { BLANK_DASH, UpdateShopifyProfileByOptions } from '../../entities';
import { selectShopifyDescription } from '../../state/customerSlice';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { searchShopifyCustomerChannels } from '../../state/thunk';
import { selectUpdateShopifyProfileState } from '../../state/updateShopifyProfileSlice';
const SEARCH_INPUT_DEBOUNCE_DELAY_IN_MS = 300;
export const ShopifyProfileComponentId = 'bf829450-11a6-45c5-915a-bbc051a82803';
function mapShopifyDescriptionToViewModel(entity) {
    if (!entity)
        return undefined;
    return {
        name: entity.name || BLANK_DASH,
        email: entity.email || BLANK_DASH,
        shopifyId: entity.shopifyId || BLANK_DASH,
        totalSpent: entity.totalSpent || BLANK_DASH,
        totalOrders: entity.totalOrders || BLANK_DASH,
        lastOrder: entity.lastOrder || BLANK_DASH,
    };
}
const updateShopifyProfileByOptions = Object.values(UpdateShopifyProfileByOptions)
    .map((option) => ({
    key: option.key,
    label: option.optionLabel,
}));
const ShopifyProfile = (_props) => {
    const di = useContext(CustomerDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const [updateIsLoading, setUpdateIsLoading] = useState(false);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ShopifyProfileComponentId);
    const shopifyDescription = useFeatureSelector(selectShopifyDescription);
    const { searchConfirmActive, isActive: updateIsActive, isError: updateIsError, searchInput, searchHelperText, updateShopifyProfileByOption, } = useFeatureSelector(selectUpdateShopifyProfileState);
    const dispatch = useFeatureDispatch();
    const debouncedSearch = useCallback(debounce(async (input) => {
        if (updateShopifyProfileByOption && input !== '') {
            dispatch(searchShopifyCustomerChannels({
                di,
                setUpdateIsLoading,
            }));
        }
    }, SEARCH_INPUT_DEBOUNCE_DELAY_IN_MS), [di.querySearchShopifyCustomerChannelsProvider, updateShopifyProfileByOption]);
    useEffect(() => {
        debouncedSearch(searchInput);
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch, searchInput]);
    const updateShopifyProfileByOptionValue = updateShopifyProfileByOption !== null
        ? UpdateShopifyProfileByOptions[updateShopifyProfileByOption]
        : {
            key: '',
            inputLabel: '',
            optionLabel: '',
        };
    const shopifyProfileViewModel = {
        description: mapShopifyDescriptionToViewModel(shopifyDescription),
        updateIsActive,
        updateIsError,
        updateIsLoading,
        updateShopifyProfileByOptions,
        update: {
            searchConfirmActive,
            searchInputLabel: updateShopifyProfileByOptionValue.inputLabel,
            searchInput,
            searchHelperText,
        },
    };
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { shopifyProfileViewModel: shopifyProfileViewModel }) });
};
export default ShopifyProfile;
