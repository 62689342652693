import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { BROADCAST_CATEGORY_AUDIENCES, BROADCAST_CATEGORY_MESSAGE_TEMPLATES, BROADCAST_CATEGORY_WA_CAMPAIGN, BroadcastCategoryRouteId, } from '@connectlyai-sdks/inbox-navigation';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import { BroadcastDIContext } from '../../context';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectMenuItems, selectSelectedMenuItemKey, setMenuItems, setSelectedMenuItem } from '../../state/menuSlice';
const includeAudienceBuilder = getFlagByName('ffEnableOldAudience');
const MENU_ITEMS_INITIAL = [
    {
        key: BROADCAST_CATEGORY_WA_CAMPAIGN,
        title: 'WhatsApp Broadcast',
        url: '',
    },
    {
        key: BROADCAST_CATEGORY_MESSAGE_TEMPLATES,
        title: 'Message Templates',
        url: '',
    },
];
if (includeAudienceBuilder) {
    MENU_ITEMS_INITIAL.push({
        key: BROADCAST_CATEGORY_AUDIENCES,
        title: 'Saved Audiences',
        url: '',
    });
}
export const BroadcastSidebarComponentId = 'acdbc3d3-ed1f-4eaa-8fa5-ee9506da0c3f';
function mapMenuItemToViewModel(item) {
    return {
        key: item.key,
        title: item.title,
        url: item.url,
    };
}
const BroadcastSidebar = (_props) => {
    const di = useContext(BroadcastDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    const match = useRouteMatch(di.navigatorProvider().generateGlobalRouteId(BroadcastCategoryRouteId));
    useEffect(() => {
        dispatch(setMenuItems(MENU_ITEMS_INITIAL));
    }, []);
    useEffect(() => {
        if (match) {
            dispatch(setSelectedMenuItem(match.params.categoryId));
        }
    }, [match === null || match === void 0 ? void 0 : match.url]);
    const menuItems = useFeatureSelector(selectMenuItems).map(mapMenuItemToViewModel);
    const selectedMenuItemKey = useFeatureSelector(selectSelectedMenuItemKey);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(BroadcastSidebarComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { items: menuItems, selectedMenuItemKey: selectedMenuItemKey }) });
};
export default BroadcastSidebar;
