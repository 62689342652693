import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { STANDARD_NOTIFICATIONS } from './constants';
import { useStandardNotificationsProps, StandardNotifications } from './types';
import { NotificationContext } from '../../contexts';

export const useStandardNotifications = ({
  successMessage = STANDARD_NOTIFICATIONS.success,
  errorMessage = STANDARD_NOTIFICATIONS.error,
  isTranslation = false,
}: useStandardNotificationsProps): StandardNotifications => {
  const { notificationProvider } = useContext(NotificationContext);
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const messages = useMemo(() => {
    if (isTranslation) {
      return {
        success: t(successMessage),
        error: t(errorMessage),
      };
    }
    return {
      success: successMessage,
      error: errorMessage,
    };
  }, [errorMessage, isTranslation, successMessage, t]);
  const notifyError = useCallback(() => {
    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: messages.error,
        icon: '',
        severity: NotificationSeverity.ERROR,
      },
    });
  }, [messages.error, notificationProvider]);
  const notifySuccess = useCallback(() => {
    notificationProvider().notify({
      surface: NotificationSurface.SNACKBAR,
      notification: {
        message: messages.success,
        icon: '',
        severity: NotificationSeverity.SUCCESS,
      },
    });
  }, [messages.success, notificationProvider]);
  return {
    notifyError,
    notifySuccess,
  };
};
