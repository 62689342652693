import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  Label,
  MenuItem,
  TextField,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShopifyDiscountType } from '../../hooks/useSettingsData/types';
import { isNumber } from '../../presentation/preview/utils';
import { DEFAULT_DISCOUNT, SHOPIFY_DISCOUNT_URL } from './constants';
import { AbandonedCartEditDiscountProps } from './types';

export const AbandonedCartEditDiscount = ({ discount, onSave, onClose }: AbandonedCartEditDiscountProps) => {
  const discountCodeRef = useRef<HTMLInputElement>(null);
  const discountValueRef = useRef<HTMLInputElement>(null);
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [discountValueError, setDiscountValueError] = useState('');
  const [selectedType, setSelectedType] = useState<ShopifyDiscountType>(discount.discountType as ShopifyDiscountType);
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.cartAbandonmentDiscount' });

  const onRemoveCode = () => {
    onSave(DEFAULT_DISCOUNT);
    onClose();
  };

  const onSaveClick = () => {
    if (discountCodeRef.current && discountValueRef.current) {
      if (
        isNumber(discountValueRef.current.value) &&
        discountCodeRef.current.value &&
        discountValueRef.current.value &&
        discountValueRef.current.value !== '0'
      ) {
        setDiscountCodeError('');
        setDiscountValueError('');
        onSave({
          discountCode: discountCodeRef.current.value,
          discountType: selectedType,
          discountValue: Number(discountValueRef.current.value),
        });
        onClose();
      } else {
        if (!discountCodeRef.current.value) {
          setDiscountCodeError(t('errorDiscountCode'));
        }
        if (!discountValueRef.current.value) {
          setDiscountValueError(t('errorDiscountValueEmpty'));
        }
        if (discountValueRef.current.value === '0' || !isNumber(discountValueRef.current.value)) {
          setDiscountValueError(t('errorDiscountValue'));
        }
      }
    }
  };

  const isPercent = selectedType === 'DISCOUNT_TYPE_PERCENTAGE';

  return (
    <>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 0 }}>
        <Label variant="body1" sx={{ color: theme.palette.text.secondary, margin: 1, fontSize: '14px' }}>
          {t('hint')}
        </Label>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            inputRef={discountCodeRef}
            id="discount-code"
            label={t('discountCode')}
            error={!!discountCodeError}
            helperText={discountCodeError}
            defaultValue={discount.discountCode}
          />
          <Grid container spacing={2} columns={2}>
            <Grid item xs>
              <TextField
                select
                value={selectedType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedType(e.target.value as ShopifyDiscountType)
                }
                label={t('discountType')}
                variant="outlined"
                sx={{ width: '100%' }}
              >
                <MenuItem value="DISCOUNT_TYPE_FIXED_AMOUNT">{t('fixedAmount')}</MenuItem>
                <MenuItem value="DISCOUNT_TYPE_PERCENTAGE">{t('percentage')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                required
                inputRef={discountValueRef}
                id="discount-value"
                label={t('discountInformation')}
                type="number"
                defaultValue={discount.discountValue}
                InputProps={{
                  inputProps: {
                    min: 0,
                    allowNegative: false,
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  },
                  startAdornment: !isPercent && <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: isPercent && <InputAdornment position="end">%</InputAdornment>,
                }}
                error={!!discountValueError}
                helperText={discountValueError}
                sx={{
                  width: '100%',
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '& input[type="number"]': {
                    '-moz-appearance': 'textfield',
                  },
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
        <Box>
          <Button variant="outlined" onClick={() => window.open(SHOPIFY_DISCOUNT_URL, '_blank')}>
            {t('manageDiscountCodes')}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 3 }}>
        <Button onClick={onRemoveCode}>{t('removeDiscount')}</Button>
        <Button variant="contained" onClick={onSaveClick}>
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};
