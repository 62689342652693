import { Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const RATE_LIMIT_QUERY_URL = '/v1/businesses/{input.businessId}/query_by_resource/ratelimit';
type RateLimitQueryUrl = typeof RATE_LIMIT_QUERY_URL;

export type RateLimitQueryPathParams = Paths['profileV1'][RateLimitQueryUrl]['post']['parameters']['path'];

export type RateLimitQueryBodyParams = Paths['profileV1'][RateLimitQueryUrl]['post']['parameters']['body'];

export type RateLimitQueryParams = { businessId: string } & RateLimitQueryBodyParams['input'];

export type RateLimitQueryResponse = Paths['profileV1'][RateLimitQueryUrl]['post']['responses']['200']['schema'];

export type RateLimitQueryOptions<TData = RateLimitQueryResponse> = Omit<
  UseQueryOptions<RateLimitQueryResponse, unknown, TData, readonly ['rate-limit']>,
  'initialData' | 'queryFn' | 'queryKey'
>;
