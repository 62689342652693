import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.graphapi.v10_0.messenger";
export var SenderAction;
(function (SenderAction) {
    SenderAction[SenderAction["SENDER_ACTION_UNSPECIFIED"] = 0] = "SENDER_ACTION_UNSPECIFIED";
    SenderAction[SenderAction["SENDER_ACTION_TYPING_ON"] = 1] = "SENDER_ACTION_TYPING_ON";
    SenderAction[SenderAction["SENDER_ACTION_TYPING_OFF"] = 2] = "SENDER_ACTION_TYPING_OFF";
    SenderAction[SenderAction["SENDER_ACTION_MARK_SEEN"] = 3] = "SENDER_ACTION_MARK_SEEN";
    SenderAction[SenderAction["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SenderAction || (SenderAction = {}));
export function senderActionFromJSON(object) {
    switch (object) {
        case 0:
        case "SENDER_ACTION_UNSPECIFIED":
            return SenderAction.SENDER_ACTION_UNSPECIFIED;
        case 1:
        case "SENDER_ACTION_TYPING_ON":
            return SenderAction.SENDER_ACTION_TYPING_ON;
        case 2:
        case "SENDER_ACTION_TYPING_OFF":
            return SenderAction.SENDER_ACTION_TYPING_OFF;
        case 3:
        case "SENDER_ACTION_MARK_SEEN":
            return SenderAction.SENDER_ACTION_MARK_SEEN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SenderAction.UNRECOGNIZED;
    }
}
export function senderActionToJSON(object) {
    switch (object) {
        case SenderAction.SENDER_ACTION_UNSPECIFIED:
            return "SENDER_ACTION_UNSPECIFIED";
        case SenderAction.SENDER_ACTION_TYPING_ON:
            return "SENDER_ACTION_TYPING_ON";
        case SenderAction.SENDER_ACTION_TYPING_OFF:
            return "SENDER_ACTION_TYPING_OFF";
        case SenderAction.SENDER_ACTION_MARK_SEEN:
            return "SENDER_ACTION_MARK_SEEN";
        case SenderAction.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MessagingType;
(function (MessagingType) {
    MessagingType[MessagingType["MESSAGING_TYPE_UNSPECIFIED"] = 0] = "MESSAGING_TYPE_UNSPECIFIED";
    MessagingType[MessagingType["MESSAGING_TYPE_RESPONSE"] = 1] = "MESSAGING_TYPE_RESPONSE";
    MessagingType[MessagingType["MESSAGING_TYPE_UPDATE"] = 2] = "MESSAGING_TYPE_UPDATE";
    MessagingType[MessagingType["MESSAGING_TYPE_MESSAGE_TAG"] = 3] = "MESSAGING_TYPE_MESSAGE_TAG";
    MessagingType[MessagingType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MessagingType || (MessagingType = {}));
export function messagingTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "MESSAGING_TYPE_UNSPECIFIED":
            return MessagingType.MESSAGING_TYPE_UNSPECIFIED;
        case 1:
        case "MESSAGING_TYPE_RESPONSE":
            return MessagingType.MESSAGING_TYPE_RESPONSE;
        case 2:
        case "MESSAGING_TYPE_UPDATE":
            return MessagingType.MESSAGING_TYPE_UPDATE;
        case 3:
        case "MESSAGING_TYPE_MESSAGE_TAG":
            return MessagingType.MESSAGING_TYPE_MESSAGE_TAG;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MessagingType.UNRECOGNIZED;
    }
}
export function messagingTypeToJSON(object) {
    switch (object) {
        case MessagingType.MESSAGING_TYPE_UNSPECIFIED:
            return "MESSAGING_TYPE_UNSPECIFIED";
        case MessagingType.MESSAGING_TYPE_RESPONSE:
            return "MESSAGING_TYPE_RESPONSE";
        case MessagingType.MESSAGING_TYPE_UPDATE:
            return "MESSAGING_TYPE_UPDATE";
        case MessagingType.MESSAGING_TYPE_MESSAGE_TAG:
            return "MESSAGING_TYPE_MESSAGE_TAG";
        case MessagingType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["NOTIFICATION_TYPE_UNSPECIFIED"] = 0] = "NOTIFICATION_TYPE_UNSPECIFIED";
    NotificationType[NotificationType["NOTIFICATION_TYPE_REGULAR"] = 1] = "NOTIFICATION_TYPE_REGULAR";
    NotificationType[NotificationType["NOTIFICATION_TYPE_SILENT_PUSH"] = 2] = "NOTIFICATION_TYPE_SILENT_PUSH";
    NotificationType[NotificationType["NOTIFICATION_TYPE_NO_PUSH"] = 3] = "NOTIFICATION_TYPE_NO_PUSH";
    NotificationType[NotificationType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(NotificationType || (NotificationType = {}));
export function notificationTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "NOTIFICATION_TYPE_UNSPECIFIED":
            return NotificationType.NOTIFICATION_TYPE_UNSPECIFIED;
        case 1:
        case "NOTIFICATION_TYPE_REGULAR":
            return NotificationType.NOTIFICATION_TYPE_REGULAR;
        case 2:
        case "NOTIFICATION_TYPE_SILENT_PUSH":
            return NotificationType.NOTIFICATION_TYPE_SILENT_PUSH;
        case 3:
        case "NOTIFICATION_TYPE_NO_PUSH":
            return NotificationType.NOTIFICATION_TYPE_NO_PUSH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return NotificationType.UNRECOGNIZED;
    }
}
export function notificationTypeToJSON(object) {
    switch (object) {
        case NotificationType.NOTIFICATION_TYPE_UNSPECIFIED:
            return "NOTIFICATION_TYPE_UNSPECIFIED";
        case NotificationType.NOTIFICATION_TYPE_REGULAR:
            return "NOTIFICATION_TYPE_REGULAR";
        case NotificationType.NOTIFICATION_TYPE_SILENT_PUSH:
            return "NOTIFICATION_TYPE_SILENT_PUSH";
        case NotificationType.NOTIFICATION_TYPE_NO_PUSH:
            return "NOTIFICATION_TYPE_NO_PUSH";
        case NotificationType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var AttachmentPayload_Addendum_Type;
(function (AttachmentPayload_Addendum_Type) {
    AttachmentPayload_Addendum_Type[AttachmentPayload_Addendum_Type["unspecified"] = 0] = "unspecified";
    AttachmentPayload_Addendum_Type[AttachmentPayload_Addendum_Type["generic"] = 1] = "generic";
    AttachmentPayload_Addendum_Type[AttachmentPayload_Addendum_Type["button"] = 2] = "button";
    AttachmentPayload_Addendum_Type[AttachmentPayload_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(AttachmentPayload_Addendum_Type || (AttachmentPayload_Addendum_Type = {}));
export function attachmentPayload_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return AttachmentPayload_Addendum_Type.unspecified;
        case 1:
        case "generic":
            return AttachmentPayload_Addendum_Type.generic;
        case 2:
        case "button":
            return AttachmentPayload_Addendum_Type.button;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AttachmentPayload_Addendum_Type.UNRECOGNIZED;
    }
}
export function attachmentPayload_Addendum_TypeToJSON(object) {
    switch (object) {
        case AttachmentPayload_Addendum_Type.unspecified:
            return "unspecified";
        case AttachmentPayload_Addendum_Type.generic:
            return "generic";
        case AttachmentPayload_Addendum_Type.button:
            return "button";
        case AttachmentPayload_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Button_Addendum_Type;
(function (Button_Addendum_Type) {
    Button_Addendum_Type[Button_Addendum_Type["unspecified"] = 0] = "unspecified";
    Button_Addendum_Type[Button_Addendum_Type["web_url"] = 1] = "web_url";
    Button_Addendum_Type[Button_Addendum_Type["postback"] = 2] = "postback";
    Button_Addendum_Type[Button_Addendum_Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Button_Addendum_Type || (Button_Addendum_Type = {}));
export function button_Addendum_TypeFromJSON(object) {
    switch (object) {
        case 0:
        case "unspecified":
            return Button_Addendum_Type.unspecified;
        case 1:
        case "web_url":
            return Button_Addendum_Type.web_url;
        case 2:
        case "postback":
            return Button_Addendum_Type.postback;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Button_Addendum_Type.UNRECOGNIZED;
    }
}
export function button_Addendum_TypeToJSON(object) {
    switch (object) {
        case Button_Addendum_Type.unspecified:
            return "unspecified";
        case Button_Addendum_Type.web_url:
            return "web_url";
        case Button_Addendum_Type.postback:
            return "postback";
        case Button_Addendum_Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseSendEvent() {
    return {
        messagingType: "",
        recipient: undefined,
        message: undefined,
        senderAction: 0,
        notificationType: 0,
        tag: "",
    };
}
export const SendEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.messagingType !== "") {
            writer.uint32(10).string(message.messagingType);
        }
        if (message.recipient !== undefined) {
            Recipient.encode(message.recipient, writer.uint32(18).fork()).ldelim();
        }
        if (message.message !== undefined) {
            Message.encode(message.message, writer.uint32(26).fork()).ldelim();
        }
        if (message.senderAction !== 0) {
            writer.uint32(32).int32(message.senderAction);
        }
        if (message.notificationType !== 0) {
            writer.uint32(40).int32(message.notificationType);
        }
        if (message.tag !== "") {
            writer.uint32(50).string(message.tag);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSendEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messagingType = reader.string();
                    break;
                case 2:
                    message.recipient = Recipient.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.message = Message.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.senderAction = reader.int32();
                    break;
                case 5:
                    message.notificationType = reader.int32();
                    break;
                case 6:
                    message.tag = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            messagingType: isSet(object.messagingType)
                ? String(object.messagingType)
                : "",
            recipient: isSet(object.recipient)
                ? Recipient.fromJSON(object.recipient)
                : undefined,
            message: isSet(object.message)
                ? Message.fromJSON(object.message)
                : undefined,
            senderAction: isSet(object.senderAction)
                ? senderActionFromJSON(object.senderAction)
                : 0,
            notificationType: isSet(object.notificationType)
                ? notificationTypeFromJSON(object.notificationType)
                : 0,
            tag: isSet(object.tag) ? String(object.tag) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.messagingType !== undefined &&
            (obj.messagingType = message.messagingType);
        message.recipient !== undefined &&
            (obj.recipient = message.recipient
                ? Recipient.toJSON(message.recipient)
                : undefined);
        message.message !== undefined &&
            (obj.message = message.message
                ? Message.toJSON(message.message)
                : undefined);
        message.senderAction !== undefined &&
            (obj.senderAction = senderActionToJSON(message.senderAction));
        message.notificationType !== undefined &&
            (obj.notificationType = notificationTypeToJSON(message.notificationType));
        message.tag !== undefined && (obj.tag = message.tag);
        return obj;
    },
};
function createBaseMessage() {
    return { dataOneof: undefined, metadata: "" };
}
export const Message = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text") {
            writer.uint32(10).string(message.dataOneof.text);
        }
        if (((_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.$case) === "attachment") {
            Attachment.encode(message.dataOneof.attachment, writer.uint32(26).fork()).ldelim();
        }
        if (message.metadata !== "") {
            writer.uint32(18).string(message.metadata);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.dataOneof = { $case: "text", text: reader.string() };
                    break;
                case 3:
                    message.dataOneof = {
                        $case: "attachment",
                        attachment: Attachment.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.metadata = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            dataOneof: isSet(object.text)
                ? { $case: "text", text: String(object.text) }
                : isSet(object.attachment)
                    ? {
                        $case: "attachment",
                        attachment: Attachment.fromJSON(object.attachment),
                    }
                    : undefined,
            metadata: isSet(object.metadata) ? String(object.metadata) : "",
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e;
        const obj = {};
        ((_a = message.dataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "text" && (obj.text = (_b = message.dataOneof) === null || _b === void 0 ? void 0 : _b.text);
        ((_c = message.dataOneof) === null || _c === void 0 ? void 0 : _c.$case) === "attachment" &&
            (obj.attachment = ((_d = message.dataOneof) === null || _d === void 0 ? void 0 : _d.attachment)
                ? Attachment.toJSON((_e = message.dataOneof) === null || _e === void 0 ? void 0 : _e.attachment)
                : undefined);
        message.metadata !== undefined && (obj.metadata = message.metadata);
        return obj;
    },
};
function createBaseAttachment() {
    return { type: "", payload: undefined };
}
export const Attachment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        if (message.payload !== undefined) {
            AttachmentPayload.encode(message.payload, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAttachment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.payload = AttachmentPayload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            payload: isSet(object.payload)
                ? AttachmentPayload.fromJSON(object.payload)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        message.payload !== undefined &&
            (obj.payload = message.payload
                ? AttachmentPayload.toJSON(message.payload)
                : undefined);
        return obj;
    },
};
function createBaseAttachmentPayload() {
    return { url: "", templateType: 0, elements: [] };
}
export const AttachmentPayload = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.templateType !== 0) {
            writer.uint32(16).int32(message.templateType);
        }
        for (const v of message.elements) {
            Element.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAttachmentPayload();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.templateType = reader.int32();
                    break;
                case 3:
                    message.elements.push(Element.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? String(object.url) : "",
            templateType: isSet(object.templateType)
                ? attachmentPayload_Addendum_TypeFromJSON(object.templateType)
                : 0,
            elements: Array.isArray(object === null || object === void 0 ? void 0 : object.elements)
                ? object.elements.map((e) => Element.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.url !== undefined && (obj.url = message.url);
        message.templateType !== undefined &&
            (obj.templateType = attachmentPayload_Addendum_TypeToJSON(message.templateType));
        if (message.elements) {
            obj.elements = message.elements.map((e) => e ? Element.toJSON(e) : undefined);
        }
        else {
            obj.elements = [];
        }
        return obj;
    },
};
function createBaseAttachmentPayload_Addendum() {
    return {};
}
export const AttachmentPayload_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAttachmentPayload_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function createBaseRecipient() {
    return { id: "", userRef: "", postId: "", commentId: "" };
}
export const Recipient = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.userRef !== "") {
            writer.uint32(18).string(message.userRef);
        }
        if (message.postId !== "") {
            writer.uint32(26).string(message.postId);
        }
        if (message.commentId !== "") {
            writer.uint32(34).string(message.commentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecipient();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userRef = reader.string();
                    break;
                case 3:
                    message.postId = reader.string();
                    break;
                case 4:
                    message.commentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            userRef: isSet(object.userRef) ? String(object.userRef) : "",
            postId: isSet(object.postId) ? String(object.postId) : "",
            commentId: isSet(object.commentId) ? String(object.commentId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.userRef !== undefined && (obj.userRef = message.userRef);
        message.postId !== undefined && (obj.postId = message.postId);
        message.commentId !== undefined && (obj.commentId = message.commentId);
        return obj;
    },
};
function createBaseResponse() {
    return { recipientId: "", messageId: "" };
}
export const Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.recipientId !== "") {
            writer.uint32(10).string(message.recipientId);
        }
        if (message.messageId !== "") {
            writer.uint32(18).string(message.messageId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.recipientId = reader.string();
                    break;
                case 2:
                    message.messageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            recipientId: isSet(object.recipientId) ? String(object.recipientId) : "",
            messageId: isSet(object.messageId) ? String(object.messageId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.recipientId !== undefined &&
            (obj.recipientId = message.recipientId);
        message.messageId !== undefined && (obj.messageId = message.messageId);
        return obj;
    },
};
function createBaseElement() {
    return {
        title: "",
        subtitle: "",
        imageUrl: "",
        defaultAction: undefined,
        buttons: [],
    };
}
export const Element = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.subtitle !== "") {
            writer.uint32(18).string(message.subtitle);
        }
        if (message.imageUrl !== "") {
            writer.uint32(26).string(message.imageUrl);
        }
        if (message.defaultAction !== undefined) {
            DefaultAction.encode(message.defaultAction, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.buttons) {
            Button.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseElement();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.subtitle = reader.string();
                    break;
                case 3:
                    message.imageUrl = reader.string();
                    break;
                case 4:
                    message.defaultAction = DefaultAction.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.buttons.push(Button.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
            defaultAction: isSet(object.defaultAction)
                ? DefaultAction.fromJSON(object.defaultAction)
                : undefined,
            buttons: Array.isArray(object === null || object === void 0 ? void 0 : object.buttons)
                ? object.buttons.map((e) => Button.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.subtitle !== undefined && (obj.subtitle = message.subtitle);
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        message.defaultAction !== undefined &&
            (obj.defaultAction = message.defaultAction
                ? DefaultAction.toJSON(message.defaultAction)
                : undefined);
        if (message.buttons) {
            obj.buttons = message.buttons.map((e) => e ? Button.toJSON(e) : undefined);
        }
        else {
            obj.buttons = [];
        }
        return obj;
    },
};
function createBaseDefaultAction() {
    return { type: "", url: "" };
}
export const DefaultAction = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDefaultAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            url: isSet(object.url) ? String(object.url) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        message.url !== undefined && (obj.url = message.url);
        return obj;
    },
};
function createBaseButton() {
    return { type: 0, url: "", title: "", payload: "" };
}
export const Button = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        if (message.title !== "") {
            writer.uint32(26).string(message.title);
        }
        if (message.payload !== "") {
            writer.uint32(34).string(message.payload);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButton();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                case 4:
                    message.payload = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? button_Addendum_TypeFromJSON(object.type) : 0,
            url: isSet(object.url) ? String(object.url) : "",
            title: isSet(object.title) ? String(object.title) : "",
            payload: isSet(object.payload) ? String(object.payload) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = button_Addendum_TypeToJSON(message.type));
        message.url !== undefined && (obj.url = message.url);
        message.title !== undefined && (obj.title = message.title);
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
function createBaseButton_Addendum() {
    return {};
}
export const Button_Addendum = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButton_Addendum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
