import React, { useContext } from 'react';
import {
  Box,
  Button,
  CampaignIcon,
  Chip,
  ConnectlyCard,
  Label,
  PeopleIcon,
  TouchAppIcon,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import i18next from 'i18next';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { applyHook } from '../../utils';
import { useMeData, selectBusinessId, useInstallIntegrationMutation, useFeatureFlag } from '../../hooks';
import { RecommendationActivationHookProps, RecommendationActivationProps } from './types';
import { useRecommendedIntegrationStatus } from '../../hooks/useRecommendedIntegrationStatus';
import { NotificationContext } from '../../contexts';
import { useMutationRecommenderOnboard } from '../../hooks/useMutationRecommenderOnboard';

const useRecommendationActivation = ({
  integrationParameters,
}: RecommendationActivationHookProps): Partial<RecommendationActivationProps> => {
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { mutate: mutateInstallIntegration, isLoading: isLoadingInstallIntegration } = useInstallIntegrationMutation();
  const { mutate: mutateOnboardRecommender, isLoading: isLoadingRecommenderOnboard } = useMutationRecommenderOnboard({
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error ingesting products. Please try again',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully began product ingestion.', // TODO: Update this message
          icon: '',
          severity: NotificationSeverity.SUCCESS,
        },
      });
    },
  });

  const onActivate = async () => {
    if (businessId) {
      await mutateInstallIntegration({
        businessId,
        integrationType: 'INTEGRATION_TYPE_SHOPIFY_RECOMMENDER',
        integrationParameters: {
          shopifyRecommenderParameters: {
            shopifyBusinessChannelId: '716cfac0-7af4-4bb2-96c9-f9d27c28f734', // TODO: [jason] Remove hard coded value for prod
            waBusinessChannelId: '71c9537f-7f0c-42aa-8ec0-07b4a4d900fe', // TODO: [jason] Remove hard coded value for prod
            etaParameters: {
              language: i18next.language.slice(0, 2),
              headerImageEnabled: true, // set this always to true
              withCheckout: false, // there are two types of messages depending on this boolean
              linkTrackingEnabled: true, // set this always to true
            },
          },
        },
      });
      await mutateOnboardRecommender({
        businessId,
        input: {
          businessChannelId: integrationParameters?.shopifyBusinessChannelId ?? '',
        },
      });
    }
  };

  const onSend = () => {
    // TODO: Call send campaign
  };
  return {
    businessId,
    isInstallingIntegration: isLoadingInstallIntegration || isLoadingRecommenderOnboard,
    onActivate,
    onSend,
  };
};

const ActivationCard = ({
  businessId,
  integrationId,
  integrationParameters,
  isInstallingIntegration,
  onActivate,
  onSend,
}: RecommendationActivationProps) => {
  const params = integrationParameters?.etaParameters;
  const language = params?.language;
  const theme = useTheme();
  const { data: status } = useRecommendedIntegrationStatus({ businessId, integrationId: integrationId ?? '' });
  const activationState = status?.state;

  return (
    <ConnectlyCard
      title="Re-engagement campaign: product recommendation"
      media={{
        children: (
          <Box>
            <Chip color="primary" sx={{ background: '#825DE1' }} label="Recommended Campaign" />
          </Box>
        ),
      }}
      icon={
        <CampaignIcon
          style={{
            height: theme.spacing(5.4),
            width: theme.spacing(5.4),
          }}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <Box
            sx={{
              p: 2,
              minWidth: 200,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <PeopleIcon width={8} height={8} />
              <Label sx={{ fontSize: 14, fontWeight: 500 }}>Estimated total recipients</Label>
            </Box>
            <Label sx={{ fontSize: 22, fontWeight: 400 }}>230-300</Label>
          </Box>
          <Box
            sx={{
              p: 2,
              minWidth: 200,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <TouchAppIcon width={8} height={10} />
              <Label sx={{ fontSize: 14, fontWeight: 500 }}>Estimated engagement rate</Label>
            </Box>
            <Label sx={{ fontSize: 22, fontWeight: 400 }}>24%</Label>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: '10px',
            p: 2,
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Label variant="h6">Who to send?</Label>
          <Label variant="body1">{`Previous customers who haven't made purchases in the last 2 weeks.`}</Label>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          alt=""
          component="img"
          draggable={false}
          sx={{
            objectFit: 'cover',
            objectPosition: 'top',
            width: 316,
            height: 438,
          }}
          src={`${process.env.PUBLIC_URL}/assets/welcome/recommended_campaign_message_${language}.png`}
        />
      </Box>
      <Box>
        {activationState === 'INTEGRATION_STATE_ACTIVE' ? (
          <Button variant="contained" onClick={onSend}>
            Send Campaign
          </Button>
        ) : (
          <Button variant="contained" onClick={onActivate} disabled={isInstallingIntegration}>
            Activate Campaign
          </Button>
        )}
      </Box>
    </ConnectlyCard>
  );
};

const ActivationCardPresentation = (props: RecommendationActivationProps) => {
  if (!props.businessId) {
    return null;
  }

  return <ActivationCard {...props} />;
};
export const RecommendationActivation = applyHook(ActivationCardPresentation, useRecommendationActivation);
