import { useQuery } from '@tanstack/react-query';
import { api } from 'src/api';
import { QUERY_CLIENT_KEYS, QueryClientKeysOptions } from './types';

export const useQueryClientKeys = ({ businessId, ...options }: { businessId: string } & QueryClientKeysOptions) => {
  return useQuery(
    [businessId],
    () =>
      api.aiV1(QUERY_CLIENT_KEYS, 'get', {
        path: { 'input.businessId': businessId },
      }),
    options,
  );
};
