import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { tagIsVisibleInInbox } from '@connectlyai-sdks/data-business';
import { mapChannelTypeToIconKind } from '@connectlyai-sdks/data-rooms';
import { RoomStatus } from '@connectlyai-idl/models/dist/models/common/models';
import { ChatRoomDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectAssignedAgent, selectDescription, selectRoomStatus, selectRoomTags } from '../../state/roomSlice';
import { selectAgents, selectTags } from '../../state/businessSlice';
import { selectUser } from '../../state/userSlice';
export const ActionBarComponentId = 'fb214ab4-1e67-45f9-b1f6-3064c64aadb9';
const AVAILABLE_ROOM_STATUS_LIST = [
    RoomStatus.ROOM_STATUS_OPEN,
    RoomStatus.ROOM_STATUS_IN_PROGRESS,
    RoomStatus.ROOM_STATUS_CLOSED,
];
function mapTagToViewModel(tag) {
    var _a, _b, _c, _d;
    const color = (_b = (_a = tag.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.v1.color;
    const validateColor = color === null || color === void 0 ? void 0 : color.match('^#(?:[0-9a-fA-F]{3}){1,2}$');
    return {
        id: tag.id,
        name: ((_d = (_c = tag.config) === null || _c === void 0 ? void 0 : _c.configOneof) === null || _d === void 0 ? void 0 : _d.v1.name) || '',
        color: validateColor ? color || '#FFF' : '#FFF',
    };
}
function mapToChannelIcon(entity) {
    var _a;
    const channelType = (_a = entity.lastChannel) === null || _a === void 0 ? void 0 : _a.channelType;
    if (channelType === undefined) {
        return undefined;
    }
    return mapChannelTypeToIconKind(channelType);
}
function mapAgentToViewModel(entity) {
    if (entity === undefined || entity === null) {
        return entity;
    }
    return {
        id: entity.id,
        name: `${entity.firstName} ${entity.lastName}`,
        avatarUrl: entity.profilePicUrl,
        username: entity.username,
    };
}
function mapAssignedAgentToViewModel(entity, businessAgents) {
    if (entity === undefined || entity === null) {
        return entity;
    }
    return (businessAgents.find((val) => val.id === entity.id) || {
        id: entity.id,
        name: `${entity.firstName} ${entity.lastName}`,
        avatarUrl: entity.profilePicUrl,
        username: entity.username,
    });
}
function mapRoomStatusToViewModel(entity) {
    switch (entity) {
        case RoomStatus.ROOM_STATUS_OPEN:
            return {
                id: '1',
                status: RoomStatus.ROOM_STATUS_OPEN,
                displayStatus: 'Open',
                color: '#EF5350',
            };
        case RoomStatus.ROOM_STATUS_IN_PROGRESS:
            return {
                id: '2',
                status: RoomStatus.ROOM_STATUS_IN_PROGRESS,
                displayStatus: 'In Progress',
                color: '#007DFF',
            };
        case RoomStatus.ROOM_STATUS_CLOSED:
            return {
                id: '3',
                status: RoomStatus.ROOM_STATUS_CLOSED,
                displayStatus: 'Closed',
                color: '#616161',
            };
        case RoomStatus.ROOM_STATUS_CUSTOM:
            return {
                id: '4',
                status: RoomStatus.ROOM_STATUS_CUSTOM,
                displayStatus: 'Custom',
                color: '#FFFFFF',
            };
        default:
            return {
                id: '1',
                status: RoomStatus.ROOM_STATUS_UNSPECIFIED,
                displayStatus: '',
                color: '#FFFFFF',
            };
    }
}
function mapDescriptionToViewModel(entity) {
    return {
        id: entity.id,
        customerId: entity.customerId,
        subject: entity.subject,
        avatarUrl: entity.avatarUrl,
        channelIconKind: mapToChannelIcon(entity),
    };
}
const ActionBar = (_props) => {
    var _a;
    const di = useContext(ChatRoomDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const descriptionViewModel = mapDescriptionToViewModel(useFeatureSelector(selectDescription));
    const businessAgents = useFeatureSelector(selectAgents).map(mapAgentToViewModel);
    const assignedAgentViewModel = mapAssignedAgentToViewModel(useFeatureSelector(selectAssignedAgent), businessAgents);
    const agentId = useFeatureSelector(selectUser);
    const assignedRoomStatus = mapRoomStatusToViewModel(useFeatureSelector(selectRoomStatus));
    const roomStatuses = useMemo(() => AVAILABLE_ROOM_STATUS_LIST.map(mapRoomStatusToViewModel), []);
    const businessTagsById = useFeatureSelector(selectTags);
    const businessTags = useMemo(() => Object.keys(businessTagsById)
        .map((val) => businessTagsById[val])
        .filter(tagIsVisibleInInbox)
        .map(mapTagToViewModel), [businessTagsById]);
    const roomTagAssociations = useFeatureSelector(selectRoomTags);
    const roomTags = useMemo(() => roomTagAssociations
        .reduce((tagAssociations, roomTag) => {
        const businessTag = businessTagsById[roomTag.tagId];
        if (businessTag && tagIsVisibleInInbox(businessTag)) {
            const tagAssociation = mapTagToViewModel(businessTag);
            tagAssociations.push(tagAssociation);
        }
        return tagAssociations;
    }, [])
        .sort((a, b) => a.name.localeCompare(b.name)), [businessTagsById, roomTagAssociations]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ActionBarComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { TopBarNavigation: (_a = di.extensions) === null || _a === void 0 ? void 0 : _a.TopBarNavigation, assignedAgent: assignedAgentViewModel, businessAgents: businessAgents, businessTags: businessTags, descriptionViewModel: descriptionViewModel, userId: agentId, assignedRoomStatus: assignedRoomStatus, roomStatuses: roomStatuses, roomTags: roomTags })) }));
};
export default ActionBar;
