import React from 'react';
import {
  Box,
  CancelIcon,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Label,
  Stack,
  TextField,
  SaveActionCard,
} from '@connectlyai-tenets/ui-styled-web';

import { useTranslation } from 'react-i18next';
import { useVtexTokenForm } from './useVtexTokenForm';

export const VtexTokenForm: React.FC = () => {
  const {
    INSTRUCTIONS_URL,
    VIDEO_URL,
    title,
    isLoading,
    saveDisabled,
    handleSubnmit,
    accessToken,
    setAccessToken,
    apiKey,
    setApiKey,
    accessTokenError,
    apiKeyError,
    apiKeyDisabled,
    accessTokenDisabled,
    handleClearApiKey,
    handleClearAccessToken,
  } = useVtexTokenForm();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.vtexSettings' });

  return (
    <SaveActionCard
      icon={<img alt={title} src={`${process.env.PUBLIC_URL}/assets/vtex/vtex_settings_logo.png`} />}
      title={title}
      isLoading={isLoading}
      saveDisabled={saveDisabled}
      onSubmit={handleSubnmit}
    >
      <Stack gap={2}>
        <Box>
          <Label variant="body1" mb={3}>
            {t('tokenCard.text1')}
          </Label>

          <Label
            variant="body1"
            dangerouslySetInnerHTML={{ __html: t('tokenCard.text2', { INSTRUCTIONS_URL, VIDEO_URL }) }}
          />
        </Box>
        <Stack direction="row" gap={2}>
          <FormControl fullWidth>
            <TextField
              disabled={apiKeyDisabled}
              variant="outlined"
              label={t('tokenCard.apiKeyInputLabel')}
              error={!!apiKeyError}
              value={apiKey}
              onChange={(e) => setApiKey(e.currentTarget.value)}
              InputProps={{
                endAdornment: !!apiKey && apiKeyDisabled && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearApiKey}>
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!apiKeyError && <FormHelperText error>{apiKeyError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              disabled={accessTokenDisabled}
              variant="outlined"
              label={t('tokenCard.tokenInputLabel')}
              error={!!accessTokenError}
              value={accessToken}
              onChange={(e) => setAccessToken(e.currentTarget.value)}
              InputProps={{
                endAdornment: !!accessToken && accessTokenDisabled && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearAccessToken}>
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!accessTokenError && <FormHelperText error>{accessTokenError}</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>
    </SaveActionCard>
  );
};
