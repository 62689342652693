import React from 'react';
import {
  IconButton,
  AccessTimeIcon,
  MessageNodeHintIcon,
  SimpleMessageNodeToolbarIcon,
  ButtonMessageNodeToolbarIcon,
  APINodeToolbarIcon,
  APINodeHintIcon,
  CustomerRepliesNodeHintIcon,
  CustomerRepliesNodeToolbarIcon,
  LinkMessageNodeToolbarIcon,
  ListMessageNodeToolbarIcon,
  OptOutNodeToolbarIcon,
  OptOutNodeHintIcon,
  SofiaNodeHintIcon,
  SofiaNodeToolbarIcon,
  CarouselIcon,
} from '@connectlyai-tenets/ui-styled-web';

export const NODE_TOOLBAR_NODES = [
  {
    group: 'groups.messages',
    hint: 'nodes.simpleMessage.hint',
    hintIcon: <MessageNodeHintIcon />,
    icon: <SimpleMessageNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_SIMPLE_MESSAGE',
    title: 'nodes.simpleMessage.title',
  },
  {
    featureFlag: 'ffEnableCarousel',
    group: 'groups.messages',
    hint: 'nodes.carouselMessage.hint',
    hintIcon: <MessageNodeHintIcon />,
    icon: <CarouselIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CAROUSEL_MESSAGE',
    title: 'nodes.carouselMessage.title',
    isNew: true,
  },
  {
    group: 'groups.messages',
    hint: 'nodes.buttonMessage.hint',
    hintIcon: <MessageNodeHintIcon />,
    icon: <ButtonMessageNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_QUICK_REPLY_MESSAGE',
    title: 'nodes.buttonMessage.title',
  },
  {
    group: 'groups.messages',
    hint: 'nodes.linkMessage.hint',
    hintIcon: <MessageNodeHintIcon />,
    icon: <LinkMessageNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_CTA_MESSAGE',
    title: 'nodes.linkMessage.title',
  },
  {
    group: 'groups.messages',
    hint: 'nodes.listMessage.hint',
    hintIcon: <MessageNodeHintIcon />,
    icon: <ListMessageNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_LIST_MESSAGE',
    title: 'nodes.listMessage.title',
  },
  {
    featureFlag: 'ffEnableETAProductReviews',
    group: 'groups.events',
    hint: 'nodes.customerReplies.hint',
    hintIcon: <CustomerRepliesNodeHintIcon />,
    icon: <CustomerRepliesNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_INCOMING_ROOM_EVENT',
    title: 'nodes.customerReplies.title',
  },
  {
    featureFlag: 'ffEnableTimeDelayNode',
    group: 'groups.timing',
    hint: 'nodes.timeDelay.hint',
    hintIcon: <AccessTimeIcon />,
    icon: (
      <IconButton disableRipple sx={{ background: '#D1D8DC', width: '28px', height: '28px' }}>
        <AccessTimeIcon style={{ width: '20px', height: '20px' }} />
      </IconButton>
    ),
    nodeType: 'FLOW_OBJECT_TYPE_TIME_DELAY',
    title: 'nodes.timeDelay.title',
  },
  {
    featureFlag: 'ffEnableAINode',
    group: 'groups.actions',
    hint: 'nodes.sofiaAI.hint',
    hintIcon: <SofiaNodeHintIcon />,
    hintLearnMore: 'https://docs.connectly.ai/how-to-use-sofia-ai',
    icon: <SofiaNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_AI_ASSISTANT',
    title: 'nodes.sofiaAI.title',
  },
  {
    group: 'groups.actions',
    hint: 'nodes.optOut.hint',
    hintIcon: <OptOutNodeHintIcon />,
    icon: <OptOutNodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_OPT_OUT',
    title: 'nodes.optOut.title',
  },
  {
    featureFlag: 'ffEnableAPINode',
    group: 'groups.actions',
    hint: 'nodes.apiAction.hint',
    hintIcon: <APINodeHintIcon />,
    icon: <APINodeToolbarIcon />,
    nodeType: 'FLOW_OBJECT_TYPE_CALL_API',
    title: 'nodes.apiAction.title',
  },
] as const;
