import React, { useCallback, useContext, useRef, useState } from 'react';
import { Label, Box, useTheme, TextField, Button } from '@connectlyai-tenets/ui-styled-web';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { MessagingChannels as SettingsMessagingChannels } from '@scenes/Settings/components/MessagingChannels';
import { selectBusinessId, useMeData, useWidget } from '../../hooks';
import { useBusinessOrganizationData } from '../../hooks/useBusinessOrganizationData';
import { useBusinessOrganizationMutation } from '../../hooks/useBusinessOrganizationMutation';
import { NotificationContext } from '../../contexts';
import { WhatsappBusinessProfilePicture } from '../WhatsappBusinessProfilePicture';
import { SMBSettingsGeneralProps } from './types';
import { applyHook } from '../../utils';
import WidgetContainerWebView from '../../presentation/WidgetContainerWebView';
import { ProfileContainer } from '../ProfileContainer';
import { SIZE_CONTENT_S } from '../../ui-theme';

const useBillingGeneral = (): Partial<SMBSettingsGeneralProps> => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  return {
    businessId,
  };
};

const SMBSettingsGeneralContent = ({ businessId }: SMBSettingsGeneralProps) => {
  const theme = useTheme();
  const emailRef = useRef<HTMLInputElement>(null);
  const [displayRequirements, setDisplayRequirements] = useState(false);
  const [savePreviewedFile, setSavePreviewedFile] = useState<boolean>(false);

  const { notificationProvider } = useContext(NotificationContext);
  const { data: org } = useBusinessOrganizationData({
    businessId,
  });
  const { script } = useWidget();

  const { mutate: mutateBusinessOrganization } = useBusinessOrganizationMutation({
    onSuccess: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Successfully updated notifcation email',
          severity: NotificationSeverity.SUCCESS,
          icon: '',
        },
      });
    },
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Error updating message',
          severity: NotificationSeverity.ERROR,
          icon: '',
        },
      });
    },
  });

  const submitEmail = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (emailRef?.current?.value) {
        mutateBusinessOrganization({
          businessId: businessId ?? '',
          id: org?.entity?.id,
          pricingPlanId: org?.entity?.pricingPlanId,
          email: emailRef.current.value,
        });
      }
    },
    [businessId, org, emailRef, mutateBusinessOrganization],
  );

  const submitPicture = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSavePreviewedFile(true);
  };

  const email = org?.entity?.email;

  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        margin: '0px auto',
        p: 4,
        background: theme.palette.skyBlue,
        height: '100%',
        gap: 2,
        overflow: 'hidden',
        scrollbarGutter: 'stable',
        '&:hover': {
          overflow: 'auto',
        },
      }}
    >
      {email && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Label variant="h6">General</Label>
          </Box>
          <ProfileContainer onSubmit={submitEmail}>
            <Label variant="subtitle1">Email Notification</Label>
            <Label variant="body2" my={2}>
              Enter email to add to notifications
            </Label>
            <TextField
              label="Email"
              defaultValue={email}
              inputRef={emailRef}
              sx={{ width: theme.spacing(SIZE_CONTENT_S) }}
            />
            <Button variant="outlined" type="submit" color="secondary">
              Save
            </Button>
          </ProfileContainer>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Label variant="h6">Profile Settings</Label>
      </Box>
      <ProfileContainer onSubmit={submitPicture}>
        <Label>WhatsApp Business Profile Picture</Label>
        <WhatsappBusinessProfilePicture
          supportPreview
          savePreviewedFile={savePreviewedFile}
          setSavePreviewedFile={setSavePreviewedFile}
          handleChangePictureSuccess={() => {
            setDisplayRequirements(false);
          }}
          handleChangePictureError={() => {
            setDisplayRequirements(true);
          }}
        />
        <Button variant="outlined" type="submit" color="secondary">
          Save
        </Button>
        {displayRequirements && (
          <Label variant="body2" color="error" sx={{ mt: 1 }}>
            Please ensure that the uploaded image is a square with dimensions of at least 400x400 pixels.
          </Label>
        )}
      </ProfileContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Label variant="h6">Messaging Channels</Label>
      </Box>
      <Box sx={{ width: '100%' }}>
        <SettingsMessagingChannels />
      </Box>
      {script && (
        <Box>
          <WidgetContainerWebView script={script} notificationService={notificationProvider()} />
        </Box>
      )}
    </Box>
  );
};

const SMBSettingsGeneralPresentation = (props: SMBSettingsGeneralProps) => {
  if (!props.businessId) {
    return null;
  }
  return <SMBSettingsGeneralContent {...props} />;
};

export const SMBSettingsGeneral = applyHook(SMBSettingsGeneralPresentation, useBillingGeneral);
