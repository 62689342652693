import produce from 'immer';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { customSendoutNodeAtom, selectedNodeAtom } from '@atoms/flow';
import { mappingsToHash, useFlowVariables } from '@hooks/useFlowVariables';
import { MessageTemplate } from '@hooks/useMessageTemplateGroupsData/types';
import { useNodeObjectChange } from '../useFlowChangeAppliers';
import { useDetectVariables } from '../useDetectVariables';
import { setMappings, setTemplateComponents } from '../../../../state/messageTemplates';

export const usePrepareTemplateForReuse = () => {
  const selectedNode = useAtomValue(selectedNodeAtom);
  const sendoutNode = useAtomValue(customSendoutNodeAtom);

  const dispatch = useDispatch();

  const { onNodeObjectChange } = useNodeObjectChange();

  const { addVariables } = useFlowVariables();
  const { detectVariables, prepareDetectVariables } = useDetectVariables();

  const prepareTemplateForReuse = useCallback(
    (template?: MessageTemplate) => {
      prepareDetectVariables(sendoutNode?.data?.v3?.enterVariables);

      if (!selectedNode || !template) return;

      const { templateComponents } = template;
      if (!templateComponents) return;

      const { variableDefinitions, mappings } = detectVariables(templateComponents);
      const updatedNode = produce(selectedNode, (draft) => {
        if (!draft.data.v1) draft.data.v1 = {};
        draft.data.v1.messageTemplateInput = { templateComponents };
        draft.data.v1.parameterMapping = { mappings };
        return draft;
      });

      onNodeObjectChange([
        {
          id: updatedNode.id,
          item: updatedNode,
        },
      ]);

      const mappingsHash = mappingsToHash(mappings);

      dispatch(setMappings(mappingsHash));
      dispatch(setTemplateComponents(templateComponents));

      if (!sendoutNode) return;

      if (!variableDefinitions || variableDefinitions.length === 0) return;

      addVariables(variableDefinitions);

      const modifiedSendoutNode = produce(sendoutNode, (draft) => {
        draft.data.v3.enterVariables = { ...draft.data.v3.enterVariables, ...variableDefinitions };
      });

      onNodeObjectChange([
        {
          id: modifiedSendoutNode.id,
          item: modifiedSendoutNode,
        },
      ]);
    },
    [addVariables, detectVariables, dispatch, onNodeObjectChange, prepareDetectVariables, selectedNode, sendoutNode],
  );
  return { prepareTemplateForReuse };
};
