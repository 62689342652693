import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { ChatRoomDIContext } from '../../context';
import { useFeatureSelector } from '../../state/hooks';
import { selectQuickReplyTemplates } from '../../state/quickReplySlice';
import { selectMessageTemplateGroups } from '../../state/messageTemplateGroupSlice';
import { selectMessageTemplateIsAvailable } from '../../state/composerSlice';
export const ComposerTemplatePopoverComponentId = 'aeaf4a17-b96a-429b-8c22-8736e0e2bd3c';
function mapQuickReplyTemplateToViewModel(item) {
    return {
        id: item.id,
        title: item.title,
        body: item.body,
    };
}
function getBodyText(template) {
    var _a, _b;
    for (let i = 0; i < template.dto.templateComponents.length; i += 1) {
        const component = template.dto.templateComponents[i];
        if (((_a = component.componentOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'body') {
            return ((_b = component.componentOneof.body.text) === null || _b === void 0 ? void 0 : _b.text) || '';
        }
    }
    return '';
}
function mapMessageTemplateToViewModel(template) {
    var _a;
    const { dto, group } = template;
    return {
        id: dto.id,
        title: ((_a = dto.language) === null || _a === void 0 ? void 0 : _a.code) ? `${group.name} (${dto.language.code})` : group.name,
        body: getBodyText(template),
    };
}
function mapMessageTemplateGroupsToViewModel(templateGroups) {
    const results = [];
    templateGroups.forEach((group) => {
        const templates = group.templates.map((dto) => mapMessageTemplateToViewModel({ dto, group }));
        results.push(...templates);
    });
    return results;
}
const ComposerTemplatePopover = ({ disabled }) => {
    const di = useContext(ChatRoomDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const messageTemplateIsAvailable = useFeatureSelector(selectMessageTemplateIsAvailable);
    const messageTemplateGroups = useFeatureSelector(selectMessageTemplateGroups);
    const messageTemplates = mapMessageTemplateGroupsToViewModel(messageTemplateGroups);
    const quickReplyTemplates = useFeatureSelector(selectQuickReplyTemplates).map(mapQuickReplyTemplateToViewModel);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(ComposerTemplatePopoverComponentId);
    return (_jsx(_Fragment, { children: UIComponent && (_jsx(UIComponent, { disabled: disabled, messageTemplateIsAvailable: messageTemplateIsAvailable, messageTemplates: messageTemplates, quickReplyTemplates: quickReplyTemplates })) }));
};
export default ComposerTemplatePopover;
