import React, { useCallback, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { BusinessNameProps } from './types';
import { applyHook } from '../../../../utils';
import { selectBusinessName, selectUseNewPhoneNumber, setBusinessName } from '../../../../features/welcome';
import { BUSINESS_NAME_MAX_LENGTH } from './constants';
import { useEffectOnce } from '../../../../hooks';

const usePhoneNumberOld = (_props: Partial<BusinessNameProps>): Partial<BusinessNameProps> => {
  const dispatch = useDispatch();
  const businessName = useSelector(selectBusinessName);
  const handleChange = useCallback(
    (value: string) => {
      dispatch(setBusinessName(value));
    },
    [dispatch],
  );

  return {
    businessName,
    onChange: handleChange,
  };
};

export const BusinessNamePresentation: VFC<BusinessNameProps> = ({
  businessName,
  claimedPhoneNumber,
  claimNumber,
  onChange,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.businessName' });
  const useNewPhoneNumber = useSelector(selectUseNewPhoneNumber);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  useEffectOnce(() => {
    if (useNewPhoneNumber && !claimedPhoneNumber) {
      claimNumber();
    }
  });

  return (
    <Box>
      <Label>{t('title')}</Label>
      <TextField
        autoFocus
        autoComplete="off"
        placeholder={t('enterBusinessName') || ''}
        fullWidth
        value={businessName}
        onChange={handleChange}
        variant="outlined"
        inputProps={{ maxLength: BUSINESS_NAME_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${businessName.length}/${BUSINESS_NAME_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Box>
  );
};

export const BusinessName = applyHook(BusinessNamePresentation, usePhoneNumberOld);
