import React, { FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Collapse,
  DesktopDatePicker,
  FormControlLabel,
  TextField,
  Label,
  Radio,
  TimePicker,
} from '@connectlyai-tenets/ui-styled-web';
import { selectCampaignDate, setCampaignDate } from '../../state/flow';
import { useCampaignDate } from '../../hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customTextField = (props: any) => <TextField sx={{ '& fieldset': { borderRadius: '10px' } }} {...props} />;
export const FlowChartSidebarChooseTime: FC = () => {
  const campaignDate = useSelector(selectCampaignDate);
  const campaignDateMemo = useMemo(() => (campaignDate ? new Date(campaignDate) : null), [campaignDate]);
  const dispatch = useDispatch();
  const { dateRestrictions } = useCampaignDate();

  const handleDateChange = (date: Date | null) => {
    dispatch(setCampaignDate(date ? date?.toISOString() : ''));
  };
  const handleClickSendNow = () => {
    dispatch(setCampaignDate(''));
  };
  const handleClickSchedule = () => {
    dispatch(setCampaignDate(new Date().toISOString()));
  };

  return (
    <>
      <Label variant="h6" gutterBottom>
        Choose Time
      </Label>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            checked={!campaignDate}
            onClick={handleClickSendNow}
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px' }}>
                Send Now
              </Label>
            }
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1.25 }}>
            Send campaign immediately
          </Label>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <FormControlLabel
            checked={!!campaignDate}
            onClick={handleClickSchedule}
            control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
            label={
              <Label component="div" noWrap variant="subtitle2" sx={{ lineHeight: '19px' }}>
                Schedule Campaign
              </Label>
            }
            labelPlacement="start"
            sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
          />
          <Label variant="body1" color="textSecondary" sx={{ mt: 1.25 }}>
            Send campaign at a scheduled time.
          </Label>
          <Collapse in={!!campaignDate}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
              }}
            >
              <DesktopDatePicker
                label="Date"
                disablePast
                minDate={dateRestrictions.minDate}
                maxDate={dateRestrictions.maxDate}
                openTo="day"
                value={campaignDateMemo}
                views={['day']}
                onChange={handleDateChange}
                slots={{
                  textField: customTextField,
                }}
              />
              <TimePicker
                label="Time"
                minTime={dateRestrictions.minTime}
                maxTime={dateRestrictions.maxTime}
                value={campaignDateMemo}
                onChange={handleDateChange}
                orientation="portrait"
                slots={{
                  textField: customTextField,
                }}
              />
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};
