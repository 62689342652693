import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_CREATE_QUICK_REPLY_TEMPLATE_URL = '/v1/businesses/{businessId}/create/quick_reply_templates';

type MutationCreateQuickReplyTemplateUrl = typeof MUTATION_CREATE_QUICK_REPLY_TEMPLATE_URL;

export type MutationCreateQuickReplyTemplateResponse =
  Paths['profileV1'][MutationCreateQuickReplyTemplateUrl]['post']['responses']['200']['schema'];
type MutationCreateQuickReplyTemplateBody = NonNullable<
  Paths['profileV1'][MutationCreateQuickReplyTemplateUrl]['post']['parameters']['body']['input']['entity']
>;
export type MutationCreateQuickReplyTemplateInput =
  Paths['profileV1'][MutationCreateQuickReplyTemplateUrl]['post']['parameters']['path'] &
    Required<Pick<MutationCreateQuickReplyTemplateBody, 'id'>> &
    MutationCreateQuickReplyTemplateBody;
export type MutationCreateQuickReplyTemplateOptions = Omit<
  UseMutationOptions<
    MutationCreateQuickReplyTemplateResponse,
    ConnectlyError,
    MutationCreateQuickReplyTemplateInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
