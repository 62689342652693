import { CreateUserPasswordInput } from '@connectlyai-idl/models/dist/models/profile/signup';
export const mutationSubmitSignUpProvider = (log, networkClient) => async (input) => {
    const url = '/v1/signup/create/password';
    log.debug('mutationSubmitSignUpProvider@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.post(url, CreateUserPasswordInput.toJSON({
            confirmToken: input.confirmToken,
            password: input.password,
        }));
        log.debug('mutationSubmitSignUpProvider@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationSubmitSignUpProvider@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
