import React, { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { sofiaStoreDescriptorAtom, sofiaWelcomeMessageAtom, sofiaPromptAdditionAtom } from '@atoms/sofia';
import { useTranslation } from 'react-i18next';
import { Box, ConnectlyCard, Alert, AlertColor } from '@connectlyai-tenets/ui-styled-web';
import { selectBusinessId, useMeData } from '@hooks';
import { selectSofiaStatus, selectSofiaDocuments } from '../../selectors';
import { useSofiaStatus, useSofiaDocuments } from '../../hooks';
import { SOFIA_STATUS_DICT, SOFIA_GENDER_DISPLAY, SOFIA_AGE_DISPLAY } from '../../constants';
import { SettingsDataSection } from '../SettingsDataSection';
import { SettingsFreeTextProps } from './types';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { mapDocumentToDataPillItem } from '../../utils';
import { getLanguage } from '../../../../utils';

const useSettingsOverview = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: sofiaStatus } = useSofiaStatus({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSofiaStatus,
  });
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const status = useMemo(() => {
    let severity: AlertColor = 'info';
    if (sofiaStatus === 'INDEXER_STATUS_SUCCESS') {
      severity = 'info';
    } else if (sofiaStatus === 'INDEXER_STATUS_ERROR') {
      severity = 'error';
    } else if (sofiaStatus === 'INDEXER_STATUS_IN_PROGRESS') {
      severity = 'warning';
    }
    return {
      ready: sofiaStatus === 'INDEXER_STATUS_SUCCESS',
      text: sofiaStatus ? t(SOFIA_STATUS_DICT[sofiaStatus]) : t('generic.loading'),
      severity,
    };
  }, [sofiaStatus, t]);
  const label = useMemo(() => {
    return {
      test: t('sofia.settingsOverviewTestWhatsApp'),
      text: t('generic.status'),
    };
  }, [t]);
  const info = useMemo(() => {
    return {
      title: t('sofia.settingsOverviewInfoTitle'),
      subtitle: t('sofia.settingsOverviewInfoSubtitle'),
    };
  }, [t]);
  return {
    info,
    label,
    status,
  };
};
const SettingsOverview = () => {
  const { label, status } = useSettingsOverview();
  return (
    <Alert severity={status.severity}>
      {label.text}: {status.text}
    </Alert>
  );
};

const SettingsBasicInformation = () => {
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);

  const data = useMemo(() => {
    const { language, name, url } = descriptor;
    if (language || name || url) {
      const values = [];
      if (language) {
        values.push({ value: getLanguage(language) });
      }
      if (name) {
        values.push({ value: name });
      }
      if (url) {
        values.push({ value: url });
      }
      return values;
    }
    return null;
  }, [descriptor]);

  return <SettingsDataSection data={data} setting="basicInformation" />;
};

const SettingsStoreDescription = () => {
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const data = useMemo(() => {
    if (descriptor.about) return [{ value: descriptor.about }];
    return null;
  }, [descriptor.about]);

  return <SettingsDataSection data={data} setting="storeDescription" />;
};

const SettingsWelcomeMessage = ({ disabled }: { disabled: boolean }) => {
  const welcomeMessage = useAtomValue(sofiaWelcomeMessageAtom);
  const data = useMemo(() => {
    if (welcomeMessage) return [{ value: welcomeMessage }];
    return null;
  }, [welcomeMessage]);

  return <SettingsDataSection data={data} setting="welcomeMessage" editDisabled={disabled} />;
};

const SettingsPromptAddition = ({ disabled }: { disabled: boolean }) => {
  const promptAddition = useAtomValue(sofiaPromptAdditionAtom);
  const data = useMemo(() => {
    if (promptAddition) return [{ value: promptAddition }];
    return null;
  }, [promptAddition]);

  return <SettingsDataSection data={data} setting="promptAddition" editDisabled={disabled} />;
};

const SettingsCustomersProfile = () => {
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const data = useMemo(() => {
    const { customersAge: age, customersGender: gender, customersDescription: description } = descriptor;
    if (age || gender || description) {
      const values = [];
      if (gender) {
        values.push({ value: SOFIA_GENDER_DISPLAY[gender] });
      }
      if (age) {
        values.push({ value: age.map((g) => SOFIA_AGE_DISPLAY[g]).join(', ') });
      }
      if (description) {
        values.push({ value: description });
      }
      return values;
    }
    return null;
  }, [descriptor]);

  return <SettingsDataSection data={data} setting="customerProfile" />;
};

const SettingsCustomersSupport = () => {
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const data = useMemo(() => {
    const { email, phone, whatsAppLink } = descriptor;
    if (email || phone || whatsAppLink) {
      const values = [];
      if (email) {
        values.push({ value: email });
      }
      if (phone) {
        values.push({ value: phone });
      }
      if (whatsAppLink) {
        values.push({ value: whatsAppLink });
      }
      return values;
    }
    return null;
  }, [descriptor]);

  return <SettingsDataSection data={data} setting="customerSupport" />;
};

const SettingsSocialMedia = () => {
  const descriptor = useAtomValue(sofiaStoreDescriptorAtom);
  const data = useMemo(() => {
    const { facebook, instagram } = descriptor;
    if (facebook || instagram) {
      const values = [];
      if (facebook) {
        values.push({ value: `Facebook: ${facebook}` });
      }
      if (instagram) {
        values.push({ value: `Instagram: ${instagram}` });
      }
      return values;
    }
    return null;
  }, [descriptor]);

  return <SettingsDataSection data={data} setting="socialMedia" />;
};

const SettingsDocuments = () => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: documents } = useSofiaDocuments({
    businessId: businessId || '',
    enabled: !!businessId,
    select: selectSofiaDocuments,
  });
  const data = useMemo(() => {
    if (!documents) return null;
    return documents.map(mapDocumentToDataPillItem);
  }, [documents]);

  return <SettingsDataSection data={data} setting="documents" isDocument />;
};

const SettingsFreeText = ({ content }: SettingsFreeTextProps) => {
  const data = useMemo(() => {
    if (content) {
      return [{ value: content }];
    }
    return null;
  }, [content]);

  return <SettingsDataSection data={data} setting="freeText" />;
};

const SettingsStoreInformation = ({
  hasIntegration,
  agentsReady,
}: {
  hasIntegration: boolean;
  agentsReady: boolean;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const title = useMemo(() => {
    return t('settingsStoreInformationTitle');
  }, [t]);
  const subtitle = useMemo(() => {
    return t('settingsStoreInformationSubtitle');
  }, [t]);

  return (
    <ConnectlyCard title={title} subtitle={subtitle}>
      <SettingsBasicInformation />
      <SettingsStoreDescription />
      <SettingsWelcomeMessage disabled={!hasIntegration} />
      <SettingsPromptAddition disabled={!agentsReady} />
    </ConnectlyCard>
  );
};

const SettingsKnowledgeBase = ({ freeText }: { freeText: string }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const title = useMemo(() => {
    return t('settingsKnowledgeBaseTitle');
  }, [t]);
  const subtitle = useMemo(() => {
    return t('settingsKnowledgeBaseSubtitle');
  }, [t]);

  return (
    <ConnectlyCard title={title} subtitle={subtitle}>
      {/* <SettingsWebsiteScan /> */}
      <SettingsDocuments />
      <SettingsFreeText content={freeText} />
    </ConnectlyCard>
  );
};

export const Settings = ({
  freeText,
  hasIntegration,
  agentsReady,
}: {
  freeText: string;
  hasIntegration: boolean;
  agentsReady: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <SettingsOverview />
      <SettingsStoreInformation hasIntegration={hasIntegration} agentsReady={agentsReady} />
      <SettingsKnowledgeBase freeText={freeText} />
    </Box>
  );
};
