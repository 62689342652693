// unreduxified, has only messageTemplates redux

import { useDispatch } from 'react-redux';
import produce from 'immer';
import { useGetAtom } from '@hooks/useGetAtom';
import { nodesAtom } from '@atoms/flow';
import { useContextSelector } from 'use-context-selector';
import { useCreateMessageTemplateMutation, useUpdateMessageTemplateMutation } from '@hooks';
import { NodeObjectUpdate } from '../useChangesQueue';
import { FlowContext } from '../../../../contexts';
import { replaceUnsentMessageTemplate, setMessageTemplateStatus } from '../../../../state/messageTemplates';

export const useWaTemplateMutations = () => {
  const dispatch = useDispatch();
  const nodes = useGetAtom(nodesAtom);
  const { onNodeObjectChange } = useContextSelector(FlowContext, (context) => {
    return context.flowChangeAppliers;
  });

  const { mutate: mutateCreateWaTemplate, isLoading: mutateCreateWaTemplateIsLoading } =
    useCreateMessageTemplateMutation({
      onMutate: ({ nodeId }) => {
        dispatch(setMessageTemplateStatus({ nodeId, status: 'MESSAGE_TEMPLATE_STATUS_PENDING' }));
      },
      onError: (data, { nodeId }) => {
        dispatch(
          setMessageTemplateStatus({
            nodeId,
            status: 'MESSAGE_TEMPLATE_STATUS_REJECTED',
            rejectionReason: {
              reason: data.userMessage,
            },
          }),
        );
      },
      onSuccess: (data, { nodeId }) => {
        if (!data || !data.template || !data.templateGroup || !data.templateGroup.name) return;
        if (data?.template?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED') {
          dispatch(
            setMessageTemplateStatus({
              nodeId,
              status: 'MESSAGE_TEMPLATE_STATUS_REJECTED',
              rejectionReason: {
                reason: data?.template?.rejectionReason?.reason as string,
              },
            }),
          );
          return;
        }
        dispatch(
          replaceUnsentMessageTemplate({
            nodeId,
            template: { ...data.template, ...{ name: data.templateGroup.name, errors: undefined } },
          }),
        );
        const templateId = data.template?.id;
        if (templateId) {
          const actualNode = nodes().find((n) => n.id === nodeId);
          if (!actualNode) {
            return;
          }
          const updatedNode = produce(actualNode, (draft) => {
            switch (actualNode.type) {
              case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
                draft.data.v1.waMessageTemplateId = data.template?.id;
                break;
              }
              case 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE': {
                draft.data.v1.templateId = data.template?.id;
                break;
              }
              default: {
                break;
              }
            }
            return draft;
          });

          const update: NodeObjectUpdate = {
            id: updatedNode.id,
            item: updatedNode,
          };
          onNodeObjectChange([update]);
        }
      },
      retry: (failureCount, error) => {
        // Infinitely retry unspecified errors (like network connectivity)
        if (!error) {
          return true;
        }
        switch (error.code) {
          case 'ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM':
          case 'ERROR_CODE_MESSAGE_TEMPLATE_DUPLICATED_LANGUAGE':
            return false;
          default:
            return failureCount < 3;
        }
      },
    });

  const { mutate: mutateUpdateWaTemplate } = useUpdateMessageTemplateMutation({
    onMutate: ({ nodeId }) => {
      dispatch(setMessageTemplateStatus({ nodeId, status: 'MESSAGE_TEMPLATE_STATUS_PENDING' }));
    },
    onError: (data, { nodeId }) => {
      dispatch(
        setMessageTemplateStatus({
          nodeId,
          status: 'MESSAGE_TEMPLATE_STATUS_REJECTED',
          rejectionReason: {
            reason: data.userMessage,
          },
        }),
      );
    },
    onSuccess: (data, { nodeId }) => {
      if (!data) return;
      dispatch(setMessageTemplateStatus({ nodeId, status: 'MESSAGE_TEMPLATE_STATUS_PENDING' }));
    },
    retry: (failureCount, error) => {
      // Retry only on HTTP status code 400
      return !(error && error.code === 'ERROR_CODE_MESSAGE_TEMPLATE_INVALID_PARAM');
    },
  });

  return {
    mutateCreateWaTemplate,
    mutateCreateWaTemplateIsLoading,
    mutateUpdateWaTemplate,
  };
};
