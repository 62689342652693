import React, { useCallback, FC, PropsWithChildren, useMemo } from 'react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Box, Button, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { sofiaEditSettingAtom } from '@atoms/sofia';
import { SettingsDataSectionProps, SettingsSubsectionContainerProps } from './types';
import { DataPill, DataPillItem } from '../DataPill';

const SettingsSubsectionContainer: FC<PropsWithChildren<SettingsSubsectionContainerProps>> = ({
  title,
  action,
  sx,
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 2,
          p: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {(title || action) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
            mb: 1,
          }}
        >
          <Label variant="h7">{title}</Label>
          {action && action}
        </Box>
      )}
      {children}
    </Box>
  );
};

export const SettingsDataSection = ({
  label,
  data,
  isDocument,
  setting,
  onRemove,
  editDisabled,
  removeDisabled,
  sx,
}: SettingsDataSectionProps) => {
  const setEditSetting = useSetAtom(sofiaEditSettingAtom);
  const onEdit = useCallback(() => {
    if (!setting) return;
    setEditSetting(setting);
  }, [setEditSetting, setting]);
  const buttonVariant = useMemo(() => {
    if (data) return 'text';
    return 'contained';
  }, [data]);
  const buttonText = useMemo(() => {
    if (!data) return 'Add';
    if (isDocument) return 'Upload';
    return 'Edit';
  }, [isDocument, data]);
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const title = useMemo(() => {
    if (label) return t(label);
    return t(`${setting}DataSectionTitle`);
  }, [label, setting, t]);
  const theme = useTheme();
  return (
    <SettingsSubsectionContainer
      title={title}
      action={
        setting && (
          <Button variant={buttonVariant} onClick={onEdit} disabled={editDisabled}>
            {buttonText}
          </Button>
        )
      }
      sx={sx}
    >
      {data && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            width: `calc(100% - ${theme.spacing(9)})`,
            flexWrap: onRemove ? 'wrap' : 'nowrap',
          }}
        >
          {data.map((item: DataPillItem, i) => {
            return (
              <DataPill
                // eslint-disable-next-line react/no-array-index-key
                key={`item-${i}`}
                display={item.display || item.value}
                isDocument={isDocument}
                disabled={removeDisabled}
                handleRemove={onRemove ? () => onRemove(item.value) : undefined}
              />
            );
          })}
        </Box>
      )}
    </SettingsSubsectionContainer>
  );
};
