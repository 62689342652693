import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { ConnectlyCard, useTheme, TimelineIcon, LineChart, purple } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { useAnalysisDataInRanges } from '../../hooks';
import { selectMessageAnalysis } from '../../selectors';

const useMessagesAnalysis = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics.messagesAnalysis' });
  const { title, subtitle } = useMemo(() => {
    return {
      title: t('title', 'Timegraph'),
      subtitle: t('subtitle', 'Learn about the trends of your Sofia AI conversation sessions and customer messages.'),
    };
  }, [t]);
  const theme = useTheme();
  const analyticsData = useAnalysisDataInRanges({});
  const { xData, sessionsValues, messagesValues } = useMemo(() => {
    return selectMessageAnalysis(analyticsData);
  }, [analyticsData]);
  const series = useMemo(() => {
    const list: Array<{
      label: string;
      curve: 'linear';
      data: number[];
      valueFormatter: (value: unknown) => string;
      color: string;
    }> = [];

    list.push({
      label: t('sessions', 'Sessions'),
      curve: 'linear',
      data: sessionsValues,
      valueFormatter: (value) => `${value} session${value === 1 ? '' : 's'}`,
      color: theme.palette.primary.main,
    });
    list.push({
      label: t('messages', 'Messages'),
      curve: 'linear',
      data: messagesValues,
      valueFormatter: (value) => `${value} message${value === 1 ? '' : 's'}`,
      color: purple[600],
    });
    return list;
  }, [messagesValues, sessionsValues, t, theme.palette.common.black, theme.palette.primary.main]);

  return {
    theme,
    title,
    subtitle,
    xData,
    series,
  };
};

export const MessagesAnalysis = () => {
  const { series, subtitle, theme, title, xData } = useMessagesAnalysis();
  return (
    <ConnectlyCard
      sx={{ flexDirection: 'column', overflow: 'auto', userSelect: 'none', flex: '0 0 auto' }}
      icon={<TimelineIcon sx={{ width: theme.spacing(4), height: theme.spacing(4) }} />}
      title={title}
      subtitle={subtitle}
      isNew
    >
      <LineChart
        xAxis={[
          {
            scaleType: 'band',
            data: xData,
            valueFormatter: (value) => format(value, 'LLL dd'),
          },
        ]}
        series={series}
        width={895}
        height={350}
        grid={{ horizontal: true }}
      />
    </ConnectlyCard>
  );
};
