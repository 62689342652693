import { Timestamp } from "../../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "exp.eta.events";
function createBaseTimeout() {
    return {
        sessionId: "",
        currentFlowId: "",
        customerId: "",
        scheduledTimeout: undefined,
    };
}
export const Timeout = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sessionId !== "") {
            writer.uint32(10).string(message.sessionId);
        }
        if (message.currentFlowId !== "") {
            writer.uint32(18).string(message.currentFlowId);
        }
        if (message.customerId !== "") {
            writer.uint32(26).string(message.customerId);
        }
        if (message.scheduledTimeout !== undefined) {
            Timestamp.encode(toTimestamp(message.scheduledTimeout), writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTimeout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.string();
                    break;
                case 2:
                    message.currentFlowId = reader.string();
                    break;
                case 3:
                    message.customerId = reader.string();
                    break;
                case 4:
                    message.scheduledTimeout = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sessionId: isSet(object.sessionId) ? String(object.sessionId) : "",
            currentFlowId: isSet(object.currentFlowId)
                ? String(object.currentFlowId)
                : "",
            customerId: isSet(object.customerId) ? String(object.customerId) : "",
            scheduledTimeout: isSet(object.scheduledTimeout)
                ? String(object.scheduledTimeout)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sessionId !== undefined && (obj.sessionId = message.sessionId);
        message.currentFlowId !== undefined &&
            (obj.currentFlowId = message.currentFlowId);
        message.customerId !== undefined && (obj.customerId = message.customerId);
        message.scheduledTimeout !== undefined &&
            (obj.scheduledTimeout = message.scheduledTimeout);
        return obj;
    },
};
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
