import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import { Label } from '../Label/Label';
export const NewLabel = ({ sx }) => {
    const theme = useTheme();
    return (_jsx(Label, { variant: "subtitle2", sx: [
            {
                background: theme.palette.grey[200],
                fontWeight: 700,
                fontSize: '10px',
                lineHeight: '16.6px',
                textTransform: 'uppercase',
                px: 1,
                py: 0.5,
                borderRadius: theme.spacing(0.25),
                [theme.breakpoints.down('lg')]: { fontSize: '9.2px', lineHeight: '15px', fontWeight: 700 },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ], children: "New" }));
};
