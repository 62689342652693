import { startOfDay, subDays, subMonths, addMonths, startOfMonth } from 'date-fns';
import { DatePeriod, DatePeriodKey } from './types';

export const DEFAULT_DATE_PERIOD: DatePeriodKey = 'last7Days';

export const DATE_PERIOD_LIST: Record<DatePeriodKey, DatePeriod> = {
  all: {
    getDates: (_date: Date) => {
      const start = new Date(0);
      const end = new Date();
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  today: {
    getDates: (date: Date) => {
      const start = startOfDay(date);
      const end = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  last7Days: {
    getDates: (date: Date) => {
      const start = subDays(date, 7);
      const end = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  last1Month: {
    getDates: (date: Date) => {
      const start = subMonths(date, 1);
      const end = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  last3Months: {
    getDates: (date: Date) => {
      const start = subMonths(date, 3);
      const end = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  last6Months: {
    getDates: (date: Date) => {
      const start = subMonths(date, 6);
      const end = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  calMonth: {
    getDates: (date: Date) => {
      const start = startOfDay(startOfMonth(date));
      const end = addMonths(start, 1);
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  prevCalMonth: {
    getDates: (date: Date) => {
      const end = startOfDay(startOfMonth(date));
      const start = startOfDay(startOfMonth(subMonths(date, 1)));
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);
      return [start, end];
    },
  },
};
