import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';
import { UseQueryOptions } from '@tanstack/react-query';

export const QUERY_REACT_FLOW_DOCUMENT_VERSIONS_URL =
  '/internal/v1/businesses/{query.businessId}/query_react_flow_document_versions/flow_documents';

type QueryReactFlowDocumentVersionsURL = typeof QUERY_REACT_FLOW_DOCUMENT_VERSIONS_URL;

export type QueryReactFlowDocumentVersionsResponse =
  Paths['flowV1'][QueryReactFlowDocumentVersionsURL]['post']['responses']['200']['schema'];
export type QueryReactFlowDocumentVersionsInput = {
  businessId: string;
} & Paths['flowV1'][QueryReactFlowDocumentVersionsURL]['post']['parameters']['body']['query'];

export type QueryReactFlowDocumentVersionsOptions = Omit<
  UseQueryOptions<
    QueryReactFlowDocumentVersionsResponse,
    ConnectlyError,
    QueryReactFlowDocumentVersionsResponse,
    readonly ['flow_document_versions', { flowDocumentId: string }]
  >,
  'initialData' | 'queryFn' | 'queryKey'
>;
