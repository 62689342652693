import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import { MUTATION_ADD_OPT_OUT_URL, MutationAddOptOutParameters, MutationAddOptOutOptions } from './types';

export const useMutationAddOptOut = (options?: MutationAddOptOutOptions) =>
  useMutation(
    ({ businessId, optOutList }: MutationAddOptOutParameters) =>
      api.profileV1(MUTATION_ADD_OPT_OUT_URL, 'post', {
        path: { businessId },
        body: { input: { optOutList } },
      }),
    {
      ...options,
    },
  );
