import { CampaignStep } from './types';

export const mapToNextStepDefault = (campaignStep: CampaignStep): CampaignStep => {
  switch (campaignStep) {
    case 'preface': {
      return 'build';
    }
    case 'build': {
      return 'compile';
    }
    case 'compile': {
      // set step to 'approval' without using mapper if unapproved templates exist
      return 'audience';
    }
    case 'approval': {
      // need to compile again after getting all templates approved
      return 'compile';
    }
    case 'audience': {
      return 'recipients';
    }
    case 'recipients': {
      return 'schedule';
    }
    case 'schedule': {
      return 'conclusion';
    }
    default: {
      return 'build';
    }
  }
};

export const mapToPreviousStep = (campaignStep: CampaignStep): CampaignStep => {
  switch (campaignStep) {
    case 'recipients': {
      return 'audience';
    }
    case 'schedule': {
      return 'recipients';
    }
    case 'conclusion': {
      return 'schedule';
    }
    default: {
      return 'build';
    }
  }
};
