import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { InitializeSpreadsheetVariables, InitializeSpreadsheetResponse, INITIALIZE_SPREADSHEET_URL } from './types';

export const useInitializeSpreadsheetMutation = (
  options?: Omit<
    UseMutationOptions<InitializeSpreadsheetResponse, ConnectlyError, InitializeSpreadsheetVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, sendoutId, ...rest }: InitializeSpreadsheetVariables) =>
      api.campaignV3(INITIALIZE_SPREADSHEET_URL, 'post', {
        path: { 'input.businessId': businessId, 'input.sendoutId': sendoutId },
        body: { input: rest },
      }) as Promise<InitializeSpreadsheetResponse>,
    options,
  );
