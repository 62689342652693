import { keyBy } from 'lodash';

export const buttonLocalization = keyBy(
  [
    {
      Language: 'en',
      Yes: 'Yes',
      No: 'No',
      'Tell me more': 'Tell me more',
      'No thanks': 'No thanks',
      'Show me': 'Show me',
      'Click here to use code': 'Click here to use code',
    },
    {
      Language: 'es',
      Yes: 'Sí',
      No: 'No',
      'Tell me more': 'Dime más',
      'No thanks': 'No, gracias',
      'Show me': 'Muéstrame',
      'Click here to use code': 'Haga clic aquí para usar el código',
    },
    {
      Language: 'pt_BR',
      Yes: 'Sim',
      No: 'Não',
      'Tell me more': 'Me diga mais',
      'No thanks': 'Não, obrigado',
      'Show me': 'Mostre-me',
      'Click here to use code': 'Clique aqui para usar o código',
    },
    {
      Language: 'sq',
      Yes: 'po',
      No: 'Jo',
      'Tell me more': 'Me trego me shume',
      'No thanks': 'Jo faleminderit',
      'Show me': 'Me trego',
      'Click here to use code': 'Klikoni këtu për të përdorur kodin',
    },
    {
      Language: 'ar',
      Yes: 'نعم',
      No: 'لا',
      'Tell me more': 'اخبرني المزيد',
      'No thanks': 'ًلا شكرا',
      'Show me': 'أرِنِي',
      'Click here to use code': 'انقر هنا لاستخدام الرمز',
    },
    {
      Language: 'az',
      Yes: 'Bəli',
      No: 'Yox',
      'Tell me more': 'Mənə daha çox danış',
      'No thanks': 'Xeyr, təşəkkürlər',
      'Show me': 'Mənə göstər',
      'Click here to use code': 'Kod istifadə etmək üçün buraya vurun',
    },
    {
      Language: 'bn',
      Yes: 'হ্যাঁ',
      No: 'না',
      'Tell me more': 'আমাকে আরো বল',
      'No thanks': 'না ধন্যবাদ',
      'Show me': 'আমাকে দেখাও',
      'Click here to use code': 'কোড ব্যবহার করতে এখানে ক্লিক করুন',
    },
    {
      Language: 'bg',
      Yes: 'Да',
      No: 'Не',
      'Tell me more': 'Кажи ми повече',
      'No thanks': 'Не благодаря',
      'Show me': 'Покажи ми',
      'Click here to use code': 'Щракнете тук, за да използвате код',
    },
    {
      Language: 'zh_CN',
      Yes: '是的',
      No: '不',
      'Tell me more': '告诉我更多',
      'No thanks': '不，谢谢',
      'Show me': '给我看看',
      'Click here to use code': '单击此处使用代码',
    },
    {
      Language: 'hr',
      Yes: 'Da',
      No: 'Ne',
      'Tell me more': 'Reci mi više',
      'No thanks': 'Ne hvala',
      'Show me': 'Pokaži mi',
      'Click here to use code': 'Kliknite ovdje da biste koristili kôd',
    },
    {
      Language: 'cs',
      Yes: 'Ano',
      No: 'Ne',
      'Tell me more': 'Řekni mi více',
      'No thanks': 'Ne, díky',
      'Show me': 'Ukaž mi',
      'Click here to use code': 'Kliknutím sem používáte kód',
    },
    {
      Language: 'da',
      Yes: 'Ja',
      No: 'Ingen',
      'Tell me more': 'Fortæl mig mere',
      'No thanks': 'Nej tak',
      'Show me': 'Vis mig',
      'Click here to use code': 'Klik her for at bruge kode',
    },
    {
      Language: 'nl',
      Yes: 'Ja',
      No: 'Nee',
      'Tell me more': 'Vertel me meer',
      'No thanks': 'Nee bedankt',
      'Show me': 'Laat het me zien',
      'Click here to use code': 'Klik hier om code te gebruiken',
    },
    {
      Language: 'et',
      Yes: 'Jah',
      No: 'Mitte',
      'Tell me more': 'Räägi mulle rohkem',
      'No thanks': 'Ei aitäh',
      'Show me': 'Näita mulle',
      'Click here to use code': 'Koodi kasutamiseks klõpsake siin',
    },
    {
      Language: 'fil',
      Yes: 'Oo',
      No: 'Hindi',
      'Tell me more': 'Sabihin sa akin ang higit pa',
      'No thanks': 'Salamat nalang',
      'Show me': 'Ipakita mo saakin',
      'Click here to use code': 'Mag -click dito upang magamit ang code',
    },
    {
      Language: 'fi',
      Yes: 'Joo',
      No: 'Ei',
      'Tell me more': 'Kerro minulle lisää',
      'No thanks': 'Ei kiitos',
      'Show me': 'Näytä minulle',
      'Click here to use code': 'Napsauta tätä käyttääksesi koodia',
    },
    {
      Language: 'fr',
      Yes: 'Oui',
      No: 'Non',
      'Tell me more': "Dis m'en plus",
      'No thanks': 'Non merci',
      'Show me': 'Montre-moi',
      'Click here to use code': 'Cliquez ici pour utiliser le code',
    },
    {
      Language: 'ka',
      Yes: 'დიახ',
      No: 'არა',
      'Tell me more': 'Მიამბე მეტი',
      'No thanks': 'Არა გმადლობთ',
      'Show me': 'Მაჩვენე',
      'Click here to use code': 'დააჭირეთ აქ, რომ გამოიყენოთ კოდი',
    },
    {
      Language: 'de',
      Yes: 'Ja',
      No: 'NEIN',
      'Tell me more': 'Erzähl mir mehr',
      'No thanks': 'Nein danke',
      'Show me': 'Zeig mir',
      'Click here to use code': 'Klicken Sie hier, um Code zu verwenden',
    },
    {
      Language: 'el',
      Yes: 'Ναί',
      No: 'Οχι',
      'Tell me more': 'Πες μου κι άλλα',
      'No thanks': 'Οχι ευχαριστώ',
      'Show me': 'Δείξε μου',
      'Click here to use code': 'Κάντε κλικ εδώ για να χρησιμοποιήσετε τον κωδικό',
    },
    {
      Language: 'gu',
      Yes: 'હા',
      No: 'કોઈ',
      'Tell me more': 'મને વધુ જણાવો',
      'No thanks': 'ના આભાર',
      'Show me': 'મને બતાવો',
      'Click here to use code': 'કોડનો ઉપયોગ કરવા માટે અહીં ક્લિક કરો',
    },
    {
      Language: 'he',
      Yes: 'כן',
      No: 'לא',
      'Tell me more': 'ספר לי עוד',
      'No thanks': 'לא תודה',
      'Show me': 'תראה לי',
      'Click here to use code': 'לחץ כאן לשימוש בקוד',
    },
    {
      Language: 'hi',
      Yes: 'हाँ',
      No: 'नहीं',
      'Tell me more': 'मुझे और बताएँ',
      'No thanks': 'जी नहीं, धन्यवाद',
      'Show me': 'मुझे दिखाओ',
      'Click here to use code': 'कोड का उपयोग करने के लिए यहां क्लिक करें',
    },
    {
      Language: 'hu',
      Yes: 'Igen',
      No: 'Nem',
      'Tell me more': 'Mondj többet',
      'No thanks': 'Nem köszönöm',
      'Show me': 'Mutasd meg nekem',
      'Click here to use code': 'Kattintson ide a kód használatához',
    },
    {
      Language: 'id',
      Yes: 'Ya',
      No: 'TIDAK',
      'Tell me more': 'Ceritakan lebih banyak',
      'No thanks': 'Tidak, terima kasih',
      'Show me': 'Perlihatkan pada saya',
      'Click here to use code': 'Klik di sini untuk menggunakan kode',
    },
    {
      Language: 'it',
      Yes: 'SÌ',
      No: 'NO',
      'Tell me more': 'Dimmi di più',
      'No thanks': 'No grazie',
      'Show me': 'Fammi vedere',
      'Click here to use code': 'Clicca qui per utilizzare il codice',
    },
    {
      Language: 'ja',
      Yes: 'はい',
      No: 'いいえ',
      'Tell me more': 'もっと教えてください',
      'No thanks': '結構です',
      'Show me': '見せて',
      'Click here to use code': 'コードを使用するにはここをクリックしてください',
    },
    {
      Language: 'kk',
      Yes: 'Иә',
      No: 'Жоқ',
      'Tell me more': 'Маған көбірек айтыңыз',
      'No thanks': 'Жоқ рақмет',
      'Show me': 'Маған көрсет',
      'Click here to use code': 'Кодты пайдалану үшін мына жерді басыңыз',
    },
    {
      Language: 'ko',
      Yes: '예',
      No: '아니요',
      'Tell me more': '더 말 해주세요',
      'No thanks': '고맙지 만 사양 할게',
      'Show me': '보여줘',
      'Click here to use code': '코드를 사용하려면 여기를 클릭하십시오',
    },
    {
      Language: 'ms',
      Yes: 'Ya',
      No: 'Tidak',
      'Tell me more': 'Beritahu saya lagi',
      'No thanks': 'Tidak, Terima kasih',
      'Show me': 'Tunjukkan kepada saya',
      'Click here to use code': 'Klik di sini untuk menggunakan kod',
    },
    {
      Language: 'ml',
      Yes: 'സമ്മതം',
      No: 'ഇല്ല',
      'Tell me more': 'എന്നോട് കൂടുതൽ പറയുക',
      'No thanks': 'വേണ്ട, നന്ദി',
      'Show me': 'എന്നെ കാണിക്കുക',
      'Click here to use code': 'കോഡ് ഉപയോഗിക്കുന്നതിന് ഇവിടെ ക്ലിക്കുചെയ്യുക',
    },
    {
      Language: 'mr',
      Yes: 'होय',
      No: 'नाही',
      'Tell me more': 'मला अधिक सांगा',
      'No thanks': 'नको धन्यवाद',
      'Show me': 'मला दाखवा',
      'Click here to use code': 'कोड वापरण्यासाठी येथे क्लिक करा',
    },
    {
      Language: 'nb',
      Yes: 'Ja',
      No: 'Nei',
      'Tell me more': 'Fortell meg mer',
      'No thanks': 'Nei takk',
      'Show me': 'Vis meg',
      'Click here to use code': 'Klikk her for å bruke kode',
    },
    {
      Language: 'fa',
      Yes: 'آره',
      No: 'هیچ',
      'Tell me more': 'بیشتر بگو',
      'No thanks': 'نه ممنون',
      'Show me': 'به من نشان دادن',
      'Click here to use code': 'برای استفاده از کد اینجا را کلیک کنید',
    },
    {
      Language: 'pl',
      Yes: 'Tak',
      No: 'NIE',
      'Tell me more': 'Powiedz mi więcej',
      'No thanks': 'Nie, dziękuję',
      'Show me': 'Pokaż mi',
      'Click here to use code': 'Kliknij tutaj, aby użyć kodu',
    },
    {
      Language: 'pa',
      Yes: 'ਹਾਂ',
      No: 'ਨਹੀਂ',
      'Tell me more': 'ਮੈਨੂੰ ਹੋਰ ਦੱਸੋ',
      'No thanks': 'ਨਹੀਂ ਧੰਨਵਾਦ',
      'Show me': 'ਮੈਨੂੰ ਵਿਖਾਓ',
      'Click here to use code': 'ਕੋਡ ਵਰਤਣ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ',
    },
    {
      Language: 'ro',
      Yes: 'da',
      No: 'Nu',
      'Tell me more': 'Spune-mi mai multe',
      'No thanks': 'Nu, mulțumesc',
      'Show me': 'Arătaţi-mi',
      'Click here to use code': 'Faceți clic aici pentru a utiliza codul',
    },
    {
      Language: 'ru',
      Yes: 'Да',
      No: 'Нет',
      'Tell me more': 'Расскажи мне больше',
      'No thanks': 'Нет, спасибо',
      'Show me': 'Покажите мне',
      'Click here to use code': 'Нажмите здесь, чтобы использовать код',
    },
    {
      Language: 'sr',
      Yes: 'да',
      No: 'Не',
      'Tell me more': 'Реци ми више',
      'No thanks': 'Не хвала',
      'Show me': 'Покажи ми',
      'Click here to use code': 'Кликните овде да бисте користили код',
    },
    {
      Language: 'sk',
      Yes: 'Áno',
      No: 'Nie',
      'Tell me more': 'Povedz mi viac',
      'No thanks': 'Nie ďakujem',
      'Show me': 'Ukáž mi',
      'Click here to use code': 'Kliknite sem a použite kód',
    },
    {
      Language: 'sl',
      Yes: 'DA',
      No: 'Ne',
      'Tell me more': 'Povej mi več',
      'No thanks': 'Ne hvala',
      'Show me': 'Pokaži mi',
      'Click here to use code': 'Kliknite tukaj, če želite uporabiti kodo',
    },
    {
      Language: 'sv',
      Yes: 'Ja',
      No: 'Nej',
      'Tell me more': 'Berätta mer',
      'No thanks': 'Nej tack',
      'Show me': 'Visa mig',
      'Click here to use code': 'Klicka här för att använda koden',
    },
    {
      Language: 'ta',
      Yes: 'ஆம்',
      No: 'இல்லை',
      'Tell me more': 'இன்னும் எனக்கு சொல்லுங்கள்',
      'No thanks': 'நன்றி இல்லை',
      'Show me': 'என்னைக் காட்டு',
      'Click here to use code': 'குறியீட்டைப் பயன்படுத்த இங்கே கிளிக் செய்க',
    },
    {
      Language: 'te',
      Yes: 'అవును',
      No: 'లేదు',
      'Tell me more': 'మరి కొంత చెప్పు',
      'No thanks': 'ధన్యవాదాలు',
      'Show me': 'నాకు చూపించు',
      'Click here to use code': 'కోడ్‌ను ఉపయోగించడానికి ఇక్కడ క్లిక్ చేయండి',
    },
    {
      Language: 'th',
      Yes: 'ใช่',
      No: 'เลขที่',
      'Tell me more': 'บอกรายละเอียดฉันเพิ่มเตืม',
      'No thanks': 'ไม่เป็นไรขอบคุณ',
      'Show me': 'แสดงให้ฉันเห็น',
      'Click here to use code': 'คลิกที่นี่เพื่อใช้รหัส',
    },
    {
      Language: 'tr',
      Yes: 'Evet',
      No: 'Hayır',
      'Tell me more': 'Bana daha fazlasını anlat',
      'No thanks': 'Hayır, teşekkürler',
      'Show me': 'Bana göster',
      'Click here to use code': 'Kodu kullanmak için buraya tıklayın',
    },
    {
      Language: 'uk',
      Yes: 'Так',
      No: 'Немає',
      'Tell me more': 'Розкажи мені більше',
      'No thanks': 'Ні, дякую',
      'Show me': 'Покажи мені',
      'Click here to use code': 'Клацніть тут, щоб використовувати код',
    },
    {
      Language: 'ur',
      Yes: 'جی ہاں',
      No: 'نہیں',
      'Tell me more': 'مجھے اور بتاؤ',
      'No thanks': 'نہیں شکریہ',
      'Show me': 'مجھے دکھا',
      'Click here to use code': 'کوڈ استعمال کرنے کے لئے یہاں کلک کریں',
    },
    {
      Language: 'vi',
      Yes: 'Đúng',
      No: 'KHÔNG',
      'Tell me more': 'Nói cho tôi biết thêm',
      'No thanks': 'Không, cám ơn',
      'Show me': 'Cho tôi xem',
      'Click here to use code': 'Bấm vào đây để sử dụng mã',
    },
  ],
  'Language',
);
