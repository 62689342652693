import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.twilio.v20100401.messagingservices";
function createBaseAddPhoneNumberToMessagingServiceRequest() {
    return { serviceSid: "", phoneNumberSid: "" };
}
export const AddPhoneNumberToMessagingServiceRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.serviceSid !== "") {
            writer.uint32(10).string(message.serviceSid);
        }
        if (message.phoneNumberSid !== "") {
            writer.uint32(18).string(message.phoneNumberSid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddPhoneNumberToMessagingServiceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.serviceSid = reader.string();
                    break;
                case 2:
                    message.phoneNumberSid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            serviceSid: isSet(object.serviceSid) ? String(object.serviceSid) : "",
            phoneNumberSid: isSet(object.phoneNumberSid)
                ? String(object.phoneNumberSid)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.serviceSid !== undefined && (obj.serviceSid = message.serviceSid);
        message.phoneNumberSid !== undefined &&
            (obj.phoneNumberSid = message.phoneNumberSid);
        return obj;
    },
};
function createBasePhoneNumberToMessagingServiceAssociation() {
    return { sid: "", accountSid: "", serviceSid: "", phoneNumber: "" };
}
export const PhoneNumberToMessagingServiceAssociation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sid !== "") {
            writer.uint32(10).string(message.sid);
        }
        if (message.accountSid !== "") {
            writer.uint32(18).string(message.accountSid);
        }
        if (message.serviceSid !== "") {
            writer.uint32(26).string(message.serviceSid);
        }
        if (message.phoneNumber !== "") {
            writer.uint32(34).string(message.phoneNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePhoneNumberToMessagingServiceAssociation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sid = reader.string();
                    break;
                case 2:
                    message.accountSid = reader.string();
                    break;
                case 3:
                    message.serviceSid = reader.string();
                    break;
                case 4:
                    message.phoneNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sid: isSet(object.sid) ? String(object.sid) : "",
            accountSid: isSet(object.accountSid) ? String(object.accountSid) : "",
            serviceSid: isSet(object.serviceSid) ? String(object.serviceSid) : "",
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sid !== undefined && (obj.sid = message.sid);
        message.accountSid !== undefined && (obj.accountSid = message.accountSid);
        message.serviceSid !== undefined && (obj.serviceSid = message.serviceSid);
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
