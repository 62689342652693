import React, { useCallback, useMemo, useContext } from 'react';
import { useSetAtom } from 'jotai';
import { cohortsGeneratingAtom } from '@atoms/audience';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import produce from 'immer';
import {
  TargetIcon,
  Box,
  Label,
  GroupIcon,
  Stack,
  useTheme,
  Tooltip,
  ConnectlyCard,
  WithSkeleton,
} from '@connectlyai-tenets/ui-styled-web';
import {
  EnhancedTable as ETable,
  EnhancedTableRowProps as ETableRowProps,
  TableHeadCell as THeadCell,
  sortString,
  sortNumber,
  sortDate,
} from '@components/EnhancedTable';
import {
  generateUseAudienceSegmentsPlaceholderData,
  selectBusinessId,
  selectTableSegments,
  useAudienceSegmentsData,
  useMeData,
  useDeleteAudienceSegmentMutation,
  AudienceSegmentsResponse,
  EngagedCustomerQueryView,
  selectLatestCohort,
  useAudienceSegmentCohorts,
} from '@hooks';
import { formatDate } from '../../../../utils';
import { NotificationContext } from '../../../../contexts';
import { selectQueryText } from '../SmartAudiences';
import { PillDisplay } from '../../../../presentation/common/PillDisplay';
import { AudienceFilter } from '../../types';

const placeholderData = generateUseAudienceSegmentsPlaceholderData(10);

const NameNode = ({ text, name, query }: { text: string; name?: string; query?: EngagedCustomerQueryView }) => {
  const queryText = useMemo(() => {
    if (!query) {
      return '';
    }
    return selectQueryText({ queryView: query });
  }, [query]);
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Label variant="body1">{name}</Label>
      {queryText && (
        <Tooltip title={queryText} placement="top">
          <Box>
            <PillDisplay sx={{ backgroundColor: '#F1EEFA', ml: 1 }}>
              <TargetIcon />
              <Label variant="caption" sx={{ ml: 0.5 }}>
                {text}
              </Label>
            </PillDisplay>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

const SegmentSize = ({
  businessId,
  segmentId,
  size,
  isSmart,
}: {
  businessId?: string;
  segmentId?: string;
  size?: number;
  isSmart: boolean;
}) => {
  const setCohortsGenerating = useSetAtom(cohortsGeneratingAtom);
  const { data: cohort, isLoading } = useAudienceSegmentCohorts(
    { businessId: businessId || '', segmentId },
    {
      select: selectLatestCohort,
      enabled: Boolean(businessId && segmentId && isSmart),
      refetchInterval: (fetchedData, _) => {
        if (fetchedData?.id && fetchedData?.segmentId) {
          setCohortsGenerating((prev: string[]) => prev.filter((id: string) => id !== fetchedData.segmentId));
          return false;
        }
        return 3000;
      },
    },
  );
  const displaySize = useMemo(() => {
    return isSmart ? (cohort?.size ?? 100) : size; // 100 is a placeholder
  }, [cohort?.size, isSmart, size]);
  const showSkeleton = useMemo(() => {
    return Boolean(isSmart && (isLoading || cohort === undefined));
  }, [isSmart, isLoading, cohort]);
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={0.5} color="text.secondary">
      <GroupIcon sx={{ width: theme.spacing(2.25), height: theme.spacing(2.25) }} />
      <WithSkeleton wrap={showSkeleton}>
        <Label variant="body1">{displaySize}</Label>
      </WithSkeleton>
    </Stack>
  );
};

const useAudiencesTable = ({ filter }: { filter: AudienceFilter }) => {
  const theme = useTheme();
  const { notificationProvider } = useContext(NotificationContext);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: segmentsData, isLoading } = useAudienceSegmentsData(
    { businessId: businessId || '' },
    {
      select: selectTableSegments,
      placeholderData,
      enabled: !!businessId,
    },
  );
  const queryClient = useQueryClient();
  const { mutate: deleteAudienceSegment } = useDeleteAudienceSegmentMutation({
    onMutate: () => {},
    onError: () => {
      notificationProvider().notify({
        surface: NotificationSurface.SNACKBAR,
        notification: {
          message: 'Something went wrong while deleting audience. Please try again.',
          icon: '',
          severity: NotificationSeverity.ERROR,
        },
      });
    },
    onSuccess: (_, { segmentId }) => {
      const hasData = Boolean(queryClient.getQueryData<AudienceSegmentsResponse>(['audienceSegments']));
      if (!hasData) {
        return;
      }

      queryClient.setQueryData<AudienceSegmentsResponse>(['audienceSegments'], (data) =>
        produce(data, (draft: AudienceSegmentsResponse) => {
          const index = draft?.entity?.segments?.findIndex((x) => x.segment?.id === segmentId);
          if (index !== undefined && index !== -1) {
            draft?.entity?.segments?.splice(index, 1);
          }

          return draft;
        }),
      );
    },
  });
  const { t } = useTranslation('translation', { keyPrefix: 'audience.manage.table' });
  const tableHead: THeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        label: t('columns.name'),
        width: '40%',
        align: 'left',
        sort: sortString,
      },
      {
        id: 'createdAt',
        label: t('columns.createdAt'),
        width: '25%',
        align: 'center',
        sort: sortDate,
      },
      {
        id: 'updatedAt',
        label: t('columns.updatedAt'),
        width: '25%',
        align: 'center',
        sort: sortDate,
      },
      {
        id: 'size',
        label: t('columns.size'),
        width: '10%',
        align: 'right',
        sort: sortNumber,
      },
    ];
  }, [t]);
  const smartText = useMemo(() => {
    return t('smart');
  }, [t]);
  const rows: ETableRowProps[] = useMemo(() => {
    if (!segmentsData) return [];
    return segmentsData
      .filter((segment) => {
        if (filter === 'SMART') return !!segment.query;
        if (filter === 'IMPORTED') return !segment.query;
        return true;
      })
      .map((segment) => {
        return {
          id: segment?.id || '',
          selectable: segment.selectable,
          data: [
            {
              value: segment?.name,
              display: <NameNode name={segment?.name} query={segment?.query} text={smartText} />,
            },
            {
              value: segment?.createdAt,
              display: (
                <Stack direction="column" alignItems="center">
                  <Label variant="body1">
                    {formatDate(segment?.createdAt || '', { year: '2-digit', month: '2-digit', day: '2-digit' })}
                  </Label>
                  <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {formatDate(segment?.createdAt || '', { hour: '2-digit', minute: '2-digit', hour12: false })}
                  </Label>
                </Stack>
              ),
              align: 'center',
            },
            {
              value: segment?.updatedAt,
              display: (
                <Stack direction="column" alignItems="center">
                  <Label variant="body1">
                    {formatDate(segment?.updatedAt || '', { year: '2-digit', month: '2-digit', day: '2-digit' })}
                  </Label>
                  <Label variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {formatDate(segment?.updatedAt || '', { hour: '2-digit', minute: '2-digit', hour12: false })}
                  </Label>
                </Stack>
              ),
              align: 'center',
            },
            {
              value: segment?.size,
              display: (
                <SegmentSize
                  key={segment?.id}
                  businessId={businessId}
                  segmentId={segment?.id}
                  size={segment?.size}
                  isSmart={!!segment?.query}
                />
              ),
              align: 'right',
            },
          ],
        };
      });
  }, [businessId, segmentsData, smartText, filter, theme.palette.text.secondary]);
  const title = useCallback(
    (numAudiences: number) => {
      const isPlural = numAudiences !== 1;
      const lookup = isPlural ? 'plural' : 'singular';
      return `${numAudiences} ${t(`title.${lookup}`)}`;
    },
    [t],
  );
  const searchFn = useCallback((row: ETableRowProps, search: string) => {
    const itemLookup = String(row.data[0].value) || '';
    return itemLookup.includes(search);
  }, []);
  const searchSupport = useMemo(() => {
    return {
      searchFn,
      placeholder: t('searchPlaceholder'),
    };
  }, [searchFn, t]);
  const handleDelete = useCallback(
    async (ids: string[]) => {
      if (!businessId) {
        return;
      }
      await Promise.all(ids.map((id) => deleteAudienceSegment({ businessId: businessId || '', segmentId: id })));
    },
    [businessId, deleteAudienceSegment],
  );
  const deleteSupport = useMemo(() => {
    if (!handleDelete) return undefined;
    return {
      handleAction: handleDelete,
      confirmModal: {
        title: (numRows: number) => {
          const isPlural = numRows > 1;
          return `Are you sure you want to delete ${isPlural ? 'these audiences' : 'this audience'}?`;
        },
        subtitle: (numRows: number) => {
          const isPlural = numRows > 1;
          return `${numRows} audiences${isPlural ? 's' : ''} will be removed.`;
        },
      },
      controls: {
        actionText: (numRows: number, editing: boolean) => {
          if (!editing) return 'Edit';
          return `Delete ${numRows === 1 ? 'audience' : `${numRows} audiences`}`;
        },
      },
    };
  }, [handleDelete]);
  return {
    tableHead,
    rows,
    title,
    isLoading,
    searchSupport,
    deleteSupport,
  };
};

export const AudiencesTable = ({ filter }: { filter: AudienceFilter }) => {
  const { tableHead, rows, title, isLoading, searchSupport, deleteSupport } = useAudiencesTable({ filter });
  return (
    <ConnectlyCard>
      <ETable
        rows={rows}
        headers={tableHead}
        sortDefault={{ by: 'createdAt', order: 'desc' }}
        selectSupport={deleteSupport}
        title={title}
        icon={<GroupIcon />}
        searchSupport={searchSupport}
        isLoading={isLoading}
      />
    </ConnectlyCard>
  );
};
