import React, { useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Position, Handle } from 'react-flow-renderer';
import { useTheme, PlusIcon, Box } from '@connectlyai-tenets/ui-styled-web';
import { createHandleId } from '../../sdks/flow/createHandleId';
import { FlowContext } from '../../contexts';
import { HandleLine } from './HandleLine';
import { SourceHandleProps, SourceHandleRef } from './types';

const useSourceHandle = ({ isHidden, ...handleType }: SourceHandleProps) => {
  const isDocumentEditable = useContextSelector(FlowContext, (context) => context.isDocumentEditable);
  const { createNodeAtHandle, isHandleConnected } = useContextSelector(
    FlowContext,
    (context) => context.flowChangeAppliers,
  );

  const handleId = createHandleId(handleType);
  const isConnected = isHandleConnected(handleType);

  const canAddNode = useMemo(() => !isConnected && isDocumentEditable, [isConnected, isDocumentEditable]);
  const isDisabled = useMemo(() => !isDocumentEditable, [isDocumentEditable]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (canAddNode) {
        createNodeAtHandle(handleType);
        event.stopPropagation();
      }
    },
    [canAddNode, createNodeAtHandle, handleType],
  );

  const radius = useMemo(() => (canAddNode && !isHidden ? 8 : 4), [canAddNode, isHidden]);

  return { canAddNode, isDisabled, handleId, isConnected, handleClick, radius };
};

// eslint-disable-next-line react/display-name
export const SourceHandle = forwardRef<SourceHandleRef, SourceHandleProps>(
  ({ handleLineLength, isHidden, ...handleType }, ref) => {
    const { canAddNode, isConnected, isDisabled, handleId, handleClick, radius } = useSourceHandle({
      isHidden,
      ...handleType,
    });
    const theme = useTheme();

    useImperativeHandle(ref, () => ({
      handleClick,
      isDisabled,
    }));

    if (isHidden && !isConnected)
      return (
        <Handle
          type="source"
          position={Position.Right}
          id={handleId}
          style={{
            opacity: 0,
          }}
        />
      );

    return (
      <>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-end',
            right: 2 - radius,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <Handle
            type="source"
            position={Position.Right}
            id={handleId}
            style={{
              alignItems: 'center',
              background: theme.palette.connectionLine,
              border: `2px solid ${theme.palette.common.white}`,
              borderRadius: '100%',
              boxShadow: `0px 2px 2px ${theme.palette.grey[400]}`,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              height: radius * 2,
              justifyContent: 'center',
              width: radius * 2,
              marginLeft: -2 * radius,
            }}
            onClick={handleClick}
          >
            {canAddNode && <PlusIcon style={{ pointerEvents: 'none' }} />}
          </Handle>
        </Box>
        {isConnected && handleLineLength && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'flex-end',
              left: handleLineLength,
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 3,
            }}
          >
            <HandleLine width={handleLineLength} />
          </Box>
        )}
      </>
    );
  },
);
