import { Paths } from '@connectlyai-tenets/sdk';

export const SOFIA_ANALYTICS_URL = '/internal/v1/businesses/{query.businessId}/query_sofia_analytics';

type SofiaAnalyticsUrl = typeof SOFIA_ANALYTICS_URL;

export type SofiaAnalyticsBodyParameters = Paths['analyticsV1'][SofiaAnalyticsUrl]['post']['parameters']['body'];
export type SofiaAnalyticsParameters = { businessId: string } & SofiaAnalyticsBodyParameters;
export type SofiaAnalyticsResponse = Paths['analyticsV1'][SofiaAnalyticsUrl]['post']['responses']['200']['schema'];
export type SofiaAnalyticsData = NonNullable<NonNullable<SofiaAnalyticsResponse['entity']>['items']>;
