import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.integration";
export var IntegrationType;
(function (IntegrationType) {
    IntegrationType[IntegrationType["INTEGRATION_TYPE_UNSPECIFIED"] = 0] = "INTEGRATION_TYPE_UNSPECIFIED";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER"] = 1] = "INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT"] = 2] = "INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING"] = 3] = "INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_AUTO_REPLY_V1"] = 4] = "INTEGRATION_TYPE_AUTO_REPLY_V1";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_SHOPIFY_RECOMMENDER"] = 5] = "INTEGRATION_TYPE_SHOPIFY_RECOMMENDER";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_BCA_MERCHANT_CATALOG"] = 6] = "INTEGRATION_TYPE_BCA_MERCHANT_CATALOG";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_SALES_BOT"] = 7] = "INTEGRATION_TYPE_SALES_BOT";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT"] = 8] = "INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_SALES_BOT_LITE"] = 9] = "INTEGRATION_TYPE_SALES_BOT_LITE";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER"] = 10] = "INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER";
    IntegrationType[IntegrationType["INTEGRATION_TYPE_SALES_ASSISTANT"] = 11] = "INTEGRATION_TYPE_SALES_ASSISTANT";
    IntegrationType[IntegrationType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(IntegrationType || (IntegrationType = {}));
export function integrationTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "INTEGRATION_TYPE_UNSPECIFIED":
            return IntegrationType.INTEGRATION_TYPE_UNSPECIFIED;
        case 1:
        case "INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER":
            return IntegrationType.INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER;
        case 2:
        case "INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT":
            return IntegrationType.INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT;
        case 3:
        case "INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING":
            return IntegrationType.INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING;
        case 4:
        case "INTEGRATION_TYPE_AUTO_REPLY_V1":
            return IntegrationType.INTEGRATION_TYPE_AUTO_REPLY_V1;
        case 5:
        case "INTEGRATION_TYPE_SHOPIFY_RECOMMENDER":
            return IntegrationType.INTEGRATION_TYPE_SHOPIFY_RECOMMENDER;
        case 6:
        case "INTEGRATION_TYPE_BCA_MERCHANT_CATALOG":
            return IntegrationType.INTEGRATION_TYPE_BCA_MERCHANT_CATALOG;
        case 7:
        case "INTEGRATION_TYPE_SALES_BOT":
            return IntegrationType.INTEGRATION_TYPE_SALES_BOT;
        case 8:
        case "INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT":
            return IntegrationType.INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT;
        case 9:
        case "INTEGRATION_TYPE_SALES_BOT_LITE":
            return IntegrationType.INTEGRATION_TYPE_SALES_BOT_LITE;
        case 10:
        case "INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER":
            return IntegrationType.INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER;
        case 11:
        case "INTEGRATION_TYPE_SALES_ASSISTANT":
            return IntegrationType.INTEGRATION_TYPE_SALES_ASSISTANT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return IntegrationType.UNRECOGNIZED;
    }
}
export function integrationTypeToJSON(object) {
    switch (object) {
        case IntegrationType.INTEGRATION_TYPE_UNSPECIFIED:
            return "INTEGRATION_TYPE_UNSPECIFIED";
        case IntegrationType.INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER:
            return "INTEGRATION_TYPE_CUSTOM_ZENDESK_HANDOVER";
        case IntegrationType.INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT:
            return "INTEGRATION_TYPE_CUSTOM_SHOPIFY_ABANDONED_CHECKOUT";
        case IntegrationType.INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING:
            return "INTEGRATION_TYPE_CUSTOM_SHOPIFY_MULTI_PRODUCT_MARKETING";
        case IntegrationType.INTEGRATION_TYPE_AUTO_REPLY_V1:
            return "INTEGRATION_TYPE_AUTO_REPLY_V1";
        case IntegrationType.INTEGRATION_TYPE_SHOPIFY_RECOMMENDER:
            return "INTEGRATION_TYPE_SHOPIFY_RECOMMENDER";
        case IntegrationType.INTEGRATION_TYPE_BCA_MERCHANT_CATALOG:
            return "INTEGRATION_TYPE_BCA_MERCHANT_CATALOG";
        case IntegrationType.INTEGRATION_TYPE_SALES_BOT:
            return "INTEGRATION_TYPE_SALES_BOT";
        case IntegrationType.INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT:
            return "INTEGRATION_TYPE_CUSTOM_VTEX_ABANDONED_CHECKOUT";
        case IntegrationType.INTEGRATION_TYPE_SALES_BOT_LITE:
            return "INTEGRATION_TYPE_SALES_BOT_LITE";
        case IntegrationType.INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER:
            return "INTEGRATION_TYPE_CUSTOM_VTEX_PAYMENT_REMINDER";
        case IntegrationType.INTEGRATION_TYPE_SALES_ASSISTANT:
            return "INTEGRATION_TYPE_SALES_ASSISTANT";
        case IntegrationType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var IntegrationState;
(function (IntegrationState) {
    IntegrationState[IntegrationState["INTEGRATION_STATE_UNSPECIFIED"] = 0] = "INTEGRATION_STATE_UNSPECIFIED";
    IntegrationState[IntegrationState["INTEGRATION_STATE_ACTIVE"] = 1] = "INTEGRATION_STATE_ACTIVE";
    IntegrationState[IntegrationState["INTEGRATION_STATE_DISABLED"] = 2] = "INTEGRATION_STATE_DISABLED";
    IntegrationState[IntegrationState["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(IntegrationState || (IntegrationState = {}));
export function integrationStateFromJSON(object) {
    switch (object) {
        case 0:
        case "INTEGRATION_STATE_UNSPECIFIED":
            return IntegrationState.INTEGRATION_STATE_UNSPECIFIED;
        case 1:
        case "INTEGRATION_STATE_ACTIVE":
            return IntegrationState.INTEGRATION_STATE_ACTIVE;
        case 2:
        case "INTEGRATION_STATE_DISABLED":
            return IntegrationState.INTEGRATION_STATE_DISABLED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return IntegrationState.UNRECOGNIZED;
    }
}
export function integrationStateToJSON(object) {
    switch (object) {
        case IntegrationState.INTEGRATION_STATE_UNSPECIFIED:
            return "INTEGRATION_STATE_UNSPECIFIED";
        case IntegrationState.INTEGRATION_STATE_ACTIVE:
            return "INTEGRATION_STATE_ACTIVE";
        case IntegrationState.INTEGRATION_STATE_DISABLED:
            return "INTEGRATION_STATE_DISABLED";
        case IntegrationState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCustomZendeskHandoverMetadata() {
    return { ticketFlowId: "", firstResponseFlowId: "", agentHandoverFlowId: "" };
}
export const CustomZendeskHandoverMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticketFlowId !== "") {
            writer.uint32(10).string(message.ticketFlowId);
        }
        if (message.firstResponseFlowId !== "") {
            writer.uint32(18).string(message.firstResponseFlowId);
        }
        if (message.agentHandoverFlowId !== "") {
            writer.uint32(26).string(message.agentHandoverFlowId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomZendeskHandoverMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticketFlowId = reader.string();
                    break;
                case 2:
                    message.firstResponseFlowId = reader.string();
                    break;
                case 3:
                    message.agentHandoverFlowId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticketFlowId: isSet(object.ticketFlowId)
                ? String(object.ticketFlowId)
                : "",
            firstResponseFlowId: isSet(object.firstResponseFlowId)
                ? String(object.firstResponseFlowId)
                : "",
            agentHandoverFlowId: isSet(object.agentHandoverFlowId)
                ? String(object.agentHandoverFlowId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticketFlowId !== undefined &&
            (obj.ticketFlowId = message.ticketFlowId);
        message.firstResponseFlowId !== undefined &&
            (obj.firstResponseFlowId = message.firstResponseFlowId);
        message.agentHandoverFlowId !== undefined &&
            (obj.agentHandoverFlowId = message.agentHandoverFlowId);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutMetadata() {
    return {
        reminderFlowId: "",
        alwaysOnReminderFlowId: "",
        conversionFlowId: "",
        reminderTemplateWaId: "",
        checkoutLinkId: "",
        checkoutLinkSlug: "",
        v2: undefined,
    };
}
export const CustomShopifyAbandonedCheckoutMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reminderFlowId !== "") {
            writer.uint32(10).string(message.reminderFlowId);
        }
        if (message.alwaysOnReminderFlowId !== "") {
            writer.uint32(18).string(message.alwaysOnReminderFlowId);
        }
        if (message.conversionFlowId !== "") {
            writer.uint32(26).string(message.conversionFlowId);
        }
        if (message.reminderTemplateWaId !== "") {
            writer.uint32(34).string(message.reminderTemplateWaId);
        }
        if (message.checkoutLinkId !== "") {
            writer.uint32(42).string(message.checkoutLinkId);
        }
        if (message.checkoutLinkSlug !== "") {
            writer.uint32(50).string(message.checkoutLinkSlug);
        }
        if (message.v2 !== undefined) {
            CustomShopifyAbandonedCheckoutMetadata_V2.encode(message.v2, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminderFlowId = reader.string();
                    break;
                case 2:
                    message.alwaysOnReminderFlowId = reader.string();
                    break;
                case 3:
                    message.conversionFlowId = reader.string();
                    break;
                case 4:
                    message.reminderTemplateWaId = reader.string();
                    break;
                case 5:
                    message.checkoutLinkId = reader.string();
                    break;
                case 6:
                    message.checkoutLinkSlug = reader.string();
                    break;
                case 7:
                    message.v2 = CustomShopifyAbandonedCheckoutMetadata_V2.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminderFlowId: isSet(object.reminderFlowId)
                ? String(object.reminderFlowId)
                : "",
            alwaysOnReminderFlowId: isSet(object.alwaysOnReminderFlowId)
                ? String(object.alwaysOnReminderFlowId)
                : "",
            conversionFlowId: isSet(object.conversionFlowId)
                ? String(object.conversionFlowId)
                : "",
            reminderTemplateWaId: isSet(object.reminderTemplateWaId)
                ? String(object.reminderTemplateWaId)
                : "",
            checkoutLinkId: isSet(object.checkoutLinkId)
                ? String(object.checkoutLinkId)
                : "",
            checkoutLinkSlug: isSet(object.checkoutLinkSlug)
                ? String(object.checkoutLinkSlug)
                : "",
            v2: isSet(object.v2)
                ? CustomShopifyAbandonedCheckoutMetadata_V2.fromJSON(object.v2)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reminderFlowId !== undefined &&
            (obj.reminderFlowId = message.reminderFlowId);
        message.alwaysOnReminderFlowId !== undefined &&
            (obj.alwaysOnReminderFlowId = message.alwaysOnReminderFlowId);
        message.conversionFlowId !== undefined &&
            (obj.conversionFlowId = message.conversionFlowId);
        message.reminderTemplateWaId !== undefined &&
            (obj.reminderTemplateWaId = message.reminderTemplateWaId);
        message.checkoutLinkId !== undefined &&
            (obj.checkoutLinkId = message.checkoutLinkId);
        message.checkoutLinkSlug !== undefined &&
            (obj.checkoutLinkSlug = message.checkoutLinkSlug);
        message.v2 !== undefined &&
            (obj.v2 = message.v2
                ? CustomShopifyAbandonedCheckoutMetadata_V2.toJSON(message.v2)
                : undefined);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutMetadata_ReminderMetadata() {
    return {
        reminderFlowId: "",
        reminderTemplateWaId: "",
        reminderTemplateGroupId: "",
        checkoutLinkId: "",
        checkoutLinkSlug: "",
        salesBotMetadata: undefined,
    };
}
export const CustomShopifyAbandonedCheckoutMetadata_ReminderMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reminderFlowId !== "") {
            writer.uint32(10).string(message.reminderFlowId);
        }
        if (message.reminderTemplateWaId !== "") {
            writer.uint32(18).string(message.reminderTemplateWaId);
        }
        if (message.reminderTemplateGroupId !== "") {
            writer.uint32(34).string(message.reminderTemplateGroupId);
        }
        if (message.checkoutLinkId !== "") {
            writer.uint32(42).string(message.checkoutLinkId);
        }
        if (message.checkoutLinkSlug !== "") {
            writer.uint32(50).string(message.checkoutLinkSlug);
        }
        if (message.salesBotMetadata !== undefined) {
            CustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata.encode(message.salesBotMetadata, writer.uint32(402).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutMetadata_ReminderMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminderFlowId = reader.string();
                    break;
                case 2:
                    message.reminderTemplateWaId = reader.string();
                    break;
                case 4:
                    message.reminderTemplateGroupId = reader.string();
                    break;
                case 5:
                    message.checkoutLinkId = reader.string();
                    break;
                case 6:
                    message.checkoutLinkSlug = reader.string();
                    break;
                case 50:
                    message.salesBotMetadata =
                        CustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminderFlowId: isSet(object.reminderFlowId)
                ? String(object.reminderFlowId)
                : "",
            reminderTemplateWaId: isSet(object.reminderTemplateWaId)
                ? String(object.reminderTemplateWaId)
                : "",
            reminderTemplateGroupId: isSet(object.reminderTemplateGroupId)
                ? String(object.reminderTemplateGroupId)
                : "",
            checkoutLinkId: isSet(object.checkoutLinkId)
                ? String(object.checkoutLinkId)
                : "",
            checkoutLinkSlug: isSet(object.checkoutLinkSlug)
                ? String(object.checkoutLinkSlug)
                : "",
            salesBotMetadata: isSet(object.salesBotMetadata)
                ? CustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata.fromJSON(object.salesBotMetadata)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.reminderFlowId !== undefined &&
            (obj.reminderFlowId = message.reminderFlowId);
        message.reminderTemplateWaId !== undefined &&
            (obj.reminderTemplateWaId = message.reminderTemplateWaId);
        message.reminderTemplateGroupId !== undefined &&
            (obj.reminderTemplateGroupId = message.reminderTemplateGroupId);
        message.checkoutLinkId !== undefined &&
            (obj.checkoutLinkId = message.checkoutLinkId);
        message.checkoutLinkSlug !== undefined &&
            (obj.checkoutLinkSlug = message.checkoutLinkSlug);
        message.salesBotMetadata !== undefined &&
            (obj.salesBotMetadata = message.salesBotMetadata
                ? CustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata.toJSON(message.salesBotMetadata)
                : undefined);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata() {
    return { salesBotTemplateWaId: "", salesBotTemplateGroupId: "" };
}
export const CustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.salesBotTemplateWaId !== "") {
            writer.uint32(10).string(message.salesBotTemplateWaId);
        }
        if (message.salesBotTemplateGroupId !== "") {
            writer.uint32(18).string(message.salesBotTemplateGroupId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutMetadata_SalesBotMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.salesBotTemplateWaId = reader.string();
                    break;
                case 2:
                    message.salesBotTemplateGroupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            salesBotTemplateWaId: isSet(object.salesBotTemplateWaId)
                ? String(object.salesBotTemplateWaId)
                : "",
            salesBotTemplateGroupId: isSet(object.salesBotTemplateGroupId)
                ? String(object.salesBotTemplateGroupId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.salesBotTemplateWaId !== undefined &&
            (obj.salesBotTemplateWaId = message.salesBotTemplateWaId);
        message.salesBotTemplateGroupId !== undefined &&
            (obj.salesBotTemplateGroupId = message.salesBotTemplateGroupId);
        return obj;
    },
};
function createBaseCustomShopifyAbandonedCheckoutMetadata_V2() {
    return { rootFlowId: "", reminders: [], conversionFlowId: "" };
}
export const CustomShopifyAbandonedCheckoutMetadata_V2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rootFlowId !== "") {
            writer.uint32(10).string(message.rootFlowId);
        }
        for (const v of message.reminders) {
            CustomShopifyAbandonedCheckoutMetadata_ReminderMetadata.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.conversionFlowId !== "") {
            writer.uint32(26).string(message.conversionFlowId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyAbandonedCheckoutMetadata_V2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rootFlowId = reader.string();
                    break;
                case 2:
                    message.reminders.push(CustomShopifyAbandonedCheckoutMetadata_ReminderMetadata.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.conversionFlowId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rootFlowId: isSet(object.rootFlowId) ? String(object.rootFlowId) : "",
            reminders: Array.isArray(object === null || object === void 0 ? void 0 : object.reminders)
                ? object.reminders.map((e) => CustomShopifyAbandonedCheckoutMetadata_ReminderMetadata.fromJSON(e))
                : [],
            conversionFlowId: isSet(object.conversionFlowId)
                ? String(object.conversionFlowId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.rootFlowId !== undefined && (obj.rootFlowId = message.rootFlowId);
        if (message.reminders) {
            obj.reminders = message.reminders.map((e) => e
                ? CustomShopifyAbandonedCheckoutMetadata_ReminderMetadata.toJSON(e)
                : undefined);
        }
        else {
            obj.reminders = [];
        }
        message.conversionFlowId !== undefined &&
            (obj.conversionFlowId = message.conversionFlowId);
        return obj;
    },
};
function createBaseCustomShopifyMultiProductMarketingMetadata() {
    return { flowDocumentId: "" };
}
export const CustomShopifyMultiProductMarketingMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocumentId !== "") {
            writer.uint32(10).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomShopifyMultiProductMarketingMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseAutoReplyV1Metadata() {
    return { flowDocumentId: "" };
}
export const AutoReplyV1Metadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.flowDocumentId !== "") {
            writer.uint32(10).string(message.flowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAutoReplyV1Metadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.flowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            flowDocumentId: isSet(object.flowDocumentId)
                ? String(object.flowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.flowDocumentId !== undefined &&
            (obj.flowDocumentId = message.flowDocumentId);
        return obj;
    },
};
function createBaseShopifyRecommenderMetadata() {
    return { rootFlowDocumentId: "", templatesMetadata: [], linksMetadata: [] };
}
export const ShopifyRecommenderMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rootFlowDocumentId !== "") {
            writer.uint32(10).string(message.rootFlowDocumentId);
        }
        for (const v of message.templatesMetadata) {
            ShopifyRecommenderMetadata_TemplateMetadata.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.linksMetadata) {
            ShopifyRecommenderMetadata_LinkMetadata.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRecommenderMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rootFlowDocumentId = reader.string();
                    break;
                case 2:
                    message.templatesMetadata.push(ShopifyRecommenderMetadata_TemplateMetadata.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.linksMetadata.push(ShopifyRecommenderMetadata_LinkMetadata.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rootFlowDocumentId: isSet(object.rootFlowDocumentId)
                ? String(object.rootFlowDocumentId)
                : "",
            templatesMetadata: Array.isArray(object === null || object === void 0 ? void 0 : object.templatesMetadata)
                ? object.templatesMetadata.map((e) => ShopifyRecommenderMetadata_TemplateMetadata.fromJSON(e))
                : [],
            linksMetadata: Array.isArray(object === null || object === void 0 ? void 0 : object.linksMetadata)
                ? object.linksMetadata.map((e) => ShopifyRecommenderMetadata_LinkMetadata.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.rootFlowDocumentId !== undefined &&
            (obj.rootFlowDocumentId = message.rootFlowDocumentId);
        if (message.templatesMetadata) {
            obj.templatesMetadata = message.templatesMetadata.map((e) => e ? ShopifyRecommenderMetadata_TemplateMetadata.toJSON(e) : undefined);
        }
        else {
            obj.templatesMetadata = [];
        }
        if (message.linksMetadata) {
            obj.linksMetadata = message.linksMetadata.map((e) => e ? ShopifyRecommenderMetadata_LinkMetadata.toJSON(e) : undefined);
        }
        else {
            obj.linksMetadata = [];
        }
        return obj;
    },
};
function createBaseShopifyRecommenderMetadata_TemplateMetadata() {
    return { groupId: "", waId: "", name: "" };
}
export const ShopifyRecommenderMetadata_TemplateMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.groupId !== "") {
            writer.uint32(10).string(message.groupId);
        }
        if (message.waId !== "") {
            writer.uint32(18).string(message.waId);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRecommenderMetadata_TemplateMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.waId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            groupId: isSet(object.groupId) ? String(object.groupId) : "",
            waId: isSet(object.waId) ? String(object.waId) : "",
            name: isSet(object.name) ? String(object.name) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.groupId !== undefined && (obj.groupId = message.groupId);
        message.waId !== undefined && (obj.waId = message.waId);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
};
function createBaseShopifyRecommenderMetadata_LinkMetadata() {
    return { linkId: "", linkSlug: "", linkName: "" };
}
export const ShopifyRecommenderMetadata_LinkMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.linkId !== "") {
            writer.uint32(10).string(message.linkId);
        }
        if (message.linkSlug !== "") {
            writer.uint32(18).string(message.linkSlug);
        }
        if (message.linkName !== "") {
            writer.uint32(26).string(message.linkName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRecommenderMetadata_LinkMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.linkId = reader.string();
                    break;
                case 2:
                    message.linkSlug = reader.string();
                    break;
                case 3:
                    message.linkName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            linkId: isSet(object.linkId) ? String(object.linkId) : "",
            linkSlug: isSet(object.linkSlug) ? String(object.linkSlug) : "",
            linkName: isSet(object.linkName) ? String(object.linkName) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.linkId !== undefined && (obj.linkId = message.linkId);
        message.linkSlug !== undefined && (obj.linkSlug = message.linkSlug);
        message.linkName !== undefined && (obj.linkName = message.linkName);
        return obj;
    },
};
function createBaseBCAMerchantCatalogMetadata() {
    return { rootFlowDocumentId: "", paymentTemplateMetadata: undefined };
}
export const BCAMerchantCatalogMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rootFlowDocumentId !== "") {
            writer.uint32(10).string(message.rootFlowDocumentId);
        }
        if (message.paymentTemplateMetadata !== undefined) {
            BCAMerchantCatalogMetadata_PaymentTemplateMetadata.encode(message.paymentTemplateMetadata, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rootFlowDocumentId = reader.string();
                    break;
                case 2:
                    message.paymentTemplateMetadata =
                        BCAMerchantCatalogMetadata_PaymentTemplateMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rootFlowDocumentId: isSet(object.rootFlowDocumentId)
                ? String(object.rootFlowDocumentId)
                : "",
            paymentTemplateMetadata: isSet(object.paymentTemplateMetadata)
                ? BCAMerchantCatalogMetadata_PaymentTemplateMetadata.fromJSON(object.paymentTemplateMetadata)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.rootFlowDocumentId !== undefined &&
            (obj.rootFlowDocumentId = message.rootFlowDocumentId);
        message.paymentTemplateMetadata !== undefined &&
            (obj.paymentTemplateMetadata = message.paymentTemplateMetadata
                ? BCAMerchantCatalogMetadata_PaymentTemplateMetadata.toJSON(message.paymentTemplateMetadata)
                : undefined);
        return obj;
    },
};
function createBaseBCAMerchantCatalogMetadata_PaymentTemplateMetadata() {
    return { templateWaId: "", templateGroupId: "" };
}
export const BCAMerchantCatalogMetadata_PaymentTemplateMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.templateWaId !== "") {
            writer.uint32(10).string(message.templateWaId);
        }
        if (message.templateGroupId !== "") {
            writer.uint32(18).string(message.templateGroupId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBCAMerchantCatalogMetadata_PaymentTemplateMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.templateWaId = reader.string();
                    break;
                case 2:
                    message.templateGroupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            templateWaId: isSet(object.templateWaId)
                ? String(object.templateWaId)
                : "",
            templateGroupId: isSet(object.templateGroupId)
                ? String(object.templateGroupId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.templateWaId !== undefined &&
            (obj.templateWaId = message.templateWaId);
        message.templateGroupId !== undefined &&
            (obj.templateGroupId = message.templateGroupId);
        return obj;
    },
};
function createBaseSalesBotMetadata() {
    return { commandFlowDocumentId: "", commandEnFlowDocumentId: "" };
}
export const SalesBotMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.commandFlowDocumentId !== "") {
            writer.uint32(10).string(message.commandFlowDocumentId);
        }
        if (message.commandEnFlowDocumentId !== "") {
            writer.uint32(18).string(message.commandEnFlowDocumentId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSalesBotMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.commandFlowDocumentId = reader.string();
                    break;
                case 2:
                    message.commandEnFlowDocumentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            commandFlowDocumentId: isSet(object.commandFlowDocumentId)
                ? String(object.commandFlowDocumentId)
                : "",
            commandEnFlowDocumentId: isSet(object.commandEnFlowDocumentId)
                ? String(object.commandEnFlowDocumentId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.commandFlowDocumentId !== undefined &&
            (obj.commandFlowDocumentId = message.commandFlowDocumentId);
        message.commandEnFlowDocumentId !== undefined &&
            (obj.commandEnFlowDocumentId = message.commandEnFlowDocumentId);
        return obj;
    },
};
function createBaseCustomVtexAbandonedCheckoutMetadata() {
    return { metadataOneof: undefined };
}
export const CustomVtexAbandonedCheckoutMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.metadataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            CustomVtexAbandonedCheckoutMetadata_V1.encode(message.metadataOneof.v1, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomVtexAbandonedCheckoutMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 7:
                    message.metadataOneof = {
                        $case: "v1",
                        v1: CustomVtexAbandonedCheckoutMetadata_V1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            metadataOneof: isSet(object.v1)
                ? {
                    $case: "v1",
                    v1: CustomVtexAbandonedCheckoutMetadata_V1.fromJSON(object.v1),
                }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.metadataOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.metadataOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? CustomVtexAbandonedCheckoutMetadata_V1.toJSON((_c = message.metadataOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function createBaseCustomVtexAbandonedCheckoutMetadata_ReminderMetadata() {
    return {
        reminderFlowId: "",
        reminderTemplateWaId: "",
        reminderTemplateGroupId: "",
        checkoutLinkId: "",
        checkoutLinkSlug: "",
    };
}
export const CustomVtexAbandonedCheckoutMetadata_ReminderMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.reminderFlowId !== "") {
            writer.uint32(10).string(message.reminderFlowId);
        }
        if (message.reminderTemplateWaId !== "") {
            writer.uint32(18).string(message.reminderTemplateWaId);
        }
        if (message.reminderTemplateGroupId !== "") {
            writer.uint32(34).string(message.reminderTemplateGroupId);
        }
        if (message.checkoutLinkId !== "") {
            writer.uint32(42).string(message.checkoutLinkId);
        }
        if (message.checkoutLinkSlug !== "") {
            writer.uint32(50).string(message.checkoutLinkSlug);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomVtexAbandonedCheckoutMetadata_ReminderMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reminderFlowId = reader.string();
                    break;
                case 2:
                    message.reminderTemplateWaId = reader.string();
                    break;
                case 4:
                    message.reminderTemplateGroupId = reader.string();
                    break;
                case 5:
                    message.checkoutLinkId = reader.string();
                    break;
                case 6:
                    message.checkoutLinkSlug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            reminderFlowId: isSet(object.reminderFlowId)
                ? String(object.reminderFlowId)
                : "",
            reminderTemplateWaId: isSet(object.reminderTemplateWaId)
                ? String(object.reminderTemplateWaId)
                : "",
            reminderTemplateGroupId: isSet(object.reminderTemplateGroupId)
                ? String(object.reminderTemplateGroupId)
                : "",
            checkoutLinkId: isSet(object.checkoutLinkId)
                ? String(object.checkoutLinkId)
                : "",
            checkoutLinkSlug: isSet(object.checkoutLinkSlug)
                ? String(object.checkoutLinkSlug)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.reminderFlowId !== undefined &&
            (obj.reminderFlowId = message.reminderFlowId);
        message.reminderTemplateWaId !== undefined &&
            (obj.reminderTemplateWaId = message.reminderTemplateWaId);
        message.reminderTemplateGroupId !== undefined &&
            (obj.reminderTemplateGroupId = message.reminderTemplateGroupId);
        message.checkoutLinkId !== undefined &&
            (obj.checkoutLinkId = message.checkoutLinkId);
        message.checkoutLinkSlug !== undefined &&
            (obj.checkoutLinkSlug = message.checkoutLinkSlug);
        return obj;
    },
};
function createBaseCustomVtexAbandonedCheckoutMetadata_V1() {
    return { rootFlowId: "", reminders: [], conversionFlowId: "" };
}
export const CustomVtexAbandonedCheckoutMetadata_V1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rootFlowId !== "") {
            writer.uint32(10).string(message.rootFlowId);
        }
        for (const v of message.reminders) {
            CustomVtexAbandonedCheckoutMetadata_ReminderMetadata.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.conversionFlowId !== "") {
            writer.uint32(26).string(message.conversionFlowId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomVtexAbandonedCheckoutMetadata_V1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rootFlowId = reader.string();
                    break;
                case 2:
                    message.reminders.push(CustomVtexAbandonedCheckoutMetadata_ReminderMetadata.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.conversionFlowId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rootFlowId: isSet(object.rootFlowId) ? String(object.rootFlowId) : "",
            reminders: Array.isArray(object === null || object === void 0 ? void 0 : object.reminders)
                ? object.reminders.map((e) => CustomVtexAbandonedCheckoutMetadata_ReminderMetadata.fromJSON(e))
                : [],
            conversionFlowId: isSet(object.conversionFlowId)
                ? String(object.conversionFlowId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.rootFlowId !== undefined && (obj.rootFlowId = message.rootFlowId);
        if (message.reminders) {
            obj.reminders = message.reminders.map((e) => e
                ? CustomVtexAbandonedCheckoutMetadata_ReminderMetadata.toJSON(e)
                : undefined);
        }
        else {
            obj.reminders = [];
        }
        message.conversionFlowId !== undefined &&
            (obj.conversionFlowId = message.conversionFlowId);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
