import { ConnectlyError } from '@connectlyai-tenets/sdk';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { api } from '../../api';
import { VerifySpreadsheetVariables, VerifySpreadsheetResponse, VERIFY_SPREADSHEET_URL } from './types';

export const useVerifySpreadsheetMutation = (
  options?: Omit<
    UseMutationOptions<VerifySpreadsheetResponse, ConnectlyError, VerifySpreadsheetVariables, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) =>
  useMutation(
    ({ businessId, sendoutId, ...rest }: VerifySpreadsheetVariables) =>
      api.campaignV3(VERIFY_SPREADSHEET_URL, 'post', {
        path: { 'input.businessId': businessId, 'input.sendoutId': sendoutId },
        body: { input: rest },
      }) as Promise<VerifySpreadsheetResponse>,
    options,
  );
