import React from 'react';
import { Box, Label } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';

export const EmptySimpleTableView = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });

  return (
    <Box sx={{ height: '180px', display: 'table-row' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          height: '99%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          justifyContent: 'center',
          borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            right: 0,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              position: 'absolute',
              right: 0,
              left: 0,
              top: '10px',
              background: (theme) => theme.palette.grey[300],
              width: '100%',
              height: '1px',
            },
          }}
        >
          <Box sx={{ background: (theme) => theme.palette.common.white, width: 'max-content', zIndex: 1, px: 1 }}>
            <Label sx={{ whiteSpace: 'pre-line', color: (theme) => theme.palette.grey[700] }}>
              {t('noDataInRange', 'No data is available for the selected range')}
            </Label>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
