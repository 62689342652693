import React, { useEffect } from 'react';
import { Label, OpenInNewIcon, ReplyIcon, Stack, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useSetAtom } from 'jotai';
import { useNavigator } from '@scenes/Analytics/hooks';
import { analyticsPageEnhancementsAtom } from '@atoms/analytics';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ReadOnlyFlow } from '@components/FlowChartCampaignV3/components/ReadOnlyFlow';
import { removeTemplateIds } from '@components/FlowChartCampaignV3/components/CampaignPresets/utils';
import type { QuerySendoutAnalyticsResponse } from '@hooks/useQuerySendoutAnalytics';
import {
  generatePlaceholderData,
  selectBusinessId,
  useFlowDocumentData,
  useMeData,
  useQuerySendoutAnalytics,
} from '@hooks';
import { SIZE_CONTENT_L, SIZE_CONTENT_XXS } from 'src/ui-theme';
import { DeliveryStatsSection, DeliveryStatsSectionProps } from './DeliveryStatsSection';
import { TableSectionDataItem, TableSectionProps } from './ReportSection/types';
import { TableSection } from './ReportSection';

const reportPlaceholderData: QuerySendoutAnalyticsResponse = generatePlaceholderData(1);

export const useSendoutReport = (sendoutId: string) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.campaign' });
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const {
    data: reportDataRes,
    isLoading: isReportDataLoading,
    isFetching: isReportDataFetching,
  } = useQuerySendoutAnalytics(
    { businessId: businessId || '', sendouts: [sendoutId] },
    {
      enabled: Boolean(businessId),
      placeholderData: reportPlaceholderData,
      keepPreviousData: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  );
  const flowDocId = reportDataRes?.entity?.items?.[0]?.flowDocumentId;
  const {
    data: previewDocumentRes,
    isLoading: isLoadingPreviewDocument,
    isFetching: isFetchingPreviewDocument,
  } = useFlowDocumentData({
    businessId: businessId as string,
    flowDocumentId: flowDocId,
    enabled: Boolean(businessId && flowDocId),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const reportDataArr = reportDataRes?.entity?.items || [];
  const reportData = reportDataArr[0];

  const sendoutName =
    isReportDataFetching || isReportDataLoading ? 'Loading...' : reportData?.sendoutName || 'Loading...';

  const progressBarSections: DeliveryStatsSectionProps[] = [
    {
      title: t('sent'),
      value: reportData?.sentCount || 0,
      hint: t('sentHint'),
      percentValue: 100,
    },
    {
      title: t('delivered'),
      value: reportData?.deliveredCount || 0,
      hint: t('deliveredHint'),
      percentValue: ((reportData?.deliveredCount || 0) / (reportData?.sentCount || 1)) * 100,
    },
    {
      title: t('opened'),
      value: reportData?.readCount || 0,
      hint: t('openedHint'),
      percentValue: ((reportData?.readCount || 0) / (reportData?.deliveredCount || 1)) * 100,
    },
    {
      title: t('engaged'),
      value: reportData?.sendoutEngagement?.uniqueSessionEngagement || 0,
      hint: t('engagementHint'),
      percentValue:
        ((reportData?.sendoutEngagement?.uniqueSessionEngagement || 0) / (reportData?.deliveredCount || 1)) * 100,
    },
  ];

  const totalButtonClicks =
    reportData?.buttonClicks &&
    reportData?.buttonClicks.buttonClicks &&
    reportData?.buttonClicks.buttonClicks.length > 0
      ? reportData?.buttonClicks.buttonClicks.reduce((total, buttonClick) => (buttonClick.count ?? 0) + total, 0)
      : 0;
  const buttonClicksArr: TableSectionDataItem[] =
    reportData?.buttonClicks &&
    reportData?.buttonClicks.buttonClicks &&
    reportData?.buttonClicks.buttonClicks.length > 0
      ? reportData?.buttonClicks?.buttonClicks?.map((buttonClick) => {
          const dataItem: TableSectionDataItem = {
            label: buttonClick.button && buttonClick.button.text ? buttonClick.button.text : '',
            value: buttonClick.count || 0,
          };
          return dataItem;
        })
      : [];

  const totalLinkClicks =
    reportData?.linkClicks && reportData?.linkClicks.linkClicks && reportData?.linkClicks.linkClicks.length > 0
      ? reportData?.linkClicks.linkClicks.reduce((total, linkClick) => (linkClick.count ?? 0) + total, 0)
      : 0;
  const linkClicksArr: TableSectionDataItem[] =
    reportData?.linkClicks && reportData?.linkClicks.linkClicks && reportData?.linkClicks.linkClicks.length > 0
      ? reportData?.linkClicks?.linkClicks?.map((linkClick) => {
          const dataItem: TableSectionDataItem = {
            label: linkClick.link && linkClick.link.displayName ? linkClick.link.displayName : '',
            value: linkClick.count || 0,
          };
          return dataItem;
        })
      : [];

  const totalErrors =
    reportData?.errors && reportData?.errors.items && reportData?.errors.items.length > 0
      ? reportData?.errors.items.reduce((total, error) => (error.count ?? 0) + total, 0)
      : 0;
  const totalErrorsArr: TableSectionDataItem[] =
    reportData?.errors && reportData?.errors.items && reportData?.errors.items.length > 0
      ? reportData?.errors.items.map((error) => {
          const dataItem: TableSectionDataItem = {
            label: error.error && error.error.userMessage ? error.error.userMessage : '',
            value: error.count || 0,
          };
          return dataItem;
        })
      : [];

  const tableSections: TableSectionProps[] = [
    {
      title: t('buttonClicks'),
      totalCount: totalButtonClicks,
      hint: t('buttonClicksHint'),
      dataItems: buttonClicksArr || [],
      ListItemIcon: <ReplyIcon sx={{ width: '16px', height: '16px', marginRight: '4px', color: '#989898' }} />,
    },
    {
      title: t('linkClicks'),
      totalCount: totalLinkClicks,
      hint: t('linkClicksHint'),
      dataItems: linkClicksArr || [],
      ListItemIcon: <OpenInNewIcon sx={{ width: '16px', height: '16px', marginRight: '4px', color: '#989898' }} />,
    },
    {
      title: t('optOutCount'),
      totalCount: reportData?.optOutCount || 0,
      hint: t('optOutCountHint'),
    },
    {
      title: t('errors'),
      totalCount: totalErrors,
      hint: t('errorsHint'),
      dataItems: totalErrorsArr || [],
    },
  ];

  let flowObjects;
  if (previewDocumentRes && previewDocumentRes.entity) {
    flowObjects = {
      nodes:
        previewDocumentRes.entity.nodes?.map((node) =>
          node.options ? removeTemplateIds(JSON.parse(node.options)) : {},
        ) || [],

      edges: previewDocumentRes.entity.edges?.map((edge) => (edge.options ? JSON.parse(edge.options) : {})) || [],
    };
  }

  return {
    sendoutName,
    progressBarSections,
    tableSections,
    flowObjects,
    isReportDataLoading,
    isReportDataFetching,
    isLoadingPreviewDocument,
    isFetchingPreviewDocument,
  };
};

export const SendoutReport = () => {
  const updatePageEnhancements = useSetAtom(analyticsPageEnhancementsAtom);
  const theme = useTheme();
  const { sendoutId } = useParams<{ sendoutId: string }>();
  const {
    progressBarSections,
    tableSections,
    flowObjects,
    isReportDataLoading,
    isReportDataFetching,
    isLoadingPreviewDocument,
    isFetchingPreviewDocument,
    sendoutName,
  } = useSendoutReport(sendoutId);
  const { routes } = useNavigator();

  window.scrollTo(0, 0);

  // This code block is to update behavior/layout of the parent PageLayout component
  useEffect(() => {
    updatePageEnhancements({
      navigateBack: routes.campaigns.navigate,
      fullScreen: true,
      title: sendoutName,
    });
  }, [routes.campaigns.navigate, sendoutName, updatePageEnhancements]);

  useEffect(() => {
    return () => {
      updatePageEnhancements({});
    };
  }, [updatePageEnhancements]);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <Stack
        sx={{
          width: '320px',
          padding: '24px',
          background: 'white',
          borderRight: '1px solid #E2E8F0',
          height: '100%',
          overflow: 'hidden',
          scrollbarGutter: 'stable',
          '&:hover': {
            overflow: 'auto',
          },
        }}
      >
        <Label
          sx={{
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Results
        </Label>
        {(isReportDataLoading || isReportDataFetching) && (
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: theme.spacing(SIZE_CONTENT_L),
            }}
          >
            <Label>Loading...</Label>
          </Stack>
        )}
        {!isReportDataLoading && !isReportDataFetching && (
          <>
            <Stack direction="column" gap="24px" marginTop="16px">
              {progressBarSections.map((section) => (
                <DeliveryStatsSection {...section} key={section.hint} />
              ))}
            </Stack>
            {tableSections.map((tableSectionItem) => (
              <Stack
                key={`${tableSectionItem.title}`}
                sx={{
                  marginTop: theme.spacing(4),
                  // adding bottom margin to last child so that data/last-child isn't cut off on smaller screen sizes
                  '&:last-child': {
                    marginBottom: theme.spacing(SIZE_CONTENT_XXS),
                  },
                }}
              >
                <TableSection {...tableSectionItem} />
              </Stack>
            ))}
          </>
        )}
      </Stack>

      <Stack
        sx={{
          width: 'calc(100% - 320px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {(!flowObjects || isLoadingPreviewDocument || isFetchingPreviewDocument) && <Label>Loading...</Label>}
        {flowObjects && !isLoadingPreviewDocument && !isFetchingPreviewDocument && <ReadOnlyFlow {...flowObjects} />}
      </Stack>
    </Stack>
  );
};
