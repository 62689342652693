import { Logger } from '@connectlyai-tenets/logging';
import { Navigator } from '@connectlyai-tenets/navigation';
import { CampaignsProps } from '../api';

export interface CampaignsDIContainer {
  logProvider: () => Logger;
  navigatorProvider: () => Navigator;
}

export const buildDIContainer = (deps: CampaignsProps): CampaignsDIContainer => ({
  logProvider: deps.logProvider,
  navigatorProvider: deps.navigatorProvider,
});
