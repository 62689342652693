import React, { PropsWithChildren } from 'react';
import { Card, Label, useTheme, APINodeCanvasIcon } from '@connectlyai-tenets/ui-styled-web';
import { TargetHandle } from '@components/TargetHandle';
import { SourceHandle } from '@components/SourceHandle';
import { APICallNodeType } from './constants';
import { useIsHoveringNode } from '../../hooks/useIsHoveringNode';

export const APICallNode = ({ id }: PropsWithChildren<{ id: string }>) => {
  const theme = useTheme();
  const { isHoveringNode, handleMouseEnter, handleMouseLeave } = useIsHoveringNode(id);

  return (
    <Card
      sx={{
        alignItems: 'center',
        background: theme.palette.common.white,
        borderRadius: '10px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.18)',
        display: 'flex',
        gap: 1,
        ':hover': {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
        },
        justifyContent: 'center',
        padding: 2,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TargetHandle nodeId={id} nodeType={APICallNodeType} actionType="on-execute" />
      <SourceHandle nodeId={id} nodeType={APICallNodeType} actionType="after-execute" isHidden={!isHoveringNode} />
      <APINodeCanvasIcon />
      <Label variant="h7">API action</Label>
    </Card>
  );
};
