import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Button,
  CloseIcon,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@connectlyai-tenets/ui-styled-web';
import { FlowContext } from '../../../../contexts';
import { Method, Parameter, ParameterField, APICallNodeUIState } from './types';
import { labels as labelsFn } from './labels';
import { APICallParametersByMethod, Methods } from './constants';
import { ParametersDialog } from './ParametersDialog';
import { useNodeUIState } from '../../hooks/useNodeUIState';
import { ParametersQuick } from './ParametersQuick';

const useAPICallNodeEditor = () => {
  const { resetSelection } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);
  const { nodeUIState, setNodeUIState, setNodeUIStateKey } = useNodeUIState<APICallNodeUIState>({
    nodeType: 'FLOW_OBJECT_TYPE_CALL_API',
  });

  const handleSave = useCallback(() => {
    // TODO save settings
    resetSelection();
  }, [resetSelection]);

  const { t } = useTranslation('translation', { keyPrefix: '' });
  const labels = useMemo(() => labelsFn(t), [t]);

  const handleURLChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newState = {
        ...nodeUIState,
        url: {
          ...nodeUIState.url,
          value: e.target.value,
        },
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const handleChangeMethod = useCallback(
    (e: SelectChangeEvent<Method>) => {
      const value = e.target.value as Method;
      const newState = {
        ...nodeUIState,
        method: {
          ...nodeUIState.method,
          value,
        },
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const handleEnabledChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean, parameter: Parameter) => {
      const newState = {
        ...nodeUIState,
        [parameter]: {
          ...nodeUIState[parameter],
          active: _checked,
        },
      };
      setNodeUIState(newState);
    },
    [setNodeUIState, nodeUIState],
  );

  const queryOptional = useMemo(() => {
    return {
      checked: nodeUIState.query.active,
      onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleEnabledChange(e, checked, 'query'),
    };
  }, [handleEnabledChange, nodeUIState.query.active]);

  const headersOptional = useMemo(() => {
    return {
      checked: nodeUIState.headers.active,
      onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        handleEnabledChange(e, checked, 'headers'),
    };
  }, [handleEnabledChange, nodeUIState.headers.active]);

  const bodyOptional = useMemo(() => {
    return {
      checked: nodeUIState.body.active,
      onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleEnabledChange(e, checked, 'body'),
    };
  }, [handleEnabledChange, nodeUIState.body.active]);

  const handleAddParameter = useCallback(
    (parameter: Parameter) => {
      const newState = {
        ...nodeUIState,
        [parameter]: {
          active: true,
          parameters: [
            ...nodeUIState[parameter].parameters,
            {
              key: { value: '', error: '' },
              value: { value: '', error: '' },
              error: '',
            },
          ],
        },
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const handleDeleteParameter = useCallback(
    (parameter: Parameter, index: number) => {
      const newState = {
        ...nodeUIState,
        [parameter]: {
          active: true,
          parameters: nodeUIState[parameter].parameters.filter((_, i) => i !== index),
        },
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const handleParameterChangeItem = useCallback(
    (value: string, parameter: Parameter, index: number, field: ParameterField) => {
      const newState = {
        ...nodeUIState,
        [parameter]: {
          active: true,
          parameters: nodeUIState[parameter].parameters.map((param, i) => {
            if (i === index) {
              return {
                ...param,
                [field]: {
                  ...param[field],
                  value,
                },
              };
            }
            return param;
          }),
        },
      };
      setNodeUIState(newState);
    },
    [nodeUIState, setNodeUIState],
  );

  const showHeaders = useMemo(
    () => APICallParametersByMethod[nodeUIState.method.value].includes('headers'),
    [nodeUIState.method.value],
  );
  const showBody = useMemo(
    () => APICallParametersByMethod[nodeUIState.method.value].includes('body'),
    [nodeUIState.method.value],
  );
  const showQuery = useMemo(
    () => APICallParametersByMethod[nodeUIState.method.value].includes('query'),
    [nodeUIState.method.value],
  );

  const dialogParam = useMemo(() => nodeUIState.dialogParam, [nodeUIState.dialogParam]);

  const setDialogParam = useCallback(
    (param: Parameter | null) => {
      setNodeUIStateKey('dialogParam', param);
    },
    [setNodeUIStateKey],
  );

  return {
    bodyOptional,
    dialogParam,
    handleAddParameter,
    handleChangeMethod,
    handleDeleteParameter,
    handleParameterChangeItem,
    handleURLChange,
    headersOptional,
    labels,
    nodeUIState,
    onSave: handleSave,
    queryOptional,
    setDialogParam,
    showBody,
    showHeaders,
    showQuery,
  };
};

export const APICallNodeEditor = () => {
  const {
    bodyOptional,
    dialogParam,
    handleAddParameter,
    handleChangeMethod,
    handleDeleteParameter,
    handleParameterChangeItem,
    handleURLChange,
    headersOptional,
    labels,
    nodeUIState,
    onSave,
    queryOptional,
    setDialogParam,
    showBody,
    showHeaders,
    showQuery,
  } = useAPICallNodeEditor();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          flex: '0 0 auto',
          px: isLargeScreen ? 3 : 2,
          mt: isLargeScreen ? 3.5 : 2,
          mb: isLargeScreen ? 2 : 1,
        }}
      >
        <Label variant="h6">{labels.title}</Label>
        <IconButton aria-label="close" onClick={onSave}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 3,
        }}
      >
        <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: 1, mb: 0 }}>
          <TextField
            id="api-node-url"
            autoComplete="off"
            label={labels.url.label}
            placeholder={labels.url.label}
            error={Boolean(nodeUIState.url.error)}
            helperText={nodeUIState.url.error}
            fullWidth
            value={nodeUIState.url.value}
            onChange={handleURLChange}
            InputLabelProps={{ shrink: true }}
            sx={{
              '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
              '& .MuiInputBase-input': { p: '11px 12px' },
            }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
          <InputLabel id="api-node-method-label">{labels.method.label}</InputLabel>
          <Select
            labelId="api-node-method-label"
            id="api-node-method"
            label={labels.method.label}
            fullWidth
            value={nodeUIState.method.value}
            variant="outlined"
            onChange={handleChangeMethod}
            sx={{
              borderRadius: '10px',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              '& .MuiSelect-select': { py: '11px', pl: '12px' },
            }}
          >
            {Methods.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showQuery && (
          <ParametersQuick
            title={labels.query.title}
            active={nodeUIState.query.active}
            parameters={nodeUIState.query.parameters}
            optional={queryOptional}
            handleEdit={() => setDialogParam('query')}
          />
        )}
        {showHeaders && (
          <ParametersQuick
            title={labels.headers.title}
            active={nodeUIState.headers.active}
            parameters={nodeUIState.headers.parameters}
            optional={headersOptional}
            handleEdit={() => setDialogParam('headers')}
          />
        )}
        {showBody && (
          <ParametersQuick
            title={labels.body.title}
            active={nodeUIState.body.active}
            parameters={nodeUIState.body.parameters}
            optional={bodyOptional}
            handleEdit={() => setDialogParam('body')}
          />
        )}
      </Box>

      {dialogParam && (
        <ParametersDialog
          parameter={dialogParam}
          title={labels[dialogParam].title}
          open={!!dialogParam}
          parameters={nodeUIState[dialogParam].parameters}
          handleAdd={() => handleAddParameter(dialogParam)}
          handleClose={() => setDialogParam(null)}
          handleDelete={(index) => handleDeleteParameter(dialogParam, index)}
          handleChangeKey={(e, index) => handleParameterChangeItem(e.target.value, dialogParam, index, 'key')}
          handleChangeValue={(value, index) => handleParameterChangeItem(value, dialogParam, index, 'value')}
          addLabel={labels[dialogParam].add}
          keyLabel={labels.parameters.key.label}
          valueLabel={labels.parameters.value.label}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          p: 3,
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="contained" onClick={onSave}>
          {labels.save}
        </Button>
      </Box>
    </Box>
  );
};
