/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Box,
  Card,
  CheckIcon,
  InfoIcon,
  ListIcon,
  Label,
  MessageCornerIcon,
  MessageIcon,
  ReportIcon,
  Tooltip,
  useTheme,
  CircularProgress,
  Link,
  AddIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { getFlagByName } from '@connectlyai-tenets/feature-flag';
import Typist from 'react-typist';
import { useStore } from 'react-flow-renderer';
import { useContextSelector } from 'use-context-selector';
import { useNodeUIState } from '@components/FlowChartCampaignV3/hooks/useNodeUIState';
import { MessageNodeUIState } from '@components/FlowChartCampaignV3/components/MessageNodeEditor/types';
import { CarouselItem } from '@components/FlowChartCampaignV3/components/CarouselItem/CarouselItem';
import { cloneDeep } from 'lodash';
import { CAROUSEL_CARDS_LIMIT } from '@components/TemplateBuilderCarousel';
import { getNewButtons } from '@components/TemplateBuilderCarousel/getNewButtons';
import { useSofiaEnabled } from '../../scenes/Sofia/hooks';
import { TargetHandle } from '../TargetHandle';
import { SourceHandle } from '../SourceHandle';
import { FlowContext } from '../../contexts';
import { FlowChartMessageNodeButton } from '../FlowChartMessageNodeButton';
import { FlowChartMessageNodeListRow } from '../FlowChartMessageNodeListRow';
import { MessageNodeProps } from './types';
import { selectHeaderMediaMessageNodeData, selectHeaderMediaIcon } from './utils';
import {
  ListTemplateComponent,
  MessageTemplateRejectionReason,
  MessageTemplateStatus,
  selectBusinessId,
  selectMessageTemplateById,
  TemplateComponent,
  useMeData,
  useMessageTemplateGroupsData,
  useFeatureFlag,
} from '../../hooks';
import { NodeType } from '../../sdks/flow/createNode';
import {
  selectUnsentMessageTemplateByNodeId,
  selectHeaderText,
  selectHeaderMedia,
  selectHeaderMediaMappingByNodeId,
  selectHeaderMediaTypeByNodeId,
  selectBody,
  selectEditedNodeId,
  selectFooter,
  selectButtons,
  selectPreviewTemplateId,
  CREATE_NEW_TEMPLATE_ID,
  selectTemplateId,
  selectIsHeaderEnabled,
  selectIsFooterEnabled,
  selectIsButtonsCollectionEnabled,
  selectList,
} from '../../state/messageTemplates';
import { selectSelectedNode } from '../../state/flow';
import { formattingPreviewOf, normalizeBody, sanitize } from '../../presentation/preview/utils';
import { getMessageNodeLabel, getRejectedReasonText, getTemplateStatusColor } from '../../utils';
import { typingObservable, useAICampaign } from '../../hooks/useAICampaign/index';
import { CodeMirrorTextField } from '../CodeMirrorTextField';
import { populateVariablesV2, useFlowVariables } from '../../hooks/useFlowVariables';
import { AppState } from '../../state/store';
import { hoveringMessageNodeIdAtom } from './atoms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectConnectionNodeId = (state: any) => state.connectionNodeId;

const isAutoReplyBodyVariablesEnabled = getFlagByName('ffEnableAutoReplyBodyVariables');
const ffEnableETAProductReviews = getFlagByName('ffEnableETAProductReviews');
const ffEnableNonButtonSourceHandle = getFlagByName('ffEnableNonButtonSourceHandle');

const useMessageNode = ({ id = '', data, nodeType: nodeTypePartial }: Partial<MessageNodeProps>) => {
  const { ffEnableETA } = useFeatureFlag(['ffEnableETA']);
  const shouldUseCodeMirror = isAutoReplyBodyVariablesEnabled || ffEnableETA;
  const enableAINode = useSofiaEnabled();

  const nodeType = useMemo(
    () => (nodeTypePartial || 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE') as NodeType,
    [nodeTypePartial],
  );

  const { v1 } = data || {};
  const templateId = v1?.waMessageTemplateId || v1?.templateId || CREATE_NEW_TEMPLATE_ID;
  const { messageTemplateInput: connectlyTemplate, parameterMapping } = v1 || {};

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: savedTemplate, isFetching: isTemplatesFetching } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(templateId),
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    filterByTemplateIdOrExternalTemplateId: {
      templateId,
    },
  });

  const selectedTemplateId = useSelector(selectTemplateId);
  const { data: selectedTemplate } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(selectedTemplateId || ''),
    keepPreviousData: true,
    filterByTemplateIdOrExternalTemplateId: {
      templateId: selectedTemplateId || '',
    },
  });

  const previewTemplateId = useSelector(selectPreviewTemplateId);
  const { data: previewTemplate } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(previewTemplateId || ''),
    keepPreviousData: true,
    filterByTemplateIdOrExternalTemplateId: {
      templateId: previewTemplateId || '',
    },
  });

  const unsentTemplate = useSelector(selectUnsentMessageTemplateByNodeId(id));
  const isRejectedTemplate = unsentTemplate?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED';
  const editedNodeId = useSelector(selectEditedNodeId);
  const editedHeaderText = useSelector(selectHeaderText);
  const editedHeaderMedia = useSelector(selectHeaderMedia);
  const editedBody = useSelector(selectBody);
  const editedFooter = useSelector(selectFooter);
  const editedButtons = useSelector(selectButtons, shallowEqual);
  const editedList = useSelector(selectList);

  const isEditedHeaderEnabled = useSelector(selectIsHeaderEnabled);
  const isEditedFooterEnabled = useSelector(selectIsFooterEnabled);
  const isEditedButtonsCollectionEnabled = useSelector(selectIsButtonsCollectionEnabled);

  const reduxHeaderMediaMapping = useSelector((state: AppState) => selectHeaderMediaMappingByNodeId(state, id));
  const reduxHeaderMediaType = useSelector((state: AppState) => selectHeaderMediaTypeByNodeId(state, id));
  const { headerMediaType, headerMediaMapping } = useMemo(() => {
    return selectHeaderMediaMessageNodeData(v1, reduxHeaderMediaType, reduxHeaderMediaMapping, editedNodeId === id);
  }, [v1, reduxHeaderMediaType, reduxHeaderMediaMapping, editedNodeId, id]);
  const headerMediaIcon = useMemo(() => {
    return selectHeaderMediaIcon(headerMediaType);
  }, [headerMediaType]);

  let status: MessageTemplateStatus = 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
  if (editedNodeId === id) {
    if (previewTemplate) {
      status = previewTemplate.template?.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
    } else if (isRejectedTemplate) {
      status = 'MESSAGE_TEMPLATE_STATUS_REJECTED';
    } else if (selectedTemplate) {
      status = selectedTemplate.template?.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
    } else {
      status = 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
    }
  } else if (unsentTemplate) {
    status = unsentTemplate.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
  } else if (savedTemplate) {
    status = savedTemplate.template?.status ?? 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
  } else if (connectlyTemplate) {
    status = 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED';
  }

  let textHeader = '';
  if (editedNodeId === id) {
    if (previewTemplate) {
      textHeader = previewTemplate.template?.templateComponents?.find((x) => x.header)?.header?.text?.text || '';
    } else if (isRejectedTemplate) {
      textHeader = isEditedHeaderEnabled ? editedHeaderText : '';
    } else if (selectedTemplate) {
      textHeader = selectedTemplate.template?.templateComponents?.find((x) => x.header)?.header?.text?.text || '';
    } else {
      textHeader = isEditedHeaderEnabled ? editedHeaderText : '';
    }
  } else if (unsentTemplate) {
    textHeader = unsentTemplate.templateComponents?.find((x) => x.header)?.header?.text?.text || '';
  } else if (savedTemplate) {
    textHeader = savedTemplate.template?.templateComponents?.find((x) => x.header)?.header?.text?.text || '';
  } else if (connectlyTemplate) {
    textHeader = connectlyTemplate.templateComponents?.find((x) => x.header)?.header?.text?.text || '';
  }

  let mediaHeader: string[] | string | undefined = headerMediaMapping;
  if (!headerMediaMapping) {
    if (editedNodeId === id) {
      if (previewTemplate) {
        mediaHeader = previewTemplate.template?.templateComponents?.find((x) => x.header)?.header?.media?.example || [];
      } else if (isRejectedTemplate) {
        mediaHeader = isEditedHeaderEnabled ? editedHeaderMedia : [];
      } else if (selectedTemplate) {
        mediaHeader =
          selectedTemplate.template?.templateComponents?.find((x) => x.header)?.header?.media?.example || [];
      } else {
        mediaHeader = isEditedHeaderEnabled ? editedHeaderMedia : [];
      }
    } else if (unsentTemplate) {
      mediaHeader = unsentTemplate.templateComponents?.find((x) => x.header)?.header?.media?.example || [];
    } else if (savedTemplate) {
      mediaHeader = savedTemplate.template?.templateComponents?.find((x) => x.header)?.header?.media?.example || [];
    } else if (connectlyTemplate) {
      mediaHeader = connectlyTemplate.templateComponents?.find((x) => x.header)?.header?.media?.example || [];
    }
  }

  let body = '';
  if (editedNodeId === id) {
    if (previewTemplate) {
      body = previewTemplate.template?.templateComponents?.find((x) => x.body)?.body?.text?.text || '';
    } else if (isRejectedTemplate) {
      body = editedBody;
    } else if (selectedTemplate) {
      body = selectedTemplate.template?.templateComponents?.find((x) => x.body)?.body?.text?.text || '';
    } else {
      body = editedBody;
    }
  } else if (unsentTemplate) {
    body = unsentTemplate.templateComponents?.find((x) => x.body)?.body?.text?.text || '';
  } else if (savedTemplate) {
    body = savedTemplate.template?.templateComponents?.find((x) => x.body)?.body?.text?.text || '';
  } else if (connectlyTemplate) {
    body = connectlyTemplate?.templateComponents?.find((x) => x.body)?.body?.text?.text || '';
  }

  let footer = '';
  if (editedNodeId === id) {
    if (previewTemplate) {
      footer = previewTemplate.template?.templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
    } else if (isRejectedTemplate) {
      footer = isEditedFooterEnabled ? editedFooter : '';
    } else if (selectedTemplate) {
      footer = selectedTemplate.template?.templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
    } else {
      footer = isEditedFooterEnabled ? editedFooter : '';
    }
  } else if (unsentTemplate) {
    footer = unsentTemplate.templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
  } else if (savedTemplate) {
    footer = savedTemplate.template?.templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
  } else if (connectlyTemplate) {
    footer = connectlyTemplate.templateComponents?.find((x) => x.footer)?.footer?.text?.text || '';
  }

  let buttons: TemplateComponent[] = [];
  if (editedNodeId === id) {
    if (previewTemplate) {
      buttons = previewTemplate.template?.templateComponents?.filter((x): x is TemplateComponent => !!x.button) || [];
    } else if (isRejectedTemplate) {
      buttons = isEditedButtonsCollectionEnabled ? editedButtons : [];
    } else if (selectedTemplate) {
      buttons = selectedTemplate.template?.templateComponents?.filter((x): x is TemplateComponent => !!x.button) || [];
    } else {
      buttons = isEditedButtonsCollectionEnabled ? editedButtons : [];
    }
  } else if (unsentTemplate) {
    buttons = unsentTemplate.templateComponents?.filter((x): x is TemplateComponent => !!x.button) || [];
  } else if (savedTemplate) {
    buttons = savedTemplate.template?.templateComponents?.filter((x): x is TemplateComponent => !!x.button) || [];
  } else if (connectlyTemplate) {
    buttons = connectlyTemplate.templateComponents?.filter((x): x is TemplateComponent => !!x.button) || [];
  }

  let list: ListTemplateComponent = useMemo(() => ({ button: '', sections: [] }), []);
  if (editedNodeId === id) {
    if (previewTemplate) {
      list = previewTemplate.template?.templateComponents?.find((x): x is TemplateComponent => !!x.list)?.list || list;
    } else if (isRejectedTemplate) {
      list = editedList;
    } else if (selectedTemplate) {
      list = selectedTemplate.template?.templateComponents?.find((x): x is TemplateComponent => !!x.list)?.list || list;
    } else {
      list = editedList;
    }
  } else if (unsentTemplate) {
    list = unsentTemplate.templateComponents?.find((x): x is TemplateComponent => !!x.list)?.list || list;
  } else if (savedTemplate) {
    list = savedTemplate.template?.templateComponents?.find((x): x is TemplateComponent => !!x.list)?.list || list;
  } else if (connectlyTemplate) {
    list = connectlyTemplate.templateComponents?.find((x): x is TemplateComponent => !!x.list)?.list || list;
  }
  const simpleListRows = useMemo(() => list?.sections?.[0]?.rows, [list]);

  let rejectionReason: MessageTemplateRejectionReason | undefined;
  if (editedNodeId === id) {
    if (previewTemplate) {
      rejectionReason = previewTemplate.template?.rejectionReason;
    } else if (unsentTemplate) {
      rejectionReason = unsentTemplate?.rejectionReason;
    } else if (selectedTemplate) {
      rejectionReason = selectedTemplate.template?.rejectionReason;
    } else {
      rejectionReason = undefined;
    }
  } else if (unsentTemplate) {
    rejectionReason = unsentTemplate.rejectionReason;
  } else if (savedTemplate) {
    rejectionReason = savedTemplate.template?.rejectionReason;
  } else if (connectlyTemplate) {
    rejectionReason = undefined;
  }

  let hasErrors = false;
  if (editedNodeId === id) {
    hasErrors = !!unsentTemplate?.errors;
  } else if (unsentTemplate) {
    hasErrors = !!unsentTemplate.errors;
  } else if (savedTemplate) {
    hasErrors = false;
  } else if (connectlyTemplate) {
    hasErrors = false;
  }

  const { populateVariables } = useFlowVariables();
  const populatedHeaderText = useMemo(
    () =>
      parameterMapping?.mappings
        ? populateVariables(textHeader, 'header_text.', parameterMapping?.mappings)
        : textHeader,
    [textHeader, populateVariables, parameterMapping?.mappings],
  );
  const populatedBody = useMemo(
    () => (parameterMapping?.mappings ? populateVariables(body, 'body_', parameterMapping?.mappings) : body),
    [body, populateVariables, parameterMapping?.mappings],
  );

  const templateType = v1?.waMessageTemplateType;

  const connectionNodeId = useStore(selectConnectionNodeId);
  const isNodePotentialTarget = useContextSelector(FlowContext, (context) => context.isNodePotentialTarget);
  const isPotentialTarget = useMemo(
    () => isNodePotentialTarget(connectionNodeId, id),
    [isNodePotentialTarget, connectionNodeId, id],
  );

  const { isAIGenerating } = useAICampaign();

  const [isTyping, setIsTyping] = React.useState(false);

  const handleTypingDone = useCallback(() => {
    setIsTyping(false);
    typingObservable.next(id);
  }, [id, setIsTyping]);

  useEffect(() => {
    if (isAIGenerating && !data?.v1?.isGenerating) {
      setIsTyping(true);
    }
  }, [isAIGenerating, data?.v1?.isGenerating]);

  const selectedNode = useSelector(selectSelectedNode);
  const isSelected = useMemo(() => selectedNode?.id === id, [selectedNode?.id, id]);
  const isBodyLoading = useMemo(() => isTemplatesFetching && !body, [isTemplatesFetching, body]);
  const isTemplateDeleted = useMemo(
    () =>
      !isTemplatesFetching && Boolean(templateId) && !savedTemplate && status === 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED',
    [isTemplatesFetching, savedTemplate, status, templateId],
  );
  const showNonButtonHandle = useMemo(
    () => ffEnableNonButtonSourceHandle || ffEnableETAProductReviews || enableAINode,
    [enableAINode],
  );

  const [hoveringMessageNodeId, setHoveringMessageNodeId] = useAtom(hoveringMessageNodeIdAtom);

  const setIsHoveringMessageNode = useCallback(
    (isHovering: boolean) => {
      if (isHovering) {
        setHoveringMessageNodeId(id);
        return;
      }

      if (hoveringMessageNodeId === id) setHoveringMessageNodeId('');
    },
    [id, hoveringMessageNodeId, setHoveringMessageNodeId],
  );

  const isHoveringMessageNode = useMemo(() => hoveringMessageNodeId === id, [hoveringMessageNodeId, id]);

  const { nodeUIState, setNodeUIStateKey } = useNodeUIState<MessageNodeUIState>({
    nodeType,
    nodeId: id,
  });

  const { carousel = [] } = nodeUIState;

  const handleCarouselItemClick = (index: number) => {
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: index,
      cards: nodeUIState.carousel!.cards,
    });
  };

  const handleAddCarouselItem = () => {
    const clonedCards = cloneDeep(nodeUIState.carousel!.cards);
    const newCards = [...clonedCards, { body: '', buttons: getNewButtons(clonedCards) }];
    setNodeUIStateKey('carousel', {
      ...nodeUIState.carousel,
      active: newCards.length - 1,
      cards: newCards,
    });
  };
  const hasButtons = useMemo(
    () => buttons.length > 0 || (simpleListRows && simpleListRows.length > 0),
    [buttons.length, simpleListRows],
  );

  return {
    shouldUseCodeMirror,
    simpleListRows,
    body,
    populatedBody,
    buttons,
    footer,
    handleTypingDone,
    hasErrors,
    headerMediaIcon,
    headerMediaType,
    isAIGenerating,
    isBodyLoading,
    isHoveringMessageNode,
    isPotentialTarget,
    isSelected,
    isTemplateDeleted,
    isTyping,
    list,
    mediaHeader,
    nodeType,
    setIsHoveringMessageNode,
    showNonButtonHandle,
    status,
    templateType,
    textHeader: populatedHeaderText,
    rejectionReason,
    isCarouselTemplateType: templateType === 'FLOW_OBJECT_TEMPLATE_TYPE_CAROUSEL_MESSAGE',
    carousel,
    handleCarouselItemClick,
    handleAddCarouselItem,
    parameterMapping,
    hasButtons,
  };
};

export const MessageNode = ({
  id = '',
  data,
  nodeType: nodeTypePartial,
}: Partial<PropsWithChildren<MessageNodeProps>>) => {
  const theme = useTheme();
  const {
    shouldUseCodeMirror,
    simpleListRows,
    body,
    populatedBody,
    buttons,
    footer,
    handleTypingDone,
    hasErrors,
    headerMediaIcon,
    headerMediaType,
    isAIGenerating,
    isBodyLoading,
    isHoveringMessageNode,
    isSelected,
    isTemplateDeleted,
    isTyping,
    list,
    mediaHeader,
    nodeType,
    setIsHoveringMessageNode,
    showNonButtonHandle,
    status = 'MESSAGE_TEMPLATE_STATUS_UNSPECIFIED',
    templateType,
    textHeader,
    rejectionReason,
    isCarouselTemplateType,
    handleCarouselItemClick,
    carousel,
    handleAddCarouselItem,
    parameterMapping,
    hasButtons,
  } = useMessageNode({ id, data, nodeType: nodeTypePartial });

  const headerSx = {
    width: '100%',
    height: '125px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <Card
      sx={{
        width: isCarouselTemplateType ? 'auto' : 300,
        boxShadow: theme.shadows[1],
        padding: 1.5,
        borderRadius: 2,
        border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.common.white}`,
        overflow: 'visible',
      }}
      onMouseEnter={() => setIsHoveringMessageNode(true)}
      onMouseLeave={() => setIsHoveringMessageNode(false)}
    >
      <TargetHandle nodeId={id} nodeType={nodeType} actionType="on-execute" />
      {showNonButtonHandle && (
        <SourceHandle nodeId={id} nodeType={nodeType} actionType="after-execute" isHidden={!isHoveringMessageNode} />
      )}
      <Box
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 1.5,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: 28,
              height: 28,
              backgroundColor:
                hasErrors || isTemplateDeleted
                  ? getTemplateStatusColor('MESSAGE_TEMPLATE_STATUS_REJECTED')
                  : getTemplateStatusColor(status),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              '& > svg': {
                width: 14,
                fill: (t) => t.palette.common.white,
              },
            }}
          >
            {hasErrors || isTemplateDeleted ? (
              <ReportIcon />
            ) : status === 'MESSAGE_TEMPLATE_STATUS_APPROVED' ? (
              <CheckIcon />
            ) : status === 'MESSAGE_TEMPLATE_STATUS_REJECTED' ? (
              <ReportIcon />
            ) : (
              <MessageIcon />
            )}
          </Box>
          <Box>
            <Label variant="h6">{getMessageNodeLabel(hasErrors || isTemplateDeleted, templateType, status)}</Label>
          </Box>
          {rejectionReason && getRejectedReasonText(rejectionReason?.reason) && (
            <Tooltip arrow placement="top" title={getRejectedReasonText(rejectionReason?.reason)}>
              <InfoIcon fill={theme.palette.secondary.dark} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          padding: 2.5,
          backgroundColor: theme.palette.grey[100],
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: isCarouselTemplateType ? 'flex-start' : 'unset',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            minHeight: 40,
            padding: 1,
            boxSizing: 'border-box',
            borderRadius: `0 ${theme.spacing(1)} ${hasButtons ? '0 0' : `${theme.spacing(1)} ${theme.spacing(1)}`}`,
            backgroundColor: theme.palette.background.paper,
            borderBottom: hasButtons ? `1px solid ${theme.palette.divider}` : 'none',
            minWidth: isCarouselTemplateType ? 200 : 'auto',
          }}
        >
          <Box
            sx={{
              color: 'white',
              position: 'absolute',
              top: -2.5,
              left: -8,
            }}
          >
            <MessageCornerIcon />
          </Box>
          {mediaHeader && typeof mediaHeader === 'string' && (
            <Box sx={headerSx}>
              {headerMediaIcon}
              <Label
                sx={{
                  background: '#EAF5EA',
                  px: 0.75,
                  borderRadius: '4px',
                  color: '#2F5D28',
                }}
              >
                {mediaHeader.slice(2, mediaHeader.length - 2)}
              </Label>
            </Box>
          )}
          {mediaHeader && Array.isArray(mediaHeader) && (mediaHeader || []).length > 0 && (
            <Box
              sx={{
                borderRadius: 1,
                width: '100%',
                maxHeight: '127px',
                marginBottom: 1,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {headerMediaType === 'TYPE_IMAGE' && (
                <Box component="img" src={mediaHeader?.[0]} alt="image" sx={{ width: '100%' }} />
              )}
              {headerMediaType === 'TYPE_VIDEO' && (
                <Box sx={headerSx}>
                  <Link href={mediaHeader?.[0]} target="_blank" rel="noopener noreferrer" sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        p: 3,
                        '&:hover svg': {
                          fill: theme.palette.primary.main,
                        },
                      }}
                    >
                      {headerMediaIcon}
                    </Box>
                  </Link>
                </Box>
              )}
              {headerMediaType === 'TYPE_DOCUMENT' && (
                <Box sx={headerSx}>
                  <Link href={mediaHeader?.[0]} target="_blank" rel="noopener noreferrer" sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        p: 3,
                        '&:hover svg': {
                          fill: theme.palette.primary.main,
                        },
                      }}
                    >
                      {headerMediaIcon}
                    </Box>
                  </Link>
                </Box>
              )}
            </Box>
          )}

          {textHeader && (
            <CodeMirrorTextField
              editable={false}
              value={textHeader}
              multiline
              containerStyle={{
                fontSize: 16,
                lineHeight: 24,
              }}
            />
          )}
          <Label variant="body1">
            {isTemplateDeleted && (
              <Box sx={{ color: theme.palette.grey[700] }}>This message template has been deleted.</Box>
            )}
            {!isTyping && !data?.v1?.isGenerating && !isBodyLoading && !isTemplateDeleted && (
              <>
                {shouldUseCodeMirror && (
                  <CodeMirrorTextField editable={false} value={populatedBody} multiline displayFormatting />
                )}
                {!shouldUseCodeMirror && (
                  <Box
                    sx={{
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(formattingPreviewOf(normalizeBody(body || 'Message body'))),
                    }}
                  />
                )}
              </>
            )}
            {!isTyping && (data?.v1?.isGenerating || isBodyLoading) && (
              <Box sx={{ height: '100%', minHeight: '200px', width: '100%', position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }}>
                  <CircularProgress sx={{ color: '#B6519D' }} />
                </Box>
              </Box>
            )}
            {isTyping && (
              <Box
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Typist onTypingDone={handleTypingDone} stdTypingDelay={20} startDelay={isAIGenerating ? 1000 : 0}>
                  {body}
                </Typist>
              </Box>
            )}
            {footer && (
              <Box
                sx={{
                  marginTop: 1,
                  color: theme.palette.text.disabled,
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {footer}
              </Box>
            )}
          </Label>
        </Box>
        {buttons?.map((button, i) => (
          <FlowChartMessageNodeButton
            button={button}
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-button-${i}`}
            isLast={i === buttons.length - 1}
            handleType={{
              nodeId: id,
              nodeType,
              actionType: 'button-click',
              buttonIndex: i,
            }}
          />
        ))}
        {simpleListRows && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.grey[200],
              borderRadius: '3.738px',
            }}
          >
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 1,
                gap: 1,
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <ListIcon color="action" />
              <Label variant="body1">{list.button || 'List Button'}</Label>
            </Box>
            {simpleListRows?.map((row, index) => (
              <FlowChartMessageNodeListRow
                index={index}
                key={row.id}
                row={row}
                isLast={index === simpleListRows.length - 1}
                handleType={{
                  nodeId: id,
                  nodeType: (nodeType || 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE') as NodeType,
                  actionType: 'button-click',
                  buttonIndex: index,
                }}
              />
            ))}
          </Box>
        )}
        {isCarouselTemplateType && 'cards' in carousel && carousel.cards.length > 0 && (
          <Box sx={{ display: 'flex', ml: 0, gap: 3, mt: 1 }}>
            {carousel.cards.map((card, index) => (
              <CarouselItem
                key={index}
                id={id}
                active={index === carousel.active}
                cardIndex={index}
                header={card.header}
                message={populateVariablesV2(card.body, `card.${index}.body.`, parameterMapping?.mappings)}
                buttons={card.buttons || []}
                onClick={handleCarouselItemClick}
              />
            ))}

            {carousel.cards.length < CAROUSEL_CARDS_LIMIT && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  onClick={handleAddCarouselItem}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '100%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: theme.palette.common.white,
                    position: 'relative',
                    zIndex: 2,
                  }}
                >
                  <AddIcon />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};
