import { AudienceSegment } from '../../hooks';

export enum AudienceListState {
  CREATE,
  UPLOAD,
  SAVE,
  COMPLETE,
}

export type AudienceListBuilderProps = {
  state: AudienceListState;
  name: string;
  nameError: string;
  onClose: () => void;
  onNameChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSelectFile: (file: File) => void;
  onSuccess: (audience: AudienceSegment) => void;
  onUrlChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  url: string;
};
