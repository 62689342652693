import React, { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { sofiaStoreDescriptorAtom } from '@atoms/sofia';
import { SofiaStoreDescriptionView } from '@scenes/Sofia/types';
import { useTranslation } from 'react-i18next';
import { StoreDescriptorTextInputProps } from './types';
import { TextInput } from '../TextInput';

export const StoreDescriptorTextInput = ({
  descriptorKey,
  info,
  autoFocus,
  multiline,
  rows,
  noLabel,
  title,
  placeholder,
  requireValue,
}: StoreDescriptorTextInputProps) => {
  const [descriptor, setDescriptor] = useAtom(sofiaStoreDescriptorAtom);
  const [error, setError] = useState('');
  const value = useMemo(() => {
    return descriptor[descriptorKey];
  }, [descriptorKey, descriptor]);
  const { t } = useTranslation('translation', { keyPrefix: 'sofia' });
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (requireValue && !newValue) {
        setError(t('inputRequired'));
      }
      if (error && newValue) {
        setError('');
      }
      setDescriptor((current: SofiaStoreDescriptionView) => ({
        ...current,
        [descriptorKey]: newValue,
      }));
    },
    [descriptorKey, error, requireValue, setDescriptor, t],
  );
  const label = useMemo(() => t(`${descriptorKey}InputLabel`), [descriptorKey, t]);
  const name = useMemo(() => label.toLowerCase().replace(' ', '-'), [label]);
  return (
    <TextInput
      label={noLabel ? undefined : label}
      name={name}
      value={value}
      info={info}
      error={error}
      autoFocus={autoFocus}
      multiline={multiline}
      rows={rows}
      noLabel={noLabel}
      title={title}
      placeholder={placeholder}
      handleChange={handleChange}
    />
    // <FormControl variant="outlined" fullWidth margin="normal" sx={{ my: 0 }}>
    //   {title && <Label variant="h7" sx={{ mb: -1 }}>{`${title}:`}</Label>}
    //   <TextField
    //     autoFocus={autoFocus}
    //     label={noLabel ? undefined : label}
    //     name={name}
    //     variant="outlined"
    //     margin="normal"
    //     value={value}
    //     fullWidth
    //     multiline={multiline}
    //     placeholder={placeholder}
    //     rows={rows}
    //     onChange={handleChange}
    //     sx={{ background: (theme) => theme.palette.common.white }}
    //   />
    //   {info && (
    //     <Label variant="body1" sx={{ color: 'text.secondary' }}>
    //       {info}
    //     </Label>
    //   )}
    // </FormControl>
  );
};
