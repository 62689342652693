import React, { FC, PropsWithChildren } from 'react';
import { Stack } from '@connectlyai-tenets/ui-styled-web';
import { Alerts, AppSidebar } from '@components';

type AppLayoutProps = {
  isAdminUser: boolean;
};
export const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = ({ isAdminUser, children }) => {
  return (
    <Stack direction="row" width="100%">
      <AppSidebar isAdminUser={isAdminUser} />
      <Stack
        sx={{
          flex: '1 1 auto',
          height: '100vh',
        }}
      >
        <Alerts />
        {children}
      </Stack>
    </Stack>
  );
};
