// todo remove with ffEnableNewHome
import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { overviewDatesAtom } from '@atoms/home';
import { dateRangeLast7DaysAtom } from '@atoms/analytics';
import { Box, Card, InfoIcon, Label, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { selectBusinessId, useConversationOverviewData, useDatePeriod, useMeData } from '@hooks';
import { DateRange, DateRangePicker } from '@components/DateRangePicker';
import { ConversationsModal } from '../ConversationsModal';
import { AccountOverviewProps } from './types';

const useAccountOverview = () => {
  const [startDate, endDate] = useAtomValue(overviewDatesAtom);
  const setDates = useSetAtom(overviewDatesAtom);
  const [dateRange, setDateRange] = useAtom(dateRangeLast7DaysAtom);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: conversationOverview, isLoading: isConversationOverviewLoading } = useConversationOverviewData({
    businessId: businessId as string,
    enabled: !!businessId,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    startTime: startDate.toISOString(),
    endTime: endDate.toISOString(),
  });

  const totalConversations = useMemo(
    () =>
      conversationOverview?.entity?.map((x) => (x.conversations ? x.conversations : 0)).reduce((acc, x) => acc + x, 0),
    [conversationOverview],
  );

  const getDateInUTC = (d: Date) => new Date(`${d.toISOString().slice(0, 10)}T00:00:00.000Z`);

  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDates([getDateInUTC(newDateRange.startDate), getDateInUTC(newDateRange.endDate)]);
      setDateRange(newDateRange);
    },
    [setDateRange, setDates],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = useCallback(() => setIsModalOpen(false), []);
  const { datePeriodToString } = useDatePeriod();

  return {
    datePeriodToString,
    endDate,
    dateRange,
    handleDateRangeChange,
    isConversationOverviewLoading,
    isModalOpen,
    onCloseModal,
    setIsModalOpen,
    startDate,
    totalConversations,
  };
};

export const AccountOverview = ({ isAgent }: AccountOverviewProps) => {
  const theme = useTheme();
  const {
    endDate,
    dateRange,
    handleDateRangeChange,
    isConversationOverviewLoading,
    isModalOpen,
    onCloseModal,
    setIsModalOpen,
    startDate,
    totalConversations,
  } = useAccountOverview();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.home.accountOverview' });

  return (
    <Card
      variant="main"
      sx={{
        justifyContent: 'space-between',
      }}
    >
      {!isAgent && (
        <ConversationsModal endDate={endDate} onClose={onCloseModal} open={isModalOpen} startDate={startDate} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Label variant="h6">{t('title')}</Label>
        <Label variant="body1" sx={{ color: theme.palette.text.secondary }}>
          {t('caption')}
        </Label>
        <DateRangePicker dateRange={dateRange} setDateRange={handleDateRangeChange} />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          background: theme.palette.grey.A100,
          borderRadius: '10px',
          display: 'flex',
          flexGrow: 1,
          gap: 1,
          justifyContent: 'space-between',
          maxWidth: theme.spacing(45),
          p: 1.5,
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            height: '100%',
            justifyContent: 'space-between',
            overflow: 'hidden',
          }}
        >
          <Label variant="body1" noWrap sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center' }}>
            {t('conversations')}
            <InfoIcon id="tooltip-analytics-conversations" width={12} height={12} fill={theme.palette.secondary.dark} />
          </Label>
          <Label variant="h5" noWrap>
            {!isConversationOverviewLoading && totalConversations?.toLocaleString()}
          </Label>
          <Label
            variant="body1"
            onClick={() => setIsModalOpen(true)}
            sx={{
              cursor: 'pointer',
              color: theme.palette.primary.main,
            }}
          >
            {t('viewDetails')}
          </Label>
        </Box>
      </Box>
    </Card>
  );
};
