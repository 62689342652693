import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Button,
  CloseIcon,
  Collapse,
  IconButton,
  Label,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { TemplateBuilderHeader } from '@components/TemplateBuilderHeader';
import { TemplateBuilderBody } from '@components/TemplateBuilderBody';
import { TemplateBuilderFooter } from '@components/TemplateBuilderFooter';
import { TemplateBuilderButtons } from '@components/TemplateBuilderButtons';
import { TemplateBuilderList } from '@components/TemplateBuilderList';
import { TemplateBuilderTemplateSelector } from '@components/TemplateBuilderTemplateSelector';
import { useAtomValue } from 'jotai';
import { selectedNodeAtom } from '@atoms/flow';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { TemplateBuilderCarousel } from '@components/TemplateBuilderCarousel';
import { FlowContext } from '../../../../contexts';
import {
  CREATE_NEW_TEMPLATE_ID,
  replaceUnsentMessageTemplate,
  selectErrors,
  selectIsEditable,
  selectTemplateId,
  setTemplateId,
} from '../../../../state/messageTemplates';
import { scrollToHTMLInputElement } from '../../../../utils';
import { TemplateType } from '../../../../sdks/flow/createNode';
import {
  selectBusinessId,
  selectMessageTemplateById,
  useMeData,
  useMessageTemplateGroupsData,
} from '../../../../hooks';
import { useCampaignFlowChangeAppliers } from '../../hooks/useCampaignFlowChangeAppliers';
import { useCreateNode } from '../../hooks/useCreateNode';
import { editNode } from '../../../../state/actions';
import { hasBody, hasButtons, hasFooter, hasHeader, hasList, hasTemplateSelector, hasCarousel } from './mappers';

const useTemplateBuilder = () => {
  const { resetSelection } = useContextSelector(FlowContext, (context) => context.flowChangeAppliers);

  const selectedTemplateId = useSelector(selectTemplateId);
  const isEditable = useSelector(selectIsEditable);

  const node = useAtomValue(selectedNodeAtom);
  const nodeId = useMemo(() => node?.id, [node]);
  const templateType: TemplateType | undefined = node?.data.v1.waMessageTemplateType;

  const errors = useSelector(selectErrors, shallowEqual);
  useEffect(() => {
    if (errors) {
      const { templateId, header, body, footer, buttons } = errors;
      if (templateId) {
        scrollToHTMLInputElement('message-template-selector', true, 0);
        return;
      }

      if (header) {
        scrollToHTMLInputElement('message-template-header', true);
        return;
      }

      if (body) {
        scrollToHTMLInputElement('message-template-body', true);
        return;
      }

      if (footer) {
        scrollToHTMLInputElement('message-template-footer', true);
        return;
      }

      if (buttons) {
        const errorButtonIndex = buttons.findIndex((x) => x.text || x.url || x.phoneNumber);
        if (errorButtonIndex !== -1) {
          let errorButtonElement = 'text';
          if (buttons[errorButtonIndex].url) {
            errorButtonElement = 'url';
          } else if (buttons[errorButtonIndex].phoneNumber) {
            errorButtonElement = 'phone';
          }

          scrollToHTMLInputElement(`message-template-button${errorButtonIndex}-${errorButtonElement}`, true);
        }
      }
    }
  }, [errors]);

  const handleSave = useCallback(() => {
    resetSelection();
  }, [resetSelection]);

  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: selectedTemplate } = useMessageTemplateGroupsData({
    businessId: businessId as string,
    enabled: !!businessId,
    channelType: 'CHANNEL_TYPE_WHATSAPP_CLOUD',
    select: selectMessageTemplateById(selectedTemplateId || ''),
    keepPreviousData: true,
    filterByTemplateIdOrExternalTemplateId: {
      templateId: selectedTemplateId || '',
    },
  });
  const dispatch = useDispatch();
  const { onNodeObjectChange } = useCampaignFlowChangeAppliers();
  const { templateName } = useCreateNode();

  const handleEditApprovedTemplate = useCallback(() => {
    if (!node || !nodeId || !selectedTemplate?.template) return;

    dispatch(setTemplateId(CREATE_NEW_TEMPLATE_ID));

    const {
      template: { templateComponents },
    } = selectedTemplate;

    const name = templateName();

    dispatch(
      replaceUnsentMessageTemplate({
        nodeId,
        template: {
          id: CREATE_NEW_TEMPLATE_ID,
          errors: undefined,
          name,
          templateComponents,
        },
      }),
    );

    const updatedNode = produce(node, (draft) => {
      draft.data.v1.templateId = CREATE_NEW_TEMPLATE_ID;
      draft.data.v1.name = name;

      if (templateComponents) {
        draft.data.v1.messageTemplateInput = {
          templateComponents,
          name,
        };
      } else if (draft.data.v1.messageTemplateInput) {
        draft.data.v1.messageTemplateInput.name = name;
      }

      return draft;
    });

    if (!updatedNode) return;

    onNodeObjectChange([
      {
        id: updatedNode.id,
        item: updatedNode,
      },
    ]);
    dispatch(editNode({ node: updatedNode }));
  }, [dispatch, nodeId, selectedTemplate, node, onNodeObjectChange, templateName]);

  const ownsCarousel = hasCarousel(templateType);

  return {
    isEditable,
    handleEditApprovedTemplate,
    templateType,
    templateId: selectedTemplateId,
    onSave: handleSave,
    node,
    ownsCarousel,
  };
};

export const TemplateBuilder = () => {
  const { isEditable, handleEditApprovedTemplate, templateType, onSave, ownsCarousel } = useTemplateBuilder();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { t } = useTranslation('translation', { keyPrefix: 'templateBuilder' });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          gap: 1,
          flex: '0 0 auto',
          px: isLargeScreen ? 3 : 2,
          mt: isLargeScreen ? 3.5 : 2,
          mb: isLargeScreen ? 2 : 1,
        }}
      >
        <Label variant="h6">{ownsCarousel ? t('titleCarousel') : t('titleMessage')}</Label>
        <IconButton aria-label="close" onClick={onSave}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        id="message-template-content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: isLargeScreen ? 3 : 2,
          flex: '1 1 auto',
          gap: isLargeScreen ? 3 : 2,
          overflowY: 'hidden',
          scrollbarGutter: 'stable',
          ':hover': {
            overflowY: 'auto',
          },
        }}
      >
        {hasTemplateSelector(templateType) && <TemplateBuilderTemplateSelector />}
        <Collapse in={isEditable}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {hasHeader(templateType) && <TemplateBuilderHeader />}
              {hasBody(templateType) && <TemplateBuilderBody minimized={ownsCarousel} />}
              {hasFooter(templateType) && <TemplateBuilderFooter />}
              {hasButtons(templateType) && <TemplateBuilderButtons />}
              {hasList(templateType) && <TemplateBuilderList />}
              {ownsCarousel && <TemplateBuilderCarousel />}
            </Box>
          </Box>
        </Collapse>
        {!isEditable && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0.9,
              gap: 3,
              justifyContent: 'center',
              mb: 10,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Label variant="body1">Changes to this message will require it to be approved again.</Label>
            <Button variant="outlined" color="primary" fullWidth onClick={handleEditApprovedTemplate}>
              Edit message
            </Button>
            <Label variant="body2" sx={{ color: theme.palette.grey[600] }}>
              You can always go back to the approved template by selecting it from dropdown above.
            </Label>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          p: 3,
          flex: '0 0 auto',
        }}
      >
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};
