import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  EVENT_REQUEST_GENERATE_CAMPAIGN_REPORT_URL,
  EventRequestGenerateCampaignReportResponse,
  EventRequestGenerateCampaignReportInput,
  EventRequestGenerateCampaignReportOptions,
} from './types';

export const useEventRequestGenerateCampaignReport = (options?: EventRequestGenerateCampaignReportOptions) => {
  return useMutation(
    (input: EventRequestGenerateCampaignReportInput) =>
      api.reportV1(EVENT_REQUEST_GENERATE_CAMPAIGN_REPORT_URL, 'post', {
        path: { 'input.businessId': input.businessId },
        body: {
          input: {
            ...input,
          },
        },
      }) as Promise<EventRequestGenerateCampaignReportResponse>,
    {
      ...options,
    },
  );
};
