import { Node } from 'react-flow-renderer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { messageTemplateStatusToJSON } from '@connectlyai-features/broadcast';
import { ParameterValueMapping } from '@connectlyai-tenets/sdk';
import { NodeType, TemplateType } from '../../sdks/flow/createNode';
import {
  MessageTemplateRejectionReason,
  MessageTemplateStatus,
  TemplateComponent,
  ListTemplateComponent,
} from '../../hooks/useMessageTemplateGroupsData/types';
import { mapToVariableHash, populateVariables } from '../../hooks/useFlowVariables';
import {
  ButtonGroup,
  TemplateComponentEnabled,
  TemplateBuilderParams,
  UnsentMessageTemplate,
  ButtonError,
  ButtonState,
  HeaderType,
  TemplateApprovalDialogParams,
} from './types';
import { cancel, deleteNode, duplicateNode, editNode, resetFlowChart, updateNodeId } from '../actions';
import {
  CREATE_NEW_TEMPLATE_ID,
  DEFAULT_HEADER_TYPE,
  DEFAULT_UNSENT_MESSAGE_TEMPLATE,
  EMPTY_TEMPLATE_ID,
} from './constants';
import { getMediaHeaderType, getTemplateBuilderErrors, mapMediaHeaderTypeToTemplateParamName } from '../../utils';
import { MessageTemplateStatusUpdate } from '../../hooks/useCreateMessageTemplateResultData/types';
import { MessageNodeProps } from '../../components/MessageNode/types';
import {
  normalizeBody,
  normalizeButton,
  normalizeFooter,
  normalizeHeader,
  normalizeUrl,
} from '../../presentation/preview/utils';

const linkTrackingDefault = true;

type State = {
  unsentTemplates: {
    entities: { [nodeId: string]: UnsentMessageTemplate | undefined };
    ids: string[];
  };
  templateBuilderParams: TemplateBuilderParams | undefined;
  templateApprovalDialogParams: TemplateApprovalDialogParams | undefined;
};

const initialState: State = {
  unsentTemplates: {
    entities: {},
    ids: [],
  },
  templateBuilderParams: undefined,
  templateApprovalDialogParams: undefined,
};

export const slice = createSlice({
  name: 'template-builder',
  initialState,
  reducers: {
    approveConnectlyTemplates: (state, { payload: { nodeIds } }: PayloadAction<{ nodeIds: string[] }>) => {
      const set = new Set(nodeIds);
      Object.entries(state.unsentTemplates.entities).forEach(([nodeId, template]) => {
        if (set.has(nodeId) && template) {
          template.status = 'MESSAGE_TEMPLATE_STATUS_APPROVED';
          template.rejectionReason = undefined;
        }
      });
    },
    normalize: (state) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate } = templateBuilderParams;
      const { templateComponents } = messageTemplate;

      const bodyComponent = templateComponents?.find((x) => Boolean(x.body));
      if (bodyComponent && bodyComponent?.body?.text?.text)
        bodyComponent.body.text = { text: normalizeBody(bodyComponent.body.text.text) };

      const footerComponent = templateComponents?.find((x) => Boolean(x.footer));
      if (footerComponent && footerComponent?.footer?.text?.text)
        footerComponent.footer.text = { text: normalizeFooter(footerComponent.footer.text.text) };

      const headerComponent = templateComponents?.find((x) => Boolean(x.header));
      if (headerComponent && headerComponent?.header?.text?.text)
        headerComponent.header.text = { text: normalizeHeader(headerComponent.header.text.text) };

      const buttonComponents = templateComponents?.filter((x) => Boolean(x.button));
      buttonComponents?.forEach((buttonComponent) => {
        if (buttonComponent.button?.quickReply?.text)
          buttonComponent.button.quickReply.text = normalizeButton(buttonComponent.button?.quickReply?.text);
        if (buttonComponent.button?.phoneNumber?.text)
          buttonComponent.button.phoneNumber.text = normalizeButton(buttonComponent.button?.phoneNumber?.text);
        if (buttonComponent.button?.url?.text)
          buttonComponent.button.url.text = normalizeButton(buttonComponent.button?.url?.text);
        if (buttonComponent.button?.url?.url)
          buttonComponent.button.url.url = normalizeUrl(buttonComponent.button?.url?.url);
      });
    },
    checkCurrentTemplateErrors: (state) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { templateId, nodeId, messageTemplate, isComponentEnabled, uiState } = templateBuilderParams;
      templateBuilderParams.errors = getTemplateBuilderErrors(
        templateId,
        messageTemplate,
        isComponentEnabled,
        // eslint-disable-next-line prettier/prettier
        { mappings: Object.entries(uiState.mappings).map(([key, value]) => ({ rawParam: key, fullQualifier: value })) },
      );
      if (templateBuilderParams.errors) {
        const unsentTemplate = state.unsentTemplates.entities[nodeId];
        if (unsentTemplate) {
          unsentTemplate.errors = templateBuilderParams.errors;
        }
      }
    },
    recalculateAllErrors: (state, action: { payload: Node[] }) => {
      Object.entries(state.unsentTemplates.entities).forEach(([id, template]) => {
        if (template) {
          const node = action.payload.find((x) => x.id === id);
          const parameterMapping = node?.data?.v1?.parameterMapping;
          const header = Boolean(template.templateComponents?.find((x) => x.header));
          const footer = Boolean(template.templateComponents?.find((x) => x.footer));
          const hasButtons = Boolean(template.templateComponents?.find((x) => x.button));
          const hasList = Boolean(template.templateComponents?.find((x) => x.list));
          const isComponentEnabled = {
            header,
            footer,
            buttons: hasButtons,
            list: hasList,
          };

          template.errors = getTemplateBuilderErrors(
            template?.id || '',
            template,
            isComponentEnabled,
            parameterMapping,
          );
        }
      });
    },
    save: (state) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { templateId, nodeId, messageTemplate, isComponentEnabled, uiState } = templateBuilderParams;
      templateBuilderParams.errors = getTemplateBuilderErrors(
        templateId,
        messageTemplate,
        isComponentEnabled,
        // eslint-disable-next-line prettier/prettier
        { mappings: Object.entries(uiState.mappings).map(([key, value]) => ({ rawParam: key, fullQualifier: value })) },
      );
      if (templateBuilderParams.errors) {
        const unsentTemplate = state.unsentTemplates.entities[nodeId];
        if (unsentTemplate) {
          unsentTemplate.errors = templateBuilderParams.errors;
        }
        return;
      }

      const unsentTemplate = state.unsentTemplates.entities[nodeId];

      const isEditable =
        templateId === CREATE_NEW_TEMPLATE_ID || unsentTemplate?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED';
      if (isEditable) {
        if (messageTemplate.templateComponents) {
          const { header, footer, buttons } = isComponentEnabled;

          messageTemplate.templateComponents = header
            ? messageTemplate.templateComponents
            : messageTemplate.templateComponents.filter((x) => !x.header);

          messageTemplate.templateComponents = footer
            ? messageTemplate.templateComponents
            : messageTemplate.templateComponents.filter((x) => !x.footer);

          messageTemplate.templateComponents = buttons
            ? messageTemplate.templateComponents
            : messageTemplate.templateComponents.filter((x) => !x.button);
        }
        messageTemplate.errors = undefined;
        messageTemplate.status = undefined;
        messageTemplate.rejectionReason = undefined;

        state.unsentTemplates.entities[nodeId] = messageTemplate;
      } else {
        delete state.unsentTemplates.entities[nodeId];
      }

      state.templateBuilderParams = undefined;
    },
    setBody: (state, { payload: body }: PayloadAction<string>) => {
      const { templateBuilderParams } = state;

      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, uiState, errors } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      const bodyComponent = templateComponents?.find((x) => Boolean(x.body));
      if (bodyComponent && bodyComponent.body) {
        bodyComponent.body.text = { text: body };
      } else {
        messageTemplate.templateComponents = [...templateComponents, { body: { text: { text: body } } }];
      }

      Object.keys(uiState.mappings).forEach((key) => {
        if (key.startsWith('body_')) {
          delete uiState.mappings[key];
        }
      });

      uiState.mappings = { ...uiState.mappings, ...mapToVariableHash(body, 'body_', '{{}}') };

      if (errors && errors.body && body.trim()) {
        delete errors.body;
      }
    },
    setTextHeader: (state, { payload: header }: PayloadAction<string>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, errors, uiState } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      const headerComponent = templateComponents?.find((x) => Boolean(x.header));
      if (headerComponent && headerComponent.header) {
        headerComponent.header.text = { text: header };
      } else {
        messageTemplate.templateComponents = [...templateComponents, { header: { text: { text: header } } }];
      }

      Object.keys(uiState.mappings).forEach((key) => {
        if (key.startsWith('header_text.')) {
          delete uiState.mappings[key];
        }
      });

      uiState.mappings = { ...uiState.mappings, ...mapToVariableHash(header, 'header_text.', '{{}}') };

      if (errors && errors.header && header.trim()) {
        delete errors.header;
      }
    },
    setMappings: (state, { payload: mappings }: PayloadAction<{ [key: string]: string }>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.uiState.mappings = mappings;
    },
    setMediaHeaderMapping: (state, { payload: variableFqn }: PayloadAction<string | null>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }
      const { messageTemplate, headerType, uiState } = templateBuilderParams;
      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      const templateParamName = mapMediaHeaderTypeToTemplateParamName(headerType);
      if (variableFqn === null) {
        delete uiState.mappings[templateParamName];
        return;
      }
      uiState.mappings[templateParamName] = variableFqn;
    },
    setMediaHeaderExample: (state, { payload: example }: PayloadAction<string | null>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, headerType, errors } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      const headerComponent = templateComponents?.find((x) => Boolean(x.header));
      const headerComponentType = getMediaHeaderType(headerType);
      if (headerComponent && headerComponent.header) {
        headerComponent.header.media = {
          type: headerComponentType,
          example: example ? [example] : [],
        };
      } else {
        messageTemplate.templateComponents = [
          ...templateComponents,
          {
            header: { media: { type: headerComponentType, example: example ? [example] : [] } },
          },
        ];
      }
      if (errors && errors.header && (example === null || example.trim())) {
        delete errors.header;
      }
    },
    setFooter: (state, { payload: footer }: PayloadAction<string>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, errors } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      const footerComponent = templateComponents?.find((x) => Boolean(x.footer));
      if (footerComponent && footerComponent.footer) {
        footerComponent.footer.text = { text: footer };
      } else {
        messageTemplate.templateComponents = [...templateComponents, { footer: { text: { text: footer } } }];
      }

      if (errors && errors.footer && footer.trim()) {
        delete errors.footer;
      }
    },
    setButtonsGroup: (state, { payload: buttonsGroup }: PayloadAction<ButtonGroup>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.buttonsGroup = buttonsGroup;
    },
    setHeaderType: (state, { payload: headerType }: PayloadAction<HeaderType>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, errors } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      let headerComponent = templateComponents?.find((x) => Boolean(x.header)) || { header: {} };
      if (!headerComponent.header) {
        headerComponent = { header: {} };
        return;
      }

      templateBuilderParams.headerType = headerType;
      switch (headerType) {
        case HeaderType.Text: {
          headerComponent.header.text = { text: '' };
          delete headerComponent.header.media;
          break;
        }
        case HeaderType.Image: {
          delete headerComponent.header.text;
          headerComponent.header.media = { type: 'TYPE_IMAGE', example: [] };
          break;
        }
        case HeaderType.Video: {
          delete headerComponent.header.text;
          headerComponent.header.media = { type: 'TYPE_VIDEO', example: [] };
          break;
        }
        case HeaderType.Document: {
          delete headerComponent.header.text;
          headerComponent.header.media = { type: 'TYPE_DOCUMENT', example: [] };
          break;
        }
        default: {
          break;
        }
      }
      messageTemplate.templateComponents = [...templateComponents.filter((x) => !x.header), headerComponent];

      if (errors && errors.header) {
        delete errors.header;
      }
    },
    setButtons: (
      state,
      {
        payload: { buttons, error, uiState, mappings },
      }: PayloadAction<{
        buttons: TemplateComponent[];
        error: Partial<ButtonError>[] | undefined;
        uiState: Partial<ButtonState>[] | undefined;
        mappings?: {
          deletePrefix: string;
          new: { [key: string]: string };
        };
      }>,
    ) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      messageTemplate.templateComponents = [...templateComponents.filter((x) => !x.button), ...buttons];
      if (templateBuilderParams.errors) {
        templateBuilderParams.errors.buttons = error;
      }

      if (templateBuilderParams.uiState) {
        templateBuilderParams.uiState.buttons = uiState;
        if (mappings) {
          Object.keys(templateBuilderParams.uiState.mappings).forEach((key) => {
            if (key.startsWith(mappings.deletePrefix)) {
              delete templateBuilderParams.uiState.mappings[key];
            }
          });
          Object.keys(mappings.new).forEach((key) => {
            templateBuilderParams.uiState.mappings[key] = mappings.new[key];
          });
        }
      }
    },
    setName: (state, { payload: name }: PayloadAction<string>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate } = templateBuilderParams;

      messageTemplate.name = name;
    },
    setIsComponentEnabled: (
      state,
      { payload: isComponentEnabled }: PayloadAction<Partial<TemplateComponentEnabled>>,
    ) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.isComponentEnabled = { ...templateBuilderParams.isComponentEnabled, ...isComponentEnabled };

      // add default first button
      if (isComponentEnabled.buttons) {
        const { messageTemplate, buttonsGroup } = templateBuilderParams;
        const buttons = messageTemplate.templateComponents?.filter((x) => x.button) || [];
        if (buttons.length === 0) {
          const button: TemplateComponent =
            buttonsGroup === ButtonGroup.QuickReply
              ? { button: { quickReply: { text: '' } } }
              : { button: { phoneNumber: { text: '', phoneNumber: '' } } };

          messageTemplate.templateComponents = [...(messageTemplate.templateComponents || []), button];
        }
      }
      if (isComponentEnabled.header) {
        const { messageTemplate } = templateBuilderParams;
        const header = messageTemplate.templateComponents?.find((x) => x.header);
        if (!header) {
          messageTemplate.templateComponents = [...(messageTemplate.templateComponents || [])];
        }
      }
    },
    setList: (
      state,
      {
        payload: { list },
      }: PayloadAction<{
        list: ListTemplateComponent;
      }>,
    ) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { messageTemplate, errors } = templateBuilderParams;

      const { templateComponents } = messageTemplate;
      if (!templateComponents) {
        return;
      }

      messageTemplate.templateComponents = [...templateComponents.filter((x) => !x.list), { list }];

      if (errors && errors.list && errors.list?.length > 0) {
        delete errors.list;
      }

      if (errors && errors.listButton && list.button?.trim()) {
        delete errors.list;
      }
    },
    setTemplateId: (state, { payload: templateId }: PayloadAction<string | null>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.templateId = templateId;

      if (templateId === CREATE_NEW_TEMPLATE_ID) {
        const messageTemplate = DEFAULT_UNSENT_MESSAGE_TEMPLATE();

        templateBuilderParams.messageTemplate = messageTemplate;
        state.unsentTemplates.entities[templateBuilderParams.nodeId] = messageTemplate;
      } else {
        templateBuilderParams.messageTemplate = { name: '', errors: undefined };
        delete state.unsentTemplates.entities[templateBuilderParams.nodeId];
      }

      const { errors } = templateBuilderParams;
      if (errors && errors.templateId && templateId !== EMPTY_TEMPLATE_ID) {
        delete errors.templateId;
      }
    },
    setPreviewTemplateId: (state, { payload: templateId }: PayloadAction<string | null>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.previewTemplateId = templateId;
    },
    setTemplateComponents: (state, { payload: templateComponents }: PayloadAction<TemplateComponent[]>) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      templateBuilderParams.messageTemplate.templateComponents = templateComponents;
    },
    setTemplateApprovalDialogOpen: (state, { payload: open }: PayloadAction<boolean>) => {
      state.templateApprovalDialogParams = { open };
    },
    setMessageTemplateStatusUpdate: (state, { payload: update }: PayloadAction<MessageTemplateStatusUpdate>) => {
      Object.values(state.unsentTemplates.entities).forEach((x) => {
        if (x && x?.name === update.templateName) {
          x.status = messageTemplateStatusToJSON(update.status) as MessageTemplateStatus;
          x.rejectionReason = update.rejectionDetails;
        }
      });
    },
    replaceUnsentMessageTemplate: (
      state,
      { payload: { nodeId, template } }: PayloadAction<{ nodeId: string; template: UnsentMessageTemplate }>,
    ) => {
      state.unsentTemplates.entities[nodeId] = template;
    },
    setMessageTemplateFromNode: (state, { payload: { node } }: PayloadAction<{ node: Node }>) => {
      if (!node.data) return;
      const { id: nodeId, type: nodeType } = node;
      const v1 = (node as MessageNodeProps).data?.v1;

      if (!v1 || (v1.waMessageTemplateId && v1.waMessageTemplateId !== CREATE_NEW_TEMPLATE_ID)) return;
      const messageTemplate: UnsentMessageTemplate = DEFAULT_UNSENT_MESSAGE_TEMPLATE();

      switch (nodeType) {
        case 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE': {
          const { name, body, footer, buttons, textHeader, mediaHeader, headerType, list } = v1;
          if (!name && !body && !footer && !buttons && !textHeader && !mediaHeader && !list) return;
          if (name) {
            messageTemplate.name = name;
          }

          let { templateComponents } = messageTemplate;

          if (!templateComponents) return;

          if (body) {
            templateComponents = [...templateComponents.filter((x) => !x.body), { body: { text: { text: body } } }];
          }
          if (footer) {
            templateComponents = [
              ...templateComponents.filter((x) => !x.footer),
              { footer: { text: { text: footer } } },
            ];
          }
          if (textHeader) {
            templateComponents = [
              ...templateComponents.filter((x) => !x.header),
              { header: { text: { text: textHeader } } },
            ];
          }
          if (buttons) {
            templateComponents = [...templateComponents.filter((x) => !x.button), ...buttons];
          }
          if (list) {
            templateComponents = [...templateComponents.filter((x) => !x.list), { list }];
          }
          if (mediaHeader && headerType) {
            templateComponents = [
              ...templateComponents.filter((x) => !x.header),
              {
                header: {
                  media: {
                    type: getMediaHeaderType(headerType),
                    example: mediaHeader,
                  },
                },
              },
            ];
          }
          state.unsentTemplates.entities[nodeId] = { ...messageTemplate, templateComponents };
          break;
        }
        default: {
          const { name } = v1;
          if (!name) return;
          if (name) {
            messageTemplate.name = name;
          }

          let { templateComponents } = messageTemplate;

          if (v1?.messageTemplateInput?.templateComponents) {
            templateComponents = v1.messageTemplateInput.templateComponents;
          }

          if (!templateComponents) return;

          state.unsentTemplates.entities[nodeId] = { ...messageTemplate, templateComponents };
          break;
        }
      }
    },
    setMessageTemplateStatus: (
      state,
      {
        payload: { nodeId, status, rejectionReason },
      }: PayloadAction<{
        nodeId: string;
        status: MessageTemplateStatus | undefined;
        rejectionReason?: MessageTemplateRejectionReason | undefined;
      }>,
    ) => {
      const template = state.unsentTemplates.entities[nodeId];
      if (template) {
        template.status = status;
        template.rejectionReason = rejectionReason;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFlowChart, (_state, _action) => {
      return initialState;
    });
    builder.addCase(deleteNode, (state, action) => {
      const { payload: node } = action;

      if (state.unsentTemplates.entities[node.id]) delete state.unsentTemplates.entities[node.id];

      const { templateBuilderParams } = state;
      if (templateBuilderParams && templateBuilderParams.nodeId === node.id) state.templateBuilderParams = undefined;
    });
    builder.addCase(duplicateNode, (_state, _action) => {});
    builder.addCase(editNode, (state, { payload: { node, showErrors } }) => {
      const nodeType = node.type as NodeType;
      const isEditableType =
        nodeType === 'FLOW_OBJECT_TYPE_SEND_WA_MESSAGE' ||
        nodeType === 'FLOW_OBJECT_TYPE_SEND_CONNECTLY_TEMPLATE_MESSAGE';
      if (!isEditableType) {
        return;
      }

      const unsentTemplate = state.unsentTemplates.entities[node.id];

      const { data } = node;
      if (!data) {
        return;
      }

      const { v1 } = data;
      if (!v1) {
        return;
      }

      // TODO This needs to be typesafe
      const { waMessageTemplateId, waMessageTemplateType, templateId, parameterMapping } = v1;

      const mappings: { [key: string]: string } = {};
      const typedParameterMapping = parameterMapping as ParameterValueMapping | undefined;
      typedParameterMapping?.mappings?.forEach((val) => {
        if (val.rawParam) {
          mappings[val.rawParam] = `{{${val.fullQualifier}}}`;
        }
      });

      let templateBuilderParams: TemplateBuilderParams;

      const isEditable =
        (!waMessageTemplateId && !templateId) || unsentTemplate?.status === 'MESSAGE_TEMPLATE_STATUS_REJECTED';

      if (!isEditable) {
        templateBuilderParams = {
          nodeId: node.id,
          messageTemplate: { name: '', errors: undefined },
          templateId: waMessageTemplateId || templateId,
          previewTemplateId: EMPTY_TEMPLATE_ID,
          buttonsGroup: ButtonGroup.QuickReply,
          canChangeButtonsGroup: false,
          headerType: HeaderType.Text,
          isComponentEnabled: { header: false, footer: false, buttons: false, list: false },
          errors: undefined,
          uiState: {
            buttons: undefined,
            mappings,
          },
        };

        delete state.unsentTemplates.entities[node.id];
      } else {
        const paramsTemplateId = unsentTemplate?.id || CREATE_NEW_TEMPLATE_ID;
        let messageTemplate = unsentTemplate || DEFAULT_UNSENT_MESSAGE_TEMPLATE();

        if (v1.name) messageTemplate = { ...messageTemplate, name: v1.name } as UnsentMessageTemplate;

        let { templateComponents } = messageTemplate;

        let buttonsGroup = ButtonGroup.CallToAction;
        let canChangeButtonsGroup = false;
        const buttons = templateComponents?.filter((x) => x.button) || [];
        let uiStateButtons = buttons.map((x, idx) => {
          const isTracked = Boolean(x.button?.url?.trackedUrl);
          const url = isTracked ? x.button?.url?.trackedUrl : x.button?.url?.url;
          let finalUrl = url?.replace('https://', '') || '';
          const paramIdx = idx + 1;
          const paramPrefix = `button_${paramIdx}_url_suffix.`;
          finalUrl = populateVariables(finalUrl, paramPrefix, typedParameterMapping?.mappings);
          return {
            tracked: (x.button?.url?.trackedUrl || '') !== '',
            url: finalUrl,
          };
        });
        if (waMessageTemplateType === undefined) {
          buttonsGroup = templateComponents?.find((x) => x.button && (x.button.url || x.button.phoneNumber))
            ? ButtonGroup.CallToAction
            : ButtonGroup.QuickReply;
          canChangeButtonsGroup = false;
        } else {
          switch (waMessageTemplateType as TemplateType) {
            case 'FLOW_OBJECT_TEMPLATE_TYPE_SIMPLE_MESSAGE': {
              buttonsGroup = ButtonGroup.CallToAction;
              canChangeButtonsGroup = false;
              break;
            }
            case 'FLOW_OBJECT_TEMPLATE_TYPE_CTA_MESSAGE': {
              buttonsGroup = ButtonGroup.CallToAction;
              canChangeButtonsGroup = false;

              if (buttons.length === 0) {
                templateComponents = [{ button: { url: { text: '', url: '' } } }];
                uiStateButtons = [{ tracked: linkTrackingDefault, url: '' }];
                messageTemplate.templateComponents = templateComponents;
              }
              break;
            }
            case 'FLOW_OBJECT_TEMPLATE_TYPE_QUICK_REPLY_MESSAGE': {
              buttonsGroup = ButtonGroup.QuickReply;
              canChangeButtonsGroup = false;

              if (buttons.length === 0) {
                templateComponents = [{ button: { quickReply: { text: '' } } }];
                uiStateButtons = [{ tracked: linkTrackingDefault, url: '' }];
                messageTemplate.templateComponents = templateComponents;
              }
              break;
            }
            case 'FLOW_OBJECT_TEMPLATE_TYPE_LIST_MESSAGE': {
              const list = templateComponents?.find((x) => x.list);
              if (!list) {
                const newRow = { text: '', id: uuidv4(), description: '' };
                const newList: ListTemplateComponent = {
                  button: '',
                  sections: [
                    {
                      title: '',
                      rows: [newRow],
                    },
                  ],
                };
                messageTemplate.templateComponents = [{ list: newList }];
              }
              break;
            }
            default: {
              buttonsGroup = ButtonGroup.CallToAction;
              canChangeButtonsGroup = false;
            }
          }
        }

        let headerType = DEFAULT_HEADER_TYPE;
        const headerComponent = templateComponents?.find((x) => x.header);
        if (headerComponent?.header?.media) {
          switch (headerComponent?.header?.media.type) {
            case 'TYPE_IMAGE': {
              headerType = HeaderType.Image;
              break;
            }
            case 'TYPE_VIDEO': {
              headerType = HeaderType.Video;
              break;
            }
            case 'TYPE_DOCUMENT': {
              headerType = HeaderType.Document;
              break;
            }
            default: {
              break;
            }
          }
        } else if (headerComponent?.header?.text) {
          headerType = HeaderType.Text;
        }

        const header = Boolean(templateComponents?.find((x) => x.header));
        const footer = Boolean(templateComponents?.find((x) => x.footer));
        const hasButtons = Boolean(templateComponents?.find((x) => x.button));
        const hasList = Boolean(templateComponents?.find((x) => x.list));
        const isComponentEnabled = {
          header,
          footer,
          buttons: hasButtons,
          list: hasList,
        };

        templateBuilderParams = {
          nodeId: node.id,
          messageTemplate,
          templateId: paramsTemplateId,
          previewTemplateId: EMPTY_TEMPLATE_ID,
          buttonsGroup,
          canChangeButtonsGroup,
          headerType,
          isComponentEnabled,
          errors: showErrors ? messageTemplate.errors : undefined,
          uiState: {
            buttons: uiStateButtons,
            mappings,
          },
        };

        state.unsentTemplates.entities[node.id] = messageTemplate;
      }

      state.templateBuilderParams = templateBuilderParams;
    });
    builder.addCase(updateNodeId, (state, { payload: { nodeId, newNodeId } }) => {
      const unsentTemplate = state.unsentTemplates.entities[nodeId];
      if (unsentTemplate) {
        delete state.unsentTemplates.entities[nodeId];
        state.unsentTemplates.entities[newNodeId] = unsentTemplate;
      }

      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      if (templateBuilderParams.nodeId === nodeId) {
        templateBuilderParams.nodeId = newNodeId;
      }
    });
    builder.addCase(cancel, (state) => {
      const { templateBuilderParams } = state;
      if (!templateBuilderParams) {
        return;
      }

      const { templateId, nodeId, isComponentEnabled, uiState } = templateBuilderParams;
      const unsentTemplate = state.unsentTemplates.entities[nodeId];
      if (unsentTemplate) {
        unsentTemplate.errors = getTemplateBuilderErrors(
          templateId,
          unsentTemplate,
          isComponentEnabled,
          uiState.mappings,
        );
      }

      state.templateBuilderParams = undefined;
    });
  },
});

export default slice.reducer;
