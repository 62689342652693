import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const EVENT_REQUEST_GENERATE_ABANDONED_CART_REPORT_URL =
  '/internal/v1/businesses/{input.businessId}/event_request_generate_abandoned_checkout_report/reports';

type EventRequestGenerateAbandonedCartReportURL = typeof EVENT_REQUEST_GENERATE_ABANDONED_CART_REPORT_URL;

export type EventRequestGenerateAbandonedCartReportResponse =
  Paths['reportV1'][EventRequestGenerateAbandonedCartReportURL]['post']['responses']['202'];
export type EventRequestGenerateAbandonedCartReportInput = {
  businessId: string;
} & Paths['reportV1'][EventRequestGenerateAbandonedCartReportURL]['post']['parameters']['body']['input'];
export type EventRequestGenerateAbandonedCartReportOptions = Omit<
  UseMutationOptions<
    EventRequestGenerateAbandonedCartReportResponse,
    ConnectlyError,
    EventRequestGenerateAbandonedCartReportInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
