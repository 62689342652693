import React, { useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';
import { deactivateBotModalIsOpenAtom } from '@atoms/sofia';
import {
  Button,
  IconButton,
  Box,
  Label,
  useTheme,
  Dialog,
  DialogContent,
  CloseIcon,
} from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { DeactivateBotModalProps } from './types';
import { SIZE_CONTENT_L, SIZE_SPACING_INTER_SECTION } from '../../../../ui-theme';

const useDeactivateBotModal = ({ deactivateBot }: DeactivateBotModalProps) => {
  const [isOpen, setIsOpen] = useAtom(deactivateBotModalIsOpenAtom);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onConfirm = useCallback(() => {
    deactivateBot();
    setIsOpen(false);
  }, [deactivateBot, setIsOpen]);
  const { t } = useTranslation('translation', { keyPrefix: '' });
  const { title, body, confirmLabel, cancelLabel } = useMemo(() => {
    return {
      title: t('sofia.deactivateBotModalTitle'),
      body: t('sofia.deactivateBotModalBody'),
      confirmLabel: t('generic.confirm'),
      cancelLabel: t('generic.cancel'),
    };
  }, [t]);
  return {
    title,
    body,
    confirmLabel,
    cancelLabel,
    isOpen,
    onClose,
    onConfirm,
  };
};

export const DeactivateBotModal = ({ deactivateBot }: DeactivateBotModalProps) => {
  const { title, body, confirmLabel, cancelLabel, isOpen, onClose, onConfirm } = useDeactivateBotModal({
    deactivateBot,
  });
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" disableRestoreFocus>
      <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_L), p: SIZE_SPACING_INTER_SECTION }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label variant="h6" sx={{ mb: 2, fontWeight: 700 }}>
            {title}
          </Label>
          <IconButton size="small" onClick={onClose} sx={{ width: theme.spacing(4), height: theme.spacing(4) }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {body}
        <Box sx={{ textAlign: 'right', pt: 2 }}>
          <Button variant="text" color="primary" onClick={onClose}>
            {cancelLabel}
          </Button>
          <Button variant="text" color="primary" onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
