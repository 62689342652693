import { ChannelType } from './models/common/channel/models';
export function hasTransientEvent(room) {
    var _a, _b;
    if (!room) {
        return false;
    }
    const { events } = room;
    return (events &&
        events.length === 1 &&
        ((_b = (_a = events[0].connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'eventDeliveryStatus');
}
export function isConnectlyRoom(room) {
    if (!room) {
        return false;
    }
    const { customer } = room;
    if (customer) {
        const { channels } = customer;
        return (channels &&
            channels.some((x) => x.channelType === ChannelType.CHANNEL_TYPE_CONNECTLY));
    }
    return false;
}
export function isIncomingMessage(room) {
    var _a;
    if (!room) {
        return false;
    }
    const { events } = room;
    if (!events) {
        return false;
    }
    if (!events.length) {
        return false;
    }
    const event = events[0];
    if (!event) {
        return false;
    }
    const { businessUserId, connectlyEvent } = event;
    if (businessUserId) {
        return false;
    }
    if (!connectlyEvent) {
        return false;
    }
    const isMessage = ((_a = connectlyEvent.eventOneof) === null || _a === void 0 ? void 0 : _a.$case) === 'message';
    if (!isMessage) {
        return false;
    }
    return true;
}
