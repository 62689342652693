import { QuerySendoutAnalyticsResponse, SendoutAnalytics } from './types';

export const generatePlaceholderData = (count = 5): QuerySendoutAnalyticsResponse => {
  const generateItem = (index: number): SendoutAnalytics => ({
    sendoutId: `SKELETON-${index}`,
    sendoutName: '',
    businessId: '',
    buttonClicks: {
      buttonClicks: [],
      uniqueButtonClicks: 0,
    },
    linkClicks: {
      linkClicks: [],
      uniqueLinkClicks: 0,
    },
    sendoutEngagement: {
      uniqueSessionEngagement: 0,
    },
    campaignCreatedAt: new Date().toISOString(),
    campaignId: `campaign-${index}`,
    campaignName: '',
    sendoutCompletedAt: new Date().toISOString(),
    sentCount: 0,
    readCount: 0,
    deliveredCount: 0,
    optOutCount: 0,
    errors: {
      items: [],
      total: 0,
    },
  });
  const items = Array.from({ length: count }, (_, index) => generateItem(index));
  return {
    entity: {
      items,
    },
  };
};
