import { webhookTopicFromJSON, webhookTopicToJSON, deliveryStatusFromJSON, deliveryStatusToJSON, roomStatusFromJSON, roomStatusToJSON, } from "../common/models";
import { Customer, Business, BusinessChannel, eligibilityTypeFromJSON, eligibilityTypeToJSON, } from "../profile/models";
import { ChannelIdentifier, ChannelSelector, channelTypeFromJSON, channelTypeToJSON, } from "../common/channel/models";
import { Timestamp } from "../../google/protobuf/timestamp";
import Long from "long";
import { PlatformError } from "../platformapi/models";
import { Attachment, Location, Interactive } from "../event/connectly/models";
import { Order, Product, WebhookEventEnvelope, } from "../../events/shopify/v2023_01/models";
import { TagAssociationCollection } from "../tags/models";
import { Metadata } from "../common/store/models";
import * as _m0 from "protobufjs/minimal";
import { TemplatedMessage } from "../../events/connectly/templated_message/v1/models";
import { Order as Order1, Checkout } from "../order/models";
import { Status, Comment } from "../ticket/models";
import { PaymentNotification } from "../payment/models";
import { APIResult } from "../../exp/eta/events/api";
import { FlowTrigger } from "../../exp/eta/events/trigger";
import { Placeholder } from "../../exp/eta/events/placeholder";
import { RecommenderCampaignCommand } from "../../exp/eta/events/recommender";
import { Timeout } from "../../exp/eta/events/timeout";
import { VerifyCampaignResult, SubmitCampaignResult, } from "../crm/campaign/models";
import { VerifySendoutResult, SubmitSendoutResult } from "../campaign/dtos";
import { MessageTemplateStatusUpdate } from "../messaging/template/models";
import { ReactFlowDocumentChangeDto, SubmitReactFlowDocumentResult, VerifyReactFlowDocumentResult, CompileReactFlowDocumentResult, DeployReactFlowDocumentResult, } from "../flow/dto/dto";
import { FbMessengerWebhookEnvelope } from "../../events/facebook/webhook/v6_0/models";
import { TwilioMessagingWebhookEvent } from "../../events/twilio/webhook/v20100401/models";
import { WhatsAppWebhookEntry } from "../../events/facebook/webhook/v12_0/whatsapp/models";
import { ZendeskZisWebhookEvent } from "../../events/zendesk/v2/models";
import { Notification } from "../../events/nubank/nupay/models";
import { SendEvent } from "../../events/facebook/graphapi/v10_0/messenger/models";
import { SendMessage } from "../../events/twilio/message/v20100401/models";
import { WhatsAppMessageRequest } from "../../events/facebook/graphapi/v12_0/whatsapp/models";
import { InstallIntegrationsInput, MigrateIntegrationsInput, UninstallIntegrationsInput, } from "../integration/dto/models";
import { StringValue, BoolValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "models.room";
export var ConnectlyEventSkip;
(function (ConnectlyEventSkip) {
    ConnectlyEventSkip[ConnectlyEventSkip["CONNECTLY_EVENT_SKIP_UNSPECIFIED"] = 0] = "CONNECTLY_EVENT_SKIP_UNSPECIFIED";
    ConnectlyEventSkip[ConnectlyEventSkip["CONNECTLY_EVENT_SKIP_DELIVERY"] = 1] = "CONNECTLY_EVENT_SKIP_DELIVERY";
    ConnectlyEventSkip[ConnectlyEventSkip["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ConnectlyEventSkip || (ConnectlyEventSkip = {}));
export function connectlyEventSkipFromJSON(object) {
    switch (object) {
        case 0:
        case "CONNECTLY_EVENT_SKIP_UNSPECIFIED":
            return ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_UNSPECIFIED;
        case 1:
        case "CONNECTLY_EVENT_SKIP_DELIVERY":
            return ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_DELIVERY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ConnectlyEventSkip.UNRECOGNIZED;
    }
}
export function connectlyEventSkipToJSON(object) {
    switch (object) {
        case ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_UNSPECIFIED:
            return "CONNECTLY_EVENT_SKIP_UNSPECIFIED";
        case ConnectlyEventSkip.CONNECTLY_EVENT_SKIP_DELIVERY:
            return "CONNECTLY_EVENT_SKIP_DELIVERY";
        case ConnectlyEventSkip.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var EventProcessingOrderMetadata_ProcessingStrategy;
(function (EventProcessingOrderMetadata_ProcessingStrategy) {
    EventProcessingOrderMetadata_ProcessingStrategy[EventProcessingOrderMetadata_ProcessingStrategy["PROCESSING_STRATEGY_UNSPECIFIED"] = 0] = "PROCESSING_STRATEGY_UNSPECIFIED";
    EventProcessingOrderMetadata_ProcessingStrategy[EventProcessingOrderMetadata_ProcessingStrategy["PROCESSING_STRATEGY_INDEPENDENT"] = 1] = "PROCESSING_STRATEGY_INDEPENDENT";
    EventProcessingOrderMetadata_ProcessingStrategy[EventProcessingOrderMetadata_ProcessingStrategy["PROCESSING_STRATEGY_SHORT_CIRCUIT"] = 2] = "PROCESSING_STRATEGY_SHORT_CIRCUIT";
    EventProcessingOrderMetadata_ProcessingStrategy[EventProcessingOrderMetadata_ProcessingStrategy["PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING"] = 99] = "PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING";
    EventProcessingOrderMetadata_ProcessingStrategy[EventProcessingOrderMetadata_ProcessingStrategy["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(EventProcessingOrderMetadata_ProcessingStrategy || (EventProcessingOrderMetadata_ProcessingStrategy = {}));
export function eventProcessingOrderMetadata_ProcessingStrategyFromJSON(object) {
    switch (object) {
        case 0:
        case "PROCESSING_STRATEGY_UNSPECIFIED":
            return EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_UNSPECIFIED;
        case 1:
        case "PROCESSING_STRATEGY_INDEPENDENT":
            return EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_INDEPENDENT;
        case 2:
        case "PROCESSING_STRATEGY_SHORT_CIRCUIT":
            return EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_SHORT_CIRCUIT;
        case 99:
        case "PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING":
            return EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return EventProcessingOrderMetadata_ProcessingStrategy.UNRECOGNIZED;
    }
}
export function eventProcessingOrderMetadata_ProcessingStrategyToJSON(object) {
    switch (object) {
        case EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_UNSPECIFIED:
            return "PROCESSING_STRATEGY_UNSPECIFIED";
        case EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_INDEPENDENT:
            return "PROCESSING_STRATEGY_INDEPENDENT";
        case EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_SHORT_CIRCUIT:
            return "PROCESSING_STRATEGY_SHORT_CIRCUIT";
        case EventProcessingOrderMetadata_ProcessingStrategy.PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING:
            return "PROCESSING_STRATEGY_SHORT_TERM_SCHEDULING";
        case EventProcessingOrderMetadata_ProcessingStrategy.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseConnectlyEvent() {
    return {
        eventOneof: undefined,
        sender: undefined,
        recipient: undefined,
        eligibility: undefined,
        skipEvent: 0,
        autoresp: undefined,
        webhookTopic: 0,
        webhookIds: [],
    };
}
export const ConnectlyEvent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
        if (((_a = message.eventOneof) === null || _a === void 0 ? void 0 : _a.$case) === "message") {
            Message.encode(message.eventOneof.message, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) === "rewardForContact") {
            RewardForContact.encode(message.eventOneof.rewardForContact, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.eventOneof) === null || _c === void 0 ? void 0 : _c.$case) === "roomAssignment") {
            RoomAssignment.encode(message.eventOneof.roomAssignment, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.eventOneof) === null || _d === void 0 ? void 0 : _d.$case) === "shopifyCustomerProfileFetch") {
            ShopifyCustomerProfileFetch.encode(message.eventOneof.shopifyCustomerProfileFetch, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.eventOneof) === null || _e === void 0 ? void 0 : _e.$case) === "roomCustomerMembershipUpdate") {
            RoomCustomerMembershipUpdate.encode(message.eventOneof.roomCustomerMembershipUpdate, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.eventOneof) === null || _f === void 0 ? void 0 : _f.$case) === "syncFromSource") {
            SyncFromSource.encode(message.eventOneof.syncFromSource, writer.uint32(50).fork()).ldelim();
        }
        if (((_g = message.eventOneof) === null || _g === void 0 ? void 0 : _g.$case) === "roomStatusChange") {
            RoomStatusChange.encode(message.eventOneof.roomStatusChange, writer.uint32(58).fork()).ldelim();
        }
        if (((_h = message.eventOneof) === null || _h === void 0 ? void 0 : _h.$case) === "campaignBroadcastTrigger") {
            CampaignBroadcastTrigger.encode(message.eventOneof.campaignBroadcastTrigger, writer.uint32(66).fork()).ldelim();
        }
        if (((_j = message.eventOneof) === null || _j === void 0 ? void 0 : _j.$case) === "templatedMessage") {
            TemplatedMessage.encode(message.eventOneof.templatedMessage, writer.uint32(74).fork()).ldelim();
        }
        if (((_k = message.eventOneof) === null || _k === void 0 ? void 0 : _k.$case) === "buttonResponse") {
            ButtonResponse.encode(message.eventOneof.buttonResponse, writer.uint32(82).fork()).ldelim();
        }
        if (((_l = message.eventOneof) === null || _l === void 0 ? void 0 : _l.$case) === "eventDeliveryStatus") {
            EventDeliveryStatus.encode(message.eventOneof.eventDeliveryStatus, writer.uint32(90).fork()).ldelim();
        }
        if (((_m = message.eventOneof) === null || _m === void 0 ? void 0 : _m.$case) === "order") {
            Order1.encode(message.eventOneof.order, writer.uint32(98).fork()).ldelim();
        }
        if (((_o = message.eventOneof) === null || _o === void 0 ? void 0 : _o.$case) === "checkout") {
            Checkout.encode(message.eventOneof.checkout, writer.uint32(106).fork()).ldelim();
        }
        if (((_p = message.eventOneof) === null || _p === void 0 ? void 0 : _p.$case) === "ticketStatus") {
            Status.encode(message.eventOneof.ticketStatus, writer.uint32(114).fork()).ldelim();
        }
        if (((_q = message.eventOneof) === null || _q === void 0 ? void 0 : _q.$case) === "ticketComment") {
            Comment.encode(message.eventOneof.ticketComment, writer.uint32(122).fork()).ldelim();
        }
        if (((_r = message.eventOneof) === null || _r === void 0 ? void 0 : _r.$case) === "paymentNotification") {
            PaymentNotification.encode(message.eventOneof.paymentNotification, writer.uint32(130).fork()).ldelim();
        }
        if (((_s = message.eventOneof) === null || _s === void 0 ? void 0 : _s.$case) === "importContacts") {
            ImportContacts.encode(message.eventOneof.importContacts, writer.uint32(794).fork()).ldelim();
        }
        if (((_t = message.eventOneof) === null || _t === void 0 ? void 0 : _t.$case) === "runMarketingCampaign") {
            RunMarketingCampaign.encode(message.eventOneof.runMarketingCampaign, writer.uint32(802).fork()).ldelim();
        }
        if (((_u = message.eventOneof) === null || _u === void 0 ? void 0 : _u.$case) === "dtoUpdateExperiment") {
            DtoUpdateExperiment.encode(message.eventOneof.dtoUpdateExperiment, writer.uint32(810).fork()).ldelim();
        }
        if (((_v = message.eventOneof) === null || _v === void 0 ? void 0 : _v.$case) === "importRooms") {
            ImportRooms.encode(message.eventOneof.importRooms, writer.uint32(818).fork()).ldelim();
        }
        if (((_w = message.eventOneof) === null || _w === void 0 ? void 0 : _w.$case) === "apiResult") {
            APIResult.encode(message.eventOneof.apiResult, writer.uint32(826).fork()).ldelim();
        }
        if (((_x = message.eventOneof) === null || _x === void 0 ? void 0 : _x.$case) === "etaFlowTrigger") {
            FlowTrigger.encode(message.eventOneof.etaFlowTrigger, writer.uint32(834).fork()).ldelim();
        }
        if (((_y = message.eventOneof) === null || _y === void 0 ? void 0 : _y.$case) === "etaPlaceholder") {
            Placeholder.encode(message.eventOneof.etaPlaceholder, writer.uint32(842).fork()).ldelim();
        }
        if (((_z = message.eventOneof) === null || _z === void 0 ? void 0 : _z.$case) === "etaRecommenderCampaignCommand") {
            RecommenderCampaignCommand.encode(message.eventOneof.etaRecommenderCampaignCommand, writer.uint32(874).fork()).ldelim();
        }
        if (((_0 = message.eventOneof) === null || _0 === void 0 ? void 0 : _0.$case) === "etaTimeout") {
            Timeout.encode(message.eventOneof.etaTimeout, writer.uint32(866).fork()).ldelim();
        }
        if (((_1 = message.eventOneof) === null || _1 === void 0 ? void 0 : _1.$case) === "shopifyProductFetch") {
            ShopifyProductFetch.encode(message.eventOneof.shopifyProductFetch, writer.uint32(850).fork()).ldelim();
        }
        if (((_2 = message.eventOneof) === null || _2 === void 0 ? void 0 : _2.$case) === "shopifyOrderFetch") {
            ShopifyOrderFetch.encode(message.eventOneof.shopifyOrderFetch, writer.uint32(858).fork()).ldelim();
        }
        if (((_3 = message.eventOneof) === null || _3 === void 0 ? void 0 : _3.$case) === "shopifyOrderIngest") {
            ShopifyOrderIngest.encode(message.eventOneof.shopifyOrderIngest, writer.uint32(882).fork()).ldelim();
        }
        if (((_4 = message.eventOneof) === null || _4 === void 0 ? void 0 : _4.$case) === "shopifyProductIngest") {
            ShopifyProductIngest.encode(message.eventOneof.shopifyProductIngest, writer.uint32(890).fork()).ldelim();
        }
        if (((_5 = message.eventOneof) === null || _5 === void 0 ? void 0 : _5.$case) === "shopifyProductMarkForDeletion") {
            ShopifyProductMarkForDeletion.encode(message.eventOneof.shopifyProductMarkForDeletion, writer.uint32(898).fork()).ldelim();
        }
        if (((_6 = message.eventOneof) === null || _6 === void 0 ? void 0 : _6.$case) === "appUninstallCleanup") {
            AppUninstallCleanup.encode(message.eventOneof.appUninstallCleanup, writer.uint32(906).fork()).ldelim();
        }
        if (((_7 = message.eventOneof) === null || _7 === void 0 ? void 0 : _7.$case) === "integrationsWorkflow") {
            IntegrationsWorkflow.encode(message.eventOneof.integrationsWorkflow, writer.uint32(914).fork()).ldelim();
        }
        if (message.sender !== undefined) {
            Sender.encode(message.sender, writer.uint32(138).fork()).ldelim();
        }
        if (message.recipient !== undefined) {
            Recipient.encode(message.recipient, writer.uint32(146).fork()).ldelim();
        }
        if (message.eligibility !== undefined) {
            Eligibility.encode(message.eligibility, writer.uint32(154).fork()).ldelim();
        }
        if (message.skipEvent !== 0) {
            writer.uint32(160).int32(message.skipEvent);
        }
        if (message.autoresp !== undefined) {
            AutoResponseMessageDesc.encode(message.autoresp, writer.uint32(170).fork()).ldelim();
        }
        if (message.webhookTopic !== 0) {
            writer.uint32(176).int32(message.webhookTopic);
        }
        for (const v of message.webhookIds) {
            writer.uint32(186).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConnectlyEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventOneof = {
                        $case: "message",
                        message: Message.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.eventOneof = {
                        $case: "rewardForContact",
                        rewardForContact: RewardForContact.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.eventOneof = {
                        $case: "roomAssignment",
                        roomAssignment: RoomAssignment.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.eventOneof = {
                        $case: "shopifyCustomerProfileFetch",
                        shopifyCustomerProfileFetch: ShopifyCustomerProfileFetch.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.eventOneof = {
                        $case: "roomCustomerMembershipUpdate",
                        roomCustomerMembershipUpdate: RoomCustomerMembershipUpdate.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.eventOneof = {
                        $case: "syncFromSource",
                        syncFromSource: SyncFromSource.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.eventOneof = {
                        $case: "roomStatusChange",
                        roomStatusChange: RoomStatusChange.decode(reader, reader.uint32()),
                    };
                    break;
                case 8:
                    message.eventOneof = {
                        $case: "campaignBroadcastTrigger",
                        campaignBroadcastTrigger: CampaignBroadcastTrigger.decode(reader, reader.uint32()),
                    };
                    break;
                case 9:
                    message.eventOneof = {
                        $case: "templatedMessage",
                        templatedMessage: TemplatedMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 10:
                    message.eventOneof = {
                        $case: "buttonResponse",
                        buttonResponse: ButtonResponse.decode(reader, reader.uint32()),
                    };
                    break;
                case 11:
                    message.eventOneof = {
                        $case: "eventDeliveryStatus",
                        eventDeliveryStatus: EventDeliveryStatus.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.eventOneof = {
                        $case: "order",
                        order: Order1.decode(reader, reader.uint32()),
                    };
                    break;
                case 13:
                    message.eventOneof = {
                        $case: "checkout",
                        checkout: Checkout.decode(reader, reader.uint32()),
                    };
                    break;
                case 14:
                    message.eventOneof = {
                        $case: "ticketStatus",
                        ticketStatus: Status.decode(reader, reader.uint32()),
                    };
                    break;
                case 15:
                    message.eventOneof = {
                        $case: "ticketComment",
                        ticketComment: Comment.decode(reader, reader.uint32()),
                    };
                    break;
                case 16:
                    message.eventOneof = {
                        $case: "paymentNotification",
                        paymentNotification: PaymentNotification.decode(reader, reader.uint32()),
                    };
                    break;
                case 99:
                    message.eventOneof = {
                        $case: "importContacts",
                        importContacts: ImportContacts.decode(reader, reader.uint32()),
                    };
                    break;
                case 100:
                    message.eventOneof = {
                        $case: "runMarketingCampaign",
                        runMarketingCampaign: RunMarketingCampaign.decode(reader, reader.uint32()),
                    };
                    break;
                case 101:
                    message.eventOneof = {
                        $case: "dtoUpdateExperiment",
                        dtoUpdateExperiment: DtoUpdateExperiment.decode(reader, reader.uint32()),
                    };
                    break;
                case 102:
                    message.eventOneof = {
                        $case: "importRooms",
                        importRooms: ImportRooms.decode(reader, reader.uint32()),
                    };
                    break;
                case 103:
                    message.eventOneof = {
                        $case: "apiResult",
                        apiResult: APIResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 104:
                    message.eventOneof = {
                        $case: "etaFlowTrigger",
                        etaFlowTrigger: FlowTrigger.decode(reader, reader.uint32()),
                    };
                    break;
                case 105:
                    message.eventOneof = {
                        $case: "etaPlaceholder",
                        etaPlaceholder: Placeholder.decode(reader, reader.uint32()),
                    };
                    break;
                case 109:
                    message.eventOneof = {
                        $case: "etaRecommenderCampaignCommand",
                        etaRecommenderCampaignCommand: RecommenderCampaignCommand.decode(reader, reader.uint32()),
                    };
                    break;
                case 108:
                    message.eventOneof = {
                        $case: "etaTimeout",
                        etaTimeout: Timeout.decode(reader, reader.uint32()),
                    };
                    break;
                case 106:
                    message.eventOneof = {
                        $case: "shopifyProductFetch",
                        shopifyProductFetch: ShopifyProductFetch.decode(reader, reader.uint32()),
                    };
                    break;
                case 107:
                    message.eventOneof = {
                        $case: "shopifyOrderFetch",
                        shopifyOrderFetch: ShopifyOrderFetch.decode(reader, reader.uint32()),
                    };
                    break;
                case 110:
                    message.eventOneof = {
                        $case: "shopifyOrderIngest",
                        shopifyOrderIngest: ShopifyOrderIngest.decode(reader, reader.uint32()),
                    };
                    break;
                case 111:
                    message.eventOneof = {
                        $case: "shopifyProductIngest",
                        shopifyProductIngest: ShopifyProductIngest.decode(reader, reader.uint32()),
                    };
                    break;
                case 112:
                    message.eventOneof = {
                        $case: "shopifyProductMarkForDeletion",
                        shopifyProductMarkForDeletion: ShopifyProductMarkForDeletion.decode(reader, reader.uint32()),
                    };
                    break;
                case 113:
                    message.eventOneof = {
                        $case: "appUninstallCleanup",
                        appUninstallCleanup: AppUninstallCleanup.decode(reader, reader.uint32()),
                    };
                    break;
                case 114:
                    message.eventOneof = {
                        $case: "integrationsWorkflow",
                        integrationsWorkflow: IntegrationsWorkflow.decode(reader, reader.uint32()),
                    };
                    break;
                case 17:
                    message.sender = Sender.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.recipient = Recipient.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.eligibility = Eligibility.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.skipEvent = reader.int32();
                    break;
                case 21:
                    message.autoresp = AutoResponseMessageDesc.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.webhookTopic = reader.int32();
                    break;
                case 23:
                    message.webhookIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventOneof: isSet(object.message)
                ? { $case: "message", message: Message.fromJSON(object.message) }
                : isSet(object.rewardForContact)
                    ? {
                        $case: "rewardForContact",
                        rewardForContact: RewardForContact.fromJSON(object.rewardForContact),
                    }
                    : isSet(object.roomAssignment)
                        ? {
                            $case: "roomAssignment",
                            roomAssignment: RoomAssignment.fromJSON(object.roomAssignment),
                        }
                        : isSet(object.shopifyCustomerProfileFetch)
                            ? {
                                $case: "shopifyCustomerProfileFetch",
                                shopifyCustomerProfileFetch: ShopifyCustomerProfileFetch.fromJSON(object.shopifyCustomerProfileFetch),
                            }
                            : isSet(object.roomCustomerMembershipUpdate)
                                ? {
                                    $case: "roomCustomerMembershipUpdate",
                                    roomCustomerMembershipUpdate: RoomCustomerMembershipUpdate.fromJSON(object.roomCustomerMembershipUpdate),
                                }
                                : isSet(object.syncFromSource)
                                    ? {
                                        $case: "syncFromSource",
                                        syncFromSource: SyncFromSource.fromJSON(object.syncFromSource),
                                    }
                                    : isSet(object.roomStatusChange)
                                        ? {
                                            $case: "roomStatusChange",
                                            roomStatusChange: RoomStatusChange.fromJSON(object.roomStatusChange),
                                        }
                                        : isSet(object.campaignBroadcastTrigger)
                                            ? {
                                                $case: "campaignBroadcastTrigger",
                                                campaignBroadcastTrigger: CampaignBroadcastTrigger.fromJSON(object.campaignBroadcastTrigger),
                                            }
                                            : isSet(object.templatedMessage)
                                                ? {
                                                    $case: "templatedMessage",
                                                    templatedMessage: TemplatedMessage.fromJSON(object.templatedMessage),
                                                }
                                                : isSet(object.buttonResponse)
                                                    ? {
                                                        $case: "buttonResponse",
                                                        buttonResponse: ButtonResponse.fromJSON(object.buttonResponse),
                                                    }
                                                    : isSet(object.eventDeliveryStatus)
                                                        ? {
                                                            $case: "eventDeliveryStatus",
                                                            eventDeliveryStatus: EventDeliveryStatus.fromJSON(object.eventDeliveryStatus),
                                                        }
                                                        : isSet(object.order)
                                                            ? { $case: "order", order: Order1.fromJSON(object.order) }
                                                            : isSet(object.checkout)
                                                                ? { $case: "checkout", checkout: Checkout.fromJSON(object.checkout) }
                                                                : isSet(object.ticketStatus)
                                                                    ? {
                                                                        $case: "ticketStatus",
                                                                        ticketStatus: Status.fromJSON(object.ticketStatus),
                                                                    }
                                                                    : isSet(object.ticketComment)
                                                                        ? {
                                                                            $case: "ticketComment",
                                                                            ticketComment: Comment.fromJSON(object.ticketComment),
                                                                        }
                                                                        : isSet(object.paymentNotification)
                                                                            ? {
                                                                                $case: "paymentNotification",
                                                                                paymentNotification: PaymentNotification.fromJSON(object.paymentNotification),
                                                                            }
                                                                            : isSet(object.importContacts)
                                                                                ? {
                                                                                    $case: "importContacts",
                                                                                    importContacts: ImportContacts.fromJSON(object.importContacts),
                                                                                }
                                                                                : isSet(object.runMarketingCampaign)
                                                                                    ? {
                                                                                        $case: "runMarketingCampaign",
                                                                                        runMarketingCampaign: RunMarketingCampaign.fromJSON(object.runMarketingCampaign),
                                                                                    }
                                                                                    : isSet(object.dtoUpdateExperiment)
                                                                                        ? {
                                                                                            $case: "dtoUpdateExperiment",
                                                                                            dtoUpdateExperiment: DtoUpdateExperiment.fromJSON(object.dtoUpdateExperiment),
                                                                                        }
                                                                                        : isSet(object.importRooms)
                                                                                            ? {
                                                                                                $case: "importRooms",
                                                                                                importRooms: ImportRooms.fromJSON(object.importRooms),
                                                                                            }
                                                                                            : isSet(object.apiResult)
                                                                                                ? {
                                                                                                    $case: "apiResult",
                                                                                                    apiResult: APIResult.fromJSON(object.apiResult),
                                                                                                }
                                                                                                : isSet(object.etaFlowTrigger)
                                                                                                    ? {
                                                                                                        $case: "etaFlowTrigger",
                                                                                                        etaFlowTrigger: FlowTrigger.fromJSON(object.etaFlowTrigger),
                                                                                                    }
                                                                                                    : isSet(object.etaPlaceholder)
                                                                                                        ? {
                                                                                                            $case: "etaPlaceholder",
                                                                                                            etaPlaceholder: Placeholder.fromJSON(object.etaPlaceholder),
                                                                                                        }
                                                                                                        : isSet(object.etaRecommenderCampaignCommand)
                                                                                                            ? {
                                                                                                                $case: "etaRecommenderCampaignCommand",
                                                                                                                etaRecommenderCampaignCommand: RecommenderCampaignCommand.fromJSON(object.etaRecommenderCampaignCommand),
                                                                                                            }
                                                                                                            : isSet(object.etaTimeout)
                                                                                                                ? {
                                                                                                                    $case: "etaTimeout",
                                                                                                                    etaTimeout: Timeout.fromJSON(object.etaTimeout),
                                                                                                                }
                                                                                                                : isSet(object.shopifyProductFetch)
                                                                                                                    ? {
                                                                                                                        $case: "shopifyProductFetch",
                                                                                                                        shopifyProductFetch: ShopifyProductFetch.fromJSON(object.shopifyProductFetch),
                                                                                                                    }
                                                                                                                    : isSet(object.shopifyOrderFetch)
                                                                                                                        ? {
                                                                                                                            $case: "shopifyOrderFetch",
                                                                                                                            shopifyOrderFetch: ShopifyOrderFetch.fromJSON(object.shopifyOrderFetch),
                                                                                                                        }
                                                                                                                        : isSet(object.shopifyOrderIngest)
                                                                                                                            ? {
                                                                                                                                $case: "shopifyOrderIngest",
                                                                                                                                shopifyOrderIngest: ShopifyOrderIngest.fromJSON(object.shopifyOrderIngest),
                                                                                                                            }
                                                                                                                            : isSet(object.shopifyProductIngest)
                                                                                                                                ? {
                                                                                                                                    $case: "shopifyProductIngest",
                                                                                                                                    shopifyProductIngest: ShopifyProductIngest.fromJSON(object.shopifyProductIngest),
                                                                                                                                }
                                                                                                                                : isSet(object.shopifyProductMarkForDeletion)
                                                                                                                                    ? {
                                                                                                                                        $case: "shopifyProductMarkForDeletion",
                                                                                                                                        shopifyProductMarkForDeletion: ShopifyProductMarkForDeletion.fromJSON(object.shopifyProductMarkForDeletion),
                                                                                                                                    }
                                                                                                                                    : isSet(object.appUninstallCleanup)
                                                                                                                                        ? {
                                                                                                                                            $case: "appUninstallCleanup",
                                                                                                                                            appUninstallCleanup: AppUninstallCleanup.fromJSON(object.appUninstallCleanup),
                                                                                                                                        }
                                                                                                                                        : isSet(object.integrationsWorkflow)
                                                                                                                                            ? {
                                                                                                                                                $case: "integrationsWorkflow",
                                                                                                                                                integrationsWorkflow: IntegrationsWorkflow.fromJSON(object.integrationsWorkflow),
                                                                                                                                            }
                                                                                                                                            : undefined,
            sender: isSet(object.sender) ? Sender.fromJSON(object.sender) : undefined,
            recipient: isSet(object.recipient)
                ? Recipient.fromJSON(object.recipient)
                : undefined,
            eligibility: isSet(object.eligibility)
                ? Eligibility.fromJSON(object.eligibility)
                : undefined,
            skipEvent: isSet(object.skipEvent)
                ? connectlyEventSkipFromJSON(object.skipEvent)
                : 0,
            autoresp: isSet(object.autoresp)
                ? AutoResponseMessageDesc.fromJSON(object.autoresp)
                : undefined,
            webhookTopic: isSet(object.webhookTopic)
                ? webhookTopicFromJSON(object.webhookTopic)
                : 0,
            webhookIds: Array.isArray(object === null || object === void 0 ? void 0 : object.webhookIds)
                ? object.webhookIds.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71;
        const obj = {};
        ((_a = message.eventOneof) === null || _a === void 0 ? void 0 : _a.$case) === "message" &&
            (obj.message = ((_b = message.eventOneof) === null || _b === void 0 ? void 0 : _b.message)
                ? Message.toJSON((_c = message.eventOneof) === null || _c === void 0 ? void 0 : _c.message)
                : undefined);
        ((_d = message.eventOneof) === null || _d === void 0 ? void 0 : _d.$case) === "rewardForContact" &&
            (obj.rewardForContact = ((_e = message.eventOneof) === null || _e === void 0 ? void 0 : _e.rewardForContact)
                ? RewardForContact.toJSON((_f = message.eventOneof) === null || _f === void 0 ? void 0 : _f.rewardForContact)
                : undefined);
        ((_g = message.eventOneof) === null || _g === void 0 ? void 0 : _g.$case) === "roomAssignment" &&
            (obj.roomAssignment = ((_h = message.eventOneof) === null || _h === void 0 ? void 0 : _h.roomAssignment)
                ? RoomAssignment.toJSON((_j = message.eventOneof) === null || _j === void 0 ? void 0 : _j.roomAssignment)
                : undefined);
        ((_k = message.eventOneof) === null || _k === void 0 ? void 0 : _k.$case) === "shopifyCustomerProfileFetch" &&
            (obj.shopifyCustomerProfileFetch = ((_l = message.eventOneof) === null || _l === void 0 ? void 0 : _l.shopifyCustomerProfileFetch)
                ? ShopifyCustomerProfileFetch.toJSON((_m = message.eventOneof) === null || _m === void 0 ? void 0 : _m.shopifyCustomerProfileFetch)
                : undefined);
        ((_o = message.eventOneof) === null || _o === void 0 ? void 0 : _o.$case) === "roomCustomerMembershipUpdate" &&
            (obj.roomCustomerMembershipUpdate = ((_p = message.eventOneof) === null || _p === void 0 ? void 0 : _p.roomCustomerMembershipUpdate)
                ? RoomCustomerMembershipUpdate.toJSON((_q = message.eventOneof) === null || _q === void 0 ? void 0 : _q.roomCustomerMembershipUpdate)
                : undefined);
        ((_r = message.eventOneof) === null || _r === void 0 ? void 0 : _r.$case) === "syncFromSource" &&
            (obj.syncFromSource = ((_s = message.eventOneof) === null || _s === void 0 ? void 0 : _s.syncFromSource)
                ? SyncFromSource.toJSON((_t = message.eventOneof) === null || _t === void 0 ? void 0 : _t.syncFromSource)
                : undefined);
        ((_u = message.eventOneof) === null || _u === void 0 ? void 0 : _u.$case) === "roomStatusChange" &&
            (obj.roomStatusChange = ((_v = message.eventOneof) === null || _v === void 0 ? void 0 : _v.roomStatusChange)
                ? RoomStatusChange.toJSON((_w = message.eventOneof) === null || _w === void 0 ? void 0 : _w.roomStatusChange)
                : undefined);
        ((_x = message.eventOneof) === null || _x === void 0 ? void 0 : _x.$case) === "campaignBroadcastTrigger" &&
            (obj.campaignBroadcastTrigger = ((_y = message.eventOneof) === null || _y === void 0 ? void 0 : _y.campaignBroadcastTrigger)
                ? CampaignBroadcastTrigger.toJSON((_z = message.eventOneof) === null || _z === void 0 ? void 0 : _z.campaignBroadcastTrigger)
                : undefined);
        ((_0 = message.eventOneof) === null || _0 === void 0 ? void 0 : _0.$case) === "templatedMessage" &&
            (obj.templatedMessage = ((_1 = message.eventOneof) === null || _1 === void 0 ? void 0 : _1.templatedMessage)
                ? TemplatedMessage.toJSON((_2 = message.eventOneof) === null || _2 === void 0 ? void 0 : _2.templatedMessage)
                : undefined);
        ((_3 = message.eventOneof) === null || _3 === void 0 ? void 0 : _3.$case) === "buttonResponse" &&
            (obj.buttonResponse = ((_4 = message.eventOneof) === null || _4 === void 0 ? void 0 : _4.buttonResponse)
                ? ButtonResponse.toJSON((_5 = message.eventOneof) === null || _5 === void 0 ? void 0 : _5.buttonResponse)
                : undefined);
        ((_6 = message.eventOneof) === null || _6 === void 0 ? void 0 : _6.$case) === "eventDeliveryStatus" &&
            (obj.eventDeliveryStatus = ((_7 = message.eventOneof) === null || _7 === void 0 ? void 0 : _7.eventDeliveryStatus)
                ? EventDeliveryStatus.toJSON((_8 = message.eventOneof) === null || _8 === void 0 ? void 0 : _8.eventDeliveryStatus)
                : undefined);
        ((_9 = message.eventOneof) === null || _9 === void 0 ? void 0 : _9.$case) === "order" &&
            (obj.order = ((_10 = message.eventOneof) === null || _10 === void 0 ? void 0 : _10.order)
                ? Order1.toJSON((_11 = message.eventOneof) === null || _11 === void 0 ? void 0 : _11.order)
                : undefined);
        ((_12 = message.eventOneof) === null || _12 === void 0 ? void 0 : _12.$case) === "checkout" &&
            (obj.checkout = ((_13 = message.eventOneof) === null || _13 === void 0 ? void 0 : _13.checkout)
                ? Checkout.toJSON((_14 = message.eventOneof) === null || _14 === void 0 ? void 0 : _14.checkout)
                : undefined);
        ((_15 = message.eventOneof) === null || _15 === void 0 ? void 0 : _15.$case) === "ticketStatus" &&
            (obj.ticketStatus = ((_16 = message.eventOneof) === null || _16 === void 0 ? void 0 : _16.ticketStatus)
                ? Status.toJSON((_17 = message.eventOneof) === null || _17 === void 0 ? void 0 : _17.ticketStatus)
                : undefined);
        ((_18 = message.eventOneof) === null || _18 === void 0 ? void 0 : _18.$case) === "ticketComment" &&
            (obj.ticketComment = ((_19 = message.eventOneof) === null || _19 === void 0 ? void 0 : _19.ticketComment)
                ? Comment.toJSON((_20 = message.eventOneof) === null || _20 === void 0 ? void 0 : _20.ticketComment)
                : undefined);
        ((_21 = message.eventOneof) === null || _21 === void 0 ? void 0 : _21.$case) === "paymentNotification" &&
            (obj.paymentNotification = ((_22 = message.eventOneof) === null || _22 === void 0 ? void 0 : _22.paymentNotification)
                ? PaymentNotification.toJSON((_23 = message.eventOneof) === null || _23 === void 0 ? void 0 : _23.paymentNotification)
                : undefined);
        ((_24 = message.eventOneof) === null || _24 === void 0 ? void 0 : _24.$case) === "importContacts" &&
            (obj.importContacts = ((_25 = message.eventOneof) === null || _25 === void 0 ? void 0 : _25.importContacts)
                ? ImportContacts.toJSON((_26 = message.eventOneof) === null || _26 === void 0 ? void 0 : _26.importContacts)
                : undefined);
        ((_27 = message.eventOneof) === null || _27 === void 0 ? void 0 : _27.$case) === "runMarketingCampaign" &&
            (obj.runMarketingCampaign = ((_28 = message.eventOneof) === null || _28 === void 0 ? void 0 : _28.runMarketingCampaign)
                ? RunMarketingCampaign.toJSON((_29 = message.eventOneof) === null || _29 === void 0 ? void 0 : _29.runMarketingCampaign)
                : undefined);
        ((_30 = message.eventOneof) === null || _30 === void 0 ? void 0 : _30.$case) === "dtoUpdateExperiment" &&
            (obj.dtoUpdateExperiment = ((_31 = message.eventOneof) === null || _31 === void 0 ? void 0 : _31.dtoUpdateExperiment)
                ? DtoUpdateExperiment.toJSON((_32 = message.eventOneof) === null || _32 === void 0 ? void 0 : _32.dtoUpdateExperiment)
                : undefined);
        ((_33 = message.eventOneof) === null || _33 === void 0 ? void 0 : _33.$case) === "importRooms" &&
            (obj.importRooms = ((_34 = message.eventOneof) === null || _34 === void 0 ? void 0 : _34.importRooms)
                ? ImportRooms.toJSON((_35 = message.eventOneof) === null || _35 === void 0 ? void 0 : _35.importRooms)
                : undefined);
        ((_36 = message.eventOneof) === null || _36 === void 0 ? void 0 : _36.$case) === "apiResult" &&
            (obj.apiResult = ((_37 = message.eventOneof) === null || _37 === void 0 ? void 0 : _37.apiResult)
                ? APIResult.toJSON((_38 = message.eventOneof) === null || _38 === void 0 ? void 0 : _38.apiResult)
                : undefined);
        ((_39 = message.eventOneof) === null || _39 === void 0 ? void 0 : _39.$case) === "etaFlowTrigger" &&
            (obj.etaFlowTrigger = ((_40 = message.eventOneof) === null || _40 === void 0 ? void 0 : _40.etaFlowTrigger)
                ? FlowTrigger.toJSON((_41 = message.eventOneof) === null || _41 === void 0 ? void 0 : _41.etaFlowTrigger)
                : undefined);
        ((_42 = message.eventOneof) === null || _42 === void 0 ? void 0 : _42.$case) === "etaPlaceholder" &&
            (obj.etaPlaceholder = ((_43 = message.eventOneof) === null || _43 === void 0 ? void 0 : _43.etaPlaceholder)
                ? Placeholder.toJSON((_44 = message.eventOneof) === null || _44 === void 0 ? void 0 : _44.etaPlaceholder)
                : undefined);
        ((_45 = message.eventOneof) === null || _45 === void 0 ? void 0 : _45.$case) === "etaRecommenderCampaignCommand" &&
            (obj.etaRecommenderCampaignCommand = ((_46 = message.eventOneof) === null || _46 === void 0 ? void 0 : _46.etaRecommenderCampaignCommand)
                ? RecommenderCampaignCommand.toJSON((_47 = message.eventOneof) === null || _47 === void 0 ? void 0 : _47.etaRecommenderCampaignCommand)
                : undefined);
        ((_48 = message.eventOneof) === null || _48 === void 0 ? void 0 : _48.$case) === "etaTimeout" &&
            (obj.etaTimeout = ((_49 = message.eventOneof) === null || _49 === void 0 ? void 0 : _49.etaTimeout)
                ? Timeout.toJSON((_50 = message.eventOneof) === null || _50 === void 0 ? void 0 : _50.etaTimeout)
                : undefined);
        ((_51 = message.eventOneof) === null || _51 === void 0 ? void 0 : _51.$case) === "shopifyProductFetch" &&
            (obj.shopifyProductFetch = ((_52 = message.eventOneof) === null || _52 === void 0 ? void 0 : _52.shopifyProductFetch)
                ? ShopifyProductFetch.toJSON((_53 = message.eventOneof) === null || _53 === void 0 ? void 0 : _53.shopifyProductFetch)
                : undefined);
        ((_54 = message.eventOneof) === null || _54 === void 0 ? void 0 : _54.$case) === "shopifyOrderFetch" &&
            (obj.shopifyOrderFetch = ((_55 = message.eventOneof) === null || _55 === void 0 ? void 0 : _55.shopifyOrderFetch)
                ? ShopifyOrderFetch.toJSON((_56 = message.eventOneof) === null || _56 === void 0 ? void 0 : _56.shopifyOrderFetch)
                : undefined);
        ((_57 = message.eventOneof) === null || _57 === void 0 ? void 0 : _57.$case) === "shopifyOrderIngest" &&
            (obj.shopifyOrderIngest = ((_58 = message.eventOneof) === null || _58 === void 0 ? void 0 : _58.shopifyOrderIngest)
                ? ShopifyOrderIngest.toJSON((_59 = message.eventOneof) === null || _59 === void 0 ? void 0 : _59.shopifyOrderIngest)
                : undefined);
        ((_60 = message.eventOneof) === null || _60 === void 0 ? void 0 : _60.$case) === "shopifyProductIngest" &&
            (obj.shopifyProductIngest = ((_61 = message.eventOneof) === null || _61 === void 0 ? void 0 : _61.shopifyProductIngest)
                ? ShopifyProductIngest.toJSON((_62 = message.eventOneof) === null || _62 === void 0 ? void 0 : _62.shopifyProductIngest)
                : undefined);
        ((_63 = message.eventOneof) === null || _63 === void 0 ? void 0 : _63.$case) === "shopifyProductMarkForDeletion" &&
            (obj.shopifyProductMarkForDeletion = ((_64 = message.eventOneof) === null || _64 === void 0 ? void 0 : _64.shopifyProductMarkForDeletion)
                ? ShopifyProductMarkForDeletion.toJSON((_65 = message.eventOneof) === null || _65 === void 0 ? void 0 : _65.shopifyProductMarkForDeletion)
                : undefined);
        ((_66 = message.eventOneof) === null || _66 === void 0 ? void 0 : _66.$case) === "appUninstallCleanup" &&
            (obj.appUninstallCleanup = ((_67 = message.eventOneof) === null || _67 === void 0 ? void 0 : _67.appUninstallCleanup)
                ? AppUninstallCleanup.toJSON((_68 = message.eventOneof) === null || _68 === void 0 ? void 0 : _68.appUninstallCleanup)
                : undefined);
        ((_69 = message.eventOneof) === null || _69 === void 0 ? void 0 : _69.$case) === "integrationsWorkflow" &&
            (obj.integrationsWorkflow = ((_70 = message.eventOneof) === null || _70 === void 0 ? void 0 : _70.integrationsWorkflow)
                ? IntegrationsWorkflow.toJSON((_71 = message.eventOneof) === null || _71 === void 0 ? void 0 : _71.integrationsWorkflow)
                : undefined);
        message.sender !== undefined &&
            (obj.sender = message.sender ? Sender.toJSON(message.sender) : undefined);
        message.recipient !== undefined &&
            (obj.recipient = message.recipient
                ? Recipient.toJSON(message.recipient)
                : undefined);
        message.eligibility !== undefined &&
            (obj.eligibility = message.eligibility
                ? Eligibility.toJSON(message.eligibility)
                : undefined);
        message.skipEvent !== undefined &&
            (obj.skipEvent = connectlyEventSkipToJSON(message.skipEvent));
        message.autoresp !== undefined &&
            (obj.autoresp = message.autoresp
                ? AutoResponseMessageDesc.toJSON(message.autoresp)
                : undefined);
        message.webhookTopic !== undefined &&
            (obj.webhookTopic = webhookTopicToJSON(message.webhookTopic));
        if (message.webhookIds) {
            obj.webhookIds = message.webhookIds.map((e) => e);
        }
        else {
            obj.webhookIds = [];
        }
        return obj;
    },
};
function createBaseEligibility() {
    return { type: 0, startTime: undefined, endTime: undefined };
}
export const Eligibility = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.startTime !== undefined) {
            Timestamp.encode(toTimestamp(message.startTime), writer.uint32(18).fork()).ldelim();
        }
        if (message.endTime !== undefined) {
            Timestamp.encode(toTimestamp(message.endTime), writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEligibility();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? eligibilityTypeFromJSON(object.type) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : undefined,
            endTime: isSet(object.endTime) ? String(object.endTime) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined &&
            (obj.type = eligibilityTypeToJSON(message.type));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
};
function createBaseEventDeliveryStatus() {
    return {
        roomId: "",
        eventId: "",
        externalEventId: "",
        timestamp: undefined,
        deliveryStatus: 0,
        error: undefined,
        platformError: undefined,
    };
}
export const EventDeliveryStatus = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roomId !== "") {
            writer.uint32(10).string(message.roomId);
        }
        if (message.eventId !== "") {
            writer.uint32(18).string(message.eventId);
        }
        if (message.externalEventId !== "") {
            writer.uint32(50).string(message.externalEventId);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(58).fork()).ldelim();
        }
        if (message.deliveryStatus !== 0) {
            writer.uint32(24).int32(message.deliveryStatus);
        }
        if (message.error !== undefined) {
            StringValue.encode({ value: message.error }, writer.uint32(34).fork()).ldelim();
        }
        if (message.platformError !== undefined) {
            PlatformError.encode(message.platformError, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventDeliveryStatus();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.eventId = reader.string();
                    break;
                case 6:
                    message.externalEventId = reader.string();
                    break;
                case 7:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.deliveryStatus = reader.int32();
                    break;
                case 4:
                    message.error = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.platformError = PlatformError.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            eventId: isSet(object.eventId) ? String(object.eventId) : "",
            externalEventId: isSet(object.externalEventId)
                ? String(object.externalEventId)
                : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            deliveryStatus: isSet(object.deliveryStatus)
                ? deliveryStatusFromJSON(object.deliveryStatus)
                : 0,
            error: isSet(object.error) ? String(object.error) : undefined,
            platformError: isSet(object.platformError)
                ? PlatformError.fromJSON(object.platformError)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.eventId !== undefined && (obj.eventId = message.eventId);
        message.externalEventId !== undefined &&
            (obj.externalEventId = message.externalEventId);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.deliveryStatus !== undefined &&
            (obj.deliveryStatus = deliveryStatusToJSON(message.deliveryStatus));
        message.error !== undefined && (obj.error = message.error);
        message.platformError !== undefined &&
            (obj.platformError = message.platformError
                ? PlatformError.toJSON(message.platformError)
                : undefined);
        return obj;
    },
};
function createBaseRecipient() {
    return { id: "" };
}
export const Recipient = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecipient();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseSender() {
    return { id: "" };
}
export const Sender = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSender();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseAutoResponseMessageDesc() {
    return { revisionId: "" };
}
export const AutoResponseMessageDesc = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.revisionId !== "") {
            writer.uint32(10).string(message.revisionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAutoResponseMessageDesc();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.revisionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            revisionId: isSet(object.revisionId) ? String(object.revisionId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.revisionId !== undefined && (obj.revisionId = message.revisionId);
        return obj;
    },
};
function createBaseMessage() {
    return {
        text: "",
        attachments: [],
        isButtonResponse: false,
        location: undefined,
        interactive: undefined,
        referral: undefined,
        isOtp: undefined,
    };
}
export const Message = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        for (const v of message.attachments) {
            Attachment.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.isButtonResponse === true) {
            writer.uint32(24).bool(message.isButtonResponse);
        }
        if (message.location !== undefined) {
            Location.encode(message.location, writer.uint32(34).fork()).ldelim();
        }
        if (message.interactive !== undefined) {
            Interactive.encode(message.interactive, writer.uint32(42).fork()).ldelim();
        }
        if (message.referral !== undefined) {
            Referral.encode(message.referral, writer.uint32(50).fork()).ldelim();
        }
        if (message.isOtp !== undefined) {
            BoolValue.encode({ value: message.isOtp }, writer.uint32(794).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.attachments.push(Attachment.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.isButtonResponse = reader.bool();
                    break;
                case 4:
                    message.location = Location.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.interactive = Interactive.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.referral = Referral.decode(reader, reader.uint32());
                    break;
                case 99:
                    message.isOtp = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            attachments: Array.isArray(object === null || object === void 0 ? void 0 : object.attachments)
                ? object.attachments.map((e) => Attachment.fromJSON(e))
                : [],
            isButtonResponse: isSet(object.isButtonResponse)
                ? Boolean(object.isButtonResponse)
                : false,
            location: isSet(object.location)
                ? Location.fromJSON(object.location)
                : undefined,
            interactive: isSet(object.interactive)
                ? Interactive.fromJSON(object.interactive)
                : undefined,
            referral: isSet(object.referral)
                ? Referral.fromJSON(object.referral)
                : undefined,
            isOtp: isSet(object.isOtp) ? Boolean(object.isOtp) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        if (message.attachments) {
            obj.attachments = message.attachments.map((e) => e ? Attachment.toJSON(e) : undefined);
        }
        else {
            obj.attachments = [];
        }
        message.isButtonResponse !== undefined &&
            (obj.isButtonResponse = message.isButtonResponse);
        message.location !== undefined &&
            (obj.location = message.location
                ? Location.toJSON(message.location)
                : undefined);
        message.interactive !== undefined &&
            (obj.interactive = message.interactive
                ? Interactive.toJSON(message.interactive)
                : undefined);
        message.referral !== undefined &&
            (obj.referral = message.referral
                ? Referral.toJSON(message.referral)
                : undefined);
        message.isOtp !== undefined && (obj.isOtp = message.isOtp);
        return obj;
    },
};
function createBaseReferral() {
    return {
        sourceUrl: undefined,
        sourceId: undefined,
        sourceType: undefined,
        headline: undefined,
        body: undefined,
        mediaType: undefined,
        imageUrl: undefined,
        videoUrl: undefined,
        thumbnailUrl: undefined,
    };
}
export const Referral = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sourceUrl !== undefined) {
            StringValue.encode({ value: message.sourceUrl }, writer.uint32(10).fork()).ldelim();
        }
        if (message.sourceId !== undefined) {
            StringValue.encode({ value: message.sourceId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.sourceType !== undefined) {
            StringValue.encode({ value: message.sourceType }, writer.uint32(26).fork()).ldelim();
        }
        if (message.headline !== undefined) {
            StringValue.encode({ value: message.headline }, writer.uint32(34).fork()).ldelim();
        }
        if (message.body !== undefined) {
            StringValue.encode({ value: message.body }, writer.uint32(42).fork()).ldelim();
        }
        if (message.mediaType !== undefined) {
            StringValue.encode({ value: message.mediaType }, writer.uint32(50).fork()).ldelim();
        }
        if (message.imageUrl !== undefined) {
            StringValue.encode({ value: message.imageUrl }, writer.uint32(58).fork()).ldelim();
        }
        if (message.videoUrl !== undefined) {
            StringValue.encode({ value: message.videoUrl }, writer.uint32(66).fork()).ldelim();
        }
        if (message.thumbnailUrl !== undefined) {
            StringValue.encode({ value: message.thumbnailUrl }, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReferral();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sourceUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.sourceId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.sourceType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.headline = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.body = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.mediaType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.imageUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.videoUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.thumbnailUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sourceUrl: isSet(object.sourceUrl) ? String(object.sourceUrl) : undefined,
            sourceId: isSet(object.sourceId) ? String(object.sourceId) : undefined,
            sourceType: isSet(object.sourceType)
                ? String(object.sourceType)
                : undefined,
            headline: isSet(object.headline) ? String(object.headline) : undefined,
            body: isSet(object.body) ? String(object.body) : undefined,
            mediaType: isSet(object.mediaType) ? String(object.mediaType) : undefined,
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : undefined,
            videoUrl: isSet(object.videoUrl) ? String(object.videoUrl) : undefined,
            thumbnailUrl: isSet(object.thumbnailUrl)
                ? String(object.thumbnailUrl)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sourceUrl !== undefined && (obj.sourceUrl = message.sourceUrl);
        message.sourceId !== undefined && (obj.sourceId = message.sourceId);
        message.sourceType !== undefined && (obj.sourceType = message.sourceType);
        message.headline !== undefined && (obj.headline = message.headline);
        message.body !== undefined && (obj.body = message.body);
        message.mediaType !== undefined && (obj.mediaType = message.mediaType);
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        message.videoUrl !== undefined && (obj.videoUrl = message.videoUrl);
        message.thumbnailUrl !== undefined &&
            (obj.thumbnailUrl = message.thumbnailUrl);
        return obj;
    },
};
function createBaseButtonResponse() {
    return { text: "", payload: "" };
}
export const ButtonResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.text !== "") {
            writer.uint32(10).string(message.text);
        }
        if (message.payload !== "") {
            writer.uint32(18).string(message.payload);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseButtonResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    message.payload = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : "",
            payload: isSet(object.payload) ? String(object.payload) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.payload !== undefined && (obj.payload = message.payload);
        return obj;
    },
};
function createBaseRewardForContact() {
    return { smsNumber: "", campaignId: "", campaignName: "" };
}
export const RewardForContact = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.smsNumber !== "") {
            writer.uint32(18).string(message.smsNumber);
        }
        if (message.campaignId !== "") {
            writer.uint32(26).string(message.campaignId);
        }
        if (message.campaignName !== "") {
            writer.uint32(10).string(message.campaignName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRewardForContact();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.smsNumber = reader.string();
                    break;
                case 3:
                    message.campaignId = reader.string();
                    break;
                case 1:
                    message.campaignName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            smsNumber: isSet(object.smsNumber) ? String(object.smsNumber) : "",
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            campaignName: isSet(object.campaignName)
                ? String(object.campaignName)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.smsNumber !== undefined && (obj.smsNumber = message.smsNumber);
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.campaignName !== undefined &&
            (obj.campaignName = message.campaignName);
        return obj;
    },
};
function createBaseImportContacts() {
    return { sourceOneof: undefined };
}
export const ImportContacts = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.sourceOneof) === null || _a === void 0 ? void 0 : _a.$case) === "csv") {
            writer.uint32(10).string(message.sourceOneof.csv);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseImportContacts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sourceOneof = { $case: "csv", csv: reader.string() };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sourceOneof: isSet(object.csv)
                ? { $case: "csv", csv: String(object.csv) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b;
        const obj = {};
        ((_a = message.sourceOneof) === null || _a === void 0 ? void 0 : _a.$case) === "csv" &&
            (obj.csv = (_b = message.sourceOneof) === null || _b === void 0 ? void 0 : _b.csv);
        return obj;
    },
};
function createBaseImportRooms() {
    return {
        zipPath: "",
        timezone: "",
        businessPhoneNumber: "",
        newBusinessPhoneNumber: "",
    };
}
export const ImportRooms = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.zipPath !== "") {
            writer.uint32(10).string(message.zipPath);
        }
        if (message.timezone !== "") {
            writer.uint32(18).string(message.timezone);
        }
        if (message.businessPhoneNumber !== "") {
            writer.uint32(26).string(message.businessPhoneNumber);
        }
        if (message.newBusinessPhoneNumber !== "") {
            writer.uint32(34).string(message.newBusinessPhoneNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseImportRooms();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.zipPath = reader.string();
                    break;
                case 2:
                    message.timezone = reader.string();
                    break;
                case 3:
                    message.businessPhoneNumber = reader.string();
                    break;
                case 4:
                    message.newBusinessPhoneNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            zipPath: isSet(object.zipPath) ? String(object.zipPath) : "",
            timezone: isSet(object.timezone) ? String(object.timezone) : "",
            businessPhoneNumber: isSet(object.businessPhoneNumber)
                ? String(object.businessPhoneNumber)
                : "",
            newBusinessPhoneNumber: isSet(object.newBusinessPhoneNumber)
                ? String(object.newBusinessPhoneNumber)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.zipPath !== undefined && (obj.zipPath = message.zipPath);
        message.timezone !== undefined && (obj.timezone = message.timezone);
        message.businessPhoneNumber !== undefined &&
            (obj.businessPhoneNumber = message.businessPhoneNumber);
        message.newBusinessPhoneNumber !== undefined &&
            (obj.newBusinessPhoneNumber = message.newBusinessPhoneNumber);
        return obj;
    },
};
function createBaseRunMarketingCampaign() {
    return { campaignName: "" };
}
export const RunMarketingCampaign = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignName !== "") {
            writer.uint32(10).string(message.campaignName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRunMarketingCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignName: isSet(object.campaignName)
                ? String(object.campaignName)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignName !== undefined &&
            (obj.campaignName = message.campaignName);
        return obj;
    },
};
function createBaseCampaignBroadcastTrigger() {
    return { campaignId: "", campaignName: "" };
}
export const CampaignBroadcastTrigger = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(18).string(message.campaignId);
        }
        if (message.campaignName !== "") {
            writer.uint32(10).string(message.campaignName);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignBroadcastTrigger();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.campaignId = reader.string();
                    break;
                case 1:
                    message.campaignName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            campaignName: isSet(object.campaignName)
                ? String(object.campaignName)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.campaignName !== undefined &&
            (obj.campaignName = message.campaignName);
        return obj;
    },
};
function createBaseDtoUpdateExperiment() {
    return {
        objectId: "",
        timestamp: undefined,
        clientId: "",
        resultOneof: undefined,
    };
}
export const DtoUpdateExperiment = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        if (message.objectId !== "") {
            writer.uint32(10).string(message.objectId);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).ldelim();
        }
        if (message.clientId !== "") {
            writer.uint32(26).string(message.clientId);
        }
        if (((_a = message.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) === "verifyCampaignResult") {
            VerifyCampaignResult.encode(message.resultOneof.verifyCampaignResult, writer.uint32(90).fork()).ldelim();
        }
        if (((_b = message.resultOneof) === null || _b === void 0 ? void 0 : _b.$case) === "submitCampaignResult") {
            SubmitCampaignResult.encode(message.resultOneof.submitCampaignResult, writer.uint32(98).fork()).ldelim();
        }
        if (((_c = message.resultOneof) === null || _c === void 0 ? void 0 : _c.$case) === "roomDto") {
            RoomDto.encode(message.resultOneof.roomDto, writer.uint32(106).fork()).ldelim();
        }
        if (((_d = message.resultOneof) === null || _d === void 0 ? void 0 : _d.$case) === "businessDto") {
            Business.encode(message.resultOneof.businessDto, writer.uint32(114).fork()).ldelim();
        }
        if (((_e = message.resultOneof) === null || _e === void 0 ? void 0 : _e.$case) === "verifySendoutResult") {
            VerifySendoutResult.encode(message.resultOneof.verifySendoutResult, writer.uint32(122).fork()).ldelim();
        }
        if (((_f = message.resultOneof) === null || _f === void 0 ? void 0 : _f.$case) === "submitSendoutResult") {
            SubmitSendoutResult.encode(message.resultOneof.submitSendoutResult, writer.uint32(130).fork()).ldelim();
        }
        if (((_g = message.resultOneof) === null || _g === void 0 ? void 0 : _g.$case) === "messageTemplateStatusUpdate") {
            MessageTemplateStatusUpdate.encode(message.resultOneof.messageTemplateStatusUpdate, writer.uint32(138).fork()).ldelim();
        }
        if (((_h = message.resultOneof) === null || _h === void 0 ? void 0 : _h.$case) === "reactFlowDocumentChange") {
            ReactFlowDocumentChangeDto.encode(message.resultOneof.reactFlowDocumentChange, writer.uint32(146).fork()).ldelim();
        }
        if (((_j = message.resultOneof) === null || _j === void 0 ? void 0 : _j.$case) === "submitReactFlowDocumentResult") {
            SubmitReactFlowDocumentResult.encode(message.resultOneof.submitReactFlowDocumentResult, writer.uint32(154).fork()).ldelim();
        }
        if (((_k = message.resultOneof) === null || _k === void 0 ? void 0 : _k.$case) === "businessChannelDto") {
            BusinessChannel.encode(message.resultOneof.businessChannelDto, writer.uint32(162).fork()).ldelim();
        }
        if (((_l = message.resultOneof) === null || _l === void 0 ? void 0 : _l.$case) === "verifyReactFlowDocumentResult") {
            VerifyReactFlowDocumentResult.encode(message.resultOneof.verifyReactFlowDocumentResult, writer.uint32(170).fork()).ldelim();
        }
        if (((_m = message.resultOneof) === null || _m === void 0 ? void 0 : _m.$case) === "compileReactFlowDocumentResult") {
            CompileReactFlowDocumentResult.encode(message.resultOneof.compileReactFlowDocumentResult, writer.uint32(178).fork()).ldelim();
        }
        if (((_o = message.resultOneof) === null || _o === void 0 ? void 0 : _o.$case) === "deployReactFlowDocumentResult") {
            DeployReactFlowDocumentResult.encode(message.resultOneof.deployReactFlowDocumentResult, writer.uint32(186).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDtoUpdateExperiment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.clientId = reader.string();
                    break;
                case 11:
                    message.resultOneof = {
                        $case: "verifyCampaignResult",
                        verifyCampaignResult: VerifyCampaignResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 12:
                    message.resultOneof = {
                        $case: "submitCampaignResult",
                        submitCampaignResult: SubmitCampaignResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 13:
                    message.resultOneof = {
                        $case: "roomDto",
                        roomDto: RoomDto.decode(reader, reader.uint32()),
                    };
                    break;
                case 14:
                    message.resultOneof = {
                        $case: "businessDto",
                        businessDto: Business.decode(reader, reader.uint32()),
                    };
                    break;
                case 15:
                    message.resultOneof = {
                        $case: "verifySendoutResult",
                        verifySendoutResult: VerifySendoutResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 16:
                    message.resultOneof = {
                        $case: "submitSendoutResult",
                        submitSendoutResult: SubmitSendoutResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 17:
                    message.resultOneof = {
                        $case: "messageTemplateStatusUpdate",
                        messageTemplateStatusUpdate: MessageTemplateStatusUpdate.decode(reader, reader.uint32()),
                    };
                    break;
                case 18:
                    message.resultOneof = {
                        $case: "reactFlowDocumentChange",
                        reactFlowDocumentChange: ReactFlowDocumentChangeDto.decode(reader, reader.uint32()),
                    };
                    break;
                case 19:
                    message.resultOneof = {
                        $case: "submitReactFlowDocumentResult",
                        submitReactFlowDocumentResult: SubmitReactFlowDocumentResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 20:
                    message.resultOneof = {
                        $case: "businessChannelDto",
                        businessChannelDto: BusinessChannel.decode(reader, reader.uint32()),
                    };
                    break;
                case 21:
                    message.resultOneof = {
                        $case: "verifyReactFlowDocumentResult",
                        verifyReactFlowDocumentResult: VerifyReactFlowDocumentResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 22:
                    message.resultOneof = {
                        $case: "compileReactFlowDocumentResult",
                        compileReactFlowDocumentResult: CompileReactFlowDocumentResult.decode(reader, reader.uint32()),
                    };
                    break;
                case 23:
                    message.resultOneof = {
                        $case: "deployReactFlowDocumentResult",
                        deployReactFlowDocumentResult: DeployReactFlowDocumentResult.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            objectId: isSet(object.objectId) ? String(object.objectId) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : undefined,
            clientId: isSet(object.clientId) ? String(object.clientId) : "",
            resultOneof: isSet(object.verifyCampaignResult)
                ? {
                    $case: "verifyCampaignResult",
                    verifyCampaignResult: VerifyCampaignResult.fromJSON(object.verifyCampaignResult),
                }
                : isSet(object.submitCampaignResult)
                    ? {
                        $case: "submitCampaignResult",
                        submitCampaignResult: SubmitCampaignResult.fromJSON(object.submitCampaignResult),
                    }
                    : isSet(object.roomDto)
                        ? { $case: "roomDto", roomDto: RoomDto.fromJSON(object.roomDto) }
                        : isSet(object.businessDto)
                            ? {
                                $case: "businessDto",
                                businessDto: Business.fromJSON(object.businessDto),
                            }
                            : isSet(object.verifySendoutResult)
                                ? {
                                    $case: "verifySendoutResult",
                                    verifySendoutResult: VerifySendoutResult.fromJSON(object.verifySendoutResult),
                                }
                                : isSet(object.submitSendoutResult)
                                    ? {
                                        $case: "submitSendoutResult",
                                        submitSendoutResult: SubmitSendoutResult.fromJSON(object.submitSendoutResult),
                                    }
                                    : isSet(object.messageTemplateStatusUpdate)
                                        ? {
                                            $case: "messageTemplateStatusUpdate",
                                            messageTemplateStatusUpdate: MessageTemplateStatusUpdate.fromJSON(object.messageTemplateStatusUpdate),
                                        }
                                        : isSet(object.reactFlowDocumentChange)
                                            ? {
                                                $case: "reactFlowDocumentChange",
                                                reactFlowDocumentChange: ReactFlowDocumentChangeDto.fromJSON(object.reactFlowDocumentChange),
                                            }
                                            : isSet(object.submitReactFlowDocumentResult)
                                                ? {
                                                    $case: "submitReactFlowDocumentResult",
                                                    submitReactFlowDocumentResult: SubmitReactFlowDocumentResult.fromJSON(object.submitReactFlowDocumentResult),
                                                }
                                                : isSet(object.businessChannelDto)
                                                    ? {
                                                        $case: "businessChannelDto",
                                                        businessChannelDto: BusinessChannel.fromJSON(object.businessChannelDto),
                                                    }
                                                    : isSet(object.verifyReactFlowDocumentResult)
                                                        ? {
                                                            $case: "verifyReactFlowDocumentResult",
                                                            verifyReactFlowDocumentResult: VerifyReactFlowDocumentResult.fromJSON(object.verifyReactFlowDocumentResult),
                                                        }
                                                        : isSet(object.compileReactFlowDocumentResult)
                                                            ? {
                                                                $case: "compileReactFlowDocumentResult",
                                                                compileReactFlowDocumentResult: CompileReactFlowDocumentResult.fromJSON(object.compileReactFlowDocumentResult),
                                                            }
                                                            : isSet(object.deployReactFlowDocumentResult)
                                                                ? {
                                                                    $case: "deployReactFlowDocumentResult",
                                                                    deployReactFlowDocumentResult: DeployReactFlowDocumentResult.fromJSON(object.deployReactFlowDocumentResult),
                                                                }
                                                                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
        const obj = {};
        message.objectId !== undefined && (obj.objectId = message.objectId);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.clientId !== undefined && (obj.clientId = message.clientId);
        ((_a = message.resultOneof) === null || _a === void 0 ? void 0 : _a.$case) === "verifyCampaignResult" &&
            (obj.verifyCampaignResult = ((_b = message.resultOneof) === null || _b === void 0 ? void 0 : _b.verifyCampaignResult)
                ? VerifyCampaignResult.toJSON((_c = message.resultOneof) === null || _c === void 0 ? void 0 : _c.verifyCampaignResult)
                : undefined);
        ((_d = message.resultOneof) === null || _d === void 0 ? void 0 : _d.$case) === "submitCampaignResult" &&
            (obj.submitCampaignResult = ((_e = message.resultOneof) === null || _e === void 0 ? void 0 : _e.submitCampaignResult)
                ? SubmitCampaignResult.toJSON((_f = message.resultOneof) === null || _f === void 0 ? void 0 : _f.submitCampaignResult)
                : undefined);
        ((_g = message.resultOneof) === null || _g === void 0 ? void 0 : _g.$case) === "roomDto" &&
            (obj.roomDto = ((_h = message.resultOneof) === null || _h === void 0 ? void 0 : _h.roomDto)
                ? RoomDto.toJSON((_j = message.resultOneof) === null || _j === void 0 ? void 0 : _j.roomDto)
                : undefined);
        ((_k = message.resultOneof) === null || _k === void 0 ? void 0 : _k.$case) === "businessDto" &&
            (obj.businessDto = ((_l = message.resultOneof) === null || _l === void 0 ? void 0 : _l.businessDto)
                ? Business.toJSON((_m = message.resultOneof) === null || _m === void 0 ? void 0 : _m.businessDto)
                : undefined);
        ((_o = message.resultOneof) === null || _o === void 0 ? void 0 : _o.$case) === "verifySendoutResult" &&
            (obj.verifySendoutResult = ((_p = message.resultOneof) === null || _p === void 0 ? void 0 : _p.verifySendoutResult)
                ? VerifySendoutResult.toJSON((_q = message.resultOneof) === null || _q === void 0 ? void 0 : _q.verifySendoutResult)
                : undefined);
        ((_r = message.resultOneof) === null || _r === void 0 ? void 0 : _r.$case) === "submitSendoutResult" &&
            (obj.submitSendoutResult = ((_s = message.resultOneof) === null || _s === void 0 ? void 0 : _s.submitSendoutResult)
                ? SubmitSendoutResult.toJSON((_t = message.resultOneof) === null || _t === void 0 ? void 0 : _t.submitSendoutResult)
                : undefined);
        ((_u = message.resultOneof) === null || _u === void 0 ? void 0 : _u.$case) === "messageTemplateStatusUpdate" &&
            (obj.messageTemplateStatusUpdate = ((_v = message.resultOneof) === null || _v === void 0 ? void 0 : _v.messageTemplateStatusUpdate)
                ? MessageTemplateStatusUpdate.toJSON((_w = message.resultOneof) === null || _w === void 0 ? void 0 : _w.messageTemplateStatusUpdate)
                : undefined);
        ((_x = message.resultOneof) === null || _x === void 0 ? void 0 : _x.$case) === "reactFlowDocumentChange" &&
            (obj.reactFlowDocumentChange = ((_y = message.resultOneof) === null || _y === void 0 ? void 0 : _y.reactFlowDocumentChange)
                ? ReactFlowDocumentChangeDto.toJSON((_z = message.resultOneof) === null || _z === void 0 ? void 0 : _z.reactFlowDocumentChange)
                : undefined);
        ((_0 = message.resultOneof) === null || _0 === void 0 ? void 0 : _0.$case) === "submitReactFlowDocumentResult" &&
            (obj.submitReactFlowDocumentResult = ((_1 = message.resultOneof) === null || _1 === void 0 ? void 0 : _1.submitReactFlowDocumentResult)
                ? SubmitReactFlowDocumentResult.toJSON((_2 = message.resultOneof) === null || _2 === void 0 ? void 0 : _2.submitReactFlowDocumentResult)
                : undefined);
        ((_3 = message.resultOneof) === null || _3 === void 0 ? void 0 : _3.$case) === "businessChannelDto" &&
            (obj.businessChannelDto = ((_4 = message.resultOneof) === null || _4 === void 0 ? void 0 : _4.businessChannelDto)
                ? BusinessChannel.toJSON((_5 = message.resultOneof) === null || _5 === void 0 ? void 0 : _5.businessChannelDto)
                : undefined);
        ((_6 = message.resultOneof) === null || _6 === void 0 ? void 0 : _6.$case) === "verifyReactFlowDocumentResult" &&
            (obj.verifyReactFlowDocumentResult = ((_7 = message.resultOneof) === null || _7 === void 0 ? void 0 : _7.verifyReactFlowDocumentResult)
                ? VerifyReactFlowDocumentResult.toJSON((_8 = message.resultOneof) === null || _8 === void 0 ? void 0 : _8.verifyReactFlowDocumentResult)
                : undefined);
        ((_9 = message.resultOneof) === null || _9 === void 0 ? void 0 : _9.$case) === "compileReactFlowDocumentResult" &&
            (obj.compileReactFlowDocumentResult = ((_10 = message.resultOneof) === null || _10 === void 0 ? void 0 : _10.compileReactFlowDocumentResult)
                ? CompileReactFlowDocumentResult.toJSON((_11 = message.resultOneof) === null || _11 === void 0 ? void 0 : _11.compileReactFlowDocumentResult)
                : undefined);
        ((_12 = message.resultOneof) === null || _12 === void 0 ? void 0 : _12.$case) === "deployReactFlowDocumentResult" &&
            (obj.deployReactFlowDocumentResult = ((_13 = message.resultOneof) === null || _13 === void 0 ? void 0 : _13.deployReactFlowDocumentResult)
                ? DeployReactFlowDocumentResult.toJSON((_14 = message.resultOneof) === null || _14 === void 0 ? void 0 : _14.deployReactFlowDocumentResult)
                : undefined);
        return obj;
    },
};
function createBaseRoomAssignment() {
    return { assignedUserId: "", roomId: "" };
}
export const RoomAssignment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.assignedUserId !== "") {
            writer.uint32(10).string(message.assignedUserId);
        }
        if (message.roomId !== "") {
            writer.uint32(18).string(message.roomId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomAssignment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assignedUserId = reader.string();
                    break;
                case 2:
                    message.roomId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            assignedUserId: isSet(object.assignedUserId)
                ? String(object.assignedUserId)
                : "",
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.assignedUserId !== undefined &&
            (obj.assignedUserId = message.assignedUserId);
        message.roomId !== undefined && (obj.roomId = message.roomId);
        return obj;
    },
};
function createBaseRoomStatusChange() {
    return { roomId: "", userId: "", from: 0, to: 0 };
}
export const RoomStatusChange = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roomId !== "") {
            writer.uint32(10).string(message.roomId);
        }
        if (message.userId !== "") {
            writer.uint32(18).string(message.userId);
        }
        if (message.from !== 0) {
            writer.uint32(24).int32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(32).int32(message.to);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomStatusChange();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.from = reader.int32();
                    break;
                case 4:
                    message.to = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            userId: isSet(object.userId) ? String(object.userId) : "",
            from: isSet(object.from) ? roomStatusFromJSON(object.from) : 0,
            to: isSet(object.to) ? roomStatusFromJSON(object.to) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.userId !== undefined && (obj.userId = message.userId);
        message.from !== undefined && (obj.from = roomStatusToJSON(message.from));
        message.to !== undefined && (obj.to = roomStatusToJSON(message.to));
        return obj;
    },
};
function createBaseShopifyCustomerProfileFetch() {
    return { businessId: "", businessChannelId: "" };
}
export const ShopifyCustomerProfileFetch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyCustomerProfileFetch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        return obj;
    },
};
function createBaseShopifyProductFetch() {
    return {
        businessId: "",
        businessChannelId: "",
        createdAtMin: "",
        skipPurchases: false,
    };
}
export const ShopifyProductFetch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.createdAtMin !== "") {
            writer.uint32(26).string(message.createdAtMin);
        }
        if (message.skipPurchases === true) {
            writer.uint32(32).bool(message.skipPurchases);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyProductFetch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.createdAtMin = reader.string();
                    break;
                case 4:
                    message.skipPurchases = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            createdAtMin: isSet(object.createdAtMin)
                ? String(object.createdAtMin)
                : "",
            skipPurchases: isSet(object.skipPurchases)
                ? Boolean(object.skipPurchases)
                : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.createdAtMin !== undefined &&
            (obj.createdAtMin = message.createdAtMin);
        message.skipPurchases !== undefined &&
            (obj.skipPurchases = message.skipPurchases);
        return obj;
    },
};
function createBaseShopifyOrderFetch() {
    return { businessId: "", businessChannelId: "", createdAtMin: "" };
}
export const ShopifyOrderFetch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.createdAtMin !== "") {
            writer.uint32(26).string(message.createdAtMin);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyOrderFetch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.createdAtMin = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            createdAtMin: isSet(object.createdAtMin)
                ? String(object.createdAtMin)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.createdAtMin !== undefined &&
            (obj.createdAtMin = message.createdAtMin);
        return obj;
    },
};
function createBaseShopifyOrderIngest() {
    return { businessId: "", businessChannelId: "", order: undefined };
}
export const ShopifyOrderIngest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.order !== undefined) {
            Order.encode(message.order, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyOrderIngest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.order = Order.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.order !== undefined &&
            (obj.order = message.order ? Order.toJSON(message.order) : undefined);
        return obj;
    },
};
function createBaseShopifyProductIngest() {
    return { businessId: "", businessChannelId: "", product: undefined };
}
export const ShopifyProductIngest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.product !== undefined) {
            Product.encode(message.product, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyProductIngest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.product = Product.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            product: isSet(object.product)
                ? Product.fromJSON(object.product)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.product !== undefined &&
            (obj.product = message.product
                ? Product.toJSON(message.product)
                : undefined);
        return obj;
    },
};
function createBaseShopifyProductMarkForDeletion() {
    return { businessId: "", businessChannelId: "", productId: 0 };
}
export const ShopifyProductMarkForDeletion = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.productId !== 0) {
            writer.uint32(24).int64(message.productId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyProductMarkForDeletion();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.productId = longToNumber(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            productId: isSet(object.productId) ? Number(object.productId) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.productId !== undefined &&
            (obj.productId = Math.round(message.productId));
        return obj;
    },
};
function createBaseAppUninstallCleanup() {
    return { inputs: [] };
}
export const AppUninstallCleanup = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.inputs) {
            AppUninstallCleanup_AppUninstallCleanupInput.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAppUninstallCleanup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.inputs.push(AppUninstallCleanup_AppUninstallCleanupInput.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inputs: Array.isArray(object === null || object === void 0 ? void 0 : object.inputs)
                ? object.inputs.map((e) => AppUninstallCleanup_AppUninstallCleanupInput.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.inputs) {
            obj.inputs = message.inputs.map((e) => e ? AppUninstallCleanup_AppUninstallCleanupInput.toJSON(e) : undefined);
        }
        else {
            obj.inputs = [];
        }
        return obj;
    },
};
function createBaseAppUninstallCleanup_AppUninstallCleanupInput() {
    return { businessId: "", businessChannelId: "" };
}
export const AppUninstallCleanup_AppUninstallCleanupInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAppUninstallCleanup_AppUninstallCleanupInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        return obj;
    },
};
function createBaseIntegrationsWorkflow() {
    return { input: undefined };
}
export const IntegrationsWorkflow = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.input !== undefined) {
            IntegrationsWorkflowInput.encode(message.input, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationsWorkflow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.input = IntegrationsWorkflowInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            input: isSet(object.input)
                ? IntegrationsWorkflowInput.fromJSON(object.input)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.input !== undefined &&
            (obj.input = message.input
                ? IntegrationsWorkflowInput.toJSON(message.input)
                : undefined);
        return obj;
    },
};
function createBaseRoomCustomerMembershipUpdate() {
    return { roomId: "", customer: undefined };
}
export const RoomCustomerMembershipUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roomId !== "") {
            writer.uint32(10).string(message.roomId);
        }
        if (message.customer !== undefined) {
            Customer.encode(message.customer, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomCustomerMembershipUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.customer = Customer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            customer: isSet(object.customer)
                ? Customer.fromJSON(object.customer)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? Customer.toJSON(message.customer)
                : undefined);
        return obj;
    },
};
function createBaseSyncFromSource() {
    return {
        businessId: "",
        businessChannelId: "",
        startTime: undefined,
        endTime: undefined,
    };
}
export const SyncFromSource = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(18).string(message.businessChannelId);
        }
        if (message.startTime !== undefined) {
            Timestamp.encode(toTimestamp(message.startTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.endTime !== undefined) {
            Timestamp.encode(toTimestamp(message.endTime), writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSyncFromSource();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.businessChannelId = reader.string();
                    break;
                case 3:
                    message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            startTime: isSet(object.startTime) ? String(object.startTime) : undefined,
            endTime: isSet(object.endTime) ? String(object.endTime) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
};
function createBaseRoomEventIdentifier() {
    return { id: "" };
}
export const RoomEventIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomEventIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseSourceEvent() {
    return { sourceOneof: undefined };
}
export const SourceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_a = message.sourceOneof) === null || _a === void 0 ? void 0 : _a.$case) === "connectly") {
            ConnectlyEvent.encode(message.sourceOneof.connectly, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.sourceOneof) === null || _b === void 0 ? void 0 : _b.$case) === "fbMessengerWebhook") {
            FbMessengerWebhookEnvelope.encode(message.sourceOneof.fbMessengerWebhook, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.sourceOneof) === null || _c === void 0 ? void 0 : _c.$case) === "twilioMessageWebhook") {
            TwilioMessagingWebhookEvent.encode(message.sourceOneof.twilioMessageWebhook, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.sourceOneof) === null || _d === void 0 ? void 0 : _d.$case) === "whatsappWebhook") {
            WhatsAppWebhookEntry.encode(message.sourceOneof.whatsappWebhook, writer.uint32(34).fork()).ldelim();
        }
        if (((_e = message.sourceOneof) === null || _e === void 0 ? void 0 : _e.$case) === "shopifyWebhook") {
            WebhookEventEnvelope.encode(message.sourceOneof.shopifyWebhook, writer.uint32(42).fork()).ldelim();
        }
        if (((_f = message.sourceOneof) === null || _f === void 0 ? void 0 : _f.$case) === "zendeskWebhook") {
            ZendeskZisWebhookEvent.encode(message.sourceOneof.zendeskWebhook, writer.uint32(50).fork()).ldelim();
        }
        if (((_g = message.sourceOneof) === null || _g === void 0 ? void 0 : _g.$case) === "nupayNotification") {
            Notification.encode(message.sourceOneof.nupayNotification, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSourceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sourceOneof = {
                        $case: "connectly",
                        connectly: ConnectlyEvent.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.sourceOneof = {
                        $case: "fbMessengerWebhook",
                        fbMessengerWebhook: FbMessengerWebhookEnvelope.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.sourceOneof = {
                        $case: "twilioMessageWebhook",
                        twilioMessageWebhook: TwilioMessagingWebhookEvent.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.sourceOneof = {
                        $case: "whatsappWebhook",
                        whatsappWebhook: WhatsAppWebhookEntry.decode(reader, reader.uint32()),
                    };
                    break;
                case 5:
                    message.sourceOneof = {
                        $case: "shopifyWebhook",
                        shopifyWebhook: WebhookEventEnvelope.decode(reader, reader.uint32()),
                    };
                    break;
                case 6:
                    message.sourceOneof = {
                        $case: "zendeskWebhook",
                        zendeskWebhook: ZendeskZisWebhookEvent.decode(reader, reader.uint32()),
                    };
                    break;
                case 7:
                    message.sourceOneof = {
                        $case: "nupayNotification",
                        nupayNotification: Notification.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sourceOneof: isSet(object.connectly)
                ? {
                    $case: "connectly",
                    connectly: ConnectlyEvent.fromJSON(object.connectly),
                }
                : isSet(object.fbMessengerWebhook)
                    ? {
                        $case: "fbMessengerWebhook",
                        fbMessengerWebhook: FbMessengerWebhookEnvelope.fromJSON(object.fbMessengerWebhook),
                    }
                    : isSet(object.twilioMessageWebhook)
                        ? {
                            $case: "twilioMessageWebhook",
                            twilioMessageWebhook: TwilioMessagingWebhookEvent.fromJSON(object.twilioMessageWebhook),
                        }
                        : isSet(object.whatsappWebhook)
                            ? {
                                $case: "whatsappWebhook",
                                whatsappWebhook: WhatsAppWebhookEntry.fromJSON(object.whatsappWebhook),
                            }
                            : isSet(object.shopifyWebhook)
                                ? {
                                    $case: "shopifyWebhook",
                                    shopifyWebhook: WebhookEventEnvelope.fromJSON(object.shopifyWebhook),
                                }
                                : isSet(object.zendeskWebhook)
                                    ? {
                                        $case: "zendeskWebhook",
                                        zendeskWebhook: ZendeskZisWebhookEvent.fromJSON(object.zendeskWebhook),
                                    }
                                    : isSet(object.nupayNotification)
                                        ? {
                                            $case: "nupayNotification",
                                            nupayNotification: Notification.fromJSON(object.nupayNotification),
                                        }
                                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        const obj = {};
        ((_a = message.sourceOneof) === null || _a === void 0 ? void 0 : _a.$case) === "connectly" &&
            (obj.connectly = ((_b = message.sourceOneof) === null || _b === void 0 ? void 0 : _b.connectly)
                ? ConnectlyEvent.toJSON((_c = message.sourceOneof) === null || _c === void 0 ? void 0 : _c.connectly)
                : undefined);
        ((_d = message.sourceOneof) === null || _d === void 0 ? void 0 : _d.$case) === "fbMessengerWebhook" &&
            (obj.fbMessengerWebhook = ((_e = message.sourceOneof) === null || _e === void 0 ? void 0 : _e.fbMessengerWebhook)
                ? FbMessengerWebhookEnvelope.toJSON((_f = message.sourceOneof) === null || _f === void 0 ? void 0 : _f.fbMessengerWebhook)
                : undefined);
        ((_g = message.sourceOneof) === null || _g === void 0 ? void 0 : _g.$case) === "twilioMessageWebhook" &&
            (obj.twilioMessageWebhook = ((_h = message.sourceOneof) === null || _h === void 0 ? void 0 : _h.twilioMessageWebhook)
                ? TwilioMessagingWebhookEvent.toJSON((_j = message.sourceOneof) === null || _j === void 0 ? void 0 : _j.twilioMessageWebhook)
                : undefined);
        ((_k = message.sourceOneof) === null || _k === void 0 ? void 0 : _k.$case) === "whatsappWebhook" &&
            (obj.whatsappWebhook = ((_l = message.sourceOneof) === null || _l === void 0 ? void 0 : _l.whatsappWebhook)
                ? WhatsAppWebhookEntry.toJSON((_m = message.sourceOneof) === null || _m === void 0 ? void 0 : _m.whatsappWebhook)
                : undefined);
        ((_o = message.sourceOneof) === null || _o === void 0 ? void 0 : _o.$case) === "shopifyWebhook" &&
            (obj.shopifyWebhook = ((_p = message.sourceOneof) === null || _p === void 0 ? void 0 : _p.shopifyWebhook)
                ? WebhookEventEnvelope.toJSON((_q = message.sourceOneof) === null || _q === void 0 ? void 0 : _q.shopifyWebhook)
                : undefined);
        ((_r = message.sourceOneof) === null || _r === void 0 ? void 0 : _r.$case) === "zendeskWebhook" &&
            (obj.zendeskWebhook = ((_s = message.sourceOneof) === null || _s === void 0 ? void 0 : _s.zendeskWebhook)
                ? ZendeskZisWebhookEvent.toJSON((_t = message.sourceOneof) === null || _t === void 0 ? void 0 : _t.zendeskWebhook)
                : undefined);
        ((_u = message.sourceOneof) === null || _u === void 0 ? void 0 : _u.$case) === "nupayNotification" &&
            (obj.nupayNotification = ((_v = message.sourceOneof) === null || _v === void 0 ? void 0 : _v.nupayNotification)
                ? Notification.toJSON((_w = message.sourceOneof) === null || _w === void 0 ? void 0 : _w.nupayNotification)
                : undefined);
        return obj;
    },
};
function createBaseTargetEvent() {
    return { targetOneof: undefined };
}
export const TargetEvent = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c, _d;
        if (((_a = message.targetOneof) === null || _a === void 0 ? void 0 : _a.$case) === "connectly") {
            ConnectlyEvent.encode(message.targetOneof.connectly, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.targetOneof) === null || _b === void 0 ? void 0 : _b.$case) === "fbMessengerSend") {
            SendEvent.encode(message.targetOneof.fbMessengerSend, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.targetOneof) === null || _c === void 0 ? void 0 : _c.$case) === "twilioMessageSend") {
            SendMessage.encode(message.targetOneof.twilioMessageSend, writer.uint32(26).fork()).ldelim();
        }
        if (((_d = message.targetOneof) === null || _d === void 0 ? void 0 : _d.$case) === "whatsappMessage") {
            WhatsAppMessageRequest.encode(message.targetOneof.whatsappMessage, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTargetEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetOneof = {
                        $case: "connectly",
                        connectly: ConnectlyEvent.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.targetOneof = {
                        $case: "fbMessengerSend",
                        fbMessengerSend: SendEvent.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.targetOneof = {
                        $case: "twilioMessageSend",
                        twilioMessageSend: SendMessage.decode(reader, reader.uint32()),
                    };
                    break;
                case 4:
                    message.targetOneof = {
                        $case: "whatsappMessage",
                        whatsappMessage: WhatsAppMessageRequest.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            targetOneof: isSet(object.connectly)
                ? {
                    $case: "connectly",
                    connectly: ConnectlyEvent.fromJSON(object.connectly),
                }
                : isSet(object.fbMessengerSend)
                    ? {
                        $case: "fbMessengerSend",
                        fbMessengerSend: SendEvent.fromJSON(object.fbMessengerSend),
                    }
                    : isSet(object.twilioMessageSend)
                        ? {
                            $case: "twilioMessageSend",
                            twilioMessageSend: SendMessage.fromJSON(object.twilioMessageSend),
                        }
                        : isSet(object.whatsappMessage)
                            ? {
                                $case: "whatsappMessage",
                                whatsappMessage: WhatsAppMessageRequest.fromJSON(object.whatsappMessage),
                            }
                            : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const obj = {};
        ((_a = message.targetOneof) === null || _a === void 0 ? void 0 : _a.$case) === "connectly" &&
            (obj.connectly = ((_b = message.targetOneof) === null || _b === void 0 ? void 0 : _b.connectly)
                ? ConnectlyEvent.toJSON((_c = message.targetOneof) === null || _c === void 0 ? void 0 : _c.connectly)
                : undefined);
        ((_d = message.targetOneof) === null || _d === void 0 ? void 0 : _d.$case) === "fbMessengerSend" &&
            (obj.fbMessengerSend = ((_e = message.targetOneof) === null || _e === void 0 ? void 0 : _e.fbMessengerSend)
                ? SendEvent.toJSON((_f = message.targetOneof) === null || _f === void 0 ? void 0 : _f.fbMessengerSend)
                : undefined);
        ((_g = message.targetOneof) === null || _g === void 0 ? void 0 : _g.$case) === "twilioMessageSend" &&
            (obj.twilioMessageSend = ((_h = message.targetOneof) === null || _h === void 0 ? void 0 : _h.twilioMessageSend)
                ? SendMessage.toJSON((_j = message.targetOneof) === null || _j === void 0 ? void 0 : _j.twilioMessageSend)
                : undefined);
        ((_k = message.targetOneof) === null || _k === void 0 ? void 0 : _k.$case) === "whatsappMessage" &&
            (obj.whatsappMessage = ((_l = message.targetOneof) === null || _l === void 0 ? void 0 : _l.whatsappMessage)
                ? WhatsAppMessageRequest.toJSON((_m = message.targetOneof) === null || _m === void 0 ? void 0 : _m.whatsappMessage)
                : undefined);
        return obj;
    },
};
function createBaseEventProcessingOrderMetadata() {
    return { parentId: "", strategy: 0, expScheduledAt: undefined };
}
export const EventProcessingOrderMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.parentId !== "") {
            writer.uint32(10).string(message.parentId);
        }
        if (message.strategy !== 0) {
            writer.uint32(16).int32(message.strategy);
        }
        if (message.expScheduledAt !== undefined) {
            Timestamp.encode(toTimestamp(message.expScheduledAt), writer.uint32(794).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventProcessingOrderMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.parentId = reader.string();
                    break;
                case 2:
                    message.strategy = reader.int32();
                    break;
                case 99:
                    message.expScheduledAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            parentId: isSet(object.parentId) ? String(object.parentId) : "",
            strategy: isSet(object.strategy)
                ? eventProcessingOrderMetadata_ProcessingStrategyFromJSON(object.strategy)
                : 0,
            expScheduledAt: isSet(object.expScheduledAt)
                ? String(object.expScheduledAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.parentId !== undefined && (obj.parentId = message.parentId);
        message.strategy !== undefined &&
            (obj.strategy = eventProcessingOrderMetadata_ProcessingStrategyToJSON(message.strategy));
        message.expScheduledAt !== undefined &&
            (obj.expScheduledAt = message.expScheduledAt);
        return obj;
    },
};
function createBaseRoomDtos() {
    return { roomsById: {} };
}
export const RoomDtos = {
    encode(message, writer = _m0.Writer.create()) {
        Object.entries(message.roomsById).forEach(([key, value]) => {
            RoomDtos_RoomsByIdEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomDtos();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = RoomDtos_RoomsByIdEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.roomsById[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomsById: isObject(object.roomsById)
                ? Object.entries(object.roomsById).reduce((acc, [key, value]) => {
                    acc[key] = RoomDto.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        obj.roomsById = {};
        if (message.roomsById) {
            Object.entries(message.roomsById).forEach(([k, v]) => {
                obj.roomsById[k] = RoomDto.toJSON(v);
            });
        }
        return obj;
    },
};
function createBaseRoomDtos_RoomsByIdEntry() {
    return { key: "", value: undefined };
}
export const RoomDtos_RoomsByIdEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            RoomDto.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomDtos_RoomsByIdEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = RoomDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? RoomDto.fromJSON(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value ? RoomDto.toJSON(message.value) : undefined);
        return obj;
    },
};
function createBaseRoomDto() {
    return {
        roomId: "",
        events: [],
        lastEventTime: undefined,
        latestCustomerEventId: "",
        latestUserEventId: "",
        users: [],
        businessChannelsById: {},
        customerChannelsById: {},
        customer: undefined,
        tags: [],
        assignedUserId: "",
        read: false,
        status: undefined,
        tagsV2: undefined,
        metadata: undefined,
        unreadCount: 0,
    };
}
export const RoomDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roomId !== "") {
            writer.uint32(10).string(message.roomId);
        }
        for (const v of message.events) {
            RoomEventDto.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.lastEventTime !== undefined) {
            Timestamp.encode(toTimestamp(message.lastEventTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.latestCustomerEventId !== "") {
            writer.uint32(34).string(message.latestCustomerEventId);
        }
        if (message.latestUserEventId !== "") {
            writer.uint32(42).string(message.latestUserEventId);
        }
        for (const v of message.users) {
            RoomUser.encode(v, writer.uint32(50).fork()).ldelim();
        }
        Object.entries(message.businessChannelsById).forEach(([key, value]) => {
            RoomDto_BusinessChannelsByIdEntry.encode({ key: key, value }, writer.uint32(58).fork()).ldelim();
        });
        Object.entries(message.customerChannelsById).forEach(([key, value]) => {
            RoomDto_CustomerChannelsByIdEntry.encode({ key: key, value }, writer.uint32(66).fork()).ldelim();
        });
        if (message.customer !== undefined) {
            Customer.encode(message.customer, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.tags) {
            writer.uint32(82).string(v);
        }
        if (message.assignedUserId !== "") {
            writer.uint32(90).string(message.assignedUserId);
        }
        if (message.read === true) {
            writer.uint32(96).bool(message.read);
        }
        if (message.status !== undefined) {
            RoomStatusDto.encode(message.status, writer.uint32(106).fork()).ldelim();
        }
        if (message.tagsV2 !== undefined) {
            TagAssociationCollection.encode(message.tagsV2, writer.uint32(114).fork()).ldelim();
        }
        if (message.metadata !== undefined) {
            Metadata.encode(message.metadata, writer.uint32(122).fork()).ldelim();
        }
        if (message.unreadCount !== 0) {
            writer.uint32(128).int32(message.unreadCount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.events.push(RoomEventDto.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.lastEventTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.latestCustomerEventId = reader.string();
                    break;
                case 5:
                    message.latestUserEventId = reader.string();
                    break;
                case 6:
                    message.users.push(RoomUser.decode(reader, reader.uint32()));
                    break;
                case 7:
                    const entry7 = RoomDto_BusinessChannelsByIdEntry.decode(reader, reader.uint32());
                    if (entry7.value !== undefined) {
                        message.businessChannelsById[entry7.key] = entry7.value;
                    }
                    break;
                case 8:
                    const entry8 = RoomDto_CustomerChannelsByIdEntry.decode(reader, reader.uint32());
                    if (entry8.value !== undefined) {
                        message.customerChannelsById[entry8.key] = entry8.value;
                    }
                    break;
                case 9:
                    message.customer = Customer.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.tags.push(reader.string());
                    break;
                case 11:
                    message.assignedUserId = reader.string();
                    break;
                case 12:
                    message.read = reader.bool();
                    break;
                case 13:
                    message.status = RoomStatusDto.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.tagsV2 = TagAssociationCollection.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.metadata = Metadata.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.unreadCount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            events: Array.isArray(object === null || object === void 0 ? void 0 : object.events)
                ? object.events.map((e) => RoomEventDto.fromJSON(e))
                : [],
            lastEventTime: isSet(object.lastEventTime)
                ? String(object.lastEventTime)
                : undefined,
            latestCustomerEventId: isSet(object.latestCustomerEventId)
                ? String(object.latestCustomerEventId)
                : "",
            latestUserEventId: isSet(object.latestUserEventId)
                ? String(object.latestUserEventId)
                : "",
            users: Array.isArray(object === null || object === void 0 ? void 0 : object.users)
                ? object.users.map((e) => RoomUser.fromJSON(e))
                : [],
            businessChannelsById: isObject(object.businessChannelsById)
                ? Object.entries(object.businessChannelsById).reduce((acc, [key, value]) => {
                    acc[key] = ChannelIdentifier.fromJSON(value);
                    return acc;
                }, {})
                : {},
            customerChannelsById: isObject(object.customerChannelsById)
                ? Object.entries(object.customerChannelsById).reduce((acc, [key, value]) => {
                    acc[key] = ChannelIdentifier.fromJSON(value);
                    return acc;
                }, {})
                : {},
            customer: isSet(object.customer)
                ? Customer.fromJSON(object.customer)
                : undefined,
            tags: Array.isArray(object === null || object === void 0 ? void 0 : object.tags)
                ? object.tags.map((e) => String(e))
                : [],
            assignedUserId: isSet(object.assignedUserId)
                ? String(object.assignedUserId)
                : "",
            read: isSet(object.read) ? Boolean(object.read) : false,
            status: isSet(object.status)
                ? RoomStatusDto.fromJSON(object.status)
                : undefined,
            tagsV2: isSet(object.tagsV2)
                ? TagAssociationCollection.fromJSON(object.tagsV2)
                : undefined,
            metadata: isSet(object.metadata)
                ? Metadata.fromJSON(object.metadata)
                : undefined,
            unreadCount: isSet(object.unreadCount) ? Number(object.unreadCount) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.roomId !== undefined && (obj.roomId = message.roomId);
        if (message.events) {
            obj.events = message.events.map((e) => e ? RoomEventDto.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.lastEventTime !== undefined &&
            (obj.lastEventTime = message.lastEventTime);
        message.latestCustomerEventId !== undefined &&
            (obj.latestCustomerEventId = message.latestCustomerEventId);
        message.latestUserEventId !== undefined &&
            (obj.latestUserEventId = message.latestUserEventId);
        if (message.users) {
            obj.users = message.users.map((e) => e ? RoomUser.toJSON(e) : undefined);
        }
        else {
            obj.users = [];
        }
        obj.businessChannelsById = {};
        if (message.businessChannelsById) {
            Object.entries(message.businessChannelsById).forEach(([k, v]) => {
                obj.businessChannelsById[k] = ChannelIdentifier.toJSON(v);
            });
        }
        obj.customerChannelsById = {};
        if (message.customerChannelsById) {
            Object.entries(message.customerChannelsById).forEach(([k, v]) => {
                obj.customerChannelsById[k] = ChannelIdentifier.toJSON(v);
            });
        }
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? Customer.toJSON(message.customer)
                : undefined);
        if (message.tags) {
            obj.tags = message.tags.map((e) => e);
        }
        else {
            obj.tags = [];
        }
        message.assignedUserId !== undefined &&
            (obj.assignedUserId = message.assignedUserId);
        message.read !== undefined && (obj.read = message.read);
        message.status !== undefined &&
            (obj.status = message.status
                ? RoomStatusDto.toJSON(message.status)
                : undefined);
        message.tagsV2 !== undefined &&
            (obj.tagsV2 = message.tagsV2
                ? TagAssociationCollection.toJSON(message.tagsV2)
                : undefined);
        message.metadata !== undefined &&
            (obj.metadata = message.metadata
                ? Metadata.toJSON(message.metadata)
                : undefined);
        message.unreadCount !== undefined &&
            (obj.unreadCount = Math.round(message.unreadCount));
        return obj;
    },
};
function createBaseRoomDto_BusinessChannelsByIdEntry() {
    return { key: "", value: undefined };
}
export const RoomDto_BusinessChannelsByIdEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            ChannelIdentifier.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomDto_BusinessChannelsByIdEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? ChannelIdentifier.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? ChannelIdentifier.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseRoomDto_CustomerChannelsByIdEntry() {
    return { key: "", value: undefined };
}
export const RoomDto_CustomerChannelsByIdEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            ChannelIdentifier.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomDto_CustomerChannelsByIdEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value)
                ? ChannelIdentifier.fromJSON(object.value)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined &&
            (obj.value = message.value
                ? ChannelIdentifier.toJSON(message.value)
                : undefined);
        return obj;
    },
};
function createBaseSimpleRoomDto() {
    return {
        roomId: "",
        assignedUserId: "",
        status: undefined,
        metadata: undefined,
    };
}
export const SimpleRoomDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roomId !== "") {
            writer.uint32(10).string(message.roomId);
        }
        if (message.assignedUserId !== "") {
            writer.uint32(18).string(message.assignedUserId);
        }
        if (message.status !== undefined) {
            RoomStatusDto.encode(message.status, writer.uint32(26).fork()).ldelim();
        }
        if (message.metadata !== undefined) {
            Metadata.encode(message.metadata, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSimpleRoomDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.assignedUserId = reader.string();
                    break;
                case 3:
                    message.status = RoomStatusDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.metadata = Metadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            assignedUserId: isSet(object.assignedUserId)
                ? String(object.assignedUserId)
                : "",
            status: isSet(object.status)
                ? RoomStatusDto.fromJSON(object.status)
                : undefined,
            metadata: isSet(object.metadata)
                ? Metadata.fromJSON(object.metadata)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.assignedUserId !== undefined &&
            (obj.assignedUserId = message.assignedUserId);
        message.status !== undefined &&
            (obj.status = message.status
                ? RoomStatusDto.toJSON(message.status)
                : undefined);
        message.metadata !== undefined &&
            (obj.metadata = message.metadata
                ? Metadata.toJSON(message.metadata)
                : undefined);
        return obj;
    },
};
function createBaseRoomEventDto() {
    return {
        id: "",
        replyTo: undefined,
        externalId: undefined,
        roomId: "",
        createdAt: undefined,
        sentAt: undefined,
        deliveredAt: undefined,
        readAt: undefined,
        authorId: "",
        businessUserId: "",
        channelType: 0,
        businessChannelId: "",
        customerChannelId: "",
        connectlyEvent: undefined,
        platformError: undefined,
        tags: undefined,
    };
}
export const RoomEventDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.replyTo !== undefined) {
            StringValue.encode({ value: message.replyTo }, writer.uint32(138).fork()).ldelim();
        }
        if (message.externalId !== undefined) {
            StringValue.encode({ value: message.externalId }, writer.uint32(130).fork()).ldelim();
        }
        if (message.roomId !== "") {
            writer.uint32(114).string(message.roomId);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(18).fork()).ldelim();
        }
        if (message.sentAt !== undefined) {
            Timestamp.encode(toTimestamp(message.sentAt), writer.uint32(26).fork()).ldelim();
        }
        if (message.deliveredAt !== undefined) {
            Timestamp.encode(toTimestamp(message.deliveredAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.readAt !== undefined) {
            Timestamp.encode(toTimestamp(message.readAt), writer.uint32(42).fork()).ldelim();
        }
        if (message.authorId !== "") {
            writer.uint32(58).string(message.authorId);
        }
        if (message.businessUserId !== "") {
            writer.uint32(66).string(message.businessUserId);
        }
        if (message.channelType !== 0) {
            writer.uint32(72).int32(message.channelType);
        }
        if (message.businessChannelId !== "") {
            writer.uint32(82).string(message.businessChannelId);
        }
        if (message.customerChannelId !== "") {
            writer.uint32(90).string(message.customerChannelId);
        }
        if (message.connectlyEvent !== undefined) {
            ConnectlyEvent.encode(message.connectlyEvent, writer.uint32(98).fork()).ldelim();
        }
        if (message.platformError !== undefined) {
            PlatformError.encode(message.platformError, writer.uint32(106).fork()).ldelim();
        }
        if (message.tags !== undefined) {
            TagAssociationCollection.encode(message.tags, writer.uint32(122).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomEventDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 17:
                    message.replyTo = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.externalId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sentAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.deliveredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.readAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.authorId = reader.string();
                    break;
                case 8:
                    message.businessUserId = reader.string();
                    break;
                case 9:
                    message.channelType = reader.int32();
                    break;
                case 10:
                    message.businessChannelId = reader.string();
                    break;
                case 11:
                    message.customerChannelId = reader.string();
                    break;
                case 12:
                    message.connectlyEvent = ConnectlyEvent.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.platformError = PlatformError.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.tags = TagAssociationCollection.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            replyTo: isSet(object.replyTo) ? String(object.replyTo) : undefined,
            externalId: isSet(object.externalId)
                ? String(object.externalId)
                : undefined,
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            sentAt: isSet(object.sentAt) ? String(object.sentAt) : undefined,
            deliveredAt: isSet(object.deliveredAt)
                ? String(object.deliveredAt)
                : undefined,
            readAt: isSet(object.readAt) ? String(object.readAt) : undefined,
            authorId: isSet(object.authorId) ? String(object.authorId) : "",
            businessUserId: isSet(object.businessUserId)
                ? String(object.businessUserId)
                : "",
            channelType: isSet(object.channelType)
                ? channelTypeFromJSON(object.channelType)
                : 0,
            businessChannelId: isSet(object.businessChannelId)
                ? String(object.businessChannelId)
                : "",
            customerChannelId: isSet(object.customerChannelId)
                ? String(object.customerChannelId)
                : "",
            connectlyEvent: isSet(object.connectlyEvent)
                ? ConnectlyEvent.fromJSON(object.connectlyEvent)
                : undefined,
            platformError: isSet(object.platformError)
                ? PlatformError.fromJSON(object.platformError)
                : undefined,
            tags: isSet(object.tags)
                ? TagAssociationCollection.fromJSON(object.tags)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.replyTo !== undefined && (obj.replyTo = message.replyTo);
        message.externalId !== undefined && (obj.externalId = message.externalId);
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.sentAt !== undefined && (obj.sentAt = message.sentAt);
        message.deliveredAt !== undefined &&
            (obj.deliveredAt = message.deliveredAt);
        message.readAt !== undefined && (obj.readAt = message.readAt);
        message.authorId !== undefined && (obj.authorId = message.authorId);
        message.businessUserId !== undefined &&
            (obj.businessUserId = message.businessUserId);
        message.channelType !== undefined &&
            (obj.channelType = channelTypeToJSON(message.channelType));
        message.businessChannelId !== undefined &&
            (obj.businessChannelId = message.businessChannelId);
        message.customerChannelId !== undefined &&
            (obj.customerChannelId = message.customerChannelId);
        message.connectlyEvent !== undefined &&
            (obj.connectlyEvent = message.connectlyEvent
                ? ConnectlyEvent.toJSON(message.connectlyEvent)
                : undefined);
        message.platformError !== undefined &&
            (obj.platformError = message.platformError
                ? PlatformError.toJSON(message.platformError)
                : undefined);
        message.tags !== undefined &&
            (obj.tags = message.tags
                ? TagAssociationCollection.toJSON(message.tags)
                : undefined);
        return obj;
    },
};
function createBaseRoomUser() {
    return { userId: "", readEventId: "" };
}
export const RoomUser = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.userId !== "") {
            writer.uint32(10).string(message.userId);
        }
        if (message.readEventId !== "") {
            writer.uint32(18).string(message.readEventId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.readEventId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            userId: isSet(object.userId) ? String(object.userId) : "",
            readEventId: isSet(object.readEventId) ? String(object.readEventId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.userId !== undefined && (obj.userId = message.userId);
        message.readEventId !== undefined &&
            (obj.readEventId = message.readEventId);
        return obj;
    },
};
function createBaseRoomStatusDto() {
    return { status: 0, displayReadyStatus: "" };
}
export const RoomStatusDto = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        if (message.displayReadyStatus !== "") {
            writer.uint32(18).string(message.displayReadyStatus);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomStatusDto();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.displayReadyStatus = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            status: isSet(object.status) ? roomStatusFromJSON(object.status) : 0,
            displayReadyStatus: isSet(object.displayReadyStatus)
                ? String(object.displayReadyStatus)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined &&
            (obj.status = roomStatusToJSON(message.status));
        message.displayReadyStatus !== undefined &&
            (obj.displayReadyStatus = message.displayReadyStatus);
        return obj;
    },
};
function createBaseCreateEventInput() {
    return {
        businessId: "",
        roomId: "",
        businessUserId: "",
        senderChannel: undefined,
        recipientChannel: undefined,
        connectlyEvent: undefined,
        processingOrder: undefined,
    };
}
export const CreateEventInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.roomId !== "") {
            writer.uint32(18).string(message.roomId);
        }
        if (message.businessUserId !== "") {
            writer.uint32(26).string(message.businessUserId);
        }
        if (message.senderChannel !== undefined) {
            ChannelIdentifier.encode(message.senderChannel, writer.uint32(34).fork()).ldelim();
        }
        if (message.recipientChannel !== undefined) {
            ChannelIdentifier.encode(message.recipientChannel, writer.uint32(42).fork()).ldelim();
        }
        if (message.connectlyEvent !== undefined) {
            ConnectlyEvent.encode(message.connectlyEvent, writer.uint32(50).fork()).ldelim();
        }
        if (message.processingOrder !== undefined) {
            EventProcessingOrderMetadata.encode(message.processingOrder, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateEventInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.roomId = reader.string();
                    break;
                case 3:
                    message.businessUserId = reader.string();
                    break;
                case 4:
                    message.senderChannel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.recipientChannel = ChannelIdentifier.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.connectlyEvent = ConnectlyEvent.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.processingOrder = EventProcessingOrderMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            businessUserId: isSet(object.businessUserId)
                ? String(object.businessUserId)
                : "",
            senderChannel: isSet(object.senderChannel)
                ? ChannelIdentifier.fromJSON(object.senderChannel)
                : undefined,
            recipientChannel: isSet(object.recipientChannel)
                ? ChannelIdentifier.fromJSON(object.recipientChannel)
                : undefined,
            connectlyEvent: isSet(object.connectlyEvent)
                ? ConnectlyEvent.fromJSON(object.connectlyEvent)
                : undefined,
            processingOrder: isSet(object.processingOrder)
                ? EventProcessingOrderMetadata.fromJSON(object.processingOrder)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.businessUserId !== undefined &&
            (obj.businessUserId = message.businessUserId);
        message.senderChannel !== undefined &&
            (obj.senderChannel = message.senderChannel
                ? ChannelIdentifier.toJSON(message.senderChannel)
                : undefined);
        message.recipientChannel !== undefined &&
            (obj.recipientChannel = message.recipientChannel
                ? ChannelIdentifier.toJSON(message.recipientChannel)
                : undefined);
        message.connectlyEvent !== undefined &&
            (obj.connectlyEvent = message.connectlyEvent
                ? ConnectlyEvent.toJSON(message.connectlyEvent)
                : undefined);
        message.processingOrder !== undefined &&
            (obj.processingOrder = message.processingOrder
                ? EventProcessingOrderMetadata.toJSON(message.processingOrder)
                : undefined);
        return obj;
    },
};
function createBaseUpdateRoomMetadataInput() {
    return {
        businessId: "",
        roomId: "",
        latestCustomerEventId: undefined,
        latestUserEventId: undefined,
        metadata: undefined,
    };
}
export const UpdateRoomMetadataInput = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        if (message.roomId !== "") {
            writer.uint32(18).string(message.roomId);
        }
        if (message.latestCustomerEventId !== undefined) {
            StringValue.encode({ value: message.latestCustomerEventId }, writer.uint32(26).fork()).ldelim();
        }
        if (message.latestUserEventId !== undefined) {
            StringValue.encode({ value: message.latestUserEventId }, writer.uint32(34).fork()).ldelim();
        }
        if (message.metadata !== undefined) {
            Metadata.encode(message.metadata, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateRoomMetadataInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                case 2:
                    message.roomId = reader.string();
                    break;
                case 3:
                    message.latestCustomerEventId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.latestUserEventId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.metadata = Metadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
            roomId: isSet(object.roomId) ? String(object.roomId) : "",
            latestCustomerEventId: isSet(object.latestCustomerEventId)
                ? String(object.latestCustomerEventId)
                : undefined,
            latestUserEventId: isSet(object.latestUserEventId)
                ? String(object.latestUserEventId)
                : undefined,
            metadata: isSet(object.metadata)
                ? Metadata.fromJSON(object.metadata)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        message.roomId !== undefined && (obj.roomId = message.roomId);
        message.latestCustomerEventId !== undefined &&
            (obj.latestCustomerEventId = message.latestCustomerEventId);
        message.latestUserEventId !== undefined &&
            (obj.latestUserEventId = message.latestUserEventId);
        message.metadata !== undefined &&
            (obj.metadata = message.metadata
                ? Metadata.toJSON(message.metadata)
                : undefined);
        return obj;
    },
};
function createBaseRoomEventMembershipInput() {
    return { memberOneof: undefined };
}
export const RoomEventMembershipInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b;
        if (((_a = message.memberOneof) === null || _a === void 0 ? void 0 : _a.$case) === "systemRoom") {
            RoomEventMembershipInput_SystemRoom.encode(message.memberOneof.systemRoom, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.memberOneof) === null || _b === void 0 ? void 0 : _b.$case) === "channelPair") {
            RoomEventMembershipInput_ChannelPair.encode(message.memberOneof.channelPair, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomEventMembershipInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.memberOneof = {
                        $case: "systemRoom",
                        systemRoom: RoomEventMembershipInput_SystemRoom.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.memberOneof = {
                        $case: "channelPair",
                        channelPair: RoomEventMembershipInput_ChannelPair.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            memberOneof: isSet(object.systemRoom)
                ? {
                    $case: "systemRoom",
                    systemRoom: RoomEventMembershipInput_SystemRoom.fromJSON(object.systemRoom),
                }
                : isSet(object.channelPair)
                    ? {
                        $case: "channelPair",
                        channelPair: RoomEventMembershipInput_ChannelPair.fromJSON(object.channelPair),
                    }
                    : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f;
        const obj = {};
        ((_a = message.memberOneof) === null || _a === void 0 ? void 0 : _a.$case) === "systemRoom" &&
            (obj.systemRoom = ((_b = message.memberOneof) === null || _b === void 0 ? void 0 : _b.systemRoom)
                ? RoomEventMembershipInput_SystemRoom.toJSON((_c = message.memberOneof) === null || _c === void 0 ? void 0 : _c.systemRoom)
                : undefined);
        ((_d = message.memberOneof) === null || _d === void 0 ? void 0 : _d.$case) === "channelPair" &&
            (obj.channelPair = ((_e = message.memberOneof) === null || _e === void 0 ? void 0 : _e.channelPair)
                ? RoomEventMembershipInput_ChannelPair.toJSON((_f = message.memberOneof) === null || _f === void 0 ? void 0 : _f.channelPair)
                : undefined);
        return obj;
    },
};
function createBaseRoomEventMembershipInput_ChannelPair() {
    return { senderChannel: undefined, recipientChannel: undefined };
}
export const RoomEventMembershipInput_ChannelPair = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.senderChannel !== undefined) {
            ChannelSelector.encode(message.senderChannel, writer.uint32(10).fork()).ldelim();
        }
        if (message.recipientChannel !== undefined) {
            ChannelSelector.encode(message.recipientChannel, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomEventMembershipInput_ChannelPair();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.senderChannel = ChannelSelector.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.recipientChannel = ChannelSelector.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            senderChannel: isSet(object.senderChannel)
                ? ChannelSelector.fromJSON(object.senderChannel)
                : undefined,
            recipientChannel: isSet(object.recipientChannel)
                ? ChannelSelector.fromJSON(object.recipientChannel)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.senderChannel !== undefined &&
            (obj.senderChannel = message.senderChannel
                ? ChannelSelector.toJSON(message.senderChannel)
                : undefined);
        message.recipientChannel !== undefined &&
            (obj.recipientChannel = message.recipientChannel
                ? ChannelSelector.toJSON(message.recipientChannel)
                : undefined);
        return obj;
    },
};
function createBaseRoomEventMembershipInput_SystemRoom() {
    return { businessId: "" };
}
export const RoomEventMembershipInput_SystemRoom = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.businessId !== "") {
            writer.uint32(10).string(message.businessId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoomEventMembershipInput_SystemRoom();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.businessId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            businessId: isSet(object.businessId) ? String(object.businessId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.businessId !== undefined && (obj.businessId = message.businessId);
        return obj;
    },
};
function createBaseIntegrationsWorkflowInput() {
    return { integrationsWorkflowInputOneof: undefined };
}
export const IntegrationsWorkflowInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a, _b, _c;
        if (((_a = message.integrationsWorkflowInputOneof) === null || _a === void 0 ? void 0 : _a.$case) ===
            "installIntegrationsInput") {
            InstallIntegrationsInput.encode(message.integrationsWorkflowInputOneof.installIntegrationsInput, writer.uint32(10).fork()).ldelim();
        }
        if (((_b = message.integrationsWorkflowInputOneof) === null || _b === void 0 ? void 0 : _b.$case) ===
            "migrateIntegrationsInput") {
            MigrateIntegrationsInput.encode(message.integrationsWorkflowInputOneof.migrateIntegrationsInput, writer.uint32(18).fork()).ldelim();
        }
        if (((_c = message.integrationsWorkflowInputOneof) === null || _c === void 0 ? void 0 : _c.$case) ===
            "uninstallIntegrationsInput") {
            UninstallIntegrationsInput.encode(message.integrationsWorkflowInputOneof.uninstallIntegrationsInput, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIntegrationsWorkflowInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.integrationsWorkflowInputOneof = {
                        $case: "installIntegrationsInput",
                        installIntegrationsInput: InstallIntegrationsInput.decode(reader, reader.uint32()),
                    };
                    break;
                case 2:
                    message.integrationsWorkflowInputOneof = {
                        $case: "migrateIntegrationsInput",
                        migrateIntegrationsInput: MigrateIntegrationsInput.decode(reader, reader.uint32()),
                    };
                    break;
                case 3:
                    message.integrationsWorkflowInputOneof = {
                        $case: "uninstallIntegrationsInput",
                        uninstallIntegrationsInput: UninstallIntegrationsInput.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            integrationsWorkflowInputOneof: isSet(object.installIntegrationsInput)
                ? {
                    $case: "installIntegrationsInput",
                    installIntegrationsInput: InstallIntegrationsInput.fromJSON(object.installIntegrationsInput),
                }
                : isSet(object.migrateIntegrationsInput)
                    ? {
                        $case: "migrateIntegrationsInput",
                        migrateIntegrationsInput: MigrateIntegrationsInput.fromJSON(object.migrateIntegrationsInput),
                    }
                    : isSet(object.uninstallIntegrationsInput)
                        ? {
                            $case: "uninstallIntegrationsInput",
                            uninstallIntegrationsInput: UninstallIntegrationsInput.fromJSON(object.uninstallIntegrationsInput),
                        }
                        : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const obj = {};
        ((_a = message.integrationsWorkflowInputOneof) === null || _a === void 0 ? void 0 : _a.$case) ===
            "installIntegrationsInput" &&
            (obj.installIntegrationsInput = ((_b = message.integrationsWorkflowInputOneof) === null || _b === void 0 ? void 0 : _b.installIntegrationsInput)
                ? InstallIntegrationsInput.toJSON((_c = message.integrationsWorkflowInputOneof) === null || _c === void 0 ? void 0 : _c.installIntegrationsInput)
                : undefined);
        ((_d = message.integrationsWorkflowInputOneof) === null || _d === void 0 ? void 0 : _d.$case) ===
            "migrateIntegrationsInput" &&
            (obj.migrateIntegrationsInput = ((_e = message.integrationsWorkflowInputOneof) === null || _e === void 0 ? void 0 : _e.migrateIntegrationsInput)
                ? MigrateIntegrationsInput.toJSON((_f = message.integrationsWorkflowInputOneof) === null || _f === void 0 ? void 0 : _f.migrateIntegrationsInput)
                : undefined);
        ((_g = message.integrationsWorkflowInputOneof) === null || _g === void 0 ? void 0 : _g.$case) ===
            "uninstallIntegrationsInput" &&
            (obj.uninstallIntegrationsInput = ((_h = message.integrationsWorkflowInputOneof) === null || _h === void 0 ? void 0 : _h.uninstallIntegrationsInput)
                ? UninstallIntegrationsInput.toJSON((_j = message.integrationsWorkflowInputOneof) === null || _j === void 0 ? void 0 : _j.uninstallIntegrationsInput)
                : undefined);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
