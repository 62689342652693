export const SETTINGS_CATEGORY_GENERAL = 'general';
export const SETTINGS_CATEGORY_BILLING = 'billing';

export const MENU_ITEMS = [
  {
    key: SETTINGS_CATEGORY_GENERAL,
    title: 'General',
    url: '',
  },
  {
    key: SETTINGS_CATEGORY_BILLING,
    title: 'Billing',
    url: '',
  },
];
