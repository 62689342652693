import React from 'react';
import { Box, useTheme, Slide } from '@connectlyai-tenets/ui-styled-web';
import { useAtomValue } from 'jotai';
import { selectedNodeAtom } from '@atoms/flow';
import { NodeType } from '../../../../sdks/flow/createNode';
import { nodesMap } from './constants';

const useNodeEditor = () => {
  const node = useAtomValue(selectedNodeAtom);
  if (node === undefined) {
    return null;
  }
  const comp = nodesMap.get(node.type as NodeType);
  if (comp === undefined) {
    return null;
  }
  return comp;
};

export const NodeEditor = () => {
  const editorComponent = useNodeEditor();
  const theme = useTheme();

  return (
    <Slide
      in={!!editorComponent}
      direction="right"
      timeout={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: theme.palette.background.paper,
          willChange: 'transform',
        }}
      >
        {editorComponent}
      </Box>
    </Slide>
  );
};
