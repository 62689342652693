import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { CsvRow } from './types';
import { useTableDataInRanges } from '../../hooks/useTableDataInRanges/useTableDataInRanges';
import { selectAnalyticsExportData } from '../../selectors';
import { createCsvData, downloadCsv } from './utils';
import { DataExport } from '../DataTable/types';

const mapDataItemToCsvRow = (item: DataExport): CsvRow => {
  return [
    item.customerExternalId,
    item.createdAt,
    item.numTurns,
    item.countMessages,
    item.countAssistantMessages,
    item.messageText,
    item.sentiment,
  ];
};
const useExportData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const { currentData } = useTableDataInRanges({});
  const data = useMemo(() => {
    return selectAnalyticsExportData(currentData);
  }, [currentData]);
  const header: CsvRow = useMemo(() => {
    return [
      t('columns.customerExternalId'),
      t('columns.createdAt'),
      t('columns.numTurns'),
      t('columns.countMessages'),
      t('columns.countAssistantMessages'),
      t('columns.messageText'),
      t('columns.sentiment'),
    ];
  }, [t]);
  const csvData = useMemo(() => {
    return createCsvData({ data: data || [], mapToDisplay: mapDataItemToCsvRow, header });
  }, [data, header]);
  const handleDownload = useCallback(() => {
    downloadCsv(csvData, 'sofia-analytics.csv');
  }, [csvData]);
  const theme = useTheme();
  return {
    data,
    theme,
    text: 'Export Data',
    handleDownload,
  };
};

export const ExportData = () => {
  const { text, handleDownload, theme, data } = useExportData();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      sx={{ width: 'fit-content', minWidth: theme.spacing(20), height: theme.spacing(4.75), fontSize: '12px' }}
      disabled={!data?.length}
    >
      {text}
    </Button>
  );
};
