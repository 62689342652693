import { useMemo } from 'react';
import { useFeatureFlag, selectBusinessId, useSettingsData, useMeData, selectSofiaLiteIntegrationId } from '@hooks';
import { useAgentDescriptor, selectDeployedAgentDescriptorId } from '../useAgentDescriptor';

export const useSofiaEnabled = () => {
  const { ffEnableAINode } = useFeatureFlag(['ffEnableAINode']);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: sofiaIntegrationId } = useSettingsData({
    businessId: businessId || '',
    select: selectSofiaLiteIntegrationId,
  });
  const { data: agentId } = useAgentDescriptor({
    businessId: businessId || '',
    select: selectDeployedAgentDescriptorId,
  });
  const aiNodeEnabled = useMemo(() => {
    return ffEnableAINode || Boolean(sofiaIntegrationId) || Boolean(agentId);
  }, [agentId, ffEnableAINode, sofiaIntegrationId]);
  return aiNodeEnabled;
};
