import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  AUDIENCE_SUBPAGE_OPT_OUT,
  AUDIENCE_SUBPAGE_TEMPLATES,
  AUDIENCE_SUBPAGE_MANAGE,
  generateAudienceCategoryRoute,
  generateAudienceRoute,
} from '@connectlyai-sdks/inbox-navigation';
import { NavigationContext } from '../../../../contexts';

export const useNavigator = () => {
  const homeRoute = generateAudienceRoute();
  const manageRoute = generateAudienceCategoryRoute({ categoryId: AUDIENCE_SUBPAGE_MANAGE });
  const templatesRoute = generateAudienceCategoryRoute({ categoryId: AUDIENCE_SUBPAGE_TEMPLATES });
  const optOutRoute = generateAudienceCategoryRoute({ categoryId: AUDIENCE_SUBPAGE_OPT_OUT });
  const navigatorProvider = useContextSelector(NavigationContext, (context) => context.navigatorProviderForAudience);
  const routes = useMemo(() => {
    return {
      home: {
        pathname: navigatorProvider().generateGlobalPath(homeRoute),
        navigate: () => navigatorProvider().navigate(homeRoute),
      },
      manage: {
        pathname: navigatorProvider().generateGlobalPath(manageRoute),
        navigate: () => navigatorProvider().navigate(manageRoute),
      },
      templates: {
        pathname: navigatorProvider().generateGlobalPath(templatesRoute),
        navigate: () => navigatorProvider().navigate(templatesRoute),
      },
      optOut: {
        pathname: navigatorProvider().generateGlobalPath(optOutRoute),
        navigate: () => navigatorProvider().navigate(optOutRoute),
      },
    };
  }, [homeRoute, manageRoute, navigatorProvider, optOutRoute, templatesRoute]);

  return { routes };
};
