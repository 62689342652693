import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "models.inbox";
export var ComposerSendBehavior;
(function (ComposerSendBehavior) {
    ComposerSendBehavior[ComposerSendBehavior["COMPOSER_SEND_BEHAVIOR_UNSPECIFIED"] = 0] = "COMPOSER_SEND_BEHAVIOR_UNSPECIFIED";
    ComposerSendBehavior[ComposerSendBehavior["COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND"] = 1] = "COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND";
    ComposerSendBehavior[ComposerSendBehavior["COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND"] = 2] = "COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND";
    ComposerSendBehavior[ComposerSendBehavior["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ComposerSendBehavior || (ComposerSendBehavior = {}));
export function composerSendBehaviorFromJSON(object) {
    switch (object) {
        case 0:
        case "COMPOSER_SEND_BEHAVIOR_UNSPECIFIED":
            return ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED;
        case 1:
        case "COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND":
            return ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND;
        case 2:
        case "COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND":
            return ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ComposerSendBehavior.UNRECOGNIZED;
    }
}
export function composerSendBehaviorToJSON(object) {
    switch (object) {
        case ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED:
            return "COMPOSER_SEND_BEHAVIOR_UNSPECIFIED";
        case ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND:
            return "COMPOSER_SEND_BEHAVIOR_CLICK_TO_SEND";
        case ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND:
            return "COMPOSER_SEND_BEHAVIOR_ENTER_TO_SEND";
        case ComposerSendBehavior.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var InboxExperience;
(function (InboxExperience) {
    InboxExperience[InboxExperience["INBOX_EXPERIENCE_UNSPECIFIED"] = 0] = "INBOX_EXPERIENCE_UNSPECIFIED";
    InboxExperience[InboxExperience["INBOX_EXPERIENCE_FULL"] = 1] = "INBOX_EXPERIENCE_FULL";
    InboxExperience[InboxExperience["INBOX_EXPERIENCE_SHOPIFY_FOCUS"] = 2] = "INBOX_EXPERIENCE_SHOPIFY_FOCUS";
    InboxExperience[InboxExperience["INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS"] = 3] = "INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS";
    InboxExperience[InboxExperience["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(InboxExperience || (InboxExperience = {}));
export function inboxExperienceFromJSON(object) {
    switch (object) {
        case 0:
        case "INBOX_EXPERIENCE_UNSPECIFIED":
            return InboxExperience.INBOX_EXPERIENCE_UNSPECIFIED;
        case 1:
        case "INBOX_EXPERIENCE_FULL":
            return InboxExperience.INBOX_EXPERIENCE_FULL;
        case 2:
        case "INBOX_EXPERIENCE_SHOPIFY_FOCUS":
            return InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS;
        case 3:
        case "INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS":
            return InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return InboxExperience.UNRECOGNIZED;
    }
}
export function inboxExperienceToJSON(object) {
    switch (object) {
        case InboxExperience.INBOX_EXPERIENCE_UNSPECIFIED:
            return "INBOX_EXPERIENCE_UNSPECIFIED";
        case InboxExperience.INBOX_EXPERIENCE_FULL:
            return "INBOX_EXPERIENCE_FULL";
        case InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS:
            return "INBOX_EXPERIENCE_SHOPIFY_FOCUS";
        case InboxExperience.INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS:
            return "INBOX_EXPERIENCE_SHOPIFY_FOCUS_PLUS";
        case InboxExperience.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseInboxSettingsV1() {
    return { composerSendBehavior: 0 };
}
export const InboxSettingsV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.composerSendBehavior !== 0) {
            writer.uint32(8).int32(message.composerSendBehavior);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInboxSettingsV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.composerSendBehavior = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            composerSendBehavior: isSet(object.composerSendBehavior)
                ? composerSendBehaviorFromJSON(object.composerSendBehavior)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.composerSendBehavior !== undefined &&
            (obj.composerSendBehavior = composerSendBehaviorToJSON(message.composerSendBehavior));
        return obj;
    },
};
function createBaseInboxSettings() {
    return { inboxSettingsOneof: undefined };
}
export const InboxSettings = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.inboxSettingsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            InboxSettingsV1.encode(message.inboxSettingsOneof.v1, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInboxSettings();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.inboxSettingsOneof = {
                        $case: "v1",
                        v1: InboxSettingsV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inboxSettingsOneof: isSet(object.v1)
                ? { $case: "v1", v1: InboxSettingsV1.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.inboxSettingsOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.inboxSettingsOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? InboxSettingsV1.toJSON((_c = message.inboxSettingsOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function createBaseInboxSettingsInput() {
    return { inboxSettingsInputOneof: undefined };
}
export const InboxSettingsInput = {
    encode(message, writer = _m0.Writer.create()) {
        var _a;
        if (((_a = message.inboxSettingsInputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1") {
            InboxSettingsV1.encode(message.inboxSettingsInputOneof.v1, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInboxSettingsInput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.inboxSettingsInputOneof = {
                        $case: "v1",
                        v1: InboxSettingsV1.decode(reader, reader.uint32()),
                    };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            inboxSettingsInputOneof: isSet(object.v1)
                ? { $case: "v1", v1: InboxSettingsV1.fromJSON(object.v1) }
                : undefined,
        };
    },
    toJSON(message) {
        var _a, _b, _c;
        const obj = {};
        ((_a = message.inboxSettingsInputOneof) === null || _a === void 0 ? void 0 : _a.$case) === "v1" &&
            (obj.v1 = ((_b = message.inboxSettingsInputOneof) === null || _b === void 0 ? void 0 : _b.v1)
                ? InboxSettingsV1.toJSON((_c = message.inboxSettingsInputOneof) === null || _c === void 0 ? void 0 : _c.v1)
                : undefined);
        return obj;
    },
};
function createBaseInboxConfigV1() {
    return { experience: 0 };
}
export const InboxConfigV1 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.experience !== 0) {
            writer.uint32(8).int32(message.experience);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInboxConfigV1();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.experience = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            experience: isSet(object.experience)
                ? inboxExperienceFromJSON(object.experience)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.experience !== undefined &&
            (obj.experience = inboxExperienceToJSON(message.experience));
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
