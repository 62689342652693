import { IntegrationMetricsResponse } from '../../hooks/useIntegrationMetricsData';
import { MetricInfo } from './types';

export const PLACEHOLDER_DATA: IntegrationMetricsResponse = {
  entity: {
    metrics: [
      {
        metricName: 'delivered_total',
        current: {
          scalar: {
            value: 1000,
          },
        },
        change: {
          scalar: {
            value: 10,
          },
        },
      },
      {
        metricName: 'read_total',
        current: {
          scalar: {
            value: 918,
          },
        },
        change: {
          scalar: {
            value: 5.13,
          },
        },
      },
      {
        metricName: 'conversion_total',
        current: {
          scalar: {
            value: 805,
          },
        },
        change: {
          scalar: {
            value: 7.29,
          },
        },
      },
      {
        metricName: 'conversion_value_total',
        current: {
          scalar: {
            value: 16142,
            unit: {
              family: 'UNIT_FAMILY_CURRENCY',
              shortName: 'USD',
              symbol: '$',
            },
          },
        },
        change: {
          scalar: {
            value: 9,
          },
        },
      },
      {
        metricName: 'total_clicks',
        current: {
          scalar: {
            value: 1409,
          },
        },
        change: {
          scalar: {
            value: 6.3,
          },
        },
      },
      {
        metricName: 'unique_clicks',
        current: {
          scalar: {
            value: 926,
          },
        },
        change: {
          scalar: {
            value: 4.2,
          },
        },
      },
      {
        metricName: 'click_through_conversion_total',
        current: {
          scalar: {
            value: 725,
          },
        },
        change: {
          scalar: {
            value: 3.95,
          },
        },
      },
      {
        metricName: 'click_through_conversion_value_total',
        current: {
          scalar: {
            value: 14428,
            unit: {
              family: 'UNIT_FAMILY_CURRENCY',
              shortName: 'USD',
              symbol: '$',
            },
          },
        },
        change: {
          scalar: {
            value: 8.2,
          },
        },
      },
    ],
  },
};

export const METRIC_INFO: MetricInfo = {
  delivered_total: {},
  read_total: {
    rateDenominator: 'delivered_total',
  },
  conversion_total: {
    rateDenominator: 'delivered_total',
  },
  conversion_value_total: {},
  total_clicks: {},
  unique_clicks: {},
  click_through_conversion_total: {},
  click_through_conversion_value_total: {},
};
