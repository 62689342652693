import { useMutation } from '@tanstack/react-query';
import { UpdateBusinessProfileOptions, UpdateBusinessProfileMutation, UpdateBusinessProfileResponse } from './types';
import { api } from '../../../../api';

export const useUpdateBusinessProfileMutation = (options?: UpdateBusinessProfileOptions) => {
  return useMutation(
    (input: UpdateBusinessProfileMutation) => {
      const { businessId, ...entity } = input;
      return api.profileV1('/v1/businesses/{entity.id}/profile', 'patch', {
        path: { 'entity.id': businessId },
        body: { entity },
      }) as Promise<UpdateBusinessProfileResponse>;
    },
    {
      ...options,
    },
  );
};
