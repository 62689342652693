import React, { FC } from 'react';
import { Box, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { VariableType } from '@hooks/useFlowVariables/types';
import { useFlowVariables } from '@hooks';

export const TextVariable: FC<{
  variable: string | React.ReactNode;
  lineHeight?: number;
  variableType: VariableType;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}> = ({ variable, lineHeight, variableType, style, icon }) => {
  const colors =
    variableType === 'SESSION'
      ? {
          backgroundColor: '#ECE7F5',
          color: '#2E1C8C',
        }
      : {
          backgroundColor: '#EAF5EA',
          color: '#2F5D28',
        };

  const { trimCurly } = useFlowVariables();
  const theme = useTheme();
  return (
    <Box
      component="span"
      style={{
        alignItems: 'center',
        display: 'inline-flex',
        height: lineHeight ? `${lineHeight}px` : undefined,
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75),
        gap: theme.spacing(0.5),
        borderRadius: '4px',
        cursor: 'pointer',
        ...colors,
        ...style,
      }}
    >
      {icon}
      {typeof variable === 'string' ? trimCurly(variable) : variable}
    </Box>
  );
};
