import { ComposerSendBehavior } from '@connectlyai-idl/models/dist/models/inbox/models';
import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@connectlyai-sdks/loading';
import { fetchUser } from './thunk';
import { setInboxSettings, setUserProfile } from './action';
function mapInboxSettingsToSettings(dto) {
    var _a, _b;
    if (!dto) {
        return dto;
    }
    return {
        clickToSend: ((_b = (_a = dto === null || dto === void 0 ? void 0 : dto.inboxSettingsOneof) === null || _a === void 0 ? void 0 : _a.v1) === null || _b === void 0 ? void 0 : _b.composerSendBehavior) || ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED,
    };
}
function mapAuthedUserPresentationToUser(userPresentation) {
    var _a, _b;
    const { dto, accessToken } = userPresentation;
    const business = dto.businesses[0];
    return {
        id: dto.id,
        username: dto.username,
        firstName: '',
        lastName: '',
        displayName: dto.displayName,
        avatarUrl: '',
        business: {
            id: (_a = business === null || business === void 0 ? void 0 : business.id) !== null && _a !== void 0 ? _a : '',
            name: (_b = business === null || business === void 0 ? void 0 : business.name) !== null && _b !== void 0 ? _b : '',
        },
        accessToken,
        settings: mapInboxSettingsToSettings(dto.inboxSettings),
        experiments: dto.experiments,
    };
}
const initialState = {
    status: 'empty',
    loading: LoadingState.UNINITIALIZED,
    timestamp: 0,
    user: null,
    unauthorized: null,
};
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state, _action) => {
            if (state.timestamp === 0) {
                state.status = 'loading';
                state.loading = LoadingState.FULL_LOADING;
            }
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.loading = LoadingState.DATA;
            state.timestamp += 1;
            state.user = mapAuthedUserPresentationToUser(action.payload);
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.status = 'failed';
            state.loading = LoadingState.FULL_ERROR;
            const unauthorizedUser = action.payload;
            if ((unauthorizedUser === null || unauthorizedUser === void 0 ? void 0 : unauthorizedUser.method) === 'sso' && (unauthorizedUser === null || unauthorizedUser === void 0 ? void 0 : unauthorizedUser.email)) {
                state.unauthorized = unauthorizedUser;
            }
            else {
                state.unauthorized = {};
            }
        });
        builder.addCase(setInboxSettings, (state, action) => {
            var _a;
            const { user } = state;
            if (user) {
                user.settings = {
                    clickToSend: ((_a = action.payload.inboxSettingsOneof) === null || _a === void 0 ? void 0 : _a.v1.composerSendBehavior) ||
                        ComposerSendBehavior.COMPOSER_SEND_BEHAVIOR_UNSPECIFIED,
                };
            }
        });
        builder.addCase(setUserProfile, (state, action) => {
            const { user } = state;
            if (user) {
                user.displayName = action.payload.displayName;
            }
        });
    },
});
export const { reset, setUser } = userSlice.actions;
