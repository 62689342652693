import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateShopifyProfileByOptions } from '../entities';
import { resetUpdate, setCustomerInput, setNotesError, setNotesInput } from './action';
export const setCustomerProfileInput = createAsyncThunk('@customer/setCustomerProfileInput', (arg, { getState, dispatch }) => {
    const state = getState();
    dispatch(setCustomerInput({
        isActive: arg.isActive,
        firstName: arg.firstName || '',
        lastName: arg.lastName || '',
        email: arg.email || state.customerSlice.description.email,
        phone: arg.phone || state.customerSlice.description.phone,
    }));
});
export const updateCustomerProfile = createAsyncThunk('@customer/updateCustomerProfile', async ({ di, setUpdateCustomerProfileIsLoading }, { getState }) => {
    const mutationUpdateCustomerProfile = di.mutationUpdateCustomerProfileProvider();
    const state = getState();
    try {
        setUpdateCustomerProfileIsLoading(true);
        const updateSlice = state.updateCustomerProfileSlice;
        const { customerId } = di.config;
        const result = await mutationUpdateCustomerProfile({
            businessId: di.config.businessId,
            customerId,
            firstName: updateSlice.firstName || '',
            lastName: updateSlice.lastName || '',
            email: updateSlice.email || '',
            phone: updateSlice.phone || '',
        });
        const customerDataStore = di.customerDataStoreProvider();
        customerDataStore.eventRequestRefreshCustomer(customerId);
        return result;
    }
    finally {
        setUpdateCustomerProfileIsLoading(false);
    }
});
export const searchShopifyCustomerChannels = createAsyncThunk('@customer/searchShopifyCustomerChannels', async ({ di, setUpdateIsLoading }, { getState }) => {
    const querySearchShopifyCustomerChannels = di.querySearchShopifyCustomerChannelsProvider();
    const state = getState().updateShopifyProfileSlice;
    const { updateShopifyProfileByOption } = state;
    const queryFields = updateShopifyProfileByOption !== null
        ? UpdateShopifyProfileByOptions[updateShopifyProfileByOption].backendQueryFields.map((field) => field)
        : [''];
    try {
        setUpdateIsLoading(true);
        return await querySearchShopifyCustomerChannels({
            businessId: di.config.businessId,
            queryFields,
            queryValues: [state.searchInput],
        });
    }
    finally {
        setUpdateIsLoading(false);
    }
});
export const mergeCustomers = createAsyncThunk('@customer/mergeCustomers', async ({ di, setMergeIsLoading }, { getState, dispatch }) => {
    const mutationMergeCustomers = di.mutationMergeCustomersProvider();
    const state = getState().updateShopifyProfileSlice;
    const { updatePotentialMatchCustomerId } = state;
    if (updatePotentialMatchCustomerId) {
        setMergeIsLoading(true);
        const targetCustomerId = di.config.customerId;
        try {
            await mutationMergeCustomers({
                businessId: di.config.businessId,
                sourceCustomerId: updatePotentialMatchCustomerId,
                targetCustomerId,
            });
            const customerDataStore = di.customerDataStoreProvider();
            customerDataStore.eventRequestRefreshCustomer(targetCustomerId);
        }
        finally {
            setMergeIsLoading(false);
        }
    }
    else {
        dispatch(resetUpdate());
    }
});
export const setNotesTextInput = createAsyncThunk('@customer/setNotesInput', ({ text }, { getState, dispatch }) => {
    const state = getState();
    dispatch(setNotesInput({
        isActive: text !== state.customerSlice.notesProfile.text,
        textInput: text,
    }));
});
export const updateCustomerNotes = createAsyncThunk('@customer/updateCustomerNotes', async ({ di, setUpdateIsLoading }, { getState, dispatch }) => {
    const mutationUpdateCustomerNotes = di.mutationUpdateCustomerNotesProvider();
    const state = getState();
    if (state.updateNotesProfileSlice.textInput !== null) {
        try {
            setUpdateIsLoading(true);
            const targetCustomerId = di.config.customerId;
            const result = await mutationUpdateCustomerNotes({
                businessId: di.config.businessId,
                customerId: di.config.customerId,
                notes: state.updateNotesProfileSlice.textInput,
            });
            const customerDataStore = di.customerDataStoreProvider();
            customerDataStore.eventRequestRefreshCustomer(targetCustomerId);
            return result;
        }
        finally {
            setUpdateIsLoading(false);
        }
    }
    else {
        dispatch(setNotesError({
            isError: true,
            errorMsg: 'Error: Please try again.',
        }));
        return {
            textInput: null,
        };
    }
});
