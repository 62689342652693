import React, { useEffect, useRef, useState, ChangeEvent, useCallback, useMemo } from 'react';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { AddVariable } from '@components/AddVariable';
import {
  Box,
  Button,
  IconButton,
  Label,
  useTheme,
  TextField,
  FormControl,
  DeleteOutlineIcon,
  Grid,
  CloseIcon,
  ArrowBackIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@connectlyai-tenets/ui-styled-web';
import { CodeMirrorTextField } from '@components/CodeMirrorTextField';
import { FlowVariableDialog, FlowVariableDialogRef } from '@components/FlowVariableDialog';
import { VARIABLE_REGEX } from '@hooks/useFlowVariables/constants';
import { ChangeParameterValue, ParametersDialogProps, APICallParameter } from './types';
import { SIZE_CONTENT_XL } from '../../../../ui-theme';

const useParametersDialog = ({
  parameters,
  handleChangeValue,
  handleDelete,
}: {
  parameters: APICallParameter[];
  handleChangeValue: ChangeParameterValue;
  handleDelete: (index: number) => void;
}) => {
  const valueCodeMirrorRefs = useRef<ReactCodeMirrorRef[]>([]);
  const flowDialogCodeMirrorRef = useRef<ReactCodeMirrorRef>({});
  const varDialogRef = useRef<FlowVariableDialogRef>(null);
  const valueContainerRefs = React.useRef<HTMLDivElement[]>([]);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleOpenDialog = useCallback(
    (index: number) => {
      setOpenIndex(index);
      varDialogRef.current?.handleOpenDialog();
    },
    [varDialogRef],
  );

  const handleDialogChange = useCallback(
    (value: string) => {
      if (openIndex === null) return;
      handleChangeValue(value, openIndex);
    },
    [handleChangeValue, openIndex],
  );

  const anchorEl: HTMLDivElement | null = useMemo(() => {
    if (openIndex === null) return null;
    return valueContainerRefs?.current[openIndex];
  }, [openIndex, valueContainerRefs]);

  const flowDialogValue: string = useMemo(() => {
    if (openIndex === null) return '';
    return parameters[openIndex]?.value?.value;
  }, [openIndex, parameters]);

  const handleUpdateValue = useCallback(
    (value: string, index: number) => {
      if (openIndex !== index) setOpenIndex(index);
      // remove variables since api node does not support mixed text and variables right now
      const variablesRemoved = value.replace(VARIABLE_REGEX, '');
      handleChangeValue(variablesRemoved, index);
    },
    [openIndex, handleChangeValue],
  );

  const handleDeleteParameter = useCallback(
    (index: number) => {
      setOpenIndex(null);
      handleDelete(index);
    },
    [handleDelete],
  );

  useEffect(() => {
    if (openIndex === null) return;
    flowDialogCodeMirrorRef.current = valueCodeMirrorRefs?.current[openIndex];
  }, [openIndex]);

  return {
    handleOpenDialog,
    handleDeleteParameter,
    handleDialogChange,
    handleUpdateValue,
    valueContainerRefs,
    valueCodeMirrorRefs,
    flowDialogCodeMirrorRef,
    anchorEl,
    varDialogRef,
    flowDialogValue,
    openIndex,
  };
};

export const ParametersDialog = ({
  parameter,
  title,
  open,
  parameters,
  handleAdd,
  handleClose,
  handleDelete,
  handleChangeKey,
  handleChangeValue,
  addLabel,
  keyLabel,
  valueLabel,
}: ParametersDialogProps) => {
  const {
    handleOpenDialog,
    handleDialogChange,
    handleUpdateValue,
    handleDeleteParameter,
    varDialogRef,
    valueContainerRefs,
    valueCodeMirrorRefs,
    flowDialogCodeMirrorRef,
    anchorEl,
    flowDialogValue,
  } = useParametersDialog({
    parameters,
    handleChangeValue,
    handleDelete,
  });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="xl">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton size="small" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Label variant="h5" fontWeight={500}>
          {title}
        </Label>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: theme.spacing(SIZE_CONTENT_XL), display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            px: isLargeScreen ? 3 : 2,
            flex: '1 1 auto',
            gap: isLargeScreen ? 3 : 2,
            overflowY: 'scroll',
          }}
        >
          {open && (
            <Grid container spacing={1}>
              {parameters.map((param, index) => {
                return (
                  <>
                    <Grid item xs={3.5}>
                      <FormControl variant="outlined" margin="normal" sx={{ my: 0 }} fullWidth>
                        <Label variant="body1" sx={{ color: theme.palette.text.secondary, wordBreak: 'break-word' }}>
                          {keyLabel}
                        </Label>
                        <TextField
                          id={`body-${parameter}-key-${index}`}
                          autoComplete="off"
                          placeholder={keyLabel}
                          error={Boolean(param.key.error)}
                          helperText={param.key.error}
                          value={param.key.value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeKey(e, index)}
                          sx={{
                            '& .MuiInputBase-root': { borderRadius: '10px' },
                            '& .MuiInputBase-input': { px: 1.5, py: 1.5 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        ref={(container: HTMLDivElement) => {
                          valueContainerRefs.current[index] = container;
                        }}
                      >
                        <Label variant="body1" sx={{ color: theme.palette.text.secondary, wordBreak: 'break-word' }}>
                          {valueLabel}
                        </Label>
                        <CodeMirrorTextField
                          value={param.value.value}
                          editable
                          codeMirrorUpdate={varDialogRef?.current?.handleCodeMirrorUpdate}
                          error={Boolean(param.value.error)}
                          handleVariableClick={varDialogRef?.current?.handleVariableClick}
                          setValue={(value: string) => handleUpdateValue(value, index)}
                          ref={(r: ReactCodeMirrorRef) => {
                            valueCodeMirrorRefs.current[index] = r;
                          }}
                          containerStyle={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid rgb(192, 192, 192)',
                            borderRadius: '10px',
                            width: '100%',
                            padding: `${theme.spacing(0.8125)} 
                        ${theme.spacing(1.5)} 
                        ${theme.spacing(0.8125)} 
                        ${theme.spacing(0.5)}`,
                          }}
                          endAdornment={
                            <AddVariable sx={{ p: 0, flexGrow: 0 }} onClick={() => handleOpenDialog(index)} />
                          }
                        />
                        {param.value.error && (
                          <Label variant="body2" sx={{ color: 'error.main' }}>
                            {param.value.error}
                          </Label>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={0.5}>
                      <Label variant="body1" sx={{ color: theme.palette.text.secondary, wordBreak: 'break-word' }}>
                        &nbsp; {/* empty label for alignment */}
                      </Label>
                      <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', height: '100%' }}>
                        <IconButton size="medium" onClick={() => handleDeleteParameter(index)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </>
                );
              })}
              <FlowVariableDialog
                anchorEl={anchorEl}
                codeMirrorRef={flowDialogCodeMirrorRef}
                value={flowDialogValue}
                handleEditorChange={handleDialogChange}
                ref={varDialogRef}
                isSessionVariable
                clearOnChange
              />
              <Button
                id={`api-${parameter}body-add`}
                onClick={handleAdd}
                variant="outlined"
                color="secondary"
                sx={{ width: 'fit-content', m: 1 }}
              >
                {addLabel}
              </Button>
            </Grid>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
