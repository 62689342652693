import { createSlice } from '@reduxjs/toolkit';
import { resetStore } from './action';
import { tryQueryMessageTemplateGroups } from './thunk';
const initialState = {
    status: 'empty',
    groups: [],
    items: {
        ids: [],
        byId: {},
    },
};
function unrollMessageTemplateGroups(templateGroups) {
    const results = [];
    templateGroups.forEach((group) => {
        results.push(...group.templates.map((dto) => ({ dto, group })));
    });
    return results;
}
export const messageTemplateGroupSlice = createSlice({
    name: 'messageTemplateGroup',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => {
        });
        builder.addCase(tryQueryMessageTemplateGroups.pending, (state, _action) => {
            state.status = 'loading';
            state.groups = [];
            state.items = {
                ids: [],
                byId: {},
            };
        });
        builder.addCase(tryQueryMessageTemplateGroups.fulfilled, (state, action) => {
            state.status = 'success';
            state.groups = action.payload;
            state.items.ids = [];
            state.items.byId = {};
            unrollMessageTemplateGroups(action.payload).forEach((template) => {
                const { id } = template.dto;
                state.items.ids.push(id);
                state.items.byId[id] = template;
            });
        });
        builder.addCase(tryQueryMessageTemplateGroups.rejected, (state, _action) => {
            state.status = 'failure';
        });
    },
});
export const { reset } = messageTemplateGroupSlice.actions;
export const selectMessageTemplateGroups = (state) => state.messageTemplateGroupSlice.groups;
export default messageTemplateGroupSlice.reducer;
