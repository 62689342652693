export const VTEX_TRIGGER_SETUP_VIDEO_URL = 'https://screenpal.com/watch/c0XbqTVGpXX';

export const VTEX_CONNECTLY_WEBHOOK_URL = 'https://api.connectly.ai/v1/webhooks/vtex/customers';

export const VTEX_CONTENT_JSON = `
{
	"html_url": "{=UrlRegistro}",
	"isCorporate": "{!isCorporate}",
	"tradeName": "{!tradeName}",
	"rclastcart": "{!rclastcart}",
	"rclastcartvalue": {!rclastcartvalue},
	"rclastsession": "{!rclastsession}",
	"rclastsessiondate": "{!rclastsessiondate}",
	"homePhone": "{!homePhone}",
	"phone": "{!phone}",
	"stateRegistration": "{!stateRegistration}",
	"email": "{!email}",
	"userId": "{!userId}",
	"firstName": "{!firstName}",
	"lastName": "{!lastName}",
	"document": "{!document}",
	"isNewsletterOptIn": "{!isNewsletterOptIn}",
	"localeDefault": "{!localeDefault}",
	"attach": "{!attach}",
	"approved": "{!approved}",
	"birthDate": "{!birthDate}",
	"businessPhone": "{!businessPhone}",
	"corporateDocument": "{!corporateDocument}",
	"corporateName": "{!corporateName}",
	"documentType": "{!documentType}",
	"gender": "{!gender}",
	"customerClass": "{!customerClass}",
	"priceTables": "{!priceTables}",
	"profilePicture": "{!profilePicture}",
	"birthDateMonth": {!birthDateMonth},
	"id": "{!id}",
	"accountId": "{!accountId}",
	"accountName": "{!accountName}",
	"dataEntityId": "{!dataEntityId}",
	"createdBy": "{!createdBy}",
	"createdIn": "{!createdIn}",
	"updatedBy": "{!updatedBy}",
	"updatedIn": "{!updatedIn}",
	"lastInteractionBy": "{!lastInteractionBy}",
	"lastInteractionIn": "{!lastInteractionIn}"
}
`;
