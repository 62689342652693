import { useMemo } from 'react';
import { selectBusinessId, useMeData } from '@hooks';
import { useAtomValue } from 'jotai';
import { sofiaAnalysisTimeRangesAtom as timeRangesAtom } from '@atoms/sofia';
import { type SofiaAnalysisResponse } from 'src/scenes/Sofia/hooks/useAnalysisData/types';
import { type SofiaAnalyticsResponse } from 'src/scenes/Sofia/hooks/useTableAnalyticsData/types';
import { useAnalysisDataInRangesReturn, useAnalysisDataInRangesProps } from './types';
import { useTableAnalyticsData } from '../useTableAnalyticsData';

/*
  Input: SofiaAnalyticsData

  [{
      customerExternalId?: string;
      createdAt?: string;
      numTurns?: number;
      countMessages?: number;
      countAssistantMessages?: number;
      messageText?: string[];
      sentiment?: string;
      roomId?: string;
      journey?: string;
      keywords?: string[];
      topics?: string[];
  }];

  Output: SofiaAnalysisData

  [{
      businessId?: string;
      flowDocumentId?: string;
      flowDocumentVersionId?: string;
      bucket?: string;
      sessionCount?: number;
      messageCount?: number;
      sentimentStats?: [{
        sentiment?: string;
        count?: number;
        sessionCount?: number;
      }];
      journeyStats?: [{
        journey?: string;
        count?: number;
        sessionCount?: number;
      }];
      topicStats?: [{
        topic?: string;
        count?: number;
        sessionCount?: number;
      }];
      keywordStats?: [{
        keyword?: string;
        count?: number;
        sessionCount?: number;
      }];
  }];
*/

interface Accumulator {
  sessionCount: number;
  messageCount: number;
  sentimentCache: { [key: string]: { count: number; sessionCount: number } };
  journeyCache: { [key: string]: { count: number; sessionCount: number } };
  topicCache: { [key: string]: { count: number; sessionCount: number } };
  keywordCache: { [key: string]: { count: number; sessionCount: number } };
}

const rollUp = (input: SofiaAnalyticsResponse | undefined): SofiaAnalysisResponse | undefined => {
  const defaultBucket = {
    sessionCount: 0,
    messageCount: 0,
    sentimentCache: {},
    journeyCache: {},
    topicCache: {},
    keywordCache: {},
  } as Accumulator;
  const data = input?.entity?.items || [];
  const reduction = data.reduce(
    (acc, curr) => {
      const dateCreated = curr.createdAt ? new Date(curr.createdAt) : new Date();
      const year = dateCreated.getUTCFullYear();
      const month = dateCreated.getUTCMonth() + 1;
      const day = dateCreated.getUTCDate() + 1;
      const createdAt = `${year}-${month}-${day}`;
      const bucket = acc[createdAt] ? acc[createdAt] : defaultBucket;
      const { sentimentCache, journeyCache, topicCache, keywordCache } = bucket;
      let { sessionCount, messageCount } = bucket;
      sessionCount += 1;
      messageCount += curr.countMessages || 0;
      if (curr.sentiment) {
        const sentiment = sentimentCache[curr.sentiment];
        if (sentiment) {
          sentiment.count += curr.countMessages || 0;
          sentiment.sessionCount += 1;
          sentimentCache[curr.sentiment] = sentiment;
        } else {
          sentimentCache[curr.sentiment] = { count: curr.countMessages || 0, sessionCount: 1 };
        }
      }
      if (curr.journey) {
        const journey = journeyCache[curr.journey];
        if (journey) {
          journey.count += curr.countMessages || 0;
          journey.sessionCount += 1;
          journeyCache[curr.journey] = journey;
        } else {
          journeyCache[curr.journey] = { count: curr.countMessages || 0, sessionCount: 1 };
        }
      }
      if (curr.topics) {
        curr.topics.forEach((currtopic) => {
          const topic = topicCache[currtopic];
          if (topic) {
            topic.count += curr.countMessages || 0;
            topic.sessionCount += 1;
            topicCache[currtopic] = topic;
          } else {
            topicCache[currtopic] = { count: curr.countMessages || 0, sessionCount: 1 };
          }
        });
      }
      if (curr.keywords) {
        curr.keywords.forEach((currKeyword) => {
          const keyword = keywordCache[currKeyword];
          if (keyword) {
            keyword.count += curr.countMessages || 0;
            keyword.sessionCount += 1;
            keywordCache[currKeyword] = keyword;
          } else {
            keywordCache[currKeyword] = { count: curr.countMessages || 0, sessionCount: 1 };
          }
        });
      }

      acc[createdAt] = {
        sessionCount,
        messageCount,
        sentimentCache,
        journeyCache,
        topicCache,
        keywordCache,
      };

      return acc;
    },
    {} as { [date: string]: Accumulator },
  );

  const buckets = Object.keys(reduction);
  buckets.sort();
  const items = buckets.map((bucket) => {
    const retrieved = reduction[bucket] ? reduction[bucket] : defaultBucket;
    const { sessionCount, messageCount, sentimentCache, journeyCache, topicCache, keywordCache } = retrieved;
    return {
      bucket,
      sessionCount, // total
      messageCount, // total
      sentimentStats: Object.keys(sentimentCache).map((sentiment) => ({
        sentiment,
        count: sentimentCache[sentiment]?.count || 0,
        sessionCount: sentimentCache[sentiment]?.sessionCount,
      })),
      journeyStats: Object.keys(journeyCache).map((journey) => ({
        journey,
        count: journeyCache[journey]?.count || 0,
        sessionCount: journeyCache[journey]?.sessionCount,
      })),
      topicStats: Object.keys(topicCache).map((topic) => ({
        topic,
        count: topicCache[topic]?.count || 0,
        sessionCount: topicCache[topic]?.sessionCount,
      })),
      keywordStats: Object.keys(keywordCache).map((keyword) => ({
        keyword,
        count: keywordCache[keyword]?.count || 0,
        sessionCount: keywordCache[keyword]?.sessionCount,
      })),
    };
  });

  return {
    entity: {
      items,
    },
  };
};

export const useAnalysisDataInRanges = ({
  timeRangeAtom = timeRangesAtom,
}: useAnalysisDataInRangesProps): useAnalysisDataInRangesReturn => {
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const timeRange = useAtomValue(timeRangeAtom);
  const { data: currentTableData } = useTableAnalyticsData({
    businessId: businessId || '',
    query: {
      timeRangeStart: timeRange[0].timeRangeStart,
      timeRangeEnd: timeRange[0].timeRangeEnd,
    },
    enabled: !!businessId,
  });
  const { data: previousTableData } = useTableAnalyticsData({
    businessId: businessId || '',
    query: {
      timeRangeStart: timeRange[1].timeRangeStart,
      timeRangeEnd: timeRange[1].timeRangeEnd,
    },
    enabled: !!businessId,
  });
  const currentData = useMemo(() => rollUp(currentTableData), [currentTableData]);
  const previousData = useMemo(() => rollUp(previousTableData), [previousTableData]);
  return {
    currentData: currentData || {},
    previousData: previousData || {},
    timeRangeStart: timeRange[0].timeRangeStart,
    timeRangeEnd: timeRange[0].timeRangeEnd,
  };
};
