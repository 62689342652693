import React, { FC, PropsWithChildren } from 'react';
import { Label, Box, FormControlLabel, Radio } from '@connectlyai-tenets/ui-styled-web';
import { SelectAudience } from '@components/SelectAudience';
import { AudienceSectionProps } from './types';

export const AudienceSelectorSection: FC<PropsWithChildren<AudienceSectionProps>> = ({
  title,
  id,
  businessId,
  checked,
  inputPlaceholder,
  onCheckedChange,
  getOptionLabel,
  isLoading,
  audienceType,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: '10px',
      }}
    >
      <FormControlLabel
        control={<Radio sx={{ m: '-9px -9px -9px 0' }} />}
        label={
          <Label component="div" noWrap variant="body1" sx={{ fontWeight: 'bold' }}>
            {title}
          </Label>
        }
        labelPlacement="start"
        sx={{ ml: 0, width: '100%', justifyContent: 'space-between' }}
        checked={checked}
        onChange={onCheckedChange}
        disabled={isLoading}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <SelectAudience
          id={id}
          businessId={businessId}
          inputPlaceholder={inputPlaceholder}
          getOptionLabel={getOptionLabel}
          isLoading={isLoading}
          audienceType={audienceType}
        />
      </Box>
      {children}
    </Box>
  );
};
