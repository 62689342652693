import { createSlice } from '@reduxjs/toolkit';
import { EligibilityType, ChannelType } from '@connectlyai-sdks/data-rooms';
import { assertUnreachable } from '@connectlyai-tenets/static-analysis';
import { insertTextMessageInput, refreshEligibility, resetStore, setRoom } from './action';
import { createMessage, uploadAttachment } from './thunk';
const initialState = {
    attachments: {
        state: 'idle',
        byId: {},
    },
    eligibilityDuration: undefined,
    isActive: true,
    recipientChannel: undefined,
    roomId: '',
    senderChannel: undefined,
    sendIsActive: false,
    templateButtonIsActive: false,
    messageTemplateIsAvailable: false,
    textMessageInput: '',
};
const CUSTOMER_UNREACHABLE_MESSAGE_SMS = 'Customer opted out of receiving Messages';
const CUSTOMER_UNREACHABLE_MESSAGE_REST = 'A new 24-hour session will open when the customer messages you.';
const CUSTOMER_UNREACHABLE_MESSAGE_WA = 'A new 24-hour session will open when the customer messages you. You can still send template messages by clicking the icon on the right.';
function computeUnreachableMessage(channelType) {
    if (channelType === undefined) {
        return undefined;
    }
    switch (channelType) {
        case ChannelType.CHANNEL_TYPE_SMS:
            return CUSTOMER_UNREACHABLE_MESSAGE_SMS;
        case ChannelType.CHANNEL_TYPE_WHATSAPP:
        case ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD:
            return CUSTOMER_UNREACHABLE_MESSAGE_WA;
        case ChannelType.CHANNEL_TYPE_INSTAGRAM:
        case ChannelType.CHANNEL_TYPE_FB_MESSENGER:
            return CUSTOMER_UNREACHABLE_MESSAGE_REST;
        case ChannelType.CHANNEL_TYPE_CONNECTLY:
        case ChannelType.CHANNEL_TYPE_EMAIL:
        case ChannelType.CHANNEL_TYPE_NUBANK:
        case ChannelType.CHANNEL_TYPE_SHOPIFY:
        case ChannelType.CHANNEL_TYPE_ZENDESK:
        case ChannelType.CHANNEL_TYPE_UNSPECIFIED:
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT:
        case ChannelType.CHANNEL_TYPE_CUSTOM_ECOMMERCE:
        case ChannelType.CHANNEL_TYPE_ALTOPAYMENT_STAGING:
        case ChannelType.CHANNEL_TYPE_VTEX:
        case ChannelType.UNRECOGNIZED:
            return undefined;
        default:
            return assertUnreachable(channelType);
    }
}
function canSendMessage(customerChannel) {
    const eligibility = customerChannel === null || customerChannel === void 0 ? void 0 : customerChannel.eligibility;
    const eligibilityType = eligibility === null || eligibility === void 0 ? void 0 : eligibility.type;
    if (!customerChannel || !eligibilityType) {
        return [true, true, undefined];
    }
    let canRespondSessionMsg;
    let canRespondTemplateMsg;
    let sessionDuration;
    const { channelType } = customerChannel;
    switch (eligibilityType) {
        case EligibilityType.ELIGIBILITY_TYPE_BIDIRECTIONAL:
        case EligibilityType.ELIGIBILITY_TYPE_OUTBOUND_ONLY:
            canRespondSessionMsg = true;
            canRespondTemplateMsg = true;
            if (eligibility === null || eligibility === void 0 ? void 0 : eligibility.startTime) {
                const startTimeInMs = Date.parse(eligibility === null || eligibility === void 0 ? void 0 : eligibility.startTime);
                if (eligibility === null || eligibility === void 0 ? void 0 : eligibility.endTime) {
                    const nowInMs = Date.now();
                    const endTimeInMs = Date.parse(eligibility === null || eligibility === void 0 ? void 0 : eligibility.endTime);
                    const withinSessionWindow = startTimeInMs <= nowInMs && nowInMs < endTimeInMs;
                    canRespondSessionMsg = withinSessionWindow;
                    canRespondTemplateMsg = withinSessionWindow;
                    sessionDuration = endTimeInMs - nowInMs;
                }
                else {
                    sessionDuration = Number.MAX_SAFE_INTEGER;
                }
            }
            break;
        case EligibilityType.ELIGIBILITY_TYPE_INBOUND_ONLY:
        case EligibilityType.UNRECOGNIZED:
            canRespondSessionMsg = false;
            canRespondTemplateMsg = false;
            break;
        default:
            return assertUnreachable(eligibilityType);
    }
    switch (channelType) {
        case ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD:
            canRespondTemplateMsg = true;
            break;
        default:
            break;
    }
    return [canRespondSessionMsg, canRespondTemplateMsg, sessionDuration];
}
function isTextMessageEventInput(input) {
    var _a, _b, _c, _d;
    return (((_b = (_a = input.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'message' && ((_d = (_c = input.connectlyEvent) === null || _c === void 0 ? void 0 : _c.eventOneof) === null || _d === void 0 ? void 0 : _d.message.text.length) > 0);
}
function attachmentIdMessageEventInput(input) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (((_b = (_a = input.connectlyEvent) === null || _a === void 0 ? void 0 : _a.eventOneof) === null || _b === void 0 ? void 0 : _b.$case) !== 'message' ||
        ((_d = (_c = input.connectlyEvent) === null || _c === void 0 ? void 0 : _c.eventOneof) === null || _d === void 0 ? void 0 : _d.message.attachments.length) === 0) {
        return undefined;
    }
    return (_g = (_f = (_e = input.connectlyEvent) === null || _e === void 0 ? void 0 : _e.eventOneof) === null || _f === void 0 ? void 0 : _f.message.attachments[0].attachmentOneof) === null || _g === void 0 ? void 0 : _g.asset.id;
}
function mutationUpdateEligibility(state, room) {
    var _a;
    if (state.senderChannel === undefined && room.defaultBusinessChannel !== undefined) {
        state.senderChannel = room.defaultBusinessChannel;
    }
    if (state.recipientChannel === undefined && room.defaultCustomerChannel !== undefined) {
        state.recipientChannel = room.defaultCustomerChannel;
    }
    const customerChannel = room.defaultCustomerChannel;
    const channelType = (_a = room.defaultCustomerChannel) === null || _a === void 0 ? void 0 : _a.channelType;
    const isWhatsAppCloud = channelType === ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD;
    state.messageTemplateIsAvailable = isWhatsAppCloud;
    const [isActive, isTemplateActive, eligibilityDuration] = canSendMessage(customerChannel);
    state.isActive = isActive;
    state.eligibilityDuration = (eligibilityDuration || -1) >= 0 ? eligibilityDuration : undefined;
    state.templateButtonIsActive = isTemplateActive;
    if (!isActive) {
        state.notification = computeUnreachableMessage(channelType);
    }
    else {
        state.notification = undefined;
    }
}
function mutationUpdateSendIsActive(state) {
    const attachments = Object.keys(state.attachments.byId);
    state.sendIsActive =
        (attachments.length === 0 && state.textMessageInput.trim().length > 0) ||
            (attachments.length > 0 && state.attachments.state === 'idle');
}
function mutationUpdateAttachmentReadyState(state) {
    state.attachments.state = Object.values(state.attachments.byId).every((val) => val.state === 'completed')
        ? 'idle'
        : 'processing';
}
function mutationUpdateTextMessageInput(state, input) {
    state.textMessageInput = input;
    if (!state.roomId) {
        return;
    }
    const key = `draft_${state.roomId}`;
    if (!input) {
        localStorage.removeItem(key);
    }
    else {
        localStorage.setItem(key, input);
    }
}
export const composerSlice = createSlice({
    name: 'composer',
    initialState,
    reducers: {
        setTextMessageInput: (state, action) => {
            const { data } = action.payload;
            mutationUpdateTextMessageInput(state, data);
            mutationUpdateSendIsActive(state);
        },
        removeAttachment: (state, action) => {
            delete state.attachments.byId[action.payload];
            mutationUpdateAttachmentReadyState(state);
            mutationUpdateSendIsActive(state);
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, (_state, _action) => initialState);
        builder.addCase(insertTextMessageInput, (state, action) => {
            const { data } = action.payload;
            const lastMsg = state.textMessageInput;
            mutationUpdateTextMessageInput(state, lastMsg ? `${lastMsg}\n${data}` : data);
            mutationUpdateSendIsActive(state);
        });
        builder.addCase(uploadAttachment.pending, (state, action) => {
            const { localId, localFileUrl, fileName, type } = action.meta.arg;
            const attachmentState = state.attachments.byId[localId];
            if (attachmentState) {
                attachmentState.state = 'uploading';
            }
            else {
                state.attachments.byId[localId] = {
                    localId,
                    localUrl: localFileUrl,
                    state: 'uploading',
                    fileName,
                    type,
                };
            }
            mutationUpdateAttachmentReadyState(state);
            mutationUpdateSendIsActive(state);
        });
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            const { localId } = action.meta.arg;
            const attachmentState = state.attachments.byId[localId];
            if (attachmentState) {
                attachmentState.state = 'completed';
                attachmentState.remoteId = action.payload.id;
                attachmentState.remoteUrl = action.payload.uri;
                mutationUpdateAttachmentReadyState(state);
                mutationUpdateSendIsActive(state);
            }
        });
        builder.addCase(uploadAttachment.rejected, (state, action) => {
            const { localId } = action.meta.arg;
            const attachmentState = state.attachments.byId[localId];
            if (attachmentState) {
                attachmentState.state = 'error';
                mutationUpdateAttachmentReadyState(state);
                mutationUpdateSendIsActive(state);
            }
        });
        builder.addCase(setRoom, (state, action) => {
            const room = action.payload;
            state.roomId = room.id;
            mutationUpdateTextMessageInput(state, localStorage.getItem(`draft_${room.id}`) || '');
            mutationUpdateSendIsActive(state);
            mutationUpdateEligibility(state, room);
        });
        builder.addCase(createMessage.pending, (state, _action) => {
            state.sendIsActive = false;
        });
        builder.addCase(createMessage.rejected, (state, _action) => {
            mutationUpdateSendIsActive(state);
        });
        builder.addCase(createMessage.fulfilled, (state, action) => {
            action.payload.sent.forEach((sent) => {
                if (isTextMessageEventInput(sent)) {
                    mutationUpdateTextMessageInput(state, '');
                }
                const attachmentId = attachmentIdMessageEventInput(sent);
                if (attachmentId) {
                    const localAttachmentState = Object.values(state.attachments.byId).find((val) => val.remoteId === attachmentId);
                    if (localAttachmentState) {
                        delete state.attachments.byId[localAttachmentState === null || localAttachmentState === void 0 ? void 0 : localAttachmentState.localId];
                    }
                }
            });
            mutationUpdateSendIsActive(state);
        });
        builder.addCase(refreshEligibility, (state, action) => {
            const room = action.payload;
            mutationUpdateEligibility(state, room);
        });
    },
});
export const { setTextMessageInput, removeAttachment, reset } = composerSlice.actions;
export const selectEligibilityDuration = (state) => (state.composerSlice.eligibilityDuration);
export const selectTextMessageInput = (state) => state.composerSlice.textMessageInput;
export const selectSendIsActive = (state) => state.composerSlice.sendIsActive;
export const selectTemplateButtonIsActive = (state) => (state.composerSlice.templateButtonIsActive);
export const selectMessageTemplateIsAvailable = (state) => state.composerSlice.messageTemplateIsAvailable;
export const selectIsActive = (state) => state.composerSlice.isActive;
export const selectNotification = (state) => state.composerSlice.notification;
export const selectAttachments = (state) => (Object.values(state.composerSlice.attachments.byId));
export default composerSlice.reducer;
