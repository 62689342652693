import { Document, Age } from './types';
import { DataPillItem } from './components/DataPill';
import { SOFIA_SUPPORTED_AGES } from './constants';

export function stringToFile(text: string, filename: string): File {
  // Step 1: Convert the string to a Blob
  const blob = new Blob([text], { type: 'text/plain' });

  // Step 2: Create a File from the Blob
  const file = new File([blob], filename, { type: 'text/plain' });

  return file;
}

export const mapDocumentToDataPillItem = (document: Document): DataPillItem => {
  return {
    value: document.id,
    display: document.name || `${document.id} (${document.mimeType})`,
  };
};

export const sortAge = (a: Age, b: Age) => {
  return SOFIA_SUPPORTED_AGES.indexOf(a) - SOFIA_SUPPORTED_AGES.indexOf(b);
};
