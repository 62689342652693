import React, { useCallback, VFC, DragEvent } from 'react';
import { Box, Card, InfoIcon, Label, Tooltip, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { FlowChartSidebarNodeProps } from './types';
import { isSafari } from '../../utils';

export const FlowChartSidebarNode: VFC<FlowChartSidebarNodeProps> = ({
  icon,
  iconSquare: isIconSquare,
  title,
  hint,
  isNew,
  onDragStart,
  onClick,
  sx,
  textWhite: isTextWhite,
}: FlowChartSidebarNodeProps) => {
  const theme = useTheme();

  const handleDragStart = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      const element = e.currentTarget;
      element.style.transition = 'unset';
      element.style.boxShadow = 'unset';
      requestAnimationFrame(() => {
        element.style.boxShadow = '';
        if (!isSafari()) {
          element.style.opacity = '0';
        }
      });

      onDragStart(e);
    },
    [onDragStart],
  );
  const handleDragEnd = useCallback((e: DragEvent<HTMLDivElement>) => {
    if (!isSafari()) {
      e.currentTarget.style.opacity = '';
      e.currentTarget.style.transition = 'margin 200ms, box-shadow 200ms';
    }
  }, []);

  const iconWidth = '28px';
  const iconHeight = isIconSquare ? iconWidth : '33.444px';
  const iconMargin = !isIconSquare ? 0 : `${(33.444 - 28) / 2}px`;

  return (
    <Card
      variant="flow"
      draggable
      sx={{
        px: 3,
        py: 2,
        willChange: 'transform',
        transition: 'margin 200ms, box-shadow 200ms',
        ':hover': {
          mb: 0.5,
          ml: 0.5,
          mt: -0.5,
          mr: -0.5,
          boxShadow: '-10px 10px 6px rgba(0, 0, 0, 0.075)',
        },
        position: 'relative',
        cursor: 'grab',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main,
        boxShadow: '-2px 2px 2px rgba(0, 0, 0, 0.15)',
        ...sx,
      }}
      onClick={onClick}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '14px', alignItems: 'center' }}>
        {typeof icon === 'string' && (
          <Box
            component="img"
            src={icon}
            sx={{ width: iconWidth, height: iconHeight, marginTop: iconMargin, marginBottom: iconMargin }}
            draggable={false}
          />
        )}
        {typeof icon !== 'string' && icon}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Label
            variant="subtitle2"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
              color: isTextWhite ? theme.palette.common.white : theme.palette.common.black,
            }}
          >
            {isNew && (
              <Box
                sx={{
                  display: 'inline-block',
                  fontSize: theme.typography.caption,
                  borderRadius: '4px',
                  padding: '0 6px',
                  marginTop: '1px',
                  color: '#B144E7',
                  backgroundColor: theme.palette.grey.A100,
                }}
              >
                NEW
              </Box>
            )}
            {title}
            {hint && (
              <Tooltip arrow placement="top" title={hint}>
                <InfoIcon
                  width={12}
                  height={12}
                  fill={isTextWhite ? theme.palette.common.white : theme.palette.common.black}
                  opacity={isTextWhite ? 1 : 0.38}
                />
              </Tooltip>
            )}
          </Label>
        </Box>
      </Box>
    </Card>
  );
};
