import { TemplateComponents } from '../../../hooks/useMessageTemplateGroupsData/types';

export const modifyTemplateComponents = (
  templateComponents: TemplateComponents,
  substituteVariables: (text: string) => string,
) => {
  return templateComponents.map((component) => {
    if (component.body) {
      return {
        body: {
          text: {
            text: substituteVariables(component.body.text?.text || ''),
          },
        },
      };
    }
    if (component.header && component.header.text) {
      return {
        header: {
          text: {
            text: substituteVariables(component.header.text.text || ''),
          },
        },
      };
    }
    return component;
  });
};
