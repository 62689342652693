import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { documentIdAtom, campaignNameAtom } from '@atoms/flow';
import { useMeData, selectBusinessId } from '@hooks';
import { useListCampaignsData } from '../useListCampaignsData';
import { selectCampaigns } from './selectors';

export const useCampaignId = (): { campaignId: string; isLoading: boolean } => {
  const documentId = useAtomValue(documentIdAtom);
  const campaignName = useAtomValue(campaignNameAtom);
  const { data: businessId } = useMeData({ select: selectBusinessId });
  const { data: campaigns, isLoading } = useListCampaignsData({
    businessId: businessId || '',
    entity: {
      search: {
        name: campaignName,
      },
    },
    enabled: !!businessId,
    select: selectCampaigns,
  });

  const campaignId = useMemo(() => {
    if (!campaigns) return '';
    return campaigns.find((campaign) => campaign.flowDocumentId === documentId)?.id || '';
  }, [campaigns]);

  return { campaignId, isLoading };
};
