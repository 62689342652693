import React, { FC, PropsWithChildren } from 'react';
import { Box, useTheme } from '@connectlyai-tenets/ui-styled-web';

export const FlowChartHeader: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: theme.spacing(8),
        flex: `0 0 ${theme.spacing(8)}`,
        px: 3,
        zIndex: theme.zIndex.appBar,
        background: theme.palette.common.white,
        boxShadow: 'none',
        // todo theme use theme colors
        borderBottom: `1px solid #E4E4E7`,
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
};
