import React from 'react';
import { useFeatureFlag } from '@hooks';
import { Box, Dialog, Label, DialogContentText, Button, Modal } from '@connectlyai-tenets/ui-styled-web';
import { useTranslation } from 'react-i18next';
import { ErrorDialogProps } from './types';

export const ErrorDialog = ({ content = '', onClose, open, title = '' }: ErrorDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.errorDialog' });
  return (
    <Modal
      dialog={{ open }}
      title={title}
      contentText={content}
      actions={{ tertiaryButton: { onClick: onClose, children: t('close') } }}
    />
  );
};
