import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_DELETE_OPT_OUT_URL = '/v1/businesses/{input.businessId}/delete_from_opt_out_list';

type MutationDeleteOptOutUrl = typeof MUTATION_DELETE_OPT_OUT_URL;

export type MutationDeleteOptOutResponse =
  Paths['profileV1'][MutationDeleteOptOutUrl]['post']['responses']['200']['schema'];
export type MutationDeleteOptOutPathParameters = { businessId: string };
export type MutationDeleteOptOutBodyParameters =
  Paths['profileV1'][MutationDeleteOptOutUrl]['post']['parameters']['body'];
export type MutationDeleteOptOutParameters = MutationDeleteOptOutPathParameters & MutationDeleteOptOutBodyParameters;
export type MutationDeleteOptOutOptions = Omit<
  UseMutationOptions<MutationDeleteOptOutResponse, ConnectlyError, MutationDeleteOptOutParameters, unknown>,
  'mutationKey' | 'mutationFn'
>;
