import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect } from 'react';
import { debounce } from 'lodash';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { SidebarDIContext } from '../../context';
import { selectSearchState } from '../../state/searchSlice';
import { searchCustomers } from '../../state/thunk';
const SEARCH_INPUT_DEBOUNCE_DELAY_IN_MS = 300;
export const SearchComponentId = 'bf6c0456-05aa-45db-bfb3-229afe70febe';
const atLeastOneAlphaNumeric = (input) => /[a-zA-Z0-9]/.test(input);
const Search = ({ opened, onClose }) => {
    const di = useContext(SidebarDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    const searchState = useFeatureSelector(selectSearchState);
    const debouncedSearch = useCallback(debounce(async (input) => {
        if (input.trim() !== '' && atLeastOneAlphaNumeric(input)) {
            dispatch(searchCustomers({
                di,
                searchInput: input,
            }));
        }
    }, SEARCH_INPUT_DEBOUNCE_DELAY_IN_MS), [di.querySearchCustomersProvider]);
    const { searchInput } = searchState;
    useEffect(() => {
        debouncedSearch(searchInput);
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch, searchInput]);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistry().getComponent(SearchComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { opened: opened, onClose: onClose, searchState: searchState }) });
};
export default Search;
