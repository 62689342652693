import React, { useCallback } from 'react';
import { useAtom } from 'jotai';
import { phoneNumberAtom } from '@atoms/home';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, Label, TextField } from '@connectlyai-tenets/ui-styled-web';
import { PHONE_NUMBER_MAX_LENGTH } from './constants';

const usePhoneNumberOld = () => {
  const [phoneNumber, setPhoneNumber] = useAtom(phoneNumberAtom);

  const handleChange = useCallback(
    (value: string) => {
      setPhoneNumber(value);
    },
    [setPhoneNumber],
  );

  return {
    phoneNumber,
    onChange: handleChange,
  };
};

export const PhoneNumberOld = () => {
  const { phoneNumber, onChange } = usePhoneNumberOld();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome.whatsAppOnboarding.phoneNumberOld' });
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <Box>
      <Label>{t('title')}</Label>
      <TextField
        autoFocus
        autoComplete="off"
        placeholder={t('enterPhoneNumber') as string}
        fullWidth
        value={phoneNumber}
        onChange={handleChange}
        variant="outlined"
        inputProps={{ maxLength: PHONE_NUMBER_MAX_LENGTH }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{`${phoneNumber.length}/${PHONE_NUMBER_MAX_LENGTH}`}</InputAdornment>
          ),
        }}
        sx={{
          mt: 1.5,
          '& .MuiInputBase-root': { pr: '12px', borderRadius: '10px' },
          '& .MuiInputBase-input': { p: '11px 12px' },
        }}
      />
    </Box>
  );
};
