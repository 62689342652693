import produce from 'immer';

// Utility function to remove templateIds from the node
// otherwise user will see them as deleted templates
export const removeTemplateIds =
  //
  // Explicit any is required here since we don't know the type of Nodes yet
  // They are recently parsed from server response to JSON objects
  // and type mapping will soon happen in useNodePersistence or useNodeUIState
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (node: any): any =>
    //
    // for the same reason same as above
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    produce(node, (draft: any) => {
      if (!draft?.data) draft.data = {};
      if (!draft?.data?.v1) draft.data.v1 = {};
      draft.data.v1.templateId = '';
    });
