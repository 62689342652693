import React, { VFC, useState, useCallback } from 'react';
import { Alert, Box, Collapse, Label, useMediaQuery, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { SIDEBAR_NODE_HINT_HIDDEN_KEY } from './constants';

export const FlowChartSidebarNodeHint: VFC = () => {
  const [open, setOpen] = useState(localStorage.getItem(SIDEBAR_NODE_HINT_HIDDEN_KEY) !== 'true');
  const handleClose = useCallback(() => {
    setOpen(false);
    localStorage.setItem(SIDEBAR_NODE_HINT_HIDDEN_KEY, 'true');
  }, []);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Collapse in={open}>
      <Alert title="Drag and Drop" severity="info" onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Label variant="body1" color="textSecondary">
            {isLargeScreen && <span>👇</span>}Drag one of the message components onto the canvas.
          </Label>
        </Box>
      </Alert>
    </Collapse>
  );
};
