import { Timestamp } from "../../../google/protobuf/timestamp";
import Long from "long";
import * as _m0 from "protobufjs/minimal";
import { StringValue, BoolValue, Int64Value, FloatValue, Int32Value, } from "../../../google/protobuf/wrappers";
import { Struct } from "../../../google/protobuf/struct";
export const protobufPackage = "events.shopify.v2023_01";
function createBaseShop() {
    return {
        address1: undefined,
        address2: undefined,
        checkoutApiSupported: undefined,
        city: undefined,
        country: undefined,
        countryCode: undefined,
        countryName: undefined,
        countyTaxes: undefined,
        createdAt: undefined,
        customerEmail: undefined,
        currency: undefined,
        domain: undefined,
        enabledPresentmentCurrencies: [],
        eligibleForCardReaderGiveaway: undefined,
        eligibleForPayments: undefined,
        email: undefined,
        googleAppsDomain: undefined,
        googleAppsLoginEnabled: undefined,
        hasDiscounts: undefined,
        hasGiftCards: undefined,
        hasStorefront: undefined,
        ianaTimezone: undefined,
        id: undefined,
        latitude: undefined,
        longitude: undefined,
        moneyFormat: undefined,
        moneyInEmailsFormat: undefined,
        moneyWithCurrencyFormat: undefined,
        moneyWithCurrencyInEmailsFormat: undefined,
        multiLocationEnabled: undefined,
        myshopifyDomain: undefined,
        name: undefined,
        passwordEnabled: undefined,
        phone: undefined,
        planDisplayName: undefined,
        preLaunchEnabled: undefined,
        cookieConsentLevel: undefined,
        planName: undefined,
        primaryLocale: undefined,
        province: undefined,
        provinceCode: undefined,
        requiresExtraPaymentsAgreement: undefined,
        setupRequired: undefined,
        shopOwner: undefined,
        source: undefined,
        taxesIncluded: undefined,
        taxShipping: undefined,
        timezone: undefined,
        updatedAt: undefined,
        weightUnit: undefined,
        zip: undefined,
        transactionalSmsDisabled: undefined,
        marketingSmsConsentEnabledAtCheckout: undefined,
        cnctShopifyApiVersion: undefined,
    };
}
export const Shop = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address1 !== undefined) {
            StringValue.encode({ value: message.address1 }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address2 !== undefined) {
            StringValue.encode({ value: message.address2 }, writer.uint32(18).fork()).ldelim();
        }
        if (message.checkoutApiSupported !== undefined) {
            BoolValue.encode({ value: message.checkoutApiSupported }, writer.uint32(26).fork()).ldelim();
        }
        if (message.city !== undefined) {
            StringValue.encode({ value: message.city }, writer.uint32(34).fork()).ldelim();
        }
        if (message.country !== undefined) {
            StringValue.encode({ value: message.country }, writer.uint32(42).fork()).ldelim();
        }
        if (message.countryCode !== undefined) {
            StringValue.encode({ value: message.countryCode }, writer.uint32(50).fork()).ldelim();
        }
        if (message.countryName !== undefined) {
            StringValue.encode({ value: message.countryName }, writer.uint32(58).fork()).ldelim();
        }
        if (message.countyTaxes !== undefined) {
            BoolValue.encode({ value: message.countyTaxes }, writer.uint32(66).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(74).fork()).ldelim();
        }
        if (message.customerEmail !== undefined) {
            StringValue.encode({ value: message.customerEmail }, writer.uint32(82).fork()).ldelim();
        }
        if (message.currency !== undefined) {
            StringValue.encode({ value: message.currency }, writer.uint32(90).fork()).ldelim();
        }
        if (message.domain !== undefined) {
            StringValue.encode({ value: message.domain }, writer.uint32(98).fork()).ldelim();
        }
        for (const v of message.enabledPresentmentCurrencies) {
            StringValue.encode({ value: v }, writer.uint32(106).fork()).ldelim();
        }
        if (message.eligibleForCardReaderGiveaway !== undefined) {
            BoolValue.encode({ value: message.eligibleForCardReaderGiveaway }, writer.uint32(114).fork()).ldelim();
        }
        if (message.eligibleForPayments !== undefined) {
            BoolValue.encode({ value: message.eligibleForPayments }, writer.uint32(122).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(130).fork()).ldelim();
        }
        if (message.googleAppsDomain !== undefined) {
            StringValue.encode({ value: message.googleAppsDomain }, writer.uint32(138).fork()).ldelim();
        }
        if (message.googleAppsLoginEnabled !== undefined) {
            BoolValue.encode({ value: message.googleAppsLoginEnabled }, writer.uint32(146).fork()).ldelim();
        }
        if (message.hasDiscounts !== undefined) {
            BoolValue.encode({ value: message.hasDiscounts }, writer.uint32(154).fork()).ldelim();
        }
        if (message.hasGiftCards !== undefined) {
            BoolValue.encode({ value: message.hasGiftCards }, writer.uint32(162).fork()).ldelim();
        }
        if (message.hasStorefront !== undefined) {
            BoolValue.encode({ value: message.hasStorefront }, writer.uint32(170).fork()).ldelim();
        }
        if (message.ianaTimezone !== undefined) {
            StringValue.encode({ value: message.ianaTimezone }, writer.uint32(178).fork()).ldelim();
        }
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(186).fork()).ldelim();
        }
        if (message.latitude !== undefined) {
            FloatValue.encode({ value: message.latitude }, writer.uint32(194).fork()).ldelim();
        }
        if (message.longitude !== undefined) {
            FloatValue.encode({ value: message.longitude }, writer.uint32(202).fork()).ldelim();
        }
        if (message.moneyFormat !== undefined) {
            StringValue.encode({ value: message.moneyFormat }, writer.uint32(210).fork()).ldelim();
        }
        if (message.moneyInEmailsFormat !== undefined) {
            StringValue.encode({ value: message.moneyInEmailsFormat }, writer.uint32(218).fork()).ldelim();
        }
        if (message.moneyWithCurrencyFormat !== undefined) {
            StringValue.encode({ value: message.moneyWithCurrencyFormat }, writer.uint32(226).fork()).ldelim();
        }
        if (message.moneyWithCurrencyInEmailsFormat !== undefined) {
            StringValue.encode({ value: message.moneyWithCurrencyInEmailsFormat }, writer.uint32(234).fork()).ldelim();
        }
        if (message.multiLocationEnabled !== undefined) {
            BoolValue.encode({ value: message.multiLocationEnabled }, writer.uint32(242).fork()).ldelim();
        }
        if (message.myshopifyDomain !== undefined) {
            StringValue.encode({ value: message.myshopifyDomain }, writer.uint32(250).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(258).fork()).ldelim();
        }
        if (message.passwordEnabled !== undefined) {
            BoolValue.encode({ value: message.passwordEnabled }, writer.uint32(266).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(274).fork()).ldelim();
        }
        if (message.planDisplayName !== undefined) {
            StringValue.encode({ value: message.planDisplayName }, writer.uint32(282).fork()).ldelim();
        }
        if (message.preLaunchEnabled !== undefined) {
            BoolValue.encode({ value: message.preLaunchEnabled }, writer.uint32(290).fork()).ldelim();
        }
        if (message.cookieConsentLevel !== undefined) {
            StringValue.encode({ value: message.cookieConsentLevel }, writer.uint32(298).fork()).ldelim();
        }
        if (message.planName !== undefined) {
            StringValue.encode({ value: message.planName }, writer.uint32(306).fork()).ldelim();
        }
        if (message.primaryLocale !== undefined) {
            StringValue.encode({ value: message.primaryLocale }, writer.uint32(314).fork()).ldelim();
        }
        if (message.province !== undefined) {
            StringValue.encode({ value: message.province }, writer.uint32(322).fork()).ldelim();
        }
        if (message.provinceCode !== undefined) {
            StringValue.encode({ value: message.provinceCode }, writer.uint32(330).fork()).ldelim();
        }
        if (message.requiresExtraPaymentsAgreement !== undefined) {
            BoolValue.encode({ value: message.requiresExtraPaymentsAgreement }, writer.uint32(338).fork()).ldelim();
        }
        if (message.setupRequired !== undefined) {
            BoolValue.encode({ value: message.setupRequired }, writer.uint32(346).fork()).ldelim();
        }
        if (message.shopOwner !== undefined) {
            StringValue.encode({ value: message.shopOwner }, writer.uint32(354).fork()).ldelim();
        }
        if (message.source !== undefined) {
            StringValue.encode({ value: message.source }, writer.uint32(362).fork()).ldelim();
        }
        if (message.taxesIncluded !== undefined) {
            BoolValue.encode({ value: message.taxesIncluded }, writer.uint32(370).fork()).ldelim();
        }
        if (message.taxShipping !== undefined) {
            BoolValue.encode({ value: message.taxShipping }, writer.uint32(378).fork()).ldelim();
        }
        if (message.timezone !== undefined) {
            StringValue.encode({ value: message.timezone }, writer.uint32(386).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(394).fork()).ldelim();
        }
        if (message.weightUnit !== undefined) {
            StringValue.encode({ value: message.weightUnit }, writer.uint32(402).fork()).ldelim();
        }
        if (message.zip !== undefined) {
            StringValue.encode({ value: message.zip }, writer.uint32(410).fork()).ldelim();
        }
        if (message.transactionalSmsDisabled !== undefined) {
            BoolValue.encode({ value: message.transactionalSmsDisabled }, writer.uint32(418).fork()).ldelim();
        }
        if (message.marketingSmsConsentEnabledAtCheckout !== undefined) {
            BoolValue.encode({ value: message.marketingSmsConsentEnabledAtCheckout }, writer.uint32(426).fork()).ldelim();
        }
        if (message.cnctShopifyApiVersion !== undefined) {
            StringValue.encode({ value: message.cnctShopifyApiVersion }, writer.uint32(434).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShop();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address1 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address2 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.checkoutApiSupported = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.city = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.country = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.countryCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.countryName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.countyTaxes = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.customerEmail = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.currency = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.domain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.enabledPresentmentCurrencies.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 14:
                    message.eligibleForCardReaderGiveaway = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.eligibleForPayments = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 17:
                    message.googleAppsDomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 18:
                    message.googleAppsLoginEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 19:
                    message.hasDiscounts = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 20:
                    message.hasGiftCards = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 21:
                    message.hasStorefront = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 22:
                    message.ianaTimezone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 23:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 24:
                    message.latitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 25:
                    message.longitude = FloatValue.decode(reader, reader.uint32()).value;
                    break;
                case 26:
                    message.moneyFormat = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 27:
                    message.moneyInEmailsFormat = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 28:
                    message.moneyWithCurrencyFormat = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 29:
                    message.moneyWithCurrencyInEmailsFormat = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 30:
                    message.multiLocationEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 31:
                    message.myshopifyDomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 32:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 33:
                    message.passwordEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 34:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 35:
                    message.planDisplayName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 36:
                    message.preLaunchEnabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 37:
                    message.cookieConsentLevel = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 38:
                    message.planName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 39:
                    message.primaryLocale = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 40:
                    message.province = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 41:
                    message.provinceCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 42:
                    message.requiresExtraPaymentsAgreement = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 43:
                    message.setupRequired = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 44:
                    message.shopOwner = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 45:
                    message.source = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 46:
                    message.taxesIncluded = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 47:
                    message.taxShipping = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 48:
                    message.timezone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 49:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 50:
                    message.weightUnit = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 51:
                    message.zip = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 52:
                    message.transactionalSmsDisabled = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 53:
                    message.marketingSmsConsentEnabledAtCheckout = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 54:
                    message.cnctShopifyApiVersion = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address1: isSet(object.address1) ? String(object.address1) : undefined,
            address2: isSet(object.address2) ? String(object.address2) : undefined,
            checkoutApiSupported: isSet(object.checkoutApiSupported)
                ? Boolean(object.checkoutApiSupported)
                : undefined,
            city: isSet(object.city) ? String(object.city) : undefined,
            country: isSet(object.country) ? String(object.country) : undefined,
            countryCode: isSet(object.countryCode)
                ? String(object.countryCode)
                : undefined,
            countryName: isSet(object.countryName)
                ? String(object.countryName)
                : undefined,
            countyTaxes: isSet(object.countyTaxes)
                ? Boolean(object.countyTaxes)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            customerEmail: isSet(object.customerEmail)
                ? String(object.customerEmail)
                : undefined,
            currency: isSet(object.currency) ? String(object.currency) : undefined,
            domain: isSet(object.domain) ? String(object.domain) : undefined,
            enabledPresentmentCurrencies: Array.isArray(object === null || object === void 0 ? void 0 : object.enabledPresentmentCurrencies)
                ? object.enabledPresentmentCurrencies.map((e) => String(e))
                : [],
            eligibleForCardReaderGiveaway: isSet(object.eligibleForCardReaderGiveaway)
                ? Boolean(object.eligibleForCardReaderGiveaway)
                : undefined,
            eligibleForPayments: isSet(object.eligibleForPayments)
                ? Boolean(object.eligibleForPayments)
                : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            googleAppsDomain: isSet(object.googleAppsDomain)
                ? String(object.googleAppsDomain)
                : undefined,
            googleAppsLoginEnabled: isSet(object.googleAppsLoginEnabled)
                ? Boolean(object.googleAppsLoginEnabled)
                : undefined,
            hasDiscounts: isSet(object.hasDiscounts)
                ? Boolean(object.hasDiscounts)
                : undefined,
            hasGiftCards: isSet(object.hasGiftCards)
                ? Boolean(object.hasGiftCards)
                : undefined,
            hasStorefront: isSet(object.hasStorefront)
                ? Boolean(object.hasStorefront)
                : undefined,
            ianaTimezone: isSet(object.ianaTimezone)
                ? String(object.ianaTimezone)
                : undefined,
            id: isSet(object.id) ? Number(object.id) : undefined,
            latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
            longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
            moneyFormat: isSet(object.moneyFormat)
                ? String(object.moneyFormat)
                : undefined,
            moneyInEmailsFormat: isSet(object.moneyInEmailsFormat)
                ? String(object.moneyInEmailsFormat)
                : undefined,
            moneyWithCurrencyFormat: isSet(object.moneyWithCurrencyFormat)
                ? String(object.moneyWithCurrencyFormat)
                : undefined,
            moneyWithCurrencyInEmailsFormat: isSet(object.moneyWithCurrencyInEmailsFormat)
                ? String(object.moneyWithCurrencyInEmailsFormat)
                : undefined,
            multiLocationEnabled: isSet(object.multiLocationEnabled)
                ? Boolean(object.multiLocationEnabled)
                : undefined,
            myshopifyDomain: isSet(object.myshopifyDomain)
                ? String(object.myshopifyDomain)
                : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            passwordEnabled: isSet(object.passwordEnabled)
                ? Boolean(object.passwordEnabled)
                : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
            planDisplayName: isSet(object.planDisplayName)
                ? String(object.planDisplayName)
                : undefined,
            preLaunchEnabled: isSet(object.preLaunchEnabled)
                ? Boolean(object.preLaunchEnabled)
                : undefined,
            cookieConsentLevel: isSet(object.cookieConsentLevel)
                ? String(object.cookieConsentLevel)
                : undefined,
            planName: isSet(object.planName) ? String(object.planName) : undefined,
            primaryLocale: isSet(object.primaryLocale)
                ? String(object.primaryLocale)
                : undefined,
            province: isSet(object.province) ? String(object.province) : undefined,
            provinceCode: isSet(object.provinceCode)
                ? String(object.provinceCode)
                : undefined,
            requiresExtraPaymentsAgreement: isSet(object.requiresExtraPaymentsAgreement)
                ? Boolean(object.requiresExtraPaymentsAgreement)
                : undefined,
            setupRequired: isSet(object.setupRequired)
                ? Boolean(object.setupRequired)
                : undefined,
            shopOwner: isSet(object.shopOwner) ? String(object.shopOwner) : undefined,
            source: isSet(object.source) ? String(object.source) : undefined,
            taxesIncluded: isSet(object.taxesIncluded)
                ? Boolean(object.taxesIncluded)
                : undefined,
            taxShipping: isSet(object.taxShipping)
                ? Boolean(object.taxShipping)
                : undefined,
            timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            weightUnit: isSet(object.weightUnit)
                ? String(object.weightUnit)
                : undefined,
            zip: isSet(object.zip) ? String(object.zip) : undefined,
            transactionalSmsDisabled: isSet(object.transactionalSmsDisabled)
                ? Boolean(object.transactionalSmsDisabled)
                : undefined,
            marketingSmsConsentEnabledAtCheckout: isSet(object.marketingSmsConsentEnabledAtCheckout)
                ? Boolean(object.marketingSmsConsentEnabledAtCheckout)
                : undefined,
            cnctShopifyApiVersion: isSet(object.cnctShopifyApiVersion)
                ? String(object.cnctShopifyApiVersion)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.address1 !== undefined && (obj.address1 = message.address1);
        message.address2 !== undefined && (obj.address2 = message.address2);
        message.checkoutApiSupported !== undefined &&
            (obj.checkoutApiSupported = message.checkoutApiSupported);
        message.city !== undefined && (obj.city = message.city);
        message.country !== undefined && (obj.country = message.country);
        message.countryCode !== undefined &&
            (obj.countryCode = message.countryCode);
        message.countryName !== undefined &&
            (obj.countryName = message.countryName);
        message.countyTaxes !== undefined &&
            (obj.countyTaxes = message.countyTaxes);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.customerEmail !== undefined &&
            (obj.customerEmail = message.customerEmail);
        message.currency !== undefined && (obj.currency = message.currency);
        message.domain !== undefined && (obj.domain = message.domain);
        if (message.enabledPresentmentCurrencies) {
            obj.enabledPresentmentCurrencies =
                message.enabledPresentmentCurrencies.map((e) => e);
        }
        else {
            obj.enabledPresentmentCurrencies = [];
        }
        message.eligibleForCardReaderGiveaway !== undefined &&
            (obj.eligibleForCardReaderGiveaway =
                message.eligibleForCardReaderGiveaway);
        message.eligibleForPayments !== undefined &&
            (obj.eligibleForPayments = message.eligibleForPayments);
        message.email !== undefined && (obj.email = message.email);
        message.googleAppsDomain !== undefined &&
            (obj.googleAppsDomain = message.googleAppsDomain);
        message.googleAppsLoginEnabled !== undefined &&
            (obj.googleAppsLoginEnabled = message.googleAppsLoginEnabled);
        message.hasDiscounts !== undefined &&
            (obj.hasDiscounts = message.hasDiscounts);
        message.hasGiftCards !== undefined &&
            (obj.hasGiftCards = message.hasGiftCards);
        message.hasStorefront !== undefined &&
            (obj.hasStorefront = message.hasStorefront);
        message.ianaTimezone !== undefined &&
            (obj.ianaTimezone = message.ianaTimezone);
        message.id !== undefined && (obj.id = message.id);
        message.latitude !== undefined && (obj.latitude = message.latitude);
        message.longitude !== undefined && (obj.longitude = message.longitude);
        message.moneyFormat !== undefined &&
            (obj.moneyFormat = message.moneyFormat);
        message.moneyInEmailsFormat !== undefined &&
            (obj.moneyInEmailsFormat = message.moneyInEmailsFormat);
        message.moneyWithCurrencyFormat !== undefined &&
            (obj.moneyWithCurrencyFormat = message.moneyWithCurrencyFormat);
        message.moneyWithCurrencyInEmailsFormat !== undefined &&
            (obj.moneyWithCurrencyInEmailsFormat =
                message.moneyWithCurrencyInEmailsFormat);
        message.multiLocationEnabled !== undefined &&
            (obj.multiLocationEnabled = message.multiLocationEnabled);
        message.myshopifyDomain !== undefined &&
            (obj.myshopifyDomain = message.myshopifyDomain);
        message.name !== undefined && (obj.name = message.name);
        message.passwordEnabled !== undefined &&
            (obj.passwordEnabled = message.passwordEnabled);
        message.phone !== undefined && (obj.phone = message.phone);
        message.planDisplayName !== undefined &&
            (obj.planDisplayName = message.planDisplayName);
        message.preLaunchEnabled !== undefined &&
            (obj.preLaunchEnabled = message.preLaunchEnabled);
        message.cookieConsentLevel !== undefined &&
            (obj.cookieConsentLevel = message.cookieConsentLevel);
        message.planName !== undefined && (obj.planName = message.planName);
        message.primaryLocale !== undefined &&
            (obj.primaryLocale = message.primaryLocale);
        message.province !== undefined && (obj.province = message.province);
        message.provinceCode !== undefined &&
            (obj.provinceCode = message.provinceCode);
        message.requiresExtraPaymentsAgreement !== undefined &&
            (obj.requiresExtraPaymentsAgreement =
                message.requiresExtraPaymentsAgreement);
        message.setupRequired !== undefined &&
            (obj.setupRequired = message.setupRequired);
        message.shopOwner !== undefined && (obj.shopOwner = message.shopOwner);
        message.source !== undefined && (obj.source = message.source);
        message.taxesIncluded !== undefined &&
            (obj.taxesIncluded = message.taxesIncluded);
        message.taxShipping !== undefined &&
            (obj.taxShipping = message.taxShipping);
        message.timezone !== undefined && (obj.timezone = message.timezone);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.weightUnit !== undefined && (obj.weightUnit = message.weightUnit);
        message.zip !== undefined && (obj.zip = message.zip);
        message.transactionalSmsDisabled !== undefined &&
            (obj.transactionalSmsDisabled = message.transactionalSmsDisabled);
        message.marketingSmsConsentEnabledAtCheckout !== undefined &&
            (obj.marketingSmsConsentEnabledAtCheckout =
                message.marketingSmsConsentEnabledAtCheckout);
        message.cnctShopifyApiVersion !== undefined &&
            (obj.cnctShopifyApiVersion = message.cnctShopifyApiVersion);
        return obj;
    },
};
function createBaseGetCustomersResponse() {
    return { customers: [] };
}
export const GetCustomersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.customers) {
            ShopifyCustomer.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCustomersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.customers.push(ShopifyCustomer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            customers: Array.isArray(object === null || object === void 0 ? void 0 : object.customers)
                ? object.customers.map((e) => ShopifyCustomer.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.customers) {
            obj.customers = message.customers.map((e) => e ? ShopifyCustomer.toJSON(e) : undefined);
        }
        else {
            obj.customers = [];
        }
        return obj;
    },
};
function createBaseAddress() {
    return {
        id: undefined,
        customerId: undefined,
        firstName: undefined,
        lastName: undefined,
        company: undefined,
        address1: undefined,
        address2: undefined,
        city: undefined,
        province: undefined,
        country: undefined,
        zip: undefined,
        phone: undefined,
        name: undefined,
        provinceCode: undefined,
        countryCode: undefined,
        countryName: undefined,
        default: undefined,
    };
}
export const Address = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.customerId !== undefined) {
            Int64Value.encode({ value: message.customerId }, writer.uint32(18).fork()).ldelim();
        }
        if (message.firstName !== undefined) {
            StringValue.encode({ value: message.firstName }, writer.uint32(26).fork()).ldelim();
        }
        if (message.lastName !== undefined) {
            StringValue.encode({ value: message.lastName }, writer.uint32(34).fork()).ldelim();
        }
        if (message.company !== undefined) {
            StringValue.encode({ value: message.company }, writer.uint32(42).fork()).ldelim();
        }
        if (message.address1 !== undefined) {
            StringValue.encode({ value: message.address1 }, writer.uint32(50).fork()).ldelim();
        }
        if (message.address2 !== undefined) {
            StringValue.encode({ value: message.address2 }, writer.uint32(58).fork()).ldelim();
        }
        if (message.city !== undefined) {
            StringValue.encode({ value: message.city }, writer.uint32(66).fork()).ldelim();
        }
        if (message.province !== undefined) {
            StringValue.encode({ value: message.province }, writer.uint32(74).fork()).ldelim();
        }
        if (message.country !== undefined) {
            StringValue.encode({ value: message.country }, writer.uint32(82).fork()).ldelim();
        }
        if (message.zip !== undefined) {
            StringValue.encode({ value: message.zip }, writer.uint32(90).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(98).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(106).fork()).ldelim();
        }
        if (message.provinceCode !== undefined) {
            StringValue.encode({ value: message.provinceCode }, writer.uint32(114).fork()).ldelim();
        }
        if (message.countryCode !== undefined) {
            StringValue.encode({ value: message.countryCode }, writer.uint32(122).fork()).ldelim();
        }
        if (message.countryName !== undefined) {
            StringValue.encode({ value: message.countryName }, writer.uint32(130).fork()).ldelim();
        }
        if (message.default !== undefined) {
            BoolValue.encode({ value: message.default }, writer.uint32(138).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddress();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.customerId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.firstName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.lastName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.company = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.address1 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.address2 = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.city = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.province = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.country = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.zip = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.provinceCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.countryCode = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.countryName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 17:
                    message.default = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            customerId: isSet(object.customerId)
                ? Number(object.customerId)
                : undefined,
            firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
            lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
            company: isSet(object.company) ? String(object.company) : undefined,
            address1: isSet(object.address1) ? String(object.address1) : undefined,
            address2: isSet(object.address2) ? String(object.address2) : undefined,
            city: isSet(object.city) ? String(object.city) : undefined,
            province: isSet(object.province) ? String(object.province) : undefined,
            country: isSet(object.country) ? String(object.country) : undefined,
            zip: isSet(object.zip) ? String(object.zip) : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            provinceCode: isSet(object.provinceCode)
                ? String(object.provinceCode)
                : undefined,
            countryCode: isSet(object.countryCode)
                ? String(object.countryCode)
                : undefined,
            countryName: isSet(object.countryName)
                ? String(object.countryName)
                : undefined,
            default: isSet(object.default) ? Boolean(object.default) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.customerId !== undefined && (obj.customerId = message.customerId);
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.company !== undefined && (obj.company = message.company);
        message.address1 !== undefined && (obj.address1 = message.address1);
        message.address2 !== undefined && (obj.address2 = message.address2);
        message.city !== undefined && (obj.city = message.city);
        message.province !== undefined && (obj.province = message.province);
        message.country !== undefined && (obj.country = message.country);
        message.zip !== undefined && (obj.zip = message.zip);
        message.phone !== undefined && (obj.phone = message.phone);
        message.name !== undefined && (obj.name = message.name);
        message.provinceCode !== undefined &&
            (obj.provinceCode = message.provinceCode);
        message.countryCode !== undefined &&
            (obj.countryCode = message.countryCode);
        message.countryName !== undefined &&
            (obj.countryName = message.countryName);
        message.default !== undefined && (obj.default = message.default);
        return obj;
    },
};
function createBaseShopifyCustomer() {
    return {
        id: undefined,
        email: undefined,
        acceptsMarketing: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        firstName: undefined,
        lastName: undefined,
        ordersCount: undefined,
        state: undefined,
        totalSpent: undefined,
        lastOrderId: undefined,
        note: undefined,
        verifiedEmail: undefined,
        multipassIdentifier: undefined,
        taxExempt: undefined,
        phone: undefined,
        tags: undefined,
        lastOrderName: undefined,
        currency: undefined,
        addresses: [],
        acceptsMarketingUpdatedAt: undefined,
        marketingOptInLevel: undefined,
        taxExemptions: [],
        adminGraphqlApiId: undefined,
        defaultAddress: undefined,
        metafield: undefined,
        emailMarketingConsent: undefined,
        smsMarketingConsent: undefined,
        cnctShopifyApiVersion: undefined,
    };
}
export const ShopifyCustomer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(18).fork()).ldelim();
        }
        if (message.acceptsMarketing !== undefined) {
            BoolValue.encode({ value: message.acceptsMarketing }, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            StringValue.encode({ value: message.createdAt }, writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            StringValue.encode({ value: message.updatedAt }, writer.uint32(42).fork()).ldelim();
        }
        if (message.firstName !== undefined) {
            StringValue.encode({ value: message.firstName }, writer.uint32(50).fork()).ldelim();
        }
        if (message.lastName !== undefined) {
            StringValue.encode({ value: message.lastName }, writer.uint32(58).fork()).ldelim();
        }
        if (message.ordersCount !== undefined) {
            Int64Value.encode({ value: message.ordersCount }, writer.uint32(66).fork()).ldelim();
        }
        if (message.state !== undefined) {
            StringValue.encode({ value: message.state }, writer.uint32(74).fork()).ldelim();
        }
        if (message.totalSpent !== undefined) {
            StringValue.encode({ value: message.totalSpent }, writer.uint32(82).fork()).ldelim();
        }
        if (message.lastOrderId !== undefined) {
            Int64Value.encode({ value: message.lastOrderId }, writer.uint32(90).fork()).ldelim();
        }
        if (message.note !== undefined) {
            StringValue.encode({ value: message.note }, writer.uint32(98).fork()).ldelim();
        }
        if (message.verifiedEmail !== undefined) {
            BoolValue.encode({ value: message.verifiedEmail }, writer.uint32(106).fork()).ldelim();
        }
        if (message.multipassIdentifier !== undefined) {
            StringValue.encode({ value: message.multipassIdentifier }, writer.uint32(114).fork()).ldelim();
        }
        if (message.taxExempt !== undefined) {
            BoolValue.encode({ value: message.taxExempt }, writer.uint32(122).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(130).fork()).ldelim();
        }
        if (message.tags !== undefined) {
            StringValue.encode({ value: message.tags }, writer.uint32(138).fork()).ldelim();
        }
        if (message.lastOrderName !== undefined) {
            StringValue.encode({ value: message.lastOrderName }, writer.uint32(146).fork()).ldelim();
        }
        if (message.currency !== undefined) {
            StringValue.encode({ value: message.currency }, writer.uint32(154).fork()).ldelim();
        }
        for (const v of message.addresses) {
            Address.encode(v, writer.uint32(162).fork()).ldelim();
        }
        if (message.acceptsMarketingUpdatedAt !== undefined) {
            StringValue.encode({ value: message.acceptsMarketingUpdatedAt }, writer.uint32(170).fork()).ldelim();
        }
        if (message.marketingOptInLevel !== undefined) {
            StringValue.encode({ value: message.marketingOptInLevel }, writer.uint32(178).fork()).ldelim();
        }
        for (const v of message.taxExemptions) {
            StringValue.encode({ value: v }, writer.uint32(186).fork()).ldelim();
        }
        if (message.adminGraphqlApiId !== undefined) {
            StringValue.encode({ value: message.adminGraphqlApiId }, writer.uint32(194).fork()).ldelim();
        }
        if (message.defaultAddress !== undefined) {
            Address.encode(message.defaultAddress, writer.uint32(202).fork()).ldelim();
        }
        if (message.metafield !== undefined) {
            Metafield.encode(message.metafield, writer.uint32(210).fork()).ldelim();
        }
        if (message.emailMarketingConsent !== undefined) {
            EmailMarketingConsent.encode(message.emailMarketingConsent, writer.uint32(218).fork()).ldelim();
        }
        if (message.smsMarketingConsent !== undefined) {
            SMSMarketingConsent.encode(message.smsMarketingConsent, writer.uint32(226).fork()).ldelim();
        }
        if (message.cnctShopifyApiVersion !== undefined) {
            StringValue.encode({ value: message.cnctShopifyApiVersion }, writer.uint32(234).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyCustomer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.acceptsMarketing = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.createdAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.updatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.firstName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.lastName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.ordersCount = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.state = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.totalSpent = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 11:
                    message.lastOrderId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 12:
                    message.note = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 13:
                    message.verifiedEmail = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 14:
                    message.multipassIdentifier = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 15:
                    message.taxExempt = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 16:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 17:
                    message.tags = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 18:
                    message.lastOrderName = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 19:
                    message.currency = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 20:
                    message.addresses.push(Address.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.acceptsMarketingUpdatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 22:
                    message.marketingOptInLevel = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 23:
                    message.taxExemptions.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 24:
                    message.adminGraphqlApiId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 25:
                    message.defaultAddress = Address.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.metafield = Metafield.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.emailMarketingConsent = EmailMarketingConsent.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.smsMarketingConsent = SMSMarketingConsent.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.cnctShopifyApiVersion = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            acceptsMarketing: isSet(object.acceptsMarketing)
                ? Boolean(object.acceptsMarketing)
                : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
            lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
            ordersCount: isSet(object.ordersCount)
                ? Number(object.ordersCount)
                : undefined,
            state: isSet(object.state) ? String(object.state) : undefined,
            totalSpent: isSet(object.totalSpent)
                ? String(object.totalSpent)
                : undefined,
            lastOrderId: isSet(object.lastOrderId)
                ? Number(object.lastOrderId)
                : undefined,
            note: isSet(object.note) ? String(object.note) : undefined,
            verifiedEmail: isSet(object.verifiedEmail)
                ? Boolean(object.verifiedEmail)
                : undefined,
            multipassIdentifier: isSet(object.multipassIdentifier)
                ? String(object.multipassIdentifier)
                : undefined,
            taxExempt: isSet(object.taxExempt)
                ? Boolean(object.taxExempt)
                : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
            tags: isSet(object.tags) ? String(object.tags) : undefined,
            lastOrderName: isSet(object.lastOrderName)
                ? String(object.lastOrderName)
                : undefined,
            currency: isSet(object.currency) ? String(object.currency) : undefined,
            addresses: Array.isArray(object === null || object === void 0 ? void 0 : object.addresses)
                ? object.addresses.map((e) => Address.fromJSON(e))
                : [],
            acceptsMarketingUpdatedAt: isSet(object.acceptsMarketingUpdatedAt)
                ? String(object.acceptsMarketingUpdatedAt)
                : undefined,
            marketingOptInLevel: isSet(object.marketingOptInLevel)
                ? String(object.marketingOptInLevel)
                : undefined,
            taxExemptions: Array.isArray(object === null || object === void 0 ? void 0 : object.taxExemptions)
                ? object.taxExemptions.map((e) => String(e))
                : [],
            adminGraphqlApiId: isSet(object.adminGraphqlApiId)
                ? String(object.adminGraphqlApiId)
                : undefined,
            defaultAddress: isSet(object.defaultAddress)
                ? Address.fromJSON(object.defaultAddress)
                : undefined,
            metafield: isSet(object.metafield)
                ? Metafield.fromJSON(object.metafield)
                : undefined,
            emailMarketingConsent: isSet(object.emailMarketingConsent)
                ? EmailMarketingConsent.fromJSON(object.emailMarketingConsent)
                : undefined,
            smsMarketingConsent: isSet(object.smsMarketingConsent)
                ? SMSMarketingConsent.fromJSON(object.smsMarketingConsent)
                : undefined,
            cnctShopifyApiVersion: isSet(object.cnctShopifyApiVersion)
                ? String(object.cnctShopifyApiVersion)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.email !== undefined && (obj.email = message.email);
        message.acceptsMarketing !== undefined &&
            (obj.acceptsMarketing = message.acceptsMarketing);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.firstName !== undefined && (obj.firstName = message.firstName);
        message.lastName !== undefined && (obj.lastName = message.lastName);
        message.ordersCount !== undefined &&
            (obj.ordersCount = message.ordersCount);
        message.state !== undefined && (obj.state = message.state);
        message.totalSpent !== undefined && (obj.totalSpent = message.totalSpent);
        message.lastOrderId !== undefined &&
            (obj.lastOrderId = message.lastOrderId);
        message.note !== undefined && (obj.note = message.note);
        message.verifiedEmail !== undefined &&
            (obj.verifiedEmail = message.verifiedEmail);
        message.multipassIdentifier !== undefined &&
            (obj.multipassIdentifier = message.multipassIdentifier);
        message.taxExempt !== undefined && (obj.taxExempt = message.taxExempt);
        message.phone !== undefined && (obj.phone = message.phone);
        message.tags !== undefined && (obj.tags = message.tags);
        message.lastOrderName !== undefined &&
            (obj.lastOrderName = message.lastOrderName);
        message.currency !== undefined && (obj.currency = message.currency);
        if (message.addresses) {
            obj.addresses = message.addresses.map((e) => e ? Address.toJSON(e) : undefined);
        }
        else {
            obj.addresses = [];
        }
        message.acceptsMarketingUpdatedAt !== undefined &&
            (obj.acceptsMarketingUpdatedAt = message.acceptsMarketingUpdatedAt);
        message.marketingOptInLevel !== undefined &&
            (obj.marketingOptInLevel = message.marketingOptInLevel);
        if (message.taxExemptions) {
            obj.taxExemptions = message.taxExemptions.map((e) => e);
        }
        else {
            obj.taxExemptions = [];
        }
        message.adminGraphqlApiId !== undefined &&
            (obj.adminGraphqlApiId = message.adminGraphqlApiId);
        message.defaultAddress !== undefined &&
            (obj.defaultAddress = message.defaultAddress
                ? Address.toJSON(message.defaultAddress)
                : undefined);
        message.metafield !== undefined &&
            (obj.metafield = message.metafield
                ? Metafield.toJSON(message.metafield)
                : undefined);
        message.emailMarketingConsent !== undefined &&
            (obj.emailMarketingConsent = message.emailMarketingConsent
                ? EmailMarketingConsent.toJSON(message.emailMarketingConsent)
                : undefined);
        message.smsMarketingConsent !== undefined &&
            (obj.smsMarketingConsent = message.smsMarketingConsent
                ? SMSMarketingConsent.toJSON(message.smsMarketingConsent)
                : undefined);
        message.cnctShopifyApiVersion !== undefined &&
            (obj.cnctShopifyApiVersion = message.cnctShopifyApiVersion);
        return obj;
    },
};
function createBaseMetafield() {
    return { key: "", namespace: "", value: "", type: "", description: "" };
}
export const Metafield = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.namespace !== "") {
            writer.uint32(18).string(message.namespace);
        }
        if (message.value !== "") {
            writer.uint32(26).string(message.value);
        }
        if (message.type !== "") {
            writer.uint32(34).string(message.type);
        }
        if (message.description !== "") {
            writer.uint32(42).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetafield();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.namespace = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.type = reader.string();
                    break;
                case 5:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            namespace: isSet(object.namespace) ? String(object.namespace) : "",
            value: isSet(object.value) ? String(object.value) : "",
            type: isSet(object.type) ? String(object.type) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.namespace !== undefined && (obj.namespace = message.namespace);
        message.value !== undefined && (obj.value = message.value);
        message.type !== undefined && (obj.type = message.type);
        message.description !== undefined &&
            (obj.description = message.description);
        return obj;
    },
};
function createBaseEmailMarketingConsent() {
    return { state: "", optInLevel: "", consentUpdatedAt: "" };
}
export const EmailMarketingConsent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== "") {
            writer.uint32(10).string(message.state);
        }
        if (message.optInLevel !== "") {
            writer.uint32(18).string(message.optInLevel);
        }
        if (message.consentUpdatedAt !== "") {
            writer.uint32(26).string(message.consentUpdatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEmailMarketingConsent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = reader.string();
                    break;
                case 2:
                    message.optInLevel = reader.string();
                    break;
                case 3:
                    message.consentUpdatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            state: isSet(object.state) ? String(object.state) : "",
            optInLevel: isSet(object.optInLevel) ? String(object.optInLevel) : "",
            consentUpdatedAt: isSet(object.consentUpdatedAt)
                ? String(object.consentUpdatedAt)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state);
        message.optInLevel !== undefined && (obj.optInLevel = message.optInLevel);
        message.consentUpdatedAt !== undefined &&
            (obj.consentUpdatedAt = message.consentUpdatedAt);
        return obj;
    },
};
function createBaseSMSMarketingConsent() {
    return {
        state: "",
        optInLevel: "",
        consentUpdatedAt: "",
        consentCollectedFrom: "",
    };
}
export const SMSMarketingConsent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== "") {
            writer.uint32(10).string(message.state);
        }
        if (message.optInLevel !== "") {
            writer.uint32(18).string(message.optInLevel);
        }
        if (message.consentUpdatedAt !== "") {
            writer.uint32(26).string(message.consentUpdatedAt);
        }
        if (message.consentCollectedFrom !== "") {
            writer.uint32(34).string(message.consentCollectedFrom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSMSMarketingConsent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = reader.string();
                    break;
                case 2:
                    message.optInLevel = reader.string();
                    break;
                case 3:
                    message.consentUpdatedAt = reader.string();
                    break;
                case 4:
                    message.consentCollectedFrom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            state: isSet(object.state) ? String(object.state) : "",
            optInLevel: isSet(object.optInLevel) ? String(object.optInLevel) : "",
            consentUpdatedAt: isSet(object.consentUpdatedAt)
                ? String(object.consentUpdatedAt)
                : "",
            consentCollectedFrom: isSet(object.consentCollectedFrom)
                ? String(object.consentCollectedFrom)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state);
        message.optInLevel !== undefined && (obj.optInLevel = message.optInLevel);
        message.consentUpdatedAt !== undefined &&
            (obj.consentUpdatedAt = message.consentUpdatedAt);
        message.consentCollectedFrom !== undefined &&
            (obj.consentCollectedFrom = message.consentCollectedFrom);
        return obj;
    },
};
function createBaseShopifyWebhookPostEnvelope() {
    return { shopifyDomain: "", customer: undefined };
}
export const ShopifyWebhookPostEnvelope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopifyDomain !== "") {
            writer.uint32(10).string(message.shopifyDomain);
        }
        if (message.customer !== undefined) {
            ShopifyCustomer.encode(message.customer, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyWebhookPostEnvelope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopifyDomain = reader.string();
                    break;
                case 2:
                    message.customer = ShopifyCustomer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopifyDomain: isSet(object.shopifyDomain)
                ? String(object.shopifyDomain)
                : "",
            customer: isSet(object.customer)
                ? ShopifyCustomer.fromJSON(object.customer)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopifyDomain !== undefined &&
            (obj.shopifyDomain = message.shopifyDomain);
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? ShopifyCustomer.toJSON(message.customer)
                : undefined);
        return obj;
    },
};
function createBaseWebhookRegistrationEvent() {
    return { webhook: undefined };
}
export const WebhookRegistrationEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.webhook !== undefined) {
            WebhookRegistrationEvent_Webhook.encode(message.webhook, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWebhookRegistrationEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.webhook = WebhookRegistrationEvent_Webhook.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            webhook: isSet(object.webhook)
                ? WebhookRegistrationEvent_Webhook.fromJSON(object.webhook)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.webhook !== undefined &&
            (obj.webhook = message.webhook
                ? WebhookRegistrationEvent_Webhook.toJSON(message.webhook)
                : undefined);
        return obj;
    },
};
function createBaseWebhookRegistrationEvent_Webhook() {
    return {
        id: undefined,
        address: undefined,
        topic: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        format: undefined,
        fields: [],
        metafieldNamespaces: [],
        apiVersion: undefined,
        privateMetafieldNamespaces: [],
    };
}
export const WebhookRegistrationEvent_Webhook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(18).fork()).ldelim();
        }
        if (message.topic !== undefined) {
            StringValue.encode({ value: message.topic }, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            StringValue.encode({ value: message.createdAt }, writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            StringValue.encode({ value: message.updatedAt }, writer.uint32(42).fork()).ldelim();
        }
        if (message.format !== undefined) {
            StringValue.encode({ value: message.format }, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.fields) {
            StringValue.encode({ value: v }, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.metafieldNamespaces) {
            StringValue.encode({ value: v }, writer.uint32(66).fork()).ldelim();
        }
        if (message.apiVersion !== undefined) {
            StringValue.encode({ value: message.apiVersion }, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.privateMetafieldNamespaces) {
            StringValue.encode({ value: v }, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWebhookRegistrationEvent_Webhook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.topic = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.createdAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.updatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.format = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.fields.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 8:
                    message.metafieldNamespaces.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 9:
                    message.apiVersion = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.privateMetafieldNamespaces.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
            topic: isSet(object.topic) ? String(object.topic) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            format: isSet(object.format) ? String(object.format) : undefined,
            fields: Array.isArray(object === null || object === void 0 ? void 0 : object.fields)
                ? object.fields.map((e) => String(e))
                : [],
            metafieldNamespaces: Array.isArray(object === null || object === void 0 ? void 0 : object.metafieldNamespaces)
                ? object.metafieldNamespaces.map((e) => String(e))
                : [],
            apiVersion: isSet(object.apiVersion)
                ? String(object.apiVersion)
                : undefined,
            privateMetafieldNamespaces: Array.isArray(object === null || object === void 0 ? void 0 : object.privateMetafieldNamespaces)
                ? object.privateMetafieldNamespaces.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.address !== undefined && (obj.address = message.address);
        message.topic !== undefined && (obj.topic = message.topic);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.format !== undefined && (obj.format = message.format);
        if (message.fields) {
            obj.fields = message.fields.map((e) => e);
        }
        else {
            obj.fields = [];
        }
        if (message.metafieldNamespaces) {
            obj.metafieldNamespaces = message.metafieldNamespaces.map((e) => e);
        }
        else {
            obj.metafieldNamespaces = [];
        }
        message.apiVersion !== undefined && (obj.apiVersion = message.apiVersion);
        if (message.privateMetafieldNamespaces) {
            obj.privateMetafieldNamespaces = message.privateMetafieldNamespaces.map((e) => e);
        }
        else {
            obj.privateMetafieldNamespaces = [];
        }
        return obj;
    },
};
function createBaseCustomerIdentifier() {
    return { id: undefined, email: undefined, phone: undefined };
}
export const CustomerIdentifier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.email !== undefined) {
            StringValue.encode({ value: message.email }, writer.uint32(18).fork()).ldelim();
        }
        if (message.phone !== undefined) {
            StringValue.encode({ value: message.phone }, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerIdentifier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.email = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.phone = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            email: isSet(object.email) ? String(object.email) : undefined,
            phone: isSet(object.phone) ? String(object.phone) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.email !== undefined && (obj.email = message.email);
        message.phone !== undefined && (obj.phone = message.phone);
        return obj;
    },
};
function createBaseCustomerDataRequestEvent() {
    return {
        shopId: undefined,
        shopDomain: undefined,
        ordersRequested: [],
        customer: undefined,
        dataRequest: undefined,
    };
}
export const CustomerDataRequestEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopId !== undefined) {
            Int64Value.encode({ value: message.shopId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.shopDomain !== undefined) {
            StringValue.encode({ value: message.shopDomain }, writer.uint32(18).fork()).ldelim();
        }
        writer.uint32(26).fork();
        for (const v of message.ordersRequested) {
            writer.uint32(v);
        }
        writer.ldelim();
        if (message.customer !== undefined) {
            CustomerIdentifier.encode(message.customer, writer.uint32(34).fork()).ldelim();
        }
        if (message.dataRequest !== undefined) {
            CustomerDataRequestEvent_DataRequest.encode(message.dataRequest, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerDataRequestEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.shopDomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.ordersRequested.push(reader.uint32());
                        }
                    }
                    else {
                        message.ordersRequested.push(reader.uint32());
                    }
                    break;
                case 4:
                    message.customer = CustomerIdentifier.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.dataRequest = CustomerDataRequestEvent_DataRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopId: isSet(object.shopId) ? Number(object.shopId) : undefined,
            shopDomain: isSet(object.shopDomain)
                ? String(object.shopDomain)
                : undefined,
            ordersRequested: Array.isArray(object === null || object === void 0 ? void 0 : object.ordersRequested)
                ? object.ordersRequested.map((e) => Number(e))
                : [],
            customer: isSet(object.customer)
                ? CustomerIdentifier.fromJSON(object.customer)
                : undefined,
            dataRequest: isSet(object.dataRequest)
                ? CustomerDataRequestEvent_DataRequest.fromJSON(object.dataRequest)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopId !== undefined && (obj.shopId = message.shopId);
        message.shopDomain !== undefined && (obj.shopDomain = message.shopDomain);
        if (message.ordersRequested) {
            obj.ordersRequested = message.ordersRequested.map((e) => Math.round(e));
        }
        else {
            obj.ordersRequested = [];
        }
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? CustomerIdentifier.toJSON(message.customer)
                : undefined);
        message.dataRequest !== undefined &&
            (obj.dataRequest = message.dataRequest
                ? CustomerDataRequestEvent_DataRequest.toJSON(message.dataRequest)
                : undefined);
        return obj;
    },
};
function createBaseCustomerDataRequestEvent_DataRequest() {
    return { id: undefined };
}
export const CustomerDataRequestEvent_DataRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerDataRequestEvent_DataRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
};
function createBaseCustomerDataRedactEvent() {
    return {
        shopId: undefined,
        shopDomain: undefined,
        customer: undefined,
        ordersToRedact: [],
    };
}
export const CustomerDataRedactEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopId !== undefined) {
            Int64Value.encode({ value: message.shopId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.shopDomain !== undefined) {
            StringValue.encode({ value: message.shopDomain }, writer.uint32(18).fork()).ldelim();
        }
        if (message.customer !== undefined) {
            CustomerIdentifier.encode(message.customer, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.ordersToRedact) {
            Int64Value.encode({ value: v }, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCustomerDataRedactEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.shopDomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.customer = CustomerIdentifier.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.ordersToRedact.push(Int64Value.decode(reader, reader.uint32()).value);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopId: isSet(object.shopId) ? Number(object.shopId) : undefined,
            shopDomain: isSet(object.shopDomain)
                ? String(object.shopDomain)
                : undefined,
            customer: isSet(object.customer)
                ? CustomerIdentifier.fromJSON(object.customer)
                : undefined,
            ordersToRedact: Array.isArray(object === null || object === void 0 ? void 0 : object.ordersToRedact)
                ? object.ordersToRedact.map((e) => Number(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopId !== undefined && (obj.shopId = message.shopId);
        message.shopDomain !== undefined && (obj.shopDomain = message.shopDomain);
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? CustomerIdentifier.toJSON(message.customer)
                : undefined);
        if (message.ordersToRedact) {
            obj.ordersToRedact = message.ordersToRedact.map((e) => e);
        }
        else {
            obj.ordersToRedact = [];
        }
        return obj;
    },
};
function createBaseShopDataRedactEvent() {
    return { shopId: undefined, shopDomain: undefined };
}
export const ShopDataRedactEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shopId !== undefined) {
            Int64Value.encode({ value: message.shopId }, writer.uint32(10).fork()).ldelim();
        }
        if (message.shopDomain !== undefined) {
            StringValue.encode({ value: message.shopDomain }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopDataRedactEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shopId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.shopDomain = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shopId: isSet(object.shopId) ? Number(object.shopId) : undefined,
            shopDomain: isSet(object.shopDomain)
                ? String(object.shopDomain)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.shopId !== undefined && (obj.shopId = message.shopId);
        message.shopDomain !== undefined && (obj.shopDomain = message.shopDomain);
        return obj;
    },
};
function createBaseShopifyRemoteProfile() {
    return { shop: undefined, webhookRegistrationEvents: [] };
}
export const ShopifyRemoteProfile = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.shop !== undefined) {
            Shop.encode(message.shop, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.webhookRegistrationEvents) {
            WebhookRegistrationEvent_Webhook.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseShopifyRemoteProfile();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shop = Shop.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.webhookRegistrationEvents.push(WebhookRegistrationEvent_Webhook.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            shop: isSet(object.shop) ? Shop.fromJSON(object.shop) : undefined,
            webhookRegistrationEvents: Array.isArray(object === null || object === void 0 ? void 0 : object.webhookRegistrationEvents)
                ? object.webhookRegistrationEvents.map((e) => WebhookRegistrationEvent_Webhook.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.shop !== undefined &&
            (obj.shop = message.shop ? Shop.toJSON(message.shop) : undefined);
        if (message.webhookRegistrationEvents) {
            obj.webhookRegistrationEvents = message.webhookRegistrationEvents.map((e) => (e ? WebhookRegistrationEvent_Webhook.toJSON(e) : undefined));
        }
        else {
            obj.webhookRegistrationEvents = [];
        }
        return obj;
    },
};
function createBaseWebhookEventEnvelope() {
    return { topic: "", event: undefined };
}
export const WebhookEventEnvelope = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.topic !== "") {
            writer.uint32(10).string(message.topic);
        }
        if (message.event !== undefined) {
            Struct.encode(Struct.wrap(message.event), writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWebhookEventEnvelope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.topic = reader.string();
                    break;
                case 2:
                    message.event = Struct.unwrap(Struct.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            topic: isSet(object.topic) ? String(object.topic) : "",
            event: isObject(object.event) ? object.event : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.topic !== undefined && (obj.topic = message.topic);
        message.event !== undefined && (obj.event = message.event);
        return obj;
    },
};
function createBasePrice() {
    return { currencyCode: "", amount: "" };
}
export const Price = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.currencyCode !== "") {
            writer.uint32(10).string(message.currencyCode);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.currencyCode = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            currencyCode: isSet(object.currencyCode)
                ? String(object.currencyCode)
                : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.currencyCode !== undefined &&
            (obj.currencyCode = message.currencyCode);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
};
function createBasePresentmentPriceInfo() {
    return { price: undefined, compareAtPrice: undefined };
}
export const PresentmentPriceInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== undefined) {
            Price.encode(message.price, writer.uint32(10).fork()).ldelim();
        }
        if (message.compareAtPrice !== undefined) {
            Price.encode(message.compareAtPrice, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePresentmentPriceInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = Price.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.compareAtPrice = Price.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? Price.fromJSON(object.price) : undefined,
            compareAtPrice: isSet(object.compareAtPrice)
                ? Price.fromJSON(object.compareAtPrice)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined &&
            (obj.price = message.price ? Price.toJSON(message.price) : undefined);
        message.compareAtPrice !== undefined &&
            (obj.compareAtPrice = message.compareAtPrice
                ? Price.toJSON(message.compareAtPrice)
                : undefined);
        return obj;
    },
};
function createBasePresentmentPricesList() {
    return { presentmentPrices: [] };
}
export const PresentmentPricesList = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.presentmentPrices) {
            PresentmentPriceInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePresentmentPricesList();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.presentmentPrices.push(PresentmentPriceInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            presentmentPrices: Array.isArray(object === null || object === void 0 ? void 0 : object.presentmentPrices)
                ? object.presentmentPrices.map((e) => PresentmentPriceInfo.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.presentmentPrices) {
            obj.presentmentPrices = message.presentmentPrices.map((e) => e ? PresentmentPriceInfo.toJSON(e) : undefined);
        }
        else {
            obj.presentmentPrices = [];
        }
        return obj;
    },
};
function createBaseProductVariant() {
    return {
        barcode: "",
        compareAtPrice: "",
        createdAt: "",
        fulfillmentService: "",
        grams: 0,
        id: 0,
        imageId: 0,
        inventoryItemId: 0,
        inventoryManagement: "",
        inventoryPolicy: "",
        inventoryQuantity: 0,
        option1: "",
        option2: "",
        option3: "",
        presentmentPricesList: undefined,
        position: 0,
        price: "",
        productId: 0,
        sku: "",
        taxable: false,
        taxCode: "",
        title: "",
        updatedAt: "",
        weight: 0,
        weightUnit: "",
    };
}
export const ProductVariant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.barcode !== "") {
            writer.uint32(10).string(message.barcode);
        }
        if (message.compareAtPrice !== "") {
            writer.uint32(18).string(message.compareAtPrice);
        }
        if (message.createdAt !== "") {
            writer.uint32(26).string(message.createdAt);
        }
        if (message.fulfillmentService !== "") {
            writer.uint32(34).string(message.fulfillmentService);
        }
        if (message.grams !== 0) {
            writer.uint32(40).int64(message.grams);
        }
        if (message.id !== 0) {
            writer.uint32(48).int64(message.id);
        }
        if (message.imageId !== 0) {
            writer.uint32(56).int64(message.imageId);
        }
        if (message.inventoryItemId !== 0) {
            writer.uint32(64).int64(message.inventoryItemId);
        }
        if (message.inventoryManagement !== "") {
            writer.uint32(74).string(message.inventoryManagement);
        }
        if (message.inventoryPolicy !== "") {
            writer.uint32(82).string(message.inventoryPolicy);
        }
        if (message.inventoryQuantity !== 0) {
            writer.uint32(88).int64(message.inventoryQuantity);
        }
        if (message.option1 !== "") {
            writer.uint32(98).string(message.option1);
        }
        if (message.option2 !== "") {
            writer.uint32(106).string(message.option2);
        }
        if (message.option3 !== "") {
            writer.uint32(114).string(message.option3);
        }
        if (message.presentmentPricesList !== undefined) {
            PresentmentPricesList.encode(message.presentmentPricesList, writer.uint32(122).fork()).ldelim();
        }
        if (message.position !== 0) {
            writer.uint32(128).int64(message.position);
        }
        if (message.price !== "") {
            writer.uint32(138).string(message.price);
        }
        if (message.productId !== 0) {
            writer.uint32(144).int64(message.productId);
        }
        if (message.sku !== "") {
            writer.uint32(154).string(message.sku);
        }
        if (message.taxable === true) {
            writer.uint32(160).bool(message.taxable);
        }
        if (message.taxCode !== "") {
            writer.uint32(170).string(message.taxCode);
        }
        if (message.title !== "") {
            writer.uint32(178).string(message.title);
        }
        if (message.updatedAt !== "") {
            writer.uint32(186).string(message.updatedAt);
        }
        if (message.weight !== 0) {
            writer.uint32(197).float(message.weight);
        }
        if (message.weightUnit !== "") {
            writer.uint32(202).string(message.weightUnit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProductVariant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.barcode = reader.string();
                    break;
                case 2:
                    message.compareAtPrice = reader.string();
                    break;
                case 3:
                    message.createdAt = reader.string();
                    break;
                case 4:
                    message.fulfillmentService = reader.string();
                    break;
                case 5:
                    message.grams = longToNumber(reader.int64());
                    break;
                case 6:
                    message.id = longToNumber(reader.int64());
                    break;
                case 7:
                    message.imageId = longToNumber(reader.int64());
                    break;
                case 8:
                    message.inventoryItemId = longToNumber(reader.int64());
                    break;
                case 9:
                    message.inventoryManagement = reader.string();
                    break;
                case 10:
                    message.inventoryPolicy = reader.string();
                    break;
                case 11:
                    message.inventoryQuantity = longToNumber(reader.int64());
                    break;
                case 12:
                    message.option1 = reader.string();
                    break;
                case 13:
                    message.option2 = reader.string();
                    break;
                case 14:
                    message.option3 = reader.string();
                    break;
                case 15:
                    message.presentmentPricesList = PresentmentPricesList.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.position = longToNumber(reader.int64());
                    break;
                case 17:
                    message.price = reader.string();
                    break;
                case 18:
                    message.productId = longToNumber(reader.int64());
                    break;
                case 19:
                    message.sku = reader.string();
                    break;
                case 20:
                    message.taxable = reader.bool();
                    break;
                case 21:
                    message.taxCode = reader.string();
                    break;
                case 22:
                    message.title = reader.string();
                    break;
                case 23:
                    message.updatedAt = reader.string();
                    break;
                case 24:
                    message.weight = reader.float();
                    break;
                case 25:
                    message.weightUnit = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            barcode: isSet(object.barcode) ? String(object.barcode) : "",
            compareAtPrice: isSet(object.compareAtPrice)
                ? String(object.compareAtPrice)
                : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            fulfillmentService: isSet(object.fulfillmentService)
                ? String(object.fulfillmentService)
                : "",
            grams: isSet(object.grams) ? Number(object.grams) : 0,
            id: isSet(object.id) ? Number(object.id) : 0,
            imageId: isSet(object.imageId) ? Number(object.imageId) : 0,
            inventoryItemId: isSet(object.inventoryItemId)
                ? Number(object.inventoryItemId)
                : 0,
            inventoryManagement: isSet(object.inventoryManagement)
                ? String(object.inventoryManagement)
                : "",
            inventoryPolicy: isSet(object.inventoryPolicy)
                ? String(object.inventoryPolicy)
                : "",
            inventoryQuantity: isSet(object.inventoryQuantity)
                ? Number(object.inventoryQuantity)
                : 0,
            option1: isSet(object.option1) ? String(object.option1) : "",
            option2: isSet(object.option2) ? String(object.option2) : "",
            option3: isSet(object.option3) ? String(object.option3) : "",
            presentmentPricesList: isSet(object.presentmentPricesList)
                ? PresentmentPricesList.fromJSON(object.presentmentPricesList)
                : undefined,
            position: isSet(object.position) ? Number(object.position) : 0,
            price: isSet(object.price) ? String(object.price) : "",
            productId: isSet(object.productId) ? Number(object.productId) : 0,
            sku: isSet(object.sku) ? String(object.sku) : "",
            taxable: isSet(object.taxable) ? Boolean(object.taxable) : false,
            taxCode: isSet(object.taxCode) ? String(object.taxCode) : "",
            title: isSet(object.title) ? String(object.title) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
            weight: isSet(object.weight) ? Number(object.weight) : 0,
            weightUnit: isSet(object.weightUnit) ? String(object.weightUnit) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.barcode !== undefined && (obj.barcode = message.barcode);
        message.compareAtPrice !== undefined &&
            (obj.compareAtPrice = message.compareAtPrice);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.fulfillmentService !== undefined &&
            (obj.fulfillmentService = message.fulfillmentService);
        message.grams !== undefined && (obj.grams = Math.round(message.grams));
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.imageId !== undefined &&
            (obj.imageId = Math.round(message.imageId));
        message.inventoryItemId !== undefined &&
            (obj.inventoryItemId = Math.round(message.inventoryItemId));
        message.inventoryManagement !== undefined &&
            (obj.inventoryManagement = message.inventoryManagement);
        message.inventoryPolicy !== undefined &&
            (obj.inventoryPolicy = message.inventoryPolicy);
        message.inventoryQuantity !== undefined &&
            (obj.inventoryQuantity = Math.round(message.inventoryQuantity));
        message.option1 !== undefined && (obj.option1 = message.option1);
        message.option2 !== undefined && (obj.option2 = message.option2);
        message.option3 !== undefined && (obj.option3 = message.option3);
        message.presentmentPricesList !== undefined &&
            (obj.presentmentPricesList = message.presentmentPricesList
                ? PresentmentPricesList.toJSON(message.presentmentPricesList)
                : undefined);
        message.position !== undefined &&
            (obj.position = Math.round(message.position));
        message.price !== undefined && (obj.price = message.price);
        message.productId !== undefined &&
            (obj.productId = Math.round(message.productId));
        message.sku !== undefined && (obj.sku = message.sku);
        message.taxable !== undefined && (obj.taxable = message.taxable);
        message.taxCode !== undefined && (obj.taxCode = message.taxCode);
        message.title !== undefined && (obj.title = message.title);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.weight !== undefined && (obj.weight = message.weight);
        message.weightUnit !== undefined && (obj.weightUnit = message.weightUnit);
        return obj;
    },
};
function createBaseImage() {
    return { id: 0, productId: 0, variantIds: [], src: "" };
}
export const Image = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.productId !== 0) {
            writer.uint32(16).int64(message.productId);
        }
        writer.uint32(26).fork();
        for (const v of message.variantIds) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.src !== "") {
            writer.uint32(34).string(message.src);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseImage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.productId = longToNumber(reader.int64());
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.variantIds.push(longToNumber(reader.int64()));
                        }
                    }
                    else {
                        message.variantIds.push(longToNumber(reader.int64()));
                    }
                    break;
                case 4:
                    message.src = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            productId: isSet(object.productId) ? Number(object.productId) : 0,
            variantIds: Array.isArray(object === null || object === void 0 ? void 0 : object.variantIds)
                ? object.variantIds.map((e) => Number(e))
                : [],
            src: isSet(object.src) ? String(object.src) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.productId !== undefined &&
            (obj.productId = Math.round(message.productId));
        if (message.variantIds) {
            obj.variantIds = message.variantIds.map((e) => Math.round(e));
        }
        else {
            obj.variantIds = [];
        }
        message.src !== undefined && (obj.src = message.src);
        return obj;
    },
};
function createBaseOption() {
    return { id: 0, name: "", values: [] };
}
export const Option = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        for (const v of message.values) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOption();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.values.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            name: isSet(object.name) ? String(object.name) : "",
            values: Array.isArray(object === null || object === void 0 ? void 0 : object.values)
                ? object.values.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.name !== undefined && (obj.name = message.name);
        if (message.values) {
            obj.values = message.values.map((e) => e);
        }
        else {
            obj.values = [];
        }
        return obj;
    },
};
function createBaseProduct() {
    return {
        bodyHtml: "",
        createdAt: "",
        handle: "",
        id: 0,
        images: [],
        options: [],
        productType: "",
        publishedAt: "",
        publishedScope: "",
        status: "",
        tags: "",
        templateSuffix: "",
        title: "",
        updatedAt: "",
        variants: [],
        vendor: "",
    };
}
export const Product = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bodyHtml !== "") {
            writer.uint32(10).string(message.bodyHtml);
        }
        if (message.createdAt !== "") {
            writer.uint32(18).string(message.createdAt);
        }
        if (message.handle !== "") {
            writer.uint32(26).string(message.handle);
        }
        if (message.id !== 0) {
            writer.uint32(32).int64(message.id);
        }
        for (const v of message.images) {
            Image.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.options) {
            Option.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.productType !== "") {
            writer.uint32(58).string(message.productType);
        }
        if (message.publishedAt !== "") {
            writer.uint32(66).string(message.publishedAt);
        }
        if (message.publishedScope !== "") {
            writer.uint32(74).string(message.publishedScope);
        }
        if (message.status !== "") {
            writer.uint32(82).string(message.status);
        }
        if (message.tags !== "") {
            writer.uint32(90).string(message.tags);
        }
        if (message.templateSuffix !== "") {
            writer.uint32(98).string(message.templateSuffix);
        }
        if (message.title !== "") {
            writer.uint32(106).string(message.title);
        }
        if (message.updatedAt !== "") {
            writer.uint32(114).string(message.updatedAt);
        }
        for (const v of message.variants) {
            ProductVariant.encode(v, writer.uint32(122).fork()).ldelim();
        }
        if (message.vendor !== "") {
            writer.uint32(130).string(message.vendor);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProduct();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bodyHtml = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.handle = reader.string();
                    break;
                case 4:
                    message.id = longToNumber(reader.int64());
                    break;
                case 5:
                    message.images.push(Image.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.options.push(Option.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.productType = reader.string();
                    break;
                case 8:
                    message.publishedAt = reader.string();
                    break;
                case 9:
                    message.publishedScope = reader.string();
                    break;
                case 10:
                    message.status = reader.string();
                    break;
                case 11:
                    message.tags = reader.string();
                    break;
                case 12:
                    message.templateSuffix = reader.string();
                    break;
                case 13:
                    message.title = reader.string();
                    break;
                case 14:
                    message.updatedAt = reader.string();
                    break;
                case 15:
                    message.variants.push(ProductVariant.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.vendor = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bodyHtml: isSet(object.bodyHtml) ? String(object.bodyHtml) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            handle: isSet(object.handle) ? String(object.handle) : "",
            id: isSet(object.id) ? Number(object.id) : 0,
            images: Array.isArray(object === null || object === void 0 ? void 0 : object.images)
                ? object.images.map((e) => Image.fromJSON(e))
                : [],
            options: Array.isArray(object === null || object === void 0 ? void 0 : object.options)
                ? object.options.map((e) => Option.fromJSON(e))
                : [],
            productType: isSet(object.productType) ? String(object.productType) : "",
            publishedAt: isSet(object.publishedAt) ? String(object.publishedAt) : "",
            publishedScope: isSet(object.publishedScope)
                ? String(object.publishedScope)
                : "",
            status: isSet(object.status) ? String(object.status) : "",
            tags: isSet(object.tags) ? String(object.tags) : "",
            templateSuffix: isSet(object.templateSuffix)
                ? String(object.templateSuffix)
                : "",
            title: isSet(object.title) ? String(object.title) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
            variants: Array.isArray(object === null || object === void 0 ? void 0 : object.variants)
                ? object.variants.map((e) => ProductVariant.fromJSON(e))
                : [],
            vendor: isSet(object.vendor) ? String(object.vendor) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.bodyHtml !== undefined && (obj.bodyHtml = message.bodyHtml);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.handle !== undefined && (obj.handle = message.handle);
        message.id !== undefined && (obj.id = Math.round(message.id));
        if (message.images) {
            obj.images = message.images.map((e) => (e ? Image.toJSON(e) : undefined));
        }
        else {
            obj.images = [];
        }
        if (message.options) {
            obj.options = message.options.map((e) => e ? Option.toJSON(e) : undefined);
        }
        else {
            obj.options = [];
        }
        message.productType !== undefined &&
            (obj.productType = message.productType);
        message.publishedAt !== undefined &&
            (obj.publishedAt = message.publishedAt);
        message.publishedScope !== undefined &&
            (obj.publishedScope = message.publishedScope);
        message.status !== undefined && (obj.status = message.status);
        message.tags !== undefined && (obj.tags = message.tags);
        message.templateSuffix !== undefined &&
            (obj.templateSuffix = message.templateSuffix);
        message.title !== undefined && (obj.title = message.title);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        if (message.variants) {
            obj.variants = message.variants.map((e) => e ? ProductVariant.toJSON(e) : undefined);
        }
        else {
            obj.variants = [];
        }
        message.vendor !== undefined && (obj.vendor = message.vendor);
        return obj;
    },
};
function createBaseGetProductsResponse() {
    return { products: [] };
}
export const GetProductsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.products) {
            Product.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetProductsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.products.push(Product.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            products: Array.isArray(object === null || object === void 0 ? void 0 : object.products)
                ? object.products.map((e) => Product.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.products) {
            obj.products = message.products.map((e) => e ? Product.toJSON(e) : undefined);
        }
        else {
            obj.products = [];
        }
        return obj;
    },
};
function createBaseGetVariantResponse() {
    return { variant: undefined };
}
export const GetVariantResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.variant !== undefined) {
            ProductVariant.encode(message.variant, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetVariantResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.variant = ProductVariant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            variant: isSet(object.variant)
                ? ProductVariant.fromJSON(object.variant)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.variant !== undefined &&
            (obj.variant = message.variant
                ? ProductVariant.toJSON(message.variant)
                : undefined);
        return obj;
    },
};
function createBaseGetProductResponse() {
    return { product: undefined };
}
export const GetProductResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.product !== undefined) {
            Product.encode(message.product, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetProductResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.product = Product.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            product: isSet(object.product)
                ? Product.fromJSON(object.product)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.product !== undefined &&
            (obj.product = message.product
                ? Product.toJSON(message.product)
                : undefined);
        return obj;
    },
};
function createBaseGetProductImageResponse() {
    return { image: undefined };
}
export const GetProductImageResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.image !== undefined) {
            Image.encode(message.image, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetProductImageResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.image = Image.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.image !== undefined &&
            (obj.image = message.image ? Image.toJSON(message.image) : undefined);
        return obj;
    },
};
function createBaseProperties() {
    return { name: "", value: "" };
}
export const Properties = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProperties();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseLineItem() {
    return {
        id: 0,
        price: "",
        productId: 0,
        quantity: 0,
        sku: "",
        title: "",
        variantId: 0,
        variantTitle: "",
        vendor: "",
        name: "",
        totalDiscount: "",
    };
}
export const LineItem = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.productId !== 0) {
            writer.uint32(24).int64(message.productId);
        }
        if (message.quantity !== 0) {
            writer.uint32(32).int64(message.quantity);
        }
        if (message.sku !== "") {
            writer.uint32(42).string(message.sku);
        }
        if (message.title !== "") {
            writer.uint32(50).string(message.title);
        }
        if (message.variantId !== 0) {
            writer.uint32(56).int64(message.variantId);
        }
        if (message.variantTitle !== "") {
            writer.uint32(66).string(message.variantTitle);
        }
        if (message.vendor !== "") {
            writer.uint32(74).string(message.vendor);
        }
        if (message.name !== "") {
            writer.uint32(82).string(message.name);
        }
        if (message.totalDiscount !== "") {
            writer.uint32(90).string(message.totalDiscount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLineItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.productId = longToNumber(reader.int64());
                    break;
                case 4:
                    message.quantity = longToNumber(reader.int64());
                    break;
                case 5:
                    message.sku = reader.string();
                    break;
                case 6:
                    message.title = reader.string();
                    break;
                case 7:
                    message.variantId = longToNumber(reader.int64());
                    break;
                case 8:
                    message.variantTitle = reader.string();
                    break;
                case 9:
                    message.vendor = reader.string();
                    break;
                case 10:
                    message.name = reader.string();
                    break;
                case 11:
                    message.totalDiscount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            price: isSet(object.price) ? String(object.price) : "",
            productId: isSet(object.productId) ? Number(object.productId) : 0,
            quantity: isSet(object.quantity) ? Number(object.quantity) : 0,
            sku: isSet(object.sku) ? String(object.sku) : "",
            title: isSet(object.title) ? String(object.title) : "",
            variantId: isSet(object.variantId) ? Number(object.variantId) : 0,
            variantTitle: isSet(object.variantTitle)
                ? String(object.variantTitle)
                : "",
            vendor: isSet(object.vendor) ? String(object.vendor) : "",
            name: isSet(object.name) ? String(object.name) : "",
            totalDiscount: isSet(object.totalDiscount)
                ? String(object.totalDiscount)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.price !== undefined && (obj.price = message.price);
        message.productId !== undefined &&
            (obj.productId = Math.round(message.productId));
        message.quantity !== undefined &&
            (obj.quantity = Math.round(message.quantity));
        message.sku !== undefined && (obj.sku = message.sku);
        message.title !== undefined && (obj.title = message.title);
        message.variantId !== undefined &&
            (obj.variantId = Math.round(message.variantId));
        message.variantTitle !== undefined &&
            (obj.variantTitle = message.variantTitle);
        message.vendor !== undefined && (obj.vendor = message.vendor);
        message.name !== undefined && (obj.name = message.name);
        message.totalDiscount !== undefined &&
            (obj.totalDiscount = message.totalDiscount);
        return obj;
    },
};
function createBaseOrder() {
    return {
        id: 0,
        customer: undefined,
        lineItems: [],
        currency: "",
        note: "",
        noteAttributes: [],
        cartToken: "",
        checkoutToken: "",
        createdAt: "",
        closedAt: "",
        cancelledAt: "",
        updatedAt: "",
    };
}
export const Order = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.customer !== undefined) {
            ShopifyCustomer.encode(message.customer, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.lineItems) {
            LineItem.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.currency !== "") {
            writer.uint32(34).string(message.currency);
        }
        if (message.note !== "") {
            writer.uint32(42).string(message.note);
        }
        for (const v of message.noteAttributes) {
            Properties.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.cartToken !== "") {
            writer.uint32(58).string(message.cartToken);
        }
        if (message.checkoutToken !== "") {
            writer.uint32(66).string(message.checkoutToken);
        }
        if (message.createdAt !== "") {
            writer.uint32(74).string(message.createdAt);
        }
        if (message.closedAt !== "") {
            writer.uint32(82).string(message.closedAt);
        }
        if (message.cancelledAt !== "") {
            writer.uint32(90).string(message.cancelledAt);
        }
        if (message.updatedAt !== "") {
            writer.uint32(98).string(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.customer = ShopifyCustomer.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.lineItems.push(LineItem.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.currency = reader.string();
                    break;
                case 5:
                    message.note = reader.string();
                    break;
                case 6:
                    message.noteAttributes.push(Properties.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.cartToken = reader.string();
                    break;
                case 8:
                    message.checkoutToken = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.closedAt = reader.string();
                    break;
                case 11:
                    message.cancelledAt = reader.string();
                    break;
                case 12:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            customer: isSet(object.customer)
                ? ShopifyCustomer.fromJSON(object.customer)
                : undefined,
            lineItems: Array.isArray(object === null || object === void 0 ? void 0 : object.lineItems)
                ? object.lineItems.map((e) => LineItem.fromJSON(e))
                : [],
            currency: isSet(object.currency) ? String(object.currency) : "",
            note: isSet(object.note) ? String(object.note) : "",
            noteAttributes: Array.isArray(object === null || object === void 0 ? void 0 : object.noteAttributes)
                ? object.noteAttributes.map((e) => Properties.fromJSON(e))
                : [],
            cartToken: isSet(object.cartToken) ? String(object.cartToken) : "",
            checkoutToken: isSet(object.checkoutToken)
                ? String(object.checkoutToken)
                : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            closedAt: isSet(object.closedAt) ? String(object.closedAt) : "",
            cancelledAt: isSet(object.cancelledAt) ? String(object.cancelledAt) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.customer !== undefined &&
            (obj.customer = message.customer
                ? ShopifyCustomer.toJSON(message.customer)
                : undefined);
        if (message.lineItems) {
            obj.lineItems = message.lineItems.map((e) => e ? LineItem.toJSON(e) : undefined);
        }
        else {
            obj.lineItems = [];
        }
        message.currency !== undefined && (obj.currency = message.currency);
        message.note !== undefined && (obj.note = message.note);
        if (message.noteAttributes) {
            obj.noteAttributes = message.noteAttributes.map((e) => e ? Properties.toJSON(e) : undefined);
        }
        else {
            obj.noteAttributes = [];
        }
        message.cartToken !== undefined && (obj.cartToken = message.cartToken);
        message.checkoutToken !== undefined &&
            (obj.checkoutToken = message.checkoutToken);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.closedAt !== undefined && (obj.closedAt = message.closedAt);
        message.cancelledAt !== undefined &&
            (obj.cancelledAt = message.cancelledAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
};
function createBaseGetOrdersResponse() {
    return { orders: [] };
}
export const GetOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            Order.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(Order.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object === null || object === void 0 ? void 0 : object.orders)
                ? object.orders.map((e) => Order.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => (e ? Order.toJSON(e) : undefined));
        }
        else {
            obj.orders = [];
        }
        return obj;
    },
};
function createBaseRecurringApplicationChargeRequest() {
    return { recurringApplicationCharge: undefined };
}
export const RecurringApplicationChargeRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.recurringApplicationCharge !== undefined) {
            RecurringApplicationCharge.encode(message.recurringApplicationCharge, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecurringApplicationChargeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.recurringApplicationCharge =
                        RecurringApplicationCharge.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            recurringApplicationCharge: isSet(object.recurringApplicationCharge)
                ? RecurringApplicationCharge.fromJSON(object.recurringApplicationCharge)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.recurringApplicationCharge !== undefined &&
            (obj.recurringApplicationCharge = message.recurringApplicationCharge
                ? RecurringApplicationCharge.toJSON(message.recurringApplicationCharge)
                : undefined);
        return obj;
    },
};
function createBaseRecurringApplicationChargeResponse() {
    return { recurringApplicationCharge: undefined };
}
export const RecurringApplicationChargeResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.recurringApplicationCharge !== undefined) {
            RecurringApplicationCharge.encode(message.recurringApplicationCharge, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecurringApplicationChargeResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.recurringApplicationCharge =
                        RecurringApplicationCharge.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            recurringApplicationCharge: isSet(object.recurringApplicationCharge)
                ? RecurringApplicationCharge.fromJSON(object.recurringApplicationCharge)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.recurringApplicationCharge !== undefined &&
            (obj.recurringApplicationCharge = message.recurringApplicationCharge
                ? RecurringApplicationCharge.toJSON(message.recurringApplicationCharge)
                : undefined);
        return obj;
    },
};
function createBaseRecurringApplicationCharge() {
    return {
        id: 0,
        name: "",
        price: "",
        billingOn: "",
        status: "",
        createdAt: undefined,
        updatedAt: undefined,
        activatedOn: "",
        returnUrl: "",
        test: false,
        cancelledOn: "",
        trialDays: 0,
        trialEndsOn: "",
        apiClientId: 0,
        decoratedReturnUrl: "",
        confirmationUrl: "",
        cappedAmount: "",
        balanceUsed: 0,
        balanceRemaining: 0,
        riskLevel: 0,
        currency: "",
        terms: "",
    };
}
export const RecurringApplicationCharge = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.price !== "") {
            writer.uint32(26).string(message.price);
        }
        if (message.billingOn !== "") {
            writer.uint32(34).string(message.billingOn);
        }
        if (message.status !== "") {
            writer.uint32(42).string(message.status);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(58).fork()).ldelim();
        }
        if (message.activatedOn !== "") {
            writer.uint32(66).string(message.activatedOn);
        }
        if (message.returnUrl !== "") {
            writer.uint32(74).string(message.returnUrl);
        }
        if (message.test === true) {
            writer.uint32(80).bool(message.test);
        }
        if (message.cancelledOn !== "") {
            writer.uint32(90).string(message.cancelledOn);
        }
        if (message.trialDays !== 0) {
            writer.uint32(96).int32(message.trialDays);
        }
        if (message.trialEndsOn !== "") {
            writer.uint32(106).string(message.trialEndsOn);
        }
        if (message.apiClientId !== 0) {
            writer.uint32(112).int64(message.apiClientId);
        }
        if (message.decoratedReturnUrl !== "") {
            writer.uint32(122).string(message.decoratedReturnUrl);
        }
        if (message.confirmationUrl !== "") {
            writer.uint32(130).string(message.confirmationUrl);
        }
        if (message.cappedAmount !== "") {
            writer.uint32(138).string(message.cappedAmount);
        }
        if (message.balanceUsed !== 0) {
            writer.uint32(149).float(message.balanceUsed);
        }
        if (message.balanceRemaining !== 0) {
            writer.uint32(157).float(message.balanceRemaining);
        }
        if (message.riskLevel !== 0) {
            writer.uint32(165).float(message.riskLevel);
        }
        if (message.currency !== "") {
            writer.uint32(170).string(message.currency);
        }
        if (message.terms !== "") {
            writer.uint32(178).string(message.terms);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRecurringApplicationCharge();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.price = reader.string();
                    break;
                case 4:
                    message.billingOn = reader.string();
                    break;
                case 5:
                    message.status = reader.string();
                    break;
                case 6:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.activatedOn = reader.string();
                    break;
                case 9:
                    message.returnUrl = reader.string();
                    break;
                case 10:
                    message.test = reader.bool();
                    break;
                case 11:
                    message.cancelledOn = reader.string();
                    break;
                case 12:
                    message.trialDays = reader.int32();
                    break;
                case 13:
                    message.trialEndsOn = reader.string();
                    break;
                case 14:
                    message.apiClientId = longToNumber(reader.int64());
                    break;
                case 15:
                    message.decoratedReturnUrl = reader.string();
                    break;
                case 16:
                    message.confirmationUrl = reader.string();
                    break;
                case 17:
                    message.cappedAmount = reader.string();
                    break;
                case 18:
                    message.balanceUsed = reader.float();
                    break;
                case 19:
                    message.balanceRemaining = reader.float();
                    break;
                case 20:
                    message.riskLevel = reader.float();
                    break;
                case 21:
                    message.currency = reader.string();
                    break;
                case 22:
                    message.terms = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            name: isSet(object.name) ? String(object.name) : "",
            price: isSet(object.price) ? String(object.price) : "",
            billingOn: isSet(object.billingOn) ? String(object.billingOn) : "",
            status: isSet(object.status) ? String(object.status) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            activatedOn: isSet(object.activatedOn) ? String(object.activatedOn) : "",
            returnUrl: isSet(object.returnUrl) ? String(object.returnUrl) : "",
            test: isSet(object.test) ? Boolean(object.test) : false,
            cancelledOn: isSet(object.cancelledOn) ? String(object.cancelledOn) : "",
            trialDays: isSet(object.trialDays) ? Number(object.trialDays) : 0,
            trialEndsOn: isSet(object.trialEndsOn) ? String(object.trialEndsOn) : "",
            apiClientId: isSet(object.apiClientId) ? Number(object.apiClientId) : 0,
            decoratedReturnUrl: isSet(object.decoratedReturnUrl)
                ? String(object.decoratedReturnUrl)
                : "",
            confirmationUrl: isSet(object.confirmationUrl)
                ? String(object.confirmationUrl)
                : "",
            cappedAmount: isSet(object.cappedAmount)
                ? String(object.cappedAmount)
                : "",
            balanceUsed: isSet(object.balanceUsed) ? Number(object.balanceUsed) : 0,
            balanceRemaining: isSet(object.balanceRemaining)
                ? Number(object.balanceRemaining)
                : 0,
            riskLevel: isSet(object.riskLevel) ? Number(object.riskLevel) : 0,
            currency: isSet(object.currency) ? String(object.currency) : "",
            terms: isSet(object.terms) ? String(object.terms) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.name !== undefined && (obj.name = message.name);
        message.price !== undefined && (obj.price = message.price);
        message.billingOn !== undefined && (obj.billingOn = message.billingOn);
        message.status !== undefined && (obj.status = message.status);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.activatedOn !== undefined &&
            (obj.activatedOn = message.activatedOn);
        message.returnUrl !== undefined && (obj.returnUrl = message.returnUrl);
        message.test !== undefined && (obj.test = message.test);
        message.cancelledOn !== undefined &&
            (obj.cancelledOn = message.cancelledOn);
        message.trialDays !== undefined &&
            (obj.trialDays = Math.round(message.trialDays));
        message.trialEndsOn !== undefined &&
            (obj.trialEndsOn = message.trialEndsOn);
        message.apiClientId !== undefined &&
            (obj.apiClientId = Math.round(message.apiClientId));
        message.decoratedReturnUrl !== undefined &&
            (obj.decoratedReturnUrl = message.decoratedReturnUrl);
        message.confirmationUrl !== undefined &&
            (obj.confirmationUrl = message.confirmationUrl);
        message.cappedAmount !== undefined &&
            (obj.cappedAmount = message.cappedAmount);
        message.balanceUsed !== undefined &&
            (obj.balanceUsed = message.balanceUsed);
        message.balanceRemaining !== undefined &&
            (obj.balanceRemaining = message.balanceRemaining);
        message.riskLevel !== undefined && (obj.riskLevel = message.riskLevel);
        message.currency !== undefined && (obj.currency = message.currency);
        message.terms !== undefined && (obj.terms = message.terms);
        return obj;
    },
};
function createBaseUsageChargeRequest() {
    return { usageCharge: undefined };
}
export const UsageChargeRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.usageCharge !== undefined) {
            UsageCharge.encode(message.usageCharge, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUsageChargeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.usageCharge = UsageCharge.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            usageCharge: isSet(object.usageCharge)
                ? UsageCharge.fromJSON(object.usageCharge)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.usageCharge !== undefined &&
            (obj.usageCharge = message.usageCharge
                ? UsageCharge.toJSON(message.usageCharge)
                : undefined);
        return obj;
    },
};
function createBaseUsageChargeResponse() {
    return { usageCharge: undefined };
}
export const UsageChargeResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.usageCharge !== undefined) {
            UsageCharge.encode(message.usageCharge, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUsageChargeResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.usageCharge = UsageCharge.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            usageCharge: isSet(object.usageCharge)
                ? UsageCharge.fromJSON(object.usageCharge)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.usageCharge !== undefined &&
            (obj.usageCharge = message.usageCharge
                ? UsageCharge.toJSON(message.usageCharge)
                : undefined);
        return obj;
    },
};
function createBaseUsageCharge() {
    return {
        id: 0,
        description: "",
        price: "",
        createdAt: undefined,
        currency: "",
        billingOn: "",
        balanceUsed: 0,
        balanceRemaining: 0,
        riskLevel: 0,
    };
}
export const UsageCharge = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).int64(message.id);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.price !== "") {
            writer.uint32(26).string(message.price);
        }
        if (message.createdAt !== undefined) {
            Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.currency !== "") {
            writer.uint32(42).string(message.currency);
        }
        if (message.billingOn !== "") {
            writer.uint32(50).string(message.billingOn);
        }
        if (message.balanceUsed !== 0) {
            writer.uint32(61).float(message.balanceUsed);
        }
        if (message.balanceRemaining !== 0) {
            writer.uint32(69).float(message.balanceRemaining);
        }
        if (message.riskLevel !== 0) {
            writer.uint32(77).float(message.riskLevel);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUsageCharge();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToNumber(reader.int64());
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.price = reader.string();
                    break;
                case 4:
                    message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.currency = reader.string();
                    break;
                case 6:
                    message.billingOn = reader.string();
                    break;
                case 7:
                    message.balanceUsed = reader.float();
                    break;
                case 8:
                    message.balanceRemaining = reader.float();
                    break;
                case 9:
                    message.riskLevel = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            description: isSet(object.description) ? String(object.description) : "",
            price: isSet(object.price) ? String(object.price) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            currency: isSet(object.currency) ? String(object.currency) : "",
            billingOn: isSet(object.billingOn) ? String(object.billingOn) : "",
            balanceUsed: isSet(object.balanceUsed) ? Number(object.balanceUsed) : 0,
            balanceRemaining: isSet(object.balanceRemaining)
                ? Number(object.balanceRemaining)
                : 0,
            riskLevel: isSet(object.riskLevel) ? Number(object.riskLevel) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.description !== undefined &&
            (obj.description = message.description);
        message.price !== undefined && (obj.price = message.price);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.currency !== undefined && (obj.currency = message.currency);
        message.billingOn !== undefined && (obj.billingOn = message.billingOn);
        message.balanceUsed !== undefined &&
            (obj.balanceUsed = message.balanceUsed);
        message.balanceRemaining !== undefined &&
            (obj.balanceRemaining = message.balanceRemaining);
        message.riskLevel !== undefined && (obj.riskLevel = message.riskLevel);
        return obj;
    },
};
function createBaseGetAccessScopesResponse() {
    return { accessScopes: [] };
}
export const GetAccessScopesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.accessScopes) {
            GetAccessScopesResponse_AccessScopes.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAccessScopesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accessScopes.push(GetAccessScopesResponse_AccessScopes.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accessScopes: Array.isArray(object === null || object === void 0 ? void 0 : object.accessScopes)
                ? object.accessScopes.map((e) => GetAccessScopesResponse_AccessScopes.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.accessScopes) {
            obj.accessScopes = message.accessScopes.map((e) => e ? GetAccessScopesResponse_AccessScopes.toJSON(e) : undefined);
        }
        else {
            obj.accessScopes = [];
        }
        return obj;
    },
};
function createBaseGetAccessScopesResponse_AccessScopes() {
    return { handle: "" };
}
export const GetAccessScopesResponse_AccessScopes = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.handle !== "") {
            writer.uint32(10).string(message.handle);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAccessScopesResponse_AccessScopes();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.handle = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            handle: isSet(object.handle) ? String(object.handle) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.handle !== undefined && (obj.handle = message.handle);
        return obj;
    },
};
function createBaseGetWebhooksResponse() {
    return { webhooks: [] };
}
export const GetWebhooksResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.webhooks) {
            GetWebhooksResponse_Webhook.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWebhooksResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.webhooks.push(GetWebhooksResponse_Webhook.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            webhooks: Array.isArray(object === null || object === void 0 ? void 0 : object.webhooks)
                ? object.webhooks.map((e) => GetWebhooksResponse_Webhook.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.webhooks) {
            obj.webhooks = message.webhooks.map((e) => e ? GetWebhooksResponse_Webhook.toJSON(e) : undefined);
        }
        else {
            obj.webhooks = [];
        }
        return obj;
    },
};
function createBaseGetWebhooksResponse_Webhook() {
    return {
        id: undefined,
        address: undefined,
        topic: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        format: undefined,
        fields: [],
        metafieldNamespaces: [],
        apiVersion: undefined,
        privateMetafieldNamespaces: [],
    };
}
export const GetWebhooksResponse_Webhook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.address !== undefined) {
            StringValue.encode({ value: message.address }, writer.uint32(18).fork()).ldelim();
        }
        if (message.topic !== undefined) {
            StringValue.encode({ value: message.topic }, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            StringValue.encode({ value: message.createdAt }, writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            StringValue.encode({ value: message.updatedAt }, writer.uint32(42).fork()).ldelim();
        }
        if (message.format !== undefined) {
            StringValue.encode({ value: message.format }, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.fields) {
            StringValue.encode({ value: v }, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.metafieldNamespaces) {
            StringValue.encode({ value: v }, writer.uint32(66).fork()).ldelim();
        }
        if (message.apiVersion !== undefined) {
            StringValue.encode({ value: message.apiVersion }, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.privateMetafieldNamespaces) {
            StringValue.encode({ value: v }, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWebhooksResponse_Webhook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.address = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.topic = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.createdAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.updatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.format = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.fields.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 8:
                    message.metafieldNamespaces.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                case 9:
                    message.apiVersion = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 10:
                    message.privateMetafieldNamespaces.push(StringValue.decode(reader, reader.uint32()).value);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            address: isSet(object.address) ? String(object.address) : undefined,
            topic: isSet(object.topic) ? String(object.topic) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            format: isSet(object.format) ? String(object.format) : undefined,
            fields: Array.isArray(object === null || object === void 0 ? void 0 : object.fields)
                ? object.fields.map((e) => String(e))
                : [],
            metafieldNamespaces: Array.isArray(object === null || object === void 0 ? void 0 : object.metafieldNamespaces)
                ? object.metafieldNamespaces.map((e) => String(e))
                : [],
            apiVersion: isSet(object.apiVersion)
                ? String(object.apiVersion)
                : undefined,
            privateMetafieldNamespaces: Array.isArray(object === null || object === void 0 ? void 0 : object.privateMetafieldNamespaces)
                ? object.privateMetafieldNamespaces.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.address !== undefined && (obj.address = message.address);
        message.topic !== undefined && (obj.topic = message.topic);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.format !== undefined && (obj.format = message.format);
        if (message.fields) {
            obj.fields = message.fields.map((e) => e);
        }
        else {
            obj.fields = [];
        }
        if (message.metafieldNamespaces) {
            obj.metafieldNamespaces = message.metafieldNamespaces.map((e) => e);
        }
        else {
            obj.metafieldNamespaces = [];
        }
        message.apiVersion !== undefined && (obj.apiVersion = message.apiVersion);
        if (message.privateMetafieldNamespaces) {
            obj.privateMetafieldNamespaces = message.privateMetafieldNamespaces.map((e) => e);
        }
        else {
            obj.privateMetafieldNamespaces = [];
        }
        return obj;
    },
};
function createBaseGetThemesResponse() {
    return { themes: [] };
}
export const GetThemesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.themes) {
            GetThemesResponse_Theme.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetThemesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.themes.push(GetThemesResponse_Theme.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            themes: Array.isArray(object === null || object === void 0 ? void 0 : object.themes)
                ? object.themes.map((e) => GetThemesResponse_Theme.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.themes) {
            obj.themes = message.themes.map((e) => e ? GetThemesResponse_Theme.toJSON(e) : undefined);
        }
        else {
            obj.themes = [];
        }
        return obj;
    },
};
function createBaseGetThemesResponse_Theme() {
    return {
        id: undefined,
        name: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        role: undefined,
        themeStoreId: undefined,
        previewable: undefined,
        processing: undefined,
        adminGraphqlApiId: undefined,
    };
}
export const GetThemesResponse_Theme = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== undefined) {
            Int64Value.encode({ value: message.id }, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== undefined) {
            StringValue.encode({ value: message.name }, writer.uint32(18).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            StringValue.encode({ value: message.createdAt }, writer.uint32(26).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            StringValue.encode({ value: message.updatedAt }, writer.uint32(34).fork()).ldelim();
        }
        if (message.role !== undefined) {
            StringValue.encode({ value: message.role }, writer.uint32(42).fork()).ldelim();
        }
        if (message.themeStoreId !== undefined) {
            Int64Value.encode({ value: message.themeStoreId }, writer.uint32(50).fork()).ldelim();
        }
        if (message.previewable !== undefined) {
            BoolValue.encode({ value: message.previewable }, writer.uint32(58).fork()).ldelim();
        }
        if (message.processing !== undefined) {
            BoolValue.encode({ value: message.processing }, writer.uint32(66).fork()).ldelim();
        }
        if (message.adminGraphqlApiId !== undefined) {
            StringValue.encode({ value: message.adminGraphqlApiId }, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetThemesResponse_Theme();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.name = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.createdAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.updatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.role = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.themeStoreId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.previewable = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.processing = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.adminGraphqlApiId = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? Number(object.id) : undefined,
            name: isSet(object.name) ? String(object.name) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            role: isSet(object.role) ? String(object.role) : undefined,
            themeStoreId: isSet(object.themeStoreId)
                ? Number(object.themeStoreId)
                : undefined,
            previewable: isSet(object.previewable)
                ? Boolean(object.previewable)
                : undefined,
            processing: isSet(object.processing)
                ? Boolean(object.processing)
                : undefined,
            adminGraphqlApiId: isSet(object.adminGraphqlApiId)
                ? String(object.adminGraphqlApiId)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.role !== undefined && (obj.role = message.role);
        message.themeStoreId !== undefined &&
            (obj.themeStoreId = message.themeStoreId);
        message.previewable !== undefined &&
            (obj.previewable = message.previewable);
        message.processing !== undefined && (obj.processing = message.processing);
        message.adminGraphqlApiId !== undefined &&
            (obj.adminGraphqlApiId = message.adminGraphqlApiId);
        return obj;
    },
};
function createBaseAssetResponse() {
    return { asset: undefined };
}
export const AssetResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.asset !== undefined) {
            AssetResponse_Asset.encode(message.asset, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.asset = AssetResponse_Asset.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            asset: isSet(object.asset)
                ? AssetResponse_Asset.fromJSON(object.asset)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.asset !== undefined &&
            (obj.asset = message.asset
                ? AssetResponse_Asset.toJSON(message.asset)
                : undefined);
        return obj;
    },
};
function createBaseAssetResponse_Asset() {
    return {
        key: undefined,
        publicUrl: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        contentType: undefined,
        size: undefined,
        checkSum: undefined,
        themeId: undefined,
        value: undefined,
    };
}
export const AssetResponse_Asset = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== undefined) {
            StringValue.encode({ value: message.key }, writer.uint32(10).fork()).ldelim();
        }
        if (message.publicUrl !== undefined) {
            StringValue.encode({ value: message.publicUrl }, writer.uint32(18).fork()).ldelim();
        }
        if (message.createdAt !== undefined) {
            StringValue.encode({ value: message.createdAt }, writer.uint32(26).fork()).ldelim();
        }
        if (message.updatedAt !== undefined) {
            StringValue.encode({ value: message.updatedAt }, writer.uint32(34).fork()).ldelim();
        }
        if (message.contentType !== undefined) {
            StringValue.encode({ value: message.contentType }, writer.uint32(42).fork()).ldelim();
        }
        if (message.size !== undefined) {
            Int32Value.encode({ value: message.size }, writer.uint32(50).fork()).ldelim();
        }
        if (message.checkSum !== undefined) {
            StringValue.encode({ value: message.checkSum }, writer.uint32(58).fork()).ldelim();
        }
        if (message.themeId !== undefined) {
            Int64Value.encode({ value: message.themeId }, writer.uint32(66).fork()).ldelim();
        }
        if (message.value !== undefined) {
            StringValue.encode({ value: message.value }, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetResponse_Asset();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.publicUrl = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.createdAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.updatedAt = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 5:
                    message.contentType = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 6:
                    message.size = Int32Value.decode(reader, reader.uint32()).value;
                    break;
                case 7:
                    message.checkSum = StringValue.decode(reader, reader.uint32()).value;
                    break;
                case 8:
                    message.themeId = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 9:
                    message.value = StringValue.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : undefined,
            publicUrl: isSet(object.publicUrl) ? String(object.publicUrl) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
            contentType: isSet(object.contentType)
                ? String(object.contentType)
                : undefined,
            size: isSet(object.size) ? Number(object.size) : undefined,
            checkSum: isSet(object.checkSum) ? String(object.checkSum) : undefined,
            themeId: isSet(object.themeId) ? Number(object.themeId) : undefined,
            value: isSet(object.value) ? String(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.publicUrl !== undefined && (obj.publicUrl = message.publicUrl);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.contentType !== undefined &&
            (obj.contentType = message.contentType);
        message.size !== undefined && (obj.size = message.size);
        message.checkSum !== undefined && (obj.checkSum = message.checkSum);
        message.themeId !== undefined && (obj.themeId = message.themeId);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
function createBaseAssetRequest() {
    return { asset: undefined };
}
export const AssetRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.asset !== undefined) {
            AssetRequest_Asset.encode(message.asset, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.asset = AssetRequest_Asset.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            asset: isSet(object.asset)
                ? AssetRequest_Asset.fromJSON(object.asset)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.asset !== undefined &&
            (obj.asset = message.asset
                ? AssetRequest_Asset.toJSON(message.asset)
                : undefined);
        return obj;
    },
};
function createBaseAssetRequest_Asset() {
    return { key: "", value: "" };
}
export const AssetRequest_Asset = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetRequest_Asset();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function toTimestamp(dateStr) {
    const date = new Date(dateStr);
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis).toISOString();
}
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
