import React, { useCallback, FC, DragEvent, useRef, useMemo, MouseEventHandler, DragEventHandler } from 'react';
import {
  Box,
  Link,
  Card,
  DraggableDotsIcon,
  InfoIcon,
  Label,
  Popover,
  useMediaQuery,
  useTheme,
  Alert,
  Button,
  NewLabel,
} from '@connectlyai-tenets/ui-styled-web';
import { useAtom } from 'jotai';
import { isSafari } from '../../../../utils';
import { toolbarNodeHintOpenForAtom } from './atoms';
import { NODE_TOOLBAR_NODES } from './constants';

export const NodeToolbarItem: FC<
  Omit<Partial<(typeof NODE_TOOLBAR_NODES)[number]>, 'hint' | 'title'> & {
    onClick: MouseEventHandler<HTMLElement>;
    onDragStart: DragEventHandler<HTMLElement>;
    hint: string;
    title: string;
    hintLearnMore: string;
    isNew?: boolean;
  }
> = ({ hint, hintIcon, hintLearnMore, icon, nodeType, onClick, onDragStart, title, isNew }) => {
  const theme = useTheme();

  const handleDragStart = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      const element = e.currentTarget;
      element.style.transition = 'unset';
      element.style.boxShadow = 'unset';
      requestAnimationFrame(() => {
        element.style.boxShadow = '';
        if (!isSafari()) {
          element.style.opacity = '0';
        }
      });

      onDragStart?.(e);
    },
    [onDragStart],
  );
  const handleDragEnd = useCallback((e: DragEvent<HTMLDivElement>) => {
    if (!isSafari()) {
      e.currentTarget.style.opacity = '';
      e.currentTarget.style.transition = 'margin 200ms, box-shadow 200ms';
    }
  }, []);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const infoIconRef = useRef<SVGSVGElement | null>(null);

  const [hintOpenFor, setHintOpenFor] = useAtom(toolbarNodeHintOpenForAtom);

  const isHintOpen = useMemo(() => hintOpenFor === nodeType, [hintOpenFor, nodeType]);

  const handleHintMouseEnter = useCallback(() => {
    if (nodeType) {
      setHintOpenFor(nodeType);
    }
  }, [nodeType, setHintOpenFor]);

  const handleHintMouseLeave = useCallback(() => {
    setHintOpenFor(null);
  }, [setHintOpenFor]);

  const handleHintClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <Card
      variant="flow"
      draggable
      sx={{
        cursor: 'grab',
        display: 'flex',
        justifyContent: 'space-between',
        p: isLargeScreen ? 2 : 1.5,
      }}
      onClick={onClick}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {icon}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Label
            variant="subtitle2"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            {title}
            {hint && (
              <Box
                onMouseEnter={handleHintMouseEnter}
                onMouseLeave={handleHintMouseLeave}
                onClick={handleHintClick}
                ref={infoIconRef}
              >
                <InfoIcon width={12} height={12} fill={theme.palette.common.black} opacity={0.38} ref={infoIconRef} />
                <Popover
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={isHintOpen}
                  anchorEl={infoIconRef.current}
                  PaperProps={{
                    onMouseEnter: handleHintMouseEnter,
                    onMouseLeave: handleHintMouseLeave,
                    onClick: handleHintClick,
                    sx: { pointerEvents: 'auto' },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5,
                      p: 2,
                      maxWidth: 248,
                      borderRadius: '10px',
                      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.18)',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                      {hintIcon}
                      <Label variant="subtitle2">{title}</Label>
                    </Box>
                    <Label variant="body2">{hint}</Label>
                    {hintLearnMore && (
                      <Alert severity="info" color="info">
                        <Button
                          component={Link}
                          href={hintLearnMore}
                          target="_blank"
                          variant="text"
                          color="primary"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            cursor: 'pointer',
                            textDecoration: 'none',
                          }}
                        >
                          <Label variant="body2">Learn More</Label>
                        </Button>
                      </Alert>
                    )}
                  </Box>
                </Popover>
              </Box>
            )}
          </Label>
        </Box>
      </Box>
      {isNew && (
        <NewLabel
          sx={{
            fontSize: 10,
            fontWeight: 'bold',
            paddingX: 1,
            paddingY: 0.5,
            borderRadius: 1,
            marginLeft: 'auto',
          }}
        />
      )}
      <DraggableDotsIcon />
    </Card>
  );
};
