import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import { DeleteResourceOptions, DeleteResourceParameters, DELETE_RESOURCE_URL } from './types';
import { DEFAULT_DELETE_RESOURCE } from './constants';

export const useMutationDeleteResource = (options?: DeleteResourceOptions) =>
  useMutation(
    ({ businessId, resource = DEFAULT_DELETE_RESOURCE }: DeleteResourceParameters) =>
      api.profileV1(DELETE_RESOURCE_URL, 'post', {
        path: { 'input.businessId': businessId },
        body: { input: { resource } },
      }),
    options,
  );
