import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../api';
import {
  MUTATION_SOFIA_STORE_DESCRIPTOR_V2,
  MutationUpdateSofiaV2Params,
  MutationUpdateSofiaV2DataOptions,
} from './types';

export const useMutationSofiaStoreDescriptorV2 = (options?: MutationUpdateSofiaV2DataOptions) => {
  return useMutation(
    [],
    (input: MutationUpdateSofiaV2Params) =>
      api.aiV1(MUTATION_SOFIA_STORE_DESCRIPTOR_V2, 'post', {
        path: { 'input.businessId': input.businessId },
        body: { input: { storeDescriptor: input.storeDescriptor } },
      }),
    options,
  );
};
