import React from 'react';
import { Avatar, Box, Label, ConnectlyIcon, useTheme } from '@connectlyai-tenets/ui-styled-web';
import { ProfileHeaderProps } from './types';
import { getLetters, getBackgroundColor, getBorderColor, getColor } from '../../utils';
import { SIZE_MEDIA_XXS, SIZE_SPACING_INTER_COMPONENT } from '../../ui-theme';

export const ProfileHeader = ({ id, subject, avatarUrl, channelIconKind }: ProfileHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar
        alt={subject}
        src={avatarUrl}
        style={{
          width: theme.spacing(4.5),
          height: theme.spacing(4.5),
          background: !avatarUrl && id ? getBackgroundColor(id) : undefined,
          border: `1px solid ${getBorderColor(id || '')}`,
        }}
      >
        {!avatarUrl && getLetters(subject) ? (
          <Label
            variant="body1"
            sx={{
              textTransform: 'uppercase',
              color: getColor(id || ''),
            }}
          >
            {getLetters(subject)}
          </Label>
        ) : undefined}
      </Avatar>
      <Label noWrap variant="subtitle2" sx={{ ml: SIZE_SPACING_INTER_COMPONENT }}>
        {subject}
      </Label>
      {channelIconKind && (
        <ConnectlyIcon
          kind={channelIconKind}
          sx={{
            ml: 0.5,
            width: theme.spacing(SIZE_MEDIA_XXS),
            height: theme.spacing(SIZE_MEDIA_XXS),
          }}
        />
      )}
    </Box>
  );
};
