import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { NavbarDIContext } from '../../context';
import { NAVBAR_DID_INIT } from '../../analytics';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { selectSelectedFilters, selectFilters, setSelectedFilters, setFilters } from '../../state/filtersSlice';
export const NavbarComponentId = '43b5ade3-9381-40dc-a5f5-4a7b2efe4a21';
const Navbar = ({ logProvider }) => {
    const log = logProvider();
    const di = useContext(NavbarDIContext);
    if (di === undefined) {
        log.error('NavbarDIContext is undefined', () => ({}));
        return _jsx(_Fragment, {});
    }
    useEffect(() => {
        di.analyticsProvider().track(NAVBAR_DID_INIT);
    }, []);
    const dispatch = useFeatureDispatch();
    useEffect(() => {
        const subContext = di
            .contextDataStoreProvider()
            .subscriptionContext()
            .subscribe((context) => {
            dispatch(setSelectedFilters(context.filters.map((filter) => filter.id)));
        });
        const subFilters = di
            .contextDataStoreProvider()
            .subscriptionFilters()
            .subscribe((filters) => {
            dispatch(setFilters(filters));
        });
        return () => {
            subContext.unsubscribe();
            subFilters.unsubscribe();
        };
    }, [di.contextDataStoreProvider]);
    const filtersById = useFeatureSelector(selectFilters);
    const selectedFiltersById = useFeatureSelector(selectSelectedFilters);
    const tags = Object.keys(filtersById).map((tagId) => {
        const filter = filtersById[tagId];
        return {
            id: filter.id,
            name: filter.name,
            isEnabled: selectedFiltersById[filter.id] || false,
        };
    });
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(NavbarComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { filters: tags }) });
};
export default Navbar;
