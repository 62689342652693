import Long from "long";
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.graphapi.v3_2.profile";
function createBaseIgUser() {
    return {
        id: "",
        igId: 0,
        followersCount: 0,
        followsCount: 0,
        mediaCount: 0,
        name: "",
        profilePictureUrl: "",
        username: "",
        website: "",
        biography: "",
    };
}
export const IgUser = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.igId !== 0) {
            writer.uint32(16).int64(message.igId);
        }
        if (message.followersCount !== 0) {
            writer.uint32(24).int32(message.followersCount);
        }
        if (message.followsCount !== 0) {
            writer.uint32(32).int32(message.followsCount);
        }
        if (message.mediaCount !== 0) {
            writer.uint32(40).int32(message.mediaCount);
        }
        if (message.name !== "") {
            writer.uint32(50).string(message.name);
        }
        if (message.profilePictureUrl !== "") {
            writer.uint32(58).string(message.profilePictureUrl);
        }
        if (message.username !== "") {
            writer.uint32(66).string(message.username);
        }
        if (message.website !== "") {
            writer.uint32(74).string(message.website);
        }
        if (message.biography !== "") {
            writer.uint32(82).string(message.biography);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIgUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.igId = longToNumber(reader.int64());
                    break;
                case 3:
                    message.followersCount = reader.int32();
                    break;
                case 4:
                    message.followsCount = reader.int32();
                    break;
                case 5:
                    message.mediaCount = reader.int32();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.profilePictureUrl = reader.string();
                    break;
                case 8:
                    message.username = reader.string();
                    break;
                case 9:
                    message.website = reader.string();
                    break;
                case 10:
                    message.biography = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            igId: isSet(object.igId) ? Number(object.igId) : 0,
            followersCount: isSet(object.followersCount)
                ? Number(object.followersCount)
                : 0,
            followsCount: isSet(object.followsCount)
                ? Number(object.followsCount)
                : 0,
            mediaCount: isSet(object.mediaCount) ? Number(object.mediaCount) : 0,
            name: isSet(object.name) ? String(object.name) : "",
            profilePictureUrl: isSet(object.profilePictureUrl)
                ? String(object.profilePictureUrl)
                : "",
            username: isSet(object.username) ? String(object.username) : "",
            website: isSet(object.website) ? String(object.website) : "",
            biography: isSet(object.biography) ? String(object.biography) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.igId !== undefined && (obj.igId = Math.round(message.igId));
        message.followersCount !== undefined &&
            (obj.followersCount = Math.round(message.followersCount));
        message.followsCount !== undefined &&
            (obj.followsCount = Math.round(message.followsCount));
        message.mediaCount !== undefined &&
            (obj.mediaCount = Math.round(message.mediaCount));
        message.name !== undefined && (obj.name = message.name);
        message.profilePictureUrl !== undefined &&
            (obj.profilePictureUrl = message.profilePictureUrl);
        message.username !== undefined && (obj.username = message.username);
        message.website !== undefined && (obj.website = message.website);
        message.biography !== undefined && (obj.biography = message.biography);
        return obj;
    },
};
var globalThis = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
