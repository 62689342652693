import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import produce from 'immer';
import { NodeObjectUpdate } from '../useChangesQueue';
import {
  selectAudience,
  selectCampaignDate,
  selectCampaignSendNode,
  selectDocumentId,
  FlowState,
  selectLanguage,
  selectCampaignName,
} from '../../state/flow';
import { useNodeObjectChange } from '../useFlowChangeAppliers';

export const useUpdateCampaignNode = (): {
  updateCampaignNode: (state: FlowState | 'startCampaignFromPreview') => void;
} => {
  const audience = useSelector(selectAudience);
  const campaignDate = useSelector(selectCampaignDate);
  const campaignName = useSelector(selectCampaignName);
  const campaignSendNode = useSelector(selectCampaignSendNode);
  const documentId = useSelector(selectDocumentId);
  const language = useSelector(selectLanguage);

  const { onNodeObjectChange } = useNodeObjectChange();

  const updateCampaignNode = useCallback(
    (state: FlowState | 'startCampaignFromPreview') => {
      if (!campaignSendNode || ['conclusion', 'preface', 'recipientDetails'].includes(state)) {
        return;
      }
      const updatedNode = produce(campaignSendNode, (draft) => {
        switch (state) {
          case 'buildCampaign':
          case 'approveTemplates':
            if (!draft.data) draft.data = {};
            if (!draft.data.v1) draft.data.v1 = {};
            if (!draft.data.v1.campaignConfig) draft.data.v1.campaignConfig = {};
            draft.data.v1.campaignConfig.name = campaignName;
            draft.data.v1.campaignConfig.type = 'CAMPAIGN_TYPE_MARKETING';
            draft.data.v1.campaignConfig.flowDocumentId = documentId;
            draft.data.v1.campaignConfig.language = language;

            if (draft.data.v1.sendoutConfig?.audienceSegmentId) delete draft.data.v1.sendoutConfig.audienceSegmentId;
            if (draft.data.v1.sendoutConfig?.inputCohortUrl) delete draft.data.v1.sendoutConfig.inputCohortUrl;
            if (draft.data.v1.scheduleConfig) delete draft.data.v1.scheduleConfig;
            break;
          case 'chooseAudience':
            if (!draft.data.v1.sendoutConfig) draft.data.v1.sendoutConfig = {};
            draft.data.v1.sendoutConfig.audienceSegmentId = audience.isEnabled ? audience.segmentId : '';
            if (draft.data.v1.sendoutConfig.audienceSegmentId) {
              delete draft.data.v1.sendoutConfig.inputCohortUrl;
            }
            if (draft.data.v1.sendoutConfig?.inputCohortUrl) delete draft.data.v1.sendoutConfig.inputCohortUrl;
            if (draft.data.v1.scheduleConfig) delete draft.data.v1.scheduleConfig;
            break;
          case 'chooseTime':
            if (!draft.data.v1.scheduleConfig) draft.data.v1.scheduleConfig = {};
            if (campaignDate) {
              draft.data.v1.scheduleConfig.schedule = {
                scheduledAt: campaignDate,
              };
            } else {
              draft.data.v1.scheduleConfig.now = {};
            }
            draft.data.v1.sendoutState = 'SENDOUT_STATE_DRAFT';
            break;

          case 'startCampaignFromPreview':
            if (!draft.data) draft.data = {};
            if (!draft.data.v1) draft.data.v1 = {};
            if (draft.data.v1.scheduleConfig) delete draft.data.v1.scheduleConfig;
            if (draft.data.v1.sendoutConfig) delete draft.data.v1.sendoutConfig;
            if (draft.data.v1.sendoutState) delete draft.data.v1.sendoutState;
            if (draft.data.v1.sendoutId) delete draft.data.v1.sendoutId;
            break;
          default:
            break;
        }
      });
      const update: NodeObjectUpdate = {
        id: campaignSendNode.id,
        item: updatedNode,
      };
      onNodeObjectChange([update]);
    },
    [campaignSendNode, onNodeObjectChange, campaignName, documentId, language, audience, campaignDate],
  );
  return { updateCampaignNode };
};
