import { useMutation } from '@tanstack/react-query';
import { api } from '../../api';
import {
  MUTATION_CREATE_QUICK_REPLY_TEMPLATE_URL,
  MutationCreateQuickReplyTemplateInput,
  MutationCreateQuickReplyTemplateOptions,
} from './types';

export const useMutationCreateQuickReplyTemplate = (options?: MutationCreateQuickReplyTemplateOptions) => {
  return useMutation(
    [],
    (input: MutationCreateQuickReplyTemplateInput) =>
      api.profileV1(MUTATION_CREATE_QUICK_REPLY_TEMPLATE_URL, 'post', {
        path: { businessId: input.businessId },
        body: {
          input: {
            entity: {
              ...input,
            },
          },
        },
      }),
    options,
  );
};
