import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { WelcomeDIContext } from '../../context';
import { WELCOME_DID_INIT } from '../../analytics';
import { useFeatureDispatch, useFeatureSelector } from '../../state/hooks';
import { setUser } from '../../state/action';
import { selectSettings, setBusiness, setBusinessChannel, setOTPCode } from '../../state/featureSlice';
import { subscriptionOTPProvider } from '../../usecases/subscriptionOTP';
export const WelcomeComponentId = 'a68b1539-b500-43de-b476-04c8adf9f29d';
function mapToSettingsViewModel(settings) {
    return {
        agentProfile: {
            id: settings.agentProfile.id,
            displayName: settings.agentProfile.displayName,
        },
        businessProfile: {
            name: settings.businessProfile.name,
            shopifyFocus: settings.businessProfile.shopifyFocus,
            integrations: settings.businessProfile.integrations,
        },
        channel: {
            phoneNumber: settings.channel.phoneNumber,
            whatsAppNumber: settings.channel.whatsAppNumber,
            whatsAppCloudNumbers: settings.channel.whatsAppCloudNumbers,
            fbMessengerPageName: settings.channel.fbMessengerPageName,
            instagramPageHandler: settings.channel.instagramPageHandler,
            shopifyShopName: settings.channel.shopifyShopName,
            shopifyAccessToken: settings.channel.shopifyAccessToken,
            hasShopifyChannel: settings.channel.hasShopifyChannel,
        },
        isDataReady: settings.isAgentProfileReady && settings.isBusinessProfileReady,
        shopify: {
            isActive: Boolean(settings.channel.shopifyAccessToken),
            shopName: settings.channel.shopifyShopName,
            hasChannel: settings.channel.hasShopifyChannel,
        },
        whatsApp: {
            isActive: settings.channel.whatsAppCloudNumbers.length > 0,
            connectWAVM: {
                claimNumber: {
                    isActive: true,
                    waNumber: settings.channel.phoneNumber,
                },
                connectWA: {
                    isActive: Boolean(settings.channel.phoneNumber),
                    verificationCode: settings.waConnect.otpCode,
                },
            },
        },
    };
}
const Welcome = (_props) => {
    const di = useContext(WelcomeDIContext);
    if (di === undefined) {
        return _jsx(_Fragment, {});
    }
    const dispatch = useFeatureDispatch();
    const settings = useFeatureSelector(selectSettings);
    useEffect(() => {
        di.logProvider().debug(WELCOME_DID_INIT, () => ({}));
    }, [di]);
    useEffect(() => {
        const sub = di
            .subscriptionUserProvider()(di.config.businessUserId)
            .subscribe((user) => {
            if (user) {
                dispatch(setUser(user));
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [dispatch, di.config.businessUserId, di.subscriptionUserProvider, di]);
    useEffect(() => {
        var _a, _b;
        if ((_a = settings.channel.smsChannel) === null || _a === void 0 ? void 0 : _a.id) {
            const log = di.logProvider();
            const smsChannel = (_b = settings.channel.smsChannel) === null || _b === void 0 ? void 0 : _b.id;
            const subscriptionOTP = subscriptionOTPProvider(log, di.dtoUpdateAccessorProvider());
            const sub = subscriptionOTP(smsChannel).subscribe((otpcode) => {
                dispatch(setOTPCode(otpcode));
            });
            return () => {
                sub.unsubscribe();
            };
        }
        return () => { };
    }, [dispatch, di.dtoUpdateAccessorProvider, settings, di]);
    useEffect(() => {
        const businessDataStore = di.businessDataStoreProvider();
        const sub = businessDataStore.subscribeBusiness(di.config.businessId).subscribe((data) => {
            data.fold((business) => {
                dispatch(setBusiness(business));
            }, () => { }, (_err) => { });
        });
        return () => {
            sub.unsubscribe();
        };
    }, [dispatch, di.config.businessId, di.businessDataStoreProvider, di]);
    useEffect(() => {
        const subscription = di.subscriptionBusinessChannelProvider()();
        const sub = subscription.subscribe((channel) => {
            dispatch(setBusinessChannel(channel));
        });
        return () => {
            sub.unsubscribe();
        };
    }, [di, dispatch, di.subscriptionBusinessChannelProvider]);
    const settingsVM = mapToSettingsViewModel(settings);
    const UIComponent = di === null || di === void 0 ? void 0 : di.uiRegistryProvider().getComponent(WelcomeComponentId);
    return _jsx(_Fragment, { children: UIComponent && _jsx(UIComponent, { settings: settingsVM }) });
};
export default Welcome;
