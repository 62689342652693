export const mutationAssignRoomAgentProvider = (log, networkClient) => async (input) => {
    const url = `/v1/businesses/${input.businessId}/rooms/${input.roomId}/assignment`;
    log.debug('mutationAssignRoomAgent@start', () => ({
        url,
    }));
    try {
        const resp = await networkClient.put(url, {
            userId: input.agentId,
        });
        log.debug('mutationAssignRoomAgent@success', () => ({
            status: resp.statusText,
            data: resp.data,
        }));
    }
    catch (err) {
        log.warn('mutationAssignRoomAgent@failure', () => ({
            error: err,
        }));
        throw err;
    }
};
