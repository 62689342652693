import { UseMutationOptions } from '@tanstack/react-query';
import { Paths } from '@connectlyai-tenets/sdk';

export const FLOW_EVENT_REQUEST_VERIFY_URL =
  '/internal/v1/businesses/{input.businessId}/request_verify_react_flow_document/flow_documents';

type FlowEventRequestVerifyURL = typeof FLOW_EVENT_REQUEST_VERIFY_URL;

export type FlowEventRequestVerifyResponse = Paths['flowV1'][FlowEventRequestVerifyURL]['post']['responses']['202'];
export type FlowEventRequestVerifyInput = {
  businessId: string;
} & Paths['flowV1'][FlowEventRequestVerifyURL]['post']['parameters']['body']['input'];
export type FlowEventRequestVerifyOptions = Omit<
  UseMutationOptions<FlowEventRequestVerifyResponse, unknown, FlowEventRequestVerifyInput, unknown>,
  'mutationKey' | 'mutationFn'
>;
