import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConnectlyIconKind, FilterByChannelOptionsAll, MessageType, RoomStatus, } from '../entities';
import { setFilterByChannel, setFilterByMarketingStatus, setFilterByRoomStatus, setFilterByStatus, setFilterByTag, setPartialFilterQuery, } from './action';
const isMarketingChannel = (filters) => {
    for (let i = 0; i < filters.length; i += 1) {
        const filter = filters[i];
        if (filter === MessageType.MARKETING) {
            return true;
        }
    }
    return false;
};
const mapChannelToChannelOptionKey = (channels) => channels.map((channel) => {
    switch (channel) {
        case ConnectlyIconKind.SMS:
            return 'SMS';
        case ConnectlyIconKind.WHATSAPP:
            return 'WHATSAPP';
        case ConnectlyIconKind.MESSENGER:
            return 'FBMESSENGER';
        case ConnectlyIconKind.INSTAGRAM:
            return 'INSTAGRAM';
        default:
            return '';
    }
});
const mapRoomStatusToRoomStatusOptionKey = (roomStatuses) => roomStatuses.map((status) => {
    switch (status) {
        case RoomStatus.ROOM_STATUS_OPEN:
            return 'OPEN';
        case RoomStatus.ROOM_STATUS_IN_PROGRESS:
            return 'IN_PROGRESS';
        case RoomStatus.ROOM_STATUS_CLOSED:
            return 'CLOSED';
        case RoomStatus.ROOM_STATUS_CUSTOM:
            return 'CUSTOM';
        default:
    }
    return '';
});
function buildTagString(filters, tag) {
    if (!tag)
        return;
    if (!tag.length)
        return;
    for (let i = 0; i < tag.length; i += 1) {
        filters.push(`filter.tags=${tag[i]}`);
    }
}
function buildUnassignedString(filters, unassigned) {
    if (unassigned) {
        filters.push('filter.assigned=false');
    }
}
function buildUnreadString(filters, unread) {
    if (unread) {
        filters.push('filter.read=false');
    }
}
function buildChannelString(filters, channel) {
    if (!channel)
        return;
    if (!channel.length)
        return;
    if (channel.some((x) => x === ConnectlyIconKind.MESSENGER) &&
        channel.some((x) => x === ConnectlyIconKind.SMS) &&
        channel.some((x) => x === ConnectlyIconKind.INSTAGRAM) &&
        channel.some((x) => x === ConnectlyIconKind.WHATSAPP)) {
        return;
    }
    for (let i = 0; i < channel.length; i += 1) {
        switch (channel[i]) {
            case ConnectlyIconKind.MESSENGER: {
                filters.push('filter.channelTypes=CHANNEL_TYPE_FB_MESSENGER');
                break;
            }
            case ConnectlyIconKind.SMS: {
                filters.push('filter.channelTypes=CHANNEL_TYPE_SMS');
                break;
            }
            case ConnectlyIconKind.INSTAGRAM: {
                filters.push('filter.channelTypes=CHANNEL_TYPE_INSTAGRAM');
                break;
            }
            case ConnectlyIconKind.WHATSAPP: {
                filters.push('filter.channelTypes=CHANNEL_TYPE_WHATSAPP_CLOUD');
                break;
            }
            default: {
                break;
            }
        }
    }
}
function buildTypeString(filters, type) {
    if (!type)
        return;
    if (!type.length)
        return;
    const hasMarketing = type.some((x) => x === MessageType.MARKETING);
    if (hasMarketing) {
        filters.push('filter.marketing=true');
    }
    else {
        filters.push('filter.marketing=false');
    }
}
function buildRoomStatusString(filters, status) {
    if (!status)
        return;
    if (!status.length)
        return;
    if (status.some((x) => x === RoomStatus.ROOM_STATUS_OPEN) &&
        status.some((x) => x === RoomStatus.ROOM_STATUS_IN_PROGRESS) &&
        status.some((x) => x === RoomStatus.ROOM_STATUS_CLOSED)) {
        return;
    }
    for (let i = 0; i < status.length; i += 1) {
        switch (status[i]) {
            case RoomStatus.ROOM_STATUS_OPEN: {
                filters.push('filter.statuses=ROOM_STATUS_OPEN');
                break;
            }
            case RoomStatus.ROOM_STATUS_IN_PROGRESS: {
                filters.push('filter.statuses=ROOM_STATUS_IN_PROGRESS');
                break;
            }
            case RoomStatus.ROOM_STATUS_CLOSED: {
                filters.push('filter.statuses=ROOM_STATUS_CLOSED');
                break;
            }
            default: {
                break;
            }
        }
    }
}
function buildFilterString(currentUsedId, filter) {
    const { useMyTasks, myTasks, all } = filter;
    if (useMyTasks) {
        const filters = [`filter.assignee_ids=[${currentUsedId}]`];
        const { unread, channel, type, roomStatus, tags } = myTasks;
        buildUnreadString(filters, unread);
        buildChannelString(filters, channel);
        buildTypeString(filters, type);
        buildRoomStatusString(filters, roomStatus);
        buildTagString(filters, tags);
        return filters.length > 0 ? `${filters.join('&')}` : '';
    }
    const filters = [];
    const { unread, unassigned, channel, type, roomStatus, tags } = all;
    buildUnreadString(filters, unread);
    buildUnassignedString(filters, unassigned);
    buildChannelString(filters, channel);
    buildTypeString(filters, type);
    buildRoomStatusString(filters, roomStatus);
    buildTagString(filters, tags);
    return filters.length > 0 ? `${filters.join('&')}` : '';
}
export const assignRoomAgent = createAsyncThunk('sidebar/assignRoomAgent', async ({ di, roomId, agentId }) => {
    const mutationAssignRoomAgent = di.mutationAssignRoomAgentProvider();
    await mutationAssignRoomAgent({
        businessId: di.config.businessId,
        roomId,
        agentId,
    });
});
export const tryRefreshRooms = createAsyncThunk('sidebar/tryRefreshRooms', async ({ di, filter }) => {
    const eventRefreshRooms = di.eventRefreshRoomsProvider();
    await eventRefreshRooms(filter);
});
export const setFilter = createAsyncThunk('@sidebar/setFilter', async ({ di, filter }, { dispatch }) => {
    const { roomStatus, channel, unread, type, tags } = filter.useMyTasks ? filter.myTasks : filter.all;
    if (roomStatus.length > 0) {
        const roomStatuses = mapRoomStatusToRoomStatusOptionKey(roomStatus);
        dispatch(setFilterByRoomStatus(roomStatuses));
    }
    else {
        const roomStatuses = mapRoomStatusToRoomStatusOptionKey([
            RoomStatus.ROOM_STATUS_OPEN,
            RoomStatus.ROOM_STATUS_IN_PROGRESS,
            RoomStatus.ROOM_STATUS_CLOSED,
            RoomStatus.ROOM_STATUS_CUSTOM,
        ]);
        dispatch(setFilterByRoomStatus(roomStatuses));
    }
    if (channel.length > 0) {
        const channels = mapChannelToChannelOptionKey(channel);
        dispatch(setFilterByChannel(channels));
    }
    else {
        dispatch(setFilterByChannel(FilterByChannelOptionsAll));
    }
    dispatch(setFilterByStatus(unread ? 'UNREAD' : 'ALL'));
    if (type.length > 0) {
        dispatch(setFilterByMarketingStatus(isMarketingChannel(type)));
    }
    else {
        dispatch(setFilterByMarketingStatus(false));
    }
    if (tags.length > 0) {
        dispatch(setFilterByTag(tags));
    }
    else {
        dispatch(setFilterByTag([]));
    }
    const filterQuery = buildFilterString(di.config.userId, filter);
    dispatch(setPartialFilterQuery(filterQuery));
});
export const tryRequestNextRoomsPage = createAsyncThunk('sidebar/tryRequestNextRoomsPage', async ({ di }) => {
    const eventRequestNextRoomsPage = di.eventRequestNextRoomsPageProvider();
    await eventRequestNextRoomsPage();
});
export const mutationSyncMessagesFromSource = createAsyncThunk('sidebar/syncMessagesFromSource', async (arg, { getState }) => {
    const mutationSyncMessagesFromSourceProvider = arg.di.mutationSyncMessagesFromSourceProvider();
    const state = getState();
    const { userId } = arg.di.config;
    const eligibleChannels = state.businessSlice.businessChannels;
    await mutationSyncMessagesFromSourceProvider(userId, arg.business, eligibleChannels);
});
export const searchCustomers = createAsyncThunk('sidebar/searchCustomers', async ({ di, searchInput }, { rejectWithValue }) => {
    const querySearchCustomers = di.querySearchCustomersProvider();
    try {
        const result = await querySearchCustomers({
            businessId: di.config.businessId,
            searchInput,
        });
        return {
            searchInput,
            result,
        };
    }
    catch (err) {
        return rejectWithValue({ searchInput });
    }
});
