import React, { FC } from 'react';
import { Label, TableRow, TableCell, Tooltip, IconButton, DeleteIcon } from '@connectlyai-tenets/ui-styled-web';
import { RowProps } from './types';

const FailedRow: FC<RowProps> = ({ id, readableName, createdAt, onDelete }) => (
  <TableRow>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
      }}
    >
      <Label variant="subtitle2">{readableName}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1">{createdAt && new Date(createdAt).toLocaleString()}</Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    >
      <Label variant="body1" sx={{ color: 'error.light' }}>
        Failed
      </Label>
    </TableCell>
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
      }}
    />
    <TableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        borderRadius: (theme) => theme.spacing(0, 2, 2, 0),
        textAlign: 'right',
      }}
    />
  </TableRow>
);

export default FailedRow;
