import { UseMutationOptions } from '@tanstack/react-query';
import { ConnectlyError, Paths } from '@connectlyai-tenets/sdk';

export const MUTATION_UPDATE_QUICK_REPLY_TEMPLATE_URL =
  '/v1/businesses/{businessId}/quick_reply_templates/{input.entity.id}';

type MutationUpdateQuickReplyTemplateUrl = typeof MUTATION_UPDATE_QUICK_REPLY_TEMPLATE_URL;

export type MutationUpdateQuickReplyTemplateResponse =
  Paths['profileV1'][MutationUpdateQuickReplyTemplateUrl]['put']['responses']['200']['schema'];
type MutationUpdateQuickReplyTemplateBody = NonNullable<
  Paths['profileV1'][MutationUpdateQuickReplyTemplateUrl]['put']['parameters']['body']['input']['entity']
>;
export type MutationUpdateQuickReplyTemplateInput =
  Paths['profileV1'][MutationUpdateQuickReplyTemplateUrl]['put']['parameters']['path'] &
    MutationUpdateQuickReplyTemplateBody;
export type MutationUpdateQuickReplyTemplateOptions = Omit<
  UseMutationOptions<
    MutationUpdateQuickReplyTemplateResponse,
    ConnectlyError,
    MutationUpdateQuickReplyTemplateInput,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;
