/* eslint-disable prettier/prettier */
import React, { useMemo } from 'react';
import { homeSofiaAnalysisTimeRangesAtom } from '@atoms/home';
import {
  Box,
  Button,
  ConnectlyCard,
  Divider,
  Label,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  useMediaQuery,
  useTheme,
} from '@connectlyai-tenets/ui-styled-web';
import { useNavigator as useAnalyticsNavigator } from '@scenes/Analytics/hooks';
import { useAnalysisDataInRanges, useSofiaEnabled, useNavigator as useSofiaNavigator } from '@scenes/Sofia/hooks';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Sentiment } from '@scenes/Sofia/types';
import { selectSofiaSummaryAnalysis } from '@scenes/Sofia/selectors';
import { SENTIMENT_EMOJIS, SENTIMENT_LABELS } from '@scenes/Sofia/constants';
import { useAnalytics } from '@hooks';
import { SIZE_SPACING_INTER_COMPONENT } from '../../../../ui-theme';
import { getSecondsToMinutes } from './utils';

type DataList = {
  id: 'conversations' | 'sentiment' | 'funnel' | 'topic' | 'word' | 'total' | 'minutesSaved';
  header: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  changeIcon?: React.ReactNode;
  changeLabel?: React.ReactNode;
}[];

const useSofiaSummary = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sofia.analytics' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const history = useHistory();
  const { routes: analyticsRoutes } = useAnalyticsNavigator();
  const { routes: sofiaRoutes } = useSofiaNavigator();
  const { sendAnalytics } = useAnalytics();

  const title = t('summaryAnalysis.title', 'Most popular Sofia AI analytics');
  const enableCTA = t('summaryAnalysis.enableCTA', 'Setup Sofia AI');
  const showAnalyticsCTA = tGeneral('showAll', 'Show all');

  const theme = useTheme();
  const analyticsData = useAnalysisDataInRanges({ timeRangeAtom: homeSofiaAnalysisTimeRangesAtom });

  const rawData = useMemo(() => {
    return selectSofiaSummaryAnalysis(analyticsData);
  }, [analyticsData]);

  const data: DataList = useMemo(() => {
    let sentimentArrowColor = theme.palette.grey[500];
    switch (rawData.sentiment.value) {
      case 'positive':
        sentimentArrowColor =
          rawData.sentiment.data.percent > 0 ? theme.palette.success.main : theme.palette.error.main;
        break;
      case 'negative':
        sentimentArrowColor =
          rawData.sentiment.data.percent > 0 ? theme.palette.error.main : theme.palette.success.main;
        break;
      default:
        break;
    }
    const { sentiment, funnel, topic, word, total } = rawData;

    const dataList: DataList = [
      {
        id: 'conversations',
        header: t('summaryAnalysis.conversations', 'Conversations'),
        title: total.data.count || '-',
        subtitle: t('summaryAnalysis.customersSubtitle', {
          count: total.data.count,
          defaultValue: `${total.data.count} sessions`,
        }),
        changeIcon:
          // eslint-disable-next-line no-nested-ternary
          total.data.change === undefined ? null : total.data.change > 0 ? (
            <TrendingUpIcon sx={{ color: sentimentArrowColor }} />
          ) : (
            <TrendingDownIcon sx={{ color: sentimentArrowColor }} />
          ),
        changeLabel:
          total.data.change === undefined
            ? null
            : t('summaryAnalysis.changeLabel', {
                sign: total.data.change > 0 ? '+' : '',
                change: Math.round(total.data.change || 0),
                defaultValue: `${total.data.change > 0 ? '+' : ''}${Math.round(total.data.change)}%`,
              }),
      },
      {
        id: 'sentiment',
        header: t('summaryAnalysis.sentiment', 'Sentiment'),
        title: sentiment.data.percent
          ? // eslint-disable-next-line max-len
            `${Math.round(sentiment.data.percent)}% ${SENTIMENT_EMOJIS[sentiment.value]} ${t(`sentimentAnalysis.sentiment.${sentiment.value}.title`, SENTIMENT_LABELS[sentiment.value as Sentiment])}`
          : '-',
        subtitle: t('summaryAnalysis.customersSubtitle', {
          count: sentiment.data.count,
          defaultValue: `${sentiment.data.count} sessions`,
        }),
        changeIcon:
          // eslint-disable-next-line no-nested-ternary
          sentiment.data.change === undefined ? null : sentiment.data.change > 0 ? (
            <TrendingUpIcon sx={{ color: sentimentArrowColor }} />
          ) : (
            <TrendingDownIcon sx={{ color: sentimentArrowColor }} />
          ),
        changeLabel:
          sentiment.data.change === undefined
            ? null
            : t('summaryAnalysis.changeLabel', {
                sign: sentiment.data.change > 0 ? '+' : '',
                change: Math.round(sentiment.data.change || 0),
                defaultValue: `${sentiment.data.change > 0 ? '+' : ''}${Math.round(sentiment.data.change)}%`,
              }),
      },
      {
        id: 'funnel',
        header: t('summaryAnalysis.funnel', 'Funnel segment'),
        // eslint-disable-next-line max-len
        title: funnel.data.percent
          ? `${Math.round(funnel.data.percent)}% ${t(`funnelAnalysis.categories.${funnel.value}.title`, funnel.value)}`
          : '-',
        // eslint-disable-next-line max-len
        subtitle: t('summaryAnalysis.customersSubtitle', {
          count: funnel.data.count,
          defaultValue: `${funnel.data.count} sessions`,
        }),
        changeLabel:
          funnel.data.change === undefined
            ? null
            : t('summaryAnalysis.changeLabel', {
                sign: funnel.data.change > 0 ? '+' : '',
                change: Math.round(funnel.data.change || 0),
                defaultValue: `${funnel.data.change > 0 ? '+' : ''}${Math.round(funnel.data.change)}%`,
              }),
        changeIcon:
          // eslint-disable-next-line no-nested-ternary
          funnel.data.change === undefined ? null : funnel.data.change > 0 ? (
            <TrendingUpIcon sx={{ color: sentimentArrowColor }} />
          ) : (
            <TrendingDownIcon sx={{ color: sentimentArrowColor }} />
          ),
      },
      {
        id: 'topic',
        header: t('summaryAnalysis.topic', 'Topic'),
        title: topic.value || '-',
        subtitle: t('summaryAnalysis.conversationsSubtitle', {
          count: topic.data.count,
          total: total.data.count,
          defaultValue: `${topic.data.count} in ${total.data.count} sessions`,
        }),
        changeLabel:
          topic.data.change === undefined
            ? null
            : t('summaryAnalysis.changeLabel', {
                sign: topic.data.change > 0 ? '+' : '',
                change: Math.round(topic.data.change || 0),
                defaultValue: `${topic.data.change > 0 ? '+' : ''}${Math.round(topic.data.change)}%`,
              }),
        changeIcon:
          // eslint-disable-next-line no-nested-ternary
          topic.data.change === undefined ? null : topic.data.change > 0 ? (
            <TrendingUpIcon sx={{ color: sentimentArrowColor }} />
          ) : (
            <TrendingDownIcon sx={{ color: sentimentArrowColor }} />
          ),
      },
      {
        id: 'word',
        header: t('summaryAnalysis.word', 'Word'),
        title: word.value || '-',
        subtitle: t('summaryAnalysis.conversationsSubtitle', {
          count: word.data.count,
          total: total.data.count,
          defaultValue: `${word.data.count} in ${total.data.count} sessions`,
        }),
        changeLabel:
          word.data.change === undefined
            ? null
            : t('summaryAnalysis.changeLabel', {
                sign: word.data.change > 0 ? '+' : '',
                change: Math.round(word.data.change || 0),
                defaultValue: `${word.data.change > 0 ? '+' : ''}${Math.round(word.data.change)}%`,
              }),
        changeIcon:
          // eslint-disable-next-line no-nested-ternary
          word.data.change === undefined ? null : word.data.change > 0 ? (
            <TrendingUpIcon sx={{ color: sentimentArrowColor }} />
          ) : (
            <TrendingDownIcon sx={{ color: sentimentArrowColor }} />
          ),
      },
      {
        id: 'minutesSaved',
        header: t('summaryAnalysis.minutesSaved', 'Minutes saved'),
        title: total.data.count ? getSecondsToMinutes(total.data.count * 30) : '-',
        subtitle: t('summaryAnalysis.customersSubtitle', {
          count: total.data.count,
          defaultValue: `${total.data.count} sessions`,
        }),
      },
    ];
    return dataList;
  }, [rawData, t, theme.palette.error.main, theme.palette.grey, theme.palette.success.main]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleSendSofiaCampaign = () => {
    sendAnalytics('homepage_analytics.sofia_summary.create_campaign_clicked');

    history.push('/flow');
  };

  const handleShowAllAnalytics = () => {
    sendAnalytics('homepage_analytics.sofia_summary.show_all_clicked');

    return analyticsRoutes.sofia.navigate();
  };

  const handleEnableSofia = () => {
    sendAnalytics('homepage_analytics.sofia_summary.enable_clicked');

    return sofiaRoutes.home.navigate();
  };

  return {
    theme,
    title,
    enableCTA,
    showAnalyticsCTA,
    data,
    isMd,
    handleSendSofiaCampaign,
    handleShowAllAnalytics,
    handleEnableSofia,
  };
};

export const SofiaSummaryV2 = () => {
  const {
    title,
    data,
    theme,
    enableCTA,
    showAnalyticsCTA,
    handleSendSofiaCampaign,
    handleShowAllAnalytics,
    handleEnableSofia,
  } = useSofiaSummary();
  const sofiaEnabled = useSofiaEnabled();

  return (
    <ConnectlyCard title={title} isNew>
      <Box
        sx={{
          mt: 3,
          display: 'grid',
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
          gridTemplateRows: 'repeat(2, minmax(136px, 1fr))',
        }}
      >
        {data.map(({ id, header, title: itemTitle, subtitle, changeLabel, changeIcon }, i) => {
          return (
            <>
              <Stack key={id} gap={SIZE_SPACING_INTER_COMPONENT} alignItems="center">
                <Label>{header}</Label>
                <Label variant="h6" sx={{ fontWeight: 'bold !important' }}>
                  {itemTitle}
                </Label>
                <Label variant="body1" color="text.secondary">
                  {subtitle}
                </Label>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  {changeIcon}
                  <Label variant="body1" color="text.secondary">
                    {changeLabel}
                  </Label>
                </Stack>
              </Stack>
              {i === 2 ? null : <Divider orientation="vertical" flexItem color={theme.palette.grey[300]} />}
            </>
          );
        })}
      </Box>
      <Stack direction="row" justifyContent="center">
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {sofiaEnabled && (
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleSendSofiaCampaign}>
              SEND A SOFIA AI CAMPAIGN
            </Button>
          )}

          {sofiaEnabled && (
            <Button variant="text" onClick={handleShowAllAnalytics} sx={{ mt: 2 }}>
              {showAnalyticsCTA}
            </Button>
          )}

          {!sofiaEnabled && (
            <Button variant="contained" onClick={handleEnableSofia} sx={{ mt: 2 }}>
              {enableCTA}
            </Button>
          )}
        </Box>
      </Stack>
    </ConnectlyCard>
  );
};
