import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "events.facebook.graphapi.v12_0.profile";
function createBaseWABAPhoneNumber() {
    return { phoneNumber: "", wabaId: "", profilePictureUrl: "" };
}
export const WABAPhoneNumber = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.phoneNumber !== "") {
            writer.uint32(10).string(message.phoneNumber);
        }
        if (message.wabaId !== "") {
            writer.uint32(18).string(message.wabaId);
        }
        if (message.profilePictureUrl !== "") {
            writer.uint32(26).string(message.profilePictureUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWABAPhoneNumber();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = reader.string();
                    break;
                case 2:
                    message.wabaId = reader.string();
                    break;
                case 3:
                    message.profilePictureUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
            wabaId: isSet(object.wabaId) ? String(object.wabaId) : "",
            profilePictureUrl: isSet(object.profilePictureUrl)
                ? String(object.profilePictureUrl)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.phoneNumber !== undefined &&
            (obj.phoneNumber = message.phoneNumber);
        message.wabaId !== undefined && (obj.wabaId = message.wabaId);
        message.profilePictureUrl !== undefined &&
            (obj.profilePictureUrl = message.profilePictureUrl);
        return obj;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
