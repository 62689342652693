import { createSlice } from '@reduxjs/toolkit';
import { fetchAgents, fetchBusiness, tryCreateTag } from './thunk';
const initialBusinessStatus = {
    status: 'loading',
    agentsStatus: 'loading',
    timestamp: 0,
    business: undefined,
};
const initialState = {
    businesses: {},
};
const mergeBusinessDto = (prev, dto) => {
    var _a, _b, _c;
    return ({
        ...prev,
        channels: dto.channels,
        quickReplyTemplates: ((_a = dto.quickReplyTemplates) === null || _a === void 0 ? void 0 : _a.templates) || [],
        tags: ((_b = dto.tags) === null || _b === void 0 ? void 0 : _b.entries) || [],
        properties: dto.properties,
        inboxConfig: dto.inboxConfig,
        integrations: ((_c = dto.integrations) === null || _c === void 0 ? void 0 : _c.entries) || [],
    });
};
const mapBusinessDtoToBusiness = (dto) => {
    var _a, _b, _c;
    return ({
        id: dto.id,
        name: dto.name,
        channels: dto.channels,
        agents: [],
        quickReplyTemplates: ((_a = dto.quickReplyTemplates) === null || _a === void 0 ? void 0 : _a.templates) || [],
        tags: ((_b = dto.tags) === null || _b === void 0 ? void 0 : _b.entries) || [],
        properties: dto.properties,
        inboxConfig: dto.inboxConfig,
        integrations: ((_c = dto.integrations) === null || _c === void 0 ? void 0 : _c.entries) || [],
    });
};
export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        businessDtoUpdate: (state, action) => {
            const newArrival = action.payload;
            const businessId = newArrival.id;
            const business = state.businesses[businessId];
            business.status = 'succeeded';
            business.timestamp += 1;
            if (business.business) {
                business.business = mergeBusinessDto(business.business, action.payload);
            }
            else {
                business.business = mapBusinessDtoToBusiness(action.payload);
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAgents.pending, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            if (!business) {
                state.businesses[businessId] = initialBusinessStatus;
            }
            if (business && business.timestamp === 0) {
                business.agentsStatus = 'loading';
            }
        });
        builder.addCase(fetchAgents.fulfilled, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            business.agentsStatus = 'succeeded';
            business.timestamp += 1;
            if (business.business) {
                business.business.agents = action.payload.agents;
            }
            else {
                business.business = {
                    id: businessId,
                    name: '',
                    agents: action.payload.agents,
                    channels: [],
                    quickReplyTemplates: [],
                    tags: [],
                    integrations: [],
                };
            }
        });
        builder.addCase(fetchAgents.rejected, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            business.agentsStatus = 'failed';
        });
        builder.addCase(fetchBusiness.pending, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            if (!business) {
                state.businesses[businessId] = initialBusinessStatus;
            }
            if (business && business.timestamp === 0) {
                business.status = 'loading';
            }
        });
        builder.addCase(fetchBusiness.fulfilled, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            business.status = 'succeeded';
            business.timestamp += 1;
            if (business.business) {
                business.business = mergeBusinessDto(business.business, action.payload);
            }
            else {
                business.business = mapBusinessDtoToBusiness(action.payload);
            }
        });
        builder.addCase(fetchBusiness.rejected, (state, action) => {
            const { businessId } = action.meta.arg;
            const business = state.businesses[businessId];
            business.status = 'failed';
        });
        builder.addCase(tryCreateTag.fulfilled, (state, action) => {
            const { businessId } = action.meta.arg.input;
            const business = state.businesses[businessId];
            if (business.business) {
                business.business.tags.push(action.payload);
            }
            else {
                business.business = {
                    id: businessId,
                    name: '',
                    agents: [],
                    channels: [],
                    quickReplyTemplates: [],
                    tags: [action.payload],
                    integrations: [],
                };
            }
        });
    },
});
export const { businessDtoUpdate, reset } = businessSlice.actions;
