import { CampaignInputType } from '@connectlyai-idl/models/dist/models/crm/campaign/models';
import { NotificationSeverity, NotificationSurface } from '@connectlyai-sdks/notification';
import { ApiError } from '@connectlyai-tenets/error';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { buttonComponentContent } from '../components/common/presenter';
import { audienceChannelType, getAudienceSegmentId, CampaignType, ChannelType, MessageTemplateComponent_Button_Type, templateIds, } from '../entities';
import { setEditCampaign, setInitializationParams } from './action';
import { buildAudienceQuery } from './utils';
export const CREATE_NEW_CAMPAIGN_ID = 'CREATE_NEW_CAMPAIGN';
export const EMPTY_CAMPAIGN_ID = null;
export const tryQueryCampaign = createAsyncThunk('broadcast/tryQueryCampaign', async ({ di, campaignId }, { dispatch }) => {
    const queryCampaign = di.queryCampaignProvider();
    try {
        const campaign = await queryCampaign(di.config.businessId, campaignId);
        const currentTemplateIds = templateIds(campaign) || [];
        const currentAudienceId = getAudienceSegmentId(campaign) || '';
        dispatch(setInitializationParams({
            parentCampaignId: campaign.id,
            name: campaign.name,
            audienceId: currentAudienceId,
            templateIds: currentTemplateIds,
        }));
        return campaign;
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch campaign.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQueryCampaigns = createAsyncThunk('broadcast/tryQueryCampaigns', async ({ di, channelType }) => {
    const queryCampaigns = di.queryCampaignsProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        const result = await queryCampaigns(di.config.businessId, channelType);
        return result;
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch campaigns.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQueryWAMessageTemplates = createAsyncThunk('broadcast/tryQueryWAMessageTemplates', async ({ di, statuses }) => {
    const queryWAMessageTemplates = di.queryWAMessageTemplatesProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    const filter = statuses || [];
    try {
        let result = await queryWAMessageTemplates(di.config.businessId);
        if (filter.length > 0) {
            result = result.filter(({ status }) => filter.includes(status));
        }
        return result;
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch whatsapp templates.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQueryMessageTemplateGroups = createAsyncThunk('broadcast/tryQueryMessageTemplateGroups', async ({ di, status, systemTemplate }) => {
    const queryMessageTemplateGroups = di.queryMessageTemplateGroupsProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        const result = await queryMessageTemplateGroups(di.config.businessId, status, systemTemplate);
        return result;
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch whatsapp templates.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryFetchBusinessChannelMetadata = createAsyncThunk('broadcast/tryFetchBusinessChannelMetadata', async ({ di, businessChannelId }) => {
    const provider = di.fetchBusinessChannelMetadataProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        return await provider(di.config.businessId, businessChannelId);
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch WhatsApp quality signals.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryMutationCreateMessageTemplate = createAsyncThunk('broadcast/tryMutationCreateMessageTemplate', async ({ di, channel, template, onCreateTemplate, onCreateTemplateError }) => {
    onCreateTemplate === null || onCreateTemplate === void 0 ? void 0 : onCreateTemplate({
        businessId: di.config.businessId,
        id: template.id,
        name: template.name,
    });
    const notificationService = di.notificationServiceProvider();
    const mutationCreateMessageTemplate = di.mutationCreateMessageTemplateProvider();
    try {
        if (di.config.businessId === '') {
            throw new Error('null business id');
        }
        const resp = await mutationCreateMessageTemplate({
            channel,
            businessId: di.config.businessId,
            template,
        });
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Template has been submitted',
                icon: '',
                severity: NotificationSeverity.SUCCESS,
            },
        });
        return resp;
    }
    catch (err) {
        onCreateTemplateError === null || onCreateTemplateError === void 0 ? void 0 : onCreateTemplateError({
            businessId: di.config.businessId,
            id: template.id,
            name: template.name,
        });
        const messageDetails = err instanceof ApiError ? `: ${err.getMessageDetails()}` : '';
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: `Failed to create template${messageDetails}`,
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryMutationDeleteMessageTemplate = createAsyncThunk('broadcast/tryMutationDeleteMessageTemplate', async ({ di, templateGroupId }) => {
    if (di === undefined) {
        return;
    }
    const mutationDeleteMessageTemplate = di.mutationDeleteMessageTemplateProvider();
    try {
        if (di.config.businessId === '') {
            throw new Error('null business id');
        }
        await mutationDeleteMessageTemplate({
            businessId: (di === null || di === void 0 ? void 0 : di.config.businessId) || '',
            templateGroupId,
        });
    }
    catch (err) {
        const messageDetails = err instanceof ApiError ? `: ${err.getMessageDetails()}` : '';
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: `Failed to delete template${messageDetails}`,
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
function checkTemplates(messageTemplateIds, cache) {
    const welcomeMessage = cache[messageTemplateIds[0]];
    if (!welcomeMessage) {
        return false;
    }
    const buttons = buttonComponentContent(welcomeMessage.dto.externalTemplate);
    const quickRepliesCount = buttons.filter((x) => x.type === MessageTemplateComponent_Button_Type.QUICK_REPLY).length;
    const autoRepliesCount = messageTemplateIds.slice(1).filter((x) => Boolean(x)).length;
    if (autoRepliesCount === 0) {
        return true;
    }
    return autoRepliesCount === quickRepliesCount;
}
export const tryMutationInitializeCampaign = createAsyncThunk('broadcast/tryMutationInitializeCampaign', async ({ di, name, messageTemplateIds, parentCampaignId, audienceId }, { getState, dispatch }) => {
    var _a, _b;
    const mutationInitializeCampaign = di.mutationInitializeCampaignProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    const state = getState();
    const { campaign } = state.campaignEditSlice;
    dispatch(setInitializationParams({
        parentCampaignId,
        name,
        audienceId,
        templateIds: messageTemplateIds,
    }));
    if (!name && parentCampaignId === CREATE_NEW_CAMPAIGN_ID) {
        throw new Error('Unable to create campaign. Please provide campaign name.');
    }
    if (!messageTemplateIds[0]) {
        throw new Error('Unable to create campaign. Please select welcome message.');
    }
    if (!checkTemplates(messageTemplateIds, state.messageTemplateGroupSlice.items.byId)) {
        throw new Error('Unable to create campaign. Please select all auto responses.');
    }
    try {
        const selectedMessageTemplate = state.messageTemplateGroupSlice.items.byId[messageTemplateIds[0]];
        if (!selectedMessageTemplate) {
            throw new Error('null/undefined selected message template');
        }
        let input;
        const campaignName = selectedMessageTemplate.group.name;
        let config;
        if (messageTemplateIds.filter((x) => Boolean(x)).length === 1) {
            config = {
                $case: 'waBroadcast',
                waBroadcast: {
                    waMessageTemplateId: messageTemplateIds[0] || '',
                },
            };
        }
        else {
            const messageTemplates = messageTemplateIds
                .reduce((arr, waMessageTemplateId, i) => {
                if (waMessageTemplateId) {
                    arr.push({
                        waMessageTemplateId,
                        stbName: i === 0 ? 'welcome' : `response_${i}`,
                    });
                }
                return arr;
            }, []);
            config = {
                $case: 'stbBroadcastV1',
                stbBroadcastV1: {
                    messageTemplates,
                },
            };
        }
        if (campaign !== null && ((_b = (_a = campaign.config) === null || _a === void 0 ? void 0 : _a.configOneof) === null || _b === void 0 ? void 0 : _b.$case) === 'broadcast') {
            const { id, businessId, type } = campaign;
            input = {
                id,
                businessId,
                name: '',
                configName: name || campaignName,
                type,
                audienceChannelType: audienceChannelType(campaign) || ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
                config: { configOneof: config, inputType: CampaignInputType.CAMPAIGN_INPUT_TYPE_SPREADSHEET },
                audienceSegmentId: audienceId || '',
            };
        }
        else {
            input = {
                id: uuidv4(),
                businessId: di.config.businessId,
                name: '',
                configName: name || campaignName,
                type: CampaignType.CAMPAIGN_TYPE_MARKETING,
                audienceChannelType: ChannelType.CHANNEL_TYPE_WHATSAPP_CLOUD,
                config: { configOneof: config, inputType: CampaignInputType.CAMPAIGN_INPUT_TYPE_SPREADSHEET },
                audienceSegmentId: audienceId || '',
            };
        }
        return await mutationInitializeCampaign(input);
    }
    catch (err) {
        dispatch(setEditCampaign(campaign));
        throw err;
    }
});
export const uploadAttachment = createAsyncThunk('broadcast/uploadAttachment', async ({ di, localFileUrl }) => {
    const notificationService = di.notificationServiceProvider();
    const mutationUploadAsset = di.mutationUploadAssetProvider();
    try {
        const assetDto = await mutationUploadAsset({
            businessId: di.config.businessId,
            localFileUrl,
        });
        return assetDto;
    }
    catch (err) {
        const messageDetails = err instanceof ApiError ? `: ${err.getMessageDetails()}` : '';
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: `Failed to upload image${messageDetails}`,
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryUploadAttachment = createAsyncThunk('broadcast/tryUploadAttachment', async ({ di, localFileUrl, name }, { getState, dispatch }) => {
    const { attachment } = getState().messageTemplatesSlice;
    const { attachmentState } = attachment;
    if (attachmentState && attachmentState.localUrl === localFileUrl) {
        throw new Error('Attachment is already picked');
    }
    dispatch(uploadAttachment({
        di,
        name,
        localId: uuidv4(),
        localFileUrl,
    }));
});
export const tryQueryAudiences = createAsyncThunk('broadcast/tryQueryAudiences', async ({ di }) => {
    const queryAudiences = di.queryAudiencesProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        return await queryAudiences(di.config.businessId);
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch audiences.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryMutationDeleteAudience = createAsyncThunk('broadcast/tryMutationDeleteAudience', async ({ di, audienceId }) => {
    const mutationDeleteAudience = di.mutationDeleteAudienceProvider();
    try {
        if (di.config.businessId === '') {
            throw new Error('null business id');
        }
        await mutationDeleteAudience({
            businessId: di.config.businessId,
            audienceId,
        });
    }
    catch (err) {
        const messageDetails = err instanceof ApiError ? `: ${err.getMessageDetails()}` : '';
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: `Failed to delete audience${messageDetails}`,
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQueryAudience = createAsyncThunk('broadcast/tryQueryAudience', async ({ di, audienceId }, { getState }) => {
    const queryAudience = di.queryAudienceProvider();
    try {
        const audience = getState().audiencesSlice.items.byId[audienceId];
        if (audience) {
            return audience;
        }
        return await queryAudience(di.config.businessId, audienceId);
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch audience.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryMutationUpdateAudience = createAsyncThunk('broadcast/tryMutationUpdateAudience', async ({ di, onUpdateAudience, onUpdateAudienceError, onCreateAudience }, { getState }) => {
    const state = getState();
    const { editAudience, name, campaignId, sendoutIds, behaviors } = state.audienceEditSlice;
    const audience = {
        id: editAudience ? editAudience.id : '',
        businessId: di.config.businessId,
        name,
        size: editAudience ? editAudience.size : 0,
        createdAt: editAudience ? editAudience.createdAt : undefined,
        updatedAt: new Date().toISOString(),
        query: buildAudienceQuery(campaignId, sendoutIds, behaviors),
    };
    if (editAudience) {
        onUpdateAudience === null || onUpdateAudience === void 0 ? void 0 : onUpdateAudience({
            businessId: di.config.businessId,
            id: audience.id,
        });
    }
    else {
        onCreateAudience === null || onCreateAudience === void 0 ? void 0 : onCreateAudience({
            businessId: di.config.businessId,
            id: audience.id,
        });
    }
    const notificationService = di.notificationServiceProvider();
    const mutationUpdateAudience = di.mutationUpdateAudienceProvider();
    try {
        if (di.config.businessId === '') {
            throw new Error('null business id');
        }
        const resp = await mutationUpdateAudience({
            businessId: di.config.businessId,
            audience,
        });
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Audience has been submitted',
                icon: '',
                severity: NotificationSeverity.SUCCESS,
            },
        });
        return resp;
    }
    catch (err) {
        if (editAudience) {
            onUpdateAudienceError === null || onUpdateAudienceError === void 0 ? void 0 : onUpdateAudienceError({
                businessId: di.config.businessId,
                id: audience.id,
            });
        }
        else {
            onCreateAudience === null || onCreateAudience === void 0 ? void 0 : onCreateAudience({
                businessId: di.config.businessId,
                id: audience.id,
            });
        }
        const messageDetails = err instanceof ApiError ? `: ${err.getMessageDetails()}` : '';
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: `Failed to update audience${messageDetails}`,
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQuerySendouts = createAsyncThunk('broadcast/tryQuerySendouts', async ({ di, channelType }) => {
    const query = di.querySendoutsProvider();
    if (di.config.businessId === '') {
        throw new Error('null business id');
    }
    try {
        return await query(di.config.businessId, channelType);
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch sendouts.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
export const tryQueryAudienceCohort = createAsyncThunk('broadcast/tryQueryAudienceCohort', async ({ di, query }) => {
    const queryAudienceCohort = di.queryAudienceCohortProvider();
    try {
        if (di.config.businessId === '') {
            throw new Error('null business id');
        }
        return await queryAudienceCohort(di.config.businessId, query);
    }
    catch (err) {
        const notificationService = di.notificationServiceProvider();
        notificationService.notify({
            surface: NotificationSurface.SNACKBAR,
            notification: {
                message: 'Failed to fetch audience size.',
                icon: '',
                severity: NotificationSeverity.ERROR,
            },
        });
        throw err;
    }
});
